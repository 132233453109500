import _ from 'lodash';

import OptionalUpgradeNotification from 'models/notification/optional_upgrade_notification';
import qconsole from 'scripts/lib/qconsole';
import removeOptionalUpgradeNotifications from 'actions/version/lib/remove_optional_upgrade_notifications';
import SoftwareVersionService from 'actions/version/software_version_service';

export default class ServerInfoObserver {
  constructor(context) {
    this.context = context;
  }

  handleUpdate(serverInfo) {
    if (!serverInfo.version) {
      this.context.errorReporter.reportMessage('ServerInfo is missing a version', { extra: { serverInfo } });
      return;
    }

    if (serverInfo.version === SoftwareVersionService.getVersion()) {
      this.removeOptionalUpgradeNotifications();
      return;
    }

    // Suppress new version notifications if the feature flag `disableNewVersionNotifications` is set.
    if (this.context.stores.appFeatures.get().isEnabled('disableNewVersionNotifications')) {
      qconsole.log(
        `Optional Upgrade Notification suppressed due to feature flag: disableNewVersionNotifications. ${getVersionInfoString(
          serverInfo
        )}`
      );
      this.removeOptionalUpgradeNotifications();
      return;
    }

    if (this.context.stores.auth.get().isLoggedIn()) {
      this.notifyOfOptionalUpgrade(serverInfo);
      return;
    }

    this.removeOptionalUpgradeNotifications();
    SoftwareVersionService.upgrade();
  }

  removeOptionalUpgradeNotifications() {
    removeOptionalUpgradeNotifications(this.context);
  }

  notifyOfOptionalUpgrade(serverInfo) {
    let optionalUpgradeNotification = _.find(this.notificationsStore.findAll(), isOptionalUpgradeNotification);
    if (!optionalUpgradeNotification) {
      qconsole.log(`upgrade recommended: ${getVersionInfoString(serverInfo)}`);
      this.notificationsStore.add(OptionalUpgradeNotification.create());
    }
  }

  get notificationsStore() {
    return this.context.stores.notifications;
  }
}

function getVersionInfoString(serverInfo) {
  return `server version: [${serverInfo.version}], app version: [${SoftwareVersionService.getVersion()}]`;
}

function isOptionalUpgradeNotification(notification) {
  return notification instanceof OptionalUpgradeNotification;
}
