import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FeatureNotificationTypes from 'models/agent_notification/feature_notification_types';
import { H3 } from 'components/common/headers';
import MarkFeatureAsSeen from 'actions/agent_notifications/mark_feature_as_seen';
import { OutlineButtonBase } from 'components/common/button';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import XIcon from 'components/lib/icons/x_icon';

export default function FeatureNotification({ feature, text, subtext, url, urlText }) {
  const executeAction = useExecuteAction();
  const markFeatureAsSeen = () => {
    executeAction(MarkFeatureAsSeen, { feature });
  };

  return (
    <Bubble data-aid="featureNotification">
      <StyledH3>{text}</StyledH3>
      {subtext ? <HeaderText>{subtext}</HeaderText> : null}
      <ButtonLink href={url} rel="noopener noreferrer" target="_blank">
        {urlText ? urlText : "See what's new"}
      </ButtonLink>
      <CloseIcon data-aid="closeFeatureNotification" onClick={markFeatureAsSeen}>
        <XIcon />
      </CloseIcon>
    </Bubble>
  );
}

FeatureNotification.propTypes = {
  feature: PropTypes.oneOf(Object.keys(FeatureNotificationTypes)).isRequired,
  orgIds: PropTypes.arrayOf(PropTypes.string),
  roleFlags: PropTypes.arrayOf(PropTypes.string),
  subtext: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const StyledH3 = styled(H3)`
  margin-bottom: 8px;
  margin-right: 24px;
`;

const HeaderText = styled.div`
  margin-bottom: 12px;
`;

const Bubble = styled.div`
  background-color: ${p => p.theme.colors.gray100};
  border: 1px solid ${p => p.theme.colors.gray200};
  border-radius: ${p => p.theme.borderRadius.large};
  box-shadow: ${p => p.theme.boxShadow.small};
  margin: 8px 16px 12px 16px;
  padding: 24px 16px 20px 16px;
  position: relative;
`;

const ButtonLink = styled.a`
  ${OutlineButtonBase}
`;

const CloseIcon = styled.div`
  color: ${p => p.theme.colors.gray400};
  cursor: pointer;
  height: 16px;
  width: 16px;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;

  svg {
    fill: ${p => p.theme.colors.gray600};
    height: 16px;
    width: 16px;
  }

  &:hover {
    svg {
      fill: ${p => p.theme.colors.gray700};
    }
  }
`;
