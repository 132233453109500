import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M8 0.5C8.27614 0.5 8.5 0.723858 8.5 1V4C8.5 4.27614 8.27614 4.5 8 4.5C7.72386 4.5 7.5 4.27614 7.5 4V1C7.5 0.723858 7.72386 0.5 8 0.5ZM11.3536 5.35355L13.3536 3.35355C13.5488 3.15829 13.5488 2.84171 13.3536 2.64645C13.1583 2.45118 12.8417 2.45118 12.6464 2.64645L10.6464 4.64645C10.4512 4.84171 10.4512 5.15829 10.6464 5.35355C10.8417 5.54882 11.1583 5.54882 11.3536 5.35355ZM12 7.5C11.7239 7.5 11.5 7.72386 11.5 8C11.5 8.27614 11.7239 8.5 12 8.5H15C15.2761 8.5 15.5 8.27614 15.5 8C15.5 7.72386 15.2761 7.5 15 7.5H12ZM11.6423 14.7186C11.5341 14.8444 11.3858 14.9293 11.2223 14.9588H11.2215C10.4828 15.089 8.30179 15.086 4.87579 12.1662L3.82204 11.1149C0.894789 7.69678 0.912039 5.51107 1.04329 4.77327C1.07287 4.6103 1.15758 4.46239 1.28329 4.35423L2.65204 3.17196C2.7898 3.0522 2.969 2.99089 3.15143 3.0011C3.33385 3.0113 3.50506 3.09222 3.62854 3.22658L5.27029 4.99176C5.3958 5.12768 5.4661 5.30536 5.46749 5.49017C5.46887 5.67497 5.40125 5.85368 5.27779 5.99146L4.42504 6.93429C4.33204 7.03854 4.27011 7.16666 4.24625 7.30417C4.2224 7.44169 4.23756 7.58312 4.29004 7.71249C4.46179 8.13901 5.04679 8.97633 5.83054 9.81216L6.17179 10.1818C7.00954 10.9638 7.84879 11.5474 8.27629 11.7188C8.40592 11.7713 8.54772 11.7865 8.68559 11.7627C8.82346 11.7389 8.95189 11.677 9.05629 11.5841L10.0013 10.7325C10.1395 10.6093 10.3187 10.5418 10.5041 10.5432C10.6895 10.5446 10.8677 10.6147 11.004 10.74L12.7725 12.378C12.9074 12.5012 12.9887 12.6722 12.9989 12.8544C13.0091 13.0366 12.9475 13.2155 12.8273 13.353L11.6423 14.7186Z"
      fill="#919191"
      fillRule="evenodd"
    />
  </Icon>
);
