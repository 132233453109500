import qconsole from 'scripts/lib/qconsole';
import Snippet from 'models/answers/snippet';

export default class SnippetUploadGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleUploadAuth({ auth, path }) {
    let upload = this._applyToUpload(path, u => u.start());
    if (!upload) {
      qconsole.log(`Ignored obsolete upload authorization for [${path}]`);
      return;
    }

    this.context.gateways.snippetUpload.upload(path, upload.file, auth);
  }

  handleUploadAuthError({ path, errors }) {
    this._applyToUpload(path, upload => upload.fail({ message: errors[0].detail }));
  }

  handleUploadAbort(path) {
    if (!this.context.stores.snippetComposition.get()) {
      return;
    }

    this.context.gateways.snippetUpload.remove(path);
  }

  handleUploadError(path, error) {
    this._applyToUpload(path, upload => upload.fail(error));
  }

  handleUploadProgress(path, progress) {
    this._applyToUpload(path, upload => upload.setProgress(progress));
  }

  handleUploadSuccess(path) {
    let upload = this._applyToUpload(path, u => u.complete());

    if (!upload) {
      this.context.gateways.snippetUpload.remove(path);
    }
  }

  _applyToUpload(path, update) {
    let { uploadId } = Snippet.getIdsFromPath(path);

    let snippet = this.context.stores.snippetComposition.get();
    if (!snippet) {
      return null;
    }

    let upload = snippet.findAttachmentById(uploadId);
    if (!upload) {
      return null;
    }

    let updatedUpload = update(upload);
    snippet.updateUpload(updatedUpload);
    this.context.stores.snippetComposition.set(snippet);
    this.context.stores.snippetComposition.clearErrors();
    return updatedUpload;
  }
}
