import _ from 'lodash';

import UserCredential from 'models/user_credential';

export default class ValidateUser {
  constructor(context) {
    this.context = context;
  }

  run({ userCredentialType, name, password }) {
    let validationErrors = UserCredential.getValidationErrors(userCredentialType, { name, password });
    if (password) {
      validationErrors = _.union(validationErrors, UserCredential.trimAndValidatePassword(password));
    }

    if (validationErrors.length) {
      this.context.stores.userCredential.setErrors(validationErrors);
    } else {
      this.context.stores.userCredential.clearErrors();
    }
  }
}
