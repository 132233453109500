import createReactClass from 'create-react-class';
import React from 'react';
import moment from 'moment';

const LongDate = createReactClass({
  render() {
    let momentDate = moment(this.props.timestamp);
    let formattedDate = momentDate.format('MMM D, YYYY');
    let isoTime = momentDate.toISOString();

    return (
      <time className="long-date nowrap" dateTime={isoTime} title={formattedDate}>
        {formattedDate}
      </time>
    );
  },
});

export default LongDate;
