/**
 * When we remove a customer from the inbox, we don't immediately remove the item from the store.
 * Instead, we mark it as deleted, and let the next inbox poll response clean up the marked items.
 *
 * Removing an item immediately can conflict with the polling inbox request: if we were to make an
 * inbox request, and then remove the item immediately, the inbox response _might_ come back with
 * that item we just deleted, and it would remain there until the next inbox response comes back
 * with it removed.
 *
 * To avoid this, we mark it as deleted. If we receive the item back in the next inbox request,
 * we can just ignore it.
 */
export default function removeItemFromInbox(context, itemId, removedAt) {
  let inboxItem = context.stores.agentInboxItems.findBy({ id: itemId });
  if (inboxItem) {
    removedAt ? inboxItem.setRemovedAt(removedAt) : inboxItem.setRemovedAtToNow();
    context.stores.agentInboxItems.replace(inboxItem);
  }
}
