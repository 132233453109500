import HighlightedMessages from 'models/highlighted_messages';

export default class HighlightGroupedMessages {
  constructor(context) {
    this.context = context;
  }

  run({ id, customerId, isHighlighted = true }) {
    if (!isHighlighted) {
      this.context.stores.highlightedMessages.set(null);
      return;
    }

    if (!id || !customerId) {
      return;
    }

    const insertedItem = HighlightedMessages.create({ id, customerId });
    this.context.stores.highlightedMessages.set(insertedItem);
  }
}
