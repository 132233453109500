import React from 'react';
import styled, { css } from 'styled-components';

export default function Hamburger({ isOpen, onClick }) {
  return (
    <Cheese data-aid="hamburger" onClick={onClick}>
      <Patty isOpen={isOpen} />
    </Cheese>
  );
}

const Cheese = styled.div`
  cursor: pointer;
  height: 18px;
  position: relative;
  width: 24px;
`;

const Patty = styled.div`
  &,
  &:after,
  &:before {
    pointer-events: none;
    display: block;
    content: '';
    width: 100%;
    border-radius: 1px;
    background-color: ${p => p.theme.colors.black};
    height: 1px;
    position: absolute;
    transform: rotate(0);
  }

  & {
    top: 8px;
  }
  &:after {
    left: 0;
    top: -8px;
  }
  &:before {
    left: 0;
    top: 8px;
  }

  &,
  &:after,
  &:before {
    transition: 0.2s top 0.2s, 0.1s left, 0.2s transform, 0.4s background-color 0.2s;
  }

  ${p => p.isOpen && openLines};
`;

const openLines = css`
  & {
    background-color: transparent;
  }
  &,
  &:after,
  &:before {
    transition: 0.2s background-color, 0.2s top, 0.2s left, 0.2s transform 0.15s;
  }
  &:before,
  &:after {
    left: 0;
    top: 0px;
  }
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;
