import _ from 'lodash';
import Inbox from 'models/location/inbox';

export default class SwitchInboxSort {
  constructor(context) {
    this.context = context;
  }

  run(sortOrder) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof Inbox)) {
      throw new Error(
        `Cannot change inbox sort order when current location is [${_.get(currentLocation, 'constructor.name')}]`
      );
    }

    let newInbox = currentLocation.deriveNew({ sortOrder });
    this.context.router.navigateTo(newInbox);
  }
}
