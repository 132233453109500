import _ from 'lodash';
import getComposition from 'actions/customer/lib/get_composition';
import updateComposition from 'actions/composition/lib/update_composition';

export default class UpdateComposition {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, newContent, customerId }) {
    const composition = getComposition(this.context, customerId, compositionId);
    if (!composition) {
      return;
    }

    if (_.isEqual(composition.content, newContent)) {
      return;
    }

    updateComposition(this.context, composition, newContent);
  }
}
