import createModel, { prop } from 'models/lib/create_model';

export default createModel({
  modelName: 'PerformExternalAction',

  properties: {
    customerId: prop(String).isRequired,
    action: prop(Object),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
