import Immutable from 'immutable';

/**
 * @param entityImmutables
 * @returns an OrderedMap indexed by `id` and sorted in the same order as the passed in `entityImmutables`
 */
export default function orderedMapFromEntityImmutables(entityImmutables) {
  return Immutable.OrderedMap().withMutations(orderedMap => {
    return entityImmutables.reduce((memo, item) => {
      return memo.set(item.get('id'), item);
    }, orderedMap);
  });
}
