import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { RoutingPreferenceGroup } from 'models/agent_routing_preferences';
import Tooltip from 'components/common/tooltip';

class AgentStatusTooltip extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['handleHoverStart', 'handleHoverEnd']);

    this.state = { isHovered: false };
  }

  handleHoverStart() {
    this.setState({ isHovered: true });
  }

  handleHoverEnd() {
    this.setState({ isHovered: false });
  }

  render() {
    const { excludeTooltip, message, preferenceGroup } = this.props;
    return (
      <Tooltip
        className={`agentStatus-tooltip agentStatus-tooltip-${_.camelCase(preferenceGroup).toLowerCase()}`}
        delay={50}
        margin={-5}
        message={excludeTooltip ? null : message}
        onHoverEnd={this.handleHoverEnd}
        onHoverStart={this.handleHoverStart}
        position="bottom"
      >
        {this.props.children({ isHovered: this.state.isHovered, handleHoverEnd: this.handleHoverEnd })}
      </Tooltip>
    );
  }
}

AgentStatusTooltip.propTypes = {
  children: PropTypes.func.isRequired,
  excludeTooltip: PropTypes.bool,
  message: PropTypes.string,
  preferenceGroup: PropTypes.oneOf(_.values(RoutingPreferenceGroup)).isRequired,
};

export default AgentStatusTooltip;
