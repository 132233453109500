import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('User').extend('Id');

Factory.define('UserWithActivation')
  .extend('User')
  .attr('activationSentAt', () => new Date().toISOString())
  .attr('activatedAt', () => new Date().toISOString());

Factory.define('UserWithDefaults')
  .extend('UserWithActivation')
  .attr('username', ['id'], id => `${id}@foo.com`)
  .attr('roleIds', ['agent', 'system-admin']);

Factory.define('EditableUser').attr('roleIds', ['agent']);
