import _ from 'lodash';
import DtoConverter from 'scripts/application/dto_converters/audience_converter';
import qconsole from 'scripts/lib/qconsole';
import Audience from 'models/audience';

export default class AudienceGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.audiences;
  }

  onFetchAllSuccess(dtos) {
    this.store.resetLoading();
    this.store.set(_.map(dtos, dto => DtoConverter.fromDto(dto)));
  }

  onFetchAllError(error) {
    this.store.resetLoading();
    qconsole.log(`Could not fetch all audiences: ${error}`);
  }

  onBroadcast(audience) {
    this.store.addOrReplace(Audience.fromJs(audience));
  }
}
