import { Factory } from 'rosie';
import '../conversation_item_factory';
import { fbMessageId } from '../lib/fb_message_id';

Factory.define('FbMessageIncoming')
  .attr('type', 'FB_MESSAGE_INCOMING')
  .attr('message', {});

Factory.define('FbMessageIncomingWithText')
  .extend('FbMessageIncoming')
  .attr('recipient', () => Factory.attributes('FbPageWithDefaults'))
  .attr('sender', () => Factory.attributes('FbMessengerUserWithDefaults'))
  .attr('timestamp', () => Date.now())
  .attr('message', () => Factory.attributes('FbMessageWithText'));

Factory.define('FbMessageIncomingWithDefaults').extend('FbMessageIncomingWithText');

Factory.define('FbMessageIncomingItem')
  .extend('ConversationItem')
  .attr('content', () => Factory.attributes('FbMessageIncoming'));

Factory.define('FbMessageIncomingItemWithText')
  .extend('ConversationItemWithDefaults')
  .attr('content', () => Factory.attributes('FbMessageIncomingWithText'));

Factory.define('FbMessageIncomingItemWithDefaults').extend('FbMessageIncomingItemWithText');

/**
* Factory for `message` attribute
*/
Factory.define('FbMessage').attr('mid', () => `mid.${Date.now()}:41d102a3e1ae206a38`);

Factory.define('FbMessageWithText')
  .extend('FbMessage')
  .attr('text', 'hello, world');

Factory.define('FbPage');

Factory.define('FbPageWithDefaults')
  .extend('FbPage')
  .attr('id', () => fbMessageId(12));

Factory.define('FbMessengerUser');

Factory.define('FbMessengerUserWithDefaults')
  .extend('FbMessengerUser')
  .attr('id', () => fbMessageId(16));
