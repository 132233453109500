import _ from 'lodash';

import { AutomaticConversationItemTypes } from 'models/conversation_item_type';
import Communicator from 'models/communicator';
import CustomerConverter from 'scripts/application/dto_converters/customer_converter';
import escapeStringRegexp from 'escape-string-regexp';
import Factory from 'factories/all';
import { getDefaultTopicName } from 'components/lib/topic_name';
import HitTypes from 'models/search/hit_types';

const HITS_MAX_LENGTH = 25;

export function agentSearch(agents, routingGroups, orgId, envelope) {
  let hits = [];
  let query = envelope.payload.query.text.toLowerCase();
  let agentMatches = agents.filter(a => a.name.toLowerCase().indexOf(query) !== -1);
  agentMatches.forEach(a => {
    routingGroups.forEach(g => {
      hits.push({
        id: `agent${a.id}group${g.id}`,
        agent: {
          id: a.id,
          name: a.name,
          email: a.email,
        },
        routingGroup: {
          id: g.id,
          name: g.name,
        },
        matchingText: query,
      });
    });
  });
  return {
    id: envelope.correlationId,
    hits,
  };
}

function getLatestManualItem(items) {
  let manualItems = _.filter(
    items,
    item =>
      (item.initiator || {}).type !== Communicator.AUTOMATED &&
      AutomaticConversationItemTypes.indexOf(item.contentType()) === -1
  );
  return _.maxBy(manualItems, 'timestamp');
}

export let searchCustomers = function(customers, topics, orgId, envelope) {
  let hits = [];
  let query = envelope.payload.query.text.toLowerCase();
  customers.forEach(function(c) {
    let customer = CustomerConverter.fromDto(c);
    if (!customer.profile || !customer.profile.name) {
      return;
    }
    let match = customer.profile.name.toLowerCase().indexOf(query);

    if (match === -1) {
      return;
    }

    let conversation = _.last(customer.conversations);
    if (!conversation) {
      return;
    }

    let latestManualItem = getLatestManualItem(customer.conversationHistory);
    let content = latestManualItem.content;
    let conversationText =
      content.content ||
      content.text ||
      content.message ||
      content.bodyPlain ||
      content.bodyHtml ||
      content.subject ||
      content.hotel ||
      content.restaurant ||
      content.customerNumber ||
      content.requestedTime ||
      '';

    let topic = getDefaultTopicName(customer.conversationHistory[0], content);

    hits.push(
      Factory.build('ConversationItemSearchHit', {
        id: conversation.id,
        customer: {
          id: customer.id,
          name: highlightText(customer.profile.name, query),
        },
        matchingText: conversationText,
        topic,
      })
    );
  });
  return {
    hits,
    query: envelope.payload.query,
    requestedAt: envelope.payload.requestedAt,
  };
};

let CachingCustomerConverter = { customers: {} };
CachingCustomerConverter.fromDto = function(customer) {
  let key = JSON.stringify(customer);
  if (!this.customers[key]) {
    this.customers[key] = CustomerConverter.fromDto(customer);
  }

  return this.customers[key];
};

// Universal Search

export const searchAll = function(customers, agents, routingGroups, topics, orgId, envelope) {
  const { query, requestedAt } = envelope.payload;
  let type = query.filter ? query.filter.type : null;
  let from = envelope.payload.query.from ? envelope.payload.query.from : 0;
  let size = envelope.payload.query.size ? envelope.payload.query.size : HITS_MAX_LENGTH;
  const searchTerm = query.text ? query.text.toLowerCase() : '';
  const customerHits = getCustomerHits(customers, searchTerm);
  const conversationHits = getConversationHits(customers, agents, routingGroups, topics, searchTerm);
  let hits;
  if (type === 'conversation') {
    hits = conversationHits.slice(from, size + from);
  } else if (type === 'customer') {
    hits = customerHits.slice(from, size + from);
  } else {
    hits = customerHits.slice(from, size + from);
    if (hits.length < size) {
      hits = hits.concat(conversationHits.slice(0, size - hits.length));
    }
  }
  const aggregations = {
    types: {
      total: conversationHits.length + customerHits.length,
      buckets: {
        customer: customerHits.length,
        conversation: conversationHits.length,
      },
    },
  };

  return { hits, query, requestedAt, aggregations };
};

function getCustomerHits(customers, searchTerm) {
  let matchingCustomers = customers.filter(c => {
    const customer = CachingCustomerConverter.fromDto(c);
    if (!customer.profile) {
      return false;
    }
    const customerPhones = customer.profile.phones.map(p => p.normalized);
    const phoneMatches = _.some(customerPhones, phone => phone.indexOf(searchTerm) !== -1);
    if (phoneMatches) {
      return true;
    }

    const picked = _.pick(c.profile, ['name', 'email', 'address']);
    return (
      _.values(picked)
        .join(' ')
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase()) !== -1
    );
  });

  return matchingCustomers.map(c => {
    const customer = CachingCustomerConverter.fromDto(c);
    const conversationItem = getLatestManualItem(customer.conversationHistory);

    return {
      id: customer.id,
      name: highlightText(customer.profile.name, searchTerm),
      email: highlightText(customer.profile.getDefaultEmail(), searchTerm),
      address: highlightText(customer.profile.address, searchTerm),
      conversation: _.last(c.conversations),
      conversationItem: conversationItem && conversationItem.toJs(),
      phone: highlightText(customer.profile.getPrimaryPhoneNumberNormalized(), searchTerm),
      type: HitTypes.CUSTOMER_PROFILE,
    };
  });
}

const SUBCONTENTS = [
  'content',
  'text',
  'message',
  'bodyPlain',
  'bodyHtml',
  'subject',
  'hotel',
  'restaurant',
  'phoneNumber',
  'requestedTime',
  'message.text',
];

function getConversationHits(customers, agents, routingGroups, topics, searchTerm) {
  let hits = [];
  _.forEach(customers, c => {
    let { conversations, conversationHistory, id, profile } = CachingCustomerConverter.fromDto(c);

    let conversation = _.last(conversations);
    if (!conversation) {
      return;
    }
    const latestManualItem = getLatestManualItem(conversationHistory);
    if (!latestManualItem) {
      return;
    }
    const content = latestManualItem.content;
    const topic = getDefaultTopicName(conversationHistory[0], latestManualItem);

    const matchingSub = _.find(SUBCONTENTS, subcontent => {
      let contentText = _.get(content, subcontent);
      return contentText && typeof contentText === 'string' && matchesTerm(contentText, searchTerm);
    });

    const agent = _.find(agents, agent => agent.id === conversation.assignee.agentId);
    const agentName = agent && agent.name;
    const routingGroupName = _.find(
      routingGroups,
      routingGroup => routingGroup.id === conversation.assignee.routingGroupId
    ).name;

    const matchingTopic = matchesTerm(topic, searchTerm);
    const matchingGroup = matchesTerm(routingGroupName, searchTerm);
    const matchingAgent = matchesTerm(agentName, searchTerm);

    if (matchingTopic || matchingSub || matchingGroup || matchingAgent) {
      const matchingText = _.get(content, matchingSub);

      hits.push({
        id: conversation.id,
        customer: {
          id,
          name: highlightText(profile.name, searchTerm),
          email: highlightText(profile.getDefaultEmail(), searchTerm),
          phone: highlightText(profile.getPrimaryPhoneNumberNormalized(), searchTerm),
        },
        assignee: {
          agent: highlightText(agentName, searchTerm),
          group: highlightText(routingGroupName, searchTerm),
        },
        matchingText: highlightText(matchingText, searchTerm),
        topic: highlightText(topic, searchTerm),
        type: HitTypes.CONVERSATION,
      });
    }
  });
  return hits;
}

function matchesTerm(text, term) {
  return text && text.toLowerCase().indexOf(term.toLowerCase()) !== -1;
}

export const highlightText = function(text, term) {
  if (!text) {
    return null;
  }
  if (!term) {
    return text;
  }
  const escapedTerm = escapeStringRegexp(term);
  return text.replace(new RegExp(escapedTerm, 'ig'), term => `<mark class='highlightedSearchTerm'>${term}</mark>`);
};
