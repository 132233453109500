import _ from 'lodash';
import parseTopicParams from './parse_topic_params';

const HTTP_OBSERVERS = [
  'onFetchAllSuccess',
  'onFetchSuccess',
  'onAddSuccess',
  'onReplaceSuccess',
  'onUpdateSuccess',
  'onDeleteSuccess',
];

const ERROR_OBSERVERS = [
  'onFetchAllError',
  'onFetchError',
  'onAddError',
  'onReplaceError',
  'onUpdateError',
  'onDeleteError',
];

/**
 * ObserverAdapter wraps the observer methods so that they can be called more
 * cleanly in the standard gateway code.
 *
 * In particular, it takes care of:
 *   1. binding the methods so they don't lose their `this` context
 *   2. parsing parameters from mqtt broadcast topics so that they are available
 *      to the the callback.
 */
export default class ObserverAdapter {
  /**
   * Creates an new ObserverAdapter.
   *
   * @param {Object} observer - an object which contains the observer callbacks
   * to adapt (e.g. `{ onBroadcast, onBroadcastDelete, onFetchSuccess }`)
   *
   * @param {Object} [patterns] - mqtt topic patterns that are used to extract
   * params from broadcast topics
   * @param {string} [patterns.broadcastTopic] - the broadcast topic
   * @param {string} [patterns.broadcastDeleteTopic] - the delete broadcast topic
   *
   * @example
   * let observer = new ObserverAdapter({
   *    onBroadcast: (customerDto, { orgId, customerId }) => replaceCustomer(customerId, customerDto),
   *    onFetchSuccess: (customerDto, { orgId, customerId }) => replaceCustomer(customerId, customerDto),
   * }, {
   *    broadcastTopic: 'orgs/:orgId/customers/:customerId',
   * })
   */
  constructor(observer, patterns = {}) {
    // handle http observers
    _.forEach(HTTP_OBSERVERS, callbackName => {
      if (_.isFunction(observer[callbackName])) {
        this[callbackName] = observer[callbackName].bind(observer);
      } else {
        this[callbackName] = _.identity;
      }
    });
    _.forEach(ERROR_OBSERVERS, callbackName => {
      if (_.isFunction(observer[callbackName])) {
        this[callbackName] = observer[callbackName].bind(observer);
      } else {
        this[callbackName] = err => {
          throw err;
        };
      }
    });

    // handle mqtt observers
    if (observer.onBroadcast) {
      this.onBroadcast = (envelope = {}, topic) =>
        observer.onBroadcast(envelope.payload, parseTopicParams(patterns.broadcastTopic, topic));
    }

    if (observer.onBroadcastDelete) {
      this.onBroadcastDelete = (envelope = {}, topic) =>
        observer.onBroadcastDelete(envelope.payload, parseTopicParams(patterns.broadcastDeleteTopic, topic));
    }
  }
}
