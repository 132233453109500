import createModel, { prop } from './lib/create_model';

const Mention = createModel({
  modelName: 'Mention',

  properties: {
    id: prop(String).isRequired,
    name: prop(String).isRequired,
    type: prop(String),
  },
});

export default Mention;
