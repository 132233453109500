import React from 'react';
import PropTypes from 'prop-types';

import PhoneCall from 'models/phone_call';

const IncomingCallNotificationContent = ({
  customerDisplayName,
  incomingCallType,
  routingGroupName,
  transferringAgentName,
}) => {
  return (
    <div className="conversationNotification-topic">
      <i className="conversationNotification-icon fa fa-phone" />
      <div className="conversationNotification-topic-heading incomingCall-topic-heading">
        {INCOMING_CALL_TYPE_TEXT[incomingCallType]}
      </div>
      <div className="conversationNotification-divider" />
      {renderMessage(customerDisplayName, incomingCallType, routingGroupName, transferringAgentName)}
    </div>
  );
};

const renderMessage = (customerDisplayName, incomingCallType, routingGroupName, transferringAgentName) => {
  if (incomingCallType !== PhoneCall.IncomingCallType.WARM_TRANSFER) {
    return (
      <div className="conversationNotification-topic-body incomingCall-topic-body">
        <span>
          From <span className="incomingCall-topic-body-customerName fs-mask">{customerDisplayName}</span>
          {routingGroupName ? ` to ${routingGroupName}` : null}
        </span>
      </div>
    );
  }
  return (
    <div className="conversationNotification-topic-body">
      {transferringAgentName}
      <span className="incomingWarmTransfer-notification-body"> is transferring </span>
      <span className="fs-mask">{customerDisplayName}</span>
      <span className="incomingWarmTransfer-notification-body"> to you</span>
      <span>{routingGroupName ? ` from ${routingGroupName}` : null}</span>
    </div>
  );
};

export const INCOMING_CALL_TYPE_TEXT = Object.freeze({
  [PhoneCall.IncomingCallType.BLIND_TRANSFER]: 'Cold Transfer',
  [PhoneCall.IncomingCallType.INCOMING]: 'Incoming Call',
  [PhoneCall.IncomingCallType.WARM_TRANSFER]: 'Warm Transfer',
});

IncomingCallNotificationContent.propTypes = {
  customerDisplayName: PropTypes.string.isRequired,
  incomingCallType: PropTypes.string.isRequired,
  routingGroupName: PropTypes.string,
  transferringAgentName: PropTypes.string,
};

export default IncomingCallNotificationContent;
