import createModel from './lib/create_model';

const FileDescriptor = createModel({
  modelName: 'FileDescriptor',

  properties: {
    contentType: String,
    contentLength: Number,
    filename: String,
  },

  statics: {
    createFromFile(file) {
      return new this({
        filename: file.name || '',
        contentType: file.type,
        contentLength: file.size,
      });
    },
  },
});

export default FileDescriptor;
