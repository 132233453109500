import CloseAnswerPanel from 'actions/answers/close_answer_panel';
import CloseCommentPanel from 'actions/task/close_comment_panel';

export default class CloseSidePanels {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    this.context.executeAction(CloseCommentPanel);

    const answerPanel = this.context.stores.answerPanel.get();
    if (answerPanel.isOpen()) {
      this.context.executeAction(CloseAnswerPanel, attrs);
    }
  }
}
