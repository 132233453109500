import _ from 'lodash';

import changeComposition from 'actions/composition/lib/change_composition';
import CompositionConverter from 'scripts/application/dto_converters/composition_converter';
import CurrentCompositionUpdater from 'actions/lib/current_composition_updater';
import CustomerView from 'models/location/customer_view';
import getComposition from 'actions/customer/lib/get_composition';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import qconsole from 'scripts/lib/qconsole';

export default class CompositionSubscriberGatewayObserver {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
  }

  getStore(customerId) {
    return getCompositionsStore(this.context, customerId);
  }

  onBroadcast(dto) {
    const composition = CompositionConverter.fromDto(dto);
    const compositionsStore = getCompositionsStore(this.context, composition.customerId);
    if (!compositionsStore) {
      return;
    }

    // Ignore compositions that are in the process of being deleted
    if (compositionsStore.has({ id: composition.id }) && compositionsStore.isPendingDelete(composition.id)) {
      return;
    }

    const existingComposition = getComposition(this.context, composition.customerId, composition.id);

    // Don't do anything if we receive an out of date updated composition
    if (existingComposition && composition._version <= existingComposition._version) {
      return;
    }

    qconsole.log(`Composition [${composition.id}] broadcasted for customer [${composition.customerId}]`);

    // When we save compositions, we don't include incomplete uploads (because if we load the composition from another window,
    // we won't have the File object available). But we also don't want to lose incomplete attachments for our current compositions
    // when we save. So we "merge" the incomplete attachments from the local composition back into the received composition.
    if (existingComposition) {
      composition.mergeIncompleteUploads(existingComposition.getUploads());
      compositionsStore.addOrReplace(composition);
    }
    // Otherwise, if we've received a brand new composition for this customer, we either created this composition somewhere else
    // or this customer was merged with another.
    else {
      compositionsStore.addOrReplace(composition);
      this.currentCompositionUpdater.update();
    }
  }

  onBroadcastDelete({ customerId }, { compositionId }) {
    const compositionsStore = this.getStore(customerId);

    if (!compositionsStore) {
      return;
    }

    let composition = compositionsStore.findBy({ id: compositionId });
    if (composition) {
      qconsole.log(`Composition delete [${composition.id}] broadcasted for customer [${composition.customerId}]`);

      compositionsStore.remove(compositionId);

      let currentLocation = this.context.stores.currentLocation.get();
      if (
        currentLocation instanceof CustomerView &&
        currentLocation.isInComposition(composition.customerId, compositionId)
      ) {
        let otherCustomerCompositions = compositionsStore.findAll();
        const filteredCompositions = _.filter(otherCustomerCompositions, c => {
          return !compositionsStore.isPendingDelete(c.id);
        });
        if (filteredCompositions.length) {
          let nextComposition = _.last(
            _.sortBy(filteredCompositions, comp => comp.updatedAt && Date.parse(comp.updatedAt))
          );
          changeComposition(this.context, nextComposition);
        } else {
          changeComposition(this.context, null);
        }
      }
    }
  }
}
