import styled from 'styled-components';
import { Item } from 'components/text_editor_new/components/controls/controls_new';
import { FontColorMenuIcon } from 'components/customer/composition/lib/slate/slate_font_color_menu';

export const ColorSwatch = styled.button`
  background-color: ${props => props.color};
  width: 24px;
  height: 24px;
  border-radius: 2px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
`;

export const ColorsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.25rem;
`;

export const ColorPickerWrapper = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
`;

export const ClearButton = styled.button`
  display: inline-flex;
  background-color: transparent;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-width: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  &:hover {
    color: ${p => p.theme.colors.green400};
    background-color: unset;
  }
`;

export const ToggleButton = styled(Item)`
  width: 40px;
`;

export const StyledFontColorMenuIcon = styled(FontColorMenuIcon)`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
`;
