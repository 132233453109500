import analytics from 'scripts/lib/analytics';

import CloseModal from 'actions/modal/close_modal';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class CustomerHistoryRedactionGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onAddSuccess(dto, { customerId, itemId }) {
    analytics.track('conversation item redaction - success', { customerId, itemId });
    this.context.executeAction(CloseModal);
    this.context.executeAction(ShowToast, {
      type: ToastType.SUCCESS,
      message: 'Message deleted.',
    });
  }

  onReplaceError(error, { customerId, itemId }) {
    analytics.track('conversation item redaction - error', { customerId, itemId });
    this.context.executeAction(CloseModal);
    this.context.executeAction(ShowToast, {
      type: ToastType.ERROR,
      message: 'This message cannot be deleted. Try again later.',
    });
  }
}
