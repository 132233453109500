import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('EmailSendFailure').attr('type', 'EMAIL_SEND_FAILURE');

Factory.define('EmailSendFailureWithDefaults')
  .extend('EmailSendFailure')
  .attr('messageId', 'id-123')
  .attr('recipient', 'b.cooper@fbi.gov')
  .attr('code', 550)
  .attr('recipientDetail', 'Mailbox does not exist')
  .attr('senderDetail', 'Mailbox does not exist');

Factory.define('EmailSendFailureItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('EmailSendFailure', content));

Factory.define('EmailSendFailureItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('EmailSendFailureWithDefaults', content));
