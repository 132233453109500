import createModel, { prop } from '../lib/create_model';
import LiveboardType from 'models/liveboards/liveboard_type';
import OldestUnanswered from 'models/liveboards/oldest_unanswered';

export const AgentStats = createModel({
  modelName: 'AgentStats',
  properties: {
    ready: Number,
    busy: Number,
    away: Number,
    online: Number,
    readyForPhone: Number,
    readyForChat: Number,
    readyForNonInteractive: Number,
    onCall: Number,
    onlineForPhone: Number,
    onlineForChat: Number,
    onlineForNonInteractive: Number,
    freeChatCapacity: Number,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const ChatStats = createModel({
  modelName: 'ChatStats',
  properties: {
    currentNewChats: Number,
    currentActiveChats: Number,
    dailyTotalChats: Number,
    currentWait: Number,
    dailyAverageChatTime: Number,
    dailyAverageResponseTime: Number,
    oldestUnanswered: OldestUnanswered,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const EmailStats = createModel({
  modelName: 'EmailStats',
  properties: {
    currentNewEmails: Number,
    currentOpenEmails: Number,
    dailyTotalResponses: Number,
    currentWait: Number, // milliseconds
    dailyAverageResponseTime: Number, // milliseconds
    oldestUnanswered: OldestUnanswered,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const PhoneStats = createModel({
  modelName: 'PhoneStats',
  properties: {
    currentNewCalls: Number,
    currentActiveCalls: Number,
    dailyTotalCalls: Number,
    dailyOutgoingCalls: Number,
    currentWait: Number, // milliseconds
    dailyAverageCallTime: Number, // milliseconds
    dailyAverageResponseTime: Number, // milliseconds
    oldestUnanswered: OldestUnanswered,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const SmsStats = createModel({
  modelName: 'SmsStats',
  properties: {
    currentNewSms: Number,
    currentOpenSms: Number,
    dailyTotalResponses: Number,
    currentWait: Number, // milliseconds
    dailyAverageResponseTime: Number, // milliseconds
    oldestUnanswered: OldestUnanswered,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const FBMessageStats = createModel({
  modelName: 'FBMessageStats',
  properties: {
    currentNewMessages: Number,
    currentOpenMessages: Number,
    dailyTotalResponses: Number,
    currentWait: Number, // milliseconds
    dailyAverageResponseTime: Number, // milliseconds
    oldestUnanswered: OldestUnanswered,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const MessagingStats = createModel({
  modelName: 'MessagingStats',
  properties: {
    currentNewMessages: Number,
    currentOpenMessages: Number,
    dailyTotalResponses: Number,
    currentWait: Number, // milliseconds
    dailyAverageResponseTime: Number, // milliseconds
    oldestUnanswered: OldestUnanswered,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const ChannelStats = createModel({
  modelName: 'ChannelStats',
  properties: {
    email: EmailStats,
    phone: PhoneStats,
    sms: SmsStats,
    fbMessage: FBMessageStats,
    chat: ChatStats,
    instagramDirect: MessagingStats,
    twitter: MessagingStats,
    whatsapp: MessagingStats,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const ConversationStats = createModel({
  modelName: 'ConversationStats',
  properties: {
    openAssigned: Number,
    openUnassigned: Number,
    waiting: Number,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const SlaStats = createModel({
  modelName: 'SlaStats',
  properties: {
    over: Number,
    total: Number,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const SummaryLiveboardSla = createModel({
  modelName: 'SummaryLiveboardSla',
  properties: {
    email: SlaStats,
    phone: SlaStats,
    sms: SlaStats,
    fbMessage: SlaStats,
    chat: SlaStats,
    instagramDirect: SlaStats,
    twitter: SlaStats,
    whatsapp: SlaStats,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const TrendStats = createModel({
  modelName: 'TrendStats',
  properties: {
    timestamp: String,
    newConversations: Number,
    closedConversations: Number,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default createModel({
  modelName: 'SummaryLiveboard',
  properties: {
    agent: AgentStats,
    channel: ChannelStats,
    conversation: ConversationStats,
    sla: SummaryLiveboardSla,
    trend: [TrendStats],
    timezone: prop(String).default('America/New_York'),
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: LiveboardType.SUMMARY });
  },
});
