import React, { createContext, useMemo, useState } from 'react';

export const DEFAULT_EMAIL_ITEM_CONTEXT = {
  showHtmlModal: false,
};

const EmailItemContext = createContext(DEFAULT_EMAIL_ITEM_CONTEXT);
export default EmailItemContext;

export function EmailItemContextProvider({ children }) {
  const [showHtmlModal, setShowHtmlModal] = useState(false);

  const value = useMemo(() => ({ showHtmlModal, setShowHtmlModal }), [showHtmlModal]);
  return <EmailItemContext.Provider value={value}>{children}</EmailItemContext.Provider>;
}
