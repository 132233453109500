import _ from 'lodash';

import { getUrl } from 'scripts/adapters/lib/url';

export function getLocationUrl(location) {
  let converter = getConverter(location);

  let queryParams = _.isFunction(converter.getQueryParams) ? converter.getQueryParams(location) : {};
  const hasCustomUrlFormat = converter.generateUrl;
  return hasCustomUrlFormat
    ? converter.generateUrl(converter.getPath(location), queryParams)
    : getUrl(converter.getPath(location), queryParams);
}

function getConverter(location) {
  let converter = location && urlConverters.get(location.constructor);
  if (!converter) {
    throw new Error(`[${_.get(location, 'constructor.name')}] does not have a URL converter`);
  }

  return converter;
}

let urlConverters = new Map();

export function registerUrlConverter(Model, converter) {
  urlConverters.set(Model, converter);
}
