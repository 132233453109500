import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const DATEFORMAT = 'MMMM Do';
const DATEFORMATWITHYEAR = 'MMMM Do, YYYY';

export default function ReportInfo({ startDate, endDate, children }) {
  let dateRange = null;
  if (startDate && endDate) {
    let isSameDate = moment(startDate).isSame(endDate, 'day');
    let dateFormat = moment(startDate).isSame(endDate, 'year') ? DATEFORMAT : DATEFORMATWITHYEAR;

    let start = !isSameDate ? moment.utc(startDate).format(dateFormat) + ' -' : '';
    let end = moment.utc(endDate).format(DATEFORMATWITHYEAR);

    dateRange = (
      <span className="reportInfo-dates">
        <div className="reportInfo-dateLabel">{isSameDate ? 'Date Picked' : 'Dates Picked'}</div>
        {start && <div className="reportInfo-date">{start}</div>}
        {end && <div className="reportInfo-date">{end}</div>}
      </span>
    );
  }
  return (
    <div className="reportInfo">
      {dateRange}
      {children}
    </div>
  );
}

ReportInfo.propTypes = {
  children: PropTypes.node,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
};
