import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Checklist from 'components/lib/checklist';
import { Channel } from 'models/search/search_filter';

const channelOptions = [
  {
    id: Channel.CHAT_MESSAGE,
    label: 'Chat',
  },
  {
    id: Channel.EMAIL,
    label: 'Email',
  },
  {
    id: Channel.FB_MESSAGE,
    label: 'Facebook',
  },
  {
    id: Channel.INSTAGRAM_DIRECT,
    label: 'Instagram Messaging',
  },
  {
    id: Channel.PHONE_CALL,
    label: 'Phone',
  },
  {
    id: Channel.SMS,
    label: 'SMS',
  },
  {
    id: Channel.TWITTER,
    label: 'Twitter',
  },
  {
    id: Channel.VOICEMAIL,
    label: 'Voicemail',
  },
  {
    id: Channel.WHATSAPP,
    label: 'WhatsApp',
  },
];

const ChannelFilter = function({
  customChannels,
  isFbMessengerEnabled,
  isChatEnabled,
  isInstagramDirectEnabled,
  isWhatsAppEnabled,
  isTwitterEnabled,
  onChange,
  selectedChannels,
}) {
  let customChannelOptions = customChannels.map(channel => {
    const channelId = `${Channel.CUSTOM_CHANNEL}:${channel.id}`;
    return { value: channelId, label: channel.name, isSelected: selectedChannels.indexOf(channelId) > -1 };
  });

  let options = channelOptions
    .filter(channel => isFbMessengerEnabled || channel.id !== Channel.FB_MESSAGE)
    .filter(channel => isChatEnabled || channel.id !== Channel.CHAT_MESSAGE)
    .filter(channel => isInstagramDirectEnabled || channel.id !== Channel.INSTAGRAM_DIRECT)
    .filter(channel => isWhatsAppEnabled || channel.id !== Channel.WHATSAPP)
    .filter(channel => isTwitterEnabled || channel.id !== Channel.TWITTER)
    .map(channel => {
      return { value: channel.id, label: channel.label, isSelected: selectedChannels.indexOf(channel.id) > -1 };
    })
    .concat(customChannelOptions)
    .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));

  return <Checklist onChange={onChannelChange} options={options} />;

  function onChannelChange(channel, isSelected) {
    let channels = isSelected ? _.union(selectedChannels, [channel]) : _.difference(selectedChannels, [channel]);
    onChange({ channels });
  }
};

ChannelFilter.propTypes = {
  customChannels: PropTypes.array,
  isChatEnabled: PropTypes.bool,
  isFbMessengerEnabled: PropTypes.bool,
  isInstagramDirectEnabled: PropTypes.bool,
  isWhatsAppEnabled: PropTypes.bool,
  isTwitterEnabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ChannelFilter;
