import React from 'react';

import { AGENT_MENTION } from '../../plugins/agent_mention';

export const agentMentionRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'agentmention') {
        return {
          object: 'inline',
          type: AGENT_MENTION,
          nodes: next(el.childNodes),
          data: { agentId: el.getAttribute('data-agentid') },
        };
      }
    },
    serialize(object, children) {
      if (object.object === 'inline' && object.type === AGENT_MENTION) {
        return <agentmention data-agentid={object.data.get('agentId')}>{children}</agentmention>;
      }
    },
  },
];
