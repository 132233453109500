import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import ConversationItem from 'models/conversation_item';
import ConversationItemMenuOpener from './conversation_item_menu_opener';
import MenuIcon from 'components/lib/icons/menu_icon';
import PinToCustomerMenu from 'components/customer/conversation_history/conversation_items_v2/menu/pin_to_customer/pin_to_customer_menu';
import { PortalledPopover } from 'components/common/lib/_popover';
import TrackRelationshipClick from 'scripts/application/actions/conversation_item/pin_item/track_relationship_click';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useWindowSize from 'components/hooks/use_window_size';

export default function ConversationItemMenuDisplayer(props) {
  const { customerId, item } = props;
  const buttonRef = useRef();
  const [isPinToCustomerVisible, setPinToCustomerVisible] = useState(false);
  const { windowHeight, windowWidth } = useWindowSize();
  const executeAction = useExecuteAction();

  const openPinToCustomer = () => {
    setPinToCustomerVisible(true);
    executeAction(TrackRelationshipClick, 'Pin To Customer');
  };
  const closePinToCustomer = () => {
    setPinToCustomerVisible(false);
  };

  return (
    <div className={classnames('conversationItemMenuDisplayer', props.className)}>
      {renderPinToCustomerMenu()}
      {renderConversationItemMenu()}
    </div>
  );

  function renderPinToCustomerMenu() {
    return isPinToCustomerVisible ? (
      <PortalledPopover
        autoPosition
        bounds={{
          bottom: windowHeight - 70,
          right: windowWidth - 10,
          top: 70,
        }}
        className="pinToCustomerMenu"
        isVisible
        position="bottom"
        targetElement={buttonRef.current}
      >
        <PinToCustomerMenu conversationItemId={item.id} currentCustomerId={customerId} onClose={closePinToCustomer} />
      </PortalledPopover>
    ) : null;
  }

  function renderConversationItemMenu() {
    if (item.isLink()) {
      return null;
    }

    return (
      <ConversationItemMenuOpener {...props} onOpenPinToCustomer={openPinToCustomer} renderButton={renderButton} />
    );
  }

  function renderButton(shouldDisplayIcon) {
    const icon = shouldDisplayIcon ? <MenuIcon /> : null;
    return (
      <div className="conversationItemMenu-button-container" onMouseDown={evt => evt.preventDefault()} ref={buttonRef}>
        {icon}
      </div>
    );
  }
}

ConversationItemMenuDisplayer.propTypes = {
  className: PropTypes.string,
  customerId: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem),
};
