import React from 'react';

import PageLayout from './page_layout';
import UniversalSearchContainer from 'components/search/universal_search_container';

export default function() {
  return (
    <PageLayout id="search-page">
      <UniversalSearchContainer />
    </PageLayout>
  );
}
