import { usePlateEditorState } from '@udecode/plate';
import PropTypes from 'prop-types';
import React from 'react';

import EndAndClose from 'components/composer/chat/end_and_close';
import SendButton from 'components/customer/composition/send_button';
import { editorHasNoText } from 'components/text_editor_new/lib/editor_has_no_text';

export default function ChatActions({ disableSubmit, editorId, hasAttachments, onSubmit }) {
  const editor = usePlateEditorState(editorId);

  if (editorHasNoText(editor) && !hasAttachments) {
    return <EndAndClose isEditorEmpty />;
  }

  return (
    <SendButton disabled={disableSubmit} onClick={onSubmit}>
      Send
    </SendButton>
  );
}

ChatActions.propTypes = {
  disableSubmit: PropTypes.bool,
  editorId: PropTypes.string,
  hasAttachments: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
