import analytics from 'scripts/lib/analytics';
import CustomerView from 'models/location/customer_view';

const PAGE_SIZE = 40;

let loadingCustomerId = null;

const loadingItems = new Set();
export { loadingItems };

export default class FetchMoreItems {
  constructor(context) {
    this.context = context;
  }

  run({ feedItems, isManual, renderedRange, scrollDirection }) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    const customerId = currentLocation.customerId;
    if (customerId !== loadingCustomerId) {
      loadingItems.clear();
      loadingCustomerId = customerId;
    }

    let { start, end } = renderedRange;

    /**
     * The idea here is that we want to fetch in pages of PAGE_SIZE rather than incrementally. Let's say PAGE_SIZE is 20,
     * and our start and end are { start: 115, end: 140 }. If we're scrolling up, we would round `start` to the nearest
     * interval of 20: 100. As we keep scrolling up, `start` will get smaller. When it hits 99, it will round to 80, so
     * then we will fetch the next "page", or 20 items.

     * Why do we do this instead of fetching items incrementally? E.g. as we scroll up a couple more items, fetch a
     * couple more items. The main reason is that touching the conversation history store is expensive and triggers a
     * bunch of re-renders. Incremental loading would trigger a lot of fetches and re-renders, and the end result would
     * be a choppier scrolling experience.
     *
     * Conversely, we also can't make the PAGE_SIZE too big because loading too many DTOs at the same into our stores as
     * immutables is also quite slow (esp. in IE11).
     */
    const pageSize = FetchMoreItems.pageSize;
    if (scrollDirection === 'UP') {
      start = Math.max(0, start - (start % pageSize));
    } else if (scrollDirection === 'DOWN') {
      end = Math.min(end + (pageSize - (end % pageSize)), feedItems.length);
    }

    let idsToFetch = [];
    const potentialItems = feedItems.slice(start, end);
    for (let i = 0; i < potentialItems.length; i++) {
      const feedItem = potentialItems[i];
      if (feedItem.type === 'item' && !feedItem.timestamp && !loadingItems.has(feedItem.id)) {
        idsToFetch.push(feedItem.id);
        loadingItems.add(feedItem.id);
      }
    }

    if (!idsToFetch.length) {
      return;
    }

    if (isManual) {
      analytics.track('Conversation Items Requested From Scroll', {
        customerId,
        startIndex: start,
        endIndex: end,
        countItems: idsToFetch.length,
        scrollDirection,
      });
    }

    this.context.gateways.customerHistory.fetchConversationItems(customerId, {
      filter: {
        ids: idsToFetch,
      },
    });
  }
}

FetchMoreItems.pageSize = PAGE_SIZE;
