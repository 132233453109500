import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import OpenCommentPanel from 'actions/task/open_comment_panel';

export default class NavigateToTaskComment {
  constructor(context) {
    this.context = context;
  }

  run({ commentId, customerId, taskId }) {
    if (!customerId) {
      return;
    }

    this.context.executeAction(NavigateToItem, {
      customerId,
      itemId: taskId,
    });

    this.context.executeAction(OpenCommentPanel, {
      itemId: taskId,
      commentId,
    });
  }
}
