import _ from 'lodash';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React, { createElement } from 'react';

import qconsole from 'scripts/lib/qconsole';
import { setDisplayName } from 'scripts/presentation/hoc_helpers/display_name';

const EXCLUDED_PROVIDERS = ['auth'];

let isFeatureEnabled = (getProvider, feature) => {
  let appFeatures = getProvider('appFeatures').get();
  return (appFeatures && appFeatures.isEnabled(feature)) || false;
};

let defaultMergeProps = (stateProps, execProps, ownProps) => Object.assign({}, ownProps, stateProps, execProps);

export default function connect(mapStateToProps, mapExecuteToProps, mergeProps) {
  mergeProps = mergeProps || defaultMergeProps;
  return function(WrappedComponent) {
    let bindingsToObserve = new Map();

    const ConnectedComponent = createReactClass({
      mixins: [Morearty.Mixin],

      contextTypes: {
        executeAction() {},
        getProvider() {},
      },

      getWrappedInstance() {
        return this.wrappedInstance;
      },

      setWrappedInstance(node) {
        this.wrappedInstance = node;
      },

      executeAction(RunnerClass, ...params) {
        this.context.executeAction(RunnerClass, ...params);
      },

      getAndObserveProvider(name) {
        let provider = this.context.getProvider(name);
        if (!provider) {
          throw new Error(`unknown provider [${name}]`);
        }

        if (!_.includes(EXCLUDED_PROVIDERS, name)) {
          if (provider.bindings) {
            bindingsToObserve.set(name, provider.bindings);
          } else {
            qconsole.warn(`Provider [${name}] has no bindings to observe. It needs to be added to EXCLUDED_PROVIDERS.`);
          }
        }
        return provider;
      },

      getProvider(name) {
        if (!_.includes(EXCLUDED_PROVIDERS, name) && !bindingsToObserve.has(name)) {
          qconsole.warn(
            `Accessing provider [${name}] without observing / passing a binding with a path containing [${name}]. Your component may not refresh when it needs to when data changes!`
          );
        }

        // Check if a binding was replaced and start observing the new binding.
        // Details:
        // In case of extended providers such as `profile` or `conversations` the
        // provider instance could be replaced between renders. For instance,
        // when the component is mounted the `profile` provider could be pointing
        // to "Martha Williams" and then while the component is still mounted the
        // current customer could change and the provider will be pointing to "Bill
        // Bellamy". The connect wrapper at this point is still observing the
        // binding for "Martha Williams". It needs to start observing "Bill Bellamy"
        // so that it could re-render in case of future changes to "Bill Bellamy".
        if (bindingsToObserve.has(name)) {
          this.context.getProvider(name).bindings.forEach(binding => {
            // If a binding is already observed it's a no-op
            // Ideally we want to stop observing bindings we no longer care about like "Martha Williams"
            // when we switch to "Bill Bellamy" in the above example
            // Morearty doesn't provide unobserveBinding so over time we might end up observing more bindings than
            // we need
            this.observeBinding(binding);
          });
        }

        return this.context.getProvider(name);
      },

      UNSAFE_componentWillMount() {
        if (mapStateToProps == null) {
          return;
        }

        // call `mapStateToProps` to populate `bindingsToObserve`
        mapStateToProps(
          {
            getProvider: this.getAndObserveProvider,
            isFeatureEnabled: feature => isFeatureEnabled(this.getAndObserveProvider, feature),
          },
          this.props
        );

        bindingsToObserve.forEach(bindings => {
          bindings.forEach(binding => this.observeBinding(binding));
        });
      },

      render() {
        const featureEnabled = feature => isFeatureEnabled(this.getProvider, feature);
        let propsFromState =
          (mapStateToProps &&
            mapStateToProps(
              {
                getProvider: this.getProvider,
                isFeatureEnabled: featureEnabled,
              },
              this.props
            )) ||
          {};
        let propsWithActions = (mapExecuteToProps && mapExecuteToProps(this.executeAction, this.props)) || {};
        let finalProps = mergeProps(propsFromState, propsWithActions, this.props);
        let wrappedInstance = (_.get(WrappedComponent, 'prototype.render') && this.setWrappedInstance) || undefined;

        return createElement(WrappedComponent, {
          ...finalProps,
          ref: wrappedInstance,
        });
      },
    });

    setDisplayName(ConnectedComponent, WrappedComponent, 'Connect');
    return ConnectedComponent;
  };
}

const ExecuteActionContext = React.createContext(_.noop);

const ExecuteActionProvider = createReactClass({
  mixins: [Morearty.Mixin],

  contextTypes: {
    executeAction() {},
  },

  executeAction(RunnerClass, ...params) {
    // Using executeAction in a callback synchronously works fine, but using `executeAction` in useEffect does not.. if the action
    // being executed modifies the state tree, subscribed components may not update. I would guess that's because useEffect runs
    // during a part of the component lifecycle that Morearty (which has been deprecated for a while) doesn't "see" the changes
    // for.
    setImmediate(() => this.context.executeAction(RunnerClass, ...params));
  },

  render() {
    return (
      <ExecuteActionContext.Provider value={this.executeAction}>{this.props.children}</ExecuteActionContext.Provider>
    );
  },
});

export { ExecuteActionContext, ExecuteActionProvider };

export { EXCLUDED_PROVIDERS };
