export default class CancelUpdateProfile {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.profile.resetPending();
    this.context.stores.profile.clearErrors();
  }
}
