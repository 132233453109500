import _ from 'lodash';
import moment from 'moment';

import createModel, { prop } from './lib/create_model';

const MIN_REFRESH_MS = 30000;

export default createModel({
  modelName: 'Auth',
  properties: {
    claims: prop(Object).default({}),
  },

  getOrgId() {
    return this.claims.orgId;
  },

  getRoleIds() {
    return this.claims.roleIds;
  },

  getUserId() {
    return this.claims.userId;
  },

  getRefreshDurationMS() {
    let parsedClaims = this.claims;
    // Tokens must be valid for > 30 seconds
    let leaseSec = parsedClaims.exp - parsedClaims.iat;
    let refreshMS = Math.floor(leaseSec * 0.01) * 1000;
    return Math.max(refreshMS, MIN_REFRESH_MS);
  },

  isLoggedIn() {
    return !_.isEmpty(this.claims) && this.claims.exp > moment().unix();
  },

  isPasswordBeingReset() {
    return this.claims.passwordReset || false;
  },

  isUserActivated() {
    return !!(this.claims.activatedAt || this.claims.ssoEnabled);
  },

  login(claims) {
    this.claims = claims;
  },

  logout() {
    this.claims = {};
  },

  getFormattedClaims() {
    return _.reduce(
      this.claims,
      (memo, v, k) => Object.assign(memo, { [getClaimName(k)]: formatClaimValue(k, v) }),
      {}
    );
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

function getClaimName(k) {
  switch (k) {
    case 'exp':
      return 'expiresAt';
    case 'iat':
      return 'issuedAt';
    default:
      return k;
  }
}

function formatClaimValue(k, v) {
  switch (k) {
    case 'exp':
      return moment.unix(v).toISOString();
    case 'iat':
      return moment.unix(v).toISOString();
    default:
      return v;
  }
}
