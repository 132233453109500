import IdGenerator from 'scripts/domain/contracts/id_generator';
import notificationFromJs from 'models/notification/notification_from_js';
import NotificationType from 'models/notification/notification_type';
import RoutingEventType from 'models/routing_event/routing_event_type';

export function notificationFromRoutingEvent(event, entities = {}) {
  return notificationFromJs(fromRoutingEvent(event, entities));
}

function fromRoutingEvent(event, { customer }) {
  switch (event.type) {
    case RoutingEventType.INBOUND_MESSAGE:
      return {
        ...event,
        id: event.queueItemId,
        profile: customer || { id: event.customerId },
        type: NotificationType.INBOUND_MESSAGE,
      };
    default:
      return {
        ...event,
        id: idFromRoutingEvent(event),
        type: typeFromRoutingEvent(event),
      };
  }
}

function idFromRoutingEvent(event) {
  return event.profile?.id || event.conversationItem?.id || IdGenerator.newId();
}

function typeFromRoutingEvent(event) {
  return NotificationType[event.type];
}
