import React from 'react';

import CommunicationControlsMenu from 'components/customer/communication_controls_menu';
import CompositionTabs from 'components/customer/composition_tabs';
import Conversation from 'models/conversation';
import CustomerContext from 'components/customer/customer_context';
import ResponsePrompt from 'components/customer/response_prompt';

export default function CompositionManager() {
  return (
    <CustomerContext.Consumer>
      {({ compositionId, customerId, latestConversationId, latestConversationStatus }) => {
        const showResponsePrompt = !(latestConversationStatus === Conversation.Status.CLOSED);
        const compositionMenu = (
          <CommunicationControlsMenu conversationId={latestConversationId} customerId={customerId} />
        );

        return compositionId ? (
          <div className="compositionManager-open">
            {compositionMenu}
            <CompositionTabs compositionId={compositionId} conversationId={latestConversationId} />
          </div>
        ) : (
          <div className="compositionManager-closed">
            {compositionMenu}
            {showResponsePrompt && <ResponsePrompt conversationId={latestConversationId} />}
          </div>
        );
      }}
    </CustomerContext.Consumer>
  );
}
