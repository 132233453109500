import _ from 'lodash';
import connect from 'components/lib/connect';
import React, { useEffect, useRef } from 'react';
import { ReactEditor } from 'slate-react';

import {
  AboveEditorActions,
  Below,
  TextEditorWrapper,
} from 'components/customer/task_comments/task_comment_editor_new_styles';
import { BoldItalicUnderlineControls, ListControls } from 'components/composer/shared/editor_controls_new';
import BelowEditorActions from 'components/composer/shared/below_editor_actions_new';
import EditorWrapper from 'components/customer/task_comments/task_comment_editor_wrapper';
import Send from 'components/customer/task_comments/task_comment_editor_new_send';
import TextEditor from 'components/text_editor_new';
import useTaskCommentEditor from 'components/customer/task_comments/use_task_comment_editor';

export function TaskCommentEditor({ itemId, isPending, lastOpenedAt }) {
  // Maintain itemId ref so that onSubmit always gets the latest value
  const itemIdRef = useRef(itemId);
  useEffect(() => {
    itemIdRef.current = itemId;
  }, [itemId]);

  const editorProps = {
    initialHtml: '',
    placeholder: 'Comment on this task',
    editorId: 'task-comment',
    attr: 'bodyHtml',
    name: 'bodyHtml',
    onChange: _.noop,
    onBlur: _.noop,
  };

  const [editorRef, onSubmit] = useTaskCommentEditor(editorProps, itemIdRef);

  useEffect(() => {
    !isPending &&
      editorRef.current &&
      editorRef.current.children &&
      editorRef.current.children.length > 0 &&
      ReactEditor.focus(editorRef.current);
  }, [editorRef, isPending, lastOpenedAt]);
  const editorNodeRef = useRef(null);

  return (
    <EditorWrapper data-aid="taskCommentEditor-editor" editor={editorRef.current} id={editorProps.editorId}>
      <AboveEditorActions>
        <BoldItalicUnderlineControls {...editorProps} />
        <ListControls {...editorProps} />
      </AboveEditorActions>
      <TextEditorWrapper ref={editorNodeRef}>
        <TextEditor {...editorProps} />
      </TextEditorWrapper>
      <Below>
        <BelowEditorActions editorRef={editorRef} positionRef={editorNodeRef} />
        <Send id={editorProps.id} onSubmit={onSubmit} />
      </Below>
    </EditorWrapper>
  );
}

function mapStateToProps({ getProvider }, { itemId }) {
  const taskInfoProvider = getProvider('taskInfo');
  let isPending = true;
  if (taskInfoProvider.findBy({ id: itemId }) && !taskInfoProvider.isPending(itemId)) {
    isPending = false;
  }

  return {
    isPending,
  };
}

const TaskCommentEditorContainer = connect(mapStateToProps)(TaskCommentEditor);

export default TaskCommentEditorContainer;
