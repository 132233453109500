import { createEditor } from 'slate';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';

// We render the autocomplete as a void node, which means it has no text children. This ensures
// that an agent can't select the text within the autocomplete and edit it or move their text
// selection within it.
export default function Autocomplete({ attributes, children, element }) {
  const text = element['data-text'];

  // The `text` might have variables that need to be updated.. so we grab the fragment
  // and convert that back to plaintext to render instead. If the agent updates the
  // variable value, it actually won't be reflected here unfortunately.
  const saturatedPlaintext = useMemo(() => {
    const editor = createEditor();
    editor.children = element['data-fragment'];
    return serializePlaintext(editor);
  }, [text]);

  // Void nodes have children set to an empty text node. We need to render this first to
  // ensure selection works correctly. We can then render the actual text by grabbing
  // it from the data saved on the node.
  return (
    <StyledAutocomplete {...attributes} contentEditable={false}>
      {children}
      <AutocompleteInner>{saturatedPlaintext}</AutocompleteInner>
      <AutocompleteTab>Tab</AutocompleteTab>
    </StyledAutocomplete>
  );
}

const StyledAutocomplete = styled.span`
  color: ${p => p.theme.colors.gray600};
  cursor: text;
`;

const AutocompleteInner = styled.span`
  position: relative;
`;

const AutocompleteTab = styled.span`
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray600};
  border-radius: 4px;
  content: 'Tab';
  cursor: text;
  font-size: 10px;
  margin-left: 4px;
  padding: 2px 4px;
  white-space: nowrap;
`;
