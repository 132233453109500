import React from 'react';

import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 16 16" {...props}>
    <g clipRule="evenodd" fill="#FFFFFF" fillRule="evenodd" strokeWidth=".5">
      <path d="M5.66923 3.40611L4.80367 2.54056C4.1788 1.91568 3.16568 1.91568 2.5408 2.54056C1.91593 3.16543 1.91593 4.17855 2.5408 4.80343L3.40636 5.66898C3.75175 6.01438 4.21577 6.16886 4.66728 6.13242C4.26607 5.72754 4.26721 5.07409 4.67068 4.67061C5.0742 4.26709 5.72777 4.266 6.13264 4.66736C6.16918 4.21575 6.01471 3.75159 5.66923 3.40611ZM7.76222 6.29692C8.52343 4.9033 8.31418 3.12061 7.13446 1.94088L6.2689 1.07533C4.8348 -0.358769 2.50967 -0.358768 1.07557 1.07533C-0.358523 2.50943 -0.358525 4.83456 1.07557 6.26865L1.94113 7.13421C3.12079 8.31387 4.90335 8.52316 6.29694 7.7621L8.23766 9.70282C7.4766 11.0964 7.6859 12.879 8.86555 14.0586L9.73111 14.9242C11.1652 16.3583 13.4903 16.3583 14.9244 14.9242C16.3585 13.4901 16.3585 11.165 14.9244 9.73087L14.0589 8.86531C12.8792 7.68559 11.0965 7.47633 9.70284 8.23754L7.76222 6.29692ZM9.86734 11.3325C10.2722 11.7338 10.9257 11.7327 11.3292 11.3292C11.7328 10.9256 11.7338 10.272 11.3324 9.86713C11.784 9.83059 12.2482 9.98506 12.5937 10.3305L13.4592 11.1961C14.0841 11.821 14.0841 12.8341 13.4592 13.459C12.8343 14.0838 11.8212 14.0838 11.1963 13.459L10.3308 12.5934C9.98539 12.248 9.83091 11.784 9.86734 11.3325Z" />
    </g>
  </IconContainer>
);
