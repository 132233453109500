import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import formatRelativeShort from './lib/format_relative_short';

const REFRESH_RATE = 30000;
const FULL_FORMAT = 'MMM D, YYYY h:mma';

export default function ShortRelativeDateTime({ className, timestamp }) {
  const [shortTime, fullTime] = useTime(timestamp);
  return (
    <time className={className} dateTime={timestamp} title={fullTime}>
      {shortTime}
    </time>
  );
}

function useTime(timestamp) {
  const fullTime = useMemo(() => moment(timestamp).format(FULL_FORMAT), [timestamp]);
  const [shortTime, setTime] = useState(() => formatRelativeShort(timestamp));
  useEffect(() => {
    let id = setInterval(() => {
      setTime(formatRelativeShort(timestamp));
    }, REFRESH_RATE);
    return () => clearInterval(id);
  }, [timestamp]);

  return [shortTime, fullTime];
}
