import _ from 'lodash';
import Cookies from 'js-cookie';
import { createHttpClient } from './http_client';
import IdGenerator from 'scripts/domain/contracts/id_generator';

export default class XhrAdapter {
  constructor(eventRecorder, requestorId, createXhr = defaultCreateXhr) {
    this.createXhr = createXhr;
    this.requestorId = requestorId;
    this.eventRecorder = eventRecorder;
  }

  axios() {
    if (!this.httpClient) {
      let timeout = 10000; // it takes 10s to schedule a report in looker
      this.httpClient = createHttpClient(this.eventRecorder, this.requestorId, { timeout });
    }
    return this.httpClient;
  }

  _recordEvent(correlationId, method, url) {
    this.eventRecorder && this.eventRecorder.recordEvent(correlationId, `${method} ${url}`);
  }

  get(url, cb, opts = {}) {
    return this._send('GET', url, cb, opts);
  }

  delete(url, cb, opts = {}) {
    return this._send('DELETE', url, cb, opts);
  }

  post(url, obj, cb, opts = {}) {
    let xhr = this._open('POST', url, cb, opts);

    xhr.setRequestHeader('Content-Type', 'application/json');
    obj ? xhr.send(JSON.stringify(obj)) : xhr.send();

    return xhr;
  }

  put(url, obj, cb, opts = {}) {
    let xhr = this._open('PUT', url, cb, opts);

    xhr.setRequestHeader('Content-Type', 'application/json');
    obj ? xhr.send(JSON.stringify(obj)) : xhr.send();

    return xhr;
  }

  postUpload(url, obj, listeners, opts = {}) {
    let xhr = this._open('POST', url, () => {}, opts);
    _(listeners)
      .omit('progress')
      .forEach((v, k) => xhr.addEventListener(k, v));
    xhr.upload.addEventListener('progress', listeners.progress);
    xhr.send(obj);
    return xhr;
  }

  _open(method, url, cb, opts) {
    let xhr = this.createXhr();

    xhr.onload = onLoad(cb);
    xhr.onerror = () => cb(new Error(`${method} ${url} error`));
    xhr.ontimeout = () => cb(new Error(`${method} ${url} timeout`));

    xhr.open(method, url, true);

    let correlationId = ensureCorrelationId(opts);
    if (opts.headers) {
      _.forOwn(opts.headers, (value, key) => xhr.setRequestHeader(key, value));
    }

    let csrfToken = Cookies.get('_csrf_token');
    if (csrfToken) {
      xhr.setRequestHeader('X-CSRF-Token', csrfToken);
    }

    // on IE timeout needs to be assigned after the xhr is opened
    // https://msdn.microsoft.com/en-us/library/cc304105(v=vs.85).aspx
    _.assign(xhr, opts || {});

    this._recordEvent(correlationId, method, url);

    return xhr;
  }

  _send(method, url, cb, opts) {
    let xhr = this._open(method, url, cb, opts);
    xhr.send();

    return xhr;
  }
}

function defaultCreateXhr() {
  return new XMLHttpRequest();
}

function onLoad(cb) {
  return function(event) {
    if (_.isFunction(cb) && event.target && event.target.readyState === 4) {
      return cb(null, adaptXhr(event.target));
    }
  };
}

function adaptXhr(xhr) {
  return {
    status: xhr.status,
    statusText: xhr.statusText,
    response: xhr.getResponseHeader('Content-Type') === 'application/json' ? safeParse(xhr.response) : xhr.response,
    responseText: xhr.responseText,
  };
}

function safeParse(payload) {
  try {
    return JSON.parse(payload);
  } catch (e) {
    return payload;
  }
}

function ensureCorrelationId(opts) {
  if (!opts.headers) {
    opts.headers = {};
  }
  if (!opts.headers[HEADER_CORRELATION_ID]) {
    opts.headers[HEADER_CORRELATION_ID] = IdGenerator.newId();
  }

  return opts.headers[HEADER_CORRELATION_ID];
}

export const HEADER_CORRELATION_ID = 'Gladly-Correlation-Id';
