import _ from 'lodash';
import moment from 'moment';

import { CommunicationSessionStatus } from 'models/agent_activity';
import Conversation from 'models/conversation';
import InteractionType, { baseInteractionType } from 'models/interaction_type';
import Communicator from 'models/communicator';
import PhoneCall from 'models/phone_call';
import ConversationItemType from 'models/conversation_item_type';

const interactionTypeRank = Object.freeze([
  InteractionType.TASK,
  InteractionType.ABANDONED_CALL,
  InteractionType.VOICEMAIL,
  InteractionType.EMAIL,
  InteractionType.CUSTOM_CHANNEL,
  InteractionType.WHATSAPP,
  InteractionType.TWITTER,
  InteractionType.FB_MESSENGER,
  InteractionType.SMS,
  InteractionType.CHAT,
  InteractionType.PHONE_CALL,
]);

export function getActiveConversationId(conversationsStore) {
  const activeConversation = getActiveConversationAttributes(conversationsStore, ['id']);
  return activeConversation ? activeConversation.id : null;
}

export function getLatestConversationId(conversationsStore) {
  const latestConversation = getLatestConversationAttributes(conversationsStore, ['id']);
  return latestConversation ? latestConversation.id : null;
}

export function getActiveConversationAttributes(conversationsStore, selectAttributes) {
  return _.last(
    conversationsStore.findAll({ filter: c => c.status !== Conversation.Status.CLOSED, select: selectAttributes })
  );
}

export function getLatestConversationAttributes(conversationsStore, selectAttributes) {
  const latestConversation = _.last(conversationsStore.findAll({ select: selectAttributes, sortBy: c => c.createdAt }));
  return getActiveConversationAttributes(conversationsStore, selectAttributes) || latestConversation || null;
}

export function getSortedConversationIds(conversationsStore) {
  let closedIds = conversationsStore
    .findAll({ filter: { status: Conversation.Status.CLOSED }, select: ['id'], sortBy: c => c.createdAt })
    .map(c => c.id);

  let active = _.last(
    conversationsStore.findAll({ filter: c => c.status !== Conversation.Status.CLOSED, select: ['id'] })
  );
  if (active) {
    return closedIds.concat(active.id);
  }

  return closedIds;
}

export function getSortedConversations(conversationsStore) {
  let closedConversations = conversationsStore.findAll({
    filter: { status: Conversation.Status.CLOSED },
    select: ['id', 'status', 'createdAt'],
    sortBy: c => c.createdAt,
  });

  let activeConversation = _.last(
    conversationsStore.findAll({
      filter: c => c.status !== Conversation.Status.CLOSED,
      select: ['id', 'status', 'createdAt'],
    })
  );
  if (activeConversation) {
    return closedConversations.concat(activeConversation);
  }

  return closedConversations;
}

export function getConversationAssignee(conversationStore) {
  let agentId = _.get(conversationStore, 'assignee.agentId');
  let groupId = _.get(conversationStore, 'assignee.routingGroupId');

  return { agentId, groupId };
}

export function getCommunicationSession(customerId, stores) {
  const { activeCall, activeSessions, customers } = stores;

  const activeCallSession = activeCall.get();
  const activeCallSessionId =
    activeCallSession && activeCallSession.conversationItem ? activeCallSession.conversationItem.id : null;

  if (activeCallSessionId) {
    return { id: activeCallSessionId, type: InteractionType.PHONE_CALL, status: CommunicationSessionStatus.ACTIVE };
  }

  const activeSession = activeSessions.findBy({ customer: { id: customerId } });
  if (activeSession) {
    const session = getCommunicationSessionFromActiveSession(activeSession);
    if (!session) {
      return null;
    }

    return {
      id: session.id,
      type: session.type,
      status: CommunicationSessionStatus.ACTIVE,
    };
  }

  if (customerId && customers.has({ id: customerId })) {
    const { conversations, conversationHistory } = customers.storesFor(customerId);

    const sessionFromHistory = getLastSessionFromHistory(conversations, conversationHistory);
    if (sessionFromHistory) {
      return {
        id: sessionFromHistory.id,
        type: sessionFromHistory.type,
        status: CommunicationSessionStatus.ENDED,
      };
    }
  }
  return null;
}

function getInteractionTypeRank(interactionType) {
  return interactionTypeRank.indexOf(baseInteractionType(interactionType));
}

function getCommunicationSessionFromActiveSession(activeSession) {
  return _.orderBy(activeSession.queueItem.sessions, [session => getInteractionTypeRank(session.type)], ['desc'])[0];
}

export function getLastSessionFromHistory(conversations, conversationHistory) {
  const currentConversationId = getActiveConversationId(conversations);
  if (!currentConversationId) {
    return null;
  }

  let lastCustomerMessage = conversationHistory.findBy({
    filter: item =>
      item.conversationId === currentConversationId &&
      item.initiator.type === Communicator.CUSTOMER &&
      !isCommunicationSessionEndedItem(item),
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });

  let lastSessionEndedItem = conversationHistory.findBy({
    filter: item => isCommunicationSessionEndedItem(item),
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });

  if (!lastSessionEndedItem) {
    return null;
  }

  if (
    !lastCustomerMessage ||
    moment(_.get(lastSessionEndedItem, 'timestamp')).isAfter(_.get(lastCustomerMessage, 'timestamp'))
  ) {
    return lastSessionEndedItem.content instanceof PhoneCall
      ? { id: lastSessionEndedItem.id, type: InteractionType.PHONE_CALL }
      : { id: lastSessionEndedItem.content.sessionId, type: lastSessionEndedItem.content.sessionType };
  }
  return null;
}

function isCommunicationSessionEndedItem(item) {
  return (
    (item.content.type === ConversationItemType.PHONE_CALL && item.content.status === PhoneCall.Status.COMPLETED) ||
    item.content.type === ConversationItemType.SESSION_ENDED
  );
}
