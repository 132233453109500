import _ from 'lodash';

import createBlankError from '../factories/error/create_blank_error';
import createTooLongError from '../factories/error/create_too_long_error';
import createModel from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

let prop = createModel.prop;

let Topic = createModel({
  modelName: 'Topic',

  properties: {
    disabled: prop(Boolean).default(false),
    id: prop(String).isRequired,
    name: prop(String),
    nameWithAncestry: prop(String),
    parentId: prop(String),
    children: prop([String]),
    descendants: prop([String]),
  },

  getName() {
    return this._getNameWithDisabled(this.name);
  },

  getNameWithAncestry() {
    return this._getNameWithDisabled(this.nameWithAncestry || this.name);
  },

  _getNameWithDisabled(name) {
    return this.disabled ? `${name} (Archived)` : name;
  },

  setName(name) {
    this.name = name;
  },

  update(attrs) {
    let validationErrors = getValidationErrors(_.merge({}, this, attrs));
    if (validationErrors.length) {
      throw new Error(_.map(validationErrors, 'detail').join('; '));
    }
    _.merge(this, _.omit(attrs, 'id'));
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },

    MAX_LENGTH: 200,

    getValidationErrors,
  },
});

function getValidationErrors(props) {
  let errors = [];

  ['name'].forEach(function(field) {
    if (!props[field] || !props[field].length) {
      errors.push(createBlankError(field));
    } else if (props[field].length > Topic.MAX_LENGTH) {
      errors.push(createTooLongError(field, `${Topic.MAX_LENGTH} characters`));
    }
  });

  return errors;
}

export default Topic;
