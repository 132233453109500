import React from 'react';
import styled from 'styled-components';

import { useTextEditorContext } from 'components/text_editor/text_editor_context';

const StyledEmailHighlight = styled.span`
  border-bottom: 1px dashed ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.green400};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.green600};
  }
`;

export default function EmailHighlight({ attributes, children, editor, node, start, end, text }) {
  const { data, setDataValue } = useTextEditorContext();

  const onClick = () => {
    if (data.emailHighlight) {
      setDataValue('emailHighlight', null);
    } else {
      const document = editor.value.document;
      setDataValue('emailHighlight', {
        path: document.getPath(node),
        key: node.key,
        start,
        end,
        text,
      });
    }
  };

  return (
    <StyledEmailHighlight
      data-aid="email-highlight"
      data-end={end}
      data-key={node.key}
      data-start={start}
      onClick={onClick}
      {...attributes}
    >
      {children}
    </StyledEmailHighlight>
  );
}
