import { createPlugins } from '@udecode/plate';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import components from 'components/text_editor_new/plugins/components';
import createAITextCompletion from 'components/text_editor_new/plugins/ai/ai_text_completion';
import createAITextCompletionHighlight from 'components/text_editor_new/plugins/ai/ai_text_completion/ai_text_completion_highlight';
import { createAnswersPlugin } from 'components/text_editor_new/plugins/answers/answers_plugin';
import createCapitalizeFirstWordPlugin from 'components/text_editor_new/plugins/capitalize_first_word';
import createCapitalizeIPlugin from 'components/text_editor_new/plugins/capitalize_i';
import { createEditorEventsPlugin } from 'components/text_editor_new/plugins/editor_events/create_editor_events_plugin';
import { createHotkeyPlugin } from 'components/text_editor_new/plugins/hotkey/create_hotkey_plugin';
import createInsertTextAfterInline from 'components/text_editor_new/plugins/insert_text_after_inline';
import createParagraphPlugin from 'components/text_editor_new/plugins/paragraph';
import createPlaceholders from 'components/text_editor_new/plugins/placeholders';
import createSaveOnBlur from 'components/text_editor_new/plugins/save_on_blur';
import createSuggestedReplies from 'components/text_editor_new/plugins/suggested_replies/create_suggested_replies';
import { useCreateOpenAnswerPanelPlugin } from 'components/text_editor_new/plugins/create_open_answer_panel_plugin';
import { useCreateSubmitOnEnterPlugin } from 'components/text_editor_new/plugins/submit_on_enter';
import { useCreateVariablesPlugin, useUpdateVariables } from 'components/text_editor_new/plugins/variables';
import useCreateAutocompletePlugin from 'components/text_editor_new/plugins/autocomplete/use_create_autocomplete_plugin';
import useEditorWithDecorators from 'components/text_editor_new/hooks/use_editor_with_decorators';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function useMessagingEditorPlugins({ composerRef }, editorProps, extraPlugins) {
  const { name, onBlur, onSubmit, editorId } = editorProps;

  const isFeatureEnabled = useIsFeatureEnabled();
  const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);

  const includeAIFeatures = isFeatureEnabled('gclDemo2023') || !agentAssistanceConfig.disableHeroAI;
  const isSuggestedRepliesEnabled = isFeatureEnabled('suggestedReplies');

  // Rather than useMemo, we lock in the value of aiPlugins on first render. We don't have to worry
  // about includeAIFeatures changing because we're using withRefreshEditorWhenFlagsChange, which
  // forcibly remounts the parent component when the flag changes.

  // This is all done because Plate / Slate do not like having plugins change after the editor is
  // initialized.
  const [aiPlugins] = useState(() => {
    return includeAIFeatures ? [createAITextCompletion(), createAITextCompletionHighlight()] : null;
  });

  const createOpenAnswerPanelPlugin = useCreateOpenAnswerPanelPlugin();
  const [suggestedRepliesPlugin] = useState(() => {
    return isSuggestedRepliesEnabled ? [createSuggestedReplies()] : null;
  });

  const createSubmitOnEnterPlugin = useCreateSubmitOnEnterPlugin(onSubmit);
  const createVariablesPlugin = useCreateVariablesPlugin();
  const createAutocompletePlugin = useCreateAutocompletePlugin();

  const plugins = useMemo(() => {
    // ORDER MATTERS. Some plugins can interact with each other in adverse ways. Be careful when changing the order.
    let pluginList = [
      // This plugin must come first
      createInsertTextAfterInline(),

      // editor events and keyboard hotkeys
      createHotkeyPlugin(),
      createEditorEventsPlugin(),

      // Autocomplete must come before the autocapitalization plugins, as their onKeyDown handlers interfere with the
      // behavior of the autocomplete plugin.
      createAutocompletePlugin(),

      // Autocapitalization
      createCapitalizeIPlugin(),
      createCapitalizeFirstWordPlugin(),

      // general
      createSaveOnBlur(onBlur),
      createOpenAnswerPanelPlugin(),

      // elements
      createParagraphPlugin(),

      // inlines
      createPlaceholders(),

      // messaging specific
      createSubmitOnEnterPlugin(),

      // etc.
      createAnswersPlugin(),
      createVariablesPlugin(),
    ];

    if (aiPlugins && aiPlugins.length) {
      pluginList = [...pluginList, ...aiPlugins];
    }

    if (suggestedRepliesPlugin && suggestedRepliesPlugin.length) {
      pluginList = [...pluginList, ...suggestedRepliesPlugin];
    }

    if (extraPlugins) {
      pluginList = [...pluginList, ...extraPlugins];
    }

    return createPlugins(pluginList, { components });
  }, [
    aiPlugins,
    createAutocompletePlugin,
    createOpenAnswerPanelPlugin,
    createSubmitOnEnterPlugin,
    createVariablesPlugin,
    extraPlugins,
    onBlur,
    suggestedRepliesPlugin,
  ]);

  const editor = useEditorWithDecorators(plugins, { editorId });

  useUpdateVariables(editor);

  const editorRef = useRef(editor);
  const [, rerender] = useState(null);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = editor;
      rerender({});
    }
    if (!composerRef.current.editorRefs[name]) {
      composerRef.current.editorRefs[name] = editorRef;
      rerender({});
    }
  }, [editor]);
  editorProps.editorRef = editorRef;
  return editorRef;
}
