import { Editor, Text, Transforms } from 'slate';

export default function focusEndOfEditor(editor) {
  for (const [node, nodePath] of Editor.nodes(editor, {
    at: [],
    match: i => {
      return Text.isText(i);
    },
    reverse: true,
  })) {
    const point = {
      offset: node.text.length,
      path: nodePath,
    };
    const newSelection = {
      anchor: point,
      focus: point,
    };
    Transforms.select(editor, newSelection);
    return;
  }
}
