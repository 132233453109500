import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import CustomerProfile from 'models/customer_profile';
import Err from 'models/err';
import ExternalProfileRow from './external_profile_row';
import { formatValue } from 'components/customer/summary/custom_attributes/visible_custom_attribute';
import GladlyProfileRow from './gladly_profile_row';
import IntegrationPlaceholderRow from './integration_placeholder_row';
import LoadingSpinner from 'components/lib/loading_spinner';
import { LookupResultType } from 'actions/external_customer_lookup/lib/lookup_result';
import ResultsHeader from './results_header';

const SHOW_OVERFLOW_FADES_DEBOUNCE_MS = 150;
const LINK_ACTION = 'Link';
const REPLACE_LINK_ACTION = 'Replace Link';

export default class ExternalCustomerLookupSearchResults extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: -1,
      shouldFadeLeft: false,
      shouldFadeRight: false,
    };

    _.bindAll(this, ['handleCancelClick', 'handleLinkClick', 'showOverflowFades']);

    this.showOverflowFades();
    this.showOverflowFades = _.debounce(this.showOverflowFades, SHOW_OVERFLOW_FADES_DEBOUNCE_MS);
  }

  componentDidMount() {
    window.addEventListener('resize', this.showOverflowFades);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.showOverflowFades);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.lookupResultProfiles !== nextProps.lookupResultProfiles) {
      this.setState({ selectedIndex: -1 });
    }
  }

  render() {
    if (_.isEmpty(this.props.lookupResultAttributes)) {
      return this.renderNoAvailableIntegrations();
    }

    if (
      !this.props.isExternalLookupPending &&
      this.props.lookupResultProfilesByIntegrationId == null &&
      _.isEmpty(this.props.lookupSuggestionsByIntegrationId)
    ) {
      return this.renderEmptySearch();
    }

    const tableClassNames = classnames('externalCustomerLookupSearchResults-table', {
      'externalCustomerLookupSearchResults-table-fadeLeft': this.state.shouldFadeLeft,
      'externalCustomerLookupSearchResults-table-fadeRight': this.state.shouldFadeRight,
    });
    const scrollerClassNames = classnames('externalCustomerLookupSearchResults-table-scroller', {
      'externalCustomerLookupSearchResults-table-scroller-noResults':
        this.hasNoResults() || this.props.isExternalLookupPending,
    });
    const wrapperClassNames = classnames('externalCustomerLookupSearchResults-table-wrapper');

    return (
      <div className="externalCustomerLookupSearchResults">
        <ResultsHeader
          inset={'medium'}
          isLookupPending={this.props.isExternalLookupPending}
          resultsCount={this.getNumberOfResults()}
          shouldShowSuggestions={this.shouldShowSuggestions()}
          suggestionsCount={this.getNumberOfSuggestions()}
        />
        <div className={wrapperClassNames}>
          <div className="externalCustomerLookupSearchResults-table-columnPinner">
            <div className={scrollerClassNames} onScroll={this.showOverflowFades} ref={node => (this.scroller = node)}>
              <table className={tableClassNames} ref={node => (this.table = node)}>
                {this.renderTableHeader()}
                {this.renderTableBody()}
              </table>
            </div>
          </div>
        </div>
        {this.renderLookupActions()}
        {this.renderLinkError()}
      </div>
    );
  }

  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th className="externalCustomerLookupSearchResults-table-sourceCell" key="externalCustomerLookup-cell1" />
          <th key="externalCustomerLookup-cell2" />
          {_.map(this.props.lookupResultAttributes, attr => (
            <th key={attr.attr}>{attr.label}</th>
          ))}
        </tr>
      </thead>
    );
  }

  renderTableBody() {
    return (
      <tbody>
        <GladlyProfileRow profileRow={getProfileRow(this.props.lookupResultAttributes, this.props.profile)} />
        {this.renderTableRowsByIntegration()}
      </tbody>
    );
  }

  renderTableRowsByIntegration() {
    if (this.props.isExternalLookupPending) {
      return this.renderTableRowsLoadingByIntegration();
    }

    const resultsByIntegrationId = this.getResultProfilesByIntegrationId();
    return _.map(this.props.integrations, integration => {
      const results = _.get(resultsByIntegrationId, integration.id, []);
      return this.renderTableRowsForIntegration(results, integration.id);
    });
  }

  renderTableRowsForIntegration(results, integrationId) {
    if (_.isEmpty(results)) {
      return (
        <IntegrationPlaceholderRow
          externalSystemName={this.getNameForIntegrationId(integrationId)}
          key={`externalCustomer-${integrationId}-placeholder`}
          message="No matches found"
          numColumns={this.props.lookupResultAttributes.length}
        />
      );
    }

    return _.map(results, (externalProfile, index) => {
      const profileRow = getProfileRow(this.props.lookupResultAttributes, externalProfile);
      const lastRow = index === results.length - 1;
      return (
        <ExternalProfileRow
          externalSystemName={this.getNameForIntegrationId(integrationId)}
          isSelected={index === this.state[integrationId]}
          key={`externalCustomer-${integrationId}-${index}`}
          onClick={this.handleClickRowForIntegration.bind(this, index, integrationId)}
          profileRow={profileRow}
          rowSpan={results.length}
          shouldDisplaySeparator={lastRow}
          shouldDisplaySource={index === 0}
        />
      );
    });
  }

  renderTableRowsLoadingByIntegration() {
    if (!this.props.isExternalLookupPending) return null;

    const colspan = this.props.lookupResultAttributes ? this.props.lookupResultAttributes.length + 1 : 1;
    const rowspan = this.props.integrations ? this.props.integrations.length + 1 : 1;
    return _.map(this.props.integrations, (integration, index) => {
      const firstRow = index === 0;

      const trClassNames = classnames(
        'externalCustomerLookupSearchResults-table-externalProfileRow',
        'externalCustomerLookupSearchResults-table-lastRow',
        'loading'
      );
      const spinnerClassNames = classnames('externalCustomerLookupSearchResults-table-loadingSpinner');

      const spinnerCell = firstRow ? (
        <td
          className="externalCustomerLookupSearchResults-table-externalProfileRow-dataCell"
          colSpan={colspan}
          rowSpan={rowspan}
        >
          <div className="externalCustomerLookupSearchResults-table-loadingSpinner-wrapper">
            <LoadingSpinner containerClassName={spinnerClassNames} />
          </div>
        </td>
      ) : null;

      return (
        <tr className={trClassNames} key={`externalCustomer-${integration.id}-loading`}>
          <td className="externalCustomerLookupSearchResults-table-sourceCell">
            <div className="sourceName">{this.getNameForIntegrationId(integration.id)}</div>
          </td>
          {spinnerCell}
        </tr>
      );
    });
  }

  renderLookupActions() {
    return (
      <div className="externalCustomerLookupSearchResults-actions">
        <Button
          blurOnClick
          buttonType={ButtonTypes.TEXT}
          className="externalCustomerLookupSearchResults-cancelButton"
          onClick={this.handleCancelClick}
          onMouseDown={evt => evt.preventDefault()}
        >
          Cancel
        </Button>
        <Button
          blurOnClick
          buttonType={ButtonTypes.PRIMARY}
          className="externalCustomerLookupSearchResults-submitButton"
          disabled={this.hasNoSelection()}
          onClick={this.handleLinkClick}
          onMouseDown={evt => evt.preventDefault()}
        >
          {this.props.isAlreadyLinked ? REPLACE_LINK_ACTION : LINK_ACTION}
        </Button>
      </div>
    );
  }

  renderEmptySearch() {
    return <div className="externalCustomerLookupSearchResults-emptySearch">Enter search fields for results</div>;
  }

  renderNoAvailableIntegrations() {
    const message = `There are no external search sources available at the moment.
       Please contact your admin to configure the Customer Linking section of your integrations`;

    return <div className="externalCustomerLookupSearchResults-emptySearch">{message}</div>;
  }

  renderLinkError() {
    if (_.isEmpty(this.props.linkErrors)) {
      return null;
    }

    const attributeErrors = _.filter(this.props.linkErrors, e => e.code === Err.Code.INVALID);

    const malformedAttributes = [];
    attributeErrors.forEach(err => {
      if (err.attr) {
        if (err.attr.startsWith('customAttributes.')) {
          const attr = err.attr.split('.')[1];
          malformedAttributes.push(attr);
        }
      }
    });

    let message = 'External linking failed. No apps were linked. Contact your app administrator for help.';
    if (malformedAttributes.length > 0) {
      message = `External linking failed because the format of custom attribute(s), [${malformedAttributes}], was unexpected. No apps were linked. Contact your app administrator for help.`;
    }
    return <div className="externalCustomerLookup-link-error">{message}</div>;
  }

  handleCancelClick() {
    this.props.onCancel();
  }

  handleClickRowForIntegration(index, integrationId) {
    this.setState({
      [integrationId]: this.state[integrationId] === index ? -1 : index,
    });
  }

  handleLinkClick() {
    const allProfiles = this.getResultProfilesByIntegrationId();
    if (_.isEmpty(allProfiles)) return;

    let totalProfileCount = 0;
    const linkedExternalProfiles = {};
    _.forEach(this.props.integrations, integration => {
      const profiles = allProfiles[integration.id];
      totalProfileCount += _.get(profiles, 'length', 0);

      if (this.state[integration.id] >= 0) {
        const selectedProfile = profiles[this.state[integration.id]];
        linkedExternalProfiles[integration.id] = selectedProfile;
      }
    });
    this.props.onLink(linkedExternalProfiles, {
      totalProfileCount,
      totalIntegrationCount: _.get(this.props.integrations, 'length', 0),
      resultType: this.getLookupResultTypeByIntegrationId(),
    });
  }

  hasNoSelection() {
    return _.reduce(
      this.props.integrations,
      (hasSelection, integration) => {
        return hasSelection && (_.isUndefined(this.state[integration.id]) || this.state[integration.id] < 0);
      },
      true
    );
  }

  getNumberOfResults() {
    const integrationIdsToShow = _.map(this.props.integrations, 'id');
    const resultsToShow = _.pick(this.props.lookupResultProfilesByIntegrationId, integrationIdsToShow);
    return _.flatten(_.values(resultsToShow)).length;
  }

  getNumberOfSuggestions() {
    const integrationIdsToShow = _.map(this.props.integrations, 'id');
    const suggestionsToShow = _.pick(this.props.lookupSuggestionsByIntegrationId, integrationIdsToShow);
    return _.flatten(_.values(suggestionsToShow)).length;
  }

  getResultProfilesByIntegrationId() {
    if (this.props.isExternalLookupPending) return {};
    return this.props.lookupResultProfilesByIntegrationId || this.props.lookupSuggestionsByIntegrationId || {};
  }

  getLookupResultTypeByIntegrationId() {
    if (this.props.isExternalLookupPending) return '';

    if (!_.isEmpty(this.props.lookupResultProfilesByIntegrationId)) {
      return LookupResultType.SEARCH;
    } else if (!_.isEmpty(this.props.lookupSuggestionsByIntegrationId)) {
      return LookupResultType.SUGGESTION;
    }

    // We don't have either results or suggestions. Bail out
    return '';
  }

  getNameForIntegrationId(integrationId) {
    const integration = _.find(this.props.integrations, ['id', integrationId]);
    return (integration && integration.name) || 'External';
  }

  hasNoResults() {
    if (!this.props.lookupResultProfiles) {
      return false;
    }
    return this.getNumberOfResults() === 0;
  }

  shouldShowSuggestions() {
    return !!this.props.lookupSuggestionsByIntegrationId && !this.props.lookupResultProfilesByIntegrationId;
  }

  showOverflowFades() {
    if (!this.table || !this.scroller) {
      return;
    }

    const tableRect = this.table.getBoundingClientRect();
    const scrollerRect = this.scroller.getBoundingClientRect();

    const maxScroll = tableRect.width - scrollerRect.width;
    const shouldFadeLeft = maxScroll > 0 && this.scroller.scrollLeft > 0;
    const shouldFadeRight = maxScroll > 0 && this.scroller.scrollLeft < maxScroll;

    if (this.state.shouldFadeLeft === shouldFadeLeft && this.state.shouldFadeRight === shouldFadeRight) return;

    // Note that setting state isn't as performant as setting the styles directly - but we don't need fantastic speeds on this page
    this.setState({ shouldFadeLeft, shouldFadeRight });
  }
}

ExternalCustomerLookupSearchResults.propTypes = {
  integrations: PropTypes.arrayOf(PropTypes.object),
  isAlreadyLinked: PropTypes.bool,
  isExternalLookupPending: PropTypes.bool,
  lookupResultAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
  lookupResultProfilesByIntegrationId: PropTypes.object,
  lookupSuggestionsByIntegrationId: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onLink: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(CustomerProfile).isRequired,
};

function getProfileRow(lookupResultAttributes, profile) {
  return _.map(lookupResultAttributes, attr => {
    const attrName = attr.attr;

    if (attrName === 'email') {
      return (
        _.get(
          _.find(profile.emails, email => !!email.primary),
          'original'
        ) ||
        _.get(profile, 'emails[0].original') ||
        ''
      );
    } else if (attrName === 'phone') {
      return (
        _.get(
          _.find(profile.phones, phone => !!phone.primary),
          'original'
        ) ||
        _.get(profile, 'phones[0].original') ||
        ''
      );
    } else if (attrName === 'emails' || attrName === 'phones') {
      return '';
    } else if (profile.hasOwnProperty(attrName)) {
      return profile[attrName] || '';
    } else if (profile.customAttributes && profile.customAttributes.hasOwnProperty(attrName)) {
      const value = profile.customAttributes[attrName];
      return (value && formatValue(value, attr.type)) || '';
    }
    return '';
  });
}
