import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import InsertSelectedSuggestedReply from 'actions/answers/insert_selected_suggested_reply';
import { SimpleAnswerContentBody } from 'components/customer/answer_panel/preview/content/answer_preview_content_body';

export default function SuggestedReplyContent() {
  const { suggestedReply } = useContext(AnswerPanelContext);
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    executeAction(InsertSelectedSuggestedReply, {});
  }, []);

  return (
    <>
      <Header>
        <HeaderTitle>Reply suggestion</HeaderTitle>
        <div>
          <Button buttonType={ButtonTypes.PRIMARY} data-aid="suggested-reply-content-use-button" onClick={onClick}>
            Use
          </Button>
        </div>
      </Header>
      <SuggestedReplyContainer>
        <StyledSimpleAnswerContentBody
          aid={'suggested-reply-content'}
          bodyHtml={`<div>${suggestedReply.content.text}<div>`}
          id={suggestedReply.content.id}
        />
      </SuggestedReplyContainer>
    </>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  align-items: center;
  padding: 12px ${p => p.theme.spacing.insetMedium} ${p => p.theme.spacing.insetLarge}
    ${p => p.theme.spacing.insetMedium};
`;

const HeaderTitle = styled.div`
  font-weight: ${p => p.theme.fontWeight.heavy};
  font-size: ${p => p.theme.fontSize.medium};
`;

const StyledSimpleAnswerContentBody = styled(SimpleAnswerContentBody)`
  .slateTextEditor {
    padding-top: 0;
  }
`;

export const SuggestedReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
`;
