import React, { useCallback } from 'react';
import styled from 'styled-components';

import NavigateBack from 'actions/current_location/navigate_back';
import NavigateHome from 'actions/current_location/navigate_home';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const StyledCustomerNotFound = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.gray100};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const CustomerNotFoundHeader = styled.span`
  font-size: ${p => p.theme.fontSize.large};
  font-weight: 600;
  margin-bottom: 8px;
`;

const CustomerNotFoundDetails = styled.span`
  color: ${p => p.theme.colors.gray600};
  font-size: ${p => p.theme.fontSize.medium};
  text-align: center;
  white-space: pre;
`;

const CustomerNotFoundLink = styled.span`
  color: ${p => p.theme.colors.green400};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.green600};
  }
`;

export default function CustomerNotFound() {
  const executeAction = useExecuteAction();

  const onClickBack = useCallback(() => executeAction(NavigateBack));
  const onClickHome = useCallback(() => executeAction(NavigateHome));

  return (
    <StyledCustomerNotFound>
      <CustomerNotFoundHeader>Customer Not Found</CustomerNotFoundHeader>
      <CustomerNotFoundDetails>
        <CustomerNotFoundLink onClick={onClickBack}>Go back</CustomerNotFoundLink> or{' '}
        <CustomerNotFoundLink onClick={onClickHome}>go to your inbox</CustomerNotFoundLink>
      </CustomerNotFoundDetails>
    </StyledCustomerNotFound>
  );
}
