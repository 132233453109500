import some from 'lodash/some';
import { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';

import CustomerContext from 'components/customer/customer_context';
import { ITEM, UNREAD_DIVIDER } from './feed/feed_item_types';
import UpdateAgentRead from 'actions/conversation/update_agent_read';
import { useExecuteAction } from 'components/hooks/connect_hooks';

/**
 * useManageAgentRead maintains the "New Activity" line in the feed based on where the agent has scrolled to and
 * what items they've seen, as well as updating the agentRead resource whenever the agent has scrolled to the
 * new items in the feed.
 */
export default function useManageAgentRead({ customerId, items, isLoadingAgentRead, lastReadTimestamp }) {
  const { latestConversationUpdatedAt, latestConversationId } = useContext(CustomerContext);

  const [itemsRef, latestConvRef] = useTrackingRefs(items, latestConversationId, latestConversationUpdatedAt);

  // Place the New Activity line after this item
  const readUntilItemRef = useRef(null);
  // Latest item the agent has scrolled to
  const latestItemSeenRef = useRef(null);
  // Index where we actually place the New Activity line
  const newActivityIndexRef = useRef(null);
  // Whether the agent is currently scrolled to the bottom of the feed or not
  const isScrolledToBottomRef = useRef(false);
  // Whether we've moved the New Activity line between previous and this render
  const hasMovedNewActivityLineRef = useRef(false);

  // Used to initialize the New Activity line when customer is loaded
  const initialLastReadTimestamp = useInitialLastReadTimestamp(customerId, lastReadTimestamp);
  // Whether we've initialized the New Activity line using the intiial last read timestamp yet or not
  const hasInitializedLastReadTimestampRef = useRef(false);

  // Reset the refs when the customer changes.
  useEffect(() => {
    return () => {
      readUntilItemRef.current = null;
      latestItemSeenRef.current = null;
      newActivityIndexRef.current = null;
      isScrolledToBottomRef.current = false;
      hasMovedNewActivityLineRef.current = false;
      hasInitializedLastReadTimestampRef.current = false;
    };
  }, [customerId]);

  const itemsWithNewActivityLine = useMemo(
    () => {
      const lastItem = getLastItem(items);

      // lastReadTimestamp may load after the feed - we want to initialize the New Activity line
      // position to that which our _initial_ lastReadTimestamp is when we first load the customer.
      if (!hasInitializedLastReadTimestampRef.current && (initialLastReadTimestamp || !isLoadingAgentRead)) {
        const index = getInitialNewActivityLineIndex(items, initialLastReadTimestamp);
        hasInitializedLastReadTimestampRef.current = true;
        if (items.length) {
          if (index != null && index < items.length) {
            newActivityIndexRef.current = index;
            readUntilItemRef.current = items[index];
            return insertNewActivityLine(items, readUntilItemRef.current);
          }
        }
      }

      // We don't place the New Activity line until after we've initialized it with the intiial lastReadTimestamp
      if (!hasInitializedLastReadTimestampRef.current) {
        return items;
      }

      // If the last item was sent by our agent, don't show the New Activity line.
      if (lastItem && lastItem.initiatedByCurrentAgent) {
        readUntilItemRef.current = null;
        return items;
      }
      // If we're scrolled to the bottom, we want to keep the New Activity line where it is.
      else if (isScrolledToBottomRef.current) {
        if (readUntilItemRef.current) {
          return insertNewActivityLine(items, readUntilItemRef.current);
        }
        return items;
      }
      // If we're not scrolled to the bottom and the items change, then we want to move the
      // New Activity line to latest item the agent has "seen" based on scroll position.
      else {
        const latestItemVisibleIndex = findItemIndexFromBack(items, latestItemSeenRef.current);
        if (latestItemVisibleIndex === -1) {
          return items;
        }

        readUntilItemRef.current = items[latestItemVisibleIndex + 1];
        hasMovedNewActivityLineRef.current = true;
        return insertNewActivityLine(items, readUntilItemRef.current);
      }
    },
    // These dependencies are very critical - we only want to re-calculate the computed items when
    // items actually change or when our initialLastReadTimestamp becomes not-null.
    [
      initialLastReadTimestamp,
      items,
      isLoadingAgentRead,
      latestItemSeenRef,
      isScrolledToBottomRef,
      hasInitializedLastReadTimestampRef,
    ]
  );

  useUpdateReadTo(
    customerId,
    latestItemSeenRef,
    hasInitializedLastReadTimestampRef,
    isScrolledToBottomRef,
    latestConvRef
  );

  const onVisibleRangeChanged = useOnVisibleRangeChanged(
    latestConvRef,
    customerId,
    hasInitializedLastReadTimestampRef,
    isScrolledToBottomRef,
    itemsRef,
    latestItemSeenRef
  );

  return [
    itemsWithNewActivityLine,
    onVisibleRangeChanged,
    {
      unreadLineIndex: findItemIndexFromBack(itemsWithNewActivityLine, readUntilItemRef.current),
      latestItemSeenIndex: findItemIndexFromBack(itemsWithNewActivityLine, latestItemSeenRef.current),
    },
  ];
}

function useOnVisibleRangeChanged(
  latestConvRef,
  customerId,
  hasInitializedLastReadTimestampRef,
  isScrolledToBottomRef,
  itemsRef,
  latestItemSeenRef
) {
  const [, rerender] = useState({});
  const executeAction = useExecuteAction();

  return useCallback(
    ({ start, end, isScrolledToBottom }) => {
      const itemIndex = findItemIndexFromBack(itemsRef.current, latestItemSeenRef.current);
      if (end - 1 > itemIndex) {
        let minLastSeen = Math.max(start, itemIndex);
        let maxLastSeen = Math.min(end - 1, itemsRef.current.length - 1);
        for (let i = maxLastSeen; i >= minLastSeen; i--) {
          if (itemsRef.current[i] && itemsRef.current[i].type === ITEM) {
            latestItemSeenRef.current = itemsRef.current[i];
            rerender({});
            break;
          }
        }
      }

      if (isScrolledToBottom !== isScrolledToBottomRef.current) {
        isScrolledToBottomRef.current = isScrolledToBottom;
        rerender({});

        // If we're scrolled to the bottom, try to update the agent read to the conversation updated at time.
        // This is unfortunately necessary because the conversation updated at time may differ from the latest
        // item timestamp.. so if we don't update the agentRead to that time, the customer may appear unread
        // in the MessagesNotificationMenu and the Inbox.
        if (
          hasInitializedLastReadTimestampRef.current &&
          isScrolledToBottom &&
          latestConvRef.current &&
          latestConvRef.current.updatedAt &&
          latestConvRef.current.id
        ) {
          executeAction(UpdateAgentRead, {
            customerId,
            agentReadAttrs: { readTo: latestConvRef.current.updatedAt },
            conversationId: latestConvRef.current.id,
          });
        }
      }

      // We need to ensure that we've initialized the New Activity line with the initial lastReadTimestamp for the
      // customer, otherwise we may mark the latest item as read _before_ we've set the "New Activity" line for
      // the customer.. meaning we'll never see the "New Activity" line!
      if (hasInitializedLastReadTimestampRef.current && latestItemSeenRef.current) {
        executeAction(UpdateAgentRead, {
          customerId,
          agentReadAttrs: { readTo: latestItemSeenRef.current.timestamp },
          conversationId: latestItemSeenRef.current.conversationId,
        });
      }
    },
    [customerId, hasInitializedLastReadTimestampRef, isScrolledToBottomRef, itemsRef, latestItemSeenRef]
  );
}

function getInitialNewActivityLineIndex(items, lastReadTimestamp) {
  if (lastReadTimestamp) {
    for (let i = items.length - 1; i >= 0; i--) {
      if (items[i].timestamp && new Date(items[i].timestamp) <= new Date(lastReadTimestamp)) {
        // Once we find an item older than our last read timestamp, iterate forward until we find an item of type ITEM
        // that's not initiated by the current agent. We'll place the New Activity line before _that_ item.
        for (let j = i + 1; j < items.length - 1; j++) {
          if (items[j].type === ITEM && !items[j].initiatedByCurrentAgent) {
            return j;
          }
        }
        return null;
      }
    }
  }
  return null;
}

// Returns the first non-null value for `lastReadTimestamp`, forever basically until we load a new customer.
function useInitialLastReadTimestamp(customerId, lastReadTimestamp) {
  const initialLastReadTimestampRef = useRef(lastReadTimestamp);
  if (lastReadTimestamp && !initialLastReadTimestampRef.current) {
    initialLastReadTimestampRef.current = lastReadTimestamp;
  }

  // Reset the ref when the customer changes
  useEffect(() => {
    return () => {
      initialLastReadTimestampRef.current = lastReadTimestamp;
    };
  }, [customerId]);

  return initialLastReadTimestampRef.current;
}

function useUpdateReadTo(
  customerId,
  latestItemSeenRef,
  hasInitializedLastReadTimestampRef,
  isScrolledToBottomRef,
  latestConvRef
) {
  const hasInitialized = hasInitializedLastReadTimestampRef.current;
  const hasLatestItemSeen = !!latestItemSeenRef.current;
  const isScrolledToBottom = isScrolledToBottomRef.current;
  const executeAction = useExecuteAction();

  // If the latest item changes, update the agentRead with its time if we're scrolled to bottom
  useEffect(() => {
    if (hasInitialized && hasLatestItemSeen) {
      executeAction(UpdateAgentRead, {
        customerId,
        agentReadAttrs: { readTo: latestItemSeenRef.current.timestamp },
        conversationId: latestItemSeenRef.current.conversationId,
      });
    }
  }, [customerId, executeAction, hasInitialized, hasLatestItemSeen, isScrolledToBottom]);

  const conversation = latestConvRef.current ? latestConvRef.current : null;
  // If the conversation updated at changes, update the agentRead with its time if we're scrolled to bottom
  useEffect(() => {
    if (conversation && hasInitialized && conversation.updatedAt && isScrolledToBottom && conversation.id) {
      executeAction(UpdateAgentRead, {
        customerId,
        agentReadAttrs: { readTo: conversation.updatedAt },
        conversationId: conversation.id,
      });
    }
  }, [customerId, executeAction, hasInitialized, conversation, isScrolledToBottom]);
}

function useTrackingRefs(items, latestConversationId, latestConversationUpdatedAt) {
  // Use itemsRef to keep track of items so we don't have to recreate callbacks that rely on items,
  // every time items changes.
  const itemsRef = useRef(items);
  useEffect(() => {
    itemsRef.current = items;
  }, [items]);
  const latestConvRef = useRef({ id: latestConversationId, updatedAt: latestConversationUpdatedAt });
  useEffect(() => {
    latestConvRef.current = {
      id: latestConversationId,
      updatedAt: latestConversationUpdatedAt,
    };
  }, [latestConversationId, latestConversationUpdatedAt]);
  return [itemsRef, latestConvRef];
}

function getLastItem(items) {
  if (!items.length) {
    return null;
  }

  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i].type === ITEM) {
      return items[i];
    }
  }

  return null;
}

function insertNewActivityLine(items, item) {
  if (item == null) {
    return items;
  }

  const unreadItem = {
    type: UNREAD_DIVIDER,
    id: 'unread-divider',
  };

  let index = findItemIndexFromBack(items, item);
  if (index < 0) {
    return items;
  }

  // HACK: Don't try to place the New Activity line after all the actual conversation items. It is definitely a bug
  // that we tried to place it after to start, so this is a guard if that situation arises.
  const after = items.slice(index);
  if (!after.length || !some(after, afterItem => afterItem.type === ITEM)) {
    return items;
  }

  // Splicing in the unread items line must ensure the item that comes afterwards is not stacked.
  if (after[0].type === ITEM) {
    after[0] = {
      ...after[0],
      isStacked: false,
    };
  }

  return [...items.slice(0, index), unreadItem, ...after];
}

function findItemIndexFromBack(items, item) {
  let index = -1;
  if (item && items.length) {
    for (let i = items.length - 1; i >= 0; i--) {
      if (items[i].id === item.id) {
        index = i;
        break;
      }
    }
  }
  return index;
}
