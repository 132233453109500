import moment from 'moment';

export default class TimestampFormatter {
  constructor(timestamp) {
    this.moment = moment(timestamp);
  }

  full() {
    return this.moment.format('MMM D, YYYY h:mma');
  }

  relative() {
    if (
      moment()
        .subtract(7, 'days')
        .isBefore(this.moment)
    ) {
      // between 7 days ago and now
      return this.ago();
    } else if (
      moment()
        .startOf('year')
        .isBefore(this.moment)
    ) {
      // between start of year and 7 days ago
      return this.moment.format('MMM D');
    } else {
      // before start of this year
      return this.moment.format('MMM D, YYYY');
    }
  }

  ago() {
    if (
      moment()
        .subtract(45, 'seconds')
        .isBefore(this.moment) &&
      moment()
        .add(45, 'seconds')
        .isAfter(this.moment)
    ) {
      return 'just now';
    }
    return this.moment.fromNow().replace('minutes', 'mins');
  }

  shortenedAgo() {
    if (
      moment()
        .subtract(45, 'seconds')
        .isBefore(this.moment) &&
      moment()
        .add(45, 'seconds')
        .isAfter(this.moment)
    ) {
      return 'just now';
    }
    return this.moment
      .fromNow()
      .replace('a minute', '1m')
      .replace('an hour', '1h')
      .replace('a day', '1d')
      .replace(' seconds', 's')
      .replace(' minutes', 'm')
      .replace(' hours', 'h')
      .replace(' days', 'd');
  }
}
