import createModel, { prop } from './lib/create_model';

const CustomChannelsConfig = createModel({
  modelName: 'CustomChannelsConfig',

  properties: {
    enabled: prop(Boolean).default(true),
  },

  statics: {
    create() {
      return new this();
    },
  },
});

export default CustomChannelsConfig;
