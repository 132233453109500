import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import T from 'prop-types';

import { H1 } from 'components/common/headers';
import Link from 'components/common/link';
import LiveboardType from 'models/liveboards/liveboard_type';
import { SLUG_IVR } from 'components/reporting/report_navigation';

export default class ReportHeader extends React.Component {
  constructor(props) {
    super(props);
    this.renderSecondaryElements = this.renderSecondaryElements.bind(this);
  }

  /* Rendering */
  render() {
    return (
      <div className="reportHeader">
        <div className="reportHeader-title">
          <H1>{this.props.title}</H1>
        </div>
        <div className="reportHeader-info">
          {this.renderTabs()}
          {this.renderSecondaryElements()}
        </div>
      </div>
    );
  }

  renderTabs() {
    let tabs = _.map(this.props.reportSelectorOptions, tab => this.renderTab(tab));
    return <div className="reportHeader-tabs">{tabs}</div>;
  }

  renderTab(tab) {
    let tabUrl = this.props.getReportUrl(tab);
    let isActiveReport = tab === this.props.activeReport;
    if (tab === SLUG_IVR) {
      isActiveReport = this.props.activeReport === 'ivr-summary' || this.props.activeReport === 'ivr-end-states';
    }
    return (
      <Link
        className={classnames('reportHeader-tab', { 'reportHeader-activeTab': isActiveReport })}
        href={tabUrl}
        key={`reportHeaderTab-${tab}`}
        onClick={this.onReportClick.bind(this, tabUrl)}
      >
        {this.getTabName(tab)}
      </Link>
    );
  }

  getTabName(tab) {
    const DEFAULT_RESULT = tab.toLowerCase();

    switch (tab) {
      case SLUG_IVR:
        return tab;
      case 'agents': {
        if (this.props.title !== 'Liveboards') {
          return 'Agent Performance';
        }
        return DEFAULT_RESULT;
      }
      case LiveboardType.PEOPLE_MATCH: {
        return 'People Match';
      }
      default:
        return DEFAULT_RESULT;
    }
  }

  onReportClick(url) {
    this.props.onReportClick(url);
  }

  renderSecondaryElements() {
    if (this.props.renderSecondaryElements) {
      return this.props.renderSecondaryElements();
    }
  }
}

ReportHeader.propTypes = {
  activeReport: T.string.isRequired,
  getReportUrl: T.func.isRequired,
  onReportClick: T.func.isRequired,
  renderSecondaryElements: T.func,
  reportSelectorOptions: T.array,
  title: T.string.isRequired,
};
