import { useEffect } from 'react';

import focusEndOfEditor from '../lib/focus_end_of_editor';

// If autoFocus is true, we focus at the end of the editor.
export default function useAutofocus(editor, autoFocus) {
  useEffect(() => {
    if (autoFocus) {
      focusEndOfEditor(editor);
    }
  }, [autoFocus]);
}
