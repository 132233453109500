import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AgentAvailability } from 'models/agent_status';
import { AgentStatusCard, AgentStatusCardColumns } from './charts/agent_status_card';
import connect from 'components/lib/connect';
import { EndpointTypes } from 'models/endpoint';
import { PreferencesStatus, RoutingChannel } from 'models/agent_routing_preferences';
import { SessionWork } from 'models/liveboards/agents_liveboard';

const IDLE_ACTIVITY = 'Idle';
const DEFAULT_REASON = 'Loading...';

export function AgentsLiveboard({ shouldDisplayFocus, idleAndAwayAgents, isChatEnabled, workingAgents }) {
  return (
    <div className={classnames('agentsLiveboard', 'liveboards-board', 'liveboards-agents')}>
      {renderUtilizationStats()}
    </div>
  );

  function renderUtilizationStats() {
    let fields = [
      AgentStatusCardColumns.AVAILABILITY,
      AgentStatusCardColumns.NAME,
      AgentStatusCardColumns.CURRENT,
      AgentStatusCardColumns.ACTIVE_REASON,
      AgentStatusCardColumns.DURATION,
    ];

    if (shouldDisplayFocus) {
      fields.splice(1, 0, AgentStatusCardColumns.FOCUS);
    }

    return (
      <div className="agentsLiveboard-statuses">
        <WorkingAgentsCard
          data={workingAgents}
          fields={fields}
          isChatEnabled={isChatEnabled}
          shouldDisplayFocus={shouldDisplayFocus}
          showSortControl
          title={`Working Agent${workingAgents.length === 1 ? '' : 's'}`}
        />
        <AgentStatusCard
          data={idleAndAwayAgents}
          fields={[AgentStatusCardColumns.NAME, AgentStatusCardColumns.AWAY_REASON, AgentStatusCardColumns.DURATION]}
          title={`Away Agent${idleAndAwayAgents.length === 1 ? '' : 's'}`}
        />
      </div>
    );
  }
}

AgentsLiveboard.propTypes = {
  idleAndAwayAgents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      duration: PropTypes.number,
      reason: PropTypes.string,
    })
  ),
  shouldDisplayFocus: PropTypes.bool,
  isChatEnabled: PropTypes.bool,
  workingAgents: PropTypes.arrayOf(
    PropTypes.shape({
      currentlyOn: PropTypes.shape({
        call: PropTypes.bool,
        activity: PropTypes.string,
        activeSessions: PropTypes.arrayOf(
          PropTypes.shape({
            conversationId: PropTypes.string,
            customerId: PropTypes.string,
            startedAt: PropTypes.string,
            sessionTypes: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        sessionWork: SessionWork,
      }),
      id: PropTypes.string,
      name: PropTypes.string,
      availableChannels: PropTypes.arrayOf(PropTypes.oneOf(_.values(RoutingChannel))),
      routingPreferences: PropTypes.shape({
        status: PropTypes.oneOf(_.values(PreferencesStatus)),
        updatedAt: PropTypes.string,
      }),
    })
  ),
};

function mapStateToProps({ getProvider }) {
  const agentsProvider = getProvider('agents');
  const agentStatusReasonProvider = getProvider('agentStatusReasons');
  const liveboard = getProvider('liveboard').get() || {};

  const messagingConfiguration = getProvider('messagingConfiguration').get();
  const shouldDisplayFocus = messagingConfiguration && messagingConfiguration.enableFocusMode;

  const statusGroups = getStatusGroups();

  const channelConfiguration = getProvider('channelConfiguration').get();
  const isChatEnabled = channelConfiguration && channelConfiguration.isChannelEnabled(EndpointTypes.CHAT);

  return {
    shouldDisplayFocus,
    idleAndAwayAgents: statusGroups.idleAndAway,
    isChatEnabled,
    workingAgents: statusGroups.working,
  };

  function getAgentStatusReason(reasonId) {
    return _.get(agentStatusReasonProvider.findBy({ id: reasonId }), 'name') || DEFAULT_REASON;
  }

  function getStatusGroups() {
    let utilStats = liveboard.utilization || [];

    let nameStats = utilStats.map(utilStat => {
      const agent = agentsProvider.findBy({ id: utilStat.agentId });
      return _.extend(utilStat.toJs(), { name: (agent && agent.getDisplayName()) || 'Agent' });
    });

    let idleAndAway = [];
    let working = [];

    nameStats.forEach(stats => {
      const isAway = stats.status === AgentAvailability.AWAY;
      if (isAway) {
        idleAndAway.push({
          id: stats.agentId,
          name: stats.name,
          duration: stats.statusReasonId
            ? moment().diff(stats.statusUpdatedAt, 'ms')
            : moment().diff(stats.presenceUpdatedAt, 'ms'),
          awayReason: stats.statusReasonId ? getAgentStatusReason(stats.statusReasonId) : IDLE_ACTIVITY,
        });
      } else {
        let currentlyOn = {
          call: !!stats.onCall,
          activity: stats.activity,
          activeSessions: stats.activeSessions,
        };

        if (stats.sessionWork) {
          currentlyOn.sessionWork = stats.sessionWork;
        }

        if (currentlyOn.activity) {
          working.push({
            currentlyOn,
            id: stats.agentId,
            name: stats.name,
            availableChannels: stats.availableChannels,
            routingPreferences: stats.routingPreferences,
            activeReason: stats.statusReasonId ? getAgentStatusReason(stats.statusReasonId) : '',
            duration: stats.statusUpdatedAt ? moment().diff(stats.statusUpdatedAt, 'ms') : undefined,
          });
        }
      }
    });

    idleAndAway = _.sortBy(idleAndAway, agent => agent.name.toLowerCase());
    working = _.sortBy(working, agent => agent.name.toLowerCase());

    return {
      working,
      idleAndAway,
    };
  }
}

const AgentsLiveboardContainer = connect(mapStateToProps)(AgentsLiveboard);

export default AgentsLiveboardContainer;

export const WorkingAgentsCard = styled(AgentStatusCard)`
  flex: ${p => (p.isChatEnabled ? 3 : 2)} 0 205px;
`;
