import { focusEditor } from '@udecode/plate';

/**
 * Tries to focus the editor at target, catching the exceptions that may occasionally fly out. Slate sometimes likes
 * to throw inexplicable node mapping errors that are random and don't seem to interrupt anything but create a lot of
 * "sentry noise". We try to focus editor async to give slate-react a chance to finish updating the DOM
 *
 * @param editor
 * @param focusTarget
 */
export function tryFocusEditorAsync(editor, focusTarget) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        focusEditor(editor, focusTarget);
        resolve();
      } catch (err) {
        reject(err);
      }
    }, 0);
  });
}
