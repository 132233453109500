import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';

import CircleIcon from './icons/circle_icon';
import styled from 'styled-components';
import AddUserFillIcon from 'components/common/icons/fill/add-user-fill';

export default class ConferenceButton extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }
  render() {
    return (
      <CircleIcon viewBox="0 0 25 25" {...this.props}>
        <StyledAddUserFillIcon />
      </CircleIcon>
    );
  }
}

const StyledAddUserFillIcon = styled(AddUserFillIcon)`
  path {
    transform: scale(0.6);
    transform-origin: center;
  }
`;
