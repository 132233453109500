import React from 'react';

import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM6.35355 6.64645C6.15829 6.45118 5.84171 6.45118 5.64645 6.64645C5.45118 6.84171 5.45118 7.15829 5.64645 7.35355L7.64645 9.35355C7.84171 9.54882 8.15829 9.54882 8.35355 9.35355L10.3536 7.35355C10.5488 7.15829 10.5488 6.84171 10.3536 6.64645C10.1583 6.45118 9.84171 6.45118 9.64645 6.64645L8 8.29289L6.35355 6.64645Z"
      fillRule="evenodd"
    />
  </IconContainer>
);
