import ConversationItem from '../conversation_item';
import createModel, { prop } from '../lib/create_model';
import CustomerProfile from '../customer_profile';
import NotificationType from './notification_type';

export default createModel({
  modelName: 'ConversationNotification',

  properties: {
    id: prop(String), // AD only field
    conversationId: prop(String),
    conversationItem: prop(ConversationItem),
    profile: prop(CustomerProfile),
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.ASSIGNED_CONVERSATION });
  },
});
