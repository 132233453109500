export default class UpdateCustomAttributes {
  constructor(context) {
    this.context = context;
  }

  run(updates) {
    let customerProfile = this.context.stores.profile.get();
    customerProfile.updateCustomAttributes(updates);

    this.context.stores.profile.setPending(customerProfile);
    this.context.gateways.customerProfile.update(
      { customerId: customerProfile.id },
      {
        customAttributes: updates,
      }
    );
  }
}
