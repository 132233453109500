import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import connect from 'components/lib/connect';
import { RoutingChannel } from 'models/agent_routing_preferences';

function NextCallRoutingTimer({ nextCallRoutingTime, shouldDisplayTimer }) {
  const calculateTimeLeft = () => {
    let timeUntilNextCall = nextCallRoutingTime ? moment(nextCallRoutingTime).diff(moment.now(), 'seconds') : 0;
    if (timeUntilNextCall <= 0) {
      return '';
    }

    let minutes = Math.floor((timeUntilNextCall / 60) % 60);
    let seconds = Math.floor(timeUntilNextCall % 60);

    return minutes
      ? `${minutes}:${seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`
      : seconds.toString();
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (!shouldDisplayTimer || !timeLeft) {
    return null;
  }

  return <div>Next call in {timeLeft}</div>;
}

NextCallRoutingTimer.propTypes = {
  nextCallRoutingTime: PropTypes.string,
  shouldDisplayTimer: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(NextCallRoutingTimer);

function mapStateToProps({ getProvider }) {
  const preferencesProvider = getProvider('agentRoutingPreferences');
  const preferences = preferencesProvider.getPending() || preferencesProvider.get();
  const isVoicePreferred = preferences.isPreferredOnChannel(RoutingChannel.VOICE);
  const isFocusOn = preferences.isFocusOn;

  const nextCallRoutingTime = preferences.nextCallRoutingTime;
  const shouldDisplayTimer = isVoicePreferred && !isFocusOn;

  return {
    nextCallRoutingTime,
    shouldDisplayTimer,
  };
}
