import _ from 'lodash';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import FacebookMessengerStrokeIcon from 'components/common/icons/stroke/facebook-messenger-stroke';
import InstagramStrokeIcon from 'components/common/icons/stroke/instagram-stroke';
import InteractionType from 'models/interaction_type';
import VoiceStrokeIcon from 'components/common/icons/stroke/voice-stroke';
import ReportDuration from 'components/reporting/report/lib/report_duration';
import SmsStrokeIcon from 'components/common/icons/stroke/sms-stroke';
import Tooltip from 'components/common/tooltip';
import TwitterFillIcon from 'components/common/icons/fill/twitter-fill';
import WhatsappStrokeIcon from 'components/common/icons/stroke/whatsapp-stroke';

class PillList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    _.bindAll(this, 'renderPill', 'renderMenuItem');
  }

  UNSAFE_componentWillMount() {
    this.calculateWidth = _.throttle(() => {
      const element = document.getElementsByClassName(this.props.className)[0];
      if (element) {
        this.setState({ width: element.offsetWidth });
      }
    }, 100);
  }

  componentDidMount() {
    this.calculateWidth();
    window.addEventListener('resize', this.calculateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateWidth);
  }

  render() {
    let maxLength = this.props.items.length;
    if (maxLength * this.props.pillWidth > this.state.width) {
      maxLength = _.floor(this.state.width / this.props.pillWidth) - 1;
    }
    return (
      <div className={this.props.className}>
        {_.take(this.props.items, maxLength).map(this.renderPill)}
        {this.renderAdditionalItems(_.drop(this.props.items, maxLength))}
      </div>
    );
  }

  renderPill(item, index) {
    return <Pill className="pillList-pill" key={`pill-${index}`} onClick={this.props.onClick} {...item} />;
  }

  renderAdditionalItems(items) {
    if (items.length === 0) {
      return null;
    }
    return (
      <div className="pillList-pill pillList-pill-hover">
        + {items.length} more
        <div className="pillList-menu-container">
          <div className="pillList-menu">{items.map(this.renderMenuItem)}</div>
        </div>
      </div>
    );
  }

  renderMenuItem(item, index) {
    return <Pill className="pillList-menu-item" key={`pill-menu-${index}`} onClick={this.props.onClick} {...item} />;
  }
}

export const Pill = props => {
  const classNames = classnames(props.className, { 'pillList-pill-hover': props.clickable });
  return (
    <Tooltip message={props.tooltip ? props.tooltip : props.text} position="top">
      <div className={classNames} onClick={onClick}>
        {renderPillIcon(props.showIcon, props.sessionType)}
        {props.duration ? (
          <ReportDuration className="pillList-duration" duration={props.duration} simplified />
        ) : (
          <div className="pillList-pill-text">{props.text}</div>
        )}
      </div>
    </Tooltip>
  );

  function renderPillIcon(showIcon, sessionType) {
    if (!showIcon) {
      return;
    }
    switch (sessionType) {
      case InteractionType.FB_MESSENGER:
        return <FacebookMessengerStrokeIcon className="pillList-pill-icon pillList-pill-icon-phone" />;
      case InteractionType.INSTAGRAM_DIRECT:
        return <InstagramStrokeIcon className="pillList-pill-icon pillList-pill-icon-phone" />;
      case InteractionType.PHONE_CALL:
        return <VoiceStrokeIcon className="pillList-pill-icon pillList-pill-icon-phone" />;
      case InteractionType.SMS:
        return <SmsStrokeIcon className="pillList-pill-icon pillList-pill-icon-phone" />;
      case InteractionType.TWITTER:
        return <TwitterFillIcon className="pillList-pill-icon pillList-pill-icon-phone" />;
      case InteractionType.WHATSAPP:
        return <WhatsappStrokeIcon className="pillList-pill-icon pillList-pill-icon-phone" />;
      default:
        return <ChatIconOutline className="pillList-pill-icon" />;
    }
  }

  function onClick() {
    props.clickable && props.onClick(props.clickParams);
  }
};

const PillItem = {
  clickable: PropTypes.bool,
  clickParams: PropTypes.any,
  duration: PropTypes.number,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  showIcon: PropTypes.bool,
  sessionType: PropTypes.string,
};

Pill.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  ...PillItem,
};

PillList.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  pillWidth: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape(PillItem)),
};

export default PillList;
