import _ from 'lodash';
import classnames from 'classnames';
import murmurhash from 'murmurhash-js';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ConversationTopicsMenuV2 from 'components/customer/conversation_history/topics_v2/conversation_topics_menu_v2';
import ConversationTopicsMenuOpenerV2 from 'components/customer/conversation_history/topics_v2/conversation_topics_menu_opener_v2';
import {
  COLLAPSE_SELECTED_OPTIONS_NUM,
  SelectAction,
  TopicsMenuOptionType,
} from 'components/customer/conversation_history/topics_v2/conversation_topics_constants_v2';
import { CustomAttributeValue } from 'models/custom_attribute';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';

export default class ConversationTopicsMultiSelectV2 extends PureComponent {
  /* life cycle */

  constructor(props) {
    super(props);

    let selectedValues = this.getInitialSelectedValues();
    let options = this.getOptions(null, this.props.options, selectedValues, this.props.suggestedIds || []);
    this.state = {
      filter: '',
      selectionsExpanded: false,
      selectedValues,
      opened: false,
      options,
    };
    this.suppressNextMouseEvent = true;

    _.bindAll(this, [
      'blur',
      'getInitialFocusedOptionId',
      'filterOptions',
      'focus',
      'handleApply',
      'handleBlur',
      'handleCancel',
      'handleClose',
      'handleCollapseSelections',
      'handleExpandSelections',
      'handleHeaderClick',
      'handleInputChange',
      'handleInputKeyDown',
      'handleMenuFocus',
      'handleMenuOpenerClick',
      'handleOpen',
      'handleOptionClick',
      'handleOptionFocus',
      'handleOutsideClick',
      'handleSubmit',
      'renderMenu',
      'setMenuRef',
      'setOpenerRef',
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let nextPropOptions = nextProps.options;

    let selectedValues = this.state.selectedValues;
    if (
      !_.isEqual(this.props.optionIds, nextProps.optionIds) ||
      !_.isEqual(this.props.selectedCustomAttributes, nextProps.selectedCustomAttributes)
    ) {
      let nextOptionIds = nextProps.optionIds || [];
      let nextAttributeValues = nextProps.selectedCustomAttributes || [];

      selectedValues = { attributeValues: nextAttributeValues, optionIds: nextOptionIds };

      this.setState({
        selectedValues,
      });
    }

    let nextOptions = this.getOptions(
      this.state.options,
      nextPropOptions,
      selectedValues,
      nextProps.suggestedIds || []
    );
    const hasNewOptions = nextOptions.length !== this.state.options.length;
    const hasChangedOptions = _.intersection(nextOptions, this.state.options).length !== this.state.options.length;

    if (hasNewOptions || hasChangedOptions) {
      this.setState({
        options: nextOptions,
      });
    }
    if (
      (nextProps.suggestedIds || []).length !== (this.props.suggestedIds || []).length ||
      _.difference(nextProps.suggestedIds, this.props.suggestedIds).length > 0
    ) {
      this.setState({
        focusedOptionId: undefined,
      });
    }

    if (nextProps.lastOpenedAt && nextProps.lastOpenedAt !== this.props.lastOpenedAt) {
      this.handleOpen();
    }
  }

  /* api */

  blur() {
    this.opener.blur();
  }

  focus() {
    this.handleOpen();
    this.opener.focus();
  }

  /* event handlers */

  handleBlur() {
    // determine if a custom attribute option has been focused. if so, do not close the menu
    const focusedOptionId = this.state.focusedOptionId || this.getInitialFocusedOptionId();
    const option = _.find(this.props.options, t => t.id === focusedOptionId);
    if (option && option.type === TopicsMenuOptionType.CUSTOM_ATTRIBUTE) {
      return;
    }

    if (this.isCancelled) {
      return;
    }
    this.saveOptionChanges();
    this.canClose = true;
    this.blur();
    this.handleClose();
  }

  handleApply(ev) {
    this.overrideEvent(ev);
    this.saveOptionChanges();
    this.closeMenu();
  }

  handleCancel(ev) {
    this.isCancelled = true;
    this.overrideEvent(ev);
    this.closeMenu();
  }

  closeMenu() {
    this.opener.focus();
    this.canClose = true;
    this.blur();
  }

  handleClose() {
    if (this.ignoreClose) {
      this.opener.focus();
      this.ignoreClose = false;
      return;
    }
    if (this.canClose) {
      this.props.onClose && this.props.onClose();
      this.canClose = false;
      this.setState({
        opened: false,
        filter: '',
        selectionsExpanded: false,
      });
    }
  }

  handleCollapseSelections(ev) {
    this.overrideEvent(ev);
    this.collapseSelections();
  }

  handleEnter() {
    let option = _.find(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    switch (option && option.type) {
      case TopicsMenuOptionType.OPTION:
        if (option.optionType === TopicsMenuOptionType.CUSTOM_ATTRIBUTE && !option.selected) {
          const inputRef = this.menu.getMenuOptionInputRef(option.id);
          if (inputRef?.current && inputRef.current.value === '') {
            // Focus the input field for the custom attribute option
            inputRef.current.focus();
          } else {
            this.opener.focus();
          }
        } else {
          this.toggleOptionSelection(option);
          this.opener.focus();
          this.setState({ filter: '', focusedOptionId: this.getNextFilteredOptionId() });
        }
        break;

      case TopicsMenuOptionType.EXPANDER:
        this.expandSelections();
        this.setState({ focusedOptionId: TopicsMenuOptionType.COLLAPSER });
        break;

      case TopicsMenuOptionType.COLLAPSER:
        this.collapseSelections();
        this.setState({ focusedOptionId: TopicsMenuOptionType.EXPANDER });
        break;

      default:
        return;
    }
  }

  handleExpandSelections(ev) {
    this.overrideEvent(ev);
    this.expandSelections();
  }

  handleHeaderClick() {
    this.resetFocusedOptionId();
  }

  handleInputChange(ev) {
    let input = ev.target.value;
    this.setState({ filter: input });
    this.filteredOptions = this.filterOptions(
      this.state.options,
      input,
      this.state.selectedValues.optionIds,
      this.state.selectedValues.attributeValues
    );
    this.resetFocusedOptionId();
    this.props.onSearch && this.props.onSearch();
  }

  handleInputKeyDown(ev) {
    if (!this.state.opened) return;

    this.suppressNextMouseEvent = true;
    const hasOptions = this.filteredOptions.length > 0;

    switch (ev.keyCode) {
      case 9: // tab
        this.overrideEvent(ev);
        this.saveOptionChanges();
        this.closeMenu();
        break;
      case 13: // enter
        this.overrideEvent(ev);
        this.handleEnter();
        break;
      case 27: {
        // esc
        this.overrideEvent(ev);

        const option = _.find(this.props.options, t => t.id === this.state.focusedOptionId);
        if (option && option.type !== TopicsMenuOptionType.CUSTOM_ATTRIBUTE) {
          this.closeMenu();
          return;
        }

        this.saveOptionChanges();
        this.canClose = true;
        this.blur();
        this.handleClose();
        break;
      }
      case 37: // left
        if ((ev.metaKey || ev.ctrlKey) && this.state.selectionsExpanded) {
          this.overrideEvent(ev);
          this.collapseSelections();
          hasOptions && this.setFocusedOptionId(TopicsMenuOptionType.EXPANDER);
        }
        break;
      case 38: // up
        this.overrideEvent(ev);
        if (ev.metaKey || ev.ctrlKey) {
          this.menu.scrollToTop();
          this.resetFocusedOptionId();
        } else {
          hasOptions && this.setFocusedOptionId(this.getPreviousFilteredOptionId());
        }
        break;
      case 39: // right
        if ((ev.metaKey || ev.ctrlKey) && !this.state.selectionsExpanded) {
          this.overrideEvent(ev);
          this.expandSelections();
          hasOptions && this.setFocusedOptionId(TopicsMenuOptionType.COLLAPSER);
        }
        break;
      case 40: // down
        this.overrideEvent(ev);
        if (ev.metaKey || ev.ctrlKey) {
          this.menu.scrollToBottom();
          this.setFocusedOptionId(this.getLastFilteredOptionId());
        } else {
          hasOptions && this.setFocusedOptionId(this.getNextFilteredOptionId());
        }
        break;
      default:
        break;
    }
  }

  handleMenuFocus(ev) {
    this.ignoreClose = true;
  }

  handleMenuOpenerClick(event) {
    if (!this.state.opened) {
      this.focus();
      this.props.onFocus && this.props.onFocus(this);
    }
  }

  handleOpen() {
    this.props.onOpen && this.props.onOpen();
    this.resetFocusedOptionId();
    this.setState({
      opened: true,
    });
    this.suppressNextMouseEvent = true;
    this.isCancelled = false;
  }

  /* process clicking option on mouse down to precede react-select's internal processing */
  handleOptionClick(ev, option) {
    this.overrideEvent(ev);
    this.toggleOptionSelection(option);
    this.setFocusedOptionId(this.getNextFilteredOptionId());
    this.setState({ filter: '' });
  }

  handleOptionFocus(option) {
    /* mouse and keyboard interaction are a bit funky. When we scroll the menu to focus an option currently out
     * of view, the mouse enters a new option which we would focus, removing focus on the one navigated to by
     * keyboard. This prevents the behavior.
     */
    if (this.suppressNextMouseEvent) {
      this.suppressNextMouseEvent = false;
      return;
    }
    this.setFocusedOptionId(option.optionId);
  }

  handleOutsideClick() {
    if (this.state.opened) {
      this.props.onOptionChange(this.state.selectedValues);
      this.props.onClose && this.props.onClose();
      this.canClose = false;
      this.setState({
        opened: false,
        filter: '',
        selectionsExpanded: false,
      });
    }
  }

  handleSubmit(attributeValue) {
    if (_.some(this.state.selectedValues.attributeValues, attributeValue)) {
      this.setState({
        filter: '',
      });
      return;
    }

    const selectedValues = {
      ...this.state.selectedValues,
      attributeValues: this.state.selectedValues.attributeValues.concat(attributeValue),
    };

    const nextOptions = this.getOptions(
      this.state.options,
      this.props.options,
      selectedValues,
      this.props.suggestedIds || []
    );
    this.setState({
      selectedValues,
      options: nextOptions,
      filter: '',
    });
  }

  /* presentation */

  render() {
    let className = classnames('conversationTopic-multi', this.props.className);

    let options = this.filterOptions(
      this.state.options,
      this.state.filter,
      this.state.selectedValues.optionIds,
      this.state.selectedValues.attributeValues
    );
    this.filteredOptions = options;
    if (options.length > 0) {
      this.focusedOptionId = this.state.focusedOptionId || this.getInitialFocusedOptionId();
    }
    return (
      <div
        className={className}
        onBlur={this.handleBlur}
        onClick={this.handleMenuOpenerClick}
        onKeyDown={this.handleInputKeyDown}
      >
        {this.renderMenuOpener()}
        {this.renderMenu(options)}
      </div>
    );
  }

  renderMenu(options) {
    if (!this.state.opened) {
      return null;
    }
    return (
      <OutsideClickHandler onClickOutside={this.handleOutsideClick}>
        <ConversationTopicsMenuV2
          dropDown={this.props.dropDown}
          focusedOptionId={this.focusedOptionId}
          numSelectedOptions={
            this.state.selectedValues.optionIds.length + this.state.selectedValues.attributeValues.length
          }
          onApply={this.handleApply}
          onCancel={this.handleCancel}
          onCollapserClick={this.handleCollapseSelections}
          onExpanderClick={this.handleExpandSelections}
          onFocus={this.handleMenuFocus}
          onHeaderClick={this.handleHeaderClick}
          onOptionClick={this.handleOptionClick}
          onOptionFocus={this.handleOptionFocus}
          onSubmit={this.handleSubmit}
          options={options}
          ref={this.setMenuRef}
          unitLabelPlural={this.props.unitLabelPlural}
          unitLabelSingular={this.props.unitLabelSingular}
        />
      </OutsideClickHandler>
    );
  }

  renderMenuOpener() {
    const selectedTopics = _.compact(
      _.map(this.state.selectedValues.optionIds, id => _.find(this.props.options, { id })?.name)
    );

    const selectedCustomAttributes = _.compact(
      _.map(this.state.selectedValues.attributeValues, ({ id, value }) => {
        let option = _.find(this.props.options, { id });
        return option && `${option.name} > ${value}${option.disabled ? ' (Archived)' : ''}`;
      })
    );

    const sortedTopicsByName = _.sortBy([...selectedTopics, ...selectedCustomAttributes], value => value.toLowerCase());

    return (
      <ConversationTopicsMenuOpenerV2
        inputValue={this.state.filter}
        isOpen={this.state.opened}
        onInputBlur={this.handleClose}
        onInputChange={this.handleInputChange}
        placeholder={this.props.placeholder}
        ref={this.setOpenerRef}
        values={sortedTopicsByName}
      />
    );
  }

  setOpenerRef(ref) {
    this.opener = ref;
  }

  /* utility */

  collapseSelections() {
    this.setState({
      selectionsExpanded: false,
    });
  }

  expandSelections() {
    this.setState({
      selectionsExpanded: true,
    });
  }

  filterOptions(options, filter, selectedTopicIds, selectedAttributeValues) {
    let selectedIds = {};
    _.forEach(selectedTopicIds, id => {
      selectedIds[id] = true;
    });
    let filteredOptions = _(options)
      .map(o => {
        return {
          ...o,
          selected: !!selectedIds[o.id],
          wasSelected: !!selectedIds[o.id] || o.wasSelected,
        };
      })
      .filter(o => filter === '' || o.selected || o.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
      .sortBy(o => o.label.toLowerCase())
      .value();

    let selectedOptions = _.filter(filteredOptions, o => o.selected);
    let suggestedOptions = _.filter(filteredOptions, o => o.suggested && !o.selected && !o.wasSelected);
    suggestedOptions = _.map(suggestedOptions, o => ({
      ...o,
      optionId: `${o.optionId}-suggested`,
    }));
    let unselectedOptions = _.filter(filteredOptions, o => !o.selected).map(o => ({ ...o, suggested: false }));
    unselectedOptions = _.filter(unselectedOptions, o => !o.archived);

    for (let i = 0; i < selectedAttributeValues.length; i++) {
      const attr = selectedAttributeValues[i];
      const option = _.find(options, t => t.id === attr.id);
      if (!option) {
        continue;
      }

      const hashValue = murmurhash.murmur3(`${option.id}-${attr.value}`).toString();
      selectedOptions.push({
        ...option,
        optionId: hashValue,
        selected: true,
        wasSelected: true,
        label: `${option.label} > ${attr.value} ${option.archived ? ' (Archived)' : ''}`,
        optionValue: attr.value,
      });
    }

    let currentOptions = this.collapseOrExpandOptions(selectedOptions);
    /* add divider between selected and unselected options */
    if (selectedOptions.length > 0 && unselectedOptions.length > 0) {
      currentOptions.push({
        disabled: true,
        type: TopicsMenuOptionType.DIVIDER,
        unfocusable: true,
        optionId: TopicsMenuOptionType.DIVIDER,
      });
    }

    if (suggestedOptions.length > 0 && this.props.suggestedLabel) {
      currentOptions.push({
        label: this.props.suggestedLabel,
        disabled: true,
        type: TopicsMenuOptionType.GROUP_TITLE,
        unfocusable: true,
        optionId: 'suggested-topics-title',
      });
      currentOptions = _.concat(currentOptions, suggestedOptions);
    }

    if (unselectedOptions.length > 0 && this.props.unselectedLabel) {
      currentOptions.push({
        label: this.props.unselectedLabel,
        disabled: true,
        type: TopicsMenuOptionType.GROUP_TITLE,
        unfocusable: true,
        optionId: 'all-topics-title',
      });
    }

    return _.concat(currentOptions, unselectedOptions);
  }

  collapseOrExpandOptions(selectedOptions) {
    /* collapse options or add ability to collapse them */
    if (selectedOptions.length > COLLAPSE_SELECTED_OPTIONS_NUM) {
      if (this.state.selectionsExpanded) {
        selectedOptions.push({
          disabled: true,
          type: TopicsMenuOptionType.COLLAPSER,
          optionId: TopicsMenuOptionType.COLLAPSER,
        });
      } else {
        let numCollapsedOptions = selectedOptions.length - COLLAPSE_SELECTED_OPTIONS_NUM;
        selectedOptions.splice(COLLAPSE_SELECTED_OPTIONS_NUM, numCollapsedOptions, {
          disabled: true,
          type: TopicsMenuOptionType.EXPANDER,
          optionId: TopicsMenuOptionType.EXPANDER,
        });
      }
    }
    return selectedOptions;
  }

  getInitialSelectedValues() {
    const optionIds = this.props.optionIds || [];
    const attributeValues = this.props.selectedCustomAttributes || [];
    return { attributeValues, optionIds };
  }

  getNextFilteredOptionId() {
    let option;
    let index = _.findIndex(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    do {
      index = (index + 1) % this.filteredOptions.length;
      option = this.filteredOptions[index];
    } while (option.unfocusable);
    return option.optionId;
  }

  getPreviousFilteredOptionId() {
    let option;
    let index = _.findIndex(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    do {
      index--;
      if (index < 0) {
        index = this.filteredOptions.length - 1;
      }
      option = this.filteredOptions[index];
    } while (option.unfocusable);
    return option.optionId;
  }

  getLastFilteredOptionId() {
    let last = _.last(this.filteredOptions);
    return last.optionId;
  }

  getOptions(currentOptions, newOptions, selectedValues, suggestedValues) {
    const selectedSet = new Set(selectedValues.optionIds);
    const suggestedSet = new Set(suggestedValues);
    const wasSelectedSet = new Set(_.filter(currentOptions || [], o => o.wasSelected));
    return newOptions.map(t => {
      let id = t.id;
      let suggested = suggestedSet.has(id);
      let selected = selectedSet.has(id);
      let wasSelected = wasSelectedSet.has(id) || selected;
      return {
        archived: t.disabled, // Can't use 'disabled' as key since that affects react-select in undesirable way.
        label: t.name,
        selected,
        wasSelected,
        type: TopicsMenuOptionType.OPTION,
        suggested,
        optionId: id,
        optionType: t.type,
        id,
      };
    });
  }

  overrideEvent(ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  getInitialFocusedOptionId() {
    if (this.filteredOptions.length > 0) {
      let firstTitleIndex = _.findIndex(this.filteredOptions, { type: TopicsMenuOptionType.GROUP_TITLE });
      if (firstTitleIndex !== -1) {
        return this.filteredOptions[firstTitleIndex + 1].optionId;
      }
      let dividerIndex = _.findIndex(this.filteredOptions, { type: TopicsMenuOptionType.DIVIDER });
      if (dividerIndex !== -1) {
        return this.filteredOptions[dividerIndex + 1].optionId;
      }
      return this.filteredOptions[0] && this.filteredOptions[0].optionId;
    }
  }

  resetFocusedOptionId() {
    this.setFocusedOptionId(this.getInitialFocusedOptionId());
  }

  saveOptionChanges() {
    this.props.onOptionChange(this.state.selectedValues);
  }

  setFocusedOptionId(optionId) {
    this.opener.focus();
    this.focusedOptionId = optionId;
    this.setState({
      focusedOptionId: optionId,
    });
  }

  setMenuRef(ref) {
    this.menu = ref;
  }

  toggleOptionSelection(option) {
    if (option.disabled || (option.optionType === TopicsMenuOptionType.CUSTOM_ATTRIBUTE && !option.selected)) {
      // no-op if unselected custom attribute is clicked
      return;
    }

    const selectionType = option.suggested ? 'suggestion' : 'general-list';
    const action = option.selected ? SelectAction.REMOVE : SelectAction.ADD;

    this.props.onSelect &&
      this.props.onSelect({
        topicId: option.id,
        topicName: option.label,
        action,
        type: option.optionType,
        selectionType,
      });

    const optionIds = option.selected
      ? _.filter(this.state.selectedValues.optionIds, v => v !== option.id)
      : [...this.state.selectedValues.optionIds, option.id];

    let selectedValues;
    if (option.optionType === TopicsMenuOptionType.TOPIC) {
      selectedValues = { ...this.state.selectedValues, optionIds };
    } else {
      selectedValues = {
        ...this.state.selectedValues,
        attributeValues: _.filter(
          this.state.selectedValues.attributeValues,
          attr => !(attr.id === option.id && attr.value === option.optionValue)
        ),
      };
    }

    let nextOptions = this.getOptions(
      this.state.options,
      this.props.options,
      selectedValues,
      this.props.suggestedIds || []
    );
    this.setState({ selectedValues, options: nextOptions });
  }
}

ConversationTopicsMultiSelectV2.propTypes = {
  className: PropTypes.string,
  dropDown: PropTypes.bool,
  label: PropTypes.string,
  lastOpenedAt: PropTypes.string,
  optionIds: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  onOpen: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onOptionChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selectedCustomAttributes: PropTypes.arrayOf(PropTypes.instanceOf(CustomAttributeValue)),
  suggestedIds: PropTypes.array,
  suggestedLabel: PropTypes.string,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
  unselectedLabel: PropTypes.string,
};
