import _ from 'lodash';

import AgentInboxItem, { InboxItemType } from 'models/agent_inbox/agent_inbox_item';
import Communicator from 'models/communicator';
import Conversation from 'models/conversation';
import ConversationItem from 'models/conversation_item';
import ConversationItemType, { AutomaticConversationItemTypes } from 'models/conversation_item_type';
import CustomerProfile from 'models/customer_profile';

export function reconcileInboxItem(context, newInboxItem, store) {
  store = store || context.stores.agentInboxItems;

  const currentInboxItem = store.findBy({ id: newInboxItem.id });
  if (!currentInboxItem) {
    store.addOrReplace(newInboxItem);
    return newInboxItem;
  }

  let hasModified;
  if (currentInboxItem.removedAt) {
    currentInboxItem.resetRemovedAt();
    hasModified = true;
  }

  // Relevant only when customerListAssignmentBroadcast flag is enabled
  if (newInboxItem.hasFetchedAtLeastOnce && !currentInboxItem.hasFetchedAtLeastOnce) {
    currentInboxItem.setHasFetchedAtLeastOnce(true);
    hasModified = true;
  }

  // We'll update the conversation in our inbox item if we receive a conversation which is not
  // closed and:
  if (
    newInboxItem.conversation &&
    newInboxItem.conversation.status !== Conversation.Status.CLOSED &&
    // Either we don't have an existing conversation
    (!currentInboxItem.conversation ||
      // Or we received a new conversation
      newInboxItem.conversation.id !== currentInboxItem.conversation.id ||
      // Or we received an update to the existing conversation
      (newInboxItem.conversation.id === currentInboxItem.conversation.id &&
        newInboxItem.conversation.version > currentInboxItem.conversation.version))
  ) {
    currentInboxItem.setConversation(newInboxItem.conversation);
    hasModified = true;
  }

  if (
    newInboxItem.item &&
    // When the new item is either a non-automatic item with a conversation id or a task and..
    shouldHandleItem(newInboxItem.item) &&
    // Either we don't have an item
    (!currentInboxItem.item ||
      // Or we received a new item with a more recent timestamp
      new Date(newInboxItem.item.timestamp) > new Date(currentInboxItem.item.timestamp) ||
      // Or it's the same item but it was updated to a newer version
      (newInboxItem.item.id === currentInboxItem.item.id && newInboxItem.item.version > currentInboxItem.item.version))
  ) {
    currentInboxItem.setItem(newInboxItem.item);
    hasModified = true;
  }

  if (
    newInboxItem.profile &&
    (!currentInboxItem.profile || newInboxItem.profile._version > currentInboxItem.profile._version)
  ) {
    currentInboxItem.setProfile(newInboxItem.profile);
    hasModified = true;
  }

  if (hasModified) {
    store.addOrReplace(currentInboxItem);
  }

  return currentInboxItem;
}

export function convertInboxDtos(dtos) {
  return _.map(dtos, dto => {
    if (dto.task) {
      return AgentInboxItem.create({
        id: dto.task.id,
        item: ConversationItem.fromJs(dto.task),
        profile: CustomerProfile.fromJs(dto.customer),
        type: InboxItemType.TASK,
        hasFetchedAtLeastOnce: true,
      });
    }

    return AgentInboxItem.create({
      id: dto.customer.id,
      conversation: Conversation.fromJs(dto.conversation),
      item: dto.conversationItem && ConversationItem.fromJs(dto.conversationItem),
      profile: CustomerProfile.fromJs(dto.customer),
      type: InboxItemType.CUSTOMER,
      hasFetchedAtLeastOnce: true,
    });
  });
}

export function filterRemovedItems(context, inboxItems) {
  const inboxItemsStore = context.stores.appFeatures.get().isEnabled('customerListAssignmentBroadcast')
    ? context.stores.agentInboxItemsNew
    : context.stores.agentInboxItems;

  const removedInboxItemIds = inboxItemsStore.findAll({ filter: i => !!i.removedAt }).map(i => i.id);
  if (!removedInboxItemIds.length) {
    return inboxItems;
  }

  return _.filter(inboxItems, item => !_.includes(removedInboxItemIds, item.id));
}

export function shouldHandleItem(item) {
  const contentType = item.contentType();
  return (
    (contentType === ConversationItemType.TASK || item.conversationId) &&
    (item.initiator || {}).type !== Communicator.AUTOMATED &&
    AutomaticConversationItemTypes.indexOf(item.contentType()) === -1
  );
}

export function getInboxItemIdFromConversationItem(conversationItem) {
  return conversationItem.contentType() === ConversationItemType.TASK
    ? conversationItem.id
    : conversationItem.customerId;
}
