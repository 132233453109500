import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import EndChat from 'actions/chat/end_chat';
import { getActiveSessionOfTypeByCustomerForAgent } from 'actions/communication_session/session_finder';
import { getActiveConversationAttributes } from 'actions/conversation/lib/conversation_helpers';
import { hasActivePhoneCall } from 'scripts/application/lib/conversation_history_helpers';
import InteractionType from 'models/interaction_type';
import OpenTopics from 'actions/customer/open_topics';
import { PopoverMenuItem } from 'components/common/popover_menu_deprecated';
import SplitButton from 'components/common/split_button';
import Tooltip from 'components/common/tooltip';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useWindowSize from 'components/hooks/use_window_size';

export function EndAndCloseBase({ areTopicsRequired, hasAgentJoinedChat, includesActivePhoneCall, isEditorEmpty }) {
  const executeAction = useExecuteAction();
  const onClickEnd = useCallback(evt => {
    if (evt) {
      evt.preventDefault();
    }
    executeAction(EndChat);
  }, []);
  const onClickEndAndClose = useCallback(
    () => executeAction(EndChat, { conversationStatus: Conversation.Status.CLOSED }),
    []
  );
  const onApplyTopics = useCallback(() => executeAction(OpenTopics), []);

  const [isMountDisabled, setIsMountDisabled] = useState(!areTopicsRequired);
  useEffect(() => {
    if (isMountDisabled) {
      const timeout = setTimeout(() => {
        setIsMountDisabled(false);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isMountDisabled]);

  const { windowWidth } = useWindowSize();

  const items = [
    <Tooltip
      bounds={{ right: windowWidth - 8 }}
      delay={300}
      key="end"
      message={!hasAgentJoinedChat ? 'Join the chat to end' : undefined}
      position="left"
    >
      <PopoverMenuItem
        className="chatCompositionForm-splitButton-menuItem"
        data-aid="endChat"
        isDisabled={!hasAgentJoinedChat || !isEditorEmpty}
        onClick={onClickEnd}
      >
        End
      </PopoverMenuItem>
    </Tooltip>,
  ];

  if (areTopicsRequired) {
    return (
      <SplitButton
        className="chatCompositionForm-splitButton"
        menuItems={[
          ...items,
          <Tooltip
            bounds={{ right: windowWidth - 8 }}
            delay={300}
            key="end-and-close"
            message={
              !hasAgentJoinedChat ? 'Join the chat to end and close' : 'Apply topics before closing the conversation'
            }
            position="left"
          >
            <PopoverMenuItem className="chatCompositionForm-splitButton-menuItem" data-aid="endAndCloseChat" isDisabled>
              End & Close
            </PopoverMenuItem>
          </Tooltip>,
        ]}
        onActionClick={onApplyTopics}
      >
        Apply Topics
      </SplitButton>
    );
  }

  let tooltipMessage = '';
  if (!hasAgentJoinedChat) {
    tooltipMessage = 'Join the chat to end and close';
  } else if (!areTopicsRequired && !isEditorEmpty) {
    tooltipMessage = 'Delete or send your message to end the chat';
  }

  if (includesActivePhoneCall) {
    return (
      <Tooltip
        bounds={{ right: windowWidth - 8 }}
        className="chatComposition-splitButton-tooltip"
        message={!hasAgentJoinedChat ? 'Join the chat to end' : undefined}
        position="top"
      >
        <SplitButton
          className="chatCompositionForm-splitButton"
          disabled={!hasAgentJoinedChat || !isEditorEmpty}
          menuItems={[
            <Tooltip
              bounds={{ right: windowWidth - 8 }}
              delay={300}
              key="endAndClose"
              message={tooltipMessage || 'Conversation can be closed once the ongoing phone call is complete'}
              position="left"
            >
              <PopoverMenuItem
                className="chatCompositionForm-splitButton-menuItem"
                data-aid="endAndCloseChat"
                isDisabled
                onClick={_.noop}
              >
                End & Close
              </PopoverMenuItem>
            </Tooltip>,
          ]}
          onActionClick={onClickEnd}
        >
          End
        </SplitButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      bounds={{ right: windowWidth - 8 }}
      className="chatComposition-splitButton-tooltip"
      message={tooltipMessage}
      position="top"
    >
      <SplitButton
        className="chatCompositionForm-splitButton"
        disabled={!hasAgentJoinedChat || isMountDisabled || !isEditorEmpty}
        menuItems={items}
        onActionClick={onClickEndAndClose}
      >
        End & Close
      </SplitButton>
    </Tooltip>
  );
}

EndAndCloseBase.defaultProps = {
  areTopicsRequired: false,
  hasAgentJoinedChat: false,
  disableOnMount: false,
};

EndAndCloseBase.propTypes = {
  areTopicsRequired: PropTypes.bool,
  disableOnMount: PropTypes.bool,
  hasAgentJoinedChat: PropTypes.bool,
  includesActivePhoneCall: PropTypes.bool,
  isEditorEmpty: PropTypes.bool,
};

const EndAndClose = connect(mapStateToProps)(EndAndCloseBase);

function mapStateToProps({ getProvider }) {
  const conversationWorkflowConfig = getProvider('conversationWorkflowConfig').get();
  const activeConversation = getActiveConversationAttributes(getProvider('conversations'), [
    'id',
    'customAttributes',
    'topicIds',
  ]);
  const conversationHistory = getProvider('conversationHistory');

  let includesActivePhoneCall = false;
  let hasTopics = false;
  if (activeConversation) {
    hasTopics = !_.isEmpty(activeConversation.topicIds) || !_.isEmpty(activeConversation.customAttributes);
    includesActivePhoneCall = hasActivePhoneCall({ conversationHistory, conversationId: activeConversation.id });
  }
  const areTopicsRequired = conversationWorkflowConfig.requireTopics && activeConversation && !hasTopics;
  const currentAgentId = getProvider('currentAgent').get().id;

  const customerId = getProvider('currentLocation').get().customerId;
  let activeChatSession = getActiveSessionOfTypeByCustomerForAgent(
    getProvider('activeSessions'),
    customerId,
    currentAgentId,
    InteractionType.CHAT
  );

  return {
    areTopicsRequired,
    hasAgentJoinedChat: !!activeChatSession,
    includesActivePhoneCall,
  };
}

export default EndAndClose;
