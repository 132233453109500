import classnames from 'classnames';
import DebounceInput from 'react-debounce-input';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const DEBOUNCE_INPUT_MS = 200;

function AdminFilterBar({ className, onChangeTextFilter, textFilter }) {
  const onChange = useCallback(evt => onChangeTextFilter(evt.target.value), [onChangeTextFilter]);

  let inputProps = textFilter ? { value: textFilter } : {};
  return (
    <div className={classnames('admin-filterBar', className)}>
      <div className="admin-filterBar-textFilter">
        <DebounceInput
          className="admin-filterBar-textFilter-input"
          debounceTimeout={DEBOUNCE_INPUT_MS}
          onChange={onChange}
          placeholder="Filter results"
          {...inputProps}
        />
        <i className="admin-filterBar-textFilter-searchIcon icon-search ion-ios-search" />
      </div>
    </div>
  );
}

AdminFilterBar.propTypes = {
  className: PropTypes.string,
  onChangeTextFilter: PropTypes.func.isRequired,
  possibleTypes: PropTypes.arrayOf(PropTypes.string),
  textFilter: PropTypes.string,
};

export default AdminFilterBar;
