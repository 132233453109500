import _ from 'lodash';

import {
  DomainError,
  NetworkError,
  NotExistError,
  PermanentError,
  RequestTimeout,
  ServerError,
  TooManyRequestsError,
  Unauthorized,
  VersionOutdated,
} from 'scripts/application/lib/error';
import httpResponseChecker, { UnexpectedHttpResponse } from 'scripts/adapters/gateways/lib/http_response_checker';

export default class HttpResponseHandler {
  handleResponse(ctx, err, res) {
    let { requestName } = ctx;

    if (res && httpResponseChecker.isSuccessful(res)) {
      this.notifyObservers(_.camelCase(`handle-${requestName}-Success`), ctx, res.data);
      return;
    }

    const handleError = _.camelCase(`handle-${requestName}-Error`);

    if (err && !err.response) {
      this.notifyObservers(handleError, ctx, translateError(err));
      return;
    }

    this.notifyObservers(handleError, ctx, this.translateErrorResponse(res || err.response));
  }

  // overridden by subclasses
  translateErrorResponse(res, resource) {
    return translateErrorResponse(res, resource);
  }
}

export function translateErrorResponse(res, resource) {
  if (httpResponseChecker.isServerError(res)) {
    return new ServerError(res.data, resource);
  }

  if (httpResponseChecker.isUnauthorized(res)) {
    return new Unauthorized(res.data, resource);
  }

  if (httpResponseChecker.isNotExistError(res)) {
    return new NotExistError(res.data.errors, resource);
  }

  if (httpResponseChecker.isVersionOutdated(res)) {
    return new VersionOutdated(res.data.errors, resource);
  }

  if (httpResponseChecker.isDomainError(res)) {
    return new DomainError(res.data.errors, resource);
  }

  if (httpResponseChecker.isPermanentError(res)) {
    return new PermanentError(res.data.errors, resource);
  }

  if (httpResponseChecker.isTooManyRequestsError(res)) {
    return new TooManyRequestsError(resource);
  }

  return new UnexpectedHttpResponse(res.status, res.data, resource);
}

export function translateError(err) {
  if (isNetworkError(err)) {
    return new NetworkError(err);
  }

  if (isTimeoutError(err)) {
    return new RequestTimeout(err);
  }

  return err;
}

function isNetworkError(err) {
  return /network\s+error/i.test(err.message);
}

function isTimeoutError(err) {
  return /timeout/i.test(err.message);
}
