import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class SharedReportConfigsService {
  fetch(attrs, callback) {
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: [
        {
          metricSetName: '13', // looker unique id for report
          title: 'Some Custom Report',
          categoryId: 'Shared',
          urlSlug: '13', // gladly unique id for report
        },
        {
          metricSetName: '14',
          title: 'Monday Morning Exec Summary',
          categoryId: 'Shared',
          urlSlug: '14',
        },
      ],
    });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/reporting/v1/orgs/:orgId/shared-report-configs': {
          GET: this.fetch,
        },
      },
      this
    );
  }
}
