import { cloneDeep, get } from 'lodash';

import { addWidgetActivityItem } from 'actions/cobrowsing/helpers';
import CoBrowsingSessionData, { CoBrowsingSessionStatus } from 'models/cobrowsing_session_data';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { trackSessionCreated } from 'actions/cobrowsing/analytics';

/**
 * This action is expected to be invoked from a widget event handler, which runs in the context of the
 * conversation
 */
export default class HandleNewSession {
  constructor(context) {
    this.context = context;
  }

  run({ id, sessionData, sessionSourceName }) {
    const existingSession = this.context.stores.localCoBrowsingSessions.find(id);
    if (existingSession) return;

    const agentId = this.context.stores.currentAgent.get().id;
    const currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation && currentLocation.customerId;
    if (!customerId) return;

    const { conversations } = this.context.stores.customers.storesFor(customerId);
    const conversationId = getActiveConversationId(conversations);
    const sourceName = sessionSourceName || '';

    this.context.stores.localCoBrowsingSessions.add(
      CoBrowsingSessionData.create({
        id,
        agentId,
        customerId,
        conversationId,
        status: CoBrowsingSessionStatus.NEW,
        sessionMeta: cloneDeep(sessionData || {}),
      })
    );

    // Create a new conversation item for the new session
    addWidgetActivityItem(this.context, {
      sourceName,
      status: CoBrowsingSessionStatus.NEW,
      meta: {
        pin: `${get(sessionData, 'pin', '')}`.trim(),
        inviteURL: `${get(sessionData, 'inviteUrl', '')}`.trim(),
      },
    });

    // Send analytics event
    trackSessionCreated(this.context, { id, customerId, conversationId });
  }
}
