import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import TaskCompletionContent from 'models/task_completion_content';

export default function createTaskCompletionItem({ agentId, customerId, note, taskItemId }) {
  return ConversationItem.create({
    content: TaskCompletionContent.create({
      note,
      taskItemId,
    }),
    customerId,
    initiator: {
      id: agentId,
      type: Communicator.AGENT,
    },
  });
}
