import PropTypes from 'prop-types';
import React from 'react';

import ConversationItem from 'models/conversation_item';
import VoiceCampaignOutreach from 'models/voice_campaign_outreach';

import Metadata from './metadata';

function AuditText({ item, isAdmin }) {
  const outreachVerb = getOutreachVerb({ item });
  if (isAdmin) {
    return auditTextLink({ item, outreachVerb });
  }
  return `${outreachVerb} via outreach campaigns`;
}

AuditText.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function auditTextLink({ item, outreachVerb }) {
  return (
    <React.Fragment>
      {`${outreachVerb} via`}
      <a className="itemMetadata-subject" href={`/admin/proactive-voice/${item.content.campaignId}`}>
        {' '}
        outreach campaigns
      </a>
    </React.Fragment>
  );
}

auditTextLink.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  outreachVerb: PropTypes.string.isRequired,
};

export default function CampaignOutreachMetadata({ item, isAdmin }) {
  const iconClass = getIconClass(item);
  return (
    <Metadata
      iconClass={iconClass}
      isInbound={false}
      subject={`"${item.content.name}"`}
      text={AuditText({ item, isAdmin })}
      timestamp={item.timestamp}
    />
  );
}

CampaignOutreachMetadata.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function getOutreachVerb({ item }) {
  if (item.content instanceof VoiceCampaignOutreach) {
    return 'called';
  }

  throw new Error('invalid content type');
}

function getIconClass(item) {
  if (item.content instanceof VoiceCampaignOutreach) {
    return 'icon-phone-filled';
  }

  throw new Error('invalid content type');
}
