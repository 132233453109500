import { noop } from 'lodash';
import qconsole from 'scripts/lib/qconsole';

export default class RequestExternalData {
  constructor(context) {
    this.context = context;
  }

  /**
   * Attempts to load External Data for the given customer
   *
   * @param {object} params - parameters that form the external data query. Required
   * @param {object} [requestOptions] - optional backend request parameters (such as timeout etc.) Passed
   * on to the gateway verbatim.
   * @return {Promise<void>|Promise}
   */
  run(params, requestOptions) {
    const {
      customerId,
      namespace,
      parentEntityType,
      parentEntityId,
      requestorId,
      requestDataRefresh,
      extraParams,
    } = params;
    if (!customerId || !parentEntityType || !parentEntityId || !requestorId || !namespace) {
      throw new Error('Cannot request external data: Missing or invalid parameters');
    }

    // Is the customer loaded? If not, ignore the request
    if (!this.context.stores.customers.has({ id: customerId })) {
      qconsole.log(`RequestExternalData: received request for unloaded customer [${customerId}]. Ignoring.`);
      return Promise.resolve();
    }

    const customerStores = this.context.stores.customers.storesFor(customerId);
    const externalDataStore = customerStores.externalDataObjects;

    const requestQuery = {
      ...extraParams,
      context: customerId,
      gladlyEntityType: parentEntityType,
      gladlyEntityId: parentEntityId,
      namespace,
      requestor: requestorId,
    };
    if (requestDataRefresh) requestQuery.refresh = true;

    const request = this.context.gateways.customerExternalDataObjects.request(
      {
        gladlyEntityType: parentEntityType,
        gladlyEntityId: parentEntityId,
      },
      requestQuery,
      requestOptions
    );

    // Prevent unhandled promise rejection. Request errors are expected to be handled by the Observer
    externalDataStore.setLoading({ namespace, parentEntityId, parentEntityType, requestorId });

    return request && request.catch(noop);
  }
}
