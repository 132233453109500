import _ from 'lodash';

export default class AgentAssistanceConfigurationService {
  constructor(pubsub, getDatabase) {
    this.pubsub = pubsub;
    this.getDatabase = getDatabase;
  }

  get(orgId) {
    let agentAssistance = this.getDatabase(orgId).agentAssistanceConfig;
    return _.isPlainObject(agentAssistance)
      ? agentAssistance
      : { errors: [{ code: 'not_exist', detail: 'configuration value [agent-assistance] does not exist' }] };
  }
}
