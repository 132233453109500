import _ from 'lodash';
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import { ELEMENT_IMAGE, ELEMENT_LINK, ELEMENT_PARAGRAPH } from '@udecode/plate';
import escapeHtml from 'escape-html';
import { KEY_ALIGN } from '@udecode/plate-alignment';
import { Text } from 'slate';

import { hasPluginWithKey } from 'components/text_editor_new/lib/has_plugin_with_key';
import serializeHtmlText from 'components/text_editor_new/lib/serialize_html_text';

// Serializes editor content. If `serializeForExternalUse` is true, will remove
// nodes like placeholders and variables that should not be leaked outside (i.e.
// in customer-facing content, such as email)
export function serializeHtml(editor, serializeForExternalUse) {
  return serializeNode(editor, editor, !!serializeForExternalUse);
}

function serializeNode(editor, node, serializeForExternalUse) {
  if (Text.isText(node)) {
    return serializeHtmlText(node);
  }

  const children = node.children.map(n => serializeNode(editor, n, serializeForExternalUse)).join('');

  // Handle lists if the editor has the plugin - otherwise we'll fall through to treating
  // list items as paragraphs.
  if (hasPluginWithKey(editor, 'list')) {
    switch (node.type) {
      case ELEMENT_LI: {
        const nodeAlignment = children ? getNodeAlignment(node) : '';
        const styleString = nodeAlignment ? `style="text-align:${nodeAlignment}"` : '';
        if (styleString) {
          return `<li ${styleString}>${children}</li>`;
        }
        return `<li>${children}</li>`;
      }

      case ELEMENT_OL:
        return `<ol dir="auto">${children}</ol>`;

      case ELEMENT_UL:
        return `<ul dir="auto">${children}</ul>`;

      default:
        break;
    }
  }

  switch (node.type) {
    case ELEMENT_IMAGE: {
      const attributes = [];
      const src = _.trim(node.src);
      const attachmentId = _.trim(node.attachmentId);
      const fileName = _.trim(node.fileName);

      attributes.push(`src="${src ? encodeURI(src) : ''}"`);
      if (attachmentId) attributes.push(`data-attachment-id="${attachmentId}"`);
      if (fileName) attributes.push(`data-file="${fileName}"`);

      return `<img ${attributes.join(' ')} />`;
    }

    case ELEMENT_LINK: {
      const linkTarget = ['_blank', '_self'].includes(node.target || '') ? node.target : '_blank';
      return `<a href="${node.url}" target="${linkTarget}" rel="noopener noreferrer">${children}</a>`;
    }

    case 'placeholder':
      if (serializeForExternalUse) return children;
      return `<placeholder>${children}</placeholder>`;

    case ELEMENT_LI:
    case ELEMENT_PARAGRAPH: {
      let renderedChildren = '<br>';
      if (children) {
        renderedChildren = children;

        // When serializing for external use, a single trailing newline / <br> in a paragraph node
        // does not actually get rendered, so we need to double up the <br> at the end to make it
        // look like a single empty newline.
        if (serializeForExternalUse && /<br\s*\/?>$/.test(children) && children !== '<br>') {
          renderedChildren = `${renderedChildren}<br>`;
        }
      }
      const nodeAlignment = children ? getNodeAlignment(node) : '';
      return nodeAlignment
        ? `<div style="text-align:${nodeAlignment}">${renderedChildren}</div>`
        : `<div>${renderedChildren}</div>`;
    }

    case 'variable':
      if (serializeForExternalUse) return children;
      return `<variable ${createAttributesString(node.attributes)}>${children}</variable>`;

    default:
      return children;
  }
}

function createAttributesString(attrs) {
  const strings = [];
  _.forEach(attrs, (value, key) => {
    if (value != null) {
      strings.push(`${key}="${escapeHtml(value)}"`);
    }
  });
  return strings.join(' ');
}

// Returns node "alignment" or empty string if the alignment is not defined or is set to the default (left)
function getNodeAlignment(node) {
  const alignKey = _.get(node, KEY_ALIGN);
  const alignment = _.trim(alignKey).toLowerCase();

  return alignment && alignment !== 'left' && alignment !== 'start' ? alignment : '';
}
