import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import ConfirmCustomerModal from 'components/customer/conversation_history/conversation_items_v2/menu/pin_to_customer/confirm_customer_modal';
import connect from 'components/lib/connect';
import DeleteRelationship from 'actions/relationship/delete_relationship';
import LoadCustomer from 'actions/customer/load_customer';
import PopoverMenu, { PopoverMenuItem } from 'components/common/menu';
import NavigateToUrl from 'actions/current_location/navigate_to_url';
import TrackEvent from 'actions/analytics/track_event';

export class CustomerRelationship extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isCustomerModalVisible: false, isPopoverMenuOpen: false };
  }

  render() {
    const { relationshipLabel } = this.props;
    return (
      <Relationship>
        {this.renderCustomerName()}
        <RelationshipLabel title={relationshipLabel}>{relationshipLabel}</RelationshipLabel>
        {this.renderMenu()}
        {this.renderCustomerModal()}
      </Relationship>
    );
  }

  renderMenu() {
    return (
      <PopoverMenu
        boundByWindow
        isOpen={this.state.isPopoverMenuOpen}
        margin={5}
        onClose={() => this.closePopoverMenu()}
        position="top"
        targetPosition="start"
        targetRef={this.customerNameRef}
      >
        <PopoverMenuItem onClick={this.props.onProfileClick}>View Customer</PopoverMenuItem>
        <PopoverMenuItem onClick={() => this.openModal()}>Define Relationship</PopoverMenuItem>
        <PopoverMenuItem onClick={this.props.onRemoveClick}>Remove</PopoverMenuItem>
      </PopoverMenu>
    );
  }

  renderCustomerName() {
    const bestName =
      _.get(this.props, 'relationship.customerProfile.name') ||
      _.get(this.props, 'relationship.customerProfile.email') ||
      _.get(this.props, 'relationship.customerProfile.phone');
    return (
      <RelationshipCustomerName
        isEmpty={!bestName}
        onClick={() => this.openPopoverMenu()}
        ref={node => {
          this.customerNameRef = node;
        }}
        title={bestName}
      >
        {bestName || 'No name'}
      </RelationshipCustomerName>
    );
  }

  renderCustomerModal() {
    return this.state.isCustomerModalVisible ? (
      <ConfirmCustomerModal
        onCancel={() => this.closeModal()}
        onClose={() => this.closeModal()}
        originalCustomerId={_.get(this.props, 'currentCustomerId')}
        selectedCustomerId={_.get(this.props, 'relationship.customerId')}
        title={'How are these customers related?'}
      />
    ) : null;
  }

  closeModal() {
    this.setState({
      isCustomerModalVisible: false,
    });
  }

  closePopoverMenu() {
    this.setState({
      isPopoverMenuOpen: false,
    });
  }

  openModal() {
    this.props.loadCustomer();
    this.setState({
      isCustomerModalVisible: true,
    });
  }

  openPopoverMenu() {
    this.setState({
      isPopoverMenuOpen: true,
    });
  }
}

CustomerRelationship.propTypes = {
  currentCustomerId: PropTypes.string.isRequired,
  relationship: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    customerProfile: PropTypes.shape({
      name: PropTypes.string,
    }),
    labelId: PropTypes.string,
  }).isRequired,
  relationshipLabel: PropTypes.string,
  loadCustomer: PropTypes.func.isRequired,
  onProfileClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export const Relationship = styled.div`
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 0 -8px;
  padding: 4px 8px;

  &:hover {
    background-color: ${p => p.theme.colors.gray100};
  }
`;

export const RelationshipCustomerName = styled.div`
  color: ${p => p.theme.colors.green400};
  cursor: pointer;
  flex-basis: 55%;
  flex-grow: 1;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: ${p => p.theme.colors.green500};
  }

  ${p => (p.isEmpty ? emptyName : '')};
`;

const emptyName = css`
  font-style: italic;
`;

export const RelationshipLabel = styled.div`
  flex-basis: 45%;
  flex-grow: 1;
  margin-left: 12px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function mapStateToProps({ getProvider }, { relationship }) {
  const labelProvider = getProvider('relationshipLabels');
  let relationshipLabel = labelProvider.findBy({ id: relationship.labelId });
  return { relationshipLabel: _.get(relationshipLabel, 'displayName') };
}

function mapExecuteToProps(executeAction, { currentCustomerId, relationship }) {
  let { id, customerId } = relationship;
  return {
    loadCustomer: () => executeAction(LoadCustomer, customerId),
    onProfileClick: () => {
      executeAction(NavigateToUrl, `/customer/${customerId}`);
      executeAction(TrackEvent, {
        event: 'Relationships - Profile - Navigate',
        props: { id, linkedCustomerId: customerId, originalCustomerId: currentCustomerId },
      });
    },
    onRemoveClick: () => executeAction(DeleteRelationship, { id }),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(CustomerRelationship);
