import Boot from 'models/location/boot';
import createDocumentStoreClass from './lib/create_document_store_class';
import Immutable from 'immutable';
import locationFromJs from 'models/location/location_from_js';

let converter = {
  fromImmutable(immutable) {
    return locationFromJs(immutable.toJS());
  },

  toImmutable(entity) {
    return Immutable.fromJS(entity.toJs());
  },
};

export default createDocumentStoreClass({
  converter,
  defaultImmutable: converter.toImmutable(Boot.create()),
});
