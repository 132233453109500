import PropTypes from 'prop-types';
import React from 'react';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ItemIcon from './item_icon';

export function OutboundIcon({ avatar, itemType, itemSubType, name, showIcon }) {
  return (
    <ItemIcon
      avatar={avatar}
      className="conversationItem-outboundIcon"
      itemSubType={itemSubType}
      itemType={itemType}
      name={name}
      showIcon={showIcon}
    />
  );
}

function mapStateToProps({ getProvider }, { initiator, itemType, itemSubType, showIcon }) {
  const agentProvider = getProvider('agents');
  const agentId = initiator.type === Communicator.AGENT && initiator.id;
  const agent = showIcon ? agentProvider.findBy({ id: agentId }) : undefined;

  const avatar = agent && agent.avatarUrl;
  const name = agent && agent.name;

  return {
    avatar,
    itemType,
    itemSubType,
    name,
    showIcon,
  };
}

OutboundIcon.propTypes = {
  avatar: PropTypes.string,
  itemType: PropTypes.string.isRequired,
  itemSubType: PropTypes.string,
  name: PropTypes.string,
  showIcon: PropTypes.bool,
};

const OutboundIconContainer = connect(mapStateToProps)(OutboundIcon);

OutboundIconContainer.propTypes = {
  itemType: PropTypes.string.isRequired,
  initiator: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  showIcon: PropTypes.bool,
};

export default OutboundIconContainer;
