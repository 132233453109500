import _ from 'lodash';
import moment from 'moment';

import createBlankError from '../factories/error/create_blank_error';
import createModel, { prop } from './lib/create_model';
import createTooLongError from '../factories/error/create_too_long_error';
import Err from 'models/err';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const maxTokenAgeHours = 24;

const User = createModel({
  modelName: 'User',

  properties: {
    id: prop(String).isRequired,
    username: prop(String).isRequired,
    password: prop(String),
    roleIds: prop([String]).isRequired,
    activatedAt: prop(String),
    activationSentAt: prop(String),
    passwordResetSentAt: prop(String),
    // A disabled user cannot log in
    disabledAt: prop(String),
  },

  setUsername(username) {
    this.username = username;
  },

  setRoleIds(roleIds) {
    this.roleIds = roleIds;
  },

  disable() {
    this.disabledAt = moment().toISOString();
  },

  enable() {
    this.disabledAt = null;
  },

  isDisabled() {
    return !!this.disabledAt;
  },

  hasInviteExpired() {
    let currentTime = moment();
    return currentTime.diff(moment(this.activationSentAt), 'hours') > User.getMaxTokenAgeHours();
  },

  isActivated() {
    return this.activatedAt != null;
  },

  resetActivation() {
    this.activationSentAt = moment()
      .add(User.getMaxTokenAgeHours(), 'hours')
      .toISOString();
  },

  update(attrs) {
    let validationErrors = getValidationErrors(
      _.mergeWith({}, this, attrs, (lhs, rhs) => (_.isArray(lhs) ? rhs : undefined))
    );
    if (validationErrors.length) {
      throw new Error(_.map(validationErrors, 'detail').join('; '));
    }

    _.mergeWith(this, _.pick(attrs, 'username', 'roleIds'), (lhs, rhs) => (_.isArray(lhs) ? rhs : undefined));
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId(), roleIds: [] }, attrs));
    },

    MAX_LENGTH: {
      username: 254,
      password: 50,
    },

    getMaxTokenAgeHours() {
      return maxTokenAgeHours;
    },

    getValidationErrors,
  },
});

function getValidationErrors({ username, roleIds }) {
  let errors = [];

  if (!username || _.isEmpty(username.trim())) {
    errors.push(createBlankError('username', 'email'));
  }

  if (username && username.length > User.MAX_LENGTH.username) {
    errors.push(createTooLongError('username', `${User.MAX_LENGTH.username} characters`, 'email'));
  }

  if (_.isEmpty(roleIds)) {
    errors.push(new Err({ code: Err.Code.BLANK, attr: 'roleIds', detail: 'at least 1 role must be selected' }));
  }

  if (_.isEqual(roleIds, ['api-user'])) {
    errors.push(new Err({ code: Err.Code.INVALID, attr: 'roleIds', detail: 'at least 1 more role must be selected' }));
  }

  return errors;
}

export default User;
