import { CoBrowsingSessionStatus } from 'models/cobrowsing_session_data';
import { trackSessionEnded } from 'actions/cobrowsing/analytics';

export default class HandleEndedSession {
  constructor(context) {
    this.context = context;
  }

  run({ id }) {
    const existingSession = this.context.stores.localCoBrowsingSessions.find(id);
    if (existingSession && existingSession.status === CoBrowsingSessionStatus.ACTIVE) {
      trackSessionEnded(this.context, existingSession);
    }

    this.context.stores.localCoBrowsingSessions.remove(id);
  }
}
