import styled from 'styled-components';
import Button, { ButtonTypes } from 'components/common/button';

const UserButton = styled(Button)`
  border: none;
  border-radius: 2px;
  font-size: ${p => p.theme.fontSize.medium};
  font-weight: bold;
  outline: none;
  height: 62px;
  min-width: 425px;
  width: 100%;
`;

export const UserActionButton = styled(UserButton).attrs({ buttonType: ButtonTypes.PRIMARY })`
  min-width: 350px;
  width: 350px;
`;

export default UserButton;
