import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Generic Channel Fill
 */

export default function GenericChannelIconFillIcon(props) {
  const letter = [...(props.letter || ' ')][0];
  return (
    <Icon viewBox="0 0 15 15" {...props}>
      <path
        clipRule="evenodd"
        d="M0 4C0 1.79086 1.79086 0 4 0H11C13.2091 0 15 1.79086 15 4V11C15 13.2091 13.2091 15 11 15H0V4Z"
        fillRule="evenodd"
      />

      <text
        dominantBaseline="middle"
        fill={props.color ? props.color : 'white'}
        fontSize="10px"
        fontWeight="400"
        textAnchor="middle"
        x="50%"
        y="57%"
      >
        {letter.toUpperCase()}
      </text>
    </Icon>
  );
}
