import _ from 'lodash';

import PhoneCall from 'models/phone_call';

export default class CompleteWarmTransfer {
  constructor(context) {
    this.context = context;
  }

  run() {
    const activeCall = this.context.stores.activeCall.get();
    if (!activeCall) {
      return;
    }

    const agentParticipantIds = activeCall.conversationItem.content.getAgentParticipantIdsOnCall();
    const transferringAgentId =
      activeCall.transferee.agentId || _.find(agentParticipantIds, id => id !== this.currentAgentId);

    if (!transferringAgentId) {
      throw new Error('no transferring agent ID specified when trying to complete warm transfer');
    }

    activeCall.conversationItem.content.setParticipantStatus(
      this.currentAgentId,
      PhoneCall.ParticipantStatus.TRANSFER_REQUESTED
    );
    this.context.stores.activeCall.setPending(activeCall);

    this.context.gateways.phoneControlsHttp.completeWarmTransfer(
      activeCall.customer.id,
      activeCall.conversationItem.id,
      {
        fromAgentId: this.currentAgentId,
        agentId: transferringAgentId,
        routingGroupId: activeCall.transferee.routingGroupId,
      }
    );
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }
}
