import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('VoiceCampaignOutreachResult').attr('type', 'VOICE_CAMPAIGN_OUTREACH_RESULT');

Factory.define('VoiceCampaignOutreachResultWithDefaults')
  .extend('VoiceCampaignOutreachResult')
  .attr('campaignId', 'campaign-id')
  .attr('recipientCollectionId', 'recipient-collection-id')
  .attr('recipientId', 'recipient-id')
  .attr('result', 'ANSWERED');

Factory.define('VoiceCampaignOutreachResultItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('VoiceCampaignOutreachResult', content);
  });

Factory.define('VoiceCampaignOutreachResultItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('VoiceCampaignOutreachResultWithDefaults', content);
  });
