import DtoConverter from 'scripts/application/dto_converters/search/search_result_converter';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import SearchQueryConverter from 'scripts/application/dto_converters/search/search_query_converter';
import SearchResult from 'models/search/search_result';
import ServerClock from 'scripts/application/lib/server_clock';

export const SEARCH_RESULT_INTERVAL_MS = 15000;

export default class Search {
  constructor(context) {
    this.context = context;
  }

  run({ id, query, requestedAt }) {
    const queryDto = SearchQueryConverter.toDto(query);
    this.context.stores.universalSearchResults.add(new SearchResult({ id, query, requestedAt, isLoading: true }));

    this.context.gateways.search
      .fetch(id, { query: queryDto, requestedAt })
      .then(dto => {
        this.clearExpiredResults();
        this.context.stores.universalSearchResults.addOrReplace(DtoConverter.fromDto({ id, ...dto }));
      })
      .catch(errorDto => {
        handleCommonErrors(this.context, errorDto);
      });
  }

  clearExpiredResults() {
    const now = ServerClock.now();
    const results = this.context.stores.universalSearchResults.findAll();
    results.forEach(result => {
      const createdAt = new Date(result.requestedAt);
      if (now - createdAt > SEARCH_RESULT_INTERVAL_MS) {
        this.context.stores.universalSearchResults.remove(result.id);
      }
    });
  }
}
