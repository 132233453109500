import _ from 'lodash';

import MqttGateway from './lib/mqtt_gateway';

export default class AgentUserGateway extends MqttGateway {
  constructor(backend, requestorId) {
    super(backend, requestorId);
  }

  get version() {
    return '3';
  }

  get topicPattern() {
    return 'agent-users/+';
  }

  add(agentUserDto) {
    return this._add([], agentUserDto);
  }

  update(userId, agentUserAttrs) {
    return this._update([userId], agentUserAttrs);
  }

  onAddSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleAddSuccess', payload);
  }

  onAddErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleAddErrors', errorDtos);
  }

  onUpdateSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateSuccess', _.last(parsedTopicParams), payload);
  }

  onUpdateErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateErrors', _.last(parsedTopicParams), errorDtos);
  }
}
