import createModel, { prop } from './lib/create_model';
const ReportBuilderConfig = createModel({
  modelName: 'ReportBuilderConfig',
  properties: {
    enabled: prop(Boolean),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ReportBuilderConfig;
