import copyTextToClipboard from 'components/lib/copy_text_to_clipboard';

export default class CopyItemLink {
  constructor(context) {
    this.context = context;
  }

  run({ item, onCopy = copyTextToClipboard }) {
    let { customerId, conversationId, id } = item;
    let link = `${window.location.origin}/customer/${customerId}/item/${id}`;
    onCopy(link);

    this.context.analytics.track('Conversation Item Link Copied', {
      customerId,
      conversationItemId: id,
      conversationId,
    });
  }
}
