export default function Submit(onSubmit) {
  return {
    onKeyDown(evt, editor, next) {
      if (!onSubmit) {
        return next();
      }

      const ctrlHeld = evt.metaKey || evt.ctrlKey;
      if (evt.key === 'Enter' && ctrlHeld) {
        onSubmit(evt);
        return true;
      }

      return next();
    },
  };
}
