import _ from 'lodash';
import { isListNode } from './utils';
import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';

const LIST_NODE_TYPES = [LIST_ITEM, ORDERED_LIST, UNORDERED_LIST];

export function normalizeNode(node, editor, next) {
  if (node.object !== 'block' || !_.includes(LIST_NODE_TYPES, node.type)) {
    return next();
  }

  const invalids = node.nodes
    .map((child, i) => {
      if (!isListNode(child)) {
        return null;
      }
      const nextNode = node.nodes.get(i + 1);

      if (!nextNode || !isListNode(nextNode)) {
        return null;
      }

      return [child, nextNode];
    })
    .filter(Boolean);

  if (invalids.isEmpty()) {
    return next();
  }

  /*
   * Join the list pairs
   */

  // We join in reverse order, so that multiple lists folds onto the first one
  return () => {
    invalids.reverse().forEach(pair => {
      const [first, second] = pair;
      const updatedSecond = editor.value.document.getDescendant(second.key);

      updatedSecond.nodes.forEach((secondNode, index) => {
        editor.moveNodeByKey(secondNode.key, first.key, first.nodes.size + index, { normalize: false });
      });

      editor.removeNodeByKey(second.key, { normalize: false });
    });
    return editor;
  };
}
