import PropTypes from 'prop-types';
import React from 'react';

import AgentMetadata from './metadata/agent_metadata';
import ApiMetadata from './metadata/api_metadata';
import AutomatedMetadata from './metadata/automated_metadata';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import CustomerMetadata from './metadata/customer_metadata';
import SystemMetadata from './metadata/system_metadata';
import ThirdPartyMetadata from './metadata/third_party_metadata';

export default function ItemMetadata(props) {
  if (!props.showMetadata) {
    return null;
  }

  switch (props.item.initiator.type) {
    case Communicator.AGENT:
      return <AgentMetadata {...props} />;
    case Communicator.API:
      return <ApiMetadata {...props} />;
    case Communicator.AUTOMATED:
      return <AutomatedMetadata {...props} />;
    case Communicator.CUSTOMER:
      return <CustomerMetadata {...props} />;
    case Communicator.EXTERNAL:
    case Communicator.INTERNAL:
      return <ThirdPartyMetadata {...props} />;
    case Communicator.SYSTEM:
      return <SystemMetadata {...props} />;
    default:
      return null;
  }
}

ItemMetadata.defaultProps = {
  showMetadata: true,
};

ItemMetadata.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  showMetadata: PropTypes.bool,
};
