import createReactClass from 'create-react-class';
import React from 'react';

import CustomerView from 'models/location/customer_view';

import T from 'prop-types';

const ActiveCallPhoneNumber = createReactClass({
  propTypes: {
    conversationId: T.string.isRequired,
    currentLocation: T.object,
    customerId: T.string.isRequired,
    isCustomerUnverified: T.bool.isRequired,
    onBackToConversationClick: T.func.isRequired,
    phoneNumber: T.string.isRequired,
  },

  getInitialState() {
    return { hover: false };
  },

  render() {
    return (
      <span
        className="phoneControls-numberCalled fs-mask"
        onMouseEnter={this.setHoverState.bind(null, true)}
        onMouseLeave={this.setHoverState.bind(null, false)}
      >
        <i className="fa fa-phone fa-phone-spacing" />
        {this.renderPhoneNumberOrText()}
      </span>
    );
  },

  setHoverState(state) {
    this.setState({ hover: state });
  },

  renderPhoneNumberOrText() {
    if (!this.state.hover || (this.isSameConversation() && this.state.hover)) {
      return <span className="phoneNumberCalled-displayNumber">{this.props.phoneNumber}</span>;
    }
    return (
      <span className="phoneNumberCalled-backToCustomer" onClick={this.onBackToConversationClick}>
        Back to customer
      </span>
    );
  },

  isSameConversation() {
    return (
      this.props.currentLocation instanceof CustomerView &&
      this.props.currentLocation.customerId === this.props.customerId &&
      this.props.currentLocation.currentConversationId === this.props.conversationId
    );
  },

  onBackToConversationClick() {
    this.props.onBackToConversationClick({
      customerId: this.props.customerId,
      conversationId: this.props.conversationId,
    });
  },
});

export default ActiveCallPhoneNumber;
