import _ from 'lodash';
import DOMPurify from 'dompurify';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import Attachment from 'models/attachment';
import connect from 'components/lib/connect';
import EmailItemContext from 'components/customer/conversation_history/conversation_items_v2/email/email_item_context';
import { getAttachmentUrl } from 'scripts/domain/services/attachments';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function ViewOriginalHtmlModal({ email }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const { showHtmlModal, setShowHtmlModal } = useContext(EmailItemContext);
  const { attachments, bodyHtml } = email;

  if (!isFeatureEnabled('viewOriginalEmailHtml')) {
    return null;
  }

  return (
    <>
      {showHtmlModal ? (
        <HtmlContentModalContainer attachments={attachments} html={bodyHtml} onClose={() => setShowHtmlModal(false)} />
      ) : null}
    </>
  );
}

ViewOriginalHtmlModal.propTypes = {
  email: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.instanceOf(Attachment)),
    bodyHtml: PropTypes.string,
  }),
};

const HtmlContentModalContainer = connect(mapStateToProps)(HtmlContentModal);

function mapStateToProps({ getProvider }) {
  return {
    orgId: getProvider('auth').get()?.claims?.orgId,
  };
}

function HtmlContentModal({ attachments, html, onClose, orgId }) {
  const convertedHtml = useMemo(() => replaceImageSources(orgId, html, attachments), [attachments, html, orgId]);

  return (
    <PortalModalWrapper>
      <Modal>
        <StyledModalCard data-aid="viewOriginalHtml-modal" onClose={onClose}>
          <Close onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
          <iframe
            csp="script-src 'none'"
            height="100%"
            id="view-original-html"
            sandbox="true"
            srcDoc={convertedHtml}
            style={{ border: 'none' }}
            width="100%"
          />
        </StyledModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

const StyledModalCard = styled(ModalCard)`
  height: 80%;
  width: 80%;
`;

function replaceImageSources(orgId, html, attachments) {
  let replaceImgSrcHtml = html;
  // Replace all the inline images via their contentId with the actual Gladly attachment URL
  _.forEach(attachments, attachment => {
    const srcUrl = getAttachmentUrl('email', orgId, attachment);
    const regexp = new RegExp(`(src=")(cid:${attachment.contentId}?)(")`);
    replaceImgSrcHtml = replaceImgSrcHtml.replace(regexp, `$1${srcUrl}$3`);
  });
  const purified = DOMPurify.sanitize(replaceImgSrcHtml);
  return purified;
}

const Close = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  position: absolute;
  right: 0px;
  top: 0px;

  &:hover {
    color: ${p => p.theme.colors.gray600};
  }
`;

const Modal = styled.div`
  text-align: center;
  .modalCard-content {
    padding: ${p => p.theme.spacing.large};
    position: relative;
  }
`;
