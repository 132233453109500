import React from 'react';
import PropTypes from 'prop-types';

import FlexibleProfileCardBase from 'components/customer/flexible_profile_card_v2/components/flexible_card_base';
import { getFlexibleCardConfiguration } from 'components/customer/flexible_profile_card_v2/configurations';

export default function FlexibleProfileCard({ configurationKey }) {
  const configuration = getFlexibleCardConfiguration(configurationKey);

  return <FlexibleProfileCardBase cardConfiguration={configuration} />;
}

FlexibleProfileCard.propTypes = {
  configurationKey: PropTypes.string.isRequired,
};
