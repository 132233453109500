import moment from 'moment-timezone';

import qconsole from 'scripts/lib/qconsole';
import ReactivateUpgradeNotification from 'actions/version/reactivate_upgrade_notification';

export default class PostponeOptionalUpgrade {
  constructor(context) {
    this.context = context;
  }

  run(notificationId) {
    let notification = this.context.stores.notifications.findBy({ id: notificationId });
    if (!notification) {
      qconsole.log(`Could not find and postpone optional upgrade notification [${notificationId}]`);
      return;
    }

    notification.postpone();
    this.context.stores.notifications.replace(notification);

    this.context.scheduler.executeIn(
      moment.duration(POSTPONE_MIN, 'minutes').as('milliseconds'),
      notificationId,
      ReactivateUpgradeNotification,
      notificationId
    );
  }
}

const POSTPONE_MIN = 30;
