import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';

export const BasicType = createEnum('ERROR', 'INFO', 'SUCCESS', 'WARNING');

const Basic = createModel({
  modelName: 'Basic',

  properties: {
    basicType: prop().oneOf(..._.values(BasicType)),
    message: prop(String).isRequired,
  },

  statics: {
    Type: BasicType,
  },
});

export default Basic;
