import _ from 'lodash';
import classnames from 'classnames';
import HtmlToText from 'html-to-text';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  StyledActivityBody,
  StyledActivityBubbleContent,
  StyledChevron,
  StyledActivityContent,
  StyledActivityExpander,
  StyledActivityTitle,
} from './customer_activity_content_styles';
import ConversationItem from 'models/conversation_item';
import ExternalLink from 'components/lib/external_link';
import Linkifier from 'components/lib/linkifier';
import SafeHtml from 'components/common/safe_html';

export default function CustomerActivityContent({ className, item, onClick, onHeightChange, shouldStartExpanded }) {
  const { activityType, expandByDefault, title, body, link } = item.content;

  const [isExpanded, setIsExpanded] = useState(expandByDefault || shouldStartExpanded);
  const [isExpandable, setIsExpandable] = useState(false);
  const bodyRef = useRef(null);
  const isBodyPlaintext = useMemo(() => {
    return _.trim(HtmlToText.fromString(body)).length === _.trim(body).length;
  }, [body]);

  useEffect(() => {
    if (!isExpanded && bodyRef.current) {
      let nextIsExpandable = bodyRef.current.offsetWidth < bodyRef.current.scrollWidth;
      if (isExpandable !== nextIsExpandable) {
        setIsExpandable(nextIsExpandable);
      }
    }
  }, [isExpanded, isExpandable]);

  useEffect(() => {
    onHeightChange && onHeightChange();
  }, [isExpanded, isExpandable, onHeightChange]);

  return (
    <StyledActivityBubbleContent
      className={classnames('CustomerActivityContent', className)}
      data-aid={`customerActivityContent-${item?.id || ''}`}
      item={item}
    >
      <StyledActivityContent>
        <bdi>
          <StyledActivityTitle className="CustomerActivityContent-title">{title}</StyledActivityTitle>
        </bdi>
        <bdi>
          <StyledActivityBody $isExpanded={isExpanded} data-aid="customerActivity-body" ref={bodyRef}>
            {isBodyPlaintext ? <Linkifier>{body}</Linkifier> : <SafeHtml html={body} setDirection />}
          </StyledActivityBody>
        </bdi>
        <bdi>
          {_.get(link, 'url') && (
            <ExternalLink subType={activityType} text={_.get(link, 'text')} type="ACTIVITY" url={_.get(link, 'url')} />
          )}
        </bdi>
      </StyledActivityContent>
      {renderExpander()}
    </StyledActivityBubbleContent>
  );

  function renderExpander() {
    if (!isExpandable) {
      return null;
    }

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
      onClick({ isExpanded });
    };

    return (
      <StyledActivityExpander data-aid="expander-btn" onClick={toggleExpand}>
        <StyledChevron $isDownwards={!isExpanded} className="ion-chevron-up" />
      </StyledActivityExpander>
    );
  }
}

CustomerActivityContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onClick: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  shouldStartExpanded: PropTypes.bool,
};
