import PropTypes from 'prop-types';

import { getDatesInRange } from 'models/location/reports';
import IvrSummaryReport from './ivr_summary_report';

import connect from 'components/lib/connect';

let mapStateToProps = function({ getProvider }, { windowWidth }) {
  const report = getProvider('report').get();

  let currentLocation = getProvider('currentLocation').get();
  let startDate = currentLocation.getStartAt();
  let endDate = currentLocation.getEndAt();
  let endpointNumber = currentLocation.getEndpointNumber();

  return {
    startDate,
    endDate,
    days: getDatesInRange(startDate, endDate),
    endpointNumber,
    report,
    windowWidth,
  };
};

const IvrSummaryReportContainer = connect(mapStateToProps)(IvrSummaryReport);

IvrSummaryReportContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

export default IvrSummaryReportContainer;
