import React, { useCallback, useContext } from 'react';
import get from 'lodash/get';

import ComposerContext from 'components/composer/contexts/composer_context';
import InternalEmailModalContainer from './internal_email_modal_container';
import RemoveThread from 'actions/composer/email/remove_thread';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function InternalEmailModal({ onKeepHistory }) {
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();
  const onRemoveHistory = useCallback(() => executeAction(RemoveThread, composition.id), [
    composition.id,
    executeAction,
  ]);

  const to = get(composition, 'content.to');
  const cc = get(composition, 'content.cc');
  const bcc = get(composition, 'content.bcc');

  return (
    <InternalEmailModalContainer
      bcc={bcc}
      cc={cc}
      onKeepHistory={onKeepHistory}
      onRemoveHistory={onRemoveHistory}
      to={to}
    />
  );
}
