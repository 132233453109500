import { InboxConversationStatus, InboxSortOrder } from 'models/location/inbox';

export default {
  fromUrlParameters({ sort, status, topic }) {
    return {
      conversationStatus: status && InboxConversationStatus[status.toUpperCase()],
      sortOrder: sort,
      topicId: topic,
    };
  },

  getPath(inbox) {
    return inbox.isTeam ? `/home/team/${inbox.routingGroupId}` : '/home';
  },

  getQueryParams(inbox) {
    let queryParams = {};
    let status = inbox.conversationStatus;
    if (status && status !== (inbox.isTeam ? InboxConversationStatus.NEW : InboxConversationStatus.OPEN)) {
      queryParams.status = status;
    }
    let sort = inbox.sortOrder;
    if (sort && sort !== InboxSortOrder.LATEST) {
      queryParams.sort = sort;
    }

    return queryParams;
  },
};
