import _ from 'lodash';
import map from 'lodash/map';
import moment from 'moment';

import AgentNotification from 'models/agent_notification';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';

export default class RerequestAgentNotifications {
  constructor(context) {
    this.context = context;
  }

  run() {
    // No notifications? Refetch the first page just in case new notifications have come in since disconnected.
    const allNotifications = this.context.stores.agentNotifications.findAll();
    if (!allNotifications.length) {
      rerequestFirstPage(this.context);
      return;
    }

    const earliestNotification = _.last(_.orderBy(allNotifications, ['createdAt'], ['desc']));
    const afterTimestamp = moment(earliestNotification.createdAt)
      .subtract(1, 'millisecond')
      .toISOString();

    this.context.gateways.agentNotification
      .requestAllAfter(afterTimestamp)
      .then(notificationsDto => {
        const agentNotifications = map(notificationsDto, notification => AgentNotification.fromJs(notification));
        this.context.stores.agentNotifications.set(agentNotifications);
      })
      .catch(errDto => {
        handleCommonErrors(this.context, errDto);
      });
  }
}

function rerequestFirstPage(context) {
  context.gateways.agentNotification
    .requestFirstPage()
    .then(notificationsDto => {
      const agentNotifications = map(notificationsDto, notification => AgentNotification.fromJs(notification));
      context.stores.agentNotifications.set(agentNotifications);
    })
    .catch(errDto => {
      handleCommonErrors(context, errDto);
    });
}
