import Layout from '../virtualized/layout';
import { LAST_CONVERSATION_SPACER_ID } from './feed_item_types';

const BOTTOM_PADDING = 16;

/**
 * When we get a new id or the height of an id changes
 *   we need to check to see if it comes after the last sticky id.
 *   If it does, we need to get the top of the sticky id, top of the s
 *   spacer, and see if we yet exceed the height of the container.
 *
 *   If we don't set the height of the spacer to the difference.
 */

export default class FeedLayout extends Layout {
  constructor({ lastStickyId }) {
    super();
    this.lastStickyId = lastStickyId;
  }

  getLastStickyId() {
    return this.lastStickyId;
  }

  setContainerHeight(height, scrollTop) {
    scrollTop = super.setContainerHeight(height, scrollTop);
    return this.adjustSpacer(scrollTop);
  }

  setHeight(id, height, scrollTop, isManual) {
    scrollTop = super.setHeight(id, height, scrollTop, isManual);
    return this.adjustSpacer(scrollTop);
  }

  setIds(ids, scrollTop) {
    scrollTop = super.setIds(ids, scrollTop);
    return this.adjustSpacer(scrollTop);
  }

  setLastStickyId(stickyId, scrollTop) {
    if (stickyId !== this.lastStickyId) {
      this.lastStickyId = stickyId;
      return this.adjustSpacer(scrollTop);
    }
    return scrollTop;
  }

  adjustSpacer(scrollTop) {
    const lastStickyTop = super.getTop(this.lastStickyId) || 0;
    const spacerTop = super.getTop(LAST_CONVERSATION_SPACER_ID);
    const spacerHeight = super.getHeight(LAST_CONVERSATION_SPACER_ID);
    const containerHeight = super.getContainerHeight();

    const stickyHeight = spacerTop - lastStickyTop;
    const heightDiff = containerHeight - stickyHeight;
    const newSpacerHeight = heightDiff > BOTTOM_PADDING ? heightDiff : BOTTOM_PADDING;
    if (newSpacerHeight !== spacerHeight) {
      return super.setHeight(LAST_CONVERSATION_SPACER_ID, newSpacerHeight, scrollTop, false);
    }
    return scrollTop;
  }
}
