import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import getHighlightedText from './lib/highlight_text';

export default function CustomAttribute({ customAttributeDef, highlightClassName, value }) {
  if (customAttributeDef.type === AttributeType.DATE) {
    value = moment(value).format('MMM D, YYYY');
  }

  return (
    <dd className={`customerConversationCard-customerInfo-${_.camelCase(customAttributeDef.type)}`}>
      {getHighlightedText(value, highlightClassName)}
    </dd>
  );
}

CustomAttribute.propTypes = {
  customAttributeDef: PropTypes.instanceOf(AttributeDef).isRequired,
  highlightClassName: PropTypes.string,
  value: PropTypes.any,
};
