import _ from 'lodash';

import ChatMessage from 'models/chat_message';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import ServerClock from 'scripts/application/lib/server_clock';

export default function createAgentChatMessage({
  agentProfile,
  content,
  conversationId,
  customerId,
  snippetIds = [],
  relatedSnippetIds = [],
}) {
  return ConversationItem.create({
    conversationId,
    content: new ChatMessage(_.merge({ sentAt: ServerClock.toISOString(), participantId: agentProfile.id }, content)),
    customerId,
    initiator: { type: Communicator.AGENT, id: agentProfile.id },
    snippetIds,
    relatedSnippetIds,
  });
}
