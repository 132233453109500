import { createContentModel } from './composition_content_shared';

const PhoneCallbackCompositionContent = createContentModel({
  modelName: 'PhoneCallbackCompositionContent',

  properties: {
    phoneNumber: String,
    notes: String,
    requestedTime: String,
  },

  isEmpty() {
    return false;
  },
});

export default PhoneCallbackCompositionContent;
