import _ from 'lodash';
import AgentPhonePreferences from 'models/agent_phone_preferences';

export default function canHandlePhoneCalls(currentAgent, connectionState, isFeatureEnabled) {
  const agentPhonePreference = _.get(currentAgent, 'voiceConfiguration.preference');
  const isBrowserPhoneAvailable = connectionState.isBrowserPhoneAvailable();

  switch (agentPhonePreference) {
    case AgentPhonePreferences.BROWSER:
    case AgentPhonePreferences.NONE: {
      return isFeatureEnabled('webRTC') && isFeatureEnabled('microphone') && isBrowserPhoneAvailable;
    }
    case AgentPhonePreferences.DIRECT_DIAL:
      return isFeatureEnabled('directDial');
    case AgentPhonePreferences.STATION:
      return isFeatureEnabled('telephonyApi') || isFeatureEnabled('sip');
    default:
      return false;
  }
}
