import _ from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import DropdownMenu from 'components/common/dropdown_menu';
import UpdateComposition from 'actions/composer/update_composition';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function FromField({ allFromAddresses, disabled }) {
  const { composition } = useContext(ComposerContext);

  const currentRecipient = composition.content.companyAddress;
  const executeAction = useExecuteAction();
  const onChange = companyAddress =>
    executeAction(UpdateComposition, { compositionId: composition.id, attrs: { companyAddress } });

  const fromOptions = _.map(allFromAddresses, ({ name, configuration: { recipientAddress } }) => ({
    label: name,
    value: recipientAddress,
    text: recipientAddress,
  }));

  const text = _.find(fromOptions, option => option.value === currentRecipient)?.label;

  return (
    <From>
      <Label>From</Label>
      <DropdownMenu
        data-aid="customChannel-fromField"
        disabled={disabled}
        onSelect={onChange}
        options={fromOptions}
        text={text}
        value={currentRecipient}
      />
    </From>
  );
}

const From = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  color: ${p => p.theme.colors.gray500};
  margin-right: 8px;
`;

export default FromField;
