import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import BubbleContent, { BubbleHeader } from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';

export default function TweetItem({ className, item }) {
  return (
    <ItemContent item={item}>
      <ItemMetadata item={item} />
      <BubbleContent className={className} item={item}>
        <BubbleHeader className="tweetItem-header">@{item.content.user.screenName}</BubbleHeader>
        {renderTweet()}
      </BubbleContent>
    </ItemContent>
  );

  function renderTweet() {
    const elements = interpolateTweet(item.content);
    return React.createElement('div', { className: 'tweetItem' }, ...elements);
  }
}

TweetItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem),
};

function interpolateTweet(tweet) {
  const interpolators = buildInterpolators(tweet.entities);
  return applyInterpolators(interpolators, tweet.text);
}

function buildInterpolators(entities) {
  return _.compact(
    _.flatten([
      (entities.userMentions || []).map(mention => buildInterpolator(mention, renderUserMention)),
      (entities.media || []).map(media => buildInterpolator(media, renderMedia)),
    ])
  );
}

function renderUserMention(mention, text) {
  return <a href={`https://twitter.com/${mention.screenName}`}>{text}</a>;
}

function renderMedia(media, text) {
  if (media.type === 'photo') {
    return (
      <div className="full-bleed">
        <img src={media.mediaUrlHttps} />
      </div>
    );
  }

  return <a href={text}>{text}</a>;
}

function buildInterpolator(entity, renderFn) {
  return {
    start: entity.indices[0],
    end: entity.indices[1],
    render(text) {
      return renderFn(entity, text);
    },
  };
}

function applyInterpolators(interpolators, text) {
  let curr = 0;
  const elements = [];

  _.sortBy(interpolators, 'start').forEach(interpolator => {
    const start = interpolator.start;
    const end = interpolator.end + 1;

    // add any leading text
    if (curr < start) {
      elements.push(text.slice(curr, start));
    }

    // interpolate within the range
    elements.push(interpolator.render(text.slice(start, end)));

    // advance the cursor
    curr = end;
  });

  if (curr < text.length) {
    // add any trailing text
    elements.push(text.slice(curr));
  }

  return elements;
}
