import changeCurrentLocation from 'actions/lib/change_current_location';
import CustomReporting from 'models/location/custom_reporting';

export default class LoadCustomReports {
  constructor(context) {
    this.context = context;
  }

  run({ path }) {
    const location = CustomReporting.create({ path });
    location.startLoading();
    changeCurrentLocation(this.context, location);
    this.context.gateways.customReporting.load(path);
  }
}
