import React from 'react';
import styled from 'styled-components';

import { EmptyState } from 'components/customer/answer_panel/lib/empty_state';
import Spinner from 'components/common/spinner';

export default function LoadingSuggestedReply() {
  return (
    <LoadingSuggestedReplyContainer>
      <Spinner size={68} />
    </LoadingSuggestedReplyContainer>
  );
}

const LoadingSuggestedReplyContainer = styled(EmptyState)`
  padding: 0px 20px;
`;
