import Immutable from 'immutable';
import ErrImmutableConverter from '../../immutable_converters/err_converter';

// factory for creating document (e.g. single object) store classes
//
// ERRORS API:
//   {set,clear}Errors
//   {set,is,commit,reset}Pending
//
// EXAMPLE EDITOR FLOW:
//   1. view layer calls action with attrs
//   2. action runs local validation and calls setErrors if necessary (short circuiting the action)
//   3. action calls setPending
//   4. action calls gateway
//   5. gateway notifies (gateway observer with gateway_observers/lib/save_result_handler mixin) to handleAddErrors or handleAddSuccess

export default function createDocumentStoreClass({ converter, defaultImmutable }) {
  class DocumentStore {
    constructor(binding) {
      this.immutableStore = new ImmutableStore(binding);
      this.provider = new DocumentProvider(this.immutableStore);
    }

    // for mixins and extensions
    get binding() {
      return this.immutableStore.binding;
    }

    getProvider() {
      return this.provider;
    }

    // replaces the contents of the store
    set(entity) {
      this.immutableStore.set(converter.toImmutable(entity));
    }

    // returns the entity from the store
    get() {
      let immutable = this.immutableStore.get();
      return immutable && converter.fromImmutable(immutable);
    }

    getPending() {
      let pendingImmutable = this.immutableStore.getPending();
      return pendingImmutable && converter.fromImmutable(pendingImmutable);
    }

    setPending(entity) {
      this.immutableStore.setPending(converter.toImmutable(entity));
    }

    isPending() {
      return !!this.immutableStore.getPending();
    }
  }

  // Delegate the rest of DocumentStore APIs to ImmutableStore
  [
    'remove',
    'clearErrors',
    'getErrors',
    'setErrors',
    'isLoading',
    'resetLoading',
    'setLoading',
    'commitPending',
    'resetPending',
  ].forEach(m => {
    DocumentStore.prototype[m] = function(...args) {
      return this.immutableStore[m](...args);
    };
  });

  class ImmutableStore {
    constructor(binding) {
      this.binding = binding;
    }

    /////////////////////
    // Immutable Entity
    /////////////////////
    get() {
      return this.binding.get() || defaultImmutable;
    }

    remove() {
      this.binding.remove();
      this.clearErrors();
      this.resetPending();
    }

    // replaces the contents of the store
    set(immutable) {
      this.binding.update(currentImm => {
        return Immutable.is(immutable, currentImm) ? currentImm : immutable;
      });
    }

    /////////////////////
    // Loading status
    /////////////////////
    isLoading() {
      return !!this._loadingBinding.get();
    }

    resetLoading() {
      this._loadingBinding.remove();
    }

    setLoading() {
      this.clearErrors();
      this._loadingBinding.set(true);
    }

    /////////////////////
    // Pending Entity
    /////////////////////
    commitPending() {
      let pendingImmutable = this._pendingBinding.get();
      if (pendingImmutable) {
        this.binding.set(pendingImmutable);
        this.resetPending();
      }
    }

    getPending() {
      return this._pendingBinding.get();
    }

    resetPending() {
      this._pendingBinding.remove();
    }

    setPending(immutable) {
      this.clearErrors();
      this._pendingBinding.set(immutable);
    }

    /////////////////////
    // Errors
    /////////////////////
    getErrors() {
      return errorsFromImm(this._errorBinding.get());
    }

    clearErrors() {
      this._errorBinding.remove();
    }

    setErrors(errors) {
      this._errorBinding.set(errorsAsImm(errors));
    }

    get _errorBinding() {
      return this.binding.meta('_errors');
    }

    get _loadingBinding() {
      return this.binding.meta('_loading');
    }

    get _pendingBinding() {
      return this.binding.meta('_pending');
    }
  }

  class DocumentProvider {
    constructor(immutableStore) {
      this.store = immutableStore;
    }

    get bindings() {
      return [this.store.binding];
    }

    get() {
      let storedImmutable = this.store.get();

      // if the immutable in the binding has not changed since the last
      // get, then serve the entity from the cache, otherwise, rebuild
      // the cache
      if (this._cachedImmutable !== storedImmutable) {
        this._cachedImmutable = storedImmutable;
        this._cachedEntity = storedImmutable && converter.fromImmutable(storedImmutable);
      }

      return this._cachedEntity;
    }

    getErrors() {
      return this.store.getErrors();
    }

    isPending() {
      return !!this.store.getPending();
    }

    getPending() {
      let pendingImmutable = this.store.getPending();
      return pendingImmutable && converter.fromImmutable(pendingImmutable);
    }

    isLoading() {
      return this.store.isLoading();
    }
  }

  return DocumentStore;
}

function errorsAsImm(errors) {
  return Immutable.List(errors.map(ErrImmutableConverter.toImmutable));
}

function errorsFromImm(errorList) {
  return errorList ? errorList.toArray().map(ErrImmutableConverter.fromImmutable) : [];
}
