import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';

import ClickExternalLink from 'actions/customer_profile/click_external_link';
import connect from 'components/lib/connect';
import { getNormalizedUrl } from 'components/lib/linkify_it';
import LinkOutStrokeIcon from 'components/common/icons/stroke/link-out-stroke';
import ProfileCardType from 'models/configuration/profile_card_type';
import { setOverflowTitle } from 'components/lib/overflow_title';

export const ActivityLinkType = 'ACTIVITY';
export const ChatLinkType = 'CHAT_CUSTOMER_URL';
export const HelpLinkType = 'HELP_URL';
export const ShipmentTracking = 'SHIPMENT_TRACKING_URL';

export function ExternalLink({ className, displayLinkOutIcon, displayOverflowTooltip, url, text, onClick }) {
  const linkTextRef = useRef();
  const displayText = text || '-';

  useEffect(() => {
    if (displayOverflowTooltip) setOverflowTitle(linkTextRef, displayText);
  });

  if (!url) {
    return (
      <div className={className} ref={linkTextRef}>
        {displayText}
      </div>
    );
  }

  const icon = displayLinkOutIcon ? <LinkOutStrokeIcon className="externalLink-icon" /> : null;
  return (
    <a
      className={classnames('externalLink', className)}
      href={getNormalizedUrl(url)}
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className="externalLink-text" ref={linkTextRef}>
        {displayText}{' '}
      </div>
      {icon}
    </a>
  );
}

ExternalLink.propTypes = {
  attrs: PropTypes.object,
  attrName: PropTypes.string,
  className: PropTypes.string,
  displayLinkOutIcon: PropTypes.bool,
  displayOverflowTooltip: PropTypes.bool,
  url: PropTypes.string,
  subType: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf([
    ProfileCardType.BASIC_PROFILE,
    ProfileCardType.CUSTOM_ATTRS,
    ProfileCardType.TRANSACTIONS,
    ChatLinkType,
    ActivityLinkType,
    HelpLinkType,
    ShipmentTracking,
  ]),
  onClick: PropTypes.func.isRequired,
};

function mapExecuteToProps(executeAction, { type, subType, attrName, onClick }) {
  return {
    onClick: evt => {
      if (_.isFunction(onClick)) onClick(evt);
      executeAction(ClickExternalLink, { type, subType, attrName });
    },
  };
}

export default connect(null, mapExecuteToProps)(ExternalLink);
