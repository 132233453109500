import _ from 'lodash';
import { Decoration, Mark } from 'slate-old';
import React from 'react';
import styled from 'styled-components';

import { hasInlineParent } from '../helpers';
import linkifyIt, { getNormalizedUrl } from 'components/lib/linkify_it';

const INLINE_LINK = 'inline_link';

const InlineLink = styled.span`
  border-bottom: 1px dashed ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.green400};
`;

export default function InlineLinksPlugin({ readOnly } = { readOnly: false }) {
  return {
    decorateNode(node, editor, next) {
      const others = next() || [];

      let decorations = [];
      const textNodes = node.getTexts();
      textNodes.forEach(textNode => {
        if (hasInlineParent(editor, textNode)) {
          return;
        }
        const { key, text } = textNode;
        let matches = linkifyIt.match(text);

        _.forEach(matches, match => {
          decorations.push(
            Decoration.create({
              anchor: { key, offset: match.index },
              focus: { key, offset: match.lastIndex },
              mark: Mark.create({ type: INLINE_LINK, data: { text: match.raw } }),
            })
          );
        });
      });

      return [...others, ...decorations];
    },

    renderMark(props, editor, next) {
      const { children, mark, attributes, text } = props;

      switch (mark.type) {
        case INLINE_LINK: {
          return readOnly ? (
            <a {...attributes} href={getNormalizedUrl(text)} rel="noopener noreferrer" target="_blank">
              {children}
            </a>
          ) : (
            <InlineLink {...attributes}>{children}</InlineLink>
          );
        }
        default:
          return next();
      }
    },
  };
}
