import HideDisconnectWarning from 'actions/notification/hide_disconnect_warning';
import qconsole from 'scripts/lib/qconsole';

export default function updateConnectionState(type, fn) {
  let connectionState = this.context.stores.connectionState.get();

  const wasDisconnected = !!connectionState[type].isDisconnected;
  fn(connectionState[type]);
  const isDisconnected = !!connectionState[type].isDisconnected;

  // report the state transition
  if (wasDisconnected !== isDisconnected) {
    if (isDisconnected) {
      const msg = `Disconnected - ${type}`;
      qconsole.warn(msg);
      this.context.analytics.track(msg);
    } else {
      const msg = `Reconnected - ${type}`;
      this.context.analytics.track(msg);
      qconsole.log(msg);
    }
  }

  // only hide the disconnect warning from here
  // as it is shown only when navigation changes
  if (!isDisconnected) {
    this.context.executeAction(HideDisconnectWarning);
  }

  this.context.stores.connectionState.set(connectionState);
}
