import React, { useCallback, useState, useEffect } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import MicrophoneUnmutedFillIcon from 'components/common/icons/fill/microphone-unmute-fill';
import MicrophoneMutedFillIcon from 'components/common/icons/fill/microphone-mute-fill';
import MutePhoneCall from 'actions/phone_call/mute_phone_call';
import classnames from 'classnames';
import styled from 'styled-components';
import connect from 'components/lib/connect';
import Tooltip from 'components/common/tooltip';
import ObserverJoin from 'actions/conversation_item/agent_coaching/observer_join';
import ObserverCoach from 'actions/conversation_item/agent_coaching/observer_coach';
import ObserverListen from 'actions/conversation_item/agent_coaching/observer_listen';
import PhoneCall from 'models/phone_call';

export function CallCoachingPills({ isDisabled, isMuted, observerStatus, onListen, onCoach, onJoin, toggleMute }) {
  const onListenClick = useCallback(() => {
    setShowMicrophone(false);
    setRequestedObserverStatus(PhoneCall.PhoneCallObserverStatus.LISTEN);
    setRequestedMutedState(true);
    onListen();
  }, [onListen]);

  const onCoachClick = useCallback(() => {
    setShowMicrophone(true);
    setRequestedObserverStatus(PhoneCall.PhoneCallObserverStatus.COACH);
    setRequestedMutedState(preservedMuteState);
    onCoach({ isMuted: preservedMuteState });
  }, [onCoach]);

  const onJoinClick = useCallback(() => {
    setShowMicrophone(true);
    setRequestedObserverStatus(PhoneCall.PhoneCallObserverStatus.JOIN);
    setRequestedMutedState(preservedMuteState);
    onJoin({ isMuted: preservedMuteState });
  }, [onJoin]);

  const onMuteToggle = useCallback(() => {
    setRequestedMutedState(!isMuted);
    setPreservedMuteState(!isMuted);
    toggleMute({ isMuted: !isMuted });
  }, [isMuted]);

  const [showMicrophone, setShowMicrophone] = useState(observerStatus !== PhoneCall.PhoneCallObserverStatus.LISTEN);
  const [requestedObserverStatus, setRequestedObserverStatus] = useState(observerStatus);
  const [requestedMutedState, setRequestedMutedState] = useState(isMuted);
  const [preservedMuteState, setPreservedMuteState] = useState(isMuted);

  // the observer status and muted state can be updated outside this component, this happens when you go invisible
  // and the pill gets disabled.
  if (isDisabled) {
    if (observerStatus !== requestedObserverStatus) {
      setRequestedObserverStatus(observerStatus);
      setShowMicrophone(observerStatus !== PhoneCall.PhoneCallObserverStatus.LISTEN);
    }
  }

  useEffect(() => {
    setRequestedMutedState(isMuted);
  }, [isMuted]);

  const microphoneTooltipMessage = isMuted ? 'Unmute yourself' : 'Mute Yourself';
  const hasRequestPending = observerStatus !== requestedObserverStatus || isMuted !== requestedMutedState;
  const isDisabledOrPending = isDisabled || hasRequestPending;

  const listenClassNames = classnames('callCoachingPill-listening', {
    isActive: observerStatus === PhoneCall.PhoneCallObserverStatus.LISTEN,
  });
  const coachClassNames = classnames('callCoachingPill-coach', {
    isActive: observerStatus === PhoneCall.PhoneCallObserverStatus.COACH,
  });
  const joinClassNames = classnames('callCoachingPill-join', {
    isActive: observerStatus === PhoneCall.PhoneCallObserverStatus.JOIN,
    showMicrophone,
  });
  const microphoneClassNames = classnames('callCoachingPill-microphone', { invisible: showMicrophone === false });
  const pillContainerClassNames = classnames('callCoachingPills', {
    disabled: isDisabledOrPending,
  });

  return (
    <PillContainer className={pillContainerClassNames}>
      <div className={'callCoaching-listen-container'} onClick={isDisabledOrPending ? noop : onListenClick}>
        <Tooltip
          className={'callCoaching-listen-tooltip'}
          key={'callCoaching-listen-tooltip'}
          message="Listen to the call"
          position="bottom"
        >
          <CallPill className={listenClassNames} isDisabled={isDisabledOrPending}>
            <ActionText>Listen</ActionText>
          </CallPill>
        </Tooltip>
      </div>
      <div className={'callCoaching-coach-container'} onClick={isDisabledOrPending ? noop : onCoachClick}>
        <Tooltip
          className={'callCoaching-coach-tooltip'}
          key={'callCoaching-coach-tooltip'}
          message="Coach the agent"
          position="bottom"
        >
          <CallPill className={coachClassNames} isDisabled={isDisabledOrPending}>
            <ActionText>Coach</ActionText>
          </CallPill>
        </Tooltip>
      </div>
      <div className={'callCoaching-join-container'} onClick={isDisabledOrPending ? noop : onJoinClick}>
        <Tooltip
          className={'callCoaching-join-tooltip'}
          key={'callCoaching-join-tooltip'}
          message="Join the call"
          position="bottom"
        >
          <CallPill className={joinClassNames} isDisabled={isDisabledOrPending}>
            <ActionText>Join</ActionText>
          </CallPill>
        </Tooltip>
      </div>
      <MicrophoneHolder className={microphoneClassNames} onClick={isDisabledOrPending ? noop : onMuteToggle}>
        <Tooltip
          className={'callCoaching-microphone-tooltip'}
          key={'callCoaching-microphone-tooltip'}
          message={microphoneTooltipMessage}
          position="bottom"
        >
          <CallPill isDisabled={isDisabledOrPending}>
            {isMuted ? (
              <MicrophoneMutedFillIcon className={'callCoaching-microphone muted'} />
            ) : (
              <MicrophoneUnmutedFillIcon className={'callCoaching-microphone unmuted'} />
            )}
          </CallPill>
        </Tooltip>
      </MicrophoneHolder>
    </PillContainer>
  );
}

CallCoachingPills.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
  observerStatus: PropTypes.string.isRequired,
  onJoin: PropTypes.func.isRequired,
  onCoach: PropTypes.func.isRequired,
  onListen: PropTypes.func.isRequired,
  toggleMute: PropTypes.func.isRequired,
};

const CallCoachingPillsContainer = connect(mapStateToProps, mapExecuteToProps)(CallCoachingPills);
export default CallCoachingPillsContainer;

function mapStateToProps({ getProvider }) {
  let currentAgent = getProvider('currentAgent').get();
  let activeCall = getProvider('activeCall').get();
  let phoneCall = activeCall.conversationItem.content;
  if (!phoneCall) {
    return {};
  }
  let participant = phoneCall.findParticipantById(currentAgent.id);
  // Dialing is considered muted since the call participant is not yet on the call and can not be heard
  let isMuted = phoneCall.isMuted(currentAgent.id) || phoneCall.isDialing(currentAgent.id);
  return {
    isDisabled: phoneCall.isHidden(currentAgent.id) || phoneCall.isDialing(currentAgent.id),
    isMuted,
    observerStatus: participant ? participant.observerStatus : PhoneCall.PhoneCallObserverStatus.LISTEN,
  };
}

function mapExecuteToProps(executeAction, { customerId, conversationItemId }) {
  return {
    onJoin: ({ isMuted }) => executeAction(ObserverJoin, { customerId, conversationItemId, isMuted }),
    onCoach: ({ isMuted }) => executeAction(ObserverCoach, { customerId, conversationItemId, isMuted }),
    onListen: () => executeAction(ObserverListen, { customerId, conversationItemId }),
    toggleMute: ({ isMuted }) => executeAction(MutePhoneCall, isMuted),
  };
}

const ActionText = styled.div`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.small};
  font-weight: ${p => p.theme.fontWeight.heavy};
  color: ${p => (p.isDisabled ? p.theme.colors.red400 : '')};
`;

const MicrophoneHolder = styled.div`
  border-left: 1px solid #cecece;
  display: flex;
  position: relative;
  transition: max-width 0.5s ease, border-color 0.5s ease, opacity 1.4s ease;
  max-width: 45px;
  opacity: 1;

  &.invisible {
    overflow: hidden;
    max-width: 0;
    border-color: transparent;
    opacity: 0;
    transition: max-width 0.5s ease, border-color 0.5s ease, opacity 0.5s ease;
  }
`;

const PillContainer = styled.div`
  animation: flex 3s linear;
  border: 1px solid ${p => p.theme.colors.gray400};
  border-radius: 100px;
  display: flex;
  height: 30px;
  overflow: hidden;
`;

const CallPill = styled.div`
  align-items: center;
  border-radius: 50px 50px;
  display: flex;
  flex-direction: row;
  height: 28px;
  padding: 8px 14px 10px;
  color: ${p => (p.isDisabled ? p.theme.colors.gray400 : p.theme.colors.gray900)};
  &:hover {
    cursor: pointer;
  }
  &.isActive {
    background-color: ${p => p.theme.colors.green400};
    color: ${p => p.theme.colors.white};
  }
  &.callCoachingPill-join.showMicrophone {
    border-radius: 50px 0px 0px 50px;
  }
  .callCoaching-microphone {
    fill: ${p => (p.isDisabled ? p.theme.colors.gray400 : p.theme.colors.green400)};
    height: ${p => p.theme.lineHeight.controls};
  }
  .callCoaching-microphone.muted {
    fill: ${p => (p.isDisabled ? p.theme.colors.gray400 : p.theme.colors.red400)};
  }
`;
