import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import connect from 'components/lib/connect';

export function RuleLink({ canViewRules, children, className, onNavigate, ruleId }) {
  if (!canViewRules || !ruleId) {
    return children;
  }

  let linkClassName = className || 'conversationItem-metadata-link';
  const route = `/admin/rules/${ruleId}`;
  let handleClick = evt => {
    evt.preventDefault();
    if (_.isFunction(onNavigate)) onNavigate(route);
  };

  return (
    <a className={linkClassName} data-aid="adminRulesLink" href={route} onClick={handleClick}>
      {children}
    </a>
  );
}

RuleLink.propTypes = {
  children: PropTypes.node.isRequired,
  canViewRules: PropTypes.bool,
  className: PropTypes.string,
  onNavigate: PropTypes.func.isRequired,
  ruleId: PropTypes.string,
};

function mapStateToProps({ isFeatureEnabled }) {
  return { canViewRules: isFeatureEnabled('rulesManagement') };
}

function mapExecuteToProps() {
  return {
    onNavigate: route => window.location.assign(route),
  };
}

const RuleLinkContainer = connect(mapStateToProps, mapExecuteToProps)(RuleLink);

RuleLinkContainer.propTypes = {
  children: PropTypes.node.isRequired,
  ruleId: PropTypes.string,
};

export default RuleLinkContainer;
