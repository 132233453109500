import {Factory} from 'rosie';
import '../conversation_item_factory';

Factory.define('HotelReservation')
  .attr('type', 'HOTEL_RESERVATION');

Factory.define('HotelReservationWithDefaults')
  .extend('HotelReservation')
  .attr('name', 'Dinner Reservation')
  .attr('hotel', 'Toronto Four Seasons')
  .attr('guests', '2 Adults')
  .attr('confirmationNumber', '257-1234');

Factory.define('HotelReservationItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function (content) {
    return Factory.attributes('HotelReservation', content);
  });


Factory.define('HotelReservationItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function (content) {
    return Factory.attributes('HotelReservationWithDefaults', content);
  });