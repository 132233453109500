export default class TrackTooltipShown {
  constructor(context) {
    this.context = context;
  }

  run(props) {
    const { customerId } = props;
    let numMatches = 0;
    let activeCall = this.context.stores.activeCall.get();

    if (this.context.stores.customers.has({ id: customerId })) {
      const customersStores = this.context.stores.customers.storesFor(customerId);
      numMatches = customersStores.customerMatch.get() && customersStores.customerMatch.get().profiles.length;
    }
    this.context.analytics.track('Customer Match Tooltip Shown', {
      ...props,
      numMatches,
      hasActiveCall: !!activeCall,
    });
  }
}
