/**
 * Simple utility function that sets or removes an element/component "title" depending on whether
 * the component value is clipped. If clipped, adding a title may improve usability. In many cases,
 * the "title" will be the full text that would have been displayed by the component if it was not
 * clipped
 *
 * NOTE: Be careful when using this function with React components that do not "forward refs", otherwise
 * it may set the title on a wrong DOM element.
 *
 * Usage example:
 *
 *  const someLongValueRef = useRef()
 *  ...
 *
 *  useEffect(() => {
 *    setOverflowTitle(someLongValueRef, 'My very long text that is likely to be clipped')
 *  })
 *
 *  ...
 *
 *  <div ref={someLongValueRef}>My very long text that is likely to be clipped</div>
 *
 * @param {*} elementRef - React "ref" to the target element OR the DOM element itself (this may happen if
 * we are trying to set title of a child element, e.g. `someRef.current.someChild`)
 * @param {string} title - The title that will be set on the component if it overflows
 */
export function setOverflowTitle(elementRef, title) {
  if (!elementRef || !title) return;

  // Try React "ref" first, if not - assume we were passed a direct DOM node ref
  const element = elementRef.current || elementRef;
  const hasOverflow = element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
  const currentTitle = element.title;

  if (hasOverflow) {
    if (currentTitle !== title) element.title = title;
  } else {
    if (currentTitle) element.title = '';
  }
}
