import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import Link from 'components/common/link';
import ReportDuration from 'components/reporting/report/lib/report_duration';
import ReportHelpers from './report_helpers';
import Reports from 'models/location/reports';
import Search from 'models/location/search';
import SearchResult from 'models/search/search_result';

export const createReportDateCellContent = aggregationLevel => {
  return ownProps => <span>{ReportHelpers.toDisplayDate(ownProps.date, aggregationLevel)}</span>;
};

export const ReportDurationCellContent = ({ value }) => {
  return <ReportDuration displayDuration={ReportDuration.toDisplayDuration(value)} />;
};
ReportDurationCellContent.propTypes = { value: PropTypes.number };

export const ReportPercentCellContent = ({ value }) => {
  return <span>{value === null ? '–' : (value * 100.0).toFixed(1)}</span>;
};
ReportPercentCellContent.propTypes = { value: PropTypes.number };

export const ReportNumberCellContent = ({ value }) => {
  return <span>{!value ? '0' : value.toLocaleString()}</span>;
};
ReportNumberCellContent.propTypes = { value: PropTypes.number };

export const ReportTextCellContent = ({ value }) => {
  return <span>{value.trim()}</span>;
};

// ReportNumberCell wrapped with <Link /> to advanced search
export const createLinkedReportNumberCell = ({
  channel,
  routingGroupIds,
  topicIds,
  status,
  hasCreatedFilter,
  hasUpdatedFilter,
}) => {
  return ownProps => {
    const { startDate, endDate, value } = ownProps;
    const href = createSearchLink({
      channel,
      routingGroupIds,
      topicIds,
      status,
      hasCreatedFilter,
      hasUpdatedFilter,
      startDate,
      endDate,
    });
    return (
      <Link className="metricCard-link" href={href}>
        <ReportNumberCellContent value={value} />
      </Link>
    );
  };
};

export const createSearchLink = ({
  topicIds,
  channel,
  routingGroupIds,
  status,
  hasCreatedFilter,
  hasUpdatedFilter,
  startDate,
  endDate,
}) => {
  const searchParams = {
    type: SearchResult.HitTypes.CONVERSATION,
    inboxIds: routingGroupIds,
    topicIds,
    channels: [channel],
    statuses: [status],
  };
  if (hasCreatedFilter || hasUpdatedFilter) {
    const dateRange = { gte: startDate, lte: endDate };
    if (hasCreatedFilter) {
      searchParams.createdAt = dateRange;
    }
    if (hasUpdatedFilter) {
      searchParams.updatedAt = dateRange;
    }
  }
  return getLocationUrl(Search.createFiltered(searchParams));
};

export const getDatesForCell = ({ minStartDate, maxEndDate, date, aggregationLevel }) => {
  if (date) {
    const timeUnit = Reports.getUnitsForAggregation(aggregationLevel);
    const cellEnd = moment(date)
      .add(1, timeUnit)
      .subtract(1, 'ms');
    return {
      startDate: moment.max(minStartDate, date).toISOString(),
      endDate: moment.min(maxEndDate, cellEnd).toISOString(),
    };
  }

  return { startDate: minStartDate.toISOString(), endDate: maxEndDate.toISOString() };
};
