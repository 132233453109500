import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import createEnum from 'scripts/lib/create_enum';
import { MenuContainer, MenuItem } from 'components/lib/menu_deprecated';

export const Channel = createEnum('EMAIL', 'PHONE');

export default function CustomerContactMenu({ isDisabled, onOpen, onSelectItem }) {
  return (
    <MenuContainer button={renderButton()} className="customerContactMenu" isDisabled={isDisabled} onOpen={onOpen}>
      {[Channel.EMAIL, Channel.PHONE].map(renderChannelItem)}
    </MenuContainer>
  );

  function renderButton() {
    return <i className={getButtonClassName()} />;
  }

  function getButtonClassName() {
    return classnames('customerContactMenu-button-icon', 'icon', 'icon-plus', {
      'customerContactMenu-button-icon-disabled': isDisabled,
    });
  }

  function renderChannelItem(channel) {
    return (
      <MenuItem
        className={`customerContactMenu-item customerContactMenu-item-${_.camelCase(channel)}`}
        key={channel}
        onSelect={handleItemClick.bind(null, channel)}
      >
        <div className={`customerContactMenu-item-icon icon-${_.camelCase(channel)}`} />
        {`Add ${channel.toLowerCase()}`}
      </MenuItem>
    );
  }

  function handleItemClick(channel) {
    onSelectItem(channel);
  }
}

CustomerContactMenu.propTypes = {
  isDisabled: PropTypes.bool,

  onOpen: PropTypes.func,
  onSelectItem: PropTypes.func.isRequired,
};
