import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'SnippetEditor',
  properties: {
    snippetId: String,
  },

  statics: {
    breadcrumb: 'Answer',
    create({ snippetId } = { snippetId: undefined }) {
      return new this({ snippetId });
    },
  },
});
