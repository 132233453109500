import _ from 'lodash';

import Attachment from 'models/attachment';
import attachmentFromJs, { typeReflect } from 'models/attachment/attachment_from_js';
import createModel, { prop } from 'models/lib/create_model';
import { getVariableIdsFromString } from './snippet_helpers';
import Upload from 'models/upload';

const SnippetContent = createModel({
  modelName: 'SnippetContent',

  properties: {
    attachments: prop([Attachment, Upload])
      .default([])
      .fromJs(attachmentFromJs),
    bodyHtml: String,
    internal: Boolean, // used for the info content type
    name: String, // used for the self service content type
    subject: String, // used for email
  },

  addAttachment(attachment) {
    this.attachments.push(attachment);
  },

  convertUploadsToAttachments(snippetId) {
    this.attachments = _.map(this.attachments, attachment => {
      if (attachment instanceof Upload) {
        let fd = attachment.fileDescriptor();
        return Attachment.create({
          id: attachment.id,
          contentType: fd.contentType,
          filename: fd.filename,
          fileSize: fd.contentLength,
          isInline: attachment.isInline,
          source: {
            type: Attachment.SourceType.SNIPPET,
            path: this.getUploadPath(snippetId, attachment.id),
          },
        });
      }
      return attachment;
    });
  },

  findAttachmentById(id) {
    return this.attachments.find(attachment => attachment.id === id);
  },

  getVariableIds() {
    return getVariableIdsFromString(this.bodyHtml);
  },

  getUploadPath(snippetId, uploadId) {
    return `kb/snippets/${snippetId}/${uploadId}`;
  },

  removeAttachment(id) {
    return _.remove(this.attachments, { id })[0];
  },

  updateUpload(upload) {
    let index = _.findIndex(this.attachments, { id: upload.id });
    this.attachments[index] = upload;
  },

  overrideToJs(toJs) {
    return () => ({
      ...toJs(),
      attachments: this.attachments.map(a => ({ ...a.toJs(), type: typeReflect.instanceToType(a) })),
    });
  },

  update(attrs) {
    _.assign(this, attrs);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default SnippetContent;
