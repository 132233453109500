import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('RecordingSummaryStatus')
  .attr('state', 'READY');

Factory.define('RecordingSummaryStatusPending')
  .extend('RecordingSummaryStatus')
  .attr('state', 'PENDING');

Factory.define('RecordingSummaryStatusFailure')
  .extend('RecordingSummaryStatus')
  .attr('state', 'FAILED')
  .attr('errorCode', 'GENERAL')
  .attr('errorMessage', 'An error occurred while processing the recording.');

Factory.define('RecordingSummary');

Factory.define('RecordingSummaryWithDefaults')
  .extend('RecordingSummary')
  .attr('language', 'en')
  .attr('status', Factory.build('RecordingSummaryStatus'))
  .attr('summary', "The customer, and the company's financial results for the quarter were down 2.1% and 2.6% from the prior year. We expect our first quarter 2019 adjusted EBITDA to be in the range of $12 million to $13 million. We expect our first quarter 2019 adjusted EBITDA to be between $80 million and $85 million. This is a result of the strong performance in the first quarter of 2018 and the strong performance in the first quarter of 2019. We expect our adjusted EBITDA margin to be in the range of 17% to 17% for the full year 2019.")
  .attr('transcriptionConfidence', 0.987654);

