import React from 'react';
import IconContainer from './icon_container';

export default function(props) {
  return (
    <IconContainer viewBox="0 0 57 51" {...props} className="errorIcon">
      <path
        className="errorIcon-stroke"
        d="M2.741 49h52.518a1 1 0 0 0 .864-1.504L29.863 2.481a1 1 0 0 0-1.727 0L1.877 47.496A1 1 0 0 0 2.741 49z"
        fill="none"
        stroke="#000"
        strokeWidth="2"
      />
      <g className="errorIcon-fill">
        <path d="M30 15a1 1 0 1 0-2 0h2zm-2 20a1 1 0 1 0 2 0h-2zm0-20v20h2V15h-2z" />
        <circle cx="29" cy="41" r="2" />
      </g>
    </IconContainer>
  );
}
