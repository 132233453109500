import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'CustomChannelConfiguration',
  properties: {
    customChannelId: prop(String),
    recipientAddress: prop(String),
  },

  setCustomChannelId(customChannelsId) {
    this.customChannelId = customChannelsId;
  },

  setRecipientAddress(recipientAddress) {
    this.recipientAddress = recipientAddress;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
