import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('ConversationNotification');

Factory.define('ConversationNotificationWithDefaults')
  .extend('ConversationNotification')
  .attr('type', 'ASSIGNED_CONVERSATION')
  .attr('profile', () => Factory.attributes('CustomerProfileWithDefaults'))
  .attr('conversationId', 'conversation-id')
  .attr('conversationItem', () => Factory.attributes('EmailMessageItemWithDefaults'))
  .attr('id', ['conversationItem'], conversationItem => conversationItem.id);

Factory.define('ConversationNotificationWithEmail').extend('ConversationNotificationWithDefaults');

Factory.define('ConversationNotificationWithAbandonedCall')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('AbandonedCallItemWithDefaults'));

Factory.define('ConversationNotificationWithChatMessage')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('ChatMessageItemWithDefaults'));

Factory.define('ConversationNotificationWithCompletedCall')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('PhoneCallItemCompleted'));

Factory.define('ConversationNotificationWithFbMessage')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('FbMessageIncomingItemWithDefaults'));

Factory.define('ConversationNotificationWithFbMessage')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('FbMessageIncomingItemWithDefaults'));

Factory.define('ConversationNotificationWithEmailSendFailure')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('EmailSendFailureItemWithDefaults'));

Factory.define('ConversationNotificationWithNote')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('ConversationNoteItemWithDefaults'));

Factory.define('ConversationNotificationWithSms')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('SmsMessageItemWithDefaults'));

Factory.define('ConversationNotificationWithTask')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('TaskItemWithDefaults'));

Factory.define('ConversationNotificationWithVoicemail')
  .extend('ConversationNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('VoicemailItemWithDefaults'));
