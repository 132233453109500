import createModel, { prop } from '../lib/create_model';
import ExpiringUrl from 'models/reports/expiring_url';

const EmbeddedReport = createModel({
  modelName: 'EmbeddedReport',
  properties: {
    expiringUrl: prop(ExpiringUrl),
    metricSet: prop(),
    supportedFilters: prop([String]),
    timezone: prop(String).default('America/New_York'),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default EmbeddedReport;
