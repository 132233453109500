import CustomerView from 'models/location/customer_view';
import HideToast from 'actions/toast_deprecated/hide_toast';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import qconsole from 'scripts/lib/qconsole';
import RemoveToast from 'actions/toast_deprecated/remove_toast';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastCategory, ToastType } from 'models/toast_deprecated';
import { GladlyError } from 'scripts/application/lib/error';
import _ from 'lodash';

export const LATENCY_BANNER_DELAY = 500;
export const REQUEST_TIMEOUT = 60000; // 1 minute

export default class RequestTimelineConversationSummary {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId }) {
    const currentAgent = this.context.stores.currentAgent.get();
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    const currentAgentId = currentAgent?.id;
    const currentCustomerId = currentLocation.customerId;

    if (!conversationId) {
      this.context.errorReporter.reportError(Error('RequestTimelineConversationSummary: invalid conversationId'), {
        tags: {
          agentId: currentAgentId,
          customerId: currentCustomerId,
        },
        message: 'Missing or invalid parameter "conversationId"',
      });
      return;
    }

    // Show activity banner - the backend call may take a few seconds. Display the banner with a short delay
    // so that the initial errors (that come back right away) do not bump into the banner
    const bannerId = IdGenerator.newId();
    const bannerTimer = setTimeout(() => {
      this.context.executeAction(ShowToast, {
        id: bannerId,
        category: ToastCategory.ACTIVITY_BANNER,
        type: ToastType.INFO,
        message: 'Stand by. Summarizing the conversation',
        persistent: true,
      });
    }, LATENCY_BANNER_DELAY);

    // Record the request
    this.context.analytics.track('Timeline Conversation Summary Requested', {
      conversationId,
      customerId: currentCustomerId,
      summaryType: 'complete',
    });

    // Request summarization
    return this.context.gateways.aiConversationSummary
      .add({ conversationId }, {}, { timeout: REQUEST_TIMEOUT })
      .then(() => {
        clearTimeout(bannerTimer);
        this.context.executeAction(HideToast, bannerId);
        RemoveToast.schedule(this.context, { id: bannerId });
      })
      .catch(err => {
        // Since the gateway re-throws errors that are likely already handled, we don't need to do much here
        clearTimeout(bannerTimer);
        this.context.executeAction(RemoveToast, bannerId);
        qconsole.log(`Error while summarizing the conversation "${conversationId}": ${this.getErrorMessage(err)}`);
      });
  }

  getErrorMessage(error) {
    if (!error) return '';
    if (error instanceof GladlyError) {
      return _.get(error, 'cause.message') || error.message;
    }
    return error.message;
  }
}
