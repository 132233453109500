import createModalModel, { prop } from './lib/create_modal_model';

export default createModalModel({
  modelName: 'MergeProfile',
  properties: {
    customerId: prop(String),
    pendingDetail: prop({
      email: String,
      index: Number,
      phone: String,
    }).default({}),
  },
});
