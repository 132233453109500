import Err from '../../models/err';
import createEnum from 'scripts/lib/create_enum';

export var ExtensionErrorReason = createEnum('TOO_LONG', 'NAN');

export default function createInvalidExtensionError(attrName, type) {
  switch (type) {
    case ExtensionErrorReason.TOO_LONG:
      return new Err({ code: Err.Code.INVALID, attr: attrName, detail: '7 digits max' });
    case ExtensionErrorReason.NAN:
      return new Err({ code: Err.Code.INVALID, attr: attrName, detail: 'must be numeric' });
    default:
      return new Err({ code: Err.Code.INVALID, attr: attrName, detail: 'invalid' });
  }
}
