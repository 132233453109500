import ContentClasses from 'models/conversation_item/content_classes';
import TypeReflect from 'models/lib/type_reflect';

export const typeReflect = new TypeReflect(ContentClasses);

export default function contentFromJs(content) {
  function getContentClass(contentType) {
    try {
      return typeReflect.typeToConstructor(contentType);
    } catch (e) {
      throw new Error(`unknown conversation item content type [${contentType}]`);
    }
  }

  return getContentClass(content.type).fromJs(content);
}
