import { faFontCase } from '@fortawesome/pro-solid-svg-icons/faFontCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { Emoji, Mention } from 'components/composer/shared/editor_action_buttons';
import { Item } from 'components/customer/composition/lib/slate/slate_styles_menu.styles';
import FormatterContext from 'components/composer/shared/formatter_context';

export default function BelowEditorActions({ children, editorRef, editorProps, positionRef, onChange, value }) {
  const { isFormattingVisible, toggleIsFormattingVisible } = useContext(FormatterContext);

  return (
    <StyledBelow>
      <Emoji
        editorRef={editorRef}
        key="editor-emoji-styles"
        menuMargin={16}
        menuPosition="left"
        positionRef={positionRef}
      />
      <Mention editorRef={editorRef} />
      <FormattingToggle
        isFormattingVisible={isFormattingVisible}
        key="editor-rich-text-toggle"
        onClick={() => toggleIsFormattingVisible(!isFormattingVisible)}
      />
      {children}
    </StyledBelow>
  );
}

const StyledBelow = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 4px;
  margin-top: 4px;
`;

function FormattingToggle({ isFormattingVisible, onClick }) {
  const title = isFormattingVisible ? 'Hide formatting' : 'Show formatting';
  const preventBlurring = evt => evt.preventDefault();

  return (
    <FormattingToggleItem data-aid="formatting" onClick={onClick} onMouseDown={preventBlurring} title={title}>
      <FontAwesomeIcon icon={faFontCase} />
    </FormattingToggleItem>
  );
}

const FormattingToggleItem = styled(Item)`
  font-size: 13px;
`;
