import CreditCardAmountModal from 'models/modal/credit_card_amount';
import CustomerView from 'models/location/customer_view';

export default class ShowCreditCardAmountConfirmationModal {
  constructor(context) {
    this.context = context;
  }

  run({ amount, memo }) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error('cannot show credit card confirmation modal when not on customer page');
    }

    const customer = this.context.stores.customers.storesFor(currentLocation.customerId);
    const profileStore = customer.profile.get();
    const customerName = profileStore.name || 'Customer';

    this.context.stores.modal.set(new CreditCardAmountModal({ amount, customerName, memo }));
  }
}
