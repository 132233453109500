import createLocationModel from './create_location_model';

export default function createAdminLocationModel(modelDef) {
  let updatedModelDef = {
    ...modelDef,
    properties: {
      ...modelDef.properties,
      scrollTop: Number,
    },
    setScrollTop(scrollTop) {
      this.scrollTop = scrollTop;
    },
  };

  return createLocationModel(updatedModelDef);
}
