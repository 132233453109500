import createModel, { prop } from 'models/lib/create_model';

const AgentNotificationMeta = createModel({
  modelName: 'AgentNotification',

  properties: {
    hasReachedEnd: prop(Boolean).default(false),
  },

  setHasReachedEnd(hasReachedEnd) {
    this.hasReachedEnd = hasReachedEnd;
  },
});

export default AgentNotificationMeta;
