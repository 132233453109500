import _ from 'lodash';

import Agent from './agent';
import AgentPhonePreferences from 'models/agent_phone_preferences';
import AgentVoiceConfiguration from './agent_voice_configuration';
import createModel, { prop } from './lib/create_model';
import createTooLongError from 'scripts/domain/factories/error/create_too_long_error';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ServerClock from 'scripts/application/lib/server_clock';

/**
 * A complete representation of the agent profile.
 *
 * This model exists primarily to let the currently logged in agent edit their attributes,
 * such as comm channels, avatar, etc.
 *
 * It can also be used by an Agent Administrator to view, create, and edit agents in the organization.
 */
const AgentProfile = createModel({
  modelName: 'AgentProfile',

  properties: {
    id: prop(String).isRequired,
    externalName: String,
    name: String,
    avatarUrl: String,
    email: String,
    twitter: String,
    // A disabled agent cannot get any work assigned - will only be different from user disabled during user creation
    disabledAt: String,
    employeeId: String,
    emailGreetingSnippetId: String,
    emailSignatureSnippetId: String,
    voiceConfiguration: prop(AgentVoiceConfiguration),
    version: prop(Number).default(0),
  },

  toAgent() {
    return new Agent(this);
  },

  update(attrs) {
    _.assign(
      this,
      _.pick(
        attrs,
        'email',
        'name',
        'avatarUrl',
        'employeeId',
        'emailGreetingSnippetId',
        'emailSignatureSnippetId',
        'externalName'
      )
    );
    if (attrs.voiceConfiguration) {
      this.voiceConfiguration = AgentVoiceConfiguration.create(attrs.voiceConfiguration);
    }
  },

  updatePhoneNumber(phone) {
    this.voiceConfiguration.updatePhoneNumber(phone);
  },

  removePhoneNumber() {
    this.voiceConfiguration.removePhoneNumber();
  },

  hasPhoneNumber() {
    return !!(this.voiceConfiguration && this.voiceConfiguration.phone);
  },

  getPhoneNumber() {
    return _.get(this, 'voiceConfiguration.phone.original', null);
  },

  getVoiceConfigurationPreference() {
    return _.get(this.voiceConfiguration, 'preference', AgentPhonePreferences.NONE);
  },

  isConfiguredForInteractiveVoice() {
    let voicePreference = this.getVoiceConfigurationPreference();

    if (
      voicePreference === AgentPhonePreferences.NONE ||
      voicePreference === AgentPhonePreferences.BROWSER ||
      voicePreference === AgentPhonePreferences.DIRECT_DIAL ||
      this.isConfiguredForSip()
    ) {
      return true;
    }

    return false;
  },

  isConfiguredForSip() {
    let voicePreference = this.getVoiceConfigurationPreference();

    if (
      voicePreference === AgentPhonePreferences.STATION &&
      this.voiceConfiguration.stationId.toLowerCase().startsWith('sip:')
    ) {
      return true;
    }

    return false;
  },

  usesExternalHandset() {
    let voicePreference = this.getVoiceConfigurationPreference();

    if (voicePreference === AgentPhonePreferences.DIRECT_DIAL || voicePreference === AgentPhonePreferences.STATION) {
      return true;
    }

    return false;
  },

  disable() {
    this.disabledAt = ServerClock.toISOString();
  },

  enable() {
    this.disabledAt = null;
  },

  isDisabled() {
    return !!this.disabledAt;
  },

  getDefaultRoutingGroup(routingGroups) {
    return _.find(routingGroups, g => g.agentIds.indexOf(this.id) > -1) || _.head(routingGroups);
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },

    getValidationErrors,

    MAX_LENGTH: {
      name: 100,
    },
  },
});

function getValidationErrors(props) {
  let errors = [];

  ['name'].forEach(function(field) {
    if (props[field] && props[field].length > AgentProfile.MAX_LENGTH[field]) {
      errors.push(createTooLongError(field, `${AgentProfile.MAX_LENGTH[field]} characters`));
    }
  });

  if (props.voiceConfiguration) {
    errors = AgentVoiceConfiguration.getValidationErrors(props.voiceConfiguration, errors);
  }
  return errors;
}

export default AgentProfile;
