import _ from 'lodash';

import createModel from './lib/create_model';
import Endpoint from './endpoint';

/* ChannelConfiguration - Organizations have Accounts, which consist of secure credentials for configuring
 *                        the account, and a list of endpoints. Non-admin users are should not have access
 *                        to the full Account object, seeing as they don't need access to the secure
 *                        credentials. Users just need all of the endpoints associated with all accounts,
 *                        so they can see / use them for outgoing communication.
 */

export default createModel({
  modelName: 'ChannelConfiguration',

  properties: {
    endpoints: [Endpoint],
  },

  addOrReplaceEndpoint(updatedEndpoint) {
    const index = this.endpoints.findIndex(e => e.id === updatedEndpoint.id);
    if (index !== -1) {
      this.endpoints[index] = updatedEndpoint;
    } else {
      this.endpoints.push(updatedEndpoint);
    }
  },

  removeEndpoint(endpointId) {
    const index = this.endpoints.findIndex(e => e.id === endpointId);
    if (index !== -1) {
      this.endpoints.splice(index, 1);
    }
  },

  isChannelEnabled(channel) {
    return this._endpointsWithType(channel).length > 0;
  },

  chatAddresses() {
    return this._endpointsWithType(Endpoint.Type.CHAT);
  },

  fromAddresses() {
    return this._endpointsWithType(Endpoint.Type.EMAIL);
  },

  companyInstagramAddresses() {
    return this._endpointsWithType(Endpoint.Type.INSTAGRAM_DIRECT);
  },

  companyCustomChannelRecipients(customChannelId) {
    return _.filter(
      this._endpointsWithType(Endpoint.Type.CUSTOM_CHANNEL),
      endpoint => endpoint.configuration?.customChannelId === customChannelId
    );
  },

  companyPhoneNumbers() {
    return this._endpointsWithType(Endpoint.Type.VOICE);
  },

  companySmsNumbers() {
    return this._endpointsWithType(Endpoint.Type.SMS);
  },

  companyWhatsAppNumbers() {
    return this._endpointsWithType(Endpoint.Type.WHATSAPP);
  },

  companyTwitterAddresses() {
    return this._endpointsWithType(Endpoint.Type.TWITTER);
  },

  getEndpointsOfType(type) {
    return this._filterEndpointsByType(type);
  },

  _filterEndpointsByType(type) {
    return this.endpoints ? _.filter(this.endpoints, endpoint => endpoint.type === type) : [];
  },

  _endpointsWithType(type) {
    return this.endpoints ? _.map(this._filterEndpointsByType(type), 'address') : [];
  },
});
