import _ from 'lodash';

import HttpUploader from './lib/http_uploader';
import MqttGateway from './lib/mqtt_gateway';
import Observable from 'scripts/lib/observable_mixin';

export default class UploadGateway {
  constructor(backend, requestorId) {
    this.fileGateway = new FileGateway(backend, requestorId);
    this.uploadAuthGateway = new UploadAuthGateway(backend, requestorId);
    this.uploadAuthGateway.addObserver(this);
    this.httpUploader = new HttpUploader(backend, this.notifyObservers.bind(this));
  }

  abort(path) {
    this.httpUploader.abort(path);
  }

  remove(agentId, path) {
    return this.fileGateway.remove(agentId, path);
  }

  upload(path, file, auth) {
    this.httpUploader.uploadFile(path, file, auth);
  }

  fetchUploadAuth(agentId, path, fileDescriptor) {
    return this.uploadAuthGateway.fetch(agentId, path, fileDescriptor);
  }

  handleFetchUploadAuthSuccess({ agentId, path, auth }) {
    this.notifyObservers('handleUploadAuth', { agentId, path, auth });
  }

  handleFetchUploadAuthError({ agentId, path, errors }) {
    this.notifyObservers('handleUploadAuthError', { agentId, path, errors });
  }

  init(opts) {
    this.fileGateway.init(opts);
    this.uploadAuthGateway.init(opts);
  }
}

_.extend(UploadGateway.prototype, Observable);

class FileGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'agents/+/uploads/+';
  }

  remove(agentId, path) {
    return this._delete([agentId, encodeURIComponent(path)]);
  }
}

class UploadAuthGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'agents/+/upload-auth/+';
  }

  fetch(agentId, path, fileDescriptor) {
    return super._fetch([agentId, encodeURIComponent(path)], fileDescriptor);
  }

  onFetchResponse(auth, topic, parsedTopicParams) {
    let uploadAuth = { auth, agentId: parsedTopicParams[0], path: decodeURIComponent(parsedTopicParams[1]) };
    this.notifyObservers('handleFetchUploadAuthSuccess', uploadAuth);
  }

  onFetchError({ errors }, topic, parsedTopicParams) {
    let result = { path: decodeURIComponent(parsedTopicParams[1]), errors, agentId: parsedTopicParams[0] };
    this.notifyObservers('handleFetchUploadAuthError', result);
  }
}
