import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import TextareaControlled from 'components/lib/textarea_controlled';
import UpdateContact from 'actions/customer_profile/update_contact';

export function CustomerAddressField({ isDisabled, isReadOnly, isResponsePending, onSubmit, value }) {
  return (
    <TextareaControlled
      className="customerProfile-address"
      disabled={isDisabled}
      onSubmit={onSubmit}
      pending={isResponsePending}
      placeholder="Address"
      readOnly={isReadOnly}
      value={value || ''}
    />
  );
}

CustomerAddressField.propTypes = {
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default connect(mapStateToProps, mapExecuteToProps)(CustomerAddressField);

function mapStateToProps({ getProvider }, { isReadOnly }) {
  let profileProvider = getProvider('profile');
  return {
    isDisabled: profileProvider.isLoading() || profileProvider.getErrors().length > 0,
    isReadOnly,
    isResponsePending: profileProvider.isPending(),
    value: _.get(profileProvider.get(), 'address'),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onSubmit: address => executeAction(UpdateContact, { address }),
  };
}
