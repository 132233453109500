import canHandlePhoneCalls from 'actions/agents/can_handle_phone_calls';

export default class AgentVoiceCapabilitiesChecker {
  constructor(context) {
    this.context = context;
  }

  canHandlePhoneCalls() {
    let currentAgent = this.context.stores.currentAgent.get();
    let appFeatures = this.context.stores.appFeatures.get();
    let connectionState = this.context.stores.connectionState.get();
    return canHandlePhoneCalls(currentAgent, connectionState, appFeatures.isEnabled.bind(appFeatures));
  }
}
