import _ from 'lodash';
import React from 'react';
import T from 'prop-types';

import connect from 'components/lib/connect';
import { H1 } from 'components/common/headers';
import TimezoneSelector from 'components/reporting/timezone_selector';
import { getUrl } from 'scripts/adapters/lib/url';
import { MenuContainer, MenuItem } from 'components/lib/menu_deprecated';
import DownloadIcon from 'components/reporting/icons/download_icon';
import TrackEvent from 'actions/analytics/track_event';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import { findReportConfigBySlug, isEmbeddedReport as getIsEmbeddedReport } from 'models/reports/report';
import { getAllReportConfigs } from 'actions/reporting/lib/reporting_helpers';

class ReportConfigHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDownloadButtonDisabled: false,
    };

    this.renderGlobalReportControls = this.renderGlobalReportControls.bind(this);
  }

  render() {
    const { config, isEmbeddedReport } = this.props;

    return (
      <div className="reportHeader">
        <div className="reportHeader-infoWithLink">
          <div className="reportHeader-titleWithlink">
            <H1>{config.title}</H1>
            <a className="reportHeader-help" href={getHelpDocUrlSlug(config.urlSlug)} rel="noreferrer" target="_blank">
              Read our guide for this report
            </a>
          </div>
          {!isEmbeddedReport ? this.renderGlobalReportControls() : null}
        </div>
      </div>
    );
  }

  renderGlobalReportControls() {
    return (
      <div className="reportHeader-info-secondaryElements">
        <TimezoneSelector />
        {this.renderDownloadButton()}
      </div>
    );
  }

  renderDownloadButton() {
    const { auth } = this.props;
    if (auth.isLoggedIn()) {
      // only render if we can determine their orgId
      let orgId = auth.getOrgId();
      let reportSlug = this.props.reportSlug;
      let filename = `${reportSlug}.csv`;
      let baseUrl = `/api/reporting/v1/orgs/${orgId}/reports/${filename}`;
      return this.renderDownloadMenu({ baseUrl, filename });
    }
  }

  renderDownloadMenu({ baseUrl, filename }) {
    const {
      isIvrReport,
      filterParams,
      currentTimezone,
      reportSlug,
      shouldLoadZeroState,
      showInfoToast,
      trackEvent,
    } = this.props;

    filterParams.timezone = currentTimezone;
    let dailyUrl = getUrl(baseUrl, _.omit(filterParams, 'aggregationLevel'));
    let halfHourlyUrl = getUrl(baseUrl, {
      ...filterParams,
      aggregationLevel: 'HALF_HOUR',
    });

    const isDefaultIvrReport = isIvrReport && !filterParams.endpointNumber;

    let isDownloadDisabled = isDefaultIvrReport || shouldLoadZeroState || this.state.isDownloadButtonDisabled;

    const menuClassName = isDefaultIvrReport
      ? 'reportHome-header-downloadMenu-disabled'
      : 'reportHome-header-downloadMenu';

    const buttonClassName = isDownloadDisabled
      ? 'reportHome-header-downloadMenu-button-content-disabled'
      : 'reportHome-header-downloadMenu-button-content';

    let reportParams = {
      slug: reportSlug,
      view: 'CSV',
      timezone: currentTimezone,
      ..._.omit(filterParams, 'aggregationLevel'),
    };

    const menuOptions = isIvrReport
      ? [{ name: 'Daily', url: dailyUrl, params: { ...reportParams, aggregationLevel: 'DAY' } }]
      : [
          {
            name: 'Half hourly',
            url: halfHourlyUrl,
            params: { ...reportParams, aggregationLevel: 'HALF_HOUR' },
          },
          { name: 'Daily', url: dailyUrl, params: { ...reportParams, aggregationLevel: 'DAY' } },
        ];
    let brieflyDisableDownloadButton = () => {
      showInfoToast();
      this.setState({ isDownloadButtonDisabled: true });
      setTimeout(() => {
        this.setState({ isDownloadButtonDisabled: false });
      }, 4000);
    };

    let disableAndTrack = arg => {
      trackEvent(arg);
      brieflyDisableDownloadButton();
    };

    disableAndTrack.bind(this);

    let menuItems = menuOptions.map((option, index) => (
      <a className="reportHome-header-downloadMenu-item" download={filename} href={option.url} key={index}>
        <ReportHomeMenuItemSelector
          className={`reportHome-header-downloadMenu-item-${_.camelCase(option.name)}`}
          name={option.name}
          onSelect={disableAndTrack}
          params={option.params}
        />
      </a>
    ));

    return (
      <MenuContainer
        button={
          <div className={buttonClassName}>
            <DownloadIcon />
            Download CSV
          </div>
        }
        className={menuClassName}
        isDisabled={isDownloadDisabled}
        ref="menu"
      >
        {menuItems}
      </MenuContainer>
    );
  }
}

class ReportHomeMenuItemSelector extends React.Component {
  constructor(props) {
    super(props);

    this.onSelect = this.props.onSelect.bind(this, {
      event: 'Report Requested',
      props: this.props.params,
    });
  }

  render() {
    return (
      <MenuItem className={this.props.className} onSelect={this.onSelect}>
        {this.props.name}
      </MenuItem>
    );
  }
}

// special case for IVR - all IVR urls should send the user to the same IVR help doc
function getHelpDocUrlSlug(urlSlug) {
  if (urlSlug.includes('ivr-')) {
    urlSlug = 'ivr';
  }

  return `https://connect.gladly.com/docs/help-documentation/article/${urlSlug}/`;
}

function mapStateToProps({ getProvider }) {
  const auth = getProvider('auth').get();
  const currentLocation = getProvider('currentLocation').get();
  const currentTimezone = currentLocation.getTimezone();
  const reportSlug = currentLocation.getSlug();

  const isEmbeddedReport = getIsEmbeddedReport(reportSlug);
  const isIvrReport = reportSlug === 'ivr-summary' || reportSlug === 'ivr-end-states';

  const sharedReportConfigs = getProvider('sharedReportConfigs').get().configs;
  const reportConfigs = _.concat(
    getAllReportConfigs({ stores: { reportConfigs: getProvider('reportConfigs') } }),
    sharedReportConfigs
  );
  const currentReportConfig = findReportConfigBySlug(reportConfigs, reportSlug);
  const isSharedReport = currentReportConfig.categoryId === 'Shared';
  const filterParams = !isSharedReport && currentLocation.getFilterParams(currentReportConfig.timeAggregation);

  return {
    auth,
    config: currentReportConfig,
    currentTimezone,
    filterParams,
    isEmbeddedReport,
    isIvrReport,
    reportSlug,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    trackEvent: arg => executeAction(TrackEvent, arg),
    showInfoToast: () =>
      executeAction(ShowToast, {
        type: ToastType.INFO,
        message: 'Hold tight - it may take a while for your CSV download to start.',
      }),
  };
}

const ReportConfigHeaderContainer = connect(mapStateToProps, mapExecuteToProps)(ReportConfigHeader);

ReportConfigHeaderContainer.propTypes = {
  shouldLoadZeroState: T.bool,
};

export default ReportConfigHeaderContainer;
