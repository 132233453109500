import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inlineDirectionSpacing } from 'components/space_helper';
import { IdGenerator } from 'factories/lib/uuid';

// Documentation: http://go.glad.ly/style/#/Common%20Components/Checkbox

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.checked };
    this._handleClick = this._handleClick.bind(this);
    this.checkbox = React.createRef();

    this._debouncedOnClick = _.debounce(this._debouncedOnClick, props.debounceInterval, {
      leading: true,
      trailing: false,
    });
  }

  UNSAFE_componentWillMount() {
    this.id = `checkbox_${IdGenerator.newId()}_${this.props['data-aid']}`;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== undefined) {
      this.setState({ checked: nextProps.checked });
    }
  }

  render() {
    let props = _.omit(this.props, ['checked', 'onChange', 'value']);
    return (
      <Label className={props.wrapperClassName} htmlFor={this.id} {...props}>
        <HiddenCheckbox
          checked={this.state.checked} // Because wrapped in label, this will be called when label is clicked
          id={this.id} // so React doesnt complain about the lack of a change handler
          onChange={_.noop}
          onClick={this._handleClick}
          {...props}
          ref={this.checkbox}
          value={this.props.value}
        />
        <Checkmark checked={this.state.checked} {...props}>
          <Icon size={this.props.size} viewBox="0 0 16 16">
            <path d="M14.68 2.26683C15.085 2.64241 15.1087 3.27512 14.7332 3.68004L6.38533 12.68C6.19143 12.8891 5.91751 13.0054 5.63243 12.9998C5.34736 12.9942 5.07824 12.8671 4.89273 12.6506L1.24056 8.38743C0.881248 7.96801 0.929977 7.33672 1.3494 6.97741C1.76882 6.6181 2.40011 6.66683 2.75942 7.08625L5.68181 10.4975L13.2668 2.31996C13.6424 1.91504 14.2751 1.89125 14.68 2.26683Z" />
          </Icon>
        </Checkmark>
        <StyledLabel>{this.props.label}</StyledLabel>
      </Label>
    );
  }

  _debouncedOnClick(ev) {
    this.toggle();
    if (this.props.onChange) {
      this.props.onChange(ev);
    }
    ev.preventDefault();
  }

  _handleClick(ev) {
    ev.persist();
    this._debouncedOnClick(ev);
  }

  toggle() {
    this.setState({ checked: !this.state.checked });
  }
}

Checkbox.propTypes = {
  'data-aid': PropTypes.string,
  /** Time (in milliseconds) to delay multiple click interactions (prevents repeated clicking) */
  debounceInterval: PropTypes.number,
  disabled: PropTypes.bool,
  /** Overall size of the checkbox */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** If the checkbox is marked as checked or not */
  checked: PropTypes.bool,
  /** Text to display to the right of the checkbox */
  label: PropTypes.any,
  /** Called when clicking on the checkbox changes the value of the `checked` property */
  onChange: PropTypes.func,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  size: 'small',
  checked: false,
  label: '',
  onChange: null,
  debounceInterval: 200,
};

export const StyledLabel = styled.span``;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  margin: -1px;
  position: absolute;
  width: 0;
  z-index: -1;
`;

const Icon = styled.svg`
  fill: ${p => p.theme.colors.white};
  stroke: none;
  line-height: 0;
  height: ${p => getSize(p)};
  width: ${p => getSize(p)};
`;

const Label = styled.label`
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  flex-basis: 0;
  align-items: center;
  &[disabled] {
    color: ${p => p.theme.colors.gray300};
    cursor: default;
  }
`;

export const Checkmark = styled.div.attrs({ inline: 'small', direction: 'right' })`
  background: ${p => (p.checked ? p.theme.colors.green400 : p.theme.colors.white)};
  border: 1px solid ${p => (p.checked ? p.theme.colors.green400 : p.theme.colors.gray300)};
  line-height: 0;
  padding: 0 1px;
  border-radius: 4px;
  display: flex;
  height: ${p => getSize(p)};
  width: ${p => getSize(p)};
  ${p => p.label && inlineDirectionSpacing(p)};
  ${Icon} {
    visibility: ${p => (p.checked ? 'visible' : 'hidden')};
  }

  &[disabled] {
    background: ${p => (p.checked ? p.theme.colors.gray200 : p.theme.colors.white)};
    border: 1px solid ${p => p.theme.colors.gray300};
    ${Icon} {
      fill: ${p => p.theme.colors.gray400};
    }
  }
`;

function getSize(props) {
  let size;
  switch (props.size) {
    case 'small':
      size = '16px';
      break;
    case 'medium':
      size = '24px';
      break;
    case 'large':
      size = '32px';
      break;
    default:
      size = '16px';
      break;
  }
  return size;
}

export default Checkbox;
