import styled, { css } from 'styled-components';
import { spaceFor } from 'components/space_helper';

const baseHeader = css`
  align-items: center;
  display: flex;
  font-weight: ${p => p.theme.fontWeight.heavy};
  justify-content: space-between;
  margin: 0;
  flex-shrink: 0;
  line-height: 1;
  margin-bottom: ${p => spaceFor('stack', p)};
`;

export const H1 = styled.h1`
  ${baseHeader};
  font-size: 24px;
`;

export const H2 = styled.h2`
  ${baseHeader};
  font-size: 20px;
`;

export const H3 = styled.h3`
  ${baseHeader};
  font-size: 16px;
`;

export const H4 = styled.h4`
  ${baseHeader};
  font-size: 14px;
  font-weight: ${p => p.theme.fontWeight.medium};
`;

export const H5 = styled.h5`
  ${baseHeader} font-size: 11px;
  ${p => p.theme.fontWeight.medium};
`;
