import PropTypes from 'prop-types';
import React from 'react';

import ConversationItem from 'models/conversation_item';
import { iconClassNameForItem } from '../metadata/metadata_icon_classes';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import { TaskLink } from '../task/task_routing_item';

export default function TaskDueItem({ item }) {
  return (
    <ItemContent isAuditItem item={item}>
      <Metadata iconClass={iconClassNameForItem(item)} text={renderText()} timestamp={item.timestamp} />
    </ItemContent>
  );

  function renderText() {
    return (
      <span className="taskDueItem">
        <TaskLink className="taskDueItem-link" taskItemId={item.content.taskItemId} /> became due
      </span>
    );
  }
}

TaskDueItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
