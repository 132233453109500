import _ from 'lodash';
import ChatCompositionContent from 'models/composition/chat_composition_content';
import CustomerView from 'models/location/customer_view';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import GreetingSuggestion from 'models/greeting_suggestion';
import TrackEvent from 'actions/analytics/track_event';

export default class TrackPhraseSuggestionShown {
  constructor(context) {
    this.context = context;
  }

  run({ charactersMatched, phraseSuggestion, compositionId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    // TODO: handle other locations
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }
    let location = currentLocation.toJs().name;

    let composition = getCompositionsStore(this.context).findBy({ id: compositionId });
    let contentType = composition.toJs().content.type;
    let props = {
      compositionId,
      contentType,
      conversationId: composition.conversationId,
      customerId: composition.customerId,
      location,
    };

    if (phraseSuggestion instanceof GreetingSuggestion) {
      this.context.executeAction(TrackEvent, {
        event: 'Greeting Suggestion Shown',
        props: {
          greetingSuggestionScore: phraseSuggestion.score,
          greetingSuggestionId: phraseSuggestion.id,
          greetingSuggestionText: phraseSuggestion.text,
          ...props,
        },
      });
      return;
    }

    if (composition.content instanceof ChatCompositionContent) {
      props = {
        chatSessionId: _.get(composition, 'content.sessionId'),
        chatSessionItemId: _.get(composition, 'content.sessionItemId'),
        ...props,
      };
    }

    this.context.executeAction(TrackEvent, {
      event: 'Phrase Suggestion - Shown',
      props: {
        phraseSuggestionId: phraseSuggestion.id,
        countCharactersMatched: charactersMatched.length,
        ...props,
      },
    });
  }
}
