import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import CompositionButtons, { CancelButton } from './composition_buttons';
import createCompositionForm, { getFormattedPhoneNumbersWithTooltip } from './lib/create_composition_form';
import Dropdown from 'components/lib/dropdown';
import ErrorTooltip from 'components/lib/error_tooltip';
import {
  getValidationErrors as getPhoneValidationErrors,
  normalizePhoneNumber,
  normalizePhoneNumberWithoutValidation,
} from 'models/phone_number';
import InitiatePhoneCall from 'actions/phone_call/initiate_phone_call';
import createInvalidCompanyNumberError from 'scripts/domain/factories/error/create_invalid_company_number_error';
import PhoneCallCompositionContent from 'models/composition/phone_call_composition_content';
import SendButton from './send_button';
import Tooltip from 'components/common/tooltip';

const PhoneCallCompositionForm = createCompositionForm({
  propTypes: {
    companyNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
  },

  getInitialState() {
    const { companyNumber, customerNumber } = this.props.composition.content;
    return { companyNumber: companyNumber || '', customerNumber: customerNumber || '' };
  },

  getCompositionContent() {
    return new PhoneCallCompositionContent({
      customerNumber: this.state.customerNumber,
      companyNumber: this.state.companyNumber,
    });
  },

  loadComposition(composition) {
    if (composition.content) {
      const { companyNumber, customerNumber } = composition.content;
      this.setState({ companyNumber: companyNumber || '', customerNumber: customerNumber || '' });
    }
  },

  /* Handlers */

  onFromChange(companyNumberOption) {
    this.setState({
      companyNumber: companyNumberOption.value,
    });
  },

  onSubmit() {
    const { companyNumber, customerNumber } = this.state;
    if (!this.getCompanyPhoneErrors(companyNumber) && _.isEmpty(this.getPhoneErrors(customerNumber))) {
      this.executeAction(InitiatePhoneCall, {
        compositionId: this.props.composition.id,
        content: {
          companyNumber: normalizePhoneNumberWithoutValidation(companyNumber),
          customerNumber: normalizePhoneNumber(customerNumber),
        },
      });
    }
  },

  /* Render */

  render() {
    const { companyNumber, customerNumber } = this.state;
    const { fromOptions, tooltipMessage } = getFormattedPhoneNumbersWithTooltip(
      this.props.companyNumbers,
      companyNumber
    );

    return (
      <div className="phoneCallCompositionForm compositionForm">
        <div className="compositionForm-phoneInput-container compositionForm-formBody">
          <div className="phoneCallCompositionForm-header">Phone call</div>
          <div className="compositionForm-phoneInput">
            <div className="compositionForm-phoneLabel">From</div>
            <div className="phoneCallCompositionForm-from-container">
              <Tooltip
                className="phoneCallCompositionForm-from-dropdown"
                delay={250}
                message={tooltipMessage}
                position="top"
              >
                <Dropdown
                  onChange={this.onFromChange}
                  options={fromOptions}
                  placeholder=""
                  ref="fromAddress"
                  searchable
                  value={companyNumber}
                />
              </Tooltip>
            </div>
          </div>
          <div className="compositionForm-phoneInput">
            <div className="compositionForm-phoneLabel">To</div>
            <ErrorTooltip
              className="compositionForm-phoneNumber-errorChecker"
              errors={this.getPhoneErrors(customerNumber)}
            >
              <dd>
                <input
                  className="editableInput editableField customerProfile-contactInput compositionForm-phoneNumber phoneCallCompositionForm-customerNumber"
                  onChange={e => this.setState({ customerNumber: e.target.value })}
                  placeholder="customer number"
                  value={customerNumber}
                />
              </dd>
            </ErrorTooltip>
          </div>
        </div>
        <CompositionButtons>
          <CancelButton onClick={this.onCancel} />
          <SendButton disabled={this.props.isMinimized} onClick={this.onSubmit}>
            Call
          </SendButton>
        </CompositionButtons>
      </div>
    );
  },

  getCompanyPhoneErrors(companyNumber) {
    if (!_.find(this.props.companyNumbers, { number: companyNumber })) {
      let error = createInvalidCompanyNumberError(companyNumber);
      return { phone: error.detail };
    }
    return null;
  },

  getPhoneErrors(phoneNumber) {
    return getPhoneValidationErrors({ phoneNumber }).map(err => err.detail);
  },
});

export default PhoneCallCompositionForm;
