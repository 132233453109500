import { Factory } from 'rosie';
import '../conversation_item_factory';
import { fbMessageId } from '../lib/fb_message_id';

import configureIdGenerator from '../lib/configure_id_generator';

let IdGenerator = configureIdGenerator();

Factory.define('FbMessageOutgoing')
  .attr('type', 'FB_MESSAGE_OUTGOING');

Factory.define('FbMessageRequest');

Factory.define('FbMessageRequestWithText')
  .extend('FbMessageRequest')
  .attr('recipient', () => Factory.attributes('FbMessengerUserWithDefaults'))
  .attr('sender', () => Factory.attributes('FbPageWithDefaults'))
  .attr('message', () => Factory.attributes('FbMessageSentWithText'));

Factory.define('FbMessageRequestWithDefaults')
  .extend('FbMessageRequestWithText');

Factory.define('FbMessageResponse');

Factory.define('FbMessageResponseWithDefaults')
  .extend('FbMessageResponse');

Factory.define('FbMessageResponseSent')
  .extend('FbMessageResponse')
  .attr('status', 'SENT');

Factory.define('FbMessageOutgoingWithText')
  .extend('FbMessageOutgoing')
  .attr('request', () =>  Factory.attributes('FbMessageRequestWithDefaults'));

Factory.define('FbMessageOutgoingWithDefaults')
  .extend('FbMessageOutgoingWithText');

Factory.define('FbMessageOutgoingItem')
  .extend('ConversationItem')
  .attr('content', () => Factory.attributes('FbMessageOutgoing'));

Factory.define('FbMessageOutgoingItemWithText')
  .extend('ConversationItemWithDefaults')
  .attr('initiator', {
    type: 'AGENT',
    id: IdGenerator.newId()
  })
  .attr('content', () => Factory.attributes('FbMessageOutgoingWithText'));

Factory.define('FbMessageOutgoingItemWithDefaults')
  .extend('FbMessageOutgoingItemWithText');

/**
* Factory for `message` attribute
*/
Factory.define('FbMessageSent');

Factory.define('FbMessageSentWithText')
  .extend('FbMessageSent')
  .attr('text', 'hello, world');

Factory.define('FbPage');

Factory.define('FbPageWithDefaults')
  .extend('FbPage')
  .attr('id', () => fbMessageId(12));

Factory.define('FbMessengerUser');

Factory.define('FbMessengerUserWithDefaults')
  .extend('FbMessengerUser')
  .attr('id', () => fbMessageId(16));

