import { Factory } from 'rosie';
import './fb_page_account_content_factory';
import './fb_page_account_secret_content_factory';

Factory.define('Account');

Factory.define('AccountWithDefaults')
  .extend('Account')
  .attr('id', 'some-account-id')
  .attr('type', 'FB_MESSENGER')
  .attr('content', Factory.attributes('FbPageAccountContentWithDefaults'))
  .attr('secret', Factory.attributes('FbPageAccountSecretContentWithDefaults'))
  .attr('description', 'some-description');
