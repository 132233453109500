import Assignee from 'models/assignee';
import Conversation from 'models/conversation';
import SwitchCurrentConversation from 'actions/customer/switch_current_conversation';
import UpdateCurrentComposition from 'actions/composition/update_current_composition';
import ConvDtoConverter from 'scripts/application/dto_converters/conversation_converter';
import { getDefaultRoutingGroupIdForAgent } from 'actions/conversation/lib/get_default_routing_groupId_for_agent';

export default class CreateConversation {
  constructor(context) {
    this.context = context;
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }

  run() {
    let conversationStore = this.context.stores.customers.storesFor(this.currentCustomerId).conversations;

    let agentId = this.currentAgentId;
    let routingGroupId = getDefaultRoutingGroupIdForAgent(this.context);

    let conversation = Conversation.create({
      assignee: new Assignee({ routingGroupId, agentId }),
      status: Conversation.Status.OPEN,
      conversationItems: [],
    });

    conversationStore.setPendingNew(conversation);
    this.context.gateways.customerHistory.addConversation(this.currentCustomerId, ConvDtoConverter.toDto(conversation));

    let conversationId = conversation.id;
    this.context.executeAction(SwitchCurrentConversation, { conversationId });
    this.context.executeAction(UpdateCurrentComposition, conversationId);
  }
}
