import ActiveCallPhoneNumber from './active_call_phone_number';
import connect from 'components/lib/connect';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';

let mapStateToProps = function(context, props) {
  return {
    conversationId: props.conversationId,
    customerId: props.customerId,
    currentLocation: context.getProvider('currentLocation').get(),
    isCustomerUnverified: props.isCustomerUnverified,
    phoneNumber: props.phoneNumber,
  };
};

let mapExecuteToProps = function(executeAction) {
  return {
    onBackToConversationClick: ({ customerId, conversationId }) =>
      executeAction(NavigateToConversation, { customerId, conversationId }),
  };
};

export default connect(mapStateToProps, mapExecuteToProps)(ActiveCallPhoneNumber);
