import { useContext, useEffect } from 'react';

import HotkeyContext from 'components/contexts/hotkeys';
import Mousetrap from 'components/lib/mousetrap';

export default function useKeyboardShortcut(hotkey, callback) {
  const { dispatchHotkey } = useContext(HotkeyContext);
  const { key, group, label } = hotkey || {};

  useEffect(() => {
    if (!key) return;

    dispatchHotkey({ type: 'add', hotkey: { key, group, label } });
    return () => {
      dispatchHotkey({ type: 'remove', key });
    };
  }, []);

  useEffect(() => {
    if (!key) return;

    Mousetrap.bind(key, () => {
      callback();
      return false;
    });

    return () => {
      Mousetrap.unbind(key);
    };
  }, [callback]);
}
