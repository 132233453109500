import NoopOnReconnectGateway from './noop_on_reconnect_gateway';

export default class ExternalDataStoreGateway extends NoopOnReconnectGateway {
  constructor(backend) {
    super(backend, {
      broadcastDeleteTopic: 'v1/orgs/:orgId/external-data/gladly-entity/:gladlyEntityType/:gladlyEntityId/event/delete',
      fetchUrl: '/api/v2/orgs/:orgId/external-data',
    });
  }
}
