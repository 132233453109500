import createModel, { prop } from '../lib/create_model';

const AgentMentionedInTaskComment = createModel({
  modelName: 'AgentMentonedInTaskComment',

  properties: {
    agentId: prop(String).isRequired,
    customerId: prop(String).isRequired,
    taskCommentId: prop(String).isRequired,
    taskId: prop(String).isRequired,
  },
});

export default AgentMentionedInTaskComment;
