import UserCredential from 'models/user_credential';
import UserCredentialType from 'models/user_credential_type';
import UserLogin from 'models/location/user_login';

export default class BeginLogIn {
  constructor(context) {
    this.context = context;
  }

  run({ email, password }) {
    if (this.context.stores.appFeatures.get().isEnabled('sso')) {
      this.context.router.navigateHome();
      return;
    }

    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof UserLogin)) {
      return;
    }

    let validationErrors = UserCredential.getValidationErrors(UserCredentialType.PASSWORD, {
      username: email,
      password,
    });

    if (validationErrors.length) {
      this.context.stores.userCredential.setErrors(validationErrors);
    } else {
      this.context.stores.userCredential.setPending(UserCredential.create({ type: UserCredentialType.PASSWORD }));
      this.context.gateways.authentication.login({ username: email, password: password.trim() });

      currentLocation.setUsername(email);
      this.context.stores.currentLocation.set(currentLocation);
    }
  }
}
