import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0)" fill="#919191">
      <path d="M3.94 13.91a.2.2 0 00.167.09h3.52a.2.2 0 00.166-.31L4.074 8.11a.2.2 0 010-.22l3.719-5.58A.2.2 0 007.626 2H4.107a.2.2 0 00-.166.09L.074 7.89a.2.2 0 000 .22l3.867 5.8zM11.94 13.91a.2.2 0 00.167.09h3.52a.2.2 0 00.166-.31l-3.719-5.58a.2.2 0 010-.22l3.719-5.58a.2.2 0 00-.167-.31h-3.519a.2.2 0 00-.166.09l-3.867 5.8a.2.2 0 000 .22l3.867 5.8z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h16v16H0V0z" fill="#fff" />
      </clipPath>
    </defs>
  </Icon>
);
