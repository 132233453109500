import MqttGateway from './lib/mqtt_gateway';

export default class PhoneGatewayBase extends MqttGateway {
  registerAgent(agentId) {
    throw new Error('registerAgent function is not implemented');
  }

  deregisterAgent(agentId) {
    throw new Error('deregisterAgent function is not implemented');
  }

  call(customerId) {
    throw new Error('call function is not implemented');
  }

  accept(customerId) {
    throw new Error('accept function is not implemented');
  }

  decline() {
    throw new Error('decline function is not implemented');
  }

  hangup() {
    throw new Error('hangup function is not implemented');
  }

  sendDigits(digits) {
    throw new Error('sendDigits function is not implemented');
  }

  getConnectionStatus() {
    throw new Error('getConnectionStatus function is not implemented');
  }
}
