import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Information Fill
 */

export default function InformationFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9 3V4.98319H7V3H9ZM7 13V7H9V13H7Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
