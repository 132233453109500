import classnames from 'classnames';
import React from 'react';

export default function CommunicationError({ className, message, onClick, helpDocHref }) {
  if (helpDocHref) {
    const helpDocDetail = ` For more information, please visit our `;
    return (
      <span className={classnames('communicationError', className)} onClick={onClick}>
        <span className="communicationError-message">
          {message}
          {helpDocDetail}
          <a className="communicationError-helpDoc-link" href={helpDocHref} rel="noreferrer" target="_blank">
            help docs
          </a>
        </span>
      </span>
    );
  }

  return (
    <span className={classnames('communicationError', className)} onClick={onClick}>
      <span className="communicationError-message">{message}</span>
    </span>
  );
}
