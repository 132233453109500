import changeComposition from 'actions/composition/lib/change_composition';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import updateComposition from 'actions/composition/lib/update_composition';
import CreateCustomChannelComposition from './create_custom_channel_composition';
import ActivateComposition from './activate_composition';

export default class ReplyToGroupMessage {
  constructor(context) {
    this.context = context;
  }

  run({ item }) {
    let { customerId, content: itemContent, conversationId } = item;

    const compositionsStore = getCompositionsStore(this.context);
    if (!compositionsStore) {
      return;
    }

    const composition = compositionsStore.findBy({
      filter: c => c.customerId === customerId && c.content.customChannelId === itemContent.customChannelId,
    });

    if (!composition) {
      this.context.executeAction(CreateCustomChannelComposition, {
        conversationId,
        customChannelId: itemContent.customChannelId,
        companyAddress: itemContent.companyAddress,
        customerAddress: itemContent.customerAddress,
        groupId: itemContent.groupId,
        groupName: itemContent.groupName,
      });

      return;
    }

    const compositionContent = composition.content;
    const Constructor = compositionContent.constructor;

    const newCompositionContent = new Constructor({
      ...compositionContent,
      companyAddress: itemContent.companyAddress,
      customerAddress: itemContent.customerAddress,
      groupId: itemContent.groupId,
      groupName: itemContent.groupName,
    });

    updateComposition(this.context, composition, newCompositionContent);
    changeComposition(this.context, composition);

    this.context.executeAction(ActivateComposition, { composition, toggleMode: false });
  }
}
