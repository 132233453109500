import _ from 'lodash';

export default class AnswerSuggestionService {
  constructor(pubsub, getDatabase) {
    this.pubsub = pubsub;
    this.getDatabase = getDatabase;

    this.fetch = this.fetch.bind(this);
  }

  fetch(requestorId, orgId, customerId, conversationId, envelope) {
    let { contentType, content, compositionId } = envelope.payload;
    let filteredSuggestions = _.find(this.getSuggestions(orgId), suggestion => {
      let isCorrectType = suggestion.contentType === contentType;
      let hasContent = content.toLowerCase().indexOf(suggestion.keyword) > -1;
      return isCorrectType && hasContent;
    });

    return {
      compositionId: compositionId || conversationId,
      suggestions: filteredSuggestions ? this.fetchSuggestedSnippets(orgId, filteredSuggestions.snippetIds) : [],
      version: 'an-answer-suggestions-model-version',
    };
  }

  getSuggestions(orgId) {
    return this.getDatabase(orgId).suggestions || [];
  }

  fetchSuggestedSnippets(orgId, snippetIds) {
    return _.filter(this.getDatabase(orgId).snippets, snippet => {
      return _.findIndex(snippetIds, item => item.id === snippet.id) !== -1;
    });
  }
}
