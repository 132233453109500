import _ from 'lodash';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import connect from 'components/lib/connect';
import DropdownMenu, { OpenerButton, StyledMenuItem } from 'components/common/dropdown_menu';
import Err from 'models/err';
import { H5 } from 'components/common/headers';
import { Languages } from 'models/answers/snippet_channels';

const AVAILABLE_LANGUAGES = _.sortBy(
  _.map(Languages, (value, key) => ({ code: key, label: value })),
  'label'
);

export function AnswerEditorLanguageSelector({ contents, errors, language, languages, setLanguage }) {
  const options = getLanguageOptions({
    contents,
    errors,
    languages,
  });

  return (
    <StyledDropdownMenu hasError={errors.length > 0}>
      <Header>View Answer In</Header>
      <DropdownMenu
        data-aid="answerEditorLanguageSelector"
        maxHeight={275}
        onSelect={setLanguage}
        options={options}
        text={Languages[language]}
        value={language}
      />
    </StyledDropdownMenu>
  );
}

AnswerEditorLanguageSelector.propTypes = {
  languages: PropTypes.array,
  contents: PropTypes.array.isRequired,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

function getLanguageOptions({ contents, errors, languages }) {
  const options = [];
  const existingSet = new Set();
  const existingLanguages = _.map(
    _.sortBy(contents, c => Languages[c.language]),
    c => c.language
  );

  options.push({
    label: 'View',
    type: 'HEADER',
  });
  existingLanguages.forEach(code => {
    const hasError = !!_.find(errors, err => err.attr && err.attr.split('.')[0] === code);
    options.push({
      label: hasError ? (
        <ErrorLabel>
          <LabelText>{Languages[code]}</LabelText> <FontAwesomeIcon icon={faExclamationTriangle} />
        </ErrorLabel>
      ) : (
        Languages[code]
      ),
      value: code,
    });
    existingSet.add(code);
  });

  const unusedLanguages = _.filter(languages ? languages : AVAILABLE_LANGUAGES, ({ code }) => !existingSet.has(code));
  if (unusedLanguages.length) {
    options.push({
      label: 'Add',
      type: 'HEADER',
    });
    unusedLanguages.forEach(({ code, label }) => {
      options.push({
        label: (
          <AddLanguage>
            {label} <PlusIcon className="icon icon-plus" />
          </AddLanguage>
        ),
        value: code,
      });
    });
  }

  return options;
}

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const configuredLanguagesProvider = getProvider('languages');
  // only languages an org has configured to use (subset of above available languages)
  let languages = _.sortBy(
    configuredLanguagesProvider.findAll().map(({ code }) => ({ code, label: Languages[code] })),
    'label'
  );
  return { languages };
}

const AnswerEditorLanguageSelectorContainer = connect(mapStateToProps)(AnswerEditorLanguageSelector);

const StyledDropdownMenu = styled.div`
  ${OpenerButton} {
    justify-content: space-between;
    margin-top: 8px;
    width: 100%;
    ${p => p.hasError && openerButtonError};
  }
`;

const openerButtonError = css`
  border-color: ${p => p.theme.colors.red400};
`;

const ErrorLabel = styled.div`
  align-items: center;
  display: flex;

  svg {
    color: ${p => p.theme.colors.red400};
    font-size: 16px;
  }
`;

const LabelText = styled.span`
  margin-right: 8px;
`;

const PlusIcon = styled.i.attrs({ className: 'icon icon-plus' })`
  align-items: center;
  color: ${p => p.theme.colors.green500};
  display: flex;
  font-size: 12px;
  margin-left: 16px;
  visibility: hidden;

  ${StyledMenuItem}:hover & {
    visibility: visible;
  }
`;

const AddLanguage = styled.span`
  display: flex;
  justify-content: space-between;
`;

const Header = styled(H5)`
  margin-bottom: 0px;
  margin-top: 8px;
`;

export default AnswerEditorLanguageSelectorContainer;
