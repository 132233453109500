import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Participants Fill
 */
export default function ParticipantsFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M5.498 8.498a2.757 2.757 0 002.764-2.752 2.757 2.757 0 00-2.764-2.75 2.757 2.757 0 00-2.763 2.75 2.757 2.757 0 002.763 2.752zM10.513 14c.276 0 .515-.225.48-.499-.3-2.298-2.646-4.086-5.495-4.086-2.85 0-5.195 1.788-5.494 4.086-.036.274.196.499.472.499H10.513z"
        fillRule="evenodd"
      />
      <path d="M13.764 4.25a2.258 2.258 0 01-2.266 2.25 2.258 2.258 0 01-2.266-2.25A2.258 2.258 0 0111.498 2a2.258 2.258 0 012.266 2.25z" />
      <path
        clipRule="evenodd"
        d="M15.522 11c.276 0 .513-.226.469-.498-.294-1.836-2.194-3.252-4.493-3.252-1.698 0-3.178.773-3.955 1.917 1.32.292 2.46.949 3.258 1.833h4.721z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
