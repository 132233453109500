import { Factory } from 'rosie';

Factory.define('SecureDataRequestItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function (content) {
    return Factory.attributes('SecureDataRequestWithDefaults', content);
  })
  .attr('initiator', {
    type: 'AGENT',
  });

Factory.define('SecureDataRequest')
  .attr('type', 'SECURE_DATA_REQUEST');

Factory.define('SecureDataRequestStatusEvent');

Factory.define('SecureDataRequestStatusEventWithDefaults')
  .extend('SecureDataRequestStatusEvent')
  .attr('requestStatus', 'REQUESTING')
  .attr('messageStatus', () => Factory.attributes('SecureDataRequestStatusMessageStatus'));


Factory.define('SecureDataRequestMessageStatus')
  .attr('value', 'SENT');

Factory.define('SecureDataRequestWithDefaults')
  .extend('SecureDataRequest')
  .attr('secureDataType', 'CREDIT_CARD')
  .attr('requestStatusHistory', () => [])
  .attr('metadata', () => Factory.attributes('SecureDataRequestMetadataWithDefaults'));

Factory.define('SecureDataRequestMetadataWithDefaults')
  .attr('monetaryUnit', 12345)
  .attr('memo', 'Snake skin boots');
