import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { getAssignee, renderAvatarOrInboxIcon } from './current_assignee';
import Tooltip from 'components/common/tooltip';

export default function ConversationAssignee({
  conversationAssignee,
  className,
  customerAssignee,
  onClick,
  textLabel,
}) {
  if (_.isEmpty(conversationAssignee)) {
    return null;
  }

  const hasCustomerAssignee = !_.isEmpty(customerAssignee);
  const customerAssigneeIconWrapperClassName = classnames({ customerAssignee: hasCustomerAssignee });
  const isAssigneeCustomerAssignee = hasSameConversationAndCustomerAssignee();
  const conversationAssigneeIconWrapperClassName = classnames('conversationAssignee', {
    customerAssignee: isAssigneeCustomerAssignee,
  });
  const conversationAssigneeClassName = classnames(conversationAssigneeIconWrapperClassName, className);
  const customerAssigneeClassName = classnames(customerAssigneeIconWrapperClassName, className);

  const hideCustomerAssignee = isAssigneeCustomerAssignee || !hasCustomerAssignee;

  return (
    <div className={classnames({ customerAssigneeIcon: !hideCustomerAssignee }, className)} onClick={onClick}>
      {renderCustomerAssigneeIcon()}
      {renderConversationAssigneeIcon()}

      <div className={`${className}-text`}>
        <div className={`${className}-text-label`}>
          {isAssigneeCustomerAssignee ? getCustomerAssigneeText() : textLabel}
        </div>
        <div className={`${className}-text-name`}>
          {getAssignee({
            agentName: conversationAssignee.agentName,
            groupName: conversationAssignee.groupName,
            isAssigneeCurrentAgent: conversationAssignee.isAssigneeCurrentAgent,
          })}
        </div>
      </div>
    </div>
  );

  function renderCustomerAssigneeIcon() {
    if (hideCustomerAssignee) {
      return null;
    }

    const { agentName, avatarImage, groupName } = customerAssignee;
    const className = 'customerAssignee';
    const children = renderAvatarOrInboxIcon(
      groupName,
      agentName,
      avatarImage,
      customerAssigneeClassName,
      customerAssigneeIconWrapperClassName
    );

    return (
      <Tooltip
        bounds={{ left: 8 }}
        className={className}
        message={getTooltipMessage({ agentName, groupName, label: getCustomerAssigneeText() })}
        position="top"
      >
        {children}
      </Tooltip>
    );
  }

  function renderConversationAssigneeIcon() {
    const { agentName, groupName, avatarImage } = conversationAssignee;
    const className = 'conversationAssignee';
    const tooltipMessage = isAssigneeCustomerAssignee
      ? getTooltipMessage({ agentName, groupName, label: getCustomerAssigneeText() })
      : getTooltipMessage({ agentName, groupName, label: 'Current Assignee' });

    const children = renderAvatarOrInboxIcon(
      groupName,
      agentName,
      avatarImage,
      conversationAssigneeClassName,
      conversationAssigneeIconWrapperClassName
    );

    return (
      <Tooltip
        bounds={{ left: 8 }}
        className={classnames(className, { partialConversationAssigneeIcon: !hideCustomerAssignee })}
        message={tooltipMessage}
        position="top"
      >
        {children}
      </Tooltip>
    );
  }

  function getTooltipMessage({ agentName, groupName, label }) {
    return (
      <div className="conversationAssignee-tooltip">
        <div className="conversationAssignee-tooltip-label">{label}</div>
        <div className="conversationAssignee-tooltip-text">{agentName ? `${agentName} (${groupName})` : groupName}</div>
      </div>
    );
  }

  function getCustomerAssigneeText() {
    return customerAssignee.agentName ? 'Dedicated Hero' : 'Hero Inbox';
  }

  function hasSameConversationAndCustomerAssignee() {
    customerAssignee = customerAssignee || {};
    return (
      customerAssignee.agentId === conversationAssignee.agentId &&
      customerAssignee.groupId === conversationAssignee.groupId
    );
  }
}

ConversationAssignee.propTypes = {
  className: PropTypes.string,
  conversationAssignee: PropTypes.shape({
    agentId: PropTypes.string,
    agentName: PropTypes.string,
    avatarImage: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    isAssigneeCurrentAgent: PropTypes.bool,
  }),
  customerAssignee: PropTypes.shape({
    agentId: PropTypes.string,
    agentName: PropTypes.string,
    avatarImage: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  textLabel: PropTypes.string,
};
