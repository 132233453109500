import React from 'react';
import styled from 'styled-components';

import { getMarginFromIndent } from 'components/customer/composition/lib/slate/slate_text_indentation_menu';
import { isList, getNodeOfType } from 'components/text_editor/plugins/lists/utils';
import { LIST_ITEM } from 'components/text_editor/plugins/lists/constants';

export const QUOTEBLOCK = 'quoteblock';

export const StyledQuoteBlock = styled.blockquote`
  border-left: 4px solid ${p => p.theme.colors.gray400};
  margin: 0;
  padding-left: ${p => p.theme.spacing.medium};
`;

export default function QuoteBlocksPlugin(options) {
  return {
    commands: { toggleQuote },
    onKeyDown: (evt, editor, next) => {
      if (evt.key === 'Enter' && !evt.shiftKey && isQuoted(editor.value) && !isList(editor.value)) {
        // if only `Enter` pressed, exit the quote block
        if (isQuotedNodeEmpty(editor.value)) {
          editor.toggleQuote();
          return next();
        }
        // if current quote block is not empty, exit the quote block and insert a new paragraph
        return editor.insertBlock({ type: 'paragraph' }).unwrapBlock(QUOTEBLOCK);
      }

      if (evt.key === 'Backspace' && isQuoted(editor.value) && isQuotedNodeEmpty(editor.value)) {
        editor.toggleQuote();
        return next();
      }
      return next();
    },

    renderNode(props, editor, next) {
      if (props.node.type === QUOTEBLOCK) {
        const { attributes, children } = props;
        const indent = props.node.get('data').get('indent');
        const marginLeft = getMarginFromIndent(indent);
        return (
          <StyledQuoteBlock {...attributes} style={{ marginLeft }}>
            {children}
          </StyledQuoteBlock>
        );
      }
      return next();
    },

    schema: {
      blocks: {
        [QUOTEBLOCK]: {
          nodes: [{ objects: ['block'] }],
        },
      },
    },
  };
}

export function isQuoted(value) {
  return value.blocks.some(block => {
    return !!value.document.getClosest(block.key, parent => parent.type === QUOTEBLOCK);
  });
}

export function isQuotedNodeEmpty(value) {
  return value.blocks.some(block => {
    return !!value.document.getClosest(block.key, parent => parent.type === QUOTEBLOCK && !parent.text);
  });
}

export function toggleQuote(editor) {
  if (isList(editor.value)) {
    const itemNode = getNodeOfType(editor.value, LIST_ITEM);
    const parentList = editor.value.document.getParent(itemNode.key);
    if (isQuoted(editor.value)) {
      editor.unwrapBlockByKey(parentList.key, QUOTEBLOCK);
    } else {
      editor.wrapBlockByKey(parentList.key, { type: QUOTEBLOCK });
    }
    return editor;
  }

  if (isQuoted(editor.value)) {
    editor.unwrapBlock(QUOTEBLOCK);
  } else {
    editor.wrapBlock({ type: QUOTEBLOCK });
  }
  return editor;
}
