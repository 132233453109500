import _ from 'lodash';
import httpStatus from 'http-status';

import { after } from 'scripts/infrastructure/backends/http_client';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import Observable from 'scripts/lib/observable_mixin';
import PhoneControlsActions from 'models/phone_controls_actions';

export default class PhoneControlsHttpGateway {
  constructor(http, requestorId) {
    this.http = http;
    this.requestorId = requestorId;
  }

  init({ orgId }) {
    this.orgId = orgId;
  }

  get _httpClient() {
    return this.http.axios();
  }

  get version() {
    return 1;
  }

  url(customerId, itemId, action) {
    let url = `/api/v${this.version}/orgs/${this.orgId}/customer/${customerId}/conversation-items/${itemId}/phone-controls`;
    switch (action) {
      case PhoneControlsActions.ADD:
        url += '/add-participant';
        return url;
      case PhoneControlsActions.ADD_OBSERVER:
        url += '/add-observer';
        return url;
      case PhoneControlsActions.BLIND_TRANSFER:
        url += '/blind-transfer';
        return url;
      case PhoneControlsActions.RECORD_QUALITY_EVENT:
        url += '/call-quality-event';
        return url;
      case PhoneControlsActions.CANCEL_WARM_TRANSFER:
        url += '/cancel-warm-transfer';
        return url;
      case PhoneControlsActions.COMPLETE_WARM_TRANSFER:
        url += '/complete-warm-transfer';
        return url;
      case PhoneControlsActions.END_CALL:
        url += '/end-call';
        return url;
      case PhoneControlsActions.END_CONFERENCE:
        url += '/end-conference';
        return url;
      case PhoneControlsActions.HOLD:
        url += '/hold-participant';
        return url;
      case PhoneControlsActions.INITIATE_WARM_TRANSFER:
        url += '/initiate-warm-transfer';
        return url;
      case PhoneControlsActions.MUTE:
        url += '/mute-participant';
        return url;
      case PhoneControlsActions.OBSERVER_ACCEPT_TRANSFER:
        url += '/observer-accept-transfer';
        return url;
      case PhoneControlsActions.OBSERVER_COACH:
        url += '/observer-coach';
        return url;
      case PhoneControlsActions.OBSERVER_DECLINE_TRANSFER:
        url += '/observer-decline-transfer';
        return url;
      case PhoneControlsActions.OBSERVER_HIDE:
        url += '/observer-hide';
        return url;
      case PhoneControlsActions.OBSERVER_JOIN:
        url += '/observer-join';
        return url;
      case PhoneControlsActions.OBSERVER_LISTEN:
        url += '/observer-listen';
        return url;
      case PhoneControlsActions.OBSERVER_SHOW:
        url += '/observer-show';
        return url;
      case PhoneControlsActions.PAUSE_RECORDING:
        url += '/pause-recording';
        return url;
      case PhoneControlsActions.REMOVE:
        url += '/remove-participant';
        return url;
      case PhoneControlsActions.RESUME_RECORDING:
        url += '/resume-recording';
        return url;
      default:
        ErrorReporter.reportError(new Error('PhoneControlsGateway error'), {
          message: 'unsupported phone control action',
          extra: { action },
        });
    }
  }

  addParticipant(customerId, conversationItemId, addAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.ADD);
    return after(
      this._httpClient.post(endpoint, {
        parameters: addAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  addCallObserver(customerId, conversationItemId, addAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.ADD_OBSERVER);
    return after(
      this._httpClient.post(endpoint, {
        parameters: addAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  blindTransfer(customerId, conversationItemId, transferAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.BLIND_TRANSFER);
    return after(
      this._httpClient.post(endpoint, {
        parameters: transferAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  cancelWarmTransfer(customerId, conversationItemId, transferAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.CANCEL_WARM_TRANSFER);
    return after(
      this._httpClient.post(endpoint, {
        parameters: transferAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  completeWarmTransfer(customerId, conversationItemId, transferAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.COMPLETE_WARM_TRANSFER);
    return after(
      this._httpClient.post(endpoint, {
        parameters: transferAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  initiateWarmTransfer(customerId, conversationItemId, transferAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.INITIATE_WARM_TRANSFER);
    return after(
      this._httpClient.post(endpoint, {
        parameters: transferAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  removeParticipant(customerId, conversationItemId, removeAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.REMOVE);
    return after(
      this._httpClient.post(endpoint, {
        parameters: removeAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  endCall(customerId, conversationItemId) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.END_CALL);
    return after(this._httpClient.post(endpoint, {}), this.handlePhoneControlResponse.bind(this));
  }

  endConference(customerId, conversationItemId, endConferenceAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.END_CONFERENCE);
    return after(
      this._httpClient.post(endpoint, {
        parameters: endConferenceAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  hold(customerId, conversationItemId, holdAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.HOLD);
    return after(
      this._httpClient.post(endpoint, {
        parameters: holdAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  mute(customerId, conversationItemId, muteAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.MUTE);
    return after(
      this._httpClient.post(endpoint, {
        parameters: muteAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  observerCoach(customerId, conversationItemId, showAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.OBSERVER_COACH);
    return after(
      this._httpClient.post(endpoint, {
        parameters: showAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  observerAcceptTransfer(customerId, conversationItemId, showAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.OBSERVER_ACCEPT_TRANSFER);
    return after(
      this._httpClient.post(endpoint, {
        parameters: showAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  observerHide(customerId, conversationItemId, hideAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.OBSERVER_HIDE);
    return after(
      this._httpClient.post(endpoint, {
        parameters: hideAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  observerDeclineTransfer(customerId, conversationItemId, showAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.OBSERVER_DECLINE_TRANSFER);
    return after(
      this._httpClient.post(endpoint, {
        parameters: showAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  observerJoin(customerId, conversationItemId, showAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.OBSERVER_JOIN);
    return after(
      this._httpClient.post(endpoint, {
        parameters: showAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  observerListen(customerId, conversationItemId, showAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.OBSERVER_LISTEN);
    return after(
      this._httpClient.post(endpoint, {
        parameters: showAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  observerShow(customerId, conversationItemId, showAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.OBSERVER_SHOW);
    return after(
      this._httpClient.post(endpoint, {
        parameters: showAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  pauseRecording(customerId, conversationItemId, recordingAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.PAUSE_RECORDING);
    return after(
      this._httpClient.post(endpoint, {
        parameters: recordingAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  resumeRecording(customerId, conversationItemId, recordingAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.RESUME_RECORDING);
    return after(
      this._httpClient.post(endpoint, {
        parameters: recordingAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  recordQualityEvent(customerId, conversationItemId, eventAttrs) {
    const endpoint = this.url(customerId, conversationItemId, PhoneControlsActions.RECORD_QUALITY_EVENT);
    return after(
      this._httpClient.post(endpoint, {
        parameters: eventAttrs,
      }),
      this.handlePhoneControlResponse.bind(this)
    );
  }

  handlePhoneControlResponse(err, res) {
    if (err) {
      this.notifyObservers('handlePhoneControlsErrors', err);
    } else if (res.status === httpStatus.OK || res.status === httpStatus.NO_CONTENT) {
      this.notifyObservers('handlePhoneControlsSuccess', res.data);
    } else {
      ErrorReporter.reportError(new Error('PhoneControlsHttpGateway error'), {
        message: `unexpected response status: ${res.status}`,
      });
      this.notifyObservers('handlePhoneControlsErrors', res.data);
    }
  }
}

_.extend(PhoneControlsHttpGateway.prototype, Observable);
