import ChatCompositionContent from 'models/composition/chat_composition_content';
import CompositionContentType from 'models/composition/composition_content_type';
import CoBrowseCompositionContent from 'models/composition/cobrowse_composition_content';
import ConversationMessageCompositionContent from 'models/composition/conversation_message_composition_content';
import CreditCardCompositionContent from 'models/composition/credit_card_composition_content';
import EmailCompositionContent from 'models/composition/email_composition_content';
import ExternalFormCompositionContent from 'models/composition/external_form_composition_content';
import FbMessageCompositionContent from 'models/composition/fb_message_composition_content';
import NoteCompositionContent from 'models/composition/note_composition_content';
import PhoneCallbackCompositionContent from 'models/composition/phone_callback_composition_content';
import PhoneCallCompositionContent from 'models/composition/phone_call_composition_content';
import SmsCompositionContent from 'models/composition/sms_composition_content';
import TaskCompositionEditContent from 'models/composition/task_composition_edit_content';
import TaskCompositionContent from 'models/composition/task_composition_content';
import CustomChannelCompositionContent from 'models/composition/custom_channel_composition_content';

export default [
  [CompositionContentType.CHAT, ChatCompositionContent],
  [CompositionContentType.COBROWSE_WIDGET, CoBrowseCompositionContent],
  [CompositionContentType.CONVERSATION_MESSAGE, ConversationMessageCompositionContent],
  [CompositionContentType.CONVERSATION_NOTE, NoteCompositionContent],
  [CompositionContentType.CREDIT_CARD_REQUEST, CreditCardCompositionContent],
  [CompositionContentType.EMAIL, EmailCompositionContent],
  [CompositionContentType.EXTERNAL_FORM, ExternalFormCompositionContent],
  [CompositionContentType.FB_MESSAGE, FbMessageCompositionContent],
  [CompositionContentType.PHONE_CALL, PhoneCallCompositionContent],
  [CompositionContentType.PHONE_CALLBACK, PhoneCallbackCompositionContent],
  [CompositionContentType.SMS, SmsCompositionContent],
  [CompositionContentType.TASK, TaskCompositionContent],
  [CompositionContentType.TASK_EDIT, TaskCompositionEditContent],
  [CompositionContentType.CUSTOM_CHANNEL, CustomChannelCompositionContent],
];
