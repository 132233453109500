import he from 'he';

// 1. converts HTML entities to plain text or HTML tags
// 2. then removes all HTML tags
export default function htmlToText(stringWithHtml = '') {
  // unescape HTML entities
  stringWithHtml = he.unescape(stringWithHtml);

  // convert NBSP \x00A0 to a space \x0020
  stringWithHtml = stringWithHtml.replace(/\xa0/g, '\x20');

  // remove anything between brackets
  stringWithHtml = stringWithHtml.replace(/<(?:.|\n)*?>/gm, '');

  // remove extra spacing
  stringWithHtml = stringWithHtml.replace(/\s+/, ' ').trim();

  return stringWithHtml;
}
