import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import SearchMenu, { SearchCache, SearchResult } from './search_menu';
import SearchPills from './search_pills';
import Topic from 'models/topic';

const TopicSearch = connect(mapStateToProps)(SearchCache);

function mapStateToProps({ getProvider }) {
  function itemTransformer(topic) {
    return {
      id: topic.id,
      disabled: topic.disabled,
      name: topic.id ? topic.getNameWithAncestry() : topic.name,
    };
  }

  return {
    select: [],
    immutable: getProvider('topics').immutableStore.binding.get(),
    provider: getProvider('topics'),
    sortField: 'nameWithAncestry',
    itemTransformer,
  };
}

const SelectedTopicsContainer = connect(mapStateToSelectedTopicsProps)(SearchPills);

function mapStateToSelectedTopicsProps({ getProvider }, { selectedTopicIds }) {
  const topicsProvider = getProvider('topics');

  return {
    selectedItems: !topicsProvider.isLoading()
      ? selectedTopicIds
          .map(
            id =>
              (id && topicsProvider.findBy({ id })) ||
              new Topic({
                id,
                name: 'No Topic',
              })
          )
          .map(topic => {
            return {
              id: topic.id,
              label: topic.getNameWithAncestry(),
            };
          })
      : [],
  };
}

function includeExtraItems(items) {
  return [{ id: '', name: 'No Topic' }].concat(items);
}

export function TopicSearchMenu({ onChange, selectedTopicIds }) {
  return (
    <React.Fragment>
      <TopicSearch excludedIds={selectedTopicIds} includeExtraItems={includeExtraItems}>
        {({ search }) => (
          <SearchMenu
            onAddItem={onAddItem}
            placeholder="Search for a topic"
            renderRow={renderRow}
            search={search}
            selectedItemIds={selectedTopicIds}
            showAllByDefault
          />
        )}
      </TopicSearch>
      <SelectedTopicsContainer onDeleteItem={onDeleteItem} selectedTopicIds={selectedTopicIds} />
    </React.Fragment>
  );

  function renderRow({ item, onClickItem }) {
    return <SearchResult id={item.id} key={item.id} onMouseDown={onClickItem} primary={item.name} />;
  }

  function onAddItem(id) {
    onChange({ topicIds: selectedTopicIds.concat(id), newTopicId: id });
  }

  function onDeleteItem(id) {
    onChange({
      topicIds: _.filter(selectedTopicIds, selectedId => selectedId !== id),
    });
  }
}

TopicSearchMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTopicIds: PropTypes.arrayOf(PropTypes.string),
};

const TopicSearchMenuContainer = connect(mapStateToSearchMenuProps)(TopicSearchMenu);
export default TopicSearchMenuContainer;

function mapStateToSearchMenuProps({ getProvider }, props) {
  const searchResult = getProvider('universalSearchResults').findBy({
    id: props.queryId,
  });
  const selectedTopicIds = searchResult ? _.get(searchResult, 'query.filter.topicIds') || [] : [];

  return {
    selectedTopicIds,
  };
}
