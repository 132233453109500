import React from 'react';

import classnames from 'classnames';
import InteractionType from 'models/interaction_type';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import { StyledIconAPI } from '../metadata/api_metadata';

export default function MessageAutomationAnnotationItem({ item }) {
  return (
    <ItemContent item={item} showIcon={false}>
      {renderAnnotationItem()}
    </ItemContent>
  );

  function renderAnnotationItem() {
    return (
      <Metadata
        icon={
          <span>
            <StyledIconAPI className="api-icon">APP</StyledIconAPI>
            <i className={classnames('itemMetadata-icon', getIconClass())} />
          </span>
        }
        isInbound={false}
        text={item.content.message.content.content.description}
        timestamp={item.timestamp}
      />
    );
  }

  function getIconClass() {
    if (item.content.message.content.content.icon === InteractionType.EMAIL) {
      return 'icon-email-filled';
    }
    return '';
  }
}
