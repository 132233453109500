import PropTypes from 'prop-types';

const ActionPropTypes = PropTypes.shape({
  name: PropTypes.string,
  formUrl: PropTypes.string,
  integrationId: PropTypes.string,
});

export const OrderAttributesPropTypes = PropTypes.shape({
  id: PropTypes.string,
  actions: PropTypes.arrayOf(ActionPropTypes),
  currencyCode: PropTypes.string,
  name: PropTypes.string,
  note: PropTypes.string,
  sku: PropTypes.string,
  total: PropTypes.string,
});

export const FlightAttributesPropTypes = PropTypes.shape({
  // Required attributes
  departureTime: PropTypes.string, // Ought to be required but 🤫
  destination: PropTypes.string.isRequired,
  flightNumber: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  pnrCode: PropTypes.string.isRequired,
  status: PropTypes.string,
});

export const StayAttributesPropTypes = PropTypes.shape({
  checkInTime: PropTypes.string,
  checkOutTime: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.string,
  neighborhood: PropTypes.string,
});

export const SubscriptionAttributesPropTypes = PropTypes.shape({
  billingIntervalNumber: PropTypes.string,
  billingIntervalUnit: PropTypes.string,
  cancellationDate: PropTypes.string,
  cancellationReason: PropTypes.string,
  createdAt: PropTypes.string,
  nextBillingAt: PropTypes.string,
  price: PropTypes.string,
  quantity: PropTypes.string,
  status: PropTypes.string,
  subscriptionId: PropTypes.string,
  updatedAt: PropTypes.string,
  actions: PropTypes.arrayOf(ActionPropTypes),
  productId: PropTypes.string,
  sku: PropTypes.string,
});
