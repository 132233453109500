import _ from 'lodash';
import moment from 'moment-timezone';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'scripts/domain/models/lib/create_model';

export const scheduleTypes = createEnum('SCHEDULE_TYPE_DATE', 'SCHEDULE_TYPE_WEEKDAY');

const TimeRange = createModel({
  modelName: 'TimeRange',

  properties: {
    start: prop(String),
    end: prop(String),
  },

  isWithinTimeRange(timezone) {
    const startHoursAndMins = this.start.split(':').map(t => parseInt(t));
    const endHoursAndMins = this.end.split(':').map(t => parseInt(t));
    const startTime = moment()
      .tz(timezone)
      .hour(startHoursAndMins[0])
      .minute(startHoursAndMins[1]);
    const endTime = moment()
      .tz(timezone)
      .hour(endHoursAndMins[0])
      .minute(endHoursAndMins[1]);
    const now = moment().tz(timezone);

    return now.isBetween(startTime, endTime, 'minute', '[]');
  },
});

const WeekdaySchedule = createModel({
  modelName: 'WeekdaySchedule',

  properties: {
    location: prop(String),
    daysOfTheWeek: prop([String]),
    TimeRanges: prop([TimeRange]),
  },

  isEnabled() {
    const now = moment().tz(this.location);
    const currentDay = now.format('dddd');

    return (
      !_.includes(this.daysOfTheWeek, currentDay) ||
      _.every(this.TimeRanges, tr => !tr.isWithinTimeRange(this.location))
    );
  },
});

const DateSchedule = createModel({
  modelName: 'DateSchedule',

  properties: {
    location: prop(String),
    date: prop(String),
    timeRanges: prop([TimeRange]),
  },

  isEnabled() {
    const todaysDate = moment()
      .tz(this.location)
      .format('MM/DD/YYYY');

    return todaysDate !== this.date || _.every(this.timeRanges, tr => !tr.isWithinTimeRange(this.location));
  },
});

const Schedule = createModel({
  modelName: 'Schedule',

  properties: {
    type: prop().oneOf(..._.keys(scheduleTypes)),
    schedule: prop.oneOf(...[WeekdaySchedule, DateSchedule]),
  },

  statics: {
    overrideFromJs() {
      return attrs => {
        switch (attrs.type) {
          case scheduleTypes.SCHEDULE_TYPE_DATE:
            attrs.schedule = DateSchedule.fromJs(attrs.schedule);
            break;
          case scheduleTypes.SCHEDULE_TYPE_WEEKDAY:
            attrs.schedule = WeekdaySchedule.fromJs(attrs.schedule);
            break;

          default:
            throw new Error(`unknown schedule type for ivr schedule node: ${attrs.type}`);
        }

        return new this(attrs);
      };
    },
  },
});

const NodeSchedule = createModel({
  modelName: 'NodeSchedule',

  properties: {
    nodeId: prop(String),
    schedule: prop(Schedule),
  },
});

export default createModel({
  modelName: 'ScheduleNode',

  properties: {
    id: prop(String).isRequired,
    description: prop(String),
    nodeSchedules: prop([NodeSchedule]),
    defaultNextId: prop(String),
  },

  isEnabled() {
    return _.every(this.nodeSchedules, ns => ns.schedule.schedule.isEnabled());
  },
});
