import { useEffect } from 'react';

export default function FocusPlugin(onChange) {
  return {
    commands: { focusAtEnd },
  };
}

export function focusAtEnd(editor) {
  const { document } = editor.value;
  editor.moveAnchorToEndOfNode(document.getTexts().last());
  editor.moveFocusToEndOfNode(document.getTexts().last());
  editor.focus();
  return editor;
}

export function useFocusOnMount(editor, onChange, shouldFocusOnMount) {
  useEffect(() => {
    if (editor && shouldFocusOnMount) {
      editor.focusAtEnd();
      onChange(editor);
    }
  }, [editor]);
}
