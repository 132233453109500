export default class UserSessionResetter {
  constructor(context) {
    this.context = context;
  }

  reset() {
    const currentAgent = this.context.stores.currentAgent.get();
    // In theory the currentAgent should always be set. In practice there would be bugs and various
    // undefined states, from which we would still want to log out, so:
    // check if currentAgent availability exists before using its attributes
    if (currentAgent) {
      this.context.capacityManager.stop();
      this.context.gateways.phoneGateway.deregisterAgent(currentAgent.id);
      this.context.gateways.conversationCount.unsubscribeFromAgentConversationCounts(currentAgent.id);
    }

    this.context.reset();
  }
}
