import MessageContentTypeFile from './message_content_type_file';
import MessageContentTypeImage from './message_content_type_image';
import MessageContentTypeLocation from './message_content_type_location';
import MessageContentTypeText from './message_content_type_text';
import MessageContentType from './message_content_type';

export default [
  [MessageContentType.FILE, MessageContentTypeFile],
  [MessageContentType.IMAGE, MessageContentTypeImage],
  [MessageContentType.LOCATION, MessageContentTypeLocation],
  [MessageContentType.TEXT, MessageContentTypeText],
];
