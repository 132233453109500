import _ from 'lodash';

import GatewayErrorHandler from 'scripts/application/lib/gateway_error_handler';
import { InfrastructureError } from 'scripts/application/lib/error';
import mixin from 'scripts/lib/mixin';
import qconsole from 'scripts/lib/qconsole';
import UnauthorizedHandler from 'actions/lib/unauthorized_handler';

/**
 * Handles typical errors occurring when communicating with a remote service
 * via a gateway by logging them to browser console.
 *
 * Sends unhandled errors to Sentry.
 *
 * This handling is useful when an error occurs in response to a background
 * action not triggered by a user, such as scheduled via a job or
 * via an incoming message.
 *
 * @param context - the runner context
 * @param err - the error to handle
 */
export function handleCommonErrors(context, err) {
  let handler = new GatewayErrorSilentHandler(context);
  handler.handleCommonErrors(err);
}

/**
 * Common implementation of error handler for gateway observers
 *
 * Compose in a gateway observer as usual or mix in. If mixing in ensure the context property is defined.
 */
export default class GatewayErrorSilentHandler {
  constructor(context) {
    this.context = context;
  }

  /**
   * Handles errors originating outside of the business logic,
   * for instance, network, communication, service availability, server bugs, etc.
   *
   * @param ctx - request context to include in Sentry report for unexpected errors
   * @param err - the error to handle
   */
  handleInfrastructureErrors(ctx, err) {
    if (arguments.length === 1 || !err) {
      err = arguments[0];
      ctx = { correlationId: err.correlationId };
    }

    if (err instanceof InfrastructureError) {
      qconsole.error(`${err.message}: ${err.response || _.get(err, 'cause.message')}`);
      return;
    }

    this.reportError(ctx, err); // We shouldn't see this, programming error.
  }
}

mixin(GatewayErrorSilentHandler.prototype, GatewayErrorHandler.prototype);
mixin(GatewayErrorSilentHandler.prototype, UnauthorizedHandler.prototype);
