import { cloneFragment } from 'slate-react-old';
import { useCallback } from 'react';
import { Value } from 'slate-old';

import FullSerializer from 'components/text_editor/serializers/full_serializer';

const SLATE_TRANSFER_TYPE = 'application/x-slate-fragment';

// New slate and old slate don't play well with each other when it comes to the clipboard. New slate and old slate have
// completely different data models, so what gets copied to the clipboard from an old Slate editor (such as in the
// answer panel) is not comprehensible to new Slate.
export default function useCopyForNewSlate(editorRef) {
  const onCopy = useCallback(
    evt => {
      if (!evt.clipboardData) {
        return;
      }

      // Clone fragment takes the current selection and creates a new fragment out of it, base64 encoding it and
      // putting it in the clipboardData for the event. Unfortunately there's no easy way to just get the
      // fragment. Have to go through the event.
      cloneFragment(evt, editorRef.current);
      const encoded = evt.clipboardData.getData(SLATE_TRANSFER_TYPE);
      if (!encoded) {
        return;
      }

      // Base 64 decode it
      const fragmentDocument = JSON.parse(decodeURIComponent(window.atob(encoded)));
      // The serializer expects a Value, not a plain object. Of course the fragment isn't quite a value..
      // it's just the document part. Thanks Slate.
      const html = FullSerializer.serialize(Value.fromJSON({ document: fragmentDocument }));

      evt.clipboardData.setData('application/x-slate-fragment-old', html);
    },
    [editorRef]
  );

  return onCopy;
}
