import _ from 'lodash';

import ChannelConfigurationConverter from 'scripts/application/dto_converters/channel_configuration_converter';
import DtoConverter from 'scripts/application/dto_converters/endpoint_converter';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import qconsole from 'scripts/lib/qconsole';

export default class EndpointGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.endpoints;
  }

  onFetchAllSuccess(endpointsDto) {
    this.store.set(_.map(endpointsDto, DtoConverter.fromDto));
    this.context.stores.channelConfiguration.set(ChannelConfigurationConverter.fromDto({ endpoints: endpointsDto }));
  }

  onFetchAllError(payload) {
    qconsole.log(`Failed to fetch endpoint list ${payload}`);
  }

  onBroadcast(endpointDto) {
    const endpoint = DtoConverter.fromDto(endpointDto);
    this.store.addOrReplace(endpoint);
    let channelConfiguration = this.context.stores.channelConfiguration.get();
    channelConfiguration.addOrReplaceEndpoint(endpoint);
    this.context.stores.channelConfiguration.set(channelConfiguration);
  }

  onBroadcastDelete({ endpointId }) {
    this.store.remove(endpointId);
    let channelConfiguration = this.context.stores.channelConfiguration.get();
    channelConfiguration.removeEndpoint(endpointId);
    this.context.stores.channelConfiguration.set(channelConfiguration);
  }

  onReplaceSuccess(endpoint, { endpointId }) {
    this.store.commitPending(endpointId);
  }

  onReplaceError(err, { endpointId }) {
    this.store.resetPending(endpointId);
    handleCommonErrors(this.context, err);
  }
}
