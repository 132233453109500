import _ from 'lodash';

import Communicator from 'models/communicator';
import ConversationItemType from 'models/conversation_item_type';
import CreateComposition from './lib/create_composition';
import CustomChannelCompositionContent from 'models/composition/custom_channel_composition_content';
import Endpoint from 'models/endpoint';
import { EndpointTypes } from 'models/endpoint';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import { MessageChannelType } from 'models/conversation_message';

class CreateCustomChannelComposition extends CreateComposition {
  constructor(context) {
    super(context, CustomChannelCompositionContent);
  }

  _createContent(attrs) {
    const {
      customChannelId,
      companyAddress: companyAddressAttr,
      customerAddress: customerAddressAttr,
      groupId,
      groupName,
    } = attrs;

    const customChannel = _.find(
      this.context.stores.customChannels.findAll(),
      customChannel => customChannel.id === customChannelId
    );
    const customChannelName = customChannel?.name;

    const channelDisplayTitle = customChannelName;

    const externalCustomerAddress = _.find(
      this.customerStores.profile.get().externalCustomerAddresses,
      address =>
        address.type === Endpoint.Type.CUSTOM_CHANNEL &&
        address.content?.customChannelId === customChannelId &&
        (!customerAddressAttr || address.id === customerAddressAttr)
    );

    const messageGroup = this._findMessageGroup(customChannel, attrs.conversationId);

    const companyAddress = companyAddressAttr || this._getCompanyAddress(attrs, customChannelId);

    return new CustomChannelCompositionContent({
      channel: MessageChannelType.CUSTOM_CHANNEL,
      channelDisplayTitle,

      companyAddress,
      companyDisplayName: companyAddress,
      customChannelId,
      customerAddress: _.get(externalCustomerAddress, 'id'),
      customerDisplayName: _.get(externalCustomerAddress, 'displayName'),
      groupId: groupId || messageGroup.groupId,
      groupName: groupName || messageGroup.groupName,
    });
  }

  _getCompanyAddress(attrs, customChannelId) {
    const lastConversationItem = this._findLastCustomChannelItem(attrs.conversationId, customChannelId);
    const conversation = this.customerStores.conversations.find(attrs.conversationId);
    const potentialFromList = this._getPotentialFroms(conversation, customChannelId);

    const recipientAddress = _.get(lastConversationItem, 'content.companyAddress');
    if (recipientAddress && potentialFromList.allFromAddresses?.includes(recipientAddress)) {
      return recipientAddress;
    }

    return _.get(potentialFromList, 'default');
  }

  _allowDuplicate() {
    return false;
  }

  _isSameCompositionType(attrs, composition) {
    return (
      composition.content instanceof this.contentClass && composition.content.customChannelId === attrs.customChannelId
    );
  }

  _canCreateComposition() {
    return canCreateCustomChannelComposition(this.context);
  }

  _getFroms({ allFromAddresses, endpoints, routingGroupId }) {
    const endpointsMap = _.mapValues(_.keyBy(endpoints, 'id'));

    let firstEndpointFromRoutingGroups = _.chain(this.context.stores.communicationQueues.findAll())
      .find(commQueue => commQueue.routingGroupId === routingGroupId && _.has(endpointsMap, commQueue.endpointId))
      .value();

    // TODO: get default endpoint address based on channel configuration (need to add channelConfiguration gateway, observer,...)
    const defaultAddress = undefined; // will be added in separated task

    return {
      default:
        defaultAddress ||
        endpointsMap[firstEndpointFromRoutingGroups?.endpointId]?.configuration?.recipientAddress ||
        allFromAddresses[0],
      preferred: endpointsMap[firstEndpointFromRoutingGroups?.endpointId]?.recipientAddress,
      allFromAddresses,
      endpointsMap,
    };
  }

  _getPotentialFroms(conversation, customChannelId) {
    let channelConfiguration = this.context.stores.channelConfiguration.get();
    let customChannelEndpoints = channelConfiguration
      ? channelConfiguration
          .getEndpointsOfType(Endpoint.Type.CUSTOM_CHANNEL)
          .filter(endpoint => endpoint.configuration?.customChannelId === customChannelId)
      : [];
    let allFromAddresses = _.map(customChannelEndpoints, 'configuration.recipientAddress');
    let routingGroupId = conversation.assignee.routingGroupId;

    return this._getFroms({
      allFromAddresses,
      customChannelId,
      conversation,
      endpoints: customChannelEndpoints,
      endpointType: Endpoint.Type.CUSTOM_CHANNEL,
      routingGroupId,
    });
  }

  _getRecipients(customChannelId) {
    const { endpoints } = this.context.stores.channelConfiguration.get();

    return _.filter(
      endpoints,
      ({ type, configuration }) =>
        type === EndpointTypes.CUSTOM_CHANNEL && configuration.customChannelId === customChannelId
    );
  }

  _findLastCustomChannelItem(conversationId, customChannelId) {
    const customerId = this.context.stores.currentLocation.get().customerId;
    const { conversationHistory } = this.context.stores.customers.storesFor(customerId);

    return getLatestManualItem({
      conversationHistory,
      filter: item =>
        item.content.type === ConversationItemType.CUSTOM_CHANNEL_MESSAGE &&
        item.content.customChannelId === customChannelId,
      conversationId,
    });
  }

  _findMessageGroup(customChannel, conversationId) {
    if (!customChannel.messageGroupingEnabled) {
      return {
        groupId: undefined,
        groupName: undefined,
      };
    }

    const customerId = this.context.stores.currentLocation.get().customerId;
    const { conversationHistory } = this.context.stores.customers.storesFor(customerId);

    // if the agent sent a message on the channel => use the agents last message groupId
    const agentLastMessage = conversationHistory.findBy({
      filter: item =>
        item.content.type === ConversationItemType.CUSTOM_CHANNEL_MESSAGE &&
        item.conversationId === conversationId &&
        item.content.customChannelId === customChannel.id &&
        item.initiator.type === Communicator.AGENT,
      sortBy: item => -1 * new Date(item.timestamp).getTime(),
    });
    if (agentLastMessage) {
      return {
        groupId: agentLastMessage.content.groupId,
        groupName: agentLastMessage.content.groupName,
      };
    }

    // use the last customer message as the groupId
    const customerLastMessage = conversationHistory.findBy({
      filter: item =>
        item.content.type === ConversationItemType.CUSTOM_CHANNEL_MESSAGE &&
        item.conversationId === conversationId &&
        item.content.customChannelId === customChannel.id &&
        item.initiator.type === Communicator.CUSTOMER,
      sortBy: item => -1 * new Date(item.timestamp).getTime(),
    });

    return {
      groupId: customerLastMessage.content.groupId,
      groupName: customerLastMessage.content.groupName,
    };
  }
}

function canCreateCustomChannelComposition(context) {
  return context.stores.customChannelsConfig.get().enabled;
}

export default CreateCustomChannelComposition;
