import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('CustomChannel').extend('Uuid');

Factory.define('CustomChannelWithDefaults')
  .extend('CustomChannel')
  .attr('id', 'aribnb-channel-1')
  .attr('name', 'airbnb')
  .attr('icon', 'airbnb');
