import _ from 'lodash';

import { ExternalCustomerLookupResult, LookupLevel } from 'models/external_customer_profile';
import { trimAndRemoveEmptyQueryFields } from './lib/lookup_helpers';

export default class SearchExternalCustomers {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, query, integrationId }) {
    if (!_.compact(_.values(query)).length) {
      this.context.stores.externalCustomerSearchResults.resetPending();
      this.context.stores.externalCustomerSearchResults.remove();
      return;
    }

    const { correlationId } = this.context.gateways.externalCustomerLookup.request(
      { customerId, integrationId },
      {
        lookupLevel: LookupLevel.BASIC,
        query: trimAndRemoveEmptyQueryFields(query),
      }
    );

    const pendingLookupResult = new ExternalCustomerLookupResult({ id: correlationId });
    this.context.stores.externalCustomerSearchResults.clearErrors();
    this.context.stores.externalCustomerSearchResults.setPending(pendingLookupResult);
  }
}
