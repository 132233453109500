import createModel from '../lib/create_model';

const FollowedTaskCompleted = createModel({
  modelName: 'FollowedTaskCompleted',

  properties: {
    customerId: String,
    taskId: String,
  },
});

export default FollowedTaskCompleted;
