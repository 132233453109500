import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import ClearSelectedAnswer from 'actions/answers/clear_selected_answer';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import { ListItem } from 'components/customer/answer_panel/search/answer_list_item';
import LoadingDots from 'components/lib/loading_dots';
import SparkleIcon from 'components/lib/icons/sparkle_icon';

export default function SuggestedReplyListItem() {
  const { selectedAnswer, suggestedReply } = useContext(AnswerPanelContext);
  const executeAction = useExecuteAction();
  const onClick = () => {
    executeAction(ClearSelectedAnswer);
  };

  if (!suggestedReply?.content && !suggestedReply?.isLoading) {
    return null;
  }

  const isFocused = !selectedAnswer;
  let icon = null;
  if (suggestedReply?.isLoading) {
    icon = <StyledLoadingDots $isFocused={isFocused} />;
  } else if (suggestedReply?.content) {
    icon = <StyledSparkleIcon $isFocused={isFocused} />;
  }
  return (
    <StyledListItem isFocused={isFocused} onClick={onClick}>
      Reply Suggestion <IconContainer>{icon}</IconContainer>
    </StyledListItem>
  );
}

export const StyledListItem = styled(ListItem)`
  flex-shrink: 0;
`;

const StyledLoadingDots = styled(LoadingDots)`
  margin: 0;
  ${p =>
    p.$isFocused &&
    css`
      .dot {
        background-color: ${p => p.theme.colors.green300};
      }
    `}
`;

const StyledSparkleIcon = styled(SparkleIcon)`
  ${p =>
    p.$isFocused &&
    css`
      path {
        fill: ${p => p.theme.colors.white};
      }
    `}
`;

const IconContainer = styled.div`
  display: flex;
  margin-left: 6px;
`;
