import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Chevron Right Fill
 */

export default function ChevronRightFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M10.6464 7.64645L5.85355 2.85355C5.53857 2.53857 5 2.76165 5 3.20711V12.7929C5 13.2383 5.53857 13.4614 5.85355 13.1464L10.6464 8.35355C10.8417 8.15829 10.8417 7.84171 10.6464 7.64645Z" />
    </Icon>
  );
}
