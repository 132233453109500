import { useEffect, useRef } from 'react';

export default function useTimeout(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay);

      // If the delay changes to null, this cleanup effect gets run, so we can be sure
      // the timeout is cleared properly.
      return () => {
        clearTimeout(id);
      };
    }
  }, [delay]);
}
