export default class MarkAgentNotificationAsRead {
  constructor(context) {
    this.context = context;
  }

  run({ id }) {
    const notification = this.context.stores.agentNotifications.findBy({ id });

    if (notification) {
      this.context.analytics.track('Agent Notification Clicked', {
        type: notification.type,
        notificationId: notification.id,
        wasUnread: !notification.readAt,
      });

      notification.markAsRead();
      this.context.stores.agentNotifications.addOrReplace(notification);
      this.context.gateways.agentNotification.update({ notificationId: notification.id }, notification.toJs());
    }
  }
}
