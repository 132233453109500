import { Factory } from 'rosie';

Factory.define('MessageContentTypeImage').attr('type', 'IMAGE');

Factory.define('MessageContentTypeImageWithDefaults')
  .extend('MessageContentTypeImage')
  .attr('caption', 'example image caption')
  .attr('bodyHtml', '<div><b>example</b> image <i>caption</i>')
  .attr('buttons', [])
  .attr('hasRedactedPaymentCardNumber', false)
  .attr('images', ['images'], images => {
    return images ? images.map(image => Factory.attributes('Attachment', image)) : [];
  });
