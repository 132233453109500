import analytics from 'scripts/lib/analytics';

export default class ObserverHide {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationItemId }) {
    let currentAgent = this.context.stores.currentAgent.get();

    this.context.gateways.phoneControlsHttp.observerHide(customerId, conversationItemId, {
      participantId: currentAgent.id,
    });

    analytics.track('Hero Coaching - Observer Hidden', {
      customerId,
      conversationItemId,
    });
  }
}
