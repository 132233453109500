export default class ExportCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ customerId }) {
    this.context.gateways.complianceCustomer.fetch({ customerId });

    this.context.analytics.track('customer export - initiated', {
      customerId,
    });
  }
}
