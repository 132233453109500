import _ from 'lodash';

import createModel, { prop } from 'models/lib/create_model';
import TaskComment from './task_comment';
import TaskFollower from './task_follower';

const TaskInfo = createModel({
  modelName: 'TaskInfo',

  properties: {
    id: prop(String).isRequired,
    comments: prop({}).default({}),
    followers: prop({}).default({}),
    areCommentsLoading: prop(Boolean),
    areFollowersLoading: prop(Boolean),
  },

  addComment(comment) {
    this.comments[comment.id] = comment;
  },

  addFollower(follower) {
    this.followers[follower.id] = follower;
  },

  getComments() {
    return _.orderBy(this.comments, ['createdAt']);
  },

  getFollowers() {
    return _.values(this.followers);
  },

  removeFollower({ agentId, itemId }) {
    let removed = _.find(this.followers, f => f.agentId === agentId && f.itemId === itemId);
    if (!removed) return null;
    delete this.followers[removed.id];
    return removed;
  },

  setCommentsLoading() {
    this.areCommentsLoading = true;
  },

  setFollowersLoading() {
    this.areFollowersLoading = true;
  },

  resetCommentsLoading() {
    this.areCommentsLoading = false;
  },

  resetFollowersLoading() {
    this.areFollowersLoading = false;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    overrideFromJs(fromJs) {
      return attrs => {
        let comments = {};
        _.forEach(attrs.comments, c => {
          comments[c.id] = TaskComment.fromJs(c);
        });
        let followers = {};
        _.forEach(attrs.followers, f => {
          followers[f.id] = new TaskFollower(f);
        });
        return fromJs({ ...attrs, comments, followers });
      };
    },
  },
});

export default TaskInfo;
