import React from 'react';

export default function QuotedTextIcon() {
  return (
    <svg
      className="quoteIcon"
      fill="#242424"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.7388 9.77412C11.4282 10.3201 10.8635 10.9071 9.90353 11.4668C9.63529 11.6169 9.46588 11.8899 9.46588 12.1766C9.46588 12.7636 10.0871 13.1731 10.6518 12.9274C12.2753 12.2312 15 10.4976 15 6.90754C15 5.29679 13.6588 4 12.0071 4C10.3553 4 9 5.29679 9 6.90754C9 8.40908 10.2 9.63762 11.7388 9.77412Z" />
      <path d="M3.73882 9.77412C3.42824 10.3201 2.86353 10.9071 1.90353 11.4668C1.63529 11.6169 1.46588 11.8899 1.46588 12.1766C1.46588 12.7636 2.08706 13.1731 2.65176 12.9274C4.27529 12.2312 7 10.4976 7 6.90754C7 5.29679 5.65882 4 4.00706 4C2.35529 4 1 5.29679 1 6.90754C1 8.40908 2.2 9.63762 3.73882 9.77412Z" />
    </svg>
  );
}
