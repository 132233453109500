import React from 'react';

import MobileChatIcon from 'components/lib/icons/mobile_chat_icon';

export default function MobileChatMetadataIcon() {
  return (
    <div className="itemMetadata-chatIcon">
      <MobileChatIcon className="mobileChatIconGray" />
    </div>
  );
}
