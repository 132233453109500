import _ from 'lodash';

import Composition from 'models/composition';
import CompositionGatewayObserver from 'actions/composition/composition_gateway_observer';
import CompositionUploads from './composition_uploads';
import CustomerView from 'models/location/customer_view';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import qconsole from 'scripts/lib/qconsole';
import Upload from 'models/upload';

export default function(context, composition) {
  qconsole.log(`Try removing composition [${composition.id}] for customer [${composition.customerId}]`);
  if (composition.persisted) {
    let { correlationId } = context.gateways.composition.remove(
      context.stores.currentAgent.get().id,
      composition.customerId,
      composition.id
    );
    startUploadCleanup(correlationId, context, composition);
  } else {
    immediatelyCleanupUploads(context, composition);
  }

  if (context.stores.customers.has({ id: composition.customerId })) {
    const compositionsStore = getCompositionsStore(context, composition.customerId);

    // If the composition is persisted, we mark it for deletion instead of just removing it
    // outright (in case we receive a broadcast from an old update, we want to ignore that
    // instead of viewing it as a new composition we need to put in the store).
    //
    // But if the composition is only saved locally, we can just remove immediately since
    // we don't need to worry about the composition having been updated on the backend.
    if (composition.peristed && compositionsStore.has({ id: composition.id })) {
      compositionsStore.setPendingDelete(composition.id);
    } else {
      compositionsStore.remove(composition.id);
    }
  }
  context.stores.localCompositions.remove(composition.customerId, composition.id);

  clearCurrentComposition(context, composition);
}

function startUploadCleanup(correlationId, context, composition) {
  setCompletedUploadsForDelete(correlationId, context.stores.currentAgent.get().id, composition);
  abortUnfinishedUploads(context, composition);
}

function setCompletedUploadsForDelete(correlationId, agentId, composition) {
  let completedUploads = composition.getUploads().filter(u => u.status === Upload.Status.COMPLETED);
  if (completedUploads.length) {
    CompositionGatewayObserver.setUploadsToDelete(
      correlationId,
      CompositionUploads.create({ agentId, compositionId: composition.id, uploads: completedUploads })
    );
  }
}

function abortUnfinishedUploads(context, composition) {
  composition
    .getUploads()
    .filter(u => u.status === Upload.Status.STARTED)
    .forEach(u => context.gateways.compositionUpload.abort(composition.getUploadPath(u.id)));
}

function immediatelyCleanupUploads(context, composition) {
  const currentAgentId = context.stores.currentAgent.get().id;
  abortUnfinishedUploads(context, composition);
  const completedUploads = composition.getUploads().filter(u => u.status === Upload.Status.COMPLETED);
  _.forEach(completedUploads, u =>
    context.gateways.compositionUpload.remove(currentAgentId, Composition.getUploadPath(composition.id, u.id))
  );
}

function clearCurrentComposition(context, composition) {
  const currentLocation = context.stores.currentLocation.get();
  if (currentLocation instanceof CustomerView) {
    if (currentLocation.currentCompositionId === composition.id) {
      currentLocation.clearCurrentComposition();
      context.stores.currentLocation.set(currentLocation);
    }
  }
}
