import React from 'react';
import PropTypes from 'prop-types';

import AbandonedCall from 'models/abandoned_call';
import AbandonedCallNotification from 'components/notification/abandoned_call_notification';
import AgentProfile from 'models/agent_profile';
import ChatMessage from 'models/chat_message';
import ChatReplyNotificationContainer from './chat_reply_notification_container';
import CompletedCallNotification from './completed_call_notification';
import ConversationNote from 'models/conversation_note';
import ConversationNoteNotificationContainer from './conversation_note_notification_container';
import ConversationNotificationModel from 'models/notification/conversation_notification';
import CustomChannelMessage from 'models/custom_channel_message';
import CustomChannelMessageNotification from './custom_channel_message_notification';
import EmailMessage from 'models/email_message';
import EmailMessageNotification from './email_message_notification';
import EmailSendFailure from 'models/email_send_failure';
import EmailSendFailureNotification from './email_send_failure_notification';
import FbMessageIncoming from 'models/fb_message_incoming';
import FbMessageIncomingNotification from './fb_message_incoming_notification';
import PhoneCall from 'models/phone_call';
import SmsMessage from 'models/sms_message';
import SmsMessageNotification from './sms_message_notification';
import Task from 'models/task';
import TaskItemNotification from './task_item_notification';
import Voicemail from 'models/voicemail';
import VoicemailNotification from './voicemail_notification';

class ConversationNotification extends React.Component {
  render() {
    let content = this.props.notification.conversationItem.content;
    if (content instanceof AbandonedCall) {
      return <AbandonedCallNotification notification={this.props.notification} />;
    } else if (content instanceof ConversationNote) {
      return <ConversationNoteNotificationContainer notification={this.props.notification} />;
    } else if (content instanceof ChatMessage) {
      return <ChatReplyNotificationContainer notification={this.props.notification} />;
    } else if (content instanceof EmailMessage) {
      return <EmailMessageNotification notification={this.props.notification} />;
    } else if (content instanceof EmailSendFailure) {
      return <EmailSendFailureNotification notification={this.props.notification} />;
    } else if (content instanceof FbMessageIncoming) {
      return <FbMessageIncomingNotification notification={this.props.notification} />;
    } else if (content instanceof SmsMessage) {
      return <SmsMessageNotification notification={this.props.notification} />;
    } else if (content instanceof Task) {
      return <TaskItemNotification currentAgent={this.props.currentAgent} notification={this.props.notification} />;
    } else if (content instanceof Voicemail) {
      return <VoicemailNotification notification={this.props.notification} />;
    } else if (content instanceof PhoneCall) {
      return <CompletedCallNotification notification={this.props.notification} />;
    } else if (content instanceof CustomChannelMessage) {
      return <CustomChannelMessageNotification notification={this.props.notification} />;
    }
    return null;
  }
}

ConversationNotification.propTypes = {
  currentAgent: PropTypes.instanceOf(AgentProfile).isRequired,
  notification: PropTypes.instanceOf(ConversationNotificationModel).isRequired,
};

export default ConversationNotification;
