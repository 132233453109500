import { CSSTransition as CSSTransitionLib, Transition as TransitionLib } from 'react-transition-group';
import React from 'react';

export default class CSSTransition extends React.Component {
  render() {
    const enabled = !CSSTransition.isDisabled;

    const props = {
      enter: enabled,
      exit: enabled,
      ...this.props,
    };

    return <CSSTransitionLib {...props} />;
  }
}

export class Transition extends React.Component {
  render() {
    const enabled = !Transition.isDisabled;

    const props = {
      enter: enabled,
      exit: enabled,
      ...this.props,
    };

    return <TransitionLib {...props} />;
  }
}
