import _ from 'lodash';

export class PropertyBuilder {
  constructor(opts) {
    this.opts = opts;
  }

  _clone(overrides) {
    let opts = _.chain(this.opts)
      .clone()
      .assign(overrides)
      .value();
    return new PropertyBuilder(opts);
  }

  type(type_) {
    return this._clone({ type: type_ });
  }

  default(defaultValue) {
    return this._clone({ defaultValue });
  }

  fromJs(fromJs) {
    if (!_.isFunction(fromJs)) {
      throw new Error('fromJs must be a function');
    }
    return this._clone({ fromJs });
  }

  get isRequired() {
    return this._clone({ isRequired: true });
  }

  label(label_) {
    return this._clone({ label: label_ });
  }

  oneOf(...oneOf_) {
    return this._clone({ oneOf: oneOf_ });
  }

  build() {
    let opts = _.omit(this.opts, 'type');
    let type = this.opts.type;

    if (opts.oneOf) {
      opts.possibleProperties = _.map(opts.oneOf, t => convertTypeToProperty(t));
      return new MultiTypedProperty(opts);
    } else if (_.isArray(type)) {
      opts.itemProperties = _.map(type, t => convertTypeToProperty(t));
      return new ArrayProperty(opts);
    } else if (_.isPlainObject(type)) {
      opts.attributeProperties = _.mapValues(type, convertTypeToProperty);
      return new ObjectProperty(opts);
    }
    opts.type = type;
    return new TypedProperty(opts);

    // recursively convert PropertyBuilder type to Property
    function convertTypeToProperty(propertyType, propertyName) {
      let builder =
        propertyType instanceof PropertyBuilder ? propertyType : new PropertyBuilder({ type: propertyType });

      if (propertyName != null) {
        builder = builder.label(propertyName);
      }

      return builder.build();
    }
  }
}

export class Property {
  constructor({ label, defaultValue, isRequired, fromJs }) {
    this.label = label;
    this.defaultValue = defaultValue;
    this.isRequired = isRequired;
    this.fromJs = fromJs;
  }

  default(value) {
    if (value == null) {
      if (_.isFunction(this.defaultValue)) {
        return this.defaultValue();
      } else if (this.defaultValue != null) {
        return _.cloneDeep(this.defaultValue);
      }
    }

    return value;
  }

  validate(value) {
    if (value == null && this.isRequired) {
      this.error('required value missing');
    }
  }

  valueFromJs(value) {
    return this.fromJs ? this.fromJs(value) : value;
  }

  error(msg) {
    if (this.label) {
      msg += ` for property ${this.label}`;
    }
    throw new Error(msg);
  }
}

export class ObjectProperty extends Property {
  constructor({ attributeProperties, label, defaultValue, fromJs, isRequired }) {
    super({ label, defaultValue, fromJs, isRequired });
    this.attributeProperties = attributeProperties;
  }

  default(value) {
    value = super.default(value);

    // create a value if none is passed in, but there's a default specified
    // for a sub-attribute within the definition
    let hasAttributeDefault = _.some(this.attributeProperties, prop => prop.defaultValue != null);

    if (value == null && hasAttributeDefault) {
      value = {};
    }

    // recursively apply defaults to the sub-attributes, if necessary
    if (value) {
      value = _.clone(value);
      _.forEach(this.attributeProperties, (prop, k) => {
        if (value[k] == null) {
          value[k] = prop.default(value[k]);
        }
      });
    }

    return value;
  }

  validate(value) {
    super.validate(value);

    // if no value is defined, it's fine (superclass checks for required)
    if (value == null) {
      return;
    }

    if (!_.isObject(value)) {
      this.error(`${value} must be an object`);
    }

    _.forOwn(this.attributeProperties, (v, k) => v.validate(value[k]));
  }

  pick(value) {
    return _.pick(value, _.keys(this.attributeProperties));
  }
}

export class ArrayProperty extends Property {
  constructor({ itemProperties, label, defaultValue, fromJs, isRequired }) {
    super({ label, defaultValue, isRequired });
    this.fromJs = fromJs;
    this.itemProperties = itemProperties;
  }

  validate(value) {
    super.validate(value);

    // if no value is defined, it's fine (superclass checks for required)
    if (value == null) {
      return;
    }

    if (!_.isArray(value)) {
      this.error(`${value} must be an array`);
    }

    // validate every item in the array is of the proper type
    if (this.itemProperties && this.itemProperties.length) {
      value.forEach(i => {
        if (!isItemValid(i, this.itemProperties)) {
          this.error(`${JSON.stringify(i)} must be an instance of one of the types`);
        }
      });
    }
  }

  valueFromJs(items) {
    if (!items) {
      return items;
    }

    let itemFromJs =
      (this.fromJs && this.fromJs.bind(this)) ||
      (this.itemProperties.length && this.itemProperties[0].valueFromJs.bind(this.itemProperties[0])) ||
      super.valueFromJs.bind(this);

    return items.map(item => itemFromJs(item));
  }
}

function isItemValid(item, properties) {
  for (let j = 0; j < properties.length; j++) {
    try {
      properties[j].validate(item);
      return true;
    } catch (e) {
      // continue
    }
  }
  return false;
}

export class TypedProperty extends Property {
  constructor({ type, label, defaultValue, fromJs, isRequired }) {
    super({ label, defaultValue, isRequired });
    this.type = type;
    this.fromJs = fromJs;
  }

  validate(value) {
    super.validate(value);

    // if no value is defined, it's fine (superclass checks for required)
    if (value == null) {
      return;
    }

    // if no type is defined, it's fine
    if (this.type == null) {
      return;
    }

    // special case some objects due to js weirdness around them
    if (this.type === String && _.isString(value)) {
      return;
    }
    if (this.type === Number && _.isNumber(value)) {
      return;
    }
    if (this.type === Boolean && _.isBoolean(value)) {
      return;
    }
    if (this.type === Function && _.isFunction(value)) {
      return;
    }

    // handle all other types
    if (!(value instanceof this.type)) {
      this.error(`${JSON.stringify(value)} must be an instance of ${this.type.name || this.type}`);
    }
  }

  valueFromJs(value) {
    if (this.fromJs) {
      return this.fromJs(value);
    } else if (this.type && this.type.fromJs) {
      return value && this.type.fromJs(value);
    }

    return super.valueFromJs(value);
  }
}

export class MultiTypedProperty extends Property {
  constructor({ oneOf, label, defaultValue, fromJs, isRequired }) {
    super({ label, defaultValue, isRequired, fromJs });
    this.fromJs = fromJs;
    this.oneOf = oneOf;
  }

  validate(value) {
    super.validate(value);

    if (value == null) {
      return;
    }

    if (_.isString(value) && _.includes(this.oneOf, String)) {
      return;
    }
    if (_.isString(value) && _.some(this.oneOf, o => typeof o === 'string')) {
      if (_.includes(this.oneOf, value)) {
        return;
      }
      this.error(`'${value}' must be one of: ${this.oneOf.map(o => `'${o}'`).join(', ')}`);
      return;
    }
    if (_.isNumber(value) && _.includes(this.oneOf, Number)) {
      return;
    }
    if (_.isBoolean(value) && _.includes(this.oneOf, Boolean)) {
      return;
    }
    if (_.isFunction(value) && _.includes(this.oneOf, Function)) {
      return;
    }

    if (!_.some(this.oneOf.map(type => value instanceof type))) {
      this.error(
        `${JSON.stringify(value)} must be an instance of one of: ${this.oneOf
          .map(type => `${type.name || type}`)
          .join(', ')}`
      );
    }
  }
}

export default function prop(type, opts = {}) {
  return new PropertyBuilder({
    type,
    defaultValue: opts.default,
    fromJs: opts.fromJs,
    isRequired: opts.required,
    label: opts.label,
    oneOf: opts.oneOf,
  });
}

_.extend(prop, {
  default(d) {
    return prop(undefined, { default: d });
  },
  get isRequired() {
    return prop(undefined, { required: true });
  },
  type(t) {
    return prop(t);
  },
  fromJs(fromJs) {
    return prop(undefined, { fromJs });
  },
  oneOf(...oneOf) {
    return prop(undefined, { oneOf });
  },
});
