import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default function TypingIndicatorAnimation({ className }) {
  return (
    <div className={classnames('typingIndicator', className)}>
      <span className="typingIndicator-dots" />
      <span className="typingIndicator-dots" />
      <span className="typingIndicator-dots" />
    </div>
  );
}

TypingIndicatorAnimation.propTypes = {
  className: PropTypes.string,
};
