import qconsole from 'scripts/lib/qconsole';

export default class ReactionsGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onAddError(error) {
    qconsole.error('error adding reaction', error);
  }
}
