import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export default function RemoveOptionIcon({ isFocused }) {
  const className = classnames({ 'hide-icon': !isFocused });

  return (
    <Icon className={className} data-aid="remove-option-icon">
      ×
    </Icon>
  );
}

RemoveOptionIcon.propTypes = {
  isFocused: PropTypes.bool,
};

const Icon = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.green400};
  border-radius: 50%;
  color: ${p => p.theme.colors.white};
  display: flex;
  height: 16px;
  justify-content: center;
  padding-bottom: 1px;
  width: 16px;
  &.hide-icon {
    visibility: hidden;
  }
`;
