/**
 * This is a helper class that tracks "events" within certain time window and determines whether
 * the event rate is excessive (over a certain number within the sliding interval)
 *
 * Sliding interval is in milliseconds
 */
export default class EventRateCounter {
  constructor(maxEventCount, slidingInterval) {
    this.eventTimes = [];
    this.excessive = false;
    this.maxEventCount = maxEventCount;
    this.timeWindow = slidingInterval;
  }

  get isExcessive() {
    return this.excessive;
  }

  countEvent() {
    const now = Date.now();
    this.eventTimes.push(now);
    this.eventTimes = this.eventTimes.slice(-this.maxEventCount);

    const count = this.eventTimes.length;
    const oldest = this.eventTimes[0];
    this.excessive = count === this.maxEventCount && now - oldest < this.timeWindow;
    return this.excessive;
  }

  reset() {
    this.eventTimes = [];
    this.excessive = false;
  }
}
