import PhoneCall from 'models/phone_call';

export default class MutePhoneCall {
  constructor(context) {
    this.context = context;
  }

  run(state) {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      const updatedStatus = state ? PhoneCall.ParticipantStatus.MUTE_REQUESTED : PhoneCall.ParticipantStatus.ACTIVE;
      activeCall.conversationItem.content.setParticipantStatus(this.currentAgentId, updatedStatus);
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.mute(activeCall.customer.id, activeCall.conversationItem.id, {
        participantId: this.currentAgentId,
        state,
      });
    }
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }
}
