import BeginLogIn from 'actions/user/begin_log_in';
import connect from 'components/lib/connect';
import NavigateToForgotPassword from 'actions/user/navigate_to_forgot_password';
import UserLogin from './user_login';

const mapStateToProps = function(context, props) {
  let provider = context.getProvider('userCredential');

  return {
    errors: provider.getErrors(),
    isResponsePending: provider.isPending(),
  };
};

const mapExecuteToProps = function(executeAction, props) {
  return {
    onLogin: (email, password) => executeAction(BeginLogIn, { email, password }),
    onForgotPassword: email => executeAction(NavigateToForgotPassword, email),
  };
};

export default connect(mapStateToProps, mapExecuteToProps)(UserLogin);
