import CallLegType from 'models/call_leg_type';
import { hasSipScheme } from 'models/phone_number';
import PhoneCall from 'models/phone_call';
import ServerClock from 'scripts/application/lib/server_clock';

export default class AddParticipantToPhoneCall {
  constructor(context) {
    this.context = context;
  }

  run({ participantId, participantType }) {
    const activeCall = this.context.stores.activeCall.get();
    const isSip = hasSipScheme(participantId);
    const callLegType = isSip ? CallLegType.STATION : CallLegType.DIRECT_DIAL;
    if (activeCall) {
      activeCall.conversationItem.content.addParticipant({
        participantId,
        status: PhoneCall.ParticipantStatus.DIALING,
        statusUpdatedAt: ServerClock.toISOString(),
        type: participantType,
        callLegType,
      });
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.addParticipant(activeCall.customer.id, activeCall.conversationItem.id, {
        communicationEndpointType: callLegType,
        participantId,
        type: participantType,
      });
    }
  }
}
