import _ from 'lodash';
import moment from 'moment';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import Snippet from 'models/answers/snippet';
import SnippetSearchResult from 'models/search/snippet_search_result';

const AnswersListType = createEnum('EMPTY', 'SEARCH', 'SUGGESTIONS', 'FAVORITES');
export { AnswersListType };

const AnswersSortType = createEnum('RELEVANCE', 'FREQUENCY');
export { AnswersSortType };

const HistoryItem = createModel({
  modelName: 'HistoryItem',
  properties: {
    answer: prop(Snippet).isRequired,
    language: prop(String).default('en-us').isRequired,
    type: prop(String).isRequired,
  },
});

const AnswerPanel = createModel({
  modelName: 'AnswerPanel',
  properties: {
    history: prop([HistoryItem]).default([]),
    lastOpenedAt: prop(String).default(''),
    loadingAnswerIds: prop([String]).default([]),
    searchLanguage: prop(String).default(''),
    searchAudiences: prop([String]).default([]),
    searchText: prop(String).default(''),
    searchResult: prop(SnippetSearchResult).default(null),
    selectedAnswer: prop(Snippet).default(null),
    selectedAnswerLanguage: prop(String).default('en-us'),
    selectedAnswerType: prop(String).default(null),
  },

  isOpen() {
    return !!this.lastOpenedAt;
  },

  open() {
    this.lastOpenedAt = moment().toISOString();
  },

  close() {
    this.lastOpenedAt = '';
  },

  selectAnswer(answer, type, language = 'en-us') {
    this.selectedAnswer = answer;
    this.selectedAnswerLanguage = language;
    this.selectedAnswerType = type;
  },

  clearAnswer() {
    this.selectedAnswer = null;
    this.seletectedAnswerLanguage = null;
    this.selectedAnswerType = null;
  },

  getSelectedAnswerId() {
    return this.selectedAnswer && this.selectedAnswer.id;
  },

  getSearchAudiences() {
    return this.searchAudiences;
  },

  addLoadingAnswerIds(loadingAnswerIds) {
    this.loadingAnswerIds = _.uniq(_.concat(this.loadingAnswerIds, loadingAnswerIds));
  },

  setLoadingAnswerIds(loadingAnswerIds) {
    this.loadingAnswerIds = loadingAnswerIds;
  },

  clearLoadingAnswerIds() {
    this.loadingAnswerIds = [];
  },

  pushSelectedOntoHistory() {
    this.history.push(
      new HistoryItem({
        answer: this.selectedAnswer,
        language: this.selectedAnswerLanguage,
        type: this.selectedAnswerType,
      })
    );
  },

  removeLastFromHistory() {
    this.history.pop();
  },

  clearHistory() {
    this.history = [];
  },

  setSortType(sortType) {
    this.sortType = sortType;
  },

  setSearchLanguage(language) {
    this.searchLanguage = language;
  },

  setSearchAudiences(audiences) {
    this.searchAudiences = audiences;
  },

  setSearchText(searchText) {
    this.searchText = searchText;
  },

  setSearchResult(snippetSearchResult) {
    this.searchResult = snippetSearchResult;
  },

  clearSearchResult() {
    this.searchResult = null;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    ListType: AnswersListType,
    SortType: AnswersSortType,
  },
});

export default AnswerPanel;
