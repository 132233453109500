import { buildSnippetRefs } from 'models/answers/snippet_helpers';
import ClearSelectedAnswer from 'actions/answers/clear_selected_answer';
import {
  getSuggestedReplyContext,
  getConversationContext,
  getSearchContext,
  snowplowTypes,
} from 'scripts/lib/analytics/domain/snowplow';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import SelectFirstAnswerShown from 'actions/answers/select_first_answer_shown';
import SuggestedReply from 'models/suggested_reply';

const MAX_SNIPPET_ID_COUNT = 5;
const MIN_WORD_COUNT_FOR_SUGGESTED_REPLIES = 4;
export default class RequestSuggestedReply {
  constructor(context) {
    this.context = context;
  }
  run({ id, text, snippets, searchLanguage, searchAudiences }) {
    if (!this.context.stores.appFeatures.get().isEnabled('suggestedReplies')) {
      return;
    }

    if (
      !text ||
      !id ||
      !snippets ||
      snippets.length === 0 ||
      this.wordCount(text) < MIN_WORD_COUNT_FOR_SUGGESTED_REPLIES
    ) {
      return;
    }

    const customerId = this.context.stores.currentLocation.get().customerId;
    if (!customerId) {
      return;
    }

    if (!this.context.stores.customers.has({ id: customerId })) {
      return;
    }

    const { suggestedReply, conversations } = this.context.stores.customers.storesFor(customerId);
    const conversationId = getActiveConversationId(conversations);
    if (!conversationId) {
      return;
    }

    if (searchLanguage !== '' && !searchLanguage.includes('en-')) {
      return;
    }

    const language = searchLanguage === '' ? 'en-us' : searchLanguage;

    const snippetRefs = buildSnippetRefs(snippets, language, searchAudiences, MAX_SNIPPET_ID_COUNT);
    if (!snippetRefs) {
      return;
    }

    this.context.analytics.trackSnowplow(
      snowplowTypes.EVENT_SUGGESTED_REPLY_REQUESTED,
      {},
      this.buildContexts(conversationId, customerId, id, text)
    );

    this.context.executeAction(ClearSelectedAnswer);
    suggestedReply.setLoading();
    this.context.gateways.suggestedReplies
      .add({}, { id, snippetRefs, input: text })
      .then(responseDto => {
        if (this.isRequestForCurrentSearchResults(id)) {
          const result = SuggestedReply.fromJs(responseDto);
          suggestedReply.set(result);
        }

        this.context.analytics.trackSnowplow(
          snowplowTypes.EVENT_SUGGESTED_REPLY_RESPONSE_RECEIVED,
          { wasGeneratedSuccessfully: true },
          this.buildContexts(conversationId, customerId, id, null)
        );
      })
      .catch(errorDto => {
        const { selectedAnswer } = this.context.stores.answerPanel.get();
        if (!selectedAnswer && this.isRequestForCurrentSearchResults(id)) {
          this.context.executeAction(SelectFirstAnswerShown);
        }

        this.context.analytics.trackSnowplow(
          snowplowTypes.EVENT_SUGGESTED_REPLY_RESPONSE_RECEIVED,
          { wasGeneratedSuccessfully: false },
          this.buildContexts(conversationId, customerId, id, null)
        );
      })
      .finally(() => {
        if (this.isRequestForCurrentSearchResults(id)) {
          suggestedReply.resetLoading();
        }
      });
  }

  isRequestForCurrentSearchResults(requestId) {
    const { searchResult } = this.context.stores.answerPanel.get();
    return requestId === searchResult?.id;
  }

  buildContexts(conversationId, customerId, id, text) {
    const contexts = [getConversationContext({ conversationId, customerId })];
    if (id && text) {
      contexts.push(getSearchContext({ id, query: { text } }));
    }
    contexts.push(getSuggestedReplyContext(id));
    return contexts;
  }

  wordCount(text) {
    if (!text) {
      return 0;
    }
    return text.trim().split(/\s+/).length;
  }
}
