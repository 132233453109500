import createModel from './lib/create_model';

const FbPageAccountSecretContent = createModel({
  modelName: 'FbPageAccountSecretContent',
  properties: {
    authToken: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default FbPageAccountSecretContent;
