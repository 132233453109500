import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import T from 'prop-types';

import { AgentAvailability } from 'models/agent_status';
import AwayStatusReasonSelector from './away_status_reason_selector';
import connect from 'components/lib/connect';
import ProgressBar from 'components/common/progress_bar';
import ReturnFromAway from 'actions/agent_status/return_from_away';
import SelectIdleReason from 'actions/agent_status/select_idle_reason';
import theme from 'scripts/presentation/themes/default';
import useInterval from 'components/hooks/use_interval';
import useRerender from 'components/hooks/use_rerender';
import UserAuthLayout from './lib/user_auth_layout';
import SurveyOrIllustration from './user_survey';

export const UserAwayPage = ({ awayReasonId, isReturning, wentAwayAt }) => {
  return (
    <ThemeProvider theme={theme}>
      <ProgressBar isVisible={isReturning} />
      <UserAuthLayout
        className="userAwayPage-container"
        displayLogo
        image={<SurveyOrIllustration />}
        layoutClassName="userAwayPage"
        title={<TimeAway timestamp={wentAwayAt} />}
      >
        <AwayStatusReasonSelector awayReasonId={awayReasonId} isReturning={isReturning} />
      </UserAuthLayout>
    </ThemeProvider>
  );
};

UserAwayPage.propTypes = {
  awayReasonId: T.string,
  isReturning: T.bool,
  wentAwayAt: T.string.isRequired,
};

function TimeAway({ timestamp }) {
  const { hours, minutes, seconds, isGreaterThan24h } = useTimeSince(timestamp);

  if (isGreaterThan24h) {
    return <Pill>&gt;24 hours</Pill>;
  }

  return (
    <TimeAwayContainer>
      <Description>Time Away</Description>

      <Pill>
        <Num>{hours}</Num>
        <Separator />
        <Num>{minutes}</Num>
        <Separator />
        <Num>{seconds}</Num>
      </Pill>
    </TimeAwayContainer>
  );
}

TimeAway.propTypes = {
  timestamp: T.string.isRequired,
};

function useTimeSince(availabilityChangedAt) {
  const startedAt = moment(availabilityChangedAt);
  const diff = moment().diff(startedAt);
  const duration = moment.duration(diff);

  const rerender = useRerender();
  useInterval(rerender, 1000);

  if (diff < 0) {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      isGreaterThan24h: false,
    };
  }

  const durationAsMs = moment.utc(duration.as('milliseconds'));

  return {
    hours: durationAsMs.format('HH'),
    minutes: durationAsMs.format('mm'),
    seconds: durationAsMs.format('ss'),
    isGreaterThan24h: duration.asHours() >= 24,
  };
}

const TimeAwayContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const Description = styled.span`
  font-size: ${p => p.theme.fontSize.base};
  font-weight: normal;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const Pill = styled.div`
  background-color: ${p => p.theme.colors.white};
  border-radius: 100px;
  display: flex;
  font-variant-numeric: tabular-nums;
  justify-content: center;
  padding: 8px;
  text-align: center;
  width: 140px;
`;

const Num = styled.div`
  width: 38px;
`;

const Separator = styled.div.attrs({ children: ':' })`
  margin-top: -1px;
  width: 10px;
`;

const mapStateToProps = context => {
  const agentStatus = context.getProvider('agentStatus').get();
  const awayReasonId = _.get(agentStatus, 'status.reasonId');
  const isReturning =
    _(context.getProvider('agentStatus').getPending()).get('status.availability') === AgentAvailability.BUSY;

  return {
    awayReasonId,
    isReturning,
    wentAwayAt: agentStatus.availabilityUpdatedAt,
  };
};

const mapExecuteToProps = executeAction => {
  return {
    onReturnClick: awayReasonId => {
      if (awayReasonId) {
        executeAction(ReturnFromAway);
      } else {
        executeAction(SelectIdleReason);
      }
    },
  };
};

export default connect(mapStateToProps, mapExecuteToProps)(UserAwayPage);
