import { Factory } from 'rosie';

Factory.define('CustomerAssignee');

Factory.define('CustomerAssigneeWithDefaults')
  .extend('CustomerAssignee')
  .attr('id', 'exampleCustomerId')
  .attr('agentId', 'exampleAgentId')
  .attr('routingGroupId', 'exampleRoutingId')
  .attr('_version', 1);
