import classnames from 'classnames';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { findEntities } from './draft_entity';
import linkifyIt, { isUrlValid } from 'components/lib/linkify_it';

const DraftLink = props => {
  const entity = props.contentState.getEntity(props.entityKey);
  const url = entity.getData().url;

  const classNames = classnames('draftEditor-link', `draftEditor-link-${props.entityKey}`, {
    'draftEditor-link-invalid': url && !isUrlValid(url),
    'draftEditor-link-empty': !url,
  });
  return <span className={classNames}>{props.children}</span>;
};

DraftLink.propTypes = {
  contentState: PropTypes.instanceOf(ContentState),
  children: PropTypes.node,
  entityKey: PropTypes.string.isRequired,
};
export default DraftLink;

// Note: Try not to change these - these need to match up with the classNames in the components above,
//       which are then used in DraftEditor to determine that entity's pixel position on screen.
export const ENTITY_TYPE_LINK = 'LINK';

export const findLinkEntities = findEntities(ENTITY_TYPE_LINK);

export function linkifyInlineLinks(editorState) {
  let blockMap = editorState.getCurrentContent().getBlockMap();

  blockMap.forEach(contentBlock => {
    let text = contentBlock.getText();
    let matches = linkifyIt.match(text);

    if (matches) {
      matches.forEach(match => {
        let selection = new SelectionState({
          anchorKey: contentBlock.getKey(),
          anchorOffset: match.index,
          focusKey: contentBlock.getKey(),
          focusOffset: match.lastIndex,
          isBackward: false,
        });

        if (hasNoExistingEntities(match, contentBlock)) {
          editorState = createLink(editorState, match.url, selection);
        }
      });
    }
  });
  return editorState;
}

export function hasNoExistingEntities(match, contentBlock) {
  return _.every(_.map(_.range(match.index, match.lastIndex), i => !contentBlock.getEntityAt(i)));
}

export function createNewLink(editorState) {
  let selection = editorState.getSelection();
  if (selection.isCollapsed()) {
    return editorState;
  }

  // No multi-link links allowed - too confusing. Use the first block's selection as basis.
  if (selection.getStartKey() !== selection.getEndKey()) {
    let focusOffset = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getLength();

    selection = selection.merge({
      anchorKey: selection.getStartKey(),
      anchorOffset: selection.getStartOffset(),
      focusKey: selection.getStartKey(),
      focusOffset,
      isBackward: false,
    });
  }

  let contentStateWithEntity = editorState.getCurrentContent().createEntity(ENTITY_TYPE_LINK, 'IMMUTABLE');
  contentStateWithEntity = Modifier.applyEntity(
    contentStateWithEntity,
    selection,
    contentStateWithEntity.getLastCreatedEntityKey()
  );
  return EditorState.push(editorState, contentStateWithEntity, 'insert-characters');
}

export function createLink(editorState, url, selection) {
  if (!url) {
    return editorState;
  }

  let contentState = editorState.getCurrentContent();
  let contentStateWithEntity = contentState.createEntity(ENTITY_TYPE_LINK, 'MUTABLE', { url });

  selection = selection || editorState.getSelection();

  contentStateWithEntity = Modifier.applyEntity(
    contentStateWithEntity,
    selection,
    contentStateWithEntity.getLastCreatedEntityKey()
  );
  const linked = EditorState.push(editorState, contentStateWithEntity, 'apply-entity');

  let collapsed = selection.merge({
    anchorOffset: selection.getEndOffset(),
    focusOffset: selection.getEndOffset(),
  });

  return EditorState.forceSelection(linked, collapsed);
}

/**
 * Changes the data URL of the given entity
 */
export function changeLink(editorState, entityKey, url) {
  const contentState = editorState.getCurrentContent();
  contentState.mergeEntityData(entityKey, { url });
  return editorState;
}
