import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import {
  AnswerPanelControl,
  BoldItalicUnderlineControls,
  ListControls,
  Separator,
} from 'components/composer/shared/editor_controls_new';
import { EditorContainer } from 'components/composer/shared/editor_new';
import { LinkButton } from 'components/composer/inline_links/link_button';
import { LinkEditor } from 'components/composer/inline_links/link_editor';
import AddTask from 'actions/composition/add_task';
import AssigneeSelector from 'components/composer/shared/assignee_selector';
import Composer, { Header, NewHeaderText } from './shared/composer_new';
import ComposerEditor from 'components/composer/shared/composer_editor';
import DatePicker from 'components/composer/shared/date_picker';
import EditorErrors from 'components/composer/shared/editor_errors';
import EditTask from 'actions/composer/edit_task';
import FontColorControls from 'components/composer/shared/font_color_controls';
import { isMacOS } from 'scripts/lib/browser_detector';
import TextAlignmentMenu from 'components/composer/shared/editor_alignment_controls_new';
import useComposer from 'components/composer/shared/use_composer';
import useEditor from 'components/composer/shared/use_editor';
import useRichTextEditorPlugins from 'components/text_editor_new/hooks/use_rich_text_editor_plugins';
import serializeHtmlExternal from 'components/text_editor_new/lib/serialize_html_external';
import { useSubmitOnHotkey } from 'components/composer/shared/use_submit_on_hotkey';

const LeftWrapper = styled.div`
  display: flex;
`;

const submitTextForCreateOrEdit = isEditing => (isEditing ? 'Save Task' : 'Add Task');
const headerTextForCreateOrEdit = isEditing => (isEditing ? 'Edit Task' : 'Create Task');
const taskForCreateOrEdit = isEditing => (isEditing ? EditTask : AddTask);

export function TaskComposer({ isEditing }) {
  const composerProps = useComposer({
    action: taskForCreateOrEdit(isEditing),
    options: { trimEmptyParagraphs: true, trimParagraphWhitespace: true },
  });
  const editorProps = useEditor('bodyHtml', composerProps, { serializer: serializeHtmlExternal });
  const editorRef = useRichTextEditorPlugins(composerProps, editorProps);

  // Register keyboard hotkeys that result in submitting the composition. For historic reason, we use both Meta+Enter
  // and Ctrl+Enter on Mac and just Ctrl+Enter on Windows
  const keyboardHotkeys = ['Mod+Enter'];
  if (isMacOS()) keyboardHotkeys.push('Control+Enter');
  useSubmitOnHotkey(editorRef.current, editorProps.onSubmit, keyboardHotkeys);

  return (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      excludeErrors
      includeAttachments
      initialHtml={editorProps.initialHtml}
      submitText={submitTextForCreateOrEdit(isEditing)}
      {...composerProps}
    >
      <Header>
        <LeftWrapper>
          <NewHeaderText>{headerTextForCreateOrEdit(isEditing)}</NewHeaderText>
          <DatePicker />
        </LeftWrapper>
        <AssigneeSelector />
      </Header>

      <EditorContainer excludeAnswerMenuButton includeAttachments>
        <ComposerEditor placeholder="Type to enter task description" {...editorProps} />
        <LinkEditor hotKey="mod+k" />
      </EditorContainer>

      <ActionBar>
        <>
          <AnswerPanelControl />
          <Separator />
        </>
        <BoldItalicUnderlineControls {...editorProps} />
        <FontColorControls {...editorProps} />
        <TextAlignmentMenu {...editorProps} />
        <ListControls {...editorProps} />
        <>
          <Separator />
          <LinkButton />
        </>
        <Emoji {...editorProps} />
        <Attachment />
      </ActionBar>

      <EditorErrors />
    </Composer>
  );
}

TaskComposer.propTypes = {
  isEditing: PropTypes.bool,
};

export default React.memo(TaskComposer);
