import createModel, { prop } from 'scripts/domain/models/lib/create_model';

const IvrNodeConfigModel = createModel({
  modelName: 'IvrNodeConfigModel',

  properties: {
    id: prop(String).isRequired,
    description: prop(String),
  },
});

const IvrNodeModel = createModel({
  modelName: 'IvrNodeModel',

  properties: {
    type: prop(String).isRequired,
    ivrNode: prop(IvrNodeConfigModel).isRequired,
  },
});

export default createModel({
  modelName: 'Ivr',

  properties: {
    id: prop(String).isRequired,
    debug: prop(Boolean),
    description: prop(String),
    name: prop(String),
    nodes: prop([IvrNodeModel]),
    rootNodeId: prop(String),
    version: prop(Number).isRequired,
  },
});
