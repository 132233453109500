import _ from 'lodash';
import createReactClass from 'create-react-class';
import { EditorState } from 'draft-js';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActionMixin from 'components/lib/action_mixin';
import AnswerPanelIcon from 'components/lib/icons/answer_panel_icon_with_circle';
import ClearCurrentComposition from 'actions/composition/close_current_composition';
import ClearSelectedKbItem from 'actions/composition/clear_selected_kb_item';
import CloseAnswerPanel from 'actions/customer/close_answer_panel';
import CompositionContentType from 'models/composition/composition_content_type';
import CompositionText from './draft_composition_text';
import { formatEditorState } from './draft/format_editor_state';
import { insertSnippet } from './draft/draft_snippet';
import KeyPressShortcutMixin from 'components/lib/keypress_shortcut_mixin';
import KnowledgeBaseDecorator from './knowledge_base_decorator';
import OpenAnswerPanel from 'actions/answers/open_answer_panel';
import RemoveEmptyCompositions from 'actions/composition/remove_empty_compositions';
import Tooltip from 'components/common/tooltip.jsx';
import UpdateComposition from 'actions/composition/update_composition';

const AUTOSAVE_COMPOSITION_INTERVAL = 5000; // ms

function createCompositionFormBase(spec) {
  return createReactClass(
    _.extend(
      {
        mixins: [ActionMixin, new KeyPressShortcutMixin()].concat(spec.mixins || []),

        propTypes: _.merge(
          {
            composition: PropTypes.object.isRequired,
            compositionErrors: PropTypes.arrayOf(PropTypes.object),
            onCancel: PropTypes.func,
            onSubmit: PropTypes.func,
            mentionMenuData: PropTypes.array.isRequired,
            selectedKbItem: PropTypes.object,
            selectedKbItemChannelType: PropTypes.string,
            toggleAnswerPanel: PropTypes.func,
            variables: PropTypes.object.isRequired,
          },
          spec.propTypes
        ),

        renderAnswerMenuButton() {
          return (
            <TooltipWrapper>
              <Tooltip className="compositionForm-mentionButton-tooltip" message="Answer Panel" position="bottom">
                <div className="answerPanelIcon-button-container" onClick={this.onToggleAnswerPanel}>
                  <AnswerPanelIcon className="answerPanelIcon-button" />
                </div>
              </Tooltip>
            </TooltipWrapper>
          );
        },

        render() {
          throw new Error('concrete implementations must defined `render`');
        },

        /* Lifecycle */

        // Specs should use state to hold non-DraftJS composition state.. define this in that case
        getInitialState() {
          const specState = spec.getInitialState ? spec.getInitialState.call(this) : {};
          return _.assign(specState, {
            currentCompositionId: this.props.composition.id,
          });
        },

        getEditorState() {
          return this.props.editorState || this.state.editorState;
        },

        UNSAFE_componentWillMount() {
          const interval = this.autosaveInterval || AUTOSAVE_COMPOSITION_INTERVAL;
          this.onChange = _.throttle(this.onChange, interval);
          spec.UNSAFE_componentWillMount && spec.UNSAFE_componentWillMount.call(this);

          spec.loadComposition.call(this, this.props.composition, this.props.compositionErrors);
        },

        componentDidMount() {
          this.props.onMount && this.props.onMount();
        },

        UNSAFE_componentWillReceiveProps(nextProps) {
          const currentId = this.state.currentCompositionId;
          const hasCompositionChanged = nextProps.composition.id !== currentId;
          if (hasCompositionChanged && currentId) {
            this.saveCurrentDraft();
          }
          if (hasCompositionChanged) {
            this.loadComposition(nextProps.composition, nextProps.compositionErrors);
          }

          if (_.isFunction(spec.UNSAFE_componentWillReceiveProps)) {
            spec.UNSAFE_componentWillReceiveProps.call(this, nextProps);
          }
        },

        componentDidUpdate(prevProps, prevState) {
          if (prevProps.isMinimized && !this.props.isMinimized) {
            this.focusCompositionEditor();
          }
          if (this.focusProperty) {
            if (this.focusSelection) {
              let newEditorState = EditorState.forceSelection(this.getEditorState(), this.focusSelection);
              this.onEditorChange(newEditorState);
              this.focusSelection = null;
            } else {
              let isChatComposition = this.props.composition.contentType() === CompositionContentType.CHAT;
              if (!isChatComposition) {
                this[this.focusProperty].focusAtEnd();
              }
            }
            this.focusProperty = null;
          }

          spec.componentDidUpdate && spec.componentDidUpdate.call(this, prevProps, prevState);
        },

        componentWillUnmount() {
          // If we cancelled the composition, then it won't have a currentCompositionId, so we won't save it.
          if (this.state.currentCompositionId) {
            this.saveCurrentDraft();
            this.onChange.cancel();
            this.executeAction(RemoveEmptyCompositions, this.props.composition.customerId);
          }
        },

        /* Handlers */

        clearSelectedKbItem() {
          this.executeAction(ClearSelectedKbItem);
        },

        getDecoratedKbVariable(item) {
          return _.find(this.props.mentionMenuData, { id: item.id });
        },

        onEditorChange(editorState) {
          const oldEditorState = this.getEditorState();
          let newEditorState = formatEditorState(editorState, oldEditorState);

          if (this.onBodyChange) {
            this.onBodyChange(newEditorState);
          }
        },

        onBlur() {
          this.saveCurrentDraft();
        },

        onCancel(event) {
          event.preventDefault();
          this.executeAction(ClearCurrentComposition);

          this.onChange.cancel(); // Ensures that we don't try to later save a draft we just cancelled
          this.setState({ currentCompositionId: null });
          if (this.props.onCancel) {
            this.props.onCancel();
          }
        },

        onChange() {
          this.saveCurrentDraft();
        },

        // Define this to submit the current form data as a new conversation item
        onSubmit(event, forceSubmit = false) {
          event.preventDefault(); // prevents form submission
          if (!spec.onSubmit) {
            throw new Error('concrete implementations must define `onSubmit`');
          }
          const specSubmit = spec.onSubmit.call(this, forceSubmit);

          this.onChange.cancel(); // Ensures that we don't try to later save a draft we just submitted
          this.setState({ currentCompositionId: null });

          if (this.props.onSubmit && specSubmit !== false) {
            this.props.onSubmit();
          }
        },

        handleKeyDown(e) {
          let attrs = {
            customerId: this.props.composition.customerId,
            conversationId: this.props.composition.conversationId,
            compositionId: this.props.composition.id,
          };

          // ctrl or cmd & / opens the answers menu
          if (e.keyCode === 191 && (e.metaKey || e.ctrlKey)) {
            this.onOpenAnswerPanel(attrs);
          }
          // esc key closes the answer panel
          if (e.keyCode === 27) {
            this.onCloseAnswerPanel(attrs);
          }
        },

        onToggleAnswerPanel() {
          this.props.toggleAnswerPanel({
            customerId: this.props.composition.customerId,
            conversationId: this.props.composition.conversationId,
            compositionId: this.props.composition.id,
          });
        },

        onOpenAnswerPanel(attrs) {
          this.executeAction(OpenAnswerPanel, attrs);
        },

        onCloseAnswerPanel(attrs) {
          this.executeAction(CloseAnswerPanel, attrs);
        },

        /* Composition Methods */

        focusCompositionEditor() {
          if (this.focusEditor) {
            this[this.focusEditor].focus();
          }
        },

        saveCurrentDraft() {
          const newContent = this.getCompositionContent();
          const compositionId = this.props.composition.id;
          this.executeAction(UpdateComposition, {
            compositionId,
            newContent,
            customerId: this.props.composition.customerId,
          });
        },

        // Override this to return Composition content from the current form data
        getCompositionContent() {
          throw new Error('concrete implementations must define `getCompositionContent`');
        },

        // Define this to take the Composition you saved from getCompositionContent and load it into the form for editing
        loadComposition(composition, compositionErrors) {
          if (!spec.loadComposition) {
            throw new Error('concrete implementations must define `loadComposition`');
          }
          this.setState({ currentCompositionId: composition.id });
          spec.loadComposition.call(this, composition, compositionErrors);
        },

        /* Helpers */

        hasAttachments() {
          return this.props.composition.hasAttachments();
        },
        getNewEditorState() {
          return CompositionText.getNewEditorState();
        },
        insertSnippet(editorState, snippetText, richTextEnabled) {
          return insertSnippet(editorState, snippetText, this.props.variables, richTextEnabled);
        },
      },
      _.omit(
        spec,
        'UNSAFE_componentWillReceiveProps',
        'getInitialState',
        'loadComposition',
        'mixins',
        'onCancel',
        'onSubmit',
        'componentDidUpdate',
        'componentWillMount'
      )
    )
  );
}

const TooltipWrapper = styled.div`
  position: absolute;
  margin-left: -28px;
  margin-top: 4px;
`;

function getFormattedPhoneNumbersWithTooltip(fromNumbers, fromNumber) {
  let tooltipMessage = '';
  let fromOptions = _.map(fromNumbers, from => {
    const label = from.label ? `${from.label} (${from.number})` : from.number;
    if (from.number === fromNumber && label !== from.number) {
      tooltipMessage = label;
    }
    return {
      value: from.number,
      label,
    };
  });
  return { fromOptions, tooltipMessage };
}

export default function createCompositionForm(spec) {
  return KnowledgeBaseDecorator(createCompositionFormBase(spec), { useVariables: true });
}

export { createCompositionFormBase, getFormattedPhoneNumbersWithTooltip };
