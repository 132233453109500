import { AgentInboxPollId } from './agent_inbox_constants';

export default class CancelAgentInboxPolling {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.scheduler.cancel(AgentInboxPollId);
  }
}
