import { last, sortBy } from 'lodash';

import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const GreetingSuggestion = createModel({
  modelName: 'GreetingSuggestion',
  properties: {
    id: prop(String).isRequired,
    text: prop(String).isRequired,
    score: prop(Number).default(1), // count of how often the greeting is used
  },

  incrementScore() {
    this.score += 1;
  },
  statics: {
    create(attrs) {
      return new this({
        id: IdGenerator.newId(),
        ...attrs,
      });
    },
  },
});

export function getTopGreetingSuggestion(suggestions) {
  return last(sortBy(suggestions, s => s.score));
}

export default GreetingSuggestion;
