import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import ConversationMessage, { MessageChannelType } from 'models/conversation_message';
import ConversationMessageAddressField from 'components/composer/conversation_message_address_field';
import SendComposerConversationMessage from 'actions/composer/send_composer_conversation_message';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import AITextRewriteControls from 'components/text_editor_new/components/controls/ai_text_rewrite_controls';
import { AnswerPanelControl, Separator } from 'components/composer/shared/editor_controls_new';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import CharacterCount from 'components/text_editor_new/character_count';
import Composer, { Header, NewHeaderText } from 'components/composer/shared/composer_new';
import ComposerContext from 'components/composer/contexts/composer_context';
import ComposerEditor from 'components/composer/shared/composer_editor';
import { EditorContainer } from 'components/composer/shared/editor_new';
import EditorErrors from 'components/composer/shared/editor_errors';
import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';
import useAIComposerOptions from 'components/text_editor_new/plugins/ai/ai_text_completion/use_ai_composer_options';
import useComposer from 'components/composer/shared/use_composer';
import { useCreatePasteAttachmentsPlugin } from 'components/text_editor_new/plugins/create_paste_attachments_plugin';
import useEditor from 'components/composer/shared/use_editor';
import useMessagingEditorPlugins from 'components/text_editor_new/hooks/use_messaging_editor_plugins';
import withAITextRewriting from 'components/composer/with_ai_text_rewriting';

const ChannelToProps = Object.freeze({
  [MessageChannelType.INSTAGRAM_DIRECT]: {
    allowedFileTypes: 'image/jpeg, image/png, image/gif',
    alwaysShowCharacterCount: true,
    maxCharacters: ConversationMessage.MAX_INSTAGRAM_MESSAGE_SIZE,
  },
  [MessageChannelType.TWITTER]: {
    allowedFileTypes: 'image/jpeg, image/png, image/gif',
    maxCharacters: ConversationMessage.MAX_CONVERSATION_MESSAGE_SIZE,
  },
  [MessageChannelType.WHATSAPP]: {
    allowedFileTypes: 'image/jpeg, image/png',
    alwaysShowCharacterCount: true,
    maxCharacters: ConversationMessage.MAX_WHATSAPP_MESSAGE_SIZE,
  },
});

const DEFAULT_CHANNEL_PROPS = Object.freeze({
  allowedFileTypes: null,
  alwaysShowCharacterCount: false,
});

export function ConversationMessageComposer({ requestorId }) {
  const { composition } = useContext(ComposerContext);
  const channel = composition.content.channel;
  const channelProps = ChannelToProps[channel] || DEFAULT_CHANNEL_PROPS;

  return <MessageComposerForChannel requestorId={requestorId} {...channelProps} />;
}

ConversationMessageComposer.propTypes = {
  requestorId: PropTypes.string,
};

export function MessageComposerForChannel({
  allowedFileTypes,
  alwaysShowCharacterCount,
  maxCharacterCount,
  requestorId,
}) {
  const { composition } = useContext(ComposerContext);
  const {
    customerAddress,
    customerDisplayName,
    companyAddress,
    companyDisplayName,
    channel,
    channelDisplayTitle,
  } = getPropertiesFromComposition(composition);

  const includeAttachments = !!allowedFileTypes;
  const composerProps = useComposer({ action: SendComposerConversationMessage });
  const editorProps = useEditor('bodyHtml', composerProps);
  const extraPlugins = useExtraPlugins(includeAttachments);
  const editorRef = useMessagingEditorPlugins(composerProps, editorProps, extraPlugins);
  const { disableSubmit, readOnly } = useAIComposerOptions(isOverCharacterLimit());
  editorProps.onSubmit = disableSubmit ? _.noop : editorProps.onSubmit;

  return (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      excludeErrors
      initialHtml={editorProps.initialHtml}
      submitText="Send"
      {...composerProps}
      disableSubmit={disableSubmit}
      includeAttachments={includeAttachments}
    >
      <Header>
        <Left>
          <NewHeaderText>{_.startCase(_.toLower(channelDisplayTitle || channel))}</NewHeaderText>
          <div className="conversationMessageComposer-address-label">To</div>
          <ConversationMessageAddressField address={customerDisplayName || customerAddress} />
        </Left>
        <Right>
          <From>
            <div className="conversationMessageComposer-address-label">From</div>
            <div className="conversationMessageComposer-from-container">
              <ConversationMessageAddressField address={companyDisplayName || companyAddress} />
            </div>
          </From>
        </Right>
      </Header>
      <EditorContainer excludeAnswerMenuButton includeAttachments={includeAttachments}>
        <ComposerEditor
          key={composition.id}
          placeholder="Type to reply"
          readOnly={readOnly}
          requestorId={requestorId}
          {...editorProps}
        />
      </EditorContainer>

      <MessagingActions>
        <ActionBar>
          <AnswerPanelControl />
          <Separator />
          <AITextRewriteControls />
          <Emoji {...editorProps} />
          {renderAttachmentButton()}
        </ActionBar>
        {renderCharacterCount()}
      </MessagingActions>

      <EditorErrors />
    </Composer>
  );

  function isOverCharacterLimit() {
    let characterCount = (editorRef.current ? serializePlaintext(editorRef.current) : '').length;
    if (maxCharacterCount != null) {
      return characterCount > maxCharacterCount;
    }
    return false;
  }

  function renderCharacterCount() {
    if (!isOverCharacterLimit() && !alwaysShowCharacterCount) {
      return null;
    }

    return <StyledCharacterCount composerRef={composerProps.composerRef} maxCharacters={maxCharacterCount} />;
  }

  function renderAttachmentButton() {
    if (!includeAttachments) {
      return null;
    }
    return <Attachment allowedFileTypes={allowedFileTypes} alwaysShow />;
  }
}

MessageComposerForChannel.propTypes = {
  allowedFileTypes: PropTypes.string,
  alwaysShowCharacterCount: PropTypes.bool,
  maxCharacterCount: PropTypes.number,
  requestorId: PropTypes.string,
};

function getPropertiesFromComposition(composition) {
  return {
    customerAddress: composition.content.customerAddress,
    customerDisplayName: composition.content.customerDisplayName,
    companyAddress: composition.content.companyAddress,
    companyDisplayName: composition.content.companyDisplayName,
    channel: composition.content.channel,
    channelDisplayTitle: composition.content.channelDisplayTitle,
  };
}

function useExtraPlugins(includeAttachments) {
  const createPasteAttachmentsPlugin = useCreatePasteAttachmentsPlugin();

  return useMemo(() => {
    return includeAttachments ? [createPasteAttachmentsPlugin()] : [];
  }, [includeAttachments, createPasteAttachmentsPlugin]);
}

const StyledCharacterCount = styled(CharacterCount)`
  background-color: inherit;
  position: relative;
`;

const MessagingActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;
const Right = styled.div`
  align-items: center;
  display: flex;
`;
const From = styled.div`
  align-items: center;
  display: flex;
  margin-left: 8px;
`;

export default React.memo(withAITextRewriting(ConversationMessageComposer));
