import MqttConnectionEventHandler from 'actions/health/mqtt_connection_event_handler';
import UnauthorizedEventHandler from 'actions/user/unauthorized_event_handler';

export default function(context) {
  var connectionEventHandler = new MqttConnectionEventHandler(context);
  var unauthorizedEventHandler = new UnauthorizedEventHandler(context);

  return [
    { event: 'unauthorized', handler: () => unauthorizedEventHandler.handleUnauthorized() },
    { event: 'disconnect', handler: () => unauthorizedEventHandler.handleDisconnect() },
    { event: 'disconnect', handler: () => connectionEventHandler.handleDisconnect() },
    { event: 'connect', handler: () => connectionEventHandler.handleConnect() },
    { event: 'reset', handler: () => connectionEventHandler.handleReset() },
  ];
}
