import _ from 'lodash';
import moment from 'moment';

import createModel, { prop } from './lib/create_model';

const PendingRequest = createModel({
  modelName: 'PendingRequest',

  properties: {
    conversationId: prop(String).isRequired,
    requestIds: prop([String]).default([]),
  },

  addRequestId(requestId) {
    this.requestIds = _.concat(this.requestIds, requestId);
  },

  hasRequestId(requestId) {
    return this.requestIds.indexOf(requestId) !== -1;
  },

  removeRequestId(requestId) {
    this.requestIds = _.without(this.requestIds, requestId);
  },

  statics: {
    create(attrs) {
      return new this(_.assign({}, attrs));
    },
  },
});

export default createModel({
  modelName: 'ConversationWorkflow',

  properties: {
    isNavigatingNextConversation: Boolean,
    topicsLastOpenedAt: String,
    pendingRequests: prop([PendingRequest]).default([]),
  },

  updateTopicsLastOpenedAt() {
    this.topicsLastOpenedAt = moment().toISOString();
  },

  setNavigatingNextConversation() {
    this.isNavigatingNextConversation = true;
  },

  clearNavigatingNextConversation() {
    this.isNavigatingNextConversation = false;
  },

  addPendingRequest(conversationId, requestId) {
    let pendingRequest = this.getPendingRequest(conversationId);
    if (pendingRequest) {
      pendingRequest.addRequestId(requestId);
      return;
    }

    this.pendingRequests.push(PendingRequest.create({ conversationId, requestIds: [requestId] }));
  },

  clearPendingRequest(requestId) {
    let pendingRequest = _.find(
      this.pendingRequests,
      pendingRequest => pendingRequest.requestIds.indexOf(requestId) !== -1
    );
    if (pendingRequest) {
      pendingRequest.removeRequestId(requestId);
      if (_(pendingRequest.requestIds).isEmpty()) {
        _.remove(this.pendingRequests, pendingRequest);
      }
    }
  },

  getPendingRequest(conversationId) {
    return _.find(this.pendingRequests, { conversationId });
  },

  hasPendingRequest(conversationId) {
    return _.some(this.pendingRequests, { conversationId });
  },

  statics: {
    create(attrs = {}) {
      return new this(attrs);
    },
  },
});
