import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AgentMetadata from '../metadata/agent_metadata';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import RoutingPhrasesContainer from '../routing/routing_phrases';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import UpdateAgentTaskRead from 'actions/task/update_agent_task_read';

export default function TaskRoutingItem({ item }) {
  return (
    <ItemContent className="taskRoutingItem" isAuditItem item={item}>
      <AgentMetadata
        iconClass="icon-task"
        item={item}
        prepositionalPhrases={renderRoutingPhrases()}
        subject={renderTaskLink()}
        text="routed"
      />
    </ItemContent>
  );

  function renderRoutingPhrases() {
    return <RoutingPhrasesContainer initiatorId={_.get(item, 'initiator.id')} routingContent={item.content} />;
  }

  function renderTaskLink() {
    return <TaskLink taskItemId={item.content.taskItemId} />;
  }
}

TaskRoutingItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function TaskLinkBase({ className, onClick }) {
  return (
    <a className={classnames(className, 'taskRoutingItem-link')} onClick={onClick}>
      Task
    </a>
  );
}

const TaskLink = connect(undefined, mapExecuteToProps)(TaskLinkBase);
export { TaskLink };

function mapExecuteToProps(executeAction, { taskItemId }) {
  return {
    onClick: () => {
      executeAction(NavigateToItem, { itemId: taskItemId });
      executeAction(UpdateAgentTaskRead, taskItemId);
    },
  };
}

TaskLink.propTypes = {
  taskItemId: PropTypes.string.isRequired,
};
