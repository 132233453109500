import moment from 'moment';

import DismissNotification from 'actions/notification/dismiss_notification';
import EmbedTokenExpiringNotification, {
  embedTokenExpiringNotificationTimerDelaySeconds,
} from 'models/notification/embed_token_expiring_notification';

export default class ShowEmbedTokenExpiringNotification {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    const now = moment();
    const notification = EmbedTokenExpiringNotification.create({ id, now: now.toISOString() });
    this.context.stores.notifications.addOrReplace(notification);
    DismissNotification.schedule(this.context, {
      id: notification.id,
      timeout: embedTokenExpiringNotificationTimerDelaySeconds * 1000,
    });
  }
}
