import rerequestCustomer from 'actions/customer/rerequest_customer';

export default class HandleCustomerReconnect {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.customers.markAllCustomersAsStale();

    const { customerId } = this.context.stores.currentLocation.get();
    if (!customerId || !this.context.stores.customers.has({ id: customerId })) {
      return;
    }

    rerequestCustomer(this.context, customerId);
  }
}
