import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ConversationItemMenuDisplayer from '../menu/conversation_item_menu_displayer';
import { getMessageGroupId } from '../menu/conversation_item_menu_container';
import HighlightGroupedMessages from 'actions/composition/highlight_grouped_messages';
import PinnedCustomerList from 'components/customer/conversation_history/conversation_items_v2/content/pinned_customer_list';
import { SizeContext } from 'components/customer/conversation_history/feed/size_provider';
import { useCurrentAgent } from 'components/contexts/current_agent';

const FULL_WIDTH_CUTOFF = 720;

export function BubbleContent({
  children,
  className,
  'data-aid': dataAid,
  hasReaction,
  isCollapsed,
  isHighlighted,
  isSomeGroupHighlighted,
  item,
  onClick = _.noop,
  onRef,
  onUnselectHighlightGroupedMessages,
  status,
}) {
  const { width } = useContext(SizeContext);
  const { id: currentAgentId } = useCurrentAgent();
  const [shouldShowOverflowMenu, setShouldShowOverflowMenu] = useState(false);

  if (isCollapsed) {
    return null;
  }

  const hasCommError = item.content.hasErrorStatus && item.content.hasErrorStatus();
  const isInbound = item.isInbound();
  const includeStatus = !!status;

  const classNames = classnames(
    'bubbleContent',
    {
      'bubbleContent-error': hasCommError,
      'bubbleContent-inbound': isInbound,
      'bubbleContent-outbound': !isInbound,
      'bubbleContent-withChin': item.linkedItems.length > 0,
      'bubbleContent-withReaction': hasReaction,
      'bubbleContent-highlighted': isHighlighted,
      [`status-${status}`]: includeStatus,
    },
    'fs-exclude',
    className
  );

  const handleClick = e => {
    if (!isHighlighted && isSomeGroupHighlighted) {
      onUnselectHighlightGroupedMessages();
    }

    setShouldShowOverflowMenu(false);

    onClick && onClick(e);
  };

  const content = (
    <StyledContent
      $currentAgentId={currentAgentId}
      className={classNames}
      data-aid={dataAid}
      onClick={handleClick}
      onMouseEnter={() => setShouldShowOverflowMenu(true)}
      onMouseLeave={() => setShouldShowOverflowMenu(false)}
      ref={!hasCommError ? onRef : undefined}
      takeFullWidth={width < FULL_WIDTH_CUTOFF}
    >
      <BubbleMenu>
        <ConversationItemMenuDisplayer item={item} shouldShowOverflowMenu={shouldShowOverflowMenu} />
      </BubbleMenu>
      {children}
    </StyledContent>
  );

  if (hasCommError) {
    const wrapperClassNames = classnames('bubbleContent-errorWrapper', {
      'bubbleContent-errorWrapper-inbound': isInbound,
      'bubbleContent-errorWrapper-outbound': !isInbound,
    });
    return (
      <React.Fragment>
        <div className={wrapperClassNames} ref={onRef}>
          <i className="bubbleContent-errorIcon fa fa-exclamation" />
          {content}
        </div>
        <PinnedCustomerList isInbound={isInbound} item={item} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {content}
      <PinnedCustomerList isInbound={isInbound} item={item} />
    </React.Fragment>
  );
}

const StyledContent = styled.div`
  ${p => (p.takeFullWidth ? 'max-width: 90%;' : '')}

  agentmention {
    background-color: ${p => p.theme.colors.gray100};
    border-radius: 4px;
    color: ${p => p.theme.colors.green400};
    font-weight: bold;
    padding: 4px;

    &:hover {
      background-color: ${p => p.theme.colors.gray200};
    }

    &[data-agentid="${p => p.$currentAgentId}"], &[data-agentid="${p => p.$currentAgentId}"]:hover {
      background-color: ${p => p.theme.colors.yellow200};
    }
  }

  ol,
  ul {
    margin: 4px 0;
    padding-inline-start: 16px;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }
`;

BubbleContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  customerId: PropTypes.string,
  'data-aid': PropTypes.string,
  hasReaction: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isSomeGroupHighlighted: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onClick: PropTypes.func,
  onRef: PropTypes.func,
  onUnselectHighlightGroupedMessages: PropTypes.func,
  status: PropTypes.string,
};

export function BubbleFooter({ className, children }) {
  const classNames = classnames('bubbleContent-footer', className);
  return <div className={classNames}>{children}</div>;
}

export function BubbleHeader({ className, children }) {
  const classNames = classnames('bubbleContent-header', className);
  return <div className={classNames}>{children}</div>;
}

export function BubbleMenu({ className, children }) {
  const classNames = classnames('bubbleContent-menu', className);
  return <div className={classNames}>{children}</div>;
}

const BubbleContentContainer = connect(mapStateToProps, mapExecuteToProps)(BubbleContent);
export default BubbleContentContainer;

function mapStateToProps({ getProvider }, props) {
  const highlightedMessages = getProvider('highlightedMessages').get();
  const customerId = getProvider('profile').get()?.id;

  const isSomeGroupHighlighted = !!highlightedMessages?.id && highlightedMessages.customerId === customerId;
  return { ...props, customerId, isSomeGroupHighlighted };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onUnselectHighlightGroupedMessages: () =>
      executeAction(HighlightGroupedMessages, {
        id: getMessageGroupId(props.item),
        customerId: props.customerId,
        isHighlighted: false,
      }),
  };
}
