import { Value } from 'slate-old';
import Html from 'slate-html-serializer-old';

import getContentGeneratorName from '../lib/get_content_generator_name';

import createWordCommentRules from './word_comment_rules';
import createWordFallbackRules from './word_fallback_rules';
import createWordListRules, { fixLists } from './word_list_rules';
import createWordRichTextRules from './word_rich_text_rules';

export default function deserializeWord(html) {
  const wordDeserializer = createWordDeserializer();

  // Sometimes words mangles comments
  const fixedComments = html.replace(/<!\[/g, '<!--[').replace(/\]>/g, ']-->');
  const value = wordDeserializer.deserialize(fixedComments);
  const valueJSON = value.toJSON();
  const fixedValueJSON = fixLists(valueJSON);
  const fixedValue = Value.fromJSON(fixedValueJSON);
  return fixedValue.document;
}

export function isPastedFromWord(html) {
  const generatorName = getContentGeneratorName(html);
  const wordRegexp = /(class="?Mso|style=["'][^"]*?\bmso-|w:WordDocument|<o:\w+>)/;
  return generatorName ? generatorName === 'microsoft' : wordRegexp.test(html);
}

export function createWordDeserializer() {
  const context = {
    ignore: false, // If we're inside a conditional comment, don't process
  };

  const wordCommentRules = createWordCommentRules(context);
  const wordListRules = createWordListRules(context);
  const wordFallbackRules = createWordFallbackRules(context);
  const wordRichTextRules = createWordRichTextRules(context);

  const wordDeserializer = new Html({
    rules: [...wordCommentRules, ...wordListRules, ...wordRichTextRules, ...wordFallbackRules],
  });
  return wordDeserializer;
}
