import _ from 'lodash';
import qconsole from 'scripts/lib/qconsole';
import Err from 'models/err';
import ItemId from 'models/item_id';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';

export default class RequestItemIds {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, dontSetLoading }) {
    const customerStores = this.context.stores.customers.storesFor(customerId);
    if (!dontSetLoading) {
      customerStores.itemIds.setLoading();
    }

    this.context.gateways.itemIds
      .fetchAll({ customerId })
      .then(itemIdsDto => {
        if (!this.context.stores.customers.has({ id: customerId })) {
          qconsole.log(`Received item ids for unloaded customer [${customerId}]. Ignoring.`);
          return;
        }
        customerStores.itemIds.set(_.map(itemIdsDto, dto => ItemId.fromJs(dto)));
      })
      .catch(error => {
        customerStores.itemIds.setErrorForLoading(new Err({ code: Err.Code.UNEXPECTED_ERROR, detail: error.message }));

        handleCommonErrors({ customerId }, error);
      })
      .finally(() => customerStores.itemIds.resetLoading());
  }
}
