import React from 'react';
import PropTypes from 'prop-types';
import connect from 'components/lib/connect';

import ConversationNotification from 'models/notification/conversation_notification';
import { getBrandIconByName } from 'components/common/custom_channels';
import NotificationContentContainer from './notification_content_container';

function CustomChannelMessageNotification({ customChannel, notification }) {
  const content = notification.conversationItem.content;
  const displayName = notification.profile.name || content.from;

  return (
    <NotificationContentContainer
      body={content.body}
      heading={displayName}
      icon={renderIcon(customChannel)}
      iconClass="icon-custom-channel"
      itemType={customChannel?.name}
      notification={notification}
      title={content?.groupName}
    />
  );
}

const renderIcon = customChannel => {
  return getBrandIconByName(customChannel?.icon, {
    className: 'conversationNotification-customChannel-icon',
    color: 'white',
    fill: 'white',
    letter: customChannel?.name,
    stroke: true,
  }).icon;
};

CustomChannelMessageNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

function mapStateToProps(context, props) {
  const conversationItem = props.notification?.conversationItem;

  const customChannels = context.getProvider('customChannels');

  const customChannelId = conversationItem.content?.customChannelId;
  const customChannel = customChannels.findBy({ id: customChannelId });

  return {
    ...props,
    customChannel,
  };
}

const CustomChannelMessageNotificationContainer = connect(mapStateToProps)(CustomChannelMessageNotification);

export default CustomChannelMessageNotificationContainer;
