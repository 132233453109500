import _ from 'lodash';

import addOrUpdateComposition from './add_or_update_composition';
import { isEasyMode } from 'scripts/application/lib/environment';
import removeComposition from 'actions/composition/lib/remove_composition';

export default class FlushUnsavedCompositions {
  constructor(context) {
    this.context = context;
  }

  run(evt) {
    const agent = this.context.stores.currentAgent.get();
    if (!agent || !agent.id) {
      return;
    }

    const compositions = this.context.stores.localCompositions.getAll();
    if (!compositions.length) {
      return;
    }

    let hasModified = false;
    _.forEach(compositions, composition => {
      const isEmpty = composition.isEmpty();
      // We shouldn't ever persist empty compositions, but just in case one was, delete it.
      const needsRemoval = composition.persisted && isEmpty;
      if (needsRemoval) {
        hasModified = true;
        removeComposition(this.context, composition);
      }
      // On the other hand, if it's not empty, let's save it.
      else if (!isEmpty) {
        hasModified = true;
        addOrUpdateComposition(this.context, composition);
      }
    });

    if (hasModified && !isEasyMode()) {
      // This will pop up a dialogue message box that halts the unload from immediately happening
      evt.preventDefault();
      evt.returnValue = '';
    }
  }
}
