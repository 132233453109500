export default {
  contextTypes: {
    getProvider() {},
    sharedBindings() {},
  },

  UNSAFE_componentWillMount() {
    this.observeBinding(this.context.sharedBindings.appFeatures);
  },

  isFeatureEnabled(feature) {
    let appFeatures = this.context.getProvider('appFeatures').get();
    return (appFeatures && appFeatures.isEnabled(feature)) || false;
  },
};
