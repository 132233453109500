import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import BackButtonContainer from 'components/lib/back_button_container';
import connect from 'components/lib/connect';
import Floorboard from 'models/location/floorboard';
import ForgotPassword from 'models/location/forgot_password';
import TopBarContent from './top_bar_content';
import UserActivation from 'models/location/user_activation';
import UserLogin from 'models/location/user_login';
import UserResetPassword from 'models/location/user_reset_password';

const EXCLUDED_LOCATIONS = [Floorboard, ForgotPassword, UserActivation, UserLogin, UserResetPassword];

export function TopBar({ currentLocation }) {
  if (_.some(EXCLUDED_LOCATIONS, excludedLocation => currentLocation instanceof excludedLocation)) {
    return null;
  }

  return (
    <TopBarStyled data-aid="topBar">
      <HamburgerSpacer />
      <BackButtonContainer />
      <TopBarContent />
    </TopBarStyled>
  );
}

const HamburgerSpacer = styled.div`
  width: 50px;
`;

const TopBarStyled = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.boxShadow.medium};
  display: flex;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7;
`;

function mapStateToProps({ getProvider }) {
  return {
    currentLocation: getProvider('currentLocation').get(),
  };
}

const TopBarContainer = connect(mapStateToProps)(TopBar);
export default TopBarContainer;
