import _ from 'lodash';
import createLocationModel, { prop } from './lib/create_location_model';

const Floorboard = createLocationModel({
  modelName: 'Floorboard',
  properties: {
    routingGroupIds: prop([String]),
    timezone: String,
  },

  deriveNew(newQuery) {
    return Floorboard.create(_.assign({}, this, newQuery));
  },

  statics: {
    breadcrumb: 'Full Screen Liveboard',
    create(attrs = {}) {
      return new this(_.omitBy(attrs, _.isNil));
    },
  },
});

export default Floorboard;
