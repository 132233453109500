import Assignee from 'models/assignee';
import PhoneCall from 'models/phone_call';
import ServerClock from 'scripts/application/lib/server_clock';

export default class InitiateWarmTransfer {
  constructor(context) {
    this.context = context;
  }

  run({ fromAgentId, toAgentId, toRoutingGroupId }) {
    const activeCall = this.context.stores.activeCall.get();
    if (!activeCall) {
      return;
    }

    activeCall.setTransferee(new Assignee({ agentId: toAgentId, routingGroupId: toRoutingGroupId }));
    this.context.stores.activeCall.set(activeCall);

    if (toAgentId) {
      // warm transfer to agent
      if (activeCall.conversationItem.content.findParticipantById(toAgentId)) {
        activeCall.conversationItem.content.setParticipantStatus(toAgentId, PhoneCall.ParticipantStatus.DIALING);
      } else {
        activeCall.conversationItem.content.addParticipant({
          participantId: toAgentId,
          status: PhoneCall.ParticipantStatus.DIALING,
          statusUpdatedAt: ServerClock.toISOString(),
          type: PhoneCall.ParticipantType.AGENT,
        });
      }
    }

    this.context.stores.activeCall.setPending(activeCall);

    this.context.gateways.phoneControlsHttp.initiateWarmTransfer(
      activeCall.customer.id,
      activeCall.conversationItem.id,
      {
        fromAgentId,
        toAgentId,
        toRoutingGroupId,
      }
    );
  }
}
