import _ from 'lodash';

import createModel from './lib/create_model';
import createBlankError from '../factories/error/create_blank_error';
import createGreaterThanError from '../factories/error/create_greater_than_error';
import createLessThanEqualToError from '../factories/error/create_less_than_equal_to_error';
import createNotNumberError from '../factories/error/create_not_number_error';
import createTooLongError from '../factories/error/create_too_long_error';
import IdGenerator from 'scripts/domain/contracts/id_generator';

// AUTOMATION_ROUTING_GROUP_ID is known routingGroupID that is dedicated to Message Automation,
// the reason for its existence is to satisfy a constraint on an open Conversation, where it needs
// a routingGroupID specified. This RoutingGroup should be hidden from view and be hidden.
export const AUTOMATION_ROUTING_GROUP_ID = 'MESSAGE_AUTOMATION';

var prop = createModel.prop;

var RoutingGroup = createModel({
  modelName: 'RoutingGroup',

  properties: {
    agentIds: prop([String]).default([]),
    defaultEndpoints: prop({}).default({}),
    disabled: prop(Boolean).default(false),
    id: prop(String).isRequired,
    name: prop(String).isRequired,
    slaSeconds: prop(Number).isRequired,
  },

  update(attrs) {
    const validationErrors = getValidationErrors(_.assign({}, this, attrs));
    if (validationErrors.length) {
      throw new Error(_.map(validationErrors, 'detail').join('; '));
    }

    _.assign(this, _.omit(attrs, 'id'));
  },

  removeAgent(agentId) {
    _.remove(this.agentIds, aId => aId === agentId);
  },

  addAgent(agentId) {
    this.agentIds.push(agentId);
    this.agentIds = _.uniq(this.agentIds);
  },

  toggleDisabled() {
    this.disabled = !this.disabled;
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },

    MAX_LENGTH: 200,

    getDefaultSlaSeconds: () => {
      return 86400; // 24 hours in seconds
    },

    convertSecondsToMinutes: seconds => {
      var mins = seconds / 60;
      return Math.floor(mins * 100) / 100; // truncate to two decimal places
    },

    getValidationErrors,
  },
});

function getValidationErrors(props) {
  var errors = [];

  ['name'].forEach(function(field) {
    if (!props[field] || !props[field].length) {
      errors.push(createBlankError(field));
    } else if (props[field].length > RoutingGroup.MAX_LENGTH) {
      errors.push(createTooLongError(field, `${RoutingGroup.MAX_LENGTH} characters`));
    }
  });

  if (props.slaSeconds != null) {
    if (isNaN(props.slaSeconds)) {
      errors.push(createNotNumberError('slaSeconds'));
    } else if (Number(props.slaSeconds) <= 0) {
      errors.push(createGreaterThanError('slaSeconds', 0));
    } else if (Number(props.slaSeconds) > 2592000) {
      errors.push(createLessThanEqualToError('slaSeconds', 43200));
    }
  } else {
    errors.push(createBlankError('slaSeconds', 'value'));
  }

  return errors;
}

export default RoutingGroup;
