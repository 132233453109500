import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import { after } from 'scripts/infrastructure/backends/http_client';

export default class SystemConfigurationGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
  }

  init() {}

  get broadcastTopic() {
    return `v${this.version}/system-configuration`;
  }

  reconnectHandler() {
    if (this._isSubscribed) {
      this.request();
    }
  }

  get version() {
    return '1';
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleFetchSystemConfigurationSuccess', envelope.payload);
  }

  /* HTTP */

  get _httpClient() {
    return this.backend.axios();
  }

  request() {
    this._isSubscribed = true;
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.get.bind(this));
  }

  get() {
    return after(this._httpClient.get(`/api/v${this.version}/system-configuration`), this.onFetch.bind(this));
  }

  onFetch(err, res) {
    if (err) {
      this.notifyObservers('handleFetchSystemConfigurationError', err);
      return;
    }
    if (res.status === 200) {
      this.notifyObservers('handleFetchSystemConfigurationSuccess', res.data);
    } else {
      this.notifyObservers('handleFetchSystemConfigurationError', res.data);
    }
  }
}

mixin(SystemConfigurationGateway.prototype, Observable);
