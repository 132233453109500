import HtmlToText from 'html-to-text';

/**
 * This is the email-specific flavor of HtmlToText that does additional processing that mostly apples
 * to email items
 *
 * @param html
 * @return {string}
 */
export function emailHtmlToText(html) {
  const divRe = /<div/gi;
  const liRe = /<br(\s*id="[^"]*")?><\/li>/gi;
  const nlx3Re = /\n{3,}/gi;
  const nlcrRe = /[\n\r]/gi;
  const spaceRe = /&(nbsp|#160);/gi;

  html = html
    .replace(nlcrRe, '')
    .replace(spaceRe, ' ')
    .replace(liRe, '</li>')
    .replace(divRe, '<br><div');

  return HtmlToText.fromString(html, {
    hideLinkHrefIfSameAsText: true,
    ignoreImage: true,
  })
    .replace(nlx3Re, '\n\n')
    .replace(/^\n+/g, '');
}
