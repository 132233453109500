import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';

export function createSchema() {
  const constructedSchema = {
    rules: [
      // Merge ordered lists that are right next to each other
      {
        match: { type: ORDERED_LIST },
        next: (next, match) => {
          return next.type !== ORDERED_LIST;
        },
        normalize: (editor, error) => {
          const { code, next } = error;
          if (code === 'next_sibling_invalid') {
            editor.mergeNodeByKey(next.key);
          }
        },
      },
      // Merge unordered lists that are right next to each other
      {
        match: { type: UNORDERED_LIST },
        next: (next, match) => {
          return next.type !== UNORDERED_LIST;
        },
        normalize: (editor, error) => {
          const { code, next } = error;
          if (code === 'next_sibling_invalid') {
            editor.mergeNodeByKey(next.key);
          }
        },
      },
    ],
    blocks: {
      list_item: {
        parent: [{ type: UNORDERED_LIST }, { type: ORDERED_LIST }],
        // List items can contain other blocks, like paragraphs or other lists.
        nodes: [{ objects: ['block'] }],

        normalize: (editor, error) => {
          switch (error.code) {
            case 'parent_type_invalid': {
              const isDocumentParent = editor.value.document.getParent(error.node.key) === editor.value.document;
              if (isDocumentParent) {
                editor.setNodeByKey(error.node.key, { type: 'paragraph' });
              } else {
                editor.unwrapBlockByKey(error.node.key, {
                  normalize: false,
                });
              }
              return;
            }
            case 'child_object_invalid':
              wrapChildrenInDefaultBlock(editor, error.node);
              return;
          }
        },
      },
    },
  };

  // validate all list types, ensure they only have list item children
  [UNORDERED_LIST, ORDERED_LIST].forEach(type => {
    constructedSchema.blocks[type] = {
      nodes: [{ match: [{ type: LIST_ITEM }, { type: UNORDERED_LIST }, { type: ORDERED_LIST }], min: 1 }],

      normalize: (editor, error) => {
        switch (error.code) {
          case 'child_type_invalid':
            editor.wrapBlockByKey(error.child.key, LIST_ITEM, {
              normalize: false,
            });
            return;
        }
      },
    };
  });

  return constructedSchema;
}

/*
 * Wraps all child of a node in the default block type.
 * Returns a change, for chaining purposes
 */
function wrapChildrenInDefaultBlock(editor, node) {
  editor.wrapBlockByKey(node.nodes.first().key, 'paragraph', {
    normalize: false,
  });

  const wrapper = editor.value.document.getDescendant(node.key).nodes.first();

  // Add in the remaining items
  node.nodes.rest().forEach((child, index) =>
    editor.moveNodeByKey(child.key, wrapper.key, index + 1, {
      normalize: false,
    })
  );

  return editor;
}
