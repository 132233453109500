import _ from 'lodash';
import { CancelToken, isCancel } from 'axios';
import { HEADER_CORRELATION_ID } from 'scripts/infrastructure/backends/http_client';
import Observable from 'scripts/lib/observable_mixin';

const DEFAULT_TIMEOUT = 600000;

export default class ReportGateway {
  constructor(backend) {
    this._outstandingFetchTokens = new Set();
    this.backend = backend;
    _.bindAll(this, ['onFetchResponse', 'onFetchError', 'onFetchCancel']);
  }

  init({ orgId }) {
    this.orgId = orgId;
  }

  get _httpClient() {
    return this.backend.axios();
  }

  cancel() {
    this._outstandingFetchTokens.forEach(cancelToken => {
      cancelToken.cancel();
    });
  }

  fetch(reportType, fetchPayload, { correlationId, reportRequestedAt }) {
    let cancelToken = CancelToken.source();
    this._outstandingFetchTokens.add(cancelToken);

    return this._httpClient
      .post(`/api/reporting/v1/orgs/${this.orgId}/reports`, fetchPayload, {
        timeout: DEFAULT_TIMEOUT,
        cancelToken: cancelToken.token,
        headers: { [HEADER_CORRELATION_ID]: correlationId },
      })
      .then(res => {
        this._outstandingFetchTokens.delete(cancelToken);
        this.onFetchResponse(reportType, res, fetchPayload, { correlationId, reportRequestedAt });
      })
      .catch(error => {
        this._outstandingFetchTokens.delete(cancelToken);
        if (isCancel(error)) {
          this.onFetchCancel(error, reportType, fetchPayload, { correlationId, reportRequestedAt });
        } else {
          this.onFetchError(error, reportType, fetchPayload, { correlationId, reportRequestedAt });
        }
      });
  }

  onFetchResponse(reportType, res, fetchPayload, { correlationId, reportRequestedAt }) {
    if (res.status === 200) {
      this.notifyObservers('handleRequestedReport', {
        correlationId,
        reportRequestedAt,
        reportType,
        reportDto: res.data,
        fetchPayload,
      });
    } else {
      this.onFetchError(res.data, reportType, fetchPayload, { correlationId, reportRequestedAt });
    }
  }

  onFetchError(errorDto, reportType, fetchPayload, { correlationId, reportRequestedAt }) {
    this.notifyObservers('handleRequestError', {
      correlationId,
      reportRequestedAt,
      reportType,
      errorDto,
      fetchPayload,
    });
  }

  onFetchCancel(cancelDto, reportType, fetchPayload, { correlationId, reportRequestedAt }) {
    this.notifyObservers('handleRequestCancel', {
      correlationId,
      reportRequestedAt,
      reportType,
      cancelDto,
      fetchPayload,
    });
  }
}

_.extend(ReportGateway.prototype, Observable);
