import { DomainError } from 'scripts/application/lib/error';
import DtoConverter from 'scripts/application/dto_converters/team_converter';
import Err from 'models/err';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import mixin from 'scripts/lib/mixin';
import NavigateBackToUsersAdmin from 'actions/users/navigate_back_to_users_admin';
import qconsole from 'scripts/lib/qconsole';
import ShowToast from 'actions/toast_deprecated/show_toast';
import TeamEditor from 'models/location/team_editor';
import { ToastType } from 'models/toast_deprecated';

export default class TeamGatewayObserver {
  constructor(context) {
    this.context = context;
  }
  get store() {
    return this.context.stores.teams;
  }

  handleFindAllSuccess(ctx, teamsDto) {
    this.store.set((teamsDto || []).map(DtoConverter.fromDto));
    this.store.resetLoading();
  }

  handleFindAllError(ctx, err) {
    this.store.resetLoading();
    this.handleCommonErrors(ctx, err);
  }

  handleDeleteSuccess({ params: { teamId } }) {
    if (this.store.isPendingDelete(teamId)) {
      this.store.commitPending(teamId);
    }
    this.navigateBackToUsers();
  }

  handleDeleteError(ctx, err) {
    this.store.resetPending(ctx.params.teamId);
    this.handleCommonErrors(ctx, err);
  }

  handleAddOrReplaceSuccess({ params: { team } }) {
    if (this.store.isPendingNew()) {
      this.store.commitPendingNew();
      this.context.executeAction(ShowToast, {
        type: ToastType.SUCCESS,
        message: 'Success! You have created a new team',
      });

      this.navigateBackToUsers();
      return;
    }

    let pendingTeam = this.store.getPending(team.id);
    let existingTeam = this.store.findBy({ id: team.id });

    if (!existingTeam || pendingTeam._version >= existingTeam._version) {
      this.store.commitPending(team.id);
      this.navigateBackToUsers();
      return;
    }

    this.store.resetPending(team.id);
    qconsole.log(`Received outdated team replace success for team [${team.id}] without team pending`);
  }

  handleAddOrReplaceError(ctx, err) {
    if (this.store.isPendingNew()) {
      this.store.resetPendingNew();

      if (!(err instanceof DomainError)) {
        this.handleCommonErrors(ctx, err);
        return;
      }

      this.store.setErrorsForNew(err.errors);
    } else {
      this.store.resetPending(ctx.params.team.id);

      if (!(err instanceof DomainError)) {
        this.handleCommonErrors(ctx, err);
        return;
      }

      if (err.errors[0].code === Err.Code.VERSION_OUTDATED) {
        this.context.executeAction(ShowToast, {
          type: ToastType.ERROR,
          message: 'Sorry. The team you edited was changed by someone else.',
        });
        this.navigateBackToUsers();
        return;
      }

      this.store.setErrors(ctx.params.team.id, err.errors);
    }
  }

  handleTeam(team) {
    let receivedTeam = DtoConverter.fromDto(team);

    let existingTeam = this.store.findBy({ id: receivedTeam.id });

    if (existingTeam) {
      if (receivedTeam._version >= existingTeam._version) {
        this.store.replace(receivedTeam);

        if (
          this.context.stores.currentLocation.get().teamId === receivedTeam.id &&
          !(this.store.isPending(receivedTeam.id) || this.store.isPendingDelete(receivedTeam.id))
        ) {
          this.context.executeAction(ShowToast, {
            type: ToastType.WARN,
            message: 'Sorry. The team you were editing was changed by someone else.',
          });
        }
      }
    } else {
      this.store.add(receivedTeam);
    }
  }

  handleDelete(teamId) {
    if (this.store.has({ id: teamId }) && !this.store.isPendingDelete(teamId)) {
      if (this.context.stores.currentLocation.get().teamId === teamId) {
        this.context.executeAction(ShowToast, {
          type: ToastType.WARN,
          message: 'Sorry. The team you were editing was deleted.',
        });
        this.navigateBackToUsers();
      }
      this.store.remove(teamId);
    }
  }

  navigateBackToUsers() {
    if (this.context.stores.currentLocation.get() instanceof TeamEditor) {
      this.context.executeAction(NavigateBackToUsersAdmin);
    }
  }
}

mixin(TeamGatewayObserver.prototype, GatewayErrorInteractiveHandler.prototype);
