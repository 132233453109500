import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import ConversationItemStatus from 'models/conversation_item_status';
import EmailMessage from 'models/email_message';
import EmailStatus from 'models/conversation_item/email_status';

export default function createAgentEmail({
  agentProfile,
  content,
  conversationId,
  customerId,
  snippetIds = [],
  relatedSnippetIds = [],
}) {
  return ConversationItem.create({
    conversationId,
    content: new EmailMessage(content),
    customerId,
    initiator: {
      id: agentProfile.id,
      type: Communicator.AGENT,
    },
    status: ConversationItemStatus.create({ value: EmailStatus.NEW }),
    snippetIds,
    relatedSnippetIds,
  });
}
