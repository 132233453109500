import React from 'react';
import PropTypes from 'prop-types';

import UniversalSearchBarContainer from 'components/search/universal_search_bar_container';
import UniversalSearchResultsContainer from 'components/search/universal_search_results_container';
import UniversalSearchFilterContainer from 'components/search/universal_search_filter_container';

class UniversalSearch extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchFilterChange = this.handleSearchFilterChange.bind(this);
  }

  resetScroll() {
    window.scrollTo(0, 0);
  }

  fetchSearchResults({ text, filterProperties }) {
    this.props.onSearchQueryChange({ text, filterProperties });
    this.resetScroll();
  }

  handleSearchTextChange(text) {
    this.fetchSearchResults({ text });
  }

  handleSearchFilterChange(filterProperties) {
    this.fetchSearchResults({ filterProperties });
  }

  render() {
    let { filterHits, onSelectItem, placeholder, searchResultId } = this.props;
    return (
      <div className="universalSearch-searchPage">
        <UniversalSearchFilterContainer onSearchFilterChange={this.handleSearchFilterChange} queryId={searchResultId} />
        <div className="universalSearch-searchBarResultsContainer">
          <UniversalSearchBarContainer
            onSearchTextChange={this.handleSearchTextChange}
            placeholder={placeholder}
            queryId={searchResultId}
          />
          <UniversalSearchResultsContainer
            filterHits={filterHits}
            onSelectItem={onSelectItem}
            queryId={searchResultId}
            shouldShowPagination
          />
        </div>
      </div>
    );
  }
}

UniversalSearch.propTypes = {
  filterHits: PropTypes.func,
  onSearchQueryChange: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func,
  placeholder: PropTypes.string,
  searchResultId: PropTypes.string,
};

export default UniversalSearch;
