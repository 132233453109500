import _ from 'lodash';

import CommunicationDetail from 'models/routing_item/communication_detail';
import RoutingItemType from '../routing_item_type';
import SmartMatchDetail from './smart_match_detail';
import TypeReflect from 'models/lib/type_reflect';

const DetailClasses = Object.freeze(
  _.map(
    [
      [RoutingItemType.INBOUND, CommunicationDetail],
      [RoutingItemType.INBOUND_MATCH, CommunicationDetail],
      [RoutingItemType.OFFER_DECLINE, CommunicationDetail],
      [RoutingItemType.OFFER_MISS, CommunicationDetail],
      [RoutingItemType.OUTBOUND, CommunicationDetail],
      [RoutingItemType.SMART_MATCH, SmartMatchDetail],
      [RoutingItemType.UNASSIGNED_NON_MEMBER, CommunicationDetail],
      [RoutingItemType.UNASSIGNED_UNAVAILABLE, CommunicationDetail],
    ],
    Object.freeze
  )
);

export default DetailClasses;

export function detailClassFromItemType(itemType) {
  try {
    return typeReflect.typeToConstructor(itemType);
  } catch (e) {
    return undefined;
  }
}

export const typeReflect = new TypeReflect(DetailClasses);
