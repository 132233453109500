import CloseCommentPanel from 'actions/task/close_comment_panel';
import { CustomerAccess } from 'models/customer_access_history';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import qconsole from 'scripts/lib/qconsole';
import unloadCustomer from './lib/unload_customer';

export default class UnloadCustomer {
  constructor(context) {
    this.context = context;
    this.MAX_LENGTH = HISTORY_LENGTH_MAX;
  }

  run(id) {
    let customerAccessHistory = this.context.stores.customerAccessHistory.get();

    if (customerAccessHistory.entries.length > this.MAX_LENGTH + HISTORY_EXCESS_THRESHOLD) {
      ErrorReporter.reportMessage('Excessive number of customers loaded', {
        extra: {
          count: customerAccessHistory.entries.length,
        },
      });
    }

    customerAccessHistory.put(CustomerAccess.create({ id }));
    this.unloadExcessCustomers(customerAccessHistory);

    let countDiff = this.context.stores.customers.count() - customerAccessHistory.entries.length;
    if (countDiff > 0) {
      ErrorReporter.reportMessage(`Found [${countDiff}] untracked customers in store`);
    }

    this.context.stores.customerAccessHistory.set(customerAccessHistory);

    this.cancelProfileEdit(id);
    this.closeCommentPanel();
  }

  unloadExcessCustomers(customerAccessHistory) {
    const toUnload = customerAccessHistory.trim(this.MAX_LENGTH);
    toUnload.forEach(customerAccess => this.unloadCustomer(customerAccess.id));
    qconsole.log(`Unloaded [${toUnload.length}] customers, remaining [${customerAccessHistory.entries.length}]`);
  }

  unloadCustomer(customerId) {
    unloadCustomer(this.context, customerId);
  }

  cancelProfileEdit(id) {
    let profileStore = this.context.stores.customers.storesFor(id).profile;
    profileStore.clearErrors();
    profileStore.resetPending();
  }

  closeCommentPanel() {
    this.context.executeAction(CloseCommentPanel);
  }
}

const HISTORY_EXCESS_THRESHOLD = 10;
const HISTORY_LENGTH_MAX = 25;
