export default class UpdateCustomerNote {
  constructor(context) {
    this.context = context;
  }

  run(notes) {
    let customerNote = this.context.stores.note.get();
    customerNote.updateContent(notes);
    this.context.stores.note.set(customerNote);

    this.context.gateways.customerNote.update({ customerId: this.currentCustomerId }, { content: notes });
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }
}
