import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import { LinkedCustomerProfile } from 'models/item_link_content';

export const RelationshipTypes = createEnum('CHILD', 'EMPLOYEE', 'EMPLOYER', 'PARENT', 'ROOMMATE', 'SPOUSE');

const Relationship = createModel({
  modelName: 'Relationship',
  properties: {
    id: prop(String).required,
    customerId: prop(String).required,
    customerProfile: prop(LinkedCustomerProfile),
    labelId: prop(String),
    version: prop(Number).default(0),
  },

  setLabelId(labelId) {
    this.labelId = labelId;
    this.version++;
  },

  statics: {
    create(attrs = {}) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },
  },
});

export default Relationship;
