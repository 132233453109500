import _ from 'lodash';

import { AnswersListType } from 'models/answer_panel';
import AnswersPreview from 'models/location/answers_preview';
import { getAnswersList } from 'scripts/application/selectors/answers';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import {
  snowplowTypes,
  getAnswerContextFromAnswer,
  getCompositionContextFromComposition,
  getSearchContext,
} from 'scripts/lib/analytics/domain/snowplow';

export default class TrackAnswerUsed {
  constructor(context) {
    this.context = context;
  }

  run({ answer, channel, language, method }) {
    const currentLocation = this.context.stores.currentLocation.get();
    const search = this.context.stores.answerPanel.get().searchResult;

    const compositionId = currentLocation.currentCompositionId;
    const snowplowContexts = [getAnswerContextFromAnswer(answer, channel, language)];

    if (!(currentLocation instanceof AnswersPreview)) {
      // no customer or composition on this page
      // but copying is still possible which can lead us to this event
      const compositionsStore = getCompositionsStore(this.context);
      const composition = compositionsStore && compositionsStore.findBy({ id: compositionId });
      if (composition) {
        snowplowContexts.push(getCompositionContextFromComposition(composition));
      }
    }
    if (search) {
      snowplowContexts.push(getSearchContext(search));
    }

    const { answers, type } = getAnswersList(this.context.stores, { compositionId });

    this.context.analytics.trackSnowplow(
      snowplowTypes.EVENT_ANSWER_USED,
      {
        position: _.findIndex(answers, a => a.id === answer.id) + 1,
        source: { [AnswersListType.SEARCH]: 'search', [AnswersListType.SUGGESTIONS]: 'suggestion' }[type],
        sortType: type === AnswersListType.SEARCH ? _.get(search, 'query.sort') : 'SUGGESTED',
        method,
      },
      snowplowContexts
    );
  }
}
