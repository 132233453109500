import { Factory } from 'rosie';

Factory.define('InboxCount');

Factory.define('TeamInboxCountWithDefaults')
  .attr('id', 'default-team-id')
  .attr('isTeam', true)
  .attr('newCount', 2)
  .attr('openCount', 3)
  .attr('waitingCount', 4);

Factory.define('PersonalInboxCountWithDefaults')
  .attr('id', ['id'], id => id || 'agent-id')
  .attr('isTeam', false)
  .attr('newCount', 2)
  .attr('openCount', 3)
  .attr('openUnreadCount', 1)
  .attr('unreadConversationIds', ['some-unread-conversation-id'])
  .attr('conversationIds', ['some-unread-conversation-id'])
  .attr('oldestUnreadConversations', () => [Factory.attributes('UnreadConversationWithDefaults')])
  .attr('waitingCount', 4);

Factory.define('UnreadConversation');

Factory.define('UnreadConversationWithDefaults')
  .extend('UnreadConversation')
  .attr('conversationId', 'some-unread-conversation-id')
  .attr('customerId', 'some-lonely-customer-id');
