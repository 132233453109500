import PropTypes from 'prop-types';
import React from 'react';

import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import Metadata from 'components/customer/conversation_history/conversation_items_v2/metadata/metadata';
import RuleLink from '../metadata/rule_link';

export default function RuleDisabledLoop({ item }) {
  const ruleLink = <RuleLink ruleId={item.content.ruleId}>Rule</RuleLink>;

  return (
    <ItemContent isAuditItem item={item}>
      <Metadata subject={ruleLink} text="deactivated because it fired multiple times" timestamp={item.timestamp} />
    </ItemContent>
  );
}

RuleDisabledLoop.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
