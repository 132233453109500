import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Transfer Fill
 */

export default function TransferFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M15.7492 8.59127C16.0836 8.26846 16.0836 7.73154 15.7492 7.40874L10.3851 2.23091C9.86547 1.7293 9 2.09874 9 2.82218L9 6L2 6C0.89543 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10L9 10L9 13.1778C9 13.9013 9.86547 14.2707 10.3851 13.7691L15.7492 8.59127Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
