import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('MessageAutomationSummaryContent').attr('type', 'MESSAGE_AUTOMATION_SUMMARY');

Factory.define('MessageAutomationSummaryContentWithDefaults')
  .extend('MessageAutomationSummaryContent')
  .attr('automationSessionID', 'automation-session-id')
  .attr('companyAddress', 'company-address')
  .attr('customerAddress', 'customer-address')
  .attr('interactionType', 'CHAT')
  .attr('summary', { description: 'Not sure what happned ...' });
