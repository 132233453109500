import createConverter from './lib/create_converter';
import ConversationItem from 'models/conversation_item';

const converter = createConverter(ConversationItem);
const ConversationItemConverter = {
  fromDto: converter.fromDto,
  toDto(entity) {
    let dto = converter.toDto(entity);
    delete dto.linkedItems;
    return dto;
  },
};

export default ConversationItemConverter;
