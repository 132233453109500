import CloseModal from 'actions/modal/close_modal';

export default class CancelScheduledReport {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.executeAction(CloseModal);
    this.context.stores.scheduledReport.remove();
  }
}
