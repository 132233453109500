import getLastInboxLocation from 'actions/inbox/lib/get_last_inbox_location';

export default class SwitchToInbox {
  constructor(context) {
    this.context = context;
  }

  run() {
    let inboxLocation = getLastInboxLocation(this.context);
    this.context.router.navigateTo(inboxLocation);
  }
}
