import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="2 2 19 19" {...props}>
    <g className="idIcon" fill="none" fillRule="evenodd">
      <g>
        <path
          className="contactIcon-path"
          d="M14.5 9.07c0 1.42-1.08 2.573-2.407 2.573-1.33 0-2.408-1.152-2.408-2.572 0-1.418 1.08-2.57 2.408-2.57 1.328 0 2.407 1.152 2.407 2.57zM12.093 11.643c-3.01 0-4.213 1.928-4.213 3.857h8.426c0-1.93-1.204-3.857-4.213-3.857z"
        />
      </g>
    </g>
  </IconContainer>
);
