import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';

export default class OpenAnswerPanel {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationId, compositionId }) {
    this.context.stores.languages.setLoading();
    this.context.gateways.language.fetch();

    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.open();
    this.context.stores.answerPanel.set(answerPanel);

    const preferences = this.context.stores.agentPreferences.get();
    preferences.setAnswerPanelLastOpenedAt(answerPanel.lastOpenedAt);
    setAgentPreferences(this.context, preferences);

    this.context.analytics.track('Answer Panel Opened', { customerId, conversationId, compositionId });
  }
}
