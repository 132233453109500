import qs from 'qs';
import StandardGateway from './lib/standard_gateway';

import { PAGE_SIZE } from 'actions/agent_notifications/request_agent_notifications';

export default class AgentNotificationGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/agents/:agentId/notifications/:notificationId');
  }

  requestFirstPage() {
    return this.subscribeAll().then(() => this.fetchBefore(''));
  }

  requestAllAfter(timestamp) {
    return this.subscribeAll().then(() => {
      const resource = this.endpoint.patterns.fetchAllUrl;
      const url = this.endpoint.fetchAllUrl;
      const queryParams = {
        after: timestamp,
      };
      const fullUrl = urlWithQuery(url, queryParams);
      return this.http.get(fullUrl, undefined, resource);
    });
  }

  fetchBefore(timestamp) {
    const resource = this.endpoint.patterns.fetchAllUrl;
    const url = this.endpoint.fetchAllUrl;
    const queryParams = {
      before: timestamp,
      limit: PAGE_SIZE,
    };
    const fullUrl = urlWithQuery(url, queryParams);

    return this.http.get(fullUrl, undefined, resource);
  }

  reconnectHandler() {
    // Handled by RerequestAgentNotifications action
  }
}

export function urlWithQuery(url, payload) {
  let query = qs.stringify(payload);
  return `${url}?${query}`;
}
