import _ from 'lodash';
import { ReactEditor } from 'slate-react';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useExecuteAction } from 'components/hooks/connect_hooks';
import { useThrottled } from 'components/hooks/debounce_hooks';
import ComposerContext from 'components/composer/contexts/composer_context';
import focusEndOfEditor from 'components/text_editor_new/lib/focus_end_of_editor';
import RemoveCompositionError from 'actions/composer/remove_composition_error';
import { serializeHtml } from 'components/text_editor_new/lib/serialize_html';
import ToggleComposerVisibility from 'actions/composer/toggle_composer_visibility';
import UpdateComposition from 'actions/composer/update_composition';
import validateEditor from 'components/text_editor_new/lib/validate_editor';

/**
 * Every editor in a composer should have an associated `useEditor` call that registers the editor with the composer.
 * Ensures that the editor's text content will autosave and submit correctly.
 *
 * @param {string} attr - Corresponds to `composition.content.${attr}`, where the html will be stored.
 * @param {{current: { editorValues: Object }}} composerRef - the ref created by `useComposer`
 * @param {{ attr: string, onChange: Function, serializer: { serialize: Function }, validator: Function }} options
 *
 * @returns {Object} props to be passed to `Editor` component
 */
export default function useEditor(
  attr,
  { composerRef, onSubmit },
  { attr: finalAttr, dontAutofocus, onChange, serializer, shouldInsertAnswers, validator } = {}
) {
  shouldInsertAnswers = shouldInsertAnswers == null ? true : shouldInsertAnswers;

  if (!serializer) {
    serializer = serializeHtml;
  }
  if (!validator) {
    validator = validateEditor;
  }

  const composerContext = useContext(ComposerContext);
  const compositionId = _.get(composerContext, 'composition.id');
  const customerId = _.get(composerContext, 'composition.customerId');

  // Register the attr / serializer / validator that the composer ref will use for this editor when submitted.
  useEffect(() => {
    composerRef.current.attrs[attr] = finalAttr;
    composerRef.current.validators[attr] = validator;
    composerRef.current.serializers[attr] = serializer;
  }, [composerRef, attr, serializer, validator, finalAttr]);

  // We have incomplete deps on purpose, otherwise `initialHtml` gets messed up when both the greeting and the
  // signature are defined
  const initialHtml = useMemo(() => {
    return _.get(composerContext, `composition.content.${attr}`, '');
  }, [attr]);
  const childrenRef = useRef(null);

  // updateComposition is the base updater. We don't want to call this every time the editor changes though
  // because serializing is expensive / could cause the editor to feel janky.
  const executeAction = useExecuteAction();
  const updateComposition = useCallback(() => {
    const editor = composerRef.current.editorRefs[attr].current;

    const html = serializeHtml(editor);

    executeAction(UpdateComposition, {
      compositionId,
      attrs: {
        [attr]: html,
      },
      customerId,
    });
    if (editor?.children !== childrenRef.current) {
      executeAction(RemoveCompositionError, { attr: finalAttr || attr, compositionId, customerId });
    }
    childrenRef.current = editor?.children;
  }, [executeAction, compositionId, finalAttr, attr, customerId, composerRef]);

  // Since updating is expensive, we throttle the composition updating.
  const throttledUpdateComposition = useThrottled(updateComposition, 5000);

  // But we want to immediately flush the changes when an agent blurs the composition.. this ensures that if
  // they click to go away, they don't lose any of their work.
  const onBlur = useCallback(() => {
    throttledUpdateComposition.cancel();
    updateComposition();
  }, [throttledUpdateComposition]);

  // When the editor state changes, we do a few things:
  //   1. Set the editor state on the composerRef. We do this because when the agent submits, the update
  //      may still be throttled and therefore the composition in the store may not have the most recent
  //      editorValue. So we need to keep track of the latest editor value _somewhere_.. and that somewhere
  //      is on the composerRef. We then pass all of these latest editor values up to the submit action
  //      when the user click submit.
  //   2. setState with the latest editor state. This ensures the component re-renders with the latest
  //      editorState value.
  //   3. We trigger a throttled update to the composition.. this is what "autosaves".

  const fullOnChange = useCallback(
    children => {
      throttledUpdateComposition();
      onChange && onChange(children);
    },
    [throttledUpdateComposition, onChange]
  );

  // When we un-minimize the editor, return focus to it.
  const isMinimized = composerContext.isMinimized;

  useEffect(() => {
    const editor = composerRef.current.editorRefs[attr]?.current;
    if (!editor || !editor.children || !editor.children.length) {
      return;
    }

    if (!isMinimized && !dontAutofocus) {
      ReactEditor.focus(editor);
      focusEndOfEditor(editor);
    } else {
      ReactEditor.blur(editor);
    }
  }, [isMinimized]);

  const onToggleHide = useCallback(() => executeAction(ToggleComposerVisibility), [executeAction]);

  return {
    editorId: attr,
    initialHtml,
    name: attr,
    onChange: fullOnChange,
    onBlur,
    onSubmit,
    onToggleHide,
  };
}
