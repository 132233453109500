import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Generic Channel Stroke
 */

export default function GenericChannelIconStrokeIcon(props) {
  const letter = [...(props.letter || ' ')][0];
  return (
    <Icon viewBox="0 0 15 15" {...props}>
      <mask fill="white" id="path-1-inside-1_2503_43629">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H11C13.2091 0 15 1.79086 15 4V11C15 13.2091 13.2091 15 11 15H0V4Z" />
      </mask>
      <path
        d="M0 15H-1V16H0V15ZM4 1H11V-1H4V1ZM14 4V11H16V4H14ZM11 14H0V16H11V14ZM1 15V4H-1V15H1ZM14 11C14 12.6569 12.6569 14 11 14V16C13.7614 16 16 13.7614 16 11H14ZM11 1C12.6569 1 14 2.34315 14 4H16C16 1.23858 13.7614 -1 11 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
        mask="url(#path-1-inside-1_2503_43629)"
      />

      <text dominantBaseline="middle" fontSize="10px" fontWeight="400" textAnchor="middle" x="50%" y="57%">
        {letter.toUpperCase()}
      </text>
    </Icon>
  );
}
