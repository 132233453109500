import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';

export default class UpdateAgentNotificationSoundType {
  constructor(context) {
    this.context = context;
  }

  run({ type }) {
    const preferences = this.context.stores.agentPreferences.get();
    preferences.setAgentNotificationSound(type);
    setAgentPreferences(this.context, preferences);
  }
}
