import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import AITextRewriteControls from 'components/text_editor_new/components/controls/ai_text_rewrite_controls';
import { AITextCompletionContextProvider } from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import { AnswerPanelControl, ControlsWrapper, Separator } from 'components/composer/shared/editor_controls_new';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import ChatActions from 'components/composer/chat/chat_actions_new';
import CharacterCount from 'components/text_editor_new/character_count';
import Composer, { Header, NewHeaderText } from 'components/composer/shared/composer_new';
import ComposerContext from 'components/composer/contexts/composer_context';
import ComposerEditor from 'components/composer/shared/composer_editor';
import CreditCardRequestButton from 'components/composer/chat/credit_card_request_button_container';
import createTypingIndicatorPlugin from 'components/composer/chat/typing_indicator_plugin';
import { EditorContainer } from 'components/composer/shared/editor_new';
import EditorErrors from 'components/composer/shared/editor_errors';
import SendChat from 'actions/composer/send_chat';
import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';

import useMessagingEditorPlugins from 'components/text_editor_new/hooks/use_messaging_editor_plugins';
import useAIComposerOptions from 'components/text_editor_new/plugins/ai/ai_text_completion/use_ai_composer_options';
import useComposer from 'components/composer/shared/use_composer';
import { useCreatePasteAttachmentsPlugin } from 'components/text_editor_new/plugins/create_paste_attachments_plugin';
import useEditor from 'components/composer/shared/use_editor';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import withRefreshEditorWhenFlagsChange from 'components/composer/shared/with_refresh_editor_when_flags_change';
import withStaticId from 'components/customer/lib/with_static_id';

export function ChatComposerBase({ includeAIFeatures, requestorId }) {
  const { composition } = useContext(ComposerContext);

  const composerProps = useComposer({ action: SendChat });
  const editorProps = useEditor('bodyHtml', composerProps, {
    attr: 'text',
    serializer: serializePlaintext,
  });
  const extraPlugins = useChatPlugins();
  const { disableSubmit, readOnly } = useAIComposerOptions();
  editorProps.onSubmit = disableSubmit ? _.noop : editorProps.onSubmit;
  const editorRef = useMessagingEditorPlugins(composerProps, editorProps, extraPlugins);

  const submitButton = (
    <ChatActions
      data-aid="sendButton"
      disableSubmit={disableSubmit}
      editorId="bodyHtml"
      hasAttachments={composition.hasAttachments()}
      onSubmit={editorProps.onSubmit}
    />
  );

  return (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      disableSubmit={disableSubmit}
      excludeErrors
      includeAttachments
      initialHtml={editorProps.initialHtml}
      submitButton={submitButton}
      submitText="Send"
      {...composerProps}
    >
      <Header>
        <Left>
          <NewHeaderText>Chat</NewHeaderText>
        </Left>
        <ControlsWrapper />
      </Header>
      <EditorContainer excludeAnswerMenuButton includeAttachments>
        <ComposerEditor
          includeAIFeatures={includeAIFeatures}
          key={composition.id}
          placeholder="Type to chat"
          readOnly={readOnly}
          requestorId={requestorId}
          showFloatingToolbar={false}
          {...editorProps}
        />
      </EditorContainer>

      <MessagingActions>
        <ActionBar>
          <AnswerPanelControl />
          <Separator />
          <AITextRewriteControls />
          <Emoji {...editorProps} />
          <CreditCardRequestButton data-aid={'credit-card-request-button'} />
          <Attachment />
        </ActionBar>
        <StyledCharacterCount composerRef={composerProps.composerRef} />
      </MessagingActions>

      <EditorErrors />
    </Composer>
  );
}

ChatComposerBase.propTypes = {
  attachmentsEnabled: PropTypes.bool,
};

const StyledCharacterCount = styled(CharacterCount)`
  background-color: inherit;
  position: relative;
`;
const MessagingActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

function ChatComposerWithAIFeatures(props) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);
  const includeAIFeatures = isFeatureEnabled('gclDemo2023') || !agentAssistanceConfig.disableHeroAI;

  let composer = <ChatComposerBase {...props} includeAIFeatures={includeAIFeatures} />;

  if (includeAIFeatures) {
    return (
      <AITextCompletionContextProvider requestorId={props.requestorId}>{composer}</AITextCompletionContextProvider>
    );
  }
  return composer;
}

import { useOnTypingRef } from 'components/composer/chat/chat_hooks';

function useChatPlugins() {
  const onTypingRef = useOnTypingRef();
  const createPasteAttachmentsPlugin = useCreatePasteAttachmentsPlugin();

  return useMemo(() => {
    return [createTypingIndicatorPlugin(onTypingRef), createPasteAttachmentsPlugin()];
  }, [createPasteAttachmentsPlugin, onTypingRef]);
}

export const ChatComposer = withStaticId(withRefreshEditorWhenFlagsChange(ChatComposerWithAIFeatures), 'requestorId');

export default React.memo(ChatComposer);
