import _ from 'lodash';

import CompositionConverter from 'scripts/application/dto_converters/composition_converter';
import CurrentCompositionUpdater from '../lib/current_composition_updater';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import qconsole from 'scripts/lib/qconsole';
import ConversationItemType from 'models/conversation_item_type';

let uploadsToDelete = {};

export default class CompositionGatewayObserver {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
  }

  getStore(customerId) {
    return getCompositionsStore(this.context, customerId);
  }

  handleFetchCompositions(compositionDtos, customerId) {
    const store = this.getStore(customerId);
    if (!store) return;

    const hasExternalAgentActions = this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
    let sortedCompositions = _(compositionDtos)
      .map(c => ({ ...c, persisted: true }))
      .map(c => CompositionConverter.fromDto(c))
      .map(fetched => {
        let existing = store.findBy({ id: fetched.id });
        return existing && existing._version > fetched._version ? existing : fetched;
      })
      .sortBy(composition => Date.parse(composition.updatedAt))
      .value();
    if (!hasExternalAgentActions) {
      sortedCompositions = _.filter(sortedCompositions, composition =>
        this._isAllowedCompositionTaskUser(composition.contentType())
      );
    }
    qconsole.log(`Handling fetched compositions for customer [${customerId}]`);

    _.forEach(sortedCompositions, c => {
      store.addOrReplace(c);
    });
    store.resetLoading();

    this.currentCompositionUpdater.update();
  }

  _isAllowedCompositionTaskUser(contentType) {
    return contentType === ConversationItemType.TASK || contentType === ConversationItemType.CONVERSATION_NOTE;
  }

  handleFetchError(errorDto) {
    qconsole.log(`Could not fetch compositions ${JSON.stringify(errorDto)}`);
  }

  handleDeleteError({ agentId, compositionId }, errors, correlationId) {
    this.constructor.resetUploadsToDelete(correlationId);
  }

  handleDeleteSuccess({ agentId, compositionId }, correlationId) {
    this.removeUploadsForRequest(correlationId);
  }

  handleUpdateErrors({ agentId, compositionId }, errors, correlationId) {
    this.constructor.resetUploadsToDelete(correlationId);
  }

  handleUpdateSuccess({ compositionId, customerId }, correlationId) {
    this.removeUploadsForRequest(correlationId);
    this.context.stores.localCompositions.remove(customerId, compositionId);
  }

  handleAddSuccess({ agentId }, correlationId) {
    return;
  }

  handleAddError({ agentId }, correlationId) {
    return;
  }

  removeUploadsForRequest(correlationId) {
    let compositionUploads = uploadsToDelete[correlationId];
    if (compositionUploads) {
      this.constructor.resetUploadsToDelete(correlationId);
      this.removeUploads(compositionUploads);
    }
  }

  removeUploads(compositionUploads) {
    compositionUploads.uploads.forEach(u =>
      this.context.gateways.compositionUpload.remove(compositionUploads.agentId, compositionUploads.getUploadPath(u.id))
    );
  }

  static resetUploadsToDelete(correlationId) {
    delete uploadsToDelete[correlationId];
  }

  static setUploadsToDelete(correlationId, compositionUploads) {
    uploadsToDelete[correlationId] = compositionUploads;
  }
}
