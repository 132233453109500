import styled from 'styled-components';

export const EmptyState = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const EmptyText = styled.div`
  font-weight: bold;
  padding-top: 8px;
  white-space: nowrap;
`;

export const EmptyContent = styled.div`
  font-size: ${p => p.theme.fontSize.base};
  margin-top: 12px;
  text-align: center;
`;
