import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { AIActivityTypes } from 'models/ai_activity/ai_activity_item';
import analytics from 'scripts/lib/analytics';
import BubbleContent from 'components/customer/conversation_history/conversation_items_v2/content/bubble_content';
import ConversationItem from 'models/conversation_item';
import ConversationSummaryItemFooter from 'components/customer/conversation_history/conversation_items_v2/ai_activity/conversation_summary_item_footer';
import ItemContent from 'components/customer/conversation_history/conversation_items_v2/item_content';
import ItemMetadata from 'components/customer/conversation_history/conversation_items_v2/item_metadata';
import RedactedItem from 'components/customer/conversation_history/conversation_items_v2/redacted_item';
import ScrollToConversationItem from 'actions/conversation_item/scroll_to_conversation_item';
import SummaryIcon from 'components/common/icons/stroke/summary-stroke';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export const FEEDBACK_NEGATIVE = 'negative';
export const FEEDBACK_POSITIVE = 'positive';

export const FEEDBACK_VISIBLE_SECONDS = 3 * 60; // thumbs up/down visibility interval in seconds

/**
 * Renders "timeline" conversation summary. For the optional date/time formatting options, see
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @param {string} [className]
 * @param {object} item - data item to render
 * @param {object} [dateFormatOptions] - used primarily for testing. Falls back on the browser defaults
 * @param {object} [timeFormatOptions] - used primarily for testing. Falls back on the browser defaults
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ConversationSummaryItem({ className, item, dateFormatOptions, timeFormatOptions }) {
  const executeAction = useExecuteAction();
  const activityType = item?.content?.activityType || '';
  if (!item?.content || activityType !== AIActivityTypes.CONVERSATION_SUMMARY) return null;

  return (
    <ItemContent className="aiConversationSummaryItem" isAuditItem={false} item={item}>
      <div>
        <SummaryMetaIcon />
        <ItemMetadata item={item} showMetadata text="created a conversation summary" timestamp={item.timestamp} />
      </div>
      {renderConversationSummary(item, { date: dateFormatOptions || {}, time: timeFormatOptions || {} })}
    </ItemContent>
  );

  /**
   * Renders the summary body
   *
   * @param conversationItem - the item (data) we are rendering
   * @param formatOptions - optional formatting params, { date: {...}, time: {...} }. Both `date` and `time`
   *                        formatting params are optional and are mostly used in testing.
   * @returns {JSX.Element}
   */
  function renderConversationSummary(conversationItem, formatOptions = {}) {
    const classes = classnames('conversationSummaryContent', className);
    const redacted = _.get(conversationItem, 'content.isRedacted');
    const renderedContent = redacted ? <RedactedItem /> : renderSummaryContent(conversationItem, formatOptions);

    return (
      <StyledBubbleContent className={classes} item={conversationItem}>
        <div className="content-wrapper">{renderedContent}</div>
      </StyledBubbleContent>
    );
  }

  function renderSummaryContent(conversationItem, formatOptions = {}) {
    return (
      <>
        {renderCaptionSection(conversationItem)}
        {renderDetailsSection(conversationItem, formatOptions)}
        <ConversationSummaryItemFooter
          feedbackVisibilityInterval={FEEDBACK_VISIBLE_SECONDS}
          item={conversationItem}
          onThumbsDown={onThumbsDown}
          onThumbsUp={onThumbsUp}
        />
      </>
    );
  }

  function renderCaptionSection(conversationItem) {
    const caption = _.trim(_.get(conversationItem, 'content.meta.caption'));
    return caption ? (
      <>
        <SectionTitle>
          <strong>Reason for customer contact</strong>
        </SectionTitle>
        {caption}
      </>
    ) : null;
  }

  function renderDetailsSection(conversationItem, formatOptions = {}) {
    const itemMeta = _.get(conversationItem, 'content.meta');
    const details = _.trim(itemMeta?.details);
    if (!details) return null;

    const parts = details
      .split('\n')
      .map(part => _.trim(part))
      .filter(part => !!part);
    if (!parts.length) return null;

    // Format order numbers, account numbers tec. if present
    const mentionedIdentifiers = formatMentionedIdentifiers(itemMeta);
    if (mentionedIdentifiers) parts.unshift(mentionedIdentifiers);

    // Do we know the "boundaries" of the conversation summary? If so, add them to the end of the "details"
    const summarySpanInfo =
      itemMeta?.fromTimestamp && itemMeta?.toTimestamp
        ? renderSummarySpanInfo(itemMeta.fromTimestamp, itemMeta.toTimestamp, formatOptions)
        : '';
    if (summarySpanInfo) {
      const itemLink = renderConversationPointLink(
        'Jump to the beginning',
        conversationItem,
        itemMeta?.fromConversation,
        itemMeta?.fromConversationItem
      );
      parts.push(
        <span>
          {summarySpanInfo}. {itemLink}
        </span>
      );
    }

    const renderedParts = parts.map((part, idx) => <li key={idx}>{part}</li>);
    return (
      <>
        <SectionTitle>
          <strong>Summary of the conversation</strong>
        </SectionTitle>
        <StyledDetailsList>{renderedParts}</StyledDetailsList>
      </>
    );
  }

  function formatMentionedIdentifiers(itemMeta) {
    const identifiers = _.trim(itemMeta.mentionedIdentifiers);
    if (!identifiers) return '';

    const formatted = identifiers
      .split('\n')
      .map(_.trim)
      .filter(part => !!part)
      .join(', ');
    return formatted ? `Key details: ${formatted}` : '';
  }

  function renderConversationPointLink(label, currentItem, targetConversationId, targetItemId) {
    if (!currentItem || !targetConversationId || !targetItemId) return null;

    const targetItemUrl = `/customer/${currentItem.customerId}/conversation/${targetConversationId}/item/${targetItemId}`;
    const handleJumpToSummaryStart = evt => {
      evt.preventDefault();

      // Create a "restore point" in the browser history so that when the agent clicks "back", they are
      // taken back to this summary item. Since our `Location` is frequently out of sync with the actual
      // browser location, we have to help it by pushing full Url of the current item to the history
      const currentItemUrl = `/customer/${currentItem.customerId}/conversation/${currentItem.conversationId}/item/${currentItem.id}`;
      if (location.pathname !== currentItemUrl) history.replaceState(null, '', currentItemUrl);

      executeAction(ScrollToConversationItem, { conversationId: targetConversationId, itemId: targetItemId });
      history.pushState(null, '', targetItemUrl);
    };

    return (
      <a href={targetItemUrl} onClick={handleJumpToSummaryStart} target="_self">
        {label || 'Jump to the item'}
      </a>
    );
  }

  function renderSummarySpanInfo(summaryStartTimestamp, summaryEndTimestamp, formatOptions = {}) {
    if (!summaryStartTimestamp || !summaryEndTimestamp) return '';

    const fromDateTime = new Date(summaryStartTimestamp);
    const toDateTime = new Date(summaryEndTimestamp);

    // Replacing 'moment' with built-in date formatters.
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    const dateFormatter = new Intl.DateTimeFormat(navigator.language, { dateStyle: 'long', ...formatOptions?.date });
    const timeFormatter = new Intl.DateTimeFormat(navigator.language, { timeStyle: 'short', ...formatOptions?.time });

    const startDate = dateFormatter.format(fromDateTime);
    const endDate = dateFormatter.format(toDateTime);
    const startTime = timeFormatter.format(fromDateTime);
    const endTime = timeFormatter.format(toDateTime);

    if (startDate === endDate) {
      return `This summary covers the conversation on ${startDate} from ${startTime} to ${endTime}`;
    } else {
      return `This summary covers messages from ${startDate} to ${endDate}`;
    }
  }

  function onThumbsUp() {
    trackFeedbackEvent({ feedback: FEEDBACK_POSITIVE });
  }
  function onThumbsDown() {
    trackFeedbackEvent({ feedback: FEEDBACK_NEGATIVE });
  }

  function trackFeedbackEvent(feedbackEvent = {}) {
    analytics.track('Timeline Conversation Summary Feedback Provided', {
      customerId: item.customerId,
      conversationId: item.conversationId,
      conversationItemId: item.id,
      summaryType: 'complete',
      ...feedbackEvent,
    });
  }
}

//
// Styled components
//
const SectionTitle = styled.div`
  font-size: 14px;
  margin-top: 4px;
`;

const StyledBubbleContent = styled(BubbleContent)`
  border: 1px solid mediumpurple;
`;

const StyledDetailsList = styled.ul`
  &&& {
    margin-top: 2px;
  }
`;

const SummaryMetaIcon = styled(SummaryIcon)`
  fill: ${p => p.theme.colors.gray600};
  height: 10px;
  margin-right: 4px;
  margin-top: -1px;
  vertical-align: middle;
  width: auto;
`;

ConversationSummaryItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  dateFormatOptions: PropTypes.object,
  timeFormatOptions: PropTypes.object,
};
