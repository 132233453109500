import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { requestNextConversation } from 'actions/conversation/lib/conversation_action_helpers';

export default class GetNextConversation {
  constructor(context) {
    this.context = context;
    this.interactiveErrorHandler = new GatewayErrorInteractiveHandler(context);
  }

  run({ conversationId } = {}) {
    if (!conversationId && this.context.stores.conversations) {
      conversationId = getActiveConversationId(this.context.stores.conversations);
    }

    return requestNextConversation(this.context, {
      agentId: this.context.stores.currentAgent.get().id,
      conversationId,
    }).catch(err => {
      this.interactiveErrorHandler.handleCommonErrors(err);
    });
  }
}
