import _ from 'lodash';

/**
 * Endpoint is used by StandardGateway to determine the http routes and mqtt
 * topics where resources are located.
 *
 * Usage
 * =====
 *
 * ```
 * // endpoint with string configuration
 *
 * let endpoint = new Endpoint('v1/orgs/:orgId/configuration/accounts');
 * endpoint = endpoint.set({ orgId: 'my-org' });
 *
 * endpoint.broadcastTopic // => 'v1/orgs/my-org/configuration/accounts'
 * endpoint.fetchUrl // => '/api/v1/orgs/my-org/configuration/accounts'
 * ```
 *
 * ```
 * // endpoint with object configuration
 *
 * let endpoint = new Endpoint({
 *   broadcastTopic: `v3/orgs/:orgId/agent-profiles/+`,
 *   fetchAllUrl: `/api/v3/orgs/:orgId/agent-profiles`,
 *   someCustomUrl: `/api/v3/orgs/:orgId/agent-profiles/:agentId/do/something/custom`,
 * });
 *
 * endpoint = endpoint.set({ orgId: 'my-org', agentId: 'my-agent' });
 * endpoint = endpoint.set({ agentId: 'different-agent' }); // agentId override
 *
 * endpoint.broadcastTopic // => `v3/orgs/my-org/agent-profiles/+`
 * endpoint.fetchAllUrl // => `/api/v3/orgs/my-org/agent-profiles`
 * endpoint.someCustomUrl // => `/api/v3/orgs/my-orgId/agent-profiles/different-agent/do/something/custom`
 * ```
 *
 * Endpoint specification
 * =======================
 *
 * Endpoints are specificed using `patterns` and `params`.
 *
 * A `pattern` looks like this: `v1/orgs/:orgId/configuration/accounts`
 *
 * `params` is a standard javascript object that contains keys for each of the
 * variables in the pattern: `{ orgId: 'my-org-id' }`.
 *
 * To determine the final resource location, the variables in the `pattern` are
 * replaced with the values in the `params`:
 * `v1/orgs/my-org/configuration/accounts`
 *
 * Endpoint configuration
 * ======================
 *
 * Endpoints are meant to be highly configurable. This flexibility allows
 * StandardGateway to work with the wide variety of backend apis that exist in
 * supernova.
 *
 * For the most common backend apis, you only need to pass a single string to
 * define the endpoint.
 *
 * For example, passing this string `v3/orgs/:orgId/agent-profiles/:agentId`
 * will generate the following endpoint patterns:
 *
 * 1. `broadcastTopic` - `v3/orgs/:orgId/agent-profiles/:agentId`
 * 2. `broadcastDeleteTopic` - `v3/orgs/:orgId/agent-profiles/:agentId/event/delete`
 * 3. `collectionBroadcastTopic` - `v3/orgs/:orgId/agent-profiles/+`
 * 4. `collectionBroadcastDeleteTopic` - `v3/orgs/:orgId/agent-profiles/+/event/delete`
 * 5. `fetchAllUrl` - `/api/v3/orgs/:orgId/agent-profiles`
 * 6. `fetchUrl` - `/api/v3/orgs/:orgId/agent-profiles/:agentId`
 * 7. `addUrl` - `/api/v3/orgs/:orgId/agent-profiles`
 * 8. `replaceUrl` - `/api/v3/orgs/:orgId/agent-profiles/:agentId`
 * 9. `updateUrl` - `/api/v3/orgs/:orgId/agent-profiles/:agentId`
 * 10. `deleteUrl` - `/api/v3/orgs/:orgId/agent-profiles/:agentId`
 *
 * If these defaults aren't correct for your use case, then don't configure the
 * endpoint with a string. Instead, pass in a patterns object that contains the
 * full set of patterns you want the endpoint to support. For example:
 *
 * ```
 * {
 *   broadcastTopic: `v3/orgs/:orgId/agent-profiles/+`,
 *   fetchAllUrl: `/api/v3/orgs/:orgId/agent-profiles`,
 *   someCustomUrl: `/api/v3/orgs/:orgId/agent-profiles/:agentId/do/something/custom`,
 * }
 * ```
 *
 * Endpoint parameterization
 * =========================
 *
 * The parameter values of an endpoint are lazily interpolated. This allows them
 * to be overriden, which is done by a call to `endpoint.set({ param: 'value' })`.
 *
 * This functionality is used in StandardGateway to default values for `orgId`
 * and `agentId` (which are passed into the Gateway in an `init()` function) on
 * the endpoint, but also allow subsequent values to override those defaults.
 *
 * Endpoint interpolation
 * ======================
 *
 * The interpolated endpoints (that is, the pattern strings with their variables
 * replaced with the values in the params object) are available on the endpoint
 * object as string properties, for example:
 * `endpoint.broadcastTopic // => 'v3/orgs/:orgId/agent-profiles/:agentId'`
 */
export default class Endpoint {
  /**
   * Create an endpoint.
   *
   * @param {(string|Object)} patterns - a string containing a default pattern,
   * or an object containing a set of patterns to interpolate. See the class
   * documentation for configuration for details.
   * @param {Object} [params={}] - a set of values to replace the variables in
   * the patterns.
   */
  constructor(patterns, params = {}) {
    this.patterns = _.isString(patterns) ? patternsFromString(patterns) : patterns;
    this.params = params;

    // define a property on this instance for each of the interpolated patterns
    _.forOwn(this.patterns, (pattern, name) => {
      Object.defineProperty(this, name, { get: () => interpolate(pattern, this.params) });
    });
  }

  /**
   * Create a new endpoint with additional params specified. This **does not**
   * mutate the current endpoint. It returns a new one with additional values
   * specified.
   *
   * @param {Object} params - the params to set on the new endpoint, these will
   * be added to the list of already specified params, overriding anything with
   * the same key.
   */
  set(params) {
    return new Endpoint(this.patterns, Object.assign({}, this.params, params));
  }

  /**
   * Clears all the params set on this endpoint. Mutates this endpoint.
   */
  reset() {
    this.params = {};
  }
}

export function interpolate(pattern, params) {
  return pattern.replace(/:([^/?&]*)/g, (i, name) => {
    if (params[name] != null) {
      return params[name];
    }
    throw new Error(`Unable to interpolate '${pattern}'. No value provided for '${name}' parameter.`);
  });
}

function patternsFromString(pattern) {
  // strip query string if any
  const [path, query = ''] = pattern.split('?');
  const collectionPattern = path.replace(/\/:[^/]+$/, '');
  const url = (p, q) => (q ? `/api/${p}?${q}` : `/api/${p}`);

  return {
    broadcastTopic: path,
    collectionBroadcastTopic: `${collectionPattern}/+`,
    broadcastDeleteTopic: `${path}/event/delete`,
    collectionBroadcastDeleteTopic: `${collectionPattern}/+/event/delete`,

    fetchAllUrl: url(collectionPattern, query),
    fetchUrl: url(pattern),
    addUrl: url(collectionPattern, query),
    replaceUrl: url(pattern),
    updateUrl: url(pattern),
    deleteUrl: url(pattern),
  };
}
