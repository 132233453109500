import createEnum from 'scripts/lib/create_enum';

export default createEnum(
  'ADMIN',
  'ASSIGNED_CONVERSATION',
  'ASSIGNED_TASK',
  'AUTO_ACCEPTED_SESSION_NOTIFICATION',
  'DECLINED_REASSIGNED_SESSION',
  'DISCONNECT',
  'EMBED_TOKEN_EXPIRING',
  'ERROR',
  'FOCUS_MODE_DISABLED',
  'INBOUND_COMMUNICATION_SESSION',
  'INBOUND_MESSAGE',
  'MANDATORY_UPGRADE',
  'MULTIPLE_TABS',
  'OFFERED_CALL',
  'OFFERED_WARM_TRANSFER',
  'OPTIONAL_RELOAD',
  'OPTIONAL_UPGRADE',
  'PAYMENT_STATUS_EVENT',
  'PHONE_CALL_OBSERVER',
  'SUCCESS',
  'UNOFFERED_CALL',
  'UNOFFERED_WARM_TRANSFER'
);
