import includes from 'lodash/includes';

const BASE_ELEMENTS = ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header'];

export const basePasteRules = [
  {
    deserialize(el, next) {
      // Return an empty block for <div><br/></div>
      if (isEmptyBlock(el)) {
        return { object: 'block', type: 'paragraph', nodes: [] };
      }

      // Mid-block <br/> should just create a newline character
      if (el.tagName && el.tagName.toLowerCase() === 'br') {
        return {
          object: 'text',
          leaves: [
            {
              object: 'leaf',
              text: '\n',
            },
          ],
        };
      }

      if (includes(BASE_ELEMENTS, el.tagName.toLowerCase())) {
        const textAlign = el.style.textAlign;
        return { object: 'block', type: 'paragraph', nodes: next(el.childNodes), data: { textAlign } };
      }

      return next();
    },
  },
];

function isEmptyBlock(el) {
  return (
    el.tagName &&
    el.tagName.toLowerCase() === 'div' &&
    el.childNodes.length === 1 &&
    el.childNodes[0].tagName &&
    el.childNodes[0].tagName.toLowerCase() === 'br'
  );
}
