import { getNodeTexts, getPoint, isCollapsed } from '@udecode/plate';
import { Point, Transforms } from 'slate';
import focusEndOfEditor from './focus_end_of_editor';

export default function ensureCursorIsAtStart(editor) {
  const _texts = getNodeTexts(editor, { at: [] });
  const textEntries = Array.from(_texts);
  const firstTextEntry = textEntries[0];
  const firstTextStartPoint = { path: firstTextEntry[1], offset: 0 };

  if (!editor.selection) {
    focusEndOfEditor(editor);
  }

  const compare = Point.compare(editor.selection.anchor, firstTextStartPoint);

  if (!isCollapsed(editor.selection) || compare !== 0) {
    const point = getPoint(editor, firstTextEntry[1], { edge: 'start' });
    Transforms.select(editor, { anchor: point, focus: point });
  }
}
