import { trim } from 'lodash';

/**
 * When we serialize, we change `<div>` to `<div><br/></div>`. Now we need to apply the reverse transformation
 * in order to avoid extra line breaks. This transform is invoked when HTML content is pasted into the editor.
 */
export function removeExtraBreaks(sourceHtml) {
  const VALID_NODE_NAMES = ['P', 'DIV'];
  const ELEMENT_BREAK = 'BR';

  const trimmedHtml = trim(sourceHtml);
  if (!trimmedHtml) return '';

  const parsedDocument = new DOMParser().parseFromString(trimmedHtml, 'text/html');
  if (!parsedDocument?.body || !parsedDocument.body.hasChildNodes()) return ''; // Very unlikely to happen

  for (const node of parsedDocument.body.childNodes) {
    if (
      VALID_NODE_NAMES.includes(node.nodeName) &&
      node.childNodes.length === 1 &&
      node.childNodes[0].nodeName === ELEMENT_BREAK
    ) {
      node.removeChild(node.childNodes[0]);
    }
  }
  return parsedDocument.body.innerHTML;
}
