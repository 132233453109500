import React from 'react';
import PropTypes from 'prop-types';

import Agent from 'models/agent';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import { ReasonEnded } from 'models/chat_ended';

export function ChatEndedItem({ agent, currentAgentId, item }) {
  const endedBy = getEndedBy();
  return (
    <ItemContent isAuditItem item={item}>
      <Metadata isInbound={false} subject={endedBy} text={getDescription()} timestamp={item.timestamp} />
    </ItemContent>
  );

  function getDescription() {
    switch (item.content.reasonEnded) {
      case ReasonEnded.AGENT_ENDED:
        return 'ended the chat';

      case ReasonEnded.CONSUMER_NO_REPLY:
        return 'No response from customer. Chat has ended';

      case ReasonEnded.TIME_EXPIRED:
        return 'No activity. Chat has ended';

      default:
        return endedBy ? 'ended the chat' : 'The chat has ended';
    }
  }

  function getEndedBy() {
    const wasEndedAutomatically = item.initiator.type === Communicator.AUTOMATED;
    if (wasEndedAutomatically) {
      return undefined;
    }

    const wasEndedByCurrentAgent = agent && agent.id === currentAgentId;
    return wasEndedByCurrentAgent ? 'You' : (agent && agent.name) || 'An agent';
  }
}

ChatEndedItem.propTypes = {
  agent: PropTypes.instanceOf(Agent), // Agent that ended the chat
  currentAgentId: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

const mapStateToProps = function(context, { item }) {
  return {
    agent: context.getProvider('agents').findBy({ id: item.initiator.id }),
    currentAgentId: context.getProvider('currentAgent').get().id,
    item,
  };
};

export default connect(mapStateToProps)(ChatEndedItem);
