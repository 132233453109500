import _ from 'lodash';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';
import PropTypes from 'prop-types';

import ActionMixin from 'components/lib/action_mixin';
import AppFeaturesMixin from 'components/lib/app_features_mixin';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';
import UpdateKbVariable from 'actions/knowledge_base/update_kb_variable';
import VariablesAdminList from './variables_admin_list';

const VariablesAdminListContainer = createReactClass({
  mixins: [ActionMixin, AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  propTypes: {
    textFilter: PropTypes.string,
  },

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('kbVariables'));
  },

  onChangeVariable(attrs) {
    this.executeAction(UpdateKbVariable, attrs);
  },

  getSortedFilteredVariables() {
    let variables = this.getProvider('kbVariables').findAll();
    let sortedVariables = _.sortBy(variables, variable => variable.name.toLowerCase());
    if (!this.props.textFilter) {
      return sortedVariables;
    }

    let textFilter = this.props.textFilter.replace(/\s/g, '').replace(/([()[{*+.$^\\|?])/g, '\\$1');
    return _.filter(sortedVariables, variable => {
      let filterRegExp = new RegExp(textFilter, 'i');
      return filterRegExp.test(variable.name.replace(/\s/g, ''));
    });
  },

  isSavePending(entityId) {
    return this.getProvider('kbVariables').isPending(entityId);
  },

  render() {
    let variables = this.getSortedFilteredVariables();
    if (!variables.length) {
      return null;
    }

    return (
      <VariablesAdminList
        isSavePending={this.isSavePending}
        onChangeVariable={this.onChangeVariable}
        readOnly={!this.isFeatureEnabled('kbManagement')}
        variables={variables}
      />
    );
  },
});

export default VariablesAdminListContainer;
