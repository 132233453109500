import CoBrowsingSessionData from 'models/cobrowsing_session_data';

const STORAGE_KEY = 'cobrowsing-sessions';

export default class LocalCoBrowsingSessionStore {
  constructor(storage, key) {
    this.storage = storage || sessionStorage;
    this.key = key || STORAGE_KEY;
  }

  clear() {
    this.storage.removeItem(this.key);
  }

  /**
   * Saves the list of sessions to the storage
   *
   * @param sessionList [CoBrowsingSessionData]
   */
  set(sessionList) {
    const mapped = (sessionList || []).map(sessionModel => sessionModel.toJs());
    this.storage.setItem(this.key, JSON.stringify(mapped));
  }

  /**
   * Loads session list from the storage and converts to array of CoBrowsingSessionData models
   */
  findAll() {
    const json = this.storage.getItem(this.key);
    const items = json ? JSON.parse(json) : [];
    return items.map(item => CoBrowsingSessionData.create(item));
  }

  find(id) {
    const items = this.findAll();
    return items.find(item => item.id === id);
  }

  /**
   * Adds or replaces the session item
   * @param session
   */
  replace(session) {
    const items = this.findAll();
    const without = items.filter(item => item.id !== session.id);
    const newItem = session instanceof CoBrowsingSessionData ? session : CoBrowsingSessionData.create(session);
    this.set([...without, newItem]);
  }

  remove(id) {
    const items = this.findAll();
    const without = items.filter(item => item.id !== id);
    this.set(without);
  }

  add(session) {
    this.replace(session);
  }

  count() {
    const items = this.findAll();
    return items.length;
  }
}
