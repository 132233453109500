import InteractionType from 'models/interaction_type';

export default class UpdateTypingStatus {
  constructor(context) {
    this.context = context;
  }

  run({ typingState, customerId }) {
    const customerAddress = getCustomerAddress(this.context, customerId);
    const companyAddress = getCompanyAddress(this.context, customerId);
    this.context.gateways.chatTyping.updateTypingStatus({ companyAddress, customerAddress, customerId, typingState });
  }
}

function getCustomerAddress(context, customerId) {
  const chatSession = getChatSession(context, customerId);
  if (!chatSession) {
    return;
  }

  return chatSession.customerIdentifier;
}

function getCompanyAddress(context, customerId) {
  const chatSession = getChatSession(context, customerId);
  if (!chatSession) {
    return;
  }

  return chatSession.endpointAddress;
}

function getChatSession(context, customerId) {
  const activeSessionsStore = context.stores.activeSessions;
  const activeSession = activeSessionsStore.findBy({ customer: { id: customerId } });
  if (!activeSession) {
    return;
  }

  return activeSession.queueItem.getSessionByType(InteractionType.CHAT);
}
