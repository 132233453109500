import React from 'react';
import { Portal as ReactPortal } from 'react-portal';
import PropTypes from 'prop-types';

/**
 * Testable wrapper around Portal.
 *
 * To disable portal for testing:
 * ```
 * Portal.isDisabled = true;
 * ```
 */

export default class Portal extends React.Component {
  render() {
    if (Portal.isDisabled) {
      return this.props.children;
    }

    return <ReactPortal node={this.props.node}>{this.props.children}</ReactPortal>;
  }
}

Portal.propTypes = {
  children: PropTypes.node,
  node: PropTypes.object,
};
