import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { CardRow, NumberCard } from 'components/liveboards/cards/metric_card';
import Conversation from 'models/conversation';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import LabeledCard from 'components/liveboards/cards/labeled_card';
import Liveboards from 'models/location/liveboards';
import LiveboardType from 'models/liveboards/liveboard_type';
import Search from 'models/location/search';

export default function TopicsHeader({ includeLinks, liveboard, routingGroupId, routingGroupIds, title }) {
  const sla = liveboard.calculatePctSla(liveboard.totals.sla.over, liveboard.totals.sla.total);
  const conversations = liveboard.totals.conversations;

  return (
    <LabeledCard className="topicsLiveboard-totals" title={title || 'All Inboxes'}>
      <CardRow className="topicsLiveboard-row">
        <NumberCard
          label="Online Agents"
          link={
            includeLinks &&
            getLocationUrl(Liveboards.create({ type: LiveboardType.AGENTS, routingGroupId, routingGroupIds }))
          }
          value={liveboard.totals.agentsOnline}
        />
        <NumberCard
          label="New Conversations"
          link={
            includeLinks &&
            getLocationUrl(
              Search.createFiltered({
                inboxIds: routingGroupIds ? routingGroupIds : [routingGroupId],
                statuses: [Conversation.InboxStatus.NEW],
              })
            )
          }
          value={conversations.openUnassigned}
        />
        <NumberCard
          label="Open Conversations"
          link={
            includeLinks &&
            getLocationUrl(
              Search.createFiltered({
                inboxIds: routingGroupIds ? routingGroupIds : [routingGroupId],
                statuses: [Conversation.InboxStatus.OPEN],
              })
            )
          }
          value={conversations.openAssigned}
        />
        <NumberCard
          label="Waiting Conversations"
          link={
            includeLinks &&
            getLocationUrl(
              Search.createFiltered({
                inboxIds: routingGroupIds ? routingGroupIds : [routingGroupId],
                statuses: [Conversation.InboxStatus.WAITING],
              })
            )
          }
          value={conversations.waiting}
        />
        <NumberCard
          label="Closed Today"
          link={
            includeLinks &&
            getLocationUrl(
              Search.createFiltered({
                inboxIds: routingGroupIds ? routingGroupIds : [routingGroupId],
                statuses: [Conversation.InboxStatus.CLOSED],
                updatedAt: {
                  gte: moment
                    .tz(moment(), liveboard.timezone)
                    .startOf('day')
                    .toISOString(),
                  lte: moment
                    .tz(moment(), liveboard.timezone)
                    .endOf('day')
                    .toISOString(),
                },
              })
            )
          }
          value={conversations.closedToday}
        />
        <NumberCard decimalPlaces={1} label="% Within SLA" value={sla && 100 * sla} zeroValue="–" />
      </CardRow>
    </LabeledCard>
  );
}

TopicsHeader.propTypes = {
  includeLinks: PropTypes.bool,
  liveboard: PropTypes.any,
  routingGroupId: PropTypes.string,
  routingGroupIds: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};
