import Dotdotdot from 'react-dotdotdot';
import { faExpandAlt } from '@fortawesome/pro-light-svg-icons/faExpandAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import AnswerPanelDropdownMenu from 'components/customer/answer_panel/lib/answer_panel_dropdown_menu';
import AnswerPreviewModal from 'components/customer/answer_panel/preview/answer_preview_modal';
import BackButton from 'components/customer/answer_panel/preview/answer_preview_back_button';
import Button, { ButtonTypes } from 'components/common/button';
import { H3 } from 'components/common/headers';
import InsertSelectedAnswer from 'actions/answers/insert_selected_answer';
import { Languages } from 'models/answers/snippet_channels';
import SelectAnswer from 'actions/answers/select_answer';
import { SnippetChannel } from 'models/answers/snippet';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function Header() {
  const { canInsertAnswer, selectedAnswer, selectedAnswerLanguage, selectedAnswerType } = useContext(
    AnswerPanelContext
  );

  let actionButton = <UseButton isDisabled={!canInsertAnswer} />;
  if (selectedAnswerType === SnippetChannel.INFO) {
    actionButton = (
      <ExpandButton answer={selectedAnswer} answerType={selectedAnswerType} language={selectedAnswerLanguage} />
    );
  } else if (selectedAnswerType === SnippetChannel.SELF_SERVICE) {
    actionButton = null;
  }

  return (
    <HeaderStyled>
      <Primary>
        <AnswerText>
          <BackButton />
          <AnswerTitle data-aid="answerPanel-preview-title">
            <Dotdotdot clamp={3}>{selectedAnswer.name}</Dotdotdot>
          </AnswerTitle>
        </AnswerText>
        <SelectLanguage answer={selectedAnswer} language={selectedAnswerLanguage} type={selectedAnswerType} />
      </Primary>
      {actionButton}
    </HeaderStyled>
  );
}

const HeaderStyled = styled.div`
  background-color: ${p => p.theme.colors.gray100};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-shrink: 0;
  min-height: 64px;
  padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.large} ${p => p.theme.spacing.medium}
    ${p => p.theme.spacing.large};
  width: 100%;
`;

const Primary = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const AnswerText = styled.div`
  margin-right: ${p => p.theme.spacing.large};
`;
const AnswerTitle = styled(H3)`
  margin-bottom: ${p => p.theme.spacing.xsmall};
`;

function ExpandButton({ answer, answerType, language }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const onClick = useCallback(() => setIsExpanded(true), []);
  const onClose = useCallback(() => setIsExpanded(false), []);

  return (
    <React.Fragment>
      <Expand data-aid="answerPanel-preview-expandButton" onClick={onClick}>
        <FontAwesomeIcon icon={faExpandAlt} />
      </Expand>
      {isExpanded ? (
        <AnswerPreviewModal answer={answer} answerType={answerType} language={language} onClose={onClose} />
      ) : null}
    </React.Fragment>
  );
}

const Expand = styled.div`
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.medium};
  &:hover {
    color: ${p => p.theme.colors.green400};
  }
`;

function UseButton({ isDisabled }) {
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    executeAction(InsertSelectedAnswer);
  }, []);

  return (
    <Use>
      <Button
        buttonType={ButtonTypes.PRIMARY}
        data-aid="answerPanel-preview-useButton"
        debounceInterval={500}
        disabled={isDisabled}
        onClick={onClick}
      >
        Use
      </Button>
    </Use>
  );
}

const Use = styled.div``;

function SelectLanguage({ answer, language, type }) {
  const executeAction = useExecuteAction();
  const onSelect = useCallback(
    value => {
      if (value !== language) {
        executeAction(SelectAnswer, { answer, answerLanguage: value, answerType: type });
      }
    },
    [answer, language, type]
  );
  const options = useOptions(answer);

  if (options.length === 1) {
    return <OnlyText data-aid="answerPanel-preview-onlyLanguage">{Languages[language]}</OnlyText>;
  }

  return (
    <AnswerPanelDropdownMenu
      data-aid="answerPanel-preview-selectLanguage"
      onSelect={onSelect}
      options={options}
      text={`View in ${Languages[language]}`}
      value={language}
    />
  );
}

const OnlyText = styled.span`
  color: ${p => p.theme.colors.gray900};
  font-size: ${p => p.theme.fontSize.small};
`;

function useOptions(answer) {
  return useMemo(
    () =>
      answer.contents.map(c => ({
        label: Languages[c.language],
        value: c.language,
      })),
    [answer]
  );
}
