import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M8 16A8 8 0 108 0a8 8 0 000 16zM9 3a.5.5 0 00-1 0v5.293l-2.854 2.853a.5.5 0 00.708.708l3-3A.5.5 0 009 8.5V3z"
      fillRule="evenodd"
    />
  </Icon>
);
