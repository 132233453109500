import _ from 'lodash';
import moment from 'moment';

import Assignee from './assignee';
import ConversationItem from './conversation_item';
import createModel, { prop } from './lib/create_model';
import CustomerProfile from './customer_profile';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import PhoneCall, { TransferStatus } from './phone_call';

export default createModel({
  modelName: 'ActiveCall',

  properties: {
    id: prop(String),
    conversationId: prop(String),
    conversationItem: prop(ConversationItem),
    customer: prop(CustomerProfile),
    hasLivePhoneConnection: prop(Boolean).default(true),
    hasAgentClickedAcceptCall: prop(Boolean).default(false),
    initiator: prop(Assignee),
    routingGroupId: prop(String),
    transferee: prop(Assignee),
    searchResultId: prop(String),
    shouldSkipMatch: prop(Boolean),
  },

  didWarmTransferFail() {
    const phoneCall = this.conversationItem.content;
    if (
      phoneCall.transfer &&
      (phoneCall.transfer.status === TransferStatus.NOT_AVAILABLE || phoneCall.transfer.status === TransferStatus.ERROR)
    ) {
      return true;
    }

    if (!this.transferee || this.conversationItem.content.status === PhoneCall.Status.WARM_TRANSFER) {
      return false;
    }

    let transfereeParticipant = this.conversationItem.content.findParticipantById(this.transferee.agentId);
    return transfereeParticipant ? PhoneCall.isParticipantUnavailable(transfereeParticipant) : false;
  },

  isTransfereeOnCall() {
    return this.conversationItem.content.isTransfereeOnCall();
  },

  canCompleteTransfer() {
    return this.conversationItem.content.canCompleteTransfer();
  },

  isAgentOnLiveCall(agentId) {
    if (this.isParticipantObserver(agentId)) {
      let participant = this.conversationItem.content.findParticipantById(agentId);
      if (PhoneCall.isActiveParticipant(participant)) {
        return true;
      }
      return false;
    }
    return this._isAgentOnCall(agentId) || this._isOutgoing() || this.hasAgentAcceptedCall();
  },

  _isAgentOnCall(agentId) {
    let agentParticipant = this.conversationItem.content.findParticipantById(agentId);
    return (
      this.conversationItem.content.isLive() &&
      !this.conversationItem.content.isWarmTransferRecipient(agentId) &&
      agentParticipant &&
      PhoneCall.isActiveParticipant(agentParticipant)
    );
  },

  hasCallOwnership(agentId) {
    // assumes max of 2 active agents on a call at once
    let activeAgentParticipants = this.conversationItem.content.getActiveAgentParticipants();
    if (activeAgentParticipants.length === 0) {
      return false;
    }
    if (activeAgentParticipants.length === 1) {
      return true;
    }
    // Attempting to determind ownership by who diled in first.
    // TODO: need to change this, unclear what the use case is and if we can look at call assignment instead.
    // CH93393
    let participantEvents = _.groupBy(this.conversationItem.content.participantEvents, event => event.participantId);
    let currentAgentDialingEvent = _.findLast(
      participantEvents[agentId],
      event => event.participantStatus === PhoneCall.ParticipantStatus.DIALING
    );
    let otherActiveAgent = _.find(activeAgentParticipants, participant => participant.participantId !== agentId);
    let otherAgentDialingEvent = _.findLast(
      participantEvents[otherActiveAgent.participantId],
      event => event.participantStatus === PhoneCall.ParticipantStatus.DIALING
    );
    return moment(currentAgentDialingEvent.eventTime).isBefore(otherAgentDialingEvent.eventTime);
  },

  isTransferLive() {
    return this.conversationItem.content.isTransferLive();
  },

  _isOutgoing() {
    return this.conversationItem.content.isOutgoing();
  },

  isParticipantObserver(agentId) {
    return this.conversationItem.content.isParticipantObserver(agentId);
  },

  isParticipantAgent(agentId) {
    return this.conversationItem.content.isParticipantAgent(agentId);
  },

  hasAgentAcceptedCall() {
    return this.hasAgentClickedAcceptCall;
  },

  setAgentHasAcceptedCall() {
    this.hasAgentClickedAcceptCall = true;
  },

  updateCustomer(customer) {
    this.customer = customer;
  },

  updateConversationId(conversationId) {
    this.conversationId = conversationId;
  },

  updateConversationItem(conversationItem) {
    this.conversationItem = conversationItem;
  },

  shouldDisplayIncomingCallNotification(agentId) {
    // do not show when there is not live connection
    if (!this.hasLivePhoneConnection) {
      return false;
    }
    // do not show when the call is outbound
    if (this.conversationItem.content.isOutgoing()) {
      return false;
    }
    // do not show when call already have been accepted
    if (this.hasAgentAcceptedCall()) {
      return false;
    }

    // show when call is in dialing state and not an observer
    if (this.conversationItem.content.isDialing(agentId) && this.isParticipantAgent(agentId)) {
      return true;
    }

    // show when agent is observing a call and receives inbound warm transfer
    let participant = this.conversationItem.content.findParticipantById(agentId);
    if (
      this.isParticipantObserver(agentId) &&
      PhoneCall.isActiveParticipant(participant) &&
      this.conversationItem.content &&
      this.conversationItem.content.getIncomingCallType(agentId) === PhoneCall.IncomingCallType.WARM_TRANSFER &&
      this.conversationItem.content.transfer &&
      this.conversationItem.content.transfer.getStatus() === TransferStatus.INITIATED &&
      this.conversationItem.content.transfer.toParticipantId === agentId
    ) {
      return true;
    }

    return false;
  },

  setTransferee(transferee) {
    this.transferee = transferee;
  },

  clearTransferee() {
    this.transferee = null;
  },

  setInitiator(initiator) {
    this.initiator = initiator;
  },

  setHasLiveConnection(isLive) {
    this.hasLivePhoneConnection = isLive;
  },

  hasLiveConnection() {
    return this.hasLivePhoneConnection;
  },

  setSearchResultId(resultId) {
    this.searchResultId = resultId;
  },

  setShouldSkipMatch(shouldSkipMatch) {
    this.shouldSkipMatch = shouldSkipMatch;
  },

  statics: {
    create(attrs) {
      return new this({
        ...attrs,
        id: IdGenerator.newId(),
      });
    },
  },
});
