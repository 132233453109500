import AnswersPreview from 'models/location/answers_preview';
import OpenAnswersPreview from 'actions/answers/open_answers_preview';
import { registerUrlConverter } from './location_url';
import StaticUrlConverter from './url_converters/static_url_converter';

registerUrlConverter(AnswersPreview, StaticUrlConverter.create('/answers-preview'));

export default function(router, { runnerContext }) {
  return {
    on() {
      runnerContext.executeAction(OpenAnswersPreview);
    },
  };
}
