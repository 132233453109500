import getPollingInterval from './get_polling_interval';
import { AgentInboxPollId } from 'actions/inbox/agent/agent_inbox_constants';
import { PollAgentInbox as PollAgentInboxDep } from './request_agent_inbox_deprecated';

export default class ChangePollingInterval {
  constructor(context) {
    this.context = context;
  }

  run() {
    const appFeatures = this.context.stores.appFeatures.get();

    // New inbox doesn't use polling - we rely exclusively on assignment broadcasts and deletes
    if (appFeatures.isEnabled('customerListAssignmentBroadcast')) {
      return;
    }

    this.context.scheduler.cancel(AgentInboxPollId);
    const pollingInterval = getPollingInterval(this.context);
    this.context.scheduler.executeEvery(pollingInterval, AgentInboxPollId, PollAgentInboxDep);
  }
}
