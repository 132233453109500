import _ from 'lodash';
import Observable from 'scripts/lib/observable_mixin';
import qs from 'qs';

export default class ServerInfoGateway {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  fetch(params) {
    this.httpClient.get(`/server${(params && '?' + qs.stringify(params)) || ''}`, this.handleResponse.bind(this));
  }

  handleResponse(err, req) {
    if (err) {
      return;
    }

    if (req.status >= 200 && req.status < 300) {
      this.notifyObservers('handleUpdate', req.response);
    }
  }
}

_.extend(ServerInfoGateway.prototype, Observable);
