import _ from 'lodash';
import Communicator from 'models/communicator';
import ConversationItemType from 'models/conversation_item_type';
import PhoneCall from 'models/phone_call';

export default class ConversationItemsService {
  constructor(publisher, getDb) {
    this.publisher = publisher;
    this.getDatabase = getDb;
  }

  _conversationItems(orgId, customerId) {
    return _.find(this._getCustomers(orgId), { id: customerId }).conversationHistory;
  }

  find(orgId, customerId, conversationItemId) {
    let history = this._conversationItems(orgId, customerId);
    return _.find(history, { id: conversationItemId });
  }

  findItemIdsByCustomerId(orgId, customerId) {
    let history = this.findByCustomerId(orgId, customerId);
    return (
      history && history.map(item => ({ id: item.id, conversationId: item.conversationId, timestamp: item.timestamp }))
    );
  }

  findByCustomerId(orgId, customerId) {
    let customer = _.find(this._getCustomers(orgId), { id: customerId });
    return customer && _.map(customer.conversationHistory, item => _.assign({}, item));
  }

  findByQuery(orgId, customerId, payload) {
    let history = this._conversationItems(orgId, customerId);
    if (payload.filter && payload.filter.ids) {
      return _(history)
        .filter(({ id }) => _.includes(payload.filter.ids, id))
        .map(item => _.assign({}, item))
        .value();
    }
    return [];
  }

  findByConversationId(orgId, conversationId) {
    let customerWithMatchingConversation = _(this._getCustomers(orgId)).find(cust =>
      _(cust.conversations).find({ id: conversationId })
    );

    if (!customerWithMatchingConversation) {
      return [];
    }

    return _.filter(
      customerWithMatchingConversation.conversationHistory,
      item => item.conversationId === conversationId
    );
  }

  fetchCurrentItems(orgId, conversationId) {
    let conversationItems = _.orderBy(this.findByConversationId(orgId, conversationId), 'timestamp', 'desc');
    let latestNonAutomaticInitiatorItemsOfEachType = _(conversationItems)
      .filter(item => !item.initiator || item.initiator.type !== Communicator.AUTOMATED)
      .uniqBy('content.type')
      .value();
    let latestCustomerItem = _.find(
      conversationItems,
      item => item.initiator && item.initiator.type === Communicator.CUSTOMER
    );
    let openTasks = _.filter(
      conversationItems,
      item => item.content.type === ConversationItemType.TASK && !item.content.closedAt
    );
    let activePhoneCalls = _.filter(
      conversationItems,
      item =>
        item.content.type === ConversationItemType.PHONE_CALL &&
        [
          PhoneCall.Status.ABANDONED,
          PhoneCall.Status.COMPLETED,
          PhoneCall.Status.DECLINED,
          PhoneCall.Status.ERROR,
          PhoneCall.Status.RECEIVING,
        ].indexOf(item.content.status) === -1
    );
    return {
      latestNonAutomaticInitiatorItemsOfEachType,
      latestCustomerItem,
      openTasks,
      activePhoneCalls,
    };
  }

  add(orgId, customerId, { correlationId, payload }) {
    let customer = _.find(this._getCustomers(orgId), { id: customerId });
    if (!customer.conversationHistory) {
      customer.conversationHistory = [];
    }
    let history = customer.conversationHistory;
    let conversationItem = payload;
    conversationItem.customerId = customerId;
    history.push(conversationItem);

    if (_.get(conversationItem, 'content.type') === 'ITEM_LINK') {
      const { itemId: originalItemId, customerId: originalCustomerId } = conversationItem.content;

      const originalCustomer = _.find(this._getCustomers(orgId), { id: originalCustomerId });
      const originalItem = _.find(originalCustomer.conversationHistory, { id: originalItemId });
      originalItem.version += 1;

      const copy = _.clone(originalItem);
      copy.linkedItems = [
        {
          customerId,
          customerProfile: {
            name: customer.profile.name,
          },
          itemId: conversationItem.id,
        },
      ];
      this.publisher.publishConversationItem(correlationId, orgId, originalCustomerId, copy);

      conversationItem.content.customerProfile = {
        name: originalCustomer.profile.name,
      };
      conversationItem.content.originalContent = copy.content;
      conversationItem.content.originalInitiator = copy.initiator;
      conversationItem.content.originalTimestamp = copy.timestamp;
    }

    this.publisher.publishConversationItem(correlationId, orgId, customerId, conversationItem);
  }

  update(orgId, customerId, conversationItemId, { correlationId, payload }) {
    let history = this._conversationItems(orgId, customerId);
    let conversationItem = _.find(history, { id: conversationItemId });

    if (_.get(payload, 'content.reopen') && _.get(conversationItem, 'content.type') === ConversationItemType.TASK) {
      conversationItem.content.closedAt = null;
      payload = {};
    }

    conversationItem = _.mergeWith(conversationItem, payload, (objVal, srcVal) => {
      if (_.isArray(srcVal)) {
        return srcVal;
      }
      // intentionally return undefined otherwise so default merge takes over
      return undefined;
    });
    conversationItem.version++;
    this.publisher.publishConversationItem(correlationId, orgId, customerId, conversationItem);
  }

  redactAttachment(correlationId, orgId, customerId, conversationItemId, attachmentId) {
    let item = this.find(orgId, customerId, conversationItemId);
    let attachments = _.get(
      item,
      'content.attachments',
      _.get(item, 'content.content.files', _.get(item, 'content.content.images', []))
    );
    const redactedAttachments = attachments.map(attachment => {
      if (attachment.id === attachmentId) {
        attachment.isRedacted = true;
      }
    });
    item.attachments = redactedAttachments;
    item.version++;
    this.publisher.publishConversationItem(correlationId, orgId, customerId, item);
  }

  delete(orgId, customerId, conversationItemId) {
    let history = this._conversationItems(orgId, customerId);
    let linkItemInd = _.findIndex(history, { id: conversationItemId });
    if (linkItemInd !== -1) {
      // delete link item
      let linkItem = history.splice(linkItemInd, 1)[0];
      // remove link from original
      this.update(orgId, linkItem.content.customerId, linkItem.content.itemId, {
        payload: {
          linkedItems: [],
        },
      });
      this.publisher.publishDelete(orgId, customerId, linkItem.id);
    }
  }

  _getCustomers(orgId) {
    return this.getDatabase(orgId).customers;
  }

  static create(pubsub, getDb) {
    return new ConversationItemsService(new ConversationItemPublisher(pubsub), getDb);
  }
}

export class ConversationItemPublisher {
  constructor(pubsub) {
    this.pubsub = pubsub;
  }

  publishConversationItem(correlationId, orgId, customerId, conversationItem) {
    this.pubsub.publish(`v1/orgs/${orgId}/customer-history/${customerId}/conversation-items/${conversationItem.id}`, {
      correlationId,
      payload: conversationItem,
    });

    let itemId = {
      id: conversationItem.id,
      conversationId: conversationItem.conversationId,
      timestamp: conversationItem.timestamp,
    };
    this.pubsub.publish(`v1/orgs/${orgId}/customer-history/${customerId}/item-ids/${conversationItem.id}`, {
      correlationId,
      payload: itemId,
    });
  }

  publishDelete(orgId, customerId, itemId) {
    this.pubsub.publish(
      `v1/orgs/${orgId}/customer-history/${customerId}/conversation-items/${itemId}/event/delete`,
      {}
    );
    this.pubsub.publish(`v1/orgs/${orgId}/customer-history/${customerId}/item-ids/${itemId}/event/delete`, {});
  }
}
