import { Factory } from 'rosie';

Factory.define('PhoneNumber');

Factory.define('PhoneNumberWithDefaults')
  .extend('PhoneNumber')
  .attr('extension', '2605')
  .attr('label', '')
  .attr('normalized', '+14158675309')
  .attr('original', '415-867-5309')
  .attr('primary', false)
  .attr('regionCode', 'US')
  .attr('type', 'OFFICE');
