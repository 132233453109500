import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styled from 'styled-components';

import Modal from 'components/lib/modal';
import SlateEditor from 'components/customer/composition/lib/slate/slate_editor';

function SelfServiceModal({ name, onClose, value, direction }) {
  return (
    <Portal node={document && document.getElementById('selfServiceModal-container')}>
      <div>
        <ReactCSSTransitionGroup
          transitionAppear
          transitionAppearTimeout={1000}
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1}
          transitionName="Modal-backdrop"
        >
          <div className="Modal-backdrop" />
        </ReactCSSTransitionGroup>
        <Modal>
          <SelfServiceModalWidget dir={direction || 'ltr'}>
            <SelfServiceModalHeader>
              <SelfServiceModalWidgetBack dir={direction || 'ltr'} onClick={onClose}>
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </SelfServiceModalWidgetBack>
              <SelfServiceModalWidgetClose onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
              </SelfServiceModalWidgetClose>
            </SelfServiceModalHeader>
            <SelfServiceModalName dir={direction || 'ltr'}>{name}</SelfServiceModalName>
            <SelfServiceModalBody dir={direction || 'ltr'}>
              <SlateEditor readOnly value={value || ''} />
            </SelfServiceModalBody>
            <SelfServiceModalFooter dir={direction || 'ltr'}>
              <FontAwesomeIcon icon={faComment} /> Chat with Us!
            </SelfServiceModalFooter>
          </SelfServiceModalWidget>
        </Modal>
      </div>
    </Portal>
  );
}

SelfServiceModal.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func,
  value: PropTypes.object,
  direction: PropTypes.string,
};

export default SelfServiceModal;

const SelfServiceModalWidget = styled.div`
  background: ${p => p.theme.colors.white};
  border-radius: 8px;
  box-shadow: ${p => p.theme.boxShadow.large};
  display: flex;
  flex-direction: column;
  height: 580px;
  padding: 15px 25px;
  position: relative;
  width: 400px;
`;

const SelfServiceModalHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: ${p => p.theme.fontSize.base};
  justify-content: space-between;
  padding-bottom: ${p => p.theme.spacing.stackMedium};
`;

const SelfServiceModalName = styled.div`
  font-size: ${p => p.theme.fontSize.large};
  font-weight: bold;
  padding-bottom: ${p => p.theme.spacing.stackSmall};
  padding-left: ${p => (p.dir === 'ltr' ? p.theme.spacing.insetSmall : 'auto')};
  padding-right: ${p => (p.dir === 'rtl' ? p.theme.spacing.insetSmall : 'auto')};
`;

const SelfServiceModalBody = styled.div`
  flex: 1 1 auto;
  padding-bottom: 80px;
  padding-left: ${p => (p.dir === 'ltr' ? p.theme.spacing.insetSmall : 'auto')};
  padding-right: ${p => (p.dir === 'rtl' ? p.theme.spacing.insetSmall : 'auto')};
  overflow-y: auto;
`;

const SelfServiceModalFooter = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.gray100};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  color: ${p => p.theme.colors.gray400};
  display: flex;
  font-weight: bold;
  height: 75px;
  justify-content: center;
  ${p => (p.dir === 'rtl' ? 'right: 0' : 'left: 0')};
  position: absolute;
  width: 100%;
  & svg {
    margin-right: ${p => (p.dir === 'ltr' ? p.theme.spacing.inlineSmall : 0)};
    margin-left: ${p => (p.dir === 'rtl' ? p.theme.spacing.inlineSmall : 0)};
  }
`;

const SelfServiceModalWidgetClose = styled.div`
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.large};
`;

const SelfServiceModalWidgetBack = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  & svg {
    margin-right: ${p => (p.dir === 'ltr' ? p.theme.spacing.inlineSmall : 'auto')};
    margin-left: ${p => (p.dir === 'rtl' ? p.theme.spacing.inlineSmall : 'auto')};
  }
`;
