import { Transforms } from 'slate';

import analytics from 'scripts/lib/analytics';
import { removeExtraBreaks } from 'components/text_editor_new/lib/remove_extra_breaks';
import { removeAppleLineBreaks } from 'components/text_editor_new/lib/remove_apple_linebreaks';
import sanitize from 'components/text_editor_new/lib/sanitize_html';
import { stripZeroWidthCharacters } from 'components/text_editor_new/lib/strip_zero_width_characters';

export default function withPaste(editor) {
  const { insertData } = editor;

  editor.insertData = data => {
    // Send the analytics event to collect the original data types. Include `editorId` as it sometimes may give a hint
    // as to where the agent is trying to paste to
    analytics.track('Text Editor Content Pasted', {
      dataTypes: [...data.types],
      editorId: editor.id,
    });

    // HACK: For some reason, pasting plaintext into the editor doesn't seem to do the expected thing of
    // first deleting the fragment that's currently selected before inserting the text. This means that
    // if you have a variable selected, pasting plaintext (e.g. copied text from an input elsewhere
    // in the app) will modify the contents of the variable, rather than replacing them.
    if (data.getData('application/x-slate-fragment-old')) {
      const oldSlateHtml = data.getData('application/x-slate-fragment-old');
      const fragment = editor.getSaturatedFragment(oldSlateHtml);
      Transforms.insertFragment(editor, fragment);
      return;
    } else if (data.types.includes('text/html') && !data.types.includes('application/x-slate-fragment')) {
      // If we see an HTML fragment that is not a "slate direct copy-paste fragment" (i.e. we are copying
      // from an external source or from another conversation item), we want to tweak the html to match the
      // way it was serialized - otherwise the pasted content will not match the source
      const sourceHtml = data.getData('text/html');
      const sanitized = stripZeroWidthCharacters(sanitize(sourceHtml));
      const transformedHtml = removeAppleLineBreaks(removeExtraBreaks(sanitized));

      if (transformedHtml) {
        const updatedDataTransfer = new DataTransfer();
        updatedDataTransfer.setData('text/html', transformedHtml);
        return insertData(updatedDataTransfer);
      }
    } else if (data.getData('text/plain')) {
      editor.deleteFragment();
    }

    insertData(data);
  };

  return editor;
}
