import { findAndRemoveAttachment } from 'models/answers/snippet_helpers';
import Snippet from 'models/answers/snippet';

export default class AbortUpload {
  constructor(context) {
    this.context = context;
  }

  run({ uploadId }) {
    let composition = this.context.stores.snippetComposition.get();
    if (!findAndRemoveAttachment({ snippet: composition, id: uploadId })) {
      return;
    }

    this.context.stores.snippetComposition.set(composition);
    let path = Snippet.getUploadPath(composition.id, uploadId);
    this.context.gateways.snippetUpload.abort(path);
  }
}
