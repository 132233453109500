import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Credit Card Stroke
 */

export default function CreditCardStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M14.4818 2H1.51822C1.11556 2 0.729399 2.15689 0.444677 2.43615C0.159955 2.71541 0 3.09417 0 3.4891V12.5109C0 12.9058 0.159955 13.2846 0.444677 13.5639C0.729399 13.8431 1.11556 14 1.51822 14H14.4818C14.8844 14 15.2706 13.8431 15.5553 13.5639C15.84 13.2846 16 12.9058 16 12.5109V3.4891C16 3.09417 15.84 2.71541 15.5553 2.43615C15.2706 2.15689 14.8844 2 14.4818 2ZM1.51822 2.7864H14.4818C14.6704 2.7864 14.8512 2.85988 14.9846 2.99068C15.118 3.12148 15.1929 3.29889 15.1929 3.48387V4.56146H0.792889V3.4891C0.792157 3.39588 0.810485 3.30347 0.846789 3.21731C0.883094 3.13116 0.936638 3.05302 1.00426 2.98751C1.07188 2.922 1.1522 2.87045 1.24048 2.83591C1.32875 2.80137 1.4232 2.78453 1.51822 2.7864ZM14.4818 13.2136H1.51822C1.32962 13.2136 1.14875 13.1401 1.01539 13.0093C0.882032 12.8785 0.807111 12.7011 0.807111 12.5161V6.95379H15.2071V12.5109C15.2078 12.6041 15.1895 12.6965 15.1532 12.7827C15.1169 12.8688 15.0634 12.947 14.9957 13.0125C14.9281 13.078 14.8478 13.1295 14.7595 13.1641C14.6712 13.1986 14.5768 13.2155 14.4818 13.2136Z" />
    </Icon>
  );
}
