import { CollectionStoreBase } from 'scripts/adapters/stores/lib/collection_store_base';

/**
 * Factory for creating CollectionStore class. The CollectionStore class treats entire collection as a single array
 * of elements that gets loaded in one step and gets tracked as a whole.
 *
 * @param converter - converter used for converting stored entities (typically, models) to/from Immutable
 * @returns {CollectionStore}
 */
export default function createCollectionStoreClass({ converter }) {
  class CollectionStore extends CollectionStoreBase {
    constructor(binding, opts) {
      super(binding, converter, opts);
    }

    isLoading() {
      return this.immutableStore.isLoading();
    }

    setLoading() {
      return this.immutableStore.setLoading();
    }

    resetLoading() {
      this.immutableStore.resetLoading();
    }

    clearErrorForLoading() {
      this.immutableStore.clearErrorForLoading();
    }

    getErrorForLoading() {
      return this.immutableStore.getErrorForLoading();
    }

    setErrorForLoading(error) {
      this.immutableStore.setErrorForLoading(error);
    }
  }

  // Delegate the rest of the store APIs to ImmutableStore
  [
    'count',
    'remove',
    'clearErrors',
    'clearErrorsForNew',
    'getAllErrors',
    'getErrors',
    'getErrorsForNew',
    'setErrors',
    'setErrorsForNew',
    'commitPending',
    'commitPendingNew',
    'resetPending',
    'resetPendingNew',
    'isPendingDelete',
    'setPendingDelete',
  ].forEach(m => {
    CollectionStore.prototype[m] = function(...args) {
      return this.immutableStore[m](...args);
    };
  });

  return CollectionStore;
}
