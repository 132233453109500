import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import PlainTextSerializer from 'components/text_editor/serializers/plaintext_serializer';

const CharacterCount = ({ className, value, getContentSize, maxCharacters }) => {
  let characters = value ? PlainTextSerializer.serialize(value) : '';
  let characterCount = 0;
  if (getContentSize) {
    characterCount = getContentSize(characters);
  } else {
    characterCount = characters.length;
  }

  let classNames = classnames('characterCount', className);
  if (maxCharacters) {
    let charactersRemaining = maxCharacters - characterCount;
    classNames = classnames(classNames, {
      'characterCount-warning': charactersRemaining <= 10 && charactersRemaining >= 0,
      'characterCount-danger': charactersRemaining < 0,
    });
  }
  return (
    <span className={classNames}>
      <span className="characterCount-count">{characterCount}</span>
      <span className="characterCount-chars">Chars</span>
    </span>
  );
};

CharacterCount.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  getContentSize: PropTypes.func,
  maxCharacters: PropTypes.number,
};

export default CharacterCount;
