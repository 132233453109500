import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import { RoutingChannel } from 'models/agent_routing_preferences';

export function getNextAssignmentForChannel(context, channel) {
  if (!RoutingChannel[channel]) {
    throw new Error(`not a supported channel type: [${channel}]`);
  }

  let agentId = context.stores.currentAgent.get().id;

  return context.gateways.agentNextAssignment
    .add({ agentId }, { channel, initiatorType: InitiatorType.SYSTEM })
    .catch(err => new GatewayErrorSilentHandler(context).handleCommonErrors(err));
}

export const InitiatorType = {
  AGENT: 'AGENT',
  SYSTEM: 'SYSTEM',
};
