import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import ConversationItemsService from 'scripts/infrastructure/backends/fake_backend/conversation_items_service';
import ConversationsService from 'scripts/infrastructure/backends/fake_backend/conversations_service';

export default class ConversationHistoryService {
  constructor(database, pubsub) {
    this.getDatabase = database;
    this.pubsub = pubsub;
    this.conversationItems = ConversationItemsService.create(pubsub, database);
    this.conversations = ConversationsService.create(pubsub, this.conversationItems, database);
  }

  getConversations(attrs, callback, path, { orgId, customerId }) {
    let response = this.conversations.findByCustomerId(orgId, customerId);
    callback(null, { status: 200, statusText: statusText(200), response });
  }

  addConversation(attrs, callback, path, { orgId, customerId }) {
    this.conversations.add(orgId, customerId, { correlationId: null, payload: attrs });
    callback(null, { status: 201, statusText: statusText(200) });
  }

  updateConversation(attrs, callback, path, { orgId, customerId, conversationId }) {
    this.conversations.update(orgId, customerId, conversationId, { correlationId: null, payload: attrs });
    callback(null, { status: 201, statusText: statusText(200) });
  }

  updateConversationCustomAttributes(attrs, callback, path, { orgId, customerId, conversationId }) {
    let response = this.conversations.updateCustomAttributes(orgId, customerId, conversationId, {
      correlationId: null,
      payload: attrs,
    });
    if (response?.error) {
      return callback(response.error, { status: 400, statusText: statusText(400) });
    }
    callback(null, { status: 204, statusText: statusText(200) });
  }

  updateConversationTopicIds(attrs, callback, path, { orgId, customerId, conversationId }) {
    this.conversations.updateTopicIds(orgId, customerId, conversationId, { correlationId: null, payload: attrs });
    callback(null, { status: 204, statusText: statusText(200) });
  }

  getCurrentItems(attrs, callback, path, { orgId, conversationId, customerId }) {
    let response = this.conversationItems.fetchCurrentItems(orgId, conversationId);
    callback(null, { status: 200, statusText: statusText(200), response });
  }

  getConversationItems(attrs, callback, path, { orgId, customerId }, query) {
    let response = this.conversationItems.findByQuery(orgId, customerId, query);
    callback(null, { status: 200, statusText: statusText(200), response });
  }

  deleteConversationItem(attrs, callback, path, { orgId, conversationItemId, customerId }) {
    this.conversationItems.delete(orgId, customerId, conversationItemId);
    callback(null, { status: 204, statusText: statusText(200) });
  }

  updateConversationItem(attrs, callback, path, { orgId, conversationItemId, customerId }) {
    this.conversationItems.update(orgId, customerId, conversationItemId, { correlationId: null, payload: attrs });
    callback(null, { status: 200, statusText: statusText(200) });
  }

  addConversationItem(attrs, callback, path, { orgId, customerId }) {
    this.conversationItems.add(orgId, customerId, { correlationId: null, payload: attrs });
    callback(null, { status: 201, statusText: statusText(200) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customer-history/:customerId/current-items/:conversationId': {
          GET: this.getCurrentItems,
        },
        '/api/v1/orgs/:orgId/customer-history/:customerId/conversation-items': {
          POST: this.addConversationItem,
          GET: this.getConversationItems,
        },
        '/api/v1/orgs/:orgId/customer-history/:customerId/conversation-items/:conversationItemId': {
          DELETE: this.deleteConversationItem,
          PUT: this.updateConversationItem,
        },
        '/api/v1/orgs/:orgId/customer-history/:customerId/conversations': {
          GET: this.getConversations,
          POST: this.addConversation,
        },
        '/api/v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId': {
          PATCH: this.updateConversation,
        },
        '/api/v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId/custom-attributes': {
          POST: this.updateConversationCustomAttributes,
        },
        '/api/v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId/topicIds': {
          PATCH: this.updateConversationTopicIds,
        },
      },
      this
    );
  }
}
