import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Assignee from 'models/assignee';
import Attachments from '../attachments/attachments';
import BubbleContent from '../content/bubble_content';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import FakeCommentEditor from 'components/customer/task_comments/fake_comment_editor';
import { getIsCommentingEnabledForTask } from 'scripts/application/selectors/customer';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import Linkifier from 'components/lib/linkifier';
import OpenOrRefreshCommentPanel from 'actions/task/open_or_refresh_comment_panel';
import SafeHtml from 'components/common/safe_html';
import TaskItemCommentPreview from './task_item_comment_preview';
import TaskAnalyticsLocations from 'actions/conversation_item/task/task_analytics_locations';
import TaskHeader from './task_header';
import UpdateTaskAssignee from 'actions/conversation_item/task/update_task_assignee';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function CommentableTaskContent({ className, comment, commentCount, isCommentingEnabled, isPending, item }) {
  const executeAction = useExecuteAction();
  const task = item.content;
  const isLink = item.isLink();

  const onOpenCommentPanel = useCallback(
    (location, e) => {
      e.stopPropagation();
      executeAction(OpenOrRefreshCommentPanel, {
        itemId: item.id,
        location,
      });
    },
    [executeAction, item.id]
  );

  const onClickComment = useCallback(e => onOpenCommentPanel(TaskAnalyticsLocations.TASK_COMMENT, e), [
    onOpenCommentPanel,
  ]);
  const onClickCommentEditor = useCallback(e => onOpenCommentPanel(TaskAnalyticsLocations.FAKE_COMMENT_EDITOR, e), [
    onOpenCommentPanel,
  ]);

  const onChangeAssignee = useCallback(
    ({ agentId, routingGroupId }) =>
      executeAction(UpdateTaskAssignee, {
        assignee: new Assignee({ agentId: agentId || '', routingGroupId }),
        conversationItemId: item.id,
        location: TaskAnalyticsLocations.TASK_ITEM,
      }),
    [executeAction, item.id]
  );

  function render() {
    return (
      <StyledBubbleContent className={className} data-aid="commentableTask" item={item}>
        <TaskHeader
          isLink={isLink}
          isPending={isPending}
          item={item}
          location={TaskAnalyticsLocations.TASK_ITEM}
          onChangeAssignee={onChangeAssignee}
        />
        {renderText()}
        {renderAttachments()}
        {renderComment()}
        {renderCommentEditor()}
      </StyledBubbleContent>
    );
  }

  function renderCommentEditor() {
    return isCommentingEnabled && <FakeCommentEditor onClick={onClickCommentEditor} />;
  }

  function renderComment() {
    return <TaskItemCommentPreview comment={comment} commentCount={commentCount} onClick={onClickComment} />;
  }

  function renderText() {
    const { bodyHtml, note } = task;

    if (bodyHtml) {
      return <SafeHtml className="taskItem-text" dir="auto" html={bodyHtml} setDirection />;
    } else if (note) {
      return <Linkifier>{note}</Linkifier>;
    }

    return null;
  }

  function renderAttachments() {
    return (
      <Attachments
        attachments={task.attachments || []}
        className="taskItem-attachments"
        itemId={item.id}
        pathPrefix="task"
      />
    );
  }

  return render();
}

CommentableTaskContent.propTypes = {
  commentCount: PropTypes.number,
  isCommentingEnabled: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

const StyledBubbleContent = styled(BubbleContent)`
  border-radius: 10px;
`;

function mapStateToProps({ getProvider }, { item }) {
  const taskInfoProvider = getProvider('taskInfo');
  const conversationHistoryProvider = getProvider('conversationHistory');
  let taskInfo = taskInfoProvider.findBy({ id: item.id });
  let comments = taskInfo ? taskInfo.getComments() : [];

  // return the last comment to display in the feed
  let comment = _.maxBy(comments, 'createdAt');

  return {
    comment,
    commentCount: comments.length,
    isPending: conversationHistoryProvider.has({ id: item.id }) && conversationHistoryProvider.isPending(item.id),
    item,
    isCommentingEnabled: getIsCommentingEnabledForTask(
      { taskInfo: taskInfoProvider, conversationHistory: conversationHistoryProvider },
      { taskId: item.id }
    ),
  };
}

const CommentableTaskContentContainer = connect(mapStateToProps)(CommentableTaskContent);
CommentableTaskContentContainer.propTypes = CommentableTaskItem.propTypes;
export { CommentableTaskContentContainer };

function mapItemStateToProps({ getProvider }, { item }) {
  const commentPanel = getProvider('commentPanel').get();
  let isFocused = commentPanel.selectedItemId === item.id;
  return { isFocused };
}

export function CommentableTaskItem({ className, item, isFocused }) {
  return (
    <ItemContent isHighlighted={isFocused} item={item}>
      <ItemMetadata item={item} text="created a task" />
      <CommentableTaskContentContainer className={className} item={item} />
    </ItemContent>
  );
}

const CommentableTaskItemContainer = connect(mapItemStateToProps)(CommentableTaskItem);
export default CommentableTaskItemContainer;

CommentableTaskItem.propTypes = {
  className: PropTypes.string,
  conversationId: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  isCommentPanelOpen: PropTypes.bool,
  isFocused: PropTypes.bool,
};
