import _ from 'lodash';

import BeginLogOut from 'actions/user/begin_log_out';
import connect from 'components/lib/connect';
import Err from 'models/err';
import ForgotPassword from 'models/location/forgot_password';
import NavigateTo from 'actions/current_location/navigate_to';
import ResetUserPassword from 'actions/user/reset_user_password';
import UserResetPassword from './user_reset_password';

function mapStateToProps({ getProvider }) {
  let userCredentialProvider = getProvider('userCredential');
  let passwordErrors = userCredentialProvider.getErrors().filter(e => e.attr === 'password');
  let currentAgent = getProvider('currentAgent').get();

  return {
    currentAgentId: _.get(currentAgent, 'id', ''),
    errors: passwordErrors,
    isPasswordResetPending: userCredentialProvider.isPending(),
    isUserAuthorized: isUserAuthorized(),
  };

  function isUserAuthorized() {
    let errors = userCredentialProvider.getErrors();
    let isAuthorized = true;
    if (errors) {
      isAuthorized = !errors.some(err => !err.attr && err.code === Err.Code.INVALID.toLowerCase());
    }
    return isAuthorized;
  }
}

function mapExecuteToProps(executeAction) {
  return {
    onNavigateToForgotPassword: () => executeAction(NavigateTo, ForgotPassword.create()),
    onNavigateToLogin: () => executeAction(BeginLogOut),
    onResetPassword: ({ id, password }) => executeAction(ResetUserPassword, { id, password }),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(UserResetPassword);
