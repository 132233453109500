export default class ExpandCustomerActivity {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    let currentCustomerId = this.context.stores.currentLocation.get().customerId;
    this.context.analytics.track('ExpandCustomerActivity', { customerId: currentCustomerId, ...attrs });
  }
}
