import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';

import { CardRow, NumberCard, DurationCard } from 'components/liveboards/cards/metric_card';
import Conversation from 'models/conversation';
import connect from 'components/lib/connect';
import Endpoint from 'models/endpoint';
import { findReportConfigBySlug } from 'models/reports/report';
import { getDatesInRange } from 'models/location/reports';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import { LegacyReportConfigs } from 'scripts/domain/models/reports/report_configs';
import ReportChannel from 'models/reports/report_channel';
import ReportInfo from '../report_info';
import Search from 'models/location/search';
import searchContentTypeFromChannel from 'models/reports/search_content_type_from_channel';
import SearchResult from 'models/search/search_result';
import SummaryChart from './lib/summary_chart';
import SummaryReportModel from 'models/reports/summary_report';
import SummaryTable from './lib/summary_table';

const calculateAverageDuration = dataByDay => {
  if (dataByDay.length > 0) {
    let totalDuration = _.reduce(dataByDay, (total, data) => total + data.duration, 0);
    let totalCount = _.reduce(dataByDay, (total, data) => total + (data.count || data.total), 0);
    return totalDuration / totalCount;
  }
  return 0;
};

const calculateTotalCount = dataByDay => {
  return _.reduce(dataByDay, (total, data) => total + data.count, 0);
};

const getAverageAmountWithinSLA = report => {
  let amtOverSLAValues = _.reduce(
    report.amountOverSLAByDay,
    (acc, entry) => {
      acc.over += entry.over;
      acc.total += entry.total;
      return acc;
    },
    { over: 0, total: 0 }
  );
  return amtOverSLAValues.total === 0
    ? 0
    : (100 * (amtOverSLAValues.total - amtOverSLAValues.over)) / amtOverSLAValues.total;
};

const getResponseNameFromChannel = channel => {
  if (channel === ReportChannel.EMAIL) {
    return 'Email';
  } else if (channel === ReportChannel.FB_MESSENGER) {
    return 'FB Messenger';
  } else if (channel === ReportChannel.PHONE_CALL) {
    return 'Phone';
  } else if (channel === ReportChannel.SMS) {
    return 'SMS';
  } else if (channel === ReportChannel.CHAT_SESSION) {
    return 'Chat';
  } else if (channel === ReportChannel.TWITTER) {
    return 'Twitter';
  } else if (channel === ReportChannel.WHATSAPP) {
    return 'WhatsApp';
  } else if (channel === ReportChannel.INSTAGRAM_DIRECT) {
    return 'Instagram Messaging';
  }
  return 'Responses';
};

function SummaryReport({
  aggregationLevel,
  channel,
  dates,
  routingGroupIds,
  report,
  windowWidth,
  startDate,
  endDate,
  isPhoneEnabled,
  isSmsEnabled,
  isFbMessengerEnabled,
  isChatEnabled,
  isTwitterEnabled,
  isWhatsappEnabled,
  isInstagramDirectEnabled,
}) {
  let summaryTable = report.getDataForSummaryDayAndCategory({ days: dates });
  let dataForChart = _.map(summaryTable, (row, date) => {
    return {
      date: moment(date).valueOf(),
      newConvo: row.newConversationsByDay ? row.newConversationsByDay.count : 0,
      closedConvo: row.closedConversationsByDay ? row.closedConversationsByDay.count : 0,
      slaResponses: row.amountOverSLAByDay ? row.amountOverSLAByDay.over : 0,
      allResponses: row.amountOverSLAByDay ? row.amountOverSLAByDay.total : 0,
    };
  });

  // time zone aware dates for advanced search URLs
  const startDateTz = moment.tz(startDate, report.timezone).toISOString();
  const endDateTz = moment
    .tz(endDate, report.timezone)
    .add(1, 'd')
    .subtract(1, 'ms')
    .toISOString();

  return (
    <div>
      <SummaryChart aggregationLevel={aggregationLevel} channel={channel} data={dataForChart} width={windowWidth} />
      <ReportInfo endDate={endDate} startDate={startDate}>
        <CardRow>
          <NumberCard
            label="Created Conversations"
            link={getLocationUrl(
              Search.createFiltered({
                type: SearchResult.HitTypes.CONVERSATION,
                inboxIds: routingGroupIds,
                channels: [searchContentTypeFromChannel(channel)],
                createdAt: {
                  gte: startDateTz,
                  lte: endDateTz,
                },
              })
            )}
            value={calculateTotalCount(report.data.newConversationsByDay)}
          />
          <NumberCard
            label="Closed Conversations"
            link={getLocationUrl(
              Search.createFiltered({
                type: SearchResult.HitTypes.CONVERSATION,
                inboxIds: routingGroupIds,
                channels: [searchContentTypeFromChannel(channel)],
                statuses: [Conversation.InboxStatus.CLOSED],
                updatedAt: {
                  gte: startDateTz,
                  lte: endDateTz,
                },
              })
            )}
            value={calculateTotalCount(report.data.closedConversationsByDay)}
          />
          <DurationCard
            className="summaryReport-duration"
            duration={calculateAverageDuration(report.data.totalTimeToCloseByDay || [])}
            label="Average Close Time"
          />
          <DurationCard
            className="summaryReport-duration"
            duration={calculateAverageDuration(
              report.data.totalResponseTimeByDay || report.data.amountOverSLAByDay || []
            )}
            label="Average Wait Time"
          />
          <NumberCard
            decimalPlaces={1}
            label={`% ${getResponseNameFromChannel(channel)} Within SLA`}
            value={getAverageAmountWithinSLA(report.data)}
          />
        </CardRow>
      </ReportInfo>
      <SummaryTable
        aggregationLevel={aggregationLevel}
        channel={channel}
        days={dates}
        endDate={endDate}
        isChatEnabled={isChatEnabled}
        isFbMessengerEnabled={isFbMessengerEnabled}
        isInstagramDirectEnabled={isInstagramDirectEnabled}
        isPhoneEnabled={isPhoneEnabled}
        isSmsEnabled={isSmsEnabled}
        isTwitterEnabled={isTwitterEnabled}
        isWhatsappEnabled={isWhatsappEnabled}
        report={report}
        routingGroupIds={routingGroupIds}
        startDate={startDate}
        windowWidth={windowWidth}
      />
    </div>
  );
}

SummaryReport.propTypes = {
  aggregationLevel: PropTypes.string.isRequired,
  channel: PropTypes.string,
  routingGroupIds: PropTypes.arrayOf(PropTypes.string),

  dates: PropTypes.arrayOf(PropTypes.string),
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,

  isChatEnabled: PropTypes.bool,
  isPhoneEnabled: PropTypes.bool,
  isSmsEnabled: PropTypes.bool,
  isFbMessengerEnabled: PropTypes.bool,
  isTwitterEnabled: PropTypes.bool,
  isWhatsappEnabled: PropTypes.bool,
  isInstagramDirectEnabled: PropTypes.bool,

  report: PropTypes.instanceOf(SummaryReportModel).isRequired,

  windowWidth: PropTypes.number,
};

let mapStateToProps = function({ getProvider, isFeatureEnabled }, { windowWidth }) {
  const report = getProvider('report').get();

  let channelConfiguration = getProvider('channelConfiguration').get();
  let currentLocation = getProvider('currentLocation').get();

  let aggregationLevel = currentLocation.getAggregationLevel(
    findReportConfigBySlug(LegacyReportConfigs, 'summary').timeAggregation
  );
  let startDate = currentLocation.getStartAt();
  let endDate = currentLocation.getEndAt();

  const isChatEnabled = isChannelEnabled(Endpoint.Type.CHAT);
  const isPhoneEnabled = isChannelEnabled(Endpoint.Type.VOICE);
  const isSmsEnabled = isChannelEnabled(Endpoint.Type.SMS);
  const isFbMessengerEnabled = isChannelEnabled(Endpoint.Type.FB_MESSENGER);
  const isTwitterEnabled = isChannelEnabled(Endpoint.Type.TWITTER);
  const isWhatsappEnabled = isChannelEnabled(Endpoint.Type.WHATSAPP);
  const isInstagramDirectEnabled = isChannelEnabled(Endpoint.Type.INSTAGRAM_DIRECT);

  return {
    aggregationLevel,
    channel: currentLocation.getChannel(),
    startDate,
    endDate,
    routingGroupIds: currentLocation.getRoutingGroupIds(),
    dates: getDatesInRange(startDate, endDate, aggregationLevel),
    report,
    windowWidth,
    isPhoneEnabled,
    isSmsEnabled,
    isFbMessengerEnabled,
    isChatEnabled,
    isTwitterEnabled,
    isWhatsappEnabled,
    isInstagramDirectEnabled,
  };

  function isChannelEnabled(channel) {
    return channelConfiguration && channelConfiguration.isChannelEnabled(channel);
  }
};

const SummaryReportContainer = connect(mapStateToProps)(SummaryReport);

SummaryReportContainer.propTypes = {
  windowWidth: PropTypes.number,
};

export default SummaryReportContainer;
