import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import BackChevronIcon from 'components/lib/icons/back_chevron_icon';
import NavigateToPreviousAnswer from 'actions/answers/navigate_to_previous_answer';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function BackButton() {
  const { history } = useContext(AnswerPanelContext);
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    executeAction(NavigateToPreviousAnswer);
  }, [executeAction]);

  if (!history.length) {
    return null;
  }

  const { answer } = _.last(history);

  return (
    <Back data-aid="answerPanel-preview-backButton" onClick={onClick}>
      <BackChevronIcon />
      <BackText>{answer.name}</BackText>
    </Back>
  );
}

const Back = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding-bottom: ${p => p.theme.spacing.large};

  .backChevronIcon {
    height: 16px;
    stroke: ${p => p.theme.colors.gray600};
    width: 8px;
  }
`;

const BackText = styled.div`
  color: ${p => p.theme.colors.gray600};
  overflow: hidden;
  padding-left: ${p => p.theme.spacing.medium};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
`;
