import _ from 'lodash';
import moment from 'moment';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

let AggregationMultiplier = {
  DAYS: 1,
  WEEKS: 7,
  MONTHS: 30,
  QUARTERS: 90,
};

export default class ReportService {
  constructor(database = getDatabase) {
    this.getDatabase = database;
  }

  getRoutes() {
    return bindCallbacks({
      '/api/reporting/v1/orgs/:orgId/reports': {
        POST: (attrs, callback, path, { orgId }) =>
          callback(null, {
            status: 200,
            statusText: statusText(200),
            response: this.fetch(orgId, attrs),
          }),
      },
    });
  }

  fetch(orgId, payload) {
    if (_.isEmpty(this.getDatabase(orgId).reports)) {
      return {};
    }
    switch (payload.metricSet.toLowerCase()) {
      case 'summaryreport':
        return this.fetchSummaryReport(orgId, payload);
      case 'agentsreport':
        return this.fetchAgentsReport(orgId, payload);
      case 'topicsreport':
        return this.fetchTopicsReport(orgId, payload);
      case 'topichierarchyreport':
        return this.fetchTopicsReport(orgId, payload);
      case 'ivrexecutivesummaryreport':
        return this.fetchIvrSummaryReport(orgId, payload);
      case 'ivrendstatesreport':
        return this.fetchIvrEndStatesReport(orgId, payload);
      default:
        return {};
    }
  }

  fetchSummaryReport(orgId, payload = {}) {
    let report = this.getDatabase(orgId).reports.summary;
    let newConversationCounts = report.newConversationCounts;
    let closedConversationCounts = report.closedConversationCounts;
    let totalCloseTime = report.totalCloseTime;
    let totalResponseTime = report.totalResponseTime;
    let totalTimeUtilized = report.totalTimeUtilized;
    let totalTimeLoggedIn = report.totalTimeLoggedIn;
    let amountOverSLA = report.amountOverSLA;
    let incomingCallCounts = report.incomingCallCounts;
    let outgoingCallCounts = report.outgoingCallCounts;
    let incomingEmailCounts = report.incomingEmailCounts;
    let outgoingEmailCounts = report.outgoingEmailCounts;
    let incomingSmsCounts = report.incomingSmsCounts;
    let outgoingSmsCounts = report.outgoingSmsCounts;
    let declinedAndMissedChats = report.declinedAndMissedChats || [];
    let incomingChatMessages = report.incomingChatMessages || [];
    let outgoingChatMessages = report.outgoingChatMessages || [];
    let chatReplyTime = report.chatReplyTime || [];
    let startedChatSessions = report.startedChatSessions || [];

    if (payload.routingGroupId) {
      newConversationCounts = _.map(report.newConversationCounts, val => {
        return _.floor(val / 2);
      });
      closedConversationCounts = _.map(report.closedConversationCounts, val => {
        return _.floor(val / 2);
      });
      totalCloseTime = _.map(report.totalCloseTime, val => {
        return _.floor(val / 2);
      });
      totalResponseTime = _.map(report.totalResponseTime, val => {
        return _.floor(val / 2);
      });
      totalTimeUtilized = _.map(report.totalTimeUtilized, val => {
        return _.floor(val / 2);
      });
      totalTimeLoggedIn = _.map(report.totalTimeLoggedIn, val => {
        return _.floor(val / 2);
      });
      amountOverSLA = _.map(report.amountOverSLA, entry => {
        let [over, total] = entry;
        return [_.floor(over / 2), total];
      });
      incomingCallCounts = _.map(report.incomingCallCounts, val => {
        return _.floor(val / 2);
      });
      outgoingCallCounts = _.map(report.outgoingCallCounts, val => {
        return _.floor(val / 2);
      });
      incomingEmailCounts = _.map(report.incomingEmailCounts, val => {
        return _.floor(val / 2);
      });
      outgoingEmailCounts = _.map(report.outgoingEmailCounts, val => {
        return _.floor(val / 2);
      });
      incomingSmsCounts = _.map(report.incomingSmsCounts, val => {
        return _.floor(val / 2);
      });
      outgoingSmsCounts = _.map(report.outgoingSmsCounts, val => {
        return _.floor(val / 2);
      });
      incomingChatMessages = _.map(report.incomingChatMessages, val => {
        return _.floor(val / 2);
      });
      outgoingChatMessages = _.map(report.outgoingChatMessages, val => {
        return _.floor(val / 2);
      });
      startedChatSessions = _.map(report.startedChatSessions, val => {
        return _.floor(val / 2);
      });
    }

    totalCloseTime = totalCloseTime.map(this.convertDurationFromHours);
    totalResponseTime = totalResponseTime.map(this.convertDurationFromHours);
    chatReplyTime = chatReplyTime.map(this.convertDurationFromHours);

    let { startAt, endAt, aggregationLevel } = this.getAggregateDateRange(payload);
    let date = endAt;
    let count = endAt.diff(startAt, aggregationLevel) + 1;
    newConversationCounts = this.loopItems(newConversationCounts, count);
    closedConversationCounts = this.loopItems(closedConversationCounts, count);
    totalCloseTime = this.loopItems(totalCloseTime, count);
    totalResponseTime = this.loopItems(totalResponseTime, count);
    totalTimeUtilized = this.loopItems(totalTimeUtilized, count);
    totalTimeLoggedIn = this.loopItems(totalTimeLoggedIn, count);
    amountOverSLA = this.loopItems(amountOverSLA, count);
    incomingCallCounts = this.loopItems(incomingCallCounts, count);
    outgoingCallCounts = this.loopItems(outgoingCallCounts, count);
    incomingEmailCounts = this.loopItems(incomingEmailCounts, count);
    outgoingEmailCounts = this.loopItems(outgoingEmailCounts, count);
    incomingSmsCounts = this.loopItems(incomingSmsCounts, count);
    outgoingSmsCounts = this.loopItems(outgoingSmsCounts, count);
    declinedAndMissedChats = this.loopItems(declinedAndMissedChats, count);
    incomingChatMessages = this.loopItems(incomingChatMessages, count);
    outgoingChatMessages = this.loopItems(outgoingChatMessages, count);
    chatReplyTime = this.loopItems(chatReplyTime, count);
    startedChatSessions = this.loopItems(startedChatSessions, count);

    return {
      newConversationsByDay: newConversationCounts.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      closedConversationsByDay: closedConversationCounts.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      totalTimeToCloseByDay: totalCloseTime.map((duration, index) =>
        this.createDurationForDay(index, duration, date, aggregationLevel)
      ),
      totalResponseTimeByDay: totalResponseTime.map((duration, index) =>
        this.createDurationForDay(index, duration, date, aggregationLevel)
      ),
      averageHandleTimeByDay: this.getDaysInRange(startAt, endAt, aggregationLevel).map(day => {
        return { day: day.format(), duration: _.random(1 * 60 * 1000, 10 * 60 * 1000) };
      }),
      totalTimeUtilizedByDay: totalTimeUtilized.map((duration, index) =>
        this.createDurationForDay(index, duration, date, aggregationLevel)
      ),
      totalTimeLoggedInByDay: totalTimeLoggedIn.map((duration, index) =>
        this.createDurationForDay(index, duration, date, aggregationLevel)
      ),
      amountOverSLAByDay: amountOverSLA.map((entry, index) =>
        this.createFractionForDay(index, entry, date, aggregationLevel, totalResponseTime)
      ),
      incomingCallsByDay: incomingCallCounts.map((count, index) =>
        this.createCallCountForDay(index, count, date, aggregationLevel)
      ),
      outgoingCallsByDay: outgoingCallCounts.map((count, index) =>
        this.createCallCountForDay(index, count, date, aggregationLevel)
      ),
      incomingEmailsByDay: incomingEmailCounts.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      outgoingEmailsByDay: outgoingEmailCounts.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      incomingSmsByDay: incomingSmsCounts.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      outgoingSmsByDay: outgoingSmsCounts.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      declinedAndMissedChatsByTime: declinedAndMissedChats.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      incomingChatMessagesByTime: incomingChatMessages.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      outgoingChatMessagesByTime: outgoingChatMessages.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
      chatReplyTimeByTime: chatReplyTime.map((duration, index) =>
        this.createDurationForDay(index, duration, date, aggregationLevel)
      ),
      startedChatSessionsByTime: startedChatSessions.map((count, index) =>
        this.createCountForDay(index, count, date, aggregationLevel)
      ),
    };
  }

  fetchAgentsReport(orgId, payload = {}) {
    let responseCounts = this.getDatabase(orgId).reports.agent.allGroups;
    if (payload.routingGroupId) {
      responseCounts = this.getDatabase(orgId).reports.agent.specificGroup;
    }

    let { startAt, endAt, aggregationLevel } = this.getAggregateDateRange(payload);
    let dates = this.getDaysInRange(startAt, endAt, aggregationLevel);
    _.mapKeys(responseCounts, (responses, id) => {
      responseCounts[id] = this.loopItems(responses, dates.length);
    });

    let result = {
      responsesByAgentIDAndDay: [],
      utilizedTimeByAgentIDAndDay: [],
      loginTimeByAgentIDAndDay: [],
      handleTimeByAgentIDAndDay: [],
      averageHandleTimeByAgentIDAndDay: [],
    };

    _.each(responseCounts, (counts, agentId) => {
      for (let i = 0; i < dates.length; i++) {
        let count = counts[i] * AggregationMultiplier[aggregationLevel.toUpperCase()];
        result.responsesByAgentIDAndDay.push({
          id: agentId,
          day: dates[i].format(),
          count: randomValueWithMax(count, count + 3, 0.25),
        });

        let utilizedTime_milliseconds = _.random(5 * 60 * 1000, 86400000);
        let loginTime_milliseconds = _.random(
          utilizedTime_milliseconds,
          _.ceil(Math.min(86400000, 1.5 * utilizedTime_milliseconds))
        );
        let customerTime_milliseconds = _.random(0, utilizedTime_milliseconds);
        let totalHandleTime_milliseconds = _.random(1000, utilizedTime_milliseconds);

        result.utilizedTimeByAgentIDAndDay.push({
          id: agentId,
          day: dates[i].format(),
          duration: utilizedTime_milliseconds,
          count: 1,
        });

        result.loginTimeByAgentIDAndDay.push({
          id: agentId,
          day: dates[i].format(),
          duration: loginTime_milliseconds,
          count: 1,
        });

        result.handleTimeByAgentIDAndDay.push({
          id: agentId,
          day: dates[i].format(),
          duration: customerTime_milliseconds,
          count: _.random(
            _.floor(Math.max(1, customerTime_milliseconds / (2 * 60 * 60 * 1000))),
            _.ceil(customerTime_milliseconds / 60000)
          ),
        });

        result.averageHandleTimeByAgentIDAndDay.push({
          id: agentId,
          day: dates[i].format(),
          duration: totalHandleTime_milliseconds,
          count: _.random(
            _.floor(Math.max(1, totalHandleTime_milliseconds / (2 * 60 * 60 * 1000))),
            _.ceil(totalHandleTime_milliseconds / 60000)
          ),
        });
      }
    });
    return result;
  }

  fetchTopicsReport(orgId, payload = {}) {
    let topicCounts = this.getDatabase(orgId).reports.topics.allTopics;
    if (payload.routingGroupId) {
      topicCounts = this.getDatabase(orgId).reports.topics.filteredTopics;
    }

    let { startAt, endAt, aggregationLevel } = this.getAggregateDateRange(payload);
    let dates = this.getDaysInRange(startAt, endAt, aggregationLevel);
    _.mapKeys(topicCounts, (responses, id) => {
      topicCounts[id] = this.loopItems(responses, dates.length);
    });

    let result = {
      newConversationsByTopicIDAndDay: [],
    };
    _.each(topicCounts, (topicCounts, topicId) => {
      for (let i = 0; i < dates.length; i++) {
        result.newConversationsByTopicIDAndDay.push({
          id: topicId,
          day: dates[i].format(),
          count: topicCounts[i] * AggregationMultiplier[aggregationLevel.toUpperCase()],
        });
      }
    });
    return result;
  }

  fetchIvrSummaryReport(orgId, payload = {}) {
    let report = this.getDatabase(orgId).reports['ivr-summary'];
    let startAt = (payload.startAt ? moment(payload.startAt) : moment().subtract(7, 'days')).startOf('day');
    let endAt = (payload.endAt ? moment(payload.endAt) : moment()).startOf('day');
    let date = endAt;
    let days = endAt.diff(startAt, 'days') + 1;

    let incomingIvrCallsByTime = this.loopItems(report.incomingIvrCallsByTime, days);
    let callsReachingEndStateByTime = this.loopItems(report.callsReachingEndStateByTime, days);
    let speechIvrAttemptsByTime = this.loopItems(report.speechIvrAttemptsByTime, days);
    let unmappedSpeechAttemptsByTime = this.loopItems(report.unmappedSpeechAttemptsByTime, days);
    let touchtoneIvrAttemptsByTime = this.loopItems(report.touchtoneIvrAttemptsByTime, days);
    let unmappedTouchtoneAttemptsByTime = this.loopItems(report.unmappedTouchtoneAttemptsByTime, days);
    let repeatCallerCountByTime = this.loopItems(report.repeatCallerCountByTime, days);

    return {
      incomingIvrCallsByTime: incomingIvrCallsByTime.map((count, index) =>
        this.createCountForDay(index, count, date, 'days')
      ),
      callsReachingEndStateByTime: callsReachingEndStateByTime.map((count, index) =>
        this.createCountForDay(index, count, date, 'days')
      ),
      speechIvrAttemptsByTime: speechIvrAttemptsByTime.map((count, index) =>
        this.createCountForDay(index, count, date, 'days')
      ),
      unmappedSpeechAttemptsByTime: unmappedSpeechAttemptsByTime.map((count, index) =>
        this.createCountForDay(index, count, date, 'days')
      ),
      touchtoneIvrAttemptsByTime: touchtoneIvrAttemptsByTime.map((count, index) =>
        this.createCountForDay(index, count, date, 'days')
      ),
      unmappedTouchtoneAttemptsByTime: unmappedTouchtoneAttemptsByTime.map((count, index) =>
        this.createCountForDay(index, count, date, 'days')
      ),
      repeatCallerCountByTime: repeatCallerCountByTime.map((count, index) =>
        this.createCountForDay(index, count, date, 'days')
      ),
    };
  }

  fetchIvrEndStatesReport(orgId, payload = {}) {
    let report = this.getDatabase(orgId).reports['ivr-end-states'];
    let { startAt, endAt, aggregationLevel } = this.getAggregateDateRange(payload);
    let dateAndEndpoints = [];

    for (let i = 0; i <= endAt.diff(startAt, aggregationLevel); i++) {
      let time = this.getDateFromTime(endAt.clone().subtract(i, aggregationLevel));
      let durationCount = report.endStateDuration[i % report.endStateDuration.length];
      let countCount = report.endStateCount[i % report.endStateCount.length];
      _.forEach(report.endStates, endState => {
        dateAndEndpoints.push({
          time: this.getDateFromTime(time),
          endStateDescription: endState,
          count: randomValueWithMax(countCount, countCount * 1.5, 0.25),
          duration: randomValueWithMax(durationCount, durationCount * 1.5, 0.25),
        });
      });
    }

    return { endStatesByTime: dateAndEndpoints };
  }

  /* Helpers */
  getAggregateDateRange(payload) {
    let startAt = (payload.startAt ? moment(payload.startAt) : moment().subtract(7, 'days')).startOf('day');
    let endAt = (payload.endAt ? moment(payload.endAt) : moment()).startOf('day');
    let aggregationLevel = 'days';
    if (payload.aggregationLevel) {
      switch (payload.aggregationLevel.toLowerCase()) {
        case 'weekly':
          aggregationLevel = 'weeks';
          startAt = startAt.startOf('isoWeek');
          endAt = endAt.startOf('isoWeek');
          break;
        case 'monthly':
          aggregationLevel = 'months';
          startAt = startAt.startOf('month');
          endAt = endAt.startOf('month');
          break;
        case 'quarterly':
          aggregationLevel = 'quarters';
          startAt = startAt.startOf('quarter');
          endAt = endAt.startOf('quarter');
          break;
      }
    }
    return { startAt, endAt, aggregationLevel };
  }

  getLastDays(numDays) {
    return _.times(numDays, day => {
      return moment().subtract(day, 'days');
    });
  }

  getDaysInRange(start, end, aggregationLevel) {
    let startAt = moment(start);
    let endAt = moment(end).startOf(aggregationLevel === 'weeks' ? 'isoWeek' : aggregationLevel);

    let num = endAt.diff(startAt, aggregationLevel) + 1;
    let x = _.times(num, count => {
      return moment(endAt).subtract(count, aggregationLevel);
    });
    return x;
  }

  createCountForDay(numDays, count, baseDate, aggregationLevel) {
    let date = moment(baseDate);
    count *= AggregationMultiplier[aggregationLevel.toUpperCase()];
    return {
      day: this.getDateFromTime(date.subtract(numDays, aggregationLevel)),
      count: randomValueWithMax(count, count * 1.5, 0.25),
    };
  }

  createCallCountForDay(numDays, count, baseDate, aggregationLevel) {
    let date = moment(baseDate);
    let day = this.getDateFromTime(date.subtract(numDays, aggregationLevel));
    count *= AggregationMultiplier[aggregationLevel.toUpperCase()];
    count = randomValueWithMax(count, count * 1.5, 0.25);
    let answered = randomValueWithMax(count, count, 0.15);
    let duration = randomValueWithMax(answered * 2 * 60 * 1000, answered * 3 * 60 * 1000, 0.45);
    return { day, count, answered, duration };
  }

  createDurationForDay(numDays, duration, baseDate, aggregationLevel) {
    let date = moment(baseDate);
    return {
      day: this.getDateFromTime(date.subtract(numDays, aggregationLevel)),
      duration,
      count: randomValueWithMax(100, 150, 0.25),
    };
  }

  createFractionForDay(numDays, entry, baseDate, aggregationLevel, totalResponseTime) {
    let date = moment(baseDate);
    let [over, total] = entry;
    over *= AggregationMultiplier[aggregationLevel.toUpperCase()];
    total *= AggregationMultiplier[aggregationLevel.toUpperCase()];
    return {
      day: this.getDateFromTime(date.subtract(numDays, aggregationLevel)),
      over,
      total: randomValueWithMax(total, total * 2, 0.25),
      duration: totalResponseTime[numDays],
    };
  }

  getDateFromTime(timestamp) {
    return moment(timestamp)
      .startOf('day')
      .format();
  }

  convertDurationFromHours(hours) {
    return moment.duration(hours, 'hours').asMilliseconds();
  }

  loopItems(items, totalLength) {
    let result = [];
    for (let i = 0; i < totalLength; i++) {
      result.push(items[i % items.length]);
    }
    return result;
  }
}

function randomValueWithMax(startingValue, maxVal, rangePct = 0.05) {
  let max = Math.floor(startingValue * (1 + rangePct));
  let min = Math.ceil(startingValue * (1 - rangePct));

  let newVal = Math.floor(Math.random() * (max - min + 1)) + min;
  return !maxVal || newVal < maxVal ? newVal : maxVal;
}
