import _ from 'lodash';

import BlindTransferCallSimulator from './communication_simulation_service/blind_transfer_call_simulator';
import BusyStatusPublisher from './communication_simulation_service/busy_status_publisher';
import CommunicationReplySimulator from './communication_simulation_service/communication_reply_simulator';
import CommunicationSimulator from './communication_simulation_service/communication_simulator';
import createEmailSendFailureItem from './communication_simulation_service/create_email_send_failure_item';
import createIncomingEmailItem from './communication_simulation_service/create_incoming_email_item';
import createIncomingTweetItem from './communication_simulation_service/create_incoming_tweet_item';
import createIncomingWebFormItem from './communication_simulation_service/create_incoming_web_form_item';
import createNoteItem from './communication_simulation_service/create_note_item';
import CustomerTweetSimulator from './communication_simulation_service/customer_tweet_simulator';
import DatabaseProvider from './communication_simulation_service/database_provider';
import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import IncomingChatSimulator from './communication_simulation_service/incoming_chat_simulator';
import IncomingCustomChannelSimulator from './communication_simulation_service/incoming_custom_channel_simulator';
import IncomingFbMessageSimulator from './communication_simulation_service/incoming_fb_message_simulator';
import IncomingPhoneCallSimulator from './communication_simulation_service/incoming_phone_call_simulator';
import IncomingSmsSimulator from './communication_simulation_service/incoming_sms_simulator';
import IncomingTaskSimulator from './communication_simulation_service/incoming_task_simulator';
import IncomingVoicemailSimulator from './communication_simulation_service/incoming_voicemail_simulator';
import qconsole from 'scripts/lib/qconsole';
import RingIterator from './communication_simulation_service/ring_iterator';
import RoutingEventPublisher from './communication_simulation_service/routing_event_publisher';
import WarmTransferCallSimulator from './communication_simulation_service/warm_transfer_call_simulator';

const SIMULATION_RESPONSE_DELAY_INTERVAL = [1000, 1500];

let chatSessionMapper = {
  sessions: {},

  associateSessionIdWithCustomerId(sessionId, customerId) {
    this.sessions[sessionId] = customerId;
  },

  getCustomerIdFromSessionId(sessionId) {
    return this.sessions[sessionId];
  },
};

export default class CommunicationSimulationService {
  constructor(pubsub, fakeTwilio, simulatedServices, getDb = getDatabase) {
    this._pubsub = pubsub;
    this.asyncEnabled = true;
    this.fakeTwilio = fakeTwilio;
    this.getDb = getDb;
    this.simulatedServices = simulatedServices;
  }

  set asyncEnabled(newValue) {
    this._asyncEnabled = newValue;
    this._pubsub.asyncEnabled = newValue;
  }

  get asyncEnabled() {
    return this._pubsub.asyncEnabled && this._asyncEnabled;
  }

  init({ orgId }) {
    if (orgId === this.orgId) {
      return;
    }

    this.database = new DatabaseProvider(orgId, this.getDb);
    this.busyStatusPublisher = new BusyStatusPublisher(orgId, this._pubsub, this.database);
    this.routingEventPublisher = new RoutingEventPublisher(orgId, this._pubsub, this.database);

    this.phoneCallSimulator = new IncomingPhoneCallSimulator(
      {
        fakeTwilio: this.fakeTwilio,
        orgId,
        services: this.simulatedServices,
      },
      this.database
    );

    this.blindTransferCallSimulator = new BlindTransferCallSimulator(
      {
        fakeTwilio: this.fakeTwilio,
        orgId,
        services: this.simulatedServices,
      },
      this.database
    );

    this.warmTransferCallSimulator = new WarmTransferCallSimulator(
      {
        fakeTwilio: this.fakeTwilio,
        orgId,
        services: this.simulatedServices,
      },
      this.database
    );

    this.incomingWebFormSimulator = new CommunicationSimulator(
      {
        attribute: 'incomingWebForms',
        createItem: createIncomingWebFormItem,
        orgId,
        publisher: this.busyStatusPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.emailSimulator = new CommunicationSimulator(
      {
        attribute: 'incomingEmails',
        createItem: createIncomingEmailItem,
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.emailFailureSimulator = new CommunicationSimulator(
      {
        attribute: 'failedEmails',
        createItem: createEmailSendFailureItem,
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.emailResponseSimulator = new CommunicationReplySimulator(
      {
        attribute: 'incomingEmails',
        createItem: createIncomingEmailItem,
        orgId,
        publisher: this.busyStatusPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.fbMessageSimulator = new IncomingFbMessageSimulator(
      {
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.incomingTweetSimulator = new CommunicationSimulator(
      {
        attribute: 'incomingTweets',
        createItem: createIncomingTweetItem,
        orgId,
        publisher: this.busyStatusPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.incomingChatSimluator = new IncomingChatSimulator(
      {
        sessionMapper: chatSessionMapper,
        orgId,
        publisher: this.busyStatusPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.noteSimulator = new CommunicationSimulator(
      {
        attribute: 'incomingNotes',
        createItem: createNoteItem,
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.smsSimulator = new IncomingSmsSimulator(
      {
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.incomingCustomChannelMessageSimulator = new IncomingCustomChannelSimulator(
      {
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.taskSimulator = new IncomingTaskSimulator(
      {
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.voicemailSimulator = new IncomingVoicemailSimulator(
      {
        orgId,
        publisher: this.routingEventPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.customerTweetSimulator = new CustomerTweetSimulator(
      {
        orgId,
        publisher: this.busyStatusPublisher,
        services: this.simulatedServices,
      },
      this.database
    );

    this.orgId = orgId;
  }

  simulateBlindTransferReceived(customerNumber) {
    if (customerNumber) {
      const blindTransferCall = _.find(this.database.get().incomingPhoneCalls, { customerNumber });
      if (!blindTransferCall) {
        qconsole.warn(`No blind transfer phone call found for ${customerNumber}`);
        return;
      }
      this.blindTransferCallSimulator.simulate(blindTransferCall);
    } else {
      this.blindTransferCallSimulator.simulateNext();
    }
  }

  simulateEmailResponseReceived() {
    this.emailResponseSimulator.simulateNext();
  }

  simulateEmailSendFailure() {
    this.emailFailureSimulator.simulateNext();
  }

  simulateInitialChatReceived() {
    this.incomingChatSimluator.simulateNext();
  }

  simulateInitialEmailReceived() {
    this.emailSimulator.simulateNext();
  }

  simulateFbMessageReceived() {
    this.fbMessageSimulator.simulateNext();
  }

  simulateNoteReceived() {
    this.noteSimulator.simulateNext();
  }

  simulatePhoneCallAccepted() {
    this.phoneCallSimulator.simulateAccepted();
  }

  simulatePhoneCallEnd() {
    this.phoneCallSimulator.simulateEndCall();
  }

  simulatePhoneCallReceived(customerNumber) {
    if (customerNumber) {
      let incomingPhoneCall = _.find(this.database.get().incomingPhoneCalls, { customerNumber });
      if (!incomingPhoneCall) {
        qconsole.warn(`No incoming phone call found for ${customerNumber}`);
        return;
      }
      this.phoneCallSimulator.simulate(incomingPhoneCall);
    } else {
      this.phoneCallSimulator.simulateNext();
    }
  }

  simulatePhoneCallUnoffered() {
    this.phoneCallSimulator.simulatePhoneCallUnoffered();
  }

  simulateWarmTransfer() {
    this.warmTransferCallSimulator.simulateNext();
  }

  simulateSmsReceived() {
    this.smsSimulator.simulateNext();
  }

  simulateTaskReceived() {
    this.taskSimulator.simulateNext();
  }

  simulateTweetReceived() {
    this.incomingTweetSimulator.simulateNext();
  }

  simulateCustomChannelMessageReceived() {
    this.incomingCustomChannelMessageSimulator.simulateNext();
  }

  simulateTweetResponse(customerId) {
    let message = this.tweetResponseIterator.getNext();
    if (message) {
      this._simulateDelayedResponse(() => this.customerTweetSimulator.simulate({ message, customerId }));
    }
  }

  simulateVoicemailReceived() {
    this.voicemailSimulator.simulateNext();
  }

  simulateWebFormReceived() {
    this.incomingWebFormSimulator.simulateNext();
  }

  get tweetResponseIterator() {
    return (
      this._tweetResponseIterator ||
      (this._tweetResponseIterator = new RingIterator(this.database.get().tweetResponses))
    );
  }

  _simulateDelayedResponse(responseFn) {
    if (this.asyncEnabled) {
      setTimeout(responseFn, _.random(...SIMULATION_RESPONSE_DELAY_INTERVAL));
    } else {
      responseFn();
    }
  }
}
