import PropTypes from 'prop-types';
import React from 'react';

import ConversationIcon from 'components/lib/conversation_icon';
import ProfileAvatar from 'components/lib/profile_avatar';
import ConversationItemType from 'models/conversation_item_type';

export default function ItemIcon({ avatar, className, itemType, itemSubType, name, showIcon }) {
  let icon = null;
  if (showIcon) {
    const displayAvatar = (name || avatar) && itemType !== ConversationItemType.WIDGET_ACTIVITY;
    icon = displayAvatar ? (
      <ProfileAvatar convoBackground profile={{ image: avatar, name }} />
    ) : (
      <ConversationIcon
        convoBackground
        iconClassName="conversationItem-avatarReplacementIcon"
        subType={itemSubType}
        type={itemType}
      />
    );
  }
  return <div className={className}>{icon}</div>;
}

ItemIcon.defaultProps = {
  showIcon: true,
};

ItemIcon.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  itemType: PropTypes.string.isRequired,
  itemSubType: PropTypes.string,
  name: PropTypes.string,
  showIcon: PropTypes.bool,
};
