export const CONTEXT_CONTACT = 'iglu:com.gladly.agent_desktop/context_contact/jsonschema/1-0-1';

export function getContactContext({ contactId, conversationId, contactChannel }) {
  return {
    schema: CONTEXT_CONTACT,
    data: {
      id: contactId,
      conversationId,
      contactChannel,
    },
  };
}
