import _ from 'lodash';

export const DEMO_ROUTES = [
  'liveboards',
  'phrase-suggestions',
  'topic-suggestions',
  'email-suggestions',
  'greeting-suggestions',
];

function isDemoRoute(url, getDatabase) {
  if (_.some(DEMO_ROUTES, demoRoute => url.includes(demoRoute))) {
    return true;
  }

  // embedded-reports and shared-reports should not use the demo http client
  if (url.includes('reports') && !url.includes('embedded-reports') && !url.includes('shared-reports')) {
    return true;
  }

  // we have a setting that allows us to use the real actions when connected
  // to a 3rd party system (i.e. Shopify)
  if (url.includes('action-forms') || url.includes('transactions')) {
    // extract the 'orgId' from the URL to see if we should
    // use real actions
    const useRealActions = getDatabase(getOrgId(url, '/customers')).useRealActions;
    return !useRealActions;
  }

  // use dataset-defined text rewrite vs real openAI text rewrite depending on the dataset
  if (url.includes('ai-text-completion')) {
    // extract the 'orgId' from the URL to look into dataset
    // and see if we should use real AI text completion
    const useRealAiTextCompletion = getDatabase(getOrgId(url, '/ai-text-completion')).useRealAiTextCompletion;
    return !useRealAiTextCompletion;
  }

  return false;
}

function getOrgId(url, afterOrgId) {
  const start = url.indexOf('orgs/') + 'orgs/'.length;
  const end = url.indexOf(afterOrgId);
  return url.substring(start, end);
}

export default class DemoRestClient {
  constructor(xhrAdapter, localRest, getDatabase) {
    this._xhrAdapter = xhrAdapter;
    this._localRest = localRest;
    this._getDatabase = getDatabase;
  }

  post(url, obj, cb, opts) {
    return this._xhrAdapter.post(url, obj, cb, opts);
  }

  get(url, cb, opts) {
    if (isDemoRoute(url, this._getDatabase)) {
      return this._localRest.get(url, cb);
    }
    return this._xhrAdapter.get(url, cb, opts);
  }

  put(url, obj, cb, opts) {
    return this._xhrAdapter.put(url, obj, cb, opts);
  }

  postUpload(url, obj, listeners, opts) {
    return this._xhrAdapter.postUpload(url, obj, listeners, opts);
  }

  delete(url, cb, opts) {
    return this._xhrAdapter.delete(url, cb, opts);
  }

  axios() {
    return new AxiosDemoClient(this._xhrAdapter.axios(), this._localRest.axios(), this._getDatabase);
  }
}

class AxiosDemoClient {
  constructor(httpClient, localRestBackendAxios, getDatabase) {
    this._httpClient = httpClient;
    this._localRestBackendAxios = localRestBackendAxios;
    this._getDatabase = getDatabase;
  }

  get(url, config) {
    if (isDemoRoute(url, this._getDatabase)) {
      return this._localRestBackendAxios.get(url, config);
    }
    return this._httpClient.get(url, config);
  }

  post(url, body, config) {
    if (isDemoRoute(url, this._getDatabase)) {
      return this._localRestBackendAxios.post(url, body, config);
    }
    return this._httpClient.post(url, body, config);
  }

  put(url, body, config) {
    return this._httpClient.put(url, body, config);
  }

  delete(url, config) {
    return this._httpClient.delete(url, config);
  }

  patch(url, body, config) {
    return this._httpClient.patch(url, body, config);
  }
}
