import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'AnswersPreview',
  properties: {},

  statics: {
    breadcrumb: 'Answers Preview',
  },
});
