import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import {
  checkEditableAttributeValue,
  connectEditableCustomAttribute,
} from 'components/customer/summary/lib/custom_attributes_helper';
import CustomAttributeBase from './custom_attribute_base';
import EditableFieldControlled from 'components/lib/editable_field_controlled';
import { tryStringify } from 'scripts/lib/try_stringify';

/**
 * This component is a "single value" editable Custom Attribute, where "single value" can be either a string
 * or a number - as opposed to Date or Percentage (which can display a mini-graph). It is used for both STRING
 * and NUMBER editable custom attributes
 *
 * @returns {JSX.Element|null}
 */
export function EditableSingleValueCustomAttribute({ def, value, isDisabled, isResponsePending, onSubmit }) {
  if (def.type !== AttributeType.STRING && def.type !== AttributeType.NUMBER) {
    return null;
  }

  let tooltip = undefined;
  const { isValid, asString } = checkEditableAttributeValue(value, true);
  if (!isValid) {
    const sanitizedValue = _.truncate(tryStringify(value), { length: 25 });
    tooltip = `Unable to display the data. [${sanitizedValue}] may not be a correct value for '${def.label}'`;
  }

  const renderedValue = (
    <EditableFieldControlled
      className={classnames('editableCustomAttribute-input', { error: !isValid })}
      disabled={isDisabled}
      onSubmit={onSubmit}
      pending={isResponsePending}
      readOnly={false}
      title={tooltip}
      value={asString}
    />
  );

  return (
    <CustomAttributeBase className="editableCustomAttribute" label={def.label}>
      {renderedValue}
    </CustomAttributeBase>
  );
}

EditableSingleValueCustomAttribute.propTypes = {
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

const EditableSingleValueCustomAttributeContainer = connectEditableCustomAttribute(EditableSingleValueCustomAttribute);
export default EditableSingleValueCustomAttributeContainer;
