import _ from 'lodash';

export default class ClearSnippetErrors {
  constructor(context) {
    this.context = context;
  }

  run(snippetId) {
    _.forEach(this.context.stores.snippets.getAllErrors(), (err, id) => this.context.stores.snippets.clearErrors(id));
  }
}
