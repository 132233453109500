import _ from 'lodash';

import CreateComposition from './lib/create_composition';
import { extractQuotedPlainTextFromEmail, extractQuotedHtmlTextFromEmail } from 'actions/composition/lib/email_body';
import EmailCompositionContent from 'models/composition/email_composition_content';
import getNormalizedEmailAddress from 'scripts/lib/get_normalized_email_address';

export default class EditEmail extends CreateComposition {
  constructor(context) {
    super(context, EmailCompositionContent);
  }

  _createContent(attrs) {
    return new EmailCompositionContent({
      from: attrs.from,
      to: attrs.to ? _.map(attrs.to, addr => getNormalizedEmailAddress(addr)).join(', ') : undefined,
      cc: attrs.cc ? _.map(attrs.cc, addr => getNormalizedEmailAddress(addr)).join(', ') : undefined,
      bcc: attrs.bcc ? _.map(attrs.bcc, addr => getNormalizedEmailAddress(addr)).join(', ') : undefined,
      subjectHtml: attrs.subjectHtml,
      bodyHtml: attrs.bodyHtmlStripped,
      headers: { 'in-reply-to': attrs.inReplyTo },
      inlineAttachments: attrs.inlineAttachments,
      quotedPlain: extractQuotedPlainTextFromEmail(attrs.bodyPlain, attrs.bodyPlainStripped),
      quotedHtml: extractQuotedHtmlTextFromEmail(attrs.bodyHtml, attrs.bodyHtmlStripped),
    });
  }
}
