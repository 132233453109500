import MqttGateway from './lib/mqtt_gateway';

export default class SnippetCountGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'snippets/+/counts';
  }

  update(snippetId) {
    return this._update([snippetId]);
  }
}
