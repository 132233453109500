import _ from 'lodash';
import PropTypes from 'prop-types';

import CancelUpdatePhoneNumber from 'actions/customer_profile/cancel_update_phone_number';
import connect from 'components/lib/connect';
import CustomerPhoneField, { CustomerPhoneEditor } from './customer_phone_field';
import DeletePhoneNumber from 'actions/customer_profile/delete_phone_number';
import getMultiValueErrors from './get_multi_value_errors';
import MultiValueField from './multi_value_field';
import { PhoneNumberType } from 'models/phone_number';
import AddOrUpdatePhoneNumber from 'actions/customer_profile/add_or_update_phone_number';

const CustomerPhoneFields = MultiValueField(CustomerPhoneField, CustomerPhoneEditor);

CustomerPhoneFields.defaultProps = {
  className: 'customerProfile-phoneFields',
  isEmptyValue: isEmptyCustomerPhone,
  newValue: { type: PhoneNumberType.MOBILE },
};

export { CustomerPhoneFields };

function isEmptyCustomerPhone({ phoneNumber }) {
  return _.isEmpty(phoneNumber);
}

const CustomerPhoneFieldsContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerPhoneFields);

CustomerPhoneFieldsContainer.propTypes = {
  isAdding: PropTypes.bool,
  onDone: PropTypes.func,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, { isAdding }) {
  let profileProvider = getProvider('profile');
  let errors = getErrors();
  let otherAttributeHasErrors = !_(profileProvider.getErrors()).isEmpty() && _(errors).isEmpty();
  let isModalOpen = !!getProvider('modal').get();
  let isMergePending = getProvider('customerMerge').isPending();

  return {
    errors,
    forceDisplay: isMergePending,
    isAdding,
    isDisabled: profileProvider.isLoading() || isModalOpen || otherAttributeHasErrors,
    isPending: !!profileProvider.getPending(),
    pendingValues: getPendingValues(),
    smsEnabled: isFeatureEnabled('sms'),
    values: getValues(),
  };

  function getErrors() {
    return getMultiValueErrors(profileProvider.getErrors(), 'phones').map(mapCustomerPhone);
  }

  function getPendingValues() {
    return _(profileProvider.getPending())
      .get('phones', [])
      .map(mapCustomerPhone);
  }

  function getValues() {
    return _(profileProvider.get())
      .get('phones', [])
      .map(mapCustomerPhone);
  }
}

function mapExecuteToProps(executeAction, { onDone }) {
  return {
    onBlurNew: done,
    onCancel: cancel,
    onSave: save,
  };

  function cancel(index) {
    executeAction(CancelUpdatePhoneNumber, { index });
    done();
  }

  function done() {
    if (onDone) {
      onDone();
    }
  }

  function save(index, { phoneNumber, extension, isPrimary, smsPreference, type }) {
    if (phoneNumber === '') {
      executeAction(DeletePhoneNumber, { index });
    } else {
      executeAction(
        AddOrUpdatePhoneNumber,
        _.omitBy(
          { index, phoneNumber, extension, primary: isPrimary, smsPreference, type, source: 'manual update' },
          _.isUndefined
        )
      );
    }
    done();
  }
}

export default CustomerPhoneFieldsContainer;

function mapCustomerPhone({ extension, original, phone, primary, smsPreference, type }) {
  return _.omitBy(
    { extension, isPrimary: primary, phoneNumber: original || phone, smsPreference, type },
    _.isUndefined
  );
}
