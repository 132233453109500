import React, { useMemo } from 'react';

import AttachSnippetAttachments from 'actions/composition/attach_snippet_attachments';
import ClearSelectedKbItem from 'actions/composition/clear_selected_kb_item';
import connect from 'components/lib/connect';

export const DEFAULT_COMPOSER_CONTEXT = {
  answerToInsert: undefined,
  attachSnippetAttachments: () => {},
  clearInsertedAnswer: () => {},
  composition: undefined,
};
const ComposerContext = React.createContext(DEFAULT_COMPOSER_CONTEXT);
export default ComposerContext;

export function ComposerContextProviderBase({
  answerToInsert,
  attachSnippetAttachments,
  children,
  composition,
  compositionErrors,
  clearInsertedAnswer,
  isExperimentalEditorEnabled,
  isMinimized,
}) {
  const value = useMemo(
    () => ({
      answerToInsert,
      attachSnippetAttachments,
      clearInsertedAnswer,
      composition,
      compositionErrors,
      isExperimentalEditorEnabled,
      isMinimized,
    }),
    [
      answerToInsert,
      attachSnippetAttachments,
      clearInsertedAnswer,
      composition,
      isExperimentalEditorEnabled,
      isMinimized,
    ]
  );

  return <ComposerContext.Provider value={value}>{children}</ComposerContext.Provider>;
}

const ComposerContextProvider = connect(mapStateToProps, mapExecuteToProps)(ComposerContextProviderBase);
export { ComposerContextProvider };

function mapStateToProps({ getProvider, isFeatureEnabled }, { composition }) {
  const selectedKbItem = getProvider('selectedKbItem').get();
  const compositionsProvider = getProvider('compositions');
  const agentPreferences = getProvider('agentPreferences').get();

  return {
    answerToInsert: selectedKbItem,
    compositionErrors: compositionsProvider.has({ id: composition.id })
      ? compositionsProvider.getErrors(composition.id)
      : [],
    isExperimentalEditorEnabled: agentPreferences.experimentalEditorEnabled,
    isMinimized: getProvider('currentLocation').get().isCompositionMinimized,
  };
}

let clearInsertedAnswer;
function mapExecuteToProps(executeAction) {
  if (!clearInsertedAnswer) {
    clearInsertedAnswer = () => executeAction(ClearSelectedKbItem);
  }

  const attachSnippetAttachments = ({ compositionId, contentChannelType, snippetItem, language }) =>
    executeAction(AttachSnippetAttachments, {
      compositionId,
      contentChannelType,
      item: snippetItem,
      language,
    });

  return {
    attachSnippetAttachments,
    clearInsertedAnswer,
  };
}
