import React from 'react';

import { ANSWER_LINK } from '../../plugins/answer_links';

export const answerLinkRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'answer') {
        return {
          object: 'inline',
          type: ANSWER_LINK,
          nodes: next(el.childNodes),
          data: { answerId: el.getAttribute('data-id'), answerName: el.getAttribute('data-name') },
        };
      }
    },
    serialize(object, children) {
      if (object.object === 'inline' && object.type === ANSWER_LINK) {
        return (
          <answer data-id={object.data.get('answerId')} data-name={object.data.get('answerName')}>
            {children}
          </answer>
        );
      }
    },
  },
];
