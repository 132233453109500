import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import Spinner from 'components/common/spinner';
import Tooltip, { TooltipTypes } from 'components/common/tooltip';

import TrackTransactionImageLoad from 'actions/customer_profile/track_transaction_image_load';

const StyledAttributeTooltip = styled.div`
  align-items: center;
  display: flex;
  font-size: ${p => p.theme.fontSize.medium};
  justify-content: center;
  max-height: 232px;
  max-width: 232px;

  ${({ isLoaded }) =>
    !isLoaded &&
    `
    height: 232px;
    width: 232px;
  `}
`;

export const StyledImage = styled.img`
  max-width: 232px;
  max-height: 232px;
  display: ${p => (p.isLoaded ? 'block' : 'none')};
`;

export class AttributesWithImages extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleLoad', 'handleError']);

    this.state = {
      isLoaded: false,
      hasError: false,
    };
  }

  render() {
    const { imageUrl, children } = this.props;

    if (!imageUrl || !children) {
      return children || null;
    }
    let message = <Spinner size={68} />;
    if (this.state.hasError) {
      message = 'This image cannot be found';
    } else if (this.state.isLoaded) {
      message = '';
    }

    const image = (
      <StyledAttributeTooltip isLoaded={this.state.isLoaded}>
        {message}
        <StyledImage
          isLoaded={this.state.isLoaded}
          onError={this.handleError}
          onLoad={this.handleLoad}
          src={imageUrl}
        />
      </StyledAttributeTooltip>
    );

    return (
      <Tooltip message={image} position="right" targetPosition="start" type={TooltipTypes.IMAGE}>
        {children}
      </Tooltip>
    );
  }

  handleLoad(evt) {
    this.setState({ isLoaded: true });
    this.props.onLoad(false);
  }

  handleError(evt) {
    this.setState({ hasError: true });
    this.props.onLoad(true);
  }
}

function mapExecuteToProps(executeAction) {
  return {
    onLoad: hasError => executeAction(TrackTransactionImageLoad, { hasError }),
  };
}

export default connect(null, mapExecuteToProps)(AttributesWithImages);
