import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 32 32" {...props} className="capacityCard-messaging-icon">
    <path d="M0 0h32v32H0z" fill="#fff" />
    <path d="M32 16c0-8.837-7.163-16-16-16S0 7.163 0 16s7.163 16 16 16 16-7.163 16-16z" fill="#5A004A" />
    <path
      d="M16 8.125v9m0 0l-2.25-2.813M16 17.125l2.25-2.813"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M11.642 17.125H8.625a.5.5 0 0 0-.5.5v5.75a.5.5 0 0 0 .5.5h14.75a.5.5 0 0 0 .5-.5v-5.75a.5.5 0 0 0-.5-.5h-3.017a4.502 4.502 0 0 1-8.716 0z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M11.642 17.125l.484-.125a.5.5 0 0 0-.484-.375v.5zm8.716 0v-.5a.5.5 0 0 0-.484.375l.484.125zm-11.733.5h3.017v-1H8.625v1zm0 0v-1a1 1 0 0 0-1 1h1zm0 5.75v-5.75h-1v5.75h1zm0 0h-1a1 1 0 0 0 1 1v-1zm14.75 0H8.625v1h14.75v-1zm0 0v1a1 1 0 0 0 1-1h-1zm0-5.75v5.75h1v-5.75h-1zm0 0h1a1 1 0 0 0-1-1v1zm-3.017 0h3.017v-1h-3.017v1zM19.874 17A4.002 4.002 0 0 1 16 20v1a5.002 5.002 0 0 0 4.842-3.75l-.968-.25zM16 20a4.002 4.002 0 0 1-3.874-3l-.968.25A5.002 5.002 0 0 0 16 21v-1z"
      fill="#fff"
    />
  </IconContainer>
);
