import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import CustomerPhoneNumberPropType from './customer_phone_number_prop_type';
import PhoneTypeSelect from './phone_type_select';
import { SmsPreferenceType } from 'models/phone_number';

export default function CustomerPhoneDisplay({ isDisabled, value, onClick }) {
  const classNames = classnames('customerProfile-contactDetails-row', 'customerProfile-contactDetails-row-phone', {
    'customerProfile-contactInput-disabled': isDisabled,
  });
  return (
    <div className="customerProfile-contactDetails-container">
      <div className={classNames} onClick={handlePhoneNumberClick}>
        <div className="customerProfile-contactInput customerPhoneDisplay-phoneNumber" data-aid="customer-phone-input">
          {getPhoneText()}
        </div>
        {value.smsPreference === SmsPreferenceType.ON ? (
          <div className="customerProfile-contactDetails-attrib-button customerProfile-contactDetails-attrib-button-active">
            SMS
          </div>
        ) : null}
        {value.isPrimary ? (
          <div className="customerProfile-contactDetails-attrib-button customerProfile-contactDetails-attrib-button-active">
            Main
          </div>
        ) : null}
      </div>
      <PhoneTypeSelect isDisabled={isDisabled} onOpen={handlePhoneTypeOpen} value={value.type} />
    </div>
  );

  function getPhoneText() {
    return value.extension ? `${value.phoneNumber} x${value.extension}` : value.phoneNumber;
  }

  function handlePhoneNumberClick() {
    onClick('phoneNumber');
  }

  function handlePhoneTypeOpen() {
    onClick('phoneType');
  }
}

CustomerPhoneDisplay.propTypes = {
  isDisabled: PropTypes.bool,
  value: CustomerPhoneNumberPropType.isRequired,

  onClick: PropTypes.func,
};
