import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import ClearExternalCustomerSearchResults from 'actions/external_customer_lookup/clear_external_customer_search_results';
import connect from 'components/lib/connect';
import CustomerProfile from 'models/customer_profile';
import DropdownMenu from 'components/common/dropdown_menu';
import Err from 'models/err';
import ExternalCustomerLookupSearchResults from './external_customer_lookup_search_results';
import ExternalCustomerProfile from 'models/external_customer_profile';
import Input from 'components/common/input';
import LinkExternalCustomer from 'actions/external_customer_lookup/link_external_customer';
import NavigateBack from 'actions/current_location/navigate_back';
import SearchExternalCustomers from 'actions/external_customer_lookup/search_external_customers';
import Tooltip, { FocusManager } from 'components/common/tooltip';
import TrackEvent from 'actions/analytics/track_event';

const DEFAULT_INTEGRATION_ID = '';

export class ExternalCustomerLookupSearch extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, [
      'handleChangeField',
      'handleChangeSource',
      'handleClickClear',
      'handleClickSearch',
      'handleFieldKeyDown',
      'handleLinkAndConfirm',
    ]);

    const integrationCount = (props.integrations && props.integrations.length) || 0;
    const initialIntegration = integrationCount === 1 ? props.integrations[0].id : DEFAULT_INTEGRATION_ID;
    this.state = {
      ...this.getInitialSearchFieldState(props),
      searchAttributes: this.getSearchAttributesForIntegration(initialIntegration),
      resultAttributes: this.getResultAttributesForIntegration(initialIntegration),
      hideErrorsForField: {},
      selectedIntegrationId: initialIntegration,
    };
  }

  render() {
    return (
      <div className="externalCustomerLookup">
        {this.renderSearchPanel()}
        <ExternalCustomerLookupSearchResults
          integrations={this.props.integrations}
          isAlreadyLinked={this.props.isAlreadyLinked}
          isExternalLookupPending={this.props.isExternalLookupPending}
          linkErrors={this.props.linkErrors}
          lookupResultAttributes={this.state.resultAttributes}
          lookupResultProfilesByIntegrationId={this.props.lookupResultProfilesByIntegrationId}
          lookupSuggestionsByIntegrationId={this.props.lookupSuggestionsByIntegrationId}
          onCancel={this.props.onCancel}
          onLink={this.handleLinkAndConfirm}
          profile={this.props.profile}
        />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isExternalLookupPending && !this.props.isExternalLookupPending) {
      // Search just completed - time to emit analytics events
      this.sendAnalyticsEvents();
    }
  }

  renderSearchPanel() {
    if (_.isEmpty(this.props.lookupSearchFields) || _.isEmpty(this.props.lookupResultAttributes)) {
      return null;
    }

    return (
      <div className={classnames('externalCustomerLookup-search')}>
        {this.renderSearchFields()}
        {this.renderSearchFooter()}
      </div>
    );
  }

  renderSearchFields() {
    return (
      <div className="externalCustomerLookup-searchFields">
        <div className="externalCustomerLookup-searchFields-header">Link Customer to External System</div>
        {this.renderSourceDropdown()}
        {this.renderSearchError()}
        {_.map(this.state.searchAttributes, (searchField, index) => this.renderSearchField(searchField, index))}
      </div>
    );
  }

  renderSourceDropdown() {
    const includeAll = this.props.integrations && this.props.integrations.length > 1;
    const options = includeAll ? [{ label: 'All Unlinked External Systems', value: DEFAULT_INTEGRATION_ID }] : [];

    _.forEach(this.props.integrations, integration => {
      options.push({
        label: integration.name,
        value: integration.id,
      });
    });

    return (
      <div>
        <label className="externalCustomerLookup-searchFields-field-label">Search In</label>
        <div className="externalCustomerLookup-searchFields-dropdown">
          <DropdownMenu
            allowEmptyValues
            fitOpenerWidth
            onSelect={this.handleChangeSource}
            options={options}
            value={this.state.selectedIntegrationId}
          />
        </div>
      </div>
    );
  }

  handleChangeSource(selectedIntegrationId) {
    this.setState({
      selectedIntegrationId,
      searchAttributes: this.getSearchAttributesForIntegration(selectedIntegrationId),
    });
  }

  renderSearchError() {
    const err = this.getSearchError();
    if (!err) {
      return null;
    }

    let errorMessage;
    if (err.code === Err.Code.OPERATION_FAILED) {
      errorMessage = `Sorry, search is not working right now. Try again soon.`;
    } else if (err.code === Err.Code.INVALID_STATE) {
      errorMessage = `Sorry, search is no longer available. Please talk to your supervisor.`;
    } else {
      // these will likely be for if (err.code === Err.Code.UNEXPECTED_ERROR || err.code === Err.Code.INVALID)
      // which would correpond to circuit breaker errors, SN 5xx errors, or lookup 4xx errors
      errorMessage = `Sorry, search is not working right now.`;
    }

    return <div className="externalCustomerLookup-searchFields-error">{errorMessage}</div>;
  }

  renderSearchField(field, index) {
    const errorMessage = this.getErrorMessageForField(field.attr);
    const inputClassNames = classnames('externalCustomerLookup-searchFields-field-input', {
      'externalCustomerLookup-searchFields-field-input-error': !!errorMessage,
    });
    return (
      <div className="externalCustomerLookup-searchFields-field" key={field.attr}>
        <label className="externalCustomerLookup-searchFields-field-label">{field.label}</label>
        <Tooltip message={errorMessage} position="right">
          <FocusManager>
            {({ onBlur, onFocus }) => (
              <Input
                autoFocus={index === 0}
                className={inputClassNames}
                name={field.attr}
                onBlur={onBlur}
                onChange={this.handleChangeField}
                onFocus={onFocus}
                onKeyDown={this.handleFieldKeyDown}
                placeholder={field.placeholder || field.label}
                value={this.state[field.attr]}
              />
            )}
          </FocusManager>
        </Tooltip>
      </div>
    );
  }

  renderSearchFooter() {
    return (
      <div className="externalCustomerLookup-searchFields-footer">
        <Button
          blurOnClick
          buttonType={ButtonTypes.TEXT}
          className="externalCustomerLookup-searchFields-footer-clearButton"
          onClick={this.handleClickClear}
        >
          Clear
        </Button>
        <Button
          blurOnClick
          buttonType={ButtonTypes.PRIMARY}
          className="externalCustomerLookup-searchFields-footer-searchButton"
          onClick={this.handleClickSearch}
        >
          Search
        </Button>
      </div>
    );
  }

  getSearchQuery() {
    return _.pick(
      this.state,
      _.map(this.state.searchAttributes, field => field.attr)
    );
  }

  getSearchError() {
    return _.find(this.props.searchErrors, e => !e.attr);
  }

  handleChangeField(evt) {
    const field = evt.target.name;
    const value = evt.target.value;

    this.setState(({ hideErrorsForField }) => ({
      hideErrorsForField: {
        ...hideErrorsForField,
        [field]: true,
      },
      [field]: value,
    }));
  }

  handleClickClear() {
    this.setState({
      ...this.getInitialSearchFieldState(),
    });
    this.props.onClearSearch();
  }

  handleClickSearch() {
    this.setState({
      resultAttributes: this.getResultAttributesForIntegration(this.state.selectedIntegrationId),
    });

    // Pick out the fields we are searching on
    const query = this.getSearchQuery();

    // Check to make sure at least one search field was entered
    const filledOut = _.find(query, value => `${value}`.trim().length > 0);
    if (!filledOut) return;

    this.props.onSearchExternalCustomers({
      query,
      customerId: this.props.profile.id,
      integrationId: this.state.selectedIntegrationId,
    });
  }

  handleFieldKeyDown(evt) {
    if (evt.key === 'Enter') {
      this.handleClickSearch();
    }
  }

  handleLinkAndConfirm(externalProfiles, meta) {
    this.props.onConfirm(externalProfiles, this.props.profile.id, meta);
  }

  getErrorMessageForField(field) {
    const err = _.find(this.props.searchErrors, e => e.attr === field);
    if (!err || this.state.hideErrorsForField[field]) {
      return '';
    }

    return err.detail || getMessageForError(err);
  }

  getInitialSearchFieldState(props = this.props) {
    const searchFields = props.lookupSearchFields;
    return _.zipObject(
      _.map(searchFields, field => field.attr),
      _.times(searchFields.length, () => '')
    );
  }

  getSelectedIntegration(integrationId) {
    return _.find(this.props.integrations, ['id', integrationId]);
  }

  getSearchAttributesForIntegration(integrationId) {
    if (integrationId === DEFAULT_INTEGRATION_ID) {
      return this.props.lookupSearchFields;
    }
    return _.get(this.getSelectedIntegration(integrationId), 'customerLookupSettings.searchQueryAttributes', []);
  }

  getResultAttributesForIntegration(integrationId) {
    if (integrationId === DEFAULT_INTEGRATION_ID) {
      return this.props.lookupResultAttributes;
    }
    return _.get(this.getSelectedIntegration(integrationId), 'customerLookupSettings.searchResultAttributes', []);
  }

  getSearchResultAnalyticsProps() {
    const query = this.getSearchQuery();
    const searchFields = _.reduce(
      query,
      (acc, value, field) => {
        if ((value || '').trim()) acc.push(field);
        return acc;
      },
      []
    );

    const selectedIntegration =
      this.state.selectedIntegrationId === DEFAULT_INTEGRATION_ID
        ? {}
        : this.getSelectedIntegration(this.state.selectedIntegrationId);
    const max = _.reduce(
      this.props.lookupResultProfilesByIntegrationId,
      (acc, resultSet) => Math.max(acc, _.get(resultSet, 'length', 0)),
      0
    );

    return {
      customerId: this.props.profile.id,
      numResultsMax: max,
      searchFields: searchFields.join(','),
      numIntegrations: (this.props.integrations || []).length,
      selectedIntegrationId: selectedIntegration.id || '',
      selectedIntegrationType: selectedIntegration.type || '',
    };
  }

  sendAnalyticsEvents() {
    if (!_.isFunction(this.props.onTrackedEvent)) return;

    const err = this.getSearchError();
    if (err) {
      // Search error (SN has returned an error code)
      this.props.onTrackedEvent('External Customer Lookup Error Returned', {
        customerId: this.props.profile.id,
      });
    } else {
      // Search returned some results
      const props = this.getSearchResultAnalyticsProps();
      this.props.onTrackedEvent('External Customer Lookup Searched', props);
    }
  }
}

function getMessageForError(err) {
  switch (err.code) {
    case Err.Code.BLANK:
      return 'This field cannot be blank';
    default:
      return 'This field is invalid';
  }
}

ExternalCustomerLookupSearch.propTypes = {
  searchErrors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
  linkErrors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
  isAlreadyLinked: PropTypes.bool.isRequired,
  isExternalLookupPending: PropTypes.bool.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.object),
  lookupResultAttributes: PropTypes.arrayOf(PropTypes.object).isRequired,
  lookupResultProfiles: PropTypes.arrayOf(PropTypes.instanceOf(ExternalCustomerProfile)),
  lookupResultProfilesByIntegrationId: PropTypes.object,
  lookupSearchFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  lookupSuggestionsByIntegrationId: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSearchExternalCustomers: PropTypes.func.isRequired,
  onTrackedEvent: PropTypes.func,
  profile: PropTypes.instanceOf(CustomerProfile).isRequired,
};

const ExternalCustomerLookupSearchContainer = connect(mapStateToProps, mapExecuteToProps)(ExternalCustomerLookupSearch);

ExternalCustomerLookupSearchContainer.propTypes = {
  profile: PropTypes.instanceOf(CustomerProfile).isRequired,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, { profile }) {
  const searchResults = getProvider('externalCustomerSearchResults').get();
  const suggestions = getProvider('externalLookupSuggestions').get();

  const integrations = _.filter(getProvider('integrations').findAll(), integration => {
    if (!integration.customerLookupSettings) {
      return false;
    }
    const isNotLinked = _.isEmpty(_.get(profile, `externalCustomerIds.${integration.id}`));
    const available = integration.enabled && isNotLinked;
    if (!available) return false;

    // Skip misconfigured integrations (i.e. those missing search attributes or result attributes)
    const searchAttrs = getAttributeList(integration, 'customerLookupSettings.searchQueryAttributes');
    const resultAttrs = getAttributeList(integration, 'customerLookupSettings.searchResultAttributes');
    return !_.isEmpty(searchAttrs) && !_.isEmpty(resultAttrs);
  });

  const isAlreadyLinked = _.isEmpty(integrations);

  // At this point, integrations => is an array of Integration models. Convert them to plain JS
  // before extracting the fields
  const integrationsJs = isAlreadyLinked ? [] : _.map(integrations, integration => integration.toJs());

  const lookupSearchFields = isAlreadyLinked
    ? []
    : getCombinedAttributes(integrationsJs, 'customerLookupSettings.searchQueryAttributes');
  const lookupResultAttributes = isAlreadyLinked
    ? []
    : getCombinedAttributes(integrationsJs, 'customerLookupSettings.searchResultAttributes');

  return {
    searchErrors: getProvider('externalCustomerSearchResults').getErrors(),
    linkErrors: getProvider('profile').getErrors(),
    integrations,
    isAlreadyLinked,
    isExternalLookupPending: !!getProvider('externalCustomerSearchResults').getPending(),
    lookupResultAttributes,
    lookupResultProfilesByIntegrationId:
      (searchResults && (searchResults.getResultsByIntegrationId() || {})) || undefined,
    lookupSearchFields,
    lookupSuggestionsByIntegrationId: (suggestions && (suggestions.getSuggestionsByIntegrationId() || {})) || undefined,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onCancel: () => executeAction(NavigateBack),
    onConfirm: (externalProfiles, customerId, meta) =>
      executeAction(LinkExternalCustomer, { externalProfiles, customerId, meta }),
    onClearSearch: () => executeAction(ClearExternalCustomerSearchResults),
    onSearchExternalCustomers: ({ query, customerId, integrationId }) =>
      executeAction(SearchExternalCustomers, { query, customerId, integrationId }),
    onTrackedEvent: (event, props) => executeAction(TrackEvent, { event, props }),
  };
}

function getAttributeList(integration, path) {
  return _.filter(_.get(integration, path, []), elt => !!elt.attr);
}

function getCombinedAttributes(integrationList, path) {
  return _.filter(_.uniqBy(_.flatMap(integrationList, path), 'attr'), elt => !!elt.attr);
}

export default ExternalCustomerLookupSearchContainer;
