import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import Err from 'models/err';
import { reconcileInboxItem } from 'actions/inbox/agent/agent_inbox_helpers';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';

export default class RequestInboxItem {
  constructor(context) {
    this.context = context;
  }

  run({ id, type }) {
    if (type === 'CUSTOMER') {
      this.context.gateways.inboxCustomerItem
        .fetch({ id })
        .then(dto => {
          const inboxItem = AgentInboxItem.fromJs({
            ...dto,
            hasFetchedAtLeastOnce: true,
            type: AgentInboxItem.Type.CUSTOMER,
          });
          reconcileInboxItem(this.context, inboxItem, this.context.stores.agentInboxItemsNew);
        })
        .catch(err => {
          if (err.code !== 'not_exist') {
            handleCommonErrors(this.context, err);
          }
          // checks if item exist before setting errors to timeout
          if (this.context.stores.agentInboxItemsNew.has({ id })) {
            this.context.stores.agentInboxItemsNew.setErrors(id, [new Err({ code: 'timeout' })]);
          }
        });
    } else {
      this.context.gateways.inboxTaskItem
        .fetch({ id })
        .then(dto => {
          const inboxItem = AgentInboxItem.fromJs({
            ...dto,
            hasFetchedAtLeastOnce: true,
            type: AgentInboxItem.Type.TASK,
          });
          reconcileInboxItem(this.context, inboxItem, this.context.stores.agentInboxItemsNew);
        })
        .catch(err => {
          if (err.code !== 'not_exist') {
            handleCommonErrors(this.context, err);
          }
          // checks if item exist before setting errors to timeout
          if (this.context.stores.agentInboxItemsNew.has({ id })) {
            this.context.stores.agentInboxItemsNew.setErrors(id, [new Err({ code: 'timeout' })]);
          }
        });
    }
  }
}
