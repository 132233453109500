import React from 'react';
import { isObject } from 'lodash';
import { MARK_COLOR, usePlateEditorState } from '@udecode/plate';

import { StyledColorPickerToolbarDropdown } from 'components/composer/shared/font_color_controls_styles';
import { COLORS } from 'components/composer/shared/font_color_constants';

export default function FontColorControls({ editorId }) {
  const editor = usePlateEditorState(editorId);

  if (!isObject(editor.pluginsByKey[MARK_COLOR])) return null;

  return <StyledColorPickerToolbarDropdown colors={COLORS} pluginKey={MARK_COLOR} title="Font color" />;
}
