import StandardGateway from 'scripts/adapters/gateways/lib/standard_gateway';

export default class WebhookGateway extends StandardGateway {
  constructor(backend) {
    super(backend, {
      broadcastTopic: 'v1/orgs/:orgId/webhooks/:webhookId',
      broadcastDeleteTopic: 'v1/orgs/:orgId/webhooks/:webhookId/event/delete',
      collectionBroadcastTopic: 'v1/orgs/:orgId/webhooks/+',
      collectionBroadcastDeleteTopic: 'v1/orgs/:orgId/webhooks/+/event/delete',
      fetchAllUrl: '/api/v1/webhooks',
      fetchUrl: '/api/v1/webhooks/:webhookId',
      addUrl: '/api/v1/webhooks',
      updateUrl: '/api/v1/webhooks/:webhookId',
      deleteUrl: '/api/v1/webhooks/:webhookId',
    });
  }
}
