import { Factory } from 'rosie';
import './lib/uuid';

import IdGenerator from './lib/id_generator';

Factory.define('TopicAncestry')
  .extend('Uuid')
  .attr('topicId', () => IdGenerator.newId())
  .attr('ancestralTopicId', () => IdGenerator.newId());

Factory.define('TopicAncestryWithDefaults')
  .extend('TopicAncestry')
  .attr('topicId', 'topic-id')
  .attr('ancestralTopicId', 'ancestor-id');
