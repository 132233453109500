import { getAllAttachments } from 'models/answers/snippet_helpers';
import Upload from 'models/upload';

export default class SnippetCompositionUploads {
  constructor(composition) {
    this.composition = composition;
  }

  cleanUp(context) {
    let attachments = getAllAttachments(this.composition);
    if (!attachments) {
      return;
    }

    let uploads = attachments.filter(a => a instanceof Upload);

    uploads
      .filter(u => u.status === Upload.Status.COMPLETED)
      .forEach(u => context.gateways.snippetUpload.remove(this.getUploadPath(u.id)));

    uploads
      .filter(u => u.status === Upload.Status.STARTED)
      .forEach(u => context.gateways.snippetUpload.abort(this.getUploadPath(u.id)));
  }

  getUploadPath(uploadId) {
    return this.composition.getUploadPath(uploadId);
  }

  static fromComposition(composition) {
    return new SnippetCompositionUploads(composition);
  }
}
