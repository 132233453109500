import AdminNotification from 'models/notification/admin_notification';
import DismissNotification from 'actions/notification/dismiss_notification';

export default class ShowAdminNotification {
  constructor(context) {
    this.context = context;
  }

  run(message) {
    let notification = AdminNotification.create({ message });
    this.context.stores.notifications.addOrReplace(notification);
    DismissNotification.schedule(this.context, { id: notification.id });
  }
}
