import AgentActivity from 'models/agent_activity';
import CancelAgentInboxPolling from 'actions/inbox/agent/cancel_agent_inbox_polling';
import removeOptionalUpgradeNotifications from 'actions/version/lib/remove_optional_upgrade_notifications';
import runLoggedOut from 'actions/lib/run_logged_out';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import { teardownFakeWebRTCConnection } from 'scripts/configuration/configure_fake_webrtc';
import UserSessionResetter from 'actions/user/lib/user_session_resetter';

export default class LogOut extends UserSessionResetter {
  run() {
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.LOG_OUT });
    this.context.executeAction(CancelAgentInboxPolling);
    this.reset();

    removeOptionalUpgradeNotifications(this.context);
    runLoggedOut(this.context);
    teardownFakeWebRTCConnection();
    this.context.router.navigateToPath('/user/logout');
  }
}
