import createModel from './lib/create_model';

const RecentlyMentionedAgentId = createModel({
  modelName: 'RecentlyMentionedAgentId',

  properties: {
    count: Number,
    id: String,
    updatedAt: String,
  },

  incrementCount() {
    this.count += 1;
    this.updatedAt = new Date().toISOString();
  },

  statics: {
    create(attrs) {
      return new this({
        count: 1,
        updatedAt: new Date().toISOString(),
        ...attrs,
      });
    },
  },
});

export default RecentlyMentionedAgentId;
