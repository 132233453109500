import findKey from 'lodash/findKey';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { removeInlineOfTypeOnBackspace } from './selection';
import { useVariables as useVariablesContext } from 'components/composer/contexts/variables_context';

export const VARIABLE = 'variable';

export const StyledVariable = styled.span.attrs({ 'data-aid': 'slate-variable' })`
  border-bottom: 1px dashed;
  ${p => p.isEmpty && emptyVariable};
`;

const emptyVariable = css`
  border-bottom: 1px dashed ${p => p.theme.colors.red400};
  color: ${p => p.theme.colors.red400};
`;

export default function Variables() {
  return {
    commands: { updateVariables },

    onChange(editor, next) {
      const { value } = editor;

      const variableInlines = value.inlines.filter(i => i.type === VARIABLE);
      if (!variableInlines.count()) {
        return next();
      }

      return editor.ensureFullSelection(next, VARIABLE);
    },

    onKeyDown(evt, editor, next) {
      if (removeInlineOfTypeOnBackspace(editor, evt, VARIABLE)) {
        return true;
      }

      return next();
    },

    renderNode(props, editor, next) {
      if (props.node.type === VARIABLE) {
        const { attributes, children, node } = props;
        const isEmpty = node.get('data').get('isEmpty');
        return (
          <StyledVariable {...attributes} isEmpty={isEmpty}>
            {children}
          </StyledVariable>
        );
      }
      return next();
    },

    schema: {
      inlines: {
        [VARIABLE]: {
          nodes: [
            {
              match: { object: 'text' },
              min: 1,
              max: 1,
            },
          ],
          text: /.+/, // Can't have empty variables
        },
      },
    },
  };
}

export function updateVariables(editor, variables) {
  const variableInlines = editor.value.document.getInlinesByType(VARIABLE);
  const originalSelection = editor.value.selection;

  variableInlines.forEach(inline => {
    let variableId = inline.data.get('variableId');
    const variableType = inline.data.get('variableType');
    const textNode = inline.getTexts().get(0);
    const variableText = textNode.text;

    if (!variableId) {
      variableId = findKey(variables, { type: variableType });
    }

    if (!variables[variableId]) {
      return;
    }

    editor.withoutSaving(() => {
      if (variables[variableId].text !== variableText) {
        editor.withoutNormalizing(() => {
          editor.moveToRangeOfNode(textNode).insertText(variables[variableId].text);
        });
      }
      editor.setNodeByKey(inline.key, {
        data: inline.data.set('isEmpty', variables[variableId].isEmpty),
      });
    });
    editor.select(originalSelection);
  });
}

export function useVariables(editor, onChange) {
  const variables = useVariablesContext();
  useEffect(() => {
    if (editor) {
      updateVariables(editor, variables);
      onChange(editor);
    }
  }, [editor, onChange, variables]);
}
