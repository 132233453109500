import { useContext, useEffect, useRef } from 'react';

import ClearSelectedSuggestedReply from 'actions/answers/clear_selected_suggested_reply';
import ComposerContext from 'components/composer/contexts/composer_context';
import connect from 'components/lib/connect';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import SetSuggestedReplyOnComposition from 'actions/composition/set_suggested_reply_on_composition';

export function InsertSelectedSuggestedReply({
  clearSelectedSuggestedReply,
  editor,
  isSuggestedRepliesEnabled,
  onChange,
  selectedSuggestedReply,
  setSuggestedReplyOnComposition,
}) {
  const prevSelectedSuggestedReply = useRef(null);
  const hasValidSelection = editor && !!editor.value.selection.start.key;
  const { composition } = useContext(ComposerContext);

  useEffect(() => {
    if (!isSuggestedRepliesEnabled) {
      return;
    }

    if (!editor || !onChange || !selectedSuggestedReply || !composition) {
      return;
    }

    if (editor && selectedSuggestedReply !== prevSelectedSuggestedReply.current) {
      if (!hasValidSelection) {
        editor.focus();
        return;
      }
      if (selectedSuggestedReply) {
        prevSelectedSuggestedReply.current = selectedSuggestedReply;
        const htmlToInsert = `<div>${selectedSuggestedReply.item.text}</div>`;
        const value = FullSerializer.deserialize(htmlToInsert);
        editor.insertFragment(value.document);
        onChange(editor);
        setSuggestedReplyOnComposition({
          currentCompositionId: composition.id,
          suggestedReply: selectedSuggestedReply.item,
        });
        clearSelectedSuggestedReply();
      }
    }
  }, [
    editor,
    composition,
    onChange,
    selectedSuggestedReply,
    clearSelectedSuggestedReply,
    hasValidSelection,
    isSuggestedRepliesEnabled,
  ]);

  return null;
}

function mapStateToProps({ getProvider, isFeatureEnabled }, { editor, onChange }) {
  const selectedSuggestedReply = getProvider('selectedSuggestedReply').get();
  const isSuggestedRepliesEnabled = isFeatureEnabled('suggestedReplies');
  return {
    editor,
    isSuggestedRepliesEnabled,
    onChange,
    selectedSuggestedReply,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    clearSelectedSuggestedReply: () => executeAction(ClearSelectedSuggestedReply),
    setSuggestedReplyOnComposition: attrs => executeAction(SetSuggestedReplyOnComposition, attrs),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(InsertSelectedSuggestedReply);
