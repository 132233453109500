import _ from 'lodash';

import connect from 'components/lib/connect';
import ChecklistFilterWithCounts from 'components/search/filters/checklist_filter_with_counts';
import Conversation from 'models/conversation';

let mapStateToProps = function(context, props) {
  let result = context.getProvider('universalSearchResults').findBy({ id: props.queryId });
  let statusAggs = _.get(result, 'aggregations.statuses.buckets');
  let selectedStatuses = _.get(result, 'query.filter.statuses') || [];
  let options = _.map(Conversation.InboxStatus, status => {
    return {
      count: statusAggs ? _.get(statusAggs, status) || 0 : null,
      value: status,
      label: _.startCase(status.toLowerCase()),
      isSelected: selectedStatuses.indexOf(status) > -1,
    };
  });

  let onChange = (status, isSelected) => {
    selectedStatuses = isSelected ? _.union(selectedStatuses, [status]) : _.difference(selectedStatuses, [status]);
    props.onChange({ statuses: selectedStatuses });
  };

  return {
    isLoading: result ? result.isLoading : false,
    onChange,
    options,
  };
};

export default connect(mapStateToProps)(ChecklistFilterWithCounts);
