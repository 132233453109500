import PropTypes from 'prop-types';

import CloseModal from 'actions/modal/close_modal';
import connect from 'components/lib/connect';
import DownloadableAttachment from 'models/downloadable_attachment';
import OpenAttachmentPreview from 'actions/modal/open_attachment_preview';
import PreviewAttachmentModal from 'components/lib/attachment/preview_attachment_modal';

const PreviewAttachmentModalContainerBase = connect(null, mapExecuteToProps)(PreviewAttachmentModal);

PreviewAttachmentModalContainerBase.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(DownloadableAttachment)).isRequired,
  currentIndex: PropTypes.number.isRequired,
};

function mapExecuteToProps(executeAction, props) {
  return {
    onClose: () => executeAction(CloseModal),
    onNavigateLeft: attachment => navigateLeft(attachment),
    onNavigateRight: attachment => navigateRight(attachment),
  };

  function navigateLeft() {
    if (props.currentIndex === 0) {
      return;
    }

    executeAction(OpenAttachmentPreview, {
      attachments: props.attachments,
      currentIndex: props.currentIndex - 1,
    });
  }

  function navigateRight() {
    if (props.currentIndex === props.attachments.length - 1) {
      return;
    }

    executeAction(OpenAttachmentPreview, {
      attachments: props.attachments,
      currentIndex: props.currentIndex + 1,
    });
  }
}

const PreviewAttachmentModalContainer = connect((context, props) => {
  let attachmentPreview = context.getProvider('modal').get();
  return {
    attachments: attachmentPreview.attachments,
    currentIndex: attachmentPreview.currentIndex,
  };
})(PreviewAttachmentModalContainerBase);

export { PreviewAttachmentModalContainerBase };
export default PreviewAttachmentModalContainer;
