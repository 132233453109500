import React from 'react';
import styled, { css } from 'styled-components';

import Basic from 'models/toast/content/basic';
import { Card, Close, Content, Footer } from './shared_toast';

export function BasicToast({ toast }) {
  const { message, basicType } = toast.content;

  return (
    <StyledCard $type={basicType} toast={toast}>
      {message}
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  ${p => p.$type === Basic.Type.ERROR && errorStyles}
  ${p => p.$type === Basic.Type.INFO && infoStyles}
  ${p => p.$type === Basic.Type.SUCCESS && successStyles}
  ${p => p.$type === Basic.Type.WARNING && warningStyles}
`;

const sharedStyles = css`
  color: ${p => p.theme.colors.white};
  ${Content} {
    margin-bottom: 0;
  }
  ${Close} {
    &:hover {
      color: ${p => p.theme.colors.white};
      opacity: 0.6;
    }
  }
  time {
    color: ${p => p.theme.colors.white};
  }
`;

const errorStyles = css`
  ${sharedStyles}
  background-color: ${p => p.theme.colors.red400};
  border-color: ${p => p.theme.colors.red600};

  ${p => (p.action == null ? hideFooterStyles : null)}
`;
const infoStyles = css``;
const successStyles = css`
  ${sharedStyles}
  background-color: ${p => p.theme.colors.green400};
  border-color: ${p => p.theme.colors.green600};

  ${p => (p.action == null ? hideFooterStyles : null)}
`;
const warningStyles = css`
  ${sharedStyles}
  background-color: ${p => p.theme.colors.yellow400};
  border-color: ${p => p.theme.colors.yellow600};

  ${p => (p.action == null ? hideFooterStyles : null)}
`;

const hideFooterStyles = css`
  ${Footer} {
    display: none;
  }
`;

export default BasicToast;
