import _ from 'lodash';

import AnswerSuggestions from 'models/answer_suggestions';
import createConverter from './lib/create_converter';
import { snippetDtoFieldChanges } from 'scripts/application/dto_converters/snippet_converter';

const answerSuggestionsConverter = createConverter(AnswerSuggestions);

export default {
  fromDto: dto => {
    dto.id = dto.compositionId;
    dto.version = dto.version;
    dto.suggestions = _.map(dto.suggestions, snippetDto => snippetDtoFieldChanges(snippetDto));
    return answerSuggestionsConverter.fromDto(dto);
  },
};
