import _ from 'lodash';
import React, { useMemo } from 'react';

import CompositionContentType from 'models/composition/composition_content_type';
import connect from 'components/lib/connect';
import FeatureTipTypes from 'models/feature_tip_types';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getAnswersList } from 'scripts/application/selectors/answers';

export const ANSWER_PANEL_DEFAULT_CONTEXT = {
  answersList: {
    answers: [],
    type: 'EMPTY',
  },
  audiences: undefined,
  canInsertAnswer: true,
  focusedAnswer: undefined,
  searchText: '',
  selectedAnswer: undefined,
  selectedAnswerType: undefined,
  selectedAudiences: [],
  suggestedReply: {
    content: undefined,
    isLoading: false,
  },
  hasSeenSemanticSearchTips: false,
};
const AnswerPanelContext = React.createContext(ANSWER_PANEL_DEFAULT_CONTEXT);

export function AnswerPanelContextProviderBase({
  answerPanel,
  answersList,
  audiences,
  canInsertAnswer,
  children,
  favoriteAnswersImm,
  languages,
  languagesImm,
  searchResultsImm,
  searchText,
  suggestionsImm,
  suggestedReplyContent,
  isSuggestedReplyLoading,
  hasSeenSemanticSearchTips,
}) {
  const value = useMemo(
    () => {
      const {
        searchLanguage,
        selectedAnswer,
        selectedAnswerLanguage,
        selectedAnswerType,
        searchAudiences,
      } = answerPanel;

      return {
        answersList,
        audiences,
        canInsertAnswer,
        focusedAnswer: answerPanel.history.length ? answerPanel.history[0].answer : selectedAnswer,
        hasSeenSemanticSearchTips,
        history: answerPanel.history,
        languages,
        lastOpenedAt: answerPanel.lastOpenedAt,
        searchLanguage,
        searchText,
        selectedAnswer,
        selectedAnswerLanguage,
        selectedAnswerType,
        selectedAudiences: searchAudiences,
        sortType: answerPanel.sortType,
        suggestedReply: {
          content: suggestedReplyContent,
          isLoading: isSuggestedReplyLoading,
        },
      };
    },
    // Use the immutables rather than the arrays of searchResults & suggestions since those are _never_
    // the same between renders
    [
      answerPanel,
      canInsertAnswer,
      favoriteAnswersImm,
      languagesImm,
      searchResultsImm,
      searchText,
      suggestionsImm,
      suggestedReplyContent,
      isSuggestedReplyLoading,
      hasSeenSemanticSearchTips,
    ]
  );

  return <AnswerPanelContext.Provider value={value}>{children}</AnswerPanelContext.Provider>;
}

const AnswerPanelWithoutCustomerStoresContextProvider = connect(mapStateWithoutCustomerStoresToProps)(
  AnswerPanelContextProviderBase
);
const AnswerPanelWithCustomerStoresContextProvider = connect(mapStateWithCustomerStoresToProps)(
  AnswerPanelWithoutCustomerStoresContextProvider
);
const AnswerPanelContextProvider = function({ isReadOnly, children }) {
  return isReadOnly ? (
    <AnswerPanelWithoutCustomerStoresContextProvider>{children}</AnswerPanelWithoutCustomerStoresContextProvider>
  ) : (
    <AnswerPanelWithCustomerStoresContextProvider>{children}</AnswerPanelWithCustomerStoresContextProvider>
  );
};
export { AnswerPanelContextProvider };

function mapStateWithCustomerStoresToProps({ getProvider, isFeatureEnabled }) {
  const currentLocation = getProvider('currentLocation').get();
  const suggestedReplyProvider = getProvider('suggestedReply');
  const suggestedReplyContent = suggestedReplyProvider.get();
  const isSuggestedReplyLoading = suggestedReplyProvider.isLoading();

  const conversationsProvider = getProvider('conversations');

  const compositionsProvider = getProvider('compositions');

  const composition = compositionsProvider.findBy({ id: currentLocation.currentCompositionId });
  const hasActiveConversation = !!getActiveConversationId(conversationsProvider);
  const canInsertAnswer =
    ((currentLocation.currentCompositionId && !hasActiveConversation) || hasActiveConversation) &&
    (!composition || composition.contentType() !== CompositionContentType.PHONE_CALL);

  return {
    canInsertAnswer,
    suggestedReplyContent,
    isSuggestedReplyLoading,
  };
}

function mapStateWithoutCustomerStoresToProps({ getProvider, isFeatureEnabled }) {
  const answerPanelProvider = getProvider('answerPanel');
  const search = answerPanelProvider.get().searchResult;

  const currentLocation = getProvider('currentLocation').get();
  const suggestionsImm = getProvider('answerSuggestions')
    .immutableStore.binding.sub(currentLocation.currentCompositionId)
    .get();
  const favoriteAnswersImm = getProvider('favoriteAnswers').immutableStore.binding.get();

  let languages = getProvider('languages').findAll();
  const languagesImm = getProvider('languages').immutableStore.binding.get();

  let audiences = getProvider('audiences').findAll();

  const answersList = getAnswersList({
    answerPanel: answerPanelProvider,
    answerSuggestions: getProvider('answerSuggestions'),
    currentLocation: getProvider('currentLocation'),
    favoriteAnswers: getProvider('favoriteAnswers'),
  });

  const agentPreferences = getProvider('agentPreferences').get();
  const hasSeenSemanticSearchTips = agentPreferences.hasSeenFeatureTip(FeatureTipTypes.SEMANTIC_SEARCH);

  return {
    answerPanel: answerPanelProvider.get(),
    audiences,
    answersList,
    favoriteAnswersImm,
    languages,
    languagesImm,
    searchText: _.get(search, 'query.text') || '',
    searchResultsImm: answerPanelProvider.store.binding.get('searchResult'),
    suggestionsImm,
    hasSeenSemanticSearchTips,
  };
}

export default AnswerPanelContext;
