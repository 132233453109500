import classnames from 'classnames';
import React from 'react';

export default function ArrowIcon({ className, facingRight } = { facingRight: false }) {
  let classNames = classnames('arrowIcon', className, {
    'arrowIcon-right': facingRight,
  });
  return (
    <svg className={classNames} version="1.1" viewBox="0 0 94 94">
      <g fill="none" fillRule="evenodd" id="Mobile" stroke="none" strokeWidth="1">
        <g id="Artboard">
          <circle className="arrowIcon-circle" cx="46" cy="47" id="Oval-2" r="45" />
          <polyline
            className="arrowIcon-arrow"
            id="shape"
            points="54.9963798 66.1236891 34 46.5618445 54.9963798 27"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}
