import _ from 'lodash';

import RunnerContext from './runner_context';

export default class ExtendedRunnerContext extends RunnerContext {
  constructor(contextAttrs) {
    super({ ...contextAttrs, stores: _.clone(contextAttrs.stores), providers: _.clone(contextAttrs.providers) });
    this._initialState = { stores: _.clone(contextAttrs.stores), providers: _.clone(contextAttrs.providers) };
    this._extendedProviderPaths = new ExtendedProviderPaths(contextAttrs.extensionPathsBinding);
  }

  extendStores(stores) {
    _.merge(this, { stores });
  }

  extendProviders(providers) {
    this._extendedProviderPaths.record(providers);

    let extendedProviders = {};
    _.forEach(providers, (provider, name) => {
      extendedProviders[name] = decorateWithPathBinding(provider, this._extendedProviderPaths.getPathBinding(name));
    });
    _.assign(this.providers, extendedProviders);
  }

  restore() {
    this.stores = _.clone(this._initialState.stores);
    this.providers = _.clone(this._initialState.providers);
    this._extendedProviderPaths.erase();
  }
}

/**
 * Decorates the extended provider with its path binding
 *
 * This function adds an additional binding to the array of bindings returned by a provider.
 * This additional binding, the path binding, serves as an identifier / pointer for the main binding. This path binding
 * has a permanent location and it can be observed to detect when the main binding of the provider
 * has been replaced with a different binding.
 *
 * Extended providers come and go and at different times they could point to different locations in the binding tree,
 * that is, the bindings they return will likely be different between renders. For instance, during a lifetime of
 * a React component the `profile` provider could point to different customer profiles.
 *
 * While a component like the connect wrapper would typically observe the binding it will be able to react to changes
 * to the binding like "Martha Williams" has a new phone number but it won't be able to detect a situation when the
 * `profile` provider starts to point to "Bill Bellamy" profile, that is, it will keep observing "Martha Williams"
 * because that was what `profile` was pointing to when the component was mounted.
 *
 * The component needs to be able to detect when the `profile` provider changes from "Martha Williams" to "Bill Bellamy".
 * This is where the path binding comes to help. The path binding is a binding at a certain permanent location,
 * for instance, `extensionPaths.profile`. Whenever RunnerContext is asked to extend the providers it records the path
 * of the current extended provider in that binding (@see ExtendedProviderPaths.prototype.record), for instance,
 * its value could start as something like `customers.martha-williams.profile` and the change to
 * `customers.bill-bellamy.profile`.
 *
 * This decorator appends this path binding to the provider bindings so that interested React components could observe
 * it and re-render when its value changes even though the original "Marta Williams" binding is still unchanged.
 *
 * @param provider - an extended (a.k.a. transient) provider, such as `profile` or `conversations`
 * @param pathBinding - a well-known binding, e.g. e.g. `extensionPaths.profile` that stores
 *                      the path / identifier for the extended provider
 * @returns {provider} provider with an additional pathBinding returned from its `bindings` property
 */
function decorateWithPathBinding(provider, pathBinding) {
  return Object.create(provider, {
    bindings: {
      get: () => [...provider.bindings, pathBinding],
    },
  });
}

class ExtendedProviderPaths {
  constructor(extensionPathsBinding) {
    this.extensionPathsBinding = extensionPathsBinding;
  }

  record(providers) {
    _.forEach(providers, (provider, name) => {
      let extensionPathBinding = this.getPathBinding(name);
      extensionPathBinding.set(provider.bindings.map(b => b.getPath().join('.')).join(':'));
    });
  }

  getPathBinding(name) {
    return this.extensionPathsBinding.sub(name);
  }

  erase() {
    this.extensionPathsBinding.clear();
  }
}
