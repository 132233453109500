import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const ICON_TYPE_MAP = {
  'application/pdf': 'pdf',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',
  'application/msword': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
};

export const AttachmentTypeIcon = ({ type, title }) => {
  const iconType = type && (type.startsWith('image/') ? 'image' : ICON_TYPE_MAP[type]);
  const classNames = classnames('attachments-attachmentType-icon', {
    [`attachments-attachmentType-icon-${iconType}`]: iconType,
  });
  return <div className={classNames} title={title} />;
};

AttachmentTypeIcon.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
};

const AttachmentType = ({ title, type, extension }) => {
  const iconType = type && (type.startsWith('image/') ? 'image' : ICON_TYPE_MAP[type]);
  const ext = extension || iconType || '';
  return (
    <span className="attachments-attachmentType">
      <AttachmentTypeIcon title={title} type={type} />
      <span className="attachments-attachmentType-label" title={ext.toUpperCase()}>
        {ext.toUpperCase()}
      </span>
    </span>
  );
};

AttachmentType.propTypes = {
  extension: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default AttachmentType;
