import createUpdateAction from '../lib/create_update_action';
import KbVariable from 'models/kb_variable';

export default class UpdateKbVariable extends createUpdateAction(KbVariable) {
  _getValidationErrors(attrs) {
    return KbVariable.getValidationErrors(attrs);
  }

  run({ id, defaultValue }) {
    super.run({ id, defaultValue });
  }
}
