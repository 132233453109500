import createBlankError from 'scripts/domain/factories/error/create_blank_error';
import createModel, { prop } from './lib/create_model';
import createTooLongError from 'scripts/domain/factories/error/create_too_long_error';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ServerClock from 'scripts/application/lib/server_clock';

export default createModel({
  modelName: 'ApiToken',
  properties: {
    id: prop(String).isRequired,
    createdAt: prop(String),
    lastUsedAt: prop(String),
    name: prop(String).isRequired,
    token: prop(String), // present only in response to add / create
  },

  clearToken() {
    this.token = undefined;
  },

  rename(newName) {
    this.name = newName;
  },

  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), createdAt: ServerClock.toISOString(), ...attrs });
    },

    getValidationErrors,
  },
});

function getValidationErrors({ name }) {
  let errors = [];

  if (!name || name.trim().length === 0) {
    errors.push(createBlankError('name'));
  }

  if (name && name.length > MAX_NAME_LENGTH) {
    errors.push(createTooLongError('name', MAX_NAME_LENGTH));
  }

  return errors;
}

const MAX_NAME_LENGTH = 500;
