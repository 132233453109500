import Morearty from 'morearty';
import React from 'react';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import styled from 'styled-components';

import ActionMixin from '../lib/action_mixin';
import Button, { ButtonTypes } from 'components/common/button';
import { InboxSortOrder } from 'models/location/inbox';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from '../lib/shared_bindings_mixin';
import SwitchInboxSort from 'actions/inbox/switch_inbox_sort';
import Tooltip from 'components/common/tooltip';

const InboxSortButton = createReactClass({
  mixins: [Morearty.Mixin, ActionMixin, ProvidersMixin, SharedBindingsMixin],

  UNSAFE_componentWillMount() {
    this.binding = this.getDefaultBinding();
    this.viewBinding = this.binding.meta('_view');
    this.observeBinding(this.getSharedBinding('currentLocation'));
    this.viewBinding.set('sort', this.getSharedBinding('currentLocation').sub('sortOrder'));
  },

  handleChange() {
    let sortStates = InboxSortOrder;
    let sort = this.viewBinding.get('sort').get() === sortStates.LATEST ? sortStates.OLDEST : sortStates.LATEST;
    this.executeAction(SwitchInboxSort, sort);
  },

  render() {
    let sortStates = InboxSortOrder;
    let sortDirection = this.viewBinding.get('sort').get();
    return (
      <StyledSortButton onClick={this.handleChange} type={ButtonTypes.PRIMARY}>
        <Tooltip message="Show most recently updated items first" position="left">
          <StyledChevron
            className={classnames('icon ion-chevron-up', {
              active: sortDirection === sortStates.LATEST,
            })}
          />
        </Tooltip>
        <Tooltip message="Show oldest items first" position="left">
          <StyledChevron
            className={classnames('icon ion-chevron-down', {
              active: sortDirection === sortStates.OLDEST,
            })}
          />
        </Tooltip>
      </StyledSortButton>
    );
  },
});

const StyledSortButton = styled(Button)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: block;
  font-weight: bolder;
  padding: 0;
  & .icon {
    color: ${p => p.theme.colors.black};
    display: block;
  }
  & .icon.active {
    color: ${p => p.theme.colors.green400};
  }

  &:hover,
  &:active {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
`;

export const StyledChevron = styled.div`
  border: 1px solid transparent;
  border-radius: 50%;
  height: 25px;
  line-height: 25px;
  transition: 0.15s ease;
  width: 25px;
  &:hover {
    border: 1px solid ${p => p.theme.colors.gray200};
    color: ${p => p.theme.colors.green300};
  }
`;

export default InboxSortButton;
