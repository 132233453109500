import _ from 'lodash';

import Err from 'models/err';

export default class UserPasswordGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleUpdateSuccess() {
    this.context.stores.userCredential.commitPending();
  }

  handleUpdateErrors(userId, errorsDto) {
    this.context.stores.userCredential.resetPending();
    this.context.stores.userCredential.setErrors(_.map(errorsDto.errors, Err.fromJs.bind(Err)));
  }
}
