import _ from 'lodash';
import classnames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import path from 'path';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import AttachmentType from './attachment_type';
import RemoveAttachmentButton, { RemoveAttachmentModal, StyledRemoveIcon } from './remove_attachment_button';

class Attachment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imageLoaded: false };
    _.bindAll(this, ['handleClick']);
  }

  filenameWithoutExtension() {
    let filename = this.props.attachment.fileDescriptor().filename;
    return path.basename(filename, path.extname(filename));
  }

  filenameExtension() {
    let extname = path.extname(this.props.attachment.fileDescriptor().filename);
    return extname && extname.substr(1);
  }

  handleClick(ev) {
    if (this.props.onClick) {
      ev.stopPropagation();
      this.props.onClick(ev);
    }
  }

  render() {
    if (this.props.attachment.isRedacted) {
      return (
        <Redacted className="attachments-attachment">
          <FontAwesomeIcon icon={faTrashAlt} />
          <Text>This item was deleted.</Text>
        </Redacted>
      );
    }

    return (
      <AttachmentWrapper
        attachment={this.props.attachment}
        className="attachments-attachment"
        disableRemove={this.props.disableRemove}
        onClick={this.handleClick}
      >
        {this.renderAttachmentDescription()}
        {this.renderAttachmentThumbnail()}
      </AttachmentWrapper>
    );
  }

  renderAttachmentDescription() {
    const { attachment } = this.props;
    const filename = attachment.fileDescriptor().filename;
    let classNames = classnames('attachments-attachment-description', this.props.className, {
      'attachments-attachment-clickable': this.props.onClick,
    });

    return (
      <div className={classNames}>
        {this.props.children}
        <Dotdotdot clamp={2}>
          <h4 className="attachments-attachment-label" title={filename}>
            {this.filenameWithoutExtension()}
          </h4>
        </Dotdotdot>
        <AttachmentType extension={this.filenameExtension()} type={attachment.fileDescriptor().contentType} />
      </div>
    );
  }

  renderAttachmentThumbnail() {
    if (!this.hasThumbnail()) {
      return null;
    }

    let classNames = classnames('attachments-attachment-thumbnail', this.props.className, {
      'attachments-attachment-clickable': this.props.onClick,
    });

    let imageClassNames = classnames('attachments-attachment-thumbnail-image', {
      'attachments-attachment-thumbnail-image-loaded': this.state.imageLoaded,
    });

    return (
      <div className={classNames}>
        <img
          className={imageClassNames}
          onLoad={() => {
            this.setState({ imageLoaded: true });
          }}
          src={this.props.thumbnailUrl}
        />
        {this.props.children}
      </div>
    );
  }

  hasThumbnail() {
    return this.props.attachment.isImage() && this.props.thumbnailUrl;
  }
}

Attachment.propTypes = {
  attachment: PropTypes.shape({ fileDescriptor: PropTypes.func, isImage: PropTypes.func, isRedacted: PropTypes.bool })
    .isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  disableRemove: PropTypes.bool,
  onClick: PropTypes.func,
  thumbnailUrl: PropTypes.string,
};

const Redacted = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.gray300};
  border-radius: 2px;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
`;
const Text = styled.div`
  font-size: 11px;
  margin-top: 8px;
  text-align: center;
`;

function AttachmentWrapper({ attachment, className, children, disableRemove, onClick }) {
  const [isRemoving, setIsRemoving] = useState(false);

  return (
    <React.Fragment>
      <StyledAttachment className={className} data-aid="attachment" onClick={onClick || _.noop}>
        {children}
        {!disableRemove ? (
          <RemoveAttachmentButton attachment={attachment} isRemoving={isRemoving} setIsRemoving={setIsRemoving} />
        ) : null}
      </StyledAttachment>
      {isRemoving ? <RemoveAttachmentModal attachment={attachment} setIsRemoving={setIsRemoving} /> : null}
    </React.Fragment>
  );
}

const StyledAttachment = styled.div`
  position: relative;
  &:hover ${StyledRemoveIcon} {
    opacity: 1;
    visibility: visible;
  }
`;

export default Attachment;
