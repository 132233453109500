import moment from 'moment';

import TimeSelector from 'components/lib/time_selector_dumb';

export function getDatetimeFromTimestamp(timestamp) {
  if (!timestamp) {
    return null;
  }

  const mom = moment(timestamp);

  let hour = mom.hour();
  if (hour > 12) {
    hour = hour - 12;
  }
  if (hour === 0) {
    hour = 12;
  }

  return {
    date: mom.toISOString(),
    time: {
      hour: hour.toString(),
      minute: mom.minute().toString(),
      meridiem: mom.hour() < 12 ? TimeSelector.Meridiem.AM : TimeSelector.Meridiem.PM,
    },
  };
}

export function getTimestampFromDatetime(datetime) {
  const originalDate = moment(datetime.date);
  const date = moment();

  // convert to 24 hour clock
  let hour = parseInt(datetime.time.hour);
  hour = hour === 12 ? 0 : hour;
  hour = datetime.time.meridiem === TimeSelector.Meridiem.PM ? (hour + 12).toString() : hour.toString();
  const minute = datetime.time.minute;

  date.set('year', originalDate.year());
  date.set('month', originalDate.month());
  date.set('date', originalDate.date());
  date.set('hour', hour);
  date.set('minute', minute);
  date.set('second', 0);
  date.set('ms', 0);
  return date.toISOString();
}
