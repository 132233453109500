import React, { useContext } from 'react';
import ChatFillIcon from 'components/lib/icons/chat_fill_icon';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function ChatRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.chat;
  const chatSla = sla.chat;
  return (
    <FloorboardRow
      activeItems={stats.currentActiveChats}
      activeLabel="Active Chats"
      currentWait={stats.currentWait}
      icon={<ChatFillIcon />}
      newItems={stats.currentNewChats}
      newLabel="New Chats"
      sla={chatSla}
      waitLabel="Oldest Unanswered Chat"
    />
  );
}
