import CustomerUnlink from 'models/customer_unlink';

export default class UnlinkExternalCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, totalLinkCount }) {
    let customerUnlink = CustomerUnlink.create({ customerId });

    this.context.stores.customerUnlink.setPending(customerUnlink);
    this.context.gateways.customerExtension.unlink(customerId);

    this.context.analytics.track('Customer Profile Unlinked', {
      customerId,
      numLinks: totalLinkCount,
    });
  }
}
