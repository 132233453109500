import createModel, { prop } from './lib/create_model';

const KeywordGroup = createModel({
  modelName: 'KeywordGroup',
  properties: {
    id: prop(String).isRequired,
    label: prop(String),
    languages: [String],
    phrases: prop([String]).isRequired,
    updatedAt: prop(String).isRequired,
    updatedBy: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default KeywordGroup;
