export default class UseAISuggestedReply {
  constructor(context) {
    this.context = context;
  }

  run({ customerId }) {
    const { aiSuggestedReply } = this.context.stores.customers.storesFor(customerId);
    const currentReply = aiSuggestedReply.get();
    if (!currentReply) {
      return;
    }

    currentReply.setUsedAt();
    aiSuggestedReply.set(currentReply);
  }
}
