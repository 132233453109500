import PropTypes from 'prop-types';
import React from 'react';
import connect from 'components/lib/connect';

import ConversationItem from 'models/conversation_item';
import VoiceCampaignOutreachResult, { OutreachResult } from 'models/voice_campaign_outreach_result';

import Metadata from './metadata';

export function CampaignOutreachResultMetadata({ item, customerName }) {
  const iconClass = getIconClass(item);
  if (item && item.content && item.content.result === OutreachResult.FAILED) {
    customerName = '';
  }

  return (
    <Metadata
      iconClass={iconClass}
      isInbound={false}
      subject={customerName}
      text={getAuditText(item)}
      timestamp={item.timestamp}
    />
  );
}

CampaignOutreachResultMetadata.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  customerName: PropTypes.string,
};

function mapStateToProps({ getProvider }, props) {
  let profile = getProvider('profile').get();
  let customerName = profile && profile.name ? profile.name : 'Customer';
  return {
    ...props,
    customerName,
  };
}

function getAuditText(item) {
  let result = item && item.content && item.content.result;
  switch (result) {
    case OutreachResult.ANSWERED:
      return 'answered';
    case OutreachResult.NO_ANSWER:
      return 'did not answer';
    case OutreachResult.FAILED:
      return 'Call could not be completed';
    default:
      return null;
  }
}

function getIconClass(item) {
  if (item.content instanceof VoiceCampaignOutreachResult) {
    return 'icon-phone-filled';
  } else {
    throw new Error('invalid content type');
  }
}

const CampaignOutreachResultMetadataContainer = connect(mapStateToProps)(CampaignOutreachResultMetadata);
export default CampaignOutreachResultMetadataContainer;
