import { newTracker, trackSelfDescribingEvent, setUserId } from '@snowplow/browser-tracker';
import { snowplowTypes } from 'scripts/lib/analytics/domain/snowplow';
import ErrorReporter from 'scripts/infrastructure/error_reporter';

const UI_EVENT_TO_SNOWPLOW_MAP = {
  'Component Clicked': snowplowTypes.EVENT_COMPONENT_CLICKED,
};

export default class SnowplowAdapter {
  constructor(snowplowEndpoint, cluster) {
    // this is to force http instead of https for local development
    this.snowplowEndpoint = cluster === 'development' ? `http://${snowplowEndpoint}` : snowplowEndpoint;
    this.gladlyStage = mapClusterToStage(cluster);
    this.gladlySite = mapClusterToSite(cluster);
  }

  _newTracker(trackerId, endpoint, configuration) {
    newTracker(trackerId, endpoint, configuration);
  }

  init() {
    this._newTracker('cf', this.snowplowEndpoint, {
      appId: 'agentDesktop',
    });
  }

  setUser(user) {
    setUserId(user.id);
    this.orgId = user.orgId;
  }

  trackUIEvent(event, meta) {
    const schema = UI_EVENT_TO_SNOWPLOW_MAP[event];
    if (!schema) {
      ErrorReporter.reportError('Snowplow UI event type not supported', {
        extra: {
          event,
        },
      });
      return;
    }
    this.track(UI_EVENT_TO_SNOWPLOW_MAP[event], meta);
  }

  track(eventSchema, data = {}, contexts = []) {
    if (!this.orgId) {
      ErrorReporter.reportError('Snowplow event was ignored because orgId was not set', {
        extra: {
          eventSchema,
        },
      });
      return;
    }

    this._trackSelfDescribingEvent({
      event: {
        schema: eventSchema,
        data,
      },
      context: [
        {
          schema: snowplowTypes.CONTEXT_GLADLY,
          data: {
            orgId: this.orgId,
            stage: this.gladlyStage,
            site: this.gladlySite,
          },
        },
        ...contexts,
      ],
    });
  }

  _trackSelfDescribingEvent(event) {
    trackSelfDescribingEvent(event);
  }
}

function mapClusterToStage(cluster) {
  return {
    production1: 'production',
    gladly: 'staging',
    staging: 'staging',
    master: 'staging',
    development: 'development',
  }[cluster];
}

function mapClusterToSite(cluster) {
  return {
    production1: 'us-1.gladly.com',
    gladly: 'us-uat.gladly.qa',
    staging: 'us-staging.gladly.qa',
    master: 'us-master.gladly.qa',
    development: 'localhost',
  }[cluster];
}
