import { Factory } from 'rosie';

Factory.define('MessageContentTypeFile').attr('type', 'FILE');

Factory.define('MessageContentTypeFileWithDefaults')
  .extend('MessageContentTypeFile')
  .attr('files', () => Factory.attributes('MessageFileAttachment'))
  .attr('text', 'example file text')
  .attr('bodyHtml', '<div><b>example</b> file <i>text</i></div>')
  .attr('buttons', []);
