import _ from 'lodash';
import moment from 'moment';

import ConversationItemType from 'models/conversation_item_type';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import InteractionType from 'models/interaction_type';
import RoutingQueueItem, { AgentParticipantStatus, RoutingStatus } from 'models/routing_queue_item';

export function getOfferedSessionForAgent(activeSessions, agentId) {
  let offeredSessions = activeSessions.findAll({
    filter: s => RoutingQueueItem.isOffered(s.queueItem),
  });

  return _.find(offeredSessions, s => s.queueItem.getOfferingAgentId() === agentId);
}

export function getActiveChatSessionByCustomer(activeSessions, customerId) {
  let session = activeSessions.findBy({ customer: { id: customerId } });

  if (
    session &&
    session.queueItem.getSessionByType(InteractionType.CHAT) &&
    session.queueItem.status === RoutingStatus.ACTIVE
  ) {
    return session;
  }

  return null;
}

export function getActiveSessionOfTypeByCustomerForAgent(activeSessions, customerId, agentId, sessionType) {
  let session = activeSessions.findBy({ customer: { id: customerId } });

  if (
    session &&
    session.queueItem.getSessionByType(sessionType) &&
    session.queueItem.getAgentParticipantStatus(agentId) === AgentParticipantStatus.ACTIVE
  ) {
    return session;
  }

  return null;
}

export function getActiveCustomChanelSessionIdFromHistory({ conversationHistory, conversations, customChannelId }) {
  const currentConversationId = getActiveConversationId(conversations);
  let lastMessage = conversationHistory.findBy({
    filter: item =>
      item.conversationId === currentConversationId &&
      item.content.type === ConversationItemType.CUSTOM_CHANNEL_MESSAGE &&
      item.content.customChannelId === customChannelId &&
      item.initiator.type === 'CUSTOMER',
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });
  return lastMessage?.content?.sessionId || null;
}

export function getActiveChatSessionIdFromHistory({ conversationHistory, conversations }) {
  const currentConversationId = getActiveConversationId(conversations);
  let lastChatMessage = conversationHistory.findBy({
    filter: item =>
      item.conversationId === currentConversationId &&
      item.content.type === ConversationItemType.CHAT_MESSAGE &&
      item.initiator.type === 'CUSTOMER',
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });

  let sessionEndedMessage = conversationHistory.findBy({
    filter: item =>
      (item.content.type === ConversationItemType.CHAT_ENDED ||
        item.content.type === ConversationItemType.SESSION_ENDED) &&
      lastChatMessage &&
      item.content.sessionId === lastChatMessage.content.sessionId,
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });

  if (
    !sessionEndedMessage ||
    moment(_.get(lastChatMessage, 'timestamp')).isAfter(_.get(sessionEndedMessage, 'timestamp'))
  ) {
    return lastChatMessage && lastChatMessage.content.sessionId;
  }
  return null;
}
