export default class CloseReport {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.gateways.report.cancel();

    this.context.stores.embedTokensReport.remove();
    this.context.stores.embeddedReport.remove();
    this.context.stores.report.remove();
  }
}
