import _ from 'lodash';
import { AttributePresentation } from 'models/configuration/attribute_def';

export const getAttributesToDisplay = (attributeList, externalData) => {
  if (_.isEmpty(attributeList) || _.isEmpty(externalData)) {
    return [];
  }

  return _.filter(attributeList, attrDef => {
    const presentation = attrDef.presentation || AttributePresentation.VISIBLE;
    const value = _.get(externalData, attrDef.attr);

    // Only allow attributes with non-empty values or zeroes (which we treat as a valid numeric value)
    return (!!value || value === 0) && presentation === AttributePresentation.VISIBLE;
  });
};
