import React from 'react';
import PropTypes from 'prop-types';

import DateRangeFilter from 'components/lib/date_range_filter';

export const ReportDateRange = ({ className, startDate, endDate, onApply }) => {
  let quickPicks = {
    'Last 7 days': now => [now.clone().subtract(7, 'days'), now],
    'Last 14 days': now => [now.clone().subtract(14, 'days'), now],
    'Last 28 days': now => [now.clone().subtract(28, 'days'), now],
    'This week': now => [now.clone().startOf('week'), now],
    'Last week': now => [now.subtract(1, 'week').startOf('week'), now.clone().endOf('week')],
    'This month': now => [now.clone().startOf('month'), now],
    'Last month': now => [now.subtract(1, 'month').startOf('month'), now.clone().endOf('month')],
  };

  return (
    <DateRangeFilter
      className={className}
      endDate={endDate}
      isClearable={false}
      onApply={onApply}
      position="bottom"
      quickPicks={quickPicks}
      startDate={startDate}
      targetPosition="start"
    />
  );
};

ReportDateRange.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
};

export default ReportDateRange;
