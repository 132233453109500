import connect from 'components/lib/connect';
import SnippetRevisions from './snippet_revisions';

const mapStateToProps = (context, props) => {
  let provider = context.getProvider('agents');

  let revisionsWithName = props.snippetRevisions.map(revision => {
    let agent = provider.findBy({ id: revision.agentId });
    let agentName = agent ? agent.name : 'Unknown';

    return {
      agentName,
      updatedAt: revision.updatedAt,
    };
  });

  return {
    snippetRevisions: revisionsWithName,
  };
};

export default connect(mapStateToProps)(SnippetRevisions);
