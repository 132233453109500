export default class PollingUpdater {
  constructor(props = [], timeout = 5000) {
    this._subscriptions = {};
    props.forEach(prop => {
      this._subscriptions[prop] = { value: null, timeout: null };
    });
    this.timeout = timeout;
  }

  poll(prop, callback) {
    if (this._subscriptions[prop] && this._subscriptions[prop].value) {
      this.clear(prop);
      this._subscriptions[prop].timeout = setTimeout(() => {
        return callback(...this._subscriptions[prop].value);
      }, this.timeout);
    }
  }

  clear(prop) {
    clearTimeout(this._subscriptions[prop].timeout);
  }

  set(prop, value) {
    this._subscriptions[prop].value = value instanceof Array ? value : [value];
  }

  clearAndSet(prop, value) {
    this.clear(prop);
    this.set(prop, value);
  }

  unsubscribe(prop) {
    if (this._subscriptions[prop].timeout) {
      clearTimeout(this._subscriptions[prop].timeout);
      this._subscriptions[prop].timeout = null;
    }
    this._subscriptions[prop].value = null;
  }
}
