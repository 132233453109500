import _ from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import ReactDOM from 'react-dom';

import Conversation from 'models/conversation';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import { NumberCard } from 'components/liveboards/cards/metric_card';
import Search from 'models/location/search';
import TrendLineChart from './trend_line_chart';

import T from 'prop-types';

class TrendLineCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { svgWidth: null };
    this.calculateSvgWidth = () => {
      var node = ReactDOM.findDOMNode(this);
      var rect = node.getBoundingClientRect();
      var width = (rect.right - rect.left) * 0.6; // subjective "good" chart size that also doesn't screw up card size
      this.setState({ svgWidth: width });
    };

    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.calculateSvgWidth);
    this.calculateSvgWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateSvgWidth);
  }

  render() {
    return (
      <div className="trendLineCard">
        <TrendLineChart data={this.props.data} svgWidth={this.state.svgWidth} />
        {this.renderLegend()}
      </div>
    );
  }

  renderLegend() {
    const data = this.props.data.toJS();
    const gte = moment
      .tz(moment(), this.props.timezone)
      .startOf('day')
      .toISOString();
    const lte = moment
      .tz(moment(), this.props.timezone)
      .endOf('day')
      .toISOString();

    const legendData = [
      {
        label: 'Created',
        value: _.sumBy(data, 'newConversations'),
        className: 'trendLineCard-legend-new',
        link: getLocationUrl(Search.createFiltered({ inboxIds: this.props.routingGroupIds, createdAt: { gte, lte } })),
      },
      {
        label: 'Closed',
        value: _.sumBy(data, 'closedConversations'),
        className: 'trendLineCard-legend-closed',
        link: getLocationUrl(
          Search.createFiltered({
            inboxIds: this.props.routingGroupIds,
            statuses: [Conversation.InboxStatus.CLOSED],
            updatedAt: { gte, lte },
          })
        ),
      },
    ];

    return (
      <div className="trendLineCard-legend">
        {legendData.map(({ label, value, className, tooltip, link }) => (
          <NumberCard
            className="donutCard-numberCard"
            key={`trendlinecard-number-${label}`}
            label={label}
            labelColor={className}
            link={(this.props.includeLinks && link) || undefined}
            tooltip={tooltip}
            tooltipPosition="top"
            value={value}
          />
        ))}
      </div>
    );
  }
}

TrendLineCard.propTypes = {
  data: ImmutablePropTypes.listOf(
    T.shape({
      timestamp: T.string,
      newConversations: T.number,
      closedConversations: T.number,
    })
  ).isRequired,
  includeLinks: T.bool,
  routingGroupIds: T.arrayOf(T.string),
  timezone: T.string,
};

export default TrendLineCard;
