import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props} className="conversationMessageFilledIcon">
    <path
      clipRule="evenodd"
      d="M15 8a7 7 0 0 1-10.928 5.795l-2.244.56a.5.5 0 0 1-.603-.616l.643-2.36A7 7 0 1 1 15 8zm-4.292 3.336a.422.422 0 0 1-.245.14c-.431.076-1.704.074-3.702-1.629l-.615-.613c-1.707-1.994-1.697-3.27-1.62-3.7a.422.422 0 0 1 .14-.244l.798-.69a.41.41 0 0 1 .57.032l.957 1.03a.433.433 0 0 1 .004.583l-.497.55a.436.436 0 0 0-.079.454c.1.249.442.737.899 1.225l.199.215c.489.457.978.797 1.228.897a.438.438 0 0 0 .455-.079l.55-.496a.436.436 0 0 1 .586.004l1.031.955a.407.407 0 0 1 .032.57l-.691.796z"
      fillRule="evenodd"
    />
  </Icon>
);
