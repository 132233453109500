import _ from 'lodash';
import path from 'path';
import createModel, { prop } from './lib/create_model';
import createEnum from 'scripts/lib/create_enum';
import Err from './err';
import FileDescriptor from './file_descriptor';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const Upload = createModel({
  modelName: 'Upload',

  properties: {
    id: prop(String).isRequired,
    descriptor: FileDescriptor,
    error: prop(Object),
    file: File,
    isInline: Boolean,
    progress: prop(Number),
    status: String,
  },

  fileDescriptor() {
    if (!this.descriptor && this.file) {
      this.descriptor = FileDescriptor.createFromFile(this.file);
    }

    return this.descriptor;
  },

  isImage() {
    return this.fileDescriptor().contentType.startsWith('image/');
  },

  isPdf() {
    return this.fileDescriptor().contentType === 'application/pdf';
  },

  start() {
    return this._createNew({
      progress: 0,
      status: Upload.Status.STARTED,
    });
  },

  fail(error) {
    return this._createNew({
      error,
      status: Upload.Status.FAILED,
    });
  },

  complete() {
    return this._createNew({
      file: undefined,
      progress: 100,
      status: Upload.Status.COMPLETED,
    });
  },

  setProgress(progress) {
    return this._createNew({
      progress,
    });
  },

  update(upload) {
    if (upload.id === this.id) {
      _.forEach(upload, (val, key) => {
        this[key] = val;
      });
    }
  },

  _createNew(attrs) {
    return new Upload(_.assign({}, this, attrs));
  },

  statics: {
    create(attrs = {}) {
      return new this(
        _.assign(
          {},
          {
            id: IdGenerator.newId(),
            descriptor: attrs.file && FileDescriptor.createFromFile(attrs.file),
            status: Upload.Status.NEW,
          },
          attrs
        )
      );
    },

    getValidationErrors({ file }) {
      let errors = [];

      if (file && _.includes(this.BlockedExtensions, path.extname(file.name).toUpperCase())) {
        errors.push(
          new Err({
            attr: 'file',
            code: Err.Code.INVALID,
            detail: `File ${file.name} isn't allowed for security reasons`,
          })
        );
      }

      return errors;
    },

    Status: createEnum('NEW', 'STARTED', 'COMPLETED', 'FAILED'),

    BlockedExtensions: (
      '.ADE, .ADP, .BAT, .CHM, .CMD, .COM, .CPL, .EXE, .HTA, .INS, .ISP, .JAR, .JSE, .LIB, .LNK, ' +
      '.MDE, .MSC, .MSP, .MST, .PIF, .SCR, .SCT, .SHB, .SYS, .VB, .VBE, .VBS, .VXD, .WSC, .WSF, .WSH'
    ).split(', '),
  },
});

export default Upload;
