import _ from 'lodash';

import Communicator from 'models/communicator';
import Conversation from 'models/conversation';
import ConversationItemType from 'models/conversation_item_type';
import CreateComposition from './lib/create_composition';
import { createQuotedEmailTextPlain, createQuotedEmailTextHtml } from './lib/create_quoted_email_text';
import EmailCompositionContent from 'models/composition/email_composition_content';
import Endpoint from 'models/endpoint';
import getNormalizedEmailAddress from 'scripts/lib/get_normalized_email_address';

class CreateEmailComposition extends CreateComposition {
  constructor(context) {
    super(context, EmailCompositionContent);
  }

  _createContent({ conversationId, toAddress }) {
    let conversation = this.customerStores.conversations.find(conversationId);
    let profile = this.customerStores.profile.get();
    let froms = this._getPotentialFroms(conversation);
    let latestEmailItem = this._getLatestManualItemOfType({ type: ConversationItemType.EMAIL, conversationId });

    let from = froms.default || froms.fallback;
    if (latestEmailItem) {
      from = this._getReplyFrom(latestEmailItem, froms);
    }

    if (!latestEmailItem || conversation.status === Conversation.Status.CLOSED) {
      return new EmailCompositionContent({
        from,
        to: toAddress || profile.getDefaultEmail(),
      });
    }

    let recipients = this._getReplyRecipients(latestEmailItem, froms, toAddress);
    return new EmailCompositionContent({
      from,
      to: recipients.to,
      cc: recipients.cc,
      bcc: recipients.bcc,
      subjectHtml: `<div>${this._getReplySubject(latestEmailItem)}</div>`,
      headers: { 'in-reply-to': latestEmailItem.content.getHeader('message-id') },
      inlineAttachments: latestEmailItem.content.inlineAttachments(),
      quotedPlain: createQuotedEmailTextPlain(latestEmailItem),
      quotedHtml: createQuotedEmailTextHtml(latestEmailItem),
    });
  }

  _canCreateComposition() {
    return canCreateEmailComposition(this.context);
  }

  _getPotentialFroms(conversation) {
    let channelConfiguration = this.context.stores.channelConfiguration.get();

    let all = channelConfiguration ? channelConfiguration.fromAddresses() : [];
    let emailEndpoints = channelConfiguration
      ? channelConfiguration.endpoints.filter(e => e.type === Endpoint.Type.EMAIL)
      : [];
    let routingGroupAddresses = _.chain(this.context.stores.communicationQueues.findAll())
      .filter(commQueue => commQueue.routingGroupId === conversation.assignee.routingGroupId)
      .map(commQueue => _.find(emailEndpoints, endpoint => endpoint.id === commQueue.endpointId))
      .compact()
      .map(endpoint => endpoint.address)
      .value();

    const currentInbox = this.context.stores.routingGroups.findBy({ id: conversation.assignee.routingGroupId });
    const defaultEndpointId = currentInbox && currentInbox.defaultEndpoints[Endpoint.Type.EMAIL];
    const defaultEndpoint =
      channelConfiguration && _.find(channelConfiguration.endpoints, e => e.id === defaultEndpointId);
    const defaultEmailAddress = defaultEndpoint && defaultEndpoint.address;

    return {
      default: defaultEmailAddress,
      preferred: routingGroupAddresses,
      all,
    };
  }

  _getReplyFrom(emailItem, froms) {
    if (emailItem.initiator.type === Communicator.AGENT) {
      return emailItem.content.from;
    }

    let to = _.map(emailItem.content.to, addr => getNormalizedEmailAddress(addr));
    let cc = _.map(emailItem.content.cc, addr => getNormalizedEmailAddress(addr));

    // If we can't get the from address from the routing group, check the to / cc addresses from the customer email instead.
    return (
      froms.default ||
      findAddressFrom(froms.preferred, to) ||
      findAddressFrom(froms.preferred, cc) ||
      froms.preferred[0] ||
      findAddressFrom(froms.all, to) ||
      findAddressFrom(froms.all, cc)
    );
  }

  _getReplyRecipients(emailItem, froms, toAddress) {
    if (toAddress) {
      return { to: toAddress };
    }

    if (emailItem.initiator.type === Communicator.AGENT) {
      return {
        to: emailItem.content.to ? _.map(emailItem.content.to, addr => getNormalizedEmailAddress(addr)).join(', ') : '',
        cc: emailItem.content.cc ? _.map(emailItem.content.cc, addr => getNormalizedEmailAddress(addr)).join(', ') : '',
        bcc: emailItem.content.bcc
          ? _.map(emailItem.content.bcc, addr => getNormalizedEmailAddress(addr)).join(', ')
          : '', // IM IN DANGERRR
      };
    }

    let fromRecipient = emailItem.content.from && [getNormalizedEmailAddress(emailItem.content.from)];
    let toRecipients = emailItem.content.to && _.map(emailItem.content.to, addr => getNormalizedEmailAddress(addr));
    let recipientToArray = _.union(fromRecipient, toRecipients);
    recipientToArray = _.filter(recipientToArray, address => !_.includes(froms.all, address));

    let to = (recipientToArray && recipientToArray.join(', ')) || '';
    let cc = emailItem.content.cc
      ? _(emailItem.content.cc)
          .map(addr => getNormalizedEmailAddress(addr))
          .filter(address => !_.includes(froms.all, address))
          .join(', ')
      : '';
    let bcc = emailItem.content.bcc
      ? _(emailItem.content.bcc)
          .map(addr => getNormalizedEmailAddress(addr))
          .filter(address => !_.includes(froms.all, address))
          .join(', ')
      : '';

    return { to, cc, bcc };
  }

  _getReplySubject(emailItem) {
    let subject = emailItem.content.subject;
    let regex = new RegExp(`^${RE_PREFIX}`, 'i');
    if (!subject.match(regex)) {
      subject = RE_PREFIX + subject;
    }
    return subject;
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }
}

function findAddressFrom(addresses, sources) {
  return _.intersection(addresses, sources)[0];
}

export function canCreateEmailComposition(context) {
  const hasExternalAgentActions = context.stores.appFeatures.get().isEnabled('externalAgentActions');
  const channelConfiguration = context.stores.channelConfiguration.get();
  return channelConfiguration && channelConfiguration.fromAddresses().length > 0 && hasExternalAgentActions;
}

const RE_PREFIX = 'Re: ';

export default CreateEmailComposition;
