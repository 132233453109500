import _ from 'lodash';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

import { Item } from 'components/text_editor_new/components/controls/controls_new';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import { usePopoverMenu } from 'components/common/menu';

import {
  IconWrapper,
  ItemsContainer,
  ItemWrapper,
  StyledContainer,
  StyledPopoverMenu,
  StyledPopoverMenuItem,
  WidthContainer,
} from 'components/text_editor_new/components/action_bar/action_bar_styles';

/*
    Phases:
    1. Initial render: Unconditionally render all items.
    2. componentDidMount: Trigger a "measuring" render.
    3. Measuring render: Unconditionally render all items, as well as the menu button.
    4. Measure DOM elements: After the measuring render, determine which items can fit
       without overflowing and save these as `visibleItemIndices`.
    5. Final render: Render visible items and shift the rest into the overflow menu.

    ** When the window is resized or the items themselves change, trigger step 3.
*/

export default function ActionBar({ children, ...props }) {
  const [visibleItemIndices, setVisibleItemIndices] = useState([]);
  const [measuringRender, setMeasuringRender] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { targetRef, isOpen, onClose, onToggle, setTargetRef } = usePopoverMenu();

  const widthContainerRef = useRef(null);
  const elementsRef = useRef(null);

  useEffect(() => {
    setIsMounted(true);
    setMeasuringRender(true);
  }, []);

  // Measure DOM elements
  useLayoutEffect(() => {
    if (measuringRender) {
      const itemElements = Array.from(elementsRef.current.children);

      let stopWidth = 0;
      const visible = [];

      itemElements.forEach((element, index) => {
        let overflowButtonWidth = 0;
        if (targetRef?.offsetWidth && index < itemElements.length - 1) {
          overflowButtonWidth = targetRef.offsetWidth;
        }

        stopWidth += element.offsetWidth + 4;
        if (widthContainerRef.current.clientWidth - 4 - overflowButtonWidth >= stopWidth) {
          visible.push(index);
        }
      });
      setVisibleItemIndices(visible);
      setMeasuringRender(false);
    }
  }, [measuringRender, targetRef]);

  // Trigger a measuringRender when the composition width changes
  useResizeObserver(widthContainerRef, () => {
    setMeasuringRender(true);
  });

  // Trigger a measuringRender when the items (children) prop changes
  useEffect(() => {
    setMeasuringRender(true);
  }, [children]);

  // Wrap each child in a <span> so we can measure its width directly
  const allItems = React.Children.map(children, (item, index) => {
    return item ? (
      <ItemWrapper>
        {React.cloneElement(item, {
          key: index,
        })}
      </ItemWrapper>
    ) : null;
  });

  // Collect the visible and overflow children for rendering
  let visibleItems = [];
  const overflowItems = [];
  if (!isMounted || measuringRender) {
    visibleItems = allItems;
  } else {
    allItems.forEach((item, index) => {
      if (visibleItemIndices.includes(index)) {
        visibleItems.push(item);
      } else {
        overflowItems.push(item);
      }
    });
  }

  return (
    <WidthContainer className={props.className} ref={widthContainerRef}>
      <StyledContainer>
        {visibleItems.length > 0 ? <ItemsContainer ref={elementsRef}>{visibleItems}</ItemsContainer> : null}
        {measuringRender || overflowItems.length > 0 ? renderOverflow() : null}
      </StyledContainer>
    </WidthContainer>
  );

  function renderOverflow() {
    return (
      <span>
        <OutsideClickHandler onClickOutside={onClose}>
          <Item
            data-aid="overflowMenuButton"
            isActive={isOpen}
            onClick={onToggle}
            onMouseDown={evt => evt.preventDefault()}
            ref={setTargetRef}
          >
            <IconWrapper>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </IconWrapper>
          </Item>
          <StyledPopoverMenu
            boundByWindow
            isOpen={isOpen}
            margin={4}
            onClickOutside={null}
            onClose={onClose}
            position="top"
            targetPosition="center"
            targetRef={targetRef}
          >
            <StyledPopoverMenuItem onClose={_.noop}>{overflowItems}</StyledPopoverMenuItem>
          </StyledPopoverMenu>
        </OutsideClickHandler>
      </span>
    );
  }
}
