import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

import {
  mergeClosedConversations,
  mergeOpenConversation,
  mergeProfile,
  mergeConversationHistory,
} from '../fake_backend/customer_merge_helpers';

export default class CustomerProfileService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  publish(topic, payload) {
    this._pubsub.publish(topic, (payload && { payload }) || {});
  }

  find(attrs, callback, path, { orgId, customerId }) {
    let customer = this._getCustomer(orgId, customerId);

    if (!customer || !customer.profile) {
      callback({ status: 404, statusText: statusText(404) });
      return;
    }

    callback(null, { status: 200, statusText: statusText(200), response: customer.profile });
  }

  findTransactions(attrs, callback, path, { orgId, customerId }) {
    let customer = this._getCustomer(orgId, customerId);

    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: (customer && { id: customerId, transactions: customer.transactions }) || {
        id: customerId,
        transactions: [],
      },
    });
  }

  add(profile, callback, path, { orgId }) {
    let attrs = _.cloneDeep(profile);
    let { id } = attrs;

    if (this._getCustomer(orgId, id)) {
      let errorResponse = {
        status: 400,
        statusText: statusText(400),
        data: {
          errors: [{ attr: 'id', code: 'taken', detail: 'id is taken' }],
        },
      };
      callback({ response: errorResponse });
      return;
    }
    let customer = { id, profile: attrs };
    this.getDatabase(orgId).customers.push(customer);
    callback(null, { status: 200, statusText: statusText(200), response: customer });
  }

  update(profile, callback, path, { orgId, customerId }) {
    let customer = this._getCustomer(orgId, customerId);

    if (profile.emails && profile.emails.length) {
      let customers = this.getDatabase(orgId).customers;

      let conflictCustomerId = '';
      let conflictIndex = _.findIndex(profile.emails, email =>
        _.find(customers, c => {
          const idNotSame = c.id !== customerId;
          const hasSameEmail = _.find(
            c.profile.emails,
            profileEmail => profileEmail.original.toLowerCase() === email.original.toLowerCase()
          );
          if (idNotSame && hasSameEmail) {
            conflictCustomerId = c.id;
            return true;
          }
          return false;
        })
      );

      if (conflictIndex !== -1) {
        let errorResponse = {
          status: 400,
          statusText: statusText(400),
          data: {
            errors: [
              {
                code: 'taken',
                detail: 'email is taken',
                attr: `emails.${conflictIndex}.original`,
                meta: { customerId: conflictCustomerId },
              },
            ],
          },
        };
        callback({ response: errorResponse });
        return;
      }
    }

    // when merging arrays, do not recursively merge by index. i.e.
    // _.merge([1, 2], [3]) => [3, 2]
    // _.merge([1, 2], [3], replaceArrays) => [3]
    function replaceArrays(objValue, srcValue) {
      if (_.isArray(objValue)) {
        return srcValue;
      }
    }
    _.mergeWith(customer.profile, profile, replaceArrays);

    callback(null, { status: 204, statusText: statusText(204) });
  }

  mergeProfiles(payload, callback, path, { orgId, customerId }) {
    let customer = this._getCustomer(orgId, payload.destCustomerId);
    let sourceCustomer = this._getCustomer(orgId, payload.sourceCustomerId);
    // defaulting to clearing the UNVERIFIED status here. This wont work for fakebackend testing of the future merge functionality
    // but we dont have that yet..
    sourceCustomer.profile.status = undefined;

    let closedConversations = mergeClosedConversations(customer, sourceCustomer);
    _.each(closedConversations, conversation => {
      this.publish(
        `v1/orgs/${orgId}/customer-history/${sourceCustomer.id}/conversations/${conversation.id}/event/delete`
      );
    });

    let conversationIds =
      this._getOpenConversation(sourceCustomer) && this._getOpenConversation(customer)
        ? {
            sourceConversationId: this._getOpenConversation(sourceCustomer).id,
            destConversationId: this._getOpenConversation(customer).id,
          }
        : {};

    let openConversation = mergeOpenConversation(customer, sourceCustomer);
    if (openConversation) {
      this.publish(
        `v1/orgs/${orgId}/customer-history/${sourceCustomer.id}/conversations/${openConversation.id}/event/delete`
      );
    }

    mergeConversationHistory(customer, sourceCustomer);
    _.each(customer.conversationHistory, item => {
      this.publish(`v1/orgs/${orgId}/customer-history/${customer.id}/conversation-items/${item.id}`, item);
    });

    _.each(customer.conversations, conversation => {
      this.publish(`v1/orgs/${orgId}/customer-history/${customer.id}/conversations/${conversation.id}`, conversation);
    });

    mergeProfile(customer, sourceCustomer);
    this.getDatabase(orgId).customers = _.without(this.getDatabase(orgId).customers, sourceCustomer);
    this.publish(`v1/orgs/${orgId}/customer-profiles/${customer.id}`, customer.profile);
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: _.merge(
        {
          sourceCustomerId: sourceCustomer.id,
          destCustomerId: customer.id,
        },
        conversationIds
      ),
    });

    this.publish(`v1/orgs/${orgId}/customer-profiles/${sourceCustomer.id}/event/delete`);
  }

  _getCustomer(orgId, customerId) {
    return _.find(this.getDatabase(orgId).customers, { id: customerId });
  }

  _getOpenConversation(customer) {
    return _.find(customer.conversations, t => {
      return t.status === 'OPEN';
    });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customer-profiles/:customerId': {
          GET: this.find,
          PATCH: this.update,
        },
        '/api/v1/orgs/:orgId/customer-profiles': {
          POST: this.add,
        },
        '/api/v1/orgs/:orgId/customer-profiles/:customerId/merges': {
          POST: this.mergeProfiles,
        },
        '/api/v1/orgs/:orgId/customers/:customerId/transactions': {
          GET: this.findTransactions,
        },
      },
      this
    );
  }
}
