import React from 'react';

export default function TaskAvatar(props) {
  return (
    <svg fill="none" height="55" viewBox="0 0 55 55" width="55" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="27.5" cy="27.5" fill="#0F203C" r="27.5" />
      <path
        clipRule="evenodd"
        d="M27.875 17C27.0178 17 26.2885 17.4109 26.0183 17.9844H24.4375C24.1614 17.9844 23.9375 18.2082 23.9375 18.4844V20.4375C23.9375 20.7136 24.1614 20.9375 24.4375 20.9375H31.3125C31.5886 20.9375 31.8125 20.7136 31.8125 20.4375V18.4844C31.8125 18.2082 31.5886 17.9844 31.3125 17.9844H29.7317C29.4615 17.4109 28.7322 17 27.875 17ZM22.625 19.6251H20.5C20.2239 19.6251 20 19.849 20 20.1251V37.5001C20 37.7762 20.2239 38.0001 20.5 38.0001H33.6023H35.25C35.5261 38.0001 35.75 37.7762 35.75 37.5001V20.1251C35.75 19.849 35.5261 19.6251 35.25 19.6251H33.125V21.75C33.125 22.0261 32.9011 22.25 32.625 22.25H23.125C22.8489 22.25 22.625 22.0261 22.625 21.75V19.6251ZM32.5336 25.642C32.7234 25.8426 32.7146 26.1591 32.514 26.3488L26.5378 32.0027C26.3394 32.1904 26.0271 32.1842 25.8364 31.9888L23.2217 29.3106C23.0288 29.113 23.0326 28.7965 23.2302 28.6036C23.4278 28.4107 23.7444 28.4145 23.9373 28.6121L26.2081 30.938L31.8268 25.6224C32.0274 25.4326 32.3438 25.4414 32.5336 25.642Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}
