import CustomerView from 'models/location/customer_view';
import ShowCreditCard from 'models/modal/show_credit_card';

export default class ShowCreditCardModal {
  constructor(context) {
    this.context = context;
  }

  run({ itemId }) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error('cannot show credit card confirmation modal when not on customer page');
    }
    const customerStores = this.context.stores.customers.storesFor(currentLocation.customerId);
    const conversationHistoryStore = customerStores.conversationHistory;
    const creditCardRequestItem = conversationHistoryStore.find(itemId);
    this.context.stores.modal.set(new ShowCreditCard({ item: creditCardRequestItem }));
  }
}
