import _ from 'lodash';
import createModel, { prop } from 'models/lib/create_model';
import createEnum from 'scripts/lib/create_enum';

export const OutreachResult = createEnum('ANSWERED', 'NO_ANSWER', 'FAILED');

export default createModel({
  modelName: 'VoiceCampaignOutreachResult',

  properties: {
    campaignId: prop(String),
    recipientCollectionId: prop(String),
    recipientId: prop(String),
    result: prop().oneOf(..._.keys(OutreachResult)),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
