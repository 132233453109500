import { Factory } from 'rosie';

import './lib/uuid';

Factory.define('DisconnectNotification').extend('Uuid').attr('type', 'DISCONNECT');

Factory.define('DisconnectNotificationNetwork').extend('DisconnectNotification').attr('reason', 'NETWORK_ISSUE');
Factory.define('DisconnectNotificationServer').extend('DisconnectNotification').attr('reason', 'SERVER_ISSUE');

Factory.define('DisconnectNotificationWithDefaults').extend('DisconnectNotificationNetwork');
