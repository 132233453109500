import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ConversationTopicsMenu from './conversation_topics_menu';
import ConversationTopicsMenuOpener from './conversation_topics_menu_opener';
import { COLLAPSE_SELECTED_OPTIONS_NUM, SelectAction, TopicsMenuOptionType } from './conversation_topics_constants';

export default class ConversationTopicsMultiSelect extends PureComponent {
  /* life cycle */

  constructor(props) {
    super(props);

    let selectedValues = this.getInitialSelectedValues();
    let options = this.getOptions(null, this.props.options, selectedValues, this.props.suggestedIds || []);
    this.state = {
      filter: '',
      selectionsExpanded: false,
      selectedValues,
      opened: false,
      options,
    };
    this.suppressNextMouseEvent = true;

    _.bindAll(this, [
      'blur',
      'getInitialFocusedOptionId',
      'filterOptions',
      'focus',
      'handleApply',
      'handleBlur',
      'handleCancel',
      'handleClose',
      'handleCollapseSelections',
      'handleExpandSelections',
      'handleHeaderClick',
      'handleInputChange',
      'handleInputKeyDown',
      'handleMenuFocus',
      'handleMenuOpenerClick',
      'handleOpen',
      'handleOptionClick',
      'handleOptionFocus',
      'renderMenu',
      'setMenuRef',
      'setOpenerRef',
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let nextPropOptions = nextProps.options;

    let selectedValues = this.state.selectedValues;
    if (!_.isEqual(this.props.optionIds, nextProps.optionIds)) {
      let nextOptionIds = nextProps.optionIds || [];

      this.setState({
        selectedValues: nextOptionIds,
      });
      selectedValues = nextOptionIds;
    }

    let nextOptions = this.getOptions(
      this.state.options,
      nextPropOptions,
      selectedValues,
      nextProps.suggestedIds || []
    );
    const hasNewOptions = nextOptions.length !== this.state.options.length;
    const hasChangedOptions = _.intersection(nextOptions, this.state.options).length !== this.state.options.length;

    if (hasNewOptions || hasChangedOptions) {
      this.setState({
        options: nextOptions,
      });
    }
    if (
      (nextProps.suggestedIds || []).length !== (this.props.suggestedIds || []).length ||
      _.difference(nextProps.suggestedIds, this.props.suggestedIds).length > 0
    ) {
      this.setState({
        focusedOptionId: undefined,
      });
    }

    if (nextProps.lastOpenedAt && nextProps.lastOpenedAt !== this.props.lastOpenedAt) {
      this.handleOpen();
    }
  }

  /* api */

  blur() {
    this.opener.blur();
  }

  focus() {
    this.handleOpen();
    this.opener.focus();
  }

  /* event handlers */

  handleBlur() {
    if (this.isCancelled) {
      return;
    }
    this.saveOptionChanges();
    this.canClose = true;
    this.blur();
    this.handleClose();
  }

  handleApply(ev) {
    this.overrideEvent(ev);
    this.saveOptionChanges();
    this.closeMenu();
  }

  handleCancel(ev) {
    this.isCancelled = true;
    this.overrideEvent(ev);
    this.closeMenu();
  }

  closeMenu() {
    this.opener.focus();
    this.canClose = true;
    this.blur();
  }

  handleClose() {
    if (this.ignoreClose) {
      this.opener.focus();
      this.ignoreClose = false;
      return;
    }
    if (this.canClose) {
      this.props.onClose && this.props.onClose();
      this.canClose = false;
      this.setState({
        opened: false,
        filter: '',
        selectionsExpanded: false,
      });
    }
  }

  handleCollapseSelections(ev) {
    this.overrideEvent(ev);
    this.collapseSelections();
  }

  handleEnter() {
    let option = _.find(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    switch (option.type) {
      case TopicsMenuOptionType.OPTION:
        this.toggleOptionSelection(option);
        this.setState({ filter: '' });
        return this.getNextFilteredOptionId();
      case TopicsMenuOptionType.EXPANDER:
        this.expandSelections();
        return TopicsMenuOptionType.COLLAPSER;
      case TopicsMenuOptionType.COLLAPSER:
        this.collapseSelections();
        return TopicsMenuOptionType.EXPANDER;
      default:
        return option;
    }
  }

  handleExpandSelections(ev) {
    this.overrideEvent(ev);
    this.expandSelections();
  }

  handleHeaderClick() {
    this.resetFocusedOptionId();
  }

  handleInputChange(ev) {
    let input = ev.target.value;
    this.setState({ filter: input });
    this.filteredOptions = this.filterOptions(this.state.options, input, this.state.selectedValues);
    this.resetFocusedOptionId();
    this.props.onSearch && this.props.onSearch();
  }

  handleInputKeyDown(ev) {
    if (!this.state.opened) return;

    this.suppressNextMouseEvent = true;
    const hasOptions = this.filteredOptions.length > 0;

    switch (ev.keyCode) {
      case 9: // tab
        this.overrideEvent(ev);
        this.saveOptionChanges();
        this.closeMenu();
        break;
      case 13: // enter
        this.overrideEvent(ev);
        hasOptions && this.setFocusedOptionId(this.handleEnter());
        break;
      case 27: // esc
        this.overrideEvent(ev);
        this.closeMenu();
        break;
      case 37: // left
        if ((ev.metaKey || ev.ctrlKey) && this.state.selectionsExpanded) {
          this.overrideEvent(ev);
          this.collapseSelections();
          hasOptions && this.setFocusedOptionId(TopicsMenuOptionType.EXPANDER);
        }
        break;
      case 38: // up
        this.overrideEvent(ev);
        if (ev.metaKey || ev.ctrlKey) {
          this.menu.scrollToTop();
          this.resetFocusedOptionId();
        } else {
          hasOptions && this.setFocusedOptionId(this.getPreviousFilteredOptionId());
        }
        break;
      case 39: // right
        if ((ev.metaKey || ev.ctrlKey) && !this.state.selectionsExpanded) {
          this.overrideEvent(ev);
          this.expandSelections();
          hasOptions && this.setFocusedOptionId(TopicsMenuOptionType.COLLAPSER);
        }
        break;
      case 40: // down
        this.overrideEvent(ev);
        if (ev.metaKey || ev.ctrlKey) {
          this.menu.scrollToBottom();
          this.setFocusedOptionId(this.getLastFilteredOptionId());
        } else {
          hasOptions && this.setFocusedOptionId(this.getNextFilteredOptionId());
        }
        break;
      default:
        break;
    }
  }

  handleMenuFocus(ev) {
    this.ignoreClose = true;
  }

  handleMenuOpenerClick(event) {
    if (!this.state.opened) {
      this.focus();
      this.props.onFocus && this.props.onFocus(this);
    }
  }

  handleOpen() {
    this.props.onOpen && this.props.onOpen();
    this.resetFocusedOptionId();
    this.setState({
      opened: true,
    });
    this.suppressNextMouseEvent = true;
    this.isCancelled = false;
  }

  /* process clicking option on mouse down to precede react-select's internal processing */
  handleOptionClick(ev, option) {
    this.overrideEvent(ev);
    this.toggleOptionSelection(option);
    this.setFocusedOptionId(this.getNextFilteredOptionId());
    this.setState({ filter: '' });
  }

  handleOptionFocus(option) {
    /* mouse and keyboard interaction are a bit funky. When we scroll the menu to focus an option currently out
     * of view, the mouse enters a new option which we would focus, removing focus on the one navigated to by
     * keyboard. This prevents the behavior.
     */
    if (this.suppressNextMouseEvent) {
      this.suppressNextMouseEvent = false;
      return;
    }
    this.setFocusedOptionId(option.optionId);
  }

  /* presentation */

  render() {
    let className = classnames('conversationTopic-multi', this.props.className);

    let options = this.filterOptions(this.state.options, this.state.filter, this.state.selectedValues);
    this.filteredOptions = options;
    if (options.length > 0) {
      this.focusedOptionId = this.state.focusedOptionId || this.getInitialFocusedOptionId();
    }
    return (
      <div
        className={className}
        onBlur={this.handleBlur}
        onClick={this.handleMenuOpenerClick}
        onKeyDown={this.handleInputKeyDown}
      >
        <label className="conversationTopic-label">{this.props.label}</label>
        {this.renderMenuOpener()}
        {this.renderMenu(options)}
      </div>
    );
  }

  renderMenu(options) {
    if (!this.state.opened) {
      return null;
    }
    return (
      <ConversationTopicsMenu
        dropDown={this.props.dropDown}
        focusedOptionId={this.focusedOptionId}
        numSelectedOptions={this.state.selectedValues.length}
        onApply={this.handleApply}
        onCancel={this.handleCancel}
        onCollapserClick={this.handleCollapseSelections}
        onExpanderClick={this.handleExpandSelections}
        onFocus={this.handleMenuFocus}
        onHeaderClick={this.handleHeaderClick}
        onOptionClick={this.handleOptionClick}
        onOptionFocus={this.handleOptionFocus}
        options={options}
        ref={this.setMenuRef}
        unitLabelPlural={this.props.unitLabelPlural}
        unitLabelSingular={this.props.unitLabelSingular}
      />
    );
  }

  renderMenuOpener() {
    let selectedOptions = _.map(this.state.selectedValues, id => {
      let option = _.find(this.props.options, t => t.id === id);
      return option ? option.name : undefined;
    });
    selectedOptions = _.filter(selectedOptions, t => t !== undefined);
    return (
      <ConversationTopicsMenuOpener
        inputValue={this.state.filter}
        isOpen={this.state.opened}
        onInputBlur={this.handleClose}
        onInputChange={this.handleInputChange}
        placeholder={this.props.placeholder}
        ref={this.setOpenerRef}
        values={selectedOptions}
      />
    );
  }

  setOpenerRef(ref) {
    this.opener = ref;
  }

  /* utility */

  collapseSelections() {
    this.setState({
      selectionsExpanded: false,
    });
  }

  expandSelections() {
    this.setState({
      selectionsExpanded: true,
    });
  }

  filterOptions(options, filter, selected) {
    let selectedIds = {};
    _.forEach(selected, id => {
      selectedIds[id] = true;
    });
    let filteredOptions = _(options)
      .map(o => {
        return {
          ...o,
          selected: !!selectedIds[o.id],
          wasSelected: !!selectedIds[o.id] || o.wasSelected,
        };
      })
      .filter(o => filter === '' || o.selected || o.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
      .sortBy(o => o.label.toLowerCase())
      .value();

    let selectedOptions = _.filter(filteredOptions, o => o.selected);
    let suggestedOptions = _.filter(filteredOptions, o => o.suggested && !o.selected && !o.wasSelected);
    suggestedOptions = _.map(suggestedOptions, o => ({
      ...o,
      optionId: `${o.optionId}-suggested`,
    }));
    let unselectedOptions = _.filter(filteredOptions, o => !o.selected).map(o => ({ ...o, suggested: false }));
    unselectedOptions = _.filter(unselectedOptions, o => !o.archived);

    let currentOptions = this.collapseOrExpandOptions(selectedOptions);
    /* add divider between selected and unselected options */
    if (selectedOptions.length > 0 && unselectedOptions.length > 0) {
      currentOptions.push({
        disabled: true,
        type: TopicsMenuOptionType.DIVIDER,
        unfocusable: true,
        optionId: TopicsMenuOptionType.DIVIDER,
      });
    }

    if (suggestedOptions.length > 0 && this.props.suggestedLabel) {
      currentOptions.push({
        label: this.props.suggestedLabel,
        disabled: true,
        type: TopicsMenuOptionType.GROUP_TITLE,
        unfocusable: true,
        optionId: 'suggested-topics-title',
      });
      currentOptions = _.concat(currentOptions, suggestedOptions);
    }

    if (unselectedOptions.length > 0 && this.props.unselectedLabel) {
      currentOptions.push({
        label: this.props.unselectedLabel,
        disabled: true,
        type: TopicsMenuOptionType.GROUP_TITLE,
        unfocusable: true,
        optionId: 'all-topics-title',
      });
    }

    return _.concat(currentOptions, unselectedOptions);
  }

  collapseOrExpandOptions(selectedOptions) {
    /* collapse options or add ability to collapse them */
    if (selectedOptions.length > COLLAPSE_SELECTED_OPTIONS_NUM) {
      if (this.state.selectionsExpanded) {
        selectedOptions.push({
          disabled: true,
          type: TopicsMenuOptionType.COLLAPSER,
          optionId: TopicsMenuOptionType.COLLAPSER,
        });
      } else {
        let numCollapsedOptions = selectedOptions.length - COLLAPSE_SELECTED_OPTIONS_NUM;
        selectedOptions.splice(COLLAPSE_SELECTED_OPTIONS_NUM, numCollapsedOptions, {
          disabled: true,
          type: TopicsMenuOptionType.EXPANDER,
          optionId: TopicsMenuOptionType.EXPANDER,
        });
      }
    }
    return selectedOptions;
  }

  getInitialSelectedValues() {
    return this.props.optionIds || [];
  }

  getNextFilteredOptionId() {
    let option;
    let index = _.findIndex(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    do {
      index = (index + 1) % this.filteredOptions.length;
      option = this.filteredOptions[index];
    } while (option.unfocusable);
    return option.optionId;
  }

  getPreviousFilteredOptionId() {
    let option;
    let index = _.findIndex(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    do {
      index--;
      if (index < 0) {
        index = this.filteredOptions.length - 1;
      }
      option = this.filteredOptions[index];
    } while (option.unfocusable);
    return option.optionId;
  }

  getLastFilteredOptionId() {
    let last = _.last(this.filteredOptions);
    return last.optionId;
  }

  getOptions(currentOptions, newOptions, selectedValues, suggestedValues) {
    const selectedSet = new Set(selectedValues);
    const suggestedSet = new Set(suggestedValues);
    const wasSelectedSet = new Set(_.filter(currentOptions || [], o => o.wasSelected));
    return newOptions.map(t => {
      let id = t.id;
      let suggested = suggestedSet.has(id);
      let selected = selectedSet.has(id);
      let wasSelected = wasSelectedSet.has(id) || selected;
      return {
        archived: t.disabled, // Can't use 'disabled' as key since that affects react-select in undesirable way.
        label: t.name,
        selected,
        wasSelected,
        type: TopicsMenuOptionType.OPTION,
        suggested,
        optionId: id,
        id,
      };
    });
  }

  overrideEvent(ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  getInitialFocusedOptionId() {
    if (this.filteredOptions.length > 0) {
      let firstTitleIndex = _.findIndex(this.filteredOptions, { type: TopicsMenuOptionType.GROUP_TITLE });
      if (firstTitleIndex !== -1) {
        return this.filteredOptions[firstTitleIndex + 1].optionId;
      }
      let dividerIndex = _.findIndex(this.filteredOptions, { type: TopicsMenuOptionType.DIVIDER });
      if (dividerIndex !== -1) {
        return this.filteredOptions[dividerIndex + 1].optionId;
      }
      return this.filteredOptions[0] && this.filteredOptions[0].optionId;
    }
  }

  resetFocusedOptionId() {
    this.setFocusedOptionId(this.getInitialFocusedOptionId());
  }

  saveOptionChanges() {
    this.props.onOptionChange(this.state.selectedValues);
  }

  setFocusedOptionId(optionId) {
    this.opener.focus();
    this.focusedOptionId = optionId;
    this.setState({
      focusedOptionId: optionId,
    });
  }

  setMenuRef(ref) {
    this.menu = ref;
  }

  toggleOptionSelection(option) {
    if (option.disabled) {
      return;
    }
    let selectionType = option.suggested ? 'suggestion' : 'general-list';
    if (option.selected) {
      const nextValues = _.filter(this.state.selectedValues, v => v !== option.id);
      this.props.onSelect &&
        this.props.onSelect({
          topicId: option.id,
          topicName: option.label,
          action: SelectAction.REMOVE,
          selectionType,
        });
      let nextOptions = this.getOptions(
        this.state.options,
        this.props.options,
        nextValues,
        this.props.suggestedIds || []
      );
      this.setState({
        selectedValues: nextValues,
        options: nextOptions,
      });
    } else {
      const nextValues = [...this.state.selectedValues, option.id];
      this.props.onSelect &&
        this.props.onSelect({
          topicId: option.id,
          topicName: option.label,
          action: SelectAction.ADD,
          selectionType,
        });
      let nextOptions = this.getOptions(
        this.state.options,
        this.props.options,
        nextValues,
        this.props.suggestedIds || []
      );
      this.setState({
        selectedValues: nextValues,
        options: nextOptions,
      });
    }
  }
}

ConversationTopicsMultiSelect.propTypes = {
  className: PropTypes.string,
  dropDown: PropTypes.bool,
  label: PropTypes.string,
  lastOpenedAt: PropTypes.string,
  optionIds: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  onOpen: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onOptionChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  suggestedIds: PropTypes.array,
  suggestedLabel: PropTypes.string,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
  unselectedLabel: PropTypes.string,
};
