import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AcceptIncomingCall from 'actions/phone_call/accept_incoming_call';
import ActiveCall from 'models/active_call';
import AgentProfile from 'models/agent_profile';
import { AutoAccept } from 'models/voice_configuration';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import DeclineIncomingCall from 'actions/phone_call/decline_incoming_call';
import DeclineWarmTransfer from 'actions/phone_call/decline_warm_transfer';
import { formatPhoneNumber } from 'models/phone_number';
import IncomingCallNotificationContent from './incoming_call_notification_content';
import Notification from './notification';
import PhoneCall from 'models/phone_call';

function IncomingCallNotification({
  activeCall,
  autoAcceptSettings,
  currentAgent,
  disableDeclineCall,
  onAcceptIncomingCall,
  onDeclineIncomingCall,
  onDeclineWarmTransfer,
  routingGroupName,
  transferringAgentName,
}) {
  const currentAgentId = currentAgent.id;

  if (!activeCall || !activeCall.shouldDisplayIncomingCallNotification(currentAgentId)) {
    return null;
  }

  const phoneCall = activeCall.conversationItem.content;
  const incomingCallType = phoneCall.getIncomingCallType(currentAgentId);

  let customerDisplayName = activeCall.customer.name
    ? activeCall.customer.name
    : formatPhoneNumber(phoneCall.customerNumber);

  return (
    <Notification contentClasses="incomingCallNotification" notificationId={activeCall.conversationItem.id}>
      <IncomingCallNotificationContent
        customerDisplayName={customerDisplayName}
        incomingCallType={incomingCallType}
        routingGroupName={routingGroupName}
        transferringAgentName={transferringAgentName}
      />
      <div className="notification-buttonContainer">
        {currentAgent.isConfiguredForInteractiveVoice() ? renderDecline() : null}
        {renderAccept()}
      </div>
    </Notification>
  );

  function renderAccept() {
    if (currentAgent.isConfiguredForInteractiveVoice() && currentAgent.usesExternalHandset()) {
      return <span className="conversationNotification-acceptMessage">Answer using handset</span>;
    } else if (autoAcceptSettings.enabled) {
      const agentParticipant = activeCall.conversationItem.content.findParticipantById(currentAgentId);
      const delay = autoAcceptSettings.delaySecs;

      let startingPercentage = 0;
      if (agentParticipant && agentParticipant.status === 'DIALING') {
        const timeSinceDial = moment().diff(moment(agentParticipant.statusUpdatedAt), 'seconds');
        startingPercentage = (timeSinceDial / delay) * 100;
      }
      return (
        <AutoAcceptCallButton
          buttonType={ButtonTypes.PRIMARY}
          className="notification-button conversationNotification-autoAcceptButton"
          duration={delay}
          onClick={onClickAccept}
          startPercentage={startingPercentage}
        >
          Accepting
        </AutoAcceptCallButton>
      );
    }

    return (
      <Button
        buttonType={ButtonTypes.PRIMARY}
        className="notification-button conversationNotification-acceptButton"
        onClick={onClickAccept}
      >
        Accept
      </Button>
    );
  }

  function renderDecline() {
    return (
      !disableDeclineCall && (
        <Button
          buttonType={ButtonTypes.TEXT}
          className="notification-button conversationNotification-declineButton"
          contrast
          onClick={isWarmTransfer(incomingCallType) ? onDeclineWarmTransfer : onDeclineIncomingCall}
        >
          Decline
        </Button>
      )
    );
  }

  function onClickAccept(ev) {
    ev.stopPropagation();
    onAcceptIncomingCall();
  }

  function isWarmTransfer() {
    return incomingCallType === PhoneCall.IncomingCallType.WARM_TRANSFER;
  }
}

IncomingCallNotification.propTypes = {
  activeCall: PropTypes.instanceOf(ActiveCall),
  autoAcceptSettings: PropTypes.instanceOf(AutoAccept),
  currentAgent: PropTypes.instanceOf(AgentProfile),
  disableDeclineCall: PropTypes.bool,
  onAcceptIncomingCall: PropTypes.func.isRequired,
  onDeclineIncomingCall: PropTypes.func.isRequired,
  onDeclineWarmTransfer: PropTypes.func.isRequired,
  routingGroupName: PropTypes.string,
  transferringAgentName: PropTypes.string,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const activeCall = getProvider('activeCall').get();
  let transferringAgentName;
  let routingGroup;
  const currentAgent = getProvider('currentAgent').get();
  const voiceConfig = getProvider('voiceConfiguration').get();

  if (activeCall) {
    let transferringAgentId;

    if (activeCall.initiator) {
      transferringAgentId = activeCall.initiator.agentId;
      routingGroup = getProvider('routingGroups').findBy({ id: activeCall.initiator.routingGroupId });
    } else {
      if (
        activeCall.conversationItem &&
        activeCall.conversationItem.content &&
        activeCall.conversationItem.content.transfer
      ) {
        transferringAgentId = activeCall.conversationItem.content.transfer.fromParticipantId;
      }
    }

    if (transferringAgentId) {
      let transferringAgent = getProvider('agents').findBy({ id: transferringAgentId });
      transferringAgentName = transferringAgent ? transferringAgent.name || transferringAgent.email : 'An agent';
    }

    if (activeCall.routingGroupId) {
      routingGroup = getProvider('routingGroups').findBy({ id: activeCall.routingGroupId });
    }
  }

  const autoAcceptSettings = voiceConfig ? voiceConfig.autoAccept : { enabled: false };

  return {
    activeCall,
    autoAcceptSettings,
    currentAgent,
    disableDeclineCall: voiceConfig.disableDeclineCall,
    routingGroupName: routingGroup ? routingGroup.name : null,
    transferringAgentName,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onAcceptIncomingCall: () => executeAction(AcceptIncomingCall),
    onDeclineIncomingCall: () => executeAction(DeclineIncomingCall),
    onDeclineWarmTransfer: () => executeAction(DeclineWarmTransfer),
  };
}

const IncomingCallNotificationContainer = connect(mapStateToProps, mapExecuteToProps)(IncomingCallNotification);

export { IncomingCallNotification };
export default IncomingCallNotificationContainer;

const AutoAcceptCallButton = styled(Button)`
  background: linear-gradient(to right, rgba(255, 255, 255, 0.37) 50%, transparent 0);
  background-size: 200% 100%;
  background-position: ${p => 100 - p.startPercentage}% 0;
  border: 1px solid white;
  animation: fadeIn ${p => p.duration}s 1s forwards;

  @keyframes fadeIn {
    100% {
      background-position: 0% 0;
    }
  }
`;
