import ConversationItemType from 'models/conversation_item_type';
import createEnum from 'scripts/lib/create_enum';
import { MessageChannelType } from 'models/conversation_message';
import { RoutingChannel } from 'models/agent_routing_preferences';

/**
 * InteractionType is the shared content types used across Liveboards, Reports, and Search
 * The goal is for InteractionType to eventually replace service specific ContentTypes and instead rely on this single InteractionType
 */
const InteractionType = createEnum(
  'ABANDONED_CALL',
  'CHAT',
  'CUSTOM_CHANNEL',
  'EMAIL',
  'FB_MESSENGER',
  'INSTAGRAM_DIRECT',
  'PHONE_CALL',
  'SMS',
  'TASK',
  'TWITTER',
  'VOICEMAIL',
  'WHATSAPP'
);

export default InteractionType;

export function newCustomInteractionType(interactionType, id) {
  return `${interactionType}:${id}`;
}

export function baseInteractionType(interactionType) {
  if (!interactionType) {
    return '';
  }

  return interactionType.split(':')[0];
}

export function customId(interactionType) {
  if (!interactionType) {
    return '';
  }

  const parts = interactionType.split(':');
  if (parts.length !== 2) {
    return '';
  }

  return parts[1];
}

export function getInteractionType(contentType, content) {
  switch (contentType) {
    case ConversationItemType.ABANDONED_CALL:
      return InteractionType.ABANDONED_CALL;
    case ConversationItemType.CHAT_MESSAGE:
      return InteractionType.CHAT;
    case ConversationItemType.CONVERSATION_MESSAGE:
      return getInteractionTypeForConversationMessage(content);
    case ConversationItemType.CUSTOM_CHANNEL_MESSAGE:
      return getInteractionTypeForCustomChannelMessage(content);
    case ConversationItemType.EMAIL:
      return InteractionType.EMAIL;
    case ConversationItemType.FB_MESSAGE_INCOMING:
    case ConversationItemType.FB_MESSAGE_OUTGOING:
      return InteractionType.FB_MESSENGER;
    case ConversationItemType.PHONE_CALL:
      return InteractionType.PHONE_CALL;
    case ConversationItemType.SMS:
      return InteractionType.SMS;
    case ConversationItemType.VOICEMAIL:
      return InteractionType.VOICEMAIL;
    default:
      return '';
  }
}

function getInteractionTypeForConversationMessage(content) {
  switch (content.channel) {
    case MessageChannelType.INSTAGRAM_DIRECT:
      return InteractionType.INSTAGRAM_DIRECT;
    case MessageChannelType.TWITTER:
      return InteractionType.TWITTER;
    case MessageChannelType.WHATSAPP:
      return InteractionType.WHATSAPP;
    default:
      return '';
  }
}

function getInteractionTypeForCustomChannelMessage(content) {
  return newCustomInteractionType(InteractionType.CUSTOM_CHANNEL, content.customChannelId);
}

export function routingChannelForInteractionType(it) {
  return routingChannelByBaseType[baseInteractionType(it)] || RoutingChannel.MAIL;
}

const routingChannelByBaseType = Object.freeze({
  [InteractionType.ABANDONED_CALL]: RoutingChannel.MAIL,
  [InteractionType.CHAT]: RoutingChannel.MESSAGING,
  [InteractionType.CUSTOM_CHANNEL]: RoutingChannel.MESSAGING,
  [InteractionType.EMAIL]: RoutingChannel.MAIL,
  [InteractionType.FB_MESSENGER]: RoutingChannel.MESSAGING,
  [InteractionType.INSTAGRAM_DIRECT]: RoutingChannel.MESSAGING,
  [InteractionType.SMS]: RoutingChannel.MESSAGING,
  [InteractionType.TASK]: RoutingChannel.TASK,
  [InteractionType.TWITTER]: RoutingChannel.MESSAGING,
  [InteractionType.VOICEMAIL]: RoutingChannel.MAIL,
  [InteractionType.WHATSAPP]: RoutingChannel.MESSAGING,
});
