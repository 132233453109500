import React from 'react';
import styled from 'styled-components';

import { PopoverMenuItem } from 'components/common/menu';
import RelativeDateTime from 'components/lib/date/short_relative_date_time';

const Item = styled(PopoverMenuItem).attrs({
  'data-aid': 'agentNotifications-item',
})`
  align-items: flex-start;
  flex-direction: row;
  padding: 16px;
`;

const Text = styled.div.attrs(p => ({ 'data-aid': 'agentNotifications-text', 'data-read': p.hasRead }))`
  flex-grow: 1;
  font-weight: ${p => (p.hasRead ? 'default' : 'bold')};
`;

function Timestamp({ timestamp }) {
  return (
    <TimestampContainer>
      <RelativeDateTime timestamp={timestamp} />
    </TimestampContainer>
  );
}

const TimestampContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: ${p => p.theme.fontSize.small};
  justify-content: center;
  margin-top: 3px;
  width: 32px;
`;

export { Item, Text, Timestamp };
