import CustomerView from 'models/location/customer_view';
import qconsole from 'scripts/lib/qconsole';
import HandleCustomerSuggestions from 'actions/external_customer_lookup/handle_customer_suggestions';

export default class CustomerAutoExtensionGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleExtensionSuccess({ customerId, correlationId, resultDto }) {
    this.clearCustomerExtensionPending(customerId);
    this.context.executeAction(HandleCustomerSuggestions, {
      correlationId,
      suggestionsByIntegrationId: resultDto,
    });
  }

  handleExtensionErrors({ customerId, correlationId, errors }) {
    this.clearCustomerExtensionPending(customerId);
    this.context.executeAction(HandleCustomerSuggestions, { correlationId, errors });
    qconsole.error(`customer auto extension for [${customerId}] failed: ${JSON.stringify(errors)}`);
  }

  clearCustomerExtensionPending(customerId) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView) || currentLocation.customerId !== customerId) {
      return;
    }

    currentLocation.clearCustomerExtensionPending();
    this.context.stores.currentLocation.set(currentLocation);
  }
}
