import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import map from 'lodash/map';
import filter from 'lodash/filter';

import { H3 } from 'components/common/headers';
import StackContainer from 'components/common/containers/stack_container';

const NavUl = styled.ul`
  margin: 0;
  padding: 0;
`;

/**
 * @visibleName Nav Menu
 */
const NavMenu = ({
  children,
  className = '',
  'data-aid': dataAid = 'navMenu',
  title,
  titleLink = '',
  childrenOrder = [],
}) => {
  const childrenArray = children && React.Children.toArray(children);

  const orderedChildren = childrenOrder.length
    ? map(childrenOrder, c => filter(childrenArray, c1 => c === c1.props.label)[0])
    : children;
  const label = titleLink || title;
  return (
    <nav aria-labelledby="nav-menu-label" className={className} data-aid={dataAid}>
      <NavUl role="navigation">
        <StackContainer as="li" inset="none" stack="medium">
          <NavMenuLabel id="nav-menu-label">{label}</NavMenuLabel>
        </StackContainer>
        {orderedChildren}
      </NavUl>
    </nav>
  );
};

NavMenu.propTypes = {
  /** Child components of the nav menu, usually categories and page links */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** String of CSS class names to allow custom styling */
  className: PropTypes.string,
  /** The title to display for the nav component */
  title: PropTypes.string.isRequired,
  /** List of all sections name in some order */
  childrenOrder: PropTypes.arrayOf(PropTypes.string),
};

const NavMenuLabel = styled(H3)`
  margin: 0;
`;

export { NavMenu, NavMenuLabel };
