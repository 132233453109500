import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import { formatValue } from 'components/customer/summary/custom_attributes/visible_custom_attribute';
import Dotdotdot from 'react-dotdotdot';

export default function VisibleMultilineCustomAttribute({
  clampTextValue = 3,
  className,
  def,
  'data-aid': dataAid,
  value,
}) {
  const MAX_CLAMP_VALUE = 200;
  const multilineRef = useRef();
  const [hasOverflow, setHasOverflow] = useState(false);
  const CheckOverflow = () => {
    if (multilineRef.current?.container) {
      let refOverflow =
        multilineRef.current?.container.clientWidth < multilineRef.current?.container.scrollWidth ||
        multilineRef.current?.container.clientHeight < multilineRef.current?.container.scrollHeight;
      setHasOverflow(refOverflow);
    }
  };
  const [textClamped, setTextClamped] = useState(true);
  useEffect(() => {
    CheckOverflow();
  });

  if (!value || !_.isString(value) || def.type !== AttributeType.MULTILINE_TEXT) {
    return null;
  }

  const hasLabel = !!_.trim(def.label);
  const trimmedValue = _.trim(value);
  const displayValArr = formatValue(trimmedValue, def.type).split('\n');
  const renderedText = _.reduce(
    displayValArr,
    (acc, displayVal, idx) => {
      if (idx) acc.push(<br key={`break-${idx}`} />);
      acc.push(displayVal);
      return acc;
    },
    []
  );

  const classNames = classnames('visibleCustomAttribute', 'multilineAttribute', className);
  const labelClassNames = classnames('multilineAttributeLabel', className);

  const HandleClick = () => {
    setTextClamped(!textClamped);
  };

  const buttonText = textClamped ? 'Show more' : 'Show less';
  const clampValue = textClamped ? clampTextValue : MAX_CLAMP_VALUE;
  const label = hasLabel ? <CustomAttributeLabel className={labelClassNames}>{def.label}</CustomAttributeLabel> : null;

  const button =
    hasOverflow || (!hasOverflow && !textClamped) ? (
      <StyledTextButton buttonType={ButtonTypes.TEXT} className="mulitlineShowButton" onClick={HandleClick}>
        {buttonText}
      </StyledTextButton>
    ) : null;

  return (
    <>
      <CustomAttributeContainer className={classNames} data-aid={dataAid}>
        {label}
        <Dotdotdot clamp={clampValue} ref={multilineRef}>
          <VisibleCustomAttributeValue className={'multilineAttributeValue'}>
            {renderedText}
          </VisibleCustomAttributeValue>
        </Dotdotdot>
        {button}
      </CustomAttributeContainer>
    </>
  );
}

export const VisibleCustomAttributeValue = styled.div`
  font-weight: ${p => p.theme.fontWeight.normal};
  flex-basis: 100%;
`;

export const StyledTextButton = styled(Button)`
  font-weight: ${p => p.theme.fontWeight.medium};
  color: ${p => p.theme.colors.green400};
  padding-left: 0;
  &:hover {
    color: ${p => p.theme.colors.green400};
    text-decoration: underline;
  }
`;

export const CustomAttributeContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  flex-wrap: wrap;
`;

export const CustomAttributeLabel = styled.div`
  flex-basis: 100%;
  font-weight: ${p => p.theme.fontWeight.medium};
  margin-right: ${p => p.theme.spacing.small};
  color: ${p => p.theme.colors.black};
  overflow: hidden;
  text-overflow: ellipsis;
`;

VisibleMultilineCustomAttribute.propTypes = {
  clampTextValue: PropTypes.number,
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  value: PropTypes.any,
};
