import hasLiveConnection from 'actions/phone_call/lib/has_live_connection';
import PhoneCallObserverNotification from 'models/notification/phone_call_observer_notification';
import DismissNotification from 'actions/notification/dismiss_notification';

export default class ShowPhoneCallObserverNotification {
  constructor(context) {
    this.context = context;
  }

  run({ message, recipient }) {
    let activeCall = this.context.stores.activeCall.get();
    let hasLivePhoneConnection = activeCall && hasLiveConnection(this.context, { activeCall });
    if (!activeCall || !hasLivePhoneConnection) {
      return;
    }

    let notification = PhoneCallObserverNotification.create({
      id: activeCall.conversationItem.id,
      message,
      recipient,
    });

    this.context.stores.notifications.addOrReplace(notification);
    DismissNotification.schedule(this.context, { id: notification.id });
  }
}
