import createModel, { prop } from './lib/create_model';
const RelationshipLabel = createModel({
  modelName: 'RelationshipLabel',
  properties: {
    id: prop(String),
    displayName: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default RelationshipLabel;
