import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import moment from 'moment';

export default class ReportingService {
  create(orgId, payload) {
    return {
      expiringUrl: {
        url:
          'https://gladlydev.cloud.looker.com/embed/dashboards/gladly_master::reporting_summary_all_channels?allow_login_screen=true&Date=Last 2 weeks\n',
        expiry: moment()
          .utc()
          .add(1, 'days')
          .toISOString(),
      },
      explore: 'contact-metrics',
      timezone: 'America/New_York',
    };
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/reporting/v1/orgs/:orgId/embedded-datasets': {
          POST: (attrs, callback, path, { orgId }) =>
            callback(null, {
              status: 200,
              statusText: statusText(200),
              response: this.create(orgId, attrs),
            }),
        },
      },
      this
    );
  }
}
