import { createPluginFactory } from '@udecode/plate';
import { isEmpty, isFunction, filter, find, forEach } from 'lodash';

import { isWeakRefSupported } from 'scripts/lib/browser_detector';

const KEY_EDITOR_EVENTS_PLUGIN = 'editor_events';
const ALLOW_WEAK_REF = isWeakRefSupported();

// Each entry in the `eventCallbacks` is an array of event handler descriptors. On modern browsers, we use
// "weak references" to prevent accidental memory leaks. On Chrome < 84 (read "Pingdom"), we have to use
// "strong references".
const eventCallbacks = {};

/**
 * Very simple implementation of the "event rail" (similar to the events in Node and other environments). The
 * reason we don't use an existing library is because we only need a small subset of functions, and we want to
 * use weak references when possible to make it harder to create a memory leak
 */
export function createEditorEventsPlugin() {
  return createPluginFactory({
    key: KEY_EDITOR_EVENTS_PLUGIN,
    withOverrides: withEditorEvents,
  })();

  function withEditorEvents(editor) {
    editor.events = editor.events || {};

    editor.events.on = (event, callback) => {
      addEventCallback(event, callback, false);
    };

    editor.events.off = (event, callback) => {
      removeEventCallback(event, callback);
    };

    editor.events.once = (event, callback) => {
      addEventCallback(event, callback, true);
    };

    editor.events.emit = (event, ...data) => {
      if (!event || isEmpty(eventCallbacks[event])) return;

      forEach(eventCallbacks[event], descriptor => {
        const handler = ALLOW_WEAK_REF ? descriptor.callbackRef?.deref() : descriptor.callbackRef;
        if (isFunction(handler)) {
          handler(...data);
        } else {
          descriptor.once = true; // Remove the entry after the event run
        }
      });

      // Now remove dead entries
      const updatedCallbacks = filter(eventCallbacks[event], entry => !entry.once);
      if (isEmpty(updatedCallbacks)) {
        delete eventCallbacks[event];
      } else {
        eventCallbacks[event] = updatedCallbacks;
      }
    };

    function addEventCallback(event, callback, once) {
      if (!event || !callback) return;

      const existingCallback = find(eventCallbacks[event], descriptor => {
        const handler = ALLOW_WEAK_REF ? descriptor.callbackRef?.deref() : descriptor.callbackRef;
        return callback === handler;
      });

      if (!existingCallback) {
        eventCallbacks[event] = eventCallbacks[event] || [];
        eventCallbacks[event].push({
          // eslint-disable-next-line no-undef
          callbackRef: ALLOW_WEAK_REF ? new WeakRef(callback) : callback,
          once,
        });
      }
    }

    function removeEventCallback(event, callback) {
      if (!event || !eventCallbacks[event]) return;

      if (!callback) {
        delete eventCallbacks[event];
      } else {
        const updatedCallbacks = filter(eventCallbacks[event], entry => {
          const handler = ALLOW_WEAK_REF ? entry.callbackRef?.deref() : entry.callbackRef;
          return handler !== callback;
        });

        if (isEmpty(updatedCallbacks)) {
          delete eventCallbacks[event];
        } else {
          eventCallbacks[event] = updatedCallbacks;
        }
      }
    }

    return editor;
  }
}
