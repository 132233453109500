import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';

import CircleIcon from './icons/circle_icon';
import styled from 'styled-components';
import PauseIcon from 'components/common/icons/stroke/pause';

export default class HoldButton extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }
  render() {
    return (
      <CircleIcon viewBox="0 0 25 25" {...this.props}>
        <StyledPauseIcon />
      </CircleIcon>
    );
  }
}

const StyledPauseIcon = styled(PauseIcon)`
  path {
    transform: scale(0.6);
    transform-origin: center;
  }
`;
