import styled from 'styled-components';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { HeightChangeContext } from 'components/customer/conversation_history/virtualized/list_item';
import DownChevronIcon from 'components/lib/icons/down_chevron_and_fill_icon';
import Link from 'components/common/link';

const CampaignWrapper = styled.div``;
const CampaignDescriptionWrapper = styled.div``;
const ExpandChevron = styled(DownChevronIcon)`
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  fill: ${p => p.theme.colors.gray400};
  height: 16px;
  width: 16px;
  transition: transform 200ms ease;
  transform: rotate(${p => p.rotate}deg);
`;
const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
  height: 32px;
`;

export default function CampaignTriggeredContent({ content }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { onHeightChange } = useContext(HeightChangeContext);
  useEffect(() => onHeightChange && onHeightChange({ isManual: true }), [isExpanded, onHeightChange]);
  const expandTimeline = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

  return (
    <CampaignWrapper>
      <TextWrapper>
        <Link href={`/admin/proactive-chat/${content.campaignId}`}>{content.campaignName} </Link>
        campaign triggered
        <ExpandChevron onClick={expandTimeline} rotate={isExpanded ? 0 : 180} />
      </TextWrapper>
      {isExpanded && (
        <CampaignDescriptionWrapper>
          <TextWrapper>{content.campaignDescription}</TextWrapper>
        </CampaignDescriptionWrapper>
      )}
    </CampaignWrapper>
  );
}
