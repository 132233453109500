import CustomerProfileConverter from 'scripts/application/dto_converters/customer_profile_converter';
import { updateActiveCallCustomer } from './lib/active_call';

export default class ActiveCallCustomerProfileObserver {
  constructor(context) {
    this.context = context;
  }

  handleEntity(profileDto) {
    let activeCall = this.context.stores.activeCall.get();
    if (!activeCall || activeCall.customer.id !== profileDto.id) {
      return;
    }

    updateActiveCallCustomer(this.context, CustomerProfileConverter.fromDto(profileDto));
  }

  handleFetchError() {
    // handled by the main observer
  }

  handleFetchSuccess(profileDto) {
    this.handleEntity(profileDto);
  }

  // No-op handlers, nothing to do if any of those happen during active call
  handleAddSuccess() {}

  handleAddErrors() {}

  handleSetSuccess() {}

  handleSetErrors() {}

  handleDeleteProfile() {}
}
