import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressAlt } from '@fortawesome/pro-light-svg-icons/faCompressAlt';

import Link from 'components/common/link';

export default class CustomerWasCombined extends React.PureComponent {
  render() {
    return (
      <div className="customer-combinedCustomer">
        <div className="customer-combinedCustomer-icon">
          <FontAwesomeIcon icon={faCompressAlt} />
        </div>
        <div className="customer-combinedCustomer-message">
          <div>This customer was merged with another</div>
          <Link className="customer-combinedCustomer-link" href={`/customer/${this.props.customerId}`}>
            Go to the new profile
          </Link>
        </div>
      </div>
    );
  }
}

CustomerWasCombined.propTypes = {
  customerId: PropTypes.string.isRequired,
};
