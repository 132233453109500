import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import connect from 'components/lib/connect';
import DropdownMenu, { OpenerButton } from 'components/common/dropdown_menu';
import Endpoint from 'models/endpoint';
import SetFrom from 'actions/composer/email/set_from';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function From(props) {
  const { compositionId, from } = props;

  const options = getFromOptions(props);
  const executeAction = useExecuteAction();
  const onChangeFrom = useCallback(
    value => {
      executeAction(SetFrom, { compositionId, from: value });
    },
    [executeAction, compositionId]
  );
  return (
    <Wrapper>
      <DropdownMenu
        data-aid="fromAddress"
        isError={!from}
        onSelect={onChangeFrom}
        openerComponent={OpenerButtonStyled}
        options={options}
        placeholder="Select a from address"
        text={from}
        value={from}
      />
    </Wrapper>
  );
}

const OpenerButtonStyled = styled(OpenerButton)`
  margin-left: 8px;
  min-height: 24px;
  padding: 4px 12px;

  &&&:active {
    ${p => (p.isError ? { 'border-color': p.theme.colors.red400 } : '')}
  }
`;

const Wrapper = styled.span``;

const FromContainer = connect(mapStateToProps)(From);

function mapStateToProps({ getProvider }, { compositionId, conversationId, from }) {
  const channelConfiguration = getProvider('channelConfiguration').get();
  const communicationQueues = getProvider('communicationQueues').findAll();
  const conversation = getProvider('conversations').findBy({ id: conversationId });
  const currentAgent = getProvider('currentAgent').get();
  const routingGroups = getProvider('routingGroups').findAll();

  return {
    channelConfiguration,
    communicationQueues,
    compositionId,
    conversation,
    currentAgent,
    from,
    routingGroups,
  };
}

function getFromOptions({
  channelConfiguration,
  communicationQueues,
  conversation,
  currentAgent,
  from,
  routingGroups,
}) {
  const emailEndpoints = channelConfiguration
    ? channelConfiguration.endpoints.filter(e => e.type === Endpoint.Type.EMAIL)
    : [];
  const inbox = _.difference(getInboxAddresses(), [from]);
  const agent = _.difference(getAgentAddresses(), inbox, [from]);
  const other = _.difference(getAllAddresses(), inbox, agent, [from]);

  let fromOptions = [];
  if (from) {
    addressGroupOptions(fromOptions, [from], 'Suggested');
  }
  addressGroupOptions(fromOptions, inbox, 'Current inbox');
  addressGroupOptions(fromOptions, agent, 'Your inboxes');
  addressGroupOptions(fromOptions, other, 'Other inboxes');

  return fromOptions;

  function getInboxAddresses() {
    return conversation
      ? _.chain(communicationQueues)
          .filter(commQueue => commQueue.routingGroupId === conversation.assignee.routingGroupId)
          .map(commQueue => _.find(emailEndpoints, endpoint => endpoint.id === commQueue.endpointId))
          .compact()
          .map(endpoint => endpoint.address)
          .value()
      : [];
  }

  function getAgentAddresses() {
    const currentAgentId = currentAgent.id;
    const agentRoutingGroups = currentAgentId
      ? routingGroups.filter(group => {
          return group.agentIds.indexOf(currentAgentId) > -1;
        })
      : [];
    const routingGroupIds = _.map(agentRoutingGroups, r => r.id);

    return _.chain(communicationQueues)
      .filter(commQueue => _.includes(routingGroupIds, commQueue.routingGroupId))
      .map(commQueue => _.find(emailEndpoints, endpoint => endpoint.id === commQueue.endpointId))
      .compact()
      .map(endpoint => endpoint.address)
      .value();
  }

  function getAllAddresses() {
    return channelConfiguration ? channelConfiguration.fromAddresses() : [];
  }
}

function addressGroupOptions(options, addresses, header) {
  let froms = _.sortBy(addresses, f => f);
  if (froms && froms.length > 0) {
    options.push({
      label: _.capitalize(header),
      type: 'HEADER',
    });
    _.map(froms, f =>
      options.push({
        value: f,
        label: f,
        text: f,
      })
    );
  }
}

export default function FromContainerContainer() {
  const { composition } = useContext(ComposerContext);
  return (
    <FromContainer
      compositionId={composition.id}
      conversationId={composition.conversationId}
      from={composition.content.from}
    />
  );
}
