import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('OfferedCallNotification');

Factory.define('OfferedCallNotificationWithDefaults')
  .extend('OfferedCallNotification')
  .attr('type', 'OFFERED_CALL')
  .attr('profile', () => Factory.attributes('CustomerProfileWithDefaults'))
  .attr('conversationId', 'conversation-id')
  .attr('conversationItem', () => Factory.attributes('PhoneCallItemWithDefaults'))
  .attr('id', ['conversationItem'], conversationItem => conversationItem.id);
