import DismissNotification from 'actions/notification/dismiss_notification';
import FocusModeDisabledNotification from 'models/notification/focus_mode_disabled_notification';

const FOCUS_MODE_DISABLED_NOTIFICATION_DISMISSAL_TIMEOUT = 10000;

export default class ShowFocusModeDisabledNotification {
  constructor(context) {
    this.context = context;
  }

  run() {
    let notification = FocusModeDisabledNotification.create();
    this.context.stores.notifications.addOrReplace(notification);
    DismissNotification.schedule(this.context, {
      id: notification.id,
      timeout: FOCUS_MODE_DISABLED_NOTIFICATION_DISMISSAL_TIMEOUT,
    });
  }
}
