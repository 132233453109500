import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import { isMonitorBot, isValidBrowser } from 'scripts/lib/browser_detector';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import WarningIcon from 'components/lib/icons/warning_icon';

export default function BrowserCompatibilityModal() {
  const [isOpen, setIsOpen] = useState(() => !isValidBrowser() && !isMonitorBot());
  const onClose = useCallback(() => setIsOpen(false), []);

  if (!isOpen) {
    return null;
  }

  return (
    <PortalModalWrapper>
      <Modal>
        <ModalCard data-aid="browserCompatibilityModal" onClose={onClose}>
          <Container>
            <IconContainer>
              <WarningIcon />
            </IconContainer>
            <Text>
              <Description>
                You are using a browser and/or operating system which we don’t support, so Gladly will not work
                correctly. Data may be lost and messages may be incorrectly sent to customers.
              </Description>
              <Plea>Please use Google Chrome to ensure a proper experience for you and your customers.</Plea>
            </Text>
          </Container>
          <ButtonWrapper>
            <Button buttonType={ButtonTypes.PRIMARY} onClick={onClose}>
              Continue Anyway
            </Button>
          </ButtonWrapper>
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

const Modal = styled.div`
  .modalCard-content {
    max-width: 550px;
  }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const IconContainer = styled.div`
  margin-right: 24px;
  svg {
    height: 48px;
    width: 48px;
  }
`;

const Text = styled.div`
  font-size: ${p => p.theme.fontSize.medium};
`;

const Description = styled.div`
  color: ${p => p.theme.colors.gray900};
  font-weight: ${p => p.theme.fontWeight.medium};
  line-height: 1.5;
  margin-bottom: ${p => p.theme.spacing.large};
`;

const Plea = styled.div`
  color: ${p => p.theme.colors.gray900};
  line-height: 1.5;
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
