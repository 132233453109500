import ErrorReporter from 'scripts/infrastructure/error_reporter';

export default class ConversationMessageGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddSuccess() {}

  handleAddErrors(errorsDto) {
    ErrorReporter.reportError(`Unhandled message send error: ${JSON.stringify(errorsDto)}`);
  }
}
