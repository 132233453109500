import moment from 'moment';

import { AggregationLevel } from 'models/reports/report_configs';

function getFormat(aggregationLevel) {
  switch (aggregationLevel) {
    case AggregationLevel.DAY:
    case AggregationLevel.WEEK:
      return 'MMMM D, YYYY';
    case AggregationLevel.MONTH:
      return 'MMMM YYYY';
    case AggregationLevel.QUARTER:
      return '[Q]Q YYYY';
    default:
      return 'MMMM D, YYYY';
  }
}

function getShortFormat(aggregationLevel) {
  switch (aggregationLevel) {
    case AggregationLevel.DAY:
    case AggregationLevel.WEEK:
      return 'MMM D';
    case AggregationLevel.MONTH:
      return "MMM 'YY";
    case AggregationLevel.QUARTER:
      return "[Q]Q 'YY";
    default:
      return 'M/DD';
  }
}

export default {
  toDisplayDate(date, aggregationLevel, showPrefix = false) {
    const prettyDate = moment(date).format(getFormat(aggregationLevel));
    const prefix = aggregationLevel === AggregationLevel.WEEK && showPrefix ? 'Week of ' : '';
    return `${prefix}${prettyDate}`;
  },

  toShortDisplayDate(date, aggregationLevel) {
    return moment(date).format(getShortFormat(aggregationLevel));
  },
};
