import _ from 'lodash';
import React from 'react';
import { AtomicBlockUtils, ContentState, EditorState } from 'draft-js';
import PropTypes from 'prop-types';

import Attachment from 'models/attachment';
import connect from 'components/lib/connect';
import { findEntities } from './draft_entity';
import { IMMUTABLE } from './draft_shared';
import LoadingSpinner from 'components/lib/loading_spinner';
import Upload from 'models/upload';

export const ENTITY_TYPE_INLINE_IMAGE = 'INLINE_IMAGE';
export const findInlineImageEntities = findEntities(ENTITY_TYPE_INLINE_IMAGE);

class DraftInlineImage extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['renderCompleted', 'renderLoading', 'renderFailed']);
  }

  renderCompleted() {
    const { contentState, block } = this.props;
    const data = contentState.getEntity(block.getEntityAt(0)).getData();
    return <img className="draftEditor-inlineImage" src={data.src} />;
  }

  renderLoading() {
    return (
      <div className="draftEditor-inlineImage-loading">
        <LoadingSpinner />
      </div>
    );
  }

  renderFailed() {
    return null;
  }

  render() {
    const attachment = this.props.attachment;
    // !attachment to handle any imported images that are hosted elsewhere
    if (!attachment || attachment instanceof Attachment) {
      return this.renderCompleted();
    }

    switch (attachment.status) {
      case Upload.Status.NEW:
      case Upload.Status.STARTED:
        return this.renderLoading();
      case Upload.Status.FAILED:
        return this.renderFailed();
      case Upload.Status.COMPLETED:
      default:
        return this.renderCompleted();
    }
  }
}

DraftInlineImage.propTypes = {
  block: PropTypes.object.isRequired,
  contentState: PropTypes.instanceOf(ContentState).isRequired,
  attachment: PropTypes.oneOfType([PropTypes.instanceOf(Attachment), PropTypes.instanceOf(Upload)]),
};

// Container

const mapStateToProps = (context, props) => {
  const data = props.contentState.getEntity(props.block.getEntityAt(0)).getData();
  const snippetComposition = context.getProvider('snippetComposition');
  if (data.attachmentId) {
    const snippet = snippetComposition.get();
    const attachment = snippet && snippet.findAttachmentById(data.attachmentId);
    return { ...props, attachment };
  }

  return { ...props };
};

export { DraftInlineImage };
export default connect(mapStateToProps)(DraftInlineImage);

// Helpers

export function insertImage({ attachmentId, editorState, src }) {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(ENTITY_TYPE_INLINE_IMAGE, IMMUTABLE, {
    attachmentId,
    src,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
}
