import { IdGenerator } from 'factories/lib/uuid';
import SearchQuery from 'models/search/search_query';
import SearchField, { SnippetSearchFields } from 'models/search/search_field';
import SearchFilter from 'models/search/search_filter';
import SearchQueryConverter from 'scripts/application/dto_converters/search/search_query_converter';
import SearchResult from 'models/search/search_result';
import { SnippetContentType } from 'models/answers/snippet';

export default class SearchAnswers {
  constructor(context) {
    this.context = context;
  }

  run({ channel, language, searchText, audienceIds, from = 0, size = 100, sort }) {
    const routerParams = this.context.router.parseQueryParameters().toJS();
    if (routerParams.lng && !language) {
      language = routerParams.lng;
    }
    if (routerParams.channel && !channel) {
      channel = routerParams.channel;
    }
    if (routerParams.q && !searchText) {
      searchText = routerParams.q;
    }
    if (routerParams.audiences && !audienceIds) {
      audienceIds = routerParams.audiences.split(',');
    }

    const id = IdGenerator.newId();
    const attrs = { text: searchText, from, size, sort };
    if (language) {
      attrs.languages = [language];
    }
    if (channel) {
      attrs.fields = getSearchFields(channel);
    }
    attrs.filter = createFilter(channel, language, audienceIds);
    const query = new SearchQuery(attrs);
    this.context.stores.snippetSearchResult.set(new SearchResult({ id, query, isLoading: true }));

    const queryDto = { query: SearchQueryConverter.toDto(query) };
    this.context.gateways.snippetSearchResultHttp.request(id, queryDto);
  }
}

function createFilter(channel, language, audienceIds) {
  let filter = new SearchFilter();
  if (!channel && !language && !audienceIds) {
    return filter;
  }
  let fieldName = channel ? searchFields[channel] : SnippetSearchFields.CONTENTS;
  filter.setAudienceIds(audienceIds);
  filter.setHasFields([new SearchField({ language, name: fieldName })]);
  return filter;
}

function getSearchFields(channel) {
  return [SnippetSearchFields.NAME, SnippetSearchFields.DESCRIPTION, searchFields[channel]];
}

const searchFields = {
  [SnippetContentType.ANY_CHANNEL]: SnippetSearchFields.ANY_CHANNEL,
  [SnippetContentType.INFO]: SnippetSearchFields.INFO,
  [SnippetContentType.MESSAGE]: SnippetSearchFields.MESSAGE,
  [SnippetContentType.SELF_SERVICE]: SnippetSearchFields.SELF_SERVICE,
};
