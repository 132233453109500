import CustomerView from 'models/location/customer_view';

import OpenOrRefreshCommentPanel from 'actions/task/open_or_refresh_comment_panel';
import Task from 'models/task';
import TaskAnalyticsLocations from 'actions/conversation_item/task/task_analytics_locations';

export default class SwitchCurrentItem {
  constructor(context) {
    this.context = context;
  }

  run({ itemId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error(
        `cannot switch item when current location is [${currentLocation && currentLocation.constructor.name}]`
      );
    }

    currentLocation.changeCurrentConversationItem(itemId);
    this.context.stores.currentLocation.set(currentLocation);

    this._openCommentPanelForTask(itemId);
  }

  _openCommentPanelForTask(itemId) {
    // determine if task comment panel should be opened
    let item = this.context.stores.conversationHistory.findBy({ id: itemId });
    if (!item || !(item.content instanceof Task)) return;
    this.context.executeAction(OpenOrRefreshCommentPanel, {
      itemId: item.id,
      location: TaskAnalyticsLocations.DIRECT_LINK,
    });
  }
}
