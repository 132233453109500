import _ from 'lodash';

import Observable from 'scripts/lib/observable_mixin';
import PollingUpdater from './lib/polling_updater';
import StandardGateway from 'scripts/adapters/gateways/lib/standard_gateway';

const UPDATE_INTERVAL_MS = 10000;

export default class ConversationCountGateway {
  constructor(backend, requestorId) {
    this._gateways = {
      agentConversationCount: new AgentConversationCountGateway(backend, requestorId),
      groupConversationCount: new StandardGateway(backend, 'v1/orgs/:orgId/routing-groups-conversation-counts'),
    };

    this._callMethodOnGateways('addObserver', this);

    _.bindAll(this, ['resetHandler', 'reconnectHandler']);

    this.poller = new PollingUpdater(['agent', 'group'], UPDATE_INTERVAL_MS);
  }

  init(opts) {
    this._callMethodOnGateways('init', opts);
  }

  reconnectHandler() {
    this._callMethodOnGateways('reconnectHandler');
  }

  resetHandler() {
    this.unsubscribeFromConversationCounts();
    this._callMethodOnGateways('resetHandler');
  }

  _callMethodOnGateways(methodName, ...params) {
    _.forIn(this._gateways, gateway => gateway[methodName] && gateway[methodName].apply(gateway, params));
  }

  fetchAgentConversationCounts(agentId) {
    return this._gateways.agentConversationCount.fetch(agentId);
  }

  fetchGroupConversationCounts() {
    return this._gateways.groupConversationCount
      .fetch()
      .then(countsDto => this.handleFetchGroupCountsSuccess(countsDto))
      .catch(errorDto => this.handleFetchConversationCountError(errorDto));
  }

  handleFetchAgentCountsSuccess(countsDto) {
    this.notifyObservers('handleFetchAgentCountsSuccess', countsDto);
    this.poller.poll('agent', this.fetchAgentConversationCounts.bind(this));
  }

  handleFetchGroupCountsSuccess(countsDtos) {
    this.notifyObservers('handleFetchGroupCountsSuccess', countsDtos);
    this.poller.poll('group', this.fetchGroupConversationCounts.bind(this));
  }

  handleFetchConversationCountError(errorDto) {
    this.notifyObservers('handleFetchConversationCountError', errorDto);
  }

  requestAgentConversationCounts(agentId) {
    this.poller.clearAndSet('agent', agentId);
    return this.fetchAgentConversationCounts(agentId);
  }

  unsubscribeFromAgentConversationCounts() {
    this.poller.unsubscribe('agent');
  }

  requestGroupConversationCounts() {
    this.poller.clearAndSet('group', true);
    return this.fetchGroupConversationCounts();
  }

  unsubscribeFromGroupConversationCounts() {
    this.poller.unsubscribe('group');
  }

  unsubscribeFromConversationCounts() {
    this.unsubscribeFromAgentConversationCounts();
    this.unsubscribeFromGroupConversationCounts();
  }
}

_.extend(ConversationCountGateway.prototype, Observable);

class AgentConversationCountGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
    _.bindAll(this, ['onFetchResponse', 'onFetchError']);
  }

  init({ orgId, agentId }) {
    this.orgId = orgId;
    this.agentId = agentId;
  }

  get _httpClient() {
    return this.backend.axios();
  }

  fetch(agentId) {
    return this._httpClient
      .get(`/api/v1/orgs/${this.orgId}/agents/${agentId}/conversation-counts`)
      .then(this.onFetchResponse)
      .catch(this.onFetchError);
  }

  onFetchResponse(res) {
    if (res.status === 200) {
      this.notifyObservers('handleFetchAgentCountsSuccess', res.data);
    } else {
      this.onFetchError(res.data);
    }
  }

  onFetchError(errorDto) {
    this.notifyObservers('handleFetchConversationCountError', errorDto);
  }
}

_.extend(AgentConversationCountGateway.prototype, Observable);
