const ensureOptionInView = (menu, optionNode) => {
  let menuTop = menu.offsetTop;
  let menuScrollTop = menu.scrollTop;
  let menuBottom = menuTop + menu.offsetHeight;
  let optionTop = optionNode.offsetTop;
  let optionBottom = optionTop + optionNode.offsetHeight;
  let bottomDelta = optionBottom - menuBottom;
  let topDelta = optionTop - menuTop;

  if (optionBottom > menuBottom && bottomDelta > menuScrollTop) {
    menu.scrollTop = bottomDelta;
  } else if (menuScrollTop + menuTop > optionTop && topDelta < menuScrollTop) {
    menu.scrollTop = topDelta;
  }
};

export default ensureOptionInView;
