import createModel, { prop } from './lib/create_model';
const Audience = createModel({
  modelName: 'Audience',
  properties: {
    id: prop(String),
    name: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Audience;
