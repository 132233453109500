import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class IntegrationsService {
  constructor(database, pubsub) {
    this.getDatabase = database;
    this.pubsub = pubsub;
  }

  publish(topic, payload) {
    this.pubsub.publish(topic, (payload && { payload }) || {});
  }

  _getDb(callback, orgId) {
    const db = this.getDatabase(orgId);
    if (!db) {
      callback({ status: 404, statusText: statusText(404) });
      return undefined;
    }
    return db;
  }

  getIntegrations(attrs, callback, path, { orgId }) {
    const db = this._getDb(callback, orgId);
    if (db) {
      const data = db.integrationConfigurations || [];
      callback(null, { status: 200, statusText: statusText(200), response: data });
    }
  }

  addIntegration(attrs, callback, path, { orgId }) {
    const db = this._getDb(callback, orgId);
    if (db) {
      const data = db.integrationConfigurations || [];
      const existing = data.find(item => item.id === attrs.id);
      if (existing) {
        callback({ status: 400, statusText: statusText(400) });
        return;
      }

      const item = cloneDeep(attrs);
      db.integrationConfigurations.push(item);
      this.publish(`v1/orgs/${orgId}/integrations/${item.id}`, item);
      callback(null, { status: 200, statusText: statusText(200), response: item });
    }
  }

  updateIntegration(attrs, callback, path, { orgId, integrationId }) {
    const db = this._getDb(callback, orgId);
    if (db) {
      const data = cloneDeep(attrs);
      delete data.id;

      const existing = db.integrationConfigurations.find(item => item.id === integrationId);
      if (!existing) {
        callback({ status: 400, statusText: statusText(400) });
        return;
      }

      merge(existing, data);
      this.publish(`v1/orgs/${orgId}/integrations/${integrationId}`, existing);
      callback(null, { status: 204, statusText: statusText(204) });
    }
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/integrations': {
          GET: this.getIntegrations,
          POST: this.addIntegration,
        },
        '/api/v1/orgs/:orgId/integrations/:integrationId': {
          PATCH: this.updateIntegration,
        },
      },
      this
    );
  }
}
