import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AgentSearch from 'components/agents/agent_search';
import connect from 'components/lib/connect';
import SearchMenu, { SearchResult } from './search_menu';
import SearchPills from './search_pills';

const SelectedAgentsContainer = connect(mapStateToSelectedAgentsProps)(SearchPills);

function mapStateToSelectedAgentsProps({ getProvider }, { selectedAgentIds }) {
  const agentProvider = getProvider('agents');
  const currentAgent = getProvider('currentAgent').get();

  return {
    selectedItems: !agentProvider.isLoading()
      ? selectedAgentIds
          .map(id => agentProvider.findBy({ id }))
          .map(agent => {
            const you = agent.id === currentAgent.id ? ' (You)' : '';
            return {
              id: agent.id,
              label: `${agent.name || agent.email}${you}`,
            };
          })
      : [],
  };
}

export function AgentSearchMenu({ currentAgent, onChange, selectedAgentIds }) {
  const currentAgentName = currentAgent.name ? `${currentAgent.name} (You)` : 'You';
  return (
    <React.Fragment>
      <AgentSearch excludedAgentIds={selectedAgentIds}>
        {({ agentSearch }) => (
          <SearchMenu
            emptySelectedDefaultItems={[
              {
                email: currentAgent.email,
                id: currentAgent.id,
                name: currentAgentName,
              },
            ]}
            onAddItem={onAddItem}
            placeholder="Search for an agent"
            renderRow={renderRow}
            search={agentSearch}
            selectedItemIds={selectedAgentIds}
          />
        )}
      </AgentSearch>
      <SelectedAgentsContainer onDeleteItem={onDeleteItem} selectedAgentIds={selectedAgentIds} />
    </React.Fragment>
  );

  function renderRow({ item, onClickItem }) {
    return (
      <SearchResult id={item.id} key={item.id} onMouseDown={onClickItem} primary={item.name} secondary={item.email} />
    );
  }

  function onAddItem(id) {
    onChange({ agentIds: selectedAgentIds.concat(id) });
  }

  function onDeleteItem(id) {
    onChange({
      agentIds: _.filter(selectedAgentIds, selectedId => selectedId !== id),
    });
  }
}

AgentSearchMenu.propTypes = {
  currentAgent: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  selectedAgentIds: PropTypes.arrayOf(PropTypes.string),
};

const AgentSearchMenuContainer = connect(mapStateToSearchMenuProps)(AgentSearchMenu);
export default AgentSearchMenuContainer;

function mapStateToSearchMenuProps({ getProvider }, props) {
  const currentAgent = getProvider('currentAgent').get();
  const searchResult = getProvider('universalSearchResults').findBy({
    id: props.queryId,
  });
  const selectedAgentIds = searchResult ? _.get(searchResult, 'query.filter.agentIds') || [] : [];

  return {
    currentAgent,
    selectedAgentIds,
  };
}
