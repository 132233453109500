import React from 'react';
import styled from 'styled-components';

import { createSchema } from './schema';
import { decreaseListDepth } from './decrease_list_depth';
import { increaseListDepth } from './increase_list_depth';
import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';
import { normalizeNode } from './normalize_node';
import { deepRemoveList, toggleList } from './toggle_list';
import { getMarginFromIndent } from 'components/customer/composition/lib/slate/slate_text_indentation_menu';
import { getNearestList, getNearestListItem, getNonListParent, isList } from './utils';
import { getListDepth, hasText, reduceListDepth } from './list_utils';

export default function Lists() {
  return {
    commands: { increaseListDepth, decreaseListDepth, toggleList },

    normalizeNode,

    onKeyDown: (evt, editor, next) => {
      const { selection } = editor.value;

      if (evt.key === 'Tab' && isList(editor.value)) {
        evt.preventDefault();
        if (evt.shiftKey) return decreaseListDepth(editor, next);
        return increaseListDepth(editor, next);
      }
      if (evt.key === 'Backspace' && isList(editor.value)) {
        handleBackspace(editor, next);
        return;
      }
      if (evt.key === 'Enter' && isList(editor.value)) {
        evt.preventDefault();

        if (selection.isExpanded) {
          editor.delete();
        }

        if (evt.shiftKey) {
          event.preventDefault();
          editor.insertText('\n');
          return;
        }

        const itemNode = getNearestListItem(editor);
        if (itemNode) {
          const itemNodeNodes = itemNode.nodes;
          const isEmptyListItem = itemNodeNodes.count() === 1 && itemNodeNodes.first().text === '';
          if (isEmptyListItem) {
            const { value } = editor;
            const { document } = value;
            const parentList = document.getParent(itemNode.key);
            const parentListDepth = document.getDepth(parentList.key);
            const nonListParent = getNonListParent(editor, itemNode);
            const nonListParentDepth = document.getDepth(nonListParent.key);

            // If the distance between our parent list and the nearest non-list parent is greater than one, then we are
            // in a nested list, so we should decrease the list depth on enter before toggling the list off.
            if (parentListDepth - nonListParentDepth > 1) {
              return decreaseListDepth(editor, next);
            } else {
              editor.withoutNormalizing(() => {
                editor.unwrapNodeByKey(itemNode.key);
                editor.setNodeByKey(itemNode.key, { type: 'paragraph' });
              });
              return;
            }
          } else {
            editor.splitDescendantsByKey(itemNode.key, selection.start.key, selection.start.offset);
            return;
          }
        }
      }
      return next();
    },

    renderNode(props, editor, next) {
      const { attributes, children, node } = props;
      switch (node.type) {
        case ORDERED_LIST: {
          const indent = node.get('data').get('indent');
          const marginLeft = getMarginFromIndent(indent);
          return (
            <OrderedList {...attributes} style={{ marginLeft }}>
              {children}
            </OrderedList>
          );
        }
        case UNORDERED_LIST: {
          const indent = node.get('data').get('indent');
          const marginLeft = getMarginFromIndent(indent);
          return (
            <UnorderedList {...attributes} style={{ marginLeft }}>
              {children}
            </UnorderedList>
          );
        }
        case LIST_ITEM: {
          const textAlign = node.get('data').get('textAlign');
          return (
            <li {...attributes} style={{ textAlign }}>
              {children}
            </li>
          );
        }
        default:
          return next();
      }
    },

    schema: createSchema(),
  };
}

function handleBackspace(editor, next) {
  const { selection } = editor.value;
  if (selection.isExpanded) return next();
  if (selection.start.offset !== 0) return next();

  const itemNode = getNearestListItem(editor);
  const isAtBeginningOfListItem = itemNode && itemNode.nodes.count() === 1 && editor.value.selection.start.offset === 0;

  const listDepth = getListDepth(editor, itemNode);
  if (isAtBeginningOfListItem) {
    if (listDepth > 1 && hasText(itemNode)) {
      reduceListDepth(editor);
    } else {
      // Hack: don't know how to fix deepRemoveList for last nodes yet, so just reuse the backspace
      // functionality to remove it
      const listNode = getNearestList(editor);
      if (listNode.nodes.last() === itemNode) {
        editor.withoutNormalizing(() => {
          editor.unwrapNodeByKey(itemNode.key);
          editor.setNodeByKey(itemNode.key, { type: 'paragraph' });
        });
        return;
      }
      deepRemoveList(editor);
    }
  }
}

export const UnorderedList = styled.ul`
  margin: 0;
  padding: 5px 25px;
`;

export const OrderedList = styled.ol`
  margin: 0;
  padding: 5px 25px;
`;
