import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ExpandableSummary from 'components/customer/flexible_profile_card/components/expandable_summary';
import FlexibleItemDetails from 'components/customer/flexible_profile_card/components/flexible_item_details';
import FlexibleItemSummary from 'components/customer/flexible_profile_card/components/flexible_item_summary';
import { isRenderableItem } from 'components/customer/flexible_profile_card/lib/is_renderable_item';

/**
 * This is the main building block of the "flexible card". When the external data is loaded, each "data row"
 * is rendered as a FlexibleCardBodyItem - which may include a collapsible summary and details parts if
 * configured.
 *
 * When rendering, the component will check the configuration against the data and skip the parts that
 * don't have any renderable data. If none of the configured elements have renderable data, the component
 * will not render.
 *
 * @param {object} cardConfig - top-level card configuration block
 * @param {string} [className] - optional class name applied to the top level component element
 * @param {object} itemData - data record to render
 * @return {JSX.Element|null}
 * @constructor
 */
export default function FlexibleCardBodyItem({ cardConfig, className, itemData }) {
  if (_.isEmpty(cardConfig) || _.isEmpty(itemData)) return null;

  const summaryConfig = _.get(cardConfig, 'displayConfiguration.itemSummary');
  const detailsConfig = _.get(cardConfig, 'displayConfiguration.itemDetails');
  const hasSummary = !_.isEmpty(_.get(summaryConfig, 'attributes'));

  const isRenderable = isRenderableItem(detailsConfig, itemData);
  const itemSummary = hasSummary ? <FlexibleItemSummary itemData={itemData} summaryConfig={summaryConfig} /> : null;
  const itemRenderFlags = { hasTopTriangle: hasSummary, hasShadow: hasSummary };
  const itemDetails = isRenderable ? (
    <FlexibleItemDetails itemData={itemData} itemDetailsConfig={detailsConfig} renderFlags={itemRenderFlags} />
  ) : null;

  // Do we have anything to render?
  if (!itemSummary && !itemDetails) return null;

  return hasSummary ? (
    <ExpandableSummary className={className} summary={itemSummary}>
      {itemDetails}
    </ExpandableSummary>
  ) : (
    <div className={className}>{itemDetails}</div>
  );
}

FlexibleCardBodyItem.propTypes = {
  cardConfig: PropTypes.object.isRequired,
  itemData: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  className: PropTypes.string,
};
