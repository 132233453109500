import { findIndex, find, escapeRegExp, sortBy } from 'lodash';

import KbVariable, { getFirstName, getLastName, getTrimmedName } from 'models/kb_variable';
import { getLatestConversationId } from '../conversation/lib/conversation_helpers';
import GreetingSuggestion, { getTopGreetingSuggestion } from 'models/greeting_suggestion';
import { hasAgentSentMessage } from 'components/composer/contexts/greeting_suggestions_context.jsx';

export const MAX_LOCAL_SUGGESTIONS = 25;

export default class AddGreetingSuggestion {
  constructor(context) {
    this.context = context;
  }

  run({ messageText, customerId }) {
    if (!messageText) {
      return;
    }

    const customerStores = this.context.stores.customers.storesFor(customerId);

    const latestConversationId = getLatestConversationId(customerStores.conversations);
    const agentHasSentMessage = hasAgentSentMessage(
      customerStores.conversationHistory,
      latestConversationId,
      this.context.stores.currentAgent.get().id
    );

    const profile = customerStores.profile.get();

    if (agentHasSentMessage) {
      return;
    }

    const textWithoutCustomerName = replaceCustomerNameWithVariable(messageText, profile);
    const greetingSuggestions = this.context.stores.greetingSuggestions.findAll();
    let matchingSuggestion = this.findMatchingSuggestion(textWithoutCustomerName, greetingSuggestions);

    if (matchingSuggestion) {
      const topSuggestion = getTopGreetingSuggestion(greetingSuggestions);
      if (topSuggestion.id === matchingSuggestion.id && topSuggestion.score >= 5) {
        // we've already updated the score for this greeting when the agent inserted it into the composition
        return;
      }
      matchingSuggestion.incrementScore();
      this.context.stores.greetingSuggestions.addOrReplace(matchingSuggestion);
      const localSuggestions = this.context.stores.localGreetingSuggestions.get();
      const existingLocalIndex = findIndex(
        localSuggestions,
        localSuggestion => localSuggestion.id === matchingSuggestion.id
      );
      localSuggestions[existingLocalIndex] = matchingSuggestion.toJs();
      this.context.stores.localGreetingSuggestions.set(localSuggestions);
    } else {
      let newGreeting = GreetingSuggestion.create({ text: textWithoutCustomerName, score: 1 });
      this.context.stores.greetingSuggestions.addOrReplace(newGreeting);

      let localSuggestions = this.context.stores.localGreetingSuggestions.get();
      if (localSuggestions.length >= MAX_LOCAL_SUGGESTIONS) {
        const sortedByScore = sortBy(localSuggestions, s => -s.score);
        localSuggestions = sortedByScore.slice(0, MAX_LOCAL_SUGGESTIONS - 1);
      }

      localSuggestions.push(newGreeting.toJs());
      this.context.stores.localGreetingSuggestions.set(localSuggestions);
    }
  }

  findMatchingSuggestion(text, suggestions) {
    const punctuationRegex = /[!'"#$%&()*+,-./:;<=>?@[\]^_`{|}~\s]/g;
    let cleanText = text.toLowerCase().replace(punctuationRegex, '');
    return find(suggestions, s => {
      let cleanSuggestion = s.text.toLowerCase().replace(punctuationRegex, '');
      return cleanSuggestion === cleanText;
    });
  }
}

export function replaceCustomerNameWithVariable(text, profile) {
  if (!profile.name || !text) {
    return text;
  }

  const fullName = getTrimmedName(profile.name);
  const firstName = getFirstName(profile.name);
  const lastName = getLastName(profile.name);

  let cleanedText = text;

  // If there's only a first name, we use the first name variable
  if (fullName && fullName !== firstName) {
    const fullNameRegex = createNameRegex(fullName);
    cleanedText = cleanedText.replace(
      fullNameRegex,
      `<variable data-type="${KbVariable.Type.CUSTOMER_NAME}">customer_name</variable>`
    );
  }

  if (firstName) {
    const firstNameRegex = createNameRegex(firstName);
    cleanedText = cleanedText.replace(
      firstNameRegex,
      `<variable data-type="${KbVariable.Type.CUSTOMER_FIRST_NAME}">customer_first_name</variable>`
    );
  }

  // if there's no last name in the profile, getLastName returns the first name
  if (lastName && lastName !== firstName) {
    const lastNameRegex = createNameRegex(lastName);
    cleanedText = cleanedText.replace(
      lastNameRegex,
      `<variable data-type="${KbVariable.Type.CUSTOMER_LAST_NAME}">customer_last_name</variable>`
    );
  }

  return cleanedText;
}

function createNameRegex(name) {
  // If the last character in the name is not a "word" character using we need to use \B instead of \b to detect word boundaries correctly
  let lastChar = name.charAt(name.length - 1);
  let regex = escapeRegExp(name);
  if (lastChar.match(/\w/)) {
    regex = `${regex}\\b`;
  } else {
    regex = `${regex}\\B`;
  }

  let firstChar = name.charAt(0);
  if (firstChar.match(/\w/)) {
    regex = `\\b${regex}`;
  } else {
    regex = `\\B${regex}`;
  }

  return new RegExp(regex, 'gi');
}
