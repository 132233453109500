import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Agent from 'models/agent';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import { getAgentName } from '../metadata/agent_metadata';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';

export function CustomerMergeItem({ agent, conversationsMerged, currentAgentId, customerName, item }) {
  const agentName = getAgentName(agent, currentAgentId);
  let profileMergeText = 'merged two customers together';
  if (customerName) {
    profileMergeText = `merged a customer into ${customerName}`;
  }

  return (
    <React.Fragment>
      <ItemContent data-aid="customerMergeProfileItem" isAuditItem item={item}>
        <ItemMetadata
          data-aid="customerMergeProfileItemMetadata"
          item={item}
          subject={agentName}
          text={profileMergeText}
        />
      </ItemContent>
      {conversationsMerged ? (
        <ItemContent data-aid="customerMergeConversationItem" isAuditItem item={item}>
          <ItemMetadata
            data-aid="customerMergeConversationItemMetadata"
            item={item}
            subject=""
            text="Conversations were merged during the customer merge"
          />
        </ItemContent>
      ) : null}
    </React.Fragment>
  );
}

CustomerMergeItem.propTypes = {
  agent: PropTypes.instanceOf(Agent),
  conversationsMerged: PropTypes.bool.isRequired,
  currentAgentId: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function mapStateToProps({ getProvider }, props) {
  const currentAgentId = getProvider('currentAgent').get().id;
  const agent = getProvider('agents').findBy({ id: _.get(props, 'item.initiator.id') });

  const currentCustomer = getProvider('profile').get();
  let customerName = '';
  if (_.get(props, 'item.content.destCustomerId') === currentCustomer.id) {
    customerName = _.get(currentCustomer, 'name');
  }
  return {
    ...props,
    agent,
    conversationsMerged: !!_.get(props, 'item.content.sourceConversationId'),
    currentAgentId,
    customerName,
  };
}

const CustomerMergeItemContainer = connect(mapStateToProps)(CustomerMergeItem);
CustomerMergeItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default CustomerMergeItemContainer;
