import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';

import { ActionType } from './chat_customer_url';

export const TimelineEventType = createEnum(
  'CAMPAIGN_TRIGGERED',
  'QUICK_ANSWER_VIEWED',
  'SEARCH_RESULT_VIEWED',
  'UNANSWERED_SEARCH'
);

export const TimelineEventCampaignContent = createModel({
  modelName: 'TimelineEventCampaignContent',
  properties: {
    campaignId: String,
    campaignName: String,
    campaignDescription: String,
  },
});

export const TimelineEventSearchResultViewedContent = createModel({
  modelName: 'TimelineEventSearchResultViewedContent',
  properties: {
    answerId: String,
    answerName: String,
    searchTerm: String,
  },
});

export const TimelineEventQuickAnsweredViewedContent = createModel({
  modelName: 'TimelineEventQuickAnsweredViewedContent',
  properties: {
    answerId: String,
    answerName: String,
  },
});

export const TimelineEventUnansweredSearchContent = createModel({
  modelName: 'TimelineEventUnansweredSearchContent',
  properties: {
    searchTerm: String,
  },
});

export const TimelineEventContentMap = {
  [TimelineEventType.CAMPAIGN_TRIGGERED]: TimelineEventCampaignContent,
  [TimelineEventType.QUICK_ANSWER_VIEWED]: TimelineEventQuickAnsweredViewedContent,
  [TimelineEventType.SEARCH_RESULT_VIEWED]: TimelineEventSearchResultViewedContent,
  [TimelineEventType.UNANSWERED_SEARCH]: TimelineEventUnansweredSearchContent,
};

export const ChatSelfServiceTimelineEvent = createModel({
  modelName: 'ChatSelfServiceTimelineEvent',
  properties: {
    createdAt: String,
    type: prop().oneOf(..._.keys(TimelineEventType)),
    content: prop().oneOf(..._.values(TimelineEventContentMap)),
  },
  statics: {
    overrideFromJs(originalFromJs) {
      return data => {
        let event = originalFromJs(_.omit(data, 'content'));
        let content = TimelineEventContentMap[event.type].fromJs(data.content);
        return new this({ ...event, content });
      };
    },
  },
});

const ChatSelfServiceMetadata = createModel({
  modelName: 'ChatSelfServiceMetadata',
  properties: {
    action: prop().oneOf(..._.keys(ActionType)),
    browser: String,
    customerUrl: String,
    device: String,
    greeting: String,
    timelineEvents: prop([ChatSelfServiceTimelineEvent]).default([]),
  },

  statics: {
    create(attrs) {
      return new this({ ...attrs });
    },
    overrideFromJs(fromJs) {
      return attrs => {
        // initially process SS timeline into usable format
        return fromJs({ ...attrs });
      };
    },
  },
});

export default ChatSelfServiceMetadata;
