import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import keys from 'lodash/keys';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import { H2 } from 'components/common/headers';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';

/**
 * @visibleName Confirm or Cancel Modal
 */

export default function ConfirmOrCancelModal({
  cancelText = 'Cancel',
  children,
  className,
  confirmOnDismiss,
  onCancel,
  onSubmit,
  submitButtonType = ButtonTypes.PRIMARY,
  cancelButtonType = ButtonTypes.TEXT,
  submitText = 'Confirm',
  text,
  title,
  'data-aid': dataAid,
}) {
  // Prevent scrolling body underneath open modal
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = '');
  }, []);

  const onClickSend = useCallback(evt => onSubmit(evt, true), [onSubmit]);
  dataAid = dataAid ? `confirmOrCancelModal-${dataAid}` : 'confirmOrCancelModal';
  return (
    <PortalModalWrapper className={className}>
      <Modal data-aid={dataAid}>
        <ModalCard onClose={confirmOnDismiss ? onSubmit : onCancel}>
          <ConfirmOrCancelCard>
            <Close onClick={confirmOnDismiss ? onSubmit : onCancel}>
              <FontAwesomeIcon icon={faTimes} />
            </Close>
            <Header data-aid="title">{title}</Header>
            {text && <p data-aid="text">{text}</p>}
            {children}
            <Actions>
              <Button
                buttonType={cancelButtonType}
                className={onSubmit ? 'confirmOrCancelModal-cancelButton' : ''}
                data-aid="confirmOrCancelModal-cancel"
                onClick={onCancel}
              >
                {cancelText}
              </Button>
              {onSubmit && (
                <Button
                  buttonType={submitButtonType}
                  className="confirmOrCancelModal-confirmButton"
                  data-aid="confirmOrCancelModal-confirm"
                  onClick={onClickSend}
                >
                  {submitText}
                </Button>
              )}
            </Actions>
          </ConfirmOrCancelCard>
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

const ConfirmOrCancelCard = styled.div`
  padding: ${p => p.theme.spacing.large};
`;

export const Close = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  position: absolute;
  right: 0px;
  top: 0px;
  &:hover {
    color: ${p => p.theme.colors.gray600};
  }
`;

export const Header = styled(H2)`
  display: inline;
`;

export const Modal = styled.div`
  text-align: center;
  .modalCard-content {
    padding: ${p => p.theme.spacing.large};
    position: relative;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => p.theme.spacing.large};
  .confirmOrCancelModal-cancelButton {
    color: ${p => p.theme.colors.gray600};
  }
  .confirmOrCancelModal-confirmButton {
    margin-left: ${p => p.theme.spacing.large};
  }
`;

ConfirmOrCancelModal.propTypes = {
  cancelButtonType: PropTypes.oneOf(keys(ButtonTypes)),
  /** Text that is displayed on the cancel button */
  cancelText: PropTypes.string,
  /** Optional child components of the confirm and cancel modal */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** If true, execute onSubmit when modal dismissed */
  confirmOnDismiss: PropTypes.bool,
  /** String of CSS class names to allow custom styling */
  className: PropTypes.string,
  /** onCancel function executed when cancel button clicked */
  onCancel: PropTypes.func,
  /** onSubmit function executed when cancel button clicked */
  onSubmit: PropTypes.func,
  /** ButtonType enum that defines submit button styling, one of: `PRIMARY`, `SECONDARY`, `OUTLINE`, `TEXT`, `DANGER` */
  submitButtonType: PropTypes.oneOf(keys(ButtonTypes)),
  /** Text that is displayed on the submit button */
  submitText: PropTypes.string,
  /** The brief explanatory text that appears below the header */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The main title text of the modal's header, phrase the title as a question like: `Permanently remove this item?` */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
