import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Show Fill
 */

export default function ShowFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M2.34226 5.35728C5.46645 2.23308 10.5318 2.23308 13.656 5.35727L15.8518 7.55309C16.047 7.74835 16.047 8.06493 15.8518 8.2602L13.656 10.456C10.5318 13.5802 5.46645 13.5802 2.34226 10.456L0.146447 8.2602C-0.0488154 8.06493 -0.0488157 7.74835 0.146446 7.55309L2.34226 5.35728ZM10.3201 7.82417C10.3201 9.10606 9.28097 10.1452 7.99908 10.1452C6.71719 10.1452 5.67801 9.10606 5.67801 7.82417C5.67801 6.54228 6.71719 5.5031 7.99908 5.5031C9.28097 5.5031 10.3201 6.54228 10.3201 7.82417ZM7.9991 8.98473C8.64004 8.98473 9.15963 8.46514 9.15963 7.82419C9.15963 7.18325 8.64004 6.66366 7.9991 6.66366C7.35815 6.66366 6.83856 7.18325 6.83856 7.82419C6.83856 8.46514 7.35815 8.98473 7.9991 8.98473Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
