import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/icons/icon';

export default function SubTopicConnector(props) {
  return (
    <StyledIcon fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 0V9C1 10.1046 1.89543 11 3 11H12" stroke="#B3B3B3" strokeLinejoin="round" />
    </StyledIcon>
  );
}

const StyledIcon = styled(Icon)`
  margin-right: ${p => p.theme.spacing.medium};
  width: ${p => p.theme.fontSize.base};
`;
