import _ from 'lodash';
import createModel, { prop } from './lib/create_model';

import { NotificationSoundType } from 'actions/notification/lib/notification_constants';

const AgentPreferences = createModel({
  modelName: 'AgentPreferences',

  properties: {
    agentNotificationsLastOpenedAt: prop(String),
    answerPanelLastOpenedAt: prop(String),
    answerSearchAudiences: prop([String]),
    answerSearchLanguage: prop(String),
    isTaskCommentFormattingOpen: prop(Boolean),
    shouldCollapseSidebar: prop(Boolean),

    readFeatureNotifications: prop(Object).default({}),
    agentNotificationSound: prop(String).default(NotificationSoundType.AGENT_NOTIFICATION),

    // Use upgraded Slate editors
    experimentalEditorEnabled: prop(Boolean).default(false),

    // Display conversation summarization controls
    conversationSummaryControlsEnabled: prop(Boolean).default(false),

    favoriteAnswerIds: prop([String]).default([]),

    seenFeatureTips: prop(Object).default({}),
  },

  setAnswerPanelLastOpenedAt(lastOpenedAt) {
    this.answerPanelLastOpenedAt = lastOpenedAt;
  },

  setAnswerSearchLanguage(language) {
    this.answerSearchLanguage = language;
  },

  setAnswerSearchAudiences(audiences) {
    this.answerSearchAudiences = audiences;
  },

  setAgentNotificationsLastOpenedAt(lastOpenedAt) {
    this.agentNotificationsLastOpenedAt = lastOpenedAt;
  },

  toggleShouldCollapseSidebar() {
    this.shouldCollapseSidebar = !this.shouldCollapseSidebar;
  },

  toggleIsTaskCommentFormattingOpen() {
    this.isTaskCommentFormattingOpen = !this.isTaskCommentFormattingOpen;
  },

  hasSeenFeatureNotification(feature) {
    return !!this.readFeatureNotifications[feature];
  },

  markFeatureAsSeen(feature) {
    this.readFeatureNotifications[feature] = true;
  },

  setAgentNotificationSound(sound) {
    this.agentNotificationSound = sound;
  },

  toggleExperimentalEditor() {
    this.experimentalEditorEnabled = !this.experimentalEditorEnabled;
  },

  toggleConversationSummaryControls() {
    this.conversationSummaryControlsEnabled = !this.conversationSummaryControlsEnabled;
  },

  isAnswerFavorited(id) {
    return !!_.find(this.favoriteAnswerIds, favId => favId === id);
  },

  toggleFavoriteAnswer(id) {
    if (this.isAnswerFavorited(id)) {
      this.favoriteAnswerIds = _.filter(this.favoriteAnswerIds, favId => favId !== id);
    } else {
      this.favoriteAnswerIds.push(id);
    }
  },

  hasSeenFeatureTip(feature) {
    return !!this.seenFeatureTips[feature];
  },

  markFeatureTipAsSeen(feature) {
    this.seenFeatureTips[feature] = true;
  },
});

export default AgentPreferences;
