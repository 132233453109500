import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import createEnum from 'scripts/lib/create_enum';

export const DisconnectReason = createEnum('NETWORK_ISSUE', 'SERVER_ISSUE');

/*
In addition to the 3 Twilio Device statuses: ('BUSY', 'OFFLINE', 'READY'), we use 'CONNECTING' to identify when the Twilio device is attempting to initially setup and 'RECONNECTING' to identify when the device token has expired and is fetching a new capability token. These statuses are used to determine if agents can handle calls or are shown error messages
*/
export const TwilioDeviceStatus = createEnum('BUSY', 'CONNECTING', 'OFFLINE', 'READY', 'RECONNECTING');

export const ConnectionState = createModel({
  modelName: 'ConnectionState',

  properties: {
    isDisconnected: prop(Boolean).default(false),
    status: prop(String).default(''),
  },

  setConnected() {
    this.isDisconnected = false;
  },

  setDisconnected() {
    this.isDisconnected = true;
  },

  updateStatus(status) {
    this.status = status;
  },
});

export default createModel({
  modelName: 'ConnectionStates',

  properties: {
    // is the mqtt gateway connected
    mqtt: prop(ConnectionState).default(() => new ConnectionState()),
    // is the browser connected to the network
    network: prop(ConnectionState).default(() => new ConnectionState()),
    // is the browser ready to make/receive phone calls
    twilio: prop(ConnectionState).default(() => new ConnectionState()),
    // should twilio status be checked
    isTwilioEnabled: prop(Boolean).default(false),
  },

  // returns true if any connection state is disconnected
  isDisconnected() {
    return this.network.isDisconnected || this.mqtt.isDisconnected || this.isTwilioDisconnected();
  },

  isTwilioDisconnected() {
    return this.isTwilioEnabled && this.twilio.isDisconnected;
  },

  getDisconnectReason() {
    if (this.network.isDisconnected || this.isTwilioDisconnected()) {
      return DisconnectReason.NETWORK_ISSUE;
    } else if (this.mqtt.isDisconnected) {
      return DisconnectReason.SERVER_ISSUE;
    }
  },

  setTwilioEnabled() {
    this.isTwilioEnabled = true;
  },

  setTwilioDisabled() {
    this.isTwilioEnabled = false;
  },

  getDisconnectedServices() {
    return ['mqtt', 'network', 'twilio'].filter(s => {
      return s === 'twilio' ? this.isTwilioDisconnected() : this[s].isDisconnected;
    });
  },

  updateTwilioStatus(status) {
    if (_.keys(TwilioDeviceStatus).indexOf(status) === -1) {
      return;
    }
    this.twilio.updateStatus(status);
  },

  isBrowserPhoneUnavailable() {
    return this.twilio.status === TwilioDeviceStatus.OFFLINE;
  },

  isBrowserPhoneAvailable() {
    return (
      this.twilio.status === TwilioDeviceStatus.READY ||
      this.twilio.status === TwilioDeviceStatus.BUSY ||
      this.isBrowserPhoneConnecting()
    );
  },

  isBrowserPhoneConnecting() {
    return (
      this.twilio.status === TwilioDeviceStatus.CONNECTING || this.twilio.status === TwilioDeviceStatus.RECONNECTING
    );
  },

  getBrowserPhoneStatus() {
    return this.twilio.status;
  },

  statics: {
    create() {
      return new this();
    },
  },
});
