import createModalModel from './lib/create_modal_model';
import { prop } from 'models/lib/create_model';

export default createModalModel({
  modelName: 'CreditCardAmount',

  properties: {
    amount: prop(Number).isRequired,
    customerName: prop(String).isRequired,
    memo: String,
  },
});
