import DismissNotification from 'actions/notification/dismiss_notification';
import { createAgentHasDismissedNotification, multipleTabsNotificationId, postMessage } from './multiple_tabs_helpers';

export default class DismissMultipleTabsNotification {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.executeAction(DismissNotification, multipleTabsNotificationId);
    createAgentHasDismissedNotification();
    postMessage({ type: 'dismiss' });
  }
}
