import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';
import ServerClock from 'scripts/application/lib/server_clock';

export default class OpenNotificationsMenu {
  constructor(context) {
    this.context = context;
  }

  run() {
    const preferences = this.context.stores.agentPreferences.get();
    preferences.setAgentNotificationsLastOpenedAt(ServerClock.toISOString());
    setAgentPreferences(this.context, preferences);
  }
}
