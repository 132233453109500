import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import KeywordGroups from 'models/location/keyword_groups';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenKeywordGroups {
  constructor(context) {
    this.context = context;
  }

  run() {
    changeCurrentLocation(this.context, KeywordGroups.create());

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.KEYWORD_GROUPS_ADMIN });
  }
}
