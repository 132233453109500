import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import PhoneCall from 'models/phone_call';

export default function createPhoneCallItem({ agentId, conversationId, content = {}, customerId }) {
  return ConversationItem.create({
    conversationId,
    content: PhoneCall.create(content),
    customerId,
    initiator: {
      id: agentId,
      type: Communicator.AGENT,
    },
  });
}
