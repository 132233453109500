import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Dotdotdot from 'react-dotdotdot';
import HtmlToText from 'html-to-text';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { AttachmentTypeIcon } from 'components/lib/attachment/attachment_type';
import Snippet, { SnippetContentType } from 'models/answers/snippet';

const ENTER = 13;
const ARROW_UP = 38;
const ARROW_DOWN = 40;

const INITIAL_WORDS = 4;
const WORDS_BEFORE = 3;

const PROMPT_HEIGHT = 44;
const MENU_MARGIN = 5;
const LINE_HEIGHT = 13;
const MAX_HEIGHT = 350;

const RESIZE_INTERVAL_MS = 100;

// Order matters - the sort order of filtered items depends on the order of params.
const FILTER_PARAMS = ['name', 'plaintext'];
const MAX_EMPTY_ATTEMPTS = 4;
export const MAX_ITEMS = 30;

/**
 * `MentionMenu` is a React component that provides @mention-like things, such as snippets and variables.
 * It is to be used in conjuction with a text field - it does not manipulate the text field directly,
 * but instead provides `onTextInsertion` to notify when (and what) text was inserted via the menu.
 *
 * Usage:
 * <MentionMenu items={this.snippetItems}
 *     cursorIndex={this.cursorIndex}
 *     text={searchText}
 *     getPixelsAtIndex={this.getPixelsAtIndex}
 *     onTextInsertion={this.onTextInsertion} />
 *
 * items is an array of `item`s, where an `item` has the following props:
 *   - name: string, required. will be displayed in the menu
 *   - plaintext: string. will be displayed in the menu under name
 *   - text: string. will be used for inline previews
 *   - isDisabled: boolean, optional. if true, then it cannot be inserted
 *
 * Filtered items will appear in the menu first in order passed into items, then in FILTER_PARAM order,
 * and then alphabetically. We limit the number of items shown to MAX_ITEMS.
 */

const MentionMenu = createReactClass({
  propTypes: {
    getPixelPosition: PropTypes.func.isRequired,
    isInsertingAnswerLink: PropTypes.bool,
    items: PropTypes.array.isRequired,
    onCancel: PropTypes.func,
    onClearPreview: PropTypes.func,
    onSearchAnswerMenu: PropTypes.func,
    onTextInsertion: PropTypes.func,
    onTextPreview: PropTypes.func,
    suggestedItems: PropTypes.arrayOf(PropTypes.string),
    text: PropTypes.string, // null if no current mention
  },

  /* Init */

  getDefaultProps() {
    return {
      suggestedItems: [],
    };
  },

  getInitialState() {
    const filteredItems =
      this.props.text || this.props.suggestedItems.length > 0
        ? this.getFilteredItems(
            this.getNormalizedSearchText(this.props.text),
            this.props.items,
            this.props.suggestedItems
          )
        : [];
    const renderedItems = filteredItems.map(item => this.renderNameAndContent(this.props.text, item));

    return {
      emptyAttempts: 0, // Number of times user has attempted a text search but found no results
      filteredItems,
      previousText: '',
      renderedItems,
      selectedIndex: 0,
    };
  },

  /* Lifecycle */

  componentDidMount() {
    this.forceRender = _.throttle(this.forceRender, RESIZE_INTERVAL_MS);
    window.addEventListener('resize', this.forceRender);
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.forceRender);
  },

  UNSAFE_componentWillReceiveProps(props) {
    if (props.text === null) {
      this.setState({ emptyAttempts: 0, filteredItems: [], previousText: '' });
      return;
    }

    const filteredItems = this.getFilteredItems(
      this.getNormalizedSearchText(props.text),
      props.items,
      props.suggestedItems
    );

    if (props.isInsertingAnswerLink && props.text !== this.props.text) {
      this.onSearchAnswerMenu(this.getNormalizedSearchText(props.text));
    }

    // The menu is already displayed, but we need to potentially change the selected menu item
    let selectedIndex = this.state.selectedIndex;
    if (!_.isEqual(filteredItems, this.state.filteredItems)) {
      // Select the first non-disabled item if the filtered list changes.
      for (let i = 0; i < filteredItems.length; i++) {
        if (!filteredItems[i].isDisabled) {
          selectedIndex = i;
          break;
        }
      }
    }

    let emptyAttempts =
      props.text.length > this.state.previousText.length && filteredItems.length === 0
        ? this.state.emptyAttempts + 1
        : 0;
    let previousText = props.text;

    // Render the item content here rather than in the render method so that we don't have to recalculate
    // highlights when something unrelated changes, such as the selected index.
    const renderedItems = filteredItems.map(item => this.renderNameAndContent(props.text, item));
    this.setState({
      emptyAttempts,
      filteredItems,
      previousText,
      renderedItems,
      selectedIndex,
    });
  },

  componentDidUpdate(prevProps, prevState) {
    if (!this.refs.menu) {
      if (prevState.emptyAttempts >= MAX_EMPTY_ATTEMPTS - 1) {
        this.cancelMenu();
      }
      return;
    }

    // Position the menu above the activation character
    let node = ReactDOM.findDOMNode(this.refs.menu);
    const cursorPixels = this.props.getPixelPosition();
    let listNode = ReactDOM.findDOMNode(this.refs.menuList);
    let desiredHeight = this.shouldShowPrompt() ? PROMPT_HEIGHT : Math.min(listNode.offsetHeight, MAX_HEIGHT);

    let menuTop =
      cursorPixels.top > MAX_HEIGHT
        ? cursorPixels.top - desiredHeight - MENU_MARGIN
        : cursorPixels.top + LINE_HEIGHT + MENU_MARGIN;
    let menuHeight = desiredHeight;

    // If the top of the menu is close to the top to the screen or above, lower it so it's not then
    // reduce its height correspondingly.
    if (menuTop < MENU_MARGIN) {
      menuHeight = desiredHeight + menuTop - MENU_MARGIN;
      menuTop = MENU_MARGIN;
    }

    node.style.top = `${menuTop}px`;
    node.style.height = `${menuHeight}px`;

    let defaultMenuLeft = cursorPixels.left + 10;
    node.style.left = `${
      defaultMenuLeft + node.offsetWidth < window.innerWidth ? defaultMenuLeft : defaultMenuLeft - node.offsetWidth
    }px`;

    if (this.state.selectedIndex !== prevState.selectedIndex) {
      this.scrollToItem(this.state.selectedIndex);
    }
  },

  /* Render */

  render() {
    if (this.shouldShowPrompt()) {
      const promptMessage = this.props.isInsertingAnswerLink
        ? 'Start typing to search for a reference answer to link. Hit escape to close.'
        : 'Start typing to search for a variable. Hit escape to close.';
      return (
        <div className="mentionMenu mentionMenu-prompt" ref="menu">
          {promptMessage}
        </div>
      );
    }

    const filteredItems = this.state.filteredItems;
    if (this.props.text === null || !filteredItems.length) {
      return null;
    }

    return (
      <div className="mentionMenu" ref="menu">
        <ul className="mentionMenu-list" ref="menuList">
          {this.shouldShowSuggestedItems() && <div className="mentionMenu-suggested">Suggested Answers</div>}
          {filteredItems.map(this.renderItem)}
        </ul>
      </div>
    );
  },

  renderNameAndContent(text, item) {
    let plaintext = '';
    if (this.isAnswer(item)) {
      let content = item.findContentByLanguage();
      plaintext = HtmlToText.fromString(content.getBodyByType(SnippetContentType.INFO));
    }
    const searchText = this.getNormalizedSearchText(text);
    const abbrevText = this.abbreviatedText(plaintext, this.findFirstMatch(searchText, plaintext));

    let params = ['name'];
    // Highlighting plaintext with 1 character slows the menu down a lot.
    if (searchText.length > 1) {
      params.push('plaintext');
    }

    const highlights = this.getHighlights(searchText, { name: item.name, plaintext: abbrevText }, params);

    return {
      name: this.highlightedText(item.name, highlights.name),
      content:
        searchText.length > 1 ? this.highlightedText(abbrevText, highlights.plaintext) : <span>{abbrevText}</span>,
    };
  },

  renderItem(item, index) {
    const classNames = classnames('mentionMenu-item', {
      active: index === this.state.selectedIndex,
      disabled: item.isDisabled,
    });

    const nameClassNames = classnames('mentionMenu-item-name', {
      'mentionMenu-item-name-answer': this.isAnswer(item),
    });

    const name = this.state.renderedItems[index].name;
    const content = this.state.renderedItems[index].content;

    return (
      <li
        className={classNames}
        key={index}
        onMouseDown={this.onMouseDown.bind(null, index)}
        onMouseOver={this.onMouseOver.bind(null, index)}
      >
        <div className="mentionMenu-item-header">
          <p className={nameClassNames}>{name}</p>
          {(item.attachments && (
            <div className="mentionMenu-item-attachmentIcons">
              {item.attachments.map(a => (
                <AttachmentTypeIcon key={a.id} type={a.fileDescriptor().contentType} />
              ))}
            </div>
          )) ||
            null}
          <Dotdotdot clamp={2} className="mentionMenu-item-content">
            {content}
          </Dotdotdot>
        </div>
      </li>
    );
  },

  /* Helpers */

  getNormalizedSearchText(searchText) {
    return searchText ? searchText.replace(/\W+/g, '').toLowerCase() : '';
  },

  getFilteredItems(searchText, items, suggestedItems) {
    if (searchText === '' && suggestedItems && suggestedItems.length > 0) {
      return _.filter(items, item => suggestedItems.indexOf(item.id) !== -1);
    }

    if (searchText.length === 0) {
      return [];
    }

    let filteredItems = [];

    for (let i = 0; i < FILTER_PARAMS.length; i++) {
      const param = FILTER_PARAMS[i];

      for (let j = 0; j < items.length; j++) {
        const item = items[j];

        if (this.findFirstMatch(searchText, item[param]) && !_.includes(filteredItems, item)) {
          filteredItems.push(item);
          if (filteredItems.length >= MAX_ITEMS) {
            return filteredItems;
          }
        }
      }
    }

    return filteredItems;
  },

  onSearchAnswerMenu(searchText) {
    if (searchText.length === 0) {
      return;
    }

    this.props.onSearchAnswerMenu(searchText);
  },

  isAnswer(item) {
    return item instanceof Snippet;
  },

  findFirstMatch(searchText, text) {
    return text && this.searchRegex(searchText).exec(text.toLowerCase());
  },

  // Return an object mapping item params to a list of [start, end) character indices of
  // matches for that param.
  getHighlights(searchText, item, params) {
    const paramMatches = this.findMatches(searchText, item, params);

    return paramMatches.reduce((highlight, paramMatch) => {
      highlight[paramMatch.param] = paramMatch.matches.map(match => {
        return { start: match.index, end: match.index + match[0].length };
      });
      return highlight;
    }, {});
  },

  findMatches(searchText, item, params) {
    if (searchText === '') {
      return [];
    }

    const searchRegex = this.searchRegex(searchText);

    return _.compact(
      _.map(params, param => {
        const itemText = item[param].toLowerCase();
        let match = searchRegex.exec(itemText);

        let matches = [];
        while (match !== null) {
          matches.push(match);
          match = searchRegex.exec(itemText);
        }
        return matches.length > 0 ? { param, matches } : null;
      })
    );
  },

  searchRegex(text) {
    const matchRe = text
      .toLowerCase()
      .split('')
      .join('\\W*'); // Search text "heLlO" should match for item text "h e   l l o"
    return new RegExp(`(${matchRe})`, 'g');
  },

  highlightedText(text, highlights) {
    let textChunks = [];
    let cursor = 0;

    _.forEach(highlights, (highlight, index) => {
      textChunks.push(<span key={`item-text-${index}`}>{text.slice(cursor, highlight.start)}</span>);
      textChunks.push(
        <span className="mentionMenu-item-highlight" key={`item-highlight-${index}`}>
          {text.slice(highlight.start, highlight.end)}
        </span>
      );
      cursor = highlight.end;
    });

    if (cursor < text.length) {
      textChunks.push(<span key={'item-text-trailing'}>{text.slice(cursor, text.length)}</span>);
    }

    return textChunks;
  },

  /**
   * Return a shortened version of the text. It always includes a few words from the beginning of the text,
   * as well as several words before the match range (and then extends to the end of the text).
   *
   * E.g. text = "Hello there, how are you doing this fine evening? What would you like to eat for dinner?", match on "like"
   *      => "Hello there, how are... What would you like to eat for dinner?"
   */
  abbreviatedText(text, match) {
    if (!match) {
      return text;
    }

    const words = text.split(' ');
    const matchedWordRange = this.matchedWordRange(words, match);

    let initialRange = { start: 0, end: INITIAL_WORDS };
    let highlightStart = null;

    // We merge if the highlighted range overlaps with the initial range.
    if (matchedWordRange.start - WORDS_BEFORE < INITIAL_WORDS) {
      initialRange.end = words.length;
    } else {
      highlightStart = Math.max(0, matchedWordRange.start - WORDS_BEFORE);
    }

    let finalWords = words.slice(initialRange.start, initialRange.end).join(' ');
    if (initialRange.end !== words.length) {
      finalWords = finalWords.concat('\u2026 ');
    }

    if (highlightStart) {
      finalWords = finalWords.concat(words.slice(highlightStart).join(' '));
    }
    return finalWords;
  },

  /**
   * Find the range of words that the match spans in the text
   * E.g. text = "One two three four", match is "wo thr" => return { start: 1, end: 2 }
   *              0   1   2     3
   */
  matchedWordRange(words, match) {
    const matchStart = match.index;
    const matchEnd = match.index + match[0].length;
    let matchedWordRange = {};
    let cursor = 0;

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const rangeStart = cursor;
      const rangeEnd = cursor + word.length;

      if (matchStart >= rangeStart && matchStart < rangeEnd) {
        matchedWordRange.start = i;
      }

      if (matchEnd >= rangeStart && matchEnd <= rangeEnd) {
        matchedWordRange.end = i;
        break;
      }

      cursor += word.length + 1;
    }

    return matchedWordRange;
  },

  getNextIndex() {
    const index = this.state.selectedIndex;
    const items = this.state.filteredItems;

    for (let i = index + 1; i < items.length; i++) {
      if (!items[i].isDisabled) {
        return i;
      }
    }
    return index;
  },

  getPreviousIndex() {
    const index = this.state.selectedIndex;
    const items = this.state.filteredItems;

    for (let i = index - 1; i >= 0; i--) {
      if (!items[i].isDisabled) {
        return i;
      }
    }
    return index;
  },

  shouldShowPrompt() {
    return this.props.text === '' && this.props.suggestedItems.length === 0;
  },

  shouldShowSuggestedItems() {
    return this.props.text === '' && this.props.suggestedItems.length > 0;
  },

  forceRender() {
    this.forceUpdate();
  },

  scrollToItem(index) {
    let menuNode = ReactDOM.findDOMNode(this.refs.menu);
    if (!menuNode) {
      return;
    }

    const itemNode = menuNode.querySelectorAll('.mentionMenu-item')[index];

    const menuRect = menuNode.getBoundingClientRect();
    const itemRect = itemNode.getBoundingClientRect();

    if (itemRect.top < menuRect.top) {
      menuNode.scrollTop = menuNode.scrollTop - (menuRect.top - itemRect.top);
    } else if (itemRect.bottom > menuRect.bottom) {
      menuNode.scrollTop = menuNode.scrollTop + (itemRect.bottom - menuRect.bottom);
    }
  },

  /* Actions */

  cancelMenu() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  },

  clearPreview() {
    if (this.props.onClearPreview) {
      this.props.onClearPreview();
    }
  },

  insertText(item) {
    this.props.onTextInsertion(item);
  },

  setSelectedIndex(nextIndex) {
    if (nextIndex !== this.state.selectedIndex) {
      this.setState({
        selectedIndex: nextIndex,
      });
    }
  },

  /* Event handlers */

  onMouseDown(index, event) {
    const item = this.state.filteredItems[index];
    if (!item.isDisabled) {
      this.insertText(item);
    }
    this._haltEvent(event);
  },

  onMouseOver(index, event) {
    this.setSelectedIndex(index);
  },

  onKeyDown(event) {
    if (this.props.text === null) {
      return;
    }

    const items = this.state.filteredItems;
    if (items && items.length === 0) {
      return;
    }

    const metaShiftAlt = !!event.metaKey || !!event.shiftKey || !!event.altKey;

    if (event.keyCode === ARROW_DOWN) {
      this._haltEvent(event);
      this.setSelectedIndex(this.getNextIndex());
    } else if (event.keyCode === ARROW_UP) {
      this._haltEvent(event);
      this.setSelectedIndex(this.getPreviousIndex());
    } else if (event.keyCode === ENTER && !metaShiftAlt) {
      this._haltEvent(event);
      const item = items[this.state.selectedIndex];
      if (!item.isDisabled) {
        this.insertText(item);
      }
    }
  },

  _haltEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  },
});

export default MentionMenu;
