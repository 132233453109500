import LoadAndSelectAnswer from 'actions/answers/load_and_select_answer';
import { SnippetChannel } from 'models/answers/snippet';

export default class ViewPublicAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ id }) {
    this.context.executeAction(LoadAndSelectAnswer, { answerId: id, answerType: SnippetChannel.SELF_SERVICE });
  }
}
