import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ConversationWorkflowButtons from 'components/customer/conversation_workflow_buttons_container';
import CustomerContext from 'components/customer/customer_context';

export const COMPOSER_BUTTONS_CLASS = 'composerButtons-portal';

const Buttons = styled.div`
  margin-left: 16px;
  padding: 0 16px;
`;

/**
 * `WorkflowButtons` either renders conversation workflow buttons (like Close & Next) if there is
 * no composition open, or renders a div that the composition can render its Send / Cancel buttons
 * into via a React.Portal (we use a portal rather than rendering them here because the composition
 * can directly control the look / behavior of the buttons).
 */
export default function WorkflowButtons() {
  return (
    <CustomerContext.Consumer>
      {({ compositionId, customerId, latestConversationId }) => {
        const classNames = classnames(COMPOSER_BUTTONS_CLASS, {
          [`${COMPOSER_BUTTONS_CLASS}-active`]: !!compositionId,
        });

        return (
          <Buttons>
            <div className={classNames} />
            {!compositionId ? (
              <ConversationWorkflowButtons conversationId={latestConversationId} customerId={customerId} />
            ) : null}
          </Buttons>
        );
      }}
    </CustomerContext.Consumer>
  );
}

WorkflowButtons.propTypes = {
  compositionId: PropTypes.string,
};
