import TaskFollower from 'models/task_follower';

export default class FollowTask {
  constructor(context) {
    this.context = context;
  }

  run({ itemId }) {
    let agentId = this.context.stores.currentAgent.get().id;

    let follower = TaskFollower.create({ agentId, itemId });
    let followerJs = follower.toJs();

    let taskInfo = this.context.stores.taskInfo.findBy({ id: itemId });
    taskInfo.addFollower(follower);
    this.context.stores.taskInfo.setPending(taskInfo);

    this.context.gateways.taskFollowers.add({ taskId: itemId }, followerJs);
    this.context.analytics.track('Task Followed', {
      followerId: agentId,
      isManual: true,
      itemId,
    });
  }
}
