import UserSessionResetter from 'actions/user/lib/user_session_resetter';
import UserActivation from 'models/location/user_activation';
import UserCredential from 'models/user_credential';
import UserCredentialType from 'models/user_credential_type';
import UserLogin from 'models/location/user_login';

export default class BeginActivation extends UserSessionResetter {
  // user visits /user/activate?token=abc
  // an http request is sent to supernova with the abc token
  // a jwToken is returned via http and the user is considered logged in (but not activated)
  // the user is redirected to /user/activate (without a token parameter)
  run(token) {
    if (this.context.stores.appFeatures.get().isEnabled('sso')) {
      this.context.router.navigateHome();
      return;
    }

    if (token) {
      // to support the case when a logged in user visits an activation URL with another user's token
      this.reset();

      this.context.stores.userCredential.setPending(
        UserCredential.create({ type: UserCredentialType.ACTIVATION_TOKEN })
      );
      this.context.gateways.authentication.login({ token });
      this.setCurrentLocation();
    } else if (this.context.stores.auth.get().isLoggedIn()) {
      this.setCurrentLocation();
    } else {
      this.context.router.navigateTo(UserLogin.create());
    }
  }

  setCurrentLocation() {
    this.context.stores.currentLocation.set(UserActivation.create());
  }
}
