import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import AgentProfileConverter from 'scripts/application/dto_converters/agent_profile_converter';
import AgentConverter from 'scripts/application/dto_converters/agent_converter';

export default class AgentProfileService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(attrs, callback, path, { orgId, agentId }) {
    let agent = _.find(this.getDatabase(orgId).agentProfiles, { id: agentId });

    if (!agent) {
      callback(null, { status: 404, statusText: statusText(404) });
      return;
    }

    callback(null, { status: 200, statusText: statusText(200), response: agent });
  }

  findAll(attrs, callback, path, { orgId }) {
    let agents = this.getDatabase(orgId).agentProfiles;

    callback(null, { status: 200, statusText: statusText(200), response: agents });
  }

  update(attrs, callback, path, { orgId, agentId }) {
    let agentProfile = _.find(this.getDatabase(orgId).agentProfiles, { id: agentId });

    if (!agentProfile) {
      callback(null, { status: 404, statusText: statusText(404) });
      return;
    }

    _.merge(agentProfile, attrs);
    if (attrs.voiceConfiguration) {
      agentProfile.voiceConfiguration = attrs.voiceConfiguration;
    }
    agentProfile.version += 1;
    this._pubsub.publish(`v3/orgs/${orgId}/agent-profiles/${agentId}`, { payload: agentProfile });

    let agentProfileEntity = AgentProfileConverter.fromDto(agentProfile);
    let agentEntity = agentProfileEntity.toAgent();
    let agentDto = AgentConverter.toDto(agentEntity);
    let currentAgent = _.find(this.getDatabase(orgId).agents, { id: agentId });
    _.merge(currentAgent, agentDto);
    this._pubsub.publish(`v3/orgs/${orgId}/agents/${agentDto.id}`, { payload: agentDto });

    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v3/orgs/:orgId/agent-profiles/:agentId': {
          GET: this.find,
          PUT: this.update,
        },
        '/api/v3/orgs/:orgId/agent-profiles': {
          GET: this.findAll,
        },
      },
      this
    );
  }
}
