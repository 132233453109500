import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import connect from 'components/lib/connect';
import SummaryCard, { SummaryHeader } from 'components/customer/summary/lib/summary_card';

function CompanyCards({ customAttributes, def, showCompanyCards, type }) {
  if (!def || !showCompanyCards) {
    return null;
  }

  let companyCards = _.get(customAttributes, 'companyCards');
  if (_.isEmpty(companyCards)) {
    return null;
  }

  return (
    <SummaryCard>
      {def.label && <SummaryHeader text={`${def.label}`} />}
      <div className={'customEvents-companyCardsHeader'}>
        <div className="customEvents-listWrapper">
          <div className={classnames('customEvents-list')}>{companyCards.map(renderCompanyCard)}</div>
        </div>
      </div>
    </SummaryCard>
  );

  function renderCompanyCard(companyCard, index) {
    let companyCardDef = _.get(def, 'row');
    return (
      <div className={'customEvents-companyCard'} key={index}>
        {renderImage(companyCard)}
        <div className="customEvents-companyCard-details-wrapper">
          <div className="customEvents-companyCard-details-header">
            <b>{companyCard[_.get(companyCardDef, 'title.attr')]}</b>
          </div>
          <div className="customEvents-companyCard-details">
            <div className="customEvents-companyCard-details-row">
              <div className="customEvents-companyCard-label">{_.get(companyCardDef, 'descriptionOne.label')}</div>
              <div className="customEvents-companyCard-detail">
                {companyCard[_.get(companyCardDef, 'descriptionOne.attr')]}
              </div>
            </div>
            <div className="customEvents-companyCard-details-row">
              <div className="customEvents-companyCard-label">{_.get(companyCardDef, 'descriptionTwo.label')}</div>
              <div className="customEvents-companyCard-detail">
                {companyCard[_.get(companyCardDef, 'descriptionTwo.attr')]}
              </div>
            </div>
            <div className="customEvents-companyCard-details-row">
              <div className="customEvents-companyCard-label">{_.get(companyCardDef, 'descriptionThree.label')}</div>
              <div className="customEvents-companyCard-detail">
                {companyCard[_.get(companyCardDef, 'descriptionThree.attr')]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderImage(companyCard) {
    let image = _.get(companyCard, 'image');
    if (image) {
      return (
        <div className="customEvents-companyCard-image-wrapper">
          <img className="customEvents-companyCard-image" src={image} />
        </div>
      );
    }
    return null;
  }
}

CompanyCards.propTypes = {
  customAttributes: PropTypes.object.isRequired,
  def: PropTypes.object,
  showCompanyCards: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export { CompanyCards };

const CompanyCardsContainer = connect(mapStateToProps)(CompanyCards);

function mapStateToProps({ getProvider, isFeatureEnabled }, { type }) {
  return {
    customAttributes: _.get(getProvider('profile').get(), 'customAttributes') || {},
    def: _.get(getProvider('customerProfileDef').get(), type),
    showCompanyCards: isFeatureEnabled('demoMode'),
    type,
  };
}

CompanyCardsContainer.propTypes = {
  customAttributes: PropTypes.object,
  def: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default CompanyCardsContainer;
