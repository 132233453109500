import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import CustomAttributeBase from './custom_attribute_base';
import { getPercentAttrAsNumber } from '../lib/custom_attributes_helper';
import PercentCustomAttributeGraphic from './percent_custom_attribute_graphic';

export default function VisiblePercentCustomAttribute({ className, 'data-aid': dataAid, def, value, renderProps }) {
  if ((!value && value !== 0) || (!_.isString(value) && !_.isNumber(value)) || def.type !== AttributeType.PERCENT) {
    return null;
  }

  let renderGraphic = null;
  const trimmedValue = _.trim(value);
  const valAsNumber = getPercentAttrAsNumber(trimmedValue);

  if (renderPropsHasHighWaterMark(renderProps) && valAsNumber && !Number.isNaN(valAsNumber)) {
    renderGraphic = <PercentCustomAttributeGraphic highWaterMark={renderProps.highWaterMark} value={valAsNumber} />;
  }

  return (
    <CustomAttributeBase className={className} data-aid={dataAid} label={def.label}>
      <VisiblePercentCustomAttributeBase>
        <VisiblePercentCustomAttributePercentage>{trimmedValue}</VisiblePercentCustomAttributePercentage>
        <VisiblePercentCustomAttributePercentBar>{renderGraphic}</VisiblePercentCustomAttributePercentBar>
      </VisiblePercentCustomAttributeBase>
    </CustomAttributeBase>
  );
}

function renderPropsHasHighWaterMark(renderProps) {
  return renderProps?.highWaterMark !== undefined && !isNaN(renderProps?.highWaterMark);
}

export const VisiblePercentCustomAttributeBase = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const VisiblePercentCustomAttributePercentage = styled.div`
  flex-basis: 25%;
`;

const VisiblePercentCustomAttributePercentBar = styled.div`
  flex-basis: 75%;
  padding-left: 10px;
`;

VisiblePercentCustomAttribute.propTypes = {
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  value: PropTypes.any,
  renderProps: PropTypes.object,
};
