import ConversationUpdater from 'actions/conversation/lib/conversation_updater';

export default class MarkTaskOpened extends ConversationUpdater {
  run({ conversationItemId }) {
    let store = this.context.stores.conversationHistory;
    let taskItem = store.find(conversationItemId);
    if (!taskItem || !taskItem.content.closedAt) {
      return;
    }
    taskItem.content.markAsOpen();
    taskItem.respond('AGENT', this.currentAgent.id);
    store.setPending(taskItem);
    let conversationItemUpdate = {
      content: {
        reopen: true,
      },
    };
    this.context.gateways.task.update(this.currentAgent.id, taskItem.id, {
      id: conversationItemId,
      customerId: this.currentCustomerId,
      conversation: {},
      conversationItem: conversationItemUpdate,
    });
  }
}
