import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import CustomAttribute from 'components/lib/customer_conversation_card/custom_attribute';
import CustomerFieldWithHighlights from 'components/lib/customer_conversation_card/customer_field_with_highlights';
import CustomerProfileDef from 'models/customer_profile_def';
import getHighlightedText from './lib/highlight_text';
import ProfileAvatar from 'components/lib/profile_avatar';
import TaskAvatar from 'components/common/icons/task_avatar';

const baseClass = 'customerConversationCard-customerInfo';
const DEFAULT_CUSTOMER_CARD_ATTRIBUTES = [
  'email',
  'phone',
  'address',
  'externalCustomerAddresses',
  'externalCustomerId',
  'note',
];

export default function CustomerCard(props) {
  return (
    <div className={baseClass}>
      {renderAvatarOrTaskIcon()}
      <div className={cn('profile')}>
        {renderName()}
        <ProfileAttributeList {...props} />
      </div>
    </div>
  );

  function renderAvatarOrTaskIcon() {
    return props.isTask ? (
      <TaskAvatarContainer>
        <TaskAvatar className={'customerConversationCard-taskAvatar'} />
      </TaskAvatarContainer>
    ) : (
      <StyledProfileAvatar>
        <ProfileAvatar className="customerConversationCard-avatar" profile={props} />
      </StyledProfileAvatar>
    );
  }

  function renderName() {
    return (
      <HeaderContainer>
        <CustomerNameHeader data-aid="customerConversationCard-customerInfo-profile-name" stack="none">
          {props.name ? getHighlightedText(props.name, cn('highlight')) : 'Unknown Customer'}
        </CustomerNameHeader>
      </HeaderContainer>
    );
  }
}

CustomerCard.propTypes = {
  address: PropTypes.string,
  customAttributes: PropTypes.object,
  customerProfileDef: PropTypes.instanceOf(CustomerProfileDef),
  email: PropTypes.string,
  externalCustomerAddress: PropTypes.string,
  externalCustomerId: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  isClosedTask: PropTypes.bool,
  isTask: PropTypes.bool,
  name: PropTypes.string,
  note: PropTypes.string,
  phone: PropTypes.string,
  matchingAttribute: PropTypes.string,
};

const HeaderContainer = styled.div`
  width: 100%;
`;

const CustomerNameHeader = styled.h3`
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .customerConversationCard-unread & {
    font-weight: ${p => p.theme.fontWeight.medium};
  }
`;
const cardAvatarSize = css`
  height: 44px;
  width: 44px;
`;
const StyledProfileAvatar = styled.div`
  .profileAvatar-unknownCustomer {
    ${cardAvatarSize}
  }
`;
const TaskAvatarContainer = styled.div`
  min-width: 44px;
  margin-right: ${p => p.theme.spacing.inlineMedium};

  .customerConversationCard-taskAvatar {
    ${cardAvatarSize}
  }
`;

export function ProfileAttributeList(props) {
  const { customAttributes, customerProfileDef, matchingAttribute } = props;
  const cardAttributes = (customerProfileDef && customerProfileDef.cardAttributes) || DEFAULT_CUSTOMER_CARD_ATTRIBUTES;

  if (matchingAttribute && cardAttributes.indexOf(matchingAttribute) === -1) {
    cardAttributes.push(matchingAttribute);
  }

  return <dl className={cn('profile-list')}>{_.map(cardAttributes, attr => renderAttribute(attr))}</dl>;

  function renderAttribute(attr) {
    const value = props[attr];
    switch (attr) {
      case 'email':
      case 'phone':
        return (
          <dd className={cn(`profile-${attr}`)} key={attr}>
            {getHighlightedText(value, cn('highlight'))}
          </dd>
        );
      case 'address':
        return (
          <dd className={cn('profile-address', { multiline: value && value.indexOf('\n') >= 0 })} key={attr}>
            {getHighlightedText(value, cn('highlight'))}
          </dd>
        );
      case 'externalCustomerAddresses':
        return (
          <dd className={cn('profile-externalAddresses')} key={attr}>
            {getHighlightedText(value, cn('highlight'))}
          </dd>
        );
      case 'externalCustomerId':
        return (
          <dd className={cn('profile-externalId')} key={attr}>
            <CustomerFieldWithHighlights label="Customer ID" value={value} />
          </dd>
        );
      case 'note':
        return (
          <dd className={cn('profile-note')} key={attr}>
            <CustomerFieldWithHighlights label="Details" value={value} />
          </dd>
        );
      /* Otherwise, attr must be a custom attribute */
      default: {
        /* May have not loaded CustomerProfileDef yet */
        if (!customerProfileDef) {
          return null;
        }

        /* CustomerProfileDef may no longer have this attribute */
        const customAttributeDef = _.find(customerProfileDef.attributes, attrDef => attrDef.attr === attr);
        if (!customAttributeDef) {
          return null;
        }

        /* Profile may not have a value for this custom attribute */
        const customAttributeValue = customAttributes && customAttributes[attr];
        if (!customAttributeValue) {
          return null;
        }

        return (
          <CustomAttribute
            customAttributeDef={customAttributeDef}
            highlightClassName={cn('highlight')}
            key={attr}
            value={customAttributeValue}
          />
        );
      }
    }
  }
}

ProfileAttributeList.propTypes = CustomerCard.propTypes;

function cn(...args) {
  let res = classnames(...args);
  return res
    .split(' ')
    .map(c => `${baseClass}-${c}`)
    .join(' ');
}
