import map from 'lodash/map';

import AgentNotification from 'models/agent_notification';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';
import RerequestAgentNotifications from './rerequest_agent_notifications';
import setHasReachedEnd from './set_has_reached_end';

export const PAGE_SIZE = 20;

export default class RequestAgentNotifications {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.agentNotifications.setLoading();
    this.context.gateways.agentNotification
      .requestFirstPage()
      .then(notificationsDto => {
        const agentNotifications = map(notificationsDto, notification => AgentNotification.fromJs(notification));
        this.context.stores.agentNotifications.set(agentNotifications);

        if (agentNotifications.length < PAGE_SIZE) {
          setHasReachedEnd(this.context);
        }
      })
      .catch(errDto => {
        handleCommonErrors(this.context, errDto);
      })
      .finally(() => {
        this.context.stores.agentNotifications.resetLoading();
      });

    this.context.backends.onReconnect(() => {
      this.context.executeAction(RerequestAgentNotifications);
    });
  }
}
