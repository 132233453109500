import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import Portal from 'components/common/utilities/portal';
import Toast from './toast';
import ToastModel from 'models/toast';

export function Toasts({ toasts }) {
  // We actually render them in reverse order, then use flex-direction: column-reverse to render them
  // in opposite order. We do this so that the notifications properly disappear under each other when
  // dismissed.
  const sortedToasts = _.orderBy(toasts, t => t.createdAt, ['desc']);

  return (
    <Portal>
      <StyledToasts>
        {_.map(sortedToasts, toast => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </StyledToasts>
    </Portal>
  );
}

Toasts.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.instanceOf(ToastModel)),
};

const ToastsContainer = connect(mapStateToProps)(Toasts);

function mapStateToProps({ getProvider }) {
  return {
    toasts: getProvider('toasts').findAll(),
  };
}

export default ToastsContainer;

const StyledToasts = styled.div`
  align-items: center;
  top: 68px;
  display: flex;
  flex-direction: column-reverse;
  justify-items: center;
  right: 16px;
  position: fixed;
  z-index: 6;
`;
