import CustomerView from 'models/location/customer_view';

export default class NavigateToCustomerProfile {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId, customerId, itemId }) {
    let customerView = CustomerView.create({
      currentConversationId: conversationId,
      currentConversationItemId: itemId,
      customerId,
    });
    this.context.router.navigateTo(customerView);
  }
}
