import createModel from '../lib/create_model';

var EmailPreview = createModel({
  modelName: 'EmailPreview',

  properties: {
    excerpt: String,
    from: String,
    subject: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default EmailPreview;
