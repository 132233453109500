import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import TaskManagerCard from './task_manager_card';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import Task from 'scripts/domain/models/task';
import { useTranslation } from 'react-i18next';

export function TaskManager({ customerId, tasks, isLoading }) {
  const { t } = useTranslation();
  if (!isLoading && !tasks.length) {
    return null;
  }
  const style = { paddingBottom: '8px' };
  return (
    <ExpandableProfileCard data-aid="taskManagerCard" isLoading={isLoading} style={style} title={t('Customer Tasks')}>
      {tasks.map((task, i) => (
        <TaskManagerCard customerId={customerId} key={i} task={task} />
      ))}
    </ExpandableProfileCard>
  );
}

TaskManager.propTypes = {
  customerId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isTaskManagerEnabled: PropTypes.bool,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
};

export default connect(mapStateToProps)(TaskManager);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  let conversationHistory = getProvider('conversationHistory');
  let profile = getProvider('profile');
  let tasks = conversationHistory.findAll().filter(item => item.content instanceof Task && !item.content.closedAt);
  tasks = _.orderBy(tasks, [item => moment(item.content.dueAt).valueOf()], ['asc']);
  return {
    tasks,
    customerId: _(profile.get()).get('id'),
    isLoading: conversationHistory.isLoading(),
  };
}
