import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import { Roles } from 'models/role';

const EditableUser = createModel({
  modelName: 'EditableUser',

  properties: {
    id: String,
    disabled: Boolean,
    email: prop(String).default(''),
    name: prop(String).default(''),
    roleIds: prop([String]).default([]),
    routingGroupIds: prop([String]).default([]),
    stationId: prop(String).default(''),
  },

  isNew() {
    return !this.email;
  },

  statics: {
    create(user, profile, routingGroupIds) {
      if (!user) {
        return new this({
          roleIds: [Roles.agent.id],
        });
      }

      return new this({
        id: user.id,
        disabled: user.isDisabled(),
        email: user.username,
        name: profile.name,
        roleIds: user.roleIds,
        routingGroupIds,
        stationId: _(profile.voiceConfiguration).get('stationId'),
      });
    },
  },
});

export default EditableUser;
