import { EditorState, Modifier } from 'draft-js';

import createEnum from 'scripts/lib/create_enum';
import getCurrentlySelectedBlock from './draft_selected_block';

export const Alignments = createEnum('CENTER', 'JUSTIFY', 'LEFT', 'RIGHT');
export const ALIGNMENT_DATA_KEY = 'textAlignment';

export function getAlignment(editorState) {
  const { currentBlock } = getCurrentlySelectedBlock(editorState);
  const blockData = currentBlock.getData();
  return blockData && blockData.get(ALIGNMENT_DATA_KEY);
}

export default function toggleAlignment(editorState, alignment) {
  const { content, currentBlock, target } = getCurrentlySelectedBlock(editorState);

  const blockData = currentBlock.getData();
  const alignmentToSet = blockData && blockData.get(ALIGNMENT_DATA_KEY) === alignment ? undefined : alignment;

  return EditorState.push(
    editorState,
    Modifier.mergeBlockData(content, target, { [ALIGNMENT_DATA_KEY]: alignmentToSet }),
    'change-block-data'
  );
}
