import _ from 'lodash';

import ActiveCall from 'models/active_call';
import createConverter from 'scripts/adapters/immutable_converters/lib/create_converter';
import createDocumentStoreClass from './lib/create_document_store_class';

let converter = createConverter(ActiveCall);

const ActiveCallStoreBase = createDocumentStoreClass({ converter });

class ActiveCallStore extends ActiveCallStoreBase {
  set(entity) {
    const existingEntity = this.get();
    if (existingEntity) {
      if (
        entity.conversationItem.version <= existingEntity.conversationItem.version &&
        !_.isEqual(entity.conversationItem, existingEntity.conversationItem)
      ) {
        return;
      }
    }
    super.set(entity);
  }
}

export default ActiveCallStore;
