import AnswersPreviewLocation from 'models/location/answers_preview';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { IdGenerator } from 'factories/lib/uuid';
import { MIN_ANSWER_SEARCH_LEN } from 'models/location/kb_admin';
import SearchField, { SnippetSearchFields } from 'models/search/search_field';
import SearchFilter from 'models/search/search_filter';
import SearchQuery from 'models/search/search_query';
import SearchQueryConverter from 'scripts/application/dto_converters/search/search_query_converter';
import SnippetSearchResult from 'models/search/snippet_search_result';
import { AnswersSortType } from 'models/answer_panel';

const RESULTS_SIZE = 50;

export default class StartAnswerSearch {
  constructor(context) {
    this.context = context;
  }

  run() {
    let answerPanel = this.context.stores.answerPanel.get();
    const { searchLanguage, searchAudiences, searchText } = answerPanel;
    if (!searchText || searchText.length < MIN_ANSWER_SEARCH_LEN) return;

    const languages = searchLanguage ? [searchLanguage] : null;
    const searchFilter =
      searchLanguage || searchAudiences.length
        ? createFilter({ language: searchLanguage, audiences: searchAudiences })
        : null;
    const id = IdGenerator.newId();
    const attrs = {
      from: 0,
      languages,
      size: RESULTS_SIZE,
      sort: AnswersSortType.RELEVANCE,
      text: searchText,
      filter: searchFilter,
    };
    const query = new SearchQuery(attrs);

    answerPanel.setSearchResult(new SnippetSearchResult({ id, query, isLoading: true }));
    this.context.stores.answerPanel.setPending(answerPanel);
    const queryDto = { query: SearchQueryConverter.toDto(query) };
    this.context.gateways.snippetSearchResultHttp.request(id, queryDto);

    const currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation.customerId;
    const conversationsStore = this.context.stores.conversations;
    const conversationId = conversationsStore && getActiveConversationId(conversationsStore);
    const compositionId = currentLocation.currentCompositionId;
    const location = currentLocation instanceof AnswersPreviewLocation ? 'Answers Preview' : 'Customer Page';

    this.context.analytics.track('Answer Panel Searched', {
      customerId,
      conversationId,
      compositionId,
      location,
    });
  }
}

function createFilter(filters) {
  let filter = new SearchFilter();
  filters.language &&
    filter.setHasFields([new SearchField({ language: filters.language, name: SnippetSearchFields.CONTENTS })]);
  filters.audiences.length && filter.setAudienceIds(filters.audiences);
  return filter;
}
