import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';

export default function AnswerPanelDropdownMenu({ 'data-aid': aid, onSelect, options, text, value }) {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();

  return (
    <React.Fragment>
      <SortWrapper data-aid={aid ? `${aid}-opener` : undefined}>
        <Sort isOpen={isOpen} onClick={onToggle} ref={setTargetRef}>
          {text} <FontAwesomeIcon icon={faAngleDown} />
        </Sort>
      </SortWrapper>
      <PopoverMenu
        data-aid={aid}
        isOpen={isOpen}
        onClose={onClose}
        position="bottom"
        targetPosition="start"
        targetRef={targetRef}
      >
        <ScrollingMenu>
          {options.map(option => (
            <MenuItem
              isFocused={option.value === value}
              key={option.value}
              onClose={onClose}
              onSelect={onSelect}
              option={option}
            />
          ))}
        </ScrollingMenu>
      </PopoverMenu>
    </React.Fragment>
  );
}

AnswerPanelDropdownMenu.propTypes = {
  'data-aid': PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function MenuItem({ isFocused, onClose, onSelect, option }) {
  const onClick = useCallback(() => {
    onSelect(option.value);
    onClose();
  }, [onClose, onSelect, option.value]);

  return (
    <PopoverMenuItem data-aid="answerPanel-dropdownMenuItem" isFocused={isFocused} onClick={onClick}>
      {option.label}
    </PopoverMenuItem>
  );
}

const ScrollingMenu = styled.div`
  max-height: 275px;
  overflow: auto;
`;

export const SortWrapper = styled.div``;

const Sort = styled.div`
  color: ${p => p.theme.colors.black};
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.small};
  &:hover {
    color: ${p => p.theme.colors.green400};
  }
  .fa-angle-down {
    transition: transform 150ms ease;
  }

  ${p => p.isOpen && flipChevron};
`;

const flipChevron = css`
  .fa-angle-down {
    transform: rotate(-180deg);
  }
`;
