import connect from 'components/lib/connect';
import Endpoint from 'models/endpoint';
import { getDescriptivePhoneNumbers } from 'actions/composition/lib/create_composition';
import PhoneCallCompositionForm from './phone_call_composition_form';

export default connect(mapStateToProps)(PhoneCallCompositionForm);

function mapStateToProps({ getProvider }, props) {
  return {
    ...props,
    companyNumbers: getDescriptivePhoneNumbers({
      getProvider,
      endpointType: Endpoint.Type.VOICE,
    }),
  };
}
