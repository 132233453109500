import { useLayoutEffect, useRef, useState } from 'react';
import { useSpring } from '@react-spring/web';
import _ from 'lodash';

/**
 * Helper hook that simplifies animating "expandable" divs with react-springs (https://react-spring.dev). The hook
 * helps with animating 'height' property in a more flexible way that allows for nested expandable blocks.
 *
 * In order for the hook to work, you MUST structure your component in a certain way:
 *    <animated.div "collapsible block">     <---- This is the block that will be animated
 *      <div "content wrapper">              <---- This is a "wrapper" div that is used for measuring the content height
 *        ... <content /> ...
 *      </div>
 *    </animated.div>
 *
 * Make sure to follow these rules, otherwise the animation may behave in some unexpected ways:
 *  1. DO NOT use margins in the "content wrapper" block. Use padding instead, add margins to the content if needed
 *  2. DO NOT try to animate the "content wrapper" - it will throw off the height measurements
 *  3. The animated block MUST be `animated.div`. Plain `div` will not animate properly.
 *
 * Example:
 *   import {animated} from '@react-spring/web'
 *
 *   const contentWrapperRef = useRef()
 *   const [isCollapsed, setIsCollapsed] = useState(!!collapsed || _.isEmpty(children))
 *   const styles = useSpringExpandCollapse({ isCollapsed, contentRef: contentWrapperRef })
 *   ....
 *   function toggleExpanded() {
 *     setIsCollapsed(prevState => !prevState)
 *   }
 *   ....
 *   <button onClick={toggleExpanded}>Toggle</button>
 *   <animated.div className="collapsible" style={styles}>
 *     <div className="content-wrapper" ref={contentWrapperRef}>
 *       Lorem ipsum <!-- here comes the content -->
 *     </div>
 *   </animated.div>
 *   ....
 *
 * @param {boolean} isCollapsed - indicates the state the animated div should transition TO (e.g. `isCollapsed = true`
 *                                means the block needs to be collapsed)
 * @param {React.RefObject} contentWrapperRef - React "ref" pointing to the "content wrapper" block. MAKE SURE to pass
 *                                              reference to the CONTENT WRAPPER, NOT TO THE ANIMATED BLOCK ITSELF
 * @param {() => void} [onAnimationEnd] - Optional callback that will be invoked once the animation is complete
 * @param {number} [springTension] - Optional "spring tension" that controls how fast the transition happens.
 *                                   See (https://react-spring.dev/common/configs#configs)
 * @returns {*} returns styles that will be applied to the animated collapsible block
 */
export function useSpringExpandCollapse({ isCollapsed, contentWrapperRef, onAnimationEnd, springTension = 200 }) {
  const contentWrapperHeight = useRef(0);
  const resetStylesRef = {
    height: 'auto',
  };
  const lastCollapsedRef = useRef(!!isCollapsed);
  const isFullyExpanded = !isCollapsed && !lastCollapsedRef.current;

  const [, refresh] = useState({});
  const bodyContentAnimatedStyle = useSpring({
    to: {
      opacity: isCollapsed ? 0 : 1,
      height: isCollapsed ? 0 : contentWrapperHeight.current || 0,
    },
    config: {
      clamp: true,
      friction: 20,
      tension: springTension,
    },
    onRest: () => {
      if (_.isFunction(onAnimationEnd)) onAnimationEnd();
      lastCollapsedRef.current = !!isCollapsed;
      if (!isCollapsed) refresh({});
    },
  });

  useLayoutEffect(() => {
    if (!contentWrapperRef?.current) return;

    const previousHeight = contentWrapperHeight.current;
    contentWrapperHeight.current = contentWrapperRef.current.offsetHeight || 0;
    if (previousHeight !== contentWrapperHeight.current) {
      refresh({});
    }
  }, [contentWrapperRef]);

  return isFullyExpanded ? resetStylesRef : bodyContentAnimatedStyle;
}
