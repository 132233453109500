import React, { useState } from 'react';
import styled from 'styled-components';

/**
 * usePlaceholder takes placeholderText and returns a populated element with that placeholder text.
 * We need to cache the placeholder element otherwise slate yells at us for being inefficient.
 *
 * @param {string} currentPlaceholderText
 */
export function usePlaceholder(currentPlaceholderText) {
  const [placeholderText, setPlaceholderText] = useState(currentPlaceholderText);
  const [placeholder, setPlaceholder] = useState(<Placeholder text={placeholderText} />);

  if (currentPlaceholderText !== placeholderText) {
    setPlaceholderText(currentPlaceholderText);
    setPlaceholder(<Placeholder text={currentPlaceholderText} />);
  }

  return placeholder;
}

const StyledPlaceholder = React.memo(styled.span`
  margin-top: 5px;
`);

const Placeholder = React.memo(function InternalPlaceholder({ text }) {
  return <StyledPlaceholder>{text}</StyledPlaceholder>;
});
