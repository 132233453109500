import _ from 'lodash';

import ReportConfigs from 'models/reports/report_configs';

export default {
  fromDto(dto) {
    let configs = _.reject(dto, 'isApiOnly');

    return ReportConfigs.fromJs({ configs });
  },
};
