import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 15 12" {...props} className="chatIconFilledContainer">
    <g fill="none" fillRule="evenodd">
      <g className="chatIconFilled" transform="translate(-435.000000, -110.000000)">
        <path d="M449.375,115.1 C449.375,117.575 447.265625,119.6 444.6875,119.6 L442.8125,119.6 L440.3125,122 L440.3125,119.6 L439.6875,119.6 C437.109375,119.6 435,117.575 435,115.1 C435,112.625 437.109375,110.6 439.6875,110.6 L444.6875,110.6 C447.265625,110.6 449.375,112.625 449.375,115.1 Z" />
      </g>
    </g>
  </IconContainer>
);
