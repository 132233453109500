import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AnswerEditor from './answer_editor';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import Snippet, { ChannelFieldName, SnippetChannel, SnippetContentType } from 'models/answers/snippet';
import Upload from 'models/upload';

class AnswerDraftManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // isNewSnippet: when searching for answer links, it causes the snippet store
      // to change. we want to record this initial value to accurately determine
      // whether we are creating or editing
      isNewSnippet: props.isNewSnippet,
      description: '',
      name: '',
      audiences: [],
      contents: [createBlankContent({ language: _.get(props, 'snippet.contents[0].language') })],
    };
    _.bindAll(this, [
      'addChannel',
      'addLanguage',
      'getChannelOnChangeForLanguage',
      'removeChannel',
      'saveDraft',
      'setDescription',
      'setName',
      'setAudiences',
      'updateFieldForLanguage',
    ]);
  }

  componentDidMount() {
    if (!this.props.isNewSnippet) {
      this.setFetchedSnippet(this.props.snippet);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { snippet } = nextProps;

    // when snippetComposition store is replaced with full snippet data, update the state
    if (this.shouldSetStateWithNextSnippet(nextProps)) {
      this.setFetchedSnippet(snippet);
    }
  }

  setFetchedSnippet(snippet) {
    let contents = snippet.contents.map(content => {
      let { anyChannel, info, message, selfService } = getContentBodiesFromSnippet(snippet, content.language);
      let contentState = {
        anyChannelBodyHtml: FullSerializer.deserialize(anyChannel),
        isInternal: _.get(content, 'info.internal'),
        infoBodyHtml: FullSerializer.deserialize(info),
        language: content.language,
        messageBodyHtml: FullSerializer.deserialize(message),
        selfServiceBodyHtml: FullSerializer.deserialize(selfService),
        selfServiceName: _.get(content, 'selfService.name'),
        subject: FullSerializer.deserialize(_.get(content, 'anyChannel.subject') || ''),
        existingChannelTypes: [],
      };
      if (message) {
        contentState.existingChannelTypes.push(SnippetChannel.MESSAGE);
      }
      if (info) {
        contentState.existingChannelTypes.push(SnippetChannel.INFO);
      }
      if (anyChannel) {
        contentState.existingChannelTypes.push(SnippetChannel.ANY_CHANNEL);
      }
      if (selfService) {
        contentState.existingChannelTypes.push(SnippetChannel.SELF_SERVICE);
      }
      return contentState;
    });
    let newState = {
      contents,
      description: snippet.description || '',
      id: snippet.id,
      name: snippet.name || '',
      audiences: snippet.audienceIds || [],
    };
    this.setState(newState);
  }

  shouldSetStateWithNextSnippet(nextProps) {
    return (
      nextProps.snippet &&
      nextProps.snippet.id === this.props.snippet.id &&
      this.props.isLoading &&
      !nextProps.isLoading &&
      !_.isEqual(nextProps.snippet, this.props.snippet)
    );
  }

  updateFieldForLanguage({ field, fieldName, language }) {
    let contents = _.map(this.state.contents, c => {
      const newC = { ...c };
      if (newC.language === language) {
        newC[fieldName] = field;
      }
      return newC;
    });
    this.setState({ contents });
  }

  addChannel({ channelType, language }) {
    let contents = _.map(this.state.contents, c => {
      const newC = { ...c };

      if (newC.language === language) {
        newC.existingChannelTypes = [...newC.existingChannelTypes, channelType];
      }
      return newC;
    });
    this.setState({ contents });
  }

  removeChannel({ channelType, language }) {
    const field = ChannelFieldName[channelType];

    let contents = _.map(this.state.contents, c => {
      const newC = { ...c };

      if (newC.language === language) {
        newC[field] = FullSerializer.deserialize('');
        if (channelType === SnippetChannel.INFO) {
          newC.isInternal = false;
        } else if (channelType === SnippetChannel.SELF_SERVICE) {
          newC.selfServiceName = '';
        } else if (channelType === SnippetChannel.ANY_CHANNEL) {
          newC.subject = FullSerializer.deserialize('');
        }
        newC.existingChannelTypes = _.remove(newC.existingChannelTypes, channel => ChannelFieldName[channel] !== field);
      }
      return newC;
    });

    let newState = {
      contents,
    };

    if (
      !_.some(
        contents,
        content =>
          !!_.some(content.existingChannelTypes, c => c === SnippetChannel.ANY_CHANNEL || c === SnippetChannel.MESSAGE)
      )
    ) {
      newState.description = '';
    }

    let content = this.props.snippet.findContentByLanguage(language);
    let attachments = content.getAttachmentsByType(SnippetContentType[channelType]);

    _.forEach(attachments, a => {
      if (a instanceof Upload) {
        this.props.onRemoveInlineImageUpload(a.id);
      } else {
        this.props.onRemoveInlineImageAttachment(a.id);
      }
    });

    this.setState(newState);
  }

  addLanguage(language) {
    const contents = _.concat(this.state.contents, createBlankContent({ language }));
    this.setState({ contents });
  }

  detachDeletedInlineImages(snippetContentType) {
    // if an inline image is deleted from the editor
    // remove it from the attachment array

    let inlineImages = _(this.state.contents)
      .map(c => this.getInlineImages(c.anyChannelBodyHtml))
      .flatten()
      .value();

    if (snippetContentType === SnippetContentType.INFO) {
      inlineImages = _(this.state.contents)
        .map(c => this.getInlineImages(c.infoBodyHtml))
        .flatten()
        .value();
    }
    const attachments = _(this.props.snippet.contents)
      .map(c => c.getAttachmentsByType(snippetContentType))
      .flatten()
      .value();

    _.forEach(attachments, a => {
      if (a.isInline && !_.includes(inlineImages, a.id)) {
        if (a instanceof Upload) {
          this.props.onRemoveInlineImageUpload(a.id);
        } else {
          this.props.onRemoveInlineImageAttachment(a.id);
        }
      }
    });
  }

  saveDraft() {
    if (this.props.snippet) {
      this.detachDeletedInlineImages(SnippetContentType.INFO);
      this.detachDeletedInlineImages(SnippetContentType.ANY_CHANNEL);
    }

    let contents = this.state.contents.map(c => {
      return {
        anyChannel: {
          subject: FullSerializer.serialize(c.subject),
          bodyHtml: FullSerializer.serialize(c.anyChannelBodyHtml),
        },
        info: {
          bodyHtml: FullSerializer.serialize(c.infoBodyHtml),
          internal: c.isInternal,
        },
        message: {
          bodyHtml: FullSerializer.serialize(c.messageBodyHtml),
        },
        selfService: {
          bodyHtml: FullSerializer.serialize(c.selfServiceBodyHtml),
          name: c.selfServiceName,
        },
        language: c.language,
      };
    });

    this.props.onSubmit(
      {
        id: this.state.id,
        name: this.state.name,
        description: this.state.description,
        contents,
        audienceIds: this.state.audiences,
      },
      this.state.isNewSnippet
    );
  }

  setName(name) {
    this.setState({ name });
  }

  setDescription(description) {
    this.setState({ description });
  }

  setAudiences(audiences) {
    this.setState({ audiences });
  }

  getChannelOnChangeForLanguage({ language, channel }) {
    return ({ value }) => {
      let contents = _.map(this.state.contents, c => {
        const newC = { ...c };

        if (newC.language === language) {
          newC[channel] = value;
        }
        return newC;
      });
      this.setState({ contents });
    };
  }

  getInlineImages(value) {
    let blocks = value.document.getBlocksAsArray();

    let inlineImages = [];
    _.forEach(blocks, block => {
      if (block.type === 'image') {
        let attachmentId = block.data.get('attachmentId');
        inlineImages.push(attachmentId);
      }
    });

    return inlineImages;
  }

  render() {
    return (
      <AnswerEditor
        {...this.props}
        {...this.state}
        addChannel={this.addChannel}
        addLanguage={this.addLanguage}
        getChannelOnChangeForLanguage={this.getChannelOnChangeForLanguage}
        removeChannel={this.removeChannel}
        saveDraft={this.saveDraft}
        setAudiences={this.setAudiences}
        setDescription={this.setDescription}
        setName={this.setName}
        updateFieldForLanguage={this.updateFieldForLanguage}
      />
    );
  }
}

function createBlankContent({ language }) {
  return {
    anyChannelBodyHtml: FullSerializer.deserialize(''),
    existingChannelTypes: [],
    infoBodyHtml: FullSerializer.deserialize(''),
    isInternal: false,
    language,
    messageBodyHtml: FullSerializer.deserialize(''),
    selfServiceBodyHtml: FullSerializer.deserialize(''),
    selfServiceName: '',
    subject: FullSerializer.deserialize(''),
  };
}

function getContentBodiesFromSnippet(snippet, language) {
  let content = snippet.findContentByLanguage(language);
  let anyChannel = content.getBodyByType(SnippetContentType.ANY_CHANNEL) || '';
  let message = content.getBodyByType(SnippetContentType.MESSAGE) || '';
  let info = content.getBodyByType(SnippetContentType.INFO) || '';
  let selfService = content.getBodyByType(SnippetContentType.SELF_SERVICE) || '';
  return { anyChannel, message, info, selfService };
}

AnswerDraftManager.propTypes = {
  isLoading: PropTypes.bool,
  isNewSnippet: PropTypes.bool,
  onRemoveInlineImageAttachment: PropTypes.func.isRequired,
  onRemoveInlineImageUpload: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  snippet: PropTypes.instanceOf(Snippet).isRequired,
};

export default AnswerDraftManager;
