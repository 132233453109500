import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import rn from 'random-number';
import WaveSurfer from 'wavesurfer.js';
import analytics from 'scripts/lib/analytics';

import Timer from 'components/lib/date/timer';

const wavePlayerBarWidth = 1;
const wavePlayerCursorColor = '#ccc';
const wavePlayerHeight = 75;
const wavePlayerMediaType = 'audio';
const wavePlayerProgressColor = '#a7dba7';
const wavePlayerWaveColor = '#009b00';
const waveRedrawDelay = 150;

// maximum supported duration (in seconds) to render waveforms. Required to prevent browser from crashing
const maxDuration = 3600;

const WavePlayer = createReactClass({
  propTypes: {
    contentType: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    isWebAudioSupported: PropTypes.bool.isRequired,
    onReady: PropTypes.func.isRequired,
    recordingId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      currentPosition: 0,
      duration: this.props.duration,
      enabled: false,
      playing: false,
      ready: false,
      recordingPeaks: [],
      url: this.props.url,
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({
        currentPosition: 0,
        duration: nextProps.duration,
        enabled: false,
        playing: false,
        ready: false,
        recordingPeaks: [],
        url: nextProps.url,
      });
      if (this.wavesurfer) {
        this.wavesurfer.destroy();
      }
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.enabled && this.state.enabled) {
      this.createWaveSurfer(this.state.url, this.state.recordingPeaks);
    }
  },

  handleClickEnable() {
    this.setState({ enabled: true });
  },

  isRecordingPeaksEnabled() {
    return this.props.isWebAudioSupported && this.state.duration < maxDuration;
  },

  createWaveSurfer(url, recordingPeaks) {
    this.wavesurfer = WaveSurfer.create({
      audioContext: this.isRecordingPeaksEnabled() ? null : 'OfflineAudioContext',
      backend: this.isRecordingPeaksEnabled() ? 'WebAudio' : 'MediaElement',
      barWidth: wavePlayerBarWidth,
      container: this.refs.waveformContainer,
      cursorColor: wavePlayerCursorColor,
      height: wavePlayerHeight,
      interact: false,
      mediaType: wavePlayerMediaType,
      progressColor: wavePlayerProgressColor,
      responsive: waveRedrawDelay,
      waveColor: wavePlayerWaveColor,
    });
    if (this.canRenderWaveform(recordingPeaks)) {
      this.wavesurfer.backend.setPeaks(recordingPeaks);
      this.wavesurfer.drawBuffer();
    }

    this.wavesurfer.load(url);

    this.wavesurfer.on('ready', this.handleReady);
    this.wavesurfer.on('finish', this.handlePlaybackFinished);
    this.wavesurfer.on('audioprocess', _.throttle(this.handlePositionChange, 500, { leading: true, trailing: false }));
    this.wavesurfer.on('seek', this.handleSeek);
    if (this.state.playing) {
      this.wavesurfer.play();
    }
  },

  componentWillUnmount() {
    if (this.wavesurfer) {
      this.wavesurfer._onResize && this.wavesurfer._onResize.clear();
      if (this.state.playing) {
        const playDuration = new Date().getTime() - this.state.playStartAt;
        this.trackPlayedRecording(playDuration);
      }
      this.wavesurfer.destroy();
    }
  },

  canRenderWaveform(recordingPeaks) {
    return recordingPeaks.length > 0;
  },

  handlePlaybackFinished() {
    const playDuration = new Date().getTime() - this.state.playStartAt;
    this.trackPlayedRecording(playDuration);
    this.setState({ playing: false, currentPosition: this.state.duration });
  },

  handlePositionChange(position) {
    if (this.state.playing) {
      if (position < 0) {
        position = 0;
      }
      this.setState({ currentPosition: position });
    }
  },

  handleSeek(floatPosition) {
    if (!isNaN(floatPosition) && !this.state.playing) {
      let currentPosition = floatPosition * this.state.duration;
      if (currentPosition < 0) {
        currentPosition = 0;
      }
      this.setState({ currentPosition });
    }
  },

  handleTogglePlay() {
    let currentPosition = this.state.currentPosition;
    if ((!this.state.playing && this.state.currentPosition === this.state.duration) || this.state.currentPosition < 0) {
      currentPosition = 0;
    }
    const isPlaying = this.state.playing;
    this.setState({
      playing: !isPlaying,
      currentPosition,
    });

    if (isPlaying) {
      const playDuration = new Date().getTime() - this.state.playStartAt;
      this.trackPlayedRecording(playDuration);
      this.wavesurfer.pause();
      return;
    }
    this.setState({ playStartAt: new Date().getTime() });
    this.wavesurfer.play(currentPosition);
  },

  handleReady() {
    let wavesurfer = this.wavesurfer;
    let nominalWidth = Math.round(
      wavesurfer.getDuration() * wavesurfer.params.minPxPerSec * wavesurfer.params.pixelRatio
    );
    let parentWidth = wavesurfer.drawer.getWidth();
    let width = nominalWidth;
    if (wavesurfer.params.fillParent && (!wavesurfer.params.scrollParent || nominalWidth < parentWidth)) {
      width = parentWidth;
    }
    let duration = wavesurfer.getDuration();
    this.props.onReady(duration);
    if (this.isRecordingPeaksEnabled()) {
      let recordingPeaks = wavesurfer.backend.getPeaks(width);
      this.setState({ recordingPeaks, ready: true, duration });
    } else {
      let generatedRecordingPeaks = [];
      let gen = rn.generator({ min: -0.65, max: 0.65, integer: false });
      let numberOfPeakValues = parentWidth / 1.45;
      for (let i = 0; i < numberOfPeakValues; i++) {
        generatedRecordingPeaks.push(gen());
      }
      this.setState({ ready: true, recordingPeaks: generatedRecordingPeaks, duration });
      this.wavesurfer.backend.setPeaks(this.state.recordingPeaks);
      this.wavesurfer.drawBuffer();
    }
    this.wavesurfer.toggleInteraction();
    this.handleTogglePlay();
  },

  trackPlayedRecording(duration) {
    analytics.track('Voice Recording played', {
      duration,
      recordingId: this.props.recordingId,
      customerId: this.props.customerId,
      contentType: this.props.contentType,
    });
  },

  render() {
    const elapsedTime = Timer.displayDuration(this.state.currentPosition * 1000);
    const totalTime = Timer.displayDuration(this.state.duration * 1000);

    if (!this.state.enabled) {
      return (
        <div className="wavePlayer-disabled" onClick={this.handleClickEnable}>
          <div className="wavePlayer-duration">{`${elapsedTime} / ${totalTime}`}</div>
          <div className="wavePlayer-disabled-background" />
          <i className={'wavePlayer-playStopButton icon-play'} />
        </div>
      );
    }

    let disabled = !this.state.ready;
    let classes = classnames('wavePlayer-playStopButton', {
      'icon-stop': this.state.playing,
      'icon-play': !this.state.playing,
      disabled,
    });
    return (
      <div className="wavePlayer-container">
        <div className="wavePlayer-waveformControlsContainer">
          <div className="wavePlayer-waveformContainer" ref="waveformContainer">
            {disabled ? (
              <span className="wavePlayer-spinner wavePlayer-spinner-visible">
                <i className="fa fa-spinner fa-pulse" />
              </span>
            ) : null}
          </div>
          <div className="wavePlayer-controlsContainer">
            <i className={classes} onClick={disabled ? _.noop : this.handleTogglePlay} />
          </div>
        </div>
        <div className="wavePlayer-duration">{`${elapsedTime} / ${totalTime}`}</div>
      </div>
    );
  },
});

export default WavePlayer;
