import OpenDataset from 'actions/reporting/open_dataset';
import { registerUrlConverter } from './location_url';
import Datasets from 'models/location/datasets';
import DatasetsUrlConverter from './url_converters/datasets_url_converter';

registerUrlConverter(Datasets, DatasetsUrlConverter);

export default function(router, { runnerContext }) {
  return {
    '/:slug': {
      on: slug => {
        runnerContext.executeAction(OpenDataset, getDatasetParams(slug));
      },
    },
  };

  function getDatasetParams(slug) {
    return DatasetsUrlConverter.fromUrlParameters({ slug, ...router.parseQueryParameters().toJS() });
  }
}
