import { useMemo, useRef } from 'react';

import createAutocompletePlugin from 'components/text_editor_new/plugins/autocomplete/create_autocomplete_plugin';
import { useGreetingSuggestions } from 'components/composer/contexts/greeting_suggestions_context';

export default function useCreateAutocompletePlugin() {
  const autocompleteRef = useRef({ isRemoving: false });
  const context = useGreetingSuggestions();

  return useMemo(() => {
    return createAutocompletePlugin(context, autocompleteRef);
  }, []);
}
