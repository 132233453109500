import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import SubTopicConnector from './sub_topic_connector.jsx';

export function TopicNameCell({ className, depth, name }) {
  return (
    <div className={classnames('topicNameCell', className)}>
      {depth > 0 ? <SubTopicConnector /> : null}
      {name}
    </div>
  );
}

TopicNameCell.propTypes = {
  depth: PropTypes.number,
  name: PropTypes.string,
};

export default styled(TopicNameCell)`
  cursor: default;
  margin-left: ${p =>
    p.depth > 0 ? `calc(${p.depth - 1} * (${p.theme.fontSize.base} + ${p.theme.spacing.medium}))` : '0px'};
  overflow: hidden;
  text-overflow: ellipsis;
`;
