import StandardGateway from './lib/standard_gateway';

export default class AgentStatusGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v3/orgs/:orgId/agent-status');
  }

  fetchAll(params, query) {
    const filter = params?.availability ? { 'f.a': params.availability } : {};
    const queryParams = { ...query, ...filter };
    return super.fetchAll(params, queryParams);
  }
}
