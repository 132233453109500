export const CONTEXT_CONVERSATION = 'iglu:com.gladly.agent_desktop/context_conversation/jsonschema/1-0-1';

export function getConversationContext({ conversationId, customerId }) {
  return {
    schema: CONTEXT_CONVERSATION,
    data: {
      id: conversationId,
      customerId,
    },
  };
}
