import React from 'react';
import PropTypes from 'prop-types';

import Communicator from 'models/communicator';
import ConversationNotification from 'models/notification/conversation_notification';
import NotificationContentContainer from './notification_content_container';
import parseAddress from 'addressparser';

function EmailMessageNotification({ notification }) {
  const emailContent = notification.conversationItem.content;
  let displayName = notification.profile.name || parseAddress(emailContent.from)[0].address;
  let heading = `Sent from ${displayName}`;

  if (
    notification.conversationItem.initiator.type === Communicator.INTERNAL ||
    notification.conversationItem.initiator.type === Communicator.EXTERNAL
  ) {
    displayName = notification.profile.name || 'Unknown Customer';
    heading = `Regarding ${displayName}`;
  }

  return (
    <NotificationContentContainer
      body={emailContent.subject}
      heading={heading}
      iconClass="icon-email-filled"
      itemType="Email"
      notification={notification}
    />
  );
}

EmailMessageNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default EmailMessageNotification;
