import createModel, { prop } from './lib/create_model';

const RuleDisabledLoop = createModel({
  modelName: 'RuleDisabledLoop',

  properties: {
    ruleId: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default RuleDisabledLoop;
