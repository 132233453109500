import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import ConversationItemType from 'models/conversation_item_type';
import ItemIcon from 'components/customer/conversation_history/conversation_items_v2/icon/item_icon';
import TypingIndicatorAnimation from 'components/customer/conversation_history/conversation_items_v2/content/typing_indicator_animation';
import TypingState from 'models/typing_state';

export function CustomerTypingIndicator(props) {
  return _.get(props.typingState, 'state') === TypingState.STARTED ? (
    <div className="customerTypingIndicator">
      <ItemIcon
        avatar={props.avatar}
        className="conversationItem-inboundIcon"
        itemType={ConversationItemType.CHAT_MESSAGE}
        name={props.name}
        showIcon={props.showIcon}
      />
      <TypingIndicatorAnimation />
    </div>
  ) : null;
}

CustomerTypingIndicator.propTypes = {
  avatar: PropTypes.string,
  typingState: PropTypes.shape({
    state: PropTypes.string,
    timestamp: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  onCustomerTypingTimeout: PropTypes.func,
  showIcon: PropTypes.bool,
};

function mapStateToProps({ getProvider }) {
  const profile = getProvider('profile').get();
  const activeSession = getProvider('activeSessions').findBy({ customer: { id: profile.id } });
  const typingState = _.get(activeSession, 'typingState');

  return {
    avatar: profile && profile.getImageUrl(),
    customerId: profile && profile.id,
    typingState,
    name: profile && profile.name,
    showIcon: true,
  };
}

const CustomerTypingIndicatorContainer = connect(mapStateToProps)(CustomerTypingIndicator);

export default CustomerTypingIndicatorContainer;
