import _ from 'lodash';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';

export default class RoutingGroupService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(requestorId, orgId) {
    return this.getDatabase(orgId).routingGroups;
  }

  delete(requestorId, orgId, routingGroupId) {
    _.remove(this.getDatabase(orgId).routingGroups, { id: routingGroupId });
  }

  add(requestorId, orgId, { correlationId, payload }) {
    this.getDatabase(orgId).routingGroups.push(payload);
    this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/routing-groups`, {
      correlationId,
      status: 'success',
    });

    this._pubsub.publish(`v1/orgs/${orgId}/routing-groups/${payload.id}`, { payload });
  }

  update(requestorId, orgId, routingGroupId, { correlationId, payload }) {
    let routingGroup = _.find(this.getDatabase(orgId).routingGroups, { id: routingGroupId });
    _.merge(routingGroup, payload, function(groupAttr, payloadAttr) {
      // Arrays in the payload (such as agentIds) should overwrite the previous array
      if (_.isArray(payloadAttr)) {
        return payloadAttr;
      }
    });
    this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/routing-groups/${routingGroupId}`, {
      correlationId,
      status: 'success',
    });

    this._pubsub.publish(`v1/orgs/${orgId}/routing-groups/${routingGroupId}`, { payload: routingGroup });
  }
}
