import CustomerView from 'models/location/customer_view';
import TrackEvent from 'actions/analytics/track_event';

export default class DeleteRelationship {
  constructor(context) {
    this.context = context;
  }

  run({ id }) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    this.context.gateways.relationship.delete({ customerId: currentLocation.customerId, relationshipId: id });

    this.context.executeAction(TrackEvent, {
      event: `Relationships - Labels - Delete Relationship`,
      props: { originalCustomerId: currentLocation.customerId },
    });
  }
}
