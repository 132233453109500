import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Morearty from 'morearty';

import ActionMixin from 'components/lib/action_mixin';
import AppFeaturesMixin from 'components/lib/app_features_mixin';
import OpenAttachmentPreview from 'actions/modal/open_attachment_preview';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';

export function createAttachmentContainer(mixin) {
  return createReactClass(
    _.extend(
      {
        mixins: [ActionMixin, AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

        propTypes: {
          attachment: PropTypes.shape({ id: PropTypes.string, fileDescriptor: PropTypes.func, isImage: PropTypes.func })
            .isRequired,
          attachments: PropTypes.arrayOf(
            PropTypes.shape({ id: PropTypes.string, fileDescriptor: PropTypes.func, isImage: PropTypes.func })
          ).isRequired,
          ...mixin.propTypes,
        },

        /* Actions */

        openAttachmentPreview() {
          let orgId = this.getProvider('auth')
            .get()
            .getOrgId();
          let currentIndex = _.chain(this.props.attachments)
            .map(a => a.id)
            .indexOf(this.props.attachment.id)
            .value();
          this.executeAction(OpenAttachmentPreview, {
            attachmentIndex: currentIndex,
            attachments: this.props.attachments,
            downloadUrls: _.map(this.props.attachments, attachment => this.getAttachmentUrl(orgId, attachment)),
          });
        },

        /* Helpers */

        getAttachmentUrl(orgId, attachment) {
          throw new Error('Concrete class must implement `getAttachmentUrl`');
        },

        attachmentUrl() {
          return this.getAttachmentUrl(
            this.getProvider('auth')
              .get()
              .getOrgId(),
            this.props.attachment
          );
        },

        /* Render */

        render() {
          return this.renderAttachment();
        },

        renderAttachment() {
          throw new Error('Concrete class must implement `renderAttachment`');
        },
      },
      mixin
    )
  );
}
