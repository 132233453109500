import MqttClient from './mqtt_client';
import URL from 'url';

export default function createMqttClient(eventRecorder, clientId) {
  var brokerUrl = URL.format({
    protocol: getProtocol(),
    slashes: true,
    hostname: window.location.hostname,
    port: window.location.port,
    pathname: '/edge',
  });
  return new MqttClient({ url: brokerUrl, eventRecorder, clientId });
}

function getProtocol() {
  return window.location.protocol.replace(/^http/, 'ws');
}
