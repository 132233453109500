import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import CustomerCombine from 'models/location/customer_combine';
import DeleteCustomer from 'models/modal/delete_customer';
import ExportCustomer from 'actions/compliance/customer/export_customer';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { MenuContainer, MenuItem } from 'components/lib/menu_deprecated';
import MenuIcon from 'components/lib/icons/menu_icon';
import NavigateTo from 'actions/current_location/navigate_to';
import OpenModal from 'actions/modal/open_modal';
import ProfileCardType from 'models/configuration/profile_card_type';
import RefreshExternalCustomerData from 'actions/external_customer_lookup/refresh_external_customer_data';
import Tooltip from 'components/common/tooltip';
import UnlinkProfile from 'models/modal/unlink_profile';

export class CustomerSummaryMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    _.bindAll(this, [
      'handleClose',
      'handleOpen',
      'handleSelectCombineCustomers',
      'handleDeleteCustomer',
      'handleExportCustomer',
    ]);
  }

  render() {
    return (
      <MenuContainer
        button={<MenuIcon className={this.getButtonClassName()} />}
        className="customerSummaryMenu"
        onClose={this.handleClose}
        onOpen={this.handleOpen}
      >
        {this.renderUnlinkProfile()}
        {this.renderCombineCustomers()}
        {this.renderRefreshExternalData()}
        {this.renderExportCustomer()}
        {this.renderDeleteCustomer()}
      </MenuContainer>
    );
  }

  renderRefreshExternalData() {
    if (!this.props.shouldShowRefreshData) {
      return null;
    }

    return (
      <MenuItem
        className="customerSummaryMenu-item customerSummaryMenu-refreshCustomer"
        onSelect={() => this.props.onSelectRefreshCustomerData(this.props.dataRefreshParameters)}
      >
        Refresh customer data
      </MenuItem>
    );
  }

  renderCombineCustomers() {
    return (
      <MenuItem
        className="customerSummaryMenu-item  customerSummaryMenu-combineCustomers"
        onSelect={this.handleSelectCombineCustomers}
      >
        Merge Customers...
      </MenuItem>
    );
  }

  renderUnlinkProfile() {
    if (!this.props.shouldShowUnlinkProfile) {
      return null;
    }
    return (
      <MenuItem
        className="customerSummaryMenu-item customerSummaryMenu-unlinkCustomer"
        onSelect={this.props.onSelectUnlinkCustomer}
      >
        Unlink Customer
      </MenuItem>
    );
  }

  renderDeleteCustomer() {
    if (!this.props.shouldShowDeleteCustomer) {
      return null;
    }

    if (this.props.isDeleteCustomerDisabled) {
      const tooltipMessage = 'Please close the most recent conversation before deleting this customer.';
      return (
        <Tooltip
          className="deleteCustomer-disabled-Tooltip"
          isVisible={this.props.isDeleteCustomerDisabled}
          message={<TooltipMessage>{tooltipMessage}</TooltipMessage>}
        >
          {this.renderDeleteCustomerMenuItem()}
        </Tooltip>
      );
    }

    return this.renderDeleteCustomerMenuItem();
  }

  renderDeleteCustomerMenuItem() {
    return (
      <DeleteMenuItem
        className="customerSummaryMenu-item customerSummaryMenu-deleteCustomer"
        isDisabled={this.props.isDeleteCustomerDisabled}
        onSelect={this.handleDeleteCustomer}
      >
        Delete Customer
      </DeleteMenuItem>
    );
  }

  renderExportCustomer() {
    if (!this.props.shouldShowExportCustomer) {
      return null;
    }

    return (
      <MenuItem
        className="customerSummaryMenu-item customerSummaryMenu-exportCustomer"
        onSelect={this.handleExportCustomer}
      >
        Export Customer Profile
      </MenuItem>
    );
  }

  getButtonClassName() {
    return classnames('customerSummaryMenu-button-icon', {
      'customerSummaryMenu-button-icon-active': this.state.isOpen,
    });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleSelectCombineCustomers() {
    if (this.props.onSelectCombineCustomers) {
      this.props.onSelectCombineCustomers();
    }
  }

  handleDeleteCustomer() {
    if (this.props.onSelectDeleteCustomer) {
      this.props.onSelectDeleteCustomer();
    }
  }

  handleExportCustomer() {
    if (this.props.onSelectExportCustomer) {
      this.props.onSelectExportCustomer();
    }
  }
}

const DeleteMenuItem = styled(MenuItem)`
  border-top: 1px solid #cecece;
  color: ${p => p.theme.colors.red400};
  &:hover {
    background: ${p => p.theme.colors.red400};
  }
  &.menuItem-disabled {
    color: ${p => p.theme.colors.gray400};
    &:hover {
      background: transparent;
    }
  }
`;

const TooltipMessage = styled.div`
  text-align: center;
  width: 265px;
`;

CustomerSummaryMenu.propTypes = {
  dataRefreshParameters: PropTypes.object,
  isDeleteCustomerDisabled: PropTypes.bool,
  onSelectCombineCustomers: PropTypes.func,
  onSelectDeleteCustomer: PropTypes.func,
  onSelectExportCustomer: PropTypes.func,
  onSelectRefreshCustomerData: PropTypes.func,
  onSelectUnlinkCustomer: PropTypes.func,
  shouldShowDeleteCustomer: PropTypes.bool,
  shouldShowExportCustomer: PropTypes.bool,
  shouldShowRefreshData: PropTypes.bool,
  shouldShowUnlinkProfile: PropTypes.bool,
};

const CustomerSummaryMenuContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerSummaryMenu);

CustomerSummaryMenuContainer.propTypes = {
  customerId: PropTypes.string.isRequired,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const profile = getProvider('profile').get();
  const integrations = getProvider('integrations').findAll();
  const customerProfileDef = getProvider('customerProfileDef').get();

  const customerIsLinked = !!(profile && (profile.externalCustomerId || !_.isEmpty(profile.externalCustomerIds)));
  const enabledIntegration =
    customerIsLinked &&
    integrations.find(integration => {
      return integration.enabled && !!_.get(profile.externalCustomerIds, integration.id);
    });

  // Check whether the customer may potentially have external data enabled integrations, display refresh menu item if yes
  const profileCards = _.get(customerProfileDef, 'profileCards') || [];
  const hasEnabledIntegrations = !!integrations.find(integration => integration.enabled);
  const hasFlexibleCards = !!_.find(profileCards, card => card.type === ProfileCardType.FLEXIBLE_CARD);
  const hasExternalAppConfigured = hasEnabledIntegrations && hasFlexibleCards;

  const conversationProvider = getProvider('conversations');
  const hasActiveConversation = !!getActiveConversationId(conversationProvider);

  return {
    dataRefreshParameters: {
      shouldRequestCustomerExtension: !!enabledIntegration,
      shouldRequestExternalData: hasExternalAppConfigured,
    },
    isDeleteCustomerDisabled: hasActiveConversation,
    shouldShowRefreshData: !!enabledIntegration || hasExternalAppConfigured,
    shouldShowUnlinkProfile: customerIsLinked,
    shouldShowDeleteCustomer: isFeatureEnabled('complianceManagement'),
    shouldShowExportCustomer: isFeatureEnabled('complianceManagement'),
  };
}

function mapExecuteToProps(executeAction, { customerId }) {
  return {
    onSelectCombineCustomers: () => executeAction(NavigateTo, CustomerCombine.create({ customerId })),
    onSelectRefreshCustomerData: (params = {}) => executeAction(RefreshExternalCustomerData, params),
    onSelectUnlinkCustomer: () => executeAction(OpenModal, new UnlinkProfile({ customerId })),
    onSelectDeleteCustomer: () => executeAction(OpenModal, new DeleteCustomer({ customerId })),
    onSelectExportCustomer: () => executeAction(ExportCustomer, { customerId }),
  };
}

export default CustomerSummaryMenuContainer;
