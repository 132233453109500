import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('Endpoint')
  .extend('Uuid')
  .attr('configuration', c => c);

Factory.define('ChatEndpoint')
  .extend('Endpoint')
  .attr('type', 'CHAT')
  .attr('address', 'a-chat-endpoint-address');

Factory.define('EmailEndpoint')
  .extend('Endpoint')
  .attr('type', 'EMAIL')
  .attr('address', 'support@company.com');

Factory.define('FbMessengerEndpoint')
  .extend('Endpoint')
  .attr('type', 'FB_MESSENGER');

Factory.define('FbMessengerEndpointWithDefaults')
  .extend('FbMessengerEndpoint')
  .attr('address', 'example-page-id')
  .attr('displayName', 'My Facebook Page');

Factory.define('InstagramDirectEndpoint')
  .extend('Endpoint')
  .attr('type', 'INSTAGRAM_DIRECT');

Factory.define('InstagramDirectEndpointWithDefaults')
  .extend('Endpoint')
  .attr('address', 'example-page-id')
  .attr('type', 'INSTAGRAM_DIRECT');

Factory.define('SmsEndpoint')
  .extend('Endpoint')
  .attr('type', 'SMS')
  .attr('address', '4152333160');

Factory.define('VoiceEndpoint')
  .extend('Endpoint')
  .attr('type', 'VOICE')
  .attr('address', '4152333160');

Factory.define('TwitterEndpoint')
  .extend('Endpoint')
  .attr('address', 'twitterAddress')
  .attr('type', 'TWITTER');

Factory.define('WhatsAppEndpoint')
  .extend('Endpoint')
  .attr('address', '+14152333160')
  .attr('type', 'WHATSAPP');

Factory.define('CustomChannelEndpoint')
  .extend('Endpoint')
  .attr('type', 'CUSTOM_CHANNEL')
  .attr('configuration', { customChannelId: '1' })
  .attr('address', 'CUSTOM_CHANNEL:1');

Factory.define('EndpointWithDefaults').extend('EmailEndpoint');
