import React, { useMemo } from 'react';

import connect from 'components/lib/connect';

export const DEFAULT_IS_FEATURE_ENABLED_CONTEXT = null;

const IsFeatureEnabledContext = React.createContext(DEFAULT_IS_FEATURE_ENABLED_CONTEXT);
export default IsFeatureEnabledContext;

export function IsFeatureEnabledProviderBase({ appFeatures, children }) {
  const value = useMemo(() => appFeatures, [appFeatures]);

  return <IsFeatureEnabledContext.Provider value={value}>{children}</IsFeatureEnabledContext.Provider>;
}

export const IsFeatureEnabledProvider = connect(mapStateToProps)(IsFeatureEnabledProviderBase);

function mapStateToProps({ getProvider }) {
  return {
    appFeatures: getProvider('appFeatures').get(),
  };
}
