import React, { useContext, useCallback, useState } from 'react';
import styled from 'styled-components';

import DatetimePickerMenu from 'components/lib/datetime_picker_menu';
import { getDatetimeFromTimestamp, getTimestampFromDatetime } from 'components/lib/date/datetime_helpers';
import UpdateComposition from 'actions/composer/update_composition';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import ComposerContext from 'components/composer/contexts/composer_context';

const StyledDatePickerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDatePickerDue = styled.div`
  align-items: center;
  color: #999999;
  display: flex;
  font-size: 12px;
  padding-right: 10px;
  line-height: 12px;
`;

const StyledDatePicker = styled.div`
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export default function DatePicker() {
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();
  const compositionId = composition.id;
  const [timestamp, setTimestamp] = useState(getDatetimeFromTimestamp(composition.content.dueAt));
  const onChangeDatetime = useCallback(
    attrs => {
      if (attrs.date) {
        let ts = getTimestampFromDatetime(attrs);
        setTimestamp(attrs);
        executeAction(UpdateComposition, {
          compositionId,
          attrs: {
            dueAt: ts,
          },
        });
      }
    },
    [compositionId, executeAction]
  );
  return (
    <StyledDatePickerContainer>
      <StyledDatePickerDue>Due</StyledDatePickerDue>
      <StyledDatePicker>
        <DatetimePickerMenu
          className="taskCompositionForm-timePickerMenu"
          onChange={onChangeDatetime}
          placeholderText="Due date"
          value={timestamp}
        />
      </StyledDatePicker>
    </StyledDatePickerContainer>
  );
}
