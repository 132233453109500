import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import connect from 'components/lib/connect';
import { createPhraseSuggestionSearcher } from 'models/phrase_suggestion';
import TrackPhraseSuggestionShown from 'actions/phrase_suggestions/track_phrase_suggestion_shown';
import TrackPhraseSuggestionUsed from 'actions/phrase_suggestions/track_phrase_suggestion_used';
import { useVariables } from './variables_context';

export const DEFAULT_PHRASE_SUGGESTIONS_CONTEXT = {
  getPhraseSuggestion: () => null,
};

const PhraseSuggestionsContext = React.createContext(DEFAULT_PHRASE_SUGGESTIONS_CONTEXT);

export function PhraseSuggestionsContextProviderBase({
  children,
  onPhraseSuggestionShown,
  onPhraseSuggestionUsed,
  phraseSuggestions,
  phraseSuggestionsImm,
}) {
  const variables = useVariables();
  const getPhraseSuggestion = useGetPhraseSuggestion(phraseSuggestions, phraseSuggestionsImm, variables);

  const value = useMemo(
    () => ({
      getPhraseSuggestion,
      onPhraseSuggestionShown,
      onPhraseSuggestionUsed,
    }),
    [getPhraseSuggestion, onPhraseSuggestionShown, onPhraseSuggestionUsed]
  );

  return <PhraseSuggestionsContext.Provider value={value}>{children}</PhraseSuggestionsContext.Provider>;
}

const PhraseSuggestionsContextProvider = connect(
  mapStateToProps,
  mapExecuteToProps
)(PhraseSuggestionsContextProviderBase);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const phraseSuggestionsProvider = getProvider('phraseSuggestions');
  return {
    phraseSuggestions: phraseSuggestionsProvider.findAll(),
    phraseSuggestionsImm: phraseSuggestionsProvider.immutableStore.binding.get(),
  };
}

let onPhraseSuggestionShown;
let onPhraseSuggestionUsed;

function mapExecuteToProps(executeAction) {
  if (!onPhraseSuggestionShown) {
    onPhraseSuggestionShown = (charactersMatched, phraseSuggestion, compositionId) => {
      executeAction(TrackPhraseSuggestionShown, {
        charactersMatched,
        phraseSuggestion,
        compositionId,
      });
    };
  }
  if (!onPhraseSuggestionUsed) {
    onPhraseSuggestionUsed = (charactersMatched, insertKey, phraseSuggestion, compositionId) => {
      executeAction(TrackPhraseSuggestionUsed, {
        charactersMatched,
        insertKey,
        phraseSuggestion,
        compositionId,
      });
    };
  }
  return {
    onPhraseSuggestionShown,
    onPhraseSuggestionUsed,
  };
}

export default function PhraseSuggestionsProvider({ children }) {
  return <PhraseSuggestionsContextProvider>{children}</PhraseSuggestionsContextProvider>;
}

function useGetPhraseSuggestion(phraseSuggestions, phraseSuggestionsImm, variables) {
  // This is probably not the best place to calculate the populated phrase suggestions, but for now this
  // is the best place I can think of.

  // We're going to be passing the getPhraseSuggestion function down into the PhraseSuggestions plugin.
  // Plugins don't have direct access to the "outside world" as they live inside slate. If some value
  // for a variable changes, we'll need to recheck all of the phrase suggestions, as they may be
  // relying on the variable that changed.

  // But it's inefficient to be constantly reconstructing plugins (and Slate yells at us). So we need
  // to only reconstruct the PhraseSuggestions plugin when variables change.

  // Or, instead of reconstructing the plugin, we could just change what is returned when getPhraseSuggestion
  // is called. That's what we're doing here.

  const phraseSuggestionsRef = useRef(null);
  const previous = useRef({
    phraseSuggestionsImm,
    variables,
  });

  useEffect(() => {
    // We cache the suggestion searcher on the ref.
    if (phraseSuggestionsRef.current == null) {
      const populatedSuggestions = populateSuggestions(variables, phraseSuggestions);
      phraseSuggestionsRef.current = createPhraseSuggestionSearcher(populatedSuggestions);
    }

    // We recreate the searcher on the ref if phrase suggestions or variables change.
    if (phraseSuggestionsImm !== previous.current.phraseSuggestionsImm || variables !== previous.current.variables) {
      const populatedSuggestions = populateSuggestions(variables, phraseSuggestions);
      phraseSuggestionsRef.current = createPhraseSuggestionSearcher(populatedSuggestions);
    }

    previous.current = {
      phraseSuggestionsImm,
      variables,
    };
  }, [phraseSuggestions, phraseSuggestionsImm, variables]);

  // Here's the trick: the callback we return has a reference to the ref, but not the actual function on the ref.
  // So we can update the ref without the plugin having to know, and things will work as expected.
  return useCallback(text => phraseSuggestionsRef.current(text), []);
}

function populateSuggestions(variables, suggestions) {
  return _.compact(
    _.map(suggestions, suggestion => {
      if (suggestion.text.indexOf('<variable') >= 0) {
        const [populatedText, valid] = getPopulatedText(variables, suggestion);
        // If the suggestion contains an unpopulated variable, we'll just drop it from the list
        if (!valid) {
          return null;
        }

        return {
          id: suggestion.id,
          text: suggestion.text,
          populatedText,
          score: suggestion.score,
        };
      }
      return { ...suggestion, populatedText: suggestion.text };
    })
  );
}

function getPopulatedText(variables, suggestion) {
  let isValid = true;
  const awfulVariableRegex = /<variable.+?data-type="(.+?)">.+?<\/variable>/gi;
  const populatedText = suggestion.text.replace(awfulVariableRegex, textReplacer);
  return [populatedText, isValid];

  function textReplacer(_match, type) {
    const variable = _.find(variables, v => v.type === type);
    if (!variable) {
      isValid = false;
    }
    return _.get(variable, 'text', '');
  }
}

export function usePhraseSuggestions() {
  return useContext(PhraseSuggestionsContext);
}
