import { Factory } from 'rosie';
import './lib/id';

Factory.define('AnswerPanelState').extend('Id');

Factory.define('AnswerPanelStateWithDefaults')
  .extend('AnswerPanelState')
  .attr('isAnswerPanelOpen', false)
  .attr('history', ['example-1']);

Factory.define('DirectAnswerLink').extend('Id');

Factory.define('DirectAnswerLinkWithDefaults')
  .extend('DirectAnswerLink')
  .attr('type', 'SELF_SERVICE')
  .attr('id', 'answer-1')
  .attr('timestamp', '2019-04-15T02:59:04.098Z');
