import _ from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';

function StyledLic({ attributes, children }) {
  const finalAttributes = _.omit(attributes, ['dir']);
  return <div {...finalAttributes}>{children}</div>;
}

function StyledOl({ attributes, children }) {
  return (
    <OlWithoutExtraPadding {...attributes} dir="auto">
      {children}
    </OlWithoutExtraPadding>
  );
}

function StyledUl({ attributes, children }) {
  return (
    <UlWithoutExtraPadding {...attributes} dir="auto">
      {children}
    </UlWithoutExtraPadding>
  );
}

const sharedListStyles = css`
  margin: 4px 0;
  padding-inline-start: 16px;
`;

const UlWithoutExtraPadding = styled.ul`
  ${sharedListStyles}
`;
const OlWithoutExtraPadding = styled.ol`
  ${sharedListStyles}
`;

export { StyledLic, StyledOl, StyledUl };
