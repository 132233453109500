import { onError } from 'scripts/adapters/gateways/lib/standard_gateway/http_adapter';

export default class ReportingApiGateway {
  constructor(backend) {
    this.backend = backend;
  }

  get http() {
    return this.backend.axios();
  }

  download(params) {
    return this.http
      .post('/api/v1/reports', params, { timeout: 1200000, responseType: 'blob' })
      .then(response => {
        const url = this._createUrl(response);
        const link = this._createLink();
        link.href = url;
        this._setAttribute(link, response);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(err => {
        this._handleError(err);
      });
  }

  _createUrl(response) {
    return URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
  }

  _createLink() {
    return document.createElement('a');
  }

  _handleError(err) {
    onError(err);
  }

  _setAttribute(link, response) {
    link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]);
  }
}
