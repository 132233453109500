import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DownChevronIcon from 'components/lib/icons/chevron_down_icon';
import ExpandedTransaction from 'components/customer/summary/transactions/expanded_transaction';
import {
  FlightAttributesPropTypes,
  OrderAttributesPropTypes,
  StayAttributesPropTypes,
  SubscriptionAttributesPropTypes,
} from 'components/customer/summary/transactions/constants';
import {
  FlightTransactionDef,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
} from 'models/configuration/transaction_def';

export default function GeneralTransaction({
  attributes,
  className,
  headerRenderer,
  isExpanded,
  shouldRenderImages,
  summaryRenderer,
  transactionDef,
  onExpand,
}) {
  const [expanded, setExpanded] = useState(!!isExpanded);
  const buttonClassNames = classnames('transaction-expandInfoButton', {
    'transaction-expandInfoButton-open': expanded,
  });

  const handleClick = evt => {
    evt && evt.preventDefault();
    onExpand && onExpand(!expanded);
    setExpanded(!expanded);
  };
  const transactionSummary = summaryRenderer(attributes, { shouldRenderImage: shouldRenderImages });

  return (
    <TransactionRow className={classnames('transaction', className)}>
      <StyledSummaryRow
        className="transaction-summary-row"
        expanded={expanded}
        onClick={handleClick}
        withImage={shouldRenderImages}
      >
        {transactionSummary}
        <StyledChevronWrapper className={buttonClassNames}>
          <DownChevronIcon />
        </StyledChevronWrapper>
      </StyledSummaryRow>
      <ExpandedTransaction
        attributes={attributes}
        headerRenderer={headerRenderer}
        isExpanded={expanded}
        shouldRenderImages={shouldRenderImages}
        transactionDef={transactionDef}
      />
    </TransactionRow>
  );
}

const StyledChevronWrapper = styled.div`
  height: 16px;
  min-width: 16px;
  width: 16px;
`;

export const StyledSummaryRow = styled.div`
  align-items: center;
  background-color: ${p => (p.expanded ? p.theme.colors.gray100 : 'inherit')};
  border-radius: ${p => p.theme.borderRadius.xsmall};
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  padding: ${p => (p.withImage ? '4px 10px 4px 4px' : '4px 10px')};
  margin: 0 -8px;

  &:hover {
    background-color: ${p => p.theme.colors.gray100};
  }
`;

const TransactionRow = styled.div`
  font-size: 12px;
  padding-top: 4px;

  /* Add a border between adjacent rows but only if they have "row-separator" class. See CSS "+" selector */
  & + &.row-separator {
    border-top: 1px solid ${p => p.theme.colors.gray200};
    margin-top: 4px;
  }
`;

GeneralTransaction.propTypes = {
  attributes: PropTypes.oneOfType([
    FlightAttributesPropTypes,
    OrderAttributesPropTypes,
    StayAttributesPropTypes,
    SubscriptionAttributesPropTypes,
  ]),
  className: PropTypes.string,
  headerRenderer: PropTypes.func,
  isExpanded: PropTypes.bool,
  shouldRenderImages: PropTypes.bool,
  summaryRenderer: PropTypes.func.isRequired,
  transactionDef: PropTypes.oneOfType([
    PropTypes.instanceOf(FlightTransactionDef),
    PropTypes.instanceOf(OrderTransactionDef),
    PropTypes.instanceOf(StayTransactionDef),
    PropTypes.instanceOf(SubscriptionTransactionDef),
  ]).isRequired,
  onExpand: PropTypes.func,
};
