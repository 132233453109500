import AITextCompletion from 'models/ai_text_completion';
import { DomainError } from 'scripts/application/lib/error';
import Err from 'models/err';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import qconsole from 'scripts/lib/qconsole';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default function postAITextCompletionRequest(context, params) {
  const { customerId, requestorId, rewriteId, type } = params;
  const { aiTextCompletions } = context.stores.customers.storesFor(customerId);
  aiTextCompletions.setLoading({ id: requestorId });

  return context.gateways.aiTextCompletion
    .add({}, params)
    .then(responseDto => {
      const result = AITextCompletion.create({
        data: {
          initialText: params.text,
          ...responseDto.data,
        },
        id: requestorId,
        rewriteId,
        type,
      });

      aiTextCompletions.addOrReplace(result);
      aiTextCompletions.clearErrorForLoading({ id: requestorId });
    })
    .catch(errDto => {
      qconsole.log(`Failed to request AI text completion for params [${JSON.stringify(params)}]`);

      if (errDto instanceof DomainError) {
        const err = Err.fromJs(errDto.errors[0]);

        let errorMsg = 'There was a problem with your authoring request. Please try again later.';
        let errorType = ToastType.ERROR;
        if (err.code === Err.Code.BLANK) {
          errorType = ToastType.INFO;
          errorMsg = "It doesn't look like any text is selected. Select some text and try again.";
        } else if (err.code === Err.Code.TOO_LONG) {
          errorType = ToastType.INFO;
          errorMsg = 'Your selected text is too long. Try again with less text.';
        }

        aiTextCompletions.setErrorForLoading(err, { id: requestorId });
        context.executeAction(ShowToast, {
          type: errorType,
          message: errorMsg,
        });
      } else {
        handleCommonErrors(context, errDto);
      }
    })
    .finally(() => {
      aiTextCompletions.resetLoading({ id: requestorId });
    });
}
