import _ from 'lodash';

import createConverter from 'scripts/application/dto_converters/lib/create_converter';
import SnippetSearchResult from 'models/search/snippet_search_result';
import { snippetDtoFieldChanges } from 'scripts/application/dto_converters/snippet_converter';

const snippetSearchResultConverter = createConverter(SnippetSearchResult);

export default {
  fromDto: dto => {
    let newDto = _.cloneDeep(dto);
    newDto.hits = newDto.hits.map(snippetDto => snippetDtoFieldChanges(snippetDto));
    return snippetSearchResultConverter.fromDto(newDto);
  },
};
