import moment from 'moment';
import { Factory } from 'rosie';

Factory.define('SnippetRevision');

Factory.define('SnippetRevisionWithDefaults')
  .extend('SnippetRevision')
  .attr('id', 'some-revision-id')
  .attr('agentId', 'some-agent-id')
  .attr('updatedAt', () => moment().toISOString());
