import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import OutsideClickHandler from 'components/common/utilities/outside_click_handler';

class DraftStylesTableMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    _.bindAll(this, [
      'getCellClassName',
      'handleCellMouseover',
      'handleClickOutside',
      'handleClickRow',
      'handleClickToggle',
      'onClose',
      'renderGrid',
      'renderRow',
    ]);
  }

  render() {
    const classNames = classnames('draftStylesMenu-item', 'draftStylesMenu-item-table', {
      'draftStylesMenu-item-active': this.state.isOpen,
    });
    const chevronDir = this.state.isOpen ? 'up' : 'down';

    return (
      <OutsideClickHandler onClickOutside={this.handleClickOutside}>
        <span className="draftStylesTableMenu">
          <div className={classNames} onClick={this.handleClickToggle} title="Table">
            <span>
              <i className="fa fa-table" />
            </span>
            <i className={`draftStylesMenu-item-chevron fa fa-angle-${chevronDir}`} />
          </div>
          {this.renderGrid()}
        </span>
      </OutsideClickHandler>
    );
  }

  renderGrid() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <div className="draftStylesTableMenu-grid" onMouseDown={ev => ev.preventDefault()}>
        {this.renderRow('1')}
        {this.renderRow('2')}
        {this.renderRow('3')}
        {this.renderRow('4')}
        {this.renderRow('5')}
        {this.renderRow('6')}
      </div>
    );
  }

  renderRow(rowNum) {
    const rowClassnames = classnames('draftStylesTableMenu-row', `draftStylesTableMenu-row-${rowNum}`);
    return (
      <div className={rowClassnames} onClick={this.handleClickRow}>
        <div
          className={this.getCellClassName(rowNum, '1')}
          onMouseOver={this.handleCellMouseover.bind(this, rowNum, '1')}
        />
        <div
          className={this.getCellClassName(rowNum, '2')}
          onMouseOver={this.handleCellMouseover.bind(this, rowNum, '2')}
        />
        <div
          className={this.getCellClassName(rowNum, '3')}
          onMouseOver={this.handleCellMouseover.bind(this, rowNum, '3')}
        />
        <div
          className={this.getCellClassName(rowNum, '4')}
          onMouseOver={this.handleCellMouseover.bind(this, rowNum, '4')}
        />
        <div
          className={this.getCellClassName(rowNum, '5')}
          onMouseOver={this.handleCellMouseover.bind(this, rowNum, '5')}
        />
        <div
          className={this.getCellClassName(rowNum, '6')}
          onMouseOver={this.handleCellMouseover.bind(this, rowNum, '6')}
        />
      </div>
    );
  }

  // Handlers

  onClose() {
    this.setState({ isOpen: false, rows: undefined, columns: undefined });
  }

  handleClickToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleClickRow() {
    this.props.onCreateTable(this.state.rows, this.state.columns);
    this.onClose();
  }

  handleCellMouseover(rowNum, colNum) {
    this.setState({ rows: rowNum, columns: colNum });
  }

  handleClickOutside() {
    this.onClose();
  }

  // Helpers

  getCellClassName(rowNum, colNum) {
    return classnames('draftStylesTableMenu-cell', `draftStylesTableMenu-row-${rowNum}-col-${colNum}`, {
      'draftStylesTableMenu-cell-active': this.state.rows >= rowNum && this.state.columns >= colNum,
    });
  }
}

DraftStylesTableMenu.propTypes = {
  onCreateTable: PropTypes.func.isRequired,
};

export default DraftStylesTableMenu;
