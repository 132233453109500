import AgentPreferences from 'models/agent_preferences';
import clearExcessSnowplowStorage from 'scripts/adapters/stores/lib/clear_excess_snowplow_storage';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getStorageSizes from 'scripts/adapters/stores/lib/get_storage_sizes';

const AGENT_PREFERENCES_KEY = 'gladly-agent-preferences';

export default class AgentPreferencesLocalStore {
  get() {
    const preferences = localStorage.getItem(AGENT_PREFERENCES_KEY);
    return AgentPreferences.fromJs((preferences && JSON.parse(preferences)) || {});
  }

  set(preferences) {
    try {
      localStorage.setItem(AGENT_PREFERENCES_KEY, JSON.stringify(preferences.toJs()));
    } catch (e) {
      const sizes = getStorageSizes(localStorage);
      clearExcessSnowplowStorage(localStorage);
      ErrorReporter.reportError(e, {
        extra: {
          sizes,
          sizesAfterClearing: getStorageSizes(localStorage),
        },
      });
    }
  }

  clear() {
    this.set(new AgentPreferences());
  }
}
