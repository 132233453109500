import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons/faPaperPlaneTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePlateEditorState } from '@udecode/plate';

import Button from 'components/common/button';
import { editorHasNoText } from 'components/text_editor_new/lib/editor_has_no_text';

export default function Send({ onSubmit }) {
  const editor = usePlateEditorState();

  return (
    <StyledSend disabled={editorHasNoText(editor)} onClick={onSubmit} onMouseDown={evt => evt.preventDefault()}>
      <FontAwesomeIcon icon={faPaperPlaneTop} />
    </StyledSend>
  );
}

export const StyledSend = styled(Button)`
  margin-top: 4px;
  padding: 4px 12px;
`;

Send.propTypes = {
  onSubmit: PropTypes.func,
};
