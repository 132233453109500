import _ from 'lodash';

export default class AttachmentService {
  constructor(datasets) {
    this.datasets = datasets;
  }

  getAttachmentUrl(orgId, attachmentId) {
    let attachment = _(this.datasets.getDatabase(orgId).customers)
      .map('conversationHistory')
      .flatten()
      .map('content')
      .filter(c => c.type === 'EMAIL')
      .map('attachments')
      .flatten()
      .compact()
      .find({ id: attachmentId });

    let url = attachment
      ? attachment.url || `attachment-${attachmentId}-has-no-url`
      : `attachment-${attachmentId}-not-found`;

    return { url };
  }
}
