import { InboxItemType } from 'models/agent_inbox/agent_inbox_item';
import NavigateToCustomerProfile from 'actions/customer/navigate_to_customer_profile';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';

export default class NavigateToInboxItem {
  constructor(context) {
    this.context = context;
  }

  run({ item, collapsed, index, needsAttention }) {
    if (item.type === InboxItemType.TASK) {
      this.context.executeAction(NavigateToItem, { customerId: item.profile.id, itemId: item.item.id });
    } else {
      this.context.executeAction(NavigateToCustomerProfile, { customerId: item.id });
    }

    this.context.analytics.track('Customer List Item Clicked', {
      collapsed,
      isTask: item.type === InboxItemType.TASK,
      needsAttention,
      rank: index,
    });
  }
}
