import PropTypes from 'prop-types';
import React from 'react';

import componentFromItemContent from './component_from_item_content';
import connect from 'components/lib/connect';
import ConversationItemModel from 'models/conversation_item';
import { HandleHeightChange } from 'scripts/presentation/components/customer/conversation_history/virtualized/list_item';
import { ItemContextProvider } from './conversation_items_v2/item_context';
import { loadingItems } from 'actions/customer/feed/fetch_more_items';
import PlaceholderItem from './conversation_items_v2/placeholder/placeholder_item';

export class ConversationItem extends React.PureComponent {
  componentDidUpdate() {
    this.props.onHeightChange && this.props.onHeightChange();
  }

  render() {
    const { conversationId, isFirstInStack, isLastInStack, isStacked, item, itemId } = this.props;
    if (!item && loadingItems.has(itemId)) {
      return <PlaceholderItem itemId={itemId} />;
    } else if (!item) {
      return null;
    }

    // These props are relevant to / used by _all_ conversation items. Do NOT add props that are relevant to only
    // one specific item type.
    const props = {
      conversationId,
      isFirstInStack,
      isLastInStack,
      isStacked,
      item,
    };

    let ItemComponent = componentFromItemContent(item.content);
    return (
      (ItemComponent && (
        <ItemContextProvider item={item}>
          <ItemComponent {...props} />
        </ItemContextProvider>
      )) ||
      null
    );
  }
}

ConversationItem.propTypes = {
  conversationId: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItemModel),
  itemId: PropTypes.string.isRequired,
  onHeightChange: PropTypes.func,
  previousItemId: PropTypes.string,
};

function mapStateToProps({ getProvider }, props) {
  const provider = getProvider('conversationHistory');
  let item = provider.findBy({ id: props.itemId });
  item = item && provider.isPending(props.itemId) ? provider.getPending(props.itemId) : item;

  return {
    item,
  };
}

const ConversationItemContainer = connect(mapStateToProps)(ConversationItem);
ConversationItemContainer.propTypes = {
  conversationId: PropTypes.string,
  isStacked: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  previousItemId: PropTypes.string,
};
export { ConversationItemContainer };

export default function HeightChangeAwareConversationItem(props) {
  return (
    <HandleHeightChange>
      {({ onHeightChange }) => <ConversationItemContainer {...props} onHeightChange={onHeightChange} />}
    </HandleHeightChange>
  );
}
