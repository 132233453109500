import { Factory } from 'rosie';

Factory.define('MessagingConfiguration');

Factory.define('MessagingConfigurationWithDefaults')
  .extend('MessagingConfiguration')
  .attr('agentCapacity', 3)
  .attr('agentMaxCapacity', 10)
  .attr('assignMailToConversationAssignee', false)
  .attr('autoAssignMessagingSessions', false)
  .attr('enableFocusMode', false)
  .attr('endMailSessionIntervalMins', 30)
  .attr('endMessagingSessionIntervalMins', 15)
  .attr('lookupBeforeRouting', () => Factory.attributes('LookupBeforeRoutingConfigurationWithDefaults'))
  .attr('multipleTwitterChannels', false)
  .attr('routeNextMessagingSessionIntervalSecs', 20)
  .attr('showPreview', true);

Factory.define('LookupBeforeRoutingConfigurationWithDefaults')
  .attr('enabled', true)
  .attr('timeoutSecs', 15);
