import _ from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import AgentNotificationItem from './agent_notification_item';
import FetchNextAgentNotificationsPage from 'actions/agent_notifications/fetch_next_agent_notifications_page';
import Spinner from 'components/common/spinner_two';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function AgentNotificationsList({ hasReachedEnd, notifications }) {
  const loadingRef = useInfiniteScroll();

  if (!notifications.length) {
    return <EmptyState>You're all caught up 👏</EmptyState>;
  }

  return (
    <React.Fragment>
      <NotificationsList>
        {_.map(notifications, notification => (
          <AgentNotificationItem key={notification.id} notification={notification} />
        ))}
      </NotificationsList>
      {hasReachedEnd ? (
        <TheEnd>The end ✨</TheEnd>
      ) : (
        <Loader data-aid="agentNotifications-loadingSpinner" ref={loadingRef}>
          <Spinner />
        </Loader>
      )}
    </React.Fragment>
  );
}

function useInfiniteScroll() {
  const executeAction = useExecuteAction();
  const [loadingRef, isLoaderInView] = useInView();

  useEffect(() => {
    if (isLoaderInView) {
      executeAction(FetchNextAgentNotificationsPage);
    }
  }, [executeAction, isLoaderInView]);

  return loadingRef;
}

const NotificationsList = styled.div`
  flex-grow: 1;
`;

const EmptyState = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 8px 0;
`;

const TheEnd = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  margin: 8px 0;
`;

const Loader = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  margin: 8px 0;
`;
