import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { MenuContainer, MenuItem } from '../lib/menu_deprecated';
import AgentPhonePreferences from 'models/agent_phone_preferences';
import AgentProfile from 'models/agent_profile';
import Button from 'components/common/button';
import ChannelConfiguration from 'models/channel_configuration';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import CoBrowseOutlineIcon from 'components/lib/icons/cobrowse_outline_icon';
import CompanyProfile from 'models/company_profile';
import CompositionContentType from 'models/composition/composition_content_type';
import connect from 'components/lib/connect';
import CreateChatComposition from 'actions/composition/create_chat_composition';
import CreateCoBrowseComposition from 'actions/composition/create_cobrowse_composition';
import CreateConversation from 'actions/conversation/create_conversation';
import CreateInstagramComposition from 'actions/composition/create_instagram_composition';
import CreateEmailComposition from 'actions/composition/create_email_composition';
import CreateFbMessageComposition from 'actions/composition/create_fb_message_composition';
import CreateNoteComposition from 'actions/composition/create_note_composition';
import CreatePhoneCallComposition from 'actions/composition/create_phone_call_composition';
import CreateSmsComposition from 'actions/composition/create_sms_composition';
import CreateWhatsAppComposition from 'actions/composition/create_whatsapp_composition';
import CreateTaskComposition from 'actions/composition/create_task_composition';
import CreateTweetComposition from 'actions/composition/create_tweet_composition';
import CreateCustomChannelComposition from 'actions/composition/create_custom_channel_composition';
import CustomerProfile from 'models/customer_profile';
import EmailStrokeIcon from 'components/common/icons/stroke/email-stroke';
import { EndpointTypes } from 'models/endpoint';
import { ExternalCustomerAddressType } from 'models/customer_profile/external_customer_address';
import FacebookMessengerStrokeIcon from 'components/common/icons/stroke/facebook-messenger-stroke';
import {
  getActiveChatSessionIdFromHistory,
  getActiveCustomChanelSessionIdFromHistory,
} from 'actions/communication_session/session_finder';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getBrandIconByName } from 'components/common/custom_channels';
import GetExternalActionForm from 'actions/external_customer_lookup/get_external_action_form';
import InteractionType from 'models/interaction_type';
import NoteStrokeIcon from 'components/common/icons/stroke/note-stroke';
import InstagramStrokeIcon from 'components/common/icons/stroke/instagram-stroke';
import registerHotkey from 'components/hotkeys/register_hotkey';
import RequestTimelineConversationSummary from 'actions/ai_conversation_summary/request_timeline_conversation_summary';
import SmsStrokeIcon from 'components/common/icons/stroke/sms-stroke';
import SummaryIcon from 'components/common/icons/stroke/summary-stroke';
import TaskStrokeIcon from 'components/common/icons/stroke/task-stroke';
import TicketIcon from 'components/lib/icons/ticket_icon';
import Tooltip from 'components/common/tooltip';
import TwitterFillIcon from 'components/common/icons/fill/twitter-fill';
import VoiceStrokeIcon from 'components/common/icons/stroke/voice-stroke';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';
import WhatsappStrokeIcon from 'components/common/icons/stroke/whatsapp-stroke';
import { WidgetTypes } from 'models/widget_configuration';

class CommunicationControlsMenuBase extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.refs.menu.open();
    }
  }

  selectEmail() {
    this.handleClick(CreateEmailComposition);
  }

  selectNote() {
    this.handleClick(CreateNoteComposition);
  }

  selectFbMessage() {
    this.handleClick(CreateFbMessageComposition);
  }

  getControls() {
    const WidgetNames = CompanyProfile.Widgets;
    let widgets = [
      {
        action: CreatePhoneCallComposition,
        icon: <VoiceStrokeIcon className="icon-phone communicationControlsMenu-item-icon" />,
        text: 'Call',
        type: WidgetNames.PHONE_CALL,
      },
      {
        action: CreateChatComposition,
        icon: <ChatIconOutline className="communicationControlsMenu-chatIcon communicationControlsMenu-item-icon" />,
        text: 'Chat',
        type: WidgetNames.CHAT,
      },
      {
        action: CreateEmailComposition,
        icon: <EmailStrokeIcon className="icon-email communicationControlsMenu-item-icon" />,
        text: 'Email',
        type: WidgetNames.EMAIL,
      },
      {
        action: CreateInstagramComposition,
        icon: (
          <StyledInstagramIcon className="communicationControlsMenu-instagramIcon communicationControlsMenu-item-icon" />
        ),
        text: 'Instagram Messaging',
        type: WidgetNames.INSTAGRAM_DIRECT,
      },
      {
        action: CreateFbMessageComposition,
        icon: <FacebookMessengerStrokeIcon className="icon-fb-messenger communicationControlsMenu-item-icon" />,
        text: 'Messenger',
        type: WidgetNames.FB_MESSAGE,
      },
      {
        action: CreateSmsComposition,
        icon: <SmsStrokeIcon className="icon-sms communicationControlsMenu-item-icon" />,
        text: 'SMS',
        type: WidgetNames.SMS,
      },
      {
        action: CreateTweetComposition,
        icon: <TwitterFillIcon className="communicationControlsMenu-item-icon" />,
        text: 'Tweet',
        type: WidgetNames.TWITTER,
      },
      {
        action: CreateWhatsAppComposition,
        icon: (
          <WhatsappStrokeIcon className="communicationControlsMenu-item-icon communicationControlsMenu-whatsApp-icon" />
        ),
        text: 'WhatsApp',
        type: WidgetNames.WHATSAPP,
      },
    ];

    if (this.props.customChannelsEnabled) {
      _.forEach(this.props.customChannels, customChannel => {
        if (!customChannel.archived) {
          widgets.push({
            action: CreateCustomChannelComposition,
            icon: getBrandIconByName(customChannel?.icon, { letter: customChannel.name, stroke: true }).icon,
            text: customChannel.name,
            type: WidgetNames.CUSTOM_CHANNEL,
            actionParams: {
              customChannelId: customChannel.id,
            },
          });
        }
      });
    }

    widgets = _.sortBy(widgets, ['text']);

    widgets.push(
      {
        action: RequestTimelineConversationSummary,
        icon: <SummaryIcon className="communicationControlsMenu-item-icon" />,
        text: 'Summarize Conversation',
        type: WidgetNames.COMPLETE_CONVERSATION_SUMMARY,
      },
      {
        action: CreateCoBrowseComposition,
        icon: <CoBrowseOutlineIcon className="communicationControlsMenu-item-icon" />,
        text: 'Co-browse with ScreenMeet',
        type: WidgetNames.COBROWSE,
      },
      {
        action: CreateNoteComposition,
        icon: <NoteStrokeIcon className="icon-note communicationControlsMenu-item-icon" />,
        text: 'Note',
        type: WidgetNames.CONVERSATION_NOTE,
      },
      {
        action: CreateTaskComposition,
        icon: <TaskStrokeIcon className="icon-task communicationControlsMenu-item-icon" />,
        text: 'Task',
        type: WidgetNames.TASK,
      }
    );

    _.forEach(this.props.profile?.actions, (action, idx) => {
      if (!_.has(action, 'integrationId') || this.props.availableIntegrations.indexOf(action.integrationId) === -1) {
        return;
      }

      widgets.push({
        action: GetExternalActionForm,
        className: idx === 0 ? 'communicationControlsMenu-item-externalForm-first' : '',
        icon: <StyledTicketIcon className="communicationControlsMenu-item-icon" />,
        text: action.name,
        type: WidgetNames.EXTERNAL_FORM,
        actionParams: {
          customerId: this.props.profile.id,
          action,
        },
      });
    });

    return widgets.filter(widget => this.props.enabledWidgets.indexOf(widget.type) >= 0);
  }

  agentCanSMS() {
    const smsNumbers = this.props.profile.getSmsPhoneNumbers();
    return smsNumbers.length > 0;
  }

  agentCanTweet() {
    const twitterAccounts = this.props.profile.getExternalCustomerAddressForType(ExternalCustomerAddressType.TWITTER);
    return !_.isEmpty(twitterAccounts);
  }

  agentCanSendInstagramDM() {
    const instagramAccounts = this.props.profile.getExternalCustomerAddressForType(
      ExternalCustomerAddressType.INSTAGRAM_DIRECT
    );
    return !_.isEmpty(instagramAccounts);
  }

  agentCanSendCustomChannelMessage(customChannelId) {
    const customerCustomChannelsAddresses = this.props.profile
      .getExternalCustomerAddressForType(ExternalCustomerAddressType.CUSTOM_CHANNEL)
      .filter(({ content }) => content.customChannelId === customChannelId);

    const customChannel = _.find(this.props.customChannels, { id: customChannelId });
    const hasActiveCustomChannelSession = !!this.props.customChannelSessionMap[customChannelId];

    // if the customer has no custom channel addresses, they can't send a message
    if (_.isEmpty(customerCustomChannelsAddresses)) {
      return false;
    }

    // if there is an active conversation agent can send a message
    if (hasActiveCustomChannelSession) {
      return true;
    }

    // if there is no active conversation and agent initiated conversations are not enabled
    if (!customChannel?.agentInitiatedConversations) {
      return false;
    }

    // if there is no active conversation and message grouping is enabled
    if (customChannel?.messageGroupingEnabled) {
      return false;
    }

    return true;
  }

  // we will need to add a check here verifying that the customer has sent a
  // WhatsApp message within the last 24 hours
  agentCanWhatsApp() {
    const whatsAppAddresses = this.props.profile.getExternalCustomerAddressForType(
      ExternalCustomerAddressType.WHATSAPP
    );
    return !_.isEmpty(whatsAppAddresses);
  }

  handleClick(Action, url, actionParams) {
    if (this.props.isMenuDisabled) {
      return;
    }

    const props = {
      conversationId: this.props.conversationId,
      ...actionParams,
    };

    if (url) {
      props.url = url;
    }
    this.props.onSelect(Action, props);
  }

  toggleMenu(e) {
    if (this.props.isMenuDisabled) {
      return;
    }

    // prevent `≠` from displaying since that is mapped to `option+`
    if (e instanceof KeyboardEvent) {
      e.preventDefault();
    }
    this.refs.menu.toggle();
  }

  isControlDisabled(type, actionParams) {
    const widgets = CompanyProfile.Widgets;

    switch (type) {
      case widgets.CHAT:
        return !this.props.doesConversationHaveExistingChat;
      case widgets.COMPLETE_CONVERSATION_SUMMARY:
        return !this.props.isActiveConversation;
      case widgets.CUSTOM_CHANNEL:
        return !this.agentCanSendCustomChannelMessage(actionParams.customChannelId);
      case widgets.INSTAGRAM_DIRECT:
        return !this.agentCanSendInstagramDM();
      case widgets.EMAIL:
        return !this.hasEmailChannel();
      case widgets.FB_MESSAGE:
        return _.isEmpty(_.get(this.props.profile, 'fbMessengerUserIds'));
      case widgets.PHONE_CALL:
        return this.props.isOnActiveCall || !this.isVoicePreferenceSupported();
      case widgets.SMS:
        return !this.agentCanSMS() || !this.hasSmsChannel();
      case widgets.WHATSAPP:
        return !this.agentCanWhatsApp() || !this.hasWhatsAppChannel();
      case widgets.TWITTER:
        return !this.agentCanTweet();
      case widgets.COBROWSE:
        return !this.props.isActiveConversation;
      default:
        return false;
    }
  }

  isMicrophoneDisabled() {
    if (this.props.isDirectDialEnabled && this.props.currentAgent.hasPhoneNumber()) {
      return false;
    }
    return this.props.isWebRTCEnabled && !this.props.isMicrophoneEnabled;
  }

  isPhoneNumberMissingForDirectDial() {
    return !this.props.isWebRTCEnabled && this.props.isDirectDialEnabled && !this.props.currentAgent.hasPhoneNumber();
  }

  isVoicePreferenceSupported() {
    switch (this.props.currentAgent.getVoiceConfigurationPreference()) {
      case AgentPhonePreferences.BROWSER:
      case AgentPhonePreferences.NONE:
        return this.props.isWebRTCEnabled && this.isBrowserPhoneAvailable() && !this.isMicrophoneDisabled();
      case AgentPhonePreferences.DIRECT_DIAL:
        return !this.isPhoneNumberMissingForDirectDial();
      case AgentPhonePreferences.STATION:
        return this.props.isSipEnabled && this.props.currentAgent.isConfiguredForSip();
      default:
        return false;
    }
  }

  renderControl({ className, action, icon, text, type, url, actionParams }) {
    if (!this.props.conversationId) {
      return null;
    }
    if (!this.props.hasExternalAgentActions && !this._isInternalAction(type)) {
      return;
    }
    const isDisabled = this.isControlDisabled(type, actionParams);

    const classes = classnames(
      'communicationControlsMenu-item',
      `communicationControlsMenu-item-${_.camelCase(type)}`,
      `${_.kebabCase(type)}-menu-item`,
      className
    );
    let iconDisabledReason = this.getIconDisabledReason(type);
    if (isDisabled && iconDisabledReason) {
      return (
        <Tooltip
          className={`${_.kebabCase(type)}-menu-item-tooltip`}
          key={_.camelCase(type + text)}
          message={iconDisabledReason}
          position="right"
        >
          <MenuItem className={classes} isDisabled key={_.camelCase(type + text)} role="menuitem">
            {icon}
            {text}
          </MenuItem>
        </Tooltip>
      );
    }
    return (
      <MenuItem
        className={classes}
        isDisabled={isDisabled}
        key={_.camelCase(type + text)}
        onSelect={this.handleClick.bind(this, action, url, actionParams)}
        role="menuitem"
      >
        {icon}
        {text}
      </MenuItem>
    );
  }

  _isInternalAction(type) {
    return (
      CompositionContentType.CONVERSATION_NOTE === type ||
      CompositionContentType.TASK === type ||
      CompositionContentType.EXTERNAL_FORM === type
    );
  }

  isBrowserAgent() {
    let agentVoicePreference = this.props.currentAgent.getVoiceConfigurationPreference();
    return (
      (agentVoicePreference === AgentPhonePreferences.BROWSER || agentVoicePreference === AgentPhonePreferences.NONE) &&
      this.props.isWebRTCEnabled
    );
  }

  isBrowserPhoneConnecting() {
    return this.isBrowserAgent() && this.props.isBrowserPhoneConnecting;
  }

  isBrowserPhoneAvailable() {
    return this.isBrowserAgent() && this.props.isBrowserPhoneAvailable && !this.props.isBrowserPhoneConnecting;
  }

  isBrowserPhoneUnavailable() {
    return this.isBrowserAgent() && !this.props.isBrowserPhoneAvailable;
  }

  getIconDisabledReason(type) {
    let widgets = CompanyProfile.Widgets;

    switch (type) {
      case widgets.SMS:
        if (!this.agentCanSMS()) {
          return 'SMS is not enabled for this customer';
        }
        break;

      case widgets.PHONE_CALL:
        if (this.props.isOnActiveCall) {
          return 'You are currently on an active call';
        } else if (this.isMicrophoneDisabled()) {
          return 'You must allow microphone access to make calls';
        } else if (this.isPhoneNumberMissingForDirectDial()) {
          return 'Add a desk phone number to your\r\nagent profile to make calls';
        } else if (this.isBrowserPhoneConnecting()) {
          return 'Your phone is connecting...\r\nPlease wait';
        } else if (this.isBrowserPhoneUnavailable()) {
          return 'Your phone is currently unavailable\r\nto make outgoing calls.\r\nPlease try reloading the page.';
        }
        break;

      case widgets.COBROWSE:
        if (!this.props.isActiveConversation) {
          return 'Start a conversation with the\r\ncustomer to enable co-browse';
        }
        break;

      default:
        return null;
    }

    return null;
  }

  render() {
    const classes = classnames('communication-control-menu-wrapper', 'dropdown-toggle', 'status');

    let button = (
      <CommunicationControlsMenuButton
        data-aid="communication-controls-button"
        disabled={this.props.isDisabled}
        onClick={this.props.onOpen}
      >
        <span className="communication-controls-menu-plus-button">
          <i className="communicationControls-icon icon-plus" />
        </span>
      </CommunicationControlsMenuButton>
    );

    return (
      <div className={classes}>
        <MenuContainer
          button={button}
          className="communication-controls-container"
          isDisabled={this.props.isMenuDisabled}
          ref="menu"
          role="menu"
        >
          {this.props.profile && this.getControls().map(this.renderControl.bind(this))}
        </MenuContainer>
      </div>
    );
  }

  hasEmailChannel() {
    return this.props.channelConfiguration && this.props.channelConfiguration.fromAddresses().length !== 0;
  }

  hasSmsChannel() {
    return this.props.channelConfiguration && this.props.channelConfiguration.companySmsNumbers().length !== 0;
  }

  hasWhatsAppChannel() {
    return this.props.channelConfiguration && this.props.channelConfiguration.companyWhatsAppNumbers().length !== 0;
  }
}

CommunicationControlsMenuBase.propTypes = {
  availableIntegrations: PropTypes.arrayOf(PropTypes.string),
  channelConfiguration: PropTypes.instanceOf(ChannelConfiguration),
  conversationId: PropTypes.string,
  currentAgent: PropTypes.instanceOf(AgentProfile),
  customChannelSessionMap: PropTypes.object,
  doesConversationHaveExistingChat: PropTypes.bool,
  enabledWidgets: PropTypes.arrayOf(PropTypes.oneOf(_.values(CompanyProfile.Widgets))).isRequired,
  isActiveConversation: PropTypes.bool,
  isBrowserPhoneConnecting: PropTypes.bool,
  isBrowserPhoneAvailable: PropTypes.bool,
  isDirectDialEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMenuDisabled: PropTypes.bool,
  isMicrophoneEnabled: PropTypes.bool,
  isOnActiveCall: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  isSipEnabled: PropTypes.bool,
  isTelephonyApiEnabled: PropTypes.bool,
  isWebRTCEnabled: PropTypes.bool,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(CustomerProfile),
};

export const CommunicationControlsMenu = withShortcuts(CommunicationControlsMenuBase, [
  registerHotkey({
    key: 'alt+e',
    callback: menuInstance => menuInstance.selectEmail(),
    group: 'Conversation',
    label: 'Email response',
  }),
  registerHotkey({
    key: 'alt+n',
    callback: menuInstance => menuInstance.selectNote(),
    group: 'Conversation',
    label: 'Add note',
  }),
  registerHotkey({
    key: 'alt+=',
    callback: menuInstance => menuInstance.toggleMenu(),
    group: 'Conversation',
    label: 'Open reply customer menu',
  }),
  registerHotkey({
    key: 'shift+=',
    callback: menuInstance => menuInstance.toggleMenu(),
    group: 'Conversation',
    label: 'Open reply customer menu',
  }),
]);

const CommunicationControlsMenuActionConnector = connect(mapStateToProps, mapExecuteToProps)(CommunicationControlsMenu);

CommunicationControlsMenuActionConnector.propTypes = {
  conversationId: PropTypes.string,
  customerId: PropTypes.string,
  hasConversations: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
};

function mapStateToProps(
  { getProvider, isFeatureEnabled },
  { conversationId, customerId, hasConversations, isDisabled, isOpen }
) {
  let isActiveConversation = false;
  if (hasConversations && !isDisabled) {
    const conversations = getProvider('conversations');
    const activeConversationId = getActiveConversationId(conversations);
    isActiveConversation = activeConversationId === conversationId;
  }

  const customChannels = getProvider('customChannels').findAll();
  const customChannelsLoading = getProvider('customChannels').isLoading();
  const isCustomChannelsEnabled = getProvider('customChannelsConfig').get().enabled;

  return {
    channelConfiguration: getProvider('channelConfiguration').get(),
    conversationId,
    currentAgent: getCurrentAgent(),
    customChannels,
    customChannelsEnabled: isCustomChannelsEnabled && !customChannelsLoading,
    doesConversationHaveExistingChat: doesConversationHaveExistingChat(),
    customChannelSessionMap: getCustomChannelSessionMap(customChannels),
    availableIntegrations: getAvailableIntegrations(getProvider('integrations').findAll()),
    enabledWidgets: getEnabledWidgets(),
    hasExternalAgentActions: isFeatureEnabled('externalAgentActions'),
    isActiveConversation,
    isBrowserPhoneConnecting: isBrowserPhoneConnecting(),
    isBrowserPhoneAvailable: isBrowserPhoneAvailable(),
    isDirectDialEnabled: isFeatureEnabled('directDial'),
    isDisabled,
    isMenuDisabled: isDisabled || !hasConversations,
    isMicrophoneEnabled: isFeatureEnabled('microphone'),
    isOnActiveCall: isAgentCurrentlyOnCall(),
    isOpen,
    isSipEnabled: isFeatureEnabled('sip'),
    isTelephonyApiEnabled: isFeatureEnabled('telephonyApi'),
    isWebRTCEnabled: isFeatureEnabled('webRTC'),
    profile: getProvider('profile').get(),
  };

  function getEnabledWidgets() {
    let widgets = [...getCompanyWidgets()];

    if (shouldShowChatOption()) {
      widgets.push(CompanyProfile.Widgets.CHAT);
    }

    if (shouldShowCoBrowsingOption()) {
      widgets.push(CompanyProfile.Widgets.COBROWSE);
    }

    if (shouldShowFbMessageOption()) {
      widgets.push(CompanyProfile.Widgets.FB_MESSAGE);
    }

    if (shouldShowInstagramOption()) {
      widgets.push(CompanyProfile.Widgets.INSTAGRAM_DIRECT);
    }

    if (shouldShowPhoneCallOption()) {
      widgets.push(CompanyProfile.Widgets.PHONE_CALL);
    }

    if (shouldShowSMSOption()) {
      widgets.push(CompanyProfile.Widgets.SMS);
    }

    if (shouldShowWhatsAppOption()) {
      widgets.push(CompanyProfile.Widgets.WHATSAPP);
    }

    if (isCustomChannelsEnabled) {
      widgets.push(CompanyProfile.Widgets.CUSTOM_CHANNEL);
    }

    if (shouldShowConversationSummaryOption()) {
      widgets.push(CompanyProfile.Widgets.COMPLETE_CONVERSATION_SUMMARY);
    }

    return widgets;
  }

  function getCompanyWidgets() {
    return _(getProvider('companyProfile').get()).get('widgets', [
      CompanyProfile.Widgets.CONVERSATION_NOTE,
      CompanyProfile.Widgets.EMAIL,
      CompanyProfile.Widgets.EXTERNAL_FORM,
      CompanyProfile.Widgets.TASK,
    ]);
  }

  function getAvailableIntegrations(allIntegrations) {
    const validIntegrations = _.filter(allIntegrations, integration => {
      return integration.enabled && _.get(integration, 'actionSettings.enabled');
    });
    return _.map(validIntegrations, 'id');
  }

  function shouldShowCoBrowsingOption() {
    const widgetConfigs = getProvider('widgetConfigurations').findAll();
    const screenMeetConfig = (widgetConfigs || []).find(
      widgetConfig => widgetConfig.enabled && widgetConfig.type === WidgetTypes.SCREENMEET
    );
    return !!screenMeetConfig;
  }

  function shouldShowConversationSummaryOption() {
    const agentAssistanceConfig = getProvider('agentAssistanceConfig').get();
    return !agentAssistanceConfig.isEnabled('disableHeroAI') && isActiveConversation;
  }

  function shouldShowChatOption() {
    let channelConfiguration = getProvider('channelConfiguration').get();
    return channelConfiguration && channelConfiguration.isChannelEnabled(EndpointTypes.CHAT);
  }

  function shouldShowFbMessageOption() {
    let channelConfiguration = getProvider('channelConfiguration').get();
    return channelConfiguration && channelConfiguration.isChannelEnabled(EndpointTypes.FB_MESSENGER);
  }

  function shouldShowInstagramOption() {
    let channelConfiguration = getProvider('channelConfiguration').get();
    return channelConfiguration && channelConfiguration.isChannelEnabled(EndpointTypes.INSTAGRAM_DIRECT);
  }

  function shouldShowWhatsAppOption() {
    let channelConfiguration = getProvider('channelConfiguration').get();
    return channelConfiguration && channelConfiguration.isChannelEnabled(EndpointTypes.WHATSAPP);
  }

  function shouldShowPhoneCallOption() {
    let channelConfiguration = getProvider('channelConfiguration').get();
    let isVoiceEnabled = !!(channelConfiguration && channelConfiguration.companyPhoneNumbers().length);
    return (
      isVoiceEnabled &&
      (isFeatureEnabled('directDial') ||
        isFeatureEnabled('webRTC') ||
        isFeatureEnabled('telephonyApi') ||
        isFeatureEnabled('sip'))
    );
  }

  function shouldShowSMSOption() {
    return isFeatureEnabled('sms');
  }

  function isAgentCurrentlyOnCall() {
    let activeCall = getProvider('activeCall').get();
    return (activeCall && activeCall.isAgentOnLiveCall(getCurrentAgent().id)) || false;
  }

  function getCustomChannelSessionMap(customChannels) {
    const conversationHistory = getProvider('conversationHistory');
    const conversations = getProvider('conversations');

    const customChannelSessionMap = {};
    for (const customChannel of customChannels) {
      customChannelSessionMap[customChannel.id] = getActiveCustomChanelSessionIdFromHistory({
        conversationHistory,
        conversations,
        customChannelId: customChannel.id,
      });
    }

    return customChannelSessionMap;
  }

  function doesConversationHaveExistingChat() {
    let activeSessionsProvider = getProvider('activeSessions');
    let queueItem = _.get(activeSessionsProvider.findBy({ customer: { id: customerId } }), 'queueItem');

    return (
      (queueItem && !!queueItem.getSessionByType(InteractionType.CHAT)) ||
      !!getActiveChatSessionIdFromHistory({
        conversationHistory: getProvider('conversationHistory'),
        conversations: getProvider('conversations'),
      })
    );
  }

  function getCurrentAgent() {
    return getProvider('currentAgent').get();
  }

  function getConnectionState() {
    return getProvider('connectionState').get();
  }

  function isBrowserPhoneConnecting() {
    let connectionState = getConnectionState();
    return connectionState && connectionState.isBrowserPhoneConnecting();
  }

  function isBrowserPhoneAvailable() {
    let connectionState = getConnectionState();
    return connectionState && connectionState.isBrowserPhoneAvailable();
  }
}

function mapExecuteToProps(executeAction, { hasConversations, isDisabled }) {
  return {
    onOpen: handleOpen,
    onSelect: (Action, params) => executeAction(Action, params),
  };

  function handleOpen() {
    if (isDisabled) {
      return;
    }

    if (!hasConversations) {
      executeAction(CreateConversation);
    }
  }
}

const CommunicationControlsMenuContainer = connect(mapStateToActionProps)(CommunicationControlsMenuActionConnector);
export default CommunicationControlsMenuContainer;

CommunicationControlsMenuContainer.propTypes = {
  conversationId: PropTypes.string,
  customerId: PropTypes.string,
  isOpen: PropTypes.bool,
};

function mapStateToActionProps({ getProvider }, { conversationId, customerId, isOpen }) {
  const currentItemsLoading = getProvider('conversationHistory').isLoading();
  const isNotCurrentCustomer = _(getProfile()).get('id') !== customerId;
  const hasPendingConversation = getProvider('conversations').isPendingNew();
  const isNavigatingNextConversation = getProvider('conversationWorkflow').get().isNavigatingNextConversation;

  const customChannels = getProvider('customChannels').findAll();
  const customChannelsLoading = getProvider('customChannels').isLoading();

  return {
    conversationId,
    customChannels,
    customChannelsLoading,
    customerId,
    hasConversations: getProvider('conversations').count() > 0,
    isDisabled:
      currentItemsLoading ||
      customChannelsLoading ||
      hasPendingConversation ||
      isNavigatingNextConversation ||
      isNotCurrentCustomer,
    isOpen,
  };

  function getProfile() {
    return getProvider('profile').get();
  }
}

export const CommunicationControlsMenuButton = styled(Button)`
  background: ${p => p.theme.colors.green400};
  border: none;
  border-radius: 50%;
  color: ${p => p.theme.colors.white};
  height: 25px;
  margin: 0;
  padding: 0;
  width: 25px;
  &:hover {
    background: ${p => p.theme.colors.green500};
    color: ${p => p.theme.colors.white};
  }
`;

const StyledTicketIcon = styled(TicketIcon)`
  stroke-width: 0;
`;

const StyledInstagramIcon = styled(InstagramStrokeIcon)`
  stroke-width: 0;
`;
