import _ from 'lodash';

export function trimAndRemoveEmptyQueryFields(query) {
  return _(query)
    .mapValues(val => {
      if (typeof val === 'string') {
        return val.trim();
      }
      return val;
    })
    .pickBy(val => {
      if (_.isArray(val)) {
        return val && val.length > 0;
      }
      if (_.isObject(val)) {
        return val && !_.isEmpty(val);
      }

      return !!val;
    })
    .value();
}
