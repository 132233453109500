import _ from 'lodash';

import CustomerView from 'models/location/customer_view';
import qconsole from 'scripts/lib/qconsole';
import Err from 'models/err';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import UnlinkProfile from 'models/modal/unlink_profile';

export default class CustomerExtensionGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleExtensionSuccess(customerId) {
    this.clearCustomerExtensionPending(customerId);
  }

  handleExtensionErrors(customerId, errors) {
    qconsole.error(`customer extension for [${customerId}] failed: ${JSON.stringify(errors)}`);
    this.clearCustomerExtensionPending(customerId);
  }

  handleUnlinkSuccess(customerId) {
    this.closeUnlinkProfileModal();

    this.context.executeAction(ShowToast, {
      type: ToastType.SUCCESS,
      message: 'You have successfully unlinked the customer.',
    });

    this.context.stores.customerUnlink.resetPending();
  }

  handleUnlinkErrors(customerId, errorDto) {
    qconsole.error(`customer unlink for [${customerId}] failed: ${JSON.stringify(errorDto)}`);
    this.closeUnlinkProfileModal();

    let message = `This profile cannot be unlinked because the server is down. Try again later.`;

    const versionOutdatedError = _.find(errorDto.errors, err => err.code === Err.Code.VERSION_OUTDATED);
    if (versionOutdatedError) {
      message = `This profile cannot be unlinked because someone else is working on it.`;
    }

    this.context.executeAction(ShowToast, {
      type: ToastType.ERROR,
      message,
    });

    this.context.stores.customerUnlink.resetPending();
  }

  clearCustomerExtensionPending(customerId) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView) || currentLocation.customerId !== customerId) {
      return;
    }

    currentLocation.clearCustomerExtensionPending();
    this.context.stores.currentLocation.set(currentLocation);
  }

  closeUnlinkProfileModal() {
    const modal = this.context.stores.modal.get();
    if (modal instanceof UnlinkProfile) {
      this.context.stores.modal.remove();
    }
  }
}
