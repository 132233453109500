import React, { useCallback } from 'react';

import { Bold, Button, Card } from './shared_toast';
import MarkAllAsRead from 'actions/agent_notifications/mark_all_as_read';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function UnreadNotificationsToast({ toast }) {
  const executeAction = useExecuteAction();

  const { numUnread } = toast.content;

  const navigateToMention = useCallback(() => {
    executeAction(MarkAllAsRead);
  }, []);
  const action = <Button onClick={navigateToMention}>Mark all as read</Button>;

  return (
    <Card action={action} toast={toast}>
      You have <Bold>{numUnread}</Bold> unread notifications
    </Card>
  );
}

export default UnreadNotificationsToast;
