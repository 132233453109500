import Composition from 'models/composition';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import qconsole from 'scripts/lib/qconsole';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class CompositionUploadGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }

  get compositionsStore() {
    return getCompositionsStore(this.context);
  }

  handleUploadAuth({ auth, agentId, path }) {
    if (this.currentAgent.id !== agentId) {
      qconsole.log(`Ignored obsolete upload authorization for agent [${agentId}]`);
      return;
    }

    let upload = this._applyToUpload(path, u => u.start());
    if (!upload) {
      qconsole.log(`Ignored obsolete upload authorization for [${path}]`);
      return;
    }

    this.context.gateways.compositionUpload.upload(path, upload.file, auth);
  }

  handleUploadAuthError({ path, errors, agentId }) {
    if (this.currentAgent.id === agentId) {
      this._applyToUpload(path, upload => upload.fail({ message: errors[0].detail }));
      this.context.executeAction(ShowToast, {
        type: ToastType.ERROR,
        message: 'Attachment upload failed: authentication error.',
      });
    }
  }

  handleUploadAbort(path) {
    this._removeUpload(path);
  }

  handleUploadError(path, error) {
    qconsole.log(`Attachment upload failed for [${path}]: `, error);
    this._applyToUpload(path, upload => upload.fail(error));
    this.context.executeAction(ShowToast, {
      type: ToastType.ERROR,
      message: 'Attachment upload failed. Please wait a moment and try again.',
    });
  }

  handleUploadProgress(path, progress) {
    this._applyToUpload(path, upload => upload.setProgress(progress));
  }

  handleUploadSuccess(path) {
    let upload = this._applyToUpload(path, u => u.complete());
    if (!upload) {
      this.context.gateways.compositionUpload.remove(this.currentAgent.id, path);
    }
  }

  _applyToUpload(path, getUpdatedUpload) {
    const store = this.compositionsStore;
    let { compositionId, uploadId } = Composition.getIdsFromPath(path);

    let composition = store.findBy({ id: compositionId });
    if (!composition) {
      return null;
    }

    let upload = composition.findAttachmentById(uploadId);
    if (!upload) {
      return null;
    }

    let updatedUpload = getUpdatedUpload(upload);
    composition.updateUpload(updatedUpload);
    store.replace(composition);
    this.context.stores.localCompositions.set(composition.customerId, composition);
    return updatedUpload;
  }

  _removeUpload(path) {
    const { compositionId } = Composition.getIdsFromPath(path);
    const composition = this.compositionsStore.findBy({ id: compositionId });
    if (!composition) return; // all uploads are already removed

    this.context.gateways.compositionUpload.remove(this.currentAgent.id, path);
  }
}
