import MqttGateway from './lib/mqtt_gateway';

export default class CustomerTypingStateGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customers/+/activity/typing/chat';
  }

  subscribe(customerId) {
    super._subscribe([customerId]);
  }

  unsubscribe(customerId) {
    super._unsubscribe([customerId]);
  }

  onReceive(customerTypingStateDto, topic, parsedTopicParams) {
    const customerId = parsedTopicParams[0];
    this.notifyObservers('handleCustomerTypingState', { customerId, customerTypingStateDto });
  }
}
