import createLocationModel, { prop } from './lib/create_location_model';

const Datasets = createLocationModel({
  modelName: 'Datasets',
  properties: {
    slug: prop(),
    timezone: prop(String).default(''),
  },

  setSlug(slug) {
    this.slug = slug;
  },

  setTimezone(timezone) {
    this.timezone = timezone;
  },

  statics: {
    breadcrumb: 'Datasets',

    create(attrs = {}) {
      return new this(attrs);
    },
  },
});

export default Datasets;
