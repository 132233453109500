import IdGenerator from 'scripts/domain/contracts/id_generator';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import qconsole from 'scripts/lib/qconsole';

const DEFAULT_TIMEOUT = 30000; // 30s

export default class CustomerExtensionHttGateway {
  constructor(http, requestorId) {
    this.http = http;
    this.requestorId = requestorId;
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  get entityName() {
    return 'customer-extensions';
  }

  url(customerId) {
    return `/api/v2/orgs/${this.orgId}/${this.entityName}/${customerId}`;
  }

  urlForUnlinking(customerId) {
    return `/api/v1/orgs/${this.orgId}/${this.entityName}/${customerId}`;
  }

  update(customerId, payload) {
    const correlationId = IdGenerator.newId();
    this.http.post(this.url(customerId), payload, this.handleUpdateResponse.bind(this, customerId), {
      timeout: DEFAULT_TIMEOUT,
      headers: { 'Gladly-Correlation-Id': correlationId, 'Gladly-Requestor-Id': this.requestorId },
    });

    return { correlationId };
  }

  unlink(customerId) {
    const correlationId = IdGenerator.newId();
    this.http.delete(this.urlForUnlinking(customerId), this.handleUnlinkResponse.bind(this, customerId), {
      timeout: DEFAULT_TIMEOUT,
      headers: { 'Gladly-Correlation-Id': correlationId, 'Gladly-Requestor-Id': this.requestorId },
    });

    return { correlationId };
  }

  handleUpdateResponse(customerId, err, res) {
    if (err != null) {
      qconsole.error('error during external customer extension: ', err);
      this.notifyObservers('handleExtensionErrors', customerId, err);
    } else if (res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleExtensionSuccess', customerId);
    } else {
      this.notifyObservers('handleExtensionErrors', customerId, res.response);
    }
  }

  handleUnlinkResponse(customerId, err, res) {
    if (err != null) {
      qconsole.error('error during customer unlink: ', err);
      this.notifyObservers('handleUnlinkErrors', customerId, err);
    } else if (res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleUnlinkSuccess', customerId);
    } else {
      this.notifyObservers('handleUnlinkErrors', customerId, res.response);
    }
  }
}

mixin(CustomerExtensionHttGateway.prototype, Observable);
