import _ from 'lodash';
import React from 'react';
import { useEffect } from 'react';

import { removeInlineOfTypeOnBackspace } from './selection';
import SlateAnswerLink from 'components/customer/composition/lib/slate/slate_answer_link';
import { useAnswerLinks } from 'components/composer/contexts/answer_links_context';

export const ANSWER_LINK = 'answerLink';

export default function AnswerLinksPlugin() {
  return {
    commands: { updateAnswerLinks },

    onChange(editor, next) {
      const { value } = editor;
      const answerInlines = value.inlines.filter(i => i.type === ANSWER_LINK);
      if (!answerInlines.count()) {
        return next();
      }

      return editor.ensureFullSelection(next, ANSWER_LINK);
    },

    onKeyDown(evt, editor, next) {
      if (removeInlineOfTypeOnBackspace(editor, evt, ANSWER_LINK)) {
        return true;
      }

      return next();
    },

    renderNode(props, editor, next) {
      if (props.node.type === ANSWER_LINK) {
        return <SlateAnswerLink {...props} />;
      }

      return next();
    },

    schema: {
      inlines: {
        [ANSWER_LINK]: {
          nodes: [
            {
              match: { object: 'text' },
              min: 1,
              max: 1,
            },
          ],
          // For some reason this invalidates seemingly valid variables
          text: /.+/, // Can't have empty answer links
        },
      },
    },
  };
}

export function useUpdateAnswerLinks(editor, onChange) {
  const answerLinks = useAnswerLinks();
  useEffect(() => {
    if (editor) {
      editor.updateAnswerLinks(answerLinks);
      onChange(editor);
    }
  }, [editor, onChange, answerLinks]);
}

export function updateAnswerLinks(editor, answerLinks) {
  const blocks = editor.value.document.getBlocksAsArray();
  let inlines = [];
  _.forEach(blocks, block => {
    let links = block.getInlinesByType(ANSWER_LINK);
    inlines = inlines.concat(links.toArray());
  });

  _.forEach(inlines, inline => {
    const id = inline.data.get('answerId');
    const answer = _.find(answerLinks, { id });
    const currentName = inline.text;

    if (answer && answer.name !== currentName) {
      editor.replaceNodeByKey(inline.key, {
        data: {
          answerId: answer.id,
          answerName: answer.name,
        },
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: answer.name,
              },
            ],
          },
        ],
        type: ANSWER_LINK,
        object: 'inline',
      });
    }
  });
  return editor;
}
