import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Err from 'models/err';
import CombineCurrentCustomerContainer from './combine_current_customer_container';
import CSSTransitionGroup from 'components/lib/css_transition_group';
import CustomerProfile from 'models/customer_profile';
import CustomerWasCombined from 'components/customer/customer_was_combined';
import LoadingSpinner from 'components/lib/loading_spinner';
import UniversalSearchContainer from 'components/customer_combine/universal_search_container';

class CustomerCombine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.filterHits = this.filterHits.bind(this);
    this.onClickCombine = this.onClickCombine.bind(this);
    this.onClearCustomerId = this.onClearCustomerId.bind(this);
    this.onSelectCustomerId = this.onSelectCustomerId.bind(this);
  }

  componentWillUnmount() {
    this.props.onFinishCombine();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isResponsePending && !nextProps.isResponsePending && _.isEmpty(nextProps.errors)) {
      this.props.onCombineSuccess();
    }
  }

  onClearCustomerId() {
    this.setState({ selectedCustomerId: null });
  }

  onClickCombine() {
    const id = this.state.selectedCustomerId;
    if (!id || this.props.isResponsePending) {
      return;
    }
    this.props.onCombine(id);
  }

  onSelectCustomerId(selectedCustomerId) {
    this.setState({ selectedCustomerId });
  }

  filterHits(hits) {
    return _.filter(hits, hit => hit.id !== this.props.customerId);
  }

  getErrors() {
    const { errors } = this.props;
    if (_.isEmpty(errors)) {
      return '';
    }
    const error = errors[0];
    if (error.attr === 'emailAddress' && error.code === Err.Code.INVALID) {
      return 'Customers could not be merged because they have different email addresses. Please edit the customer record and try again.';
    }
    if (error.attr === 'externalCustomerId' && error.code === Err.Code.INVALID) {
      return 'Customers could not be merged because they have different customer ids. Please edit the customer record and try again.';
    }
    if (error.attr === 'phones' && error.code === Err.Code.INVALID) {
      return 'Customers could not be merged because they have different primary phone numbers. Please edit the customer record and try again.';
    }
    if (error.attr === 'activeChat' && error.code === Err.Code.INVALID_STATE) {
      return 'Customers could not be merged because they both have an active chat. Please end the chat with one of the customers and try again.';
    }
    return `Customers could not be merged: ${error.detail}`;
  }

  render() {
    const { isLoading, isResponsePending, profile, mergedCustomerId } = this.props;

    if (isLoading) {
      return <LoadingSpinner />;
    } else if (!profile && mergedCustomerId) {
      return <CustomerWasCombined customerId={mergedCustomerId} />;
    } else if (!profile) {
      return null;
    }

    const errorMessage = !isResponsePending && this.getErrors();
    const warningMessage =
      !isResponsePending &&
      _.isEmpty(this.props.errors) &&
      'By merging customers, all email addresses, phone numbers, social media handles, and conversations will be moved into one profile and cannot be undone.';
    const isDisabled = !this.state.selectedCustomerId || isResponsePending;
    const buttonClasses = classnames('customerCombine-combiner-button', {
      'customerCombine-combiner-button-disabled': isDisabled,
    });
    return (
      <div className="customerCombine">
        <CombineCurrentCustomerContainer customerId={this.props.customerId} />
        <div className="customerCombine-search">
          <SearchWrapper>
            <UniversalSearchContainer
              filterHits={this.filterHits}
              onSearchChange={this.onClearCustomerId}
              onSelectItem={this.onSelectCustomerId}
              placeholder="Search customers"
            />
          </SearchWrapper>
        </div>
        <div className="customerCombine-combiner-wrapper">
          <div className="customerCombine-combiner">
            <div className="customerCombine-combiner-button-wrapper">
              <div className={buttonClasses} onClick={this.onClickCombine}>
                Merge
              </div>
            </div>
            <div className="customerCombine-combiner-message-wrapper">
              <CSSTransitionGroup
                transitionAppearTimeout={1000}
                transitionEnterTimeout={1000}
                transitionLeaveTimeout={1000}
                transitionName="customerCombineWarningOrError"
              >
                {warningMessage ? <div className="customerCombine-combiner-warning">{warningMessage}</div> : null}
              </CSSTransitionGroup>
              <CSSTransitionGroup
                transitionAppearTimeout={1000}
                transitionEnterTimeout={1000}
                transitionLeaveTimeout={1000}
                transitionName="customerCombineWarningOrError"
              >
                {errorMessage ? <div className="customerCombine-combiner-error">{errorMessage}</div> : null}
              </CSSTransitionGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomerCombine.propTypes = {
  customerId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  errors: PropTypes.array,
  mergedCustomerId: PropTypes.string,
  onCombine: PropTypes.func.isRequired,
  onCombineSuccess: PropTypes.func.isRequired,
  onFinishCombine: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(CustomerProfile).isRequired,
};

export default CustomerCombine;

const SearchWrapper = styled.div`
  .universalSearch-searchInput {
    width: 80%;
    margin: 0 auto;
  }
`;
