import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 60 60" {...props} className="eyeIcon">
    <g fill="none" fillRule="evenodd">
      <circle className="eyeIcon-circle" cx="30" cy="30" r="30" transform="translate(.67)" />
      <g className="eyeIcon-stroke" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M45.063 31.5s-7.5 8.55-14.063 8.55c-6.563 0-14.063-8.55-14.063-8.55s7.5-8.55 14.063-8.55c6.563 0 14.063 8.55 14.063 8.55z"
          strokeWidth="1.8"
        />
        <path
          d="M35.688 31.5c0 2.624-2.1 4.75-4.688 4.75-2.59 0-4.688-2.126-4.688-4.75s2.1-4.75 4.688-4.75c2.59 0 4.688 2.126 4.688 4.75z"
          strokeWidth="1.8"
        />
        <path d="M31.938 31.5c0 .524-.422.95-.938.95-.518 0-.938-.426-.938-.95s.42-.95.938-.95c.516 0 .938.426.938.95z" />
      </g>
    </g>
  </IconContainer>
);
