import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import BubbleContent from 'components/customer/conversation_history/conversation_items_v2/content/bubble_content';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';

function AbandonedCallItem({ item }) {
  return (
    <ItemContent item={item}>
      <ItemMetadata item={item} text="abandoned" />
      <AbandonedCallBubbleContent data-aid={`abandonedCall-${item?.id}`} item={item}>
        <AbandonedCallText data-aid="abandonedCallItemText">Follow-up on Abandoned Call</AbandonedCallText>
      </AbandonedCallBubbleContent>
    </ItemContent>
  );
}

const AbandonedCallBubbleContent = styled(BubbleContent)`
  justify-content: left;
  max-width: initial;
  width: 30%;
`;

const AbandonedCallText = styled.div`
  color: ${p => p.theme.colors.gray600};
  text-align: left;
`;

AbandonedCallItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default AbandonedCallItem;
