import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { AdminView, AdminHeader, AdminTitle } from '../lib/admin_layout';
import Button from 'components/common/button';
import connect from 'components/lib/connect';
import InformationIcon from 'components/lib/icons/information_icon';
import FilterSnippetAdmin from 'actions/knowledge_base/filter_snippet_admin';
import NavigateToSnippet from 'actions/knowledge_base/navigate_to_snippet';
import SnippetsAdminListContainer from './snippets_admin_list_container';
import SnippetFilters from 'components/admin/knowledge_base/snippet/snippet_filters';
import Tooltip from 'components/common/tooltip';

export function SnippetsAdmin({
  onAddSnippet,
  onChangeFilter,
  readOnly,
  renderSwitch,
  urlChannel,
  urlLanguage,
  urlQuery,
  urlAudiences,
}) {
  const [language, setLanguage] = useState(urlLanguage || '');
  const [channel, setChannel] = useState(urlChannel || '');
  const [queryText, setQueryText] = useState(urlQuery);
  const [audiences, setAudiences] = useState(urlAudiences || []);

  const onChangeText = useCallback(
    searchText => {
      onChangeFilter({ channel, searchText, language, audiences });
      setQueryText(searchText);
    },
    [channel, language, audiences, onChangeFilter]
  );
  const onChangeLanguage = useCallback(
    selectedLanguage => {
      onChangeFilter({ channel, searchText: queryText, language: selectedLanguage, audiences });
      setLanguage(selectedLanguage);
    },
    [channel, queryText, audiences, onChangeFilter]
  );
  const onChangeChannel = useCallback(
    selectedChannel => {
      onChangeFilter({ channel: selectedChannel, searchText: queryText, language, audiences });
      setChannel(selectedChannel);
    },
    [queryText, language, audiences, onChangeFilter]
  );
  const onChangeAudiences = useCallback(
    selectedAudience => {
      let audienceIds = [...audiences];
      const index = audienceIds.indexOf(selectedAudience);
      if (index === -1) {
        audienceIds.push(selectedAudience);
      } else {
        audienceIds.splice(index, 1);
      }
      setAudiences(audienceIds);
      onChangeFilter({ channel, language, searchText: queryText, audiences: audienceIds });
    },
    [channel, language, queryText, audiences, onChangeFilter]
  );

  let addSnippetButton = (
    <Button className="knowledgeBaseAdmin-header-addSnippet" onClick={onAddSnippet}>
      Add Answer
    </Button>
  );

  function renderFilters() {
    return (
      <StyledFiltersRow>
        <SnippetFilters
          {...{
            language,
            onChangeLanguage,
            channel,
            onChangeChannel,
            text: queryText,
            onChangeText,
            selectedAudiences: audiences,
            onChangeAudiences,
          }}
        />
        {!readOnly && <StyledButtonWrapper>{addSnippetButton}</StyledButtonWrapper>}
      </StyledFiltersRow>
    );
  }

  let message = (
    <div>
      Gladly’s Answers help empower
      <br />
      your agents and provide customers
      <br />
      with a high-quality, consistent
      <br />
      experience.
      <br />
      <a href="https://help.gladly.com/docs/what-is-answers/" rel="noopener noreferrer" target="_blank">
        Learn more about Answers
      </a>
    </div>
  );

  return (
    <AdminView className="knowledgeBaseAdmin">
      <AdminHeader className="knowledgeBaseAdmin-header">
        <AdminTitle className="knowledgeBaseAdmin-header-title">
          Answers
          <Tooltip hoverableTooltip leaveDelay={100} message={message} position="right">
            <StyledInfoIcon />
          </Tooltip>
        </AdminTitle>
      </AdminHeader>
      {renderSwitch()}
      {renderFilters()}
      <SnippetsAdminListContainer readOnly={readOnly} textFilter={queryText} />
    </AdminView>
  );
}

SnippetsAdmin.propTypes = {
  onAddSnippet: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  renderSwitch: PropTypes.func.isRequired,
  urlChannel: PropTypes.string,
  urlLanguage: PropTypes.string,
  urlQuery: PropTypes.string,
};

const StyledButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 60px;
  height: 34px;
  margin-top: ${p => p.theme.spacing.xlarge};
`;

const StyledFiltersRow = styled.div`
  display: flex;
`;

const StyledInfoIcon = styled(InformationIcon)`
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  fill: ${p => p.theme.colors.gray600};
  height: 15px;
  margin-left: 5px;
  stroke: ${p => p.theme.colors.gray600};
  width: 15px;
`;

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  let location = getProvider('currentLocation').get();
  let urlChannel = location.channel;
  let urlLanguage = location.language;
  let urlQuery = location.query || '';
  let urlAudiences = location.audiences;

  return {
    urlChannel,
    urlLanguage,
    urlQuery,
    urlAudiences,
    readOnly: !isFeatureEnabled('kbManagement'),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onAddSnippet: () => executeAction(NavigateToSnippet, 'new'),
    onChangeFilter: ({ searchText, language, channel, audiences }) =>
      executeAction(FilterSnippetAdmin, { query: searchText, language, channel, audiences }),
  };
}

const SnippetsAdminContainer = connect(mapStateToProps, mapExecuteToProps)(SnippetsAdmin);

export default SnippetsAdminContainer;
