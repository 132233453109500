import React, { useState } from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import AddObserverToCall from 'actions/conversation_item/agent_coaching/add_observer_to_call';
import RadioButton from '../common/radio_button';
import ConfirmOrCancelModal from '../common/confirm_or_cancel_modal';
import styled from 'styled-components';

const AddObserverToCallModal = props => {
  const [agentVisible, setAgentVisible] = useState(false);

  const text = 'Would you like the agent to know you are listening on the call?';

  return (
    <ConfirmOrCancelModal
      data-aid={'addObserver-modal'}
      onCancel={props.onClose}
      onSubmit={() => props.onConfirm(agentVisible)}
      submitText={'Listen in'}
      text={text}
      title={'Listen in'}
    >
      <RadioButton
        dataAid={`addObserverToCallModal-hidden-radio`}
        isChecked={!agentVisible}
        key={'addObserverToCallModal-hiddenButton'}
        label={'No, keep me hidden'}
        onChange={() => setAgentVisible(false)}
        value={'value'}
      />
      <SpacerDiv />
      <RadioButton
        dataAid={'addObserverToCallModal-visible-radio'}
        isChecked={agentVisible}
        key={'addObserverToCallModal-visibleButton'}
        label={'Yes, make me visible'}
        onChange={() => setAgentVisible(true)}
        value={'value'}
      />
    </ConfirmOrCancelModal>
  );
};

export const SpacerDiv = styled.div`
  padding-left: 20px;
  display: inline;
`;

export { AddObserverToCallModal };

AddObserverToCallModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function AddObserverToCallModalWrapper(props) {
  return <AddObserverToCallModal onClose={props.onClose} onConfirm={handleConfirm} />;

  function handleConfirm(isAgentVisible) {
    props.addObserverToCall({
      customerId: props.customerId,
      conversationItemId: props.conversationItemId,
      isAgentVisible,
    });
  }
}

AddObserverToCallModalWrapper.propTypes = {
  customerId: PropTypes.string.isRequired,
  conversationItemId: PropTypes.string.isRequired,
  addObserverToCall: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider }) {
  let modalAttrs = getProvider('modal').get();

  return {
    customerId: modalAttrs.customerId,
    conversationItemId: modalAttrs.conversationItemId,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onClose: props.onClose,
    addObserverToCall,
  };

  function addObserverToCall({ customerId, conversationItemId, isAgentVisible }) {
    props.onClose();
    executeAction(AddObserverToCall, { customerId, conversationItemId, isAgentVisible });
  }
}

const AddObserverToCallModalContainer = connect(mapStateToProps, mapExecuteToProps)(AddObserverToCallModalWrapper);

AddObserverToCallModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddObserverToCallModalContainer;
