import upperFirst from 'lodash/upperFirst';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
    whitelist: ['en', 'es'],
    load: 'all',
    react: {
      wait: true,
      bindI18n: 'languageChanged',
    },
  });

i18n.services.formatter.add('upperFirst', upperFirst);

export default i18n;
