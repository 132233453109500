import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import { connectEditableCustomAttribute } from 'components/customer/summary/lib/custom_attributes_helper';
import CustomAttributeBase from './custom_attribute_base';
import DatePickerMenu from 'components/lib/date_picker_menu';
import { tryStringify } from 'scripts/lib/try_stringify';

export function EditableDateCustomAttribute({ def, value, isDisabled, isResponsePending, onSubmit, placeholderText }) {
  if (def.type !== AttributeType.DATE) {
    return null;
  }

  let tooltip = undefined;
  const isValidDate = _.isString(value) ? moment(value).isValid() : false;
  const dateValue = isValidDate ? moment(value).toISOString() : undefined;
  const isError = !!value && !isValidDate;
  if (isError) {
    const sanitizedValue = _.truncate(tryStringify(value), { length: 25 });
    tooltip = `This date may be wrong. [${sanitizedValue}] is not a correct value for '${def.label}'`;
  }

  const renderedValue = (
    <DatePickerMenu
      className={classnames('editableCustomAttribute-datePicker', { error: isError })}
      date={dateValue}
      isDisabled={isDisabled || isResponsePending}
      onChange={onSubmit}
      placeholderText={placeholderText}
    />
  );

  return (
    <CustomAttributeBase label={def.label} title={tooltip}>
      <div className="editableDateCustomAttribute-value">{renderedValue}</div>
    </CustomAttributeBase>
  );
}

EditableDateCustomAttribute.propTypes = {
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
};

const EditableDateCustomAttributeContainer = connectEditableCustomAttribute(EditableDateCustomAttribute);
export default EditableDateCustomAttributeContainer;
