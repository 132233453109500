import React from 'react';
import { useAnswerBody, useAnswerSubject } from 'components/text_editor_new/plugins/answers/use_answers';

export function withUseAnswerBody(Component) {
  return props => {
    const { editorRef } = props;
    const editor = editorRef.current;

    useAnswerBody(editor, true);
    return <Component {...props} />;
  };
}

export function withUseAnswerSubject(Component) {
  return props => {
    const { editorRef } = props;
    const editor = editorRef.current;

    useAnswerSubject(editor, false);
    return <Component {...props} />;
  };
}
