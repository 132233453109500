import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AgentAvailability } from 'models/agent_status';
import connect from 'components/lib/connect';
import Endpoint from 'models/endpoint';
import SearchableAssignmentMenu from 'components/lib/assignment_menu/searchable_assignment_menu';
import SearchResult from 'components/lib/assignment_menu/search_result';
import TransferButtonOptions from 'components/lib/assignment_menu/transfer_button_options';
import TransferButtonOption from 'components/lib/assignment_menu/transfer_button_option';

export class SearchableTransferMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.renderAssignmentRow = this.renderAssignmentRow.bind(this);
  }

  render() {
    const agentResultsCategories = [
      {
        className: 'ready-agents',
        name: 'Agents ready',
        filter: agentResult => _.includes(this.props.readyAgentIds, agentResult.agentId),
      },
      {
        className: 'other-agents',
        name: 'All Agents',
        filter: agentResult => !_.includes(this.props.readyAgentIds, agentResult.agentId),
      },
    ];

    return (
      <SearchableAssignmentMenu
        agentResultsCategories={agentResultsCategories}
        className="searchableTransferMenu"
        defaultResults={this.props.observers}
        defaultResultsGroupName="Call Participants" // TODO review name
        excludedAgentIds={[this.props.currentAgentId, ...this.props.awayAndOfflineAgentIds]}
        inboxIds={this.props.inboxIds}
        onBlur={this.props.onClose}
        renderRow={this.renderAssignmentRow}
        title="Transfer"
      />
    );
  }

  renderAssignmentRow(hit) {
    return (
      <TransferSearchResultContainer
        agentButtons={
          <TransferButtonOption
            agentId={hit.agentId}
            currentAgentId={this.props.currentAgentId}
            routingGroupId={hit.routingGroupId}
          />
        }
        agentClassName="transfer-selectableSearchResults"
        inboxButtons={
          <TransferButtonOptions
            agentId={hit.agentId}
            currentAgentId={this.props.currentAgentId}
            onClose={this.props.onClose}
            routingGroupId={hit.routingGroupId}
          />
        }
        inboxClassName="transfer-selectableSearchResults-group"
        key={`${hit.routingGroupId}${_.get(hit, 'agentId', '')}`}
        {...this.props}
        {...hit}
      />
    );
  }
}

SearchableTransferMenu.propTypes = {
  awayAndOfflineAgentIds: PropTypes.arrayOf(PropTypes.string),
  currentAgentId: PropTypes.string.isRequired,
  inboxIds: PropTypes.arrayOf(PropTypes.string),
  observers: PropTypes.arrayOf(
    PropTypes.shape({
      agentEmail: PropTypes.string,
      agentId: PropTypes.string.isRequired,
      agentName: PropTypes.string,
      routingGroupId: PropTypes.string.isRequired,
      routingGroupName: PropTypes.string.isRequired,
    })
  ),
  readyAgentIds: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
};

const SearchableTransferMenuContainer = connect(mapStateToMenuProps)(SearchableTransferMenu);

function mapStateToMenuProps({ getProvider }) {
  let endpoints = getProvider('channelConfiguration').get().endpoints;
  let indexedQueues = _.groupBy(getProvider('communicationQueues').findAll(), 'routingGroupId');
  let routingGroupProvider = getProvider('routingGroups');
  let allVoiceRoutingGroups = routingGroupProvider.findAll().filter(isVoiceRoutingGroup);
  let activeCall = getProvider('activeCall').get();
  // default to activeCall routing groupId or first voice routing groupId for transfer to observer
  let inbox = activeCall.routingGroupId
    ? routingGroupProvider.find(activeCall.routingGroupId)
    : allVoiceRoutingGroups[0];
  let agentProvider = getProvider('agents');
  let observers = activeCall.conversationItem.content
    .getVisibleObserverParticipants()
    .map(participant => agentProvider.findBy({ id: participant.participantId }))
    .filter(agent => !!agent)
    .map(agent => {
      return {
        agentEmail: agent.email,
        agentId: agent.id,
        agentName: agent.name,
        routingGroupId: inbox.id,
        routingGroupName: inbox.name,
      };
    });

  let currentAgentId = getProvider('currentAgent').get().id;

  let agentStatusesProvider = getProvider('agentStatuses');
  let readyForVoiceAgentIds = _.map(
    agentStatusesProvider.findAll({
      filter: as => as.status.availability === AgentAvailability.READY && as.status.channels.VOICE.available,
      select: ['id'],
    }),
    agent => agent.id
  );
  let readyAndBusyAgentIds = _.map(
    agentStatusesProvider.findAll({
      filter: as => _.includes([AgentAvailability.READY, AgentAvailability.BUSY], as.status.availability),
      select: ['id'],
    }),
    agent => agent.id
  );
  let allAgentIds = _.map(agentProvider.findAll({ select: ['id'] }), agent => agent.id);
  let awayAndOfflineAgentIds = _.difference(allAgentIds, readyAndBusyAgentIds);

  function isVoiceRoutingGroup(rg) {
    return _.some(indexedQueues[rg.id], cq =>
      _.some(endpoints, e => e.id === cq.endpointId && e.type === Endpoint.Type.VOICE)
    );
  }

  return {
    awayAndOfflineAgentIds,
    currentAgentId,
    inboxIds: allVoiceRoutingGroups.map(i => i.id),
    observers,
    readyAgentIds: readyForVoiceAgentIds,
  };
}

export default SearchableTransferMenuContainer;

export class TransferSearchResult extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return <SearchResult {...this.props} />;
  }
}

TransferSearchResult.propTypes = {
  agentId: PropTypes.string,
  currentAgentId: PropTypes.string,
  readyAgentIds: PropTypes.arrayOf(PropTypes.string),
  routingGroupId: PropTypes.string,
};

function mapStateToProps({ getProvider }, props) {
  return {
    currentAgentId: getProvider('currentAgent').get().id,
  };
}

export const TransferSearchResultContainer = connect(mapStateToProps)(TransferSearchResult);
