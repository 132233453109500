import { Factory } from 'rosie';

Factory.define('ContextEvent');

Factory.define('ContextEventWithDefaults')
  .extend('ContextEvent')
  .attr('timestamp', () => new Date().toISOString())
  .attr('type', 'reserveration')
  .attr('action', 'search')
  .attr('value', ['type', 'action'], (type, action) => {
    var key = `${type}:${action}`;

    switch(key) {
      case 'reservation:search':
        return ({
          property: "Airius Airline's Hotel Chain",
          roomType: 'Single Room',
          checkIn: '2014-12-23',
          checkOut: '2014-12-27',
          resultCount: 3
        });
      case 'policy:search':
        return ({
          property: '1234'
        });
      case 'contact:voice':
        return ({
          phoneNumber: '123-456-7890'
        });
      case 'knowledge-base:search':
        return ({
          searchText: 'John Matrix',
          searchResult: "I'm going after you, Sully"
        });
      default:
        return ({
          content: `Unknown event type: ${key}`
        });
    }
  });
