import createDemoPublisherShims from '../infrastructure/backends/create_demo_publisher_shims';
import createDemoSubscriberShims from '../infrastructure/backends/create_demo_subscriber_shims';
import createMqttClient from '../infrastructure/backends/create_mqtt_client';
import DemoPubsubClient from '../infrastructure/backends/demo_pubsub_client';
import LocalPubsubBackend from '../infrastructure/backends/local_pubsub_backend';
import PubsubCompositeClient from '../infrastructure/backends/pubsub_composite_client';

export default function(eventRecorder, requestorId) {
  let mqttClient = createMqttClient(eventRecorder, requestorId);
  let localPubsub = new LocalPubsubBackend();
  let demoSubscriberShims = createDemoSubscriberShims();
  let demoPublisherShims = createDemoPublisherShims();
  let demoClient = new DemoPubsubClient(mqttClient, localPubsub, demoSubscriberShims, demoPublisherShims);
  return new PubsubCompositeClient(mqttClient, demoClient);
}
