import { PlateProvider } from '@udecode/plate';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import { AITextCompletionButtons } from 'components/text_editor_new/components/controls/ai_text_rewrite_controls';
import { AITextCompletionContextProvider } from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import {
  AnswerPanelControl,
  BoldItalicUnderlineControls,
  ListControls,
  Separator,
} from 'components/composer/shared/editor_controls_new';
import { EditorContainer } from 'components/composer/shared/editor_new';
import ForgottenAttachmentModal from 'components/composer/email/forgotten_attachment_modal';
import ForgottenTextModal from 'components/composer/email/forgotten_text_modal';
import CloseCurrentComposition from 'actions/composition/close_current_composition';
import Composer, { Header, NewHeaderText } from 'components/composer/shared/composer_new';
import ComposerEditor, { ComposerSubjectLineEditor } from 'components/composer/shared/composer_editor';
import connect from 'components/lib/connect';
import createAITextCompletion from 'components/text_editor_new/plugins/ai/ai_text_completion';
import createAITextCompletionHighlight from 'components/text_editor_new/plugins/ai/ai_text_completion/ai_text_completion_highlight';
import EditorErrors from 'components/composer/shared/editor_errors';
import FontColorControls from 'components/composer/shared/font_color_controls';
import From from 'components/composer/email/from';
import getInitialHtml from 'components/composer/email/get_initial_html';
import { isMacOS } from 'scripts/lib/browser_detector';
import InsertInlineImageButton from 'components/composer/shared/insert_inline_image_button_new';
import InternalEmailModal from 'components/composer/email/internal_email_modal_wrapper';
import { LinkButton } from 'components/composer/inline_links/link_button';
import { LinkEditor } from 'components/composer/inline_links/link_editor';
import Recipients from 'components/composer/email/recipients';
import SelectionInitializer from 'components/composer/email/selection_initializer';
import SendEmail from 'actions/composer/send_email';
import serializeHtmlExternal from 'components/text_editor_new/lib/serialize_html_external';
import { serializePlaintext, serializePlaintextFragment } from 'components/text_editor_new/lib/serialize_plaintext';
import { SnippetContentType } from 'models/answers/snippet';
import { SubjectContainerNew } from 'components/composer/email/subject';
import TextAlignmentMenu from 'components/composer/shared/editor_alignment_controls_new';
import Thread from 'components/composer/email/thread';
import useComposer from 'components/composer/shared/use_composer';
import useEditor from 'components/composer/shared/use_editor';
import useForgottenTextModal from 'components/composer/email/use_forgotten_text_modal';
import useHasRemovableThread from 'components/composer/email/use_has_removable_thread';
import useInitialValue from 'components/text_editor_new/hooks/use_initial_value';
import usePlaintextEditorPlugins from 'components/text_editor_new/hooks/use_plaintext_editor_plugins';
import useRichTextEditorPlugins from 'components/text_editor_new/hooks/use_rich_text_editor_plugins';
import useWasCustomerAddedToEmail from 'components/composer/email/use_was_customer_added_to_email';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import { useSnippetAttachments } from 'components/composer/email/use_snippet_attachments';
import withStaticId from 'components/customer/lib/with_static_id';
import { useSubmitOnHotkey } from 'components/composer/shared/use_submit_on_hotkey';

const AI_HERO_PLUGINS = [createAITextCompletion(), createAITextCompletionHighlight()];

export function EmailComposer({ defaultBody, hasNewAttachments, onCloseComposition, profile, requestorId }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const includeAIFeatures = isFeatureEnabled('enableEmailComposerHeroAI');

  const composerProps = useComposer({
    action: SendEmail,
    options: { createContent },
  });
  const editorProps = useEditor('bodyHtml', composerProps, { serializer: serializeHtmlExternal });
  const subjectEditorProps = useEditor('subjectHtml', composerProps, {
    attr: 'subject',
    dontAutofocus: true,
    serializer: serializePlaintext,
    editorId: 'subjectHtml',
  });
  const hasRemovableThread = useHasRemovableThread();
  const [wasCustomerAddedToEmail, ignoreCustomerWasAddedToEmail] = useWasCustomerAddedToEmail(profile);

  const extraPlugins = includeAIFeatures ? AI_HERO_PLUGINS : [];
  const editorRef = useRichTextEditorPlugins(composerProps, editorProps, extraPlugins);
  const subjectRef = usePlaintextEditorPlugins(composerProps, subjectEditorProps);

  // With greetings and signatures, we want to place the initial cursor position in an expected place for agents.
  // If we have a greeting _and_ a signature, for example, the cursor goes in the middle. If we're loading a draft,
  // on the other hand, the cursor will just go at the end (hence the -1).
  const [initialHtml, initialParagraphIndex] = useMemo(() => {
    if (editorProps.initialHtml) {
      return [editorProps.initialHtml, -1];
    }

    return getInitialHtml(defaultBody, editorRef);
  }, []);

  const initialSubjectValue = useInitialValue(subjectEditorProps.initialHtml, subjectRef.current);
  const initialSubjectPlaintext = serializePlaintextFragment(initialSubjectValue || []);
  useSnippetAttachments(defaultBody);

  const {
    onSubmit: decoratedOnSubmit,
    showForgottenTextModal,
    clearShowForgottenTextModal,
    forgottenAttachmentText,
    clearForgottenAttachmentText,
  } = useForgottenTextModal(composerProps.onSubmit, editorProps.editorRef.current, defaultBody);

  // Override default `onCancel` because in case of emails, we need to account for the default values,
  // i.e. greeting, signature and/or initial subject. Also, take attachments into account.
  const onCancel = () => {
    const [defaultHtml] = getInitialHtml(defaultBody, editorRef);
    const defaultFragment = defaultHtml ? editorRef.current.getSaturatedFragment(defaultHtml) : [];
    const defaultPlaintext = serializePlaintextFragment(defaultFragment);

    const defaultValues = { body: _.trim(defaultPlaintext), subject: _.trim(initialSubjectPlaintext) };
    const currentValues = {
      body: _.trim(serializePlaintext(editorRef.current)),
      subject: _.trim(serializePlaintext(subjectRef.current)),
    };

    // Did they change at least one non-whitespace character in either body or the subject?
    if (_.isEqual(defaultValues, currentValues) && !hasNewAttachments) {
      onCloseComposition();
    } else {
      composerProps.setIsCancelling(true);
    }
  };

  // Register keyboard hotkeys that result in submitting the composition. For historic reason, we use both Meta+Enter
  // and Ctrl+Enter on Mac and just Ctrl+Enter on Windows
  const keyboardHotkeys = ['Mod+Enter'];
  if (isMacOS()) keyboardHotkeys.push('Control+Enter');
  useSubmitOnHotkey(editorRef.current, decoratedOnSubmit, keyboardHotkeys);

  const authoringControls = (
    <>
      <AnswerPanelControl />
      <Separator />
      {includeAIFeatures ? (
        <>
          <AITextCompletionButtons />
          <Separator />
        </>
      ) : null}
    </>
  );

  const composer = (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      excludeErrors
      includeAttachments
      initialHtml={initialHtml}
      submitText="Send Email"
      {...composerProps}
      onCancel={onCancel}
      onSubmit={decoratedOnSubmit}
    >
      {initialParagraphIndex > -1 ? <SelectionInitializer paragraphIndex={initialParagraphIndex} /> : null}
      <Header>
        <NewHeaderText>Email</NewHeaderText>
        <Recipients />
        <From />
      </Header>
      <SubjectContainerNew editorProps={subjectEditorProps}>
        <PlateProvider
          editor={subjectRef.current}
          id={subjectEditorProps.editorId}
          initialValue={initialSubjectValue}
          normalizeInitialValue
          onChange={subjectEditorProps.onChange}
        >
          <ComposerSubjectLineEditor
            autoFocus={false}
            minHeight="0px"
            placeholder="Enter a subject"
            width="100%"
            {...subjectEditorProps}
          />
        </PlateProvider>
      </SubjectContainerNew>

      <EditorContainer excludeAnswerMenuButton includeAttachments>
        <ComposerEditor
          autoFocus={!!editorProps.initialHtml || initialParagraphIndex === -1}
          includeAIFeatures={includeAIFeatures}
          padding="8px"
          placeholder="Type to respond"
          requestorId={requestorId}
          {...editorProps}
          initialHtml={initialHtml}
        />
        {hasRemovableThread && <Thread />}
        <LinkEditor hotKey="mod+k" />
      </EditorContainer>

      <ActionBar>
        {authoringControls}
        <BoldItalicUnderlineControls {...editorProps} />
        <FontColorControls {...editorProps} />
        <TextAlignmentMenu {...editorProps} />
        <ListControls {...editorProps} />
        <>
          <Separator />
          <LinkButton />
        </>
        <Emoji {...editorProps} />
        <Attachment />
        <InsertInlineImageButton {...editorProps} />
      </ActionBar>

      <EditorErrors />

      {hasRemovableThread && wasCustomerAddedToEmail ? (
        <InternalEmailModal onKeepHistory={ignoreCustomerWasAddedToEmail} />
      ) : null}
      {forgottenAttachmentText ? (
        <ForgottenAttachmentModal
          onCancel={clearForgottenAttachmentText}
          onSubmit={composerProps.onSubmit}
          text={forgottenAttachmentText}
        />
      ) : null}
      {showForgottenTextModal ? (
        <ForgottenTextModal
          onCancel={clearShowForgottenTextModal}
          onSubmit={evt => {
            composerProps.onSubmit(evt);
            clearShowForgottenTextModal();
          }}
        />
      ) : null}
    </Composer>
  );

  return includeAIFeatures ? (
    <AITextCompletionContextProvider requestorId={requestorId}>{composer}</AITextCompletionContextProvider>
  ) : (
    composer
  );
}

function createContent(editor, attr, content) {
  if (attr === 'bodyHtml') {
    return {
      ...content,
      bodyPlain: serializePlaintext(editor),
    };
  }

  return content;
}

EmailComposer.propTypes = {
  defaultBody: PropTypes.shape({
    greetingSnippet: PropTypes.object,
    signatureSnippet: PropTypes.object,
  }),
  hasNewAttachments: PropTypes.bool,
  onCloseComposition: PropTypes.func.isRequired,
  profile: PropTypes.object,
  requestorId: PropTypes.string.isRequired,
};

const MemoizedComposer = React.memo(EmailComposer);
const EmailComposerContainer = connect(mapStateToProps, mapExecuteToProps)(MemoizedComposer);

function mapStateToProps({ getProvider }) {
  const compositionProvider = getProvider('compositions');
  const currentAgent = getProvider('currentAgent').get() || {};
  const currentLocation = getProvider('currentLocation').get();
  const compositionId = currentLocation?.currentCompositionId;
  const { emailGreetingSnippetId, emailSignatureSnippetId } = currentAgent;

  const defaultBody = {
    greetingSnippet: getProvider('currentAgentSnippets').findBy({ id: emailGreetingSnippetId }),
    signatureSnippet: getProvider('currentAgentSnippets').findBy({ id: emailSignatureSnippetId }),
  };

  let hasNewAttachments = false;
  if (compositionId) {
    const composition = compositionProvider.find(compositionId);
    if (composition?.hasAttachments()) {
      // check whether we have any attachments that were added by the agent (i.e. were not transferred from either
      // the greeting or the signature)
      const snippetAttachments = _.reduce(
        defaultBody,
        (acc, answer) => {
          if (!answer) return acc;

          const attachments = [];
          _.forEach(answer.contents, contentBlock => {
            const ids = _.map(contentBlock[SnippetContentType.ANY_CHANNEL]?.attachments, attachment => attachment.id);
            attachments.push(...ids);
          });
          return [...acc, ...attachments];
        },
        []
      );

      hasNewAttachments = _.some(composition.attachments, attachment => !snippetAttachments.includes(attachment.id));
    }
  }

  return {
    defaultBody,
    hasNewAttachments,
    profile: getProvider('profile').get(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onCloseComposition: () => executeAction(CloseCurrentComposition),
  };
}

export default withStaticId(EmailComposerContainer, 'requestorId');
