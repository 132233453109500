import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('ConversationStatusChange').attr('type', 'CONVERSATION_STATUS_CHANGE');

Factory.define('ConversationStatusChangeWithDefaults')
  .extend('ConversationStatusChange')
  .attr('status', 'CLOSED');

Factory.define('ConversationStatusChangeItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationStatusChange', content);
  });

Factory.define('ConversationStatusChangeItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationStatusChangeWithDefaults', content);
  });
