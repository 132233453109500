import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

export function onChange(autocompleteRef, editor) {
  // Removing an existing suggestion will cause onChange to fire.. but we don't want to immediately
  // re-add the suggestion, otherwise it'll never go away. So we set this flag when removing, and
  // unset it after the onChange fires for the removal.
  if (autocompleteRef.current.isRemoving) {
    autocompleteRef.current.isRemoving = false;
    return;
  }

  editor.ensureAutocompleteExists();
}

export function onKeyDown(context, editor, evt) {
  // Composing probably means using an IME (e.g. Japanese keyboard) to type.
  if (ReactEditor.isComposing(editor)) {
    return;
  }

  const allowedSpecialKeys = ['Escape', 'Enter', 'Tab', 'ArrowRight', 'Backspace'];
  const existingAutocompletes = editor.getAutocompleteNodes();
  if (existingAutocompletes.length === 0) {
    return;
  }

  // Check whether we care about this event
  const shouldHandle = (isCharacterKey(evt) || allowedSpecialKeys.includes(evt.key)) && !hasModifier(evt);
  if (!shouldHandle) return;

  // We intercept Shift+Enter because it is actually a "character" key
  const shouldIntercept = shouldHandle & (evt.key === 'Enter' && evt.shiftKey);

  // If a user types part of the autocomplete, insert that character then continue to show
  // the rest of the autocomplete.
  const [autocompleteNode, autocompletePath] = existingAutocompletes[0];
  if (isKeyFirstCharacterInAutocomplete(evt, autocompleteNode)) {
    const autocompleteText = autocompleteNode['data-text'];
    Transforms.insertText(editor, autocompleteText[0]);

    const nextText = autocompleteText.slice(1);
    const fragment = editor.getSaturatedFragment(`<div>${nextText}</div>`);

    // Update the autocomplete node with the new text / fragment
    Transforms.setNodes(
      editor,
      {
        'data-fragment': fragment,
        'data-text': nextText,
        isPartial: true,
      },
      {
        at: autocompletePath,
      }
    );
    evt.preventDefault();
    return true;
  }

  // Otherwise, when the user types, we don't need to show the autocomplete suggestion anymore.
  editor.removeAutocomplete();

  // But if we were showing a suggestion and they hit Tab or Right Arrow, we'll insert it.
  const shouldInsertAutocomplete = evt.key === 'Tab' || evt.key === 'ArrowRight';
  if (shouldInsertAutocomplete) {
    evt.preventDefault();

    const id = autocompleteNode['data-id'];
    const text = autocompleteNode['data-text'];
    const fragment = autocompleteNode['data-fragment'];

    if (fragment) {
      Transforms.insertFragment(editor, fragment);
    } else {
      Transforms.insertText(editor, text);
    }

    editor.updateVariables();
    context.onUpdateGreetingSuggestionScore(id);
  }

  // Intercept unwanted characters after the autocomplete was removed so that the undo history is consistent
  if (shouldIntercept) evt.preventDefault();
}

function isKeyFirstCharacterInAutocomplete(evt, autocompleteNode) {
  if (!isCharacterKey(evt)) return false;

  // Allow agents to type the first character even if the capitalization is different
  const key = evt.key.toLowerCase();
  const text = autocompleteNode['data-text'];
  return key === (text[0] || '').toLowerCase();
}

/**
 * Quick and dirty way to distinguish between "letter keys" and special keys, arrows, functional keys etc.
 *
 * @param evt - keyboard event
 * @return {boolean}
 */
function isCharacterKey(evt) {
  return (evt.type === 'keydown' || evt.type === 'keypress') && evt.key.length === 1;
}

/**
 * Checks whether any of the modifier keys were pressed with the character
 *
 * @param evt - keyboard event
 * @return {boolean}
 */
function hasModifier(evt) {
  return (evt.type === 'keydown' || evt.type === 'keypress') && (evt.altKey || evt.metaKey || evt.ctrlKey);
}
