import _ from 'lodash';

import createModel, { prop } from 'models/lib/create_model';
import Conversation from 'models/conversation';
import createEnum from 'scripts/lib/create_enum';

export const SlaStatuses = createEnum('WITHIN', 'EXCEEDING');

const createBuckets = types =>
  _.reduce(
    types,
    (buckets, type) => {
      buckets[type] = Number;
      return buckets;
    },
    {}
  );

const statusBuckets = createBuckets(Conversation.InboxStatus);
const slaBuckets = createBuckets(SlaStatuses);

export default createModel({
  modelName: 'SearchAggregations',

  properties: {
    types: prop({
      total: Number,
      buckets: Object,
    }),
    statuses: prop({
      buckets: prop(statusBuckets),
    }),
    dueDates: prop({
      buckets: prop(slaBuckets),
    }),
  },
});
