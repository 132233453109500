import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Avatar from './avatar';
import HtmlToText from 'html-to-text';
import UnknownCustomerAvatar from 'components/common/icons/unknown_customer_avatar';

const ProfileAvatar = ({
  className,
  channel,
  convoBackground,
  isLarge,
  isXLarge,
  isSmall,
  noWrapper,
  profile,
  wrapperClassName,
  ...props
}) => {
  let outerClassName = classnames('profileAvatar', wrapperClassName, {
    'profileAvatar-iconWrapper': !isLarge && !isSmall && !noWrapper,
    'profileAvatar-iconWrapper-small': isSmall && !isXLarge,
    'profileAvatar-iconWrapper-large': isLarge && !isXLarge,
    'profileAvatar-iconWrapper-xlarge': isXLarge,
    'profileAvatar-iconWrapper-convoBackground': convoBackground,
  });

  return (
    <div className={outerClassName} {...props}>
      {renderAvatar()}
    </div>
  );

  function renderAvatar() {
    let imageUrl = _.isFunction(profile.getImageUrl) ? profile.getImageUrl() : profile.image;

    if (channel) {
      return <ChannelIcon channel={channel} isLarge={isLarge} />;
    }

    if (imageUrl) {
      return <Avatar className={className} image={imageUrl} />;
    } else if (profile.name) {
      return <InitialIcon isLarge={isLarge} isSmall={isSmall} isXLarge={isXLarge} name={profile.name} />;
    }

    // placeholder profile image
    return <StyledUnknownAvatar $isLarge={isLarge} className="profileAvatar-unknownCustomer" />;
  }
};

ProfileAvatar.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.string,
  convoBackground: PropTypes.bool,
  isLarge: PropTypes.bool,
  isXLarge: PropTypes.bool,
  isSmall: PropTypes.bool,
  noWrapper: PropTypes.bool,
  profile: PropTypes.shape({
    getImageUrl: PropTypes.func,
    image: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  wrapperClassName: PropTypes.string,
};

export default ProfileAvatar;

const StyledUnknownAvatar = styled(UnknownCustomerAvatar)`
  ${p => (p.$isLarge ? 'height: 55px;' : '')}
  ${p => (p.$isLarge ? 'width: 55px;' : '')}
`;

const ChannelIcon = ({ channel, isLarge }) => {
  let channelClass = _.kebabCase(channel);
  let className = classnames('profileAvatar-channelIcon', `profileAvatar-${channelClass}`, {
    'profileAvatar-channelIcon-large': isLarge,
  });
  return <div className={className} />;
};

ChannelIcon.propTypes = {
  channel: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
};

const InitialIcon = ({ isSmall, isLarge, isXLarge, name }) => {
  let className = classnames('profileAvatar-initialIcon', {
    'profileAvatar-initialIcon-small': isSmall,
    'profileAvatar-initialIcon-large': isLarge && !isXLarge,
    'profileAvatar-initialIcon-xlarge': isXLarge,
  });

  let splitName = HtmlToText.fromString(name)
    .trim()
    .split(' ');
  let firstInitial = _.head(splitName)[0];
  let secondInitial = splitName.length > 1 ? _.last(splitName)[0] : '';
  return <div className={className}>{(firstInitial + secondInitial).toUpperCase()}</div>;
};

InitialIcon.propTypes = {
  isSmall: PropTypes.bool,
  isLarge: PropTypes.bool,
  isXLarge: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
