import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import theme from 'scripts/presentation/themes/default';
import ConversationItem from 'models/conversation_item';
import StackContainer from 'components/common/containers/stack_container';
import { PopoverArrow } from 'components/common/lib/_popover';
import PopoverCard from 'components/common/popover_card';
import Dotdotdot from 'react-dotdotdot';
import LongDateTime from 'components/lib/date/long_date_time';
import OpenOrRefreshCommentPanel from 'actions/task/open_or_refresh_comment_panel';
import RelativeDateTime from 'components/lib/date/relative_date_time';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import TaskAnalyticsLocations from 'actions/conversation_item/task/task_analytics_locations';
import UpdateAgentTaskRead from 'actions/task/update_agent_task_read';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function TaskManagerCard({ customerId, task }) {
  const [isVisible, setVisible] = useState(false);
  const [opener, setOpener] = useState(null);
  const { t } = useTranslation();

  const [onMouseOver, onMouseLeave] = useMouseHover(setVisible);

  const executeAction = useExecuteAction();
  const onClickTask = useCallback(
    evt => {
      evt.stopPropagation();
      executeAction(UpdateAgentTaskRead, task.id);
      executeAction(NavigateToItem, { customerId, itemId: task.id });
      executeAction(OpenOrRefreshCommentPanel, {
        itemId: task.id,
        location: TaskAnalyticsLocations.TASK_MANAGER,
      });
    },
    [customerId, executeAction, task.id]
  );

  const content = task.content;
  const isOverdue = moment().isAfter(content.dueAt);

  return (
    <StyledTaskManagerCard
      data-aid="taskManagerCard"
      isOverdue={isOverdue}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      ref={node => setOpener(node)}
    >
      <TaskNote data-aid="taskNote" onMouseDown={onClickTask}>
        <Dotdotdot clamp={1}>{content.note}</Dotdotdot>
      </TaskNote>
      <TaskDueDate data-aid="taskDueDate" onMouseDown={onClickTask}>
        <RelativeDateTime timestamp={content.dueAt} />
      </TaskDueDate>
      <StatusIndicator isOverdue={isOverdue} />
      <TaskPopoverCard
        bounds={{ top: 10 }}
        data-aid="taskPopoverCard"
        isVisible={isVisible}
        position="right"
        targetElement={opener}
      >
        <TaskPopoverHeader data-aid="taskPopoverHeader">
          <TaskPopoverDate data-aid="taskPopoverDate" isOverdue={isOverdue}>
            Due
            <LongDateTime className="task-popover-dueDate-timestamp" timestamp={moment(content.dueAt)} />
          </TaskPopoverDate>
          <TaskPopoverGoto onClick={onClickTask}>{t('Go to Task')}</TaskPopoverGoto>
        </TaskPopoverHeader>
        <TaskPopoverNote data-aid="taskPopoverNote">
          <Dotdotdot clamp={2}>{content.note}</Dotdotdot>
        </TaskPopoverNote>
      </TaskPopoverCard>
    </StyledTaskManagerCard>
  );
}

TaskManagerCard.propTypes = {
  customerId: PropTypes.string.isRequired,
  task: PropTypes.instanceOf(ConversationItem).isRequired,
};

const TaskPopoverCard = styled(PopoverCard)`
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.boxShadow.medium};
  height: 84px;
  padding: 8px 16px 0 16px;
  width: 408px;

  ${PopoverArrow} {
    display: none;
  }
`;

function useMouseHover(setVisible) {
  const closeTimeout = useRef(null);
  const onMouseOver = useCallback(() => {
    clearTimeout(closeTimeout.current);
    setVisible(true);
  }, [setVisible]);
  const onMouseLeave = useCallback(() => {
    closeTimeout.current = setTimeout(() => {
      setVisible(false);
    }, 100);
  }, [setVisible]);
  useEffect(() => {
    return () => {
      clearTimeout(closeTimeout.current);
    };
  }, []);

  return [onMouseOver, onMouseLeave];
}

const StyledTaskManagerCard = styled(StackContainer).attrs({
  inset: 'small',
})`
  background-color: white;
  border: 1px solid ${p => (p.isOverdue ? p.theme.colors.red400 : p.theme.colors.gray400)};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  min-height: 32px;
  transition: background-color ${p => p.theme.transitions.fast};
  white-space: pre-wrap;
  word-wrap: break-word;
  flex-direction: row;

  &:hover {
    background-color: ${p => p.theme.colors.gray100};
  }
`;

const TaskNote = styled.div`
  flex: 1 0 auto;
  width: 40%;
`;

const TaskDueDate = styled.div`
  padding-right: 8px;
`;

const TaskPopoverHeader = styled.div`
  display: flex;
  font-weight: ${p => p.theme.fontWeight.medium};
`;

const TaskPopoverDate = styled.div`
  flex: 1 0 auto;
`;

const TaskPopoverNote = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const TaskPopoverGoto = styled.div`
  color: #009b00;
  cursor: pointer;
`;

const SVGWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function StatusIndicator({ isOverdue }) {
  const fill = isOverdue ? theme.colors.red400 : theme.colors.green400;
  return (
    <SVGWrapper>
      <svg height="8" width="8">
        <circle cx="4" cy="4" fill={fill} r="4" />
      </svg>
    </SVGWrapper>
  );
}

StatusIndicator.propTypes = {
  isOverdue: PropTypes.bool,
};
