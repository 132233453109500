import _ from 'lodash';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import IdGenerator from 'scripts/domain/contracts/id_generator';

export default class AgentUserService {
  constructor(agentService, routingGroupService, userService, pubsub, database = getDatabase) {
    this.agentService = agentService;
    this.userService = userService;
    this.routingGroupService = routingGroupService;
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  add(requestorId, orgId, { correlationId, payload }) {
    let id = IdGenerator.newId();
    let user = {
      id,
      disabledAt: payload.disabledAt,
      roleIds: payload.roleIds,
      username: payload.email,
    };
    this.userService.add(requestorId, orgId, { correlationId, payload: user });

    let agent = {
      id,
      disabledAt: payload.disabledAt,
      email: payload.email,
      name: payload.name,
      voiceConfiguration: payload.voiceConfiguration || { preference: 'NONE' },
    };
    this.agentService.add(requestorId, orgId, { correlationId, payload: agent });

    _.forEach(payload.routingGroupIds, routingGroupId => {
      let routingGroup = _.find(this.getDatabase(orgId).routingGroups, { id: routingGroupId });
      routingGroup.agentIds.push(payload.id);
      this.routingGroupService.update(requestorId, orgId, routingGroupId, { correlationId, routingGroup });
    });

    this._pubsub.publish(`v3/requestor/${requestorId}/orgs/${orgId}/agent-users`, {
      correlationId,
      status: 'success',
      payload: { id },
    });
  }

  update(requestorId, orgId, userId, { correlationId, payload }) {
    let disabledAt = payload.disabled ? new Date().toISOString() : null;
    let user = {
      id: userId,
      disabledAt,
      roleIds: payload.roleIds,
      username: payload.email,
    };
    this.userService.update(requestorId, orgId, userId, { correlationId, payload: user });

    let agent = {
      id: payload.id,
      disabledAt,
      email: payload.email,
      name: payload.name,
      voiceConfiguration: payload.voiceConfiguration || { preference: 'NONE' },
    };
    this.agentService.update(orgId, userId, { correlationId, payload: agent });

    // routing groups
    let existingGroupsIds = [];

    _.forEach(this.getDatabase(orgId).routingGroups, routingGroup => {
      if (routingGroup.agentIds.indexOf(userId) > -1) {
        existingGroupsIds.push(routingGroup.id);
      }
    });

    let toRemove = _.difference(existingGroupsIds, payload.routingGroupIds);
    let toAdd = _.difference(payload.routingGroupIds, existingGroupsIds);

    toRemove.forEach(routingGroupId => {
      let routingGroup = _.find(this.getDatabase(orgId).routingGroups, { id: routingGroupId });
      _.remove(routingGroup.agentIds, agentId => agentId === userId);
      this.routingGroupService.update(requestorId, orgId, routingGroupId, { correlationId, routingGroup });
    });

    toAdd.forEach(routingGroupId => {
      let routingGroup = _.find(this.getDatabase(orgId).routingGroups, { id: routingGroupId });
      routingGroup.agentIds.push(userId);
      this.routingGroupService.update(requestorId, orgId, routingGroupId, { correlationId, routingGroup });
    });

    this._pubsub.publish(`v3/requestor/${requestorId}/orgs/${orgId}/agent-users/${userId}`, {
      correlationId,
      status: 'success',
    });
  }
}
