import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import TimeSelector from 'components/lib/time_selector_dumb';
import { getDatetimeFromTimestamp } from 'components/lib/date/datetime_helpers';

export default function TimePickerPanel({ startDate, endDate, onChange }) {
  return (
    <div className="dateRange-picker-row">
      <div className="timePickerPanel-time">
        <ShortDate className="timePickerPanel-time-date" date={startDate} />
        <TimeSelector
          className="timePickerPanel-time-selector"
          onChange={time => onChange({ startTime: time })}
          time={getDatetimeFromTimestamp(startDate).time}
        />
      </div>
      <div className="timePickerPanel-filler">to</div>
      <div className="timePickerPanel-time">
        <ShortDate className="timePickerPanel-time-date" date={endDate} />
        <TimeSelector
          className="timePickerPanel-time-selector"
          onChange={time => onChange({ endTime: time })}
          time={getDatetimeFromTimestamp(endDate).time}
        />
      </div>
      <div className="timePickerPanel-filler" />
    </div>
  );
}

TimePickerPanel.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export function ShortDate({ className, date }) {
  return (
    <time className={className} dateTime={date} title={moment(date).format('MMM D')}>
      {moment(date).format('MMM D')}
    </time>
  );
}
