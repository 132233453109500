import _ from 'lodash';

import ConversationItemType from 'models/conversation_item_type';
import FetchAnswerLinks from './fetch_answer_links';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getLatestManualCustomerConversationItemAttrs } from 'scripts/application/lib/conversation_history_helpers';
import { SnippetChannel, SnippetContentType } from 'models/answers/snippet';

/**
 * SelectAnswer takes an answer and (optionally) an answerType, and will _try_ to preview that answer in the
 * answer panel. If the passed answerType is not available, it uses a set of backup answer types to preview
 * instead.
 *
 * When `clearHistory` is passed as true, SelectAnswer will ensure that the internal "Back" button in the
 * AnswerPanel is reset.. so if you select a new answer from the search pane after clicking through a bunch
 * of answer links, it won't have a back button anymore.
 */
export default class SelectAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ answer, answerLanguage, answerType, clearHistory }) {
    const { searchLanguage } = this.context.stores.answerPanel.get();

    clearHistory = clearHistory != null ? clearHistory : false;
    answerLanguage = getAnswerLanguage(answer, answerLanguage, searchLanguage);
    answerType = getAnswerType(this.context, answer, answerLanguage, answerType);

    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.selectAnswer(answer, answerType, answerLanguage);
    if (clearHistory) {
      answerPanel.clearHistory();
    }
    this.context.stores.answerPanel.set(answerPanel);

    // Since we're selecting a different answer, we must clear out the old loaded answers (for links) and fetch the answers
    // associated with the new one.
    answerPanel.clearLoadingAnswerIds();
    this.context.stores.snippets.set([]);
    this.context.executeAction(FetchAnswerLinks, { answer });
  }
}

function getAnswerLanguage(answer, preferredAnswerLanguage, backupLanguage) {
  if (preferredAnswerLanguage && answer.hasLanguage(preferredAnswerLanguage)) {
    return preferredAnswerLanguage;
  } else if (backupLanguage && answer.hasLanguage(backupLanguage)) {
    return backupLanguage;
  }
  return answer.contents[0].language;
}

/**
 * Returns the answer type that will be shown by default for the newly selected answer. The preferred
 * answer type may not exist for the given answer, so we rely on a defined ordered list of backup answer
 * types to select the answer type in that case.
 *
 * @param {*} context
 * @param {*} answer
 * @param {*} overrideAnswerType
 */
function getAnswerType(context, answer, answerLanguage, overrideAnswerType) {
  const preferredAnswerType = overrideAnswerType || getPreferredAnswerType(getLastCustomerItemType(context));
  return getExistingTypeForAnswer(answer, answerLanguage, preferredAnswerType);
}

function getLastCustomerItemType(context) {
  const currentLocation = context.stores.currentLocation.get();
  if (!currentLocation.customerId || !context.stores.customers.has({ id: currentLocation.customerId })) return;

  const { conversations, conversationHistory } = context.stores.customers.storesFor(currentLocation.customerId);
  const activeConversationId = getActiveConversationId(conversations);
  const latestCustomerAttrs = getLatestManualCustomerConversationItemAttrs({
    conversationHistory,
    conversationId: activeConversationId,
    fields: ['content'],
  });
  return _.get(latestCustomerAttrs, 'content.type') || null;
}

function getPreferredAnswerType(lastCustomerItemType) {
  if (lastCustomerItemType === ConversationItemType.EMAIL) {
    return SnippetChannel.ANY_CHANNEL;
  } else if (_.includes(MESSAGE_ITEM_TYPES, lastCustomerItemType)) {
    return SnippetChannel.MESSAGE;
  }
  return SnippetChannel.INFO;
}

const MESSAGE_ITEM_TYPES = [
  ConversationItemType.FB_MESSAGE_INCOMING,
  ConversationItemType.SMS,
  ConversationItemType.TWITTER,
  ConversationItemType.CHAT,
  ConversationItemType.CHAT_MESSAGE,
  ConversationItemType.CHAT_CUSTOMER_URL,
];

function getExistingTypeForAnswer(answer, answerLanguage, preferredType) {
  if (doesAnswerExistForType(answer, answerLanguage, preferredType)) {
    return preferredType;
  }

  let content = answer.findContentByLanguage(answerLanguage);
  return _.find(BACKUP_ANSWER_TYPES, type => !!_.get(content, `${SnippetContentType[type]}.bodyHtml`));
}

const BACKUP_ANSWER_TYPES = [
  SnippetChannel.INFO,
  SnippetChannel.ANY_CHANNEL,
  SnippetChannel.MESSAGE,
  SnippetChannel.SELF_SERVICE,
];

function doesAnswerExistForType(answer, answerLanguage, type) {
  let contentChannelType = SnippetContentType[type];
  let content = answer.findContentByLanguage(answerLanguage);
  return !!_.get(content, `${contentChannelType}.bodyHtml`);
}
