import HOTKEYS from './hotkeys_list';

export default function registerHotkey(hotkey) {
  const { key, group, label } = hotkey;
  if (!group) {
    throw new Error(`Hotkey [${hotkey.key}] must have a defined group`);
  }
  if (!label) {
    throw new Error(`Hotkey [${hotkey.key}] must have a defined key`);
  }

  let groupKeys = HOTKEYS[group];
  if (!groupKeys) {
    groupKeys = {};
    HOTKEYS[group] = groupKeys;
  }
  groupKeys[key] = label;

  return hotkey;
}
