import cleanupNonCurrentCustomer from 'actions/customer/lib/cleanup_non_current_customer';
import SystemNotificationGenerator from 'scripts/domain/contracts/system_notification_generator';

export default class DeclinePhoneCall {
  constructor(context) {
    this.context = context;
  }

  run() {
    let activeCall = this.context.stores.activeCall.get();
    if (!activeCall) {
      return;
    }

    // check is current agent is observer on call
    let currentAgent = this.context.stores.currentAgent.get();
    const customerId = activeCall.customer.id;
    const conversationItemId = activeCall.conversationItem.id;
    let phoneCall = activeCall.conversationItem.content;
    if (phoneCall.isParticipantObserver(currentAgent.id) && phoneCall.isTransferee(currentAgent.id)) {
      // cancel local transfer state to remove banner
      phoneCall.transfer.cancelTransfer();
      this.context.stores.activeCall.set(activeCall);
      this.context.gateways.phoneControlsHttp.observerDeclineTransfer(customerId, conversationItemId, {
        participantId: currentAgent.id,
      });
    } else {
      this.cleanupActiveCall(activeCall);
      this.declineCall(activeCall);
    }
    SystemNotificationGenerator.closeNotification(activeCall.conversationItem.id);
    this.cleanupNonCurrentCustomer(activeCall.customer.id);
  }

  cleanupActiveCall(activeCall) {
    this.context.gateways.activeCall.cancelFetches();
    this.context.gateways.conversationItem.unsubscribe(activeCall.customer.id, activeCall.conversationItem.id);
    this.context.gateways.customerHistoryMerge.unsubscribe(activeCall.customer.id);
    this.context.stores.activeCall.remove();
  }

  declineCall(activeCall) {
    let currentAgentId = this.context.stores.currentAgent.get().id;

    this.context.gateways.routingEvent.broadcast({
      agentId: currentAgentId,
      conversationItemId: activeCall.conversationItem.id,
      customerId: activeCall.customer.id,
      type: this._getRoutingEventType(),
    });
  }

  cleanupNonCurrentCustomer(customerId) {
    cleanupNonCurrentCustomer(this.context, customerId);
  }
}
