import _ from 'lodash';
import ReportConfigsConverter from 'scripts/application/dto_converters/report_configs_converter';

export default async function fetchReportConfigs(context) {
  context.stores.reportConfigs.setLoading();

  const { enabled: threadsEnabled } = await context.gateways.threadsConfiguration.fetch();

  return context.gateways.reportConfigs
    .fetch()
    .then(response => handleSuccess(response))
    .catch(err => handleError(err));

  function handleSuccess(response) {
    const orgIsDemoMode = context.stores.appFeatures.get().isEnabled('demoMode');

    const reportConfigs = ReportConfigsConverter.fromDto(response);

    let configs = reportConfigs.configs;
    if (!threadsEnabled) {
      configs = _.reject(configs, reportConfig => reportConfig.metricSetName === 'SelfServiceThreadsReport');
    }

    if (!orgIsDemoMode) {
      // filter out all report configs that is a demo of a report
      reportConfigs.setConfigs(_.reject(configs, reportConfig => !!reportConfig.demoOf));
    } else {
      let [demoReports, normalReports] = _(configs)
        .reject('demoExcluded')
        .partition('demoOf');

      // for the demo reports, get a list of all the regular reports that these demoable ones will replace
      const metricSetNamesToReplace = _(demoReports)
        .reject(demoReportConfig => demoReportConfig.demoOf === 'self')
        .keyBy('demoOf')
        .value();

      // remove the normal reports that already have demo configs
      const filteredReports = _(normalReports).reject(reportConfig =>
        metricSetNamesToReplace.hasOwnProperty(reportConfig.metricSetName)
      );

      reportConfigs.setConfigs(filteredReports.concat(demoReports).value());
    }

    context.stores.reportConfigs.set(reportConfigs);
    context.stores.reportConfigs.resetLoading();
  }

  function handleError(err) {
    context.stores.reportConfigs.resetLoading();
    throw err;
  }
}
