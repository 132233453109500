import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import scrollparent from 'scrollparent';

import ConversationItem from 'models/conversation_item';
import ConversationItemMenuContainer from './conversation_item_menu_container';
import { MenuContainer } from 'components/lib/menu_deprecated';
import TaskAnalyticsLocations from 'actions/conversation_item/task/task_analytics_locations';
import TrackItemMenuOpened from 'actions/conversation_item/task/track_item_menu_opened';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function ConversationItemMenuOpener({
  item,
  location,
  onOpenPinToCustomer,
  renderButton,
  shouldShowOverflowMenu,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const executeAction = useExecuteAction();

  const onClick = useCallback(() => {
    if (!shouldShowOverflowMenu && !isOpen) return;

    setIsOpen(true);
    const itemType = item.contentType();
    executeAction(TrackItemMenuOpened, {
      itemId: item.id,
      itemType,
      location: location || TaskAnalyticsLocations.TASK_ITEM,
    });
  }, [executeAction, isOpen, item, location, shouldShowOverflowMenu]);

  const onClose = useCallback(() => setIsOpen(false), []);

  const [scrollParent, setScrollParent] = useState(null);
  const menuRef = useRef(null);
  const setRef = useCallback(ref => {
    menuRef.current = ref;
    menuRef.current && setScrollParent(scrollparent(menuRef.current.container));
  }, []);

  // Once the menu is open, we should avoid doing anything that may cause `ref` to update,
  // as it will cause setting state, re-rendering and will likely mess up the portals and
  // everything that is displayed there. That's why we hide the button icon instead of
  // hiding the entire menu container
  const menuButton = renderButton(shouldShowOverflowMenu || isOpen);
  return (
    <MenuContainer
      button={menuButton}
      className="conversationItemMenu"
      onClick={onClick}
      onClose={onClose}
      positionInside={scrollParent}
      ref={node => setRef(node)}
    >
      {isOpen ? <ConversationItemMenuContainer item={item} onOpenPinToCustomer={onOpenPinToCustomer} /> : null}
    </MenuContainer>
  );
}

ConversationItemMenuOpener.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  location: PropTypes.string,
  onOpenPinToCustomer: PropTypes.func,
  renderButton: PropTypes.func.isRequired,
  shouldShowOverflowMenu: PropTypes.bool,
};
