import _ from 'lodash';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import { AgentInbox, GroupInbox } from './lib/inbox_backend';
import Conversation from 'models/conversation';
import { InboxConversationStatus } from 'models/location/inbox';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

var InboxStatus = InboxConversationStatus;
var Status = Conversation.Status;

export default class AgentConversationListService {
  constructor(publishResponse) {
    this.publishResponse = publishResponse;
  }

  getRoutes() {
    return bindCallbacks({
      '/api/v1/orgs/:orgId/agents/:agentId/conversation-counts': {
        GET: (attrs, callback, path, { orgId, agentId }) =>
          callback(null, {
            status: 200,
            statusText: statusText(200),
            response: this.fetchAgentCounts(orgId, agentId),
          }),
      },
      '/api/v1/orgs/:orgId/routing-groups-conversation-counts': {
        GET: (attrs, callback, path, { orgId }) =>
          callback(null, {
            status: 200,
            statusText: statusText(200),
            response: this.fetchGroupCounts(orgId),
          }),
      },
    });
  }

  fetchAgentCounts(orgId, agentId) {
    let customers = getDatabase(orgId).customers;
    let openAgentInbox = new AgentInbox(customers, agentId, Status.OPEN);
    let waitingAgentInbox = new AgentInbox(customers, agentId, Status.WAITING);

    return {
      agentId,
      counts: [
        {
          status: Status.OPEN,
          count: openAgentInbox.getInboxConversationDtos().length,
          unreadCount: openAgentInbox.getInboxConversationDtos().length,
        },
        { status: Status.WAITING, count: waitingAgentInbox.getInboxConversationDtos().length },
      ],
    };
  }

  fetchGroupCounts(orgId) {
    let customers = getDatabase(orgId).customers;
    let routingGroupIds = this.getRoutingGroupIds(orgId);

    return routingGroupIds.map(groupId => {
      let newGroupInbox = new GroupInbox(customers, groupId, InboxStatus.NEW);
      let openGroupInbox = new GroupInbox(customers, groupId, Status.OPEN);
      let waitingGroupInbox = new GroupInbox(customers, groupId, Status.WAITING);

      return {
        groupId,
        counts: [
          { status: InboxStatus.NEW, count: newGroupInbox.getInboxConversationDtos().length },
          { status: Status.OPEN, count: openGroupInbox.getInboxConversationDtos().length },
          { status: Status.WAITING, count: waitingGroupInbox.getInboxConversationDtos().length },
        ],
      };
    });
  }

  getRoutingGroupIds(orgId) {
    var routingGroups = getDatabase(orgId).routingGroups;
    return _.map(routingGroups, routingGroup => routingGroup.id);
  }
}
