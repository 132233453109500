import Color from 'color';
import { createPluginFactory, MARK_COLOR } from '@udecode/plate';

// Ignore color style for the elements that are NOT on the allowed list
const ALLOWED_TAGS = ['B', 'DIV', 'EM', 'I', 'P', 'SPAN', 'STRONG', 'U'];

/**
 * Simple text color plugin that replaces the Plate provided plugin and adds more robust
 * checks for the default text color. If the pasted text has explicit color style and the
 * color is "dark enough to be black", it will be stripped and the text will be rendered
 * with the default Gladly font color. This is done to deal with the situation when the
 * agent pastes some external HTML with explicit colors that are hard to distinguish from
 * black and that may interfere with Gladly styling (links etc.)
 */
export function createFontColorPlugin() {
  return createPluginFactory({
    key: MARK_COLOR,
    inject: {
      props: {
        nodeKey: MARK_COLOR,
      },
    },

    deserializeHtml: {
      isLeaf: true,

      getNode(element) {
        if (element.style.color) {
          return shouldAllowElementColor(element.style.color) ? { [MARK_COLOR]: element.style.color } : {};
        }
      },

      rules: [{ validStyle: { color: '*' }, validNodeName: ALLOWED_TAGS }],
    },
  })();
}

function shouldAllowElementColor(cssColor) {
  try {
    const color = Color(cssColor);
    return color.luminosity() > 0.05;
  } catch {
    // If we are here, the element has a style declaration that we are unable to support, e.g. `var(--some-variable)`
    return false;
  }
}
