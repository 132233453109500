import moment from 'moment';

import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import Factory from 'factories/all';
import InteractionType from 'models/interaction_type';

export default class IncomingVoicemailSimulator extends CommunicationSimulator {
  constructor({ orgId, publisher, services }, database) {
    super({ attribute: 'incomingVoicemails', createItem: createVoicemailItem, orgId, publisher, services }, database);
  }

  _createRoutingItem(attrs) {
    return Factory.build(
      'RoutingItemForInbound',
      this._getRoutingItemAttrs({ channel: InteractionType.VOICEMAIL, ...attrs })
    );
  }
}

function createVoicemailItem(rawVoicemail) {
  return Factory.build('VoicemailItem', {
    initiator: {
      type: Communicator.CUSTOMER,
      id: rawVoicemail.customerId,
    },
    content: rawVoicemail.content,
    routeAt: moment().format(),
  });
}
