import _ from 'lodash';

import createCollectionStoreClass from './lib/create_collection_store_class';
import createConverter from 'scripts/adapters/immutable_converters/lib/create_converter';
import ConversationItem from 'models/conversation_item';
import Observable from 'scripts/lib/observable_mixin';
import { typeReflect } from 'models/conversation_item/content_from_js';

const ConversationHistoryStoreBase = createCollectionStoreClass({
  converter: createConverter(ConversationItem),
});

class ConversationHistoryStore extends ConversationHistoryStoreBase {
  _isMatch(plainObject, query) {
    if (query.content && query.content.type) {
      query = _.cloneDeep(query);
      query.content.type = typeReflect.instanceClassToType(query.content.type);
    }
    return super._isMatch(plainObject, query);
  }

  addOrReplace(entity) {
    const foundEntity = this.findBy({ id: entity.id });
    if (this._shouldAddOrReplace(entity, foundEntity)) {
      super.addOrReplace(entity);
    }
  }

  _shouldAddOrReplace(entity, foundEntity) {
    if (!foundEntity || foundEntity.isPlaceholder() || foundEntity.version < entity.version) {
      return true;
    }

    if (foundEntity.version === entity.version) {
      if (!_.get(foundEntity, 'content.version') && _.get(entity, 'content.version')) {
        return true;
      }
      if (
        _.get(foundEntity, 'content.version') &&
        _.get(entity, 'content.version') &&
        foundEntity.content.version < entity.content.version
      ) {
        return true;
      }
    }
    return false;
  }
}

_.extend(ConversationHistoryStore.prototype, Observable);

export default ConversationHistoryStore;
