export default class PubsubCompositeClient {
  constructor(defaultClient, demoClient) {
    this._activeClient = this._defaultClient = defaultClient;
    this._demoClient = demoClient;
  }

  publish(topic, message) {
    this._activeClient.publish(topic, message);
  }

  subscribe(topicPattern, onMessage, onAck) {
    this._activeClient.subscribe(topicPattern, onMessage, onAck);
  }

  unsubscribe(topic, onReceive) {
    this._activeClient.unsubscribe(topic, onReceive);
  }

  on(event, listener) {
    this._activeClient.on(event, listener);
  }

  removeListener(event, listener) {
    this._activeClient.removeListener(event, listener);
  }

  connect(username, orgId) {
    this._activeClient.connect(username, orgId);
  }

  configureMqttServerV1(isMqttServerV1) {
    this._activeClient.configureMqttServerV1(isMqttServerV1);
  }

  multiplyDefaultMqttKeepAlive(multiplier) {
    this._activeClient.multiplyDefaultMqttKeepAlive(multiplier);
  }

  reset() {
    this._activeClient.reset();
    this._activeClient = this._defaultClient;
  }

  enableDemo() {
    this._activeClient = this._demoClient;
    this._activeClient.activateDemoClient();
  }

  disableDemo() {
    this._activeClient = this._defaultClient;
    this._demoClient.close();
  }
}
