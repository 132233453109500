import ConversationItem from '../../models/conversation_item';
import Communicator from '../../models/communicator';
import ItemLinkContent from 'models/item_link_content';

export default function createItemLink({ content, currentAgentId, customerId }) {
  return ConversationItem.create({
    content: ItemLinkContent.create(content),
    customerId,
    initiator: {
      id: currentAgentId,
      type: Communicator.AGENT,
    },
  });
}
