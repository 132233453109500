import _ from 'lodash';

export default class CloseWebhooks {
  constructor(context) {
    this.context = context;
  }

  run() {
    _.forEach(this.context.stores.webhooks.getAllErrors(), (val, webhookId) => {
      this.context.stores.webhooks.clearErrors(webhookId);
    });
    this.context.gateways.webhook.unsubscribeAll();
  }
}
