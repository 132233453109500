import connect from 'components/lib/connect';
import CloseConversation from 'actions/conversation/close_conversation';
import CloseConversationWarningModal from 'components/modals/close_conversation_warning_modal';
import CloseAndGetNextConversation from 'actions/conversation/close_and_get_next_conversation';

function mapStateToProps(context) {
  const modalAttrs = context.getProvider('modal').get();
  const { conversationId, customerId, shouldGetNext } = modalAttrs;

  return { conversationId, customerId, shouldGetNext };
}

function mapExecuteToProps(executeAction, props) {
  function closeConversation(conversationId, customerId) {
    executeAction(CloseConversation, { conversationId, customerId });
    props.onClose();
  }

  function closeAndGetNextConversation(conversationId, customerId) {
    executeAction(CloseAndGetNextConversation, { conversationId, customerId });
    props.onClose();
  }

  return {
    onClose: props.onClose,
    closeConversation,
    closeAndGetNextConversation,
  };
}

function mergeProps(stateProps, execProps) {
  const onConfirm = stateProps.shouldGetNext
    ? execProps.closeAndGetNextConversation.bind(null, stateProps.conversationId, stateProps.customerId)
    : execProps.closeConversation.bind(null, stateProps.conversationId, stateProps.customerId);

  return {
    shouldGetNext: stateProps.shouldGetNext,
    onClose: execProps.onClose,
    onConfirm,
  };
}

export default connect(mapStateToProps, mapExecuteToProps, mergeProps)(CloseConversationWarningModal);
