import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AgentStatusButton from './agent_status_button';
import AgentStatusTooltip from './agent_status_tooltip';
import connect from 'components/lib/connect';
import { EndpointTypes } from 'models/endpoint';
import Mail from './icons/mail';
import MailSelected from './icons/mail_selected';
import registerHotkey from 'components/hotkeys/register_hotkey';
import RoutableCounts from 'models/routable_counts';
import { RoutingChannel, RoutingPreferenceGroup } from 'models/agent_routing_preferences';
import UpdateAgentRoutingPreferences from 'actions/routing/update_agent_routing_preferences';
import withShortcuts from 'components/keyboard_shortcuts/with_shortcuts';

export function NonInteractiveStatusButton({ channels, isFocusOn, isLoading, isPreferred, routableCounts, onClick }) {
  if (isFocusOn) {
    return <StyledDiv>{isPreferred ? <MailSelected /> : <Mail />}</StyledDiv>;
  }

  return (
    <div className={classnames('nonInteractiveStatusButton', { disabled: isLoading })}>
      <AgentStatusTooltip message={getTooltipText(channels)} preferenceGroup={RoutingPreferenceGroup.NON_INTERACTIVE}>
        {({ isHovered }) => (
          <AgentStatusButton
            onClick={handleClick}
            preferenceGroup={RoutingPreferenceGroup.NON_INTERACTIVE}
            routableCounts={routableCounts}
            showTrueCount={isHovered}
          >
            {isPreferred ? <MailSelected /> : <Mail />}
          </AgentStatusButton>
        )}
      </AgentStatusTooltip>
    </div>
  );

  function handleClick() {
    onClick({ isPreferred });
  }
}

NonInteractiveStatusButton.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.oneOf(_.values(EndpointTypes))).isRequired,
  isFocusOn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPreferred: PropTypes.bool.isRequired,
  routableCounts: PropTypes.instanceOf(RoutableCounts),
  onClick: PropTypes.func.isRequired,
};

function getTooltipText(channels) {
  let enabledChannelNames = [];
  if (_.includes(channels, EndpointTypes.EMAIL)) {
    enabledChannelNames.push('Email');
  }
  if (_.includes(channels, EndpointTypes.VOICE)) {
    enabledChannelNames.push('Voicemail');
  }
  enabledChannelNames.push('Task');

  return enabledChannelNames.sort().join(', ');
}

const NonInteractiveStatusButtonWithShortcuts = withShortcuts(NonInteractiveStatusButton, [
  registerHotkey({
    key: 'alt+1',
    handler: _.debounce(({ isPreferred, onClick }) => onClick({ isPreferred }), 300, {
      leading: true,
      trailing: false,
    }),
    group: 'Availability',
    label: 'Toggle readiness for mail + tasks',
  }),
]);

export { NonInteractiveStatusButtonWithShortcuts };

const NonInteractiveStatusButtonContainer = connect(
  mapStateToProps,
  mapExecuteToProps
)(NonInteractiveStatusButtonWithShortcuts);

function mapStateToProps({ getProvider }, { channels }) {
  const preferencesProvider = getProvider('agentRoutingPreferences');
  const preferences = preferencesProvider.getPending() || preferencesProvider.get();
  const isPreferred =
    preferences.isPreferredOnChannel(RoutingChannel.MAIL) && preferences.isPreferredOnChannel(RoutingChannel.TASK);
  const routableCounts = getProvider('routableCounts').get();

  return {
    channels,
    isFocusOn: preferences.isFocusOn,
    isLoading: preferences._version === 0,
    isPreferred,
    routableCounts,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClick: ({ isPreferred }) =>
      executeAction(UpdateAgentRoutingPreferences, {
        channels: {
          [RoutingChannel.MAIL]: !isPreferred,
          [RoutingChannel.TASK]: !isPreferred,
        },
      }),
  };
}

export default NonInteractiveStatusButtonContainer;

const StyledDiv = styled.div`
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
`;
