import React, { useCallback } from 'react';
import connect from 'components/lib/connect';
import PropTypes from 'prop-types';

import ConfirmOrCancelModal from 'components/common/confirm_or_cancel_modal';
import SendCreditCardRequest from 'actions/chat/send_credit_card_request';
import { useExecuteAction } from 'components/hooks/connect_hooks';

/* Currency Formatter */
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function CreditCardAmountModal({ amount, customerName, memo, onClose }) {
  const executeAction = useExecuteAction();
  const onSubmit = useCallback(() => executeAction(SendCreditCardRequest, { amount, memo }), [amount, memo]);

  return (
    <ConfirmOrCancelModal
      onCancel={onClose}
      onSubmit={onSubmit}
      submitText={'Send'}
      text={`${formatter.format(amount)} from ${customerName}`}
      title={'You are about to request'}
    />
  );
}

CreditCardAmountModal.propTypes = {
  amount: PropTypes.number.isRequired,
  customerName: PropTypes.string,
  memo: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider }, props) {
  const { amount, memo, customerName } = getProvider('modal').get();

  return { amount, memo, customerName, ...props };
}

export default connect(mapStateToProps)(CreditCardAmountModal);
