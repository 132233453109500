import React from 'react';

import { PLACEHOLDER } from '../../plugins/placeholders';

export const placeholderRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'placeholder') {
        return { object: 'inline', type: PLACEHOLDER, nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'inline' && object.type === PLACEHOLDER) {
        return <placeholder>{children}</placeholder>;
      }
    },
  },
];
