import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import connect from 'components/lib/connect';
import { CONVERSATION, CUSTOMER_TYPING_INDICATOR, ITEM, LAST_CONVERSATION_SPACER } from './feed/feed_item_types';
import FeedItems from './feed/feed_items';
import fetchFeedItems from './fetch_feed_items';
import { ItemsContextProviderContainer } from 'components/customer/conversation_history/conversation_items_v2/items_context';
import { H1 } from 'components/common/headers';
import Spinner from 'components/common/spinner';
import UnreadMessagesBanner from './unread_messages_banner';
import useManageAgentRead from './use_manage_agent_read';

export function ConversationHistory({
  customerId,
  isEmpty,
  isLoading,
  items,
  hasErrorLoadingConversationHistory,
  hasErrorLoadingConversations,
  hasErrorLoadingItemIds,
  latestItemIdPerConversation,
}) {
  if (hasErrorLoadingConversationHistory || hasErrorLoadingConversations || hasErrorLoadingItemIds) {
    return (
      <ErrorLoadingHistory
        customerId={customerId}
        hasErrorLoadingConversationHistory={hasErrorLoadingConversationHistory}
        hasErrorLoadingConversations={hasErrorLoadingConversations}
        hasErrorLoadingItemIds={hasErrorLoadingItemIds}
      />
    );
  } else if (isLoading) {
    return <LoadingHistory />;
  } else if (isEmpty) {
    return <EmptyHistory />;
  }

  return <NewHistoryContainer customerId={customerId} items={items} key={customerId} />;
}

ConversationHistory.propTypes = {
  customerId: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf([CONVERSATION, CUSTOMER_TYPING_INDICATOR, ITEM, LAST_CONVERSATION_SPACER]).isRequired,

      conversationId: PropTypes.string,
      initiatedByCurrentAgent: PropTypes.bool,
      isFirstInStack: PropTypes.bool,
      isLastInStack: PropTypes.bool,
      isStacked: PropTypes.bool,
      timestamp: PropTypes.string,
    })
  ),
  hasErrorLoadingConversationHistory: PropTypes.bool,
  hasErrorLoadingConversations: PropTypes.bool,
  hasErrorLoadingItemIds: PropTypes.bool,
  latestItemIdPerConversation: PropTypes.objectOf(PropTypes.string),
};

export function EmptyHistory() {
  return (
    <div style={{ flex: '1 1 auto' }}>
      <div className="emptyConversationHistory">
        <div className="emptyConversationHistory-message">
          <H1 data-aid="emptyConversationHistory-message-main">No Conversations Yet</H1>
          <div className="emptyConversationHistory-message-detail">Click the plus menu to start a conversation</div>
        </div>
      </div>
    </div>
  );
}

export function ErrorLoadingHistory({
  customerId,
  hasErrorLoadingConversationHistory,
  hasErrorLoadingConversations,
  hasErrorLoadingItemIds,
}) {
  useEffect(() => {
    analytics.track('Conversation Feed Loading Error Shown', {
      customerId,
      hasErrorLoadingConversationHistory,
      hasErrorLoadingConversations,
      hasErrorLoadingItemIds,
    });
  }, [customerId, hasErrorLoadingConversationHistory, hasErrorLoadingConversations, hasErrorLoadingItemIds]);

  return (
    <ConversationHistoryMessageContainer>
      <ConversationHistoryMessage>
        <H1 data-aid="errorLoadingConversationHistory-message-main">The conversation history could not be loaded</H1>
        <ConversationHistoryMessageDetail>Please refresh the page</ConversationHistoryMessageDetail>
      </ConversationHistoryMessage>
    </ConversationHistoryMessageContainer>
  );
}

export function LoadingHistory() {
  return (
    <SpinnerContainer data-aid="conversationHistory-loading">
      <Spinner />
    </SpinnerContainer>
  );
}

function useCalculateStackedItems(items) {
  return useMemo(() => calculateStackedItems(items), [items]);
}

function calculateStackedItems(items) {
  const newItems = [];

  let previousItem = null;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    newItems.push(items[i]);

    if (item.isStacked && previousItem && !previousItem.isStacked) {
      newItems[i - 1] = {
        ...previousItem,
        isFirstInStack: true,
      };
    } else if (!item.isStacked && previousItem && previousItem.isStacked) {
      newItems[i - 1] = {
        ...previousItem,
        isLastInStack: true,
      };
    }
    previousItem = item;
  }

  if (previousItem && previousItem.isStacked) {
    newItems[items.length - 1] = {
      ...previousItem,
      isLastInStack: true,
    };
  }

  return newItems;
}

const SpinnerContainer = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  flex: 1;
  width: 64px;
`;

const ConversationHistoryMessageContainer = styled.div`
  flex: 1 1 auto;
`;

const ConversationHistoryMessage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const ConversationHistoryMessageDetail = styled.div`
  color: ${p => p.theme.colors.gray600};
  font-size: ${p => p.theme.fontSize.medium};
  text-align: center;
  white-space: pre;
`;

function NewHistory({
  agentRead,
  anchor,
  anchorPosition,
  currentConversationId,
  currentItemId,
  customerId,
  isLoadingAgentRead,
  items,
  lastAnchorChangeAt,
}) {
  const lastReadTimestamp = useTimestampFromAgentRead(agentRead, items);
  const [splicedItems, onVisibleRangeChanged, unreadBannerProps] = useManageAgentRead({
    customerId,
    items,
    isLoadingAgentRead,
    lastReadTimestamp,
  });
  const [anchorId, newAnchorPosition] = useAnchor(anchor, anchorPosition, currentItemId, currentConversationId, items);
  items = useCalculateStackedItems(splicedItems);

  return (
    <ItemsContextProviderContainer>
      <div style={{ flex: '1 1 auto' }}>
        <FeedItems
          anchorId={anchorId}
          anchorPosition={newAnchorPosition}
          customerId={customerId}
          items={items}
          lastAnchorChangeAt={lastAnchorChangeAt}
          onVisibleRangeChanged={onVisibleRangeChanged}
        />
        <UnreadMessagesBanner items={items} {...unreadBannerProps} />
      </div>
    </ItemsContextProviderContainer>
  );
}

const NewHistoryContainer = connect(mapStateToProps)(NewHistory);

export { NewHistoryContainer };

function mapStateToProps({ getProvider }) {
  const agentRead = getProvider('agentRead').get();
  const currentLocation = getProvider('currentLocation').get();
  const { anchor, anchorPosition, currentConversationId, currentConversationItemId, lastRoutedAt } = currentLocation;

  return {
    agentRead,
    anchor,
    anchorPosition,
    currentConversationId,
    currentItemId: currentConversationItemId,
    isLoadingAgentRead: getProvider('agentRead').isLoading(),
    lastAnchorChangeAt: lastRoutedAt,
  };
}

function useAnchor(anchor, passedAnchorPosition, currentItemId, currentConversationId, items) {
  let anchorId;
  let anchorPosition = 'bottom';
  if (anchor) {
    anchorId = anchor;
    anchor = null;
  } else if (currentItemId) {
    anchorId = currentItemId;
    anchorPosition = 'center';
  } else if (currentConversationId) {
    if (passedAnchorPosition === 'top') {
      anchorId = currentConversationId;
    } else {
      anchorId = getLastItemIdForConversation(items, currentConversationId);
    }
    anchorPosition = passedAnchorPosition || 'bottom';
  } else {
    const lastItem = _.last(items);
    anchorId = (lastItem && lastItem.id) || undefined;
  }

  return [anchorId, anchorPosition];
}

function getLastItemIdForConversation(items, currentConversationId) {
  let anchorId;
  let foundConversation = false;
  for (let i = 0; i < items.length; i++) {
    if (foundConversation && items[i].type === CONVERSATION) {
      break;
    }
    if (items[i].id === currentConversationId) {
      foundConversation = true;
    } else if (foundConversation) {
      anchorId = items[i].id;
    }
  }

  return anchorId;
}

function useTimestampFromAgentRead(agentRead, items) {
  return useMemo(() => {
    if (!agentRead) {
      return null;
    }

    let latestConversationId;
    for (let i = items.length - 1; i >= 0; i--) {
      if (items[i].type === CONVERSATION) {
        latestConversationId = items[i].id;
        break;
      } else if (items[i].conversationId) {
        latestConversationId = items[i].conversationId;
        break;
      }
    }

    return agentRead.conversationId === latestConversationId ? agentRead.readTo : null;
  }, [agentRead, items]);
}

export default fetchFeedItems(ConversationHistory);
