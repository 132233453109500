import AutoAcceptedSessionNotification from 'models/notification/auto_accepted_session_notification';

export default class ActiveSessionCustomerProfileObserver {
  constructor(context) {
    this.context = context;
  }

  handleEntity(profile) {
    this.updateSessionCustomer(profile);
  }

  handleFetchSuccess(profile) {
    this.updateSessionCustomer(profile);
  }

  updateSessionCustomer(profile) {
    if (!profile.name) {
      return;
    }

    let customerSession = this.context.stores.activeSessions.findBy({ customer: { id: profile.id } });
    if (!customerSession) {
      return;
    }

    customerSession.updateCustomerName(profile.name);
    this.context.stores.activeSessions.replace(customerSession);

    let autoAcceptNotification = this.context.stores.notifications.findBy({ id: customerSession.id });
    if (autoAcceptNotification && autoAcceptNotification instanceof AutoAcceptedSessionNotification) {
      autoAcceptNotification.updateProfileName(profile.name);
      this.context.stores.notifications.replace(autoAcceptNotification);
    }
  }
}
