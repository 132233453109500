import React, { useCallback } from 'react';

import ErrorReporter from 'scripts/infrastructure/error_reporter';
import { isDevelopment } from 'scripts/application/lib/environment';
import connect from 'components/lib/connect';
import { Item, Text, Timestamp } from './shared_notification';
import MarkAgentNotificationAsRead from 'actions/agent_notifications/mark_agent_notification_as_read';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function AgentMentionedInItem({ agentName, notification }) {
  const onClick = useOnClick(notification);

  if (isInvalidItemMention(notification)) {
    reportInvalidItemMentionError();
    return null;
  }

  let mentionItemType = getMentionedItemType(notification);
  return (
    <Item onClick={onClick}>
      <Text hasRead={notification.hasRead()}>
        {agentName} mentioned you in {mentionItemType}
      </Text>
      <Timestamp timestamp={notification.createdAt} />
    </Item>
  );
}

const AgentMentionedInItemContainer = connect(mapStateToProps)(AgentMentionedInItem);

function mapStateToProps({ getProvider }, { notification }) {
  const agent = getProvider('agents').findBy({ id: notification.initiator.id });

  return {
    agentName: agent ? agent.getDisplayName() : 'An agent',
  };
}

function useOnClick(notification) {
  const { id, content } = notification;

  const executeAction = useExecuteAction();
  return useCallback(() => {
    executeAction(MarkAgentNotificationAsRead, { id });
    executeAction(NavigateToItem, {
      customerId: content.customerId,
      itemId: content.itemId,
    });
  }, [id, content.customerId, content.itemId]);
}

function isInvalidItemMention(notification) {
  const { content } = notification;
  return content.itemType !== 'TASK' && content.itemType !== 'NOTE';
}

function reportInvalidItemMentionError(notification) {
  const { content } = notification;
  const unknownItemErr = new Error(`unexpected item agent mention notification itemType: ${content.itemType}`);
  ErrorReporter.reportError(unknownItemErr, {
    message: 'Unexpected item agent mention notification itemType - ignoring',
  });

  const devMode = isDevelopment();
  if (devMode) {
    throw unknownItemErr;
  }
  return;
}

function getMentionedItemType(notification) {
  const { content } = notification;

  if (content.itemType === 'TASK') {
    return 'a task';
  } else if (content.itemType === 'NOTE') {
    return 'a note';
  }
}

export default AgentMentionedInItemContainer;
