import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import AnswerPanelIcon from 'components/lib/icons/answer_panel_icon_with_circle';
import Attachments from './attachments';
import CustomerContext from 'components/customer/customer_context';
import EditorScrollContext from './editor_scroll_context';
import ToggleAnswerPanel from 'actions/answers/toggle_answer_panel';
import Tooltip from 'components/common/tooltip';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const EditorScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;

  .slateTextEditor {
    min-height: 23vh;
    padding: 8px;
  }
`;

const EditorContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 -4px;
  min-height: 0;
`;

/**
 * EditorContainer wraps an editor component in a scrollable div and will optionally render attachments.
 */
export function EditorContainer({ children, includeAttachments, excludeAnswerMenuButton }) {
  const scrollerRef = useRef(null);

  const [parentScrollNode, setParentScrollNode] = useState(null);

  return (
    <EditorScrollContext.Provider value={parentScrollNode}>
      <EditorContentContainer data-aid="slate-editor-container" ref={scrollerRef}>
        {!excludeAnswerMenuButton ? <AnswerMenuButton /> : null}
        <EditorScrollContainer data-aid="slate-scroll-container" ref={node => setParentScrollNode(node)}>
          {children}
          {includeAttachments ? <Attachments scrollerRef={scrollerRef} /> : null}
        </EditorScrollContainer>
      </EditorContentContainer>
    </EditorScrollContext.Provider>
  );
}

EditorContainer.propTypes = {
  children: PropTypes.node,
  excludeAnswerMenuButton: PropTypes.bool,
  includeAttachments: PropTypes.bool,
};

const AnswerPanelIconWrapper = styled.div`
  cursor: pointer;
  padding-top: 3px;
`;

const ToggleAnswerPanelMessage = styled.span`
  display: inline-block;
`;

const Hotkey = styled.span`
  color: #999;
  padding-left: 8px;
`;
const message = (
  <ToggleAnswerPanelMessage>
    Answer Panel
    <Hotkey>Ctrl-/</Hotkey>
  </ToggleAnswerPanelMessage>
);

export const AnswerMenuButton = React.memo(function AnswerMenuButtonBase() {
  const { customerId, compositionId, latestConversationId } = useContext(CustomerContext);
  const executeAction = useExecuteAction();
  const toggleAnswerPanel = useCallback(() => {
    analytics.track('Composer Answer Panel Button Clicked', {
      customerId,
      compositionId,
      conversationId: latestConversationId,
    });
    executeAction(ToggleAnswerPanel, {
      customerId,
      compositionId,
      conversationId: latestConversationId,
    });
  }, [executeAction, customerId, compositionId, latestConversationId]);

  return (
    <Tooltip className="answerPanelToggleButton" delay={500} message={message} position="bottom">
      <AnswerPanelIconWrapper onClick={toggleAnswerPanel}>
        <AnswerPanelIcon className="answerPanelIcon-button" />
      </AnswerPanelIconWrapper>
    </Tooltip>
  );
});
