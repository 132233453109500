import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import Err from 'models/err';
import NameHints from './lib/name_hints';
import PasswordField from './lib/password_field';
import PasswordHints from './lib/password_hints';
import StackContainer from 'components/common/containers/stack_container';
import UserAuthLayout from './lib/user_auth_layout';
import UserButton, { UserActionButton } from 'components/user/lib/user_button';

const UserActivation = createReactClass({
  propTypes: {
    currentAgentId: PropTypes.string.isRequired,
    isResponsePending: PropTypes.bool.isRequired,
    isUserAuthorized: PropTypes.bool.isRequired,
    nameErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    passwordErrors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,

    onActivate: PropTypes.func.isRequired,
    onAttributesChange: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      name: '',
      password: '',
      userActivated: false,
    };
  },

  render() {
    if (this.props.isUserAuthorized) {
      if (this.props.isResponsePending) {
        return this.renderLoadingState();
      } else if (this.state.userActivated) {
        return this.renderUserActivated();
      }
      return this.renderActivationForm();
    }
    return this.renderUserUnauthorized();
  },

  renderLoadingState() {
    return <UserAuthLayout message="We are setting up your account" title="One moment" />;
  },

  renderUserUnauthorized() {
    return (
      <UserAuthLayout
        className="userActivation-layout userActivation-failure"
        message={
          'Your activation code is invalid or has expired.\nPlease contact your System Administrator to request a new activation code.'
        }
        title="Account Activation Failed"
      >
        {this.renderContinueButton()}
      </UserAuthLayout>
    );
  },

  renderUserActivated() {
    return (
      <UserAuthLayout
        className="userActivation-success"
        displayLogo
        image={<div className="userActivation-image" />}
        message="'Continue' and log into Gladly using your credentials."
        title="Account Activated"
      >
        {this.renderContinueButton()}
      </UserAuthLayout>
    );
  },

  renderContinueButton() {
    return (
      <div className="userAuthLayout-proceedLinkContainer">
        <div className="form-group">
          <UserButton
            className="form-control btn userAuthLayout-button btn-default userAuthLayout-button-navigateToLogin"
            onClick={this.handleContinueClick}
          >
            Continue
          </UserButton>
        </div>
      </div>
    );
  },

  renderActivationForm() {
    return (
      <UserAuthLayout
        className="userActivation-layout"
        displayLogo
        image={<div className="userActivation-image" />}
        title="Account Activation"
      >
        <div className="userAuthLayout-formContainer userActivation-formContainer">
          <form className="login-form" onSubmit={this.activateUser}>
            <div className="userAuthLayout-formElementsContainer">
              <div className="userAuthLayout-formElements">
                <div className="userActivation-message">Enter your name and create a password</div>
                <div>
                  <input
                    autoFocus
                    className="userAuthLayout-form-input name-control userAuthLayout-activation-name"
                    onChange={this.onNameChange}
                    placeholder="Full Name"
                  />
                </div>
                <div className="userAuthLayout-passwordContainer">
                  <PasswordField
                    className="userAuthLayout-form-input userAuthLayout-passwordInput"
                    onChange={this.onPasswordChange}
                    onSubmit={this.activateUser}
                    value={this.state.password}
                  />
                </div>
                <div className="form-group">{this.renderCompleteActivationButton()}</div>
              </div>
              <div>
                <StackContainer inset="none" stack="medium">
                  <NameHints errors={this.props.nameErrors} name={this.state.name} />
                </StackContainer>
                <StackContainer inset="none" stack="none">
                  <PasswordHints errors={this.props.passwordErrors} password={this.state.password} />
                </StackContainer>
              </div>
            </div>
          </form>
        </div>
      </UserAuthLayout>
    );
  },

  hasValidUserAttributes() {
    return (
      this.state.name &&
      this.state.password &&
      this.props.nameErrors.length === 0 &&
      this.props.passwordErrors.length === 0
    );
  },

  activateUser(ev) {
    ev.preventDefault();
    if (this.hasValidUserAttributes()) {
      this.props.onActivate({
        id: this.props.currentAgentId,
        name: this.state.name,
        password: this.state.password,
      });
      this.setState({ userActivated: true });
    }
  },

  handleContinueClick(ev) {
    ev.preventDefault();
    this.setState({ userActivated: false });
    this.props.onContinue();
  },

  renderCompleteActivationButton() {
    return (
      <UserActionButton
        className="form-control userAuthLayout-form-button userAuthLayout-form-completeActivation"
        disabled={!this.hasValidUserAttributes()}
        onClick={this.activateUser}
      >
        Complete Activation
      </UserActionButton>
    );
  },

  onPasswordChange(password) {
    this.setState({ password, userActivated: false });
    this.props.onAttributesChange({ name: this.state.name, password });
  },

  onNameChange(ev) {
    let name = ev.target.value;
    this.setState({ name, userActivated: false });
    this.props.onAttributesChange({ name, password: this.state.password });
  },
});

export default UserActivation;
