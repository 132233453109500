import createModel, { prop } from 'models/lib/create_model';

export default function(notificationType, modelDef) {
  return createModel({
    properties: {
      id: prop(String).isRequired,
      message: prop(String),
    },
    overrideToJs(toJs) {
      return () => ({ ...toJs(), type: notificationType });
    },
    ...modelDef,
  });
}

export { prop };
