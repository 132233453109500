import Reports from 'models/location/reports';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class ApplyFilters {
  constructor(context) {
    this.context = context;
  }

  run(reportParams) {
    const oldReport = this.context.stores.currentLocation.get();
    const { startAt, endAt } = reportParams;

    if (
      !Reports.isDateRangeValid({
        startDate: startAt,
        endDate: endAt,
      })
    ) {
      this.context.executeAction(ShowToast, {
        type: ToastType.ERROR,
        message: 'Sorry, we are unable to run this report. Please select less than 731 days and try again.',
      });
      return;
    }

    const newReport = oldReport.deriveNew(reportParams);
    this.context.router.navigateTo(newReport);
  }
}
