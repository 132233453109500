import _ from 'lodash';
import { isDevelopment } from 'scripts/application/lib/environment';

import prop from './prop';

import ErrorReporter from '../../../infrastructure/error_reporter';

/** Create a Model Object
 *
 * Model Objects have the following behaviors:
 *
 * - Define the set of properties they support, and their types, using the
 *   `properties` key with an object with property names as keys, and types
 *   as values
 *
 *   Example:
 *
 *   ```javascript
 *   var Person = createModel({
 *     modelName: 'Person',
 *     properties: {
 *       id: String,          // `id` is a `String`
 *       friends: [Person]    // `friends` is an array of `Person` instances
 *       profile: {           // `profile` is an object:
 *         name: String,      //    with a `name` `String`
 *         birthday: Date     //    and a `birthday` `Date`
 *       }
 *     }
 *   });
 *   ```
 *
 *   Note: You can also use the `prop` method to define the property type:
 *
 *   - `name: prop(String)`
 *   - `name: prop.type(String)`
 *
 * - Specify default values for properties in the 'properties' key by using
 *   the `prop` method with the `default` option. The `default` option can
 *   take a literal value or a function that returns the desired default.
 *   Note that literal defaults are only supported for built-in types, such
 *   as primitive types, arrays, and plain objects. To provide a default value
 *   for a property of another Model type you'll need to use a function.
 *
 *   Example:
 *
 *   ```javascript
 *   var prop = createModel.prop;
 *   var Person = createModel({
 *     modelName: 'Person',
 *     properties: {
 *       name: prop(String).default(''),       // `name` is defaulted to ''
 *       friends: prop([Person]).default([]),  // `friends` is defaulted to []
 *       createdAt: prop(String).default(() =>
 *          new Date().toISOString()),         // `createdAt` is defaulted to ISO representation of "now"
 *     }
 *   });
 *   ```
 *
 *   Note: the following syntaxes also work for defining defaults with the
 *   `prop` method:
 *
 *   - `name: prop(String).default('')`
 *   - `name: prop.type(String).default('')`
 *   - `name: prop(String, { default: '' })`
 *
 * - Specify required properties in the 'properties' key by using the `prop`
 *   method with the `required` option
 *
 *   Example:
 *
 *   ```javascript
 *   var prop = createModel.prop;
 *   var Person = createModel({
 *     modelName: 'Person',
 *     properties: {
 *       name: prop(String).isRequired,       // `name` is required
 *     }
 *   });
 *   ```
 *
 *   Note: the following syntaxes also work for defining required properties
 *
 *   - `name: prop(String).isRequired`
 *   - `name: prop.type(String).isRequired`
 *   - `name: prop(String, { required: true })`
 *
 * - Property definitions are exposed on the Model class via the `properties`
 *   attribute
 *
 *   Example:
 *
 *   ```javascript
 *   var Person = createModel({ properties: { name: String} });
 *   _.keys(Person.properties); // => ['name']
 *   ```
 *
 * - Properties can only be mutated by providing an explicit mutate method,
 *   ex: setName(updatedName)
 *
 * - Static methods may be defined within a `statics: {}` block
 *
 * - Model instances come with `toJs` method that recursively converts the model
 *   graph to a plain JS object.
 *
 *   The default behavior of `toJs` can be overridden by defining a custom function
 *   factory via the `overrideToJs` attribute. The factory receives the original
 *   `toJs` function as its single argument and must return a new `toJs` function.
 *
 *   Example:
 *
 *   ```javascript
 *   var Person = createModel({
 *      properties: { name: String },
 *      overrideToJs: function (originalToJs) {
 *        return function () { return { ...originalToJs(), type: 'FRIEND' }; }
 *      },
 *   });
 *   ```
 *
 * - Model classes come with `fromJs` method that recursively instantiates the model
 *   with dependencies form a plain JavaScript object.
 *
 *   The default behavior of `fromJs` method can be overridden by defining a custom
 *   function via the `overrideFromJs` factory defined as a `static` attribute. The
 *   factory receives the original `fromJs` method as its single argument and must
 *   return a new `fromJs` function.
 *
 *   Example:
 *
 *   ```javascript
 *   var Person = createModel({
 *     properties: { name: String, markup: String },
 *     static: {
 *       overrideFromJs(originalFromJs) {
 *          return ({ name }) => new Person({ ...originalFromJs({ name }), markup: `**${name}**` });
 *       };
 *     },
 *   });
 *   ```
 *
 *   The behavior of `fromJs` can be overridden on per attribute basis as well, which
 *   can be helpful for dealing with polymorphic properties.
 *
 *   Example:
 *
 *   ```javascript
 *   var Message = createModel({
 *      properties: {
 *        content: prop({}).fromJs((attrs) => {
 *          switch(attrs.type) {
 *          case 'EMAIL':
 *            return new EmailContent(attrs);
 *          case 'SMS':
 *            return new SmsContent(attrs);
 *          default:
 *            return new TextContent(attrs);
 *          }
 *        }),
 *        timestamp: String,
 *      },
 *   });
 *   ```
 */

export default function createModel(options) {
  const devModeOverride = options.isDevelopment; // This is used primarily for testing
  const devMode = devModeOverride !== undefined ? devModeOverride : isDevelopment();

  const modelName = options.modelName;
  if (!modelName) {
    throw new Error('modelName option required');
  }

  let staticMethods = options.statics || {};
  let methods = _.omit(options, ['modelName', 'properties', 'statics']);

  // handle the properties using `prop` since options.properties uses the same
  // `prop` definition api as any of the sub-properties can)
  let modelProperties = prop(options.properties)
    .default({})
    .build();
  // expose the property definitions on the class
  staticMethods.properties = modelProperties.attributeProperties;

  // Inner class representing a Model
  const PrivateModelClass = createNamedFunction(`Private${modelName}`, function(attrs) {
    attrs = modelProperties.default(attrs);
    attrs = modelProperties.pick(attrs);
    if (devMode) {
      try {
        modelProperties.validate(attrs);
      } catch (e) {
        e.message = `${e.message} for [${modelName}]`;
        throw e;
      }
    }

    // define validating setters for each of the properties
    devMode
      ? createValidatingPropertyAccessors(this, attrs || {})
      : createBasicPropertyAccessorsAndSetters(this, attrs || {});
  });

  _.extend(PrivateModelClass.prototype, methods);
  _.extend(PrivateModelClass, staticMethods);

  // Wraps a Model class providing Read-only access to the properties.
  // Mutation can be via defined methods
  const ModelClass = createNamedFunction(modelName, function() {
    let _private = Object.create(PrivateModelClass.prototype);
    PrivateModelClass.apply(_private, arguments);

    // Allow access to the private implementation internally
    Object.defineProperty(this, '_private', {
      value: _private,
      enumerable: false,
    });

    // Expose defined properties for read access only
    devMode ? createReadOnlyPropertyAccessors(this, _private) : createBasicPropertyAccessors(this, _private);
  });

  defineFromJs(ModelClass);
  defineToJs(ModelClass);

  // Expose static methods
  _(staticMethods)
    .omit('overrideFromJs')
    .forIn((method, methodName) => {
      ModelClass[methodName] = PrivateModelClass[methodName];
    });

  // Expose non-static methods
  _(methods)
    .omit('overrideToJs')
    .forIn((method, methodName) => {
      ModelClass.prototype[methodName] = function() {
        return this._private[methodName].apply(this._private, arguments);
      };
    });

  return ModelClass;

  // define accessors for each of the properties based on the spec returned
  // from the `callback(propertyName, propertyDefinition)` function
  function createPropertyAccessors(instance, callback) {
    _.forOwn(ModelClass.properties, (property, propertyName) => {
      Object.defineProperty(instance, propertyName, _.merge({ enumerable: true }, callback(propertyName, property)));
    });
  }

  function createBasicPropertyAccessors(instance, propertyValues) {
    createPropertyAccessors(instance, propertyName => {
      return {
        get() {
          return propertyValues[propertyName];
        },
      };
    });
  }
  function createBasicPropertyAccessorsAndSetters(instance, propertyValues) {
    createPropertyAccessors(instance, propertyName => {
      return {
        get() {
          return propertyValues[propertyName];
        },
        set(value) {
          return (propertyValues[propertyName] = value);
        },
      };
    });
  }

  function createReadOnlyPropertyAccessors(instance, propertyValues) {
    createPropertyAccessors(instance, propertyName => {
      return {
        get() {
          let ret = propertyValues[propertyName];
          if (_.isPlainObject(ret) || _.isArray(ret)) {
            ret = Object.freeze(_.clone(ret));
          }
          return ret;
        },
      };
    });
  }

  function createValidatingPropertyAccessors(instance, propertyValues) {
    createPropertyAccessors(instance, (propertyName, propertyDefinition) => {
      return {
        set(value) {
          try {
            propertyDefinition.validate(value);
          } catch (e) {
            e.message = `${e.message} for [${modelName}]`;
            throw e;
          }
          return (propertyValues[propertyName] = value);
        },
        get() {
          return propertyValues[propertyName];
        },
      };
    });
  }

  function defineFromJs(Model) {
    let props = modelProperties.attributeProperties;
    Model.fromJs = function(attrs) {
      try {
        return new this(
          _.reduce(attrs, (memo, v, k) => ({ ...memo, [k]: props[k] ? props[k].valueFromJs(v) : v }), {})
        );
      } catch (err) {
        ErrorReporter.reportError(err, { extra: { attrs } });
        throw err;
      }
    };

    if (_.isFunction(staticMethods.overrideFromJs)) {
      Model.fromJs = staticMethods.overrideFromJs.call(Model, Model.fromJs.bind(Model));
    }
  }

  function defineToJs(Model) {
    Model.prototype.toJs = function() {
      return asJs(_.toPlainObject(this._private));
    };

    if (_.isFunction(methods.overrideToJs)) {
      let originalToJs = Model.prototype.toJs;
      Model.prototype.toJs = function() {
        return methods.overrideToJs.call(this, originalToJs.bind(this)).apply(this, arguments);
      };
    }
  }
}

// expose the property definition api
export { prop };
createModel.prop = prop;

// http://stackoverflow.com/a/22880379
function createNamedFunction(name, fn) {
  // eslint-disable-next-line
  return new Function(`return function (call) { return function ${name} () { return call(this, arguments) }; };`)()(
    Function.apply.bind(fn)
  );
}

function asJs(value) {
  if (value && _.isFunction(value.toJs)) {
    return value.toJs();
  } else if (_.isPlainObject(value)) {
    return _.chain(value)
      .omitBy(v => _.isUndefined(v) || _.isFunction(v))
      .reduce((memo, v, k) => ({ ...memo, [k]: asJs(v) }), {})
      .value();
  } else if (_.isArray(value)) {
    return value.map(asJs);
  }
  return value;
}
