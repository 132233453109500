import Auth from 'models/auth';

export const STORAGE_KEY = 'auth';

export default class AuthStore {
  constructor(localStorage) {
    this.localStorage = localStorage;
  }

  // replaces the contents of the store
  set(auth, { temporary } = {}) {
    this.auth = auth;
    if (!temporary) {
      this.store(auth);
    }
  }

  // returns the entity from the store
  get() {
    return this.auth || this.load();
  }

  load() {
    let json = this.localStorage.getItem(STORAGE_KEY);
    return Auth.fromJs(json ? JSON.parse(json) : {});
  }

  store(auth) {
    this.localStorage.setItem(STORAGE_KEY, JSON.stringify(auth.toJs()));
  }

  remove() {
    this.auth = null;
    this.localStorage.removeItem(STORAGE_KEY);
  }

  getProvider() {
    return { get: this.get.bind(this) };
  }
}
