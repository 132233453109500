import React from 'react';
import PropTypes from 'prop-types';

import KeypadButton from './keypad_button';

const KeypadLayout = ({ onClick }) => {
  return (
    <div className="keypad-layout">
      {KeyPadLayoutButtons.map(button => (
        <div
          className={`keypad-layout-buttonContainer keypad-layout-buttonContainer-${button.symbol}`}
          key={`keypad-layout-buttonContainer-${button.symbol}`}
        >
          <KeypadButton {...button} onClick={onClick} />
        </div>
      ))}
    </div>
  );
};

export const KeyPadLayoutButtons = Object.freeze([
  { symbol: '1', name: '1', alias: '_' },
  { symbol: '2', name: '2', alias: 'ABC' },
  { symbol: '3', name: '3', alias: 'DEF' },
  { symbol: '4', name: '4', alias: 'GHI' },
  { symbol: '5', name: '5', alias: 'JKL' },
  { symbol: '6', name: '6', alias: 'MNO' },
  { symbol: '7', name: '7', alias: 'PQRS' },
  { symbol: '8', name: '8', alias: 'TUV' },
  { symbol: '9', name: '9', alias: 'WXYZ' },
  { symbol: '*', name: '*' },
  { symbol: '0', name: '0', alias: '+' },
  { symbol: '#', name: 'pound', alias: '_' },
]);

KeypadLayout.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default KeypadLayout;
