import React, { useCallback, useRef, useEffect } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

function useClickOutside(ref, onClickOutside) {
  const clickCapturedRef = useRef(false);
  useEffect(() => {
    function handleClickOutside(evt) {
      if (!clickCapturedRef.current && ref.current && !ref.current.contains(evt.target)) {
        onClickOutside && onClickOutside(evt);
      }
      clickCapturedRef.current = false;
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, clickCapturedRef, onClickOutside]);

  return useCallback(() => {
    clickCapturedRef.current = true;
  }, []);
}

function OutsideClickHandler({ children, onClickOutside = noop, disableOnClickOutside = false, innerRef }) {
  const ref = useRef(null);
  const actualRef = innerRef || ref;
  const captureInnerClick = useClickOutside(actualRef, onClickOutside);

  if (innerRef || disableOnClickOutside) {
    return children;
  }

  return (
    <div onMouseDown={captureInnerClick} onTouchStart={captureInnerClick} ref={actualRef}>
      {children}
    </div>
  );
}

OutsideClickHandler.propTypes = {
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  onClickOutside: PropTypes.func,
  disableOnClickOutside: PropTypes.bool,
};

export default OutsideClickHandler;
