import _ from 'lodash';

// Returns the first (alphabetically by name) of the agent's routing groups or
// all groups, if the agent does not belong to any
export function getDefaultRoutingGroupIdForAgent(context) {
  let allGroups = context.stores.routingGroups.findAll();
  let enabledGroups = _.filter(allGroups, group => !group.disabled);
  let orderedGroups = _.orderBy(enabledGroups, ['name'], ['asc']);

  let defaultGroup = context.stores.currentAgent.get().getDefaultRoutingGroup(orderedGroups);
  return defaultGroup && defaultGroup.id;
}

export default {
  getDefaultRoutingGroupIdForAgent,
};
