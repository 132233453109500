import _ from 'lodash';

import DocumentStoreSaveResultHandler from '../lib/document_store_save_result_handler';
import DtoConverter from 'scripts/application/dto_converters/agent_read_converter';
import mixin from 'scripts/lib/mixin';

export default class AgentReadGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchSuccess(agentReadDto) {
    const newRead = DtoConverter.fromDto(agentReadDto);

    const customerId = this.findCustomerForConversationId(newRead.conversationId);
    if (customerId) {
      this.context.stores.customers.storesFor(customerId).agentRead.resetLoading();
      this.context.stores.customers.storesFor(customerId).agentRead.set(newRead);
    }
    this.updateActiveSession(newRead);
  }

  onFetchError(errorDto) {
    throw new Error(`Failed to fetch agent read: ${JSON.stringify(errorDto)}`);
  }

  onBroadcast(agentReadDto) {
    const newRead = DtoConverter.fromDto(agentReadDto);

    const customerId = this.findCustomerForConversationId(newRead.conversationId);
    if (customerId) {
      const existingRead = this.context.stores.customers.storesFor(customerId).agentRead.get();
      if (!existingRead || new Date(existingRead.readTo) < new Date(newRead.readTo)) {
        this.context.stores.customers.storesFor(customerId).agentRead.set(newRead);
      }
    }
    this.updateActiveSession(newRead);
  }

  onReplaceSuccess() {}

  onReplaceError(errorDto) {
    throw new Error(`Failed to update agent read: ${JSON.stringify(errorDto)}`);
  }

  _isConversationForCurrentCustomer(conversationId) {
    const customerId = _.get(this.context.stores.currentLocation.get(), 'customerId');
    if (!customerId || !this.context.stores.customers.has({ id: customerId })) {
      return false;
    }

    const conversation = this.context.stores.customers
      .storesFor(customerId)
      .conversations.findBy({ id: conversationId });
    if (!conversation) {
      return false;
    }

    return true;
  }

  findCustomerForConversationId(conversationId) {
    let foundCustomerId;
    this.context.stores.customers.findAll({ select: ['id'] }).map(customer => {
      const conversation = this.context.stores.customers
        .storesFor(customer.id)
        .conversations.findBy({ id: conversationId });
      if (conversation) {
        foundCustomerId = customer.id;
      }
    });
    return foundCustomerId;
  }

  updateActiveSession(agentRead) {
    const activeSession = this.context.stores.activeSessions.findBy({
      queueItem: { conversationId: agentRead.conversationId },
    });
    if (activeSession) {
      activeSession.update({ readTo: agentRead.readTo });
      this.context.stores.activeSessions.replace(activeSession);
    }
  }
}

mixin(AgentReadGatewayObserver.prototype, DocumentStoreSaveResultHandler.prototype);
