import _ from 'lodash';

import { CustomerStatus } from 'models/customer_profile';
import { getCustomerMatches } from './lib/get_customer_matches';

export default class VerifyNewCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationItemId, searchResultId }) {
    this.context.gateways.customerProfile.update({ customerId }, { status: CustomerStatus.VERIFIED });

    this.context.analytics.track('Customer Match - New Customer Selected', {
      conversationItemId,
      itemCount: _(getCustomerMatches(this.context, { customerId, searchResultId })).get('length'),
    });
  }
}
