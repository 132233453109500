import _ from 'lodash';
import { insertNode } from '@udecode/plate';
import { ReactEditor } from 'slate-react';
import { Editor, Text, Transforms } from 'slate';

import { AUTOCOMPLETE } from './autocomplete_constants';
import ensureCursorIsAtStart from 'components/text_editor_new/lib/ensure_cursor_is_at_start';
import { editorHasNoText } from 'components/text_editor_new/lib/editor_has_no_text';

export function ensureAutocompleteExists(context, editor) {
  const existingAutocompletes = editor.getAutocompleteNodes();
  const suggestion = context.getGreetingSuggestion();

  if (existingAutocompletes.length > 0) {
    // If we're currently typing in the autocomplete manually, don't remove.
    if (existingAutocompletes[0][0].isPartial) {
      return;
    }
    if (!suggestion || !suggestion.text || editorHasNonAutocompleteNodes(editor)) {
      editor.removeAutocomplete();
      return;
    } else if (suggestion.text !== existingAutocompletes[0][0]['data-text']) {
      const fragment = getFragment(editor, suggestion.text);
      Transforms.setNodes(
        editor,
        {
          'data-fragment': fragment,
          'data-text': suggestion.text,
          'data-id': suggestion.id,
        },
        {
          at: existingAutocompletes[0][1],
        }
      );
    }

    if (existingAutocompletes.length > 1) {
      _.forEach(existingAutocompletes.slice(1), ([, path]) => {
        Transforms.removeNodes(editor, {
          at: path,
          match: n => {
            return n.type === AUTOCOMPLETE;
          },
        });
      });
    }

    ensureCursorIsAtStart(editor);
    return;
  }

  if (!editorHasNoText(editor)) {
    return;
  }

  if (!suggestion || !suggestion.text) {
    return;
  }

  // Insert the autocompletion. Note that autocompletions are rendered as void nodes, so they aren't
  // editable and aren't really a part of the editor content. This is great because it means that
  // Slate won't even try to serialize it by default.

  // TODO: should greeting suggestion text actually be a fragment? How render this?
  const fragment = getFragment(editor, suggestion.text);
  insertNode(editor, {
    children: [
      {
        text: '',
      },
    ],
    'data-fragment': fragment,
    'data-text': suggestion.text,
    'data-id': suggestion.id,
    type: AUTOCOMPLETE,
  });

  ReactEditor.focus(editor);
}

function getFragment(editor, text) {
  const lines = text.split('\n');
  const paragraphs = _.map(lines, line => `<div>${line}</div>`);

  return editor.getSaturatedFragment(paragraphs.join(''));
}

export function removeAutocomplete(ref, editor) {
  // We only should ever have one autocomplete, but handle the unhappy path just in case
  const existingAutocompletes = editor.getAutocompleteNodes();
  if (existingAutocompletes.length > 0) {
    // Set this flag so that we don't immediately re-add the suggestion after removing it
    ref.current.isRemoving = true;

    Transforms.removeNodes(editor, {
      at: [],
      match: n => {
        return n.type === AUTOCOMPLETE;
      },
      voids: true,
    });
  }
}

function editorHasNonAutocompleteNodes(editor) {
  const nodeGenerator = Editor.nodes(editor, {
    at: [],
    match: n => n.type !== AUTOCOMPLETE && Text.isText(n) && n.text !== '',
  });

  // Are we at the end of the sequence already?
  const { done } = nodeGenerator.next();
  return !done;
}
