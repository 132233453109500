import _ from 'lodash';

import createModel from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

var prop = createModel.prop;

/**
 * A model with a minimal set of agent profile attributes necessary for making sense of
 * `Conversations`, assignments, and other models where `agentId` is present.
 *
 * This model should be treated as read-only at all times.
 */
export default createModel({
  modelName: 'Agent',

  properties: {
    id: prop(String).isRequired,
    name: String,
    avatarUrl: String,
    email: String,
    twitter: String,
    disabledAt: String,
  },

  isDisabled() {
    return !!this.disabledAt;
  },

  getDisplayName() {
    return this.name && this.name.length ? this.name : this.email;
  },

  statics: {
    create(attrs) {
      return new this(_.assign({ id: IdGenerator.newId() }, attrs));
    },
  },
});
