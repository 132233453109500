import React, { useContext, useMemo } from 'react';

import connect from 'components/lib/connect';
import CustomerContext from 'components/customer/customer_context';
import UseAISuggestedReply from 'actions/ai_suggested_replies/use_ai_suggested_reply';
import useExecuteAction from 'components/hooks/use_execute_action';

export const DEFAULT_DEMO_SUGGESTED_REPLY_CONTEXT = {
  getSuggestedReply: () => null,
};
const DemoSuggestedReplyContext = React.createContext(DEFAULT_DEMO_SUGGESTED_REPLY_CONTEXT);

const DemoSuggestedReplyContextProvider = connect(mapStateToProps)(DemoSuggestedReplyContextProviderBase);

function mapStateToProps({ getProvider }) {
  const demoSuggestedReplyProvider = getProvider('aiSuggestedReply');
  const reply = demoSuggestedReplyProvider.get();

  return {
    demoSuggestedReplyProvider,
    suggestedReply: reply && !reply.usedAt ? reply : null,
  };
}

function DemoSuggestedReplyContextProviderBase({ children, demoSuggestedReplyProvider, suggestedReply }) {
  const executeAction = useExecuteAction();
  const { customerId } = useContext(CustomerContext);

  const value = useMemo(() => {
    return {
      suggestedReply,
      getSuggestedReply: () => demoSuggestedReplyProvider.get(),
      useSuggestedReply: () => executeAction(UseAISuggestedReply, { customerId }),
    };
  }, [customerId, demoSuggestedReplyProvider, suggestedReply]);

  return <DemoSuggestedReplyContext.Provider value={value}>{children}</DemoSuggestedReplyContext.Provider>;
}

export { DemoSuggestedReplyContext };
export default DemoSuggestedReplyContextProvider;
