import createModel, { prop } from './lib/create_model';
import { CustomAttributeValue } from 'models/custom_attribute';

const ConversationCustomAttributeChange = createModel({
  modelName: 'ConversationCustomAttributeChange',

  properties: {
    added: prop([CustomAttributeValue]).default([]),
    removed: prop([CustomAttributeValue]).default([]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ConversationCustomAttributeChange;
