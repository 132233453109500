import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';
import SmsMessageAttachment from './sms_message_attachment';
import redactAttachment from './attachment_redact';

const MAX_SMS_MESSAGE_LENGTH = 1600;

export const baseSmsMessageModel = (modelName, customProperties, customStatics) =>
  createModel({
    modelName,

    properties: {
      ...(customProperties || {}),
      attachments: [SmsMessageAttachment],
      from: String,
      to: String,
      body: String,
      status: prop({
        value: String,
        errorCode: String,
      }),
      hasRedactedPaymentCardNumber: Boolean,
      sessionId: String,
      isRedacted: Boolean,
    },

    redactAttachment(id) {
      redactAttachment(this.attachments, id);
    },

    getErrorMessage() {
      return this.status.errorCode ? ErrorCodeMessageMap[this.status.errorCode] : '';
    },

    getErrorMessageHelpDocHref() {
      return this.status.errorCode ? ErrorCodeHelpDocHrefMap[this.status.errorCode] : '';
    },

    getMessageText() {
      return this.body;
    },

    getStatus() {
      return (this.status && this.status.value) || '';
    },

    hasErrorStatus() {
      return hasErrorStatus(this.getStatus());
    },

    statics: {
      ...(customStatics || {}),

      create(rawAttrs) {
        const attrs = _.merge({ attachments: [] }, rawAttrs, {
          from: this._normalizeNumber(rawAttrs.from),
          to: this._normalizeNumber(rawAttrs.to),
        });
        return new this(attrs);
      },

      _normalizeNumber(number) {
        return number && number.replace(/[^\d+]/g, ''); // keeps only numbers and the plus sign
      },
    },
  });

const SmsMessage = baseSmsMessageModel('SmsMessage', {}, { MAX_MESSAGE_LENGTH: MAX_SMS_MESSAGE_LENGTH });

export function hasErrorStatus(status) {
  return [STATUS.FAILED, STATUS.UNDELIVERED].indexOf(status) >= 0;
}

export default SmsMessage;

export const STATUS = createEnum('NEW', 'SENDING', 'SENT', 'DELIVERED', 'UNDELIVERED', 'FAILED', 'UNKNOWN', 'RECEIVED');

// SMS error codes
export const ERROR_CODE = createEnum(
  'INVALID_TO_NUMBER',
  'MEDIA_SIZE_TOO_LARGE',
  'MISSING_TO_NUMBER',
  'MESSAGE_BODY_TOO_LONG',
  'MMS_NOT_ENABLED',
  'SEND_RATE_OVER_LIMIT',
  'SMS_ACCOUNT_SUSPENDED',
  'SMS_HANDSET_UNAVAILABLE',
  'SMS_NO_ACTIVE_CONVERSATION',
  'SMS_NUMBER_BLOCKED',
  'SMS_NUMBER_FORBIDDEN',
  'SMS_NUMBER_INVALID',
  'SMS_HANDSET_UNREACHABLE',
  'SMS_MESSAGE_OBJECTIONABLE',
  'SMS_MESSAGE_PART_NOT_RECEIVED',
  'SMS_PRICE_EXCEEDED',
  'SMS_NUMBER_IS_NOT_A2P_10DLC_REGISTERED',
  'SMS_NUMBER_IS_PENDING_A2P_10DLC_REGISTRATION'
);

// ErrorCodeMessageMap maps error codes to a user-facing message
export const ErrorCodeMessageMap = {
  [ERROR_CODE.INVALID_TO_NUMBER]: "The 'To' number you have selected is invalid.",
  [ERROR_CODE.MEDIA_SIZE_TOO_LARGE]: 'The media size exceeds the mobile operator size limit.',
  [ERROR_CODE.MISSING_TO_NUMBER]: "No 'To' number specified.",
  [ERROR_CODE.MESSAGE_BODY_TOO_LONG]: 'The message body exceeded the maximum length of 1600 characters',
  [ERROR_CODE.MMS_NOT_ENABLED]: 'The phone number you are sending from does not support attachments.',
  [ERROR_CODE.SEND_RATE_OVER_LIMIT]:
    'You tried to send too many messages too quickly and your message queue overflowed. Try sending your message again after waiting some time.',
  [ERROR_CODE.SMS_ACCOUNT_SUSPENDED]: 'Your SMS account was suspended.',
  [ERROR_CODE.SMS_HANDSET_UNAVAILABLE]:
    'The destination handset you are trying to reach is switched off or otherwise unavailable.',
  [ERROR_CODE.SMS_NO_ACTIVE_CONVERSATION]: 'Could not send message because conversation was closed.',
  [ERROR_CODE.SMS_NUMBER_BLOCKED]: 'The destination number is blocked from receiving this message.',
  [ERROR_CODE.SMS_NUMBER_FORBIDDEN]:
    "The destination number has disabled receiving messages from this number and must text 'START' to this number to enable.",
  [ERROR_CODE.SMS_NUMBER_INVALID]: 'The destination number is not a valid mobile number.',
  [ERROR_CODE.SMS_HANDSET_UNREACHABLE]:
    'The destination number could not be reached. It may be a landline or may no longer exist.',
  [ERROR_CODE.SMS_MESSAGE_OBJECTIONABLE]:
    'We detected the contents of this message may violate carrier acceptable use policies.',
  [ERROR_CODE.SMS_MESSAGE_PART_NOT_RECEIVED]:
    'One or more segments associated with your multi-part inbound message was not received.',
  [ERROR_CODE.SMS_PRICE_EXCEEDED]: 'The price of your message exceeds the max price set.',
  [ERROR_CODE.SMS_NUMBER_IS_NOT_A2P_10DLC_REGISTERED]:
    'The number used is not A2P 10DLC registered. Please use an alternative form of communication.',
  [ERROR_CODE.SMS_NUMBER_IS_PENDING_A2P_10DLC_REGISTRATION]:
    'The number used is pending A2P 10DLC registration. Please use an alternative form of communication.',
};

const A2P10DLCRegistrationErrorUrl =
  'https://connect.gladly.com/docs/help-documentation/article/a2p-10DLC-registration-error';

// ErrorCodeHelpDocHrefMap maps error codes to corresponding help doc link
export const ErrorCodeHelpDocHrefMap = {
  [ERROR_CODE.SMS_MESSAGE_OBJECTIONABLE]: 'https://help.gladly.com/docs/sms-send-error',
  [ERROR_CODE.SMS_NUMBER_IS_NOT_A2P_10DLC_REGISTERED]: A2P10DLCRegistrationErrorUrl,
  [ERROR_CODE.SMS_NUMBER_IS_PENDING_A2P_10DLC_REGISTRATION]: A2P10DLCRegistrationErrorUrl,
};
