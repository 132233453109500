import React, { useCallback, useContext } from 'react';

import AddOrUpdatePhoneNumber from 'actions/customer_profile/add_or_update_phone_number';
import connect from 'components/lib/connect';
import CreateEmailComposition from 'actions/composition/create_email_composition';
import CreatePhoneCallComposition from 'actions/composition/create_phone_call_composition';
import CustomerContext from 'components/customer/customer_context';
import InlineAnnotatedContent from './inline_annotated_content';
import { isVoiceEnabled } from 'components/lib/voice/is_voice_enabled';
import UpdateEmailAddress from 'actions/customer_profile/update_email_address';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function InlineContentActions(props) {
  const { latestConversationId } = useContext(CustomerContext);
  const executeAction = useExecuteAction();

  const { profile } = props;

  const onCallPhone = useCallback(
    phoneNumber => executeAction(CreatePhoneCallComposition, { phoneNumber, conversationId: latestConversationId }),
    [executeAction, latestConversationId]
  );
  const onSavePhone = useCallback(
    phoneNumber => {
      if (profile && !profile.hasPhoneNumber(phoneNumber)) {
        executeAction(AddOrUpdatePhoneNumber, { phoneNumber, primary: true, source: 'save from conversation item' });
      }
    },
    [executeAction, profile]
  );

  const onSendEmail = useCallback(
    email => {
      executeAction(CreateEmailComposition, { conversationId: latestConversationId, toAddress: email });
    },
    [executeAction, latestConversationId]
  );
  const onSaveEmail = useCallback(
    email => {
      if (profile && !profile.hasEmail(email)) {
        executeAction(UpdateEmailAddress, { original: email, index: profile.emails.length });
      }
    },
    [executeAction, profile]
  );

  const actionProps = {
    onCallPhone,
    onSavePhone,
    onSendEmail,
    onSaveEmail,
  };

  return <InlineAnnotatedContent {...props} {...actionProps} />;
}

export default connect(mapStateToProps)(InlineContentActions);

function mapStateToProps(context, props) {
  const channelConfiguration = context.getProvider('channelConfiguration').get();

  return {
    ...props,
    canEmail: channelConfiguration && channelConfiguration.fromAddresses().length > 0,
    hasVoice: isVoiceEnabled(context), // cannot make outbound calls with CTI integration
    profile: context.getProvider('profile').get(),
    profileHasErrors: context.getProvider('profile').getErrors().length > 0,
    hasExternalAgentActions: context.isFeatureEnabled('externalAgentActions'),
  };
}
