import StandardGateway from './lib/standard_gateway';

export default class EmbeddedReportGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'reporting/v1/embedded-reports');
  }

  create(params, data) {
    return this.add(params, data);
  }
}
