import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'MessagingConfiguration',
  properties: {
    agentCapacity: prop(Number),
    agentMaxCapacity: prop(Number),
    assignMailToConversationAssignee: prop(Boolean),
    autoAssignMessagingSessions: prop(Boolean).default(false),
    enableFocusMode: prop(Boolean).default(false),
    endMailSessionIntervalMins: prop(Number),
    endMessagingSessionIntervalMins: prop(Number),
    lookupBeforeRouting: prop({
      enabled: prop(Boolean).default(false),
      timeoutSecs: prop(Number),
    }).default({ enabled: true, timeoutSecs: 15 }),
    multipleTwitterChannels: prop(Boolean).default(false),
    routeNextMessagingSessionIntervalSecs: prop(Number),
    showPreview: prop(Boolean).default(true),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
