import qconsole from 'scripts/lib/qconsole';
import SelectAnswer from './select_answer';

export default class FollowAnswerLink {
  constructor(context) {
    this.context = context;
  }

  run({ answerId }) {
    const answer = this.context.stores.snippets.findBy({ id: answerId });
    if (!answer) {
      qconsole.error(`Unable to find answer in store [${answerId}]`);
      return;
    }

    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.pushSelectedOntoHistory();
    this.context.stores.answerPanel.set(answerPanel);

    const answerLanguage = answerPanel.selectedAnswerLanguage; // Try to preserve current language
    this.context.executeAction(SelectAnswer, { answer, answerLanguage });
  }
}
