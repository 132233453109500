import { isEqual, isObject, reduce } from 'lodash';

import AgentRoutingPreferences, { RoutingChannel } from 'models/agent_routing_preferences';
import { CAPACITY_MANAGER_STARTUP_DELAY_SECONDS } from 'actions/routing/capacity_manager';
import Err from 'models/err';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import qconsole from 'scripts/lib/qconsole';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import { DomainError, VersionOutdated } from 'scripts/application/lib/error';

export default class UpdateAgentRoutingPreferences {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorInteractiveHandler(this.context);
  }

  /**
   * Applies the given preference update to pending agent's routing preferences and schedules the backend
   * update via the gateway, that is, the update is debounced to
   *  - avoid ensure correct routing precedence if multiple channels are selected in a short amount of time
   *  - to avoid unnecessary backend thrashing if, for example, the user presses and holds a keyboard shortcut,
   *    thus, potentially triggering dozens if not hundreds of preference updates.
   *
   * @param preferenceUpdate - partial object of the full model that only contains updated properties in
   * the shape of `{ channels: RoutingChannel[], isFocusOn: boolean }`, where `channels` and `isFocusOn`
   * are mutually exclusive.
   */
  run(preferenceUpdate) {
    const current = this.store.get();

    let pending = this.store.getPending();
    if (pending) {
      const pendingUpdate = whatModified(pending, current);
      if (isDifferentPropertyUpdate(pendingUpdate, preferenceUpdate)) {
        this.updateBackendFromPending(); // send the pending changes to the backend before applying the new update.
        pending.incrementVersion();
      }
    } else {
      pending = current;
      pending.incrementVersion();
    }

    pending.updatePreferences(preferenceUpdate);
    this.store.setPending(pending);

    // Postpone capacity-based routing for channels affected by the change:
    // - when a channel gets selected it helps to avoid a race between the capacity manager
    //   and the routing triggered by the preference change.
    // - when a channel gets unselected it prevents a counter-intuitive UX
    //   of getting work routed on that channel while the unselecting is being handled and throttled.
    this.postponeRouting();

    // Debounce the update because an agent can click multiple preferences in a short amount of time
    this.debounceUpdate();

    this.recordAnalytics(preferenceUpdate);
  }

  /**
   * Postpones capacity-based routing for channels with a pending preference change.
   */
  postponeRouting() {
    if (this.hasPendingChangeFor(RoutingChannel.MESSAGING)) {
      this.context.capacityManager.postponeMessagingRouting(CAPACITY_MANAGER_STARTUP_DELAY_SECONDS);
    }

    if (this.hasPendingChangeFor(RoutingChannel.VOICE)) {
      this.context.capacityManager.postponeCallRouting(CAPACITY_MANAGER_STARTUP_DELAY_SECONDS);
    }
  }

  hasPendingChangeFor(channel) {
    return this.store.getPending()?.channels?.[channel] !== this.store.get()?.channels?.[channel];
  }

  debounceUpdate() {
    clearTimeout(this.constructor.throttleTimeout);
    this.constructor.throttleTimeout = setTimeout(() => this.updateBackendFromPending(), DEBOUNCE_DELAY);
  }

  recordAnalytics(preferenceUpdate) {
    const agentId = this.context.stores.currentAgent.get().id;

    if (preferenceUpdate.isFocusOn) {
      this.context.analytics.track('Focus Entered', {
        agentId,
      });
    } else if (preferenceUpdate.isFocusOn === false) {
      this.context.analytics.track('Focus Exited', {
        agentId,
      });
    }
  }

  updateBackendFromPending() {
    let pending = this.store.getPending();
    // Abort update if the pending state was reset during the debounce interval
    if (!pending) {
      return;
    }

    // Reset the pending state and abort the update if the pending state got
    // outdated during the debounce interval.
    if (pending._version <= this.store.get()._version) {
      this.store.resetPending();
      return;
    }

    const sentPreferences = pending.toJs();
    this.context.gateways.agentRoutingPreferences
      .set(sentPreferences)
      .then(() => this.handleSetSuccess(AgentRoutingPreferences.fromJs(sentPreferences)))
      .catch(err => this.handleSetError(err));
  }

  handleSetError(err) {
    this.store.resetPending();

    if (err instanceof VersionOutdated) {
      qconsole.log('Ignored version outdated from setting routing preferences. Will re-fetch the latest state.');
      this.context.gateways.agentRoutingPreferences.get();

      return;
    }

    if (
      err instanceof DomainError &&
      err.errors[0].code === Err.Code.INVALID_STATE &&
      err.errors[0].attr === 'status'
    ) {
      this.context.executeAction(ShowToast, {
        type: ToastType.INFO,
        message: "It appears you've been set to Away. Checking...",
      });

      this.context.gateways.agentStatus.get();

      return;
    }

    this.errorHandler.handleCommonErrors(err);
  }

  handleSetSuccess(sentPreferences) {
    if (sentPreferences._version > this.store.get()._version) {
      this.store.set(sentPreferences);
    }

    const pending = this.store.getPending();
    if (pending && pending._version > sentPreferences._version) {
      // return to keep the pending change if its version is greater than what was sent to the backend;
      // this means that there was an update for a different property, e.g. `isInFocus: false` was sent
      // to the backend and a channel preference change is currently pending.
      return;
    }

    this.store.resetPending();
  }

  get store() {
    return this.context.stores.agentRoutingPreferences;
  }
}

function isDifferentPropertyUpdate(p1, p2) {
  return !(('isFocusOn' in p1 && 'isFocusOn' in p2) || ('channels' in p1 && 'channels' in p2));
}

function whatModified(modified, original) {
  return reduce(
    modified,
    (result, value, key) => {
      if (!isEqual(value, original[key])) {
        result[key] = isObject(value) ? whatModified(value, original[key]) : value;
      }
      return result;
    },
    {}
  );
}

const DEBOUNCE_DELAY = 1500;
