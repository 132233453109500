import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class CompositionService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  add(compAttrs, callback, path, { orgId, agentId, customerId }) {
    const attrs = _.merge({}, compAttrs);
    const { id } = attrs;

    let compositions = this._getCompositions(orgId, agentId);
    let compositionIndex = this._findCompositionIndex(orgId, agentId, id);

    if (compositionIndex !== -1) {
      callback(null, { status: 400, statusText: statusText(400) });
      return;
    }

    compositions.push(attrs);

    callback(null, { status: 200, statusText: statusText(200) });
  }

  remove(compAttrs, callback, path, { orgId, agentId, customerId, compositionId }) {
    _.remove(this._getCompositions(orgId, agentId), composition => {
      return composition.id === compositionId;
    });

    callback(null, { status: 200, statusText: statusText(200) });
  }

  find(compAttrs, callback, path, { orgId, agentId, customerId }) {
    let compositions = this._getCompositions(orgId, agentId);

    callback(null, { status: 200, statusText: statusText(200), response: compositions });
  }

  update(compAttrs, callback, path, { orgId, agentId, customerId, compositionId }) {
    let compositions = this._getCompositions(orgId, agentId);
    let compositionIndex = this._findCompositionIndex(orgId, agentId, compositionId);

    if (compositionIndex === -1) {
      callback(null, { status: 400, statusText: statusText(400) });
      return;
    }

    compositions[compositionIndex] = compAttrs;

    callback(null, { status: 200, statusText: statusText(200) });
  }

  _findCompositionIndex(orgId, agentId, compositionId) {
    return _.findIndex(this._getCompositions(orgId, agentId), composition => {
      return composition.id === compositionId;
    });
  }

  _getCompositions(orgId, agentId) {
    let database = this.getDatabase(orgId);

    let agentCompositions = database.compositions[agentId];
    if (!agentCompositions) {
      database.compositions[agentId] = [];
    }
    return database.compositions[agentId];
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/agents/:agentId/customers/:customerId/compositions': {
          POST: this.add,
          GET: this.find,
        },
        '/api/v1/orgs/:orgId/agents/:agentId/customers/:customerId/compositions/:compositionId': {
          DELETE: this.remove,
          PUT: this.update,
        },
      },
      this
    );
  }
}
