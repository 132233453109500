import UserCredential from 'models/user_credential';
import UserCredentialType from 'models/user_credential_type';
import UserLogin from 'models/location/user_login';
import UserResetPassword from 'models/location/user_reset_password';

export default class BeginPasswordReset {
  constructor(context) {
    this.context = context;
  }

  run(token) {
    if (this.context.stores.appFeatures.get().isEnabled('sso')) {
      this.context.router.navigateHome();
      return;
    }

    let auth = this.context.stores.auth.get();
    if (auth.isLoggedIn()) {
      if (auth.isPasswordBeingReset()) {
        this.setCurrentLocation();
      } else {
        this.context.router.navigateHome();
      }
    } else if (token) {
      this.context.stores.userCredential.setPending(
        UserCredential.create({ type: UserCredentialType.RESET_PASSWORD_TOKEN })
      );
      this.context.gateways.authentication.login({ token });
      this.setCurrentLocation();
    } else {
      this.context.router.navigateTo(UserLogin.create());
    }
  }

  setCurrentLocation() {
    this.context.stores.currentLocation.set(UserResetPassword.create());
  }
}
