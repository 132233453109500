import _ from 'lodash';
import produce from 'immer';
import qs from 'qs';

import AgentInboxObserver from 'actions/inbox/agent/agent_inbox_observer';
import { AgentInboxPollId } from './agent_inbox_constants';
import { convertInboxDtos, filterRemovedItems, reconcileInboxItem } from 'actions/inbox/agent/agent_inbox_helpers';
import Endpoint from 'scripts/adapters/gateways/lib/standard_gateway/endpoint';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getPollingInterval from './get_polling_interval';
import qconsole from 'scripts/lib/qconsole';
import RequestAgentInbox from './request_agent_inbox';
import { setSubscription, getSubscription, isSubscribedToItem, removeSubscription } from './agent_inbox_subscriptions';
import trackIncomingInboxDifferences from 'actions/inbox/agent/track_incoming_inbox_differences';

const DEFAULT_TIMEOUT = 10000;
const SortOrder = 'SLA_OR_LATEST';

export const CONVERSATIONS_TOPIC = 'v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId';
export const ITEMS_TOPIC = 'v1/orgs/:orgId/customer-history/:customerId/conversation-items/:conversationItemId';
export const PROFILE_TOPIC = 'v1/orgs/:orgId/customer-profiles/:customerId';
export const PROFILE_MERGE_TOPIC = 'v1/orgs/:orgId/customer-profiles/:customerId/merges';

/**
 * Deprecated! Use `request_agent_inbox` instead.
 */
export default class RequestAgentInboxDeprecated {
  constructor(context) {
    this.context = context;
    this.observer = new AgentInboxObserver(context);
    this.backend = this.context.backend;

    const auth = this.context.stores.auth.get();
    this.orgId = auth.getOrgId();
  }

  run() {
    if (!this.context.stores.appFeatures.get().isEnabled('internalAgentActions')) {
      return;
    }

    if (this.context.stores.appFeatures.get().isEnabled('customerListAssignmentBroadcast')) {
      this.context.executeAction(RequestAgentInbox);
      return;
    }

    const meta = this.context.stores.agentInboxMeta.get();
    const agent = this.context.stores.currentAgent.get();
    if (!agent || !agent.id) return;

    const sort = SortOrder;
    const payload = { sort, conversationStatus: 'OPEN', limit: meta.limit, unified: true };

    if (!meta.lastRequestedAt) {
      this.context.stores.agentInboxItems.setLoading();
    }

    const gateway = this.context.gateways.agentInboxV5;

    const pollingInterval = getPollingInterval(this.context);
    const url = gateway.endpoint.set({ agentId: agent.id }).fetchUrl;
    gateway.http
      .get(requestUrl(url, payload), { timeout: DEFAULT_TIMEOUT })
      .then(dtos => {
        let inboxItems = convertInboxDtos(dtos.previews);
        this.subscribe(filterRemovedItems(this.context, inboxItems));
        unsubscribeFromRemovedItems(this.context, inboxItems);
        this.context.scheduler.executeEvery(pollingInterval, AgentInboxPollId, PollAgentInbox);
      })
      .catch(errorDto => {
        ErrorReporter.reportError(errorDto);
        throw errorDto;
      })
      .finally(() => {
        this.context.stores.agentInboxItems.resetLoading();
      });

    meta.setLastRequestedAtToNow();
    this.context.stores.agentInboxMeta.set(meta);
  }

  subscribe(inboxItems) {
    subscribeToInbox(this.context, this.backend, this.observer, this.orgId, inboxItems);
  }
}

export class PollAgentInbox {
  constructor(context) {
    this.context = context;
    this.observer = new AgentInboxObserver(context);
    this.backend = this.context.backend;

    const auth = this.context.stores.auth.get();
    this.orgId = auth.getOrgId();
  }

  run() {
    if (!this.context.stores.appFeatures.get().isEnabled('internalAgentActions')) {
      return;
    }

    const meta = this.context.stores.agentInboxMeta.get();
    const agent = this.context.stores.currentAgent.get();
    if (!agent || !agent.id) return;

    const sort = SortOrder;
    const payload = { sort, conversationStatus: 'OPEN', limit: meta.limit, unified: true };

    const gateway = this.context.gateways.agentInboxV5;

    const url = gateway.endpoint.set({ agentId: agent.id }).fetchUrl;

    const requestedAt = new Date();
    gateway.http
      .get(requestUrl(url, payload), { timeout: DEFAULT_TIMEOUT })
      .then(dtos => {
        const inboxItems = convertInboxDtos(dtos.previews);
        trackIncomingInboxDifferences(this.context, inboxItems, requestedAt);

        const filteredItems = filterRemovedItems(this.context, inboxItems);
        this.subscribe(filteredItems);
        unsubscribeFromRemovedItems(this.context, inboxItems);
      })
      .catch(errorDto => {
        // We'll retry again.. if we fail 3 times then we should set error loading and inform the agent that we
        // cannot load the inbox rn.
        qconsole.error(errorDto);
        throw errorDto;
      });
  }

  subscribe(inboxItems) {
    subscribeToInbox(this.context, this.backend, this.observer, this.orgId, inboxItems);
  }
}

export function subscribeToItem(backend, observer, orgId, inboxItem) {
  const conversationsEndpoint = new Endpoint(CONVERSATIONS_TOPIC).set({
    customerId: inboxItem.profile.id,
    orgId,
  });
  const conversationsHandler = observer.onBroadcastConversation.bind(observer, CONVERSATIONS_TOPIC);

  const itemsEndpoint = new Endpoint(ITEMS_TOPIC).set({
    customerId: inboxItem.profile.id,
    orgId,
  });
  const itemsHandler = observer.onBroadcastItem.bind(observer, ITEMS_TOPIC);

  const profileEndpoint = new Endpoint(PROFILE_TOPIC).set({
    customerId: inboxItem.profile.id,
    orgId,
  });
  const profileHandler = observer.onBroadcastProfile.bind(observer, PROFILE_TOPIC);

  const profileMergeEndpoint = new Endpoint(PROFILE_MERGE_TOPIC).set({
    customerId: inboxItem.profile.id,
    orgId,
  });
  const profileMergeHandler = observer.onBroadcastProfileMerge.bind(observer, PROFILE_MERGE_TOPIC);

  return Promise.all([
    subscribe(backend, inboxItem.id, conversationsEndpoint.collectionBroadcastTopic, conversationsHandler),
    subscribe(backend, inboxItem.id, itemsEndpoint.collectionBroadcastTopic, itemsHandler),
    subscribe(backend, inboxItem.id, profileEndpoint.broadcastTopic, profileHandler),
    subscribe(backend, inboxItem.id, profileMergeEndpoint.broadcastTopic, profileMergeHandler),
  ]);
}

export function subscribeToInbox(context, backend, observer, orgId, inboxItemList) {
  _.forEach(inboxItemList, inboxItem => {
    if (isSubscribedToItem(inboxItem.id)) {
      reconcileInboxItem(context, inboxItem);
      return;
    }

    subscribeToItem(backend, observer, orgId, inboxItem).catch(err => {
      unsubscribe(backend, inboxItem.id);
      ErrorReporter.reportError(err);
    });
  });

  _.forEach(inboxItemList, inboxItem => {
    context.stores.agentInboxItems.addOrReplace(inboxItem);
  });
}

export function subscribeToCustomer(backend, observer, orgId, customerId) {
  const conversationsEndpoint = new Endpoint(CONVERSATIONS_TOPIC).set({
    customerId,
    orgId,
  });
  const conversationsHandler = observer.onBroadcastConversation.bind(observer, CONVERSATIONS_TOPIC);

  const itemsEndpoint = new Endpoint(ITEMS_TOPIC).set({
    customerId,
    orgId,
  });
  const itemsHandler = observer.onBroadcastItem.bind(observer, ITEMS_TOPIC);

  const profileEndpoint = new Endpoint(PROFILE_TOPIC).set({
    customerId,
    orgId,
  });
  const profileHandler = observer.onBroadcastProfile.bind(observer, PROFILE_TOPIC);

  const profileMergeEndpoint = new Endpoint(PROFILE_MERGE_TOPIC).set({
    customerId,
    orgId,
  });
  const profileMergeHandler = observer.onBroadcastProfileMerge.bind(observer, PROFILE_MERGE_TOPIC);

  return Promise.all([
    subscribe(backend, customerId, conversationsEndpoint.collectionBroadcastTopic, conversationsHandler),
    subscribe(backend, customerId, itemsEndpoint.collectionBroadcastTopic, itemsHandler),
    subscribe(backend, customerId, profileEndpoint.broadcastTopic, profileHandler),
    subscribe(backend, customerId, profileMergeEndpoint.broadcastTopic, profileMergeHandler),
  ]);
}

function unsubscribeFromRemovedItems(context, inboxItems) {
  const existingItems = context.stores.agentInboxItems.findAll();
  const newItemSet = new Set(_.map(inboxItems, i => i.id));
  _.forEach(existingItems, item => {
    if (!newItemSet.has(item.id)) {
      unsubscribe(context.backend, item.id);
      context.stores.agentInboxItems.remove(item.id);
    }
  });
}

export function unsubscribe(backend, itemId) {
  _.forEach(getSubscription(itemId), (handler, topic) => {
    backend.unsubscribe(topic, handler);
  });
  removeSubscription(itemId);
}

export function subscribe(backend, itemId, topic, handler) {
  const subscription = getSubscription(itemId);
  if (subscription[topic]) {
    return Promise.reject(new Error(`Already subscribed to agent inbox item [${itemId}] topic [${topic}]`));
  }

  const newSubscription = produce(subscription, draft => {
    draft[topic] = handler;
  });
  setSubscription(itemId, newSubscription);

  return backend.subscribe(topic, handler);
}

export function requestUrl(url, payload) {
  let query = qs.stringify(payload);
  return `${url}?${query}`;
}
