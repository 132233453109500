import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from '../lib/create_model';

const AttributeType = createEnum('CURRENCY', 'DATE', 'NUMBER', 'PERCENT', 'STRING', 'MULTILINE_TEXT');
const AttributePresentation = createEnum('VISIBLE', 'EDITABLE', 'HIDDEN');

const AttributeDef = createModel({
  modelName: 'AttributeDef',

  properties: {
    attr: prop(String).isRequired,
    label: prop(String),
    linkTemplate: prop(String),
    linkText: prop(String),
    presentation: prop(String)
      .oneOf(..._.values(AttributePresentation))
      .default(AttributePresentation.VISIBLE),
    type: prop(String)
      .oneOf(..._.values(AttributeType))
      .default(AttributeType.STRING),
  },
});

export { AttributeType, AttributePresentation };
export default AttributeDef;
