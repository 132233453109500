import createModel from './lib/create_model';

export default createModel({
  modelName: 'WebContext',

  properties: {
    events: [],
    username: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
