import createModalModel, { prop } from './lib/create_modal_model';
import DownloadableAttachment from 'models/downloadable_attachment';

export default createModalModel({
  modelName: 'AttachmentPreview',
  properties: {
    attachments: prop([DownloadableAttachment]).default([]).isRequired,
    currentIndex: prop(Number).isRequired,
  },

  // override toJs because of the special treatment for the `attachment`
  // it isn't worth customizing toJs with `overrideToJs` because there isn't much left, just the `downloadUrl`
  toJs() {
    return {
      // not converting attachments to plain JS object for simplicity, we aren't going to marshal these models anywhere
      attachments: this.attachments,
      currentIndex: this.currentIndex,
      type: this.constructor.typeName,
    };
  },
});
