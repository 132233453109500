import _ from 'lodash';

export default {
  fromModelClass(ModelClass) {
    var entityName = _.camelCase(ModelClass.name);
    var storeName = entityName + 's';

    return {
      get gatewayName() {
        return entityName;
      },
      get storeName() {
        return storeName;
      },
    };
  },
};
