import React from 'react';
import PropTypes from 'prop-types';

import ConversationModal from 'components/modals/conversation_modal';

export default function ReopenConversationModal(props) {
  return (
    <ConversationModal
      headerText="Conversation is closed"
      onCancel={props.onCancel}
      option={{
        className: 'closedConversationModal-reopenConversation',
        label: 'Reopen',
        onClick: props.onReopenConversationClick,
      }}
      renderBody={renderModalBody}
      secondOption={{
        className: 'closedConversationModal-newConversation',
        label: 'Create New',
        onClick: props.onCreateNewConversationClick,
      }}
    />
  );

  function renderModalBody() {
    return (
      <div>
        <p>To keep the conversation going,</p>
        <p>create a new conversation or reopen the old one.</p>
      </div>
    );
  }
}

ReopenConversationModal.propTypes = {
  onCancel: PropTypes.func,
  onCreateNewConversationClick: PropTypes.func,
  onReopenConversationClick: PropTypes.func,
};
