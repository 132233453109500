import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import FlexibleBlock from 'components/customer/flexible_profile_card/components/flexible_block';
import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';

export default function FlexibleItemSummary({ className, itemData, summaryConfig }) {
  const summaryAttributes = _.get(summaryConfig, 'attributes') || [];
  const renderableAttrs = getAttributesToDisplay(summaryAttributes, itemData);

  const contentClassnames = classnames('flexible-item-summary-content', className);
  return _.isEmpty(renderableAttrs) ? (
    <SummaryPlaceholder className={contentClassnames}>No data to display</SummaryPlaceholder>
  ) : (
    <FlexibleBlock className={contentClassnames} config={{ attributes: renderableAttrs }} customAttributes={itemData} />
  );
}

const SummaryPlaceholder = styled.div`
  padding: 16px 4px;
  text-align: center;
`;

FlexibleItemSummary.propTypes = {
  className: PropTypes.string,
  itemData: PropTypes.object.isRequired,
  summaryConfig: PropTypes.object.isRequired,
};
