import { Factory } from 'rosie';

Factory.define('CustomerLookupConfig');

Factory.define('CustomerLookupConfigWithLookupApi')
  .extend('CustomerLookupConfig')
  .attr('adapterType', 'LOOKUP_API')
  .attr('adapterConfiguration', {
    url: 'https://api.test/lookup',
  })
  .attr('autoLinkFields', ['emails'])
  .attr('overwritingEnabled', true);
