import { trim } from 'lodash';
import { useCallback, useState, useContext } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import hasForgottenAttachment from 'components/composer/email/has_forgotten_attachment';
import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';
import { stripGreetingAndSignature } from 'components/composer/email/strip_greeting_and_signature';

export default function useForgottenTextModal(onSubmit, editor, defaultEditorBody) {
  const [forgottenAttachmentText, setForgottenAttachmentText] = useState(null);
  const [showForgottenTextModal, setShowForgottenTextModal] = useState(false);
  const clearForgottenAttachmentText = useCallback(() => setForgottenAttachmentText(null), []);
  const clearShowForgottenTextModal = useCallback(() => setShowForgottenTextModal(false), []);
  const { composition } = useContext(ComposerContext);

  const decoratedOnSubmit = useCallback(
    evt => {
      const bodyPlain = serializePlaintext(editor);
      const content = defaultEditorBody ? stripGreetingAndSignature(defaultEditorBody, editor) : bodyPlain;
      if (!trim(content)) {
        setShowForgottenTextModal(true);
        return;
      }

      const hasAttachments = composition.attachments.length > 0;
      const [shouldShowForgottentAttachmentModal, matchingText] = hasForgottenAttachment(bodyPlain, hasAttachments);
      if (shouldShowForgottentAttachmentModal) {
        setForgottenAttachmentText(matchingText);
        return;
      }

      onSubmit(evt);
    },
    [defaultEditorBody, onSubmit, editor, composition]
  );

  return {
    onSubmit: decoratedOnSubmit,
    showForgottenTextModal,
    clearShowForgottenTextModal,
    forgottenAttachmentText,
    clearForgottenAttachmentText,
  };
}
