import { createContentModel, isEmpty } from './composition_content_shared';

const FbMessageCompositionContent = createContentModel({
  modelName: 'FbMessageCompositionContent',

  properties: {
    messageHtml: String,
  },

  isEmpty() {
    return isEmpty(this.messageHtml);
  },
});

FbMessageCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB = 25;

export default FbMessageCompositionContent;
