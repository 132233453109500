import navigateToConversation from './lib/navigate_to_conversation';

export default class NavigateToConversation {
  constructor(context) {
    this.context = context;
  }

  run({ anchorPosition, customerId, conversationId, conversationItemId }) {
    navigateToConversation(this.context, { anchorPosition, customerId, conversationId, conversationItemId });
  }
}
