import _ from 'lodash';
import qs from 'qs';

import { after, HEADER_CORRELATION_ID } from 'scripts/infrastructure/backends/http_client';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import HttpResponseHandler from 'scripts/adapters/gateways/lib/http_response_handler';
import mixin from 'scripts/lib/mixin';
import MqttTopicPattern from 'scripts/adapters/gateways/lib/mqtt_topic_pattern';
import Observable from 'scripts/lib/observable_mixin';

export default class RoutingQueueItemGateway {
  constructor(backend) {
    this.http = backend;
    this.mqttClient = backend;
    _.bindAll(this, ['handleBroadcast', 'handleDeleteBroadcast', 'findAll']);

    this.subscriptions = new Set();
  }

  init({ orgId, agentId } = {}) {
    this.orgId = orgId;
    this.agentId = agentId;
  }

  get version() {
    return '1';
  }

  /* mqtt methods and handler */

  get broadcastDeleteTopicPattern() {
    return new MqttTopicPattern(`v${this.version}/orgs/${this.orgId}/routing-queue-items/+/event/delete`);
  }

  broadcastTopic(id) {
    return `v${this.version}/orgs/${this.orgId}/routing-queue-items/${id}`;
  }

  broadcastDeleteTopic(id) {
    return this.broadcastDeleteTopicPattern.interpolate(id);
  }

  request() {
    this.findAll(this.agentId);
  }

  subscribe(queueItemId) {
    this.subscriptions.add(queueItemId);
    this.mqttClient.subscribe(
      this.broadcastTopic(queueItemId),
      this.handleBroadcast,
      this.getQueueItem.bind(this, queueItemId)
    );
    this.mqttClient.subscribe(this.broadcastDeleteTopic(queueItemId), this.handleDeleteBroadcast);
  }

  unsubscribe(queueItemId) {
    this.subscriptions.delete(queueItemId);
    this.mqttClient.unsubscribe(this.broadcastTopic(queueItemId), this.handleBroadcast);
    this.mqttClient.unsubscribe(this.broadcastDeleteTopic(queueItemId), this.handleDeleteBroadcast);
  }

  reconnectHandler() {
    if (this.subscriptions.size > 0) {
      this.request();
    }
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleRoutingQueueItem', envelope.payload);
  }

  handleDeleteBroadcast(envelope, topic) {
    this.notifyObservers('handleRoutingQueueItemDelete', this.broadcastDeleteTopicPattern.extractParams(topic)[0]);
  }

  /* http methods and handlers */

  get _http() {
    return this.http.axios();
  }

  get url() {
    return `/api/v${this.version}/orgs/${this.orgId}/routing-queue-items`;
  }

  findAll(agentId) {
    const correlationId = IdGenerator.newId();
    const url = `${this.url}?${qs.stringify({ agentId })}`;

    const requestPromise = after(
      this._http.get(url, { headers: { [HEADER_CORRELATION_ID]: correlationId } }),
      this.handleResponse.bind(this, { requestName: 'findAll', correlationId, params: { agentId } })
    );

    return { correlationId, requestPromise };
  }

  itemUrl(itemId) {
    return `/api/v${this.version}/orgs/${this.orgId}/routing-queue-items/${itemId}`;
  }

  getQueueItem(itemId) {
    const correlationId = IdGenerator.newId();
    const url = this.itemUrl(itemId);

    const requestPromise = after(
      this._http.get(url, { headers: { [HEADER_CORRELATION_ID]: correlationId } }),
      this.handleResponse.bind(this, { requestName: 'fetch', correlationId, itemId })
    );

    return { correlationId, requestPromise };
  }
}

mixin(RoutingQueueItemGateway.prototype, HttpResponseHandler.prototype);
mixin(RoutingQueueItemGateway.prototype, Observable);
