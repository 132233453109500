import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttachmentModal from 'components/attachments/attachment_modal';
import AttachmentPreviewOpener from 'components/lib/attachment/attachment_preview_opener';
import FbMessageIncomingAttachment from 'models/fb_message_incoming_attachment';
import FbMessageOutgoingAttachment from 'models/fb_message_outgoing_attachment';
import GenericAttachment from 'components/lib/attachment/generic_attachment';
import { getFbAttachmentUrl } from 'scripts/domain/services/attachments';
import { HandleHeightChange } from 'components/customer/conversation_history/virtualized/list_item';
import InlineImageAttachment from 'components/lib/attachment/inline_image_attachment';

const FbMessageAttachmentsContainer = AttachmentPreviewOpener(FbMessageAttachments);

function FbMessageAttachments({ attachments, orgId }) {
  const [previewAttachment, setPreviewAttachment] = useState(null);
  if (_.isEmpty(attachments)) {
    return null;
  }

  let [inlineImageAttachments, genericAttachments] = _.partition(
    attachments,
    a => !a.isRedacted && a.isImage() && a.isAvailable()
  );
  let availableAttachments = _.filter(inlineImageAttachments.concat(genericAttachments), a => a.isAvailable());

  return (
    <React.Fragment>
      <div className="fbMessageAttachments">
        <ul className="fbMessageAttachments-inlineImageAttachments">
          {_.map(inlineImageAttachments, renderInlineImageAttachment)}
        </ul>
        <ul className="genericAttachments">{_.map(genericAttachments, renderGenericAttachment)}</ul>
      </div>
      {previewAttachment && (
        <AttachmentModal
          attachment={previewAttachment}
          attachments={availableAttachments}
          setAttachment={setPreviewAttachment}
          url={getDownloadUrl(previewAttachment)}
        />
      )}
    </React.Fragment>
  );

  function renderGenericAttachment(attachment) {
    return (
      <GenericAttachment
        attachment={attachment}
        key={attachment.id}
        onClick={(attachment.isAvailable() && openAttachmentPreview) || undefined}
      />
    );

    function openAttachmentPreview() {
      setPreviewAttachment(attachment);
    }
  }

  function renderInlineImageAttachment(attachment) {
    return (
      <HandleHeightChange key={attachment.id}>
        {({ onHeightChange }) => (
          <InlineImageAttachment
            attachment={attachment}
            inlinePreviewImageUrl={getPreviewUrl()}
            onClick={openAttachmentPreview}
            onLoad={onHeightChange}
          />
        )}
      </HandleHeightChange>
    );

    function getPreviewUrl() {
      return `${getDownloadUrl(attachment)}?size=preview`;
    }

    function openAttachmentPreview() {
      setPreviewAttachment(attachment);
    }
  }

  function getDownloadUrl(a) {
    return getFbAttachmentUrl(orgId, a);
  }
}

FbMessageAttachmentsContainer.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(FbMessageIncomingAttachment),
      PropTypes.instanceOf(FbMessageOutgoingAttachment),
    ])
  ).isRequired,
};

FbMessageAttachments.propTypes = {
  ...FbMessageAttachmentsContainer.propTypes,
  ...AttachmentPreviewOpener.childPropTypes,
};

export { FbMessageAttachments };
export default FbMessageAttachmentsContainer;
