import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class UpdateCompositionWithSnippet {
  constructor(context) {
    this.context = context;
  }

  run({ snippetId, compositionId, snippetLanguage, snippetChannelType }) {
    const store = getCompositionsStore(this.context);
    if (store.findBy({ id: compositionId })) {
      const composition = store.find(compositionId);
      composition.addSnippetId(snippetId);
      composition.addRelatedSnippetId({ snippetId, snippetLanguage, snippetChannelType });
      store.replace(composition);
    }
  }
}
