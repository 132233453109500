import ConversationWorkflowConfig from 'models/conversation_workflow_config';
import qconsole from 'scripts/lib/qconsole';

export default class ConversationWorkflowConfigGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleEntity(workflowConfigDto) {
    this.context.stores.conversationWorkflowConfig.resetLoading();
    this.context.stores.conversationWorkflowConfig.set(ConversationWorkflowConfig.fromJs(workflowConfigDto));
  }

  handleRequestError(errorDto) {
    this.context.stores.conversationWorkflowConfig.resetLoading();
    qconsole.error(`Unexpected error requesting conversation workflow config ${JSON.stringify(errorDto)}`);
  }
}
