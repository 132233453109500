import _ from 'lodash';
import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import createEnum from 'scripts/lib/create_enum';
import ensureOptionInView from 'components/lib/ensure_option_in_view';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Input from 'components/common/input';
import registerHotkey from 'components/hotkeys/register_hotkey';
import ShortList from 'components/common/short_list';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

export const COLLAPSE_SELECTED_OPTIONS_NUM = 5;

export class MultiSelectMenu extends PureComponent {
  /* life cycle */

  constructor(props) {
    super(props);

    let selectedValues = this.getInitialSelectedValues();
    let options = this.getOptions(null, this.props.options, selectedValues, this.props.suggestedIds || []);
    this.state = {
      filter: '',
      selectionsExpanded: false,
      selectedValues,
      opened: false,
      options,
    };
    this.suppressNextMouseEvent = true;

    _.bindAll(this, [
      'blur',
      'getInitialFocusedOptionId',
      'filterOptions',
      'focus',
      'handleApply',
      'handleBlur',
      'handleCancel',
      'handleClose',
      'handleCollapseSelections',
      'handleExpandSelections',
      'handleHeaderClick',
      'handleInputChange',
      'handleInputKeyDown',
      'handleMenuFocus',
      'handleMenuOpenerClick',
      'handleOpen',
      'handleOptionClick',
      'handleOptionFocus',
      'renderMenu',
      'setMenuRef',
      'setOpenerRef',
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let nextPropOptions = nextProps.options;

    let selectedValues = this.state.selectedValues;
    if (!_.isEqual(this.props.optionIds, nextProps.optionIds)) {
      let nextOptionIds = Immutable.fromJS(nextProps.optionIds || []);

      this.setState({
        selectedValues: nextOptionIds,
      });
      selectedValues = nextOptionIds;
    }

    let nextOptions = this.getOptions(
      this.state.options,
      nextPropOptions,
      selectedValues,
      nextProps.suggestedIds || []
    );
    const hasNewOptions = nextOptions.size !== this.state.options.size;
    const hasChangedOptions =
      nextOptions.toSet().intersect(this.state.options.toSet()).size !== this.state.options.size;

    if (hasNewOptions || hasChangedOptions) {
      this.setState({
        options: nextOptions,
      });
    }
    if (
      (nextProps.suggestedIds || []).length !== (this.props.suggestedIds || []).length ||
      _.difference(nextProps.suggestedIds, this.props.suggestedIds).length > 0
    ) {
      this.setState({
        focusedOptionId: undefined,
      });
    }

    if (nextProps.lastOpenedAt && nextProps.lastOpenedAt !== this.props.lastOpenedAt) {
      this.handleOpen();
    }
  }

  /* api */

  blur() {
    this.opener.blur();
  }

  focus() {
    this.handleOpen();
    this.opener.focus();
  }

  /* event handlers */

  handleBlur() {
    if (this.isCancelled) {
      return;
    }
    this.saveOptionChanges();
    this.canClose = true;
    this.blur();
    this.handleClose();
  }

  handleApply(ev) {
    this.overrideEvent(ev);
    this.saveOptionChanges();
    this.closeMenu();
  }

  handleCancel(ev) {
    this.isCancelled = true;
    this.overrideEvent(ev);
    this.closeMenu();
  }

  closeMenu() {
    this.opener.focus();
    this.canClose = true;
    this.blur();
  }

  handleClose() {
    if (this.ignoreClose) {
      this.opener.focus();
      this.ignoreClose = false;
      return;
    }
    if (this.canClose) {
      this.props.onClose && this.props.onClose();
      this.canClose = false;
      this.setState({
        opened: false,
        filter: '',
        selectionsExpanded: false,
      });
    }
  }

  handleCollapseSelections(ev) {
    this.overrideEvent(ev);
    this.collapseSelections();
  }

  handleEnter() {
    let option = this.filteredOptions.find(o => o.optionId === this.focusedOptionId);
    switch (option.type) {
      case ConversationTopicMenu.optionType.OPTION:
        this.toggleOptionSelection(option);
        this.setState({ filter: '' });
        return this.getNextFilteredOptionId();
      case ConversationTopicMenu.optionType.EXPANDER:
        this.expandSelections();
        return ConversationTopicMenu.optionType.COLLAPSER;
      case ConversationTopicMenu.optionType.COLLAPSER:
        this.collapseSelections();
        return ConversationTopicMenu.optionType.EXPANDER;
      default:
        return option;
    }
  }

  handleExpandSelections(ev) {
    this.overrideEvent(ev);
    this.expandSelections();
  }

  handleHeaderClick() {
    this.resetFocusedOptionId();
  }

  handleInputChange(ev) {
    let input = ev.target.value;
    this.setState({ filter: input });
    this.filteredOptions = this.filterOptions(this.state.options, input, this.state.selectedValues).toJS();
    this.resetFocusedOptionId();
    this.props.onSearch && this.props.onSearch();
  }

  handleInputKeyDown(ev) {
    if (!this.state.opened) return;

    this.suppressNextMouseEvent = true;
    const hasOptions = this.filteredOptions.length > 0;

    switch (ev.keyCode) {
      case 9: // tab
        this.overrideEvent(ev);
        this.saveOptionChanges();
        this.closeMenu();
        break;
      case 13: // enter
        this.overrideEvent(ev);
        hasOptions && this.setFocusedOptionId(this.handleEnter());
        break;
      case 27: // esc
        this.overrideEvent(ev);
        this.closeMenu();
        break;
      case 37: // left
        if ((ev.metaKey || ev.ctrlKey) && this.state.selectionsExpanded) {
          this.overrideEvent(ev);
          this.collapseSelections();
          hasOptions && this.setFocusedOptionId(ConversationTopicMenu.optionType.EXPANDER);
        }
        break;
      case 38: // up
        this.overrideEvent(ev);
        if (ev.metaKey || ev.ctrlKey) {
          this.menu.scrollToTop();
          this.resetFocusedOptionId();
        } else {
          hasOptions && this.setFocusedOptionId(this.getPreviousFilteredOptionId());
        }
        break;
      case 39: // right
        if ((ev.metaKey || ev.ctrlKey) && !this.state.selectionsExpanded) {
          this.overrideEvent(ev);
          this.expandSelections();
          hasOptions && this.setFocusedOptionId(ConversationTopicMenu.optionType.COLLAPSER);
        }
        break;
      case 40: // down
        this.overrideEvent(ev);
        if (ev.metaKey || ev.ctrlKey) {
          this.menu.scrollToBottom();
          this.setFocusedOptionId(this.getLastFilteredOptionId());
        } else {
          hasOptions && this.setFocusedOptionId(this.getNextFilteredOptionId());
        }
        break;
      default:
        break;
    }
  }

  handleMenuFocus() {
    this.ignoreClose = true;
  }

  handleMenuOpenerClick() {
    if (!this.state.opened) {
      this.focus();
      this.props.onFocus && this.props.onFocus(this);
    }
  }

  handleOpen() {
    this.props.onOpen && this.props.onOpen();
    this.resetFocusedOptionId();
    this.setState({
      opened: true,
    });
    this.suppressNextMouseEvent = true;
    this.isCancelled = false;
  }

  /* process clicking option on mouse down to precede react-select's internal processing */
  handleOptionClick(ev, option) {
    this.overrideEvent(ev);
    this.toggleOptionSelection(option);
    this.setFocusedOptionId(this.getNextFilteredOptionId());
    this.setState({ filter: '' });
  }

  handleOptionFocus(option) {
    /* mouse and keyboard interaction are a bit funky. When we scroll the menu to focus an option currently out
     * of view, the mouse enters a new option which we would focus, removing focus on the one navigated to by
     * keyboard. This prevents the behavior.
     */
    if (this.suppressNextMouseEvent) {
      this.suppressNextMouseEvent = false;
      return;
    }
    this.setFocusedOptionId(option.optionId);
  }

  /* presentation */

  render() {
    let className = classnames('conversationTopic-multi', this.props.className);

    let options = this.filterOptions(this.state.options, this.state.filter, this.state.selectedValues);
    this.filteredOptions = options.toJS();
    if (options.size > 0) {
      this.focusedOptionId = this.state.focusedOptionId || this.getInitialFocusedOptionId();
    }
    return (
      <div
        className={className}
        onBlur={this.handleBlur}
        onClick={this.handleMenuOpenerClick}
        onKeyDown={this.handleInputKeyDown}
      >
        <label className="conversationTopic-label">{this.props.label}</label>
        {this.renderMenuOpener()}
        {this.renderMenu(options)}
      </div>
    );
  }

  renderMenu(options) {
    if (!this.state.opened) {
      return null;
    }
    return (
      <ConversationTopicMenu
        dropDown={this.props.dropDown}
        focusedOptionId={this.focusedOptionId}
        numSelectedOptions={this.state.selectedValues.size}
        onApply={this.handleApply}
        onCancel={this.handleCancel}
        onCollapserClick={this.handleCollapseSelections}
        onExpanderClick={this.handleExpandSelections}
        onFocus={this.handleMenuFocus}
        onHeaderClick={this.handleHeaderClick}
        onOptionClick={this.handleOptionClick}
        onOptionFocus={this.handleOptionFocus}
        options={options}
        ref={this.setMenuRef}
        unitLabelPlural={this.props.unitLabelPlural}
        unitLabelSingular={this.props.unitLabelSingular}
      />
    );
  }

  renderMenuOpener() {
    let selectedOptions = this.state.selectedValues.map(id => {
      let option = this.props.options.find(t => t.get('id') === id);
      return option ? option.get('name') : undefined;
    });
    selectedOptions = Immutable.fromJS(selectedOptions.filter(t => t !== undefined));
    return (
      <ConversationTopicMenuOpener
        inputValue={this.state.filter}
        isOpen={this.state.opened}
        onInputBlur={this.handleClose}
        onInputChange={this.handleInputChange}
        placeholder={this.props.placeholder}
        ref={this.setOpenerRef}
        values={selectedOptions}
      />
    );
  }

  setOpenerRef(ref) {
    this.opener = ref;
  }

  /* utility */

  collapseSelections() {
    this.setState({
      selectionsExpanded: false,
    });
  }

  expandSelections() {
    this.setState({
      selectionsExpanded: true,
    });
  }

  filterOptions(options, filter, selected) {
    let selectedValues = {};
    selected.forEach(o => {
      selectedValues[o] = true;
    });
    let filteredOptions = options
      .map(o => o.update('selected', () => selectedValues.hasOwnProperty(o.get('id'))))
      .map(o => o.update('wasSelected', () => selectedValues.hasOwnProperty(o.get('id')) || o.get('wasSelected')))
      .filter(
        o =>
          filter === '' ||
          o.get('selected') ||
          o
            .get('label')
            .toLowerCase()
            .indexOf(filter.toLowerCase()) !== -1
      )
      .sortBy(o => o.get('label').toLowerCase());

    let selectedOptions = filteredOptions.filter(o => o.get('selected'));
    let suggestedOptions = filteredOptions.filter(
      o => o.get('suggested') && !o.get('selected') && !o.get('wasSelected')
    );
    suggestedOptions = suggestedOptions.map(o => o.set('optionId', `${o.get('optionId')}-suggested`));
    let unselectedOptions = filteredOptions.filter(o => !o.get('selected')).map(o => o.set('suggested', false));
    unselectedOptions = unselectedOptions.filter(o => !o.get('archived'));

    let currentOptions = this.collapseOrExpandOptions(selectedOptions);
    /* add divider between selected and unselected options */
    if (selectedOptions.size > 0 && unselectedOptions.size > 0) {
      currentOptions = currentOptions.push(
        Immutable.fromJS({
          disabled: true,
          type: ConversationTopicMenu.optionType.DIVIDER,
          unfocusable: true,
          optionId: ConversationTopicMenu.optionType.DIVIDER,
        })
      );
    }

    if (suggestedOptions.size > 0 && this.props.suggestedLabel) {
      currentOptions = currentOptions.push(
        Immutable.fromJS({
          label: this.props.suggestedLabel,
          disabled: true,
          type: ConversationTopicMenu.optionType.GROUP_TITLE,
          unfocusable: true,
          optionId: 'suggested-topics-title',
        })
      );
      currentOptions = currentOptions.concat(suggestedOptions);
    }

    if (unselectedOptions.size > 0 && this.props.unselectedLabel) {
      currentOptions = currentOptions.push(
        Immutable.fromJS({
          label: this.props.unselectedLabel,
          disabled: true,
          type: ConversationTopicMenu.optionType.GROUP_TITLE,
          unfocusable: true,
          optionId: 'all-topics-title',
        })
      );
    }

    return currentOptions.concat(unselectedOptions);
  }

  collapseOrExpandOptions(selectedOptions) {
    /* collapse options or add ability to collapse them */
    if (selectedOptions.size > COLLAPSE_SELECTED_OPTIONS_NUM) {
      if (this.state.selectionsExpanded) {
        selectedOptions = selectedOptions.push(
          Immutable.fromJS({
            disabled: true,
            type: ConversationTopicMenu.optionType.COLLAPSER,
            optionId: ConversationTopicMenu.optionType.COLLAPSER,
          })
        );
      } else {
        let numCollapsedOptions = selectedOptions.size - COLLAPSE_SELECTED_OPTIONS_NUM;
        selectedOptions = selectedOptions.splice(
          COLLAPSE_SELECTED_OPTIONS_NUM,
          numCollapsedOptions,
          Immutable.fromJS({
            disabled: true,
            type: ConversationTopicMenu.optionType.EXPANDER,
            optionId: ConversationTopicMenu.optionType.EXPANDER,
          })
        );
      }
    }
    return selectedOptions;
  }

  getInitialSelectedValues() {
    return Immutable.fromJS(this.props.optionIds || []);
  }

  getNextFilteredOptionId() {
    let index = _.findIndex(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    do {
      index = (index + 1) % this.filteredOptions.length;
      var option = this.filteredOptions[index];
    } while (option.unfocusable);
    return option.optionId;
  }

  getPreviousFilteredOptionId() {
    let index = _.findIndex(this.filteredOptions, o => o.optionId === this.focusedOptionId);
    do {
      index--;
      if (index < 0) {
        index = this.filteredOptions.length - 1;
      }
      var option = this.filteredOptions[index];
    } while (option.unfocusable);
    return option.optionId;
  }

  getLastFilteredOptionId() {
    let last = _.last(this.filteredOptions);
    return last.optionId;
  }

  getOptions(currentOptions, newOptions, selectedValues, suggestedValues) {
    return newOptions.map(t => {
      let id = t.get('id');
      let suggested = suggestedValues.find(s => s === id) !== undefined;
      let selected = selectedValues.find(s => s === id) !== undefined;
      let wasSelected =
        (currentOptions && currentOptions.find(o => o.get('id') === id && o.get('wasSelected'))) || selected;
      return Immutable.fromJS({
        archived: t.get('disabled'), // Can't use 'disabled' as key since that affects react-select in undesirable way.
        label: t.get('name'),
        selected,
        wasSelected,
        type: ConversationTopicMenu.optionType.OPTION,
        suggested,
        optionId: id,
        id,
      });
    });
  }

  overrideEvent(ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  getInitialFocusedOptionId() {
    if (this.filteredOptions.length > 0) {
      let firstTitleIndex = _.findIndex(this.filteredOptions, { type: ConversationTopicMenu.optionType.GROUP_TITLE });
      if (firstTitleIndex !== -1) {
        return this.filteredOptions[firstTitleIndex + 1].optionId;
      }
      let dividerIndex = _.findIndex(this.filteredOptions, { type: ConversationTopicMenu.optionType.DIVIDER });
      if (dividerIndex !== -1) {
        return this.filteredOptions[dividerIndex + 1].optionId;
      }
      return this.filteredOptions[0] && this.filteredOptions[0].optionId;
    }
  }

  resetFocusedOptionId() {
    this.setFocusedOptionId(this.getInitialFocusedOptionId());
  }

  saveOptionChanges() {
    this.props.onOptionChange(this.state.selectedValues.toJS());
  }

  setFocusedOptionId(optionId) {
    this.opener.focus();
    this.focusedOptionId = optionId;
    this.setState({
      focusedOptionId: optionId,
    });
  }

  setMenuRef(ref) {
    this.menu = ref;
  }

  toggleOptionSelection(option) {
    if (option.disabled) {
      return;
    }
    let selectionType = option.suggested ? 'suggestion' : 'general-list';
    if (option.selected) {
      this.props.onSelect &&
        this.props.onSelect({
          topicId: option.id,
          topicName: option.label,
          action: SelectAction.REMOVE,
          selectionType,
        });
      let nextOptions = this.getOptions(
        this.state.options,
        this.props.options,
        this.state.selectedValues,
        this.props.suggestedIds || []
      );
      this.setState({
        selectedValues: this.state.selectedValues.delete(this.state.selectedValues.findIndex(v => v === option.id)),
        options: nextOptions,
      });
    } else {
      this.props.onSelect &&
        this.props.onSelect({
          topicId: option.id,
          topicName: option.label,
          action: SelectAction.ADD,
          selectionType,
        });
      let nextOptions = this.getOptions(
        this.state.options,
        this.props.options,
        this.state.selectedValues,
        this.props.suggestedIds || []
      );
      this.setState({
        selectedValues: this.state.selectedValues.push(option.id),
        options: nextOptions,
      });
    }
  }
}

MultiSelectMenu.propTypes = {
  className: PropTypes.string,
  dropDown: PropTypes.bool,
  label: PropTypes.string,
  lastOpenedAt: PropTypes.string,
  optionIds: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  onOpen: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onOptionChange: PropTypes.func.isRequired,
  options: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  placeholder: PropTypes.string,
  suggestedIds: PropTypes.array,
  suggestedLabel: PropTypes.string,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
  unselectedLabel: PropTypes.string,
};

export class ConversationMultiTopicBase extends PureComponent {
  render() {
    return (
      <MultiSelectMenu
        {...this.props}
        label="Topics"
        onClose={this.props.onMenuClose}
        onOpen={this.props.onMenuOpen}
        onOptionChange={this.props.onTopicChange}
        onSearch={this.props.onTopicSearch}
        onSelect={this.props.onTopicSelect}
        optionIds={this.props.conversation.topicIds}
        options={this.props.topics}
        placeholder="Add a Topic"
        ref={multiSelect => (this.multiSelect = multiSelect)}
        suggestedIds={this.props.topicSuggestions && this.props.topicSuggestions.addTopics}
        suggestedLabel="Suggested Topics"
        unitLabelSingular="Topic"
        unselectedLabel="All Topics"
      />
    );
  }
}

ConversationMultiTopicBase.propTypes = {
  className: PropTypes.string,
  dropDown: PropTypes.bool,
  conversation: PropTypes.object.isRequired,
  onFocus: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onTopicChange: PropTypes.func.isRequired,
  onTopicSearch: PropTypes.func,
  onTopicSelect: PropTypes.func,
  topics: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  topicSuggestions: PropTypes.object,
};

export class ConversationTopicSelectionCollapser extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, 'handleMouseEnter');
  }

  handleMouseEnter() {
    this.props.onMouseEnter(this.props.option.toJS());
  }

  render() {
    let className = classnames('conversationTopic-collapser', {
      'conversationTopic-collapser-focused': this.props.isFocused,
    });
    return (
      <div className={className} onMouseDown={this.props.onClick} onMouseEnter={this.handleMouseEnter}>
        Show Fewer
        <i className="conversationTopic-chevron fa fa-chevron-up" />
      </div>
    );
  }
}

ConversationTopicSelectionCollapser.propTypes = {
  isFocused: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: ImmutablePropTypes.map,
};

export const ConversationTopicSelectionDivider = function() {
  return <div className="conversationTopic-divider" />;
};

export const ConversationTopicGroupTitle = function({ label }) {
  return <div className="conversationTopic-groupTitle searchResults-header-font">{label}</div>;
};

ConversationTopicGroupTitle.propTypes = {
  label: PropTypes.string,
};

export class ConversationTopicSelectionExpander extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, 'handleMouseEnter');
  }

  handleMouseEnter() {
    this.props.onMouseEnter(this.props.option.toJS());
  }

  render() {
    let className = classnames('conversationTopic-expander', {
      'conversationTopic-expander-focused': this.props.isFocused,
    });
    return (
      <div className={className} onMouseDown={this.props.onClick} onMouseEnter={this.handleMouseEnter}>
        {`+${this.props.numSelections} more`}
        <i className="conversationTopic-chevron fa fa-chevron-down" />
      </div>
    );
  }
}

ConversationTopicSelectionExpander.propTypes = {
  isFocused: PropTypes.bool,
  numSelections: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: ImmutablePropTypes.map,
};

export class ConversationTopicMenuHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasChangedCount: false };
    this.timeout = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // blink the count when it changes
    if (nextProps.numSelections !== this.props.numSelections) {
      this.setState({ hasChangedCount: true });
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({ hasChangedCount: false });
      }, 1000);
    }
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  render() {
    let props = this.props;

    let unitLabel = props.unitLabelSingular;
    if (props.numSelections !== 1) {
      unitLabel = props.unitLabelPlural ? props.unitLabelPlural : `${props.unitLabelSingular}s`;
    }

    return (
      <div
        className={classnames('conversationTopic-header', {
          'conversationTopic-header-changed': this.state.hasChangedCount,
        })}
        onMouseDown={props.onClick}
      >
        {`${props.numSelections} ${unitLabel} selected`}
      </div>
    );
  }
}

ConversationTopicMenuHeader.propTypes = {
  numSelections: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
};

export class ConversationTopicMenuOption extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, 'handleClick', 'handleMouseEnter');
  }

  handleClick(ev) {
    this.props.onClick(ev, this.props.option.toJS());
  }

  handleMouseEnter() {
    this.props.onMouseEnter(this.props.option.toJS());
  }

  render() {
    let isSelected = this.props.option.get('selected');
    let isFocused = this.props.isFocused;
    let isSuggested = this.props.option.get('suggested');
    let className = classnames('conversationTopic-option', {
      'conversationTopic-option-focused': isFocused,
      'conversationTopic-option-selected': isSelected,
      'conversationTopic-option-suggested': isSuggested,
      'conversationTopic-option-selected-focused': isSelected && isFocused,
    });
    let iconClasses = classnames('conversationTopic-option-icon', {
      'conversationTopic-option-unselect': isFocused,
      'conversationTopic-option-select': !isFocused,
    });
    return (
      <div className={className} onMouseDown={this.handleClick} onMouseEnter={this.handleMouseEnter}>
        <div className="conversationTopic-option-label">{this.props.option.get('label')}</div>
        {isSelected && <div className={iconClasses}>{isFocused && '×'}</div>}
      </div>
    );
  }
}

ConversationTopicMenuOption.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: ImmutablePropTypes.map,
};

export class ConversationTopicMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleWheel', 'handleHeaderClick', 'handleMenuMouseDown', 'handleMouseDown', 'setMenuRef']);
  }

  componentDidUpdate() {
    this.ensureOptionInView(this.props.focusedOptionId);
  }

  handleWheel(ev) {
    ev.stopPropagation();
  }

  handleHeaderClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.scrollToTop();
    this.props.onHeaderClick();
  }

  // this will prevent closing the menu when clicking in an area of the menu that is not an option or button
  handleMouseDown(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  handleMenuMouseDown(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  render() {
    let classes = classnames('conversationTopic-menu-container', {
      'conversationTopic-menu-container-dropDown': this.props.dropDown,
    });
    return (
      <div className={classes} onMouseDown={this.handleMouseDown} onWheel={this.handleWheel}>
        {this.renderHeader()}
        <div className="conversationTopic-menu" onMouseDown={this.handleMenuMouseDown} ref={this.setMenuRef}>
          {this.renderOptions()}
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <ConversationTopicMenuHeader
        numSelections={this.props.numSelectedOptions}
        onClick={this.handleHeaderClick}
        onCloseClick={this.props.onCancel}
        unitLabelPlural={this.props.unitLabelPlural}
        unitLabelSingular={this.props.unitLabelSingular}
      />
    );
  }

  renderOptions() {
    return this.props.options.map(o => {
      let id = o.get('id');
      let optionId = o.get('optionId');
      return (
        <div
          className="conversationTopic-menu-option-container"
          id={`conversation-topic-option-${optionId}`}
          key={`conversation-topic-option-${optionId}`}
          ref={id}
        >
          {this.renderOption(o, optionId === this.props.focusedOptionId, this.props.onOptionFocus)}
        </div>
      );
    });
  }

  renderOption(option, isFocused) {
    switch (option.get('type')) {
      case ConversationTopicMenu.optionType.COLLAPSER:
        return (
          <ConversationTopicSelectionCollapser
            isFocused={isFocused}
            onClick={this.props.onCollapserClick}
            onMouseEnter={this.props.onOptionFocus}
            option={option}
          />
        );
      case ConversationTopicMenu.optionType.DIVIDER:
        return <ConversationTopicSelectionDivider />;
      case ConversationTopicMenu.optionType.EXPANDER:
        return (
          <ConversationTopicSelectionExpander
            isFocused={isFocused}
            numSelections={this.props.numSelectedOptions - COLLAPSE_SELECTED_OPTIONS_NUM}
            onClick={this.props.onExpanderClick}
            onMouseEnter={this.props.onOptionFocus}
            option={option}
          />
        );
      case ConversationTopicMenu.optionType.OPTION:
        return (
          <ConversationTopicMenuOption
            isFocused={isFocused}
            onClick={this.props.onOptionClick}
            onMouseEnter={this.props.onOptionFocus}
            option={option}
          />
        );
      case ConversationTopicMenu.optionType.GROUP_TITLE:
        return <ConversationTopicGroupTitle label={option.get('label')} />;
      default:
        return null;
    }
  }

  /* in order to to get a sticky header on this menu, we need to disable `react-select`'s scrolling `<div>` and insert
   * our own. This, however, breaks `react-select`'s arrow key navigation. We have to compensate by scrolling an
   * option that is outside the menu into view when we navigate to it by arrow key.
   */
  ensureOptionInView(id) {
    if (!this.menu || !id) {
      return;
    }
    let optionNode = this.refs[id];
    if (!optionNode) {
      return;
    }
    ensureOptionInView(this.menu, optionNode);
  }

  setMenuRef(ref) {
    this.menu = ref;
  }

  scrollToTop() {
    this.menu.scrollTop = 0;
  }

  scrollToBottom() {
    this.menu.scrollTop = this.menu.scrollHeight;
  }
}

ConversationTopicMenu.propTypes = {
  dropDown: PropTypes.bool,
  focusedOptionId: PropTypes.string,
  numSelectedOptions: PropTypes.number.isRequired,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  onCollapserClick: PropTypes.func.isRequired,
  onExpanderClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onOptionFocus: PropTypes.func.isRequired,
  options: ImmutablePropTypes.listOf(ImmutablePropTypes.map).isRequired,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
};

ConversationTopicMenu.optionType = createEnum('COLLAPSER', 'DIVIDER', 'EXPANDER', 'OPTION', 'GROUP_TITLE');

export class ConversationTopicMenuOpener extends PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleInputBlur', 'handleInputChange', 'setInputRef']);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.focus();
    }
  }

  blur() {
    if (this.input) {
      this.input.blur();
    }
  }

  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  handleInputChange(ev) {
    this.setState({ inputValue: ev.target.value });
    this.props.onInputChange(ev);
  }

  handleInputBlur() {
    this.props.onInputBlur();
  }

  render() {
    return (
      <div className="conversationTopic-menuOpener">
        {this.renderInput()}
        {this.renderValue()}
      </div>
    );
  }

  renderInput() {
    if (this.props.isOpen) {
      return (
        <Input
          className="conversationTopic-menuOpener-input"
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
          placeholder=" Type to search"
          ref={this.setInputRef}
          value={this.props.inputValue}
          wrapperClassName="conversationTopic-menuOpener-inputWrapper"
        />
      );
    }

    return null;
  }

  renderValue() {
    if (!this.props.isOpen) {
      return (
        <ShortList
          className="conversationTopic-display"
          placeholder={this.props.placeholder}
          values={this.props.values}
        />
      );
    }

    return null;
  }

  setInputRef(ref) {
    this.input = ref;
  }
}

ConversationTopicMenuOpener.propTypes = {
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  values: ImmutablePropTypes.listOf(PropTypes.string),
};

export default withShortcuts(ConversationMultiTopicBase, [
  registerHotkey({
    key: 'alt+t',
    callback: component => {
      component.multiSelect.handleMenuOpenerClick();
    },
    group: 'Conversation',
    label: 'Edit topics',
  }),
]);

export const SelectAction = createEnum('ADD', 'REMOVE');
