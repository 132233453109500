import createLocationModel from '../lib/create_location_model';

export default createLocationModel({
  modelName: 'WebhooksAdmin',
  properties: {},

  statics: {
    breadcrumb: 'Webhooks Admin',
  },
});
