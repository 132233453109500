import ForgotPassword from 'models/location/forgot_password';

export default class OpenForgotPassword {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (this.context.stores.appFeatures.get().isEnabled('sso')) {
      this.context.router.navigateHome();
      return;
    }

    let passwordResetEmail = this.context.stores.currentLocation.get().username;
    this.context.stores.currentLocation.set(ForgotPassword.create({ passwordResetEmail }));
  }
}
