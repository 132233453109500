import createModel, { prop } from './lib/create_model';

const SnippetDetails = createModel({
  modelName: 'SnippetDetails',
  properties: {
    id: prop(String),
    name: prop(String),
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const AppliedSnippetRefs = createModel({
  modelName: 'AppliedSnippetRefs',
  properties: {
    audienceIds: prop([String]).default([]),
    language: prop(String),
    snippetDetails: prop([SnippetDetails]).default([]),
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const SuggestedReply = createModel({
  modelName: 'SuggestedReply',
  properties: {
    id: prop(String),
    text: prop(String),
    appliedSnippetRefs: prop(AppliedSnippetRefs),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export { SnippetDetails, AppliedSnippetRefs };

export default SuggestedReply;
