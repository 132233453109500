import React, { useMemo } from 'react';

const ItemContext = React.createContext({
  itemId: null,
});
export default ItemContext;

export function ItemContextProvider({ children, item }) {
  const value = useMemo(
    () => ({
      item,
    }),
    [item]
  );

  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>;
}
