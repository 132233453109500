import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('InboundMessageNotification').attr('type', 'INBOUND_MESSAGE');

Factory.define('InboundMessageNotificationWithDefaults')
  .extend('InboundMessageNotification')
  .attr('profile', () => Factory.attributes('SessionCustomerWithDefaults'))
  .attr('conversationId', 'conversation-id')
  .attr('conversationItemId', 'conversation-item-id')
  .attr('sessionType', () => 'SMS')
  .attr('id', ['conversationId'], conversationId => conversationId);

Factory.define('InboundMessageNotificationWithChatMessage')
  .extend('InboundMessageNotificationWithDefaults')
  .attr('messagePreview', 'Example chat message text')
  .attr('sessionType', 'CHAT');

Factory.define('InboundMessageNotificationWithEmailMessage')
  .extend('InboundMessageNotificationWithDefaults')
  .attr('messagePreview', 'Example email message text')
  .attr('sessionType', 'EMAIL');

Factory.define('InboundMessageNotificationWithFBMessage')
  .extend('InboundMessageNotificationWithDefaults')
  .attr('messagePreview', 'Example FB message text')
  .attr('sessionType', 'FB_MESSENGER');

Factory.define('InboundMessageNotificationWithSMS')
  .extend('InboundMessageNotificationWithDefaults')
  .attr('messagePreview', 'Example SMS text')
  .attr('sessionType', 'SMS');

Factory.define('InboundMessageNotificationWithWhatsApp')
  .extend('InboundMessageNotificationWithDefaults')
  .attr('messagePreview', 'Example WhatsApp message text')
  .attr('sessionType', 'WHATSAPP');
