import { useCallback } from 'react';

import PlaintextSerializer from 'components/text_editor/serializers/plaintext_serializer';
import hasForgottenToWriteStuff from './has_forgotten_to_write_stuff';
import { useVariables } from 'components/composer/contexts/variables_context';

export default function useCheckEmpty(defaultBody) {
  const variables = useVariables();
  return useCallback(
    editorValues => {
      const bodyPlain = PlaintextSerializer.serialize(editorValues.bodyHtml);
      return hasForgottenToWriteStuff(bodyPlain, defaultBody, variables);
    },
    [defaultBody, variables]
  );
}
