import createModel, { prop } from 'models/lib/create_model';

export const BasicAuthCredentials = createModel({
  modelName: 'BasicAuthCredentials',
  properties: {
    username: prop(String),
    password: prop(String),
    realm: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
