export default function createConverter(EntityClass) {
  return {
    fromDto(...args) {
      return EntityClass.fromJs(...args);
    },

    toDto(entity) {
      return entity.toJs();
    },
  };
}
