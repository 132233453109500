import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('KbVariable')
  .extend('Uuid');

Factory.define('KbVariableWithDefaults')
  .extend('KbVariable')
  .attr('name', ['id'], id =>`KbVariable ${id}`)
  .attr('defaultValue', ['name'], name => `${name} default`)
  .attr('type', ['name'], () => `CUSTOMER_FIRST_NAME`);
