import { Factory } from 'rosie';

Factory.define('AgentStatus');

Factory.define('AgentStatusWithDefaults')
  .extend('AgentStatus')
  .attr('id', 'current-agent-id')
  .attr('_version', 0)
  .attr('updatedAt', () => now())
  .attr('availabilityUpdatedAt', () => now())
  .attr('status', { availability: 'OFFLINE' });

Factory.define('AgentStatusAway')
  .extend('AgentStatusWithDefaults')
  .attr('_version', 1)
  .attr('status', { availability: 'AWAY' });

Factory.define('AgentStatusBusy')
  .extend('AgentStatusWithDefaults')
  .attr('_version', 1)
  .attr('status', { availability: 'BUSY' });

Factory.define('AgentStatusOffline')
  .extend('AgentStatusWithDefaults')
  .attr('_version', 1)
  .attr('status', { availability: 'OFFLINE' });

Factory.define('AgentStatusReady')
  .extend('AgentStatusWithDefaults')
  .attr('_version', 1)
  .attr('status', ['updatedAt'], updatedAt => ({
    availability: 'READY',
    channels: {
      MAIL: { available: true, changedAt: updatedAt },
      MESSAGING: { available: true, changedAt: updatedAt },
      TASK: { available: true, changedAt: updatedAt },
      VOICE: { available: true, changedAt: updatedAt },
    },
  }));

Factory.define('AgentStatusReadyForMessaging')
  .extend('AgentStatusWithDefaults')
  .attr('_version', 1)
  .attr('status', ['updatedAt'], updatedAt => ({
    availability: 'READY',
    channels: {
      MESSAGING: { available: true, changedAt: updatedAt },
    },
  }));

function now() {
  return new Date().toISOString();
}
