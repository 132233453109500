import moment from 'moment';

import CustomerTypingStateConverter from 'scripts/application/dto_converters/customer_typing_state_converter';
import StopCustomerTypingState from 'actions/communication_session/stop_customer_typing_state';
import Communicator from 'models/communicator';
import ConversationItemType from 'models/conversation_item_type';
import TypingState from 'models/typing_state';
import CustomerTypingState from 'models/customer_typing_state';
import { updateCustomerTypingState } from './lib/update_customer_typing_state';

const IDLE_TYPING_CLEAR_DELAY_MS = 15000;

export default class CustomerTypingStateObserver {
  constructor(context) {
    this.context = context;
  }

  handleCustomerTypingState({ customerId, customerTypingStateDto }) {
    const customerTypingState = CustomerTypingStateConverter.fromDto(customerTypingStateDto);
    const hasUpdated = this.updateCustomerTypingState(customerId, customerTypingState);
    if (!hasUpdated) {
      return;
    }

    const timeoutId = `customer-typing-timeout-${customerId}`;

    if (customerTypingState.state === 'STARTED') {
      this.context.scheduler.cancel(timeoutId);
      this.context.scheduler.executeIn(IDLE_TYPING_CLEAR_DELAY_MS, timeoutId, StopCustomerTypingState, { customerId });
    } else {
      this.context.scheduler.cancel(timeoutId);
    }
  }

  handleUpdateConversationItem({ customerId, conversationItemDto }) {
    if (
      conversationItemDto.initiator.type !== Communicator.CUSTOMER ||
      conversationItemDto.content.type !== ConversationItemType.CHAT_MESSAGE
    ) {
      return;
    }

    const customerTypingState = CustomerTypingState.fromJs({
      state: TypingState.STOPPED,
      timestamp: moment().toISOString(),
    });

    this.updateCustomerTypingState(customerId, customerTypingState);
  }

  updateCustomerTypingState(customerId, customerTypingState) {
    return updateCustomerTypingState(this.context, customerId, customerTypingState);
  }
}
