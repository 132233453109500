import _ from 'lodash';

import createModel from './lib/create_model';

const SOURCES = ['roleFeatures', 'gatekeeperFeatures', 'browserCapabilities'];
// TODO: remove ignore sources loaded once we get proper blocking on loading the app features for all sources.
const IGNORE_SOURCES_LOADED = ['demoMode'];

export const FeatureList = createModel({
  modelName: 'FeatureList',

  properties: {
    /* FEATURE FLAGS */

    // Enables ability to click phone numbers in cards to place call
    clickToCall: Boolean,

    // Enables "Contact Support" menu link for the users with correct roles
    contactSupport: Boolean,

    // load the cookieless versions of the Looker embeds
    cookielessLookerEmbed: Boolean,

    // update customer list by using agent assignment broadcasts
    customerListAssignmentBroadcast: Boolean,

    // shows custom attributes ui features
    customAttributes: Boolean,

    debug: Boolean,

    // enable dedicated hero capabilities
    dedicatedHero: Boolean,

    demoMode: Boolean,

    // can accept/make phones calls over direct dial
    directDial: Boolean,

    // disable analytics tracking (e.g. segment), typically used for non-production orgs
    disableAnalytics: Boolean,

    // disable Keyword Groups in admin part
    disableKeywordGroup: Boolean,

    // suppress new version notifications
    disableNewVersionNotifications: Boolean,

    // overwrites multi-source-customer-lookup display behavior to use customer-profile-def to display externalCustomerId
    displayLegacyExternalId: Boolean,

    doubleInboxPollingInterval1: Boolean,
    doubleInboxPollingInterval2: Boolean,

    // Enable Edit and Add HTTP2 Node in IVR Editor
    enableEditingHttp2Node: Boolean,

    // use HTML to generate plain text
    emailPlainTextFromHtml: Boolean,

    // Enable HeroAI tools in the new Email composer
    enableEmailComposerHeroAI: Boolean,

    // enable outgoing chat over http
    enableOutgoingChatHttp: Boolean,

    // enable "fake webRTC" channel to prevent browser throttling
    enableFakeWebRTC: Boolean,

    // forces the email composer to be the new slate composer
    forceEmailSlateBeta: Boolean,

    // enable features for the 2023 Gladly Connect Live (GCL) Demo
    gclDemo2023: Boolean,

    // enable Html Editor when configuring Gladly App messages
    gladAppHtmlEditor: Boolean,

    // new property 'loop the queue and hold music' on the voice editor page
    loopHoldMusic: Boolean,

    // enable upgraded FlexCard (LoopReturn)
    loopReturnsV2: Boolean,

    // whether the browser supports and the user allowed the microphone
    microphone: Boolean,

    // Activate UI to allow for configuration of SMS endpoints to be controlled by message automation.
    smsMessageAutomation: Boolean,

    // edit Glad App configuration for mobile
    sidekickMobileSDK: Boolean,

    // enable direct link to Sidekick V2
    sidekickV2: Boolean,

    // can accept/make phone calls over SIP
    sip: Boolean,

    // new version of slate
    slateBeta: Boolean,

    // can send/receive sms messages
    sms: Boolean,

    // enables UI controls that trigger AI Suggested Replies features
    suggestedReplies: Boolean,

    // enables CTI integration with third party telephony systems
    telephonyApi: Boolean,

    // testFlag is used only for testing, as feature flags should generally be short-lived
    testFlag: Boolean,

    // Twilio Umatilla edge location rollout
    twilioUmatilla: Boolean,

    // Enable endpoint cache in telephony service
    useEndpointCache: Boolean,

    // enables user survey on away page
    userSurvey: Boolean,

    // allows users to view the original html of an email via the item overflow menu
    viewOriginalEmailHtml: Boolean,

    // can accept/make phones calls over webRTC
    webRTC: Boolean,

    // can view whatsapp configuration page
    whatsAppTechProvider: Boolean,

    // adjust keepalive time for MQTT client
    doubleKeepAlive1: Boolean,
    doubleKeepAlive2: Boolean,
    tripleKeepAlive: Boolean,

    // enable mqtt server v1
    mqttServerV1: Boolean,

    /* ROLE FEATURES */

    // - can navigate to admin settings
    adminSettings: Boolean,

    // - can communicate with customers
    externalAgentActions: Boolean,

    // - can be routed conversations
    // - can work on conversations
    // - can search across all conversations
    // - can suggest new content
    internalAgentActions: Boolean,

    // can create and update campaigns
    campaignsManagement: Boolean,

    // can create and update channels
    channelManagement: Boolean,

    // can create & update custom attributes
    customAttributeManagement: Boolean,

    // can edit assignee (dedicated hero) for customer
    customerAssigneeManagement: Boolean,

    // can create and update email domains
    emailDomainsManagement: Boolean,

    // can delete communication attachments
    manageAttachments: Boolean,

    // can download and delete phone call and voicemail recordings
    manageVoiceRecordings: Boolean,

    // can view and control Mobile Phone Number Identification
    mobileNumberLookupManagement: Boolean,

    // can view group inboxes (in the dashboard)
    groupInboxes: Boolean,

    // - can create and edit routing groups
    // - can assign agents to routing groups
    inboxManagement: Boolean,

    // - can list, add, delete personal API tokens
    // - can list, add, delete webhooks
    devManagement: Boolean,

    // can view developer debugging tools
    debuggingView: Boolean,

    // - can delete profile
    // - can redact conversation item
    complianceManagement: Boolean,

    // can view and edit integrations (eg. customer lookup)
    integrationsManagement: Boolean,

    // can view and edit IVR configuration
    ivrConfigurationManagement: Boolean,

    // can listen to live calls
    listenToCalls: Boolean,

    // can edit answers/variables
    kbManagement: Boolean,

    // can build reports
    reportBuilder: Boolean,

    // can edit rules
    rulesManagement: Boolean,

    // add support for flexible cards in the admin
    adminFlexibleCardEnabled: Boolean,

    // can edit skills
    skillManagement: Boolean,

    // whether the user has logged in using SSO
    sso: Boolean,

    // can edit single sign-on configuration
    ssoConfigurationManagement: Boolean,

    // can view the existing users
    // can edit teams
    teamManagement: Boolean,

    // can create & update topics
    topicManagement: Boolean,

    // userManagement enables the following:
    // - can create and edit users (agents only)
    // - can trigger password reset for users (agents only)
    // - can edit agent channels
    userManagement: Boolean,

    // can see all routingGroup inboxes
    viewAllGroupInboxes: Boolean,

    // can view liveboards
    viewLiveboards: Boolean,

    // can view reports
    viewReports: Boolean,

    // can view webhooks admin page
    webhooks: Boolean,

    // in-app notification For Answer Threads Classroom
    atClassroomNotification: Boolean,

    // receives in-app notifications for Sidekick Customers
    sidekickNotifications: Boolean,

    // use topic IDs selector in Sidekick Threads in AddTopics action if possible
    sidekickThreadUseTopicIds: Boolean,
  },
});

const AppFeatures = createModel({
  modelName: 'AppFeatures',

  properties: {
    roleFeatures: FeatureList,
    gatekeeperFeatures: FeatureList,
    browserCapabilities: FeatureList,
  },

  replace(source, features) {
    const featureList = new FeatureList(features);
    const newAttrs = _.merge({}, this, { [source]: featureList });
    return new AppFeatures(newAttrs);
  },

  sourcesAreLoaded() {
    return _.chain(this)
      .pick(SOURCES)
      .values()
      .every()
      .value();
  },

  isEnabled(feature) {
    if (this.sourcesAreLoaded() || _.includes(IGNORE_SOURCES_LOADED, feature)) {
      return (
        _.some(SOURCES, source => this[source] && this[source][feature] === true) &&
        _.every(SOURCES, source => this[source] && this[source][feature] !== false)
      );
    }

    return false;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
    SOURCES,
  },
});

export default AppFeatures;
