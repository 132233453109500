import map from 'lodash/map';
import EmailSuggestion from 'models/email_suggestion';

export default class FetchEmailSuggestions {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (this.context.stores.appFeatures.get().isEnabled('demoMode')) {
      const agentId = this.context.stores.currentAgent.get().id;
      this.context.gateways.emailSuggestions.fetch({ agentId }).then(suggestionsDto => {
        const modelSuggestions = map(suggestionsDto, suggestion => EmailSuggestion.fromJs(suggestion));
        this.context.stores.emailSuggestions.set(modelSuggestions);
      });
    } else {
      const emailSuggestions = this.context.stores.localEmailSuggestions.get();
      const modelSuggestions = map(emailSuggestions, suggestion => EmailSuggestion.fromJs(suggestion));
      this.context.stores.emailSuggestions.set(modelSuggestions);
    }
  }
}
