import map from 'lodash/map';
import GreetingSuggestion from 'models/greeting_suggestion';

export default class FetchGreetingSuggestions {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (this.context.stores.appFeatures.get().isEnabled('demoMode')) {
      const agentId = this.context.stores.currentAgent.get().id;
      this.context.gateways.greetingSuggestions.fetch({ agentId }).then(suggestionsDto => {
        const modelSuggestions = map(suggestionsDto, suggestion => GreetingSuggestion.create(suggestion));
        this.context.stores.greetingSuggestions.set(modelSuggestions);
      });
    } else {
      const greetingSuggestions = this.context.stores.localGreetingSuggestions.get();
      const modelSuggestions = map(greetingSuggestions, suggestion => GreetingSuggestion.fromJs(suggestion));
      this.context.stores.greetingSuggestions.set(modelSuggestions);
    }
  }
}
