import PropTypes from 'prop-types';
import React from 'react';

import ChevronDownFillIcon from 'components/common/icons/fill/chevron-down-fill';
import ChevronRightFillIcon from 'components/common/icons/fill/chevron-right-fill';

export function CategoryChevron({ isOpen, toggleSelectedReportCollapse, categoryId }) {
  if (isOpen) {
    return (
      <ChevronDownFillIcon
        cursor={'pointer'}
        data-aid={`menuCategory-chevronDown-${categoryId}`}
        onClick={() => toggleSelectedReportCollapse(categoryId)}
      />
    );
  }

  return (
    <ChevronRightFillIcon
      cursor={'pointer'}
      data-aid={`menuCategory-chevronRight-${categoryId}`}
      onClick={() => toggleSelectedReportCollapse(categoryId)}
    />
  );
}

CategoryChevron.propTypes = {
  isOpen: PropTypes.bool,
  toggleSelectedReportCollapse: PropTypes.func,
  categoryId: PropTypes.string,
};
