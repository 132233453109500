import createModel, { prop } from './lib/create_model';
import { LinkedCustomerProfile } from './item_link_content';

export default createModel({
  modelName: 'LinkedItem',

  properties: {
    customerId: prop(String).required,
    customerProfile: prop(LinkedCustomerProfile),
    itemId: prop(String).required,
    timestamp: prop(String),

    isOriginal: prop(Boolean),
  },
});
