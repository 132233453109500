import _ from 'lodash';
import { createPluginFactory } from '@udecode/plate';
import { HistoryEditor } from 'slate-history';
import { produce } from 'immer';
import { ReactEditor } from 'slate-react';
import { Transforms, Range, Text } from 'slate';

import createUndoHistoryBreak from 'components/text_editor_new/lib/create_undo_history_break';
import getLastNode from 'components/text_editor_new/lib/get_last_node';

export const I_WORDS = ['i', "i've", "i'm", "i'll", "i'd"];

export default function createCapitalizeIPlugin() {
  return createPluginFactory({
    key: 'capitalize-i',

    handlers: {
      onKeyDown: editor => evt => {
        return capitalizeI(editor, evt);
      },
    },
  })();
}

export function capitalizeI(editor, evt) {
  // Composing probably means using an IME (e.g. Japanese keyboard) to type.
  if (ReactEditor.isComposing(editor)) {
    return;
  }

  let { selection } = editor;

  // We only capitalize if you're typing normally, with a collapsed selection
  if (!selection || !Range.isCollapsed(selection)) {
    return;
  }

  // We only capitalize after we hit a whitespace character.
  const charRegexp = new RegExp(/^\s$/);
  if (!charRegexp.test(evt.key)) {
    return;
  }

  const lastTextNodeEntry = getLastNode(editor, {
    at: selection,
    match: Text.isText,
  });
  if (!lastTextNodeEntry) {
    return;
  }

  const [lastTextNode] = lastTextNodeEntry;
  if (!lastTextNode || !lastTextNode.text) {
    return;
  }

  const text = lastTextNode.text;

  // Find the start of the word.. e.g. "Hi i've "
  //                                       ^
  const whitespaceRegexp = /\s/i;
  let startIndex = selection.anchor.offset;
  for (; startIndex > 0; startIndex--) {
    const char = text.charAt(startIndex - 1);
    if (whitespaceRegexp.test(char)) {
      break;
    }
  }

  if (startIndex === selection.anchor.offset || startIndex < 0) {
    return;
  }

  // Find the end of the word.. e.g. "Hi i've. "
  //                                          ^
  const alphabetOrApostropheRegexp = /[a-z']/i;
  let endIndex = startIndex + 1;
  for (; endIndex <= selection.anchor.offset; endIndex++) {
    const char = text.charAt(endIndex);
    if (!alphabetOrApostropheRegexp.test(char)) {
      break;
    }
  }

  // If there is no word then w/e
  if (endIndex - startIndex === 0) {
    return;
  }

  // Ensure that the word matches something we care about
  const word = text.slice(startIndex, endIndex);
  if (!_.includes(I_WORDS, word)) {
    return;
  }

  const capitalizeISelection = produce(selection, draft => {
    draft.anchor.offset = startIndex;
    draft.focus.offset = startIndex + 1;
  });

  // Insert the text and _then_ change the capitalization. This way, an agent can hit undo if they didn't
  // want that letter capitalized.
  evt.preventDefault();
  HistoryEditor.withoutMerging(editor, () => {
    editor.insertText(evt.key);
  });

  // Force push new undo batch so that hitting undo only un-does the capitalization.
  createUndoHistoryBreak(editor);

  let newSelection = editor.selection;
  Transforms.insertText(editor, 'I', { at: capitalizeISelection });
  Transforms.select(editor, newSelection);

  return true;
}
