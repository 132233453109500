import changeCurrentLocation from 'actions/lib/change_current_location';
import WebhookEditor from 'models/location/integrations/webhook_editor';

export default class OpenWebhook {
  constructor(context) {
    this.context = context;
  }

  run(webhookId) {
    this.context.gateways.webhook.fetchAll();
    this.context.stores.webhooks.setLoading();

    changeCurrentLocation(this.context, WebhookEditor.create({ webhookId }));
  }
}
