import ConversationItem from 'models/conversation_item';
import createModalModel, { prop } from './lib/create_modal_model';
import CreditCard from 'models/credit_card';

export default createModalModel({
  modelName: 'ShowCreditCard',

  properties: {
    creditCard: prop(CreditCard),
    item: prop(ConversationItem).isRequired,
    errorMessage: prop(String),
  },

  setCreditCard(creditCard) {
    this.creditCard = creditCard;
  },

  setErrorMessage(errMessage) {
    this.errorMessage = errMessage;
  },
});
