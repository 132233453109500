import _ from 'lodash';
import classnames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import PropTypes from 'prop-types';

import Agent from 'models/agent';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import ConversationIcon from 'components/lib/conversation_icon';
import ConversationItem from 'models/conversation_item';
import ConversationItemPreview from 'components/lib/conversation_item_preview';
import ConversationItemType from 'models/conversation_item_type';
import { MessageChannelType } from 'models/conversation_message';
import { CustomerActivityType } from 'models/customer_activity';
import { faTwitter, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTopicNameFromItem } from 'components/lib/topic_name';
import RelativeDateTime from 'components/lib/date/relative_date_time';
import RoutingGroup from 'models/routing_group';
import ShortList from 'components/common/short_list';
import Topic from 'models/topic';
import TicketIcon from 'components/lib/icons/ticket_icon';
import { StyledIconAPI } from 'components/customer/conversation_history/conversation_items_v2/metadata/api_metadata';

const { CHAT_MESSAGE, CONVERSATION_MESSAGE, CUSTOMER_ACTIVITY, PHONE_CALL, TWITTER } = ConversationItemType;
const baseClass = 'customerConversationCard-conversationInfo';

export class ConversationCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }

  render() {
    return <div className={baseClass}>{this.props.conversation ? <Body {...this.props} /> : <Empty />}</div>;
  }
}

const ConversationCardConnector = connect(mapStateToProps)(ConversationCardComponent);
export default ConversationCardConnector;

ConversationCardComponent.propTypes = {
  assignedAgent: PropTypes.instanceOf(Agent),
  assignedGroup: PropTypes.instanceOf(RoutingGroup),
  conversation: PropTypes.instanceOf(Conversation),
  conversationItem: PropTypes.instanceOf(ConversationItem),
  customChannelNames: PropTypes.object,
  hasDraft: PropTypes.bool,
  taskCount: PropTypes.number,
  topic: PropTypes.instanceOf(Topic),
};

function mapStateToProps({ getProvider }, props) {
  const customChannels = getProvider('customChannels').findAll();
  const customChannelNames = {};
  customChannels.forEach(channel => {
    customChannelNames[channel.id] = channel.name;
  });

  return {
    customChannelNames,
    ...props,
  };
}

export function Empty() {
  return <div className={cn('empty')}>No previous conversations</div>;
}

export function Body({
  assignedAgent,
  assignedGroup,
  conversation,
  conversationItem,
  customChannelNames,
  hasDraft,
  taskCount,
  topic,
  topics,
  updatedBy,
}) {
  return (
    <div className={cn('body')}>
      <div className={cn('topRow')}>
        <Title
          conversationItem={conversationItem}
          customChannelNames={customChannelNames}
          hasDraft={hasDraft}
          topic={topic}
          topics={topics}
        />
        <Sla conversation={conversation} conversationItem={conversationItem} />
        <Status conversation={conversation} />
        <LiveCall conversationItem={conversationItem} />
        <Icon conversationItem={conversationItem} />
      </div>
      <Excerpt conversation={conversation} conversationItem={conversationItem} topic={topic} />
      <Metadata
        assignedAgent={assignedAgent}
        assignedGroup={assignedGroup}
        createdAt={conversation && conversation.createdAt}
        taskCount={taskCount}
        updatedAt={conversation.updatedAt}
      />
    </div>
  );
}

Body.propTypes = ConversationCardComponent.propTypes;

export function Title({ conversationItem, customChannelNames, hasDraft, topic, topics }) {
  return (
    <div className={cn('title', 'multipleTopics')}>
      <Dotdotdot clamp={1}>
        <div className={cn('draft-topic-wrapper')}>
          <HasDraft hasDraft={hasDraft} />
          <TitleContent
            conversationItem={conversationItem}
            customChannelNames={customChannelNames}
            topic={topic}
            topics={topics}
          />
        </div>
      </Dotdotdot>
    </div>
  );
}

export function HasDraft({ hasDraft }) {
  if (!hasDraft) {
    return null;
  }
  return (
    <div className={cn('hasDraft')}>
      Draft <span className={cn('hasDraftDash')}>-</span>
    </div>
  );
}

export function TitleContent({ topic, topics, conversationItem, customChannelNames }) {
  let defaultTitle = getTopicNameFromItem(conversationItem, customChannelNames);
  let titlePlaceholder = decorateTitleWithIcon(defaultTitle, conversationItem);

  return (
    <div className={cn('titleContent', 'multipleTopics')}>
      <ShortList className={cn('multipleTopics', 'titleContent')} placeholder={titlePlaceholder} values={topics} />
    </div>
  );
}

function decorateTitleWithIcon(defaultTitle, conversationItem) {
  const initiatorType = _.get(conversationItem, 'initiator.type');
  if (initiatorType && initiatorType === Communicator.API) {
    return (
      <span className={cn('title')}>
        <span className={cn('title-api')}>
          <StyledIconAPI className={cn('title-api-icon')}>APP</StyledIconAPI>
        </span>
        {defaultTitle}
      </span>
    );
  }

  return defaultTitle;
}

export function Sla({ className, conversation, conversationItem }) {
  if (conversationItem && conversationItem.hasActivePhoneCall()) {
    return null;
  }

  const dueAt = conversation && conversation.isOpen() && conversation.dueAt;
  if (dueAt) {
    let danger = conversation.isOverdue() || conversation.isNearlyDue();
    return (
      <div className={classnames(cn('sla', { 'sla-danger': danger }), className)}>
        Due <RelativeDateTime timestamp={dueAt} />
      </div>
    );
  }

  return null;
}

export function Icon({ conversationItem }) {
  if (!conversationItem) {
    return null;
  }
  let conversationType = conversationItem.contentType();

  switch (conversationType) {
    case CHAT_MESSAGE:
      return <ChatIconOutline className="customerConversationCard-conversationInfo-chatIcon" />;
    case CONVERSATION_MESSAGE:
      return <MessageIcon conversationItem={conversationItem} />;
    case TWITTER:
      return <ChatIcon conversationItem={conversationItem} />;
    case PHONE_CALL:
      return <PhoneIcon conversationItem={conversationItem} />;
    case CUSTOMER_ACTIVITY:
      return <CustomerActivityIcon conversationItem={conversationItem} />;
  }

  return (
    <div className={cn('icon')}>
      <ConversationIcon type={conversationType} />
    </div>
  );
}

export function ChatIcon({ conversationItem }) {
  const conversationType = conversationItem.contentType();

  return (
    <div className={cn('icon', 'chatIcon')}>
      <ConversationIcon iconClassName="fa fa-lg fa-fw" type={conversationType} />
    </div>
  );
}

export function CustomerActivityIcon({ conversationItem }) {
  const conversationType = conversationItem.contentType();

  if (conversationItem.content.activityType === CustomerActivityType.ISSUE) {
    return <TicketIcon className={cn('ticketIcon')} />;
  }
  return (
    <div className={cn('icon')}>
      <ConversationIcon subType={conversationItem.content.activityType} type={conversationType} />
    </div>
  );
}

export function MessageIcon({ conversationItem }) {
  let channel = conversationItem.content.getChannelType();
  switch (channel) {
    case MessageChannelType.WHATSAPP:
      return <FontAwesomeIcon className="customerConversationCard-conversationInfo-messageIcon" icon={faWhatsapp} />;
    case MessageChannelType.TWITTER:
      return <FontAwesomeIcon className="customerConversationCard-conversationInfo-messageIcon" icon={faTwitter} />;
    case MessageChannelType.INSTAGRAM_DIRECT:
      return <FontAwesomeIcon className="customerConversationCard-conversationInfo-messageIcon" icon={faInstagram} />;
    default:
      return (
        <div className={cn('icon')}>
          <ConversationIcon type={channel} />
        </div>
      );
  }
}

export function PhoneIcon({ conversationItem }) {
  const call = conversationItem.content;
  const isLive = call.isLive() || call.isOutgoing();
  const isWaiting = call.isWaiting();
  const conversationType = conversationItem.contentType();

  return (
    <div className={cn('icon', 'phoneIcon', { isLive, isWaiting })}>
      <ConversationIcon type={conversationType} />
    </div>
  );
}

export function LiveCall({ conversationItem }) {
  if (!conversationItem || !conversationItem.hasLivePhoneCall()) {
    return null;
  }
  return <div className={cn('liveCall', 'isLive')}>Live</div>;
}

export function Excerpt({ conversationItem, conversation }) {
  let hasTopic = conversation && (conversation.topicId !== undefined || conversation.topicIds.length > 0);
  if (!conversationItem) {
    return null;
  }
  return (
    <div className={cn('excerpt')}>
      <Dotdotdot clamp={3}>
        <ConversationItemPreview conversationItem={conversationItem} hasTopic={hasTopic} />
      </Dotdotdot>
    </div>
  );
}

export function Metadata({ createdAt, assignedGroup, assignedAgent, taskCount, updatedAt }) {
  return (
    <div className={cn('metadata')}>
      <span className={cn('metadata-updatedAt')}>
        <UpdatedAt updatedAt={updatedAt} />,
      </span>
      {assignedGroup && <span className={cn('metadata-group')}> {assignedGroup.name},</span>}
      {assignedAgent && <span className={cn('metadata-agent')}> Assigned to {assignedAgent.name},</span>}
      <span className={cn('metadata-created')}>
        {' '}
        Created <RelativeDateTime timestamp={createdAt} />
      </span>
      {taskCount > 0 && (
        <span className={cn('metadata-taskCount')}>
          {' '}
          {taskCount} Task
          {taskCount > 1 ? 's' : ''}
        </span>
      )}
    </div>
  );
}

export function UpdatedAt({ updatedAt }) {
  return (
    <div className={cn('updatedAt')}>
      {'Updated '}
      <RelativeDateTime timestamp={updatedAt} />
    </div>
  );
}

export function Status({ conversation }) {
  if (!conversation) {
    return null;
  }
  return <div className={cn('status')}>{conversation.getInboxStatus()}</div>;
}

// like classnames, but prepends the base class to the result
function cn(...args) {
  let res = classnames(...args);
  return res
    .split(' ')
    .map(c => `${baseClass}-${c}`)
    .join(' ');
}
