import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import d3 from 'd3';
import moment from 'moment';
import React from 'react';

import ChartAxis from './chart_axis';
import ChartDateAxis from './chart_date_axis';
import ChartBar from './chart_bar';
import ChartLine from './chart_line';
import ChartTooltip, { ChartTooltipTitle } from './chart_tooltip';
import ReportChannel from 'models/reports/report_channel';
import ReportHelpers from './report_helpers';

import T from 'prop-types';

var SummaryChart = createReactClass({
  propTypes: {
    data: T.array.isRequired,
    width: T.number,
    channel: T.string,
  },

  statics: {
    MIN_SVG_WIDTH: 850,
    REPORT_PADDING: 50, // left & right padding
    X_OFFSET: 100, // left & right offset (space for label/axis)
    Y_OFFSET: 30, // bottom offset (space for x axis)
    CONVO_CHART_HEIGHT: 200,
    CONVO_CHART_TOP_OFFSET: 5,
    CHART_PADDING: 20, // left & right space between axis and chart
    CHART_SPACING: 50, // vertical height between top & bottom chart
    RESPONSE_CHART_HEIGHT: 160,
    MOUSEOVER_THROTTLE_INTERVAL: 10,
    roundToNearestPowerOfTen(number) {
      let nearestPower = number.toString().length - 1 || 1;
      let nearestPowerOfTen = Math.pow(10, nearestPower);
      return Math.ceil(number / nearestPowerOfTen) * nearestPowerOfTen;
    },
  },

  UNSAFE_componentWillMount() {
    this.debouncedOnMouseMove = _.throttle(clientX => {
      if (!this.state.displayTooltip) {
        this.setState({ displayTooltip: true });
      }
      if (clientX) {
        this.setTooltipInfo(clientX - SummaryChart.REPORT_PADDING);
      }
    }, SummaryChart.MOUSEOVER_THROTTLE_INTERVAL);
  },

  /* Init */
  getInitialState() {
    return {
      displayTooltip: false,
    };
  },

  /* Render */
  render() {
    this.createD3Scales();

    return (
      <div>
        <div className="summaryChart">
          <svg
            className="summaryChart-svg"
            height={this.svgSize.height}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            onMouseMove={this.onMouseMove}
            ref="summarySvg"
            width={this.svgSize.width}
          >
            {this.renderConvoChart()}
            {this.renderResponseChart()}
            {this.renderXAxis()}
            {this.renderSeparator()}
          </svg>
          {this.state.displayTooltip && this.renderTooltip()}
        </div>
      </div>
    );
  },

  renderTooltip() {
    let data = this.state.tooltipDataPoint;
    let xPos = this.xScale(data.date) + SummaryChart.REPORT_PADDING;

    let chartBottom = this.responseChartSize.height + this.responseChartSize.yOffset;

    let [lowerNum, higherNum] = d3.extent([data.newConvo, data.closedConvo]);
    let topPos = this.convoChartYScale(higherNum);

    return (
      <div className="summaryChart-tooltip">
        <div className="summaryChart-tooltip-line" style={{ top: topPos, left: xPos, height: chartBottom - topPos }} />
        {this.renderSelectedCircle(xPos, this.convoChartYScale(lowerNum))}
        {this.renderSelectedCircle(xPos, this.responseCountYScale(data.allResponses))}
        {this.renderConvoToolTip(data, xPos, topPos)}
        {this.renderResponseToolTip(data, xPos)}
      </div>
    );
  },

  renderSelectedCircle(xPos, topPosition) {
    return (
      <div className="summaryChart-tooltip-circle" style={{ left: xPos, top: topPosition }}>
        <div className="chartTooltip-circle" />
      </div>
    );
  },

  renderConvoToolTip(data, xPos, yPos) {
    return (
      <ChartTooltip className="summaryChart-chartTooltip-convo" x={xPos} y={yPos}>
        <ChartTooltipTitle>
          {ReportHelpers.toDisplayDate(data.date, this.props.aggregationLevel, true)}
        </ChartTooltipTitle>
        <div>
          <div className="summaryChart-summaryKey-color summaryChart-open-key" />
          {`${data.newConvo.toLocaleString()} created ${this.pluralize('conversation', data.newConvo)}`}
        </div>
        <div>
          <div className="summaryChart-summaryKey-color summaryChart-closed-key" />
          {`${data.closedConvo.toLocaleString()} closed ${this.pluralize('conversation', data.closedConvo)}`}
        </div>
      </ChartTooltip>
    );
  },

  renderResponseToolTip(data, xPos) {
    let percent = this.convertOverAndTotalToPercentage(data.slaResponses, data.allResponses);
    let channelLabel = this.pluralize('response', data.allResponses);
    if (this.props.channel === ReportChannel.CHAT_SESSION) {
      channelLabel = this.pluralize('chat', data.allResponses);
    } else if (this.props.channel === ReportChannel.EMAIL) {
      channelLabel = `${this.pluralize('email', data.allResponses)} responded to`;
    } else if (this.props.channel === ReportChannel.SMS) {
      channelLabel = `${this.pluralize('SMS', data.allResponses)} responded to`;
    } else if (this.props.channel === ReportChannel.FB_MESSENGER) {
      channelLabel = `${this.pluralize('FB msg', data.allResponses)} responded to`;
    } else if (this.props.channel === ReportChannel.PHONE_CALL) {
      channelLabel = `${this.pluralize('call', data.allResponses)}`;
    } else if (this.props.channel === ReportChannel.TWITTER) {
      channelLabel = `${this.pluralize('Twitter msg', data.allResponses)} responded to`;
    } else if (this.props.channel === ReportChannel.WHATSAPP) {
      channelLabel = `${this.pluralize('WhatsApp msg', data.allResponses)} responded to`;
    } else if (this.props.channel === ReportChannel.INSTAGRAM_DIRECT) {
      channelLabel = `${this.pluralize('Instagram msg', data.allResponses)} responded to`;
    }
    return (
      <ChartTooltip className="summaryChart-chartTooltip-sla" x={xPos} y={this.responsePercentYScale(percent)}>
        <div>{`${percent.toFixed(1)}% (${(data.allResponses - data.slaResponses).toLocaleString()}) within SLA`}</div>
        <div>{`${data.allResponses.toLocaleString()} ${channelLabel} total`}</div>
      </ChartTooltip>
    );
  },

  renderSeparator() {
    let yPos = this.convoChartSize.height + SummaryChart.CHART_SPACING / 2;
    return <line className="summaryChart-separator" x2={this.svgSize.width} y1={yPos} y2={yPos} />;
  },

  renderXAxis() {
    let chartsSize = {
      width: this.svgSize.width,
      height: this.svgSize.height - this.svgSize.yOffset,
      xOffset: this.svgSize.xOffset,
      yOffset: this.svgSize.yOffset,
    };

    return (
      <ChartDateAxis
        aggregationLevel={this.props.aggregationLevel}
        className="summaryChart"
        endAt={this.endDate}
        scale={this.xScale}
        size={chartsSize}
        startAt={this.startDate}
      />
    );
  },

  renderConvoChart() {
    let openConvoData = this.props.data.map(entry => [entry.date, entry.newConvo]);
    let closedConvoData = this.props.data.map(entry => [entry.date, entry.closedConvo]);

    return (
      <g>
        <ChartAxis
          className="summaryChart-chartAxis"
          isYAxis
          scale={this.convoChartYScale}
          showGridLine
          size={this.convoChartSize}
          tickFormat={num => num.toLocaleString()}
        />
        {this.renderYAxisLabel('conversations', _.omit(this.convoChartSize, 'yOffset'))}
        <ChartLine
          className="summaryChart-open"
          data={openConvoData}
          showPoints={false}
          xScale={this.xScale}
          yScale={this.convoChartYScale}
        />
        <ChartLine
          className="summaryChart-closed"
          data={closedConvoData}
          showPoints={false}
          xScale={this.xScale}
          yScale={this.convoChartYScale}
        />
      </g>
    );
  },

  renderResponseChart() {
    let slaPercentages = this.props.data.map(dataByDay => {
      let percent = this.convertOverAndTotalToPercentage(dataByDay.slaResponses, dataByDay.allResponses);
      return [dataByDay.date, percent.toFixed(1)];
    });
    let allResponses = this.props.data.map(dataByDay => [dataByDay.date, dataByDay.allResponses]);

    return (
      <g>
        <ChartAxis
          className="summaryChart-chartAxis"
          isYAxis
          scale={this.responseCountYScale}
          showGridLine
          size={this.responseChartSize}
          tickFormat={num => num.toLocaleString()}
        />
        <ChartAxis
          className="summaryChart-chartAxis-sla"
          isRightSide
          isYAxis
          numTicks={3}
          scale={this.responsePercentYScale}
          size={_.set(
            _.clone(this.responseChartSize),
            'xOffset',
            this.responseChartSize.width + this.responseChartSize.xOffset
          )}
        />
        {this.renderYAxisLabel(this.getYAxisLabel(), this.responseChartSize)}
        {this.renderYAxisLabel('% < SLA', this.responseChartSize, true, 'summaryChart-sla-line')}
        <ChartBar
          className="summaryChart-sla"
          data={allResponses}
          width={this.responseChartSize.width}
          xScale={this.xScale}
          yPos={this.responseChartSize.height + this.responseChartSize.yOffset}
          yScale={this.responseCountYScale}
        />
        <ChartLine
          className="summaryChart-sla"
          data={slaPercentages}
          showPoints={false}
          xScale={this.xScale}
          yScale={this.responsePercentYScale}
        />
      </g>
    );
  },

  renderYAxisLabel(text, chartSize, isDisplayedRight, className) {
    let yOffset = chartSize.yOffset ? chartSize.yOffset : 0;
    let xOffset = chartSize.xOffset / 4;
    let labelTransform = `translate(${isDisplayedRight ? this.svgSize.width - xOffset : xOffset}, ${yOffset +
      chartSize.height / 2}) rotate(-90)`;
    return (
      <text className={classnames('summaryChart-axis-label', className)} transform={labelTransform}>
        {text}
      </text>
    );
  },

  /* Events */
  onMouseEnter(e) {
    this.setState({ displayTooltip: true });
    this.setTooltipInfo(e.clientX);
  },

  onMouseLeave() {
    this.setState({ displayTooltip: false });
  },

  onMouseMove(evt) {
    this.debouncedOnMouseMove(evt.clientX);
  },

  /* Helpers */
  createD3Scales() {
    let { svgSize, convoChartSize, responseChartSize } = this.getSizes();
    this.svgSize = svgSize;
    this.convoChartSize = convoChartSize;
    this.responseChartSize = responseChartSize;

    this.xScale = this.getTimeScale();
    this.convoChartYScale = this.getConvoChartYScale();
    this.responsePercentYScale = this.getResponsePercentYScale();
    this.responseCountYScale = this.getResponseCountYScale();
  },

  getSizes() {
    // TODO: Extract x and y offset to CSS transforms
    let svgWidth = this.props.width - SummaryChart.REPORT_PADDING * 2;

    let svgSize = {
      width: svgWidth >= SummaryChart.MIN_SVG_WIDTH ? svgWidth : SummaryChart.MIN_SVG_WIDTH,
      height:
        SummaryChart.CONVO_CHART_HEIGHT +
        SummaryChart.RESPONSE_CHART_HEIGHT +
        SummaryChart.CHART_SPACING +
        SummaryChart.Y_OFFSET,
      xOffset: SummaryChart.X_OFFSET,
      yOffset: SummaryChart.Y_OFFSET,
    };

    let chartWidth = svgSize.width - svgSize.xOffset * 2;

    let convoChartSize = {
      width: chartWidth,
      height: SummaryChart.CONVO_CHART_HEIGHT,
      xOffset: svgSize.xOffset,
      yOffset: SummaryChart.CONVO_CHART_TOP_OFFSET,
    };

    let responseChartSize = {
      width: chartWidth,
      height: SummaryChart.RESPONSE_CHART_HEIGHT,
      xOffset: svgSize.xOffset,
      yOffset: convoChartSize.height + SummaryChart.CHART_SPACING,
    };

    return { svgSize, convoChartSize, responseChartSize };
  },

  getTimeScale() {
    let dates = this.props.data.map(entry => entry.date);

    let min = _.min(dates);
    let max = _.max(dates);

    this.startDate = min;
    this.endDate = max;

    let startX = this.svgSize.xOffset + SummaryChart.CHART_PADDING;
    let endX = this.svgSize.width - SummaryChart.CHART_PADDING - SummaryChart.X_OFFSET;

    return d3.time
      .scale()
      .domain([min, max])
      .rangeRound([startX, endX]);
  },

  getConvoChartYScale() {
    let allValues = _.flatten(this.props.data.map(entry => [entry.newConvo, entry.closedConvo]));
    let maxValue = _.max(allValues);

    return d3.scale
      .linear()
      .domain([0, SummaryChart.roundToNearestPowerOfTen(maxValue) || 10])
      .rangeRound([this.convoChartSize.height, this.convoChartSize.yOffset]);
  },

  getResponsePercentYScale() {
    let maxValue = _.max(
      this.props.data.map(entry => this.convertOverAndTotalToPercentage(entry.slaResponses, entry.allResponses))
    );
    let possibleValues = [1, 4, 10, 20, 40, 100];

    return d3.scale
      .linear()
      .domain([0, _.min(possibleValues.filter(val => maxValue <= val))])
      .rangeRound([this.responseChartSize.yOffset + this.responseChartSize.height, this.responseChartSize.yOffset]);
  },

  getResponseCountYScale() {
    let maxValue = _.max(this.props.data.map(entry => entry.allResponses));

    return d3.scale
      .linear()
      .domain([0, SummaryChart.roundToNearestPowerOfTen(maxValue) || 10])
      .rangeRound([this.responseChartSize.yOffset + this.responseChartSize.height, this.responseChartSize.yOffset]);
  },

  setTooltipInfo(mouseX) {
    let mousePositionDate = this.xScale.invert(mouseX).valueOf();

    let closestDataPoint = this.getClosestPointFromDate(mousePositionDate);
    this.setState({
      tooltipDataPoint: closestDataPoint,
    });
  },

  getClosestPointFromDate(date) {
    return _.minBy(this.props.data, point => Math.abs(moment(point.date).diff(date)));
  },

  pluralize(str, num) {
    return num === 1 ? str : `${str}s`;
  },

  convertOverAndTotalToPercentage(over, total) {
    // compute % within
    return total > 0 ? (100.0 * (total - over)) / total : 0;
  },

  getYAxisLabel() {
    switch (this.props.channel) {
      case ReportChannel.EMAIL:
        return 'emails responded to';
      case ReportChannel.PHONE_CALL:
        return 'calls';
      case ReportChannel.CHAT_SESSION:
        return 'chats';
      case ReportChannel.SMS:
        return 'SMSs responded to';
      case ReportChannel.FB_MESSENGER:
        return 'FB msgs responded to';
      case ReportChannel.TWITTER:
        return 'Twitter msgs responded to';
      default:
        return 'responses';
    }
  },
});

export default SummaryChart;
