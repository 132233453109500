import _ from 'lodash';

import { getRenderableBlocks } from 'components/customer/flexible_profile_card/lib/get_renderable_blocks';
import { isRenderableList } from 'components/customer/flexible_profile_card/lib/is_renderable_list';

/**
 * Helper function that goes through the list of "section" configurations and returns only those that have
 * at least one attribute that can be rendered (i.e. has some data for it)
 *
 * @param {object[]} sectionConfigs - list of the "section" configurations. Each section may have one or more
 *                                    "blocks" (aka "attribute groups") and is considered "renderable" if at
 *                                    least one of the blocks has at least one renderable data element (attribute)
 * @param {object} data
 * @returns {object[]}
 */
export function getRenderableSections(sectionConfigs, data) {
  if (_.isEmpty(sectionConfigs) || _.isEmpty(data)) return [];

  return _.filter(sectionConfigs, sectionCfg => {
    if (_.isEmpty(sectionCfg)) return false;

    // First, check the top-level attribute groups
    const renderableBlocks = getRenderableBlocks(sectionCfg.blocks, data);
    if (!_.isEmpty(renderableBlocks)) return true;

    // Next, check if the section has a list - and if yes, whether it is renderable
    return isRenderableList(sectionCfg, data);
  });
}
