import Html from 'slate-html-serializer-old';

import { agentMentionRules } from './rules/agent_mention_rules';
import { answerLinkRules } from './rules/answer_link_rules';
import { baseRules } from './rules/base_rules';
import { fallbackRules } from './rules/fallback_rules';
import { inlineImageRules } from './rules/inline_image_rules';
import { phraseSuggestionRules } from './rules/phrase_suggestion_rules';
import { placeholderRules } from './rules/placeholder_rules';
import { richTextRules } from './rules/rich_text_rules';
import { tableRules } from './rules/table_rules';
import { variableRules } from './rules/variable_rules';

const FullSerializer = new Html({
  rules: [
    ...baseRules,
    ...answerLinkRules,
    ...phraseSuggestionRules,
    ...inlineImageRules,
    ...placeholderRules,
    ...richTextRules,
    ...tableRules,
    ...variableRules,
    ...agentMentionRules,
    ...fallbackRules,
  ],
});

export default FullSerializer;
