import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('Rule').extend('Uuid');

Factory.define('RuleWithDefaults')
  .extend('Rule')
  .attr('name', ['id'], id => `Rule ${id}`)
  .attr('order', 1)
  .attr('disabled', false)
  .attr('event', ['event'], event => {
    return (
      event || {
        incomingCommunication: {
          startsConversation: true,
          sms: {
            body: { contains: { values: ['topic1'] } },
          },
        },
      }
    );
  })
  .attr('trigger', ['trigger'], trigger => {
    return (
      trigger || {
        type: 'INCOMING_COMMUNICATION',
      }
    );
  })
  .attr('conditions', [])
  .attr('actions', ['actions'], actions => {
    return (
      actions || {
        addTopics: { topicIds: ['topic1'] },
      }
    );
  });

Factory.define('RuleWithAgentStatusUpdateEvent')
  .extend('RuleWithDefaults')
  .option('agentStatus', 'OFFLINE')
  .attr('event', ['agentStatus'], agentStatus => {
    return { agentStatusUpdate: { agentStatus } };
  })
  .attr('trigger', ['trigger'], trigger => {
    return (
      trigger || {
        type: 'AGENT_STATUS_UPDATE',
        value: 'OFFLINE',
      }
    );
  })
  .attr('conditions', [{ type: 'CHANNELS', entity: 'CONVERSATION', value: ['MAIL'] }]);

Factory.define('RuleWithConversationDueInEvent')
  .extend('RuleWithDefaults')
  .option('dueIn', 0)
  .attr('event', ['dueIn'], dueIn => {
    return { conversationDueIn: { dueIn } };
  })
  .attr('trigger', ['trigger'], trigger => {
    return (
      trigger || {
        type: 'CONVERSATION_DUE_IN',
        value: 0,
      }
    );
  })
  .attr('conditions', [{ type: 'AGENT_STATUS', entity: 'AGENT', value: 'OFFLINE' }]);

Factory.define('RuleWithTaskDueInEvent')
  .extend('RuleWithDefaults')
  .option('dueIn', 0)
  .attr('event', ['dueIn'], dueIn => {
    return {
      taskDueIn: {
        dueIn,
      },
    };
  })
  .attr('trigger', ['trigger'], trigger => {
    return (
      trigger || {
        type: 'TASK_DUE_IN',
        value: 0,
      }
    );
  })
  .attr('conditions', [{ type: 'AGENT_STATUS', entity: 'AGENT', value: 'OFFLINE' }]);
