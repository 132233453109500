import SnippetEditor from 'models/location/snippet_editor';

export default class NavigateToSnippet {
  constructor(context) {
    this.context = context;
  }

  run(snippetId) {
    this.context.router.navigateTo(SnippetEditor.create({ snippetId }));
  }
}
