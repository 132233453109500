import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import SearchInput from 'components/common/search_input';

export class UniversalSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleClearText = this.handleClearText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <div className="universalSearch-searchInput-wrapper">
        <SearchInput
          autoFocus
          className="universalSearch-searchInput"
          delay={600}
          initialValue={this.props.initialText}
          onChange={this.handleSubmit}
          onClearText={this.handleClearText}
          placeholder={this.props.placeholder}
        />
        {this.props.shouldShowSLASort && <div className="universalSearch-results-sort">Sorted by SLA</div>}
      </div>
    );
  }

  handleClearText() {
    this.props.onSubmit('');
  }

  handleSubmit(evt) {
    this.props.onSubmit(evt.target.value);
  }
}

UniversalSearchBar.defaultProps = {
  initialText: '',
  placeholder: 'Search for customer or conversation',
};

UniversalSearchBar.propTypes = {
  initialText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  shouldShowSLASort: PropTypes.bool,
};

const UniversalSearchBarContainer = connect(mapStateToProps)(UniversalSearchBar);

function mapStateToProps({ getProvider }, { queryId }) {
  const result = getProvider('universalSearchResults').findBy({ id: queryId });
  let shouldShowSLASort = _.get(result, 'query.filter.dueAt.gte') || _.get(result, 'query.filter.dueAt.lte');

  return {
    shouldShowSLASort,
  };
}

export default UniversalSearchBarContainer;
