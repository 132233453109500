import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAirbnb } from '@fortawesome/free-brands-svg-icons';
import GenericChannelIconFillIcon from 'components/common/icons/fill/generic-channel-icon-fill';
import GenericChannelIconStrokeIcon from 'components/common/icons/stroke/generic-channel-icon-stroke';

const GenericChannelIconFillIconStyled = styled(GenericChannelIconFillIcon)`
  width: 14px;
`;

export const GenericChannelIconStrokeIconStyled = styled(GenericChannelIconStrokeIcon)`
  width: 14px;
`;

const BRANDS_ICONS = [
  {
    label: 'Airbnb',
    icon: props => {
      const nextProps = _.omit(props, ['stroke']);
      return <FontAwesomeIcon className="customChannel-brand-icon" icon={faAirbnb} {...nextProps} />;
    },
    value: 'airbnb',
  },
];

const GENERIC_ICON = {
  label: 'Generic icon',
  icon: props => {
    const nextProps = _.omit(props, ['stroke']);
    return props.stroke ? (
      <GenericChannelIconStrokeIconStyled className="customChannel-brand-icon" {...nextProps} />
    ) : (
      <GenericChannelIconFillIconStyled className="customChannel-brand-icon" {...nextProps} />
    );
  },
  value: 'generic_icon',
};

const getChannelIconsDropdownOptions = props => {
  return [...BRANDS_ICONS, { type: 'SPLITTER', label: '' }, GENERIC_ICON].map(item =>
    item.icon ? { ...item, icon: item.icon(props) } : item
  );
};

const getBrandIconByName = (iconName, props) => {
  const icon = _.find(BRANDS_ICONS, icon => icon.value === iconName);

  if (!icon) {
    return { ...GENERIC_ICON, icon: GENERIC_ICON.icon(props) };
  }

  return { ...icon, icon: icon.icon(props) };
};

export { BRANDS_ICONS, GENERIC_ICON, getChannelIconsDropdownOptions, getBrandIconByName };
