import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export default function ConversationTopicSelectionExpanderV2({
  isFocused,
  numSelections,
  onClick,
  onMouseEnter,
  option,
}) {
  const className = classnames({ 'toggle-focused': isFocused });

  return (
    <ConversationTopicSelectionToggle
      className={className}
      data-aid="conversation-menu-topic-expander"
      onMouseDown={onClick}
      onMouseEnter={handleMouseEnter}
    >
      {`+${numSelections} more`}
      <i className="conversationTopic-chevron fa fa-chevron-down" />
    </ConversationTopicSelectionToggle>
  );

  function handleMouseEnter() {
    onMouseEnter(option);
  }
}

ConversationTopicSelectionExpanderV2.propTypes = {
  isFocused: PropTypes.bool,
  numSelections: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: PropTypes.object,
};

export const ConversationTopicSelectionToggle = styled.div`
  cursor: pointer;
  height: 32px;
  padding-bottom: ${p => p.theme.spacing.medium};
  padding-left: ${p => p.theme.spacing.large};
  padding-top: ${p => p.theme.spacing.medium};
  pointer-events: auto;
  &.toggle-focused {
    background-color: ${p => p.theme.colors.gray100};
  }
`;
