import { isList } from './utils';
import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';
import { getNearestListItemFromNode } from './utils';

export function toggleList(editor, type) {
  const { value } = editor;
  const { document } = value;
  const hasList = isList(value);

  if (hasList) {
    value.blocks
      .filter(block => block.type === LIST_ITEM)
      .forEach(block => {
        const listParent = document.getClosest(
          block.key,
          parent => parent.type === UNORDERED_LIST || parent.type === ORDERED_LIST
        );
        if (listParent && listParent.type !== type) {
          editor.setNodeByKey(listParent.key, { type });
        } else {
          deepRemoveListByNode(editor, block);
        }
      });
  } else {
    editor.withoutNormalizing(() => {
      editor.setBlocks(LIST_ITEM).wrapBlock(type);
    });
  }

  return editor;
}

const MAX_ITERATIONS = 10;

export function deepRemoveList(editor) {
  // A list node might have paragraph nodes as children, in which case we need to move the children of the nested
  // node (e.g. the text nodes) to be direct children of the list node. In effect, we're unwrapping the paragraph
  // node. For some reason unknown to me, editor.unwrapNodeByKey() does _not_ work on these nested paragraphs.
  let currentNode = editor.value.blocks.first();
  let iterations = 0;
  while (currentNode && currentNode.type !== LIST_ITEM) {
    const listNode = getNearestListItemFromNode(editor, currentNode);
    listNode.nodes.forEach(node => {
      if (node.type === 'paragraph') {
        const currentListNode = editor.value.document.getNode(listNode.key);
        let index = currentListNode.nodes.count();
        node.nodes.forEach(childNode => {
          editor.moveNodeByKey(childNode.key, currentListNode.key, index);
          index++;
        });
        editor.removeNodeByKey(node.key);
      }
    });
    currentNode = editor.value.blocks.first();

    iterations++; // Failsafe: just in case this doesn't handle every possible scenario, bail out to prevent infinite loop.
    if (iterations >= MAX_ITERATIONS) {
      break;
    }
  }

  const { value } = editor;
  const { document } = value;

  const node = document.getClosest(value.selection.start.key, parent => parent.type === LIST_ITEM);
  node && deepRemoveListByNode(editor, node);
  return editor;
}

// Unwrap the list node until it's at the top level - at this point, the schema will normalize the list node
// into a paragraph node.
function deepRemoveListByNode(editor, node) {
  editor.withoutNormalizing(() => {
    while (editor.value.document.getNode(node.key) && hasListParent(editor.value.document, node)) {
      editor.unwrapBlockByKey(node.key);
    }
  });
}

function hasListParent(document, node) {
  const listParent = document.getClosest(
    node.key,
    parent => parent.type === UNORDERED_LIST || parent.type === ORDERED_LIST
  );
  return !!listParent;
}
