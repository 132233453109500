import _ from 'lodash';
import Immutable from 'immutable';

import ErrImmutableConverter from 'scripts/adapters/immutable_converters/err_converter';
import orderedMapFromEntityImmutables from 'scripts/adapters/immutable_converters/lib/ordered_map_from_entity_immutables';
import { simpleHash } from 'scripts/adapters/lib/simple_hash';

// Meta binding root paths for various store and element states
const ERROR_STATE_PATH = '_errors';
const LOADING_STATE_PATH = '_loading';
const LOADING_ERROR_PATH = 'loading.error';
const NEW_STATE_PATH = 'newEntity';
const PENDING_STATE_PATH = '_pending';
const PENDING_STATE_DEL_PATH = '_pendingDelete';

/**
 * This is the base class for both CollectionStore and MultiDocumentStore classes (which track loading states differently).
 * It is intended for subclassing and creating new purpose-built multi-element store classes (e.g. CollectionStore or
 * MultiDocumentStore class)
 */
export class CollectionStoreBase {
  constructor(binding, converter, { createProvider } = {}) {
    this.immutableStore = new ImmutableStore(binding, this._isMatch.bind(this));
    if (!createProvider) {
      createProvider = CollectionProvider.create;
    }
    this.converter = converter;
    this.provider = createProvider(this.immutableStore, { fromImmutable: this._fromImmutable.bind(this) });
  }

  get binding() {
    return this.immutableStore.binding;
  }

  getProvider() {
    return this.provider;
  }

  set(entities) {
    let newMap = this.orderedMapFromEntities(entities);
    this.immutableStore.set(newMap);
    this.getProvider().clearCache();
  }

  // converts an array of `entities` into an `OrderedMap` with the same
  // order as the passed in `entities` and indexed by `id`
  orderedMapFromEntities(entities) {
    let immutables = entities.map(this.converter.toImmutable);
    return this.orderedMapFromEntityImmutables(immutables);
  }

  // returns an OrderedMap indexed by `id` and sorted in the same order as
  // the passed in `entityImmutables`
  orderedMapFromEntityImmutables(entityImmutables) {
    return orderedMapFromEntityImmutables(entityImmutables);
  }

  add(entity) {
    let found = !this.immutableStore.findAll({ id: entity.id }).isEmpty();
    if (found) {
      throw new Error(`entity with id [${entity.id}] already exists`);
    }
    this.addOrReplace(entity);
  }

  addOrReplace(entity) {
    this.immutableStore.put(this.converter.toImmutable(entity));
  }

  replace(updatedEntity) {
    this.immutableStore.find(updatedEntity.id);
    this.addOrReplace(updatedEntity);
  }

  find(entityId) {
    return this._fromImmutable(this.immutableStore.find(entityId));
  }

  findAll(query) {
    return this.immutableStore
      .findAll(query)
      .map(imm => {
        if (query && query.select) {
          if (!_.isArray(query.select)) {
            throw new Error(`expected an array for select option, got[${query.select}]`);
          }
          return imm.filter((v, k) => query.select.includes(k)).toJS();
        }
        return this._fromImmutable(imm);
      })
      .toArray();
  }

  findBy(query) {
    return this.findAll(query)[0];
  }

  has(query) {
    return this.immutableStore.has(query);
  }

  setPending(entity) {
    this.immutableStore.setPending(this.converter.toImmutable(entity));
  }

  getPending(entityId) {
    let immutable = this.immutableStore.getPending(entityId);
    return immutable && this._fromImmutable(immutable);
  }

  isPending(entityId) {
    return !!this.immutableStore.getPending(entityId);
  }

  setPendingNew(entity) {
    this.immutableStore.setPendingNew(this.converter.toImmutable(entity));
  }

  getPendingNew() {
    let immutable = this.immutableStore.getPendingNew();
    return immutable && this._fromImmutable(immutable);
  }

  isPendingNew() {
    return !!this.immutableStore.getPendingNew();
  }

  _fromImmutable(immutableEntity) {
    return this.converter.fromImmutable(immutableEntity);
  }

  _isMatch(plainObject, query) {
    return _.isMatch(plainObject, query);
  }
}

export class CollectionProvider {
  constructor(immutableStore, converter) {
    this.immutableStore = immutableStore;
    this.converter = converter;
    this._entityCache = {};
  }

  get bindings() {
    return [this.immutableStore.binding];
  }

  count() {
    return this.immutableStore.count();
  }

  clearCache() {
    this._entityCache = {};
  }

  find(entityId) {
    return this._fromImmutableWithCache(entityId, this.immutableStore.find(entityId));
  }

  findAll(query) {
    if (query && query.select && !_.isArray(query.select)) {
      throw new Error(`expected an array for select option, got [${query.select}]`);
    }

    return this.immutableStore
      .findAll(query)
      .map((imm, entityId) => {
        if (query && query.select) {
          return imm.filter((v, k) => query.select.includes(k)).toJS();
        }
        return this._fromImmutableWithCache(entityId, imm);
      })
      .toArray();
  }

  findBy(query) {
    let found = this.findAll(query)[0];
    if (!found && _.keys(query).length === 1 && query.id) {
      this._removeFromCache(query.id);
    }

    return found;
  }

  has(query) {
    return this.immutableStore.has(query);
  }

  _fromImmutableWithCache(entityId, immutable) {
    let cacheEntry = this._entityCache[entityId];
    if (cacheEntry && cacheEntry.immutable === immutable) {
      return cacheEntry.entity;
    }

    let entity = this.converter.fromImmutable(immutable);
    this._entityCache[entityId] = { entity, immutable };
    return entity;
  }

  _removeFromCache(entityId) {
    delete this._entityCache[entityId];
  }

  getAllErrors() {
    return this.immutableStore.getAllErrors();
  }

  getErrors(entityId) {
    return this.immutableStore.getErrors(entityId);
  }

  getErrorsForNew() {
    return this.immutableStore.getErrorsForNew();
  }

  getErrorForLoading() {
    return this.immutableStore.getErrorForLoading();
  }

  getPending(entityId) {
    return this.converter.fromImmutable(this.immutableStore.getPending(entityId));
  }

  getPendingNew() {
    let immutable = this.immutableStore.getPendingNew();
    return immutable && this.converter.fromImmutable(immutable);
  }

  isPending(entityId) {
    return !!this.immutableStore.getPending(entityId);
  }

  isPendingDelete(entityId) {
    return this.immutableStore.isPendingDelete(entityId);
  }

  isPendingNew() {
    return !!this.immutableStore.getPendingNew();
  }

  isLoading() {
    return !!this.immutableStore.isLoading();
  }

  static create(immutableStore, options) {
    return new CollectionProvider(immutableStore, options);
  }
}

/**
 * Immutable store that backs both CollectionStore and MultiDocumentStore classes
 */
class ImmutableStore {
  constructor(binding, isMatch) {
    this.binding = binding;
    if (!this.binding.get()) {
      this.binding.set(Immutable.OrderedMap());
    }
    this._isMatch = isMatch;
    this._loadingStateTracker = {};
  }

  /////////////////////
  // Immutable Entity
  /////////////////////
  count() {
    let entityMap = this.binding.get();
    return entityMap ? entityMap.count() : 0;
  }

  find(entityId) {
    if (!entityId) {
      throw new Error(`entity id cannot be [${entityId}]`);
    }

    let immutable = this._findById(entityId);
    if (!immutable) {
      throw new Error(`could not find entity with id [${entityId}]`);
    }

    return immutable;
  }

  findAll(query) {
    let entries = this.binding.get();
    if (!entries) {
      return Immutable.OrderedMap();
    }

    let filter = query && (query.filter || query.select || query.sortBy) ? query.filter : query; // backward compat
    if (filter) {
      if (!_.isPlainObject(filter) && !_.isFunction(filter)) {
        throw new Error(`expected an object or function for filter option, got [${filter}]`);
      }
      let filterAttrs = _.keys(filter);
      if (filterAttrs.length === 1 && filterAttrs[0] === 'id') {
        let foundImm = this._findById(filter.id);

        return Immutable.OrderedMap(foundImm ? [[filter.id, foundImm]] : []);
      }

      entries = entries.filter(imm => (_.isFunction(filter) ? filter(imm.toJS()) : this._isMatch(imm.toJS(), filter)));
    }

    if (query && query.sortBy) {
      if (!_.isFunction(query.sortBy)) {
        throw new Error(`expected a function for sortBy option, got [${query.sortBy}]`);
      }
      entries = entries.sortBy(imm => query.sortBy(imm.toJS()));
    }

    return entries;
  }

  _findById(entityId) {
    if (!entityId) {
      return undefined;
    }
    return this.binding.get(entityId);
  }

  has(query) {
    if (query.id && _.keys(query).length === 1) {
      const imm = this.binding.get();
      return !!imm && imm.has(query.id);
    }
    throw new Error('not yet implemented. can only query "has" by id.');
  }

  put(immutable) {
    if (_(immutable.get('id')).isEmpty()) {
      throw new Error('id cannot be blank');
    }

    this.binding.update(immutable.get('id'), currentImm => {
      let newImm = immutable.merge(
        (currentImm || Immutable.Map()).filter((v, k) => [ERROR_STATE_PATH, PENDING_STATE_PATH].indexOf(k) !== -1)
      );
      return Immutable.is(newImm, currentImm) ? currentImm : newImm;
    });
  }

  remove(entityId) {
    this.binding.remove(entityId);
  }

  set(orderedMap) {
    this.binding.update(currentMap => (Immutable.is(orderedMap, currentMap) ? currentMap : orderedMap));
    this.binding.meta(NEW_STATE_PATH).remove();
  }

  /////////////////////
  // Pending Entity
  /////////////////////
  commitPending(entityId) {
    if (this.binding.get([entityId, PENDING_STATE_PATH])) {
      this.binding.set(entityId, this.binding.get([entityId, PENDING_STATE_PATH]));
      this.resetPending(entityId);
      return;
    }

    if (this.binding.get([entityId, PENDING_STATE_DEL_PATH])) {
      this.remove(entityId);
    }
  }

  getPending(entityId) {
    this.find(entityId);
    return this.binding.get([entityId, PENDING_STATE_PATH]);
  }

  resetPending(entityId) {
    this.binding.remove([entityId, PENDING_STATE_PATH]);
    this.binding.remove([entityId, PENDING_STATE_DEL_PATH]);
  }

  setPending(immutable) {
    let entityId = immutable.get('id');
    this.find(entityId);
    this.clearErrors(entityId);
    this.binding.set([entityId, PENDING_STATE_PATH], immutable);
  }

  setPendingDelete(entityId) {
    if (this.getPending(entityId)) {
      throw new Error(`cannot set pending delete while update is pending for entity [${entityId}]`);
    }
    this.clearErrors(entityId);
    this.binding.set([entityId, PENDING_STATE_DEL_PATH], true);
  }

  isPendingDelete(entityId) {
    this.find(entityId);
    return this.binding.get([entityId, PENDING_STATE_DEL_PATH]) || false;
  }

  setPendingNew(immutable) {
    let pendingNewEntity = this._pendingNewEntityBinding.get();
    if (pendingNewEntity) {
      if (pendingNewEntity.equals(immutable)) {
        return;
      }
      throw new Error(`cannot overwrite pending new entity [${pendingNewEntity.get('id')}]`);
    }

    this.clearErrorsForNew();
    this._pendingNewEntityBinding.set(immutable);
  }

  getPendingNew() {
    return this._pendingNewEntityBinding.get();
  }

  commitPendingNew() {
    let pendingNewEntity = this._pendingNewEntityBinding.get();
    if (pendingNewEntity) {
      this.binding.set(pendingNewEntity.get('id'), pendingNewEntity);
      this.resetPendingNew();
    }
  }

  resetPendingNew() {
    this._pendingNewEntityBinding.remove();
  }

  /////////////////////
  // Loading State
  /////////////////////
  /**
   * Returns loading state of a particular store element, as identified by its "entityKey". The `entityKey` may have one
   * or more attributes that are used as a "composite key" and must uniquely identify that element. If `entityKey`
   * is omitted or empty, returns the "root" store-wide loading state.
   *
   * @param {Object} [entityKey] - parameters of the entity, which loading state we are tracking (undefined or
   * empty object for store-wide state)
   * @returns {boolean}
   */
  isLoading(entityKey) {
    const keyString = this._getKeyString(entityKey);
    return this._getLoadingFlagFor(keyString);
  }

  /**
   * Returns entity keys for all tracked entities that are in "loading" state
   *
   * @return {[string]}
   */
  getLoadingStateKeys() {
    return _.keys(this._loadingStateTracker);
  }

  /**
   * Resets loading state of a particular store element, as identified by its "entityKey". The `entityKey` may have one
   * or more attributes that are used as a "composite key" and must uniquely identify that element. If `entityKey`
   * is omitted or empty, the "root" store-wide loading state will be reset.
   *
   * @param {Object} [entityKey] - parameters of the entity, which loading state we are tracking (undefined or
   * empty object for store-wide state)
   */
  resetLoading(entityKey) {
    const keyString = this._getKeyString(entityKey);
    this._clearLoadingFlagFor(keyString);
  }

  /**
   * Resets loading state for all tracked individual elements AND store-wide loading state
   */
  resetAllLoading() {
    this._clearAllLoadingFlags();
  }

  /**
   * Sets loading state of a particular store element, as identified by its "entityKey". The `entityKey` may have one
   * or more attributes that are used as a "composite key" and must uniquely identify that element. If `entityKey`
   * is omitted or empty, the "root" store-wide loading state will be set.
   *
   * @param {Object} [entityKey] - parameters of the entity, which loading state we are tracking (undefined or
   * empty object for store-wide state)
   */
  setLoading(entityKey) {
    this.clearErrorForLoading(entityKey);
    this._setLoadingFlagFor(this._getKeyString(entityKey));
  }

  /////////////////////
  // Error State
  /////////////////////
  clearErrors(entityId) {
    this.binding.remove([entityId, ERROR_STATE_PATH]);
  }

  clearErrorsForNew() {
    this._newEntityErrorBinding.remove();
  }

  getErrorsForNew() {
    return errorsFromImm(this._newEntityErrorBinding.get());
  }

  getAllErrors() {
    let entries = this.binding.get() || Immutable.OrderedMap();
    entries = entries
      .filter(immutable => immutable.has(ERROR_STATE_PATH))
      .reduce((acc, imm) => {
        acc[imm.get('id')] = errorsFromImm(imm.get(ERROR_STATE_PATH));
        return acc;
      }, {});
    return entries;
  }

  getErrors(entityId) {
    this.find(entityId);
    return errorsFromImm(this.binding.get([entityId, ERROR_STATE_PATH]));
  }

  setErrors(entityId, errors) {
    this.find(entityId);
    this.binding.set([entityId, ERROR_STATE_PATH], errorsAsImm(errors));
  }

  setErrorsForNew(errors) {
    this._newEntityErrorBinding.set(errorsAsImm(errors));
  }

  /////////////////////
  // Loading Errors
  /////////////////////

  /**
   * Returns loading error meta of a particular store element, as identified by its "entityKey". The `entityKey` may have one
   * or more attributes that are used as a "composite key" and must uniquely identify that element. If `entityKey`
   * is omitted or empty, it returns the "root" store-wide loading state.
   *
   * @param {Object} [entityKey] - parameters of the entity, which loading state we are tracking (undefined or
   * empty object for store-wide loading error)
   *
   * @returns {*}
   */
  getErrorForLoading(entityKey) {
    const metaSuffix = this._getKeyString(entityKey);
    const meta = this._getLoadingErrorMetaFor(metaSuffix).get();
    return meta && ErrImmutableConverter.fromImmutable(meta);
  }

  /**
   * Sets loading error meta for a particular store element, as identified by its "entityKey". The `entityKey` may have one
   * or more attributes that are used as a "composite key" and must uniquely identify that element. If `entityKey`
   * is omitted or empty, it sets the "root" store-wide loading state.
   *
   * @param {*} error
   * @param {Object} [entityKey] - parameters of the entity, which loading state we are tracking (undefined or
   * empty object for store-wide loading error)
   *
   * @returns {*}
   */
  setErrorForLoading(error, entityKey) {
    const metaSuffix = this._getKeyString(entityKey);
    this._getLoadingErrorMetaFor(metaSuffix).set(ErrImmutableConverter.toImmutable(error));
  }

  /**
   * Clears loading error meta for a particular store element, as identified by its "entityKey". The `entityKey` may have one
   * or more attributes that are used as a "composite key" and must uniquely identify that element. If `entityKey`
   * is omitted or empty, it clears the "root" store-wide loading state.
   *
   * @param {Object} [entityKey] - parameters of the entity, which loading state we are tracking (undefined or
   * empty object for store-wide loading error)
   */
  clearErrorForLoading(entityKey) {
    const metaSuffix = this._getKeyString(entityKey);
    this._getLoadingErrorMetaFor(metaSuffix).remove();
  }

  /**
   * Clears all tracked loading errors
   */
  clearAllErrorsForLoading() {
    this._getLoadingErrorMetaFor().remove();
  }

  _getLoadingErrorMetaFor(pathSuffix) {
    const metaPath = pathSuffix ? [LOADING_ERROR_PATH, pathSuffix].join('.') : LOADING_ERROR_PATH;
    return this.binding.meta(metaPath);
  }

  ///////////////////////////////
  // Loading State Meta Helpers
  ///////////////////////////////
  _getKeyString(entityKey) {
    return simpleHash(entityKey);
  }

  _getLoadingStateMetaFor(pathSuffix) {
    const metaPath = pathSuffix ? [LOADING_STATE_PATH, pathSuffix].join('_') : LOADING_STATE_PATH;
    return this.binding.meta(metaPath);
  }

  _setLoadingFlagFor(pathSuffix) {
    this._getLoadingStateMetaFor(pathSuffix).set(true);
    if (pathSuffix) {
      this._loadingStateTracker[pathSuffix] = true;
    }
  }

  _clearLoadingFlagFor(pathSuffix) {
    if (!pathSuffix) {
      this._getLoadingStateMetaFor().remove();
    } else if (this._loadingStateTracker[pathSuffix]) {
      this._getLoadingStateMetaFor(pathSuffix).remove();
      delete this._loadingStateTracker[pathSuffix];
    }
  }

  _clearAllLoadingFlags() {
    for (const trackedElt in this._loadingStateTracker) {
      this._getLoadingStateMetaFor(trackedElt).remove();
    }
    this._loadingStateTracker = {};
    this._getLoadingStateMetaFor().remove();
  }

  _getLoadingFlagFor(pathSuffix) {
    return !!this._getLoadingStateMetaFor(pathSuffix).get();
  }

  get _newEntityErrorBinding() {
    return this.binding.meta(`${NEW_STATE_PATH}.errors`);
  }

  get _pendingNewEntityBinding() {
    return this.binding.meta(`${NEW_STATE_PATH}.pending`);
  }
}

function errorsAsImm(errors) {
  return Immutable.List(errors.map(ErrImmutableConverter.toImmutable));
}

function errorsFromImm(errorList) {
  return errorList ? errorList.toArray().map(ErrImmutableConverter.fromImmutable) : [];
}
