import { useContext, useEffect, useState } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import createEmailTemplate from './create_email_template';
import { isEditorEmpty } from 'components/text_editor/helpers';
import { useAnswers } from 'components/text_editor/plugins/answers';
import { useVariables } from 'components/composer/contexts/variables_context';
import addSnippetAttachmentsToComposition from 'components/composer/email/add_snippet_attachments_to_composition';

export default function useEmailTemplateAndAnswers({ editorRef, onChange, value }, defaultBody) {
  const variables = useVariables();
  const [shouldPopulate, setShouldPopulate] = useState(true);
  const { composition, attachSnippetAttachments } = useContext(ComposerContext);
  const editor = editorRef.current;

  useEffect(() => {
    if (editor && isEditorEmpty(editor.value) && shouldPopulate) {
      const { selection } = createEmailTemplate(editor, defaultBody, variables);
      if (selection) {
        editor.select(selection);
      }
      onChange(editor);
      setShouldPopulate(false);

      addSnippetAttachmentsToComposition({
        attachSnippetAttachments,
        composition,
        defaultBody,
      });
    }
  }, [editor, composition, attachSnippetAttachments]);

  useAnswers(editor, onChange);
}
