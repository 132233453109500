import _ from 'lodash';
import Communicator from 'models/communicator';
import ConversationItemType from 'models/conversation_item_type';
import CreateComposition from './lib/create_composition';
import Endpoint from 'models/endpoint';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import SmsCompositionContent from 'models/composition/sms_composition_content';
import { SmsPreferenceType } from 'models/phone_number';

class CreateSmsComposition extends CreateComposition {
  constructor(context) {
    super(context, SmsCompositionContent);
  }

  _createContent(attrs) {
    const smsNumbers = this._getSmsNumbers();
    const previousSms = this._findLastSmsItem(attrs.conversationId);
    const previousCustomerSmsNumber = previousSms
      ? previousSms.initiator.type === Communicator.CUSTOMER
        ? previousSms.content.from
        : previousSms.content.to
      : null;
    const toNumber =
      previousCustomerSmsNumber && _.find(smsNumbers, { normalized: previousCustomerSmsNumber })
        ? previousCustomerSmsNumber
        : smsNumbers && smsNumbers.length
        ? smsNumbers[0].normalized
        : '';

    const conversation = this.customerStores.conversations.find(attrs.conversationId);
    let from = '';
    if (previousSms) {
      from = previousSms.initiator.type === Communicator.CUSTOMER ? previousSms.content.to : previousSms.content.from;
    }
    const potentialFroms = this._getPotentialFroms(conversation);
    if (!_.includes(potentialFroms.allFromNumbers, from)) {
      from = potentialFroms.default;
    }
    return new SmsCompositionContent({
      to: toNumber,
      from,
    });
  }

  _canCreateComposition() {
    return canCreateSmsComposition(this.context);
  }

  _getPotentialFroms(conversation) {
    let channelConfiguration = this.context.stores.channelConfiguration.get();
    let smsEndpoints = channelConfiguration ? channelConfiguration.getEndpointsOfType(Endpoint.Type.SMS) : [];
    let allFromNumbers = _.map(smsEndpoints, 'address');
    let routingGroupId = conversation.assignee.routingGroupId;

    return this._getFroms({
      allFromNumbers,
      conversation,
      endpoints: smsEndpoints,
      endpointType: Endpoint.Type.SMS,
      routingGroupId,
    });
  }

  _getSmsNumbers() {
    const { phones } = this.customerStores.profile.get();
    return _.filter(phones, 'smsPreference', SmsPreferenceType.ON);
  }

  _findLastSmsItem(conversationId) {
    const customerId = this.context.stores.currentLocation.get().customerId;
    const { conversationHistory } = this.context.stores.customers.storesFor(customerId);
    return getLatestManualItem({
      conversationHistory,
      filter: item => item.content.type === ConversationItemType.SMS,
      conversationId,
    });
  }
}

export function canCreateSmsComposition(context) {
  const hasExternalAgentActions = context.stores.appFeatures.get().isEnabled('externalAgentActions');
  const channelConfiguration = context.stores.channelConfiguration.get();
  return channelConfiguration && channelConfiguration.companySmsNumbers().length > 0 && hasExternalAgentActions;
}

export default CreateSmsComposition;
