import _ from 'lodash';

import { AnswersListType } from 'models/answer_panel';

export function getAnswersList(stores, { compositionId } = {}) {
  const search = stores.answerPanel.get().searchResult;
  const searchText = _.get(search, 'query.text') || '';
  if (searchText) {
    const searchResults = _.get(search, 'hits') || [];

    // HACK: sometimes when resetting the demo, snippets are removed from the database but _not_ the search index.
    // In this case, when the results are returned, the only thing will exist is the snippet id, everything else
    // will be blank. We will fix this eventually, but for now let's just filter out those results.
    const filteredResults = _.filter(searchResults, snippet => snippet.contents && snippet.contents.length > 0);

    return {
      type: AnswersListType.SEARCH,
      answers: filteredResults,
    };
  }

  const favoriteAnswers = stores.favoriteAnswers.findAll();
  if (favoriteAnswers.length > 0) {
    return {
      type: AnswersListType.FAVORITES,
      answers: _.sortBy(favoriteAnswers, a => a.name.toLowerCase()),
    };
  }

  const suggestedAnswers = getSuggestedAnswersList(stores, { compositionId });
  if (suggestedAnswers.length > 0) {
    return {
      type: AnswersListType.SUGGESTIONS,
      answers: suggestedAnswers,
    };
  }

  return {
    type: AnswersListType.EMPTY,
    answers: [],
  };
}

export function getSuggestedAnswersList(stores, { compositionId } = {}) {
  compositionId = compositionId || stores.currentLocation.get().currentCompositionId;
  const suggestionsForComposition = stores.answerSuggestions.findBy({ id: compositionId });
  return suggestionsForComposition ? suggestionsForComposition.suggestions : [];
}

export function getSuggestedAnswersModelVersion(stores, { compositionId } = {}) {
  compositionId = compositionId || stores.currentLocation.get().currentCompositionId;
  const suggestionsForComposition = stores.answerSuggestions.findBy({ id: compositionId });
  return (suggestionsForComposition && suggestionsForComposition.version) || '';
}
