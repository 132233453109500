import DtoConverter from 'scripts/application/dto_converters/agent_profile_converter';
import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';
import qconsole from 'scripts/lib/qconsole';
import UserEditorUpdater from './lib/user_editor_updater';

export default class AgentProfileGatewayObserver {
  constructor(context, userEditorUpdater = new UserEditorUpdater(context)) {
    this.context = context;
    this.userEditorUpdater = userEditorUpdater;
  }

  get store() {
    return this.context.stores.agentProfiles;
  }

  onFetchAllSuccess(agentProfileDtos) {
    this.store.set(agentProfileDtos.map(DtoConverter.fromDto));
    this.store.resetLoading();
    this.userEditorUpdater.update();
  }

  onBroadcast(agentProfileDto) {
    let agentProfileEntity = DtoConverter.fromDto(agentProfileDto);
    this.store.addOrReplace(agentProfileEntity);
  }

  onUpdateError(errorsDto, { agentId: userId }) {
    let errors = errorsDto.errors.map(ErrorDtoConverter.fromDto);
    this.userEditorUpdater.setErrors(userId, errors);
    this.store.resetPending(userId);
  }

  onUpdateSuccess(_, { agentId: userId }) {
    this.store.commitPending(userId);
    this.userEditorUpdater.remove(userId);
  }

  onFetchAllError(errorDto) {
    qconsole.log(`Failed to fetch agent profile ${JSON.stringify(errorDto)}`);
  }
}
