import DismissNotification from 'actions/notification/dismiss_notification';
import { notificationFromRoutingEvent } from 'actions/notification/lib/notification_converter';
import ShowConversationSystemNotification from 'actions/notification/show_conversation_system_notification';

export function createAndShowNotification(context, eventDto) {
  const notification = notificationFromRoutingEvent(eventDto, getEntities());
  context.stores.notifications.addOrReplace(notification);
  DismissNotification.schedule(context, { id: notification.id });
  context.executeAction(ShowConversationSystemNotification, notification);

  function getEntities() {
    if (!eventDto.customerId) {
      return {};
    }

    let activeSession = context.stores.activeSessions.findBy({ id: eventDto.queueItemId });
    return { customer: activeSession?.customer };
  }
}
