export default class UpdateConversationItem {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationItemId, itemAttrs }) {
    if (!customerId) {
      return;
    }

    this.context.gateways.customerHistory.updateConversationItem(customerId, conversationItemId, itemAttrs);
  }
}
