import createLocationModel, { prop } from './lib/create_location_model';

export default createLocationModel({
  modelName: 'CustomerCombine',
  properties: {
    customerId: prop(String).isRequired,
    currentConversationId: String,
  },

  statics: {
    breadcrumb: 'Merge Customers',
  },
});
