import qconsole from 'scripts/lib/qconsole';
import HandleCustomerSuggestions from 'actions/external_customer_lookup/handle_customer_suggestions';

const pendingSuggestionsRequests = {};

export default class ExternalCustomerLookupGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get searchResultStore() {
    return this.context.stores.externalCustomerSearchResults;
  }

  handleFetchSuccess({ fetchResultDto, correlationId }) {
    this.context.executeAction(HandleCustomerSuggestions, {
      correlationId,
      resultsByIntegrationId: fetchResultDto,
    });
  }

  handleFetchError({ errorsDto, correlationId }) {
    this.context.executeAction(HandleCustomerSuggestions, { correlationId, errors: errorsDto });
    qconsole.error(`Could not fetch external profiles ${JSON.stringify(errorsDto)}`);
  }

  static getPendingSuggestionRequest(correlationId) {
    return pendingSuggestionsRequests[correlationId];
  }

  static setPendingSuggestionRequest(correlationId, suggestionRequest) {
    pendingSuggestionsRequests[correlationId] = suggestionRequest;
  }

  static removePendingSuggestionRequest(correlationId) {
    delete pendingSuggestionsRequests[correlationId];
  }
}
