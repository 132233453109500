import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const PhoneControlsActionContainerButtonContainer = ({ children, className }) => {
  return <div className={classnames('phoneControlsActionContainer-buttonContainer', className)}>{children}</div>;
};

PhoneControlsActionContainerButtonContainer.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default PhoneControlsActionContainerButtonContainer;
