import PropTypes from 'prop-types';
import React from 'react';

import NotificationContentContainer from './notification_content_container';
import TaskNotificationModel from 'models/notification/task_notification';

class TaskNotification extends React.Component {
  render() {
    const notification = this.props.notification;
    const content = notification.conversationItem.content.note;
    const heading = getHeading(notification);
    return (
      <NotificationContentContainer
        body={content}
        containerClasses="taskNotification"
        contentClasses="taskNotificationContent"
        heading={heading}
        iconClass="icon-task-filled"
        itemType="Task"
        notification={notification}
      />
    );
  }
}

function getHeading(notification) {
  return notification.conversationItem.content.hasNotifiedDue ? 'Assigned to you and due now' : 'Assigned to you';
}

TaskNotification.propTypes = {
  notification: PropTypes.oneOfType([PropTypes.instanceOf(TaskNotificationModel)]).isRequired,
};

export default TaskNotification;
