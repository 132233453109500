import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/common/button';

const AttachmentButton = createReactClass({
  propTypes: {
    allowedFileTypes: PropTypes.string,
    'data-aid': PropTypes.string,
    buttonComponent: PropTypes.any,
    buttonText: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    multiple: PropTypes.bool,
    onAttach: PropTypes.func.isRequired,
    onAttachBlur: PropTypes.func,
  },

  getDefaultProps() {
    return {
      multiple: true,
      labelClassName: 'draftStylesMenu-item',
    };
  },

  focus() {
    this.button.focus();
  },

  onAttach(event) {
    this.input.value = '';
    this.input.click();
    event.preventDefault();
  },

  render() {
    const ButtonComponent = this.props.buttonComponent || StyledAttachmentButton;
    return (
      <label className={this.props.labelClassName} key="label" title="Attachment">
        <input
          accept={this.props.allowedFileTypes}
          className="attachmentInput"
          multiple={this.props.multiple}
          onChange={this.props.onAttach}
          ref={input => (this.input = input)}
          style={{ display: 'none' }}
          type="file"
        />
        <ButtonComponent
          className={classnames({ 'fa fa-paperclip fa-flip-horizontal': !this.props.buttonText }, this.props.className)}
          data-aid={this.props['data-aid'] || 'attachmentsButton'}
          onBlur={this.props.onAttachBlur}
          onClick={this.onAttach}
          ref={button => (this.button = button)}
          role="button"
          tabIndex="-1"
        >
          {this.props.buttonText}
        </ButtonComponent>
      </label>
    );
  },
});

export const StyledAttachmentButton = styled(Button)`
  background: transparent;
  border: none;
  color: inherit;
  height: 25px;
  margin: 0;
  padding: 0;
  width: 25px;
  &:focus {
    border: 1px solid ${p => p.theme.colors.gray200};
    border-radius: 4px;
  }
  &:hover {
    background: transparent;
  }
`;

export default AttachmentButton;
