import _ from 'lodash';
import Dotdotdot from 'react-dotdotdot';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import connect from 'components/lib/connect';
import { default as ConversationModel } from 'models/conversation';
import CustomerContext from 'components/customer/customer_context';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import ShortLongDate from 'components/lib/date/short_long_date';
import useExecuteAction from 'components/hooks/use_execute_action';

export function Conversation({ conversation, topicNames }) {
  const { customerId } = useContext(CustomerContext);
  const executeAction = useExecuteAction();
  const onClickConversation = () => {
    executeAction(NavigateToConversation, {
      anchorPosition: conversation.status !== 'CLOSED' ? undefined : 'top',
      customerId,
      conversationId: conversation.id,
    });
  };

  const today = new Date();
  const dateCreated = new Date(conversation.createdAt);
  const displayFullMonth = dateCreated.getFullYear() === today.getFullYear();

  const label = getLabel() || '';
  const displayTooltip = label.length > 25; // One line fits approx. 25 characters

  return (
    <div className="conversationNav-conversation" onClick={onClickConversation}>
      <div className="conversationNav-conversation-icon">
        <FontAwesomeIcon icon={faCalendarAlt} />
      </div>
      <div className="conversationNav-conversation-timestamp">
        <ShortLongDate displayFullMonth={displayFullMonth} timestamp={conversation.createdAt} />
      </div>
      <div className="conversationNav-conversation-label" title={displayTooltip ? label : null}>
        <Dotdotdot clamp={3}>{label}</Dotdotdot>
      </div>
    </div>
  );

  function getLabel() {
    if (!topicNames.length) {
      return _.upperFirst(conversation.status.toLowerCase());
    }
    return topicNames.join(', ');
  }
}

Conversation.propTypes = {
  conversation: PropTypes.instanceOf(ConversationModel),
  customerId: PropTypes.string.isRequired,
  onClickConversation: PropTypes.func.isRequired,
  topicNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function mapStateToProps({ getProvider }, { conversation }) {
  const customAttributesProvider = getProvider('customAttributes');
  const topicsProvider = getProvider('topics');
  let topicNames = [];

  if (conversation) {
    const topics = conversation.topicIds.map(id => topicsProvider.findBy({ id })?.name).filter(Boolean);

    const customAttributeNames = conversation.customAttributes
      .map(attr => {
        const customAttribute = customAttributesProvider.findBy({ id: attr.id });
        return customAttribute ? `${customAttribute.label} > ${attr.value}` : null;
      })
      .filter(Boolean);

    topicNames = [...topics, ...customAttributeNames].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  return {
    conversation,
    topicNames,
  };
}

const ConversationContainer = connect(mapStateToProps)(Conversation);

ConversationContainer.propTypes = {
  conversation: PropTypes.instanceOf(ConversationModel).isRequired,
  customerId: PropTypes.string.isRequired,
};

export default ConversationContainer;
