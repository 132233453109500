import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Attachment from 'models/attachment';
import { H3 } from 'components/common/headers';
import Spinner from 'components/common/spinner_two';
import useWindowSize from 'components/hooks/use_window_size';

export default function AttachmentModalContent({ attachment, url }) {
  if (attachment.isRedacted) {
    return <RedactedContent />;
  }
  return attachment.isImage() ? <ImageContent key={attachment.id} url={url} /> : <GenericContent />;
}

AttachmentModalContent.propTypes = {
  attachment: PropTypes.instanceOf(Attachment).isRequired,
  url: PropTypes.string.isRequired,
};

export function RedactedContent() {
  return (
    <Redacted>
      <FontAwesomeIcon icon={faTrashAlt} size="2x" />
      <Text>This item was deleted.</Text>
    </Redacted>
  );
}

const Redacted = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 16px;
`;
const Text = styled.div`
  margin-top: 8px;
  text-align: center;
`;

export function ImageContent({ url }) {
  const [imageDimensions, setImageDimensions] = useState(null);
  const imgRef = useRef(null);

  const spinner = imageDimensions ? null : (
    <SpinnerBox>
      <Spinner />
    </SpinnerBox>
  );

  useFitImage(imgRef, imageDimensions);

  const setScaledImageDimensions = useCallback(
    ({ target: img }) => {
      setImageDimensions({ height: img.naturalHeight, width: img.naturalWidth });
    },
    [setImageDimensions]
  );

  return (
    <React.Fragment>
      {spinner}
      <Image isLoaded={!!imageDimensions} onLoad={setScaledImageDimensions} ref={imgRef} src={url} />
    </React.Fragment>
  );
}

function useFitImage(imgRef, imageDimensions) {
  const [shouldUpdateDimensions, setShouldUpdateDimensions] = useState({});

  // When the window size changes, first we update the image back to fit the available space by resetting its image and
  // height back to default. Then we re-render and force the layout effect below to rescale the image.
  const { windowHeight, windowWidth } = useWindowSize();
  useEffect(() => {
    if (imgRef) {
      imgRef.current.style.height = `inherit`;
      imgRef.current.style.width = `inherit`;
      setShouldUpdateDimensions({});
    }
  }, [imgRef, windowHeight, windowWidth]);

  // The image will use the flex-grow strategy to take up the available space (such that the modal takes up 80%
  // height / width). But this means the image will appear to be the ratio of the window.. so we scale either the
  // width or height down so the image maintains its original ratio (yet still fits within the bounds of the)
  // parent element.
  useLayoutEffect(() => {
    if (imgRef && imageDimensions) {
      const { height, width } = imgRef && imgRef.current.getBoundingClientRect();
      const imageRatio = imageDimensions.width / imageDimensions.height;
      const actualRatio = width / height;

      if (actualRatio > imageRatio) {
        imgRef.current.style.width = `${Math.round(imageRatio * height)}px`;
      } else {
        imgRef.current.style.height = `${Math.round(width / imageRatio)}px`;
      }
    }
  }, [imageDimensions, shouldUpdateDimensions]);
}

ImageContent.propTypes = {
  url: PropTypes.string.isRequired,
};

export function GenericContent() {
  return (
    <Generic>
      <Generic>No preview available</Generic>
    </Generic>
  );
}

const SpinnerBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 16px;

  min-width: 240px;
  height: 160px;
`;

export const Image = styled.img`
  background-color: white;
  display: ${p => (p.isLoaded ? 'block' : 'none')};
  min-height: 1px;
  object-fit: contain;
  padding: 16px;
  position: relative;
`;

const Generic = styled(H3)`
  align-items: center;
  color: ${p => p.theme.colors.gray500};
  margin-bottom: 0;
  padding: 32px;
  justify-content: center;
`;
