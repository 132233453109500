export default class RedactAttachment {
  constructor(context) {
    this.context = context;
  }

  run({ attachmentId, customerId, itemId }) {
    const { conversationHistory } = this.context.stores.customers.storesFor(customerId);
    const item = conversationHistory.findBy({ id: itemId });
    item.content.redactAttachment(attachmentId);
    conversationHistory.setPending(item);
    this.context.gateways.customerHistory.updateAttachment(customerId, itemId, attachmentId, { isRedacted: true });
  }
}
