import analytics from 'scripts/lib/analytics';

export default class ObserverListen {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationItemId }) {
    let phoneCallItem = this.context.stores.conversationHistory.find(conversationItemId);
    let currentAgent = this.context.stores.currentAgent.get();

    let agentParticipantIds = phoneCallItem.content.getAgentParticipantIdsOnCall();
    if (agentParticipantIds.length === 0) {
      // No agent currently on the call
      return;
    }

    if (!phoneCallItem.content.isParticipantObserver(currentAgent.id)) {
      return;
    }

    this.context.gateways.phoneControlsHttp.observerListen(customerId, conversationItemId, {
      participantId: currentAgent.id,
    });

    analytics.track('Hero Coaching - Observer Listening', {
      customerId,
      conversationItemId,
    });
  }
}
