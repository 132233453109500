import _ from 'lodash';
import { useCallback, useContext, useEffect, useRef } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import { ExecuteActionContext } from 'components/lib/connect';
import OpenAnswerPanel from 'actions/answers/open_answer_panel';

export function ToggleAnswerPanelPlugin({ openAnswerPanel }) {
  return {
    onKeyDown(evt, editor, next) {
      if (evt.key === '/' && (evt.metaKey || evt.ctrlKey)) {
        editor.blur();
        openAnswerPanel();
        return;
      }

      return next();
    },
  };
}

export function useToggleAnswerPanel() {
  const { composition } = useContext(ComposerContext);
  const attrs = _.pick(composition, ['conversationId', 'customerId']);
  attrs.compositionId = _.get(composition, 'id');

  const executeAction = useContext(ExecuteActionContext);
  const attrsRef = useRef(attrs);
  useEffect(() => {
    attrsRef.current = attrs;
  }, [attrs.compositionId, attrs.conversationId, attrs.customerId]);

  const openAnswerPanel = useCallback(() => {
    executeAction(OpenAnswerPanel, attrsRef.current);
  }, []);

  return ToggleAnswerPanelPlugin({ openAnswerPanel });
}
