import _ from 'lodash';

/**
 * A base action class for updating the customer profile
 * Extending classes must provide implementation of ```_update``` and ```_getValidationErrors``` methods.
 */
export default class {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    _.forEach(attrs, function(value, key) {
      if (typeof value === 'string') {
        attrs[key] = value.trim();
      }
    });

    const validationErrors = this._getValidationErrors(attrs);
    if (validationErrors.length) {
      return this.context.stores.profile.setErrors(validationErrors);
    } else {
      const profile = this.context.stores.profile.get();
      if (!profile || !this.currentCustomerId) {
        return;
      }

      const updatedAttributes = this._update(profile, attrs);
      this.context.stores.profile.setPending(profile);

      // We return the promise to support unit tests
      return this.context.gateways.customerProfile
        .update({ customerId: this.currentCustomerId }, updatedAttributes)
        .catch(err => {
          if (this._shouldIgnoreResponseError(err, updatedAttributes)) return;
          throw err;
        });
    }
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }

  /**
   * Returns validation errors for the specified attributes.
   * @returns an array of Err objects or an empty array if there were no errors.
   *
   * Subclasses must provide an implementation.
   */
  _getValidationErrors(attrs) {
    throw new Error('_getValidationErrors not implemented');
  }

  /**
   * This is a hookup that allows to selectively ignore errors returned by Supernova instead of triggering
   * a sentry alert. By default, all errors are reported. The subclass (specific implementation) may choose
   * what to do in each particular situation by overriding this method
   *
   * @param err - the response error, usually one of the `GladlyError` subclasses (e.g. DomainError etc.)
   * @param requestData - the original request attributes
   * @returns {boolean}
   */
  _shouldIgnoreResponseError(err, requestData) {
    return false;
  }

  /**
   * Updates the CustomerProfile with attributes and returns effectively updated attributes.
   * @returns an object containing effectively updated attributes for sending to the gateway.
   *
   * Subclasses must provide an implementation.
   */
  _update() {
    throw new Error('_update is not implemented');
  }
}
