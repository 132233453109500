import _ from 'lodash';
import { produce } from 'immer';
import { Transforms, Editor, Node, Path, Range } from 'slate';

import { stripZeroWidthCharacters } from 'components/text_editor_new/lib/strip_zero_width_characters';

// If our selection ends up inside a matching node, move the selection so that it completely
// surrounds the matching node.
// Usually, this is used in the `onChange` callback for a plugin.
export default function ensureFullSelection(editor, matcher) {
  const { selection } = editor;

  const nodePaths = [];
  for (const ip of Editor.nodes(editor, {
    match: n => matcher(n),
  })) {
    nodePaths.push(ip);
  }

  if (!nodePaths.length) {
    return;
  }

  // In the case of a collapsed selection, startInline and endInline will be the same.
  // If the selection starts and ends in two different nodes, then they will be different.
  const [firstNode, firstPath] = nodePaths[0];
  const [lastNode, lastPath] = _.last(nodePaths);

  const start = Range.start(selection);
  const end = Range.end(selection);

  // Get the last child of the last node - we will need it in order to calculate the selection for
  // the cases when the `lastNode` has multiple non-empty children (as in case with placeholders)
  const firstNodeText = Node.string(firstNode);
  const lastNodeText = Node.string(lastNode);
  const [, firstChildPath] = firstNode.children?.length ? Node.first(editor, firstPath) : [];
  const [lastChild, lastChildPath] = lastNode.children?.length ? Node.last(editor, lastPath) : [];

  // We consider the starts and ends to be "inside" if they're not at the edges.. e.g. "|text" is not inside,
  // but "t|ext" is.
  const isStartInNode =
    start.offset > 0 && start.offset !== firstNodeText.length && Path.compare(start.path, firstPath) === 0;

  const isEndInNode = end.offset > 0 && Path.compare(end.path, lastPath) === 0;

  // The update selection should include the node at the beginning and end of the selection (if the selection
  // is collapsed, these will be the same node).
  const isForward = Range.isForward(selection);
  const updatedSelection = produce(selection, draft => {
    if (isStartInNode) {
      draft[isForward ? 'anchor' : 'focus'].offset = 0;
      if (firstChildPath) {
        // If we have children, move selection to the beginning of the very first child
        draft[isForward ? 'anchor' : 'focus'].path = firstChildPath;
      }
    }
    if (isEndInNode) {
      const endNodeText = lastChild ? Node.string(lastChild) : lastNodeText;
      const offset = stripZeroWidthCharacters(endNodeText).length;
      if (lastChildPath) {
        // When the last node has children, we need to move the path to the very last child and set the
        // offset to the *printable* length of the child (discarding "zero-length" entities) - otherwise
        // we are flirting with exception because Slate goes by what is rendered in the DOM => it cannot
        // map zero-length characters onto the nodes
        draft[isForward ? 'focus' : 'anchor'].path = lastChildPath;
      }
      draft[isForward ? 'focus' : 'anchor'].offset = offset;
    }
  });

  // If the selection hasn't changed, then we don't need to force the issue
  if (Range.equals(updatedSelection, selection)) {
    return;
  }

  Transforms.setSelection(editor, updatedSelection);
}
