import createModel from './lib/create_model';

const NoReplyNeeded = createModel({
  modelName: 'NoReplyNeeded',

  properties: {},

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default NoReplyNeeded;
