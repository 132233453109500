import connect from 'components/lib/connect';
import FbSticker from './fb_sticker';

function mapStateToProps(context, props) {
  function getStickerUrl() {
    let orgId = context
      .getProvider('auth')
      .get()
      .getOrgId();
    return `/api/facebook/v1/orgs/${orgId}/attachments/${props.attachment.id}`;
  }

  return {
    stickerUrl: getStickerUrl(),
  };
}

export default connect(mapStateToProps)(FbSticker);
