import { Editor, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { ELEMENT_LIC } from '@udecode/plate';

import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getFirstNode from 'components/text_editor_new/lib/get_first_node';
import { focusFirstPlaceholder } from 'components/text_editor_new/plugins/placeholders';
import { stripZeroWidthCharacters } from 'components/text_editor_new/lib/strip_zero_width_characters';

export function insertAnswer(editor, plugins, variablesRef, html, meta) {
  const onMalformedFragment = () => {
    ErrorReporter.reportError(new Error('New Slate: probably malformed answer'), {
      message: 'Unable to insert answer due to Slate not parsing it correctly',
      extra: meta,
    });
  };

  // Remove zero-width characters from the markup as they may result in slate-react exceptions
  const filtered = stripZeroWidthCharacters(html);
  const fragment = editor.getSaturatedFragment(filtered, onMalformedFragment);
  if (!fragment.length) return;

  ReactEditor.focus(editor);

  // If we insert an answer and undo when there's nothing else in the editor, the selection can
  // become null. Maybe a Slate bug? Anyways, just focus the first node in the editor and select
  // that in that case.
  if (!editor.selection) {
    const entry = Editor.first(editor, []);
    Transforms.select(editor, Editor.range(editor, entry[1]));
  }

  // Slate makes it difficult to know the exact range of where a fragment was inserted, so we manually
  // figure it out ourselves by finding the nearest block of start of the current selection.
  // Below, we get the first text node in that block after inserting the framgent, and use the path to
  // that text node as our "start" point of the fragment insertion.
  const [nearestBlock, nearestBlockPath] = Editor.above(editor, {
    at: editor.selection.anchor,
    match: n => Editor.isBlock(editor, n),
  });

  // Plate's <LIC> does some weird things that may break `Transforms.insertFragment` under certain conditions.
  // Because of that, we have to wrap the fragment into an adjacent <LIC> element, otherwise adding unwrapped
  // nodes may result in exception
  if (nearestBlock?.type === ELEMENT_LIC) {
    Transforms.insertNodes(editor, { type: ELEMENT_LIC, children: fragment });
  } else {
    Transforms.insertFragment(editor, fragment);
  }

  // If we insert an answer with a placeholder in it, focus the first placeholder in the inserted
  // fragment.
  const newSelection = editor.selection;
  const searchForFirstTextSelection = {
    anchor: { offset: 0, path: nearestBlockPath },
    focus: editor.selection.focus,
  };
  const firstNodeEntry = getFirstNode(editor, { at: searchForFirstTextSelection, match: Text.isText });

  if (!firstNodeEntry) return;
  const [, firstTextPath] = firstNodeEntry;

  const startPoint = { path: firstTextPath, offset: 0 };
  const endPoint = Editor.end(editor, newSelection);

  focusFirstPlaceholder(editor, { at: { anchor: startPoint, focus: endPoint } });
}
