import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 12 12" {...props}>
    <g className="informationIcon" fillRule="evenodd">
      <path
        d="M6.342 10.91C3.392 10.91 1 8.536 1 5.61 1 2.686 3.392.314 6.342.314c2.95 0 5.343 2.372 5.343 5.298 0 2.927-2.392 5.3-5.343 5.3zm0-10.156C3.638.754 1.445 2.93 1.445 5.61c0 2.683 2.193 4.857 4.897 4.857 2.705 0 4.897-2.174 4.897-4.856C11.22 2.945 9.033.79 6.34.79V.754z"
        fillRule="nonzero"
        strokeWidth="0.2"
      />
      <text
        fontFamily="HelveticaNeue, Helvetica Neue"
        fontSize="7.2"
        letterSpacing=".216"
        strokeWidth="0.2"
        transform="translate(1)"
      >
        <tspan x="4.5" y="8">
          i
        </tspan>
      </text>
    </g>
  </IconContainer>
);
