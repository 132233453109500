import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';
import styled from 'styled-components';

import ActionMixin from '../lib/action_mixin';
import SharedBindingsMixin from '../lib/shared_bindings_mixin';

import T from 'prop-types';

const EmptyInboxWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const EmptyInbox = createReactClass({
  mixins: [ActionMixin, Morearty.Mixin, SharedBindingsMixin],

  propTypes: {
    className: T.string,
    status: T.string.isRequired,
  },

  statics: {
    MESSAGE_TEXT: {
      GROUP: {
        NEW: 'Your team does not have any new conversations',
        OPEN: (
          <p>
            Great job! This inbox does not have any customers <br /> who are waiting for responses from your team.
          </p>
        ),
        WAITING: 'You are not waiting for responses from any customers in this inbox.',
        CLOSED: 'This team has not yet closed any customer conversations. Time to get busy!',
      },
    },
  },

  /* rendering */
  render() {
    let classes = classnames('emptyInbox', this.props.className);
    return (
      <EmptyInboxWrapper>
        <div className={classes}>
          {this.renderIcon()}
          {this.renderMessage()}
        </div>
      </EmptyInboxWrapper>
    );
  },

  renderIcon() {
    return (
      <div className="emptyInbox-icon">
        {' '}
        <div className={`emptyInbox-icon-${this.props.status.toLowerCase()}`} />{' '}
      </div>
    );
  },

  renderMessage() {
    return (
      <div className="emptyInbox-message">
        <div className="emptyInbox-message-main">No {this.props.status.toLowerCase()} conversations</div>
        <div className="emptyInbox-message-detail">{EmptyInbox.MESSAGE_TEXT['GROUP'][this.props.status]}</div>
      </div>
    );
  },
});

export default EmptyInbox;
