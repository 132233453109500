import _ from 'lodash';

import ConversationConverter from 'scripts/application/dto_converters/conversation_converter';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import CurrentCompositionUpdater from '../lib/current_composition_updater';
import CustomerMergeConverter from 'scripts/application/dto_converters/customer_merge_converter';
import EmailMessage from 'models/email_message';
import EmailStatus from 'models/conversation_item/email_status';
import Err from 'models/err';
import ErrDtoConverter from 'scripts/application/dto_converters/err_converter';
import FetchCommentsAndFollowersForTask from 'actions/task/fetch_comments_and_followers_for_task';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import { getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';
import ItemId from 'models/item_id';
import OpenOrRefreshCommentPanel from 'actions/task/open_or_refresh_comment_panel';
import qconsole from 'scripts/lib/qconsole';
import RequestAgentRead from 'actions/conversation/request_agent_read';
import RequestItemIds from 'actions/customer/request_item_ids';
import TaskAnalyticsLocations from 'actions/conversation_item/task/task_analytics_locations';
import trackPlaintextEmailDisplayed from 'actions/customer/lib/track_plaintext_email_displayed';
import tryUpdateInboxItem from 'actions/inbox/lib/try_update_inbox_item';

export default class CustomerHistoryGatewayObserver {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
    this.silentErrorHandler = new GatewayErrorSilentHandler(context);
    this.interactiveErrorHandler = new GatewayErrorInteractiveHandler(context);
    this.ITEM_CHUNK_SIZE = 40; // to make testing easier 🤫
  }

  /* Conversations */
  handleAddConversationSuccess({ customerId }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received conversation add success for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    let store = this._getCustomerStores(customerId).conversations;
    store.commitPendingNew();
  }

  handleAddConversationErrors({ customerId, errorDtos }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received conversation add error for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    let store = this._getCustomerStores(customerId).conversations;

    if (store.isPendingNew()) {
      store.setErrorsForNew(errorDtos.errors.map(ErrDtoConverter.fromDto));
      store.resetPendingNew();
    }
  }

  handleReceiveConversations({ customerId, conversationsDto }) {
    if (!this._isCustomerLoaded(customerId)) {
      return;
    }

    let customerStores = this._getCustomerStores(customerId);
    conversationsDto.forEach(conversationDto =>
      this._addOrReplaceConversation({ customerId, conversationDto, customerStores })
    );

    let latestConversationId = getLatestConversationId(customerStores.conversations);
    if (latestConversationId) {
      this._fetchCurrentItems(customerId, latestConversationId);
      const latestConversation = customerStores.conversations.findBy({ id: latestConversationId });
      tryUpdateInboxItem(this.context, { conversation: latestConversation });
    } else {
      customerStores.conversationHistory.resetLoading();
    }
    this._resetLoadingConversations(customerId, customerStores);
    customerStores.conversations.clearErrorForLoading();
    this.currentCompositionUpdater.update();

    if (latestConversationId) {
      this.context.executeAction(RequestAgentRead, {
        agentId: this.context.stores.currentAgent.get().id,
        conversationId: latestConversationId,
        customerId,
      });
    }
  }

  handleFetchConversationsError({ customerId, error }) {
    this._getCustomerStores(customerId).conversations.setErrorForLoading(
      new Err({ code: Err.Code.UNEXPECTED_ERROR, detail: error.message })
    );
    this._getCustomerStores(customerId).conversations.resetLoading();
    this._getCustomerStores(customerId).conversationHistory.resetLoading();

    this.silentErrorHandler.handleCommonErrors({ customerId }, error);
  }

  _fetchCurrentItems(customerId, conversationId) {
    this.context.gateways.customerHistory.fetchCurrentConversationItems(customerId, conversationId);
  }

  handleReceiveCurrentConversationItems({ customerId, currentItemsDto }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received current conversation items for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    let customerStores = this._getCustomerStores(customerId);
    let conversationItemsDto = _(currentItemsDto)
      .values()
      .flatten()
      .uniq()
      .filter(item => item)
      .value();

    this._loadConversationItems({ customerId, conversationItemsDto, customerStores });
    this._loadOpenTasks({ currentItemsDto, customerStores });
    this._loadActiveSecureDataRequests({ currentItemsDto, customerStores });

    customerStores.activeSecureDataRequests.resetLoading();
    customerStores.conversationHistory.clearErrorForLoading();
    customerStores.conversationHistory.resetLoading();

    this.currentCompositionUpdater.update();

    if (currentItemsDto.latestCustomerItem) {
      const latestCustomerItemId = currentItemsDto.latestCustomerItem.id;
      const latestCustomerItem = customerStores.conversationHistory.findBy({ id: latestCustomerItemId });
      tryUpdateInboxItem(this.context, { item: latestCustomerItem });
    }
  }

  handleFetchCurrentConversationItemsError({ customerId, conversationId, error }) {
    this._getCustomerStores(customerId).conversationHistory.setErrorForLoading(
      new Err({ code: Err.Code.UNEXPECTED_ERROR, detail: error.message })
    );
    this._getCustomerStores(customerId).conversationHistory.resetLoading();
    this.silentErrorHandler.handleCommonErrors({ customerId, conversationId }, error);
  }

  handleUpdateConversation({ customerId, conversationDto }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(
        `Received conversation update [${conversationDto.id}] for unloaded customer [${customerId}]. Ignoring.`
      );
      return;
    }

    let customerStores = this._getCustomerStores(customerId);
    this._addOrReplaceConversation({ customerId, conversationDto, customerStores });
  }

  handleUpdateConversationSuccess({ customerId, conversationId }) {
    if (!this._isCustomerLoaded(customerId)) {
      return;
    }

    let store = this._getCustomerStores(customerId).conversations;
    let pendingConversation = store.getPending(conversationId);
    if (!pendingConversation) {
      return;
    }

    let existingConversation = store.findBy({ id: conversationId });
    if (!existingConversation || pendingConversation.version >= existingConversation.version) {
      store.commitPending(conversationId);
    } else {
      store.resetPending(conversationId);
    }
  }

  handleUpdateConversationError({ customerId, conversationId, correlationId, error }) {
    if (!this._isCustomerLoaded(customerId)) {
      return;
    }

    let store = this._getCustomerStores(customerId).conversations;
    if (store.isPending(conversationId)) {
      store.resetPending(conversationId);
    }
    this.interactiveErrorHandler.handleCommonErrors({ correlationId, customerId, conversationId }, error);
  }

  handleUpdateConversationTopicIdsError({ customerId, conversationId, error }) {
    this.interactiveErrorHandler.handleCommonErrors({ customerId, conversationId }, error);
  }

  handleUpdateConversationTopicIdsSuccess({ customerId, conversationId }) {}

  handleReconnect() {}

  _getConversationHistory(customerStores) {
    return customerStores.conversationHistory.getProvider().findAll();
  }

  _addOrReplaceConversation({ customerId, conversationDto, customerStores }) {
    let conversation = ConversationConverter.fromDto(conversationDto);
    customerStores.conversations.addOrReplace(conversation);
  }

  _getCustomerStores(customerId) {
    return this.context.stores.customers.storesFor(customerId);
  }

  _resetLoadingConversations(customerId, customerStores) {
    customerStores.conversations.resetLoading();
  }

  _filterConversationItems(conversationItemsDto) {
    return _.filter(conversationItemsDto, ci => {
      return ci.content && ci.content.type in ConversationItemType;
    });
  }

  handleConversationsFetchError({ customerId, errorDto }) {
    qconsole.log(`Failed to fetch conversations for customer ${customerId}: ${JSON.stringify(errorDto)}`);
  }

  handleDeleteConversation({ customerId, conversationId }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received conversation delete [${conversationId}] for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    let customerStores = this.context.stores.customers.storesFor(customerId);
    customerStores.conversations.remove(conversationId);
  }

  /* Conversation Items */

  handleReceiveConversationItems({ customerId, conversationItemsDto }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received conversation items for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    let customerStores = this._getCustomerStores(customerId);
    this._loadConversationItems({ customerId, conversationItemsDto, customerStores });
  }

  handleFetchConversationItemsError({ customerId, error }) {
    this._getCustomerStores(customerId).conversationHistory.setErrorForLoading(
      new Err({ code: Err.Code.UNEXPECTED_ERROR, detail: error.message })
    );
    this._getCustomerStores(customerId).conversationHistory.resetLoading();
    this.silentErrorHandler.handleCommonErrors({ customerId }, error);
  }

  _loadActiveSecureDataRequests({ currentItemsDto, customerStores }) {
    const activeSecureDataRequestsStore = customerStores.activeSecureDataRequests;

    _.each(currentItemsDto.activeSecureDataRequests, ci => {
      activeSecureDataRequestsStore.addOrReplace(ConversationItem.fromJs(ci));
    });
  }

  _loadOpenTasks({ currentItemsDto, customerStores }) {
    let conversationHistoryStore = customerStores.conversationHistory;
    let openTasks = _.map(currentItemsDto.openTasks, ci => ConversationItem.fromJs(ci));
    if (conversationHistoryStore.count() === 0) {
      conversationHistoryStore.set(openTasks);
    } else {
      _.forEach(openTasks, otci => conversationHistoryStore.addOrReplace(otci));
    }
  }

  _loadConversationItems({ customerId, conversationItemsDto, customerStores }) {
    let conversationHistoryStore = customerStores.conversationHistory;
    let filteredItems = this._filterConversationItems(conversationItemsDto);
    let conversationItems = filteredItems.reduce((memo, conversationItem) => {
      try {
        return memo.concat(ConversationItem.fromJs(conversationItem));
      } catch (e) {
        this.context.errorReporter.reportError(e, {
          tags: { customerId, conversationItemId: conversationItem.id },
          extra: { conversationItem, customerId },
          message: 'Skipped conversation item for customer',
        });
        return memo;
      }
    }, []);
    if (conversationHistoryStore.count() === 0) {
      conversationHistoryStore.set(conversationItems);
    } else {
      _.forEach(conversationItems, item => conversationHistoryStore.addOrReplace(item));
    }

    this._handleTasks({ items: filteredItems });
  }

  _handleTasks({ items }) {
    _.forEach(items, i => {
      if (
        _.get(i, 'content.type') === ConversationItemType.TASK ||
        (_.get(i, 'content.type') === ConversationItemType.ITEM_LINK &&
          _.get(i, 'content.originalContent.type') === ConversationItemType.TASK)
      ) {
        this.context.executeAction(FetchCommentsAndFollowersForTask, { taskId: i.id });
        if (this._isCurrentTask(i.id)) {
          this.context.executeAction(OpenOrRefreshCommentPanel, {
            itemId: i.id,
            location: TaskAnalyticsLocations.DIRECT_LINK,
          });
        }
      }
    });
  }

  handleUpdateConversationItem({ customerId, conversationItemDto }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(
        `Received conversation item [${conversationItemDto.id}] for unloaded customer [${customerId}]. Ignoring.`
      );
      return;
    }

    let customerStores = this._getCustomerStores(customerId);
    let conversationHistoryStore = customerStores.conversationHistory;
    let conversationItem;
    try {
      conversationItem = ConversationItem.fromJs(conversationItemDto);
    } catch (e) {
      this.context.errorReporter.reportError(e, {
        tags: { customerId, conversationItemId: conversationItemDto.id },
        extra: { conversationItem: conversationItemDto },
        message: 'Ignored conversation item for customer',
      });
      return;
    }

    if (
      conversationItem.content instanceof EmailMessage &&
      conversationItem.content.getStatus() === EmailStatus.SENDING
    ) {
      const oldConversationItem = conversationHistoryStore.findBy({ id: conversationItem.id });
      trackPlaintextEmailDisplayed(oldConversationItem, conversationItem);
    }

    conversationHistoryStore.addOrReplace(conversationItem);
    conversationHistoryStore.resetPending(conversationItem.id);
    this._handleItemId(customerStores.itemIds, conversationItem);
    this._handleTasks({ customerId, items: [conversationItemDto] });
  }

  _handleItemId(itemIdsStore, item) {
    const itemId = new ItemId({
      id: item.id,
      conversationId: item.conversationId,
      timestamp: item.timestamp,
    });
    itemIdsStore.addOrReplace(itemId);
  }

  handleDeleteConversationItem({ customerId, conversationItemId }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(
        `Received conversation item delete [${conversationItemId}] for unloaded customer [${customerId}]. Ignoring.`
      );
      return;
    }

    let customerStores = this.context.stores.customers.storesFor(customerId);
    customerStores.conversationHistory.remove(conversationItemId);
    customerStores.itemIds.remove(conversationItemId);
  }

  /* Customer items */

  handleAddItemSuccess({ customerId }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received item add success for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    let store = this._getCustomerStores(customerId).conversationHistory;
    store.commitPendingNew();
  }

  handleAddItemErrors({ customerId, errorDtos }) {
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received item add error for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    let store = this._getCustomerStores(customerId).conversationHistory;
    if (store.isPendingNew()) {
      store.setErrorsForNew(errorDtos.errors.map(ErrDtoConverter.fromDto));
      store.resetPendingNew();
    }
  }

  /* Merge */

  handleCustomerMerge(mergeDto) {
    this.context.stores.conversationMerge.commitPending();

    const conversationHistoryMerge = CustomerMergeConverter.fromDto(mergeDto);
    const customerId = conversationHistoryMerge.destCustomerId;

    if (!customerId || !this._isCustomerLoaded(customerId)) {
      qconsole.log(`Received merge for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    this.context.gateways.customerHistory.requestConversations(customerId);
    this.context.executeAction(RequestItemIds, { customerId });
  }

  _isCurrentTask(itemId) {
    let currentLocation = this.context.stores.currentLocation.get();
    const currentItemId = currentLocation.currentConversationItemId;
    return currentItemId && currentItemId === itemId;
  }

  _isCustomerLoaded(customerId) {
    return this.context.stores.customers.has({ id: customerId });
  }
}
