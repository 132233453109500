import _ from 'lodash';

import analytics from 'scripts/lib/analytics';
import changeComposition from 'actions/composition/lib/change_composition';
import { checkAndResetNavigatingToNext } from 'actions/conversation/lib/conversation_workflow';
import Conversation from 'models/conversation';
import ConvItemDtoConverter from 'scripts/application/dto_converters/conversation_item_converter';
import createConversationStatusChangeItem from 'scripts/domain/factories/conversation_item/create_conversation_status_change_item';
import createEnum from 'scripts/lib/create_enum';
import CustomerView from 'models/location/customer_view';
import { hasActivePhoneCall } from 'scripts/application/lib/conversation_history_helpers';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import Inbox from 'models/location/inbox';
import { InitiatorType } from 'actions/conversation/lib/get_next_assignment_for_channel';
import removeComposition from 'actions/composition/lib/remove_composition';
import removeItemFromInbox from 'actions/inbox/lib/remove_item_from_inbox';
import ServerClock from 'scripts/application/lib/server_clock';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import UpdateAgentRead from 'actions/conversation/update_agent_read';

export const AssignmentReason = createEnum('EXISTING_OFFERED_ASSIGNMENT');
export const AssignmentStatus = createEnum('ASSIGNED', 'NOT_ASSIGNED');
export const CapacityStatus = createEnum('CAPACITY_MAX_REACHED', 'CAPACITY_NOMINAL_REACHED');

export function closeConversation(context, { conversationId, customerId, agentId }) {
  const conversationClosedItem = createConversationStatusChangeItem({
    conversationId,
    agentId,
    customerId,
    status: Conversation.Status.CLOSED,
  });

  clearCompositions(context, conversationId, customerId);
  markConversationAsRead(context, conversationId, customerId);

  return updateConversationWithItems(context, {
    conversationId,
    customerId,
    conversationItems: [conversationClosedItem],
    conversationUpdates: {
      id: conversationId,
      status: Conversation.Status.CLOSED,
    },
  }).then(res => {
    removeItemFromInbox(context, customerId);

    analytics.track('Conversation Closed', {
      customerId,
      conversationId,
    });
    return res;
  });
}

/**
 *
 * @param context
 * @param agentId
 * @param conversationId
 * @returns {Promise} returns a promise with the results from calling the gateway. errors will get rethrown and is the caller's responsibility to catch and handle
 */
export function requestNextConversation(context, { agentId, conversationId }) {
  analytics.track('Work Requested');
  // exit if a call is active or being offered
  let activeCall = context.stores.activeCall.get();
  if (activeCall) {
    return Promise.resolve();
  }

  let conversationWorkflow = context.stores.conversationWorkflow.get();
  let currentLocation = context.stores.currentLocation.get();
  let currentCustomerId = currentLocation instanceof CustomerView ? currentLocation.customerId : undefined;

  conversationWorkflow.setNavigatingNextConversation();
  context.stores.conversationWorkflow.set(conversationWorkflow);

  if (conversationId) {
    markConversationAsRead(context, conversationId);
  }

  return context.gateways.agentNextAssignment
    .add({ agentId }, { initiatorType: InitiatorType.AGENT, currentCustomerId })
    .then(response => {
      if (_.isEmpty(response)) {
        navigateToInbox(context, emptyQueueMessage);
      } else if (
        response.status === AssignmentStatus.NOT_ASSIGNED &&
        response.capacityStatus === CapacityStatus.CAPACITY_MAX_REACHED
      ) {
        navigateToInbox(context, capacityMaxReachedMessage);
      } else if (
        response.status === AssignmentStatus.NOT_ASSIGNED &&
        response.reason === AssignmentReason.EXISTING_OFFERED_ASSIGNMENT
      ) {
        // Don't navigate to inbox if there's a pending offer.
        checkAndResetNavigatingToNext(context);
      } else if (response.status !== AssignmentStatus.ASSIGNED) {
        ErrorReporter.reportError(new Error('unexpected response for agent next assignment'), {
          message: 'failed to handle agent next assignment',
          extra: {
            response,
          },
        });
        context.executeAction(ShowToast, {
          type: ToastType.ERROR,
          message: 'There was an error getting your next conversation. Please try again.',
        });
      }

      return response;
    })
    .catch(err => {
      checkAndResetNavigatingToNext(context);
      throw err;
    });
}

function navigateToInbox(context, message) {
  checkAndResetNavigatingToNext(context);

  let activeCall = context.stores.activeCall.get();
  if (!activeCall) {
    context.executeAction(ShowToast, message);
  }

  context.router.navigateTo(Inbox.create());
}

export const capacityMaxReachedMessage = Object.freeze({
  id: 'CAPACITY_MAX_REACHED',
  message: "You've reached the maximum limit of customers that can be assigned at one time",
  type: ToastType.INFO,
});

export const emptyQueueMessage = Object.freeze({
  id: 'EMPTY_QUEUE',
  message: "Nice! You've helped all the customers for now.",
  type: ToastType.SUCCESS,
});

export function updateConversationWithItems(
  context,
  { conversationId, customerId, conversationItems, conversationUpdates }
) {
  customerId = customerId || context.stores.currentLocation.get().customerId;

  const { conversations } = context.stores.customers.storesFor(customerId);
  let conversation = conversations.find(conversationId);

  let oldConversationStatus = conversation.status;
  let effectiveUpdates = conversation.update(conversationUpdates);

  conversationItems.forEach(ci => {
    context.stores.conversationHistory.add(ci);
  });

  let conversationItemsDto = conversationItems.map(ci => {
    return ConvItemDtoConverter.toDto(ci);
  });

  // If the operation is Reopen Conversation, mark the conversation as pending and wait for the server response
  if (oldConversationStatus === Conversation.Status.CLOSED && effectiveUpdates.status === Conversation.Status.OPEN) {
    conversations.setPending(conversation);
  } else {
    conversations.replace(conversation);
  }

  return context.gateways.customerHistory.updateConversation(
    customerId,
    conversationId,
    _.merge(conversationUpdates, {
      id: conversationId,
      newConversationItems: conversationItemsDto,
    })
  );
}

export function getConversationFlags(context, { conversationId, customerId }) {
  const { conversations, conversationHistory } = context.stores.customers.storesFor(customerId);

  const conversation = conversations.find(conversationId);
  const hasSla = conversation.hasSla();
  const hasActiveCall = hasActivePhoneCall({ conversationHistory, conversationId });

  const areTopicsRequired = context.stores.conversationWorkflowConfig.get().requireTopics;
  const needsTopics = areTopicsRequired && !conversation.topicIds.length && !conversation.customAttributes.length;

  return {
    hasActiveCall,
    hasSla,
    needsTopics,
    status: conversation.status,
  };
}

function clearCompositions(context, conversationId, customerId) {
  const store = getCompositionsStore(context, customerId);
  let compositions = store.findAll({ conversationId });
  compositions.forEach(c => removeComposition(context, c));

  store.clearErrorsForNew();
  changeComposition(context, null);
}

function markConversationAsRead(context, conversationId, customerId) {
  let actionAttrs = {
    agentReadAttrs: { readTo: ServerClock.toISOString() },
    conversationId,
  };

  if (customerId) {
    actionAttrs.customerId = customerId;
  }

  context.executeAction(UpdateAgentRead, actionAttrs);
}
