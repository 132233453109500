import _ from 'lodash';

import createBlankError from '../factories/error/create_blank_error';
import createModel from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

var prop = createModel.prop;

var CommunicationQueue = createModel({
  modelName: 'CommunicationQueue',

  properties: {
    id: prop(String).isRequired,

    name: prop(String), // optional friendly name
    endpointId: prop(String).isRequired,
    routingSelector: prop(String), // abitrary content to handle additional routing within an
    // endpoint, e.g.:
    //   - an IVR or extension within a phone number endpoint

    routingGroupId: prop(String).isRequired,
    slaSeconds: prop(Number),
  },

  setName(name) {
    this.name = name;
  },

  setRoutingGroupId(routingGroupId) {
    this.routingGroupId = routingGroupId;
  },

  setSlaSeconds(slaSeconds) {
    this.slaSeconds = slaSeconds;
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },
    getValidationErrors,
  },
});

function getValidationErrors(props) {
  var errors = [];
  ['endpointId', 'routingGroupId'].forEach(function(field) {
    if (!props[field] || !props[field].length) {
      errors.push(createBlankError(field));
    }
  });

  return errors;
}

export default CommunicationQueue;
