import ConversationItemType from 'models/conversation_item_type';
import CreateChatComposition from './create_chat_composition';
import CreateComposition from './lib/create_composition';
import CreateEmailComposition from './create_email_composition';
import CreateFbMessageComposition from './create_fb_message_composition';
import CreateNoteComposition from './create_note_composition';
import CreatePhoneCallComposition from './create_phone_call_composition';
import CreateSmsComposition from './create_sms_composition';
import CreateTweetComposition from './create_tweet_composition';
import CreateWhatsAppComposition from './create_whatsapp_composition';

import { getLatestManualCustomerConversationItemAttrs } from 'scripts/application/lib/conversation_history_helpers';
import { getActiveChatSessionIdFromHistory } from 'actions/communication_session/session_finder';
import InteractionType from 'models/interaction_type';
import { MessageChannelType } from 'models/conversation_message';
import CreateInstagramComposition from './create_instagram_composition';

export default class ActivateDefaultResponse extends CreateComposition {
  _createContent() {
    return this.content.createResponse();
  }

  run({ conversationId }) {
    let customerId = this.context.stores.currentLocation.get().customerId;

    const sessionId = getSessionId(this.customerId, this.context, this.customerStores);
    if (sessionId) {
      this.context.executeAction(CreateChatComposition, {
        conversationId,
        sessionId,
      });
      return;
    }

    let { conversationHistory, conversations } = this.context.stores.customers.storesFor(customerId);

    let conversation = conversations.findBy({ id: conversationId });
    if (!conversation) {
      return;
    }

    let latestCustomerAttrs = getLatestManualCustomerConversationItemAttrs({
      conversationHistory,
      conversationId: conversation.id,
      fields: ['content'],
    });
    let latestCustomerContent = latestCustomerAttrs && latestCustomerAttrs.content;
    let contentType = latestCustomerContent && latestCustomerContent.type;
    if (contentType === ConversationItemType.CONVERSATION_MESSAGE) {
      contentType = latestCustomerContent.channel;
    }

    if (!this.context.stores.appFeatures.get().isEnabled('externalAgentActions')) {
      this.context.executeAction(CreateNoteComposition, { conversationId });
      return;
    }

    // WebForms default to emails
    if (contentType === ConversationItemType.WEB_FORM || contentType === ConversationItemType.EMAIL) {
      this.context.executeAction(CreateEmailComposition, { conversationId });
      return;
    }

    if (contentType === ConversationItemType.SMS) {
      this.context.executeAction(CreateSmsComposition, { conversationId });
      return;
    }

    if (contentType === ConversationItemType.TWITTER) {
      let customerTwitterHandle = latestCustomerContent && latestCustomerContent.meta['handle'];
      let customerTwitterAddress = latestCustomerContent && latestCustomerContent.customerAddress;
      this.context.executeAction(CreateTweetComposition, {
        conversationId,
        customerTwitterHandle,
        customerTwitterAddress,
      });
      return;
    }

    if (contentType === ConversationItemType.VOICEMAIL) {
      this.context.executeAction(CreatePhoneCallComposition, {
        conversationId,
        phoneNumber: latestCustomerContent.customerNumber,
      });
      return;
    }

    if (contentType === MessageChannelType.INSTAGRAM_DIRECT) {
      this.context.executeAction(CreateInstagramComposition, { conversationId });
      return;
    }

    if (
      contentType === ConversationItemType.FB_MESSAGE_INCOMING ||
      contentType === ConversationItemType.FB_MESSAGE_OUTGOING
    ) {
      this.context.executeAction(CreateFbMessageComposition, { conversationId });
      return;
    }

    // Generic message items will rely on the channel type
    if (contentType === MessageChannelType.WHATSAPP) {
      this.context.executeAction(CreateWhatsAppComposition, { conversationId });
      return;
    }

    // default is to create a note, even when no conversationHistory
    this.context.executeAction(CreateNoteComposition, { conversationId });
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }
}

export function getSessionId(customerId, context, customerStores) {
  let activeSession = context.stores.activeSessions.findBy({ customer: { id: customerId } });
  const chatSession = activeSession && activeSession.queueItem.getSessionByType(InteractionType.CHAT);
  return chatSession ? chatSession.id : getActiveChatSessionIdFromHistory(customerStores);
}
