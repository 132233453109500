import _ from 'lodash';

import { getAllLinkedAnswerIds } from 'models/answers/snippet_helpers';

export default class SetAnswerView {
  constructor(context) {
    this.context = context;
  }

  run({ snippet }) {
    if (!snippet) {
      return;
    }

    this.context.stores.snippetView.set(snippet);
    this.context.stores.snippetLinks.set([]);

    let snippetLinkIds = getAllLinkedAnswerIds(snippet);
    let answerPanelState = this.context.stores.answerPanelState.get();
    let answerPanelStateHistory = answerPanelState && answerPanelState.history;
    snippetLinkIds = _.compact([].concat(snippetLinkIds, answerPanelStateHistory));

    if (snippetLinkIds.length) {
      this.context.stores.snippetLinks.setLoading();
      this.context.gateways.snippet.fetchAll({ 'f.ids': snippetLinkIds });
    }
  }
}
