import _ from 'lodash';

import createModel from './lib/create_model';

var PhoneCallback = createModel({
  modelName: 'PhoneCallback',

  properties: {
    phoneNumber: String,
    requestedTime: String,
    notes: String,
  },

  withRequestedTime(time) {
    return this._withAttributes({ requestedTime: time });
  },

  withNotes(notes) {
    return this._withAttributes({ notes });
  },

  _withAttributes(attrs) {
    return new PhoneCallback(_.merge({}, this, attrs));
  },

  statics: {
    create(attrs) {
      var d = this.getDefaultRequestTime();
      return new this(_.merge({ requestedTime: d.toISOString() }, attrs));
    },

    // round to the next 15 minutes
    getDefaultRequestTime() {
      var d = new Date();
      var mins = d.getMinutes() + 5;
      var next15 = Math.ceil(mins / 15) * 15;
      d.setMinutes(next15, 0, 0);
      return d;
    },
  },
});

export default PhoneCallback;
