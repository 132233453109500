import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const WidgetTypes = createEnum('SCREENMEET');

export const WidgetConfigParameter = createModel({
  modelName: 'WidgetConfigParameter',
  properties: {
    param: prop(String).isRequired,
    value: prop(String).default(''),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const WidgetConfigSettings = createModel({
  modelName: 'WidgetConfigSettings',
  properties: {
    loaderUrl: prop(String).isRequired,
    pluginUrl: prop(String).default(''),
    permissions: prop([String]).default([]),
    parameters: prop([WidgetConfigParameter]).default([]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default createModel({
  modelName: 'WidgetConfiguration',
  properties: {
    id: prop(String).isRequired,
    name: prop(String).isRequired,
    type: prop(String).isRequired,
    enabled: prop(Boolean).default(false),
    publicToken: prop(String).isRequired,

    settings: prop(WidgetConfigSettings).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
