import AddObserverToCall from 'models/modal/add_observer_to_call';
import AnswerPreview from './answer_preview';
import AttachmentPreview from './attachment_preview';
import AgentStatusReason from './agent_status_reason';
import CloseConversationWarning from './close_conversation_warning';
import CreditCardAmount from './credit_card_amount';
import CustomReportingError from './custom_reporting_error';
import DeleteCustomer from './delete_customer';
import DeleteVoiceRecording from './delete_voice_recording';
import MergeProfile from './merge_profile';
import RedactConversationItem from './redact_conversation_item';
import ReopenConversation from './reopen_conversation';
import ScheduleRecurringReport from './schedule_recurring_report';
import ShowCreditCard from './show_credit_card';
import TaskConfirmation from './task_confirmation';
import UnlinkProfile from './unlink_profile';
import UploadCsv from './upload_csv';
import WebhookVerification from './webhook_verification';

let modalClasses = [
  AddObserverToCall,
  AnswerPreview,
  AttachmentPreview,
  AgentStatusReason,
  CloseConversationWarning,
  CreditCardAmount,
  CustomReportingError,
  DeleteCustomer,
  DeleteVoiceRecording,
  MergeProfile,
  RedactConversationItem,
  ReopenConversation,
  ScheduleRecurringReport,
  ShowCreditCard,
  TaskConfirmation,
  UnlinkProfile,
  UploadCsv,
  WebhookVerification,
];

export default Object.freeze(modalClasses.map(c => [c.typeName, c]));
