import bowser from 'bowser';

// These are "browsers" and bots that are used for synthetic monitoring
const MONITORS = ['pingdom'];

const matchUserAgent = match => window.navigator.userAgent.toLowerCase().includes(match.toLowerCase());

export const IS_FIREFOX = matchUserAgent('firefox');

/**
 * Checks whether the current browser is one of the known synthetic monitors
 * @return {boolean}
 */
export function isMonitorBot() {
  return MONITORS.some(monitor => matchUserAgent(monitor));
}

/**
 * Checks whether the current browser is officially supported
 * @return {boolean}
 */
export function isValidBrowser() {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.satisfies({
    desktop: {
      chrome: '>1',
    },
  });
}

/**
 * Checks whether the browser supports "Weak References" (supported since Chrome 84). Since we transpile down
 * to Chrome 78 (because of Pingdom), we have to be careful not to use WeakRef in Pingdom browsers
 */
export function isWeakRefSupported() {
  return typeof WeakRef === 'function';
}

/**
 * Checks whether we are running on Mac (macOS)
 *
 * @returns {boolean}
 */
export function isMacOS() {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.getOSName(true) === 'macos';
}

/**
 * Checks whether we are running on Windows
 *
 * @returns {boolean}
 */
export function isWindows() {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.getOSName(true) === 'windows';
}
