import createModel, { prop } from '../lib/create_model';
import LiveboardType from 'models/liveboards/liveboard_type';
import RuleScore from 'models/rule_score';

const SmartMatchEntry = createModel({
  modelName: 'SmartMatchEntry',

  properties: {
    agentId: prop(String).isRequired,
    conversationId: prop(String).isRequired,
    customerId: prop(String).isRequired,
    customerName: prop(String).isRequired,
    createdAt: prop(String).isRequired,
    ruleScores: prop([RuleScore]).default([]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default createModel({
  modelName: 'SmartMatchLiveboard',

  properties: {
    entries: prop([SmartMatchEntry]).default([]),
    timezone: prop(String).default('America/New_York'),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: LiveboardType.PEOPLE_MATCH });
  },
});
