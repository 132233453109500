import { createContentModel } from 'models/composition/composition_content_shared';

const CoBrowseCompositionContent = createContentModel({
  modelName: 'CoBrowseCompositionContent',

  properties: {
    conversationId: String,
  },

  isEmpty() {
    return false;
  },
});

export default CoBrowseCompositionContent;
