import moment from 'moment';

import createModel, { prop } from 'models/lib/create_model';
import NotificationType from './notification_type';

export const embedTokenExpiringNotificationTimerDelaySeconds = 300; // 5 minutes

export default createModel({
  modelName: 'EmbedTokenExpiringNotification',

  properties: {
    id: prop(String).isRequired,
    tokenExpiry: prop(String).isRequired,
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.EMBED_TOKEN_EXPIRING });
  },

  statics: {
    create({ id, now }) {
      const tokenExpiry = moment(now)
        .add(embedTokenExpiringNotificationTimerDelaySeconds, 'seconds')
        .toISOString();
      return new this({ id, tokenExpiry });
    },
  },
});
