import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function FlippableChevron({ className, isDownwards, onClick }) {
  const classNames = classnames('conversationItem-flippableChevron', 'ion-chevron-up', className, {
    'conversationItem-flippableChevron-downwards': isDownwards,
  });
  return <i className={classNames} onClick={onClick} onMouseDown={onMouseDown} />;

  function onMouseDown(e) {
    e.preventDefault(); // Prevents selecting text if you click too much
  }
}

FlippableChevron.propTypes = {
  className: PropTypes.string,
  isDownwards: PropTypes.bool,
  onClick: PropTypes.func,
};
