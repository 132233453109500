import createModel, { prop } from 'models/lib/create_model';

const ExpiringUrl = createModel({
  modelName: 'ExpiringUrl',
  properties: {
    url: prop(String),
    expiry: prop(String),
  },
});

export default ExpiringUrl;
