import React, { useCallback } from 'react';

import connect from 'components/lib/connect';
import { Item, Text, Timestamp } from './shared_notification';
import MarkAgentNotificationAsRead from 'actions/agent_notifications/mark_agent_notification_as_read';
import NavigateToTaskComment from 'actions/task/navigate_to_task_comment';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function AgentMentionedInTaskComment({ agentName, notification }) {
  const onClick = useOnClick(notification);

  return (
    <Item onClick={onClick}>
      <Text hasRead={notification.hasRead()}>{agentName} mentioned you in a task comment</Text>
      <Timestamp timestamp={notification.createdAt} />
    </Item>
  );
}

const AgentMentionedInTaskCommentContainer = connect(mapStateToProps)(AgentMentionedInTaskComment);

function mapStateToProps({ getProvider }, { notification }) {
  const agent = getProvider('agents').findBy({ id: notification.initiator.id });

  return {
    agentName: agent ? agent.getDisplayName() : 'An agent',
  };
}

function useOnClick(notification) {
  const { id, content } = notification;

  const executeAction = useExecuteAction();
  return useCallback(() => {
    executeAction(MarkAgentNotificationAsRead, { id });
    executeAction(NavigateToTaskComment, {
      commentId: content.taskCommentId,
      customerId: content.customerId,
      taskId: content.taskId,
    });
  }, [id, content.taskCommentId, content.customerId, content.taskId]);
}

export default AgentMentionedInTaskCommentContainer;
