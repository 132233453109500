import createModel, { prop } from 'models/lib/create_model';

export default createModel({
  modelName: 'Action',

  properties: {
    formUrl: prop(String),
    name: prop(String),
    integrationId: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
