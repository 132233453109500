import _ from 'lodash';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import InternalEmailModal from './internal_email_modal';
import { getRecipientsList } from 'actions/composition/send_email';

function mapStateToProps(context, props) {
  let recipients = _.map(
    _.concat(getRecipientsList(props.to), getRecipientsList(props.cc), getRecipientsList(props.bcc)),
    _.toLower
  );

  return {
    memberDescription: getMemberDescription(recipients),
    onKeepHistory: props.onKeepHistory,
    onRemoveHistory: props.onRemoveHistory,
  };

  function getCustomerDisplayName() {
    let customer = context.getProvider('profile').get();
    if (customer.name) {
      return customer.name;
    }

    let customerEmails = customer.emails.map(email => email.original.toLowerCase());
    return _.find(customerEmails, email => {
      return _.includes(recipients, email);
    });
  }

  function getMemberDescription() {
    // do not include customer in the recipient count
    let recipientCount = recipients.length - 1;

    let recipientDescription = '';
    if (recipientCount > 0) {
      recipientDescription = ` + ${recipientCount} other${recipientCount > 1 ? 's' : ''}`;
    }

    return `${getCustomerDisplayName()}${recipientDescription}`;
  }
}

const InternalEmailModalContainer = connect(mapStateToProps)(InternalEmailModal);

InternalEmailModalContainer.propTypes = {
  to: PropTypes.string,
  cc: PropTypes.string,
  bcc: PropTypes.string,
  onKeepHistory: PropTypes.func.isRequired,
  onRemoveHistory: PropTypes.func.isRequired,
};

InternalEmailModalContainer.defaultProps = {
  to: '',
  cc: '',
  bcc: '',
};

export default InternalEmailModalContainer;
