import _ from 'lodash';

import { DEFAULT_FETCH_SIZE, InboxSortOrder } from 'models/location/inbox';

export default class RequestInbox {
  constructor(context) {
    this.context = context;
  }

  run({ cutoffs } = {}) {
    let agentId = this.context.stores.currentAgent.get().id;
    this.context.gateways.conversationCount.requestAgentConversationCounts(agentId);

    if (!this.isFeatureEnabled('groupInboxes')) {
      return;
    }
    let inbox = this.context.stores.currentLocation.get();
    let sort = inbox.sortOrder;
    let topicId = inbox.topicId;
    let conversationStatus = inbox.conversationStatus;
    let isPolling = inbox.isPolling;
    cutoffs = cutoffs || {};
    let cutoff = sort === InboxSortOrder.LATEST ? cutoffs.min : cutoffs.max;
    this.context.gateways.conversationCount.requestGroupConversationCounts();

    let payload = { sort, conversationStatus, cutoff, limit: DEFAULT_FETCH_SIZE };

    if (inbox.isTeam) {
      if (inbox.routingGroupId) {
        this.context.gateways.inboxHttp.requestGroupInbox(
          inbox.routingGroupId,
          _.assign(payload, { topicId }),
          isPolling
        );
      }
    }
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }
}
