import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import DismissMultipleTabsNotification from 'actions/multiple_tabs/dismiss_multiple_tabs_notification';
import canHandlePhoneCalls from 'actions/agents/can_handle_phone_calls';
import LinkOutStrokeIcon from 'components/common/icons/stroke/link-out-stroke';
import Notification from './notification';

export function MultipleTabsNotification({ agentCanHandlePhoneCalls, id, onDismiss }) {
  return (
    <Notification contentClasses="warningNotification" notificationId={id} onClick={onDismiss}>
      <StyledContent>
        <StyledMessage>
          <StyledWarning data-aid="warning-icon" icon={faTriangleExclamation} />
          <StyledMessageWrapper>
            Having Gladly open in multiple tabs can cause technical errors
            {agentCanHandlePhoneCalls ? ', such as missing calls' : ''}. We recommend sticking to 1 tab. For more
            information, visit our&nbsp;
            <StyledExternalLink
              data-aid="help-link"
              href="https://help.gladly.com/docs/why-cant-i-have-gladly-running-on-multiple-browser-tabs"
              rel="noopener noreferrer"
              target="_blank"
            >
              Help Docs
              <StyledLinkOutStroke />
            </StyledExternalLink>
          </StyledMessageWrapper>
        </StyledMessage>
        <Button buttonType={ButtonTypes.SECONDARY} contrast data-aid="dismiss-button" onClick={onDismiss}>
          Got it
        </Button>
      </StyledContent>
    </Notification>
  );
}

MultipleTabsNotification.propTypes = {
  agentCanHandlePhoneCalls: PropTypes.bool,
  id: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
};

const MultipleTabsNotificationContainer = connect(mapStateToProps, mapExecuteToProps)(MultipleTabsNotification);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const currentAgent = getProvider('currentAgent').get();
  const connectionState = getProvider('connectionState').get();

  return {
    handlesPhoneCalls: canHandlePhoneCalls(currentAgent, connectionState, isFeatureEnabled),
  };
}

function mapExecuteToProps(executeAction, { id }) {
  return {
    onDismiss: () => executeAction(DismissMultipleTabsNotification, { id }),
  };
}

export const StyledMessage = styled.div`
  align-items: center;
  color: black;
  display: flex;
  font-weight: 600;
`;

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledMessageWrapper = styled.div`
  margin-right: 10px;
`;

export const StyledExternalLink = styled.a`
  text-decoration: underline;
  color: ${p => p.theme.colors.black};
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    color: ${p => p.theme.colors.gray800};
    svg {
      fill: ${p => p.theme.colors.gray800};
    }
  }
`;

const StyledLinkOutStroke = styled(LinkOutStrokeIcon)`
  width: 16px;
  margin-left: 4px;
  fill: ${p => p.theme.colors.black};
`;

export const StyledWarning = styled(FontAwesomeIcon)`
  color: ${p => p.theme.colors.black};
  font-size: 24px;
  margin-right: 16px;
`;

export default MultipleTabsNotificationContainer;
