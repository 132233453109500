import TrackEvent from 'actions/analytics/track_event';

export default class TrackRelationshipClick {
  constructor(context) {
    this.context = context;
  }

  run(target) {
    let currentLocation = this.context.stores.currentLocation.get();
    let currentCustomerId = currentLocation.customerId;

    this.context.executeAction(TrackEvent, {
      event: `Relationships - ${target} - Click`,
      props: { currentCustomerId },
    });
  }
}
