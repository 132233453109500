import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default class KeypadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const classes = classnames('keypad-button', `keypad-button-${this.props.name}`, {
      'keypad-button-active': this.state.active,
    });

    const aliasClasses = classnames('keypad-button-alias', {
      'keypad-button-blankAlias': this.props.alias === '_',
      'keypad-button-active': this.state.active && this.props.alias !== '_',
    });
    const symbolClasses = classnames('keypad-button-symbol', { 'keypad-button-active': this.state.active });
    return (
      <div
        className={classes}
        onClick={this.handleClick}
        onMouseDown={this.setActiveState.bind(this, true)}
        onMouseLeave={this.setActiveState.bind(this, false)}
        onMouseUp={this.setActiveState.bind(this, false)}
      >
        <div className={symbolClasses}>{this.props.symbol}</div>
        <div className={aliasClasses}>{this.props.alias}</div>
      </div>
    );
  }

  handleClick() {
    this.props.onClick(this.props.symbol);
  }

  setActiveState(state) {
    this.setState({ active: state });
  }
}

KeypadButton.propTypes = {
  symbol: PropTypes.string.isRequired,
  alias: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
