import createModel from './lib/create_model';

const prop = createModel.prop;

export default createModel({
  modelName: 'DiagnosticsConfig',

  properties: {
    fullStoryDeactivated: prop(Boolean).default(false),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
