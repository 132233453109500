import _ from 'lodash';

import createRoleFeatures from 'scripts/domain/factories/configuration/create_role_features';
import qconsole from 'scripts/lib/qconsole';

export function replaceAuth(context, { claims }) {
  let auth = context.stores.auth.get();
  auth.login(_.omit(claims, 'topicPermissions'));
  if (!auth.isLoggedIn()) {
    qconsole.log('Auth claims are expired');
    return null;
  }

  let appFeatures = context.stores.appFeatures.get();
  appFeatures = appFeatures.replace('roleFeatures', createRoleFeatures(claims));

  if (
    !appFeatures.roleFeatures.internalAgentActions &&
    !appFeatures.roleFeatures.viewLiveboards &&
    !appFeatures.roleFeatures.kbManagement &&
    !appFeatures.roleFeatures.reportBuilder &&
    !appFeatures.roleFeatures.debuggingView
  ) {
    qconsole.log("Auth claims roles don't have enough permissions to use agent-desktop");
    return null;
  }
  context.analytics.updateUser({ isSSOEnabled: claims.ssoEnabled, roles: claims.roleIds.join(', ') });
  context.stores.auth.set(auth, { temporary: auth.isPasswordBeingReset() });
  context.stores.appFeatures.set(appFeatures);
  return auth;
}
