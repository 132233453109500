import _ from 'lodash';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class SetFrom {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, from }) {
    const compositionsStore = getCompositionsStore(this.context);
    const composition = compositionsStore.findBy({ id: compositionId });
    composition.content.setFrom(from);
    compositionsStore.replace(composition);

    const errors = compositionsStore.getErrors(compositionId);
    const filtered = _.filter(errors, err => err.attr !== 'from');
    compositionsStore.setErrors(compositionId, filtered);
  }
}
