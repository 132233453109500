import StandardGateway from 'scripts/adapters/gateways/lib/standard_gateway';

export default class CustomerHistoryRedactionGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/customer-history/:customerId/conversation-items/:itemId');
  }

  redactContent({ customerId, itemId }) {
    let url = `${this.endpoint.set({ customerId, itemId }).updateUrl}/redaction`;
    let resource = `${this.endpoint.patterns.updateUrl}/redaction`;
    return this.http
      .post(url, {}, undefined, resource)
      .then(dto => {
        this.observer.onAddSuccess(dto, { customerId, itemId });
        return dto;
      })
      .catch(error => {
        this.observer.onAddError(error, { customerId, itemId });
        throw error;
      });
  }
}
