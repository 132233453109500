import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import ConversationWorkflowButtons from './conversation_workflow_buttons';
import {
  getActiveChatSessionByCustomer,
  getActiveChatSessionIdFromHistory,
} from 'actions/communication_session/session_finder';
import {
  getLatestManualCustomerConversationItemAttrs,
  getLatestNoReplyNeededTimestamp,
  hasActivePhoneCall,
} from 'scripts/application/lib/conversation_history_helpers';

const ConversationWorkflowButtonsContainer = connect(mapStateToProps)(ConversationWorkflowButtons);
export default ConversationWorkflowButtonsContainer;

function mapStateToProps({ getProvider, isFeatureEnabled }, { conversationId, customerId }) {
  let agentRoutingPreferencesProvider = getProvider('agentRoutingPreferences');
  let agentStatus = getProvider('agentStatus').get();
  let conversations = getProvider('conversations');
  let currentConversation = conversations.findBy({ id: conversationId });
  let currentAgentId = getProvider('currentAgent').get().id;
  const workflow = getProvider('conversationWorkflow').get();
  let conversationHistory = getProvider('conversationHistory');
  let conversationWorkflowConfig = getProvider('conversationWorkflowConfig').get();
  const activeChatSession = getActiveChatSessionByCustomer(getProvider('activeSessions'), customerId);
  const isChatActive =
    !!activeChatSession ||
    !!getActiveChatSessionIdFromHistory({
      conversationHistory,
      conversations,
    });

  if (!currentConversation) {
    return {
      areTopicsRequired: false,
      conversationId,
      isAssignedToCurrentAgent: false,
      isChatActive: false,
      isClosed: false,
      isDisabled: true,
      isNoReplyNeeded: false,
    };
  }

  const isClosed = currentConversation.status === Conversation.Status.CLOSED;
  let lastNrnTimestamp = getLatestNoReplyNeededTimestamp(conversationHistory);
  let lastInboundItem = getLatestManualCustomerConversationItemAttrs({
    conversationHistory,
    conversationId,
    fields: ['timestamp'],
  });
  let isNoReplyNeeded = !!(
    lastNrnTimestamp &&
    lastInboundItem &&
    moment(lastNrnTimestamp).isAfter(lastInboundItem.timestamp)
  );
  const areTopicsRequired =
    conversationWorkflowConfig.requireTopics &&
    _.isEmpty(currentConversation.topicIds) &&
    _.isEmpty(currentConversation.customAttributes) &&
    !isClosed;

  const preferences = agentRoutingPreferencesProvider.getPending() || agentRoutingPreferencesProvider.get();
  const isNextDisabled = _.get(preferences, 'isFocusOn');

  return {
    conversationId,
    areTopicsRequired,
    isAssignedToCurrentAgent: isAssignedToCurrentAgent(),
    isChatActive,
    isClosed,
    isDisabled:
      !conversationId ||
      !agentStatus ||
      (!isClosed && hasActivePhoneCall({ conversationHistory, conversationId })) ||
      isChatActive ||
      workflow.isNavigatingNextConversation ||
      workflow.hasPendingRequest(conversationId),
    isNextDisabled,
    isNoReplyNeeded,
  };

  function isAssignedToCurrentAgent() {
    let conversationAssigneeId = _.get(currentConversation, 'assignee.agentId');
    return currentAgentId === conversationAssigneeId;
  }
}

ConversationWorkflowButtonsContainer.propTypes = {
  conversationId: PropTypes.string,
  customerId: PropTypes.string.isRequired,
};
