import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import React, { PureComponent } from 'react';
import ReactSelect from 'react-select';
import T from 'prop-types';

import AgentActivity from 'models/agent_activity';
import { H2 } from 'components/common/headers';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import CompositionContentType from 'models/composition/composition_content_type';
import connect from 'components/lib/connect';
import createEnum from 'scripts/lib/create_enum';
import { EndpointTypes } from 'models/endpoint';
import InteractionType from 'models/composition/interaction_type';
import LiveboardTable from './liveboard_table';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import PillList from './pill_list';
import ReportDuration from 'components/reporting/report/lib/report_duration';
import { RoutingChannel, PreferencesStatus } from 'models/agent_routing_preferences';
import { WorkType } from 'models/liveboards/agents_liveboard';

export const LiveboardFilters = createEnum('AGENT_NAME_ASC', 'AGENT_NAME_DESC', 'CURRENTLY_ON', 'FOCUS');

const AgentStatusCardColumns = {
  NAME: 'name',
  AWAY_REASON: 'awayReason',
  AVAILABILITY: 'availableChannels',
  CURRENT: 'currentlyOn',
  DURATION: 'duration',
  ACTIVE_REASON: 'activeReason',
  FOCUS: 'routingPreferences',
};

let columns = {
  [AgentStatusCardColumns.NAME]: {
    fieldName: 'name',
    title: 'Agent',
    className: 'agentStatusCard-row-name',
  },
  [AgentStatusCardColumns.CURRENT]: {
    fieldName: 'currentlyOn',
    title: 'Currently On',
    className: 'agentStatusCard-row-current',
    render: (currentlyOn, index) => {
      return <AgentActivityContainer currentlyOn={currentlyOn} key={`agentActivityContainer-${index}`} />;
    },
  },
  [AgentStatusCardColumns.AWAY_REASON]: {
    fieldName: 'awayReason',
    title: 'Status',
    className: 'agentStatusCard-row-reason',
  },
  [AgentStatusCardColumns.AVAILABILITY]: {
    fieldName: 'availableChannels',
    title: 'Working',
    className: 'agentStatusCard-row-status',
    render: (chan, index) => {
      return (
        <AgentStatusAvailabilityContainer availableChannels={chan} key={`agentStatusAvailabilityContainer-${index}`} />
      );
    },
  },
  [AgentStatusCardColumns.FOCUS]: {
    fieldName: 'routingPreferences',
    title: 'Focus',
    className: 'agentStatusCard-row-focus',
    render: (routingPreferences, index) => {
      return <AgentFocusStatus key={`agentFocusStatus-${index}`} routingPreferences={routingPreferences} />;
    },
  },
  [AgentStatusCardColumns.ACTIVE_REASON]: {
    fieldName: 'activeReason',
    title: 'Status',
    className: 'agentStatusCard-row-reason',
  },
  [AgentStatusCardColumns.DURATION]: {
    fieldName: 'duration',
    title: 'Status Time',
    className: 'agentStatusCard-row-duration',
    render: (duration, index) => {
      return (
        <ReportDuration
          className="agentStatusCard-row-duration"
          duration={duration}
          key={`agentStatusCard-duration-${index}`}
          simplified
        />
      );
    },
  },
};

class AgentStatusCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { sortedBy: LiveboardFilters.CURRENTLY_ON };
    _.bindAll(this, 'renderAgentStatusCardHeader', 'renderSortControl', 'updateSortOrder');
  }
  render() {
    let data = this.sortData(this.props.data);
    return (
      <LiveboardTable
        className={classnames('agentsLiveboard-agentStatus', 'agentStatusCard', this.props.className)}
        columns={this.props.fields.map(field => columns[field])}
        data={data}
        height={this.state.listHeight}
        ref={liveboard => (this.liveboard = liveboard)}
        renderHeader={this.renderAgentStatusCardHeader}
        showColumnTitle
        sortBy={this.state.sortedBy}
        title={this.props.title}
        width={this.state.listWidth}
      />
    );
  }

  sortData(unsorted) {
    if (!this.props.showSortControl) {
      return unsorted;
    }
    switch (this.state.sortedBy) {
      case LiveboardFilters.CURRENTLY_ON: {
        let copy = [...unsorted];
        return copy.sort(compareByChannel);
      }
      case LiveboardFilters.AGENT_NAME_ASC:
        return _.orderBy(unsorted, [agent => agent.name.toLowerCase()]);
      case LiveboardFilters.AGENT_NAME_DESC:
        return _.orderBy(unsorted, [agent => agent.name.toLowerCase()], ['desc']);
      case LiveboardFilters.FOCUS:
        return _.orderBy(
          unsorted,
          [
            agent => agent.routingPreferences.status === PreferencesStatus.FOCUS,
            agent => agent.routingPreferences.updatedAt,
            agent => agent.name.toLowerCase(),
          ],
          ['desc', 'asc', 'asc']
        );
      default:
        return unsorted;
    }
  }

  renderAgentStatusCardHeader() {
    return (
      <div className="agentStatusCard-header">
        <H2 data-aid="agentStatusCard-header-title" stack="none">{`${this.props.data.length} ${this.props.title}`}</H2>
        {this.renderSortControl()}
      </div>
    );
  }

  renderSortControl() {
    if (!this.props.showSortControl) {
      return null;
    }

    let labels = {
      [LiveboardFilters.AGENT_NAME_ASC]: 'Agent name (A to Z)',
      [LiveboardFilters.AGENT_NAME_DESC]: 'Agent name (Z to A)',
      [LiveboardFilters.CURRENTLY_ON]: 'Currently on',
    };

    if (this.props.shouldDisplayFocus) {
      labels[LiveboardFilters.FOCUS] = 'Focus time';
    }

    let options = _.map(labels, (label, value) => {
      return { label, value };
    });

    return (
      <div className="agentStatusCard-sort">
        <div className="agentStatusCard-sort-title">Sorted by</div>
        <ReactSelect
          className="agentStatusCard-sort-select"
          clearable={false}
          onChange={this.updateSortOrder}
          options={options}
          placeholder={labels[this.state.sortedBy]}
          searchable={false}
        />
      </div>
    );
  }

  updateSortOrder(option) {
    this.setState({ sortedBy: option.value });
  }
}

function compareByChannel(a, b) {
  if (!a.currentlyOn) {
    return 1;
  }
  if (!b.currentlyOn) {
    return -1;
  }
  let importantActivities = [AgentActivity.Type.CUSTOMER, AgentActivity.Type.INBOX, AgentActivity.Type.KNOWLEDGE_BASE];
  let aSessions = a.currentlyOn.activeSessions || [];
  let bSessions = b.currentlyOn.activeSessions || [];
  // sort by number of active sessions
  if (aSessions.length > 0) {
    return bSessions.length > 0 ? bSessions.length - aSessions.length : -1;
  }
  if (bSessions.length > 0) {
    return 1;
  }

  // check if on phone call
  if (a.currentlyOn.call) {
    return -1;
  }
  if (b.currentlyOn.call) {
    return 1;
  }
  let aActivity = a.currentlyOn.activity;
  let bActivity = b.currentlyOn.activity;
  let aImportantActivity = importantActivities.indexOf(aActivity) >= 0;
  let bImportantActivity = importantActivities.indexOf(bActivity) >= 0;

  // sort by how important the activity is
  if (aImportantActivity) {
    return bImportantActivity ? importantActivities.indexOf(aActivity) - importantActivities.indexOf(bActivity) : -1;
  }

  if (bImportantActivity) {
    return 1;
  }

  // sort by name of activity
  return aActivity < bActivity ? -1 : aActivity > bActivity;
}

AgentStatusCard.propTypes = {
  className: T.string,
  data: T.array.isRequired,
  shouldDisplayFocus: T.bool,
  title: T.string.isRequired,
  fields: T.array.isRequired,
};

export function AgentFocusStatus({ routingPreferences }) {
  if (routingPreferences.status !== PreferencesStatus.FOCUS) {
    return <div className="agentStatusCard-row-focus" />;
  }

  return (
    <div className="agentStatusCard-row-focus">
      <div data-aid="agent-focus-status">
        <ReportDuration
          className="agent-focus-duration"
          duration={moment().diff(routingPreferences.updatedAt, 'ms')}
          simplified
        />
      </div>
    </div>
  );
}

AgentFocusStatus.propTypes = {
  routingPreferences: T.shape({
    status: T.oneOf(_.values(PreferencesStatus)),
    updatedAt: T.string,
  }),
};

export function AgentStatusAvailability({ availableChannels, isChatEnabled }) {
  let showChatAvailability = () => {
    if (isChatEnabled) {
      return (
        <ChatIconOutline
          className={classnames('agentStatusCard-row-status-icon', {
            'agentStatusCard-row-status-messaging-available':
              availableChannels.indexOf(RoutingChannel.MESSAGING) !== -1,
            'agentStatusCard-row-status-messaging-unavailable':
              availableChannels.indexOf(RoutingChannel.MESSAGING) === -1,
          })}
        />
      );
    }
    return null;
  };

  if (!availableChannels) {
    return null;
  }

  return (
    <div className="agentStatusCard-row-status">
      <div
        className={classnames('agentStatusCard-row-status-email', 'agentStatusCard-row-status-icon', {
          'agentStatusCard-row-status-email-available':
            availableChannels.indexOf(RoutingChannel.MAIL) !== -1 ||
            availableChannels.indexOf(RoutingChannel.TASK) !== -1,
          'agentStatusCard-row-status-email-unavailable':
            availableChannels.indexOf(RoutingChannel.MAIL) === -1 &&
            availableChannels.indexOf(RoutingChannel.TASK) === -1,
        })}
      />
      <div
        className={classnames('agentStatusCard-row-status-voice', 'agentStatusCard-row-status-icon', {
          'agentStatusCard-row-status-voice-available': availableChannels.indexOf(RoutingChannel.VOICE) !== -1,
          'agentStatusCard-row-status-voice-unavailable': availableChannels.indexOf(RoutingChannel.VOICE) === -1,
        })}
      />
      {showChatAvailability()}
    </div>
  );
}
AgentStatusAvailability.propTypes = {
  availableChannels: T.arrayOf(T.oneOf(_.values(RoutingChannel))),
  isChatEnabled: T.bool,
};

const AgentStatusAvailabilityContainer = connect(mapStateToProps)(AgentStatusAvailability);

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  const channelConfiguration = getProvider('channelConfiguration').get();
  let isChatEnabled = !_(channelConfiguration)
    .chain() // forces even single return value functions to get wrapped and deferred i.e. calling value() is required
    .get('endpoints', [])
    .map('type')
    .uniq()
    .intersection([
      EndpointTypes.CHAT,
      EndpointTypes.FB_MESSENGER,
      EndpointTypes.SMS,
      EndpointTypes.TWITTER,
      EndpointTypes.WHATSAPP,
    ])
    .isEmpty()
    .value();

  return {
    ...props,
    isChatEnabled,
  };
}

const AgentActivityContainer = connect(mapActivityStateToProps, mapActivityExecuteToProps)(PillList);

function transformActiveSessionsToPills(activeSessions, isClickable) {
  return _.map(activeSessions, activeSession => {
    return {
      clickable: isClickable,
      clickParams: { conversationId: activeSession.conversationId, customerId: activeSession.customerId },
      duration: moment().diff(activeSession.startedAt, 'ms'),
      showIcon: true,
      sessionType: activeSession.sessionTypes && activeSession.sessionTypes[0],
    };
  });
}

function mapActivityStateToProps({ getProvider, isFeatureEnabled }, { currentlyOn }) {
  const customChannels = getProvider('customChannels');
  const clickable = isFeatureEnabled('internalAgentActions');

  const { sessionWork } = currentlyOn;
  let pills = [];

  if (_.get(sessionWork, 'workType') === WorkType.ACT) {
    pills.push({
      text: activityNames[WorkType.ACT],
      clickable,
      clickParams: { customerId: sessionWork.customerId },
      tooltip: 'Accruing After Contact Time',
    });
  }

  if (currentlyOn) {
    pills.push(...transformActiveSessionsToPills(currentlyOn.activeSessions, clickable));
  }

  const items = pills.length ? pills : [{ text: getActivityName(currentlyOn, customChannels) }];
  return {
    className: 'agentStatusCard-row-current',
    items,
    pillWidth: 110,
  };
}

export function getActivityName(currentlyOn, customChannels) {
  if (currentlyOn.call) {
    return 'Call';
  }
  const activityType = currentlyOn.activity || '';

  if (activityNames[activityType]) {
    return activityNames[activityType];
  }

  if (activityType.startsWith(`${AgentActivity.Type.CUSTOM_CHANNEL}:`)) {
    const customChannelId = activityType.split(':')[1];
    const customChannel = customChannels.findBy({ id: customChannelId });
    return customChannel ? customChannel.name : 'Custom Channel';
  }

  return _.startCase(
    activityType
      .toLowerCase()
      .split('_')
      .join(' ')
  );
}

const activityNames = Object.freeze({
  [AgentActivity.Type.KNOWLEDGE_BASE]: 'Answers',
  [AgentActivity.Type.IVRS_ADMIN]: 'IVR Admin',
  [AgentActivity.Type.FACEBOOK_MESSAGE]: 'FB Message',
  [CompositionContentType.CONVERSATION_NOTE]: 'Note',
  [CompositionContentType.SMS]: 'SMS',
  [InteractionType.TWITTER]: 'Twitter',
  [InteractionType.WHATSAPP]: 'WhatsApp',
  [AgentActivity.Type.TASK_COMMENT_PANEL_OPEN]: 'Task Panel',
  [WorkType.ACT]: 'ACT',
});

function mapActivityExecuteToProps(executeAction) {
  return {
    onClick: ({ conversationId, customerId }) => executeAction(NavigateToConversation, { conversationId, customerId }),
  };
}

export { AgentStatusAvailabilityContainer, AgentStatusCardColumns, AgentStatusCard, AgentActivityContainer };
