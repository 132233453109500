import DtoConverter from 'scripts/application/dto_converters/snippet_converter';
import Err from 'models/err';
import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';
import SelectAnswer from 'actions/answers/select_answer';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class LoadAndSelectAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ answerId, answerLanguage, answerType }) {
    this.context.gateways.snippet
      .fetch({ snippetId: answerId })
      .then(snippetDto => {
        const answer = DtoConverter.fromDto(snippetDto);
        const answerPanel = this.context.stores.answerPanel.get();
        if (!answerPanel.isOpen()) {
          answerPanel.open();
          this.context.stores.answerPanel.set(answerPanel);
        }
        this.context.executeAction(SelectAnswer, { answer, answerLanguage, answerType, clearHistory: true });
      })
      .catch(errorDto => {
        const error = ErrorDtoConverter.fromDto(errorDto);
        if (error.code === Err.Code.NOT_EXIST) {
          this.context.executeAction(ShowToast, {
            type: ToastType.WARN,
            message: 'Hmmm, it looks like that answer was deleted',
          });
          return;
        }
        this.context.executeAction(ShowToast, {
          type: ToastType.ERROR,
          message: 'Could not load the requested answer',
        });
      });
  }
}
