import PropTypes from 'prop-types';
import React from 'react';

import { ButtonTypes } from 'components/common/button';
import DismissNotificationButtonContainer from './dismiss_notification_button';
import ErrorNotificationModel from 'models/notification/error_notification';
import StackContainer from 'components/common/containers/stack_container';
import styled from 'styled-components';

const StyledErrorNotificationContainer = styled(StackContainer)`
  align-items: center;
  background-color: ${p => p.theme.colors.yellow400};
  border-radius: ${p => p.theme.borderRadius.default};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.38);
  color: ${p => p.theme.colors.black};
  display: flex;
  flex-direction: row;
  height: 60px;
  z-index: 11;
`;

const StyledErrorNotificationContent = styled.div`
  display: flex;
  width: 100%;
`;

export default function ErrorNotification({ notification }) {
  const message = notification.message;

  return (
    <StyledErrorNotificationContainer key={`notification-${notification.id}`} notificationId={notification.id}>
      <StyledErrorNotificationContent>{message}</StyledErrorNotificationContent>
      <DismissNotificationButtonContainer buttonType={ButtonTypes.SECONDARY} notificationId={notification.id} />
    </StyledErrorNotificationContainer>
  );
}

ErrorNotification.propTypes = {
  notification: PropTypes.instanceOf(ErrorNotificationModel).isRequired,
};
