import _ from 'lodash';

import { AnswersListType } from 'models/answer_panel';
import AttachSnippetAttachments from 'actions/composition/attach_snippet_attachments';
import CreateCompositionFromAnswerType from 'actions/composition/create_composition_from_answer_type';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getAnswersList, getSuggestedAnswersModelVersion } from 'scripts/application/selectors/answers';
import SelectedKbItem from 'models/selected_kb_item';
import { SnippetChannel, SnippetContentType } from 'models/answers/snippet';
import TrackAnswerUsed from 'actions/knowledge_base/track_answer_used';
import UpdateCompositionWithSnippet from 'actions/composition/update_composition_with_snippet';

export default class InsertSelectedAnswer {
  constructor(context) {
    this.context = context;
  }

  run() {
    const answerPanel = this.context.stores.answerPanel.get();
    const { selectedAnswer, selectedAnswerLanguage, selectedAnswerType } = answerPanel;
    if (!selectedAnswer || selectedAnswerType === SnippetChannel.INFO) {
      return;
    }

    let insertedItem = SelectedKbItem.create({
      item: selectedAnswer,
      channelType: selectedAnswerType,
      language: selectedAnswerLanguage,
    });
    this.context.stores.selectedKbItem.set(insertedItem);

    const currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation.customerId;

    const { conversations } = this.context.stores.customers.storesFor(customerId);
    const conversationId = getActiveConversationId(conversations);
    let compositionId = currentLocation.currentCompositionId;
    if (!compositionId && conversationId) {
      this.context.executeAction(CreateCompositionFromAnswerType, { answerType: selectedAnswerType, conversationId });
      compositionId = this.context.stores.currentLocation.get().currentCompositionId;
    }

    if (compositionId) {
      this.context.executeAction(AttachSnippetAttachments, {
        compositionId,
        contentChannelType: SnippetContentType[selectedAnswerType],
        item: selectedAnswer,
        language: selectedAnswerLanguage,
      });
      this.context.executeAction(UpdateCompositionWithSnippet, {
        compositionId,
        snippetId: selectedAnswer.id,
        snippetLanguage: selectedAnswerLanguage,
        snippetChannelType: SnippetContentType[selectedAnswerType],
      });
    }

    const search = this.context.stores.answerPanel.get().searchResult;
    const searchText = _.get(search, 'query.text') || '';

    const answerSuggestionsModelVersion = getSuggestedAnswersModelVersion(this.context.stores, { compositionId });

    const { answers, type } = getAnswersList(this.context.stores, { compositionId });

    let sortType = _.get(search, 'query.sort');
    let selectionType = 'search';
    if (type === AnswersListType.SUGGESTIONS) {
      sortType = 'suggested';
      selectionType = 'suggestion';
    } else if (type === AnswersListType.FAVORITES) {
      sortType = 'favorites';
      selectionType = 'favorite';
    }

    this.context.analytics.track('Answer Panel - Answer Selected', {
      'exp-answerSuggestionsModelVersion': answerSuggestionsModelVersion,
      answerCount: answers.length,
      answerId: selectedAnswer.id,
      channelType: selectedAnswerType,
      compositionId,
      conversationId,
      customerId,
      searchText,
      selectionRank: _.findIndex(answers, answer => answer.id === selectedAnswer.id),
      selectionType,
      sortType,
      version: answerSuggestionsModelVersion, // for backwards compatability
    });

    this.context.executeAction(TrackAnswerUsed, {
      answer: selectedAnswer,
      channel: selectedAnswerType,
      language: selectedAnswerLanguage,
      method: 'INSERTED',
    });
  }
}
