import { clearPendingRequest } from 'actions/composition/lib/update_conversation_workflow';
import ErrorReporter from 'scripts/infrastructure/error_reporter';

export default class OutgoingFbMessageGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddSuccess(payload, correlationId) {
    clearPendingRequest(this.context, correlationId);
  }

  handleAddErrors(payload, correlationId) {
    clearPendingRequest(this.context, correlationId);

    ErrorReporter.reportError(`Unhandled Facebook message send error: ${JSON.stringify(payload)}`);
  }
}
