import styled from 'styled-components';

const MessageGroupContainer = styled.div.attrs({ 'data-aid': 'slate-subject-container' })`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  [data-slate-editor='true'] {
    flex-shrink: 1;
    font-weight: bold;
    flex-basis: 200px;
    width: 100%;
  }
`;

export default MessageGroupContainer;
