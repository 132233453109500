import _ from 'lodash';
import classnames from 'classnames';
import d3 from 'd3';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import ChartAxis from './chart_axis';
import ChartDateAxis from './chart_date_axis';
import ChartLine from './chart_line';
import ReportHelpers from './report_helpers';
import TopicReport from 'scripts/domain/models/reports/topic_report';

function LineGraph({ aggregationLevel, data, lineData, lineIds, tooltipTotalLabel, width }) {
  const [tooltipDatapoint, setTooltipDatapoint] = useState([]);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  let startDate, endDate;

  const statics = {
    MIN_SVG_WIDTH: 900,
    REPORT_PADDING: 48, // left & right padding
    X_OFFSET: 100, // left & right offset (space for label/axis)
    Y_OFFSET: 30, // bottom offset (space for x axis)
    CHART_HEIGHT: 200,
    CHART_TOP_OFFSET: 5,
    CHART_PADDING: 20, // left & right space between axis and chart
    CHART_SPACING: 50, // vertical height between top & bottom chart
    MOUSEOVER_THROTTLE_INTERVAL: 10,
    TOOLTIP_KEY_LINE_HEIGHT: 29,
    TOOLTIP_HEADER_HEIGHT: 50,
    TOOLTIP_CIRCLE_SIZE: 7,
  };

  // used in chart y scale
  let allIdsWithCounts = _.flatten(_.map(data, 'values'));
  let allCounts = _.map(allIdsWithCounts, 'value');

  /* Render */

  function renderChart() {
    return (
      <g>
        <ChartAxis
          className="lineGraph-axis"
          isYAxis
          scale={yScale}
          showGridLine
          size={chartSize}
          tickFormat={num => num.toLocaleString()}
        />
        {renderYAxisLabel('created conversations', _.omit(chartSize, 'yOffset'))}
        {renderLines()}
      </g>
    );
  }

  function renderXAxis() {
    let chartsSize = {
      width: svgSize.width,
      height: svgSize.height - svgSize.yOffset,
      xOffset: svgSize.xOffset,
      yOffset: svgSize.yOffset,
    };

    return (
      <ChartDateAxis
        aggregationLevel={aggregationLevel}
        className="lineGraph"
        endAt={endDate}
        scale={xScale}
        showDateLabel
        size={chartsSize}
        startAt={startDate}
      />
    );
  }

  // returns a line component for every id in the lineIds array
  function renderLines() {
    let allLines = [];

    _.forEach(lineIds, id => {
      allLines.push(
        <ChartLine
          className={classnames('lineGraph', `lineGraph-line-${allLines.length}`)}
          data={lineData[id]}
          key={`chartLine-${allLines.length}`}
          showPoints={false}
          xScale={xScale}
          yScale={yScale}
        />
      );
    });
    return allLines;
  }

  function renderYAxisLabel(text, chartSize, isDisplayedRight, className) {
    let yOffset = chartSize.yOffset ? chartSize.yOffset : 0;
    let xOffset = chartSize.xOffset / 4;
    let labelTransform = `translate(${isDisplayedRight ? svgSize.width - xOffset : xOffset}, ${yOffset +
      chartSize.height / 2}) rotate(-90)`;
    return (
      <text className={classnames('lineGraph-axis-label', className)} transform={labelTransform}>
        {text}
      </text>
    );
  }

  function renderTooltip() {
    if (tooltipDatapoint) {
      let xPos = xScale(tooltipDatapoint.date) + statics.REPORT_PADDING;
      let chartBottom = svgSize.height - svgSize.yOffset;
      let totalsForXPos = _.map(tooltipDatapoint.values, 'value');
      let topPos = yScale(_.max(d3.extent(totalsForXPos)));
      let height = totalsForXPos.length * statics.TOOLTIP_KEY_LINE_HEIGHT + statics.TOOLTIP_HEADER_HEIGHT;

      return (
        <div>
          <TooltipLine data-aid="lineGraph-tooltip-line" height={chartBottom - topPos} left={xPos} top={topPos} />
          {renderSelectedCircles(xPos)}
          {renderTooltipContents(xPos, topPos, height)}
        </div>
      );
    }
    return null;
  }

  function getSortedValues() {
    return tooltipDatapoint.values.sort((a, b) => (a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0));
  }

  function renderSelectedCircles(xPos) {
    let circles = _.map(getSortedValues(), entry => {
      let yPos = yScale(entry.value);
      return (
        <TooltipCircle
          data-aid={`lineGraph-tooltip-circle-${entry.rank}`}
          key={`tooltipCircle-${entry.rank}`}
          left={xPos}
          size={statics.TOOLTIP_CIRCLE_SIZE}
          top={yPos}
        />
      );
    });
    return circles;
  }

  function renderTooltipContents(xPos, topPos, height) {
    return (
      <LineGraphTooltip data-aid="lineGraph-tooltip" height={height} top={topPos} xPos={xPos}>
        <TooltipTriangle data-aid="lineGraph-tooltip-triangle" />
        <TooltipRectangle data-aid="lineGraph-tooltip-rectangle" height={height}>
          <TooltipDate data-aid="lineGraph-tooltip-date">{`${ReportHelpers.toDisplayDate(
            tooltipDatapoint.date,
            aggregationLevel,
            true
          )}`}</TooltipDate>
          <TooltipTitle data-aid="lineGraph-tooltip-title">{`${tooltipDatapoint.total.toLocaleString()} ${pluralize(
            tooltipTotalLabel,
            tooltipDatapoint.total
          )} added`}</TooltipTitle>
          {renderTooltipKeys()}
        </TooltipRectangle>
      </LineGraphTooltip>
    );
  }

  function renderTooltipKeys() {
    let allTooltipKeys = [];

    _.forEach(getSortedValues(), entry => {
      let name = entry.name;
      let total = entry.value;
      let percentage =
        tooltipDatapoint.total > 0 ? `(${_.round((entry.value / tooltipDatapoint.total) * 100, 0)}%) ` : '';

      allTooltipKeys.push(
        <TooltipItem key={`tooltipItem-${entry.rank}`}>
          <TooltipKey data-aid={`lineGraph-tooltipKey-${entry.rank}`} rank={entry.rank} />
          <TooltipKeyText
            data-aid={`lineGraph-tooltipKeyText-${entry.rank}`}
          >{`${total} ${percentage}${name}`}</TooltipKeyText>
        </TooltipItem>
      );
    });
    return allTooltipKeys;
  }

  /* Events */

  function onMouseEnter(e) {
    setTooltipInfo(e.clientX);
    setDisplayTooltip(true);
  }

  function onMouseLeave() {
    setDisplayTooltip(false);
  }

  /* Helpers */

  function getSizes() {
    let svgWidth = width - statics.REPORT_PADDING * 2;

    let svgSize = {
      width: svgWidth >= statics.MIN_SVG_WIDTH ? svgWidth : statics.MIN_SVG_WIDTH,
      height: statics.CHART_HEIGHT + statics.Y_OFFSET,
      xOffset: statics.X_OFFSET,
      yOffset: statics.Y_OFFSET,
    };

    let chartWidth = svgSize.width - svgSize.xOffset * 2;

    let chartSize = {
      width: chartWidth,
      height: statics.CHART_HEIGHT,
      xOffset: svgSize.xOffset,
      yOffset: statics.CHART_TOP_OFFSET,
    };

    return { svgSize, chartSize };
  }

  function getTimeScale() {
    let dates = data.map(entry => entry.date);

    startDate = _.min(dates);
    endDate = _.max(dates);

    let startX = svgSize.xOffset + statics.CHART_PADDING;
    let endX = svgSize.width - statics.CHART_PADDING - statics.X_OFFSET;

    return d3.time
      .scale()
      .domain([startDate, endDate])
      .rangeRound([startX, endX]);
  }

  function getChartYScale() {
    let maxValue = _.max(allCounts);
    return d3.scale
      .linear()
      .domain([0, TopicReport.roundToNearestPowerOfTen(maxValue) || 10])
      .rangeRound([chartSize.height, chartSize.yOffset]);
  }

  function setTooltipInfo(mouseX) {
    let mousePositionDate = xScale.invert(mouseX).valueOf();

    let closestDatapoint = getClosestPointFromDate(mousePositionDate);
    setTooltipDatapoint(closestDatapoint);
  }

  function getClosestPointFromDate(date) {
    return _.minBy(data, point => Math.abs(moment(point.date).diff(date)));
  }

  function pluralize(str, num) {
    return num === 1 ? str : `${str}s`;
  }

  const debouncedOnMouseMove = _.throttle(clientX => {
    if (!displayTooltip) {
      setDisplayTooltip(true);
    }
    if (clientX) {
      setTooltipInfo(clientX - statics.REPORT_PADDING);
    }
  }, statics.MOUSEOVER_THROTTLE_INTERVAL);

  /* Render */
  let { svgSize, chartSize } = getSizes();
  let yScale = getChartYScale();
  let xScale = getTimeScale();

  return (
    <div>
      <Graph data-aid="lineGraph">
        <svg
          data-aid="lineGraph-svg"
          height={svgSize.height}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={({ clientX }) => debouncedOnMouseMove(clientX)}
          width={svgSize.width}
        >
          {renderChart()}
          {renderXAxis()}
        </svg>
        {displayTooltip && renderTooltip()}
      </Graph>
    </div>
  );
}

LineGraph.propTypes = {
  aggregationLevel: PropTypes.string.isRequired,
  data: PropTypes.array,
  lineIds: PropTypes.array,
  lineData: PropTypes.object,
  tooltipTotalLabel: PropTypes.string,
  width: PropTypes.number,
};

export default LineGraph;

const Graph = styled.div`
  padding: 0 ${p => p.theme.spacing.insetXXLarge};
  position: relative;
`;

const LineGraphTooltip = styled.div`
  height: ${p => p.height}px;
  left: ${p => p.xPos}px;
  pointer-events: none;
  position: absolute;
  top: ${p => p.top}px;
  z-index: 5;
`;

const TooltipTriangle = styled.div`
  border: 10px solid transparent;
  border-top-color: ${p => p.theme.colors.gray800};
  left: -10px;
  position: relative;
  top: -16px;
  width: 10px;
`;

const TooltipRectangle = styled.div`
  background: ${p => p.theme.colors.gray800};
  border-radius: 10px;
  color: white;
  height: ${p => p.height}px;
  padding: ${p => p.theme.spacing.insetSmall};
  position: relative;
  right: 50%;
  top: ${p => -1 * (p.height + 36)}px;
  width: 280px;
`;

const TooltipDate = styled.div`
  padding: 0 0 0 ${p => p.theme.spacing.insetSmall};
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TooltipTitle = styled.div`
  padding: 0 0 ${p => p.theme.spacing.insetSmall} ${p => p.theme.spacing.insetSmall};
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TooltipLine = styled.div`
  border-left: 1px solid ${p => p.theme.colors.black};
  height: ${p => p.height}px;
  left: ${p => p.left}px;
  position: absolute;
  top: ${p => p.top}px;
`;

const TooltipCircle = styled.div`
  background: ${p => p.theme.colors.black};
  border-radius: 50%;
  height: ${p => p.size}px;
  left: ${p => p.left - p.size * 0.5}px;
  position: absolute;
  top: ${p => p.top - p.size * 0.5}px;
  width: ${p => p.size}px;
`;

const TooltipItem = styled.div`
  padding: 0 ${p => p.theme.spacing.insetSmall};
  position: relative;
  display: inline-block;
  float: left;
`;

const TooltipKey = styled.div`
  background-color: ${p => p.theme.lineGraphColors[`lineGraphLine${p.rank}`]};
  border-radius: 50%;
  display: inline-block;
  height: 9px;
  margin: 0 ${p => p.theme.spacing.insetSmall} 6px 0;
  width: 9px;
`;

const TooltipKeyText = styled.div`
  padding: 0 ${p => p.theme.spacing.insetSmall};
  display: inline-block;
  margin: 0 $spacing-xsmall;
  width: 230px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
