import AcceptIncomingCall from 'actions/phone_call/accept_incoming_call';

export default class AutoAcceptIncomingCall {
  constructor(context) {
    this.context = context;
  }

  run({ activeCall, currentAgentId }) {
    const { autoAccept } = this.context.stores.voiceConfiguration.get();
    if (autoAccept && autoAccept.enabled) {
      // Generate a random number between -250 and 250 to add jitter to the auto accept interval
      // This should help if they happen to have multiple tabs open to ensure they don't all
      // auto accept at the same time
      const jitter = Math.floor(Math.random() * 500) - 250;

      setTimeout(() => {
        const latestActiveCall = this.context.stores.activeCall.get();
        if (
          latestActiveCall &&
          activeCall.id === latestActiveCall.id &&
          latestActiveCall.shouldDisplayIncomingCallNotification(currentAgentId)
        ) {
          this.context.executeAction(AcceptIncomingCall);
        }
      }, autoAccept.delaySecs * 1000 + jitter);
    }
  }
}
