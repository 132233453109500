import React from 'react';
import styled from 'styled-components';
import Icon from 'components/lib/icons/icon';

export default props => (
  <StyledIcon viewBox="0 0 17 13" {...props}>
    <path
      d="M3.51946 12.0789L3.57195 12.1303L3.57691 12.1255C3.79674 12.2905 4.11266 12.2745 4.31383 12.0774L6.74344 9.697C6.96327 9.48163 6.96327 9.13128 6.74344 8.9159C6.52447 8.70136 6.16956 8.70136 5.95058 8.9159L3.91663 10.9087L1.88271 8.91739C1.8827 8.91738 1.8827 8.91738 1.88269 8.91737C1.66372 8.70285 1.30882 8.70286 1.08985 8.91739C0.870022 9.13277 0.870022 9.48311 1.08985 9.69849L3.51946 12.0789Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.15"
    />
    <rect
      fill="currentColor"
      height="10.48"
      rx="0.491667"
      stroke="currentColor"
      strokeWidth="0.15"
      transform="rotate(-180 4.40831 11.405)"
      width="0.983334"
      x="4.40831"
      y="11.405"
    />
    <path d="M9 8L16 8" stroke="currentColor" strokeLinecap="round" strokeWidth="1.2" />
    <path d="M9 11L13.4545 11" stroke="currentColor" strokeLinecap="round" strokeWidth="1.2" />
    <path d="M9 2L16 2" stroke="currentColor" strokeLinecap="round" strokeWidth="1.2" />
    <path d="M9 5L16 5" stroke="currentColor" strokeLinecap="round" strokeWidth="1.2" />
  </StyledIcon>
);

const StyledIcon = styled(Icon)`
  min-width: 17px;
  min-height: 13px;
  stroke: ${p => p.theme.colors.gray800};
`;
