import { detect } from 'detect-browser';
import { isPlainObject, memoBuilder } from '@sentry/utils';
import { ExtraErrorData, RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/browser';
import { FullStory } from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import sanitizeSlate from 'scripts/configuration/logging/sanitize_slate';

export default function() {
  const blacklistUrls = [/192\.168\.\d+\.\d+/, /localhost/];
  const sanitizeKeys = ['X-CSRF-Token'];

  // https://docs.sentry.io/platforms/javascript/#connecting-the-sdk-to-sentry
  if (blacklistUrls.every(re => !re.test(window.location))) {
    Sentry.init({
      dsn: 'https://7e122b64091e4a3492cbb033ae09b692@o20645.ingest.sentry.io/41254',
      release: window.gladlyConfig.release,
      environment: window.gladlyConfig.environment,
      blacklistUrls,
      ignoreErrors: [
        /^MQTT - subscribe timeout$/,
        /^MQTT - subscription not granted \(\[\]\).*$/,
        /^ResizeObserver loop limit exceeded$/,
        /^ResizeObserver loop completed with undelivered notifications.$/,
        /^undefined$/,
        /^Identifier 'SiddMutationObserver' has already been declared$/, // Ulta-specific issue
        /^Identifier 'ObjectSelector' has already been declared$/, // Ulta-specific issue
        /^Identifier 'InjectionFunctionFactory' has already been declared$/, // Ulta-specific issue
      ],
      // Turned off, because it breaks our router. Can be turned on when we are able
      // to upgrade to V7. See https://www.notion.so/gladly/Sentry-Upgrade-in-Agent-Desktop-d6623a0494bc4504a3328c87e481edd7
      autoSessionTracking: false,
      integrations: [
        // Ignore history breadcrumbs because it interferes with our router
        // https://docs.sentry.io/platforms/javascript/#breadcrumbs-1
        new Sentry.Integrations.Breadcrumbs({ history: false }),
        // Mark parts of the stacktrack which are part of our code. Other frames will appear collapsed inside Sentry
        // https://github.com/getsentry/sentry-javascript/issues/1631#issuecomment-432957194
        new RewriteFrames({
          iteratee(frame) {
            if (frame.filename) {
              frame.in_app = /https:\/\/cdn\.gladly\.(qa|com)\/assets\/agent-desktop\/[0-9a-f]+\/main-[0-9a-f]+.js/i.test(
                frame.filename
              );
            }
            return frame;
          },
        }),

        // Add fullstory link to sentry
        new SentryFullStory('gladly', { client: FullStory }),

        // Add non-native attributes from the Error object and attach as extra data
        new ExtraErrorData(),
      ],
      beforeBreadcrumb(breadcrumb) {
        return sanitize(breadcrumb, sanitizeKeys);
      },
      beforeSend(event) {
        event = sanitize(event, sanitizeKeys);
        event = sanitizeSlate(event);
        event = isSupportedBrowser() ? event : null;
        return event;
      },
    });

    Sentry.configureScope(function(scope) {
      scope.setTags({
        serverName: window.location.hostname,
        cluster: window.gladlyConfig.cluster,
      });
    });
  }
}

// Recursively mask values matching sanitize keys. Adapted from
// https://github.com/getsentry/sentry-javascript/pull/2422/files
const sanitize = (input, sanitizeKeys, memo = memoBuilder()) => {
  const [memoize, unmemoize] = memo;
  const inputIsArray = Array.isArray(input);
  const inputIsPlainObject = isPlainObject(input);

  if (!inputIsArray && !inputIsPlainObject) {
    return input;
  }

  // Avoid circular references
  if (memoize(input)) {
    return input;
  }

  let sanitizedValue;
  if (inputIsArray) {
    sanitizedValue = input.map(value => sanitize(value, sanitizeKeys, memo));
  } else if (inputIsPlainObject) {
    sanitizedValue = Object.keys(input).reduce((acc, key) => {
      acc[key] = sanitizeKeys.includes(key) ? '********' : sanitize(input[key], sanitizeKeys, memo);
      return acc;
    }, {});
  }

  unmemoize(input);

  return sanitizedValue;
};

const isSupportedBrowser = () => {
  // https://github.com/DamonOehlman/detect-browser
  const browser = detect();

  return (
    browser &&
    !['iOS', 'Android OS', 'BlackBerry OS'].includes(browser.os) && // ignore mobile
    browser.name === 'chrome' // only support chrome
  );
};

export { isSupportedBrowser, sanitize };
