import React from 'react';

import ChatIconFilled from 'components/lib/icons/chat_icon_filled';

export default function ChatMetadataIcon() {
  return (
    <div className="itemMetadata-chatIcon">
      <ChatIconFilled />
    </div>
  );
}
