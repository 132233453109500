import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Notification from './notification';
import OptionalReloadNotificationModel, { ReloadReason } from 'models/notification/optional_reload_notification';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import { DismissNotificationButton } from './dismiss_notification_button';
import DismissNotification from 'actions/notification/dismiss_notification';
import PhoneNotAvailableIcon from 'components/lib/icons/phone_not_available';

export function OptionalReloadNotification({ notification, onDismiss, onReload }) {
  function renderIcon() {
    switch (notification.reason) {
      case ReloadReason.PHONE_NOT_AVAILABLE:
        return <PhoneNotAvailableIcon />;
      default:
        return null;
    }
  }

  function renderMessage() {
    switch (notification.reason) {
      case ReloadReason.PHONE_NOT_AVAILABLE:
        return 'Your phone is currently unavailable. Please try reloading the page.';
      default:
        return 'Please reload the page';
    }
  }

  return (
    <Notification contentClasses="optionalReloadNotification" notificationId={notification.id}>
      <div className="conversationNotification-topic">
        {renderIcon()}
        <div className="optionalReloadNotification-content">{renderMessage()}</div>
      </div>
      <div className="notification-buttonContainer">
        <ReloadButton buttonType={ButtonTypes.TEXT} data-aid="optionalUpgradeNotification-reload" onClick={onReload}>
          Reload Now
        </ReloadButton>
      </div>
      <DismissNotificationButton buttonText="Later" notificationId={notification.id} onClick={onDismiss} />
    </Notification>
  );
}

const ReloadButton = styled(Button)`
  color: ${p => p.theme.colors.white};
  &:hover {
    color: ${p => p.theme.colors.white};
    text-decoration: underline;
  }
`;
OptionalReloadNotification.propTypes = {
  notification: PropTypes.instanceOf(OptionalReloadNotificationModel),
  onDismiss: PropTypes.func,
  onReload: PropTypes.func,
};

export default connect((context, props) => props, mapExecuteToProps)(OptionalReloadNotification);

function mapExecuteToProps(executeAction) {
  return {
    onDismiss: notificationId => executeAction(DismissNotification, notificationId),
    onReload: () => window.location.reload(),
  };
}
