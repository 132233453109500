import UserLogin from 'models/location/user_login';

export default class OpenLogin {
  constructor(context) {
    this.context = context;
  }

  run(preAuthExternalPath) {
    if (this.context.stores.auth.get().isLoggedIn()) {
      this.context.router.navigateHome();
      return;
    }

    let preAuthPath;
    if (preAuthExternalPath) {
      preAuthPath = { path: preAuthExternalPath, external: true };
    } else {
      let requestedPath = this.context.stores.currentLocation.get().requestedPath;
      preAuthPath = requestedPath ? { path: requestedPath, external: false } : undefined;
    }
    this.context.stores.currentLocation.set(UserLogin.create({ preAuthPath }));
  }
}
