import { VARIABLE } from './variables';

export const VARIABLE_ACTIVATION_CHARACTER = '/';

export default function VariableEditingPlugin() {
  let previousVariableMentionText = null;

  return {
    commands: { insertVariable },

    onChange(editor, next) {
      let variableMentionText = editor.getMentionText(VARIABLE_ACTIVATION_CHARACTER);
      if (variableMentionText && variableMentionText !== previousVariableMentionText) {
        previousVariableMentionText = variableMentionText;

        editor.updateMention(variableMentionText);
        return next();
      }
    },

    onKeyDown(evt, editor, next) {
      if (evt.key === VARIABLE_ACTIVATION_CHARACTER) {
        evt.preventDefault();
        editor.startMention(VARIABLE_ACTIVATION_CHARACTER);
        return next();
      }

      if (evt.key === 'Escape' && editor.getMentionText(VARIABLE_ACTIVATION_CHARACTER) !== null) {
        editor.removeMention();
        return next();
      }

      return next();
    },
  };
}

export function insertVariable(editor, variable) {
  const mentionText = editor.getMentionText(VARIABLE_ACTIVATION_CHARACTER);

  editor.removeMention();
  editor.deleteBackward(mentionText.length + 1);
  const selectedRange = editor.value.selection;

  editor
    .insertText(' ')
    .insertInlineAtRange(selectedRange, {
      data: {
        variableId: variable.id,
      },
      nodes: [
        {
          object: 'text',
          leaves: [
            {
              text: variable.name,
            },
          ],
        },
      ],
      type: VARIABLE,
    })
    .focus();
}
