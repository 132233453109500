import createModel, { prop } from './lib/create_model';

const TopicSuggestions = createModel({
  modelName: 'TopicSuggestions',

  properties: {
    id: prop(String).isRequired,
    customerId: prop(String).isRequired,
    modelVersion: prop(String).isRequired,
    conversationId: prop(String),
    addTopics: prop([String]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default TopicSuggestions;
