import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import HoverMenu from 'components/customer/lib/hover_menu';
import { isUrlValid } from 'components/lib/linkify_it';

export const StyledReadonlyLink = styled.span`
  color: ${p => p.theme.colors.green400};
  text-decoration: underline;
  ${p => p.isInvalid && invalidLink};
  ${p => p.isEmpty && emptyLink};
`;

const invalidLink = css`
  color: ${p => p.theme.colors.red400};
  &:hover,
  &:focus {
    color: ${p => p.theme.colors.red400};
  }
`;

const emptyLink = css`
  background-color: ${p => p.theme.colors.gray300};
  color: inherit;
  padding: 2px;
  text-decoration: none;
`;

const StyledHoverLink = styled.a`
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  padding: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ReadOnlyLink = props => {
  const { href } = props;
  const isInvalid = href && !isUrlValid(href);
  const isEmpty = !href;
  const classNames = classnames('readonly-link', props.className, {
    'readonly-link-invalid': isInvalid,
    'readonly-link-empty': isEmpty,
  });

  return (
    <HoverMenu
      hoverElement={
        <StyledReadonlyLink {...props.attributes} className={classNames} isEmpty={isEmpty} isInvalid={isInvalid}>
          {props.children}
        </StyledReadonlyLink>
      }
      menuProps={{ contentEditable: false }}
    >
      <StyledHoverLink href={href} rel="noopener noreferrer" target="_blank">
        {href}
      </StyledHoverLink>
    </HoverMenu>
  );
};

ReadOnlyLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
};
export default ReadOnlyLink;
