import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { PlateProvider } from '@udecode/plate';

import ComposerContext from 'components/composer/contexts/composer_context';
import CompositionButtons, { CancelButton } from 'components/customer/composition/composition_buttons';
import InsetContainer from 'components/common/containers/inset_container';
import SendButton from 'components/customer/composition/send_button';
import StackContainer from 'components/common/containers/stack_container';
import { StyledPlaceholder } from 'components/text_editor/plugins/placeholders';
import useInitialValue from 'components/text_editor_new/hooks/use_initial_value';

import AttachmentsDropZone, { useDroppable } from 'components/composer/shared/attachments_drop_zone';
import ComposerCancelModal from 'components/composer/shared/composer_cancel_modal';

export const StyledComposer = styled(InsetContainer)`
  flex-direction: column;
  max-height: 50vh;
  min-height: 25vh;
  overflow-x: hidden;
  width: 100%;

  ${p => p.hasErrors && errorPlaceholder};
`;

const errorPlaceholder = css`
  ${StyledPlaceholder} {
    border-color: ${p => p.theme.colors.red400};
    color: ${p => p.theme.colors.red400};
  }
`;

export default function Composer({
  cancelText,
  children,
  composerRef,
  defaultEditor,
  defaultEditorId,
  defaultOnChange,
  disableSubmit,
  excludeErrors,
  hideCancel,
  includeAttachments,
  initialHtml,
  isCancelling,
  onCancel,
  onSubmit,
  setIsCancelling,
  submitButton,
  submitText,
}) {
  const [dropProps, isDragActive] = useDroppable(includeAttachments);
  const { compositionErrors, isMinimized } = useContext(ComposerContext);
  const initialValue = useInitialValue(initialHtml, defaultEditor);

  if (!doEditorsExist(composerRef)) {
    return null;
  }

  return (
    <PlateProvider
      editor={defaultEditor}
      id={defaultEditorId}
      initialValue={initialValue}
      normalizeInitialValue
      onChange={defaultOnChange}
    >
      <StyledComposer data-aid="composer" {...dropProps} hasErrors={!!compositionErrors.length}>
        {includeAttachments ? <AttachmentsDropZone isDragActive={isDragActive} /> : null}
        {children}
        {!excludeErrors ? <Errors errors={compositionErrors} /> : null}
        <Buttons
          cancelText={cancelText}
          disableSubmit={disableSubmit || isMinimized}
          hideCancel={hideCancel}
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitButton={submitButton}
          submitText={submitText}
        />

        {isCancelling ? (
          <ComposerCancelModal
            onCancel={() => {
              setIsCancelling(false);
            }}
          />
        ) : null}
      </StyledComposer>
    </PlateProvider>
  );
}

function doEditorsExist(composerRef) {
  const editorRefs = composerRef.current.editorRefs;
  return _.keys(editorRefs).length > 0 && _.every(editorRefs, ref => !!ref.current);
}

Composer.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.node,
  composerRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  defaultEditor: PropTypes.object,
  defaultEditorId: PropTypes.string,
  defaultOnChange: PropTypes.func,
  disableSubmit: PropTypes.bool,
  excludeErrors: PropTypes.bool,
  hideCancel: PropTypes.bool,
  includeAttachments: PropTypes.bool,
  initialHtml: PropTypes.string,
  isCancelling: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  setIsCancelling: PropTypes.func,
  submitButton: PropTypes.node,
  submitText: PropTypes.string,
};

const Error = styled.div`
  color: ${p => p.theme.colors.red400};
  flex-shrink: 0;
  text-align: right;
`;

export function Errors({ errors }) {
  if (!errors.length) {
    return null;
  }

  return <Error data-aid="composerError">{_.upperFirst(errors[0].detail)}</Error>;
}

Errors.propTypes = {
  errors: PropTypes.array,
};

const StyledHeader = styled(StackContainer)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 4px;
  white-space: nowrap;
`;

export function Header({ children, className }) {
  return (
    <StyledHeader className={className} inset="none">
      {children}
    </StyledHeader>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const HeaderText = styled.div.attrs({ 'data-aid': 'headerText' })`
  border-right: 2px solid ${p => p.theme.colors.gray200};
  color: ${p => (p.color ? p.theme.colors[p.color] : p.theme.colors.green400)};
  font-weight: ${p => p.theme.fontWeight.medium};
  margin-right: ${p => p.theme.spacing.medium};
  padding-right: ${p => p.theme.spacing.medium};
  text-transform: uppercase;
`;

export const NewHeaderText = styled.div.attrs({ 'data-aid': 'headerText' })`
  color: ${p => p.theme.colors.black};
  font-size: 14px;
  font-weight: ${p => p.theme.fontWeight.heavy};
  margin: 0px 16px 0px 4px;
`;

export function Buttons({ cancelText, hideCancel, disableSubmit, onCancel, onSubmit, submitButton, submitText }) {
  const cancelButton = hideCancel ? null : <CancelButton label={cancelText} onClick={onCancel} />;
  return (
    <CompositionButtons>
      {cancelButton}
      {submitButton !== undefined ? (
        submitButton
      ) : (
        <SendButton
          data-aid="sendButton"
          disabled={disableSubmit}
          onClick={disableSubmit ? _.noop : onSubmit}
          onMouseDown={evt => evt.preventDefault()}
        >
          {submitText}
        </SendButton>
      )}
    </CompositionButtons>
  );
}

Buttons.defaultProps = {
  submitText: 'Send',
};

Buttons.propTypes = {
  cancelText: PropTypes.string,
  hideCancel: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitButton: PropTypes.node,
  submitText: PropTypes.string,
};
