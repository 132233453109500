import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

import connect from 'components/lib/connect';

const StyledPlaceholder = styled.em`
  color: ${p => p.theme.colors.gray600};
`;

export function SelectRelationshipMenu({ className, labelId, updateRelationship, relationshipOptions }) {
  return (
    <ReactSelect
      className={className}
      clearable={false}
      onChange={updateRelationship}
      options={relationshipOptions}
      placeholder={<StyledPlaceholder>blank</StyledPlaceholder>}
      searchable={false}
      value={labelId}
    />
  );
}

SelectRelationshipMenu.propTypes = {
  className: PropTypes.string,
  labelId: PropTypes.string,
  relationshipOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ).isRequired,
  updateRelationship: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider }) {
  const labelProvider = getProvider('relationshipLabels');
  let relationshipOptions = [{ label: <StyledPlaceholder>blank</StyledPlaceholder>, value: '' }].concat(
    labelProvider.findAll().map(l => {
      return { label: l.displayName, value: l.id };
    })
  );
  return {
    relationshipOptions,
  };
}

const SelectRelationshipMenuContainer = connect(mapStateToProps)(SelectRelationshipMenu);
export default SelectRelationshipMenuContainer;
