import React from 'react';
import PropTypes from 'prop-types';

import SearchInput from 'components/common/search_input';

class UniversalSearchBar extends React.Component {
  render() {
    return (
      <div className="universalSearch-searchBar">
        <SearchInput
          autoFocus
          className="universalSearch-searchInput"
          delay={600}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder || 'Search for customer or conversation'}
        />
      </div>
    );
  }
}

UniversalSearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default UniversalSearchBar;
