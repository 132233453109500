import _ from 'lodash';

import CurrentCompositionUpdater from '../lib/current_composition_updater';
import CustomerView from 'models/location/customer_view';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import removeComposition from 'actions/composition/lib/remove_composition';

export default class RemoveEmptyCompositions {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
  }

  run(customerId) {
    const currentLocation = this.context.stores.currentLocation.get();
    // We only want to remove empty compositions when we're navigating away from a customer page
    if (currentLocation instanceof CustomerView && currentLocation.customerId === customerId) {
      return;
    }

    const compositionsStore = getCompositionsStore(this.context, customerId);
    if (!compositionsStore) {
      return;
    }

    const compositions = compositionsStore.findAll({
      filter: c => c.customerId === customerId,
    });
    if (!compositions.length) {
      return;
    }

    _.forEach(compositions, (composition, i) => {
      if (composition.isEmpty()) {
        removeComposition(this.context, composition);
      }
    });

    this.currentCompositionUpdater.update();
  }
}
