import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AggregationLevel } from 'models/reports/report_configs';
import {
  createReportDateCellContent,
  getDatesForCell,
  ReportNumberCellContent,
  ReportPercentCellContent,
} from './lib/cell_content';
import ReportTable from './lib/report_table';
import { SORT_PARAMETERS, getNextSortOrder } from './lib/sort_control';

class IvrSummaryReport extends Component {
  constructor(props) {
    super(props);

    this.report = props.report.init({ days: props.days });

    const initialSortMetric = 'date';
    const initialSort = SORT_PARAMETERS.ASC;
    this.state = {
      sortedRowOrder: sortByOrder(props.days, initialSortMetric, initialSort, this.report.getDataForDayAndMetric),
      currentSort: { [initialSortMetric]: initialSort },
    };

    this.changeTableSort = this.changeTableSort.bind(this);
  }

  /* render */
  render() {
    if (!this.props.endpointNumber) {
      return null;
    }
    const { startDate, endDate } = this.props;
    const { timezone } = this.report;
    const reportStartDate = moment.tz(startDate, timezone);
    const reportEndDate = moment
      .tz(endDate, timezone)
      .add(1, 'd')
      .subtract(1, 'ms');
    let cols = [
      {
        header: 'Date',
        ref: 'date',
        cellType: createReportDateCellContent(AggregationLevel.DAY),
      },
      {
        header: 'Incoming Calls',
        ref: 'incomingIvrCallsByTime',
        cellType: ReportNumberCellContent,
      },
      {
        header: 'Calls Reaching End State',
        ref: 'callsReachingEndStateByTime',
        cellType: ReportNumberCellContent,
      },
      {
        header: 'Calls Abandoned',
        ref: 'abandonedIvrCallsByTime',
        cellType: ReportNumberCellContent,
      },
      {
        header: '% Abandoned',
        ref: 'pctAbandonedByTime',
        cellType: ReportPercentCellContent,
      },
      {
        header: 'Speech IVR Attempts',
        ref: 'speechAttemptsByTime',
        cellType: ReportNumberCellContent,
      },
      {
        header: 'Unmapped Speech Attempts',
        ref: 'unmappedSpeechAttemptsByTime',
        cellType: ReportNumberCellContent,
      },
      {
        header: 'Touchtone IVR Attempts',
        ref: 'touchtoneAttemptsByTime',
        cellType: ReportNumberCellContent,
      },
      {
        header: 'Unmapped Touchtone Attempts',
        ref: 'unmappedTouchtoneAttemptsByTime',
        cellType: ReportNumberCellContent,
        width: ReportTable.MIN_CELL_WIDTH + 20,
      },
      {
        header: 'Repeat Callers',
        ref: 'repeatCallerCountByTime',
        cellType: ReportNumberCellContent,
      },
    ];

    cols = cols.map(col => {
      col.renderCell = cellProps => {
        const date = this.state.sortedRowOrder[cellProps.rowIndex];
        const value = this.report.getDataForDayAndMetric(date, col.ref);
        const dates = getDatesForCell({
          minStartDate: reportStartDate,
          maxEndDate: reportEndDate,
          date: moment.tz(date, timezone),
        });
        return React.createElement(col.cellType, {
          value,
          date,
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      };
      return col;
    });

    return (
      <div className="reportTable-primary">
        <ReportTable
          changeSort={this.changeTableSort}
          columns={cols}
          currentSort={this.state.currentSort}
          rowsCount={this.props.days.length}
          windowWidth={this.props.windowWidth}
        />
      </div>
    );
  }

  changeTableSort(metric) {
    let sort = getNextSortOrder(this.state.currentSort[metric]);
    let order = sortByOrder(this.state.sortedRowOrder, metric, sort, this.report.getDataForDayAndMetric);
    this.setState({
      sortedRowOrder: order,
      currentSort: { [metric]: sort },
    });
  }
}

IvrSummaryReport.propTypes = {
  days: PropTypes.arrayOf(PropTypes.string).isRequired,
  endDate: PropTypes.string.isRequired,
  endpointNumber: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  windowWidth: PropTypes.number,
};

const sortByOrder = (sortedRowOrder, metric, sort, getDataForDayAndMetric) =>
  _.orderBy(
    sortedRowOrder,
    date => {
      let val = metric === 'date' ? moment(date, ['YYYY-MM-DD']).valueOf() : getDataForDayAndMetric(date, metric);
      // Some values (durations) may be returned as null which should be treated as <0 for sorting
      return val !== null ? val : -1;
    },
    [sort.toLowerCase()]
  );

export default IvrSummaryReport;
