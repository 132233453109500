import _ from 'lodash';

import ChatNotification, { ChatNotificationTypes } from './chat_notification';
import CustomerView from 'models/location/customer_view';
import connect from 'components/lib/connect';
import DismissNotification from 'actions/notification/dismiss_notification';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';

function mapStateToProps(context, props) {
  let notification = props.notification;
  let customerProfile = _.get(notification, 'profile');
  let customerName = _.get(customerProfile, 'name');
  let customerEmail = customerProfile ? customerProfile.getDefaultEmail() : null;
  let notificationId = _.get(notification, 'id');
  let isCurrentChatNotification = isNotificationForCurrentChat(context, notification);
  let currentLocation = context.getProvider('currentLocation').get();

  return {
    cancelButtonTitle: 'Dismiss',
    currentLocation,
    customerName: customerName || customerEmail || 'Customer',
    isCurrentChatNotification,
    messageText: _.get(notification, 'conversationItem.content.text') || 'New message',
    notificationId,
    notificationTitle: 'Reply',
    notificationType: ChatNotificationTypes.REPLY,
    primaryButtonTitle: 'Reply',
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onPrimaryAction: () => {
      executeAction(DismissNotification, props.notification.id);
      executeAction(NavigateToConversation, {
        customerId: props.notification.profile.id,
        conversationId: props.notification.conversationId,
      });
    },
    onCancelAction: () => executeAction(DismissNotification, props.notification.id),
  };
}

const ChatReplyNotificationContainer = connect(mapStateToProps, mapExecuteToProps)(ChatNotification);

function isNotificationForCurrentChat(context, notification) {
  let currentLocation = context.getProvider('currentLocation').get();

  return (
    currentLocation instanceof CustomerView && currentLocation.currentConversationId === notification.conversationId
  );
}

export default ChatReplyNotificationContainer;
