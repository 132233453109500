import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const ProactiveChatActionType = createEnum('GREETING');
export const ProactiveChatSourceType = createEnum('API', 'CAMPAIGN');

export default createModel({
  modelName: 'ProactiveChatContent',

  properties: {
    action: prop().oneOf(..._.values(ProactiveChatActionType)).isRequired,
    campaignId: prop(String),
    sentAt: prop(String),
    source: prop().oneOf(..._.values(ProactiveChatSourceType)).isRequired,
    text: prop(String),
  },

  isGreetingMessage() {
    return this.action === ProactiveChatActionType.GREETING;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
