import _ from 'lodash';

import Observable from 'scripts/lib/observable_mixin';

export default function createGatewayClass(proto) {
  class Gateway {
    constructor(backend) {
      this._backend = backend;
    }
  }
  _.extend(Gateway.prototype, Observable);

  Object.getOwnPropertyNames(proto).forEach(propName => {
    Object.defineProperty(Gateway.prototype, propName, Object.getOwnPropertyDescriptor(proto, propName));
  });

  return Gateway;
}
