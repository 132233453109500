import React from 'react';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePlateEditorState } from '@udecode/plate';

import { Item } from 'components/text_editor_new/components/controls/controls_new';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';

const DEFAULT_ALIGNMENT = 'left';

export default function TextAlignmentMenu({ editorId }) {
  const editor = usePlateEditorState(editorId);

  const { targetRef, isOpen, onClose, onToggle, setTargetRef } = usePopoverMenu();
  const chevronDir = isOpen ? 'up' : 'down';

  // Check whether the current editor supports text alignment at all. Hide the controls if not
  if (!isFunction(editor?.getCurrentTextAlignment) || !isFunction(editor?.setTextAlignment)) return null;

  const currentAlignment = editor.getCurrentTextAlignment() || DEFAULT_ALIGNMENT;
  return (
    <OutsideClickHandler onClickOutside={onClose}>
      <ExpandableToolbarItem
        data-aid="textAlignmentMenuButton"
        data-alignment={currentAlignment}
        isActive={isOpen || currentAlignment !== DEFAULT_ALIGNMENT}
        onClick={onToggle}
        ref={setTargetRef}
        title="Text alignment"
      >
        <span>
          <i className={`fa fa-align-${currentAlignment}`} />
          <i className={`draftStylesMenu-item-chevron fa fa-angle-${chevronDir}`} />
        </span>
      </ExpandableToolbarItem>
      <PopoverMenu
        boundByWindow
        data-aid="textAlignmentMenu"
        isOpen={isOpen}
        onClickOutside={null}
        onClose={onClose}
        position="bottom"
        targetRef={targetRef}
      >
        <PopoverMenuItem data-aid="left" onClick={() => setAlign('left')}>
          Left
        </PopoverMenuItem>
        <PopoverMenuItem data-aid="right" onClick={() => setAlign('right')}>
          Right
        </PopoverMenuItem>
        <PopoverMenuItem data-aid="center" onClick={() => setAlign('center')}>
          Center
        </PopoverMenuItem>
        <PopoverMenuItem data-aid="justify" onClick={() => setAlign('justify')}>
          Justify
        </PopoverMenuItem>
      </PopoverMenu>
    </OutsideClickHandler>
  );

  function setAlign(alignment) {
    if (isFunction(editor?.setTextAlignment)) {
      editor.setTextAlignment(alignment);
    }
  }
}

const ExpandableToolbarItem = styled(Item)`
  width: 40px;
`;

TextAlignmentMenu.propTypes = {
  editorId: PropTypes.string.isRequired,
};
