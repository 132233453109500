import _ from 'lodash';
import { Factory } from 'rosie';
import '../conversation_item_factory';

function parseEntities(text) {
  return {
    userMentions: parseUserMentions(text)
  };
}

function parseUserMentions(text) {
  let res = [];
  const re = /(^|[^\w])@(\w+)/gi;
  let match;
  while((match = re.exec(text)) != null) {
    res.push({
      screenName: match[2].toLowerCase(),
      indices: [match.index + match[1].length, re.lastIndex - 1]
    });
  }
  return res;
}

Factory.define('Tweet')
  .attr('type', 'TWITTER')
  .attr('text')
  .attr('entities', ['text', 'entities'], function(text, entities) {
    return _.merge({}, entities, parseEntities(text));
  });

Factory.define('TweetWithDefaults')
  .extend('Tweet')
  .attr('user', { screenName: 'schwarzenegger' })
  .attr('text', "@Bennett I love factories, and whenever I'm passionate about a product, I want to see it being made.")
  .attr('entities', { userMentions: [{ screenName: 'bennett', indices: [0, 7] }] })
  .attr('retweetCount', 7337)
  .attr('favoriteCount', 1234);

Factory.define('TwitterItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function(content) {
    return Factory.attributes('Tweet', content);
  });

Factory.define('TwitterItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TweetWithDefaults', content);
  });