import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M15 8A7 7 0 111 8a7 7 0 0114 0zm1 0A8 8 0 110 8a8 8 0 0116 0zm-4.15-2.08a.56.56 0 000-.762.49.49 0 00-.72 0L6.837 9.7 4.87 7.62a.49.49 0 00-.72 0 .56.56 0 000 .76l2.327 2.462a.49.49 0 00.72 0l4.655-4.923z"
      fillRule="evenodd"
    />
  </Icon>
);
