import _ from 'lodash';
import httpStatus from 'http-status';

import { after } from 'scripts/infrastructure/backends/http_client';
import Observable from 'scripts/lib/observable_mixin';

export default class CreditCardGateway {
  constructor(http, requestorId) {
    this.http = http;
    this.requestorId = requestorId;
  }

  init({ agentId, orgId }) {
    this.agentId = agentId;
    this.orgId = orgId;
  }

  get(vaultEnvironment, vaultId, customerId, itemId) {
    this.http
      .axios()
      .post('/api/v1/users/chat_payment_tokens', {})
      .then(response => {
        const { userId, token } = response.data;
        const basicAuth = btoa(`${userId}:${token}`);
        const headers = {
          Authorization: `Basic ${basicAuth}`,
        };
        const url = this.url(vaultEnvironment, vaultId, customerId, itemId);
        after(this.http.axios().get(url, { headers }), this.handleGetResponse.bind(this));
      })
      .catch(err => {
        this.handleGetResponse(err, null);
      });
  }

  complete({ customerId, itemId }) {
    this.http
      .axios()
      .post(`/api/v1/secure-data/cc/orgs/${this.orgId}/customers/${customerId}/secure-payment/${itemId}/complete`)
      .then(res => this.notifyObservers('handleCompleted', res))
      .catch(err => this.notifyObservers('handleCompletedError', err));
  }

  handleGetResponse(err, res) {
    if (err) {
      this.notifyObservers('handleGetError', { error: err });
    } else if (res.status === httpStatus.OK) {
      this.notifyObservers('handleGetSuccess', res.data);
    } else {
      this.notifyObservers('handleGetError', { response: res });
    }
  }

  url(vaultEnvironment, vaultId, customerId, itemId) {
    const vgsUrl = this.getVgsUrl(vaultEnvironment, vaultId);
    return `${vgsUrl}/api/v1/secure-data/cc/orgs/${this.orgId}/customers/${customerId}/secure-payment/${itemId}`;
  }

  getVgsUrl(vaultEnvironment, vaultId) {
    if (vaultEnvironment === 'PRODUCTION') {
      return `https://${vaultId}.live.verygoodproxy.com`;
    }

    return `https://${vaultId}.sandbox.verygoodproxy.com`;
  }
}

_.extend(CreditCardGateway.prototype, Observable);
