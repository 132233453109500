import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';
import StartAnswerSearch from './start_answer_search';

export default class SetSearchLanguage {
  constructor(context) {
    this.context = context;
  }

  run({ language }) {
    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.setSearchLanguage(language);
    this.context.stores.answerPanel.set(answerPanel);

    const preferences = this.context.stores.agentPreferences.get();
    preferences.setAnswerSearchLanguage(language);
    setAgentPreferences(this.context, preferences);

    this.context.executeAction(StartAnswerSearch);
  }
}
