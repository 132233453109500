import _ from 'lodash';

export default class {
  constructor(context) {
    this.context = context;
  }

  run(customerProfile) {
    let phoneNumberToMatch;
    const numPhones = customerProfile.phones ? customerProfile.phones.length : 0;

    if (numPhones === 0) {
      return;
    }

    phoneNumberToMatch = _.get(_.last(customerProfile.phones), 'normalized', '');

    this.context.gateways.customerMatch.fetch(phoneNumberToMatch, customerProfile.id);
  }
}
