import React from 'react';
import classNames from 'classnames';
import { IdGenerator } from 'factories/lib/uuid';

function addChildClass(child, childClassName) {
  if (!child || !child.props) {
    return child;
  }

  const className = classNames(child.props && child.props.className, childClassName);

  const props = {
    className,
  };

  return React.cloneElement(child, props);
}

export default function childLayout(ContainerComponent) {
  return props => {
    const childClassName = `childLayoutContainer_child_${IdGenerator.newId()}`;
    const wrapperProps = { childClassName, ...props };
    return (
      <ContainerComponent {...wrapperProps}>
        {React.Children.map(props.children, child => addChildClass(child, childClassName))}
      </ContainerComponent>
    );
  };
}
