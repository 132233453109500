import _ from 'lodash';

import { asAttachment } from 'scripts/domain/factories/conversation_item/create_item_content';
import clearCommunicationComposition from 'actions/composition/lib/clear_communication_composition';
import convertAgentIdsToMentions from 'actions/composer/shared/convert_agent_ids_to_mentions';
import createTaskEditedItem from 'scripts/domain/factories/conversation_item/create_task_edited_item';
import CustomerView from 'models/location/customer_view';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import htmlToText from 'models/conversation_item/html_to_text';
import Task from 'models/task';
import tryUpdateTaskItem from 'actions/inbox/lib/try_update_task_item';

export default class EditTask {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, content }) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    const customerId = currentLocation.customerId;
    const agentId = this.context.stores.currentAgent.get().id;
    const composition = getCompositionsStore(this.context).findBy({ id: compositionId });
    if (!composition) {
      return;
    }

    const taskItem = this.context.stores.conversationHistory.findBy({ id: composition.content.conversationItemId });
    if (!taskItem) {
      return;
    }

    content = convertAgentIdsToMentions(this.context, content);

    const payload = this._createPayload({ taskItem, composition, content });
    this.setPendingTaskItem(taskItem, composition, content);
    this.context.gateways.task.update(this.currentAgentId, taskItem.id, payload);

    const taskEditedItem = createTaskEditedItem({
      agentId,
      customerId,
      note: taskItem.content.note,
      taskItemId: taskItem.id,
    });
    this._addConversationItem(taskEditedItem);
    this._clearComposition({ composition });

    this.context.analytics.track('Task Edited', { customerId, itemId: taskItem.id });
  }

  _clearComposition({ composition }) {
    composition.detachUploads();
    getCompositionsStore(this.context).replace(composition);
    clearCommunicationComposition(this.context);
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }

  _addConversationItem(conversationItem) {
    this.context.gateways.customerHistory.addItem(conversationItem.customerId, conversationItem.toJs());
  }

  _createPayload({ taskItem, composition, content }) {
    const id = taskItem.id;
    const customerId = taskItem.customerId;
    const conversation = { id: composition.conversationId };

    const attachmentsJs = _.map(composition.attachments || [], a => asAttachment(composition, a).toJs());
    const conversationItem = {
      content: {
        assignee: composition.content.assignee.toJs(),
        attachments: attachmentsJs,
        bodyHtml: content.bodyHtml,
        dueAt: composition.content.dueAt,
        mentions: _.map(content.mentions, m => m.toJs()),
        note: htmlToText(content.bodyHtml),
      },
    };
    return {
      id,
      customerId,
      conversation,
      conversationItem,
    };
  }

  setPendingTaskItem(taskItem, composition, content) {
    const oldContent = taskItem.toJs().content;

    const mergedUpdates = {
      ...oldContent,
      assignee: composition.content.assignee,
      attachments: _.map(composition.attachments || [], a => asAttachment(composition, a)),
      bodyHtml: content.bodyHtml,
      dueAt: composition.content.dueAt,
      mentions: content.mentions,
      note: htmlToText(content.bodyHtml),
    };

    const newContent = new Task(mergedUpdates);
    taskItem.replaceContent(newContent);
    this.context.stores.conversationHistory.setPending(taskItem);

    tryUpdateTaskItem(this.context, { item: taskItem, force: true });
  }
}
