import StandardGateway from './lib/standard_gateway';

const DEFAULT_TIMEOUT = 10000;

export default class SnippetSearchResultHttpGateway extends StandardGateway {
  constructor(backend) {
    super(backend, { fetchUrl: '/api/v1/orgs/:orgId/search/snippets' });
  }

  request(id, params) {
    let url = this.endpoint.set(params).fetchUrl;

    return this.http
      .post(url, params, { timeout: DEFAULT_TIMEOUT })
      .then(res => {
        this.observer.onFetchSuccess(res, id);
      })
      .catch(error => {
        this.observer.onFetchError(error);
      });
  }
}
