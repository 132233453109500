import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import PrepositionalPhrase from '../metadata/prepositional_phrase';
import RoutingItem from 'models/routing_item';
import RoutingItemType from 'models/routing_item_type';
import RuleLink from '../metadata/rule_link';
import TaskRouting from 'models/task_routing';

// Deprecated. Use the strings defined in routing_item.jsx instead.
class RoutingPhrases extends React.Component {
  render() {
    let { assignee, assigneeWas, itemType, taskItemId } = this.props.routingContent;

    let prepositionalPhrases = [];

    switch (itemType) {
      // used by task_routing_item.jsx
      case RoutingItemType.AGENT_REQUEST:
        if (
          assigneeWas &&
          assigneeWas.routingGroupId === assignee.routingGroupId &&
          assigneeWas.agentId &&
          assignee.agentId &&
          assigneeWas.agentId !== assignee.agentId
        ) {
          this.addStolenPhrases(prepositionalPhrases);
          break;
        }

        if (!this.props.assigneeWasNames) {
          prepositionalPhrases.push({
            preposition: 'to',
            complement: getAssigneeName(this.props.assigneeNames),
          });
        } else {
          this.addAssigneePhrases(prepositionalPhrases, assignee, assigneeWas);
        }

        prepositionalPhrases.push({
          preposition: 'on',
          complement: 'request',
          suppressHighlight: true,
        });
        break;

      // used by task_routing_item.jsx
      case RoutingItemType.AUTOMATED_ASSIGNMENT: {
        this.addAssigneePhrases(prepositionalPhrases, assignee, assigneeWas);

        let ruleComplement = <RuleLink ruleId={this.props.initiatorId}>rule</RuleLink>;
        prepositionalPhrases.push({
          preposition: 'by',
          complement: ruleComplement,
        });
        break;
      }

      // used by task_routing_item.jsx
      case RoutingItemType.MANUAL_ASSIGNMENT:
        this.addAssigneePhrases(prepositionalPhrases, assignee, assigneeWas);

        if (this.props.initiatorType !== Communicator.API) {
          prepositionalPhrases.push({
            preposition: 'by',
            complement: this.props.initiatorName,
          });
        }
        break;

      // used by task_routing_item.jsx (backward compatibility; not in use since Jul 2020)
      case RoutingItemType.REROUTED_UNASSIGNED:
        prepositionalPhrases = this.getReroutedUnassignedMessage(assignee, assigneeWas, taskItemId);
        break;

      // used by task_routing_item.jsx
      case RoutingItemType.USER_DISABLED:
        prepositionalPhrases = this.getUserDeactivatedMessage(assignee, assigneeWas);
        break;

      default:
        break;
    }
    return (
      <span className="routingPhrases">
        {prepositionalPhrases.map((phrase, i) => (
          <PrepositionalPhrase {...phrase} key={i} />
        ))}
      </span>
    );
  }

  addAssigneePhrases(prepositionalPhrases, assignee, assigneeWas) {
    let hasAgentChanged = this.hasAgentAssignmentChanged(assigneeWas, assignee);
    let hasGroupChanged = this.hasGroupAssignmentChanged(assigneeWas, assignee);

    let complement1;
    let complement2;

    // hasAgentChanged is false if there was previously no assignee
    if (!hasAgentChanged && !hasGroupChanged) {
      complement1 = getAssigneeName(this.props.assigneeWasNames);
      complement2 = getAssigneeName(this.props.assigneeNames);
    } else if (hasAgentChanged) {
      complement1 = getAssigneeName(this.props.assigneeWasNames);
      complement2 = getAssigneeName(this.props.assigneeNames);
    } else {
      complement1 = _(this.props.assigneeWasNames).get('routingGroup');
      complement2 = this.props.assigneeNames.routingGroup;
    }

    prepositionalPhrases.push({
      preposition: 'from',
      complement: complement1,
    });

    prepositionalPhrases.push({
      preposition: 'to',
      complement: complement2,
    });
  }

  getReroutedUnassignedMessage(assignee, assigneeWas, taskItemId) {
    return [
      {
        preposition: 'from',
        complement: getAssigneeName(this.props.assigneeWasNames),
      },
      {
        preposition: 'to',
        complement: this.getChangedAssigneeName(assigneeWas, assignee),
      },
      {
        preposition: 'because',
        complement: taskItemId ? 'of task reassignment' : 'of conversation reassignment',
        suppressHighlight: true,
      },
    ];
  }

  addStolenPhrases(prepositionalPhrases) {
    const assigneeWas = getAssigneeName(this.props.assigneeWasNames);
    prepositionalPhrases.push({
      preposition: 'to',
      complement: getAssigneeName(this.props.assigneeNames),
    });
    prepositionalPhrases.push({
      preposition: 'because',
      complement: assigneeWas,
      suppressHighlight: true,
    });
    prepositionalPhrases.push({
      preposition: assigneeWas === 'you' ? 'were' : 'was',
      complement: 'offline',
      suppressHighlight: true,
    });
  }

  getUserDeactivatedMessage(assignee, assigneeWas) {
    let deactivatedUsername = getAssigneeName(this.props.assigneeWasNames);
    let complementVerb = deactivatedUsername === 'you' ? 'were' : 'was';

    return [
      {
        preposition: 'from',
        complement: deactivatedUsername,
      },
      {
        preposition: 'to',
        complement: this.getChangedAssigneeName(assigneeWas, assignee),
      },
      {
        preposition: 'because',
        complement: `${deactivatedUsername} ${complementVerb} deactivated`,
        suppressHighlight: true,
      },
    ];
  }

  hasAgentAssignmentChanged(assigneeWas, assignee) {
    if (!assigneeWas || !assigneeWas.agentId) {
      return assignee.agentId;
    }
    if (assignee.agentId) {
      return assignee.agentId !== assigneeWas.agentId;
    }
    return false;
  }

  hasGroupAssignmentChanged(assigneeWas, assignee) {
    if (!assigneeWas || !assigneeWas.routingGroupId) {
      return assignee.routingGroupId;
    }
    if (assignee.routingGroupId) {
      return assignee.routingGroupId !== assigneeWas.routingGroupId;
    }
    return false;
  }

  getChangedAssigneeName(assigneeWas, assignee) {
    if (this.hasAgentAssignmentChanged(assigneeWas, assignee)) {
      return this.props.assigneeNames.agent;
    }
    return this.props.assigneeNames.routingGroup;
  }
}

const assigneeNamesPropType = PropTypes.shape({ agent: PropTypes.string, routingGroup: PropTypes.string.isRequired });
const routingContentPropType = PropTypes.oneOfType([
  PropTypes.instanceOf(RoutingItem),
  PropTypes.instanceOf(TaskRouting),
]);

RoutingPhrases.propTypes = {
  assigneeNames: assigneeNamesPropType.isRequired,
  assigneeWasNames: assigneeNamesPropType,
  initiatorId: PropTypes.string,
  initiatorType: PropTypes.string,
  initiatorName: PropTypes.string,
  routingContent: routingContentPropType.isRequired,
};

function getAssigneeName(assigneeNames) {
  return assigneeNames && (assigneeNames.agent || assigneeNames.routingGroup);
}

// Deprecated. Use the strings defined in routing_item.jsx instead.
const RoutingPhrasesContainer = connect(mapStateToProps)(RoutingPhrases);

function mapStateToProps({ getProvider, isFeatureEnabled }, { initiatorId, routingContent, initiatorType }) {
  return {
    assigneeNames: getAssigneeNames(routingContent.assignee),
    assigneeWasNames: routingContent.assigneeWas && getAssigneeNames(routingContent.assigneeWas),
    initiatorId,
    initiatorType,
    initiatorName: initiatorId && getNameOrYou(initiatorId),
    routingContent,
  };

  function getAssigneeNames({ agentId, routingGroupId }) {
    return {
      agent: agentId && getNameOrYou(agentId),
      routingGroup: getRoutingGroupName(routingGroupId),
    };
  }

  function getNameOrYou(agentId) {
    return agentId === getCurrentAgentId() ? 'you' : getAgentName(agentId);
  }

  function getCurrentAgentId() {
    return getProvider('currentAgent').get().id;
  }

  function getAgentName(agentId) {
    let agent = getProvider('agents').findBy({ id: agentId });
    return agent ? agent.getDisplayName() : '';
  }

  function getRoutingGroupName(routingGroupId) {
    let group = getProvider('routingGroups').findBy({ id: routingGroupId });
    return group ? group.name : '';
  }
}

RoutingPhrasesContainer.propTypes = {
  initiatorId: PropTypes.string,
  routingContent: routingContentPropType.isRequired,
};

export default RoutingPhrasesContainer;
