import _ from 'lodash';

export default function getAssigneeDetails({ getProvider, isFeatureEnabled }, { agentId, groupId }) {
  const groupProvider = getProvider('routingGroups');
  const agentProvider = getProvider('agents');
  const currentAgent = getProvider('currentAgent').get();
  let assignee = {};

  if (agentId) {
    let agent = agentProvider.findBy({ id: agentId });
    assignee = { agentId };
    assignee.agentName = agent && agent.getDisplayName();
    assignee.avatarImage = agent && agent.avatarUrl;
    assignee.isAssigneeCurrentAgent = agentId === currentAgent.id;
  }
  if (groupId) {
    const group = groupProvider.findBy({ id: groupId });
    assignee.groupId = groupId;
    assignee.groupName = _.get(group, 'name');
    if (group && group.disabled) {
      assignee.groupName = `${group.name} (Archived)`;
    }
  }
  return assignee;
}
