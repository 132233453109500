// These are methods used to created email body from the part input by the
// agent and the quoted text. They are also used to extract quoted text from
// the whole email so should be kept in sync.

const quotedPlainPadding = '\n\n';

export function convertToQuotedPlainBodyReply(bodyPlainStripped, quotedPlain) {
  if (quotedPlain) {
    return `${bodyPlainStripped}${quotedPlainPadding}${quotedPlain}`;
  }
  return bodyPlainStripped;
}

export function extractQuotedPlainTextFromEmail(bodyPlain, bodyPlainStripped) {
  if ((bodyPlainStripped && bodyPlainStripped.length) < bodyPlain.length - quotedPlainPadding.length) {
    return bodyPlain.substring(bodyPlainStripped.length + quotedPlainPadding.length);
  }
  return undefined;
}

export function convertToQuotedHtmlBodyReply(bodyHtmlStripped, quotedHtml) {
  if (quotedHtml) {
    return `${bodyHtmlStripped}${quotedHtml}`;
  }
  return bodyHtmlStripped;
}

export function extractQuotedHtmlTextFromEmail(bodyHtml, bodyHtmlStripped) {
  if ((bodyHtmlStripped && bodyHtmlStripped.length) < bodyHtml.length) {
    return bodyHtml.substring(bodyHtmlStripped.length);
  }
  return undefined;
}
