import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';

/**
 * Stops imitation of current user.
 *
 */

export default class StopImitation {
  constructor(context) {
    this.context = context;
  }

  run() {
    const claims = this.context.stores.auth.get().claims;
    const orgId = claims.orgId;
    const userId = claims.userId;
    this.context.gateways.imitation
      .delete({ orgId, userId })
      .then(() => {
        this.context.reset();
        this.context.capacityManager.stop();
        this.navigateToUrl('/admin/imitate');
      })
      .catch(err => handleCommonErrors(this.context, err));
  }

  navigateToUrl(location) {
    window.location.assign(location);
  }
}
