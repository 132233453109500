import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import { updateStore } from 'actions/routing/lib/agent_routing_preferences';

export default class AgentRoutingPreferencesGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  onBroadcast(dto) {
    updateStore(this.context, dto);
  }

  onFetchError(err) {
    this.errorHandler.handleCommonErrors(err);
  }

  onFetchSuccess(dto) {
    updateStore(this.context, dto);
  }
}
