import addPlaceholderCustomerToInbox from './add_placeholder_customer_to_inbox';
import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import AgentInboxObserver from 'actions/inbox/agent/agent_inbox_observer';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import { getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import { isSubscribedToItem } from 'actions/inbox/agent/agent_inbox_subscriptions';
import { reconcileInboxItem } from 'actions/inbox/agent/agent_inbox_helpers';
import { subscribeToCustomer, unsubscribe } from 'actions/inbox/agent/request_agent_inbox_deprecated';

export default function addCustomerToInbox(context, { conversationId, customerId, store }) {
  let itemsStore = store || context.stores.agentInboxItems;

  if (!context.stores.customers.has({ id: customerId })) {
    addPlaceholderCustomerToInbox(context, customerId, itemsStore);
  } else {
    let { profile, conversations, conversationHistory } = context.stores.customers.storesFor(customerId);

    conversationId =
      conversationId || getLatestConversationId(context.stores.customers.storesFor(customerId).conversations);

    let previewItem = getLatestManualItem({ conversationHistory, conversationId, filter: null });
    let newItem = new AgentInboxItem({
      id: customerId,
      conversation: conversations.findBy({ id: conversationId }),
      item: previewItem,
      profile: profile.get(),
      type: AgentInboxItem.Type.CUSTOMER,
    });
    newItem.setManuallyCreatedAtToNow();
    reconcileInboxItem(context, newItem, itemsStore);
  }

  if (!isSubscribedToItem(customerId)) {
    const observer = new AgentInboxObserver(context);
    const auth = context.stores.auth.get();
    const orgId = auth.getOrgId();
    subscribeToCustomer(context.backend, observer, orgId, customerId).catch(err => {
      unsubscribe(context.backend, customerId);
      ErrorReporter.reportError(err);
    });
  }
}
