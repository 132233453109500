import { StyledLeaf, withProps } from '@udecode/plate';
import { css } from 'styled-components';

export const StyledAITextCompletionSelection = withProps(StyledLeaf, {
  as: 'span',
  styles: {
    root: css`
      border-radius: 1px;
      background: #9dc9fb;
      box-shadow: 0 0 0 3px #9dc9fb;
    `,
  },
});
