import _ from 'lodash';

export default class FakeTwilio {
  get Device() {
    return this;
  }

  setup(token, parameters) {
    this.token = token;
    this.parameters = parameters;
    return true;
  }

  destroy() {
    this.deviceIsDestroyed = true;
  }

  disconnectAll() {
    this.deviceDisconnected = true;
  }

  on(event, callback) {
    this[`${event}Callback`] = callback;
  }

  status() {
    return 'ready';
  }

  simulateIncomingCall() {
    let connection = {
      accept: this.onClientAccept || _.noop,
      disconnect: this.onClientDisconnect || _.noop,
      on: _.noop,
      reject: _.noop,
      sendDigits: _.noop,
      status: () => 'open',
    };
    this.incomingCallback(connection);
  }
}
