import React from 'react';

import { VARIABLE } from '../../plugins/variables';

export const variableRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'variable') {
        return {
          object: 'inline',
          type: VARIABLE,
          nodes: next(el.childNodes),
          data: { variableId: el.getAttribute('data-id'), variableType: el.getAttribute('data-type') },
        };
      }
    },
    serialize(object, children) {
      if (object.object === 'inline' && object.type === 'variable') {
        return (
          <variable data-id={object.data.get('variableId')} data-type={object.data.get('variableType')}>
            {children}
          </variable>
        );
      }
    },
  },
];

export const externalVariableRules = [
  {
    serialize(object, children) {
      if (object.object === 'inline' && object.type === 'variable') {
        return object.text ? <React.Fragment>{children}</React.Fragment> : <br />;
      }
    },
  },
];
