import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import SummaryCard from './summary_card';

export default class ExpandableSummaryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };

    this.onClickExpander = this.onClickExpander.bind(this);
  }

  onClickExpander() {
    this.setState(({ isExpanded }) => {
      return { isExpanded: !isExpanded };
    });
  }

  render() {
    let { children, className, footer, header, isLoading, limit } = this.props;
    const { isExpanded } = this.state;

    const fewerThanLimit = children.length <= limit;
    const renderedChildren =
      (children.length && (
        <div className="customerSummaryCard-expandableChildren">
          {fewerThanLimit || isExpanded ? children : children.slice(0, limit)}
        </div>
      )) ||
      null;
    const expander = fewerThanLimit ? null : (
      <SummaryCardExpander isDownwards={!this.state.isExpanded} onClick={this.onClickExpander} />
    );

    let wrappedFooter = null;
    if (footer) {
      const footerClassNames = classnames('expandableCustomerSummaryCard-footer', {
        'expandableCustomerSummaryCard-footer-empty': !children.length,
        'expandableCustomerSummaryCard-footer-withExpander': !!expander,
      });
      let existingClassName = _.get(footer, 'props.className');
      let className = classnames(existingClassName, footerClassNames);
      wrappedFooter = React.cloneElement(footer, {
        ...footer.props,
        className,
      });
    }

    return (
      <SummaryCard className={className} isLoading={isLoading}>
        {header}
        {renderedChildren}
        {wrappedFooter}
        {expander}
      </SummaryCard>
    );
  }
}

ExpandableSummaryCard.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  footer: PropTypes.node,
  header: PropTypes.node,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
};

ExpandableSummaryCard.defaultProps = {
  children: [],
  limit: 3,
};

export function SummaryCardExpander({ isDownwards, onClick }) {
  const chevronClassNames = classnames('customerSummaryCard-expander-chevron', 'ion-chevron-up', {
    'customerSummaryCard-expander-chevron-downwards': isDownwards,
  });

  return (
    <div className="customerSummaryCard-expander" onClick={onClick} onMouseDown={onMouseDown}>
      <i className={chevronClassNames} />
    </div>
  );

  function onMouseDown(e) {
    e.preventDefault(); // Prevents selecting text if you click too much
  }
}

SummaryCardExpander.propTypes = {
  isDownwards: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
