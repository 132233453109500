import React from 'react';
import { createPluginFactory, ELEMENT_PARAGRAPH, onKeyDownToggleElement } from '@udecode/plate';
import get from 'lodash/get';

/**
 * Enables support for paragraphs. We serialize to `<div>` (not to Plate standard `<p>`) for
 * backward compatibility with the old Slate. That's the only reason this custom plugin exists.
 */
export default function createParagraphPlugin() {
  return createPluginFactory({
    key: ELEMENT_PARAGRAPH,
    isElement: true,
    handlers: {
      onKeyDown: onKeyDownToggleElement,
    },
    deserializeHtml: {
      rules: [
        {
          validNodeName: ['P', 'DIV'],
        },
      ],
    },
    serializeHtml: ({ element, children }) => {
      if (element.type !== ELEMENT_PARAGRAPH) return children;
      const isEmptyLine = element.children.length === 1 && get(element, 'children[0].text') === '';
      const childrenToRender = isEmptyLine ? React.createElement('br', {}) : children;
      return React.createElement('div', {}, childrenToRender);
    },
  })();
}
