import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ProfileCardType from 'models/configuration/profile_card_type';
import {
  FlightAttributesPropTypes,
  OrderAttributesPropTypes,
  StayAttributesPropTypes,
  SubscriptionAttributesPropTypes,
} from 'components/customer/summary/transactions/constants';
import {
  FlightTransactionDef,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
} from 'models/configuration/transaction_def';
import { transactionDefTypeReflect } from 'models/configuration/transaction_def_from_js';
import VisibleCustomAttribute from 'components/customer/summary/custom_attributes/visible_custom_attribute';

export default function ExpandedTransactionBase({ attributes, headerRenderer, transactionDef }) {
  return (
    <div className="transaction-expandedInformation">
      <div className="transaction-expandedInformation-topTriangle">
        <div className="transaction-expandedInformation-topTriangle-border" />
      </div>
      {renderHeader()}
      <hr className="transaction-middleBorder" />
      {renderAttributes()}
    </div>
  );

  function renderHeader() {
    return _.isFunction(headerRenderer) ? headerRenderer(attributes) : null;
  }

  function renderAttributes() {
    return (
      <div className="transaction-expandedInformation-optionalAttributes">
        {_.map(transactionDef.attributes, def => {
          const key = `optionalAttributes-${def.attr}`;
          return (
            <VisibleCustomAttribute
              className="transactionAttribute"
              def={def}
              key={key}
              location={{
                type: ProfileCardType.TRANSACTIONS,
                subType: transactionDefTypeReflect.instanceToType(transactionDef),
              }}
              value={attributes[def.attr]}
            />
          );
        })}
      </div>
    );
  }
}

ExpandedTransactionBase.propTypes = {
  attributes: PropTypes.oneOfType([
    FlightAttributesPropTypes,
    OrderAttributesPropTypes,
    StayAttributesPropTypes,
    SubscriptionAttributesPropTypes,
  ]),
  headerRenderer: PropTypes.func,
  transactionDef: PropTypes.oneOfType([
    PropTypes.instanceOf(FlightTransactionDef),
    PropTypes.instanceOf(OrderTransactionDef),
    PropTypes.instanceOf(StayTransactionDef),
    PropTypes.instanceOf(SubscriptionTransactionDef),
  ]).isRequired,
};
