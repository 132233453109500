import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 60 60" {...props} className="glassesIcon">
    <g fill="none" fillRule="evenodd">
      <circle className="glassesIcon-circle" cx="30" cy="30" r="30" transform="translate(.67)" />
      <g className="glassesIcon-stroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M28.858 30.5c0 2.702-2.165 4.89-4.834 4.89-2.67 0-4.833-2.188-4.833-4.89 0-2.7 2.164-4.888 4.834-4.888 2.67 0 4.833 2.19 4.833 4.89zM32.483 30.5c0 2.702 2.162 4.89 4.833 4.89 2.67 0 4.833-2.188 4.833-4.89 0-2.7-2.165-4.888-4.834-4.888-2.67 0-4.834 2.19-4.834 4.89z" />
        <path d="M28.858 30.5c0-1.01.81-1.832 1.812-1.832 1 0 1.813.82 1.813 1.833M16.774 30.5c0-1.09.233-2.122.656-3.052M44.566 30.5c0-1.09-.236-2.122-.658-3.052" />
      </g>
    </g>
  </IconContainer>
);
