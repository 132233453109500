import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import CustomerEmailAddress from 'models/customer_email_address';
import PhoneNumber from 'models/phone_number';

const ExternalCustomerProfile = createModel({
  modelName: 'ExternalCustomerProfile',
  properties: {
    address: String,
    customAttributes: Object,
    emails: prop([CustomerEmailAddress]).default([]),
    externalCustomerId: String,
    externalCustomerIds: prop(Object).default({}),
    name: String,
    phones: prop([PhoneNumber]).default([]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const ExternalCustomerLookupResult = createModel({
  modelName: 'ExternalCustomerLookupResult',
  properties: {
    customerId: String,
    id: prop(String).isRequired,
    results: [ExternalCustomerProfile],
    resultsByIntegrationId: prop(Object).default({}),
    suggestionsByIntegrationId: prop(Object).default({}),
  },

  getResults() {
    return this.results;
  },

  getResultsByIntegrationId() {
    return this.resultsByIntegrationId;
  },

  getSuggestionsByIntegrationId() {
    return this.suggestionsByIntegrationId;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const LookupLevel = createEnum('BASIC', 'DETAILED');

const ExternalCustomerLookupRequest = createModel({
  modelName: 'ExternalCustomerLookupRequest',
  properties: {
    lookupLevel: prop(String).oneOf(..._.keys(LookupLevel)),
    query: Object,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export { ExternalCustomerLookupResult, ExternalCustomerLookupRequest };
export default ExternalCustomerProfile;
