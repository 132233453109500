export default class MqttAdapter {
  constructor(backend) {
    this.client = backend;
  }

  subscribe(topic, onBroadcast) {
    if (onBroadcast == null) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      this.client.subscribe(topic, onBroadcast, resolve);
    });
  }

  unsubscribe(topic, onBroadcast) {
    if (onBroadcast == null) {
      return Promise.resolve();
    }

    this.client.unsubscribe(topic, onBroadcast);
    return Promise.resolve();
  }
}
