import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { removeInlineOfTypeOnBackspace } from '../selection';

export const AGENT_MENTION = 'agent-mention';
export const AGENT_MENTION_SEARCH = 'agentMentionSearch';
export const AGENT_MENTION_ACTIVATION_CHARACTER = '@';

export default function AgentMentions(agentMentionRef, agentMentionSearchRef) {
  const completeAgentMentionSearch = (editor, attrs) => {
    completeAgentMentionSearchBase(editor, attrs);
    agentMentionRef.current.hasJustInsertedMention = true;
  };

  return {
    commands: { completeAgentMentionSearch, removeAgentMentionSearch, startAgentMentionSearch },
    queries: { getAgentMentionText, getMentionedAgentIds },

    onBlur(evt, editor, next) {
      // Remove existing agent mention search when we blur the editor
      if (editor.getAgentMentionText() !== null) {
        editor.removeAgentMentionSearch();
      }
    },

    onChange(editor, next) {
      const { value } = editor;

      removeAgentMentionSearchIfSelectionMovedOutside(editor);

      const mentionInlines = value.inlines.filter(i => i.type === AGENT_MENTION);
      if (!mentionInlines.count()) {
        return next();
      }

      // Ensure when we click inside of a completed mention, that it selects the whole thing / treats it as atomic
      return editor.ensureFullSelection(next, AGENT_MENTION);
    },

    onKeyDown(evt, editor, next) {
      const { value } = editor;

      // If we just inserted a mention, an agent _might_ not want the extra whitespace we auto-insert afterwards,
      // so if they try to insert another space or punctuation that really doesn't want whitespace in front of it
      // (like a period or a comma), then remove the extra whitespace.
      if (agentMentionRef.current.hasJustInsertedMention && isInsertingKey(evt.key)) {
        agentMentionRef.current.hasJustInsertedMention = false;
        if (isPunctuationOrSpace(evt.key) && isSelectionCollapsedAndHasWhitespaceBefore(editor)) {
          editor.deleteBackward();
        }
      }

      // If our cursor is directly after or anywhere in/around a completed mention, remove it all on backspace
      if (removeInlineOfTypeOnBackspace(editor, evt, AGENT_MENTION)) {
        return;
      }

      // If we don't have an existing agent mention search and hit `@`, then try to start one
      if (evt.key === AGENT_MENTION_ACTIVATION_CHARACTER && !editor.getAgentMentionText()) {
        // Don't start @mentions if the @ isn't at the beginning of a line or has a whitespace before it.
        if (editor.value.selection.start.offset !== 0) {
          const textNode = editor.value.texts.last();
          const text = textNode && textNode.text;
          if (text && !/\s/i.test(text.charAt(editor.value.selection.start.offset - 1))) {
            return next();
          }
        }
        evt.preventDefault();
        editor.insertText(AGENT_MENTION_ACTIVATION_CHARACTER);
        editor.startAgentMentionSearch();
        return;
      }

      // Remove the agent mention search if we hit escape
      if (evt.key === 'Escape' && editor.getAgentMentionText() !== null) {
        editor.removeAgentMentionSearch();
        return next();
      }

      let decorations = value.decorations.filter(decoration => decoration.mark.type === AGENT_MENTION_SEARCH);
      const hasMentions = decorations.size > 0;

      if (!hasMentions) {
        return next();
      }

      // If we hit space and already have one space in our mention, remove the mention.
      if (evt.key === ' ') {
        const agentMentionText = editor.getAgentMentionText();
        if (!agentMentionText || agentMentionText.indexOf(' ') > -1) {
          editor.removeAgentMentionSearch();
          return next();
        }
      }

      // The AgentMentionMenu component maintains the state of what items are currently visible / what is currently selected, so we
      // delegate the responsibility of handling these events to the menu itself.
      if (evt.key === 'Enter' || evt.key === 'ArrowUp' || evt.key === 'ArrowDown' || evt.key === 'Tab') {
        // If we hit enter and have something to mention, mention. Otherwise, remove the mention.
        if (evt.key === 'Enter' || evt.key === 'Tab') {
          const inserted = agentMentionRef.current.onInsert();
          if (inserted) {
            evt.preventDefault();
          } else {
            editor.removeAgentMentionSearch();
            return next();
          }
        } else if (evt.key === 'ArrowUp') {
          evt.preventDefault();
          agentMentionRef.current.onArrowUp();
        } else if (evt.key === 'ArrowDown') {
          evt.preventDefault();
          agentMentionRef.current.onArrowDown();
        }
        return;
      }

      // If backspace, and if we're deleting the last character (the '@'), remove the decoration.
      if (evt.key === 'Backspace') {
        let decoration = decorations.get(0);
        let fragment = value.document.getFragmentAtRange({ anchor: decoration.anchor, focus: decoration.focus });
        if (fragment.text.length === 1) {
          editor.removeAgentMentionSearch();
        }
      }

      return next();
    },

    renderMark(props, editor, next) {
      const { mark, children, attributes } = props;
      if (mark.type === AGENT_MENTION_SEARCH) {
        return (
          <AgentMentionSearch {...attributes} ref={agentMentionSearchRef}>
            {children}
          </AgentMentionSearch>
        );
      }
      return next();
    },

    renderNode(props, editor, next) {
      if (props.node.type === AGENT_MENTION) {
        const { attributes, children } = props;
        return <StyledAgentMention {...attributes}>{children}</StyledAgentMention>;
      }
      return next();
    },

    schema: {
      inlines: {
        [AGENT_MENTION]: {
          nodes: [
            {
              match: { object: 'text' },
              min: 1,
              max: 1,
            },
          ],
          text: /.+/, // Can't have empty agent mentions
        },
      },
    },
  };
}

function isInsertingKey(key) {
  return key !== 'Control' && key !== 'Shift';
}

function isPunctuationOrSpace(key) {
  return /[.,:;!?' ]/g.test(key);
}

function isSelectionCollapsedAndHasWhitespaceBefore(editor) {
  const selection = editor.value.selection;
  if (!selection.isCollapsed || selection.anchor.offset !== 1) {
    return false;
  }

  const text = editor.value.texts.first();
  return text.text.slice(0, 1) === ' ';
}

const StyledAgentMention = styled.span`
  background-color: ${p => p.theme.colors.gray100};
  border-radius: 4px;
  color: ${p => p.theme.colors.green400};
  font-weight: bold;
  padding: 4px;

  &:hover {
    background-color: ${p => p.theme.colors.gray200};
  }
`;

const AgentMentionSearch = styled.span.attrs({ 'data-agentmention': true })``;

export function completeAgentMentionSearchBase(editor, { email, id, name }) {
  const agentMentionText = editor.getAgentMentionText();
  editor.removeAgentMentionSearch();
  editor.deleteBackward(agentMentionText.length + 1);
  const selectedRange = editor.value.selection;

  // So we've removed the mention search completely, and have the selection where we want to insert the actual agent mention.
  // We insert a space _first_, and then we insert the full mention _before_ the space using that selection.
  // Weird i know
  editor
    .insertText(' ')
    .insertInlineAtRange(selectedRange, {
      data: {
        agentId: id,
      },
      nodes: [
        {
          object: 'text',
          leaves: [
            {
              text: `@${name || email}`,
            },
          ],
        },
      ],
      type: AGENT_MENTION,
    })
    .focus();
}

// For an active mention search such as "@mary", return "mary"
export function getAgentMentionText(editor) {
  const value = editor.value;
  let mention = value.decorations.filter(decoration => decoration.mark.type === AGENT_MENTION_SEARCH);
  if (mention.size === 0) return null; // if a mention has not been started, there is no mention text
  if (!value.startText) return null;

  let decoration = mention.get(0);
  let fragment = value.document.getFragmentAtRange({ anchor: decoration.anchor, focus: decoration.focus });
  let text = fragment.text;

  if (text.slice(0, 1) !== AGENT_MENTION_ACTIVATION_CHARACTER) {
    return null;
  }

  return text.slice(1);
}

// Retrieve a deduplicate array of all agent ids mentioned in the editor currently.
export function getMentionedAgentIds(editor) {
  const agentMentionInlines = editor.value.document.getInlinesByType(AGENT_MENTION);
  const agentIds = [];
  agentMentionInlines.forEach(inline => agentIds.push(inline.data.get('agentId')));
  return _.uniq(agentIds);
}

// Stop trying to mention an agent
export function removeAgentMentionSearch(editor) {
  const value = editor.value;
  let decorations = value.decorations.filter(decoration => decoration.mark.type !== AGENT_MENTION_SEARCH);
  editor.setDecorations(decorations);
}

// If you start a mention but move the cursor away, remove it
function removeAgentMentionSearchIfSelectionMovedOutside(editor) {
  let mentionSearches = editor.value.decorations.filter(decoration => decoration.mark.type === AGENT_MENTION_SEARCH);
  if (!mentionSearches.size) {
    return;
  }

  const mentionSearch = mentionSearches.get(0);
  if (
    mentionSearch &&
    (!editor.value.selection.anchor.isInRange(mentionSearch) || !editor.value.selection.focus.isInRange(mentionSearch))
  ) {
    editor.removeAgentMentionSearch();
  }
}

// Start trying to mention an agent
export function startAgentMentionSearch(editor) {
  // Remove any stray @mentions if they exist
  let decorations = editor.value.decorations.filter(decoration => decoration.mark.type !== AGENT_MENTION_SEARCH);

  // We might not have an @ inserted yet (e.g. if we click the @ button to insert mentions instead).
  let { selection } = editor.value;
  const firstText = editor.value.texts.first();
  const charBefore = firstText.text.slice(selection.start.offset - 1, selection.start.offset);
  if (charBefore !== AGENT_MENTION_ACTIVATION_CHARACTER) {
    editor.insertText(AGENT_MENTION_ACTIVATION_CHARACTER);
  }

  selection = editor.value.selection;
  let decoration = {
    anchor: { key: selection.start.key, offset: selection.start.offset - 1 },
    focus: { key: selection.start.key, offset: selection.start.offset },
    mark: { type: AGENT_MENTION_SEARCH },
  };

  decorations = decorations.push(decoration);
  editor.setDecorations(decorations);
}
