import updateConnectionState from 'actions/health/lib/update_connection_state';

export default class NetworkConnectionEventHandler {
  constructor(context) {
    this.context = context;
    this.updateConnectionState = updateConnectionState.bind(this, 'network');
  }

  handleDisconnect() {
    this.updateConnectionState(state => state.setDisconnected());
  }

  handleConnect() {
    this.updateConnectionState(state => state.setConnected());
  }
}
