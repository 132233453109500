import _ from 'lodash';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import InsetContainer from 'components/common/containers/inset_container';
import { PortalledPopover, POPOVER_PROPS } from 'components/common/lib/_popover';

import useWindowSize from 'components/hooks/use_window_size';

// Contains close() callback so items can close menu after clicking.
const PopoverMenuContext = createContext({
  onClose: () => {},
});

/**
 * @visibleName Popover Menu
 */

export default function PopoverMenu(props) {
  const { 'data-aid': aid, boundByWindow, className, isOpen, onClickOutside, onClose, target, targetRef } = props;

  const popoverProps = {
    'data-aid': aid,
    autoPosition: true,
    className,
    isVisible: isOpen,
    onClickOutside: onClickOutside !== null ? onClose : undefined,
    position: props.position,
    role: 'menu',
    target,
    targetElement: targetRef,
    ..._.pick(props, POPOVER_PROPS),
  };

  const { windowHeight, windowWidth } = useWindowSize();
  if (boundByWindow) {
    popoverProps.bounds = {
      bottom: windowHeight - 68,
      left: 8,
      right: windowWidth - 8,
      top: 68,
    };
  }

  const contextValue = useMemo(
    () => ({
      onClose,
    }),
    [onClose]
  );

  return (
    <PopoverMenuContext.Provider value={contextValue}>
      <StyledPopoverMenu {...popoverProps} />
    </PopoverMenuContext.Provider>
  );
}

export function usePopoverMenu(positionRef) {
  const [targetRef, setTargetRef] = useState(positionRef || null);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const onOpen = useCallback(() => setIsOpen(true), []);

  return { targetRef, setTargetRef, isOpen, onClose, onToggle, onOpen };
}

PopoverMenu.defaultProps = {
  boundByWindow: true,
  isOpen: false,
  position: 'top',
};

const StyledPopoverMenu = styled(PortalledPopover)`
  background-color: ${p => p.theme.colors.white};
  border-radius: 6px;
  box-shadow: ${p => p.theme.boxShadow.medium};
  overflow: hidden;
`;

const StyledMenuItem = styled(InsetContainer)`
  cursor: pointer;
  min-width: 100px;
  padding: 8px 16px;
  word-break: break-word;
  &:hover {
    background: ${p => p.theme.colors.green100};
  }
  ${p => p.isHovered && hoveredMenuItem};
  ${p => p.isDisabled && disabledMenuItem};
  ${p => p.isFocused && !p.isDisabled && !p.isMultiSelect && focusedMenuItem};
`;

const hoveredMenuItem = css`
  background: ${p => p.theme.colors.green100};
`;

const disabledMenuItem = css`
  color: ${p => p.theme.colors.gray600};
  cursor: default;
  &:hover {
    background: ${p => p.theme.colors.white};
  }
`;

const focusedMenuItem = css`
  background-color: ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.white};
  cursor: default;
  &:hover {
    background-color: ${p => p.theme.colors.green400};
    color: ${p => p.theme.colors.white};
  }
`;

StyledMenuItem.defaultProps = { inset: 'small' };

export { StyledMenuItem };

export function PopoverMenuItem({
  className,
  component,
  children,
  'data-aid': aid,
  isDisabled,
  isFocused,
  isHovered,
  isMultiSelect,
  onClick,
  onClose,
  onMouseDown,
  onMouseOver,
}) {
  const popoverContext = useContext(PopoverMenuContext);
  onClose = onClose ? onClose : popoverContext.onClose;
  const decoratedOnClick = useCallback(
    event => {
      if (!isDisabled) {
        onClick && onClick(event);

        if (!isMultiSelect) {
          onClose && onClose();
        }
      }
    },
    [isDisabled, onClose, onClick, isMultiSelect]
  );

  const Component = component || StyledMenuItem;
  return (
    <Component
      className={className}
      data-aid={aid}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isHovered={isHovered}
      isMultiSelect={isMultiSelect}
      onClick={decoratedOnClick}
      onMouseDown={!isDisabled ? onMouseDown : undefined}
      onMouseOver={onMouseOver}
      role="menuitem"
    >
      {children}
    </Component>
  );
}

export { StyledPopoverMenu };

PopoverMenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  isDisabled: PropTypes.bool,
  'data-aid': PropTypes.string,
  isFocused: PropTypes.bool,
  isHovered: PropTypes.bool,
  /** Boolean to determine if dropdown menu should allow multiple selections */
  isMultiSelect: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseOver: PropTypes.func,
};

PopoverMenu.propTypes = PortalledPopover.propTypes;
