import _ from 'lodash';

import PhoneCall from 'models/phone_call';

export default class EndConference {
  constructor(context) {
    this.context = context;
  }

  run() {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      const currentAgentId = this.context.stores.currentAgent.get().id;

      _.forEach(activeCall.conversationItem.content.participants, participant => {
        if (
          participant.type !== PhoneCall.ParticipantType.CUSTOMER &&
          participant.participantId !== currentAgentId &&
          PhoneCall.isActiveParticipant(participant)
        ) {
          activeCall.conversationItem.content.setParticipantStatus(
            participant.participantId,
            PhoneCall.ParticipantStatus.DISCONNECTING
          );
        }
      });
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.endConference(activeCall.customer.id, activeCall.conversationItem.id, {
        moderatorParticipantId: currentAgentId,
      });
    }
  }
}
