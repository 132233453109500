import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Hide Fill
 */

export default function HideFillIcon(props) {
  return (
    <Icon viewBox="0 0 17 16" {...props}>
      <path
        clipRule="evenodd"
        d="M4.50116 3.82003C3.73434 4.19745 3.01541 4.70516 2.37743 5.34314L0.146447 7.57412C-0.0488154 7.76939 -0.0488157 8.08597 0.146446 8.28123L2.37742 10.5122C5.07248 13.2073 9.21203 13.5775 12.3039 11.6228L9.91007 9.22894C9.48552 9.80337 8.8034 10.1759 8.03428 10.1759C6.74689 10.1759 5.70326 9.13223 5.70326 7.84485C5.70326 7.07573 6.07576 6.39361 6.65018 5.96905L4.50116 3.82003ZM7.5857 5.55695L5.4543 3.42555C8.2485 2.47614 11.4633 3.11534 13.6911 5.34315L15.9221 7.57412C16.1174 7.76939 16.1174 8.08597 15.9221 8.28123L13.6911 10.5122C13.497 10.7063 13.2954 10.8884 13.0872 11.0584L10.3222 8.29342C10.3505 8.14826 10.3653 7.99829 10.3653 7.84485C10.3653 6.55746 9.32166 5.51383 8.03428 5.51383C7.88084 5.51383 7.73086 5.52866 7.5857 5.55695ZM7.49334 6.81221C7.12204 7.00711 6.86877 7.3964 6.86877 7.84485C6.86877 8.48854 7.39059 9.01035 8.03428 9.01035C8.48272 9.01035 8.87201 8.75709 9.06692 8.38579L7.49334 6.81221Z"
        fillRule="evenodd"
      />
      <path d="M2.06058 1.35355C1.86532 1.15829 1.54874 1.15829 1.35348 1.35355C1.15822 1.54882 1.15822 1.8654 1.35348 2.06066L13.8127 14.5199C14.008 14.7151 14.3245 14.7151 14.5198 14.5199C14.7151 14.3246 14.7151 14.008 14.5198 13.8128L2.06058 1.35355Z" />
    </Icon>
  );
}
