import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import ConversationNoteNotification from './conversation_note_notification';
import ConversationNotification from 'models/notification/conversation_notification';

const ConversationNoteNotificationContainer = connect(mapStateToProps)(ConversationNoteNotification);

function mapStateToProps(context, { notification }) {
  const agentId = notification.conversationItem.initiator.id;
  const agentsProvider = context.getProvider('agents');
  const agent = agentsProvider.findBy({ id: agentId });

  return { agent, notification };
}

ConversationNoteNotificationContainer.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default ConversationNoteNotificationContainer;
