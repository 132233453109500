import PropTypes from 'prop-types';
import React from 'react';

import InsetContainer from 'components/common/containers/inset_container';
import InformationI from 'components/common/icons/information_i.jsx';
import Tooltip from 'components/common/tooltip';

export default function ResultsHeader({
  inset = 'large',
  isLookupPending,
  resultsCount,
  shouldShowSuggestions,
  suggestionsCount,
}) {
  let header;

  if (isLookupPending) {
    header = (
      <InsetContainer inset={inset}>
        <p>Searching for matches...</p>
      </InsetContainer>
    );
  } else if (shouldShowSuggestions) {
    header = (
      <InsetContainer inset={inset}>
        <div>
          We found{' '}
          <span className="externalCustomerLookupSearchResults-header-text-highlight">
            {suggestionsCount} possible matches.
          </span>{' '}
          Try searching for other matches
          <div className="externalCustomerLookupSearchResults-header-tooltipIcon-container">
            <Tooltip enabled message="Results are based on the customer’s profile information." position="bottom">
              <InformationI className="externalCustomerLookupSearchResults-header-tooltipIcon" />
            </Tooltip>
          </div>
        </div>
      </InsetContainer>
    );
  } else {
    const matchesText = resultsCount !== 1 ? 'matches' : 'match';
    const suggestedAction = resultsCount < 1 ? 'changing' : 'refining';
    const suggestionText = resultsCount !== 1 ? `Try ${suggestedAction} your search for better results` : '';

    header = (
      <InsetContainer inset={inset}>
        <p>
          We found{' '}
          <span className="externalCustomerLookupSearchResults-header-text-highlight">
            {resultsCount} possible {matchesText}.
          </span>{' '}
          {suggestionText}
        </p>
      </InsetContainer>
    );
  }

  return <div className="externalCustomerLookupSearchResults-header">{header}</div>;
}

ResultsHeader.propTypes = {
  inset: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  isLookupPending: PropTypes.bool,
  resultsCount: PropTypes.number.isRequired,
  shouldShowSuggestions: PropTypes.bool,
  suggestionsCount: PropTypes.number.isRequired,
};
