import createEnum from 'scripts/lib/create_enum';

const ConversationItemType = createEnum(
  // customer initiated
  'ABANDONED_CALL',
  'AI_ACTIVITY',
  'CALL_FORWARD',
  'CHAT',
  'CHAT_MESSAGE',
  'CONVERSATION_MESSAGE',
  'CREDIT_CARD_VIEWED',
  'CUSTOM_CHANNEL_MESSAGE',
  'EMAIL',
  'EMAIL_PREVIEW', // used in inbox
  'EMAIL_CAMPAIGN_OUTREACH',
  'EMAIL_CAMPAIGN_OUTREACH_RESULT',
  'FB_MESSAGE_INCOMING',
  'FB_MESSAGE_OUTGOING',
  'HOTEL_RESERVATION',
  'MESSAGE_AUTOMATION', // deprecated
  'MESSAGE_AUTOMATION_MESSAGE',
  'MESSAGE_AUTOMATION_SUMMARY',
  'NO_REPLY_NEEDED',
  'PHONE_CALL',
  'PHONE_CALLBACK',
  'SMS',
  'SMS_CAMPAIGN_OUTREACH',
  'SMS_CAMPAIGN_OUTREACH_RESULT',
  'TWITTER',
  'WEB_FORM',
  'VOICE_CAMPAIGN_OUTREACH',
  'VOICE_CAMPAIGN_OUTREACH_RESULT',
  'VOICEMAIL',
  // context from customer's web or mobile experiences
  'MOBILE_CONTEXT',
  'WEB_CONTEXT',
  'HELP_APP_CONTEXT',
  // rule loop detected
  'RULE_DISABLED_LOOP',
  // agent initiated
  'CONVERSATION_NOTE',
  'CUSTOMER_MERGE',
  'TASK',
  'SECURE_DATA_REQUEST',
  // task feed item
  'TASK_COMPLETION',
  'TASK_DUE',
  'TASK_EDITED',
  'TASK_REOPENED',
  'TASK_ROUTING',
  // conversation assignee change
  'ROUTING_ITEM',
  // chat feed items
  'CHAT_CUSTOMER_URL',
  'CHAT_ENDED',
  'CHAT_SELF_SERVICE_METADATA',
  'PROACTIVE_CHAT_CONTENT',
  // audit item for changing the customer assignee
  'CUSTOMER_ASSIGNEE_CHANGE',
  // email feed item
  'EMAIL_SEND_FAILURE',
  // conversation custom attribute change
  'CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE',
  // conversation topic change
  'TOPIC_CHANGE',
  // conversation status change
  'CONVERSATION_STATUS_CHANGE',
  // audit item for ending messaging session
  'SESSION_ENDED',
  // audit item for deletion of voice recording
  'VOICE_RECORDING_DELETED',
  // linked item from another customer
  'ITEM_LINK',
  'PAYMENT_STATUS_EVENT',
  'PAYMENT_COMPLETED',
  // a conversation may have associated conversation items that have not yet been received
  // from the server; the PLACEHOLDER type is used in the interim until the actual
  // conversation item is received; the place holder is needed in order to keep
  // track of the conversation item id
  'PLACEHOLDER',
  // demo marketing email routing item
  'MARKETING_EMAIL',
  // external activity item
  'CUSTOMER_ACTIVITY',
  // conversation item for sessions
  'MESSAGING_SESSION',
  // UI Widget activity record
  'WIDGET_ACTIVITY'
);

export const AutomaticConversationItemTypes = [
  ConversationItemType.CREDIT_CARD_VIEWED,
  ConversationItemType.CALL_FORWARD,
  ConversationItemType.CHAT_CUSTOMER_URL,
  ConversationItemType.CHAT_SELF_SERVICE_METADATA,
  ConversationItemType.CHAT_ENDED,
  ConversationItemType.CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE,
  ConversationItemType.CUSTOMER_ASSIGNEE_CHANGE,
  ConversationItemType.CUSTOMER_MERGE,
  ConversationItemType.TOPIC_CHANGE,
  ConversationItemType.CONVERSATION_STATUS_CHANGE,
  ConversationItemType.EMAIL_SEND_FAILURE,
  ConversationItemType.HELP_APP_CONTEXT,
  ConversationItemType.ITEM_LINK,
  ConversationItemType.MARKETING_EMAIL,
  ConversationItemType.MESSAGING_SESSION,
  ConversationItemType.MOBILE_CONTEXT,
  ConversationItemType.NO_REPLY_NEEDED,
  ConversationItemType.PAYMENT_STATUS_EVENT,
  ConversationItemType.PAYMENT_COMPLETED,
  ConversationItemType.ROUTING_ITEM,
  ConversationItemType.RULE_DISABLED_LOOP,
  ConversationItemType.SESSION_ENDED,
  ConversationItemType.TASK_COMPLETION,
  ConversationItemType.TASK_DUE,
  ConversationItemType.TASK_EDITED,
  ConversationItemType.TASK_ROUTING,
  ConversationItemType.TASK_REOPENED,
  ConversationItemType.VOICE_RECORDING_DELETED,
  ConversationItemType.WEB_CONTEXT,
];

export default ConversationItemType;
