import analytics from 'scripts/lib/analytics';
import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';

export default class ToggleFavoriteAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ answer }) {
    const agentPreferences = this.context.stores.agentPreferences.get();
    const isFavorited = !!agentPreferences.isAnswerFavorited(answer.id);

    agentPreferences.toggleFavoriteAnswer(answer.id);
    setAgentPreferences(this.context, agentPreferences);

    if (!isFavorited) {
      this.context.stores.favoriteAnswers.addOrReplace(answer);
    } else {
      this.context.stores.favoriteAnswers.remove(answer.id);
    }

    analytics.track('Answer Favorites Changed', {
      numFavorites: agentPreferences.favoriteAnswerIds.length,
    });
  }
}
