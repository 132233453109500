import React from 'react';
import PropTypes from 'prop-types';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import EditableDateCustomAttribute from 'components/customer/summary/custom_attributes/editable_date_custom_attribute';
import EditablePercentCustomAttribute from 'components/customer/summary/custom_attributes/editable_percent_custom_attribute';
import EditableSingleValueCustomAttribute from 'components/customer/summary/custom_attributes/editable_single_value_custom_attribute';

function EditableCustomAttribute({ def, renderProps, value }) {
  switch (def.type) {
    case AttributeType.DATE:
      return <EditableDateCustomAttribute def={def} value={value} />;
    case AttributeType.PERCENT:
      return <EditablePercentCustomAttribute def={def} renderProps={renderProps} value={value} />;

    // Both STRING and NUMBER are handled by `EditableSingleValueCustomAttribute`
    default:
      return <EditableSingleValueCustomAttribute def={def} value={value} />;
  }
}

EditableCustomAttribute.propTypes = {
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  renderProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EditableCustomAttribute;
