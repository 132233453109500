import Composition from 'models/composition';
import Snippet from 'models/answers/snippet';

export function getAttachmentUrl(type, orgId, attachment) {
  return `/api/v1/orgs/${orgId}/${type}/attachments/${attachment.id}`;
}

export function getMultipleAttachmentsUrl(type, orgId, fileName, attachments) {
  const attachmentIds = attachments.map(a => a.id).join(',');
  return `/api/v1/orgs/${orgId}/${type}/attachments?filename=${fileName}&ids=${attachmentIds}`;
}

export function getEmailAttachmentUrl(orgId, attachment) {
  return `/api/v1/orgs/${orgId}/email/attachments/${attachment.id}`;
}

export function getFbAttachmentUrl(orgId, attachment) {
  return `/api/facebook/v1/orgs/${orgId}/attachments/${attachment.id}`;
}

export function getMessagingAttachmentUrl(orgId, attachment) {
  return `/api/v1/orgs/${orgId}/messaging/attachments/${attachment.id}`;
}

export function getSmsAttachmentUrl(orgId, attachment) {
  return `/api/v1/orgs/${orgId}/sms/attachments/${attachment.id}`;
}

export function getCustomChannelAttachmentUrl(orgId, attachment) {
  return `/api/v1/orgs/${orgId}/custom-channel/attachments/${attachment.id}`;
}

export function getSnippetAttachmentUrl(orgId, attachment) {
  return `/api/v1/orgs/${orgId}/shared-uploads/${attachment.source.path}`;
}

export function getSnippetUploadUrl(orgId, snippetId, attachment) {
  let path = Snippet.getUploadPath(snippetId, attachment.id);
  return `/api/v1/orgs/${orgId}/shared-uploads/${path}`;
}

export function getUploadAttachmentUrl(orgId, agentId, compositionId, attachment) {
  let path = Composition.getUploadPath(compositionId, attachment.id);
  return `/api/v1/orgs/${orgId}/agents/${agentId}/uploads/${path}`;
}

export function getThumbnailUrl(url) {
  return `${url}?size=thumbnail`;
}
