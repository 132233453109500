import _ from 'lodash';

import TrackCustomerUpdate from './track_customer_update';

export default class UpdateContact {
  constructor(context) {
    this.context = context;
  }

  run(contact) {
    let customerProfile = this.context.stores.profile.get();
    customerProfile.updateContact(contact);

    this.context.stores.profile.setPending(customerProfile);
    this.context.gateways.customerProfile.update({ customerId: this.currentCustomerId }, contact);

    this.context.executeAction(TrackCustomerUpdate, {
      customerId: this.currentCustomerId,
      field: _.first(_.keys(contact)),
    });
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }
}
