import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AddCustomerRelationshipMenu from 'components/customer/conversation_history/conversation_items_v2/menu/pin_to_customer/add_customer_relationship_menu';
import connect from 'components/lib/connect';
import CustomerRelationship from 'components/customer/summary/customer_relationship';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import { PortalledPopover } from 'components/common/lib/_popover';
import TrackRelationshipClick from 'actions/conversation_item/pin_item/track_relationship_click';
import WindowSizeWatcher from 'components/common/utilities/window_size_watcher';

export class CustomerRelationships extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isPinToCustomerVisible: false };
    this.handleCloseRelationshipMenu = this.handleCloseRelationshipMenu.bind(this);
    this.handleOpenRelationshipMenu = this.handleOpenRelationshipMenu.bind(this);
    this.renderRelationship = this.renderRelationship.bind(this);
  }

  render() {
    if (!this.props.hasProfile) {
      return null;
    }

    return (
      <React.Fragment>
        <ExpandableProfileCard
          isEmpty={_.isEmpty(this.props.relationships)}
          isLoading={this.props.isLoading}
          menu={this.renderButtonMenu()}
          title="Relationships"
        >
          {_.map(this.props.relationships, this.renderRelationship)}
        </ExpandableProfileCard>
        {this.renderAddCustomerMenu()}
      </React.Fragment>
    );
  }

  renderButtonMenu() {
    return (
      <i
        className="customerContactMenu-button-icon icon icon-plus"
        onClick={this.handleOpenRelationshipMenu}
        ref={node => (this.button = node)}
      />
    );
  }

  renderRelationship(relationship) {
    return (
      <CustomerRelationship
        currentCustomerId={this.props.customerId}
        key={relationship.id}
        relationship={relationship}
      />
    );
  }

  renderAddCustomerMenu() {
    if (!this.state.isPinToCustomerVisible) {
      return null;
    }

    return (
      <WindowSizeWatcher>
        {({ windowHeight, windowWidth }) => (
          <PortalledPopover
            autoPosition
            bounds={{
              bottom: windowHeight - 68,
              left: 8,
              right: windowWidth - 8,
              top: 68,
            }}
            className="customerRelationships-relationshipMenu"
            isVisible
            margin={5}
            position="top"
            targetElement={this.button}
          >
            <AddCustomerRelationshipMenu
              currentCustomerId={this.props.customerId}
              omittedCustomerIds={this.props.omittedCustomerIds}
              onClose={this.handleCloseRelationshipMenu}
              title="Add Relationship"
            />
          </PortalledPopover>
        )}
      </WindowSizeWatcher>
    );
  }

  handleOpenRelationshipMenu() {
    this.setState({ isPinToCustomerVisible: true });
    this.props.onOpenRelationshipMenu();
  }

  handleCloseRelationshipMenu() {
    this.setState({ isPinToCustomerVisible: false });
  }
}

CustomerRelationships.propTypes = {
  customerId: PropTypes.string.isRequired,
  hasProfile: PropTypes.bool,
  isLoading: PropTypes.bool,
  onOpenRelationshipMenu: PropTypes.func.isRequired,
  omittedCustomerIds: PropTypes.arrayOf(PropTypes.string),
  relationships: PropTypes.array.isRequired,
};

const CustomerRelationshipsContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerRelationships);

function mapExecuteToProps(executeAction) {
  return {
    onOpenRelationshipMenu: () => executeAction(TrackRelationshipClick, 'Add Profile Relationship'),
  };
}

function mapStateToProps({ getProvider }) {
  let relationshipsProvider = getProvider('relationships');
  let relationshipLabelsProvider = getProvider('relationshipLabels');
  let relationships = relationshipsProvider.findAll() || [];
  let pendingNewRelationship = relationshipsProvider.getPendingNew();
  if (pendingNewRelationship) {
    relationships = _.concat(relationships, pendingNewRelationship);
  }
  let customerId = getProvider('currentLocation').get().customerId;
  let omittedCustomerIds = relationships.map(r => r.customerId);

  const profileProvider = getProvider('profile');
  const hasProfile = !profileProvider.isLoading() && !!profileProvider.get();

  return {
    customerId,
    hasProfile,
    isLoading:
      profileProvider.isLoading() || relationshipsProvider.isLoading() || relationshipLabelsProvider.isLoading(),
    omittedCustomerIds,
    relationships,
  };
}

export default CustomerRelationshipsContainer;
