import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import InlineImage from 'components/customer/summary/transactions/inline_image';
import ShippingBoxIcon from 'components/common/icons/shipping_box';

export default function ProductImage({ imageUrl, placeholderImage, onClick }) {
  const placeholder = placeholderImage || <DefaultPlaceholderImage />;
  return (
    <ProductImageContainer onClick={onClick}>
      <InlineImage imageUrl={imageUrl || ''} placeholder={placeholder} />
    </ProductImageContainer>
  );
}

const DefaultPlaceholderImage = styled(ShippingBoxIcon)`
  height: 28px;
  width: 28px;
`;

const ProductImageContainer = styled.div`
  background-color: ${p => p.theme.colors.white};
  border: 0.5px solid ${p => p.theme.colors.gray400};
  box-shadow: 0.75px 0.75px 1px 0px #48484866;
  border-radius: ${p => p.theme.borderRadius.small};
  height: 60px;
  width: 60px;
  margin-right: 12px;
  min-height: 60px;
  min-width: 60px;
  overflow: hidden;
`;

ProductImage.propTypes = {
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  placeholderImage: PropTypes.node,
};
