import _ from 'lodash';
import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';
import { isList } from './utils';

export function increaseListDepth(editor, next = _.noop) {
  const { value } = editor;
  if (!isList(value)) return next();

  value.blocks
    .filter(block => block.type === LIST_ITEM)
    .forEach(block => {
      const closestParent = value.document.getClosest(
        block.key,
        parent => parent.type === ORDERED_LIST || parent.type === UNORDERED_LIST
      );
      if (!closestParent) {
        return;
      }
      if (closestParent.type === ORDERED_LIST) {
        editor.wrapBlockByKey(block.key, ORDERED_LIST).focus();
      } else if (closestParent.type === UNORDERED_LIST) {
        editor.wrapBlockByKey(block.key, UNORDERED_LIST).focus();
      }
    });
}
