import Communicator from 'models/communicator';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import TypeReflect from 'models/lib/type_reflect';
import keys from 'lodash/keys';
import { QuickReplyOption } from './chat_message';
import _ from 'lodash';

export const messageType = createEnum('MENU', 'START', 'TEXT', 'DATA_DISPLAY', 'ANNOTATION');
export const dataDisplayType = createEnum('ORDER_STATUS');
export const annotationDisplayType = createEnum('SINGLE_LINE');

const StartContent = createModel({
  modelName: 'StartContent',

  properties: {
    automationId: prop(String),
    label: prop(String),
  },

  toString() {
    return this.label;
  },
});

const TextContent = createModel({
  modelName: 'TextContent',

  properties: {
    value: prop(String).isRequired,
  },

  toString() {
    return this.value;
  },
});

const MenuContent = createModel({
  modelName: 'MenuContent',

  properties: {
    prompt: prop(String).isRequired,
    menuItems: prop([QuickReplyOption]).default([]).isRequired,
  },

  toString() {
    return this.prompt;
  },
});

const FulfillmentContent = createModel({
  modelName: 'FulfillmentContent',

  properties: {
    estimatedDeliveryDate: prop(String),
    status: prop(String),
    trackingNumber: prop(String),
    trackingUrl: prop(String),
  },
});

const ProductContent = createModel({
  modelName: 'ProductContent',

  properties: {
    imageUrl: prop(String),
    name: prop(String),
    sku: prop(String),
    status: prop(String),
  },
});

const OrderStatusConfigContent = createModel({
  modelName: 'OrderStatusConfigContent',

  properties: {
    missingFulfillmentMessage: prop(String),
    missingStatusAndEtaMessage: prop(String),
    missingEtaMessage: prop(String),
  },
});

const DataDisplayOrderStatus = createModel({
  modelName: 'DataDisplayOrderStatus',

  properties: {
    config: prop(OrderStatusConfigContent),
    currencyCode: prop(String),
    customerOrderUrl: prop(String),
    fulfillments: prop([FulfillmentContent]).default([]),
    id: prop(String).isRequired,
    itemCount: prop(Number),
    products: prop([ProductContent]).default([]),
    totalAmount: prop(Number),
  },
});

const DataDisplayContent = createModel({
  modelName: 'DataDisplayContent',

  properties: {
    type: prop().oneOf(...keys(dataDisplayType)).isRequired,
    displayContent: prop().oneOf(DataDisplayOrderStatus).isRequired,
  },

  statics: {
    overrideFromJs(fromJs) {
      return attrs => {
        let DataDisplayConstructor = dataDisplayTypeReflect.typeToConstructor(attrs.type);
        return new this({
          ...attrs,
          displayContent: DataDisplayConstructor.fromJs(attrs.displayContent),
        });
      };
    },
  },

  toString() {
    return this.type.toString();
  },
});

const DataDisplayTypeClasses = [[dataDisplayType.ORDER_STATUS, DataDisplayOrderStatus]];

const dataDisplayTypeReflect = new TypeReflect(DataDisplayTypeClasses);

const SingleLineContent = createModel({
  modelName: 'SingleLineContent',

  properties: {
    icon: prop(String),
    description: prop(String),
  },
});

const AnnotationContent = createModel({
  modelName: 'AnnotationContent',

  properties: {
    type: prop().oneOf(..._.keys(annotationDisplayType)).isRequired,
    content: prop(SingleLineContent),
  },
});

const MessageTypeClasses = [
  [messageType.MENU, MenuContent],
  [messageType.START, StartContent],
  [messageType.TEXT, TextContent],
  [messageType.DATA_DISPLAY, DataDisplayContent],
  [messageType.ANNOTATION, AnnotationContent],
];

const messageTypeReflect = new TypeReflect(MessageTypeClasses);

export const AutomationSummary = createModel({
  modelName: 'AutomationSummary',

  properties: {
    description: prop(String).isRequired,
  },
});

const Initiator = createModel({
  modelName: 'Initiator',

  properties: {
    type: prop().oneOf(...keys(Communicator)).isRequired,
    id: prop(String).isRequired,
  },
});

const MessageAutomationSummaryContent = createModel({
  modelName: 'MessageAutomationSummaryContent',

  properties: {
    automationSessionID: prop(String).isRequired,
    companyAddress: prop(String).isRequired,
    customerAddress: prop(String).isRequired,
    interactionType: prop(String).isRequired,
    summary: prop(AutomationSummary).isRequired,
  },

  getMessageText() {
    return 'Message automation summary';
  },
});

export default MessageAutomationSummaryContent;

const AutomationMessage = createModel({
  modelName: 'AutomationMessage',

  properties: {
    id: prop(String).isRequired,
    from: prop(Initiator).isRequired,
    createdAt: prop(String).isRequired,
    type: prop().oneOf(...keys(messageType)).isRequired,
    content: prop().oneOf(MenuContent, StartContent, TextContent, DataDisplayContent, AnnotationContent).isRequired,
    context: prop(Object).default({}),
  },

  statics: {
    overrideFromJs(fromJs) {
      return attrs => {
        let MessageConstructor = messageTypeReflect.typeToConstructor(attrs.type);
        return new this({
          ...attrs,
          from: Initiator.fromJs(attrs.from),
          content: MessageConstructor.fromJs(attrs.content),
        });
      };
    },
  },
});

export const MessageAutomationContent = createModel({
  // deprecated
  modelName: 'MessageAutomationContent',

  properties: {
    automationSessionID: prop(String).isRequired,
    companyAddress: prop(String).isRequired,
    customerAddress: prop(String).isRequired,
    interactionType: prop(String).isRequired,
    messages: prop([AutomationMessage]).default([]).isRequired,
    summary: prop(AutomationSummary).isRequired,
  },
});

export const MessageAutomationItemContent = createModel({
  modelName: 'MessageAutomationItemContent',

  properties: {
    message: prop(AutomationMessage).isRequired,
    interactionType: prop(String).isRequired,
  },

  from() {
    return _.capitalize(this.message.from.type);
  },
});
