import CompositionConverter from 'scripts/application/dto_converters/composition_converter';
import CompositionGatewayObserver from 'actions/composition/composition_gateway_observer';
import CompositionUploads from 'actions/composition/lib/composition_uploads';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class RemoveUpload {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, uploadId }) {
    const compositionsStore = getCompositionsStore(this.context);
    if (!compositionsStore) {
      return;
    }

    let composition = compositionsStore.find(compositionId);
    if (!composition) {
      return;
    }

    let upload = composition.removeAttachment(uploadId);
    if (!upload) {
      return;
    }

    compositionsStore.replace(composition);

    let agentId = this.context.stores.currentAgent.get().id;
    let customerId = this.context.stores.currentLocation.get().customerId;
    let { correlationId } = this.context.gateways.composition.update(
      agentId,
      customerId,
      compositionId,
      CompositionConverter.toDto(composition)
    );

    CompositionGatewayObserver.setUploadsToDelete(
      correlationId,
      CompositionUploads.create({ agentId, compositionId, uploads: [upload] })
    );
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
