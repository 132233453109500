import React from 'react';
import PropTypes from 'prop-types';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import AutomationContentContainer from './message_automation_summary_content_container';

export default function MessageAutomationSummaryItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <AutomationContentContainer className={className} item={item} />
    </ItemContent>
  );
}

MessageAutomationSummaryItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
