import createEnum from 'scripts/lib/create_enum';

const RoutingItemType = createEnum(
  'ABANDONED_CALL',
  'AGENT_REQUEST',
  'AUTOMATED_ASSIGNMENT',
  'BLIND_TRANSFER',
  'CALL_DEVICE_ERROR',
  'CONVERSATION_MERGE',
  'CUSTOMER_ASSIGNEE_ASSIGNMENT',
  'CUSTOMER_ASSIGNEE_CHANGE',
  'DECLINED_OVER_CAPACITY_CHAT',
  'DECLINED_REASSIGNED_CHAT',
  'INBOUND',
  'INBOUND_MATCH',
  'MANUAL_ASSIGNMENT',
  'MESSAGE_AUTOMATION_HANDOFF',
  'OFFER_CONFLICT',
  'OFFER_DECLINE',
  'OFFER_MISS',
  'OUTBOUND',
  'QUEUED_TRANSFER',
  'REASSIGNED_CHAT',
  'REROUTED_OFFLINE',
  'REROUTED_UNASSIGNED',
  'SMART_MATCH',
  'UNASSIGNED_NON_MEMBER',
  'UNASSIGNED_OFFLINE',
  'UNASSIGNED_UNAVAILABLE',
  'USER_DISABLED'
);

export default RoutingItemType;
