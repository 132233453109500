import PopoverMenu from 'components/common/menu';
import styled from 'styled-components';

export const StyledMenu = styled(PopoverMenu)`
  border: 1px solid ${p => p.theme.colors.gray300};
`;

export const StyledLinkMenuWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: 8px;
  min-width: 400px;
`;

export const StyledInput = styled.input`
  margin: 0;
  border: none;
  flex-grow: 1;
  &:focus {
    outline: none;
  }
`;

export const StyledLinkMenuSeparator = styled.span`
  border-left: 1px solid ${p => p.theme.colors.gray300};
  height: 20px;
  width: 1px;
`;

export const StyledLinkMenuButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    color: ${p => p.theme.colors.gray300};
  }
  &:hover {
    color: ${p => p.theme.colors.green400};
  }
`;
