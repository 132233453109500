import { INBOX_UPDATE_INTERVAL } from './agent_inbox_constants';

export default function getPollingInterval(context) {
  const appFeatures = context.stores.appFeatures.get();
  let pollingInterval = INBOX_UPDATE_INTERVAL;
  if (appFeatures.isEnabled('doubleInboxPollingInterval1')) {
    pollingInterval = pollingInterval * 2;
  }
  if (appFeatures.isEnabled('doubleInboxPollingInterval2')) {
    pollingInterval = pollingInterval * 2;
  }
  return pollingInterval;
}
