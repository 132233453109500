import { getActiveSessionOfTypeByCustomerForAgent } from 'actions/communication_session/session_finder';
import Communicator from 'models/communicator';
import ConversationUpdater from 'actions/conversation/lib/conversation_updater';
import CurrentCompositionUpdater from 'actions/lib/current_composition_updater';
import CustomerView from 'models/location/customer_view';
import InteractionType from 'models/interaction_type';
import { OutgoingChatOperation } from 'models/chat_message';

export default class EndChat extends ConversationUpdater {
  constructor(context) {
    super(context);
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
  }

  run({ conversationStatus } = {}) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    this.currentCompositionUpdater.update();

    let activeChatSession = getActiveSessionOfTypeByCustomerForAgent(
      this.context.stores.activeSessions,
      this.currentCustomerId,
      this.currentAgent.id,
      InteractionType.CHAT
    );

    if (activeChatSession) {
      let itemId = activeChatSession.id;
      let conversationId = activeChatSession.queueItem.conversationId;
      this.update(itemId, conversationId, conversationStatus);
    }
  }

  update(itemId, conversationId, conversationStatus) {
    let conversationItemUpdate = {
      initiator: {
        id: this.currentAgent.id,
        type: Communicator.AGENT,
      },
    };

    let conversation = { id: conversationId };
    if (conversationStatus) {
      conversation.status = conversationStatus;
    }

    const payload = {
      outgoingCommunication: {
        id: itemId,
        customerId: this.currentCustomerId,
        conversation,
        conversationItem: conversationItemUpdate,
      },
      operation: OutgoingChatOperation.END_CHAT,
    };

    if (this.context.stores.appFeatures.get().isEnabled('enableOutgoingChatHttp')) {
      this.context.gateways.outgoingChatHttp.update(
        {
          agentId: this.currentAgent.id,
          itemId,
        },
        payload
      );
    } else {
      this.context.gateways.outgoingChat.update(this.currentAgent.id, itemId, payload);
    }
  }
}
