import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import InitiateWarmTransfer from 'actions/phone_call/initiate_warm_transfer';

export class TransferButtonOption extends PureComponent {
  render() {
    return (
      <div className="transferButtonOptions-buttons">
        <Button
          buttonType={ButtonTypes.SECONDARY}
          className="transferButtonOptions-button-warm"
          onClick={this.props.onWarmSelect}
        >
          Transfer
        </Button>
      </div>
    );
  }
}

TransferButtonOption.propTypes = {
  onWarmSelect: PropTypes.func.isRequired,
};

const TransferButtonOptionsContainer = connect(undefined, mapExecuteToProps)(TransferButtonOption);

TransferButtonOptionsContainer.propTypes = {
  agentId: PropTypes.string,
  currentAgentId: PropTypes.string,
  onClose: PropTypes.func,
  routingGroupId: PropTypes.string,
};

function mapExecuteToProps(executeAction, { agentId, currentAgentId, routingGroupId }) {
  return {
    onWarmSelect: () =>
      executeAction(InitiateWarmTransfer, {
        fromAgentId: currentAgentId,
        toAgentId: agentId,
        toRoutingGroupId: routingGroupId,
      }),
  };
}

export default TransferButtonOptionsContainer;
