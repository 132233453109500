import CustomChannelsConfig from 'models/custom_channels_config';
import qconsole from 'scripts/lib/qconsole';

export default class CustomChannelsConfigGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleEntity(dto) {
    this.context.stores.customChannelsConfig.resetLoading();
    this.context.stores.customChannelsConfig.set(CustomChannelsConfig.fromJs(dto));
  }

  handleRequestError(errorDto) {
    this.context.stores.customChannelsConfig.resetLoading();
    qconsole.error(`Unexpected error requesting conversation workflow config ${JSON.stringify(errorDto)}`);
  }
}
