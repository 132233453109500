import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import T from 'prop-types';

import createEnum from 'scripts/lib/create_enum';
import Paginate from 'components/common/paginate';
import ReportTable from './report_table';
import { SORT_PARAMETERS, getNextSortOrder } from './sort_control';
import ReportHelpers from './report_helpers';
import Tooltip from 'components/common/tooltip';

export const SharedTableColumns = createEnum('NAME', 'TOTAL');

const SharedTable = createReactClass({
  propTypes: {
    days: T.array,
    // changeTable sort takes the parameters (sortCol, sort) and returns a list of IDs which are used as the sorted
    // order by the table
    changeTableSort: T.func,
    columnNames: T.arrayOf(T.string),
    extraColumn: T.shape({
      header: T.string,
      renderCell: T.func,
    }), // (optional) 3rd column
    getNameForId: T.func,
    getTitleForId: T.func,
    highlight: T.shape({
      firstCol: T.number.isRequired,
      high: T.number.isRequired,
      low: T.number.isRequired,
    }), // object which includes rules for which cells to highlight
    renderCell: T.func, // ({ value, id, date })
    report: T.any,
    shouldPaginate: T.bool,
    windowWidth: T.number,
  },

  statics: {
    CELL_WIDTH: 100,
    ROWS: 25,
  },

  getInitialState() {
    return {
      sortedRows: [],
      sortedRowsForPage: [],
      currentSort: { total: SORT_PARAMETERS.ASC },
      from: 0,
      isPaginated: this.props.shouldPaginate,
    };
  },

  UNSAFE_componentWillMount() {
    this.report = this.props.report;
    let from = this.state.from;
    let allIds = this.report.getIds();
    const isPaginated = allIds.length > SharedTable.ROWS && this.props.shouldPaginate;
    this.setState({
      sortedRows: allIds,
      sortedRowsForPage: allIds.slice(from, from + SharedTable.ROWS),
      isPaginated,
    });
  },

  componentDidMount() {
    this.changeTableSort(SharedTableColumns.TOTAL);
  },

  /* render */
  render() {
    const isPaginated = this.state.isPaginated;
    const rowsCount = isPaginated
      ? Math.min(this.state.sortedRowsForPage.length, SharedTable.ROWS)
      : this.state.sortedRows.length;
    const renderCell = this.props.renderCell;

    let cols = [
      {
        header: this.props.columnNames[0],
        renderCell: props => {
          if (rowsCount === 0) {
            return <div className="sharedTable-cell">All {this.props.name}</div>;
          }
          let id = isPaginated ? this.state.sortedRowsForPage[props.rowIndex] : this.state.sortedRows[props.rowIndex];
          let name = this.props.getNameForId(id);
          let title = name;
          if (this.props.getTitleForId) {
            title = this.props.getTitleForId(id);
          }
          return (
            <Tooltip bounds={{ left: 40 }} message={title} position="bottom">
              <div className="sharedTable-cell">{name}</div>
            </Tooltip>
          );
        },
        ref: SharedTableColumns.NAME,
      },
      {
        header: this.props.columnNames[1],
        renderCell: props => {
          let id = null;
          let value = 0;
          if (rowsCount > 0) {
            id = isPaginated ? this.state.sortedRowsForPage[props.rowIndex] : this.state.sortedRows[props.rowIndex];
            value = this.report.getTotalForId(id);
          }

          if (renderCell) {
            return renderCell({ value, id });
          }
          return value.toLocaleString();
        },
        ref: SharedTableColumns.TOTAL,
      },
    ];

    let extraColumn = this.props.extraColumn;
    if (extraColumn) {
      extraColumn.renderCell = extraColumn.renderCell.bind(this);
      cols.push(extraColumn);
    }

    this.props.days.forEach(date => {
      cols.push({
        header: ReportHelpers.toShortDisplayDate(date, this.props.aggregationLevel),
        renderCell: props => {
          let id = null;
          let value = 0;
          if (rowsCount > 0) {
            id = isPaginated ? this.state.sortedRowsForPage[props.rowIndex] : this.state.sortedRows[props.rowIndex];
            value = this.report.getDataForDayAndId(date, id);
          }

          if (renderCell) {
            return renderCell({ date, value, id });
          }
          if (this.props.highlight) {
            let classes = classnames({
              'reportTable-element-high': value !== 0 && value >= this.props.highlight.high,
              'reportTable-element-low': value !== 0 && value <= this.props.highlight.low,
            });
            return <div className={classes}>{value.toLocaleString()}</div>;
          }
          return value.toLocaleString();
        },
        ref: date,
        width: SharedTable.CELL_WIDTH,
      });
    });

    return (
      <div>
        <ReportTable
          changeSort={this.changeTableSort}
          columns={cols}
          currentSort={this.state.currentSort}
          rowsCount={rowsCount || 1}
          windowWidth={this.props.windowWidth}
        />
        {isPaginated ? (
          <Paginate
            from={this.state.from}
            onChange={this.changePage}
            size={SharedTable.ROWS}
            total={this.state.sortedRows.length}
          />
        ) : null}
      </div>
    );
  },

  changePage(from) {
    let newPage = this.state.sortedRows.slice(from, from + SharedTable.ROWS);
    this.setState({
      sortedRowsForPage: newPage,
      from,
    });
  },

  changeTableSort(sortCol) {
    let sort = getNextSortOrder(this.state.currentSort[sortCol]);

    let order = [];
    if (this.props.changeTableSort) {
      order = this.props.changeTableSort(sortCol, sort);
    } else {
      order = _.orderBy(
        this.state.sortedRows,
        itemId => {
          if (sortCol === SharedTableColumns.TOTAL) {
            return this.report.getTotalForId(itemId);
          }
          return sortCol === SharedTableColumns.NAME
            ? this.props.getNameForId(itemId).toLowerCase()
            : this.report.getDataForDayAndId(sortCol, itemId);
        },
        [sort.toLowerCase()]
      );
    }

    let from = 0;

    this.setState({
      sortedRows: order,
      sortedRowsForPage: order.slice(from, from + SharedTable.ROWS),
      currentSort: { [sortCol]: sort },
      from,
    });
  },
});

export default SharedTable;
