import { Factory } from 'rosie';

import '../conversation_item_factory';
import IdGenerator from '../lib/id_generator';

Factory.define('ConversationTopicChange')
  .attr('type', 'TOPIC_CHANGE');

Factory.define('ConversationTopicChangeWithDefaults')
  .extend('ConversationTopicChange')
  .attr('addedTopics', [IdGenerator.newId()])
  .attr('removedTopics', [IdGenerator.newId()]);

Factory.define('ConversationTopicChangeItem')
  .extend('ConversationItem')
  .attr('content', ['content'], (content) => Factory.attributes('ConversationTopicChange', content));

Factory.define('ConversationTopicChangeItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationTopicChangeWithDefaults', content);
  })
  .attr('initiator', {
    type: 'AGENT',
    id: IdGenerator.newId(),
  });

Factory.define('ConversationTopicChangeItemWithRuleInitiator')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationTopicChangeWithDefaults', content);
  })
  .attr('initiator', {
    type: 'AUTOMATION',
    id: IdGenerator.newId(),
  });
