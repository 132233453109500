import createLocationModel, { prop } from './lib/create_location_model';

export default createLocationModel({
  modelName: 'ExternalCustomerLookup',
  properties: {
    confirmLookupResultIndex: Number,
    customerId: prop(String).isRequired,
    linkRequested: prop(Boolean),
  },

  setLinkRequested() {
    this.linkRequested = true;
  },
});
