import CustomerView from 'models/location/customer_view';
import RelationshipConverter from 'scripts/application/dto_converters/relationship_converter';
import TrackEvent from 'actions/analytics/track_event';

export default class UpdateRelationship {
  constructor(context) {
    this.context = context;
  }

  run({ originalCustomerId, id, labelId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    let customerStore = this.context.stores.customers.storesFor(originalCustomerId);
    let relationship = customerStore.relationships.findBy({ id });
    relationship.setLabelId(labelId);
    customerStore.relationships.setPending(relationship);

    const dto = RelationshipConverter.toDto(relationship, originalCustomerId);
    this.context.gateways.relationship.update(
      { customerId: originalCustomerId, relationshipId: id },
      { labelId: dto.labelId, version: dto.version }
    );

    this.context.executeAction(TrackEvent, {
      event: `Relationships - Labels - Update Relationship`,
      props: { originalCustomerId, labelId },
    });
  }
}
