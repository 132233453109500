import { useContext, useEffect, useMemo, useRef } from 'react';
import { createPluginFactory } from '@udecode/plate';
import { ReactEditor } from 'slate-react';

import ComposerContext from 'components/composer/contexts/composer_context';
import OpenAnswerPanel from 'actions/answers/open_answer_panel';
import useExecuteAction from 'components/hooks/use_execute_action';

export function useCreateOpenAnswerPanelPlugin() {
  const executeAction = useExecuteAction();
  const { composition } = useContext(ComposerContext);

  const openAnswerPanelRef = useRef(
    composition
      ? () => {
          executeAction(OpenAnswerPanel, {
            compositionId: composition.id,
            conversationId: composition.conversationId,
            customerId: composition.customerId,
          });
        }
      : null
  );

  useEffect(() => {
    if (composition) {
      openAnswerPanelRef.current = () => {
        executeAction(OpenAnswerPanel, {
          compositionId: composition.id,
          conversationId: composition.conversationId,
          customerId: composition.customerId,
        });
      };
    } else {
      openAnswerPanelRef.current = null;
    }
  }, [composition]);

  return useMemo(() => createOpenAnswerPanelPlugin(openAnswerPanelRef), []);
}

export function createOpenAnswerPanelPlugin(openAnswerPanelRef) {
  return createPluginFactory({
    key: 'open-answer-panel',

    handlers: {
      onKeyDown: editor => evt => {
        if (openAnswerPanelRef.current && evt.key === '/' && (evt.metaKey || evt.ctrlKey)) {
          ReactEditor.blur(editor);
          openAnswerPanelRef.current();
          evt.preventDefault();
          return true;
        }
      },
    },
  });
}
