export function updateCustomerTypingState(context, customerId, customerTypingState) {
  const activeSessionsStore = context.stores.activeSessions;

  const activeSession = activeSessionsStore.findBy({ customer: { id: customerId } });
  if (!activeSession) {
    return false;
  }

  activeSession.setTypingState(customerTypingState);
  activeSessionsStore.addOrReplace(activeSession);

  return true;
}
