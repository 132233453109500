import changeCurrentLocation from 'actions/lib/change_current_location';
import getLastInboxLocation from 'actions/inbox/lib/get_last_inbox_location';
import Inbox, { InboxSortOrder } from 'models/location/inbox';
import RequestInbox from 'actions/inbox/request_inbox';

export default class InboxView {
  constructor(context) {
    this.context = context;
  }

  enter({ sortOrder, topicId }, createInboxParams) {
    let currentLocation = this.context.stores.currentLocation.get();
    let inInbox = currentLocation instanceof Inbox;
    if (inInbox) {
      // clear the state if we're requesting the inbox from the inbox page
      this.leave();
    }

    let inboxLocation = getLastInboxLocation(this.context);
    let hasQueryChanged = inboxLocation.setQuery(createInboxParams(createCommonParams({ sortOrder, topicId })));

    changeCurrentLocation(this.context, inboxLocation);

    // force a reload if we're requesting the inbox from the inbox page
    if (hasQueryChanged || inInbox) {
      this.context.stores.inbox.set([]);
    }

    this.context.executeAction(RequestInbox, { cutoffs: {} });

    if (hasQueryChanged || inInbox) {
      this.context.stores.inbox.setLoading();
    }
  }

  leave() {
    this.context.gateways.inboxHttp.unsubscribeFromInboxes();
    this.context.gateways.conversationCount.unsubscribeFromGroupConversationCounts();
  }
}

function createCommonParams({ sortOrder, topicId }) {
  if (!(sortOrder in InboxSortOrder)) {
    sortOrder = InboxSortOrder.LATEST;
  }
  return { sortOrder, topicId };
}
