import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import CustomerProfile from 'models/customer_profile';
import EditEmail from 'actions/composition/edit_email';
import EmailContent from './email_content';
import { EmailItemContextProvider } from './email_item_context';
import EmailStatus from 'models/conversation_item/email_status';
import getNormalizedEmailAddress from 'scripts/lib/get_normalized_email_address';
import { HandleHeightChange } from 'components/customer/conversation_history/virtualized/list_item';
import ItemCommError from '../item_comm_error';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import noop from 'lodash/noop';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export function EmailItem({ className, item, onEditEmail, profile }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const externalAgentActions = isFeatureEnabled('externalAgentActions');
  const internalAgentActions = isFeatureEnabled('internalAgentActions');

  const isTaskUser = () => {
    return !externalAgentActions && internalAgentActions;
  };

  return (
    <EmailItemContextProvider>
      <ItemContent item={item}>
        <ItemMetadata item={item} status={getStatus()} text={getMetadataText(item, profile)} />
        <HandleHeightChange isManual>
          {({ onHeightChange }) => <EmailContent className={className} item={item} onHeightChange={onHeightChange} />}
        </HandleHeightChange>
        <ItemCommError
          className="emailItem-editMessage"
          item={item}
          message="Edit Message"
          onClick={isTaskUser() ? noop : onEditEmail}
        />
      </ItemContent>
    </EmailItemContextProvider>
  );

  function getStatus() {
    const status = item.content.getStatus();
    switch (status) {
      case EmailStatus.DELIVERED:
        return 'delivered';
      case EmailStatus.SENT:
        return 'sent';
      case EmailStatus.DELIVERY_ERROR:
        return 'delivery failed';
      case EmailStatus.SENDING:
        return 'sending';
      default:
        return '';
    }
  }
}

function getMetadataText(item, profile) {
  if (item.content.isForward()) {
    return 'forwarded an email';
  }
  if (!profile) {
    return 'sent an email';
  }
  let customerEmails = profile.emails.map(email => getNormalizedEmailAddress(email.original, true));
  if (item.content.isSentToAny(customerEmails)) {
    return 'replied via email';
  }
  return 'sent an email';
}

EmailItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onEditEmail: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func,
  profile: PropTypes.instanceOf(CustomerProfile),
};

function mapStateToProps({ getProvider }, props) {
  const profile = getProvider('profile').get();
  return {
    ...props,
    profile,
  };
}

function mapExecuteToProps(executeAction, { item }) {
  const email = item.content;
  return {
    onEditEmail: () =>
      executeAction(EditEmail, {
        attachments: email.attachments,
        bcc: email.bcc,
        bodyHtmlStripped: email.bodyHtmlStripped,
        bodyHtml: email.bodyHtml,
        bodyPlainStripped: email.bodyPlainStripped,
        bodyPlain: email.bodyPlain,
        cc: email.cc,
        from: email.from,
        inReplyTo: email.getHeader('in-reply-to'),
        inlineAttachments: email.inlineAttachments(),
        subjectHtml: email.subject,
        to: email.to,
      }),
  };
}

const EmailItemContainer = connect(mapStateToProps, mapExecuteToProps)(EmailItem);
EmailItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onHeightChange: PropTypes.func,
};
export default EmailItemContainer;
