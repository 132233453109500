import createReactClass from 'create-react-class';
import moment from 'moment';
import React from 'react';

import T from 'prop-types';

// DateInput is a text input that allows users to manually input
// to enter a date string and calls `this.props.onValidDate`
// when they have entered a valid date
const DateInput = createReactClass({
  /* setup */

  propTypes: {
    // date should be an iso formatted date string
    // `<DateInput date={moment().toISOString()} />`
    date: T.string.isRequired,

    // format is the display format for the date input
    // and the format used to parse the input
    // default: M/DD/YY
    format: T.string,

    // onValidDate is a callback that is called when the
    // date in the input can be parsed according to the
    // format. The callback is passed an iso formatted
    // date string
    // `<DateInput onValidDate={str => alert(str)} />`
    onValidDate: T.func,
  },

  getDefaultProps() {
    return {
      format: 'M/DD/YY',
      onValidDate(isoDateStr) {
        /* noop */
      },
    };
  },

  getInitialState() {
    return this.getStateFromProps(this.props);
  },

  /* lifecycle */

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.props.date) {
      this.setState(this.getStateFromProps(nextProps));
    }
  },

  /* render */

  render() {
    return (
      <input
        className={this.props.className}
        onChange={this.onChange}
        ref="input"
        type="text"
        value={this.state.value}
      />
    );
  },

  /* events */

  onChange(evt) {
    let value = evt.target.value;
    let m = moment(value, this.props.format, true);

    if (m.isValid()) {
      this.props.onValidDate(m.toISOString());
    }

    this.setState({ value });
  },

  /* helpers */

  getStateFromProps(props) {
    return {
      value: moment(props.date).format(this.props.format),
    };
  },
});

export default DateInput;
