export default function SubmitOnEnterPlugin(onSubmit) {
  return {
    onKeyDown(evt, editor, next) {
      const metaIncluded = !!evt.metaKey || !!evt.shiftKey || !!evt.altKey;
      if (evt.key === 'Enter' && !metaIncluded) {
        onSubmit(evt);
        return true;
      }
      return next();
    },
  };
}
