import React from 'react';
import styled from 'styled-components';

import ArrowRightStrokeIcon from 'components/common/icons/stroke/arrow-right-stroke';
import NavigateToClosedConversationResults from 'actions/search/navigate_to_closed_conversation_results';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function AgentHomeIllustration() {
  const executeAction = useExecuteAction();
  const onClickText = () => executeAction(NavigateToClosedConversationResults);
  return (
    <Centerer data-aid="agentHome-illustration">
      <FlyingMan />
      <AgentHomeText onClick={onClickText}>
        See how many customers you've helped today <StyledRightArrow />
      </AgentHomeText>
    </Centerer>
  );
}

function FlyingMan() {
  return (
    <svg fill="none" height="113" viewBox="0 0 404 113" width="404" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M223.199 29.9505C223.199 32.0498 221.667 33.7513 219.783 33.7513H40.8626C38.9795 33.7513 37.4468 32.0498 37.4468 29.9505C37.4468 27.8601 38.9795 26.1586 40.8626 26.1586C41.8385 26.1586 42.7232 26.6159 43.3408 27.3521C44.1488 23.4075 47.3285 20.47 51.1185 20.47C53.8327 20.47 56.2349 21.9682 57.6757 24.2707C59.1171 21.9682 61.5113 20.47 64.2255 20.47C65.3767 20.47 66.4749 20.7409 67.4588 21.2319C69.1515 17.0503 72.9415 14.1384 77.3332 14.1384C79.9945 14.1384 82.4265 15.2137 84.3175 16.9827C86.399 14.4599 89.3883 12.8774 92.7128 12.8774C95.4502 12.8774 97.9588 13.9439 99.9259 15.7298C102.442 7.09557 109.739 0.857178 118.356 0.857178C126.347 0.857178 133.202 6.22358 136.168 13.8845C138.966 10.541 142.969 8.44984 147.415 8.44984C153.37 8.44984 158.539 12.208 161.094 17.7102C163.641 12.208 168.81 8.44984 174.765 8.44984C180.934 8.44984 186.256 12.4789 188.704 18.2944C190.023 17.6595 191.471 17.3042 192.997 17.3042C197.534 17.3042 201.415 20.3937 203.031 24.7956C204.625 23.2718 206.691 22.366 208.956 22.366C212.448 22.366 215.475 24.5416 217.008 27.7412C217.625 26.7847 218.632 26.1586 219.783 26.1586C221.667 26.1579 223.199 27.8594 223.199 29.9505Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M199.06 101.042C199.06 103.357 200.751 105.233 202.829 105.233H400.231C402.309 105.233 404 103.357 404 101.042C404 98.7364 402.309 96.8601 400.231 96.8601C399.155 96.8601 398.179 97.3644 397.497 98.1762C396.606 93.8264 393.098 90.5871 388.916 90.5871C385.922 90.5871 383.271 92.2391 381.682 94.7783C380.091 92.2391 377.45 90.5871 374.455 90.5871C373.185 90.5871 371.973 90.8858 370.888 91.4273C369.02 86.816 364.839 83.605 359.994 83.605C357.057 83.605 354.374 84.7908 352.288 86.7415C349.991 83.9595 346.693 82.2144 343.025 82.2144C340.005 82.2144 337.237 83.3905 335.067 85.3598C332.291 75.8386 324.241 68.9592 314.734 68.9592C305.917 68.9592 298.354 74.877 295.082 83.3249C291.994 79.638 287.577 77.332 282.673 77.332C276.103 77.332 270.399 81.4762 267.581 87.5437C264.771 81.4762 259.068 77.332 252.497 77.332C245.691 77.332 239.819 81.7749 237.119 88.188C235.664 87.4878 234.065 87.096 232.382 87.096C227.376 87.096 223.095 90.5029 221.311 95.357C219.553 93.6767 217.274 92.6779 214.775 92.6779C210.922 92.6779 207.582 95.077 205.891 98.6053C205.21 97.5506 204.099 96.8601 202.829 96.8601C200.751 96.8594 199.06 98.7356 199.06 101.042Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M121.838 81.7932C121.838 81.7932 123.092 89.6424 135.84 90.1041C148.589 90.5658 142.11 85.487 153.395 84.1018C164.68 82.7167 187.46 84.3327 187.46 84.3327L161.337 72.7898L121.838 81.7932Z"
        fill="#BEDBFF"
      />
      <path
        d="M179.434 73.8966C179.434 73.8966 162.388 69.4199 152.43 70.3442C142.472 71.2686 126.663 77.2155 124.947 78.2525C124.947 78.2525 126.129 81.1059 125.099 82.5566C125.099 82.5566 143.196 78.8038 145.334 79.8768C146.276 80.35 170.368 80.767 170.368 80.767C170.368 80.767 174.189 83.2616 179.434 73.8966Z"
        fill="#2888FF"
      />
      <path
        d="M118.509 80.1985L120.393 84.0806L141.895 79.9805L141.603 72.5007L134.813 73.9713C133.992 74.1491 133.184 74.3952 132.395 74.7068L118.509 80.1985Z"
        fill="#002652"
      />
      <path
        d="M119.763 79.3915L123.823 78.1638L123.439 80.7457L123.459 80.9401C123.513 81.5971 123.435 82.2468 123.238 82.8525C123.044 83.4606 122.731 84.0179 122.315 84.4879L122.322 84.4921L118.068 89.4694C117.977 89.5853 117.827 89.6157 117.705 89.5433C117.692 89.5345 117.677 89.5262 117.666 89.5165C117.408 89.3184 117.343 88.9273 117.522 88.6415L118.949 85.9483C119.004 85.8408 119.034 85.7179 119.034 85.5914C119.032 85.2064 118.747 84.8933 118.395 84.8933L113.901 84.6255L114.281 82.9195L114.925 82.6734C115.24 82.5524 115.543 82.3936 115.828 82.2011L118.457 80.4161C118.675 80.2683 118.87 80.0859 119.034 79.8772C119.223 79.6358 119.484 79.476 119.763 79.3915Z"
        fill="#002652"
      />
      <path
        d="M109.385 83.8393C109.435 84.2091 109.752 84.5189 110.167 84.5633L116.499 84.6838L117.203 82.4805L109.593 83.5858C109.474 83.5904 109.381 83.7012 109.385 83.8328C109.385 83.8356 109.385 83.8375 109.385 83.8393Z"
        fill="#002652"
      />
      <path
        d="M109.873 82.6933C109.982 83.0474 110.343 83.2898 110.759 83.252L117.317 82.0589L117.902 79.845L110.036 82.4033C109.919 82.4315 109.846 82.5589 109.871 82.6873C109.872 82.6896 109.872 82.6914 109.873 82.6933Z"
        fill="#002652"
      />
      <path
        d="M111.159 81.1825C111.256 81.5412 111.608 81.7993 112.024 81.7799L118.31 80.9304L118.975 78.7441L111.333 80.8999C111.216 80.923 111.138 81.0467 111.158 81.1765C111.159 81.1788 111.159 81.1806 111.159 81.1825Z"
        fill="#002652"
      />
      <path
        d="M113.203 78.4538C113.19 78.8278 113.449 79.1972 113.849 79.326L120.05 80.7453L121.314 78.9095L113.449 78.2479C113.331 78.228 113.221 78.3176 113.203 78.4478C113.203 78.4496 113.203 78.452 113.203 78.4538Z"
        fill="#002652"
      />
      <path
        d="M297.409 55.8621C296.877 57.9422 296.51 60.9299 293.995 61.7527C287.471 63.8872 277.038 62.6687 277.038 62.6687C277.038 62.6687 256.873 68.8405 255.341 73.8492C253.811 78.8523 266.645 82.5156 273.397 91.1561C279.359 98.7864 279.538 106.576 279.538 106.576C279.538 106.576 274.473 106.93 272.825 102.982C269.952 96.0988 262.966 93.8156 256.149 94.8194C225.284 92.3058 204.307 88.3258 204.307 88.3258C181.768 83.2751 171.06 76.2658 171.06 76.2658L175.804 75.0833L172.854 65.7013C172.854 65.7013 220.214 33.3235 214.41 41.8278C208.606 50.3317 221.755 64.7317 221.755 64.7317C227.607 63.076 244.304 62.4841 248.582 63.2039C252.866 63.9191 273.608 50.5972 283.772 48.5084C290.712 47.0826 299.33 48.381 297.409 55.8621Z"
        fill="#BEDBFF"
      />
      <path
        d="M295.877 60.9787C292.629 62.658 287.544 63.0334 283.475 63.0144C278.103 62.9895 272.764 63.9605 267.717 65.9916C262.21 68.2083 256.154 71.1923 255.341 73.8495C253.811 78.8527 272.136 83.1988 278.888 91.8393C284.85 99.4696 279.537 106.576 279.537 106.576C279.537 106.576 274.472 106.93 272.824 102.982C269.951 96.0991 263.014 94.382 256.149 94.8197C224.75 96.8222 204.306 88.3261 204.306 88.3261C182.838 83.745 171.059 76.2661 171.059 76.2661L174.677 75.3644C175.278 75.2148 175.627 74.5209 175.425 73.8786L174.888 72.1707C174.888 72.1707 200.361 84.1647 225.461 80.839C232.571 79.8971 239.924 76.9393 246.531 73.7466C269.593 62.6012 282.482 52.039 295.528 56.109C297.63 56.7656 297.856 59.9556 295.877 60.9787Z"
        fill="#93C3FF"
      />
      <path
        d="M245.315 71.9974C245.163 72.0772 231.789 79.1544 219.282 80.4514C208.724 81.5401 198.718 80.178 190.752 78.2758C183.534 76.5522 177.989 74.3863 175.208 73.1881L174.764 71.7762C179.417 73.8567 198.188 81.4907 219.188 79.3202C231.512 78.0435 244.78 70.9848 244.927 70.9036L245.315 71.9974Z"
        fill="#2888FF"
      />
      <path
        d="M280.641 49.9519C272.505 54.3091 245.315 71.9974 245.315 71.9974L209.932 67.172L222.84 59.1871C224.494 58.719 227.015 58.3362 229.876 58.0435C237.139 57.3015 246.02 59.6401 249.089 60.1567C253.373 60.8719 246.145 61.589 256.457 60.7667L280.641 49.9519Z"
        fill="#2888FF"
      />
      <path
        d="M174.119 69.7243L172.854 65.7018C172.854 65.7018 180.788 65.3772 188.567 66.707L194.993 70.1139L203.33 67.4203C207.742 63.0252 209.932 67.1719 209.932 67.1719C204.128 75.6758 194.469 76.1947 194.469 76.1947L174.119 69.7243Z"
        fill="#2888FF"
      />
      <path
        d="M204.307 88.3265C181.243 87.3518 170.352 76.1067 170.352 76.1067L174.678 75.3643C180.347 81.1888 204.307 88.3265 204.307 88.3265Z"
        fill="#2888FF"
      />
      <path d="M196.578 73.8069L193.696 72.5015L188.939 85.3156L191.821 86.621L196.578 73.8069Z" fill="#2888FF" />
      <path
        d="M194.477 77.3658C194.186 77.3542 193.91 77.332 193.632 77.266C191.486 76.7535 190.117 74.4075 190.581 72.0366C190.975 70.0259 192.61 68.5659 194.471 68.5659C194.753 68.5659 195.037 68.5996 195.315 68.6657C197.462 69.1782 198.831 71.5241 198.367 73.895C197.973 75.9058 196.337 77.3658 194.477 77.3658ZM194.471 70.4128C193.392 70.4128 192.444 71.2596 192.215 72.4268C191.946 73.8022 192.74 75.1634 193.985 75.4607C194.148 75.4995 194.313 75.5189 194.477 75.5189C195.555 75.5189 196.504 74.6721 196.732 73.5053C196.863 72.8391 196.75 72.1562 196.416 71.5832C196.082 71.0102 195.565 70.615 194.962 70.4714C194.8 70.4322 194.635 70.4128 194.471 70.4128Z"
        fill="#E0E0E0"
      />
      <path
        d="M230.053 63.9061C225.773 63.189 212.8 52.5142 223.173 35.0059C228.453 26.0939 190.42 47.1694 188.566 66.7068C213.14 69.3409 230.053 63.9061 230.053 63.9061Z"
        fill="#BEDBFF"
      />
      <path
        d="M298.976 57.53C298.16 59.5661 296.515 60.924 294.011 61.7477C294.006 61.7464 294 61.751 293.995 61.7496C297.343 55.2186 296.373 50.7155 291.206 49.6743C287.58 48.9439 280.288 52.3195 277.013 54.0736C270.697 57.4584 255.213 66.3547 248.322 70.0489C241.907 62.5271 231.794 59.3745 229.876 58.0434C229.898 58.0425 238.67 56.5673 249.667 57.6588C253.982 58.0873 275.834 46.8329 286.145 45.9912C297.952 45.0281 301.726 50.6749 298.976 57.53Z"
        fill="#002652"
      />
      <path
        d="M172.854 65.7032C172.854 65.7032 183.383 50.2335 191.581 43.92C199.778 37.6065 215.383 31.0307 217.302 30.6184C217.302 30.6184 218.042 33.6482 219.673 34.0379C219.673 34.0379 203.577 43.9163 202.553 46.2498C202.101 47.2785 188.567 66.7065 188.567 66.7065C188.567 66.7065 182.304 69.21 172.854 65.7032Z"
        fill="#2888FF"
      />
      <path
        d="M274.316 87.5742C287.08 96.8187 279.538 106.576 279.538 106.576C279.538 106.576 275.799 106.662 274.053 104.776C274.053 104.776 278.935 105.207 274.316 87.5742Z"
        fill="#002652"
      />
      <path
        d="M221.521 27.8477L222.875 32.0557L204.152 44.4343L199.958 38.553L206.56 34.4096C206.898 34.1976 207.249 34.0129 207.611 33.8569L221.521 27.8477Z"
        fill="#002652"
      />
      <path
        d="M220.584 28.0467L216.781 30.04L218.597 31.7206L218.696 31.8827C219.044 32.4196 219.486 32.856 219.993 33.1732C220.499 33.4945 221.064 33.6921 221.658 33.7485L221.655 33.7568L227.818 34.466C227.955 34.4886 228.087 34.4032 228.137 34.261C228.142 34.2443 228.148 34.2277 228.15 34.2125C228.23 33.8759 228.048 33.5328 227.743 33.4451L225.072 32.4307C224.967 32.3883 224.872 32.3171 224.797 32.2211C224.571 31.9279 224.603 31.4856 224.869 31.2317L228.119 27.7914L226.824 26.7715L226.19 27.049C225.88 27.1843 225.557 27.2817 225.227 27.3412L222.179 27.8819C221.926 27.9272 221.672 27.9285 221.424 27.8888C221.138 27.8431 220.846 27.9101 220.584 28.0467Z"
        fill="#002652"
      />
      <path
        d="M231.079 23.9436C231.259 24.2603 231.202 24.7234 230.913 25.0558L226.184 29.7076L224.349 28.5441L230.772 23.9011C230.865 23.8189 231.001 23.8355 231.076 23.9385C231.077 23.9403 231.078 23.9422 231.079 23.9436Z"
        fill="#002652"
      />
      <path
        d="M230.032 23.4258C230.159 23.773 230.028 24.2172 229.691 24.4877L224.014 28.307L222.263 27.0498L229.737 23.3237C229.842 23.2614 229.974 23.3044 230.03 23.4202C230.031 23.4221 230.032 23.4239 230.032 23.4258Z"
        fill="#002652"
      />
      <path
        d="M228.165 23.2076C228.304 23.5488 228.189 23.9985 227.862 24.2838L222.595 28.1673L220.8 26.9886L227.867 23.1189C227.97 23.052 228.102 23.0894 228.163 23.2025C228.163 23.2043 228.165 23.2057 228.165 23.2076Z"
        fill="#002652"
      />
      <path
        d="M225.005 22.6101C225.236 22.8843 225.257 23.3507 225.03 23.7367L221.166 29.2791L219.124 28.7975L224.696 22.6313C224.774 22.5316 224.91 22.5201 225.001 22.6059C225.002 22.6073 225.004 22.6087 225.005 22.6101Z"
        fill="#002652"
      />
      <path d="M188.938 85.3198L163.217 80.4081L174.621 78.9163L188.938 85.3198Z" fill="#2888FF" />
      <path
        d="M175.615 74.1185L173.428 65.4845L165.224 63.988L164.494 68.873L163.854 73.1577L174.664 75.277C175.27 75.3956 175.781 74.7737 175.615 74.1185Z"
        fill="#DA68BF"
      />
      <path
        d="M170.061 67.8019L168.488 69.2766C167.699 70.0154 166.485 69.6529 166.141 68.5757L161.025 52.569C160.662 51.4341 161.201 50.1898 162.229 49.789L163.081 49.4565C167.052 47.908 171.337 50.6432 172.104 55.2169L172.794 59.3225C173.324 62.4857 172.282 65.7209 170.061 67.8019Z"
        fill="#002652"
      />
      <path d="M161.776 57.4816L156.377 59.5872L158.8 67.1692L164.199 65.0636L161.776 57.4816Z" fill="#ED956E" />
      <path
        d="M154.559 60.8354L155.714 64.4521C155.756 64.5832 155.82 64.7046 155.904 64.809L156.719 65.8321C156.993 66.1756 157.043 66.6715 156.844 67.0737L156.667 67.4334L158.772 74.0202C159.217 75.4141 160.763 75.9238 161.828 75.0277L171.698 66.7177L154.559 60.8354Z"
        fill="#DA68BF"
      />
      <path
        d="M159.633 70.9642L156.498 70.0851C155.79 69.8866 155.516 68.9359 155.991 68.3218L157.97 65.7598L159.633 70.9642Z"
        fill="#DA68BF"
      />
      <path
        d="M165.361 73.4837L165.973 72.2306C166.317 71.526 167.188 71.4069 167.677 71.9974L169.544 74.2501"
        fill="black"
        opacity="0.1"
      />
      <path
        d="M158.745 51.61L158.988 51.5153C160.015 51.1146 161.142 51.7097 161.504 52.8446L163.785 59.98L155.172 63.3389L154.533 61.3402C153.264 57.368 155.15 53.0118 158.745 51.61Z"
        fill="#002652"
      />
      <path
        d="M167.927 63.5969L163.321 65.3929L161.835 60.743L166.441 58.9469C167.376 58.5822 168.401 59.1242 168.731 60.1571L169.022 61.0667C169.352 62.0991 168.862 63.2321 167.927 63.5969Z"
        fill="#ED956E"
      />
      <path d="M167.227 62.783L164.524 63.8372L165.977 68.384L168.68 67.3299L167.227 62.783Z" fill="#ED956E" />
      <path
        d="M157.609 57.7548L163.138 55.5986L164.139 58.7309C165.048 58.464 165.911 58.193 166.021 58.0984C166.189 57.9543 164.974 61.1729 164.207 63.1754C163.996 63.7253 163.284 63.7008 163.104 63.138L162.464 61.1346C162.355 60.7943 162.017 60.6156 161.709 60.7357L158.91 61.8267L157.609 57.7548Z"
        fill="#002652"
      />
      <path
        d="M155.973 64.6324L157.247 64.1356C157.395 64.0779 157.557 64.0876 157.699 64.1624L158.416 64.5415C158.892 64.7927 158.855 65.5536 158.358 65.7475L156.995 66.2789"
        fill="black"
        opacity="0.1"
      />
      <path
        d="M159.476 70.3911L159.41 70.9318C159.374 71.2282 159.23 71.4951 159.01 71.6701L158.257 72.2712L157.85 70.9983L159.476 70.3911Z"
        fill="black"
        opacity="0.1"
      />
      <path
        d="M164.043 66.7915L164.796 69.1454L169.926 67.1448C172.995 65.948 174.604 62.2298 173.521 58.8399L172.442 55.4638C170.798 50.3189 165.69 47.6207 161.032 49.4366L159.95 49.8586C155.302 51.6713 152.864 57.3033 154.505 62.438L154.918 63.7331L156.836 62.9852C159.777 61.8373 163.005 63.542 164.043 66.7915Z"
        fill="#002652"
      />
      <path d="M166.228 65.7343L164.56 65.6179L164.099 73.6758L165.768 73.7921L166.228 65.7343Z" fill="#002652" />
      <path
        d="M165.44 67.4639C166.12 67.4639 166.671 66.8551 166.671 66.1041C166.671 65.3532 166.12 64.7444 165.44 64.7444C164.76 64.7444 164.209 65.3532 164.209 66.1041C164.209 66.8551 164.76 67.4639 165.44 67.4639Z"
        fill="#2888FF"
      />
      <path d="M172.835 56.0012L156.404 62.9028L156.962 64.5251L173.394 57.6235L172.835 56.0012Z" fill="#93C3FF" />
      <path
        d="M157.498 66.8087C157.283 66.8087 157.075 66.7099 156.926 66.5372L155.384 64.7439L155.108 63.9419L160.11 61.8411L161.012 64.4613C161.087 64.6788 161.081 64.9156 160.995 65.1276C160.908 65.3399 160.752 65.5025 160.555 65.5847L157.778 66.7509C157.688 66.7893 157.594 66.8087 157.498 66.8087Z"
        fill="#BEDBFF"
      />
      <path
        d="M159.868 62.4368L160.622 64.6258C160.669 64.7639 160.637 64.8812 160.613 64.9389C160.59 64.9966 160.532 65.1014 160.407 65.1536L157.629 66.3199C157.587 66.3379 157.543 66.3466 157.498 66.3466C157.397 66.3466 157.299 66.3 157.229 66.2192L155.745 64.4929L155.647 64.2094L159.868 62.4368ZM160.352 61.2456L154.569 63.6747L155.024 64.9947L156.623 66.8554C156.855 67.1256 157.173 67.2705 157.498 67.2705C157.642 67.2705 157.787 67.2419 157.927 67.1837L160.704 66.0174C161.327 65.7561 161.64 64.986 161.403 64.298L160.352 61.2456Z"
        fill="#93C3FF"
      />
      <path
        d="M164.922 66.6075C164.922 66.6075 164.922 66.6231 164.922 66.6075C164.95 66.7011 164.993 67.1377 164.512 67.6211C164.046 68.1045 163.156 68.5879 161.531 68.9466C161.305 68.9933 161.164 69.2272 161.206 69.4767C161.249 69.7262 161.461 69.8822 161.687 69.8354C166.561 68.7594 165.671 66.3269 165.671 66.3269C165.6 66.0929 165.36 65.9682 165.148 66.0618C164.936 66.1397 164.823 66.4048 164.908 66.6387L164.922 66.6075Z"
        fill="#BEDBFF"
      />
      <path
        d="M160.585 68.5879C161.334 68.4319 162.026 68.775 162.125 69.3675C162.224 69.9601 161.701 70.5682 160.952 70.7242C160.204 70.8801 159.511 70.537 159.412 69.9445C159.314 69.3519 159.836 68.7438 160.585 68.5879Z"
        fill="#002652"
      />
      <path
        d="M0 110.499C0 111.817 0.951329 112.886 2.12019 112.886H113.177C114.346 112.886 115.297 111.817 115.297 110.499C115.297 109.185 114.346 108.116 113.177 108.116C112.571 108.116 112.022 108.404 111.639 108.866C111.137 106.388 109.164 104.542 106.811 104.542C105.126 104.542 103.635 105.484 102.741 106.93C101.846 105.484 100.36 104.542 98.6754 104.542C97.9609 104.542 97.2792 104.713 96.6686 105.021C95.6179 102.394 93.2658 100.565 90.5395 100.565C88.8876 100.565 87.3781 101.24 86.2043 102.352C84.9123 100.767 83.0569 99.7723 80.9933 99.7723C79.2942 99.7723 77.7371 100.442 76.5162 101.564C74.9541 96.14 70.4248 92.2207 65.0768 92.2207C60.1166 92.2207 55.8618 95.5921 54.0207 100.405C52.2839 98.3045 49.7989 96.9908 47.0397 96.9908C43.3431 96.9908 40.1345 99.3518 38.5491 102.809C36.9682 99.3518 33.7596 96.9908 30.063 96.9908C26.2343 96.9908 22.9305 99.522 21.4115 103.176C20.5928 102.777 19.6935 102.553 18.7467 102.553C15.9305 102.553 13.5218 104.494 12.5183 107.26C11.5293 106.303 10.2467 105.734 8.84106 105.734C6.67328 105.734 4.79441 107.1 3.84308 109.11C3.45976 108.51 2.83511 108.116 2.12019 108.116C0.951329 108.116 0 109.185 0 110.499Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M284.794 32.187C284.794 33.5058 285.745 34.5748 286.914 34.5748H397.971C399.14 34.5748 400.091 33.5058 400.091 32.187C400.091 30.8736 399.14 29.8047 397.971 29.8047C397.365 29.8047 396.816 30.092 396.433 30.5545C395.931 28.0764 393.957 26.2309 391.605 26.2309C389.92 26.2309 388.429 27.1721 387.535 28.6187C386.64 27.1721 385.154 26.2309 383.469 26.2309C382.755 26.2309 382.073 26.401 381.463 26.7095C380.412 24.0824 378.06 22.2531 375.333 22.2531C373.682 22.2531 372.172 22.9286 370.998 24.04C369.706 22.4551 367.851 21.4608 365.787 21.4608C364.088 21.4608 362.531 22.1309 361.31 23.2528C359.748 17.8284 355.219 13.9092 349.871 13.9092C344.911 13.9092 340.656 17.2806 338.815 22.0935C337.078 19.993 334.593 18.6792 331.834 18.6792C328.137 18.6792 324.928 21.0403 323.343 24.497C321.762 21.0403 318.554 18.6792 314.857 18.6792C311.028 18.6792 307.724 21.2105 306.205 24.8641C305.387 24.4652 304.487 24.242 303.541 24.242C300.724 24.242 298.316 26.1829 297.312 28.9484C296.323 27.9911 295.041 27.422 293.635 27.422C291.467 27.422 289.588 28.7888 288.637 30.7989C288.254 30.1981 287.629 29.8047 286.914 29.8047C285.745 29.8047 284.794 30.8736 284.794 32.187Z"
        fill="url(#paint3_linear)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear"
          x1="130.323"
          x2="130.323"
          y1="0.857178"
          y2="33.7513"
        >
          <stop stopColor="#E3E3E3" />
          <stop offset="1" stopColor="#EFF0F2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear"
          x1="301.53"
          x2="301.53"
          y1="68.9592"
          y2="105.233"
        >
          <stop stopColor="#E3E3E3" />
          <stop offset="1" stopColor="#EFF0F2" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear"
          x1="57.6485"
          x2="57.6485"
          y1="112.886"
          y2="92.2207"
        >
          <stop stopColor="#EFF0F2" stopOpacity="0.4" />
          <stop offset="1" stopColor="#E3E3E3" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear"
          x1="342.442"
          x2="342.442"
          y1="34.5748"
          y2="13.9092"
        >
          <stop stopColor="#EFF0F2" stopOpacity="0.4" />
          <stop offset="1" stopColor="#E3E3E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const AgentHomeText = styled.div`
  cursor: pointer;
  height: 20px;
  margin: 20px 0 60px 0;
  text-align: center;
  vertical-align: middle;

  &:hover {
    border-bottom: 1px solid;
    color: ${p => p.theme.colors.green400};
    fill: ${p => p.theme.colors.green400};
  }
`;

const Centerer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const StyledRightArrow = styled(ArrowRightStrokeIcon)`
  height: 15px;
  margin-bottom: 0px;
  vertical-align: middle;
`;
