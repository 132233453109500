import DtoConverter from 'scripts/application/dto_converters/station_configuration_converter';
import qconsole from 'scripts/lib/qconsole';

export default class StationConfigurationGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.stationConfigurations;
  }

  handleFetchSuccess(fetchResultDto) {
    let results = fetchResultDto.map(DtoConverter.fromDto);
    this.store.set(results);
    this.store.resetLoading();
  }

  handleFetchError(errorDto) {
    this.store.resetLoading();
    qconsole.log(`Could not fetch StationConfigurations ${JSON.stringify(errorDto)}`);
  }

  handleEntity(payload) {
    this.store.addOrReplace(DtoConverter.fromDto(payload));
  }

  handleDelete(stationConfigurationId) {
    this.store.remove(stationConfigurationId);
  }
}
