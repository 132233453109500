import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="2 2 19 19" {...props}>
    <g className="phoneIcon" fill="none" fillRule="evenodd">
      <path
        className="contactIcon-path"
        d="M15.122 16.5c.147-.025.28-.1.375-.21l1.058-1.2c.222-.252.2-.627-.05-.855l-1.578-1.438c-.25-.228-.64-.23-.894-.007l-.844.748c-.19.166-.46.21-.697.118-.38-.15-1.13-.663-1.877-1.35l-.305-.325c-.7-.732-1.22-1.468-1.374-1.842-.095-.232-.05-.5.12-.683l.762-.828c.228-.25.225-.633-.006-.878L8.346 6.2c-.232-.247-.614-.267-.87-.05L6.252 7.19c-.11.094-.188.223-.215.368-.116.647-.132 2.566 2.48 5.568l.94.923c3.058 2.563 5.005 2.565 5.664 2.45z"
      />
    </g>
  </IconContainer>
);
