import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import FlexibleBlock from 'components/customer/flexible_profile_card/components/flexible_block';
import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';

export default function FlexibleItemList({ className, sectionConfig, sectionData }) {
  if (_.isEmpty(sectionConfig) || _.isEmpty(sectionData)) return null;

  // Get list configuration
  const listSourcePath = _.trim(_.get(sectionConfig, 'listSource'));
  const listMaxLength = _.get(sectionConfig, 'listMaxLength') || 0;
  const listItemConfig = _.get(sectionConfig, 'listItem');

  // Spot check the configs and data
  const listData = listSourcePath ? _.get(sectionData, listSourcePath) || [] : [];
  if (_.isEmpty(listData) || !_.isArray(listData) || _.isEmpty(listItemConfig)) return null;

  // Now make sure we have the right data set to render
  const listLimit = _.isNumber(listMaxLength) && _.isFinite(listMaxLength) && listMaxLength > 0 ? listMaxLength : 0;
  const dataSlice = listLimit > 0 ? listData.slice(0, listLimit) : listData;

  // Do we have any items to display? If not, quit
  const renderedItems = renderListItems(listItemConfig, dataSlice);
  if (_.isEmpty(renderedItems)) return null;

  return <StyledListContainer className={classnames(className, 'flexible-list')}>{renderedItems}</StyledListContainer>;
}

function renderListItems(itemConfig, data) {
  const itemAttributes = _.get(itemConfig, 'attributes');
  if (_.isEmpty(itemAttributes)) return [];

  return _.reduce(
    data,
    (acc, dataItem, key) => {
      // Check whether we need to render this list element
      const renderableAttrs = getAttributesToDisplay(itemAttributes, dataItem);
      if (!_.isEmpty(renderableAttrs)) {
        acc.push(
          <FlexibleBlock className="flexible-list-item" config={itemConfig} customAttributes={dataItem} key={key} />
        );
      }
      return acc;
    },
    []
  );
}

const StyledListContainer = styled.div`
  & .flexible-list-item {
    margin-bottom: 8px;

    & .visibleCustomAttribute {
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
`;

FlexibleItemList.propTypes = {
  className: PropTypes.string,
  sectionConfig: PropTypes.object.isRequired,
  sectionData: PropTypes.object.isRequired,
};
