export default function getContentGeneratorName(content) {
  const metaGeneratorTag = /<meta\s+name=["']?generator["']?\s+content=["']?(\w+)/gi;
  const result = metaGeneratorTag.exec(content);

  if (!result || !result.length) {
    return '';
  }

  const generatorName = result[1].toLowerCase();
  if (generatorName.indexOf('microsoft') === 0) {
    return 'microsoft';
  }
  return 'unknown';
}
