import Portal from 'components/common/utilities/portal';
import PropTypes from 'prop-types';
import React from 'react';

export default class PortalModalWrapper extends React.PureComponent {
  render() {
    return (
      <Portal node={document && document.getElementById('modalPortal')}>
        <div className={this.props.className}>{this.props.children}</div>
      </Portal>
    );
  }
}

PortalModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
