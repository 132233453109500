import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 60 60" {...props} className="magnifyingGlassIcon">
    <g fill="none" fillRule="evenodd">
      <circle className="magnifyingGlassIcon-circle" cx="30" cy="30" r="30" />
      <g className="magnifyingGlassIcon-stroke" strokeWidth="2" transform="translate(21 20)">
        <ellipse cx="7.25" cy="7.317" rx="7.25" ry="7.317" />
        <path d="M12.325 12.927l6.565 6.626" strokeLinecap="square" />
      </g>
    </g>
  </IconContainer>
);
