import { trim } from 'lodash';

const APPLE_LINE_BREAK = '<br class="Apple-interchange-newline">';

/**
 * For the reasons unknown, Chromium may occasionally prepend or append the "Apple interchange" line break
 * to the copy-pasted HTML. While it does not create any serious problems for the agents, it makes testing
 * difficult, so we manually remove those unwanted breaks
 *
 * @param {string} sourceHtml
 * @return {string}
 */
export function removeAppleLineBreaks(sourceHtml) {
  let html = trim(sourceHtml);

  if (html.startsWith(APPLE_LINE_BREAK)) {
    html = html.replace(new RegExp(`^(${APPLE_LINE_BREAK})+`, 'g'), '');
  }
  if (html.endsWith(APPLE_LINE_BREAK)) {
    html = html.replace(new RegExp(`(${APPLE_LINE_BREAK})+$`, 'g'), '');
  }

  return trim(html);
}
