import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('Transaction').extend('Uuid');

Factory.define('CustomerTransactionWithDefaults')
  .extend('Transaction')
  .attr('type', 'GENERIC')
  .attr('attributes', {
    flightNo: '117',
    pnr: 'TSF72R',
  });

Factory.define('StayTransactionWithDefaults')
  .extend('Transaction')
  .attr('type', 'STAY')
  .attr('attributes', {
    name: 'Chicago',
    url: 'http://www.google.com',
    checkInTime: '',
    checkOutTime: '',
    title: 'Vibrant 3BR-C at The Plymouth by Sonder',
    address: '417 South Dearborn Street',
    neighborhood: 'South Loop',
    ota: 'Airbnb',
    guests: '4 guests',
  });
