import { ensureCurrentMentionStillActive, removeMention } from './draft_mention';
import { ensureFullAnswerLinkSelection } from './draft_answer';
import { ensureFullPlaceholderSelection } from './draft_placeholder';
import { ensureFullVariableSelection } from './draft_variable';

export function formatEditorState(editorState, oldEditorState, isEditingSnippets, mentionCharacter) {
  if (oldEditorState.getSelection() !== editorState.getSelection()) {
    editorState = ensureFullVariableSelection(editorState);
    editorState = ensureFullAnswerLinkSelection(editorState);
    if (!isEditingSnippets) {
      editorState = ensureFullPlaceholderSelection(editorState);
    }
    if (oldEditorState.getSelection().hasFocus && !editorState.getSelection().hasFocus) {
      editorState = removeMention(editorState);
    }
  }

  if (oldEditorState.getCurrentContent() !== editorState.getCurrentContent()) {
    editorState = ensureCurrentMentionStillActive(editorState, mentionCharacter);
  }

  return editorState;
}
