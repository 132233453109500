import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CoBrowsingWidgetActivity from 'components/customer/conversation_history/conversation_items_v2/widget_activity/cobrowsing_widget_activity_item';
import ConversationItem from 'models/conversation_item';
import { WidgetActivityTypes } from 'models/widget_activity';

export const ActivityDisplayLabel = {
  [WidgetActivityTypes.COBROWSE]: 'Co-browsing',
};

export default function WidgetActivityItem({ item, className }) {
  const classes = classnames('widgetActivityItem', className);
  switch (item.content.activityType) {
    case WidgetActivityTypes.COBROWSE:
      return <CoBrowsingWidgetActivity className={classes} item={item} />;

    default:
      return null;
  }
}

WidgetActivityItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
