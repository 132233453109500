import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import AttributeDef from 'models/configuration/attribute_def';
import CustomAttributeBase from 'components/customer/summary/custom_attributes/custom_attribute_base';
import { tryStringify } from 'scripts/lib/try_stringify';

/**
 * This component is displayed in place of the actual Custom Attribute when the attribute cannot be rendered
 * due to exceptions. The most common cause would be malformed data sent from the external system (e.g. an
 * object instead of a string)
 */
export default function CustomAttributeError({ className, 'data-aid': dataAid, def, value }) {
  const classNames = classnames(className, 'error');
  const sanitizedValue = _.truncate(tryStringify(value), { length: 25 });
  const tooltip = `Unable to display the data. [${sanitizedValue}] is not a correct value for '${def.label}'`;

  return (
    <CustomAttributeBase className={classNames} data-aid={dataAid} label={def.label}>
      <CustomAttributeValue title={tooltip}>Error</CustomAttributeValue>
    </CustomAttributeBase>
  );
}

const CustomAttributeValue = styled.div`
  background-color: ${p => p.theme.colors.yellow100};
  color: ${p => p.theme.colors.red500};
  font-weight: ${p => p.theme.fontWeight.medium};
  overflow: hidden;
  padding-left: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

CustomAttributeError.propTypes = {
  className: PropTypes.string,
  def: PropTypes.instanceOf(AttributeDef),
  'data-aid': PropTypes.string,
  value: PropTypes.any,
};
