import _ from 'lodash';

import ChatMessage from 'models/chat_message';
import Conversation from 'models/conversation';
import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import CustomerConverter from 'scripts/application/dto_converters/customer_converter';
import Factory from 'factories/all';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import InteractionType from 'models/interaction_type';

export default class IncomingChatSimulator extends CommunicationSimulator {
  constructor({ sessionMapper, orgId, publisher, services }, database) {
    super({ attribute: 'incomingChats', createItem: createIncomingChatMessage, orgId, publisher, services }, database);
    this.sessionMapper = sessionMapper;
  }

  _getNextCommunication() {
    let incomingChat = _.cloneDeep(super._getNextCommunication());
    if (!incomingChat) {
      return;
    }
    incomingChat.sessionId = IdGenerator.newId();
    this.sessionMapper.associateSessionIdWithCustomerId(incomingChat.sessionId, incomingChat.customerId);
    return incomingChat;
  }

  _findConversation(communication, customer) {
    let conversation = this._findConversationByChatSessionId(customer, communication.sessionId);
    if (conversation) {
      conversation.status = Conversation.Status.OPEN;
    }
    return conversation;
  }

  _findConversationByChatSessionId(customerDto, sessionId) {
    let customer = CustomerConverter.fromDto(customerDto);
    let conversation = _.find(customer.conversations, t =>
      t.conversationItems.some(ci => ci.content instanceof ChatMessage && ci.content.sessionId === sessionId)
    );
    return conversation ? _.find(customerDto.conversations, { id: conversation.id }) : null;
  }

  _createRoutingItem(attrs) {
    return Factory.build(
      'RoutingItemForInbound',
      this._getRoutingItemAttrs({ channel: InteractionType.CHAT, ...attrs })
    );
  }
}

export function createIncomingChatMessage(incomingChat, currentAgent) {
  return Factory.build('ChatMessageItemWithDefaults', {
    initiator: {
      type: Communicator.CUSTOMER,
      id: incomingChat.customerId,
    },
    content: {
      sessionId: incomingChat.sessionId,
      message: incomingChat.message,
      pendingResponse: true,
    },
  });
}
