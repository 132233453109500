import React from 'react';

import { Attachment, Emoji, InlineImage } from './shared/editor_action_buttons';
import { RichTextEditorStyles } from './shared/editor_styles';
import InlineImagesPlugin from 'components/text_editor/plugins/inline_images';
import Composer, { Header, HeaderText, useComposer } from './shared/composer';
import connect from 'components/lib/connect';
import { EditorContainer, useEditor } from './shared/editor';
import ForgottenAttachmentModal from './email/forgotten_attachment_modal';
import ForgottenTextModal from './email/forgotten_text_modal';
import From from './email/from';
import InternalEmailModal from './email/internal_email_modal_wrapper';
import PlaintextSerializer from 'components/text_editor/serializers/plaintext_serializer';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import Recipients from './email/recipients';
import RichTextEditor from './editors/rich_text';
import SendEmail from 'actions/composer/send_email';
import Subject, { SubjectContainer } from './email/subject';
import Thread from './email/thread';
import useEmailComposer from './email/use_email_composer';
import useEmailTemplateAndAnswers from './email/use_email_template';
import useHasRemovableThread from './email/use_has_removable_thread';
import useCheckEmpty from './email/use_email_check_empty';
import useInsertSubject from './email/use_insert_subject';
import { usePhraseSuggestionsPlugin } from 'components/text_editor/plugins/phrase_suggestions';
import { usePlugins } from 'components/text_editor';
import useWasCustomerAddedToEmail from './email/use_was_customer_added_to_email';

export function EmailComposer({ defaultBody, profile }) {
  const composer = useComposer(SendEmail, { checkIsEmpty: useCheckEmpty(defaultBody) });
  const editorProps = useEditor('bodyHtml', composer, {
    shouldInsertAnswers: false,
    serializer: FullSerializer,
  });

  const subjectProps = useEditor('subjectHtml', composer, {
    attr: 'subject',
    autoFocus: false,
    serializer: PlaintextSerializer,
    shouldInsertAnswers: false,
  });
  const hasRemovableThread = useHasRemovableThread();
  const [wasCustomerAddedToEmail, ignoreCustomerWasAddedToEmail] = useWasCustomerAddedToEmail(profile);
  useInsertSubject(subjectProps);
  useEmailTemplateAndAnswers(editorProps, defaultBody);

  const [composerProps, composerRef] = composer;
  const {
    clearForgottenAttachmentText,
    clearShowForgottenTextModal,
    forgottenAttachmentText,
    onSubmit,
    showForgottenTextModal,
  } = useEmailComposer(composerRef, { ...composerProps, defaultBody });
  composerProps.onSubmit = onSubmit;

  const phraseSuggestionsPlugin = usePhraseSuggestionsPlugin();
  const plugins = usePlugins(() => [phraseSuggestionsPlugin, InlineImagesPlugin()]);

  return (
    <Composer includeAttachments submitText="Send Email" {...composerProps}>
      <Header>
        <HeaderText>Email</HeaderText>
        <Recipients />
        <From />
      </Header>

      <SubjectContainer data-aid="slate-subject-container">
        <Subject {...subjectProps} />
        <RichTextEditorStyles {...editorProps}>
          <Emoji {...editorProps} />
          <InlineImage {...editorProps} />
          <Attachment alwaysShow />
        </RichTextEditorStyles>
      </SubjectContainer>

      <EditorContainer includeAttachments>
        <RichTextEditor placeholder="Type to respond" {...editorProps} plugins={plugins} />
        {hasRemovableThread && <Thread />}
      </EditorContainer>

      {hasRemovableThread && wasCustomerAddedToEmail ? (
        <InternalEmailModal onKeepHistory={ignoreCustomerWasAddedToEmail} />
      ) : null}
      {forgottenAttachmentText ? (
        <ForgottenAttachmentModal
          onCancel={clearForgottenAttachmentText}
          onSubmit={onSubmit}
          text={forgottenAttachmentText}
        />
      ) : null}
      {showForgottenTextModal ? (
        <ForgottenTextModal onCancel={clearShowForgottenTextModal} onSubmit={onSubmit} />
      ) : null}
    </Composer>
  );
}

const MemoizedComposer = React.memo(EmailComposer);
const EmailComposerContainer = connect(mapStateToProps)(MemoizedComposer);

function mapStateToProps({ getProvider }) {
  const currentAgent = getProvider('currentAgent').get() || {};
  const { emailGreetingSnippetId, emailSignatureSnippetId } = currentAgent;

  return {
    defaultBody: {
      greetingSnippet: getProvider('currentAgentSnippets').findBy({ id: emailGreetingSnippetId }),
      signatureSnippet: getProvider('currentAgentSnippets').findBy({ id: emailSignatureSnippetId }),
    },
    profile: getProvider('profile').get(),
  };
}

export default EmailComposerContainer;
