import React from 'react';

export default function DownloadIcon() {
  return (
    <svg className="reportHome-header-downloadMenu-button-icon" fill="none" height="13" width="14">
      <path d="M0 0h14v13H0z" fill="none" />
      <path d="M.587 12.136h12.916c.294 0 .49.182.49.455s-.196.454-.49.454H.49c-.293 0-.489-.181-.489-.454.098-.273.294-.455.587-.455zm5.871-2.512V.376c0-.226.215-.376.538-.376.323 0 .538.15.538.376v9.248c0 .226-.215.376-.538.376-.323 0-.538-.15-.538-.376z" />
      <path d="M6.752 9.182l4.11-3.818c.195-.182.586-.182.782 0a.524.524 0 0 1 0 .727l-4.11 3.818c-.195.182-.587.182-.782 0-.196-.182-.196-.454 0-.727z" />
      <path d="M7.339 9.182l-4.11-3.818c-.196-.182-.587-.182-.783 0a.524.524 0 0 0 0 .727l4.11 3.818c.195.182.587.182.783 0 .195-.182.195-.454 0-.727z" />
    </svg>
  );
}
