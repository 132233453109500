import addCustomerToInbox from 'actions/inbox/lib/add_customer_to_inbox';
import closeNotification from 'actions/notification/lib/close_notification';
import ConversationUpdater from 'actions/conversation/lib/conversation_updater';
import CustomerView from 'models/location/customer_view';
import { getOfferedSessionForAgent } from './session_finder';
import RoutingEventType from 'models/routing_event/routing_event_type';

export default class AcceptCommunicationSession extends ConversationUpdater {
  run() {
    const currentAgentId = this.currentAgent.id;
    let offeredSession = getOfferedSessionForAgent(this.context.stores.activeSessions, currentAgentId);
    if (!offeredSession) {
      return;
    }

    this.context.capacityManager.postponeMessagingRouting();

    offeredSession.accept(currentAgentId);

    this.context.stores.activeSessions.replace(offeredSession);

    this.context.gateways.routingEvent.publish(currentAgentId, {
      queueItemId: offeredSession.id,
      type: RoutingEventType.ACCEPTED_COMMUNICATION_SESSION,
    });

    closeNotification(this.context, offeredSession.id);

    let customerView = CustomerView.create({
      currentConversationId: offeredSession.queueItem.conversationId,
      customerId: offeredSession.customer.id,
    });
    this.context.router.navigateTo(customerView);

    addCustomerToInbox(this.context, {
      conversationId: offeredSession.queueItem.conversationId,
      customerId: offeredSession.customer.id,
    });
  }
}
