import React from 'react';
import styled from 'styled-components';

import { EmptyState, EmptyText } from 'components/customer/answer_panel/lib/empty_state';
import MagnifyingGlassIcon from 'components/lib/icons/magnifying_glass_icon';

export default function PlaceholderAnswerSearch() {
  return (
    <Placeholder data-aid="placeholderAnswerSearch">
      <MagnifyingGlassIcon />
      <EmptyText>Search Answers</EmptyText>
    </Placeholder>
  );
}

const Placeholder = styled(EmptyState)`
  .magnifyingGlassIcon {
    height: 60px;
    width: 60px;
  }
  .magnifyingGlassIcon-circle {
    fill: ${p => p.theme.colors.gray100};
  }
  .magnifyingGlassIcon-stroke {
    stroke: ${p => p.theme.colors.green400};
  }
`;
