import _ from 'lodash';
import classnames from 'classnames';
import createEnum from 'scripts/lib/create_enum';
import React from 'react';
import PropTypes from 'prop-types';
import { faFillDrip } from '@fortawesome/pro-regular-svg-icons/faFillDrip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import { CELL_BACKGROUND_COLORS } from 'components/text_editor/plugins/tables';

const COLORS = createEnum(...Object.keys(CELL_BACKGROUND_COLORS));

class SlateTableCellFillMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };

    _.bindAll(this, [
      'closeMenu',
      'getListItemClassname',
      'maintainEditorFocus',
      'onSetBackgroundColor',
      'renderColorSwatch',
      'renderMenu',
      'toggleMenu',
    ]);
  }

  render() {
    const chevronDir = this.state.isMenuOpen ? 'up' : 'down';
    const className = classnames('slateTableCellFillMenu', 'draftStylesMenu-item', {
      'draftStylesMenu-item-active': this.state.isMenuOpen,
      'slateStylesMenu-item-disabled': !this.props.isSelectionInTable,
    });

    return (
      <span>
        <OutsideClickHandler onClickOutside={this.closeMenu}>
          <div className={className} onClick={this.toggleMenu} title="Cell color">
            <FontAwesomeIcon icon={faFillDrip} />
            <i className={`draftStylesMenu-item-chevron fa fa-angle-${chevronDir}`} />
          </div>
          {this.renderMenu()}
        </OutsideClickHandler>
      </span>
    );
  }

  renderMenu() {
    if (!this.state.isMenuOpen) {
      return null;
    }

    return (
      <div className="slateTableCellFillMenu-list">
        <div>
          {this.renderColorSwatch(COLORS.white)}
          {this.renderColorSwatch(COLORS.lightRed)}
          {this.renderColorSwatch(COLORS.lightOrange)}
          {this.renderColorSwatch(COLORS.lightGreen)}
          {this.renderColorSwatch(COLORS.lightBlue)}
        </div>
        <div>
          {this.renderColorSwatch(COLORS.gray)}
          {this.renderColorSwatch(COLORS.red)}
          {this.renderColorSwatch(COLORS.orange)}
          {this.renderColorSwatch(COLORS.green)}
          {this.renderColorSwatch(COLORS.blue)}
        </div>
        <div>
          {this.renderColorSwatch(COLORS.darkGray)}
          {this.renderColorSwatch(COLORS.darkRed)}
          {this.renderColorSwatch(COLORS.darkOrange)}
          {this.renderColorSwatch(COLORS.darkGreen)}
          {this.renderColorSwatch(COLORS.darkBlue)}
        </div>
      </div>
    );
  }

  renderColorSwatch(color) {
    const itemStyle = { backgroundColor: CELL_BACKGROUND_COLORS[color] };
    return (
      <div className={this.getListItemClassname(color)} onClick={e => this.onSetBackgroundColor(e, color)}>
        <div
          className={classnames('slateTableCellFillMenu-list-item', `slateTableCellFillMenu-list-item-${color}`)}
          style={itemStyle}
        />
      </div>
    );
  }

  getListItemClassname(color) {
    if (!this.props.editor) {
      return 'slateTableCellFillMenu-item-container';
    }

    const value = this.props.editor.value;
    const { startBlock } = value;
    const cell = startBlock && value.document.getClosest(startBlock.key, p => p.type === 'table_cell');
    const existingCellColor = cell && cell.data.get('backgroundColor');

    return classnames('slateTableCellFillMenu-item-container', {
      'draftStylesFontColorMenu-list-item-selected': existingCellColor === CELL_BACKGROUND_COLORS[color],
    });
  }

  toggleMenu() {
    if (this.props.isSelectionInTable) {
      this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }
  }

  closeMenu() {
    this.setState({ isMenuOpen: false });
  }

  maintainEditorFocus() {
    this.props.editor.focus();
  }

  onSetBackgroundColor(event, color) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.setBackgroundColor(color));
    this.closeMenu();
  }
}

export default SlateTableCellFillMenu;

SlateTableCellFillMenu.propTypes = {
  editor: PropTypes.object,
  isSelectionInTable: PropTypes.bool,
  onChange: PropTypes.func,
};
