import Immutable from 'immutable';

import createDocumentStoreClass from './lib/create_document_store_class';
import modalFromJs from 'models/modal/modal_from_js';

let converter = {
  fromImmutable(immutable) {
    return modalFromJs(immutable.toJS());
  },

  toImmutable(entity) {
    return Immutable.fromJS(entity.toJs());
  },
};

export default createDocumentStoreClass({ converter });
