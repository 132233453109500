import OpenCommentPanel from 'actions/task/open_comment_panel';
import ToggleSidePanels from 'actions/task/toggle_side_panels';

export default class OpenOrRefreshCommentPanel {
  constructor(context) {
    this.context = context;
  }

  run({ itemId, location }) {
    const commentPanel = this.context.stores.commentPanel.get();
    this.context.analytics.track('Task Comment Panel Opened', {
      itemId,
      location,
    });

    if (commentPanel.isOpen()) {
      // switch to the comments panel
      const refreshOnly = commentPanel.isOpen() && commentPanel.selectedItemId === itemId;
      this.context.executeAction(OpenCommentPanel, { itemId, refreshOnly });
    } else {
      // load comments for item and take care of other side panel logic
      this.context.executeAction(ToggleSidePanels, { itemId });
    }
  }
}
