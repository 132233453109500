import ClearSuggestedReply from 'actions/suggested_replies/clear_suggested_reply';

export default class CloseAnswerPanel {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationId, compositionId }) {
    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.clearAnswer();
    answerPanel.clearHistory();
    answerPanel.clearSearchResult();
    answerPanel.close();
    this.context.stores.answerPanel.set(answerPanel);

    this.context.stores.snippetSearchResult.remove();
    this.context.executeAction(ClearSuggestedReply, { customerId });
    this.context.analytics.track('Answer Panel Closed', { customerId, conversationId, compositionId });
  }
}
