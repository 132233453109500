import createModel, { prop } from './lib/create_model';

const IvrSettings = createModel({
  modelName: 'IvrSettings',
  properties: {
    ivrId: prop(String),
    ivrVersion: prop(Number),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const MessageSettings = createModel({
  modelName: 'MessageSettings',
  properties: {
    type: prop(String),
    voice: prop(String),
    message: prop(String),
    language: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default createModel({
  modelName: 'VoiceEndpointConfiguration',
  properties: {
    callerId: prop(String),
    errorMessage: prop(MessageSettings),
    holdMessage: prop(MessageSettings),
    initialIvr: prop(IvrSettings),
    queueIvr: prop(IvrSettings),
    queueUrl: prop(String),
    recordCalls: prop(Boolean),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
