import RequestCompositions from 'actions/customer/request_compositions';
import RequestItemIds from 'actions/customer/request_item_ids';

export default function(context, customerId, { isRefreshing } = { isRefreshing: false }) {
  context.gateways.customerAssignee.request(customerId);
  context.gateways.customerHistory.requestConversations(customerId);
  context.gateways.customerHistory.subscribeToConversationItems(customerId);
  context.gateways.customerHistory.subscribeToMerge(customerId);
  context.gateways.customerProfile.request({ customerId });
  context.gateways.customerProfileMerge.subscribe({ customerId });
  context.gateways.customerNote.request({ customerId });
  context.gateways.customerTransaction.request({ customerId });
  context.gateways.customerActiveSecureDataRequests.subscribe({ customerId });
  context.executeAction(RequestItemIds, { customerId, dontSetLoading: isRefreshing });
  context.gateways.relationship.requestAll({ customerId });

  const appFeatures = context.stores.appFeatures.get();
  if (appFeatures.isEnabled('gclDemo2023')) {
    context.gateways.aiSuggestedReply.request({ customerId });
  }

  // We don't need to re-request compositions for a given customer when we're refreshing them
  // as a result of a disconnect. Whereas other customer fields like the history or profile
  // certainly could have changed during the disconnect, the compositions are local to the
  // agent.. so the local agent compositions _are_ certainly up to date. No other agent can
  // change them.
  if (!isRefreshing) {
    context.executeAction(RequestCompositions, { customerId });
  }

  context.stores.customers.unmarkCustomerAsStale(customerId);

  let customerStores = context.stores.customers.storesFor(customerId);
  if (!isRefreshing) {
    customerStores.assignee.setLoading();
    customerStores.conversationHistory.setLoading();
    customerStores.conversations.setLoading();
    customerStores.note.setLoading();
    customerStores.profile.setLoading();
    customerStores.transactions.setLoading();
    customerStores.activeSecureDataRequests.setLoading();
    customerStores.relationships.setLoading();
  }
}
