export default class TrackItemMenuOpened {
  constructor(context) {
    this.context = context;
  }

  run({ itemId, itemType, location }) {
    this.context.analytics.track('Conversation Item Menu Opened', {
      itemId,
      itemType,
      location,
    });
  }
}
