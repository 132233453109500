import AttachmentClasses from './attachment_classes';
import TypeReflect from 'models/lib/type_reflect';

const typeReflect = new TypeReflect(AttachmentClasses);

export { typeReflect };

export default function(attachment) {
  let AttachmentClass = typeReflect.typeToConstructor(attachment.type);
  return AttachmentClass.fromJs(attachment);
}
