import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('ApiTokenDemo').extend('Uuid');

Factory.define('ApiTokenDemoWithDefaults')
  .extend('ApiTokenDemo')
  .attr('createdAt', () => new Date().toISOString())
  .attr('agentId', 'some-agent')
  .attr('token', 'some-super-secret-token')
  .attr('name', 'New Token');