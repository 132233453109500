import React, { createContext, useMemo } from 'react';

export const SizeContext = createContext({
  height: 0,
  width: 0,
});

export default function SizeProvider({ children, height, width }) {
  const sizeValue = useMemo(() => ({ height, width }), [height, width]);

  return <SizeContext.Provider value={sizeValue}>{children}</SizeContext.Provider>;
}
