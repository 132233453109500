import ErrorReporter from 'scripts/infrastructure/error_reporter';
import SystemConfiguration from 'models/system_configuration';

export default class SystemConfigurationGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleFetchSystemConfigurationSuccess(fetchResultDto) {
    let systemConfiguration = SystemConfiguration.fromJs(fetchResultDto);
    this.context.stores.systemConfiguration.set(systemConfiguration);
  }

  handleFetchSystemConfigurationError(errorDto) {
    ErrorReporter.reportMessage('Failed to fetch system configuration', { extra: { ...errorDto } });
  }
}
