import { v4 } from 'uuid';

import IdGenerator from 'scripts/domain/contracts/id_generator';
import URLSafeBase64 from 'urlsafe-base64';

export default function() {
  IdGenerator.newId = () => {
    // UUID is 128-bit
    // Buffer class takes number of octets (8 bits)
    // 128 bits / 8 bits = 16 octets
    const buffer = new Buffer(16);
    v4({}, buffer);
    return URLSafeBase64.encode(buffer);
  };
  return IdGenerator;
}
