import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import { searchAll } from 'scripts/infrastructure/backends/fake_backend/fake_search';

export default class SearchService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(payload, callback, path, { orgId }) {
    let results = searchAll(
      this.getDatabase(orgId).customers,
      this.getDatabase(orgId).agents,
      this.getDatabase(orgId).routingGroups,
      this.getDatabase(orgId).topics,
      orgId,
      { payload }
    );
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: results,
    });
  }

  getRoutes() {
    return bindCallbacks({ '/api/v1/orgs/:orgId/search': { POST: this.find } }, this);
  }
}
