import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import RecordingSummary, {
  RecordingSummaryStatusState,
  RecordingSummaryStatusErrorCode,
} from 'models/recording_summary';

export default function RecordingDetails({ recordingSummary }) {
  if (
    !recordingSummary ||
    recordingSummary.status.state === '' ||
    recordingSummary.status.state === RecordingSummaryStatusState.REDACTED
  ) {
    return null;
  }

  if (recordingSummary.status.state === RecordingSummaryStatusState.FAILED) {
    return (
      <RecordingDetailsContainer>
        <StatusFailed>{getErrorMessage(recordingSummary.status.errorCode)}</StatusFailed>
      </RecordingDetailsContainer>
    );
  }

  if (recordingSummary.status.state === RecordingSummaryStatusState.PENDING) {
    return (
      <RecordingDetailsContainer>
        <StatusPending>Writing call summary ...</StatusPending>
      </RecordingDetailsContainer>
    );
  }

  return <RecordingDetailsContainer>{recordingSummary.summary}</RecordingDetailsContainer>;
}

function getErrorMessage(errorCode) {
  switch (errorCode) {
    case RecordingSummaryStatusErrorCode.EMPTY_TRANSCRIPT:
      return 'Unable to create call summary as the recording did not contain audible speech.';
    case RecordingSummaryStatusErrorCode.LANGUAGE_NOT_SUPPORTED:
      return 'Unable to create call summary due to unsupported language.';
    default:
      return 'Unable to create call summary.';
  }
}

RecordingDetails.propTypes = {
  recordingSummary: PropTypes.instanceOf(RecordingSummary),
};

const StatusPending = styled.span`
  color: ${p => p.theme.colors.gray600};
`;
const StatusFailed = styled.span`
  color: ${p => p.theme.colors.gray600};
`;
const RecordingDetailsContainer = styled.div`
  padding-top: 12px;
`;
