import isArray from 'lodash/isArray';
import qconsole from 'scripts/lib/qconsole';

import WidgetConfiguration from 'models/widget_configuration';

export default class WidgetConfigGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchAllSuccess(data) {
    this.handleUpdateAll(data);
  }

  onBroadcast(data) {
    this.handleUpdateItem(data);
  }

  onFetchAllError(error) {
    this.context.stores.widgets.resetLoading();
    qconsole.error(`Unexpected error requesting widget list: ${JSON.stringify(error)}`);
  }

  handleUpdateAll(data) {
    this.context.stores.widgets.resetLoading();
    if (!isArray(data)) {
      qconsole.error(
        `WidgetConfigGatewayObserver.handleUpdateAll: Unexpected data - expected array, got [${JSON.stringify(data)}]`
      );
      return;
    }

    this.context.stores.widgets.set(data.map(widget => WidgetConfiguration.fromJs(widget)));
  }

  handleUpdateItem(data) {
    if (!data || !data.id) {
      qconsole.error(`WidgetConfigGatewayObserver.handleUpdateItem: Unexpected data. Got [${JSON.stringify(data)}]`);
      return;
    }

    this.context.stores.widgets.addOrReplace(WidgetConfiguration.fromJs(data));
  }
}
