import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

export default function TablesPlugin() {
  return {
    commands: { setCellAlignment, setBackgroundColor },
  };
}

function setCellAlignment(editor, textAlign) {
  const value = editor.value;
  const { startBlock } = value;
  const cell = startBlock && value.document.getClosest(startBlock.key, p => p.type === 'table_cell');
  let currentData = cell.data.toJSON();
  let newData = { ...currentData, textAlign };
  return editor.setNodeByKey(cell.key, { data: newData });
}

function setBackgroundColor(editor, color) {
  const value = editor.value;
  const { startBlock } = value;
  const cell = startBlock && value.document.getClosest(startBlock.key, p => p.type === 'table_cell');
  let currentData = cell.data.toJSON();
  let newData;

  if (color === 'white') {
    newData = _.omit(currentData, ['backgroundColor']);
  } else {
    const backgroundColor = CELL_BACKGROUND_COLORS[color];
    newData = { ...currentData, backgroundColor };
  }
  return editor.setNodeByKey(cell.key, { data: newData });
}

export const CELL_BACKGROUND_COLORS = {
  lightBlue: '#90E7FD',
  lightGreen: '#9BD79B',
  lightOrange: '#FFE69B',
  lightRed: '#FF8796',
  white: '#FFFFFF',
  blue: '#57D0EE',
  green: '#64B964',
  orange: '#FFD24B',
  red: '#ED435D',
  gray: '#E6E6E6',
  darkBlue: '#00A0BE',
  darkGreen: '#009B00',
  darkOrange: '#FFBE00',
  darkRed: '#FA193C',
  darkGray: '#D4D4D4',
};

// necessary because of the overriding rules in DeepTable when used
export function renderTableNode(props, editor, next) {
  const { attributes, children, node } = props;
  switch (node.type) {
    case 'table':
      return (
        <StyledTable className="slateEditor-table">
          <tbody {...attributes}>{children}</tbody>
        </StyledTable>
      );
    case 'table_row':
      return <tr {...attributes}>{children}</tr>;
    case 'table_cell': {
      const textAlign = node.get('data').get('textAlign');
      const backgroundColor = node.get('data').get('backgroundColor');

      return (
        <StyledTableCell style={{ textAlign, backgroundColor }} {...attributes}>
          {children}
        </StyledTableCell>
      );
    }
    case 'paragraph': {
      const textAlign = node.get('data').get('textAlign');
      const margin = '0';
      return (
        <p {...attributes} style={{ textAlign, margin }}>
          {children}
        </p>
      );
    }
    default:
      return next();
  }
}

const StyledTable = styled.table`
  border-collapse: collapse;
  display: block;
  max-width: 800px;
  min-width: 100%;
  overflow: auto;
  table-layout: fixed;
`;

const StyledTableCell = styled.td`
  border: 1px solid ${p => p.theme.colors.gray300};
  min-height: 28px;
  min-width: 50px;
  padding: 3px 5px;
  vertical-align: top;
`;
