import CustomReporting from 'models/location/custom_reporting';
import OpenModal from 'actions/modal/open_modal';
import CustomReportingError from 'models/modal/custom_reporting_error';

export default class CustomReportingGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleLoad({ path, files, dirs }) {
    this.updateLocation(path, location => {
      location.filesLoaded({ dirs, files });
    });
  }

  handleLoadError({ path }) {
    this.updateLocation(path, location => {
      location.failedToLoad();
      this.context.executeAction(OpenModal, CustomReportingError.create());
    });
  }

  updateLocation(path, cb) {
    let store = this.context.stores.currentLocation;

    let location = store.get();
    if (!(location instanceof CustomReporting) || location.path !== path) {
      return; // no longer on the page, abort
    }

    cb(location); // mutate the location
    store.set(location); // save changes
  }
}
