import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('WebForm')
  .attr('type', 'WEB_FORM');

Factory.define('WebFormWithDefaults')
  .extend('WebForm')
  .attr('submittedAt', () => new Date().toISOString())
  .attr('content', {
    name: 'Arnold Schwarzenegger',
    email: 'topGunActor@sag.com',
    reservationType: 'Limo',
    comments: 'Californians love their cars.'
  });

Factory.define('WebFormItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], (content) => Factory.attributes('WebForm', content));

Factory.define('WebFormItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('_demoOnly', true)
  .attr('content', ['content'], (content) => Factory.attributes('WebFormWithDefaults', content));