import _ from 'lodash';

import { after } from 'scripts/infrastructure/backends/http_client';
import Observable from 'scripts/lib/observable_mixin';
import MqttTopicPattern from 'scripts/adapters/gateways/lib/mqtt_topic_pattern';

export default class StationConfigurationGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
  }

  init({ orgId, agentId }) {
    this.orgId = orgId;
    this.agentId = agentId;
  }

  get broadcastTopic() {
    return `v1/orgs/${this.orgId}/telephony/configuration/stations/+`;
  }

  get broadcastDeleteTopicPattern() {
    return new MqttTopicPattern(`v1/orgs/${this.orgId}/telephony/configuration/stations/+/event/delete`);
  }

  request() {
    this._isSubscribed = true;
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.get.bind(this));
    this.mqttClient.subscribe(this.broadcastDeleteTopicPattern.interpolate(), this.handleDeleteBroadcast.bind(this));
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleEntity', envelope.payload);
  }

  handleDeleteBroadcast(envelope, topic) {
    this.notifyObservers('handleDelete', this.broadcastDeleteTopicPattern.extractParams(topic)[0]);
  }

  reconnectHandler() {
    if (this._isSubscribed) {
      this.request();
    }
  }

  get url() {
    return `/api/v1/orgs/${this.orgId}/telephony/configuration/stations`;
  }

  get _httpClient() {
    return this.backend.axios();
  }

  get() {
    return after(this._httpClient.get(`${this.url}`), this.onFetchAll.bind(this));
  }

  onFetchAll(err, res) {
    if (err) {
      this.notifyObservers('handleFetchError', err);
      return;
    }
    res && res.status === 200
      ? this.notifyObservers('handleFetchSuccess', res.data)
      : this.notifyObservers('handleFetchError', res.data);
  }
}

_.extend(StationConfigurationGateway.prototype, Observable);
