import { Inline } from 'slate-old';

import _ from 'lodash';

export const CAPITALIZED_WORD = 'capitalizedWord';
// Capitalizes the first character of the first word of a sentence after finishing the typing of that word.
// You can immediately hit Ctrl-Z to undo the capitalization.
export default function CapitalizeFirstWordPlugin(options) {
  return {
    onKeyDown(evt, editor, next) {
      const { value } = editor;
      let { selection, decorations } = value;

      let offset = selection.start.offset;
      const lastText = value.texts.last();

      let otherDecorations = value.decorations.filter(decoration => decoration.mark.type !== CAPITALIZED_WORD);
      if (otherDecorations.size > 0) {
        return next();
      }

      // We only capitalize if we're adding characters to the end of a text.
      if (!lastText || offset !== lastText.text.length) {
        return next();
      }

      let capitalizedWord = decorations.filter(d => d.mark.type === CAPITALIZED_WORD);
      if (capitalizedWord.get(0)) {
        // after hitting backspace, if the previous word is a capitalized word (plus single whitespace character),
        // undo the capitalization
        if (evt.key === 'Backspace' && capitalizedWord.get(0).focus.offset + 1 === selection.start.offset) {
          editor.undo();
          return;
        }

        // if pressing any other character, delete the decoration
        let otherDecorations = decorations.filter(d => d.mark.type !== CAPITALIZED_WORD);
        editor.withoutSaving(() => {
          editor.setDecorations(otherDecorations);
        });
      }

      // We only capitalize after we hit a whitespace character.
      const charRegexp = new RegExp(/^\s$/);
      if (!charRegexp.test(evt.key)) {
        return next();
      }

      let wordRange = { start: offset, end: offset };

      // Grab the last word finished by the agent, including punctuation (except we only include up to the first
      // terminating character.)
      // Examples:
      //   "Start. Hi,"            => "Hi,"
      //   "Start. www.google.com" => "com"
      //   "Start. Great."         =>"Great."
      const wordRegexp = new RegExp(/[\S]/);
      const termRegexp = new RegExp(/[!?.]/);
      let hasMatchedTerminatingOnce = false;
      let i = offset - 1;
      for (; i >= 0; i--) {
        const char = lastText.text.charAt(i);
        if (termRegexp.test(char)) {
          if (hasMatchedTerminatingOnce) {
            break;
          }
          wordRange.start = i;
          hasMatchedTerminatingOnce = true;
        } else if (wordRegexp.test(char)) {
          wordRange.start = i;
        } else {
          break;
        }
      }

      const word = lastText.text.slice(wordRange.start, wordRange.end);
      if (!word) {
        return next();
      }

      // This matched word must either be at the start of the block or it must have a space in front of it.
      if (wordRange.start > 0 && !charRegexp.test(lastText.text.charAt(wordRange.start - 1))) {
        return next();
      }

      // Search for a terminating character. If we don't find one before a non-whitespace character, then this
      // isn't the first word in a sentence, so there's nothing to do.
      let shouldCapitalize = true;
      let encounteredTerminus = false;
      const terminusRegexp = new RegExp(/[.?!]/);
      const nonWhitespaceRegexp = new RegExp(/\S/);
      for (; i >= 0; i--) {
        const char = lastText.text.charAt(i);
        if (terminusRegexp.test(char)) {
          encounteredTerminus = true;
          break;
        } else if (nonWhitespaceRegexp.test(char)) {
          shouldCapitalize = false;
          break;
        }
      }
      if (!shouldCapitalize) {
        return next();
      }

      const previousSibling = value.document.getPreviousSibling(lastText.key);
      if (!encounteredTerminus && previousSibling && Inline.isInline(previousSibling)) {
        return next();
      }

      // Insert the text and _then_ change the capitalization. This way, an agent can hit undo if they didn't want that
      // letter capitalized.
      evt.preventDefault();
      editor.insertText(evt.key);

      const letterToCapitalizeSelection = selection.moveStartTo(wordRange.start).moveEndTo(wordRange.start + 1);
      const existingMarks = editor.value.document.getActiveMarksAtRange(letterToCapitalizeSelection);
      editor.insertTextAtRange(letterToCapitalizeSelection, _.capitalize(word.charAt(0)), existingMarks);

      let decoration = {
        anchor: { key: selection.start.key, offset: wordRange.start },
        focus: { key: selection.start.key, offset: wordRange.end },
        mark: { type: CAPITALIZED_WORD },
      };
      decorations = decorations.push(decoration);
      editor.setDecorations(decorations);

      return;
    },
  };
}
