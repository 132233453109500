import AgentProfile from 'models/agent_profile';
import createBlankError from '../factories/error/create_blank_error';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import createTooLongError from '../factories/error/create_too_long_error';
import Err from 'models/err';
import UserCredentialType from './user_credential_type';
import User from 'models/user';

const MINIMUM_PASSWORD_LENGTH = 8;

const TYPE_ATTRIBUTES = {
  [UserCredentialType.ACTIVATION_TOKEN]: ['name', 'password'],
  [UserCredentialType.PASSWORD]: ['username', 'password'],
  [UserCredentialType.RESET_PASSWORD_TOKEN]: ['password'],
};

const UserCredential = createModel({
  modelName: 'UserCredential',

  properties: {
    type: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
    getMinimumPasswordLength() {
      return MINIMUM_PASSWORD_LENGTH;
    },
    MAX_LENGTH: {
      username: User.MAX_LENGTH.username,
      name: AgentProfile.MAX_LENGTH.name,
    },
    PASSWORD_VALIDATION_ERROR_CODES: createEnum(
      'LOWERCASE_LETTER_REQUIRED',
      'UPPERCASE_LETTER_REQUIRED',
      'NUMBER_REQUIRED',
      'SPECIAL_CHARACTER_REQUIRED',
      'TOO_SHORT'
    ),
    PasswordResetErrorCodes: Object.freeze({
      PASSWORD_RECENTLY_USED: 'password_recently_used',
    }),
    getValidationErrors,
    trimAndValidatePassword,
    LoginErrorCodes: Object.freeze({
      PASSWORD_EXPIRED: 'password_expired',
    }),
  },
});

function getValidationErrors(type, props) {
  let errors = [];

  let rejectBlank = function(attrName) {
    if (!props[attrName]) {
      errors.push(createBlankError(attrName, attrName === 'username' ? 'email' : attrName));
    }
  };

  let rejectLong = function(field) {
    if (props[field] && props[field].length > UserCredential.MAX_LENGTH[field]) {
      errors.push(createTooLongError(field, `${UserCredential.MAX_LENGTH[field]} characters`));
    }
  };

  TYPE_ATTRIBUTES[type].forEach(function(field) {
    rejectBlank(field);
    if (type !== UserCredentialType.PASSWORD) {
      rejectLong(field);
    }
  });

  return errors;
}

export const INVALID_PASSWORD_CODE_DETAIL_MAPPING = {
  [UserCredential.PASSWORD_VALIDATION_ERROR_CODES
    .TOO_SHORT]: `At least ${UserCredential.getMinimumPasswordLength()} characters long`,
  [UserCredential.PASSWORD_VALIDATION_ERROR_CODES.LOWERCASE_LETTER_REQUIRED]: 'At least 1 lowercase character',
  [UserCredential.PASSWORD_VALIDATION_ERROR_CODES.UPPERCASE_LETTER_REQUIRED]: 'At least 1 uppercase character',
  [UserCredential.PASSWORD_VALIDATION_ERROR_CODES.NUMBER_REQUIRED]: 'At least 1 number',
  [UserCredential.PASSWORD_VALIDATION_ERROR_CODES.SPECIAL_CHARACTER_REQUIRED]: 'At least 1 special character',
};

const passwordValidators = [
  password => {
    return !/[a-z]/.test(password)
      ? {
          code: UserCredential.PASSWORD_VALIDATION_ERROR_CODES.LOWERCASE_LETTER_REQUIRED,
          detail:
            INVALID_PASSWORD_CODE_DETAIL_MAPPING[
              UserCredential.PASSWORD_VALIDATION_ERROR_CODES.LOWERCASE_LETTER_REQUIRED
            ],
        }
      : null;
  },
  password => {
    return !/[A-Z]/.test(password)
      ? {
          code: UserCredential.PASSWORD_VALIDATION_ERROR_CODES.UPPERCASE_LETTER_REQUIRED,
          detail:
            INVALID_PASSWORD_CODE_DETAIL_MAPPING[
              UserCredential.PASSWORD_VALIDATION_ERROR_CODES.UPPERCASE_LETTER_REQUIRED
            ],
        }
      : null;
  },
  password => {
    return !/[0-9]/.test(password)
      ? {
          code: UserCredential.PASSWORD_VALIDATION_ERROR_CODES.NUMBER_REQUIRED,
          detail: INVALID_PASSWORD_CODE_DETAIL_MAPPING[UserCredential.PASSWORD_VALIDATION_ERROR_CODES.NUMBER_REQUIRED],
        }
      : null;
  },
  password => {
    return !/[^A-Za-z0-9]/.test(password)
      ? {
          code: UserCredential.PASSWORD_VALIDATION_ERROR_CODES.SPECIAL_CHARACTER_REQUIRED,
          detail:
            INVALID_PASSWORD_CODE_DETAIL_MAPPING[
              UserCredential.PASSWORD_VALIDATION_ERROR_CODES.SPECIAL_CHARACTER_REQUIRED
            ],
        }
      : null;
  },
  password => {
    return password.length < UserCredential.getMinimumPasswordLength()
      ? {
          code: UserCredential.PASSWORD_VALIDATION_ERROR_CODES.TOO_SHORT,
          detail: INVALID_PASSWORD_CODE_DETAIL_MAPPING[UserCredential.PASSWORD_VALIDATION_ERROR_CODES.TOO_SHORT],
        }
      : null;
  },
];

function trimAndValidatePassword(password) {
  let validationErrors = [];
  let trimmedPassword = password.trim();
  passwordValidators.forEach(function(validate) {
    let error = validate(trimmedPassword);
    if (error) {
      validationErrors.push(
        new Err({
          code: error.code,
          attr: 'password',
          detail: error.detail,
        })
      );
    }
  });
  return validationErrors;
}

export default UserCredential;
