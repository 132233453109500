export default class TrackNotificationsBellClicked {
  constructor(context) {
    this.context = context;
  }

  run({ hasUnreadNotifications, unreadCount, totalCount }) {
    this.context.analytics.track('Agent Notifications Bell Clicked', {
      hasUnreadNotifications,
      unreadCount,
      totalCount,
    });
  }
}
