import TrackAnswerUsed from 'actions/knowledge_base/track_answer_used';

export default class TrackAnswerPanelCopy {
  constructor(context) {
    this.context = context;
  }

  run({ answer, channel, language }) {
    this.context.analytics.track('Answer Menu - Copy', { answerId: answer.id, channel });
    this.context.executeAction(TrackAnswerUsed, { answer, channel, language, method: 'COPIED' });
  }
}
