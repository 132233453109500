import _ from 'lodash';

import ConversationItemType from 'models/conversation_item_type';
import CreateComposition from './lib/create_composition';
import Err from 'models/err';
import FbMessageCompositionContent from 'models/composition/fb_message_composition_content';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

class CreateFbMessageComposition extends CreateComposition {
  constructor(context) {
    super(context, FbMessageCompositionContent);
  }
  _createContent({ conversationId }) {
    let lastFbMessageItem = this._getLatestManualItemOfType({
      type: ConversationItemType.FB_MESSAGE_INCOMING,
      conversationId,
    });

    if (lastFbMessageItem) {
      return new FbMessageCompositionContent({});
    }

    let lastFbMessangerUserId = _.last(this.customerStores.profile.get().fbMessengerUserIds);
    if (lastFbMessangerUserId) {
      return new FbMessageCompositionContent({});
    }

    let err = [
      new Err({
        code: Err.Code.INVALID_STATE,
        detail: 'Customer profile does not have any facebook messenger user ids',
      }),
    ];
    getCompositionsStore(this.context).setErrorsForNew(err);
    return;
  }

  _canCreateComposition() {
    return this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
  }
}

export default CreateFbMessageComposition;
