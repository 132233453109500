import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';

export default class ToggleTaskFormattingVisible {
  constructor(context) {
    this.context = context;
  }

  run() {
    const preferences = this.context.stores.agentPreferences.get();
    preferences.toggleIsTaskCommentFormattingOpen();
    setAgentPreferences(this.context, preferences);
  }
}
