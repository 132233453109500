import createModel, { prop } from 'models/lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import NotificationType from './notification_type';

export default createModel({
  modelName: 'FocusModeDisabledNotification',

  properties: {
    id: prop(String).isRequired,
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.FOCUS_MODE_DISABLED });
  },

  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), ...attrs });
    },
  },
});
