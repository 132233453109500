import InboxView from './lib/inbox_view';

export default class LeaveInbox {
  constructor(context) {
    this.inboxView = new InboxView(context);
  }

  run() {
    this.inboxView.leave();
  }
}
