const FONT_TAG_SIZE_MAP = {
  '1': 'x-small',
  '2': 'small',
  '3': 'medium',
  '4': 'large',
  '5': 'x-large',
  '6': 'xx-large',
  '7': 'xxx-large',
  '+1': 'large',
  '+2': 'x-large',
  '-1': 'small',
  '-2': 'x-small',
};

export const DEFAULT_FONT_SIZE = 'medium';

/**
 * Attempts to translate the font size from the units used by the `<font>` tag to
 * the CSS `font-size` units. Returns the original value if it cannot be translated
 *
 * @param {string} size - the value of the <font> tag `size` attribute
 * @return {string}
 */
export function translateFontTagSize(size) {
  return FONT_TAG_SIZE_MAP[size] || size;
}
