import _ from 'lodash';
import React from 'react';

export default function getHighlightedText(text, highlightClassName) {
  if (!text) {
    return null;
  }

  const ranges = getHighlightRanges(text);
  let textChunks = [];
  let cursor = 0;

  _.forEach(ranges, (highlight, index) => {
    textChunks.push(<span key={`item-text-${index}`}>{text.slice(cursor, highlight.start)}</span>);
    textChunks.push(
      <span className={highlightClassName} key={`item-highlight-${index}`}>
        {highlight.content}
      </span>
    );
    cursor = highlight.end;
  });

  if (cursor < text.length) {
    textChunks.push(<span key={'item-text-trailing'}>{text.slice(cursor, text.length)}</span>);
  }

  return textChunks;
}

function getHighlightRanges(text) {
  const matches = findHighlightMatches(text);
  return matches.map(match => {
    return { start: match.index, end: match.index + match[0].length, content: match[1] };
  });
}

const HIGHLIGHT_REGEX = /<mark class='highlightedSearchTerm'>(.+?)<\/mark>/;

export function findHighlightMatches(text) {
  if (text === '') {
    return [];
  }

  let highlightRegex = new RegExp(HIGHLIGHT_REGEX, 'g');
  let match = highlightRegex.exec(text);
  let matches = [];

  while (match !== null) {
    matches.push(match);
    match = highlightRegex.exec(text);
  }
  return matches;
}

const HIGHLIGHT_START_REGEX = /<mark class='highlightedSearchTerm'>/g;
const HIGHLIGHT_END_REGEX = /<\/mark>/g;

export function stripHighlighting(text) {
  let stripped = text.replace(HIGHLIGHT_START_REGEX, '');
  return stripped.replace(HIGHLIGHT_END_REGEX, '');
}
