import remove from 'lodash/remove';
import find from 'lodash/find';
import set from 'lodash/set';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class ComplianceService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this._getDatabase = database;
  }

  removeCustomerData(orgId, customerId) {
    remove(this._getDatabase(orgId).customers, { id: customerId });

    this._pubsub.publish(`v1/orgs/${orgId}/compliance/customers/${customerId}/event/delete`, {});
  }

  deleteCustomer(payload, callback, path, { orgId, customerId }) {
    this.removeCustomerData(orgId, customerId);
    callback(null, { status: 204, statusText: statusText(204) });
  }

  redactConversationItemData(orgId, customerId, itemId) {
    const history = find(this._getDatabase(orgId).customers, { id: customerId }).conversationHistory;
    const item = find(history, { id: itemId });

    // write defined text for different kinds of message
    const text = '... text removed';
    item.content.body = text;
    item.content.text = text;
    item.content.bodyPlain = text;
    set(item, 'content.message.text', text);

    this._pubsub.publish(`v1/orgs/${orgId}/compliance/customers/${customerId}/items/:${itemId}/event/delete`, {});
  }

  redactConversationItem(payload, callback, path, { orgId, customerId, itemId }) {
    this.redactConversationItemData(orgId, customerId, itemId);
    callback(null, { status: 200, statusText: statusText(200) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/compliance/customers/:customerId': {
          DELETE: this.deleteCustomer,
        },
        '/api/v1/orgs/:orgId/compliance/customers/:customerId/items/:itemId': {
          PATCH: this.redactConversationItem,
        },
      },
      this
    );
  }
}
