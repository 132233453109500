import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { StyledIconAPI } from '../metadata/api_metadata';
import CheckmarkCircle from 'components/common/icons/checkmark_circle';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import './interaction_strings'; // interaction names
import ItemContent from '../item_content';
import Metadata from 'components/customer/conversation_history/conversation_items_v2/metadata/metadata';
import RoutingItemModel from 'models/routing_item';
import RoutingItemType from 'models/routing_item_type';
import './routing_item_strings'; // text templates for all routing phrases
import RuleLink from 'components/customer/conversation_history/conversation_items_v2/metadata/rule_link';
import Tooltip from 'components/common/tooltip';
import useWindowSize from 'components/hooks/use_window_size';
import InteractionType, { baseInteractionType, customId } from 'models/interaction_type';

export default function RoutingItem({ item }) {
  return (
    <ItemContent className="routingItem" isAuditItem isInbound={false} item={item}>
      <OptionalTooltip item={item}>
        <Metadata
          icon={getIcon(item)}
          iconClass={getIconClasses(item)}
          isInbound={false}
          prepositionalPhrases={<RoutingPhraseContainer content={item.content} initiator={item.initiator} />}
          subject=""
          text=""
          timestamp={item.timestamp}
        />
      </OptionalTooltip>
    </ItemContent>
  );
}

RoutingItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function OptionalTooltip({ children, item }) {
  const { t } = useTranslation();
  const { windowWidth } = useWindowSize();

  const message = getMessage();
  if (message) {
    const tooltipProps = { bounds: { right: windowWidth - 8 }, position: 'bottom', message };

    return <Tooltip {...tooltipProps}>{children}</Tooltip>;
  } else {
    return children;
  }

  function getMessage() {
    switch (item.content.itemType) {
      case RoutingItemType.SMART_MATCH: {
        let rules = _(item.content.detail.ruleScores)
          .orderBy(['value', item => item.name.toLowerCase()], ['desc', 'asc'])
          .map('name')
          .value()
          .join(', ');

        return t(`routingItemTooltip.${RoutingItemType.SMART_MATCH}`, { rules });
      }
      default:
        return undefined;
    }
  }
}

OptionalTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function getIcon(item) {
  if (item.initiator.type === Communicator.API) {
    return <StyledIconAPI className="api-icon">APP</StyledIconAPI>;
  }

  if (item.content.itemType === RoutingItemType.SMART_MATCH) {
    return <StyledCheckmarkCircle />;
  }

  return null;
}

function getIconClasses(item) {
  switch (item.content.itemType) {
    case RoutingItemType.BLIND_TRANSFER:
      return 'icon-phone-filled';
    default:
      return 'fa fa-exchange';
  }
}

const RoutingPhraseContainer = connect(mapRoutingPhraseStateToProps)(RoutingPhrase);

function mapRoutingPhraseStateToProps({ getProvider }, { content, initiator }) {
  let agents = getProvider('agents');
  let customChannels = getProvider('customChannels');
  let routingGroups = getProvider('routingGroups');

  let names = {};
  let agent = agents.findBy({ id: _.get(content, 'assignee.agentId') });
  if (agent) {
    names[agent.id] = nameFromAgent(agent);
  }

  const interactionType = content.detail?.channel;
  if (baseInteractionType(interactionType) === InteractionType.CUSTOM_CHANNEL) {
    names[interactionType] = customChannels.findBy({ id: customId(interactionType) })?.name;
  }

  let routingGroup = routingGroups.findBy({ id: _.get(content, 'assignee.routingGroupId') });
  if (routingGroup) {
    names[routingGroup.id] = routingGroup.name;
  }

  if (initiator.type === Communicator.AGENT) {
    let initiatorAgent = agents.findBy({ id: initiator.id });
    if (initiatorAgent) {
      names[initiator.id] = nameFromAgent(initiatorAgent);
    }
  }

  let priorAgent = agents.findBy({ id: _.get(content, 'assigneeWas.agentId') });
  if (priorAgent) {
    names[priorAgent.id] = nameFromAgent(priorAgent);
  }

  let priorRoutingGroup = routingGroups.findBy({ id: _.get(content, 'assigneeWas.routingGroupId') });
  if (priorRoutingGroup) {
    names[priorRoutingGroup.id] = priorRoutingGroup.name;
  }

  const currentAgentId = getProvider('currentAgent').get().id;

  return { currentAgentId, names };

  function nameFromAgent(agent) {
    return agent.name || agent.email;
  }
}

function RoutingPhrase({ content, currentAgentId, initiator, names }) {
  const { i18n, t } = useTranslation();

  const { agentId, routingGroupId } = _.get(content, 'assignee', {});
  const prior = _.omitBy(
    _.get(content, 'assigneeWas', {}),
    (v, k) => k === 'routingGroupId' && v === 'MESSAGE_AUTOMATION'
  );

  return (
    <span className="routingPhrase">
      <Trans
        components={{
          agent: <Highlight />,
          inbox: <Highlight />,
          help: <StyledHelpLink path={getHelpPath()} />,
          rule: <StyledRuleLink ruleId={initiator.id} />,
        }}
        defaults="Routed"
        i18nKey={buildKey('routingItem', content.itemType, getTo(), getFrom(), getInitiator())}
        values={{
          // default to ... to avoid rendering of tags,
          // e.g. "Routed to <agent></agent>";
          // both indicate an issue but the tags just look bad.
          agent: names[agentId] || '...',
          inbox: names[routingGroupId] || '...',
          initiator: names[initiator.id] || '...',
          interactionChannel: getInteraction('interactionChannel'),
          interactionMessage: getInteraction('interactionMessage'),
          priorAgent: names[prior.agentId] || '...',
          priorInbox: names[prior.routingGroupId] || '...',
        }}
      />
    </span>
  );

  // Iterates over parts appending each part separated by the ".",
  // e.g. routingItem.INBOUND.toYou.fromInbox.
  // If the key at any level doesn't exist the "_DEFAULT" is used instead,
  // e.g. routingItem._DEFAULT if the first part doesn't exist,
  // routingItem.INBOUND._DEFAULT if the second part doesn't exist, etc.
  // If the _DEFAULT key doesn't exist the function returns the key at the
  // last level where the key existed, e.g. routingItem.INBOUND.
  function buildKey(root, ...parts) {
    let key = root;

    for (let i = 0; i < parts.length; i++) {
      const nextKey = `${key}.${parts[i]}`;
      const defaultKey = `${key}._DEFAULT`;

      if (i18n.exists(nextKey)) {
        key = nextKey;
      } else if (i18n.exists(defaultKey)) {
        key = defaultKey;
      } else {
        return key;
      }
    }

    return key;
  }

  function getFrom() {
    if (_.isEmpty(prior.routingGroupId)) {
      return undefined;
    }

    switch (prior.agentId) {
      case undefined:
      case '':
      case agentId: // assume routing between inboxes because agent id didn't change
        return 'fromInbox';
      case currentAgentId:
        return 'fromYou';
      default:
        return 'fromAgent';
    }
  }

  function getHelpPath() {
    if (content.itemType === RoutingItemType.CALL_DEVICE_ERROR) {
      return 'browser-connection-failed/';
    }

    return null;
  }

  function getInitiator() {
    switch (initiator.type) {
      case Communicator.AGENT:
        return initiator.id === currentAgentId ? 'byYou' : 'byAgent';
      case Communicator.API:
        return 'byApp';
      case Communicator.AUTOMATED:
        return 'byRule';
    }

    return undefined;
  }

  function getInteraction(root) {
    const interactionType = content.detail?.channel;
    const baseType = baseInteractionType(interactionType);
    const channelName = names[interactionType];

    if (baseType === InteractionType.CUSTOM_CHANNEL && !channelName) {
      return t(`${root}._DEFAULT`);
    }

    return t(buildKey(root, baseType || '_DEFAULT'), { channelName });
  }

  function getTo() {
    switch (agentId) {
      case undefined:
      case '':
      case prior.agentId: // assume routing between inboxes because agent id didn't change
        return 'toInbox';
      case currentAgentId:
        return 'toYou';
      default:
        return 'toAgent';
    }
  }
}

RoutingPhrase.propTypes = {
  content: PropTypes.instanceOf(RoutingItemModel).isRequired,
  initiator: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(Communicator)).isRequired,
  }).isRequired,
  currentAgentId: PropTypes.string.isRequired,
  names: PropTypes.objectOf(PropTypes.string).isRequired,
};

function HelpLink({ children, className, path }) {
  if (!path) {
    return children;
  }

  return (
    <a
      className={className}
      data-aid="helpLink"
      href={`https://connect.gladly.com/docs/help-documentation/article/${path}`}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
}

HelpLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  path: PropTypes.string,
};

const Highlight = styled.span.attrs(() => ({ 'data-aid': 'highlight' }))`
  font-weight: bold;
`;

const StyledCheckmarkCircle = styled(CheckmarkCircle)`
  height: 16px;
  margin-right: 4px;
  vertical-align: text-top;
  width: 16px;
`;

const StyledHelpLink = styled(HelpLink)`
  font-weight: bold;
`;

const StyledRuleLink = styled(RuleLink)`
  font-weight: bold;
`;
