import _ from 'lodash';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import ActionMixin from 'components/lib/action_mixin';
import NavigateToUrl from 'actions/current_location/navigate_to_url';

const Link = createReactClass({
  propTypes: {
    className: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object, PropTypes.number]),
  },

  mixins: [ActionMixin],

  handleOnClick(ev) {
    let rightClick = ev.button === 2;

    // If the user was not trying to open a new tab or use the right click menu call the handler (if exists) and
    // preventDefault
    if (!(ev.metaKey || ev.ctrlKey) && !rightClick) {
      ev.preventDefault();

      if (this.props.onClick && typeof this.props.onClick === 'function') {
        this.props.onClick.apply(null, arguments);
      } else {
        this.executeAction(NavigateToUrl, this.props.href);
      }
    }
  },

  render() {
    return (
      <a onClick={this.handleOnClick} {..._.omit(this.props, 'onClick')}>
        {this.props.children}
      </a>
    );
  },
});

export default Link;
