import _ from 'lodash';

import qconsole from 'scripts/lib/qconsole';
import TaskComment from 'models/task_comment';
import TaskFollower from 'models/task_follower';
import TaskInfo from 'models/task_info';

export default class FetchCommentsAndFollowersForTask {
  constructor(context) {
    this.context = context;
  }

  run({ taskId }) {
    if (!taskId) {
      throw new Error('FetchCommentsAndFollowersForTask must be called with a task id');
    }

    const taskInfoStore = this.context.stores.taskInfo;
    let taskInfo = taskInfoStore.findBy({ id: taskId });
    if (!taskInfo) taskInfo = TaskInfo.create({ id: taskId });
    taskInfo.setCommentsLoading();
    taskInfo.setFollowersLoading();
    taskInfoStore.addOrReplace(taskInfo);

    this.context.gateways.taskComments
      .requestAll({ taskId })
      .then(commentDtos => {
        // don't `.set` because that would blow away other comments
        let taskInfo = taskInfoStore.findBy({ id: taskId });
        _.forEach(commentDtos, c => taskInfo.addComment(TaskComment.fromJs(c)));
        taskInfoStore.addOrReplace(taskInfo);
      })
      .catch(err => qconsole.error(`Unable to fetch task comments: ${JSON.stringify(err)}`))
      .finally(() => {
        let taskInfo = taskInfoStore.findBy({ id: taskId });
        taskInfo.resetCommentsLoading();
        taskInfoStore.addOrReplace(taskInfo);
      });

    this.context.gateways.taskFollowers
      .requestAll({ taskId })
      .then(followerDtos => {
        // don't `.set` because that would blow away other followers
        let taskInfo = taskInfoStore.findBy({ id: taskId });
        _.forEach(followerDtos, f => taskInfo.addFollower(TaskFollower.fromJs(f)));
        taskInfoStore.addOrReplace(taskInfo);
      })
      .catch(err => qconsole.error(`Unable to fetch task followers: ${JSON.stringify(err)}`))
      .finally(() => {
        let taskInfo = taskInfoStore.findBy({ id: taskId });
        taskInfo.resetFollowersLoading();
        taskInfoStore.addOrReplace(taskInfo);
      });
  }
}
