import IdGenerator from 'scripts/domain/contracts/id_generator';
import Err from 'models/err';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import qconsole from 'scripts/lib/qconsole';

const DEFAULT_TIMEOUT = 30000; // 30s

export default class ExternalCustomerLookupHttpGateway {
  constructor(http, requestorId) {
    this.http = http;
    this.requestorId = requestorId;
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  get entityName() {
    return 'external-customer-lookup';
  }

  url(customerId) {
    return `/api/v2/orgs/${this.orgId}/customers/${customerId}/${this.entityName}/integrations`;
  }

  urlWithIntegration(customerId, integrationId) {
    return `/api/v2/orgs/${this.orgId}/customers/${customerId}/${this.entityName}/integrations/${integrationId}`;
  }

  request({ customerId, integrationId }, payload) {
    const correlationId = IdGenerator.newId();
    const url = integrationId ? this.urlWithIntegration(customerId, integrationId) : this.url(customerId);

    this.http.post(url, payload, this.handleResponse.bind(this, correlationId), {
      timeout: DEFAULT_TIMEOUT,
      headers: { 'Gladly-Correlation-Id': correlationId, 'Gladly-Requestor-Id': this.requestorId },
    });

    return { correlationId };
  }

  handleResponse(correlationId, err, res) {
    if (err != null) {
      qconsole.error('error during external customer lookup: ', err);

      this.notifyObservers('handleFetchError', {
        errorsDto: { errors: [{ code: 'unexpected_error' }] },
        correlationId,
      });
    } else if (res.status !== 200) {
      this.notifyObservers('handleFetchError', {
        errorsDto: errors(res.response),
        correlationId,
      });
    } else {
      this.notifyObservers('handleFetchSuccess', {
        fetchResultDto: res.response,
        correlationId,
      });
    }
  }
}

function errors(response) {
  if (response && response.errors) {
    return response;
  } else {
    qconsole.error('error response from external customer lookup: ', response);
    return { errors: [{ detail: 'error during customer lookup', code: Err.Code.UNEXPECTED_ERROR }] };
  }
}

mixin(ExternalCustomerLookupHttpGateway.prototype, Observable);
