import includes from 'lodash/includes';

import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';

export function getListDepth(editor, node) {
  let count = 0;
  let currentNode = node;
  while (currentNode !== editor.value.document) {
    const nextParent = editor.value.document.getParent(currentNode.key);
    if (isListNode(nextParent)) {
      count++;
    }
    currentNode = nextParent;
  }
  return count;
}

export function reduceListDepth(editor) {
  const { value } = editor;

  value.blocks
    .filter(block => block.type === LIST_ITEM)
    .forEach(block => {
      const depth = getListDepth(editor, block);
      const listParent = value.document.getClosest(
        block.key,
        parent => parent.type === UNORDERED_LIST || parent.type === ORDERED_LIST
      );
      if (!listParent || depth < 1) {
        return;
      }
      editor.withoutNormalizing(() => {
        editor.unwrapBlockByKey(block.key).focus();
      });
    });
}

export function isListNode(node) {
  return includes([UNORDERED_LIST, ORDERED_LIST], node.type);
}

export function hasText(node) {
  const textNode = node.getFirstText();
  return textNode && !!textNode.text;
}
