import _ from 'lodash';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';
import PropTypes from 'prop-types';

import DateRangeFilter from 'components/lib/date_range_filter';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';

const DateCreatedFilterContainer = createReactClass({
  mixins: [Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  propTypes: {
    onChange: PropTypes.func,
    queryId: PropTypes.string,
  },

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('universalSearchResults'));
  },

  render() {
    let props = {};
    let result = this.getProvider('universalSearchResults').findBy({ id: this.props.queryId });
    let createdAt = _.get(result, 'query.filter.createdAt');
    if (createdAt) {
      props.startDate = createdAt.gte;
      props.endDate = createdAt.lte;
    }

    return (
      <DateRangeFilter
        {...props}
        className="universalSearch-filter-dateCreated"
        isDateTimePicker
        onApply={this.onApply}
        onClear={this.onClear}
        position="right"
        renderPlaceholder={this.renderPlaceholder}
      />
    );
  },

  renderPlaceholder() {
    return <div className="universalSearch-filter-dateCreated-placeholder">Created</div>;
  },

  onApply({ startDate, endDate }) {
    if (this.props.onChange) {
      this.props.onChange({ createdAt: { gte: startDate, lte: endDate } });
    }
  },

  onClear() {
    if (this.props.onChange) {
      this.props.onChange({ createdAt: null });
    }
  },
});

export default DateCreatedFilterContainer;
