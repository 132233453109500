import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import connect from 'components/lib/connect';
import DismissNotification from 'actions/notification/dismiss_notification';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import { PhoneCallObserverNotificationRecipientType } from 'models/notification/phone_call_observer_notification';

export function PhoneCallObserverNotification({ notificationId, message, recipient, onDismiss }) {
  let recipientClass = recipient === PhoneCallObserverNotificationRecipientType.OBSERVER ? 'observer' : 'agent';
  let classes = classnames('notification', 'observerAddedToCall-notification', recipientClass);

  return (
    <div className="notificationContainer">
      <div className={classes} notificationId={notificationId}>
        <div className="observerAddedToCall-notification-message" onClick={onDismiss}>
          {message}
        </div>
        <div
          className="observerAddedToCall-notification-close"
          data-aid="observerAddedToCall-notification-close"
          onClick={onDismiss}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
}

PhoneCallObserverNotification.propTypes = {
  notificationId: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  recipient: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapExecuteToProps)(PhoneCallObserverNotification);

function mapStateToProps(context, props) {
  let notification = props.notification;
  return {
    notificationId: get(notification, 'id'),
    message: get(notification, 'message'),
    recipient: get(notification, 'recipient'),
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onDismiss: () => executeAction(DismissNotification, props.notification.id),
  };
}
