import createEnum from 'scripts/lib/create_enum';

const CompositionContentType = createEnum(
  'CHAT',
  'COBROWSE_WIDGET',
  'CONVERSATION_MESSAGE',
  'CONVERSATION_NOTE',
  'CONVERSATION',
  'CREDIT_CARD_REQUEST',
  'CUSTOM_CHANNEL',
  'EMAIL',
  'EXTERNAL_FORM',
  'FB_MESSAGE',
  'PHONE_CALL',
  'PHONE_CALLBACK',
  'SMS',
  'TASK_EDIT',
  'TASK',
  'TWITTER'
);

export default CompositionContentType;
