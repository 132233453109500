import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Checklist from 'components/lib/checklist';
import Err from 'models/err';
import UserCredential, { INVALID_PASSWORD_CODE_DETAIL_MAPPING } from 'models/user_credential';

const PasswordHints = ({ className, errors, password, hasDifferentPasswordRequirement }) => {
  let options = _.map(UserCredential.PASSWORD_VALIDATION_ERROR_CODES, errorCode => {
    return {
      label: INVALID_PASSWORD_CODE_DETAIL_MAPPING[errorCode],
      value: errorCode,
      isSelected: (password && !errors.find(err => err.code === errorCode)) || false,
    };
  });

  return (
    <div className={classnames('passwordHints', className)}>
      <h1 className="passwordHints-heading">Password Requirements</h1>
      <div className="passwordHints-checkList">
        <Checklist options={options} readOnly />
        {renderDifferentPasswordRequirement()}
      </div>
    </div>
  );

  function renderDifferentPasswordRequirement() {
    if (!hasDifferentPasswordRequirement) {
      return null;
    }

    const hasDiffError = !!_.find(errors, err => {
      return err.code === UserCredential.PasswordResetErrorCodes.PASSWORD_RECENTLY_USED;
    });

    const classNames = classnames('passwordHints-differentPassword', {
      'passwordHints-differentPassword-error': hasDiffError,
    });

    return (
      <div className={classNames}>
        <div className="passwordHints-differentPassword-toggle" />
        <div className="checklist-option-label">Different from your last 4 passwords</div>
      </div>
    );
  }
};

PasswordHints.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
  password: PropTypes.string.isRequired,
  hasDifferentPasswordRequirement: PropTypes.bool,
};

export default PasswordHints;
