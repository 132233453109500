import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M7.939 0a3 3 0 00-3 3v.292l5.796 5.796c.132-.337.204-.704.204-1.088V3a3 3 0 00-3-3zm-3 5.853V8a3 3 0 002.5 2.959v.786A3.94 3.94 0 014 7.838V4.914l.939.939zm-1.5-1.5H3v3.485a4.94 4.94 0 004.439 4.913V14.5h-2.5a.5.5 0 000 1h6a.5.5 0 100-1h-2.5v-1.749a4.913 4.913 0 002.447-.95l2.72 2.719a.5.5 0 00.707-.707L1.853 1.353a.5.5 0 10-.707.708L3.44 4.353zm6.73 6.731a3.917 3.917 0 01-1.73.661v-.786c.42-.071.81-.229 1.152-.454l.579.58zm1.949-.613l-.73-.73a3.92 3.92 0 00.49-1.903V4.353h1v3.485c0 .968-.279 1.87-.76 2.633z"
      fillRule="evenodd"
    />
  </Icon>
);
