import React from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty } from 'lodash';

import connect from 'components/lib/connect';

export const DEFAULT_AI_ACTIVITY_COMPOSER_CONTEXT = {
  requireReadonly: false,
  preventSubmission: false,
};

export function AIActivityComposerContextProviderBase({ children, requireReadonly, preventSubmission }) {
  const value = {
    requireReadonly,
    preventSubmission,
  };
  return <AIActivityComposerContext.Provider value={value}>{children}</AIActivityComposerContext.Provider>;
}

AIActivityComposerContextProviderBase.propTypes = {
  children: PropTypes.any,
  requireReadonly: PropTypes.bool,
  preventSubmission: PropTypes.bool,
};

function mapStateToProps({ getProvider }, { compositionId }) {
  if (!compositionId) return DEFAULT_AI_ACTIVITY_COMPOSER_CONTEXT;

  const provider = getProvider('aiTextCompletions');
  const currentLoadingKeys = provider?.getLoadingStateKeys() || [];
  if (isEmpty(currentLoadingKeys)) return DEFAULT_AI_ACTIVITY_COMPOSER_CONTEXT;

  const needReadonly = find(currentLoadingKeys, key => key?.compositionId === compositionId && key?.preventUserInput);
  const needPreventSubmission = find(
    currentLoadingKeys,
    key => key?.compositionId === compositionId && key?.preventSubmit
  );
  return {
    requireReadonly: !!needReadonly,
    preventSubmission: !!needPreventSubmission,
  };
}

export const AIActivityComposerContextProvider = connect(mapStateToProps)(AIActivityComposerContextProviderBase);

AIActivityComposerContextProvider.propTypes = {
  children: PropTypes.any,
  compositionId: PropTypes.string.isRequired,
};

const AIActivityComposerContext = React.createContext(DEFAULT_AI_ACTIVITY_COMPOSER_CONTEXT);
export default AIActivityComposerContext;
