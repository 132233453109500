import { faCircle as faCircleFull } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import CustomerDetails from 'components/sidebar/inbox_customer_details';
import InboxItemAvatar from './inbox_item_avatar';
import NavigateToInboxItem from 'actions/inbox/agent/navigate_to_inbox_item';
import { PortalledPopover, PopoverArrow } from 'components/common/lib/_popover';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useWindowSize from 'components/hooks/use_window_size';

export default function CollapsedInboxItem({ collapsed, currentAgentId, index, item, needsAttention, selectedItemId }) {
  const executeAction = useExecuteAction();
  const navigateToCustomer = () => {
    executeAction(NavigateToInboxItem, { item, collapsed, index, needsAttention });
  };

  const [isVisible, setVisible] = useState(false);
  const [opener, setOpener] = useState(null);
  const [onMouseOver, onMouseLeave] = useMouseHover(setVisible);
  const { windowHeight } = useWindowSize();

  return (
    <Item
      data-aid="customerList-item"
      data-customer-id={item.id}
      isCurrent={item.id === selectedItemId}
      onClick={navigateToCustomer}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      ref={node => setOpener(node)}
    >
      <NeedsAttention isCurrent={item.id === selectedItemId} needsAttention={needsAttention} />
      <InboxItemAvatar collapsed item={item} />
      <StyledPopoverCard
        bounds={{ bottom: windowHeight - 8, top: 8 }}
        isVisible={isVisible}
        position="right"
        targetElement={opener}
      >
        <StyledCustomerDetails collapsed currentAgentId={currentAgentId} item={item} selectedItemId={selectedItemId} />
      </StyledPopoverCard>
    </Item>
  );
}

function useMouseHover(setVisible) {
  const closeTimeout = useRef(null);

  const onMouseOver = useCallback(() => {
    clearTimeout(closeTimeout.current);
    setVisible(true);
  }, [setVisible]);

  const onMouseLeave = useCallback(() => {
    closeTimeout.current = setTimeout(() => {
      setVisible(false);
    }, 100);
  }, [setVisible]);

  useEffect(() => {
    return () => {
      clearTimeout(closeTimeout.current);
    };
  }, []);

  return [onMouseOver, onMouseLeave];
}

const Item = styled.div`
  ${p => (p.isCurrent ? `background-color: ${p.theme.colors.green400};` : '')}
  border-radius: 4px;
  ${p => (p.isCurrent ? `color: ${p.theme.colors.white};` : '')}
  cursor: pointer;
  display: flex;
  margin: 4px 2px;
  padding: 8px 0px;

  &:hover {
    background-color: ${p => (p.isCurrent ? p.theme.colors.green400 : p.theme.colors.gray100)};
  }
`;

const StyledCustomerDetails = styled(CustomerDetails)`
  width: 275px;
`;

const StyledPopoverCard = styled(PortalledPopover)`
  background-color: ${p => p.theme.colors.white};
  border-radius: 6px;
  box-shadow: ${p => p.theme.boxShadow.large};
  padding: 16px;
  width: 275px;

  &[data-position='right'] {
    ${PopoverArrow} {
      border-right-color: ${p => p.theme.colors.white};
    }
  }
`;

function NeedsAttention({ needsAttention, isCurrent }) {
  return (
    <NeedsAttentionContainer isCurrent={isCurrent}>
      {needsAttention ? <FontAwesomeIcon data-aid="sidebarInboxItem-needsAttention" icon={faCircleFull} /> : null}
    </NeedsAttentionContainer>
  );
}

const NeedsAttentionContainer = styled.div`
  align-items: center;
  color: ${p => (p.isCurrent ? p.theme.colors.white : p.theme.colors.green400)};
  display: flex;
  flex-shrink: 0;
  font-size: 10px;
  justify-content: center;
  margin: 0px 4px;
  width: 10px;
`;
