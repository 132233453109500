import _ from 'lodash';

import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import { reconcileInboxItem } from 'actions/inbox/agent/agent_inbox_helpers';

/**
 * tryUpdateInboxItem() will, if a partial inbox item already exists for a customer, try to
 * update the relevant parts of the inbox item (conversation, item, profile).
 */
export default function tryUpdateInboxItem(context, { conversation, item, profile }) {
  let customerId = profile ? profile.id : item ? item.customerId : conversation.customerId;
  if (!customerId) {
    return;
  }

  // We _must_ have already made a placeholder inbox item for this customer, otherwise we will
  // end up creating an inbox item for every customer we load.
  // We generally create these placeholder items in response to routing events for newly assigned
  // conversations / tasks / phone calls / active sessions, in their respective observers.
  _.forEach([context.stores.agentInboxItems, context.stores.agentInboxItemsNew], store => {
    const existingItem = store.findBy({ id: customerId });
    if (!existingItem) {
      return;
    }

    const newInboxItem = new AgentInboxItem({
      conversation,
      id: customerId,
      item,
      profile,
      type: AgentInboxItem.Type.CUSTOMER,
    });
    reconcileInboxItem(context, newInboxItem, store);
  });
}
