import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Setting "allowSameOrigin" to true will allow the code within iframe access to the host storage and cookies,
 * which substantially weakens the sandbox. We allow this for ScreenMeet because the widget does not work
 * otherwise. Normally, it should be set to false.
 */
const WidgetHostFrame = React.forwardRef((props, iframeRef) => {
  const sameOrigin = props.allowSameOrigin ? 'allow-same-origin' : '';
  const allowForms = props.allowForms ? 'allow-forms' : '';
  const sandbox = `allow-scripts allow-popups ${sameOrigin} ${allowForms}`.trim();
  const title = props.frameTitle || 'CoBrowse Widget Host Frame';
  const frameId = 'cobrowse-host-frame';

  return (
    <StyledFrame
      allow={props.permissions}
      data-aid={props.dataAid || 'cobrowse-host-frame'}
      id={frameId}
      name={frameId}
      onLoad={props.onLoad}
      ref={iframeRef}
      referrerPolicy="origin"
      sandbox={sandbox}
      scrolling={props.allowScrolling ? 'auto' : 'no'}
      src={props.sourceUrl}
      title={title}
    />
  );
});

const StyledFrame = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`;

WidgetHostFrame.propTypes = {
  allowForms: PropTypes.bool,
  allowSameOrigin: PropTypes.bool.isRequired,
  allowScrolling: PropTypes.bool,
  dataAid: PropTypes.string,
  frameTitle: PropTypes.string,
  sourceUrl: PropTypes.string.isRequired,
  permissions: PropTypes.string,
  onLoad: PropTypes.func,
};

export default WidgetHostFrame;
