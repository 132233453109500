export default class MarkAllAsRead {
  constructor(context) {
    this.context = context;
  }

  run() {
    let numMarkedAsRead = 0;
    const notifications = this.context.stores.agentNotifications.findAll();
    notifications.forEach(notification => {
      if (!notification.hasRead()) {
        numMarkedAsRead++;
        notification.markAsRead();
        this.context.stores.agentNotifications.addOrReplace(notification);
        this.context.gateways.agentNotification.update({ notificationId: notification.id }, notification.toJs());
      }
    });

    this.context.analytics.track('Agent Notifications - Mark All As Read Clicked', {
      numberMarkedAsRead: numMarkedAsRead,
    });
  }
}
