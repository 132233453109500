import moment from 'moment';
import _ from 'lodash';

import { findReportConfigBySlug } from 'models/reports/report';
import { getAllReportConfigs } from 'scripts/application/actions/reporting/lib/reporting_helpers';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import { getChannelFilterValue } from 'models/reports/report_channel';
import { NotExistError, TooManyRequestsError } from 'scripts/application/lib/error';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class DownloadCsv {
  constructor(context) {
    this.context = context;
  }

  run(reportParams) {
    const csvApiAggregation = reportParams.aggregationLevel;
    const oldReport = this.context.stores.currentLocation.get();
    const newReport = oldReport.deriveNew(reportParams);

    const allReportConfigs = getAllReportConfigs(this.context);
    const config = findReportConfigBySlug(allReportConfigs, newReport.getSlug());

    let aggregationLevel = newReport.getValidAggregationLevel(config.timeAggregation);
    newReport.setAggregationLevel(aggregationLevel);

    const csvApiParams = getCsvApiParams(newReport, config, csvApiAggregation);

    this.context.gateways.reportingApi.download(csvApiParams).catch(err => this.handleDownloadError(err));
    this.context.analytics.track({
      event: 'Report Requested',
      props: {
        slug: newReport.slug,
        view: 'CSV',
        ...csvApiParams,
      },
    });
    this.context.executeAction(ShowToast, {
      type: ToastType.INFO,
      message: 'Hold tight - it may take a while for your CSV download to start.',
    });
  }

  handleDownloadError(err) {
    if (err instanceof NotExistError) {
      this.context.executeAction(ShowToast, {
        type: ToastType.ERROR,
        message: 'Reports are not enabled for this organization.',
      });
      return;
    } else if (err instanceof TooManyRequestsError) {
      this.context.executeAction(ShowToast, {
        type: ToastType.WARN,
        message: 'Your report could not be downloaded at this time. Please try again in a few minutes.',
      });
      return;
    }

    let handler = new GatewayErrorInteractiveHandler(this.context);
    handler.handleCommonErrors(err);
  }
}

function getCsvApiParams(report, config, aggregationLevel) {
  const { metricSetName, filters } = config;

  return {
    metricSet: metricSetName,
    timezone: report.timezone,
    startAt: moment(report.startAt).format('YYYY-MM-DD'),
    endAt: moment(report.endAt).format('YYYY-MM-DD'),
    aggregationLevel: _.includes(filters, 'timeAggregation') ? aggregationLevel : undefined,
    filters: {
      channel: _.includes(filters, 'channel') ? getChannelFilterValue(report.channel) : undefined,
      inboxes: _.includes(filters, 'inboxes') ? report.routingGroupIds : undefined,
      phoneNumber: _.includes(filters, 'phoneNumber') ? report.endpointNumber : undefined,
      teams: _.includes(filters, 'teams') ? report.teamIds : undefined,
    },
  };
}
