import IdGenerator from 'scripts/domain/contracts/id_generator';
import RoutingEventType from 'models/routing_event/routing_event_type';

export default class RoutingEventPublisher {
  constructor(orgId, pubsub, database) {
    this.database = database;
    this.orgId = orgId;
    this.pubsub = pubsub;
  }

  publish(customer, conversation, conversationItem) {
    let eventId = IdGenerator.newId();
    if (!this.database.get().events) {
      this.database.get().events = {};
    }
    this.database.get().events[eventId] = { customerId: customer.id, conversationId: conversation.id };

    this.pubsub.publish(`v1/orgs/${this.orgId}/agents/${this.database.get().currentAgent.id}/routing-event`, {
      payload: {
        type: RoutingEventType.ASSIGNED_CONVERSATION,
        conversationId: conversation.id,
        conversationItem,
        profile: customer.profile,
      },
    });
  }
}
