import createModel from './lib/create_model';

import InteractionType from 'models/composition/interaction_type';

export default createModel({
  modelName: 'CompanyProfile',

  properties: {
    emailDomain: String,
    name: String,
    widgets: [String],
    fromAddresses: [String],
  },

  statics: {
    Widgets: InteractionType,
    create(attrs) {
      return new this(attrs);
    },
  },
});
