import { addCustomerToAccessHistory } from './lib/customer_access_history';
import Customer from 'models/customer';

export default class CreatePendingCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ id }) {
    let customer = Customer.create({ id });
    this.context.stores.customers.add(customer);
    this.context.stores.customers.storesFor(id).profile.setPending(customer.profile);

    addCustomerToAccessHistory(this.context, customer.id);
  }
}
