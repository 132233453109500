import inflection from 'inflection';
import { css } from 'styled-components';

export function spaceFor(type, props) {
  if (!props.theme) {
    return null;
  }
  if (props[type] === 'none') {
    return 0;
  }
  let val = props[type] ? inflection.camelize(`${type}_${props[type]}`, true) : `${type}Medium`;
  return props.theme.spacing[val];
}

export function inlineDirectionSpacing() {
  return css`
    margin-right: ${props => (!props.direction || props.direction === 'right' ? spaceFor('inline', props) : 0)};
    margin-left: ${props => (props.direction === 'left' ? spaceFor('inline', props) : 0)};
  `;
}

export function removeInlinePadding(p) {
  if (p.direction === 'left') {
    return css`
      padding-left: 0;
    `;
  }
  return css`
    padding-right: 0;
  `;
}

export function removeInsetLastMargin(p) {
  let marginCss = '';
  if (p.inset === 'none') {
    if (p.direction === 'left') {
      marginCss = css`
        ${p.childClassName}:last-child {
          margin-left: 0;
        }
      `;
    } else {
      marginCss = css`
        .${p.childClassName}:last-child {
          margin-right: 0;
        }
      `;
    }
  }
  return marginCss;
}
