import classnames from 'classnames';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';

const ExpandableList = createReactClass({
  propTypes: {
    className: PropTypes.string,
    list: PropTypes.array,
    prefix: PropTypes.string,
  },

  getInitialState() {
    return {
      expanded: false,
    };
  },

  toggleListExpansion() {
    this.setState({
      expanded: !this.state.expanded,
    });
  },

  render() {
    let classes = classnames('expandableList', this.props.className);
    if (!this.props.list || this.props.list.length === 0) {
      return null;
    }
    return (
      <div className={classes}>
        {this.props.prefix} {this.renderList()}
      </div>
    );
  },

  renderList() {
    return (
      <span>
        <span>{this.props.list[0]}</span>
        <span className="expandableList-content" onClick={this.toggleListExpansion}>
          {this.renderLongList()}
        </span>
      </span>
    );
  },

  renderLongList() {
    if (this.props.list.length <= 1) {
      return null;
    }

    let list = this.props.list.slice(1);
    if (this.state.expanded) {
      return this.renderExpandedList(list);
    }
    return this.renderCollapsedList(list);
  },

  renderExpandedList(list) {
    return <span>{`, ${list.join(', ')}`}</span>;
  },

  renderCollapsedList(list) {
    return <span>{` & ${list.length} other${list.length > 1 ? 's' : ''}`}</span>;
  },
});

export default ExpandableList;
