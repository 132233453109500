import React, { useContext } from 'react';
import TwitterFillIcon from 'components/lib/icons/twitter_fill_icon';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function TwitterRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.twitter;
  const callSla = sla.twitter;
  return (
    <FloorboardRow
      activeItems={stats.currentOpenMessages}
      activeLabel="Open Messages"
      currentWait={stats.currentWait}
      icon={<TwitterFillIcon />}
      newItems={stats.currentNewMessages}
      newLabel="New Messages"
      sla={callSla}
      waitLabel="Oldest Unanswered Message"
    />
  );
}
