import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';

export default class TopicHierarchyService {
  constructor(publishResponse, database = getDatabase) {
    this.publishResponse = publishResponse;
    this.getDatabase = database;
  }

  getRoutes() {
    return bindCallbacks({
      '/api/v1/orgs/:orgId/topic-hierarchy': {
        GET: (attrs, callback, path, { orgId }) => {
          callback(null, {
            status: 200,
            statusText: statusText(200),
            response: this.fetch(orgId),
          });
        },
      },
    });
  }

  fetch(orgId) {
    const database = this.getDatabase(orgId);
    if (database) {
      return database.topicHierarchy || {};
    }
    return {};
  }
}
