import _ from 'lodash';
import React, { useState } from 'react';

import IdGenerator from 'scripts/domain/contracts/id_generator';
import { getDisplayName } from 'scripts/presentation/hoc_helpers/display_name';

/**
 * Wraps the component with a static ID prop that does not change through the entire component lifecycle. The ID is
 * passed to the component via `propName` prop
 *
 * NOTE: This ID may not be a good fit for components which lifecycle is dependent on some outside parameters (like
 * memoised components with dependencies)
 *
 * @param {Function|React.Component} WrappedComponent
 * @param {String} propName
 * @returns {Function}
 */
export default function withStaticId(WrappedComponent, propName) {
  if (!WrappedComponent) throw new Error('withStaticId: missing "WrappedComponent"');
  const idName = _.camelCase(propName);
  if (!idName) throw new Error('withStaticId: missing or invalid "propName"');

  return props => {
    const [id] = useState(() => {
      const staticId = IdGenerator.newId();
      const displayName = getDisplayName(WrappedComponent);
      if (!displayName) {
        return staticId;
      }
      return `${displayName}-${staticId}`;
    });
    const combinedProps = { ...props, [idName]: id };
    return <WrappedComponent {...combinedProps} />;
  };
}
