import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 5 5" {...props}>
    <g>
      <circle cx="2.5" cy="2.5" r="2.5" />
    </g>
  </IconContainer>
);
