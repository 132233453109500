import DtoConverter from 'scripts/application/dto_converters/diagnostics_config_converter';
import analytics from 'scripts/lib/analytics/index';
import AnalyticsTools from 'scripts/lib/analytics/domain/analytics_tools';

export default class DiagnosticsConfigurationGatewayObserver {
  constructor() {
    this.analytics = analytics;
  }

  onFetchSuccess(dto) {
    this.handleDto(dto);
  }

  onBroadcast(dto) {
    this.handleDto(dto);
  }

  handleDto(dto) {
    const diagnosticsConfig = DtoConverter.fromDto(dto);
    if (diagnosticsConfig.fullStoryDeactivated) {
      this.analytics.forceDisable(AnalyticsTools.FULLSTORY);
    }
  }
}
