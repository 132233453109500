import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default class PauseOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldAnimate: props.isPaused,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isPaused) {
      this.setState({ shouldAnimate: true });
    }
  }

  render() {
    return (
      <div
        className={classnames('voiceItem-waveForm-paused', {
          active: this.props.isPaused,
          inactive: !this.props.isPaused,
          animateInactive: !this.props.isPaused && this.state.shouldAnimate,
        })}
      >
        <div
          className={classnames('voiceItem-waveForm-paused-text', {
            active: this.props.isPaused,
            inactive: !this.props.isPaused,
            animateInactive: !this.props.isPaused && this.state.shouldAnimate,
          })}
        >
          Recording paused
        </div>
      </div>
    );
  }
}

PauseOverlay.propTypes = {
  isPaused: PropTypes.bool.isRequired,
};
