import EmbedTokensReport from 'models/reports/embed_tokens_report';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import mixin from 'scripts/lib/mixin';

export default class EmbedTokensReportGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.embedTokensReport;
  }

  onAddError(error) {
    this.handleCommonErrors(error);
    this.store.resetLoading();
  }

  onAddSuccess(responseDto) {
    if (responseDto) {
      const current = this.store.get();
      const update = EmbedTokensReport.fromJs(responseDto);

      if (current && update.tokenExpiry < current.tokenExpiry) {
        this.store.resetLoading();
        return;
      }

      if (!update.url && current?.url) {
        update.setUrl(current.url);
      }
      this.store.set(update);
      this.store.resetLoading();
    }
  }
}

mixin(EmbedTokensReportGatewayObserver.prototype, GatewayErrorInteractiveHandler.prototype);
