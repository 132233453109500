import _ from 'lodash';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import getNormalizedEmailAddress from 'scripts/lib/get_normalized_email_address';
import { normalizePhoneNumber } from 'models/phone_number';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

const DEFAULT_QUERY_KEYS = [
  'address',
  'customAttributes',
  'emails',
  'name',
  'phones',
  'phone',
  'email',
  'externalCustomerId',
  'externalCustomerIds',
];

/*
  This customer lookup adapter implements basic lookup with the following behavior:
  - The potential results must be defined in dataset.externalProfiles
    (for sagan db, this is datasets/src/datasets/sagan/sagan_external_profiles.js)
  - The search implements an exact match for: name, address, email, phone number, or any custom attribute
  - If the query contains multiple attributes, the result must match for all query attributes
  - To return all results defined in the dataset, do not fill in any search query fields
*/

export default class ExternalCustomerLookupService {
  constructor(getDb = getDatabase) {
    this.getDatabase = getDb;
  }

  fetch(orgId, payload) {
    return ExternalCustomerLookupService.findProfiles(this.getDatabase(orgId), orgId, payload).results;
  }

  findByIntegrationId(query, orgId) {
    const allResults = ExternalCustomerLookupService.findProfiles(this.getDatabase(orgId), orgId, query);
    return _.groupBy(allResults.results, result => result.integrationId);
  }

  find(payload, callback, path, { orgId }) {
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: { results: this.findByIntegrationId(payload, orgId) },
    });
  }

  findForIntegrationId(payload, callback, path, { integrationId, orgId }) {
    const allResults = this.findByIntegrationId(payload, orgId);
    const response = { results: { [integrationId]: _.get(allResults, integrationId, []) } };

    callback(null, { status: 200, statusText: statusText(200), response });
  }

  static findProfiles(datastore, orgId, payload) {
    const query = payload.query;

    const hasMultipleEmails = !!(query.emails && query.emails.length);
    const hasMultiplePhones = !!(query.phones && query.phones.length);
    const hasSingleEmail = !!query.email;
    const hasSinglePhone = !!query.phone;
    const queryEmail = query.email && getNormalizedEmailAddress(query.email);
    const queryEmails = _.map(query.emails, email => getNormalizedEmailAddress(email));
    const queryPhone = query.phone && normalizePhoneNumber(query.phone);
    const queryPhones = _.map(query.phones, phone => normalizePhoneNumber(phone));

    let externalProfiles = _.filter(datastore.externalProfiles, externalProfile => {
      const profileEmails = _.map(externalProfile.emails, email => getNormalizedEmailAddress(email.original));
      const profilePhones = _.map(externalProfile.phones, phone => normalizePhoneNumber(phone.original));
      const multiEmailMatches = hasMultipleEmails ? _.intersection(profileEmails, queryEmails) : [];
      const singleEmailMatch = hasSingleEmail ? profileEmails.includes(queryEmail) : false;
      const singlePhoneMatch = hasSinglePhone ? profilePhones.includes(queryPhone) : false;
      const multiPhoneMatches = hasMultiplePhones ? _.intersection(profilePhones, queryPhones) : [];

      const defaultQueryResult =
        matchQueryField(query, externalProfile, 'externalCustomerId') &&
        matchQueryField(query, externalProfile, 'name') &&
        matchQueryField(query, externalProfile, 'address') &&
        (!hasSingleEmail || singleEmailMatch) &&
        (!hasMultipleEmails || multiEmailMatches.length > 0) &&
        (!hasSinglePhone || singlePhoneMatch) &&
        (!hasMultiplePhones || multiPhoneMatches.length > 0);

      const customKeys = _.keys(_.omit(query, DEFAULT_QUERY_KEYS));
      const customQueryResult =
        !customKeys.length ||
        (customKeys.length &&
          _.every(
            _.map(customKeys, customKey => {
              return !query[customKey] || query[customKey] === _.get(externalProfile, `customAttributes.${customKey}`);
            })
          ));
      return defaultQueryResult && customQueryResult;
    });

    // The "BASIC" result does not include transactions
    if (payload.lookupLevel === 'BASIC') {
      externalProfiles = externalProfiles.map(profile => _.omit(profile, ['transactions']));
    }

    return {
      results: externalProfiles,
    };
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v2/orgs/:orgId/customers/:customerId/external-customer-lookup/integrations': {
          POST: this.find,
        },
        '/api/v2/orgs/:orgId/customers/:customerId/external-customer-lookup/integrations/:integrationId': {
          POST: this.findForIntegrationId,
        },
      },
      this
    );
  }
}

function matchQueryField(query, externalProfile, fieldName) {
  const value = query[fieldName];
  return value ? value === externalProfile[fieldName] : true;
}
