import ErrorReporter from 'scripts/infrastructure/error_reporter';

export default class OutgoingEmailGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onAddError(error, params) {
    ErrorReporter.reportError(new Error(`OutgoingEmailGatewayObserver email send error`), {
      extra: { error, ...params },
    });
  }
}
