import _ from 'lodash';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/common/button';
import connect from 'components/lib/connect';
import CancelAgentInboxPolling from 'actions/inbox/agent/cancel_agent_inbox_polling';
import InboxMenu from './inbox_menu';
import InboxList from './inbox_list_container';
import RequestAgentInbox from 'actions/inbox/agent/request_agent_inbox';
import RequestAgentInboxDeprecated from 'actions/inbox/agent/request_agent_inbox_deprecated';
import Spinner from 'components/common/spinner_two';
import TwoArrowsRight from 'components/lib/icons/two_arrows_right';
import TwoArrowsLeft from 'components/lib/icons/two_arrows_left';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useWindowSize from 'components/hooks/use_window_size';

export function SidebarInbox({
  canPerformAgentActions,
  collapsed,
  customerCount,
  hasLoadingError,
  isLoading,
  isUsingNewInbox,
  someItemHasFailedLoad,
  toggleCollapse,
}) {
  useRequestAgentInbox(canPerformAgentActions, isUsingNewInbox);

  if (!canPerformAgentActions) {
    return null;
  }

  if (isLoading) {
    return (
      <StyledSidebar collapsed={collapsed} data-aid="customerList-loading" isLoading={isLoading}>
        <Spinner data-aid="customerList-loading" />
      </StyledSidebar>
    );
  }

  if (hasLoadingError) {
    return <FailedLoadInbox />;
  }

  // For the new inbox, if we have a loading error (e.g. the inbox request timed out) or an individual item failed to
  return collapsed && !someItemHasFailedLoad ? (
    <CollapsedInbox toggleCollapse={toggleCollapse} />
  ) : (
    <ExpandedInbox
      customerCount={customerCount}
      someItemHasFailedLoad={someItemHasFailedLoad}
      toggleCollapse={toggleCollapse}
    />
  );
}

function useRequestAgentInbox(canPerformAgentActions, isUsingNewInbox) {
  const executeAction = useExecuteAction();
  useEffect(() => {
    if (canPerformAgentActions) {
      if (!isUsingNewInbox) {
        executeAction(RequestAgentInboxDeprecated);
      } else {
        executeAction(RequestAgentInbox);
      }
      return () => {
        executeAction(CancelAgentInboxPolling);
      };
    }
    // We handle isUsingNewInbox changing in the feature set gateway observer, hence why we don't include it here.
  }, [canPerformAgentActions, executeAction]);
}

function ExpandedInbox({ customerCount, someItemHasFailedLoad, toggleCollapse }) {
  return (
    <StyledSidebar data-aid="customerList" data-collapsed="false">
      <Inbox data-aid="customerList-scrollWrapper">
        <Header data-aid="customerList-header">
          <Title data-aid="customerList-title">
            {customerCount != null ? `${customerCount} customer${customerCount !== 1 ? 's' : ''}` : 'My Customers'}
          </Title>
          <InboxMenu />
        </Header>
        <InboxList />
      </Inbox>
      {!someItemHasFailedLoad ? (
        <Collapser data-aid="customerList-sizeToggle" onClick={toggleCollapse}>
          <TwoArrowsLeft />
        </Collapser>
      ) : null}
    </StyledSidebar>
  );
}

function CollapsedInbox({ toggleCollapse }) {
  const { windowWidth } = useWindowSize();

  return (
    <StyledSidebar collapsed data-aid="customerList" data-collapsed="true">
      <Scroller data-aid="customerList-scrollWrapper">
        <InboxList collapsed />
        <InboxMenu collapsed />
      </Scroller>
      {windowWidth > 1280 ? (
        <Expander data-aid="customerList-sizeToggle" onClick={toggleCollapse}>
          <TwoArrowsRight />
        </Expander>
      ) : null}
    </StyledSidebar>
  );
}

function FailedLoadInbox() {
  const executeAction = useExecuteAction();
  const rerequestInbox = () => {
    executeAction(RequestAgentInbox);
  };

  return (
    <StyledSidebar data-aid="customerList-failedLoad" data-collapsed="false">
      <FailedInbox>
        <FailedContainer>
          <LoadErrorDescription>The inbox failed to load</LoadErrorDescription>
          <Button onClick={rerequestInbox} type={Button.Types.PRIMARY}>
            Retry
          </Button>
        </FailedContainer>
      </FailedInbox>
    </StyledSidebar>
  );
}

export function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const agentInboxProvider = getProvider('agentInboxItems');
  const agentInboxNewProvider = getProvider('agentInboxItemsNew');

  const personalInboxCounts = getProvider('personalInboxCounts').get();
  const customerCount = personalInboxCounts ? personalInboxCounts.openCount + personalInboxCounts.waitingCount : null;
  const useNewInboxItems = isFeatureEnabled('customerListAssignmentBroadcast');

  const someItemHasFailedLoad = useNewInboxItems
    ? _.compact(
        _.map(agentInboxNewProvider.findAll(), item => {
          const errors = agentInboxNewProvider.getErrors(item.id);
          return errors && errors.length;
        })
      ).length > 0
    : false;

  return {
    canPerformAgentActions: isFeatureEnabled('internalAgentActions'),
    customerCount,
    // New inbox doesn't use polling so we should show a button which allows agents to manually re-fetch the inbox if there's a request timeout.
    hasLoadingError: useNewInboxItems ? agentInboxNewProvider.getErrorForLoading() : false,
    isLoading: useNewInboxItems ? agentInboxNewProvider.isLoading() : agentInboxProvider.isLoading(),
    isUsingNewInbox: useNewInboxItems,
    // Likewise, if an individual item fails to load, we need to ensure we show the expanded inbox so that they can manually refetch an individual item.
    someItemHasFailedLoad,
  };
}

const SidebarInboxContainer = connect(mapStateToProps)(SidebarInbox);
export default SidebarInboxContainer;

const Title = styled.div`
  color: ${p => p.theme.colors.gray700};
  flex: 1;
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  padding: 16px 16px 0 16px;
`;

const Inbox = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
`;

const loadingSidebar = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledSidebar = styled.div`
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.boxShadow.small};
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
  padding-top: 60px;
  position: relative;
  width: ${p => (p.collapsed ? `80px` : `340px`)};
  z-index: 6;
  ${p => p.isLoading && loadingSidebar}
`;

const Expander = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.white};
  bottom: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  color: ${p => p.theme.colors.gray500};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-bottom: 16px;
  position: absolute;
  right: 0;
  width: 24px;

  &:hover {
    background-color: ${p => p.theme.colors.gray100};
  }

  svg {
    height: 14px;
  }
`;

const Scroller = styled.div`
  height: 100%;
  overflow: auto;
`;

const Collapser = Expander;

const FailedInbox = styled(Inbox)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
const FailedContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LoadErrorDescription = styled.div`
  margin-bottom: 8px;
`;
