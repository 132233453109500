import ConvItemDtoConverter from 'scripts/application/dto_converters/conversation_item_converter';
import ConversationUpdater from 'actions/conversation/lib/conversation_updater';
import createNoReplyNeeded from 'scripts/domain/factories/conversation_item/create_no_reply_needed';

export default class AddNoReplyNeeded extends ConversationUpdater {
  run() {
    let noReplyNeededContent = {};
    let activeConversation = this.getActiveConversation();
    if (!activeConversation) {
      return;
    }

    let noReplyNeededItem = createNoReplyNeeded({
      agentProfile: this.currentAgent,
      conversationId: activeConversation.id,
      content: noReplyNeededContent,
      customerId: this.currentCustomerId,
    });

    this.context.stores.conversationHistory.add(noReplyNeededItem);

    this.context.gateways.noReplyNeeded.addNoReplyNeeded(this.currentAgent.id, {
      id: noReplyNeededItem.id,
      customerId: this.currentCustomerId,
      conversation: { id: activeConversation.id },
      conversationItem: ConvItemDtoConverter.toDto(noReplyNeededItem),
    });
  }
}
