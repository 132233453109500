import React, { useContext, useEffect, useState } from 'react';

import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function withRefreshEditorWhenFlagsChange(Component) {
  function WithRefreshEditorWhenFlagsChange(props) {
    const isFeatureEnabled = useIsFeatureEnabled();
    const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);
    const [key, setKey] = useState(IdGenerator.newId());

    // When AI text features change, our editor plugins will change.. Plate apparently doesn't handle this well,
    // so we need force an entire remount of the wrapped component to create a new editor.
    // This may lose _some_ agent work in the process.
    const disableHeroAI = agentAssistanceConfig.disableHeroAI;
    const suggestedReplies = isFeatureEnabled('suggestedReplies');

    useEffect(() => {
      setKey(IdGenerator.newId());
    }, [disableHeroAI, suggestedReplies]);

    return <Component {...props} key={key} />;
  }

  WithRefreshEditorWhenFlagsChange.displayName = `withRefreshEditorWhenFlagsChange(${Component.displayName ||
    Component.name})`;

  return WithRefreshEditorWhenFlagsChange;
}
