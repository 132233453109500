import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import AgentStatusButton from './agent_status_button';
import AgentStatusTooltip from './agent_status_tooltip';
import connect from 'components/lib/connect';
import Mail from './icons/mail';
import MailSelected from './icons/mail_selected';
import RoutableCounts from 'models/routable_counts';
import { RoutingChannel, RoutingPreferenceGroup } from 'models/agent_routing_preferences';
import UpdateAgentRoutingPreferences from 'actions/routing/update_agent_routing_preferences';
import withShortcuts from 'components/keyboard_shortcuts/with_shortcuts';

export function TaskStatusButton({ isLoading, isPreferred, routableCounts, onClick }) {
  return (
    <div className={classnames('taskStatusButton', { disabled: isLoading })}>
      <AgentStatusTooltip message="Tasks" preferenceGroup={RoutingPreferenceGroup.TASK}>
        {({ isHovered }) => (
          <AgentStatusButton
            onClick={handleClick}
            preferenceGroup={RoutingPreferenceGroup.TASK}
            routableCounts={routableCounts}
            showTrueCount={isHovered}
          >
            {isPreferred ? <MailSelected /> : <Mail />}
          </AgentStatusButton>
        )}
      </AgentStatusTooltip>
    </div>
  );

  function handleClick() {
    onClick({ isPreferred });
  }
}

TaskStatusButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isPreferred: PropTypes.bool.isRequired,
  routableCounts: PropTypes.instanceOf(RoutableCounts),
  onClick: PropTypes.func.isRequired,
};

const TaskStatusButtonWithShortcuts = withShortcuts(TaskStatusButton, [
  {
    key: 'alt+5',
    handler: _.debounce(({ isPreferred, onClick }) => onClick({ isPreferred }), 300, {
      leading: true,
      trailing: false,
    }),
    group: 'Availability',
    label: 'Toggle readiness for tasks',
  },
]);

export { TaskStatusButtonWithShortcuts };

const TaskStatusButtonContainer = connect(mapStateToProps, mapExecuteToProps)(TaskStatusButtonWithShortcuts);

function mapStateToProps({ getProvider }) {
  const preferencesProvider = getProvider('agentRoutingPreferences');
  const preferences = preferencesProvider.getPending() || preferencesProvider.get();
  const isPreferred = preferences.isPreferredOnChannel(RoutingChannel.TASK);
  const routableCounts = getProvider('routableCounts').get();

  return {
    isLoading: preferences._version === 0,
    isPreferred,
    routableCounts,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClick: ({ isPreferred }) =>
      executeAction(UpdateAgentRoutingPreferences, { channels: { [RoutingChannel.TASK]: !isPreferred } }),
  };
}

export default TaskStatusButtonContainer;
