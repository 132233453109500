import _ from 'lodash';
import SelectAnswer from './select_answer';

export default class NavigateToPreviousAnswer {
  constructor(context) {
    this.context = context;
  }

  run() {
    const answerPanel = this.context.stores.answerPanel.get();
    const { answer, language, type } = _.last(answerPanel.history);
    if (!answer) {
      return;
    }

    answerPanel.removeLastFromHistory();
    this.context.stores.answerPanel.set(answerPanel);
    this.context.executeAction(SelectAnswer, { answer, answerLanguage: language, answerType: type });
  }
}
