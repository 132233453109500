import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'scripts/domain/models/lib/create_model';
import ScheduleNode from 'scripts/domain/models/ivr/ivr_node_schedule';

export const IvrNodeTypes = createEnum('IVR_NODE_SCHEDULE');

export default createModel({
  modelName: 'IvrNode',

  properties: {
    type: prop().oneOf(..._.keys(IvrNodeTypes)),
    ivrNode: prop().oneOf(...[ScheduleNode]),
  },

  statics: {
    overrideFromJs() {
      return attrs => {
        switch (attrs.type) {
          case IvrNodeTypes.IVR_NODE_SCHEDULE:
            attrs.ivrNode = ScheduleNode.fromJs(attrs.ivrNode);
            break;

          default:
            throw new Error(`unsupported ivr node type: ${attrs.type}`);
        }

        return new this(attrs);
      };
    },
  },

  isEnabled() {
    if (this.type !== IvrNodeTypes.IVR_NODE_SCHEDULE) {
      return true;
    }

    return this.ivrNode.isEnabled();
  },
});
