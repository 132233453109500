import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class ReportConfigsService {
  fetch(attrs, callback, path) {
    let response = this._getReportConfigs();

    callback(null, {
      status: 200,
      statusText: statusText(200),
      response,
    });
  }

  _getReportConfigs() {
    return [
      {
        metricSetName: 'ConversationCloseTimeByAgentReport',
        title: 'Conversation Close Time by Agent',
        categoryId: 'Agent Performance',
        urlSlug: 'conversation-close-time-by-agent',
        filters: ['dateRange', 'timeAggregation', 'channel', 'inboxes', 'teams'],
        timeAggregation: ['halfHourly', 'daily', 'weekly', 'monthly', 'quarterly'],
      },
      {
        metricSetName: 'TaskCloseTimeByAgentReport',
        title: 'Task Close Time by Agent',
        categoryId: 'Agent Performance',
        urlSlug: 'task-close-time-by-agent',
        filters: ['dateRange', 'timeAggregation', 'inboxes', 'teams'],
        timeAggregation: ['halfHourly', 'daily', 'weekly', 'monthly', 'quarterly'],
      },
      {
        metricSetName: 'WorkSessionsReport',
        title: 'Work Sessions',
        categoryId: 'Forecasting and Staffing',
        urlSlug: 'work-sessions',
        filters: ['dateRange', 'channel', 'inboxes', 'teams'],
      },
      {
        metricSetName: 'ConversationSummaryReport',
        title: 'Conversation Summary',
        categoryId: 'Conversations',
        urlSlug: 'conversation-summary',
        filters: ['dateRange', 'timeAggregation', 'inboxes', 'teams'],
      },
      {
        metricSetName: 'DailyTrendsReport',
        title: 'Daily Trends',
        categoryId: 'Conversations',
        urlSlug: 'daily-trends',
        filters: ['dateRange'],
      },
    ];
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/report-configs': {
          GET: this.fetch,
        },
      },
      this
    );
  }
}
