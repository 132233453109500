import createReactClass from 'create-react-class';
import disableScroll from 'disable-scroll';
import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ReactDOM from 'react-dom';

import Mousetrap from 'components/lib/mousetrap';

// This is a very simplistic modal shell. It gives you a backdrop and modal format as well as managing animation.
// You are responsible for closing it. Put whatever you want inside, modal doesn't care. You can put stuff in a div
// called .header, .footer and .body if you want. Id recommend it, it will format it better. Otherwise it doesn't do much but look pretty.

const Modal = createReactClass({
  propTypes: {
    children: PropTypes.any,
    className: PropTypes.string,
  },

  componentDidUpdate() {
    const firstButton = ReactDOM.findDOMNode(this).querySelector('button');
    if (firstButton) {
      firstButton.focus();
    }
  },

  UNSAFE_componentWillMount() {
    Mousetrap.pause();
    disableScroll.on(document.body, { disableKeys: false });
  },

  componentWillUnmount() {
    Mousetrap.unpause();
    disableScroll.off();
  },

  render() {
    return (
      <div className="Modal-wrapper">
        <ReactCSSTransitionGroup
          transitionAppear
          transitionAppearTimeout={700}
          transitionEnterTimeout={700}
          transitionLeaveTimeout={700}
          transitionName="modal"
        >
          <div className={`Modal ${this.props.className || ''}`}>{this.props.children}</div>
        </ReactCSSTransitionGroup>
      </div>
    );
  },
});

export default Modal;
