import _ from 'lodash';

import DtoConverter from 'scripts/application/dto_converters/snippet_converter';
import Err from 'models/err';
import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';
import KbAdmin from 'models/location/kb_admin';
import NavigateToKbAdmin from 'actions/knowledge_base/navigate_to_kb_admin';
import qconsole from 'scripts/lib/qconsole';
import ShowAdminNotification from 'actions/notification/show_admin_notification';
import ShowSnippetSuccessToast from './show_snippet_success_toast';
import { getAllLinkedAnswerIds } from 'models/answers/snippet_helpers';
import HandleRequestedAnswers from 'actions/answers/handle_requested_answers';
import SetAnswerView from 'actions/knowledge_base/set_answer_view';

export default class SnippetGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.snippets;
  }

  get compositionStore() {
    return this.context.stores.snippetComposition;
  }

  get snippetComposition() {
    return this.compositionStore.get();
  }

  get currentAgentStore() {
    return this.context.stores.currentAgent;
  }

  get currentAgentSnippetsStore() {
    return this.context.stores.currentAgentSnippets;
  }

  get snippetViewStore() {
    return this.context.stores.snippetView;
  }

  get snippetLinksStore() {
    return this.context.stores.snippetLinks;
  }

  onFetchError(errorDto, params) {
    qconsole.log('Failed to fetch snippets', errorDto, params);
  }

  onFetchSuccess(snippetDto) {
    let snippet = DtoConverter.fromDto(snippetDto);
    this.handleRequestedCompositionEntities(snippet);
  }

  handleRequestedCompositionEntities(snippet) {
    let snippetComposition = this.compositionStore.get();
    if (snippetComposition && snippetComposition.id === (snippet && snippet.id)) {
      this.compositionStore.set(snippet);
      this.context.stores.snippets.addOrReplace(snippet);
      this.compositionStore.clearErrors();
      this.compositionStore.resetLoading();

      // to prevent infinite looping
      let snippetLinkIds = _.filter(getAllLinkedAnswerIds(snippet), id => {
        const currentSnippetId = snippet && snippet.id;
        return id !== currentSnippetId;
      });

      if (snippetLinkIds.length) {
        this.snippetLinksStore.setLoading();
        this.context.gateways.snippet.fetchAll({ 'f.ids': snippetLinkIds });
      }
    }
  }

  onFetchAllError(errorDto, params, query) {
    qconsole.log('Failed to fetch snippets', errorDto, query);
  }

  onFetchAllSuccess(snippetDtos) {
    const snippets = _.map(snippetDtos, DtoConverter.fromDto);
    this.handleRequestedLinksEntities(snippets);
  }

  handleRequestedLinksEntities(snippets) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation.customerId) {
      this.context.executeAction(HandleRequestedAnswers, { answers: snippets });
      return;
    }

    let snippetViewStore = this.snippetViewStore.get();
    let answerPanelState = this.context.stores.answerPanelState.get();
    let answerPanelStateHistory = answerPanelState && answerPanelState.history;
    let snippetCompositionStore = this.context.stores.snippetComposition.get();

    let snippetCompositionLinkedAnswerIds = getAllLinkedAnswerIds(snippetCompositionStore);
    let snippetViewLinkedAnswerIds = getAllLinkedAnswerIds(snippetViewStore);
    let linkedAnswerIds = _(snippetViewLinkedAnswerIds)
      .concat(snippetCompositionLinkedAnswerIds, answerPanelStateHistory)
      .compact()
      .value();

    // if there are no snippet links or we've fetched a linked snippet, we want to reset loading
    let shouldResetSnippetLinksLoading = snippets.length === 0;
    _.forEach(snippets, snippet => {
      if (_.includes(linkedAnswerIds, snippet.id)) {
        this.snippetLinksStore.addOrReplace(snippet);
        if (!shouldResetSnippetLinksLoading) {
          shouldResetSnippetLinksLoading = true;
        }
      }
    });

    if (shouldResetSnippetLinksLoading) {
      this.context.stores.snippetLinks.resetLoading();
    }

    if (
      answerPanelState?.directLink &&
      snippetViewStore?.id !== answerPanelState.directLink.id &&
      _.some(snippets, s => s.id === answerPanelState.directLink.id)
    ) {
      let snippet = this.context.stores.snippetLinks.findBy({ id: answerPanelState.directLink.id });
      this.context.executeAction(SetAnswerView, { snippet });
    }
  }

  onBroadcast(snippetDto) {
    let snippet = DtoConverter.fromDto(snippetDto);
    let isCompositionPending = this.compositionStore.isPending();

    if (snippet.id === this.currentSnippetId && !isCompositionPending) {
      this.context.executeAction(
        ShowAdminNotification,
        'Another user has edited this answer. Saving will overwrite their changes'
      );
    }
    this.store.addOrReplace(snippet);

    const hasSnippet = this.currentAgentSnippetsStore.has({ id: snippet.id });
    if (hasSnippet) {
      this.currentAgentSnippetsStore.addOrReplace(snippet);
    }
  }

  onBroadcastDelete(snippetId) {
    this.store.remove(snippetId);

    if (snippetId === this.currentSnippetId) {
      this.compositionStore.remove();
      this.context.executeAction(NavigateToKbAdmin);
      this.context.executeAction(ShowAdminNotification, 'Another user deleted that answer');
    }
  }

  // Save result handlers

  hasPendingAdd() {
    return (
      this.snippetComposition &&
      this.compositionStore.isPending() &&
      !this.store.findBy({ id: this.snippetComposition.id })
    );
  }

  hasPendingUpdate(snippetId) {
    return this.compositionStore.isPending() && this.compositionStore.getPending().id === snippetId;
  }

  onAddError(errorsDto) {
    if (this.hasPendingAdd()) {
      this.compositionStore.setErrors(errorsDto.errors.map(ErrorDtoConverter.fromDto));
      this.compositionStore.resetPending();
    }
  }

  onAddSuccess() {
    if (this.snippetComposition && this.compositionStore.isPending()) {
      let snippet = this.compositionStore.getPending();
      this.context.executeAction(ShowSnippetSuccessToast, snippet);

      this.compositionStore.remove();
      this.context.router.navigateTo(KbAdmin.create());
    }
  }

  onUpdateError(errorsDto, params) {
    if (this.hasPendingUpdate(params.snippetId)) {
      this.compositionStore.setErrors(errorsDto.errors.map(ErrorDtoConverter.fromDto));
      this.compositionStore.resetPending();
    }
  }

  onUpdateSuccess(dto, params) {
    if (this.hasPendingUpdate(params.snippetId)) {
      let snippet = this.compositionStore.getPending();
      this.context.executeAction(ShowSnippetSuccessToast, snippet);

      this.compositionStore.remove();
      this.context.stores.snippets.commitPending(params.snippetId);
      this.context.router.navigateTo(KbAdmin.create());
    }
  }

  onDeleteError(errorsDto, params) {
    let snippetId = params.snippetId;
    let errors = errorsDto.errors.map(ErrorDtoConverter.fromDto);
    if (errors[0].code === Err.Code.NOT_EXIST) {
      this.onDeleteSuccess(null, params);
      return;
    }
    if (this.store.findBy({ id: snippetId })) {
      this.store.resetPending(snippetId);
      this.store.setErrors(snippetId, errors);
    }
  }

  onDeleteSuccess(dto, params) {
    this.store.commitPending(params.snippetId);
  }

  get currentSnippetId() {
    return this.context.stores.currentLocation.get().snippetId;
  }
}
