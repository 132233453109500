import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from 'components/lib/icons/arrow_icon';
import { AttachmentTypeIcon } from 'components/lib/attachment/attachment_type';
import DownloadableAttachment from 'models/downloadable_attachment';
import KeypressGlobalMixin from 'components/lib/keypress_global_mixin';
import LoadingSpinner from 'components/lib/loading_spinner';
import Modal from 'components/lib/modal';
import Button, { ButtonTypes } from 'components/common/button';

const PreviewAttachmentModal = createReactClass({
  mixins: [KeypressGlobalMixin],

  propTypes: {
    attachments: PropTypes.arrayOf(PropTypes.instanceOf(DownloadableAttachment)).isRequired,
    currentIndex: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onNavigateLeft: PropTypes.func.isRequired,
    onNavigateRight: PropTypes.func.isRequired,
  },

  getInitialState() {
    return { isImageLoaded: false };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentIndex !== nextProps.currentIndex) {
      this.setState({ isImageLoaded: false });
    }
  },

  onArrowLeft() {
    this.props.onNavigateLeft(this.props.currentIndex);
  },

  onArrowRight() {
    this.props.onNavigateRight(this.props.currentIndex);
  },

  // Ensures agents don't accidentally select something by accident if they click too fast on the arrows and
  // hit the first or last attachment and keep clicking on the (now hidden) arrow.
  preventDefault(evt) {
    evt.preventDefault();
  },

  render() {
    return (
      <Modal className="previewAttachmentModal">
        {this.renderHeader()}
        {this.renderImageSpinner()}
        <div className="previewAttachmentModal-background" onClick={this.props.onClose} />
        {this.renderLeftNavigationArrow()}
        {this.renderRightNavigationArrow()}
        {this.renderEmptyPreview()}
        {this.renderImagePreview()}
      </Modal>
    );
  },

  renderLeftNavigationArrow() {
    let arrow = <ArrowIcon />;
    let isFirstAttachment = this.isFirstAttachment();

    if (isFirstAttachment) {
      arrow = null;
    }

    let className = classnames('previewAttachmentModal-navigateLeft', {
      'previewAttachmentModal-navigateLeft-hidden': isFirstAttachment,
    });

    return (
      <div className={className} onClick={this.onArrowLeft} onMouseDown={this.preventDefault}>
        {arrow}
      </div>
    );
  },

  renderRightNavigationArrow() {
    let arrow = <ArrowIcon facingRight />;
    let isLastAttachment = this.isLastAttachment();

    if (isLastAttachment) {
      arrow = null;
    }

    let className = classnames('previewAttachmentModal-navigateRight', {
      'previewAttachmentModal-navigateRight-hidden': isLastAttachment,
    });

    return (
      <div className={className} onClick={this.onArrowRight} onMouseDown={this.preventDefault}>
        {arrow}
      </div>
    );
  },

  renderImageSpinner() {
    if (this.getCurrentAttachment().isImage() && !this.state.isImageLoaded) {
      return <LoadingSpinner />;
    }
    return null;
  },

  renderImagePreview() {
    if (this.getCurrentAttachment().isImage()) {
      let classNames = classnames('previewAttachmentModal-image', {
        'previewAttachmentModal-image-loaded': this.state.isImageLoaded,
      });
      return <img className={classNames} onLoad={this.onLoadImage} src={this.getCurrentAttachment().downloadUrl} />;
    }
    return null;
  },

  renderEmptyPreview() {
    if (!this.getCurrentAttachment().isImage()) {
      const attachmentName = this.getCurrentAttachment().fileDescriptor.filename;
      return (
        <div className="previewAttachmentModal-emptyPreview">
          <div className="previewAttachmentModal-emptyPreview-filenameContainer">
            <AttachmentTypeIcon title={attachmentName} type={this.getCurrentAttachment().fileDescriptor.contentType} />
            <div className="previewAttachmentModal-emptyPreview-filename">{attachmentName}</div>
          </div>
          <a
            className="previewAttachmentModal-emptyPreview-downloadButton-link"
            href={this.getCurrentAttachment().downloadUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button buttonType={ButtonTypes.PRIMARY} className="previewAttachmentModal-emptyPreview-downloadButton">
              <div className="previewAttachmentModal-emptyPreview-downloadButton-items">
                <div className="previewAttachmentModal-download" />
                <div className={'previewAttachmentModal-emptyPreviewText'}>Download</div>
              </div>
            </Button>
          </a>
        </div>
      );
    }
    return null;
  },

  renderHeader() {
    const attachmentName = this.getCurrentAttachment().fileDescriptor.filename;

    return (
      <div className="previewAttachmentModal-header">
        <div className="previewAttachmentModal-filenameContainer">
          <AttachmentTypeIcon title={attachmentName} type={this.getCurrentAttachment().fileDescriptor.contentType} />
          <div className="previewAttachmentModal-filename">{attachmentName}</div>
        </div>
        <div className="previewAttachmentModal-buttonContainer">
          {this.renderDownloadButton()}
          <div className="previewAttachmentModal-actionWrapper" onClick={this.props.onClose}>
            <div className="previewAttachmentModal-close" />
          </div>
        </div>
      </div>
    );
  },

  renderDownloadButton() {
    return (
      <a
        className="previewAttachmentModal-actionWrapper"
        href={this.getCurrentAttachment().downloadUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="previewAttachmentModal-download" />
      </a>
    );
  },

  getCurrentAttachment() {
    return this.props.attachments[this.props.currentIndex];
  },

  isFirstAttachment() {
    return this.props.currentIndex <= 0;
  },

  isLastAttachment() {
    return this.props.currentIndex >= this.props.attachments.length - 1;
  },

  onLoadImage() {
    this.setState({ isImageLoaded: true });
  },
});

export default PreviewAttachmentModal;
