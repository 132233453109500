import React from 'react';
import styled from 'styled-components';

import AnimatedNumber from 'react-animated-number';

export default function FloorboardPercent({ sla, label }) {
  if (!sla) {
    return null;
  }
  let over = sla.over || 0;
  let total = sla.total || 0;
  let pct = 0;
  if (total > 0 && total > over) {
    pct = parseFloat(((100 * (total - over)) / total).toFixed(0));
  }

  return (
    <FloorboardPercentWrapper>
      <FloorboardNumber>
        <AnimatedNumber duration={600} stepPrecision={0} value={pct} />%
      </FloorboardNumber>
      <FloorboardNumberLabel>{label}</FloorboardNumberLabel>
    </FloorboardPercentWrapper>
  );
}

const FloorboardPercentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-end: span 1;
  grid-row-end: span 1;
  border-top: 1px solid ${p => p.theme.colors.gray600};
  align-items: flex-end;
  justify-content: center;
  padding-right: 90px;
`;

const FloorboardNumber = styled.div`
  font-size: 7vh;
  line-height: 1;
  font-weight: bold;
  color: ${p => (p.number < p.threshold ? p.theme.colors.red400 : p.theme.colors.white)};
`;

export const FloorboardNumberLabel = styled.div`
  font-size: 3.6vh;
  color: ${p => (p.number < p.threshold ? p.theme.colors.red400 : p.theme.colors.white)};
`;
