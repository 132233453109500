import React from 'react';
import styled from 'styled-components';

import InformationFillIcon from 'components/common/icons/fill/information-fill';

export default function RedactedItem() {
  return (
    <StyledRedactedItem>
      <StyledRedactedIcon className="conversation-item-redacted-icon" />{' '}
      <span className="conversation-item-redacted-text">This item has been deleted</span>
    </StyledRedactedItem>
  );
}

const StyledRedactedItem = styled.span`
  align-items: center;
  color: ${p => p.theme.colors.gray600};
  display: flex;
  margin-right: 8px;
`;

const StyledRedactedIcon = styled(InformationFillIcon)`
  display: inline-flex;
  fill: ${p => p.theme.colors.gray600};
  height: 14px;
  margin-right: 6px;
`;
