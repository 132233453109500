import React from 'react';

export default function EmptyCheck() {
  return (
    <svg fill="none" height="56" width="56" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" fill="#F6F6F6" r="28" />
      <path
        clipRule="evenodd"
        d="M38.955 20.493a1.6 1.6 0 01-.048 2.262l-13.356 12.8a1.6 1.6 0 01-2.26-.045l-5.843-6.063a1.6 1.6 0 112.304-2.22l4.736 4.914 12.205-11.696a1.6 1.6 0 012.262.048z"
        fill="#4D4E4E"
        fillRule="evenodd"
      />
    </svg>
  );
}
