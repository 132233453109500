import PhoneCall from 'models/phone_call';

export default class CancelWarmTransfer {
  constructor(context) {
    this.context = context;
  }

  run({ agentId, routingGroupId }) {
    const activeCall = this.context.stores.activeCall.get();
    if (!activeCall) {
      return;
    }

    activeCall.clearTransferee();
    this.context.stores.activeCall.set(activeCall);

    const phoneCall = activeCall.conversationItem.content;
    if (!phoneCall.isTransferLive()) {
      return;
    }

    const transfereeParticipant = phoneCall.findParticipantById(agentId);
    if (transfereeParticipant && PhoneCall.isActiveParticipant(transfereeParticipant)) {
      activeCall.conversationItem.content.setParticipantStatus(agentId, PhoneCall.ParticipantStatus.DISCONNECTING);
      this.context.stores.activeCall.setPending(activeCall);
    }

    this.context.gateways.phoneControlsHttp.cancelWarmTransfer(activeCall.customer.id, activeCall.conversationItem.id, {
      agentId,
      routingGroupId,
    });
  }
}
