import ItemLinkContent from 'models/item_link_content';
import Task from 'models/task';

export function getIsCommentingEnabledForTask(stores, { taskId }) {
  const item = stores.conversationHistory.findBy({ id: taskId });
  let task;
  if (item && item.content instanceof Task) {
    task = item.content;
  } else if (item && item.content instanceof ItemLinkContent && item.content.originalContent instanceof Task) {
    task = item.content.originalContent;
  }
  const taskInfo = stores.taskInfo.findBy({ id: taskId });

  return !!(task && !task.isClosed() && taskInfo);
}
