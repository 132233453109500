import React from 'react';
import styled from 'styled-components';

import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import FromField from './from_field';
import { Header, HeaderText } from 'components/composer/shared/composer_new';
import ToField from './to_field';

export default function SMSHeader({ editorProps, isInvalidNumber, phoneNumbers }) {
  return (
    <Header>
      <Left>
        <HeaderText color="green400">SMS</HeaderText>
        <ToField isInvalidNumber={isInvalidNumber} phoneNumbers={phoneNumbers} />
      </Left>
      <Right>
        <Emoji {...editorProps} />
        <Attachment alwaysShow />
        <FromField />
      </Right>
    </Header>
  );
}

const Left = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;
const Right = styled.div`
  align-items: center;
  display: flex;
`;
