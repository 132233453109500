import {
  getConversationContext,
  getSuggestedReplyContext,
  snowplowTypes,
  getCompositionContextFromComposition,
} from 'scripts/lib/analytics/domain/snowplow';

const MAX_MESSAGE_LENGTH = 10000;
export default class TrackSuggestedReplySent {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationId, composition, messageSent, appliedSuggestedReply }) {
    if (!customerId || !conversationId || !appliedSuggestedReply) {
      return;
    }

    const similarityPercentage = relativeSimilarity(appliedSuggestedReply.text, messageSent);
    const snowplowContexts = [
      getConversationContext({ conversationId, customerId }),
      getSuggestedReplyContext(appliedSuggestedReply.id),
      getCompositionContextFromComposition(composition),
    ];
    this.context.analytics.trackSnowplow(
      snowplowTypes.EVENT_SUGGESTED_REPLY_SENT,
      { similarityPercentage },
      snowplowContexts
    );
  }
}

function relativeSimilarity(original, modified) {
  if (modified.length >= MAX_MESSAGE_LENGTH) {
    return 0;
  }

  if (original.toLowerCase() === modified.toLowerCase()) {
    return 1;
  }

  return 1 - levenshtein(original, modified) / Math.max(original.length, modified.length);
}

function levenshtein(original, modified) {
  const m = original.length;
  const n = modified.length;

  let prevRow = Array(n + 1)
    .fill(0)
    .map((_, idx) => idx);
  let currRow = Array(n + 1).fill(0);

  for (let i = 1; i <= m; i++) {
    currRow[0] = i;
    for (let j = 1; j <= n; j++) {
      const cost = original[i - 1] === modified[j - 1] ? 0 : 1;
      currRow[j] = Math.min(currRow[j - 1] + 1, prevRow[j] + 1, prevRow[j - 1] + cost);
    }
    [prevRow, currRow] = [currRow, prevRow];
  }

  return prevRow[n];
}
