import _ from 'lodash';

import convertAgentIdsToMentions from 'actions/composer/shared/convert_agent_ids_to_mentions';
import { createContent } from 'scripts/domain/factories/conversation_item/create_item_content';
import createTaskItem from 'scripts/domain/factories/conversation_item/create_task_item';
import Err from 'models/err';
import SubmitComposition, { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';
import TaskInfo from 'models/task_info';
import UpdateAgentTaskRead from 'actions/task/update_agent_task_read';

export default class AddTask extends SubmitComposition {
  // never show the reopen modal
  willShowReopenConversationModal() {
    return false;
  }

  sendMessage(conversationUpdates, taskItem) {
    this.context.gateways.task.add(this.currentAgent.id, {
      id: taskItem.id,
      customerId: this.currentCustomerId,
      conversation: conversationUpdates,
      conversationItem: getOutgoingItemDto(taskItem),
    });
  }

  addItemToConversation() {
    // don't add the item to the conversation
  }

  createOutgoingItem(composition, composedContent) {
    let compositionErrors = composition.validateAttachmentStatus();
    compositionErrors = compositionErrors.concat(composition.validateTotalAttachmentSize());

    const disabledRoutingGroupIds = _.map(
      _.filter(this.context.stores.routingGroups.findAll(), rg => !!rg.disabled),
      rg => rg.id
    );
    if (_.includes(disabledRoutingGroupIds, composition.content.assignee.routingGroupId)) {
      compositionErrors = compositionErrors.concat([
        new Err({
          attr: 'assignee',
          code: Err.Code.INVALID,
          detail: 'assigned inbox is disabled',
        }),
      ]);
    }

    if (compositionErrors.length) {
      return { compositionErrors };
    }

    let content = convertAgentIdsToMentions(this.context, composedContent);

    let taskContent = createContent(composition, { ...composition.content, ...content });
    let taskItem = createTaskItem({
      agentProfile: this.currentAgent,
      content: taskContent,
      customerId: composition.customerId,
      snippetIds: composition.snippetIds,
      relatedSnippetIds: composition.relatedSnippetIds,
    });

    let taskInfo = TaskInfo.create({ id: taskItem.id });
    this.context.stores.taskInfo.setPendingNew(taskInfo);

    return { compositionErrors: [], conversationItem: taskItem };
  }

  afterSend({ id }) {
    this.context.executeAction(UpdateAgentTaskRead, id);
  }
}
