export default function configureSharedBindings(rootBinding) {
  return {
    accounts: rootBinding.sub('accounts'),
    activeCall: rootBinding.sub('activeCall'),
    activeChats: rootBinding.sub('activeChats'),
    agents: rootBinding.sub('agents'),
    agentProfiles: rootBinding.sub('agentProfiles'),
    agentRead: rootBinding.sub('agentRead'),
    agentStatus: rootBinding.sub('agentStatus'),
    agentStatuses: rootBinding.sub('agentStatuses'),
    answerPanelState: rootBinding.sub('answerPanelState'),
    answerSuggestions: rootBinding.sub('answerSuggestions'),
    apiTokens: rootBinding.sub('apiTokens'),
    appFeatures: rootBinding.sub('appFeatures'),
    channelConfiguration: rootBinding.sub('channelConfiguration'),
    communicationQueues: rootBinding.sub('communicationQueues'),
    companyProfile: rootBinding.sub('companyProfile'),
    compositions: rootBinding.sub('compositions'),
    connectionState: rootBinding.sub('connectionState'),
    conversationWorkflow: rootBinding.sub('conversationWorkflow'),
    currentAgent: rootBinding.sub('currentAgent'),
    currentLocation: rootBinding.sub('currentLocation'),
    customerMerge: rootBinding.sub('customerMerge'),
    customerProfileDef: rootBinding.sub('customerProfileDef'),
    customerUnlink: rootBinding.sub('customerUnlink'),
    customers: rootBinding.sub('customers'),
    externalActionForm: rootBinding.sub('externalActionForm'),
    inbox: rootBinding.sub('inbox'),
    kbVariables: rootBinding.sub('kbVariables'),
    liveboard: rootBinding.sub('liveboard'),
    locationHistory: rootBinding.sub('locationHistory'),
    modal: rootBinding.sub('modal'),
    notifications: rootBinding.sub('notifications'),
    performExternalAction: rootBinding.sub('performExternalAction'),
    personalInboxCounts: rootBinding.sub('personalInboxCounts'),
    phoneCall: rootBinding.sub('phoneCall'),
    report: rootBinding.sub('report'),
    router: rootBinding.sub('router'),
    routingGroups: rootBinding.sub('routingGroups'),
    rules: rootBinding.sub('rules'),
    scheduledReport: rootBinding.sub('scheduledReport'),
    searchResults: rootBinding.sub('searchResults'),
    selectedKbItem: rootBinding.sub('selectedKbItem'),
    snippetComposition: rootBinding.sub('snippetComposition'),
    snippets: rootBinding.sub('snippets'),
    snippetRevisions: rootBinding.sub('snippetRevisions'),
    snippetSearchResult: rootBinding.sub('snippetSearchResult'),
    systemConfiguration: rootBinding.sub('systemConfiguration'),
    teamInboxCounts: rootBinding.sub('teamInboxCounts'),
    topics: rootBinding.sub('topics'),
    topicSuggestions: rootBinding.sub('topicSuggestions'),
    universalSearchResults: rootBinding.sub('universalSearchResults'),
    userCredential: rootBinding.sub('userCredential'),
    userEditor: rootBinding.sub('userEditor'),
    users: rootBinding.sub('users'),
    viewState: rootBinding.sub('viewState'),
    voiceConfiguration: rootBinding.sub('voiceConfiguration'),
  };
}
