import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('PhoneCallback')
  .attr('type', 'PHONE_CALLBACK');

Factory.define('PhoneCallbackWithDefaults')
  .extend('PhoneCallback')
  .attr('phoneNumber', '415-989-8909')
  .attr('requestedTime', '2015-03-25T13:15:00-07:00');

Factory.define('PhoneCallbackItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function (content) {
    return Factory.attributes('PhoneCallback', content);
  });

Factory.define('PhoneCallbackItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function (content) {
    return Factory.attributes('PhoneCallbackWithDefaults', content);
  });

