import _ from 'lodash';

import ContextNames from './context_names';

/**
 * Creates a basic action class for entity update
 * @param EntityClass - the class of entity being updated
 * @param update(entity, attrs)      - updates the entity with attributes
 *                                     if not specified `EntityClass.prototype.update(attrs)` will be used
 * @returns a class for entity update action
 */
export default function createUpdateAction(EntityClass, { update } = {}) {
  let { storeName, gatewayName } = ContextNames.fromModelClass(EntityClass);

  return class {
    constructor(context) {
      this.context = context;
    }

    run(attrs) {
      _.forEach(attrs, function(value, key) {
        if (typeof value === 'string') {
          attrs[key] = value.trim();
        }
      });

      let validationErrors = this._getValidationErrors(attrs);
      if (validationErrors.length) {
        this.context.stores[storeName].setErrors(attrs.id, validationErrors);
      } else {
        let entity = this.context.stores[storeName].find(attrs.id);
        if (update) {
          update(entity, attrs);
        } else {
          entity.update(attrs);
        }
        this.context.stores[storeName].setPending(entity);
        this.context.gateways[gatewayName].update(attrs.id, _.omit(attrs, 'id'));
      }

      if (this.after) {
        this.after(attrs, validationErrors);
      }
    }

    /**
     * Returns validation errors for the specified attributes.
     * @returns an array of Err objects or an empty array if there were no errors.
     *
     * Override in subclasses if validation rules are different or need to be extended.
     */
    _getValidationErrors(attrs) {
      throw new Error('_getValidationErrors not implemented');
    }
  };
}
