import ReopenConversation from 'models/modal/reopen_conversation';

export default class ShowReopenConversationModal {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.modal.set(ReopenConversation.create());
  }
}
