import _ from 'lodash';

import analytics from 'scripts/lib/analytics';
import CompositionContentType from 'models/composition/composition_content_type';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import PerformExternalAction from 'models/perform_external_action';
import removeComposition from 'actions/composition/lib/remove_composition';

export default class GetExternalActionForm {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, action }) {
    const compositions = getCompositionsStore(this.context, customerId).findAll();
    const formComposition = _.find(compositions, c => c.contentType() === CompositionContentType.EXTERNAL_FORM);
    if (formComposition) {
      removeComposition(this.context, formComposition);
    }

    this.context.stores.externalActionForm.remove();
    this.context.stores.performExternalAction.remove();
    this.context.stores.externalActionForm.setLoading();

    this.context.gateways.externalCustomerLookupActions.fetchForm(customerId, action.integrationId, action);

    this.context.stores.performExternalAction.set(PerformExternalAction.create({ customerId, action }));

    analytics.track('Action Opened', {
      actionName: _.get(action, 'name'),
      customerId,
      conversationId: this.getCurrentConversationId(customerId),
      integrationId: action && action.integrationId,
      integrationType: this.context.stores.integrations.findBy({ id: action.integrationId })?.type,
    });
  }

  getCurrentConversationId(customerId) {
    let currentLocation = this.context.stores.currentLocation.get();
    return currentLocation.customerId === customerId ? currentLocation.currentConversationId : null;
  }
}
