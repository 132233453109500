import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import COLORS from 'components/common/colors';
import connect from 'components/lib/connect';
import CreateProfileAndLinkItem from 'actions/conversation_item/pin_item/create_profile_and_link_item';
import ErrorTooltip from 'components/customer/profile/contact_info/error_tooltip';
import Input from 'components/common/input';
import LoadingSpinner from 'components/lib/loading_spinner';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import SelectRelationshipMenu from './select_relationship_menu';
import UnloadCustomer from 'actions/customer/unload_customer';
import withShortcuts from 'components/keyboard_shortcuts/with_shortcuts';

export function AddCustomerContent({
  email,
  name,
  phone,
  labelId,

  onKeyDown,
  onUpdateEmail,
  onUpdateName,
  onUpdatePhone,
  onUpdateLabelId,

  errors,
}) {
  return (
    <div className="confirmCustomerModal-body" onKeyDown={onKeyDown} tabIndex="0">
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Name</div>
        <ErrorTooltip data-aid="confirmCustomerModal-errorTooltip" errors={errors['name']} position="right">
          <Input
            autoFocus
            className="confirmCustomerModal-body-input"
            onChange={onUpdateName}
            placeholder="Name"
            value={name}
            wrapperClassName="confirmCustomerModal-body-inputWrapper"
          />
        </ErrorTooltip>
      </div>
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Email</div>
        <ErrorTooltip data-aid="confirmCustomerModal-errorTooltip" errors={errors['emails']} position="right">
          <Input
            className="confirmCustomerModal-body-input"
            onChange={onUpdateEmail}
            placeholder="Email"
            value={email}
            wrapperClassName="confirmCustomerModal-body-inputWrapper"
          />
        </ErrorTooltip>
      </div>
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Phone #</div>
        <ErrorTooltip data-aid="confirmCustomerModal-errorTooltip" errors={errors['phones']} position="right">
          <Input
            className="confirmCustomerModal-body-input"
            onChange={onUpdatePhone}
            placeholder="Phone"
            value={phone}
            wrapperClassName="confirmCustomerModal-body-inputWrapper"
          />
        </ErrorTooltip>
      </div>
      {renderRelationship()}
    </div>
  );

  function renderRelationship() {
    return (
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Relationship</div>
        <SelectRelationshipMenu
          className="confirmCustomerModal-body-select"
          labelId={labelId}
          updateRelationship={onUpdateLabelId}
        />
      </div>
    );
  }
}

AddCustomerContent.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  labelId: PropTypes.string,

  onKeyDown: PropTypes.func,
  onUpdateLabelId: PropTypes.func,
  onUpdateEmail: PropTypes.func.isRequired,
  onUpdateName: PropTypes.func.isRequired,
  onUpdatePhone: PropTypes.func.isRequired,

  errors: PropTypes.object.isRequired,
};

const AddCustomerContentWithShortcuts = withShortcuts(AddCustomerContent, [
  { key: 'enter', handler: props => props.onSubmit() },
  { key: 'escape', handler: props => props.onCancel() },
]);

export class AddCustomerModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleUpdateEmail = this.handleUpdateField.bind(this, 'email');
    this.handleUpdateName = this.handleUpdateField.bind(this, 'name');
    this.handleUpdatePhone = this.handleUpdateField.bind(this, 'phone');
    this.handleUpdateLabelId = this.handleUpdateLabelId.bind(this);

    this.state = { isSubmitted: false, email: '', name: '', phone: '', labelId: null };
  }

  componentDidUpdate() {
    if (this.state.isSubmitted && !this.props.isPending && _.isEmpty(this.props.errors)) {
      this.props.onClose();
    }
  }

  render() {
    const contentProps = {
      errors: this.props.errors,

      onCancel: this.props.onCancel,
      onKeyDown: this.handleKeyDown,
      onSubmit: this.handleClickAdd,

      onUpdateEmail: this.handleUpdateEmail,
      onUpdateName: this.handleUpdateName,
      onUpdatePhone: this.handleUpdatePhone,
      onUpdateLabelId: this.handleUpdateLabelId,
    };

    return (
      <PortalModalWrapper>
        <ModalCard className="confirmCustomerModal" onClose={this.props.onCancel}>
          <div className="confirmCustomerModal-header">Add a New Customer</div>
          <AddCustomerContentWithShortcuts {...this.state} {...contentProps} />
          <div className="confirmCustomerModal-footer">
            <Button
              blurOnClick
              buttonType={ButtonTypes.SECONDARY}
              className="confirmCustomerModal-cancelButton"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
            <Button
              blurOnClick
              buttonType={ButtonTypes.PRIMARY}
              className="confirmCustomerModal-confirmButton"
              onClick={this.handleClickAdd}
            >
              {this.state.isSubmitted && this.props.isPending ? (
                <LoadingSpinner color={COLORS.white} position="relative" size="16px" width="2px" />
              ) : (
                'Add'
              )}
            </Button>
          </div>
        </ModalCard>
      </PortalModalWrapper>
    );
  }

  handleUpdateField(field, evt) {
    this.setState({ [field]: evt.target.value });
  }

  handleUpdateLabelId(evt) {
    this.setState({ labelId: evt.value });
  }

  handleClickAdd() {
    this.setState({ isSubmitted: true });
    this.props.onAddCustomer({
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      labelId: this.state.labelId,
    });
  }

  handleKeyDown(evt) {
    if (evt.key === 'Enter') {
      this.handleClickAdd();
    } else if (evt.key === 'Escape') {
      this.props.onCancel();
    }
  }
}

AddCustomerModal.propTypes = {
  errors: PropTypes.object,
  isPending: PropTypes.bool,
  onAddCustomer: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, { newCustomerId }) {
  const profileProvider = getProvider(`customers.${newCustomerId}.profile`);
  const errors = getErrorsByField(profileProvider.getErrors());

  return {
    errors,
    isPending: profileProvider.isPending(),
  };
}

function mapExecuteToProps(executeAction, { itemId, newCustomerId, onCancel, originalCustomerId }) {
  return {
    onAddCustomer: ({ email, name, phone, labelId }) => {
      executeAction(CreateProfileAndLinkItem, {
        itemId,
        profileAttrs: {
          email,
          id: newCustomerId,
          name,
          phone,
          labelId,
        },
        originalCustomerId,
      });
    },
    onCancel: () => {
      executeAction(UnloadCustomer, newCustomerId);
      onCancel();
    },
  };
}

function getErrorsByField(errors) {
  let errorsObj = {};
  _.forEach(errors, err => {
    const field = err.attr.split('.')[0];
    const existing = errorsObj[field];
    errorsObj[field] = existing ? existing.concat(err) : [err];
  });
  return errorsObj;
}

const AddCustomerModalContainer = connect(mapStateToProps, mapExecuteToProps)(AddCustomerModal);
export default AddCustomerModalContainer;
