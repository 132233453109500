import PropTypes from 'prop-types';
import React from 'react';

import StackContainer from 'components/common/containers/stack_container.jsx';
import Chip from 'components/common/chip.jsx';
import Tooltip from 'components/common/tooltip';

export default function SearchPills({ onDeleteItem, selectedItems }) {
  if (!selectedItems.length) {
    return null;
  }

  return (
    <div className="universalSearch-selectedPills">
      {selectedItems.map(item => (
        <SearchPill id={item.id} key={item.id} label={item.label} onDelete={onDeleteItem} />
      ))}
    </div>
  );
}

SearchPills.propTypes = {
  onDeleteItem: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export function SearchPill({ id, label, onDelete }) {
  return (
    <StackContainer inset="none" stack="small">
      <Tooltip message={label} position="right">
        <Chip label={label} onDelete={onClickDelete} />
      </Tooltip>
    </StackContainer>
  );

  function onClickDelete(evt) {
    evt.preventDefault();
    onDelete(id);
  }
}

SearchPill.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};
