import createModel, { prop } from './lib/create_model';
import FbMessageIncomingAttachment from './fb_message_incoming_attachment';
import FBMessageExternalLink from './fb_message_external_link';
import FbMessengerUser from './fb_messenger_user';
import FbPage from './fb_page';
import redactAttachment from './attachment_redact';

export const FbMessage = createModel({
  modelName: 'FbMessage',
  properties: {
    mid: String,
    text: String,
    attachments: [FbMessageIncomingAttachment],
    links: [FBMessageExternalLink],
  },

  redactAttachment(id) {
    redactAttachment(this.attachments, id);
  },
});

export default createModel({
  modelName: 'FbMessageIncoming',
  properties: {
    hasRedactedPaymentCardNumber: Boolean,
    message: prop(FbMessage).isRequired,
    recipient: FbPage,
    sender: FbMessengerUser,
    timestamp: Number, // preserving the Facebook timestamp, we probably won't care about it in UI anyway
    isRedacted: Boolean,
  },

  getMessageText() {
    return this.message.text;
  },

  redactAttachment(attachmentId) {
    this.message.redactAttachment(attachmentId);
  },
});
