import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function InstagramMetadataIcon() {
  return (
    <div className="itemMetadata-instagramIcon itemMetadata-fontawesome">
      <FontAwesomeIcon icon={faInstagram} />
    </div>
  );
}
