import { AccountType } from 'models/account';
import CloseCurrentComposition from 'actions/composition/close_current_composition';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import { addPendingRequestIdForConversation } from 'actions/composition/lib/update_conversation_workflow';
import { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';
import InteractionType from 'models/interaction_type';
import ItemId from 'models/item_id';
import { OutgoingChatOperation } from 'models/chat_message';
import SecureDataRequest, {
  SecureDataRequestMetadata,
  SecureDataRequestStatuses,
  SecureDataRequestStatusEvent,
  SecureDataRequestType,
} from 'models/secure_data_request';

export default class SendCreditCardRequest {
  constructor(context) {
    this.context = context;
  }

  run() {
    const currentAgent = this.context.stores.currentAgent.get();
    const currentLocation = this.context.stores.currentLocation.get();
    const { currentCompositionId, customerId } = currentLocation;
    const customerStores = this.context.stores.customers.storesFor(customerId);
    const activeSession = this.context.stores.activeSessions.findBy({ customer: { id: customerId } });
    const vsgAccount = this.context.stores.accounts.findBy({ type: AccountType.VGS });
    if (!vsgAccount) {
      throw new Error('vgs is not configured');
    }

    if (!activeSession) {
      throw new Error('no customer sessions found');
    }

    const chatSession = activeSession.queueItem.getSessionByType(InteractionType.CHAT);
    if (!chatSession) {
      throw new Error('no chat session found');
    }

    const creditCardComposition = this.context.stores.compositions.find(currentCompositionId);
    const { conversationId } = activeSession.queueItem;

    const creditCardRequestItem = this.createCreditCardRequestItem(
      creditCardComposition.content.amount,
      creditCardComposition.content.memo,
      currentAgent.id,
      conversationId,
      customerId
    );

    customerStores.activeSecureDataRequests.add(creditCardRequestItem);
    this.addItemToHistory(conversationId, creditCardRequestItem);

    const payload = {
      outgoingCommunication: {
        id: creditCardRequestItem.id,
        customerId,
        conversation: { id: conversationId },
        conversationItem: getOutgoingItemDto(creditCardRequestItem),
      },
      operation: OutgoingChatOperation.REQUEST_CREDIT_CARD,
    };

    let correlationId;
    if (this.context.stores.appFeatures.get().isEnabled('enableOutgoingChatHttp')) {
      const obj = this.context.gateways.outgoingChatHttp.update(
        {
          agentId: currentAgent.id,
          itemId: creditCardRequestItem.id,
        },
        payload
      );
      correlationId = obj.correlationId;
    } else {
      const obj = this.context.gateways.outgoingChat.update(currentAgent.id, creditCardRequestItem.id, payload);
      correlationId = obj.correlationId;
    }
    addPendingRequestIdForConversation(this.context, conversationId, correlationId);
    this.context.executeAction(CloseCurrentComposition);
  }

  addItemToHistory(conversationId, creditCardRequestItem) {
    this.context.stores.conversationHistory.add(creditCardRequestItem);
    this.context.stores.itemIds.addOrReplace(
      new ItemId({
        id: creditCardRequestItem.id,
        conversationId,
        timestamp: creditCardRequestItem.timestamp,
      })
    );
  }

  createCreditCardRequestItem(amount, memo, agentId, conversationId, customerId) {
    const requestingStatusEvent = new SecureDataRequestStatusEvent({
      requestStatus: SecureDataRequestStatuses.REQUESTING,
    });
    const monetaryUnit = Math.round(amount * 100);
    return ConversationItem.create({
      conversationId,
      customerId,
      content: new SecureDataRequest({
        secureDataType: SecureDataRequestType.CREDIT_CARD,
        currentRequestStatus: requestingStatusEvent,
        metadata: new SecureDataRequestMetadata({ monetaryUnit, memo }),
      }),
      initiator: { type: Communicator.AGENT, id: agentId },
    });
  }
}
