import _ from 'lodash';

export default class RequestCompositions {
  constructor(context) {
    this.context = context;
  }

  run({ customerId }) {
    let agentId = this.context.stores.currentAgent.get().id;

    const { compositions } = this.context.stores.customers.storesFor(customerId);
    const localCompositions = _.sortBy(this.context.stores.localCompositions.get(customerId), composition =>
      Date.parse(composition.updatedAt)
    );

    // Not sure if this is correct.
    if (localCompositions.length) {
      compositions.set(_.uniq(localCompositions, composition => composition.contentType()));
    }

    this.context.gateways.composition.request(agentId, customerId);
    compositions.setLoading();
  }
}
