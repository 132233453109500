import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Whatsapp Stroke
 */

export default function WhatsappStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M2.83294 11.6423L2.93969 11.2509L2.74355 10.8957C2.26993 10.0381 2 9.0521 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C6.75054 14 5.59305 13.6192 4.6335 12.9674L4.26327 12.7159L3.82907 12.8245L2.41406 13.1782L2.83294 11.6423ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 9.22544 1.31489 10.3773 1.86818 11.3792L1.22465 13.7388C1.12409 14.1075 1.45755 14.4481 1.8283 14.3554L4.0716 13.7946C5.19167 14.5554 6.54392 15 8 15ZM10.708 11.3358C10.6449 11.4093 10.5584 11.4587 10.463 11.476H10.4626C10.0316 11.5519 8.75938 11.5502 6.76088 9.84697L6.14619 9.23369C4.43863 7.23979 4.44869 5.96479 4.52525 5.53441C4.54251 5.43934 4.59192 5.35306 4.66525 5.28997L5.46369 4.60031C5.54405 4.53045 5.64858 4.49469 5.755 4.50064C5.86141 4.50659 5.96129 4.55379 6.03331 4.63217L6.991 5.66186C7.06422 5.74115 7.10522 5.84479 7.10603 5.9526C7.10684 6.0604 7.06739 6.16465 6.99538 6.24502L6.49794 6.795C6.44369 6.85581 6.40756 6.93055 6.39365 7.01077C6.37973 7.09099 6.38858 7.17349 6.41919 7.24895C6.51938 7.49776 6.86063 7.98619 7.31781 8.47376L7.51688 8.68939C8.00556 9.14552 8.49513 9.48599 8.7445 9.58595C8.82012 9.61659 8.90284 9.62547 8.98326 9.61158C9.06369 9.59769 9.1386 9.56159 9.1995 9.50738L9.75075 9.01064C9.83138 8.93876 9.93594 8.89939 10.0441 8.9002C10.1522 8.901 10.2561 8.94193 10.3357 9.01501L11.3673 9.9705C11.446 10.0424 11.4934 10.1421 11.4994 10.2484C11.5053 10.3547 11.4694 10.4591 11.3992 10.5392L10.708 11.3358Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
