import withInsertTextAfterInline from 'components/text_editor_new/lib/with_insert_text_after_inline';

export const INSERT_TEXT_AFTER_INLINE = 'insert-text-after-inline';

export default function createInsertTextAfterInline() {
  return {
    key: 'insert-text-after-inline',
    withOverrides: withInsertTextAfterInline,
  };
}
