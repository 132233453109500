import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { H1 } from 'components/common/headers';

const UserAuthLayout = props => {
  return (
    <div className={classnames('userAuthLayoutContainer', props.className)}>
      {renderLogo()}
      <div className={classnames('userAuthLayout', props.layoutClassName)}>
        {renderNotification()}
        {props.image ? props.image : <div className="userAuthLayout-titleLogo" />}
        {renderTitle()}
        {renderMessage()}
        {props.children}
      </div>
    </div>
  );

  function renderLogo() {
    let logoClasses = classnames('userAuthLayout-logo', { 'userAuthLayout-logo-active': props.onLogoClick });
    if (props.displayLogo) {
      return <div className={logoClasses} onClick={props.onLogoClick || _.noop} />;
    }
    return null;
  }

  function renderNotification() {
    if (!props.notification) {
      return null;
    }

    return <div className="userAuthLayout-notification">{props.notification}</div>;
  }

  function renderTitle() {
    if (props.title) {
      return (
        <H1 className={classnames('userAuthLayout-title', props.headerClassName)} data-aid="userAuthLayout-title">
          {props.title}
        </H1>
      );
    }
    return null;
  }

  function renderMessage() {
    if (props.message) {
      return <div className="userAuthLayout-message">{props.message}</div>;
    }
    return null;
  }
};

export default UserAuthLayout;

UserAuthLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  displayLogo: PropTypes.bool,
  image: PropTypes.any,
  layoutClassName: PropTypes.string,
  message: PropTypes.string,
  notification: PropTypes.string,
  onLogoClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
