import createModel, { prop } from 'models/lib/create_model';
import moment from 'moment';

const AgentPresence = createModel({
  modelName: 'AgentPresence',
  properties: {
    occurredAt: prop(String).isRequired,
  },

  statics: {
    create({ occurredAt } = {}) {
      return new this({ occurredAt: occurredAt || moment().toISOString() });
    },
  },
});

export default AgentPresence;
