import hasLiveConnection from 'actions/phone_call/lib/has_live_connection';
import OfferedCallNotification from 'models/notification/offered_call_notification';
import OfferedWarmTransferNotification from 'models/notification/offered_warm_transfer_notification';
import PhoneCall from 'models/phone_call';
import ShowConversationSystemNotification from 'actions/notification/show_conversation_system_notification';

export default class ShowIncomingCallSystemNotification {
  constructor(context) {
    this.context = context;
  }

  run() {
    let activeCall = this.context.stores.activeCall.get();
    let hasLivePhoneConnection = activeCall && hasLiveConnection(this.context, { activeCall });
    if (!activeCall || !hasLivePhoneConnection) {
      return;
    }

    let currentAgentId = this.context.stores.currentAgent.get().id;
    let incomingCallType = activeCall.conversationItem.content.getIncomingCallType(currentAgentId);
    let notification;
    // Blind Transfer also uses OfferedCallNotification
    if (incomingCallType === PhoneCall.IncomingCallType.WARM_TRANSFER) {
      notification = OfferedWarmTransferNotification.create({
        id: activeCall.conversationItem.id,
        conversationId: activeCall.conversationId,
        conversationItem: activeCall.conversationItem,
        initiator: activeCall.initiator,
        profile: activeCall.customer,
      });
    } else {
      notification = OfferedCallNotification.create({
        id: activeCall.conversationItem.id,
        conversationId: activeCall.conversationId,
        conversationItem: activeCall.conversationItem,
        profile: activeCall.customer,
      });
    }

    this.context.stores.notifications.addOrReplace(notification);
    this.context.executeAction(ShowConversationSystemNotification, notification);
  }
}
