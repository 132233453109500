import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import CompositionAttachmentsContainer from 'components/customer/composition/lib/attachments/composition_attachments_container';
import ComposerContext from 'components/composer/contexts/composer_context';
import { useOnUnmount } from 'components/hooks/lifecycle_hooks';

export default function Attachments({ scrollerRef }) {
  useScrollDownToNewAttachments(scrollerRef);

  const context = useContext(ComposerContext);
  return <CompositionAttachmentsContainer compositionId={context.composition.id} />;
}

Attachments.propTypes = {
  scrollerRef: PropTypes.shape({
    scrollHeight: PropTypes.number,
    scrollTop: PropTypes.number,
  }),
};

/**
 * Keeps track of how many attachments the composition has. When new uploads are added, it scrolls down
 * to the bottom.
 */
function useScrollDownToNewAttachments(scrollerRef) {
  const scrollTimeoutRef = useRef(null);
  const composerContext = useContext(ComposerContext);
  const composition = composerContext.composition;
  const [previousAttachmentsLength, setAttachmentsLength] = useState(composition.attachments.length);

  useEffect(() => {
    if (composition.attachments.length > previousAttachmentsLength) {
      // We can't set the scrollTop immediately because the uploads _may_ not have appeared yet..
      // so we might not scroll down all the way.
      scrollTimeoutRef.current = setTimeout(() => {
        scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
      }, 200);

      setAttachmentsLength(composition.attachments.length);
    }
  }, [composition, scrollerRef, setAttachmentsLength]);

  useOnUnmount(() => clearTimeout(scrollTimeoutRef.current), [scrollTimeoutRef]);
}
