import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class SnippetService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  fetch(payload, callback, path, { orgId, snippetId }) {
    let snippet = _.find(this.getDatabase(orgId).snippets, { id: snippetId });
    if (!snippet) {
      callback(null, { status: 404, statusText: statusText(404) });
      return;
    }

    callback(null, { status: 200, statusText: statusText(200), response: snippet });
  }

  findAll(payload, callback, path, { orgId }, query) {
    let snippets = this.getDatabase(orgId).snippets;

    if (!query['f.ids']) {
      callback(null, {
        status: 200,
        statusText: statusText(200),
        response: snippets,
      });
      return;
    }

    let ids = query['f.ids'].split(',');
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: _.filter(snippets, snippet => _.includes(ids, snippet.id)) || [],
    });
  }

  add(payload, callback, path, { orgId }) {
    let snippets = this.getDatabase(orgId).snippets;
    snippets.push(payload);
    callback(null, { status: 204, statusText: statusText(204) });
  }

  update(attrs, callback, path, { orgId, snippetId }) {
    let snippet = _.find(this.getDatabase(orgId).snippets, { id: snippetId });
    _.assign(snippet, attrs);
    callback(null, { status: 204, statusText: statusText(204) });
  }

  delete(teamId, callback, path, { orgId, snippetId }) {
    _.remove(this.getDatabase(orgId).snippets, { id: snippetId });
    this._pubsub.publish(`v1/orgs/${orgId}/snippets/${snippetId}/event/delete`, {});
    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/snippets': { GET: this.findAll, POST: this.add },
        '/api/v1/orgs/:orgId/snippets/:snippetId': { GET: this.fetch, PATCH: this.update, DELETE: this.delete },
      },
      this
    );
  }
}
