import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import ChangeAssignment from 'actions/conversation/change_assignment';
import connect from 'components/lib/connect';
import { getActiveConversationId, getConversationAssignee } from 'actions/conversation/lib/conversation_helpers';
import { getAssignee, renderAvatarOrInboxIcon } from './current_assignee';
import getAssigneeDetails from 'components/lib/assignment_menu/lib/get_assignee_details';
import RemoveCustomerAssignee from 'actions/customer/remove_customer_assignee';

export class CustomerAssignee extends PureComponent {
  constructor(props) {
    super(props);
    this.handleAssignConversation = this.handleAssignConversation.bind(this);
  }

  handleAssignConversation() {
    const { customerAssignee, onAssignConversation } = this.props;
    onAssignConversation(customerAssignee.agentId, customerAssignee.groupId);
  }

  render() {
    const { canManageCustomerAssignee, className, customerAssignee } = this.props;
    const displayName = getAssignee({ agentName: customerAssignee.agentName, groupName: customerAssignee.groupName });

    if (!canManageCustomerAssignee && _.isEmpty(customerAssignee)) {
      return null;
    }

    return (
      <div className={className}>
        {renderAvatarOrInboxIcon(
          customerAssignee.groupName,
          customerAssignee.agentName,
          customerAssignee.avatarImage,
          className
        )}
        <div className={`${className}-text`}>
          <div className={`${className}-text-label`}>
            {customerAssignee.groupName && !customerAssignee.agentName ? 'Hero Inbox' : 'Dedicated Hero'}
          </div>
          <div className={`${className}-text-name`} title={displayName}>
            {displayName}
          </div>
        </div>
        {this.renderAssigneeButtons()}
      </div>
    );
  }

  renderAssigneeButtons() {
    const {
      canManageCustomerAssignee,
      canReassignConversation,
      className,
      customerAssignee,
      onRemoveCustomerAssignee,
    } = this.props;

    if (_.isEmpty(customerAssignee)) {
      return null;
    }

    return (
      <div className={`${className}-customerAssigneeButtons`}>
        {canReassignConversation ? (
          <Button
            buttonType={ButtonTypes.SECONDARY}
            className={`${className}-customerAssigneeButtons-button-assignConversation`}
            onClick={this.handleAssignConversation}
          >
            Reassign to Hero
          </Button>
        ) : null}
        {canManageCustomerAssignee ? (
          <Button
            buttonType={ButtonTypes.SECONDARY}
            className={`${className}-customerAssigneeButtons-button-removeCustomerAssignee`}
            onClick={onRemoveCustomerAssignee}
          >
            Remove
          </Button>
        ) : null}
      </div>
    );
  }
}

CustomerAssignee.propTypes = {
  canManageCustomerAssignee: PropTypes.bool,
  canReassignConversation: PropTypes.bool,
  className: PropTypes.string,
  customerAssignee: PropTypes.shape({
    agentId: PropTypes.string,
    agentName: PropTypes.string,
    avatarImage: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
  }),
  onAssignConversation: PropTypes.func,
  onRemoveCustomerAssignee: PropTypes.func,
};

const CustomerAssigneeContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerAssignee);

CustomerAssigneeContainer.propTypes = {
  onClose: PropTypes.func,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const conversationProvider = getProvider('conversations');
  const conversationId = getActiveConversationId(conversationProvider);
  const conversation = conversationProvider.findBy({ id: conversationId });

  const conversationAssignee = getConversationAssignee(conversation);
  const customerAssignee = getCustomerAssignee();
  const canReassignConversation = isReassignConversationEnabled();

  return {
    canManageCustomerAssignee: isFeatureEnabled('customerAssigneeManagement') && isFeatureEnabled('dedicatedHero'),
    canReassignConversation,
    customerAssignee,
  };

  function getCustomerAssignee() {
    let assignee = getProvider('assignee').get() || {};
    return getAssigneeDetails(
      { getProvider, isFeatureEnabled },
      { agentId: assignee.agentId, groupId: assignee.routingGroupId }
    );
  }

  function isReassignConversationEnabled() {
    const { agentId, groupId } = customerAssignee;
    if (!groupId) {
      return false;
    }
    if (conversationAssignee.agentId || conversationAssignee.groupId) {
      return !(conversationAssignee.agentId === agentId && conversationAssignee.groupId === groupId);
    }
    return false;
  }
}

function mapExecuteToProps(executeAction, { onClose }) {
  return {
    onAssignConversation: (agentId, routingGroupId) => {
      executeAction(ChangeAssignment, {
        assignee: { agentId, routingGroupId },
      });
      if (onClose) {
        onClose();
      }
    },
    onRemoveCustomerAssignee: () => {
      executeAction(RemoveCustomerAssignee);
      if (onClose) {
        onClose();
      }
    },
  };
}

export default CustomerAssigneeContainer;
