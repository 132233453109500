import React from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import InternetIcon from 'components/common/icons/stroke/internet-stroke';
import Notification from './notification';

const StyledMessage = styled.div`
  align-items: center;
  color: black;
  display: flex;
  font-weight: 600;
`;

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledInternetIcon = styled(InternetIcon)`
  margin-right: 16px;
  width: 16px;
`;

const HelpLink = styled.a`
  color: ${p => p.theme.colors.black};
  padding-left: 3px;
  text-decoration: underline;
`;

const DisconnectedNotification = ({ id, onDismiss }) => {
  return (
    <Notification contentClasses="warningNotification" notificationId={id} onClick={onDismiss}>
      <StyledContent>
        <StyledMessage>
          <StyledInternetIcon />
          Your internet connection is unstable and Gladly may not work as expected. To troubleshoot, visit our
          <HelpLink
            href="https://help.gladly.com/docs/en/unstable-internet-connection"
            rel="noopener noreferrer"
            target="_blank"
          >
            Help Docs
          </HelpLink>
          .
        </StyledMessage>
        <Button buttonType={ButtonTypes.SECONDARY} contrast onClick={onDismiss}>
          Dismiss
        </Button>
      </StyledContent>
    </Notification>
  );
};

export default DisconnectedNotification;
