import OptionalReloadNotification from 'models/notification/optional_reload_notification';

export default class HideOptionalReloadNotification {
  constructor(context) {
    this.context = context;
  }

  run() {
    let optionalReloadNotifications = this.context.stores.notifications
      .findAll()
      .filter(n => n instanceof OptionalReloadNotification);
    optionalReloadNotifications.forEach(n => this.context.stores.notifications.remove(n.id));
  }
}
