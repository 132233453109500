import _ from 'lodash';
import createModel from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

var HotelReservation = createModel({
  modelName: 'HotelReservation',

  properties: {
    name: String,
    phoneNumber: String,
    hotel: String,
    guests: String,
    arrival: String,
    departure: String,
    email: String,
    roomRate: String,
    confirmationNumber: String,
    total: String,
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },
  },
});

export default HotelReservation;
