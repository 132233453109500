import _ from 'lodash';
import createReactClass from 'create-react-class';
import React from 'react';
import classnames from 'classnames';

const Avatar = createReactClass({
  render() {
    let style = _.merge({ backgroundImage: `url(${this.props.image})` }, this.props.style);
    return <div className={classnames('avatar', this.props.className)} style={style} />;
  },
});

export default Avatar;
