import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import URLSafeBase64 from 'urlsafe-base64';

export default function PlaceholderItem({ itemId }) {
  const lastNum = _.last(URLSafeBase64.decode(itemId));
  const isInbound = !isNaN(lastNum) ? !!(lastNum % 2) : Math.random() >= 0.5;

  const contentClassNames = classnames('placeholderItem-container', {
    'placeholderItem-container-inbound': isInbound,
    'placeholderItem-container-outbound': !isInbound,
  });
  const bodyClassNames = classnames('placeholderItem-content', {
    'placeholderItem-content-inbound': isInbound,
    'placeholderItem-content-outbound': !isInbound,
  });

  return (
    <div className="placeholderItem">
      {isInbound ? <div className="placeholderItem-inboundIcon" /> : null}
      <div className={contentClassNames}>
        <div className="placeholderItem-metadata" />
        <div className={bodyClassNames} />
      </div>
      {!isInbound ? <div className="placeholderItem-outboundIcon" /> : null}
    </div>
  );
}

PlaceholderItem.propTypes = {
  itemId: PropTypes.string.isRequired,
};
