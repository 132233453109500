import createModel from './lib/create_model';

export default createModel({
  modelName: 'TaskEditedContent',

  properties: {
    note: String,
    taskItemId: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
