import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import analytics from 'scripts/lib/analytics';
import { AIActivityComposerContextProvider } from 'components/composer/contexts/ai_activity_composer_context';
import { ComposerContextProvider } from 'components/composer/contexts/composer_context';
import CompositionContentType from 'models/composition/composition_content_type';
import CompositionModel from 'models/composition';
import connect from 'components/lib/connect';
import Err from 'models/err';
import ErrorBoundary from 'components/common/utilities/error_boundary';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import ChatComposerNew from 'components/composer/chat_composer_new';
import CustomChannelComposer from 'components/composer/custom_channel_composer';
import CoBrowseComposer from 'components/composer/cobrowse_composer';
import ConversationMessageComposerNew from 'components/composer/conversation_message_composer_new';
import CreditCardComposer from 'components/composer/credit_card_composer';
import EmailComposer from 'components/composer/email_composer';
import EmailComposerNew from 'components/composer/email_composer_new';
import ExternalFormComposer from 'components/composer/external_form_composer';
import FbMessengerComposerNew from 'components/composer/fb_messenger_composer_new';
import NoteComposer from 'components/composer/note_composer';
import NoteComposerNew from 'components/composer/note_composer_new';
import PhoneCallCompositionContainer from './composition/phone_call_composition_container';
import registerHotkey from 'components/hotkeys/register_hotkey';
import SmsComposerNew from 'components/composer/sms_composer_new';
import TaskComposer from 'components/composer/task_composer';
import TaskComposerNew from 'components/composer/task_composer_new';
import ToggleAnswerPanel from 'actions/answers/toggle_answer_panel';
import ToggleComposerVisibility from 'actions/composer/toggle_composer_visibility';
import useExecuteAction from 'components/hooks/use_execute_action';
import useKeyboardShortcut from 'components/hooks/use_keyboard_shortcut';

const toggleComposerVisibilityHotkey = registerHotkey({
  key: 'alt+h',
  label: 'Show/hide composition',
  group: 'Customer',
});

export function Composition(props) {
  const executeAction = useExecuteAction();
  const toggleComposerVisibility = useCallback(() => executeAction(ToggleComposerVisibility), [executeAction]);
  useKeyboardShortcut(toggleComposerVisibilityHotkey, toggleComposerVisibility);

  const {
    composition,
    currentCompositionId,
    hasChannelConfiguration,
    isAILegacyNoteComposerFeatures,
    isCompositionMinimized,
    isEmailForceBetaEnabled,
    isGclDemoEnabled,
    isLoading,
    isSlateBetaEnabled,
  } = props;
  if (!composition || !hasChannelConfiguration || (!composition && isLoading)) {
    if (currentCompositionId) {
      debouncedLogCurrentCompIdSetWithoutComposition();
    }
    return null;
  }

  const contentType = composition.contentType();
  const style = isCompositionMinimized ? { display: 'none' } : {};

  // Set key on div so composition fully unmounts / remounts when composition changes
  return (
    <div className="customerComposition-shadowWrapper" style={style}>
      <div className={`conversationResponse-composition-container ${_.kebabCase(contentType)}`}>
        <div className="composition" key={composition.id}>
          <ErrorBoundary
            errorMeta={{ errorBoundary: 'Conversation Response Composition', contentType }}
            renderError={({ onResolveError }) => (
              <CompositionError
                composition={composition}
                isSlateBetaEnabled={isSlateBetaEnabled}
                onClickContinue={onResolveError}
              />
            )}
          >
            {getForm()}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );

  function getForm() {
    const {
      compositionErrors,
      onMount,
      onToggleAnswerPanel,
      selectedKbItem,
      selectedKbItemChannelType,
      selectedKbItemId,
      selectedKbItemLanguage,
    } = props;

    const defaultProps = {
      composition,
      compositionErrors,
      isMinimized: isCompositionMinimized,
      onMount,
      selectedKbItem,
      selectedKbItemChannelType,
      selectedKbItemId,
      selectedKbItemLanguage,
      toggleAnswerPanel: onToggleAnswerPanel,
    };

    function withComposerContext(ComposerComponent, composerProps) {
      return (
        <ComposerContextProvider composition={composition}>
          <ComposerComponent {...composerProps} />
        </ComposerContextProvider>
      );
    }

    function withAIActivityAndComposerContext(ComposerComponent, composerProps) {
      return (
        <ComposerContextProvider composition={composition}>
          <AIActivityComposerContextProvider compositionId={composition?.id}>
            <ComposerComponent {...composerProps} />
          </AIActivityComposerContextProvider>
        </ComposerContextProvider>
      );
    }

    switch (contentType) {
      /* Communication composers */
      case CompositionContentType.CHAT:
        return withComposerContext(ChatComposerNew);

      case CompositionContentType.CONVERSATION_MESSAGE:
        return withComposerContext(ConversationMessageComposerNew);

      case CompositionContentType.CREDIT_CARD_REQUEST:
        return withComposerContext(CreditCardComposer);

      case CompositionContentType.CUSTOM_CHANNEL:
        return withComposerContext(CustomChannelComposer);

      case CompositionContentType.EMAIL:
        return isSlateBetaEnabled || isEmailForceBetaEnabled
          ? withAIActivityAndComposerContext(EmailComposerNew)
          : withComposerContext(EmailComposer);

      case CompositionContentType.FB_MESSAGE:
        return withComposerContext(FbMessengerComposerNew);

      case CompositionContentType.SMS:
        return withComposerContext(SmsComposerNew);

      /* Internal Composers */

      case CompositionContentType.CONVERSATION_NOTE:
        if (isSlateBetaEnabled) {
          return withComposerContext(NoteComposerNew);
        } else if (isAILegacyNoteComposerFeatures) {
          return withAIActivityAndComposerContext(NoteComposer);
        } else {
          return withComposerContext(NoteComposer);
        }

      case CompositionContentType.TASK:
        return withComposerContext(isSlateBetaEnabled && !isGclDemoEnabled ? TaskComposerNew : TaskComposer);

      case CompositionContentType.TASK_EDIT:
        return withComposerContext(isSlateBetaEnabled && !isGclDemoEnabled ? TaskComposerNew : TaskComposer, {
          isEditing: true,
        });

      /* Non-Slate compositions */

      case CompositionContentType.COBROWSE_WIDGET:
        return withComposerContext(CoBrowseComposer);

      case CompositionContentType.EXTERNAL_FORM:
        return withComposerContext(ExternalFormComposer);

      /* Draft composition forms */

      case CompositionContentType.PHONE_CALL:
        return <PhoneCallCompositionContainer {...defaultProps} />;

      default:
        return null;
    }
  }
}

Composition.propTypes = {
  composition: PropTypes.instanceOf(CompositionModel),
  compositionErrors: PropTypes.arrayOf(PropTypes.instanceOf(Err)),
  hasChannelConfiguration: PropTypes.bool,
  isCompositionMinimized: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedKbItem: PropTypes.object,
  selectedKbItemId: PropTypes.string,
  selectedKbItemChannelType: PropTypes.string,

  onToggleAnswerPanel: PropTypes.func.isRequired,
};

function CompositionError({ composition, isSlateBetaEnabled, onClickContinue }) {
  useEffect(() => {
    analytics.track('Whoops Screen Shown', {
      componentName: 'composition',
      compositionId: composition.id,
      conversationId: composition.conversationId,
      customerId: composition.customerId,
      contentType: composition.contentType(),
      slateBeta: isSlateBetaEnabled,
    });
  }, [composition, composition.id, isSlateBetaEnabled]);

  return (
    <div className="composition-errorBoundary">
      <div>Whoops! Something went wrong.</div>
      <div className="composition-errorBoundary-continue" onClick={onClickContinue}>
        Click here to continue editing
      </div>
    </div>
  );
}

CompositionError.propTypes = {
  onClickContinue: PropTypes.func.isRequired,
  composition: PropTypes.object,
};

const CompositionContainer = connect(mapStateToProps, mapExecuteToProps)(Composition);
CompositionContainer.propTypes = {
  compositionId: PropTypes.string,
  onSubmit: PropTypes.func,
};
export default CompositionContainer;

function mapStateToProps({ getProvider, isFeatureEnabled }, { currentCompositionId, onMount }) {
  const agentAssistanceConfig = getProvider('agentAssistanceConfig').get();
  const hasChannelConfiguration = !!getProvider('channelConfiguration').get();
  const compositionsProvider = getProvider('compositions');

  const composition = compositionsProvider.findBy({ id: currentCompositionId });
  const compositionErrors = (composition && compositionsProvider.getErrors(composition.id)) || [];

  const selectedKbItem = getProvider('selectedKbItem').get();
  const { isCompositionMinimized } = getProvider('currentLocation').get();
  const preferences = getProvider('agentPreferences').get();

  const isSlateBetaEnabled = isFeatureEnabled('slateBeta') && preferences.experimentalEditorEnabled;

  return {
    composition,
    compositionErrors,
    currentCompositionId,
    isAILegacyNoteComposerFeatures: !agentAssistanceConfig.isEnabled('disableHeroAI'),
    isCompositionMinimized,
    isEmailForceBetaEnabled: isFeatureEnabled('forceEmailSlateBeta'),
    isGclDemoEnabled: isFeatureEnabled('gclDemo2023'),
    isLoading: compositionsProvider.isLoading(),
    isSlateBetaEnabled,
    hasChannelConfiguration,
    onMount,
    selectedKbItem: _.get(selectedKbItem, 'item'),
    selectedKbItemId: _.get(selectedKbItem, 'id'),
    selectedKbItemChannelType: _.get(selectedKbItem, 'channelType'),
    selectedKbItemLanguage: _.get(selectedKbItem, 'language'),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onToggleAnswerPanel: attrs => executeAction(ToggleAnswerPanel, attrs),
  };
}

// Only log this error once every 5 minutes
const debouncedLogCurrentCompIdSetWithoutComposition = _.debounce(
  logCurrentCompIdSetWithoutComposition,
  1000 * 60 * 5,
  { leading: true, trailing: false }
);

function logCurrentCompIdSetWithoutComposition() {
  ErrorReporter.reportError(new Error('Current composition id set but no composition found'));
}
