import React from 'react';

import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import scrollparent from 'scrollparent';

import ExpandedTransactionBase from 'components/customer/summary/transactions/expanded_transaction_base';
import ExpandedOrderTransactionBase from 'components/customer/summary/transactions/expanded_order_transaction_base';
import ExpandedSubscriptionTransactionBase from 'components/customer/summary/transactions/expanded_subscription_transaction_base';
import {
  FlightTransactionDef,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
} from 'models/configuration/transaction_def';
import {
  FlightAttributesPropTypes,
  OrderAttributesPropTypes,
  StayAttributesPropTypes,
  SubscriptionAttributesPropTypes,
} from './constants';

const TRANSACTION_ANIMATION_DURATION = 300;
const SCROLLING_PADDING = 10;

export { TRANSACTION_ANIMATION_DURATION };

export default class ExpandedTransaction extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isExpanded: props.isExpanded, transactionHeight: props.isExpanded ? 'auto' : 0 };
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleAnimationStart = this.handleAnimationStart.bind(this);
    this.setRef = this.setRef.bind(this);
    this.renderTransactionBase = this.renderTransactionBase.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimeout);
    this.request && cancelAnimationFrame(this.request);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isExpanded && !this.props.isExpanded) {
      clearTimeout(this.closeTimeout);
      this.setState({ isExpanded: true, transactionHeight: 'auto' });
    } else if (!nextProps.isExpanded && this.props.isExpanded) {
      // We delay the unmounting of the flight transaction when closing until after the closing animation has completed
      this.closeTimeout = setTimeout(() => this.setState({ isExpanded: false }), TRANSACTION_ANIMATION_DURATION);
      this.setState({ transactionHeight: 0 });
    }
  }

  render() {
    return (
      <div className="transaction-expandedInformation-wrapper" ref={this.setRef}>
        <AnimateHeight
          animateOpacity
          className="transaction-detailed"
          duration={TRANSACTION_ANIMATION_DURATION}
          height={this.state.transactionHeight}
          onAnimationEnd={this.handleAnimationEnd}
          onAnimationStart={this.handleAnimationStart}
        >
          <span style={{ display: 'flex', flexDirection: 'column' }}>{this.renderTransactionBase()}</span>
        </AnimateHeight>
      </div>
    );
  }

  renderTransactionBase() {
    if (!this.state.isExpanded) {
      return null;
    } else if (this.props.transactionDef instanceof OrderTransactionDef) {
      return (
        <ExpandedOrderTransactionBase
          attributes={this.props.attributes}
          shouldRenderImages={!!this.props.shouldRenderImages}
          transactionDef={this.props.transactionDef}
        />
      );
    } else if (this.props.transactionDef instanceof SubscriptionTransactionDef) {
      return (
        <ExpandedSubscriptionTransactionBase
          attributes={this.props.attributes}
          headerRenderer={this.props.headerRenderer}
          transactionDef={this.props.transactionDef}
        />
      );
    }

    return (
      <ExpandedTransactionBase
        attributes={this.props.attributes}
        headerRenderer={this.props.headerRenderer}
        transactionDef={this.props.transactionDef}
      />
    );
  }

  handleAnimationStart() {
    const increment = 20;
    const duration = TRANSACTION_ANIMATION_DURATION + 40; // Set duration past to help with animation completing

    let currentTime = 0;
    const animateScroll = () => {
      const parent = scrollparent(this.wrapper);
      currentTime += increment;

      if (parent) {
        let scrollparentBottom = parent.getBoundingClientRect().bottom;
        let wrapperBottom = this.wrapper.getBoundingClientRect().bottom;
        const diff = wrapperBottom + SCROLLING_PADDING - scrollparentBottom;

        if (diff > 0) {
          parent.scrollTop = parent.scrollTop + diff;
        }

        if (currentTime < duration) {
          this.request = requestAnimationFrame(animateScroll);
        }
      }
    };
    animateScroll();
  }

  handleAnimationEnd() {
    // Cleanup leftover space if request frame didn't fire enough before animation ended
    const parent = scrollparent(this.wrapper);
    let scrollparentBottom = parent.getBoundingClientRect().bottom;
    let wrapperBottom = this.wrapper.getBoundingClientRect().bottom;
    const diff = wrapperBottom + SCROLLING_PADDING - scrollparentBottom;
    if (diff > 0) {
      parent.scrollTop = parent.scrollTop + diff;
    }

    this.request && cancelAnimationFrame(this.request);
    this.request = null;
  }

  setRef(node) {
    this.wrapper = node;
  }
}

ExpandedTransaction.propTypes = {
  attributes: PropTypes.oneOfType([
    FlightAttributesPropTypes,
    OrderAttributesPropTypes,
    StayAttributesPropTypes,
    SubscriptionAttributesPropTypes,
  ]),
  headerRenderer: PropTypes.func,
  isExpanded: PropTypes.bool,
  shouldRenderImages: PropTypes.bool,
  transactionDef: PropTypes.oneOfType([
    PropTypes.instanceOf(FlightTransactionDef),
    PropTypes.instanceOf(OrderTransactionDef),
    PropTypes.instanceOf(StayTransactionDef),
    PropTypes.instanceOf(SubscriptionTransactionDef),
  ]).isRequired,
};
