import PropTypes from 'prop-types';

import AbortUpload from 'actions/composition/abort_upload';
import CompositionUpload from './composition_upload';
import connect from 'components/lib/connect';
import RemoveUpload from 'actions/composition/remove_upload';
import RetryUpload from 'actions/composition/retry_upload';
import Upload from 'models/upload';

const CompositionUploadContainer = connect(mapStateToProps, mapExecuteToProps)(CompositionUpload);

function mapStateToProps(context, props) {
  return {
    thumbnailUrl: props.thumbnailUrl,
    upload: props.upload,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onAbort: () => executeAction(AbortUpload, { compositionId: props.compositionId, uploadId: props.upload.id }),
    onOpenAttachmentPreview: props.onOpenAttachmentPreview,
    onRemove: () => executeAction(RemoveUpload, { compositionId: props.compositionId, uploadId: props.upload.id }),
    onRetry: () => executeAction(RetryUpload, { compositionId: props.compositionId, uploadId: props.upload.id }),
  };
}

CompositionUploadContainer.propTypes = {
  compositionId: PropTypes.string.isRequired,
  onOpenAttachmentPreview: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  upload: PropTypes.instanceOf(Upload).isRequired,
};

export default CompositionUploadContainer;
