import _ from 'lodash';
import Observable from 'scripts/lib/observable_mixin';

export default class NetworkConnectionMonitor {
  constructor(win) {
    win.addEventListener('online', this.handleConnect.bind(this));
    win.addEventListener('offline', this.handleDisconnect.bind(this));
  }

  handleConnect() {
    this.notifyObservers('handleConnect', null);
  }

  handleDisconnect() {
    this.notifyObservers('handleDisconnect', null);
  }
}

_.extend(NetworkConnectionMonitor.prototype, Observable);
