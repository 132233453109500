import _ from 'lodash';
import TypeReflect from 'models/lib/type_reflect';

import redactAttachment from './attachment_redact';
import CustomChannelMessageAttachment from 'models/custom_channel_message_attachment';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import Err from 'models/err';

const MB = 1 << (10 * 2);
const MAX_MESSAGE_LENGTH = 2048;

export const customChannelMessageStatusCode = createEnum('SENDING', 'SENT', 'ACCEPTED', 'DELIVERED', 'READ', 'FAILED');

export const CustomChannelMessageStatus = createModel({
  modelName: 'CustomChannelMessageStatus',
  properties: {
    value: prop(String).oneOf(..._.values(customChannelMessageStatusCode)).isRequired,
    details: prop(String),
    createdAt: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const messageType = createEnum('TEXT', 'FILES');

export const CustomChannelMessageContentFiles = createModel({
  modelName: 'CustomChannelMessageContentFiles',
  properties: {
    caption: prop(String),
    attachments: prop([CustomChannelMessageAttachment]).default([]),
  },

  getSnippetText() {
    return this.caption;
  },

  redactAttachment(attachmentId) {
    redactAttachment(this.attachments, attachmentId);
  },

  validate() {
    const errors = [];

    if (this.attachments.length > 5) {
      errors.push(new Err({ attr: 'attachments', code: Err.INVALID, detail: 'there is a maximum of 5 attachments' }));
    }

    _.forEach(this.attachments, attachment => {
      if (attachment.fileDescriptor().contentLength > 10 * MB) {
        errors.push(
          new Err({
            attr: 'attachments',
            code: Err.INVALID,
            detail: 'the maximum size of each attachment is 10MB',
          })
        );
      }
    });

    return errors;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const CustomChannelMessageContentText = createModel({
  modelName: 'CustomChannelMessageContentText',
  properties: {
    text: prop(String),
  },

  getSnippetText() {
    return this.text;
  },

  validate() {
    const errors = [];

    // Do we have any text at all?
    if (!_.trim(this.text)) {
      errors.push(
        new Err({
          attr: 'text',
          code: Err.Code.BLANK,
          detail: 'Message cannot be empty',
        })
      );
    }

    // Do we have too much text?
    if (this.text?.length > MAX_MESSAGE_LENGTH) {
      errors.push(
        new Err({
          attr: 'text',
          code: Err.Code.TOO_LONG,
          detail: `Your message can contain a maximum of ${MAX_MESSAGE_LENGTH} characters`,
        })
      );
    }

    return errors;
  },

  statics: {
    MAX_LENGTH: MAX_MESSAGE_LENGTH,

    create(attrs) {
      return new this(attrs);
    },
  },
});

const MessageContentTypes = [CustomChannelMessageContentText, CustomChannelMessageContentFiles];

const MessageTypeClasses = [
  [messageType.TEXT, CustomChannelMessageContentText],
  [messageType.FILES, CustomChannelMessageContentFiles],
];

const messageTypeReflect = new TypeReflect(MessageTypeClasses);

const CustomChannelMessage = createModel({
  modelName: 'CustomChannelMessage',
  properties: {
    customChannelId: prop(String).isRequired,
    companyAddress: prop(String).isRequired,
    customerAddress: prop(String).isRequired,

    sessionId: prop(String),

    isRedacted: prop(Boolean).default(false),
    hasRedactedPaymentCardNumber: prop(Boolean).default(false),

    status: prop(CustomChannelMessageStatus).isRequired,
    previousStatuses: prop(Array),

    groupId: prop(String),
    groupName: prop(String),

    messageType: prop(String).oneOf(..._.values(messageType)).isRequired,
    content: prop().oneOf(...MessageContentTypes).isRequired,
  },

  getErrorMessage() {
    return this.hasErrorStatus() && this.status.details ? this.status.details : 'Failed to send message.';
  },

  getErrorMessageHelpDocHref() {
    return '';
  },

  getMessageText() {
    return this.getSnippetText();
  },

  getSnippetText() {
    return this.content.getSnippetText();
  },

  getStatus() {
    return this.status?.value || '';
  },

  hasErrorStatus() {
    return hasErrorStatus(this.getStatus());
  },

  redactAttachment(attachmentId) {
    this.content.redactAttachment(attachmentId);
  },

  validate() {
    const errors = [];

    errors.push(...this.content.validate());

    if (!this.customerAddress) {
      errors.push(
        new Err({
          attr: 'customerAddress',
          code: Err.Code.BLANK,
          detail: 'The "customerAddress" field cannot be empty',
        })
      );
    }

    if (!this.companyAddress) {
      errors.push(
        new Err({
          attr: 'companyAddress',
          code: Err.Code.BLANK,
          detail: 'The "companyAddress" field cannot be empty',
        })
      );
    }

    return errors;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
    overrideFromJs(fromJs) {
      return attrs => {
        const messageConstructor = messageTypeReflect.typeToConstructor(attrs.messageType);
        const content = messageConstructor.fromJs(attrs.content);
        const previousStatuses = attrs.previousStatuses
          ? attrs.previousStatuses.map(s => new CustomChannelMessageStatus(s))
          : [];
        return fromJs({ ...attrs, content, previousStatuses });
      };
    },
  },
});

export function hasErrorStatus(status) {
  return status === customChannelMessageStatusCode.FAILED;
}

export default CustomChannelMessage;
