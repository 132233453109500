import { FlexibleCardConfigurationType } from 'models/flexible_card';

import loopReturnsConfig from './loop_returns';

/**
 * A "catalog" of all Flexible Card configurations that AD knows and can render.
 *
 * When adding a new configuration, please make sure to
 *   1. Add it to the `FlexibleCardConfigurationType` enum
 *   2. Add the corresponding config here (preferably in its own code file)
 */
const configurations = {
  [FlexibleCardConfigurationType.LOOP_RETURNS]: loopReturnsConfig,
};

/**
 * Helper function to look up the Flexible Card configuration block by the "key" (which normally comes
 * from the `customerProfileDef`)
 *
 * @param {string} configKey - should be one of `FlexibleCardConfigurationType` values
 * @returns {object|undefined}
 */
export function getFlexibleCardConfiguration(configKey) {
  const trimmed = (configKey || '').trim();
  return trimmed ? configurations[trimmed] : undefined;
}
