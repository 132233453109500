import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'Integrations',
  properties: {},

  statics: {
    breadcrumb: 'Integrations',
  },
});
