import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ErrorReporter from 'scripts/infrastructure/error_reporter';
import FlexibleProfileCardBase from 'components/customer/flexible_profile_card/components/flexible_card_base';
import { getFlexibleCardConfiguration } from 'components/customer/flexible_profile_card/configurations';
import ProfileErrorCard from 'components/customer/profile/profile_error_card';
import qconsole from 'scripts/lib/qconsole';
import { validateConfig } from 'components/customer/flexible_profile_card/lib/configuration_validation';

export default function FlexibleProfileCard({ configurationKey, dataFilter, dataRowLimit, dataSort, expandThreshold }) {
  const configuration = getFlexibleCardConfiguration(configurationKey);
  const { error, valid } = validateConfig(configuration);
  if (!valid) {
    qconsole.error(`Unable to display Flexible Card "${configurationKey}". Missing or invalid configuration.`, error);
    ErrorReporter.reportError(error, {
      extra: {
        source: 'FlexibleProfileCard',
        cardType: configurationKey,
      },
    });

    return (
      <ProfileErrorCard
        data-aid={`flexible_card_configuration_error`}
        reason="The card configuration is not correct."
      />
    );
  }

  // Validate and adjust data sorting/limiting options if necessary
  let filter = _.isEmpty(dataFilter) ? {} : dataFilter;
  let sort = _.isEmpty(dataSort) ? {} : dataSort;
  const limit = Math.max(~~dataRowLimit, 0);

  if (!_.isObject(filter)) {
    qconsole.error(`Flexible Card "${configurationKey}": invalid "dataFilter" parameter. Ignoring...`);
    filter = {};
  }
  if (!_.isObject(sort)) {
    qconsole.error(`Flexible Card "${configurationKey}": invalid "dataSort" parameter. Ignoring...`);
    sort = {};
  }

  return (
    <FlexibleProfileCardBase
      cardConfiguration={configuration}
      dataFilter={filter}
      dataRowLimit={limit}
      dataSort={sort}
      expandThreshold={expandThreshold}
    />
  );
}

FlexibleProfileCard.propTypes = {
  configurationKey: PropTypes.string.isRequired,
  dataFilter: PropTypes.object,
  dataRowLimit: PropTypes.number,
  dataSort: PropTypes.object,
  expandThreshold: PropTypes.number,
};
