import ConversationPreview from 'models/conversation_preview';
import TaskPreview from 'models/task_preview';

export default {
  fromDto(props) {
    return props.task ? TaskPreview.fromJs(props) : ConversationPreview.fromJs(props);
  },

  toDto(entity) {
    return entity.toJs();
  },
};
