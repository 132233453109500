import { after } from 'scripts/infrastructure/backends/http_client';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';

export default function createConfigurationHttpGateway(configKey, backend, requestorId) {
  class ConfigurationHttpGateway {
    constructor() {
      this.backend = backend;
      this.mqttClient = backend;
      this.requestorId = requestorId;
      this.configKey = configKey;
    }

    init({ orgId } = {}) {
      this.orgId = orgId;
    }

    get version() {
      return '1';
    }

    get broadcastTopic() {
      return `v${this.version}/orgs/${this.orgId}/configuration/${this.configKey}`;
    }

    get _http() {
      return this.backend.axios();
    }

    reconnectHandler() {
      if (this._isSubscribed) {
        this.request();
      }
    }

    handleBroadcast(envelope) {
      this.notifyObservers('handleFetchConfiguration', envelope.payload);
    }

    url() {
      return `/api/v${this.version}/orgs/${this.orgId}/configuration/${this.configKey}`;
    }

    request() {
      this._isSubscribed = true;
      this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.get.bind(this));
    }

    /* HTTP Methods */

    get() {
      return after(this._http.get(this.url()), this.onGet.bind(this));
    }

    remove() {
      return after(this._http.delete(this.url()), this.onDelete.bind(this));
    }

    update(configBody) {
      return after(this._http.put(this.url(), configBody), this.onPut.bind(this));
    }

    /* Handlers */

    onGet(err, res) {
      if (err) {
        this.notifyObservers('handleFetchConfigurationError', err);
        return;
      }
      if (res.status === 200) {
        this.notifyObservers('handleFetchConfiguration', res.data);
      } else {
        this.notifyObservers('handleFetchConfigurationError', res.data);
      }
    }

    onDelete(err, res) {
      if (err) {
        this.notifyObservers('handleDeleteErrors', err);
        return;
      }
      if (res.status === 204) {
        this.notifyObservers('handleConfigurationDelete', null);
      } else {
        this.notifyObservers('handleDeleteErrors', res.data);
      }
    }

    onPut(err, res) {
      if (err) {
        this.notifyObservers('handleUpdateErrors', err);
        return;
      }
      if (res.status === 204) {
        this.notifyObservers('handleConfigurationUpdate', null);
      } else {
        this.notifyObservers('handleUpdateErrors', res.data);
      }
    }
  }

  mixin(ConfigurationHttpGateway.prototype, Observable);

  return new ConfigurationHttpGateway(backend, requestorId);
}
