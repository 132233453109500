import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactSelect from 'react-select';

const Dropdown = createReactClass({
  propTypes: {
    deleteRemoves: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    searchable: PropTypes.bool,
    value: PropTypes.any,
  },

  handleWheel(ev) {
    let menuNode = ReactDOM.findDOMNode(this.select).querySelector('.Select-menu');
    if (menuNode) {
      ev.stopPropagation();
    }
  },

  handleChange(option) {
    if (!option || (!option.value && !this.props.deleteRemoves)) {
      return;
    }
    this.props.onChange(option);
  },

  render() {
    return (
      <div className="dropdownMenu" onWheel={this.handleWheel}>
        <ReactSelect
          clearable={false}
          onChange={this.handleChange}
          optionRenderer={this.renderOption}
          options={this.props.options}
          ref={this.getRef}
          searchable={!!this.props.searchable}
          value={this.props.value}
        />
      </div>
    );
  },

  renderOption(option) {
    return (
      <div className="dropdownMenu-item-spacer">
        <span className="dropdownMenu-item-label">{option.label}</span>
      </div>
    );
  },

  getRef(ref) {
    this.select = ref;
  },
});

export default Dropdown;
