import createModel, { prop } from './lib/create_model';
import Snippet from 'models/answers/snippet';

const AnswerSuggestions = createModel({
  modelName: 'AnswerSuggestions',

  properties: {
    id: prop(String).isRequired,
    version: String,
    suggestions: prop([Snippet]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default AnswerSuggestions;
