import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import IconContainer from './icon_container';

export default function MobileChatIcon({ props, className }) {
  return (
    <IconContainer viewBox="0 0 16 16" {...props} className={classnames(className)}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" strokeWidth="0" />
        <path
          clipRule="evenodd"
          d="M11.376 12.945c0 .574-.435 1.04-.972 1.04l-4.369-.004c-.536 0-.97-.467-.97-1.041l.009-9.883c0-.574.435-1.04.971-1.04l4.37.004c.536 0 .97.467.97 1.041l-.009 9.883z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7.015 3.787l2.427.002" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </IconContainer>
  );
}

MobileChatIcon.propTypes = {
  className: PropTypes.string,
};
