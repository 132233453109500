import PopoverMenu, { PopoverMenuItem } from 'components/common/menu';
import styled from 'styled-components';

export const StyledPopoverMenu = styled(PopoverMenu)`
  overflow: visible;
`;

export const StyledPopoverMenuItem = styled(PopoverMenuItem)`
  flex-direction: row;
  gap: 4px;
  min-width: 0;
  padding: 8px;
  &:hover {
    background: none;
  }
`;

export const IconWrapper = styled.span`
  font-size: 16px;
`;

export const WidthContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`;

export const StyledContainer = styled.span`
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  gap: 4px;
  height: 34px;
  padding: 4px;
`;

export const ItemsContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  gap: 4px;
  min-width: 1px;

  & > * {
    flex-shrink: 0;
  }
`;

export const ItemWrapper = styled.span`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
