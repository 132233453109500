import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import Attachments from 'components/admin/knowledge_base/snippet/snippet_attachments';
import BackButton from 'components/customer/answer_panel/preview/answer_preview_back_button';
import Body from './content/answer_preview_content_body';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';

export default function AnswerPreviewModal({ answer, language, answerType, onClose }) {
  return (
    <PortalModalWrapper>
      <Modal>
        <ModalCard data-aid="answerPreviewModal" onClose={onClose}>
          <Close onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
          <BackButton />
          <Name>{answer.name}</Name>
          <Body answer={answer} data-aid="answerPanel-modal-body" language={language} type={answerType} />
          <Attachments contentChannelType={answerType} isRemoveable={false} snippet={answer} />
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

const Modal = styled.div`
  .modalCard-content {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    max-width: 70vw;
    overflow: auto;
    position: relative;
  }
`;

const Close = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    color: ${p => p.theme.colors.gray600};
  }
`;

const Name = styled.div`
  font-size: ${p => p.theme.fontSize.large};
  font-weight: bold;
  line-height: ${p => p.theme.fontSize.large};
  margin-bottom: ${p => p.theme.spacing.large};
`;
