import ErrorReporter from 'scripts/infrastructure/error_reporter';
import { Unauthorized } from 'scripts/application/lib/error';

/**
 * Abstract / template class for handling common gateway errors.
 */
export default class GatewayErrorHandler {
  /**
   * Handles typical errors occurring when communicating with a remote service via a gateway.
   *
   * @param ctx - request context for reporting unknown errors to Sentry
   * @param err - the error to handle
   */
  handleCommonErrors(ctx, err) {
    if (arguments.length === 1 || !err) {
      err = arguments[0];
    }

    if (err instanceof Unauthorized) {
      this.handleUnauthorized(ctx, err);
    } else {
      this.handleInfrastructureErrors(ctx, err);
    }
  }

  reportError({ correlationId }, err) {
    if (arguments.length === 1 || !err) {
      err = arguments[0];
      correlationId = err.correlationId;
    }

    // Convert our Gladly error into a "readable" error object
    const loggedError = new Error(err.message);
    const causeStack = err.stack;
    const errors = JSON.stringify(err.errors || []);
    const detailedMessage = err.detailedMessage || err.message;

    loggedError.name = err.name;
    err.stack = loggedError.stack; // update error stack trace to retain observer stack trace

    ErrorReporter.reportError(loggedError, {
      tags: { cause: detailedMessage },
      extra: { correlationId, causeStack, errors, detailedMessage, message: err.message, name: loggedError.name },
    });
  }
}
