import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Transition } from 'components/common/lib/css_transition';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import ScrollNotifier from 'components/lib/scroll_notifier';
import Portal from 'components/common/utilities/portal';

export const BOUNDS_PROPTYPE = PropTypes.shape({
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
});
export const POSITION_PROPTYPE = PropTypes.oneOf(['bottom', 'left', 'top', 'right']);
export const TARGET_PROPTYPE = PropTypes.shape({
  bottom: PropTypes.number,
  height: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.number,
});

/**
 * Popover will position some content around a given `targetElement` DOM element, depending on the given `position`
 * prop. It will automatically be repositioned when parent of `targetElement` is scrolled or the window resizes.
 *
 * You can provide a static `target` object instead of a `targetElement`. Note that specifying `target` means the
 * position of the popover will not be updated automatically if a parent scrolls. In that case, you'll want
 * to use the `ScrollNotifier` component yourself to update `target`.
 */
export class Popover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      positions: undefined,
    };
    this.positionPopover = this.positionPopover.bind(this);
  }

  componentDidMount() {
    this.positionPopover();
  }

  componentDidUpdate() {
    this.positionPopover();
  }

  getTargetDOMNode() {
    return this.props.targetRef ? this.props.targetRef.current : this.props.targetElement;
  }

  render() {
    return (
      <ScrollNotifier findScrollingParentOf={this.getTargetDOMNode()} onScroll={this.positionPopover}>
        <PopoverContainer
          className={this.props.className}
          data-aid={this.props['data-aid']}
          onClick={blockClick}
          ref={node => (this.popover = node)}
          role={this.props.role}
          style={this.props.style}
        >
          {this.props.children}
          <PopoverArrow ref={node => (this.arrow = node)} />
        </PopoverContainer>
      </ScrollNotifier>
    );
  }

  positionPopover() {
    let { fitOpenerWidth, position, target, targetElement, targetRef } = this.props;

    if (!target && targetElement) {
      target = targetElement.getBoundingClientRect();
    } else if (!target && targetRef && targetRef.current) {
      target = targetRef.current.getBoundingClientRect();
    }

    // Without a target, we cannot position the popover relative to anything, so hide the popover.
    if (!target) {
      this.popover.style.visibility = 'hidden';
      return;
    }

    let pixels = this.getComputedPixels({ position, target });
    let { arrowLeft, arrowTop, popoverLeft, popoverTop, height } = pixels;

    this.arrow.style.left = `${arrowLeft}px`;
    this.arrow.style.top = `${arrowTop}px`;

    this.popover.style.transform = `translate3d(${popoverLeft}px, ${popoverTop}px, 0)`;
    this.popover.style.visibility = 'visible';
    if (height) {
      this.popover.style.height = `${height}px`;
    }

    if (fitOpenerWidth) {
      this.popover.style.width = `${target.width}px`;
    }
    this.popover.setAttribute('data-position', pixels.position);
  }

  getComputedPixels({ position, target }) {
    const targetPixels = target;
    let height;
    let { left, top, popoverLeft, popoverPixels, popoverTop } = this.getPixels(targetPixels, position);

    // Change position if we can fix a violation of bounds
    if (this.props.autoPosition && this.props.bounds) {
      if (
        position === 'bottom' &&
        this.props.bounds.bottom != null &&
        popoverTop + popoverPixels.height >= this.props.bounds.bottom
      ) {
        position = 'top';
        let pixels = this.getPixels(targetPixels, position);
        popoverLeft = pixels.popoverLeft;
        popoverTop = pixels.popoverTop;
      } else if (position === 'top' && this.props.bounds.top != null && popoverTop <= this.props.bounds.top) {
        position = 'bottom';
        let pixels = this.getPixels(targetPixels, position);
        popoverLeft = pixels.popoverLeft;
        popoverTop = pixels.popoverTop;
      }
    }

    // Account for bounds if specified
    if (this.props.bounds) {
      if (this.props.bounds.left != null && popoverLeft <= this.props.bounds.left) {
        const diff = this.props.bounds.left - popoverLeft;
        popoverLeft += diff;
      }
      if (this.props.bounds.right != null && popoverLeft + popoverPixels.width >= this.props.bounds.right) {
        const diff = popoverLeft + popoverPixels.width - this.props.bounds.right;
        popoverLeft -= diff;
      }

      if (this.props.bounds.top != null && popoverTop <= this.props.bounds.top) {
        const diff = this.props.bounds.top - popoverTop;
        popoverTop += diff;
      }

      if (this.props.bounds.bottom != null && popoverTop + popoverPixels.height >= this.props.bounds.bottom) {
        const diff = popoverTop + popoverPixels.height - this.props.bounds.bottom;
        if (this.props.bounds.top && popoverTop - diff < this.props.bounds.top) {
          height = this.props.bounds.bottom - this.props.bounds.top;
        } else {
          popoverTop -= diff;
        }
      }
    }

    // Position the arrow
    const arrowPixels = this.arrow.getBoundingClientRect();
    let arrowLeft, arrowTop;
    if (position === 'top' || position === 'bottom') {
      arrowLeft = left - popoverLeft - arrowPixels.width / 2;
      if (position === 'top') {
        arrowTop = popoverPixels.height;
      } else {
        arrowTop = -arrowPixels.height;
      }
    } else if (position === 'left' || position === 'right') {
      arrowTop = top - popoverTop - arrowPixels.height / 2;
      if (position === 'left') {
        arrowLeft = popoverPixels.width;
      } else {
        arrowLeft = -arrowPixels.width;
      }
    }

    return {
      arrowLeft: Math.floor(arrowLeft),
      arrowTop: Math.floor(arrowTop),
      height,
      popoverLeft: Math.floor(popoverLeft),
      popoverTop: Math.floor(popoverTop),
      position,
    };
  }

  getPixels(targetPixels, position) {
    const popoverPixels = this.popover.getBoundingClientRect();

    let xPlacementOffset = targetPixels.width / 2;
    let yPlacementOffset = targetPixels.height / 2;
    if (position === 'top' || position === 'bottom') {
      if (this.props.targetPosition === 'start') {
        xPlacementOffset = popoverPixels.width / 2;
      } else if (this.props.targetPosition === 'end') {
        xPlacementOffset = targetPixels.width - popoverPixels.width / 2;
      }
    } else if (position === 'left' || position === 'right') {
      if (this.props.targetPosition === 'start') {
        yPlacementOffset = popoverPixels.height / 2;
      } else if (this.props.targetPosition === 'end') {
        yPlacementOffset = targetPixels.height - popoverPixels.height / 2;
      }
    }

    const left = targetPixels.left + xPlacementOffset;
    const top = targetPixels.top + yPlacementOffset;

    // Position the popover container
    let popoverLeft, popoverTop;
    if (position === 'top' || position === 'bottom') {
      popoverLeft = left - popoverPixels.width / 2;

      if (position === 'top') {
        popoverTop = targetPixels.top - popoverPixels.height - this.props.margin;
      } else {
        popoverTop = targetPixels.bottom + this.props.margin;
      }
    } else if (position === 'left' || position === 'right') {
      popoverTop = top - popoverPixels.height / 2;

      if (position === 'left') {
        popoverLeft = targetPixels.left - popoverPixels.width - this.props.margin;
      } else {
        popoverLeft = targetPixels.right + this.props.margin;
      }
    }
    return { popoverLeft, popoverPixels, popoverTop, top, left };
  }

  handleScroll() {
    this.positionPopover();
  }
}

Popover.defaultProps = {
  margin: 10,
};

Popover.propTypes = {
  autoPosition: PropTypes.bool, // will change position if violates bounds
  bounds: BOUNDS_PROPTYPE,
  children: PropTypes.node,
  className: PropTypes.string,
  fitOpenerWidth: PropTypes.bool, // if true then set the Popover's width to 100% of the OpenerButton
  margin: PropTypes.number,
  position: POSITION_PROPTYPE.isRequired,
  role: PropTypes.string,
  target: TARGET_PROPTYPE,
  targetElement: PropTypes.object,
  targetPosition: PropTypes.oneOf(['start', 'center', 'end']),
  targetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  style: PropTypes.object,
};

/**
 * PortalledPopover will render a Popover, except it will fade in the Popover and render it in a Portal
 * to ensure that it renders on top of everything else.
 */
export class PortalledPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: props.isVisible,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    // Immediately hide the popover if children becomes empty
    if (!props.isVisible && !props.children && this.state.isVisible) {
      this.setState({ isVisible: false });
    }
    // While we're fading out the modal, we want to keep a snapshot of the previous children in order
    // to prevent weird flickering / changes while it fades out
    else if (this.props.isVisible && !props.isVisible) {
      this.cachedChildren = this.props.children;
    }

    if (this.cachedChildren && props.isVisible) {
      this.cachedChildren = null;
    }
  }

  componentDidUpdate() {
    // We delay rendering the popover content until after the CSS transition group has rendered to ensure the
    // transition gets properly applied. We also delay making the entire component return null for the same
    // reason.
    if (!this.state.isVisible && this.props.isVisible) {
      this.setState({ isVisible: true });
    } else if (this.state.isVisible && !this.props.isVisible) {
      this.setStateUpdateTimeout({ isVisible: false });
    }
  }

  componentWillUnmount() {
    this.clearStateUpdateTimeout();
  }

  setStateUpdateTimeout(update) {
    this.clearStateUpdateTimeout();
    this.closeTimeout = this.props.setTimeout(() => this.setState(update), 75);
  }

  clearStateUpdateTimeout() {
    if (!this.closeTimeout) return;

    this.props.clearTimeout(this.closeTimeout);
    this.closeTimeout = undefined;
  }

  render() {
    if (!this.props.isVisible && !this.state.isVisible) {
      return null;
    }

    return (
      <Portal>
        <Transition
          in={this.props.isVisible && this.state.isVisible}
          mountOnEnter
          timeout={{ enter: 75, exit: 75 }}
          unmountOnExit
        >
          {animatingState => this.renderContent(animatingState)}
        </Transition>
      </Portal>
    );
  }

  renderContent(animatingState) {
    const props = _.pick(this.props, POPOVER_PROPS);

    const content = (
      <AnimatedPopover animatingState={animatingState} {...props}>
        {this.cachedChildren || this.props.children}
      </AnimatedPopover>
    );
    if (this.props.onClickOutside) {
      return <OutsideClickHandler onClickOutside={this.props.onClickOutside}>{content}</OutsideClickHandler>;
    }

    return content;
  }
}

PortalledPopover.defaultProps = {
  isVisible: false,

  clearTimeout: window.clearTimeout.bind(window),
  setTimeout: window.setTimeout.bind(window),
};

PortalledPopover.propTypes = {
  ...Popover.propTypes,
  margin: PropTypes.number,
  onClickOutside: PropTypes.func,

  isVisible: PropTypes.bool,
  clearTimeout: PropTypes.func,
  setTimeout: PropTypes.func,
};

export const POPOVER_PROPS = [
  'autoPosition',
  'bounds',
  'children',
  'className',
  'data-aid',
  'fitOpenerWidth',
  'margin',
  'position',
  'role',
  'style',
  'target',
  'targetElement',
  'targetPosition',
  'targetRef',
];

function blockClick(e) {
  e.stopPropagation();
}

const PopoverContainer = styled.div`
  left: 0;
  position: fixed;
  top: 0;
  z-index: 20;
`;

const PopoverArrow = styled.div`
  border: 6px solid transparent;
  height: 0;
  pointer-events: none;
  position: fixed;
  width: 0;
`;

const AnimatedPopover = styled(Popover)`
  transition: opacity 75ms ease-in;
  opacity: ${({ animatingState }) => {
    switch (animatingState) {
      case 'entering':
      case 'exiting':
      case 'exited':
        return '0';
      default:
        return '1';
    }
  }};
`;

export { PopoverContainer, PopoverArrow, AnimatedPopover };
