import _ from 'lodash';
import moment from 'moment';

const reportHelpers = {
  getDataById(dataProp) {
    let dataById = {};

    let data = this.data[dataProp];
    data.forEach(item => {
      let itemId = item.id;
      let date = reportHelpers.getDate(item.day).format('YYYY-MM-DD');
      if (!(itemId in dataById)) {
        dataById[itemId] = {};
      }
      dataById[itemId][date] = item.count;
    });

    return dataById;
  },

  init(getData, filteredIds) {
    this.dataById = getData();
    this.filteredIds = filteredIds;

    return {
      getDataForDayAndId: (date, entityId) => {
        let currentData = this.dataById[entityId] || {};
        return currentData[date] || 0;
      },

      getDataForId: entityId => {
        return this.dataById[entityId];
      },

      getTotalForId: entityId => {
        return _.sum(_.values(this.dataById[entityId]));
      },

      getIds: () => {
        if (this.filteredIds) {
          return this.filteredIds;
        } else {
          return _.keys(this.dataById);
        }
      },
    };
  },

  getDate(datetime) {
    // since the organization timezone is not currently available in the
    // front-end, and it's impossible to correctly deduce timezone offsets
    // across dst boundaries when there is the possibility of missing dates,
    // we simply discard the timezone offset information to create the date
    return moment.utc(datetime, 'YYYY-MM-DD');
  },
};

export default reportHelpers;
