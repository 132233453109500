import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { MenuContainer, MenuItem } from 'components/lib/menu_deprecated';
import { PhoneNumberType } from 'models/phone_number';

export default class PhoneTypeSelect extends React.Component {
  render() {
    return (
      <div className={classnames('phoneTypeSelect', { 'phoneTypeSelect-disabled': this.props.isDisabled })}>
        <MenuContainer
          button={this.renderContainerOpener()}
          className="phoneTypeSelect-menu"
          isDisabled={this.props.isDisabled}
          onOpen={this.props.onOpen}
          ref={node => (this.menu = node)}
        >
          {_(PhoneNumberType)
            .reject(t => t === this.props.value)
            .map(this.renderMenuItem.bind(this))
            .value()}
        </MenuContainer>
      </div>
    );
  }

  open() {
    this.menu.open();
  }

  renderContainerOpener() {
    let classNames = classnames('phoneTypeSelect-menu-button-chevron', {
      'phoneTypeSelect-menu-button-chevron-open': this.props.isEditing,
    });
    return (
      <div>
        <span className="phoneTypeSelect-menu-button-text">{getPhoneNumberTypeLabel(this.props.value)}</span>
        <i className={classNames} />
      </div>
    );
  }

  renderMenuItem(phoneNumberType) {
    return (
      <MenuItem
        className={`phoneTypeSelect-menuItem-${phoneNumberType}`}
        key={phoneNumberType}
        onSelect={this.props.onChange && this.props.onChange.bind(null, phoneNumberType)}
      >
        {getPhoneNumberTypeLabel(phoneNumberType)}
      </MenuItem>
    );
  }
}

PhoneTypeSelect.propTypes = {
  isDisabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  value: PropTypes.oneOf(_.values(PhoneNumberType)),
};

function getPhoneNumberTypeLabel(type) {
  switch (type) {
    case PhoneNumberType.HOME:
      return 'Home';
    case PhoneNumberType.MOBILE:
      return 'Mobile';
    case PhoneNumberType.OFFICE:
      return 'Office';
    default:
      return 'Other';
  }
}
