import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RemoveOptionIcon from 'components/customer/conversation_history/topics_v2/remove_option_icon';

export default function ConversationTopicMenuOptionV2({ isFocused, onClick, onMouseEnter, option }) {
  const isSelected = option.selected;
  const className = classnames({
    'conversation-topic-menu-option-focused': isFocused,
    'conversation-topic-menu-option-selected': isSelected,
  });

  const icon = isSelected ? <RemoveOptionIcon isFocused={isFocused} /> : null;

  return (
    <ConversationTopicMenuOption
      className={className}
      data-aid="conversation-topic-menu-option"
      onMouseDown={handleClick}
      onMouseEnter={handleMouseEnter}
    >
      <ConversationMenuOptionLabel>{option.label}</ConversationMenuOptionLabel>
      {icon}
    </ConversationTopicMenuOption>
  );

  function handleClick(ev) {
    onClick(ev, option);
  }

  function handleMouseEnter() {
    onMouseEnter(option);
  }
}

ConversationTopicMenuOptionV2.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: PropTypes.object,
};

export const ConversationMenuOptionLabel = styled.div`
  flex: 1 1 0;
  padding-right: ${p => p.theme.spacing.stackMedium};
  width: 90%;
`;

export const ConversationTopicMenuOption = styled.div`
  color: ${p => p.theme.colors.defaultFontBlack};
  cursor: pointer;
  display: flex;
  line-height: ${p => p.theme.lineHeight.controls};
  margin-bottom: 1px;
  margin-top: 1px;
  padding: ${p => p.theme.spacing.medium} ${p => p.theme.spacing.large};
  word-break: normal;
  &.conversation-topic-menu-option-focused {
    background-color: ${p => p.theme.colors.gray100};
  }
  &.conversation-topic-menu-option-selected {
    align-items: center;
    justify-content: center;
    padding-right: ${p => p.theme.spacing.inlineLarge};
  }
`;
