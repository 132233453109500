import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon height="20" viewBox="0 0 16 16" width="20" {...props}>
    <path
      clipRule="evenodd"
      d="M14.4619 5.19134C14.3845 5.37818 14.2022 5.5 14 5.5L10.5 5.5C9.9477 5.5 9.49998 5.05228 9.49998 4.5L9.49999 1C9.49999 0.797769 9.62181 0.615451 9.80864 0.53806C9.99548 0.46067 10.2105 0.503448 10.3535 0.646447L14.3535 4.64645C14.4965 4.78945 14.5393 5.0045 14.4619 5.19134ZM12.7929 4.5L10.5 2.20711L10.5 4.5L12.7929 4.5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M2.5 0.5C1.94772 0.5 1.5 0.947714 1.5 1.5V14.5C1.5 15.0523 1.94772 15.5 2.5 15.5L13.5 15.5C14.0523 15.5 14.5 15.0523 14.5 14.5L14.5 5C14.5 4.72386 14.2761 4.5 14 4.5L10.5 4.5V1C10.5 0.723858 10.2762 0.5 10 0.5H2.5ZM2.5 1.5L9.50001 1.5V4.5C9.50001 5.05229 9.94772 5.5 10.5 5.5L13.5 5.5L13.5 14.5L2.5 14.5L2.5 1.5ZM7.5 7C7.5 6.72386 7.72386 6.5 8 6.5H12C12.2761 6.5 12.5 6.72386 12.5 7C12.5 7.27614 12.2761 7.5 12 7.5H8C7.72386 7.5 7.5 7.27614 7.5 7ZM7.5 12C7.5 11.7239 7.72386 11.5 8 11.5H12C12.2761 11.5 12.5 11.7239 12.5 12C12.5 12.2761 12.2761 12.5 12 12.5H8C7.72386 12.5 7.5 12.2761 7.5 12ZM4.42961 5.86661C4.27414 5.71113 4.02208 5.71113 3.86661 5.8666C3.71113 6.02206 3.71113 6.27413 3.8666 6.4296L4.43701 7.00003L3.8666 7.57045C3.71113 7.72592 3.71114 7.97799 3.86661 8.13345C4.02208 8.28892 4.27414 8.28892 4.42961 8.13344L5 7.56304L5.57039 8.13344C5.72586 8.28892 5.97792 8.28892 6.13339 8.13345C6.28886 7.97799 6.28887 7.72592 6.1334 7.57045L5.56299 7.00003L6.1334 6.4296C6.28887 6.27413 6.28887 6.02206 6.13339 5.8666C5.97792 5.71113 5.72586 5.71113 5.57039 5.86661L5 6.43701L4.42961 5.86661ZM6.44042 9.90814C6.61483 9.7087 6.91665 9.6954 7.10676 9.88243C7.28858 10.0613 7.29801 10.3554 7.13101 10.5464L5.34219 12.5919C5.25381 12.6929 5.12521 12.7528 4.98744 12.7499C4.84972 12.747 4.72386 12.6819 4.63968 12.5777L3.85707 11.6088C3.69809 11.412 3.71743 11.1182 3.90676 10.9462C4.10476 10.7663 4.40563 10.7935 4.5715 10.9989L5.01085 11.5428L6.44042 9.90814Z"
      fillRule="evenodd"
    />
  </Icon>
);
