import _ from 'lodash';

export default {
  _getObservers() {
    if (!this._observers) {
      this._observers = [];
    }
    return this._observers;
  },

  addObserver(observer) {
    this._getObservers().push(observer);
  },

  removeObserver(observer) {
    _.remove(this._getObservers(), candidate => {
      return candidate === observer;
    });
  },

  // - if just one argument, this expects the observer to have a function named "handle"
  // - if more than one argument, the first argument must be the function name
  //   and the rest of the arguments will be applied to that function in the context of the observer
  notifyObservers(...args) {
    let observerCalled = false;
    let [method, observerArgs] = args.length === 1 ? ['handle', [args[0]]] : [args[0], _.tail(args)];
    _.forEach(this._getObservers(), observer => {
      if (observer[method]) {
        observerCalled = true;
        observer[method].apply(observer, observerArgs);
      }
    });

    if (!observerCalled && this._getObservers().length > 0) {
      throw new Error(`[${method}] is undefined`);
    }
  },
};
