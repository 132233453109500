import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Note Stroke
 */

export default function NoteStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M6.49997 9H2L2 2L14 2L14 14L7.99997 14L7.99997 10.5C7.99997 9.67157 7.3284 9 6.49997 9ZM1 1.5L1 9.5C1 9.77614 1.22386 10 1.5 10H6.49997C6.77611 10 6.99997 10.2239 6.99997 10.5L6.99997 14.5C6.99997 14.7761 7.22383 15 7.49997 15L14.5 15C14.7761 15 15 14.7761 15 14.5L15 1.5C15 1.22386 14.7761 1 14.5 1L1.5 1C1.22386 1 1 1.22386 1 1.5ZM4.12472 12.2191L3.8508 12H5.00002V12.9194L4.12472 12.2191ZM2.11307 11.8904C1.744 11.5952 1.95277 11 2.42541 11H5.50002C5.77616 11 6.00002 11.2239 6.00002 11.5L6.00002 13.9597C6.00002 14.3789 5.51506 14.612 5.18767 14.3501L3.50002 13L2.11307 11.8904ZM3.5 4C3.5 3.72386 3.72386 3.5 4 3.5L12 3.5C12.2761 3.5 12.5 3.72386 12.5 4C12.5 4.27614 12.2761 4.5 12 4.5L4 4.5C3.72386 4.5 3.5 4.27614 3.5 4ZM4 6.5C3.72386 6.5 3.5 6.72386 3.5 7C3.5 7.27614 3.72386 7.5 4 7.5L12 7.5C12.2761 7.5 12.5 7.27614 12.5 7C12.5 6.72386 12.2761 6.5 12 6.5L4 6.5Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
