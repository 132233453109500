import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import AgentMetadata from '../metadata/agent_metadata';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import { iconClassNameForItem } from '../metadata/metadata_icon_classes';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function TaskReopenedItem({ item }) {
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    executeAction(NavigateToItem, { customerId: item.customerId, itemId: item.content.taskItemId });
  }, [item.customerId, item.content.taskItemId]);

  const metaDataText = (
    <span className="taskReopenedItem">
      reopened a{' '}
      <a
        className="taskReopenedItem-link itemMetadata-subject"
        href={`#conversationItem-${item.content.taskItemId}`}
        onClick={onClick}
      >
        Task
      </a>
    </span>
  );

  return (
    <ItemContent isAuditItem item={item}>
      <AgentMetadata iconClass={iconClassNameForItem(item)} item={item} text={metaDataText} />
    </ItemContent>
  );
}

TaskReopenedItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
