import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';

/**
 * Common implementation of Document Store save result handlers
 *
 * Compose in a gateway as usual or mix in. If mixing in don't forget to define the `store` property.
 */
export default class DocumentStoreSaveResultHandler {
  constructor(store) {
    this.store = store;
  }

  handleSetErrors(errorsDto) {
    if (this.store.isPending()) {
      this.store.setErrors(errorsDto.errors.map(ErrorDtoConverter.fromDto));
      this.store.resetPending();
    }
  }

  handleSetSuccess() {
    this.store.commitPending();
  }
}
