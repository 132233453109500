import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import Floorboard from 'models/location/floorboard';
import LiveboardType from 'models/liveboards/liveboard_type';
import pollLiveboard from 'actions/liveboards/poll_liveboard';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import TrackEvent from 'actions/analytics/track_event';

let stopPolling = () => {};

export default class OpenFloorboard {
  constructor(context) {
    this.context = context;
  }

  run({ routingGroupIds, timezone }) {
    const nextLocation = Floorboard.create({ routingGroupIds, timezone });

    changeCurrentLocation(this.context, nextLocation);

    stopPolling = pollLiveboard(this.context, {
      liveboardType: LiveboardType.SUMMARY,
      query: {
        routingGroupIds: nextLocation.routingGroupIds,
        timezone: nextLocation.timezone,
      },
    });

    this.context.executeAction(TrackEvent, {
      event: 'Floorboard Opened',
      props: {
        type: LiveboardType.SUMMARY,
        inboxCount: nextLocation.routingGroupIds ? nextLocation.routingGroupIds.length : 0,
        routingGroupIds: nextLocation.routingGroupIds,
        timezone: nextLocation.timezone,
      },
    });

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.LIVEBOARDS });
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }

  static stopPolling() {
    stopPolling();
  }
}
