import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('InboundCommunicationSessionNotification');

Factory.define('InboundCommunicationSessionNotificationWithDefaults')
  .extend('InboundCommunicationSessionNotification')
  .attr('id', 'incoming-session-id')
  .attr('profile', () => Factory.attributes('SessionCustomerWithDefaults'))
  .attr('sessionType', 'SMS');

Factory.define('InboundCommunicationSessionNotificationWithAbandonedCall')
  .extend('InboundCommunicationSessionNotificationWithDefaults')
  .attr('sessionType', 'ABANDONED_CALL');

Factory.define('InboundCommunicationSessionNotificationWithChat')
  .extend('InboundCommunicationSessionNotificationWithDefaults')
  .attr('sessionType', 'CHAT');

Factory.define('InboundCommunicationSessionNotificationWithEmail')
  .extend('InboundCommunicationSessionNotificationWithDefaults')
  .attr('sessionType', 'EMAIL');

Factory.define('InboundCommunicationSessionNotificationWithFBMessage')
  .extend('InboundCommunicationSessionNotificationWithDefaults')
  .attr('sessionType', 'FB_MESSENGER');

Factory.define('InboundCommunicationSessionNotificationWithSMS')
  .extend('InboundCommunicationSessionNotificationWithDefaults')
  .attr('sessionType', 'SMS');

Factory.define('InboundCommunicationSessionNotificationWithVoicemail')
  .extend('InboundCommunicationSessionNotificationWithDefaults')
  .attr('sessionType', 'VOICEMAIL');

Factory.define('InboundCommunicationSessionNotificationWithWhatsApp')
  .extend('InboundCommunicationSessionNotificationWithDefaults')
  .attr('sessionType', 'WHATSAPP');
