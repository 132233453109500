import CollectionStoreSaveResultHandler from '../lib/collection_store_save_result_handler';
import DtoConverter from 'scripts/application/dto_converters/routing_group_converter';
import Inbox from 'models/location/inbox';
import mixin from 'scripts/lib/mixin';
import qconsole from 'scripts/lib/qconsole';
import UserEditorUpdater from '../users/lib/user_editor_updater';

export default class RoutingGroupGatewayObserver {
  constructor(context, userEditorUpdater = new UserEditorUpdater(context)) {
    this.context = context;
    this.userEditorUpdater = userEditorUpdater;
  }

  get store() {
    return this.context.stores.routingGroups;
  }

  handleRequestedRoutingGroups(routingGroupsDto) {
    this.store.set(routingGroupsDto.map(DtoConverter.fromDto));
    this.store.resetLoading();
    this.userEditorUpdater.update();
    this.refreshInboxIfNecessary();
  }

  handleRequestFetchError(payload) {
    qconsole.log(`Failed to fetch routingGroups list ${payload}`);
  }

  handleRoutingGroup(routingGroupDto) {
    this.store.addOrReplace(DtoConverter.fromDto(routingGroupDto));
  }

  handleDeleteRoutingGroup(routingGroupId) {
    this.store.remove(routingGroupId);
  }

  handleUpdateErrors(routingGroupId, body) {
    qconsole.log(`Failed to update ${routingGroupId} ${body}`);
  }

  handleDeleteError(routingGroupId) {
    qconsole.log(`Failed to delete routing group ${routingGroupId}`);
  }

  // if the team inbox view loads before the set of routing groups load (e.g.
  // on hard refresh of "/home/team") the application cannot determine the
  // default routing group. in this case, we re-trigger the route when the
  // routing groups are loaded
  refreshInboxIfNecessary() {
    let currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation instanceof Inbox && currentLocation.isTeam && !currentLocation.routingGroupId) {
      this.context.router.refresh();
    }
  }
}

mixin(RoutingGroupGatewayObserver.prototype, CollectionStoreSaveResultHandler.prototype);
