import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 60 60" {...props} className="bookMagnifyingGlassIcon">
    <g fill="none" fillRule="evenodd">
      <circle className="bookMagnifyingGlassIcon-circle" cx="30" cy="30" r="30" />
      <g className="bookMagnifyingGlassIcon-stroke" strokeLinejoin="round" strokeWidth="1.5">
        <path d="M30.592 42.438h-9.75c-1.257 0-2.274-1.01-2.274-2.25V18.811" strokeLinecap="round" />
        <path
          d="M40.16 16.563H20.84c-1.254 0-2.272 1.006-2.272 2.25 0 1.242 1.018 2.605 2.273 2.605h19.32v8.11M21 19h18.507"
          strokeLinecap="round"
        />
        <path d="M39.716 35.413c0 2.365-1.936 4.28-4.324 4.28-2.387 0-4.323-1.915-4.323-4.28 0-2.367 1.935-4.283 4.322-4.283 2.388 0 4.324 1.916 4.324 4.283z" />
        <path d="M38.455 38.443l3.977 3.937" strokeLinecap="round" />
      </g>
    </g>
  </IconContainer>
);
