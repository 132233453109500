export const COLORS = Object.freeze([
  '#5E5E5E',
  '#EF0000',
  '#F46216',
  '#00A800',
  '#0076E3',
  '#9549FF',
  '#DA00B8',
  '#000000',
  '#690000',
  '#B6470D',
  '#003A00',
  '#003063',
  '#450086',
  '#5F004F',
]);
