import moment from 'moment';
import Communicator from 'models/communicator';
import ConversationItemType from 'models/conversation_item_type';
import { hasErrorStatus as chatHasErrorStatus } from 'models/chat_message';
import { hasErrorStatus as fbMessageHasErrorStatus } from 'models/fb_message_outgoing';
import { hasErrorStatus as smsHasErrorStatus } from 'models/sms_message';
import { hasErrorStatus as conversationMessageHasErrorStatus } from 'models/conversation_message';

export default function isItemStackable(item, previousItem) {
  return (
    item &&
    isStackableType(item) &&
    doesNotHaveError(item) &&
    previousItem &&
    doesNotHaveError(previousItem) &&
    isSameType(item, previousItem) &&
    hasSameInitiator(item, previousItem) &&
    hasSameInitiatorType(item, previousItem) &&
    isInTimeFrame(item, previousItem) &&
    item.getIn(['initiator', 'type']) !== Communicator.AUTOMATED
  );
}

function hasSameInitiator(item, previousItem) {
  const previousInitiator = previousItem.getIn(['initiator', 'id']);
  const currentInitiator = item.getIn(['initiator', 'id']);

  return previousInitiator && currentInitiator && previousInitiator === currentInitiator;
}

function hasSameInitiatorType(item, previousItem) {
  const previousInitiator = previousItem.getIn(['initiator', 'type']);
  const currentInitiator = item.getIn(['initiator', 'type']);

  return previousInitiator && currentInitiator && previousInitiator === currentInitiator;
}

function isInTimeFrame(item, previousItem) {
  let timeLimit = moment(previousItem.get('timestamp')).add(10, 'minutes');
  return moment(item.get('timestamp')).isBefore(timeLimit);
}

function isSameType(item, previousItem) {
  if (item.getIn(['content', 'type']) === ConversationItemType.CONVERSATION_MESSAGE) {
    return (
      item.getIn(['content', 'type']) === previousItem.getIn(['content', 'type']) &&
      item.getIn(['content', 'channel']) === previousItem.getIn(['content', 'channel'])
    );
  }
  if (item.getIn(['content', 'type']) === ConversationItemType.CUSTOM_CHANNEL_MESSAGE) {
    return (
      item.getIn(['content', 'type']) === previousItem.getIn(['content', 'type']) &&
      item.getIn(['content', 'groupId']) === previousItem.getIn(['content', 'groupId'])
    );
  }
  return item.getIn(['content', 'type']) === previousItem.getIn(['content', 'type']);
}

const STACKABLE_TYPES = [
  ConversationItemType.CHAT_MESSAGE,
  ConversationItemType.CONVERSATION_MESSAGE,
  ConversationItemType.CONVERSATION_NOTE,
  ConversationItemType.CUSTOM_CHANNEL_MESSAGE,
  ConversationItemType.FB_MESSAGE_INCOMING,
  ConversationItemType.FB_MESSAGE_OUTGOING,
  ConversationItemType.MESSAGE_AUTOMATION_MESSAGE,
  ConversationItemType.SMS,
];

function isStackableType(item) {
  let type = item.getIn(['content', 'type']);
  return STACKABLE_TYPES.indexOf(type) >= 0;
}

function doesNotHaveError(item) {
  return !(
    (item.getIn(['content', 'type']) === ConversationItemType.SMS &&
      smsHasErrorStatus(item.getIn(['content', 'status', 'value']))) ||
    (item.getIn(['content', 'type']) === ConversationItemType.FB_MESSAGE_OUTGOING &&
      fbMessageHasErrorStatus(item.getIn(['content', 'response', 'status']))) ||
    (item.getIn(['content', 'type']) === ConversationItemType.CHAT_MESSAGE &&
      chatHasErrorStatus(item.getIn(['content', 'status', 'value']))) ||
    (item.getIn(['content', 'type']) === ConversationItemType.CONVERSATION_MESSAGE &&
      conversationMessageHasErrorStatus(item.getIn(['content', 'status', 'value'])))
  );
}
