export function isEditorEmpty(value) {
  const { document } = value;
  if (document.nodes.count() > 1) {
    return false;
  }

  // If there's a greeting suggestion, the editor will have text, but we want to consider the editor to be empty
  const greetingSuggestionInline = document
    .getInlines()
    .find(i => i.type === 'phrase_suggestion' && i.get('data').get('type') === 'GREETING');
  if (greetingSuggestionInline) {
    return true;
  }

  return !document.text;
}

export function isContentEqual(editorValueOne, editorValueTwo) {
  return editorValueOne.document === editorValueTwo.document;
}

export function hasInlineParent(editor, textNode) {
  let originalPath = editor.value.document.getPath(textNode.key);
  // Iterate through each parent in the textNode's path, see if any of them is of type 'inline'
  let parentPath = originalPath.slice(0, originalPath.count() - 1);
  while (parentPath.count() > 0) {
    let parentNode = editor.value.document.getNode(parentPath);
    if (parentNode.object === 'inline') {
      return true;
    }
    parentPath = parentPath.slice(0, parentPath.count() - 1);
  }
  return false;
}
