import _ from 'lodash';

import hasLiveConnection from './lib/has_live_connection';

export default class ActiveCallAgentObserver {
  constructor(context) {
    this.context = context;
  }

  // After a browser refresh, it's possible that the active call is fetched before the current agent's profile
  // Active call's `hasLivePhoneConnection` is set based on the callLegType for the current agent.
  // The active call needs to be updated with the correct connection status after the current agent profile is loaded
  handleAgentProfile(agentProfileDto) {
    let activeCall = this.context.stores.activeCall.get();
    if (!activeCall) {
      return;
    }
    let currentAgentId = _(this.context.stores.currentAgent.get()).get('id');
    if (currentAgentId === agentProfileDto.id) {
      activeCall.setHasLiveConnection(hasLiveConnection(this.context, { activeCall }));
      this.context.stores.activeCall.set(activeCall);
    }
  }
}
