import createEnum from 'scripts/lib/create_enum';

const Languages = Object.freeze({
  da: 'Danish',
  de: 'German',
  el: 'Greek',
  'en-150': 'English - Europe',
  'en-au': 'English - Australia',
  'en-ca': 'English - Canada',
  'en-gb': 'English - United Kingdom',
  'en-ie': 'English - Ireland',
  'en-nz': 'English - New Zealand',
  'en-us': 'English - United States',
  'es-419': 'Spanish - Latin America',
  'es-es': 'Spanish - Spain',
  'es-mx': 'Spanish - Mexico',
  'es-us': 'Spanish - United States',
  et: 'Estonian',
  fi: 'Finnish',
  fil: 'Filipino',
  fr: 'French',
  'fr-be': 'French - Belgium',
  'fr-ca': 'French - Canada',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  id: 'Indonesian',
  is: 'Icelandic',
  it: 'Italian',
  ja: 'Japanese',
  ka: 'Georgian',
  ko: 'Korean',
  lv: 'Latvian',
  lt: 'Lithuanian',
  ms: 'Malay',
  nl: 'Dutch',
  'nl-be': 'Dutch - Belgium',
  no: 'Norwegian',
  pl: 'Polish',
  'pt-br': 'Portuguese - Brazil',
  'pt-pt': 'Portuguese - Portugal',
  ro: 'Romanian',
  ru: 'Russian',
  sidekick: 'Sidekick',
  sk: 'Slovak',
  sl: 'Slovenian',
  sr: 'Serbian',
  'sr-ME': 'Serbian - Montenegro',
  sv: 'Swedish',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  vi: 'Vietnamese',
  'zh-hans': 'Chinese - Simplified',
  'zh-hant': 'Chinese - Traditional',
});

export const RTL_LANGUAGES = createEnum('ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi');

export const lngDirection = language => {
  if (RTL_LANGUAGES[language]) {
    return 'rtl';
  }
  return 'ltr';
};

export default Languages;
