import createModel, { prop } from 'models/lib/create_model';
import ServerClock from 'scripts/application/lib/server_clock';

const PAGE_SIZE = 20;

const AgentInboxMeta = createModel({
  modelName: 'AgentInboxMeta',

  properties: {
    lastRequestedAt: prop(String),
    limit: prop(Number).default(PAGE_SIZE),
  },

  setLimit(limit) {
    this.limit = limit;
  },

  setLastRequestedAtToNow() {
    this.lastRequestedAt = ServerClock.toISOString();
  },

  statics: {
    PAGE_SIZE,
  },
});

export default AgentInboxMeta;
