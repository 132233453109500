import React from 'react';
import styled from 'styled-components';

import FromField from './from_field';
import { Header, NewHeaderText } from 'components/composer/shared/composer_new';
import { OpenerButton } from 'components/common/dropdown_menu';
import ToField from './to_field';

export default function AISMSHeader({ editorProps, isInvalidNumber, phoneNumbers }) {
  return (
    <Container>
      <Header>
        <Left>
          <NewHeaderText>SMS</NewHeaderText>
          <ToField isInvalidNumber={isInvalidNumber} phoneNumbers={phoneNumbers} />
        </Left>
        <Right>
          <SmallerFromField />
        </Right>
      </Header>
    </Container>
  );
}

const Container = styled.div``;
const Left = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;
const Right = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 1;
  min-width: 0;
`;
const SmallerFromField = styled(FromField)`
  ${OpenerButton} {
    min-height: 30px;
    padding: 6px 12px;
  }
`;
