import _ from 'lodash';
import classnames from 'classnames';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';

export default class CircleIcon extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }
  render() {
    return (
      <svg className={classnames(this.props.className, 'circleIcon')} {..._.omit(this.props, 'className')}>
        <g className="circleIcon-icon">{this.props.children}</g>
        <g className="circleIcon-circleHoverGroup">
          <circle className="circleIcon-circleHover" cx="12.5" cy="12.5" r="12" />
        </g>
        <g className="circleIcon-circleGroup">
          <circle className="circleIcon-circle" cx="12.5" cy="12.5" r="12" />
        </g>
      </svg>
    );
  }
}
