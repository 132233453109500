import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Instagram Fill
 */

export default function InstagramFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path
        clipRule="evenodd"
        d="M3.495.09C1.844.443.552 1.688.107 3.357c-.143.532-.143 8.769 0 9.301a4.511 4.511 0 003.237 3.237c.532.143 8.769.143 9.301 0 1.596-.426 2.778-1.6 3.236-3.214.135-.475.166-8.505.036-9.14A4.43 4.43 0 0012.473.087C11.905-.03 4.048-.028 3.495.09zm10.276 1.857c.553.324.543 1.137-.017 1.465-.748.439-1.604-.414-1.166-1.161a.84.84 0 011.183-.304zM9.086 3.73c3.29.862 4.426 4.912 2.06 7.35-2.152 2.218-5.841 1.586-7.16-1.226C2.43 6.53 5.536 2.8 9.087 3.73zm-1.82 1.69c-2.347.676-2.646 3.931-.46 4.997a2.676 2.676 0 003.863-2.41c0-1.778-1.707-3.077-3.404-2.588z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
