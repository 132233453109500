import React from 'react';

export default function InternalEmailIcon() {
  return (
    <svg className="internalEmailModal-icon" fill="none" viewBox="0 0 58 49" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M0 0h58v49H0z" fill="#fff" />
        <path
          d="M2.741 48h52.518a1 1 0 0 0 .864-1.504L29.863 1.481a1 1 0 0 0-1.727 0L1.877 46.496A1 1 0 0 0 2.741 48z"
          stroke="#111"
          strokeWidth="2"
        />
        <g fill="#111">
          <path d="M30 14a1 1 0 1 0-2 0h2zm-2 20a1 1 0 1 0 2 0h-2zm0-20v20h2V14h-2z" />
          <circle cx="29" cy="40" r="2" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h58v49H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
