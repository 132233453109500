import { produce } from 'immer';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

export const DEFAULT_CONTEXT = {};

const TextEditorContext = React.createContext(DEFAULT_CONTEXT);
export default TextEditorContext;

export function useTextEditorContext() {
  return useContext(TextEditorContext);
}

export function useTextEditorContextValue(editorRef) {
  const [data, setEditorData] = useState({});

  // Store data in a ref so we don't have to recreate the callback each time
  // data changes.
  const editorDataRef = useRef(data);
  useEffect(() => {
    editorDataRef.current = data;
  }, [data]);

  // Will use like this: setDataValue('key', 123) so that user doesn't have to
  // worry about preserving other values in the data context object.
  const setDataValue = useCallback(
    (key, value) => {
      const newData = produce(editorDataRef.current, draft => {
        draft[key] = value;
      });
      setEditorData(newData);
    },
    [editorDataRef]
  );

  // useMemo necessary otherwise the context value will change identity each render,
  // which will lead to unnecessary re-renders below.
  return useMemo(() => ({ data, editorRef, setDataValue }), [data, setDataValue, editorRef]);
}
