import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Settings Fill
 */

export default function SettingsFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M6.25 1.5C6.25 1.22386 6.47386 1 6.75 1H9.25C9.52614 1 9.75 1.22386 9.75 1.5V3.04873C9.92563 3.11081 10.097 3.18197 10.2635 3.26164L11.3587 2.16637C11.554 1.9711 11.8706 1.9711 12.0659 2.16637L13.8336 3.93413C14.0289 4.12939 14.0289 4.44598 13.8336 4.64124L12.7383 5.73651C12.818 5.90301 12.8892 6.07436 12.9513 6.25H14.5C14.7761 6.25 15 6.47386 15 6.75V9.25C15 9.52614 14.7761 9.75 14.5 9.75H12.9513C12.8892 9.92564 12.818 10.097 12.7383 10.2635L13.8336 11.3588C14.0289 11.554 14.0289 11.8706 13.8336 12.0659L12.0659 13.8336C11.8706 14.0289 11.554 14.0289 11.3587 13.8336L10.2635 12.7384C10.097 12.818 9.92563 12.8892 9.75 12.9513V14.5C9.75 14.7761 9.52614 15 9.25 15H6.75C6.47386 15 6.25 14.7761 6.25 14.5V12.9513C6.07436 12.8892 5.90301 12.818 5.73652 12.7383L4.64123 13.8336C4.44597 14.0289 4.12939 14.0289 3.93413 13.8336L2.16636 12.0659C1.9711 11.8706 1.9711 11.554 2.16636 11.3588L3.26165 10.2635C3.18197 10.097 3.11081 9.92564 3.04873 9.75H1.5C1.22386 9.75 1 9.52614 1 9.25V6.75C1 6.47386 1.22386 6.25 1.5 6.25H3.04873C3.11081 6.07436 3.18197 5.90302 3.26165 5.73653L2.16636 4.64124C1.9711 4.44598 1.9711 4.12939 2.16636 3.93413L3.93413 2.16637C4.12939 1.9711 4.44597 1.9711 4.64123 2.16637L5.73652 3.26165C5.90301 3.18197 6.07436 3.11081 6.25 3.04873V1.5ZM8 10.625C9.44975 10.625 10.625 9.44975 10.625 8C10.625 6.55025 9.44975 5.375 8 5.375C6.55025 5.375 5.375 6.55025 5.375 8C5.375 9.44975 6.55025 10.625 8 10.625Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
