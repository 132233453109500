import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import HoldMenuItem from 'components/phone_controls/participants/hold_menu_item';
import RemoveParticipantFromPhoneCall from 'actions/phone_call/remove_participant_from_phone_call';
import PhoneCall from 'models/phone_call';
import { MenuContainer, MenuItem } from 'components/lib/menu_deprecated';
import Tooltip from 'components/common/tooltip';

export class CallParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: null, nameHover: null };
    this.setHoverState = this.setHoverState.bind(this);
    this.setNameHoverState = this.setNameHoverState.bind(this);
    this.participantRef = React.createRef();
  }

  render() {
    const statusClasses = classnames('callParticipant-entry-status', {
      'callParticipant-entry-status-pulsing':
        this.props.participantStatus === PhoneCall.ParticipantStatus.DIALING ||
        this.props.participantStatus === PhoneCall.ParticipantStatus.DISCONNECTING,
      'callParticipant-entry-status-unavailable': PhoneCall.isParticipantUnavailable({
        status: this.props.participantStatus,
      }),
      'callParticipant-entry-status-activeMenu': !this.isDisabledStatus() && this.props.showMenuOnHover,
    });
    return (
      <div className={`callParticipant-entry ${this.props.className}`}>
        <div
          className="callParticipant-entry-name"
          onMouseEnter={this.setNameHoverState.bind(null, true)}
          ref={this.participantRef}
        >
          {this.renderParticipantName()}
        </div>
        <div
          className={statusClasses}
          onMouseEnter={this.setHoverState.bind(null, true)}
          onMouseLeave={this.setHoverState.bind(null, false)}
        >
          {this.renderParticipantStateOrMenuOptions()}
        </div>
      </div>
    );
  }

  setHoverState(state) {
    this.setState({ hover: state });
  }

  // Only used to rerender state and allow Tooltip to display. 'nameHover' value is not used
  setNameHoverState(state) {
    this.setState({ nameHover: state });
  }

  renderParticipantStateOrMenuOptions() {
    if (this.props.showMenuOnHover && this.state.hover && !this.isDisabledStatus()) {
      return (
        <MenuContainer
          button={<span className="callParticipant-menu-opener">Actions</span>}
          className="callParticipant-menu"
          ref="menu"
        >
          <HoldMenuItem participantId={this.props.participantId} />
          <MenuItem onSelect={this.props.onRemoveParticipant}>Remove from Call</MenuItem>
        </MenuContainer>
      );
    }
    return statusTextMap[this.props.participantStatus];
  }

  isDisabledStatus() {
    return (
      [
        PhoneCall.ParticipantStatus.BUSY,
        PhoneCall.ParticipantStatus.CANCELED,
        PhoneCall.ParticipantStatus.DIALING,
        PhoneCall.ParticipantStatus.DISCONNECTING,
        PhoneCall.ParticipantStatus.FAILED,
        PhoneCall.ParticipantStatus.HOLD_REQUESTED,
        PhoneCall.ParticipantStatus.HUNG_UP,
        PhoneCall.ParticipantStatus.NO_ANSWER,
      ].indexOf(this.props.participantStatus) !== -1
    );
  }

  renderParticipantName() {
    if (
      this.participantRef.current &&
      this.participantRef.current.scrollWidth > this.participantRef.current.offsetWidth
    ) {
      return (
        <Tooltip delay={500} message={this.props.displayName} position="top">
          {this.props.displayName}
        </Tooltip>
      );
    }
    return <span>{this.props.displayName}</span>;
  }
}

CallParticipant.propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  participantId: PropTypes.string.isRequired,
  participantStatus: PropTypes.string.isRequired,
  showMenuOnHover: PropTypes.bool.isRequired,
  onRemoveParticipant: PropTypes.func,
};

const statusTextMap = Object.freeze({
  [PhoneCall.ParticipantStatus.ACTIVE]: 'Connected',
  [PhoneCall.ParticipantStatus.BUSY]: 'Unavailable',
  [PhoneCall.ParticipantStatus.CANCELED]: 'Unavailable',
  [PhoneCall.ParticipantStatus.DIALING]: 'Calling...',
  [PhoneCall.ParticipantStatus.DISCONNECTING]: 'Disconnecting...',
  [PhoneCall.ParticipantStatus.FAILED]: 'Unavailable',
  [PhoneCall.ParticipantStatus.HUNG_UP]: 'Disconnected',
  [PhoneCall.ParticipantStatus.HOLD_REQUESTED]: 'Hold Requested...',
  [PhoneCall.ParticipantStatus.NO_ANSWER]: 'Unavailable',
  [PhoneCall.ParticipantStatus.ON_HOLD]: 'On Hold',
});

function mapExecuteToProps(executeAction, { participantId }) {
  return {
    onRemoveParticipant: () => executeAction(RemoveParticipantFromPhoneCall, { participantId }),
  };
}

export default connect(null, mapExecuteToProps)(CallParticipant);
