import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from '../lib/create_model';
import LiveboardType from 'models/liveboards/liveboard_type';
import OldestUnanswered from 'models/liveboards/oldest_unanswered';

export const ConversationsStats = createModel({
  modelName: 'ConversationsStats',
  properties: {
    openAssigned: Number,
    openUnassigned: Number,
    waiting: Number,
    closedToday: Number,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const SlaStats = createModel({
  modelName: 'SlaStats',
  properties: {
    over: Number,
    total: Number,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const TotalsStats = createModel({
  modelName: 'TotalsStats',
  properties: {
    groupId: String,
    groupIds: [String],
    agentsOnline: Number,
    conversations: ConversationsStats,
    currentWait: Number,
    sla: SlaStats,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const TopicsStats = createModel({
  modelName: 'TopicsStats',
  properties: {
    id: String,
    conversations: ConversationsStats,
    currentWait: Number,
    oldestUnanswered: OldestUnanswered,
    sla: SlaStats,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
    CalculatedValueTypes: createEnum(
      'NEW_CONVERSATIONS',
      'OPEN_CONVERSATIONS',
      'WAITING_CONVERSATIONS',
      'CLOSED_TODAY',
      'CURRENT_WAIT',
      'PCT_WITHIN_SLA'
    ),
  },

  calculateValue(calculatedValueType) {
    switch (calculatedValueType) {
      case TopicsStats.CalculatedValueTypes.NEW_CONVERSATIONS:
        return this.conversations.openUnassigned || 0;
      case TopicsStats.CalculatedValueTypes.OPEN_CONVERSATIONS:
        return this.conversations.openAssigned || 0;
      case TopicsStats.CalculatedValueTypes.WAITING_CONVERSATIONS:
        return this.conversations.waiting || 0;
      case TopicsStats.CalculatedValueTypes.CLOSED_TODAY:
        return this.conversations.closedToday || 0;
      case TopicsStats.CalculatedValueTypes.CURRENT_WAIT:
        return this.currentWait || 0;
      case TopicsStats.CalculatedValueTypes.PCT_WITHIN_SLA:
        return this.sla.total && this.sla.total > 0 ? (this.sla.total - this.sla.over) / this.sla.total : null;
      default:
        throw new Error(`[${calculatedValueType}] is not a valid calculated value type`);
    }
  },
});

const TopicsLiveboard = createModel({
  modelName: 'TopicsLiveboard',
  properties: {
    totals: TotalsStats,
    topics: [TopicsStats],
    timezone: prop(String).default('America/New_York'),
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },

  aggregateValue(calculatedValueType) {
    switch (calculatedValueType) {
      case TopicsStats.CalculatedValueTypes.NEW_CONVERSATIONS:
        return _.reduce(
          this.topics,
          (total, topic) => {
            return total + topic.conversations.openUnassigned;
          },
          0
        );
      case TopicsStats.CalculatedValueTypes.OPEN_CONVERSATIONS:
        return _.reduce(
          this.topics,
          (total, topic) => {
            return total + topic.conversations.openAssigned;
          },
          0
        );
      case TopicsStats.CalculatedValueTypes.WAITING_CONVERSATIONS:
        return _.reduce(
          this.topics,
          (total, topic) => {
            return total + topic.conversations.waiting;
          },
          0
        );
      case TopicsStats.CalculatedValueTypes.CLOSED_TODAY:
        return _.reduce(
          this.topics,
          (total, topic) => {
            return total + topic.conversations.closedToday;
          },
          0
        );
      case TopicsStats.CalculatedValueTypes.CURRENT_WAIT:
        return _.reduce(
          this.topics,
          (max, topic) => {
            return max > topic.currentWait ? max : topic.currentWait;
          },
          0
        );
      case TopicsStats.CalculatedValueTypes.PCT_WITHIN_SLA: {
        let sla = _.reduce(
          this.topics,
          (totals, topic) => {
            totals.over += topic.sla.over;
            totals.total += topic.sla.total;
            return totals;
          },
          { over: 0, total: 0 }
        );

        return this.calculatePctSla(sla.over, sla.total);
      }
      default:
        throw new Error(`[${calculatedValueType}] is not a valid calculated value type`);
    }
  },

  findTopicById(id) {
    let index = this.topics.findIndex(function(t) {
      return id ? t.id === id : t.id == null;
    });

    return index === -1 ? null : this.topics[index];
  },

  calculatePctSla(over, total) {
    return total && total > 0 ? (total - over) / total : null;
  },

  calculateValueById(id, calculatedValueType) {
    let topic = this.findTopicById(id);
    return topic ? topic.calculateValue(calculatedValueType) : null;
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: LiveboardType.TOPICS });
  },
});

export default TopicsLiveboard;
