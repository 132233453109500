import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const AssignmentType = createEnum('CUSTOMER', 'TASK');

const AgentAssignment = createModel({
  modelName: 'AgentAssignment',

  properties: {
    customerId: prop(String).isRequired,
    id: prop(String).isRequired,
    type: prop().oneOf(..._.values(AssignmentType)).isRequired,
  },

  statics: {
    Type: AssignmentType,
  },
});

export default AgentAssignment;
