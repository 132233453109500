import { SnippetChannel } from 'models/answers/snippet';
import TrackAnswerPanelView from 'actions/knowledge_base/track_answer_panel_view';
import UpdateSnippetCount from 'actions/knowledge_base/update_snippet_count';

const ANALYTICS_COUNT_DELAY_10 = 10000;

export default class StartTrackAnswerView {
  constructor(context) {
    this.context = context;
  }

  run({ answer, answerType, language }) {
    if (!answer || answerType !== SnippetChannel.INFO) {
      return;
    }
    this.context.scheduler.executeIn(
      ANALYTICS_COUNT_DELAY_10,
      getTrackAnswerId(answer.id, answerType, DELAY_10),
      TrackAnswerPanelView,
      {
        answer,
        channel: answerType,
        language,
        time: ANALYTICS_COUNT_DELAY_10,
      }
    );
    this.context.scheduler.executeIn(
      ANALYTICS_COUNT_DELAY_10,
      getTrackAnswerId(answer.id, answerType, COUNT),
      UpdateSnippetCount,
      answer.id
    );
  }
}

const DELAY_10 = '10';
const COUNT = 'count';

export { DELAY_10, COUNT };

export function getTrackAnswerId(answerId, answerType, trackingType) {
  return `${answerId}-${answerType}-${trackingType}`;
}
