import _ from 'lodash';
import ChatCompositionContent from 'models/composition/chat_composition_content';
import CustomerView from 'models/location/customer_view';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import GreetingSuggestion from 'models/greeting_suggestion';
import TrackEvent from 'actions/analytics/track_event';

export default class TrackPhraseSuggestionUsed {
  constructor(context) {
    this.context = context;
  }

  run({ charactersMatched, insertKey, phraseSuggestion, compositionId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    // TODO: handle other locations
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    // If we are unable to find any suggestions, then don't send analytics
    if (!phraseSuggestion) return;

    const location = currentLocation.toJs().name;
    const composition = getCompositionsStore(this.context).findBy({ id: compositionId });
    const contentType = composition && composition.toJs().content.type;

    let props = {
      compositionId,
      contentType,
      conversationId: currentLocation.currentConversationId,
      customerId: currentLocation.customerId,
      insertKey,
      location,
    };

    if (phraseSuggestion instanceof GreetingSuggestion) {
      this.context.executeAction(TrackEvent, {
        event: 'Greeting Suggestion Used',
        props: {
          greetingSuggestionScore: phraseSuggestion.score,
          greetingSuggestionId: phraseSuggestion.id,
          greetingSuggestionText: phraseSuggestion.text,
          ...props,
        },
      });
      return;
    }

    if (composition.content instanceof ChatCompositionContent) {
      props = {
        chatSessionId: _.get(composition, 'content.sessionId'),
        chatSessionItemId: _.get(composition, 'content.sessionItemId'),
        ...props,
      };
    }

    this.context.executeAction(TrackEvent, {
      event: 'Phrase Suggestion - Used',
      props: {
        countCharactersMatched: charactersMatched.length,
        countCharactersInserted: phraseSuggestion.text.length - charactersMatched.length,
        phraseSuggestionId: phraseSuggestion.id,
        ...props,
      },
    });
  }
}
