import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import CustomAttributeError from 'components/customer/summary/custom_attributes/custom_attribute_error';
import VisiblePercentCustomAttribute from 'components/customer/summary/custom_attributes/visible_percent_custom_attribute';
import VisibleStringCustomAttribute from 'components/customer/summary/custom_attributes/visible_string_custom_attribute';
import { tryStringify } from 'scripts/lib/try_stringify';
import qconsole from 'scripts/lib/qconsole';
import VisibleMultilineCustomAttribute from 'components/customer/summary/custom_attributes/visible_multiline_custom_attribute';

export default function VisibleCustomAttribute({ className, 'data-aid': dataAid, def, value, renderProps, location }) {
  if (!value && value !== 0) {
    return null;
  }

  // We received a value that we can't display. Render a surrogate "error field" instead
  if (!_.isString(value) && !_.isNumber(value)) {
    const sanitizedValue = _.truncate(_.trim(tryStringify(value)), { length: 25 });
    qconsole.error(`Custom Attribute '${def.attr}' expected a string or a number, received [${sanitizedValue}]`);

    return (
      <CustomAttributeError
        className={className}
        data-aid={`custom_attribute_${def.label}_error`}
        def={def}
        value={value}
      />
    );
  }

  switch (def.type) {
    case 'PERCENT':
      return (
        <VisiblePercentCustomAttribute
          className={className}
          data-aid={dataAid}
          def={def}
          renderProps={renderProps}
          value={value}
        />
      );
    case 'MULTILINE_TEXT':
      return (
        <VisibleMultilineCustomAttribute
          className={className}
          data-aid={dataAid}
          def={def}
          renderProps={renderProps}
          value={value}
        />
      );
    default:
      return (
        <VisibleStringCustomAttribute
          className={className}
          data-aid={dataAid}
          def={def}
          location={location}
          value={value}
        />
      );
  }
}

export function formatValue(value, defType) {
  if (defType === AttributeType.DATE) {
    if (value.indexOf('T') !== -1) {
      return moment(value).format('MMM D, YYYY h:mm A');
    }
    return moment(value).format('MMM D, YYYY');
  }
  return value;
}

VisibleCustomAttribute.propTypes = {
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  value: PropTypes.any,
  renderProps: PropTypes.object,
  location: PropTypes.shape({
    type: PropTypes.string,
    subType: PropTypes.string,
  }),
};
