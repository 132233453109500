import React from 'react';
import Icon from 'components/lib/icons/icon';

const CoBrowseOutline = props => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" {...props}>
      <path
        clipRule="evenodd"
        d="M22 4L2 4C1.44772 4 1 4.44772 1 5L1 17.5C1 18.0523 1.44772 18.5 2 18.5L22 18.5C22.5523 18.5 23 18.0523 23 17.5V5C23 4.44772 22.5523 4 22 4ZM2 3C0.895431 3 0 3.89543 0 5L0 17.5C0 18.6046 0.89543 19.5 2 19.5H11.5V22H7.5C7.22386 22 7 22.2239 7 22.5C7 22.7761 7.22386 23 7.5 23H11.5H12.5L16.5 23C16.7761 23 17 22.7761 17 22.5C17 22.2239 16.7761 22 16.5 22L12.5 22V19.5L22 19.5C23.1046 19.5 24 18.6046 24 17.5V5C24 3.89543 23.1046 3 22 3L2 3Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4 8.92857H3V7C3 6.44772 3.44772 6 4 6H5.71428V7H4V8.92857ZM20 8.92857H21V7C21 6.44772 20.5523 6 20 6H18.2857V7H20V8.92857ZM20 14.0714H21V16C21 16.5523 20.5523 17 20 17H18.2857V16H20V14.0714ZM4 14.0714H3V16C3 16.5523 3.44772 17 4 17H5.71429V16H4V14.0714ZM10.2857 16V17H13.7143V16H10.2857ZM13.7143 7V6H10.2857V7H13.7143Z"
        fillRule="evenodd"
      />
    </Icon>
  );
};

CoBrowseOutline.proptypes = {
  ...Icon.proptypes,
};

export default CoBrowseOutline;
