import _ from 'lodash';

import AgentPhonePreferences from './agent_phone_preferences';
import createModel, { prop } from './lib/create_model';
import PhoneNumber, { getValidationErrors as getPhoneValidationErrors } from './phone_number';

const AgentVoiceConfiguration = createModel({
  modelName: 'AgentVoiceConfiguration',

  properties: {
    phone: prop(PhoneNumber),
    preference: prop()
      .oneOf(..._.keys(AgentPhonePreferences))
      .default(AgentPhonePreferences.NONE),
    stationId: prop(String).default(''),
  },

  removePhoneNumber() {
    this.phone = null;
  },

  updatePhoneNumber(phone) {
    this.phone = PhoneNumber.create({
      original: phone,
    });
  },

  statics: {
    create(attrs) {
      let parsedAttrs = parseAttributes(attrs);
      return new this(parsedAttrs);
    },

    getValidationErrors,
  },
});

function parseAttributes(attrs) {
  if (attrs && attrs.phone) {
    let phone = PhoneNumber.create(attrs.phone);
    return { ...attrs, phone };
  }
  return attrs;
}

function getValidationErrors(props, errors) {
  if (props.phone) {
    errors = errors.concat(
      getPhoneValidationErrors({
        phoneNumber: props.phone.original,
        extension: props.phone.extension,
        regionCode: props.phone.regionCode,
      })
    );
  }
  return errors;
}

export default AgentVoiceConfiguration;
