import _ from 'lodash';

import qconsole from 'scripts/lib/qconsole';
import RoutableCounts from 'models/routable_counts';
import { RoutingChannel, RoutingPreferenceGroup } from 'models/agent_routing_preferences';

export default class RoutableInboxCountsGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleFetchRoutableCountsSuccess(byRoutingGroup) {
    let currentAgentId = _.get(this.context.stores.currentAgent.get(), 'id');

    let routableCounts = _(byRoutingGroup)
      .filter((counts, id) =>
        _.includes(_.get(this.context.stores.routingGroups.findBy({ id }), 'agentIds'), currentAgentId)
      )
      .reduce((attrs, counts) => {
        attrs[RoutingPreferenceGroup.MESSAGING] += counts[RoutingChannel.MESSAGING] || 0;
        attrs[RoutingPreferenceGroup.VOICE] += counts[RoutingChannel.VOICE] || 0;
        const taskCount = counts[RoutingChannel.TASK] || 0;
        const nonInteractiveCount = (counts[RoutingChannel.MAIL] || 0) + taskCount;
        attrs[RoutingPreferenceGroup.NON_INTERACTIVE] += nonInteractiveCount;
        attrs[RoutingPreferenceGroup.TASK] += taskCount;

        return attrs;
      }, RoutableCounts.create().toJs());

    this.context.stores.routableCounts.set(RoutableCounts.fromJs(routableCounts));
  }

  handleFetchRoutableCountsError(errorDto) {
    qconsole.log(`Failed to fetch routable inbox counts ${JSON.stringify(errorDto)}`);
  }
}
