import _ from 'lodash';
import createModel from './lib/create_model';

var CustomerNote = createModel({
  modelName: 'CustomerNote',

  properties: {
    content: String,
  },

  withContent(content) {
    return new CustomerNote(_.merge({}, this, { content }));
  },

  updateContent(note) {
    this.content = note;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CustomerNote;
