import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import AgentStatusButton from './agent_status_button';
import AgentStatusTooltip from './agent_status_tooltip';
import connect from 'components/lib/connect';
import { EndpointTypes } from 'models/endpoint';
import InteractionType, { baseInteractionType, customId } from 'models/interaction_type';
import Messaging from './icons/messaging';
import MessagingSelected from './icons/messaging_selected';
import registerHotkey from 'components/hotkeys/register_hotkey';
import RoutableCounts from 'models/routable_counts';
import { RoutingChannel, RoutingPreferenceGroup } from 'models/agent_routing_preferences';
import UpdateAgentRoutingPreferences from 'actions/routing/update_agent_routing_preferences';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useKeyboardShortcut from 'components/hooks/use_keyboard_shortcut';

const hotkey = registerHotkey({
  key: 'alt+3',
  group: 'Availability',
  label: 'Toggle readiness for messaging',
});

export function MessagingStatusButton({ channels, customChannels, isFocusOn, isLoading, isPreferred, routableCounts }) {
  const executeAction = useExecuteAction();
  const handleClick = useCallback(() => {
    executeAction(UpdateAgentRoutingPreferences, { channels: { [RoutingChannel.MESSAGING]: !isPreferred } });
  }, [isPreferred]);
  useKeyboardShortcut(hotkey, handleClick);

  if (isFocusOn) {
    return <StyledDiv>{isPreferred ? <MessagingSelected /> : <Messaging />}</StyledDiv>;
  }

  return (
    <div className={classnames('messagingStatusButton', { disabled: isLoading })}>
      <AgentStatusTooltip
        message={getTooltipText(channels, customChannels)}
        preferenceGroup={RoutingPreferenceGroup.MESSAGING}
      >
        {({ isHovered }) => (
          <AgentStatusButton
            onClick={handleClick}
            preferenceGroup={RoutingPreferenceGroup.MESSAGING}
            routableCounts={routableCounts}
            showTrueCount={isHovered}
          >
            {isPreferred ? <MessagingSelected /> : <Messaging />}
          </AgentStatusButton>
        )}
      </AgentStatusTooltip>
    </div>
  );
}

MessagingStatusButton.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.oneOf([
      EndpointTypes.CHAT,
      EndpointTypes.CUSTOM_CHANNEL,
      EndpointTypes.FB_MESSENGER,
      EndpointTypes.INSTAGRAM_DIRECT,
      EndpointTypes.SMS,
      EndpointTypes.TWITTER,
      EndpointTypes.WHATSAPP,
    ])
  ).isRequired,
  isFocusOn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPreferred: PropTypes.bool.isRequired,
  routableCounts: PropTypes.instanceOf(RoutableCounts),
};

function getTooltipText(channels, customChannels) {
  return channels
    .filter(channel => isChannelActive(channel, customChannels))
    .map(channel => getChannelName(channel, customChannels))
    .filter(channelName => !!channelName)
    .sort()
    .join(', ');
}

function getChannelName(channel, customChannels) {
  const interactionType = baseInteractionType(channel);

  if (interactionType === InteractionType.CUSTOM_CHANNEL) {
    const customChannelId = customId(channel);
    const customChannel = _.find(customChannels, { id: customChannelId });
    return customChannel?.name ?? '';
  }

  switch (interactionType) {
    case EndpointTypes.CHAT:
      return 'Chat';
    case EndpointTypes.SMS:
      return 'SMS';
    case EndpointTypes.FB_MESSENGER:
      return 'FB Messenger';
    case EndpointTypes.INSTAGRAM_DIRECT:
      return 'Instagram Messaging';
    case EndpointTypes.WHATSAPP:
      return 'WhatsApp';
    case EndpointTypes.TWITTER:
      return 'Twitter';
    default:
      return '';
  }
}

function isChannelActive(channel, customChannels) {
  const interactionType = baseInteractionType(channel);

  if (interactionType === InteractionType.CUSTOM_CHANNEL) {
    const customChannelId = customId(channel);
    const customChannel = _.find(customChannels, { id: customChannelId });
    return !customChannel?.archived;
  }

  return true;
}

export default connect(mapStateToProps)(MessagingStatusButton);

function mapStateToProps({ getProvider }, { channels }) {
  const preferencesProvider = getProvider('agentRoutingPreferences');
  const preferences = preferencesProvider.getPending() || preferencesProvider.get();
  const isPreferred = preferences.isPreferredOnChannel(RoutingChannel.MESSAGING);
  const routableCounts = getProvider('routableCounts').get();

  const isCustomChannelsEnabled = getProvider('customChannelsConfig').get().enabled;
  const customChannels = getProvider('customChannels').findAll();

  const realtimeChannels = _.intersection(channels, [
    EndpointTypes.CHAT,
    EndpointTypes.CUSTOM_CHANNEL,
    EndpointTypes.FB_MESSENGER,
    EndpointTypes.INSTAGRAM_DIRECT,
    EndpointTypes.SMS,
    EndpointTypes.TWITTER,
    EndpointTypes.WHATSAPP,
  ]);

  if (isCustomChannelsEnabled) {
    const availableCustomChannels = _.filter(
      channels,
      channel => channel.startsWith(EndpointTypes.CUSTOM_CHANNEL) && !channel.archived
    );

    if (availableCustomChannels.length) {
      realtimeChannels.push(...availableCustomChannels);
    }
  }

  return {
    channels: realtimeChannels,
    customChannels,
    isFocusOn: preferences.isFocusOn,
    isLoading: preferences._version === 0,
    isPreferred,
    routableCounts,
  };
}

const StyledDiv = styled.div`
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
`;
