import React from 'react';
import PropTypes from 'prop-types';

function PercentCustomAttributeGraphic({ value, highWaterMark }) {
  const width = Math.min(100, (value * 100) / highWaterMark);

  const fillStyle = {
    width: `${width}%`,
  };

  return (
    <div className="percentCustomAttribute-background">
      <div className="percentCustomAttribute-fill" style={fillStyle} />
    </div>
  );
}

PercentCustomAttributeGraphic.propTypes = {
  value: PropTypes.number,
  highWaterMark: PropTypes.number,
};

export default PercentCustomAttributeGraphic;
