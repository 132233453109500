import isArray from 'lodash/isArray';

import qconsole from 'scripts/lib/qconsole';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import Integration from 'models/integration';

export default class IntegrationsGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchAllSuccess(data) {
    this.handleUpdateAll(data);
  }

  onBroadcast(data) {
    this.handleUpdateItem(data);
  }

  onFetchAllError(error) {
    qconsole.log(`Unexpected error requesting integrations list: ${JSON.stringify(error)}`);
  }

  handleUpdateAll(data) {
    this.context.stores.integrations.resetLoading();
    if (!isArray(data)) {
      ErrorReporter.reportError(new Error('IntegrationsGatewayObserver error'), {
        message: `Unexpected data. Expected array, got ${JSON.stringify(data)}`,
      });
      return;
    }

    this.context.stores.integrations.set(data.map(integration => Integration.fromJs(integration)));
  }

  handleUpdateItem(data) {
    if (!data || !data.id) {
      ErrorReporter.reportError(new Error('IntegrationsGatewayObserver error'), {
        message: `Unexpected data. Expected integration data, got ${JSON.stringify(data)}`,
      });
      return;
    }

    this.context.stores.integrations.addOrReplace(Integration.fromJs(data));
  }
}
