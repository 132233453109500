import React, { useMemo } from 'react';

import connect from 'components/lib/connect';

export const DEFAULT_LIVEBOARD_CONTEXT = {
  sla: undefined,
  channel: undefined,
  agent: undefined,
};
const LiveboardContext = React.createContext(DEFAULT_LIVEBOARD_CONTEXT);
export default LiveboardContext;

export function LiveboardContextProviderBase({ sla, channel, agent, children }) {
  const value = useMemo(
    () => ({
      sla,
      channel,
      agent,
    }),

    [sla, channel, agent]
  );

  return <LiveboardContext.Provider value={value}>{children}</LiveboardContext.Provider>;
}

const LiveboardContextProvider = connect(mapStateToProps, null)(LiveboardContextProviderBase);
export { LiveboardContextProvider };

function mapStateToProps({ getProvider }, { composition }) {
  const liveboard = getProvider('liveboard').get();
  if (!liveboard) {
    return null;
  }
  const { sla, channel, agent } = liveboard;

  return { sla, channel, agent };
}
