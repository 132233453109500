import OpenApiTokens from '../api_tokens/open_api_tokens';

export default class OpenIntegrations {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (
      !this.context.stores.appFeatures.get().isEnabled('integrationsManagement') &&
      !this.context.stores.appFeatures.get().isEnabled('devManagement')
    ) {
      this.context.router.navigateHome();
      return;
    }

    if (this.context.stores.appFeatures.get().isEnabled('devManagement')) {
      this.context.executeAction(OpenApiTokens);
    }
  }
}
