import React, { useContext, useRef } from 'react';

import { AISummarizeConversationMenuItem } from 'components/composer/legacy_composer_ai_support/ai_action_buttons';
import { Attachment, Emoji, Separator } from 'components/composer/shared/editor_action_buttons';
import { EditorContainer, useEditor } from 'components/composer/shared/editor';
import { RichTextEditorStyles } from 'components/composer/shared/editor_styles';
import AIActivityComposerContext from 'components/composer/contexts/ai_activity_composer_context';
import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import AIActivityMessage from 'components/composer/legacy_composer_ai_support/ai_activity_message';
import AITextCompletion from 'models/ai_text_completion';
import AddNote from 'actions/composer/add_note';
import Composer, { Header, HeaderText, useComposer } from 'components/composer/shared/composer';
import ComposerContext from 'components/composer/contexts/composer_context';
import InsertConversationSummary from 'components/text_editor/plugins/ai_actions/insert_conversation_summary';
import RichTextEditor from 'components/composer/editors/rich_text';

export function NoteComposer() {
  const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);
  const options = { trimEmptyParagraphs: true, trimParagraphWhitespace: true };
  options.createContent = createContent;

  const { requireReadonly, preventSubmission } = useContext(AIActivityComposerContext);
  const { composition } = useContext(ComposerContext);
  const composer = useComposer(AddNote, options);
  const editorProps = useEditor('bodyHtml', composer);
  const [composerProps] = composer;
  const placeholderText = 'Type to enter note';

  const initialReadonlyState = useRef(editorProps?.readOnly);
  const initialDisableSubmit = useRef(composerProps?.disableSubmit);

  const aiFeaturesEnabled = !agentAssistanceConfig.disableHeroAI;
  const aiLoadingStates = [{ type: AITextCompletion.Types.conversation_summary }];
  const aiTools = aiFeaturesEnabled
    ? [
        <AISummarizeConversationMenuItem
          className="summarize_conversation_menu_button"
          conversationId={composition?.conversationId}
          key="menu-ai-summarize-conversation-button"
        />,
        <Separator key="menu-ai-separator" />,
      ]
    : [];
  const aiActivityMessage = aiFeaturesEnabled ? (
    <AIActivityMessage loadingStatesToWatch={aiLoadingStates} messageText="Stand by. Summarizing the conversation" />
  ) : null;

  // Additional props we need to add in response to the running AI actions
  const aiExtraEditorProps = aiFeaturesEnabled
    ? {
        readOnly: !!(initialReadonlyState.current || requireReadonly),
      }
    : {};
  const aiExtraComposerProps = aiFeaturesEnabled
    ? {
        disableSubmit: !!(initialDisableSubmit.current || preventSubmission),
      }
    : {};

  // Combine all props we need to render the composer and the text editor
  const combinedComposerProps = {
    includeAttachments: true,
    submitText: 'Add Note',
    ...composerProps,
    ...aiExtraComposerProps,
  };
  const combinedEditorProps = {
    allowMentions: true,
    placeholder: placeholderText,
    ...editorProps,
    ...aiExtraEditorProps,
  };

  return (
    <Composer {...combinedComposerProps}>
      <Header>
        <HeaderText color="yellow400">Note</HeaderText>
        <RichTextEditorStyles {...editorProps} additionalMenuItems={aiTools}>
          <Emoji key="editor-emoji-styles" {...editorProps} />
          <Attachment alwaysShow key="editor-attachment-styles" />
        </RichTextEditorStyles>
      </Header>
      <EditorContainer includeAttachments>
        <RichTextEditor {...combinedEditorProps} />
      </EditorContainer>
      {aiActivityMessage}
      {aiFeaturesEnabled ? (
        <InsertConversationSummary
          conversationId={composition?.conversationId}
          customerId={composition?.customerId}
          editor={combinedEditorProps.editorRef.current}
          onChange={combinedEditorProps.onChange}
        />
      ) : null}
    </Composer>
  );
}

export default React.memo(NoteComposer);

function createContent(editor, content) {
  return {
    ...content,
    mentionedAgentIds: editor.getMentionedAgentIds(editor),
  };
}
