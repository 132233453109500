import classnames from 'classnames';
import React, { useCallback, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from 'components/lib/date/date_range/date_range_picker';
import DateRangePickerButton from 'components/lib/date_range_picker_button';
import { PortalledPopover } from 'components/common/lib/_popover';
import QuickPickPanel from 'components/lib/date/date_range/quick_pick_panel';
import TimePickerPanel from 'components/lib/date/date_range/time_picker_panel';
import useKeyboardShortcut from 'components/hooks/use_keyboard_shortcut';

export default function DateRangeFilter({
  className,
  endDate,
  isClearable,
  isDateTimePicker,
  onApply,
  onClear,
  position,
  quickPicks,
  renderPlaceholder,
  startDate,
  targetPosition,
}) {
  const [isPickerVisible, dispatchIsPickerVisible] = useReducer(isPickerVisibleReducer, false);
  const hidePicker = useCallback(() => dispatchIsPickerVisible('off'), []);
  const togglePicker = useCallback(() => dispatchIsPickerVisible('toggle'), []);
  useKeyboardShortcut({ key: 'escape' }, hidePicker);

  const [range, setRange] = useState({ startDate, endDate });
  useUpdateRangeWhenPropsChange(range, setRange, startDate, endDate);

  const handleClear = useCallback(() => {
    onClear && onClear();
    dispatchIsPickerVisible('off');
    setRange({ startDate: null, endDate: null });
  }, [onClear]);
  const handleApply = useCallback(newRange => {
    onApply && onApply(newRange);
    dispatchIsPickerVisible('off');
    setRange(newRange);
  });

  const [button, setButton] = useState(null);
  const classes = classnames('dateRange', className, {
    'dateRange-picker-visible': isPickerVisible,
  });

  return (
    <div className={classes}>
      <DateRangePickerButton
        endDate={range.endDate}
        onClear={isClearable ? handleClear : null}
        onClick={togglePicker}
        renderPlaceholder={renderPlaceholder}
        setRef={setButton}
        startDate={range.startDate}
      />
      <PortalledPopover
        className="dateRangeFilter"
        isVisible={isPickerVisible}
        onClickOutside={hidePicker}
        position={position}
        targetElement={button}
        targetPosition={targetPosition}
      >
        <DateRangePicker
          className="dateRange"
          endDate={range.endDate}
          onApply={handleApply}
          panel={isDateTimePicker ? TimePickerPanel : QuickPickPanel}
          quickPicks={quickPicks}
          startDate={range.startDate}
        />
      </PortalledPopover>
    </div>
  );
}

function useUpdateRangeWhenPropsChange(range, setRange, startDate, endDate) {
  useEffect(() => {
    if (startDate !== range.startDate || endDate !== range.endDate) {
      setRange({ startDate, endDate });
    }
  }, [startDate, endDate]);
}

function isPickerVisibleReducer(isVisible, type) {
  switch (type) {
    case 'on':
      return true;
    case 'off':
      return false;
    case 'toggle':
      return !isVisible;
    default:
      throw new Error();
  }
}

DateRangeFilter.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.string,
  isClearable: PropTypes.bool,
  isDateTimePicker: PropTypes.bool,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  position: PropTypes.string.isRequired,
  quickPicks: PropTypes.objectOf(PropTypes.func),
  renderPlaceholder: PropTypes.func,
  startDate: PropTypes.string,
  targetPosition: PropTypes.string,
};

DateRangeFilter.defaultProps = {
  isClearable: true,
  isDateTimePicker: false,
  startDate: null,
  endDate: null,
  quickPicks: {},
};
