import { AgentAvailability } from 'models/agent_status';

export default class BusyStatusPublisher {
  constructor(orgId, pubsub, database) {
    this.database = database;
    this.orgId = orgId;
    this.pubsub = pubsub;
  }

  publish(customer, conversation) {
    this.pubsub.publish(`v1/orgs/${this.orgId}/agent-status/${this.database.get().currentAgent.id}`, {
      payload: {
        id: this.database.get().currentAgent.id,
        availability: AgentAvailability.BUSY,
        updatedAt: new Date().toISOString(),
      },
    });
  }
}
