import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import KeypressEventHandler from 'components/lib/dom_controls/lib/keypress_event_handler';

// EditableFieldDumb is a "dumb" (unaware of Morearty bindings) react component
export default class EditableFieldDumb extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['focus', 'handleBlur', 'handleCancel', 'handleFocus', 'handleSubmit']);
  }

  focus() {
    this.refs.input.focus();
  }

  handleBlur(evt) {
    const newValue = evt.target.value;
    if (this.props.onBlur) {
      this.props.onBlur(newValue);
      return;
    }

    if (newValue !== this.props.value) {
      this.props.onSubmit(newValue);
    } else if (newValue === '') {
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    }
  }

  handleFocus(evt) {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.props.onSubmit(evt.target.value);
    evt.target.blur();
  }

  handleCancel(evt) {
    evt.target.value = this.props.value;
    evt.target.blur();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.refs.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.autoFocus && this.props.autoFocus) {
      this.refs.input.focus();
    }
    if (this.props.value !== this.refs.input.value) {
      this.refs.input.value = this.props.value;
    }
  }

  render() {
    const keypressEventHandler = new KeypressEventHandler(this.handleSubmit, this.handleCancel, { isInputField: true });
    return (
      <form className={this.props.formClass} onSubmit={this.handleSubmit}>
        <input
          className={this.props.className}
          data-aid={this.props.dataAid}
          defaultValue={this.props.value}
          maxLength={this.props.maxLength}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyDown={this.props.onKeyDown}
          placeholder={this.props.placeholder || 'Not available'}
          ref="input"
          {...keypressEventHandler.reactAttributes()}
        />
      </form>
    );
  }
}

EditableFieldDumb.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  dataAid: PropTypes.string,
  formClass: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onCancel: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
