import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'UsersUpload',
  properties: {
    successRowCount: prop(Number),
    processedRowCount: prop(Number),
    errors: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
