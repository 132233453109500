import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Item } from './slate_styles_menu.styles';
import TextIndentDecreaseIcon from 'components/common/icons/text_indent_decrease_icon';
import TextIndentIncreaseIcon from 'components/common/icons/text_indent_increase_icon';

// the pixels by which we indent
export const TEXT_INDENTATION_VALUE = 40;

export function SlateTextDecreaseIndentationMenu({ editor, onChange }) {
  const onDecreaseIndentation = useCallback(
    evt => {
      evt.preventDefault();
      editor.focus();
      return onChange(editor.decreaseIndentation());
    },
    [editor, onChange]
  );

  return (
    <Item data-aid="decreaseIndent" onClick={onDecreaseIndentation} title="Decrease Indentation">
      <StyledIndentDecreaseIcon />
    </Item>
  );
}

export function SlateTextIncreaseIndentationMenu({ editor, onChange }) {
  const onIncreaseIndentation = useCallback(
    evt => {
      evt.preventDefault();
      editor.focus();
      return onChange(editor.increaseIndentation());
    },
    [editor, onChange]
  );

  return (
    <Item data-aid="increaseIndent" onClick={onIncreaseIndentation} title="Increase Indentation">
      <StyledIndentIncreaseIcon />
    </Item>
  );
}

export function getIndentLevelFromMargin(marginLeft) {
  return marginLeft ? parseInt(marginLeft.replace(/px/, '')) / TEXT_INDENTATION_VALUE : undefined;
}

export function getMarginFromIndent(indent) {
  return indent ? indent * TEXT_INDENTATION_VALUE : undefined;
}

const StyledIndentIncreaseIcon = styled(TextIndentIncreaseIcon)`
  fill: ${p => p.theme.colors.gray900};
  height: 16px;
  width: 16px;
`;

const StyledIndentDecreaseIcon = styled(TextIndentDecreaseIcon)`
  fill: ${p => p.theme.colors.gray900};
  height: 16px;
  width: 16px;
`;

SlateTextDecreaseIndentationMenu.propTypes = {
  editor: PropTypes.object,
  onChange: PropTypes.func,
};
SlateTextIncreaseIndentationMenu.propTypes = {
  editor: PropTypes.object,
  onChange: PropTypes.func,
};
