import MqttGateway from './lib/mqtt_gateway';

export default class AnswerSuggestionGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customers/+/conversations/+/answer-suggestions';
  }

  fetch(customerId, conversationId, payload) {
    return super._fetch([customerId, conversationId], payload);
  }

  onFetchResponse(fetchResultDto, topic, parsedTopicParams) {
    this.notifyObservers('handleRequestedEntities', fetchResultDto);
  }

  onFetchError(errorDto, topic, parsedTopicParams) {
    this.notifyObservers('handleRequestError', errorDto);
  }
}
