import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import StickyFill from 'react-stickyfill';

import ConversationHeader from 'components/customer/conversation_history/conversation_header_container';
import ConversationItem from 'components/customer/conversation_history/conversation_item';
import CustomerTypingIndicator from 'components/customer/conversation_history/conversation_items_v2/content/customer_typing_indicator';
import { H1 } from 'components/common/headers';
import {
  CONVERSATION,
  CUSTOMER_TYPING_INDICATOR,
  ITEM,
  LAST_CONVERSATION_SPACER,
  UNREAD_DIVIDER,
} from './feed_item_types';
import { HeightChangeContext } from 'components/customer/conversation_history/virtualized/list_item';

export default class FeedItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps.item, this.props.item) || nextProps.isSticky !== this.props.isSticky;
  }

  render() {
    const { item, isSticky } = this.props;

    switch (item.type) {
      case CONVERSATION:
        if (!isSticky) {
          return <div className="conversationHeader-placeholder" />;
        }
        return (
          <StickyFill>
            <ConversationHeader conversationId={item.id} key={item.id} />
          </StickyFill>
        );
      case CUSTOMER_TYPING_INDICATOR:
        return <CustomerTypingIndicator />;
      case ITEM:
        return (
          <ConversationItem
            conversationId={item.conversationId}
            isFirstInStack={item.isFirstInStack}
            isLastInStack={item.isLastInStack}
            isStacked={item.isStacked}
            itemId={item.id}
            key={item.id}
            previousItemId={item.previousId}
          />
        );
      // Doesn't need to render - its height is accounted for in its sticky container
      case LAST_CONVERSATION_SPACER:
        return (
          <HeightChangeContext.Consumer>
            {({ height }) => <LastConversationSpacer height={height} isEmpty={item.isEmpty} />}
          </HeightChangeContext.Consumer>
        );
      case UNREAD_DIVIDER:
        return (
          <div className="unreadMessagesItem" key="unread">
            <div className="unreadMessagesItem-text">New Activity</div>
            <div className="unreadMessagesItem-line" />
          </div>
        );
    }

    throw new Error(`FeedItem cannot render an unknown item type [${item.type}]`);
  }
}

FeedItem.propTypes = {
  item: PropTypes.object.isRequired,
  isSticky: PropTypes.bool,
};

function LastConversationSpacer({ height, isEmpty }) {
  if (!isEmpty) {
    return <div className="lastConversationSpacer" style={{ height }} />;
  }

  return (
    <div className="emptyConversationHistory" style={{ height }}>
      <div className="emptyConversationHistory-message">
        <H1 data-aid="emptyConversationHistory-message-main">Empty Conversation</H1>
        <div className="emptyConversationHistory-message-detail">Click the plus menu to create an item</div>
      </div>
    </div>
  );
}

LastConversationSpacer.propTypes = {
  height: PropTypes.number,
  isEmpty: PropTypes.bool,
};
