import React from 'react';
import styled from 'styled-components';
import DropdownMenu from 'components/common/dropdown_menu';
import { H5 } from 'components/common/headers';
import connect from 'components/lib/connect';
import _ from 'lodash';

export function AudienceSelector({ options, setAudiences, audiences }) {
  function onSelect(value) {
    let audienceIds = [...audiences];
    const index = audienceIds.indexOf(value);
    if (index === -1) {
      audienceIds.push(value);
    } else {
      audienceIds.splice(index, 1);
    }
    setAudiences(audienceIds);
  }

  return (
    <React.Fragment>
      <Header>Audience</Header>
      {!options.length ? (
        <EmptyAudienceStyle>
          <a href="/admin/audiences">Create an Audience</a> now and start assigning them to your Answers.
        </EmptyAudienceStyle>
      ) : (
        <StyledDropdown
          isMultiSelect
          onSelect={onSelect}
          options={options}
          placeholder="Select Audience"
          searchPlaceholder="Search Audiences"
          searchable
          value={audiences}
        />
      )}
    </React.Fragment>
  );
}

const StyledDropdown = styled(DropdownMenu)`
  margin-top: ${p => p.theme.spacing.stackSmall};
  margin-left: ${p => (p.leftMargin ? '12px' : '0')};
  margin-right: ${p => (p.rightMargin ? '12px' : '0')};
  width: 220px;
`;

const Header = styled(H5)`
  margin-bottom: 0px;
  margin-top: 8px;
  padding-top: 4px;
`;

const EmptyAudienceStyle = styled.div`
  color: ${p => p.theme.colors.gray700};
  margin-top: ${p => p.theme.spacing.stackXSmall};

  a {
    font-weight: bold;
  }
`;

const AudienceSelectorContainer = connect(mapStateToProps)(AudienceSelector);

function mapStateToProps({ getProvider }) {
  let audiences = _.map(getProvider('audiences').findAll(), audience => ({
    value: audience.id,
    label: audience.name,
  }));
  audiences = audiences.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  return { options: audiences };
}

export default AudienceSelectorContainer;
