import _ from 'lodash';

import Factory from 'factories/all';
import PhoneCall from 'models/phone_call';

export default class OfferedCallPublisher {
  constructor(orgId, incomingCallService, database) {
    this.database = database;
    this.orgId = orgId;
    this.incomingCallService = incomingCallService;
  }

  publish(customer, conversation, conversationItem) {
    let agentId = this.database.get().currentAgent.id;
    let routingGroup = _.sample(this.database.get().routingGroups);
    if (conversationItem.content.status !== PhoneCall.IncomingCallType.WARM_TRANSFER) {
      this.incomingCallService.publishOfferedCall({
        orgId: this.orgId,
        agentId,
        customer,
        conversationId: conversation.id,
        conversationItem,
        routingGroupId: routingGroup.id,
      });
    } else {
      let initiator = Factory.build('Assignee', {
        agentId: conversation.assignee.agentId,
        routingGroupId: conversation.assignee.routingGroupId,
      });
      this.incomingCallService.publishOfferedWarmTransfer({
        orgId: this.orgId,
        agentId,
        customer,
        conversationId: conversation.id,
        conversationItem,
        initiator,
      });
    }
  }
}
