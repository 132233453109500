import { Factory } from 'rosie';

Factory.define('SuggestedReply')
  .attr('id')
  .attr('text')
  .attr('appliedSnippetRefs');

Factory.define('SuggestedReplySnippetDetails')
  .attr('id')
  .attr('name');

Factory.define('SuggestedReplyAppliedSnippetRef')
  .attr('audienceIds', [])
  .attr('language')
  .attr('snippetDetails', []);

Factory.define('SuggestedReplyAppliedSnippetRefsDefaults')
  .extend('SuggestedReplyAppliedSnippetRef')
  .attr('audienceIds', ['audience-id'])
  .attr('language', 'en-us')
  .attr('snippetDetails', [
    Factory.build(
      'SuggestedReplySnippetDetails',
      {
        id: 'snippet-detail-1',
        name: 'snippet detail 1 name',
      },
      {}
    ),
    Factory.build(
      'SuggestedReplySnippetDetails',
      {
        id: 'snippet-detail-2',
        name: 'snippet detail 2 name',
      },
      {}
    ),
  ]);

Factory.define('SuggestedReplyWithDefaults')
  .extend('SuggestedReply')
  .attr('id', 'suggested-reply-id')
  .attr('text', 'suggested reply text')
  .attr('appliedSnippetRefs', () => Factory.attributes('SuggestedReplyAppliedSnippetRefsDefaults'));
