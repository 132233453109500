import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import qconsole from 'scripts/lib/qconsole';

export default class ExternalDataObjectService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(attrs, callback, path, params, query) {
    const { orgId } = params || {};
    const { context, gladlyEntityType, gladlyEntityId, namespace, page, pageSize, refresh, requestor, delay } =
      query || {};

    if (!orgId) {
      return callback(Error(statusText(400)), {
        status: 400,
        statusText: 'Missing or invalid parameter "orgId"',
      });
    }

    if (refresh) {
      qconsole.log('ExternalDataObjectService.find: data refresh requested');
    }

    // Check whether we received all required params
    const requiredQueryParams = {
      context,
      namespace,
      gladlyEntityType,
      gladlyEntityId,
      requestor,
    };

    const missingParam = _.findKey(requiredQueryParams, value => !value);
    if (missingParam) {
      return callback(Error(statusText(400)), {
        status: 400,
        statusText: `Missing or invalid query parameter '${missingParam}'`,
      });
    }

    let database;
    try {
      database = this.getDatabase(orgId);
      if (!database) {
        return callback(Error(statusText(500)), { status: 500, statusText: `No database for org ${orgId}` });
      }
    } catch (err) {
      return callback(Error(statusText(500)), {
        status: 500,
        statusText: `Unable to get database for org ${orgId}: ${err.message}`,
      });
    }

    const dataObject = _.get(database, `externalAppData.${namespace}`);
    if (!dataObject) {
      return callback(Error(statusText(404)), { status: 404, statusText: statusText(404) });
    }

    const timeout = ~~delay * 1000; // This will be non-zero if delay is either a number or numeric string, and zero otherwise
    setTimeout(() => {
      const externalDataItems = _.isArray(dataObject) ? dataObject : [dataObject];
      const envelope = {
        context,
        namespace,
        page,
        pageSize,
        requestor,

        data: { externalDataItems, errors: [] },
      };

      callback(null, { status: 200, statusText: statusText(200), response: envelope });
    }, Math.max(timeout, 0));
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v2/orgs/:orgId/external-data': {
          GET: this.find,
        },
      },
      this
    );
  }
}
