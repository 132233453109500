import moment from 'moment';

import AgentActivity from 'models/agent_activity';
import Search from 'models/location/search';
import SearchFilter from 'models/search/search_filter';
import SearchQuery from 'models/search/search_query';
import SearchResult from 'models/search/search_result';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class NavigateToClosedConversationResults {
  constructor(context) {
    this.context = context;
  }

  run() {
    const currentAgentId = this.context.stores.currentAgent.get().id;
    let startDate = moment()
      .startOf('day')
      .format();
    let endDate = moment()
      .endOf('day')
      .format();

    let query = new SearchQuery({
      filter: new SearchFilter({
        type: SearchResult.HitTypes.CONVERSATION,
        agentIds: [currentAgentId],
        statuses: ['CLOSED'],
        updatedAt: { gte: startDate, lte: endDate },
      }),
    });

    let newSearch = Search.create({ query });
    this.context.router.navigateTo(newSearch);

    this.context.analytics.track('Closed Conversations Viewed');

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.SEARCH });
  }
}
