import CustomerProfileGatewayObserver from './customer_profile_gateway_observer';
import ActiveCallCustomerProfileObserver from '../phone_call/active_call_customer_profile_observer';
import ActiveSessionCustomerProfileObserver from '../communication_session/active_session_customer_profile_observer';

export default class CustomerProfileCompoundGatewayObserver {
  constructor(context) {
    this.context = context;
    this.customerProfileObserver = new CustomerProfileGatewayObserver(context);
    this.activeCallObserver = new ActiveCallCustomerProfileObserver(context);
    this.activeSessionObserver = new ActiveSessionCustomerProfileObserver(context);
  }

  get store() {
    return this.context.stores.customers;
  }

  onBroadcast(dto) {
    this.customerProfileObserver.handleEntity(dto);
    this.activeCallObserver.handleEntity(dto);
    this.activeSessionObserver.handleEntity(dto);
  }

  onBroadcastDelete(dto, { customerId }) {
    this.customerProfileObserver.handleDeleteProfile({ customerId });
  }

  onFetchSuccess(dto, { customerId }) {
    this.customerProfileObserver.handleFetchSuccess(dto, customerId);
    this.activeCallObserver.handleEntity(dto);
    this.activeSessionObserver.handleEntity(dto);
  }

  onFetchError(error, { customerId }) {
    this.customerProfileObserver.handleFetchError(customerId, error);
  }

  onUpdateSuccess(dto, { customerId }) {
    this.customerProfileObserver.handleSetSuccess(customerId, dto || {});
  }

  onUpdateError(error, { customerId }) {
    this.customerProfileObserver.handleSetErrors(customerId, error);
  }

  onAddSuccess(dto, params) {
    this.customerProfileObserver.handleAddSuccess({});
  }

  onAddError(error, params) {
    this.customerProfileObserver.handleAddErrors({ errorsDto: error });
  }

  onDeleteSuccess(dto, params) {
    this.customerProfileObserver.handleDeleteProfile(params);
  }
}
