import MqttGateway from './lib/mqtt_gateway';

export default class NoReplyNeededGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'agents/+/outgoing/no-reply-needed';
  }

  addNoReplyNeeded(agentId, noReplyNeededPayload) {
    return super._add([agentId], noReplyNeededPayload);
  }

  onAddSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleAddSuccess', payload);
  }

  onAddErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleAddErrors', errorDtos);
  }
}
