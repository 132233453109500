import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import DropdownMenu from 'components/common/dropdown_menu';
import { Languages } from 'models/answers/snippet_channels';
import SnippetsAdminFilter from './snippets_admin_filter';
import { SnippetContentType } from 'models/answers/snippet';

export const channelOptions = [
  { label: 'All Channels', value: '' },
  { label: 'Email', value: SnippetContentType.ANY_CHANNEL },
  { label: 'Messaging', value: SnippetContentType.MESSAGE },
  { label: 'Public', value: SnippetContentType.SELF_SERVICE },
  { label: 'Reference', value: SnippetContentType.INFO },
];

export function SnippetFilters({
  text,
  onChangeText,
  language,
  languages,
  onChangeLanguage,
  channel,
  onChangeChannel,
  selectedAudiences,
  audiences,
  onChangeAudiences,
}) {
  let languageText = _.get(
    _.find(languages, l => l.value === language),
    'label'
  );
  let channelText = _.get(
    _.find(channelOptions, c => c.value === channel),
    'label'
  );
  return (
    <StyledFilters>
      <SnippetsAdminFilter onChangeTextFilter={onChangeText} textFilter={text} />
      <StyledDropdownWrapper>
        <DropdownMenu
          clearable={false}
          onSelect={onChangeLanguage}
          options={languages}
          text={languageText}
          value={language}
        />
      </StyledDropdownWrapper>
      <StyledDropdownWrapper>
        <DropdownMenu onSelect={onChangeChannel} options={channelOptions} text={channelText} value={channel} />
      </StyledDropdownWrapper>
      {audiences.length > 0 ? (
        <StyledDropdownWrapper>
          <DropdownMenu
            isMultiSelect
            onSelect={onChangeAudiences}
            options={audiences}
            placeholder="All Audiences"
            searchPlaceholder="Search Audiences"
            searchable
            value={selectedAudiences}
          />
        </StyledDropdownWrapper>
      ) : (
        ''
      )}
    </StyledFilters>
  );
}

const StyledFilters = styled.div`
  align-items: center;
  display: flex;
  margin-left: 60px;
  margin-top: ${p => p.theme.spacing.xlarge};
`;

const StyledDropdownWrapper = styled.div`
  margin-left: ${p => p.theme.spacing.inlineMedium};

  button {
    max-width: 175px;
  }
`;

SnippetFilters.propTypes = {
  channel: PropTypes.string,
  language: PropTypes.string,
  languages: PropTypes.array,
  onChangeChannel: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  text: PropTypes.string,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const configuredLanguagesProvider = getProvider('languages');
  let languages = [{ label: 'All Languages', value: '' }].concat(
    _.sortBy(
      configuredLanguagesProvider.findAll().map(({ code }) => ({ value: code, label: Languages[code] })),
      'label'
    )
  );
  let audiences = _.map(getProvider('audiences').findAll(), audience => ({
    value: audience.id,
    label: audience.name,
  }));
  audiences = audiences.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  return { languages, audiences };
}

export default connect(mapStateToProps)(SnippetFilters);
