import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import hasForgottenAttachment from './has_forgotten_attachment';
import hasForgottenToWriteStuff from './has_forgotten_to_write_stuff';
import PlaintextSerializer from 'components/text_editor/serializers/plaintext_serializer';
import { useVariables } from 'components/composer/contexts/variables_context';

export default function useEmailComposer(composerRef, { defaultBody, onSubmit }) {
  const variables = useVariables();
  const [forgottenAttachmentText, setForgottenAttachmentText] = useState(null);
  const clearForgottenAttachmentText = useCallback(() => setForgottenAttachmentText(null), []);
  const [showForgottenTextModal, setShowForgottenTextModal] = useState(false);
  const clearShowForgottenTextModal = useCallback(() => setShowForgottenTextModal(false), []);

  const { composition, compositionErrors } = useContext(ComposerContext);

  const decoratedOnSubmit = useCallback(
    (evt, forceSubmit) => {
      const editorValue = composerRef.current.editorValues.bodyHtml;

      const hasAttachments = composition.attachments.length > 0;
      const bodyPlain = PlaintextSerializer.serialize(editorValue);
      const [shouldShowForgottenAttachmentModal, matchingText] = hasForgottenAttachment(bodyPlain, hasAttachments);
      if (shouldShowForgottenAttachmentModal && !forceSubmit) {
        setForgottenAttachmentText(matchingText);
        return;
      }

      const shouldShowForgottenTextModal = hasForgottenToWriteStuff(bodyPlain, defaultBody, variables);
      if (shouldShowForgottenTextModal && !forceSubmit) {
        setShowForgottenTextModal(true);
        return;
      }

      onSubmit();
    },
    [composerRef, composition, onSubmit]
  );

  const prevCompositionErrorsRef = useRef(null);
  useEffect(() => {
    // If the errors have changed, close the modals
    if (prevCompositionErrorsRef.current !== compositionErrors && compositionErrors && compositionErrors.length) {
      setShowForgottenTextModal(false);
      setForgottenAttachmentText(null);
    }
    if (prevCompositionErrorsRef.current !== compositionErrors) {
      prevCompositionErrorsRef.current = compositionErrors;
    }
  }, [compositionErrors, prevCompositionErrorsRef.current]);

  return {
    clearForgottenAttachmentText,
    clearShowForgottenTextModal,
    forgottenAttachmentText,
    onSubmit: decoratedOnSubmit,
    showForgottenTextModal,
  };
}
