import createModel, { prop } from './lib/create_model';

const Reaction = createModel({
  modelName: 'Reaction',

  properties: {
    content: prop(Object).default({}),
    initiator: prop({
      type: String,
      id: String,
    }).isRequired,
    timestamp: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Reaction;
