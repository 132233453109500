import { convertToHTML as toHTML, convertFromHTML as fromHTML } from 'draft-convert';

import { ALIGNMENT_DATA_KEY } from './draft_alignment';
import configureIdGenerator from 'scripts/configuration/configure_id_generator';
import createEnum from 'scripts/lib/create_enum';
import { CUSTOM_STYLE_PREFIX_COLOR } from './draft_custom_styles';
import { ENTITY_TYPE_INLINE_IMAGE } from './draft_inline_image';
import { ENTITY_TYPE_LINK } from './draft_link';
import { fromOptions, toOptions } from './draft_convert';
import { IMMUTABLE, MUTABLE } from './draft_shared';

const Sizes = createEnum('SMALL', 'NORMAL', 'LARGE', 'HUGE');

export function convertToEmailHTML(editorState, includeEntities = true) {
  let toOptionsBase = toOptions(includeEntities);
  const options = {
    styleToHTML: style => {
      if (style.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
        const spanStyle = style.split(CUSTOM_STYLE_PREFIX_COLOR)[1];
        return {
          start: `<font color=${spanStyle}>`,
          end: '</font>',
        };
      }
      switch (style) {
        case 'CODE':
          return { start: '', end: '' };
        case 'BOLD':
          return {
            start: '<b>',
            end: '</b>',
          };
        case 'ITALIC':
          return {
            start: '<i>',
            end: '</i>',
          };
        case 'UNDERLINE':
          return {
            start: '<u>',
            end: '</u>',
          };
        case 'SMALL':
          return {
            start: '<font size="1">',
            end: '</font>',
          };
        case 'NORMAL':
          return {
            start: '<span>',
            end: '</span>',
          };
        case 'LARGE':
          return {
            start: '<font size="4">',
            end: '</font>',
          };
        case 'HUGE':
          return {
            start: '<font size="6">',
            end: '</font>',
          };
        default:
          return { start: '<span>', end: '</span>' };
      }
    },
    blockToHTML: block => {
      const type = block.type;
      const blockData = block.data;
      const alignment = blockData && blockData[ALIGNMENT_DATA_KEY];

      if (type === 'unstyled') {
        if (alignment) {
          return {
            start: `<div style="text-align: ${alignment.toLowerCase()}">`,
            end: '</div>',
            empty: `<div style="text-align: ${alignment.toLowerCase()}"><br/></div>`,
          };
        }
        return {
          start: '<div>',
          end: '</div>',
          empty: '<div><br/></div>',
        };
      } else if (type === 'pre') {
        return {
          start: '<div>',
          end: '</div>',
          empty: '<div><br/></div>',
        };
      } else if (type === 'atomic' && blockData.type === 'table') {
        return blockData.tableHtml;
      } else if (type === 'atomic') {
        return {
          start: '',
          end: '',
        };
      }
    },
    entityToHTML: (entity, text) => {
      switch (entity.type) {
        case ENTITY_TYPE_LINK:
          return {
            start: `<a href="${entity.data.url}" target="_blank">`,
            end: '</a>',
          };
        case ENTITY_TYPE_INLINE_IMAGE:
          return `<img src="${entity.data.src}" data-attachment-id="${entity.data.attachmentId}">`;
        default: {
          return toOptionsBase.entityToHTML(entity, text);
        }
      }
    },
  };
  return toHTML(options)(editorState.getCurrentContent());
}

export function convertFromEmailHTML(
  html,
  { answers, variables, isEditingSnippets } = { answers: [], variables: [], isEditingSnippets: false }
) {
  let fromOptionsBase = fromOptions({ answers, variables, isEditingSnippets });
  const options = {
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'font' && node.color) {
        currentStyle = currentStyle.add(`${CUSTOM_STYLE_PREFIX_COLOR}${node.color}`);
      }
      if (nodeName === 'b') {
        return currentStyle.add('BOLD');
      } else if (nodeName === 'i') {
        return currentStyle.add('ITALIC');
      } else if (nodeName === 'u') {
        return currentStyle.add('UNDERLINE');
      } else if (nodeName === 'font' && node.size === '1') {
        return currentStyle.add(Sizes.SMALL);
      } else if (nodeName === 'font' && node.size === '4') {
        return currentStyle.add(Sizes.LARGE);
      } else if (nodeName === 'font' && node.size === '6') {
        return currentStyle.add(Sizes.HUGE);
      } else if (nodeName === 'code') {
        return currentStyle.remove('CODE');
      }
      return currentStyle;
    },
    htmlToBlock: (nodeName, node, lastList, inBlock) => {
      if (inBlock === 'atomic') {
        return false;
      }

      if (nodeName === 'div' && node.style.textAlign) {
        return {
          type: 'unstyled',
          data: {
            [ALIGNMENT_DATA_KEY]: node.style.textAlign.toUpperCase(),
          },
        };
      } else if (nodeName === 'pre') {
        return { type: 'unstyled' };
      } else if (nodeName === 'img') {
        return { type: 'atomic' };
      } else if (nodeName === 'table') {
        return {
          type: 'atomic',
          data: {
            id: configureIdGenerator().newId(),
            tableHtml: node.outerHTML,
            type: 'table',
          },
        };
      }
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      switch (nodeName) {
        case 'a': {
          const url = node.getAttribute('href');
          return createEntity(ENTITY_TYPE_LINK, MUTABLE, { url });
        }
        case 'img': {
          const src = node.getAttribute('src');
          const attachmentId = node.getAttribute('data-attachment-id');
          return createEntity(ENTITY_TYPE_INLINE_IMAGE, IMMUTABLE, { attachmentId, src });
        }
        default: {
          return fromOptionsBase.htmlToEntity(nodeName, node, createEntity);
        }
      }
    },
  };
  return fromHTML(options)(html, { flat: true });
}
