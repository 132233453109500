import createModel, { prop } from './lib/create_model';
const Language = createModel({
  modelName: 'Language',
  properties: {
    id: prop(String).isRequired,
    code: prop(String).isRequired,
    language: prop(String),
    region: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Language;
