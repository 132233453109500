import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import { iconClassNameForItem } from './metadata_icon_classes';
import Metadata from './metadata';
import { typeReflect } from 'models/conversation_item/content_from_js';

const ConversationItemOptions = {
  [ConversationItemType.SMS_CAMPAIGN_OUTREACH]: {
    subject: 'Proactive SMS',
    text: (customerName, item) => `sent to ${customerName || get(item, 'content.to[0]')}`,
  },
  [ConversationItemType.EMAIL_CAMPAIGN_OUTREACH]: {
    subject: 'Proactive Email',
    text: (customerName, item) => `sent to ${customerName || get(item, 'content.to[0]')}`,
  },
};

const ALLOWED_ITEM_TYPES = [
  ConversationItemType.PROACTIVE_CHAT_CONTENT,
  ConversationItemType.SMS_CAMPAIGN_OUTREACH,
  ConversationItemType.EMAIL_CAMPAIGN_OUTREACH,
];

export function SystemMetadata({ iconClass, item, text, subject, customerName }) {
  const itemType = typeReflect.instanceToType(item.content);

  if (!ALLOWED_ITEM_TYPES.includes(itemType)) {
    return null;
  }

  iconClass = iconClass ? iconClass : iconClassNameForItem(item);

  if (itemType !== ConversationItemType.PROACTIVE_CHAT_CONTENT) {
    text = ConversationItemOptions[itemType]?.text(customerName, item);
    subject = ConversationItemOptions[itemType]?.subject;
  }

  return <Metadata iconClass={iconClass} isInbound={false} subject={subject} text={text} timestamp={item.timestamp} />;
}

SystemMetadata.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  text: PropTypes.any,
  customerName: PropTypes.string,
};

function mapStateToProps({ getProvider }, props) {
  let profile = getProvider('profile').get();
  return {
    ...props,
    customerName: profile.name,
  };
}

const SystemMetadataContainer = connect(mapStateToProps)(SystemMetadata);
export default SystemMetadataContainer;
