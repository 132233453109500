/**
 * Id generator for Entities
 */
function notInitialized() {
  throw new Error('Id generator is not initialized');
}

export default {
  newId: notInitialized,
};
