import createModel, { prop } from '../lib/create_model';
import NotificationType from './notification_type';
import { SessionCustomer } from '../active_session';

export default createModel({
  modelName: 'AutoAcceptedSessionNotification',

  properties: {
    id: prop(String).isRequired,
    conversationId: prop(String).isRequired,
    messagePreview: prop(String),
    profile: prop(SessionCustomer).isRequired,
    sessionType: prop(String),
  },

  updateMessagePreview(messagePreview) {
    this.messagePreview = messagePreview;
  },

  updateProfileName(name) {
    this.profile.updateName(name);
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.AUTO_ACCEPTED_SESSION_NOTIFICATION });
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
