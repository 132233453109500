import createModel from './lib/create_model';

const ItemId = createModel({
  modelName: 'ItemId',

  properties: {
    id: String,
    conversationId: String,
    timestamp: String,
  },
});

export default ItemId;
