import { Factory } from 'rosie';
import moment from 'moment';

Factory.define('AnswerPanel').attr('name', 'ANSWER_PANEL');

Factory.define('AnswerPanelWithDefaults')
  .extend('AnswerPanel')
  .attr('selectedAnswer', null)
  .attr('history', [])
  .attr('lastOpenedAt', () => moment().toISOString());
