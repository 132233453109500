export default class MqttTopicPattern {
  constructor(patternString) {
    this.patternString = patternString;
  }

  extractParams(topic) {
    return topic.match(`^${this.patternString.replace(/\+/g, '([^/]+)')}$`).slice(1);
  }

  interpolate(...topicParams) {
    return topicParams.reduce((topic, param) => topic.replace('+', param), this.patternString);
  }

  toString() {
    return this.patternString;
  }
}
