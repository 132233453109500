import React from 'react';

import {
  getIndentLevelFromMargin,
  getMarginFromIndent,
} from 'components/customer/composition/lib/slate/slate_text_indentation_menu';

export const baseRules = [
  {
    deserialize(el, next) {
      // Return an empty block for <div><br/></div>
      if (isEmptyBlock(el)) {
        return {
          object: 'block',
          type: 'paragraph',
          nodes: [
            {
              object: 'text',
              leaves: [{ object: 'leaf', text: '' }],
            },
          ],
        };
      }

      // Mid-block <br/> should just create a newline character
      if (el.tagName && el.tagName.toLowerCase() === 'br') {
        return {
          object: 'text',
          leaves: [
            {
              object: 'leaf',
              text: '\n',
            },
          ],
        };
      }

      if (
        el.tagName.toLowerCase() === 'p' ||
        el.tagName.toLowerCase() === 'span' ||
        el.tagName.toLowerCase() === 'div'
      ) {
        const textAlign = el.style.textAlign;
        const indent = getIndentLevelFromMargin(el.style.marginLeft);
        return { object: 'block', type: 'paragraph', nodes: next(el.childNodes), data: { textAlign, indent } };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'paragraph') {
        const textAlign = object.data.get('textAlign');
        let style = textAlign ? { textAlign } : undefined;
        const marginLeft = getMarginFromIndent(object.data.get('indent'));
        if (marginLeft) {
          style = { ...style, marginLeft };
        }
        return <div style={style}>{object.text ? children : <br />}</div>;
      }
    },
  },
];

function isEmptyBlock(el) {
  return (
    el.tagName &&
    el.tagName.toLowerCase() === 'div' &&
    el.childNodes.length === 1 &&
    el.childNodes[0].tagName &&
    el.childNodes[0].tagName.toLowerCase() === 'br'
  );
}
