import { Editor, Transforms, Range } from 'slate';

/**
 * Selections at the end of inlines are ambiguous - when typing, should it insert at the end of the
 * inline, or at the beginning of the next text node?
 *
 * Chrome forces the selection to always look like it's at the end of the inline rather than the beginning
 * of the next text, so Slate made a change whereby it now inserts text at the end of the inline..
 * and this is me effectively undoing that change, because we generally don't want users editing
 * inlines.
 */
export default function withInsertTextAfterInline(editor) {
  editor.insertText = text => {
    const { selection, marks } = editor;

    if (selection) {
      // If the cursor is at the end of an inline, move it outside of
      // the inline before inserting
      if (Range.isCollapsed(selection)) {
        const inline = Editor.above(editor, {
          match: n => Editor.isInline(editor, n),
          mode: 'highest',
        });

        if (inline) {
          const [, inlinePath] = inline;

          if (Editor.isEnd(editor, selection.anchor, inlinePath)) {
            const point = Editor.after(editor, inlinePath);
            Transforms.setSelection(editor, {
              anchor: point,
              focus: point,
            });
          }
        }
      }

      if (marks) {
        const node = { text, ...marks };
        Transforms.insertNodes(editor, node);
      } else {
        Transforms.insertText(editor, text);
      }
      editor.marks = null;
    }
  };

  return editor;
}
