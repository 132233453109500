import qconsole from 'scripts/lib/qconsole';

import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';
import RelationshipConverter from 'scripts/application/dto_converters/relationship_converter';

export default class RelationshipGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  getStore(id) {
    if (this._isCustomerLoaded(id)) {
      return this.context.stores.customers.storesFor(id).relationships;
    }
    qconsole.log(`Customer not loaded. Ignoring response`);
    return null;
  }

  onBroadcast(dto) {
    const store = this.getStore(dto.customerId);
    if (store) {
      store.addOrReplace(RelationshipConverter.fromDto(dto));
    }
  }

  onBroadcastDelete(dto, { customerId, relationshipId }) {
    const store = this.getStore(customerId);
    if (store) {
      store.remove(relationshipId);
    }
  }

  onFetchAllSuccess(fetchResultDto, { customerId }) {
    const store = this.getStore(customerId);
    if (store) {
      store.resetLoading();
      store.set(fetchResultDto.map(r => RelationshipConverter.fromDto(r)));
    }
  }

  onFetchAllError(err, { customerId }) {
    const store = this.getStore(customerId);
    if (store) {
      store.resetLoading();
    }
    handleCommonErrors({ customerId }, err);
  }

  onAddSuccess(dto, { customerId, relationshipId }) {
    const store = this.getStore(customerId);

    if (store) {
      const pendingResult = store.getPendingNew();
      if (pendingResult && pendingResult.id === relationshipId) {
        store.commitPendingNew();
      }
    }
  }

  onAddError(errorsDto, { customerId, relationshipId }) {
    qconsole.log(`Could not add relationship ${JSON.stringify(errorsDto)}`);

    const store = this.getStore(customerId);

    if (store) {
      const pendingResult = store.getPendingNew();
      if (pendingResult && pendingResult.id === relationshipId) {
        store.resetPendingNew();
      }
    }
  }

  onUpdateSuccess(dto, { customerId, relationshipId }) {
    const store = this.getStore(customerId);
    if (store) {
      store.commitPending(relationshipId);
    }
  }

  onUpdateError(errorsDto, { customerId, relationshipId }) {
    qconsole.log(`Could not update relationship ${JSON.stringify(errorsDto)}`);

    const store = this.getStore(customerId);

    if (store) {
      const pendingResult = store.getPending(relationshipId);
      if (pendingResult && pendingResult.id === relationshipId) {
        store.resetPending(relationshipId);
      }
    }
  }

  onDeleteSuccess(dto, { customerId, relationshipId }) {}

  onDeleteError(errorsDto, { customerId, relationshipId }) {
    qconsole.log(`Could not delete relationship ${JSON.stringify(errorsDto)}`);
  }

  _isCustomerLoaded(customerId) {
    return this.context.stores.customers.has({ id: customerId });
  }
}
