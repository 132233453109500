import createReactClass from 'create-react-class';
import React from 'react';
import d3 from 'd3';

import T from 'prop-types';

const ChartLine = createReactClass({
  propTypes: {
    className: T.string.isRequired, // class used for display
    data: T.arrayOf(T.arrayOf(T.oneOfType([T.string, T.number]))).isRequired, // array of tuples [x,y]
    xScale: T.any.isRequired, // d3 scale for x-axis
    yScale: T.any.isRequired, // d3 scale for y-axis
    showPoints: T.bool, // whether points should be displayed on the line
  },

  statics: {
    POINT_RADIUS: 2,
  },

  /* Lifecycle */
  UNSAFE_componentWillMount() {
    this.line = d3.svg
      .line()
      .x(d => this.props.xScale(d[0]))
      .y(d => this.props.yScale(d[1]))
      .interpolate('linear');
  },

  /* Render */
  render() {
    let showPoints = this.props.showPoints || this.props.data.length === 1;

    return (
      <g>
        <path className={`${this.props.className}-path`} d={this.line(this.props.data)} />
        {showPoints && this.renderPoints()}
      </g>
    );
  },

  renderPoints() {
    return this.props.data.map((point, index) => this.renderPoint(point, index));
  },

  renderPoint(point, index) {
    return (
      <circle
        className={`${this.props.className}-circle`}
        cx={this.props.xScale(point[0])}
        cy={this.props.yScale(point[1])}
        key={`point${point[0]}${point[1]}`}
        r={ChartLine.POINT_RADIUS}
      />
    );
  },
});

export default ChartLine;
