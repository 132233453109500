import React from 'react';
import styled from 'styled-components';

import { EmptyState, EmptyText } from 'components/customer/answer_panel/lib/empty_state';
import GlassesIcon from 'components/lib/icons/glasses_icon';

export default function PlaceholderAnswerPreview() {
  return (
    <Placeholder data-aid="placeholderAnswerPreview">
      <GlassesIcon />
      <EmptyText>Preview Answers</EmptyText>
    </Placeholder>
  );
}

const Placeholder = styled(EmptyState)`
  .glassesIcon {
    height: 60px;
    width: 60px;
  }
  .glassesIcon-circle {
    fill: ${p => p.theme.colors.gray100};
  }
  .glassesIcon-stroke {
    stroke: ${p => p.theme.colors.green400};
  }
`;
