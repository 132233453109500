import classnames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import React from 'react';
import P from 'prop-types';
import RelativeDateTime from 'components/lib/date/relative_date_time';
import TaskStroke from 'components/common/icons/stroke/task-stroke';

import styled from 'styled-components';

export default function TaskCard(props) {
  let relativeTime = time => {
    return <RelativeDateTime timestamp={time} />;
  };

  let renderDueAt = () => {
    if (props.isClosed) {
      return null;
    }
    let dueClasses = classnames('taskCard-header-dueAt', { 'taskCard-header-dueAtDanger': props.isNearlyOrOverdue });

    return (
      <div className={dueClasses}>
        {'Due '}
        {relativeTime(props.dueAt, props.isNearlyOrOverdue)}
      </div>
    );
  };

  let render = () => {
    return (
      <div className={classnames('taskCard', props.className)}>
        <div className="taskCard-header">
          <div className={classnames('taskCard-header-label', { 'taskCard-header-label-closed': props.isClosed })}>
            {props.isClosed ? 'Closed Task' : 'Task'}
          </div>
          {renderDueAt()}
          <TaskIconContainer>
            <TaskStroke className={'task-card-icon'} />
          </TaskIconContainer>
        </div>
        <div className="taskCard-body">
          <Dotdotdot clamp={3}>{props.note}</Dotdotdot>
        </div>
        <div className="taskCard-footer">
          <div className="taskCard-footer-text">
            {`Created by ${props.creatorName},`}
            {props.assigneeName && ` Assigned to ${props.assigneeName},`}
          </div>
          {relativeTime(props.createdAt)}
        </div>
      </div>
    );
  };

  return render();
}

TaskCard.propTypes = {
  assigneeName: P.string,
  className: P.string,
  creatorName: P.string,
  createdAt: P.string,
  dueAt: P.string,
  isClosed: P.bool,
  isNearlyOrOverdue: P.bool,
  note: P.string,
};

const TaskIconContainer = styled.div`
  padding-left: ${p => p.theme.spacing.inlineSmall};

  .task-card-icon {
    height: 20px;
    width: 20px;
    fill: ${p => p.theme.colors.gray600};
  }
`;
