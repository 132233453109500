import createEnum from 'scripts/lib/create_enum';

export default createEnum(
  'ACCEPTED_COMMUNICATION_SESSION',
  'ASSIGNED_CONVERSATION',
  'ASSIGNED_TASK',
  'DECLINED_OFFERED_CALL',
  'DECLINED_WARM_TRANSFER',
  'DUE_TASK',
  'INBOUND_COMMUNICATION_SESSION',
  'INBOUND_MESSAGE',
  'OFFERED_CALL',
  'OFFERED_WARM_TRANSFER',
  'REJECTED_COMMUNICATION_SESSION',
  'UNOFFERED_CALL',
  'UNOFFERED_WARM_TRANSFER',
  'UNREAD_CONVERSATION',
  'UNREAD_TASK'
);
