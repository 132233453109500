import { Factory } from 'rosie';

Factory.define('TaskFollower')
  .extend('Uuid')
  .attr('name', 'TASK_FOLLOWER');

Factory.define('TaskFollowerWithDefaults')
  .extend('TaskFollower')
  .attr('id', 'default-id')
  .attr('itemId', 'default-itemId')
  .attr('agentId', 'default-agentId')
  .attr('createdAt', '2019-10-21T16:00:00.000Z')
  .attr('version', 1);
