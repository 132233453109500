import ConversationItem from 'models/conversation_item';
import Communicator from 'models/communicator';
import TaskReopenedContent from 'models/task_reopened_content';

export default function createTaskReopenedItem({ agentId, note, customerId, taskItemId }) {
  return ConversationItem.create({
    content: TaskReopenedContent.create({
      note,
      taskItemId,
    }),
    customerId,
    initiator: {
      id: agentId,
      type: Communicator.AGENT,
    },
  });
}
