import { Factory } from 'rosie';

Factory.define('MessageContentTypeLocation').attr('type', 'LOCATION');

Factory.define('MessageContentTypeLocationWithDefaults')
  .extend('MessageContentTypeLocation')
  .attr('location', {
    coordinates: {
      latitude: 39.0204,
      longitude: 122.101,
    },
  })
  .attr('buttons', []);
