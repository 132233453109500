import { useEffect, useMemo, useRef, useState } from 'react';

import { createEditor } from 'slate';
import { createPlugins, withPlate } from '@udecode/plate';

import components from 'components/text_editor_new/plugins/components';
import { createAnswersPlugin } from '../plugins/answers/answers_plugin';
import createCapitalizeIPlugin from 'components/text_editor_new/plugins/capitalize_i';
import createCapitalizeFirstWordPlugin from 'components/text_editor_new/plugins/capitalize_first_word';
import { createEditorEventsPlugin } from 'components/text_editor_new/plugins/editor_events/create_editor_events_plugin';
import { createHotkeyPlugin } from 'components/text_editor_new/plugins/hotkey/create_hotkey_plugin';
import createInsertTextAfterInline from 'components/text_editor_new/plugins/insert_text_after_inline';
import createParagraphPlugin from 'components/text_editor_new/plugins/paragraph';
import createPlaceholders from 'components/text_editor_new/plugins/placeholders';
import createSaveOnBlur from 'components/text_editor_new/plugins/save_on_blur';
import { useCreateOpenAnswerPanelPlugin } from 'components/text_editor_new/plugins/create_open_answer_panel_plugin';
import { useCreateVariablesPlugin, useUpdateVariables } from 'components/text_editor_new/plugins/variables';

export default function usePlaintextEditorPlugins({ composerRef }, editorProps) {
  const { name, onBlur } = editorProps;

  const createOpenAnswerPanelPlugin = useCreateOpenAnswerPanelPlugin();
  const createVariablesPlugin = useCreateVariablesPlugin();

  const plugins = useMemo(
    () =>
      createPlugins(
        // ORDER MATTERS. Some plugins can interact with each other in adverse ways. Be careful when changing the order.
        [
          // This plugin must come first
          createInsertTextAfterInline(),

          // editor events and keyboard hotkeys
          createHotkeyPlugin(),
          createEditorEventsPlugin(),

          // Autocapitalization
          createCapitalizeIPlugin(),
          createCapitalizeFirstWordPlugin(),

          // general
          createSaveOnBlur(onBlur),
          createOpenAnswerPanelPlugin(),

          // elements
          createParagraphPlugin(),

          // inlines
          createPlaceholders(),

          // etc.
          createAnswersPlugin(),
          createVariablesPlugin(),
        ],
        { components }
      ),
    [onBlur]
  );

  const editor = useMemo(() => withPlate(createEditor(), { plugins }), [plugins]);
  useUpdateVariables(editor);

  const editorRef = useRef(editor);
  const [, rerender] = useState(null);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = editor;
      rerender({});
    }
    if (!composerRef.current.editorRefs[name]) {
      composerRef.current.editorRefs[name] = editorRef;
      rerender({});
    }
  }, [editor]);
  editorProps.editorRef = editorRef;
  return editorRef;
}
