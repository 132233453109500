import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomerCard from 'components/lib/customer_conversation_card/customer_card';
import Link from 'components/common/link';
import TaskCardContainer from 'components/lib/customer_conversation_card/task_card_container';
import UpdateAgentTaskRead from 'actions/task/update_agent_task_read';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function CustomerTaskCardBase({
  className,
  isDisabled,
  isLink,
  isSelected,
  item,
  onClick,

  address,
  customerId,
  email,
  image,
  name,
  phone,
}) {
  const executeAction = useExecuteAction();
  const handleClick = useCallback(() => {
    onClick({ customerId, itemId: item.id });
    executeAction(UpdateAgentTaskRead, item.id);
  }, [customerId, item.id, onClick]);
  const task = item.content;

  const classNames = classnames(
    'customerTaskCard',
    className,
    { 'customerTaskCard-disabled': isDisabled },
    { 'customerTaskCard-selected': isSelected }
  );

  const content = (
    <div className={classNames} onClick={handleClick}>
      <CustomerCard
        address={address}
        email={email}
        id={customerId}
        image={image}
        isClosedTask={task.isClosed()}
        isTask
        name={name}
        phone={phone}
      />
      <TaskCardContainer
        assignedAgentId={task.assignee.agentId}
        createdAt={item.timestamp}
        creator={item.initiator}
        dueAt={task.dueAt}
        isClosed={task.isClosed()}
        note={task.note}
      />
    </div>
  );

  if (!isLink) {
    return content;
  }

  const linkUrl = `/customer/${customerId}/item/${item.id}`;
  return (
    <div>
      <Link className="unstyled" href={linkUrl}>
        {content}
      </Link>
    </div>
  );
}

const CustomerTaskCard = React.memo(CustomerTaskCardBase);
export default CustomerTaskCard;

CustomerTaskCard.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLink: PropTypes.bool,
  isSelected: PropTypes.bool,
  item: PropTypes.object,
  onClick: PropTypes.func,

  address: PropTypes.string,
  customerId: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
};
