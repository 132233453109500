import { useEffect } from 'react';

import StartTrackAnswerView from 'actions/answers/start_track_answer_view';
import EndTrackAnswerView from 'actions/answers/end_track_answer_view';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function useTrackAnswerUsage(answer, answerType, language) {
  const executeAction = useExecuteAction();

  useEffect(() => {
    if (answer) {
      executeAction(StartTrackAnswerView, { answer, answerType, language });
      return () => {
        executeAction(EndTrackAnswerView, { answerId: answer.id, answerType });
      };
    }
  }, [answer, answerType]);
}
