import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'UserLogin',
  properties: {
    preAuthPath: { preAuthPath: { path: String, external: Boolean } },
    username: String,
  },

  setUsername(username) {
    this.username = username;
  },
});
