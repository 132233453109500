import _ from 'lodash';
import { Binding } from 'morearty';

import { EXCLUDED_PROVIDERS } from 'components/lib/connect';
import qconsole from 'scripts/lib/qconsole';

export default {
  contextTypes: {
    getProvider() {},
  },

  getProvider(name) {
    let bindings = [];
    if (this.props.binding) {
      if (this.props.binding instanceof Binding) {
        bindings.push(this.props.binding);
      } else {
        bindings = bindings.concat(_.values(this.props.binding));
      }
    }
    bindings = bindings.concat(this.observedBindings);

    let observesRootBinding = _.some(bindings, binding => _.isEqual(binding && binding.getPath(), []));
    let observedBinding = _.find(bindings, binding => _.includes(binding && binding.getPath(), name));
    if (!observesRootBinding && !observedBinding && !_.includes(EXCLUDED_PROVIDERS, name)) {
      qconsole.warn(
        `Accessing provider '${name}' without observing / passing a binding with a path containing '${name}'. Your component may not refresh when it needs to when data changes!`
      );
    }
    return this.context.getProvider(name);
  },
};
