import { some, forEach } from 'lodash';
import React from 'react';

import connect from 'components/lib/connect';
import FeatureNotification from './feature_notification';
import FeatureNotificationTypes from 'scripts/domain/models/agent_notification/feature_notification_types';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const proactiveConversations = {
  feature: FeatureNotificationTypes.IN_APP_NOTIFICATION,
  roleFlags: ['ivrConfigurationManagement'],
  key: 'proactive-conversations-notification',
  text: 'Reach Customers Through Bulk Communication',
  subtext:
    'Enhance communication with your Customers by proactively sharing information and updates through SMS, Voice, Email, or Chat.',
  url: 'https://connect.gladly.com/docs/help-documentation/article/get-started-with-proactive-conversations',
  urlText: 'LEARN MORE',
};

const CURRENT_FEATURES = [proactiveConversations];

export function FeatureNotificationList({ agentPreferences, organization }) {
  const isFeatureEnabled = useIsFeatureEnabled();

  let featureNotifications = [];
  forEach(CURRENT_FEATURES, featureProps => {
    if (shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization)) {
      featureNotifications.push(<FeatureNotification {...featureProps} />);
    }
  });

  if (!featureNotifications.length) {
    return null;
  }

  return <React.Fragment>{featureNotifications}</React.Fragment>;
}

const FeatureNotificationListContainer = connect(mapStateToProps)(FeatureNotificationList);

function mapStateToProps({ getProvider }) {
  const agentPreferences = getProvider('agentPreferences').get();
  const organization = getProvider('auth').get()?.claims;

  return {
    agentPreferences,
    organization,
  };
}

export function useHasFeaturesToShow(agentPreferences, organization) {
  const isFeatureEnabled = useIsFeatureEnabled();
  return some(CURRENT_FEATURES, featureProps => {
    return shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization);
  });
}

function shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization) {
  const roleRestricted = featureProps.roleFlags && featureProps.roleFlags.length;
  const restrictedAndHasRole = some(featureProps.roleFlags, roleFlag => isFeatureEnabled(roleFlag));
  const flagRestricted = featureProps.flag;

  const orgRestricted = !!featureProps.orgIds;
  const belongsToOrg = some(featureProps.orgIds, orgId => orgId === organization?.orgId);

  const currentTime = Date.now();
  const startRestricted = !!featureProps.startTime;
  const afterStart = startRestricted ? currentTime > featureProps.startTime : false;
  const endRestricted = !!featureProps.endTime;
  const beforeEnd = endRestricted ? currentTime < featureProps.endTime : false;

  return (
    (!orgRestricted || belongsToOrg) &&
    (!roleRestricted || restrictedAndHasRole) &&
    (!flagRestricted || isFeatureEnabled(featureProps.flag)) &&
    (!startRestricted || afterStart) &&
    (!endRestricted || beforeEnd) &&
    !agentPreferences.hasSeenFeatureNotification(featureProps.feature)
  );
}

export default FeatureNotificationListContainer;
