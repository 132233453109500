import { useEffect, useRef } from 'react';

import connect from 'components/lib/connect';
import qconsole from 'scripts/lib/qconsole';
import { startFakeWebRTCConnection } from 'scripts/configuration/configure_fake_webrtc';

/**
 * Very simple component that starts a "fake WebRTC" connection if the feature flag is set. We use
 * "fake WebRTC" to combat performance/timer throttling from the browsers that reduce resources for
 * the background tabs, which creates problems for async tasks that are running on timers.
 *
 * @param {boolean} enableFakeWebRTC
 * @returns {null}
 * @constructor
 */
function FakeWebRTCStarterBase({ enableFakeWebRTC }) {
  const fakeWebRTCStartedRef = useRef(false);

  useEffect(() => {
    if (enableFakeWebRTC && !fakeWebRTCStartedRef.current) {
      fakeWebRTCStartedRef.current = true; // Play safe in case of "re-rendering jitter"
      startFakeWebRTCConnection()
        .then(() => {
          qconsole.log('[fakewebrtc] Connection started');
        })
        .catch(err => {
          qconsole.error('[fakewebrtc] Unable to start the connection: ', err);
          fakeWebRTCStartedRef.current = false;
        });
    }
  }, [enableFakeWebRTC]);

  return null;
}

function mapStateToProps({ isFeatureEnabled }) {
  return {
    enableFakeWebRTC: isFeatureEnabled('enableFakeWebRTC'),
  };
}

export default connect(mapStateToProps)(FakeWebRTCStarterBase);
