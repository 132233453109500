import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import _ from 'lodash';

import ConversationItem from 'models/conversation_item';
import ThumbsDownIcon from 'components/common/icons/stroke/thumbs-down-stroke';
import ThumbsUpIcon from 'components/common/icons/stroke/thumbs-up-stroke';

export default function ConversationSummaryItemFooter({ item, feedbackVisibilityInterval, onThumbsUp, onThumbsDown }) {
  // How long ago was the item created? Calculate the visibility window
  const timestamp = _.get(item, 'timestamp');
  const now = moment();
  const timespan = moment.duration(now.diff(moment(timestamp))).asSeconds();

  const [thumbsUpRippleClass, setThumbsUpRippleClass] = useState('');
  const [thumbsDownRippleClass, setThumbsDownRippleClass] = useState('');
  const [feedbackVisible, setFeedbackVisible] = useState(timespan < feedbackVisibilityInterval);

  // Hide feedback panel once the visibility window is over
  useEffect(() => {
    if (!feedbackVisible) return;

    const timeout = Math.max(0, feedbackVisibilityInterval - timespan);
    const timer = setTimeout(() => {
      setFeedbackVisible(false);
    }, timeout * 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  const feedbackPanel = feedbackVisible ? (
    <FeedbackButtonPanel>
      <IconButton data-aid="button-thumbs-up" onClick={handleThumbsUpClick}>
        <ThumbsUp />
        <IconButtonRipple className={thumbsUpRippleClass} onAnimationEnd={onRippleAnimationEnd} />
      </IconButton>
      <IconButton data-aid="button-thumbs-down" onClick={handleThumbsDownClick}>
        <ThumbsDown />
        <IconButtonRipple className={thumbsDownRippleClass} onAnimationEnd={onRippleAnimationEnd} />
      </IconButton>
    </FeedbackButtonPanel>
  ) : null;

  const footerText = `This summary was created automatically by Gladly.${feedbackVisible ? ' How did we do?' : ''}`;
  return (
    <FooterSectionWrapper>
      <FooterTextWrapper>{footerText}</FooterTextWrapper>
      {feedbackPanel}
    </FooterSectionWrapper>
  );

  function handleThumbsUpClick() {
    setThumbsUpRippleClass('animated');
    _.isFunction(onThumbsUp) && onThumbsUp();
  }
  function handleThumbsDownClick() {
    setThumbsDownRippleClass('animated');
    _.isFunction(onThumbsDown) && onThumbsDown();
  }

  function onRippleAnimationEnd() {
    setFeedbackVisible(false);
  }
}

const commonIconCSS = css`
  fill: ${p => p.theme.colors.gray700};
  height: 15px;
  position: relative;
  width: auto;

  &:hover {
    fill: ${p => p.theme.colors.green400};
  }
`;

const rippleEffect = keyframes`
  0% {
    scale: 1;
    opacity: 0.5;
  }
  100% {
    scale: 50;
    opacity: 0;
  }
`;

export const FeedbackButtonPanel = styled.div`
  height: 20px;
  margin-left: 10px;
  position: relative;
  top: -2px;
  white-space: nowrap;
`;

const FooterSectionWrapper = styled.div`
  color: ${p => p.theme.colors.gray700};
  display: flex;
  font-size: ${p => p.theme.fontSize.base};
  margin-top: 16px;
  position: relative;
`;

const FooterTextWrapper = styled.div`
  flex-basis: 100%;
`;

export const IconButton = styled.div`
  border-radius: 50%;
  display: inline-block;
  line-height: 1;
  margin-left: 4px;
  padding: 4px;
  position: relative;

  &:hover {
    svg {
      fill: ${p => p.theme.colors.green400};
    }
    cursor: pointer;
  }
`;

export const IconButtonRipple = styled.div`
  background-color: transparent;
  border-radius: 50%;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform-origin: center;
  width: 1px;

  &.animated {
    background-color: ${p => p.theme.colors.green400};
    animation: ${rippleEffect} 0.65s ease-in-out;
  }
`;

const ThumbsUp = styled(ThumbsUpIcon)`
  ${commonIconCSS};
`;

const ThumbsDown = styled(ThumbsDownIcon)`
  ${commonIconCSS};
  top: 2px;
  transform: scaleX(-1); // Flip the icon vertically
`;

ConversationSummaryItemFooter.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  feedbackVisibilityInterval: PropTypes.number.isRequired,
  onThumbsDown: PropTypes.func,
  onThumbsUp: PropTypes.func,
};
