import React from 'react';

export default function VoiceSelected() {
  return (
    <svg className="availabilityIcon availabilityIcon-voiceSelected" height="32" width="32">
      <defs>
        <rect height="30" id="a" rx="15" width="30" />
      </defs>
      <g fill="#009B00" fillRule="evenodd">
        <g transform="translate(1 1)">
          <rect
            className="availabilityIcon-voiceSelected-rect"
            height="29"
            rx="14.5"
            stroke="#009b00"
            width="29"
            x=".5"
            y=".5"
          />
        </g>
        <path
          className="availabilityIcon-voiceSelected-path"
          d="M21.625 14a.624.624 0 1 0 0 1.25H23.5a.624.624 0 1 0 0-1.25h-1.875zm-5-2.875c.346 0 .625-.28.625-.625V8.625a.624.624 0 1 0-1.25 0V10.5c0 .346.28.625.625.625zm4.431.245l1.314-1.313a.62.62 0 0 0 0-.876.62.62 0 0 0-.876 0l-1.313 1.314a.618.618 0 1 0 .875.875z"
          fill="#FFF"
        />
        <path
          className="availabilityIcon-voiceSelected-path"
          d="M21.63 23.982a.964.964 0 0 0 .56-.321l1.58-1.825a.934.934 0 0 0-.073-1.303l-2.358-2.189a.995.995 0 0 0-1.337-.01l-1.26 1.138a.998.998 0 0 1-1.04.18c-.57-.229-1.689-1.009-2.806-2.054l-.455-.494c-1.045-1.117-1.825-2.236-2.054-2.806a1 1 0 0 1 .18-1.04l1.137-1.26a.994.994 0 0 0-.01-1.336l-2.189-2.359a.934.934 0 0 0-1.302-.073L8.378 9.81a.967.967 0 0 0-.32.56c-.175.986-.198 3.907 3.705 8.475l1.405 1.405c4.568 3.902 7.476 3.906 8.461 3.732z"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
