import ConversationItemType from 'models/conversation_item_type';
import { typeReflect } from 'models/conversation_item/content_from_js';

const METADATA_ICON_CLASSES = Object.freeze({
  [ConversationItemType.ABANDONED_CALL]: 'icon-abandonedCall',
  [ConversationItemType.CHAT_SELF_SERVICE_METADATA]: 'ion-ios-world-outline',
  [ConversationItemType.CREDIT_CARD_VIEWED]: 'fa fa-credit-card',
  [ConversationItemType.CONVERSATION_NOTE]: 'icon-note-filled',
  [ConversationItemType.CUSTOMER_ASSIGNEE_CHANGE]: 'fa fa-exchange',
  [ConversationItemType.EMAIL]: 'icon-email-filled',
  [ConversationItemType.EMAIL_CAMPAIGN_OUTREACH]: 'icon-email-filled',
  [ConversationItemType.EMAIL_PREVIEW]: 'icon-email-filled',
  [ConversationItemType.EMAIL_SEND_FAILURE]: 'conversationItem-errorIcon fa fa-exclamation',
  [ConversationItemType.FB_MESSAGE_INCOMING]: 'icon-fb-messenger-filled',
  [ConversationItemType.FB_MESSAGE_OUTGOING]: 'icon-fb-messenger-filled',
  [ConversationItemType.PAYMENT_STATUS_EVENT]: 'fa fa-credit-card',
  [ConversationItemType.PAYMENT_COMPLETED]: 'fa fa-credit-card',
  [ConversationItemType.PHONE_CALL]: 'icon-phone-filled',
  [ConversationItemType.SESSION_ENDED]: '',
  [ConversationItemType.SMS]: 'icon-sms',
  [ConversationItemType.SMS_CAMPAIGN_OUTREACH]: 'icon-sms',
  [ConversationItemType.TASK]: 'icon-task',
  [ConversationItemType.TASK_COMPLETION]: 'icon-task',
  [ConversationItemType.TASK_DUE]: 'icon-task',
  [ConversationItemType.TASK_EDITED]: 'icon-task',
  [ConversationItemType.TASK_REOPENED]: 'icon-task',
  [ConversationItemType.TWITTER]: 'fa fa-lg fa-twitter',
  [ConversationItemType.VOICEMAIL]: 'icon-voicemail',
});

export function iconClassNameForItem(item) {
  const itemType = typeReflect.instanceToType(item.content);
  return METADATA_ICON_CLASSES[itemType];
}

export default METADATA_ICON_CLASSES;
