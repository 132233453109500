import Language from 'models/language';
import qconsole from 'scripts/lib/qconsole';

export default class LanguageGatewayObserver {
  constructor(context) {
    this.context = context;
  }
  get store() {
    return this.context.stores.languages;
  }
  handleFetchSuccess(languages = []) {
    const isLoading = this.store.isLoading();
    if (isLoading) {
      this.store.set(languages.map(l => Language.fromJs({ id: l, code: l })));
      this.store.resetLoading();
    }
  }
  handleFetchError(errorDto) {
    const isLoading = this.store.isLoading();
    if (isLoading) {
      this.store.resetLoading();
    }
    qconsole.log(`Could not fetch language ${JSON.stringify(errorDto)}`);
  }
}
