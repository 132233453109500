import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function ConversationMessageAddressField({ address }) {
  return <div className={classnames('conversationMessageComposer-address')}>{address}</div>;
}

ConversationMessageAddressField.propTypes = {
  address: PropTypes.string.isRequired,
};
