import Dotdotdot from 'react-dotdotdot';
import path from 'path';
import React from 'react';
import PropTypes from 'prop-types';

import AttachmentUploadProgress from './attachment_upload_progress';
import Attachment from 'components/lib/attachment/attachment';
import Upload from 'models/upload';

class CompositionUpload extends React.Component {
  filenameWithoutExtension() {
    let filename = this.props.upload.fileDescriptor().filename;
    return path.basename(filename, path.extname(filename));
  }

  render() {
    switch (this.props.upload.status) {
      case Upload.Status.NEW:
      case Upload.Status.STARTED:
        return this.renderStarted();
      case Upload.Status.FAILED:
        return this.renderFailed();
      case Upload.Status.COMPLETED:
      default:
        return this.renderCompleted();
    }
  }

  renderStarted() {
    const { upload } = this.props;
    return (
      <div className="attachments-attachment">
        <div
          className="attachments-attachment-description compositionAttachments-attachment compositionAttachments-attachment-uploading"
          key={upload.id}
        >
          <span
            className="compositionAttachments-attachment-remove-container"
            onClick={this.getHandler(this.props.onAbort)}
          >
            <span className="compositionAttachments-attachment-remove">✕</span>
          </span>
          <h4 className="attachments-attachment-label compositionAttachments-attachment-label-uploading">
            Uploading...
          </h4>
          <AttachmentUploadProgress progress={upload.progress || 0} />
        </div>
      </div>
    );
  }

  renderFailed() {
    const { upload } = this.props;
    const filename = upload.fileDescriptor().filename;
    return (
      <div className="attachments-attachment">
        <div
          className="attachments-attachment-description compositionAttachments-attachment compositionAttachments-attachment-failed"
          key={upload.id}
        >
          <span
            className="compositionAttachments-attachment-remove-container"
            onClick={this.getHandler(this.props.onRemove)}
          >
            <span className="compositionAttachments-attachment-remove">✕</span>
          </span>
          <Dotdotdot clamp={2}>
            <h4 className="attachments-attachment-label" title={filename}>
              {this.filenameWithoutExtension()}
            </h4>
          </Dotdotdot>
          <span
            className="compositionAttachments-attachment-failed-retry"
            onClick={this.getHandler(this.props.onRetry)}
          >
            <i className="compositionAttachments-attachment-failed-retry-icon fa fa-repeat" />
            <span className="compositionAttachments-attachment-failed-retry-label">Failed</span>
          </span>
        </div>
      </div>
    );
  }

  renderCompleted() {
    return (
      <Attachment
        attachment={this.props.upload}
        className="compositionAttachments-attachment"
        disableRemove
        onClick={this.props.onOpenAttachmentPreview}
        thumbnailUrl={this.props.thumbnailUrl}
      >
        <span
          className="compositionAttachments-attachment-remove-container"
          onClick={this.getHandler(this.props.onRemove)}
        >
          <span className="compositionAttachments-attachment-remove">✕</span>
        </span>
      </Attachment>
    );
  }

  getHandler(fn) {
    return event => {
      event.stopPropagation();
      fn();
    };
  }
}

CompositionUpload.propTypes = {
  onAbort: PropTypes.func.isRequired,
  onOpenAttachmentPreview: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  upload: PropTypes.instanceOf(Upload).isRequired,
};

export default CompositionUpload;
