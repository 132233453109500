import classnames from 'classnames';
import createEnum from 'scripts/lib/create_enum';
import React from 'react';
import PropTypes from 'prop-types';

export const GutterWidth = createEnum('small', 'default', 'large');

function getGutterWidthClass(props) {
  if (props.gutterWidth) {
    return `grid-gutterWidth-${props.gutterWidth}`;
  }
  return null;
}

/** @deprecated Grid is no longer used for layout*/
export default function Grid(props) {
  return (
    <div
      className={classnames(
        'grid',
        { 'grid-outsideGutters': props.outsideGutters },
        getGutterWidthClass(props),
        props.className
      )}
    >
      {props.children}
    </div>
  );
}

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** width of gutters between columns. If not set, its default. Duh. */
  gutterWidth: PropTypes.oneOf(Object.keys(GutterWidth)),
  outsideGutters: PropTypes.bool,
};
