import _ from 'lodash';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import connect from 'components/lib/connect';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import SearchableMenu from 'components/lib/assignment_menu/searchable_menu';
import SearchResults from './search_results';

export class SearchableAssignmentMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.renderResults = this.renderResults.bind(this);
  }

  render() {
    return (
      <SearchableMenu
        className={this.props.className}
        onSearch={this.props.onSearch}
        placeholder={this.getPlaceholderText()}
        ref={node => (this.menu = node)}
        searchResults={this.renderResults()}
        title={this.props.title}
      />
    );
  }

  renderResults() {
    if (!this.props.hasSearchText && this.props.selfAssignmentResults) {
      return (
        <div>
          <SearchResults
            className="searchableMenu-results-agents"
            name=""
            renderRow={this.props.renderRow}
            results={this.props.selfAssignmentResults}
          />
        </div>
      );
    }

    if (!this.props.hasSearchText && this.props.defaultResults) {
      return (
        <div>
          <div className="searchableMenu-resultsPlaceholder">Begin typing to find an inbox or agent.</div>
          <SearchResults
            className="searchableMenu-results-inboxes"
            name={this.props.defaultResultsGroupName}
            renderRow={this.props.renderRow}
            results={this.props.defaultResults}
          />
        </div>
      );
    }

    return (
      <div>
        <SearchResults
          className="searchableMenu-results-inboxes"
          name="Inboxes"
          renderRow={this.props.renderRow}
          results={this.props.inboxResults}
        />
        {this.renderAgentResults()}
      </div>
    );
  }

  renderAgentResults() {
    if (_.isEmpty(this.props.agentResultsCategories)) {
      return (
        <SearchResults
          className="searchableMenu-results-agents"
          name="Agents"
          renderRow={this.props.renderRow}
          results={this.props.agentResults}
        />
      );
    }

    let result = [];
    for (let i = 0; i < this.props.agentResultsCategories.length; i++) {
      let category = this.props.agentResultsCategories[i];
      let filteredResults = this.props.agentResults.filter(category.filter);
      if (filteredResults.length) {
        result.push(
          <SearchResults
            className={'searchableMenu-results-agents ' + category.className}
            name={category.name}
            renderRow={this.props.renderRow}
            results={filteredResults}
          />
        );
      }
    }

    return result;
  }

  getPlaceholderText() {
    const inboxResults = this.props.inboxResults;
    const agentResults = this.props.agentResults;
    if (!this.props.hasSearchText && !this.props.selfAssignmentResults && !this.props.defaultResults) {
      return 'Begin typing to find an inbox or agent.';
    }
    if (this.props.hasSearchText && !inboxResults.length && !agentResults.length) {
      return 'No results. Try refining your search.';
    }
    return null;
  }

  resetScrolling() {
    this.menu && this.menu.resetScrolling();
  }
}

const SHARED_PROP_TYPES = {
  agentResultsCategories: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      // filter takes a single agent results and returns a bool  representing if the result should be kept
      filter: PropTypes.func.isRequired,
    })
  ),
  className: PropTypes.string,
  currentAgentId: PropTypes.string,
  currentAssignee: PropTypes.shape({
    agentId: PropTypes.string,
    groupId: PropTypes.string,
  }),
  defaultResults: PropTypes.arrayOf(
    PropTypes.shape({
      agentEmail: PropTypes.string,
      agentId: PropTypes.string.isRequired,
      agentName: PropTypes.string,
      routingGroupId: PropTypes.string.isRequired,
      routingGroupName: PropTypes.string.isRequired,
    })
  ),
  defaultResultsGroupName: PropTypes.string,
  renderRow: PropTypes.func.isRequired,
  showSelfAssignmentResultsByDefault: PropTypes.bool,
  title: PropTypes.string,
};

SearchableAssignmentMenu.propTypes = {
  ...SHARED_PROP_TYPES,

  agentResults: PropTypes.arrayOf(
    PropTypes.shape({
      agentEmail: PropTypes.string,
      agentId: PropTypes.string.isRequired,
      agentName: PropTypes.string,
      routingGroupId: PropTypes.string.isRequired,
      routingGroupName: PropTypes.string.isRequired,
    })
  ).isRequired,
  selfAssignmentResults: PropTypes.arrayOf(
    PropTypes.shape({
      agentEmail: PropTypes.string,
      agentId: PropTypes.string.isRequired,
      agentName: PropTypes.string,
      routingGroupId: PropTypes.string.isRequired,
      routingGroupName: PropTypes.string.isRequired,
    })
  ),
  inboxResults: PropTypes.arrayOf(
    PropTypes.shape({
      routingGroupId: PropTypes.string.isRequired,
      routingGroupName: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSearch: PropTypes.func.isRequired,
};

const AGENT_LIMIT = 30;
const INBOX_OPTIONS = {
  distance: 100,
  location: 0,
  keys: ['routingGroupName'],
  threshold: 0.4,
};
const AGENT_OPTIONS = {
  distance: 100,
  location: 0,
  keys: ['agentName'],
  shouldSort: true,
  threshold: 0.2,
};

export class AssignmentMenuSearcher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { agentResults: [], inboxResults: [] };

    this.onSearchTextChange = this.onSearchTextChange.bind(this);
  }

  onSearchTextChange(evt) {
    const searchText = evt.target.value;
    this.setState({
      agentResults: getAgentResults(this.props.agentSearch, searchText, this.props.agentToInboxes),
      inboxResults: this.props.inboxSearch.search(searchText),
      hasSearchText: !!searchText,
    });
    this.menu && this.menu.resetScrolling();
  }

  getSelfAssignmentResults() {
    if (this.props.showSelfAssignmentResultsByDefault) {
      const currentAgent = _.find(this.props.agentSearch.list, a => a.agentId === this.props.currentAgentId);
      const agentInboxes = this.props.agentToInboxes[this.props.currentAgentId];
      if (currentAgent && agentInboxes) {
        return _.map(
          _.filter(
            agentInboxes,
            agentInbox =>
              !(
                this.props.currentAssignee &&
                this.props.currentAssignee.groupId === agentInbox.routingGroupId &&
                this.props.currentAssignee.agentId === currentAgent.agentId
              )
          ),
          agentInbox => ({ ...currentAgent, ...agentInbox })
        );
      }
    }
    return undefined;
  }

  render() {
    return (
      <SearchableAssignmentMenu
        agentResults={this.state.agentResults}
        agentResultsCategories={this.props.agentResultsCategories}
        className={this.props.className}
        defaultResults={this.props.defaultResults}
        hasSearchText={this.state.hasSearchText}
        inboxResults={this.state.inboxResults}
        onSearch={this.onSearchTextChange}
        ref={node => (this.menu = node)}
        renderRow={this.props.renderRow}
        selfAssignmentResults={this.getSelfAssignmentResults()}
        title={this.props.title}
      />
    );
  }
}

AssignmentMenuSearcher.propTypes = {
  ...SHARED_PROP_TYPES,

  agentSearch: PropTypes.instanceOf(Fuse).isRequired,
  agentToInboxes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  inboxSearch: PropTypes.instanceOf(Fuse).isRequired,
};

function getAgentResults(agentSearch, searchText, agentToInboxes) {
  const rawResults = agentSearch.search(searchText).slice(0, AGENT_LIMIT);

  const results = [];
  _.forEach(rawResults, agent => {
    _.forEach(agentToInboxes[agent.agentId], agentInbox => {
      results.push({ ...agent, ...agentInbox });
    });
  });
  return results;
}

export class AssignmentMenuCache extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getAgentFuzzySearch(props),
      ...this.getInboxFuzzySearch(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const areInboxIdsEqual = _.isEqual(nextProps.inboxIds, this.props.inboxIds);

    if (
      !areInboxIdsEqual ||
      nextProps.agentsImm !== this.props.agentsImm ||
      nextProps.routingGroupsImm !== this.props.routingGroupsImm ||
      nextProps.excludedAgentIds !== this.props.excludedAgentIds
    ) {
      this.setState(this.getAgentFuzzySearch(nextProps));
    }

    if (!areInboxIdsEqual || nextProps.routingGroupsImm !== this.props.routingGroupsImm) {
      this.setState(this.getInboxFuzzySearch(nextProps));
    }
  }

  getAgentFuzzySearch({
    agentsProvider,
    currentAgentId,
    currentAssignee,
    excludedAgentIds,
    inboxIds,
    routingGroupsProvider,
  }) {
    const inboxIdsSet = new Set(inboxIds);
    const findAttrs = inboxIds
      ? {
          filter: inbox => inboxIdsSet.has(inbox.id),
        }
      : undefined;

    let inboxes = _.sortBy(routingGroupsProvider.findAll(findAttrs), i => i.name.toLowerCase());
    // Prefer showing the current inbox first, but keep everything else sorted
    if (currentAssignee) {
      const currentInboxIndex = _.findIndex(inboxes, i => i.id === currentAssignee.groupId);
      if (currentInboxIndex > -1) {
        const currentInbox = inboxes[currentInboxIndex];
        inboxes.splice(currentInboxIndex, 1);
        inboxes.unshift(currentInbox);
      }
    }
    inboxes = _.filter(inboxes, inbox => !inbox.disabled);

    const agentIds = new Set();
    const agentToInboxes = {};
    _.forEach(inboxes, inbox => {
      const inboxName =
        currentAssignee && inbox.id === currentAssignee.groupId ? `${inbox.name} (Current)` : inbox.name;

      _.forEach(inbox.agentIds, id => {
        agentIds.add(id);
        if (id in agentToInboxes) {
          agentToInboxes[id].push({ routingGroupId: inbox.id, routingGroupName: inboxName });
        } else {
          agentToInboxes[id] = [{ routingGroupId: inbox.id, routingGroupName: inboxName }];
        }
      });
    });

    const agents = agentsProvider
      .findAll({
        select: ['id', 'name', 'email'],
        filter: agent => !agent.disabledAt && agentIds.has(agent.id) && !_.includes(excludedAgentIds, agent.id),
      })
      .map(a => ({
        agentEmail: a.email,
        agentId: a.id,
        agentName: a.id === currentAgentId ? `${a.name} (You)` : a.name,
      }));

    const agentSearch = new Fuse(agents, AGENT_OPTIONS);
    return { agentToInboxes, agentSearch };
  }

  getInboxFuzzySearch({ inboxIds, routingGroupsProvider }) {
    const inboxIdsSet = new Set(inboxIds);
    const findAttrs = inboxIds
      ? {
          select: ['id', 'name', 'disabled'],
          filter: inbox => inboxIdsSet.has(inbox.id),
        }
      : {
          select: ['id', 'name', 'disabled'],
        };
    let inboxes = routingGroupsProvider.findAll(findAttrs);
    inboxes = _.filter(inboxes, inbox => !inbox.disabled);
    inboxes = inboxes.map(inbox => ({
      routingGroupId: inbox.id,
      routingGroupName: inbox.name,
    }));

    return { inboxSearch: new Fuse(inboxes, INBOX_OPTIONS) };
  }

  render() {
    return (
      <OutsideClickHandler onClickOutside={this.props.onBlur}>
        <AssignmentMenuSearcher
          agentSearch={this.state.agentSearch}
          agentToInboxes={this.state.agentToInboxes}
          defaultResults={this.props.defaultResults}
          inboxSearch={this.state.inboxSearch}
          {..._.pick(this.props, _.keys(SHARED_PROP_TYPES))}
        />
      </OutsideClickHandler>
    );
  }
}

AssignmentMenuCache.defaultProps = { excludedAgentIds: [] };

AssignmentMenuCache.propTypes = {
  ...SHARED_PROP_TYPES,

  agentsImm: PropTypes.object.isRequired,
  agentsProvider: PropTypes.object.isRequired,
  currentAgentId: PropTypes.string.isRequired,
  excludedAgentIds: PropTypes.arrayOf(PropTypes.string),
  inboxIds: PropTypes.arrayOf(PropTypes.string),
  routingGroupsImm: PropTypes.object.isRequired,
  routingGroupsProvider: PropTypes.object.isRequired,

  onBlur: PropTypes.func.isRequired,
};

const AssignmentMenuContainer = connect(mapStateToProps)(AssignmentMenuCache);

function mapStateToProps({ getProvider, isFeatureEnabled }, { defaultResults }) {
  return {
    agentsImm: getProvider('agents').immutableStore.binding.get(),
    agentsProvider: getProvider('agents'),
    currentAgentId: getProvider('currentAgent').get().id,
    defaultResults,
    routingGroupsImm: getProvider('routingGroups').immutableStore.binding.get(),
    routingGroupsProvider: getProvider('routingGroups'),
  };
}

AssignmentMenuContainer.propTypes = {
  ...SHARED_PROP_TYPES,

  excludedAgentIds: PropTypes.arrayOf(PropTypes.string),
  inboxIds: PropTypes.arrayOf(PropTypes.string),
  onBlur: PropTypes.func.isRequired,
};

export default AssignmentMenuContainer;
