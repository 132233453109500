import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Add In Circle Stroke
 */

export default function AddInCircleStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 3C8.30682 3 8.55556 3.24873 8.55556 3.55556V7.44443H12.4444C12.7513 7.44443 13 7.69316 13 7.99999C13 8.30681 12.7513 8.55554 12.4444 8.55554H8.55556V12.4444C8.55556 12.7513 8.30682 13 8 13C7.69318 13 7.44444 12.7513 7.44444 12.4444V8.55554H3.55556C3.24873 8.55554 3 8.30681 3 7.99999C3 7.69316 3.24873 7.44443 3.55556 7.44443H7.44444V3.55556C7.44444 3.24873 7.69318 3 8 3Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
