import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ServerClock from 'scripts/application/lib/server_clock';

const TaskFollower = createModel({
  modelName: 'TaskFollower',
  properties: {
    id: prop(String).isRequired,
    itemId: prop(String).isRequired,
    agentId: prop(String).isRequired,
    createdAt: prop(String).isRequired,
    version: prop(Number).default(0),
  },

  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), createdAt: ServerClock.toISOString(), ...attrs });
    },
  },
});

export default TaskFollower;
