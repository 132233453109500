import ActivateDefaultResponse from 'actions/composition/activate_default_response';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import SelectedSuggestedReply from 'models/selected_suggested_reply';
import { getConversationContext, getSuggestedReplyContext, snowplowTypes } from 'scripts/lib/analytics/domain/snowplow';

export default class InsertSelectedSuggestedReply {
  constructor(context) {
    this.context = context;
  }

  run() {
    const suggestedReply = this.context.stores.suggestedReply.get();
    if (!suggestedReply) {
      return;
    }
    const insertedSuggestedReply = SelectedSuggestedReply.create({
      item: suggestedReply,
    });

    this.context.stores.selectedSuggestedReply.set(insertedSuggestedReply);

    const currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation.customerId;

    const { conversations } = this.context.stores.customers.storesFor(customerId);
    const conversationId = getActiveConversationId(conversations);
    let currentCompositionId = currentLocation.currentCompositionId;
    if (!currentCompositionId && conversationId) {
      this.context.executeAction(ActivateDefaultResponse, {
        conversationId,
      });
    }

    const contexts = [
      getConversationContext({ conversationId, customerId }),
      getSuggestedReplyContext(suggestedReply.id),
    ];
    this.context.analytics.trackSnowplow(snowplowTypes.EVENT_SUGGESTED_REPLY_USED, { method: 'INSERTED' }, contexts);
  }
}
