import createReactClass from 'create-react-class';
import React from 'react';

const ContextEvent = createReactClass({
  render() {
    let contextEvent = this.props.contextEvent;

    return (
      <li className="contextContent-event">
        <i className="contextContent-event-circle fa fa-circle" />
        <div className="contextContent-event-details">
          <div className="contextContent-event-primaryDetail">{formatEventPrimaryDetail(contextEvent)}</div>
          <div className="contextContent-event-secondaryDetail">{formatEventSecondaryDetail(contextEvent)}</div>
        </div>
      </li>
    );
  },
});

// this is temporary until we finalize the context event structure and output templates
function formatEventPrimaryDetail(contextEvent) {
  let key = `${contextEvent.type}:${contextEvent.action}`;

  switch (key) {
    case 'reservation:search':
      return `Searching for reservation at ${contextEvent.value.property} for a ${contextEvent.value.roomType} Check-In: ${contextEvent.value.checkIn} Check-Out: ${contextEvent.value.checkOut}`;

    case 'policy:search':
      return `Searching for policy: ${contextEvent.value.property}`;

    case 'contact:voice':
      return `Called customer support from ${contextEvent.value.phoneNumber}`;

    case 'knowledge-base:search':
      return `Searched knowledge base for: "${contextEvent.value.searchText}"`;

    case 'help-app:open':
      return `Opened the help app on ${contextEvent.value.url}`;
    case 'help-app:search':
      return `Searched for "${contextEvent.value.searchText}"`;
    case 'help-app:view':
      return `Viewed "${contextEvent.value.answerTitle}"`;
    case 'help-app:request':
      return `Requested to chat with an agent`;

    default:
      return JSON.stringify(contextEvent.value);
  }
}

function formatEventSecondaryDetail(contextEvent) {
  let key = `${contextEvent.type}:${contextEvent.action}`;

  switch (key) {
    case 'knowledge-base:search':
      return `Number of returned results was: ${contextEvent.value.searchResults}`;
    case 'help-app:view':
      return contextEvent.value.answerText;
    case 'help-app:open':
    case 'help-app:search':
    case 'help-app:app':
    case 'help-app:request':
      return null;
    default:
      return 'secondary';
  }
}

export default ContextEvent;
