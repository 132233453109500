import { Factory } from 'rosie';

Factory.define('ExternalCustomerLookupResult');

Factory.define('ExternalCustomerLookupResultWithDefaults')
  .extend('ExternalCustomerLookupResult')
  .attr('id', 'some-correlation-id')
  .attr('results', () => [Factory.attributes('ExternalCustomerProfileWithDefaults')]);

Factory.define('ExternalCustomerProfile');

Factory.define('ExternalCustomerProfileWithDefaults')
  .extend('ExternalCustomerProfile')
  .attr('address', '42 Wallaby Way\nSydney, Australia')
  .attr('customAttributes', {
    favoriteDay: 'Sunday',
    favoriteFruit: 'Apple',
  })
  .attr('emails', () => [Factory.attributes('CustomerEmailAddressPrimary')])
  .attr('externalCustomerId', 'example-external-customer-id')
  .attr('name', 'Dory')
  .attr('phones', () => [Factory.attributes('PhoneNumberWithDefaults')]);
