import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import BubbleContent from 'components/customer/conversation_history/conversation_items_v2/content/bubble_content';
import ConversationItem from 'models/conversation_item';

import CampaignOutreachMetadata from '../metadata/campaign_outreach_metadata';
import ItemContent from '../item_content';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const StyledBubbleContent = styled(BubbleContent)`
  min-width: 250px;
  color: ${p => p.theme.colors.gray600};
`;

export default function VoiceCampaignOutreachItem({ item }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  return (
    <ItemContent item={item}>
      <CampaignOutreachMetadata isAdmin={isFeatureEnabled('adminSettings')} item={item} />
      <StyledBubbleContent data-aid={'description'} item={item}>
        {item.content.description}
      </StyledBubbleContent>
    </ItemContent>
  );
}

VoiceCampaignOutreachItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
