import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

// See ./click_to_edit_field.md
export default function(FieldDisplay, FieldEditor) {
  class ClickToEditField extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isEditing: !_.isEmpty(this.props.errors),
        value: this.props.value,
      };

      _.bindAll(this, ['handleCancel', 'handleDisplayClick', 'handleEditorBlur', 'handleSubmit']);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.forceDisplay && this.state.isEditing) {
        this.setState({ isEditing: false, value: nextProps.value });
      } else if (!this.state.isEditing) {
        this.setState({ value: nextProps.value });
      }

      if (this.state.isSavePending && this.isDoneSaving(nextProps)) {
        this.setState({ isSavePending: false });

        if (!this.hasErrors(nextProps)) {
          this.setState({ isEditing: false, value: nextProps.value });
        }
      }

      if (this.hasErrors(nextProps)) {
        this.setState({ isSavePending: false });
      }
    }

    hasErrors(props = this.props) {
      return !_(props.errors).isEmpty();
    }

    isDoneSaving(nextProps) {
      return !nextProps.isResponsePending;
    }

    render() {
      return this.state.isEditing ? (
        <FieldEditor
          autoFocus={this.state.focusField || true}
          errors={this.props.errors}
          initialValue={this.state.value}
          isDisabled={this.state.isSavePending}
          onBlur={this.handleEditorBlur}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          {...this.getChildProps()}
        />
      ) : (
        <FieldDisplay
          isDisabled={this.props.isDisabled || this.props.isResponsePending}
          onClick={this.handleDisplayClick}
          value={this.state.value}
          {...this.getChildProps()}
        />
      );
    }

    getChildProps() {
      /* eslint-disable react/forbid-foreign-prop-types */
      return _.omit(this.props, _.keys(ClickToEditField.propTypes));
      /* eslint-enable react/forbid-foreign-prop-types */
    }

    handleDisplayClick(fieldName) {
      if (this.props.isDisabled || this.props.isResponsePending) {
        return;
      }

      this.setState({ isEditing: true, focusField: fieldName });
    }

    handleEditorBlur(value) {
      if (this.state.isSavePending) {
        return;
      }

      if (!_.isEqual(value, this.props.value)) {
        this.save(value);
        return;
      }

      if (!this.hasErrors()) {
        this.closeEditor();
      }
    }

    handleSubmit(newValue) {
      if (this.state.isSavePending) {
        return;
      }

      this.save(newValue);
    }

    handleCancel() {
      if (this.state.isSavePending) {
        return;
      }

      this.setState({ value: this.props.value });
      this.closeEditor();

      if (this.props.onCancelEdit) {
        this.props.onCancelEdit();
      }
    }

    closeEditor() {
      this.setState({ isEditing: false });
    }

    save(newValue) {
      this.setState({ value: newValue, isSavePending: true });

      if (this.props.onSave) {
        this.props.onSave(newValue);
      }
    }
  }

  ClickToEditField.propTypes = {
    forceDisplay: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isResponsePending: PropTypes.bool,
    /* eslint-disable react/forbid-foreign-prop-types */
    errors: _.get(FieldEditor, 'propTypes.errors', PropTypes.any),
    value: _.get(FieldEditor, 'propTypes.initialValue', PropTypes.any),
    /* eslint-enable react/forbid-foreign-prop-types */

    onCancelEdit: PropTypes.func,
    onSave: PropTypes.func,
  };

  return ClickToEditField;
}
