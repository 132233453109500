import _ from 'lodash';

import changeComposition from 'actions/composition/lib/change_composition';
import CloseModal from 'actions/modal/close_modal';
import CustomerView from 'models/location/customer_view';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import removeComposition from 'actions/composition/lib/remove_composition';

export default class CloseCurrentComposition {
  constructor(context) {
    this.context = context;
  }

  run() {
    // HACK: If a modal is still open after we close the composition, then the gray overlay will remain.
    //       This isn't the proper fix, see https://jira.mertslounge.ca/browse/DEV-8253
    this.context.executeAction(CloseModal);
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error(
        `cannot clear current composition when current location is [${currentLocation &&
          currentLocation.constructor.name}]`
      );
    }

    this.context.stores.selectedKbItem.set(null);

    let currentCompositionId = currentLocation.currentCompositionId;
    let composition = getCompositionsStore(this.context).findBy({ id: currentCompositionId });

    if (composition) {
      this.focusPreviousComposition(composition);
      removeComposition(this.context, composition);
    }

    getCompositionsStore(this.context).clearErrorsForNew();
  }

  // set the composition to the previous composition for the conversation
  focusPreviousComposition(composition) {
    let prevComposition = this.getPreviousComposition(composition);
    changeComposition(this.context, prevComposition);

    // Only set composition last closed at if we've closed all the compositions
    if (!prevComposition) {
      this.setCompositionLastClosedAtToNow();
    }
  }

  getPreviousComposition(composition) {
    const compositionsStore = getCompositionsStore(this.context, composition.customerId);
    let compositions = compositionsStore.findAll({ customerId: composition.customerId });

    let index = _.findIndex(compositions, { id: composition.id });
    if (index === 0) {
      index = 1;
      for (; index < compositions.length; index++) {
        const nextComposition = compositions[index];
        if (!compositionsStore.isPendingDelete(nextComposition.id)) {
          return nextComposition;
        }
      }
    } else if (index > 0) {
      index = index - 1;
      for (; index >= 0; index--) {
        const nextComposition = compositions[index];
        if (!compositionsStore.isPendingDelete(nextComposition.id)) {
          return nextComposition;
        }
      }
    }

    return null;
  }

  setCompositionLastClosedAtToNow() {
    const location = this.context.stores.currentLocation.get();
    if (location.setCompositionLastClosedAtToNow) {
      location.setCompositionLastClosedAtToNow();
      this.context.stores.currentLocation.set(location);
    }
  }
}

CloseCurrentComposition.actionName = 'ClearCurrentComposition'; // for tracking actions in analytics
