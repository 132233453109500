import connect from 'components/lib/connect';
import get from 'lodash/get';
import React from 'react';

import { ButtonTypes } from 'components/common/button';
import ConfirmOrCancelModal from 'components/common/confirm_or_cancel_modal';
import CloseModal from 'actions/modal/close_modal';
import DeleteCustomer from 'actions/compliance/customer/delete_customer';

function mapStateToProps({ getProvider }) {
  const profile = getProvider('profile').get();

  const customerId = get(profile, 'id');

  return {
    customerId,
    title: 'Delete customer',
    submitText: 'Delete customer',
    text: 'Are you sure you want to delete the customer. This cannot be undone.',
    submitButtonType: ButtonTypes.DANGER,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onCancel: () => {
      executeAction(CloseModal);
    },
    onConfirm: props => {
      executeAction(DeleteCustomer, props);
    },
  };
}

const CustomerDeleteModal = props => {
  const onSubmit = () => {
    props.onConfirm({ customerId: props.customerId });
  };

  return <ConfirmOrCancelModal {...props} onSubmit={onSubmit} />;
};

const CustomerDeleteModalContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerDeleteModal);

export default CustomerDeleteModalContainer;
