import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import connect from 'components/lib/connect';
import Link from 'components/common/link';
import { MenuContainer, MenuItem } from 'components/lib/menu_deprecated';
import SummaryCard from 'components/customer/summary/lib/summary_card';

function CustomerRelationships({ customAttributes, showRelationships }) {
  if (!showRelationships) {
    return null;
  }

  let relationships = _.get(customAttributes, 'relationships.items');
  if (_.isEmpty(relationships)) {
    return null;
  }

  return (
    <SummaryCard>
      <div className={'customEvents-relationshipHeader'}>
        <div className={'customerSummaryCard-headerText'}>
          {_.get(customAttributes, 'relationships.label', 'Relationships')}
        </div>
        <MenuContainer
          button={<i className="customerContactMenu-button-icon icon icon-plus" />}
          className="customerContactMenu"
          isDisabled={false}
        >
          <MenuItem className="customerContactMenu-item" key="1">
            Add Relationship
          </MenuItem>
        </MenuContainer>
      </div>
      <div className="customEvents-listWrapper">
        <div className={classnames('customEvents-list')}>{relationships.map(renderRelationship)}</div>
      </div>
    </SummaryCard>
  );

  function renderRelationship(relationship, index) {
    return (
      <div className={'customEvents-relationship'} key={index}>
        <Link href={_.get(relationship, 'url')}>{_.get(relationship, 'name')}</Link>
        <div>{_.get(relationship, 'relationship')}</div>
      </div>
    );
  }
}

CustomerRelationships.propTypes = {
  customAttributes: PropTypes.object.isRequired,
  showRelationships: PropTypes.bool,
};

export { CustomerRelationships };

const CustomerRelationshipsContainer = connect(mapStateToProps)(CustomerRelationships);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  return {
    customAttributes: _.get(getProvider('profile').get(), 'customAttributes') || {},
    showRelationships: isFeatureEnabled('demoMode'),
  };
}

CustomerRelationshipsContainer.propTypes = {
  customAttributes: PropTypes.object,
};

export default CustomerRelationshipsContainer;
