import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('EmailPreview')
    .attr('type', 'EMAIL_PREVIEW');

Factory.define('EmailPreviewWithDefaults')
    .extend('EmailPreview')
    .attr('from', 'support@example.com')
    .attr('subject', 'What happened to Buzzsaw?')
    .attr('excerpt', 'He had to make like banana and split.');

Factory.define('EmailPreviewItem')
    .extend('ConversationItem')
    .attr('content', ['content'], (content) => Factory.attributes('EmailPreview', content));

Factory.define('EmailPreviewItemWithDefaults')
    .extend('ConversationItemWithDefaults')
    .attr('content', ['content'], (content) => Factory.attributes('EmailPreviewWithDefaults', content));