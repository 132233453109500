import { Factory } from 'rosie';
import moment from 'moment';
import '../conversation_item_factory';
import configureIdGenerator from '../lib/configure_id_generator';

let IdGenerator = configureIdGenerator();

Factory.define('PhoneCall').attr('type', 'PHONE_CALL');

Factory.define('PhoneCallIncoming')
  .extend('PhoneCall')
  .attr('startedAt', ['status'], status => {
    if (status === 'COMPLETED') {
      return moment()
        .subtract(30, 'minutes')
        .format();
    } else {
      return new Date().toISOString();
    }
  })
  .attr('answeredAt', ['status'], status => {
    if (status === 'COMPLETED') {
      return moment()
        .subtract(29, 'minutes')
        .format();
    } else {
      return null;
    }
  })
  .attr('completedAt', ['status'], status => {
    if (status === 'COMPLETED') {
      return moment()
        .subtract(25, 'minutes')
        .format();
    } else {
      return null;
    }
  })
  .attr('customerNumber', '555-555-5555')
  .attr('companyNumber', '888-888-8888')
  .attr('status', 'INCOMING')
  .attr('participants', [])
  .attr('participantEvents', [])
  .attr('callQualityEvents', [])
  .attr('recordingEvents', [{ status: 'IN_PROGRESS' }]);

Factory.define('PhoneCallCompleted')
  .extend('PhoneCall')
  .attr('customerNumber', '555-555-5555')
  .attr('status', 'COMPLETED')
  .attr('participants', [
    {
      type: 'AGENT',
      status: 'HUNG_UP',
    },
  ]);

Factory.define('PhoneCallWithDefaults')
  .extend('PhoneCallIncoming')
  .attr('transcribedText', ['status'], status => {
    if (status === 'COMPLETED') {
      return 'transcribed text goes here';
    }
    return undefined;
  })
  .attr('hasVoicemail', false)
  .attr('isAbandoned', false)
  .attr('status', 'COMPLETED')
  .attr('recordingId', 'call-recording-1')
  .attr('recordingDuration', 29)
  .attr('recordingEvents', [])
  .attr('recordingStatus', 'AVAILABLE');

Factory.define('PhoneCallItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('PhoneCall', content));

Factory.define('PhoneCallItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('PhoneCallWithDefaults', content))
  .attr('responder', {
    type: 'AGENT',
    id: IdGenerator.newId(),
  });

Factory.define('PhoneCallItemIncoming')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('PhoneCallIncoming', content));

Factory.define('PhoneCallItemCompleted')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('PhoneCallCompleted', content));

Factory.define('PhoneCallTransfer');

Factory.define('PhoneCallTransferWithDefaults')
  .extend('PhoneCallTransfer')
  .attr('type', 'WARM')
  .attr('status', 'IN_PROGRESS')
  .attr('toRoutingGroupId', 'a-routing-group-id')
  .attr('toParticipantId', 'transferee-agent-id');
