import _ from 'lodash';

import ClearRecentCustomers from 'actions/recent_customers/clear_recent_customers';
import OpenSearchResults from 'actions/search/open_search_results';
import { registerUrlConverter } from './location_url';
import Search from 'models/location/search';
import SearchQueryConverter from 'scripts/adapters/routes/url_converters/search_query_converter';
import SearchUrlConverter from 'scripts/adapters/routes/url_converters/search_url_converter';

registerUrlConverter(Search, SearchUrlConverter);

export default function(router, { runnerContext }) {
  return {
    '/?$': {
      on: () => {
        let queryParams = router.parseQueryParameters().toJS();
        let query = _.isEmpty(queryParams) ? null : SearchQueryConverter.fromUrlParameters(queryParams);
        runnerContext.executeAction(OpenSearchResults, query);
      },

      after: () => {
        let searchRouteRe = /\/search/;
        let isNextRouteSearch = !!searchRouteRe.test(router.getPath());
        if (!isNextRouteSearch) {
          runnerContext.executeAction(ClearRecentCustomers);
        }
      },
    },
  };
}
