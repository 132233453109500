import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import { formatPhoneNumber } from 'models/phone_number';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import RecordingStatus from 'models/recording_status';
import Timer from 'components/lib/date/timer';
import VoicemailWavePlayerContainer from './recordings/voicemail_wave_player_container';
import RecordingDetails from './recording_details';

function VoicemailItem({ className, item }) {
  const voicemail = item.content;
  const tooltip = `called ${formatPhoneNumber(voicemail.companyNumber)} from ${formatPhoneNumber(
    voicemail.customerNumber
  )}`;

  let content = (
    <div className="voiceItem-callWave">
      <div className="voiceItem-waveForm" />
      <div className="voiceItem-duration">{getCallDuration()}</div>
    </div>
  );
  if (voicemail.recordingStatus === RecordingStatus.AVAILABLE) {
    content = <VoicemailWavePlayerContainer item={item} />;
  }

  if (voicemail.recordingStatus === RecordingStatus.DELETED) {
    content = <div className="voiceItem-recordingDeleted">Recording has been deleted</div>;
  }
  let contentClasses = classnames(
    'voicemailItem-content',
    {
      'voicemailItem-content-recordingDeleted': voicemail.recordingStatus === RecordingStatus.DELETED,
    },
    className
  );
  return (
    <ItemContent item={item}>
      <ItemMetadata item={item} tooltip={tooltip} />
      <BubbleContent className={contentClasses} data-aid={`voicemailItem-${item.id}`} item={item}>
        {content}
        <RecordingDetails recordingSummary={item.content.recordingSummary} />
      </BubbleContent>
    </ItemContent>
  );

  function getCallDuration() {
    return Timer.displayDuration(voicemail.recordingDuration * 1000 || 0);
  }
}

VoicemailItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default VoicemailItem;
