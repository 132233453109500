import React from 'react';
import PropTypes from 'prop-types';

export default function BackButton({ count, isVisible, onNavigateBack, previousPath }) {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="navigation-back">
      <div className="navigation-backLink" data-aid="navigateBack" href="#" onClick={onNavigateBack}>
        <span className="navigation-backIcon" />
        <span>{previousPath}</span>
      </div>
    </div>
  );
}

BackButton.propTypes = {
  count: PropTypes.number,
  isVisible: PropTypes.bool,
  onNavigateBack: PropTypes.func.isRequired,
  previousPath: PropTypes.string.isRequired,
};
