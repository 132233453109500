import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M11 8.708c1.214-1.129 2-2.81 2-4.264C13 1.99 10.761 0 8 0S3 1.99 3 4.444C3 5.9 3.786 7.58 5 8.708V11h6V8.708zM11 13v-1H5v1h6zm-1 1a2 2 0 11-4 0h4z"
      fillRule="evenodd"
    />
    <path d="M6 7c-.833-.833-2-3 0-5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
