import findIndex from 'lodash/findIndex';

export default class UpdateGreetingSuggestionScore {
  constructor(context) {
    this.context = context;
  }

  run({ greetingSuggestionId }) {
    const existingSuggestion = this.context.stores.greetingSuggestions.findBy({ id: greetingSuggestionId });
    existingSuggestion.incrementScore();
    this.context.stores.greetingSuggestions.addOrReplace(existingSuggestion);
    const localSuggestions = this.context.stores.localGreetingSuggestions.get();
    const existingLocalIndex = findIndex(
      localSuggestions,
      localSuggestion => localSuggestion.id === existingSuggestion.id
    );
    localSuggestions[existingLocalIndex] = existingSuggestion.toJs();
    this.context.stores.localGreetingSuggestions.set(localSuggestions);
  }
}
