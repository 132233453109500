import React from 'react';
import PropTypes from 'prop-types';

import HoverMenu from 'components/customer/lib/hover_menu';
import linkifyIt, { getNormalizedUrl } from 'components/lib/linkify_it';

export default function DraftInlineLink({ children, decoratedText }) {
  const url = getNormalizedUrl(decoratedText);

  return (
    <HoverMenu
      hoverElement={<span className="draftEditor-inlineLink">{children}</span>}
      menuProps={{ contentEditable: false }}
    >
      <a className="draftEditor-inlineLink-link" href={url} rel="noopener noreferrer" target="_blank">
        {url}
      </a>
    </HoverMenu>
  );
}

DraftInlineLink.propTypes = {
  children: PropTypes.node,
  decoratedText: PropTypes.string,
};

export function DraftClickableInlineLink({ children, decoratedText }) {
  const url = getNormalizedUrl(decoratedText);
  return (
    <a className="draftEditor-inlineLink-clickableLink" href={url} rel="noopener noreferrer" target="_blank">
      {decoratedText}
    </a>
  );
}

export function findInlineLinks(contentBlock, callback) {
  const text = contentBlock.getText();
  let matches = linkifyIt.match(text);
  if (matches) {
    matches.forEach(match => {
      callback(match.index, match.lastIndex);
    });
  }
}
