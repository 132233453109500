import React, { useContext } from 'react';
import MessengerFillIcon from 'components/lib/icons/messenger_fill_icon';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function MessengerRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.fbMessage;
  const messengerSla = sla.fbMessage;
  return (
    <FloorboardRow
      activeItems={stats.currentOpenMessages}
      activeLabel="Active Messages"
      currentWait={stats.currentWait}
      icon={<MessengerFillIcon />}
      newItems={stats.currentNewMessages}
      newLabel="New Messages"
      sla={messengerSla}
      waitLabel="Oldest Unanswered Message"
    />
  );
}
