import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';

import ServerClock from 'scripts/application/lib/server_clock';
import TimestampFormatter from './lib/timestamp_formatter';

const TimeAgo = createReactClass({
  getInitialState() {
    return { currentTime: ServerClock.now() };
  },

  componentDidMount() {
    this.startTimer();
  },

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  },

  startTimer() {
    clearInterval(this.timerInterval);
    this.timerInterval = setInterval(this.updateCurrentTime, 30 * 1000);
  },

  updateCurrentTime() {
    this.setState({ currentTime: ServerClock.now() });
  },

  render() {
    let tf = new TimestampFormatter(this.props.timestamp);
    let fullTime = tf.full();
    let shortTime = this.props.shortened ? tf.shortenedAgo() : tf.ago();
    const title = this.props.title !== undefined ? this.props.title : fullTime;

    return (
      <time className={classnames(this.props.className)} dateTime={this.props.timestamp} title={title}>
        {shortTime}
      </time>
    );
  },
});

export default TimeAgo;
