import _ from 'lodash';

import CustomerView from 'models/location/customer_view';
import ExternalCustomerLookupGatewayObserver from './external_customer_lookup_gateway_observer';
import { ExternalCustomerLookupResult } from 'models/external_customer_profile';
import { trimAndRemoveEmptyQueryFields } from './lib/lookup_helpers';

export default class {
  constructor(context) {
    this.context = context;
  }

  run(customerId) {
    const activeIntegration = this.context.stores.integrations.findBy({ enabled: true });
    if (!activeIntegration) {
      return;
    }

    const customerStores = this.context.stores.customers.storesFor(customerId);
    const profile = customerStores.profile.get();
    if (this.isEmpty(profile)) {
      return;
    }

    const { correlationId } = this.context.gateways.customerAutoExtension.update(customerId);

    const pendingLookupResult = new ExternalCustomerLookupResult({ customerId, id: correlationId });
    ExternalCustomerLookupGatewayObserver.setPendingSuggestionRequest(correlationId, pendingLookupResult);

    let currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation instanceof CustomerView && currentLocation.customerId === customerId) {
      currentLocation.setCustomerExtensionPending();
      this.context.stores.currentLocation.set(currentLocation);
    }
  }

  isEmpty(profile) {
    if (!profile) {
      return true;
    }

    let flattenedProfile = {
      ..._.pick(profile.toJs(), [
        'address',
        'emails',
        'externalCustomerId',
        'externalCustomerIds',
        'fbUserProfile',
        'image',
        'name',
        'phones',
        'twitter',
      ]),
      ...profile.customAttributes,
    };
    flattenedProfile.emails = getQueryContactList(flattenedProfile.emails, 'original');
    flattenedProfile.phones = getQueryContactList(flattenedProfile.phones, 'normalized');

    flattenedProfile = trimAndRemoveEmptyQueryFields(flattenedProfile);
    return _.isEmpty(flattenedProfile);
  }
}

function getQueryContactList(attributes, takeAttr) {
  return _(attributes)
    .partition(attr => attr.primary)
    .flatten()
    .compact()
    .map(attr => attr[takeAttr])
    .value();
}
