import { Factory } from 'rosie';

Factory.define('AutoAcceptedSessionNotification');

Factory.define('AutoAcceptedSessionNotificationWithDefaults')
  .extend('AutoAcceptedSessionNotification')
  .attr('type', 'AUTO_ACCEPTED_SESSION_NOTIFICATION')
  .attr('profile', () => Factory.attributes('SessionCustomerWithDefaults'))
  .attr('conversationId', 'conversation-id')
  .attr('id', 'queue-item-id')
  .attr('messagePreview', '')
  .attr('sessionType', 'SMS');
