import { CustomerAccess } from 'models/customer_access_history';
import UnloadCustomer from 'actions/customer/unload_customer';

export function addCustomerToAccessHistory(context, customerId) {
  let customerAccessHistory = context.stores.customerAccessHistory.get();
  customerAccessHistory.put(CustomerAccess.create({ id: customerId }));
  context.stores.customerAccessHistory.set(customerAccessHistory);

  // Prevent too many customers from being loaded, causing a memory leak.
  const unloader = new UnloadCustomer(context);
  unloader.unloadExcessCustomers(context.stores.customerAccessHistory.get());
}

export function removeCustomerFromAccessHistory(context, customerId) {
  let customerAccessHistory = context.stores.customerAccessHistory.get();
  customerAccessHistory.remove(customerId);
  context.stores.customerAccessHistory.set(customerAccessHistory);
}
