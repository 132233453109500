import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import Morearty from 'morearty';
import React from 'react';

import AppFeaturesMixin from 'components/lib/app_features_mixin';
import CapacityCard from 'components/liveboards/charts/capacity_card';
import { CardRow, NumberCard, DurationCard } from 'components/liveboards/cards/metric_card';
import Conversation from 'models/conversation';
import Endpoint from 'models/endpoint';
import LabeledCard from 'components/liveboards/cards/labeled_card';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import ProvidersMixin from '../lib/providers_mixin';
import Search from 'models/location/search';
import SharedBindings from '../lib/shared_bindings_mixin';
import { SumDonutCard, PercentageDonutCard } from './cards/donut_card';
import TrendLineCard from './charts/trend_line_card';

let SummaryLiveboard = createReactClass({
  mixins: [AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindings],

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('agents'));
    this.observeBinding(this.getSharedBinding('channelConfiguration'));
    this.observeBinding(this.getSharedBinding('currentLocation'));
    this.observeBinding(this.getSharedBinding('liveboard'));
    let viewBinding = this.getViewBinding();

    let conversationStats = Immutable.fromJS([
      {
        label: 'New',
        value: 0,
        className: 'summaryLiveboard-stats-conversation-chart-unassigned',
        tooltip: 'Open and unassigned',
      },
      {
        label: 'Open',
        value: 0,
        className: 'summaryLiveboard-stats-conversation-chart-assigned',
        tooltip: 'Open and assigned',
      },
      {
        label: 'Waiting',
        value: 0,
        className: 'summaryLiveboard-stats-conversation-chart-waiting',
        tooltip: 'Waiting for customer reply',
      },
    ]);
    viewBinding.set('conversation', conversationStats);

    let phoneStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('phone', phoneStats);

    let emailStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('email', emailStats);

    let smsStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('sms', smsStats);

    let chatStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('chat', chatStats);

    let fbMessageStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('fbMessage', fbMessageStats);

    let instagramDirectStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('instagramDirect', instagramDirectStats);

    let twitterStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('twitter', twitterStats);

    let whatsappStats = Immutable.fromJS([
      {
        label: 'Exceeding SLA',
        value: 0,
        className: 'summaryLiveboard-stats-exceeding',
        position: 'top',
      },
      {
        label: 'Within SLA',
        value: 0,
        className: 'summaryLiveboard-stats-within',
        position: 'top',
      },
    ]);
    viewBinding.set('whatsapp', whatsappStats);

    viewBinding.set('trend', Immutable.List());
  },

  UNSAFE_componentWillReceiveProps() {
    const liveboard = this.getSharedBinding('liveboard').get();
    if (!liveboard || liveboard.size === 0) {
      return;
    }

    let viewBinding = this.getViewBinding();
    let routingGroupIds = this.getRoutingGroupIds();

    viewBinding.set('timezone', liveboard.get('timezone'));

    let conversation = liveboard.get('conversation');
    viewBinding.sub('conversation.0').set('value', conversation.get('openUnassigned'));
    viewBinding.sub('conversation.1').set('value', conversation.get('openAssigned'));
    viewBinding.sub('conversation.2').set('value', conversation.get('waiting'));

    if (this.includeLinks()) {
      viewBinding.sub('conversation.0').set(
        'link',
        getLocationUrl(
          Search.createFiltered({
            inboxIds: routingGroupIds,
            statuses: [Conversation.InboxStatus.NEW],
          })
        )
      );
      viewBinding.sub('conversation.1').set(
        'link',
        getLocationUrl(
          Search.createFiltered({
            inboxIds: routingGroupIds,
            statuses: [Conversation.InboxStatus.OPEN],
          })
        )
      );
      viewBinding.sub('conversation.2').set(
        'link',
        getLocationUrl(
          Search.createFiltered({
            inboxIds: routingGroupIds,
            statuses: [Conversation.InboxStatus.WAITING],
          })
        )
      );
    }

    const sla = liveboard.get('sla');

    let phone = sla.get('phone');
    if (phone) {
      let phoneOver = phone.get('over') ? phone.get('over') : 0;
      let phoneTotal = phone.get('total') ? phone.get('total') : 0;
      let phoneWithin = phoneTotal - phoneOver;
      viewBinding.sub('phone.0').set('value', phoneOver);
      viewBinding.sub('phone.1').set('value', phoneWithin);

      let phonePct = phoneTotal === 0 ? null : (100 * phoneWithin) / phoneTotal;
      viewBinding.sub('phonePct').set(phonePct);
    }

    let email = sla.get('email');
    if (email) {
      let emailOver = email.get('over') ? email.get('over') : 0;
      let emailTotal = email.get('total') ? email.get('total') : 0;
      let emailWithin = emailTotal - emailOver;
      viewBinding.sub('email.0').set('value', emailOver);
      viewBinding.sub('email.1').set('value', emailWithin);

      let emailPct = emailTotal === 0 ? null : (100 * emailWithin) / emailTotal;
      viewBinding.sub('emailPct').set(emailPct);
    }

    let sms = sla.get('sms');
    if (sms) {
      let smsOver = sms.get('over') ? sms.get('over') : 0;
      let smsTotal = sms.get('total') ? sms.get('total') : 0;
      let smsWithin = smsTotal - smsOver;
      viewBinding.sub('sms.0').set('value', smsOver);
      viewBinding.sub('sms.1').set('value', smsWithin);

      let smsPct = smsTotal === 0 ? null : (100 * smsWithin) / smsTotal;
      viewBinding.sub('smsPct').set(smsPct);
    }

    let chat = sla.get('chat');
    if (chat) {
      let chatOver = chat.get('over') ? chat.get('over') : 0;
      let chatTotal = chat.get('total') ? chat.get('total') : 0;
      let chatWithin = chatTotal - chatOver;
      viewBinding.sub('chat.0').set('value', chatOver);
      viewBinding.sub('chat.1').set('value', chatWithin);

      let chatPct = chatTotal === 0 ? null : (100 * chatWithin) / chatTotal;
      viewBinding.sub('chatPct').set(chatPct);
    }

    let fbMessage = sla.get('fbMessage');
    if (fbMessage) {
      let fbMessageOver = fbMessage.get('over') ? fbMessage.get('over') : 0;
      let fbMessageTotal = fbMessage.get('total') ? fbMessage.get('total') : 0;
      let fbMessageWithin = fbMessageTotal - fbMessageOver;
      viewBinding.sub('fbMessage.0').set('value', fbMessageOver);
      viewBinding.sub('fbMessage.1').set('value', fbMessageWithin);

      let fbMessagePct = fbMessageTotal === 0 ? null : (100 * fbMessageWithin) / fbMessageTotal;
      viewBinding.sub('fbMessagePct').set(fbMessagePct);
    }

    let instagramDirect = sla.get('instagramDirect');
    if (instagramDirect) {
      let instagramDirectOver = instagramDirect.get('over') ? instagramDirect.get('over') : 0;
      let instagramDirectTotal = instagramDirect.get('total') ? instagramDirect.get('total') : 0;
      let instagramDirectWithin = instagramDirectTotal - instagramDirectOver;
      viewBinding.sub('instagramDirect.0').set('value', instagramDirectOver);
      viewBinding.sub('instagramDirect.1').set('value', instagramDirectWithin);

      let instagramDirectPct = instagramDirectTotal === 0 ? null : (100 * instagramDirectWithin) / instagramDirectTotal;
      viewBinding.sub('instagramDirectPct').set(instagramDirectPct);
    }

    let twitter = sla.get('twitter');
    if (twitter) {
      let twitterOver = twitter.get('over') ? twitter.get('over') : 0;
      let twitterTotal = twitter.get('total') ? twitter.get('total') : 0;
      let twitterWithin = twitterTotal - twitterOver;
      viewBinding.sub('twitter.0').set('value', twitterOver);
      viewBinding.sub('twitter.1').set('value', twitterWithin);

      let twitterPct = twitterTotal === 0 ? null : (100 * twitterWithin) / twitterTotal;
      viewBinding.sub('twitterPct').set(twitterPct);
    }

    let whatsapp = sla.get('whatsapp');
    if (whatsapp) {
      let whatsappOver = whatsapp.get('over') ? whatsapp.get('over') : 0;
      let whatsappTotal = whatsapp.get('total') ? whatsapp.get('total') : 0;
      let whatsappWithin = whatsappTotal - whatsappOver;
      viewBinding.sub('whatsapp.0').set('value', whatsappOver);
      viewBinding.sub('whatsapp.1').set('value', whatsappWithin);

      let whatsappPct = whatsappTotal === 0 ? null : (100 * whatsappWithin) / whatsappTotal;
      viewBinding.sub('whatsappPct').set(whatsappPct);
    }

    let oldTrend = viewBinding.get('trend');
    let newTrend = liveboard.get('trend');
    newTrend.forEach(function(newValue, index) {
      oldTrend = oldTrend.update(index, function(oldValue) {
        let update = oldValue || Immutable.Map();

        update = update.set('timestamp', newValue.get('timestamp'));
        update = update.set('newConversations', newValue.get('newConversations'));
        update = update.set('closedConversations', newValue.get('closedConversations'));

        if (!oldValue) {
          oldTrend = oldTrend.push(update);
        }
        return update;
      });
    });

    // if the new trends are shorter than the old trend, we need to clear out the values where weren't overwritten
    let sizeDiff = oldTrend.size - newTrend.size;
    if (sizeDiff > 0) {
      _.times(sizeDiff, function() {
        oldTrend = oldTrend.pop();
      });
    }

    viewBinding.sub('trend').set(oldTrend);
  },

  /* Render */
  render() {
    let liveboard = this.getSharedBinding('liveboard').toJS();
    return this.renderStats(liveboard);
  },

  renderStats(liveboard) {
    return (
      <div className={classnames('summaryLiveboard-stats', this.props.className)}>
        {this.renderMainStats(liveboard)}
        {this.renderPhoneStats(liveboard)}
        {this.renderEmailStats(liveboard)}
        {this.renderSmsStats(liveboard)}
        {this.renderFBMessageStats(liveboard)}
        {this.renderChatStats(liveboard)}
        {this.renderInstagramDirectStats(liveboard)}
        {this.renderTwitterStats(liveboard)}
        {this.renderWhatsappStats(liveboard)}
      </div>
    );
  },

  renderMainStats(liveboard) {
    return (
      <div className="summaryLiveboard-stats-main">
        {this.renderTrendStats(liveboard.trend)}
        <CapacityCard className="summaryLiveboard-stats-capacity" />
        {this.renderConversationStats(liveboard.conversation)}
      </div>
    );
  },

  renderConversationStats(conversationStats) {
    if (conversationStats) {
      let data = this.getViewBinding()
        .sub('conversation')
        .get();

      let link =
        this.includeLinks() &&
        getLocationUrl(
          Search.createFiltered({
            inboxIds: this.getRoutingGroupIds(),
            statuses: [Conversation.InboxStatus.NEW, Conversation.InboxStatus.OPEN, Conversation.InboxStatus.WAITING],
          })
        );

      return (
        <LabeledCard className="summaryLiveboard-stats-conversation" title="Conversations">
          <SumDonutCard data={data} label="Total" link={link} />
        </LabeledCard>
      );
    }
  },

  renderPhoneStats({ channel, sla }) {
    if (this.hasEndpoint(Endpoint.Type.VOICE)) {
      let phoneStats = channel.phone;
      let data = this.getViewBinding()
        .sub('phone')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(phoneStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-voice">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="Voice Statistics">
            <CardRow>
              <NumberCard label="New Calls" value={phoneStats.currentNewCalls} />
              <NumberCard label="Active Calls" value={phoneStats.currentActiveCalls} />
              <NumberCard label="Completed Calls Today" value={phoneStats.dailyTotalCalls} />
              <NumberCard label="Outgoing Calls Today" value={phoneStats.dailyOutgoingCalls} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="Voice Time">
            <CardRow>
              <DurationCard duration={phoneStats.currentWait} label="Current Wait" link={oldestUnansweredLink} />
              <DurationCard duration={phoneStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="Voice SLA Achievement">
            <PercentageDonutCard data={data} label="Within SLA" percentage={this.getViewBinding().get('phonePct')} />
          </LabeledCard>
        </div>
      );
    }
  },

  renderEmailStats({ channel, sla }) {
    if (this.hasEndpoint(Endpoint.Type.EMAIL)) {
      let emailStats = channel.email;
      let data = this.getViewBinding()
        .sub('email')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(emailStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-email">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="Email Statistics">
            <CardRow>
              <NumberCard label="New Emails" value={emailStats.currentNewEmails} />
              <NumberCard label="Open Emails" value={emailStats.currentOpenEmails} />
              <NumberCard label="Outgoing Emails Today" value={emailStats.dailyTotalResponses} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="Email Time">
            <CardRow>
              <DurationCard
                duration={emailStats.currentWait}
                label="Oldest Unanswered Email"
                link={oldestUnansweredLink}
              />
              <DurationCard duration={emailStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="Email SLA Achievement">
            <PercentageDonutCard data={data} label="Within SLA" percentage={this.getViewBinding().get('emailPct')} />
          </LabeledCard>
        </div>
      );
    }
  },

  renderSmsStats({ channel, sla }) {
    if (this.hasEndpoint(Endpoint.Type.SMS)) {
      let smsStats = channel.sms;
      let data = this.getViewBinding()
        .sub('sms')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(smsStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-sms">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="SMS Statistics">
            <CardRow>
              <NumberCard label="New SMS" value={smsStats.currentNewSms} />
              <NumberCard label="Active SMS" value={smsStats.currentOpenSms} />
              <NumberCard label="Outgoing SMSs Today" value={smsStats.dailyTotalResponses} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="SMS Time">
            <CardRow>
              <DurationCard duration={smsStats.currentWait} label="Oldest Unanswered SMS" link={oldestUnansweredLink} />
              <DurationCard duration={smsStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="SMS SLA Achievement">
            <PercentageDonutCard data={data} label="Within SLA" percentage={this.getViewBinding().get('smsPct')} />
          </LabeledCard>
        </div>
      );
    }
  },

  renderChatStats({ channel, sla }) {
    if (this.hasEndpoint(Endpoint.Type.CHAT)) {
      let chatStats = channel.chat;
      let data = this.getViewBinding()
        .sub('chat')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(chatStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-chat">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="Chat Statistics">
            <CardRow>
              <NumberCard label="New Chats" value={chatStats.currentNewChats} />
              <NumberCard label="Active Chats" value={chatStats.currentActiveChats} />
              <NumberCard label="Chats Today" value={chatStats.dailyTotalChats} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="Chat Time">
            <CardRow>
              <DurationCard
                duration={chatStats.currentWait}
                label="Oldest Unanswered Chat"
                link={oldestUnansweredLink}
              />
              <DurationCard duration={chatStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="Chat SLA Achievement">
            <PercentageDonutCard data={data} label="Within SLA" percentage={this.getViewBinding().get('chatPct')} />
          </LabeledCard>
        </div>
      );
    }
  },

  renderFBMessageStats({ channel, sla }) {
    if (this.hasEndpoint(Endpoint.Type.FB_MESSENGER)) {
      let fbMessageStats = channel.fbMessage;
      let data = this.getViewBinding()
        .sub('fbMessage')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(fbMessageStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-fbMessage">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="Facebook Messenger Statistics">
            <CardRow>
              <NumberCard label="New FB Messages" value={fbMessageStats.currentNewMessages} />
              <NumberCard label="Active FB Messages" value={fbMessageStats.currentOpenMessages} />
              <NumberCard label="Outgoing FB Messages Today" value={fbMessageStats.dailyTotalResponses} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="Facebook Messenger Time">
            <CardRow>
              <DurationCard
                duration={fbMessageStats.currentWait}
                label="Oldest Unanswered FB Message"
                link={oldestUnansweredLink}
              />
              <DurationCard duration={fbMessageStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="Facebook Messenger SLA Achievement">
            <PercentageDonutCard
              data={data}
              label="Within SLA"
              percentage={this.getViewBinding().get('fbMessagePct')}
            />
          </LabeledCard>
        </div>
      );
    }
  },

  renderInstagramDirectStats({ channel }) {
    if (this.hasEndpoint(Endpoint.Type.INSTAGRAM_DIRECT) && !!channel.instagramDirect) {
      let instagramDirectStats = channel.instagramDirect;
      let data = this.getViewBinding()
        .sub('instagramDirect')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(instagramDirectStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-instagramDirect">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="Instagram Messaging Statistics">
            <CardRow>
              <NumberCard label="New Instagram Messages" value={instagramDirectStats.currentNewMessages} />
              <NumberCard label="Active Instagram Messages" value={instagramDirectStats.currentOpenMessages} />
              <NumberCard label="Outgoing Instagram Messages Today" value={instagramDirectStats.dailyTotalResponses} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="Instagram Messaging Time">
            <CardRow>
              <DurationCard
                duration={instagramDirectStats.currentWait}
                label="Oldest Unanswered Instagram Message"
                link={oldestUnansweredLink}
              />
              <DurationCard duration={instagramDirectStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="Instagram Messaging SLA Achievement">
            <PercentageDonutCard
              data={data}
              label="Within SLA"
              percentage={this.getViewBinding().get('instagramDirectPct')}
            />
          </LabeledCard>
        </div>
      );
    }
  },

  renderTwitterStats({ channel }) {
    if (this.hasEndpoint(Endpoint.Type.TWITTER) && !!channel.twitter) {
      let twitterStats = channel.twitter;
      let data = this.getViewBinding()
        .sub('twitter')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(twitterStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-twitter">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="Twitter Statistics">
            <CardRow>
              <NumberCard label="New Twitter Messages" value={twitterStats.currentNewMessages} />
              <NumberCard label="Active Twitter Messages" value={twitterStats.currentOpenMessages} />
              <NumberCard label="Outgoing Twitter Messages Today" value={twitterStats.dailyTotalResponses} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="Twitter Time">
            <CardRow>
              <DurationCard
                duration={twitterStats.currentWait}
                label="Oldest Unanswered Twitter"
                link={oldestUnansweredLink}
              />
              <DurationCard duration={twitterStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="Twitter SLA Achievement">
            <PercentageDonutCard data={data} label="Within SLA" percentage={this.getViewBinding().get('twitterPct')} />
          </LabeledCard>
        </div>
      );
    }
  },

  renderWhatsappStats({ channel }) {
    if (this.hasEndpoint(Endpoint.Type.WHATSAPP) && !!channel.whatsapp) {
      let whatsappStats = channel.whatsapp;
      let data = this.getViewBinding()
        .sub('whatsapp')
        .get();

      let oldestUnansweredLink = this.getOldestUnansweredLink(whatsappStats.oldestUnanswered);

      return (
        <div className="summaryLiveboard-stats-whatsapp">
          <LabeledCard className="summaryLiveboard-summaryMetrics" title="WhatsApp Statistics">
            <CardRow>
              <NumberCard label="New WhatsApp Messages" value={whatsappStats.currentNewMessages} />
              <NumberCard label="Active WhatsApp Messages" value={whatsappStats.currentOpenMessages} />
              <NumberCard label="Outgoing WhatsApp Messages Today" value={whatsappStats.dailyTotalResponses} />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-timeMetrics" title="WhatsApp Time">
            <CardRow>
              <DurationCard
                duration={whatsappStats.currentWait}
                label="Oldest Unanswered WhatsApp"
                link={oldestUnansweredLink}
              />
              <DurationCard duration={whatsappStats.dailyAverageResponseTime} label="Avg. Wait Time Today" />
            </CardRow>
          </LabeledCard>
          <LabeledCard className="summaryLiveboard-slaMetrics" title="WhatsApp SLA Achievement">
            <PercentageDonutCard data={data} label="Within SLA" percentage={this.getViewBinding().get('whatsappPct')} />
          </LabeledCard>
        </div>
      );
    }
  },

  renderTrendStats(trendStats) {
    if (trendStats) {
      let immutableTrendStats = this.getViewBinding()
        .sub('trend')
        .get();
      return (
        <LabeledCard className="summaryLiveboard-stats-trends" title={'Conversation Trends Today'}>
          <TrendLineCard
            data={immutableTrendStats}
            includeLinks={this.includeLinks()}
            routingGroupIds={this.getRoutingGroupIds()}
            timezone={this.getViewBinding().get('timezone')}
          />
        </LabeledCard>
      );
    }
  },

  /* helpers*/
  includeLinks() {
    return this.getProvider('appFeatures')
      .get()
      .isEnabled('internalAgentActions');
  },

  getViewBinding() {
    return this.getSharedBinding('liveboard').meta('_view');
  },

  getRoutingGroupIds() {
    let currentLocation = this.getProvider('currentLocation').get();
    return currentLocation.getRoutingGroupIds();
  },

  hasEndpoint(type) {
    let { endpoints } = this.getProvider('channelConfiguration').get() || {};
    return (endpoints || []).some(e => e.type === type);
  },

  getOldestUnansweredLink(oldestUnanswered) {
    if (!(this.includeLinks() && _(oldestUnanswered).get('customerId'))) {
      return null;
    }

    let { conversationId, conversationItemId, customerId } = oldestUnanswered;

    return conversationItemId
      ? `customer/${customerId}/item/${conversationItemId}`
      : `customer/${customerId}/conversation/${conversationId}`;
  },
});

export default SummaryLiveboard;
