import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import { ITEM } from './feed/feed_item_types';
import JumpToItem from 'actions/customer/feed/jump_to_item';

const OPEN_DELAY = 100;
const CLOSE_DELAY = 5000;

/**
 * UnreadMessagesBanner will display that new messages exist when an agent:
 *   1. Navigates to a customer page with a "New Activity" line present in the feed
 *   2. Is scrolled up in the feed and new messages come in
 */
export class UnreadMessagesBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastViewedUnreadLineIndex: props.unreadLineIndex != null ? props.unreadLineIndex : -1,
      isVisible: props.unreadLineIndex != null && props.unreadLineIndex > -1,
    };

    if (props.unreadLineIndex != null && props.latestItemSeenIndex > props.unreadLineIndex) {
      this.closeTimeout = props.setTimeout(() => {
        this.setState({
          isVisible: false,
        });
      }, CLOSE_DELAY);
    }

    this.onClickClose = this.onClickClose.bind(this);
    this.onClickJump = this.onClickJump.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we don't have an unreadLineIndex, immediately close.
    if (this.state.isVisible && (nextProps.unreadLineIndex == null || nextProps.unreadLineIndex === -1)) {
      this.props.clearTimeout(this.closeTimeout);
      this.props.clearTimeout(this.openTimeout);
      this.setState({ isVisible: false });
      return;
    }

    // If we have a new unreadLineIndex, we should open
    if (nextProps.unreadLineIndex != null && nextProps.unreadLineIndex > this.state.lastViewedUnreadLineIndex) {
      this.props.clearTimeout(this.closeTimeout);
      this.props.clearTimeout(this.openTimeout);
      this.openTimeout = this.props.setTimeout(() => {
        this.setState({
          isVisible: true,
        });
      }, OPEN_DELAY);
    }

    // If we scroll down to an item below the unread line, we should queue up a close
    if (nextProps.latestItemSeenIndex > nextProps.unreadLineIndex) {
      this.props.clearTimeout(this.closeTimeout);
      this.closeTimeout = this.props.setTimeout(() => {
        this.setState({
          isVisible: false,
        });
      }, CLOSE_DELAY);
      this.setState({ lastViewedUnreadLineIndex: nextProps.unreadLineIndex });
    }
  }

  componentWillUnmount() {
    this.props.clearTimeout(this.openTimeout);
    this.props.clearTimeout(this.closeTimeout);
  }

  render() {
    if (!this.state.isVisible) {
      return null;
    }

    let numUnread = 0;
    for (let i = this.props.unreadLineIndex; i < this.props.items.length; i++) {
      if (this.props.items[i] && this.props.items[i].type === ITEM && !this.props.items[i].initiatedByCurrentAgent) {
        numUnread += 1;
      }
    }

    if (numUnread === 0) {
      return null;
    }

    let since = '';
    const lastReadItem = findLastReadItem(this.props.items, this.props.unreadLineIndex);
    if (lastReadItem && lastReadItem.timestamp) {
      const readToMoment = moment(lastReadItem.timestamp);
      const readToTime = readToMoment.format('h:mm A');
      const now = moment();
      const readToDate = now.startOf('day').isAfter(readToMoment) ? ` on ${readToMoment.format('MMMM Do')}` : '';
      const readToYear = now.startOf('year').isAfter(readToMoment) ? `, ${readToMoment.format('YYYY')}` : '';
      since = ` since ${readToTime}${readToDate}${readToYear}`;
    }

    return (
      <div className="unreadMessagesBanner">
        <div className="unreadMessagesBanner-message" onClick={this.onClickJump}>
          {numUnread} new item
          {numUnread > 1 ? 's' : ''}
          {since}
        </div>
        <div className="unreadMessagesBanner-close" data-aid="unreadMessagesBanner-close" onClick={this.onClickClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    );
  }

  onClickJump() {
    const nextUnreadItemIndex = findNextUnreadItemIndex(this.props.items, this.props.unreadLineIndex);
    if (this.props.items.length > nextUnreadItemIndex) {
      this.props.onClickJump(this.props.items[nextUnreadItemIndex].id);
    }
  }

  onClickClose() {
    this.props.clearTimeout(this.openTimeout);
    this.props.clearTimeout(this.closeTimeout);
    this.setState({ isVisible: false });
  }
}

function findNextUnreadItemIndex(items, unreadLineIndex) {
  for (let i = unreadLineIndex; i < items.length; i++) {
    if (items[i].type === ITEM) {
      return i;
    }
  }
  return null;
}

function findLastReadItemIndex(items, unreadLineIndex) {
  for (let i = unreadLineIndex - 1; i >= 0; i--) {
    if (items[i].type === ITEM) {
      return i;
    }
  }
  return null;
}

function findLastReadItem(items, unreadLineIndex) {
  const index = findLastReadItemIndex(items, unreadLineIndex);
  return index != null ? items[index] : null;
}

UnreadMessagesBanner.propTypes = {
  items: PropTypes.array,
  latestItemSeenIndex: PropTypes.number,
  onClickJump: PropTypes.func,
  unreadLineIndex: PropTypes.number,

  clearTimeout: PropTypes.func.isRequired,
  setTimeout: PropTypes.func.isRequired,
};

UnreadMessagesBanner.defaultProps = {
  clearTimeout: window.clearTimeout.bind(window),
  setTimeout: window.setTimeout.bind(window),
};

const UnreadMessagesBannerContainer = connect(undefined, mapExecuteToProps)(UnreadMessagesBanner);

function mapExecuteToProps(executeAction) {
  return {
    onClickJump: id => executeAction(JumpToItem, id),
  };
}

UnreadMessagesBannerContainer.propTypes = {
  items: PropTypes.array,
  latestItemSeenIndex: PropTypes.number,
  unreadLineIndex: PropTypes.number,
};

export default UnreadMessagesBannerContainer;
