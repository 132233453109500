import BackButton from './back_button';
import connect from './connect';
import InboxView from 'models/location/inbox';
import KbAdmin from 'models/location/kb_admin';
import NavigateBack from 'actions/current_location/navigate_back';

export default connect(mapStateToProps, mapExecuteToProps)(BackButton);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const areAgentActionsEnabled = isFeatureEnabled('internalAgentActions');
  const areAnswersAdminEnabled = isFeatureEnabled('kbManagement');
  const personalInboxCounts = getProvider('personalInboxCounts').get();
  const currentLocation = getProvider('currentLocation').get();
  const previousLocation = getProvider('locationHistory')
    .get()
    .getPreviousEntry();
  const previousLocationIsInbox = previousLocation instanceof InboxView;
  const count = previousLocationIsInbox && personalInboxCounts ? personalInboxCounts.openUnreadCount || 0 : undefined;
  const isVisible =
    (!(currentLocation instanceof InboxView) && areAgentActionsEnabled) ||
    (!(currentLocation instanceof KbAdmin) && !(currentLocation instanceof InboxView) && areAnswersAdminEnabled);

  let previousPath = previousLocation.constructor.breadcrumb;
  if (previousPath === InboxView.breadcrumb) {
    previousPath = 'Home';
  }

  return {
    count,
    isVisible,
    previousPath,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onNavigateBack: () => executeAction(NavigateBack),
  };
}
