import AgentStatus from 'models/agent_status';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';

export default class AgentStatusesGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  onBroadcast(dto) {
    this.store.addOrReplace(AgentStatus.fromJs(dto));
  }

  onFetchAllSuccess(dtos) {
    if (dtos) {
      this.store.set(dtos.map(s => AgentStatus.fromJs(s)));
    }
  }

  onFetchAllError(err) {
    this.errorHandler.handleCommonErrors(err);
  }

  get store() {
    return this.context.stores.agentStatuses;
  }
}
