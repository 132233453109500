import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

const TextareaControlled = createReactClass({
  propTypes: {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    pending: PropTypes.bool,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.string.isRequired,
  },

  getInitialState() {
    return { workingValue: this.props.value };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.pending && nextProps.value !== this.state.workingValue) {
      this.setState({ workingValue: nextProps.value });
    }
  },

  onChange(evt) {
    this.setState({ workingValue: evt.target.value });
  },

  onSubmit() {
    if (this.state.workingValue !== this.props.value) {
      this.props.onSubmit(this.state.workingValue);
    }
  },

  setRef(node) {
    this.textarea = node;
  },

  render() {
    return (
      <ReactTextareaAutosize
        className={this.props.className}
        disabled={this.props.disabled || this.props.pending}
        onBlur={this.onSubmit}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        ref={this.setRef}
        value={this.state.workingValue}
      />
    );
  },
});

export default TextareaControlled;
