import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import moment from 'moment';

import TimeAgo from './time_ago';
import TimestampFormatter from './lib/timestamp_formatter';

/**
 * Renders a time DOM element in 1 of 3 different formats based on age
 * - before current year
 * - between start of year and 7 days ago
 * - between 7 days ago and now
 * Requires one attribute:
 * @attribute timestamp - ISO8601 string
 *
 * Example
 * ```
 *  <RelativeDateTime timestamp={new Date().toISOString()} />
 *```
 */

const RelativeDateTime = createReactClass({
  displayName: 'RelativeDateTime',

  render() {
    if (
      moment()
        .subtract(7, 'days')
        .isBefore(this.props.timestamp)
    ) {
      return (
        <TimeAgo
          className={classnames(this.props.className)}
          shortened={this.props.shortened}
          timestamp={this.props.timestamp}
          title={this.props.title}
        />
      );
    }
    return (
      <TimeElement
        className={classnames(this.props.className)}
        timestamp={this.props.timestamp}
        title={this.props.title}
      />
    );
  },
});

const TimeElement = createReactClass({
  displayName: 'TimeElement',

  render() {
    let tf = new TimestampFormatter(this.props.timestamp);
    let fullTime = tf.full();
    let shortTime = tf.relative();
    const title = this.props.title !== undefined ? this.props.title : fullTime;

    return (
      <time className={classnames(this.props.className)} dateTime={this.props.timestamp} title={title}>
        {shortTime}
      </time>
    );
  },
});

export default RelativeDateTime;
