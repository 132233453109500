import PropTypes from 'prop-types';
import React from 'react';

import ConversationItem from 'models/conversation_item';

import CampaignOutreachResultMetadata from '../metadata/campaign_outreach_result_metadata';
import ItemContent from '../item_content';

export default function VoiceCampaignOutreachResultItem({ item }) {
  return (
    <ItemContent isAuditItem item={item}>
      <CampaignOutreachResultMetadata item={item} />
    </ItemContent>
  );
}

VoiceCampaignOutreachResultItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
