import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import AITextRewriteControls from 'components/text_editor_new/components/controls/ai_text_rewrite_controls';
import { AITextCompletionContextProvider } from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons';
import { AnswerPanelControl, ControlsWrapper, Separator } from 'components/composer/shared/editor_controls_new';
import { EditorContainer } from 'components/composer/shared/editor_new';
import EditorErrors from 'components/composer/shared/editor_errors';
import { useCreatePasteAttachmentsPlugin } from 'components/text_editor_new/plugins/create_paste_attachments_plugin';

import ComposerContext from 'components/composer/contexts/composer_context';
import CharacterCount from 'components/text_editor_new/character_count';
import Composer, { Header, NewHeaderText } from 'components/composer/shared/composer_new';
import ComposerEditor from 'components/composer/shared/composer_editor';
import connect from 'components/lib/connect';
import Endpoint from 'models/endpoint';
import FromField from 'components/composer/custom_channel/from_field';
import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';
import SendCustomChannelMessage from 'actions/composer/send_custom_channel_message';
import ToField from 'components/composer/custom_channel/to_field';
import MessageGroupContainer from './custom_channel/message_group_container';
import useAIComposerOptions from 'components/text_editor_new/plugins/ai/ai_text_completion/use_ai_composer_options';
import useComposer from 'components/composer/shared/use_composer';
import useEditor from 'components/composer/shared/use_editor';
import useMessagingEditorPlugins from 'components/text_editor_new/hooks/use_messaging_editor_plugins';
import withRefreshEditorWhenFlagsChange from 'components/composer/shared/with_refresh_editor_when_flags_change';
import withStaticId from 'components/customer/lib/with_static_id';

export function CustomChannelComposerBase({ customChannels, customerAddresses, companyAddresses, includeAIFeatures }) {
  const { composition } = useContext(ComposerContext);
  const customChannel = _.find(customChannels, { id: composition?.content?.customChannelId });
  const areAttachmentsEnabled = customChannel?.allowAttachments;

  const composerProps = useComposer({ action: SendCustomChannelMessage });
  const editorProps = useEditor('bodyHtml', composerProps, { attr: 'body', serializer: serializePlaintext });
  const { disableSubmit, readOnly } = useAIComposerOptions();
  editorProps.onSubmit = disableSubmit ? _.noop : editorProps.onSubmit;

  const extraPlugins = useExtraPlugins(areAttachmentsEnabled);
  const editorRef = useMessagingEditorPlugins(composerProps, editorProps, extraPlugins);

  const allCustomerAddresses = _.filter(
    customerAddresses,
    recipient =>
      recipient.content?.customChannelId && recipient.content?.customChannelId === composition?.content?.customChannelId
  );

  const allCompanyAddresses = _.filter(
    companyAddresses,
    endpoint =>
      endpoint.configuration?.customChannelId &&
      endpoint.configuration?.customChannelId === composition?.content?.customChannelId
  );

  const channelName = composition.content?.channelDisplayTitle;
  const isMessageGroupingEnabled = customChannel?.messageGroupingEnabled;

  return (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      disableSubmit={disableSubmit}
      excludeErrors
      includeAttachments={areAttachmentsEnabled}
      initialHtml={editorProps.initialHtml}
      submitText="Send"
      {...composerProps}
    >
      <Header>
        <Left>
          <NewHeaderText>{_.startCase(_.toLower(channelName))}</NewHeaderText>
          <ToField allFromAddresses={allCustomerAddresses} disabled={isMessageGroupingEnabled} />
        </Left>
        <StyledNonGroupingControlsWrapper>
          <FromField allFromAddresses={allCompanyAddresses} disabled={isMessageGroupingEnabled} />
        </StyledNonGroupingControlsWrapper>
      </Header>
      {isMessageGroupingEnabled && (
        <MessageGroupContainer>
          <Left>
            <GroupName>{composition.content.groupName}</GroupName>
          </Left>
        </MessageGroupContainer>
      )}
      <EditorContainer excludeAnswerMenuButton includeAttachments>
        <ComposerEditor
          key={composition.id}
          placeholder="Type a message"
          readOnly={readOnly}
          {...editorProps}
          onSubmit={editorProps.onSubmit}
        />
      </EditorContainer>

      <MessagingActions>
        <ActionBar>
          <AnswerPanelControl />
          <Separator />
          <AITextRewriteControls />
          <Emoji {...editorProps} />
          {areAttachmentsEnabled && <Attachment alwaysShow data-aid="attachmentsButton" />}
        </ActionBar>
        <StyledCharacterCount composerRef={composerProps.composerRef} />
      </MessagingActions>

      <EditorErrors />
    </Composer>
  );
}

const StyledCharacterCount = styled(CharacterCount)`
  background-color: inherit;
  position: relative;
`;
const MessagingActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

function useExtraPlugins(areAttachmentsEnabled) {
  const createPasteAttachmentsPlugin = useCreatePasteAttachmentsPlugin();
  return useMemo(() => {
    return areAttachmentsEnabled ? [createPasteAttachmentsPlugin()] : [];
  }, [createPasteAttachmentsPlugin, areAttachmentsEnabled]);
}

const GroupName = styled.span`
  font-weight: 500;
  margin-left: 4px;
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

const StyledNonGroupingControlsWrapper = styled(ControlsWrapper)`
  height: 24px;
  margin-left: 4px;
`;

function CustomChannelComposerWithAIFeatures(props) {
  const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);

  const includeAIFeatures = !agentAssistanceConfig.disableHeroAI;

  let composer = <CustomChannelComposerBase {...props} includeAIFeatures={includeAIFeatures} />;

  if (includeAIFeatures) {
    return (
      <AITextCompletionContextProvider requestorId={props.requestorId}>{composer}</AITextCompletionContextProvider>
    );
  }
  return composer;
}

export const CustomChannelComposer = connect(mapStateToProps)(
  withStaticId(withRefreshEditorWhenFlagsChange(CustomChannelComposerWithAIFeatures), 'requestorId')
);

const CustomChannelComposerConnected = connect(mapStateToProps)(CustomChannelComposer);
export default CustomChannelComposerConnected;

function mapStateToProps({ getProvider }) {
  const profile = getProvider('profile').get();
  const channelConfiguration = getProvider('channelConfiguration').get();

  const customChannels = getProvider('customChannels').findAll();

  return {
    customChannels,
    customerAddresses: profile ? profile.getExternalCustomerAddressForType(Endpoint.Type.CUSTOM_CHANNEL) : [],
    companyAddresses: channelConfiguration.getEndpointsOfType(Endpoint.Type.CUSTOM_CHANNEL),
  };
}
