import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Attachment from 'models/attachment';
import connect from 'components/lib/connect';
import LoadingSpinner from 'components/lib/loading_spinner';
import Upload from 'models/upload';

class SlateInlineImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loadingError: false };
    _.bindAll(this, ['renderCompleted', 'renderLoading', 'renderFailed']);
  }

  renderCompleted() {
    const className = classnames(this.props.className, 'slate-inlineImage', {
      'slate-inlineImage-focused': this.props.isFocused,
    });

    // Display custom error icon if we have it, otherwise fall back on the browser provided one
    if (this.state.loadingError && this.props.loadingErrorIcon) {
      return <>{this.props.loadingErrorIcon}</>;
    }

    return (
      <StyledSlateInlineImage
        alt={this.props.alt}
        className={className}
        data-aid={this.props['data-aid']}
        isFocused={this.props.isFocused}
        onError={() => {
          this.setState({ loadingError: true });
        }}
        onLoad={() => {
          this.setState({ loadingError: false });
        }}
        src={this.props.src}
      />
    );
  }

  renderLoading() {
    return (
      <StyledSlateInlineImageLoading className="slate-inlineImage-loading">
        <LoadingSpinner />
      </StyledSlateInlineImageLoading>
    );
  }

  renderFailed() {
    return null;
  }

  render() {
    const attachment = this.props.attachment;
    // !attachment to handle any imported images that are hosted elsewhere
    if (!attachment || attachment instanceof Attachment) {
      return this.renderCompleted();
    }

    switch (attachment.status) {
      case Upload.Status.NEW:
      case Upload.Status.STARTED:
        return this.renderLoading();

      case Upload.Status.FAILED:
        return this.renderFailed();

      case Upload.Status.COMPLETED:
      default:
        return this.renderCompleted();
    }
  }
}

const focusedImage = css`
  border: 1px solid ${p => p.theme.colors.green400};
`;

export const StyledSlateInlineImage = styled.img`
  cursor: default;
  display: inline-block;
  max-width: 100%;
  ${p => p.isFocused && focusedImage};
`;

export const StyledSlateInlineImageLoading = styled.div`
  background-color: ${p => p.theme.colors.gray100};
  border: 1px solid ${p => p.theme.colors.green400};
  cursor: default;
  direction: ltr;
  height: 200px;
  max-width: 100%;
  position: relative;
  width: 300px;

  & .loadingSpinner {
    height: 50px;
    width: 50px;
  }
`;

SlateInlineImage.propTypes = {
  alt: PropTypes.string,
  attachment: PropTypes.oneOfType([PropTypes.instanceOf(Attachment), PropTypes.instanceOf(Upload)]),
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  isFocused: PropTypes.bool,
  loadingErrorIcon: PropTypes.node,
  src: PropTypes.string,
};

// Container

const mapStateToProps = (context, { attachmentId }) => {
  const snippetCompositionProvider = context.getProvider('snippetComposition');

  if (attachmentId) {
    const snippet = snippetCompositionProvider.get();
    let attachment = snippet && snippet.findAttachmentById(attachmentId);
    if (!attachment) {
      let compositionsProvider;
      try {
        compositionsProvider = context.getProvider('compositions');
      } catch (e) {
        return {};
      }
      if (compositionsProvider) {
        const compositions = compositionsProvider.findAll();
        for (let i = 0; i < compositions.length; i++) {
          attachment = compositions[i].findAttachmentById(attachmentId);
          if (attachment) {
            break;
          }
        }
      }
    }
    return { attachment };
  }

  return {};
};

export { SlateInlineImage };
export default connect(mapStateToProps)(SlateInlineImage);
