import ApiToken from 'models/api_token';
import ErrConverter from 'scripts/application/dto_converters/err_converter';
import qconsole from 'scripts/lib/qconsole';

export default class ApiTokenGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddSuccess(userId, apiToken) {
    if (!this.isCurrentAgent(userId)) {
      qconsole.log(`discarding API token for user [${userId}] while current agent is [${this.currentAgentId}]`);
      return;
    }

    let pendingApiToken = this.store.getPendingNew();
    if (!pendingApiToken || pendingApiToken.id !== apiToken.id) {
      qconsole.log(
        `Discarding API token [${apiToken.id}] while expecting API token [${pendingApiToken && pendingApiToken.id}]`
      );
      return;
    }

    this._addApiToken(apiToken);
    this.store.resetPendingNew();
  }

  handleAddErrors(userId, { errors }) {
    if (!this.isCurrentAgent(userId)) {
      qconsole.log(`ignoring API token errors for [${userId}] while current agent is [${this.currentAgentId}]`);
      return;
    }

    this.store.resetPendingNew();
    // just log the error without presenting it to the agent, they would be able to create a new API token regardless
    qconsole.log(`Could not create API token for user [${userId}]: ${JSON.stringify(errors)}`);
  }

  handleDelete(userId, apiTokenId) {
    if (!this.isCurrentAgent(userId)) {
      qconsole.log(`ignoring API token delete for user [${userId}] while current agent is [${this.currentAgentId}]`);
      return;
    }

    this.store.remove(apiTokenId);
  }

  handleEntity(userId, apiToken) {
    if (!this.isCurrentAgent(userId)) {
      qconsole.log(`discarding API token for user [${userId}] while current agent is [${this.currentAgentId}]`);
      return;
    }

    let existingApiToken = this.store.findBy({ id: apiToken.id });
    if (existingApiToken) {
      let newApiToken = existingApiToken.token ? { ...apiToken, token: existingApiToken.token } : apiToken;
      this.store.replace(ApiToken.fromJs(newApiToken));
    } else {
      let pendingNewToken = this.store.getPendingNew();
      if (!pendingNewToken || pendingNewToken.id !== apiToken.id) {
        this._addApiToken(apiToken);
      }
    }
  }

  handleFetchResponse(userId, apiTokens) {
    if (!this.isCurrentAgent(userId)) {
      qconsole.log(`ignoring API tokens for [${userId}] while current agent is [${this.currentAgentId}]`);
      return;
    }

    this.store.set((apiTokens && apiTokens.map(apiToken => ApiToken.fromJs(apiToken))) || []);
    this.store.resetLoading();
  }

  handleFetchErrors(userId, errors) {
    qconsole.log(`Could not fetch API tokens for user [${userId}]: ${JSON.stringify(errors)}`);
  }

  handleUpdateErrors(userId, apiTokenId, { errors }) {
    if (!this.isCurrentAgent(userId)) {
      qconsole.log(`ignoring API token errors for [${userId}] while current agent is [${this.currentAgentId}]`);
      return;
    }

    this.store.resetPending(apiTokenId);
    this.store.setErrors(apiTokenId, errors.map(ErrConverter.fromDto));
  }

  handleUpdateSuccess(userId, apiTokenId) {
    if (!this.isCurrentAgent(userId)) {
      qconsole.log(
        `discarding API Token update response for user [${userId}] while current agent is [${this.currentAgentId}]`
      );
      return;
    }

    this.store.commitPending(apiTokenId);
  }

  _addApiToken(apiToken) {
    this.store.set([ApiToken.fromJs(apiToken)].concat(this.store.findAll()));
  }

  isCurrentAgent(userId) {
    return this.currentAgentId === userId;
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }

  get store() {
    return this.context.stores.apiTokens;
  }
}
