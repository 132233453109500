import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/icons/icon';

export default props => (
  <StyledIcon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M2 3a1 1 0 000 2h12a1 1 0 100-2H2zm5 7a1 1 0 011-1h6a1 1 0 110 2H8a1 1 0 01-1-1zm-6 3a1 1 0 011-1h12a1 1 0 110 2H2a1 1 0 01-1-1zm6-6a1 1 0 011-1h6a1 1 0 110 2H8a1 1 0 01-1-1zm-3.146-.146a.5.5 0 10-.708-.708l-2 2a.5.5 0 000 .708l2 2a.5.5 0 00.708-.708L2.707 9H5.5a.5.5 0 000-1H2.707l1.147-1.146z"
      fill="#242424"
      fillRule="evenodd"
    />
  </StyledIcon>
);

const StyledIcon = styled(Icon)`
  margin-top: -2px;
`;
