import { useMemo } from 'react';

import deserializeHtml from 'components/text_editor_new/lib/deserialize_html';
import sanitize from 'components/text_editor_new/lib/sanitize_html';

export default function useInitialValue(html, editor) {
  // Order of operations:
  //   1. If we have existing composition content, load that.
  //   2. If we have a pending answer to insert, load that.
  //   3. Otherwise, load a blank composer.
  return useMemo(() => {
    if (html) {
      const sanitizedHtml = sanitize(html);
      const parsedDocument = new DOMParser().parseFromString(sanitizedHtml, 'text/html');
      return deserializeHtml(parsedDocument.body, editor);
    }

    // TODO: figure out why deserializing doesn't give a hoot about the root node
    // May need to change the root node serializer to have a `data-root` property or something
    return [
      {
        type: 'p',
        children: [{ text: '' }],
      },
    ];
  }, [editor]);
}
