import _ from 'lodash';
import moment from 'moment';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import ServerClock from 'scripts/application/lib/server_clock';
import { SnippetChannel } from 'models/answers/snippet';

export const AnswerPanelSort = createEnum('RELEVANCE', 'FREQUENCY');

export const DirectAnswerLink = createModel({
  modelName: 'DirectAnswerLink',
  properties: {
    type: prop().oneOf(..._.keys(SnippetChannel)).isRequired,
    id: prop(String).isRequired,
    timestamp: prop(String).isRequired,
  },
  statics: {
    create(attrs) {
      return new this({ timestamp: ServerClock.toISOString(), ...attrs });
    },
  },
});

export default createModel({
  modelName: 'AnswerPanelState',
  properties: {
    isAnswerPanelOpen: prop(Boolean).isRequired,
    lastOpenedAt: prop(String),
    history: prop([String]).default([]),
    sort: prop(String).default(AnswerPanelSort.FREQUENCY),
    directLink: prop(DirectAnswerLink),
  },

  setDirectLink(link) {
    this.directLink = link;
  },

  openAnswerPanel() {
    this.isAnswerPanelOpen = true;
  },

  navigateForward(answerId) {
    if (_.last(this.history) !== answerId) {
      this.history.push(answerId);
    } else {
      this.history = [answerId];
    }
  },

  navigateBack() {
    if (this.history.length > 0) {
      this.history.pop();
    }
  },

  clearHistory() {
    this.history = [];
  },

  changeSort(sort) {
    this.sort = sort;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    createOpenPanel(attrs) {
      return this.create({
        ...attrs,
        isAnswerPanelOpen: true,
        lastOpenedAt: moment().toISOString(),
      });
    },
  },
});
