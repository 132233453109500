import { Factory } from 'rosie';
import moment from 'moment';

Factory.define('Auth');

Factory.define('AuthWithDefaults')
  .extend('Auth')
  .attr('claims', () => Factory.attributes('ClaimsWithDefaults'));

Factory.define('Claims');

Factory.define('ClaimsWithDefaults')
  .attr('activatedAt', () => new Date().toISOString())
  .attr('orgId', 'exampleOrgId')
  .attr('roleIds', ['agent'])
  .attr('userId', 'exampleUserId')
  .attr('iat', () => moment().unix())
  .attr('ssoEnabled', false)
  .attr('saganUser', '')
  .attr('exp', () =>
    moment()
      .add(1, 'day')
      .unix()
  );
