import addCustomerToInbox from 'actions/inbox/lib/add_customer_to_inbox';
import addTaskToInbox from 'actions/inbox/lib/add_task_to_inbox';
import AgentAssignment from 'models/agent_assignment';
import RequestInboxItem from 'actions/inbox/agent/request_inbox_item';
import { unsubscribe } from 'actions/inbox/agent/request_agent_inbox';

export default class AgentAssignmentGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  isEnabled() {
    const appFeatures = this.context.stores.appFeatures.get();
    return appFeatures.isEnabled('customerListAssignmentBroadcast');
  }

  onBroadcast(assignmentDto) {
    if (!this.isEnabled()) {
      return;
    }

    const assignment = AgentAssignment.fromJs(assignmentDto);
    const existingItem = this.context.stores.agentInboxItemsNew.findBy({ id: assignment.id });

    if (assignment.type === 'CUSTOMER') {
      addCustomerToInbox(this.context, { customerId: assignment.id, store: this.context.stores.agentInboxItemsNew });
    } else {
      addTaskToInbox(this.context, assignment.customerId, assignment.id, this.context.stores.agentInboxItemsNew);
    }

    // If we have an uninitialized item in the store, that means we received a broadcast for that customer
    // (like a conversation broadcast) before we finished fetching the item from an assignment. This is a relatively
    // common occurance, and we still need to fetch in order to fully saturate the item.
    if (!existingItem || !existingItem.hasFetchedAtLeastOnce) {
      this.context.executeAction(RequestInboxItem, { id: assignment.id, type: assignment.type });
    }
  }

  onBroadcastDelete(assignmentDto) {
    if (!this.isEnabled()) {
      return;
    }

    const assignment = AgentAssignment.fromJs(assignmentDto);
    this.context.stores.agentInboxItemsNew.remove(assignment.id);
    unsubscribe(this.context.backend, assignment.id);
  }
}
