import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import createEnum from 'scripts/lib/create_enum';
import { formatPhoneNumber } from 'models/phone_number';
import MessageContentTypeFile from './message_content_type_file';
import MessageContentTypeImage from './message_content_type_image';
import MessageContentTypeLocation from './message_content_type_location';
import MessageContentTypeText from './message_content_type_text';
import messageContentFromJs, { typeReflect } from './message_content_from_js';
import Reaction from './reaction';

export const MessageChannelType = createEnum('INSTAGRAM_DIRECT', 'TWITTER', 'WHATSAPP');
const MessageContentTypes = [
  MessageContentTypeFile,
  MessageContentTypeImage,
  MessageContentTypeLocation,
  MessageContentTypeText,
];
export const MessageStatus = createEnum(
  'DELIVERED',
  'FAILED',
  'NEW',
  'READ',
  'RECEIVED',
  'SENDING',
  'SENT',
  'UNDELIVERED',
  'UNKNOWN'
);

const ConversationMessage = createModel({
  modelName: 'ConversationMessage',
  properties: {
    channel: prop().oneOf(..._.values(MessageChannelType)).isRequired,
    companyAddress: prop(String),
    content: prop()
      .oneOf(...MessageContentTypes)
      .fromJs(messageContentFromJs),
    customerAddress: prop(String),
    externalId: prop(String),
    meta: prop(Object).default({}),
    reactions: prop([Reaction]).default([]),
    status: prop({
      value: String,
      error: String,
    }),
    statusUpdates: prop(Object).default([]),
    isRedacted: prop(Boolean),
  },

  update(attrs) {
    _.assign(this, _.omit(attrs, 'id'));
  },

  contentType() {
    return typeReflect.instanceToType(this.content);
  },

  formatCompanyAddress() {
    switch (this.channel) {
      case MessageChannelType.WHATSAPP:
        return formatPhoneNumber(this.companyAddress);

      case MessageChannelType.TWITTER:
      case MessageChannelType.INSTAGRAM_DIRECT:
        return this.companyAddress;

      default:
        return this.companyAddress;
    }
  },

  formatCustomerAddress() {
    switch (this.channel) {
      case MessageChannelType.WHATSAPP:
        return formatPhoneNumber(this.customerAddress);

      case MessageChannelType.TWITTER:
        return this.meta.displayName;

      case MessageChannelType.INSTAGRAM_DIRECT:
      default:
        return this.customerAddress;
    }
  },

  getAttachments() {
    switch (this.content.constructor) {
      case MessageContentTypeFile:
        return this.content.files;

      case MessageContentTypeImage:
        return this.content.images;

      case MessageContentTypeLocation:
      case MessageContentTypeText:
      default:
        return [];
    }
  },

  getBody() {
    return this.content.getBody();
  },

  getChannelName() {
    switch (this.channel) {
      case MessageChannelType.WHATSAPP:
        return 'WhatsApp';

      case MessageChannelType.TWITTER:
        return 'Twitter DM';

      case MessageChannelType.INSTAGRAM_DIRECT:
        return 'Instagram Messaging';

      default:
        return '';
    }
  },

  getMessageText() {
    return this.getBody();
  },

  isVideoMessage() {
    return this.getStoryMentionType() === 'mp4' || this.getVideoAttachments().length === 1;
  },

  isImageStoryMentionMessage() {
    return this.getStoryMentionType() === 'jpg';
  },

  getVideoAttachments() {
    if (!this.content || !this.content.files) {
      return [];
    }
    return this.content.files.filter(f => f.contentType.indexOf('video') >= 0);
  },

  getStoryMentionUrl() {
    return this.meta['storyMentionUrl'];
  },

  getStoryMentionType() {
    return this.meta['storyMentionType'];
  },

  hasReaction() {
    return this.reactions && this.reactions.length > 0;
  },

  getChannelType() {
    return this.channel;
  },

  getFormattedBody() {
    return this.content.bodyHtml;
  },

  getStatus() {
    return (this.status && this.status.value) || '';
  },

  hasErrorStatus() {
    let status = this.getStatus();
    return status === MessageStatus.FAILED || status === MessageStatus.UNDELIVERED;
  },

  getErrorCode() {
    return (this.status && this.status.error) || '';
  },

  getErrorMessage() {
    let errorCode = this.getErrorCode();
    switch (errorCode) {
      case ErrorCodes.NO_ACTIVE_CONVERSATION:
        return 'Could not send message because conversation was closed.';

      case ErrorCodes.REENGAGEMENT:
        switch (this.channel) {
          case MessageChannelType.WHATSAPP:
            return `Could not send message after 24h have passed since customer's last message`;
          default:
            return 'Could not send message outside of reengagement window';
        }

      case ErrorCodes.INSTAGRAM_MESSAGING_STORY_NOT_EXIST:
        return `Unable to send a reaction. The story is no longer available.`;

      case ErrorCodes.INSTAGRAM_MESSAGING_OUTSIDE_WINDOW:
        return `Unable to send message. The response window has been exceeded.`;

      default:
        return '';
    }
  },

  redactAttachment(attachmentId) {
    switch (this.content.constructor) {
      case MessageContentTypeFile:
      case MessageContentTypeImage:
        this.content.redactAttachment(attachmentId);
    }
  },

  hasRedactedPaymentCardNumber() {
    switch (this.content.constructor) {
      case MessageContentTypeText:
      case MessageContentTypeFile:
      case MessageContentTypeImage:
        return this.content.hasRedactedPaymentCardNumber;
      case MessageContentTypeLocation:
      default:
        return false;
    }
  },

  overrideToJs(toJs) {
    return () => {
      let obj = toJs();
      if (obj.content) {
        obj.content.type = this.contentType();
      }
      return obj;
    };
  },

  statics: {
    // SMS character limit is 1600.
    // Chat character limit is 2048.
    // Twitter DM character limit is 10000.
    // WhatsApp character limit is 1600.
    // For now, use smaller generic max character limit of 2048 to align with Chat and prevent issues rendering large messages.
    MAX_CONVERSATION_MESSAGE_SIZE: 2048,
    MAX_INSTAGRAM_MESSAGE_SIZE: 640,
    MAX_WHATSAPP_MESSAGE_SIZE: 1600,
    create(attrs) {
      return new this(attrs);
    },
  },
});

export function hasErrorStatus(status) {
  return [MessageStatus.FAILED, MessageStatus.UNDELIVERED].indexOf(status) >= 0;
}

export const ErrorCodes = {
  NO_ACTIVE_CONVERSATION: 'NO_ACTIVE_CONVERSATION',
  REENGAGEMENT: 'REENGAGEMENT',
  INSTAGRAM_MESSAGING_STORY_NOT_EXIST: 'instagram story no longer exists',
  INSTAGRAM_MESSAGING_OUTSIDE_WINDOW: 'not_in_window',
};

export default ConversationMessage;
