import ActivityObserver from 'actions/user/activity_observer';

export default function configureActivityObserver(context) {
  configureActivities(new ActivityObserver(context));
}

export function configureActivities(observer) {
  onActivity(observer.handleActivity.bind(observer));
  onFocus(observer.handleFocus.bind(observer));
}

function onActivity(callback) {
  ['mousemove', 'wheel', 'keydown', 'click', 'focus'].forEach(name => {
    document.addEventListener(name, callback, { passive: true });
  });
}

function onFocus(callback) {
  document.addEventListener('focus', callback);
}
