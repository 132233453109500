import React from 'react';

export default function ZeroStateIcon() {
  return (
    <svg
      className="reportZeroState-icon"
      fill="none"
      height="56"
      viewBox="0 0 56 56"
      width="56"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" fill="#F9F9F9" r="28" />
      <path
        clipRule="evenodd"
        d="M16 13C16 12.4477 16.4477 12 17 12H31C31.5523 12 32 12.4477 32 13V19C32 19.5523 32.4477 20 33 20H39C39.5523 20 40 20.4477 40 21V43C40 43.5523 39.5523 44 39 44H17C16.4477 44 16 43.5523 16 43V13ZM21 26C21 25.4477 21.4477 25 22 25H34C34.5523 25 35 25.4477 35 26C35 26.5523 34.5523 27 34 27H22C21.4477 27 21 26.5523 21 26ZM22 31C21.4477 31 21 31.4477 21 32C21 32.5523 21.4477 33 22 33H34C34.5523 33 35 32.5523 35 32C35 31.4477 34.5523 31 34 31H22ZM21 38C21 37.4477 21.4477 37 22 37H34C34.5523 37 35 37.4477 35 38C35 38.5523 34.5523 39 34 39H22C21.4477 39 21 38.5523 21 38ZM34 12.9657V17.6C34 17.8209 34.1791 18 34.4 18H39.0343C39.3907 18 39.5691 17.5691 39.3172 17.3172L34.6828 12.6828C34.4309 12.4309 34 12.6093 34 12.9657Z"
        fill="#009B00"
        fillRule="evenodd"
      />
    </svg>
  );
}
