import PhoneCall from 'models/phone_call';

export default class HoldPhoneCall {
  constructor(context) {
    this.context = context;
  }

  run({ participantId, state }) {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      const updatedStatus = state ? PhoneCall.ParticipantStatus.HOLD_REQUESTED : PhoneCall.ParticipantStatus.ACTIVE;
      activeCall.conversationItem.content.setParticipantStatus(participantId, updatedStatus);
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.hold(activeCall.customer.id, activeCall.conversationItem.id, {
        participantId,
        state,
      });
    }
  }
}
