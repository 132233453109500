import _ from 'lodash';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';

import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar';
import { faMoneyCheckAlt } from '@fortawesome/pro-solid-svg-icons/faMoneyCheckAlt';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons/faRepeat';
import { faTruck } from '@fortawesome/pro-solid-svg-icons/faTruck';

import connect from 'components/lib/connect';
import Link from 'components/common/link';
import SummaryCard, { SummaryHeader } from 'components/customer/summary/lib/summary_card';
import Tooltip from 'components/common/tooltip';

function CustomerEventList({ currentConversationTopicIds, customAttributes, def, showEventsList, type }) {
  if (!def || !showEventsList) {
    return null;
  }

  let events = _.get(customAttributes, type);
  if (_.isEmpty(events)) {
    return null;
  }

  let filteredEvents = omitActivationTopics(currentConversationTopicIds, events);

  if (_.isEmpty(filteredEvents)) {
    return null;
  }

  return (
    <SummaryCard>
      {def.label && <SummaryHeader text={`${def.label}`} />}
      <div className="customEvents-listWrapper">
        <div className={classnames('customEvents-list', _.kebabCase(type))}>{filteredEvents.map(renderEvent)}</div>
      </div>
    </SummaryCard>
  );

  function renderEvent(event, i) {
    let header = _.get(event, 'header');
    let headerDef = _.get(def, 'header');
    let items = _.get(event, 'items');
    let filteredItems = omitActivationTopics(currentConversationTopicIds, items);
    if (_.isEmpty(filteredItems)) {
      return null;
    }
    let columnFourUrl = _.get(headerDef, 'columnFour.url');
    let columnFourDetail = header[_.get(headerDef, 'columnFour.attr')];

    return (
      <div className="customEvents-row">
        <Tooltip
          className="customEvents-header-icon-tooltip"
          message={header[_.get(headerDef, 'iconLabel.attr')]}
          position="top"
        >
          <FontAwesomeIcon
            className="customEvents-header-icon"
            icon={renderIcon(header[_.get(headerDef, 'icon.attr')])}
          />
        </Tooltip>
        <div className="customEvents-group">
          <div className="customEvents-header" key={i}>
            <div className="customEvents-header-label">
              <b>{header[_.get(headerDef, 'columnOne.attr')]}</b>
            </div>
            <div className="customEvents-header-label">{header[_.get(headerDef, 'columnTwo.attr')]}</div>
            <div className="customEvents-header-label">{header[_.get(headerDef, 'columnThree.attr')]}</div>
            <div className="customEvents-header-label">
              {columnFourUrl ? renderLink(header[columnFourUrl], columnFourDetail) : columnFourDetail}
            </div>
          </div>
          <div className="customEvents-divider" />
          {filteredItems.map(renderItem)}
        </div>
      </div>
    );
  }

  function renderLink(url, name) {
    return (
      <Link href={url} onClick={() => window.open(url, '_blank')}>
        {name}
      </Link>
    );
  }

  function renderIcon(iconType) {
    switch (iconType) {
      case 'building':
        return faBuilding;
      case 'check':
        return faMoneyCheckAlt;
      case 'car':
        return faCar;
      case 'repeat':
        return faRepeat;
      default:
        return faTruck;
    }
  }

  function renderItem(item, i) {
    let rowDef = _.get(def, 'row');
    let titleUrl = _.get(rowDef, 'title.url');
    let titleName = item[_.get(rowDef, 'title.attr')];
    let descriptionOneUrl = _.get(rowDef, 'descriptionOne.url');
    let descriptionOneDetail = item[_.get(rowDef, 'descriptionOne.attr')];
    let descriptionTwoUrl = _.get(rowDef, 'descriptionTwo.url');
    let descriptionTwoDetail = item[_.get(rowDef, 'descriptionTwo.attr')];
    let descriptionThreeUrl = _.get(rowDef, 'descriptionThree.url');
    let descriptionThreeDetail = item[_.get(rowDef, 'descriptionThree.attr')];
    let descriptionFourUrl = _.get(rowDef, 'descriptionFour.url');
    let descriptionFourDetail = item[_.get(rowDef, 'descriptionFour.attr')];
    let renderButtons = _.get(rowDef, 'buttonOne') || _.get(rowDef, 'buttonTwo');
    let buttonOneActive = item[_.get(rowDef, 'buttonOne.active')];
    let buttonOneLabel = _.get(rowDef, 'buttonOne.label');
    let buttonTwoActive = item[_.get(rowDef, 'buttonTwo.active')];
    let buttonTwoLabel = _.get(rowDef, 'buttonTwo.label');

    return (
      <div>
        <div className={classnames('customEvents-event', 'customEvents-event-withImage')} key={i}>
          <div className="customEvents-event-content">
            <div>
              <div className="customEvents-event-title">
                {titleUrl ? renderLink(item[titleUrl], titleName) : <b>{titleName}</b>}
              </div>
            </div>
            <div className="customEvents-event-details">
              <div className="customEvents-event-details-row">
                <div className="customEvents-event-label">{_.get(rowDef, 'descriptionOne.label')}</div>
                <div className="customEvents-event-detail">
                  {descriptionOneUrl ? renderLink(item[descriptionOneUrl], descriptionOneDetail) : descriptionOneDetail}
                </div>
              </div>
              <div className="customEvents-event-details-row">
                <div className="customEvents-event-label">{_.get(rowDef, 'descriptionTwo.label')}</div>
                <div className="customEvents-event-detail">
                  {descriptionTwoUrl ? renderLink(item[descriptionTwoUrl], descriptionTwoDetail) : descriptionTwoDetail}
                </div>
              </div>
              <div className="customEvents-event-details-row">
                <div className="customEvents-event-label">{_.get(rowDef, 'descriptionThree.label')}</div>
                <div className="customEvents-event-detail">
                  {descriptionThreeUrl
                    ? renderLink(item[descriptionThreeUrl], descriptionThreeDetail)
                    : descriptionThreeDetail}
                </div>
              </div>
              {descriptionFourDetail ? (
                <div className="customEvents-event-details-row">
                  <div className="customEvents-event-label">{_.get(rowDef, 'descriptionFour.label')}</div>
                  <div className="customEvents-event-detail">
                    {descriptionFourUrl
                      ? renderLink(item[descriptionFourUrl], descriptionFourDetail)
                      : descriptionFourDetail}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="customEvents-event-image-wrapper">
            <img className="customEvents-event-image" src={item[_.get(rowDef, 'image.attr')]} />
          </div>
        </div>
        <div className="customEvents-divider" />
        <div className="customEvents-event-actionButton-wrapper">
          {_.get(rowDef, 'buttonOne') ? (
            <button
              className={classnames({
                'customEvents-event-actionButton': buttonOneActive,
                'customEvents-event-actionButton-inactive': !buttonOneActive,
              })}
            >
              {buttonOneLabel}
            </button>
          ) : null}
          {_.get(rowDef, 'buttonTwo') ? (
            <button
              className={classnames({
                'customEvents-event-actionButton': buttonTwoActive,
                'customEvents-event-actionButton-inactive': !buttonTwoActive,
              })}
            >
              {buttonTwoLabel}
            </button>
          ) : null}
        </div>
        {renderButtons ? <div className="customEvents-divider" /> : null}
      </div>
    );
  }
}

function omitActivationTopics(currentConversationTopicIds, list) {
  return _.filter(list, l => {
    return !l.activationTopicId || _.includes(currentConversationTopicIds, l.activationTopicId);
  });
}

CustomerEventList.propTypes = {
  customAttributes: PropTypes.object.isRequired,
  def: PropTypes.object,
  showEventsList: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export { CustomerEventList };

const CustomerEventListContainer = connect(mapStateToProps)(CustomerEventList);

function mapStateToProps({ getProvider, isFeatureEnabled }, { type }) {
  let currentConversation = getProvider('conversations').findBy({
    id: getProvider('currentLocation').get().currentConversationId,
  });
  let transactionsProvider = getProvider('transactions');

  return {
    currentConversationTopicIds: _(currentConversation).get('topicIds', []),
    customAttributes: _.get(getProvider('profile').get(), 'customAttributes') || {},
    def: _.get(getProvider('customerProfileDef').get(), type),
    showEventsList:
      isFeatureEnabled('demoMode') && !transactionsProvider.isLoading() && transactionsProvider.count() === 0,
    type,
  };
}

CustomerEventListContainer.propTypes = {
  currentConversationTopicIds: PropTypes.arrayOf(PropTypes.string),
  customAttributes: PropTypes.object,
  def: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default CustomerEventListContainer;
