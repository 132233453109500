import ErrorReporter from 'scripts/infrastructure/error_reporter';
import FetchCommentsAndFollowersForTask from 'actions/task/fetch_comments_and_followers_for_task';

export default class TaskGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddSuccess(payload) {
    let taskInfo = this.context.stores.taskInfo.getPendingNew();
    if (taskInfo) {
      this.context.stores.taskInfo.commitPendingNew();
      this.context.executeAction(FetchCommentsAndFollowersForTask, { taskId: taskInfo.id });
    }
  }

  handleAddErrors(errorsDto) {
    ErrorReporter.reportError(new Error('could not add task'), {
      extra: { payload: JSON.stringify(errorsDto, null, 2) },
    });
  }

  handleUpdateSuccess(entityId, payload) {}

  handleUpdateErrors(taskId, errorsDto) {
    ErrorReporter.reportError(new Error('could not update task'), {
      extra: { taskId, payload: JSON.stringify(errorsDto, null, 2) },
    });
  }
}
