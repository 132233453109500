import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import T from 'prop-types';

import ActionMixin from 'components/lib/action_mixin';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import Link from 'components/common/link';
import NavigateTo from 'actions/current_location/navigate_to';

const AgentHomeNavList = createReactClass({
  mixins: [ActionMixin],

  propTypes: {
    items: T.arrayOf(
      T.shape({
        count: T.number,
        name: T.string,
        isActive: T.bool,
        location: T.shape({
          getPath: T.func.required,
          getQueryParams: T.func.required,
        }),
      })
    ).isRequired,
  },

  render() {
    let alphabetizedGroups = _.sortBy(this.props.items, item => item.name.toLowerCase());

    return <ul className="agentHomeNavList">{alphabetizedGroups.map(this.renderItem)}</ul>;
  },

  renderItem(item) {
    let url = getLocationUrl(item.location);
    let key = url.replace(/\W/g, '-');
    return (
      <li className={classnames('agentHomeNavList-item', { active: item.isActive })} key={key}>
        <Link className="agentHomeNavList-item-text" href={url} onClick={this.onLinkClick.bind(this, item.location)}>
          <span className="agentHomeNavList-item-name">{item.name}</span>
          <span className="agentHomeNavList-item-count">{item.count || '0'}</span>
        </Link>
      </li>
    );
  },

  onLinkClick(location) {
    this.executeAction(NavigateTo, location);
  },
});

export default AgentHomeNavList;
