import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';

import T from 'prop-types';

/**
 * @deprecated Please use /components/common/tooltip.jsx instead
 */

const Tooltip = createReactClass({
  propTypes: {
    enabled: T.bool,
    message: T.string.isRequired,
    position: T.string,
    multiline: T.bool,
    delayed: T.bool,

    children: T.element.isRequired,
  },

  getDefaultProps() {
    return {
      enabled: true,
      position: 'top',
      multiline: true,
    };
  },

  getInitialState() {
    return {
      showTooltip: false,
    };
  },

  render() {
    const child = this.props.children;

    const childClasses = classnames(child.props.className, `simptip-position-${this.props.position}`, {
      'tooltip-active': this.state.showTooltip,
      'simptip-multiline': this.props.multiline,
      'tooltip-delayed': this.props.delayed,
    });

    return React.cloneElement(child, {
      className: childClasses,
      'data-tooltip': this.props.enabled ? this.props.message : undefined,
    });
  },
});

export default Tooltip;
