export default class FakeS3 {
  constructor() {
    this.uploads = {};
  }

  post(url, form, onReceive) {
    this.uploads[form.get('key')] = form;
    onReceive({ status: 204, statusText: 'No content' });
  }

  createGetUrl(path) {
    let upload = this.uploads[path];
    return upload && URL.createObjectURL(upload.get('file'));
  }

  remove(path) {
    delete this.uploads[path];
  }
}
