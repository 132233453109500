import React, { useContext } from 'react';
import InstagramFillIcon from 'components/common/icons/fill/instagram-fill';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function InstagramDirectRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.instagramDirect;
  const instagramDirectSla = sla.instagramDirect;
  return (
    <FloorboardRow
      activeItems={stats.currentOpenMessages}
      activeLabel="Open Messages"
      currentWait={stats.currentWait}
      icon={<InstagramFillIcon className="iconContainer" />}
      newItems={stats.currentNewMessages}
      newLabel="New Messages"
      sla={instagramDirectSla}
      waitLabel="Oldest Unanswered Message"
    />
  );
}
