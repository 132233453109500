import _ from 'lodash';

import LogActivity from 'actions/agent_activity/log_activity';
import ServerClock from 'scripts/application/lib/server_clock';
import UpdatePresence from 'actions/agent_activity/update_presence';

const ACTIVITY_INTERVAL_MS = 500;
const PRESENCE_INTERVAL_MS = 1 * 60 * 1000;
const SERVER_INFO_CHECK_INTERVAL_MS = 10 * 1000;

export default class ActivityObserver {
  constructor(context) {
    this.context = context;
    this.sendPresenceUpdate = _.throttle(this.sendPresenceUpdate, PRESENCE_INTERVAL_MS);
    this.checkServerInfo = _.throttle(this.checkServerInfo, SERVER_INFO_CHECK_INTERVAL_MS);
    this.handleActivity = _.throttle(this.handleActivity, ACTIVITY_INTERVAL_MS);
  }

  handleActivity() {
    if (this.isConnected() && this.isLoggedIn()) {
      this.sendPresenceUpdate();
    }
    this.checkServerInfo();
  }

  handleFocus() {
    if (this.isConnected() && this.isLoggedIn()) {
      this.logLastActivity();
    }
  }

  logLastActivity() {
    this.context.executeAction(LogActivity, { withNewId: true });
  }

  sendPresenceUpdate() {
    this.context.executeAction(UpdatePresence);
  }

  checkServerInfo() {
    this.context.gateways.serverInfo.fetch({ t: ServerClock.toISOString() });
  }

  isConnected() {
    return !this.context.stores.connectionState.get().isDisconnected();
  }

  isLoggedIn() {
    return this.context.stores.auth.get().isLoggedIn();
  }
}
