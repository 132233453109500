import CustomerMergeConverter from 'scripts/application/dto_converters/customer_merge_converter';
import CustomerProfile from 'models/customer_profile';
import {
  subscribeToActiveCall,
  unsubscribeFromActiveCall,
  updateActiveCallCustomer,
} from 'actions/phone_call/lib/active_call';

export default class ActiveCallCustomerHistoryMergeObserver {
  constructor(context) {
    this.context = context;
  }

  handleConversationHistoryMerge(mergeDto) {
    let conversationHistoryMerge = CustomerMergeConverter.fromDto(mergeDto);
    let activeCall = this.context.stores.activeCall.get();
    if (!activeCall || activeCall.customer.id !== conversationHistoryMerge.sourceCustomerId) {
      return;
    }

    if (conversationHistoryMerge.destConversationId) {
      activeCall.updateConversationId(conversationHistoryMerge.destConversationId);
      this.context.stores.activeCall.set(activeCall);
    }

    if (activeCall.customer.id === conversationHistoryMerge.destCustomerId) {
      return;
    }

    this.handleCustomerMerge(conversationHistoryMerge);
  }

  handleCustomerMerge(customerMerge) {
    let activeCall = this.context.stores.activeCall.get();

    unsubscribeFromActiveCall(this.context);
    subscribeToActiveCall(this.context, { customerId: customerMerge.destCustomerId });

    let destProfile =
      this.getLoadedProfile(customerMerge.destCustomerId) ||
      CustomerProfile.create({ ...activeCall.customer, id: customerMerge.destCustomerId });

    updateActiveCallCustomer(this.context, destProfile);
  }

  getLoadedProfile(id) {
    return this.context.stores.customers.has({ id }) && this.context.stores.customers.storesFor(id).profile.get();
  }
}
