import includes from 'lodash/includes';

const ALLOWED_ELEMENTS = [
  'a',
  'div',
  'p',
  'pre',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'font',
  'ul',
  'li',
  'ol',
  'span',
  'br',

  // Style elements
  'b',
  'u',
  'i',

  // Defunct / unsupported elements
  's',
];

export default function createWordFallbackRules(context) {
  return [
    {
      deserialize(el, next) {
        const elementIsText = isTextElement(el);
        if (
          !context.ignore &&
          ((el.tagName && includes(ALLOWED_ELEMENTS, el.tagName.toLowerCase())) || elementIsText)
        ) {
          if (elementIsText) {
            return {
              object: 'text',
              leaves: [
                {
                  text: el.textContent || '\n' || undefined,
                },
              ],
            };
          }
          return next();
        }

        return null;
      },
    },
  ];
}

function isTextElement(el) {
  return el.nodeType === 3 && hasValidTextParent(el);
}

function hasValidTextParent(el) {
  return (
    el.parentElement && el.parentElement.tagName && includes(ALLOWED_ELEMENTS, el.parentElement.tagName.toLowerCase())
  );
}
