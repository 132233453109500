import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import RecipientRow from './recipient_row';
import SetRecipients from 'actions/composer/email/set_recipients';
import useRerender from 'components/hooks/use_rerender';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function Recipients() {
  const { composition } = useContext(ComposerContext);

  const [isOpen, setIsOpen] = useState(false);

  const tos = getList(composition.content.to);
  const ccs = getList(composition.content.cc);
  const bccs = getList(composition.content.bcc);

  if (!isOpen) {
    return <RecipientsSummary bccs={bccs} ccs={ccs} onClick={() => setIsOpen(true)} tos={tos} />;
  }

  return <RecipientsEditor initialBccs={bccs} initialCcs={ccs} initialTos={tos} setIsOpen={setIsOpen} />;
}

export function RecipientsEditor({ initialTos, initialCcs, initialBccs, setIsOpen }) {
  const [recipientsRef, setTos, setCcs, setBccs] = useRecipients({ initialTos, initialCcs, initialBccs });
  const { tos, bccs, ccs } = recipientsRef.current;

  const onBlurRecipients = useOnBlurRecipients(setIsOpen, recipientsRef);
  const onClose = useCloseOnEscape(setIsOpen, recipientsRef);

  return (
    <StyledRecipients data-aid="recipients-editor" onBlur={onBlurRecipients} onKeyDown={onClose} tabIndex="-1">
      <RecipientRow autoFocus data-aid="tos-row" label="To" onChange={setTos} values={tos} />
      <RecipientRow data-aid="ccs-row" label="Cc" onChange={setCcs} values={ccs} />
      <RecipientRow data-aid="bccs-row" label="Bcc" onChange={setBccs} values={bccs} />
    </StyledRecipients>
  );
}

RecipientsEditor.propTypes = {
  initialTos: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialCcs: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialBccs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const StyledRecipients = styled.div`
  flex-grow: 1;
  outline: none;
`;

/**
 * useRecipients() returns a ref containing the current recipients plus setters for each. It's a bit wonky because
 * it uses the ref to store state rather than actual React state, but the reason for this: if an agent is typing
 * a recipient and then immediately blurs off, the `setTos` function will fire, but so will the onBlur callback,
 * which will effectively "miss" that state update. So if we set on the ref instead, and read from the ref, we
 * won't miss the state update. All we need to do then, is when setting recipient values on the ref, to also
 * force a re-render of the component (which we do using a setState function).
 */
function useRecipients({ initialTos, initialCcs, initialBccs }) {
  const recipientsRef = useRef({ tos: initialTos, ccs: initialCcs, bccs: initialBccs });
  const rerender = useRerender();

  const setTos = useCallback(val => {
    recipientsRef.current.tos = val;
    rerender();
  }, []);
  const setCcs = useCallback(val => {
    recipientsRef.current.ccs = val;
    rerender();
  }, []);
  const setBccs = useCallback(val => {
    recipientsRef.current.bccs = val;
    rerender();
  }, []);

  return [recipientsRef, setTos, setCcs, setBccs];
}

function useOnBlurRecipients(setIsOpen, recipientsRef) {
  const { composition } = useContext(ComposerContext);
  const compositionId = composition.id;
  const executeAction = useExecuteAction();
  return useCallback(
    evt => {
      const currentTarget = evt.currentTarget;
      // If we've blurred out of the whole thing, then close the recipients window
      setTimeout(function() {
        if (!currentTarget.contains(document.activeElement)) {
          setIsOpen(false);
        }
      }, 0);
      executeAction(SetRecipients, { compositionId, recipients: recipientsRef.current });
    },
    [compositionId, executeAction, recipientsRef, setIsOpen]
  );
}

function useCloseOnEscape(setIsOpen, recipientsRef) {
  const { composition } = useContext(ComposerContext);
  const compositionId = composition.id;
  const executeAction = useExecuteAction();
  return useCallback(
    evt => {
      if (evt.key === 'Escape') {
        setIsOpen(false);
        executeAction(SetRecipients, { compositionId, recipients: recipientsRef.current });
      }
    },
    [compositionId, executeAction, recipientsRef, setIsOpen]
  );
}

export function RecipientsSummary({ tos, ccs, bccs, onClick }) {
  return (
    <StyledSummary data-aid="recipients-summary" onClick={onClick}>
      <ToWrapper>
        <Label>To</Label>
        <ToList data-aid="slate-email-recipients">{tos.join(', ')}</ToList>
      </ToWrapper>
      {ccs.length ? (
        <Count>
          <Label>Cc</Label>
          <Highlight>{recipientCount(ccs)}</Highlight>
        </Count>
      ) : null}
      {bccs.length ? (
        <Count>
          <Label>Bcc</Label>
          <Highlight>{recipientCount(bccs)}</Highlight>
        </Count>
      ) : null}
    </StyledSummary>
  );
}

RecipientsSummary.propTypes = {
  tos: PropTypes.arrayOf(PropTypes.string).isRequired,
  ccs: PropTypes.arrayOf(PropTypes.string).isRequired,
  bccs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
};

const StyledSummary = styled.div`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;
const ToWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: 0;
`;
const Label = styled.div`
  color: ${p => p.theme.colors.gray600};
  margin-right: 8px;
`;
const Highlight = styled.span`
  color: ${p => p.theme.colors.green400};
`;
const ToList = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Count = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: 8px;
`;

function recipientCount(recipients) {
  return recipients.length > 1 ? `${recipients.length} recipients` : `${recipients.length} recipient`;
}

function getList(recipientStr) {
  if (!recipientStr) {
    return [];
  }
  return recipientStr.split(',').map(recip => recip.trim());
}
