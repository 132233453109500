'use strict';

import _ from 'lodash';

export function validateEnum(msg, type, types) {
  if ( !(type in types) ) {
    throw new Error(`Failed to set ${msg} because ${type} is not in ${Object.keys(types)}`);
  }
  return type;
}

export function resequenceIds(collection = []) {
  return collection.map( (attrs, index) => {
    return _.merge(
      {},
      { id: `${index + 1}` }, // override default id generator so each item's ID reset at 1
      attrs
    );
  });
}
