import createModel, { prop } from '../lib/create_model';
import NotificationType from './notification_type';
import { SessionCustomer } from '../active_session';

export default createModel({
  modelName: 'InboundCommunicationSessionNotification',

  properties: {
    id: prop(String),
    messagePreview: prop(String),
    profile: prop(SessionCustomer),
    sessionType: prop(String),
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.INBOUND_COMMUNICATION_SESSION });
  },

  updateMessagePreview(messagePreview) {
    this.messagePreview = messagePreview;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
