import createModel from './lib/create_model';

const Transaction = createModel({
  modelName: 'Transaction',

  properties: {
    id: String,
    type: String,
    attributes: Object,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Transaction;
