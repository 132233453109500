import StartAnswerSearch from './start_answer_search';

export default class SetSearchText {
  constructor(context) {
    this.context = context;
  }

  run({ text }) {
    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.setSearchText(text);
    this.context.stores.answerPanel.set(answerPanel);

    this.context.executeAction(StartAnswerSearch);
  }
}
