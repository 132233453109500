import CreateComposition from './lib/create_composition';
import CreditCardCompositionContent from 'models/composition/credit_card_composition_content';

export default class CreateCreditCardRequestComposition extends CreateComposition {
  constructor(context) {
    super(context, CreditCardCompositionContent);
  }

  run() {
    super.run(...arguments);
  }
}
