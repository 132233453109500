import { useContext, useEffect, useRef } from 'react';
import { ReactEditor } from 'slate-react';
import { Editor, Transforms } from 'slate';
import { usePlateEditorRef } from '@udecode/plate';

import ClearSelectedSuggestedReply from 'actions/answers/clear_selected_suggested_reply';
import ComposerContext from 'components/composer/contexts/composer_context';
import SetSuggestedReplyOnComposition from 'actions/composition/set_suggested_reply_on_composition';
import connect from 'components/lib/connect';
import deserializeHtml from 'components/text_editor_new/lib/deserialize_html';
import focusEndOfEditor from 'components/text_editor_new/lib/focus_end_of_editor';

export function insertSuggestedReply(editor, html) {
  ReactEditor.focus(editor);
  if (!editor.selection) {
    const entry = Editor.first(editor, []);
    Transforms.select(editor, Editor.range(editor, entry[1]));
  }

  if (!editor.selection) {
    focusEndOfEditor(editor);
  }

  const fragment = deserializeHtml(html, editor);
  Transforms.insertFragment(editor, fragment);
}

export function useSuggestedReplies({
  clearSelectedSuggestedReply,
  selectedSuggestedReply,
  setSuggestedReplyOnComposition,
}) {
  const { composition } = useContext(ComposerContext);
  const editor = usePlateEditorRef();
  const prevSuggestedReply = useRef(null);
  useEffect(() => {
    if (!editor || !composition) {
      return;
    }
    if (selectedSuggestedReply !== prevSuggestedReply) {
      prevSuggestedReply.current = selectedSuggestedReply;

      if (selectedSuggestedReply) {
        const html = `<div>${selectedSuggestedReply.item.text}</div>`;
        insertSuggestedReply(editor, html);
        setSuggestedReplyOnComposition({
          currentCompositionId: composition.id,
          suggestedReply: selectedSuggestedReply.item,
        });
        clearSelectedSuggestedReply();
      }
    }
  }, [editor, clearSelectedSuggestedReply, selectedSuggestedReply, setSuggestedReplyOnComposition]);
}

function UseInsertSelectedSuggestedReply({
  selectedSuggestedReply,
  clearSelectedSuggestedReply,
  setSuggestedReplyOnComposition,
}) {
  useSuggestedReplies({ selectedSuggestedReply, clearSelectedSuggestedReply, setSuggestedReplyOnComposition });
  return null;
}

function mapStateToProps({ getProvider }) {
  const selectedSuggestedReply = getProvider('selectedSuggestedReply').get();
  return {
    selectedSuggestedReply,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    clearSelectedSuggestedReply: () => executeAction(ClearSelectedSuggestedReply),
    setSuggestedReplyOnComposition: attrs => executeAction(SetSuggestedReplyOnComposition, attrs),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(UseInsertSelectedSuggestedReply);
