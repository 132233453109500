import createEnum from 'scripts/lib/create_enum';
import React from 'react';

export const markRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'strong') {
        return { object: 'mark', type: 'bold', nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'bold') {
        return <strong>{children}</strong>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'i') {
        return { object: 'mark', type: 'italic', nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'italic') {
        return <i>{children}</i>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'u') {
        return { object: 'mark', type: 'underline', nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'underline') {
        return <u>{children}</u>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'font') {
        const fontSize = el.size;
        const fontColor = el.color;
        return { object: 'mark', type: 'font', nodes: next(el.childNodes), data: { fontSize, fontColor } };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'font') {
        const fontSize = object.data.get('fontSize');
        const fontColor = object.data.get('fontColor');
        return (
          <font color={fontColor} size={fontSize}>
            {children}
          </font>
        );
      }
    },
  },
];

export const markPlugin = {
  renderMark: (props, editor, next) => {
    const { children, mark, attributes } = props;
    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'italic':
        return <i {...attributes}>{children}</i>;
      case 'underline':
        return <u {...attributes}>{children}</u>;
      case 'font': {
        const fontSize = mark.get('data').get('fontSize');
        const fontColor = mark.get('data').get('fontColor');
        return (
          <font {...attributes} color={fontColor} size={fontSize}>
            {children}
          </font>
        );
      }
      default:
        return next();
    }
  },
};

export const FONT_COLORS = {
  blue: '#2650b2',
  green: '#009b00',
  orange: '#ff7d00',
  red: '#d2210b',
  gray: '#999999',
  black: '#000000',
};

export const FONT_SIZES = createEnum('SMALL', 'NORMAL', 'LARGE', 'HUGE');
