import KbAdmin from 'models/location/kb_admin';

export default class NavigateToKbAdmin {
  constructor(context) {
    this.context = context;
  }

  run(snippetId) {
    let kbAdmin = KbAdmin.create();
    this.context.router.navigateTo(kbAdmin);
  }
}
