/**
 * Returns a sparse array of errors for the specified multi value attribute such as `emails` or `phones`.
 *
 * @param errors - all model errors, where multi-value errors are specified as `modelAttr.index.fieldAttr`, e.g.
 *                 `emails.0.original`
 * @param attrName - the name of model's multi-value attribute, e.g. `emails`
 * @returns [] - a sparse array of errors where the index in the array corresponds to the index portion of the model
 *               error, e.g. `emails.2.original` becomes retVal[2].original
 */
export default function(errors, attrName) {
  if (!errors) {
    return null;
  }

  let fieldErrors = errors.filter(error => {
    return error.attr ? error.attr.indexOf(`${attrName}.`) !== -1 : false;
  });

  let multiValueErrors = [];
  fieldErrors.forEach(error => {
    let errorMatch = multiValueErrorRegExp.exec(error.attr);
    if (!errorMatch) {
      return;
    }

    let index = errorMatch[1];
    let valueErrors = multiValueErrors[index] || {};
    let attr = errorMatch[2];
    if (!valueErrors[attr]) {
      valueErrors[attr] = error;
    }
    multiValueErrors[index] = valueErrors;
  });

  return multiValueErrors;
}

const multiValueErrorRegExp = /.+\.(\d+)\.(.+)/; // e.g. `emails.0.original` => '0', 'original'
