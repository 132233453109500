import React from 'react';

import AgentNotification from 'models/agent_notification';
import connect from 'components/lib/connect';
import { Item, Text, Timestamp } from './shared_notification';
import MarkAgentNotificationAsRead from 'actions/agent_notifications/mark_agent_notification_as_read';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function TaskCommented({ agent, notification }) {
  const executeAction = useExecuteAction();

  const onClick = () => {
    executeAction(MarkAgentNotificationAsRead, { id: notification.id });
    executeAction(NavigateToItem, { customerId: notification.content.customerId, itemId: notification.content.taskId });
  };

  const objectText = getObjectText(notification);
  return (
    <Item onClick={onClick}>
      <Text hasRead={notification.hasRead()}>
        {agent ? agent.name : 'An agent'} commented on a task {objectText}
      </Text>
      <Timestamp timestamp={notification.createdAt} />
    </Item>
  );
}

function getObjectText(notification) {
  if (notification.type === AgentNotification.Type.FOLLOWED_TASK_COMMENTED) {
    return 'you follow';
  } else if (notification.type === AgentNotification.Type.ASSIGNED_TASK_COMMENTED) {
    return 'assigned to you';
  }
  return '';
}

const TaskCommentedContainer = connect(mapStateToProps)(TaskCommented);

function mapStateToProps({ getProvider }, { notification }) {
  return {
    agent: getProvider('agents').findBy({ id: notification.initiator.id }),
  };
}

export default TaskCommentedContainer;
