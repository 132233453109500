import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import './interaction_strings'; // interaction names
import InteractionType, { baseInteractionType, customId } from 'models/interaction_type';
import ItemContent from '../item_content';
import Metadata from 'components/customer/conversation_history/conversation_items_v2/metadata/metadata';
import SessionEnded, { SessionEndReason } from 'models/session_ended';
import './session_ended_item_strings'; // text templates for all session end phrases

export function SessionEndedItem({ channelName, currentAgentId, initiatorName, item }) {
  if (!isSupportedContent(item.content)) {
    return null;
  }

  return (
    <ItemContent isAuditItem item={item}>
      <Metadata
        text={
          <SessionEndMessage
            channelName={channelName}
            content={item.content}
            currentAgentId={currentAgentId}
            initiator={{ id: item.initiator?.id, name: initiatorName }}
          />
        }
        timestamp={item.timestamp}
      />
    </ItemContent>
  );
}

function SessionEndMessage({ channelName, content, currentAgentId, initiator }) {
  const { i18n, t } = useTranslation();

  return (
    <Trans
      components={{ agent: <Highlight /> }}
      defaults="Communication ended"
      i18nKey={buildMessageKey()}
      values={{
        agent: initiator.name || t('genericAgent'),
        count: content.timeout,
        interaction: t(buildInteractionKey(), { channelName }),
      }}
    />
  );

  function buildMessageKey() {
    const rootKey = `sessionEndedItem.${content.reason}`;
    const initiatorKey = initiator.id === currentAgentId ? 'you' : 'agent';
    const compoundKey = `${rootKey}.${initiatorKey}`;

    return i18n.exists(compoundKey) ? compoundKey : rootKey;
  }

  function buildInteractionKey() {
    const selectorKey =
      content.reason === SessionEndReason.CUSTOMER_NO_REPLY ? 'interactionMessage' : 'interactionSession';

    const interactionType = baseInteractionType(content.sessionType);
    if (interactionType === InteractionType.CUSTOM_CHANNEL && !channelName) {
      return `${selectorKey}._DEFAULT`;
    }

    const interactionKey = `${selectorKey}.${interactionType}`;

    return i18n.exists(interactionKey) ? interactionKey : `${selectorKey}._DEFAULT`;
  }
}

SessionEndMessage.propTypes = {
  channelName: PropTypes.string,
  content: PropTypes.instanceOf(SessionEnded).isRequired,
  currentAgentId: PropTypes.string.isRequired,
  initiator: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

function isSupportedContent(content) {
  return includes(supportedInteractionsByEndReason[content.reason], baseInteractionType(content.sessionType));
}

const supportedInteractionTypes = Object.freeze([
  InteractionType.CHAT,
  InteractionType.CUSTOM_CHANNEL,
  InteractionType.FB_MESSENGER,
  InteractionType.INSTAGRAM_DIRECT,
  InteractionType.SMS,
  InteractionType.WHATSAPP,
]);

const supportedInteractionsByEndReason = Object.freeze({
  [SessionEndReason.AGENT_ENDED]: supportedInteractionTypes,
  [SessionEndReason.CUSTOMER_NO_REPLY]: supportedInteractionTypes,
  [SessionEndReason.STARTED_NEW]: Object.freeze([InteractionType.CHAT]),
  [SessionEndReason.TIME_EXPIRED]: supportedInteractionTypes,
});

const Highlight = styled.span.attrs(() => ({ 'data-aid': 'highlight' }))`
  font-weight: bold;
`;

SessionEndedItem.propTypes = {
  channelName: PropTypes.string,
  currentAgentId: PropTypes.string.isRequired,
  initiatorName: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function mapStateToProps({ getProvider }, { item }) {
  let agents = getProvider('agents');
  let currentAgentId = getProvider('currentAgent').get().id;
  let customChannels = getProvider('customChannels');

  return {
    channelName: getCustomChannelName(item.content.sessionType),
    currentAgentId,
    initiatorName: getInitiatorName(item.initiator),
    item,
  };

  function getCustomChannelName(sessionType) {
    return (
      (baseInteractionType(sessionType) === InteractionType.CUSTOM_CHANNEL &&
        customChannels.findBy({ id: customId(sessionType) })?.name) ||
      null
    );
  }

  function getInitiatorName(initiator) {
    return (initiator?.type === Communicator.AGENT && agents.findBy({ id: initiator.id })?.name) || null;
  }
}

const SessionEndedItemContainer = connect(mapStateToProps)(SessionEndedItem);

SessionEndedItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default SessionEndedItemContainer;
