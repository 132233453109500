import React from 'react';
import _ from 'lodash';

import { AttributePresentation } from 'models/configuration/attribute_def';
import connect from 'components/lib/connect';
import CustomAttribute from '../custom_attribute';
import CustomAttributesSubgroup from '../custom_attributes/custom_attributes_subgroup';
import ProfileCardType from 'models/configuration/profile_card_type';
import qconsole from 'scripts/lib/qconsole';
import UpdateCustomAttributes from 'actions/customer_profile/update_custom_attributes';

export function getAttributeDefsToShow(profileCardDef, customerProfileDef, customAttributes) {
  if (!profileCardDef || !customerProfileDef) {
    return [];
  }

  // from the profileCardDef, get the relevant attribute defs to show
  let attrDefsForCardChain;
  if (profileCardDef.type === ProfileCardType.BASIC_PROFILE && !profileCardDef.attrs) {
    // for the BASIC_INFO card *only*, absense of attrs equates to showing all attrs
    // this helps us w/ the default/0-config case
    attrDefsForCardChain = _(customerProfileDef.attributes);
  } else {
    let attributeDefsMap = _.keyBy(customerProfileDef.attributes, 'attr');

    attrDefsForCardChain = _(profileCardDef.attrs)
      .map(attrToRender => {
        return attributeDefsMap[attrToRender];
      })
      .filter(attrDef => {
        return !_.isEmpty(attrDef);
      });
  }

  // We don't want to render HIDDEN attributes, and we only want to render VISIBLE attributes
  // if they have a value. But we always render EDITABLE attributes (in case an agent wants
  // to populate the value from scratch)
  return attrDefsForCardChain
    .filter(
      attrDef =>
        attrDef.presentation === AttributePresentation.EDITABLE ||
        (attrDef.presentation === AttributePresentation.VISIBLE && customAttributes && customAttributes[attrDef.attr])
    )
    .value();
}

export function getPercentAttrAsNumber(percentAttr) {
  if (!percentAttr) {
    return;
  }

  // if there's a trailing % sign, trim that off before proceeding
  const lastCharacter = percentAttr.charAt(percentAttr.length - 1);
  if (lastCharacter === '%') {
    percentAttr = percentAttr.slice(0, -1);
  }

  return Number(percentAttr);
}

export function renderCustomAttributes(renderableAttributes, customAttributes, location) {
  const renderProps = {};
  // find all PERCENT attributes to be rendered in this group and find the highest maximum NUMBER value for them
  _.each(renderableAttributes, attrDef => {
    if (attrDef.type !== 'PERCENT') return;
    if (customAttributes && _.has(customAttributes, attrDef.attr)) {
      try {
        // Make sure we don't try co convert values that are clearly not numeric
        const value = _.get(customAttributes, attrDef.attr);
        if (_.isNil(value) || (!_.isString(value) && !_.isNumber(value))) return;

        let asNumber = getPercentAttrAsNumber(_.trim(value));
        if (!Number.isNaN(asNumber)) {
          if (renderProps.highWaterMark === undefined) {
            renderProps.highWaterMark = asNumber;
          } else if (asNumber > renderProps.highWaterMark) {
            renderProps.highWaterMark = asNumber;
          }
        }
      } catch (err) {
        // We came across a wrong value, report error and skip it
        return qconsole.error(
          `renderCustomAttributes: wrong value [${JSON.stringify(
            _.get(customAttributes, attrDef.attr)
          )}] for attribute '${attrDef.attr}'`
        );
      }
    }
  });

  return _.map(renderableAttributes, (attrDef, index) => {
    const attributeValue = _.get(customAttributes, attrDef.attr, null);
    return (
      <CustomAttribute
        def={attrDef}
        key={`customAttribute-${attrDef.attr}-${index}`}
        location={{ type: location }}
        renderProps={renderProps}
        value={attributeValue}
      />
    );
  });
}

export function renderCustomAttributesSubgroup(profileCardDef, customerProfileDef, customAttributes, location) {
  if (!profileCardDef || !customerProfileDef) {
    return [];
  }

  return _.map(profileCardDef.subgroups, (subgroupDef, index) => {
    return (
      <CustomAttributesSubgroup
        customAttributes={customAttributes}
        customerProfileDef={customerProfileDef}
        key={`customAttributesSubgroup-${subgroupDef.label}-${index}`}
        location={location}
        subgroupDef={subgroupDef}
      />
    );
  });
}

export function countSubgroupCustomAttributes(profileCardDef, customerProfileDef, customAttributes) {
  if (!profileCardDef || !customerProfileDef) {
    return 0;
  }

  return _.reduce(
    profileCardDef.subgroups,
    (attrsThusFar, subgroupDef) => {
      const subgroupCustomAttrs = getAttributeDefsToShow(subgroupDef, customerProfileDef, customAttributes);
      return attrsThusFar + subgroupCustomAttrs.length;
    },
    0
  );
}

export function checkEditableAttributeValue(value, allowNumbers) {
  const verifyType = allowNumbers ? val => _.isString(val) || _.isNumber(val) : val => _.isString(val);

  const isNil = _.isNil(value) || _.isNaN(value);
  const isCorrectValue = !isNil && verifyType(value);
  const valueAsString = isCorrectValue ? _.trim(value) : '';
  const isError = !isNil && !isCorrectValue;

  return {
    isValid: !isError,
    asString: valueAsString,
  };
}

/**
 * Helper method that provides a standard "connect" implementation for all editable custom attributes
 *
 * @param {React.ComponentElement} EditableCustomAttr - The attribute to connect
 * @returns {React.ComponentElement}
 */
export function connectEditableCustomAttribute(EditableCustomAttr) {
  function mapStateToProps({ getProvider }) {
    const profileProvider = getProvider('profile');
    return {
      isDisabled: profileProvider.isLoading() || !_.isEmpty(profileProvider.getErrors()),
      isResponsePending: profileProvider.isPending(),
    };
  }

  function mapExecuteToProps(executeAction, { def }) {
    const getUpdates = value => _.set({}, def?.attr, _.isString(value) || _.isNumber(value) ? _.trim(value) : value);
    return {
      onSubmit: value => executeAction(UpdateCustomAttributes, getUpdates(value)),
    };
  }

  return connect(mapStateToProps, mapExecuteToProps)(EditableCustomAttr);
}
