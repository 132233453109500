import CloseModal from 'actions/modal/close_modal';
import connect from 'components/lib/connect';
import CreateConversation from 'actions/conversation/create_conversation';
import ReopenConversationModal from './reopen_conversation_modal';
import ReopenConversation from 'actions/conversation/reopen_conversation';

let mapExecuteToProps = function(executeAction, props) {
  function closeModal() {
    executeAction(CloseModal);
  }

  function createConversation() {
    closeModal();
    executeAction(CreateConversation);
  }

  function reopenConversation() {
    closeModal();
    executeAction(ReopenConversation);
  }

  return {
    onCancel: props.onCancel,
    onCreateNewConversationClick: createConversation,
    onReopenConversationClick: reopenConversation,
  };
};

export default connect(() => {}, mapExecuteToProps)(ReopenConversationModal);
