import { bindAll, get, trim } from 'lodash';

import { DomainError, GladlyError, RequestTimeout } from 'scripts/application/lib/error';
import analytics from 'scripts/lib/analytics';
import { AIConversationSummarizationErrorCode } from 'models/ai_conversation_summary/errors';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class AIConversationSummariesGatewayObserver {
  constructor(context) {
    this.context = context;
    bindAll(this, ['recordComposerSummaryErrorAnalytics', 'recordTimelineSummaryErrorAnalytics']);
  }

  onAddSuccess() {}

  onAddError(error, { conversationId }) {
    this.handleError(error, this.recordTimelineSummaryErrorAnalytics, conversationId);
  }

  onFetchSuccess() {}

  onFetchError(error, { conversationId }) {
    this.handleError(error, this.recordComposerSummaryErrorAnalytics, conversationId);
  }

  handleError(error, analyticsFn, conversationId) {
    const errorCode = this.parseErrorCode(error);

    switch (errorCode) {
      case `CONVERSATION.${AIConversationSummarizationErrorCode.NO_CUSTOMER_ITEMS}`:
        analyticsFn({ conversationId }, AIConversationSummarizationErrorCode.NO_CUSTOMER_ITEMS);
        return this.displayError(
          'Must have at least one message from the customer to create a summary.',
          ToastType.INFO
        );

      case `CONVERSATION.${AIConversationSummarizationErrorCode.WRONG_STATUS}`:
        analyticsFn({ conversationId }, AIConversationSummarizationErrorCode.WRONG_STATUS);
        return this.displayError(
          'Conversation summaries can only be created when the conversation is open.',
          ToastType.INFO
        );

      case `CONVERSATION.${AIConversationSummarizationErrorCode.TOO_LONG}`:
        analyticsFn({ conversationId }, AIConversationSummarizationErrorCode.TOO_LONG);
        return this.displayError(
          'The summary could not be created because there is too much text to summarize.',
          ToastType.INFO
        );

      case `CONVERSATION.${AIConversationSummarizationErrorCode.TOO_SHORT}`:
        analyticsFn({ conversationId }, AIConversationSummarizationErrorCode.TOO_SHORT);
        return this.displayError('More text is needed to provide a meaningful summary.', ToastType.INFO);

      default: {
        const meta = this.extractErrorMeta(error);
        const code =
          error instanceof RequestTimeout
            ? AIConversationSummarizationErrorCode.TIMEOUT
            : AIConversationSummarizationErrorCode.UNKNOWN;
        analyticsFn({ ...meta, conversationId }, code);

        return this.displayError('We cannot provide a summary at this time. Please try again later.');
      }
    }
  }

  displayError(message, type = ToastType.ERROR) {
    this.context.executeAction(ShowToast, { type, message });
  }

  extractErrorMeta(error) {
    if (error instanceof GladlyError) {
      const meta = {
        errorType: error.name,
        errorMessage: trim(get(error, 'cause.message') || error.message), // Falling back on standard `message` if `cause` is missing
      };

      // Try extracting "correlation ID" - it may or may not be there, depending on the error type
      const correlationId = get(error, 'cause.config.headers.Gladly-Correlation-Id');
      if (correlationId) meta.correlationId = correlationId;

      return meta;
    }

    // If we are here, the error is a plain vanilla Error that doesn't have much to work with
    return {
      errorMessage: trim(error.message),
    };
  }

  parseErrorCode(error) {
    let errorCode = '';
    if (error instanceof DomainError) {
      const code = get(error, 'errors.0.code') || '';
      const attr = get(error, 'errors.0.attr') || '';
      errorCode = `${attr}.${code}`;
    }
    return errorCode;
  }

  recordComposerSummaryErrorAnalytics(requestParams, errorCode) {
    analytics.track('Composer Conversation Summary Error Received', {
      ...requestParams,
      errorCode,
    });
  }

  recordTimelineSummaryErrorAnalytics(requestParams, errorCode) {
    analytics.track('Timeline Conversation Summary Error Received', {
      ...requestParams,
      errorCode,
    });
  }
}
