import _ from 'lodash';
import classnames from 'classnames';
import connect from 'components/lib/connect';
import { MultiSelectMenu } from 'components/lib/conversation_multi_topic_deprecated';
import NavigateTo from 'actions/current_location/navigate_to';
import Immutable from 'immutable';

export default connect(mapStateToProps, mapExecuteToProps)(MultiSelectMenu);

function mapStateToProps({ getProvider }, { className, optionIds }) {
  return {
    className: classnames('multipleSelectMenu', className),
    onFocus: _.noop,
    optionIds: optionIds || [],
    options: getTeamOptions(),
    placeholder: 'All Teams',
    unitLabelSingular: 'team',
    unitLabelPlural: 'teams',
  };

  function getTeamOptions() {
    let teams = _.map(getProvider('teams').findAll(), team => ({
      id: team.id,
      name: team.name,
    }));
    return Immutable.fromJS(teams);
  }
}

function mapExecuteToProps(executeAction, { currentLocation, optionIds, onApply }) {
  return {
    onOptionChange: handleOptionChange,
  };

  function handleOptionChange(updatedIds) {
    let currentIds = optionIds || [];
    if (updatedIds.length !== currentIds.length || _.difference(updatedIds, currentIds).length > 0) {
      if (onApply) {
        return onApply(updatedIds);
      } else {
        executeAction(NavigateTo, currentLocation.deriveNew({ teamIds: updatedIds }));
      }
    }
  }
}
