import createModel from './lib/create_model';

const AgentRead = createModel({
  modelName: 'AgentRead',

  properties: {
    conversationId: String,
    agentId: String,
    readTo: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default AgentRead;
