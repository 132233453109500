import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import DropdownMenu, { ChevronIcon, ButtonTextWrapper, DropdownPlaceholder } from 'components/common/dropdown_menu';
import SetSearchAudiences from 'actions/answers/set_search_audiences';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function AudienceSelector() {
  const { audiences, selectedAudiences } = useContext(AnswerPanelContext);
  const options = useOptions(audiences);
  const executeAction = useExecuteAction();

  if (!audiences.length) {
    return null;
  }

  function onSelect(value) {
    let audienceIds = [...selectedAudiences];
    const index = audienceIds.indexOf(value);
    if (index === -1) {
      audienceIds.push(value);
    } else {
      audienceIds.splice(index, 1);
    }
    executeAction(SetSearchAudiences, { audiences: audienceIds });
  }

  function useOptions(audiences) {
    return useMemo(
      () =>
        audiences
          .map(audience => ({ value: audience.id, label: audience.name }))
          .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
      [audiences]
    );
  }

  return (
    <React.Fragment>
      <StyledDropdown
        isMultiSelect
        onSelect={onSelect}
        openerComponent={OpenerComponent}
        options={options}
        placeholder="All audiences"
        searchPlaceholder="Search Audiences"
        searchable
        value={selectedAudiences}
      />
    </React.Fragment>
  );
}

const StyledDropdown = styled(DropdownMenu)`
  border: none;
  font-size: ${p => p.theme.fontSize.small};
  cursor: pointer;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
`;

const OpenerComponent = styled.div`
  font-size: ${p => p.theme.fontSize.small};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.green400};

    ${ChevronIcon} {
      stroke: ${p => p.theme.colors.green400};
      fill: ${p => p.theme.colors.green400};
    }
  }

  ${ButtonTextWrapper} {
    display: inline;
    width: 100%;
  }

  ${DropdownPlaceholder} {
    color: ${p => p.theme.colors.black};
    &:hover {
      color: ${p => p.theme.colors.green400};
    }
  }

  ${ChevronIcon} {
    width: 0.5em;
    height: 1em;
    vertical-align: -0.125em;
    font-size: inherit;
    margin-left: 1px;
    stroke: ${p => p.theme.colors.gray900};
    fill: ${p => p.theme.colors.gray900};

    &:hover {
      color: ${p => p.theme.colors.green400};
    }
  }
`;

export default AudienceSelector;
