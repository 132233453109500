import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import MissingImageIcon from 'components/common/icons/missing_image_icon';
import ProfileCardType from 'models/configuration/profile_card_type';
import ProductImage from 'components/customer/summary/transactions/product_image';
import { setOverflowTitle } from 'components/lib/overflow_title';
import { OrderTransactionDef, TransactionDefType } from 'models/configuration/transaction_def';
import VisibleCustomAttribute from 'components/customer/summary/custom_attributes/visible_custom_attribute';

export default function OrderProductAttributes({ product, shouldRenderImage, transactionDef }) {
  const productNameRef = useRef();

  const productImage = shouldRenderImage ? (
    <ProductImageWrapper>
      <ProductImage imageUrl={product.imageUrl || ''} placeholderImage={<ProductPlaceholderImage />} />
    </ProductImageWrapper>
  ) : null;

  useEffect(() => {
    setOverflowTitle(productNameRef, _.trim(_.get(product, 'name', '')));
  });

  return (
    <ProductAttributes className="productAttributes-wrapper">
      <ProductWrapper>
        {productImage}
        <ProductAttributeValues>
          <ProductNameWrapper extraMargin={shouldRenderImage} ref={productNameRef}>
            {product.name}
          </ProductNameWrapper>
          {_.map(transactionDef.attributes, def => {
            const key = `productAttributes-${def.attr}`;
            return (
              <VisibleCustomAttribute
                className="transactionAttribute"
                def={def}
                key={key}
                location={{ type: ProfileCardType.TRANSACTIONS, subType: TransactionDefType.ORDER }}
                value={product[def.attr]}
              />
            );
          })}
        </ProductAttributeValues>
      </ProductWrapper>
    </ProductAttributes>
  );
}

const ProductAttributes = styled.div`
  margin-top: 16px;
`;

const ProductAttributeValues = styled.div`
  width: 100%;
  overflow: hidden;
`;

const ProductImageWrapper = styled.div`
  margin-top: 4px;
`;

const ProductNameWrapper = styled.div`
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: ${p => (p.extraMargin ? '6px' : '2px')};
  overflow-wrap: anywhere;
`;

const ProductPlaceholderImage = styled(MissingImageIcon)`
  height: 21px;
  width: 24px;
`;

const ProductWrapper = styled.div`
  align-items: top;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 2px;
`;

OrderProductAttributes.propTypes = {
  product: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  shouldRenderImage: PropTypes.bool,
  transactionDef: PropTypes.instanceOf(OrderTransactionDef).isRequired,
};
