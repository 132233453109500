import { Factory } from 'rosie';

import '../conversation_item_factory';
import IdGenerator from '../lib/id_generator';

const customChannelId = IdGenerator.newId();

Factory.define('CustomChannelMessage').attr('type', 'CUSTOM_CHANNEL_MESSAGE');

Factory.define('CustomChannelMessageStatus').attr('value', 'READ');

Factory.define('CustomChannelMessageItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('CustomChannelMessage', content));

Factory.define('CustomChannelMessageWithDefaults')
  .extend('CustomChannelMessage')
  .attr('customChannelId', customChannelId)
  .attr('companyAddress', 'some company address')
  .attr('customerAddress', 'some customer address')
  .attr('messageType', 'TEXT')
  .attr('status', ['status'], status => Factory.attributes('CustomChannelMessageStatus', status))
  .attr('content', { text: 'hello' })
  .attr('sessionId', 'session-id');

Factory.define('CustomChannelMessageItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('CustomChannelMessageWithDefaults', content));
