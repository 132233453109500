import ConversationItem from 'models/conversation_item';
import PaymentStatusEventContent from 'models/payment_status_event_content';

export default function createPaymentStatusEventItem({ customerId, eventType, initiator, secureDataItemId }) {
  return ConversationItem.create({
    content: PaymentStatusEventContent.create({
      eventType,
      secureDataItemId,
    }),
    customerId,
    initiator,
  });
}
