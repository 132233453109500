import _ from 'lodash';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';
import PropTypes from 'prop-types';

import ActionMixin from 'components/lib/action_mixin';
import AppFeaturesMixin from 'components/lib/app_features_mixin';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import NavigateToCustomerProfile from 'actions/customer/navigate_to_customer_profile';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';
import UniversalSearchResults from 'components/search/universal_search_results';

const UniversalSearchResultsContainer = createReactClass({
  mixins: [ActionMixin, AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  propTypes: {
    filterHits: PropTypes.func,
    onSearchCancel: PropTypes.func,
    onSelectItem: PropTypes.func,
    queryId: PropTypes.string,
    shouldShowPagination: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      shouldShowPagination: true,
    };
  },

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('universalSearchResults'));
  },

  onSelectCustomer(customerId, conversationId) {
    if (this.props.onSelectItem) {
      this.props.onSelectItem(customerId, conversationId);
    } else {
      if (!conversationId) {
        this.executeAction(NavigateToCustomerProfile, { customerId });
      } else {
        this.executeAction(NavigateToConversation, { customerId, conversationId });
      }
      if (this.props.onSearchCancel) {
        this.props.onSearchCancel();
      }
    }
  },

  render() {
    const result = this.getProvider('universalSearchResults').findBy({ id: this.props.queryId });
    let hits = _.get(result, 'hits', null);

    let shouldShowSLASort = !!(_.get(result, 'query.filter.dueAt.gte') || _.get(result, 'query.filter.dueAt.lte'));

    if (this.props.filterHits) {
      hits = this.props.filterHits(hits);
    }

    return (
      <UniversalSearchResults
        data-aid="universalSearch-results"
        hits={hits}
        isLoading={result ? result.isLoading : false}
        onSelectCustomer={this.onSelectCustomer}
        queryId={this.props.queryId}
        shouldShowPagination={this.props.shouldShowPagination}
        shouldShowSLASort={shouldShowSLASort}
      />
    );
  },
});

export default UniversalSearchResultsContainer;
