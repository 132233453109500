import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttachmentModal from 'components/attachments/attachment_modal';
import AttachmentPreviewOpener from 'components/lib/attachment/attachment_preview_opener';
import AttachmentsDownloadAllContainer from 'components/lib/attachment/attachments_download_all_container';
import AttachmentStatus from 'models/attachment/attachment_status';
import GenericAttachment from 'components/lib/attachment/generic_attachment';
import { getSmsAttachmentUrl } from 'scripts/domain/services/attachments';
import { HandleHeightChange } from 'components/customer/conversation_history/virtualized/list_item';
import InlineImageAttachment from 'components/lib/attachment/inline_image_attachment';
import SmsMessageAttachment from 'models/sms_message_attachment';

const SmsMessageAttachmentsContainer = AttachmentPreviewOpener(SmsMessageAttachments);

function SmsMessageAttachments({ attachments, itemId, orgId }) {
  const [previewAttachment, setPreviewAttachment] = useState(null);
  if (_.isEmpty(attachments)) {
    return null;
  }

  let [inlineImageAttachments, genericAttachments] = _.partition(
    attachments,
    a => !a.isRedacted && a.isImage() && a.status === AttachmentStatus.AVAILABLE
  );
  let availableAttachments = _.filter(
    inlineImageAttachments.concat(genericAttachments),
    a => a.status === AttachmentStatus.AVAILABLE
  );

  return (
    <React.Fragment>
      <div className="itemAttachments">
        <ul className="attachments-inlineImageAttachments">
          {_.map(inlineImageAttachments, renderInlineImageAttachment)}
        </ul>
        <div className="genericAttachments">
          {_.map(genericAttachments, renderGenericAttachment)}
          <AttachmentsDownloadAllContainer attachments={availableAttachments} itemId={itemId} pathPrefix="sms" />
        </div>
      </div>
      {previewAttachment && (
        <AttachmentModal
          attachment={previewAttachment}
          attachments={availableAttachments}
          setAttachment={setPreviewAttachment}
          url={getDownloadUrl(previewAttachment)}
        />
      )}
    </React.Fragment>
  );

  function renderGenericAttachment(attachment, index) {
    return (
      <GenericAttachment
        attachment={attachment}
        key={attachment.id}
        onClick={(attachment.status === AttachmentStatus.AVAILABLE && openAttachmentPreview) || undefined}
      />
    );

    function openAttachmentPreview() {
      setPreviewAttachment(attachment);
    }
  }

  function renderInlineImageAttachment(attachment, index) {
    return (
      <HandleHeightChange key={attachment.id}>
        {({ onHeightChange }) => (
          <InlineImageAttachment
            attachment={attachment}
            inlinePreviewImageUrl={getPreviewUrl()}
            key={attachment.id}
            onClick={openAttachmentPreview}
            onLoad={onHeightChange}
          />
        )}
      </HandleHeightChange>
    );

    function getPreviewUrl() {
      let imageUrl = getDownloadUrl(attachment);
      return `${imageUrl}?size=preview`;
    }

    function openAttachmentPreview() {
      setPreviewAttachment(attachment);
    }
  }

  function getDownloadUrl(a) {
    return getSmsAttachmentUrl(orgId, a);
  }
}

SmsMessageAttachmentsContainer.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(SmsMessageAttachment)).isRequired,
  itemId: PropTypes.string.isRequired,
  onHeightChange: PropTypes.func,
};

SmsMessageAttachments.propTypes = {
  ...AttachmentPreviewOpener.childPropTypes,
  ...SmsMessageAttachmentsContainer.propTypes,
};

export { SmsMessageAttachments };
export default SmsMessageAttachmentsContainer;
