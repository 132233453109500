import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ConversationTopicsMultiSelect from './conversation_topics_multi_select';
import registerHotkey from 'components/hotkeys/register_hotkey';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

export class ConversationTopicsBase extends PureComponent {
  render() {
    return (
      <ConversationTopicsMultiSelect
        {...this.props}
        label="Topics"
        onClose={this.props.onMenuClose}
        onOpen={this.props.onMenuOpen}
        onOptionChange={this.props.onTopicChange}
        onSearch={this.props.onTopicSearch}
        onSelect={this.props.onTopicSelect}
        optionIds={this.props.conversation.topicIds}
        options={this.props.topics}
        placeholder="Add a Topic"
        ref={multiSelect => (this.multiSelect = multiSelect)}
        suggestedIds={this.props.topicSuggestions && this.props.topicSuggestions.addTopics}
        suggestedLabel="Suggested Topics"
        unitLabelSingular="Topic"
        unselectedLabel="All Topics"
      />
    );
  }
}

ConversationTopicsBase.propTypes = {
  className: PropTypes.string,
  dropDown: PropTypes.bool,
  conversation: PropTypes.object.isRequired,
  onFocus: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onTopicChange: PropTypes.func.isRequired,
  onTopicSearch: PropTypes.func,
  onTopicSelect: PropTypes.func,
  topics: PropTypes.arrayOf(PropTypes.object),
  topicSuggestions: PropTypes.object,
};

export default withShortcuts(ConversationTopicsBase, [
  registerHotkey({
    key: 'alt+t',
    callback: component => {
      component.multiSelect.handleMenuOpenerClick();
    },
    group: 'Conversation',
    label: 'Edit topics',
  }),
]);
