import { createAlignPlugin, KEY_ALIGN } from '@udecode/plate-alignment';
import { ELEMENT_LI, ELEMENT_PARAGRAPH } from '@udecode/plate';
import { Node, Point } from 'slate';
import { findNode, focusEditor, isBlock, isCollapsed, isDefined, setElements, unsetNodes } from '@udecode/plate-common';
import { get, map, trim, uniq } from 'lodash';

const DEFAULT_ALIGNMENT = 'left';
const VALID_ALIGNMENT_VALUES = ['left', 'center', 'right', 'justify'];
const VALID_TYPES = [ELEMENT_LI, ELEMENT_PARAGRAPH];

export default function createTextAlignmentPlugin() {
  return createAlignPlugin({
    inject: {
      props: {
        defaultNodeValue: DEFAULT_ALIGNMENT,
        validTypes: VALID_TYPES,
      },
    },
    withOverrides: editor => {
      editor.getCurrentTextAlignment = () => getCurrentBlockAlignment(editor);
      editor.setTextAlignment = alignment => {
        setCurrentBlockAlignment(editor, alignment);
      };

      return editor;
    },
  });
}

/**
 * Calculates text alignment value of the currently selected nodes. Takes selection
 * into account
 *
 * @param editor
 * @return {string} - one of the `VALID_ALIGNMENT_VALUES`
 */
function getCurrentBlockAlignment(editor) {
  // If selection is not collapsed, we try to find all selected nodes and check
  // their alignment values. If we see that all blocks have the same alignment,
  // then we return that value, otherwise we fall back on the defaults
  if (!isCollapsed(editor?.selection)) {
    const selectedNodes = getSelectedNodes(editor);
    if (!selectedNodes.length) return 'left';

    const alignments = map(selectedNodes, node => {
      const align = trim(get(node, KEY_ALIGN) || '').toLowerCase();
      return VALID_ALIGNMENT_VALUES.includes(align) ? align : DEFAULT_ALIGNMENT;
    });
    const uniqueValues = uniq(alignments);
    return uniqueValues.length === 1 ? uniqueValues[0] : DEFAULT_ALIGNMENT;
  }

  // If we are here, we need to check just the current node at the cursor
  const entry = findNode(editor, { match: node => isDefined(node[KEY_ALIGN]) });
  const alignment = entry ? trim(entry[0][KEY_ALIGN]).toLowerCase() : '';
  return VALID_ALIGNMENT_VALUES.includes(alignment) ? alignment : DEFAULT_ALIGNMENT;
}

/**
 * Iterates through the nodes and returns block elements that intersect with the selection
 * and have the type that this plugin can handle
 *
 * @param editor
 * @return {Node[]}
 */
function getSelectedNodes(editor) {
  if (!editor?.selection) return [];

  // Search all nodes that overlap the selection
  const generator = Node.descendants(editor, {
    from: editor.selection.anchor.path,
    to: editor.selection.focus.path,
    reverse: Point.isBefore(editor.selection.focus, editor.selection.anchor),
  });

  const nodes = [];
  for (const [node] of generator) {
    // Node built-in filtering is buggy, so it's easier to filter this way
    if (isBlock(editor, node) && VALID_TYPES.includes(node.type)) {
      nodes.push(node);
    }
  }
  return nodes;
}

function setCurrentBlockAlignment(editor, newAlignment) {
  if (!VALID_ALIGNMENT_VALUES.includes(newAlignment)) return;

  const nodeMatch = node => isBlock(editor, node) && VALID_TYPES.includes(node.type);
  if (newAlignment === DEFAULT_ALIGNMENT) {
    unsetNodes(editor, KEY_ALIGN, { match: nodeMatch });
  } else {
    setElements(
      editor,
      { [KEY_ALIGN]: newAlignment },
      {
        match: node => isBlock(editor, node) && VALID_TYPES.includes(node.type),
      }
    );
  }

  // Re-focus editor at the point where they stopped typing (or selecting)
  focusEditor(editor, editor.selection);
}
