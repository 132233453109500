import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import React, { useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import SelectAnswer from 'actions/answers/select_answer';
import { SnippetChannel, SnippetContentType } from 'models/answers/snippet';
import Tooltip from 'components/common/tooltip';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function Channels() {
  const { selectedAnswer, selectedAnswerLanguage, selectedAnswerType } = useContext(AnswerPanelContext);

  return (
    <ChannelsStyled>
      <Channel
        answer={selectedAnswer}
        channel={SnippetChannel.INFO}
        language={selectedAnswerLanguage}
        message="Reference"
        type={selectedAnswerType}
      >
        <FontAwesomeIcon icon={faBook} />
      </Channel>
      <Channel
        answer={selectedAnswer}
        channel={SnippetChannel.ANY_CHANNEL}
        language={selectedAnswerLanguage}
        message="Email"
        type={selectedAnswerType}
      >
        <i className="icon-email" />
      </Channel>
      <Channel
        answer={selectedAnswer}
        channel={SnippetChannel.MESSAGE}
        language={selectedAnswerLanguage}
        message="Message"
        type={selectedAnswerType}
      >
        <FontAwesomeIcon icon={faComment} />
      </Channel>
      <Channel
        answer={selectedAnswer}
        channel={SnippetChannel.SELF_SERVICE}
        language={selectedAnswerLanguage}
        message="Public"
        type={selectedAnswerType}
      >
        <FontAwesomeIcon icon={faGlobe} />
      </Channel>
    </ChannelsStyled>
  );
}

export const ChannelsStyled = styled.div`
  border-top: 1px solid ${p => p.theme.colors.gray400};
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 46px;
  justify-content: space-around;
`;

function Channel({ answer, channel, children, language, message, type }) {
  const contentType = SnippetContentType[channel];
  let content = answer.findContentByLanguage(language);
  const hasChannel = !!_.get(content, `${contentType}.bodyHtml`);
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    if (hasChannel) {
      executeAction(SelectAnswer, { answer, answerLanguage: language, answerType: channel });
    }
  }, [answer, channel, hasChannel]);

  return (
    <Tooltip delay={800} message={message} position="top">
      <IconWrapper
        data-aid={`answerPanel-preview-channelSelector-${contentType}`}
        isDisabled={!hasChannel}
        isFocused={hasChannel && type === channel}
        onClick={onClick}
      >
        {children}
      </IconWrapper>
    </Tooltip>
  );
}

export const IconWrapper = styled.div`
  align-items: center;
  border-top: 2px solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  height: 100%;
  justify-content: center;
  width: 60px;

  &:hover {
    color: ${p => p.theme.colors.green400};
  }

  ${p => p.isDisabled && disabledIconWrapper};
  ${p => p.isFocused && focusedIconWrapper};
`;

const focusedIconWrapper = css`
  border-top-color: ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.green400};
`;

const disabledIconWrapper = css`
  color: ${p => p.theme.colors.gray300};
  cursor: default;
  &:hover {
    color: ${p => p.theme.colors.gray300};
  }
`;
