export default class SetSuggestedReplyOnComposition {
  constructor(context) {
    this.context = context;
  }

  run({ currentCompositionId, suggestedReply }) {
    const currentComposition = this.context.stores.compositions.find(currentCompositionId);
    if (!currentComposition || !suggestedReply) {
      return;
    }

    currentComposition.setAppliedSuggestedReply(suggestedReply);
    this.context.stores.compositions.replace(currentComposition);
  }
}
