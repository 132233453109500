import classNames from 'classnames';
import React from 'react';

import T from 'prop-types';

export default class Notification extends React.Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  render() {
    return (
      <div className={classNames('notificationContainer', this.props.containerClasses)} onClick={this.onClick}>
        <div
          className={classNames('notification', this.props.contentClasses)}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          <div className="notification-content">{this.props.children}</div>
        </div>
      </div>
    );
  }

  onClick() {
    this.props.onClick && this.props.onClick(this.props.notificationId);
  }
}

Notification.propTypes = {
  onClick: T.func,
  onMouseEnter: T.func,
  onMouseLeave: T.func,
  containerClasses: T.string,
  contentClasses: T.string,
  children: T.any.isRequired,
  notificationId: T.string.isRequired,
};
