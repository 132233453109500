import React, { useContext } from 'react';
import styled from 'styled-components';
import AnimatedNumber from 'react-animated-number';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function Capacity() {
  const {
    agent: { away, online },
  } = useContext(LiveboardContext);

  const working = online - away;

  return (
    <CapacityWrapper>
      <span>
        <CapacityValue>
          <AnimatedNumber duration={600} stepPrecision={0} value={online} />
        </CapacityValue>{' '}
        Agents Online &nbsp;
      </span>
      <span>
        <CapacityValue>
          <AnimatedNumber duration={600} stepPrecision={0} value={working} />
        </CapacityValue>{' '}
        Agents Working &nbsp;
      </span>
      <span>
        <CapacityValue>
          <AnimatedNumber duration={600} stepPrecision={0} value={away} />
        </CapacityValue>{' '}
        Agents Away
      </span>
    </CapacityWrapper>
  );
}

const CapacityWrapper = styled.div`
  font-size: 3.2vh;
`;

const CapacityValue = styled.div`
  font-weight: bold;
  width: 5vw;
  display: inline-block;
  text-align: right;
`;
