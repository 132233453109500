import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';

import CircleIcon from './icons/circle_icon';
import styled from 'styled-components';
import MicrophoneMutedFillIcon from 'components/common/icons/fill/microphone-mute-fill';

export default class MuteButton extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }
  render() {
    return (
      <CircleIcon viewBox="0 0 25 25" {...this.props}>
        <StyledMicrophoneMutedFillIcon />
      </CircleIcon>
    );
  }
}

const StyledMicrophoneMutedFillIcon = styled(MicrophoneMutedFillIcon)`
  path {
    transform: scale(0.6);
    transform-origin: center;
  }
`;
