import _ from 'lodash';

import AnswerPanelState from 'models/answer_panel_state';

export default class CloseAnswerPanel {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationId, compositionId }) {
    let isAnswerPanelOpen = _.get(this.context.stores.answerPanelState.get(), 'isAnswerPanelOpen');
    if (!isAnswerPanelOpen) {
      return;
    }

    let answerPanelState = AnswerPanelState.create({ isAnswerPanelOpen: false });
    this.context.stores.answerPanelState.set(answerPanelState);

    this.context.stores.snippetSearchResult.remove();

    this.context.analytics.track('Answer Panel Closed', { customerId, conversationId, compositionId });
  }
}
