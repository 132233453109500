import { useEffect } from 'react';

import connect from 'components/lib/connect';
import OpenFirstInbox from 'actions/inbox/open_first_inbox';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function RedirectToTeamInboxes({ areGroupsLoaded, currentLocation, shouldRedirect }) {
  const executeAction = useExecuteAction();

  useEffect(() => {
    if (shouldRedirect && areGroupsLoaded) {
      executeAction(OpenFirstInbox);
    }
  }, [areGroupsLoaded, shouldRedirect, executeAction]);

  return null;
}

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const currentLocation = getProvider('currentLocation').get();
  return {
    areGroupsLoaded: !getProvider('routingGroups').isLoading(),
    // Basically, if it's a team-admin AND they're currently not on a team inbox page..
    shouldRedirect: isFeatureEnabled('groupInboxes') && !currentLocation.isTeam,
  };
}

const RedirectToTeamInboxesContainer = connect(mapStateToProps)(RedirectToTeamInboxes);

export default RedirectToTeamInboxesContainer;
