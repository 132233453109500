import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Dial Pad Fill
 */

export default function DialPadFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <rect height="4" rx="1" width="4" x="1" y="1" />
      <rect height="4" rx="1" width="4" x="6" y="1" />
      <rect height="4" rx="1" width="4" x="11" y="1" />
      <rect height="4" rx="1" width="4" x="1" y="6" />
      <rect height="4" rx="1" width="4" x="6" y="6" />
      <rect height="4" rx="1" width="4" x="11" y="6" />
      <rect height="4" rx="1" width="4" x="1" y="11" />
      <rect height="4" rx="1" width="4" x="6" y="11" />
      <rect height="4" rx="1" width="4" x="11" y="11" />
    </Icon>
  );
}
