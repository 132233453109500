import _ from 'lodash';

import { createPhoneCallContent, hasConfiguredPhoneNumbers } from './lib/phone_composition';
import { getActiveConversation } from 'models/customer';
import { typeReflect as CompositionTypeReflect } from 'models/composition/composition_content_from_js';
import ActivateComposition from './activate_composition';
import analytics from 'scripts/lib/analytics';
import Composition from 'models/composition';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import PhoneCallCompositionContent from 'models/composition/phone_call_composition_content';
import removeComposition from 'actions/composition/lib/remove_composition';

class CreateExternalPhoneCallComposition {
  constructor(context) {
    this.context = context;
    this.contentClass = PhoneCallCompositionContent;
  }

  run({ phoneNumber }) {
    if (!this._canCreateComposition()) {
      return;
    }

    let compositionStore = getCompositionsStore(this.context);
    if (!hasConfiguredPhoneNumbers(this.context)) {
      return;
    }

    // Can't create composition if no active conversation
    const conversation = this.activeConversation;
    if (!conversation) {
      return;
    }

    // Remove existing phone call composition, we'll replace it with this one
    let compositions = compositionStore.findAll();
    let existingPhoneCallComposition = _.find(compositions, composition => {
      return (
        composition.content instanceof PhoneCallCompositionContent &&
        this.activeConversation.id === composition.conversationId
      );
    });
    if (existingPhoneCallComposition) {
      removeComposition(this.context, existingPhoneCallComposition);
    }

    let composition = this._createComposition(conversation, phoneNumber);
    compositionStore.add(composition);
    analytics.track('Conversation Response Created', {
      customerId: composition.customerId,
      conversationId: composition.conversationId,
      compositionId: composition.id,
      contentType: CompositionTypeReflect.instanceClassToType(this.contentClass),
    });

    let agentId = this.context.stores.currentAgent.get().id;
    let customerId = this.context.stores.currentLocation.get().customerId;
    this.context.gateways.composition.add(agentId, customerId, composition.toJs());

    this.context.executeAction(ActivateComposition, { composition });
  }

  _createComposition(conversation, phoneNumber) {
    let currentLocation = this.context.stores.currentLocation.get();

    let composition = Composition.create({
      content: createPhoneCallContent(this.context, this.activeConversation, phoneNumber),
      conversationId: conversation.id,
      customerId: currentLocation.customerId,
    });

    return composition;
  }

  _canCreateComposition() {
    return this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }

  get activeConversation() {
    const { conversations } = this.context.stores.customers.storesFor(this.currentCustomerId);
    return getActiveConversation(conversations.findAll());
  }
}

export default CreateExternalPhoneCallComposition;
