import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import ModelFactory from 'scripts/domain/__specs__/factories/model_factory';

export default class AgentRoutingPreferencesService {
  constructor(getDatabase, pubsub) {
    this.pubsub = pubsub;
    this.preferences = ModelFactory.attributes('AgentRoutingPreferencesNone');
  }

  get(data, callback, path, { orgId, agentId }) {
    callback(null, { status: 200, statusText: statusText(200), response: this.preferences });
  }

  set(dto, callback, path, { orgId, agentId }) {
    this.preferences = dto;

    this.pubsub.publish(`v1/orgs/${orgId}/agents/${agentId}/routing-preferences`, {
      payload: this.preferences,
    });

    callback(null, { status: 204, statusText: statusText(204) });
  }

  update(dto, callback, path, { orgId, agentId }) {
    this.preferences = {
      channels: _.assign(this.preferences.channels, dto.channels),
      _version: dto._version || this.preferences.version + 1,
    };

    this.pubsub.publish(`v1/orgs/${orgId}/agents/${agentId}/routing-preferences`, {
      payload: this.preferences,
    });

    callback(null, { status: 200, statusText: statusText(200), response: this.preferences });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/agents/:agentId/routing-preferences': {
          GET: this.get,
          PATCH: this.update,
          PUT: this.set,
        },
      },
      this
    );
  }
}
