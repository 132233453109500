import React from 'react';
import PropTypes from 'prop-types';

import ModalButton, { ModalButtonDivider } from 'components/modals/modal_button';
import Modal from 'components/lib/modal';
import ModalFooter from 'components/modals/modal_footer';

function CloseConversationWarningModal(props) {
  const closeText = props.shouldGetNext ? 'Yes, close and next' : 'Yes, close';

  return (
    <Modal className="closeConversationWarningModal">
      <div className="closeConversationWarningModal-header">Wait! Close conversation?</div>
      <div className="closeConversationWarningModal-body">
        <p>Conversation will be closed without a reply.</p>
      </div>
      <ModalFooter>
        <ModalButton className="closeConversationWarningModal-cancelButton" onClick={props.onClose}>
          Cancel
        </ModalButton>
        <ModalButtonDivider />
        <ModalButton className="closeConversationWarningModal-confirmButton" onClick={props.onConfirm}>
          {closeText}
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

CloseConversationWarningModal.propTypes = {
  shouldGetNext: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CloseConversationWarningModal;
