import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/common/input';
import LabelInput from 'components/lib/label_input';
import Link from 'components/common/link';
import UserAuthLayout from './lib/user_auth_layout';
import UserButton from './lib/user_button';

const ForgotPassword = createReactClass({
  propTypes: {
    hasExpiredPassword: PropTypes.bool,
    initialEmail: PropTypes.string.isRequired,
    onRequestPasswordReset: PropTypes.func.isRequired,
  },

  getInitialState() {
    return { email: this.props.initialEmail, submitted: false };
  },

  onNavigateToForgotPassword() {
    this.setState({ submitted: false });
  },

  onRequestPasswordReset(ev) {
    ev.preventDefault();
    this.setState({ submitted: true });
    this.props.onRequestPasswordReset(this.state.email);
  },

  render() {
    if (this.state.submitted) {
      return this.renderPasswordRequested();
    }

    return this.renderPasswordRequestForm();
  },

  renderPasswordRequested() {
    return (
      <UserAuthLayout
        className="forgotPassword-layout"
        displayLogo
        image={<div className="forgotPassword-completeImage" />}
        message={this.renderPasswordRequestedMessage()}
        title="Please check your email"
      >
        <div className="userAuthLayout-proceedLinkContainer form-group">
          <Link href="/user/forgot-password" id="forgotPassword-link" onClick={this.onNavigateToForgotPassword}>
            Resend Reset Email
          </Link>
        </div>
      </UserAuthLayout>
    );
  },

  renderPasswordRequestedMessage() {
    return (
      <div>
        <p>{`We've sent an email to '${this.state.email}'.`}</p>
        <p>Please click the link in the email to reset your password.</p>
      </div>
    );
  },

  renderPasswordRequestForm() {
    return (
      <UserAuthLayout
        className="forgotPassword-layout"
        displayLogo
        image={<div className="forgotPassword-requestImage" />}
        message="We will send you a link to reset your password"
        notification={
          this.props.hasExpiredPassword
            ? 'Your password has expired. Enter your email so we can send you a link to reset your password'
            : null
        }
        title="Enter your email address"
      >
        <div className="userAuthLayout-formContainer">
          <form className="login-form" onSubmit={this.onRequestPasswordReset}>
            <LabelInput inputId="name" labelClass="userAuthLayout-form-inputLabel" placeholder="Email address">
              <Input
                autoFocus={!this.props.hasExpiredPassword}
                className="userAuthLayout-form-input email-control"
                name="email"
                onChange={evt => this.setState({ email: evt.target.value })}
                required="required"
                value={this.state.email}
              />
            </LabelInput>
            <div className="form-group">{this.renderRequestPasswordButton()}</div>
          </form>
        </div>
      </UserAuthLayout>
    );
  },

  renderRequestPasswordButton() {
    let isValidEmail = /.+@.+\..+/.test(this.state.email);
    let disabled = isValidEmail ? {} : { disabled: true };
    return (
      <UserButton className="userAuthLayout-form-button" onClick={this.onRequestPasswordReset} {...disabled}>
        Send Reset Email
      </UserButton>
    );
  },
});

export default ForgotPassword;
