import _ from 'lodash';
import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('TaskRouting').attr('type', 'TASK_ROUTING');

Factory.define('TaskRoutingItemForAgentRequest')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskRouting', _.merge({ itemType: 'AGENT_REQUEST' }, content));
});

Factory.define('TaskRoutingItemForManualAssignment')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskRouting', _.merge({ itemType: 'MANUAL_ASSIGNMENT' }, content));
});
