import _ from 'lodash';
import connect from 'components/lib/connect';
import MessageItem from './message_item';
import { MessageChannelType } from 'models/conversation_message';

const mapStateToProps = function(context, props) {
  const { getProvider } = context;
  const orgId = getProvider('auth')
    .get()
    .getOrgId();
  const messageContent = props.item.content;
  const channelType = messageContent.getChannelType();
  const hasReaction = messageContent.hasReaction();

  const getFriendlyCustomerAddress = customerAddress => {
    const customerProfile = getProvider('profile').get();
    const externalCustomerAddresses = customerProfile.getExternalCustomerAddresses();

    switch (channelType) {
      case MessageChannelType.TWITTER: {
        let customerHandle = '';
        externalCustomerAddresses.forEach(externalAddress => {
          if (externalAddress.id === customerAddress) {
            if (externalAddress) {
              customerHandle = _normalizeTwitterHandle(externalAddress.displayName);
            }
          }
        });
        return customerHandle;
      }

      case MessageChannelType.WHATSAPP:
      default:
        return messageContent.formatCustomerAddress();
    }
  };

  const getFriendlyCompanyAddress = companyAddress => {
    const channelConfig = getProvider('channelConfiguration').get();
    const endpoints = (channelConfig && channelConfig.endpoints) || [];

    switch (channelType) {
      case MessageChannelType.TWITTER: {
        let companyHandle = '';
        endpoints.forEach(endpoint => {
          if (endpoint.address === companyAddress) {
            let endpointConfig = endpoint.configuration;
            if (endpointConfig) {
              companyHandle = _normalizeTwitterHandle(endpointConfig.handle);
            }
          }
        });
        return companyHandle;
      }

      case MessageChannelType.INSTAGRAM_DIRECT: {
        let handle;
        endpoints.forEach(ep => {
          if (ep.address === companyAddress) {
            handle = _.get(ep, 'configuration.handle');
          }
        });
        return handle || companyAddress;
      }

      case MessageChannelType.WHATSAPP:
      default:
        return messageContent.formatCompanyAddress();
    }
  };

  return {
    friendlyCompanyAddress: getFriendlyCompanyAddress(messageContent.companyAddress),
    friendlyCustomerAddress: getFriendlyCustomerAddress(messageContent.customerAddress),
    hasReaction,
    ...props,
    orgId,
  };
};

function _normalizeTwitterHandle(handle) {
  if (_.startsWith(handle, '@')) {
    return handle;
  }
  return `@${handle}`;
}

export default connect(mapStateToProps)(MessageItem);
