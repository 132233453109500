import React from 'react';
import styled from 'styled-components';

/**
 * @visibleName Quoted Content
 */

export default function QuotedContentIcon({ onClick, onMouseDown }) {
  return (
    <StyledSvg fill="none" height="14" viewBox="0 0 26 14" width="26" {...{ onClick, onMouseDown }}>
      <rect className="oval" height="13" rx="6.5" width="25" x="0.5" y="0.5" />
      <circle className="dot" cx="19" cy="7" r="1" />
      <circle className="dot" cx="13" cy="7" r="1" />
      <circle className="dot" cx="7" cy="7" r="1" />
    </StyledSvg>
  );
}

const StyledSvg = styled.svg`
  cursor: pointer;
  display: flex;
  height: 14px;
  margin: ${p => p.theme.spacing.medium} 0 ${p => p.theme.spacing.small} 0;
  width: 26px;
  .oval {
    stroke: ${p => p.theme.colors.gray400};
  }
  .dot {
    stroke: ${p => p.theme.colors.gray600};
    stroke-width: 2px;
  }
  &:hover rect,
  &:hover circle {
    stroke: ${p => p.theme.colors.green400};
  }
`;
