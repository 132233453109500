import _ from 'lodash';

import ActiveCallConverter from 'scripts/application/dto_converters/active_call_converter';
import Assignee from 'models/assignee';
import CurrentCompositionUpdater from '../lib/current_composition_updater';
import hasLiveConnection from './lib/has_live_connection';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import PhoneCall from 'models/phone_call';
import qconsole from 'scripts/lib/qconsole';
import { subscribeToActiveCall, unsubscribeFromActiveCall } from './lib/active_call';

export default class ActiveCallGatewayObserver {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
  }

  /* Fetch Active Call */

  handleFetchActiveCallSuccess(activeCallDto) {
    if (_.isEmpty(activeCallDto)) {
      const currentActiveCall = this.context.stores.activeCall.get();
      if (currentActiveCall) {
        unsubscribeFromActiveCall(this.context);
        this.context.stores.activeCall.remove();
      }
    } else {
      // we have an active call, populate the store and setup subscriptions for it
      const activeCall = ActiveCallConverter.fromDto({
        id: IdGenerator.newId(),
        ...activeCallDto,
      });

      activeCall.setHasLiveConnection(hasLiveConnection(this.context, { activeCall }));

      if (activeCallDto.conversationItem.content.status === PhoneCall.Status.WARM_TRANSFER) {
        const routingGroupId = _.get(activeCallDto, 'conversationItem.content.transfer.toRoutingGroupId');
        const agentId = _.get(activeCallDto, 'conversationItem.content.transfer.toParticipantId');

        activeCall.setTransferee(new Assignee({ routingGroupId, agentId }));
      }

      this.context.stores.activeCall.set(activeCall);
      this.context.gateways.activeCall.cancelFetches();
      subscribeToActiveCall(this.context);
      this.currentCompositionUpdater.update();
    }
  }

  handleFetchActiveCallError(errorDto) {
    qconsole.log(`Failed to fetch existing active call ${JSON.stringify(errorDto)}`);
  }
}
