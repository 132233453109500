import moment from 'moment';

import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import Factory from 'factories/all';
import InteractionType from 'models/interaction_type';

export default class IncomingTaskSimulator extends CommunicationSimulator {
  constructor({ orgId, publisher, services }, database) {
    super({ attribute: 'incomingTasks', createItem: createTaskItem, orgId, publisher, services }, database);
  }

  _createRoutingItem(attrs) {
    return Factory.build(
      'RoutingItemForInbound',
      this._getRoutingItemAttrs({ channel: InteractionType.SMS, ...attrs })
    );
  }
}

function createTaskItem(rawTask) {
  return Factory.build('TaskItem', {
    initiator: {
      type: Communicator.AGENT,
      id: rawTask.agentId,
    },
    content: rawTask.content,
    dueAt: moment().format(),
  });
}
