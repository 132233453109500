import { Factory } from 'rosie';

import './widget_config_param_factory';

Factory.define('WidgetConfigSettings');

Factory.define('WidgetConfigSettingsWithDefaults')
  .extend('WidgetConfigSettings')
  .attr('loaderUrl', 'https://example.com/here')
  .attr('parameters', [Factory.attributes('WidgetConfigParameterWithDefaults')]);
