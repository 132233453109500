import createModel, { prop } from './lib/create_model';

const CustomAttribute = createModel({
  modelName: 'CustomAttribute',

  properties: {
    id: prop(String).isRequired,
    key: prop(String).isRequired,
    label: prop(String).isRequired,
    deactivated: prop(Boolean).default(false),
    createdAt: prop(String).isRequired,
    createdBy: prop(String).isRequired,
    updatedAt: prop(String),
    updatedBy: prop(String),
    _version: prop(Number).default(1),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const CustomAttributeValue = createModel({
  modelName: 'CustomAttributeValue',

  properties: {
    id: prop(String).isRequired,
    value: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export function customAttributeValuesFromDto(customAttributes) {
  return customAttributes.map(({ id, value }) => CustomAttributeValue.create({ id, value }));
}

export default CustomAttribute;
