import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ConfirmRedaction } from 'components/attachments/attachment_modal';
import connect from 'components/lib/connect';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import ModalCard from 'components/common/modal_card';
import CloseInCircleFill from 'components/common/icons/fill/close-in-circle-fill';

function RemoveAttachmentButton({ attachment, canRemoveAttachment, setIsRemoving }) {
  useResetIsRedactingWhenAttachmentChanges(attachment, setIsRemoving);

  if (!canRemoveAttachment) {
    return null;
  }

  const onClick = evt => {
    evt.preventDefault(); // Don't open up attachment preview modal
    evt.stopPropagation();
    setIsRemoving(true);
  };

  return (
    <React.Fragment>
      <StyledRemoveIcon onClick={onClick}>
        <CloseInCircleFill />
      </StyledRemoveIcon>
    </React.Fragment>
  );
}

export const StyledRemoveIcon = styled.div`
  cursor: pointer;
  height: 20px;
  opacity: 0;
  position: absolute;
  right: -16px;
  top: -15px;
  transform: translate(50%, -50%);
  visibility: hidden;
  width: 20px;
  right: 0;
  top: 0;
  z-index: 1;

  path {
    fill: ${p => p.theme.colors.gray400};
  }
  &:hover path {
    fill: ${p => p.theme.colors.gray500};
  }
`;

function mapStateToProps({ isFeatureEnabled }, { attachment }) {
  return {
    canRemoveAttachment: !attachment.isRedacted && isFeatureEnabled('complianceManagement'),
  };
}

const RemoveAttachmentButtonContainer = connect(mapStateToProps)(RemoveAttachmentButton);
export default RemoveAttachmentButtonContainer;

export function RemoveAttachmentModal({ attachment, setIsRemoving }) {
  const onClose = () => setIsRemoving(false);
  return (
    <PortalModalWrapper>
      <Modal>
        <ModalCard onCancel={onClose} onClose={onClose}>
          <ConfirmRedaction attachment={attachment} onCancel={onClose} onClose={onClose} />
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

function useResetIsRedactingWhenAttachmentChanges(attachment, setIsRemoving) {
  useEffect(() => {
    setIsRemoving(false);
  }, [attachment]);
}

const Modal = styled.div`
  text-align: center;

  .modalCard-content {
    display: flex;
    flex-direction: column;
    max-height: 80%;
    max-width: 80%;
    padding: 0;
    position: relative;
  }
`;
