import styled from 'styled-components';

export const TOASTS_PORTAL_ID = 'TOASTS_PORTAL';
const ToastsPortal = styled.div.attrs({ id: TOASTS_PORTAL_ID })`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  z-index: 30;
`;

export default ToastsPortal;
