import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import BubbleContent from 'components/customer/conversation_history/conversation_items_v2/content/bubble_content';
import Button from 'components/common/button';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import { SecureDataRequestStatuses } from 'models/secure_data_request';
import showCreditCardModal from 'actions/secure_data/show_credit_card_modal';
import { TransmissionStatuses } from 'models/secure_data_request';
import TypingIndicatorAnimation from 'components/customer/conversation_history/conversation_items_v2/content/typing_indicator_animation';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import qconsole from 'scripts/lib/qconsole';

/* Currency Formatter */
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function CreditCardRequestItem({ currentAgentId, item }) {
  const executeAction = useExecuteAction();
  const showCreditCard = useCallback(() => executeAction(showCreditCardModal, { itemId: item.id }), [item]);
  let currentRequestStatus = _.get(item, 'content.currentRequestStatus');
  let status;

  const disabled = item.initiator.id !== currentAgentId;
  switch (currentRequestStatus.requestStatus) {
    case SecureDataRequestStatuses.REQUESTING:
      if (_.get(currentRequestStatus, 'messageStatus.value') === TransmissionStatuses.FAILED) {
        status = (
          <RequestStatus data-aid={'request-status'}>
            Failed to send request to customer. Please try again.
          </RequestStatus>
        );
      } else {
        status = (
          <RequestStatus data-aid={'request-status'}>
            <span>Waiting for customer</span>
            <TypingIndicator />
          </RequestStatus>
        );
      }
      break;
    case SecureDataRequestStatuses.SUBMITTED:
      status = (
        <RequestStatus data-aid={'request-status'}>
          <Button data-aid={'view-button'} disabled={disabled} onClick={showCreditCard}>
            View Card
          </Button>
        </RequestStatus>
      );
      break;
    case SecureDataRequestStatuses.DECLINED:
      status = <RequestStatus data-aid={'request-status'}>Customer Declined</RequestStatus>;
      break;
    case SecureDataRequestStatuses.ERROR:
      status = <RequestStatus data-aid={'request-status'}>Configuration Error</RequestStatus>;
      break;
    case SecureDataRequestStatuses.COMPLETED:
      status = <RequestStatus data-aid={'request-status'}>Completed</RequestStatus>;
      break;
    case SecureDataRequestStatuses.TIMED_OUT:
      status = <RequestStatus data-aid={'request-status'}>Session Timed Out</RequestStatus>;
      break;
    default:
      qconsole.error('Invalid request status type');
  }

  // Assuming US dollar for now
  const monetaryUnit = _.get(item, 'content.metadata.monetaryUnit', 0);
  const memo = _.get(item, 'content.metadata.memo', '');

  let amount = 0;
  if (monetaryUnit !== 0) {
    amount = monetaryUnit / 100;
  }

  return (
    <StyledButtonContent data-aid={'creditCardRequest'} item={item}>
      <Amount data-aid={'amount'}>{formatter.format(amount)}</Amount>
      {memo ? <Memo data-aid={'memo'}>{memo}</Memo> : null}
      {status}
    </StyledButtonContent>
  );
}

const StyledButtonContent = styled(BubbleContent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
  padding: ${p => `${p.theme.spacing.medium}px ${p.theme.spacing.large}px`};
`;

const Amount = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Memo = styled.div`
  color: ${p => p.theme.colors.gray600};
  width: 100%;
`;

const RequestStatus = styled.div`
  margin-top: ${p => p.theme.spacing.medium};
`;

const TypingIndicator = styled(TypingIndicatorAnimation)`
  bottom: 2px;
  box-shadow: none;
  margin: 0px 0px 0px 12px;
  padding: 0px;

  & .typingIndicator-dots {
    height: 3px;
    width: 3px;
  }
`;

function mapStateToProps({ getProvider }, { item }) {
  const currentAgentId = getProvider('currentAgent').get().id;
  return { currentAgentId, item };
}

const CreditCardRequestItemContainer = connect(mapStateToProps)(CreditCardRequestItem);
export default CreditCardRequestItemContainer;

CreditCardRequestItem.propTypes = {
  currentAgentId: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
