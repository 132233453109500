import { filter } from 'lodash';
import Communicator from 'models/communicator';

export default class RemoveReaction {
  constructor(context) {
    this.context = context;
  }

  run({ item }) {
    let reactions = item.content && item.content.reactions;
    let reactionItems = filter(reactions, reaction => reaction.initiator.type === Communicator.AGENT);
    if (reactionItems.length > 0) {
      let itemUpdate = this.context.stores.conversationHistory.findBy({ id: item.id });
      itemUpdate.content.update({ reactions: [] });
      this.context.stores.conversationHistory.setPending(itemUpdate);
      this.context.gateways.reactions.delete({ itemId: item.id });
    }
  }
}
