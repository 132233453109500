import { createPluginFactory } from '@udecode/plate-common';
import { MARK_FONT_SIZE } from '@udecode/plate-font';

/**
 * This is a tweaked version of the standard font size plugin. It should be used instead
 * of the Plate one. It fixes a bug that caused the default font size ('medium') do be
 * applied to all elements that have no style. That interferes with Gladly default styling,
 * hence this plugin.
 */
export function createFontSizePlugin() {
  return createPluginFactory({
    key: MARK_FONT_SIZE,
    inject: {
      props: {
        nodeKey: MARK_FONT_SIZE,
      },
    },
    then: (editor, { type }) => ({
      deserializeHtml: {
        isLeaf: true,

        getNode: element => {
          const size = element.style.fontSize;
          if (size && size !== 'medium') {
            return { [type]: size };
          }
        },

        rules: [
          {
            validStyle: {
              fontSize: '*',
            },
          },
        ],
      },
    }),
  })();
}
