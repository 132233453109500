import Communicator from 'models/communicator';
import Reaction from 'models/reaction';

export default class AddReaction {
  constructor(context) {
    this.context = context;
  }

  run({ item }) {
    const reactionContent = {
      stringRepresentation: 'love',
    };

    let currentAgent = this.context.stores.currentAgent.get();

    let reactionItem = Reaction.create({
      content: reactionContent,
      initiator: {
        id: currentAgent.id,
        type: Communicator.AGENT,
      },
    });

    let itemUpdate = this.context.stores.conversationHistory.findBy({ id: item.id });
    itemUpdate.content.update({ reactions: [reactionItem] });
    this.context.stores.conversationHistory.setPending(itemUpdate);

    this.context.gateways.reactions.add({ itemId: item.id }, reactionItem);
  }
}
