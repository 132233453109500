import _ from 'lodash';
import PropTypes from 'prop-types';

import { PhoneNumberType, SmsPreferenceType } from 'models/phone_number';

export default PropTypes.shape({
  extension: PropTypes.string,
  isPrimary: PropTypes.bool,
  phoneNumber: PropTypes.string,
  smsPreference: PropTypes.oneOf(_.values(SmsPreferenceType)),
  type: PropTypes.oneOf(_.values(PhoneNumberType)),
});
