export default class AgentSubscriptions {
  constructor(context) {
    this.context = context;
  }

  subscribe(agentId) {
    let auth = this.context.stores.auth.get();
    if (auth.isUserActivated() && !auth.isPasswordBeingReset()) {
      // fetch
      this.context.gateways.activeCall.fetch(agentId);

      // request
      this.context.gateways.agentRoutingPreferences.request();
      this.context.gateways.agentStatus.request();
      this.context.gateways.currentAgentProfile.request();

      this.context.gateways.routableInboxCounts.requestRoutableInboxCounts();
      this.context.gateways.conversationCount.requestAgentConversationCounts(agentId);

      // subscribe
      this.context.gateways.routingEvent.subscribe(agentId);
    }
  }
}
