import _ from 'lodash';

import AddGreetingSuggestion from 'actions/greeting_suggestions/add_greeting_suggestion';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import SendChatMessage from 'actions/composition/send_chat_message';
import TypingState from 'models/typing_state';
import UpdateTypingStatus from 'actions/composition/update_typing_status';

export default class SendChat {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, compositionId, content }) {
    const composition = getCompositionsStore(this.context).findBy({ id: compositionId });
    if (!composition) {
      return;
    }

    const compositionContent = composition.toJs().content;
    _.forEach(content, (html, attr) => {
      compositionContent[attr] = html;
    });

    this.context.executeAction(AddGreetingSuggestion, {
      customerId,
      messageText: content.text,
    });

    this.context.executeAction(SendChatMessage, {
      compositionId,
      content: compositionContent,
    });

    this.context.executeAction(UpdateTypingStatus, {
      typingState: TypingState.STOPPED,
      customerId: composition.customerId,
    });
  }
}
