import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const ReasonEnded = createEnum('AGENT_ENDED', 'CONSUMER_NO_REPLY', 'NEW_SESSION_STARTED', 'TIME_EXPIRED');

const ChatEnded = createModel({
  modelName: 'ChatEnded',

  properties: {
    sessionId: String,
    reasonEnded: prop().oneOf(..._.keys(ReasonEnded)),
  },
});

export default ChatEnded;
