import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class RemoveThread {
  constructor(context) {
    this.context = context;
  }

  run(compositionId) {
    const compositionsStore = getCompositionsStore(this.context);
    const composition = compositionsStore.findBy({ id: compositionId });
    composition.content.removeThread();
    compositionsStore.replace(composition);
  }
}
