import { useEffect, useMemo, useRef, useState } from 'react';

import {
  createPlugins,
  createBoldPlugin,
  createItalicPlugin,
  createListPlugin,
  createUnderlinePlugin,
  createSoftBreakPlugin,
} from '@udecode/plate';

import components from 'components/text_editor_new/plugins/components';
import createAITextCompletion from 'components/text_editor_new/plugins/ai/ai_text_completion';
import { createAnswersPlugin } from 'components/text_editor_new/plugins/answers/answers_plugin';
import createCapitalizeFirstWordPlugin from 'components/text_editor_new/plugins/capitalize_first_word';
import createCapitalizeIPlugin from 'components/text_editor_new/plugins/capitalize_i';
import { createFontColorPlugin } from 'components/text_editor_new/plugins/font_style/font_color';
import { createFontElementPlugin } from 'components/text_editor_new/plugins/font_style/font_element';
import { createFontSizePlugin } from 'components/text_editor_new/plugins/font_style/font_size';
import { createEditorEventsPlugin } from 'components/text_editor_new/plugins/editor_events/create_editor_events_plugin';
import { createHotkeyPlugin } from 'components/text_editor_new/plugins/hotkey/create_hotkey_plugin';
import { createInlineImagePlugin } from 'components/text_editor_new/plugins/inline_image/create_inline_image_plugin';
import createInsertTextAfterInline from 'components/text_editor_new/plugins/insert_text_after_inline';
import createParagraphPlugin from 'components/text_editor_new/plugins/paragraph';
import createPlaceholders from 'components/text_editor_new/plugins/placeholders';
import { createLinkPlugin } from 'components/text_editor_new/plugins/inline_links/create_link_plugin';
import { createLinkSelectionPlugin } from 'components/text_editor_new/plugins/inline_links/create_link_selection_plugin';
import createSaveOnBlur from 'components/text_editor_new/plugins/save_on_blur';
import createSuggestedReplies from 'components/text_editor_new/plugins/suggested_replies/create_suggested_replies';
import createTextAlignmentPlugin from 'components/text_editor_new/plugins/text_alignment';
import { useCreateOpenAnswerPanelPlugin } from 'components/text_editor_new/plugins/create_open_answer_panel_plugin';
import { useCreateVariablesPlugin, useUpdateVariables } from 'components/text_editor_new/plugins/variables';
import useEditorWithDecorators from 'components/text_editor_new/hooks/use_editor_with_decorators';
import { useCreatePasteAttachmentsPlugin } from 'components/text_editor_new/plugins/create_paste_attachments_plugin';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function useRichTextEditorPlugins({ composerRef }, editorProps, extraPlugins) {
  const { name, onBlur, editorId } = editorProps;
  const isFeatureEnabled = useIsFeatureEnabled();

  const createOpenAnswerPanelPlugin = useCreateOpenAnswerPanelPlugin();
  const createVariablesPlugin = useCreateVariablesPlugin();
  const createPasteAttachmentsPlugin = useCreatePasteAttachmentsPlugin();

  const isSuggestedRepliesEnabled = isFeatureEnabled('suggestedReplies');
  const [suggestedRepliesPlugin] = useState(() => {
    return isSuggestedRepliesEnabled ? createSuggestedReplies() : null;
  });

  const plugins = useMemo(() => {
    // ORDER MATTERS. Some plugins can interact with each other in adverse ways. Be careful when changing the order.
    let pluginList = [
      // This plugin must come first
      createInsertTextAfterInline(),

      // editor events and keyboard hotkeys
      createHotkeyPlugin(),
      createEditorEventsPlugin(),

      // Autocapitalization
      createCapitalizeIPlugin(),
      createCapitalizeFirstWordPlugin(),

      createAITextCompletion(),

      // general
      createSaveOnBlur(onBlur),
      createOpenAnswerPanelPlugin(),

      // marks
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createFontColorPlugin(),
      createFontSizePlugin(),
      createFontElementPlugin(),

      // elements
      createListPlugin(),
      createParagraphPlugin(),
      createTextAlignmentPlugin(),
      createInlineImagePlugin(),

      // inlines
      createPlaceholders(),
      createLinkPlugin(),
      createLinkSelectionPlugin(),

      // etc.
      createAnswersPlugin(),
      createVariablesPlugin(),
      createPasteAttachmentsPlugin(),

      createSoftBreakPlugin(),
    ];

    if (extraPlugins) {
      pluginList = [...pluginList, ...extraPlugins];
    }

    if (suggestedRepliesPlugin) {
      pluginList = pluginList.concat(suggestedRepliesPlugin);
    }

    return createPlugins(pluginList, { components });
  }, [
    createOpenAnswerPanelPlugin,
    createPasteAttachmentsPlugin,
    createVariablesPlugin,
    extraPlugins,
    onBlur,
    suggestedRepliesPlugin,
  ]);

  const editor = useEditorWithDecorators(plugins, { editorId });
  useUpdateVariables(editor);

  const editorRef = useRef(editor);
  const [, rerender] = useState(null);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = editor;
      rerender({});
    }
    if (!composerRef.current.editorRefs[name]) {
      composerRef.current.editorRefs[name] = editorRef;
      rerender({});
    }
  }, [composerRef, editor, name]);
  editorProps.editorRef = editorRef;

  return editorRef;
}
