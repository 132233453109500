import _ from 'lodash';
import he from 'he';

import Composition from 'models/composition';
import TaskEditCompositionContent from 'models/composition/task_composition_edit_content';
import CreateComposition from './lib/create_composition';

export default class EditTaskItem extends CreateComposition {
  constructor(context) {
    super(context, TaskEditCompositionContent);
  }

  _createTaskEditComposition({ content, conversationItemId }) {
    return new TaskEditCompositionContent({
      assignee: content.assignee,
      bodyHtml: content.bodyHtml || createBodyHtmlFromNote(content.note),
      dueAt: content.dueAt,
      conversationItemId,
    });
  }

  // overridden method
  _createComposition(attrs) {
    const currentLocation = this.context.stores.currentLocation.get();
    return Composition.create({
      attachments: attrs.item.content.attachments,
      content: attrs.content,
      conversationId: attrs.conversationId,
      customerId: currentLocation.customerId,
    });
  }

  // overridden method
  _createContent(attrs) {
    return this._createTaskEditComposition({
      content: attrs.item.content,
      conversationItemId: attrs.item.id,
    });
  }
}

function createBodyHtmlFromNote(note) {
  const lines = note.split('\n');
  const escapedLines = _.map(lines, line => he.encode(line));
  const divWrappedLines = _.map(escapedLines, line => `<div>${line}</div>`);
  return `<div>${divWrappedLines.join('')}</div>`;
}
