import _ from 'lodash';
import createModel, { prop } from './lib/create_model';
import TrieSearch from 'trie-search';

const PhraseSuggestion = createModel({
  modelName: 'PhraseSuggestion',
  properties: {
    id: prop(String),
    text: prop(String),
    score: prop(Number),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export function createPhraseSuggestionSearcher(suggestions) {
  const trie = getTrie(suggestions);

  return text => {
    if (trie.size) {
      const phraseSuggestions = trie.get(text);
      return _.last(_.sortBy(phraseSuggestions, s => s.score));
    }
    return null;
  };
}

export function getTrie(suggestions) {
  let ts = new TrieSearch('populatedText', { indexField: 'id', splitOnRegEx: false });
  ts.addAll(suggestions);
  return ts;
}

export function getSuggestionText(suggestion, sentenceText, minCharacters = 5) {
  if (sentenceText.length < minCharacters) {
    return '';
  }
  // if the agent has started typing part of the we should return the populated suggestion text
  const awfulVariableRegex = /<variable.+?data-type="(.+?)">.+?<\/variable>/gi;
  let match = awfulVariableRegex.exec(_.get(suggestion, 'text'));
  if (match && sentenceText.length > match.index) {
    return suggestion ? suggestion.populatedText.substring(sentenceText.length) : '';
  }
  return suggestion ? suggestion.text.substring(sentenceText.length) : '';
}

export default PhraseSuggestion;
