import { useContext, useEffect } from 'react';

import addSnippetAttachmentsToComposition from 'components/composer/email/add_snippet_attachments_to_composition';
import ComposerContext from 'components/composer/contexts/composer_context';

/**
 * Transfers snippet attachments to the composition to make sure we handle inlines properly. This helper is
 * intended to be used with the NEW EMAIL COMPOSER ONLY
 *
 * @param {object} defaultBody - expected structure is `{greetingSnippet?:<snippet>, signatureSnippet?:<snippet>}`
 */
export function useSnippetAttachments(defaultBody) {
  const { composition, attachSnippetAttachments } = useContext(ComposerContext);
  const defaultBodyHash = JSON.stringify(defaultBody);
  const attachmentsHash = JSON.stringify(composition?.attachments);

  // We use incomplete effect deps on purpose, in order to deal with excessive updates due to context changes
  useEffect(() => {
    addSnippetAttachmentsToComposition({
      attachSnippetAttachments,
      composition,
      defaultBody,
    });
  }, [attachmentsHash, defaultBodyHash]);
}
