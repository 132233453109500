import StandardGateway from './lib/standard_gateway';

export default class AgentNotificationGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/snippets/:snippetId');
  }

  fetchAll(query, httpOptions) {
    return super.fetchAll(null, query, httpOptions);
  }
}
