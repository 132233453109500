import CustomerTypingState from 'models/customer_typing_state';
import TypingState from 'models/typing_state';
import { updateCustomerTypingState } from './lib/update_customer_typing_state';
import ServerClock from 'scripts/application/lib/server_clock';

export default class StopCustomerTypingState {
  constructor(context) {
    this.context = context;
  }

  run({ customerId }) {
    const customerTypingState = CustomerTypingState.fromJs({
      state: TypingState.STOPPED,
      timestamp: ServerClock.toISOString(),
    });

    updateCustomerTypingState(this.context, customerId, customerTypingState);
  }
}
