import _ from 'lodash';

import SearchQuery from 'models/search/search_query';
import SearchFilterConverter from 'scripts/adapters/routes/url_converters/search_filter_converter';

export default {
  fromUrlParameters(urlParamaters = {}) {
    let filterDto = _.omit(urlParamaters, ['q', 'fr', 'sz']);
    filterDto = SearchFilterConverter.convertToDto(filterDto);
    return SearchQuery.fromJs({
      text: urlParamaters.q,
      filter: filterDto,
      size: isNaN(parseInt(urlParamaters.sz)) ? null : parseInt(urlParamaters.sz),
      from: isNaN(parseInt(urlParamaters.fr)) ? null : parseInt(urlParamaters.fr),
    });
  },

  toUrlParameters(searchQuery) {
    let params = {};
    if (searchQuery.text) {
      params.q = searchQuery.text;
    }
    if (searchQuery.from && searchQuery.from !== 0) {
      params.fr = searchQuery.from;
    }
    if (searchQuery.size && searchQuery.size !== SearchQuery.DEFAULT_PAGE_SIZE) {
      params.sz = searchQuery.size;
    }
    return _.merge(params, SearchFilterConverter.convertToUrl(searchQuery.filter));
  },
};
