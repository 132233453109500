import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';
import SearchField from './search_field';
export const Channel = createEnum(
  'CHAT_MESSAGE',
  'CUSTOM_CHANNEL',
  'EMAIL',
  'FB_MESSAGE',
  'INSTAGRAM_DIRECT',
  'PHONE_CALL',
  'SMS',
  'VOICEMAIL',
  'WHATSAPP',
  'TWITTER'
);

export default createModel({
  modelName: 'SearchFilter',

  properties: {
    agentIds: prop([String]),
    answerIds: prop([String]),
    audienceIds: prop([String]),
    channels: prop([String]),
    createdAt: prop({
      gte: prop(String),
      lte: prop(String),
    }),
    customerIds: prop([String]),
    dueAt: prop({
      gte: prop(String),
      lte: prop(String),
    }),
    inboxIds: prop([String]),
    hasFields: prop([SearchField]),
    statuses: prop([String]),
    topicIds: prop([String]),
    type: prop(String),
    updatedAt: prop({
      gte: prop(String),
      lte: prop(String),
    }),
  },

  setType(type) {
    this.type = type;
  },

  setAgentIds(agentIds) {
    this.agentIds = agentIds;
  },

  setAnswerIds(answerIds) {
    this.answerIds = answerIds;
  },

  setAudienceIds(audiences) {
    this.audienceIds = audiences;
  },

  setChannels(channels) {
    this.channels = channels;
  },

  setTopicIds(topicIds) {
    this.topicIds = topicIds;
  },

  setInboxIds(inboxIds) {
    this.inboxIds = inboxIds;
  },

  setStatuses(statuses) {
    this.statuses = statuses;
  },

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
  },

  setUpdatedAt(updatedAt) {
    this.updatedAt = updatedAt;
  },

  setDueAt(dueAt) {
    this.dueAt = dueAt;
  },

  setHasFields(hasFields) {
    this.hasFields = hasFields;
  },

  statics: {
    Channel,
  },
});
