import StandardGateway from './lib/standard_gateway';

export default class AgentRoutingPreferencesGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/agents/:agentId/routing-preferences');
  }

  get() {
    return super.fetch();
  }

  set(preferences) {
    return super.replace(preferences);
  }
}
