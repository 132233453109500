import React, { useContext } from 'react';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import Channels from './answer_preview_channels';
import Content from './content';
import EmptyAnswerPreview from './empty_answer_preview';
import Header from './answer_preview_header';
import PlaceholderAnswerPreview from './placeholder_answer_preview';
import useTrackAnswerView from 'components/customer/answer_panel/use_track_answer_view';

export default function AnswerPreview() {
  const { searchText, selectedAnswer, selectedAnswerType, selectedAnswerLanguage } = useContext(AnswerPanelContext);
  useTrackAnswerView(selectedAnswer, selectedAnswerType, selectedAnswerLanguage);

  if (!selectedAnswer && searchText) {
    return <EmptyAnswerPreview />;
  } else if (!selectedAnswer) {
    return <PlaceholderAnswerPreview />;
  }

  return (
    <React.Fragment>
      <Header />
      <Content />
      <Channels />
    </React.Fragment>
  );
}
