import React from 'react';
import PropTypes from 'prop-types';

import ConversationNotification from 'models/notification/conversation_notification';
import NotificationContentContainer from './notification_content_container';

function EmailSendFailureNotification({ notification }) {
  let content = notification.conversationItem.content;

  return (
    <NotificationContentContainer
      body={content.recipient ? `Email not delivered for ${content.recipient}` : 'Email not delivered'}
      containerClasses="emailSendFailureNotification"
      heading={'Email failed'}
      iconClass="icon-email-filled"
      itemType="Email"
      notification={notification}
    />
  );
}

EmailSendFailureNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default EmailSendFailureNotification;
