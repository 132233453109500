import React from 'react';
import PropTypes from 'prop-types';

import ConversationNotification from 'models/notification/conversation_notification';
import { formatPhoneNumber } from 'models/phone_number';
import NotificationContentContainer from './notification_content_container';

function VoicemailNotification({ notification }) {
  return (
    <NotificationContentContainer
      heading={`From ${formatPhoneNumber(notification.conversationItem.content.customerNumber)}`}
      iconClass="icon-voicemail"
      itemType="Voicemail"
      notification={notification}
    />
  );
}

VoicemailNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default VoicemailNotification;
