import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName End Call Fill
 */

export default function EndCallFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M0.00223626 5.28746C-0.0113978 5.10501 0.0376123 4.92324 0.141019 4.77273C0.610735 4.09525 2.29705 2.39657 7.1951 2.00189L8.81993 2C13.7177 2.38271 15.3914 4.10027 15.8596 4.77783C15.9625 4.92794 16.0113 5.10931 15.9978 5.29165L15.8539 7.28057C15.84 7.48134 15.749 7.66885 15.6003 7.80313C15.4516 7.9374 15.257 8.0078 15.058 7.99931L12.4283 7.90308C12.2265 7.89496 12.0351 7.81125 11.8914 7.66824C11.7477 7.52523 11.6619 7.33318 11.6509 7.12951L11.5813 5.72957C11.5727 5.57579 11.5216 5.42761 11.4338 5.3018C11.3461 5.17598 11.2252 5.07754 11.0849 5.01759C10.6231 4.81896 9.52527 4.62223 8.27519 4.58163L7.72647 4.55949C6.47629 4.603 5.37801 4.80227 4.91577 5.00198C4.77517 5.06209 4.65396 5.16078 4.56592 5.28683C4.47788 5.41288 4.42653 5.56126 4.41768 5.71512L4.34554 7.1158C4.33397 7.31964 4.24772 7.51201 4.10358 7.65545C3.95944 7.79889 3.76773 7.88313 3.56579 7.89177L0.936462 7.99353C0.737233 8.00263 0.542561 7.93267 0.394041 7.79861C0.245521 7.66454 0.154934 7.477 0.141637 7.27605L0.00223626 5.28746ZM8.5 7C8.5 6.72386 8.27614 6.5 8 6.5C7.72386 6.5 7.5 6.72386 7.5 7V12.9325L5.32009 11.1159C5.10795 10.9391 4.79267 10.9678 4.61589 11.1799C4.43911 11.392 4.46777 11.7073 4.67991 11.8841L7.67991 14.3841C7.86533 14.5386 8.13467 14.5386 8.32009 14.3841L11.3201 11.8841C11.5322 11.7073 11.5609 11.392 11.3841 11.1799C11.2073 10.9678 10.892 10.9391 10.6799 11.1159L8.5 12.9325V7Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
