import { addCustomerToAccessHistory } from '../customer/lib/customer_access_history';
import Customer from 'models/customer';
import CustomerProfileConverter from 'scripts/application/dto_converters/customer_profile_converter';
import NavigateToCustomerProfile from 'actions/customer/navigate_to_customer_profile';

export default class CreateProfile {
  constructor(context) {
    this.context = context;
  }

  run({ attrs, navigateToCustomer }) {
    const customer = createProfile(this.context, attrs);
    if (navigateToCustomer) {
      this.context.executeAction(NavigateToCustomerProfile, { customerId: customer.id });
    }
  }
}

export function createProfile(context, attrs) {
  let customer = Customer.create(attrs);
  addCustomerToStore();
  addCustomerToAccessHistory(context, customer.id);
  context.gateways.customerProfile.add(CustomerProfileConverter.toDto(customer.profile));
  return customer;

  function addCustomerToStore() {
    context.stores.customers.add(customer);
    context.stores.customers.setPendingNew(customer);
    let profileStore = context.stores.customers.storesFor(customer.id).profile;
    profileStore.setPending(customer);
  }
}
