import { createContentModel, isEmpty } from './composition_content_shared';

const ExternalFormCompositionContent = createContentModel({
  modelName: 'ExternalFormCompositionContent',

  properties: {
    errors: Object,
    sectionsContent: Object,
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },
});

export default ExternalFormCompositionContent;
