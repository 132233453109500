import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('SmsMessageAttachment').extend('Uuid');

Factory.define('SmsMessageAttachmentWithDefaults')
  .extend('SmsMessageAttachment')
  .attr('contentType', 'text/plain')
  .attr('filename', 'some-filename.txt')
  .attr('source', {})
  .attr('fileSize', 100);
