export default class RefreshAuthToken {
  constructor(context) {
    this.context = context;
  }

  run() {
    let auth = this.context.stores.auth.get();
    if (!auth.isLoggedIn()) {
      this.context.scheduler.cancel(this.constructor.jobId);
      return;
    }

    this.context.gateways.authentication.requestTokenRefresh();
  }

  static get jobId() {
    return 'refreshAuthToken';
  }
}
