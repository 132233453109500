import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class RemoveAttachment {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, attachmentId }) {
    const compositionsStore = getCompositionsStore(this.context);
    if (!compositionsStore) {
      return;
    }

    let composition = compositionsStore.find(compositionId);
    if (!composition || !composition.removeAttachment(attachmentId)) {
      return;
    }
    compositionsStore.replace(composition);
  }
}
