import TaskComment from 'models/task_comment';
import convertAgentIdsToMentions from 'actions/composer/shared/convert_agent_ids_to_mentions';

export default class AddCommentToTask {
  constructor(context) {
    this.context = context;
  }

  run(content) {
    let initiatingAgentId = this.context.stores.currentAgent.get().id;

    content = convertAgentIdsToMentions(this.context, content);

    const { mentions, message, itemId } = content;
    let comment = TaskComment.create({ initiatingAgentId, itemId, message, mentions });

    let taskInfo = this.context.stores.taskInfo.findBy({ id: itemId });
    taskInfo.addComment(comment);
    this.context.stores.taskInfo.setPending(taskInfo);

    let commentJs = comment.toJs();
    this.context.gateways.taskComments.add({ taskId: itemId }, commentJs);

    let currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation.customerId;
    this.context.analytics.track('Task Comment Added', { customerId, itemId });
  }
}
