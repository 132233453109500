import createReactClass from 'create-react-class';
import moment from 'moment';
import Morearty from 'morearty';
import P from 'prop-types';
import React from 'react';

import Communicator from 'models/communicator';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';
import TaskCard from './task_card';

const TaskCardContainer = createReactClass({
  mixins: [Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  propTypes: {
    assignedAgentId: P.string,
    createdAt: P.string,
    creator: P.shape({
      id: P.string,
      type: P.string,
    }),
    dueAt: P.string,
    isClosed: P.bool,
    note: P.string,
  },

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('agents'));
  },

  render() {
    let creatorName = this.getCreatorName();
    let assigneeName = this.props.assignedAgentId && this.getAgentName(this.props.assignedAgentId);
    let isNearlyOrOverdue = this.isNearlyOrOverdue(this.props.dueAt);

    return (
      <TaskCard
        assigneeName={assigneeName}
        createdAt={this.props.createdAt}
        creatorName={creatorName}
        dueAt={this.props.dueAt}
        isClosed={this.props.isClosed}
        isNearlyOrOverdue={isNearlyOrOverdue}
        note={this.props.note}
      />
    );
  },

  getCreatorName() {
    if (this.props.creator.type === Communicator.AUTOMATED) {
      return 'Rule';
    }
    return this.getAgentName(this.props.creator.id);
  },

  getAgentName(id) {
    let agent = this.getProvider('agents').findBy({ id });
    return agent ? agent.getDisplayName() : 'Agent';
  },

  isNearlyOrOverdue(dueAt) {
    return moment()
      .add(5, 'minutes')
      .isAfter(dueAt);
  },
});

export default TaskCardContainer;
