import createLocationModel from '../lib/create_location_model';

export default createLocationModel({
  modelName: 'WebhookEditor',
  properties: {
    webhookId: String,
  },

  statics: {
    breadcrumb: 'Webhook Editor',
    create({ webhookId } = { webhookId: undefined }) {
      return new this({ webhookId });
    },
  },
});
