import createModel, { prop } from './lib/create_model';

export const CustomerAssigneeValue = createModel({
  modelName: 'CustomerAssigneeValue',
  properties: {
    agentId: prop(String),
    routingGroupId: prop(String).isRequired,
  },
});

export default createModel({
  modelName: 'CustomerAssigneeChange',

  properties: {
    newAssignee: prop(CustomerAssigneeValue),
    previousAssignee: prop(CustomerAssigneeValue),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
