import classnames from 'classnames';
import moment from 'moment';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import PhoneCall from 'models/phone_call';
import Timer from 'components/lib/date/timer';

export class ActiveCallElapsedTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = { previousState: null, startTime: null };
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
    this.renderLiveTime = this.renderLiveTime.bind(this);
    this.renderMuteOrHoldTime = this.renderMuteOrHoldTime.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAgentMuted !== this.props.isAgentMuted && this.props.isAgentMuted) {
      this.setState({ previousState: ActiveCallElapsedTime.StatusText.MUTE, startTime: this.props.agentMutedAt });
    }
    if (nextProps.isCustomerOnHold !== this.props.isCustomerOnHold && this.props.isCustomerOnHold) {
      this.setState({ previousState: ActiveCallElapsedTime.StatusText.HOLD, startTime: this.props.customerOnHoldAt });
    }
  }

  render() {
    if (this.props.isLiveCall) {
      return (
        <div className="activeCallStatus-container activeCallElapsedTime">
          {this.renderLiveTime()}
          {this.renderMuteOrHoldTime()}
          {this.renderRecordingPaused()}
        </div>
      );
    }
    return (
      <div className="activeCallElapsedTime">
        <div className="activeCallElapsedTime-connecting">Connecting...</div>
      </div>
    );
  }
  renderLiveTime() {
    let classes = classnames('activeCallElapsedTime-liveCall', {
      'activeCall-mutedOrOnHold': this.isMutedOrOnHold(),
      'activeCall-liveTime-recordingPaused': this.shouldRenderPausedTimer(),
    });
    return (
      <div className={classes}>
        {ActiveCallElapsedTime.StatusText.LIVE}
        <Timer className="activeCallElapsedTime-timer liveCallTimer" startTime={this.props.answeredAt} />
      </div>
    );
  }

  renderRecordingPaused() {
    const classes = classnames('activeCall-recordingPausedContainer', {
      'activeCall-recordingPaused': this.shouldRenderPausedTimer(),
    });
    return <div className={classes}>{ActiveCallElapsedTime.StatusText.RECORDING_PAUSED}</div>;
  }

  renderMuteOrHoldTime() {
    const classes = classnames('activeCall-muteOrHoldContainer', {
      'activeCall-mutedOrOnHold': this.isMutedOrOnHold(),
    });
    const timerClasses = classnames('activeCallElapsedTime-timer mutedOrOnHoldCallTimer', {
      muteTimer: this.props.isAgentMuted && !this.props.isObserver,
      holdTimer: this.props.isCustomerOnHold,
    });
    if (this.props.isCustomerOnHold) {
      return (
        <div className={classes}>
          {ActiveCallElapsedTime.StatusText.HOLD}
          <Timer className={timerClasses} startTime={this.props.customerOnHoldAt} />
        </div>
      );
    }

    if (this.props.isAgentMuted && !this.props.isObserver) {
      return (
        <div className={classes}>
          {ActiveCallElapsedTime.StatusText.MUTE}
          <Timer className={timerClasses} startTime={this.props.agentMutedAt} />
        </div>
      );
    }
    const duration = moment().diff(moment(this.state.startTime), 'ms');
    return (
      <div className={classes}>
        {this.state.previousState}
        <span className="activeCallElapsedTime-timer mutedOrOnHoldCallTimer">{Timer.displayDuration(duration)}</span>
      </div>
    );
  }

  isMutedOrOnHold() {
    return (this.props.isAgentMuted && !this.props.isObserver) || this.props.isCustomerOnHold;
  }

  shouldRenderPausedTimer() {
    return !this.isMutedOrOnHold() && this.props.isRecordingPaused;
  }
}

ActiveCallElapsedTime.propTypes = {
  answeredAt: PropTypes.string,
  isLiveCall: PropTypes.bool.isRequired,
  isCustomerOnHold: PropTypes.bool,
  isAgentMuted: PropTypes.bool,
  agentMutedAt: PropTypes.string,
  customerOnHoldAt: PropTypes.string,
  isRecordingPaused: PropTypes.bool.isRequired,
};

ActiveCallElapsedTime.StatusText = Object.freeze({
  HOLD: 'On Hold:',
  LIVE: 'Live:',
  MUTE: 'Mute:',
  RECORDING_PAUSED: 'Recording Paused',
});

const ActiveCallElapsedTimeContainer = connect(mapStateToProps)(ActiveCallElapsedTime);
export default ActiveCallElapsedTimeContainer;

function mapStateToProps({ getProvider }) {
  let currentAgent = getProvider('currentAgent').get();
  let currentAgentId = currentAgent.id;
  let activeCall = getProvider('activeCall').get();
  let phoneCall = activeCall.conversationItem.content;

  return {
    answeredAt: phoneCall.answeredAt,
    isLiveCall: phoneCall.isLive(),
    isAgentMuted: phoneCall.isMuted(currentAgentId),
    isObserver: phoneCall.isParticipantObserver(currentAgentId),
    agentMutedAt: phoneCall.getParticipantUpdatedAt(currentAgentId),
    isCustomerOnHold: phoneCall.isOnHold(phoneCall.customerNumber),
    customerOnHoldAt: phoneCall.getParticipantUpdatedAt(phoneCall.customerNumber),
    isRecordingPaused: phoneCall.getLatestRecordingEventStatus() === PhoneCall.RecordingStatus.PAUSED,
  };
}
