import PropTypes from 'prop-types';
import React from 'react';

import ModalButton, { ModalButtonDivider } from 'components/modals/modal_button';
import ModalFooter from 'components/modals/modal_footer';
import InternalEmailIcon from './internal_email_icon';
import Modal from 'components/lib/modal';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';

function InternalEmailModal(props) {
  return (
    <PortalModalWrapper>
      <Modal className="internalEmailModal">
        <div className="internalEmailModal-iconContainer">
          <InternalEmailIcon />
        </div>
        <div className="internalEmailModal-header">Private Conversations</div>
        <div className="internalEmailModal-body">
          You may be including private conversations about {'\n'}
          <strong>{props.memberDescription}</strong> in this message
        </div>
        <ModalFooter data-aid="internalEmailModal-footer">
          <ModalButton className="internalEmailModal-keepButton" onClick={props.onKeepHistory}>
            Keep Original Message
          </ModalButton>
          <ModalButtonDivider />
          <ModalButton className="internalEmailModal-removeButton" onClick={props.onRemoveHistory}>
            Remove Original Message
          </ModalButton>
        </ModalFooter>
      </Modal>
    </PortalModalWrapper>
  );
}

InternalEmailModal.propTypes = {
  memberDescription: PropTypes.string.isRequired,
  onKeepHistory: PropTypes.func.isRequired,
  onRemoveHistory: PropTypes.func.isRequired,
};

export default InternalEmailModal;
