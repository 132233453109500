import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import FlexibleBlock from 'components/customer/flexible_profile_card/components/flexible_block';

export default function FlexibleBlockList({ configList, externalData, className }) {
  if (_.isEmpty(configList) || _.isEmpty(externalData)) {
    return null;
  }

  const blockListClassNames = classNames('flexible-block-list', className);
  return (
    <div className={blockListClassNames}>
      {_.map(configList, (attrConfig, key) => {
        return <FlexibleBlock className={className} config={attrConfig} customAttributes={externalData} key={key} />;
      })}
    </div>
  );
}

FlexibleBlockList.propTypes = {
  configList: PropTypes.array.isRequired,
  externalData: PropTypes.object,
  className: PropTypes.string,
};
