import React from 'react';

import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ExternalLink from 'components/lib/external_link';
import GeneralTransaction from 'components/customer/summary/transactions/general_transaction';
import {
  FlightTransactionDef,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
} from 'models/configuration/transaction_def';
import ProfileCardType from 'models/configuration/profile_card_type';

export default function TransactionGroup({
  groupClassName,
  groupName,
  headerRenderer,
  shouldExpandFirst,
  shouldRenderImages,
  summaryRenderer,
  transactions,
  transactionDef,
  onExpand,
  type,
}) {
  if (!transactions || transactions.length === 0) {
    return null;
  }

  let urlAttribute = transactionDef && transactionDef.urlAttribute;
  let url = _.get(
    urlAttribute ? _.find(transactions, t => !!t.attributes[urlAttribute]) : undefined,
    `attributes.${urlAttribute}`
  );

  const classNames = classnames('transactionGroup-header', groupClassName);
  const groupNameLink = groupName ? (
    <div className={classNames}>
      <ExternalLink
        attrName={urlAttribute}
        className={classnames('transactionGroup-externalLink', 'transactionGroup-name')}
        subType={type}
        text={groupName}
        type={ProfileCardType.TRANSACTIONS}
        url={url}
      />
    </div>
  ) : null;
  const renderedTransactions = _.map(transactions, (t, idx) => (
    <GeneralTransaction
      attributes={t.attributes}
      headerRenderer={headerRenderer}
      isExpanded={shouldExpandFirst ? idx === 0 : false}
      key={t.id}
      onExpand={onExpand}
      shouldRenderImages={shouldRenderImages}
      summaryRenderer={summaryRenderer}
      transactionDef={transactionDef}
    />
  ));

  return (
    <div className="transactionGroup">
      {groupNameLink}
      {renderedTransactions}
    </div>
  );
}

TransactionGroup.propTypes = {
  onExpand: PropTypes.func,
  groupClassName: PropTypes.string,
  groupName: PropTypes.string,
  headerRenderer: PropTypes.func,
  shouldExpandFirst: PropTypes.bool,
  shouldRenderImages: PropTypes.bool,
  summaryRenderer: PropTypes.func,
  transactionDef: PropTypes.oneOfType([
    PropTypes.instanceOf(FlightTransactionDef),
    PropTypes.instanceOf(OrderTransactionDef),
    PropTypes.instanceOf(StayTransactionDef),
    PropTypes.instanceOf(SubscriptionTransactionDef),
  ]).isRequired,
  transactions: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.string,
};
