import React from 'react';
import styled, { css } from 'styled-components';

export const Menu = styled.div`
  display: flex;
  gap: 4px;
  position: relative;
`;

export const SeparatorOuter = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 24px;
  justify-content: center;
  padding: 0;
`;

export const SeparatorInner = styled.div`
  border-left: 1px solid ${p => p.theme.colors.gray300};
  height: 24px;
  width: 1px;
`;

export function Separator() {
  return (
    <SeparatorOuter>
      <SeparatorInner />
    </SeparatorOuter>
  );
}

const preventBlurring = evt => evt.preventDefault();

export const Item = styled.span.attrs({ onMouseDown: preventBlurring })`
  align-items: center;
  border: 1px solid ${p => p.theme.colors.white};
  border-radius: 4px;
  color: ${p => p.theme.colors.gray900};
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  height: 25px;
  justify-content: center;
  margin-bottom: 0;
  text-align: center;
  width: 25px;

  &.disabled {
    cursor: default;
  }
  &:hover:not(.disabled) {
    border: 1px solid ${p => p.theme.colors.gray200};
  }
  & .placeholderIcon {
    fill: ${p => p.theme.colors.gray900};
    height: 14px;
    width: 14px;
  }
  ${p => p.isActive && activeItem};
`;

const activeItem = css`
  border: 1px solid ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.green400};
  &:hover {
    border: 1px solid ${p => p.theme.colors.green400};
  }
  & .placeholderIcon {
    fill: ${p => p.theme.colors.green400};
  }
  & .quoteIcon {
    fill: ${p => p.theme.colors.green400};
  }
`;

export const ExpandableItem = styled(Item)`
  width: 40px;
`;
