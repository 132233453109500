import { SnippetContentType } from 'models/answers/snippet';

export default function addSnippetAttachmentsToComposition({ defaultBody, composition, attachSnippetAttachments }) {
  if (!composition || !attachSnippetAttachments) {
    return;
  }
  Object.keys(defaultBody).forEach(key => {
    const answer = defaultBody[key];
    if (!answer) {
      return;
    }
    answer.contents.forEach(content => {
      const snippet = content[SnippetContentType.ANY_CHANNEL];
      if (!snippet) {
        return;
      }
      snippet.attachments.forEach(attachment => {
        if (!composition.findAttachmentById(attachment.id)) {
          attachSnippetAttachments({
            compositionId: composition.id,
            contentChannelType: SnippetContentType.ANY_CHANNEL,
            language: answer.language,
            snippetItem: answer,
          });
        }
      });
    });
  });
}
