import _ from 'lodash';

import connect from 'components/lib/connect';
import StationPreferenceMenu from './station_preference_menu';
import UpdateCurrentAgentProfile from 'actions/current_agent/update_current_agent_profile';
import ClearCurrentAgentProfileErrors from 'actions/current_agent/clear_current_agent_profile_errors';

const mapStateToProps = ({ getProvider }, props) => {
  // current agent
  const currentAgentProvider = getProvider('currentAgent');
  const currentAgent = currentAgentProvider.get();
  const isPending = currentAgentProvider.isPending();
  const appFeatures = getProvider('appFeatures').get();
  const isDirectDialEnabled = appFeatures.isEnabled('directDial');
  const agentProfileErrors = currentAgentProvider.getErrors();
  const error = getErrorByAttribute('phone');

  // stations
  const stationConfigurations = getProvider('stationConfigurations').findAll();
  let stations = _.map(stationConfigurations, station => {
    return { label: station.name, value: station.address, type: station.type };
  });

  return {
    currentAgent,
    error,
    isDirectDialEnabled,
    isPending,
    stations,
    ...props,
  };

  function getErrorByAttribute(attr) {
    let attrErrors = _.filter(agentProfileErrors, e => e.attr === attr);
    return attrErrors.length ? attrErrors[0].detail : null;
  }
};

const mapExecuteToProps = executeAction => {
  return {
    onClearProfileErrors: () => executeAction(ClearCurrentAgentProfileErrors),
    onProfileUpdate: attrs => executeAction(UpdateCurrentAgentProfile, attrs),
  };
};

export default connect(mapStateToProps, mapExecuteToProps)(StationPreferenceMenu);
