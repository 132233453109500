import Assignee from 'models/assignee';
import addCustomerToInbox from 'actions/inbox/lib/add_customer_to_inbox';
import removeItemFromInbox from 'actions/inbox/lib/remove_item_from_inbox';
import ConversationUpdater from 'actions/conversation/lib/conversation_updater';
import RoutingItemType from 'models/routing_item_type';
import Communicator from 'models/communicator';

export default class AssignConversation extends ConversationUpdater {
  run(attrs) {
    let conversation = this.context.stores.conversations.find(attrs.conversationId);

    if (isSameAssignee(conversation.assignee, attrs.assignee)) {
      return;
    }

    let newAssigneeId = attrs.assignee.agentId;
    let newAssignee;
    if (!newAssigneeId) {
      newAssignee = new Assignee({ routingGroupId: attrs.assignee.routingGroupId, agentId: '' });
    } else {
      newAssignee = new Assignee({ routingGroupId: attrs.assignee.routingGroupId, agentId: newAssigneeId });
    }

    if (newAssigneeId && newAssigneeId === this.currentAgent.id) {
      addCustomerToInbox(this.context, { conversationId: conversation.id, customerId: this.currentCustomerId });
    } else {
      removeItemFromInbox(this.context, this.currentCustomerId);
    }

    this.updateConversation(conversation.id, {
      assignee: {
        ...newAssignee,
        routingItemType: RoutingItemType.MANUAL_ASSIGNMENT,
        initiator: {
          id: this.currentAgent.id,
          type: Communicator.AGENT,
        },
      },
    });
  }
}

function isSameAssignee(assigneeWas, newAssignee) {
  return (
    (!newAssignee.agentId && !assigneeWas.agentId && assigneeWas.routingGroupId === newAssignee.routingGroupId) ||
    (assigneeWas.agentId === newAssignee.agentId && assigneeWas.routingGroupId === newAssignee.routingGroupId)
  );
}
