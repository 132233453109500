import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useCallback } from 'react';

import { isValidPhoneNumber, normalizePhoneNumber } from 'models/phone_number';
import connect from 'components/lib/connect';
import CreateExternalPhoneCallComposition from 'actions/composition/create_external_phone_call_composition';
import { isVoiceEnabled } from 'components/lib/voice/is_voice_enabled';
import PopoverMenu, { PopoverMenuItem } from 'components/common/popover_menu_deprecated';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useCopied from 'components/hooks/use_copied';
import { useTextEditorContext } from 'components/text_editor/text_editor_context';

export function PhoneNumberHighlightMenuBase({ agentHasActiveCall, isVoiceEnabled }) {
  const [showCopied, onCopy] = useCopied();
  const { phoneNumberHighlight, onClickOutside } = usePhoneNumberHighlight();
  const [onClickCall, callableNumber] = usePhoneNumber(phoneNumberHighlight, onClickOutside);

  if (!phoneNumberHighlight) {
    return null;
  }

  // Find the element in the DOM pertaining to the current highlight range
  const element = document.querySelector(
    `[data-aid="phone-number-highlight"][data-key="${phoneNumberHighlight.key}"][data-start="${phoneNumberHighlight.start}"]`
  );
  if (!element) {
    return null;
  }

  const copiedText = showCopied ? 'Copied!' : 'Copy to clipboard';
  const copyClassNames = classnames('draftEditor-inlinePhoneNumber-copy', {
    'draftEditor-inlinePhoneNumber-copy-copied': showCopied,
  });

  let menuItems = [
    <PopoverMenuItem key="copy">
      <CopyToClipboard onCopy={onCopy} text={callableNumber}>
        <div className={copyClassNames}>{copiedText}</div>
      </CopyToClipboard>
    </PopoverMenuItem>,
  ];
  if (isVoiceEnabled) {
    menuItems = [
      <PopoverMenuItem isDisabled={agentHasActiveCall} key="call" onClick={onClickCall}>
        Make new outgoing call
      </PopoverMenuItem>,
      ...menuItems,
    ];
  }

  return (
    <PopoverMenu
      autoPosition
      boundByWindow={false}
      button={element}
      isOpen
      onClickOutside={onClickOutside}
      position="top"
      style={{ zIndex: 21 }}
    >
      {menuItems}
    </PopoverMenu>
  );
}

const PhoneNumberHighlightMenu = connect(mapStateToProps)(PhoneNumberHighlightMenuBase);

function mapStateToProps(context, props) {
  return {
    ...props,
    agentHasActiveCall: !!context.getProvider('activeCall').get(),
    isVoiceEnabled: isVoiceEnabled(context),
  };
}

export default PhoneNumberHighlightMenu;

function usePhoneNumber(range, onClick) {
  const unformattedNumber = range ? range.text : '';

  let callableNumber;
  if (isValidPhoneNumber(unformattedNumber)) {
    callableNumber = unformattedNumber;
  } else if (isValidPhoneNumber(`+${unformattedNumber}`)) {
    callableNumber = `+${unformattedNumber}`;
  }

  const executeAction = useExecuteAction();
  const onClickCall = useCallback(() => {
    callableNumber && executeAction(CreateExternalPhoneCallComposition, { phoneNumber: callableNumber });
    onClick();
  }, [callableNumber]);

  const normalized = (callableNumber && normalizePhoneNumber(callableNumber)) || '';
  return [onClickCall, normalized];
}

function usePhoneNumberHighlight() {
  const { data, setDataValue } = useTextEditorContext();

  const onClickOutside = useCallback(() => {
    setDataValue('phoneNumberHighlight', null);
  }, [setDataValue]);

  return {
    phoneNumberHighlight: data.phoneNumberHighlight,
    onClickOutside,
  };
}
