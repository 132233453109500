import React from 'react';

export const MENTION_TEXT = 'mentionText';

export default function Mentions() {
  return {
    commands: { removeMention, startMention, updateMention },
    queries: { getMentionText },

    onKeyDown(evt, editor, next) {
      if (evt.key !== 'Backspace') return next();

      const { value } = editor;
      let decorations = value.decorations.filter(decoration => decoration.mark.type === MENTION_TEXT);
      if (decorations.size) {
        let decoration = decorations.get(0);
        let fragment = value.document.getFragmentAtRange({ anchor: decoration.anchor, focus: decoration.focus });
        // when deleting either the answer '+' or variable '/' activation character, remove the mention decoration
        if (fragment.text.length === 1) {
          editor.removeMention();
        }
      }

      return next();
    },

    renderMark(props, editor, next) {
      const { mark, children, attributes } = props;
      if (mark.type === MENTION_TEXT) {
        return (
          <span className="draftEditor-mention" {...attributes}>
            {children}
          </span>
        );
      }
      return next();
    },
  };
}

export function updateMention(editor, mentionText) {
  const { value } = editor;
  const { selection } = value;

  let decorations = value.decorations.filter(decoration => decoration.mark.type !== MENTION_TEXT);
  decorations = decorations.push({
    anchor: {
      key: selection.start.key,
      offset: selection.start.offset - mentionText.length - 1,
    },
    focus: {
      key: selection.start.key,
      offset: selection.start.offset,
    },
    mark: {
      type: MENTION_TEXT,
    },
  });

  // Make the change to decorations without saving it into the undo history,
  // so that there isn't a confusing behavior when undoing.
  editor.withoutSaving(() => {
    editor.setDecorations(decorations);
  });
}

export function removeMention(editor) {
  const value = editor.value;
  let decorations = value.decorations.filter(decoration => decoration.mark.type !== MENTION_TEXT);
  editor.setDecorations(decorations);
}

export function startMention(editor, character) {
  editor.insertText(character);

  const { value } = editor;
  let { selection, decorations } = value;
  let decoration = {
    anchor: { key: selection.start.key, offset: selection.start.offset - 1 },
    focus: { key: selection.start.key, offset: selection.start.offset },
    mark: { type: MENTION_TEXT },
  };

  decorations = decorations.push(decoration);
  editor.setDecorations(decorations);
}

export function getMentionText(editor, character) {
  const value = editor.value;
  let mention = value.decorations.filter(decoration => decoration.mark.type === MENTION_TEXT);
  if (mention.size === 0) return null; // if a mention has not been started, there is no mention text
  if (!value.startText) return null;

  let decoration = mention.get(0);
  let fragment = value.document.getFragmentAtRange({ anchor: decoration.anchor, focus: decoration.focus });
  let text = fragment.text;

  if (text.slice(0, 1) !== character) {
    return null;
  }

  return text.length === 1 ? '' : text.slice(1);
}
