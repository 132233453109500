import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import virtualized from 'components/lib/virtualized';

export class DebouncedAutosizerCache extends React.Component {
  constructor(props) {
    super(props);
    const { height, width } = props;

    this.state = {
      height,
      width,
    };

    this.setSizeFromProps = _.throttle(this.setSizeFromProps, 300);
  }

  componentDidUpdate() {
    if (this.props.height !== this.state.height || this.props.width !== this.state.width) {
      this.setSizeFromProps();
    }
  }

  setSizeFromProps() {
    this.setState({
      height: this.props.height,
      width: this.props.width,
    });
  }

  render() {
    const { height, width } = this.state;
    return this.props.children({ height, width });
  }
}

DebouncedAutosizerCache.propTypes = {
  children: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default function DebouncedAutosizer({ children }) {
  return (
    <virtualized.AutoSizer>
      {({ height, width }) => (
        <DebouncedAutosizerCache height={height} width={width}>
          {children}
        </DebouncedAutosizerCache>
      )}
    </virtualized.AutoSizer>
  );
}

DebouncedAutosizer.propTypes = {
  children: PropTypes.func.isRequired,
};
