import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import ChangeAssignment from 'actions/conversation/change_assignment';
import connect from 'components/lib/connect';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import UpdateCustomerAssignee from 'actions/customer/update_customer_assignee';

export function CustomerAssignmentButtonOptions({ hasActiveConversation, onAssignConversation, onAssignCustomer }) {
  return (
    <div className="customerAssignmentButtonOptions-buttons">
      <Button
        buttonType={ButtonTypes.SECONDARY}
        className="customerAssignmentButtonOptions-button-assignCustomer"
        onClick={onAssignCustomer}
      >
        Make Hero
      </Button>
      <Button
        buttonType={ButtonTypes.SECONDARY}
        className="customerAssignmentButtonOptions-button-assignConversation"
        disabled={!hasActiveConversation}
        onClick={hasActiveConversation ? onAssignConversation : _.noop}
      >
        Assign
      </Button>
    </div>
  );
}

CustomerAssignmentButtonOptions.propTypes = {
  hasActiveConversation: PropTypes.bool,
  onAssignConversation: PropTypes.func,
  onAssignCustomer: PropTypes.func,
};

const CustomerAssignmentButtonOptionsContainer = connect(
  mapStateToProps,
  mapExecuteToProps
)(CustomerAssignmentButtonOptions);

CustomerAssignmentButtonOptionsContainer.propTypes = {
  agentId: PropTypes.string,
  onClose: PropTypes.func,
  routingGroupId: PropTypes.string,
};

function mapStateToProps({ getProvider }, props) {
  const activeConversationId = getActiveConversationId(getProvider('conversations'));

  return {
    hasActiveConversation: !!activeConversationId,
  };
}

function mapExecuteToProps(executeAction, { agentId, routingGroupId, onClose }) {
  return {
    onAssignConversation: () => {
      executeAction(ChangeAssignment, {
        assignee: { agentId, routingGroupId },
      });
      if (onClose) {
        onClose();
      }
    },
    onAssignCustomer: () => {
      executeAction(UpdateCustomerAssignee, { agentId, routingGroupId });
      if (onClose) {
        onClose();
      }
    },
  };
}

export default CustomerAssignmentButtonOptionsContainer;
