import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import ConversationTopicsActionConnectorV2 from 'components/customer/conversation_history/topics_v2/conversation_topics_action_connector_v2';

function mapStateToProps({ getProvider, isFeatureEnabled }, { conversationId, customerId }) {
  const conversationsProvider = getProvider('conversations');
  const customAttributesProvider = getProvider('customAttributes');
  const topicsProvider = getProvider('topics');
  const topicSuggestionsProvider = getProvider('topicSuggestions');

  const agentAssistanceConfig = getProvider('agentAssistanceConfig').get();
  const lastOpenedAt = getProvider('conversationWorkflow').get().topicsLastOpenedAt;

  let topicSuggestions;
  if (agentAssistanceConfig.isEnabled('topicSuggestions')) {
    topicSuggestions = topicSuggestionsProvider.findBy({
      customerId,
      conversationId,
    });
  }

  return {
    conversation: conversationsProvider.find(conversationId),
    customAttributesImmutable: customAttributesProvider.immutableStore.binding.get(),
    customAttributesProvider,
    customerId,
    getTopic: id => topicsProvider.findBy({ id }),
    lastOpenedAt,
    topicsImmutable: topicsProvider.immutableStore.binding.get(),
    topicsProvider,
    topicSuggestions,
  };
}

const ConversationTopicsContainerV2 = connect(mapStateToProps)(ConversationTopicsActionConnectorV2);

ConversationTopicsContainerV2.propTypes = {
  conversationId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default ConversationTopicsContainerV2;
