import Communicator from 'models/communicator';
import Factory from 'factories/all';

export default function createIncomingNote(incomingNote, agent) {
  return Factory.build('ConversationNoteItem', {
    initiator: {
      type: Communicator.AGENT,
      id: incomingNote.agentId,
    },
    content: incomingNote.content,
  });
}
