import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import DropdownMenu from 'components/common/dropdown_menu';
import UpdateComposition from 'actions/composer/update_composition';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function ToField({ allFromAddresses, disabled }) {
  const { composition } = useContext(ComposerContext);
  const currentAddress = _.find(allFromAddresses, { id: composition.content.customerAddress });

  const executeAction = useExecuteAction();
  const onChange = customerAddress =>
    executeAction(UpdateComposition, { compositionId: composition.id, attrs: { customerAddress } });

  const options = _.map(allFromAddresses, address => ({
    label: address.displayName,
    value: address.id,
  }));

  return (
    <To>
      <Label>To</Label>
      <DropdownMenu
        data-aid="customChannel-toField"
        disabled={disabled}
        onSelect={onChange}
        options={options}
        text={currentAddress?.displayName}
        value={currentAddress?.id}
      />
    </To>
  );
}

ToField.propTypes = {
  allFromAddresses: PropTypes.arrayOf(PropTypes.object),
};

const To = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  color: ${p => p.theme.colors.gray500};
  margin-right: 8px;
`;
