import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import { H3 } from 'components/common/headers';
import { AdminListItem } from '../lib/admin_layout';
import SubmitInput from 'components/common/submit_input';
import KbVariable from 'models/kb_variable';

const VariablesAdminListItem = createReactClass({
  propTypes: {
    onChangeVariable: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    savePending: PropTypes.bool,
    variable: PropTypes.instanceOf(KbVariable).isRequired,
  },

  getInitialState() {
    return { defaultValue: this.props.variable.defaultValue };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.savePending && nextProps.variable.defaultValue !== this.state.defaultValue) {
      this.setState({ defaultValue: nextProps.variable.defaultValue });
    }
  },

  onChange(defaultValue) {
    this.setState({ defaultValue });
  },

  onSubmit() {
    this.props.onChangeVariable({
      id: this.props.variable.id,
      defaultValue: this.state.defaultValue,
    });
  },

  getPlaceholder() {
    return this.state.defaultValue ? '' : 'Enter a default value';
  },

  render() {
    let variable = this.props.variable;
    return (
      <AdminListItem className="variablesAdmin-list-item">
        <H3 data-aid="variablesAdmin-list-item-name">{variable.name}</H3>
        <div className="variablesAdmin-list-item-defaultValue">
          <SubmitInput
            className="variablesAdmin-list-item-defaultValue-input"
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            placeholder={this.getPlaceholder()}
            readOnly={this.props.readOnly}
            value={this.state.defaultValue || ''}
          />
        </div>
      </AdminListItem>
    );
  },
});

export default VariablesAdminListItem;
