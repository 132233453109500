import classnames from 'classnames';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import { getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';

const SendButton = connect(mapStateToProps)(Button);
SendButton.propTypes = Button.propTypes;
export default SendButton;

function mapStateToProps({ getProvider }, props) {
  let conversations = getProvider('conversations');
  let isPendingNew = conversations.isPendingNew();

  let latestConversationId = getLatestConversationId(conversations);
  let isLatestConversationPending = latestConversationId && conversations.isPending(latestConversationId);

  return {
    buttonType: ButtonTypes.PRIMARY,
    ...props,
    className: classnames('composition-send', props.className),
    disabled: props.disabled || isPendingNew || isLatestConversationPending,
  };
}
