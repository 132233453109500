import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';

import ClickToEditField from 'components/common/click_to_edit_field';
import MergeableErrorTooltip from './contact_info/mergeable_error_tooltip';
import CustomerPhoneDisplay from './customer_phone_display';
import CustomerPhoneNumberPropType from './customer_phone_number_prop_type';
import Err from 'models/err';
import ErrorTooltip from './contact_info/error_tooltip';
import Input from 'components/common/input';
import KeypressEventHandler from 'components/lib/dom_controls/lib/keypress_event_handler';
import { PhoneNumberType, SmsPreferenceType } from 'models/phone_number';
import PhoneTypeSelect from './phone_type_select';

const INPUT_SWITCH_TIMEOUT = 250;

// CustomerPhoneEditorBase is a "dumb" (unaware of Morearty bindings) react component.
// But it's actually pretty freaking smart in order to handle the nice blur-supporting UI.
export class CustomerPhoneEditorBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: props.errors,
      smsEnabled: props.smsEnabled,
      isFocused: !!props.errors,
      value: props.initialValue,
    };

    _.bindAll(this, [
      'handleBlur',
      'handleBlurAll',
      'handleCancel',
      'handleClearPhone',
      'handleExtensionChange',
      'handleFocus',
      'handlePhoneNumberChange',
      'handlePhoneTypeChange',
      'handlePhoneTypeOpenMenu',
      'handlePrimaryToggle',
      'handleSmsToggle',
      'handleSubmit',
    ]);

    this.submitTimeout = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.initialValue, this.props.initialValue)) {
      this.setState({ value: nextProps.initialValue });
    }

    if (nextProps.errors) {
      this.setState({ isFocused: true });
    }
  }

  componentDidMount() {
    if (this.props.autoFocus === 'phoneType') {
      this.phoneTypeSelect.open();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoFocus && !prevProps.autoFocus) {
      this.phoneNumberInput.focusAtEnd();
    }
  }

  render() {
    const rowClassNames = this.getRowClassNames();
    const smsButtonClassNames = classnames(
      'customerProfile-contactDetails-phone-smsPreference-button',
      'customerProfile-contactDetails-attrib-button',
      {
        'customerProfile-contactDetails-attrib-button-active':
          !this.isSmsButtonDisabled() && this.state.value.smsPreference === SmsPreferenceType.ON,
        'customerProfile-contactDetails-attrib-button-disabled': this.isSmsButtonDisabled(),
      }
    );
    const primaryButtonClassNames = classnames(
      'customerProfile-contactDetails-phone-primary-button',
      'customerProfile-contactDetails-attrib-button',
      {
        'customerProfile-contactDetails-attrib-button-active':
          !this.isPrimaryButtonDisabled() && this.state.value.isPrimary,
        'customerProfile-contactDetails-attrib-button-disabled': this.isPrimaryButtonDisabled(),
      }
    );
    const formKeypressHandler = new KeypressEventHandler(this.handleSubmit, this.handleCancel, { isInputField: true });
    const smsButtonKeypressHandler = new KeypressEventHandler(_.noop, _.noop, {
      isInputField: true,
      toggleFn: this.handleSmsToggle,
    });
    const primaryButtonKeypressHandler = new KeypressEventHandler(_.noop, _.noop, {
      isInputField: true,
      toggleFn: this.handlePrimaryToggle,
    });

    return (
      <div className="customerProfile-contactDetails-container">
        <form {...formKeypressHandler.reactAttributes()} onBlur={this.handleBlur} onFocus={this.handleFocus}>
          <div className={rowClassNames}>
            {this.getPhoneInput()}
            {this.state.value.type === PhoneNumberType.OFFICE ? (
              <ErrorTooltip
                className="customerProfile-contactInput-phoneExtension"
                errors={this.getFieldErrors('extension')}
              >
                <Input
                  className="editableField customerProfile-contactInput customerProfile-extension"
                  disabled={this.props.isDisabled}
                  onChange={this.handleExtensionChange}
                  placeholder="Ext."
                  value={this.state.value.extension || ''}
                />
              </ErrorTooltip>
            ) : null}
            {this.props.smsEnabled && this.state.value.type === PhoneNumberType.MOBILE ? (
              <ErrorTooltip errors={this.getFieldErrors('smsPreference')}>
                <div
                  className={smsButtonClassNames}
                  onBlur={this.onBlur}
                  onClick={this.handleSmsToggle}
                  onFocus={this.onFocus}
                  tabIndex="0"
                  title="SMS"
                  {...smsButtonKeypressHandler.reactAttributes()}
                >
                  SMS
                </div>
              </ErrorTooltip>
            ) : null}
            <ErrorTooltip errors={this.getFieldErrors('isPrimary')}>
              <div
                className={primaryButtonClassNames}
                onBlur={this.onBlur}
                onClick={this.handlePrimaryToggle}
                onFocus={this.onFocus}
                tabIndex="0"
                title="Main"
                {...primaryButtonKeypressHandler.reactAttributes()}
              >
                Main
              </div>
            </ErrorTooltip>
          </div>
          <PhoneTypeSelect
            isDisabled={this.props.isDisabled}
            isEditing
            onChange={this.handlePhoneTypeChange}
            onOpen={this.handlePhoneTypeOpenMenu}
            ref={node => (this.phoneTypeSelect = node)}
            value={this.state.value.type}
          />
        </form>
      </div>
    );
  }

  getPhoneInput() {
    const phoneInput = (
      <Input
        autoFocus={!!this.props.autoFocus}
        className="editableField customerProfile-contactInput customerProfile-phone"
        data-aid="customer-phone-input"
        disabled={this.props.isDisabled}
        onChange={this.handlePhoneNumberChange}
        placeholder="Phone"
        ref={node => (this.phoneNumberInput = node)}
        value={this.state.value.phoneNumber || ''}
      />
    );

    if (this.state.value.type === PhoneNumberType.MOBILE) {
      return (
        <MergeableErrorTooltip
          attr="phone"
          className="customerProfile-contactInput-phone-wrapper"
          customerId={this.props.customerId}
          data-aid="contactField-errorTooltip"
          errors={this.getFieldErrors('phoneNumber').concat(this.getFieldErrors('type'))}
          margin={48}
          onClear={this.handleClearPhone}
          value={this.state.value.phoneNumber || ''}
        >
          {phoneInput}
        </MergeableErrorTooltip>
      );
    }

    return (
      <ErrorTooltip
        className="customerProfile-contactInput-phone-wrapper"
        errors={this.getFieldErrors('phoneNumber').concat(this.getFieldErrors('type'))}
      >
        {phoneInput}
      </ErrorTooltip>
    );
  }

  getFieldErrors(fieldName) {
    return (_.get(this.props.errors, fieldName) && [this.props.errors[fieldName]]) || [];
  }

  getRowClassNames() {
    return classnames(
      'customerProfile-contactDetails-row',
      'customerProfile-contactDetails-row-phone',
      'customerProfile-contactDetails-row-editing',
      this.props.className,
      { 'customerProfile-contactDetails-row-focused': this.props.errors || this.state.isFocused }
    );
  }

  isPrimaryButtonDisabled() {
    return this.props.isDisabled || !this.state.value.phoneNumber;
  }

  isSmsButtonDisabled() {
    return (
      this.props.isDisabled ||
      !this.state.value.phoneNumber ||
      this.state.value.smsPreference === SmsPreferenceType.FORBIDDEN
    );
  }

  handleBlur() {
    this.setState({ isFocused: false });

    // handleBlurAll() is called either when we blur an input field.. since *could* be blurring one input to focus
    // on the other, we setTimeout here in case we focus on the other so that we avoid submitting until the user
    // is completely done.
    this.props.clearTimeout(this.submitTimeout);
    this.submitTimeout = this.props.setTimeout(() => this.handleBlurAll(), INPUT_SWITCH_TIMEOUT);
  }

  handleBlurAll() {
    if (this.props.onBlur) {
      this.props.onBlur(this.state.value);
    }
  }

  handleCancel(evt) {
    if (this.props.isDisabled) {
      return;
    }

    evt.target.blur();

    this.setState({
      isFocused: false,
      value: this.props.initialValue,
    });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleClearPhone() {
    if (this.props.isDisabled) {
      return;
    }
    const newValue = { ...this.state.value, phoneNumber: '' };
    this.setState({ isFocused: false, value: newValue });
    this.props.onSubmit && this.props.onSubmit(newValue);
  }

  handleExtensionChange(evt) {
    this.mergeStateValue({ extension: evt.target.value });
  }

  handleFocus() {
    this.setState({ isFocused: true });
    this.props.clearTimeout(this.submitTimeout);
    this.submitTimeout = null;
  }

  handlePhoneNumberChange(evt) {
    this.mergeStateValue({ phoneNumber: evt.target.value });
  }

  handlePhoneTypeChange(type) {
    this.mergeStateValue({
      extension: type !== PhoneNumberType.OFFICE ? '' : this.state.value.extension,
      smsPreference: type !== PhoneNumberType.MOBILE ? SmsPreferenceType.OFF : this.state.value.smsPreference,
      type,
    });

    setImmediate(() => this.phoneNumberInput && this.phoneNumberInput.focusAtEnd());
  }

  handlePhoneTypeOpenMenu() {
    this.props.clearTimeout(this.submitTimeout);
    this.phoneNumberInput && this.phoneNumberInput.focusAtEnd();
  }

  handlePrimaryToggle(evt) {
    evt.preventDefault(); // prevent scrolling when pressing space

    if (this.isPrimaryButtonDisabled()) {
      return;
    }

    this.mergeStateValue({ isPrimary: !this.state.value.isPrimary });
  }

  handleSmsToggle(evt) {
    evt.preventDefault(); // prevent scrolling when pressing space

    if (this.isSmsButtonDisabled()) {
      return;
    }

    const updatedSmsPref =
      this.state.value.smsPreference === SmsPreferenceType.ON ? SmsPreferenceType.OFF : SmsPreferenceType.ON;
    this.mergeStateValue({ smsPreference: updatedSmsPref });
  }

  handleSubmit(evt) {
    evt.preventDefault(); // prevent reload on Enter

    if (this.props.isDisabled) {
      return;
    }

    if (!this.props.onSubmit || _.isEqual(this.state.value, this.props.initialValue)) {
      evt.target.blur();
      return;
    }

    let submitValue = { isPrimary: false, ..._.omitBy(this.state.value, _.isUndefined) };
    this.props.onSubmit(submitValue);
  }

  mergeStateValue(newValue) {
    this.setState({ value: { ...this.state.value, ...newValue } });
  }
}

export const CustomerPhoneEditor = ReactTimeout(CustomerPhoneEditorBase);

CustomerPhoneEditor.propTypes = {
  autoFocus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  errors: PropTypes.shape({
    extension: PropTypes.instanceOf(Err),
    isPrimary: PropTypes.instanceOf(Err),
    phoneNumber: PropTypes.instanceOf(Err),
    smsPreference: PropTypes.instanceOf(Err),
  }),
  initialValue: CustomerPhoneNumberPropType,
  isDisabled: PropTypes.bool,
  smsEnabled: PropTypes.bool,

  onBlur: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

CustomerPhoneEditorBase.propTypes = {
  ...CustomerPhoneEditor.propTypes,
  clearTimeout: PropTypes.func.isRequired, // required by ReactTimeout
  setTimeout: PropTypes.func.isRequired, // required by ReactTimeout
};

export default ClickToEditField(CustomerPhoneDisplay, CustomerPhoneEditor);
