import React from 'react';
import PropTypes from 'prop-types';

import AgentMetadata from '../metadata/agent_metadata';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import PrepositionalPhrase from '../metadata/prepositional_phrase';
import RuleLink from '../metadata/rule_link';

export function NoReplyNeededItem({ item }) {
  const isAgentInitiated = item.initiator.type === Communicator.AGENT;

  return (
    <ItemContent isAuditItem item={item}>
      {isAgentInitiated ? renderAgentNRNMessage() : renderRuleNRNMessage()}
    </ItemContent>
  );

  function renderAgentNRNMessage() {
    return <AgentMetadata item={item} text="marked No Reply Needed. Due date has been cleared" />;
  }

  function renderRuleNRNMessage() {
    return (
      <Metadata
        isInbound={false}
        prepositionalPhrases={getRuleNRNPrepositionalPhrase()}
        text="Marked No Reply Needed"
        timestamp={item.timestamp}
      />
    );
  }

  function getRuleNRNPrepositionalPhrase() {
    let ruleComplement = <RuleLink ruleId={item.initiator.id}>rule</RuleLink>;

    return <PrepositionalPhrase complement={ruleComplement} key="by" preposition={'by'} />;
  }
}

NoReplyNeededItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function mapStateToProps({ isFeatureEnabled }, { item }) {
  return {
    item,
  };
}

const NoReplyNeededItemContainer = connect(mapStateToProps, null)(NoReplyNeededItem);

NoReplyNeededItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default NoReplyNeededItemContainer;
