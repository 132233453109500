import AudioFactory from 'scripts/application/lib/audio_factory';
import { NotificationSoundType } from 'actions/notification/lib/notification_constants';

export const LastPlayed = {};

export default function playNotificationSound(type) {
  let soundSrc = getSoundUrlByType(type);
  if (soundSrc) {
    let audio = AudioFactory.create();
    audio.src = soundSrc;
    audio.play();
  }
}

function getSoundUrlByType(type) {
  switch (type) {
    case NotificationSoundType.AGENT_NOTIFICATION:
      return '/audio/notification_sound.mp3';
    case NotificationSoundType.DECLINED_SESSION_REASSIGNMENT:
    case NotificationSoundType.INBOUND_MESSAGE:
    case NotificationSoundType.PAYMENT_STATUS_EVENT:
      return '/audio/cuteclicks.mp3';
    default:
      return undefined;
  }
}
