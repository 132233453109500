import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import { iconClassNameForItem } from './metadata_icon_classes';
import Metadata from './metadata';
import RuleLink from '../metadata/rule_link';
import { typeReflect } from 'models/conversation_item/content_from_js';

export function AutomatedMetadata({
  hasError,
  icon,
  iconClass,
  isRulesManagementEnabled,
  item,
  prepositionalPhrases,
  status,
}) {
  iconClass = iconClass || iconClassNameForItem(item);

  if (typeReflect.instanceToType(item.content) === ConversationItemType.TASK) {
    let metadataText = (
      <span>
        created by{' '}
        <RuleLink className="itemMetadata-link" ruleId={item.initiator.id}>
          rule
        </RuleLink>
      </span>
    );

    return (
      <Metadata
        hasError={hasError}
        icon={icon}
        iconClass={iconClass}
        isInbound={false}
        prepositionalPhrases={prepositionalPhrases}
        status={status}
        subject={'Task'}
        text={metadataText}
        timestamp={item.timestamp}
      />
    );
  }

  let metadataText = 'sent';
  if (isRulesManagementEnabled) {
    metadataText = (
      <span>
        sent by{' '}
        <RuleLink className="itemMetadata-link" ruleId={item.initiator.id}>
          rule
        </RuleLink>
      </span>
    );
  }

  return (
    <Metadata
      hasError={hasError}
      icon={icon}
      iconClass={iconClass}
      isInbound={false}
      prepositionalPhrases={prepositionalPhrases}
      status={status}
      subject="Autoreply"
      text={metadataText}
      timestamp={item.timestamp}
    />
  );
}

AutomatedMetadata.propTypes = {
  hasError: PropTypes.bool,
  icon: PropTypes.node,
  iconClass: PropTypes.string,
  isRulesManagementEnabled: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  prepositionalPhrases: PropTypes.node,
  status: PropTypes.string,
  subject: PropTypes.string,
  text: PropTypes.any,
};

function mapStateToProps({ isFeatureEnabled }, props) {
  return {
    ...props,
    isRulesManagementEnabled: isFeatureEnabled('rulesManagement'),
  };
}

const AutomatedMetadataContainer = connect(mapStateToProps, null)(AutomatedMetadata);
AutomatedMetadataContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default AutomatedMetadataContainer;
