import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import AgentInboxObserver from 'actions/inbox/agent/agent_inbox_observer';
import { isSubscribedToItem } from 'actions/inbox/agent/agent_inbox_subscriptions';
import qconsole from 'scripts/lib/qconsole';
import { subscribeToCustomer, unsubscribe } from 'actions/inbox/agent/request_agent_inbox_deprecated';

// Add a "placeholder" inbox item that doesn't have any data in it yet for a given customerId.
// We'll fill in these pieces of information as we receive the fetch responses from the backend
// in tryUpdateInboxItem().
export default function addCustomerPlaceholderToInbox(context, customerId, store) {
  const existingItem = store.findBy({ id: customerId });
  if (existingItem) {
    return;
  }

  let newInboxItem = new AgentInboxItem({
    id: customerId,
    type: AgentInboxItem.Type.CUSTOMER,
  });
  newInboxItem.setManuallyCreatedAtToNow();
  store.add(newInboxItem);

  if (!isSubscribedToItem(newInboxItem)) {
    const observer = new AgentInboxObserver(context);
    const auth = context.stores.auth.get();
    const orgId = auth.getOrgId();

    subscribeToCustomer(context.backend, observer, orgId, customerId).catch(err => {
      unsubscribe(context.backend, customerId);
      qconsole.error(err);
    });
  }
}
