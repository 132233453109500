import _ from 'lodash';
import { useContext, useEffect, useRef } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import { insertAnswer } from 'components/text_editor/plugins/answers';
import { isEditorEmpty } from 'components/text_editor/helpers';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import { useVariables } from 'components/composer/contexts/variables_context';

export default function useInsertSubject({ editorRef, onChange }) {
  const { answerToInsert } = useContext(ComposerContext);
  const variables = useVariables();
  const prevAnswerRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && answerToInsert !== prevAnswerRef.current) {
      prevAnswerRef.current = answerToInsert;

      if (answerToInsert && isEditorEmpty(editorRef.current.value)) {
        const channelType = answerToInsert.channelType;
        const item = answerToInsert.item;
        let content = item.findContentByLanguage(answerToInsert.language);
        const subjectHtml = channelType && _.get(content, 'anyChannel.subject');
        if (subjectHtml) {
          insertAnswer(editorRef.current, FullSerializer, subjectHtml, variables, false);
          onChange(editorRef.current);
        }
      }
    }
  }, [answerToInsert, editorRef.current, onChange]);
}
