import { Selection } from 'slate-old';
import { SnippetContentType } from 'models/answers/snippet';

import { findFirstPlaceholderNode } from 'components/text_editor/plugins/placeholders';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import { insertAnswer } from 'components/text_editor/plugins/answers';

export default function createEmailTemplate(editor, defaultBody, variables) {
  const { greetingSnippet, signatureSnippet } = defaultBody;

  let selection = null;

  if (greetingSnippet) {
    let content = greetingSnippet.findContentByLanguage();
    const greetingBodyHtml = content.getBodyByType(SnippetContentType.ANY_CHANNEL);

    if (greetingBodyHtml) {
      insertAnswer(editor, FullSerializer, `${greetingBodyHtml}`, variables, false);
      editor.insertBlock({ type: 'paragraph' });
      editor.insertBlock({ type: 'paragraph' });
      selection = editor.value.selection;
    }
  }

  if (signatureSnippet) {
    let content = signatureSnippet.findContentByLanguage();
    const signatureBodyHtml = content.getBodyByType(SnippetContentType.ANY_CHANNEL);

    if (signatureBodyHtml) {
      selection = editor.value.selection;
      editor.insertBlock({ type: 'paragraph' });
      editor.insertBlock({ type: 'paragraph' });
      insertAnswer(editor, FullSerializer, `${signatureBodyHtml}`, variables, false);
    }
  }

  const placeholderNode = findFirstPlaceholderNode(editor);
  if (placeholderNode) {
    selection = Selection.create({ isFocused: true }).moveToRangeOfNode(placeholderNode);
  }

  return { selection };
}
