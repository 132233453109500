import CustomerView from 'models/location/customer_view';
import TrackLinkedItemNavigation from 'actions/conversation_item/pin_item/track_linked_item_navigation';

export default class NavigateToLinkedCustomer {
  constructor(context) {
    this.context = context;
  }

  run(link) {
    this.context.executeAction(TrackLinkedItemNavigation, link);

    this.context.router.navigateTo(
      CustomerView.create({
        customerId: link.customerId,
        currentConversationItemId: link.itemId,
      })
    );
  }
}
