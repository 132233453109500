import _ from 'lodash';
import { EditorState, Modifier, SelectionState } from 'draft-js';

import { isOffsetInsideEntity } from './draft_entity';
import { ENTITY_TYPE_VARIABLE } from './draft_variable';

export const MUTABLE = 'MUTABLE';
export const IMMUTABLE = 'IMMUTABLE';

export function insertText(editorState, text) {
  const sel = editorState.getSelection();
  const selection = editorState.getSelection().merge({
    focusKey: sel.getAnchorKey(),
    focusOffset: sel.getAnchorOffset(),
  });
  let contentState = Modifier.insertText(editorState.getCurrentContent(), selection, text);

  return EditorState.push(editorState, contentState, 'insert-characters');
}

export function ensureFullSelection(editorState, entityType, findEntitiesOfType, includeEnd, includeStart) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const ao = selection.getAnchorOffset();
  const fo = selection.getFocusOffset();
  const aBlock = contentState.getBlockForKey(selection.getAnchorKey());
  const fBlock = contentState.getBlockForKey(selection.getFocusKey());

  let withinRange = (point, start, end) => {
    if (includeEnd && includeStart) {
      return point >= start && point <= end;
    } else if (includeEnd) {
      return point > start && point <= end;
    } else if (includeStart) {
      return point >= start && point < end;
    }
    return point > start && point < end;
  };

  if (
    editorState.getSelection().getHasFocus() &&
    (isOffsetInsideEntity(contentState, entityType, ao, aBlock) ||
      isOffsetInsideEntity(contentState, entityType, fo, fBlock))
  ) {
    let aRange,
      fRange = null;
    let [newAo, newFo] = [ao, fo];

    if (aBlock) {
      findEntitiesOfType(
        aBlock,
        (start, end) => {
          if (withinRange(ao, start, end)) {
            aRange = { start, end };
          }
        },
        contentState
      );
    }

    if (fBlock) {
      findEntitiesOfType(
        fBlock,
        (start, end) => {
          if (withinRange(fo, start, end)) {
            fRange = { start, end };
          }
        },
        contentState
      );
    }

    if (aRange) {
      let { start, end } = aRange;
      newAo = selection.getIsBackward() ? end : start;
    }

    if (fRange) {
      let { start, end } = fRange;
      newFo = selection.getIsBackward() ? start : end;
    }

    if (newAo !== ao || newFo !== fo) {
      return EditorState.forceSelection(
        editorState,
        selection.merge({
          anchorOffset: newAo,
          focusOffset: newFo,
        })
      );
    }
  }

  return editorState;
}

// A fragment is a DraftJS block map, which you can get from contentState.getBlockMap()
export function insertFragment(editorState, selection, fragment) {
  const newContentState = Modifier.replaceWithFragment(editorState.getCurrentContent(), selection, fragment);
  return EditorState.push(editorState, newContentState, 'insert-fragment');
}

/**
 * Return the entity key that should be used when inserting text for the
 * specified target selection, only if the entity is `MUTABLE`. `IMMUTABLE`
 * and `SEGMENTED` entities should not be used for insertion behavior.
 */
export function getEntityKeyForSelection(contentState, targetSelection) {
  let entityKey;

  if (targetSelection.isCollapsed()) {
    let key = targetSelection.getAnchorKey();
    let offset = targetSelection.getAnchorOffset();
    if (offset > 0) {
      entityKey = contentState.getBlockForKey(key).getEntityAt(offset - 1);
      return filterKey(contentState.getEntityMap(), entityKey);
    }
    return null;
  }

  let startKey = targetSelection.getStartKey();
  let startOffset = targetSelection.getStartOffset();
  let startBlock = contentState.getBlockForKey(startKey);

  entityKey = startOffset === startBlock.getLength() ? null : startBlock.getEntityAt(startOffset);

  return filterKey(contentState.getEntityMap(), entityKey);
}

/**
 * The only way to manually re-run decorators for a block is to pretend to change text in the block.
 */
export function triggerDecoratorForBlock(editorState, blockKey) {
  let originalSelection = editorState.getSelection();
  let selection = new SelectionState({
    anchorKey: blockKey,
    focusKey: blockKey,
    anchorOffset: 0,
    focusOffset: 0,
    isBackward: false,
    hasFocus: originalSelection.getHasFocus(),
  });

  editorState = EditorState.acceptSelection(editorState, selection);
  editorState = insertText(editorState, ' ');
  let contentState = Modifier.removeRange(
    editorState.getCurrentContent(),
    editorState.getSelection().merge({
      anchorOffset: editorState.getSelection().getAnchorOffset() - 1,
    }),
    'backward'
  );
  editorState = EditorState.push(editorState, contentState, 'insert-characters');
  return EditorState.acceptSelection(editorState, originalSelection);
}

/**
 * Determine whether an entity key corresponds to a `MUTABLE` entity. If so,
 * return it. If not, return null.
 */
function filterKey(entityMap, entityKey) {
  if (entityKey) {
    let entity = entityMap.__get(entityKey);
    return entity.getMutability() === MUTABLE ? entityKey : null;
  }
  return null;
}

/**
 * An alternative to EditorState.forceSelection that does _not_ modify the existing
 * focus. So if you want to move the selection without stealing focus from elsewhere
 * in the app, use this function.
 */
export function updateSelection(editorState, selection) {
  return EditorState.set(editorState, {
    selection,
    forceSelection: true,
    nativelyRenderedContent: null,
    inlineStyleOverride: null,
  });
}

export function getNewBlocks(oldEditorState, newEditorState) {
  let newBlockMap = newEditorState.getCurrentContent().getBlockMap();

  let oldBlockKeys = oldEditorState
    .getCurrentContent()
    .getBlockMap()
    .keySeq()
    .toArray();
  let newBlockKeys = newBlockMap.keySeq().toArray();
  let diffBlockKeys = _.difference(newBlockKeys, oldBlockKeys);
  return _.map(diffBlockKeys, blockKey => newBlockMap.get(blockKey));
}

/* Everything below here was (mostly) lifted from the Draft-JS source code.. useful but not exposed :( */

/**
 * updateEntities() iterates through every entity of entityType in editorState and updates it with the new
 * entity value if it is different.
 */
export function updateEntities(editorState, entityType, entityList, findRangesToUpdateOfType) {
  const originalEditorState = editorState;
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  const originalSelection = editorState.getSelection();
  const selectionBlockKey = originalSelection.getAnchorKey();
  const selectionOffset = originalSelection.getAnchorOffset();
  let contentState = editorState.getCurrentContent();

  // We may need to adjust the current selection if we adjust entities before the given selection
  let offsetBeforeSelection = 0;
  let hasModifiedSelection = false;

  _.forEach(blocks, block => {
    let rangesNeedingUpdate = findRangesToUpdateOfType(contentState, block, entityList);

    // As we update entities in each block, the length of text may change, creating an offset in the [start, end)
    // for each entity in rangesNeedingUpdate. We keep track of that offset here.
    let diff = 0;
    _.forEach(rangesNeedingUpdate, range => {
      const { start, end, entityKey, blockKey } = range;

      const selection = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: start + diff,
        focusKey: blockKey,
        focusOffset: end + diff,
        isBackward: false,
      });

      const inlineStyle = editorState
        .getCurrentContent()
        .getBlockForKey(blockKey)
        .getInlineStyleAt(selection.anchorOffset);

      let { text, data } = getDataForUdpate(range, entityType);

      contentState = Modifier.replaceText(contentState, selection, text, inlineStyle, entityKey);

      contentState = contentState.mergeEntityData(entityKey, data);

      editorState = EditorState.push(editorState, contentState, 'insert-characters');

      // This is the difference in text size between what the variable was before and after updating
      const rangeDiff = text.length - (end - start);

      // If there's a difference before the beginning of the selection, then we need to offset it to account
      // for the difference.
      if (selectionBlockKey === blockKey && end <= selectionOffset) {
        offsetBeforeSelection += rangeDiff;
      }
      diff = diff + rangeDiff;
    });

    if (!hasModifiedSelection && offsetBeforeSelection !== 0) {
      hasModifiedSelection = true;
    }
  });

  if (editorState === originalEditorState) {
    return originalEditorState;
  }
  // Push the updated editor state onto the original to prevent all the intermediate steps from being
  // present in the undo history
  editorState = EditorState.push(originalEditorState, editorState.getCurrentContent(), 'insert-characters');
  editorState = updateSelection(editorState, originalEditorState.getSelection());

  if (hasModifiedSelection) {
    const ao = originalSelection.getAnchorOffset();
    // For simplicity sake, if a entity update happens for a line we've selected, collapse the selection.
    // Otherwise we would need to keep track of the selection offset for both the anchor and focus of the
    // selection.
    const updatedSelection = originalSelection.merge({
      anchorOffset: ao + offsetBeforeSelection,
      focusOffset: ao + offsetBeforeSelection,
      focusKey: originalSelection.getAnchorKey(),
    });
    editorState = updateSelection(editorState, updatedSelection);
  }
  return editorState;
}

function getDataForUdpate(range, entityType) {
  if (entityType === ENTITY_TYPE_VARIABLE) {
    return { text: range.variable.text, data: { isDisabled: range.variable.isDisabled } };
  }
  return { text: range.answer.name, data: { name: range.answer.name } };
}
