import _ from 'lodash';

/**
 * Creates a copy of the original object reordering the keys alphabetically. If the `srcObject` is
 * not an object, it will be returned as-is
 *
 * @param {Object|*} src - source object
 * @return {{Object}|*}
 */
function createOrderedObject(src) {
  if (!_.isObject(src) || _.isArray(src)) return src;

  const ret = {};
  const keys = _.keys(src).sort();
  for (const key of keys) {
    if (!key) continue;

    const value = src[key];
    ret[key] = _.isObject(value) ? createOrderedObject(value) : value;
  }

  return ret;
}

/**
 * Creates a simple string "hash" of the given object. Keys with empty/nil name and those with `undefined` value
 * will be ignored. The hash is meant for quick object comparison and is not intended for cryptography
 *
 * @param {Object} data
 * @returns {string}
 */
export function simpleHash(data) {
  if (_.isEmpty(data)) return '';

  const key = createOrderedObject(data);
  return JSON.stringify(key);
}
