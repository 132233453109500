import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';

export const SnippetSearchFields = createEnum(
  'ANY_CHANNEL',
  'CONTENTS',
  'DESCRIPTION',
  'INFO',
  'MESSAGE',
  'NAME',
  'SELF_SERVICE'
);

export default createModel({
  modelName: 'SearchField',

  properties: {
    language: prop(String),
    name: prop(String),
  },
});
