import { Factory } from 'rosie';

Factory.define('ChatCompositionContent').attr('type', 'CHAT');
Factory.define('ConversationMessageCompositionContent').attr('type', 'CONVERSATION_MESSAGE');
Factory.define('CreditCardCompositionContent').attr('type', 'CREDIT_CARD_REQUEST');
Factory.define('EmailCompositionContent').attr('type', 'EMAIL');
Factory.define('ExternalFormCompositionContent').attr('type', 'EXTERNAL_FORM');
Factory.define('FbMessageCompositionContent').attr('type', 'FB_MESSAGE');
Factory.define('NoteCompositionContent').attr('type', 'CONVERSATION_NOTE');
Factory.define('PhoneCallCompositionContent').attr('type', 'PHONE_CALL');
Factory.define('PhoneCallbackCompositionContent').attr('type', 'PHONE_CALLBACK');
Factory.define('SmsCompositionContent').attr('type', 'SMS');
Factory.define('TaskCompositionContent').attr('type', 'TASK');
Factory.define('TaskCompositionEditContent').attr('type', 'TASK_EDIT');
Factory.define('CustomChannelCompositionContent').attr('type', 'CUSTOM_CHANNEL');

Factory.define('CreditCardCompositionContentWithDefaults')
  .extend('CreditCardCompositionContent')
  .attr('amount', 153.25)
  .attr('memo', 'order #1001');

Factory.define('FbMessageCompositionContentWithDefaults')
  .extend('FbMessageCompositionContent')
  .attr('messageHtml', '<div>hello world</div>');

Factory.define('ExternalFormCompositionContentWithDefaults')
  .extend('ExternalFormCompositionContent')
  .attr('sectionsContent', [
    {
      type: 'input',
      label: 'Value',
      attr: 'discountValue',
      input: {
        type: 'text',
      },
      hint: 'Discount value greater than zero is required',
      optional: 'false',
    },
  ]);
