import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'TeamEditor',
  properties: {
    teamId: String,
  },

  statics: {
    breadcrumb: 'Team',
    create({ teamId } = { teamId: undefined }) {
      return new this({ teamId });
    },
  },
});
