import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnswerPanelToggleIcon from 'components/lib/icons/answer_panel_toggle_icon';
import Button from 'components/common/button';

export default function AnswerPanelToggleButton({ isActive, isOpen, isPanelOpen, onToggleAnswerPanel }) {
  const [isHovered, setHovered] = useState(false);
  const onMouseEnter = useCallback(() => setHovered(true), []);
  const onMouseLeave = useCallback(() => setHovered(false), []);

  useEffect(() => {
    setHovered(false);
  }, [isPanelOpen]);

  let content = isActive && isOpen && isHovered ? <StyledX>✕</StyledX> : <AnswerPanelToggleIcon />;

  return (
    <AnswerPanelButtonWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <StyledAnswerToggle data-aid="answerPanelToggleButton" isActive={isActive} onClick={onToggleAnswerPanel}>
        {content}
      </StyledAnswerToggle>
    </AnswerPanelButtonWrapper>
  );
}

AnswerPanelToggleButton.propTypes = {
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool,
  isPanelOpen: PropTypes.bool,
  onToggleAnswerPanel: PropTypes.func.isRequired,
};

export const StyledToggle = styled(Button)`
  align-items: center;
  background-color: ${p => (p.isActive ? p.theme.colors.green400 : p.theme.colors.white)};
  border: 1px solid ${p => p.theme.colors.green400};
  border-right: none;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 0;
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  box-shadow: ${p => p.theme.boxShadow.small};
  color: ${p => p.theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 4px;
  width: 24px;
  &:hover {
    background-color: ${p => p.theme.colors.green500};
  }
`;

const StyledAnswerToggle = styled(StyledToggle)`
  .iconPath {
    stroke: ${p => (p.isActive ? p.theme.colors.white : p.theme.colors.green400)};
  }
  &:hover {
    .iconPath {
      stroke: white;
    }
  }
`;

const AnswerPanelButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 13px;
  z-index: 6;
`;

export const StyledX = styled.span`
  bottom: 4px;
  color: white;
  padding-left: 2px;
  position: absolute;
`;
