import React, { useCallback, useContext } from 'react';
import {
  ELEMENT_OL,
  ELEMENT_UL,
  focusEditor,
  getPluginType,
  isMarkActive,
  getListItemEntry,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  toggleMark,
  toggleList,
  usePlateEditorState,
} from '@udecode/plate';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import AnswerPanelIcon from 'components/lib/icons/answer_panel_icon';
import CustomerContext from 'components/customer/customer_context';
import ToggleAnswerPanel from 'actions/answers/toggle_answer_panel';
import Tooltip from 'components/common/tooltip';
import { useExecuteAction } from 'components/hooks/connect_hooks';

import { Item } from 'components/text_editor_new/components/controls/controls_new';
import { Separator } from 'components/customer/composition/lib/slate/slate_styles_menu.styles';

export const ControlsWrapper = styled.div`
  display: flex;
  gap: 4px;
  justify-content: flex-start;
`;

function Icon({ className, 'data-aid': dataAid, editorId, name, symbol, type }) {
  const editor = usePlateEditorState(editorId);

  return (
    <Item
      className={className}
      data-aid={dataAid || name}
      isActive={editor?.selection && isMarkActive(editor, type)}
      onClick={evt => {
        evt.preventDefault();
        evt.stopPropagation();

        toggleMark(editor, { key: type });
        focusEditor(editor);
      }}
      title={startCase(name)}
    >
      {symbol}
    </Item>
  );
}

export function BoldItalicUnderlineControls({ editorId }) {
  const editor = usePlateEditorState(editorId);

  const buttons = [
    {
      component: StyledBoldIcon,
      name: 'bold',
      symbol: 'B',
      type: getPluginType(editor, MARK_BOLD),
    },
    {
      component: StyledItalicIcon,
      name: 'italic',
      symbol: 'i',
      type: getPluginType(editor, MARK_ITALIC),
    },
    {
      component: StyledUnderlineIcon,
      name: 'underline',
      symbol: 'U',
      type: getPluginType(editor, MARK_UNDERLINE),
    },
  ];

  return (
    <>
      {buttons.map(btn => (
        <btn.component editor={editor} key={btn.name} name={btn.name} symbol={btn.symbol} type={btn.type} />
      ))}
    </>
  );
}

const StyledBoldIcon = styled(Icon)`
  font-weight: bold;
`;

const StyledItalicIcon = styled(Icon)`
  font-style: italic;
`;

const StyledUnderlineIcon = styled(Icon)`
  text-decoration: underline;
`;

BoldItalicUnderlineControls.propTypes = {
  editorId: PropTypes.string.isRequired,
};

export { Separator };

function ListIcon({ className, 'data-aid': dataAid, editorId, name, symbol, type }) {
  const editor = usePlateEditorState(editorId);
  const listItem = !!editor?.selection && getListItemEntry(editor);
  const isActive = listItem && listItem?.list[0]?.type === type;

  return (
    <Item
      className={className}
      data-aid={dataAid || name}
      isActive={isActive}
      onClick={evt => {
        evt.preventDefault();
        evt.stopPropagation();

        toggleList(editor, { type });
        focusEditor(editor);
      }}
      title={startCase(name)}
    >
      {symbol}
    </Item>
  );
}
export function ListControls({ editorId, editorRef }) {
  const buttons = [
    {
      'data-aid': 'orderedList',
      name: 'ordered list',
      symbol: <i className="fa fa-list-ol" />,
      type: getPluginType(editorRef.current, ELEMENT_OL),
    },
    {
      'data-aid': 'unorderedList',
      name: 'unordered list',
      symbol: <i className="fa fa-list-ul" />,
      type: getPluginType(editorRef.current, ELEMENT_UL),
    },
  ];

  return (
    <>
      {buttons.map(btn => {
        return (
          <ListIcon
            data-aid={btn['data-aid']}
            editorId={editorId}
            key={btn.type}
            name={btn.name}
            symbol={btn.symbol}
            type={btn.type}
          />
        );
      })}
    </>
  );
}

export const AnswerPanelControl = React.memo(function AnswerMenuButtonBase() {
  const { customerId, compositionId, latestConversationId } = useContext(CustomerContext);
  const executeAction = useExecuteAction();
  const toggleAnswerPanel = useCallback(() => {
    analytics.track('Composer Answer Panel Button Clicked', {
      customerId,
      compositionId,
      conversationId: latestConversationId,
    });
    executeAction(ToggleAnswerPanel, {
      customerId,
      compositionId,
      conversationId: latestConversationId,
    });
  }, [executeAction, customerId, compositionId, latestConversationId]);

  const message = (
    <ToggleAnswerPanelMessage>
      Answer Panel
      <Hotkey>Ctrl-/</Hotkey>
    </ToggleAnswerPanelMessage>
  );

  return (
    <Tooltip className="answerPanelToggleButton" delay={500} message={message}>
      <Item
        data-aid="answerPanelToggle-control"
        onClick={evt => {
          evt.preventDefault();
          evt.stopPropagation();
          toggleAnswerPanel();
        }}
      >
        <StyledAnswerPanelIcon className="answerPanelIcon-button" />
      </Item>
    </Tooltip>
  );
});

const StyledAnswerPanelIcon = styled(AnswerPanelIcon)`
  margin-top: -2px;
  .answerPanelIcon-path {
    stroke: black !important;
  }
`;

const ToggleAnswerPanelMessage = styled.span`
  display: inline-block;
`;

const Hotkey = styled.span`
  color: #999;
  padding-left: 8px;
`;
