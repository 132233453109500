export default class TrackGrammarlyUsage {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (document.getElementsByTagName('grammarly-extension').length > 0) {
      this.context.analytics.updateUser({ usesPluginGrammarly: true });
    }
  }
}
