import { Factory } from 'rosie';

Factory.define('Inbox').attr('name', 'INBOX');

Factory.define('InboxWithDefaults')
  .extend('Inbox')
  .attr('conversationStatus', 'OPEN')
  .attr('isPolling', true)
  .attr('isTeam', false)
  .attr('sortOrder', 'LATEST');

Factory.define('PersonalInbox').extend('InboxWithDefaults');

Factory.define('TeamInbox')
  .extend('InboxWithDefaults')
  .attr('isTeam', true)
  .attr('routingGroupId', 'default-routing-group-id');
