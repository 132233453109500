import _ from 'lodash';

import { AgentAvailability } from 'models/agent_status';
import Floorboard from 'models/location/floorboard';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import { isCancel } from 'axios';
import LiveboardConverter from 'scripts/application/dto_converters/liveboard_converter';
import Liveboards from 'models/location/liveboards';
import LiveboardType from 'models/liveboards/liveboard_type';

const POLLING_INTERVAL = 15000;
const INITIAL_REQUEST_TIMEOUT = 30000;
const SUBSEQUENT_REQUEST_TIMEOUT = 120000;

export default function pollLiveboard(context, { liveboardType, query }) {
  let fetch = methodForLiveboardType(context, liveboardType);
  let interactiveHandler = new GatewayErrorInteractiveHandler(context);
  let silentHandler = new GatewayErrorSilentHandler(context);
  let nextRequestTimerId;

  query = _.omitBy(query, _.isEmpty);

  context.stores.liveboard.remove();

  fetchInitialLiveboard();

  context.stores.liveboard.setLoading();

  return stopPolling;

  function fetchInitialLiveboard() {
    fetchLiveboard(INITIAL_REQUEST_TIMEOUT, handleInitialError);
  }

  function handleInitialError(err) {
    interactiveHandler.handleCommonErrors(context, err);
    scheduleNextRequest();
  }

  function handleSuccess(response) {
    let currentLocation = context.stores.currentLocation.get();

    if (
      !(
        (currentLocation instanceof Liveboards && liveboardType === currentLocation.type) ||
        currentLocation instanceof Floorboard
      )
    ) {
      return;
    }

    context.stores.liveboard.set(LiveboardConverter.fromDto(response, liveboardType.toUpperCase()));
    context.stores.liveboard.resetLoading();

    scheduleNextRequest();
  }

  function scheduleNextRequest() {
    nextRequestTimerId = setTimeout(() => {
      let agentAvailability = context.stores.agentStatus.get().getAvailability();
      if (agentAvailability === AgentAvailability.AWAY || agentAvailability === AgentAvailability.OFFLINE) {
        scheduleNextRequest();

        return;
      }

      fetchSubsequentLiveboard();
    }, POLLING_INTERVAL);
  }

  function fetchSubsequentLiveboard() {
    fetchLiveboard(SUBSEQUENT_REQUEST_TIMEOUT, handleSubsequentError);
  }

  function handleSubsequentError(err) {
    silentHandler.handleCommonErrors(context, err);
    scheduleNextRequest();
  }

  function fetchLiveboard(timeout, handleError) {
    clearTimeout(nextRequestTimerId);

    fetch(query, { timeout })
      .then(response => handleSuccess(response, query))
      .catch(err => {
        if (isCancel(err)) {
          return;
        }

        handleError(err);
      });
  }

  function stopPolling() {
    clearTimeout(nextRequestTimerId);
  }
}

function methodForLiveboardType(context, liveboardType) {
  let gateway = context.gateways.liveboard;

  switch (liveboardType) {
    case LiveboardType.AGENTS:
      return gateway.fetchAgentsLiveboard.bind(gateway);
    case LiveboardType.PEOPLE_MATCH:
      return gateway.fetchPeopleMatchLiveboard.bind(gateway);
    case LiveboardType.SUMMARY:
      return gateway.fetchSummaryLiveboard.bind(gateway);
    case LiveboardType.TOPICS:
      return gateway.fetchTopicsLiveboard.bind(gateway);
    default:
      throw Error(`could not fetch liveboard of unknown type ${liveboardType}`);
  }
}
