import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import Err from 'models/err';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import PhoneGatewayBase from './phone_gateway_base';
import qconsole from 'scripts/lib/qconsole';
import { TwilioDeviceStatus } from 'models/connection_states';
import getGladlyCdn from 'scripts/lib/gladly_cdn';

export const TwilioWarnings = createEnum(
  'low-mos',
  'high-rtt',
  'high-jitter',
  'high-packet-loss',
  'high-packets-lost-fraction'
);

// Maximum number of times the Twilio client device should try to setup
const MAX_RETRY_COUNT = 10;

// Maximum duration the Twilio connection should be in the 'connecting' status
const MAX_CONNECTING_DURATION = 20000;

const TOKEN_FETCH_INTERVAL = 10000;

// Base CDN path for voice assets
const VOICE_ASSET_PATH_BASE = '/assets/voice/agent-desktop';

export default class TwilioPhoneGateway extends PhoneGatewayBase {
  constructor(backend, requestorId, twilio) {
    super(backend, requestorId);
    this.twilio = twilio;
    this.setupAttempt = 0;
    this.registerAgent = _.throttle(this._registerAgent, TOKEN_FETCH_INTERVAL);
    this.isTwilioUmatillaEnabled = false;
  }

  get version() {
    return '1';
  }

  get topicPattern() {
    return 'configuration/twilio-token/+';
  }

  get registeredAgentId() {
    if (this._registeredAgentId) {
      return this._registeredAgentId;
    }
    qconsole.log('No registeredAgentId for the twilio phone gateway. Ensure you called registerAgent first.');
    return null;
  }

  _registerAgent(agentId) {
    if (!agentId) {
      ErrorReporter.reportError(new Error('TwilioPhoneGateway error'), {
        message: 'missing agentId - phone gateway will not start up',
      });
      return;
    }
    this._registeredAgentId = agentId;
    super._fetch([agentId]);
    this.notifyObservers('handleTwilioEnabled', null);
    let twilioConnectingStatus = this._callbacksRegistered
      ? TwilioDeviceStatus.RECONNECTING
      : TwilioDeviceStatus.CONNECTING;
    this.notifyObservers('handlePhoneStatus', twilioConnectingStatus);
  }

  deregisterAgent(agentId) {
    if (this.twilio) {
      this._deviceDestroyed = true;
      this.twilio.Device.destroy();
    }

    this._callbacksRegistered = false;
    this._registeredAgentId = null;
    this.notifyObservers('handleTwilioDisabled', null);
  }

  setTwilioUmatilla(isTwilioUmatillaEnabled) {
    this.isTwilioUmatillaEnabled = isTwilioUmatillaEnabled;
  }

  onFetchResponse(fetchResultDto, topic, parsedTopicParams) {
    this.configureTwilio(fetchResultDto.capabilityToken);
  }

  onFetchError(errorDto, topic, parsedTopicParams) {
    this.notifyObservers('handleRequestError', errorDto);
    // Do not retry if the org does not have a configured telephony gateway
    let unconfiguredGatewayError = _.find(errorDto.errors, { code: Err.Code.INVALID_STATE });
    if (!unconfiguredGatewayError) {
      ErrorReporter.reportError(new Error('twilio token error'), {
        message: 'failed to fetch twilio auth token',
        tags: { errors: JSON.stringify(errorDto) },
      });
      this.registerAgent(this.registeredAgentId);
    }
  }

  configureTwilio(capabilityToken) {
    let codecPreferences = ['opus', 'pcmu'];
    let edgeLocations = ['ashburn', 'dublin', 'frankfurt', 'sydney'];
    if (this.isTwilioUmatillaEnabled) {
      edgeLocations = ['ashburn', 'dublin', 'frankfurt', 'sydney', 'umatilla'];
    }

    let isTwilioSetupSuccessfully = this.twilio.Device.setup(capabilityToken, {
      allowIncomingWhileBusy: true,
      debug: true,
      closeProtection: true,
      codecPreferences,
      edge: edgeLocations,
      sounds: this._getSoundFiles(),
    });
    if (isTwilioSetupSuccessfully) {
      this.setupAttempt = 0;
      this._registerCallbacks();
      this.notifyObservers('handleConnect', null);
    } else {
      ErrorReporter.reportError(new Error('twilio setup error'), {
        message: 'failed to setup twilio device',
        extra: {
          setupAttempt: this.setupAttempt,
        },
      });
      if (this.setupAttempt < MAX_RETRY_COUNT) {
        this.setupAttempt += 1;
        setTimeout(this.registerAgent(this.registeredAgentId), 3000);
      }
      this.notifyObservers('handleDisconnect', null);
    }
  }

  _registerCallbacks() {
    // Unable to register callbacks in constructor because Twilio breaks capybara-webkit
    if (this._callbacksRegistered) {
      return;
    }

    this.twilio.Device.on('ready', this.onReady.bind(this));
    this.twilio.Device.on('error', this.onError.bind(this));
    this.twilio.Device.on('connect', this.onConnect.bind(this));
    this.twilio.Device.on('disconnect', this.onDisconnect.bind(this));
    this.twilio.Device.on('cancel', this.onCancel.bind(this));
    this.twilio.Device.on('incoming', this.onIncoming.bind(this));
    this.twilio.Device.on('offline', this.onOffline.bind(this));

    this._callbacksRegistered = true;
  }

  onReady(device) {
    this.notifyObservers('handleRegisteredAgent', {});
    let deviceStatus = device.status();
    this.notifyObservers('handlePhoneStatus', deviceStatus.toUpperCase());
  }

  onError() {
    this.notifyObservers('handleDeviceError', {});
  }

  onConnect() {
    qconsole.log('Twilio established a connection');
  }

  onDisconnect(connection) {
    this.connection = null;
    this.notifyObservers('handleHangup', {});
  }

  // Receive Cancel event in one of 4 scenarios
  // 1) Declining call
  // 2) Timing out: Agent did not accept or decline the call before Twilio views call as `no_answer`
  // 2) Answering call in another tab
  // 3) Customer hanging up or Transferring agent canceling before agent has answered
  onCancel(connection) {
    qconsole.log('Twilio cancel called');
    if (this.connection) {
      this.connection = null;
    } else {
      this.notifyObservers('handleCancelError', 'canceling call without an active connection');
    }
    this.notifyObservers('handleHangup', {});
  }

  onIncoming(connection) {
    const status = this.twilio.Device.status();
    if (status.toUpperCase() !== TwilioDeviceStatus.READY && status.toUpperCase() !== TwilioDeviceStatus.BUSY) {
      ErrorReporter.reportMessage(`unexpected twilio device status on incoming event`, {
        tags: { status },
        extra: {
          callSid: _.get(connection, 'parameters.CallSid'),
        },
      });
    }
    this.connection = connection;
    this.connection.on('error', err => {
      let callSid = err.connection.parameters.CallSid;
      ErrorReporter.reportError(new Error('twilio connection error'), {
        message: err.message,
        tags: { message: err.message, code: err.code, callSid, deviceStatus: this.getDeviceStatus() },
      });
    });
    this.notifyObservers('handleActiveCall', {});
  }

  onOffline(device) {
    let deviceStatus = device.status();
    this.notifyObservers('handlePhoneStatus', deviceStatus.toUpperCase());
    if (this._deviceDestroyed) {
      this._deviceDestroyed = false;
      return;
    }
    this.registerAgent(this.registeredAgentId);
  }

  accept() {
    // There should always be an active connection when accepting a webRTC call.
    // Direct Dial will not use this accept method as the call is answered via physical phone
    if (this.connection) {
      this.connection.accept();
      this.notifyObservers('handleAccept', {});
      this.connection.on('warning', warningName => {
        if (_.keys(TwilioWarnings).indexOf(warningName) !== -1) {
          this.notifyObservers('handleCallQualityWarning', warningName);
        }
      });
      this.connection.on('warning-cleared', warningName => {
        if (_.keys(TwilioWarnings).indexOf(warningName) !== -1) {
          this.notifyObservers('handleCallQualityWarningCleared', warningName);
        }
      });
      let connection = this.connection;
      this._checkConnectionStatus(connection);

      // Device is busy once it has an active call
      this.notifyObservers('handlePhoneStatus', TwilioDeviceStatus.BUSY);
    } else {
      this.notifyObservers('handleAcceptError', 'could not accept call without an active connection');
    }
  }

  call() {
    this.notifyObservers('handleCall', {});
  }

  decline() {
    if (this.connection) {
      this.connection.reject();
    }
  }

  hangup() {
    if (this.connection) {
      this.connection.disconnect();
    } else {
      this.notifyObservers('handleHangup', {});
    }
  }

  sendDigits(digits) {
    if (this.connection) {
      this.connection.sendDigits(digits);
    }
  }

  disconnectActiveConnection() {
    if (this.connection) {
      // reference: https://www.twilio.com/docs/voice/client/javascript/device#disconnect-all
      this.twilio.Device.disconnectAll();
    }
  }

  getConnectionStatus() {
    if (this.connection) {
      return this.connection.status();
    }
    return null;
  }

  getDeviceStatus() {
    let status;
    try {
      // 3 Possible Device statuses: 'ready', 'busy', 'offline'
      status = this.twilio.Device.status().toUpperCase();
    } catch (err) {
      status = TwilioDeviceStatus.OFFLINE;
    }
    return status;
  }

  isDeviceReady() {
    return this.getDeviceStatus() === TwilioDeviceStatus.READY;
  }

  // checks the Twilio connection status after a set amount of time. Twilio connections
  // should either 'closed' or 'open' after the agent has accepted the incoming call.
  // Calls that fail to connect, typically fail within 10s. Report a message to sentry
  // if the connection does not update within the maximum connecting duration
  _checkConnectionStatus(connection) {
    setTimeout(
      function() {
        let connectionStatus = connection.status();
        if (connectionStatus !== 'connecting') {
          return;
        }

        let connectionState;
        if (this.context && this.context.stores && this.context.stores.connectionState) {
          connectionState = this.context.stores.connectionState.get();
        }
        ErrorReporter.reportMessage('twilio webRTC call failed to connect within 20s', {
          extra: {
            connectionState,
            status: connectionStatus,
            callID: connection.parameters.CallSid,
          },
        });
      }.bind(this),
      MAX_CONNECTING_DURATION
    );
  }

  _getSoundFiles() {
    const gladlyCdn = getGladlyCdn();
    const voiceFileBase = gladlyCdn + VOICE_ASSET_PATH_BASE;
    return {
      disconnect: `${voiceFileBase}/disconnect.mp3`,
      dtmf0: `${voiceFileBase}/dtmf-0.mp3`,
      dtmf1: `${voiceFileBase}/dtmf-1.mp3`,
      dtmf2: `${voiceFileBase}/dtmf-2.mp3`,
      dtmf3: `${voiceFileBase}/dtmf-3.mp3`,
      dtmf4: `${voiceFileBase}/dtmf-4.mp3`,
      dtmf5: `${voiceFileBase}/dtmf-5.mp3`,
      dtmf6: `${voiceFileBase}/dtmf-6.mp3`,
      dtmf7: `${voiceFileBase}/dtmf-7.mp3`,
      dtmf8: `${voiceFileBase}/dtmf-8.mp3`,
      dtmf9: `${voiceFileBase}/dtmf-9.mp3`,
      dtmfh: `${voiceFileBase}/dtmf-hash.mp3`,
      dtmfs: `${voiceFileBase}/dtmf-star.mp3`,
      incoming: `${voiceFileBase}/incoming.mp3`,
      outgoing: `${voiceFileBase}/outgoing.mp3`,
    };
  }
}
