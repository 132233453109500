import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useExecuteAction } from 'components/hooks/connect_hooks';
import ConversationItem from 'models/conversation_item';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import { iconClassNameForItem } from '../metadata/metadata_icon_classes';
import ItemContent from '../item_content';
import AgentMetadata from '../metadata/agent_metadata';

export default function TaskEditedItem({ item }) {
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    executeAction(NavigateToItem, { customerId: item.customerId, itemId: item.content.taskItemId });
  }, [item.customerId, item.content.taskItemId]);

  const metadataText = (
    <span className="taskEditedItem">
      edited{' '}
      <a className="taskEditedItem-link itemMetadata-subject" onClick={onClick}>
        Task
      </a>
    </span>
  );

  return (
    <ItemContent isAuditItem item={item}>
      <AgentMetadata iconClass={iconClassNameForItem(item)} item={item} text={metadataText} />
    </ItemContent>
  );
}

TaskEditedItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
