import React from 'react';
import { Decoration, Mark } from 'slate-old';

import SipHighlight from './sip_highlight';
import SipHighlightMenu from './sip_highlight_menu';

export default function SipHighlighting({ onChange }) {
  return {
    decorateNode(node, editor, next) {
      const others = next() || [];

      let decorations = [];
      const textNodes = node.getTexts();
      textNodes.forEach(textNode => {
        const sipRanges = findSipRanges(textNode);
        sipRanges.forEach(range => {
          decorations.push(
            Decoration.create({
              anchor: {
                key: textNode.key,
                offset: range.start,
              },
              focus: {
                key: textNode.key,
                offset: range.end,
              },
              mark: Mark.create({
                type: 'sipHighlight',
                isAtomic: true,
                data: {
                  text: range.word,
                },
              }),
            })
          );
        });
      });
      return [...others, ...decorations];
    },

    renderEditor(props, editor, next) {
      const children = next();

      return (
        <React.Fragment>
          {children}
          <SipHighlightMenu />
        </React.Fragment>
      );
    },

    renderMark(props, editor, next) {
      const { attributes, children, mark, node, offset, text } = props;

      if (mark.type === 'sipHighlight') {
        return (
          <SipHighlight
            editor={editor}
            end={offset + text.length}
            node={node}
            onChange={onChange}
            start={offset}
            text={text}
            {...attributes}
          >
            {children}
          </SipHighlight>
        );
      }
      return next();
    },
  };
}

// SIP_REGEX is similar to EMAIL_REGEX but with slight differences for IP as domain and the "sip:" or "sips" address pre-fix.
// TODO: Potentially add REGEX support for custom SIP ports as part of the SIP address
function findSipRanges(textNode) {
  const SIP_REGEX = /(sips?:)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi;
  const text = textNode.text;

  let ranges = [];
  let match = SIP_REGEX.exec(text);
  while (match !== null) {
    ranges.push({
      start: match.index,
      end: match.index + match[0].length,
      word: match[0],
    });
    match = SIP_REGEX.exec(text);
  }

  return ranges;
}
