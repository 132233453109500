import _ from 'lodash';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import CreateExternalPhoneCallComposition from 'actions/composition/create_external_phone_call_composition';
import CustomAttributeBase from 'components/customer/summary/custom_attributes/custom_attribute_base';
import ExternalLink from 'components/lib/external_link';
import { formatValue } from 'components/customer/summary/custom_attributes/visible_custom_attribute';
import { isUrlValid } from 'scripts/presentation/components/lib/linkify_it';
import { isValidPhoneNumber } from 'models/phone_number';
import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';
import { setOverflowTitle } from 'components/lib/overflow_title';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const MUSTACHE_REGEX = /{{([\s\S]+?)}}/g;

export default function VisibleStringCustomAttribute({ className, def, 'data-aid': dataAid, value, location }) {
  // We cannot exit before the hooks are executed, so we are checking whether the attribute is valid and can
  // be rendered, and act accordingly
  const isValid =
    (value || value === 0) && (_.isString(value) || _.isNumber(value)) && def.type !== AttributeType.PERCENT;
  const trimmedValue = _.trim(value);
  const displayVal = isValid ? formatValue(trimmedValue, def.type) : '';
  const formattedVisibleAttribute = isValid ? FormattedVisibleAttribute(def, trimmedValue, displayVal, location) : '';
  const classNames = classnames('visibleCustomAttribute', className);

  const attrValueRef = useRef();
  useEffect(() => {
    setOverflowTitle(attrValueRef, formattedVisibleAttribute);
  });

  return isValid ? (
    <CustomAttributeBase className={classNames} data-aid={dataAid} label={def.label}>
      <VisibleCustomAttributeValue ref={attrValueRef}>{formattedVisibleAttribute}</VisibleCustomAttributeValue>
    </CustomAttributeBase>
  ) : null;
}

function FormattedVisibleAttribute(def, value, displayVal, location) {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();
  const executeAction = useExecuteAction();
  const isFeatureEnabled = useIsFeatureEnabled();
  const linkText = _.trim(def.linkText);

  if (def.linkTemplate && !isUrlValid(displayVal)) {
    let url = _.template(def.linkTemplate, {
      interpolate: MUSTACHE_REGEX,
      escape: MUSTACHE_REGEX,
    })({ value });

    return (
      <ExternalLink
        attrName={def.attr}
        className="transactionGroup-externalLink"
        subType={_.get(location, 'subType')}
        text={linkText || displayVal}
        type={_.get(location, 'type')}
        url={url}
      />
    );
  } else if (isUrlValid(displayVal)) {
    const cleanDisplayVal = displayVal.replace(/https?:\/\/www\.|^https?:\/\/|www\./, '');
    return (
      <ExternalLink
        attrName={def.attr}
        className="transactionGroup-externalLink"
        subType={_.get(location, 'subType')}
        text={linkText || cleanDisplayVal}
        type={_.get(location, 'type')}
        url={displayVal}
      />
    );
  } else if (isFeatureEnabled('clickToCall') && isValidPhoneNumber(displayVal)) {
    const onCall = () => {
      executeAction(CreateExternalPhoneCallComposition, { phoneNumber: displayVal });
    };

    return (
      <>
        <StyledPhoneNumberHighlight onClick={onToggle} ref={setTargetRef}>
          {displayVal}
        </StyledPhoneNumberHighlight>
        <PopoverMenu
          boundByWindow={false}
          data-aid="customAttribute-actionMenu"
          isOpen={isOpen}
          margin={0}
          onClose={onClose}
          position="right"
          targetPosition="center"
          targetRef={targetRef}
        >
          <PopoverMenuItem className="customAttribute-phoneNumber" data-aid="menuItem-call" onClick={onCall}>
            <div data-aid="call-number">Call</div>
          </PopoverMenuItem>
          <PopoverMenuItem className="customAttribute-phoneNumber" data-aid="menuItem-copy">
            <CopyToClipboard text={displayVal}>
              <div data-aid="copy-number">Copy</div>
            </CopyToClipboard>
          </PopoverMenuItem>
        </PopoverMenu>
      </>
    );
  }

  return displayVal;
}

export const StyledPhoneNumberHighlight = styled.span`
  border-bottom: 1px dashed ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.green400};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.green600};
  }
`;

export const VisibleCustomAttributeValue = styled.div`
  font-weight: ${p => p.theme.fontWeight.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

VisibleStringCustomAttribute.propTypes = {
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  value: PropTypes.any,
  location: PropTypes.shape({
    type: PropTypes.string,
    subType: PropTypes.string,
  }),
};
