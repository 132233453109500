import _ from 'lodash';
import ModelFactory from 'scripts/domain/__specs__/factories/model_factory';

export default class TopicSuggestionsService {
  static find(dataset, customerId, conversationId) {
    const customer = _.find(dataset.customers, customer => customer.id === customerId);
    const filteredSuggestions = _.find(dataset.topicSuggestions, suggestion => {
      return _.toLower(customer.profile.name).includes(_.toLower(suggestion.customerName));
    });

    const attrs = filteredSuggestions
      ? {
          customerId,
          modelVersion: 'a-model-version',
          addTopics: filteredSuggestions.addTopics,
        }
      : {
          customerId,
          modelVersion: '',
        };

    return ModelFactory.attributes(
      'TopicSuggestionsWithDefaults',
      _.merge(
        {
          conversationId,
        },
        attrs
      )
    );
  }
}
