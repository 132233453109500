import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Editor from 'components/composer/editors/super';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import { SnippetChannel, SnippetContentType } from 'models/answers/snippet';

/**
 * Body displays rich text answer content, and also will add some special styling for internal answers.
 */
export default function Body({ answer, 'data-aid': aid, language, type }) {
  let content = answer.findContentByLanguage(language);
  const bodyHtml = content.getBodyByType(SnippetContentType[type]);
  if (!bodyHtml) {
    return null;
  }
  let isInternal = content.info && content.info.internal && type === SnippetChannel.INFO;
  let id = answer && `${answer.id}-${type}`;

  return (
    <SimpleAnswerContentBody aid={aid} bodyHtml={bodyHtml} id={id} isInternal={isInternal}></SimpleAnswerContentBody>
  );
}

export function SimpleAnswerContentBody({ aid, bodyHtml, className, id, isInternal }) {
  return (
    <BodyStyled className={className} data-aid={aid} isInternal={isInternal}>
      {isInternal ? <Internal /> : null}
      <SlatePreview html={bodyHtml} key={id} />
    </BodyStyled>
  );
}

const BodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .slateTextEditor {
    flex-grow: 1;
    padding: 16px;
  }
  & img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  ${p => p.isInternal && bodyInternal};
`;

const bodyInternal = css`
  border-left: 2px solid ${p => p.theme.colors.yellow400};
  padding-left: ${p => p.theme.spacing.medium};
  padding-right: ${p => p.theme.spacing.medium};
`;

function Internal() {
  return <InternalStyled>Internal</InternalStyled>;
}

const InternalStyled = styled.div`
  color: ${p => p.theme.colors.yellow400};
  font-size: ${p => p.theme.fontSize.base};
  font-weight: ${p => p.theme.fontWeight.heavy};
  line-height: 100%;
  padding-bottom: 3px;
  text-transform: uppercase;
`;

export function SlatePreview({ html }) {
  const [value, onChange] = useEditorValue(html);
  if (!html) {
    return null;
  }

  return <Editor autoFocus={false} onChange={onChange} placeholder="There is no content" readOnly value={value} />;
}

/**
 * useEditorValue caches the editor value.. deserializing html can be expensive, so we only want to do it when the
 * html changes.
 *
 * On top of this, it also allows the value to be changed.. why would we need this if our editor is supposedly
 * readOnly?
 *
 * Well, there are some features in the editor (such as the phone number highlighter) that rely on being able to
 * set data on the document in order to function. For example, when you click a phone number to open the menu,
 * it sets data on the document to let the editor know that it should open the menu.
 *
 * No text is actually editable.. just some internal bookkeeping.
 */
export function useEditorValue(html) {
  // don't initially deserialize the html for the default state`
  // the below `useEffect` takes care of this deserialization
  // we don't want to duplicate the effort
  const [value, setValue] = useState(() => FullSerializer.deserialize(''));

  const onChange = useCallback(editor => {
    setValue(editor.value);
  }, []);

  useEffect(() => {
    const newValue = FullSerializer.deserialize(html);
    setValue(newValue);
  }, [html]);

  return [value, onChange];
}
