export default class RemoveSnippet {
  constructor(context) {
    this.context = context;
  }

  run(snippetId) {
    this.context.stores.snippets.setPendingDelete(snippetId);
    this.context.gateways.snippet.delete({ snippetId });
  }
}
