import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Input from 'components/common/input';
import { ShortList } from 'components/common/short_list';

export default class ConversationTopicMenuOpener extends PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleInputBlur', 'handleInputChange', 'setInputRef']);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.focus();
    }
  }

  blur() {
    if (this.input) {
      this.input.blur();
    }
  }

  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  handleInputChange(ev) {
    this.setState({ inputValue: ev.target.value });
    this.props.onInputChange(ev);
  }

  handleInputBlur() {
    this.props.onInputBlur();
  }

  render() {
    return (
      <div className="conversationTopic-menuOpener">
        {this.renderInput()}
        {this.renderValue()}
      </div>
    );
  }

  renderInput() {
    if (this.props.isOpen) {
      return (
        <Input
          className="conversationTopic-menuOpener-input"
          onBlur={this.handleInputBlur}
          onChange={this.handleInputChange}
          placeholder=" Type to search"
          ref={this.setInputRef}
          value={this.props.inputValue}
          wrapperClassName="conversationTopic-menuOpener-inputWrapper"
        />
      );
    }

    return null;
  }

  renderValue() {
    if (!this.props.isOpen) {
      return (
        <ShortList
          className="conversationTopic-display"
          placeholder={this.props.placeholder}
          values={this.props.values}
        />
      );
    }

    return null;
  }

  setInputRef(ref) {
    this.input = ref;
  }
}

ConversationTopicMenuOpener.propTypes = {
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
};
