import analytics from 'scripts/lib/analytics';
import CustomerMergeDtoConverter from 'scripts/application/dto_converters/customer_merge_converter';

export default class MergeCustomers {
  constructor(context) {
    this.context = context;
  }

  run(customerMerge) {
    this.context.stores.customerMerge.setPending(customerMerge);
    this.context.stores.conversationMerge.setPending(customerMerge);
    const mergeDto = CustomerMergeDtoConverter.toDto(customerMerge);
    this.context.gateways.customerProfileMerge.add({ customerId: mergeDto.sourceCustomerId }, mergeDto);
    analytics.track('Customer Merged', {
      customerId: customerMerge.destCustomerId, // double logging `destCustomerId` as `customerId` to enable funnel analysis with other events, holding customerId constant
      sourceCustomerId: customerMerge.sourceCustomerId,
      destCustomerId: customerMerge.destCustomerId,
    });
  }
}
