import createModel from './lib/create_model';

var WebForm = createModel({
  modelName: 'WebForm',

  properties: {
    content: Object,
    submittedAt: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default WebForm;
