import _ from 'lodash';

import { AIActivityTypes } from 'models/ai_activity/ai_activity_item';

export function getAIActivityItemPreview(content) {
  const activityType = content?.activityType || '';

  switch (activityType) {
    case AIActivityTypes.CONVERSATION_SUMMARY:
      return getConversationSummaryPreview(content);

    default:
      return null;
  }
}

function getConversationSummaryPreview(content) {
  const activityType = content?.activityType || '';
  const meta = content?.meta || {};

  switch (activityType) {
    case AIActivityTypes.CONVERSATION_SUMMARY: {
      const caption = _.trim(meta.caption);
      return `Conversation Summary - ${caption}`;
    }

    default:
      return null;
  }
}
