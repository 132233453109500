import { createPluginFactory, isCollapsed } from '@udecode/plate';
import { Editor, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';

import getFirstNode from 'components/text_editor_new/lib/get_first_node';
import getLastNode from 'components/text_editor_new/lib/get_last_node';

export const MARK_LINK_SELECTION = 'inline-link-selection';
export const MARK_LINK_SELECTION_FIRST_NODE = 'first-node';
export const MARK_LINK_SELECTION_LAST_NODE = 'last-node';

export function createLinkSelectionPlugin() {
  return createPluginFactory({
    key: MARK_LINK_SELECTION,

    isLeaf: true,
    withOverrides: withLinkSelection,
  })();

  function withLinkSelection(editor) {
    editor.setLinkSelectionMarks = () => {
      if (!editor.selection) return;

      HistoryEditor.withoutSaving(editor, () => {
        Editor.withoutNormalizing(editor, () => Editor.addMark(editor, MARK_LINK_SELECTION, true));
        if (isCollapsed(editor.selection)) return;

        // Tag the first and the last selected nodes to help with styling and locating the DOM boundaries.
        // We need to step outside the selection range because the nodes will get split and reshuffled as
        // the marks are added. We only tag the boundaries if the selection is not "collapsed", as tagging
        // a collapsed selection start/end makes no practical sense
        const [, firstNodePath] = getFirstNode(editor, { at: [], match: node => !!node[MARK_LINK_SELECTION] }) || [];
        const [, lastNodePath] = getLastNode(editor, { at: [], match: node => !!node[MARK_LINK_SELECTION] }) || [];

        if (firstNodePath) {
          Transforms.setNodes(editor, { [MARK_LINK_SELECTION_FIRST_NODE]: true }, { at: firstNodePath });
        }
        if (lastNodePath) {
          Transforms.setNodes(editor, { [MARK_LINK_SELECTION_LAST_NODE]: true }, { at: lastNodePath });
        }
      });
    };

    editor.removeLinkSelectionMarks = () => {
      if (!editor.selection || isCollapsed(editor.selection)) return;
      HistoryEditor.withoutSaving(editor, () => {
        Editor.withoutNormalizing(editor, () => {
          Editor.removeMark(editor, MARK_LINK_SELECTION);

          // We go over the entire editor out of abundance of caution, just to make sure there are no leftovers
          for (const [, nodePath] of Editor.nodes(editor, { at: [], match: node => !!node[MARK_LINK_SELECTION] })) {
            Transforms.unsetNodes(
              editor,
              [MARK_LINK_SELECTION, MARK_LINK_SELECTION_FIRST_NODE, MARK_LINK_SELECTION_LAST_NODE],
              { at: nodePath }
            );
          }
        });
      });
    };

    return editor;
  }
}
