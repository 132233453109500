import AgentExperienceConfig from 'models/agent_experience_config';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';

export default class RequestAgentExperienceConfig {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.gateways.agentExperienceConfig
      .request()
      .then(dto => {
        this.context.stores.agentExperienceConfig.set(AgentExperienceConfig.fromJs(dto));
      })
      .catch(errDto => {
        handleCommonErrors(this.context, errDto);
      });
    this.context.stores.agentExperienceConfig.setLoading();
  }
}
