import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import AgentInboxObserver from 'actions/inbox/agent/agent_inbox_observer';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import { isSubscribedToItem } from 'actions/inbox/agent/agent_inbox_subscriptions';
import { reconcileInboxItem } from 'actions/inbox/agent/agent_inbox_helpers';
import { subscribeToItem, unsubscribe } from 'actions/inbox/agent/request_agent_inbox_deprecated';

export default function addTaskToInbox(context, taskItem) {
  const customerId = taskItem.customerId;

  // If we don't have a profile, that's OK - we'll probably be loading the customer soon, and
  // will fill in the profile when it loads.
  let profile;
  if (context.stores.customers.has({ id: customerId })) {
    profile = context.stores.customers.storesFor(customerId).profile.get();
  }

  let newItem = new AgentInboxItem({
    id: taskItem.id,
    item: taskItem,
    profile,
    type: AgentInboxItem.Type.TASK,
  });
  newItem.setManuallyCreatedAtToNow();
  const reconciledItem = reconcileInboxItem(context, newItem);

  if (!isSubscribedToItem(reconciledItem.id)) {
    const observer = new AgentInboxObserver(context);
    const auth = context.stores.auth.get();
    const orgId = auth.getOrgId();

    // Sinful, but rather than adjusting the subscribeToItem API to take { id, customerId }, I'm
    // just going to shoehorn in a fake profile (since we only need the id from it)
    subscribeToItem(context.backend, observer, orgId, {
      id: reconciledItem.id,
      profile: { id: taskItem.customerId },
    }).catch(err => {
      unsubscribe(this.backend, reconciledItem.id);
      ErrorReporter.reportError(err);
    });
  }
}
