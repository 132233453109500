import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AgentNotificationService {
  constructor(getDatabase, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = getDatabase;
  }

  findAll(attrs, callback, path, { agentId, orgId }, query) {
    const allNotifications = this.getDatabase(orgId).agentNotifications;
    const agentNotifications = allNotifications[agentId] || [];
    const sortedNotifications = _.orderBy(agentNotifications, ['createdAt'], ['desc']);

    let filteredNotifications = sortedNotifications;
    if (query.before) {
      filteredNotifications = _.filter(sortedNotifications, notification => notification.createdAt < query.before);
    } else if (query.after) {
      filteredNotifications = _.filter(sortedNotifications, notification => notification.createdAt > query.after);
    }
    if (query.limit) {
      filteredNotifications = filteredNotifications.slice(0, query.limit);
    }

    callback(null, { status: 200, statusText: statusText(200), response: filteredNotifications });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/agents/:agentId/notifications': {
          GET: this.findAll,
        },
      },
      this
    );
  }
}
