import createEnum from 'scripts/lib/create_enum';

const InteractionType = createEnum(
  'CHAT',
  'COBROWSE',
  'CONVERSATION',
  'CONVERSATION_NOTE',
  'COMPLETE_CONVERSATION_SUMMARY',
  'CREDIT_CARD_REQUEST',
  'CUSTOM_CHANNEL',
  'EMAIL',
  'EXTERNAL_FORM',
  'FB_MESSAGE',
  'INSTAGRAM_DIRECT',
  'PHONE_CALL',
  'PHONE_CALLBACK',
  'SMS',
  'TASK_EDIT',
  'TASK',
  'TWITTER',
  'WHATSAPP'
);

export default InteractionType;
