import _ from 'lodash';
import createConverter from './lib/create_converter';
import Relationship from 'models/relationship';

const relationshipConverter = createConverter(Relationship);

export default {
  fromDto: dto => {
    const newDto = _.cloneDeep(dto);
    newDto.customerId = dto.otherCustomerId;
    delete newDto.otherCustomerId;
    return relationshipConverter.fromDto(newDto);
  },
  toDto: (model, originalCustomerId) => {
    const dto = relationshipConverter.toDto(model);
    dto.otherCustomerId = dto.customerId;
    dto.customerId = originalCustomerId;
    delete dto.customerProfile;
    return dto;
  },
};
