import qconsole from 'scripts/lib/qconsole';

export default class NoReplyNeededGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddSuccess(payload) {}

  handleAddErrors(errorsDto) {
    qconsole.warn(`Failed to add no reply needed ${JSON.stringify(errorsDto)}`);
  }
}
