import moment from 'moment';
import { removeBodyHtmlTag } from 'scripts/lib/html_helpers';

export const BLOCKQUOTE_STYLE = 'margin:0.5ex 0 0 0.8ex;border-left:1px #ccc solid;padding-left:1ex';

export function createQuotedEmailTextPlain(emailItem) {
  if (emailItem.content.bodyPlain) {
    let responseHeader = `On ${moment(emailItem.timestamp).format('dddd, MMMM D, YYYY, h:mm A')} ${
      emailItem.content.from
    } wrote:`;

    return `${responseHeader}\r\n\r\n${emailItem.content.bodyPlain
      .split(/\r?\n/)
      .map(line => `> ${line}\r\n`)
      .join('')}`;
  }

  return null;
}

export function createQuotedEmailTextHtml(emailItem) {
  let quotedText = emailItem.content.bodyHtml;
  if (!quotedText && emailItem.content.bodyPlain) {
    quotedText = `<div>${emailItem.content.bodyPlain.replace(/(\r\n|\n|\r)/g, '<br>')}</div>`;
  }

  if (quotedText) {
    const sanitizedQuotedText = removeBodyHtmlTag(quotedText);
    const timestamp = moment(emailItem.timestamp).format('dddd, MMMM D, YYYY, h:mm A');
    const escapedFromAddress = htmlEscapeFromAddress(emailItem.content.from);

    return `<div><br><div><div>On ${timestamp} ${escapedFromAddress} wrote:</div><div dir="auto"><blockquote style="${BLOCKQUOTE_STYLE}">${sanitizedQuotedText}</blockquote></div></div><br></div>`;
  }

  return null;
}

/*
  The fromAddress will often be formatted as "John Smith" <jsmith@example.com>.
  We need to escape the characters in order for them to get rendered in the HTML email.
*/
function htmlEscapeFromAddress(fromAddress = '') {
  return fromAddress
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}
