import DtoConverter from 'scripts/application/dto_converters/user_converter';
import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';
import qconsole from 'scripts/lib/qconsole';
import UserEditorUpdater from './lib/user_editor_updater';

export default class UserGatewayObserver {
  constructor(context, userEditorUpdater = new UserEditorUpdater(context)) {
    this.context = context;
    this.userEditorUpdater = userEditorUpdater;
  }

  get store() {
    return this.context.stores.users;
  }

  handleRequestedUsers(usersDto) {
    this.store.set(usersDto.map(DtoConverter.fromDto));
    this.store.resetLoading();
    this.userEditorUpdater.update();
  }

  handleRequestFetchError(payload) {
    qconsole.log(`Failed to fetch users list ${payload}`);
  }

  handleUser(userDto) {
    this.store.addOrReplace(DtoConverter.fromDto(userDto));
  }

  handleDeleteUser(userId) {
    this.store.remove(userId);
  }

  handleUpdateErrors(userId, errorsDto) {
    let errors = errorsDto.errors.map(ErrorDtoConverter.fromDto);
    this.userEditorUpdater.setErrors(userId, errors);
    this.store.resetPending(userId);
  }

  handleUpdateSuccess(userId) {
    this.store.commitPending(userId);
    this.userEditorUpdater.remove(userId);
  }

  getNewAgentId() {
    let editableUser = this.context.stores.userEditor.get();
    return editableUser && editableUser.id;
  }

  get userEditorStore() {
    return this.context.stores.userEditor;
  }

  get userEditor() {
    return this.userEditorStore.get();
  }
}
