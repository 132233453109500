import createModel, { prop } from 'models/lib/create_model';
import createEnum from 'scripts/lib/create_enum';

export const FormSectionTypes = createEnum('text', 'input');
export const FormInputSectionTypes = createEnum('text', 'select', 'checkbox');

export default createModel({
  modelName: 'ExternalActionForm',

  properties: {
    title: prop(String),
    actionUrl: prop(String),
    closeButton: prop(String),
    submitButton: prop(String),
    sections: prop(Object),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
