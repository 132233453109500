import React, { useCallback } from 'react';

import connect from 'components/lib/connect';
import DropdownMenu from 'components/common/dropdown_menu_v2';
import { NotificationSoundType } from 'actions/notification/lib/notification_constants';
import UpdateAgentNotificationSoundType from 'actions/agent_preferences/update_agent_notification_sound_type';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const OPTIONS = Object.freeze([
  {
    label: 'Basic sound',
    value: NotificationSoundType.AGENT_NOTIFICATION,
  },
  {
    label: 'No sound',
    value: '',
  },
]);

export function AgentNotificationSound({ soundType }) {
  const executeAction = useExecuteAction();
  const onSelect = useCallback(
    selectedSoundType => {
      executeAction(UpdateAgentNotificationSoundType, { type: selectedSoundType });
    },
    [executeAction]
  );

  return <DropdownMenu allowEmptyValues onSelect={onSelect} options={OPTIONS} value={soundType || ''} />;
}

const AgentNotificationSoundContainer = connect(mapStateToProps)(AgentNotificationSound);

function mapStateToProps({ getProvider }) {
  const preferences = getProvider('agentPreferences').get();

  return {
    soundType: preferences.agentNotificationSound,
  };
}

export default AgentNotificationSoundContainer;
