import _ from 'lodash';
import { createPluginFactory } from '@udecode/plate';

export const TYPING_INDICATOR = 'typing-indicator';

export default function createTypingIndicatorPlugin(onTypingRef) {
  let previousChildren = null;

  const p = createPluginFactory({
    key: TYPING_INDICATOR,

    handlers: {
      onChange: () => children => {
        if (previousChildren && previousChildren !== children) {
          onTypingRef.current.onStartTyping();
          if (isNonEmpty(children)) {
            onTypingRef.current.onStopTyping();
          }
        }
        previousChildren = children;
      },
    },
  });
  return p();
}

export function isNonEmpty(children) {
  if (children.length > 1) {
    return true;
  }

  const grandChildren = children[0]?.children;
  if (grandChildren.length === 1) {
    return !_.isEqual(grandChildren[0], { text: '' });
  }
  if (grandChildren[1]?.type === 'autocomplete') {
    return false;
  }

  return grandChildren && grandChildren.length > 1;
}
