import _ from 'lodash';
import ModelFactory from 'scripts/domain/__specs__/factories/model_factory';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AgentStatusService {
  constructor(database, pubsub) {
    this.pubsub = pubsub;
  }

  get(data, callback, path, { orgId, agentId }) {
    callback(null, { status: 200, statusText: statusText(200), response: this.getAgentStatus({ agentId }) });
  }

  getAgentStatus({ agentId }) {
    // Use BUSY as the default to avoid becoming OFFLINE upon browser refresh
    return this.agentStatus || ModelFactory.attributes('AgentStatusBusy', { id: agentId });
  }

  getAgentStatuses(data, callback, path, { orgId }, query) {
    let response = [
      ModelFactory.attributes('AgentStatusReady'),
      ModelFactory.attributes('AgentStatusReady', { id: 'another-agent-id' }),
    ];
    callback(null, { status: 200, statusText: statusText(200), response });
  }

  update({ availability, awayReasonId, _version }, callback, path, { orgId, agentId }) {
    let currentStatus = this.getAgentStatus({ agentId });
    this.agentStatus = {
      id: agentId,
      status: _.assign(
        currentStatus.status,
        availability && { availability },
        awayReasonId && { reasonId: awayReasonId }
      ),
      availabilityUpdated: now(),
      updatedAt: now(),
      _version: _version || currentStatus._version + 1,
    };

    this.pubsub.publish(`v3/orgs/${orgId}/agent-status/${agentId}`, {
      payload: this.agentStatus,
    });

    callback(null, { status: 200, statusText: statusText(200), response: this.agentStatus });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v3/orgs/:orgId/agent-status': {
          GET: this.getAgentStatuses,
        },
        '/api/v3/orgs/:orgId/agent-status/:agentId': {
          GET: this.get,
          PATCH: this.update,
        },
      },
      this
    );
  }
}

function now() {
  return new Date().toISOString();
}
