import React from 'react';
import T from 'prop-types';

import connect from 'components/lib/connect';
import Endpoint from 'models/endpoint';
import { H3 } from 'components/common/headers';
import MailSelected from 'components/lib/icons/liveboards_capacity_card_mail_icon';
import MessagingSelected from 'components/page_layout/agent_status/icons/messaging_selected';
import { NumberCard } from 'components/liveboards/cards/metric_card';
import VoiceSelected from 'components/page_layout/agent_status/icons/voice_selected';

export function CapacityCard({ data, hasVoice, hasMail, hasMessaging }) {
  let messagingCapacityCardLabel = 'Messaging';
  let mailCapacityCardLabel = 'Email + Tasks';

  return (
    <div className="summaryLiveboard-stats-capacity capacityCard capacityCard-card">
      <H3 data-aid="capacityCard-title">Capacity</H3>
      <div className="capacityCard-container">
        <div className="capacityCard-overview">
          <NumberCard
            className="capacityCard-overview-number"
            data-aid="capacity_totalAgentsOnline"
            decimalPlaces={0}
            label="Agents online"
            shouldDrawBorder={false}
            value={data.online}
            zeroValue="0"
          />
          <NumberCard
            className="capacityCard-overview-number"
            data-aid="capacity_agentsWorking"
            decimalPlaces={0}
            label="Agents working"
            shouldDrawBorder={false}
            value={data.online - data.away}
            zeroValue="0"
          />
          <NumberCard
            className="capacityCard-overview-number"
            data-aid="capacity_agentsAway"
            decimalPlaces={0}
            label="Agents away"
            shouldDrawBorder={false}
            value={data.away}
            zeroValue="0"
          />
        </div>
        <div className="capacityCard-stats">
          {hasVoice && (
            <CapacityCardDataRow
              available={data.readyForPhone}
              channel="voice"
              label="Voice"
              working={data.onlineForPhone}
            />
          )}
          {hasMail && (
            <CapacityCardDataRow
              available={data.readyForNonInteractive}
              channel="mail"
              label={mailCapacityCardLabel}
              working={data.onlineForNonInteractive}
            />
          )}
          {hasMessaging && (
            <CapacityCardDataRow
              available={data.readyForChat}
              capacity={data.freeChatCapacity}
              channel="messaging"
              label={messagingCapacityCardLabel}
              working={data.onlineForChat}
            />
          )}
        </div>
      </div>
    </div>
  );
}

CapacityCard.propTypes = {
  data: T.object.isRequired,
  hasMessaging: T.bool,
  hasMail: T.bool,
  hasVoice: T.bool,
};

export function CapacityCardDataRow({ label, channel, working, available, capacity }) {
  const channelIcons = {
    messaging: <MessagingSelected />,
    voice: <VoiceSelected />,
    mail: <MailSelected />,
  };

  let pct = Math.round(100 * ((working - available) / working));
  let iconComponent = channelIcons[channel];

  let freeMessagingCapacityLabel = 'Free Messaging Capacity';

  return (
    <div className="capacityCard-stats-row">
      <div className="capacityCard-icon-container">{iconComponent}</div>
      <div className="capacityCard-stats-container">
        <div className="capacityCard-stats-row-channel-title" data-aid={`${channel}_capacityCardStats_title`}>
          {label} | {available} Available
        </div>
        {channel === 'messaging' && (
          <div className="capacityCard-stats-row-channel-data">
            <span
              className="capacityCard-stats-row-channel-data-number"
              data-aid={`${channel}_capacityCardRow_capacity`}
            >
              {capacity}
            </span>{' '}
            {freeMessagingCapacityLabel}
          </div>
        )}
        <div className="capacityCard-stats-row-channel-data" data-aid={`${channel}_capacityCardRow_data`}>
          <span className="capacityCard-stats-row-channel-data-number" data-aid={`${channel}_capacityCardRow_occupied`}>
            {working - available}
          </span>{' '}
          Occupied out of{' '}
          <span className="capacityCard-stats-row-channel-data-number" data-aid={`${channel}_capacityCardRow_working`}>
            {working}
          </span>{' '}
          Working
        </div>
        <div className="capacityCard-stats-progressbar capacityCard-stats-progressbar-background">
          <div
            className={`capacityCard-stats-progressbar capacityCard-stats-progressbar-${channel}`}
            style={{ width: `${pct}%` }}
          />
        </div>
      </div>
    </div>
  );
}

CapacityCardDataRow.propTypes = {
  available: T.number.isRequired,
  capacity: T.number,
  channel: T.string.isRequired,
  label: T.string.isRequired,
  working: T.number.isRequired,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  let channelConfiguration = getProvider('channelConfiguration').get() || {};
  let liveboard = getProvider('liveboard').get();
  const data = liveboard.agent;

  const hasChat = hasEndpoint(Endpoint.Type.CHAT);
  const hasSMS = hasEndpoint(Endpoint.Type.SMS);
  const hasFbMessenger = hasEndpoint(Endpoint.Type.FB_MESSENGER);
  const hasWhatsApp = hasEndpoint(Endpoint.Type.WHATSAPP);
  const hasMail = hasEndpoint(Endpoint.Type.EMAIL);
  const hasVoice = hasEndpoint(Endpoint.Type.VOICE);

  const hasMessaging = hasChat || hasSMS || hasFbMessenger || hasWhatsApp;

  return {
    data,
    hasMail,
    hasMessaging,
    hasVoice,
  };

  function hasEndpoint(type) {
    return (channelConfiguration.endpoints || []).some(e => e.type === type);
  }
}

export default connect(mapStateToProps)(CapacityCard);
