import createReactClass from 'create-react-class';
import React from 'react';
import Morearty from 'morearty';

import ActionMixin from 'components/lib/action_mixin';
import CloseModal from 'actions/modal/close_modal';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import KeypressGlobalMixin from 'components/lib/keypress_global_mixin';
import Modal from './modal';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';

const ModalContainer = createReactClass({
  mixins: [ActionMixin, KeypressGlobalMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('modal'));
  },

  onCancel() {
    this.closeModal();
  },

  onError({ message, meta }) {
    ErrorReporter.reportError(Error('Error while trying to display a modal'), {
      extra: meta,
      message,
    });
    this.executeAction(CloseModal);
  },

  closeModal() {
    this.executeAction(CloseModal);
  },

  getModalShown() {
    return this.getProvider('modal').get();
  },

  render() {
    const modalShown = this.getModalShown();
    return (
      <div>
        <div id="selfServiceModal-container" />
        <div id="deleteAnswerCardModal-container" />
        <Modal modalShown={modalShown} onClose={this.closeModal} onError={this.onError} />
      </div>
    );
  },
});

export default ModalContainer;
