import React from 'react';
import styled from 'styled-components';

import { EmptyState, EmptyText } from 'components/customer/answer_panel/lib/empty_state';
import BooksIcon from 'components/lib/icons/books_icon';

export default function AnswerPanelDefault() {
  return (
    <AnswerPanelDefaultContainer>
      <BooksIcon />
      <StyledEmptyText>Preview Answers</StyledEmptyText>
    </AnswerPanelDefaultContainer>
  );
}

const AnswerPanelDefaultContainer = styled(EmptyState)`
  padding: 0px 20px;
`;

const StyledEmptyText = styled(EmptyText)`
  padding-top: 0;
`;
