import Picker from '@emoji-mart/react';
import React, { useCallback, useEffect, useRef } from 'react';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import EmojiIcon from 'components/lib/icons/emoji_picker_icon';
import { Item } from 'components/text_editor_new/components/controls/controls_new';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { usePopoverMenu } from 'components/common/menu';
import Theme from 'scripts/presentation/themes/default.js';
import TrackEmojiUsage from 'actions/composer/track_emoji_usage';
import { useExecuteAction } from 'components/hooks/connect_hooks';

// icons
import AppleIcon from 'components/lib/icons/apple_icon';
import CarIcon from 'components/lib/icons/car_icon';
import ClockIcon from 'components/lib/icons/clock_icon';
import DogIcon from 'components/lib/icons/dog_icon';
import FlagIcon from 'components/lib/icons/flag_icon';
import LightBulbIcon from 'components/lib/icons/lightbulb_icon';
import SoccerBallIcon from 'components/lib/icons/soccer_ball_icon';
import SymbolsIcon from 'components/lib/icons/symbols_icon';

const Emoji = ({ editorRef, menuMargin, menuPosition, positionRef }) => {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();

  // If we pass in a positionRef, use that instead
  useEffect(() => {
    if (positionRef) {
      setTargetRef(positionRef.current);
    }
  }, [positionRef]);

  const insertEmoji = useInsertEmoji(editorRef.current, onClose);

  const icons = {
    categories: {
      recent: () => <ClockIcon />,
      people: () => <EmojiIcon />,
      nature: () => <DogIcon />,
      foods: () => <AppleIcon />,
      activity: () => <SoccerBallIcon />,
      places: () => <CarIcon />,
      objects: () => <LightBulbIcon />,
      symbols: () => <SymbolsIcon />,
      flags: () => <FlagIcon />,
    },
  };
  return (
    <React.Fragment>
      <OutsideClickHandler onClickOutside={onClose}>
        <EmojiButton
          data-aid={`slateEmoji-button`}
          isActive={isOpen}
          onClick={onToggle}
          ref={positionRef ? undefined : setTargetRef}
          title="Emoji"
        >
          <StyledEmojiIcon />
        </EmojiButton>
        <StyledMenu
          data-aid={`slateEmoji-menu`}
          isOpen={isOpen}
          margin={menuMargin || 8}
          onClickOutside={null}
          onClose={onClose}
          position={menuPosition || 'top'}
          targetPosition="center"
          targetRef={targetRef}
        >
          <Picker
            autoFocus
            color={Theme.colors.gray600}
            emoji=""
            emojiTooltip
            icons={icons}
            native
            notFoundEmoji="mag"
            onEmojiSelect={insertEmoji}
            previewPosition="none"
            showPreview={false}
            showSkinTones={false}
            style={{ minWidth: '394px' }}
            theme="light"
            title=""
          />
        </StyledMenu>
      </OutsideClickHandler>
    </React.Fragment>
  );
};

export function useInsertEmoji(editor, onClose) {
  const isShiftHeldRef = useIsShiftHeldRef();
  const executeAction = useExecuteAction();
  return useCallback(
    emoji => {
      editor.insertText(emoji.native);
      ReactEditor.focus(editor);

      executeAction(TrackEmojiUsage, { name: emoji.name });
      !isShiftHeldRef.current && onClose();
    },
    [editor, onClose, executeAction]
  );
}

function useIsShiftHeldRef() {
  const isShiftHeldRef = useRef(false);
  useEffect(() => {
    function downHandler({ key }) {
      if (key === 'Shift') {
        isShiftHeldRef.current = true;
      }
    }

    function upHandler({ key }) {
      if (key === 'Shift') {
        isShiftHeldRef.current = false;
      }
    }

    window.addEventListener('keydown', downHandler, true);
    window.addEventListener('keyup', upHandler, true);
    return () => {
      window.removeEventListener('keydown', downHandler, true);
      window.removeEventListener('keyup', upHandler, true);
    };
  }, []);
  return isShiftHeldRef;
}

export default Emoji;

const StyledEmojiIcon = styled(EmojiIcon)`
  height: 13px;
  width: 13px;
`;

const activeButton = css`
  ${StyledEmojiIcon} {
    fill: ${p => p.theme.colors.gray900};
  }
`;

const EmojiButton = styled(Item)`
  ${StyledEmojiIcon} {
    fill: ${p => p.theme.colors.gray800};
  }

  &:hover,
  &:active {
    ${StyledEmojiIcon} {
      fill: ${p => p.theme.colors.gray900};
    }
  }
  ${p => p.isActive && activeButton}
`;

const StyledMenu = styled(PopoverMenu)`
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.1);
`;
