import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';

export default function ChatQuickReplyResponseContent({ className, item, status }) {
  return (
    <BubbleContent
      className={classnames('chatItem-content quick-reply-response', className)}
      data-aid={`chatItem-quick-reply-response-${item.id}`}
      item={item}
      status={status}
    >
      <InlineAnnotatedContentContainer id={item.id} isRedacted={item.content.isRedacted} text={item.content.text} />
    </BubbleContent>
  );
}

ChatQuickReplyResponseContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  status: PropTypes.string,
};
