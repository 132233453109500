import _ from 'lodash';

import ClearMergeProfiles from 'actions/customer/clear_merge_profiles';
import CompleteMergeCustomers from 'actions/customer/complete_merge_customers';
import connect from 'components/lib/connect';
import CustomerCombine from './customer_combine';
import CustomerMerge from 'models/customer_merge';
import MergeCustomers from 'actions/customer/merge_customers';

export default connect(mapStateToProps, mapExecuteToProps)(CustomerCombine);

function mapStateToProps({ getProvider }, { customerId }) {
  const conversationsProvider = getProvider('conversations');
  const profileProvider = getProvider('profile');
  const customerMergeProvider = getProvider('customerMerge');

  const customerMerge = customerMergeProvider.get();
  const mergedCustomerId =
    (customerMerge &&
      _.get(customerMerge, 'sourceCustomerId') === customerId &&
      _.get(customerMerge, 'destCustomerId')) ||
    undefined;

  return {
    customerId,
    errors: customerMergeProvider.getErrors(),
    isLoading: profileProvider.isLoading() || conversationsProvider.isLoading(),
    isResponsePending: customerMergeProvider.isPending(),
    mergedCustomerId,
    profile: profileProvider.get(),
  };
}

function mapExecuteToProps(executeAction, { customerId }) {
  return {
    onCombine: sourceCustomerId => executeAction(MergeCustomers, newCustomerMerge(sourceCustomerId)),
    onCombineSuccess: () => executeAction(CompleteMergeCustomers, customerId),
    onFinishCombine: () => executeAction(ClearMergeProfiles),
  };

  function newCustomerMerge(sourceCustomerId) {
    return new CustomerMerge({ sourceCustomerId, destCustomerId: customerId });
  }
}
