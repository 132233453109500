import React, { useEffect, useRef, useState } from 'react';
import PropType from 'prop-types';
import _ from 'lodash';

import {
  StyledInput,
  StyledLinkMenuButton,
  StyledLinkMenuSeparator,
  StyledLinkMenuWrapper,
  StyledMenu,
} from 'components/composer/inline_links/link_button_styles';
import KeypressEventHandler from 'components/lib/dom_controls/lib/keypress_event_handler';

/**
 * A simple "link editor" component. To avoid dealing with "stale state", the component uses a non-controlled
 * input and keeps intermediary values in refs vs. the more traditional internal state approach.
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
export function LinkEditorPopover({
  className,
  'data-aid': dataAid,
  initialValue,
  isEditing,
  isOpen,
  onClose,
  onRemove,
  onSubmit,
  verticalPosition,
  horizontalPosition,
  targetRef,
}) {
  const trimmedInitialValue = _.trim(initialValue);
  const [, refresh] = useState({});
  const enableSubmitRef = useRef(false);
  const inputFieldRef = useRef();

  // Help with focusing the input field for composers that may have multiple autofocus elements
  useEffect(() => {
    if (isOpen && inputFieldRef.current) {
      inputFieldRef.current.value = trimmedInitialValue;
      inputFieldRef.current.focus();
      enableSubmitRef.current = false;
      refresh({});
    }
  }, [isOpen, trimmedInitialValue]);

  if (!isOpen) return null;

  const onChange = event => {
    const newValue = _.trim(event.target.value);
    const isChanged = newValue !== trimmedInitialValue;
    if (isChanged !== enableSubmitRef.current) {
      enableSubmitRef.current = isChanged;
      refresh({});
    }
  };

  const onClickSubmit = event => {
    event.preventDefault();
    if (!enableSubmitRef.current) return;

    return onSubmit(_.trim(inputFieldRef.current.value));
  };

  const keypressEventHandler = new KeypressEventHandler(onClickSubmit, onClose, { isInputField: true });
  return (
    <StyledMenu
      className={className}
      data-aid={dataAid || 'link-menu'}
      isOpen={isOpen}
      onClickOutside={null}
      onClose={onClose}
      position={verticalPosition || 'top'}
      targetPosition={horizontalPosition || 'center'}
      targetRef={targetRef}
    >
      <StyledLinkMenuWrapper>
        <StyledInput
          autoFocus
          onChange={onChange}
          placeholder="Type or paste URL"
          ref={inputFieldRef}
          {...keypressEventHandler.reactAttributes()}
        />
        <StyledLinkMenuSeparator />
        {isEditing ? (
          <>
            <StyledLinkMenuButton disabled={!enableSubmitRef.current} onClick={onClickSubmit}>
              Update
            </StyledLinkMenuButton>
            <StyledLinkMenuButton onClick={onRemove}>Remove</StyledLinkMenuButton>
          </>
        ) : (
          <StyledLinkMenuButton disabled={!enableSubmitRef.current} onClick={onClickSubmit}>
            Add
          </StyledLinkMenuButton>
        )}
      </StyledLinkMenuWrapper>
    </StyledMenu>
  );
}

LinkEditorPopover.propTypes = {
  className: PropType.string,
  'data-aid': PropType.string,
  horizontalPosition: PropType.string,
  initialValue: PropType.string,
  isEditing: PropType.bool,
  isOpen: PropType.bool,
  onClose: PropType.func.isRequired,
  onRemove: PropType.func.isRequired,
  onSubmit: PropType.func.isRequired,
  targetRef: PropType.shape({ current: PropType.node }),
  verticalPosition: PropType.string,
};
