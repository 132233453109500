import { Editor, Text } from 'slate';

/**
 * Checks whether the editor has no readable (non-whitespace) text. NOTE: this method
 * does not account for voids, images and other non-text nodes.
 *
 * @param editor
 * @returns {boolean}
 */
export function editorHasNoText(editor) {
  if (!editor?.children?.length) {
    return true;
  }

  // Try to find a text node that has a non-whitespace character in it.
  // NOTE: we do not account for non-text nodes, such as voids, images etc.
  const { done } = Editor.nodes(editor, {
    at: [],
    match: node => Text.isText(node) && /\S/g.test(node.text),
  }).next();
  return done;
}
