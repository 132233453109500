import React from 'react';

import Toast from 'models/toast';
import AgentMentionedInItem from './toast_content/agent_mentioned_in_item_toast';
import AgentMentionedInTaskComment from './toast_content/agent_mentioned_in_task_comment_toast';
import Basic from './toast_content/basic_toast';
import UnreadNotifications from './toast_content/unread_notifications_toast';

export default function getContentForToast(toast) {
  switch (toast.type) {
    case Toast.Type.AGENT_MENTIONED_IN_ITEM:
      return <AgentMentionedInItem toast={toast} />;
    case Toast.Type.AGENT_MENTIONED_IN_TASK_COMMENT:
      return <AgentMentionedInTaskComment toast={toast} />;
    case Toast.Type.BASIC:
      return <Basic toast={toast} />;
    case Toast.Type.UNREAD_NOTIFICATIONS:
      return <UnreadNotifications toast={toast} />;
    default:
      return null;
  }
}
