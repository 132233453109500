import _ from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ReasonType } from 'models/agent_status_reason';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import DropdownMenu, { LabelContainer } from 'components/common/dropdown_menu';
import { H2 } from 'components/common/headers';
import ModalCard from 'components/common/modal_card';
import Menu from 'components/common/menu';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import ProgressBar from 'components/common/progress_bar';
import ReturnFromAway from 'actions/agent_status/return_from_away';
import StayAway from 'actions/agent_status/stay_away';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function StatusReasonsModal({ agentName, isIdleReasonPending, reasons }) {
  const executeAction = useExecuteAction();
  const [selectedReasonId, setSelectedReasonId] = useState(null);

  const onClickContinue = () => {
    if (selectedReasonId) {
      executeAction(ReturnFromAway, { reasonId: selectedReasonId, isReturningFromIdle: true });
    }
  };
  const onCancel = () => {
    executeAction(StayAway);
  };

  return (
    <PortalModalWrapper>
      <ProgressBar isVisible={isIdleReasonPending} />
      <Modal>
        <ModalCard>
          <Close onClick={onCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
          <Header>Welcome back{agentName ? `, ${agentName}` : ''}</Header>
          <Description>What did you do while you were away?</Description>
          <StyledDropdownMenu
            disabled={isIdleReasonPending}
            menuComponent={StyledMenu}
            onSelect={setSelectedReasonId}
            options={reasons}
            placeholder="Select an Away Status"
            value={selectedReasonId}
          />
          <Actions>
            <Button buttonType={ButtonTypes.TEXT} onClick={onCancel}>
              Cancel
            </Button>
            <SendButton
              buttonType={ButtonTypes.PRIMARY}
              disabled={!selectedReasonId || isIdleReasonPending}
              onClick={onClickContinue}
            >
              Continue
            </SendButton>
          </Actions>
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

function optionsForAgentStatusReasons(reasons) {
  return _(reasons)
    .map(r => ({ value: r.id, label: r.name }))
    .value();
}

StatusReasonsModal.propTypes = {
  agentName: PropTypes.string,
  isIdleReasonPending: PropTypes.bool,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};

function mapStateToProps({ getProvider }) {
  let reasons = optionsForAgentStatusReasons(
    getProvider('agentStatusReasons').findAll({
      filter: r => !r.disabled && r.type === ReasonType.AWAY,
    })
  );
  let currentAgent = getProvider('currentAgent').get();
  const pendingStatus = getProvider('agentStatus').getPending();
  const isIdleReasonPending = !!_(pendingStatus).get('status.activeReasonId');

  return { agentName: currentAgent.name, isIdleReasonPending, reasons };
}

const StatusReasonsModalContainer = connect(mapStateToProps)(StatusReasonsModal);

export default StatusReasonsModalContainer;

const StyledMenu = styled(Menu)`
  width: 200px;
  z-index: 21;

  ${LabelContainer} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 200px;
`;

const SendButton = styled(Button)`
  margin-left: ${p => p.theme.spacing.large};
`;

const Close = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  position: absolute;
  right: 0px;
  top: 0px;

  &:hover {
    color: ${p => p.theme.colors.gray600};
  }
`;

const Header = styled(H2)`
  display: inline;
  padding: 0 16px;
`;

const Description = styled.p``;

const Modal = styled.div`
  text-align: center;
  .modalCard-content {
    padding: ${p => p.theme.spacing.large};
    position: relative;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => p.theme.spacing.large};
`;
