import _ from 'lodash';
import PropTypes from 'prop-types';

import AutoAcceptedSessionNotification from 'models/notification/auto_accepted_session_notification';
import ClearNotificationTimer from 'actions/notification/clear_notification_timer';
import connect from 'components/lib/connect';
import ConversationNotification from 'models/notification/conversation_notification';
import FollowIncomingNotification from 'actions/notification/follow_incoming_notification';
import NotificationContent from './notification_content';
import StartNotificationTimer from 'actions/notification/start_notification_timer';
import TaskNotification from 'models/notification/task_notification';

const NotificationContentContainer = connect(mapStateToProps, mapExecuteToProps, mergeProps)(NotificationContent);

NotificationContentContainer.propTypes = {
  body: PropTypes.string,
  containerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconClass: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  notification: PropTypes.oneOfType([
    PropTypes.instanceOf(AutoAcceptedSessionNotification),
    PropTypes.instanceOf(ConversationNotification),
    PropTypes.instanceOf(TaskNotification),
  ]).isRequired,
  title: PropTypes.string,
};

function mergeProps(stateProps, execProps, ownProps) {
  return Object.assign({}, stateProps, execProps);
}

function mapExecuteToProps(executeAction, { notification }) {
  return {
    onClick: followIncomingNotification,
    onMouseEnter: () => executeAction(ClearNotificationTimer, notification.id),
    onMouseLeave: () => executeAction(StartNotificationTimer, notification.id),
  };

  function followIncomingNotification() {
    executeAction(FollowIncomingNotification, notification);
  }
}

function mapStateToProps(context, props) {
  return {
    ..._.omit(props, 'notification'),
    notificationId: props.notification.id,
  };
}

export default NotificationContentContainer;
