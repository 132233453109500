import {
  createPlugins,
  createBoldPlugin, // bold mark
  createItalicPlugin, // italic mark
  createListPlugin,
  createUnderlinePlugin, // underline mark
  createSoftBreakPlugin,
  ELEMENT_LI,
  getPluginType,
  someNode,
} from '@udecode/plate';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AddCommentToTask from 'actions/task/add_comment_to_task';
import clearEditorAndHistory from 'components/text_editor_new/lib/clear_editor_and_history';
import components from 'components/text_editor_new/plugins/components';
import createAITextCompletion from 'components/text_editor_new/plugins/ai/ai_text_completion';
import createAITextCompletionHighlight from 'components/text_editor_new/plugins/ai/ai_text_completion/ai_text_completion_highlight';
import createCapitalizeFirstWordPlugin from 'components/text_editor_new/plugins/capitalize_first_word';
import createCapitalizeIPlugin from 'components/text_editor_new/plugins/capitalize_i';
import createInsertTextAfterInline from 'components/text_editor_new/plugins/insert_text_after_inline';
import createParagraphPlugin from 'components/text_editor_new/plugins/paragraph';
import { createSubmitOnEnterPlugin } from 'components/text_editor_new/plugins/submit_on_enter';
import { editorHasNoText } from 'components/text_editor_new/lib/editor_has_no_text';
import normalizeEditor from 'components/text_editor_new/lib/normalize_editor';
import serializeHtmlExternal from 'components/text_editor_new/lib/serialize_html_external';
import TrackGrammarlyUsage from 'actions/composer/track_grammarly_usage';
import useEditorWithDecorators from 'components/text_editor_new/hooks/use_editor_with_decorators';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function useTaskCommentEditor(editorProps, itemIdRef) {
  const { editorId } = editorProps;
  const onSubmitRef = useRef();

  const isFeatureEnabled = useIsFeatureEnabled();
  const includeAIFeatures = isFeatureEnabled('aiTextEditE2E');
  const createSubmitOnEnter = createSubmitOnEnterPlugin(onSubmitRef);

  // Rather than useMemo, we lock in the value of aiPlugins on first render. We don't have to worry
  // about includeAIFeatures changing because we're using withRefreshEditorWhenFlagsChange, which
  // forcibly remounts the parent component when the flag changes.

  // This is all done because Plate / Slate do not like having plugins change after the editor is
  // initialized.
  const [aiPlugins] = useState(() => {
    return includeAIFeatures ? [createAITextCompletion(), createAITextCompletionHighlight()] : null;
  });

  const plugins = useMemo(() => {
    let pluginList = [
      // This plugin must come first
      createInsertTextAfterInline(),

      // Autocapitalization
      createCapitalizeIPlugin(),
      createCapitalizeFirstWordPlugin(),

      // elements
      createParagraphPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createListPlugin(),

      createSoftBreakPlugin(),
      createSubmitOnEnter(),
    ];

    if (aiPlugins && aiPlugins.length) {
      pluginList = [...pluginList, ...aiPlugins];
    }

    return createPlugins(pluginList, { components });
  }, [aiPlugins]);

  const editor = useEditorWithDecorators(plugins, { editorId });

  const editorRef = useRef(editor);
  const [, rerender] = useState(null);

  const onSubmit = useOnSubmit(editorRef, itemIdRef, onSubmitRef);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = editor;
      rerender({});
    }
  }, [editor]);
  editorProps.editorRef = editorRef;

  return [editorRef, onSubmit];
}

function useOnSubmit(editorRef, itemIdRef, onSubmitRef) {
  const executeAction = useExecuteAction();

  const onSubmit = useCallback(
    evt => {
      const editor = editorRef.current;

      if (editorHasNoText(editor)) {
        return;
      }

      const listSelected = someNode(editor, {
        match: { type: getPluginType(editor, ELEMENT_LI) },
      });
      if (listSelected && evt?.key === 'Enter') {
        return;
      }

      evt.preventDefault();
      normalizeEditor(editor, { trimEmptyParagraphs: true, trimParagraphWhitespace: true });

      const content = {
        itemId: itemIdRef.current,
        message: serializeHtmlExternal(editor),
      };
      executeAction(AddCommentToTask, content);
      executeAction(TrackGrammarlyUsage);
      clearEditorAndHistory(editor);
    },
    [editorRef, itemIdRef, executeAction]
  );

  useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit, onSubmitRef]);

  return onSubmit;
}
