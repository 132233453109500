import CBuffer from 'cbuffer';

// messages that match these strings (or regexps) will not be logged
const ignores = [
  '/conversation-counts',
  '/inbox',
  '/routing-groups-routable-counts',
  '/routing-groups-conversation-counts',
  '/refresh_tokens',
  '/server',
  '/activity/command/add',
];

export default class EventRecorder {
  constructor() {
    this._events = new CBuffer(200);
    this._subscriptions = {};

    // ensure we don't leak too much memory by keeping old subscriptions around forever
    this._interval = window.setInterval(() => this._removeOldUnsubscribes(), 60 * 1000);
  }

  recordEvent(correlationId, name) {
    if (!name || ignores.some(i => name.match(i))) {
      return; // don't log unimportant messages
    }
    if (/^(SUB|UNSUB)/.test(name)) {
      let [status, topic] = name.split(' ', 2);
      this._recordSubscription(topic, status);
    }
    if (/^SUB(ACK|ERR)/.test(name)) {
      return; // don't log SUBACKs
    }
    this._events.push({ correlationId, name, timestamp: new Date() });
  }

  recentEvents() {
    return this._events
      .map(({ correlationId, name, timestamp }) => `${timestamp.toLocaleString()} ${correlationId} ${name}`)
      .join('\n');
  }

  _recordSubscription(topic, status) {
    this._subscriptions[topic] = { status, timestamp: new Date() };
  }

  _removeOldUnsubscribes() {
    const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;
    for (let topic in this._subscriptions) {
      let { status, timestamp } = this._subscriptions[topic];
      if (/^UNSUB/.test(status) && timestamp <= fiveMinutesAgo) {
        delete this._subscriptions[topic];
      }
    }
  }

  reportSubscriptions() {
    return Object.entries(this._subscriptions)
      .map(([topic, { status, timestamp }]) => {
        return `${timestamp.toLocaleString()} ${status} ${topic}`;
      })
      .sort()
      .join('\n');
  }
}
