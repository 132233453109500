import _ from 'lodash';

import Attachment from 'models/attachment';
import { AttachmentType } from 'models/attachment/attachment_classes';
import createConverter from './lib/create_converter';
import Snippet, { SnippetContentType } from 'models/answers/snippet';

const snippetConverter = createConverter(Snippet);

export default {
  fromDto: dto => {
    let newDto = snippetDtoFieldChanges(dto);
    return snippetConverter.fromDto(newDto);
  },
  toDto: dto => {
    let newDto = snippetConverter.toDto(dto);

    _.forEach(newDto.contents, content => deleteContentSource(content));

    return newDto;

    function deleteContentSource(content) {
      _.forEach(content, c => {
        _.forEach(c.attachments, a => delete a['source']);
      });
    }
  },
};

export function snippetDtoFieldChanges(snippetDto) {
  let newDto = _.cloneDeep(snippetDto);

  // legacy for specs & demo; to be deprecated
  if (newDto.content) {
    convertContent({ content: newDto.content, id: newDto.id });
  }
  if (!newDto.contents) {
    newDto.contents = newDto.content ? [newDto.content] : [];
  }

  _.forEach(newDto.contents, c => convertContent({ content: c, id: newDto.id }));
  if (snippetDto.subject) {
    // temporarily fill in email content subject if it doesn't exist and is present on the snippet
    newDto.contents = _.map(newDto.contents, c => {
      if (c.anyChannel) {
        c.anyChannel.subject = c.anyChannel.subject || snippetDto.subject;
      }
      return c;
    });
  }

  return newDto;
}

function convertContent({ content, id }) {
  _.forEach(_.values(SnippetContentType), channel => {
    let snippetContent = content[channel];
    if (!snippetContent || _.isEmpty(snippetContent)) {
      snippetContent = undefined;
      return;
    }

    convertAttachments({ snippetContent, id });
  });
}

function convertAttachments({ snippetContent, id }) {
  snippetContent.attachments = _.forEach(snippetContent.attachments, attachment => {
    attachment.type = AttachmentType.SNIPPET_ATTACHMENT;
    attachment.source = attachment.source || {
      path: Snippet.getUploadPath(id, attachment.id),
      type: Attachment.SourceType.SNIPPET,
    };
  });
}
