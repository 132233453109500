import { Factory } from 'rosie';

Factory.define('Colors');

Factory.define('ColorsWithDefaults')
  .extend('Colors')
  .attr('alert', '#E51D0B')
  .attr('attachmentBackground', '#ffffff')
  .attr('brandAccent', '#ffffff')
  .attr('brandPrimary', '#ffffff')
  .attr('brandSecondary', '#f3f3f3')
  .attr('onboardingButton', '#009b00')
  .attr('submitArrow', '#009b00');

Factory.define('ScheduleBlock');

Factory.define('ScheduleBlockWithDefaults')
  .extend('ScheduleBlock')
  .attr('start', '7:00')
  .attr('stop', '22:00');

Factory.define('OfficeHours');

Factory.define('OfficeHoursWithDefaults')
  .extend('OfficeHours')
  .attr('timezone', 'America/Los_Angeles')
  .attr('monday', () => [Factory.attributes('ScheduleBlockWithDefaults')])
  .attr('tuesday', () => [Factory.attributes('ScheduleBlockWithDefaults')])
  .attr('wednesday', () => [Factory.attributes('ScheduleBlockWithDefaults')])
  .attr('thursday', () => [Factory.attributes('ScheduleBlockWithDefaults')])
  .attr('friday', () => [Factory.attributes('ScheduleBlockWithDefaults')])
  .attr('saturday', () => [Factory.attributes('ScheduleBlockWithDefaults')])
  .attr('sunday', () => [Factory.attributes('ScheduleBlockWithDefaults')]);

Factory.define('Onboarding');

Factory.define('OnboardingWithDefaults')
  .extend('Onboarding')
  .attr('enabled', true);

Factory.define('Throttling');

Factory.define('ThrottlingWithDefaults')
  .extend('Throttling')
  .attr('autoThrottlingEnabled', false)
  .attr('autoThrottlingThreshold', 600)
  .attr('percentageRollout', 100);

Factory.define('ChatWidgetConfiguration');

Factory.define('ChatWidgetConfigurationWithDefaults')
  .extend('ChatWidgetConfiguration')
  .attr('alignment', 'RIGHT')
  .attr('appId', 'app-id')
  .attr('colors', Factory.attributes('ColorsWithDefaults'))
  .attr('enabled', true)
  .attr('officeHours', Factory.attributes('OfficeHoursWithDefaults'))
  .attr('onboarding', Factory.attributes('OnboardingWithDefaults'))
  .attr('percentageRollout', 100)
  .attr('title', 'Chat with Us')
  .attr('throttling', Factory.attributes('ThrottlingWithDefaults'))
  .attr('welcomeMessage', 'hi, how can we help you today?');
