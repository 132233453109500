import createModalModel, { prop } from './lib/create_modal_model';
import Snippet from 'models/answers/snippet';

export default createModalModel({
  modelName: 'AnswerPreview',
  properties: {
    answerId: prop(String),
    composition: prop(Snippet),
    isEditingSnippets: prop(Boolean).default(false),
  },
});
