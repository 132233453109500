import { useContext, useEffect, useRef } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import { editorHasNoText } from 'components/text_editor_new/lib/editor_has_no_text';
import { insertAnswer } from 'components/text_editor_new/lib/insert_answer';
import { SnippetContentType } from 'models/answers/snippet';
import { useVariables } from 'components/composer/contexts/variables_context';

/**
 * If there is an Answer in the ComposerContext, try to extract the body markup and insert it into the editor
 *
 * @param {*} editor
 * @param {boolean} [clearAnswerAfterInsert] - optional flag that indicates whether the answer should be cleared
 *                                             from the context after it is inserted. Typically, it would be true
 *                                             for the "main" editor and false for the auxiliary ones (if any)
 */
export function useAnswerBody(editor, clearAnswerAfterInsert) {
  const { answerToInsert, clearInsertedAnswer } = useContext(ComposerContext);
  const prevAnswerRef = useRef(null);
  const variablesRef = useVariablesRef();

  // We insert answers by setting "answerToInsert" in our composer context.. when the component using this
  // hook "sees" that update, it inserts it into the editor, then clears it from the context.
  useEffect(() => {
    if (!editor || answerToInsert === prevAnswerRef.current) return;

    prevAnswerRef.current = answerToInsert;
    if (!answerToInsert) return;

    const itemContent = getAnswerBody(answerToInsert);
    if (itemContent) {
      insertAnswer(editor, editor.plugins, variablesRef, itemContent, { snippetId: answerToInsert.id });
    }
    clearAnswerAfterInsert && clearInsertedAnswer();
  }, [editor, clearInsertedAnswer, answerToInsert, clearAnswerAfterInsert, variablesRef]);
}

/**
 * If there is an Answer in the ComposerContext, try to extract the subject markup and insert it into the editor.
 * If the editor is not empty, do not modify and return right away.
 *
 * @param {*} editor
 * @param {boolean} [clearAnswerAfterInsert] - optional flag that indicates whether the answer should be cleared
 *                                             from the context after it is inserted. Typically, it would be false
 */
export function useAnswerSubject(editor, clearAnswerAfterInsert) {
  const { answerToInsert, clearInsertedAnswer } = useContext(ComposerContext);
  const prevAnswerRef = useRef(null);
  const variablesRef = useVariablesRef();

  // We insert answers by setting "answerToInsert" in our composer context.. when the component using this
  // hook "sees" that update, it inserts it into the editor, then clears it from the context.
  useEffect(() => {
    if (!editor || answerToInsert === prevAnswerRef.current) return;

    prevAnswerRef.current = answerToInsert;
    if (!answerToInsert || !editorHasNoText(editor)) return;

    const itemContent = getAnswerSubject(answerToInsert);
    if (itemContent) {
      insertAnswer(editor, editor.plugins, variablesRef, itemContent, { snippetId: answerToInsert.id });
    }
    clearAnswerAfterInsert && clearInsertedAnswer();
  }, [editor, clearInsertedAnswer, answerToInsert, clearAnswerAfterInsert, variablesRef]);
}

//------------------------------------------------------------------------------
// Internal helpers below this line
//------------------------------------------------------------------------------
function getAnswerBody(answer) {
  if (!answer) return '';

  const channelType = answer.channelType;
  const item = answer.item;
  const content = item.findContentByLanguage(answer.language);
  return (channelType && content.getBodyByType(SnippetContentType[channelType])) || '';
}

function getAnswerSubject(answer) {
  if (!answer) return '';

  const channelType = answer.channelType;
  const item = answer.item;
  const content = item.findContentByLanguage(answer.language);
  return (channelType && content.getSubjectByType(SnippetContentType[channelType])) || '';
}

function useVariablesRef() {
  const variables = useVariables();
  const ref = useRef(variables);

  useEffect(() => {
    ref.current = variables;
  }, [variables]);

  return ref;
}
