import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoveReactionIcon from 'components/lib/icons/love_reaction_icon';
import TimestampFormatter from 'components/lib/date/lib/timestamp_formatter';

const MessageReaction = ({ timestamp, className }) => {
  const tf = new TimestampFormatter(timestamp);
  const fullTime = tf.full();
  return (
    <ReactionWrapper className={classnames('messageItem-reaction', className)} title={`${fullTime}`}>
      <LoveReactionIcon />
    </ReactionWrapper>
  );
};

const ReactionWrapper = styled.div`
  position: absolute;
  bottom: -12px;
  height: 24px;
  width: 24px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid #e3e3e3;
  border-radius: 50px;
  padding: 4px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);

  ${LoveReactionIcon} {
    height: 13px;
    width: 14px;
    fill: ${p => p.theme.colors.red400};
  }
`;

MessageReaction.propTypes = {
  timestamp: PropTypes.string,
  className: PropTypes.string,
};

export default MessageReaction;
