export default class RemoveCustomerAssignee {
  constructor(context) {
    this.context = context;
  }

  run() {
    let currentCustomerId = this.context.stores.currentLocation.get().customerId;
    this.context.gateways.customerAssignee.remove(currentCustomerId);
  }
}
