import _ from 'lodash';

import Err from 'models/err';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import qconsole from 'scripts/lib/qconsole';
import ReportConverter from 'scripts/application/dto_converters/report_converter';
import ServerClock from 'scripts/application/lib/server_clock';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import TrackEvent from 'actions/analytics/track_event';

export default class ReportGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.report;
  }

  handleRequestedReport({ reportType, reportDto, fetchPayload, correlationId, reportRequestedAt }) {
    this.store.set(ReportConverter.fromDto(reportDto, reportType));
    this.store.resetLoading();
    this.executeTrackEvent(reportType, { fetchPayload, correlationId, reportRequestedAt });
  }

  handleRequestError({ reportType, errorDto, fetchPayload, correlationId, reportRequestedAt }) {
    qconsole.log('Failed to fetch report', errorDto);
    this.store.resetLoading();

    let errors = _.get(errorDto, 'response.data.errors');

    let toastMessage = _.find(errors, err => err.code === Err.Code.NOT_EXIST)
      ? 'Reports are not enabled for this organization.'
      : 'We ran into an unexpected error. Please refresh the page.';

    this.context.executeAction(ShowToast, {
      type: ToastType.ERROR,
      message: toastMessage,
    });
    this.executeTrackEvent(reportType, { fetchPayload, correlationId, reportRequestedAt, isError: true });

    if (errorDto && errorDto.response) {
      const fetchParams = { extra: _.assign({ correlationId }, fetchPayload) };
      ErrorReporter.reportMessage(
        `report request error ${errorDto.response.status} ${errorDto.response.statusText}`,
        fetchParams
      );
    }
  }

  handleRequestCancel({ reportType, fetchPayload, correlationId, reportRequestedAt }) {
    qconsole.log('Report request canceled');
    this.store.resetLoading();
    this.executeTrackEvent(reportType, { fetchPayload, correlationId, reportRequestedAt, isCancel: true });
  }

  executeTrackEvent(reportType, { fetchPayload, correlationId, reportRequestedAt, isCancel, isError }) {
    const inboxCount = _(fetchPayload).get('filters.inboxes.length', 0);
    const teamCount = _(fetchPayload).get('filters.teams.length', 0);
    const topicCount = _(fetchPayload).get('filters.topics.length', 0);

    this.context.executeAction(TrackEvent, {
      event: 'Report Received',
      props: {
        view: 'UI',
        ..._.omitBy(fetchPayload, _.isUndefined),
        type: reportType,
        correlationId,
        reportRequestedAt,
        reportReceivedAt: ServerClock.toISOString(),
        inboxCount,
        teamCount,
        topicCount,
        isCancel: isCancel === true,
        isError: isError === true,
      },
    });
  }
}
