import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import CurrentAssignee from 'components/lib/assignment_menu/current_assignee';
import CustomerContext from 'components/customer/customer_context';
import SearchableConversationAssignmentMenu from 'components/lib/assignment_menu/searchable_conversation_assignment_menu';

export function ConversationAssignment({ canManageCustomerAssignee, customerAssignee, conversationId }) {
  if (!canManageCustomerAssignee && !conversationId) {
    const isCustomerAssigneePresent = !_.isEmpty(customerAssignee);
    let textLabel;
    if (!isCustomerAssigneePresent) {
      textLabel = 'Assigned to';
    } else if (customerAssignee.agentName) {
      textLabel = 'Dedicated Hero';
    } else {
      textLabel = 'Hero Inbox';
    }

    return (
      <CurrentAssignee
        className={classnames(
          { customerAssignee: isCustomerAssigneePresent },
          'customer-conversationResponseControls-assignment'
        )}
        onClick={_.noop}
        textLabel={textLabel}
        {...customerAssignee}
      />
    );
  }

  return <SearchableConversationAssignmentMenu conversationId={conversationId} />;
}

export const ConversationAssignmentContainer = connect(mapStateToProps)(ConversationAssignment);

ConversationAssignment.propTypes = {
  canManageCustomerAssignee: PropTypes.bool,
  conversationId: PropTypes.string,
  customerAssignee: PropTypes.shape({
    agentName: PropTypes.string,
    avatarImage: PropTypes.string,
    groupName: PropTypes.string,
    isAssigneeCurrentAgent: PropTypes.bool,
  }),
};

function mapStateToProps({ getProvider, isFeatureEnabled }, { conversationId }) {
  function getCustomerAssignee() {
    let customerAssignee = {};
    let agentProvider = getProvider('agents');
    let assigneeProvider = getProvider('assignee');
    let currentAgentProvider = getProvider('currentAgent');
    let routingGroupProvider = getProvider('routingGroups');
    if (!conversationId) {
      let assignee = assigneeProvider.get();
      if (assignee) {
        let agentId = assignee.agentId;
        if (agentId) {
          let agent = agentProvider.findBy({ id: agentId });
          customerAssignee.agentName = agent && agent.getDisplayName();
          customerAssignee.avatarImage = agent && agent.avatarUrl;
          customerAssignee.isAssigneeCurrentAgent = agentId === _.get(currentAgentProvider.get(), 'id');
        }
        let routingGroupId = assignee.routingGroupId;
        if (routingGroupId) {
          let routingGroup = routingGroupProvider.findBy({ id: routingGroupId });
          customerAssignee.groupName = routingGroup && routingGroup.name;
        }
      }
    }
    return customerAssignee;
  }

  return {
    canManageCustomerAssignee: isFeatureEnabled('customerAssigneeManagement') && isFeatureEnabled('dedicatedHero'),
    conversationId,
    customerAssignee: getCustomerAssignee(),
  };
}

/**
 * Merely reads latestConversationId from the context... will try to think of a better way to do this in the future.
 */
export default function ConversationAssignmentWithConversationId() {
  return (
    <CustomerContext.Consumer>
      {({ latestConversationId }) => <ConversationAssignmentContainer conversationId={latestConversationId} />}
    </CustomerContext.Consumer>
  );
}
