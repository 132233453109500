import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('CallForward').attr('type', 'CALL_FORWARD');

Factory.define('CallForwardWithDefaults')
  .extend('CallForward')
  .attr('forwardedToNumber', '+14158889999');

Factory.define('CallForwardItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('CallForwardWithDefaults', content);
  });

Factory.define('CallForwardItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('CallForwardWithDefaults', content);
  })
  .attr('initiator', {
    type: 'CUSTOMER',
    id: 'default-initiator-id',
  });
