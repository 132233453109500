import LogActivity from './log_activity';
import SetActivity from './set_activity';

export default class SetAndLogActivity {
  constructor(context) {
    this.context = context;
  }

  run({ agentId, availabilityChannels, channel, reasonId, routingGroupId, timestamp, type }) {
    this.context.executeAction(SetActivity, {
      agentId,
      availabilityChannels,
      channel,
      reasonId,
      routingGroupId,
      timestamp,
      type,
    });
    this.context.executeAction(LogActivity);
  }
}
