import createModel, { prop } from '../lib/create_model';

export default createModel({
  modelName: 'InstagramCustomerAddressContent',
  properties: {
    isUserFollowBusiness: prop(Boolean).default(false),
    isBusinessFollowUser: prop(Boolean).default(false),
    followerCount: prop(Number),
    isVerifiedUser: prop(Boolean).default(false),
    pageId: prop(String).Required,
  },
});
