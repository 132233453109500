import _ from 'lodash';

import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import Assignee from 'models/assignee';
import { AUTOMATION_ROUTING_GROUP_ID } from 'models/routing_group';
import clearCommunicationComposition from 'actions/composition/lib/clear_communication_composition';
import ConversationItemDtoConverter from 'scripts/application/dto_converters/conversation_item_converter';
import ConversationItemType from 'models/conversation_item_type';
import routingGroupResolver from 'actions/conversation/lib/get_default_routing_groupId_for_agent';
import { getActiveConversation } from 'models/customer';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import { getSuggestedAnswersModelVersion } from 'scripts/application/selectors/answers';
import { getInboxItemIdFromConversationItem, reconcileInboxItem } from 'actions/inbox/agent/agent_inbox_helpers';
import ItemId from 'models/item_id';
import ShowReopenConversationModal from 'actions/conversation/show_reopen_conversation_modal';
import UpdateAgentRead from 'actions/conversation/update_agent_read';
import updateComposition from 'actions/composition/lib/update_composition';
import UpdateSnippetCount from 'actions/knowledge_base/update_snippet_count';
import TrackSuggestedReplySent from 'actions/suggested_replies/track_suggested_reply_sent';

export default class SubmitComposition {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, content, conversationId }) {
    let composition = this.loadComposition(compositionId);
    let conversationItem = this.processComposition(composition, content);
    if (!conversationItem) {
      return;
    }

    this.processConversationItem(composition, conversationItem, conversationId);

    this.trackAnalytics(composition, conversationItem);
    this.afterSend(conversationItem);
  }

  loadComposition(compositionId) {
    return this.compositions.find(compositionId);
  }

  processConversationItem(composition, conversationItem, conversationId) {
    if (!conversationItem) {
      return;
    }

    this.addItemToHistory(conversationItem);
    let conversationUpdates = this.addItemToConversation(conversationItem, this.activeConversation);

    this.sendMessage(conversationUpdates, conversationItem);

    this.markConversationRead(
      this.activeConversationId || conversationId,
      conversationItem.timestamp,
      composition.customerId
    );
    this.scrollToItem(this.activeConversationId || conversationId);
    this.updateAgentInbox(conversationItem);
  }

  processComposition(composition, content) {
    // save composed content
    if (this.isNewConversationPending() || this.willShowReopenConversationModal()) {
      this.saveComposedContent(composition, content);
      return null;
    }

    // create outgoing items
    let { compositionErrors, conversationItem } = this.createOutgoingItem(composition, content);
    if (compositionErrors.length) {
      this.compositions.setErrors(composition.id, compositionErrors);
      this.saveComposedContent(composition, content);
      return null;
    }

    this.clearComposition(composition);

    return conversationItem;
  }

  clearComposition(composition) {
    // handle uploads
    composition.detachUploads();
    this.compositions.replace(composition);
    clearCommunicationComposition(this.context);
  }

  addItemToConversation(conversationItem, conversation) {
    let activeConversation = this.activeConversation;

    // add the item to the active conversation
    let conversationUpdates = this.getConversationUpdates(activeConversation);
    let effectiveUpdates = activeConversation.update(conversationUpdates);
    this.context.stores.conversations.replace(activeConversation);

    // Include conversation id in outgoing conversation updates as required by OutgoingCommunication model
    return _.assign({ id: activeConversation.id }, effectiveUpdates);
  }

  addItemToHistory(conversationItem) {
    this.context.stores.conversationHistory.add(conversationItem);
    this.context.stores.itemIds.addOrReplace(
      new ItemId({
        id: conversationItem.id,
        conversationId: this.activeConversationId,
        timestamp: conversationItem.timestamp,
      })
    );
  }

  markConversationRead(conversationId, timestamp, customerId) {
    this.context.executeAction(UpdateAgentRead, {
      agentReadAttrs: { readTo: timestamp },
      conversationId,
      customerId,
    });
  }

  trackAnalytics(composition, conversationItem) {
    // record snippet count
    _.each(conversationItem.snippetIds, id => {
      this.context.executeAction(UpdateSnippetCount, id);
    });

    let payload = {
      customerId: this.currentCustomerId,
      conversationId: this.activeConversationId,
      conversationItemId: conversationItem.id,
      compositionId: composition.id,
      contentType: composition.contentType(),
      answerSuggestionsModelVersion: getSuggestedAnswersModelVersion(this.context.stores, {
        compositionId: composition.id,
      }),
    };

    if (conversationItem && conversationItem.contentType() === ConversationItemType.TASK) {
      payload.isConversationOpen = !!this.activeConversationId;
    }

    if (conversationItem) {
      this._trackConversationResponseSentEvent(payload);
      this._trackSuggestedReplySentEvent(conversationItem, composition);
    }
  }

  scrollToItem(conversationId) {
    // Set the current item to the one we just created in order to scroll to it
    const currentLocation = this.context.stores.currentLocation.get();
    // This scrolls us down to the new item after we add it
    currentLocation.changeCurrentConversation(conversationId);
    currentLocation.clearCurrentConversationItem();
    this.context.stores.currentLocation.set(currentLocation);
  }

  isNewConversationPending() {
    return this.context.stores.conversations.isPendingNew();
  }

  willShowReopenConversationModal() {
    if (this.activeConversation) {
      return false;
    }

    this.context.executeAction(ShowReopenConversationModal);

    return true;
  }

  saveComposedContent(composition, composedContent) {
    updateComposition(
      this.context,
      composition,
      new composition.content.constructor({ ...composition.content, ...composedContent })
    );
  }

  createOutgoingItem(composition, composedContent) {
    throw new Error(`Not implemented by ${this.constructor.name}`);
  }

  get compositions() {
    return getCompositionsStore(this.context);
  }

  get activeConversation() {
    return (
      this._activeConversation ||
      (this._activeConversation = getActiveConversation(this.context.stores.conversations.findAll()))
    );
  }

  get activeConversationId() {
    // an active conversation may or may not exist after free the task
    return (this.activeConversation || {}).id;
  }

  get currentAgent() {
    return this._currentAgent || (this._currentAgent = this.context.stores.currentAgent.get());
  }

  get currentCustomerId() {
    return this._currentCustomerId || (this._currentCustomerId = this.context.stores.currentLocation.get().customerId);
  }

  getConversationUpdates(conversation) {
    if (conversation.assignee.routingGroupId === AUTOMATION_ROUTING_GROUP_ID) {
      return {
        assignee: Assignee.create({
          routingGroupId: routingGroupResolver.getDefaultRoutingGroupIdForAgent(this.context),
        }),
      };
    }
    return {};
  }

  sendMessage(conversationUpdates, outgoingItem) {
    throw new Error(`Not implemented by ${this.constructor.name}`);
  }

  updateAgentInbox(conversationItem) {
    const inboxItemId = getInboxItemIdFromConversationItem(conversationItem);
    const agentInboxItem = this.context.stores.agentInboxItems.findBy({ id: inboxItemId });
    if (agentInboxItem) {
      const inboxItemUpdates = new AgentInboxItem({
        id: conversationItem.customerId,
        item: conversationItem,
        type: AgentInboxItem.Type.CUSTOMER,
      });
      reconcileInboxItem(this.context, inboxItemUpdates);
    }
  }

  afterSend(item) {
    // no-op by default
  }

  _trackConversationResponseSentEvent({
    customerId,
    conversationId,
    conversationItemId,
    compositionId,
    contentType,
    answerSuggestionsModelVersion,
    isConversationOpen,
  }) {
    this.context.analytics.track('Conversation Response Sent', {
      customerId,
      conversationId,
      conversationItemId,
      compositionId,
      contentType,
      isConversationOpen,
      'exp-answerSuggestionsModelVersion': answerSuggestionsModelVersion,
    });
  }

  _trackSuggestedReplySentEvent(conversationItem, composition) {
    if (
      this.context.stores.appFeatures.get().isEnabled('suggestedReplies') &&
      _.isFunction(conversationItem.content.getMessageText)
    ) {
      this.context.executeAction(TrackSuggestedReplySent, {
        customerId: this.currentCustomerId,
        conversationId: conversationItem.conversationId,
        composition,
        messageSent: conversationItem.content.getMessageText(),
        appliedSuggestedReply: composition.appliedSuggestedReply,
      });
    }
  }

  isSuggestedReplyEnabledForComposition() {
    return false;
  }
}

/**
 * Removes conversation and customer ids from outgoing conversation item to avoid potential failures to locate
 * conversation due to customer merge during send.
 *
 * @param conversationItem - the outgoing conversation item to be included in the OutgoingCommunication
 * @returns conversationItem DTO with customer and conversation ids removed
 */
export function getOutgoingItemDto(conversationItem) {
  return _.omit(ConversationItemDtoConverter.toDto(conversationItem), ['conversationId', 'customerId']);
}
