import React from 'react';
import Icon from 'components/lib/icons/icon';

export default function SummaryIcon(props) {
  return (
    <Icon viewBox="0 0 19 14" {...props}>
      <path
        clipRule="evenodd"
        d="M0.25 2.55566C0.25 2.14145 0.585786 1.80566 1 1.80566H10C10.4142 1.80566 10.75 2.14145 10.75 2.55566C10.75 2.96988 10.4142 3.30566 10 3.30566L1 3.30566C0.585786 3.30566 0.25 2.96988 0.25 2.55566ZM0.25 9.88867C0.25 9.47446 0.585786 9.13867 1 9.13867L4 9.13867C4.41421 9.13867 4.75 9.47446 4.75 9.88867C4.75 10.3029 4.41421 10.6387 4 10.6387H1C0.585786 10.6387 0.25 10.3029 0.25 9.88867ZM1 5.47266C0.585786 5.47266 0.25 5.80844 0.25 6.22266C0.25 6.63687 0.585786 6.97266 1 6.97266L7 6.97266C7.41421 6.97266 7.75 6.63687 7.75 6.22266C7.75 5.80844 7.41421 5.47266 7 5.47266L1 5.47266Z"
        fillRule="evenodd"
      />
      <path d="M18.5056 1.55859L17.5036 0.519531C17.1872 0.191406 16.7389 0 16.3171 0C15.8952 0 15.4469 0.191406 15.1305 0.519531L13.1529 2.57031L12.2564 3.5L5.82263 10.1719L5.50621 13.2891C5.45347 13.6719 5.74352 14 6.11267 14C6.13904 14 6.16541 14 6.19178 14L9.19772 13.6719L15.6315 7L16.528 6.07031L18.5056 4.01953C19.1648 3.33594 19.1648 2.24219 18.5056 1.55859ZM8.61763 12.4141L6.85097 12.6055L7.03555 10.7734L13.1265 4.42969L13.9439 3.58203L15.5524 5.25L14.735 6.09766L8.61763 12.4141ZM17.6091 3.08984L16.4489 4.32031L14.8405 2.65234L16.027 1.44922C16.1325 1.33984 16.2643 1.3125 16.3171 1.3125C16.3698 1.3125 16.5016 1.33984 16.6071 1.44922L17.6091 2.48828C17.7673 2.65234 17.7673 2.92578 17.6091 3.08984Z" />
    </Icon>
  );
}
