import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ScrollNotifier from 'components/lib/scroll_notifier';

export default class HoverMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isHovered: false };

    _.bindAll(this, ['handleMouseDown', 'handleMouseOver', 'handleMouseOut', 'onClick']);

    this.forceRender = _.throttle(() => {
      this.forceUpdate();
    }, 100);
  }

  componentDidMount() {
    this.positionMenu();
  }

  componentDidUpdate() {
    this.positionMenu();
  }

  onClick() {
    this.setState({ isHovered: false });
  }

  render() {
    const { children, className, hoverElement, menuProps } = this.props;
    const containerClassNames = classnames('hoverMenu-container', className);

    const compactedChildren = _.filter(React.Children.toArray(children), child => {
      return child.props.shouldDisplay !== false;
    });
    const wrappedChildren = compactedChildren.map((child, index) => {
      if (child.type !== HoverMenuItem) {
        return child;
      }
      return React.cloneElement(child, {
        onClick: evt => {
          child.props.onClick && child.props.onClick(evt);
          this.onClick();
        },
        isLastItem: index === compactedChildren.length - 1,
      });
    });

    return (
      <ScrollNotifier findScrollingParentOf={this.hoverable} onScroll={this.positionMenu}>
        <span className={containerClassNames} onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseOut}>
          <span className="hoverMenu-hoverable" ref={node => (this.hoverable = node)}>
            {hoverElement}
          </span>
          <span
            className="hoverMenu-menu"
            onMouseDown={this.handleMouseDown}
            ref={node => (this.menu = node)}
            {...menuProps}
          >
            {wrappedChildren}
            <span className="hoverMenu-menu-triangle-border" />
            <span className="hoverMenu-menu-triangle" />
          </span>
        </span>
      </ScrollNotifier>
    );
  }

  // Calling prevent default stops us from accidentally highlighting / selecting text when clicking menu item
  handleMouseDown(evt) {
    evt.preventDefault();
  }

  handleMouseOver(evt) {
    this.setState({ isHovered: true });
    this.props.handleMouseOver && this.props.handleMouseOver(evt);
  }

  handleMouseOut(evt) {
    this.setState({ isHovered: false });
    this.props.handleMouseOut && this.props.handleMouseOut(evt);
  }

  positionMenu() {
    if (!this.hoverable || !this.menu || !this.state.isHovered) {
      return;
    }
    let pixels = this.hoverable.getClientRects()[0] || this.hoverable.getBoundingClientRect();
    let height = this.menu.offsetHeight;
    let menuTop = pixels.top - height;
    if (menuTop < 0) {
      menuTop = pixels.bottom;
    }
    let width = this.menu.offsetWidth;
    let menuLeft = pixels.left - width / 2 + pixels.width / 2;

    this.menu.style.top = `${Math.floor(menuTop)}px`;
    this.menu.style.left = `${Math.floor(menuLeft)}px`;
  }

  findScrollingParentOf() {
    return this.hoverable;
  }

  handleScroll() {
    this.positionMenu();
  }
}

HoverMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hoverElement: PropTypes.node.isRequired, // the "button" that hovering over opens the menu
  menuProps: PropTypes.object,
};

export function HoverMenuItem({ children, isDisabled, isLastItem, onClick, shouldDisplay }) {
  if (!shouldDisplay) {
    return null;
  }

  const classNames = classnames('hoverMenu-item', {
    'hoverMenu-item-disabled': isDisabled,
    'hoverMenu-item-last': !isDisabled && isLastItem,
  });
  return (
    <div className={classNames} onClick={guardedOnClick}>
      {children}
    </div>
  );

  function guardedOnClick(evt) {
    if (!isDisabled && onClick) {
      onClick(evt);
    }
  }
}

HoverMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  isLastItem: PropTypes.bool,
  onClick: PropTypes.func,
  shouldDisplay: PropTypes.bool,
};

HoverMenuItem.defaultProps = {
  shouldDisplay: true,
};
