import { Factory } from 'rosie';

Factory.define('MessageContentTypeText').attr('type', 'TEXT');

Factory.define('MessageContentTypeTextWithDefaults')
  .extend('MessageContentTypeText')
  .attr('body', 'example body')
  .attr('bodyHtml', '<div><b>example</b> <i>body</i></div>')
  .attr('buttons', [])
  .attr('hasRedactedPaymentCardNumber', false);
