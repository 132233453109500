import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('Voicemail').attr('type', 'VOICEMAIL');

Factory.define('VoicemailWithDefaults')
  .extend('Voicemail')
  .attr('companyNumber', '+14153334444')
  .attr('customerNumber', '+14156667777')
  .attr('recordingDuration', 29)
  .attr('recordingId', 'voicemail-1')
  .attr('recordingStatus', 'AVAILABLE')
  .attr('startedAt', new Date().toISOString());

Factory.define('VoicemailItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('Voicemail', content));

Factory.define('VoicemailItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('VoicemailWithDefaults', content));
