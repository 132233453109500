import _ from 'lodash';
import { isPlainObject, memoBuilder } from '@sentry/utils';

// Slate can throw errors in two ways:
//   1. As a render error
//   2. As a callback error
//
// We could easily intercept case (1) by wrapping the Slate editor in an error boundary,
// but we can't deal with case (2) very easily.. that basically has to be handled at the
// ErrorReporter level because callback errors are handled as a result of Sentry.wrap()
// in my basic understanding.
//
// After the Slate upgrade, Slate errors seem to include the entire Slate state tree, which
// includes all of the text that an agent has written, which can certainly include PII. This
// is bad. But having the entire state tree in the error can be useful for debugging, so we
// do want to keep it instead of just wiping it out wholesale. Slate unforunately does not
// have a built in way of overriding their error handling logic. Hence, after all that, we
// have this function.
//
// All of the PII will be contained within the text nodes, so we can just sanitize those out from
// Slate errors, replacing `{"text":"Some bad PII"}` with `{"text":"<redacted 12 chars>"}` in
// Slate errors.
export default function sanitizeSlate(input, memo = memoBuilder()) {
  const [memoize, unmemoize] = memo;
  const inputIsArray = Array.isArray(input);
  const inputIsPlainObject = isPlainObject(input);

  if (!inputIsArray && !inputIsPlainObject) {
    return input;
  }

  // Avoid circular references
  if (memoize(input)) {
    return input;
  }

  let sanitizedValue;
  if (inputIsArray) {
    sanitizedValue = input.map(value => sanitizeSlate(value, memo));
  } else if (inputIsPlainObject) {
    sanitizedValue = Object.keys(input).reduce((acc, key) => {
      const value = input[key];
      if (_.isString(value) && value.includes(TRIGGER)) {
        const replaceRegex = /("text":")(.*?)("|$)/g;
        let match = replaceRegex.exec(value);
        let matches = [];
        while (match !== null) {
          matches.push(match);
          match = replaceRegex.exec(value);
        }

        let startIndex = 0;
        let replaced = '';
        _.forEach(matches, match => {
          const fullMatchLength = match[0].length;

          const beforeText = match[1];
          const redactedLength = match[2].length;
          const afterText = match[3];

          replaced = `${replaced}${value.substring(
            startIndex,
            match.index
          )}${beforeText}<redacted ${redactedLength} chars>${afterText}`;
          startIndex = match.index + fullMatchLength;
        });
        replaced = `${replaced}${value.substring(startIndex)}`;

        acc[key] = replaced;
      } else {
        acc[key] = sanitizeSlate(input[key], memo);
      }
      return acc;
    }, {});
  }

  unmemoize(input);

  return sanitizedValue;
}

// Slate errors that contain PII seem to always have this substring in their
// error message.
const TRIGGER = 'node: {';
