import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import OutsideClickHandler from 'components/common/utilities/outside_click_handler';

class SlateTableRowColumnMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };

    _.bindAll(this, [
      'toggleMenu',
      'closeMenu',
      'maintainEditorFocus',
      'onInsertColumn',
      'onInsertRow',
      'onRemoveColumn',
      'onRemoveRow',
      'onRemoveTable',
    ]);
  }

  render() {
    const chevronDir = this.state.isMenuOpen ? 'up' : 'down';
    const className = classnames('slateTableRowColumnMenu', 'draftStylesMenu-item', {
      'draftStylesMenu-item-active': this.state.isMenuOpen,
    });

    return (
      <span>
        <OutsideClickHandler onClickOutside={this.closeMenu}>
          <div className={className} onClick={this.toggleMenu} title="Add/Delete">
            <i className="fa fa-table" />
            <i className={`draftStylesMenu-item-chevron fa fa-angle-${chevronDir}`} />
          </div>
          {this.renderMenu()}
        </OutsideClickHandler>
      </span>
    );
  }

  renderMenu() {
    if (!this.state.isMenuOpen) {
      return null;
    }

    return (
      <div className="slateTableRowColumnMenu-list">
        <div className="slateTableRowColumnMenu-list-item" onClick={this.onInsertRow}>
          Insert row below
        </div>
        <div className="slateTableRowColumnMenu-list-item" onClick={this.onInsertColumn}>
          Insert column right
        </div>
        <div className="slateTableRowColumnMenu-list-item" onClick={this.onRemoveRow}>
          Delete row
        </div>
        <div className="slateTableRowColumnMenu-list-item" onClick={this.onRemoveColumn}>
          Delete column
        </div>
        <div className="slateTableRowColumnMenu-list-item" onClick={this.onRemoveTable}>
          Delete table
        </div>
      </div>
    );
  }

  toggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  closeMenu() {
    this.setState({ isMenuOpen: false });
  }

  maintainEditorFocus() {
    this.props.editor.focus();
  }

  onInsertColumn(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.insertColumn());
    this.closeMenu();
  }

  onInsertRow(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.insertRow());
    this.closeMenu();
  }

  onRemoveColumn(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.removeColumn());
    this.closeMenu();
  }

  onRemoveRow(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.removeRow());
    this.closeMenu();
  }

  onRemoveTable(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.removeTable());
    this.closeMenu();
  }
}

export default SlateTableRowColumnMenu;

SlateTableRowColumnMenu.propTypes = {
  editor: PropTypes.object,
  onChange: PropTypes.func,
};
