import React from 'react';
import styled from 'styled-components';

import AddTask from 'actions/composition/add_task';
import { Attachment, Emoji } from './shared/editor_action_buttons';
import { RichTextEditorStyles } from './shared/editor_styles';
import Composer, { Header, HeaderText, useComposer } from './shared/composer';
import { EditorContainer, useEditor } from './shared/editor';
import EditTask from 'actions/composer/edit_task';
import Err from 'models/err';
import RichTextEditor from './editors/rich_text';
import validateEditor from 'components/text_editor/validators/external_validator';
import DatePicker from 'components/composer/shared/date_picker';
import AssigneeSelector from 'components/composer/shared/assignee_selector';

export function TaskComposer({ isEditing }) {
  const options = { trimEmptyParagraphs: true, trimParagraphWhitespace: true };
  options.createContent = createContent;

  const composer = useComposer(isEditing ? EditTask : AddTask, options);

  const editorProps = useEditor('bodyHtml', composer, { validator: taskBodyValidator });
  const [composerProps] = composer;

  let placeholderText = 'Type to enter task description';

  return (
    <Composer includeAttachments submitText={`${isEditing ? 'Save ' : 'Add '}Task`} {...composerProps}>
      <Header>
        <LeftControls>
          <HeaderText color="green400">{`${isEditing ? 'Edit ' : 'Create '}Task`}</HeaderText>
          <DatePicker />
        </LeftControls>
        <AssigneeSelector />
      </Header>
      <StyledRichEditorContainer>
        <StyledRichEditorElement>
          <RichTextEditorStyles {...editorProps}>
            <Emoji {...editorProps} />
            <Attachment alwaysShow />
          </RichTextEditorStyles>
        </StyledRichEditorElement>
      </StyledRichEditorContainer>
      <EditorContainer includeAttachments>
        <RichTextEditor allowMentions placeholder={placeholderText} {...editorProps} />
      </EditorContainer>
    </Composer>
  );
}

export const StyledRichEditorContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
`;

const StyledRichEditorElement = styled.div`
  margin-left: auto;
  width: 100%;
`;

const LeftControls = styled.div`
  display: flex;
`;

function taskBodyValidator(value) {
  const { document } = value;
  const body = (document?.text || '').trim();

  const errors = validateEditor(value);
  if (!body.length) {
    errors.push(
      new Err({
        attr: 'content',
        code: Err.Code.BLANK,
        detail: 'editor is empty',
      })
    );
  }

  return errors;
}

export default React.memo(TaskComposer);

function createContent(editor, content) {
  return {
    ...content,
    mentionedAgentIds: editor.getMentionedAgentIds(editor),
  };
}
