import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('OfferedWarmTransferNotification');

Factory.define('OfferedWarmTransferNotificationWithDefaults')
  .extend('OfferedWarmTransferNotification')
  .attr('type', 'OFFERED_WARM_TRANSFER')
  .attr('profile', () => Factory.attributes('CustomerProfileWithDefaults'))
  .attr('conversationId', 'conversation-id')
  .attr('conversationItem', () => Factory.attributes('PhoneCallItemWithDefaults'))
  .attr('id', ['conversationItem'], conversationItem => conversationItem.id)
  .attr('initiator', () => Factory.attributes('AssigneeWithAgentDefaults'));
