import { forEach, map } from 'lodash';

import analytics from 'scripts/lib/analytics';
import CustomerMerge from 'models/customer_merge';
import ErrDtoConverter from 'scripts/application/dto_converters/err_converter';
import MergeProfile from 'models/modal/merge_profile';
import unloadCustomer from 'actions/customer/lib/unload_customer';

export default class CustomerMergeGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.customerMerge;
  }

  onBroadcast(mergeDto) {
    const merge = CustomerMerge.fromJs(mergeDto);

    moveCompositions(this.context, merge);
    unloadCustomer(this.context, merge.sourceCustomerId);

    const currentLocation = this.context.stores.currentLocation.get();
    if (!currentLocation.customerId) {
      return;
    }

    if (!this.store.isPending() && merge.sourceCustomerId === currentLocation.customerId) {
      this.store.set(merge);
    }
  }

  onAddError(errorsDto) {
    if (this.store.isPending()) {
      this.store.setErrors(errorsDto.errors.map(ErrDtoConverter.fromDto));
      this.store.resetPending();
      this.context.stores.conversationMerge.resetPending();

      analytics.track('Customer Merge Error Returned', {
        attr: map(errorsDto.errors, 'attr'),
      });
    }
  }

  onAddSuccess(merge, { customerId }) {
    if (this.store.isPending() && this.store.getPending().sourceCustomerId === customerId) {
      const pendingMerge = this.store.getPending();

      const destinationCustomerId = pendingMerge.destCustomerId;
      if (this.context.stores.customers.has({ id: destinationCustomerId })) {
        const customerStore = this.context.stores.customers.storesFor(destinationCustomerId);
        customerStore.profile.resetPending();
        customerStore.profile.clearErrors();

        const customerMatchStore = this.context.stores.customers.storesFor(destinationCustomerId).customerMatch;
        if (pendingMerge.shouldClearMatches) {
          customerMatchStore.set(null);
        }
      }

      this.store.commitPending();

      const modal = this.context.stores.modal.get();
      if (modal instanceof MergeProfile) {
        this.context.stores.modal.remove();
      }
    }
  }
}

function moveCompositions(context, merge) {
  if (!context.stores.customers.has({ id: merge.sourceCustomerId })) {
    return;
  }

  const { compositions } = context.stores.customers.storesFor(merge.sourceCustomerId);
  const allCompositions = compositions.findAll();
  if (allCompositions.length) {
    forEach(allCompositions, composition => {
      composition.setCustomerId(merge.destCustomerId);
      context.stores.localCompositions.set(merge.destCustomerId, composition);
      compositions.set([]);
    });
  }
  context.stores.localCompositions.removeAllForCustomer(merge.sourceCustomerId);
}
