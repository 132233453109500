import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="2 2 19 19" {...props}>
    <g className="fbIcon" fill="none" fillRule="evenodd">
      <path
        className="contactIcon-path"
        d="M14.75 6H13.1c-2.063 0-2.708.886-2.708 2.48v1.266H9v1.9h1.392v5.643h2.476v-5.645h1.676l.232-1.9h-1.908V8.608c0-.506.103-.76.902-.76h.98V6z"
      />
    </g>
  </IconContainer>
);
