import createModel, { prop } from '../lib/create_model';
import {
  FlightTransactionDef,
  GenericTransactionDef,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
} from 'models/configuration/transaction_def';
import { transactionDefFromJs } from 'models/configuration/transaction_def_from_js';

const ProfileCardTransactionSource = createModel({
  modelName: 'ProfileCardTransactionSource',
  properties: {
    definition: prop()
      .oneOf(
        GenericTransactionDef,
        FlightTransactionDef,
        OrderTransactionDef,
        SubscriptionTransactionDef,
        StayTransactionDef
      )
      .fromJs(transactionDefFromJs).isRequired,
    filter: prop(Object).default({}),
  },
});

const ProfileCardDataSources = createModel({
  modelName: 'ProfileCardDataSources',
  properties: {
    transactions: ProfileCardTransactionSource,
  },
});

export default ProfileCardDataSources;
