import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';
import { createPlaintext } from './snippet_helpers';
import { getValidationErrors, MAX_LENGTH } from './snippet_validators';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import SnippetChannels, { Languages } from './snippet_channels';
import SnippetContent from './snippet_content';

export const SnippetChannel = createEnum('INFO', 'ANY_CHANNEL', 'MESSAGE', 'SELF_SERVICE');

export const SnippetContentType = Object.freeze({
  ANY_CHANNEL: 'anyChannel',
  INFO: 'info',
  MESSAGE: 'message',
  SELF_SERVICE: 'selfService',
});

export const ChannelFieldName = Object.freeze({
  ANY_CHANNEL: 'anyChannelBodyHtml',
  INFO: 'infoBodyHtml',
  MESSAGE: 'messageBodyHtml',
  SELF_SERVICE: 'selfServiceBodyHtml',
});

export const ChannelMaxUploadSize = Object.freeze({
  ANY_CHANNEL: 15,
  SELF_SERVICE: 5,
});

const Snippet = createModel({
  modelName: 'Snippet',

  properties: {
    id: prop(String).isRequired,
    contents: prop([SnippetChannels]).default([]),
    description: String,
    audienceIds: prop([String]).default([]),
    language: prop(String).default('en-us'),
    name: String,
    plaintext: prop(String).default(''),
  },

  findContentByLanguage(language = 'en-us') {
    return (
      _.find(this.contents, c => c.language === language) ||
      _.find(this.contents, c => c.language === this.language) ||
      this.contents[0]
    );
  },

  findOrCreateContentByLanguage(language) {
    let content = _.find(this.contents, c => c.language === language);
    if (content) return content;

    content = SnippetChannels.createBlank({ language });
    this.contents.push(content);
    return content;
  },

  hasLanguage(language) {
    return !!_.find(this.contents, c => c.language === language);
  },

  findAttachmentById(id) {
    let match;
    _.find(this.contents, c => {
      let test = c.findAttachmentById(id);
      if (test) {
        match = test;
      }
      return test;
    });
    return match;
  },

  updateUpload(upload) {
    let attachment = this.findAttachmentById(upload.id);
    attachment.update(upload);
  },

  update(attrs) {
    let validationErrors = getValidationErrors(attrs);
    if (validationErrors.length) {
      throw new Error(_.map(validationErrors, 'detail').join('; '));
    }

    if (attrs.contents) {
      // Filter out any deleted languages
      this.contents = _.filter(
        this.contents,
        oldContent => !!_.find(attrs.contents, newContent => newContent.language === oldContent.language)
      );

      // Then either update the existing content for each language or create a new one if it doesn't exist
      // for that language
      attrs.contents.forEach(channels => {
        let content = this.contents.find(c => c.language === channels.language);
        if (content) {
          content.update(channels);
        } else {
          const modelChannels = SnippetChannels.create({
            language: channels.language,
            anyChannel: SnippetContent.create(channels.anyChannel),
            info: SnippetContent.create(channels.info),
            message: SnippetContent.create(channels.message),
            selfService: SnippetContent.create(channels.selfService),
          });
          this.contents.push(SnippetChannels.create(modelChannels));
        }
      });
    }

    this.audienceIds = attrs.audienceIds;

    attrs.plaintext = createPlaintext(attrs);

    _.merge(this, _.omit(attrs, 'id', 'contents', 'audienceIds'));
  },

  getUploadPath(uploadId) {
    return Snippet.getUploadPath(this.id, uploadId);
  },

  statics: {
    create(attrs = {}) {
      // legacy for specs & demo; to be deprecated
      let contents = attrs.contents;
      if (!attrs.contents) {
        contents = attrs.content ? [SnippetChannels.fromJs(attrs.content.toJs())] : [];
      }

      attrs.plaintext = createPlaintext({ contents });
      return new this(_.merge({ id: IdGenerator.newId(), contents }, attrs));
    },

    getUploadPath(snippetId, uploadId) {
      return `kb/snippets/${snippetId}/${uploadId}`;
    },

    getIdsFromPath(path) {
      let pathComponents = path.split('/');
      return {
        snippetId: pathComponents[2],
        uploadId: pathComponents[3],
      };
    },

    getUploadIdFromPublicAnswerUploadPath(path) {
      let pathComponents = path.split('/');
      return pathComponents[3];
    },

    overrideFromJs(fromJs) {
      // legacy for specs & demo; to be deprecated
      return attrs => {
        let contents = attrs.contents;
        if (!contents) {
          contents = attrs.content ? [attrs.content] : [];
        }

        contents.sort((a, b) => {
          // sort by language with english-us first
          if (a.language === 'en-us') return -1;
          if (b.language === 'en-us') return 1;

          let aLang = Languages[a.language];
          let bLang = Languages[b.language];

          if (aLang < bLang) return -1;
          return bLang < aLang ? 1 : 0;
        });

        let plaintext = createPlaintext({ contents });
        return fromJs({ plaintext, ...attrs, contents });
      };
    },

    getValidationErrors,

    MAX_LENGTH,
  },
});

export default Snippet;
export { SnippetChannels, SnippetContent }; // reexport for easy imports of all models
