import KeywordGroups from 'models/location/keyword_groups';
import OpenKeywordGroups from 'actions/answers/open_keyword_groups';
import { registerUrlConverter } from './location_url';
import StaticUrlConverter from './url_converters/static_url_converter';

registerUrlConverter(KeywordGroups, StaticUrlConverter.create('/keyword-groups'));

export default function(router, { runnerContext }) {
  return {
    on() {
      runnerContext.executeAction(OpenKeywordGroups);
    },
  };
}
