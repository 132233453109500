import _ from 'lodash';

export default class RequestTracker {
  constructor() {
    this.requests = [];
  }

  map(callback, thisArg) {
    return this.requests.map(({ params, query }) => callback(params, query), thisArg);
  }

  /**
   * Adds request parameters to the "tracked" list.
   * Request "params" are required and query parameters are optional
   *
   * @param {*} params - request parameters (may be used in the topic name, path etc.)
   * @param {*} [query] - optional query attributes to track
   */
  track(params, query) {
    // ensure each request is stored only once
    const entry = { params, query };
    const index = this.requests.findIndex(item => _.isEqual(item, entry));
    if (index < 0) {
      this.requests.push(entry);
    }
  }

  /**
   * Removes request parameters from the "tracked" list. If the "query" parameter is omitted or empty,
   * then only the "params" will be taken into account and all requests that match this set of "params"
   * will be removed
   *
   * @param {*} params - request parameters (may be used in the topic name, path etc.)
   * @param {*} [query] - optional query attributes
   */
  remove(params, query) {
    const useQuery = !_.isEmpty(query);
    _.remove(this.requests, entry =>
      useQuery ? _.isEqual(entry, { params, query }) : _.isEqual(entry.params, params)
    );
  }

  reset() {
    this.requests = [];
  }
}
