import _ from 'lodash';

import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import ConversationHeader from './conversation_header';
import SwitchCurrentConversation from 'actions/customer/switch_current_conversation';

function mapStateToProps(context, props) {
  let { conversationId, onSlaClick } = props;
  let conversation = context.getProvider('conversations').findBy({ id: conversationId });

  if (!conversation) {
    return {};
  }

  let lastItemTimestamp = _.max(
    context
      .getProvider('conversationHistory')
      .findAll({ filter: { conversationId }, select: ['timestamp'] })
      .map(i => i.timestamp)
  );

  let assignedAgent =
    conversation.assignee && conversation.assignee.agentId
      ? context.getProvider('agents').findBy({ id: conversation.assignee.agentId })
      : undefined;

  const topics = [];
  const topicsLong = [];

  const topicsProvider = context.getProvider('topics');
  const customAttributesProvider = context.getProvider('customAttributes');

  _.forEach(conversation.topicIds, id => {
    let topic = topicsProvider.findBy({ id });
    if (topic) {
      topics.push(topic.name);
      topicsLong.push(topic.getNameWithAncestry());
    }
  });

  _.forEach(conversation.customAttributes, attr => {
    let customAttribute = customAttributesProvider.findBy({ id: attr.id });
    if (customAttribute) {
      const displayValue = `${customAttribute.label} > ${attr.value}`;
      topics.push(displayValue);
      topicsLong.push(`${displayValue}${customAttribute.deactivated ? ' (Archived)' : ''}`);
    }
  });

  const lessCaseInsensitive = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());
  return {
    ...props,
    assignedAgent,
    conversation,
    isActive: conversation.status !== Conversation.Status.CLOSED,
    lastItemTimestamp,
    topics: topics.sort(lessCaseInsensitive),
    topicsLong: topicsLong.sort(lessCaseInsensitive),
    onSlaClick,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onSlaClick: (conversationId, conversationItemId) =>
      executeAction(SwitchCurrentConversation, {
        conversationId,
        conversationItemId,
      }),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(ConversationHeader);
