/**
 * We rely on the build process to rewrite `process.env.NODE_ENV` when running in the browser
 */

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isDevelopment() {
  return !isProduction();
}

// Make development slightly easier
export function isEasyMode() {
  return process.env.EASY_MODE;
}
