import createModel, { prop } from './lib/create_model';
import CustomerProfile from 'models/customer_profile';

const CustomerMatch = createModel({
  modelName: 'CustomerMatch',
  properties: {
    profiles: prop([CustomerProfile]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CustomerMatch;
