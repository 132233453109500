import _ from 'lodash';

import Assignee from 'models/assignee';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import Query from 'models/search/search_query';
import SearchFilter from 'models/search/search_filter';
import SearchResult from 'models/search/search_result';
import RecentCustomer from 'models/recent_customer';

const RECENT_CUSTOMER_COUNT = 50;

export default class RequestRecentCustomers {
  constructor(context) {
    this.context = context;
  }

  run() {
    const recentCustomerIds = this.context.stores.localRecentCustomerIds.get();
    const indexById = {};
    _.forEach(recentCustomerIds, (customerId, index) => {
      indexById[customerId] = index;
    });
    const query = new Query({
      size: RECENT_CUSTOMER_COUNT,
      filter: new SearchFilter({ customerIds: recentCustomerIds, type: SearchResult.HitTypes.CUSTOMER_PROFILE }),
    });
    const result = SearchResult.create({ query });

    this.context.stores.recentCustomers.setLoading();
    this.context.gateways.search
      .fetch(result.id, { query: query.toJs(), requestedAt: result.requestedAt })
      .then(result => {
        const recentCustomers = Array(recentCustomerIds.length).fill(null);
        _.forEach(result.hits, hit => {
          const recentCustomer = recentCustomerFromHit(hit);
          const index = indexById[recentCustomer.id];
          recentCustomers[index] = recentCustomer;
        });

        this.context.stores.recentCustomers.set(_.compact(recentCustomers));
      })
      .catch(err => handleCommonErrors(this.context, err))
      .finally(() => {
        this.context.stores.recentCustomers.resetLoading();
      });
  }
}

function recentCustomerFromHit(hit) {
  return new RecentCustomer({
    id: hit.id,
    image: hit.image,
    name: hit.name,
    email: hit.email,
    phone: hit.phone,
    assignee: hit.conversation ? new Assignee(hit.conversation.assignee) : null,
  });
}
