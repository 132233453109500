import createEnum from 'scripts/lib/create_enum';

import AssignedTaskCommented from './assigned_task_commented';
import FollowedTaskCommented from './followed_task_commented';

import AssignedTaskClosed from './assigned_task_closed';
import FollowedTaskClosed from './followed_task_closed';

import AgentMentionedInItem from './agent_mentioned_in_item';
import AgentMentionedInTaskComment from './agent_mentioned_in_task_comment';

const AgentNotificationType = createEnum(
  'ASSIGNED_TASK_COMMENTED',
  'FOLLOWED_TASK_COMMENTED',
  'ASSIGNED_TASK_CLOSED',
  'FOLLOWED_TASK_CLOSED',
  'ITEM_MENTION',
  'ITEM_MENTION_COMMENT'
);
export { AgentNotificationType };

const AgentNotificationContentClasses = [
  [AgentNotificationType.ASSIGNED_TASK_CLOSED, AssignedTaskClosed],
  [AgentNotificationType.FOLLOWED_TASK_CLOSED, FollowedTaskClosed],

  [AgentNotificationType.ASSIGNED_TASK_COMMENTED, AssignedTaskCommented],
  [AgentNotificationType.FOLLOWED_TASK_COMMENTED, FollowedTaskCommented],

  [AgentNotificationType.ITEM_MENTION, AgentMentionedInItem],
  [AgentNotificationType.ITEM_MENTION_COMMENT, AgentMentionedInTaskComment],
];
export default AgentNotificationContentClasses;
