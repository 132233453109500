import React, { useContext } from 'react';

import { RichTextEditorStyles } from 'components/composer/shared/editor_styles';
import FormatterContext from 'components/composer/shared/formatter_context';

export default function AboveEditorActions(props) {
  const { isFormattingVisible } = useContext(FormatterContext);

  if (!isFormattingVisible) {
    return null;
  }

  return <RichTextEditorStyles {...props} />;
}
