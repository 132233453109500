import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import PrepositionalPhrase from './prepositional_phrase';

export function ItemPrepositionalPhrases({ isVisible, name }) {
  if (!isVisible) {
    return null;
  }

  return <PrepositionalPhrase complement={name} preposition="regarding" />;
}

ItemPrepositionalPhrases.propTypes = {
  isVisible: PropTypes.bool,
  name: PropTypes.string,
};

const ItemPrepositionalPhrasesContainer = connect(mapStateToProps)(ItemPrepositionalPhrases);
export default ItemPrepositionalPhrasesContainer;

function mapStateToProps({ getProvider }, { item }) {
  const profileProvider = getProvider('profile');

  const isVisible = item.isLink();
  const name = item.isLink() ? _.get(profileProvider.get(), 'name', '') : '';

  return {
    isVisible,
    name,
  };
}
