import _ from 'lodash';
import { Cell, Column, Table } from 'fixed-data-table-2';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import NavigateToAdmin from 'actions/lib/navigate_to_admin';
import NavigateToUrl from 'actions/current_location/navigate_to_url';
import Tooltip from 'components/common/tooltip';
import WindowSizeWatcher from 'components/common/utilities/window_size_watcher';

const TABLE_SIZES = Object.freeze({
  CELL_HEIGHT: 40,
  HEADER_CELL_HEIGHT: 50,
  CELL_WIDTH: 140,
  GUTTER: 100,
});

export function SmartMatchLiveboard({
  internalActionsEnabled,
  isCustomerListCollapsed,
  isRulesManagementEnabled,
  rows,
  timezone,
  handleCustomerClick,
  handleRuleScoreClick,
}) {
  return (
    <div className="liveboards-board smartMatchLiveboard-table">
      <WindowSizeWatcher>
        {({ windowWidth }) => (
          <Table
            headerHeight={TABLE_SIZES.HEADER_CELL_HEIGHT}
            maxHeight={TABLE_SIZES.CELL_HEIGHT * (rows.length + 1) + TABLE_SIZES.HEADER_CELL_HEIGHT}
            rowHeight={TABLE_SIZES.CELL_HEIGHT}
            rowsCount={rows.length}
            width={getTableWidth(windowWidth)}
          >
            {renderAgentName()}
            {renderCustomerName()}
            {renderMatchTime()}
            {renderMatchReason()}
          </Table>
        )}
      </WindowSizeWatcher>
    </div>
  );

  // In unit tests, the window size is always 0. We are guarding against negative `width` values,
  // otherwise Table component gets confused
  function getTableWidth(windowWidth) {
    if (internalActionsEnabled) {
      return isCustomerListCollapsed || windowWidth <= 1280
        ? Math.max(windowWidth - 80 - TABLE_SIZES.GUTTER, 0)
        : Math.max(windowWidth - 340 - TABLE_SIZES.GUTTER, 0);
    }

    return windowWidth;
  }

  function renderAgentName() {
    return (
      <Column
        cell={({ rowIndex }) => {
          return (
            <Cell className="smartMatchLiveboard-agentNameCell">
              <AgentName>{rows[rowIndex].agentName}</AgentName>
            </Cell>
          );
        }}
        flexGrow={1}
        header={<Cell className="smartMatchLiveboard-headerCell">Agent</Cell>}
        width={TABLE_SIZES.CELL_WIDTH}
      />
    );
  }

  function renderCustomerName() {
    return (
      <Column
        cell={({ rowIndex }) => {
          return <Cell className="smartMatchLiveboard-customerNameCell">{renderCustomerPill(rowIndex)}</Cell>;
        }}
        flexGrow={1}
        header={<Cell className="smartMatchLiveboard-headerCell">Customer</Cell>}
        width={TABLE_SIZES.CELL_WIDTH}
      />
    );
  }

  function renderCustomerPill(rowIndex) {
    if (internalActionsEnabled) {
      return (
        <ClickableCustomerName
          className="smartMatchLiveboard-customerName"
          onClick={() => handleCustomerClick(rows[rowIndex].customer.id, rows[rowIndex].conversationId)}
        >
          {rows[rowIndex].customer.name}
        </ClickableCustomerName>
      );
    }

    return <CustomerName className="smartMatchLiveboard-customerName">{rows[rowIndex].customer.name}</CustomerName>;
  }

  function renderMatchTime() {
    return (
      <Column
        cell={({ rowIndex }) => {
          return (
            <MatchTimeCell className="smartMatchLiveboard-matchTimeCell">
              <div key={rowIndex}>
                {moment(rows[rowIndex].createdAt)
                  .tz(timezone)
                  .format('LT')}
              </div>
            </MatchTimeCell>
          );
        }}
        flexGrow={1}
        header={<Cell className="smartMatchLiveboard-headerCell">Match Time</Cell>}
        width={TABLE_SIZES.CELL_WIDTH - 50}
      />
    );
  }

  function renderMatchReason() {
    return (
      <Column
        cell={({ rowIndex }) => renderRuleScores(rows[rowIndex].ruleScores)}
        flexGrow={4}
        header={<Cell className="smartMatchLiveboard-headerCell">Match Reason</Cell>}
        width={4 * TABLE_SIZES.CELL_WIDTH}
      />
    );
  }

  function renderRuleScore(score) {
    return (
      <RuleScore className="smartMatchLiveboard-ruleScore" key={score.id}>
        <Tooltip bounds={{ left: 60 }} message={score.name} position="bottom">
          {isRulesManagementEnabled ? (
            <ClickableRuleScoreName
              data-aid="clickableRuleScoreName"
              onClick={() => handleRuleScoreClick(score.id, score.revisionId)}
            >
              {score.name}
            </ClickableRuleScoreName>
          ) : (
            <RuleScoreName>{score.name}</RuleScoreName>
          )}
        </Tooltip>
      </RuleScore>
    );
  }

  function renderRuleScores(scores) {
    let sortedScores = _.orderBy(scores, ['value', score => score.name.toLowerCase()], ['desc', 'asc']);
    if (sortedScores.length <= 4) {
      return _.map(sortedScores, score => renderRuleScore(score));
    }

    const truncatedScores = sortedScores.slice(0, 3);
    return (
      <div>
        {_.map(truncatedScores, score => renderRuleScore(score))}
        <MoreScores className="smartMatchLiveboard-moreScores"> + {sortedScores.length - 3} more</MoreScores>
      </div>
    );
  }
}

SmartMatchLiveboard.propTypes = {
  internalActionsEnabled: PropTypes.bool,
  isCustomerListCollapsed: PropTypes.bool,
  isRulesManagementEnabled: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      agentName: PropTypes.string.isRequired,
      customer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      conversationId: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      ruleScores: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          revisionId: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ).isRequired,
  timezone: PropTypes.string.isRequired,
  handleCustomerClick: PropTypes.func.isRequired,
  handleRuleScoreClick: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const agentsProvider = getProvider('agents');
  const liveboard = getProvider('liveboard').get() || {};
  const entries = liveboard.entries || [];

  const rows = entries.map(entry => ({
    agentName: getAgentName(entry.agentId),
    customer: {
      id: entry.customerId,
      name: entry.customerName || 'Customer',
    },
    conversationId: entry.conversationId,
    createdAt: entry.createdAt,
    ruleScores: entry.ruleScores,
  }));

  return {
    internalActionsEnabled: isFeatureEnabled('internalAgentActions'),
    isCustomerListCollapsed: _.get(getProvider('agentPreferences').get(), 'shouldCollapseSidebar'),
    isRulesManagementEnabled: isFeatureEnabled('rulesManagement'),
    rows,
    timezone: liveboard.timezone,
  };

  function getAgentName(agentId) {
    const agent = agentsProvider.findBy({ id: agentId });
    return (agent && agent.getDisplayName()) || 'Agent';
  }
}

function mapExecuteToProps(executeAction) {
  return {
    handleCustomerClick: (customerId, conversationId) =>
      executeAction(NavigateToUrl, `/customer/${customerId}/conversation/${conversationId}`),
    handleRuleScoreClick: (ruleId, revisionId) => NavigateToAdmin(`people-match/${ruleId}?revisionId=${revisionId}`),
  };
}

const SmartMatchLiveboardContainer = connect(mapStateToProps, mapExecuteToProps)(SmartMatchLiveboard);

export default SmartMatchLiveboardContainer;

const AgentName = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${TABLE_SIZES.CELL_WIDTH - 5}px;
`;

const CustomerName = styled.span`
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray400};
  border-radius: 20px;
  display: inline-block;
  justify-content: center;
  margin-right: 8px;
  overflow: hidden;
  padding: 2px 12px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${TABLE_SIZES.CELL_WIDTH - 8}px;
`;

const ClickableCustomerName = styled(CustomerName)`
  &:hover {
    cursor: pointer;
  }
`;

const MatchTimeCell = styled(Cell)`
  margin-left: 10px;
`;

const MoreScores = styled.span`
  display: inline-block;
  justify-content: left;
  margin: 7px 5px;
  overflow: hidden;
  padding: 2px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${TABLE_SIZES.CELL_WIDTH + 10}px;
`;

const RuleScore = styled.div`
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray400};
  border-radius: 20px;
  display: inline-block;
  margin: 7px 5px;
  overflow: hidden;
  padding: 2px 12px;
`;

const RuleScoreName = styled.span`
  display: block;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${TABLE_SIZES.CELL_WIDTH - 20}px;
`;

const ClickableRuleScoreName = styled(RuleScoreName)`
  &:hover {
    cursor: pointer;
  }
`;
