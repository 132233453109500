import moment from 'moment';

import Conversation from './conversation';
import createModel from './lib/create_model';
import ConversationItem from './conversation_item';
import CustomerProfile from './customer_profile';
import PhoneCall from 'models/phone_call';

var ConversationPreview = createModel({
  modelName: 'ConversationPreview',

  properties: {
    conversation: Conversation,
    conversationItem: ConversationItem,
    customer: CustomerProfile,
    hasDraft: Boolean,
    taskCount: Number,
    readTo: String,
  },

  isUnverified() {
    // a call is unverified if the customer is UNVERIFIED and the call is incoming, queued, or in progress
    if (this.conversationItem && this.conversationItem.content instanceof PhoneCall) {
      return (
        this.customer &&
        !this.customer.isVerified() &&
        (this.conversationItem.content.status === PhoneCall.Status.INCOMING ||
          this.conversationItem.content.status === PhoneCall.Status.QUEUED ||
          this.conversationItem.content.status === PhoneCall.Status.IN_PROGRESS)
      );
    }
    return false;
  },

  isUnread() {
    return !this.readTo || moment(this.readTo).isBefore(this.conversation.updatedAt);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ConversationPreview;
