import { isEmpty, omitBy, mapValues } from 'lodash';
import qs from 'qs';

export function getUrl(path, queryParams, customStringifyOptions) {
  const search = convertParamsToSearch(queryParams, customStringifyOptions);
  const join = path.includes('?') ? '&' : '?';

  return search ? `${path}${join}${search}` : path;
}

function convertParamsToSearch(params, customStringifyOptions) {
  // The last check "v !== v" is for NaN
  const compactedParams = omitBy(params, v => v === false || v === null || v === undefined || v !== v);
  const search = mapValues(compactedParams, v => (v === true ? '1' : v));

  return isEmpty(search) ? '' : qs.stringify(search, customStringifyOptions);
}
