import React, { useCallback } from 'react';
import {
  usePlateEditorState,
  getMark,
  getPluginType,
  select,
  focusEditor,
  setMarks,
  removeMark,
} from '@udecode/plate-common';

import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import {
  ColorSwatch,
  ColorPickerWrapper,
  ColorsWrapper,
  ClearButton,
  ToggleButton,
  StyledFontColorMenuIcon,
} from 'components/composer/shared/color_picker_styles';
import PopoverMenu, { usePopoverMenu } from 'components/common/menu';

export default function ColorPicker({ colors, editorId, pluginKey, title }) {
  const editor = usePlateEditorState(editorId);
  const { targetRef, isOpen, onClose, onToggle, setTargetRef } = usePopoverMenu();

  const pluginType = getPluginType(editor, pluginKey);
  const currentColor = editor && getMark(editor, pluginType);

  const updateColor = useCallback(
    value => {
      if (editor && editor.selection) {
        // This feels like it belongs in editor.updateColor, but would require
        // overriding their plugin, so I haven't done it yet.
        select(editor, editor.selection);
        focusEditor(editor);

        setMarks(editor, { [pluginType]: value });
      }
    },
    [editor, pluginType]
  );

  const updateColorAndClose = useCallback(
    value => {
      updateColor(value);
      onClose();
    },
    [updateColor, onClose]
  );

  const clearColor = useCallback(() => {
    if (editor && editor.selection) {
      select(editor, editor.selection);
      focusEditor(editor);

      removeMark(editor, { key: pluginType });

      onToggle();
    }
  }, [editor, select, focusEditor, removeMark, onToggle]);

  return (
    <OutsideClickHandler onClickOutside={onClose}>
      <ToggleButton
        data-aid="slateFontColor"
        isActive={isOpen || currentColor}
        onClick={onToggle}
        ref={setTargetRef}
        title={title}
      >
        <>
          <StyledFontColorMenuIcon activeColor={currentColor}>A</StyledFontColorMenuIcon>
          <i
            className={`draftStylesMenu-item-chevron fa fa-angle-${isOpen ? 'up' : 'down'}`}
            data-aid="ColorPickerIcon"
          />
        </>
      </ToggleButton>
      <PopoverMenu
        boundByWindow
        isOpen={isOpen}
        onClickOutside={null}
        onClose={onClose}
        position="bottom"
        targetRef={targetRef}
      >
        <ColorPickerWrapper data-aid="ColorPicker">
          <ColorsWrapper>
            {colors.map((color, idx) => (
              <ColorSwatch color={color} key={color + idx} onClick={() => updateColorAndClose(color)} />
            ))}
          </ColorsWrapper>
          <ClearButton data-aid="ColorPickerClear" onClick={clearColor}>
            Clear
          </ClearButton>
        </ColorPickerWrapper>
      </PopoverMenu>
    </OutsideClickHandler>
  );
}
