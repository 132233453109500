import Inbox from 'models/location/inbox';

export default function(context, newLocation) {
  let currentLocation = context.stores.currentLocation.get();

  if (newLocation instanceof Inbox) {
    updateLocationHistory(context, lh => lh.resetHistory());
  } else if (newLocation.constructor !== currentLocation.constructor) {
    updateLocationHistory(context, lh => lh.updateHistory(currentLocation));
  }

  context.stores.currentLocation.set(newLocation);
}

function updateLocationHistory(context, update) {
  let locationHistory = context.stores.locationHistory.get();
  if (locationHistory.isNavigatingBack) {
    locationHistory.popPreviousEntry();
  }

  update(locationHistory);
  context.stores.locationHistory.set(locationHistory);
}
