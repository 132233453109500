import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck';
import Tooltip from 'components/common/tooltip';
import Conversation from 'models/conversation';

export function ShieldOfTrust({ enabled }) {
  return (
    <Tooltip
      delay={300}
      hoverableTooltip
      message={<span>Trusted Message</span>}
      position="top"
      style={{ padding: '4px 8px' }}
    >
      <ShieldOfTrustWrapper enabled={enabled}>
        <FontAwesomeIcon icon={faShieldCheck} />
      </ShieldOfTrustWrapper>
    </Tooltip>
  );
}

ShieldOfTrust.propTypes = {
  enabled: PropTypes.bool,
};

function mapStateToProps({ getProvider }, props) {
  const { conversationId } = props;
  if (!conversationId) {
    return {
      enabled: false,
    };
  }

  const conversation = getProvider('conversations').find(conversationId);
  if (!conversation) {
    return {
      enabled: false,
    };
  }

  const enabled = conversation.status !== Conversation.Status.CLOSED;
  return {
    enabled,
  };
}

const ShieldOfTrustContainer = connect(mapStateToProps)(ShieldOfTrust);
ShieldOfTrustContainer.propTypes = {
  conversationId: PropTypes.string,
};
export default ShieldOfTrustContainer;

const ShieldOfTrustWrapper = styled.span`
  margin-left: 6px;
  margin-right: 7px;
  color: ${p => (p.enabled ? p.theme.colors.green400 : p.theme.colors.gray700)};
`;
