export default {
  fromUrlParameters({ slug, timezone }) {
    return {
      slug,
      timezone,
    };
  },

  getPath(datasets) {
    return `/datasets/${datasets.slug}`;
  },

  getQueryParams(datasets) {
    let queryParams = {};

    if (datasets.timezone) {
      queryParams.timezone = datasets.timezone;
    }

    return queryParams;
  },
};
