import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import LinkedItem from 'models/linked_item';
import NavigateToLinkedCustomer from 'actions/conversation_item/pin_item/navigate_to_linked_customer';
import UnlinkItemFromCustomer from 'actions/conversation_item/pin_item/unlink_item_from_customer';

export default class PinnedCustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.renderCustomerPin = this.renderCustomerPin.bind(this);
  }

  render() {
    let linkedItems = _.get(this.props.item, 'linkedItems');
    return linkedItems && linkedItems.length ? this.renderPinnedCustomerList(linkedItems) : null;
  }

  renderPinnedCustomerList(linkedItems) {
    let className = classnames('pinnedCustomerList', this.props.className, {
      'pinnedCustomerList-link': this.isLink(),
      'pinnedCustomerList-inbound': this.props.isInbound,
    });
    let sortedLinkedItems = _.sortBy(linkedItems, i => new Date(i.timestamp));
    return (
      <div className={className}>
        <div className="pinnedCustomerList-label">{this.renderLabel()}</div>
        {sortedLinkedItems.map(this.renderCustomerPin)}
      </div>
    );
  }

  renderCustomerPin(linkedItem, i) {
    return (
      <PinnedCustomerContainer
        content={linkedItem}
        isLast={i === this.props.item.linkedItems.length - 1}
        item={this.props.item}
        key={`${linkedItem.itemId}-${linkedItem.customerId}`}
      />
    );
  }

  renderLabel() {
    return this.isLink() ? 'From a conversation with' : 'Pinned to';
  }

  isLink() {
    return this.props.item.isLink();
  }
}

PinnedCustomerList.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  isInbound: PropTypes.bool,
};

export class PinnedCustomer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.state = {};
  }

  handleRemove(evt) {
    evt.stopPropagation(); // Otherwise `handleClick` will be invoked
    if (this.state.wasRemoved) {
      return;
    }

    this.props.onRemove();
    this.setState({ wasRemoved: true });
  }

  handleClick() {
    if (this.state.wasRemoved) {
      return;
    }

    this.props.onClick();
  }

  render() {
    const { isLast, name } = this.props;
    const comma = isLast ? '' : ',';
    const classNames = classnames('pinnedCustomer', {
      'pinnedCustomer-last': isLast,
      'pinnedCustomer-removed': this.state.wasRemoved,
    });
    return (
      <div className={classNames} onClick={this.handleClick}>
        <div className="pinnedCustomer-name">
          {name}
          {comma}
          <span className="pinnedCustomer-remove" onClick={this.handleRemove}>
            ✕
          </span>
        </div>
      </div>
    );
  }
}

PinnedCustomer.propTypes = {
  isLast: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider }, { content, isLast }) {
  return {
    isLast,
    name: _.get(content, 'customerProfile.name') || 'A customer',
  };
}

function mapExecuteToProps(executeAction, { content, item }) {
  return {
    onClick: () => {
      executeAction(NavigateToLinkedCustomer, content);
    },
    onRemove: evt => {
      executeAction(UnlinkItemFromCustomer, { item, linkedItem: content });
    },
  };
}

const PinnedCustomerContainer = connect(mapStateToProps, mapExecuteToProps)(PinnedCustomer);

export { PinnedCustomerContainer };

PinnedCustomerContainer.propTypes = {
  content: PropTypes.instanceOf(LinkedItem).isRequired,
  isLast: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
