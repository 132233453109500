import _ from 'lodash';
import createModel from './lib/create_model';

var prop = createModel.prop;

/* All routable items (such as conversations, conversation items, etc.) have a field `assignee`, which is
 * an Assignee object. If an item has an Assignee with no agentId, the routing engine will try
 * to assign the item a delegate Agent that belongs to the Assignee's routing group
 */
const Assignee = createModel({
  modelName: 'Assignee',

  properties: {
    routingGroupId: prop(String).isRequired,
    agentId: String,
  },

  routeToGroup(routingGroupId) {
    return new Assignee({ routingGroupId });
  },

  routeToAgent(agentId) {
    return new Assignee(_.merge({}, this, { agentId }));
  },

  statics: {
    create(attrs = {}) {
      return new Assignee(attrs);
    },
  },
});

export default Assignee;
