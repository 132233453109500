import { Factory } from 'rosie';

Factory.define('SummaryLiveboard');

Factory.define('SummaryLiveboardWithDefaults')
  .extend('SummaryLiveboard')
  .attr('agent', ['agent'], () => {
    return {
      ready: 3,
      busy: 2,
      away: 3,
      online: 4,
      readyForPhone: 5,
      readyForChat: 4,
      readyForNonInteractive: 6,
      onCall: 7,
      onlineForPhone: 5,
      onlineForChat: 6,
      onlineForNonInteractive: 6,
      freeChatCapacity: 8,
    };
  })
  .attr('channel', ['channel'], () => {
    return {
      email: {
        currentNewEmails: 10,
        currentOpenEmails: 11,
        dailyTotalResponses: 12,
        currentWait: 1300,
        dailyAverageResponseTime: 1400,
      },
      phone: {
        currentNewCalls: 15,
        currentActiveCalls: 16,
        dailyTotalCalls: 17,
        dailyOutgoingCalls: 18,
        currentWait: 1900,
        dailyAverageCallTime: 2000,
        dailyAverageResponseTime: 3000,
      },
      sms: {
        currentNewSms: 10,
        currentOpenSms: 11,
        dailyTotalResponses: 12,
        currentWait: 1300,
        dailyAverageResponseTime: 1400,
      },
      fbMessage: {
        currentNewMessages: 10,
        currentOpenMessages: 11,
        dailyTotalResponses: 12,
        currentWait: 1300,
        dailyAverageResponseTime: 1400,
      },
      chat: {
        currentNewChats: 5,
        currentActiveChats: 6,
        dailyTotalChats: 20,
        currentWait: 1700,
        dailyAverageChatTime: 1100,
        dailyAverageResponseTime: 2100,
      },
      instagramDirect: {
        currentNewMessages: 50,
        currentOpenMessages: 16,
        dailyTotalResponses: 51,
        currentWait: 300,
        dailyAverageResponseTime: 1500,
      },
      twitter: {
        currentNewMessages: 5,
        currentOpenMessages: 6,
        dailyTotalResponses: 21,
        currentWait: 1300,
        dailyAverageResponseTime: 1400,
      },
      whatsapp: {
        currentNewMessages: 11,
        currentOpenMessages: 12,
        dailyTotalResponses: 13,
        currentWait: 1400,
        dailyAverageResponseTime: 1500,
      },
    };
  })
  .attr('conversation', ['conversation'], () => {
    return {
      openAssigned: 4,
      openUnassigned: 5,
      waiting: 6,
    };
  })
  .attr('sla', ['sla'], () => {
    return {
      email: {
        over: 21,
        total: 22,
      },
      phone: {
        over: 23,
        total: 24,
      },
      sms: {
        over: 25,
        total: 26,
      },
      fbMessage: {
        over: 25,
        total: 26,
      },
      chat: {
        over: 27,
        total: 28,
      },
      twitter: {
        over: 25,
        total: 26,
      },
      whatsapp: {
        over: 29,
        total: 30,
      },
    };
  })
  .attr('trend', ['trend'], () => {
    return [
      { timestamp: '2016-05-24T21:45:24.433Z', newConversations: 5, closedConversations: 10 },
      { timestamp: '2016-05-23T21:45:24.433Z', newConversations: 12, closedConversations: 3 },
    ];
  });

Factory.define('AgentsLiveboard');

Factory.define('AgentsLiveboardWithDefaults')
  .extend('SummaryLiveboard')
  .attr('utilization', [
    {
      activity: 'EMAIL',
      agentId: 'michelle-smith-id',
      availableChannels: ['VOICE'],
      onCall: false,
      presenceUpdatedAt: '2016-09-29T21:15:00.000Z',
      routingPreferences: {
        status: 'FOCUS',
        updatedAt: '2016-09-29T21:15:00.000Z',
      },
      sessionWork: {
        customerId: 'sarah-victoria-adams-A',
        sessionType: 'PHONE_CALL',
        workType: 'ACT',
      },
      status: 'READY',
      statusReasonId: 'active-reason-1',
      statusUpdatedAt: '2016-09-29T21:15:00.000Z',
    },
    {
      activity: 'EMAIL',
      agentId: 'sarah-brown-id',
      availableChannels: ['VOICE'],
      onCall: false,
      presenceUpdatedAt: '2016-09-29T21:15:00.000Z',
      routingPreferences: {
        status: 'ACTIVE',
        updatedAt: '2016-09-29T21:15:00.000Z',
      },
      sessionWork: {
        customerId: 'sarah-victoria-adams-A',
        sessionType: 'EMAIL',
        workType: 'UNKNOWN',
      },
      status: 'READY',
      statusReasonId: 'active-reason-1',
      statusUpdatedAt: '2016-09-29T21:15:00.000Z',
    },
    {
      activity: 'CUSTOMER',
      agentId: 'michael-smith-id',
      availableChannels: ['VOICE'],
      onCall: true,
      routingPreferences: {
        status: 'ACTIVE',
        updatedAt: '2016-09-29T21:15:00.000Z',
      },
      presenceUpdatedAt: '2016-09-29T21:15:00.000Z',
      sessionWork: {
        customerId: 'sarah-victoria-adams-A',
        sessionType: 'PHONE_CALL',
        workType: 'HANDLE',
      },
      status: 'BUSY',
      statusReasonId: 'active-reason-1',
      statusUpdatedAt: '2016-09-29T21:15:00.000Z',
    },
  ]);

Factory.define('TopicsLiveboard')
  .attr('totals')
  .attr('topics');

Factory.define('TopicsLiveboardWithDefaults')
  .extend('TopicsLiveboard')
  .attr('totals', ['totals'], totals => {
    return (
      totals || {
        groupId: 'id-123',
        agentsOnline: 1,
        conversations: {
          openAssigned: 2,
          openUnassigned: 3,
          waiting: 4,
          closedToday: 5,
        },
        currentWait: 6000,
        sla: {
          over: 7,
          total: 8,
        },
      }
    );
  })
  .attr('topics', ['topics'], topics => {
    return (
      topics || [
        {
          id: 'id-456',
          conversations: {
            openAssigned: 9,
            openUnassigned: 10,
            waiting: 11,
            closedToday: 12,
          },
          currentWait: 13000,
          sla: {
            over: 13,
            total: 14,
          },
        },
        {
          id: 'id-789',
          conversations: {
            openAssigned: 15,
            openUnassigned: 16,
            waiting: 17,
            closedToday: 18,
          },
          currentWait: 19000,
          sla: {
            over: 20,
            total: 21,
          },
        },
      ]
    );
  });

Factory.define('TopicsLiveboardTopic')
  .attr('id')
  .attr('conversations')
  .attr('currentWait')
  .attr('sla');

Factory.define('TopicsLiveboardTopicWithDefaults')
  .extend('TopicsLiveboardTopic')
  .attr('id', ['id'], id => id)
  .attr('conversations', ['conversations'], conversations => {
    return (
      conversations || {
        openAssigned: 9,
        openUnassigned: 10,
        waiting: 11,
        closedToday: 12,
      }
    );
  })
  .attr('currentWait', ['currentWait'], wait => wait || 13000)
  .attr('sla', ['sla'], sla => {
    return (
      sla || {
        over: 13,
        total: 14,
      }
    );
  });

Factory.define('TopicsLiveboardTotals')
  .attr('groupId')
  .attr('agentsOnline')
  .attr('conversations')
  .attr('currentWait')
  .attr('sla');

Factory.define('TopicsLiveboardTotalsWithDefaults')
  .extend('TopicsLiveboardTotals')
  .attr('groupId', ['groupId'], groupId => groupId)
  .attr('agentsOnline', ['agentsOnline'], agentsOnline => agentsOnline || 1)
  .attr('conversations', ['conversations'], conversations => {
    return (
      conversations || {
        openAssigned: 2,
        openUnassigned: 3,
        waiting: 4,
        closedToday: 5,
      }
    );
  })
  .attr('currentWait', ['currentWait'], currentWait => 6000)
  .attr('sla', ['sla'], sla => {
    return (
      sla || {
        over: 7,
        total: 8,
      }
    );
  });

Factory.define('SmartMatchLiveboard')
  .attr('entries')
  .attr('timezone');

Factory.define('SmartMatchLiveboardWithDefaults')
  .extend('SmartMatchLiveboard')
  .attr('entries', ['entries'], entries => {
    return (
      entries || [
        {
          agentId: 'lisa-green-id',
          conversationId: 'another-conversation-id',
          customerId: 'another-customer-id',
          customerName: 'Another Customer Name',
          createdAt: '2021-06-30T21:45:24.433Z',
          ruleScores: [
            {
              id: 'another-rule-id',
              name: 'Another Rule Name - Large',
              revisionId: 1,
              value: 5,
            },
            {
              id: 'rule-id',
              name: 'Example Rule Name - Medium',
              revisionId: 1,
              value: 3,
            },
          ],
        },
        {
          agentId: 'michelle-smith-id',
          conversationId: 'conversation-id',
          customerId: 'customer-id',
          customerName: 'Example Customer Name',
          createdAt: '2021-06-30T21:55:24.433Z',
          ruleScores: [
            {
              id: 'rule-id',
              name: 'Example Rule Name - Medium',
              revisionId: 1,
              value: 3,
            },
          ],
        },
        {
          agentId: 'michael-smith-id',
          conversationId: 'yet-another-conversation-id',
          customerId: 'yet-another-customer-id',
          customerName: 'Yet Another Customer Name',
          createdAt: '2021-06-30T21:35:24.433Z',
          ruleScores: [],
        },
      ]
    );
  })
  .attr('timezone', ['timezone'], timezone => timezone || 'America/New_York');
