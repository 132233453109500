import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { trim } from 'lodash';

import { Item } from 'components/customer/composition/lib/slate/slate_styles_menu.styles';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import AISummarizeIcon from 'components/common/icons/stroke/summary-stroke';
import AITextCompletion from 'models/ai_text_completion';
import ComposerContext from 'components/composer/contexts/composer_context';
import Conversation from 'models/conversation';
import connect from 'components/lib/connect';
import RequestComposerConversationSummary from 'actions/ai_conversation_summary/request_composer_conversation_summary';
import withStaticId from 'components/customer/lib/with_static_id';

export function AISummarizeConversationButton({ className, conversationId, isEnabled, requestorId }) {
  const executeAction = useExecuteAction();
  const { composition } = useContext(ComposerContext);

  // Do we have a valid composition? Bail out if not.
  if (!composition) return null;

  const onClick = () => {
    if (!isEnabled || !conversationId) return;
    executeAction(RequestComposerConversationSummary, {
      compositionId: composition.id,
      conversationId,
      customerId: composition.customerId,
      requestorId,
    });
  };

  const isDisabled = !isEnabled || !conversationId;
  const itemClassname = classnames(className, { disabled: isDisabled });
  const iconClassname = classnames('summarize_icon', { disabled: isDisabled });
  return (
    <Item
      className={itemClassname}
      data-aid="aiSummarizeConversation"
      onClick={onClick}
      title={isEnabled ? 'Summarize Conversation' : ''}
    >
      <StyledSummarizeIcon className={iconClassname} />
    </Item>
  );
}

const StyledSummarizeIcon = styled(AISummarizeIcon)`
  height: auto;
  width: 14px;
  &:not(.disabled) path {
    fill: ${p => p.theme.colors.gray900};
  }
  &.disabled path {
    fill: ${p => p.theme.colors.gray500};
  }
`;

AISummarizeConversationButton.propTypes = {
  className: PropTypes.string,
  conversationId: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  requestorId: PropTypes.string.isRequired,
};

function mapStateToProps({ getProvider }, { conversationId, requestorId }) {
  const conversations = getProvider('conversations');
  const completions = getProvider('aiTextCompletions');

  let isConversationClosed = true;
  const trimmedId = trim(conversationId);
  if (conversations && trimmedId && conversations.has({ id: trimmedId })) {
    const conversation = conversations.find(trimmedId);
    isConversationClosed = conversation.status === Conversation.Status.CLOSED;
  }

  const isLoading = completions.isLoading({ id: requestorId, type: AITextCompletion.Types.conversation_summary });
  const isEnabled = !isLoading && !isConversationClosed;

  return {
    conversationId: trimmedId,
    isEnabled,
    requestorId,
  };
}

export const AISummarizeConversationMenuItem = withStaticId(
  connect(mapStateToProps)(AISummarizeConversationButton),
  'requestorId'
);
