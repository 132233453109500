import SelectFirstAnswerShown from './select_first_answer_shown';
import ClearSuggestedReply from 'actions/suggested_replies/clear_suggested_reply';

export default class ClearAnswerSearch {
  constructor(context) {
    this.context = context;
  }

  run() {
    let answerPanel = this.context.stores.answerPanel.get();
    answerPanel.clearSearchResult();
    this.context.stores.answerPanel.set(answerPanel);
    this.context.stores.answerPanel.resetPending();
    this.context.executeAction(SelectFirstAnswerShown);
    const currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation.customerId;
    if (customerId) {
      this.context.executeAction(ClearSuggestedReply, { customerId });
    }
  }
}
