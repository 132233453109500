import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import SelectAnswer from 'actions/answers/select_answer';
import { useExecuteAction } from 'components/hooks/connect_hooks';

/**
 * useKeyboardNavigation allows agents to navigate through the answers list using the up / down arrow keys
 */
export default function useKeyboardNavigation(panelRef, answers, selectedAnswerId) {
  const executeAction = useExecuteAction();

  // In order to prevent us from having to recreate the navigateUp / navigateDown callbacks
  // every time answers or selectedAnswerId changes, I instead store their current values
  // in a ref, and use the ref's current value in the callbacks instead.
  const ref = useRef({ answers, selectedAnswerId });
  useEffect(() => {
    ref.current = { answers, selectedAnswerId };
  }, [answers, selectedAnswerId]);

  const navigateUp = useCallback(() => {
    const { answers, selectedAnswerId } = ref.current;
    const selectedIndex = _.findIndex(answers, { id: selectedAnswerId });
    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      executeAction(SelectAnswer, { answer: answers[newIndex], clearHistory: true });
    }
  }, []);

  const navigateDown = useCallback(() => {
    const { answers, selectedAnswerId } = ref.current;
    const selectedIndex = _.findIndex(answers, { id: selectedAnswerId });
    if (selectedIndex > -1 && selectedIndex < answers.length - 1) {
      const newIndex = selectedIndex + 1;
      executeAction(SelectAnswer, { answer: answers[newIndex], clearHistory: true });
    }
  }, []);

  const onKeyDown = useCallback(
    evt => {
      if (evt.key === 'ArrowUp') {
        evt.preventDefault();
        navigateUp();
        panelRef.current.focus();
      } else if (evt.key === 'ArrowDown') {
        evt.preventDefault();
        navigateDown();
        panelRef.current.focus();
      }
    },
    [navigateUp, navigateDown]
  );

  return onKeyDown;
}
