import './lib/uuid';
import { Factory } from 'rosie';

Factory.define('SearchResult')
  .extend('Uuid')
  .attr('query', ['query'], query => {
    return Factory.attributes('SearchQuery', query);
  })
  .attr('aggregations', ['aggregations'], aggs => {
    return Factory.attributes('SearchAggregations', aggs);
  })
  .attr('requestedAt');
