import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('TaskReopenedContent')
  .attr('type', 'TASK_REOPENED');

Factory.define('TaskReopenedContentWithDefaults')
  .extend('TaskReopenedContent')
  .attr('taskItemId', 'task-reopened-id');

Factory.define('TaskReopenedContentItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskReopenedContent', content);
  });

Factory.define('TaskReopenedContentItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function (content) {
    return Factory.attributes('TaskReopenedContentWithDefaults', content);
  });
