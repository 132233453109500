import PropTypes from 'prop-types';
import React from 'react';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ItemIcon from './item_icon';

export function InboundIcon({ avatar, itemType, name, showIcon }) {
  return (
    <ItemIcon
      avatar={avatar}
      className="conversationItem-inboundIcon"
      itemType={itemType}
      name={name}
      showIcon={showIcon}
    />
  );
}

InboundIcon.propTypes = {
  avatar: PropTypes.string,
  itemType: PropTypes.string.isRequired,
  name: PropTypes.string,
  showIcon: PropTypes.bool,
};

function mapStateToProps({ getProvider }, { initiator, itemType, showIcon }) {
  const profileProvider = getProvider('profile');

  const customerId = initiator.type === Communicator.CUSTOMER && initiator.id;
  const profile = showIcon && customerId ? profileProvider.get() : undefined;
  let isCurrentCustomer = profile && profile.id === initiator.id;
  return {
    avatar: isCurrentCustomer ? profile.getImageUrl() : undefined,
    itemType,
    name: isCurrentCustomer ? profile.name : initiator.name,
    showIcon,
  };
}

const InboundIconContainer = connect(mapStateToProps)(InboundIcon);

InboundIconContainer.propTypes = {
  itemType: PropTypes.string.isRequired,
  initiator: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  showIcon: PropTypes.bool,
};

export default InboundIconContainer;
