import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Item = forwardRef((props, ref) => {
  function preventBlurring(evt) {
    evt.preventDefault();
  }

  // By default, we want clicking on a toolbar item button to _not_ blur the editor. For example,
  // if you're hitting the "Bold" button, you want to be able to keep typing. Calling
  // evt.preventDefault() in `onMouseDown` helps achieve this.
  const onMouseDown = props.onMouseDown || preventBlurring;
  return <StyledItem disabled={props.isDisabled} {...props} onMouseDown={onMouseDown} ref={ref} />;
});

Item.propTypes = {
  isDisabled: PropTypes.bool,
  onMouseDown: PropTypes.func,
};

export { Item, activeItemStyles, disabledItemStyles, itemColorStyles, itemSizeStyles };

const itemColorStyles = css`
  background-color: ${p => p.theme.colors.white};
  color: ${p => p.theme.colors.gray800};
  &:hover {
    color: ${p => p.theme.colors.gray900};
    background-color: ${p => p.theme.colors.gray100};
  }
  &:active {
    color: ${p => p.theme.colors.gray900};
    background-color: ${p => p.theme.colors.gray200};
  }
`;

const itemSizeStyles = css`
  height: 24px;
  width: 24px;
`;

const StyledItem = styled.button`
  align-items: center;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-bottom: 0;
  text-align: center;

  ${itemColorStyles};
  ${itemSizeStyles};

  ${p => p.isActive && activeItemStyles};
  ${p => p.disabled && disabledItemStyles};
`;

const activeItemStyles = css`
  background-color: ${p => p.theme.colors.gray200};
  color: ${p => p.theme.colors.gray900};
  &:active,
  &:hover {
    background-color: ${p => p.theme.colors.gray300};
  }
`;

const disabledItemStyles = css`
  background-color: ${p => p.theme.colors.white};
  color: ${p => p.theme.colors.gray300};
  cursor: default;

  &:active,
  &:hover {
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.gray300};
  }
`;
