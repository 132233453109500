import { Factory } from 'rosie';
import '../conversation_item_factory';
import IdGenerator from '../lib/id_generator';

Factory.define('EmailCampaignOutreach')
  .attr('type', 'EMAIL_CAMPAIGN_OUTREACH')
  .attr('to', [])
  .attr('cc', [])
  .attr('headers', () => {
    return { 'message-id': `<${IdGenerator.newId()}@example.com>` };
  })
  .attr('bodyHtml')
  .attr('bodyPlain', ['bodyPlain', 'bodyHtml'], (bodyPlain, bodyHtml) => {
    return bodyPlain ? bodyPlain : bodyHtml;
  })
  .attr('attachments', ['attachments'], attachments => {
    return attachments ? attachments.map(attachment => Factory.attributes('Attachment', attachment)) : [];
  });

Factory.define('EmailCampaignOutreachWithDefaults')
  .extend('EmailCampaignOutreach')
  .attr('from', 'support@example.com')
  .attr('to', ['amber.mendez@ict.com'])
  .attr('cc', ['dr@who.uk'])
  .attr('subject', 'What happened to Buzzsaw?')
  .attr('bodyHtml', '<div>He had to make like a banana and split.</div>')
  .attr('bodyPlain', 'He had to make like a banana and split.')
  .attr('bodyPlainStripped', 'He had to make like a banana and split.')
  .attr('sendStatus', '')
  .attr('recipientStatuses', { 'amber.mendez@ict.com': { status: '' }, 'dr@who.uk': { status: '' } })
  .attr('attachments', ['attachments'], attachments => {
    return attachments ? attachments.map(attachment => Factory.attributes('Attachment', attachment)) : [];
  })
  .attr('headers', () => {
    return { 'message-id': `<${IdGenerator.newId()}@example.com>` };
  });

Factory.define('EmailCampaignOutreachItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('EmailCampaignOutreach', content));

Factory.define('EmailCampaignOutreachItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('EmailCampaignOutreachWithDefaults', content));
