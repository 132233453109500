import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import DismissNotificationButton from './dismiss_notification_button';
import Notification from './notification';

export function UnofferedWarmTransferNotification({ notificationId, initiatingAgentName }) {
  return (
    <Notification key={`notification-${notificationId}`} notificationId={notificationId}>
      <div className="unofferedCallNotification-message">You missed a phone transfer from {initiatingAgentName}</div>
      <DismissNotificationButton notificationId={notificationId} />
    </Notification>
  );
}

UnofferedWarmTransferNotification.propTypes = {
  notificationId: PropTypes.string.isRequired,
  initiatingAgentName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(UnofferedWarmTransferNotification);

function mapStateToProps({ getProvider }, { notification }) {
  let phoneCall = notification.conversationItem.content;
  let initiatingAgentParticipantId = phoneCall.getActiveAgentParticipants()[0].participantId;
  let initiatingAgent = getProvider('agents').findBy({ id: initiatingAgentParticipantId });

  return {
    notificationId: notification.id,
    initiatingAgentName: initiatingAgent.name || initiatingAgent.email,
  };
}
