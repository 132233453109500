import IdGenerator from 'scripts/domain/contracts/id_generator';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import { translateError, translateErrorResponse } from 'scripts/adapters/gateways/lib/http_response_handler';
import { after, HEADER_CORRELATION_ID } from 'scripts/infrastructure/backends/http_client';

export default class ExternalCustomerLookupActionsGateway {
  constructor(backend, requestorId) {
    this.backend = backend;
    this.requestorId = requestorId;
  }

  get _http() {
    return this.backend.axios();
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  fetchFormUrl(customerId, integrationId) {
    return `/api/v2/orgs/${this.orgId}/customers/${customerId}/external-customer-lookup/integrations/${integrationId}/action-forms`;
  }

  performActionUrl(customerId, integrationId) {
    return `/api/v2/orgs/${this.orgId}/customers/${customerId}/external-customer-lookup/integrations/${integrationId}/actions`;
  }

  fetchForm(customerId, integrationId, { formUrl }) {
    const correlationId = IdGenerator.newId();
    const payload = { formUrl };

    after(
      this._http.post(this.fetchFormUrl(customerId, integrationId), payload, {
        headers: { [HEADER_CORRELATION_ID]: correlationId },
      }),
      this.handleFetchFormResponse.bind(this)
    );

    return { correlationId };
  }

  performAction(customerId, integrationId, payload) {
    const correlationId = IdGenerator.newId();

    after(
      this._http.post(this.performActionUrl(customerId, integrationId), payload, {
        headers: { [HEADER_CORRELATION_ID]: correlationId },
      }),
      this.handlePerformActionResponse.bind(this)
    );

    return { correlationId };
  }

  handleFetchFormResponse(err, res) {
    if (err != null) {
      this.notifyObservers('handleFetchFormErrors', err);
    } else if (res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleFetchFormSuccess', res.data);
    } else {
      this.notifyObservers('handleFetchFormErrors', res.data);
    }
  }

  handlePerformActionResponse(err, res) {
    if (err != null) {
      err.response
        ? this.notifyObservers('handlePerformActionErrors', translateErrorResponse(err.response))
        : this.notifyObservers('handlePerformActionErrors', translateError(err));
    } else if (res.status >= 200 && res.status < 300) {
      this.notifyObservers('handlePerformActionSuccess', res.data);
    } else {
      this.notifyObservers('handlePerformActionErrors', translateErrorResponse(res));
    }
  }
}

mixin(ExternalCustomerLookupActionsGateway.prototype, Observable);
