import { isArray } from 'lodash';

const MAX_NOTES_PER_RETURN = 5;

// This is for backward compatibility with the current pre-GraphQL data retrieval APIs.
// For cards based on GraphQL data, there should be no need for these transforms
export const dataConfiguration = {
  dataItemTransform: item => {
    if (!isArray(item?.notes)) return item;
    return {
      ...item,
      notes: item.notes.slice(0, MAX_NOTES_PER_RETURN),
    };
  },
  dataFilter: {},
  dataRowLimit: 10,
  dataSort: {},
  namespace: 'loop_returns',
};

export const template = `
  <Card title="{{ __data.length }} Return{{- __data.length > 1 ? 's' : '' }}" expandThreshold="5">
    <List dataSource="__data" separateItems="yes">
      <Drawer initialState="expandFirst">
        <Fixed>
          <Text fontSize="medium" fontWeight="semiBold">Order {{ orderName }}</Text>
          <DateTimeValue dataSource="createdAt" label="Created on" valueFontWeight="medium" />
          <StringValue dataSource="stateCapitalized" />
        </Fixed>
        <Expandable>
          <!-- Top portion that we display for each return -->
          <Text fontSize="medium" fontWeight="semiBold">RMA {{ id }}</Text>
          <Link dataSource="url" linkText="Manage the return in Loop" valueFontWeight="medium" />
          <Spacer size="medium" />
          <StringValue dataSource="customerEmail" label="Order email" valueFontWeight="medium" />
          <DateTimeValue dataSource="updatedAt" label="Last updated" valueFontWeight="medium" />
          <Formula label="Status" valueFontWeight="medium">{{ stateCapitalized | capitalize }}</Formula>
          <StringValue dataSource="outcome" label="Outcome" valueFontWeight="medium" />
          <Divider spacing="medium"/>
          
          <!-- Credits -->
          <Section title="Returned item {{- (return.items | length) > 1 ? 's' : '' }}" padding="none" titleFontSize="medium">
            <List dataSource="return.items">
              <Text fontWeight="semiBold" maxLines="2">{{ title | capitalize }}</Text>
              <StringValue dataSource="returnReasonCombined" maxLines="2" valueBackground="transparent" />
              <StringValue dataSource="priceWithCurrency" label="Price" valueAlignment="right" />
              <Spacer when="__index < (__parent.return.items | length) - 1"/>
            </List>
          </Section>
          
          <!-- Exchanges -->
          <Spacer when="exchange.items | length > 0" />
          <Section title="Purchased item {{- (exchange.items | length) > 1 ? 's' : '' }}" padding="none" titleFontSize="medium">
            <List dataSource="exchange.items">
              <Text fontWeight="semiBold" maxLines="2">{{ title | capitalize }}</Text>
              <StringValue dataSource="sku" label="SKU" valueAlignment="right" />
              <StringValue dataSource="priceWithCurrency" label="Price" valueAlignment="right" />
              <StringValue dataSource="isOutOfStock" label="Out of stock" valueAlignment="right" />
              <Spacer when="__index < (__parent.exchange.items | length) - 1"/>
            </List>
          </Section>
          
          <!-- Return summary -->
          <Divider when="return is not empty" />
          <Section padding="none" title="Return credits" titleFontSize="medium">
            <StringValue dataSource="return.productTotalWithCurrency" label="Item total" valueAlignment="right" />
            <StringValue dataSource="return.discountTotalWithCurrency" label="Discounts" valueAlignment="right" />
            <StringValue dataSource="return.taxTotalWithCurrency" label="Tax" valueAlignment="right" />
            <StringValue dataSource="return.totalWithCurrency" label="Total" valueAlignment="right" valueFontWeight="semiBold" />          
          </Section>
          
          <Spacer when="exchange is not empty" />
          <Section padding="none" title="Purchased" titleFontSize="medium">
            <StringValue dataSource="exchange.productTotalWithCurrency" label="Item total" valueAlignment="right" />
            <StringValue dataSource="exchange.discountTotalWithCurrency" label="Discounts" valueAlignment="right" />
            <StringValue dataSource="exchange.taxTotalWithCurrency" label="Tax" valueAlignment="right" />
            <StringValue dataSource="exchange.totalWithCurrency" label="Total" valueAlignment="right" valueFontWeight="semiBold" />
          </Section>
          
          <Spacer when="costBreakdown.handlingFeeWithCurrency is not empty" />
          <Section padding="none" title="Other fees" titleFontSize="medium">
            <StringValue dataSource="costBreakdown.handlingFeeWithCurrency" label="Handling fee" valueAlignment="right" />
          </Section>
          
          <Spacer when="costBreakdown is not empty" />
          <Section padding="none" title="Totals" titleFontSize="medium"> 
            <StringValue dataSource="costBreakdown.refundWithCurrency" label="Refund total" valueAlignment="right" valueFontWeight="semiBold" />
            <StringValue dataSource="costBreakdown.upsellWithCurrency" label="Upsell total" valueAlignment="right" valueFontWeight="semiBold" />
            <StringValue dataSource="costBreakdown.giftCardAmountWithCurrency" label="Gift card total" valueAlignment="right" valueFontWeight="semiBold" />
          </Section>
          
          <!-- Shipping -->
          <Spacer when="shipping is not empty" />
          <Divider when="shipping is not empty" />
          <Section title="Shipping details" expandable="yes" padding="none" titleFontSize="medium">
            <StringValue dataSource="shipping.labelStatus" label="Shipment status" valueFontWeight="medium" />
            <DateTimeValue dataSource="shipping.labelUpdatedAt" label="Last updated at" valueFontWeight="medium" />
            <StringValue dataSource="shipping.carrier" label="Carrier" valueFontWeight="medium" />
            <StringValue dataSource="shipping.trackingNumber" label="Tracking number" valueFontWeight="medium" />
            <StringValue dataSource="shipping.returnMethod" label="Return method" valueFontWeight="medium" />
            <Spacer size="medium" />
            <Link dataSource="shipping.statusPageUrl" linkText="View the return status page" valueFontWeight="medium" />
          </Section>
          
          <!-- Notes -->
          <Spacer when="notes | length > 0" />
          <Divider when="notes | length > 0" />
          <Section title="Notes" expandable="yes" padding="none" titleFontSize="medium">
            <List dataSource="notes">
              <DateTimeValue dataSource="createdAt" valueFontWeight="semiBold" />
              <StringValue dataSource="content" maxLines="3" multilineStyle="expandable" />
              <Spacer size="medium" />
            </List>
          </Section>          
          
          <!-- Final spacer to visually separate the return from the list -->
          <Spacer size="medium"/>
        </Expandable>
      </Drawer>
    </List>
  </Card>
`;
