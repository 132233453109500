import Inbox from 'models/location/inbox';

export default class UpdateInboxLocation {
  constructor(context) {
    this.context = context;
  }

  run(...params) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof Inbox)) {
      // allow inbox update to finish if we've just navigated away
      let history = this.context.stores.locationHistory.get();
      let inboxLocation = history.getPreviousEntry();
      if (!(inboxLocation instanceof Inbox)) {
        throw new Error(
          `cannot update inbox location when current location is [${currentLocation &&
            currentLocation.constructor.name}]`
        );
      }

      this.updateInbox(inboxLocation, ...params);
      history.replacePreviousEntry(inboxLocation);
      this.context.stores.locationHistory.set(history);
      return;
    }
    this.updateInbox(currentLocation, ...params);
    this.context.stores.currentLocation.set(currentLocation);
  }

  updateInbox(inbox, ...runParams) {
    throw new Error('Not implemented. Subclasses must override');
  }
}
