import MqttGateway from './lib/mqtt_gateway';

export default class AgentActivityGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'agents/+/activity/+';
  }

  add(agentId, activityPayload, correlationId) {
    return super._add([agentId], activityPayload, correlationId);
  }

  // make sure NOT to have onAddSuccess/onAddErrors because this will break logout tracking
  // due to the async nature of our system, waiting for a response doesn't work on teardown
  // because we've already disconnected
}
