import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Information i
 */

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M8 16A8 8 0 108 0a8 8 0 000 16zM9 3v1.983H7V3h2zM7 13V7h2v6H7z"
      fill="#919191"
      fillRule="evenodd"
    />
  </Icon>
);
