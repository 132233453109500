import './lib/uuid';
import { Factory } from 'rosie';

Factory.define('ConversationItemSearchHit').extend('Uuid').attr('type', 'conversation').attr('status');

Factory.define('ConversationItemSearchHitWithDefaults')
  .extend('ConversationItemSearchHit')
  .attr('id', 'id-123')
  .attr('customer', { id: 'customerId-123', name: 'Javiera' })
  .attr('matchingText', 'tengo una camisa gris')
  .attr('topic', 'las cosas')
  .attr('topics', ['las cosas', 'las otra cosas'])
  .attr('status', 'WAITING')
  .attr('createdAt', '2017-01-11T00:00:00Z')
  .attr('updatedAt', '2017-01-12T00:00:00Z')
  .attr('dueAt', '2017-01-13T00:00:00Z');
