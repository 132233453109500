import { useMemo } from 'react';
import { createEditor } from 'slate';
import { withPlate } from '@udecode/plate';

import withPaste from 'components/text_editor_new/lib/with_paste';

export default function useEditorWithDecorators(plugins, { editorId }) {
  const editor = useMemo(() => {
    const baseEditor = withPlate(createEditor(), { plugins, id: editorId });
    return withPaste(baseEditor);
  }, [editorId, plugins]);

  return editor;
}
