import StandardGateway from './lib/standard_gateway';

export default class AgentStatusGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v3/orgs/:orgId/agent-status/:agentId');
  }

  get() {
    return super.fetch();
  }
}
