import hasLiveConnection from './has_live_connection';
import isCustomerLoaded from 'actions/customer/lib/is_customer_loaded';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';

export function updateActiveCallCustomer(context, newProfile) {
  let activeCall = context.stores.activeCall.get();
  let currentCustomerProfile = activeCall.customer;

  activeCall.updateCustomer(newProfile);

  let isPending = context.stores.activeCall.isPending();
  if (isPending) {
    context.stores.activeCall.setPending(activeCall);
  } else {
    context.stores.activeCall.set(activeCall);
  }

  if (!currentCustomerProfile.isVerified() && newProfile.isVerified() && hasLiveConnection(context, { activeCall })) {
    context.executeAction(NavigateToConversation, {
      customerId: newProfile.id,
      conversationId: activeCall.conversationId,
    });
  }
}

export function subscribeToActiveCall(context, { customerId } = {}) {
  let activeCall = context.stores.activeCall.get();
  customerId = customerId || activeCall.customer.id;

  context.gateways.customerHistoryMerge.subscribe(customerId);
  context.gateways.conversationItem.request(customerId, activeCall.conversationItem.id);
  if (!isCustomerLoaded(context, customerId)) {
    context.gateways.customerProfile.request({ customerId });
  }
}

export function unsubscribeFromActiveCall(context) {
  let activeCall = context.stores.activeCall.get();

  context.gateways.activeCall.cancelFetches();
  context.gateways.conversationItem.unsubscribe(activeCall.customer.id, activeCall.conversationItem.id);
  context.gateways.customerHistoryMerge.unsubscribe(activeCall.customer.id);
  if (!isCustomerLoaded(context, activeCall.customer.id)) {
    context.gateways.customerProfile.unsubscribe({ customerId: activeCall.customer.id });
  }
}
