import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import CustomerProfile from 'models/customer_profile';
import CustomerWasCombined from 'components/customer/customer_was_combined';
import ExternalCustomerLookupSearch from './external_customer_lookup_search';
import LoadingSpinner from 'components/lib/loading_spinner';

export const ExternalCustomerLookup = ({ isLoading, profile, mergedCustomerId }) => {
  if (mergedCustomerId) {
    return <CustomerWasCombined customerId={mergedCustomerId} />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!profile) {
    return null;
  }

  return <ExternalCustomerLookupSearch profile={profile} />;
};

function mapStateToProps({ getProvider }, { customerId }) {
  const customerMerge = getProvider('customerMerge').get();
  const mergedCustomerId =
    customerMerge && _.get(customerMerge, 'sourceCustomerId') === customerId && _.get(customerMerge, 'destCustomerId');

  const customerProfileDefProvider = getProvider('customerProfileDef');

  const profileProvider = getProvider('profile');
  const integrationsProvider = getProvider('integrations');

  const isLoading =
    profileProvider.isLoading() || customerProfileDefProvider.isLoading() || integrationsProvider.isLoading();

  const profile = profileProvider.get();

  return {
    isLoading,
    mergedCustomerId,
    profile,
  };
}

ExternalCustomerLookup.propTypes = {
  isLoading: PropTypes.bool,
  mergedCustomerId: PropTypes.string,
  profile: PropTypes.instanceOf(CustomerProfile),
};

export default connect(mapStateToProps, null)(ExternalCustomerLookup);
