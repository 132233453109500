import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import { H2 } from 'components/common/headers';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';

export default function ForgottenAttachmentModal({ onCancel, onSubmit, text }) {
  const onClickSend = useCallback(evt => onSubmit(evt, true), [onSubmit]);
  return (
    <PortalModalWrapper>
      <Modal>
        <ModalCard data-aid="forgotten-attachment-modal" onClose={onCancel}>
          <Close onClick={onCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
          <Header>It seems like you forgot to attach a file</Header>
          <p>You wrote &quot;{text}&quot; in your message, but there are no files attached. Send anyway?</p>
          <Actions>
            <Button
              buttonType={ButtonTypes.TEXT}
              data-aid="cancel-button"
              onClick={onCancel}
              onMouseDown={evt => evt.preventDefault()}
            >
              Cancel
            </Button>
            <Button
              buttonType={ButtonTypes.PRIMARY}
              className="forgottenAttachmentModal-sendButton"
              data-aid="send-button"
              onClick={onClickSend}
            >
              Send
            </Button>
          </Actions>
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

ForgottenAttachmentModal.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  text: PropTypes.string.isRequired,
};

const Close = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    color: ${p => p.theme.colors.gray600};
  }
`;

const Header = styled(H2)`
  display: inline;
`;

const Modal = styled.div`
  text-align: center;
  .modalCard-content {
    padding: ${p => p.theme.spacing.large};
    position: relative;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => p.theme.spacing.large};

  .forgottenAttachmentModal-sendButton {
    margin-left: ${p => p.theme.spacing.large};
  }
`;
