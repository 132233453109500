import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import Slider from 'react-slick';

import SnippetAttachmentContainer from 'components/admin/knowledge_base/snippet/snippet_attachment_container';
import SnippetUploadContainer from 'components/admin/knowledge_base/snippet/snippet_upload_container';

export default function SnippetAttachmentsSlider(props) {
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: props.slidesToShow,
    slidesToScroll: props.slidesToShow,
    nextArrow: (
      <div>
        <FontAwesomeIcon className="slider-SliderArrow" icon={faAngleRight} />
      </div>
    ),
    prevArrow: (
      <div>
        <FontAwesomeIcon className="slider-SliderArrow" icon={faAngleLeft} />
      </div>
    ),
  };
  return (
    <Slider className="answerEditor-attachments-slider" {...sliderSettings}>
      {props.children}
    </Slider>
  );
}

SnippetAttachmentsSlider.propTypes = {
  children: PropTypes.oneOfType([SnippetAttachmentContainer, SnippetUploadContainer]),
};
