import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

export default function MentionableAgent({ editorRef, dispatch, email, id, index, name, isSelected }) {
  const ref = useRef(null);

  useEffect(() => {
    if (isSelected) {
      // block: 'nearest' ensures that we only try to scroll the item into view if it's currently
      // not visible.
      ref.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isSelected]);

  const onClick = useCallback(() => {
    editorRef.current.completeAgentMentionSearch({ email, id, name });
  }, [editorRef]);

  const onMouseEnter = useCallback(() => {
    dispatch({ type: 'set', index });
  }, [dispatch, index]);

  return (
    <StyledItem
      $isSelected={isSelected}
      data-aid="agentMentionItem"
      onClick={onClick}
      onMouseDown={preventBlurring}
      onMouseEnter={onMouseEnter}
      ref={ref}
    >
      {name ? <Name title={name}>{name}</Name> : null}
      <Email hasName={!!name} title={email}>
        {email}
      </Email>
    </StyledItem>
  );
}

MentionableAgent.propTypes = {
  editorRef: PropTypes.shape({
    current: PropTypes.shape({
      completeAgentMentionSearch: PropTypes.func.isRequired,
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  isSelected: PropTypes.bool,
};

const StyledItem = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px 20px;
  ${p => {
    return p.$isSelected ? `background-color: ${p.theme.colors.green100};` : '';
  }}
`;
const Name = styled.span`
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Email = styled.span`
  color: ${p => (p.hasName ? p.theme.colors.gray500 : p.theme.colors.black)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function preventBlurring(evt) {
  evt.preventDefault();
}
