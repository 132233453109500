import _ from 'lodash';

import HttpUploader from './lib/http_uploader';
import MqttGateway from './lib/mqtt_gateway';
import Observable from 'scripts/lib/observable_mixin';

export default class SharedUploadGateway {
  constructor(backend, requestorId) {
    this.fileGateway = new FileGateway(backend, requestorId);
    this.uploadAuthGateway = new UploadAuthGateway(backend, requestorId);
    this.uploadAuthGateway.addObserver(this);
    this.httpUploader = new HttpUploader(backend, this.notifyObservers.bind(this));
  }

  abort(path) {
    return this.httpUploader.abort(path);
  }

  remove(path) {
    return this.fileGateway.remove(path);
  }

  upload(path, file, auth) {
    this.httpUploader.uploadFile(path, file, auth);
  }

  fetchUploadAuth(path, fileDescriptor) {
    return this.uploadAuthGateway.fetch(path, fileDescriptor);
  }

  handleFetchUploadAuthSuccess({ path, auth }) {
    this.notifyObservers('handleUploadAuth', { path, auth });
  }

  handleFetchUploadAuthError({ path, errors }) {
    this.notifyObservers('handleUploadAuthError', { path, errors });
  }

  init(opts) {
    this.fileGateway.init(opts);
    this.uploadAuthGateway.init(opts);
  }
}

_.extend(SharedUploadGateway.prototype, Observable);

class FileGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'uploads/+';
  }

  remove(path) {
    return this._delete([encodeURIComponent(path)]);
  }
}

class UploadAuthGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'upload-auth/+';
  }

  fetch(path, fileDescriptor) {
    return super._fetch([encodeURIComponent(path)], fileDescriptor);
  }

  onFetchResponse(auth, topic, parsedTopicParams) {
    let uploadAuth = { auth, path: decodeURIComponent(parsedTopicParams[0]) };
    this.notifyObservers('handleFetchUploadAuthSuccess', uploadAuth);
  }

  onFetchError({ errors }, topic, parsedTopicParams) {
    let result = { path: decodeURIComponent(parsedTopicParams[0]), errors };
    this.notifyObservers('handleFetchUploadAuthError', result);
  }
}
