import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FlightStatusColor } from 'models/configuration/transaction_def';

export default function FlightTransactionDot({ status, statusCodeMap }) {
  const outerCircleClassNames = getStatusClassNames('flightTransactionDot-outerCircle');
  const innerCircleClassNames = getStatusClassNames('flightTransactionDot-innerCircle');
  return (
    <svg className="flightTransactionDot" viewBox="0 0 157 157" xmlns="http://www.w3.org/2000/svg">
      <ellipse className={outerCircleClassNames} cx="78.5" cy="78.5" rx="73.5" ry="73.5" strokeWidth="9" />
      <ellipse className={innerCircleClassNames} cx="78.5" cy="78.5" rx="45.5" ry="45.5" strokeWidth="5" />
    </svg>
  );

  function getStatusClassNames(prefix) {
    return classnames(prefix, {
      [`${prefix}-greenStatus`]: statusCodeMap && statusCodeMap[status] === FlightStatusColor.GREEN,
      [`${prefix}-yellowStatus`]: statusCodeMap && statusCodeMap[status] === FlightStatusColor.YELLOW,
      [`${prefix}-redStatus`]: statusCodeMap && statusCodeMap[status] === FlightStatusColor.RED,
    });
  }
}

FlightTransactionDot.propTypes = {
  status: PropTypes.string,
  statusCodeMap: PropTypes.objectOf(PropTypes.string),
};
