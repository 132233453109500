import _ from 'lodash';

import connect from 'components/lib/connect';
import InboxList from './inbox_list';
import InteractionType, { routingChannelForInteractionType } from 'models/interaction_type';
import needsAttention from './needs_attention';
import { RoutingStatus } from 'models/routing_queue_item';
import { RoutingChannel } from 'models/agent_routing_preferences';

export function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const agentInboxProvider = getProvider('agentInboxItems');
  const agentInboxNewProvider = getProvider('agentInboxItemsNew');

  const agentInboxItems = agentInboxProvider.findAll();
  const agentInboxItemsNew = agentInboxNewProvider.findAll();
  const useNewInboxItems = isFeatureEnabled('customerListAssignmentBroadcast');

  const currentAgentId = getProvider('currentAgent').get().id;
  const currentCustomerId = getProvider('currentLocation').get().customerId;
  const commentPanel = getProvider('commentPanel').get();
  const activeCall = getProvider('activeCall').get();

  let isAgentOnCall = activeCall ? activeCall.isAgentOnLiveCall(currentAgentId) : false;
  let activeCallConversationId = isAgentOnCall ? activeCall.conversationId : null;

  let activeSessions = getProvider('activeSessions').findAll({
    filter: s =>
      (s.queueItem.status === RoutingStatus.ACTIVE || s.queueItem.status === RoutingStatus.OFFERED) &&
      _.get(s.queueItem, 'assignee.agentId') === currentAgentId,
  });

  const filteredInboxItems = filterInboxItems(agentInboxItems);
  const sortedInboxItems = sortItems(filteredInboxItems, activeCallConversationId, activeSessions);

  const filteredInboxItemsNew = filterInboxItems(agentInboxItemsNew);
  const sortedInboxItemsNew = sortItems(filteredInboxItemsNew, activeCallConversationId, activeSessions);

  const displayedInboxItems = useNewInboxItems ? sortedInboxItemsNew : sortedInboxItems;

  return {
    activeSessions,
    activeCallConversationId,
    agentInboxItems: displayedInboxItems,
    canPerformAgentActions: isFeatureEnabled('internalAgentActions'),
    currentAgentId,
    inboxItemErrors: getInboxItemErrors(),
    isLoading: useNewInboxItems ? agentInboxNewProvider.isLoading() : agentInboxProvider.isLoading(),
    selectedItemId: getSelectedItemId(displayedInboxItems, commentPanel.selectedItemId, currentCustomerId),
  };

  function getInboxItemErrors() {
    if (!useNewInboxItems) {
      return {};
    }

    const errorMap = {};
    _.forEach(agentInboxNewProvider.findAll(), item => {
      const errors = agentInboxNewProvider.getErrors(item.id);
      errorMap[item.id] = errors;
    });
    return errorMap;
  }
}

function filterInboxItems(items) {
  return _.filter(items, item => item.hasLoaded() && !item.removedAt);
}

function getSelectedItemId(inboxItems, selectedTaskId, currentCustomerId) {
  if (!selectedTaskId) {
    return currentCustomerId;
  }
  let selectedTask = _.find(inboxItems, { id: selectedTaskId });
  return (selectedTask && selectedTask.id) || currentCustomerId;
}

function sortItems(inboxItems, activeCallConversationId, activeSessions) {
  const activeCallItem = _.remove(
    inboxItems,
    item => item.conversation && item.conversation.id === activeCallConversationId
  );

  const chatSessions = _.filter(
    activeSessions,
    session => !!_.find(session.queueItem.sessions, s => s.type === InteractionType.CHAT)
  );
  const chatConversationIds = new Set(_.map(chatSessions, s => s.queueItem.conversationId));
  const chatItems = _.orderBy(
    _.remove(inboxItems, item => item.conversation && chatConversationIds.has(item.conversation.id)),
    [item => item.getUpdatedAt()],
    'asc'
  );
  const [chatItemsNeedingAttention, otherChatItems] = _.partition(chatItems, i =>
    needsAttention(i, activeSessions, activeCallConversationId)
  );

  const nonChatMessagingSessions = _.filter(
    activeSessions,
    session => !!_.find(session.queueItem.sessions, s => s.type !== InteractionType.CHAT && isMessagingType(s.type))
  );
  const nonChatMessagingConversationIds = new Set(_.map(nonChatMessagingSessions, s => s.queueItem.conversationId));
  const nonChatMessagingItems = _.orderBy(
    _.remove(inboxItems, item => item.conversation && nonChatMessagingConversationIds.has(item.conversation.id)),
    [item => item.getUpdatedAt()],
    'asc'
  );
  const [messagingItemsNeedingAttention, otherMessagingItems] = _.partition(nonChatMessagingItems, i =>
    needsAttention(i, activeSessions, activeCallConversationId)
  );

  const sortedItems = _.orderBy(inboxItems, [item => item.getUpdatedAt()], 'desc');
  let [hasSla, noSla] = _.partition(sortedItems, item => item.hasSLA());
  hasSla = _.orderBy(hasSla, [item => item.getDueAt()], 'asc');

  return [
    ...activeCallItem,
    ...chatItemsNeedingAttention,
    ...otherChatItems,
    ...messagingItemsNeedingAttention,
    ...otherMessagingItems,
    ...hasSla,
    ...noSla,
  ];

  function isMessagingType(sessionType) {
    return routingChannelForInteractionType(sessionType) === RoutingChannel.MESSAGING;
  }
}

const SidebarInboxContainer = connect(mapStateToProps)(InboxList);
export default SidebarInboxContainer;
