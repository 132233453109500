import AgentPhonePreferences from './agent_phone_preferences';
import createModel, { prop } from './lib/create_model';
import ServerClock from 'scripts/application/lib/server_clock';

const StationConfiguration = createModel({
  modelName: 'StationConfiguration',
  properties: {
    id: prop(String).isRequired,
    address: prop(String).isRequired,
    name: prop(String).isRequired,
    type: prop().oneOf(AgentPhonePreferences.DIRECT_DIAL, AgentPhonePreferences.STATION).isRequired,
    updatedAt: prop(String).default(() => ServerClock.toISOString()),
  },

  statics: {
    create(attrs) {
      return this(attrs);
    },
  },
});

export function convertPhoneToDirectDialAddress(phone) {
  const normalized = phone.normalized;
  const extension = phone.extension;
  return extension ? `${normalized};ext=${extension}` : `${normalized}`;
}

export default StationConfiguration;
