import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import NoReplyNeeded from 'models/no_reply_needed';

export default function createNoReplyNeeded({ agentProfile, conversationId, content = {}, customerId }) {
  return ConversationItem.create({
    conversationId,
    content: NoReplyNeeded.create(content),
    customerId,
    initiator: {
      id: agentProfile.id,
      type: Communicator.AGENT,
    },
  });
}
