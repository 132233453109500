import createConverter from 'scripts/adapters/immutable_converters/lib/create_converter';
import createDocumentStoreClass from './lib/create_document_store_class';
import LocationHistory from 'models/location_history';

const converter = createConverter(LocationHistory);

export default createDocumentStoreClass({
  converter,
  defaultImmutable: converter.toImmutable(LocationHistory.create()),
});
