import TrackAnswerUsed from 'actions/knowledge_base/track_answer_used';

export default class TrackAnswerPanelView {
  constructor(context) {
    this.context = context;
  }

  run({ answer, channel, language, time }) {
    this.context.analytics.track('Answer Menu - View', { channel, time });
    this.context.executeAction(TrackAnswerUsed, { answer, channel, language, method: 'VIEWED_10' });
  }
}
