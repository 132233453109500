import createSimulationKeyMap from './create_simulation_key_map';

export default function configureCommSimulation(runnerContext) {
  var keyMap = createSimulationKeyMap(runnerContext);

  window.addEventListener('keydown', function(event) {
    if (event.shiftKey && event.ctrlKey && keyMap[event.keyCode]) {
      event.preventDefault();
      keyMap[event.keyCode]();
    }
  });
}
