import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import ChevronDown from 'components/lib/icons/chevron_down_icon';
import ChevronUp from 'components/lib/icons/chevron_up_icon';
import classnames from 'classnames';
import connect from 'components/lib/connect';
import Link from 'components/common/link';
import SummaryCard, { SummaryHeader } from 'components/customer/summary/lib/summary_card';

function Payouts({ customAttributes, def, showPayouts, type }) {
  if (!def || !showPayouts) {
    return null;
  }

  let payouts = _.get(customAttributes, 'payouts');
  if (_.isEmpty(payouts)) {
    return null;
  }
  return (
    <SummaryCard>
      {def.label && <SummaryHeader text={`${def.label}`} />}
      <div className="customEvents-payoutsColumnHeader">
        <div className="customEvents-payoutsColumnHeader-label">Payment ID</div>
        <div className="customEvents-payoutsColumnHeader-label">Status</div>
      </div>
      <div className={'customEvents-payoutsHeader'}>
        <div className="customEvents-listWrapper">
          <div className={classnames('customEvents-list')}>{payouts.map(renderPayouts)}</div>
        </div>
      </div>
    </SummaryCard>
  );

  function renderPayouts(payout, index) {
    return <PayoutItem def={def} key={index} payout={payout} />;
  }
}

Payouts.propTypes = {
  customAttributes: PropTypes.object.isRequired,
  def: PropTypes.object,
  showPayouts: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

class PayoutItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };

    _.bindAll(this, ['handleMenuClick']);
  }

  render() {
    let payoutDef = _.get(this.props.def, 'row');
    let description = this.props.payout[_.get(payoutDef, 'description.attr')];
    let payoutTitle = this.props.payout[_.get(payoutDef, 'title.attr')];
    let payoutTitleUrl = this.props.payout[_.get(payoutDef, 'title.url')];
    return (
      <div className="customEvents-payout-line" key={this.props.key}>
        <div className="customEvents-payout-line-wrapper">
          <div className="customEvents-payout-item-wrapper">
            <div className="customEvents-payout-item">
              {payoutTitleUrl ? this.renderLink(payoutTitleUrl, payoutTitle) : <div>{payoutTitle}</div>}
            </div>
          </div>
          <div className="customEvents-payout-item-wrapper">
            {this.renderStatusDot(description)}
            <div className="customEvents-payout-description">{description}</div>
            {this.renderExpansionButton()}
          </div>
        </div>
        {this.state.isExpanded ? this.renderExpandedInfo(this.props.payout, payoutDef) : null}
      </div>
    );
  }

  renderLink(url, name) {
    return (
      <Link href={url} onClick={() => window.open(url, '_blank')}>
        {name}
      </Link>
    );
  }

  renderStatusDot(status) {
    switch (status.toLowerCase().replace(/\s/g, '')) {
      case 'inreview':
        return <div className="customEvents-payouts-status-yellow" />;
      case 'paid':
        return <div className="customEvents-payouts-status-green" />;
      default:
        return <div className="customEvents-payouts-status-gray" />;
    }
  }

  renderExpansionButton() {
    if (this.state.isExpanded) {
      return <ChevronDown className="customEvents-payouts-expansion" onClick={this.handleMenuClick} />;
    }
    return <ChevronUp className="customEvents-payouts-expansion" onClick={this.handleMenuClick} />;
  }

  renderExpandedInfo(payout, payoutDef) {
    let detailsOne = this.props.payout[_.get(payoutDef, 'detailsOne.attr')];
    let detailsTwo = this.props.payout[_.get(payoutDef, 'detailsTwo.attr')];
    let detailsThree = this.props.payout[_.get(payoutDef, 'detailsThree.attr')];
    let detailsOneUrl = this.props.payout[_.get(payoutDef, 'detailsOne.url')];
    let detailsTwoUrl = this.props.payout[_.get(payoutDef, 'detailsTwo.url')];
    let detailsThreeUrl = this.props.payout[_.get(payoutDef, 'detailsThree.url')];

    return (
      <div className="customEvents-payout-detail-wrapper">
        <div className="customEvents-payout-detail-columnWrapper customEvents-payout-detailOne">
          <div className="customEvents-payout-detail-columnLabel">{_.get(payoutDef, 'detailsOne.label')}</div>
          {detailsOneUrl ? this.renderLink(detailsOneUrl, detailsOne) : <div>{detailsOne}</div>}
        </div>
        <div className="customEvents-payout-detail-columnWrapper customEvents-payout-detailTwo">
          <div className="customEvents-payout-detail-columnLabel">{_.get(payoutDef, 'detailsTwo.label')}</div>
          {detailsTwoUrl ? this.renderLink(detailsTwoUrl, detailsTwo) : <div>{detailsTwo}</div>}
        </div>
        <div className="customEvents-payout-detail-columnWrapper customEvents-payout-detailThree">
          <div className="customEvents-payout-detail-columnLabel">{_.get(payoutDef, 'detailsThree.label')}</div>
          {detailsThreeUrl ? this.renderLink(detailsThreeUrl, detailsThree) : <div>{detailsThree}</div>}
        </div>
      </div>
    );
  }

  handleMenuClick() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }
}

PayoutItem.propTypes = {
  def: PropTypes.object,
  payout: PropTypes.object.isRequired,
  key: PropTypes.number.isRequired,
};

export { Payouts, PayoutItem };

const PayoutsContainer = connect(mapStateToProps)(Payouts);

function mapStateToProps({ getProvider, isFeatureEnabled }, { type }) {
  return {
    customAttributes: _.get(getProvider('profile').get(), 'customAttributes') || {},
    def: _.get(getProvider('customerProfileDef').get(), type),
    showPayouts: isFeatureEnabled('demoMode'),
    type,
  };
}

PayoutsContainer.propTypes = {
  customAttributes: PropTypes.object,
  def: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default PayoutsContainer;
