import Mousetrap from 'components/lib/mousetrap';

function KeyPressShortcutMixin() {
  return {
    shortcuts: [],

    registerKeyPressShortcut(keyboardShortcut, callback) {
      this.shortcuts.push(keyboardShortcut);
      Mousetrap.bind(keyboardShortcut, callback);
    },

    deregisterKeyPressShortcuts() {
      this.shortcuts.map(function(shortcut) {
        Mousetrap.unbind(shortcut);
      });
    },

    componentWillUnmount() {
      this.deregisterKeyPressShortcuts();
    },
  };
}

export default KeyPressShortcutMixin;
