import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import InstagramFillIcon from 'components/common/icons/fill/instagram-fill';
import InstagramVerifiedIcon from 'components/lib/icons/instagram_verified_icon';
import Tooltip from 'components/common/tooltip';

const rowWrapperClassNames =
  'customerProfile-instagramRow customerProfile-contactDetails-row customerProfile-contactDetails-readonly static';

const InstagramDirectField = props => {
  if (!props.address) {
    return null;
  }
  if (props.address.content) {
    return (
      <div className={'customerProfile-instagramSection'}>
        <div className={rowWrapperClassNames}>
          <InstagramFillIcon className="customerProfile-instagramIcon" />
          <div className="customerProfile-instagram-field customerProfile-instagram-field-displayName">
            {props.address.displayName}
          </div>
          {renderVerifiedIcon(props.address)}
        </div>
        {renderInstagramFollowers(props.address.content.followerCount)}
        {renderInstagramBusinessFollowers(props.address.content.businessFollowers)}
        {renderInstagramFollowedBusinesses(props.address.content.followedBusinesses)}
      </div>
    );
  }
  return (
    <div className={rowWrapperClassNames}>
      <InstagramFillIcon className="customerProfile-instagramIcon" />
      <div className="customerProfile-instagram-field customerProfile-instagram-field-displayName">
        {props.address.displayName}
      </div>
    </div>
  );
};

function renderVerifiedIcon(address) {
  if (!address.content.isVerifiedUser) {
    return null;
  }
  return (
    <StyledTooltip message={'Verified Account'} position="top">
      <StyledInstagramVerifiedIcon className="customerProfile-instagramVerifiedIcon" />
    </StyledTooltip>
  );
}

function renderInstagramFollowers(followerCount) {
  if (!followerCount) return null;

  let followerCountStr = `${followerCount} Follower`;
  if (followerCount > 1) {
    followerCountStr += `s`;
  }

  return (
    <div className={classNames(rowWrapperClassNames, 'compact')}>
      <div className="customerProfile-instagram-field customerProfile-instagram-field-additional-info customerProfile-instagram-field-followerCount">
        {followerCountStr}
      </div>
    </div>
  );
}

function renderInstagramBusinessFollowers(businessFollowers) {
  if (!businessFollowers || businessFollowers.length === 0) {
    return null;
  }

  let firstFollower = businessFollowers[0];
  const otherFollows = _.slice(businessFollowers, 1);

  return (
    <div className={classNames(rowWrapperClassNames, 'compact')}>
      <div className="customerProfile-instagram-field customerProfile-instagram-field-additional-info customerProfile-instagram-field-businessFollowers">
        <StyledFollowSpan>Followed by </StyledFollowSpan>
        {firstFollower}
        {renderMoreInstagramFollows(otherFollows)}
      </div>
    </div>
  );
}

function renderMoreInstagramFollows(otherInstagramFollows) {
  if (otherInstagramFollows.length === 0) {
    return null;
  }
  let label = _.join(otherInstagramFollows, ',');
  const othersStr = otherInstagramFollows.length === 1 ? 'other' : 'others';
  return (
    <Tooltip message={label} position="top">
      {`, +${otherInstagramFollows.length} ${othersStr}`}
    </Tooltip>
  );
}

function renderInstagramFollowedBusinesses(followedBusinesses) {
  if (!followedBusinesses || followedBusinesses.length === 0) {
    return null;
  }

  let followStr = followedBusinesses[0];
  const otherFollows = _.slice(followedBusinesses, 1);

  return (
    <div className={classNames(rowWrapperClassNames, 'compact')}>
      <div className="customerProfile-instagram-field customerProfile-instagram-field-additional-info customerProfile-instagram-field-followedBusinesses">
        <StyledFollowSpan>Follows </StyledFollowSpan>
        {followStr}
        {renderMoreInstagramFollows(otherFollows)}
      </div>
    </div>
  );
}

const StyledFollowSpan = styled.span`
  color: #919191;
`;

const StyledInstagramVerifiedIcon = styled(InstagramVerifiedIcon)`
  path {
    transform: scale(0.05);
  }
`;

const StyledTooltip = styled(Tooltip)`
  height: 16px;
  padding-left: 4px;
`;

InstagramDirectField.propTypes = {
  address: PropTypes.shape({
    content: PropTypes.shape({
      businessFollowers: PropTypes.arrayOf(PropTypes.node),
      isVerifiedUser: PropTypes.bool,
      followedBusinesses: PropTypes.arrayOf(PropTypes.string),
      followerCount: PropTypes.number,
    }),
    displayName: PropTypes.string,
  }),
};

export default InstagramDirectField;
