import ShowDisconnectWarning from 'actions/notification/show_disconnect_warning';

export default class DisconnectCheckBeforeUnloadEventHandler {
  constructor(context) {
    this.context = context;
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
  }

  handleBeforeUnload(e) {
    const auth = this.context.stores.auth.get();
    const isLoggedIn = auth && auth.isLoggedIn();

    const isDisconnected = this.context.stores.connectionState.get().isDisconnected();

    if (isLoggedIn && isDisconnected) {
      this.context.executeAction(ShowDisconnectWarning);
    }
  }
}
