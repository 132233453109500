import createReactClass from 'create-react-class';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';
import Modal from 'components/lib/modal';
import ModalButton, { ModalButtonDivider } from 'components/modals/modal_button';
import ModalFooter from 'components/modals/modal_footer';

const DeleteAnswerCardModal = createReactClass({
  propTypes: {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
  },

  render() {
    return (
      <Portal node={document && document.getElementById('deleteAnswerCardModal-container')}>
        {this.renderModal()}
      </Portal>
    );
  },

  renderModal() {
    return (
      <div>
        <ReactCSSTransitionGroup
          transitionAppear
          transitionAppearTimeout={1000}
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1}
          transitionName="Modal-backdrop"
        >
          <div className="Modal-backdrop" />
        </ReactCSSTransitionGroup>
        <Modal className="deleteAnswerCardModal">
          <div className="deleteAnswerCardModal-header">Delete Response</div>
          <div className="deleteAnswerCardModal-body">Are you sure you want to delete this channel?</div>
          <ModalFooter>
            <ModalButton onClick={this.onCancel}>Cancel</ModalButton>
            <ModalButtonDivider />
            <ModalButton onClick={this.onConfirm}>Yes, Delete</ModalButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  },

  onCancel() {
    this.props.onCancel();
  },

  onConfirm() {
    this.props.onConfirm();
  },
});

export default DeleteAnswerCardModal;
