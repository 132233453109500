import PhoneCall from 'models/phone_call';

export default class BlindTransferPhoneCall {
  constructor(context) {
    this.context = context;
  }

  run(routingGroupId) {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      activeCall.conversationItem.content.setParticipantStatus(
        this.currentAgentId,
        PhoneCall.ParticipantStatus.TRANSFER_REQUESTED
      );
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.blindTransfer(activeCall.customer.id, activeCall.conversationItem.id, {
        routingGroupId,
        currentAgentId: this.currentAgentId,
      });
    }
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }
}
