import React from 'react';

import _ from 'lodash';
import classnames from 'classnames';
import { faPlane } from '@fortawesome/pro-solid-svg-icons/faPlane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';

import { FlightAttributesPropTypes } from 'components/customer/summary/transactions/constants';
import { FlightTransactionDef } from 'models/configuration/transaction_def';

export default function ExpandedFlightTransactionHeader({ attributes, transactionDef }) {
  const departureDate = attributes.departureTime ? moment(attributes.departureTime).format('MMM D YYYY') : '';
  const departureTime = attributes.departureTime ? moment(attributes.departureTime).format('hh:mm A') : '';

  const headerClassNames = classnames('flightTransaction-expandedInformation-header', {
    'flightTransaction-expandedInformation-header-withoutDepartureTime': !attributes.departureTime,
    'flightTransaction-expandedInformation-header-noOptionalAttributes': !_.some(
      _.map(transactionDef.attributes, def => attributes[def.attr])
    ),
  });

  return (
    <div className={headerClassNames}>
      <div className="flightTransaction-expandedInformation-header-origin-destination-wrapper">
        <div className="flightTransaction-expandedInformation-header-origin">
          <div className="flightTransaction-expandedInformation-header-originCode">{attributes.origin}</div>
        </div>
        <div className="flightTransaction-expandedInformation-header-flightIcon">
          <FontAwesomeIcon icon={faPlane} />
        </div>
        <div className="flightTransaction-expandedInformation-header-destination">{attributes.destination}</div>
      </div>
      <div className="flightTransaction-expandedInformation-header-left">
        <div className="flightTransaction-expandedInformation-header-departureDate">{departureDate}</div>
      </div>
      <div className="flightTransaction-expandedInformation-header-right">
        <div className="flightTransaction-expandedInformation-header-departureTime">{departureTime}</div>
      </div>
    </div>
  );
}

ExpandedFlightTransactionHeader.propTypes = {
  attributes: FlightAttributesPropTypes.isRequired,
  transactionDef: PropTypes.instanceOf(FlightTransactionDef).isRequired,
};
