import { closeConversation } from 'actions/conversation/lib/conversation_action_helpers';

export default class CloseConversation {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId, customerId }) {
    closeConversation(this.context, {
      conversationId,
      customerId,
      agentId: this.currentAgent.id,
    });
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
