import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0)">
      <path
        clipRule="evenodd"
        d="M2.939 5.55c.223-.517.455-1.054.735-1.63.223-.397.467-.751.913-.793h6.836c.446.042.69.396.913.793.28.576.512 1.113.735 1.63l.274.63.01.057H2.644l.005-.023c.097-.219.192-.44.289-.663zM0 6c.006-.267.144-.494.246-.494h1.542l1.014-2.358C3.41 2.27 3.857 2 4.587 2h6.836c.73 0 1.177.271 1.785 1.148l1.014 2.358h1.542c.094 0 .23.227.236.494v.01c0 .022 0 .045-.002.068a1.334 1.334 0 01-1.005 1.215l.33.815c.023.06.035.123.035.187 0 .836-.005 1.668-.01 2.496-.005.856-.01 1.71-.01 2.562 0 .355-.263.647-.608.647h-2.11a.641.641 0 01-.629-.647v-1.42H4v1.42c0 .355-.264.647-.609.647H1.28a.641.641 0 01-.628-.647c0-1.678 0-3.357-.02-5.055a.5.5 0 01.037-.193l.329-.814A1.334 1.334 0 010 6zm5.845 3.242h4.3c.264 0 .467.23.467.5 0 .251-.203.48-.467.48h-4.3c-.244 0-.467-.229-.467-.48 0-.27.223-.5.467-.5zm7.221-.919c-.548 0-1.014.46-1.014 1.044 0 .563.466 1.043 1.014 1.043.568 0 1.014-.48 1.014-1.043 0-.584-.446-1.044-1.014-1.044zm-10.122 0c.548 0 1.014.46 1.014 1.044 0 .563-.466 1.043-1.014 1.043-.568 0-1.014-.48-1.014-1.043 0-.584.446-1.044 1.014-1.044z"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h16v16H0z" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
