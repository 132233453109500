import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  AdminListItem,
  AdminListItemHeader,
  AdminListItemBody,
  AdminListItemMetaSwap,
  AdminTrash,
} from 'components/admin/lib/admin_layout';
import { AttachmentTypeIcon } from 'components/lib/attachment/attachment_type';
import AnswerErrLink from './snippet/answer_error_link';
import ConfirmOrCancelModal from 'components/common/confirm_or_cancel_modal';
import { H3 } from 'components/common/headers';
import { Languages } from 'models/answers/snippet_channels';
import Err from 'models/err';
import LoadingSpinner from 'components/lib/loading_spinner';
import Snippet, { SnippetContentType } from 'models/answers/snippet';
import SnippetAdminPaginateContainer from './snippet/snippet_admin_paginate_container';

const StyledSnippetName = styled(H3)`
  display: inline;
  flex: 0 1 auto;
  margin-bottom: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EmptyResults = styled.h4`
  margin: 12px 24px;
`;

const StyledSnippetSubtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient vertical;
  color: ${p => p.theme.colors.gray600};
  font-size: ${p => p.theme.fontSize.base};
  font-weight: bold;
  margin-bottom: ${p => p.theme.spacing.small};
`;

const SnippetsAdminList = createReactClass({
  propTypes: {
    isLoading: PropTypes.bool,
    onNavigateToSnippet: PropTypes.func.isRequired,
    onRemoveSnippet: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    snippets: PropTypes.arrayOf(PropTypes.instanceOf(Snippet)),
    textFilter: PropTypes.string.isRequired,
    errors: PropTypes.object,
  },

  getInitialState() {
    return { scrollToIndex: -1, isDeleteModalVisible: false, pendingSnippetId: null };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.textFilter !== this.props.textFilter) {
      this.setState({ scrollToIndex: 0 });
    } else {
      this.setState({ scrollToIndex: -1 });
    }
  },

  render() {
    if (this.props.isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <div className="adminList snippetsAdmin-list">
        {this.renderSnippets()}
        {this.renderPaginate()}
        {this.renderDeleteConfirmationModal()}
      </div>
    );
  },

  onCancelDeleteAnswer() {
    this.setState({ isDeleteModalVisible: false, pendingSnippetId: null });
  },

  onConfirmDeleteAnswer() {
    let pendingSnippetId = this.state.pendingSnippetId;
    pendingSnippetId && this.props.onRemoveSnippet(this.state.pendingSnippetId);
    this.onCancelDeleteAnswer();
  },

  onClickSnippet(snippetId) {
    this.props.onNavigateToSnippet(snippetId);
  },

  onClickRemoveSnippet(snippetId, evt) {
    evt.stopPropagation();
    this.setState({ pendingSnippetId: snippetId, isDeleteModalVisible: true });
  },

  renderDeleteConfirmationModal() {
    if (!this.state.isDeleteModalVisible) return null;

    return (
      <ConfirmOrCancelModal
        onCancel={this.onCancelDeleteAnswer}
        onSubmit={this.onConfirmDeleteAnswer}
        submitText="Remove"
        title="Permanently remove this answer?"
      />
    );
  },

  renderSnippets() {
    if (this.props.snippets.length === 0) {
      return <EmptyResults>No results to display</EmptyResults>;
    }

    let snippets = _.map(this.props.snippets, snippet => {
      return (
        <AdminListItem
          className="snippetsAdmin-list-item"
          key={snippet.id}
          onClick={this.onClickSnippet.bind(this, snippet.id)}
        >
          <div className="snippetsAdmin-list-item-content">
            <AdminListItemHeader className="snippetsAdmin-list-item-header">
              <StyledSnippetName data-aid="snippetsAdmin-list-item-header-name">{snippet.name}</StyledSnippetName>
              {this.renderChannelTypes(snippet)}
            </AdminListItemHeader>
            {this.renderLanguages(snippet)}
            {this.renderAudiences(snippet)}
            {this.renderBody(snippet)}
            {this.renderErrors(snippet.id)}
          </div>
          {this.renderAttachments(snippet)}
        </AdminListItem>
      );
    });

    return snippets;
  },

  renderLanguages(snippet) {
    let languages = snippet.contents.map(c => Languages[c.language]);
    return <StyledSnippetSubtitle>{languages.join(', ')}</StyledSnippetSubtitle>;
  },

  renderAudiences(snippet) {
    if (!snippet.audienceIds) {
      return null;
    }
    let audiences = snippet.audienceIds.map(a => this.props.audiences[a]);
    return <StyledSnippetSubtitle>{audiences.join(', ')}</StyledSnippetSubtitle>;
  },

  renderChannelTypes(snippet) {
    const infoExists = _.some(snippet.contents, c => _.get(c, 'info.bodyHtml'));
    const anyChannelExists = _.some(snippet.contents, c => _.get(c, 'anyChannel.bodyHtml'));
    const messageExists = _.some(snippet.contents, c => _.get(c, 'message.bodyHtml'));
    const selfServiceExists = _.some(snippet.contents, c => _.get(c, 'selfService.bodyHtml'));

    return (
      <div className="snippetsAdmin-list-channelTypes">
        {infoExists ? (
          <FontAwesomeIcon className="snippetsAdmin-channelIcon snippetsAdmin-channelIcon-info" icon={faBook} />
        ) : null}
        {anyChannelExists ? (
          <FontAwesomeIcon className="snippetsAdmin-channelIcon snippetsAdmin-channelIcon-email" icon={faEnvelope} />
        ) : null}
        {messageExists ? (
          <FontAwesomeIcon className="snippetsAdmin-channelIcon snippetsAdmin-channelIcon-message" icon={faComment} />
        ) : null}
        {selfServiceExists ? (
          <FontAwesomeIcon className="snippetsAdmin-channelIcon snippetsAdmin-channelIcon-selfService" icon={faGlobe} />
        ) : null}
      </div>
    );
  },

  renderPaginate() {
    return (
      <AdminListItem className="snippetsAdmin-list-item">
        <div className="snippetsAdmin-paginate">
          <SnippetAdminPaginateContainer searchText={this.props.textFilter} />
        </div>
      </AdminListItem>
    );
  },

  renderAttachments(snippet) {
    let content = snippet.findContentByLanguage('en-us');
    let attachmentTypes = (
      <div className="snippetsAdmin-list-item-meta-attachmentTypes">
        {_.takeRight(content.getAttachmentsByType(SnippetContentType.ANY_CHANNEL), 4).map(a => (
          <AttachmentTypeIcon key={a.id} title={a.fileDescriptor().filename} type={a.fileDescriptor().contentType} />
        ))}
      </div>
    );

    if (this.props.readOnly) {
      return (
        <div className="snippetsAdmin-list-item-meta snippetsAdmin-list-item-meta-readonly">{attachmentTypes}</div>
      );
    }

    return (
      <AdminListItemMetaSwap className="snippetsAdmin-list-item-meta">
        {attachmentTypes}
        <AdminTrash onClick={this.onClickRemoveSnippet.bind(this, snippet.id)} />
      </AdminListItemMetaSwap>
    );
  },

  renderBody(snippet) {
    let classNames = classnames('snippetsAdmin-list-item-body', {
      hasError: this.props.errors && this.props.errors[snippet.id],
      noError: !(this.props.errors && this.props.errors[snippet.id]), // necessary to prevent bad nesting in IE11
    });
    return <AdminListItemBody className={classNames}>{snippet.plaintext}</AdminListItemBody>;
  },

  renderErrors(snippetId) {
    let containsError = this.props.errors && this.props.errors[snippetId];
    if (containsError) {
      return (
        <AdminListItemBody className="snippetsAdmin-list-item-body isError">
          {errorMessage(this.props.errors[snippetId])}
        </AdminListItemBody>
      );
    }
  },

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  },

  updateWindowDimensions() {
    this.setState({ windowHeight: window.innerHeight });
  },
});

function errorMessage(snippetErrorList) {
  let rulesUsedIn = getUsedInRules(snippetErrorList);

  if (snippetErrorList[0].meta && snippetErrorList[0].meta.helpCenterID) {
    return getHelpCenterErrorMessage(snippetErrorList);
  } else if (snippetErrorList[0].meta && snippetErrorList[0].meta.ivrId) {
    return getConsumerSelfServiceErrorMessage(snippetErrorList);
  } else if (rulesUsedIn.length > 0) {
    return `Could not delete answer, used in rule${snippetErrorList.length > 1 ? 's' : ''}: ${rulesUsedIn.join(', ')}`;
  }
  return 'Could not delete answer';
}

function getUsedInRules(errors) {
  return errors
    .filter(err => err.code === Err.Code.IN_USE)
    .map(err => {
      let matches = err.detail && err.detail.match(/\[(.*?)\]/);
      return matches ? matches[1] : null;
    });
}

function getHelpCenterErrorMessage(errors) {
  return (
    <span>
      This Answer cannot be deleted until it is removed from{' '}
      <AnswerErrLink href={`/admin/help-center/${errors[0].meta.helpCenterID}/sections/${errors[0].meta.sectionID}`}>
        this Help Center section.
      </AnswerErrLink>
    </span>
  );
}

function getConsumerSelfServiceErrorMessage(errors) {
  return (
    <span>
      This answer cannot be deleted until it is removed from{' '}
      <AnswerErrLink href={`/admin/threads/${errors[0].meta.ivrId}`}>this</AnswerErrLink> consumer self service thread.
    </span>
  );
}

export default SnippetsAdminList;
