import React, { useContext, useRef, useState } from 'react';

import connect from 'scripts/presentation/components/lib/connect';
import createAnswerLinksWithStatus from 'components/customer/composition/lib/answer/create_answer_link_with_status';

export const DEFAULT_CONTEXT = {};

const AnswerLinkContext = React.createContext(DEFAULT_CONTEXT);
export default AnswerLinkContext;

export function AnswerLinkProviderBase({ answer, answerLinks, answerLinksImm, children, isAnswerLinksStoreLoading }) {
  const [value, setValue] = useState(() => createAnswerLinksWithStatus(answer, answerLinks, isAnswerLinksStoreLoading));

  const previous = useRef({
    answer,
    answerLinksImm,
    isAnswerLinksStoreLoading,
  });

  if (
    previous.current.answersLinkImm !== answerLinksImm ||
    previous.current.answer !== answer ||
    previous.current.isAnswerLinksStoreLoading !== isAnswerLinksStoreLoading
  ) {
    previous.current = {
      answer,
      answerLinksImm,
      isAnswerLinksStoreLoading,
    };

    setValue(createAnswerLinksWithStatus(answer, answerLinks, isAnswerLinksStoreLoading));
  }

  return <AnswerLinkContext.Provider value={value}>{children}</AnswerLinkContext.Provider>;
}

const AnswerLinkProvider = connect(mapStateToProps)(AnswerLinkProviderBase);
export { AnswerLinkProvider };

function mapStateToProps({ getProvider }) {
  const answerProvider = getProvider('snippetComposition');
  const answerLinksProvider = getProvider('snippetLinks');

  return {
    answer: answerProvider.get(),
    answerLinks: answerLinksProvider.get(),
    answerLinksImm: answerLinksProvider.immutableStore.binding.get(),
    isAnswerLinksStoreLoading: answerLinksProvider.isLoading(),
  };
}

export function useAnswerLinks() {
  return useContext(AnswerLinkContext);
}
