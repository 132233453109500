import DtoConverter from 'scripts/application/dto_converters/agent_assistance_config_converter';
import qconsole from 'scripts/lib/qconsole';

export default class AgentAssistanceConfigGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleEntity(dto) {
    this.context.stores.agentAssistanceConfig.set(DtoConverter.fromDto(dto));
  }

  handleRequestError(errorDto) {
    qconsole.log(`Unexpected error requesting agent assistance configuration ${JSON.stringify(errorDto)}`);
  }
}
