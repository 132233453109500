import _ from 'lodash';

import AnswerModal from 'components/modals/answer_modal';
import connect from 'components/lib/connect';
import KnowledgeBaseDecorator from 'components/customer/composition/lib/knowledge_base_decorator';
import createAnswerLinksWithStatus from 'components/customer/composition/lib/answer/create_answer_link_with_status';

function mapStateToProps({ getProvider }, props) {
  const history = _.get(getProvider('answerPanelState').get(), 'history');
  const answer = getAnswer();
  const isSnippetLinksStoreLoading = getProvider('snippetLinks').isLoading();
  let answerLinks = getProvider('snippetLinks').findAll();
  answerLinks = createAnswerLinksWithStatus(answer, answerLinks, isSnippetLinksStoreLoading);

  return {
    answer,
    answerLinks,
    history,
    variables: props.variables,
    isEditingSnippets: getProvider('modal').get().isEditingSnippets,
  };

  function getAnswer() {
    let answerPreview = getProvider('modal').get();
    if (answerPreview.composition) {
      return answerPreview.composition;
    }

    let snippetView = getProvider('snippetView').get();
    if (_.get(snippetView, 'id') === _.get(answerPreview, 'answerId')) {
      return snippetView;
    }
    return null;
  }
}

function mapExecuteToProps(executeAction, props) {
  return {
    onClose: props.onClose,
  };
}

export const AnswerModalContainerBase = connect(mapStateToProps, mapExecuteToProps)(AnswerModal);
export default KnowledgeBaseDecorator(AnswerModalContainerBase, {
  variableResolver: variable => variable.name,
  useVariables: true,
});
