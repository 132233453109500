import React, { useCallback } from 'react';

import { Bold, Button, Card } from './shared_toast';
import connect from 'components/lib/connect';
import NavigateToTaskComment from 'actions/task/navigate_to_task_comment';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function AgentMentionedInTaskCommentToast({ agentName, toast }) {
  const executeAction = useExecuteAction();

  const { customerId, taskId, taskCommentId } = toast.content;

  const navigateToMention = useCallback(() => {
    executeAction(NavigateToTaskComment, {
      commentId: taskCommentId,
      customerId,
      taskId,
    });
  }, [customerId, taskId, taskCommentId]);
  const action = (
    <Button id={toast.id} onClick={navigateToMention}>
      Go to comment
    </Button>
  );

  return (
    <Card action={action} toast={toast}>
      <Bold>{agentName}</Bold> mentioned <Bold>you</Bold> in a task comment
    </Card>
  );
}

const AgentMentionedInTaskCommentToastContainer = connect(mapStateToProps)(AgentMentionedInTaskCommentToast);

function mapStateToProps({ getProvider }, { toast }) {
  const { agentId } = toast.content;

  const agent = getProvider('agents').findBy({ id: agentId });
  let agentName = 'An agent';
  if (agent) {
    agentName = agent.getDisplayName();
  }

  return {
    agentName,
  };
}

export default AgentMentionedInTaskCommentToastContainer;
