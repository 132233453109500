import _ from 'lodash';
import moment from 'moment';

import { AggregationLevel } from 'models/reports/report_configs';
import createLocationModel, { prop } from './lib/create_location_model';
import { isEmbeddedReport } from 'models/reports/report';
import ReportChannel from 'models/reports/report_channel';

export const AGGREGATION_LABELS = {
  [AggregationLevel.HALF_HOUR]: 'Half Hourly',
  [AggregationLevel.DAY]: 'Daily',
  [AggregationLevel.WEEK]: 'Weekly',
  [AggregationLevel.MONTH]: 'Monthly',
  [AggregationLevel.QUARTER]: 'Quarterly',
};

export const getDatesInRange = (startDate, endDate, aggregationLevel) => {
  let timeUnit = Reports.getUnitsForAggregation(aggregationLevel);
  let startAt = Reports.getDateAfterAggregation(startDate, aggregationLevel);
  let endAt = Reports.getDateAfterAggregation(endDate, aggregationLevel);

  let numDays = endAt.diff(startAt, timeUnit);

  if (numDays < 0) {
    return [];
  }

  return [startAt.format('YYYY-MM-DD')].concat(
    _.times(numDays, () => {
      return startAt.add(1, timeUnit).format('YYYY-MM-DD');
    })
  );
};

export const DEFAULT_REPORT_SLUG = 'daily-trends';
export const DATE_FORMAT = 'YYYY-MM-DD';

const Reports = createLocationModel({
  modelName: 'Reports',
  properties: {
    slug: prop().default(DEFAULT_REPORT_SLUG),
    routingGroupId: prop(String),
    routingGroupIds: prop([String]),
    teamIds: prop([String]),
    topicIds: prop([String]),
    startAt: prop(String),
    endAt: prop(String),
    aggregationLevel: prop().oneOf(..._.values(AggregationLevel)),
    channel: prop().oneOf(..._.values(ReportChannel)),
    timezone: prop(String).default(''),
    endpointNumber: prop(String),
  },

  deriveNew(newFilters) {
    return Reports.create(_.assign({}, this, newFilters));
  },

  hasDateRange() {
    return this.startAt && this.endAt;
  },

  getStartAt() {
    return (
      this.startAt ||
      moment()
        .subtract(1, 'week')
        .format(DATE_FORMAT)
    );
  },

  getEndAt() {
    return this.endAt || moment().format(DATE_FORMAT);
  },

  getRoutingGroupIds() {
    return this.routingGroupIds || [];
  },

  getTeamIds() {
    return this.teamIds || [];
  },

  getTopicIds() {
    return this.topicIds || [];
  },

  getAggregationLevel(allowedAggregations) {
    return (
      this.aggregationLevel ||
      Reports.getDefaultAggregationLevelForDateRange({
        startDate: this.getStartAt(),
        endDate: this.getEndAt(),
        allowedAggregations,
      })
    );
  },

  getValidAggregationLevel(allowedAggregations) {
    return Reports.getValidAggregationLevelForReport({
      allowedAggregations,
      level: this.getAggregationLevel(allowedAggregations),
      slug: this.getSlug(),
      startDate: this.getStartAt(),
      endDate: this.getEndAt(),
      channel: this.getChannel(),
      inboxes: this.getRoutingGroupIds(),
    });
  },

  getChannel() {
    return this.channel;
  },

  getSlug() {
    return this.slug;
  },

  getTimezone() {
    return this.timezone;
  },

  getEndpointNumber() {
    return this.endpointNumber;
  },

  getFilterParams(allowedAggregations) {
    return {
      routingGroupIds: this.routingGroupIds,
      teamIds: this.teamIds,
      topicIds: this.topicIds,
      startAt: this.startAt,
      endAt: this.endAt,
      channel: this.channel,
      aggregationLevel: this.getAggregationLevel(allowedAggregations),
      endpointNumber: this.endpointNumber,
    };
  },

  isEmbeddedReport() {
    return isEmbeddedReport(this.getSlug());
  },

  addRoutingGroupId(routingGroupId) {
    this.routingGroupIds.push(routingGroupId);
  },

  setSlug(slug) {
    this.slug = slug;
  },

  setRoutingGroupId(routingGroupId) {
    this.routingGroupId = routingGroupId;
  },

  setRoutingGroupIds(routingGroupIds) {
    this.routingGroupIds = routingGroupIds;
  },

  setTeamIds(teamIds) {
    this.teamIds = teamIds;
  },

  setTopicIds(topicIds) {
    this.topicIds = topicIds;
  },

  setAggregationLevel(level) {
    if (Reports.isAggregationLevelValid(level)) {
      this.aggregationLevel = level;
    }
  },

  setChannel(channel) {
    if (channel && channel in ReportChannel) {
      this.channel = channel;
    }
  },

  setDateRange({ startAt, endAt }) {
    let start = moment(startAt, DATE_FORMAT);
    let end = moment(endAt, DATE_FORMAT);

    if (start.isValid() && end.isValid()) {
      if (start.isAfter(end)) {
        [end, start] = [start, end];
      }

      this.startAt = start.format(DATE_FORMAT);
      this.endAt = end.format(DATE_FORMAT);
    } else {
      this.startAt = moment()
        .subtract(1, 'week')
        .format(DATE_FORMAT);
      this.endAt = moment().format(DATE_FORMAT);
    }
  },

  setTimezone(timezone) {
    this.timezone = timezone;
  },

  setEndpointNumber(endpointNumber) {
    this.endpointNumber = endpointNumber;
  },

  hasFilters() {
    return !!(
      this.aggregationLevel ||
      this.channel ||
      this.endAt ||
      this.endpointNumber ||
      this.routingGroupIds ||
      this.startAt ||
      this.teamIds ||
      this.topicIds
    );
  },

  statics: {
    breadcrumb: 'Reports',
    defaultSlug: DEFAULT_REPORT_SLUG,

    isDateRangeValid({ startDate, endDate }) {
      return moment(endDate).diff(startDate, 'days') <= 731;
    },

    isAggregationLevelValid(level) {
      return _(AggregationLevel)
        .values()
        .includes(level);
    },

    isAggregationLevelValidForReport({ level, startDate, endDate, allowedAggregations }) {
      const aggregationOptions = Reports.getAggregationOptions({ start: startDate, end: endDate, allowedAggregations });
      return _.includes(aggregationOptions, level);
    },

    getDefaultAggregationLevelForDateRange({ startDate, endDate, allowedAggregations }) {
      let aggregationOptions = Reports.getAggregationOptions({ start: startDate, end: endDate, allowedAggregations });
      return _.last(aggregationOptions);
    },

    getAggregationOptions({ start, end, allowedAggregations }) {
      let aggregationOptions = [];

      if (!allowedAggregations) {
        return aggregationOptions;
      }

      let length = moment(end).diff(start, 'days');

      if (_.includes(allowedAggregations, AggregationLevel.HALF_HOUR) && length < 32) {
        aggregationOptions.push(AggregationLevel.HALF_HOUR);
      }

      if (_.includes(allowedAggregations, AggregationLevel.DAY) && length < 100) {
        aggregationOptions.push(AggregationLevel.DAY);
      }

      if (_.includes(allowedAggregations, AggregationLevel.WEEK) && length > 10 && length < 700) {
        aggregationOptions.push(AggregationLevel.WEEK);
      }

      if (_.includes(allowedAggregations, AggregationLevel.MONTH) && length > 45 && length < 3000) {
        aggregationOptions.push(AggregationLevel.MONTH);
      }

      if (_.includes(allowedAggregations, AggregationLevel.QUARTER) && length > 120) {
        aggregationOptions.push(AggregationLevel.QUARTER);
      }

      return aggregationOptions;
    },

    getValidAggregationLevelForReport({ slug, allowedAggregations = [], level, startDate, endDate, channel, inboxes }) {
      if (!_.isEmpty(allowedAggregations)) {
        // time aggregation is allowed for this report
        if (
          // check if the level given is valid for this report
          // (this also checks if the level is a valid option at all)
          Reports.isAggregationLevelValidForReport({ level, startDate, endDate, allowedAggregations })
        ) {
          return level;
        }

        return Reports.getDefaultAggregationLevelForDateRange({ startDate, endDate, allowedAggregations });
      }
    },

    getUnitsForAggregation(aggregationLevel) {
      // used by momentjs calculations
      switch (aggregationLevel) {
        case AggregationLevel.DAY:
          return 'days';
        case AggregationLevel.WEEK:
          return 'weeks';
        case AggregationLevel.MONTH:
          return 'months';
        case AggregationLevel.QUARTER:
          return 'quarters';
        default:
          return 'days';
      }
    },

    getDateAfterAggregation(date, aggregationLevel) {
      let timeUnit = Reports.getUnitsForAggregation(aggregationLevel);
      // isoWeek starts on Monday, which is needed, but moment does not have
      // a plural form 'isoweeks' for the unit to be used in calculations
      return moment(date).startOf(timeUnit === 'weeks' ? 'isoWeek' : timeUnit);
    },

    create({
      slug,
      aggregationLevel,
      channel,
      routingGroupId,
      routingGroupIds,
      teamIds,
      topicIds,
      timezone,
      startAt,
      endAt,
      endpointNumber,
    } = {}) {
      let reports = new this();

      if (slug) {
        reports.setSlug(slug);
      }

      if (channel) {
        reports.setChannel(channel);
      }

      if (startAt && endAt) {
        reports.setDateRange({ startAt, endAt });
      }

      if (aggregationLevel) {
        reports.setAggregationLevel(aggregationLevel);
      }

      if (routingGroupId) {
        reports.setRoutingGroupId(routingGroupId);
      }

      if (!_.isEmpty(routingGroupIds)) {
        reports.setRoutingGroupIds(routingGroupIds);
      }

      if (!_.isEmpty(teamIds)) {
        reports.setTeamIds(teamIds);
      }

      if (!_.isEmpty(topicIds)) {
        reports.setTopicIds(topicIds);
      }

      if (timezone) {
        reports.setTimezone(timezone);
      }

      if (endpointNumber) {
        reports.setEndpointNumber(endpointNumber);
      }

      return reports;
    },
  },
});

export default Reports;
