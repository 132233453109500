import createModel, { prop } from './lib/create_model';
const MessageContentTypeText = createModel({
  modelName: 'MessageContentTypeText',
  properties: {
    body: prop(String),
    bodyHtml: prop(String),
    buttons: prop(Object).default([]),
    hasRedactedPaymentCardNumber: prop(Boolean).default(false),
  },

  getBody() {
    return this.body || '';
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default MessageContentTypeText;
