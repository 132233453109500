import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import CircleXIcon from 'components/lib/icons/circle_x_icon';

export default function EditorErrors() {
  const { compositionErrors } = useContext(ComposerContext);

  if (!compositionErrors.length) {
    return null;
  }

  return (
    <ErrorWrapper>
      <Error data-aid="composerError">
        <StyledCircleXIcon /> {_.upperFirst(compositionErrors[0].detail)}
      </Error>
    </ErrorWrapper>
  );
}

EditorErrors.propTypes = {
  errors: PropTypes.array,
};

const Error = styled.div`
  align-items: center;
  background-color: #ffcdd2;
  border-radius: 8px;
  bottom: -8px;
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  left: 0;
  padding: 4px 8px;
  position: relative;
  right: 0;
`;

const ErrorWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const StyledCircleXIcon = styled(CircleXIcon)`
  height: 16px;
  width: 16px;
  margin-right: 4px;

  g {
    stroke: ${p => p.theme.colors.red500};
  }
`;
