import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import createTooLongError from '../factories/error/create_too_long_error';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const Type = createEnum(
  'AGENT_ID',
  'AGENT_EMPLOYEE_ID',
  'AGENT_FIRST_NAME',
  'AGENT_NAME',
  'AGENT_PHONE',
  'AGENT_TWITTER',
  'COMPANY_NAME',
  'CUSTOMER_ID',
  'CUSTOMER_ADDRESS',
  'CUSTOMER_EMAIL',
  'CUSTOMER_FIRST_NAME',
  'CUSTOMER_LAST_NAME',
  'CUSTOMER_NAME',
  'CUSTOMER_PHONE',
  'CUSTOMER_TWITTER'
);

const KbVariable = createModel({
  modelName: 'KbVariable',

  properties: {
    id: prop(String).isRequired,
    type: prop(String).isRequired,
    name: String,
    defaultValue: String,
  },

  setDefaultValue(val) {
    this.defaultValue = val;
  },

  update(attrs) {
    _.merge(this, _.omit(attrs, 'id'));
  },

  isAgent() {
    return /^AGENT_/.test(this.type);
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },

    getValidationErrors,

    MAX_LENGTH: 500,

    Type,

    Resolver: {
      AGENT_ID: details => {
        return details.agent.id;
      },
      AGENT_EMPLOYEE_ID: details => {
        return details.agent.employeeId;
      },
      AGENT_FIRST_NAME: details => {
        const preferredName = details.agent.externalName || details.agent.name;
        return getFirstName(preferredName);
      },
      AGENT_NAME: details => {
        const preferredName = details.agent.externalName || details.agent.name;
        return getTrimmedName(preferredName);
      },
      AGENT_PHONE: details => {
        return details.agent.getPhoneNumber();
      },
      AGENT_TWITTER: details => {
        return details.agent.twitter;
      },
      COMPANY_NAME: details => {
        return details.company.name;
      },
      CUSTOMER_ID: details => {
        return details.customer.id;
      },
      CUSTOMER_ADDRESS: details => {
        return details.customer.address ? details.customer.address.replace(/\n/g, ', ') : '';
      },
      CUSTOMER_EMAIL: details => {
        return details.customer.getDefaultEmail();
      },
      CUSTOMER_FIRST_NAME: details => {
        return getFirstName(details.customer.name);
      },
      CUSTOMER_LAST_NAME: details => {
        return getLastName(details.customer.name);
      },
      CUSTOMER_NAME: details => {
        return getTrimmedName(details.customer.name);
      },
      CUSTOMER_PHONE: details => {
        return details.customer.getPrimaryPhoneNumberForDisplay();
      },
      CUSTOMER_TWITTER: details => {
        return _.get(details.customer.getDefaultTwitterAccount(), 'displayName', '');
      },
    },
  },
});

export function getTrimmedName(name) {
  return name ? name.trim().replace(/\s+/g, ' ') : null;
}

export function getFirstName(fullName) {
  if (!fullName) {
    return null;
  }
  const trimmedName = getTrimmedName(fullName);
  const firstName = trimmedName.split(' ')[0];
  return firstName !== '' ? firstName : null;
}

export function getLastName(fullName) {
  if (!fullName) {
    return null;
  }
  const trimmedName = getTrimmedName(fullName);
  const names = trimmedName.split(' ');
  const lastName = names[names.length - 1];
  return lastName !== '' ? lastName : null;
}

export function resolveVariables(variables, { agent, customerProfile }) {
  return _.reduce(
    variables,
    (draft, variable) => {
      const resolvedValue = resolve(variable.type, { agent, customerProfile }) || variable.defaultValue || '';
      draft[variable.id] = {
        isEmpty: !resolvedValue,
        text: resolvedValue || variable.name,
        type: variable.type,
      };
      return draft;
    },
    {}
  );
}

function resolve(type, { agent, customerProfile }) {
  switch (type) {
    case Type.AGENT_ID:
      return agent.id;
    case Type.AGENT_EMPLOYEE_ID:
      return agent.employeeId;
    case Type.AGENT_FIRST_NAME:
      return getFirstName(agent.externalName || agent.name) || '';
    case Type.AGENT_NAME:
      return agent.externalName || agent.name || '';
    case Type.AGENT_PHONE:
      return agent.getPhoneNumber();
    case Type.AGENT_TWITTER:
      return agent.twitter;
    case Type.CUSTOMER_ID:
      return customerProfile ? customerProfile.id : '';
    case Type.CUSTOMER_ADDRESS:
      return _.get(customerProfile, 'address', '').replace(/\n/g, ', ');
    case Type.CUSTOMER_EMAIL:
      return customerProfile ? customerProfile.getDefaultEmail() : '';
    case Type.CUSTOMER_FIRST_NAME:
      return customerProfile ? getFirstName(customerProfile.name) : '';
    case Type.CUSTOMER_LAST_NAME:
      return customerProfile ? getLastName(customerProfile.name) : '';
    case Type.CUSTOMER_NAME:
      return customerProfile ? getTrimmedName(customerProfile.name) : '';
    case Type.CUSTOMER_PHONE:
      return customerProfile ? customerProfile.getPrimaryPhoneNumberForDisplay() : '';
    case Type.CUSTOMER_TWITTER:
      return customerProfile ? _.get(customerProfile.getDefaultTwitterAccount(), 'displayName', '') : '';
    default:
      return '';
  }
}

function getValidationErrors(props) {
  const errors = [];

  ['defaultValue'].forEach(function(field) {
    if (props[field] && props[field].length > KbVariable.MAX_LENGTH) {
      errors.push(createTooLongError(field, `${KbVariable.MAX_LENGTH} characters`, 'default value'));
    }
  });
  return errors;
}

export const VARIABLE = 'variable';
export default KbVariable;
