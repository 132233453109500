export default class FollowTask {
  constructor(context) {
    this.context = context;
  }

  run({ itemId }) {
    let agentId = this.context.stores.currentAgent.get().id;

    let taskInfo = this.context.stores.taskInfo.findBy({ id: itemId });
    let followerToRemove = taskInfo.removeFollower({ agentId, itemId });
    this.context.stores.taskInfo.setPending(taskInfo);

    this.context.gateways.taskFollowers.delete({ taskId: itemId, followerId: followerToRemove && followerToRemove.id });
    this.context.analytics.track('Task Unfollowed', {
      followerId: agentId,
      isManual: true,
      itemId,
    });
  }
}
