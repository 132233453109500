import { get, reduce } from 'lodash';
import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseCurrentComposition from 'actions/composition/close_current_composition';
import Composer, { Header, HeaderText } from 'components/composer/shared/composer';
import ComposerContext from 'components/composer/contexts/composer_context';
import connect from 'components/lib/connect';
import ExternalActionForm, { FormInputSectionTypes, FormSectionTypes } from 'models/external_action_form';
import PerformAction from 'actions/external_customer_lookup/perform_action';
import Section from 'components/composer/external_form/external_form_section';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function ExternalFormComposer({ externalActionForm, errors, isWaitingOnAction }) {
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();

  const sections = get(externalActionForm, 'sections', []);

  const [formData, setFormData] = useFormDataState(sections);

  const onCancel = useCallback(() => executeAction(CloseCurrentComposition), []);
  const onSubmit = useCallback(
    () =>
      executeAction(PerformAction, {
        customerId: composition.customerId,
        action: {
          actionUrl: get(externalActionForm, 'actionUrl'),
          ...formData,
        },
      }),
    [formData]
  );
  const onChange = newValues => {
    setFormData({ ...formData, ...newValues });
  };

  return (
    <Composer
      cancelText={get(externalActionForm, 'closeButton', null)}
      disableSubmit={isWaitingOnAction}
      hideCancel={isWaitingOnAction}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitText={get(externalActionForm, 'submitButton', 'Submit')}
    >
      <Header>
        <HeaderText color="green400">{get(externalActionForm, 'title')}</HeaderText>
      </Header>
      <StyledFormContainer>
        {sections.length > 0 && sections.map((section, idx) => renderSection(section, idx))}
      </StyledFormContainer>
    </Composer>
  );

  function renderSection(section, id) {
    const error = errors.find(e => e.attr === section.attr);
    return (
      <Section
        error={error ? error.detail : null}
        key={`section-${id}`}
        section={section}
        updateState={onChange}
        value={get(formData, section.attr, null)}
      />
    );
  }
}

function useFormDataState(sections) {
  return useState(() => {
    return reduce(
      sections,
      (result, section) => {
        if (section.type === FormSectionTypes.input) {
          result[section.attr] =
            section.defaultValue || (section.input.type === FormInputSectionTypes.checkbox ? false : '');
        }
        return result;
      },
      {}
    );
  });
}

const StyledFormContainer = styled.div`
  margin-left: ${p => p.theme.spacing.large};
  position: relative;
  overflow-y: auto;
`;

ExternalFormComposer.propTypes = {
  errors: PropTypes.array,
  externalActionForm: PropTypes.instanceOf(ExternalActionForm).isRequired,
  isWaitingOnAction: PropTypes.bool,
};

function mapStateToProps({ getProvider }) {
  const externalActionForm = getProvider('externalActionForm').get();
  const isWaitingOnAction = getProvider('performExternalAction').isLoading();
  const errors = getProvider('externalActionForm').getErrors();

  return {
    externalActionForm,
    isWaitingOnAction,
    errors,
  };
}

export default connect(mapStateToProps)(ExternalFormComposer);
