import _ from 'lodash';

import connect from 'components/lib/connect';
import TopicsHeader from './topics_header';

export default connect(mapStateToProps)(TopicsHeader);

function mapStateToProps(context, props) {
  let liveboard = context.getProvider('liveboard').get();

  let inboxIds = props.routingGroupIds;
  let title;
  if (inboxIds && inboxIds.length) {
    let groups = _.compact(
      inboxIds.map(id => {
        let group = context.getProvider('routingGroups').findBy({ id });
        return _.get(group, 'name');
      })
    );
    if (groups && groups.length) {
      title = groups.join(', ');
    }
  }

  return {
    includeLinks: context.isFeatureEnabled('internalAgentActions'),
    liveboard,
    routingGroupIds: inboxIds,
    title,
  };
}
