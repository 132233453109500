import Immutable from 'immutable';
import Conversation from 'models/conversation';

export default {
  fromImmutable(immutableMap, conversationItems) {
    return Conversation.fromJs(immutableMap.toJS(), conversationItems);
  },

  toImmutable(entity) {
    return Immutable.fromJS(entity && entity.toJs());
  },
};
