import _ from 'lodash';

import Assignee from 'models/assignee';
import createModel, { prop } from './lib/create_model';
import DetailClasses, { detailClassFromItemType } from 'models/routing_item/detail_classes';
import RoutingItemType from 'models/routing_item_type';

const RoutingItem = createModel({
  modelName: 'RoutingItem',

  properties: {
    assignee: prop(Assignee).isRequired,
    assigneeWas: prop(Assignee),
    detail: prop().oneOf(
      ..._(DetailClasses)
        .map(1)
        .uniq()
        .value()
    ),
    itemType: prop().oneOf(..._.values(RoutingItemType)).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    overrideFromJs(fromJs) {
      return attrs => {
        // work around Supernova sending invalid Assignee (an empty object) for assigneeWas
        if (_.isEmpty(attrs.assigneeWas)) {
          attrs = _.omit(attrs, 'assigneeWas');
        }

        if (attrs.detail) {
          let detailClass = detailClassFromItemType(attrs.itemType);
          if (detailClass) {
            attrs = _.assign({}, attrs, { detail: detailClass.fromJs(attrs.detail) });
          } else {
            attrs = _.omit(attrs, 'detail');
          }
        }

        return fromJs(attrs);
      };
    },
  },
});

export default RoutingItem;
