import _ from 'lodash';

import Inbox from 'models/location/inbox';
import NavigateTo from 'actions/current_location/navigate_to';

export default class OpenFirstTeamInbox {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (!this.context.stores.appFeatures.get().isEnabled('groupInboxes')) {
      return;
    }

    const currentAgentId = this.context.stores.currentAgent.get().id;
    const routingGroups = this.context.stores.routingGroups.findAll();
    const enabledRoutingGroups = _.filter(routingGroups, group => !group.disabled);
    const sortedRoutingGroups = _.sortBy(enabledRoutingGroups, group => group.name.toLowerCase());
    const firstTeamRoutingGroup = sortedRoutingGroups.filter(group => {
      return group.agentIds.indexOf(currentAgentId) > -1 && !group.disabled;
    })[0];
    let routingGroupId;
    if (firstTeamRoutingGroup) {
      routingGroupId = firstTeamRoutingGroup.id;
    } else if (sortedRoutingGroups.length) {
      routingGroupId = sortedRoutingGroups[0].id;
    }

    if (!routingGroupId) {
      return;
    }

    const inboxLocation = Inbox.create({ conversationStatus: null, isTeam: true, routingGroupId });
    this.context.executeAction(NavigateTo, inboxLocation);
  }
}
