import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Facebook Messenger Stroke
 */

export default function FacebookMessengerStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M1.5 7.5C1.5 4.22054 4.37461 1.5 8 1.5C11.6254 1.5 14.5 4.22054 14.5 7.5C14.5 10.7795 11.6254 13.5 8 13.5C7.20904 13.5 6.45241 13.3697 5.75317 13.1319C5.60607 13.0818 5.44407 13.103 5.3148 13.1892L4 14.0657V12.4791C4 12.3251 3.929 12.1796 3.80754 12.0849C2.38868 10.978 1.5 9.33027 1.5 7.5ZM8 0.5C3.89341 0.5 0.5 3.59976 0.5 7.5C0.5 9.58108 1.47225 11.4419 3 12.718V15C3 15.1844 3.10149 15.3538 3.26407 15.4408C3.42665 15.5278 3.62392 15.5183 3.77735 15.416L5.66863 14.1552C6.40327 14.3792 7.18707 14.5 8 14.5C12.1066 14.5 15.5 11.4002 15.5 7.5C15.5 3.59976 12.1066 0.5 8 0.5ZM3.89205 9.05194L6.92961 5.58044C6.96771 5.53691 7.03468 5.53468 7.07558 5.57558L8.94599 7.44599C8.97788 7.47788 9.02716 7.48448 9.06632 7.46211L12.0611 5.7508C12.1562 5.69645 12.2569 5.81255 12.1897 5.89901L9.07783 9.89993C9.03814 9.95096 8.96119 9.95149 8.92081 9.90101L7.05343 7.56679C7.02267 7.52834 6.96849 7.51801 6.92573 7.54244L4.01692 9.20461C3.91898 9.26058 3.81777 9.13684 3.89205 9.05194Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
