import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React, { Suspense } from 'react';

import { createGetProvider } from './configure_providers';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import Root from 'components/root';

export default function configureAppBootstrap({ moreartyCtx, runnerContext, sharedBindings }) {
  let AppBootstrap = createReactClass({
    UNSAFE_componentWillMount() {
      moreartyCtx.init(this);
    },

    childContextTypes: {
      morearty: Morearty.Mixin.contextTypes.morearty,
      executeAction() {},
      getProvider() {},
      sharedBindings() {},
    },

    getChildContext() {
      return {
        morearty: moreartyCtx,
        executeAction,
        getProvider: createGetProvider(runnerContext.providers),
        sharedBindings,
      };
    },

    render() {
      return (
        <Suspense fallback={<div />}>
          <Root binding={moreartyCtx.getBinding()} />
        </Suspense>
      );
    },
  });

  return React.createElement(AppBootstrap);

  function executeAction(...args) {
    try {
      runnerContext.executeAction(...args);
    } catch (e) {
      // to prevent render errors if executeAction is called from component lifecycle
      ErrorReporter.reportError(e);
    }
  }
}
