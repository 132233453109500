import { createQuotedEmailTextPlain, createQuotedEmailTextHtml } from './lib/create_quoted_email_text';
import CreateEmailComposition from './create_email_composition';
import EmailCompositionContent from 'models/composition/email_composition_content';

export default class ForwardEmail extends CreateEmailComposition {
  constructor(context) {
    super(context, EmailCompositionContent);
  }

  _allowDuplicate() {
    return true;
  }

  _createComposition({ content, conversationId, item }) {
    return super._createComposition({
      attachments: item.content.attachments,
      content,
      conversationId,
    });
  }

  _createContent({ item, conversation }) {
    let quotedPlain = createQuotedEmailTextPlain(item);
    let quotedHtml = createQuotedEmailTextHtml(item);

    let froms = this._getPotentialFroms(conversation);
    return new EmailCompositionContent({
      from: this._getReplyFrom(item, froms),
      to: '',
      subjectHtml: getForwardSubject(item),
      quotedPlain,
      quotedHtml,
      isForward: true,
      inlineAttachments: item.content.inlineAttachments(),
    });
  }
}

function getForwardSubject(emailItem) {
  let subject = emailItem.content.subject;
  let regex = new RegExp(`^${FWD_PREFIX}`, 'i');
  if (!subject.match(regex)) {
    subject = FWD_PREFIX + subject;
  }
  return subject;
}

const FWD_PREFIX = 'Fwd: ';
