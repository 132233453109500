import CheckVersion from 'actions/version/check_version';

export default function(context) {
  startPeriodicVersionCheck(context);
}

function startPeriodicVersionCheck(context) {
  context.scheduler.executeEvery(VERSION_CHECK_INTERVAL, CheckVersion.jobId, CheckVersion);
}

const VERSION_CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes
