import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

export const ToastCategory = createEnum('ACTIVITY_BANNER', 'TOAST');
export const ToastType = createEnum('GENERAL_ACTIVITY', 'ERROR', 'INFO', 'SUCCESS', 'WARN');

const Toast = createModel({
  modelName: 'ToastDeprecated',
  properties: {
    id: prop(String),
    category: prop(String).oneOf(..._.values(ToastCategory)),
    message: prop(String),
    link: prop(String),
    persistent: prop(Boolean), // This property controls whether the toast will be removed automatically on timeout (true) or it has to be removed manually (false)
    type: prop(String).oneOf(..._.values(ToastType)),
    visible: prop(Boolean), // Internal property, controls toast visibility
  },

  statics: {
    create(attrs) {
      return new this(
        _.merge({ id: IdGenerator.newId(), category: ToastCategory.TOAST, persistent: false, visible: true }, attrs)
      );
    },

    createFrom(otherToast, overrides = {}) {
      const finalAttrs = _.merge(
        {
          id: otherToast.id,
          category: otherToast.category,
          message: otherToast.message,
          link: otherToast.link,
          persistent: otherToast.persistent,
          type: otherToast.type,
          visible: otherToast.visible,
        },
        overrides
      );
      return new this(finalAttrs);
    },
  },
});

export default Toast;
