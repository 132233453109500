import UserCredential from 'models/user_credential';
import UserCredentialType from 'models/user_credential_type';

export default class ActivateUser {
  constructor(context) {
    this.context = context;
  }

  run({ id, name, password }) {
    this.context.stores.userCredential.setPending(UserCredential.create({ type: UserCredentialType.ACTIVATION_TOKEN }));
    this.context.gateways.userPassword.update(id, { password: password.trim() });
    this.context.gateways.agentProfile.update({ agentId: id }, { disabledAt: null, name });
  }
}
