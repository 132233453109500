import _ from 'lodash';

export default class SnippetService {
  constructor(pubsub, getDatabase) {
    this.pubsub = pubsub;
    this.getDatabase = getDatabase;
  }

  fetch(orgId, { correlationId, payload }) {
    let snippets = this.getDatabase(orgId).snippets;

    if (!payload) {
      return snippets;
    }
    return _.filter(snippets, snippet => _.includes(payload.ids, snippet.id));
  }

  add(requestorId, orgId, { correlationId, payload }) {
    this.getDatabase(orgId).snippets.push(payload);
    this.publish(`v1/requestor/${requestorId}/orgs/${orgId}/snippets/command/add/success`, {});
    this.publish(`v1/requestor/${requestorId}/orgs/${orgId}/snippets`, {
      correlationId,
      status: 'success',
    });

    this.publish(`v1/orgs/${orgId}/snippets/${payload.id}`, { payload });
  }

  update(orgId, snippetId, { correlationId, payload }) {
    let snippet = _.find(this.getDatabase(orgId).snippets, { id: snippetId });
    _.assign(snippet, payload);
    this.publish(`v1/orgs/${orgId}/snippets/${snippetId}`, { payload: snippet });
  }

  delete(orgId, snippetId) {
    let rulesWithSnippet = _.filter(this.getDatabase(orgId).rules, rule => {
      return rule.actions && rule.actions.sendReply && rule.actions.sendReply.snippetId === snippetId;
    });
    if (rulesWithSnippet.length > 0) {
      let errors = rulesWithSnippet.map(rule => {
        return { code: 'in_use', detail: `used in rule [${rule.name}] with id [${rule.id}]` };
      });
      return { errors };
    } else {
      _.remove(this.getDatabase(orgId).snippets, { id: snippetId });
      this.publish(`v1/orgs/${orgId}/snippets/${snippetId}/event/delete`, {});
      return null;
    }
  }

  publish(topic, message) {
    this.pubsub.publish(topic, message);
  }
}
