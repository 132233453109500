import { useCallback, useEffect, useState } from 'react';

function getSize() {
  return {
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  };
}

export default function useWindowSize() {
  const [{ windowHeight, windowWidth }, setWindowDimensions] = useState(getSize);

  const onWindowResize = useCallback(() => setWindowDimensions(getSize()), []);
  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  return { windowHeight, windowWidth };
}
