import IvrEndStateReport from 'models/reports/ivr_end_state_report';
import IvrSummaryReport from 'models/reports/ivr_summary_report';
import SummaryReport from 'models/reports/summary_report';
import TopicReport from 'models/reports/topic_report';
import { ReportType } from 'models/reports/report';

export default [
  [ReportType.SUMMARY, SummaryReport],
  [ReportType.TOPICS, TopicReport],
  [ReportType['IVR-END-STATES'], IvrEndStateReport],
  [ReportType['IVR-SUMMARY'], IvrSummaryReport],
];
