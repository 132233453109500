import changeComposition from 'actions/composition/lib/change_composition';
import CustomerView from 'models/location/customer_view';

class ActivateComposition {
  constructor(context) {
    this.context = context;
  }

  run({ composition, toggleMode = true }) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error(
        `cannot activate composition when current location is [${currentLocation && currentLocation.constructor.name}]`
      );
    }

    if (composition.id === currentLocation.currentCompositionId || currentLocation.isCompositionMinimized) {
      if (toggleMode || currentLocation.isCompositionMinimized) {
        currentLocation.toggleCompositionMinimized();
      }

      this.context.stores.currentLocation.set(currentLocation);
    }

    changeComposition(this.context, composition);
  }
}

export default ActivateComposition;
