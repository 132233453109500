import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import connect from 'components/lib/connect';
import CurrentAssignee from './current_assignee';
import SearchableAssignmentMenu from 'components/lib/assignment_menu/searchable_assignment_menu';
import WindowSizeWatcher from 'components/common/utilities/window_size_watcher';
import SearchResult from './search_result';
import { PortalledPopover } from 'components/common/lib/_popover';

export class SearchableTaskAssignment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };

    this.renderAssignmentRow = this.renderAssignmentRow.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  render() {
    const { agentName, className, groupName, isAssigneeCurrentAgent, isCurrentInboxDisabled } = this.props;
    const opener = this.opener;
    const classes = classnames('searchableTaskAssignment', className, {
      searchableAssignmentMenuVisible: this.state.isMenuOpen,
    });
    return (
      <div className={classes} onClick={this.onClick} ref={node => (this.opener = node)}>
        <WindowSizeWatcher>
          {({ windowHeight, windowWidth }) => (
            <PortalledPopover
              autoPosition
              bounds={{
                bottom: windowHeight - 68,
                right: windowWidth - 8,
                top: 68,
              }}
              className="taskAssigneeCard"
              data-aid="taskAssigneeCard"
              isVisible={this.state.isMenuOpen}
              onClickOutside={this.onClickOutside.bind(this)}
              position="top"
              targetElement={opener}
            >
              <SearchableAssignmentMenu
                className="searchableTaskAssignment-menu"
                currentAssignee={{
                  agentId: this.props.agentId,
                  groupId: this.props.routingGroupId,
                }}
                onBlur={this.handleToggleMenu}
                renderRow={this.renderAssignmentRow}
                showSelfAssignmentResultsByDefault
                title="Assign to"
              />
            </PortalledPopover>
          )}
        </WindowSizeWatcher>
        <CurrentAssignee
          agentName={agentName}
          className="searchableTaskAssignment-assignee"
          groupName={groupName}
          hideAvatarOrIcon
          isAssigneeCurrentAgent={isAssigneeCurrentAgent}
          isInboxDisabled={isCurrentInboxDisabled}
          onClick={this.handleToggleMenu}
          readOnly={this.props.readOnly}
          textLabel={this.props.label}
        />
      </div>
    );
  }

  onClickOutside() {
    this.setState({ isMenuOpen: false });
  }

  renderAssignmentRow(hit) {
    return (
      <SearchResult
        key={`${hit.routingGroupId}${_.get(hit, 'agentId', '')}`}
        onAgentSelect={this.onSelect}
        onInboxSelect={this.onSelect}
        {...hit}
      />
    );
  }

  onClick(evt) {
    evt.stopPropagation();
  }

  handleToggleMenu(evt) {
    evt.preventDefault();
    if (this.props.readOnly) return;
    this.setState(prevState => {
      return { isMenuOpen: !prevState.isMenuOpen };
    });
  }

  onSelect(selection) {
    this.props.onSelect(selection);
    this.setState({ isMenuOpen: false });
  }
}

SearchableTaskAssignment.propTypes = {
  agentId: PropTypes.string,
  agentName: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  routingGroupId: PropTypes.string,
  groupName: PropTypes.string,
  isAssigneeCurrentAgent: PropTypes.bool.isRequired,
  isCurrentInboxDisabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
};

function mapMenuStateToProps({ getProvider }, props) {
  const taskAssignee = getTaskAssignee(props.agentId, props.routingGroupId);
  const currentGroup = getProvider('routingGroups').findBy({ id: props.routingGroupId });

  return {
    agentName: taskAssignee.agentName,
    groupName: taskAssignee.groupName,
    isAssigneeCurrentAgent: taskAssignee.agentId === getCurrentAgentId(),
    isCurrentInboxDisabled: !!(currentGroup && currentGroup.disabled),
  };

  function getCurrentAgentId() {
    return getProvider('currentAgent').get().id;
  }

  function getTaskAssignee(agentId, routingGroupId) {
    let groupProvider = getProvider('routingGroups');
    let agentProvider = getProvider('agents');

    let assignee = { agentId };
    if (routingGroupId) {
      let group = groupProvider.findBy({ id: routingGroupId });
      assignee.groupName = group && group.name;
    }
    if (agentId) {
      let agent = agentProvider.findBy({ id: agentId });
      assignee.agentName = agent && agent.getDisplayName();
    }
    return assignee;
  }
}

const SearchableTaskAssignmentContainer = connect(mapMenuStateToProps)(SearchableTaskAssignment);

SearchableTaskAssignmentContainer.propTypes = {
  agentId: PropTypes.string,
  routingGroupId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SearchableTaskAssignmentContainer;
