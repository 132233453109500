import _ from 'lodash';

import CurrentCompositionUpdater from '../lib/current_composition_updater';
import DtoConverter from 'scripts/application/dto_converters/customer_profile_converter';
import Err from 'models/err';
import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';
import Inbox from 'models/location/inbox';
import NavigateToCustomerProfile from 'actions/customer/navigate_to_customer_profile';
import { preloadCustomer } from 'actions/conversation/lib/conversation_workflow';
import qconsole from 'scripts/lib/qconsole';
import requestCustomer from 'actions/customer/lib/request_customer';
import LookupCustomer from 'actions/external_customer_lookup/lookup_customer';
import RequestCustomerMatches from 'actions/customer_match/request_customer_matches';
import tryUpdateInboxItem from 'actions/inbox/lib/try_update_inbox_item';
import unloadCustomer from 'actions/customer/lib/unload_customer';

const onAddSuccessCallbacks = {};

export default class CustomerProfileGatewayObserver {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
  }

  get store() {
    return this.context.stores.customers;
  }

  handleEntity(payload) {
    if (this._isCustomerLoaded(payload.id)) {
      const lastNewPhone = _.get(_.last(payload.phones), 'normalized');
      if (lastNewPhone) {
        let profileStore = this.store.storesFor(payload.id).profile;
        const currentProfile = profileStore.get();

        if (currentProfile && lastNewPhone !== _.get(_.last(currentProfile.phones), 'normalized')) {
          this.context.executeAction(RequestCustomerMatches, payload);
        }
      }

      this._replaceProfile(payload);
    }
  }

  handleFetchSuccess(profileDto, customerId) {
    if (customerId !== profileDto.id) {
      this._handleMovedProfile(customerId, profileDto.id);
    }

    if (this._isCustomerLoaded(profileDto.id)) {
      const profile = this._replaceProfile(profileDto);
      this.store.storesFor(profile.id).profile.resetLoading();
      this.context.executeAction(LookupCustomer, profile.id);
      this.context.executeAction(RequestCustomerMatches, profile);
      this.currentCompositionUpdater.update();
    }
  }

  _handleMovedProfile(oldProfileId, newProfileId) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation.customerId === oldProfileId) {
      this.context.executeAction(NavigateToCustomerProfile, {
        conversationId: currentLocation.currentConversationId,
        customerId: newProfileId,
        itemId: currentLocation.currentConversationItemId,
      });
    }

    unloadCustomer(this.context, oldProfileId);
  }

  handleFetchError(profileId, errorDto) {
    const movedError = _.find(errorDto.errors, err => err.code === Err.Code.MOVED);
    if (movedError) {
      this._handleMovedProfile(profileId, movedError.meta.new);
    }

    const hasNotFoundErr = _.find(errorDto.errors, err => err.code === Err.Code.NOT_EXIST);
    if (this._isCustomerLoaded(profileId) && hasNotFoundErr) {
      let profileStore = this.store.storesFor(profileId).profile;
      profileStore.resetLoading();
      profileStore.remove();
    }
    qconsole.log(`Failed to fetch customer profile: [${JSON.stringify(errorDto)}]`);
  }

  handleDeleteProfile({ customerId }) {
    if (this._isCustomerLoaded(customerId)) {
      let profileStore = this.store.storesFor(customerId).profile;
      profileStore.remove(customerId);
    }
  }

  handleSetErrors(customerId, errorsDto) {
    if (this._isCustomerLoaded(customerId)) {
      let profileStore = this.store.storesFor(customerId).profile;
      if (profileStore.isPending()) {
        const errors = errorsDto.errors.map(ErrorDtoConverter.fromDto);
        const mergeableError = _.find(errors, err => err.code === Err.Code.TAKEN && err.meta.customerId);
        if (mergeableError) {
          preloadCustomer(this.context, mergeableError.meta.customerId);
        }
        profileStore.setErrors(errors);
        profileStore.resetPending();
      }
    }
  }

  handleSetSuccess(customerId) {
    if (this._isCustomerLoaded(customerId)) {
      let profileStore = this.store.storesFor(customerId).profile;
      profileStore.commitPending();
      this.context.executeAction(LookupCustomer, customerId);
    }
  }

  handleAddSuccess({ correlationId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    let customer = this.store.getPendingNew();
    let customerId = (customer || {}).id;
    if (customerId && this._isCustomerLoaded(customerId)) {
      this.store.resetPendingNew(); // only set to pending to retrieve profile store
      let profileStore = this.store.storesFor(customerId).profile;
      profileStore.commitPending();
      requestCustomer(this.context, customerId);
      if (currentLocation instanceof Inbox) {
        this.context.executeAction(NavigateToCustomerProfile, { customerId });
      }
    }

    if (correlationId && onAddSuccessCallbacks[correlationId]) {
      onAddSuccessCallbacks[correlationId]();
      delete onAddSuccessCallbacks[correlationId];
    }
  }

  handleAddErrors({ correlationId, errorsDto }) {
    let customer = this.store.getPendingNew();
    let customerId = (customer || {}).id;
    if (customerId && this._isCustomerLoaded(customerId)) {
      let profileStore = this.store.storesFor(customerId).profile;
      if (profileStore.isPending()) {
        profileStore.setErrors(errorsDto.errors.map(ErrorDtoConverter.fromDto));
        profileStore.resetPending();
        this.store.resetPending();
      }
      this.store.resetPendingNew();
      qconsole.log(`Failed to create customer profile ${JSON.stringify(errorsDto)}`);
    }

    if (onAddSuccessCallbacks[correlationId]) {
      delete onAddSuccessCallbacks[correlationId];
    }
  }

  _isCustomerLoaded(customerId) {
    return this.store.has({ id: customerId });
  }

  _replaceProfile(dto) {
    let receivedProfile = DtoConverter.fromDto(dto);

    let profileStore = this.store.storesFor(receivedProfile.id).profile;
    let existingProfile = profileStore.get();

    if (existingProfile && receivedProfile._version >= existingProfile._version) {
      profileStore.set(receivedProfile);
    }

    tryUpdateInboxItem(this.context, { profile: receivedProfile });
    return receivedProfile;
  }

  static onAddSuccess(correlationId, callback) {
    onAddSuccessCallbacks[correlationId] = callback;
  }
}
