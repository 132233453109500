import classnames from 'classnames';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import InboxIcon from '../icons/inbox_icon';
import ProfileAvatar from '../profile_avatar';
import Tooltip from 'components/common/tooltip';

export default class CurrentAssignee extends PureComponent {
  render() {
    const {
      agentName,
      avatarImage,
      className,
      groupName,
      hideAvatarOrIcon,
      isAssigneeCurrentAgent,
      isInboxDisabled,
      onClick,
      readOnly,
      textLabel,
    } = this.props;

    let assigneeText = (
      <div className={`${className}-text`}>
        <div className={`${className}-text-label`}>{textLabel}</div>
        <StyledNameWrapper>
          <StyledAssignee
            $isInvalid={isInboxDisabled}
            $readOnly={readOnly}
            className={classnames(`${className}-text-name`)}
          >
            {getAssignee({ agentName, groupName, isAssigneeCurrentAgent })}
          </StyledAssignee>
          {!readOnly && <StyledIcon $isInvalid={isInboxDisabled} icon={faChevronDown} />}
        </StyledNameWrapper>
      </div>
    );

    if (isInboxDisabled) {
      assigneeText = (
        <Tooltip message="Inbox is disabled. Please select another" position="left">
          {assigneeText}
        </Tooltip>
      );
    }

    return (
      <div className={className} onClick={onClick}>
        {hideAvatarOrIcon ? null : renderAvatarOrInboxIcon(groupName, agentName, avatarImage, className)}
        {assigneeText}
      </div>
    );
  }
}

const StyledNameWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const StyledAssignee = styled.div`
  color: ${p => (p.$isInvalid ? p.theme.colors.red400 : p.theme.colors.green400)};
  ${p => p.$readOnly && readOnlyAssigneeCss}
`;

const readOnlyAssigneeCss = css`
  color: ${p => p.theme.colors.black};
  cursor: default;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${p => (p.$isInvalid ? p.theme.colors.red400 : p.theme.colors.green400)};
  margin-left: 5px;
`;

CurrentAssignee.propTypes = {
  agentName: PropTypes.string,
  avatarImage: PropTypes.string,
  className: PropTypes.string,
  hideAvatarOrIcon: PropTypes.bool,
  isAssigneeCurrentAgent: PropTypes.bool,
  isInboxDisabled: PropTypes.bool,
  groupName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  textLabel: PropTypes.string,
};

export function getAssignee({ agentName, groupName, isAssigneeCurrentAgent }) {
  if (agentName && agentName.length && groupName && groupName.length) {
    return `${getAgentName(agentName, isAssigneeCurrentAgent)} in ${groupName}`;
  } else if (agentName && agentName.length) {
    return getAgentName(agentName, isAssigneeCurrentAgent);
  } else if (groupName && groupName.length) {
    return groupName;
  }
  return 'Not assigned';
}

export function renderAvatarOrInboxIcon(groupName, agentName, avatarImage, className, wrapperClassName) {
  if (agentName) {
    return <ProfileAvatar profile={{ name: agentName, image: avatarImage }} wrapperClassName={wrapperClassName} />;
  } else if (groupName) {
    return (
      <div className={`${className}-inboxIconContainer`}>
        <InboxIcon className={`${className}-inboxIcon`} />
      </div>
    );
  }
  return <ProfileAvatar profile={{ name: '' }} />;
}

function getAgentName(agentName, isAssigneeCurrentAgent) {
  if (isAssigneeCurrentAgent) {
    return `${agentName} (You)`;
  }
  return agentName;
}
