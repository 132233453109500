import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import { CustomerActivityType } from 'models/customer_activity';
import CustomerActivityContent from './customer_activity_content';
import CustomerActivityContentWithAdditional from './customer_activity_content_with_additional';
import ExpandCustomerActivity from 'actions/customer/expand_customer_activity';
import { HandleHeightChange } from 'components/customer/conversation_history/virtualized/list_item';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';

export function CustomerActivity({ className, item, shouldStartExpanded, onClick }) {
  const ContentComponent = item.content?.additionalContent
    ? CustomerActivityContentWithAdditional
    : CustomerActivityContent;

  return (
    <ItemContent item={item}>
      <ItemMetadata item={item} text={getText(item)} />
      <HandleHeightChange isManual>
        {({ onHeightChange }) => (
          <ContentComponent
            className={className}
            item={item}
            onClick={onClick}
            onHeightChange={onHeightChange}
            shouldStartExpanded={shouldStartExpanded}
          />
        )}
      </HandleHeightChange>
    </ItemContent>
  );

  function getText(conversationItem) {
    if (conversationItem.initiator.type === Communicator.AGENT) {
      return 'performed an activity';
    }
    return 'activity';
  }
}

CustomerActivity.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  shouldStartExpanded: PropTypes.bool,
  onClick: PropTypes.func,
};

function mapStateToProps({ isFeatureEnabled }, props) {
  return {
    shouldStartExpanded:
      isFeatureEnabled('demoMode') || _.get(props, 'item.content.activityType') === CustomerActivityType.SMS,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClick: attrs => executeAction(ExpandCustomerActivity, attrs),
  };
}

const CustomerActivityContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerActivity);

export default CustomerActivityContainer;
