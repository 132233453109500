import DeleteVoiceRecording from 'models/modal/delete_voice_recording';

export default class ShowDeleteVoiceRecordingModal {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    this.context.stores.modal.set(DeleteVoiceRecording.create(attrs));
  }
}
