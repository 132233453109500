import _ from 'lodash';

import ConversationMessageCompositionContent from 'models/composition/conversation_message_composition_content';
import CreateComposition from './lib/create_composition';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import { MessageChannelType } from 'models/conversation_message';

class CreateWhatsAppComposition extends CreateComposition {
  constructor(context) {
    super(context, ConversationMessageCompositionContent);
  }

  _createContent(attrs) {
    const lastWhatsAppItem = this._findLastWhatsAppItem(attrs.conversationId);

    const conversation = this.customerStores.conversations.find(attrs.conversationId);
    let companyAddress;
    let customerAddress;
    if (lastWhatsAppItem) {
      companyAddress = lastWhatsAppItem.content.companyAddress;
      customerAddress = lastWhatsAppItem.content.customerAddress;
    }

    if (!customerAddress) {
      customerAddress = this._getWhatsAppNumber();
    }

    const potentialFroms = this._getPotentialFroms(conversation);
    if (!_.includes(potentialFroms.allFromNumbers, companyAddress)) {
      companyAddress = potentialFroms.default;
    }

    return new ConversationMessageCompositionContent({
      channel: MessageChannelType.WHATSAPP,
      companyAddress,
      customerAddress,
      companyDisplayName: companyAddress,
      customerDisplayName: customerAddress,
    });
  }

  _canCreateComposition() {
    const hasExternalAgentActions = this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
    const channelConfiguration = this.context.stores.channelConfiguration.get();
    return channelConfiguration && channelConfiguration.companyWhatsAppNumbers().length > 0 && hasExternalAgentActions;
  }

  _getPotentialFroms(conversation) {
    let channelConfiguration = this.context.stores.channelConfiguration.get();
    let whatsAppEndpoints = channelConfiguration
      ? channelConfiguration.getEndpointsOfType(MessageChannelType.WHATSAPP)
      : [];
    let allFromNumbers = _.map(whatsAppEndpoints, 'address');
    let routingGroupId = conversation.assignee.routingGroupId;

    return this._getFroms({
      allFromNumbers,
      conversation,
      endpoints: whatsAppEndpoints,
      endpointType: MessageChannelType.WHATSAPP,
      routingGroupId,
    });
  }

  _getWhatsAppNumber() {
    const profile = this.customerStores.profile.get();
    return _.trim(profile.whatsappIds[0]?.username);
  }

  _findLastWhatsAppItem(conversationId) {
    const customerId = this.context.stores.currentLocation.get().customerId;
    const { conversationHistory } = this.context.stores.customers.storesFor(customerId);
    return getLatestManualItem({
      conversationHistory,
      filter: item => item.content.channel === MessageChannelType.WHATSAPP,
      conversationId,
    });
  }
}

export default CreateWhatsAppComposition;
