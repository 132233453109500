import { useEffect } from 'react';

import connect from 'components/lib/connect';
import { INBOX_COUNT_MAX } from 'models/inbox_count';

export function Title({ openCount }) {
  let title = document.title;
  if (title === 'Gladly') {
    if (openCount > INBOX_COUNT_MAX) {
      title += ` (999+)`;
    } else if (openCount > 0) {
      title += ` (${openCount})`;
    }
  }

  useTitle(title);
  return null;
}

function mapStateToProps({ getProvider }) {
  const personalInboxCounts = getProvider('personalInboxCounts').get();
  const openCount = (personalInboxCounts && personalInboxCounts.openCount) || 0;

  return {
    openCount,
  };
}

const TitleContainer = connect(mapStateToProps)(Title);
export default TitleContainer;

export function useTitle(title) {
  // Ensure we reset to previous title here.
  useEffect(() => {
    const originalTitle = document.title;
    return () => {
      document.title = originalTitle;
    };
  }, []);

  // Set the current title.
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);
}
