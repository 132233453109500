import _ from 'lodash';

import CustomerAssignee from 'models/customer_assignee';

export default class UpdateCustomerAssignee {
  constructor(context) {
    this.context = context;
  }

  run({ agentId, routingGroupId }) {
    let assigneeStore = this.store.storesFor(this.currentCustomerId).assignee;
    let customerAssignee = assigneeStore.get();
    if (customerAssignee) {
      customerAssignee.update(agentId, routingGroupId);
    } else {
      customerAssignee = CustomerAssignee.create({
        id: this.currentCustomerId,
        agentId,
        routingGroupId,
        _version: 1,
      });
    }
    assigneeStore.setPending(customerAssignee);
    this.context.gateways.customerAssignee.update(this.currentCustomerId, _.omit(customerAssignee.toJs(), '_version'));
  }

  get store() {
    return this.context.stores.customers;
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }
}
