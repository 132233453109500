import contentFromJs, { typeReflect } from 'models/conversation_item/content_from_js';
import ConversationItem from 'models/conversation_item';
import createModel, { prop } from './lib/create_model';

const LinkedCustomerProfile = createModel({
  modelName: 'LinkedCustomerProfile',

  properties: {
    email: String,
    name: String,
    phone: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export { LinkedCustomerProfile };

export default createModel({
  modelName: 'ItemLinkContent',

  properties: {
    customerId: prop(String).required,
    itemId: prop(String).required,

    // following props provided by SN
    customerProfile: prop(LinkedCustomerProfile),
    originalContent: prop(/* content */).fromJs(contentFromJs),
    originalInitiator: { type: String, id: String },
    originalTimestamp: prop(String),
  },

  contentType() {
    return typeReflect.instanceToType(this.originalContent);
  },

  overrideToJs(toJs) {
    return () => {
      let obj = toJs();
      if (obj.originalContent) {
        obj.originalContent.type = this.contentType();
      }
      return obj;
    };
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export function createConversationItemFromItemLink(item) {
  let itemDto = item.toJs();

  if (!itemDto.content || !itemDto.content.originalContent) {
    return null;
  }
  let linkedItem = ConversationItem.fromJs({
    id: itemDto.id,
    content: itemDto.content.originalContent,
    customerId: itemDto.customerId,
    initiator: {
      ...itemDto.content.originalInitiator,
      name: itemDto.content.customerProfile.name,
    },
    timestamp: itemDto.content.originalTimestamp,
    version: itemDto.version,
    linkedItems: [
      {
        customerId: itemDto.content.customerId,
        itemId: itemDto.content.itemId,
        customerProfile: itemDto.content.customerProfile,

        isOriginal: true,
      },
    ],
  });
  return linkedItem;
}
