import React from 'react';
import Icon from 'components/lib/icons/icon_container';

export default props => (
  <Icon viewBox="0 0 28 28" {...props}>
    <path
      clipRule="evenodd"
      d="M15.22 20.683a.51.51 0 0 1 .345-.183c6.026-.59 10.685-4.546 10.685-9.337 0-5.199-5.485-9.413-12.25-9.413S1.75 5.964 1.75 11.163c0 3.847 3.003 7.155 7.306 8.615.207.07.35.263.35.482v6.128c0 .47.59.68.887.317l4.926-6.022z"
      fillRule="evenodd"
    />
  </Icon>
);
