import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class DynamicOverflow extends React.Component {
  constructor(props) {
    super(props);

    this.elementRefs = [];
    this.state = {
      visibleElementIds: [...this.props.children.keys()],
      containerRef: null,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    this.handleResize = _.throttle(this.handleResize, DEFAULT_THROTTLE);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.handleResize.cancel();
  }

  handleResize() {
    const containerWidth = this.containerRef && this.containerRef.clientWidth;
    const overflowElementWidth = this.props.defaultItemWidth;
    const elementWidths = this.elementRefs.map(r => {
      return getWidth(r) || this.props.defaultItemWidth;
    });

    // start with index of elements that are `alwaysShow`
    const visibleElementIds = this.props.children.reduce(
      (acc, element, idx) => (element.props.alwaysShow ? [...acc, idx] : acc),
      []
    );

    // Add 5 to account for the separator (1px) + gap (4px)
    let totalVisibleWidth = _.sum(visibleElementIds.map(i => elementWidths[i])) + overflowElementWidth + 5;

    for (const [idx, element] of this.props.children.entries()) {
      const elementWidth = elementWidths[idx];
      if (element.props.alwaysShow) {
        continue;
      } else if (totalVisibleWidth + elementWidth > containerWidth) {
        break;
      } else {
        // CSS includes 4px gap between each item, so include that
        totalVisibleWidth += elementWidth + 4;
        visibleElementIds.push(idx);
      }
    }

    this.setState({ visibleElementIds: _.sortBy(visibleElementIds) });
  }

  render() {
    const elements = this.props.children.map((element, idx) => {
      // wrap elements with a span in case any of them are functional components (which we can't get a ref for)
      return (
        <span
          className="dynamic-overflow-elementwrapper"
          key={`element-${idx}`}
          ref={instance => (this.elementRefs[idx] = instance)}
        >
          {element}
        </span>
      );
    });
    const visibleElements = this.state.visibleElementIds.map(i => elements[i]);
    const overflowElements = elements.filter((element, idx) => {
      return this.state.visibleElementIds.indexOf(idx) < 0 && !this.props.children[idx].props.excludeFromOverflow;
    });

    return (
      <StyledOverflow className={this.props.className} ref={instance => (this.containerRef = instance)}>
        {this.props.itemRenderer(visibleElements, overflowElements)}
      </StyledOverflow>
    );
  }
}
const StyledOverflow = styled.span`
  display: flex;
  flex: 0 0 100%;
  gap: 4px;
  justify-content: flex-end;
  overflow: hidden;
`;

export { StyledOverflow };

function getWidth(ref) {
  return (
    (ref && ref.getBoundingClientRect().width) ||
    (ref && ref.firstChild && ref.firstChild.getBoundingClientRect().width)
  );
}

DynamicOverflow.propTypes = {
  ...React.Component.propTypes,
  defaultItemWidth: PropTypes.number.isRequired,
  itemRenderer: PropTypes.func.isRequired,
};

const DEFAULT_THROTTLE = 200;

export default DynamicOverflow;
