const ServerClock = {
  adjustmentIntervalMillis: 1000,
  isAdjusting: false,
  offset: 0,
  targetOffset: 0,
  timerId: null,

  _adjustOffset() {
    ServerClock.isAdjusting = true;

    // If the target offset is > 25 ms away, the clock offset will approach the target offset by 25 ms every second until it matches the target offset
    // However, if the target offset is >= 2000 ms away, the offset will immediately be set to the target offset.
    if (
      Math.abs(ServerClock.targetOffset - ServerClock.offset) <= 25 ||
      Math.abs(ServerClock.targetOffset - ServerClock.offset) >= 2000
    ) {
      ServerClock.offset = ServerClock.targetOffset;
      ServerClock.isAdjusting = false;
    } else {
      if (ServerClock.targetOffset > ServerClock.offset) {
        ServerClock.offset += 25;
      } else {
        ServerClock.offset -= 25;
      }

      ServerClock.timerId = setTimeout(ServerClock._adjustOffset, ServerClock.adjustmentIntervalMillis);
    }
  },

  now() {
    const now = new Date();
    return new Date(now.valueOf() + ServerClock.offset);
  },

  reset() {
    clearTimeout(ServerClock.timerId);
    ServerClock.offset = 0;
    ServerClock.targetOffset = 0;
    ServerClock.isAdjusting = false;
  },

  updateOffset({ requestSentAt, requestReceivedAt, responseSentAt }, responseReceivedAt) {
    ServerClock.targetOffset =
      (new Date(requestReceivedAt) -
        new Date(requestSentAt) +
        (new Date(responseSentAt) - new Date(responseReceivedAt))) /
      2;
    if (!ServerClock.isAdjusting) {
      ServerClock._adjustOffset();
    }
  },
};

// Populate ServerClock with the methods of Date's instances that don't change state.
[
  'toString',
  'toDateString',
  'toTimeString',
  'toLocaleString',
  'toLocaleDateString',
  'toLocaleTimeString',
  'valueOf',
  'getTime',
  'getFullYear',
  'getUTCFullYear',
  'getMonth',
  'getUTCMonth',
  'getDate',
  'getUTCDate',
  'getDay',
  'getUTCDay',
  'getHours',
  'getUTCHours',
  'getMinutes',
  'getUTCMinutes',
  'getSeconds',
  'getUTCSeconds',
  'getMilliseconds',
  'getUTCMilliseconds',
  'getTimezoneOffset',
  'toUTCString',
  'toISOString',
  'toJSON',
].forEach(function(method) {
  ServerClock[method] = function() {
    return new Date(ServerClock.now())[method]();
  };
});

export default ServerClock;
