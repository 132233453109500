import _ from 'lodash';

export default class HttpUploader {
  constructor(backend, notifyObservers) {
    this._httpClient = backend;
    this.notifyObservers = notifyObservers;
    this.uploadRequests = {};
  }

  abort(path) {
    let uploadRequest = this.uploadRequests[path];
    if (uploadRequest) {
      uploadRequest.abort();
      delete this.uploadRequests[path];
    }
  }

  uploadFile(path, file, auth) {
    let requestForm = _.reduce(
      auth.postParams,
      (memo, v, k) => {
        memo.append(k, v);
        return memo;
      },
      new FormData()
    );
    requestForm.append('file', file);

    this.upload(path, requestForm, auth.url);
  }

  upload(path, body, url, opts) {
    this.uploadRequests[path] = this._httpClient.postUpload(
      url,
      body,
      {
        abort: evt => this.handleUploadAbort(evt, path),
        error: evt => this.handleUploadError(evt, path),
        load: evt => this.handleUploadFinished(evt, path),
        progress: evt => this.handleUploadProgress(evt, path),
      },
      opts
    );
  }

  handleUploadAbort(evt, path) {
    delete this.uploadRequests[path];
    this.notifyObservers('handleUploadAbort', path);
  }

  handleUploadError(evt, path) {
    let request = evt.target;
    let error;
    delete this.uploadRequests[path];
    if (request.status) {
      error = {
        statusText: request.statusText,
        status: request.status,
        message: request.response,
      };
    } else {
      error = { message: 'connection error' };
    }
    this.notifyObservers('handleUploadError', path, error);
  }

  handleUploadFinished(evt, path) {
    let request = evt.target;
    delete this.uploadRequests[path];
    if (request.status >= 200 && request.status < 300) {
      this.notifyObservers('handleUploadSuccess', path, request.response);
    } else {
      this.notifyObservers('handleUploadError', path, {
        statusText: request.statusText,
        status: request.status,
        message: request.response,
      });
    }
  }

  handleUploadProgress(evt, path) {
    let progress = evt.lengthComputable && (evt.loaded / evt.total) * 100;
    if (progress || progress === 0) {
      this.notifyObservers('handleUploadProgress', path, progress);
    }
  }
}
