import _ from 'lodash';
import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Button, { ButtonTypes } from 'components/common/button';
import Endpoint from 'models/endpoint';
import { formatPhoneNumber } from 'models/phone_number';
import NavigateToReportWithFilters from 'actions/reporting/navigate_to_report_with_filters';
import ReportDateRange from './report_date_range';

import connect from 'components/lib/connect';
import moment from 'moment/moment';

export class IvrReportFilters extends Component {
  constructor(props) {
    super(props);

    const { currentReport } = this.props;
    this.state = {
      startDate: currentReport.getStartAt(),
      endDate: currentReport.getEndAt(),
      endpointNumber: currentReport.getEndpointNumber(),
    };
    _.bindAll(this, ['applyFilters', 'onEndpointChange', 'renderEndpointNumbers', 'onDateRangeChange']);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentReport !== this.props.currentReport) {
      const { currentReport } = this.props;
      this.setState({
        startDate: currentReport.getStartAt(),
        endDate: currentReport.getEndAt(),
        endPointNumber: currentReport.getEndpointNumber(),
      });
    }
  }

  render() {
    let isApplyForceEnabled = this.props.isApplyForceEnabled;
    const shouldDisableApplyButton = isApplyForceEnabled ? false : !this.hasUnappliedChanges();

    return (
      <div className="reportFilters">
        {this.props.isDisabled && <span className="reportFilters-overlay" />}
        {this.renderEndpointNumbers()}
        <ReportDateRange
          className={classnames({
            'dateRange-changed': this.hasUnappliedDateChanges(),
          })}
          endDate={this.state.endDate}
          onApply={this.onDateRangeChange}
          startDate={this.state.startDate}
        />
        <Button
          buttonType={ButtonTypes.PRIMARY}
          className="reportFilters-applyButton"
          disabled={shouldDisableApplyButton}
          onClick={this.applyFilters}
        >
          View Report
        </Button>
      </div>
    );
  }

  renderEndpointNumbers() {
    let options = [];
    if (this.props.voiceEndpoints) {
      this.props.voiceEndpoints.forEach(x => {
        options.push({ value: x.address, label: formatPhoneNumber(x.address) });
      });
    }

    let placeHolder;
    if (this.state.endpointNumber) {
      placeHolder = formatPhoneNumber(this.state.endpointNumber);
    }

    return (
      <ReactSelect
        className={classnames('reportFilters-phoneNumberMenu', {
          'reportFilters-phoneNumberMenu-changed': this.hasUnappliedEndpointChanges(),
        })}
        clearable={false}
        name="reportingEndpointNumber"
        onChange={this.onEndpointChange}
        options={options}
        placeholder={placeHolder}
      />
    );
  }

  onEndpointChange(endpoint) {
    this.setState({ endpointNumber: endpoint.value });
  }

  onDateRangeChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  hasUnappliedChanges() {
    return this.hasUnappliedDateChanges() || this.hasUnappliedEndpointChanges();
  }

  hasUnappliedEndpointChanges() {
    let selectedEndpoint = this.state.endpointNumber;
    let currentReportEndpoint = this.props.currentReport.getEndpointNumber();

    return selectedEndpoint !== currentReportEndpoint;
  }

  hasUnappliedDateChanges() {
    let selectedStartDate = this.state.startDate;
    let selectedEndDate = this.state.endDate;
    let currentReportStartDate = this.props.currentReport.getStartAt();
    let currentReportEndDate = this.props.currentReport.getEndAt();

    return (
      !this.areDatesEqual(selectedStartDate, currentReportStartDate) ||
      !this.areDatesEqual(selectedEndDate, currentReportEndDate)
    );
  }

  areDatesEqual(date1, date2) {
    const dateFormat = 'YYYY-MM-DD';
    let formattedDate1 = moment(date1, dateFormat).format(dateFormat);
    let formattedDate2 = moment(date2, dateFormat).format(dateFormat);
    return formattedDate1 === formattedDate2;
  }

  applyFilters() {
    let newFilters = {
      startAt: this.state.startDate,
      endAt: this.state.endDate,
      endpointNumber: this.state.endpointNumber,
    };

    this.props.navigateToReportWithFilters(newFilters);
  }
}

IvrReportFilters.propTypes = {
  currentReport: PropTypes.object.isRequired,
  endpointNumber: PropTypes.string,
  isApplyForceEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  navigateToReportWithFilters: PropTypes.func.isRequired,
  voiceEndpoints: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps({ isFeatureEnabled, getProvider }) {
  let currentReport = getProvider('currentLocation').get();

  let channelConfiguration = getProvider('channelConfiguration').get();
  let voiceEndpoints = channelConfiguration
    ? channelConfiguration.endpoints.filter(e => e.type === Endpoint.Type.VOICE)
    : [];

  let isViewReportsEnabled = isFeatureEnabled('viewReports');
  let endpointNumber = currentReport.getEndpointNumber();
  let isDisabled = getProvider('report').isLoading() || !isViewReportsEnabled;

  return {
    currentReport,
    endpointNumber,
    isDisabled,
    voiceEndpoints,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    navigateToReportWithFilters: newFilters => executeAction(NavigateToReportWithFilters, newFilters),
  };
}

const IvrReportFiltersContainer = connect(mapStateToProps, mapExecuteToProps)(IvrReportFilters);
export default IvrReportFiltersContainer;
