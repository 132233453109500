import _ from 'lodash';
import changeCurrentLocation from 'actions/lib/change_current_location';
import CurrentCompositionUpdater from 'actions/lib/current_composition_updater';
import CustomerView from 'models/location/customer_view';
import ExternalCustomerLookup from 'models/location/external_customer_lookup';
import loadCustomer from './lib/load_customer';
import rerequestCustomer from './rerequest_customer';
import { loadingItems } from 'actions/customer/feed/fetch_more_items';

export default class SwitchCustomer {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(this.context);
  }

  run(requestedCustomerId) {
    if (this.isRequestingDifferentCustomer(requestedCustomerId)) {
      this.changeCurrentLocation(requestedCustomerId);
      const isStaleCustomer = this.context.stores.customers.isCustomerStale(requestedCustomerId);
      if (!this.context.stores.customers.findBy({ id: requestedCustomerId })) {
        this.loadCustomer(requestedCustomerId);
      } else if (isStaleCustomer) {
        rerequestCustomer(this.context, requestedCustomerId);
      }

      this.extendStores(requestedCustomerId);
      this.updateCompositions(requestedCustomerId);
    } else {
      if (!(this.context.stores.currentLocation.get() instanceof CustomerView)) {
        this.changeCurrentLocation(requestedCustomerId);
      }
      this.currentCompositionUpdater.update();
    }
    this.resetLoadingItems();
    this.updateRecentCustomerIds(requestedCustomerId);
  }

  extendStores(customerId) {
    let customerStores = this.context.stores.customers.storesFor(customerId);
    this.context.extendStores(customerStores);
    this.context.extendProviders(_.mapValues(customerStores, store => store.getProvider()));
  }

  loadCustomer(customerId) {
    loadCustomer(this.context, customerId);
  }

  updateCompositions() {
    this.currentCompositionUpdater.update();
  }

  isRequestingDifferentCustomer(requestedCustomerId) {
    return requestedCustomerId !== this.currentLocation.customerId;
  }

  changeCurrentLocation(requestedCustomerId) {
    let locationHistory = this.context.stores.locationHistory.get();
    let previousLocation = locationHistory.getPreviousEntry();
    let nextCustomerLocation =
      (previousLocation instanceof CustomerView &&
        previousLocation.customerId === requestedCustomerId &&
        previousLocation) ||
      CustomerView.create({ customerId: requestedCustomerId });

    if (this.currentLocation instanceof ExternalCustomerLookup && this.currentLocation.linkRequested) {
      nextCustomerLocation.setCustomerExtensionPending();
    }
    nextCustomerLocation.updateRoutedAt();

    changeCurrentLocation(this.context, nextCustomerLocation);
  }

  resetLoadingItems() {
    loadingItems.clear();
  }

  updateRecentCustomerIds(customerId) {
    const recentCustomerIds = this.context.stores.localRecentCustomerIds.get();
    const newRecentCustomerIds = _.concat(
      [customerId],
      _.filter(recentCustomerIds, id => id !== customerId)
    );
    this.context.stores.localRecentCustomerIds.set(newRecentCustomerIds);
  }

  get currentLocation() {
    return this.context.stores.currentLocation.get();
  }
}
