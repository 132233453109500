import classnames from 'classnames';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';

export default class RecordingButton extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }
  render() {
    return (
      <div className={classnames('circleBorder', { disabled: this.props.isDisabled })}>
        <div className={classnames('recordingButton', this.props.contentClasses)} />
      </div>
    );
  }
}

RecordingButton.propTypes = {
  contentClasses: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
};
