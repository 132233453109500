import { Factory } from 'rosie';

Factory.define('Sla');

let now = new Date().toISOString();

Factory.define('SlaWithDefaults')
  .extend('Sla')
  .attr('contentType', 'EMAIL')
  .attr('setByConversationItemId', 'sla-item-id')
  .attr('dueAt', now);
