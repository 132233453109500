import React from 'react';
import styled from 'styled-components';
import Icon from 'components/lib/icons/icon';

export default props => (
  <StyledIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M18.1875 10.2656C18.1875 10.6211 17.9082 10.875 17.5781 10.875H14.7344H13.9219C13.5664 10.875 13.3125 10.6211 13.3125 10.2656C13.3125 9.93555 13.5664 9.65625 13.9219 9.65625H14.7344H16.1055L14.7852 8.33594C14.0488 7.625 13.084 7.21875 12.0938 7.21875C10.2402 7.21875 8.64062 8.36133 7.98047 9.96094C7.82812 10.2656 7.47266 10.3926 7.16797 10.2656C6.86328 10.1387 6.71094 9.7832 6.83789 9.47852C7.70117 7.44727 9.73242 6 12.0938 6C13.4141 6 14.6836 6.5332 15.623 7.47266L16.9688 8.81836V7.42188V6.60938C16.9688 6.2793 17.2227 6 17.5781 6C17.9082 6 18.1875 6.2793 18.1875 6.60938V10.2656ZM6.60938 12.5H9.45312C9.7832 12.5 10.0625 12.7793 10.0625 13.1094C10.0625 13.4648 9.7832 13.7188 9.45312 13.7188H8.05664L9.40234 15.0645C10.1133 15.7754 11.0781 16.1562 12.0938 16.1562C13.9219 16.1562 15.5215 15.0645 16.1816 13.4648C16.334 13.1348 16.6895 13.0078 16.9941 13.1348C17.2988 13.2617 17.4512 13.6172 17.3242 13.9473C16.4355 15.9531 14.4297 17.375 12.0938 17.375C10.748 17.375 9.47852 16.8672 8.53906 15.9277L7.21875 14.582V15.9531C7.21875 16.3086 6.93945 16.5625 6.60938 16.5625C6.25391 16.5625 6 16.3086 6 15.9531V13.1094C6 12.7793 6.25391 12.5 6.60938 12.5Z"
      fill="currentColor"
      strokeWidth="0.25"
    />
  </StyledIcon>
);

const StyledIcon = styled(Icon)`
  min-width: 24px;
  stroke: ${p => p.theme.colors.gray800};
`;
