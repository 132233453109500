import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const WidgetActivityTypes = createEnum('COBROWSE');

const WidgetActivity = createModel({
  modelName: 'WidgetActivity',

  properties: {
    activityType: prop().oneOf(...Object.keys(WidgetActivityTypes)).isRequired,
    body: prop(String),
    meta: prop(Object),
    status: prop(String).isRequired,
    sourceName: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default WidgetActivity;
