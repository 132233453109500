import { createAndShowNotification } from 'actions/notification/lib/show_notification';
import CustomerView from 'models/location/customer_view';
import { emptyQueueMessage } from 'actions/conversation/lib/conversation_action_helpers';
import isCustomerLoaded from 'actions/customer/lib/is_customer_loaded';
import loadCustomer from 'actions/customer/lib/load_customer';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import RemoveToast from 'actions/toast_deprecated/remove_toast';

export function checkAndResetNavigatingToNext(context) {
  let conversationWorkflow = context.stores.conversationWorkflow.get();
  if (conversationWorkflow.isNavigatingNextConversation) {
    conversationWorkflow.clearNavigatingNextConversation();
    context.stores.conversationWorkflow.set(conversationWorkflow);
    return true;
  }
  return false;
}

export function handleNotifiableEvent(context, event) {
  const customerId = event.customerId || event.profile.id;

  // if currently viewing the customer's conversation, do not create a notification
  let currentLocation = context.stores.currentLocation.get();
  if (currentLocation instanceof CustomerView && currentLocation.customerId === customerId) {
    checkAndResetNavigatingToNext(context);
    return;
  }

  preloadCustomer(context, customerId);
  removeEmptyQueueNotifications(context);

  if (checkAndResetNavigatingToNext(context)) {
    context.executeAction(NavigateToConversation, {
      customerId,
      conversationId: event.conversationId,
    });
  } else {
    createAndShowNotification(context, event);
  }
}

export function preloadCustomer(context, customerId) {
  if (!isCustomerLoaded(context, customerId)) {
    loadCustomer(context, customerId);
  }
}
export function removeEmptyQueueNotifications(context) {
  context.executeAction(RemoveToast, emptyQueueMessage.id);
}
