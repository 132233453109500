import _ from 'lodash';
import Immutable from 'immutable';

import Customer from 'models/customer';
import orderedMapFromEntityImmutables from './lib/ordered_map_from_entity_immutables';

export default {
  fromImmutable(immutable) {
    let customer = immutable.toJS();
    return Customer.fromJs({
      ...customer,
      compositions: _.values(customer.compositions),
      conversationHistory: _.values(customer.conversationHistory),
      conversations: _.values(customer.conversations),
      itemIds: _.values(customer.itemIds),
      note: customer.note || {},
      profile: customer.profile || {},
      transactions: _.values(customer.transactions),
    });
  },

  toImmutable(customer) {
    return Immutable.fromJS(customer.toJs(), (k, v) => {
      if (COLLECTION_KEYS.has(k)) {
        return orderedMapFromEntityImmutables(v);
      }
      return Immutable.Iterable.isIndexed(v) ? v.toList() : v.toMap();
    });
  },
};

const COLLECTION_KEYS = new Set(['compositions', 'conversations', 'conversationHistory']);
