import _ from 'lodash';
import AgentActivity from 'models/agent_activity';
import { AgentAvailability } from 'models/agent_status';
import BeginLogOut from 'actions/user/begin_log_out';
import cancelAgentPolling from 'actions/configuration/lib/cancel_agent_polling';
import { DomainError } from 'scripts/application/lib/error';
import Err from 'models/err';
import { handleErrorInteractively, updateStore } from 'actions/agent_status/lib/agent_status';
import { logStatusActivity } from 'actions/agent_status/lib/agent_activity';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class GoAway {
  constructor(context) {
    this.context = context;
  }

  run({ reasonId }) {
    if (this.store.get().getAvailability() === AgentAvailability.AWAY) {
      return;
    }

    this.setAwayPending(reasonId);

    this.context.gateways.agentStatus
      .update({ availability: AgentAvailability.AWAY, awayReasonId: reasonId })
      .then(response => this.handleSuccess(response))
      .catch(err => this.handleError(err));

    logStatusActivity(this.context, AgentActivity.Type.AWAY, { reasonId });

    this.context.capacityManager.stop();

    const reason = this.context.stores.agentStatusReasons.find(reasonId);
    this.context.analytics.track('Agent Status Reason Updated', { id: reason.id, name: reason.name, type: 'AWAY' });
    cancelAgentPolling(this.context);
  }

  handleError(err) {
    if (
      err instanceof DomainError &&
      err.errors[0].code === Err.Code.INVALID_STATE &&
      err.errors[0].attr === 'status.availability'
    ) {
      this.context.executeAction(ShowToast, {
        type: ToastType.INFO,
        message: "It appears you've been logged out. Checking...",
      });

      this.context.gateways.agentStatus
        .get()
        .then(dto => {
          switch (_(dto).get('status.availability')) {
            case AgentAvailability.OFFLINE:
              // Keep AWAY status pending to continue showing the away page
              // while the gateway observer begins the logout process.
              return;
            case AgentAvailability.AWAY:
              this.store.resetPending();
              return;
            default:
              this.store.resetPending();
              this.context.capacityManager.start();
          }
        })
        .catch(() =>
          // Force logout since we know that the status was OFFLINE moments ago
          // and we couldn't determine otherwise.
          this.context.executeAction(BeginLogOut)
        );

      return;
    }

    this.context.capacityManager.start();

    handleErrorInteractively(this.context, err);
  }

  handleSuccess(response) {
    this.store.resetPending();
    updateStore(this.context, response);
  }

  setAwayPending(reasonId) {
    let status = this.store.get();
    status.setAway(reasonId);
    status.incrementVersion();
    this.store.setPending(status);
  }

  get store() {
    return this.context.stores.agentStatus;
  }
}
