import AgentProfileEditor from 'models/location/agent_profile_editor';
import BeginActivation from 'actions/user/begin_activation';
import BeginLogOut from 'actions/user/begin_log_out';
import BeginPasswordReset from 'actions/user/begin_password_reset';
import ForgotPassword from 'models/location/forgot_password';
import OpenAgentProfile from 'actions/current_agent/open_agent_profile';
import OpenForgotPassword from 'actions/user/open_forgot_password';
import OpenLogin from 'actions/user/open_login';
import { registerUrlConverter } from './location_url';
import StaticUrlConverter from './url_converters/static_url_converter';
import UserActivation from 'models/location/user_activation';
import UserLogin from 'models/location/user_login';
import UserResetPassword from 'models/location/user_reset_password';

registerUrlConverter(AgentProfileEditor, StaticUrlConverter.create('/user/profile'));
registerUrlConverter(ForgotPassword, StaticUrlConverter.create('/user/forgot-password'));
registerUrlConverter(UserActivation, StaticUrlConverter.create('/user/activate'));
registerUrlConverter(UserLogin, StaticUrlConverter.create('/user/login'));
registerUrlConverter(UserResetPassword, StaticUrlConverter.create('/user/reset-password'));

export default function(router, { runnerContext }) {
  return {
    '/activate': {
      on: () => {
        runnerContext.executeAction(BeginActivation, router.getQueryParameter('token'));
      },
    },
    '/forgot-password': {
      on: () => {
        runnerContext.executeAction(OpenForgotPassword);
      },
    },
    '/login': {
      on: () => {
        runnerContext.executeAction(OpenLogin, router.getQueryParameter('fromPath'));
      },
    },
    '/profile': {
      on: () => {
        runnerContext.executeAction(OpenAgentProfile);
      },
    },
    '/reset-password': {
      on: () => {
        runnerContext.executeAction(BeginPasswordReset, router.getQueryParameter('token'));
      },
    },
    '/logout': {
      on() {
        runnerContext.executeAction(BeginLogOut);
      },
    },
  };
}
