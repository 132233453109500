import { TransactionDefClasses } from './transaction_def';
import TypeReflect from 'models/lib/type_reflect';

const transactionDefTypeReflect = new TypeReflect(TransactionDefClasses);

const transactionDefFromJs = function(transactionDef) {
  try {
    let ContentClass = transactionDefTypeReflect.typeToConstructor(transactionDef.type);
    return ContentClass.fromJs(transactionDef);
  } catch (e) {
    throw new Error(`unknown transaction def type [${transactionDef.type}]`);
  }
};

export { transactionDefFromJs, transactionDefTypeReflect };
