import _ from 'lodash';
import Conversation from 'models/conversation';
import createEnum from 'scripts/lib/create_enum';
import createLocationModel, { prop } from './lib/create_location_model';

export const DEFAULT_FETCH_SIZE = 20;
export const InboxConversationStatus = Conversation.InboxStatus;
export const InboxSortOrder = createEnum('LATEST', 'OLDEST');

let queryProperties = {
  conversationStatus: prop()
    .oneOf(..._.values(InboxConversationStatus))
    .default(InboxConversationStatus.OPEN),
  isTeam: prop(Boolean).default(false),
  routingGroupId: String,
  sortOrder: prop()
    .oneOf(..._.values(InboxSortOrder))
    .default(InboxSortOrder.LATEST),
  topicId: String,
};

let transientProperties = {
  cutoffMax: String, // Cutoffs are max/min conversation UpdatedAt TS for inbox pagination
  cutoffMin: String,
  isPolling: prop(Boolean).default(true),
  scrollPosition: Array, // Cached previous scroll position once user clicks on a conversation item
};

const Inbox = createLocationModel({
  modelName: 'Inbox',
  properties: {
    ...queryProperties, // inbox query
    ...transientProperties, // inbox transient state
  },

  deriveNew(newQuery) {
    return Inbox.create(_.assign(this.constructor.filterQuery(this), newQuery));
  },

  setQuery(newState) {
    this.constructor.checkQuery(newState);

    let query = this.constructor.filterQuery(newState);
    if (!this.includesState(query)) {
      this._clearTransientState();
      _.merge(this, query);
      return true;
    }

    return false;
  },

  includesState(otherState) {
    return !_.some(otherState, (value, key) => this.constructor.properties.hasOwnProperty(key) && value !== this[key]);
  },

  _clearTransientState() {
    _.forEach(transientProperties, (v, k) => (this[k] = undefined));
    this.isPolling = true;
  },

  setTransientState(transientState) {
    _.merge(this, _.pick(transientState, _.keys(transientProperties)));
  },

  statics: {
    breadcrumb: 'Home',

    checkQuery({ conversationStatus, sortOrder }) {
      if (conversationStatus && !(conversationStatus in InboxConversationStatus)) {
        throw new Error(`[${conversationStatus}] is not an inbox conversation status`);
      }

      if (sortOrder && !(sortOrder in InboxSortOrder)) {
        throw new Error(`[${sortOrder}] is not a valid sort order`);
      }
    },

    create(attrs = {}) {
      this.checkQuery(attrs);

      if (!attrs.conversationStatus) {
        attrs = { ...attrs, conversationStatus: this.getDefaultConversationStatus(attrs) };
      }

      return new this(attrs);
    },

    filterQuery(state) {
      return _.pick(state, _.keys(queryProperties));
    },

    getDefaultConversationStatus({ isTeam }) {
      return isTeam ? InboxConversationStatus.NEW : InboxConversationStatus.OPEN;
    },
  },
});

export default Inbox;
