const COLORS = {
  black: '#000000',
  blue: '#001FE4',
  gray: '#D2D2D2',
  green: '#009b00',
  lightGray: '#EAEAEA',
  orange: '#FF7C00',
  purple: '#5A004A',
  red: '#D2220E',
  white: '#FFFFFF',
  xlightGray: '#F9F9F9',
  yellow: '#E5C300',
};

export default COLORS;

const GRAYS = {
  xdark: '#111111',
  dark: COLORS.darkGray,
  medium: '#777777',
  light: '#999999',
  xlight: COLORS.gray,
  xxlight: '#DDDDDD',
  xxxlight: COLORS.lightGray,
  xxxxlight: COLORS.xlightGray,
};

export { GRAYS };
