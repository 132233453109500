import React from 'react';
import PropTypes from 'prop-types';

import ModalButton, { ModalButtonDivider } from 'components/modals/modal_button';
import connect from 'components/lib/connect';
import DeleteCallRecording from 'actions/conversation_item/voice_recording/delete_call_recording';
import DeleteVoicemailRecording from 'actions/conversation_item/voice_recording/delete_voicemail_recording';
import Modal from '../lib/modal';
import ModalFooter from 'components/modals/modal_footer';

const DeleteVoiceRecordingModal = props => {
  return (
    <Modal className="deleteVoiceRecordingModal">
      <div className="deleteVoiceRecordingModal-header">Delete Recording?</div>
      <div className="deleteVoiceRecordingModal-body">
        Recording will be permanently <br />
        deleted and cannot be undone.
      </div>
      <ModalFooter className="deleteVoiceRecordingModal-footer">
        <ModalButton className="deleteVoiceRecordingModal-cancelButton" onClick={props.onClose}>
          Cancel
        </ModalButton>
        <ModalButtonDivider />
        <ModalButton className="deleteVoiceRecordingModal-confirmButton" onClick={props.onConfirm}>
          Yes, delete
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export { DeleteVoiceRecordingModal };

DeleteVoiceRecordingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function DeleteVoiceRecordingModalWrapper(props) {
  return <DeleteVoiceRecordingModal onClose={props.onClose} onConfirm={handleConfirm} />;

  function handleConfirm() {
    props.deleteVoiceRecording({
      customerId: props.customerId,
      conversationItemId: props.conversationItemId,
      isCallRecording: props.isCallRecording,
    });
  }
}

DeleteVoiceRecordingModalWrapper.propTypes = {
  customerId: PropTypes.string.isRequired,
  conversationItemId: PropTypes.string.isRequired,
  deleteVoiceRecording: PropTypes.func.isRequired,
  isCallRecording: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider }) {
  let modalAttrs = getProvider('modal').get();

  return {
    customerId: modalAttrs.customerId,
    conversationItemId: modalAttrs.conversationItemId,
    isCallRecording: modalAttrs.isCallRecording,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onClose: props.onClose,
    deleteVoiceRecording,
  };

  function deleteVoiceRecording({ customerId, conversationItemId, isCallRecording }) {
    props.onClose();
    if (isCallRecording) {
      executeAction(DeleteCallRecording, { customerId, conversationItemId });
    } else {
      executeAction(DeleteVoicemailRecording, { customerId, conversationItemId });
    }
  }
}

const DeleteVoiceRecordingModalContainer = connect(
  mapStateToProps,
  mapExecuteToProps
)(DeleteVoiceRecordingModalWrapper);

DeleteVoiceRecordingModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DeleteVoiceRecordingModalContainer;
