import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const CoBrowsingSessionStatus = createEnum('NEW', 'ACTIVE', 'ENDED');

const CoBrowsingSessionData = createModel({
  modelName: 'CoBrowsingSessionData',

  properties: {
    id: prop(String).isRequired,
    agentId: prop(String).isRequired,
    customerId: prop(String).isRequired,
    conversationId: prop(String).isRequired,
    sessionMeta: prop(Object).isRequired.default({}), // Here we keep the original SM session attributes if needed
    status: prop()
      .oneOf(...Object.keys(CoBrowsingSessionStatus))
      .isRequired.default(CoBrowsingSessionStatus.NEW),
  },

  setStatus(status) {
    this.status = status;
  },

  updateMeta(newMeta) {
    this.sessionMeta = { ...this.sessionMeta, ...(newMeta || {}) };
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CoBrowsingSessionData;
