import AgentActivity from 'models/agent_activity';
import { AgentAvailability } from 'models/agent_status';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ServerClock from 'scripts/application/lib/server_clock';

export default class LogActivity {
  constructor(context) {
    this.context = context;
  }

  run({ withNewId } = {}) {
    let activity = this.context.stores.agentActivity.get();
    let status = this.context.stores.agentStatus.getPending() || this.context.stores.agentStatus.get();
    let availability = status && status.getAvailability();

    const isBecomingAway = activity && activity.type === AgentAvailability.AWAY;
    const isAway = !isBecomingAway && availability === AgentAvailability.AWAY;
    const isOffline = availability === AgentAvailability.OFFLINE;

    if (
      activity &&
      activity.agentId &&
      (offlineActivities.includes(activity.type) || (status && !isAway && !isOffline))
    ) {
      let id = withNewId ? IdGenerator.newId() : activity.id;

      let agentId = activity.agentId;
      let payload = { ...activity.toJs(), timestamp: ServerClock.toISOString(), id };

      this.context.gateways.agentActivity.add(agentId, payload);
    }
  }
}

const offlineActivities = Object.freeze([
  AgentActivity.Type.LOG_IN, // LOG_IN will almost always be with OFFLINE status
  AgentActivity.Type.LOG_OUT, // Automatic LOG_OUT occurs after going OFFLINE
]);
