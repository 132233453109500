import DtoConverter from 'scripts/application/dto_converters/company_profile_converter';
import qconsole from 'scripts/lib/qconsole';

export default class CompanyProfileGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleCompanyProfileFetch(companyProfileDto) {
    this.context.stores.companyProfile.set(DtoConverter.fromDto(companyProfileDto));
  }

  handleFetchRequestError(errorDto) {
    qconsole.log(`Failed to fetch company profile ${JSON.stringify(errorDto)}`);
  }

  handleCompanyProfile(companyProfileDto) {
    this.context.stores.companyProfile.set(DtoConverter.fromDto(companyProfileDto));
  }
}
