import { Editor, Transforms } from 'slate';
import Err from 'models/err';
import { focusFirstPlaceholder, PLACEHOLDER } from '../plugins/placeholders';
import { VARIABLE } from '../plugins/variables';

export default function validateEditor(editor) {
  const errors = [];

  let { done: hasNoPlaceholders } = Editor.nodes(editor, {
    at: [],
    match: n => {
      return n.type === PLACEHOLDER;
    },
  }).next();
  if (!hasNoPlaceholders) {
    focusFirstPlaceholder(editor);
    errors.push(new Err({ attr: 'content', code: Err.INVALID, detail: 'there are unfilled fields in the editor' }));
  }

  let { done: hasNoEmptyVariables } = Editor.nodes(editor, {
    at: [],
    match: n => {
      return n.type === VARIABLE && n.isEmpty;
    },
  }).next();
  if (!hasNoEmptyVariables) {
    errors.push(new Err({ attr: 'content', code: Err.INVALID, detail: 'there are invalid variables in the editor' }));
  }

  if (errors.length) {
    setTimeout(() => {
      Transforms.select(editor);
    }, 1);
  }

  return errors;
}
