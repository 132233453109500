import _ from 'lodash';
import classnames from 'classnames';
import connect from 'components/lib/connect';
import PropTypes from 'prop-types';
import React from 'react';

import PauseRecording from 'actions/phone_call/pause_recording';
import PhoneCall from 'models/phone_call';
import RecordingButton from 'components/phone_controls/buttons/recording_button';
import ResumeRecording from 'actions/phone_call/resume_recording';
import Tooltip from 'components/lib/deprecated_tooltip';

export function RecordingControls({
  isDisabled,
  isResponsePending,
  isTooltipDisabled,
  onPauseRecording,
  onResumeRecording,
  recordingStatus,
}) {
  const isRecordingPaused = recordingStatus === PhoneCall.RecordingStatus.PAUSED;
  let recordingClasses = classnames('phoneControls-callRecording', {
    'callRecording-button-active': !isRecordingPaused && !isDisabled,
    'callRecording-button-paused': isRecordingPaused && !isDisabled,
    disabled: isDisabled,
  });
  let tooltipMessage = isRecordingPaused ? 'Resume recording' : 'Pause recording';
  return (
    <Tooltip
      className="phoneControls-callRecording-tooltip"
      enabled={!isTooltipDisabled && !isDisabled}
      message={tooltipMessage}
      position="bottom"
    >
      <li
        className="phoneControls-button phoneControls-recordingContainer"
        onClick={isResponsePending || isDisabled ? _.noop : togglePauseRecording}
      >
        <RecordingButton contentClasses={recordingClasses} isDisabled={isDisabled} />
      </li>
    </Tooltip>
  );

  function togglePauseRecording() {
    isRecordingPaused ? onResumeRecording() : onPauseRecording();
  }
}

RecordingControls.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isResponsePending: PropTypes.bool.isRequired,
  isTooltipDisabled: PropTypes.bool.isRequired,
  onPauseRecording: PropTypes.func.isRequired,
  onResumeRecording: PropTypes.func.isRequired,
  recordingStatus: PropTypes.string.isRequired,
};

const RecordingControlsContainer = connect(mapStateToProps, mapExecuteToProps)(RecordingControls);
RecordingControlsContainer.propTypes = {
  isTooltipDisabled: PropTypes.bool.isRequired,
};
export default RecordingControlsContainer;

function mapStateToProps({ getProvider }, { isCallEnding, isTooltipDisabled }) {
  let activeCallProvider = getProvider('activeCall');
  let currentAgent = getProvider('currentAgent').get();
  let activeCall = activeCallProvider.get();
  let recordingStatus = activeCall
    ? activeCall.conversationItem.content.getLatestRecordingEventStatus()
    : PhoneCall.RecordingStatus.NOT_RECORDING;
  let agentHasCallOwnership = activeCall ? activeCall.hasCallOwnership(currentAgent.id) : false;

  return {
    isResponsePending: activeCallProvider.isPending(),
    isTooltipDisabled,
    recordingStatus,
    isDisabled: isCallEnding || !agentHasCallOwnership,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onPauseRecording: () => executeAction(PauseRecording),
    onResumeRecording: () => executeAction(ResumeRecording),
  };
}
