import _ from 'lodash';
import { AutoSizer, List } from 'react-virtualized';
import classnames from 'classnames';
import T from 'prop-types';
import React from 'react';

class LiveboardTable extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['renderRow', 'rowRenderer']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.list.forceUpdateGrid();
    }
  }

  rowRenderer({ key, index, style }) {
    const row = this.props.data[index];
    return (
      <div key={key} style={style}>
        {this.renderRow(row)}
      </div>
    );
  }

  renderRow(row) {
    return (
      <div className="liveboardTable-row" id={`liveboardTable-row-${row.id}`} key={row.id}>
        {this.props.columns.map((column, index) => this.renderColumn(row[column.fieldName], column, index))}
      </div>
    );
  }

  renderColumn(value, column, index) {
    return column.render ? (
      column.render(value, index, this.list ? this.list.offsetHeight : 0)
    ) : (
      <div className={column.className} key={`liveBoardTable-column-${index}`}>
        {value}
      </div>
    );
  }

  renderTitle(column, index) {
    return (
      <div className={column.className} key={`liveBoardTable-title-${index}`}>
        {column.title}
      </div>
    );
  }

  render() {
    let classes = classnames('liveboardTable', this.props.className);
    return (
      <div className={classes}>
        {this.props.renderHeader && this.props.renderHeader(this.props)}
        <div className="liveboardTable-list">
          {this.props.showColumnTitle && (
            <div className="liveboardTable-row-titles">{this.props.columns.map(this.renderTitle)}</div>
          )}
          <div className="liveboardTable-rows">
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className="liveboardTable-autosizer"
                  containerStyle={{ position: 'initial' }}
                  height={height}
                  ref={ref => (this.list = ref)}
                  rowCount={this.props.data.length}
                  rowHeight={40}
                  rowRenderer={this.rowRenderer}
                  sortBy={this.props.sortBy}
                  width={width}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }
}

LiveboardTable.propTypes = {
  className: T.string,
  title: T.string,
  showColumnTitle: T.bool,
  renderHeader: T.func,
  columns: T.arrayOf(
    T.shape({
      fieldName: T.string,
      title: T.string,
      render: T.func,
    })
  ),
  data: T.array.isRequired,
};

export default LiveboardTable;
