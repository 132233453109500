import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SearchInput from 'components/common/search_input';

const DEBOUNCE_INPUT_MS = 200;

function SnippetsAdminFilter({ onChangeTextFilter, textFilter }) {
  const onChange = useCallback(evt => onChangeTextFilter(evt.target.value), [onChangeTextFilter]);

  let inputProps = textFilter ? { initialValue: textFilter } : {};
  return (
    <StyledInput
      className="snippetsAdminFilter-input"
      delay={DEBOUNCE_INPUT_MS}
      onChange={onChange}
      placeholder="Find an answer"
      {...inputProps}
    />
  );
}

const StyledInput = styled(SearchInput)`
  width: 225px;
`;

SnippetsAdminFilter.propTypes = {
  onChangeTextFilter: PropTypes.func.isRequired,
  textFilter: PropTypes.string,
};

export default SnippetsAdminFilter;
