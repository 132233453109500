import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AttachmentButton from 'components/lib/attachment/attachment_button';
import Button, { ButtonTypes } from 'components/common/button';
import CircleXIcon from 'components/lib/icons/circle_x_icon';
import ErrorIcon from 'components/lib/icons/error_icon';
import connect from 'components/lib/connect';
import Modal from 'components/lib/modal';
import ModalButton, { ModalButtonDivider } from 'components/modals/modal_button';
import ModalFooter from 'components/modals/modal_footer';
import ProgressBar from 'components/common/progress_bar';
import SuccessIcon from 'components/lib/icons/success_icon';
import Upload from 'models/upload';
import UploadIcon from 'components/lib/icons/upload_icon';
import UploadUsers from 'actions/users/upload_users';
import UsersUpload from 'models/users_upload';

const troubleshootingHelpPath = 'https://help.gladly.com/docs/managing-users-and-roles#troubleshooting-your-csv-upload';
const csvFormatHelpPath = 'https://help.gladly.com/docs/managing-users-and-roles#create-a-csv-file';
class UploadCsvModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { modalState: Upload.Status.NEW, errorMessages: '' };

    _.bindAll(this, ['onAttachInputChange', 'onDownloadResults']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let errorMessages = '';
    if (nextProps.usersUploadErrors && nextProps.usersUploadErrors.length > 0) {
      errorMessages = _.map(nextProps.usersUploadErrors, 'detail').join('\n');
    }
    this.setState({ errorMessages });

    if (nextProps.usersUpload && nextProps.usersUpload.file.status && !errorMessages) {
      this.setState({ modalState: nextProps.usersUpload.file.status });
    }
  }

  render() {
    return (
      <Modal className="uploadCsvModal-wrapper">
        <div className="uploadCsvModal">
          {this.renderModalContent()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }

  renderModalContent() {
    switch (this.state.modalState) {
      case Upload.Status.COMPLETED:
        if (this.props.usersUpload.uploadResult && !this.props.usersUpload.uploadResult.errors) {
          return this.renderComplete();
        }
        return this.renderIncomplete();
      case Upload.Status.FAILED:
        return this.renderSystemError();
      case Upload.Status.STARTED:
        return <InProgressModal />;
      default:
        return this.renderNewUpload();
    }
  }

  renderComplete() {
    return (
      <div>
        <div className="uploadCsvModal-icon">
          <SuccessIcon />
        </div>
        <div className="uploadCsvModal-header uploadCsvModal-success">Upload complete</div>
      </div>
    );
  }

  renderIncomplete() {
    return (
      <div>
        <div className="uploadCsvModal-icon">
          <CircleXIcon />
        </div>
        <div className="uploadCsvModal-header">Upload incomplete</div>
        <div className="uploadCsvModal-body">
          {this.props.usersUpload.uploadResult.successRowCount}/{this.props.usersUpload.uploadResult.processedRowCount}{' '}
          users added successfully.
          <div>
            <a className="uploadCsvModal-help-link" href={troubleshootingHelpPath} rel="noreferrer" target="_blank">
              <u>Link to help article.</u>
            </a>
          </div>
          <div className="uploadCsvModal-downloadErrorsButton-wrapper">
            <Button
              buttonType={ButtonTypes.PRIMARY}
              className="uploadCsvModal-downloadErrorsButton"
              onClick={this.onDownloadResults}
            >
              Download List of Issues
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderSystemError() {
    let errorMessage = '';
    if (this.props.usersUpload.file.error.status === 400) {
      const error = JSON.parse(this.props.usersUpload.file.error.message);
      errorMessage = _.map(error.errors, 'detail').join('\n');
    }

    return (
      <div>
        <div className="uploadCsvModal-icon uploadCsvModal-error">
          <ErrorIcon />
        </div>
        <div className="uploadCsvModal-header uploadCsvModal-error">Upload error</div>
        <div className="uploadCsvModal-body">
          {errorMessage ? (
            <div>Upload failed because {errorMessage}.</div>
          ) : (
            <div>We ran into an unexpected error. Please try again later.</div>
          )}
          <div>
            <a className="uploadCsvModal-help-link" href={troubleshootingHelpPath} rel="noreferrer" target="_blank">
              <u>Link to help article.</u>
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderNewUpload() {
    const { errorMessages } = this.state;

    return (
      <div>
        <div
          className={classnames('uploadCsvModal-icon', {
            'uploadCsvModal-error': errorMessages,
          })}
        >
          {!errorMessages ? <UploadIcon className="uploadCsvModal-uploadIcon" /> : <ErrorIcon />}
        </div>
        <div
          className={classnames('uploadCsvModal-header', {
            'uploadCsvModal-error': errorMessages,
          })}
        >
          {!errorMessages ? 'CSV uploading tips' : 'Upload Error'}
        </div>
        <div className="uploadCsvModal-body">
          {!errorMessages ? (
            <div>
              Add users in bulk by uploading a CSV file of up to {UsersUpload.MAX_FILE_UPLOAD_SIZE_MB}
              MB according to
              <a className="uploadCsvModal-help-link" href={csvFormatHelpPath} rel="noreferrer" target="_blank">
                <u>this format</u>
              </a>
              .
            </div>
          ) : (
            <div>{errorMessages}</div>
          )}
        </div>
      </div>
    );
  }

  renderFooter() {
    if (this.state.modalState === Upload.Status.NEW) {
      return this.renderUploadCancelButtons();
    }
    if (this.state.modalState !== Upload.Status.STARTED) {
      return this.renderCloseButton();
    }
    return null;
  }

  renderUploadCancelButtons() {
    return (
      <ModalFooter className="uploadCsvModal-footer">
        <ModalButton className="uploadCsvModal-cancelButton" onClick={this.props.onClose}>
          Cancel
        </ModalButton>
        <ModalButtonDivider />
        <ModalAttachmentButton
          allowedFileTypes="text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          buttonText="Upload CSV File"
          labelClassName="uploadCsvModal-attachmentLabel"
          multiple={false}
          onAttach={this.onAttachInputChange}
          ref={button => (this.attachmentButton = button)}
        />
      </ModalFooter>
    );
  }

  renderCloseButton() {
    return (
      <div className="uploadCsvModal-footer">
        <ModalButton
          className="uploadCsvModal-cancelButton cancelButton-border-right-radius"
          onClick={this.props.onClose}
        >
          Close
        </ModalButton>
      </div>
    );
  }

  onAttachInputChange() {
    let attachedFiles = this.attachmentButton.input.files;

    if (attachedFiles.length === 1) {
      this.props.onStartUpload(attachedFiles[0]);
    }

    this.attachmentButton.input.value = '';
  }

  onDownloadResults() {
    let csvErrors = this.props.usersUpload.uploadResult.errors;
    if (!csvErrors) {
      return;
    }

    let decodedCsvErrors = atob(csvErrors);
    let blob = new Blob([decodedCsvErrors]);
    const timeStamp = moment().format('YYYY_MM_DD_hh_mm_ss');
    let fileName = `user_upload_errors_${timeStamp}.csv`;
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      let link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob, { type: 'text/csv' });
      link.download = fileName;
      document.body.appendChild(link);
      // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      link.click();
      document.body.removeChild(link);
    }
  }
}

UploadCsvModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onStartUpload: PropTypes.func.isRequired,
  usersUpload: PropTypes.instanceOf(UsersUpload),
  usersUploadErrors: PropTypes.arrayOf(PropTypes.object),
};

export { UploadCsvModal };

function mapExecuteToProps(executeAction, props) {
  return {
    onClose: props.onClose,
    onStartUpload: file => executeAction(UploadUsers, { file }),
  };
}

function mapStateToProps({ getProvider }) {
  return {
    usersUpload: getProvider('usersUpload').get(),
    usersUploadErrors: getProvider('usersUpload').getErrors(),
  };
}
const UploadCsvModalContainer = connect(mapStateToProps, mapExecuteToProps)(UploadCsvModal);

const ModalAttachmentButton = styled(AttachmentButton)`
  border-radius: 0;
  color: ${p => p.theme.colors.white};
  flex: 1;
  font-size: ${p => p.theme.fontSize.medium};
  height: 50px;
  &:first-child {
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-bottom-right-radius: 5px;
  }
  width: auto;
`;

export default UploadCsvModalContainer;

// Use pure component to avoid re-renders that mess up the auto-increment progress bar [ch20253]
class InProgressModal extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="uploadCsvModal-icon">
          <UploadIcon />
        </div>
        <div className="uploadCsvModal-header">Uploading...</div>
        <div className="uploadCsvModal-body">
          <ProgressBar isVisible mode="inline" />
        </div>
      </div>
    );
  }
}
