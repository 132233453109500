import { createContentModel, isEmpty } from './composition_content_shared';

const SmsCompositionContent = createContentModel({
  modelName: 'SmsCompositionContent',

  properties: {
    bodyHtml: String,
    to: String,
    from: String,
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },
});

SmsCompositionContent.MAX_TOTAL_ATTACHMENT_SIZE_MB = 5;

export default SmsCompositionContent;
