export default class RemoveToast {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    let toast = this.context.stores.toasts.findBy({ id });

    if (toast) {
      this.context.stores.toasts.remove(id);
    }
  }
}
