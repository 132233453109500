import Html from 'slate-html-serializer-old';

import { agentMentionRules } from './rules/agent_mention_rules';
import { baseRules } from './rules/base_rules';
import { fallbackRules } from './rules/fallback_rules';
import { richTextRules } from './rules/rich_text_rules';
import { tableRules } from './rules/table_rules';
import { externalVariableRules } from './rules/variable_rules';

export default function getExternalSerializer() {
  return new Html({
    rules: [
      ...baseRules,
      ...richTextRules,
      ...tableRules,
      ...externalVariableRules,
      ...agentMentionRules,
      ...fallbackRules,
    ],
  });
}
