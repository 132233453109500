import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import ServerClock from 'scripts/application/lib/server_clock';

export default class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: ServerClock.now(),
      startTime: props.startTime ? new Date(props.startTime) : ServerClock.now(),
    };
    this.updateCurrentTime = this.updateCurrentTime.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.startTime !== this.state.startTime) {
      this.setState({
        currentTime: ServerClock.now(),
        startTime: props.startTime ? new Date(props.startTime) : ServerClock.now(),
      });
      this.startTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  startTimer() {
    clearInterval(this.timerInterval);
    this.timerInterval = setInterval(this.updateCurrentTime, 1000);
  }

  updateCurrentTime() {
    this.setState({ currentTime: ServerClock.now() });
  }

  render() {
    const duration = this.state.currentTime - this.state.startTime;
    return <time className={classnames('timer', this.props.className)}>{Timer.displayDuration(duration)}</time>;
  }
}

Timer.displayDuration = function(duration) {
  let format = 'mm:ss';
  if (moment.duration(duration, 'ms').hours() > 0) {
    format = `hh:${format}`;
  }
  return moment()
    .startOf('day')
    .add(duration, 'ms')
    .format(format);
};

Timer.propTypes = {
  className: PropTypes.string,
  startTime: PropTypes.string,
};
