import _ from 'lodash';
import React, { useState, useCallback } from 'react';

import Boot from 'models/location/boot';
import connect from 'components/lib/connect';
import Floorboard from 'models/location/floorboard';
import ForgotPassword from 'models/location/forgot_password';
import registerHotkey from 'components/hotkeys/register_hotkey';
import Inbox from './sidebar_inbox';
import UserActivation from 'models/location/user_activation';
import UserLogin from 'models/location/user_login';
import UserResetPassword from 'models/location/user_reset_password';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useKeyboardShortcut from 'components/hooks/use_keyboard_shortcut';
import useWindowSize from 'components/hooks/use_window_size';
import ToggleSidebarCollapse from 'actions/inbox/agent/toggle_sidebar_collapse';

const BLACKLISTED_LOCATION_MODELS = [Boot, Floorboard, ForgotPassword, UserActivation, UserLogin, UserResetPassword];

export function Sidebar({ currentLocation, shouldCollapseSidebar }) {
  const executeAction = useExecuteAction();
  const { windowWidth } = useWindowSize();
  // Auto-collapse the customer list if the screen size is very small
  const autoCollapsed = windowWidth <= 1280;

  const [isCollapsed, setIsCollapsed] = useState(shouldCollapseSidebar);

  const toggleCollapse = useCallback(() => {
    if (!autoCollapsed) {
      setIsCollapsed(!isCollapsed);
      executeAction(ToggleSidebarCollapse, { windowWidth });
    }
  }, [autoCollapsed, isCollapsed, executeAction, windowWidth]);

  useKeyboardShortcut(toggleSidebarHotKey, toggleCollapse);

  if (_.some(BLACKLISTED_LOCATION_MODELS, locationModel => currentLocation instanceof locationModel)) {
    return null;
  }

  return <Inbox collapsed={isCollapsed || autoCollapsed} toggleCollapse={toggleCollapse} />;
}

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  return {
    currentLocation: getProvider('currentLocation').get(),
    shouldCollapseSidebar: getProvider('agentPreferences').get().shouldCollapseSidebar,
  };
}

const SidebarContainer = connect(mapStateToProps)(Sidebar);

export default SidebarContainer;

const toggleSidebarHotKey = registerHotkey({
  key: 'alt+[',
  group: 'General',
  label: 'Expand/Collapse Left Side Panel',
});
