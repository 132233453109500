import KbAdmin, { MIN_ANSWER_SEARCH_LEN } from 'models/location/kb_admin';

export default class FilterSnippetAdmin {
  constructor(context) {
    this.context = context;
  }

  run({ query, language, channel, audiences }) {
    if (query && query.length < MIN_ANSWER_SEARCH_LEN) {
      // search only if min text length is provided OR if text is empty
      // empty text clears results and shows full answer list
      return;
    }
    this.context.router.navigateTo(KbAdmin.create({ language, channel, query, audiences }));
  }
}
