import { prop } from 'models/lib/create_model';
import { createContentModel } from './composition_content_shared';

export default createContentModel({
  modelName: 'CreditCardCompositionContent',

  properties: {
    amount: prop(Number).default(null),
    memo: prop(String).default(''),
  },

  isEmpty() {
    return !this.amount;
  },
});
