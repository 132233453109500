import classnames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Conversation from 'models/conversation';
import getHighlightedText from './lib/highlight_text';
import RelativeDateTime from 'components/lib/date/relative_date_time';

const baseClass = 'customerConversationCard-conversationInfo';

export default class ConversationItemCard extends PureComponent {
  render() {
    return (
      <div className={baseClass}>
        <Body {...this.props} />
      </div>
    );
  }
}

ConversationItemCard.propTypes = {
  assignedAgentName: PropTypes.string,
  assignedGroupName: PropTypes.string.isRequired,
  text: PropTypes.string,
  topic: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  status: PropTypes.string,
  dueAt: PropTypes.string,
};

function Body({
  text,
  topic,
  topics,
  updatedBy,
  assignedGroupName,
  assignedAgentName,
  createdAt,
  updatedAt,
  dueAt,
  status,
}) {
  return (
    <div className={cn('body')}>
      <div className={cn('topRow')}>
        <Title topic={topic} topics={topics} />
        <Sla dueAt={dueAt} status={status} />
        <Status status={status} />
      </div>
      <Content text={text} />
      <Metadata
        assignedAgentName={assignedAgentName}
        assignedGroupName={assignedGroupName}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
    </div>
  );
}

Body.propTypes = ConversationItemCard.propTypes;

export function Title({ topic, topics }) {
  if (topics) {
    let content = [];
    topics.forEach((topic, pos) => {
      content.push(getHighlightedText(topic, cn('itemContent-highlight')));
      if (pos + 1 < topics.size) {
        content.push(', ');
      }
    });

    return (
      <div className={cn('title')}>
        <div className={cn('title-topics')}>{content.length > 0 ? content : 'No Topic'}</div>
      </div>
    );
  }
  let displayText = topic ? getHighlightedText(topic, cn('itemContent-highlight')) : 'No Topic';
  return <div className={cn('title')}>{displayText}</div>;
}
Title.propTypes = { topic: PropTypes.string };

function Status({ status }) {
  if (!status) {
    return null;
  }
  return <div className={cn('status')}>{status}</div>;
}

function Sla({ dueAt, status }) {
  if (!dueAt || !(status === Conversation.InboxStatus.NEW || status === Conversation.InboxStatus.OPEN)) {
    return null;
  }

  let danger =
    moment().isAfter(moment(dueAt)) ||
    moment()
      .add(5, 'minutes')
      .isAfter(moment(dueAt));
  return (
    <span className={cn('sla', { 'sla-danger': danger })}>
      Due <RelativeDateTime timestamp={dueAt} />
    </span>
  );
}

function Content({ text }) {
  return (
    <Dotdotdot clamp={3} className={cn('itemContent')}>
      {getHighlightedText(text, cn('itemContent-highlight'))}
    </Dotdotdot>
  );
}

function Metadata({ updatedAt, createdAt, assignedGroupName, assignedAgentName }) {
  return (
    <div className={cn('metadata')}>
      {updatedAt && (
        <span>
          Last updated <RelativeDateTime timestamp={updatedAt} />
        </span>
      )}
      {(assignedGroupName && getHighlightedText(`, ${assignedGroupName}`, cn('itemContent-highlight'))) || ''}
      {(assignedAgentName && getHighlightedText(`, Assigned to ${assignedAgentName}`, cn('itemContent-highlight'))) ||
        ''}
      {createdAt && (
        <span>
          , Created <RelativeDateTime timestamp={createdAt} />
        </span>
      )}
    </div>
  );
}

// like classnames, but prepends the base class to the result
function cn(...args) {
  let res = classnames(...args);
  return res
    .split(' ')
    .map(c => `${baseClass}-${c}`)
    .join(' ');
}
