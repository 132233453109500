import moment from 'moment';

const MINUTES_IN_DAY = 1440;
const MINUTES_IN_ALMOST_TWO_DAYS = 2520;
const MINUTES_IN_MONTH = 43200;

export default function formatRelativeShort(timestamp) {
  const now = moment();
  const then = moment(timestamp);

  const minutes = now.diff(then, 'minutes');

  // 0 mins
  if (minutes === 0) {
    return 'now';
    // 1 mins up to 0.75 hrs
  } else if (minutes < 45) {
    return `${minutes}m`;
    // 0.75 hrs up to 24 hrs
  } else if (minutes < MINUTES_IN_DAY) {
    const hours = Math.round(minutes / 60);
    return `${hours}hr`;
    // 1 day up to 1.75 days
  } else if (minutes < MINUTES_IN_ALMOST_TWO_DAYS) {
    return `1d`;
    // 1.75 days up to 30 days
  } else if (minutes < MINUTES_IN_MONTH) {
    const days = Math.round(minutes / MINUTES_IN_DAY);
    return `${days}d`;
  }

  const months = now.diff(then, 'months');

  // 1 months up to 12 months
  if (months < 12) {
    const nearestMonth = Math.round(minutes / MINUTES_IN_MONTH);
    return `${nearestMonth}mo`;
  }

  // 1 year and up
  const years = Math.floor(months / 12);
  return `${years}yr`;
}
