/**
 * Helper function that takes in an HTML string and strips out <body> and <html> tags that it may have.
 * This is useful for dealing with incoming HTML, especially emails sent from HTML-enabled clients (Gmail)
 *
 * @param {string} htmlContent
 * @return {string}
 */
export function removeBodyHtmlTag(htmlContent) {
  if (!htmlContent) return '';

  const container = document.createElement('div');
  container.innerHTML = htmlContent;
  return container.innerHTML;
}
