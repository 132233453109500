import _ from 'lodash';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import Observable from 'scripts/lib/observable_mixin';

const DEFAULT_TIMEOUT = 5000; // 5s

export default class CustomerMatchGateway {
  constructor(http, requestorId) {
    this.http = http;
    this.requestorId = requestorId;
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  url(phone) {
    let url = `/api/v1/orgs/${this.orgId}/customer-profile-matches`;
    if (phone) {
      url = url + `?phone=${encodeURIComponent(phone)}`;
    }
    return url;
  }

  fetch(phone, customerId) {
    const correlationId = IdGenerator.newId();
    this.http.get(this.url(phone), this.onFetchResponse.bind(this, customerId), {
      timeout: DEFAULT_TIMEOUT,
      headers: { 'Gladly-Correlation-Id': correlationId, 'Gladly-Requestor-Id': this.requestorId },
    });

    return { correlationId };
  }

  onFetchResponse(customerId, err, res) {
    if (err != null) {
      this.notifyObservers('handleFetchCustomerMatchError', err);
    } else if (res.status !== 200) {
      this.notifyObservers('handleFetchCustomerMatchError', res.response);
    } else {
      this.notifyObservers('handleFetchCustomerMatchSuccess', res.response, customerId);
    }
  }
}

_.extend(CustomerMatchGateway.prototype, Observable);
