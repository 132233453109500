import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';

export default class TrackTransactionImageLoad {
  constructor(context) {
    this.context = context;
  }

  run({ hasError }) {
    const currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation.customerId;
    const conversationsStore = this.context.stores.conversations;
    const conversationId = conversationsStore && getActiveConversationId(conversationsStore);

    this.context.analytics.track('Transaction Image Loaded', { hasError, customerId, conversationId });
  }
}
