import React from 'react';
import PropTypes from 'prop-types';

import ConversationItem from 'models/conversation_item';
import ItemPreviewText from 'components/lib/item_preview_text';

function ConversationItemPreview({ conversationItem, hasTopic }) {
  if (!conversationItem) {
    return null;
  }

  return (
    <div className="preview">
      <ItemPreviewText conversationItem={conversationItem} hasTopic={hasTopic} />
    </div>
  );
}

ConversationItemPreview.propTypes = {
  conversationItem: PropTypes.instanceOf(ConversationItem),
  hasTopic: PropTypes.bool,
};

export default ConversationItemPreview;
