import _ from 'lodash';
import Auth from 'models/auth';
import Boot from 'models/location/boot';
import cookieJar from 'scripts/application/lib/cookie_jar';
import LogIn from 'actions/user/log_in';
import qconsole from 'scripts/lib/qconsole';

const IGNORED_ROUTES = ['/user/login', '/user/activate', '/user/forgot-password', '/user/reset-password'];

export default function(router, context) {
  return () => {
    let auth = context.stores.auth.get();
    let currentPath = router.getPath();
    let currentLocation = context.stores.currentLocation.get();

    if (currentLocation instanceof Boot && !currentLocation.isCompleted) {
      if (auth.isLoggedIn()) {
        updateBootLocation(context, currentLocation, { requestedPath: currentPath });
        context.executeAction(LogIn, { claims: auth.claims });

        return false;
      }

      let desktopToken = cookieJar.get('desktopToken');
      if (desktopToken) {
        try {
          let claims = JSON.parse(atob(desktopToken));
          if (Auth.create({ claims }).isLoggedIn()) {
            updateBootLocation(context, currentLocation, { requestedPath: currentPath, isFromSso: true });
            context.executeAction(LogIn, { claims });

            return false;
          }
        } catch (e) {
          qconsole.error(`Could not parse desktopToken cookie [${desktopToken}]`);
        }
      }
    }

    if (_.some(IGNORED_ROUTES, route => _.startsWith(currentPath, route))) {
      return true;
    }

    if (!auth.isLoggedIn()) {
      if (currentLocation instanceof Boot) {
        updateBootLocation(context, currentLocation, { requestedPath: currentPath });
      }
      router.navigateToUrl('/user/login');
      return false;
    }

    return true;
  };
}

function updateBootLocation(context, bootLocation, attrs) {
  context.stores.currentLocation.set(Boot.create({ ...bootLocation, ...attrs }));
}
