import _ from 'lodash';

import { after, HEADER_CORRELATION_ID } from 'scripts/infrastructure/backends/http_client';
import HttpResponseHandler from 'scripts/adapters/gateways/lib/http_response_handler';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import mixin from 'scripts/lib/mixin';
import Observable from 'scripts/lib/observable_mixin';

export default class FeatureSetGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
    _.bindAll(this, ['fetch', 'handleBroadcast']);
  }

  init({ orgId }) {
    this.orgId = orgId;
  }

  get _httpClient() {
    return this.backend.axios();
  }

  get broadcastTopic() {
    return `v1/orgs/${this.orgId}/feature-set`;
  }

  request() {
    this._isSubscribed = true;
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast, this.fetch);
  }

  handleBroadcast({ payload }) {
    this.notifyObservers('handleFeatureSet', payload);
  }

  fetch() {
    const correlationId = IdGenerator.newId();
    const requestPromise = after(
      this._httpClient.get(`/api/v1/orgs/${this.orgId}/feature-set`, {
        headers: { [HEADER_CORRELATION_ID]: correlationId },
      }),
      this.handleResponse.bind(this, { requestName: 'fetch', correlationId })
    );

    return { correlationId, requestPromise };
  }

  reconnectHandler() {
    if (this._isSubscribed) {
      this.request();
    }
  }
}

mixin(FeatureSetGateway.prototype, Observable);
mixin(FeatureSetGateway.prototype, HttpResponseHandler.prototype);
