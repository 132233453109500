import React from 'react';
import PropTypes from 'prop-types';

import ConversationNotificationContent from './conversation_notification_content';
import DismissNotificationButtonContainer from './dismiss_notification_button';
import Notification from './notification';

function NotificationContent({
  body,
  containerClasses,
  contentClasses,
  heading,
  icon,
  iconClass,
  itemType,
  notificationId,
  onClick,
  onMouseEnter,
  onMouseLeave,
  title,
}) {
  return (
    <Notification
      containerClasses={containerClasses}
      contentClasses={contentClasses}
      key={`notification-${notificationId}`}
      notificationId={notificationId}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ConversationNotificationContent
        body={body}
        heading={heading}
        icon={icon}
        iconClass={iconClass}
        itemType={itemType}
        title={title}
      />
      <DismissNotificationButtonContainer buttonText="Save For Later" notificationId={notificationId} />
    </Notification>
  );
}

NotificationContent.propTypes = {
  body: PropTypes.string,
  containerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  title: PropTypes.string,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconClass: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  notificationId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default NotificationContent;
