import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import { AdminView, AdminHeader, AdminTitle } from '../lib/admin_layout';
import AdminFilterBar from 'components/admin/lib/admin_filter_bar';
import VariablesAdminListContainer from './variables_admin_list_container';

const VariablesAdmin = createReactClass({
  propTypes: {
    renderSwitch: PropTypes.func.isRequired,
  },

  getInitialState() {
    return { textFilter: '' };
  },

  onChangeTextFilter(textFilter) {
    this.setState({ textFilter });
  },

  render() {
    return (
      <AdminView className="knowledgeBaseAdmin">
        <AdminHeader className="knowledgeBaseAdmin-header">
          <AdminTitle className="knowledgeBaseAdmin-header-title">Variables</AdminTitle>
        </AdminHeader>
        {this.props.renderSwitch()}
        <AdminFilterBar onChangeTextFilter={this.onChangeTextFilter} />
        <VariablesAdminListContainer textFilter={this.state.textFilter} />
      </AdminView>
    );
  },
});

export default VariablesAdmin;
