import _ from 'lodash';

import { default as SendCustomChannelItem } from 'actions/composition/send_custom_channel_message';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class SendCustomChannelMessage {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, content }) {
    const composition = getCompositionsStore(this.context).findBy({ id: compositionId });
    if (!composition) {
      return;
    }

    const compositionContent = composition.toJs().content;
    _.forEach(content, (html, attr) => {
      compositionContent[attr] = html;
    });

    compositionContent.bodyPlain = content.body;

    this.context.executeAction(SendCustomChannelItem, {
      compositionId,
      content: compositionContent,
    });
  }
}
