import _ from 'lodash';
import styled from 'styled-components';
import React from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import ModalCard from 'components/common/modal_card';
import PropTypes from 'prop-types';
import Spinner from 'components/common/spinner';
import createEnum from 'scripts/lib/create_enum';

export const UnlinkProfileFieldTypes = createEnum('BOTH', 'REMAIN', 'REMOVE');
const REMOVE_AND_REMAIN = 'Unlinking will remove some fields automatically.';
const REMOVE_ONLY = 'Unlinking will remove these fields automatically.';
const REMAIN_ONLY = 'Unlinking will not remove these fields automatically.';

export class CustomerUnlinkModal extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, ['unlinkCustomer']);
  }

  renderBody(option) {
    switch (option) {
      case UnlinkProfileFieldTypes.BOTH:
        return <div>{REMOVE_AND_REMAIN}</div>;
      case UnlinkProfileFieldTypes.REMAIN:
        return <div>{REMAIN_ONLY}</div>;
      case UnlinkProfileFieldTypes.REMOVE:
        return <div>{REMOVE_ONLY}</div>;

      default:
        return null;
    }
  }

  renderCards(option, removeItems, remainItems) {
    switch (option) {
      case UnlinkProfileFieldTypes.BOTH:
        return this.renderBothCards(removeItems, remainItems);
      case UnlinkProfileFieldTypes.REMAIN:
        return this.renderSingleCard(remainItems);
      case UnlinkProfileFieldTypes.REMOVE:
        return this.renderSingleCard(removeItems);

      default:
        return null;
    }
  }

  renderBothCards(removeItems, remainItems) {
    return (
      <React.Fragment>
        <StyledCardLeft>
          <StyledCardTitle>Fields that will be removed</StyledCardTitle>
          <StyledCardList data-aid="card-list-items-remove">{removeItems}</StyledCardList>
        </StyledCardLeft>
        <StyledCardRight>
          <StyledCardTitle>Fields that will remain</StyledCardTitle>
          <StyledCardList data-aid="card-list-items-remain">{remainItems}</StyledCardList>
        </StyledCardRight>
      </React.Fragment>
    );
  }

  renderSingleCard(items) {
    return (
      <StyledCardSingle>
        <StyledCardList data-aid="card-list-items">{items}</StyledCardList>
      </StyledCardSingle>
    );
  }

  render() {
    const { onClose, fieldsToRemove, fieldsToRemain, isUnlinkPending } = this.props;

    const modalOption = this.getUnlinkProfileFieldTypes(fieldsToRemove, fieldsToRemain);

    let listRemoveItems = null;
    let listRemainItems = null;

    if (_.isArray(fieldsToRemove)) {
      listRemoveItems = fieldsToRemove.map(f => (
        <StyledListItem data-aid={`card-item-remove-${f}`} key={f}>
          {f}
        </StyledListItem>
      ));
    }

    if (_.isArray(fieldsToRemain)) {
      listRemainItems = fieldsToRemain.map(f => (
        <StyledListItem data-aid={`card-item-remain-${f}`} key={f}>
          {f}
        </StyledListItem>
      ));
    }

    return (
      <StyledModalCard data-aid="modal-card" onClose={onClose}>
        <StyledModalHeader>Unlink Customer</StyledModalHeader>
        <StyledModalBody data-aid="modal-body">
          <div>This customer is linked to a profile on an external system.</div>
          {this.renderBody(modalOption)}
        </StyledModalBody>
        <StyledCardsContainer>{this.renderCards(modalOption, listRemoveItems, listRemainItems)}</StyledCardsContainer>
        <StyledActionsContainer>
          <StyledActionCancelButton
            blurOnClick
            buttonType={ButtonTypes.SECONDARY}
            data-aid="action-cancel"
            disabled={isUnlinkPending}
            onClick={isUnlinkPending ? _.noop : onClose}
          >
            Cancel
          </StyledActionCancelButton>
          <StyledActionButton
            blurOnClick
            buttonType={ButtonTypes.PRIMARY}
            data-aid="action-unlink"
            onClick={isUnlinkPending ? _.noop : this.unlinkCustomer}
          >
            {this.renderLoadingSpinner(isUnlinkPending)}
          </StyledActionButton>
        </StyledActionsContainer>
      </StyledModalCard>
    );
  }

  getUnlinkProfileFieldTypes(fieldsToRemove, fieldsToRemain) {
    if (!_.isEmpty(fieldsToRemove) && !_.isEmpty(fieldsToRemain)) {
      return UnlinkProfileFieldTypes.BOTH;
    } else if (!_.isEmpty(fieldsToRemove)) {
      return UnlinkProfileFieldTypes.REMOVE;
    } else {
      return UnlinkProfileFieldTypes.REMAIN;
    }
  }

  unlinkCustomer() {
    this.props.onUnlink({
      customerId: this.props.customerId,
      totalLinkCount: this.props.totalLinkCount,
    });
  }

  renderLoadingSpinner(isUnlinkPending) {
    if (!isUnlinkPending) {
      return 'Unlink';
    }

    return (
      <StyledSpinnerContainer data-aid="customerUnlinkModal-spinner">
        <Spinner color="#FFF" stroke={1} />
      </StyledSpinnerContainer>
    );
  }
}

const StyledSpinnerContainer = styled.div`
  width: 16px;
  display: inline-block;
`;

const StyledCard = styled.div`
  align-items: center;
  vertical-align: top;
  width: 180px;
`;

const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  list-style-position: inside;
  max-height: 110px;
  overflow: scroll;
  margin: ${p => p.theme.fontSize.large} 0px;
`;

const StyledCardLeft = styled(StyledCard)`
  margin-right: ${p => p.theme.spacing.medium};
`;

const StyledCardRight = styled(StyledCard)`
  margin-left: ${p => p.theme.spacing.medium};
`;

const StyledCardSingle = styled.div`
  margin-block-start: 0;
  width: 330px;
`;

const StyledCardTitle = styled.div``;

const StyledCardList = styled.ul`
  display: flex;
  flex-flow: column;
  padding: 0;
`;

const StyledListItem = styled.li`
  text-transform: capitalize;
`;

const StyledModalHeader = styled.div`
  font-size: ${p => p.theme.fontSize.medium};
  font-weight: bold;
  margin-bottom: ${p => p.theme.spacing.small};
`;

const StyledModalBody = styled.div``;

const StyledActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledModalCard = styled(ModalCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 604px;
`;

const StyledActionButton = styled(Button)`
  width: 100px;
`;

const StyledActionCancelButton = styled(StyledActionButton)`
  margin-right: 8px;
`;

CustomerUnlinkModal.propTypes = {
  customerId: PropTypes.string,
  fieldsToRemove: PropTypes.arrayOf(PropTypes.string),
  fieldsToRemain: PropTypes.arrayOf(PropTypes.string),
  isUnlinkPending: PropTypes.bool,
  totalLinkCount: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
};
