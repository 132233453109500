import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class CustomerNoteService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(attrs, callback, path, { orgId, customerId }) {
    let customer = _.find(this.getDatabase(orgId).customers, { id: customerId });

    if (!customer) {
      callback(null, { status: 404, statusText: statusText(404) });
      return;
    }

    callback(null, { status: 200, statusText: statusText(200), response: _.extend(customer.note, { id: customerId }) });
  }

  update(note, callback, path, { orgId, customerId }) {
    let customer = _.find(this.getDatabase(orgId).customers, { id: customerId });
    customer.note = note;

    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customer-notes/:customerId': {
          GET: this.find,
          PATCH: this.update,
        },
      },
      this
    );
  }
}
