import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export class ConversationTopicSelectionCollapser extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, 'handleMouseEnter');
  }

  handleMouseEnter() {
    this.props.onMouseEnter(this.props.option);
  }

  render() {
    let className = classnames('conversationTopic-collapser', {
      'conversationTopic-collapser-focused': this.props.isFocused,
    });
    return (
      <div className={className} onMouseDown={this.props.onClick} onMouseEnter={this.handleMouseEnter}>
        Show Fewer
        <i className="conversationTopic-chevron fa fa-chevron-up" />
      </div>
    );
  }
}

ConversationTopicSelectionCollapser.propTypes = {
  isFocused: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: PropTypes.object,
};

export const ConversationTopicSelectionDivider = function() {
  return <div className="conversationTopic-divider" />;
};

export const ConversationTopicGroupTitle = function({ label }) {
  return <div className="conversationTopic-groupTitle searchResults-header-font">{label}</div>;
};

ConversationTopicGroupTitle.propTypes = {
  label: PropTypes.string,
};

export class ConversationTopicSelectionExpander extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, 'handleMouseEnter');
  }

  handleMouseEnter() {
    this.props.onMouseEnter(this.props.option);
  }

  render() {
    let className = classnames('conversationTopic-expander', {
      'conversationTopic-expander-focused': this.props.isFocused,
    });
    return (
      <div className={className} onMouseDown={this.props.onClick} onMouseEnter={this.handleMouseEnter}>
        {`+${this.props.numSelections} more`}
        <i className="conversationTopic-chevron fa fa-chevron-down" />
      </div>
    );
  }
}

ConversationTopicSelectionExpander.propTypes = {
  isFocused: PropTypes.bool,
  numSelections: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: PropTypes.object,
};

export class ConversationTopicMenuHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasChangedCount: false };
    this.timeout = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // blink the count when it changes
    if (nextProps.numSelections !== this.props.numSelections) {
      this.setState({ hasChangedCount: true });
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({ hasChangedCount: false });
      }, 1000);
    }
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  render() {
    let props = this.props;

    let unitLabel = props.unitLabelSingular;
    if (props.numSelections !== 1) {
      unitLabel = props.unitLabelPlural ? props.unitLabelPlural : `${props.unitLabelSingular}s`;
    }

    return (
      <div
        className={classnames('conversationTopic-header', {
          'conversationTopic-header-changed': this.state.hasChangedCount,
        })}
        onMouseDown={props.onClick}
      >
        {`${props.numSelections} ${unitLabel} selected`}
      </div>
    );
  }
}

ConversationTopicMenuHeader.propTypes = {
  numSelections: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
};

export class ConversationTopicMenuOption extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, 'handleClick', 'handleMouseEnter');
  }

  handleClick(ev) {
    this.props.onClick(ev, this.props.option);
  }

  handleMouseEnter() {
    this.props.onMouseEnter(this.props.option);
  }

  render() {
    let isSelected = this.props.option.selected;
    let isFocused = this.props.isFocused;
    let isSuggested = this.props.option.suggested;
    let className = classnames('conversationTopic-option', {
      'conversationTopic-option-focused': isFocused,
      'conversationTopic-option-selected': isSelected,
      'conversationTopic-option-suggested': isSuggested,
      'conversationTopic-option-selected-focused': isSelected && isFocused,
    });
    let iconClasses = classnames('conversationTopic-option-icon', {
      'conversationTopic-option-unselect': isFocused,
      'conversationTopic-option-select': !isFocused,
    });
    return (
      <div className={className} onMouseDown={this.handleClick} onMouseEnter={this.handleMouseEnter}>
        <div className="conversationTopic-option-label">{this.props.option.label}</div>
        {isSelected && <div className={iconClasses}>{isFocused && '×'}</div>}
      </div>
    );
  }
}

ConversationTopicMenuOption.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: PropTypes.object,
};
