import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier, SelectionState } from 'draft-js';

import { findEntities, isEntitySelected, removeEntity } from './draft_entity';
import { getTextSelection } from './draft_selection';
import { ensureFullSelection, MUTABLE } from './draft_shared';

const DraftPlaceholder = props => <span className="draftEditor-placeholder">{props.children}</span>;

DraftPlaceholder.propTypes = {
  children: PropTypes.node,
};
export default DraftPlaceholder;

export const ENTITY_TYPE_PLACEHOLDER = 'PLACEHOLDER';
export const findPlaceholderEntities = findEntities(ENTITY_TYPE_PLACEHOLDER);

export function togglePlaceholder(editorState) {
  let isPlaceholderSelected = isEntitySelected(editorState, ENTITY_TYPE_PLACEHOLDER);
  return isPlaceholderSelected
    ? removeEntity(editorState, ENTITY_TYPE_PLACEHOLDER)
    : insertPlaceholderAtSelection(editorState, editorState.getSelection());
}

export function insertPlaceholderAtSelection(editorState, selection) {
  const selectionText = getTextSelection(editorState.getCurrentContent(), selection).trim();
  if (selectionText === '') {
    return editorState;
  }
  // If we're inserting a placeholder, we're in snippet editing mode, which means we want to be able to edit
  // the placeholder values. When we insert the snippet later, the placeholder will be IMMUTABLE (see
  // draft_convert.js for where this happens).
  let contentState = editorState.getCurrentContent();
  let contentStateWithEntity = contentState.createEntity(ENTITY_TYPE_PLACEHOLDER, MUTABLE);
  contentStateWithEntity = Modifier.applyEntity(
    contentStateWithEntity,
    selection,
    contentStateWithEntity.getLastCreatedEntityKey()
  );

  return EditorState.push(editorState, contentStateWithEntity, 'apply-entity');
}

export function findNextPlaceholderSelection(editorState) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const ranges = findNextPlaceholderRanges(
    contentState,
    selection.getEndKey(),
    selection.getEndOffset(),
    editorState.getCurrentContent().getBlocksAsArray()
  );

  if (!ranges.length) {
    return null;
  }

  const range = ranges[0];
  const key = range.block.getKey();
  return new SelectionState({
    anchorKey: key,
    focusKey: key,
    anchorOffset: range.start,
    focusOffset: range.end,
    isBackward: false,
  });
}

export function findNextPlaceholderRanges(contentState, startBlockKey, offsetInStartingBlock, blocks) {
  const filteredBlocks = _.dropWhile(blocks, block => block.getKey() !== startBlockKey);

  const nextPlaceholders = [];
  _.forEach(filteredBlocks, block => {
    const getNextPlaceholder = (start, end) => {
      if (block.getKey() !== startBlockKey || start >= offsetInStartingBlock) {
        nextPlaceholders.push({ start, end, block });
      }
    };
    findPlaceholderEntities(block, getNextPlaceholder, contentState);
  });

  return nextPlaceholders;
}

export function ensureFullPlaceholderSelection(editorState) {
  return ensureFullSelection(editorState, ENTITY_TYPE_PLACEHOLDER, findPlaceholderEntities, true, true);
}

// Specifically for forcing the selection to the first placeholder of a fragment inserted into an editor state.
// If there are no placeholders in the given fragment, it does not change the selection.
export function forceSelectionToFirstPlaceholder(preInsertedSelection, insertedEditorState, fragmentEditorState) {
  const insertedBlockArray = insertedEditorState.getCurrentContent().getBlocksAsArray();

  // Even after inserting the fragment, the block we inserted the fragment into retains the same key.
  const firstBlockKey = preInsertedSelection.getStartKey();
  const firstBlockIndex = _.findIndex(insertedBlockArray, block => block.getKey() === firstBlockKey);

  // Get all the blocks that we touched or inserted with the fragment. Zero block diff means ALL the blocks are fragment blocks.
  const fragmentBlocksInInsertedState = insertedBlockArray.slice(
    firstBlockIndex,
    firstBlockIndex + fragmentEditorState.getCurrentContent().getBlocksAsArray().length
  );

  // Then find the placeholder range only in those inserted blocks starting from the inserted selection offset
  const insertedContentState = insertedEditorState.getCurrentContent();
  const placeholderRanges = findNextPlaceholderRanges(
    insertedContentState,
    firstBlockKey,
    preInsertedSelection.getStartOffset(),
    fragmentBlocksInInsertedState
  );

  const selection = getFirstPlaceholderSelection(placeholderRanges);

  if (!selection) {
    return insertedEditorState;
  }

  return EditorState.forceSelection(insertedEditorState, selection);
}

export function getPlaceholderSelection(editorState) {
  const blockArray = editorState.getCurrentContent().getBlocksAsArray();
  const firstBlockKey = blockArray[0].getKey();
  const contentState = editorState.getCurrentContent();
  const placeholderRanges = findNextPlaceholderRanges(contentState, firstBlockKey, 0, blockArray);
  return getFirstPlaceholderSelection(placeholderRanges);
}

function getFirstPlaceholderSelection(placeholderRanges) {
  if (!placeholderRanges.length) {
    return null;
  }

  const placeholderRange = placeholderRanges[0];
  const key = placeholderRange.block.getKey();
  return new SelectionState({
    anchorKey: key,
    focusKey: key,
    anchorOffset: placeholderRange.start,
    focusOffset: placeholderRange.end,
    isBackward: false,
  });
}
