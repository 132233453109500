import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'Err', // to avoid conflict with JavaScript built-in `Error` class

  properties: {
    // The attribute that the error is associated with. This is a path to the attribute, e.g. `phones.0.phoneNumber`.
    attr: String,
    code: String,
    // Intended for API users, not for end users. These are neither localized nor localizable. Prefer using attr (+code) (+meta) to
    // construct human-readable messages for display.
    detail: String,
    meta: prop(Object).default({}),
  },

  statics: {
    Code: Object.freeze({
      BLANK: 'blank',
      INVALID: 'invalid',
      INVALID_STATE: 'invalid_state',
      MOVED: 'moved',
      NOT_EXIST: 'not_exist',
      OPERATION_FAILED: 'operation_failed',
      TAKEN: 'taken',
      TOO_SHORT: 'too_short',
      TOO_LONG: 'too_long',
      NOT_A_NUMBER: 'not_a_number',
      GREATER_THAN: 'greater_than',
      LESS_THAN: 'less_than',
      LESS_THAN_OR_EQUAL_TO: 'less_than_or_equal_to',
      IN_USE: 'in_use',
      UNEXPECTED_ERROR: 'unexpected_error',
      VERSION_OUTDATED: 'version_outdated',
    }),
  },
});
