import React, { useContext } from 'react';
import VoiceFillIcon from 'components/lib/icons/voice_fill_icon';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function VoiceRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.phone;
  const callSla = sla.phone;
  return (
    <FloorboardRow
      activeItems={stats.currentActiveCalls}
      activeLabel="Active Calls"
      currentWait={stats.currentWait}
      icon={<VoiceFillIcon />}
      newItems={stats.currentNewCalls}
      newLabel="New Calls"
      sla={callSla}
      waitLabel="Current Wait"
    />
  );
}
