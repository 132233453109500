import _ from 'lodash';

import { DomainError } from 'scripts/application/lib/error';
import Err from 'models/err';
import { getValidationErrors } from 'models/phone_number';
import UpdateProfileActionBase from './update_profile_action_base';
import analytics from 'scripts/lib/analytics';

export default class AddOrUpdatePhoneNumber extends UpdateProfileActionBase {
  _update(profile, { index, phoneNumber, extension, primary, smsPreference, type, source }) {
    let eventName;
    let eventAttributes;
    if (index === profile.phones.length || index == null) {
      eventName = 'Customer Phone Number Added';
      eventAttributes = {
        customerId: profile.id,
        index: profile.phones.length,
        isPrimary: primary,
        smsPreference,
        source,
        type,
      };
      profile.addPhoneNumber({ index, phoneNumber, extension, primary, smsPreference, type });
    } else {
      eventName = 'Customer Phone Number Updated';
      let previousPhoneNumber = profile.phones[index];
      eventAttributes = {
        customerId: profile.id,
        index,
        source,
        previousIsPrimary: previousPhoneNumber.primary,
        previousSmsPreference: previousPhoneNumber.smsPreference,
        previousType: previousPhoneNumber.type,
        isPrimary: primary,
        smsPreference,
        type,
      };
      profile.updatePhoneNumber({ index, phoneNumber, extension, primary, smsPreference, type });
    }
    analytics.track(eventName, eventAttributes);

    return _.pick(profile, 'phones');
  }

  _getValidationErrors(attrs) {
    const { index } = attrs;
    const errs = getValidationErrors(attrs);

    return errs.map(
      err =>
        new Err({
          code: err.code,
          attr: `phones.${index}.${err.attr}`,
          detail: err.detail,
        })
    );
  }

  /**
   * Check the response and see if the error is due to a phone number conflict
   *
   * @param err
   * @param requestData
   * @returns {boolean}
   * @private
   */
  _shouldIgnoreResponseError(err, requestData) {
    if (err instanceof DomainError) {
      // Go through the nested errors and make sure we only see errors related to phone number conflicts
      const nestedErrors = err.errors || [];
      return nestedErrors.reduce((acc, err) => {
        const errorCode = _.get(err, 'code', '');
        const errorAttr = _.get(err, 'attr', '');

        return acc && errorCode === Err.Code.TAKEN && errorAttr.startsWith('phones.');
      }, true);
    }

    return super._shouldIgnoreResponseError(err, requestData);
  }
}
