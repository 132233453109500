import _ from 'lodash';
import ReportHelpers from './report_helpers';
import Report, { ReportType } from './report';

export default class SummaryReport extends Report {
  getDataForSummaryDayAndCategory({ days }) {
    let dataByDay = _.fromPairs(
      _.map(days, date => {
        return [date, {}];
      })
    );

    _.mapKeys(this.data, (series, seriesName) => {
      if (seriesName === 'activeHandleTimes') {
        _.map(series, item => {
          _.map(item, channelItem => {
            let day = channelItem.day || channelItem.time;
            const date = ReportHelpers.getDate(day).format('YYYY-MM-DD');

            switch (channelItem.channel) {
              case 'CHAT_SESSION':
                if (date in dataByDay) {
                  dataByDay[date]['chatActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'EMAIL':
                if (date in dataByDay) {
                  dataByDay[date]['emailActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'VOICEMAIL':
                if (date in dataByDay) {
                  dataByDay[date]['voicemailActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'FB_MESSAGE_INCOMING':
                if (date in dataByDay) {
                  dataByDay[date]['fbMessengerActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'SMS':
                if (date in dataByDay) {
                  dataByDay[date]['smsActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'PHONE_CALL':
                if (date in dataByDay) {
                  dataByDay[date]['voiceActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'INSTAGRAM_DIRECT':
                if (date in dataByDay) {
                  dataByDay[date]['instagramDirectActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'TWITTER':
                if (date in dataByDay) {
                  dataByDay[date]['twitterActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
              case 'WHATSAPP':
                if (date in dataByDay) {
                  dataByDay[date]['whatsappActiveHandleTime'] = _.pick(channelItem, ['average', 'count']);
                }
                break;
            }
          });
        });
        return;
      }
      if (seriesName === 'conversationMessagesByDay') {
        let messageData = _.omit(series, 'channel');
        messageData.incomingMessages.forEach(countByDay => {
          let day = countByDay.day;
          let date = ReportHelpers.getDate(day).format('YYYY-MM-DD');
          dataByDay[date]['incomingMessagesByDay'] = countByDay.count;
        });
        messageData.outgoingMessages.forEach(countByDay => {
          let day = countByDay.day;
          let date = ReportHelpers.getDate(day).format('YYYY-MM-DD');
          dataByDay[date]['outgoingMessagesByDay'] = countByDay.count;
        });
      }

      _.map(series, item => {
        let day = item.day || item.time;
        const date = ReportHelpers.getDate(day).format('YYYY-MM-DD');
        if (date in dataByDay) {
          dataByDay[date][seriesName] = _.omit(item, ['day', 'time']);
        }
      });
    });

    return dataByDay;
  }

  init({ days }) {
    this.dataByDay = this.getDataForSummaryDayAndCategory({ days });

    return {
      timezone: this.timezone,
      getDataForDayAndMetric: (date, metric) => {
        let dataForDate = this.dataByDay[date] || {};
        let rawData = dataForDate[metric] || {};

        const incomingCalls = dataForDate.incomingCallsByDay || {};
        const outgoingCalls = dataForDate.outgoingCallsByDay || {};

        const chatSessions = dataForDate.chatSessionsByDay || {};

        const incomingDuration = incomingCalls.duration || 0;
        const incomingCount = incomingCalls.count || 0;
        const incomingAnswered = incomingCalls.answered || 0;

        const outgoingDuration = outgoingCalls.duration || 0;
        const outgoingAnswered = outgoingCalls.answered || 0;

        const chatSessionCount = chatSessions.count || 0;
        const chatSessionAnswered = chatSessions.answered || 0;
        const chatDuration = chatSessions.duration || 0;

        const abandonedCount = incomingCount - incomingAnswered;

        const closedConversations = (dataForDate.closedConversationsByDay || {}).count || 0;

        switch (metric) {
          case 'voiceActiveHandleTime':
            return dataForDate.voiceActiveHandleTime && dataForDate.voiceActiveHandleTime.average;
          case 'closedVoiceConversations':
            return dataForDate.voiceActiveHandleTime && dataForDate.voiceActiveHandleTime.count;
          case 'chatActiveHandleTime':
            return dataForDate.chatActiveHandleTime && dataForDate.chatActiveHandleTime.average;
          case 'closedChatConversations':
            return dataForDate.chatActiveHandleTime && dataForDate.chatActiveHandleTime.count;
          case 'voicemailActiveHandleTime':
            return dataForDate.voicemailActiveHandleTime && dataForDate.voicemailActiveHandleTime.average;
          case 'closedVoicemailConversations':
            return dataForDate.voicemailActiveHandleTime && dataForDate.voicemailActiveHandleTime.count;
          case 'smsActiveHandleTime':
            return dataForDate.smsActiveHandleTime && dataForDate.smsActiveHandleTime.average;
          case 'closedSmsConversations':
            return dataForDate.smsActiveHandleTime && dataForDate.smsActiveHandleTime.count;
          case 'fbMessengerActiveHandleTime':
            return dataForDate.fbMessengerActiveHandleTime && dataForDate.fbMessengerActiveHandleTime.average;
          case 'closedFbMessengerConversations':
            return dataForDate.fbMessengerActiveHandleTime && dataForDate.fbMessengerActiveHandleTime.count;
          case 'instagramDirectActiveHandleTime':
            return dataForDate.instagramDirectActiveHandleTime && dataForDate.instagramDirectActiveHandleTime.average;
          case 'closedInstagramDirectConversations':
            return dataForDate.instagramDirectActiveHandleTime && dataForDate.instagramDirectActiveHandleTime.count;
          case 'twitterActiveHandleTime':
            return dataForDate.twitterActiveHandleTime && dataForDate.twitterActiveHandleTime.average;
          case 'closedTwitterConversations':
            return dataForDate.twitterActiveHandleTime && dataForDate.twitterActiveHandleTime.count;
          case 'whatsappActiveHandleTime':
            return dataForDate.whatsappActiveHandleTime && dataForDate.whatsappActiveHandleTime.average;
          case 'closedWhatsappConversations':
            return dataForDate.whatsappActiveHandleTime && dataForDate.whatsappActiveHandleTime.count;
          case 'emailActiveHandleTime':
            return dataForDate.emailActiveHandleTime && dataForDate.emailActiveHandleTime.average;
          case 'closedEmailConversations':
            return dataForDate.emailActiveHandleTime && dataForDate.emailActiveHandleTime.count;
          case 'amountOverSLAByDay':
            return rawData.total > 0 ? rawData.over / rawData.total : null;
          case 'pctWithinSLAByDay':
            rawData = dataForDate.amountOverSLAByDay || {};
            return rawData.total > 0 ? 1 - rawData.over / rawData.total : null;
          case 'averageTimeToCloseByDay':
            rawData = dataForDate.totalTimeToCloseByDay || {};
            return rawData.count > 0 ? rawData.duration / rawData.count : null;
          case 'averageResponseTimeByDay':
            // TODO: Remove after we've completely excised the old reporting format
            if (dataForDate.totalResponseTimeByDay) {
              rawData = dataForDate.totalResponseTimeByDay;
              return rawData.count > 0 ? rawData.duration / rawData.count : null;
            }
            rawData = dataForDate.amountOverSLAByDay || {};
            return rawData.total > 0 ? rawData.duration / rawData.total : null;
          case 'averageHandleTimeByDay':
            return closedConversations > 0 ? rawData.duration : null;
          case 'averageIncomingTalkTimeByDay':
            return incomingAnswered > 0 ? incomingDuration / incomingAnswered : null;
          case 'averageOutgoingTalkTimeByDay':
            return outgoingAnswered > 0 ? outgoingDuration / outgoingAnswered : null;
          case 'pctAbandonedCallsByDay':
            return incomingCount > 0 ? abandonedCount / incomingCount : null;
          case 'abandonedCallsByDay':
            return abandonedCount;
          case 'chatSessionsByDay':
            return chatSessionCount;
          case 'averageChatTimeByDay':
            return chatSessionAnswered > 0 ? chatDuration / chatSessionAnswered : null;
          case 'maxConcurrentChatSessionsByTime':
            return rawData.count || 0;
          case 'avgConcurrentChatSessionsByTime':
            return rawData.float || 0;
          case 'declinedAndMissedChatsByTime':
            return _.get(dataForDate, 'declinedAndMissedChatsByTime.count', 0);
          case 'incomingChatMessagesByTime':
            return _.get(dataForDate, 'incomingChatMessagesByTime.count', 0);
          case 'outgoingChatMessagesByTime':
            return _.get(dataForDate, 'outgoingChatMessagesByTime.count', 0);
          case 'avgChatReplyTimeByTime':
            rawData = dataForDate.chatReplyTimeByTime || {};
            return rawData.count > 0 ? rawData.duration / rawData.count : null;
          case 'pctUtilizedByDay':
            return dataForDate.totalTimeUtilizedByDay &&
              dataForDate.totalTimeLoggedInByDay &&
              dataForDate.totalTimeLoggedInByDay.duration > 0
              ? dataForDate.totalTimeUtilizedByDay.duration / dataForDate.totalTimeLoggedInByDay.duration
              : null;
          case 'startedChatSessionsByTime':
            return rawData.count || 0;
          case 'incomingInstagramDirectMessagesByDay':
            return _.get(dataForDate, 'incomingInstagramDirectMessagesByDay.count', 0);
          case 'outgoingInstagramDirectMessagesByDay':
            return _.get(dataForDate, 'outgoingInstagramDirectMessagesByDay.count', 0);
          case 'incomingTwitterByDay':
            return _.get(dataForDate, 'incomingTwitterByDay.count', 0);
          case 'outgoingTwitterByDay':
            return _.get(dataForDate, 'outgoingTwitterByDay.count', 0);
          case 'incomingWhatsappsByDay':
            return _.get(dataForDate, 'incomingWhatsappByDay.count', 0);
          case 'outgoingWhatsappsByDay':
            return _.get(dataForDate, 'outgoingWhatsappByDay.count', 0);
          default:
            return rawData.count || 0;
        }
      },
    };
  }

  get reportType() {
    return ReportType.SUMMARY;
  }
}
