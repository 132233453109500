import { cloneDeep, get } from 'lodash';

import { addWidgetActivityItem } from 'actions/cobrowsing/helpers';
import { CoBrowsingSessionStatus } from 'models/cobrowsing_session_data';
import { trackSessionStarted } from 'actions/cobrowsing/analytics';

export default class HandleActiveSession {
  constructor(context) {
    this.context = context;
  }

  run({ id, sessionData, sessionSourceName }) {
    const existingSession = this.context.stores.localCoBrowsingSessions.find(id);
    if (existingSession && existingSession.status !== CoBrowsingSessionStatus.ACTIVE) {
      existingSession.setStatus(CoBrowsingSessionStatus.ACTIVE);
      existingSession.updateMeta(cloneDeep(sessionData || {}));
      this.context.stores.localCoBrowsingSessions.replace(existingSession);

      // Create a new conversation item for the active session
      const recordingUrl = get(sessionData, 'recordingUrl', '');
      addWidgetActivityItem(this.context, {
        meta: {
          recordingUrl,
          sessionId: id,
        },
        sourceName: sessionSourceName || '',
        status: CoBrowsingSessionStatus.ACTIVE,
      });

      trackSessionStarted(this.context, existingSession);
    }
  }
}
