var KeypressMixin = {
  submit(event) {
    if (this.onSubmit) {
      this.onSubmit(event);
    }
  },

  cancel(event) {
    if (this.onCancel) {
      this.onCancel(event);
    }
  },

  key(event) {
    if (this.onKeyDown) {
      this.onKeyDown(event);
    }
  },

  handleKeyDown(event) {
    // ensure shift and alt are not also pressed
    var shiftAlt = !!event.shiftKey || !!event.altKey;

    if (event.key === 'Escape') {
      this.cancel(event);
    } else if (event.key === 'Enter' && !shiftAlt) {
      if (this.props.enterSends || event.ctrlKey || event.metaKey) {
        this.submit(event);
      }
    }

    this.key(event);
  },
};

export default KeypressMixin;
