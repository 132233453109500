import _ from 'lodash';
import { faCircle as faCircleFull } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/common/button';
import CustomerDetails from 'components/sidebar/inbox_customer_details';
import InboxItemAvatar from './inbox_item_avatar';
import NavigateToInboxItem from 'actions/inbox/agent/navigate_to_inbox_item';
import RequestInboxItem from 'actions/inbox/agent/request_inbox_item';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function InboxItem({ currentAgentId, errors, index, item, needsAttention, selectedItemId }) {
  const executeAction = useExecuteAction();
  const navigateToCustomer = () => {
    executeAction(NavigateToInboxItem, { item, collapsed: false, index, needsAttention });
  };

  const isSelected = item.id === selectedItemId;

  if (errors && _.find(errors, e => e.code === 'timeout')) {
    return <FailedItem index={index} isSelected={isSelected} item={item} needsAttention={needsAttention} />;
  }

  return (
    <Item data-aid="customerList-item" data-customer-id={item.id} isCurrent={isSelected} onClick={navigateToCustomer}>
      <NeedsAttention isCurrent={item.id === selectedItemId} needsAttention={needsAttention} />
      <InboxItemAvatar item={item} />
      <CustomerDetails currentAgentId={currentAgentId} item={item} selectedItemId={selectedItemId} />
    </Item>
  );
}

const hoverItem = css`
  &:hover {
    background-color: ${p => (p.isCurrent ? p.theme.colors.green400 : p.theme.colors.gray100)};
  }
`;

const Item = styled.div`
  border-radius: 4px;
  ${p => (p.isCurrent ? `color: ${p.theme.colors.white};` : '')}
  cursor: pointer;
  display: flex;
  height: 86px;
  margin: 8px;
  padding: 8px;
  ${p => (p.isCurrent ? `background-color: ${p.theme.colors.green400};` : '')}

  ${hoverItem}
`;

function FailedItem({ index, item, isSelected, needsAttention }) {
  const executeAction = useExecuteAction();
  const navigateToCustomer = () => {
    executeAction(NavigateToInboxItem, { item, collapsed: false, index, needsAttention });
  };

  const rerequestItem = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    executeAction(RequestInboxItem, { id: item.id, type: item.type });
  };

  return (
    <StyledFailedItem data-aid="customerList-item-failed" isCurrent={isSelected} onClick={navigateToCustomer}>
      <FailedContainer>
        <LoadErrorDescription>Item failed to load</LoadErrorDescription>
        <Button contrast={isSelected} onClick={rerequestItem} type={Button.Types.PRIMARY}>
          Retry
        </Button>
      </FailedContainer>
    </StyledFailedItem>
  );
}

const StyledFailedItem = styled(Item)`
  align-items: center;
  border: 1px solid ${p => p.theme.colors.gray300};
  justify-content: center;
`;

const FailedContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LoadErrorDescription = styled.div`
  margin-bottom: 8px;
`;

function NeedsAttention({ needsAttention, isCurrent }) {
  return (
    <NeedsAttentionContainer isCurrent={isCurrent}>
      {needsAttention ? <FontAwesomeIcon data-aid="sidebarInboxItem-needsAttention" icon={faCircleFull} /> : null}
    </NeedsAttentionContainer>
  );
}

const NeedsAttentionContainer = styled.div`
  align-items: center;
  color: ${p => (p.isCurrent ? p.theme.colors.white : p.theme.colors.green400)};
  display: flex;
  flex-shrink: 0;
  font-size: 10px;
  height: 100%;
  justify-content: center;
  margin-right: 4px;
  width: 11px;
`;
