import Toast from 'models/toast';
import TrackEvent from 'scripts/application/actions/analytics/track_event';

export default class TrackToast {
  constructor(context) {
    this.context = context;
  }

  run({ toast, track }) {
    if (!track || !toast) {
      return;
    }

    // We're only tracking mention toasts for now
    if (
      toast.type !== Toast.Type.AGENT_MENTIONED_IN_ITEM &&
      toast.type !== Toast.Type.AGENT_MENTIONED_IN_TASK_COMMENT
    ) {
      return;
    }

    const { id, content, type } = toast;

    this.context.executeAction(TrackEvent, {
      event: `Toast ${track}`,
      props: {
        id,
        agentId: content.agentId,
        customerId: content.customerId,
        itemId: content.itemId || content.taskId,
        itemType: content.itemType || '',
        toastType: type,
      },
    });
  }
}
