import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('CustomerMerge').attr('type', 'CUSTOMER_MERGE');

Factory.define('CustomerMergeWithDefaults')
  .extend('CustomerMerge')
  .attr('sourceCustomerId', 'sourceCustomerId')
  .attr('sourceConversationId', 'sourceConversationId')
  .attr('destCustomerId', 'destCustomerId')
  .attr('destConversationId', 'destConversationId');

Factory.define('CustomerMergeItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('CustomerMerge', content));

Factory.define('CustomerMergeItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('CustomerMergeWithDefaults', content))
  .attr('initiator', {
    type: 'AGENT',
    id: 'current-agent-id',
  });
