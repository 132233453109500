import _ from 'lodash';

import ConversationItemType from 'models/conversation_item_type';
import Endpoint from 'models/endpoint';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import { isValidPhoneNumber, normalizePhoneNumber } from 'models/phone_number';
import PhoneCallCompositionContent from 'models/composition/phone_call_composition_content';

export function createPhoneCallContent(context, conversation, phoneNumber) {
  const customerId = context.stores.currentLocation.get().customerId;
  const { conversationHistory } = context.stores.customers.storesFor(customerId);
  const previousPhoneCall = getLatestManualItem({
    conversationHistory,
    conversationId: conversation.id,
    filter: item => item.content.type === ConversationItemType.PHONE_CALL,
  });

  let companyNumber = '';
  const potentialCompanyNumbers = getPotentialFroms(context, conversation);
  if (potentialCompanyNumbers.default) {
    companyNumber = potentialCompanyNumbers.default;
  } else if (previousPhoneCall) {
    companyNumber = previousPhoneCall.content.companyNumber;
  }

  if (!_.includes(potentialCompanyNumbers.allFromNumbers, companyNumber)) {
    companyNumber = potentialCompanyNumbers.fallback;
  }

  const customerStores = context.stores.customers.storesFor(customerId);
  const customerNumber =
    phoneNumber && isValidPhoneNumber(phoneNumber)
      ? normalizePhoneNumber(phoneNumber)
      : customerStores.profile.get().getPrimaryPhoneNumberNormalized();

  return new PhoneCallCompositionContent({
    customerNumber,
    companyNumber,
  });
}

function getPotentialFroms(context, conversation) {
  let channelConfiguration = context.stores.channelConfiguration.get();

  let voiceEndpoints = channelConfiguration ? channelConfiguration.getEndpointsOfType(Endpoint.Type.VOICE) : [];
  let allFromNumbers = _.map(voiceEndpoints, 'address');
  let firstRoutingGroupPhoneNumber = _.chain(context.stores.communicationQueues.findAll())
    .filter(commQueue => commQueue.routingGroupId === conversation.assignee.routingGroupId)
    .map(commQueue => _.find(voiceEndpoints, endpoint => endpoint.id === commQueue.endpointId))
    .compact()
    .map(endpoint => endpoint.address)
    .head()
    .value();

  const currentInbox = context.stores.routingGroups.findBy({ id: conversation.assignee.routingGroupId });
  const defaultEndpointId = currentInbox && currentInbox.defaultEndpoints[Endpoint.Type.VOICE];
  const defaultEndpoint =
    channelConfiguration && _.find(channelConfiguration.endpoints, e => e.id === defaultEndpointId);
  const defaultPhoneNumber = defaultEndpoint && defaultEndpoint.address;

  return {
    allFromNumbers,
    default: defaultPhoneNumber,
    fallback: defaultPhoneNumber || firstRoutingGroupPhoneNumber || allFromNumbers[0],
    preferred: firstRoutingGroupPhoneNumber,
  };
}

export function hasConfiguredPhoneNumbers(context) {
  const channelConfiguration = context.stores.channelConfiguration.get();
  return channelConfiguration && channelConfiguration.companyPhoneNumbers().length > 0;
}
