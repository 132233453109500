import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ContactFbIcon from 'components/lib/icons/contact_fb_icon';
import FbUserProfileModel from 'models/customer_profile/fb_user_profile';

const FbUserProfile = ({ className, profile }) => {
  let profileName = profile ? profile.getName() : undefined;

  const rowClassName = classnames('fbUserProfile-row', className);
  return (
    <div className={rowClassName}>
      <ContactFbIcon className="customerProfile-fbIcon" />
      <div className="fbUserProfile customerProfile-contactDetails-row customerProfile-contactDetails-readonly static">
        <div className="fbUserProfile-name">{profileName || 'Private User'}</div>
      </div>
    </div>
  );
};

FbUserProfile.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.instanceOf(FbUserProfileModel),
};

export default FbUserProfile;
