import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import SmsMessage, { STATUS as SmsStatus } from 'models/sms_message';

export default function createAgentSms(
  agentProfile,
  conversationId,
  customerId,
  { attachments, from, to, body, snippetIds, relatedSnippetIds }
) {
  return ConversationItem.create({
    conversationId,
    content: SmsMessage.create({ attachments, from, to, body, status: { value: SmsStatus.NEW } }),
    customerId,
    initiator: {
      id: agentProfile.id,
      type: Communicator.AGENT,
    },
    snippetIds,
    relatedSnippetIds,
  });
}
