import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import {
  MARK_LINK_SELECTION,
  MARK_LINK_SELECTION_FIRST_NODE,
  MARK_LINK_SELECTION_LAST_NODE,
} from 'components/text_editor_new/plugins/inline_links/create_link_selection_plugin';

/**
 * Very simple component that has only one purpose in life: render the nodes tagged with `MARK_LINK_SELECTION`
 * in the way that mimics the native browser selection. We use this component to maintain the "visual selection"
 * state of the nodes when the Slate editor loses focus (e.g. when the link editor window pops up).
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function InlineLinkSelection({ attributes, children, className, leaf }) {
  const isFirstNode = !!_.get(leaf, MARK_LINK_SELECTION_FIRST_NODE);
  const isLastNode = !!_.get(leaf, MARK_LINK_SELECTION_LAST_NODE);

  // Add "marker" attributes to help with querying the DOM (which is far mor reliable than Slate native
  // and very buggy DOM translation)
  const extraAttrs = {
    [`data-${MARK_LINK_SELECTION}`]: true,
  };
  if (isFirstNode) extraAttrs[`data-${MARK_LINK_SELECTION_FIRST_NODE}`] = true;
  if (isLastNode) extraAttrs[`data-${MARK_LINK_SELECTION_LAST_NODE}`] = true;

  return (
    <StyledSelection {...attributes} {...extraAttrs} className={className}>
      {children}
    </StyledSelection>
  );
}

const StyledSelection = styled.span`
  color: ${p => p.theme.colors.green400};
`;

InlineLinkSelection.propTypes = {
  attributes: PropType.object,
  children: PropType.node,
  className: PropType.string,
  leaf: PropType.object,
};
