import { Factory } from 'rosie';
import './lib/id';

import configureIdGenerator from './lib/configure_id_generator';

let IdGenerator = configureIdGenerator();

Factory.define('ConversationItem')
  .extend('Id')
  .attr('customerId', () => IdGenerator.newId())
  .attr('timestamp', () => new Date().toISOString())
  .attr('version', 0);

Factory.define('ConversationItemWithDefaults')
  .extend('ConversationItem')
  .attr('customerId', 'customer-id')
  .attr('conversationId', 'conversation-id')
  .attr('initiator', ['customerId'], customerId => ({
    type: 'CUSTOMER',
    id: customerId,
  }))
  .attr('content', { type: 'PLACEHOLDER' });

Factory.define('ConversationItemWithoutConversationId')
  .extend('ConversationItem')
  .attr('customerId', 'customer-id')
  .attr('conversationId', '')
  .attr('initiator', ['customerId'], customerId => ({
    type: 'CUSTOMER',
    id: customerId,
  }))
  .attr('content', { type: 'PLACEHOLDER' });
