import { createPluginFactory } from '@udecode/plate';

export default function createSaveOnBlur(onBlur) {
  return createPluginFactory({
    key: 'save-on-blur',
    handlers: {
      onBlur: () => evt => {
        onBlur && onBlur();
      },
    },
  })();
}
