export default class RefreshEmbedTokens {
  constructor(context) {
    this.context = context;
  }

  run({ apiToken, navigationToken }) {
    this.context.stores.embedTokensReport.setLoading();
    this.context.gateways.embedTokens.add({ apiToken, navigationToken });
  }
}
