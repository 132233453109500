import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AgentTaskReadService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;

    this.reads = {};
  }

  update(agentAttrs, callback, path, { orgId, agentId, itemId }) {
    const agentTaskRead = { agentId, itemId, ...agentAttrs };
    this.reads[itemId] = agentTaskRead;

    this._pubsub.publish(`v1/orgs/${orgId}/agent-task-reads/${agentId}`, {
      payload: agentTaskRead,
    });

    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/agent-task-reads/:agentId': {
          PUT: this.update,
        },
      },
      this
    );
  }
}
