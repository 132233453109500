import _ from 'lodash';

import MqttGateway from './lib/mqtt_gateway';

export default class CustomerAssigneeGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer-assignee/+';
  }

  reconnectHandler() {
    this._responseHandlers = {};
  }

  request(customerId) {
    return super._subscribeAndFetch([customerId]);
  }

  unsubscribe(customerId) {
    super._unsubscribe([customerId]);
  }

  update(customerId, payload) {
    return super._update([customerId], payload);
  }

  remove(customerId) {
    return this._delete([customerId]);
  }

  onReceive(entityDto, topic, parsedTopicParams) {
    this.notifyObservers('handleEntity', entityDto);
  }

  onDelete(topic, parsedTopicParams) {
    this.notifyObservers('handleDelete', _.last(parsedTopicParams));
  }

  onFetchResponse(fetchResultDto, topic, parsedTopicParams) {
    this.notifyObservers('handleFetchSuccess', fetchResultDto);
  }

  onFetchError(errorDto, topic, parsedTopicParams) {
    this.notifyObservers('handleFetchError', _.last(parsedTopicParams), errorDto);
  }

  onUpdateSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateSuccess', _.last(parsedTopicParams), payload);
  }

  onUpdateErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateErrors', _.last(parsedTopicParams), errorDtos);
  }
}
