import classnames from 'classnames';
import createReactClass from 'create-react-class';
import moment from 'moment';
import React from 'react';
import T from 'prop-types';

import { H1 } from 'components/common/headers';

const ReportDuration = createReactClass({
  propTypes: {
    className: T.string,
    displayDuration: T.shape({
      months: T.number,
      days: T.number,
      hours: T.number,
      minutes: T.number,
      seconds: T.number,
    }),
    duration: T.number,
    simplified: T.bool,
    header: T.bool,
  },

  statics: {
    toDisplayDuration(duration) {
      if (!duration && duration !== 0) {
        return null;
      }
      if (duration <= 0) {
        return { minutes: 0, seconds: 0 };
      }

      let momentDuration = moment.duration(duration, 'ms');

      // extract(moment.duration(1.5001, 'minutes'), 'minutes', 'seconds')
      //  => { minutes: 1, seconds: 30 }
      let extract = (md, precision1, precision2) => {
        let base = moment.duration(1, precision2).asMilliseconds();
        let rounded = moment.duration(Math.round(md.asMilliseconds() / base) * base);
        return {
          [precision1]: rounded.get(precision1),
          [precision2]: rounded.get(precision2),
        };
      };

      if (duration < moment.duration(1, 'hours')) {
        return extract(momentDuration, 'minutes', 'seconds');
      } else if (duration < moment.duration(1, 'days')) {
        return extract(momentDuration, 'hours', 'minutes');
      } else if (duration < moment.duration(1, 'months')) {
        return extract(momentDuration, 'days', 'hours');
      }
      return extract(momentDuration, 'months', 'days');
    },
  },

  render() {
    let duration =
      this.props.displayDuration !== undefined
        ? this.props.displayDuration
        : ReportDuration.toDisplayDuration(this.props.duration);

    if (!duration) {
      return this.renderDuration({
        blankDuration: true,
      });
    } else if ('months' in duration) {
      return this.renderDuration({
        bigCount: duration.months,
        bigUnit: 'M',
        littleCount: duration.days,
        littleUnit: 'd',
      });
    } else if ('days' in duration) {
      return this.renderDuration({
        bigCount: duration.days,
        bigUnit: 'd',
        littleCount: duration.hours,
        littleUnit: 'h',
      });
    } else if ('hours' in duration) {
      return this.renderDuration({
        bigCount: duration.hours,
        bigUnit: 'h',
        littleCount: duration.minutes,
        littleUnit: 'm',
      });
    }
    return this.renderDuration({
      bigCount: duration.minutes,
      bigUnit: 'm',
      littleCount: duration.seconds,
      littleUnit: 's',
    });
  },

  renderDuration({ bigCount, bigUnit, littleCount, littleUnit, blankDuration }) {
    let containerClasses = classnames('reportDuration', this.props.className);

    if (blankDuration) {
      return (
        <span className={containerClasses} data-aid="reportDuration-blank">
          –
        </span>
      );
    }

    if (this.props.simplified) {
      return (
        <span className={containerClasses}>
          {bigCount || '0'}
          {`${bigUnit} `}
          {littleCount || '0'}
          {littleUnit}
        </span>
      );
    }

    let countClasses = classnames('reportDuration-count', this.props.className && `${this.props.className}-count`);
    let unitClasses = classnames('reportDuration-unit', this.props.className && `${this.props.className}-unit`);

    if (this.props.header) {
      return (
        <H1>
          {bigCount || '0'}
          {bigUnit} {littleCount || '0'}
          {littleUnit}
        </H1>
      );
    }

    return (
      <span className={containerClasses}>
        <span className={countClasses}>{bigCount || '0'}</span>
        <span className={unitClasses}>{bigUnit}</span>
        <span className={countClasses}>{littleCount || '0'}</span>
        <span className={unitClasses}>{littleUnit}</span>
      </span>
    );
  },
});

export default ReportDuration;
