import { after } from 'scripts/infrastructure/backends/http_client';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';

export default class RoutingGroupGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
  }

  get version() {
    return 1;
  }

  url(routingGroupId) {
    return `/api/v1/orgs/${this.orgId}/routing-groups/${routingGroupId || ''}`.replace(/\/$/, '');
  }

  get broadcastTopic() {
    return `v1/orgs/${this.orgId}/routing-groups/+`;
  }

  init({ orgId, agentId }) {
    this.orgId = orgId;
    this.agentId = agentId;
  }

  get _http() {
    return this.backend.axios();
  }

  reconnectHandler() {
    this.request();
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleRoutingGroup', envelope.payload);
  }

  request() {
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.get.bind(this));
  }

  get() {
    return after(this._http.get(this.url()), this.onFetchAll.bind(this));
  }

  add(routingGroupDto) {
    return after(this._http.post(this.url(), routingGroupDto), this.onAdd.bind(this));
  }

  remove(routingGroupId) {
    return after(this._http.delete(this.url(routingGroupId)), this.onRemove.bind(this, routingGroupId));
  }

  onAdd(err, res) {
    if (err) {
      this.notifyObservers('handleAddErrors', err);
      return;
    }
    if (res.status === 204) {
      this.notifyObservers('handleAddSuccess', res.data);
    } else {
      this.notifyObservers('handleAddErrors', res.data);
    }
  }

  update(routingGroupId, body) {
    return after(this._http.put(this.url(routingGroupId), body), this.onUpdate.bind(this, routingGroupId, body));
  }

  onUpdate(routingGroupId, routingGroupDto, err, res) {
    if (err) {
      this.notifyObservers('handleUpdateErrors', routingGroupId, routingGroupDto);
      return;
    }
    if (res.status === 204) {
      this.notifyObservers('handleUpdateSuccess', routingGroupId, routingGroupDto);
    } else {
      this.notifyObservers('handleUpdateErrors', routingGroupId, routingGroupDto);
    }
  }

  onRemove(routingGroupId, err, res) {
    if (err) {
      this.notifyObservers('handleDeleteError', routingGroupId);
      return;
    }
    if (res.status === 204) {
      this.notifyObservers('handleDeleteRoutingGroup', routingGroupId);
    } else {
      this.notifyObservers('handleDeleteError', routingGroupId);
    }
  }

  onFetchAll(err, res) {
    if (err) {
      this.notifyObservers('handleRequestFetchError', err);
      return;
    }
    if (res.status === 200) {
      this.notifyObservers('handleRequestedRoutingGroups', res.data);
    } else {
      this.notifyObservers('handleRequestFetchError', res.data);
    }
  }
}

mixin(RoutingGroupGateway.prototype, Observable);
