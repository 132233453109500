import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import Factory from 'factories/all';

export default class TaskFollowerService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(attrs, callback, path, { orgId, taskId }) {
    let taskFollowers = this._getFollowers(orgId, taskId);
    callback(null, { status: 200, statusText: statusText(200), response: taskFollowers });
  }

  add(attrs, callback, path, { orgId, taskId }) {
    callback(null, { status: 200, statusText: statusText(200), response: attrs });
  }

  _getFollowers(orgId, taskId) {
    // TODO: add followers to org dbs
    let database = this.getDatabase(orgId);
    let defaultAgentId1 = _.get(database.agents, `${Object.keys(database.agents)[0]}.id`);
    let defaultAgentId2 = _.get(database.agents, `${Object.keys(database.agents)[1]}.id`);

    return [
      Factory.build('TaskFollowerWithDefaults', {
        id: 'follower-id-1',
        agentId: defaultAgentId1,
        itemId: taskId,
      }),
      Factory.build('TaskFollowerWithDefaults', {
        id: 'follower-id-2',
        agentId: defaultAgentId2,
        itemId: taskId,
      }),
    ];
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/tasks/:taskId/followers': {
          GET: this.findAll,
          POST: this.add,
        },
      },
      this
    );
  }
}
