import Toast from 'models/toast_deprecated';

/**
 * Unlike `RemoveToast`, this action initiates graceful "fading off" of the toast but does not delete
 * the toast from the store - this needs to be done separately, typically by either calling the
 * `RemoveToast.schedule(...)` helper or by setting a timeout to allow the animation to finish
 */
export default class HideToast {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    let toast = this.context.stores.toastsDeprecated.findBy({ id });
    if (toast) {
      const updatedToast = Toast.createFrom(toast, { visible: false });
      this.context.stores.toastsDeprecated.addOrReplace(updatedToast);
    }
  }
}
