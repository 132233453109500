import changeCurrentLocation from 'actions/lib/change_current_location';
import CustomerCombine from 'models/location/customer_combine';

export default class OpenCustomerCombine {
  constructor(context) {
    this.context = context;
  }

  run(customerId) {
    let customerCombine = CustomerCombine.create({
      customerId,
      currentConversationId: this.getCurrentConversationId(customerId),
    });
    changeCurrentLocation(this.context, customerCombine);
  }

  getCurrentConversationId(customerId) {
    let currentLocation = this.context.stores.currentLocation.get();
    return currentLocation.customerId === customerId ? currentLocation.currentConversationId : null;
  }
}
