import CustomerView from 'models/location/customer_view';
import { LinkedCustomerProfile } from 'models/item_link_content';
import Relationship from 'models/relationship';
import RelationshipConverter from 'scripts/application/dto_converters/relationship_converter';
import TrackEvent from 'actions/analytics/track_event';

export default class AddRelationship {
  constructor(context) {
    this.context = context;
  }

  run({ originalCustomerId, customerId, labelId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    const otherCustomerProfile = this.context.stores.customers.has({ id: customerId })
      ? this.context.stores.customers.storesFor(customerId).profile.get()
      : null;
    let customerProfile;
    if (otherCustomerProfile) {
      customerProfile = new LinkedCustomerProfile({
        name: otherCustomerProfile.name,
      });
    }

    let relationship = Relationship.create({
      customerId,
      customerProfile, // This gets dropped by the DTO converter
      labelId,
    });
    let customerStore = this.context.stores.customers.storesFor(originalCustomerId);
    customerStore.relationships.setPendingNew(relationship);

    this.context.gateways.relationship.add(
      { customerId: originalCustomerId, relationshipId: relationship.id },
      RelationshipConverter.toDto(relationship, originalCustomerId)
    );

    this.context.executeAction(TrackEvent, {
      event: `Relationships - Labels - Add Relationship`,
      props: { linkedCustomerId: customerId, originalCustomerId, labelId },
    });
  }
}
