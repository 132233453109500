import ErrorReporter from 'scripts/infrastructure/error_reporter';
import NotificationClasses from './notification_classes';
import TypeReflect from 'models/lib/type_reflect';

export const typeReflect = new TypeReflect(NotificationClasses);

export default function(notification) {
  try {
    let NotificationClass = typeReflect.typeToConstructor(notification.type);
    return NotificationClass.fromJs(notification);
  } catch (e) {
    ErrorReporter.reportError(e, { extra: { notification } });
    return null;
  }
}
