import Immutable from 'immutable';
import reportFromJs from 'models/reports/report_from_js';

export default {
  fromImmutable(immutable) {
    return reportFromJs(immutable.toJS());
  },

  toImmutable(entity) {
    return Immutable.fromJS(entity && entity.toJs());
  },
};
