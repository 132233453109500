import classnames from 'classnames';

import React from 'react';
import IconContainer from './icon_container';

export default function MenuIcon({ className, ...props }) {
  return (
    <IconContainer className={classnames('menuIcon', className)} viewBox="0 0 4 20" {...props}>
      <g fillRule="evenodd">
        <circle cx="2" cy="2" r="2" />
        <circle cx="2" cy="9.692" r="2" />
        <circle cx="2" cy="17.385" r="2" />
      </g>
    </IconContainer>
  );
}

MenuIcon.propTypes = IconContainer.propTypes;
