import _ from 'lodash';
import HtmlToText from 'html-to-text';
import React from 'react';

import AgentProfile from 'models/agent_profile';
import CompanyProfile from 'models/company_profile';
import connect from 'components/lib/connect';
import CustomerProfile from 'models/customer_profile';
import { getSuggestedAnswersList, getSuggestedAnswersModelVersion } from 'scripts/application/selectors/answers';
import KbVariable from 'models/kb_variable';

export default function(ComposedComponent, options = {}) {
  return class DecoratedComponent extends connect(mapStateToProps)(ComposedComponent) {
    render() {
      let wrappedElement = super.render();

      return React.cloneElement(wrappedElement, {
        ...wrappedElement.props,
        ref: node => (this.wrapped = node),
      });
    }
  };

  function mapStateToProps({ getProvider }, props) {
    let resolvedVariables = getResolvedKbVariables();
    const providers = {
      currentLocation: getProvider('currentLocation'),
      answerSuggestions: getProvider('answerSuggestions'),
    };

    return {
      ...props,
      answerSuggestionsModelVersion: getSuggestedAnswersModelVersion(providers),
      mentionMenuData: getMentionMenuData(),
      suggestedItems: getSuggestedAnswersList(providers),
      variables: _.keyBy(resolvedVariables, 'id'),
    };

    function getMentionMenuData() {
      let mentionMenuData = [];
      if (options.useVariables) {
        mentionMenuData = mentionMenuData.concat(resolvedVariables);
      }

      return mentionMenuData;
    }

    function getResolvedKbVariables() {
      const resolveVariable = options.variableResolver || getDefaultResolveFunc();

      return _.sortBy(_.map(getProvider('kbVariables').findAll(), getKbVariableObj), item => item.name.toLowerCase());

      function getKbVariableObj(kbVar) {
        const text = (resolveVariable(kbVar) || '').trim();
        return {
          id: kbVar.id,
          isDisabled: !text,
          name: kbVar.name,
          text: text || '\u2014',
          plaintext: HtmlToText.fromString(text, { wordwrap: false }),
          type: 'variable',
        };
      }
    }

    function getDefaultResolveFunc() {
      let resolver = new VariableResolver({ getProvider });
      return resolver.resolve.bind(resolver);
    }
  }
}

class VariableResolver {
  constructor(connectContext) {
    this.connectContext = connectContext;
  }

  resolve(variable) {
    return (
      (variable.type && KbVariable.Resolver[variable.type] && KbVariable.Resolver[variable.type](this.context)) ||
      variable.defaultValue
    );
  }

  get context() {
    return this._context || (this._context = this.createContext());
  }

  createContext() {
    const isExternalNameEnabled = this.connectContext.getProvider('agentExperienceConfig').get().allowExternalName;
    const customerId = this.connectContext.getProvider('currentLocation').get().customerId;
    const agent = this.connectContext.getProvider('currentAgent').get();

    return {
      agent: AgentProfile.create({
        ...agent,
        externalName: isExternalNameEnabled && agent ? agent.externalName : undefined,
      }),
      company: this.connectContext.getProvider('companyProfile').get() || CompanyProfile.create(),
      customer: this.connectContext.getProvider('profile').get() || CustomerProfile.create({ id: customerId }),
    };
  }
}
