import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import IconContainer from './icon_container';

export default function InboxIcon(props) {
  return (
    <IconContainer viewBox="0 0 34 34" {...props} className={classnames('inboxIcon', props.className)}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M25.96 16.932h.001l-2.647-5.679a.449.449 0 0 0-.409-.253h-10.81a.45.45 0 0 0-.409.253l-2.647 5.679a.408.408 0 0 0-.039.173v5.469c0 .235.2.426.447.426h16.106c.247 0 .447-.19.447-.426v-5.469a.407.407 0 0 0-.04-.173zm-13.574-5.079h10.228l2.25 4.826H20.36a.44.44 0 0 0-.446.394c-.095 1.194-1.155 2.13-2.413 2.13-1.258 0-2.317-.936-2.41-2.13a.44.44 0 0 0-.447-.394h-4.507l2.25-4.826zm12.72 10.294H9.894v-4.615h4.36c.31 1.443 1.662 2.524 3.245 2.524 1.582 0 2.935-1.081 3.247-2.524h4.358v4.615z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </IconContainer>
  );
}

InboxIcon.propTypes = {
  className: PropTypes.string,
};
