import _ from 'lodash';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

import connect from 'components/lib/connect';
import FeatureNotificationList, { useHasFeaturesToShow } from './feature_notification_list';
import { H3 } from 'components/common/headers';
import MarkAllAsRead from 'actions/agent_notifications/mark_all_as_read';
import NotificationsList from './agent_notifications_list';
import OpenNotificationsMenu from 'actions/agent_notifications/open_notifications_menu';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { usePopoverMenu } from 'components/common/menu';
import TrackNotificationsBellClicked from 'actions/agent_notifications/track_notifications_bell_clicked';
import useExecuteAction from 'components/hooks/use_execute_action';

export function AgentNotificationsMenu({ agentPreferences, hasReachedEnd, isLoading, lastOpenedAt, notifications }) {
  const popoverProps = usePopoverMenu();
  const { isOpen, onClose, onToggle, setTargetRef, targetRef } = popoverProps;
  const executeAction = useExecuteAction();

  const mostRecentNotification = notifications[0];
  useKeepMenuAsReadIfOpen(isOpen, mostRecentNotification);
  const hasFeaturesToShow = useHasFeaturesToShow(agentPreferences);

  const isBellUnread =
    !isLoading &&
    (hasFeaturesToShow ||
      (!lastOpenedAt && mostRecentNotification) ||
      (lastOpenedAt && mostRecentNotification && new Date(mostRecentNotification.createdAt) > new Date(lastOpenedAt)));

  const unreadNotifications = _.filter(notifications, notification => !notification.hasRead());

  const onClickBell = useCallback(() => {
    executeAction(OpenNotificationsMenu);
    executeAction(TrackNotificationsBellClicked, {
      hasUnreadNotifications: !!isBellUnread,
      unreadCount: unreadNotifications.length,
      totalCount: notifications.length,
    });
    onToggle();
  }, [isBellUnread, executeAction, onToggle, unreadNotifications, notifications]);

  return (
    <OutsideClickHandler onClickOutside={onClose}>
      <TopBarOption isOpen={isOpen} onClick={onClickBell} ref={setTargetRef}>
        <Bell isUnread={isBellUnread} />
      </TopBarOption>

      <StyledPopoverMenu
        data-aid="agentNotifications-menu"
        isOpen={isOpen}
        onClickOutside={null}
        onClose={onClose}
        position="bottom"
        targetRef={targetRef}
      >
        <Header>
          <HeaderLabel>Notifications</HeaderLabel>
          {unreadNotifications.length > 0 ? <MarkAll /> : null}
        </Header>
        <FeatureNotificationList />
        <NotificationsList hasReachedEnd={hasReachedEnd} notifications={notifications} />
      </StyledPopoverMenu>
    </OutsideClickHandler>
  );
}

const AgentNotificationsMenuContainer = connect(mapStateToProps)(AgentNotificationsMenu);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const meta = getProvider('agentNotificationMeta').get();
  const agentPreferences = getProvider('agentPreferences').get();

  return {
    agentPreferences,
    hasReachedEnd: meta.hasReachedEnd,
    isLoading: getProvider('agentNotifications').isLoading(),
    lastOpenedAt: agentPreferences.agentNotificationsLastOpenedAt,
    notifications: _.orderBy(getProvider('agentNotifications').findAll(), ['createdAt'], ['desc']),
  };
}

function useKeepMenuAsReadIfOpen(isOpen, mostRecentNotification) {
  const executeAction = useExecuteAction();
  useEffect(() => {
    if (isOpen) {
      executeAction(OpenNotificationsMenu);
    }
  }, [isOpen, mostRecentNotification, executeAction]);
}

const StyledPopoverMenu = styled(PopoverMenu)`
  display: flex;
  flex-direction: column;
  max-height: 557px;
  min-height: 170px;
  overflow: auto;
  width: calc(25% - 16px);
`;

const Header = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  position: sticky;
  top: 0;
`;
const HeaderLabel = styled(H3)`
  margin-bottom: 0;
  margin-right: 8px;
`;

const greenIcon = css`
  path {
    fill: ${p => p.theme.colors.green400};
  }
`;

const TopBarOption = styled.li.attrs({
  'data-aid': 'agentNotifications-menuOption',
})`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 48px;

  svg {
    font-size: 20px;
  }

  ${p => p.isOpen && greenIcon}

  &:hover {
    ${greenIcon}
  }
`;

const BellWrapper = styled.div`
  position: relative;
`;

function MarkAll() {
  const executeAction = useExecuteAction();
  return (
    <MarkAllItem data-aid="agentNotifications-markAllAsRead" onClick={() => executeAction(MarkAllAsRead)}>
      Mark all as read
    </MarkAllItem>
  );
}

const MarkAllItem = styled.span`
  color: ${p => p.theme.colors.green400};
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.green500};
  }
`;

export function Bell({ isUnread }) {
  return (
    <BellWrapper>
      <FontAwesomeIcon icon={faBell} />
      {isUnread ? <UnreadDot /> : null}
    </BellWrapper>
  );
}

const UnreadDot = styled.div`
  background-color: ${p => p.theme.colors.red400};
  border-radius: 50%;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  width: 10px;
`;

export default AgentNotificationsMenuContainer;
