import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AgentService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(attrs, callback, path, { orgId }) {
    let agents = this.getDatabase(orgId).agents;

    callback(null, { status: 200, statusText: statusText(200), response: agents });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/agents': { GET: this.findAll },
      },
      this
    );
  }
}
