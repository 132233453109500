import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('TaskComment').extend('Uuid');

Factory.define('TaskCommentWithDefaults')
  .extend('TaskComment')
  .attr('id', 'task-item-id')
  .attr('initiatingAgentId')
  .attr('createdAt', () => new Date().toISOString())
  .attr('initiatingAgentId', 'agent-id')
  .attr('itemId', 'item-id')
  .attr('message', 'message')
  .attr('version', 1);
