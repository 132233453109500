import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'HighlightedMessages',

  properties: {
    id: prop(String).isRequired,
    customerId: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
