import TrackEvent from 'actions/analytics/track_event';

export default class UnlinkItemFromCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ item, linkedItem }) {
    let customerId = linkedItem.customerId;
    let itemId = linkedItem.itemId;

    // If the item is a link, then the item itself is the one we want to delete.. linkedItem would
    // point back to the original item.
    if (item.isLink()) {
      customerId = item.customerId;
      itemId = item.id;
    }

    this.context.gateways.customerHistory.deleteConversationItem(customerId, itemId);
    this.context.executeAction(TrackEvent, {
      event: 'Relationships - Items - Unlink Item',
      props: { customerId, itemId },
    });
  }
}
