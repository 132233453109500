import _ from 'lodash';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import ReactTextareaAutosize from 'react-textarea-autosize';

import connect from 'components/lib/connect';
import ProfileCard from 'components/customer/profile/profile_card';
import UpdateCustomerNote from 'actions/customer/update_customer_note';

const CustomerNotes = createReactClass({
  propTypes: {
    content: PropTypes.string,
    hasNoProfile: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return { content: this.props.content || '', hasChanged: false };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.hasChanged && nextProps.content !== this.state.content) {
      this.setState({ content: nextProps.content });
    }
  },

  onChange(evt) {
    this.setState({ content: evt.target.value, hasChanged: true });
  },

  onSubmit() {
    let updatedContent = (this.state.content || '').trim();
    if (this.state.hasChanged) {
      this.props.onSubmit(updatedContent);
    }
    this.setState({ hasChanged: false, content: updatedContent });
  },

  render() {
    if (this.props.hasNoProfile) {
      return null;
    }

    return (
      <ProfileCard data-aid="customerDetailsSection" isLoading={this.props.isLoading} title="Details">
        <ReactTextareaAutosize
          className="customerNotes-textArea"
          disabled={this.props.isLoading}
          onBlur={this.onSubmit}
          onChange={this.onChange}
          placeholder="Click to enter customer notes..."
          value={this.state.content}
        />
      </ProfileCard>
    );
  },
});

export { CustomerNotes };

export default connect(mapStateToProps, mapExecuteToProps)(CustomerNotes);

function mapStateToProps({ getProvider }) {
  const noteProvider = getProvider('note');
  const profileProvider = getProvider('profile');
  const hasNoProfile = !profileProvider.get() && !profileProvider.isLoading();

  return {
    content: _(noteProvider.get()).get('content', ''),
    hasNoProfile,
    isLoading: noteProvider.isLoading(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onSubmit: updatedNotes => executeAction(UpdateCustomerNote, updatedNotes),
  };
}
