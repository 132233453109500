import createModalModel, { prop } from './lib/create_modal_model';

export default createModalModel({
  modelName: 'ScheduleRecurringReport',
  properties: {
    filters: prop(Object).default({}),
    timezone: prop(String),
    metricSet: prop(String),
    name: prop(String),
  },
});
