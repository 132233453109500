import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { find, trim } from 'lodash';

import connect from 'components/lib/connect';
import LoadingDots from 'components/lib/loading_dots';

export function AIActivityMessageBase({ messageText, shouldDisplayMessage }) {
  if (!shouldDisplayMessage) return null;
  return (
    <StyledModalShield>
      <StyledMessage>
        {trim(messageText)}
        <StyledActivityIndicator />
      </StyledMessage>
    </StyledModalShield>
  );
}

const StyledActivityIndicator = styled(LoadingDots)`
  margin-left: 8px;
  position: relative;
  top: 1px;
`;

const StyledMessage = styled.div`
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.small};
  box-shadow: ${p => p.theme.boxShadow.small};
  color: ${p => p.theme.colors.gray700};
  display: flex;
  justify-content: center;
  padding: 18px;
  max-width: 85%;
  overflow: hidden;
  white-space: nowrap;
  width: 75%;
`;

const StyledModalShield = styled.div`
  align-items: center;
  background-color: rgba(250, 250, 250, 0.3);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;

AIActivityMessageBase.propTypes = {
  messageText: PropTypes.string,
  shouldDisplayMessage: PropTypes.bool,
};

function mapStateToProps({ getProvider }, { loadingStatesToWatch }) {
  const provider = getProvider('aiTextCompletions');
  const isLoading = !!find(loadingStatesToWatch, key => provider.hasActiveLoadingStateFor(key));

  return {
    shouldDisplayMessage: isLoading,
  };
}

const AIActivityMessage = connect(mapStateToProps)(AIActivityMessageBase);

AIActivityMessage.propTypes = {
  loadingStatesToWatch: PropTypes.arrayOf(PropTypes.object).isRequired,
  messageText: PropTypes.string,
};

AIActivityMessage.defaultProps = {
  loadingStatesToWatch: [],
  messageText: '',
};

export default AIActivityMessage;
