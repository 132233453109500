import _ from 'lodash';
import faker from 'faker';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AITextCompletionService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
    this.customerCompletionIndexItems = {};
  }

  requestAITextCompletion(query, callback, path, { orgId }) {
    const { customerId, conversationId, requestorId, type } = query;

    let completionText = this._getCompletionText(orgId, customerId, type);
    if (!completionText) {
      completionText = `${_.capitalize(faker.lorem.sentence())}. ${_.capitalize(faker.lorem.sentence())}`;
    }

    // Return result within 200ms - 800ms
    const randomMs = Math.random() * 600 + 200;

    setTimeout(() => {
      callback(null, {
        status: 200,
        statusText: statusText(200),
        response: {
          data: {
            conversationId,
            createdAt: new Date().toISOString(),
            customerId,
            requestorId,
            text: completionText,
          },
        },
      });
    }, randomMs);
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/ai-text-completion': {
          POST: this.requestAITextCompletion,
        },
      },
      this
    );
  }

  // We can specify different completion texts for different customers, types, and by invocation.
  // customerDb.aiTextCompletions is an array of text strings - each time we request a completion,
  // we iterate through to the next one. After we iterate through these, we'll continue doing so
  // in the same order back from the first.

  // We can also specify a default one for a customer to ensure we don't accidentally see some lorem ipsum
  // in a demo if a demo-er accidentally hits the wrong button.
  _getCompletionText(orgId, customerId, type) {
    let customerCompletionItemsByType = this.customerCompletionIndexItems[customerId];
    if (!customerCompletionItemsByType) {
      customerCompletionItemsByType = {};
      this.customerCompletionIndexItems[customerId] = customerCompletionItemsByType;
    }

    let customerIndexItem = customerCompletionItemsByType[type];
    if (!customerIndexItem) {
      customerIndexItem = {
        index: 0,
      };
      this.customerCompletionIndexItems[customerId][type] = customerIndexItem;
    } else {
      customerIndexItem.index += 1;
    }

    const customer = this._getCustomer(orgId, customerId);
    if (!customer.aiTextCompletions) {
      return null;
    }

    const completionsByType = customer.aiTextCompletions[type];
    if (!completionsByType) {
      return customer.aiTextCompletions.DEFAULT || null;
    }

    return (
      completionsByType[customerIndexItem.index % completionsByType.length] ||
      customer.aiTextCompletions.DEFAULT ||
      null
    );
  }

  _getCustomer(orgId, customerId) {
    return _.find(this.getDatabase(orgId).customers, { id: customerId });
  }
}
