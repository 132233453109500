import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="2 2 19 19" {...props}>
    <g className="emailIcon" fill="none" fillRule="evenodd">
      <g className="contactIcon-path" mask="url(#b)">
        <path d="M10.872 11.507c.477.23.846.214 1.314-.01 1.52-.732 3.067-1.424 4.75-2.2 0 1.168.002 2.202 0 3.236v1.6c0 .562-.33.9-.956.904-2.932.017-5.864.032-8.795-.023-.352-.007-.98-.433-.994-.686-.09-1.61-.046-3.226-.046-4.995 1.694.775 3.225 1.452 4.728 2.174z" />
        <path d="M15.657 7.672c.303.004.645.058.898.192.5.267.47.718-.044.96-1.533.72-3.07 1.44-4.627 2.122-.224.097-.614.05-.856-.057-1.465-.64-2.914-1.304-4.353-1.986-.217-.103-.52-.372-.487-.506.058-.23.31-.476.556-.603.23-.12.556-.124.84-.127 1.346-.01 2.69-.005 4.037-.005 1.346 0 2.692-.01 4.037.01z" />
      </g>
    </g>
  </IconContainer>
);
