import _ from 'lodash';
import { getAllLinkedAnswerIds } from 'models/answers/snippet_helpers';
import Snippet from 'models/answers/snippet';

const LOADING_ANSWER_LINK = 'Loading answer link';
export const INVALID_ANSWER_LINK = 'Invalid Answer Link';

export default function createAnswerLinksWithStatus(snippet, snippetLinks, isLoading) {
  if (!snippet) {
    return snippetLinks;
  }

  const answerLinkIds = getAllLinkedAnswerIds(snippet);
  let updatedLinks = snippetLinks;
  if (isLoading) {
    updatedLinks = _.map(answerLinkIds, id => {
      return Snippet.fromJs({ id, name: LOADING_ANSWER_LINK });
    });
    return updatedLinks;
  }

  _.forEach(answerLinkIds, id => {
    if (!_.find(snippetLinks, { id })) {
      updatedLinks.push(Snippet.fromJs({ id, name: INVALID_ANSWER_LINK }));
    }
  });
  return updatedLinks;
}
