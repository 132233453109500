import ConversationUpdater from 'actions/conversation/lib/conversation_updater';
import removeItemFromInbox from 'actions/inbox/lib/remove_item_from_inbox';

export default class MarkTaskClosed extends ConversationUpdater {
  run({ conversationItemId }) {
    let store = this.context.stores.conversationHistory;
    let taskItem = store.find(conversationItemId);
    taskItem.content.markClosed();
    taskItem.respond('AGENT', this.currentAgent.id);
    store.setPending(taskItem);

    let conversationItemUpdate = {
      content: {
        closedAt: taskItem.content.closedAt,
      },
    };

    this.context.gateways.task.update(this.currentAgent.id, taskItem.id, {
      id: conversationItemId,
      customerId: this.currentCustomerId,
      conversationItem: conversationItemUpdate,
    });

    removeItemFromInbox(this.context, taskItem.id, taskItem.content.closedAt);
  }
}
