import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AttributePresentation } from 'models/configuration/attribute_def';
import connect from 'components/lib/connect';
import connectLocal from 'components/lib/connect_local';
import ContactAddressIcon from 'components/lib/icons/contact_address_icon';
import { ContactAttributeDef } from 'models/customer_profile_def';
import ContactEmailIcon from 'components/lib/icons/contact_email_icon';
import ContactIdIcon from 'components/lib/icons/contact_id_icon';
import CustomerAddressField from 'components/customer/profile/customer_address_field';
import CustomerContactField from 'components/customer/profile/customer_contact_field';
import CustomerContactMenu, { Channel } from 'components/customer/profile/customer_contact_menu';
import CustomerEmailFields from 'components/customer/profile/customer_email_fields';
import CustomerPhoneFields from 'components/customer/profile/customer_phone_fields';
import CustomerProfileModel from 'models/customer_profile';
import Endpoint, { EndpointTypes } from 'models/endpoint';
import { ExternalCustomerAddressType } from 'models/customer_profile/external_customer_address';
import ExternalCustomerAddressField from 'components/customer/profile/external_customer_address_field';
import FbUserProfile from 'components/customer/profile/fb_user_profile';
import PhoneSpeakingIcon from 'components/lib/icons/phone_speaking';
import ProfileCard from 'components/customer/profile/profile_card';

export function CustomerProfile({
  companyInstagramAddresses,
  contactAttributes,
  customChannels,
  hasErrors,
  isAddingEmail,
  isAddingPhone,
  isCustomChannelsEnabled,
  isLoading,
  isResponsePending,
  isInstagramEnabled,
  isWhatsAppEnabled,
  isTwitterEnabled,
  displayExternalID,
  onAddChannel,
  onDoneAddingEmail,
  onDoneAddingPhone,
  profile,
}) {
  if (!isLoading && !profile) {
    return null;
  }

  const menu = <CustomerContactMenu isDisabled={isResponsePending || hasErrors} onSelectItem={onAddChannel} />;

  return (
    <ProfileCard data-aid="customerContactSection" isLoading={isLoading} menu={menu} title="Contact">
      {renderAddress()}
      {renderEmails()}
      {renderPhones()}
      {renderFacebook()}
      {renderExternalCustomerAddresses()}
      {renderExternalCustomerId()}
    </ProfileCard>
  );

  function renderAddress() {
    if (hasPresentation('address', AttributePresentation.HIDDEN)) {
      return null;
    }

    return (
      <div className="customerProfile-addressRow" key="address">
        <ContactAddressIcon className="customerProfile-addressIcon" />
        <CustomerAddressField isReadOnly={hasPresentation('address', AttributePresentation.VISIBLE)} />
      </div>
    );
  }

  function renderEmails() {
    return (
      <div className="customerProfile-emailRow" key="email">
        <ContactEmailIcon className="customerProfile-emailIcon" />
        <CustomerEmailFields
          customerId={profile.id}
          isAdding={isAddingEmail}
          key={profile.id}
          onDone={onDoneAddingEmail}
        />
      </div>
    );
  }

  function renderPhones() {
    return (
      <div className="customerProfile-phoneRow" key="phone">
        <ContactPhoneIcon className="customerProfile-phoneIcon" />
        <CustomerPhoneFields
          customerId={profile.id}
          isAdding={isAddingPhone}
          key={profile.id}
          onDone={onDoneAddingPhone}
        />
      </div>
    );
  }

  function renderFacebook() {
    if (
      !profile ||
      (_.isEmpty(profile.fbMessengerUserIds) && !profile.fbUserProfile) ||
      hasPresentation('fbUserProfile', AttributePresentation.HIDDEN)
    ) {
      return null;
    }

    return (
      <FbUserProfile
        className="customerProfile-facebookRow customerProfile-contactDetails-row customerProfile-contactDetails-readonly"
        key="facebook"
        profile={profile.fbUserProfile}
      />
    );
  }

  function renderExternalCustomerAddresses() {
    if (hasPresentation('externalCustomerAddresses', AttributePresentation.HIDDEN)) {
      return null;
    }

    if (_.isEmpty(profile.getExternalCustomerAddresses())) {
      return null;
    }

    const transformedInstagramAddresses = profile.instagramAddresses(companyInstagramAddresses);
    let externalCustomerAddresses = profile.getExternalCustomerAddresses();
    externalCustomerAddresses = _.filter(
      externalCustomerAddresses,
      address => address.type !== ExternalCustomerAddressType.INSTAGRAM_DIRECT
    );
    externalCustomerAddresses = _.concat(externalCustomerAddresses, transformedInstagramAddresses);

    return (
      <>
        {_.map(externalCustomerAddresses, address => (
          <ExternalCustomerAddressField
            address={address}
            customChannels={customChannels}
            isCustomChannelsEnabled={isCustomChannelsEnabled}
            isInstagramEnabled={isInstagramEnabled}
            isTwitterEnabled={isTwitterEnabled}
            isWhatsAppEnabled={isWhatsAppEnabled}
            key={address?.id}
          />
        ))}
      </>
    );
  }

  function renderExternalCustomerId() {
    if (
      hasPresentation('externalCustomerId', AttributePresentation.HIDDEN) ||
      displayExternalID === AttributePresentation.HIDDEN
    ) {
      return null;
    }

    const isReadonly =
      hasPresentation('externalCustomerId', AttributePresentation.VISIBLE) ||
      displayExternalID === AttributePresentation.VISIBLE;
    const rowClassNames = classNames('customerProfile-contactDetails-row', {
      readonly: isReadonly,
    });

    return (
      <div className="customerProfile-idRow" key="externalCustomerId">
        <ContactIdIcon className="customerProfile-idIcon" />
        <div className={rowClassNames}>
          <dd className="customerProfile-contactField customerProfile-idField">
            <CustomerContactField attr="externalCustomerId" isReadOnly={isReadonly} label="Customer ID" />
          </dd>
        </div>
      </div>
    );
  }

  function hasPresentation(attr, presentation) {
    const contactAttribute = _.find(contactAttributes, contactAttr => contactAttr.attr === attr);
    return contactAttribute && contactAttribute.presentation === presentation;
  }
}

const ContactPhoneIcon = styled(PhoneSpeakingIcon)`
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-left: 5px;
`;

CustomerProfile.propTypes = {
  companyInstagramAddresses: PropTypes.arrayOf(PropTypes.instanceOf(Endpoint)),
  contactAttributes: PropTypes.arrayOf(PropTypes.instanceOf(ContactAttributeDef)),
  hasErrors: PropTypes.bool,
  isAddingEmail: PropTypes.bool,
  isAddingPhone: PropTypes.bool,
  isLoading: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  isInstagramEnabled: PropTypes.bool,
  isWhatsAppEnabled: PropTypes.bool,
  isTwitterEnabled: PropTypes.bool,
  displayExternalID: PropTypes.string,
  onAddChannel: PropTypes.func.isRequired,
  onDoneAddingEmail: PropTypes.func.isRequired,
  onDoneAddingPhone: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(CustomerProfileModel),
};

const AddHandlingCustomerProfile = connectLocal({ mapSetStateToProps })(CustomerProfile);
export { AddHandlingCustomerProfile };

function mapSetStateToProps(setState) {
  return {
    onAddChannel: channelName => {
      if (channelName === Channel.EMAIL) {
        setState({ isAddingEmail: true });
      } else if (channelName === Channel.PHONE) {
        setState({ isAddingPhone: true });
      }
    },
    onDoneAddingEmail: () => setState({ isAddingEmail: false }),
    onDoneAddingPhone: () => setState({ isAddingPhone: false }),
  };
}

AddHandlingCustomerProfile.propTypes = {
  companyInstagramAddresses: PropTypes.arrayOf(PropTypes.instanceOf(Endpoint)),
  contactAttributes: PropTypes.arrayOf(PropTypes.instanceOf(ContactAttributeDef)),
  hasErrors: PropTypes.bool,
  isLoading: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  isInstagramEnabled: PropTypes.bool,
  isWhatsAppEnabled: PropTypes.bool,
  isTwitterEnabled: PropTypes.bool,
  displayExternalID: PropTypes.string,
  profile: PropTypes.instanceOf(CustomerProfileModel),
};

export default connect(mapStateToProps)(AddHandlingCustomerProfile);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const profileProvider = getProvider('profile');
  const defProvider = getProvider('customerProfileDef');
  const channelConfiguration = getProvider('channelConfiguration').get();
  const customChannelsProvider = getProvider('customChannels');
  const isCustomChannelsEnabled = getProvider('customChannelsConfig').get().enabled;
  const isInstagramEnabled =
    channelConfiguration && channelConfiguration.isChannelEnabled(EndpointTypes.INSTAGRAM_DIRECT);
  const isWhatsAppEnabled = channelConfiguration && channelConfiguration.isChannelEnabled(EndpointTypes.WHATSAPP);
  const companyInstagramAddresses =
    channelConfiguration && channelConfiguration.getEndpointsOfType(EndpointTypes.INSTAGRAM_DIRECT);
  let displayExternalID = undefined;
  if (!isFeatureEnabled('displayLegacyExternalId')) {
    const originalIntegration = getProvider('integrations').findBy({
      customerLookupSettings: { isOriginal: true },
    });
    displayExternalID = originalIntegration ? AttributePresentation.VISIBLE : AttributePresentation.HIDDEN;
  }

  return {
    companyInstagramAddresses,
    contactAttributes: _.get(defProvider.get(), 'contactAttributes'),
    customChannels: customChannelsProvider.findAll(),
    isCustomChannelsEnabled,
    hasErrors: !_(profileProvider.getErrors()).isEmpty(),
    isLoading: defProvider.isLoading() || profileProvider.isLoading() || customChannelsProvider.isLoading(),
    isResponsePending: profileProvider.isPending(),
    isInstagramEnabled,
    isWhatsAppEnabled,
    isTwitterEnabled: false,
    displayExternalID,
    profile: profileProvider.get(),
  };
}
