import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import Conversation from './conversation';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import { sortConversations } from 'models/customer';

export function ConversationList({ conversations, customerId, isLoading }) {
  if (!isLoading && !conversations.length) return null;

  return (
    <ExpandableProfileCard data-aid="conversationListCard" isLoading={isLoading} title="Conversations">
      {_.map(conversations, conversation => (
        <Conversation conversation={conversation} customerId={customerId} key={conversation.id} />
      ))}
    </ExpandableProfileCard>
  );
}

ConversationList.propTypes = {
  conversations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
  customerId: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps)(ConversationList);

function mapStateToProps({ getProvider }) {
  let conversations = getProvider('conversations');
  let topics = getProvider('topics');
  let profile = getProvider('profile').get();

  return {
    conversations: sortConversations(conversations.findAll()).reverse(),
    customerId: profile?.id,
    isLoading: conversations.isLoading() || topics.isLoading(),
  };
}
