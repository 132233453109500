import addCustomerToInbox from 'actions/inbox/lib/add_customer_to_inbox';
import Conversation from 'models/conversation';
import { updateConversationWithItems } from 'actions/conversation/lib/conversation_action_helpers';
import createConversationStatusChangeItem from 'scripts/domain/factories/conversation_item/create_conversation_status_change_item';
import { getLatestConversation } from 'models/customer';

export default class ReopenConversation {
  constructor(context) {
    this.context = context;
  }

  run() {
    let customerId = this.context.stores.currentLocation.get().customerId;

    const { conversations } = this.context.stores.customers.storesFor(customerId);
    let conversation = getLatestConversation(conversations.findAll());
    if (conversation.status !== Conversation.Status.CLOSED) {
      return;
    }

    let conversationStatusChangeItem = createConversationStatusChangeItem({
      agentId: this.currentAgent.id,
      conversationId: conversation.id,
      customerId,
      status: Conversation.Status.OPEN,
    });

    updateConversationWithItems(this.context, {
      conversationId: conversation.id,
      customerId,
      conversationItems: [conversationStatusChangeItem],
      conversationUpdates: {
        id: conversation.id,
        status: Conversation.Status.OPEN,
      },
    });

    addCustomerToInbox(this.context, { customerId });
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
