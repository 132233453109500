import React, { useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { AnswersListType } from 'models/answer_panel';
import AnswerFavorites from 'components/customer/answer_panel/search/answer_favorites';
import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import AnswerSearchInput from 'components/customer/answer_panel/search/answer_search_input';
import AnswerSearchResults from 'components/customer/answer_panel/search/answer_search_results';
import AnswerSuggestions from 'components/customer/answer_panel/search/answer_suggestions';
import AudienceSelector from 'components/customer/answer_panel/search/answer_search_audience_filter';
import ClearSelectedAnswer from 'actions/answers/clear_selected_answer';
import EmptyAnswerSearch from 'components/customer/answer_panel/search/empty_answer_search';
import LanguageSort from 'components/customer/answer_panel/search/answer_search_language_sort';
import PlaceholderAnswerSearch from 'components/customer/answer_panel/search/placeholder_answer_search';
import SuggestedReplyListItem from 'components/customer/answer_panel/search/suggested_reply_list_item';
import useExecuteAction from 'components/hooks/use_execute_action';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import useKeyboardNavigation from 'components/customer/answer_panel/search/use_keyboard_navigation';

export default function AnswerSearch() {
  const { answersList, audiences, focusedAnswer, languages, searchText } = useContext(AnswerPanelContext);
  const hasAnswers = answersList.answers.length > 0;
  const isFeatureEnabled = useIsFeatureEnabled();
  const isSuggestedReplyEnabled = isFeatureEnabled('suggestedReplies');

  const searchRef = useRef(null);
  const onKeyDown = useKeyboardNavigation(searchRef, answersList.answers, focusedAnswer && focusedAnswer.id);
  const shouldShowFilterBar = audiences.length > 0 || languages.length >= 2;

  let content;
  if (answersList.type === AnswersListType.SEARCH && searchText && hasAnswers) {
    if (isSuggestedReplyEnabled) {
      content = (
        <>
          <SuggestedReplyListItem />
          <AnswerSearchResults />
        </>
      );
    } else {
      content = <AnswerSearchResults />;
    }
  } else if (answersList.type === AnswersListType.SEARCH && searchText) {
    content = isSuggestedReplyEnabled ? null : <EmptyAnswerSearch />;
  } else if (answersList.type === AnswersListType.SUGGESTIONS && hasAnswers) {
    content = <AnswerSuggestions />;
  } else if (answersList.type === AnswersListType.FAVORITES && hasAnswers) {
    content = <AnswerFavorites />;
  } else {
    content = isSuggestedReplyEnabled ? null : <PlaceholderAnswerSearch />;
  }

  const executeAction = useExecuteAction();
  useEffect(() => {
    if (!hasAnswers && !searchText) {
      executeAction(ClearSelectedAnswer);
    }
  }, [hasAnswers, searchText]);

  function renderFilters() {
    return (
      <StyledFilterWrapper>
        <LanguageSort />
        <AudienceSelector />
      </StyledFilterWrapper>
    );
  }

  const hasContent = content !== null;
  return (
    <AnswerSearchContainer hasContent={hasContent} onKeyDown={onKeyDown} ref={searchRef} tabIndex="0">
      <AnswerSearchInput />
      {shouldShowFilterBar ? renderFilters() : <NoFilterSpacer />}
      {content}
    </AnswerSearchContainer>
  );
}

const StyledFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const AnswerSearchContainer = styled.div`
  border: 0;
  ${p =>
    p.hasContent &&
    css`
      border-bottom: 1px solid ${p => p.theme.colors.gray400};
    `}
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: ${p => (p.hasContent ? '35%' : '10%')};
  outline: none;
`;

const NoFilterSpacer = styled.div`
  height: 8px;
  width: 100%;
`;
