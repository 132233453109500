import { Factory } from 'rosie';
import moment from 'moment';

import './lib/uuid';

Factory.define('TopicSuggestions')
  .extend('Uuid')
  .attr('createdAt', () => moment().toISOString());

Factory.define('TopicSuggestionsWithDefaults')
  .extend('TopicSuggestions')
  .attr('customerId', 'customerId')
  .attr('conversationId', 'conversationId')
  .attr('modelVersion', 'modelVersion')
  .attr('addTopics', ['topic-1', 'topic-2']);
