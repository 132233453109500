import { AgentAvailability } from 'models/agent_status';
import { handleErrorInteractively, updateStore } from 'actions/agent_status/lib/agent_status';

export default class UpdateActiveReason {
  constructor(context) {
    this.context = context;
  }

  run({ reasonId }) {
    const availability = this.store.get().getAvailability();
    if (availability !== AgentAvailability.BUSY && availability !== AgentAvailability.READY) {
      return;
    }

    this.setNewReasonPending(reasonId);

    this.context.gateways.agentStatus
      .update({ activeReasonId: reasonId })
      .then(response => this.handleSuccess(response))
      .catch(err => handleErrorInteractively(this.context, err));

    const reason = this.context.stores.agentStatusReasons.find(reasonId);
    this.context.analytics.track('Agent Status Reason Updated', { id: reason.id, name: reason.name, type: 'ACTIVE' });
  }

  handleSuccess(response) {
    this.store.resetPending();
    updateStore(this.context, response);
  }

  setNewReasonPending(reasonId) {
    let status = this.store.get();
    status.updateActiveReason(reasonId);
    status.incrementVersion();
    this.store.setPending(status);
  }

  get store() {
    return this.context.stores.agentStatus;
  }
}
