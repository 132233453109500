import { trim } from 'lodash';

import getInitialHtml from 'components/composer/email/get_initial_html';
import { serializePlaintext, serializePlaintextFragment } from 'components/text_editor_new/lib/serialize_plaintext';

/**
 * Attempts to strip the greeting and signature snippets from the editor content and return only
 * the agent-typed part of the email.
 *
 * @param greetingSnippet
 * @param signatureSnippet
 * @param editor
 */
export function stripGreetingAndSignature({ greetingSnippet, signatureSnippet }, editor) {
  const [greetingHtml] = getInitialHtml({ greetingSnippet }, editor);
  const [signatureHtml] = getInitialHtml({ signatureSnippet }, editor);

  const greetingPlaintext = trim(getSaturatedPlaintext(greetingHtml, editor));
  const signaturePlaintext = trim(getSaturatedPlaintext(signatureHtml, editor));

  let emailContent = trim(serializePlaintext(editor));
  if (greetingPlaintext && emailContent.startsWith(greetingPlaintext)) {
    emailContent = emailContent.substring(greetingPlaintext.length);
  }
  if (signaturePlaintext && emailContent.endsWith(signaturePlaintext)) {
    emailContent = emailContent.substring(0, emailContent.length - signaturePlaintext.length);
  }

  return emailContent;
}

function getSaturatedPlaintext(unsaturatedHtml, editor) {
  const fragment = unsaturatedHtml ? editor.getSaturatedFragment(unsaturatedHtml) : [];
  return serializePlaintextFragment(fragment);
}
