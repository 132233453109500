import _ from 'lodash';
import { CancelToken, isCancel } from 'axios';
import Observable from 'scripts/lib/observable_mixin';
import PollingUpdater from './lib/polling_updater';
import qs from 'qs';
import StandardGateway from './lib/standard_gateway';

const DEFAULT_TIMEOUT = 10000;
const UPDATE_INTERVAL_MS = 10000;

export default class InboxHttpGateway {
  constructor(backend) {
    this._gateways = {
      groupInbox: new GroupInboxGateway(backend, this),
    };
    this.poller = new PollingUpdater(['group'], UPDATE_INTERVAL_MS);
  }

  init({ orgId, agentId }) {
    this._callMethodOnGateways('init', { orgId, agentId });
  }

  _callMethodOnGateways(methodName, ...params) {
    _.forIn(this._gateways, gateway => gateway[methodName] && gateway[methodName].apply(gateway, params));
  }

  fetchGroupInbox(groupId, payload) {
    this.cancelFetches();
    return this._gateways.groupInbox.fetch(groupId, payload);
  }

  handleFetchGroupInboxSuccess(customerDtos) {
    this.notifyObservers('handleFetchInboxSuccess', customerDtos);
    this.poller.poll('group', this.fetchGroupInbox.bind(this));
  }

  handleFetchInboxError(errorDto) {
    this.notifyObservers('handleFetchInboxError', errorDto);
  }

  requestGroupInbox(groupId, payload, isPolling) {
    if (isPolling) {
      this.poller.clearAndSet('group', [groupId, payload]);
    } else {
      this.poller.clear('group');
    }
    return this.fetchGroupInbox(groupId, payload);
  }

  unsubscribeFromGroupInbox() {
    this.poller.unsubscribe('group');
  }

  unsubscribeFromInboxes() {
    this._callMethodOnGateways('cancel');
    this.unsubscribeFromGroupInbox();
  }

  cancelFetches() {
    this._callMethodOnGateways('cancel');
    this.poller.clear('group');
  }
}

_.extend(InboxHttpGateway.prototype, Observable);
class GroupInboxGateway extends StandardGateway {
  constructor(backend, manager) {
    super(backend, { fetchUrl: `/api/v4/orgs/:orgId/routing-groups/:routingGroupId/inbox` });
    this.manager = manager;
  }

  cancel() {
    this.source && this.source.cancel();
  }

  fetch(routingGroupId, payload) {
    this.source = CancelToken.source();
    let config = {
      cancelToken: this.source.token,
      timeout: DEFAULT_TIMEOUT,
    };

    let url = this.endpoint.set({ routingGroupId }).fetchUrl;
    return this.http
      .get(requestUrl(url, payload), config)
      .then(dto => {
        this.manager.handleFetchGroupInboxSuccess(dto);
      })
      .catch(error => {
        if (!isCancel(error)) {
          this.manager.handleFetchInboxError(error);
        }
      });
  }
}

export function requestUrl(url, payload) {
  let query = qs.stringify(payload);
  return `${url}?${query}`;
}
