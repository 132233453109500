import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import Col from 'components/common/grid/col';
import connect from 'components/lib/connect';
import { EVENT_TABLE_DEF } from 'models/customer_profile_def';
import Grid from 'components/common/grid/grid';
import LongDate from 'components/lib/date/long_date';
import Row from 'components/common/grid/row';
import ShortDateRange from 'components/lib/date/short_date_range';
import SummaryCard, { SummaryHeader } from 'components/customer/summary/lib/summary_card';

function CustomerEventList({ currentConversationTopicIds, customAttributes, def, showEventsList, type }) {
  if (!def || !showEventsList) {
    return null;
  }

  let events = customAttributes[def.attr];
  if (_.isEmpty(events)) {
    return null;
  }

  let filteredEvents = _.filter(events, event => {
    return !event.activationTopicId || _.includes(currentConversationTopicIds, event.activationTopicId);
  });

  if (_.isEmpty(filteredEvents)) {
    return null;
  }

  return (
    <SummaryCard>
      {def.label && <SummaryHeader text={`${filteredEvents.length} ${def.label}`} />}
      <Grid>
        <div className={classnames('customerEvents-list', _.kebabCase(type))}>{filteredEvents.map(renderEvent)}</div>
      </Grid>
    </SummaryCard>
  );

  function renderEvent(event, i) {
    const hasImage = def.image && event[def.image.attr];
    const hasLink = def.url && event[def.url.attr];
    const hasDate = (def.date && event[def.date.attr]) || (def.end && event[def.end.attr]);
    const classNames = classnames('customerEvents-event', {
      'customerEvents-event-withImage': hasImage,
      'customerEvents-event-link': hasLink,
    });
    const imageColSpan = hasImage ? '8' : '';
    return (
      <Row className={classNames} key={i} onClick={hasLink ? () => openLink(hasLink) : _.noop}>
        {hasImage && (
          <Col className="customerEvents-event-image-wrapper">
            <img className="customerEvents-event-image" src={event[def.image.attr]} />
          </Col>
        )}
        <Col className="customerEvents-event-content" colSpan={imageColSpan}>
          <Row>
            <Col className="customerEvents-event-title">
              <b>{convertTitle(event[def.title.attr])}</b>
            </Col>
          </Row>
          <div className="customerEvents-event-details">
            <Row>
              {event[def.detail.label] && (
                <Col className="customerEvents-event-label" colSpan="4">
                  {event[def.detail.label]}
                </Col>
              )}
              <Col className="customerEvents-event-detail">{event[def.detail.attr]}</Col>
            </Row>
            {hasDate && (
              <Row className="customerEvents-event-dates">
                <Col colSpan="12">
                  <EventDate date={event[def.date.attr]} end={event[def.end.attr]} />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

CustomerEventList.propTypes = {
  customAttributes: PropTypes.object.isRequired,
  def: PropTypes.shape(EVENT_TABLE_DEF),
  showEventsList: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export { CustomerEventList };

function convertTitle(title) {
  if (title === 'LGB-SFO') {
    return (
      <div>
        {' '}
        <img className="customerEvents-plane-icon" src="/images/demo/travel/ion-icon-plane.svg" /> LGB |{' '}
        <img className="customerEvents-plane-icon" src="/images/demo/travel/ion-icon-plane-flip.svg" /> SFO{' '}
      </div>
    );
  } else if (title === 'SFO-LGB') {
    return (
      <div>
        {' '}
        <img className="customerEvents-plane-icon" src="/images/demo/travel/ion-icon-plane.svg" /> SFO |{' '}
        <img className="customerEvents-plane-icon" src="/images/demo/travel/ion-icon-plane-flip.svg" /> LGB{' '}
      </div>
    );
  }
  return title;
}

function openLink(url) {
  window.open(url, '_blank');
}

function EventDate({ date, end }) {
  if (date && end) {
    if (moment(date, 'YYYY-MM-DDTHH:mm:ss', true).isValid() && moment(end, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
      return <ShortDateRange endTime={end} startTime={date} />;
    }
    return (
      <div>
        {' '}
        {date} <br /> {end}{' '}
      </div>
    );
  }
  return <LongDate timestamp={date || end} />;
}

EventDate.propTypes = {
  date: PropTypes.string,
  end: PropTypes.string,
};

const CustomerEventListContainer = connect(mapStateToProps)(CustomerEventList);

function mapStateToProps({ getProvider, isFeatureEnabled }, { type }) {
  let currentConversation = getProvider('conversations').findBy({
    id: getProvider('currentLocation').get().currentConversationId,
  });
  let transactionsProvider = getProvider('transactions');

  return {
    currentConversationTopicIds: _(currentConversation).get('topicIds', []),
    customAttributes: _.get(getProvider('profile').get(), 'customAttributes') || {},
    def: _.get(getProvider('customerProfileDef').get(), type),
    showEventsList:
      isFeatureEnabled('demoMode') && !transactionsProvider.isLoading() && transactionsProvider.count() === 0,
    type,
  };
}

CustomerEventListContainer.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CustomerEventListContainer;
