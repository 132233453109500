import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import ExternalLink from 'components/lib/external_link';
import { H3 } from 'components/common/headers';
import InformationIcon from 'components/lib/icons/information_icon';
import ProfileCardDef from 'models/configuration/profile_card_def';
import ProfileCardType from 'models/configuration/profile_card_type';
import RightArrowIcon from 'components/lib/icons/right_arrow_icon';
import { setOverflowTitle } from 'components/lib/overflow_title';
import { StayAttributesPropTypes } from 'components/customer/summary/transactions/constants';
import { StayTransactionDef, TransactionDefType } from 'models/configuration/transaction_def';
import Tooltip from 'components/common/tooltip';
import TransactionGroup from './transaction_group';

export default function StayTransactions({ isLoading, now, transactionDef, transactions, onExpand, profileCardDef }) {
  let previousStays = []; // Stays where CheckOutTime is in the past, sorted by CheckInTime desc
  let otherStays = []; // Stays with invalid CheckInTime

  // Current and upcoming stays are sorted by CheckInTime asc
  let currentStays = []; // Stays where CheckInTime < now < CheckOutTime
  let futureStays = []; // Stays where CheckInTime is in the future

  _.forEach(transactions, transaction => {
    if (!transaction.attributes) {
      return;
    }

    let checkInTime = _.get(transaction, 'attributes.checkInTime');
    let checkOutTime = _.get(transaction, 'attributes.checkOutTime');
    let timezone = _.get(transaction, 'attributes.timezone');
    let momentCheckIn = timezone ? moment(checkInTime).utcOffset(timezone) : moment(checkInTime);
    let momentCheckOut = timezone ? moment(checkOutTime).utcOffset(timezone) : moment(checkOutTime);

    if (momentCheckOut.isAfter(moment(now))) {
      if (momentCheckIn.isAfter(moment(now))) {
        futureStays.push(transaction);
      } else {
        currentStays.push(transaction);
      }
    } else {
      if (momentCheckIn.isValid()) {
        previousStays.push(transaction);
      } else {
        otherStays.push(transaction);
      }
    }
  });

  previousStays = _.orderBy(previousStays, [stay => moment(stay.attributes.checkInTime).valueOf()], ['desc']);

  const numStays = transactions.length;

  return (
    <ExpandableProfileCard
      isEmpty={!numStays}
      isLoading={isLoading}
      limit={3}
      title={_.get(profileCardDef, 'label') || `${numStays} Stay${numStays === 1 ? '' : 's'}`}
    >
      <TransactionGroup
        groupClassName="stayTransaction-currentStays"
        groupName="Current & Future"
        headerRenderer={attributes => (
          <ExpandedStayTransactionHeader
            attributes={attributes}
            now={now || moment().format()}
            transactionDef={transactionDef}
          />
        )}
        key="current"
        onExpand={onExpand}
        shouldExpandFirst={currentStays.length > 0}
        shouldRenderImages={false}
        summaryRenderer={attributes => (
          <StayTransactionSummary attributes={attributes} transactionDef={transactionDef} />
        )}
        transactionDef={transactionDef}
        transactions={_.sortBy(currentStays.concat(futureStays), ['checkInTime'])}
      />

      {currentStays.length && (previousStays.length || otherStays.length) ? (
        <div className="stayTransaction-divider" />
      ) : null}
      <TransactionGroup
        groupClassName="stayTransaction-previousStays"
        groupName="Previous"
        headerRenderer={attributes => (
          <ExpandedStayTransactionHeader attributes={attributes} transactionDef={transactionDef} />
        )}
        key="previous"
        onExpand={onExpand}
        shouldRenderImages={false}
        summaryRenderer={attributes => (
          <StayTransactionSummary attributes={attributes} transactionDef={transactionDef} />
        )}
        transactionDef={transactionDef}
        transactions={previousStays.concat(otherStays)}
      />
    </ExpandableProfileCard>
  );
}

StayTransactions.propTypes = {
  isLoading: PropTypes.bool,
  now: PropTypes.string,
  onExpand: PropTypes.func,
  profileCardDef: PropTypes.instanceOf(ProfileCardDef),
  transactionDef: PropTypes.instanceOf(StayTransactionDef),
  transactions: PropTypes.arrayOf(PropTypes.shape({ attributes: StayAttributesPropTypes })),
};

export function ExpandedStayTransactionHeader({ attributes, now, transactionDef }) {
  const stayTitleRef = useRef();

  const { checkInTime, checkOutTime, title, address, neighborhood, timezone } = attributes;
  const headerClassNames = classnames('stayTransaction-expandedInformation-header', {
    'stayTransaction-expandedInformation-header-noOptionalAttributes': !_.some(
      _.map(transactionDef.attributes, def => attributes[def.attr])
    ),
  });

  const isCurrentStay = moment(now).isBetween(checkInTime, checkOutTime);
  const toolTipText = address || neighborhood ? _.join([address, neighborhood], '\n') : '';
  const titleText = _.trim(title) || '-';

  useEffect(() => {
    setOverflowTitle(stayTitleRef, titleText);
  });

  return (
    <div className={headerClassNames}>
      {isCurrentStay && <div className="stayTransaction-header-current">Current stay</div>}
      <div className="stayTransaction-header-title-wrapper">
        <StyledStayTitle className="stayTransaction-header-title" ref={stayTitleRef}>
          {titleText}
        </StyledStayTitle>
        {toolTipText && (
          <Tooltip className="stayTransaction-header-tooltip" message={toolTipText} position="right">
            <InformationIcon className="stayTransaction-header-tooltip-icon" />
          </Tooltip>
        )}
      </div>
      <div className="stayTransaction-header-timestamps">
        <div className="stayTransaction-header-timestamp">
          <div className="stayTransaction-header-timestamp-label">Check-in</div>
          <div>{formatStayTimestamp(checkInTime, timezone, 'ddd, MM/DD/YY', false)}</div>
          <div>{formatStayTimestamp(checkInTime, timezone, 'h:mm A', true)}</div>
        </div>
        <div className="stayTransaction-header-timestamp">
          <div className="stayTransaction-header-timestamp-label">Checkout</div>
          <div>{formatStayTimestamp(checkOutTime, timezone, 'ddd, MM/DD/YY', false)}</div>
          <div>{formatStayTimestamp(checkOutTime, timezone, 'h:mm A', true)}</div>
        </div>
      </div>
    </div>
  );
}

const StyledStayTitle = styled(H3)`
  font-weight: 600;
  line-height: 21px;
  width: calc(100% - 24px);
`;

ExpandedStayTransactionHeader.propTypes = {
  attributes: StayAttributesPropTypes.isRequired,
  now: PropTypes.string,
  transactionDef: PropTypes.instanceOf(StayTransactionDef).isRequired,
};

/**
 * Renders one "stay summary" row in the list of stays
 *
 * @param attributes
 * @returns {JSX.Element}
 * @constructor
 */
export function StayTransactionSummary({ attributes }) {
  const { name, url, checkInTime, checkOutTime, timezone } = attributes;

  // The area for the "name" can fit approx. 20 characters before it starts truncating. Avoid displaying the
  // tooltip for short names that do not need it
  return (
    <div className="stayTransaction-summary">
      <div className="stayTransaction-name">
        <ExternalLink
          attrName="url"
          className="stayTransaction-externalLink"
          displayOverflowTooltip
          onClick={evt => {
            // stop event bubbling to make sure it doesn't interfere with the wrapper click handlers
            evt && evt.stopPropagation();
          }}
          subType={TransactionDefType.STAY}
          text={name}
          type={ProfileCardType.TRANSACTIONS}
          url={url}
        />
      </div>
      <div className="stayTransaction-timestamps">
        <div className="stayTransaction-time">{formatStayTimestamp(checkInTime, timezone, 'MM/DD/YY')}</div>
        <div className="stayTransaction-icon">
          <RightArrowIcon className="stayTransaction-arrowIcon" />
        </div>
        <div className="stayTransaction-time">{formatStayTimestamp(checkOutTime, timezone, 'MM/DD/YY')}</div>
      </div>
    </div>
  );
}

StayTransactionSummary.propTypes = {
  attributes: StayAttributesPropTypes,
  transactionDef: PropTypes.instanceOf(StayTransactionDef),
};

function formatStayTimestamp(ts, tz, format, shouldIncludeTz = false) {
  if (!ts || !moment(ts).isValid()) {
    return 'N/A';
  }

  if (tz) {
    if (moment.tz.zone(tz)) {
      if (shouldIncludeTz) {
        format += ' z';
      }

      return moment(ts)
        .tz(tz)
        .format(format);
    }

    return moment(ts)
      .utcOffset(tz)
      .format(format);
  }

  return moment(ts).format(format);
}
