import _ from 'lodash';

import createModel, { prop } from 'models/lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ToastContentClasses, { ToastType } from './toast_content_classes';
import toastContentFromJs from './toast_content_from_js';

const DEFAULT_DURATION = 8000;

const Toast = createModel({
  modelName: 'Toast',
  properties: {
    createdAt: prop(String).isRequired,
    content: prop()
      .oneOf(..._.map(ToastContentClasses, clazz => clazz[1]))
      .fromJs(toastContentFromJs).isRequired,
    duration: prop(Number).default(DEFAULT_DURATION).isRequired,
    id: prop(String).isRequired,
    type: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this({ createdAt: new Date().toISOString(), id: IdGenerator.newId(), ...attrs });
    },
    overrideFromJs(fromJs) {
      return attrs => {
        attrs.content.type = attrs.type;
        return fromJs(attrs);
      };
    },
    Type: ToastType,
  },
});

export default Toast;
