import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class GreetingSuggestionsService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(suggAttrs, callback, path, { orgId, agentId }) {
    let suggestions = this._getSuggestions(orgId, agentId);
    callback(null, { status: 200, statusText: statusText(200), response: suggestions });
  }

  _getSuggestions(orgId, agentId) {
    let database = this.getDatabase(orgId);

    return database.greetingSuggestions;
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/agents/:agentId/greeting-suggestions': {
          GET: this.find,
        },
      },
      this
    );
  }
}
