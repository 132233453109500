import React from 'react';
import PropTypes from 'prop-types';

import OrderStatusCard from './order_status_card_item';
import { dataDisplayType } from 'models/message_automation_content';

export default function DataDisplayItem(props) {
  switch (props.type) {
    case dataDisplayType.ORDER_STATUS: {
      return (
        <OrderStatusCard
          config={props.displayContent.config}
          currencyCode={props.displayContent.currencyCode}
          customerOrderUrl={props.displayContent.customerOrderUrl}
          fulfillments={props.displayContent.fulfillments}
          id={props.displayContent.id}
          itemCount={props.displayContent.itemCount}
          products={props.displayContent.products}
          totalAmount={props.displayContent.totalAmount}
        />
      );
    }
    default:
      return null;
  }
}

DataDisplayItem.propTypes = {
  type: PropTypes.string.isRequired,
  displayContent: PropTypes.object.isRequired,
};
