import { createContentModel, isEmpty } from './composition_content_shared';

const ChatCompositionContent = createContentModel({
  modelName: 'ChatCompositionContent',

  properties: {
    bodyHtml: String,
    sessionId: String,
    sessionItemId: String,
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },
});

ChatCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB = 15;

export default ChatCompositionContent;
