import HtmlToText from 'html-to-text';

import analytics from 'scripts/lib/analytics';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import haveExistingRequest from './_have_existing_request';
import postAITextCompletionRequest from './_post_ai_text_completion_request';
import qconsole from 'scripts/lib/qconsole';

export default class RequestAITextCompletion {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, requestorId, text, type, composition }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      qconsole.log(`RequestAITextCompletion: received request for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }
    if (!requestorId) {
      qconsole.error('RequestAITextCompletion: must include "requestorId". Ignoring.');
      return;
    }
    if (!text) {
      qconsole.error('RequestAITextCompletion: must include "text". Ignoring.');
      return;
    }

    if (haveExistingRequest(this.context, { customerId, id: requestorId })) {
      return;
    }

    const rewriteId = IdGenerator.newId();
    postAITextCompletionRequest(this.context, { customerId, requestorId, rewriteId, text, type });

    const totalLength =
      (composition && HtmlToText.fromString(composition.content.bodyHtml, { wordwrap: null }).length) || -1;
    analytics.track('Rewrite Requested', {
      customerId,
      conversationId: composition?.conversationId || '',
      compositionId: composition?.id || '',
      contentType: composition?.contentType() || 'no content type',
      rewriteId,
      rewriteType: type,
      selectedLength: text.length,
      totalLength,
    });
  }
}
