import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export default function FocusButton({ isFocusOn, numPreferenceButtons }) {
  let className = isFocusOn ? 'focus' : '';

  switch (numPreferenceButtons) {
    case 1:
      className += ' one';
      break;
    case 2:
      className += ' two';
      break;
    case 3:
      className += ' three';
  }

  return (
    <StyledButton className={className}>
      <div className="svg-wrapper">
        <svg className="availabilityIcon availabilityIcon-focusSelected" fill="none" height="32" width="32">
          <circle cx="16" cy="16" fill="#fff" r="15.5" stroke="#111" />
          <path d="M18.073 22.154c1.766-.473 3.206-1.708 3.912-3.33.445-.968-.566-1.934-1.514-1.486a3.11 3.11 0 0 1-4.366-1.985 3.103 3.103 0 0 1 .814-3.032c.75-.735.165-2.01-.88-1.924a7.98 7.98 0 0 0-1.072.166 5.997 5.997 0 0 0-4.242 7.348 5.982 5.982 0 0 0 7.348 4.243ZM15.26 11.649c.294-.078.578-.106.884-.14a4.238 4.238 0 0 0-1.124 4.136c.685 2.558 3.561 3.874 5.94 2.702a4.84 4.84 0 0 1-3.177 2.72 4.871 4.871 0 0 1-5.97-3.447c-.692-2.58.843-5.273 3.447-5.97Z" />
        </svg>
      </div>
      <div className="focus-text">Focus on</div>
    </StyledButton>
  );
}

FocusButton.propTypes = {
  isFocusOn: PropTypes.bool.isRequired,
  numPreferenceButtons: PropTypes.number.isRequired,
};

const StyledButton = styled.div`
  align-items: center;
  border-radius: 20px;
  border: none;
  display: flex;
  height: 32px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: relative;
  transition: width 0.5s;
  width: 32px;
  z-index: 10;
  > .svg-wrapper {
    display: flex;
    margin-left: 56px;
    transition: margin-left 0.5s;
    > svg {
      > circle {
        display: inherit;
      }
      > path {
        // icon
        fill: #242424;
      }
    }
  }
  > .focus-text {
    color: transparent;
    min-width: 56px;
    opacity: 0;
    transition: opacity 0.5s;
  }
  &.focus {
    background-color: #009b00;
    // number of preference buttons to cover over
    &.one {
      transition: width 0.5s, left 0.5s;
      width: 117px;
      left: -46px;
    }
    &.two {
      transition: width 0.5s;
      width: 117px;
    }
    &.three {
      transition: width 0.5s;
      width: 152px;
    }
    > .svg-wrapper {
      margin-left: -10px;
      transition: margin-left 0.5s;
      > svg {
        > circle {
          display: none;
        }
        > path {
          // icon
          fill: #fff;
        }
      }
    }
    > .focus-text {
      color: #fff;
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
`;
