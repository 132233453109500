import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CommunicationError from 'components/customer/communication_error';
import ConversationItem from 'models/conversation_item';

export default function ItemCommError({ className, item, message, onClick }) {
  if (!item.content.hasErrorStatus()) {
    return null;
  }

  let classNames = classnames('conversationItem-communicationError', className);

  return (
    <CommunicationError
      className={classNames}
      helpDocHref={(item.content.getErrorMessageHelpDocHref && item.content.getErrorMessageHelpDocHref()) || ''}
      message={
        message || (item.content.getErrorMessage && item.content.getErrorMessage()) || 'An unknown error occurred.'
      }
      onClick={onClick}
    />
  );
}

ItemCommError.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func,
};
