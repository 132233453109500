import React, { useState, useEffect } from 'react';
import ReactProgressBar from 'react-progress-bar-plus';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';

const ProgressBar = ({ intervalTime = 20, isVisible = false, mode = 'page', 'data-aid': dataAid }) => {
  const [percent, setPercent] = useState(-1);

  useEffect(() => {
    if (isVisible) {
      setPercent(0);
    } else if (percent !== -1) {
      setPercent(100);
      const timer = setTimeout(() => setPercent(-1), 250);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  const ProgressBarComponent = mode === 'inline' ? ProgressBarInlineMode : ProgressBarPageMode;

  return (
    <ProgressBarComponent
      autoIncrement
      data-aid={dataAid}
      intervalTime={intervalTime}
      percent={percent}
      spinner={false}
    />
  );
};

const ProgressBarPageMode = styled(ReactProgressBar)`
  &.react-progress-bar {
    left: 0;
    opacity: 1;
    position: fixed;
    top: 0;
    transition: opacity 10ms, width 100ms;
    visibility: visible;
    width: 100%;
    z-index: 99;

    &.react-progress-bar-on-top {
      height: 100%;
    }

    &.react-progress-bar-hide {
      opacity: 0;
      visibility: hidden;
      z-index: -10;
    }

    .react-progress-bar-percent {
      // "theme" is undefined in the test mode
      background: ${p => get(p, 'theme.colors.green400', '#009b00')};
      box-shadow: none;
      height: 5px;
      transition: all 100ms ease;
    }
  }
`;

const ProgressBarInlineMode = styled(ProgressBarPageMode)`
  &.react-progress-bar {
    //  "theme" is undefined in the test mode
    background-color: ${p => get(p, 'theme.colors.gray400', '#cecece')};
    border-radius: 10px;
    height: 8px;
    position: relative;

    .react-progress-bar-percent {
      border-radius: 10px;
      height: 8px;
    }
  }
`;

ProgressBar.propTypes = {
  /** autoincrement speed, ms */
  intervalTime: PropTypes.number,
  /** to control the visibility of the ProgressBar */
  isVisible: PropTypes.bool,
  /** 'page' for displaying page progress, 'inline' for using inside a component */
  mode: PropTypes.oneOf(['page', 'inline']),
};

export default ProgressBar;
