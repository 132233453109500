import React from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import DismissNotification from 'actions/notification/dismiss_notification';

export function DismissNotificationButton({ buttonText = 'Dismiss', notificationId, onClick, buttonType }) {
  return (
    <div className="notification-buttonContainer">
      <Button
        buttonType={buttonType ? ButtonTypes.SECONDARY : ButtonTypes.PRIMARY}
        className="notification-button notification-dismiss"
        contrast
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </div>
  );

  function handleClick(ev) {
    ev.stopPropagation();

    if (onClick) {
      onClick(notificationId);
    }
  }
}

DismissNotificationButton.propTypes = {
  buttonText: PropTypes.string,
  notificationId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default connect((context, props) => props, mapExecuteToProps)(DismissNotificationButton);

function mapExecuteToProps(executeAction) {
  return {
    onClick: notificationId => executeAction(DismissNotification, notificationId),
  };
}
