import classnames from 'classnames';
import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import InboxIcon from 'components/lib/icons/inbox_icon';

export class SearchResult extends React.Component {
  render() {
    const {
      agentButtons,
      agentClassName,
      agentCount,
      agentEmail,
      agentId,
      agentName,
      agentReadyCount,
      inboxButtons,
      inboxClassName,
      onAgentSelect,
      onInboxSelect,
      routingGroupName,
    } = this.props;

    if (!agentId) {
      let agentCountDetail = agentCount + (agentCount === 1 ? ' agent' : ' agents');
      if (agentReadyCount !== undefined) {
        agentCountDetail = agentCountDetail + ', ' + agentReadyCount + ' ready';
      }

      return (
        <div className={classnames('selectableSearchResults', inboxClassName)} onClick={onInboxSelect}>
          <div className="selectableSearchResults-description">
            <span className="selectableSearchResults-main">
              <span className="selectableSearchResults-main-text">
                {routingGroupName} <span className="selectableSearchResults-detail">{agentCountDetail}</span>
              </span>
            </span>
          </div>
          {inboxButtons}
        </div>
      );
    }

    return (
      <div className={classnames('selectableSearchResults', agentClassName)} onClick={onAgentSelect}>
        <div className="selectableSearchResults-content">
          <span className="selectableSearchResults-main">
            <span className="selectableSearchResults-main-text">
              {agentName} <span className="selectableSearchResults-detail">{agentEmail}</span>
            </span>
          </span>
          <div className="selectableSearchResults-info">
            <InboxIcon className="selectableSearchResults-info-icon" />
            <span className="selectableSearchResults-info-text">{routingGroupName}</span>
          </div>
        </div>
        {agentButtons}
      </div>
    );
  }
}

SearchResult.propTypes = {
  agentClassName: PropTypes.string,
  agentCount: PropTypes.number,
  agentEmail: PropTypes.string,
  agentId: PropTypes.string,
  agentName: PropTypes.string,
  agentReadyCount: PropTypes.number,
  inboxClassName: PropTypes.string,
  readyAgentIds: PropTypes.arrayOf(PropTypes.string),
  routingGroupId: PropTypes.string,
  routingGroupName: PropTypes.string,

  agentButtons: PropTypes.element,
  inboxButtons: PropTypes.element,
  onAgentSelect: PropTypes.func,
  onInboxSelect: PropTypes.func,
};

function mapStateToProps({ getProvider }, { readyAgentIds, routingGroupId }) {
  let routingGroup = getProvider('routingGroups').findBy({ id: routingGroupId });
  return {
    agentCount: routingGroup && routingGroup.agentIds.length,
    agentReadyCount: routingGroup && readyAgentIds && intersection(routingGroup.agentIds, readyAgentIds).length,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onAgentSelect: () => {
      props.onAgentSelect &&
        props.onAgentSelect({
          agentId: props.agentId,
          routingGroupId: props.routingGroupId,
        });
    },
    onInboxSelect: () => {
      props.onInboxSelect &&
        props.onInboxSelect({
          agentId: props.agentId,
          routingGroupId: props.routingGroupId,
        });
    },
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(SearchResult);
