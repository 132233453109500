import createModel, { prop } from 'models/lib/create_model';

const AgentMentionedInTaskComment = createModel({
  modelName: 'AgentMentionedInTaskComment',

  properties: {
    agentId: prop(String).isRequired,
    customerId: prop(String).isRequired,
    taskCommentId: prop(String).isRequired,
    taskId: prop(String).isRequired,
  },
});

export default AgentMentionedInTaskComment;
