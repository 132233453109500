import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePlateEditorState, focusEditor, isCollapsed, usePlateEditorRef, ELEMENT_LINK } from '@udecode/plate';

import { EVENT_EDIT_INLINE_LINK } from 'components/composer/inline_links/inline_link';
import { getNormalizedUrl } from 'components/lib/linkify_it';
import { hasPluginOfType } from 'components/text_editor_new/lib/has_plugin_of_type';
import { LinkEditorPopover } from 'components/composer/inline_links/link_editor_popover';
import {
  MARK_LINK_SELECTION,
  MARK_LINK_SELECTION_FIRST_NODE,
} from 'components/text_editor_new/plugins/inline_links/create_link_selection_plugin';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import qconsole from 'scripts/lib/qconsole';
import { usePopoverMenu } from 'components/common/menu';
import { useEditorEventCallback, useKeyboardHotkeyCallback } from 'components/composer/inline_links/hooks';

const DEFAULT_URL = '';

function LinkEditorBase({ hotKey }) {
  const { targetRef, setTargetRef, isOpen, onClose, onOpen } = usePopoverMenu();
  const [linkUrl, setLinkUrl] = useState(DEFAULT_URL);
  const [isEditing, setIsEditing] = useState(false);
  const disableLinkEditing = useRef(true);
  const shouldRemoveMarks = useRef(false);

  const editor = usePlateEditorState();
  disableLinkEditing.current = !editor.selection || (isCollapsed(editor.selection) && !editor.isLinkSelected());

  /**
   * Adds editor marks around the region that is being edited as a link. We use these marks to
   * calculate the popover target DOM node, and also to render the edited area with a different
   * style/color. Adding marks will result in splitting/rearranging editor nodes
   */
  const addSelectionMarks = useCallback(() => {
    if (editor.setLinkSelectionMarks) {
      editor.setLinkSelectionMarks();
      shouldRemoveMarks.current = true;
    }
  }, [editor]);

  /**
   * Remove previously inserted editor marks and recombine the nodes back
   */
  const removeSelectionMarks = useCallback(() => {
    if (editor.removeLinkSelectionMarks && shouldRemoveMarks.current) {
      editor.removeLinkSelectionMarks();
      shouldRemoveMarks.current = false;
    }
  }, [editor]);

  const restoreEditorSelection = useCallback(
    collapseSelection => {
      setImmediate(() => {
        if (!editor.selection) return;
        try {
          const focusTarget = collapseSelection ? editor.selection.focus : editor.selection;
          focusEditor(editor, focusTarget);
        } catch (err) {
          // These random errors are non-actionable, so there is no point in sending a full sentry
          qconsole.log('ERROR: could not re-focus editor after the link editor was closed', err);
        }
      });
    },
    [editor]
  );

  // By the time we are here, we should already have the "link selection" marks inserted. Try to get
  // the DOM node corresponding to the first mark so that the popup would appear just above the selection.
  //
  // We have to do the DOM querying "out-of-band" because slate-react runs the DOM mutations in a
  // semi-async fashion and by the time the marks are in the editor, the DOM is not yet updated.
  //
  // In some cases (e.g. when the selection is collapsed and the inserted mark has zero width) we
  // end up with a situation when the node does not have the `MARK_LINK_SELECTION_FIRST_NODE` tag, so
  // we try to search for the `MARK_LINK_SELECTION` and only then give up.
  function getPopupTargetAsync() {
    const firstMarkedNodeQuery = `[data-${MARK_LINK_SELECTION}][data-${MARK_LINK_SELECTION_FIRST_NODE}]`;
    const fallbackNodeQuery = `[data-${MARK_LINK_SELECTION}]`;

    return new Promise(resolve => {
      setTimeout(() => {
        const domNode = document.query(firstMarkedNodeQuery) || document.query(fallbackNodeQuery);
        resolve(domNode);
      }, 0);
    });
  }

  const openLinkEditor = useCallback(() => {
    if (isOpen || disableLinkEditing.current) return;
    const linkSelected = editor.selection && editor.isLinkSelected();

    addSelectionMarks();
    if (linkSelected) {
      const [linkNode] = editor.getCurrentLinkNode();
      setIsEditing(true);
      setLinkUrl(linkNode.url);
    }

    getPopupTargetAsync().then(node => {
      if (node) {
        setTargetRef(node);
        onOpen();
      }
    });
  }, [addSelectionMarks, editor, isOpen, onOpen, setTargetRef]);

  const closeLinkEditor = useCallback(() => {
    if (isOpen) {
      setIsEditing(false);
      setLinkUrl(DEFAULT_URL);
      onClose();
      removeSelectionMarks();
    }
  }, [isOpen, onClose, removeSelectionMarks]);

  const onCreateLink = useCallback(
    newUrl => {
      removeSelectionMarks();
      editor.addLink(getNormalizedUrl(newUrl));
      closeLinkEditor();
      restoreEditorSelection(true);
    },
    [closeLinkEditor, editor, removeSelectionMarks, restoreEditorSelection]
  );

  const onUpdateLink = useCallback(
    newUrl => {
      removeSelectionMarks();
      const linkNode = editor.getCurrentLinkNode();
      editor.editLink(linkNode, getNormalizedUrl(newUrl));
      closeLinkEditor();
      restoreEditorSelection(true);
    },
    [closeLinkEditor, editor, removeSelectionMarks, restoreEditorSelection]
  );

  const onRemoveLink = useCallback(() => {
    removeSelectionMarks();
    const linkNode = editor.getCurrentLinkNode();
    if (!linkNode) return;

    // Let normalizer handle removing the link node
    editor.editLink(linkNode, '');
    closeLinkEditor();
    restoreEditorSelection(true);
  }, [closeLinkEditor, editor, removeSelectionMarks, restoreEditorSelection]);

  /**
   * We can cancel editing in two ways: by pressing "Escape" or by clicking somewhere in the editor. In the first
   * case, we need to restore the selection; in the second case, Slate will handle selection change for us.
   */
  const onCancel = useCallback(
    restoreSelection => {
      if (isOpen) {
        closeLinkEditor();
        restoreSelection && restoreEditorSelection(false);
      }
    },
    [closeLinkEditor, isOpen, restoreEditorSelection]
  );

  const onLinkClickEvent = useCallback(() => {
    if (!isOpen && !disableLinkEditing.current) {
      openLinkEditor();
    }
  }, [isOpen, openLinkEditor]);

  const onKeyboardShortcut = useCallback(
    evt => {
      evt.preventDefault();
      if (!isOpen && !disableLinkEditing.current) {
        openLinkEditor();
      }
    },
    [isOpen, openLinkEditor]
  );

  useKeyboardHotkeyCallback(editor, hotKey, onKeyboardShortcut);
  useEditorEventCallback(editor, EVENT_EDIT_INLINE_LINK, onLinkClickEvent);

  const onSubmit = isEditing ? onUpdateLink : onCreateLink;
  return (
    <OutsideClickHandler onClickOutside={() => onCancel(false)}>
      <LinkEditorPopover
        horizontalPosition="center"
        initialValue={linkUrl}
        isEditing={isEditing}
        isOpen={isOpen}
        onClose={() => onCancel(true)}
        onRemove={onRemoveLink}
        onSubmit={onSubmit}
        targetRef={targetRef}
        verticalPosition="top"
      />
    </OutsideClickHandler>
  );
}

LinkEditorBase.propTypes = {
  hotKey: PropTypes.string,
};

export function LinkEditor(props) {
  const editor = usePlateEditorRef();
  return hasPluginOfType(editor, ELEMENT_LINK) ? <LinkEditorBase {...props} /> : null;
}
