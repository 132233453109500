import _ from 'lodash';
import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';

export function getPrevious(value, block) {
  const previousBlock = value.document.getPreviousBlock(block.key);
  const previousDepth = previousBlock ? value.document.getDepth(previousBlock.key) : -1;
  return [previousBlock, previousDepth];
}
export function getNodeOfType(value, type) {
  return value.blocks.filter(block => block.type === type).first();
}
export function isList(value) {
  return (
    value.blocks.some(block => block.type === LIST_ITEM) ||
    value.blocks.some(block => nodeHasParentOfType(value, block, LIST_ITEM))
  );
}
export function isListNode(node) {
  return _.includes([UNORDERED_LIST, ORDERED_LIST], node.type);
}
export function nodeHasParentOfType(value, node, type) {
  return !!value.document.getClosest(node.key, parent => parent.type === type);
}
function hasParentOfType(value, type) {
  return value.blocks.some(block => !!value.document.getClosest(block.key, parent => parent.type === type));
}
export function isUnorderedList(value) {
  return hasParentOfType(value, UNORDERED_LIST);
}
export function isOrderedList(value) {
  return hasParentOfType(value, ORDERED_LIST);
}
function onlyRemove(editor, type) {
  return editor.unwrapBlock(type).focus();
}
export function onlyRemoveUnorderedList(editor) {
  return onlyRemove(editor, UNORDERED_LIST);
}
export function onlyRemoveOrderedList(editor) {
  return onlyRemove(editor, ORDERED_LIST);
}
export function getNonListParent(editor, node) {
  const { value } = editor;
  const { document } = value;
  let parent = node;

  while (parent !== document) {
    parent = document.getParent(parent.key);
    if (!_.includes([UNORDERED_LIST, ORDERED_LIST, LIST_ITEM], parent.type)) {
      break;
    }
  }
  return parent;
}
export function getTopListParent(editor, node) {
  const isList = n => _.includes([UNORDERED_LIST, ORDERED_LIST], n.type);

  let currentNode = node;
  let parent;
  while (currentNode !== editor.value.document) {
    const nextParent = editor.value.document.getParent(currentNode.key);
    if (nextParent && isList(nextParent)) {
      parent = nextParent;
    } else if (nextParent && !isList(nextParent)) {
      break;
    }
    currentNode = nextParent;
  }

  return parent;
}

export function getNearestList(editor) {
  let currentNode = editor.value.blocks.first();
  while (
    currentNode.type !== UNORDERED_LIST &&
    currentNode.type !== ORDERED_LIST &&
    currentNode !== editor.value.document
  ) {
    currentNode = editor.value.document.getParent(currentNode.key);
  }
  return currentNode === editor.value.document ? null : currentNode;
}

export function getNearestListItem(editor) {
  let currentNode = editor.value.blocks.first();
  while (currentNode.type !== LIST_ITEM && currentNode !== editor.value.document) {
    currentNode = editor.value.document.getParent(currentNode.key);
  }
  return currentNode === editor.value.document ? null : currentNode;
}

export function getNearestListItemFromNode(editor, node) {
  while (node.type !== LIST_ITEM && node !== editor.value.document) {
    node = editor.value.document.getParent(node.key);
  }
  return node === editor.value.document ? null : node;
}
