import analytics from 'scripts/lib/analytics';
import qconsole from 'scripts/lib/qconsole';

export default class CancelAITextCompletion {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, requestorId, composition, source }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      qconsole.log(`CancelAITextCompletion: received request for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    const { aiTextCompletions } = this.context.stores.customers.storesFor(customerId);
    const completion = aiTextCompletions.findBy({ id: requestorId });
    aiTextCompletions.remove(requestorId);

    if (source === 'user' && completion) {
      analytics.track('Rewrite Rejected', {
        customerId,
        conversationId: composition?.conversationId,
        compositionId: composition?.id,
        contentType: composition?.contentType(),
        rewriteId: completion?.rewriteId,
        rewriteType: completion?.type,
        source,
      });
    }
  }
}
