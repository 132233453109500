import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { AggregationLevel } from 'models/reports/report_configs';
import {
  createReportDateCellContent,
  getDatesForCell,
  ReportDurationCellContent,
  ReportNumberCellContent,
  ReportTextCellContent,
} from './lib/cell_content';
import { SORT_PARAMETERS, getNextSortOrder } from './lib/sort_control';
import { getDatesInRange } from 'models/location/reports';
import ReportTable from './lib/report_table';

import connect from 'components/lib/connect';

export class IvrEndStateReport extends React.Component {
  constructor(props) {
    super(props);

    this.report = props.report.init();
    const initialSortIndexes = _.range(_.size(this.props.report.data.endStatesByTime));
    const initialSortMetric = 'time';
    const initialSort = SORT_PARAMETERS.ASC;
    this.state = {
      sortedRowOrder: sortByOrder(
        initialSortIndexes,
        initialSortMetric,
        initialSort,
        this.report.getDataForRowAndMetric
      ),
      currentSort: { [initialSortMetric]: initialSort },
    };

    this.changeTableSort = this.changeTableSort.bind(this);
  }

  /* render */
  render() {
    if (!this.props.endpointNumber) {
      return null;
    }
    const { startDate, endDate } = this.props;
    const { timezone } = this.report;
    const reportStartDate = moment.tz(startDate, timezone);
    const reportEndDate = moment
      .tz(endDate, timezone)
      .add(1, 'd')
      .subtract(1, 'ms');

    let cols = [
      {
        header: 'Date',
        ref: 'time',
        cellType: createReportDateCellContent(this.props.aggregationLevel),
      },
      {
        header: 'IVR End State',
        ref: 'endStateDescription',
        cellType: ReportTextCellContent,
      },
      {
        header: 'Number of calls',
        ref: 'count',
        cellType: ReportNumberCellContent,
      },
      {
        header: 'Avg. Time In IVR',
        ref: 'duration',
        cellType: ReportDurationCellContent,
      },
    ];

    cols = cols.map(col => {
      col.renderCell = cellProps => {
        const rowIndex = this.state.sortedRowOrder[cellProps.rowIndex];
        const value = this.report.getDataForRowAndMetric(rowIndex, col.ref);
        const date = this.report.getDataForRowAndMetric(rowIndex, 'time');
        const dates = getDatesForCell({
          minStartDate: reportStartDate,
          maxEndDate: reportEndDate,
          date: moment.tz(date, timezone),
        });
        return React.createElement(col.cellType, {
          value,
          date,
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      };
      return col;
    });

    return (
      <ReportTable
        changeSort={this.changeTableSort}
        columns={cols}
        currentSort={this.state.currentSort}
        rowsCount={_.size(this.props.report.data.endStatesByTime)}
        windowWidth={this.props.windowWidth}
      />
    );
  }

  changeTableSort(metric) {
    let sort = getNextSortOrder(this.state.currentSort[metric]);
    let order = sortByOrder(this.state.sortedRowOrder, metric, sort, this.report.getDataForRowAndMetric);
    this.setState({
      sortedRowOrder: order,
      currentSort: { [metric]: sort },
    });
  }
}

const sortByOrder = (sortedRowOrder, metric, sort, getDataForDayAndMetric) =>
  _.orderBy(
    sortedRowOrder,
    rowIndex => {
      let val =
        metric === 'date'
          ? moment(getDataForDayAndMetric(rowIndex, metric), ['YYYY-MM-DD']).valueOf()
          : getDataForDayAndMetric(rowIndex, metric);
      // Some values (durations) may be returned as null which should be treated as <0 for sorting
      return val !== null ? val : -1;
    },
    [sort.toLowerCase()]
  );

IvrEndStateReport.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  endDate: PropTypes.string.isRequired,
  endpointNumber: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

let mapStateToProps = function(context, { windowWidth }) {
  const report = context.getProvider('report').get();

  let currentLocation = context.getProvider('currentLocation').get();
  let startDate = currentLocation.getStartAt();
  let endDate = currentLocation.getEndAt();
  let endpointNumber = currentLocation.getEndpointNumber();

  return {
    startDate,
    endDate,
    endpointNumber,
    dates: getDatesInRange(startDate, endDate, AggregationLevel.DAY),
    report,
    windowWidth,
  };
};

const IvrEndStateReportContainer = connect(mapStateToProps)(IvrEndStateReport);
IvrEndStateReportContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};
export default IvrEndStateReportContainer;
