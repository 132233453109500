import connect from 'components/lib/connect';
import DraftEditor from './draft_editor';
import { createAnswerLinksWithStatus } from 'components/customer/composition/lib/draft/draft_answer';
import ToggleComposerVisibility from 'actions/composer/toggle_composer_visibility';

function mapStateToProps({ getProvider }, props) {
  let editorProps = { ...props };

  const snippetLinks = getProvider('snippetLinks').findAll();
  const isSnippetLinksStoreLoading = getProvider('snippetLinks').isLoading();
  editorProps.snippetLinks = createAnswerLinksWithStatus(props.snippet, snippetLinks, isSnippetLinksStoreLoading);
  return editorProps;
}

function mapExecuteToProps(executeAction) {
  const defaultProps = {
    onToggleHide: () => executeAction(ToggleComposerVisibility),
  };

  return defaultProps;
}

const DraftEditorContainer = connect(mapStateToProps, mapExecuteToProps)(DraftEditor);
export default DraftEditorContainer;
