import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import ComposerContext from 'components/composer/contexts/composer_context';
import RemoveThread from 'actions/composer/email/remove_thread';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import SafeHtml from 'components/common/safe_html';

export default function Thread() {
  const threadText = useThreadText();
  const threadHtml = useThreadHtml();
  const [showThread, setShowThread] = useState(false);
  const deleteThread = useDeleteThread();
  useCloseThreadIfDeleted(threadText, showThread, setShowThread);

  return (
    <ThreadContainer>
      <Actions>
        <Ellipsis onClick={() => setShowThread(!showThread)}>
          <EllipsisDot />
          <EllipsisDot />
          <EllipsisDot />
        </Ellipsis>
        <Remove data-aid="slate-email-remove-thread" onClick={deleteThread}>
          Remove Original
        </Remove>
      </Actions>
      {showThread ? (
        <ThreadText>
          <SafeHtml html={threadHtml} setDirection />
        </ThreadText>
      ) : null}
    </ThreadContainer>
  );
}

const ThreadContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Actions = styled.div`
  display: flex;
`;

const Ellipsis = styled.div`
  align-items: center;
  background: transparent;
  border: 1px solid ${p => p.theme.colors.gray500};
  border-radius: 12px;
  color: ${p => p.theme.colors.gray500};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: space-between;
  margin-right: 8px;
  padding: 0 8px;
  width: 42px;

  &:hover {
    border-color: ${p => p.theme.colors.green400};
    color: ${p => p.theme.colors.green400};
  }
`;

const EllipsisDot = styled(FontAwesomeIcon).attrs({ icon: faCircle })`
  font-size: 6px;
`;

const Remove = styled.div`
  border: 1px solid ${p => p.theme.colors.gray500};
  border-radius: 12px;
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 0 8px;
  &:hover {
    border-color: ${p => p.theme.colors.green400};
    color: ${p => p.theme.colors.green400};
  }
`;

const ThreadText = styled.div`
  border-top: 1px solid ${p => p.theme.colors.gray400};
  color: ${p => p.theme.colors.gray500};
  margin-top: 12px;
  padding-top: 8px;
  white-space: pre-wrap;
`;

function useThreadText() {
  const { composition } = useContext(ComposerContext);
  return _.trim(composition.content.quotedPlain);
}

function useThreadHtml() {
  const { composition } = useContext(ComposerContext);
  return _.trim(composition.content.quotedHtml);
}

function useDeleteThread() {
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();
  return useCallback(() => {
    executeAction(RemoveThread, composition.id);
  }, [executeAction, composition.id]);
}

// Not technically needed, but if in the future we allow restoring the thread, then
// this component will continue to function properly.
function useCloseThreadIfDeleted(threadText, showThread, setShowThread) {
  useEffect(() => {
    if (!threadText && showThread) {
      setShowThread(false);
    }
  }, [threadText, showThread, setShowThread]);
}
