import { useEffect } from 'react';
import { isEmpty, forEach } from 'lodash';

export function useSubmitOnHotkey(editor, submitHandler, keyboardHotkeys) {
  useEffect(() => {
    if (!submitHandler || isEmpty(keyboardHotkeys) || !editor?.registerHotkeyCallback || !editor?.cancelHotkeyCallback)
      return;
    const hotkeys = [...keyboardHotkeys];

    forEach(hotkeys, key => editor.registerHotkeyCallback(key, submitHandler));
    return () => {
      forEach(hotkeys, key => editor.cancelHotkeyCallback(key, submitHandler));
    };
  }, [editor, keyboardHotkeys, submitHandler]);
}
