import { createPluginFactory } from '@udecode/plate';
import { isFunction, trim } from 'lodash';
import isHotkey from 'is-hotkey';

import { isWeakRefSupported } from 'scripts/lib/browser_detector';

const KEY_KEYBOARD_HOTKEY_PLUGIN = 'keyboard_hotkey';
const ALLOW_WEAK_REF = isWeakRefSupported();

// We force only one callback per hotkey combination to avoid potential collisions. On modern browsers,
// we use "weak references" to prevent accidental memory leaks. On Chrome < 84 (read "Pingdom"), we have
// to use "strong references"
const hotkeyCallbacks = {};

/**
 * Simple plugin that allows various editor-related components to "subscribe" to certain keyboard shortcuts
 * (aka "hot keys") and when the shortcut is pressed, the callback will be invoked. The reason for this plugin
 * is that Slate intercepts many keyboard events so using traditional keyboard hooks becomes unreliable unless
 * attached to the top-level window, which creates an opportunity for memory leaks. Plate `useHotkey` hook is
 * also full of limitations so this plugin is a lightweight solution for all sorts of keyboard-aware editor
 * toolbar buttons, components etc.
 */
export function createHotkeyPlugin() {
  return createPluginFactory({
    key: KEY_KEYBOARD_HOTKEY_PLUGIN,
    handlers: {
      onKeyDown: editor => evt => {
        const callback = findCallbackForKeyboardEvent(evt);
        callback && callback(evt, editor);
      },
    },
    withOverrides: withKeyboardHotkeyCallbacks,
  })();

  function findCallbackForHotkey(hotkey) {
    const key = trim(hotkey);
    if (key) {
      const callbackRef = hotkeyCallbacks[key];
      const callback = ALLOW_WEAK_REF ? callbackRef?.deref() : callbackRef;
      if (!callback) delete hotkeyCallbacks[key]; // Clean up dead callbacks

      return callback;
    }
  }

  function findCallbackForKeyboardEvent(keyboardEvent) {
    const requestedHotkey = Object.keys(hotkeyCallbacks).find(key => isHotkey(key, keyboardEvent));
    if (!requestedHotkey) return undefined;

    return findCallbackForHotkey(requestedHotkey);
  }

  function withKeyboardHotkeyCallbacks(editor) {
    editor.registerHotkeyCallback = (hotkey, callback) => {
      const key = trim(hotkey);
      if (!key || !isFunction(callback)) return;

      // eslint-disable-next-line no-undef
      hotkeyCallbacks[key] = ALLOW_WEAK_REF ? new WeakRef(callback) : callback;
    };

    editor.cancelHotkeyCallback = hotkey => {
      const key = trim(hotkey);
      if (key) {
        delete hotkeyCallbacks[key];
      }
    };

    return editor;
  }
}
