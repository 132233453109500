import { useEffect } from 'react';

export function useKeyboardHotkeyCallback(editor, hotKey, callback) {
  useEffect(() => {
    if (editor.registerHotkeyCallback && editor.cancelHotkeyCallback && hotKey) {
      editor.registerHotkeyCallback(hotKey, callback);

      return () => {
        editor.cancelHotkeyCallback(hotKey);
      };
    }
  }, [editor, hotKey, callback]);
}

export function useEditorEventCallback(editor, event, callback) {
  useEffect(() => {
    if (editor.events?.on && editor.events?.off) {
      editor.events.on(event, callback);

      return () => {
        editor.events.off(event, callback);
      };
    }
  }, [editor, event, callback]);
}
