import _ from 'lodash';
import { createPluginFactory, match } from '@udecode/plate';
import { Editor, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import React, { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

import { useVariables as useVariablesContext } from 'components/composer/contexts/variables_context';
import ensureFullSelection from 'components/text_editor_new/lib/ensure_full_selection';
import removeInlineFromStartOfSelection from 'components/text_editor_new/lib/remove_inline_from_start_of_selection';
import removeInlineOnBackspace from 'components/text_editor_new/lib/remove_inline_on_backspace';

export const VARIABLE = 'variable';

export default function createVariablesPlugin(variablesRef) {
  const factory = createPluginFactory({
    key: VARIABLE,
    isElement: true,
    isInline: true,

    component: props => {
      const { element } = props;
      return <StyledVariable data-slate-value={props.element.value} isEmpty={element.isEmpty} {...props} />;
    },

    handlers: {
      onChange: editor => () => {
        ensureFullSelection(editor, n => n.type === VARIABLE);
      },

      // If we have the variable selected (via ensureFullSelection above), remove the entire variable
      // inline before inserting text.. if we don't do this, we just end up editing the text within.

      // We use onDOMBeforeInput rather than onKeyDown because onKeyDown gets called for ALL input presses,
      // including hitting Cmd for example. onDOMBeforeInput let's us confirm that the user is in fact
      // inserting text and not doing something else to the document that involves a keypress (like hitting
      // Tab!).
      onDOMBeforeInput: editor => evt => {
        return removeInlineFromStartOfSelection(editor, evt, VARIABLE);
      },

      // If we hit backspace and we have a variable directly behind the cursor, delete it.
      onKeyDown: editor => evt => {
        if (ReactEditor.isComposing(editor)) {
          return;
        }

        removeInlineOnBackspace(editor, evt, VARIABLE);
      },
    },

    withOverrides: withVariables,

    deserializeHtml: {
      getNode: el => {
        return {
          attributes: {
            'data-id': el.getAttribute('data-id'),
            'data-type': el.getAttribute('data-type'),
          },
          type: VARIABLE,
        };
      },

      rules: [
        {
          validNodeName: ['VARIABLE'],
        },
      ],
    },

    serializeHtml: props => {
      const { children, element } = props;
      return (
        <variable data-id={element.attributes['data-id']} data-type={element.attributes['data-type']}>
          {children}
        </variable>
      );
    },
  });

  return factory;

  function withVariables(editor) {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
      // Remove empty variables
      if (match(node, [], { type: VARIABLE })) {
        if (!node.children.length || !node.children[0].text) {
          return Transforms.removeNodes(editor, { at: path });
        }
      }
      normalizeNode([node, path]);
    };
    editor.updateVariables = () => updateVariables(editor, variablesRef);

    return editor;
  }
}

// We take in a variablesRef so that the useCreateVariablesPlugin hook can keep that ref updated
// with the latest variables _without_ us having to recreate the plugin every time the variables
// change.
export function updateVariables(editor, variablesRef) {
  const initialSelection = editor.selection;

  Editor.withoutNormalizing(editor, () => {
    const variables = variablesRef.current;

    // Set empty variables as empty first
    for (const [node, path] of Editor.nodes(editor, {
      at: [],
      match: n => {
        return n.type === VARIABLE;
      },
    })) {
      const attributes = node.attributes;
      let variableId = attributes['data-id'];
      const variableType = attributes['data-type'];

      if (!variableId) {
        variableId = _.findKey(variables, { type: variableType });
      }

      if (!variables[variableId]) {
        return;
      }

      const oldIsEmpty = node.isEmpty;
      const newIsEmpty = variables[variableId].isEmpty;

      if (oldIsEmpty !== newIsEmpty) {
        Transforms.setNodes(
          editor,
          { isEmpty: variables[variableId].isEmpty },
          {
            at: path,
          }
        );
      }
    }

    // Update variable content now that we have the emptiness updated
    const operations = [];
    for (const [node, path] of Editor.nodes(editor, {
      at: [],
      match: n => {
        return n.type === VARIABLE;
      },
    })) {
      const attributes = node.attributes;
      const variableType = attributes['data-type'];

      let variableId = attributes['data-id'];
      if (!variableId) {
        variableId = _.findKey(variables, { type: variableType });
      }

      if (!variables[variableId]) {
        return;
      }

      const textNode = node.children[0];
      const variableText = textNode.text;

      const newVariableText = variables[variableId].text;
      if (variableText !== newVariableText) {
        const textPath = [...path, 0];
        const range = {
          anchor: {
            path: textPath,
            offset: 0,
          },
          focus: {
            path: textPath,
            offset: variableText.length,
          },
        };

        // Modifying the editor tree as we iterate over it is asking for bad news (the internals of Slate do not like
        // this). So we just do them in one big batch at the end.
        operations.push(() => Transforms.insertText(editor, newVariableText, { at: range, match: n => n === node }));
      }
    }

    // Don't save the variable update to the undo history to prevent agents from hitting Ctrl-Z and ending up with a
    // stale variable or unfilled variable value (such as "Agent First Name")
    HistoryEditor.withoutSaving(editor, () => {
      _.forEach(operations, op => op());
      // Restore the selection from before if it has changed from whatever we've done.
      if (
        initialSelection &&
        initialSelection.anchor &&
        initialSelection.focus &&
        editor.selection !== initialSelection
      ) {
        Transforms.select(editor, initialSelection);
      }
    });
  });
}

export function useCreateVariablesPlugin() {
  const variables = useVariablesContext();
  const variablesRef = useRef(variables);

  // Keep the ref up to date if the variables change, obviating the need to recreate the plugin
  // each time the variables change. Also, recreating the plugin wouldn't even work because Plate
  // doesn't seem to notice changes to plugins.. soo...
  useEffect(() => {
    variablesRef.current = variables;
  }, [variables]);

  return useMemo(() => createVariablesPlugin(variablesRef), []);
}

export function useUpdateVariables(editor) {
  const currentVariables = useVariablesContext();

  useEffect(() => {
    if (editor && editor.children && editor.children.length && currentVariables) {
      editor.updateVariables();
    }
  }, [editor, currentVariables]);
}

export const StyledVariable = styled.span.attrs({ 'data-aid': 'slate-variable' })`
  border-bottom: 1px dashed;
  ${p => p.isEmpty && emptyVariable};
`;

const emptyVariable = css`
  border-bottom: 1px dashed ${p => p.theme.colors.red400};
  color: ${p => p.theme.colors.red400};
`;
