import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledNavLink } from './nav_link_styles';

/**
 * @visibleName Menu Category
 */
const MenuCategory = ({
  children,
  className = '',
  'data-aid': dataAid = 'menuCategory',
  label,
  onClick,
  icon,
  isOpen = true,
  isSelected = false,
}) => {
  return (
    <Category className={className} data-aid={dataAid} onClick={onClick}>
      <LabelContainer isSelected={isSelected}>
        {icon}
        {label}
      </LabelContainer>
      <ChildrenContainer icon={!!icon}>{isOpen && children}</ChildrenContainer>
    </Category>
  );
};

MenuCategory.propTypes = {
  /** Child components of the MenuCategory, usually page links */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** String of CSS class names to allow custom styling */
  className: PropTypes.string,
  /** <Icon /> component to display to the left of the label */
  icon: PropTypes.node,
  /** Boolean to specify if category should show children */
  isOpen: PropTypes.bool,
  /** Boolean to specify if category should highlight */
  isSelected: PropTypes.bool,
  /** String or component to label the category */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ]),
  /** The onClick function for the MenuCategory */
  onClick: PropTypes.func,
};

export const LabelContainer = styled.li`
  display: flex;
  font-weight: ${p => p.theme.fontWeight.heavy};
  line-height: ${p => p.theme.lineHeight.controls};
  padding: 6px 0;
  color: ${p => (p.isSelected ? p.theme.colors.green400 : p.theme.colors.black)};

  svg {
    width: ${p => p.theme.iconSize.xsmall};
    margin-right: 8px;
  }
  path {
    fill: ${p => p.theme.colors.gray600};
  }
  a {
    color: ${p => p.theme.colors.black};
    text-decoration: none;
  }
`;

const Category = styled.ul`
  cursor: ${p => (p.onClick ? 'pointer' : 'default')};
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
`;

export const ChildrenContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${StyledNavLink} {
    padding-left: ${p => (p.icon ? p.theme.spacing.inlineLarge : p.theme.spacing.inlineMedium)};
  }
`;

export { MenuCategory };
