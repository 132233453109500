import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'CustomerUsernameAddress',

  properties: {
    username: prop(String).isRequired,
  },

  statics: {
    create(attrs = {}) {
      return new this(attrs);
    },
  },
});
