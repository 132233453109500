import { clearPendingRequest } from 'actions/composition/lib/update_conversation_workflow';
import ErrorReporter from 'scripts/infrastructure/error_reporter';

export default class OutgoingChatHttpGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onAddSuccess({ correlationId }) {
    clearPendingRequest(this.context, correlationId);
  }

  onAddError(err, params, correlationId) {
    clearPendingRequest(this.context, correlationId);
    ErrorReporter.reportError(`Failed to update outgoing chat: ${err}`, {
      extra: { chatId: params.itemId, correlationId },
    });
  }
}
