import ForgotPassword from 'models/location/forgot_password';
import UserLogin from 'models/location/user_login';

export default class NavigateToForgotPassword {
  constructor(context) {
    this.context = context;
  }

  run(username) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation instanceof UserLogin) {
      currentLocation.setUsername(username);
      this.context.stores.currentLocation.set(currentLocation);
    }

    this.context.router.navigateTo(ForgotPassword.create());
  }
}
