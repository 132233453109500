import React from 'react';
import Icon from 'components/lib/icons/icon_container';

export default props => (
  <Icon viewBox="0 0 29 29" {...props}>
    <path
      clipRule="evenodd"
      d="M2.813 5.438a1 1 0 0 0-1 1v2.124c.084 0 .17.022.248.066L14.5 15.737l12.44-7.109a.498.498 0 0 1 .247-.066V6.438a1 1 0 0 0-1-1H2.813zm24.375 4.2l-12.44 7.109a.5.5 0 0 1-.496 0L1.812 9.638v12.925a1 1 0 0 0 1 1h23.375a1 1 0 0 0 1-1V9.637z"
      fillRule="evenodd"
    />
  </Icon>
);
