import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer {...props}>
    <g className="trashIcon">
      <path d="M2.963 19.604h12.562V3.77H2.963zM6.103 3.77h6.28V1.397h-6.28zM1 3.5h17.273M6.5 6.542v9.5M9.5 6.542v9.5M12.5 6.542v9.5" />
    </g>
  </IconContainer>
);
