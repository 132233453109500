const theme = {
  borderRadius: {
    xsmall: '2px',
    small: '4px',
    default: '6px',
    large: '8px',
  },
  boxShadow: {
    default: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
    xsmall: '0 1px 0px rgba(0, 0, 0, 0.25)',
    small: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
    medium: '0 4px 12px 0 rgba(0, 0, 0, 0.08)',
    mediumInverted: '0 -4px 12px 0 rgba(0, 0, 0, 0.08)',
    large: '0px 0px 10px rgba(0, 0, 0, 0.06), 0px 4px 20px rgba(0, 0, 0, 0.04), 0px 0px 80px rgba(0, 0, 0, 0.06)',
  },
  fontFamily: {
    default: 'Inter, Arial, sans-serif',
  },
  fontSize: {
    small: '10px',
    reduced: '12px',
    base: '13px',
    medium: '16px',
    large: '18px',
    xlarge: '24px',
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    mediumHeavy: 600,
    heavy: 700,
  },
  iconSize: {
    xsmall: '16px',
    small: '24px',
    medium: '32px',
    large: '48px',
  },
  lineHeight: {
    base: '1.62',
    controls: '16px',
  },
  overlay: {
    backdropFilter: 'blur(4px)',
    color: 'rgba(246, 246, 246, 0.5)',
  },
  spacing: {
    insetSmall: '8px',
    insetMedium: '16px',
    insetLarge: '24px',
    insetXLarge: '32px',
    insetXXLarge: '48px',

    stackXSmall: '4px',
    stackSmall: '8px',
    stackMedium: '16px',
    stackLarge: '24px',
    stackXLarge: '32px',

    inlineSmall: '8px',
    inlineMedium: '16px',
    inlineLarge: '24px',
    inlineXLarge: '32px',

    xsmall: '2px',
    small: '4px',
    medium: '8px',
    large: '16px',
    xlarge: '32px',
  },
  colors: {
    // http://go.glad.ly/style/#/Colors
    black: '#000000',
    blue700: '#0F203C', // deprecated
    brandDarkGreen: '#0E790E', // ported from _variables.sass
    defaultFontBlack: '#111111', // ported from _variables.sass
    gray100: `#f6f6f6`,
    gray200: `#f1f1f1`,
    gray300: `#e3e3e3`,
    gray400: `#cecece`,
    gray500: `#b3b3b3`,
    gray600: `#919191`,
    gray700: `#767676`,
    gray800: `#484848`, // tooltip color
    gray900: `#242424`,
    grayCoorsLight: '#CCCBD1', // j/k - do not use (or name things like this) RIP jotto (but he isnt really dead, just to us)
    green100: '#d8f4d8',
    green200: '#9bd79b',
    green300: '#64b964',
    green400: '#009b00',
    green500: '#0c7c0c',
    green600: '#125715',
    green700: '#003c00',
    eggShelleyBlue: '#008080', // You will be missed Shelley!
    purple100: '#e6dcff',
    purple200: '#d0b8fc',
    purple300: '#aa91ff',
    purple400: '#8c69f0',
    purple500: '#6e46b9',
    purple600: '#4f2a93',
    purple700: '#331962',
    red100: '#ffcdd2',
    red200: '#ff8796',
    red300: '#ed435d',
    red400: '#d50e0e',
    red500: '#c8122e',
    red600: '#a50f2d',
    red700: '#5C0000',
    suyderhoudOrange: '#FF7033', // Farewell Gerad! Good Luck!
    white: '#ffffff',
    yellow100: '#fff3c6',
    yellow200: '#ffe69b',
    yellow300: '#ffd24b',
    yellow400: '#ffbe00',
    yellow500: '#f0a500',
    yellow600: '#b98106',
    yellow700: '#8F660C',
  },
  transitions: {
    fast: '75ms ease-in-out',
  },
  zIndex: {
    top: 999999,
  },
};

theme.lineGraphColors = {
  lineGraphLine0: theme.colors.purple400,
  lineGraphLine1: theme.colors.red300,
  lineGraphLine2: theme.colors.yellow300,
  lineGraphLine3: theme.colors.red700,
  lineGraphLine4: theme.colors.green300,
};

theme.windowWidth = {
  small: 1366,
};

export default theme;
