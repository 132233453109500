import _ from 'lodash';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import DownloadableAttachment from 'models/downloadable_attachment';
import OpenAttachmentPreview from 'actions/modal/open_attachment_preview';

function mapStateToProps(context, props) {
  return {
    orgId: context
      .getProvider('auth')
      .get()
      .getOrgId(),
    ...props,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onOpenAttachmentPreview: (attachments, getDownloadUrl, index) => {
      let downloadableAttachments = _.map(attachments, a =>
        DownloadableAttachment.create({ id: a.id, fileDescriptor: a.fileDescriptor(), downloadUrl: getDownloadUrl(a) })
      );
      executeAction(OpenAttachmentPreview, {
        attachments: downloadableAttachments,
        currentIndex: index,
      });
    },
  };
}

const AttachmentPreviewOpener = connect(mapStateToProps, mapExecuteToProps);

export default AttachmentPreviewOpener;

AttachmentPreviewOpener.childPropTypes = {
  onOpenAttachmentPreview: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
};
