const EMBEDDED_DATASET_NAMES = Object.freeze([
  'agent_events',
  'agent_metrics',
  'contact_metrics',
  'conversation_events',
  'conversation_metrics',
  'task_events',
  'task_metrics',
]);

const DEMO_EMBEDDED_DATASET_NAMES = Object.freeze([
  'agent_events',
  'agent_metrics',
  'contact_metrics_demo',
  'conversation_events',
  'conversation_metrics_demo',
  'task_events',
  'task_metrics',
]);

let getDatasetNames = function getDatasetNames(isDemo) {
  if (isDemo) {
    return DEMO_EMBEDDED_DATASET_NAMES;
  }

  return EMBEDDED_DATASET_NAMES;
};

export { getDatasetNames };
