import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import some from 'lodash/some';

import { isComment, isConditionalEnd, isConditionalStart } from './word_comment_rules';

export default function createWordListRules(context) {
  return [
    {
      deserialize: (el, next) => {
        if (isValidListElement(el)) {
          const listId = get(el, 'attributes.start.value');
          context.listId = listId;
          context.listDepth = 1;
          return {
            object: 'block',
            type: el.tagName.toLowerCase() === 'ol' ? 'ordered_list' : 'unordered_list',
            nodes: next(el.childNodes),

            data: {
              listId,
              listDepth: 1,
            },
          };
        } else if (isValidListItemElement(el) && isListItem(el)) {
          const styles = convertStyleStringToMap(el.attributes.style.value || '');
          const listAttrs = styles['mso-list'];

          let listId = null;
          let listDepth = 1;
          if (listAttrs) {
            listId = listAttrs.match(/l(\d+?)\s+/)[1];
            listDepth = Number.parseInt(listAttrs.match(/level(.+?)\s+/)[1]);
          } else {
            // TODO: figure out level from indentation styles
          }

          const commentText = getCommentTextForElement(el);

          return {
            object: 'block',
            type: 'list_item',
            nodes: next(el.childNodes),

            data: {
              listId,
              listDepth,
              commentText,
            },
          };
        }

        return next();
      },
    },
  ];
}

const LIST_ELEMENTS = ['ol', 'ul'];
const LIST_ITEM_ELEMENTS = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'li'];

function isValidListElement(el) {
  return el.tagName && includes(LIST_ELEMENTS, el.tagName.toLowerCase());
}

function convertStyleStringToMap(style) {
  return style.split(';').reduce(function(ruleMap, ruleString) {
    const rulePair = ruleString.split(':');
    ruleMap[rulePair[0].trim()] = rulePair[1].trim();
    return ruleMap;
  }, {});
}

function isValidListItemElement(el) {
  return el.tagName && includes(LIST_ITEM_ELEMENTS, el.tagName.toLowerCase());
}

function isListItem(el) {
  return (
    (get(el, 'attributes.style.value', '').match(/mso-list:\s?l\d/) &&
      el.parentElement.tagName.toLowerCase() !== 'li') ||
    hasListCommentChild(el)
  );
}

function hasListCommentChild(el) {
  return some(el.childNodes, childNode => childNode.nodeType === 8);
}

/**
 * We figure out the list type based on what the HTML comment text contains... Word uses
 * symbols for unordered lists, and then a collection of alphabetic and numeric characters
 * for ordered lists.
 *
 * E.g. the comment will look something like this:
 *   <![if !supportLists]>
 *     <span lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;
 *   mso-bidi-font-family:Symbol'>
 *       <span style='mso-list:Ignore'>· // This is what we're searching for.
 *         <span style='font:7.0pt "Times New Roman"'>
 *           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 *         </span>
 *       </span>
 *     </span>
 *   <![endif]>
 */
function getCommentTextForElement(el) {
  let commentText;
  let hasListConditionalStarted = false;
  forEach(el.childNodes, node => {
    if (isComment(node) && isConditionalStart(node)) {
      hasListConditionalStarted = true;
    } else if (isComment(node) && isConditionalEnd(node)) {
      hasListConditionalStarted = false;
    } else if (hasListConditionalStarted && node.textContent) {
      commentText = node.textContent;
    }
  });
  return commentText;
}

/**
 * Microsoft Word produces some of the most scuffed HTML ever. The deserialize rules above produce the necessary
 * slate objects, but they don't necessarily have the correct structure.
 *
 * There are two main cases we have to contend with: actual proper <ol><li></ol> structures, and then these <p>
 * tags which may or may not contain the necessary list id, depth, and type information we need to figure out
 * what the hell sort of list they're supposed to be representing.
 *
 * We do an initial pass using the serialer above, but we'll often times end up afterwards with something like this:
 *   ol
 *     li
 *   li
 *   li
 *
 * Those latter two <li>s need to be placed inside the <ol> at their proper depths (which may mean creating
 * additional lists within).
 *
 * Alternatively, those two <li>s may belong to a new list entirely, in which case we'll need to create a brand new
 * list to contain them. Thanks Microsoft.
 */
export function fixLists(value) {
  const { document } = value;

  let activeList = null;
  let consolidatedNodes = [];
  forEach(document.nodes, node => {
    if (node.type === 'ordered_list' || node.type === 'unordered_list') {
      activeList = node;
      node.nodes = filter(node.nodes, child => child.object !== 'text'); // Filter out text nodes that don't belong
      consolidatedNodes.push(node);
    } else if (node.type === 'list_item') {
      const isSameList = get(activeList, 'data.listId') === get(node, 'data.listId');
      if (!activeList || !isSameList) {
        const newListType =
          activeList && isSameList ? activeList.type : getListTypeFromCommentText(node.data.commentText);
        const newList = {
          object: 'block',
          type: newListType,
          nodes: [node],
          data: {
            listId: node.data.listId,
            listDepth: 1,
          },
        };
        activeList = newList;
        consolidatedNodes.push(newList);
      } else if (activeList) {
        if (node.data.listDepth === activeList.data.listDepth) {
          activeList.nodes.push(node);
        } else if (node.data.listDepth > activeList.data.listDepth) {
          // TODO: Nest deeper lists in a loop until the list depth matches up
          const deeperList = {
            object: 'block',
            type: activeList.type,
            nodes: [node],
            data: {
              listId: node.data.listId,
              listDepth: node.data.listDepth,
            },
            parent: activeList,
          };
          activeList.nodes.push(deeperList);
          activeList = deeperList;
        } else {
          while (activeList && node.data.listDepth < activeList.data.listDepth) {
            activeList = activeList.parent;
          }
          activeList && activeList.nodes.push(node);
        }
      }
    } else {
      consolidatedNodes.push(node);
    }
  });
  document.nodes = consolidatedNodes;
  return value;
}

const unorderedRegex = /(o\s+|·|§)/g;
const orderedRegex = /([ivx]|[IVX]|[a-z]|[A-Z]|\d)/g;

function getListTypeFromCommentText(commentText) {
  // Unordered check first: word uses "o" in Courier font to create the second level of unordered list items.
  // But the ordered regex will also match an "o" (assuming the list is long enough).
  if (commentText && unorderedRegex.test(commentText)) {
    return 'unordered_list';
  } else if (commentText && orderedRegex.test(commentText)) {
    return 'ordered_list';
  }
  return 'unordered_list';
}
