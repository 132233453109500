import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ChangeAssignment from 'actions/conversation/change_assignment';
import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import ConversationAssignee from './conversation_assignee';
import CurrentAssignee from './current_assignee';
import CustomerAssignee from './customer_assignee';
import CustomerAssignmentButtonOptions from 'components/lib/assignment_menu/customer_assignment_button_options';
import getAssigneeDetails from 'components/lib/assignment_menu/lib/get_assignee_details';
import { getConversationAssignee } from 'actions/conversation/lib/conversation_helpers';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import registerHotkey from 'components/hotkeys/register_hotkey';
import SearchableAssignmentMenu from 'components/lib/assignment_menu/searchable_assignment_menu';
import SearchResult from './search_result';
import ShowReopenConversationModal from 'actions/conversation/show_reopen_conversation_modal';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

export class SearchableConversationAssignment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };

    _.bindAll(this, [
      'closeAssignmentMenu',
      'getAssigneeInfo',
      'handleSelect',
      'handleToggleMenu',
      'renderAssignmentRow',
    ]);
  }

  render() {
    return (
      <div className="searchableConversationAssignment">
        {this.renderMenu()}
        {this.renderAssignee()}
      </div>
    );
  }

  renderMenu() {
    if (!this.state.isMenuOpen) {
      return null;
    }

    return (
      <div className="searchableConversationAssignment-menu-block">
        <OutsideClickHandler onClickOutside={this.handleToggleMenu}>
          <CustomerAssignee
            className="searchableConversationAssignment-customerAssignee"
            onClose={this.closeAssignmentMenu}
          />
          <SearchableAssignmentMenu
            className="searchableConversationAssignment-menu"
            conversationId={this.props.conversationId}
            currentAssignee={this.props.conversationAssignee}
            onBlur={_.noop}
            renderRow={this.renderAssignmentRow}
            showSelfAssignmentResultsByDefault
            title="Assign To"
          />
        </OutsideClickHandler>
      </div>
    );
  }

  renderAssignmentRow(hit) {
    let agentButtons = null;
    let inboxButtons = null;
    const { canManageCustomerAssignee } = this.props;

    if (canManageCustomerAssignee) {
      agentButtons = <CustomerAssignmentButtonOptions onClose={this.closeAssignmentMenu} {...hit} />;
      inboxButtons = <CustomerAssignmentButtonOptions onClose={this.closeAssignmentMenu} {...hit} />;
    }

    return (
      <SearchResult
        agentButtons={agentButtons}
        agentClassName="conversation-selectableSearchResults-agents"
        inboxButtons={inboxButtons}
        inboxClassName="conversation-selectableSearchResults-inboxes"
        key={`${hit.routingGroupId}${_.get(hit, 'agentId', '')}`}
        onAgentSelect={canManageCustomerAssignee ? _.noop : this.handleSelect}
        onInboxSelect={canManageCustomerAssignee ? _.noop : this.handleSelect}
        {...hit}
      />
    );
  }

  renderAssignee() {
    const { conversationAssignee, conversationId, customerAssignee } = this.props;
    const className = 'searchableConversationAssignment-assignee';

    if (!conversationId) {
      return (
        <CurrentAssignee
          className={classnames({ 'currentAssignee-customerAssignee': !_.isEmpty(customerAssignee) }, className)}
          onClick={this.handleToggleMenu}
          {...this.getAssigneeInfo()}
        />
      );
    }

    return (
      <ConversationAssignee
        className={className}
        conversationAssignee={conversationAssignee}
        customerAssignee={customerAssignee}
        onClick={this.handleToggleMenu}
        textLabel={'Assigned to'}
      />
    );
  }

  getAssigneeInfo() {
    const { conversationAssignee, conversationId, customerAssignee } = this.props;
    let assignee = conversationAssignee || {};
    assignee.textLabel = 'Assigned to';

    if (!conversationId && !_.isEmpty(customerAssignee)) {
      assignee = customerAssignee;
      assignee.textLabel = customerAssignee.agentName ? 'Dedicated Hero' : 'Hero Inbox';
    }

    return assignee;
  }

  closeAssignmentMenu() {
    this.setState({ isMenuOpen: false });
  }

  handleSelect(selected) {
    this.props.onSelect(selected);
    this.closeAssignmentMenu();
  }

  handleToggleMenu(evt) {
    evt && evt.preventDefault();

    if (!this.props.disableMenu) {
      this.setState(prevState => {
        return { isMenuOpen: !prevState.isMenuOpen };
      });
    }

    this.props.onToggleMenu && this.props.onToggleMenu();
  }
}

SearchableConversationAssignment.propTypes = {
  canManageCustomerAssignee: PropTypes.bool,
  conversationId: PropTypes.string,
  conversationAssignee: PropTypes.shape({
    agentId: PropTypes.string,
    agentName: PropTypes.string,
    avatarImage: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    isAssigneeCurrentAgent: PropTypes.bool,
    textLabel: PropTypes.string,
  }),
  customerAssignee: PropTypes.shape({
    agentId: PropTypes.string,
    agentName: PropTypes.string,
    avatarImage: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    isAssigneeCurrentAgent: PropTypes.bool,
  }),
  disableMenu: PropTypes.bool,
  isConversationClosed: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleMenu: PropTypes.func,
};

export const ConversationAssignmentWithShortcuts = withShortcuts(SearchableConversationAssignment, [
  registerHotkey({
    key: 'alt+a',
    callback: component => component.handleToggleMenu(),
    group: 'Conversation',
    label: 'Change assignee',
  }),
]);

function mapStateToMenuProps({ getProvider, isFeatureEnabled }, { conversationId }) {
  const conversation = getProvider('conversations').findBy({ id: conversationId });
  const isConversationClosed = _.get(conversation, 'status') === Conversation.Status.CLOSED;

  const conversationAssignee = getConversationAssignee(conversation);
  const conversationAssigneeDetails = getAssigneeDetails(
    { getProvider, isFeatureEnabled },
    {
      agentId: conversationAssignee.agentId,
      groupId: conversationAssignee.groupId,
    }
  );

  return {
    canManageCustomerAssignee: isFeatureEnabled('customerAssigneeManagement') && isFeatureEnabled('dedicatedHero'),
    conversationAssignee: conversationAssigneeDetails,
    customerAssignee: getCustomerAssignee(),
    disableMenu: isConversationClosed,
  };

  function getCustomerAssignee() {
    let assignee = getProvider('assignee').get() || {};
    return getAssigneeDetails(
      { getProvider, isFeatureEnabled },
      { agentId: assignee.agentId, groupId: assignee.routingGroupId }
    );
  }
}

function mapExecuteToMenuProps(executeAction) {
  return {
    onSelect: ({ agentId, routingGroupId }) => {
      executeAction(ChangeAssignment, {
        assignee: { agentId, routingGroupId },
      });
    },
    onToggleMenu: () => executeAction(ShowReopenConversationModal),
  };
}

function mergeProps(stateProps, execProps, ownProps) {
  const props = {
    ...stateProps,
    ...execProps,
    ...ownProps,
  };

  if (!stateProps.disableMenu) {
    delete props.onToggleMenu;
  }

  return props;
}

const SearchableConversationAssignmentContainer = connect(
  mapStateToMenuProps,
  mapExecuteToMenuProps,
  mergeProps
)(ConversationAssignmentWithShortcuts);

SearchableConversationAssignmentContainer.propTypes = {
  conversationId: PropTypes.string,
};

export default SearchableConversationAssignmentContainer;
