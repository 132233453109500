import _ from 'lodash';
import React, { useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import StartUpload from 'actions/composition/start_upload';

const DropZone = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px dashed ${p => p.theme.colors.green400};
  bottom: 0;
  color: ${p => p.theme.colors.green400};
  display: flex;
  font-size: ${p => p.theme.fontSize.large};
  font-weight: ${p => p.theme.fontWeight.heavy};
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export default function AttachmentsDropZone({ isDragActive }) {
  if (!isDragActive) {
    return null;
  }

  return <DropZone>Drop files here</DropZone>;
}

/**
 * Returns a set of props to be passed to the composer to enable the dropzone and the state of whether a drag is
 * currently active or not.
 *
 * @param {boolean} includeAttachments
 */
export function useDroppable(includeAttachments) {
  const [isDragActive, setIsDragActive] = useState(false);

  const enterCounterRef = useRef(0);

  const onDragEnter = useCallback(
    evt => {
      if (evt.dataTransfer.types[0] === 'Files') {
        evt.preventDefault();

        enterCounterRef.current++;

        setIsDragActive(true);
      }
    },
    [enterCounterRef]
  );

  const onDragLeave = useCallback(
    evt => {
      if (evt.dataTransfer.types[0] === 'Files') {
        evt.preventDefault();

        // Only deactivate once the dropzone and all children was left.
        if (--enterCounterRef.current > 0) {
          return;
        }

        setIsDragActive(false);
      }
    },
    [enterCounterRef]
  );

  const onDragOver = useCallback(evt => {
    if (evt.dataTransfer.types[0] === 'Files') {
      evt.preventDefault();
      evt.stopPropagation();
      // This changes the mouse cursor to the "+" sign.
      evt.dataTransfer.dropEffect = 'copy'; // eslint-disable-line no-param-reassign
      return false;
    }
  }, []);

  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();
  const uploadFile = useCallback(
    file =>
      executeAction(StartUpload, {
        compositionId: composition.id,
        file,
      }),
    [composition.id]
  );

  const onDrop = useCallback(
    evt => {
      evt.preventDefault();
      evt.persist();

      enterCounterRef.current = 0;
      setIsDragActive(false);

      const files = getDataTransferItems(evt);
      if (files.length > 0) {
        _.forEach(files, file => uploadFile(file));
      }
    },
    [enterCounterRef]
  );

  const attachmentProps = includeAttachments
    ? {
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop,
      }
    : {};

  return [attachmentProps, isDragActive];
}

function getDataTransferItems(evt) {
  let dataTransferItemsList = [];
  if (evt.dataTransfer) {
    const dt = evt.dataTransfer;

    // NOTE: Only the 'drop' event has access to DataTransfer.files, otherwise it will always be empty
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files;
    } else if (dt.items && dt.items.length) {
      // During the drag even the dataTransfer.files is null but Chrome implements some drag store,
      // which is accesible via dataTransfer.items
      dataTransferItemsList = _.map(dt.items, i => i.getAsFile());
    }
  } else if (evt.target && evt.target.files) {
    dataTransferItemsList = evt.target.files;
  }

  // Convert from DataTransferItemsList to the native Array
  return _.compact(Array.prototype.slice.call(dataTransferItemsList));
}
