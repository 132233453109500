import { get, upperFirst } from 'lodash';
import analytics from 'scripts/lib/analytics';

import CloseModal from 'actions/modal/close_modal';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class CustomerComplianceGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchSuccess(dto, { customerId }) {
    analytics.track('customer export - success', { customerId });
    this.downloadProfile(dto);
  }

  onFetchError(error, { customerId }) {
    analytics.track('customer export - error', { customerId });
    this.context.executeAction(ShowToast, {
      type: ToastType.ERROR,
      message: 'This profile cannot be exported. Try again later.',
    });
  }

  onDeleteSuccess(dto, { customerId }) {
    analytics.track('customer deletion - success', { customerId });
    this.context.executeAction(CloseModal);
    this.context.executeAction(ShowToast, {
      type: ToastType.SUCCESS,
      message: 'Customer deleted.',
    });
  }

  onDeleteError(dto, { customerId }) {
    analytics.track('customer deletion - error', { customerId });
    this.context.executeAction(CloseModal);
    let toastMessage = 'This profile cannot be deleted. ';
    const errorDetail = get(dto, 'errors[0].detail');
    if (errorDetail) {
      toastMessage = toastMessage.concat(`${upperFirst(errorDetail)}. `);
    }

    toastMessage = toastMessage.concat('Try again later.');

    this.context.executeAction(ShowToast, {
      type: ToastType.ERROR,
      message: toastMessage,
    });
  }

  downloadProfile(response) {
    const url = URL.createObjectURL(new Blob([JSON.stringify(response, null, 2)], { type: 'application/json' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `profile-${response.id}.json`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
