import _ from 'lodash';
import createContext from 'create-react-context';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  BOUNDS_PROPTYPE,
  PortalledPopover,
  POSITION_PROPTYPE,
  PopoverArrow,
  TARGET_PROPTYPE,
} from 'components/common/lib/_popover';
import createEnum from 'scripts/lib/create_enum';

export const DEFAULT_CONTEXT = { onBlur: undefined, onFocus: undefined };
export const TooltipContext = createContext(DEFAULT_CONTEXT);

export const TooltipTypes = createEnum('PRIMARY', 'IMAGE');

/*
 * Tooltip is a component which will display a tooltip when you hover over the `children` prop. 'margin' and 'bounds' can be used to change placement of tooltip.
 *
 * You can use the `FocusManager` component defined below to display the tooltip in response to focus events as well.
 */
export default class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'handleChildUnfocused',
      'handleChildFocused',
      'handleMouseEnter',
      'handleMouseLeave',
      'setReference',
    ]);

    this.state = {
      context: {
        onBlur: this.handleChildUnfocused,
        onFocus: this.handleChildFocused,
      },
      isChildFocused: false,
      isVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((this.props.message && !nextProps.message) || (this.props.isVisible && !nextProps.isVisible)) {
      // Can't forget to clear this timeout - otherwise with the right timing, the tooltip can be set to
      // isVisible false, but the openTimeout will cause it to open briefly before disappearing again.
      this.props.clearTimeout(this.openTimeout);
      this.setState({ isVisible: false });
    }
  }

  componentWillUnmount() {
    this.props.clearTimeout(this.openTimeout);
    this.props.clearTimeout(this.closeTimeout);
  }

  render() {
    return (
      <HoverWrapper className={this.props.className}>
        <Hoverer
          data-aid="tooltip-hoverer"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          ref={this.setReference}
        >
          <TooltipContext.Provider value={this.state.context}>{this.props.children}</TooltipContext.Provider>
        </Hoverer>
        {this.renderTooltip()}
      </HoverWrapper>
    );
  }

  renderTooltip() {
    let { 'data-aid': aid, hoverableTooltip, margin, message, position, type } = this.props;

    const isVisible = this.state.isVisible || this.state.isChildFocused;

    if (hoverableTooltip) {
      message = React.cloneElement(message, {
        onMouseLeave: this.handleChildUnfocused,
        onMouseEnter: this.handleChildFocused,
      });
    }

    let RenderTooltipPopover;
    switch (type) {
      case TooltipTypes.PRIMARY:
        RenderTooltipPopover = TooltipPopover;
        break;
      case TooltipTypes.IMAGE:
        RenderTooltipPopover = ImageTooltipPopover;
        break;
      default:
        RenderTooltipPopover = TooltipPopover;
        break;
    }

    return (
      <RenderTooltipPopover
        bounds={this.props.bounds}
        data-aid={aid}
        fitOpenerWidth={this.props.fitOpenerWidth}
        isVisible={!!(message && (this.props.forceVisible || isVisible))}
        margin={margin}
        position={position}
        style={this.props.style}
        target={this.props.target}
        targetElement={this.state.targetElement}
        targetPosition={this.props.targetPosition}
      >
        {message}
      </RenderTooltipPopover>
    );
  }

  handleChildUnfocused() {
    this.setState({ isChildFocused: false });
  }

  handleChildFocused() {
    this.setState({ isChildFocused: true });
  }

  handleMouseEnter(evt) {
    this.props.onHoverStart && this.props.onHoverStart(evt);
    this.props.clearTimeout(this.closeTimeout);
    this.openTimeout = this.props.setTimeout(() => {
      this.setState({ isVisible: true });
    }, this.props.delay);
  }

  handleMouseLeave(evt) {
    this.props.onHoverEnd && this.props.onHoverEnd(evt);
    this.props.clearTimeout(this.openTimeout);
    this.closeTimeout = this.props.setTimeout(() => {
      this.setState({ isVisible: false });
    }, this.props.leaveDelay);
  }

  setReference(node) {
    this.setState({ targetElement: node });
  }
}

Tooltip.propTypes = {
  bounds: BOUNDS_PROPTYPE,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
  fitOpenerWidth: PropTypes.bool,
  forceVisible: PropTypes.bool,
  hoverableTooltip: PropTypes.bool,
  leaveDelay: PropTypes.number,
  margin: PropTypes.number,
  message: PropTypes.node,
  onHoverEnd: PropTypes.func,
  onHoverStart: PropTypes.func,
  position: POSITION_PROPTYPE,
  style: PropTypes.object,
  target: TARGET_PROPTYPE,
  targetPosition: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(TooltipTypes)),

  setTimeout: PropTypes.func,
  clearTimeout: PropTypes.func,
};

Tooltip.defaultProps = {
  delay: 50,
  leaveDelay: 20,
  margin: 10,
  position: 'top',
  type: TooltipTypes.PRIMARY,

  setTimeout: window.setTimeout.bind(window),
  clearTimeout: window.clearTimeout.bind(window),
};

const HoverWrapper = styled.span`
  pointer-events: auto;
`;
const Hoverer = styled.span``;

const TooltipPopover = styled(PortalledPopover)`
  background-color: ${p => p.theme.colors.gray800};
  border-radius: ${p => p.theme.borderRadius.default};
  color: #ecf0f1;
  left: 0px;
  padding: 10px;
  position: fixed;
  top: 0px;
  white-space: pre-line;
  will-change: transform;
  z-index: 21;

  &[data-position='bottom'] {
    ${PopoverArrow} {
      border-bottom-color: ${p => p.theme.colors.gray800};
    }
  }
  &[data-position='right'] {
    ${PopoverArrow} {
      border-right-color: ${p => p.theme.colors.gray800};
    }
  }
  &[data-position='left'] {
    ${PopoverArrow} {
      border-left-color: ${p => p.theme.colors.gray800};
    }
  }
  &[data-position='top'] {
    ${PopoverArrow} {
      border-top-color: ${p => p.theme.colors.gray800};
    }
  }
`;

const ImageTooltipPopover = styled(PortalledPopover)`
  background-color: ${p => p.theme.colors.white};
  border-radius: ${p => p.theme.borderRadius.default};
  box-shadow: ${p => p.theme.boxShadow.medium};
  color: ${p => p.theme.colors.gray600};
  left: 0px;
  margin-left: 8px;
  padding: 8px;
  position: fixed;
  top: 0px;
  white-space: pre-line;
  will-change: transform;
  z-index: 21;
`;

export { HoverWrapper, Hoverer, TooltipPopover };

/*
 * `FocusManager` should be used as a child of `Tooltip` if one wishes to display the tooltip in response to some focus
 * event on a child.. e.g. if you want to show the tooltip always when focused on an <input>, even if the <input>
 * is not hovered.
 *
 * This component uses React's new Context API (https://reactjs.org/docs/context.html) in order to have access to
 * `Tooltip`'s `handleBlur` and `handleFocus` callbacks. This way the `FocusManager` can be anywhere in the child tree of
 * `Tooltip` - it doesn't have to be a direct child nor does it need to rely on being passed the correct props by
 * `Tooltip`.
 *
 * The `children` prop in this case is _not_ a React element, but instead a render prop function:
 * (https://reactjs.org/docs/render-props.html). By having `children` be a function, we can decorate the `<input>`'s
 * `onFocus` and `onBlur` callbacks with a call to `Tooltip`'s `onFocus` and `onBlur` in order to properly maintain the
 * focused state for tooltip display.
 *
 * Example usage:
 *
 * ```
    <Tooltip message={errorMessage}>
      <div className="inputLabel">Label</div>
      <FocusManager onBlur={this.handleBlur} onFocus={this.handleFocus}>
        {({ onBlur, onFocus }) => (
          <Input
            autoFocus
            onBlur={onBlur}
            onChange={this.handleChange}
            onFocus={onFocus}
            value={this.state.value}
          />
        )}
      </FocusManager>
    </Tooltip>
 * ```
 *
 * You can pass your own `onBlur` and `onFocus` callbacks to `FocusManager` as props, which will decorate them with the
 * calls to the `Tooltip`'s `onBlur` and `onFocus`, and then passes _those_ wrapped functions as arguments to the
 * `children` render prop function.
 */
export function FocusManager({ children, onBlur, onFocus }) {
  const childBlur = onBlur;
  const childFocus = onFocus;

  return (
    <TooltipContext.Consumer>
      {({ onBlur: onBlurHandler, onFocus: onFocusHandler }) => {
        const childParams = {
          onBlur: evt => {
            onBlurHandler && onBlurHandler(evt);
            childBlur && childBlur(evt);
          },
          onFocus: evt => {
            onFocusHandler && onFocusHandler(evt);
            childFocus && childFocus(evt);
          },
        };
        return children(childParams);
      }}
    </TooltipContext.Consumer>
  );
}

FocusManager.propTypes = {
  children: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};
