import React from 'react';
import IconContainer from './icon_container';

export default function AnswerPanelToggleIcon(props) {
  return (
    <IconContainer height="11" viewBox="0 0 20 20" width="11" {...props}>
      <g
        className="iconPath"
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <path d="M11.5,4 C11.5,2.066 16.648,0.5 22.5,0.5 L22.5,17 C16.648,17 11.5,18.566 11.5,20.5 C11.5,18.566 6.351,17 0.5,17 L0.5,0.5 C6.351,0.5 11.5,2.066 11.5,4 L11.5,4 Z" />
        <path d="M11.5,4 L11.5,20.375" />
        <path d="M9,6.2402 C7.39,5.6232 5.113,5.1772 2.5,5.0002" />
        <path d="M9,9.2402 C7.39,8.6232 5.113,8.1772 2.5,8.0002" />
        <path d="M9,12.2402 C7.39,11.6232 5.113,11.1772 2.5,11.0002" />
        <path d="M9,15.2402 C7.39,14.6232 5.113,14.1772 2.5,14.0002" />
        <path d="M14,6.2402 C15.61,5.6232 17.887,5.1772 20.5,5.0002" />
        <path d="M14,9.2402 C15.61,8.6232 17.887,8.1772 20.5,8.0002" />
        <path d="M14,12.2402 C15.61,11.6232 17.887,11.1772 20.5,11.0002" />
        <path d="M14,15.2402 C15.61,14.6232 17.887,14.1772 20.5,14.0002" />
      </g>
    </IconContainer>
  );
}
