export default class ClearSuggestedReply {
  constructor(context) {
    this.context = context;
  }

  run({ customerId }) {
    if (!this.context.stores.appFeatures.get().isEnabled('suggestedReplies')) {
      return;
    }

    if (!customerId) {
      const currentLocation = this.context.stores.currentLocation.get();
      customerId = currentLocation?.customerId;
    }

    if (!customerId || !this.context.stores.customers.has({ id: customerId })) {
      return;
    }

    const { suggestedReply } = this.context.stores.customers.storesFor(customerId);
    suggestedReply.remove();
    suggestedReply.resetLoading();
  }
}
