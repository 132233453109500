import Attachment from 'models/attachment';
import { createContentModel, isEmpty } from './composition_content_shared';
import { prop } from 'models/lib/create_model';

const EmailCompositionContent = createContentModel({
  modelName: 'EmailCompositionContent',

  properties: {
    bcc: String,
    bodyHtml: String,
    cc: String,
    from: String,
    headers: Object,
    inlineAttachments: prop([Attachment]).default([]),
    isForward: Boolean,
    quotedHtml: String,
    quotedPlain: String,
    subjectHtml: String,
    to: String,
  },

  setRecipients({ tos, ccs, bccs }) {
    this.to = tos.join(', ');
    this.cc = ccs.join(', ');
    this.bcc = bccs.join(', ');
  },

  setFrom(from) {
    this.from = from;
  },

  addInlineAttachments(inlineAttachments) {
    this.inlineAttachments = this.inlineAttachments.concat(inlineAttachments);
  },

  removeThread() {
    this.quotedPlain = undefined;
    this.quotedHtml = undefined;
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },

  getHeader(key) {
    return this.headers && this.headers[key];
  },
});

EmailCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB = 15;

export default EmailCompositionContent;
