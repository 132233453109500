export default class ClearApiTokens {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.apiTokens.findAll().forEach(apiToken => {
      if (apiToken.token) {
        apiToken.clearToken();
        this.context.stores.apiTokens.replace(apiToken);
      }

      this.context.stores.apiTokens.clearErrors(apiToken.id);
    });
  }
}
