import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useExecuteAction } from 'components/hooks/connect_hooks';
import Button from 'components/common/button';
import EmojiIcon from 'components/lib/icons/emoji_picker_icon';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { usePopoverMenu } from 'components/common/menu';
import Theme from 'scripts/presentation/themes/default.js';
import TrackEmojiUsage from 'actions/composer/track_emoji_usage';

// icons
import AppleIcon from 'components/lib/icons/apple_icon';
import CarIcon from 'components/lib/icons/car_icon';
import ClockIcon from 'components/lib/icons/clock_icon';
import DogIcon from 'components/lib/icons/dog_icon';
import FlagIcon from 'components/lib/icons/flag_icon';
import LightBulbIcon from 'components/lib/icons/lightbulb_icon';
import SoccerBallIcon from 'components/lib/icons/soccer_ball_icon';
import SymbolsIcon from 'components/lib/icons/symbols_icon';

const Emoji = ({ editor, menuMargin, menuPosition, positionRef }) => {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();

  // If we pass in a positionRef, use that instead
  useEffect(() => {
    if (positionRef) {
      setTargetRef(positionRef.current);
    }
  }, [positionRef]);

  const insertEmoji = useInsertEmoji(editor, onClose);
  const icons = {
    categories: {
      recent: () => <ClockIcon />,
      people: () => <EmojiIcon />,
      nature: () => <DogIcon />,
      foods: () => <AppleIcon />,
      activity: () => <SoccerBallIcon />,
      places: () => <CarIcon />,
      objects: () => <LightBulbIcon />,
      symbols: () => <SymbolsIcon />,
      flags: () => <FlagIcon />,
    },
  };
  return (
    <React.Fragment>
      <OutsideClickHandler onClickOutside={onClose}>
        <EmojiButton
          data-aid={`slateEmoji-button`}
          onClick={onToggle}
          onMouseDown={preventBlurring}
          ref={positionRef ? undefined : setTargetRef}
          title="Emoji"
        >
          <StyledEmojiIcon />
        </EmojiButton>
        <StyledMenu
          data-aid={`slateEmoji-menu`}
          isOpen={isOpen}
          margin={menuMargin || 8}
          onClickOutside={null}
          onClose={onClose}
          position={menuPosition || 'top'}
          targetPosition="center"
          targetRef={targetRef}
        >
          <Picker
            autoFocus
            color={Theme.colors.gray600}
            data={data}
            emoji=""
            emojiTooltip
            icons={icons}
            native
            notFoundEmoji="mag"
            onEmojiSelect={insertEmoji}
            previewPosition="none"
            showPreview={false}
            showSkinTones={false}
            style={{ minWidth: '394px' }}
            theme="light"
            title=""
          />
        </StyledMenu>
      </OutsideClickHandler>
    </React.Fragment>
  );
};

export function useInsertEmoji(editor, onClose) {
  const executeAction = useExecuteAction();
  return useCallback(
    emoji => {
      editor.focus();
      editor.insertText(emoji.native);
      executeAction(TrackEmojiUsage, { name: emoji.name });
    },
    [editor, executeAction]
  );
}

export default Emoji;
const EmojiButton = styled(Button)`
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  color: inherit;
  display: flex;
  height: 25px;
  justify-content: center;
  margin: 0;
  padding: 0;
  min-width: 25px;
  &:focus {
    border: 1px solid ${p => p.theme.colors.gray200};
    border-radius: 4px;
  }
  &:hover {
    background: transparent;
    border: 1px solid ${p => p.theme.colors.gray200};
    border-radius: 4px;
  }
`;

const StyledEmojiIcon = styled(EmojiIcon)`
  fill: ${p => p.theme.colors.gray900};
  height: 13px;
  width: 13px;
`;

const StyledMenu = styled(PopoverMenu)`
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

function preventBlurring(evt) {
  evt.preventDefault();
}
