import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import { AUTOMATION_ROUTING_GROUP_ID } from 'models/routing_group';
import { getLatestConversationAttributes } from 'actions/conversation/lib/conversation_helpers';

export function CustomerNotAssignedToCurrentAgentBannerBase({ assignedTo, isLoading }) {
  if (isLoading || !assignedTo) {
    return null;
  }

  return (
    <Banner>
      <span>
        This customer is currently assigned to <Strong>{assignedTo}</Strong>
      </span>
    </Banner>
  );
}

CustomerNotAssignedToCurrentAgentBannerBase.propTypes = {
  assignedTo: PropTypes.string,
  isLoading: PropTypes.bool,
};

const CustomerNotAssignedToCurrentAgentBanner = connect(mapStateToProps)(CustomerNotAssignedToCurrentAgentBannerBase);

const Strong = styled.span`
  font-weight: 600;
`;

const Banner = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.yellow100};
  box-shadow: ${p => p.theme.boxShadow.small};
  display: flex;
  font-size: ${p => p.theme.fontSize.small};
  justify-content: center;
  padding: 4px 0;
  text-align: center;
  z-index: 4;
`;

function mapStateToProps({ getProvider }) {
  const agentsProvider = getProvider('agents');
  const currentAgentId = getProvider('currentAgent').get().id;
  const conversationsProvider = getProvider('conversations');
  const conversation = getLatestConversationAttributes(conversationsProvider, ['id', 'assignee', 'status']);
  const routingGroupsProvider = getProvider('routingGroups');

  let defaultProps = { assignedTo: '' };

  if (!conversation || conversation.status === Conversation.Status.CLOSED) {
    return defaultProps;
  }
  const isLoading = routingGroupsProvider.isLoading() || agentsProvider.isLoading();
  if (isLoading) {
    return {
      ...defaultProps,
      isLoading: true,
    };
  }

  const agentId = conversation.assignee.agentId;
  if (agentId && agentId === currentAgentId) {
    return defaultProps;
  }

  if (conversation.assignee.routingGroupId === AUTOMATION_ROUTING_GROUP_ID) {
    return {
      assignedTo: 'Self-Service',
    };
  }

  const routingGroup = routingGroupsProvider.findBy({ id: conversation.assignee.routingGroupId });
  let assignedTo = routingGroup.name;
  if (agentId) {
    const agent = agentsProvider.findBy({ id: agentId });
    assignedTo = agent ? agent.name : 'another agent';
  }

  return {
    assignedTo,
  };
}

export default CustomerNotAssignedToCurrentAgentBanner;
