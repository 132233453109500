import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import React from 'react';
import styled, { css } from 'styled-components';

import { ExpandableItem } from './slate_styles_menu.styles';
import { FONT_COLORS } from 'components/customer/composition/lib/slate/slate_marks';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { usePopoverMenu } from 'components/common/menu';

const COLORS = createEnum(...Object.keys(FONT_COLORS));

export default function SlateFontColorMenu(props) {
  const { targetRef, isOpen, onClose, onToggle, setTargetRef } = usePopoverMenu();
  const chevronDir = isOpen ? 'up' : 'down';
  const currentColor = getCurrentColor();

  const onSetFontColor = (ev, color) => {
    ev.preventDefault();
    props.editor.focus();

    let value = props.editor.value;
    value.marks.forEach(mark => {
      const fontColor = mark.get('data').get('fontColor');
      if (fontColor) {
        props.onChange(props.editor.removeMark(mark));
      }
    });

    if (color !== COLORS.black) {
      const fontColor = FONT_COLORS[color];
      props.onChange(props.editor.addMark({ type: 'font', data: { fontColor } }));
    }
    onClose();
  };

  return (
    <OutsideClickHandler onClickOutside={onClose}>
      <ExpandableItem
        css={`
          font-size: 14px;
        `}
        data-aid="slateFontColor"
        isActive={isOpen}
        onClick={onToggle}
        ref={setTargetRef}
        title="Font color"
      >
        <FontColorMenuIcon activeColor={currentColor}>
          <span>A</span>
        </FontColorMenuIcon>
        <i className={`draftStylesMenu-item-chevron fa fa-angle-${chevronDir}`} />
      </ExpandableItem>
      {renderMenu()}
    </OutsideClickHandler>
  );

  function renderMenu() {
    return (
      <StyledPopoverMenu
        boundByWindow
        data-aid="slateFontColorMenu-list"
        isOpen={isOpen}
        onClickOutside={null}
        onClose={onClose}
        position="bottom"
        targetRef={targetRef}
      >
        <ColorSwatchMenu>
          {_.map(COLORS, color => {
            return (
              <ColorSwatchContainer
                data-aid={`fontColorSwatch-${color}`}
                isActive={isColorActive(color)}
                key={`colorSwatch-${color}`}
                onClick={e => onSetFontColor(e, color)}
              >
                <ColorSwatch swatchColor={FONT_COLORS[color]} />
              </ColorSwatchContainer>
            );
          })}
        </ColorSwatchMenu>
      </StyledPopoverMenu>
    );
  }

  function getCurrentColor() {
    let currentColor;
    const editor = props.editor;
    const value = editor && editor.value;
    value &&
      value.activeMarks.forEach(mark => {
        let fontColor = mark.get('data').get('fontColor');
        if (fontColor) {
          currentColor = fontColor;
        }
      });

    return currentColor || FONT_COLORS.black;
  }

  function isColorActive(color) {
    if (!props.editor) {
      return false;
    }

    const value = props.editor.value;
    const fontColor = FONT_COLORS[color];

    let isActive = value.activeMarks.some(mark => mark.get('data').get('fontColor') === fontColor);
    if (color === COLORS.black) {
      // since black is the default text color fontColor may not actually be set on the text
      return isActive || value.marks.every(mark => !mark.get('data').get('fontColor'));
    }
    return isActive;
  }
}

export const StyledPopoverMenu = styled(PopoverMenu)`
  padding: 2px;
`;

const ColorSwatchMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 62px;
`;

const ColorSwatch = styled.div`
  border-radius: 2px;
  height: 25px;
  margin: ${p => p.theme.spacing.xsmall};
  width: 25px;
  background-color: ${p => p.swatchColor};
`;

export const ColorSwatchContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  margin: ${p => (p.isActive ? `1px 0px` : `1px`)};
  ${p => p.isActive && activeColor};
`;

const activeColor = css`
  border: 1px solid ${p => p.theme.colors.black};
  border-radius: 2px;
`;

export const FontColorMenuIcon = styled.span`
  border-bottom: 4px solid ${p => p.activeColor};
  height: 22px;
  margin-bottom: ${p => p.theme.spacing.small};
  width: 16px;
`;
