import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('WhatsApp').attr('type', 'WHATSAPP');

Factory.define('WhatsAppWithDefaults')
  .extend('WhatsApp')
  .attr('from', '+16139920250')
  .attr('to', '+12024561111')
  .attr('body', 'Wahoo!');

Factory.define('WhatsAppItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], content => Factory.attributes('WhatsApp', content));

Factory.define('WhatsAppItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('_demoOnly', true)
  .attr('content', ['content'], content => Factory.attributes('WhatsAppWithDefaults', content));
