import {
  closeConversation,
  getConversationFlags,
  requestNextConversation,
} from 'actions/conversation/lib/conversation_action_helpers';
import Conversation from 'models/conversation';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';

export default class CloseAndGetNextConversation {
  constructor(context) {
    this.context = context;
    this.interactiveErrorHandler = new GatewayErrorInteractiveHandler(context);
  }

  run({ conversationId, customerId }) {
    let { hasActiveCall, needsTopics, status } = getConversationFlags(this.context, {
      conversationId,
      customerId,
    });

    let agentId = this.currentAgent.id;

    if (status === Conversation.Status.CLOSED) {
      requestNextConversation(this.context, {
        agentId,
        conversationId,
      }).catch(err => {
        this.interactiveErrorHandler.handleCommonErrors(err);
      });

      return;
    }

    if (hasActiveCall || needsTopics) {
      return;
    }

    closeConversation(this.context, {
      conversationId,
      customerId,
      agentId,
    }).then(() => {
      return requestNextConversation(this.context, {
        agentId,
        conversationId,
      }).catch(err => {
        this.interactiveErrorHandler.handleCommonErrors(err);
      });
    });
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
