import { assign, forEach, has, keys } from 'lodash';

import { navigateBack } from 'actions/current_location/navigate_back';
import { DomainError } from 'scripts/application/lib/error';

export default class LinkExternalCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ externalProfiles, customerId, meta }) {
    // Meta values are set in external_customer_lookup_search_results.jsx / handleLinkClick()
    const { totalProfileCount, totalIntegrationCount, resultType } = meta;
    const linkCount = keys(externalProfiles).length;

    const customerProfile = this.context.stores.customers.storesFor(customerId).profile.get();

    // Create customer profile update with externalCustomerId and combined custom attributes
    // from the manually linked external profiles
    const externalCustomerIds = {};
    const customAttributes = {};

    forEach(externalProfiles, (externalProfile, integrationId) => {
      externalCustomerIds[integrationId] = externalProfile.externalCustomerId;
      assign(customAttributes, externalProfile.customAttributes);
    });

    const updatePayload = {
      customAttributes,
      externalCustomerIds,
      unlinked: false,
    };

    customerProfile.updateCustomAttributes(customAttributes);

    // If org contains a original integration, profile update also keeps externalCustomerId in sync
    const originalIntegration = this.context.stores.integrations.findBy({
      customerLookupSettings: { isOriginal: true },
    });

    if (originalIntegration && has(externalCustomerIds, originalIntegration.id)) {
      updatePayload.externalCustomerId = externalCustomerIds[originalIntegration.id];
      customerProfile.updateExternalCustomerId(externalCustomerIds[originalIntegration.id]);
    }

    customerProfile.updateExternalCustomerIds(externalCustomerIds);
    this.context.stores.profile.setPending(customerProfile);
    this.context.gateways.customerProfile
      .update({ customerId }, updatePayload)
      .then(() => {
        this.trackLinkAnalytics(customerId, totalProfileCount, linkCount, totalIntegrationCount, resultType);
        this.setLinkRequested();
        navigateBack(this.context);
      })
      .catch(e => {
        if (!(e instanceof DomainError)) {
          throw e;
        }
      });
  }

  setLinkRequested() {
    let currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation.setLinkRequested) {
      currentLocation.setLinkRequested();
    }
    this.context.stores.currentLocation.set(currentLocation);
  }

  trackLinkAnalytics(customerId, profileCount, linkCount, integrationCount, resultType) {
    this.context.analytics.track('Customer Profile Linked', {
      customerId,
      numResults: profileCount,
      numLinks: linkCount,
      numIntegrations: integrationCount,
      selectionType: resultType,
    });
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }
}
