import _ from 'lodash';

import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class RemoveCompositionError {
  constructor(context) {
    this.context = context;
  }

  run({ attr, compositionId, customerId }) {
    const compositionsStore = getCompositionsStore(this.context, customerId);
    if (!compositionsStore || !compositionsStore.has({ id: compositionId })) return;

    const errors = compositionsStore.getErrors(compositionId);
    if (errors.length === 0) return;

    const filtered = _.filter(errors, err => err.attr !== attr);
    compositionsStore.setErrors(compositionId, filtered);
  }
}
