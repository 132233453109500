import _ from 'lodash';

import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import AgentPhonePreferences from 'models/agent_phone_preferences';
import { convertPhoneToDirectDialAddress } from 'models/station_configuration';

export default class ReportVoiceQuality {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationItem }) {
    const currentAgent = this.context.stores.currentAgent.get();
    const phoneCall = conversationItem.content;

    let agentStation;
    const agentPreference = _.get(currentAgent, 'voiceConfiguration.preference');
    if (agentPreference === AgentPhonePreferences.DIRECT_DIAL) {
      agentStation = convertPhoneToDirectDialAddress(_.get(currentAgent, 'voiceConfiguration.phone'));
    } else if (agentPreference === AgentPhonePreferences.STATION) {
      agentStation = _.get(currentAgent, 'voiceConfiguration.stationId');
    }

    this.context.analytics.track('Poor Call Quality Reported', {
      agentId: currentAgent.id,
      agentStation,
      callLegType: phoneCall.getCallLegType(currentAgent.id),
      callSid: phoneCall.callId,
      conversationItemId: conversationItem.id,
      customerId,
      hasCallQualityEvents: phoneCall.callQualityEvents.length > 0,
    });

    this.context.executeAction(ShowToast, {
      type: ToastType.SUCCESS,
      message: 'Thank you for reporting a call quality issue. We will look into it!',
    });
  }
}
