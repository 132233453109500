'use strict';

import { Factory } from 'rosie';
import './lib/id';

Factory.define('AgentProfile')
  .extend('Agent')
  .attr('name', '')
  .attr('email', '');

Factory.define('AgentProfileWithDefaults')
  .extend('AgentWithDefaults')
  .attr('voiceConfiguration', () => Factory.attributes('AgentVoiceConfigurationWithDefaults'))
  .attr('employeeId', 'agent-employee-id')
  .attr('emailGreetingSnippetId', 'greeting-snippet-id')
  .attr('emailSignatureSnippetId', 'signature-snippet-id')
  .attr('version', 1);
