export default class ShowMatches {
  constructor(context) {
    this.context = context;
  }

  run(customerId) {
    let numMatches = 0;

    if (this.context.stores.customers.has({ id: customerId })) {
      const customersStores = this.context.stores.customers.storesFor(customerId);
      numMatches = customersStores.customerMatch.get() && customersStores.customerMatch.get().profiles.length;
      this.context.analytics.track('Customer Match - Modal opened', { customerId, numMatches });
    }
  }
}
