import ReportChannel from 'models/reports/report_channel';
import { Channel } from 'models/search/search_filter.js';

export default function(channel) {
  switch (channel) {
    case ReportChannel.EMAIL:
      return Channel.EMAIL;
    case ReportChannel.FB_MESSENGER:
      return Channel.FB_MESSAGE;
    case ReportChannel.PHONE_CALL:
      return Channel.PHONE_CALL;
    case ReportChannel.SMS:
      return Channel.SMS;
    case ReportChannel.CHAT_SESSION:
      return Channel.CHAT_MESSAGE;
    case ReportChannel.TWITTER:
      return Channel.TWITTER;
    case ReportChannel.WHATSAPP:
      return Channel.WHATSAPP;
    default:
      return;
  }
}
