import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { AgentMentionMenu, AgentMentionPlugin } from 'components/text_editor/plugins/agent_mention';
import CapitalizeFirstWord from 'components/text_editor/plugins/capitalize_first_word';
import CapitalizeI from 'components/text_editor/plugins/capitalize_i';
import Lists from 'components/text_editor/plugins/lists';
import Placeholders, { useFocusFirstPlaceholder } from 'components/text_editor/plugins/placeholders';
import QuoteBlocks from 'components/text_editor/plugins/quote_blocks';
import RichText from 'components/text_editor/plugins/rich_text';
import InsertSelectedSuggestedReply from 'components/text_editor/plugins/suggested_replies/insert_selected_suggested_reply';
import TextEditor, { getEditorProps, useEditorRef, usePlugins } from 'components/text_editor';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import { useToggleAnswerPanel } from '../plugins/toggle_answer_panel';

export default function RichTextEditor(props) {
  const { allowMentions, agentMentionProps, onChange } = props;
  const editorRef = useEditorRef(props.editorRef);
  useFocusFirstPlaceholder(editorRef.current, onChange);

  const toggleAnswerPanel = useToggleAnswerPanel();

  const agentMentionSearchRef = useRef(null);
  const agentMentionRef = useRef(null);

  const isFeatureEnabled = useIsFeatureEnabled();
  const isSuggestedRepliesEnabled = isFeatureEnabled('suggestedReplies');

  // Order matters! Be careful with reordering this list.
  const plugins = usePlugins(() => {
    return _.compact([
      CapitalizeI(),
      CapitalizeFirstWord(),
      allowMentions && AgentMentionPlugin(agentMentionRef, agentMentionSearchRef),
      RichText({ onChange }),
      Lists(),
      // commenting out inline links for now because slate decorations don't play well with spellcheck
      // InlineLinks(),
      Placeholders(),
      QuoteBlocks(),

      toggleAnswerPanel,
      ...props.plugins,
    ]);
  }, [allowMentions, isSuggestedRepliesEnabled, agentMentionRef]);

  const editorProps = getEditorProps(props);
  const children = allowMentions ? (
    <AgentMentionMenu
      {...agentMentionProps}
      mentionSearchRef={agentMentionSearchRef}
      menuActionsRef={agentMentionRef}
    />
  ) : null;

  return (
    <>
      <TextEditor {...editorProps} editorRef={editorRef} plugins={plugins}>
        {children}
      </TextEditor>

      {isSuggestedRepliesEnabled ? (
        <InsertSelectedSuggestedReply editor={editorRef.current} onChange={onChange} />
      ) : null}
    </>
  );
}

RichTextEditor.defaultProps = {
  agentMentionProps: {},
  allowMentions: false,
  plugins: [],
};

RichTextEditor.propTypes = {
  agentMentionProps: PropTypes.object,
  allowMentions: PropTypes.bool,
  editorRef: PropTypes.shape({ current: PropTypes.any }),
  onChange: PropTypes.func.isRequired,
  plugins: PropTypes.array,
};
