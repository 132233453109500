import * as FS from '@fullstory/browser';
import _ from 'lodash';

export default class FullstoryAdapter {
  constructor(fullstoryOrgId) {
    this.isEnabled = false;
    this.fullstoryOrgId = fullstoryOrgId;
    this._fullstory = FS.FullStory;
  }

  enable() {
    if (!this.isEnabled && !this.isForceDisabled) {
      this._fullstory('restart');
      this.isEnabled = true;
    }
  }

  /* This will prevent Fullstory from starting if it has not already or stop the tracking if Fullstory has not been yet enabled.
  (January 2024) Note that Fullstory charges us per session and therefore disabling Fullstory after the session 
  has already started does not give us much advantage. */
  forceDisable() {
    this.isForceDisabled = true;
    this.disable();
  }

  disable() {
    if (this.isEnabled) {
      this._fullstory('setIdentity', { anonymous: true });
      this._fullstory('shutdown');
      this.isEnabled = false;
    }
  }

  init() {
    this._init({ orgId: this.fullstoryOrgId, startCaptureManually: true /* , debug: true*/ });
  }

  _init(options) {
    FS.init(options);
  }

  setUser(user) {
    if (!this.isEnabled || !user || !user.id) {
      return;
    }
    this._fullstory('setIdentity', { uid: user.id });
    let userProperties = _.omit(user, 'id');
    if (!_.isEmpty(userProperties)) {
      this.updateUser(userProperties);
    }
  }

  updateUser(properties) {
    if (!this.isEnabled) {
      return;
    }
    let userProperties = _(properties)
      .omit('id')
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    if (_.isEmpty(userProperties)) {
      return;
    }
    if (userProperties.name) {
      userProperties.displayName = userProperties.name;
      delete userProperties.name;
    }
    this._fullstory('setProperties', {
      type: 'user',
      properties: userProperties || {},
    });
  }

  track(eventName, eventProperties) {
    if (!this.isEnabled) {
      return;
    }
    this._fullstory('trackEvent', { name: eventName, properties: eventProperties || {} });
  }
}
