import _ from 'lodash';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import { NumberCard } from 'components/liveboards/cards/metric_card';
import DonutChart from 'components/liveboards/charts/donut_chart';

const DonutCardType = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  tooltip: PropTypes.string,
  link: PropTypes.string,
};

export default function DonutCard({ donutData, legendData, label, displayValue, radius, link }) {
  return (
    <div className="donutCard-contents">
      <DonutChart data={donutData} displayValue={displayValue} label={label} link={link} radius={radius} />
      <div className="donutCard-legend">
        {legendData.map(({ className, label, tooltip, value, link }) => (
          <NumberCard
            className="donutCard-numberCard"
            key={`donutCard-number-${label}`}
            label={label}
            labelColor={className}
            link={link}
            tooltip={tooltip}
            tooltipPosition="top"
            value={value}
          />
        ))}
      </div>
    </div>
  );
}

DonutCard.propTypes = {
  donutData: PropTypes.arrayOf(PropTypes.shape(DonutCardType)).isRequired,
  legendData: PropTypes.arrayOf(PropTypes.shape(DonutCardType)).isRequired,
  label: PropTypes.string,
  displayValue: PropTypes.string,
  radius: PropTypes.number,
};

export class SumDonutCard extends Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }

  render() {
    const data = this.props.data.toJS();
    const total = _.reduce(data, (sum, piece) => sum + piece.value, 0);
    return (
      <DonutCard
        displayValue={total.toLocaleString()}
        donutData={total === 0 ? [] : data}
        label={this.props.label}
        legendData={data}
        link={this.props.link}
      />
    );
  }
}

SumDonutCard.propTypes = {
  data: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(DonutCardType)).isRequired,
  label: PropTypes.string,
};

export class PercentageDonutCard extends Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }

  render() {
    const { label, percentage } = this.props;
    const data = this.props.data.toJS();
    const props = {
      radius: 90,
      legendData: data,
    };

    if (percentage != null) {
      return <DonutCard {...props} displayValue={`${percentage.toFixed(1)}%`} donutData={data} label={label} />;
    }

    return <DonutCard {...props} displayValue="–" donutData={[]} label="" />;
  }
}

PercentageDonutCard.propTypes = {
  data: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains(DonutCardType)).isRequired,
  label: PropTypes.string,
  percentage: PropTypes.number,
};
