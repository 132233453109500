import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';

import AgentHome from 'components/agent_home';
import { AgentProfileWrapper } from 'components/user/agent_profile_container';
import AgentProfileEditor from 'models/location/agent_profile_editor';
import AnswersPreview from 'models/location/answers_preview';
import AnswersPreviewPage from 'components/customer/answer_panel/answers_preview';
import CustomerCombine from 'models/location/customer_combine';
import CustomerCombinePage from 'components/customer_combine_page';
import CustomerPageContainer from 'components/customer_page';
import CustomerView from 'models/location/customer_view';
import CustomReporting from 'models/location/custom_reporting';
import CustomReportingPage from 'components/custom_reporting/custom_reporting_page';
import Datasets from 'models/location/datasets';
import ExternalCustomerLookup from 'models/location/external_customer_lookup';
import ExternalCustomerLookupPage from 'components/external_customer_lookup_page';
import ForgotPassword from 'models/location/forgot_password';
import ForgotPasswordContainer from 'components/user/forgot_password_container';
import FloorboardLocation from 'models/location/floorboard';
import Floorboard from 'components/floorboard/floorboard';
import Inbox from 'models/location/inbox';
import KbAdmin from 'models/location/kb_admin';
import KnowledgeBaseAdminPage from 'components/admin/knowledge_base/knowledge_base_admin_page';
import LiveboardsLocation from 'models/location/liveboards';
import Liveboards from 'components/liveboards/liveboards';
import ReportHome from 'components/reporting/report_home';
import Reports from 'models/location/reports';
import Search from 'models/location/search';
import SearchPage from 'components/search_page';
import SnippetAdminPage from 'components/admin/knowledge_base/snippet/snippet_admin_page';
import SnippetEditor from 'models/location/snippet_editor';
import UserActivation from 'models/location/user_activation';
import UserActivationContainer from 'components/user/user_activation_container';
import UserLogin from 'models/location/user_login';
import UserLoginContainer from 'components/user/user_login_container';
import UserResetPassword from 'models/location/user_reset_password';
import UserResetPasswordContainer from 'components/user/user_reset_password_container';

import Sidebar from 'components/sidebar/sidebar';

const PageContainer = styled.div`
  display: flex;
`;

export function PageContentBase({ binding, currentLocation }) {
  return (
    <PageContainer className="page-container">
      {!isReportLocation(currentLocation) ? <Sidebar /> : null}
      {renderComponent()}
    </PageContainer>
  );

  function renderComponent() {
    if (currentLocation instanceof AnswersPreview) {
      return <AnswersPreviewPage />;
    } else if (currentLocation instanceof AgentProfileEditor) {
      return <AgentProfileWrapper />;
    } else if (currentLocation instanceof CustomerCombine) {
      return <CustomerCombinePage />;
    } else if (currentLocation instanceof CustomerView) {
      return <CustomerPageContainer />;
    } else if (currentLocation instanceof CustomReporting) {
      return <CustomReportingPage />;
    } else if (currentLocation instanceof ExternalCustomerLookup) {
      return <ExternalCustomerLookupPage />;
    } else if (currentLocation instanceof ForgotPassword) {
      return <ForgotPasswordContainer />;
    } else if (currentLocation instanceof FloorboardLocation) {
      return <Floorboard />;
    } else if (currentLocation instanceof Inbox) {
      return <AgentHome binding={binding} />;
    } else if (currentLocation instanceof KbAdmin) {
      return <KnowledgeBaseAdminPage />;
    } else if (currentLocation instanceof LiveboardsLocation) {
      return (
        <Liveboards
          binding={{
            default: binding,
            liveboard: binding.sub('liveboard'),
          }}
        />
      );
    } else if (isReportLocation(currentLocation)) {
      return <ReportHome />;
    } else if (currentLocation instanceof Search) {
      return <SearchPage />;
    } else if (currentLocation instanceof SnippetEditor) {
      return <SnippetAdminPage />;
    } else if (currentLocation instanceof UserActivation) {
      return <UserActivationContainer />;
    } else if (currentLocation instanceof UserLogin) {
      return <UserLoginContainer />;
    } else if (currentLocation instanceof UserResetPassword) {
      return <UserResetPasswordContainer />;
    }

    return null;
  }
}

function isReportLocation(location) {
  return location instanceof Reports || location instanceof Datasets;
}

const PageContent = connect(mapStateToProps)(PageContentBase);
export default PageContent;

function mapStateToProps({ getProvider }) {
  const currentLocation = getProvider('currentLocation').get();

  return {
    currentLocation,
  };
}
