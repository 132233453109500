import moment from 'moment';

import getBc from './get_broadcast_channel';
import MultipleTabsNotification from 'models/notification/multiple_tabs_notification';
import IdGenerator from 'scripts/domain/contracts/id_generator';

export const browserTabsNotificationKey = 'gladly-browser-tabs-notification';
const channelName = 'gladly-agent-desktop';
let channel;
let id;
const DaysUntilExpiration = 10;
export const multipleTabsNotificationId = 'multiple-tabs';

export function postMessage(attrs) {
  const [channel, id] = getChannelAndId();
  if (!attrs.type) {
    throw new Error(`Missing [type] from posted message`);
  }
  channel.postMessage({ ...attrs, id });
}

export function getChannelAndId() {
  if (!channel) {
    const BroadcastChannel = getBc.getBroadcastChannel();
    channel = new BroadcastChannel(channelName);
    id = IdGenerator.newId();
  }
  return [channel, id];
}

export function createAgentHasDismissedNotification() {
  localStorage.setItem(
    browserTabsNotificationKey,
    JSON.stringify({
      expiry: moment()
        .add(DaysUntilExpiration, 'days')
        .toDate()
        .getTime(),
    })
  );
}

export function getAgentHasDismissedNotificationRecently() {
  let notificationItemJSON = localStorage.getItem(browserTabsNotificationKey);
  if (notificationItemJSON) {
    const notificationItem = JSON.parse(notificationItemJSON);
    const now = new Date();
    if (now.getTime() > notificationItem.expiry) {
      localStorage.removeItem(browserTabsNotificationKey);
      return null;
    }
    return notificationItem;
  }
  return null;
}

export function hasNotification(context) {
  return context.stores.notifications.has({ id: multipleTabsNotificationId });
}

export function createNotification(context) {
  const notification = MultipleTabsNotification.create({ id: multipleTabsNotificationId });
  context.stores.notifications.addOrReplace(notification);
}
