import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Err from 'models/err';
import Tooltip from 'components/common/tooltip';

/**
 * ErrorTooltip will render a tooltip below a contact field if there are errors pertaining to that
 * contact field.
 */
export default function ErrorTooltip({ children, className, 'data-aid': aid, errors, position }) {
  const hasErrors = !_.isEmpty(errors);

  const classNames = classnames(
    'contactField-errorTooltip',
    {
      'contactField-errorTooltip-hasErrors': hasErrors,
    },
    className
  );

  return (
    <Tooltip
      className={classNames}
      data-aid={aid}
      forceVisible={hasErrors}
      message={_.map(errors, e => _.capitalize(e.detail)).join('; ')}
      position={position}
    >
      {children}
    </Tooltip>
  );
}

ErrorTooltip.defaultProps = {
  position: 'bottom',
};

ErrorTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)),
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};
