import PropTypes from 'prop-types';
import React from 'react';
import IconContainer from './icon_container';

export default function PhoneNotAvailableIcon({ props, className }) {
  return (
    <IconContainer viewBox="0 0 26 27" {...props} className="phone-not-available-icon">
      <g fill="none" fillRule="evenodd">
        <path
          clipRule="evenodd"
          d="M21.444 24.973c.33-.059.625-.232.841-.482l2.37-2.737a1.402 1.402 0 00-.11-1.954l-3.537-3.284a1.492 1.492 0 00-2.004-.014l-1.892 1.706c-.42.38-1.03.483-1.56.27-.854-.343-2.532-1.513-4.207-3.08l-.684-.742c-1.567-1.676-2.737-3.354-3.081-4.208a1.501 1.501 0 01.27-1.56l1.706-1.89a1.491 1.491 0 00-.014-2.005L6.258 1.455a1.4 1.4 0 00-1.953-.11l-2.738 2.37a1.45 1.45 0 00-.481.841c-.26 1.478-.297 5.86 5.558 12.711l2.107 2.108c6.853 5.853 11.216 5.859 12.693 5.598z"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M23.369 2.338l-22 24" stroke="#fff" />
      </g>
    </IconContainer>
  );
}

PhoneNotAvailableIcon.propTypes = {
  className: PropTypes.string,
};
