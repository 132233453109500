import createNotificationModel, { prop } from 'models/notification/lib/create_notification_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import NotificationType from 'models/notification/notification_type';

export default createNotificationModel(NotificationType.MULTIPLE_TABS, {
  modelName: 'MultipleTabsNotification',
  properties: {
    id: prop(String).isRequired,
  },
  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), ...attrs });
    },
  },
});
