export function addPendingRequestIdForConversation(context, conversationId, requestId) {
  const workflow = context.stores.conversationWorkflow.get();
  workflow.addPendingRequest(conversationId, requestId);
  context.stores.conversationWorkflow.set(workflow);
}

export function clearPendingRequest(context, requestId) {
  const workflow = context.stores.conversationWorkflow.get();
  workflow.clearPendingRequest(requestId);
  context.stores.conversationWorkflow.set(workflow);
}
