import styled from 'styled-components';

import BubbleContent from '../content/bubble_content';

export const StyledActivityTitle = styled.div`
  font-weight: 600;
`;

export const StyledActivityBody = styled.div`
  ${p =>
    p.$isExpanded
      ? ''
      : `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;`};
`;

export const StyledActivityExpander = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid ${p => p.theme.colors.gray200};
  color: ${p => p.theme.colors.gray400};
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover {
    background: #f9f9f9;
    color: ${p => p.theme.colors.green400};
  }
`;

export const StyledChevron = styled.div`
  cursor: pointer;
  display: inline-block;
  transition: transform 0.15s ease;
  ${p =>
    p.$isDownwards &&
    `
      transform: rotate(180deg);
      `};
`;

export const StyledActivityBubbleContent = styled(BubbleContent)`
  padding: 0;
`;

export const StyledActivityContent = styled.div`
  padding: 10px 20px;
`;
