import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ChevronDownIcon from 'components/lib/icons/chevron_down_icon';
import { QuickReplyOption } from 'models/chat_message';

export default function ChatQuickReplyRequestContent({ className, item, status, requestText, options }) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <BubbleContent
      className={classnames('chatItem-content quick-reply-request', className)}
      data-aid={`chatItem-quick-reply-request-${item.id}`}
      item={item}
      status={status}
    >
      <InlineAnnotatedContentContainer id={item.id} isRedacted={item.content.isRedacted} text={requestText} />
      {renderOptions(options)}
    </BubbleContent>
  );

  function renderOptions(opts) {
    let optionsSpans = opts.map((o, i) => {
      return (
        <span key={o.id}>
          {o.label}
          {i < opts.length - 1 ? ', ' : ''}
        </span>
      );
    });

    return (
      <Options>
        <OptionsTitle onClick={() => setShowOptions(!showOptions)}>
          Quick Reply Options <ChevronIcon data-isopen={showOptions} />
        </OptionsTitle>
        {showOptions && optionsSpans}
      </Options>
    );
  }
}

export const Options = styled.div`
  margin-top: 10px;
`;

const OptionsTitle = styled.span`
  font-weight: ${p => p.theme.fontWeight.heavy};
  display: flex;
  cursor: pointer;
`;

export const ChevronIcon = styled(ChevronDownIcon)`
  stroke: ${p => p.theme.colors.gray600};
  fill: ${p => p.theme.colors.gray600};
  height: 1em;
  display: flex;
  margin-left: ${p => p.theme.spacing.small};
  margin-top: ${p => p.theme.spacing.small};
  ${p => p['data-isopen'] && flipChevron};
`;

const flipChevron = css`
  transform: rotate(-180deg);
`;
ChatQuickReplyRequestContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  status: PropTypes.string,
  requestText: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.instanceOf(QuickReplyOption)),
};
