import _ from 'lodash';
import classnames from 'classnames';
import Immutable from 'immutable';

import connect from 'components/lib/connect';
import { MultiSelectMenu } from 'components/lib/conversation_multi_topic_deprecated';
import NavigateTo from 'actions/current_location/navigate_to';

export default connect(mapStateToProps, mapExecuteToProps)(MultiSelectMenu);

function mapStateToProps({ getProvider }, { className, optionIds }) {
  return {
    className: classnames('multipleSelectMenu', className),
    onFocus: _.noop,
    optionIds: optionIds || [],
    options: getTopicOptions(),
    placeholder: 'All Topics',
    unitLabelSingular: 'topic',
    unitLabelPlural: 'topics',
  };

  function getTopicOptions() {
    let topics = _.map(getProvider('topics').findAll(), topic => ({
      id: topic.id,
      name: topic.getNameWithAncestry(),
    }));
    return Immutable.fromJS(topics);
  }
}

function mapExecuteToProps(executeAction, { currentLocation, optionIds, onApply }) {
  return {
    onOptionChange: handleOptionChange,
  };

  function handleOptionChange(updatedIds) {
    let currentIds = optionIds || [];
    if (updatedIds.length !== currentIds.length || _.difference(updatedIds, currentIds).length > 0) {
      if (onApply) {
        return onApply(updatedIds);
      } else {
        executeAction(NavigateTo, currentLocation.deriveNew({ topicIds: updatedIds }));
      }
    }
  }
}
