import TopicPattern from 'scripts/infrastructure/topic_pattern';
import ConversationItemType from 'models/conversation_item_type';

const { CHAT, PHONE_CALLBACK, TWITTER } = ConversationItemType;
const CONVERSATIONS_UPDATE = new TopicPattern(
  'v1/requestor/:requestorId/orgs/:orgId/customer-history/:customerId/conversations/:conversationId/command/update'
);

export default function createDemoPublisherShims() {
  return [
    {
      pattern: CONVERSATIONS_UPDATE,
      shim: createConversationUpdateShim,
    },
  ];
}

function createConversationUpdateShim(topic, envelope, publish) {
  if (envelope.payload) {
    const convAttrs = envelope.payload;
    const newConvItems = convAttrs.newConversationItems;
    if (!newConvItems) {
      return false;
    }

    switch (newConvItems[0].content.type) {
      case CHAT:
      case PHONE_CALLBACK:
      case TWITTER:
        publish(topic, envelope);
        return true;
    }
  }

  return false;
}
