import _ from 'lodash';
import moment from 'moment';

import TopicPattern from 'scripts/infrastructure/topic_pattern';
import InboxService from './fake_backend/inbox_service_v3';

const CONVERSATION_BROADCAST = new TopicPattern(
  'v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId'
);
const CONVERSATIONS_REQUESTOR = new TopicPattern(
  'v1/requestor/:requestorId/orgs/:orgId/customer-history/:customerId/conversations'
);

const CONVERSATION_ITEMS_REQUESTOR = new TopicPattern(
  'v1/requestor/:requestorId/orgs/:orgId/customer-history/:customerId/conversation-items'
);

const CURRENT_CONVERSATION_ITEMS_REQUESTOR = new TopicPattern(
  'v1/requestor/:requestorId/orgs/:orgId/customer-history/:customerId/current-items/:conversationId'
);
const ITEM_IDS_REQUESTOR = new TopicPattern(
  'v1/requestor/:requestorId/orgs/:orgId/customer-history/:customerId/item-ids'
);

const GROUP_INBOX_REQUESTOR = new TopicPattern(
  'v1/requestor/:requestorId/orgs/:orgId/routing-groups/:routingGroupId/inbox'
);
const AGENT_INBOX_REQUESTOR = new TopicPattern('v1/requestor/:requestorId/orgs/:orgId/agents/:agentId/inbox');

const AGENTS_LIVEBOARD_REQUESTOR = new TopicPattern('v4/requestor/:requestorId/orgs/:orgId/liveboards/agents');
const SUMMARY_LIVEBOARD_REQUESTOR = new TopicPattern('v3/requestor/:requestorId/orgs/:orgId/liveboards/summary');
const TOPICS_LIVEBOARD_REQUESTOR = new TopicPattern('v3/requestor/:requestorId/orgs/:orgId/liveboards/topics');

export default function createDemoSubscriberShims() {
  return [
    {
      pattern: CONVERSATION_BROADCAST,
      shim: createConversationShim,
    },
    {
      pattern: CONVERSATIONS_REQUESTOR,
      shim: createConversationsShim,
    },
    {
      pattern: CONVERSATION_ITEMS_REQUESTOR,
      shim: createConversationItemsShim,
    },
    {
      pattern: CURRENT_CONVERSATION_ITEMS_REQUESTOR,
      shim: createCurrentConversationItemsShim,
    },
    {
      pattern: ITEM_IDS_REQUESTOR,
      shim: createItemIdsShim,
    },
    {
      pattern: AGENT_INBOX_REQUESTOR,
      shim: createInboxShim,
    },
    {
      pattern: GROUP_INBOX_REQUESTOR,
      shim: createInboxShim,
    },
    {
      pattern: AGENTS_LIVEBOARD_REQUESTOR,
      shim: generateLivebordShimCreator('agents'),
    },
    {
      pattern: SUMMARY_LIVEBOARD_REQUESTOR,
      shim: generateLivebordShimCreator('summary'),
    },
    {
      pattern: TOPICS_LIVEBOARD_REQUESTOR,
      shim: generateLivebordShimCreator('topics'),
    },
  ];
}

function createInboxShim(callback, { conversationItemsService }) {
  return (envelope, topic) => {
    if (envelope.payload) {
      let { orgId } = this.pattern.extractParams(topic);

      envelope = _.cloneDeep(envelope);
      envelope.payload = _.map(envelope.payload, (serverConversationPreview, i) => {
        let serverConversationItem = serverConversationPreview.conversationItem || {};
        let demoConversationItems = conversationItemsService.findByConversationId(
          orgId,
          serverConversationPreview.conversation.id
        );

        let demoConversationItem =
          new InboxService().convertConversationToPreview({
            customer: {},
            conversationItems: demoConversationItems,
          }).conversationItem || {};

        let serverConversationPreviewWithoutConversationItem = _.omit(serverConversationPreview, 'conversationItem');

        let shimmedConversationItem;
        if (demoConversationItem.id === serverConversationItem.id) {
          shimmedConversationItem = _.merge({}, demoConversationItem, serverConversationItem);
        } else {
          let items = _([demoConversationItem, serverConversationItem])
            .reject(_.isEmpty)
            .orderBy(item => moment(item.timestamp).valueOf(), ['asc'])
            .value();

          shimmedConversationItem = _.last(items);
        }

        if (_.isEmpty(shimmedConversationItem)) {
          return serverConversationPreviewWithoutConversationItem;
        }
        return _.merge(serverConversationPreviewWithoutConversationItem, {
          conversationItem: shimmedConversationItem,
        });
      });
    }
    return callback(envelope, topic);
  };
}

function createConversationShim(callback, { conversationsService }) {
  return (envelope, topic) => {
    if (envelope.payload) {
      let { orgId, customerId } = this.pattern.extractParams(topic);
      let demoConversations = conversationsService.findByCustomerId(orgId, customerId);
      if (demoConversations) {
        envelope = _.cloneDeep(envelope);
        let conv = envelope.payload;
        let demoConv = _.find(demoConversations, dc => dc.id === conv.id);
        envelope.payload = mergeConversation(demoConv, conv);
      }
    }
    return callback(envelope, topic);
  };
}

function createConversationsShim(callback, { conversationsService }) {
  return (envelope, topic) => {
    if (envelope.payload) {
      let { orgId, customerId } = this.pattern.extractParams(topic);
      let demoConversations = conversationsService.findByCustomerId(orgId, customerId);
      if (demoConversations) {
        let indexedDemoConvs = _.keyBy(demoConversations, 'id');
        envelope = _.cloneDeep(envelope);
        envelope.payload = _.map(envelope.payload, conv => {
          let demoConv = indexedDemoConvs[conv.id];
          return mergeConversation(demoConv, conv);
        });
      }
    }
    return callback(envelope, topic);
  };
}

function mergeConversation(target, source) {
  if (target) {
    return _.merge({}, target, source);
  }
  return source;
}

// as a simplification for demo purposes conversation items shim returns all conversation
// items even if a filter was specified in the request payload
function createConversationItemsShim(callback, { conversationItemsService }) {
  return (envelope, topic) => {
    if (envelope.payload) {
      let { orgId, customerId } = this.pattern.extractParams(topic);
      let demoConversationItems = conversationItemsService.findByCustomerId(orgId, customerId);
      if (demoConversationItems) {
        envelope = _.cloneDeep(envelope);
        envelope.payload = _(
          envelope.payload.concat(
            _.filter(demoConversationItems, demoItem => {
              return !_.find(envelope.payload, item => item.id === demoItem.id);
            })
          )
        )
          .orderBy(item => moment(item.timestamp).valueOf(), ['asc'])
          .value();
      }
    }
    return callback(envelope, topic);
  };
}

// This will only inject conversation-items as part of the current conversation
function createCurrentConversationItemsShim(callback, { conversationItemsService }) {
  return (envelope, topic) => {
    if (envelope.payload.latestNonAutomaticInitiatorItemsOfEachType) {
      let { orgId, customerId } = this.pattern.extractParams(topic);
      let demoConversationItems = conversationItemsService.findByCustomerId(orgId, customerId);
      if (demoConversationItems) {
        envelope = _.cloneDeep(envelope);
        envelope.payload.latestNonAutomaticInitiatorItemsOfEachType = _(
          envelope.payload.latestNonAutomaticInitiatorItemsOfEachType.concat(
            _injectCurrentDemoItems(envelope.payload, demoConversationItems)
          )
        )
          .orderBy(item => moment(item.timestamp).valueOf(), ['asc'])
          .value();
      }
    }
    return callback(envelope, topic);
  };
}

// We only need to do special injecting if the currentItems contain a chat session currently.
// We can extend this in the future to handle more cases if needed. The 'createConversationItemsShim'
// handles injecting all other conversation items if there is no active chat in the current items
function _injectCurrentDemoItems(currentItems, demoItems) {
  const chatSession = currentItems.activeChat;
  if (!chatSession) {
    return [];
  }

  // Find demo items for the chat session conversation
  const demoItemsToAdd = _.filter(demoItems, demoItem => {
    return (
      !_.find(currentItems.latestNonAutomaticInitiatorItemsOfEachType, item => item.id === demoItem.id) &&
      demoItem.conversationId === chatSession.conversationId
    );
  });
  const helpAppItemIndex = _.findIndex(demoItemsToAdd, item => {
    return item.content && item.content.type === 'HELP_APP_CONTEXT';
  });
  if (helpAppItemIndex !== -1) {
    demoItemsToAdd[helpAppItemIndex].timestamp = moment(chatSession.timestamp)
      .subtract(1, 'minute')
      .toISOString();
  }

  return demoItemsToAdd;
}

function createItemIdsShim(callback, { conversationItemsService }) {
  return (envelope, topic) => {
    if (envelope.payload) {
      let { orgId, customerId } = this.pattern.extractParams(topic);
      let demoItemIds = conversationItemsService.findItemIdsByCustomerId(orgId, customerId);
      if (demoItemIds) {
        envelope = _.cloneDeep(envelope);
        envelope.payload = _(
          envelope.payload.concat(
            _.filter(demoItemIds, demoItemId => {
              return !_.find(envelope.payload, item => item.id === demoItemId.id);
            })
          )
        )
          .orderBy(item => moment(item.timestamp).valueOf(), ['asc'])
          .value();
      }
    }
    return callback(envelope, topic);
  };
}

function generateLivebordShimCreator(liveboardType) {
  return function createLiveboardShim(callback, { liveboardService }) {
    return (envelope, topic) => {
      if (envelope.payload) {
        let fakePayload = liveboardService.fetch(envelope.topicParams.orgId, liveboardType, envelope.payload);
        if (_.isEmpty(fakePayload)) {
          return callback(envelope, topic);
        }

        if (liveboardType === 'agents') {
          _.each(envelope.payload.utilization, u => {
            let index = _.findIndex(fakePayload.utilization, { agentId: u.agentId });
            fakePayload.utilization.splice(index, 1, u);
          });
        }
        envelope.payload = fakePayload;
      }
      return callback(envelope, topic);
    };
  };
}
