import _ from 'lodash';

import createModel, { prop } from 'models/lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import SearchAggregations from 'models/search/search_aggregations';
import SearchQuery from 'models/search/search_query';
import ServerClock from 'scripts/application/lib/server_clock';
import Snippet from 'models/answers/snippet';

export default createModel({
  modelName: 'SnippetSearchResult',

  properties: {
    id: prop(String).isRequired,
    requestedAt: String,
    hits: prop([Snippet]).default([]),
    query: prop(SearchQuery).isRequired,
    isLoading: prop(Boolean).default(false), // whether the search results are loading or returned
    aggregations: SearchAggregations,
  },

  statics: {
    create(attrs) {
      let defaults = {
        id: IdGenerator.newId(),
        requestedAt: ServerClock.toISOString(),
      };

      return new this(_.merge(defaults, attrs));
    },

    createWithSearchText(text) {
      return this.create({
        query: new SearchQuery({ text }),
      });
    },
  },
});
