import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/common/button';
import ChevronDownIcon from 'scripts/presentation/components/lib/icons/chevron_down_icon';
import ChevronUpIcon from 'scripts/presentation/components/lib/icons/chevron_up_icon';
import PopoverMenu, { PopoverMenuItem } from 'components/common/popover_menu_deprecated';
import WindowSizeWatcher from 'scripts/presentation/components/common/utilities/window_size_watcher';

/**
 * @visibleName Split Button
 */

export default class SplitButton extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.setButtonRef = this.setButtonRef.bind(this);
  }

  handleActionClick(evt) {
    if (this.props.disabled) {
      return;
    }
    evt.preventDefault();
    this.props.onActionClick();
  }

  handleMenuClick(evt) {
    if (this.props.disabled) {
      return;
    }
    evt.preventDefault();
    this.actionMenu.toggle();
  }

  onToggle(isOpen) {
    this.setState({ isOpen });
  }

  render() {
    let direction =
      this.props.dropDirection === 'up'
        ? 'top'
        : this.props.dropDirection === 'down'
        ? 'bottom'
        : this.props.dropDirection;
    return (
      <SplitButtonContainer className={this.props.className} data-aid={this.props['data-aid']}>
        {this.renderActionButton()}
        {this.renderMenuButton()}
        <WindowSizeWatcher>
          {({ windowHeight, windowWidth }) => (
            <PopoverMenu
              autoPosition
              boundByWindow={false}
              bounds={{
                bottom: windowHeight - 8,
                left: 8,
                right: windowWidth - 8,
                top: 68,
              }}
              button={this.state.menuButton}
              isDisabled={this.props.disabled}
              onToggle={this.onToggle}
              position={direction}
              ref={node => (this.actionMenu = node)}
            >
              {this.props.menuItems}
            </PopoverMenu>
          )}
        </WindowSizeWatcher>
      </SplitButtonContainer>
    );
  }

  renderActionButton() {
    let props = _.omit(this.props, ['data-aid', 'onActionClick']);
    return (
      <ActionButton data-aid="action-button" {...props} onClick={this.handleActionClick}>
        {this.props.children}
      </ActionButton>
    );
  }

  renderMenuButton() {
    let props = _.omit(this.props, 'data-aid', 'onActionClick');
    let direction = this.state.isOpen ? OPPOSITE_DIRECTION[this.props.dropDirection] : this.props.dropDirection;
    let RenderIcon;
    switch (direction) {
      case 'up':
        RenderIcon = UpIcon;
        break;

      default:
        RenderIcon = DownIcon;
        break;
    }
    return (
      <MenuButton data-aid="menu-button" {...props} onClick={this.handleMenuClick} ref={this.setButtonRef}>
        <RenderIcon />
      </MenuButton>
    );
  }
  setButtonRef(node) {
    this.setState({ menuButton: node });
  }
}

export const ActionButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid ${p => p.theme.colors.white};
  line-height: ${p => p.theme.lineHeight.controls};
  padding: ${p => p.theme.spacing.medium} 12px ${p => p.theme.spacing.medium} 16px;
`;
export const MenuButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: ${p => p.theme.lineHeight.controls};
  padding: ${p => p.theme.spacing.medium} 12px ${p => p.theme.spacing.medium} 10px;
  display: flex;
  align-items: center;
`;

const SplitButtonContainer = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row;
`;

const chevronCommon = css`
  stroke: ${p => p.theme.colors.white};
  fill: ${p => p.theme.colors.white};
  height: 16px;
  width: 13px;
  line-height: 0;
`;

const DownIcon = styled(ChevronDownIcon)`
  ${chevronCommon};
`;

const UpIcon = styled(ChevronUpIcon)`
  ${chevronCommon};
`;

SplitButton.defaultProps = {
  dropDirection: 'up',
};

SplitButton.propTypes = {
  disabled: PropTypes.bool,
  /** Disables interaction with both sides of the button */
  onActionClick: PropTypes.func.isRequired,
  /** Event handler for the main part of the button */
  menuItems: PropTypes.arrayOf(PopoverMenuItem).isRequired,
  /** Array of PopOverMenuItems to be displayed in the menu */
  dropDirection: PropTypes.string,
  /** Direction the menu should attempt to drop (up, down) Optional. Default is down.
   * If the menu cannot fit in the direction specified it will be auto positioned. This property will
   * also effect the direction of the arrow on the menu button */
};

const OPPOSITE_DIRECTION = {
  up: 'down',
  down: 'up',
};
