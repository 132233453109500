import CompositionContentType from 'models/composition/composition_content_type';

export const CONTEXT_COMPOSITION = 'iglu:com.gladly.agent_desktop/context_composition/jsonschema/1-0-1';

export const getCompositionContextFromComposition = composition => ({
  schema: CONTEXT_COMPOSITION,
  data: {
    id: composition.id,
    customerId: composition.customerId,
    conversationId: composition.conversationId,
    contentType: {
      [CompositionContentType.CHAT]: 'chat',
      [CompositionContentType.CONVERSATION_NOTE]: 'note',
      [CompositionContentType.EMAIL]: 'email',
      [CompositionContentType.FB_MESSAGE]: 'fbMessage',
      [CompositionContentType.SMS]: 'sms',
      [CompositionContentType.TASK]: 'task',
      [CompositionContentType.TASK_EDIT]: 'task',
      [CompositionContentType.TWITTER]: 'tweet',
      [CompositionContentType.COBROWSE_WIDGET]: 'co-browsing',
      [CompositionContentType.CONVERSATION]: 'conversation',
      [CompositionContentType.CONVERSATION_MESSAGE]: 'conversation-message',
      [CompositionContentType.CREDIT_CARD_REQUEST]: 'credit-card-request',
      [CompositionContentType.EXTERNAL_FORM]: 'external-form',
      [CompositionContentType.PHONE_CALL]: 'phone-call',
      [CompositionContentType.PHONE_CALLBACK]: 'phone-callback',
    }[composition.contentType()],
  },
});
