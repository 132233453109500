import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('ConversationNote')
  .attr('type', 'CONVERSATION_NOTE');

Factory.define('ConversationNoteWithDefaults')
  .extend('ConversationNote')
  .attr('bodyHtml', '<div>You should clone yourself while you\'re still alive.</div>')
  .attr('content', 'You should clone yourself while you\'re still alive.');

Factory.define('ConversationNoteItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationNote', content);
  })
  .attr('initiator', {
      type: 'AGENT',
      id: 'default-agent-id'
  });

Factory.define('ConversationNoteItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationNoteWithDefaults', content);
  })
  .attr('initiator', {
      type: 'AGENT',
      id: 'default-agent-id'
  });
