import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class TaskCommentService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(attrs, callback, path, { orgId, taskId }) {
    let taskComments = this._getComments(orgId, taskId);
    callback(null, { status: 200, statusText: statusText(200), response: taskComments });
  }

  add(attrs, callback, path, { orgId, taskId }) {
    callback(null, { status: 200, statusText: statusText(200), response: attrs });
  }

  _getComments(orgId, taskId) {
    let comments = this.getDatabase(orgId).taskComments || [];
    return comments.filter(c => c.itemId === taskId);
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/tasks/:taskId/comments': {
          GET: this.findAll,
          POST: this.add,
        },
      },
      this
    );
  }
}
