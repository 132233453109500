import React from 'react';
import connect from 'components/lib/connect';
import BubbleContent from '../content/bubble_content';
import classnames from 'classnames';
import ConversationItem from 'models/conversation_item';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { messageType } from 'models/message_automation_content';
import DataDisplayItem from './data_display_item';
import ChatQuickReplyRequestContent from 'components/customer/conversation_history/conversation_items_v2//chat/chat_quick_reply_request_content';

function AutomationItemContent(props) {
  switch (props.item.content.message.type) {
    case messageType.DATA_DISPLAY:
      return (
        <BubbleContent className={classnames('automationItem-content', props.className)} item={props.item}>
          <DataDisplayItem
            displayContent={props.item.content.message.content.displayContent}
            type={props.item.content.message.content.type}
          />
        </BubbleContent>
      );
    case messageType.TEXT:
    case messageType.START:
      return (
        <BubbleContent className={classnames('automationItem-content', props.className)} item={props.item}>
          <InlineAnnotatedContentContainer
            id={props.item.id}
            key={props.item.id}
            text={props.item.content.message.content.toString()}
          />
        </BubbleContent>
      );
    case messageType.MENU:
      return (
        <ChatQuickReplyRequestContent
          className={classnames('automationItem-content', props.className)}
          item={props.item}
          options={props.item.content.message.content.menuItems}
          requestText={props.item.content.message.content.prompt}
        />
      );
    default:
      return null;
  }
}

AutomationItemContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
export default connect(mapStateToProps)(AutomationItemContent);

function mapStateToProps(context, props) {
  return {
    ...props,
  };
}

export const Bold = styled.span`
  font-weight: bold;
`;
