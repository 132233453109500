import createModel, { prop } from './lib/create_model';

const CustomerAssignee = createModel({
  modelName: 'CustomerAssignee',

  properties: {
    id: prop(String).isRequired,
    agentId: prop(String),
    routingGroupId: prop(String).isRequired,
    _version: prop(Number).default(1),
  },

  update(agentId, routingGroupId) {
    this.agentId = agentId;
    this.routingGroupId = routingGroupId;
    this._version++;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CustomerAssignee;
