import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class ItemIdsService {
  constructor(getDatabase) {
    this.getDatabase = getDatabase;
  }

  findAll(payload, callback, path, { orgId, customerId }) {
    let history = this._findItemsForCustomerId(orgId, customerId);
    const itemIds =
      (history &&
        history.map(item => ({ id: item.id, conversationId: item.conversationId, timestamp: item.timestamp }))) ||
      [];
    callback(null, { status: 200, statusText: statusText(200), response: itemIds });
  }

  _findItemsForCustomerId(orgId, customerId) {
    let customer = _.find(this._getCustomers(orgId), { id: customerId });
    return customer && _.map(customer.conversationHistory, item => _.assign({}, item));
  }

  _getCustomers(orgId) {
    return this.getDatabase(orgId).customers;
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customer-history/:customerId/item-ids': {
          GET: this.findAll,
        },
      },
      this
    );
  }
}
