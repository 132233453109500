import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import classnames from 'classnames';
import ConversationCardContainer from 'components/lib/customer_conversation_card/conversation_card_container';
import CustomerCard from 'components/lib/customer_conversation_card/customer_card';
import CustomerProfileDef from 'models/customer_profile_def';
import Link from 'components/common/link';

function CustomerConversationCard(props) {
  const { className, conversation, customerProfileDef, isDisabled, isLink, isSelected, onClick, profile } = props;

  const classNames = classnames(
    'customerConversationCard',
    className,
    { 'customerConversationCard-disabled': isDisabled },
    { 'customerConversationCard-selected': isSelected }
  );

  let cardContent = (
    <div className={classNames} onClick={onClick}>
      <CustomerCard customerProfileDef={customerProfileDef} {...profile} />
      <ConversationCardContainer
        {..._.omit(props, 'customerProfileDef', 'isDisabled', 'isLink', 'onClick', 'profile')}
      />
    </div>
  );

  if (isLink) {
    let linkUrl = `/customer/${profile.id}`;
    if (conversation) {
      linkUrl = `${linkUrl}/conversation/${conversation.id}`;
    }

    return (
      <div>
        <Link className="unstyled" href={linkUrl} onClick={noop => {}}>
          {cardContent}
        </Link>
      </div>
    );
  }

  return cardContent;
}

CustomerConversationCard.propTypes = Object.assign(
  {
    className: PropTypes.string,
    customerProfileDef: PropTypes.instanceOf(CustomerProfileDef),
    hasDraft: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLink: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    profile: PropTypes.shape(CustomerCard.propTypes),
    taskCount: PropTypes.number,
  },
  ConversationCardContainer.propTypes
);
CustomerConversationCard.defaultProps = {
  isDisabled: false,
  isLink: false,
};

export default CustomerConversationCard;
