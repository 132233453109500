import _ from 'lodash';

import Attachment from 'models/attachment';
import createModel from 'models/lib/create_model';
import EmailCompositionContent from 'models/composition/email_composition_content';
import EmailRecipientDeliveryStatus from 'models/email_recipient_delivery_status';
import EmailStatus from 'models/conversation_item/email_status';
import { emailHtmlToText } from 'models/conversation_item/email_html_to_text';
import equalIgnoreWhitespace from 'scripts/lib/equal_ignore_whitespace';
import Err from 'models/err';
import getNormalizedEmailAddress from 'scripts/lib/get_normalized_email_address';
import redactAttachment from 'models/attachment_redact';
import Upload from 'models/upload';

const ZERO_WIDTH_SPACES_REGEX = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g;

export const baseEmailMessageModel = (modelName, customProperties) =>
  createModel({
    modelName,

    properties: {
      ...(customProperties ? customProperties : {}),
      attachments: [Attachment],
      bodyHtml: String, // HTML version of email body (typically includes quoted AKA thread history)
      bodyHtmlStripped: String, // HTML version of actual message AKA "unquoted" (EXCLUDES quoted)
      bodyPlain: String, // text version of email body (typically includes quoted)
      bodyPlainStripped: String, // actual message AKA "unquoted" (EXCLUDES quoted)
      cc: [String],
      bcc: [String],
      from: String,
      fromName: String,
      subject: String,
      to: [String],
      headers: Object,
      hasRedactedPaymentCardNumber: Boolean,
      recipientStatuses: Object,
      sendStatus: String,
      isRedacted: Boolean,
      version: Number,
    },

    getRecipientStatuses() {
      // filter out any recipients that are just zero width spaces
      const filteredRecipient = {};
      _.forEach(this.recipientStatuses, function(recipientStatus, email) {
        if (!ZERO_WIDTH_SPACES_REGEX.test(email)) {
          filteredRecipient[email] = recipientStatus;
        }
      });

      return this.recipientStatuses || {};
    },

    getHeader(key) {
      return this.headers && this.headers[key];
    },

    getMessageText() {
      return this.getNonQuotedPlainText() || this.getNonQuotedPlainTextFromHtml() || this.subject;
    },

    hasQuotedContent() {
      if (this.bodyPlainStripped) {
        return !equalIgnoreWhitespace(this.bodyPlain, this.bodyPlainStripped);
      }

      return false;
    },

    hasQuotedContentFromHtml() {
      if (this.bodyHtmlStripped) {
        return !equalIgnoreWhitespace(this.bodyHtml, this.bodyHtmlStripped);
      }

      return false;
    },

    /*
     * It's possible bodyPlainStripped contains additional content after the quoted content. Only use the matching content
     * when determining the start of the quoted content
     */
    getQuotedPlainText() {
      if (this.hasQuotedContent()) {
        let nonQuotedLen = this.bodyPlainStripped ? this._getMatchingBodyPlainCount() : 0;
        return this.bodyPlain
          .substring(nonQuotedLen)
          .replace(/^\n+/, '')
          .trim();
      }
      return '';
    },

    _isWhiteSpace(char) {
      let isWhiteSpace = /\s/;
      return isWhiteSpace.test(char);
    },

    _getMatchingCountWithoutWhiteSpace() {
      let plainWithoutWhiteSpace = this.bodyPlain.replace(/\s/g, '');
      let strippedWithoutWhiteSpace = this.bodyPlainStripped.replace(/\s/g, '');

      for (let i = 0; i < plainWithoutWhiteSpace.length; i++) {
        if (plainWithoutWhiteSpace[i] !== strippedWithoutWhiteSpace[i]) {
          return i;
        }
      }
    },

    _getMatchingBodyPlainCount() {
      let count = 0;
      let matchedCountWithoutWhiteSpace = this._getMatchingCountWithoutWhiteSpace();

      while (matchedCountWithoutWhiteSpace > 0) {
        if (!this._isWhiteSpace(this.bodyPlain[count])) {
          matchedCountWithoutWhiteSpace -= 1;
        }
        count += 1;
      }

      return count;
    },

    getPlainTextFromHtml() {
      return this.bodyHtml && emailHtmlToText(this.bodyHtml);
    },

    getStrippedPlainTextFromHtml() {
      if (this.bodyHtmlStripped) {
        return emailHtmlToText(this.bodyHtmlStripped);
      }

      return this.getPlainTextFromHtml();
    },

    getQuotedPlainTextFromHtml() {
      if (this.hasQuotedContentFromHtml()) {
        let strippedPlainTextFromHtml = this.getStrippedPlainTextFromHtml();
        let nonQuotedLen = strippedPlainTextFromHtml ? strippedPlainTextFromHtml.length : 0;
        return this.getPlainTextFromHtml()
          .substring(nonQuotedLen)
          .replace(/^\n+/, '');
      }
      return '';
    },

    getNonQuotedPlainText() {
      return this.bodyPlainStripped || this.bodyPlain;
    },

    getNonQuotedPlainTextFromHtml() {
      return this.getStrippedPlainTextFromHtml();
    },

    // filter simply based on content id presence and existence, more thorough filtering will happen during send
    inlineAttachments() {
      return (this.attachments || []).filter(a => a.contentId && !a.isRedacted);
    },

    regularAttachments() {
      return (this.attachments || []).filter(a => !a.contentId);
    },

    redactAttachment(id) {
      redactAttachment(this.attachments, id);
    },

    isSentToAny(normalizedEmails) {
      const inToField =
        this.to &&
        this.to.some(toEmail => normalizedEmails.some(email => email === getNormalizedEmailAddress(toEmail, true)));
      const inCcField =
        this.cc &&
        this.cc.some(ccEmail => normalizedEmails.some(email => email === getNormalizedEmailAddress(ccEmail, true)));
      const inBccField =
        this.bcc &&
        this.bcc.some(bccEmail => normalizedEmails.some(email => email === getNormalizedEmailAddress(bccEmail, true)));
      return inToField || inCcField || inBccField;
    },

    isForward() {
      const FWD_PREFIX = 'Fwd: ';
      let subject = this.subject;
      let regex = new RegExp(`^${FWD_PREFIX}`, 'i');
      return !!subject.match(regex);
    },

    getStatus() {
      let errorOccurred = this.communicationError();
      let emailDelivered = this.communicationDelivered();
      let emailSentFinal = this.communicationSentFinal();
      if (errorOccurred) {
        return EmailStatus.DELIVERY_ERROR;
      }

      if (emailDelivered) {
        return EmailStatus.DELIVERED;
      }

      if (emailSentFinal) {
        return EmailStatus.SENT;
      }

      return EmailStatus.SENDING;
    },

    hasErrorStatus() {
      return this.getStatus() === EmailStatus.DELIVERY_ERROR;
    },

    communicationError() {
      if (this.sendStatus === undefined) {
        return false;
      }

      if (this.sendStatus === EmailMessage.SendStatus.ERROR) {
        return true;
      }

      let errors = _.filter(this.getRecipientStatuses(), function(recipientStatus) {
        return recipientStatus.status === EmailRecipientDeliveryStatus.Status.ERROR;
      });

      return !_.isEmpty(errors);
    },

    communicationDelivered() {
      if (this.sendStatus !== EmailMessage.SendStatus.SENT) {
        return false;
      }

      if (!this.getRecipientStatuses() || this.getRecipientStatuses().length === 0) {
        return false;
      }

      let undelivered = _.filter(this.getRecipientStatuses(), function(recipientStatus) {
        return recipientStatus.status !== EmailRecipientDeliveryStatus.Status.DELIVERED;
      });

      return _.isEmpty(undelivered);
    },

    communicationSentFinal() {
      if (this.sendStatus !== EmailMessage.SendStatus.SENT) {
        return false;
      }

      if (!this.getRecipientStatuses() || this.getRecipientStatuses().length === 0) {
        return false;
      }

      let notSentFinal = _.filter(this.getRecipientStatuses(), function(recipientStatus) {
        return recipientStatus.status !== EmailRecipientDeliveryStatus.Status.SENT_FINAL;
      });

      return _.isEmpty(notSentFinal);
    },

    statics: {
      create(attrs) {
        return new this(_.merge({ attachments: [] }, attrs));
      },

      SendStatus: Object.freeze({
        INITIAL: '',
        SENT: 'SENT',
        ERROR: 'ERROR',
      }),
    },
  });

const EmailMessage = baseEmailMessageModel('EmailMessage');

const MB = 1 << (10 * 2);
EmailMessage.MAX_BODY_LENGTH = 3000000;
EmailMessage.MAX_EMAIL_SIZE_MB = 25;

function createAttachmentsValidator(action) {
  return attachments => {
    let errors = [];

    if (_(attachments).find(u => u instanceof Upload && u.status === Upload.Status.FAILED)) {
      errors.push(
        new Err({
          attr: 'attachments',
          code: Err.Code.INVALID,
          detail: `Cannot ${action} with failed attachments`,
        })
      );
    }

    if (
      _(attachments).find(
        u => (u instanceof Upload && u.status === Upload.Status.NEW) || u.status === Upload.Status.STARTED
      )
    ) {
      errors.push(
        new Err({
          attr: 'attachments',
          code: Err.Code.INVALID,
          detail: `Cannot ${action} while attachments are being uploaded`,
        })
      );
    }

    attachments.forEach(attachment => {
      if (attachment.fileDescriptor().contentLength > EmailCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB * MB) {
        errors.push(
          new Err({
            attr: 'attachments',
            code: Err.Code.TOO_LONG,
            detail: `Attachment ${attachment.fileDescriptor().filename} exceeds ${
              EmailCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB
            }MB`,
          })
        );
      }
    });

    return errors;
  };
}

function createTotalSizeValidator(type) {
  return (content, attachments) => {
    let contentSize = content.length;
    let totalLength = attachments.reduce(
      (sum, attachment) => sum + attachment.fileDescriptor().contentLength,
      contentSize
    );
    if (totalLength > EmailMessage.MAX_EMAIL_SIZE_MB * MB) {
      return [
        new Err({
          code: Err.Code.TOO_LONG,
          detail: `${type} size exceeds ${EmailMessage.MAX_EMAIL_SIZE_MB}MB`,
        }),
      ];
    }
    return [];
  };
}

export { createAttachmentsValidator, createTotalSizeValidator, MB };

export default EmailMessage;
