import React from 'react';
import styled from 'styled-components';

import AnimatedNumber from 'react-animated-number';

export default function FloorboardNumber({ number, label, animate = true } = {}) {
  return (
    <FloorboardNumberCard>
      <FloorboardNumberWrapper>
        {animate ? (
          <AnimatedNumber duration={600} stepPrecision={0} value={number} />
        ) : (
          <React.Fragment>{number}</React.Fragment>
        )}
      </FloorboardNumberWrapper>
      <FloorboardNumberLabel>{label}</FloorboardNumberLabel>
    </FloorboardNumberCard>
  );
}

const FloorboardNumberCard = styled.div`
  grid-column-end: span 1;
  grid-row-end: span 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${p => p.theme.colors.gray600};
  align-items: flex-end;
  justify-content: center;
`;

const FloorboardNumberWrapper = styled.div`
  font-size: 7vh;
  line-height: 1;
  font-weight: bold;
  color: ${p => p.theme.colors.white};
`;

const FloorboardNumberLabel = styled.div`
  font-size: 3.6vh;
  color: ${p => p.theme.colors.white};
`;
