import createModel from './lib/create_model';

export default createModel({
  modelName: 'MobileContext',

  properties: {
    events: [],
    username: String,
    video: {
      type: String,
      url: String,
    },
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
