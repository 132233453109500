import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import theme from '../../themes/default';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import SpinnerTwo from 'components/common/spinner_two';

// Documentation: http://go.glad.ly/style/#/Common%20Components/Button

export const ButtonTypes = createEnum('PRIMARY', 'SECONDARY', 'OUTLINE', 'TEXT', 'DANGER');

export const CloseButtonTypes = createEnum('X_ICON', 'BACK_BUTTON', 'NONE');

const getLoaderColor = (currentType, disabled) => {
  const { gray300, white, gray500 } = theme.colors;
  const colorSchema = [
    { types: [ButtonTypes.DANGER], color: white, disabledColor: gray300 },
    { types: [ButtonTypes.PRIMARY], color: white },
    { types: [ButtonTypes.SECONDARY], color: white, disabledColor: gray300 },
    { types: [ButtonTypes.TEXT], color: gray500, disabledColor: gray300 },
  ];

  const buttonStyle = colorSchema.find(({ types }) => types.includes(currentType)) || {
    color: white,
    disabledColor: white,
  };
  return disabled ? buttonStyle.disabledColor : buttonStyle.color;
};

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.button = React.createRef();
    this.handleClick = this.handleClick.bind(this);

    this.debouncedOnClick = _.debounce(this.debouncedOnClick, props.debounceInterval, {
      leading: true,
      trailing: false,
    });
  }

  componentWillUnmount() {
    this.debouncedOnClick.cancel();
  }

  focus() {
    this.button.current.focus();
  }

  blur() {
    this.button.current.blur();
  }

  getBoundingClientRect() {
    return this.button.current.getBoundingClientRect();
  }

  handleClick(evt) {
    if (this.props.disabled) {
      return;
    }
    evt && evt.persist();
    this.debouncedOnClick(evt);

    if (this.props.blurOnClick) {
      this.blur();
    }
  }

  debouncedOnClick(evt) {
    if (this.props.onClick) {
      this.props.onClick(evt);
    }
  }

  render() {
    const type = this.props.buttonType || this.props.type;
    const { isLoading } = this.props;
    const loaderColor = getLoaderColor(type, this.props.disabled);
    let RenderButton;
    switch (type) {
      case ButtonTypes.PRIMARY:
        RenderButton = PrimaryButton;
        break;
      case ButtonTypes.SECONDARY:
        RenderButton = SecondaryButton;
        break;
      case ButtonTypes.OUTLINE:
        RenderButton = OutlineButton;
        break;
      case ButtonTypes.TEXT:
        RenderButton = TextButton;
        break;
      case ButtonTypes.DANGER:
        RenderButton = DangerButton;
        break;
      default:
        RenderButton = PrimaryButton;
        break;
    }
    return (
      <RenderButton {...this.props} onClick={this.handleClick} ref={this.button}>
        {isLoading ? (
          <LoadingIcon>
            <SpinnerTwo color={loaderColor} size={14} stroke={1} />
          </LoadingIcon>
        ) : null}
        {this.props.children}
      </RenderButton>
    );
  }
}

Button.propTypes = {
  /** If true will call the button blur method when clicked */
  blurOnClick: PropTypes.bool,
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  /** Time to "pause" between firing `onClick` handler. This keeps the user from clicking rapidly and firing the `onClick` over and over */
  debounceInterval: PropTypes.number,
  disabled: PropTypes.bool,
  /** Function called when button is clicked */
  onClick: PropTypes.func,
  /** One of: `PRIMARY`, `SECONDARY`, `OUTLINE`, `TEXT`, or `DANGER` */
  buttonType: PropTypes.oneOf(Object.keys(ButtonTypes)),
  type: PropTypes.oneOf(Object.keys(ButtonTypes)),
  /** Determines which side the inline spacing will be applied */
  direction: PropTypes.oneOf(['left', 'right']),
  /** Render small or regular size */
  isSmall: PropTypes.bool,
  /** Render spinner*/
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  debounceInterval: 200,
  isLoading: false,
};

Button.Types = ButtonTypes;

const LoadingIcon = styled.span`
  display: inline-block;
  left: -4px;
  position: relative;
`;

const buttonBase = css`
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  font-size: ${p => (p.isSmall ? '12px' : p.theme.fontSize.base)};
  line-height: ${p => p.theme.lineHeight.controls};
  padding: ${p => (p.isSmall ? '4px 12px' : '8px 16px')};
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  white-space: nowrap;
  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  ${p =>
    p.isLoading &&
    ` {
      pointer-events: none;
  `}
`;

const primaryContrast = css`
  background: transparent;
  border: 1px solid ${p => p.theme.colors.white};
  &:hover {
    background: ${p => rgba(p.theme.colors.white, 0.4)};
  }
  &:active {
    background: ${p => rgba(p.theme.colors.white, 0.6)};
  }
  &[disabled] {
    background: ${p => rgba(p.theme.colors.gray100, 0.3)};
  }
`;

const PrimaryButtonBase = css`
  ${buttonBase};
  background: ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.white};
  &:hover {
    background: ${p => p.theme.colors.green300};
  }
  &:active {
    background: ${p => p.theme.colors.green600};
  }
  &[disabled] {
    background: ${p => p.theme.colors.gray300};
  }
  ${p => p.contrast && primaryContrast};

  ${({ isLoading, theme, contrast }) =>
    isLoading &&
    ` {
      background: ${contrast ? rgba(theme.colors.white, 0.6) : theme.colors.green600};
  `}
`;

const SecondaryButtonBase = css`
  ${buttonBase};
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray300};
  font-weight: ${p => p.theme.fontWeight.medium};
  color: ${p => p.theme.colors.black};
  &:hover {
    background: ${p => p.theme.colors.gray100};
  }
  &:active {
    background: ${p => p.theme.colors.gray400};
    color: ${p => p.theme.colors.white};
  }
  &[disabled] {
    background: ${p => p.theme.colors.white};
    border: 1px solid ${p => p.theme.colors.gray300};
    color: ${p => p.theme.colors.gray300};
  }

  ${({ isLoading, theme }) =>
    isLoading &&
    ` {
      color: ${theme.colors.white};
      background: ${theme.colors.gray400};
  `}
`;

const OutlineButtonBase = css`
  ${buttonBase};
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.green400};
  font-weight: ${p => p.theme.fontWeight.medium};
  &:hover {
    background: ${p => p.theme.colors.gray100};
  }
  &:active {
    background: ${p => p.theme.colors.green400};
    color: ${p => p.theme.colors.white};
  }
  &[disabled] {
    background: ${p => p.theme.colors.white};
    border: 1px solid ${p => p.theme.colors.gray300};
    color: ${p => p.theme.colors.gray300};
  }
`;

const textContrast = css`
  color: ${p => p.theme.colors.white};
  &:hover {
    color: ${p => p.theme.colors.white};
  }
  &:active {
    color: ${p => p.theme.colors.white};
  }
  &[disabled] {
    color: ${p => p.theme.colors.gray300};
  }
`;

const TextButtonBase = css`
  ${buttonBase};
  border: none;
  background: transparent;
  color: ${p => p.theme.colors.gray600};
  &:hover {
    background: transparent;
    color: ${p => p.theme.colors.gray500};
  }
  &:active {
    background: transparent;
    color: ${p => p.theme.colors.gray700};
  }
  &[disabled] {
    background: transparent;
    color: ${p => p.theme.colors.gray300};
  }
  ${p => p.contrast && textContrast};
`;

const DangerButtonBase = css`
  ${buttonBase};
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray300};
  color: ${p => p.theme.colors.red400};
  &:hover {
    background: ${p => p.theme.colors.red300};
    color: ${p => p.theme.colors.white};
  }
  &:active {
    background: ${p => p.theme.colors.red500};
    color: ${p => p.theme.colors.white};
  }
  &[disabled] {
    background: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.gray300};
  }

  ${({ isLoading, theme }) =>
    isLoading &&
    ` {
      color: ${theme.colors.white};
      background: ${theme.colors.red500};
  `}
`;

export const PrimaryButton = styled.button`
  ${buttonBase};
  ${PrimaryButtonBase};
`;

export const SecondaryButton = styled.button`
  ${buttonBase};
  ${SecondaryButtonBase};
`;

export const OutlineButton = styled.button`
  ${buttonBase};
  ${OutlineButtonBase};
`;

export const TextButton = styled.button`
  ${buttonBase};
  ${TextButtonBase};
`;

export const DangerButton = styled.button`
  ${buttonBase};
  ${DangerButtonBase};
`;

export { PrimaryButtonBase, SecondaryButtonBase, OutlineButtonBase, TextButtonBase, DangerButtonBase };

export default Button;
