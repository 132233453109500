import { EditorState, Modifier, SelectionState } from 'draft-js';

export function findEntities(entityType) {
  return (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
      let entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === entityType;
    }, callback);
  };
}

/**
 * Given an editorState, returns whether an entity of the given type is currently selected. Uses the end of the selection.
 *   if includeStart:
 *     |ENTITY => true
 *   if includeEnd:
 *     ENTITY| => true
 */
export function isEntitySelected(
  editorState,
  entityType,
  { includeStart, includeEnd } = { includeStart: false, includeEnd: true }
) {
  let selection = editorState.getSelection();
  let block = editorState.getCurrentContent().getBlockForKey(selection.getEndKey());
  let endOffset = selection.getEndOffset();

  let contentState = editorState.getCurrentContent();
  let isLeftKeyOfType = endOffset && isEntityKeyOfType(contentState, block.getEntityAt(endOffset - 1), entityType);
  let isMidKeyOfType = isEntityKeyOfType(contentState, block.getEntityAt(endOffset), entityType);

  return (includeStart && isMidKeyOfType) || (includeEnd && isLeftKeyOfType) || (isMidKeyOfType && isLeftKeyOfType);
}

function isEntityKeyOfType(contentState, entityKey, entityType) {
  if (!entityKey) {
    return false;
  }
  let entity = contentState.getEntity(entityKey);
  return entity && entity.getType() === entityType;
}

export function isOffsetInsideEntity(contentState, entityType, offset, block) {
  // to account for beginning and end selections
  let entityKey = block && (block.getEntityAt(offset - 1) || block.getEntityAt(offset));
  return entityKey != null && contentState.getEntity(entityKey).getType() === entityType;
}

/**
 * Returns the { start, end } offsets of the entity based around the end of the selection of the given entity type.
 * Does not return the range if the end of the selection is at the front of the entity.
 *
 * E.g.   0 1 2 3 4   5 6 7
 *        _ _ L I N | K _ _
 *   => { start: 2, end: 6 }
 */
export function getEntityRangeAtSelection(editorState, entityType, selection) {
  let entityStart = null;
  let entityEnd = null;

  let block = editorState.getCurrentContent().getBlockForKey(selection.getEndKey());
  let cursorEnd = selection.getEndOffset();
  let entityKey = block.getEntityAt(cursorEnd - 1);

  if (entityKey) {
    // Starting at the end offset, expand the range outwards until the entity key changes.
    let workingKey = null;
    let i = cursorEnd - 1;
    do {
      i--;
      workingKey = block.getEntityAt(i);
    } while (i >= 0 && workingKey === entityKey);
    entityStart = i + 1;

    workingKey = null;
    i = cursorEnd - 1;
    do {
      i++;
      workingKey = block.getEntityAt(i);
    } while (i <= block.getLength() && workingKey === entityKey);
    entityEnd = i;

    return { start: entityStart, end: entityEnd };
  }

  return null;
}

/**
 * Removes the entity of the given type from the editorState from the end of the current selection.
 */
export function removeEntity(editorState, entityType) {
  let range = getEntityRangeAtSelection(editorState, entityType, editorState.getSelection());
  if (!range) {
    return editorState;
  }

  let { start, end } = range;
  let selection = editorState.getSelection();

  let entitySelection = new SelectionState({
    anchorKey: selection.getEndKey(),
    focusKey: selection.getEndKey(),
    anchorOffset: start,
    focusOffset: end,
    isBackward: false,
  });

  let newContentState = Modifier.applyEntity(editorState.getCurrentContent(), entitySelection, null);

  let removedEntity = EditorState.push(editorState, newContentState, 'apply-entity');

  return EditorState.forceSelection(removedEntity, selection);
}
