import _ from 'lodash';

import InboxCount, { UnreadConversation } from 'models/inbox_count';
import { InboxConversationStatus } from 'models/location/inbox';

export default {
  fromDto(dto) {
    let conversationIds = [];
    let isTeam = dto.hasOwnProperty('groupId');
    let id = isTeam ? dto.groupId : dto.agentId;
    let newCount = 0;
    let openCount = 0;
    let unreadConversationIds;
    let oldestUnreadConversations;
    let openUnreadCount;
    let waitingCount = 0;
    if (dto.counts) {
      if (Array.isArray(dto.counts)) {
        dto.counts.forEach(countDto => {
          if (!_.isEmpty(countDto.conversationIds)) {
            conversationIds = conversationIds.concat(countDto.conversationIds);
          }

          if (countDto.status === InboxConversationStatus.NEW) {
            newCount = countDto.count;
          } else if (countDto.status === InboxConversationStatus.OPEN) {
            openCount = countDto.count;
            unreadConversationIds = countDto.unreadConversationIds;
            if (countDto.unreadConversations) {
              oldestUnreadConversations =
                countDto.unreadConversations &&
                countDto.unreadConversations
                  .sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt))
                  .map(conversation => new UnreadConversation(conversation));
            } else if (countDto.oldestUnreadConversations) {
              oldestUnreadConversations =
                countDto.oldestUnreadConversations &&
                countDto.oldestUnreadConversations
                  .sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt))
                  .map(conversation => new UnreadConversation(conversation));
            }
            if (countDto.unreadCount != null) {
              openUnreadCount = countDto.unreadCount;
            }
          } else if (countDto.status === InboxConversationStatus.WAITING) {
            waitingCount = countDto.count;
          }
        });
      }
    }

    let countAttr = {
      conversationIds,
      id,
      isTeam,
      newCount,
      openCount,
      waitingCount,
      oldestUnreadConversations,
      unreadConversationIds,
    };

    if (openUnreadCount != null) {
      countAttr.openUnreadCount = openUnreadCount;
    }

    return new InboxCount(countAttr);
  },
};
