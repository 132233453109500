import AgentStatusReason from 'models/agent_status_reason';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';

export default class AgentStatusReasonGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  get store() {
    return this.context.stores.agentStatusReasons;
  }

  onFetchAllSuccess(agentStatusReasonsDtos) {
    this.store.set(agentStatusReasonsDtos.map(r => AgentStatusReason.fromJs(r)));
  }

  onFetchAllError(err) {
    this.errorHandler.handleCommonErrors(err);
  }

  onBroadcast(agentStatusReasonsDto) {
    this.store.addOrReplace(AgentStatusReason.fromJs(agentStatusReasonsDto));
  }
}
