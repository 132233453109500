import _ from 'lodash';

import changeCurrentLocation from 'actions/lib/change_current_location';
import Datasets from 'models/location/datasets';
import { getDatasetNames } from 'models/reports/embedded_dataset';
import fetchReportConfigs from 'actions/reporting/fetch_report_configs';
import requestSharedReportConfigs from './request_shared_report_configs';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import Reports from 'models/location/reports';

export default class OpenDataset {
  constructor(context) {
    this.context = context;
  }

  run(params) {
    const isReportBuilderConfigLoading = this.context.stores.reportBuilderConfig.isLoading();
    const reportBuilderConfig = this.context.stores.reportBuilderConfig.get();
    const reportBuilderEnabled = _.get(reportBuilderConfig, 'enabled');

    // reportBuilder must be enabled in the config
    // AND the user must have the `reportBuilder` role feature to access a dataset
    if (
      (!reportBuilderEnabled && !isReportBuilderConfigLoading) ||
      !this.context.stores.appFeatures.get().isEnabled('reportBuilder')
    ) {
      this.context.router.navigateHome();
      return;
    }

    const orgIsDemoMode = this.context.stores.appFeatures.get().isEnabled('demoMode');

    const { slug, timezone } = params;

    if (!_.includes(getDatasetNames(orgIsDemoMode), slug)) {
      this.context.router.navigateTo(Reports.create());
      return;
    }

    const reportConfigsStore = this.context.stores.reportConfigs;
    if (!reportConfigsStore.isLoading() && reportConfigsStore.get().isEmpty()) {
      fetchReportConfigs(this.context).catch(err => this.handleErrors(err));
    }

    const sharedReportConfigsStore = this.context.stores.sharedReportConfigs;
    if (!sharedReportConfigsStore.isLoading() && sharedReportConfigsStore.get().isEmpty()) {
      requestSharedReportConfigs(this.context).catch(err => this.handleErrors(err));
    }

    changeCurrentLocation(this.context, Datasets.create(params));

    const isCookielessLookerEmbedEnabled = this.context.stores.appFeatures.get().isEnabled('cookielessLookerEmbed');
    if (isCookielessLookerEmbedEnabled) {
      this.context.stores.embedTokensReport.setLoading();
      this.context.gateways.embedTokensReport.add(
        { type: 'dataset' },
        {
          dataset: slug,
          timezone,
        }
      );
    } else {
      this.context.stores.embeddedReport.setLoading();
      this.context.gateways.embeddedDataset.add({
        dataset: slug,
        timezone,
      });
    }
  }

  handleErrors(err) {
    let interactiveHandler = new GatewayErrorInteractiveHandler(this.context);
    interactiveHandler.handleCommonErrors(this.context, err);
  }
}
