import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Internet Stroke
 */

export default function InternetStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M1.37472 7.33104C2.31076 6.27151 4.11141 4.98205 6.42982 4.60715C8.72377 4.23621 11.5787 4.75133 14.6764 7.38117C14.8869 7.55988 15.2025 7.5341 15.3812 7.32359C15.5599 7.11308 15.5341 6.79755 15.3236 6.61883C12.0213 3.81538 8.87625 3.19856 6.27019 3.61998C3.6886 4.03743 1.68925 5.46462 0.62529 6.66896C0.442461 6.87591 0.462015 7.19189 0.668966 7.37472C0.875916 7.55754 1.19189 7.53799 1.37472 7.33104ZM3.36313 9.34372C4.0101 8.66021 5.26907 7.81566 6.89605 7.57011C8.50211 7.32772 10.5068 7.66243 12.6895 9.39189C12.9059 9.56339 13.2204 9.52695 13.3919 9.31052C13.5634 9.09408 13.527 8.7796 13.3105 8.60811C10.9218 6.71537 8.64075 6.29547 6.74681 6.58131C4.87379 6.864 3.41848 7.83055 2.63688 8.65629C2.44705 8.85683 2.45574 9.1733 2.65629 9.36313C2.85683 9.55295 3.1733 9.54426 3.36313 9.34372ZM10.7245 11.4172C8.20768 9.7554 6.05471 10.7376 5.3304 11.3753C5.12314 11.5578 4.8072 11.5377 4.62472 11.3304C4.44225 11.1231 4.46235 10.8072 4.66961 10.6247C5.65959 9.75314 8.30662 8.6224 11.2755 10.5828C11.506 10.7349 11.5694 11.0451 11.4173 11.2755C11.2651 11.5059 10.9549 11.5694 10.7245 11.4172ZM8 14C8.55229 14 9 13.5523 9 13C9 12.4477 8.55229 12 8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
