import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import BrokenOrderPreview from 'components/common/icons/broken_order_preview';

const FULFILLMENTS_TO_SHOW = 3;
const MAX_FULFILLMENT_LINK_LENGTH = 16;
const MAX_ORDER_ID_LENGTH = 60;
const MAX_FULFILLMENT_STATUS_LENGTH = 30;

export default function OrderStatusCard(props) {
  const [isInvalidImg, setisInvalidImg] = useState(false);

  const handleError = () => setisInvalidImg(true);

  function OrderImgPreview() {
    if (isInvalidImg) {
      return <EmptyImgPreview>{BrokenOrderPreview()}</EmptyImgPreview>;
    }

    const imgPreview = getFirstImgPreview();
    if (imgPreview) {
      return <ImagePreview alt="Image not found" onError={handleError} src={imgPreview} />;
    }
    return null;
  }

  function getFirstImgPreview() {
    if (!props.products || !props.products.length) {
      return '';
    }

    return get(props.products[0], 'imageUrl', '');
  }

  function OrderCost() {
    if (!props.totalAmount) {
      return null;
    }

    return getFormattedCurrencyAmount(props.currencyCode, props.totalAmount);
  }

  function EstimatedDeliveryDate(deliveryDate) {
    if (!deliveryDate) {
      return null;
    }
    return (
      <EstimatedDeliveryDateWrapper>{`Expected to arrive ${moment(deliveryDate).format(
        'MMM DD'
      )}`}</EstimatedDeliveryDateWrapper>
    );
  }

  function isSingleFulfillment() {
    return (props.fulfillments || []).length === 1;
  }

  function MissingFulfillmentMessage() {
    if (!get(props.config, 'missingFulfillmentMessage', '')) {
      return null;
    }
    return <FulfillmentMessageWrapper>{props.config.missingFulfillmentMessage}</FulfillmentMessageWrapper>;
  }

  function MissingStatusAndEtaMessage() {
    if (!get(props.config, 'missingStatusAndEtaMessage', '')) {
      return null;
    }
    return <FulfillmentMessageWrapper>{props.config.missingStatusAndEtaMessage}</FulfillmentMessageWrapper>;
  }

  function MissingEtaMessage() {
    if (!get(props.config, 'missingEtaMessage', '')) {
      return null;
    }
    return <FulfillmentMessageWrapper>{props.config.missingEtaMessage}</FulfillmentMessageWrapper>;
  }

  function FulfillmentMessage(fulfillment) {
    if (!fulfillment.trackingUrl && !fulfillment.status && !fulfillment.estimatedDeliveryDate) {
      return MissingFulfillmentMessage();
    } else if (fulfillment.trackingUrl && !fulfillment.status && !fulfillment.estimatedDeliveryDate) {
      return MissingStatusAndEtaMessage();
    } else if (fulfillment.trackingUrl && fulfillment.status && !fulfillment.estimatedDeliveryDate) {
      return MissingEtaMessage();
    }
    return null;
  }

  function FulfillmentTrackingLink(fulfillment) {
    if (!fulfillment.trackingUrl) {
      return null;
    }
    const linkTextRaw = fulfillment.trackingNumber ? fulfillment.trackingNumber : fulfillment.trackingUrl;
    const linkText = linkTextRaw.slice(0, MAX_FULFILLMENT_LINK_LENGTH);
    const isLinkTruncated = linkText.length !== linkTextRaw.length;
    return (
      <FulfillmentTrackingContentWrapper>
        {'Tracking '}
        <FulfillmentTrackingLinkWrapper
          href={fulfillment.trackingUrl}
          target="_blank"
          title={isLinkTruncated ? linkTextRaw : ''}
        >
          {isLinkTruncated ? `${linkText}…` : linkText}
        </FulfillmentTrackingLinkWrapper>
      </FulfillmentTrackingContentWrapper>
    );
  }

  function AdditionalFulfillments() {
    if (!props.fulfillments || props.fulfillments.length <= FULFILLMENTS_TO_SHOW) {
      return null;
    }
    const additionalFulfillmentsCount = props.fulfillments.length - FULFILLMENTS_TO_SHOW;
    return <AdditionalFulfillmentsWrapper>{`+${additionalFulfillmentsCount} more`}</AdditionalFulfillmentsWrapper>;
  }

  function FormatFulfillmentStatusText(fulfillmentStatus) {
    if (!fulfillmentStatus) {
      return '';
    }
    // remove underscores and sentence case the fullfillment status
    const statusWords = fulfillmentStatus.split('_');
    const formattedStatus = upperFirst(statusWords.join(' ').toLowerCase());

    return formattedStatus;
  }

  function FulfillmentStatusFormat(status) {
    if (!status) {
      return null;
    }
    status = FormatFulfillmentStatusText(status);
    const statusText = status.slice(0, MAX_FULFILLMENT_STATUS_LENGTH);
    const isTextTruncated = statusText.length !== status.length;
    return (
      <FulfillmentStatus title={isTextTruncated ? status : ''}>
        {isTextTruncated ? `${statusText}…` : statusText}
      </FulfillmentStatus>
    );
  }

  function MultipleFulfillmentsStatusFormat(status) {
    if (!status) {
      return null;
    }
    status = FormatFulfillmentStatusText(status);
    const statusText = status.slice(0, MAX_FULFILLMENT_STATUS_LENGTH);
    const isTextTruncated = statusText.length !== status.length;
    return (
      <MultipleFulfillmentsStatus title={isTextTruncated ? status : ''}>
        {isTextTruncated ? `${statusText}…` : statusText}
      </MultipleFulfillmentsStatus>
    );
  }

  function OrderFulfillment() {
    if (!props.fulfillments || !props.fulfillments.length) {
      return MissingFulfillmentMessage();
    }
    if (isSingleFulfillment()) {
      return (
        <>
          {FulfillmentStatusFormat(props.fulfillments[0].status)}
          {EstimatedDeliveryDate(props.fulfillments[0].estimatedDeliveryDate)}
          {FulfillmentTrackingLink(props.fulfillments[0])}
          {FulfillmentMessage(props.fulfillments[0])}
        </>
      );
    }
    const fulfillmentsToShow = props.fulfillments.slice(0, FULFILLMENTS_TO_SHOW);
    const hasSameStatusForAll = fulfillmentsToShow.every(
      (fulfillment, idx, arr) => fulfillment.status === arr[0].status
    );
    if (hasSameStatusForAll) {
      return (
        <>
          <FulfillmentItem>
            {MultipleFulfillmentsStatusFormat(fulfillmentsToShow[0].status)}
            {fulfillmentsToShow.map((fulfillment, idx) => {
              return (
                <React.Fragment key={idx}>
                  {EstimatedDeliveryDate(fulfillment.estimatedDeliveryDate)}
                  {FulfillmentTrackingLink(fulfillment)}
                  {FulfillmentMessage(fulfillment)}
                </React.Fragment>
              );
            })}
          </FulfillmentItem>
          {AdditionalFulfillments()}
        </>
      );
    }
    return (
      <>
        {fulfillmentsToShow.map((fulfillment, idx) => (
          <FulfillmentItem key={idx}>
            {MultipleFulfillmentsStatusFormat(fulfillment.status)}
            {EstimatedDeliveryDate(fulfillment.estimatedDeliveryDate)}
            {FulfillmentTrackingLink(fulfillment)}
            {FulfillmentMessage(fulfillment)}
          </FulfillmentItem>
        ))}
        {AdditionalFulfillments()}
      </>
    );
  }

  function OrderNumberFormat(orderID) {
    if (!orderID) {
      return null;
    }
    const orderText = `Order ${orderID}`;
    const formattedOrderText = orderText.slice(0, MAX_ORDER_ID_LENGTH);
    const isTextTruncated = orderText.length !== formattedOrderText.length;
    return (
      <OrderNumber title={isTextTruncated ? orderText : ''}>
        {isTextTruncated ? `${formattedOrderText}…` : formattedOrderText}
      </OrderNumber>
    );
  }

  function OrderDetails() {
    return (
      <OrderContentWrapper>
        {OrderNumberFormat(props.id)}
        <OrderStatusRowWrapper>
          {ItemCount()}
          <OrderCostWrapper>{OrderCost()}</OrderCostWrapper>
        </OrderStatusRowWrapper>
        {OrderFulfillment()}
      </OrderContentWrapper>
    );
  }

  function ItemCount() {
    if (!props.itemCount) {
      return null;
    }

    let itemText = props.itemCount === 1 ? 'item' : 'items';

    return (
      <ItemCountWrapper>
        {props.itemCount} {itemText}
      </ItemCountWrapper>
    );
  }

  return (
    <OuterWrapper tabIndex="0">
      <OrderStatusMessage>
        <OrderStatusContentWrapper>
          {OrderImgPreview()}
          {OrderDetails()}
        </OrderStatusContentWrapper>
      </OrderStatusMessage>
    </OuterWrapper>
  );
}

OrderStatusCard.propTypes = {
  id: PropTypes.string.isRequired,
  customerOrderUrl: PropTypes.string,
  totalAmount: PropTypes.number,
  currencyCode: PropTypes.string,
  products: PropTypes.array,
  itemCount: PropTypes.number,
  fulfillments: PropTypes.array,
  config: PropTypes.object,
};

function getCurrencySymbol(currency) {
  switch (currency) {
    case 'USD':
      return '$';
    default:
      return '$';
  }
}

function getCurrencyAmount(currency, monetaryUnit = 0) {
  switch (currency) {
    case 'USD':
      return monetaryUnit;
    default:
      return monetaryUnit;
  }
}

function getFormattedCurrencyAmount(currency, monetaryUnit) {
  switch (currency) {
    case 'USD':
      return getCurrencySymbol(currency) + getCurrencyAmount(currency, monetaryUnit);
    default:
      // default to putting currency symbol before amount
      return getCurrencySymbol(currency) + getCurrencyAmount(currency, monetaryUnit);
  }
}

const OrderStatusContentWrapper = styled.div`
  line-height: 20px;
  flex-direction: row;
  display: flex;
`;

const EmptyImgPreview = styled.div`
  flex-direction: row;
  height: 60px;
  margin-right: 20px;
  width: 60px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  box-shadow: 0.5px 0.5px 0.75px rgba(36, 36, 36, 0.08);
`;

const ImagePreview = styled.img`
  flex-direction: row;
  height: 60px;
  margin-right: 20px;
  width: 60px;
  border-radius: 4px;
  border: 0.5px solid #cecece;
  filter: drop-shadow(0.75px 0.75px 1px rgba(36, 36, 36, 0.12));
`;

const OrderContentWrapper = styled.div`
  flex-direction: row;
  line-height: 20px;
`;

const OrderNumber = styled.div`
  font-size: 13px;
  font-weight: 600;
  width: 195px;
  word-break: break-all;
`;

const OrderCostWrapper = styled.div`
  font-size: 13px;
`;

const FulfillmentStatus = styled.div`
  font-size: 13px;
`;

const MultipleFulfillmentsStatus = styled(FulfillmentStatus)`
  font-weight: 500;
`;

const EstimatedDeliveryDateWrapper = styled.div`
  font-size: 13px;
`;

const FulfillmentTrackingContentWrapper = styled.div`
  font-size: ${p => p.theme.fontSize.base};
  text-decoration: none;
`;

const FulfillmentTrackingLinkWrapper = styled.a`
  overflow: hidden;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FulfillmentMessageWrapper = styled.div`
  font-size: 13px;
  color: #767676;
`;

const ItemCountWrapper = styled.div`
  font-size: 13px;
`;

const OrderStatusRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > div:not(:first-child):before {
    content: ' • ';
    white-space: pre;
    font-weight: 500;
  }
`;

const AdditionalFulfillmentsWrapper = styled.div`
  font-size: 13px;
  margin-top: 6px;
`;

const FulfillmentItem = styled.div`
  flex-direction: column;
  margin-top: 6px;
`;

const Message = styled.div`
  border-radius: 16;
  flex: 0 0 auto;
`;

const OrderStatusMessage = styled(Message)`
  background-color: white;
  border-top-left-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-wrap;
  min-width: 290px !important;
`;

const OuterWrapper = styled.div`
  flex: 0 0 auto;
  margin: 6px 4px;
  width: 100%;
`;
