import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('CustomerAssigneeValue');

Factory.define('CustomerAssigneeValueWithDefaults')
  .extend('CustomerAssigneeValue')
  .attr('agentId', 'exampleAgentId')
  .attr('routingGroupId', 'exampleRoutingGroupId');

Factory.define('CustomerAssigneeChange').attr('type', 'CUSTOMER_ASSIGNEE_CHANGE');

Factory.define('CustomerAssigneeChangeWithDefaults')
  .extend('CustomerAssigneeChange')
  .attr(
    'newAssignee',
    Factory.attributes('CustomerAssigneeValueWithDefaults', {
      agentId: 'newAgentId',
      routingGroupId: 'newRoutingGroupId',
    })
  )
  .attr(
    'previousAssignee',
    Factory.attributes('CustomerAssigneeValueWithDefaults', {
      agentId: 'previousAgentId',
      routingGroupId: 'previousRoutingGroupId',
    })
  );

Factory.define('CustomerAssigneeChangeForAdd')
  .extend('CustomerAssigneeChange')
  .attr(
    'newAssignee',
    Factory.attributes('CustomerAssigneeValueWithDefaults', {
      agentId: 'newAgentId',
      routingGroupId: 'newRoutingGroupId',
    })
  );

Factory.define('CustomerAssigneeChangeForDelete')
  .extend('CustomerAssigneeChange')
  .attr(
    'previousAssignee',
    Factory.attributes('CustomerAssigneeValueWithDefaults', {
      agentId: 'previousAgentId',
      routingGroupId: 'previousRoutingGroupId',
    })
  );

Factory.define('CustomerAssigneeChangeItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('CustomerAssigneeChange', content));

Factory.define('CustomerAssigneeChangeItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('initiator', { type: 'AGENT', id: 'exampleAgentId' })
  .attr('content', ['content'], content => Factory.attributes('CustomerAssigneeChangeWithDefaults', content));

Factory.define('CustomerAssigneeChangeItemForAdd')
  .extend('ConversationItemWithDefaults')
  .attr('initiator', { type: 'AGENT', id: 'exampleAgentId' })
  .attr('content', ['content'], content => Factory.attributes('CustomerAssigneeChangeForAdd', content));

Factory.define('CustomerAssigneeChangeItemForDelete')
  .extend('ConversationItemWithDefaults')
  .attr('initiator', { type: 'AGENT', id: 'exampleAgentId' })
  .attr('content', ['content'], content => Factory.attributes('CustomerAssigneeChangeForDelete', content));
