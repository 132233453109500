import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Attachment from 'components/lib/attachment/attachment';
import AttachmentModal from 'components/attachments/attachment_modal';
import AttachmentPreviewOpener from 'components/lib/attachment/attachment_preview_opener';
import AttachmentsDownloadAllContainer from 'components/lib/attachment/attachments_download_all_container';
import { getAttachmentUrl } from 'scripts/domain/services/attachments';

const AttachmentsContainer = AttachmentPreviewOpener(Attachments);

function Attachments({ itemId, attachments, onOpenAttachmentPreview, orgId, pathPrefix }) {
  const [previewAttachment, setPreviewAttachment] = useState(null);
  if (_.isEmpty(attachments)) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="attachments">
        {_.map(attachments, renderAttachment)}
        <AttachmentsDownloadAllContainer attachments={attachments} itemId={itemId} pathPrefix={pathPrefix} />
      </div>
      {previewAttachment && (
        <AttachmentModal
          attachment={previewAttachment}
          attachments={attachments}
          setAttachment={setPreviewAttachment}
          url={getDownloadUrl(previewAttachment)}
        />
      )}
    </React.Fragment>
  );

  function getThumbnailUrl(attachment) {
    return `${getDownloadUrl(attachment)}?size=thumbnail`;
  }

  function getDownloadUrl(attachment) {
    return getAttachmentUrl(pathPrefix, orgId, attachment);
  }

  function renderAttachment(attachment, index) {
    return (
      <Attachment
        attachment={attachment}
        key={attachment.id}
        onClick={(!attachment.isUpload() && openAttachmentPreview) || undefined}
        thumbnailUrl={(!attachment.isUpload() && getThumbnailUrl(attachment)) || undefined}
      />
    );

    function openAttachmentPreview() {
      setPreviewAttachment(attachment);
    }
  }
}

AttachmentsContainer.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      fileDescriptor: PropTypes.func.isRequired,
      id: PropTypes.string.isRequired,
      isImage: PropTypes.func.isRequired,
      isUpload: PropTypes.func.isRequired,
    })
  ).isRequired,
  itemId: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
};

Attachments.propTypes = {
  ...AttachmentPreviewOpener.childPropTypes,
  ...AttachmentsContainer.propTypes,
};

export { Attachments };
export default AttachmentsContainer;
