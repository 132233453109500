import connect from 'components/lib/connect';
import DismissNotification from 'actions/notification/dismiss_notification';
import Notifications from './notifications';

const NotificationsContainer = connect(mapStateToProps, mapExecuteToProps)(Notifications);

function mapStateToProps(context) {
  const currentAgent = context.getProvider('currentAgent').get();
  return {
    activeCall: context.getProvider('activeCall').get(),
    currentAgent,
    notifications: context.getProvider('notifications').findAll(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onDismissNotification: notificationId => executeAction(DismissNotification, notificationId),
  };
}

export default NotificationsContainer;
