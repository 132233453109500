import styled from 'styled-components';
import Button, { ButtonTypes } from 'components/common/button';

export const ModalButtonDivider = styled.div`
  background: ${p => p.theme.colors.gray200};
  border-bottom: 10px solid ${p => p.theme.colors.green400};
  border-top: 10px solid ${p => p.theme.colors.green400};
  opacity: 0.3;
  width: 2px;
`;

const ModalButton = styled(Button)`
  border-radius: 0;
  color: ${p => p.theme.colors.white};
  flex: 1;
  font-size: ${p => p.theme.fontSize.base};
  height: 50px;
  &:first-child {
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-bottom-right-radius: 5px;
  }
`;

ModalButton.defaultProps = {
  buttonType: ButtonTypes.PRIMARY,
};

export default ModalButton;
