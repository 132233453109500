import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { createElement } from 'react';

export default function(WrappedComponent) {
  class CollapsibleItem extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isCollapsed: props.collapseByDefault || false };
      this.onToggleCollapse = this.onToggleCollapse.bind(this);
      this.setWrappedInstance = this.setWrappedInstance.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.props.isCollapsible && nextProps.isCollapsible && nextProps.collapseByDefault) {
        this.setState({ isCollapsed: true });
      }
    }

    render() {
      const wrappedInstance = (WrappedComponent.prototype.render && this.setWrappedInstance) || undefined;
      return createElement(WrappedComponent, {
        ..._.omit(this.props, ['collapseByDefault', 'isCollapsible']),
        isCollapsed: this.props.isCollapsible ? this.state.isCollapsed : undefined,
        onToggleCollapse: this.props.isCollapsible ? this.onToggleCollapse : undefined,
        ref: wrappedInstance,
      });
    }

    onToggleCollapse() {
      this.setState(
        prevState => ({ isCollapsed: !prevState.isCollapsed }),
        () => this.props.onHeightChange && this.props.onHeightChange()
      );
    }

    getWrappedInstance() {
      return this.wrappedInstance;
    }

    setWrappedInstance(node) {
      this.wrappedInstance = node;
    }
  }

  CollapsibleItem.propTypes = {
    collapseByDefault: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    onHeightChange: PropTypes.func,
  };

  CollapsibleItem.defaultProps = {
    isCollapsible: true,
  };

  return CollapsibleItem;
}
