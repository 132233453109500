import React from 'react';
import PageLayout from 'components/page_layout';
import connect from 'components/lib/connect';
import PropTypes from 'prop-types';
import Link from 'components/common/link';
import { LoadingStates } from 'models/location/custom_reporting';

const rootPath = '/customreporting/';

export function CustomReportingPage({ path, dirs, files, loadingState }) {
  let isLoading = loadingState === LoadingStates.LOADING;
  let hasLoaded = loadingState === LoadingStates.LOADED;

  let fullPath = rootPath + path;
  return (
    <PageLayout className="customReporting-pageLayout" showLoading={isLoading}>
      <div className="adminView">
        <div className="adminHeader">
          <div className="adminTitle">Custom Reports</div>
        </div>
        <BreadcrumbBar path={fullPath} />
        {hasLoaded && <FileList dirs={dirs} files={files} path={fullPath} />}
      </div>
    </PageLayout>
  );
}

CustomReportingPage.propTypes = {
  dirs: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  loadingState: PropTypes.oneOf(Object.keys(LoadingStates)),
  path: PropTypes.string.isRequired,
};

function BreadcrumbBar({ path }) {
  let parts = breadcrumbParts(path);
  let home = parts.shift();
  return (
    <div className="customReporting-breadcrumbBar">
      <Breadcrumb href={home.href}>
        <i className="customReporting-homeIcon" />
      </Breadcrumb>
      {parts.map(({ href, name }, i) => [
        <i className="customReporting-fileSeparator" key={`sep-${i}`} />,
        <Breadcrumb href={href} key={`crumb-${i}`}>
          {name}
        </Breadcrumb>,
      ])}
    </div>
  );
}

function breadcrumbParts(path) {
  let href = '/';
  return path
    .split('/')
    .filter(p => !!p) // remove empty parts
    .map(name => {
      href += `${name}/`;
      return { href, name: decodeURIComponent(name) };
    });
}

BreadcrumbBar.propTypes = {
  path: PropTypes.string.isRequired,
};

function Breadcrumb({ children, href }) {
  return (
    <div className="customReporting-breadcrumb">
      <Link className="customReporting-breadcrumb-link" href={href}>
        {children}
      </Link>
    </div>
  );
}

Breadcrumb.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function FileList({ path, dirs, files }) {
  return (
    <div className="customReporting-list">
      {dirs.map(dir => (
        <Dir dir={dir} key={dir} path={path} />
      ))}
      {files.map(({ name }) => (
        <File key={name} name={name} path={path} />
      ))}
    </div>
  );
}

FileList.propTypes = {
  path: PropTypes.string.isRequired,
  dirs: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
};

function FileListItem({ children }) {
  return (
    <div className="customReporting-listItem">
      <div className="adminListItemHeader">{children}</div>
    </div>
  );
}

FileListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

function File({ path, name }) {
  return (
    <FileListItem>
      <a href={`/api${path}${name}`}>
        <i className="customReporting-file" />
        {name}
      </a>
    </FileListItem>
  );
}

File.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
};

function Dir({ path, dir }) {
  return (
    <FileListItem>
      <Link href={`${path}${dir}`}>
        <i className="customReporting-folder" />
        {dir.slice(0, -1)}
      </Link>
    </FileListItem>
  );
}

Dir.propTypes = {
  path: PropTypes.string,
  dir: PropTypes.string,
};

export default connect(mapStateToProps)(CustomReportingPage);

function mapStateToProps({ getProvider }) {
  return getProvider('currentLocation').get();
}
