import CollectionStoreSaveResultHandler from '../lib/collection_store_save_result_handler';
import mixin from 'scripts/lib/mixin';
import DtoConverter from 'scripts/application/dto_converters/topic_converter';
import qconsole from 'scripts/lib/qconsole';
import TopicAncestryUpdater from 'actions/topics/lib/topic_ancestry_updater';

export default class TopicGatewayObserver {
  constructor(context) {
    this.context = context;
    this.topicAncestryUpdater = new TopicAncestryUpdater(context);
  }

  get store() {
    return this.context.stores.topics;
  }

  handleRequestedEntities(topicDtos) {
    this.context.stores.topics.set(topicDtos.map(DtoConverter.fromDto));
    this.topicAncestryUpdater.update();
  }

  handleRequestError(errorDto) {
    qconsole.log(`Failed to fetch topics list ${JSON.stringify(errorDto)}`);
  }

  handleEntity(topicDto) {
    this.context.stores.topics.addOrReplace(DtoConverter.fromDto(topicDto));
    this.topicAncestryUpdater.update();
  }

  handleDelete(topicId) {
    this.context.stores.topics.remove(topicId);
    this.topicAncestryUpdater.update();
  }
}

mixin(TopicGatewayObserver.prototype, CollectionStoreSaveResultHandler.prototype);
