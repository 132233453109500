import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spaceFor } from 'components/space_helper';
import InsetContainer from 'components/common/containers/inset_container';

const StackContainer = styled(InsetContainer)`
  margin-bottom: ${p => (p.stack !== 'end' ? spaceFor('stack', p) : 0)};
`;

StackContainer.propTypes = {
  inset: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  stack: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
};

/** @component */
export default StackContainer;
