import createNotificationModel, { prop } from 'scripts/domain/models/notification/lib/create_notification_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import NotificationType from 'scripts/domain/models/notification/notification_type';

export default createNotificationModel(NotificationType.ERROR, {
  modelName: 'ErrorNotification',

  properties: {
    id: prop(String).isRequired,
    message: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), ...attrs });
    },
  },
});
