import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Attachment from 'models/attachment';
import AttachmentPreviewOpener from 'components/lib/attachment/attachment_preview_opener';
import Composition from 'models/composition';
import CompositionSnippetAttachmentContainer from './composition_snippet_attachment_container';
import CompositionUploadContainer from './composition_upload_container';
import connect from 'components/lib/connect';
import { getSnippetAttachmentUrl, getUploadAttachmentUrl, getAttachmentUrl } from 'scripts/domain/services/attachments';
import Upload from 'models/upload';
import CompositionContentType from 'models/composition/composition_content_type';

function CompositionAttachments({ className, composition, currentAgentId, onOpenAttachmentPreview, orgId }) {
  const compositionAttachments = _.filter(composition.attachments, a => !a.isInline);
  if (!composition || _.isEmpty(compositionAttachments)) {
    return null;
  }

  let classNames = classnames('attachments', className);
  return <div className={classNames}>{_.map(compositionAttachments, renderAttachment)}</div>;

  function renderAttachment(attachment, index) {
    let props = {
      compositionId: composition.id,
      key: attachment.id,
      onOpenAttachmentPreview: openAttachmentPreview,
      thumbnailUrl: getThumbnailUrl(attachment),
    };

    if (attachment instanceof Attachment && composition.contentType() === CompositionContentType.TASK_EDIT) {
      props.thumbnailUrl = getTaskThumbnailUrl(attachment);
    } else if (
      attachment instanceof Attachment &&
      composition.contentType() === CompositionContentType.EMAIL &&
      composition.content.isForward === true
    ) {
      props.thumbnailUrl = getEmailAttachmentUrl(attachment);
    } else if (attachment instanceof Upload) {
      return <CompositionUploadContainer {...props} upload={attachment} />;
    }

    return <CompositionSnippetAttachmentContainer {...props} attachment={attachment} />;

    function openAttachmentPreview() {
      if (composition.contentType() === CompositionContentType.TASK_EDIT) {
        onOpenAttachmentPreview(compositionAttachments, getTaskThumbnailUrl, index);
      } else if (composition.contentType() === CompositionContentType.EMAIL && composition.content.isForward === true) {
        onOpenAttachmentPreview(compositionAttachments, getEmailAttachmentUrl, index);
      } else {
        onOpenAttachmentPreview(compositionAttachments, getDownloadUrl, index);
      }
    }
  }

  function getDownloadUrl(attachment) {
    return attachment.source && attachment.source.type === Attachment.SourceType.SNIPPET
      ? getSnippetAttachmentUrl(orgId, attachment)
      : getUploadAttachmentUrl(orgId, currentAgentId, composition.id, attachment);
  }

  function getThumbnailUrl(attachment) {
    return getDownloadUrl(attachment);
  }

  function getTaskThumbnailUrl(attachment) {
    return getAttachmentUrl('task', orgId, attachment);
  }

  function getEmailAttachmentUrl(attachment) {
    return getAttachmentUrl('email', orgId, attachment);
  }
}

CompositionAttachments.propTypes = {
  ...AttachmentPreviewOpener.childPropTypes,
  className: PropTypes.string,
  composition: PropTypes.instanceOf(Composition),
};

const CompositionAttachmentsContainerBase = connect(
  mapStateToProps,
  mapExecuteToProps,
  mergeProps
)(CompositionAttachments);

function mergeProps(stateProps, execProps, ownProps) {
  return Object.assign({}, stateProps, execProps);
}

function mapStateToProps(context, props) {
  const compositionsProvider = context.getProvider('compositions');

  let composition = compositionsProvider.findBy({ id: props.compositionId });
  return {
    className: props.className,
    composition,
    currentAgentId: context.getProvider('currentAgent').get().id,
    orgId: props.orgId,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onOpenAttachmentPreview: props.onOpenAttachmentPreview,
  };
}

CompositionAttachmentsContainerBase.propTypes = {
  ...AttachmentPreviewOpener.childPropTypes,
  className: PropTypes.string,
  compositionId: PropTypes.string.isRequired,
};

const CompositionAttachmentsContainer = AttachmentPreviewOpener(CompositionAttachmentsContainerBase);

CompositionAttachmentsContainer.propTypes = {
  className: PropTypes.string,
  compositionId: PropTypes.string.isRequired,
};

export { CompositionAttachmentsContainerBase, CompositionAttachments };
export default CompositionAttachmentsContainer;
