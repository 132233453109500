import _ from 'lodash';

import MqttGateway from './lib/mqtt_gateway';
import Observable from 'scripts/lib/observable_mixin';

export default class ActiveCallGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'agents/+/active/call';
  }

  fetch(agentId) {
    this.cancelFetches();
    this.agentId = agentId;
    return super._fetch([this.agentId]);
  }

  onFetchResponse(activeCallDto) {
    this.notifyObservers('handleFetchActiveCallSuccess', activeCallDto);
  }

  onFetchError(errorDto) {
    this.notifyObservers('handleFetchActiveCallError', errorDto);
  }

  reconnectHandler() {
    if (this.agentId) {
      super._fetch([this.agentId]);
    }
  }
}

_.extend(ActiveCallGateway.prototype, Observable);
