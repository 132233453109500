// Adapted from https://gist.github.com/muffs/9023e4cbe7ef5499b6a0

import _ from 'lodash';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

/**
 * Testable wrapper around React's CSSTransitionGroup
 * To disable transitions for testing:
 * ```
 * CSSTransitionGroup.isTransitionDisabled = true;
 * ```
 */
export default class CSSTransitionGroup extends React.Component {
  render() {
    var shouldTransition = !CSSTransitionGroup.isTransitionDisabled;

    var transitionProps = {
      transitionEnter: shouldTransition,
      transitionLeave: shouldTransition,
    };

    return (
      <ReactCSSTransitionGroup {..._.merge({}, this.props, transitionProps)}>
        {this.props.children}
      </ReactCSSTransitionGroup>
    );
  }
}
