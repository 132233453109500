export default function Selection() {
  return {
    commands: { ensureFullSelection, removeInlineOfTypeOnBackspace },
  };
}

export function ensureFullSelection(editor, next, type) {
  const { value } = editor;
  const { document, selection } = value;

  const inlines = document.getLeafInlinesAtRange(selection);
  if (!inlines.count()) {
    return next();
  }
  const foundInlines = inlines.filter(i => i.type === type);
  if (!foundInlines.count()) {
    return next();
  }

  // In the case of a collapsed selection, startInline and endInline will be the same.
  // If the selection starts and ends in two different variables, then they will be different.
  // Doesn't really affect the logic in any way though.
  const startInline = foundInlines.find(
    node =>
      selection.start.isInNode(node) && !selection.start.isAtEndOfNode(node) && !selection.start.isAtStartOfNode(node)
  );
  const endInline = foundInlines.find(
    node => selection.end.isInNode(node) && !selection.end.isAtEndOfNode(node) && !selection.end.isAtStartOfNode(node)
  );

  if (startInline || endInline) {
    // Allow the default behavior to go through before adjusting the selection.
    const nextReturn = next();
    if (startInline) {
      const previousNode = editor.value.document.getPreviousNode(startInline.key);
      if (previousNode) {
        editor.moveStartToEndOfNode(previousNode);
      } else {
        editor.moveStartToStartOfNode(startInline);
      }
    }
    startInline && editor.moveStartToStartOfNode(startInline);
    endInline && editor.moveEndToEndOfNode(endInline);
    return nextReturn;
  }

  const ret = next();
  if (editor.value.selection.isCollapsed && editor.value.selection.start.isAtStartOfNode(foundInlines.get(0))) {
    const previousNode = editor.value.document.getPreviousNode(foundInlines.get(0).key);
    previousNode && editor.moveToEndOfNode(previousNode);
  }

  return ret;
}

export function removeInlineOfTypeOnBackspace(editor, evt, type) {
  if (evt.key !== 'Backspace') {
    return false;
  }

  const { value } = editor;
  const { document, selection } = value;

  const currentNode = document.getNode(selection.start.key);
  if (!selection.start.isAtStartOfNode(currentNode)) {
    return false;
  }

  const previousNode = document.getPreviousNode(selection.start.key);
  if (!previousNode || previousNode.type !== type) {
    return false;
  }

  evt.preventDefault();
  editor.removeNodeByKey(previousNode.key);
  return true;
}
