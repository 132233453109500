import _ from 'lodash';
import CustomerMatch from 'models/customer_match';
import qconsole from 'scripts/lib/qconsole';

export default class CustomerMatchV2GatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleFetchCustomerMatchSuccess(fetchResultDto, customerId) {
    if (customerId) {
      if (this.context.stores.customers.has({ id: customerId })) {
        const filteredProfiles = _.filter(fetchResultDto.profiles, customerProfile => {
          return customerProfile.id !== customerId;
        });

        this.context.stores.customers
          .storesFor(customerId)
          .customerMatch.set(CustomerMatch.fromJs({ profiles: filteredProfiles }));
      }
    }
  }

  handleFetchCustomerMatchError(errorMessage) {
    qconsole.log(`Could not fetch CustomerMatch ${JSON.stringify(errorMessage)}`);
  }
}
