import AISuggestedReply from 'models/ai_suggested_reply';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getLatestManualCustomerConversationItemAttrs } from 'scripts/application/lib/conversation_history_helpers';

export default class RequestAISuggestedReply {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, type }) {
    if (!this.context.stores.appFeatures.get().isEnabled('gclDemo2023')) {
      return;
    }

    const { conversations, conversationHistory, aiSuggestedReply } = this.context.stores.customers.storesFor(
      customerId
    );
    const conversationId = getActiveConversationId(conversations);
    if (!conversationId) {
      return;
    }

    const latestCustomerItem = getLatestManualCustomerConversationItemAttrs({
      conversationHistory,
      conversationId,
      fields: ['id', 'timestamp'],
    });
    if (!latestCustomerItem) {
      return;
    }

    // We only want to request another suggested reply if the latest customer item is newer than when we last used a suggested reply
    // Otherwise we want to just keep showing the previous suggested reply.
    const currentReply = aiSuggestedReply.get();
    if (currentReply && currentReply.usedAt && new Date(currentReply.usedAt) > new Date(latestCustomerItem.timestamp)) {
      return;
    }

    this.context.gateways.aiSuggestedReply
      .add({}, { customerId, itemId: latestCustomerItem.id, type })
      .then(suggestedReplyDto => {
        if (!suggestedReplyDto) {
          return;
        }

        const { aiSuggestedReply } = this.context.stores.customers.storesFor(customerId);
        aiSuggestedReply.set(AISuggestedReply.create(suggestedReplyDto));
      })
      .catch(errorDto => {
        ErrorReporter.reportError(errorDto);
      });
  }
}
