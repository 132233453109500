import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { HandleHeightChange } from 'scripts/presentation/components/customer/conversation_history/virtualized/list_item';
import TaskComment from 'components/customer/task_comments/task_comment';
import TaskCommentModel from 'models/task_comment';

export function CommentPreview({ comment, commentCount, onClick, onHeightChange }) {
  useEffect(onHeightChange, [comment && comment.id]);

  if (!comment) return null;
  return (
    <StyledCommentPreview onClick={onClick}>
      {renderText()}
      <StyledTaskComment {...comment} />
    </StyledCommentPreview>
  );

  function renderText() {
    if (commentCount < 2) return null;

    let text = commentCount === 2 ? 'Show previous comment' : `Show ${commentCount - 1} previous comments`;
    return <StyledText>{text}</StyledText>;
  }
}

CommentPreview.propTypes = {
  commentCount: PropTypes.number,
  comment: PropTypes.instanceOf(TaskCommentModel),
  onClick: PropTypes.func,
  onHeightChange: PropTypes.func.isRequired,
};

export default function HeightChangeAwareCommentPreview(props) {
  return (
    <HandleHeightChange>
      {({ onHeightChange }) => <CommentPreview {...props} onHeightChange={onHeightChange} />}
    </HandleHeightChange>
  );
}

const StyledCommentPreview = styled.div`
  cursor: pointer;
`;

const StyledTaskComment = styled(TaskComment)`
  margin: 0 0 ${p => p.theme.spacing.stackSmall} 0;
  padding: 8px 0;
`;

const StyledText = styled.div`
  color: ${p => p.theme.colors.green400};
  padding-bottom: 8px;
  &:hover,
  &:focus {
    color: ${p => p.theme.colors.green500};
  }
`;
