import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import connect from 'components/lib/connect';
import Datasets from 'models/location/datasets';
import EmbeddedReport, { ReportStatus } from './report/embedded_report';
import { findReportConfigBySlug } from 'models/reports/report';
import FullPageSpinner, { SpinnerContainer } from 'components/common/full_page_spinner';
import { getAllReportConfigs } from 'actions/reporting/lib/reporting_helpers';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import IvrEndStateReport from './report/ivr_end_state_report';
import IvrReportFilters from './ivr_report_filters';
import IvrSummaryReportContainer from './report/ivr_summary_report_container';
import Link from 'components/common/link';
import NavigateToUrl from 'actions/current_location/navigate_to_url';
import PageLayout from '../page_layout';
import ReportConfigHeader from './report_config_header';
import ReportFilters from './report_filters';
import ReportNavigation, { refineSlug } from 'components/reporting/report_navigation';
import Reports from 'models/location/reports';
import ReportZeroState from './report_zero_state';
import SpinnerTwo from 'components/common/spinner_two';
import StackContainer from 'components/common/containers/stack_container';
import SummaryReport from './report/summary_report';
import TopicReport from './report/topic_report';
import TwoArrowsLeft from 'components/lib/icons/two_arrows_left';
import TwoArrowsRight from 'components/lib/icons/two_arrows_right';
import WindowSizeWatcher from 'scripts/presentation/components/common/utilities/window_size_watcher';

const MENU_WIDTH = 310;

export const Kind = Object.freeze({
  BUILT_IN: 'BUILT_IN', // Built-in dashboard reports, a.k.a. OOB dashboards.
  DATASET: 'DATASET', // Insight builder.
  EMBEDDED: 'EMBEDDED', // "Classic" embedded report with one or two tiles.
  IVR: 'IVR', // IVR Summary and IVR End States reports built with legacy Gladly UI.
  LEGACY: 'LEGACY',
  SHARED: 'SHARED',
});

export class ReportHome extends React.Component {
  constructor(props) {
    super(props);

    const isReportNavigationOpen = !this.shouldCloseReportNavigation({});

    this.state = {
      isReportNavigationOpen,
      // isReportNavigationVisible gates navigation rendering for animation:
      // when we set open:true we also set visible:true to show it right away,
      // when we set open:false we keep visible:true and only set visible:false when
      // the transition ends.
      isReportNavigationVisible: isReportNavigationOpen,
    };

    this.getReportUrl = this.getReportUrl.bind(this);
    this.toggleReportNavigationMenu = this.toggleReportNavigationMenu.bind(this);
    this.handleEmbeddedReportStatusChange = this.handleEmbeddedReportStatusChange.bind(this);
  }

  /* Render */
  render() {
    const {
      isReportListLoading,
      reportDescriptor: { kind },
    } = this.props;

    if (isReportListLoading) {
      return <FullPageSpinner data-aid="reportHome-spinner"></FullPageSpinner>;
    }

    const shouldLoadZeroState = this.isZeroState();

    return (
      <PageLayout className="reportHome" id="reports">
        <ReportHomeFlexWrapper>
          <StyledSidebar
            isReportNavOpen={this.state.isReportNavigationOpen}
            onTransitionEnd={() => this.setState({ isReportNavigationVisible: this.state.isReportNavigationOpen })}
          >
            {this.renderSidebarNavigationTab()}
            {this.state.isReportNavigationVisible ? this.renderSidebarNavigation() : null}
          </StyledSidebar>
          <InvisiblePlaceholder isReportNavOpen={this.state.isReportNavigationOpen} />
          <StyledReportMain isReportNavOpen={this.state.isReportNavigationOpen}>
            {_.includes([Kind.EMBEDDED, Kind.IVR, Kind.LEGACY], kind) ? (
              <ReportConfigHeader shouldLoadZeroState={shouldLoadZeroState} />
            ) : null}
            {kind === Kind.IVR ? this.renderIvrSubTabs() : null}
            {kind === Kind.IVR ? <IvrReportFilters isApplyForceEnabled={shouldLoadZeroState} /> : null}
            {kind === Kind.EMBEDDED || kind === Kind.LEGACY ? (
              <ReportFilters isApplyForceEnabled={shouldLoadZeroState} isDisabled={this.shouldDisableFilters()} />
            ) : null}
            {shouldLoadZeroState ? <ReportZeroState /> : this.renderReport()}
          </StyledReportMain>
        </ReportHomeFlexWrapper>
      </PageLayout>
    );
  }

  renderIvrSubTabs() {
    const {
      navigateToUrl,
      currentLocation: { slug: currentSlug },
    } = this.props;

    let ivrSummaryUrl = this.getReportUrl('ivr-summary');
    let ivrEndStatesUrl = this.getReportUrl('ivr-end-states');

    return (
      <div className="reportHeader reportHeader-tabs">
        <Link
          className={classnames('reportHeader-tab', {
            'reportHeader-activeTab': currentSlug === 'ivr-summary',
          })}
          href={ivrSummaryUrl}
          onClick={navigateToUrl.bind(this, ivrSummaryUrl)}
        >
          Executive Summary
        </Link>
        <Link
          className={classnames('reportHeader-tab', {
            'reportHeader-activeTab': currentSlug === 'ivr-end-states',
          })}
          href={ivrEndStatesUrl}
          onClick={navigateToUrl.bind(this, ivrEndStatesUrl)}
        >
          End States
        </Link>
      </div>
    );
  }

  renderSidebarNavigation() {
    return (
      <NavigationWrapper
        onTransitionEnd={e =>
          // Prevent re-rendering of ReportHome when CSS transitions happen within ReportNavigation.
          e.stopPropagation()
        }
      >
        <ReportNavigation
          // TODO: Remove isCollapsible prop below when number of reports warrants collapsible categories (the default
          // for prop is already set to true in ReportNavigation). See: ch57683
          isCollapsible={false}
        />
      </NavigationWrapper>
    );
  }

  renderSidebarNavigationTab() {
    const isExpandDisabled = this.isRestricted() || this.isRunning();

    return (
      <div>
        <CollapseTab
          data-aid={'reportHome-sideNav-collapseTab'}
          isHidden={!this.state.isReportNavigationOpen}
          onClick={this.toggleReportNavigationMenu}
        >
          <CollapseArrows />
        </CollapseTab>
        <ExpandTab
          data-aid={'reportHome-sideNav-expandTab'}
          isDisabled={isExpandDisabled}
          isHidden={this.state.isReportNavigationOpen}
          onClick={this.toggleReportNavigationMenu}
        >
          <ExpandArrows $isDisabled={isExpandDisabled} />
        </ExpandTab>
      </div>
    );
  }

  toggleReportNavigationMenu(evt) {
    evt.preventDefault();

    if (this.state.isReportNavigationOpen) {
      this.closeReportNavigationMenu();
    } else {
      this.openReportNavigationMenu();
    }
  }

  openReportNavigationMenu() {
    this.setState({
      isReportNavigationOpen: true,
      // if the navigation is opening, we want to make it visible it before the open animation.
      isReportNavigationVisible: true,
    });
  }

  closeReportNavigationMenu() {
    this.setState({
      isReportNavigationOpen: false,
      // We do NOT want to set `isReportNavigationVisible` to `false` if the navigation is closing because
      // we don't want to unmount it before the close animation - the onTransitionEnd handler will take care of that.
    });
  }

  getReportUrl(slug) {
    let currentLocation = this.props.currentLocation;
    return getLocationUrl(Reports.create({ ...currentLocation, slug: refineSlug(slug) }));
  }

  renderReport() {
    switch (this.props.reportDescriptor.kind) {
      case Kind.IVR:
      case Kind.LEGACY:
        return this.renderLegacyReport();
      default:
        return this.renderEmbeddedReport();
    }
  }

  renderEmbeddedReport() {
    const shouldRenderSpinner = this.shouldRenderSpinner();

    return (
      <React.Fragment>
        {shouldRenderSpinner && <ReportSpinner data-aid="reportHome-embeddedReport-spinner" />}
        {/* Always render because we need to wait for EmbeddedReport's iframe to load. Hide until that iframe is loaded. */}
        <div
          className={classnames('reportHome-embeddedReport-wrapper', {
            'reportHome-hidden': shouldRenderSpinner,
          })}
        >
          <EmbeddedReport
            key={this.props.currentLocation.slug}
            kind={this.props.reportDescriptor.kind}
            onStatusChange={this.handleEmbeddedReportStatusChange}
          />
        </div>
      </React.Fragment>
    );
  }

  renderLegacyReport() {
    if (this.shouldRenderSpinner()) {
      return <ReportSpinner data-aid="reportHome-legacyReport-spinner" />;
    }

    return (
      <WindowSizeWatcher>
        {({ windowWidth }) => {
          switch (this.props.currentLocation.slug) {
            case 'summary':
              return <SummaryReport windowWidth={windowWidth} />;
            case 'topics':
              return <TopicReport windowWidth={windowWidth} />;
            case 'ivr-summary':
              return <IvrSummaryReportContainer windowWidth={windowWidth} />;
            case 'ivr-end-states':
              return <IvrEndStateReport windowWidth={windowWidth} />;
            default:
              return null;
          }
        }}
      </WindowSizeWatcher>
    );
  }

  handleEmbeddedReportStatusChange(reportStatus) {
    let newState = { reportStatus };

    if (this.shouldCloseReportNavigation(newState)) {
      newState.isReportNavigationOpen = false;
    }

    this.setState(newState);
  }

  isRestricted() {
    return !this.props.isViewReportsEnabled;
  }

  isRun(state = this.state) {
    switch (this.props.reportDescriptor.kind) {
      case Kind.DATASET:
        return state.reportStatus === ReportStatus.LOADED;
      case Kind.IVR:
      case Kind.LEGACY:
        return this.props.reportDescriptor.isLoaded;
      default:
        return state.reportStatus === ReportStatus.RAN;
    }
  }

  isRunning(state = this.state) {
    return isLegacyReportLoading(this.props) || state.reportStatus === ReportStatus.RUNNING;
  }

  isZeroState() {
    const {
      reportDescriptor: { kind, isLoading, isLoaded },
    } = this.props;

    return _.includes([Kind.EMBEDDED, Kind.IVR, Kind.LEGACY], kind) && !(isLoading || isLoaded);
  }

  shouldCloseReportNavigation(state) {
    return this.isRestricted() || this.isRunning(state) || this.isRun(state);
  }

  shouldDisableFilters() {
    return this.isRestricted() || !(this.isZeroState() || this.isRun());
  }

  shouldRenderSpinner() {
    return (
      this.isRestricted() || this.props.reportDescriptor.isLoading || this.state.reportStatus === ReportStatus.LOADING
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (state.isReportNavigationOpen && isLegacyReportLoading(props)) {
      return {
        isReportNavigationOpen: false,
      };
    }

    return null;
  }
}

ReportHome.propTypes = {
  currentLocation: PropTypes.object,
  isReportListLoading: PropTypes.bool,
  isViewReportsEnabled: PropTypes.bool,
  reportDescriptor: PropTypes.shape({
    isLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    kind: PropTypes.oneOf(_.values(Kind)).isRequired,
  }).isRequired,

  navigateToUrl: PropTypes.func,
};

function isLegacyReportLoading(props) {
  const {
    reportDescriptor: { isLoading, kind },
  } = props;

  return _.includes([Kind.IVR, Kind.LEGACY], kind) && isLoading;
}

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const currentLocation = getProvider('currentLocation').get();
  const embeddedReport = getProvider('embeddedReport');
  const embedTokensReport = getProvider('embedTokensReport');
  const report = getProvider('report');
  const reportBuilderConfig = getProvider('reportBuilderConfig');
  const reportConfigs = getProvider('reportConfigs');
  const sharedReportConfigs = getProvider('sharedReportConfigs');

  const isCookielessLookerEmbedEnabled = isFeatureEnabled('cookielessLookerEmbed');
  const isReportListLoading = [reportBuilderConfig, reportConfigs, sharedReportConfigs].some(p => p.isLoading());
  const isViewReportsEnabled = isFeatureEnabled('viewReports');
  const reportDescriptor = Object.freeze(getReportDescriptor());

  return {
    currentLocation,
    isReportListLoading,
    isViewReportsEnabled,
    reportDescriptor,
  };

  function getReportDescriptor() {
    const provider = isCookielessLookerEmbedEnabled ? embedTokensReport : embeddedReport;
    if (currentLocation instanceof Datasets) {
      return { kind: Kind.DATASET, isLoading: provider.isLoading(), isLoaded: !!provider.get() };
    }

    if (!currentLocation.isEmbeddedReport()) {
      if (currentLocation.slug === 'ivr-summary' || currentLocation.slug === 'ivr-end-states') {
        return { kind: Kind.IVR, isLoading: report.isLoading(), isLoaded: !!report.get() };
      }

      return { kind: Kind.LEGACY, isLoading: report.isLoading(), isLoaded: !!report.get() };
    }

    const sharedReportSlugs = _.map(sharedReportConfigs.get().configs, 'urlSlug');

    if (_.includes(sharedReportSlugs, currentLocation.slug)) {
      return { kind: Kind.SHARED, isLoading: provider.isLoading(), isLoaded: !!provider.get() };
    }

    const allReportConfigs = getAllReportConfigs({ stores: { reportConfigs } });
    const currentReportConfig = findReportConfigBySlug(allReportConfigs, currentLocation.slug);

    if (currentReportConfig && _.isEmpty(currentReportConfig.filters)) {
      return { kind: Kind.BUILT_IN, isLoading: provider.isLoading(), isLoaded: !!provider.get() };
    }

    return { kind: Kind.EMBEDDED, isLoading: provider.isLoading(), isLoaded: !!provider.get() };
  }
}

function mapExecuteToProps(executeAction) {
  return {
    navigateToUrl: url => executeAction(NavigateToUrl, url),
  };
}

const ReportHomeContainer = connect(mapStateToProps, mapExecuteToProps)(ReportHome);

export default ReportHomeContainer;

const ReportHomeFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

// InvisiblePlaceholder grows and shrinks with the nav menu
// so the reports get compressed to the right
const InvisiblePlaceholder = styled.div`
  display: inline-block;
  height: 100vh;
  left: 0px;
  position: relative;
  top: 0px;
  transition: width 0.5s ease;
  visibility: hidden;
  width: 0px;

  ${p => p.isReportNavOpen && grow};
`;

export const StyledSidebar = styled.div`
  height: 100vh;
  position: fixed;
  transition: transform 0.5s ease;
  z-index: 6;

  ${p => p.isReportNavOpen && slideRight};
  ${p => !p.isReportNavOpen && slideCenter};
`;

const NavigationWrapper = styled.div`
  background-color: ${p => p.theme.colors.gray200};
  box-shadow: inset -3px 3px 3px ${p => p.theme.colors.gray300};
  display: inline-block;
  height: 94%;
  left: -${MENU_WIDTH}px;
  overflow: auto;
  padding: 32px;
  position: absolute;
  top: 0;
  width: ${MENU_WIDTH}px;
`;

const TabBase = css`
  align-items: center;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray300};
  cursor: pointer;
  display: flex;
  height: 28px;
  position: absolute;
  top: 22px;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  width: 28px;
  z-index: 9;

  &:hover {
    background: ${p => p.theme.colors.gray100};
  }
`;

const ExpandTab = styled.div`
  ${TabBase};

  border-left: none;
  border-radius: 0px 8px 8px 0px;
  box-shadow: 2px 2px 8px ${p => p.theme.colors.gray300};

  &:hover {
    background-color: ${p => p.theme.colors.gray100};
  }

  ${p => p.isHidden && hideExpand};
  ${p => p.isDisabled && disableExpandTab};
`;

const CollapseTab = styled.div`
  ${TabBase};

  border-radius: 8px 0px 0px 8px;
  border-right: none;
  box-shadow: -2px 2px 8px ${p => p.theme.colors.gray300};
  right: 0px;

  &:hover {
    background-color: ${p => p.theme.colors.gray100};
  }

  ${p => p.isHidden && slideCenter && hideCollapse};
`;

const ArrowBase = css`
  height: 14px;
  padding-left: 6px;
`;

export const ExpandArrows = styled(TwoArrowsRight)`
  ${ArrowBase};

  ${p => p.$isDisabled && disableExpandArrows};
`;

const CollapseArrows = styled(TwoArrowsLeft)`
  ${ArrowBase};
`;

function ReportSpinner() {
  return (
    <SpinnerContainer>
      <StackContainer stack="small">
        <StyledSpinner />
        Loading your report
      </StackContainer>
    </SpinnerContainer>
  );
}

const StyledReportMain = styled.div`
  display: inline-block;
  width: 100%;
`;

const StyledSpinner = styled(SpinnerTwo).attrs(p => ({ color: p.theme.colors.green400, size: 120, stroke: 3 }))``;

const hideExpand = css`
  opacity: 0;
  visibility: hidden;
`;

const disableExpandTab = css`
  box-shadow: none;
  pointer-events: none;
`;

const disableExpandArrows = css`
  filter: brightness(150%);
`;

const hideCollapse = css`
  opacity: 0;
`;

const grow = css`
  width: 400px;
`;

const slideRight = css`
  transform: translateX(310px);
`;

const slideCenter = css`
  transform: translateX(0px);
`;
