import createModel from 'models/lib/create_model';

export default createModel({
  modelName: 'VoiceCampaignOutreach',

  properties: {
    campaignId: String,
    name: String,
    description: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
