import _ from 'lodash';
import StationConfiguration from 'scripts/domain/models/station_configuration';

export default {
  fromDto: dto => {
    let newDto = { id: dto.address, ...dto };
    return StationConfiguration.fromJs(newDto);
  },

  toDto(stationConfig) {
    let dto = stationConfig.toJs();
    return _.omit(dto, 'id');
  },
};
