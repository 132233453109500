import _ from 'lodash';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';

export default class CompositionService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  publishResponse(topic, payload) {
    this._pubsub.publish(topic, (payload && { payload }) || {});
  }

  add(requestorId, orgId, agentId, correlationId, compAttrs) {
    const attrs = _.merge({}, compAttrs);
    const { id } = attrs;

    let compositions = this._getCompositions(orgId, agentId);
    let compositionIndex = this._findCompositionIndex(orgId, agentId, id);

    if (compositionIndex !== -1) {
      this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/compositions/${id}`, {
        correlationId,
        status: 'error',
        errors: [
          {
            attr: 'composition',
            detail: 'compositionId is already taken',
          },
        ],
      });
      return;
    }
    compositions.push(attrs);

    this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/compositions`, {
      correlationId,
      status: 'success',
    });
    this.publishResponse(`v1/orgs/${orgId}/agents/${agentId}/compositions/${id}`, attrs);
  }

  remove(requestorId, orgId, agentId, compositionId, correlationId) {
    _.remove(this._getCompositions(orgId, agentId), composition => {
      return composition.id === compositionId;
    });

    this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/compositions`, {
      status: 'success',
    });

    this.publishResponse(`v1/orgs/${orgId}/agents/${agentId}/compositions/${compositionId}/event/delete`);
  }

  find(orgId, agentId) {
    return this._getCompositions(orgId, agentId);
  }

  findCustomerCompositionIds(orgId, agentId, customerIds) {
    let compositions = this._getCompositions(orgId, agentId);
    let compsByCustomer = _.groupBy(compositions, 'customerId');
    let result = [];
    _.forEach(compsByCustomer, (comps, customerId) => {
      if (_.includes(customerIds, customerId)) {
        result.push({
          customerId,
          compositionIds: _.map(comps, c => c.id),
        });
      }
    });
    return result;
  }

  update(requestorId, orgId, agentId, compositionId, correlationId, compAttrs) {
    let compositions = this._getCompositions(orgId, agentId);
    let compositionIndex = this._findCompositionIndex(orgId, agentId, compositionId);

    if (compositionIndex === -1) {
      this._pubsub.publish(
        `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/compositions/${compositionId}`,
        {
          correlationId,
          status: 'error',
          errors: [
            {
              attr: 'composition',
              detail: 'no match in db for compositionId',
            },
          ],
        }
      );
      return;
    }

    compositions[compositionIndex] = compAttrs;

    this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/compositions`, {
      correlationId,
      status: 'success',
    });

    this.publishResponse(
      `v1/orgs/${orgId}/agents/${agentId}/compositions/${compositionId}`,
      compositions[compositionIndex]
    );
  }

  _findCompositionIndex(orgId, agentId, compositionId) {
    return _.findIndex(this._getCompositions(orgId, agentId), composition => {
      return composition.id === compositionId;
    });
  }

  _getCompositions(orgId, agentId) {
    let agentCompositions = this.getDatabase(orgId).compositions[agentId];
    if (!agentCompositions) {
      this.getDatabase(orgId).compositions[agentId] = [];
    }
    return this.getDatabase(orgId).compositions[agentId];
  }
}
