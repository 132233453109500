import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import CustomerView from 'models/location/customer_view';
import createEnum from 'scripts/lib/create_enum';
import Notification from './notification';

export const ChatNotificationTypes = createEnum('INCOMING', 'REPLY', 'REASSIGNMENT', 'DECLINED_REASSIGNMENT');

function ChatNotification({
  assigningAgentName,
  cancelButtonTitle,
  currentLocation,
  customerName,
  isCurrentChatNotification,
  notificationId,
  notificationTitle,
  notificationType,
  messageText,
  onPrimaryAction,
  onCancelAction,
  primaryButtonTitle,
  shouldDisableCancelButton,
  shouldDisablePrimaryButton,
  transfereeName,
}) {
  if (!notificationId || isCurrentChatNotification) {
    return null;
  }

  if (notificationType === ChatNotificationTypes.REPLY && currentLocation instanceof CustomerView) {
    return null;
  }

  return (
    <Notification contentClasses="incomingChatNotification" notificationId={notificationId}>
      <div className="conversationNotification-topic">
        <ChatIconOutline className="conversationNotification-chat-icon" />
        <div className="conversationNotification-notificationType">{notificationTitle}</div>
        <div className="conversationNotification-divider" />
        {renderChatNotificationBody()}
      </div>
      <div className="notification-buttonContainer">
        <Button
          buttonType={ButtonTypes.TEXT}
          className={classnames('notification-button', 'chatNotification-button', 'chatNotification-cancel', {
            'chatNotification-disabled': shouldDisableCancelButton,
          })}
          contrast
          disabled={shouldDisableCancelButton}
          onClick={onCancelAction}
        >
          {cancelButtonTitle}
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          className={classnames('notification-button', 'chatNotification-button', 'chatNotification-primary', {
            'chatNotification-disabled': shouldDisablePrimaryButton,
          })}
          contrast
          data-aid="notification-button-primary"
          disabled={shouldDisablePrimaryButton}
          onClick={onClickPrimary}
        >
          {primaryButtonTitle}
        </Button>
      </div>
    </Notification>
  );

  function onClickPrimary(ev) {
    ev.stopPropagation();
    onPrimaryAction();
  }

  function renderChatNotificationBody() {
    switch (notificationType) {
      case ChatNotificationTypes.REASSIGNMENT:
        return (
          <div className="conversationNotification-topic-container-reassignment">
            <div className="conversationNotification-topic-name fs-mask">{customerName}</div>
            <div className="conversationNotification-topic-reassignment"> has been assigned to you by </div>
            <div className="conversationNotification-topic-name">{assigningAgentName}</div>
          </div>
        );
      case ChatNotificationTypes.DECLINED_REASSIGNMENT:
        return (
          <div className="conversationNotification-topic-container-reassignment">
            <div className="conversationNotification-topic-name">{transfereeName}</div>
            <div className="conversationNotification-topic-reassignment"> isn't available to help </div>
            <div className="conversationNotification-topic-name fs-mask">{customerName}</div>
          </div>
        );
      default:
        return (
          <div className="conversationNotification-topic-container">
            <div className="conversationNotification-topic-heading fs-mask">{customerName}</div>
            <div className="conversationNotification-divider" />
            <div className="conversationNotification-topic-body">{messageText}</div>
          </div>
        );
    }
  }
}

ChatNotification.propTypes = {
  assigningAgentName: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  customerName: PropTypes.string,
  isCurrentChatNotification: PropTypes.bool,
  messageText: PropTypes.string,
  notificationId: PropTypes.string,
  notificationTitle: PropTypes.string.isRequired,
  notificationType: PropTypes.string,
  onPrimaryAction: PropTypes.func.isRequired,
  onCancelAction: PropTypes.func.isRequired,
  primaryButtonTitle: PropTypes.string,
  shouldDisableCancelButton: PropTypes.bool,
  shouldDisablePrimaryButton: PropTypes.bool,
  transfereeName: PropTypes.string,
};

export default ChatNotification;
