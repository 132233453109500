import { useEffect } from 'react';
import { isEmpty, isFunction, filter, get, map, trim } from 'lodash';

import { insertFragmentWithoutExtraLineToStart } from 'components/text_editor/plugins/fragment_helpers';
import { isEditorEmpty } from 'components/text_editor/helpers';
import ClearComposerConversationSummary from 'actions/ai_conversation_summary/clear_composer_conversation_summary';
import connect from 'components/lib/connect';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import AITextCompletion from 'models/ai_text_completion';

export function InsertConversationSummaryBase({ editor, fragmentHtml, onAfterInserted, onChange }) {
  useEffect(() => {
    if (!editor || !fragmentHtml || !onChange) return;

    // Do we have a text in the editor already?
    const hasText = !isEditorEmpty(editor.value);

    const value = FullSerializer.deserialize(fragmentHtml);
    if (hasText) {
      editor.insertFragment(value.document);
    } else {
      insertFragmentWithoutExtraLineToStart(editor, value.document);
    }
    onChange && onChange(editor);

    if (isFunction(onAfterInserted)) {
      onAfterInserted();
    }
  }, [editor, fragmentHtml, onAfterInserted, onChange]);

  return null;
}

function formatConversationSummary(summaryDto) {
  if (!summaryDto) return '';

  const caption = trim(get(summaryDto, 'summary.caption')); // This also takes care of null and undefined values
  const details = get(summaryDto, 'summary.details') || [];
  const mentionedIds = get(summaryDto, 'summary.mentionedIdentifiers') || [];

  const blockSeparator = '<p></p>';
  const captionBlock = caption ? `<p><strong>Reason for customer contact</strong></p><p>${caption}</p>` : '';

  // If we received "mentioned identifiers", add them as the first item of the "details" section
  const formattedIdentifiers = map(mentionedIds, id => trim(id))
    .filter(sentence => !!sentence)
    .join(', ');
  if (formattedIdentifiers && details.length) {
    details.unshift(`Key details: ${formattedIdentifiers}`);
  }

  // If we know the "summary boundaries", add this to the details
  const boundary = formatSummaryBoundary(summaryDto);
  if (boundary) details.push(boundary);

  let detailsBlock = '';
  const trimmedDetails = map(details, sentence => trim(sentence));
  const filteredDetails = filter(trimmedDetails, sentence => !!sentence);
  const detailsBlockItems = map(filteredDetails, sentence => `<li>${sentence}</li>`);
  if (detailsBlockItems.length) {
    const listElement = `<ul>${detailsBlockItems.join()}</ul>`;
    detailsBlock = `<p><strong>Summary of the conversation</strong></p>${listElement}`;
  }

  return captionBlock || detailsBlock
    ? `<div>${captionBlock}${captionBlock && detailsBlock ? blockSeparator : ''}${detailsBlock}</div>`
    : '';
}

function formatSummaryBoundary(summaryDto) {
  const { summaryStart, summaryEnd } = get(summaryDto, 'summary', {});
  if (isEmpty(summaryStart) || isEmpty(summaryEnd)) return '';

  const fromTimestamp = summaryStart.timestamp;
  const toTimestamp = summaryEnd.timestamp;
  if (!fromTimestamp || !toTimestamp) return '';

  const fromDateTime = new Date(fromTimestamp);
  const toDateTime = new Date(toTimestamp);

  // Replacing 'moment' with built-in date formatters.
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
  const dateFormatter = new Intl.DateTimeFormat(navigator.language, { dateStyle: 'long' });
  const timeFormatter = new Intl.DateTimeFormat(navigator.language, { timeStyle: 'short' });

  const startDate = dateFormatter.format(fromDateTime);
  const endDate = dateFormatter.format(toDateTime);
  const startTime = timeFormatter.format(fromDateTime);
  const endTime = timeFormatter.format(toDateTime);

  if (startDate === endDate) {
    return `This summary covers the conversation on ${startDate} from ${startTime} to ${endTime}`;
  } else {
    return `This summary covers messages from ${startDate} to ${endDate}`;
  }
}

function mapStateToProps({ getProvider }, { conversationId }) {
  const completionProvider = getProvider('aiTextCompletions');
  const completion = completionProvider.findBy({
    id: conversationId,
    type: AITextCompletion.Types.conversation_summary,
  });

  return {
    fragmentHtml: completion ? formatConversationSummary(completion.data) : '',
  };
}

function mapExecuteToProps(executeAction, { conversationId, customerId }) {
  return {
    onAfterInserted: () => {
      executeAction(ClearComposerConversationSummary, { conversationId, customerId });
    },
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(InsertConversationSummaryBase);
