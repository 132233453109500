import React, { useCallback } from 'react';

import { Bold, Button, Card } from './shared_toast';
import connect from 'components/lib/connect';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import TrackToast from 'actions/toast/track_toast';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function AgentMentionedInItemToast({ agentName, toast }) {
  const executeAction = useExecuteAction();

  const { customerId, itemId, itemType } = toast.content;

  const navigateToMention = useCallback(() => {
    executeAction(NavigateToItem, {
      customerId,
      itemId,
    });
    executeAction(TrackToast, { toast, track: 'Action Clicked' });
  }, [customerId, itemId]);

  const action = (
    <Button id={toast.id} onClick={navigateToMention}>
      {buttonText(itemType)}
    </Button>
  );

  return (
    <Card action={action} toast={toast}>
      <Bold>{agentName}</Bold> mentioned <Bold>you</Bold> {inContent(itemType)}
    </Card>
  );
}

function buttonText(type) {
  if (type === 'NOTE') {
    return 'Go to note';
  } else if (type === 'TASK') {
    return 'Go to task';
  }
}

function inContent(type) {
  if (type === 'NOTE') {
    return 'in a note';
  } else if (type === 'TASK') {
    return 'in a task';
  }
}

const AgentMentionedInItemToastContainer = connect(mapStateToProps)(AgentMentionedInItemToast);

function mapStateToProps({ getProvider }, { toast }) {
  const { agentId } = toast.content;

  const agent = getProvider('agents').findBy({ id: agentId });
  let agentName = 'An agent';
  if (agent) {
    agentName = agent.getDisplayName();
  }

  return {
    agentName,
  };
}

export default AgentMentionedInItemToastContainer;
