import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Button from 'components/common/button';
import createEnum from 'scripts/lib/create_enum';
import EndAndClose from 'components/composer/chat/end_and_close';
import { PopoverMenuItem } from 'components/common/popover_menu_deprecated';
import registerHotkey from 'components/hotkeys/register_hotkey';
import SplitButton from 'components/common/split_button';
import Tooltip from 'components/common/tooltip';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useKeyboardShortcut from 'components/hooks/use_keyboard_shortcut';
import useWindowSize from 'components/hooks/use_window_size';

import GetNextConversation from 'actions/conversation/get_next_conversation';
import OpenTopics from 'actions/customer/open_topics';
import TryClosingConversation from 'actions/conversation/try_closing_conversation';
import TryCloseAndGetNextConversation from 'actions/conversation/try_close_and_get_next_conversation';

const ConversationWorkflowButtons = React.memo(ConversationWorkflowButtonsBase);
export default ConversationWorkflowButtons;

export function ConversationWorkflowButtonsBase(props) {
  const {
    areTopicsRequired,
    isAssignedToCurrentAgent,
    isChatActive,
    isClosed,
    isDisabled,
    isNextDisabled,
    isNoReplyNeeded,
  } = props;

  const [onClose, onCloseAndNext, onNext] = useCloseCallbacks(props);
  useKeyboardShortcut(closeHotkey, onClose);
  useKeyboardShortcut(closeAndNextHotkey, onCloseAndNext);
  useKeyboardShortcut(nextHotkey, onNext);

  if (isChatActive) {
    return <EndAndClose isEditorEmpty />;
  } else if (areTopicsRequired) {
    return <ApplyTopics isDisabled={isDisabled} isNextDisabled={isNextDisabled} onNext={onNext} />;
  } else if (isClosed) {
    return <Next isDisabled={isDisabled || isNextDisabled} onNext={onNext} />;
  } else if (isNextDisabled) {
    return <Close isDisabled={isDisabled} onClose={onClose} />;
  } else if (isAssignedToCurrentAgent && !isNoReplyNeeded) {
    return <CloseAndNext isDisabled={isDisabled} onClose={onClose} onCloseAndNext={onCloseAndNext} onNext={onNext} />;
  }
  return <NextWithMenu isDisabled={isDisabled} onClose={onClose} onCloseAndNext={onCloseAndNext} onNext={onNext} />;
}

function useCloseCallbacks({ areTopicsRequired, conversationId, customerId, isDisabled, isNextDisabled }) {
  const executeAction = useExecuteAction();
  const onClose = useCallback(
    () => !isDisabled && !areTopicsRequired && executeAction(TryClosingConversation, { conversationId, customerId }),
    [conversationId, customerId, isDisabled, areTopicsRequired]
  );
  const onCloseAndNext = useCallback(
    () =>
      !isDisabled &&
      !isNextDisabled &&
      !areTopicsRequired &&
      executeAction(TryCloseAndGetNextConversation, { conversationId, customerId }),
    [conversationId, customerId, isDisabled, isNextDisabled, areTopicsRequired]
  );
  const onNext = useCallback(
    () => !isDisabled && !isNextDisabled && executeAction(GetNextConversation, { conversationId }),
    [conversationId, isDisabled, isNextDisabled]
  );

  return [onClose, onCloseAndNext, onNext];
}

export function ApplyTopics({ isDisabled, isNextDisabled, onNext }) {
  const executeAction = useExecuteAction();
  const onApplyTopics = useCallback(() => !isDisabled && executeAction(OpenTopics), [isDisabled]);
  const { windowWidth } = useWindowSize();

  const tooltipProps = {
    bounds: { right: windowWidth - 8 },
    delay: 300,
    message: 'Apply topics before closing the conversation',
    position: 'left',
  };

  return (
    <SplitButton
      data-aid="workflow-button"
      disabled={isDisabled}
      dropDirection="up"
      menuItems={[
        <Tooltip {...tooltipProps} key="close">
          <CloseItem isDisabled onClick={_.noop} />
        </Tooltip>,
        <NextItem isDisabled={isNextDisabled} key="next" onClick={onNext} />,
        <Tooltip {...tooltipProps} key="closeAndNext">
          <CloseAndNextItem isDisabled onClick={_.noop} />
        </Tooltip>,
      ]}
      onActionClick={onApplyTopics}
    >
      Apply Topics
    </SplitButton>
  );
}

export function NextWithMenu({ isDisabled, onClose, onCloseAndNext, onNext }) {
  return (
    <SplitButton
      data-aid="workflow-button"
      disabled={isDisabled}
      dropDirection="up"
      menuItems={[
        <CloseItem key="close" onClick={onClose} />,
        <CloseAndNextItem key="closeAndNext" onClick={onCloseAndNext} />,
      ]}
      onActionClick={onNext}
    >
      Next
    </SplitButton>
  );
}

export function Close({ isDisabled, onClose }) {
  return (
    <Button data-aid="close-button" disabled={isDisabled} onClick={onClose}>
      Close
    </Button>
  );
}

export function CloseAndNext({ isDisabled, onClose, onCloseAndNext, onNext }) {
  return (
    <SplitButton
      data-aid="workflow-button"
      disabled={isDisabled}
      dropDirection="up"
      menuItems={[<NextItem key="next" onClick={onNext} />, <CloseItem key="close" onClick={onClose} />]}
      onActionClick={onCloseAndNext}
    >
      Close & Next
    </SplitButton>
  );
}

export function Next({ isDisabled, onNext }) {
  return (
    <Button data-aid="next-button" disabled={isDisabled} onClick={onNext}>
      Next
    </Button>
  );
}

function NextItem({ isDisabled, onClick }) {
  return (
    <PopoverMenuItem data-aid="next" isDisabled={isDisabled} onClick={onClick}>
      Next
    </PopoverMenuItem>
  );
}

function CloseItem({ isDisabled, onClick }) {
  return (
    <PopoverMenuItem data-aid="close" isDisabled={isDisabled} onClick={onClick}>
      Close
    </PopoverMenuItem>
  );
}
function CloseAndNextItem({ isDisabled, onClick }) {
  return (
    <PopoverMenuItem data-aid="close-and-next" isDisabled={isDisabled} key="closeAndNextMenuItem" onClick={onClick}>
      Close & Next
    </PopoverMenuItem>
  );
}

const WorkflowActions = createEnum('CLOSE', 'NEXT', 'CLOSE_AND_NEXT');

ConversationWorkflowButtonsBase.WorkflowActions = WorkflowActions;

ConversationWorkflowButtonsBase.propTypes = {
  areChatActionsDisabled: PropTypes.bool,
  areTopicsRequired: PropTypes.bool,
  conversationId: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  hasAgentJoinedChat: PropTypes.bool,
  isAssignedToCurrentAgent: PropTypes.bool,
  isChatActive: PropTypes.bool,
  isClosed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  isNoReplyNeeded: PropTypes.bool,
};

ConversationWorkflowButtonsBase.defaultProps = {
  isClosed: false,
  isDisabled: false,
  isNextDisabled: false,
};

const closeHotkey = registerHotkey({
  key: 'alt+.',
  group: 'Conversation Workflow',
  label: 'Close',
});
const nextHotkey = registerHotkey({
  key: 'alt+,',
  group: 'Conversation Workflow',
  label: 'Next',
});
const closeAndNextHotkey = registerHotkey({
  key: 'alt+;',
  group: 'Conversation Workflow',
  label: 'Close & next',
});
