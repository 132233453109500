import PropTypes from 'prop-types';
import React from 'react';

import AgentMetadata from '../metadata/agent_metadata';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';

export function TaskCompletionItem({ item, onClickCompletionLink }) {
  const metadataText = (
    <span className="taskCompletionItem">
      closed a{' '}
      <a
        className="taskCompletionItem-link itemMetadata-subject"
        href={`#conversationItem-${item.content.taskItemId}`}
        onClick={onClick}
      >
        Task
      </a>
    </span>
  );

  return (
    <ItemContent isAuditItem item={item}>
      <AgentMetadata item={item} text={metadataText} />
    </ItemContent>
  );

  function onClick(e) {
    e.preventDefault();
    onClickCompletionLink();
  }
}

TaskCompletionItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onClickCompletionLink: PropTypes.func.isRequired,
};

function mapStateToProps(context, props) {
  return props;
}

function mapExecuteToProps(executeAction, { item }) {
  return {
    onClickCompletionLink: () => {
      executeAction(NavigateToItem, {
        itemId: item.content.taskItemId,
      });
    },
  };
}

const TaskCompletionItemContainer = connect(mapStateToProps, mapExecuteToProps)(TaskCompletionItem);
TaskCompletionItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
export default TaskCompletionItemContainer;
