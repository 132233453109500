import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { PlateProvider } from '@udecode/plate';

import { StyledOverflow } from 'components/common/dynamic_overflow';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import FormatterContext from 'components/composer/shared/formatter_context';
import ToggleTaskFormattingVisible from 'actions/task/toggle_task_formatting_visible';
import connect from 'components/lib/connect';

export function EditorWrapper(props) {
  const { children, 'data-aid': dataaid, className, editor, id, isFormattingVisible, innerRef: ref } = props;

  const executeAction = useExecuteAction();
  const toggleIsFormattingVisible = useCallback(() => {
    executeAction(ToggleTaskFormattingVisible);
  }, []);

  const value = useMemo(() => {
    return { isFormattingVisible, toggleIsFormattingVisible };
  }, [isFormattingVisible, toggleIsFormattingVisible]);

  return (
    <PlateProvider editor={editor} id={id}>
      <FormatterContext.Provider value={value}>
        <StyledEditorWrapper className={className} data-aid={dataaid} ref={ref}>
          {children}
        </StyledEditorWrapper>
      </FormatterContext.Provider>
    </PlateProvider>
  );
}

const EditorWrapperContainer = connect(mapStateToProps)(EditorWrapper);

function mapStateToProps({ getProvider }) {
  return {
    isFormattingVisible: getProvider('agentPreferences').get().isTaskCommentFormattingOpen,
  };
}

export default EditorWrapperContainer;

const StyledEditorWrapper = styled.div`
  background-color: ${p => p.theme.colors.white};
  border-top: 1px solid ${p => p.theme.colors.gray200};
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
  position: sticky;

  textarea {
    border: 1px solid ${p => p.theme.colors.gray400};
    border-radius: ${p => p.theme.borderRadius.default};
    margin-left: ${p => p.theme.spacing.medium};
    outline: none;
    padding: 8px 12px;
    resize: none;
    width: 100%;
    &:focus {
      border-color: ${p => p.theme.colors.green400};
    }
  }

  ${StyledOverflow} {
    justify-content: flex-start;
    margin-bottom: 8px;
  }
`;
