import Err from 'models/err';
import Upload from 'models/upload';
import { SnippetContentType, ChannelMaxUploadSize } from 'models/answers/snippet';

export default class StartUpload {
  constructor(context) {
    this.context = context;
  }

  run({ file, contentChannelType, onInsertImage, isInline, language, currentImageCount }) {
    let snippetCompositionStore = this.context.stores.snippetComposition;

    let errors = Upload.getValidationErrors({ file });
    if (errors.length) {
      snippetCompositionStore.setErrors(errors);
      return;
    }

    let upload = Upload.create({ file, isInline });
    let largeUploadError = this.constructor.validateSize(upload, contentChannelType);
    if (largeUploadError.length) {
      snippetCompositionStore.setErrors(largeUploadError);
      return;
    }

    let snippetComposition = snippetCompositionStore.get();
    let content = snippetComposition.findOrCreateContentByLanguage(language);
    if (!content[contentChannelType]) {
      content.addContentOfType(contentChannelType);
    }
    content[contentChannelType].addAttachment(upload);
    this.context.stores.snippetComposition.clearErrors();
    this.context.stores.snippetComposition.set(snippetComposition);

    const orgId = this.context.stores.auth.get().getOrgId();
    if (contentChannelType === SnippetContentType.SELF_SERVICE) {
      if (currentImageCount > 9) {
        snippetCompositionStore.setErrors([
          new Err({
            attr: 'attachments',
            code: Err.Code.INVALID,
            detail: `Answer cannot contain more than 10 images`,
          }),
        ]);
        return;
      }
      this.context.gateways.publicAnswerUpload
        .fetchUploadAuth(orgId, upload.id, upload.fileDescriptor().toJs())
        .then(response => this.handleAssetUploadAuthSuccess(upload, response, onInsertImage))
        .catch(err => this.handleAssetUploadAuthError(err, upload, snippetCompositionStore));
      return;
    }
    this.context.gateways.snippetUpload.fetchUploadAuth(
      snippetComposition.getUploadPath(upload.id),
      upload.fileDescriptor().toJs()
    );

    // private inline images
    if (upload.isImage() && onInsertImage) {
      onInsertImage({ orgId, image: upload });
    }
  }

  handleAssetUploadAuthSuccess(upload, response, onInsertImage) {
    onInsertImage({ image: upload, imageUrl: response.objectUrl });
  }

  handleAssetUploadAuthError(err, upload, snippetCompositionStore) {
    snippetCompositionStore.setErrors([
      new Err({
        attr: 'image',
        code: Err.Code.OPERATION_FAILED,
        detail: `Image ${upload.fileDescriptor().filename} failed to upload. Please try again.`,
      }),
    ]);
  }

  static validateSize(upload, snippetContentType) {
    let maxSize = ChannelMaxUploadSize.ANY_CHANNEL;
    if (snippetContentType === SnippetContentType.SELF_SERVICE) {
      maxSize = ChannelMaxUploadSize.SELF_SERVICE;
    }
    if (upload.fileDescriptor().contentLength > maxSize * MB) {
      return [
        new Err({
          attr: 'attachments',
          code: Err.Code.TOO_LONG,
          detail: `Attachment ${upload.fileDescriptor().filename} exceeds ${maxSize}MB`,
        }),
      ];
    }
    return [];
  }
}
const MB = 1 << (10 * 2);
