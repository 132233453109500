import CombineCurrentCustomer from './combine_current_customer';
import connect from 'components/lib/connect';
import { getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getLatestManualItem, getOpenTaskCount } from 'scripts/application/lib/conversation_history_helpers';

export default connect(mapStateToProps)(CombineCurrentCustomer);

function mapStateToProps({ getProvider }) {
  const latestConversationId = getLatestConversationId(getProvider('conversations'));
  let conversation = latestConversationId ? getProvider('conversations').findBy({ id: latestConversationId }) : null;
  let conversationHistory = getProvider('conversationHistory');
  let latestConversationItem = latestConversationId
    ? getLatestManualItem({
        conversationHistory,
        conversationId: latestConversationId,
      })
    : null;
  let taskCount = latestConversationId ? getOpenTaskCount({ conversationHistory, conversationId: conversation.id }) : 0;
  return {
    conversation,
    latestConversationItem,
    profile: getProvider('profile').get(),
    taskCount,
  };
}
