import { Factory } from 'rosie';

Factory.define('AgentRoutingPreferences')
  .attr('channels', {})
  .attr('isFocusOn', false)
  .attr('_version', 1);

Factory.define('AgentRoutingPreferencesNone')
  .extend('AgentRoutingPreferences')
  .attr('channels', () => Factory.attributes('ChannelStatesNone'));

Factory.define('ChannelStatesNone')
  .attr('MAIL', false)
  .attr('MESSAGING', false)
  .attr('TASK', false)
  .attr('VOICE', false);
