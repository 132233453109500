import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('IvrNodeWithDefaults')
  .attr('type', 'IVR_NODE_SCHEDULE')
  .attr('ivrNode', () => Factory.attributes('IvrNodeScheduleWithDefaults'));

Factory.define('IvrNodeSchedule').extend('Uuid');

Factory.define('IvrNodeScheduleWithDefaults')
  .extend('IvrNodeSchedule')
  .attr('description', 'node-schedule-description')
  .attr('nodeSchedules', [])
  .attr('defaultNextId', 'next-id');

Factory.define('NodeScheduleWithDefaults')
  .attr('nodeId', 'node-id')
  .attr('schedule', () => Factory.attributes('ScheduleWithDefaults'));

Factory.define('ScheduleWithDefaults')
  .attr('type', 'SCHEDULE_TYPE_DATE')
  .attr('schedule', () => Factory.attributes('DateScheduleWithDefaults'));

Factory.define('DateScheduleWithDefaults')
  .attr('location', 'America/New_York')
  .attr('date', () => new Date().toDateString())
  .attr('timeRanges', () => [Factory.attributes('TimeRangeWithDefaults')]);

Factory.define('TimeRangeWithDefaults')
  .attr('start', '00:00')
  .attr('end', '23:59');
