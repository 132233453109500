import LocationClasses from './location_classes';
import TypeReflect from 'models/lib/type_reflect';

export const typeReflect = new TypeReflect(LocationClasses);

export default function(location) {
  function getLocationClass(locationName) {
    try {
      return typeReflect.typeToConstructor(locationName);
    } catch (e) {
      throw new Error(`unknown location [${locationName}]`);
    }
  }

  return getLocationClass(location.name).fromJs(location);
}
