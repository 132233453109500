import PropTypes from 'prop-types';
import React from 'react';

import ConversationNotification from 'models/notification/conversation_notification';
import NotificationContentContainer from './notification_content_container';
import PhoneCall from 'models/phone_call';

function CompletedCallNotification({ notification }) {
  if (notification.conversationItem.content.status !== PhoneCall.Status.COMPLETED) {
    return null;
  }

  const heading = notification.profile.name || notification.conversationItem.content.customerNumber;
  const itemType = notification.conversationItem.content.isAbandonedCall() ? 'Abandoned call' : 'Completed call';

  return (
    <NotificationContentContainer
      heading={heading}
      iconClass="icon-phone-filled"
      itemType={itemType}
      notification={notification}
    />
  );
}

CompletedCallNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default CompletedCallNotification;
