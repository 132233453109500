import Communicator from 'models/communicator';
import Factory from 'factories/all';

export default function createIncomingEmailItem(incomingEmail, agent) {
  return Factory.build('EmailMessageItem', {
    initiator: {
      type:
        incomingEmail.initiator && incomingEmail.initiator.type ? incomingEmail.initiator.type : Communicator.CUSTOMER,
      id: incomingEmail.initiator && incomingEmail.initiator.id ? incomingEmail.initiator.id : incomingEmail.customerId,
    },
    content: incomingEmail.content,
  });
}
