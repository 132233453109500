import _ from 'lodash';
import Conversation from 'models/conversation';
import ConversationItem from 'models/conversation_item';
import Factory from 'factories/all';
import factoryModels from './factory_models';

class ModelFactory extends Factory {
  static defineFromPlainFactory(plainFactory, name, constructor) {
    let attributes = (attrs, options) => _.cloneDeep(plainFactory.attributes(attrs, options));
    return _.extend(ModelFactory.define(name, constructor), _.omit(plainFactory, 'construct', 'attributes'), {
      attributes,
    });
  }
}

ModelFactory.build = (name, attrs) => {
  if (!ModelFactory.factories[name]) {
    throw new Error(`ModelFactory not defined for [${name}]. Check spelling or factory_models.js`);
  }
  const attributes = ModelFactory.factories[name].attributes(attrs);
  return ModelFactory.factories[name].construct(attributes);
};

ModelFactory.factories = {};

let constructorsByFactory = _.reduce(factoryModels, (memo, v, k) => ({ ...memo, [k]: modelFromJs(v) }), {});

_.forEach(Factory.factories, (factory, name) => {
  if (constructorsByFactory[name]) {
    ModelFactory.defineFromPlainFactory(factory, name, constructorsByFactory[name]);
  }
});

function modelFromJs(Model) {
  if (Model === Conversation) {
    return conversationFromJs();
  }

  return attrs => Model.fromJs(attrs);
}

function conversationFromJs() {
  return function(attrs) {
    let conversationItems = (attrs.conversationItems || []).map(ci => ConversationItem.fromJs(ci));
    return Conversation.fromJs(attrs, conversationItems);
  };
}

export default ModelFactory;
