import {
  createPlateUI,
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_LIC,
} from '@udecode/plate';

import { InlineImage } from 'components/text_editor_new/plugins/inline_image/inline_image';
import { StyledLinkInline } from 'components/composer/inline_links/inline_link';
import { StyledP } from 'components/text_editor_new/plugins/paragraph_styles';
import {
  StyledAITextCompletionSelection,
  MARK_AI_TEXT_COMPLETION_SELECTION,
} from 'components/text_editor_new/plugins/ai/ai_text_completion';
import {
  StyledAITextCompletionHighlight,
  MARK_AI_TEXT_COMPLETION_HIGHLIGHT,
} from 'components/text_editor_new/plugins/ai/ai_text_completion/ai_text_completion_highlight';
import { MARK_LINK_SELECTION } from 'components/text_editor_new/plugins/inline_links/create_link_selection_plugin';
import { InlineLinkSelection } from 'components/text_editor_new/plugins/inline_links/inline_link_selection';
import { StyledLic, StyledOl, StyledUl } from 'components/text_editor_new/plugins/list_components';

// Overriding built-in components from plugin factories does not work.  So we
// override them here. See https://plate.udecode.io/docs/styling#plate-ui
const components = createPlateUI({
  [ELEMENT_IMAGE]: InlineImage,
  [ELEMENT_LINK]: StyledLinkInline,
  [ELEMENT_PARAGRAPH]: StyledP,
  [ELEMENT_UL]: StyledUl,
  [ELEMENT_OL]: StyledOl,
  [ELEMENT_LIC]: StyledLic,
  [MARK_AI_TEXT_COMPLETION_HIGHLIGHT]: StyledAITextCompletionHighlight,
  [MARK_AI_TEXT_COMPLETION_SELECTION]: StyledAITextCompletionSelection,
  [MARK_LINK_SELECTION]: InlineLinkSelection,
});

export default components;
