import createEnum from 'scripts/lib/create_enum';

const SelectAction = createEnum('ADD', 'REMOVE');
const TopicsMenuOptionType = createEnum(
  'COLLAPSER',
  'CUSTOM_ATTRIBUTE',
  'DIVIDER',
  'EXPANDER',
  'GROUP_TITLE',
  'OPTION',
  'TOPIC'
);
const COLLAPSE_SELECTED_OPTIONS_NUM = 5;

export { COLLAPSE_SELECTED_OPTIONS_NUM, SelectAction, TopicsMenuOptionType };
