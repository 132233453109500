import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spaceFor } from 'components/space_helper';

const InsetContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin: 0;
  padding: ${p => spaceFor('inset', p)};
`;

InsetContainer.propTypes = {
  inset: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
};
/** @component */
export default InsetContainer;
