import qconsole from 'scripts/lib/qconsole';

export class TwilioProxy {
  get impl() {
    return this._impl;
  }

  set impl(impl) {
    if (this._impl) {
      this._impl.Device.destroy();
    }

    this._impl = impl;
  }

  get Device() {
    return new TwilioDeviceProxy(this);
  }
}

class TwilioDeviceProxy {
  constructor(twilioProxy) {
    this.twilioProxy = twilioProxy;
  }

  setup(token, parameters) {
    if (this.twilioProxy.impl) {
      this.twilioProxy.impl.Device.setup(token, parameters);
      return true;
    }
    qconsole.log(`[twilio] could not call 'setup' on the device`);
    return false;
  }

  destroy() {
    if (this.twilioProxy.impl) {
      this.twilioProxy.impl.Device.destroy();
    } else {
      qconsole.log(`[twilio] could not call 'destroy' on the device`);
    }
  }

  disconnectAll() {
    if (this.twilioProxy.impl) {
      this.twilioProxy.impl.Device.disconnectAll();
    } else {
      qconsole.log(`[twilio] could not call 'disconnectAll' on the device`);
    }
  }

  status() {
    if (this.twilioProxy.impl) {
      return this.twilioProxy.impl.Device.status();
    } else {
      qconsole.log(`[twilio] could not call 'status' on the device`);
    }
  }

  on(event, cb) {
    if (this.twilioProxy.impl) {
      this.twilioProxy.impl.Device.on(event, cb);
    } else {
      qconsole.log(`[twilio] could not call 'on' for event [${event}] on the device`);
    }
  }
}
