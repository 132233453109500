import './lib/uuid';
import { Factory } from 'rosie';

Factory.define('VoiceConfiguration');

Factory.define('VoiceConfigurationWithDefaults')
  .extend('VoiceConfiguration')
  .attr('callWrapUpIntervalSecs', 60)
  .attr('declineCallAutoReadyIntervalSecs', 20)
  .attr('disableDeclineCall', false)
  .attr('hotelingEnabled', false)
  .attr('shouldAutoOpenCallNote', true);
