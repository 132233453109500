import React, { useContext } from 'react';
import EmailFillIcon from 'components/lib/icons/email_fill_icon';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function EmailRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.email;
  const emailSla = sla.email;
  return (
    <FloorboardRow
      activeItems={stats.currentOpenEmails}
      activeLabel="Open Emails"
      currentWait={stats.currentWait}
      icon={<EmailFillIcon />}
      newItems={stats.currentNewEmails}
      newLabel="New Emails"
      sla={emailSla}
      waitLabel="Oldest Unanswered Email"
    />
  );
}
