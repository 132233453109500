import Immutable from 'immutable';
import liveboardFromJs from 'models/liveboards/liveboard_from_js';

export default {
  toImmutable(entity) {
    return Immutable.fromJS(entity && entity.toJs());
  },

  fromImmutable(immutable) {
    return liveboardFromJs(immutable.toJS());
  },
};
