import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export default class Scroller extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleScroll', 'setRef']);
  }

  render() {
    return (
      <StyledScroller
        data-aid="conversationHistory-scroller"
        onScroll={this.handleScroll}
        ref={this.setRef}
        style={{ height: this.props.height, width: this.props.width, ...this.props.style }}
      >
        {this.props.children}
      </StyledScroller>
    );
  }

  handleScroll(evt) {
    if (!this.scroller) return;
    this.props.onScroll(evt);
  }

  scrollTop(top) {
    if (!this.scroller) return null;
    if (top != null) {
      this.scroller.scrollTop = top;
    }
    return this.scroller.scrollTop;
  }

  setRef(node) {
    this.scroller = node;
  }
}

const StyledScroller = styled.div`
  overflow: auto;
  position: relative;
  width: 100%;
  -webkit-overflow-scrolling: touch;
`;

Scroller.propTypes = {
  children: PropTypes.node,
  height: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
  style: PropTypes.object,
  width: PropTypes.number.isRequired,
};

Scroller.defaultProps = {
  style: {},
};
