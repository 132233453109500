import DisconnectNotification from 'models/notification/disconnect_notification';

export default class ShowDisconnectWarning {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.analytics.track('Modal - Disconnect Modal Shown', {
      reason: this.context.stores.connectionState.get().getDisconnectReason(),
    });

    this.context.stores.notifications.add(
      DisconnectNotification.create({ reason: this.context.stores.connectionState.get().getDisconnectReason() })
    );
  }
}
