import { Factory } from 'rosie';
import './lib/id';

Factory.define('Agent')
   .extend('Id');

Factory.define('AgentWithDefaults')
  .extend('Agent')
  .attr('name', ['id'], function (id) { return `Agent ${id}`;})
  .attr('avatarUrl', ['id'], function (id) { return `http://agents.com/avatar/${id}`; })
  .attr('email', ['id'], function (id) { return `agent_${id}@agents.com`; })
  .attr('twitter', ['id'], function (id) { return `@agent${id}`; })
  .attr('disabledAt', () => { return null; });
