import AgentActivity from 'models/agent_activity';
import { InboxConversationStatus } from 'models/location/inbox';
import InboxView from './lib/inbox_view';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenTeamInbox {
  constructor(context) {
    this.context = context;
    this.inboxView = new InboxView(context);
  }

  run({ conversationStatus, routingGroupId, sortOrder, topicId }) {
    this.inboxView.enter({ sortOrder, topicId }, inboxParams =>
      this._createInboxParams(inboxParams, { conversationStatus, routingGroupId })
    );

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.INBOX, routingGroupId });
  }

  _createInboxParams(inboxParams, { conversationStatus, routingGroupId }) {
    if (!routingGroupId) {
      let routingGroup = this._getDefaultRoutingGroup();
      routingGroupId = routingGroup && routingGroup.id;
    }

    if (!conversationStatus) {
      conversationStatus = InboxConversationStatus.NEW;
    }

    return { ...inboxParams, conversationStatus, isTeam: true, routingGroupId };
  }

  // return the first routing group the agent belongs to, or, if the agent has
  // no routing groups, the first routing group overall
  _getDefaultRoutingGroup() {
    let routingGroups = this.context.stores.routingGroups.findAll();

    return this.context.stores.currentAgent.get().getDefaultRoutingGroup(routingGroups);
  }
}
