import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('TaskCompletionContent').attr('type', 'TASK_COMPLETION');

Factory.define('TaskCompletionContentWithDefaults').extend('TaskCompletionContent').attr('taskItemId', 'id-123');

Factory.define('TaskCompletionContentItem').extend('ConversationItem').attr('content', ['content'], function(content) {
  return Factory.attributes('TaskCompletionContent', content);
});

Factory.define('TaskCompletionContentItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskCompletionContentWithDefaults', content);
  });
