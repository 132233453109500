import { faAt } from '@fortawesome/pro-solid-svg-icons/faAt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';

import AttachmentButton from 'components/lib/attachment/attachment_button';
import ComposerContext from 'components/composer/contexts/composer_context';
import { default as EmojiCore } from 'components/composer/shared/emoji';
import HotkeyLabel from 'components/hotkeys/hotkey_label';
import InlineImageButton from 'components/lib/inline_image_button';
import { Item, Menu, Separator } from 'components/customer/composition/lib/slate/slate_styles_menu.styles';
import { ORDERED_LIST, UNORDERED_LIST } from 'components/text_editor/plugins/lists/constants';
import QuotedTextIcon from 'components/common/icons/quoted_text_icon';
import { QUOTEBLOCK } from 'components/text_editor/plugins/quote_blocks';
import Tooltip from 'components/common/tooltip';
import InsertInlineAttachmentCompositionImage from 'actions/composition/insert_inline_attachment_composition_image';
import StartUpload from 'actions/composition/start_upload';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const preventBlurring = evt => evt.preventDefault();

const Bold = styled(function({ className, editor, onChange, value }) {
  const toggleBold = useEditorChange(editor, onChange, () => editor.toggleMark('bold'));
  return (
    <Item
      className={className}
      data-aid="bold"
      isActive={isMarkActive(value, 'bold')}
      onClick={toggleBold}
      onMouseDown={preventBlurring}
      title="Bold"
    >
      B
    </Item>
  );
})`
  font-weight: bold;
`;

const Italic = styled(function({ className, editor, onChange, value }) {
  const toggleItalic = useEditorChange(editor, onChange, () => editor.toggleMark('italic'));
  return (
    <Item
      className={className}
      data-aid="italic"
      isActive={isMarkActive(value, 'italic')}
      onClick={toggleItalic}
      onMouseDown={preventBlurring}
      title="Italic"
    >
      i
    </Item>
  );
})`
  font-style: italic;
`;

const Underline = styled(function({ className, editor, onChange, value }) {
  const toggleUnderline = useEditorChange(editor, onChange, () => editor.toggleMark('underline'));
  return (
    <Item
      className={className}
      data-aid="underline"
      isActive={isMarkActive(value, 'underline')}
      onClick={toggleUnderline}
      onMouseDown={preventBlurring}
      title="Underline"
    >
      U
    </Item>
  );
})`
  text-decoration: underline;
`;

export function OrderedList({ editor, onChange, value }) {
  const toggleOrderedList = useEditorChange(editor, onChange, () => editor.toggleList('ordered_list'));
  return (
    <Item
      data-aid="orderedList"
      isActive={isListActive(value, 'ordered_list')}
      onClick={toggleOrderedList}
      onMouseDown={preventBlurring}
      title="Ordered list"
    >
      <i className="fa fa-list-ol" />
    </Item>
  );
}

export function UnorderedList({ editor, onChange, value }) {
  const toggleUnorderedList = useEditorChange(editor, onChange, () => editor.toggleList('unordered_list'));
  return (
    <Item
      data-aid="unorderedList"
      isActive={isListActive(value, 'unordered_list')}
      onClick={toggleUnorderedList}
      onMouseDown={preventBlurring}
      title="Unordered list"
    >
      <i className="fa fa-list-ul" />
    </Item>
  );
}

export function Quote({ editor, onChange, value }) {
  const toggleQuote = useEditorChange(editor, onChange, () => editor.toggleQuote());

  return (
    <Tooltip delay={500} message="Quote Text" position="top">
      <Item data-aid="quote" isActive={isQuotedTextActive(value)} onClick={toggleQuote} onMouseDown={preventBlurring}>
        <QuotedTextIcon />
      </Item>
    </Tooltip>
  );
}

export function Attachment({ allowedFileTypes = '', multiple = true }) {
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();
  const uploadFile = useCallback(
    file =>
      executeAction(StartUpload, {
        compositionId: composition.id,
        file,
      }),
    [composition.id, executeAction]
  );

  const onChange = useCallback(
    evt => {
      const files = evt.target.files;
      for (let i = 0; i < files.length; i++) {
        uploadFile(files[i]);
      }
      evt.target.value = null;
    },
    [uploadFile]
  );

  return <AttachmentButton allowedFileTypes={allowedFileTypes} multiple={multiple} onAttach={onChange} />;
}

export function InlineImage({ editorRef, onChange }) {
  const inlineImagesButtonRef = useRef(null);
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();

  const onInsertImage = useCallback(
    upload => {
      const editor = editorRef.current;
      executeAction(InsertInlineAttachmentCompositionImage, {
        compositionId: composition.id,
        insertImage: editor.insertImage,
        onChange,
        upload,
      });
    },
    [editorRef, composition]
  );

  const startUpload = useCallback(
    file => {
      executeAction(StartUpload, {
        compositionId: composition.id,
        file,
        isInline: true,
        onInsertImage,
      });
    },
    [composition, onInsertImage, executeAction]
  );

  const onInsertImageInputChange = useCallback(() => {
    for (let i = 0; i < inlineImagesButtonRef.current.input.files.length; i++) {
      const f = inlineImagesButtonRef.current.input.files[i];
      if (f.type.startsWith('image/')) {
        startUpload(f);
      }
    }
  }, [inlineImagesButtonRef]);

  return (
    <InlineImageButton
      accept="image/*"
      className="slateStylesMenu-image"
      onInsertImage={onInsertImageInputChange}
      ref={button => (inlineImagesButtonRef.current = button)}
    />
  );
}

export function Link({ editor, onChange, value }) {
  const toggleLink = useCallback(
    evt => {
      evt.preventDefault();
      onChange(editor.wrapInline({ type: 'link' }).blur());
    },
    [editor, onChange]
  );

  return (
    <Tooltip delay={500} message={<HotkeyLabel hotkey="ctrl+k" label="Insert Link" />} position="top">
      <Item data-aid="link" isActive={isInlineActive(value, 'link')} onClick={toggleLink} onMouseDown={preventBlurring}>
        <i className="fa fa-link" />
      </Item>
    </Tooltip>
  );
}

export function Emoji(props) {
  const editor = props.editorRef.current;
  return <EmojiCore editor={editor} {...props} />;
}

export function Mention({ editorRef }) {
  const hasMention = editorRef.current?.getAgentMentionText() !== null;
  const toggleMention = useCallback(() => {
    if (hasMention && editorRef.current) {
      editorRef.current.removeAgentMentionSearch();
    } else if (editorRef.current) {
      editorRef.current.startAgentMentionSearch();
    }
  }, [hasMention]);

  return (
    <Item data-aid="mention" isActive={hasMention} onClick={toggleMention} onMouseDown={preventBlurring}>
      <FontAwesomeIcon icon={faAt} />
    </Item>
  );
}

export function useEditorChange(editor, onChange, callback) {
  return useCallback(
    evt => {
      evt.preventDefault();
      editor.focus();
      return onChange(callback());
    },
    [callback, editor, onChange]
  );
}

function isMarkActive(value, style) {
  return value.activeMarks.some(mark => mark.type === style);
}

function isListActive(value, listType) {
  let document = value && value.document;
  return value.blocks.some(block => {
    const closestList = document.getClosest(
      block.key,
      parent => parent.type === UNORDERED_LIST || parent.type === ORDERED_LIST
    );
    return closestList && closestList.type === listType;
  });
}

function isQuotedTextActive(value) {
  return value.blocks.some(block => {
    return !!value.document.getClosest(block.key, parent => parent.type === QUOTEBLOCK);
  });
}

function isInlineActive(value, type) {
  return value.inlines.some(inline => inline.type === type);
}

export { Bold, Italic, Underline, Menu, Separator };
