import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isFunction } from 'lodash';
import qconsole from 'scripts/lib/qconsole';

import BubbleContent from 'components/customer/conversation_history/conversation_items_v2/content/bubble_content';
import { CoBrowsingSessionStatus } from 'models/cobrowsing_session_data';
import ConversationItem from 'models/conversation_item';
import ItemContent from 'components/customer/conversation_history/conversation_items_v2/item_content';
import ItemMetadata from 'components/customer/conversation_history/conversation_items_v2/item_metadata';
import Tooltip from 'components/common/tooltip';
import useCopied from 'components/hooks/use_copied';
import { WidgetActivityTypes } from 'models/widget_activity';

const CoBrowsingSessionLabel = {
  [CoBrowsingSessionStatus.NEW]: 'session created',
  [CoBrowsingSessionStatus.ACTIVE]: 'session started',
  [CoBrowsingSessionStatus.ENDED]: 'session ended',
};

const CoBrowsingWidgetActivity = ({ item, className }) => {
  if (item.content.activityType !== WidgetActivityTypes.COBROWSE) return null;

  const isAuditItem = item.content.status === CoBrowsingSessionStatus.ENDED;
  return (
    <ItemContent isAuditItem={isAuditItem} item={item}>
      <ItemMetadata
        item={item}
        subject={getSubject(item.content)}
        text={CoBrowsingSessionLabel[item.content.status] || item.content.status}
        timestamp={item.timestamp}
      />
      {renderContent(item, className)}
    </ItemContent>
  );
};

function getSubject(content) {
  return `${content.sourceName} Co-browsing`;
}

function renderContent(item, className) {
  switch (item.content.status) {
    case CoBrowsingSessionStatus.NEW:
      return <SessionCreatedContent className={className} item={item} />;

    case CoBrowsingSessionStatus.ACTIVE:
      return <SessionStartedContent className={className} item={item} />;

    default:
      return null;
  }
}

function SessionCreatedContent({ item, className }) {
  const [showPinCopied, onPinCopied] = useCopied();
  const [showInviteCopied, onInviteCopied] = useCopied();

  const inviteURL = get(item.content.meta, 'inviteURL', '');
  const pin = get(item.content.meta, 'pin', '');
  if (!inviteURL && !pin) return null;

  const copyToClipboard = (value, onCopy) => evt => {
    evt.preventDefault();
    if (!value || !navigator.clipboard) return;

    navigator.clipboard
      .writeText(value)
      .then(() => {
        if (isFunction(onCopy)) onCopy();
      })
      .catch(err => {
        qconsole.error('CoBrowse Activity Item: no write access to the clipboard', err);
      });
  };

  const pinFragment = pin ? (
    <div>
      <span>Session PIN: </span>
      <Tooltip delay={250} message={showPinCopied ? 'PIN copied' : 'Copy to clipboard'} position="top">
        <span className="pin">
          <a href="#" onClick={copyToClipboard(pin, onPinCopied)} rel="nofollow noopener">
            {pin}
          </a>
        </span>
      </Tooltip>
    </div>
  ) : null;

  const inviteLinkFragment = inviteURL ? (
    <div>
      <span>Invite link: </span>
      <Tooltip delay={250} message={showInviteCopied ? 'Link copied' : 'Copy to clipboard'} position="top">
        <span className="invite-url">
          <a href={inviteURL} onClick={copyToClipboard(inviteURL, onInviteCopied)} rel="nofollow noopener">
            {inviteURL}
          </a>
        </span>
      </Tooltip>
    </div>
  ) : null;

  const classes = classnames('widget-activity-content', 'widget-activity-cobrowse', 'session-created', className);
  return (
    <BubbleContent className={classes} data-aid={`cobrowsingSessionCreated-${item?.id}`} item={item}>
      <div className="content-wrapper">
        {pinFragment}
        {inviteLinkFragment}
      </div>
    </BubbleContent>
  );
}

function SessionStartedContent({ item, className }) {
  const recordingUrl = get(item.content.meta, 'recordingUrl', '');
  const sessionId = get(item.content.meta, 'sessionId', '');
  if (!recordingUrl || !sessionId) return null;

  const classes = classnames('widget-activity-content', 'widget-activity-cobrowse', 'session-started', className);
  return (
    <BubbleContent className={classes} data-aid={`cobrowsingSessionStarted-${item?.id}`} item={item}>
      <div className="content-wrapper">
        <span>View recording for </span>
        <span className="recording-url">
          <a href={recordingUrl} rel="noopener nofollow noreferrer" target="_blank">
            ScreenMeet session - {sessionId}
          </a>
        </span>
      </div>
    </BubbleContent>
  );
}

CoBrowsingWidgetActivity.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default CoBrowsingWidgetActivity;
