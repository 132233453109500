import React from 'react';
import PropTypes from 'prop-types';

import componentFromItemContent from 'components/customer/conversation_history/component_from_item_content';
import ConversationItem from 'models/conversation_item';
import { createConversationItemFromItemLink } from 'models/item_link_content';

export default function LinkedItem({ item }) {
  let linkedItem = createConversationItemFromItemLink(item);
  if (!linkedItem) {
    return null;
  }

  let ItemComponent = componentFromItemContent(item.content.originalContent);
  return (ItemComponent && <ItemComponent className="linkedItem" item={linkedItem} />) || null;
}

LinkedItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
