import React from 'react';

export default function Mail() {
  return (
    <svg className="availabilityIcon availabilityIcon-mail" data-aid="mail-selected" height="32" width="32">
      <g fill="none" fillRule="evenodd">
        <rect height="29" rx="14.5" stroke="#111" transform="translate(1 1)" width="29" x=".5" y=".5" />
        <g fill="#111">
          <path d="M15.527 8v7.454l-1.437-1.426-.566.562 2.4 2.382 2.404-2.383-.565-.564-1.436 1.424V8z" />
          <path d="M20.27 16.806l2.719.003v5.02H9.014l-.003-5.021 2.667.002c.18 1.257 1.848 3.021 4.277 3.021 2.43 0 4.12-1.768 4.316-3.025zM9.768 15.804h-.264.264zm9.548 0l-.01.491c-.007.259-.929 2.532-3.35 2.532-2.413 0-3.302-2.264-3.304-2.522l-.004-.5h-1.87L8 15.801l.001.002.003 7.03H24v-6.997h-4.614v-.033h-.07z" />
        </g>
      </g>
    </svg>
  );
}
