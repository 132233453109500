import _ from 'lodash';
import moment from 'moment';

import BeginLogOut from 'actions/user/begin_log_out';
import DocumentStoreSaveResultHandler from '../lib/document_store_save_result_handler';
import { DomainError, InfrastructureError, Unauthorized, NotExistError } from 'scripts/application/lib/error';
import Err from 'models/err';
import ForgotPassword from 'models/location/forgot_password';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import LogIn from 'actions/user/log_in';
import LogOut from 'actions/user/log_out';
import mixin from 'scripts/lib/mixin';
import qconsole from 'scripts/lib/qconsole';
import RefreshAuthToken from 'actions/user/refresh_auth_token';
import { replaceAuth } from 'actions/user/lib/auth';
import UnauthorizedHandler from 'actions/lib/unauthorized_handler';
import UserCredential from 'models/user_credential';

export default class AuthenticationGatewayObserver {
  constructor(context) {
    this.context = context;
    this.silentErrorHandler = new GatewayErrorSilentHandler(context);
    this.unauthorizedHandler = new UnauthorizedHandler(context);
  }

  get store() {
    return this.context.stores.userCredential;
  }

  handleLoginError(ctx, err) {
    if (err instanceof Unauthorized) {
      if (_.find(_(err.response).get('errors'), e => e.code === UserCredential.LoginErrorCodes.PASSWORD_EXPIRED)) {
        this.context.router.navigateTo(ForgotPassword.create());
        this.handleSetErrors(err.response);

        return;
      }

      this.handleSetErrors(
        createErrInvalidDto(_(ctx).get('params.token') ? 'invalid or expired token' : 'invalid email or password')
      );
      return;
    }

    if (err instanceof DomainError) {
      this.handleSetErrors({ errors: err.errors });
      return;
    }

    this.handleSetErrors(createErrUnexpectedDto('try again later'));

    if (!(err instanceof InfrastructureError)) {
      this.silentErrorHandler.reportError(ctx, err);
    }
  }

  handleLoginSuccess(ctx, claims) {
    this.handleSetSuccess();
    this.context.executeAction(LogIn, { claims });
  }

  handleLogoutError(ctx, err) {
    if (err instanceof Unauthorized || err instanceof NotExistError) {
      this.context.executeAction(LogOut);
      return;
    }

    this.silentErrorHandler.handleInfrastructureErrors(ctx, err);
  }

  handleLogoutSuccess() {
    this.context.executeAction(LogOut);
  }

  handleRequestPasswordResetError(ctx, err) {
    this.silentErrorHandler.handleInfrastructureErrors(ctx, err);
  }

  handleRequestPasswordResetSuccess(ctx) {
    qconsole.log('Successfully requested password reset');
  }

  handleRequestTokenRefreshError(ctx, err) {
    if (err instanceof Unauthorized) {
      this.cancelTokenRefresh();
      this.unauthorizedHandler.handleUnauthorized(ctx, err);
    }
  }

  handleRequestTokenRefreshSuccess(ctx, claims) {
    if (claims.exp <= moment().unix()) {
      qconsole.log('New auth token is expired: local clock too fast?');
      return;
    }

    let auth = replaceAuth(this.context, { claims });
    if (!auth) {
      qconsole.warn('New auth token is invalid. Logging out.');
      this.cancelTokenRefresh();
      this.context.executeAction(BeginLogOut);
      return;
    }

    if (auth.isUserActivated() && !auth.isPasswordBeingReset()) {
      this.cancelTokenRefresh();
      this.context.scheduler.executeEvery(auth.getRefreshDurationMS(), RefreshAuthToken.jobId, RefreshAuthToken);
    }
  }

  cancelTokenRefresh() {
    this.context.scheduler.cancel(RefreshAuthToken.jobId);
  }
}

mixin(AuthenticationGatewayObserver.prototype, DocumentStoreSaveResultHandler.prototype);

function createErrInvalidDto(detail) {
  return { errors: [{ detail, code: Err.Code.INVALID }] };
}

function createErrUnexpectedDto(detail) {
  // somehow we ended up using `invalid` for unexpected errors. Would be great to clean that up at some point.
  return { errors: [{ detail, code: Err.Code.INVALID }] };
}
