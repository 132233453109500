import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('RoutingGroup')
  .extend('Uuid');

Factory.define('RoutingGroupWithDefaults')
  .extend('RoutingGroup')
  .attr('name', 'RoutingGroup Name')
  .attr('slaSeconds', 60 * 60) //Default SLA Time set to 60 minutes
  .attr('agentIds', []);
