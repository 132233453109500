import WebhookEditor from 'models/location/integrations/webhook_editor';
import WebhooksAdmin from 'models/location/integrations/webhooks_admin';

import CloseWebhooks from 'actions/integrations/webhooks/close_webhooks';
import OpenNewWebhook from 'actions/integrations/webhooks/open_new_webhook';
import OpenWebhook from 'actions/integrations/webhooks/open_webhook';
import OpenWebhooks from 'actions/integrations/webhooks/open_webhooks';

import { registerUrlConverter } from './location_url';
import StaticUrlConverter from './url_converters/static_url_converter';

registerUrlConverter(WebhooksAdmin, StaticUrlConverter.create('/webhooks'));
registerUrlConverter(WebhookEditor, { getPath: e => `/webhooks/${e.webhookId || 'new'}` });

export default function(router, { runnerContext }) {
  return {
    on: () => {
      runnerContext.executeAction(OpenWebhooks);
    },
    '/new': {
      on: () => {
        runnerContext.executeAction(OpenNewWebhook);
      },
      after: () => {
        // TODO: cleanup
      },
    },
    '/:webhookId': {
      on: webhookId => {
        runnerContext.executeAction(OpenWebhook, webhookId);
      },
      after: () => {
        // TODO: cleanup
      },
    },
    after() {
      runnerContext.executeAction(CloseWebhooks);
    },
  };
}
