import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'Boot',
  properties: {
    isCompleted: Boolean,
    isFromSso: Boolean,
    requestedPath: String,
  },

  complete() {
    this.isCompleted = true;
  },
});
