import { useEffect, useRef } from 'react';

import analytics from 'scripts/lib/analytics';
import connect from 'components/lib/connect';

// CustomerPageViewedTracker is a renderless component used solely to track the `Customer Page Viewed`
// and `Customer Page Exited` events, along with metadata about the customer, once those have loaded.
const CustomerPageViewedTracker = props => {
  const previousCustomerIdRef = useRef(null);
  useEffect(() => {
    if (props.isCustomerLoading || previousCustomerIdRef.current === props.customerId) return;
    analytics.track('Customer Page Viewed', {
      customerId: props.customerId,
      countConversations: props.countConversations,
      isCustomerLinked: props.isCustomerLinked,
    });
    previousCustomerIdRef.current = props.customerId;

    return () => {
      analytics.track('Customer Page Exited', {
        customerId: props.customerId,
        countConversations: props.countConversations,
        isCustomerLinked: props.isCustomerLinked,
      });
    };
  }, [props.isCustomerLoading, props.customerId]);

  return null;
};

export default connect(({ getProvider }) => {
  const conversationsProvider = getProvider('conversations');

  return {
    isCustomerLoading: conversationsProvider.isLoading(),
    countConversations: conversationsProvider.findAll().length,
  };
})(CustomerPageViewedTracker);
