import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';
import StartAnswerSearch from './start_answer_search';

export default class SetSearchAudiences {
  constructor(context) {
    this.context = context;
  }

  run({ audiences }) {
    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.setSearchAudiences(audiences);
    this.context.stores.answerPanel.set(answerPanel);

    const preferences = this.context.stores.agentPreferences.get();
    preferences.setAnswerSearchAudiences(audiences);
    setAgentPreferences(this.context, preferences);

    this.context.executeAction(StartAnswerSearch);
  }
}
