import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class RelationshipLabelService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(relationshipAttrs, callback, path, { orgId, customerId }) {
    let relationshipLabels = this._getRelationshipLabels(orgId, customerId);

    callback(null, { status: 200, statusText: statusText(200), response: relationshipLabels });
  }

  _getRelationshipLabels(orgId, customerId) {
    let database = this.getDatabase(orgId);

    return database.relationshipLabels || [];
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/relationship-labels': {
          GET: this.find,
        },
      },
      this
    );
  }
}
