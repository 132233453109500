import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'KbAdmin',
  properties: {
    channel: String,
    language: String,
    query: String,
    audiences: [String],
  },

  statics: {
    breadcrumb: 'Answers',
  },
});

export const MIN_ANSWER_SEARCH_LEN = 3;
