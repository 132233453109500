import Err from 'models/err';
import { PLACEHOLDER } from '../plugins/placeholders';
import { VARIABLE } from '../plugins/variables';

export default function validateEditor(value) {
  const { document } = value;
  const errors = [];

  const placeholderInlines = document.getInlinesByType(PLACEHOLDER);
  if (placeholderInlines.count() > 0) {
    errors.push(new Err({ attr: 'content', code: Err.INVALID, detail: 'there are unfilled fields in the editor' }));
  }

  const variableInlines = document.getInlinesByType(VARIABLE);
  const hasEmptyVariables = variableInlines.find(variable => {
    return variable.data.get('isEmpty');
  });
  if (hasEmptyVariables) {
    errors.push(new Err({ attr: 'content', code: Err.INVALID, detail: 'there are invalid variables in the editor' }));
  }

  return errors;
}
