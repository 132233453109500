import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ConversationTopicSelectionToggle } from 'components/customer/conversation_history/topics_v2/conversation_topic_selection_expander_v2';

export default function ConversationTopicSelectionCollapserV2({ isFocused, onClick, onMouseEnter, option }) {
  const className = classnames({ 'toggle-focused': isFocused });

  return (
    <ConversationTopicSelectionToggle
      className={className}
      data-aid="conversation-menu-topic-collapser"
      onMouseDown={onClick}
      onMouseEnter={handleMouseEnter}
    >
      Show Fewer
      <i className="conversationTopic-chevron fa fa-chevron-up" />
    </ConversationTopicSelectionToggle>
  );

  function handleMouseEnter() {
    onMouseEnter(option);
  }
}

ConversationTopicSelectionCollapserV2.propTypes = {
  isFocused: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  option: PropTypes.object,
};
