import _ from 'lodash';

import EditableUser from 'models/editable_user';
import NavigateBack from 'actions/current_location/navigate_back';
import UserEditor from 'models/location/user_editor';

/* When we load the user editor page directly, we're dependent on having loaded the users, agent profiles,
 * and routing groups beforehand. Once we have all three loaded, we can set the EditableUser in the
 * userEditor store with all of the values that constitute a fully saturated "user" model.
 */
export default class UserEditorUpdater {
  constructor(context) {
    this.context = context;
  }

  // When we are loading a user's page, we may not have loaded the necessary stores yet. So we wait until
  // all the agent profiles / users / routingGroups stores are loaded before setting the user editor editable user.
  update() {
    if (!this.isEditingUser()) {
      return;
    }

    if (
      this.context.stores.agentProfiles.isLoading() ||
      this.context.stores.users.isLoading() ||
      this.context.stores.routingGroups.isLoading()
    ) {
      return;
    }

    let userId = this.currentUserId;

    let userToEdit = this.context.stores.users.findBy({ id: userId });
    let profileToEdit = this.context.stores.agentProfiles.findBy({ id: userId });
    let routingGroupIds = this.context.stores.routingGroups.findAll().filter(rg => _.includes(rg.agentIds, userId));

    let newEditableUser = userId
      ? EditableUser.create(
          userToEdit,
          profileToEdit,
          routingGroupIds.map(rg => rg.id)
        )
      : EditableUser.create();
    this.userEditorStore.set(newEditableUser);
  }

  // When we successfully add a user, we need to wait until there are no errors from either the
  // users or agent profiles updates before letting the user return to the users admin page.
  remove(userId) {
    let editableUser = this.context.stores.userEditor.get();
    if (!editableUser || (userId && userId !== editableUser.id)) {
      return;
    }

    if (
      this.context.stores.agentProfiles.isPendingNew() ||
      this.context.stores.users.isPendingNew() ||
      (userId && this.context.stores.agentProfiles.isPending(userId)) ||
      (userId && this.context.stores.users.isPending(userId)) ||
      this.userEditorStore.getErrors().length
    ) {
      return;
    }

    this.context.stores.userEditor.remove();

    let currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation instanceof UserEditor) {
      this.context.executeAction(NavigateBack);
    }
  }

  setErrors(userId, errors) {
    if (!this.isEditingUser()) {
      return;
    }

    if (userId && this.currentEditingUser.id !== userId) {
      return;
    }

    let currentErrors = this.userEditorStore.getErrors();
    if (currentErrors.length) {
      errors = _.uniq(currentErrors.concat(errors));
    }

    this.userEditorStore.setErrors(errors);
    this.userEditorStore.resetPending();
  }

  isEditingUser() {
    let currentLocation = this.context.stores.currentLocation.get();
    return currentLocation instanceof UserEditor;
  }

  isEnabled() {
    let appFeatures = this.context.stores.appFeatures.get();
    return !!(appFeatures && appFeatures.isEnabled('usersPageV2'));
  }

  get currentUserId() {
    return this.context.stores.currentLocation.get().userId;
  }

  get currentEditingUser() {
    return this.userEditorStore.get();
  }

  get userEditorStore() {
    return this.context.stores.userEditor;
  }
}
