// These two defaults are stand-in values until the server side pagination is implemented. "Page Size" is
// the max number of results we request from the backend at once
export const REQUEST_DEFAULTS = {
  PAGE: 1,
  PAGE_SIZE: 50,
};

export const IGNORE_ERROR_CODES = {
  integrationDisabledError: 'INTEGRATION_DISABLED',
  customerNotExistError: 'CUSTOMER_NOT_EXIST',
};
