import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import connect from 'components/lib/connect';
import DismissNotification from 'actions/notification/dismiss_notification';
import { iconClassFromSessionType } from 'components/lib/icon_classes';
import InteractionType from 'models/interaction_type';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import Notification from './notification';

function DeclinedSessionReassignmentNotification({
  customerName,
  notificationId,
  onCancel,
  onPrimaryAction,
  transfereeName,
  type,
}) {
  if (!notificationId) {
    return null;
  }
  return (
    <Notification contentClasses="declineSessionReassignmentNotification" notificationId={notificationId}>
      <div className="conversationNotification-topic">
        {renderIcon()}
        <div className="conversationNotification-notificationType">Assignment</div>
        <div className="conversationNotification-divider" />
        <div className="conversationNotification-topic-container-reassignment">
          <div className="conversationNotification-topic-name">{transfereeName}</div>
          <div className="conversationNotification-topic-reassignment"> isn't available to help </div>
          <div className="conversationNotification-topic-name">{customerName}</div>
        </div>
      </div>
      <div>
        <Button buttonType={ButtonTypes.TEXT} contrast data-aid="notification-button-dismiss" onClick={onCancel}>
          Dismiss
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          contrast
          data-aid="notification-button-primary"
          onClick={onClickPrimary}
        >
          Return to Customer
        </Button>
      </div>
    </Notification>
  );

  function onClickPrimary(ev) {
    ev.stopPropagation();
    onPrimaryAction();
  }

  function renderIcon() {
    return type === InteractionType.CHAT ? (
      <ChatIconOutline className="conversationNotification-chat-icon" />
    ) : (
      <i className={iconClassFromSessionType(type)} />
    );
  }
}

DeclinedSessionReassignmentNotification.propTypes = {
  customerName: PropTypes.string,
  notificationId: PropTypes.string,
  onPrimaryAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  transfereeName: PropTypes.string,
  type: PropTypes.string,
};

const DeclinedSessionReassignmentNotificationContainer = connect(
  mapStateToProps,
  mapExecuteToProps
)(DeclinedSessionReassignmentNotification);

function mapStateToProps({ getProvider }, { notification }) {
  let customerProfile = _.get(notification, 'profile');
  let customerName = _.get(customerProfile, 'name', 'Customer');
  let notificationId = _.get(notification, 'id');
  let transferee = getProvider('agents').findBy({ id: _.get(notification, 'transferee.agentId') });
  let type = _.get(notification, 'sessionType');

  return {
    customerName,
    notificationId,
    transfereeName: _.get(transferee, 'name', 'Agent'),
    type,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onPrimaryAction: () => {
      executeAction(DismissNotification, props.notification.id);
      executeAction(NavigateToConversation, {
        customerId: props.notification.profile.id,
        conversationId: props.notification.conversationId,
      });
    },
    onCancel: () => executeAction(DismissNotification, props.notification.id),
  };
}

export { DeclinedSessionReassignmentNotification };
export default DeclinedSessionReassignmentNotificationContainer;
