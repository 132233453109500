import _ from 'lodash';

export default class TypeReflect {
  // map is an array of [type, constructor, dtoConverter] tuples
  constructor(map) {
    this.map = map.map(c => {
      return {
        type: c[0],
        constructor: c[1],
        converter: c[2],
      };
    });
  }

  instanceToType(instance) {
    return this.find(i => instance instanceof i.constructor).type;
  }

  instanceClassToType(instanceClass) {
    return this.find(i => _.isEqual(instanceClass, i.constructor)).type;
  }

  typeToConstructor(type) {
    return this.find(i => type === i.type).constructor;
  }

  typeToConverter(type) {
    let match = _.find(this.map, item => item.type === type);
    return match && match.converter;
  }

  find(searchFn) {
    let found = this.map.find(searchFn);
    if (!found) {
      throw new Error('constructor/type mapping not found');
    }
    return found;
  }
}
