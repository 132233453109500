import AgentActivity from 'models/agent_activity';
import { InboxConversationStatus } from 'models/location/inbox';
import InboxView from './lib/inbox_view';
import OpenFirstInbox from 'actions/inbox/open_first_inbox';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenHome {
  constructor(context) {
    this.context = context;
    this.inboxView = new InboxView(context);
  }

  run({ conversationStatus, sortOrder, topicId }) {
    const appFeatures = this.context.stores.appFeatures.get();
    if (!appFeatures.isEnabled('internalAgentActions') && appFeatures.isEnabled('viewLiveboards')) {
      this.context.router.navigateToUrl('/liveboards');
      return;
    }

    if (!appFeatures.isEnabled('internalAgentActions') && appFeatures.isEnabled('kbManagement')) {
      this.context.router.navigateToUrl('/kb-admin');
      return;
    }

    if (
      !appFeatures.isEnabled('internalAgentActions') &&
      (appFeatures.isEnabled('viewReports') || appFeatures.isEnabled('reportBuilder'))
    ) {
      this.context.router.navigateToUrl('/reports');
      return;
    }

    if (
      !appFeatures.isEnabled('internalAgentActions') &&
      appFeatures.isEnabled('debuggingView') &&
      appFeatures.isEnabled('integrationsManagement')
    ) {
      this.context.router.navigateToUrl('/admin/apps');
      return;
    }

    this.context.analytics.track('Inbox Opened', { conversationStatus });
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.INBOX });

    const currentLocation = this.context.stores.currentLocation.get();
    if (
      appFeatures.isEnabled('groupInboxes') &&
      !this.context.stores.routingGroups.isLoading() &&
      !currentLocation.isTeam
    ) {
      this.context.executeAction(OpenFirstInbox);
      return;
    }

    this.inboxView.enter({ sortOrder, topicId }, inboxParams => createInboxParams(inboxParams, { conversationStatus }));
  }
}

function createInboxParams(inboxParams, { conversationStatus }) {
  if (!conversationStatus) {
    conversationStatus = InboxConversationStatus.OPEN;
  }

  return { ...inboxParams, conversationStatus, isTeam: false, routingGroupId: null };
}
