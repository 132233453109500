import createModel from './lib/create_model';

var ContentPlaceholder = createModel({
  modelName: 'ContentPlaceholder',

  properties: {
    isLoading: Boolean,
  },
});

export default ContentPlaceholder;
