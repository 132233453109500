import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('TaskCommentAPI').extend('Uuid');

Factory.define('TaskCommentAPIWithDefaults')
  .extend('TaskCommentAPI')
  .attr('id', 'task-item-id')
  .attr('comment', 'comment')
  .attr('agentId', 'agent-id');
