import _ from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Attachment from 'models/attachment';
import AttachmentModalContent from './attachment_modal_content';
import ArrowIcon from 'components/lib/icons/arrow_icon';
import Button, { ButtonTypes } from 'components/common/button';
import CustomerContext from 'components/customer/customer_context';
import { H2, H4 } from 'components/common/headers';
import ItemContext from 'components/customer/conversation_history/conversation_items_v2/item_context';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import RedactAttachment from 'actions/attachments/redact_attachment';
import Tooltip from 'components/common/tooltip';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useKeyboardShortcut from 'components/hooks/use_keyboard_shortcut';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function AttachmentModal({ attachment, attachments, setAttachment, url }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const canRedact = !attachment.isRedacted && isFeatureEnabled('complianceManagement');
  const [isRedacting, setIsRedacting] = useState(false);
  const onClose = useCallback(() => setAttachment(null), [setAttachment]);
  useResetIsRedactingWhenAttachmentChanges(attachment, setIsRedacting);

  const body = isRedacting ? (
    <ConfirmRedaction attachment={attachment} onCancel={() => setIsRedacting(false)} onClose={onClose} />
  ) : (
    <React.Fragment>
      <HeaderBar>
        <Overflow>
          <Title title={attachment.filename}>{attachment.filename}</Title>
        </Overflow>
        <Actions>
          {renderRedact()}
          {!attachment.isRedacted && <Download attachment={attachment} url={url} />}
          <Close onClick={onClose} title="Close">
            <FontAwesomeIcon icon={faTimes} />
          </Close>
        </Actions>
      </HeaderBar>
      <AttachmentModalContent attachment={attachment} url={url} />
    </React.Fragment>
  );

  return (
    <PortalModalWrapper>
      <Modal>
        <StyledModalCard onClose={onClose}>
          {body}
          <Arrows attachment={attachment} attachments={attachments} setAttachment={setAttachment} />
        </StyledModalCard>
      </Modal>
    </PortalModalWrapper>
  );

  function renderRedact() {
    if (!canRedact) {
      return (
        <Tooltip message="You must be a compliance admin to delete attachments">
          <Redact disabled title="Delete">
            <FontAwesomeIcon icon={faTrashAlt} />
          </Redact>
        </Tooltip>
      );
    }

    return (
      <Redact onClick={() => setIsRedacting(true)} title="Delete">
        <FontAwesomeIcon icon={faTrashAlt} />
      </Redact>
    );
  }
}

AttachmentModal.propTypes = {
  attachment: PropTypes.instanceOf(Attachment).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(Attachment)).isRequired,
  setAttachment: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

function useResetIsRedactingWhenAttachmentChanges(attachment, setIsRedacting) {
  useEffect(() => {
    setIsRedacting(false);
  }, [attachment]);
}

const StyledModalCard = styled(ModalCard)`
  position: relative;
`;

const Modal = styled.div`
  text-align: center;

  .modalCard-content {
    display: flex;
    flex-direction: column;
    max-height: 80%;
    max-width: 80%;
    padding: 0;
    position: relative;
  }
`;

const HeaderBar = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.gray200};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  height: 32px;
  justify-content: space-between;
`;

export const Title = styled(H4)`
  display: inline;
  margin-bottom: 0;
`;

const Overflow = styled.span`
  flex-shrink: 1;
  margin: 0 12px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
`;

export const Redact = styled.div`
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  font-size: 18px;
  padding: 0 12px;

  &:hover {
    color: ${p => p.theme.colors.red400};
  }
  ${p => p.disabled && disabledRedact}
`;

const disabledRedact = css`
  cursor: not-allowed;
  color: ${p => p.theme.colors.gray400};
  &:hover {
    color: ${p => p.theme.colors.gray400};
  }
`;

export const Close = styled.div`
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  font-size: 20px;
  height: 32px;
  padding: 0 12px;

  &:hover {
    color: ${p => p.theme.colors.gray700};
  }
`;

export function Download({ url }) {
  return (
    <DownloadLink download href={url} rel="nofollow" target="_blank" title="Download">
      <FontAwesomeIcon icon={faArrowToBottom} />
    </DownloadLink>
  );
}

Download.propTypes = {
  attachment: PropTypes.instanceOf(Attachment).isRequired,
  url: PropTypes.string.isRequired,
};

const DownloadLink = styled.a`
  color: ${p => p.theme.colors.gray600};
  font-size: 20px;
  padding: 0 12px;
`;

export function Arrows({ attachment, attachments, setAttachment }) {
  const index = _.findIndex(attachments, attachment);
  const isLeftEnabled = index > 0;
  const isRightEnabled = index < attachments.length - 1;
  useArrowShortcuts(attachments, index, setAttachment);

  return (
    <React.Fragment>
      {isLeftEnabled && <LeftArrow onClick={() => setAttachment(attachments[index - 1])} />}
      {isRightEnabled && <RightArrow onClick={() => setAttachment(attachments[index + 1])} />}
    </React.Fragment>
  );
}

Arrows.propTypes = {
  attachment: PropTypes.instanceOf(Attachment).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(Attachment)).isRequired,
  setAttachment: PropTypes.func.isRequired,
};

const leftArrowHotkey = Object.freeze({
  key: 'left',
  group: 'Attachments',
  label: 'Left',
});
const rightArrowHotkey = Object.freeze({
  key: 'right',
  group: 'Attachments',
  label: 'Right',
});

function useArrowShortcuts(attachments, index, setAttachment) {
  // We'll store the latest attachments / index in a ref so we never have to recreate the navigate left
  // or right callbacks.
  const attachmentsRef = useRef({ attachments, index });

  // Memoize these callbacks forever so we don't have to bind / rebind the keyboard shortcuts when the
  // current index (or I suppose the attachments) changes.
  const navigateLeft = useCallback(() => {
    const { attachments, index } = attachmentsRef.current;
    if (index > 0) {
      setAttachment(attachments[index - 1]);
    }
  }, [setAttachment]);
  const navigateRight = useCallback(() => {
    const { attachments, index } = attachmentsRef.current;
    if (index < attachments.length - 1) {
      setAttachment(attachments[index + 1]);
    }
  }, [setAttachment]);

  useKeyboardShortcut(leftArrowHotkey, navigateLeft);
  useKeyboardShortcut(rightArrowHotkey, navigateRight);

  // Update the ref only if attachments or index have changed.
  useEffect(() => {
    attachmentsRef.current = { attachments, index };
  }, [attachments, index]);
}

export const LeftArrow = styled.div.attrs({ children: <ArrowIcon /> })`
  cursor: pointer;
  height: 64px;
  position: absolute;
  left: -16px;
  top: 50%;
  transform: translate(-100%, -50%);
  width: 64px;

  .arrowIcon-circle {
    fill: rgba(0, 0, 0, 0.25);
  }
`;
export const RightArrow = styled.div.attrs({ children: <ArrowIcon facingRight /> })`
  cursor: pointer;
  height: 64px;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translate(100%, -50%);
  width: 64px;

  .arrowIcon-circle {
    fill: rgba(0, 0, 0, 0.25);
  }
`;

export function ConfirmRedaction({ attachment, onCancel, onClose }) {
  const { customerId } = useContext(CustomerContext);
  const { item } = useContext(ItemContext);

  const executeAction = useExecuteAction();
  const redactAttachment = useCallback(() => {
    executeAction(RedactAttachment, { attachmentId: attachment.id, customerId, itemId: item.id });
    onClose();
  }, [attachment.id]);

  return (
    <RedactContent>
      <RedactClose onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </RedactClose>
      <Text>
        <H2>Delete Attachment?</H2>
        <p>Are you sure you want to delete this attachment?</p>
      </Text>
      <RedactActions>
        <Button buttonType={ButtonTypes.TEXT} onClick={onCancel}>
          Cancel
        </Button>
        <DeleteButton onClick={redactAttachment}>Delete</DeleteButton>
      </RedactActions>
    </RedactContent>
  );
}

ConfirmRedaction.propTypes = {
  attachment: PropTypes.instanceOf(Attachment).isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const RedactContent = styled.div`
  padding: 16px;
  position: relative;
  ${H2} {
    display: inline;
  }
`;

const RedactClose = styled(Close)`
  position: absolute;
  right: 0;
  top: 0;
`;

const RedactActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => p.theme.spacing.large};
`;

const Text = styled.div``;

const DeleteButton = styled(Button).attrs({ buttonType: ButtonTypes.DANGER })`
  border: 1px solid ${p => p.theme.colors.gray300};
  margin-left: 8px;
`;
