import _ from 'lodash';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { AgentAvailability } from 'models/agent_status';
import connect from 'components/lib/connect';
import HotkeysModal from 'components/hotkeys/hotkeys_modal';
import ModalContainer from 'components/modals/modal_container';
import Navigation from 'components/root/navigation';
import NotificationsContainer from 'components/notification/notifications_container';
import PageContent from 'components/root/page_content';
import { SpinnerPortal } from 'components/common/full_page_spinner';
import Title from 'components/title';
import Toasts from 'components/toasts';
import { ToastCategory } from 'models/toast_deprecated';
import { ToastsContainer } from 'components/toasts_deprecated';
import { ToastPortalDeprecated } from 'components/common/toast_deprecated';
import TopBar from 'components/root/top_bar';
import UserAwayPage from 'components/user/user_away_page';

const RootContentContainer = connectRootContent(RootContent);

export default RootContentContainer;

export function RootContent({ binding, showAwayPage }) {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  return (
    <PageContainer isNavOpen={isNavigationOpen}>
      {showAwayPage ? (
        <UserAwayPage />
      ) : (
        <React.Fragment>
          <Navigation isOpen={isNavigationOpen} setIsOpen={setIsNavigationOpen} />
          <TopBar />
          <PageContent binding={binding} />
        </React.Fragment>
      )}
      <NonLayoutContent />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  height: 100%;

  .navigationMenu {
    transition: transform 0.38s ease, box-shadow 0.38s ease;
  }
  &::after {
    backdrop-filter: blur(4px);
    background-color: rgba(246, 246, 246, 0.5);
    bottom: 0;
    content: '';
    cursor: auto;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease;
    width: 100%;
    z-index: -1;
  }

  ${() => isWindows() && windowsStyles}
  ${p => p.isNavOpen && navOpenStyles}
`;

const navOpenStyles = css`
  overflow: hidden;
  .navigationMenu {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 20px rgba(0, 0, 0, 0.04), 0 0 80px rgba(0, 0, 0, 0.06);
    transform: translate3d(0, 0, 0);
  }
  .hamburger-spacer {
    width: 0;
  }
  .hamburger-wrapper {
    transform: translate3d(240px, 0, 0);
    transition: transform 0.3s 0.1s ease-out;
  }
  .hamburger {
    transition-delay: 0s;
    span:nth-child(1) {
      left: 50%;
      top: 10px;
      width: 0;
    }
    span:nth-child(2) {
      transform: rotate(45deg);
    }
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
    span:nth-child(4) {
      left: 50%;
      top: 10px;
      width: 0;
    }
  }
  &::after {
    opacity: 1;
    z-index: 8;
  }
`;

function isWindows() {
  return navigator.platform.indexOf('Win') !== -1;
}

const windowsScrollbarBorderRadius = '100px';

const windowsStyles = css`
  ::-webkit-scrollbar {
    background-color: ${p => p.theme.colors.white};
    width: 10px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    -webkit-border-radius: ${windowsScrollbarBorderRadius};
    background: rgba(0, 0, 0, 0.09);
    max-height: 20px;
    z-index: 3;
  }

  ::-webkit-scrollbar-thumb:vertical:hover {
    -webkit-border-radius: ${windowsScrollbarBorderRadius};
    background: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    min-height: 10px;
  }

  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0);
    width: 10px;

    ::-webkit-scrollbar-thumb:vertical:hover {
      -webkit-border-radius: ${windowsScrollbarBorderRadius};
      background: rgba(0, 0, 0, 0.1);
      background-clip: padding-box;
      min-height: 10px;
    }
  }

  ::-webkit-scrollbar-thumb:vertical:active {
    -webkit-border-radius: ${windowsScrollbarBorderRadius};
    background: rgba(0, 0, 0, 0.1);
  }
`;

export function NonLayoutContent() {
  return (
    <React.Fragment>
      <Title />
      <NotificationsContainer />
      <ModalContainer />
      <SpinnerPortal />
      <ToastPortalDeprecated />
      <HotkeysModal />
      <Toasts />
      <ToastsContainer category={ToastCategory.ACTIVITY_BANNER} />
      <ToastsContainer category={ToastCategory.TOAST} />
      <div id="modalPortal" />
    </React.Fragment>
  );
}

export function connectRootContent(rootContent) {
  return connect(mapStateToProps)(rootContent);
}

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  let currentStatus = getProvider('agentStatus').get();
  let pendingStatus = getProvider('agentStatus').getPending();

  const isAgentAway =
    getAvailability(currentStatus) === AgentAvailability.AWAY ||
    getAvailability(pendingStatus) === AgentAvailability.AWAY;
  const isAgentReturningFromIdle =
    getAvailability(currentStatus) === AgentAvailability.AWAY &&
    !_(currentStatus).get('status.reasonId') &&
    getAvailability(pendingStatus) === AgentAvailability.BUSY;
  return {
    ...props,
    showAwayPage: isAgentAway && !isAgentReturningFromIdle,
  };

  function getAvailability(status) {
    return _(status).get('status.availability');
  }
}
