import React from 'react';
import PropTypes from 'prop-types';

import TextEditor from 'components/text_editor_new';
import { useFocusFirstPlaceholder } from 'components/text_editor_new/plugins/placeholders';
import { withUseAnswerBody, withUseAnswerSubject } from 'components/text_editor_new/lib/with_use_answers';
import { useUpdateVariables } from 'components/text_editor_new/plugins/variables';

/**
 * General purpose text editor used in most composers
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function ComposerEditor(props) {
  return <TextEditorWithAnswerBodyMemo {...props} />;
}

/**
 * This flavor is specific to the email composer and is used for the subject line
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function ComposerSubjectLineEditor(props) {
  return <TextEditorWithAnswerSubjectMemo {...props} />;
}

/**
 * "Base" text editor that gets wrapped with helpers to form one of the editors above
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ComposerEditorBase(props) {
  const { editorRef } = props;
  const editor = editorRef.current;

  useUpdateVariables(editor);
  useFocusFirstPlaceholder(editor);

  return <TextEditor {...props} />;
}

ComposerEditorBase.propTypes = {
  editorRef: PropTypes.shape({ current: PropTypes.object }),
};

const TextEditorWithAnswerBodyMemo = React.memo(withUseAnswerBody(ComposerEditorBase));
const TextEditorWithAnswerSubjectMemo = React.memo(withUseAnswerSubject(ComposerEditorBase));
