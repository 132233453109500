import DtoConverter from 'scripts/application/dto_converters/customer_note_converter';
import qconsole from 'scripts/lib/qconsole';

export default class CustomerNoteGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onBroadcast(payload) {
    if (this._isCustomerLoaded(payload.id)) {
      let noteStore = this.context.stores.customers.storesFor(payload.id).note;
      noteStore.set(DtoConverter.fromDto(payload));
    }
  }

  onFetchSuccess(payload) {
    if (this._isCustomerLoaded(payload.id)) {
      let noteStore = this.context.stores.customers.storesFor(payload.id).note;
      noteStore.set(DtoConverter.fromDto(payload));
      noteStore.resetLoading();
    }
  }

  handleFetchError(errorDto) {
    qconsole.log(`Failed to fetch customer note ${JSON.stringify(errorDto)}`);
  }

  _isCustomerLoaded(customerId) {
    return this.context.stores.customers.has({ id: customerId });
  }
}
