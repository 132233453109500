import React from 'react';
import Icon from 'components/lib/icons/icon_container';

export default props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      clipRule="evenodd"
      d="M16 1.5a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V2a.5.5 0 0 1 .5-.5zm10.354 4.854a.5.5 0 0 0-.708-.708l-4 4a.5.5 0 0 0 .708.708l4-4zM24 15.5a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-.715 13.937a1.447 1.447 0 0 1-.84.48h-.002c-1.477.261-5.84.255-12.691-5.585L7.644 22.23C1.79 15.394 1.824 11.022 2.087 9.547c.059-.326.228-.622.48-.839l2.737-2.364a1.403 1.403 0 0 1 1.953.11l3.284 3.53a1.486 1.486 0 0 1 .015 1.999L8.85 13.869a1.495 1.495 0 0 0-.27 1.556c.344.853 1.514 2.528 3.081 4.2l.683.739c1.675 1.563 3.354 2.73 4.209 3.073a1.5 1.5 0 0 0 1.56-.269l1.89-1.703a1.494 1.494 0 0 1 2.005.015l3.537 3.276a1.397 1.397 0 0 1 .11 1.95l-2.37 2.731z"
      fillRule="evenodd"
    />
  </Icon>
);
