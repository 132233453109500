import createModel, { prop } from '../lib/create_model';
import NotificationType from './notification_type';
import { SessionCustomer } from '../active_session';

export default createModel({
  modelName: 'InboundMessageNotification',

  properties: {
    id: prop(String).isRequired, // AD only field
    conversationId: prop(String).isRequired,
    conversationItemId: prop(String).isRequired,
    messagePreview: prop(String),
    profile: prop(SessionCustomer).isRequired,
    sessionType: prop(String).isRequired,
  },

  updateMessagePreview(messagePreview) {
    this.messagePreview = messagePreview;
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.INBOUND_MESSAGE });
  },
});
