import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import ModalButton from 'components/modals/modal_button';
import Modal from 'components/lib/modal';
import ModalFooter from 'components/modals/modal_footer';

const ConfirmationModal = ({ body, className, onConfirm }) => (
  <Modal className={classnames(className, 'confirmationModal')}>
    <div className="confirmationModal-body">{body}</div>
    <ModalFooter>
      <ModalButton className="confirmationModal-button" onClick={onConfirm}>
        Ok
      </ModalButton>
    </ModalFooter>
  </Modal>
);

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  body: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ConfirmationModal;
