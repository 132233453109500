import _ from 'lodash';
import qs from 'qs';

const PARAM_REGEX = /(:[^/?&$]+)/g;

export default class TopicPattern {
  // pattern should be of the form: 'v1/orgs/:orgId/customer-profiles/:customerId'
  constructor(pattern) {
    this._pattern = pattern;
    this._paramNames = _.map(pattern.match(PARAM_REGEX), p => p.substr(1));
    this._paramRegex = new RegExp(`^${pattern.replace(PARAM_REGEX, '([^/?&]+)')}(?:\\?|$)`);
  }

  doesMatch(topic) {
    return this._paramRegex.test(topic);
  }

  // Actual topic should match the pattern string, except with all of the plus signs filled in.
  //   e.g. actualTopic = 'v1/orgs/qt9fnIItnt/customer-profiles/unft989fnt9t-unf'
  // This then returns an object mapping param names to values:
  //   => { orgId: 'qt9fnIItnt', customerId: 'unft989fnt9t-unf' }
  extractParams(actualTopic) {
    const matches = this._paramRegex.exec(actualTopic);
    if (!matches) {
      return null;
    }

    return matches.slice(1).reduce((memo, p, i) => {
      memo[this._paramNames[i]] = p;
      return memo;
    }, {});
  }

  parseQuery(actualTopic) {
    const [, query] = (actualTopic || '').split('?');
    return (query && qs.parse(query)) || {};
  }

  toString() {
    return this._pattern;
  }
}
