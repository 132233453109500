import _ from 'lodash';
import { Editor } from 'slate-old';

import createEmailTemplate from './create_email_template';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import PlainTextSerializer from 'components/text_editor/serializers/plaintext_serializer';

export default function hasForgottenToWriteStuff(plaintext, defaultBody, variables) {
  const emptyEditorValue = FullSerializer.deserialize('');
  const emptyEditor = new Editor({ value: emptyEditorValue });
  createEmailTemplate(emptyEditor, defaultBody, variables);

  if (stripWhitespace(plaintext) === stripWhitespace(PlainTextSerializer.serialize(emptyEditor.value))) {
    return true;
  } else if (stripWhitespace(plaintext) === '') {
    return true;
  }
  return false;
}

function stripWhitespace(plaintext) {
  const lines = plaintext.split(/\r?\n/);
  return _.map(lines, line => line.trim()).join('');
}
