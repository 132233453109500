import { clone, isEmpty, omitBy, trim } from 'lodash';
import qs from 'qs';

import Endpoint from './endpoint';
import HttpAdapter from './http_adapter';
import MqttAdapter from './mqtt_adapter';
import ObserverAdapter from './observer_adapter';
import RequestTracker from './request_tracker';

/**
 * StandardGateway represents a "standard" best-practice-based gateway.
 *
 * If you don't need anything special, you should be able to use this directly.
 * Otherwise, extend it or create a copy of it for your custom gateway and tweak
 * as necessary.
 *
 * See configure_gateways.js for numerous examples.
 */
export default class StandardGateway {
  /**
   * Create a StandardGateway
   *
   * @param {*} backend - an http/mqtt backend
   * @param {(string|Object)} endpoint - an endpoint configuration (see the
   * endpoint documentation)
   */
  constructor(backend, endpoint) {
    this.mqtt = new MqttAdapter(backend);
    this.http = new HttpAdapter(backend);
    this.endpoint = new Endpoint(endpoint);

    // `requests` tracks outstanding subscriptions, so the resources can be
    // re-fetched on reconnect (in case any broadcasts were missed while
    // disconnected)
    this.requests = new RequestTracker();
    this.requestAlls = new RequestTracker();
  }

  /**
   * Subscribes to the broadcast topic via mqtt then fetches the resource via
   * http.
   *
   * @param {object} [params] - the parameters to fill in the endpoint topic with.
   * @param {object} [query] - optional query parameters to add to the request
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, 'agents/:agentId');
   *
   * gateway.setObserver({
   *   onBroadcast: handleAgentBroadcast,
   *   onFetchSuccess: handleAgentFetch,
   * });
   *
   * gateway
   *  .request({ agentId: 'my-agent' })
   *  .then(agentDto => console.log(agentDto));
   *
   *  Note: if the query parameters were provided, they will be used when re-requesting
   *  the data on reconnect. The subscription topics by default ignore query parameters
   *  unless explicitly configured via endpoint attributes. Please note that using
   *  `request` and `requestAll` with query parameters may have a potential side effect
   *  of subscribing to a wider set of updates than the data returned from the call
   */
  request(params, query, httpOptions) {
    // track what has been requested, so it can be re-requested on reconnect
    this.requests.track(params, query);

    return this.subscribe(params).then(() => this.fetch(params, query, httpOptions));
  }

  /**
   * Subscribes to the broadcast topic via mqtt.
   *
   * @param {*} [params] - the parameters to fill in the endpoint topic
   * @returns {Promise} - a promise that resolves when the subscription is ack'd
   *
   * @example
   * let gateway = new StandardGateway(backend, 'agents/:agentId');
   *
   * gateway.setObserver({ onBroadcast: handleAgentBroadcast });
   *
   * gateway.subscribe({ agentId: 'my-agent' }).then(() => console.log('subscribed!'));
   */
  subscribe(params) {
    const endpoint = this.endpoint.set(params);
    return this._mqttSubscribeToTopic(endpoint.broadcastTopic, this.observer.onBroadcast).then(() =>
      this._mqttSubscribeToTopic(endpoint.broadcastDeleteTopic, this.observer.onBroadcastDelete)
    );
  }

  /**
   * Unsubscribes from the broadcast topic via mqtt.
   *
   * @param {*} [params] - the parameters to fill in the endpoint topic
   * @returns {Promise} - a promise that resolves immediately
   *
   * @example
   * let gateway = new StandardGateway(backend, 'agents/:agentId');
   *
   * gateway.setObserver({ onBroadcast: handleAgentBroadcast });
   *
   * gateway
   *   .subscribe({ agentId: 'my-agent' })
   *   .then(() => gateway.unsubscribe({ agentId: 'my-agent '}));
   */
  unsubscribe(params) {
    // remove any tracked requests, so they are not re-fetched on reconnect
    this.requests.remove(params);
    return this._mqttUnsubscribe(params);
  }

  /**
   * Uses http to GET the data at the endpoint's `fetchAllUrl`. See the Endpoint
   * documentation for how to configure an endpoint.
   *
   * @param {object} [params] - the parameters to fill in the endpoint url
   * @param {object} [query] - optional query parameters to add to the request url
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, {
   *   fetchAllUrl: '/api/v1/agents',
   * });
   *
   * gateway.setObserver({
   *   onFetchAllSuccess: handleFetchAllAgents,
   *   onFetchAllError: handleFetchAllAgentsError,
   * });
   *
   * gateway
   *   .fetchAll()
   *   .then(allAgentsDto => console.log(allAgentsDto));
   */
  fetchAll(params, query, httpOptions) {
    const localParams = clone(params);
    const localQuery = clone(query);
    const url = this.buildUrl(this.endpoint.set(params).fetchAllUrl, query);
    const resource = this.endpoint.patterns.fetchAllUrl;

    return this.http
      .get(url, httpOptions, resource)
      .then(dto => {
        this.observer.onFetchAllSuccess(dto, localParams, localQuery);
        return dto;
      })
      .catch(error => {
        this.observer.onFetchAllError(error, localParams, localQuery);
        throw error;
      });
  }

  /**
   * Uses http to GET the data at the endpoint's `fetchUrl`. See the Endpoint
   * documentation for how to configure an endpoint.
   *
   * @param {object} params - the parameters to fill in the endpoint url
   * @param {object} [query] - optional query parameters to add to the request url
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, {
   *   fetchUrl: '/api/v1/agents/:agentId',
   * });
   *
   * gateway.setObserver({
   *   onFetchSuccess: handleFetchAgent,
   *   onFetchError: handleFetchAgentError,
   * });
   *
   * gateway
   *   .fetch({ agentId: 'my-agent-id' })
   *   .then(agentDto => console.log(agentDto));
   */
  fetch(params, query, httpOptions) {
    const localParams = clone(params);
    const localQuery = clone(query);
    const url = this.buildUrl(this.endpoint.set(localParams).fetchUrl, localQuery);
    const resource = this.endpoint.patterns.fetchUrl;

    return this.http
      .get(url, httpOptions, resource)
      .then(dto => {
        this.observer.onFetchSuccess(dto, localParams, localQuery);
        return dto;
      })
      .catch(error => {
        this.observer.onFetchError(error, localParams, localQuery);
        throw error;
      });
  }

  /**
   * Uses http to POST the data to the endpoint's `addUrl`. See the Endpoint
   * documentation for how to configure an endpoint.
   *
   * @param {object} [params] - the parameters to fill in the endpoint url
   * @param {*} data - the data to POST to the url
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, {
   *   addUrl: '/api/v1/agents',
   * });
   *
   * gateway.setObserver({
   *   onAddSuccess: handleAddAgent,
   *   onAddError: handleAddAgentError,
   * });
   *
   * gateway
   *   .add({ id: 'my-id', name: 'My name' })
   *   .then(() => console.log('added!'));
   */
  add(params, data, httpOptions) {
    const localParams = clone(params);
    const url = this.endpoint.set(localParams).addUrl;
    const resource = this.endpoint.patterns.addUrl;

    return this.http
      .post(url, data || localParams, httpOptions, resource)
      .then(dto => {
        this.observer.onAddSuccess(dto, localParams);
        return dto;
      })
      .catch(error => {
        this.observer.onAddError(error, localParams);
        throw error;
      });
  }

  /**
   * Uses http to PUT the data to the endpoint's `replaceUrl`. See the Endpoint
   * documentation for how to configure an endpoint.
   *
   * By convention, a replace should include an entire new copy of the entity,
   * not a partial update (use `update` for that).
   *
   * @param {object} [params] - the parameters to fill in the endpoint url
   * @param {*} data - the data to PUT to the url
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, {
   *   replaceUrl: '/api/v1/agents/:agentId',
   * });
   *
   * gateway.setObserver({
   *   onReplaceSuccess: handleReplaceAgent,
   *   onReplaceError: handleReplaceAgentError,
   * });
   *
   * gateway
   *   .replace({ agentId: 'my-id' }, { id: 'my-id', name: 'My new name' })
   *   .then(() => console.log('replaced!'));
   */
  replace(params, data, httpOptions) {
    const localParams = clone(params);
    const url = this.endpoint.set(localParams).replaceUrl;
    const resource = this.endpoint.patterns.replaceUrl;

    return this.http
      .put(url, data || localParams, httpOptions, resource)
      .then(dto => {
        this.observer.onReplaceSuccess(dto, localParams);
        return dto;
      })
      .catch(error => {
        this.observer.onReplaceError(error, localParams);
        throw error;
      });
  }

  /**
   * Uses http to PATCH the data to the endpoint's `updateUrl`. See the Endpoint
   * documentation for how to configure an endpoint.
   *
   * By convention, the update should only publish that attributes that have
   * changed. Use a `replace` to replace an entire entity with a new version.
   *
   * @param {object} [params] - the parameters to fill in the endpoint url
   * @param {*} data - the data to PATCH to the url
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, {
   *   updateUrl: '/api/v1/agents/:agentId',
   * });
   *
   * gateway.setObserver({
   *   onUpdateSuccess: handleUpdateAgent,
   *   onUpdateError: handleUpdateAgentError,
   * });
   *
   * gateway
   *   .update({ agentId: 'my-id' }, { name: 'My new name' })
   *   .then(() => console.log('updated!'));
   */
  update(params, data, httpOptions) {
    const localParams = clone(params);
    const url = this.endpoint.set(localParams).updateUrl;
    const resource = this.endpoint.patterns.updateUrl;

    return this.http
      .patch(url, data || localParams, httpOptions, resource)
      .then(dto => {
        this.observer.onUpdateSuccess(dto, localParams);
        return dto;
      })
      .catch(error => {
        this.observer.onUpdateError(error, localParams);
        throw error;
      });
  }

  /**
   * Issues an http DELETE to the endpoint's `deleteUrl`. See the Endpoint
   * documentation for how to configure an endpoint.
   *
   * @param {object} [params] - the parameters to fill in the endpoint url
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, {
   *   deleteUrl: '/api/v1/agents/:agentId',
   * });
   *
   * gateway.setObserver({
   *   onDeleteSuccess: handleDeleteAgent,
   *   onDeleteError: handleDeleteAgentError,
   * });
   *
   * gateway
   *   .delete({ agentId: 'my-id' })
   *   .then(() => console.log('deleted!'));
   */
  delete(params, httpOptions) {
    const localParams = clone(params);
    const url = this.endpoint.set(localParams).deleteUrl;
    const resource = this.endpoint.patterns.deleteUrl;

    return this.http
      .delete(url, httpOptions, resource)
      .then(dto => {
        this.observer.onDeleteSuccess(dto, localParams);
        return dto;
      })
      .catch(error => {
        this.observer.onDeleteError(error, localParams);
        throw error;
      });
  }

  /**
   * Subscribes to the collection broadcast topic via mqtt then fetches the
   * resource via http (using `fetchAll`).
   *
   * @param {object} [params] - the parameters to fill in the endpoint topic with.
   * @param {object} [query] - optional query parameters to add to the request url
   * @param {object} [httpOptions] - additional options to pass to the underlying Http backend
   * @returns {Promise} - a promise that resolves with the json response body
   *
   * @example
   * let gateway = new StandardGateway(backend, 'agents/:agentId');
   *
   * gateway.setObserver({
   *   onBroadcast: handleAgentBroadcast,
   *   onFetchSuccess: handleAgentFetch,
   * });
   *
   * gateway
   *  .requestAll()
   *  .then(agentDtos => console.log(agentDtos));
   *
   *  Note: if the query parameters were provided, they will be used when re-requesting
   *  the data on reconnect. The subscription topics by default ignore query parameters
   *  unless explicitly configured via endpoint attributes. Please note that using
   *  `request` and `requestAll` with query parameters may have a potential side effect
   *  of subscribing to a wider set of updates than the data returned from the call
   */
  requestAll(params, query, httpOptions) {
    // track what has been requested, so it can be re-requested on reconnect
    this.requestAlls.track(params, query);
    return this.subscribeAll(params).then(() => this.fetchAll(params, query, httpOptions));
  }

  /**
   * Subscribes to the collection broadcast topic via mqtt.
   *
   * @param {*} [params] - the parameters to fill in the endpoint topic
   * @returns {Promise} - a promise that resolves when the subscription is ack'd
   *
   * @example
   * let gateway = new StandardGateway(backend, 'agents/:agentId');
   *
   * gateway.setObserver({ onBroadcast: handleAgentBroadcast });
   *
   * gateway.subscribeAll().then(() => console.log('subscribed!'));
   */
  subscribeAll(params) {
    const endpoint = this.endpoint.set(params);
    return this._mqttSubscribeToTopic(endpoint.collectionBroadcastTopic, this.observer.onBroadcast).then(() =>
      this._mqttSubscribeToTopic(endpoint.collectionBroadcastDeleteTopic, this.observer.onBroadcastDelete)
    );
  }

  /**
   * Unsubscribes from the collection broadcast topic via mqtt.
   *
   * @param {*} [params] - the parameters to fill in the endpoint topic
   * @returns {Promise} - a promise that resolves immediately
   *
   * @example
   * let gateway = new StandardGateway(backend, 'agents/:agentId');
   *
   * gateway.setObserver({ onBroadcast: handleAgentBroadcast });
   *
   * gateway
   *   .subscribeAll()
   *   .then(() => gateway.unsubscribeAll());
   */
  unsubscribeAll(params) {
    // remove any tracked requests, so they are not re-fetched on reconnect
    this.requestAlls.remove(params);
    return this._mqttUnsubscribeAll(params);
  }

  /**
   * These methods will be called on the provided observer when the
   * corresponding event occurs.
   *
   * Note only a single observer may be set on the gateway.
   *
   * @callback dtoCallback
   * @param {*} dto - the response data-transfer object
   *
   * @callback errorCallback
   * @param {*} error - the error
   *
   * @param {Object} observer - the observer object, which optionally contains the below methods
   * @param {dtoCallback} [observer.onBroadcast] - called with the dto on broadcast
   * @param {dtoCallback} [observer.onBroadcastDelete] - called with the success dto on broadcast delete
   * @param {dtoCallback} [observer.onFetchAllSuccess] - called with the dto on fetch all success
   * @param {errorCallback} [observer.onFetchAllError] - called with the error on fetch all error
   * @param {dtoCallback} [observer.onFetchSuccess] - called with the dto on fetch success
   * @param {errorCallback} [observer.onFetchError] - called with the error on fetch error
   * @param {dtoCallback} [observer.onAddSuccess] - called with the dto on add success
   * @param {errorCallback} [observer.onAddError] - called with the error on add error
   * @param {dtoCallback} [observer.onReplaceSuccess] - called with the dto on replace success
   * @param {errorCallback} [observer.onReplaceError] - called with the error on replace error
   * @param {dtoCallback} [observer.onUpdateSuccess] - called with the dto on update success
   * @param {errorCallback} [observer.onUpdateError] - called with the error on update error
   * @param {dtoCallback} [observer.onDeleteSuccess] - called with the dto on delete success
   * @param {errorCallback} [observer.onDeleteError] - called with the error on delete error
   */
  setObserver(observer) {
    if (this._observer) {
      throw new Error('Cannot set an observer more than once on a gateway.');
    }
    this._observer = new ObserverAdapter(observer, this.endpoint.patterns);
  }

  get observer() {
    if (!this._observer) {
      this._observer = new ObserverAdapter({}, this.endpoint.patterns);
    }
    return this._observer;
  }

  buildUrl(path, queryParams) {
    // Remove `undefined` and `NaN` param values
    const search = omitBy(queryParams, v => v === undefined || v !== v);
    const join = path.includes('?') ? '&' : '?';

    return isEmpty(search) ? path : `${path}${join}${qs.stringify(search, { allowDots: true, arrayFormat: 'comma' })}`;
  }

  // lifecycle methods
  // these methods are used by the backend to communicate changes to the gateway

  // init is called on login with the agent and org ids
  init({ orgId, agentId }) {
    this.endpoint = this.endpoint.set({ orgId, agentId });
  }

  // reconnectHandler is called on reconnect, it should re-fetch resources that
  // were subscribed to
  reconnectHandler() {
    // re-fetch every resource that we were subscribed to on reconnect
    let requests = this.requests.map((params, query) => this.request(params, query));
    let requestAlls = this.requestAlls.map((params, query) => this.requestAll(params, query));
    return Promise.all(requests.concat(requestAlls));
  }

  // resetHandler is called on logout
  resetHandler() {
    this.endpoint.reset();
    this.requests.reset();
    this.requestAlls.reset();
  }

  // Internal helpers
  _mqttSubscribeToTopic(topic, handler) {
    const topicName = trim(topic);
    return topicName ? this.mqtt.subscribe(topicName, handler) : Promise.resolve();
  }

  _mqttUnsubscribeFromTopic(topic, handler) {
    const topicName = trim(topic);
    return topicName ? this.mqtt.unsubscribe(topicName, handler) : Promise.resolve();
  }

  _mqttUnsubscribe(params) {
    const endpoint = this.endpoint.set(params);
    return this._mqttUnsubscribeFromTopic(endpoint.broadcastTopic, this.observer.onBroadcast).then(() =>
      this._mqttUnsubscribeFromTopic(endpoint.broadcastDeleteTopic, this.observer.onBroadcastDelete)
    );
  }

  _mqttUnsubscribeAll(params) {
    const endpoint = this.endpoint.set(params);
    return this._mqttUnsubscribeFromTopic(endpoint.collectionBroadcastTopic, this.observer.onBroadcast).then(() =>
      this._mqttUnsubscribeFromTopic(endpoint.collectionBroadcastDeleteTopic, this.observer.onBroadcastDelete)
    );
  }
}
