import createModel from './lib/create_model';

var Sla = createModel({
  modelName: 'Sla',

  properties: {
    contentType: String,
    setByConversationItemId: String,
    dueAt: String,
  },
});

export default Sla;
