import { SnippetContentType } from 'models/answers/snippet';
import deserializeHtml from 'components/text_editor_new/lib/deserialize_html';

export default function getInitialHtml({ greetingSnippet, signatureSnippet }, editorRef) {
  const editor = editorRef.current;
  let htmlTemplate = '';
  let initialParagraphIndex = -1;

  if (greetingSnippet) {
    const content = greetingSnippet.findContentByLanguage();
    htmlTemplate = `${content.getBodyByType(SnippetContentType.ANY_CHANNEL)}<p /><p />`;

    const fragment = deserializeHtml(htmlTemplate, editor);
    initialParagraphIndex = fragment.length - 1;
  }

  if (signatureSnippet) {
    const content = signatureSnippet.findContentByLanguage();
    const signatureBodyHtml = `<p />${content.getBodyByType(SnippetContentType.ANY_CHANNEL)}`;

    htmlTemplate = `${htmlTemplate}${signatureBodyHtml}`;
    if (!greetingSnippet) {
      htmlTemplate = `<p />${htmlTemplate}`;
      initialParagraphIndex = 0;
    }
  }

  return [htmlTemplate, initialParagraphIndex];
}
