import React from 'react';
import styled from 'styled-components';

import AnswerPanel from 'components/customer/answer_panel';
import PageLayout from 'components/page_layout';

function AnswersPreview() {
  return (
    <StyledPageLayout>
      <StyledAnswerPanel isReadOnly />
    </StyledPageLayout>
  );
}

const StyledPageLayout = styled(PageLayout)`
  .page-childrenContainer {
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }
`;

const StyledAnswerPanel = styled(AnswerPanel)`
  height: 100%;
`;

export default AnswersPreview;
