import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class VoiceConfigurationService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(compAttrs, callback, path, { orgId }) {
    let config = this._getConfig(orgId);

    callback(null, { status: 200, statusText: statusText(200), response: config });
  }

  _getConfig(orgId) {
    let database = this.getDatabase(orgId);
    let config = database.voiceConfiguration;
    return config;
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/voice': {
          GET: this.find,
        },
      },
      this
    );
  }
}
