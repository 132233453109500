import { AnswersListType } from 'models/answer_panel';
import ClearSelectedAnswer from './clear_selected_answer';
import { getAnswersList } from 'scripts/application/selectors/answers';
import SelectAnswer from './select_answer';

export default class SelectFirstAnswerShown {
  constructor(context) {
    this.context = context;
  }

  run() {
    const { answers, type } = getAnswersList(this.context.stores);
    if (!answers.length || type === AnswersListType.FAVORITES) {
      this.context.executeAction(ClearSelectedAnswer);
      return;
    }

    this.context.executeAction(SelectAnswer, { answer: answers[0] });
  }
}
