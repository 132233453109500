import createLocationModel from './lib/create_location_model';
import SearchFilter from 'models/search/search_filter';
import SearchQuery from 'models/search/search_query';

export default createLocationModel({
  modelName: 'Search',
  properties: {
    currentResultId: String,
    query: SearchQuery,
  },

  statics: {
    breadcrumb: 'Search',

    createFiltered(filterAttrs) {
      return this.create({ query: new SearchQuery({ filter: new SearchFilter(filterAttrs) }) });
    },
  },
});
