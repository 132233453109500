import _ from 'lodash';
import Observable from 'scripts/lib/observable_mixin';
import PollingUpdater from './lib/polling_updater';

const DEFAULT_TIMEOUT = 5000;
const UPDATE_INTERVAL_MS = 15000;

export default class RoutableInboxCountsGateway {
  constructor(http) {
    this.http = http;
    this.poller = new PollingUpdater(['routableCounts'], UPDATE_INTERVAL_MS);
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  requestRoutableInboxCounts() {
    this.poller.clearAndSet('routableCounts');
    this.fetch();
  }

  fetch() {
    this.http.get(`/api/v3/orgs/${this.orgId}/routing-groups-routable-counts`, this.onFetchResponse.bind(this), {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  onFetchResponse(err, res) {
    if (err != null || res.status !== 200) {
      this.notifyObservers('handleFetchRoutableCountsError', err);
      this.poller.poll('routableCounts', this.fetch.bind(this));
    } else {
      this.notifyObservers('handleFetchRoutableCountsSuccess', res.response);
      this.poller.poll('routableCounts', this.fetch.bind(this));
    }
  }

  unsubscribeFromRoutableCounts() {
    this.poller.unsubscribe('routableCounts');
  }
}

_.extend(RoutableInboxCountsGateway.prototype, Observable);
