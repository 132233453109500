import _ from 'lodash';

import createModel, { prop } from 'models/lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import Mention from 'models/mention';
import ServerClock from 'scripts/application/lib/server_clock';

const TaskComment = createModel({
  modelName: 'TaskComment',

  properties: {
    id: String,
    createdAt: String,
    initiatingAgentId: String,
    itemId: String,
    message: String,
    mentions: [Mention],
    version: prop(Number).default(0),
  },

  statics: {
    create(attrs) {
      let defaults = {
        id: IdGenerator.newId(),
        createdAt: ServerClock.toISOString(),
      };
      return new this(_.merge({}, defaults, attrs));
    },
  },
});

export default TaskComment;
