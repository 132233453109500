import _ from 'lodash';

import IdGenerator from 'scripts/domain/contracts/id_generator';

export default class ConversationsService {
  constructor(publisher, conversationItemsService, getDb) {
    this.publisher = publisher;
    this.conversationItemsService = conversationItemsService;
    this.getDatabase = getDb;
  }

  findByCustomerId(orgId, customerId) {
    let customer = _.find(this._getCustomers(orgId), { id: customerId });
    return customer && _.map(customer.conversations, conv => _.merge({}, conv));
  }

  add(orgId, customerId, { correlationId, payload }) {
    let conversation = {
      ..._.omit(payload, 'newConversationItems'),
    };
    let customerConversations = this._customerConversations(orgId, customerId);
    if (customerConversations) {
      customerConversations.push(conversation);
    } else {
      let customer = _.find(this._getCustomers(orgId), { id: customerId });
      customer.conversationHistory = [];
      customer.conversations = [conversation];
    }

    this.publisher.publishConversation(correlationId, orgId, customerId, conversation);

    this._addConversationItems(orgId, customerId, { correlationId, payload });
  }

  linkItemToCustomer({ orgId, customerId, correlationId, payload, originalItem }) {
    let item = payload.newConversationItems[0];
    let content = item.content;

    let customer = _.find(this._getCustomers(orgId), { id: customerId });
    let name = _.get(customer, 'profile.name');

    // add SN content fields
    content.customerProfile = { name, id: customerId };
    content.originalContent = originalItem.content;
    content.originalInitiator = originalItem.initiator;

    this._addConversationItems(orgId, item.customerId, { correlationId, payload });
  }

  update(orgId, customerId, conversationId, { correlationId, payload }) {
    let conversations = this._customerConversations(orgId, customerId);
    let conversation = _.find(conversations, { id: conversationId });

    let newConversationItems = payload.newConversationItems;
    if (_.get(newConversationItems, '0.content.type') === 'ITEM_LINK') {
      let createLink = () => {
        let item = payload.newConversationItems[0];

        let customer = _.find(this._getCustomers(orgId), { id: item.customerId });
        let name = _.get(customer, 'profile.name');

        let originalItem = this.conversationItemsService.find(orgId, customerId, item.content.itemId);
        let linkedItems = originalItem.linkedItems || [];

        this.linkItemToCustomer({ orgId, customerId, correlationId, payload, originalItem });
        linkedItems.push({
          customerId: item.customerId,
          itemId: item.id,
          customerProfile: { name },
          type: 'ITEM_LINK',
        });

        this.conversationItemsService.update(orgId, customerId, item.content.itemId, {
          correlationId,
          payload: {
            linkedItems,
          },
        });
      };
      try {
        createLink();
      } catch (e) {
        // add delay in case we're still configuring new customer profile
        setTimeout(createLink, 500);
      }
      return;
    }

    let assignee;
    if (payload.assignee) {
      assignee = _.pick(payload.assignee, 'routingGroupId', 'agentId');
      if (payload.assignee.routingItemType) {
        let routingItem = {
          id: IdGenerator.newId(),
          conversationId,
          customerId,
          content: {
            type: 'ROUTING_ITEM',
            assignee,
            assigneeWas: conversation.assignee,
            itemType: payload.assignee.routingItemType,
          },
          initiator: payload.assignee.initiator,
          timestamp: new Date().toISOString(),
          version: 1,
        };

        newConversationItems = (newConversationItems || []).concat(routingItem);
      }
      conversation.assignee = assignee;
    }

    _.merge(conversation, _.omit(payload, 'assignee', 'newConversationItems'));

    this.publisher.publishConversation(correlationId, orgId, customerId, conversation);

    this._addConversationItems(orgId, customerId, { correlationId, payload: { newConversationItems } });
  }

  updateCustomAttributes(orgId, customerId, conversationId, { correlationId, payload }) {
    let conversation = _.find(this._customerConversations(orgId, customerId), { id: conversationId });
    const updatedAttributeKeys = new Set(_.union(payload.add, payload.remove).map(attr => attr.key));

    const existingAttributes = _.filter(this.getDatabase(orgId).customAttributes, attr =>
      updatedAttributeKeys.has(attr.key)
    ).map(({ id, key }) => {
      return { id, key };
    });

    let attributesIDsByKey = {};
    for (const attr of existingAttributes) {
      attributesIDsByKey[attr.key] = attr.id;
    }

    const addedAttributes =
      payload.add &&
      payload.add.map(attr => {
        return {
          id: attributesIDsByKey[attr.key],
          value: attr.value,
        };
      });

    const attrHasTooLongValueLength = _.some(addedAttributes, attr => attr.value.length > 255);
    if (attrHasTooLongValueLength) {
      return {
        error: {
          errors: [
            {
              code: 'too_long',
            },
          ],
        },
      };
    }

    const removedAttributes =
      payload.remove &&
      payload.remove.map(attr => {
        return {
          id: attributesIDsByKey[attr.key],
          value: attr.value,
        };
      });

    const equalAttrs = (a, b) => a.id === b.id && a.value === b.value;

    const customAttributes = _.unionWith(conversation.customAttributes, addedAttributes, equalAttrs);
    conversation.customAttributes = _.differenceWith(customAttributes, removedAttributes, equalAttrs);

    this.publisher.publishConversation(correlationId, orgId, customerId, conversation);

    const conversationItem = {
      id: IdGenerator.newId(),
      content: {
        added: addedAttributes,
        removed: removedAttributes,
        type: 'CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE',
      },
      customerId,
      conversationId,
      initiator: payload.initiator,
      timestamp: new Date().toISOString(),
      version: 1,
    };

    this.conversationItemsService.add(orgId, customerId, { correlationId, payload: conversationItem });
  }

  updateTopicIds(orgId, customerId, conversationId, { correlationId, payload }) {
    let conversations = this._customerConversations(orgId, customerId);
    let conversation = _.find(conversations, { id: conversationId });

    let conversationItem = {
      id: IdGenerator.newId(),
      content: {
        addedTopics: payload.add,
        removedTopics: payload.remove,
        type: 'TOPIC_CHANGE',
      },
      customerId,
      initiator: payload.initiator,
      timestamp: new Date().toISOString(),
      version: 0,
    };

    let topicIds = _.union(conversation.topicIds, payload.add);
    _.pull(topicIds, ...payload.remove);
    _.merge(conversation, {
      topicId: topicIds[0] ? topicIds[0] : conversation.topicId,
    });
    conversation.topicIds = topicIds;

    this.publisher.publishConversation(correlationId, orgId, customerId, conversation);

    this.conversationItemsService.add(orgId, customerId, { correlationId, payload: conversationItem });
  }

  _addConversationItems(orgId, customerId, { correlationId, payload }) {
    _.forEach(payload.newConversationItems, ci => {
      this.conversationItemsService.add(orgId, customerId, { correlationId, payload: ci });
    });
  }

  _customerConversations(orgId, customerId) {
    return _.find(this._getCustomers(orgId), { id: customerId }).conversations;
  }

  _getCustomers(orgId) {
    return this.getDatabase(orgId).customers;
  }

  static create(pubsub, conversationItemsService, getDb) {
    return new ConversationsService(new ConversationPublisher(pubsub), conversationItemsService, getDb);
  }
}

export class ConversationPublisher {
  constructor(pubsub) {
    this.pubsub = pubsub;
  }

  publishConversation(correlationId, orgId, customerId, conversation) {
    this.pubsub.publish(`v1/orgs/${orgId}/customer-history/${customerId}/conversations/${conversation.id}`, {
      correlationId,
      payload: conversation,
    });
  }
}
