import _ from 'lodash';

import CloseSidePanels from 'actions/customer/close_side_panels';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import OpenAnswerPanel from 'actions/answers/open_answer_panel';

export default class ToggleAnswerPanel {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!currentLocation.customerId) {
      return;
    }

    if (!attrs) {
      const composition = getCompositionsStore(this.context).findBy({ id: currentLocation.currentCompositionId });
      attrs = {
        compositionId: _.get(composition, 'id'),
        conversationId: _.get(composition, 'conversationId'),
        customerId: _.get(composition, 'customerId'),
      };
    }

    if (this.context.stores.answerPanel.get().lastOpenedAt) {
      this.context.executeAction(CloseSidePanels, attrs);
    } else {
      this.context.executeAction(OpenAnswerPanel, attrs);
    }
  }
}
