import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RCPaginate from 'rc-pagination';
import enUS from 'rc-pagination/lib/locale/en_US';

class Paginate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedSize: props.size || 0,
      cachedFrm: props.from || 0,
      cachedTotal: props.total || 0,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (!props.isLoading) {
      this.setState({
        cachedFrm: props.from,
        cachedSize: props.size,
        cachedTotal: props.total,
      });
    }
  }
  render() {
    if (this.state.cachedTotal <= this.state.cachedSize) {
      return null;
    }

    let currentPage = this._getCurrentPage();
    return (
      <div className={classnames('paginate', { 'paginate-loading': this.props.isLoading })}>
        <RCPaginate
          current={currentPage > 0 ? currentPage : 1}
          locale={enUS}
          onChange={(page, size) => this.props.onChange((page - 1) * size, size)}
          pageSize={this.state.cachedSize || this.props.size}
          total={this.state.cachedTotal || this.props.total}
        />
      </div>
    );
  }
  _getCurrentPage() {
    let from = this.state.cachedFrm || this.props.from;
    let size = this.state.cachedSize || this.props.size;
    return Math.floor(from / size) + 1;
  }
}

Paginate.defaultProps = {
  from: 0,
  total: 0,
};

Paginate.propTypes = {
  /** Function to call to load more results. */
  onChange: PropTypes.func,
  /** Number of results per page. */
  size: PropTypes.number.isRequired,
  /** The number from which the current results start. Determines which page
   *  number is highlighted.
   */
  from: PropTypes.number,
  /** Total number of results. */
  total: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default Paginate;
