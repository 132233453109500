import { Factory } from 'rosie';

import './customer_profile_factory';
import './conversation_factory';
import './conversation_items/email_preview_factory';

Factory.define('TaskPreview');

Factory.define('TaskPreviewWithDefaults')
  .attr('customer', () => Factory.attributes('CustomerProfileWithDefaults'))
  .attr('task', () => Factory.attributes('TaskItemWithDefaults'))
  .attr('readTo', new Date().toISOString());
