import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { INBOX_COUNT_MAX } from 'models/inbox_count';
import RoutableCounts from 'models/routable_counts';
import { RoutingPreferenceGroup } from 'models/agent_routing_preferences';
import { useDebounced } from 'components/hooks/debounce_hooks';

export default function AgentStatusButton({
  children,
  disabled,
  preferenceGroup,
  routableCounts,
  showTrueCount,
  onClick,
}) {
  onClick = useDebounced(onClick, 500, { leading: true, trailing: false });

  const classNames = classnames('agentStatusButton', {
    [`agentStatusButton-${_.camelCase(preferenceGroup).toLowerCase()}`]: preferenceGroup,
    'agentStatusButton-disabled': disabled,
  });

  return (
    <div className={classNames} onClick={onClick}>
      <div className="agentStatus-icon">
        {children}
        {preferenceGroup ? (
          <AgentStatusButtonQueueCount
            preferenceGroup={preferenceGroup}
            routableCounts={routableCounts}
            showTrueCount={showTrueCount}
          />
        ) : null}
      </div>
    </div>
  );
}

AgentStatusButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  preferenceGroup: PropTypes.oneOf(_.values(RoutingPreferenceGroup)),
  routableCounts: PropTypes.instanceOf(RoutableCounts),
  showTrueCount: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export function AgentStatusButtonQueueCount({ preferenceGroup, routableCounts, showTrueCount }) {
  if (_.get(routableCounts, preferenceGroup, -1) <= 0) {
    return null;
  }

  let count = routableCounts[preferenceGroup];
  let countBucket;

  if (!showTrueCount) {
    if (count > INBOX_COUNT_MAX) {
      countBucket = '999+';
    } else if (count >= 10 && count % 10 !== 0) {
      countBucket = `${Math.floor(count / 10)}0+`;
    } else {
      countBucket = count;
    }
  } else {
    countBucket = count > INBOX_COUNT_MAX ? '999+' : count;
  }
  return <div className={'agentStatusButton-queueCount'}>{countBucket}</div>;
}

AgentStatusButtonQueueCount.propTypes = {
  preferenceGroup: PropTypes.oneOf(_.values(RoutingPreferenceGroup)).isRequired,
  routableCounts: PropTypes.instanceOf(RoutableCounts),
  showTrueCount: PropTypes.bool,
};
