import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import CollapsedInboxItem from './collapsed_inbox_item';
import EmptyCheck from './empty_check';
import InboxItem from './inbox_item';
import IncreaseAgentInboxLimit from 'actions/inbox/agent/increase_agent_inbox_limit';
import needsAttention from './needs_attention';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function InboxListWithInfiniteScroll(props) {
  const loadingRef = useInfiniteScroll();

  return <MemoInboxList {...props} loadingRef={loadingRef} />;
}

export function InboxList({
  activeCallConversationId,
  activeSessions,
  agentInboxItems,
  collapsed,
  currentAgentId,
  inboxItemErrors,
  loadingRef,
  selectedItemId,
}) {
  if (agentInboxItems.length === 0 && !collapsed) {
    return <EmptyList />;
  }

  const Component = collapsed ? CollapsedInboxItem : InboxItem;

  let inboxItems = agentInboxItems.map((item, index) => (
    <Component
      currentAgentId={currentAgentId}
      errors={inboxItemErrors[item.id]}
      index={index}
      item={item}
      key={item.id}
      needsAttention={needsAttention(item, activeSessions, activeCallConversationId)}
      selectedItemId={selectedItemId}
    />
  ));
  // When the loader almost comes into view, we'll load the next page of inbox items
  inboxItems.push(<Loader key="loader" ref={loadingRef} />);

  return <React.Fragment>{inboxItems}</React.Fragment>;
}

InboxList.defaultProps = {
  inboxItemErrors: {},
};

const MemoInboxList = React.memo(InboxList);

function useInfiniteScroll() {
  const executeAction = useExecuteAction();
  const [loadingRef, isLoaderInView] = useInView();

  useEffect(() => {
    if (isLoaderInView) {
      executeAction(IncreaseAgentInboxLimit);
    }
  }, [executeAction, isLoaderInView]);

  return loadingRef;
}

// Position the invisible loader div 200px above where it's rendered so that we can
// trigger loading more items _before_ we get to the exact bottom of the customer list
const Loader = styled.div`
  height: 1px;
  transform: translateY(-200px);
`;

function EmptyList() {
  return (
    <EmptyState data-aid="customerList-empty">
      <CenterEmptyCheck>
        <EmptyCheck />
      </CenterEmptyCheck>
      <EmptyStateText>
        Nice work!
        <br />
        All your customers have been helped.
      </EmptyStateText>
    </EmptyState>
  );
}

const EmptyState = styled.div`
  align-items: center;
  margin-top: -60px;
  position: absolute;
  top: 45%;
  width: 100%;
`;

const EmptyStateText = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.gray900};
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
`;

const CenterEmptyCheck = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;
