import _ from 'lodash';

const I_WORDS = ['i', "i've", "i'm", "i'll", "i'd"];

export default function CapitalizeI() {
  return {
    onKeyDown(evt, editor, next) {
      const { value } = editor;
      const { selection } = value;

      // We only capitalize if you're typing normally, with a collapsed selection
      if (!selection.isCollapsed) {
        return next();
      }

      // We only capitalize after we hit a whitespace character.
      const charRegexp = new RegExp(/^\s$/);
      if (!charRegexp.test(evt.key)) {
        return next();
      }

      const textNode = value.texts.last();
      const text = textNode && textNode.text;
      if (!text) {
        return next();
      }

      // Find the start of the word.. e.g. "Hi I've "
      //                                       ^
      const whitespaceRegexp = /\s/i;
      let start = selection.start.offset;
      for (; start >= 0; start--) {
        const char = text.charAt(start - 1);
        if (whitespaceRegexp.test(char)) {
          break;
        }
      }

      if (start === selection.start.offset || start < 0) {
        return next();
      }

      // Find the start of the word.. e.g. "Hi I've. "
      //                                           ^
      const alphabetOrApostropheRegexp = /[a-z']/i;
      let end = start + 1;
      for (; end <= selection.start.offset; end++) {
        const char = text.charAt(end);
        if (!alphabetOrApostropheRegexp.test(char)) {
          break;
        }
      }

      // If there is no word then w/e
      if (end - start === 0) {
        return next();
      }

      // Ensure that the word matches something we care about
      const word = text.slice(start, end);
      if (!_.includes(I_WORDS, word)) {
        return next();
      }

      // // Insert the text and _then_ change the capitalization. This way, an agent can hit undo if they didn't
      // // want that letter capitalized.
      evt.preventDefault();
      editor.insertText(evt.key);

      const capitalizeISelection = selection.moveStartTo(start).moveEndTo(start + 1);
      const existingMarks = editor.value.document.getActiveMarksAtRange(capitalizeISelection);
      editor.insertTextAtRange(capitalizeISelection, 'I', existingMarks);

      return;
    },
  };
}
