import _ from 'lodash';

import { AggregationLevel } from 'models/reports/report_configs';
import Reports from 'models/location/reports';

const aggregationLevelMapping = Object.freeze({
  quarter_hour: AggregationLevel.QUARTER_HOUR,
  half_hour: AggregationLevel.HALF_HOUR,
  day: AggregationLevel.DAY,
  week: AggregationLevel.WEEK,
  month: AggregationLevel.MONTH,
  quarter: AggregationLevel.QUARTER,
});

export default {
  fromUrlParameters({ slug, aggregate, channel, groups, teams, topics, start, end, endpointNumber, timezone }) {
    return {
      slug,
      // if the aggregate is not valid, it's probably a legacy value
      // so try to map it to the new corresponding value to preserve backwards compatability for old direct links
      aggregationLevel: Reports.isAggregationLevelValid(aggregate) ? aggregate : aggregationLevelMapping[aggregate],
      channel: channel && channel.toUpperCase(),
      routingGroupIds: groups && _.filter(groups),
      teamIds: teams && _.filter(teams),
      topicIds: topics && _.filter(topics),
      startAt: start,
      endAt: end,
      timezone,
      endpointNumber,
    };
  },

  getPath(reports) {
    return reports.slug === Reports.defaultSlug ? '/reports' : `/reports/${reports.slug}`;
  },

  getQueryParams(reports) {
    let queryParams = {};
    if (reports.getChannel()) {
      queryParams.channel = reports.getChannel().toLowerCase();
    }
    if (reports.routingGroupIds) {
      queryParams.groups = reports.getRoutingGroupIds();
    }
    if (reports.teamIds) {
      queryParams.teams = reports.teamIds;
    }
    if (reports.topicIds) {
      queryParams.topics = reports.topicIds;
    }
    if (reports.hasDateRange()) {
      queryParams.start = reports.getStartAt();
      queryParams.end = reports.getEndAt();
    }
    if (reports.aggregationLevel) {
      queryParams.aggregate = reports.aggregationLevel;
    }
    if (reports.getEndpointNumber()) {
      queryParams.endpointNumber = reports.getEndpointNumber();
    }
    if (reports.getTimezone()) {
      queryParams.timezone = reports.getTimezone();
    }

    return queryParams;
  },
};
