import createEnum from 'scripts/lib/create_enum';

const LOCATIONS = createEnum(
  'CONVERSATION_ITEM_MENU',
  'DIRECT_LINK',
  'FAKE_COMMENT_EDITOR',
  'SIDE_PANEL',
  'TASK_COMMENT',
  'TASK_ITEM',
  'TASK_MANAGER'
);

export default LOCATIONS;
