import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import theme from 'scripts/presentation/themes/default';

import Spinner from 'components/common/spinner';

export default function SummaryCard({ className, children, isLoading, onClick }) {
  let classNames = classnames('customerSummaryCard', className);

  if (isLoading) {
    children = (
      <SpinnerContainer>
        <Spinner color={theme.colors.gray400} stroke={2} />
      </SpinnerContainer>
    );
  }

  return (
    <div className={classNames} onClick={onClick}>
      {children}
    </div>
  );
}

SummaryCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

export function SummaryHeader({ children, className, text }) {
  const classNames = classnames('customerSummaryCard-header', className);
  return (
    <div className={classNames}>
      {text ? <SummaryHeaderText>{text}</SummaryHeaderText> : null}
      {children}
    </div>
  );
}

SummaryHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
};

export const SpinnerContainer = styled.div`
  width: 24px;
  align-self: center;
`;

export function SummaryHeaderText({ children }) {
  return <div className="customerSummaryCard-headerText">{children}</div>;
}

SummaryHeaderText.propTypes = {
  children: PropTypes.node.isRequired,
};
