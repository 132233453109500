import _ from 'lodash';

import createModel, { prop } from '../lib/create_model';
import { LegacyConfigs as LegacyReportConfigs } from 'scripts/domain/models/reports/report_configs';

export const LegacyReportSlugs = getSlugs(LegacyReportConfigs);

// List of report types to be used only for legacy report models
export const ReportType = Object.freeze({
  AGENTS: 'AGENTS',
  SUMMARY: 'SUMMARY',
  TOPICS: 'TOPICS',
  'IVR-SUMMARY': 'IVR-SUMMARY',
  'IVR-END-STATES': 'IVR-END-STATES',
});

export function isEmbeddedReport(slug) {
  return !(LegacyReportSlugs.indexOf(slug) > -1);
}

export function findReportConfigBySlug(reportConfigs, urlSlug) {
  return _.find(reportConfigs, { urlSlug });
}

export function getSlugs(configs) {
  return _.map(configs, config => config.urlSlug);
}

// slug -> type mapping
export function getTypeFromSlug(slug) {
  if (LegacyReportSlugs.indexOf(slug) > -1) {
    return slug.toUpperCase();
  }
}

export default createModel({
  modelName: 'Report',
  properties: {
    timezone: prop(String).default('America/New_York'),
    data: prop(Object).default({}),
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: this.reportType });
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
