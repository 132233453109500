import moment from 'moment';

import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import Factory from 'factories/all';
import OfferedCallPublisher from './offered_call_publisher';
import PhoneCall from 'models/phone_call';
import qconsole from 'scripts/lib/qconsole';

export default class BlindTransferCallSimulator extends CommunicationSimulator {
  constructor({ orgId, fakeTwilio, services }, database) {
    super(
      {
        attribute: 'incomingPhoneCalls',
        createItem: createBlindTransferCall,
        orgId,
        publisher: new OfferedCallPublisher(orgId, services.incomingCall, database),
        services,
      },
      database
    );
    this._fakeTwilio = fakeTwilio;
  }

  simulate(communication) {
    if (!this.incomingCallService.isCallQueueEmpty()) {
      qconsole.warn('Current agent has an active phone call');
      return;
    }
    this._fakeTwilio.simulateIncomingCall();
    super.simulate(communication);
  }

  get incomingCallService() {
    return this.services.incomingCall;
  }
}

function createBlindTransferCall(rawPhoneCall) {
  let currentDate = new Date().toISOString();
  let currentAgentId = this.database.get().currentAgent.id;
  let otherAgentId = 'other-agent-id';
  return Factory.build('PhoneCallItem', {
    initiator: {
      type: Communicator.CUSTOMER,
      id: rawPhoneCall.customerId,
    },
    content: {
      answeredAt: moment(currentDate)
        .subtract(1250, 'ms')
        .toISOString(),
      customerNumber: rawPhoneCall.customerNumber,
      companyNumber: rawPhoneCall.companyNumber,
      status: PhoneCall.Status.OFFERING,
      startedAt: currentDate,
      participants: [
        {
          participantId: rawPhoneCall.customerNumber,
          callId: rawPhoneCall.customerNumber,
          status: PhoneCall.ParticipantStatus.ACTIVE,
          statusUpdatedAt: currentDate,
          type: PhoneCall.ParticipantType.CUSTOMER,
        },
        {
          participantId: otherAgentId,
          callId: otherAgentId,
          status: PhoneCall.ParticipantStatus.HUNG_UP,
          statusUpdatedAt: currentDate,
          type: PhoneCall.ParticipantType.AGENT,
        },
        {
          participantId: currentAgentId,
          callId: currentAgentId,
          status: PhoneCall.ParticipantStatus.DIALING,
          statusUpdatedAt: currentDate,
          type: PhoneCall.ParticipantType.AGENT,
        },
      ],
      participantEvents: [
        {
          participantId: rawPhoneCall.customerNumber,
          participantStatus: PhoneCall.ParticipantStatus.DIALING,
          eventTime: moment(currentDate)
            .subtract(2000, 'ms')
            .toISOString(),
        },
        {
          participantId: rawPhoneCall.customerNumber,
          participantStatus: PhoneCall.ParticipantStatus.ACTIVE,
          eventTime: moment(currentDate)
            .subtract(1750, 'ms')
            .toISOString(),
        },
        {
          participantId: otherAgentId,
          participantStatus: PhoneCall.ParticipantStatus.DIALING,
          eventTime: moment(currentDate)
            .subtract(1500, 'ms')
            .toISOString(),
        },
        {
          participantId: otherAgentId,
          participantStatus: PhoneCall.ParticipantStatus.ACTIVE,
          eventTime: moment(currentDate)
            .subtract(1250, 'ms')
            .toISOString(),
        },
        {
          participantId: otherAgentId,
          participantStatus: PhoneCall.ParticipantStatus.TERMINATING,
          eventTime: moment(currentDate)
            .subtract(1000, 'ms')
            .toISOString(),
        },
        {
          participantId: otherAgentId,
          participantStatus: PhoneCall.ParticipantStatus.HUNG_UP,
          eventTime: moment(currentDate)
            .subtract(750, 'ms')
            .toISOString(),
        },
        {
          participantId: currentAgentId,
          participantStatus: PhoneCall.ParticipantStatus.DIALING,
          eventTime: currentDate,
        },
      ],
    },
    timestamp: currentDate,
  });
}
