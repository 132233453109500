import { Factory } from 'rosie';

import '../conversation_item_factory';

// This is for demo use only

Factory.define('MarketingEmail').attr('type', 'MARKETING_EMAIL');
Factory.define('MarketingEmailWithDefaults')
  .extend('MarketingEmail')
  .attr('url', 'www.google.com')
  .attr('subject', 'Say hello to your first special offer!');

Factory.define('MarketingEmailItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function(content) {
    return Factory.attributes('MarketingEmail', content);
  });

Factory.define('MarketingEmailItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function(content) {
    return Factory.attributes('MarketingEmailWithDefaults', content);
  });
