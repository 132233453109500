import StandardGateway from './lib/standard_gateway';

export default class SearchGateway extends StandardGateway {
  constructor(backend) {
    super(backend, { fetchUrl: '/api/v1/orgs/:orgId/search' });
  }

  fetch(id, params) {
    let url = this.endpoint.set(params).fetchUrl;
    return this.http.post(url, params);
  }
}
