import createModel from './lib/create_model';

const MarketingEmail = createModel({
  modelName: 'MarketingEmail',
  properties: {
    subject: String,
    url: String,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default MarketingEmail;
