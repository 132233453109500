import _ from 'lodash';

import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';

/**
 * Simple helper to filter the configured "attribute groups" and return only those that have data
 * for at least one attribute in the group
 *
 * @param {object[]} blockConfigs - a list of "block" (aka "attribute group") definitions
 * @param {object} data - the data that is supposed to be rendered
 */
export function getRenderableBlocks(blockConfigs, data) {
  if (_.isEmpty(blockConfigs) || _.isEmpty(data)) return [];

  return _.filter(blockConfigs, blockDefinition => {
    const attrs = _.get(blockDefinition, 'attributes', []);
    const renderableAttrs = getAttributesToDisplay(attrs, data);
    return !_.isEmpty(renderableAttrs);
  });
}
