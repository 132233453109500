import _ from 'lodash';

import AITextCompletion from 'models/ai_text_completion';
import Err from 'models/err';
import { GladlyError } from 'scripts/application/lib/error';
import qconsole from 'scripts/lib/qconsole';
import UseComposerConversationSummary from 'actions/ai_conversation_summary/use_composer_conversation_summary';

export const REQUEST_TIMEOUT = 60000; // 1 minute

export default class RequestComposerConversationSummary {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, conversationId, customerId, requestorId }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      return qconsole.log(
        `RequestComposerConversationSummary: received request for unloaded customer [${customerId}]. Ignoring.`
      );
    }

    // Make sure we received all required parameters
    const valid = this.verifyParameters(
      { compositionId, conversationId, requestorId },
      {
        customerId,
      }
    );
    if (!valid) return;

    // Check whether there is a similar request already running, stop if yes. When checking, we only
    // take into account the requestor and the request type - all other attributes are for the benefit
    // of the other components that may be watching loading activities
    const partialRequestKey = { id: requestorId, type: AITextCompletion.Types.conversation_summary };
    const { aiTextCompletions } = this.context.stores.customers.storesFor(customerId);
    const completionProvider = aiTextCompletions.getProvider();
    const isRunning = completionProvider.hasActiveLoadingStateFor(partialRequestKey);
    if (isRunning) {
      return qconsole.log(
        `RequestComposerConversationSummary: multiple concurrent requests for ${requestorId}. Ignoring`
      );
    }

    // Record the request
    this.context.analytics.track('Composer Conversation Summary Requested', {
      conversationId,
      customerId,
      summaryType: 'complete',
    });

    // Make the actual request
    const completeRequestKey = { ...partialRequestKey, compositionId, preventUserInput: true, preventSubmit: true };
    aiTextCompletions.clearErrorForLoading(completeRequestKey);
    aiTextCompletions.setLoading(completeRequestKey);
    return this.context.gateways.aiConversationSummary
      .fetch({ conversationId }, {}, { timeout: REQUEST_TIMEOUT })
      .then(summaryDto => {
        this.context.executeAction(UseComposerConversationSummary, {
          compositionId,
          conversationId,
          customerId,
          summaryDto,
        });
      })
      .catch(err => {
        aiTextCompletions.setErrorForLoading(Err.fromJs(err), completeRequestKey);
        qconsole.log(`Error while summarizing the conversation "${conversationId}": ${this.getErrorMessage(err)}`);
      })
      .finally(() => {
        aiTextCompletions.resetLoading(completeRequestKey);
      });
  }

  verifyParameters(params, meta = {}) {
    let isValid = true;
    _.forEach(params, (value, key) => {
      if (!value) {
        this.context.errorReporter.reportError(Error(`RequestComposerConversationSummary: invalid ${key}`), {
          tags: meta,
          message: `Missing or invalid parameter "${key}"`,
        });
        isValid = false;
      }
    });

    return isValid;
  }

  getErrorMessage(error) {
    if (!error) return '';
    if (error instanceof GladlyError) {
      return _.get(error, 'cause.message') || error.message;
    }
    return error.message;
  }
}
