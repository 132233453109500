import classnames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Attachments from '../attachments/attachments';
import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import RedactedItem from 'components/customer/conversation_history/conversation_items_v2/redacted_item';
import SafeHtml from 'components/common/safe_html';

export default function NoteItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata item={item} showMetadata={!isStacked} text="added a note" />
      <NoteContent className={className} item={item} />
    </ItemContent>
  );
}

NoteItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export function NoteContent({ className, item }) {
  return (
    <BubbleContent className={classnames('noteContent', className)} data-aid={`noteContent-${item.id}`} item={item}>
      {get(item, 'content.isRedacted') ? (
        <RedactedItem />
      ) : (
        <SafeHtml data-aid="noteContentBody" html={get(item, 'content.bodyHtml')} setDirection />
      )}

      <Attachments attachments={item.content.attachments || []} itemId={item.id} pathPrefix="note" />
    </BubbleContent>
  );
}

NoteContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
