import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M8 0a3 3 0 00-3 3v5a3 3 0 002.5 2.959v.786A3.94 3.94 0 014.06 7.838V4.353h-1v3.485A4.94 4.94 0 007.5 12.75V14.5H5a.5.5 0 000 1h6a.5.5 0 000-1H8.5v-1.749a4.94 4.94 0 004.439-4.913V4.353h-1v3.485a3.94 3.94 0 01-3.44 3.907v-.786A3 3 0 0011 8V3a3 3 0 00-3-3z"
      fillRule="evenodd"
    />
  </Icon>
);
