export function setDisplayName(NewComponent, WrappedComponent, name) {
  return (NewComponent.displayName = wrapDisplayName(WrappedComponent, name));
}

function wrapDisplayName(Component, name) {
  return `${name}(${getDisplayName(Component)})`;
}

export function getDisplayName(Component) {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return undefined;
  }

  return Component.displayName || Component.name || 'Component';
}
