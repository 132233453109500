import _ from 'lodash';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import {
  CONVERSATION,
  CUSTOMER_TYPING_INDICATOR,
  ITEM,
  LAST_CONVERSATION_SPACER,
  LAST_CONVERSATION_SPACER_ID,
} from './feed/feed_item_types';
import ConversationItemType from 'models/conversation_item_type';
import { getSortedConversations } from 'actions/conversation/lib/conversation_helpers';
import isItemStackable from './is_item_stackable';
import { RoutingChannel } from 'models/agent_routing_preferences';
import { routingChannelForInteractionType } from 'models/interaction_type';
import { SessionEndReason } from 'models/session_ended';
import TypingState from 'models/typing_state';

// SessionEndReasons that we actually display in the feed
const visibleSessionEndedReasons = Object.freeze([
  SessionEndReason.AGENT_ENDED,
  SessionEndReason.CUSTOMER_NO_REPLY,
  SessionEndReason.STARTED_NEW,
  SessionEndReason.TIME_EXPIRED,
]);

/**
 * fetchFeedItems is a container HoC which retrieves conversations and items for a customers and creates
 * a list of sorted "feed items", which are lightweight representations of things we want to see in the feed,
 * (like conversation items or conversation headers).
 *
 * Avoiding instantiating item models was deliberate, as it becomes quite expensive to do that for hundreds of items,
 * especially when we want to avoid any scroll jank.
 */

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  const conversationsProvider = getProvider('conversations');
  const conversationHistoryProvider = getProvider('conversationHistory');
  const itemIdsProvider = getProvider('itemIds');
  const currentAgentId = getProvider('currentAgent').get().id;
  const activeSessionProvider = getProvider('activeSessions');
  const conversationMergeProvider = getProvider('conversationMerge');

  const conversationHistoryErrors = conversationHistoryProvider.getErrorForLoading();
  const conversationErrors = conversationsProvider.getErrorForLoading();
  const itemIdsErrors = itemIdsProvider.getErrorForLoading();

  const sortedConversations = getSortedConversations(conversationsProvider);
  let items = getItemsFromIds();

  const activeSession = activeSessionProvider.findBy({ customer: { id: props.customerId } });
  let customerIsTyping = _.get(activeSession, 'typingState.state') === TypingState.STARTED;

  if (customerIsTyping) {
    items.push({
      type: CUSTOMER_TYPING_INDICATOR,
      id: 'customer-typing',
    });
  }

  const hasNoConversations = !sortedConversations.length && !conversationsProvider.isLoading();
  const hasNoItems = !items.length && !itemIdsProvider.isLoading();
  const isLastConversationEmpty = items.length > 0 && _.last(items).type === CONVERSATION;

  items.push({
    type: LAST_CONVERSATION_SPACER,
    id: LAST_CONVERSATION_SPACER_ID,
    isEmpty: hasNoItems || isLastConversationEmpty,
  });

  return {
    customerId: props.customerId,
    isEmpty: hasNoItems && hasNoConversations,
    hasErrorLoadingConversationHistory: !!conversationHistoryErrors,
    hasErrorLoadingConversations: !!conversationErrors,
    hasErrorLoadingItemIds: !!itemIdsErrors,
    items,
    isLoading:
      conversationsProvider.isLoading() ||
      conversationHistoryProvider.isLoading() ||
      itemIdsProvider.isLoading() ||
      conversationMergeProvider.isPending(),
  };

  function getItemsFromIds() {
    const items = [];

    let seenConvos = new Set();
    let previousItemImmutable = null;

    let itemIds = _.sortBy(itemIdsProvider.findAll(), 'timestamp');
    for (let i = 0; i < itemIds.length; i++) {
      const { id, conversationId } = itemIds[i];
      if (conversationId && !seenConvos.has(conversationId)) {
        items.push({
          type: CONVERSATION,
          id: conversationId,
        });
        seenConvos.add(conversationId);
      }

      const itemImmutable = conversationHistoryProvider.immutableStore.binding.get(id);
      const initiatorId = itemImmutable && itemImmutable.getIn(['initiator', 'id']);
      const initiatorType = itemImmutable && itemImmutable.getIn(['initiator', 'type']);

      if (itemImmutable && !shouldFeedItemBeCreated(itemImmutable)) {
        continue;
      }
      const newItem = {
        conversationId,
        id,
        initiatedByCurrentAgent: initiatorType === Communicator.AGENT && initiatorId === currentAgentId,
        isStacked: isItemStackable(itemImmutable, previousItemImmutable),
        timestamp: itemImmutable ? itemImmutable.get('timestamp') : null,
        type: ITEM,
      };
      items.push(newItem);

      previousItemImmutable = itemImmutable;
    }

    // This usually only happens when we create a new conversation without any items in it.
    if (seenConvos.size < sortedConversations.length) {
      const missingConversations = _.filter(sortedConversations, conversation => !seenConvos.has(conversation.id));
      _.forEach(missingConversations, conversation => {
        for (let i = items.length - 1; i >= 0; i--) {
          const item = items[i];
          if (item.timestamp && new Date(item.timestamp) <= new Date(conversation.createdAt)) {
            items.splice(i + 1, 0, {
              type: CONVERSATION,
              id: conversation.id,
            });
            return;
          }
        }
      });
    }

    return items;
  }
}

function shouldFeedItemBeCreated(itemImmutable) {
  const contentType = itemImmutable.getIn(['content', 'type']);

  switch (contentType) {
    case ConversationItemType.EMAIL_CAMPAIGN_OUTREACH_RESULT:
    case ConversationItemType.SMS_CAMPAIGN_OUTREACH_RESULT:
    case ConversationItemType.MESSAGING_SESSION:
      return false;
    case ConversationItemType.SESSION_ENDED:
      return !isMailSessionType() && _.includes(visibleSessionEndedReasons, itemImmutable.getIn(['content', 'reason']));
    default:
      return true;
  }

  function isMailSessionType() {
    const sessionType = itemImmutable.getIn(['content', 'sessionType']);
    return routingChannelForInteractionType(sessionType) === RoutingChannel.MAIL;
  }
}

export default connect(mapStateToProps);
