import React from 'react';
import PropTypes from 'prop-types';

function FbSticker({ stickerUrl }) {
  return <img className="fbSticker" src={stickerUrl} />;
}

FbSticker.propTypes = {
  stickerUrl: PropTypes.string.isRequired,
};

export default FbSticker;
