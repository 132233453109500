import _ from 'lodash';

import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';

/**
 * Helper function that checks whether the "list" (as configured, normally a part of the "section" config) has
 * any renderable elements, i.e. elements that have data
 *
 * @param {string} listSource - path to the list in the `data` object
 * @param {object} listItem - list "item" configuration
 * @param {number} [listMaxLength] - if specified, limits the renderable part of the list to the first N elements
 * @param {object} data - data block to render
 * @return {boolean} returns "true" if at least one list element has at least one renderable attribute, "false" otherwise
 */
export function isRenderableList({ listSource, listItem, listMaxLength }, data) {
  if (_.isEmpty(data)) return false;

  const source = _.trim(listSource);
  const list = source ? _.get(data, source, []) : [];

  // Do we have the list? If not, return
  if (_.isEmpty(list) || _.isEmpty(listItem) || _.isEmpty(listItem?.attributes)) return false;

  // Now check the list data elements against the list item configuration
  const limit = _.isNumber(listMaxLength) && _.isFinite(listMaxLength) && listMaxLength > 0 ? listMaxLength : 0;
  const listSlice = limit ? list.slice(0, limit) : list;
  const itemAttrs = _.get(listItem, 'attributes');

  const renderableElement = _.find(listSlice, listElementData => {
    const renderableAttr = getAttributesToDisplay(itemAttrs, listElementData);
    return !_.isEmpty(renderableAttr);
  });

  return !!renderableElement;
}
