import RequestInbox from './request_inbox';
import UpdateInboxLocation from './lib/update_inbox_location';

export default class SetInboxPolling extends UpdateInboxLocation {
  run(isPolling) {
    super.run(isPolling);

    if (isPolling) {
      this.context.executeAction(RequestInbox);
    } else {
      this.context.gateways.inboxHttp.unsubscribeFromInboxes();
    }
  }

  updateInbox(inbox, isPolling) {
    inbox.setTransientState({ isPolling });
  }
}
