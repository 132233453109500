import _ from 'lodash';
import { LIST_ITEM, ORDERED_LIST, UNORDERED_LIST } from './constants';
import { isList } from './utils';

export function decreaseListDepth(editor, next = _.noop) {
  const { value } = editor;
  if (!isList(value)) return next();

  value.blocks
    .filter(block => block.type === LIST_ITEM)
    .forEach(block => {
      const depth = value.document.getDepth(block.key);
      const listParent = value.document.getClosest(
        block.key,
        parent => parent.type === UNORDERED_LIST || parent.type === ORDERED_LIST
      );
      if (!listParent || depth <= 2) {
        return;
      }
      editor.withoutNormalizing(() => {
        editor.unwrapBlockByKey(block.key).focus();
      });
    });
}
