import _ from 'lodash';
import moment from 'moment-timezone';

import createModel, { prop } from 'models/lib/create_model';
import ServerClock from 'scripts/application/lib/server_clock';

export const CustomerAccess = createModel({
  modelName: 'CustomerAccess',
  properties: {
    id: prop(String).isRequired,
    lastUsedAt: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this({ lastUsedAt: ServerClock.toISOString(), ...attrs });
    },
  },
});

export default createModel({
  modelName: 'CustomerAccessHistory',
  properties: {
    entries: prop([CustomerAccess]).isRequired,
  },

  put(customerAccess) {
    let existingIndex = _.findIndex(this.entries, { id: customerAccess.id });
    if (existingIndex >= 0) {
      if (moment(customerAccess.lastUsedAt).isBefore(moment(this.entries[existingIndex].lastUsedAt))) {
        return;
      }
      this.entries.splice(existingIndex, 1);
    }

    let insertAt = _.sortedIndexBy(this.entries, customerAccess, 'lastUsedAt');
    this.entries.splice(insertAt, 0, customerAccess);
  },

  remove(customerId) {
    let existingIndex = _.findIndex(this.entries, { id: customerId });
    if (existingIndex >= 0) {
      this.entries.splice(existingIndex, 1);
    }
  },

  trim(maxLength) {
    let removed = _.take(this.entries, this.entries.length - maxLength);
    this.entries = this.entries.slice(removed.length);
    return removed;
  },

  trimNotAccessedSince(cutoff) {
    let removed = _.takeWhile(this.entries, ca => moment(ca.lastUsedAt).isBefore(cutoff));
    this.entries = this.entries.slice(removed.length);
    return removed;
  },

  statics: {
    create() {
      return new this({ entries: [] });
    },
  },
});
