import _ from 'lodash';
import httpStatus from 'http-status';

import Observable from 'scripts/lib/observable_mixin';

export default class TopicGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqtt = backend;

    _.bindAll(this, ['get', 'getHandler', 'addHandler', 'handleBroadcast']);
  }

  init({ orgId }) {
    this.orgId = orgId;
  }

  get http() {
    return this.backend.axios();
  }

  get broadcastTopic() {
    return `v1/orgs/${this.orgId}/topics/+`;
  }

  request() {
    this._isSubscribed = true;
    this.mqtt.subscribe(this.broadcastTopic, this.handleBroadcast, this.get);
  }

  get() {
    return this.http
      .get(topicUrl(this.orgId))
      .then(response => this.getHandler(null, response))
      .catch(err => this.getHandler(err, err.response));
  }

  add(topic) {
    return this.http
      .post(topicUrl(this.orgId), topic)
      .then(response => this.addHandler(null, response))
      .catch(err => this.addHandler(err, err.response));
  }

  update(topicId, topicAttrs) {
    return this.http
      .put(topicUrl(this.orgId, topicId), topicAttrs)
      .then(response => this.updateHandler(topicId, null, response))
      .catch(err => this.updateHandler(topicId, err, err.response));
  }

  getHandler(err, res) {
    _.get(res, 'status') === httpStatus.OK
      ? this.notifyObservers('handleRequestedEntities', res.data)
      : this.notifyObservers('handleRequestError', err || res.data);
  }

  addHandler(err, res) {
    _.get(res, 'status') === httpStatus.NO_CONTENT
      ? this.notifyObservers('handleAddSuccess', {})
      : this.notifyObservers('handleAddErrors', err || res.data);
  }

  updateHandler(topicId, err, res) {
    _.get(res, 'status') === httpStatus.NO_CONTENT
      ? this.notifyObservers('handleUpdateSuccess', topicId)
      : this.notifyObservers('handleUpdateErrors', err || res.data);
  }

  reconnectHandler() {
    if (this._isSubscribed) {
      this.request();
    }
  }

  handleBroadcast(envelope) {
    this.handlePayload(envelope.payload);
  }

  handlePayload(topicsDto) {
    this.notifyObservers('handleEntity', topicsDto);
  }
}

export function topicUrl(orgId, topicId) {
  return topicId ? `/api/v1/orgs/${orgId}/topics/${topicId}` : `/api/v1/orgs/${orgId}/topics`;
}

_.extend(TopicGateway.prototype, Observable);
