import Assignee from 'models/assignee';
import { createContentModel } from './composition_content_shared';

const TaskEditCompositionContent = createContentModel({
  modelName: 'TaskEditCompositionContent',

  properties: {
    assignee: Assignee,

    bodyHtml: String,
    dueAt: String,
    conversationItemId: String,
  },

  isEmpty() {
    return false;
  },
});

TaskEditCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB = 500;
TaskEditCompositionContent.MAX_TOTAL_ATTACHMENT_SIZE_MB = 1000;

export default TaskEditCompositionContent;
