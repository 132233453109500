import _ from 'lodash';
import PropTypes from 'prop-types';

import CancelUpdateEmailAddress from 'actions/customer_profile/cancel_update_email_address';
import connect from 'components/lib/connect';
import CustomerEmailEditor from './customer_email_editor';
import CustomerEmailField from './customer_email_field';
import getMultiValueErrors from './get_multi_value_errors';
import MultiValueField from './multi_value_field';
import UpdateEmailAddress from 'actions/customer_profile/update_email_address';

const CustomerEmailFields = MultiValueField(CustomerEmailField, CustomerEmailEditor);

CustomerEmailFields.defaultProps = {
  className: 'customerProfile-emailFields',
  isEmptyValue: isEmptyCustomerEmail,
  newValue: {},
};

export { CustomerEmailFields };

function isEmptyCustomerEmail({ email }) {
  return _.isEmpty(email);
}

const CustomerEmailFieldsContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerEmailFields);

function mapStateToProps({ getProvider }, { isAdding }) {
  let profileProvider = getProvider('profile');
  let errors = getErrors();
  let otherAttributeHasErrors = !_(profileProvider.getErrors()).isEmpty() && _(errors).isEmpty();
  let isModalOpen = !!getProvider('modal').get();
  let isMergePending = getProvider('customerMerge').isPending();

  return {
    errors,
    forceDisplay: isMergePending,
    isAdding,
    isDisabled: profileProvider.isLoading() || isModalOpen || otherAttributeHasErrors,
    isPending: !!profileProvider.getPending(),
    pendingValues: getPendingValues(),
    values: getValues(),
  };

  function getErrors() {
    return getMultiValueErrors(profileProvider.getErrors(), 'emails').map(mapCustomerEmail);
  }

  function getPendingValues() {
    return _(profileProvider.getPending())
      .get('emails', [])
      .map(mapCustomerEmail);
  }

  function getValues() {
    return _(profileProvider.get())
      .get('emails', [])
      .map(mapCustomerEmail);
  }
}

function mapExecuteToProps(executeAction, { onDone }) {
  return {
    onBlurNew: done,
    onCancel: cancel,
    onSave: save,
  };

  function cancel(index) {
    executeAction(CancelUpdateEmailAddress, { index });
    done();
  }

  function done() {
    if (onDone) {
      onDone();
    }
  }

  function save(index, { email, isPrimary }) {
    executeAction(UpdateEmailAddress, { index, original: email, primary: isPrimary });
    done();
  }
}

function mapCustomerEmail({ original, primary }) {
  return _.omitBy({ email: original, isPrimary: primary }, _.isUndefined);
}

CustomerEmailFieldsContainer.propTypes = {
  isAdding: PropTypes.bool,
  onDone: PropTypes.func,
};

export default CustomerEmailFieldsContainer;
