import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class ReportBuilderConfigService {
  find(compAttrs, callback, path, { orgId }) {
    const config = {
      enabled: true,
    };

    callback(null, { status: 200, statusText: statusText(200), response: config });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/report-builder': {
          GET: this.find,
        },
      },
      this
    );
  }
}
