import _ from 'lodash';

import UpdateProfileActionBase from './update_profile_action_base';
import { DomainError } from 'scripts/application/lib/error';
import Err from 'models/err';

export default class UpdateEmailAddress extends UpdateProfileActionBase {
  _update(profile, { index, original, primary }) {
    if (original) {
      profile.updateEmailAddress({ index, original, primary });
    } else {
      profile.deleteEmailAddress(index);
    }

    return { emails: profile.emails.map(email => email.toJs()) };
  }

  _getValidationErrors({ index, original }) {
    return [];
  }

  /**
   * Check the response and see if the error is due to a email conflict
   *
   * @param err
   * @param requestData
   * @returns {boolean}
   * @private
   */
  _shouldIgnoreResponseError(err, requestData) {
    if (err instanceof DomainError) {
      // Go through the nested errors and make sure we only see errors related to email conflicts
      const nestedErrors = err.errors || [];
      return nestedErrors.reduce((acc, err) => {
        const errorCode = _.get(err, 'code', '');
        const errorAttr = _.get(err, 'attr', '');

        return acc && errorCode === Err.Code.TAKEN && errorAttr.startsWith('emails.');
      }, true);
    }

    return super._shouldIgnoreResponseError(err, requestData);
  }
}
