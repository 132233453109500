import escapeHtml from 'escape-html';
import { MARK_COLOR, MARK_FONT_SIZE } from '@udecode/plate-font';
import _ from 'lodash';

export default function serializeHtmlText(node) {
  let string = escapeHtml(node.text);

  // We don't need to wrap empty strings in marks. This also messes with the empty paragraph
  // logic that makes <div><br></div> since it expects an empty string, not an awkwardly empty
  // <b><u></u></b> for example.
  if (!string) {
    return string;
  }

  // Serialize marks
  if (node.bold) {
    string = `<b>${string}</b>`;
  }
  if (node.italic) {
    string = `<i>${string}</i>`;
  }
  if (node.underline) {
    string = `<u>${string}</u>`;
  }

  if (node[MARK_COLOR] || node[MARK_FONT_SIZE]) {
    const color = _.trim(node[MARK_COLOR]);
    const fontSize = _.trim(node[MARK_FONT_SIZE]);

    const styleParts = [];
    if (color) {
      styleParts.push(`color:${color}`);
    }
    if (fontSize) {
      styleParts.push(`font-size:${fontSize}`);
    }

    const styleString = styleParts.join(';');
    if (styleString) {
      string = `<span style="${styleString}">${string}</span>`;
    }
  }

  // Serialize other line breaks.
  string = string.replace(new RegExp('\\n', 'g'), '<br>');

  return string;
}
