import MqttGateway from './lib/mqtt_gateway';

export default class AgentSearchResultGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'search/agents';
  }

  request(payload) {
    return super._fetch([], payload);
  }

  onFetchResponse(fetchResultDto, topic, parsedTopicParams, correlationId) {
    this.notifyObservers('handleFetchSuccess', { fetchResultDto, correlationId });
  }

  onFetchError(errorDto) {
    this.notifyObservers('handleFetchError', errorDto);
  }
}
