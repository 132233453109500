import _ from 'lodash';

import AgentProfile from 'models/agent_profile';
import AgentStatus from 'models/agent_status';
import CheckVersion from 'actions/version/check_version';
import getRuntimeFeatures from 'scripts/lib/get_runtime_features';
import qconsole from 'scripts/lib/qconsole';
import RefreshAuthToken from 'actions/user/refresh_auth_token';
import { replaceAuth } from 'actions/user/lib/auth';
import CheckForMultipleTabs from 'actions/multiple_tabs/check_for_multiple_tabs';

export default class LogIn {
  constructor(context) {
    this.context = context;
  }

  run({ claims }) {
    let auth = replaceAuth(this.context, { claims });
    if (!auth) {
      qconsole.warn('Received authentication token is invalid. Aborting login.');
      return;
    }

    this.context.scheduler.cancel(CheckVersion.jobId);

    this.initBackend(auth);
    this.initAnalytics(auth);
    this.initCurrentAgent(auth);

    this.context.gateways.featureSet.request();
    this.configureBackend({
      isMqttServerV1: this.context.stores.appFeatures.get().isEnabled('mqttServerV1'),
    });

    if (auth.isUserActivated() && !auth.isPasswordBeingReset()) {
      this.scheduleAuthRefresh(auth);
    }

    initSystemNotification();
    this.context.executeAction(CheckForMultipleTabs);
  }

  initAnalytics(auth) {
    const preferences = this.context.stores.agentPreferences.get();
    let user = {
      id: auth.getUserId(),
      orgId: auth.getOrgId(),
      ...getRuntimeFeatures(),
      activeExperimentalEditor: preferences && preferences.experimentalEditorEnabled,
    };
    this.context.analytics.setUser(user);
    this.context.analytics.setUserSnowplow(user);
  }

  initBackend(auth) {
    this.context.backend.connect('cookie', auth.getOrgId());
    this.initGateways(auth);
  }

  configureBackend({ isMqttServerV1 }) {
    this.context.backend.configureMqttServerV1(isMqttServerV1);
  }

  initCurrentAgent(auth) {
    this.context.stores.currentAgent.set(new AgentProfile({ id: auth.getUserId() }));
    this.context.stores.currentAgent.setLoading();
    this.context.stores.agentStatus.set(AgentStatus.create({ id: auth.getUserId() }));
  }

  initGateways(auth) {
    _.values(this.context.gateways).forEach(
      g => g.init && g.init({ agentId: auth.getUserId(), orgId: auth.getOrgId() })
    );
  }

  scheduleAuthRefresh(auth) {
    this.context.scheduler.executeEvery(auth.getRefreshDurationMS(), RefreshAuthToken.jobId, RefreshAuthToken);
  }
}

function initSystemNotification() {
  if ('Notification' in window && Notification.permission !== 'denied' && Notification.requestPermission) {
    Notification.requestPermission(function(permission) {
      if (permission === 'granted') {
        qconsole.log('Notifications enabled');
      } else {
        qconsole.warn('Notifications denied');
      }
    });
  } else {
    qconsole.warn('Notifications not supported');
  }
}
