import React from 'react';
import PropTypes from 'prop-types';

import AttributeDef, { AttributePresentation } from 'models/configuration/attribute_def';
import EditableCustomAttribute from './custom_attributes/editable_custom_attribute';
import VisibleCustomAttribute from './custom_attributes/visible_custom_attribute';

export function CustomAttribute({ value, def, renderProps, location }) {
  if (def.presentation === AttributePresentation.EDITABLE) {
    return <EditableCustomAttribute def={def} renderProps={renderProps} value={value} />;
  }

  return <VisibleCustomAttribute def={def} location={location} renderProps={renderProps} value={value} />;
}

CustomAttribute.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  renderProps: PropTypes.object,
  location: PropTypes.shape({
    type: PropTypes.string,
    subType: PropTypes.string,
  }),
};

export default CustomAttribute;
