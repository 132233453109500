import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import FullPageSpinner from 'components/common/full_page_spinner';
import InlineContainer from 'components/common/containers/inline_container';
import LogoWhite from 'components/logo_white';
import VoiceRow from './voice_row';
import ChatRow from './chat_row';
import SMSRow from './sms_row';
import MessengerRow from './messenger_row';
import EmailRow from './email_row';
import InstagramDirectRow from './instagram_direct_row';
import TwitterRow from './twitter_row';
import WhatsappRow from './whatsapp_row';
import FloorboardCapacity from './floorboard_capacity';
import Endpoint from 'models/endpoint';
import CloseCircleIcon from 'components/lib/icons/close_circle_icon';
import { LiveboardContextProvider } from 'components/liveboards/contexts/liveboard_context';

export default connect(mapStateToProps, null)(Floorboard);

export function Floorboard({ endpoints, location, liveboard }) {
  return !liveboard ? (
    <FullPageSpinner />
  ) : (
    <LiveboardContextProvider>
      <FloorboardContainer>
        <FloorboardGrid columns={5}>
          <Header inline="medium">
            <Logo />
            <FloorboardCapacity />
            <CloseIcon onClick={() => window.history.back()} />
          </Header>
          {hasEndpoint(Endpoint.Type.VOICE) && <VoiceRow />}
          {hasEndpoint(Endpoint.Type.CHAT) && <ChatRow />}
          {hasEndpoint(Endpoint.Type.SMS) && <SMSRow />}
          {hasEndpoint(Endpoint.Type.FB_MESSENGER) && <MessengerRow />}
          {hasEndpoint(Endpoint.Type.INSTAGRAM_DIRECT) && <InstagramDirectRow />}
          {hasEndpoint(Endpoint.Type.EMAIL) && <EmailRow />}
          {hasEndpoint(Endpoint.Type.TWITTER) && <TwitterRow />}
          {hasEndpoint(Endpoint.Type.WHATSAPP) && <WhatsappRow />}
        </FloorboardGrid>
      </FloorboardContainer>
    </LiveboardContextProvider>
  );

  function hasEndpoint(type) {
    return (endpoints || []).some(e => e.type === type);
  }
}

function mapStateToProps({ getProvider }) {
  let liveboard = getProvider('liveboard').get();
  let { endpoints } = getProvider('channelConfiguration').get() || {};
  return {
    liveboard,
    endpoints,
    location: getProvider('currentLocation').get(),
  };
}

const FloorboardContainer = styled.div`
  background-color: ${p => p.theme.colors.black};
  color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 10px;
  width: 100vw;
  overflow: hidden;
`;

const FloorboardGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: minmax(60px, auto);
  grid-gap: 0;
  grid-template-columns: minmax(60px, 110px) repeat(3, minmax(20px, auto)) 25%;
  grid-template-rows: minmax(40px, 60px);
  height: auto;
  justify-items: stretch;
  min-height: 100vh;
  min-height: 100vh;
`;

const Header = styled(InlineContainer)`
  grid-column-end: span 5;
  grid-row-end: span 1;
  padding-left: 60px;
  padding-right: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  display: flex;
  flex-grow: 0;
`;

const CloseIcon = styled(CloseCircleIcon)`
  fill: ${p => p.theme.colors.white};
  width: 2vh;
  align-self: flex-start;
  cursor: pointer;
  && {
    margin-left: auto;
    margin-right: 0;
  }
`;

const Logo = styled(LogoWhite)`
  height: 3.5vh;
`;
