import React from 'react';

import { PHRASE_SUGGESTION } from 'components/text_editor/plugins/phrase_suggestions';

export const phraseSuggestionRules = [
  {
    serialize(object, children) {
      if (object.object === 'inline' && object.type === PHRASE_SUGGESTION) {
        return <React.Fragment />;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'phrasesuggestion') {
        return {
          object: 'inline',
          type: PHRASE_SUGGESTION,
          nodes: next(el.childNodes),
          data: { text: el.textContent, type: el.getAttribute('type') },
        };
      }
    },
  },
];
