import CompositionConverter from 'scripts/application/dto_converters/composition_converter';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default function addOrUpdateComposition(context, composition) {
  const agentId = context.stores.currentAgent.get().id;

  const compositionsStore = getCompositionsStore(context, composition.customerId);

  if (!composition.persisted) {
    composition.markAsPersisted();

    // Replace in case we cancel the dialogue box.
    if (compositionsStore && compositionsStore.has({ id: composition.id })) {
      compositionsStore.replace(composition);
    }
    context.stores.localCompositions.set(composition.customerId, composition);

    context.gateways.composition.add(agentId, composition.customerId, CompositionConverter.toDto(composition));
  } else {
    context.gateways.composition.update(
      agentId,
      composition.customerId,
      composition.id,
      CompositionConverter.toDto(composition)
    );
  }
}
