import _ from 'lodash';

import Attachment from './attachment';
import redactAttachment from './attachment_redact';
import createModel, { prop } from './lib/create_model';
import htmlToText from './conversation_item/html_to_text';
import Mention from './mention';

const ConversationNote = createModel({
  modelName: 'ConversationNote',

  properties: {
    attachments: [Attachment],
    bodyHtml: prop(String),
    content: prop(String),
    isRedacted: prop(Boolean),
    mentions: [Mention],
  },

  withContent(content) {
    return ConversationNote.create(_.merge({}, this, { content }));
  },

  redactAttachment(attachmentId) {
    redactAttachment(this.attachments, attachmentId);
  },

  getMessageText() {
    return this.content;
  },

  statics: {
    create(attrs) {
      if (!attrs.content) {
        attrs = {
          ...attrs,
          content: htmlToText(attrs.bodyHtml),
        };
      }
      return new this(attrs);
    },
  },
});

export default ConversationNote;
