import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import PrepositionalPhrase from '../metadata/prepositional_phrase';
import SwitchCurrentConversation from 'actions/customer/switch_current_conversation';

export function VoiceRecordingDeletedItem({ item, onClickRecordingLink }) {
  let recordingComplement = (
    <a className="conversationItem-metadata-link" onClick={onClickRecordingLink}>
      recording
    </a>
  );
  return (
    <ItemContent isAuditItem item={item}>
      <ItemMetadata
        item={item}
        prepositionalPhrases={[
          <PrepositionalPhrase complement={recordingComplement} key={`deletedRecording-${item.id}`} />,
        ]}
        text="deleted the"
      />
    </ItemContent>
  );
}

VoiceRecordingDeletedItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onClickRecordingLink: PropTypes.func.isRequired,
};

function mapStateToProps(context, { item }) {
  return { item };
}

function mapExecuteToProps(executeAction, { conversationId, item }) {
  return {
    onClickRecordingLink: () => {
      executeAction(SwitchCurrentConversation, {
        conversationId,
        conversationItemId: item.content.conversationItemId,
      });
    },
  };
}

const VoiceRecordingDeletedItemContainer = connect(mapStateToProps, mapExecuteToProps)(VoiceRecordingDeletedItem);

VoiceRecordingDeletedItemContainer.propTypes = {
  conversationId: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default VoiceRecordingDeletedItemContainer;
