import createModel, { prop } from 'models/lib/create_model';

export default createModel({
  modelName: 'AgentSearchHit',

  properties: {
    id: prop(String).isRequired,
    agent: prop({
      email: String,
      id: prop(String).isRequired,
      name: String,
    }),
    routingGroup: prop({
      id: prop(String).isRequired,
      name: String,
    }),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
