import _ from 'lodash';
import { Editor, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { createPluginFactory } from '@udecode/plate';
import React from 'react';
import { ReactEditor } from 'slate-react';

import AITextCompletionPreview from 'components/text_editor_new/plugins/ai/components/ai_text_completion_preview';
import ensureFullSelection from 'components/text_editor_new/lib/ensure_full_selection';
import getNodes from 'components/text_editor_new/lib/get_nodes';
import { editorHasNoText } from 'components/text_editor_new/lib/editor_has_no_text';
import { hasSingleSelectedInlineNode } from 'components/text_editor_new/lib/has_single_selected_inline_node';
import { isCollapsed } from 'components/text_editor_new/lib/is_collapsed';
import selectAll from 'components/text_editor_new/lib/select_all';
import { UseAITextCompletion } from './use_ai_text_completion';

export const MARK_AI_TEXT_COMPLETION_SELECTION = 'ai-text-completion-selection';

// The AITextCompletion plugin allows the user to select some text, an mark some text as a selection that we will
// potentially replace with AI-generated text.
export default function AITextCompletion() {
  return createPluginFactory({
    key: MARK_AI_TEXT_COMPLETION_SELECTION,
    isLeaf: true,
    withOverrides: withAITextCompletion,

    handlers: {
      onChange: editor => () => {
        ensureFullSelection(editor, n => !!n[MARK_AI_TEXT_COMPLETION_SELECTION]);
      },
    },

    renderAfterEditable: () => {
      return (
        <>
          <UseAITextCompletion />
          <AITextCompletionPreview />
        </>
      );
    },
  })();

  function withAITextCompletion(editor) {
    const { apply } = editor;

    // Don't allow changes to the editor while we're in the middle of an AI text edit
    editor.apply = operation => {
      if (editor.aiTextCompletionSelectionRef && editor.aiTextCompletionSelectionRef.current) {
        return;
      }
      apply(operation);
    };

    editor.initiateAITextCompletion = () => {
      if (editor.shouldDisableTextCompletion()) {
        return;
      }

      let selection = editor.selection;
      if (!selection) {
        return;
      }

      // If selection is collapsed, then we want the completion to be performed over all the text
      // in the editor.
      if (isCollapsed(selection)) {
        selection = selectAll(editor);
        if (!selection || isCollapsed(selection)) {
          return;
        }
      }

      // Don't save the mark in the history so that undoing doesn't accidentally
      // return to having this mark. We remove ai text edit responses from the store after
      // cancelling or completing, so it doesn't make sense to show the mark after undoing
      // unless we want to get more complicated with the store (e.g. not actually deleting
      // the text edit responses until the composer is sent or closed).
      HistoryEditor.withoutSaving(editor, () => {
        Editor.addMark(editor, MARK_AI_TEXT_COMPLETION_SELECTION, true);
      });

      // rangeRefs will try to maintain the range initially specified even if the editor changes.
      // The affinity determines _how_ the range changes based on certain edits. In our case, our
      // initial range spans the selection of text that is our current "AI text selection".

      // If an agent chooses to cancel the completion, we want the editor selection to be reverted
      // to the original selection they made so it doesn't feel awkward. When we remove the AI selection
      // mark, it messes with things, and the range tightens up a bit and can lose parts of the selection
      // if the selection included empty lines. Setting the affinity to 'outward' will ensure that we don't
      // lose as much of this selection, keeping it more consistent with the original selection.
      editor.aiTextCompletionSelectionRef = Editor.rangeRef(editor, editor.selection, {
        affinity: 'outward',
      });
    };

    editor.insertAITextCompletion = text => {
      const newSelection = editor.aiTextCompletionSelectionRef.unref();
      editor.aiTextCompletionSelectionRef = null;

      // Don't save to the history - we want to entirely skip this stuff on undo.
      removeTextCompletionMarkAtRange(newSelection);
      Transforms.delete(editor);
      editor.highlightAITextCompletion();
      Editor.insertText(editor, text);
    };

    editor.removeAITextCompletion = () => {
      if (editor.aiTextCompletionSelectionRef) {
        const range = editor.aiTextCompletionSelectionRef.unref();
        editor.aiTextCompletionSelectionRef = null;
        removeTextCompletionMarkAtRange(range);
      }
    };

    editor.shouldDisableTextCompletion = () => {
      const isEmpty = editorHasNoText(editor);
      if (isEmpty) {
        return true;
      }

      let selection = editor.selection;
      if (!selection || isCollapsed(selection)) {
        return false;
      }

      return !!hasSingleSelectedInlineNode(editor, selection);
    };

    const { insertText } = editor;
    editor.insertText = text => {
      const marks = Editor.marks(editor);
      if (marks[MARK_AI_TEXT_COMPLETION_SELECTION]) {
        const leafEntry = Editor.leaf(editor, editor.selection);
        const leafPath = leafEntry[1];
        const point = Editor.after(editor, leafPath);

        Transforms.setSelection(editor, {
          anchor: point,
          focus: point,
        });
      }
      insertText(text);
    };

    function removeTextCompletionMarkAtRange(selection) {
      HistoryEditor.withoutSaving(editor, () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);

        const rangeRef = Editor.rangeRef(editor, selection, { affinity: 'outward' });
        Editor.removeMark(editor, MARK_AI_TEXT_COMPLETION_SELECTION);

        // Unfortunately, there are certain cases where there are stranded marks that need to cleaned up still.
        const markNodes = getNodes(editor, { at: [], match: n => !!n[MARK_AI_TEXT_COMPLETION_SELECTION] });
        _.forEach(markNodes, ([, path]) => {
          Transforms.unsetNodes(editor, MARK_AI_TEXT_COMPLETION_SELECTION, { at: path });
        });

        // And unfortunately Slate does not track the selection correctly after removing the marks esp. with
        // regards to empty text nodes. Still not perfect HACK.
        Transforms.select(editor, rangeRef.unref());
      });
    }

    return editor;
  }
}
