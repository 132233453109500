import _ from 'lodash';

const ALLOWED_KEYS = ['a', 'c'];

export default function ReadOnly(readOnly) {
  if (readOnly) {
    return {
      onBeforeInput: evt => {
        evt.preventDefault();
        return true;
      },
      onCut: evt => {
        evt.preventDefault();
        return true;
      },
      onDrop: evt => {
        evt.preventDefault();
        return true;
      },
      onInput: evt => {
        evt.preventDefault();
        return true;
      },
      onKeyDown: (evt, editor, next) => {
        if ((evt.metaKey || evt.ctrlKey) && _.includes(ALLOWED_KEYS, evt.key)) {
          return next();
        }
        evt.preventDefault();
        return true;
      },
      onPaste: evt => {
        evt.preventDefault();
        return true;
      },
    };
  }

  return {};
}
