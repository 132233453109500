import SoftwareVersionService from 'actions/version/software_version_service';
import qconsole from 'scripts/lib/qconsole';

export default function() {
  SoftwareVersionService.getVersion = () => window.gladlyConfig.release;

  SoftwareVersionService.upgrade = () => {
    qconsole.log(`Upgrading Gladly software by reloading the browser at [${window.location}]`);
    window.location.reload(true);
  };
}
