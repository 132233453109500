import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import connect from 'components/lib/connect';
import CreateProfile from 'actions/customer_profile/create_profile';
import { MenuContainer, MenuItem } from '../lib/menu_deprecated';
import Spinner, { SpinnerContainer } from 'components/common/spinner';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function AddCustomerButton({ isNewCustomerPending }) {
  const executeAction = useExecuteAction();
  const onAddCustomer = useCallback(() => {
    analytics.track('Add Customer Button Clicked', { location: 'team-inbox' });
    executeAction(CreateProfile, {});
  }, []);

  let spinner = <StyledSpinner color="white" />;
  let plus = <i className="icon-plus" />;
  return (
    <MenuContainer
      button={isNewCustomerPending ? spinner : plus}
      className="agentHome-menu"
      isDisabled={isNewCustomerPending}
      isOpen
    >
      <MenuItem
        className="agentHome-menu-item agentHome-addCustomer-menu-item"
        isDisabled={isNewCustomerPending}
        onSelect={onAddCustomer}
      >
        Add New Customer
      </MenuItem>
    </MenuContainer>
  );
}

AddCustomerButton.propTypes = {
  isNewCustomerPending: PropTypes.bool,
};

function mapStateToProps({ getProvider }) {
  let customersProvider = getProvider('customers');
  return { isNewCustomerPending: customersProvider.isPendingNew() };
}

const StyledSpinner = styled(Spinner)`
  ${SpinnerContainer} {
    height: 24px;
    left: 6px;
    top: 6px;
    width: 24px;
  }
`;

export default connect(mapStateToProps)(AddCustomerButton);
