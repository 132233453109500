import _ from 'lodash';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import CancelScheduledReport from 'actions/reporting/cancel_scheduled_report';
import Chip from 'components/common/chip';
import connect from 'components/lib/connect';
import DropdownMenu from 'components/common/dropdown_menu';
import FullPageModal, { Footer } from 'components/common/full_page_modal';
import { H1, H4 } from 'components/common/headers';
import Input from 'components/common/input';
import InlineContainer from 'components/common/containers/inline_container';
import InsetContainer from 'components/common/containers/inset_container';
import moment from 'moment';
import ReportChannel from 'models/reports/report_channel';
import SaveScheduledReport from 'actions/reporting/save_scheduled_report';
import ScheduledReportError from './scheduled_report_error';
import { Frequency, Interval, ReportFormat } from 'models/reports/scheduled_report';
import Spinner from 'components/common/spinner';
import { getErrorMessageList } from 'components/lib/error_handler';

const dataAidPrefix = 'schedule-recurring-report-modal-';

const FREQUENCY_OPTIONS = _.map(Frequency, option => {
  return { label: _.capitalize(option), value: option };
});

const INTERVAL_OPTIONS = _.map(Interval, option => {
  return { label: _.lowerCase(option) + '(s)', value: option };
});

const FORMAT_OPTIONS = _.map(ReportFormat, option => {
  return { label: option, value: option };
});

const ScheduleRecurringReportModal = function({ scheduledReport, onClose, onSave, isLoading, errors }) {
  const [recipients, setRecipients] = useState(
    _.map(
      _.filter(_.get(scheduledReport, 'recipients'), r => r.type === 'EMAIL'),
      r => r.address
    )
  );
  const onChangeRecipients = useCallback(r => setRecipients(r), [setRecipients]);

  const [name, setName] = useState(_.get(scheduledReport, 'name'));
  const onChangeName = useCallback(e => setName(e.target.value), [setName]);

  const [frequency, setFrequency] = useState(FREQUENCY_OPTIONS[0].value);
  const onChangeFrequency = useCallback(newFrequency => setFrequency(newFrequency), [setFrequency]);

  const [format, setFormat] = useState(FORMAT_OPTIONS[0].value);
  const onChangeFormat = useCallback(newFormat => setFormat(newFormat), [setFormat]);

  const [interval, setInterval] = useState(INTERVAL_OPTIONS[0].value);
  const onChangeInterval = useCallback(newInterval => setInterval(newInterval), [setInterval]);

  const [quantity, setQuantity] = useState('');
  const onChangeQuantity = useCallback(e => setQuantity(e.target.value), [setQuantity]);

  const errorArray = getStateErrorsFromProps(errors);

  const onSaveReport = () => {
    onSave({
      name,
      recipients,
      format,
      frequency,
      interval,
      quantity,
      filters: scheduledReport.filters,
      timezone: scheduledReport.timezone,
      metricSet: scheduledReport.metricSet,
    });
  };

  const getContent = () => {
    const reportFilters = _.get(scheduledReport, 'filters');
    let filters = [];
    if (!_.isUndefined(reportFilters.channel)) {
      const channelName =
        reportFilters.channel === 'allChannels' || reportFilters.channel === ReportChannel.ALL
          ? 'All Channels'
          : `${_.capitalize(reportFilters.channel)} Channel`;
      filters.push(channelName);
    }
    if (!_.isUndefined(reportFilters.inboxes)) {
      filters.push(
        _.size(reportFilters.inboxes) > 0
          ? _.size(reportFilters.inboxes) === 1
            ? '1 Inbox'
            : `${_.size(reportFilters.inboxes)} Inboxes`
          : 'All Inboxes'
      );
    }
    if (!_.isUndefined(reportFilters.teams)) {
      filters.push(
        _.size(reportFilters.teams) > 0
          ? _.size(reportFilters.teams) === 1
            ? '1 Team'
            : `${_.size(reportFilters.teams)} Teams`
          : 'All Teams'
      );
    }

    let SubmitButtonContents;
    if (isLoading) {
      SubmitButtonContents = (
        <StyledSpinnerContainer>
          <Spinner color="FFF" stroke={1} />
        </StyledSpinnerContainer>
      );
    } else {
      SubmitButtonContents = 'Schedule Report';
    }

    const filterComponent = (
      <StyledFilterContainer data-aid={`${dataAidPrefix}filter`}>
        {_.map(filters, filter => {
          return <Chip key={filter} label={filter} />;
        })}
      </StyledFilterContainer>
    );

    return (
      <React.Fragment>
        <Body>
          <StyledTitle>Schedule recurring report</StyledTitle>
          <StyledTitleDetails>
            If you have any questions, take a look at {'              '}
            <a href="https://help.gladly.com/docs/schedule-a-report/" rel="noopener noreferrer" target="_blank">
              {' '}
              our guide.
            </a>
          </StyledTitleDetails>
          <OptionDetail>
            {_.get(scheduledReport, 'name') || 'Unknown'} report has {_.size(filters)} filters applied.
          </OptionDetail>
          {!_.isEmpty(filters) && filterComponent}

          <OptionHeader>Email Subject</OptionHeader>
          <div>
            <FormTextInput
              attr="emailSubject"
              autoFocus
              data-aid={`${dataAidPrefix}subject`}
              label="Email Subject"
              onChange={onChangeName}
              placeholder={_.get(scheduledReport, 'name') || 'Declined and Missed Calls'}
              value={name}
            />
          </div>

          <OptionHeader>Email To</OptionHeader>
          <FormEmailsInput emails={recipients} onChange={onChangeRecipients} />
          <ScheduledReportError errors={_.get(errorArray, 'recipients')} />
          <OptionHeader>Report Format</OptionHeader>
          <div>
            <DropdownMenu
              data-aid={`${dataAidPrefix}format`}
              onSelect={onChangeFormat}
              options={FORMAT_OPTIONS}
              value={format}
            />
          </div>

          <OptionHeader>Delivery Schedule</OptionHeader>
          <DetailContainer>
            <OptionDetail>Select how often you would like to receive this report.</OptionDetail>
          </DetailContainer>
          <FrequencyContainer>
            <div>
              <DropdownMenu
                data-aid={`${dataAidPrefix}frequency`}
                onSelect={onChangeFrequency}
                options={FREQUENCY_OPTIONS}
                value={frequency}
              />
            </div>
            <div>
              The report is usually available by 6am{' '}
              {_.get(scheduledReport, 'timezone')
                ? moment()
                    .tz(_.get(scheduledReport, 'timezone'))
                    .format('z')
                : "in your organization's timezone"}
              .
            </div>
          </FrequencyContainer>

          <OptionHeader>Date Range</OptionHeader>
          <OptionDetail> Choose the date range that should be included in the scheduled report. </OptionDetail>
          <LookbackContainer>
            <div>For the past</div>
            <div>
              <FormNumberInput
                data-aid={`${dataAidPrefix}quantity`}
                onChange={onChangeQuantity}
                placeholder="Enter a number"
                value={quantity}
              />
            </div>
            <div>
              <DropdownMenu
                data-aid={`${dataAidPrefix}interval`}
                onSelect={onChangeInterval}
                options={INTERVAL_OPTIONS}
                value={interval}
              />
            </div>
          </LookbackContainer>
          <ScheduledReportError errors={_.get(errorArray, 'quantity')} />
        </Body>

        <Footer>
          <Button data-aid={`${dataAidPrefix}cancel`} onClick={onClose} type={Button.Types.TEXT}>
            Cancel
          </Button>
          <Button
            data-aid={`${dataAidPrefix}save`}
            onClick={isLoading ? _.noop : onSaveReport}
            type={ButtonTypes.PRIMARY}
          >
            <SubmitButtonContainer>{SubmitButtonContents}</SubmitButtonContainer>
          </Button>
        </Footer>
      </React.Fragment>
    );
  };

  return <FullPageModal content={getContent()} data-aid={`${dataAidPrefix}content`} onClose={onClose} visible />;
};

ScheduleRecurringReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  scheduledReport: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const modal = getProvider('modal').get() || {};

  return {
    scheduledReport: modal,
    isLoading: getProvider('scheduledReport').isPending(),
    errors: getProvider('scheduledReport').getErrors(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClose: () => {
      executeAction(CancelScheduledReport);
    },
    onSave: scheduledReport => {
      executeAction(SaveScheduledReport, { params: scheduledReport });
    },
  };
}

function getStateErrorsFromProps(errors) {
  return {
    quantity: getErrorMessageList({ errors, attr: 'quantity' }),
    recipients: getErrorMessageList({ errors, attr: 'recipients' }),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(ScheduleRecurringReportModal);

const Body = styled(InsetContainer).attrs({ inset: 'large' })`
  flex-grow: 1;
  max-width: 600px;
  width: 80%;
  padding-top: 0;
`;

const OptionHeader = styled.p`
  font-weight: bold;
  margin-top: ${p => p.theme.spacing.insetXLarge};
  margin-bottom: ${p => p.theme.spacing.medium};
`;

const StyledTitle = styled(H1)`
  display: inline;
  text-align: center;
`;

const StyledTitleDetails = styled(H4)`
  display: inline;
  text-align: center;
  margin-bottom: ${p => p.theme.spacing.insetXLarge};
`;

const StyledFilterContainer = styled(InlineContainer).attrs({ inline: 'small', inset: 'small' })`
  padding: ${p => p.theme.spacing.medium};
  padding-bottom: 0px;
  padding-left: 0px;
`;

const OptionDetail = styled.p`
  margin: 0px;
`;

const LookbackContainer = styled(InlineContainer).attrs({ inline: 'small', inset: 'small' })`
  align-items: center;
  padding-left: 0px;
`;

const FrequencyContainer = styled(InlineContainer).attrs({ inline: 'small', inset: 'small' })`
  align-items: center;
  padding-left: 0px;
`;

const DetailContainer = styled.p`
  margin: 0px;
  padding-bottom: 8px;
`;

const FormTextInput = styled(Input)`
  border: none;
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: 4px;
  min-height: 30px;
  padding-left: ${p => p.theme.spacing.insetSmall};
  width: 550px;
`;

export const FormNumberInput = styled(({ hasErrors, ...props }) => <Input {...props} />)`
  border: none;
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: 4px;
  min-height: 30px;
  padding-left: ${p => p.theme.spacing.insetSmall};
  width: 125px;
  ${props =>
    props.hasErrors &&
    css`
      border-color: ${p => p.theme.colors.red400};
      margin-bottom: ${p => p.theme.spacing.stackSmall};
    `};
  &::placeholder {
    color: ${p => p.theme.colors.gray600};
  }
`;

const StyledSpinnerContainer = styled.div`
  width: 16px;
  display: inline-block;
`;

const SubmitButtonContainer = styled.div`
  width: 103px;
`;

const FormEmailsInput = ({ emails, onChange }) => {
  const [entry, setEntry] = useState('');
  const onChangeEntry = useCallback(e => setEntry(e.target.value), [setEntry]);

  const addEntry = useCallback(() => {
    let email = entry.trim();
    email && onChange(emails.concat(email));
    setEntry('');
  }, [onChange, emails, entry]);

  const onKeyDown = useCallback(
    evt => {
      if (evt.key === 'Enter' || evt.key === ' ' || evt.key === ',') {
        evt.stopPropagation();
        evt.preventDefault();
        addEntry();
      }
    },
    [addEntry]
  );

  const removeEmail = useCallback(idx => onChange(emails.filter((e, i) => i !== idx)), [onChange, emails]);

  // click anywhere in the field to focus
  const inputRef = useRef(null);
  const [isEditing, setEditing] = useState(false);
  const onClickContainer = useCallback(() => setEditing(true), [setEditing]);
  const onBlurInput = useCallback(() => {
    addEntry();
    setEditing(false);
  }, [setEditing, addEntry]);
  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // handle resizing the text input
  useEffect(() => {
    let el = inputRef.current.input;
    if (el && el.style) {
      if (emails.length > 0 || el.value) {
        // make the input fit its width
        el.style.width = '10px'; // reset the width to something small
        el.style.width = `${el.scrollWidth + 10}px`; // grow to fit the content
      } else {
        // reset to the default width
        el.style.width = null;
      }
    }
  }, [entry, emails]);

  return (
    <StyledEmailContainer onClick={onClickContainer}>
      {_.map(emails, (email, i) => (
        <StyledEmailChip key={`${i}-${email}`} label={email} onDelete={() => removeEmail(i)} />
      ))}
      <StyledEmailEntryInput
        attr="emailEntry"
        autoFocus
        data-aid="email-entry-input"
        label="Email Entry"
        onBlur={onBlurInput}
        onChange={onChangeEntry}
        onKeyDown={onKeyDown}
        placeholder={emails.length > 0 ? '' : 'e.g you@yourcompany.com'}
        ref={inputRef}
        value={entry}
      />
    </StyledEmailContainer>
  );
};

const StyledEmailChip = styled(Chip)`
  margin: 8px 8px 0 0;
`;

const StyledEmailEntryInput = styled(Input)`
  border: none;
  min-height: 35px; /* match the chip height */
  margin: 8px 8px 0 0; /* match the chip margin */
  padding: 0;
  width: 200px;
`;

const StyledEmailContainer = styled(InlineContainer).attrs({ inline: 'small', inset: 'small' })`
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.default};
  background: ${p => p.theme.colors.white};
  cursor: text;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 8px 8px; /* make up for the margin on the chips */
`;
