import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ANSWER_LINK_ACTIVATION_CHARACTER } from 'components/text_editor/plugins/answer_link_editing';
import CreateTableMenu from 'components/customer/composition/lib/draft/draft_styles_table_menu';
import Emoji from 'components/composer/shared/emoji';
import { getSnippetUploadUrl } from 'scripts/domain/services/attachments';
import InlineImageButton from 'components/lib/inline_image_button';
import { ORDERED_LIST, UNORDERED_LIST } from 'components/text_editor/plugins/lists/constants';
import PlaceholderIcon from 'components/lib/icons/placeholder_icon';
import SlateAlignmentMenu from 'components/customer/composition/lib/slate/slate_alignment_menu';
import SlateFontColorMenu from 'components/customer/composition/lib/slate/slate_font_color_menu';
import SlateFontSizeMenu from 'components/customer/composition/lib/slate/slate_font_size_menu';
import SlateTableCellFillMenu from 'components/customer/composition/lib/slate/slate_tables/slate_table_cell_fill_menu';
import SlateTableRowColumnMenu from 'components/customer/composition/lib/slate/slate_tables/slate_table_row_column_menu';
import { SlateTextDecreaseIndentationMenu, SlateTextIncreaseIndentationMenu } from './slate_text_indentation_menu';
import { SnippetContentType, ChannelFieldName } from 'models/answers/snippet';
import { VARIABLE_ACTIVATION_CHARACTER } from 'components/text_editor/plugins/variable_editing';

import { Item, Menu, Separator } from './slate_styles_menu.styles';

class SlateStylesMenu extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'createTable',
      'onInsertImage',
      'getOnInsertImageInputChange',
      'startAnswerLinkMention',
      'startVariableMention',
      'toggleBold',
      'toggleItalic',
      'toggleLink',
      'toggleOrderedList',
      'togglePlaceholder',
      'toggleUnderline',
      'toggleUnorderedList',
    ]);
  }

  render() {
    if (this.props.channel === ChannelFieldName.SELF_SERVICE) {
      return this.renderSelfServiceMenu();
    }

    if (this.props.channel === ChannelFieldName.MESSAGE) {
      return this.renderMessageMenu();
    }

    const isEmailChannel = this.props.channel === ChannelFieldName.ANY_CHANNEL;

    return (
      <Menu className={classnames('slateStylesMenu', this.props.className)} data-aid="slateStylesMenu">
        <SlateFontSizeMenu editor={this.props.editor} onChange={this.props.onChange} />
        {(this.props.channel === ChannelFieldName.INFO || isEmailChannel) && (
          <SlateFontColorMenu editor={this.props.editor} onChange={this.props.onChange} />
        )}
        {this.renderRichText()}
        <Separator />
        {this.renderOrderedList()}
        {this.renderUnorderedList()}
        <SlateAlignmentMenu
          editor={this.props.editor}
          isSelectionInTable={this.isSelectionInTable()}
          onChange={this.props.onChange}
        />
        {isEmailChannel && (
          <SlateTextDecreaseIndentationMenu editor={this.props.editor} onChange={this.props.onChange} />
        )}
        {isEmailChannel && (
          <SlateTextIncreaseIndentationMenu editor={this.props.editor} onChange={this.props.onChange} />
        )}
        <Separator />
        {this.renderLink()}
        <Item
          data-aid="placeholder"
          isActive={this.isInlineActive('placeholder')}
          onClick={this.togglePlaceholder}
          title="Required Field"
        >
          <PlaceholderIcon />
        </Item>
        <VariableItem data-aid="variable" onClick={this.startVariableMention}>
          <ItemLabel>/var</ItemLabel>
        </VariableItem>
        {this.props.channel === ChannelFieldName.INFO && (
          <AnswerLinkItem data-aid="answerLink" onClick={this.startAnswerLinkMention}>
            <ItemLabel>+ref</ItemLabel>
          </AnswerLinkItem>
        )}
        <Separator />
        <Emoji editor={this.props.editor} />
        {this.props.channel === ChannelFieldName.INFO && this.renderInlineImageAndTableMenusInfoChannel()}
        {this.props.channel === ChannelFieldName.ANY_CHANNEL &&
          this.renderInlineImageButtonForAnyChannel(SnippetContentType.ANY_CHANNEL)}
        {this.props.children}
      </Menu>
    );
  }

  renderSelfServiceMenu() {
    return (
      <LeftAlignedMenu
        className={classnames('slateStylesMenu', this.props.className)}
        data-aid="selfService-slateStylesMenu"
      >
        <SlateFontColorMenu editor={this.props.editor} onChange={this.props.onChange} />
        {this.renderRichText()}
        <Separator />
        <React.Fragment>
          {this.renderOrderedList()}
          {this.renderUnorderedList()}
          <Separator />
        </React.Fragment>
        <Emoji editor={this.props.editor} />
        {this.renderLink()}
        {this.renderInlineImageButtonForAnyChannel(SnippetContentType.SELF_SERVICE)}
      </LeftAlignedMenu>
    );
  }

  renderMessageMenu() {
    return (
      <LeftAlignedMenu
        className={classnames('slateStylesMenu', this.props.className)}
        data-aid="selfService-slateStylesMenu"
      >
        <Item
          data-aid="placeholder"
          isActive={this.isInlineActive('placeholder')}
          onClick={this.togglePlaceholder}
          title="Required Field"
        >
          <PlaceholderIcon />
        </Item>
        <VariableItem data-aid="variable" onClick={this.startVariableMention}>
          <ItemLabel>/var</ItemLabel>
        </VariableItem>
        <Emoji editor={this.props.editor} />
      </LeftAlignedMenu>
    );
  }

  renderInlineImageButtonForAnyChannel(snippetContentType) {
    return (
      <InlineImageButton
        accept="image/*"
        className="slateStylesMenu-image"
        onInsertImage={this.getOnInsertImageInputChange(snippetContentType)}
        ref={button => (this.inlineImageButton = button)}
      />
    );
  }

  renderInlineImageAndTableMenusInfoChannel() {
    return (
      <React.Fragment>
        {this.isSelectionInTable() ? (
          <SlateTableRowColumnMenu editor={this.props.editor} onChange={this.props.onChange} />
        ) : (
          <CreateTableMenu onCreateTable={this.createTable} />
        )}
        <SlateTableCellFillMenu
          editor={this.props.editor}
          isSelectionInTable={this.isSelectionInTable()}
          onChange={this.props.onChange}
        />
        {this.renderInlineImageButtonForAnyChannel(SnippetContentType.INFO)}
      </React.Fragment>
    );
  }

  renderRichText() {
    return (
      <React.Fragment>
        <BoldItem data-aid="bold" isActive={this.isMarkActive('bold')} onClick={this.toggleBold} title="Bold">
          B
        </BoldItem>
        <ItalicItem data-aid="italic" isActive={this.isMarkActive('italic')} onClick={this.toggleItalic} title="Italic">
          i
        </ItalicItem>
        <UnderlineItem
          data-aid="underline"
          isActive={this.isMarkActive('underline')}
          onClick={this.toggleUnderline}
          title="Underline"
        >
          U
        </UnderlineItem>
      </React.Fragment>
    );
  }

  renderLink() {
    return (
      <Item data-aid="link" isActive={this.isInlineActive('link')} onClick={this.toggleLink} title="Link">
        <i className="fa fa-link" />
      </Item>
    );
  }

  renderOrderedList() {
    return (
      <Item
        data-aid="orderedList"
        isActive={this.isListActive('ordered_list')}
        onClick={this.toggleOrderedList}
        title="Ordered list"
      >
        <i className="fa fa-list-ol" />
      </Item>
    );
  }

  renderUnorderedList() {
    return (
      <Item
        data-aid="unorderedList"
        isActive={this.isListActive('unordered_list')}
        onClick={this.toggleUnorderedList}
        title="Unordered list"
      >
        <i className="fa fa-list-ul" />
      </Item>
    );
  }

  isSelectionInTable() {
    const editor = this.props.editor;
    return editor && editor.isSelectionInTable();
  }

  getOnInsertImageInputChange(snippetContentType) {
    return () => {
      const editor = this.props.editor;
      if (!editor) {
        return;
      }
      for (let i = 0; i < this.inlineImageButton.input.files.length; i++) {
        const file = this.inlineImageButton.input.files[i];
        if (file.type.startsWith('image/')) {
          let imageBlocks = editor.getImageBlocks();
          this.props.onStartUpload(
            this.inlineImageButton.input.files[i],
            snippetContentType, // should this be snippet channel
            this.onInsertImage,
            true,
            imageBlocks.size
          );
        }
      }
      this.inlineImageButton.input.value = '';
    };
  }

  /**
   * @param imageObj object containing image asset
   * @param imageObj.image the uploaded image asset object
   * @param imageObj.imageUrl the public url of a self service channel answer image asset
   * @param imageObj.orgId the org id of an info channel answer image asset
   */
  onInsertImage(imageObj) {
    const editor = this.props.editor;
    let src = '';
    switch (this.props.channel) {
      case ChannelFieldName.INFO:
      case ChannelFieldName.ANY_CHANNEL:
        src = getSnippetUploadUrl(imageObj.orgId, this.props.snippetId, imageObj.image);
        break;
      case ChannelFieldName.SELF_SERVICE:
        src = imageObj.imageUrl;
        break;
      default:
        return;
    }
    this.props.onChange(editor.insertImage({ src, attachmentId: imageObj.image.id }));
  }

  isMarkActive(style) {
    let editor = this.props.editor;
    if (!editor) return;

    let value = editor.value;
    let isActive = value.activeMarks.some(mark => mark.type === style);
    return isActive;
  }

  isListActive(listType) {
    let editor = this.props.editor;
    if (!editor) return false;

    let value = editor.value;
    let document = value && value.document;
    return value.blocks.some(block => {
      const closestList = document.getClosest(
        block.key,
        parent => parent.type === UNORDERED_LIST || parent.type === ORDERED_LIST
      );
      return closestList && closestList.type === listType;
    });
  }

  isInlineActive(type) {
    let editor = this.props.editor;
    if (!editor) return;

    let value = editor.value;
    const isFocused = value && value.inlines.some(inline => inline.type === type);
    return isFocused;
  }

  maintainEditorFocus(event) {
    this.props.editor.focus();
  }

  // Actions

  createTable(rows, columns, event) {
    this.maintainEditorFocus();
    const editor = this.props.editor;
    // insert an extra line after the table
    editor.insertBlock({ type: 'paragraph' });
    editor.moveBackward(1);
    editor.insertTable(columns, rows);
    this.props.onChange(editor);
  }

  toggleOrderedList(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.toggleList('ordered_list'));
  }

  toggleUnorderedList(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.toggleList('unordered_list'));
  }

  toggleBold(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.toggleMark('bold'));
  }

  toggleItalic(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.toggleMark('italic'));
  }

  toggleUnderline(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.toggleMark('underline'));
  }

  toggleLink(evt) {
    evt.preventDefault();
    this.props.onChange(this.props.editor.wrapInline({ type: 'link' }).blur());
  }

  togglePlaceholder(event) {
    event.preventDefault();
    this.maintainEditorFocus();

    let value = this.props.editor.value;
    const isPlaceholder = value && value.inlines.some(inline => inline.type === 'placeholder');
    if (isPlaceholder) {
      this.props.onChange(this.props.editor.unwrapInline({ type: 'placeholder' }));
    } else {
      this.props.onChange(this.props.editor.wrapInline({ type: 'placeholder' }));
    }
  }

  startAnswerLinkMention(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.startMention(ANSWER_LINK_ACTIVATION_CHARACTER));
  }

  startVariableMention(event) {
    event.preventDefault();
    this.maintainEditorFocus();
    this.props.onChange(this.props.editor.startMention(VARIABLE_ACTIVATION_CHARACTER));
  }
}

SlateStylesMenu.propTypes = {
  channel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  editor: PropTypes.object,
  onChange: PropTypes.func,
  onStartUpload: PropTypes.func.isRequired,
  snippetId: PropTypes.string,
};

export const LeftAlignedMenu = styled(Menu)`
  justify-content: flex-start;
  padding-left: 5px;
`;

const BoldItem = styled(Item)`
  font-size: 15px;
  font-weight: bold;
`;
const ItalicItem = styled(Item)`
  font-size: 15px;
  font-style: italic;
`;
const UnderlineItem = styled(Item)`
  font-size: 15px;
  text-decoration: underline;
`;
export const AnswerLinkItem = styled(Item)`
  height: 25px;
  line-height: 18px;
  width: 36px;
`;
const VariableItem = styled(Item)`
  height: 25px;
  line-height: 18px;
  width: 36px;
`;
const ItemLabel = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.gray600};
`;

export default SlateStylesMenu;
