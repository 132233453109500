import Toast from 'models/toast';
import { typeReflect } from 'models/toast/toast_content_from_js';
import playNotificationSound from 'actions/notification/lib/play_notification_sound';

export default class CreateToast {
  constructor(context) {
    this.context = context;
  }

  run({ content, duration }) {
    const type = typeReflect.instanceToType(content);

    const toast = Toast.create({ content, duration, type });
    this.context.stores.toasts.addOrReplace(toast);

    const agentNotificationSound = this.context.stores.agentPreferences.get().agentNotificationSound;
    playNotificationSound(agentNotificationSound);
  }
}
