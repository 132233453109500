import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

const alignmentTypes = createEnum('LEFT', 'RIGHT');
const borderRadiusTypes = createEnum('NONE', 'SMALL', 'LARGE');
const compositionStageTypes = createEnum('EMAIL', 'MESSAGE', 'NAME');
const initialButtonTypes = createEnum('ICON', 'TEXT');
const providerTypes = createEnum('SMOOCH', 'CHAT_ROCKET');
const widgetTypes = createEnum('CHAT', 'DEMO_SELF_SERVICE', 'INTERACTIVE_CHAT', 'SELF_SERVICE');

const Attachments = createModel({
  modelName: 'Attachments',
  properties: {
    fileSizeErrorText: prop(String),
    genericErrorText: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const ChatClosed = createModel({
  modelName: 'ChatClosed',
  properties: {
    message: prop(String),
    title: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const ChatHistory = createModel({
  modelName: 'ChatHistory',
  properties: {
    attachmentUploadButtonTitle: prop(String),
    backButtonTitle: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const ChatThrottled = createModel({
  modelName: 'ChatThrottled',
  properties: {
    message: prop(String),
    title: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const closeButtonIconType = createEnum('DASH_ICON', 'X_ICON');
const CloseButton = createModel({
  modelName: 'CloseButton',
  properties: {
    iconType: prop()
      .oneOf(..._.keys(closeButtonIconType))
      .default(closeButtonIconType.X_ICON),
    title: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const Colors = createModel({
  modelName: 'Colors',
  properties: {
    alert: prop(String),
    attachmentBackground: prop(String),
    brandPrimary: prop(String),
    brandSecondary: prop(String),
    brandAccent: prop(String),
    greetingCloseButton: prop(String),
    onboardingButton: prop(String),
    submitArrow: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const ThemeColors = createModel({
  modelName: 'ThemeColors',
  properties: {
    alert: prop(String),
    attachmentBackground: prop(String),
    brandPrimary: prop(String),
    brandSecondary: prop(String),
    brandAccent: prop(String),
    chatLauncher: prop(String),
    submitArrow: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const Composition = createModel({
  modelName: 'Composition',
  properties: {
    emailError: prop(String),
    emailPrompt: prop(String),
    messagePrompt: prop(String),
    nameError: prop(String),
    namePrompt: prop(String),
  },
});

const Errors = createModel({
  modelName: 'Errors',
  properties: {
    connectionError: prop(String),
    connectionRetryButton: prop(String),
    dismissErrorButton: prop(String),
    fileSizeError: prop(String),
    genericUploadError: prop(String),
  },
});

const Header = createModel({
  modelName: 'Header',
  properties: {
    backButton: prop(String),
    greeting: prop(String),
    title: prop(String),
  },
});

const InteractiveOnboarding = createModel({
  modelName: 'InteractiveOnboarding',
  properties: {
    autoResponse: prop(String),
    emailPrompt: prop(String),
    namePrompt: prop(String),
    onboardingSuccess: prop(String),
  },
});

const CustomText = createModel({
  modelName: 'CustomText',
  properties: {
    attachments: prop(Attachments),
    buttonText: prop(String),
    errors: prop(Errors),
    chatHistory: prop(ChatHistory),
    composition: prop(Composition),
    headerTitle: prop(String),
    header: prop(Header),
    inactiveButtonText: prop(String),
    interactiveOnboarding: prop(InteractiveOnboarding),
    noSearchResults: prop(String),
    officeHourMessage: prop(String),
    paymentFormCvvToolTip: prop(String),
    paymentFormCvvError: prop(String),
    paymentFormCvvLabel: prop(String),
    paymentFormCvvPlaceholder: prop(String),
    paymentFormExpDateError: prop(String),
    paymentFormExpDateLabel: prop(String),
    paymentFormExpDatePlaceholder: prop(String),
    paymentFormNameError: prop(String),
    paymentFormNameLabel: prop(String),
    paymentFormNamePlaceholder: prop(String),
    paymentFormNumberError: prop(String),
    paymentFormNumberLabel: prop(String),
    paymentFormNumberPlaceholder: prop(String),
    paymentFormPayButton: prop(String),
    paymentFormDeclineButton: prop(String),
    paymentItemDeclineButton: prop(String),
    paymentItemEnterButton: prop(String),
    paymentItemStateCompleted: prop(String),
    paymentItemStateDeclined: prop(String),
    paymentItemStateError: prop(String),
    paymentItemStateSubmitted: prop(String),
    paymentItemStateTimedOut: prop(String),
    preIdentifiedUserWelcomeMessage: prop(String),
    searchPlaceholder: prop(String),
    unreadMessagePlural: prop(String),
    unreadMessageSingular: prop(String),
  },
});

const FontLibrary = createModel({
  modelName: 'FontLibrary',
  properties: {
    primary: prop(String),
    secondary: prop(String),
  },
});

const Formats = createModel({
  modelName: 'Formats',
  properties: {
    time: prop(String),
  },
});

const MinimizedChatButton = createModel({
  modelName: 'MinimizedChatButton',
  properties: {
    title: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const ScheduleBlock = createModel({
  modelName: 'ScheduleBlock',
  properties: {
    start: prop(String),
    stop: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const Theme = createModel({
  modelName: 'Theme',
  properties: {
    borderRadius: prop()
      .oneOf(..._.keys(borderRadiusTypes))
      .default(borderRadiusTypes.LARGE),
    colors: prop(ThemeColors),
    dropshadowEnabled: prop(Boolean),
    fontLibrary: prop(FontLibrary),
    formats: prop(Formats),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const Throttling = createModel({
  modelName: 'Throttling',
  properties: {
    autoThrottlingEnabled: prop(Boolean),
    autoThrottlingThreshold: prop(Number),
    percentageRollout: prop(Number),
  },

  setAutoThrottlingEnabled(isEnabled) {
    this.autoThrottlingEnabled = isEnabled;
  },

  setAutoThrottlingThreshold(duration) {
    this.autoThrottlingThreshold = duration;
  },

  setPercentageRollout(pct) {
    this.percentageRollout = pct;
  },
});

const OfficeHours = createModel({
  modelName: 'OfficeHours',
  properties: {
    timezone: prop(String),
    monday: prop([ScheduleBlock]),
    tuesday: prop([ScheduleBlock]),
    wednesday: prop([ScheduleBlock]),
    thursday: prop([ScheduleBlock]),
    friday: prop([ScheduleBlock]),
    saturday: prop([ScheduleBlock]),
    sunday: prop([ScheduleBlock]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const InitialChatButton = createModel({
  modelName: 'InitialChatButton',
  properties: {
    type: prop()
      .oneOf(..._.keys(initialButtonTypes))
      .default(initialButtonTypes.TEXT),
    value: prop(String),
  },
});

const Onboarding = createModel({
  modelName: 'Onboarding',
  properties: {
    authenticationError: prop(String),
    blankFieldError: prop(String),
    emailField: prop(String),
    enabled: prop(Boolean).default(true),
    header: prop(String),
    initialCompositionStage: prop()
      .oneOf(..._.keys(compositionStageTypes))
      .default(compositionStageTypes.EMAIL),
    introMessage: prop(String),
    invalidEmailError: prop(String),
    nameField: prop(String),
    submitButton: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const OngoingChat = createModel({
  modelName: 'OngoingChat',
  properties: {
    compositionPlaceholder: prop(String),
    endChatTitle: prop(String),
    endMessageAgentEnded: prop(String),
    endMessageConsumerNoReply: prop(String),
    endMessageNewSessionStarted: prop(String),
    endMessageTimeExpired: prop(String),
    submitButtonTitle: prop(String),
    title: prop(String),
    welcomeMessage: prop(String),
  },
});

const SelfService = createModel({
  modelName: 'SelfService',
  properties: {
    quickActions: prop(Array),
  },
});

const ChatWidgetConfiguration = createModel({
  modelName: 'ChatWidgetConfiguration',
  properties: {
    abTestingPercentage: prop(Number),
    attachmentsEnabled: prop(Boolean),
    alignment: prop()
      .oneOf(..._.keys(alignmentTypes))
      .default(alignmentTypes.RIGHT),
    border: prop(Object).default({}),
    borderRadius: prop()
      .oneOf(..._.keys(borderRadiusTypes))
      .default(borderRadiusTypes.LARGE),
    chatClosed: prop(ChatClosed),
    chatThrottled: prop(ChatThrottled),
    closeButton: prop(CloseButton),
    colors: prop(Colors),
    conversationExpirationTime: prop(String),
    customText: prop(CustomText),
    display: prop(String),
    enabled: prop(Boolean).isRequired,
    font: prop(String),
    initialChatButton: prop(InitialChatButton),
    lng: prop(String),
    minimizedChatButton: prop(MinimizedChatButton),
    officeHours: prop(OfficeHours).isRequired,
    onboarding: prop(Onboarding).isRequired,
    ongoingChat: prop(OngoingChat),
    paymentRequestEnabledFor: prop(Array).default([]),
    provider: prop()
      .oneOf(..._.keys(providerTypes))
      .default(providerTypes.CHAT_ROCKET),
    providerId: prop(String),
    pushNotificationAccountIds: prop(Object).default({}),
    pushNotificationTitle: prop(String),
    rules: prop(Object).default({}),
    selfService: prop(SelfService),
    theme: prop(Theme),
    throttling: prop(Throttling).default(new Throttling()),
    widgetType: prop()
      .oneOf(..._.keys(widgetTypes))
      .default(widgetTypes.CHAT),
    zIndex: prop(Number),
  },

  setPercentageRollout(pct) {
    // set in 2 places for backwards compatibility (for now)
    // were moving to a throttling object and we'll be moving percentageRollout to it
    this.percentageRollout = pct;
    this.throttling.setPercentageRollout(pct);
  },

  setAutoThrottlingEnabled(isEnabled) {
    this.throttling.setAutoThrottlingEnabled(isEnabled);
  },

  setAutoThrottlingThreshold(duration) {
    this.throttling.setAutoThrottlingThreshold(duration);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ChatWidgetConfiguration;
