import React, { createRef, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';
import styled, { css } from 'styled-components';
import qconsole from 'scripts/lib/qconsole';

import connect from 'components/lib/connect';
import createEnum from 'scripts/lib/create_enum';
import Composer, { Header, HeaderText } from './shared/composer';
import ComposerContext from 'components/composer/contexts/composer_context';
import ClearCurrentComposition from 'actions/composition/close_current_composition';
import { createWidgetHost } from 'components/composer/cobrowse/widget_host_methods';
import { ExecuteActionContext } from 'components/lib/connect';
import HandleNewSession from 'actions/cobrowsing/handle_new_session';
import HandleActiveSession from 'actions/cobrowsing/handle_active_session';
import HandleEndedSession from 'actions/cobrowsing/handle_ended_session';
import HandleWidgetLoaded from 'actions/cobrowsing/handle_widget_loaded';
import Spinner from 'components/common/spinner_two';
import UpdateComposition from 'actions/composer/update_composition';
import WidgetHostFrame from 'components/composer/cobrowse/widget_host_frame';
import { WidgetTypes } from 'models/widget_configuration';

const SessionEvents = createEnum('SESSION_CREATED', 'SESSION_STARTED', 'SESSION_ENDED');

const CoBrowseComposer = props => {
  const { composition } = useContext(ComposerContext);
  const executeAction = useContext(ExecuteActionContext);
  const iframeRef = createRef();
  const loadingOverlayRef = createRef();
  const loadingTimerRef = useRef(0);
  const hostChannelRef = useRef(null);
  const iframeLoaded = useRef(false);
  const updatedRef = useRef(false);

  const loaderUrl = (props.loaderUrl || '').trim();
  const publicToken = (props.publicToken || '').trim();
  const permissions = props.permissions && props.permissions.join(' ');
  const widgetContext = getWidgetContext(composition);
  const widgetParams = getWidgetConfig(props);
  const sessionSourceName = 'ScreenMeet';

  const onWidgetEvent = (eventName, eventData) => {
    switch (eventName) {
      case SessionEvents.SESSION_CREATED:
        executeAction(HandleNewSession, { id: eventData.id, sessionData: eventData, sessionSourceName });
        break;

      case SessionEvents.SESSION_STARTED:
        executeAction(HandleActiveSession, { id: eventData.id, sessionData: eventData, sessionSourceName });
        break;

      case SessionEvents.SESSION_ENDED:
        executeAction(HandleEndedSession, { id: eventData.id, sessionData: eventData, sessionSourceName });
        break;

      default:
        qconsole.log(`Co-browsing widget triggered an unknown event ${eventName}. Ignoring...`);
        break;
    }
  };
  const onWidgetError = (error, ...args) => {
    hideLoadingOverlay();
    qconsole.error(`Co-browsing widget error: ${error}`, ...args);
  };

  // We manipulate the overlay "ref" instead of setting component state to avoid unnecessary expensive re-rendering
  // of the entire composition
  const hideLoadingOverlay = () => {
    if (!iframeLoaded.current) {
      iframeLoaded.current = true;

      // Report loading time stats
      const loadingTime = Date.now() - loadingTimerRef.current;
      executeAction(HandleWidgetLoaded, { loaderUrl, loadingTime });

      // Add small delay to allow the widget finish its UI initialization and improve the overall experience
      setTimeout(() => {
        if (loadingOverlayRef.current) loadingOverlayRef.current.style.display = 'none';
      }, 750);
    }
  };

  const dismissComposition = () => {
    executeAction(ClearCurrentComposition);
  };

  useEffect(() => {
    loadingTimerRef.current = loadingTimerRef.current || Date.now();
    if (!updatedRef.current) {
      setImmediate(() => {
        executeAction(UpdateComposition, {
          attrs: { conversationId: composition.conversationId, timestamp: Date.now() },
          compositionId: composition.id,
          customerId: composition.customerId,
        });
        updatedRef.current = true;
      });
    }

    // Now start loading the widget "host frame"
    if (!iframeRef.current || !loaderUrl || !publicToken || !props.widgetEnabled || !updatedRef.current) return;

    // Create "widget host communication channel" if it has not been created yet
    if (!hostChannelRef.current) {
      hostChannelRef.current = createWidgetHost(iframeRef.current, publicToken, {
        params: widgetParams,
        context: widgetContext,
        onEvent: onWidgetEvent,
        onError: onWidgetError,
      });

      return () => {
        // The channel is stateless so we don't need to keep it around once the component is unmounted
        if (hostChannelRef.current) {
          hostChannelRef.current.disconnect();
          hostChannelRef.current = undefined;
        }
      };
    }
  });

  let compositionUI;
  let overlay = null;
  if (!props.widgetEnabled) {
    compositionUI = <Message>Co-Browsing is not enabled</Message>;
  } else if (!loaderUrl || !publicToken) {
    compositionUI = <Message>Unable to display the widget. Please check widget configuration</Message>;
  } else {
    const [root, query] = loaderUrl.split('?');
    const queryParams = qs.parse(query || '');
    queryParams.public_token = publicToken;

    const sourceUrl = `${root}?${qs.stringify(queryParams)}`;
    compositionUI = (
      <WidgetHostFrame
        allowForms
        allowSameOrigin
        allowScrolling={false}
        onLoad={() => hideLoadingOverlay()}
        permissions={permissions}
        ref={iframeRef}
        sourceUrl={sourceUrl}
      />
    );

    overlay = iframeLoaded.current ? null : (
      <LoadingOverlay data-aid="widget-loading-spinner" ref={loadingOverlayRef}>
        <Spinner size={64} />
      </LoadingOverlay>
    );
  }

  return (
    <Composer disableSubmit={false} hideCancel onSubmit={dismissComposition} submitText="Close ScreenMeet">
      <Header>
        <Left>
          <HeaderText>ScreenMeet</HeaderText>
        </Left>
      </Header>
      <CompositionContainer>
        <ContentWrapper>
          {overlay}
          {compositionUI}
        </ContentWrapper>
      </CompositionContainer>
    </Composer>
  );
};

CoBrowseComposer.propTypes = {
  loaderUrl: PropTypes.string.isRequired,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      param: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  permissions: PropTypes.arrayOf(PropTypes.string),
  publicToken: PropTypes.string.isRequired,
  widgetEnabled: PropTypes.bool,
};

function mapStateToProps({ getProvider }) {
  const widgetConfigs = getProvider('widgetConfigurations').findAll();
  const screenMeetCfg = (widgetConfigs || []).find(config => config.type === WidgetTypes.SCREENMEET);

  return {
    widgetEnabled: !!(screenMeetCfg && screenMeetCfg.enabled),
    publicToken: screenMeetCfg ? screenMeetCfg.publicToken : '',
    loaderUrl: screenMeetCfg ? screenMeetCfg.settings.loaderUrl : '',
    permission: screenMeetCfg ? screenMeetCfg.settings.permissions : undefined,
    parameters: screenMeetCfg ? screenMeetCfg.settings.parameters : [],
  };
}

function getWidgetConfig(props) {
  return {
    params: cloneDeep(props.parameters || []),
  };
}

function getWidgetContext(composition) {
  return {
    conversationId: composition.conversationId,
    conversationName: `Co-browsing session with "${composition.customerId}"`,
  };
}

const flexCentered = css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

const CompositionContainer = styled.div`
  ${flexCentered}
`;

const ContentWrapper = styled.div`
  ${flexCentered}
  height: 352px;
  width: 352px;
  border: 1px solid ${p => p.theme.colors.gray500};
  position: relative;
`;

const LoadingOverlay = styled.div`
  ${flexCentered}
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: 10;
`;

const Message = styled.div`
  color: ${p => p.theme.colors.gray800};
`;

export default connect(mapStateToProps)(CoBrowseComposer);
