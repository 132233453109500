import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import Attachments from 'components/admin/knowledge_base/snippet/snippet_attachments';
import Modal from 'components/lib/modal';
import { SlatePreview } from 'components/customer/answer_panel/preview/content/answer_preview_content_body';
import { SnippetContentType } from 'models/answers/snippet';

let AnswerModal = createReactClass({
  propTypes: {
    answer: PropTypes.object,
    answerLinks: PropTypes.array,
    history: PropTypes.array,
    isEditingSnippets: PropTypes.bool,
    onClose: PropTypes.func,
    variables: PropTypes.object,
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.history.length !== this.props.history.length) {
      // to close the modal successfully, this timeout is needed so that it does not rerender
      setTimeout(() => this.props.onClose(), 10);
    }
  },

  render() {
    let answer = this.props.answer;
    if (!answer) return null;

    let content = answer.findContentByLanguage();
    let answerHtml = content.getBodyByType(SnippetContentType.INFO);

    let isInternal = content.info && content.info.internal;

    let internalHeader = isInternal ? <div className="answerModal-header-internal">INTERNAL</div> : null;
    const classNames = classnames('answerModal-body', {
      'answerModal-body-internal': isInternal,
    });

    return (
      <Modal className="answerModal-wrapper">
        <div className="answerModal">
          <div className="answerModal-name">{answer.name}</div>
          <div className={classNames}>
            {internalHeader}
            <SlatePreview html={answerHtml} />
          </div>
          <Attachments contentChannelType={SnippetContentType.INFO} isRemoveable={false} snippet={answer} />
        </div>
        <div className="answerModal-close" onClick={this.props.onClose}>
          ✕
        </div>
      </Modal>
    );
  },
});

export default AnswerModal;
