import React from 'react';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import CreateProfile from 'actions/customer_profile/create_profile';
import MenuIcon from 'components/lib/icons/menu_icon';
import OverflowFillIcon from 'components/common/icons/fill/overflow-fill';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';
import RequestAgentInbox from 'actions/inbox/agent/request_agent_inbox';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function InboxMenu({ collapsed }) {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();
  const executeAction = useExecuteAction();

  const onAddCustomer = () => {
    analytics.track('Add Customer Button Clicked', { location: 'customer-list', collapsed });
    executeAction(CreateProfile, { navigateToCustomer: true });
  };

  const onRefreshInbox = () => {
    executeAction(RequestAgentInbox);
    analytics.track('Agent Inbox Refreshed', { location: 'customer-list', manual: true });
  };

  return (
    <React.Fragment>
      <OutsideClickHandler onClickOutside={onClose}>
        {collapsed ? (
          <CollapsedIconContainer>
            <Padding />
            <span data-aid="customerList-overflowMenuButton" onClick={onToggle} ref={setTargetRef}>
              <CollapsedInboxMenuIcon open={isOpen} />
            </span>
          </CollapsedIconContainer>
        ) : (
          <div data-aid="customerList-overflowMenuButton" onClick={onToggle} ref={setTargetRef}>
            <StyledMenuIconContainer>
              <StyledMenuIcon open={isOpen} />
            </StyledMenuIconContainer>
          </div>
        )}
        <PopoverMenu
          boundByWindow={false}
          data-aid="customerList-overflowMenu"
          isOpen={isOpen}
          margin={5}
          onClickOutside={null}
          onClose={onClose}
          position="right"
          targetPosition="start"
          targetRef={targetRef}
        >
          <PopoverMenuItem
            className="profile-menuItem-addNewCustomer"
            data-aid="menuItem-Profile"
            onClick={onAddCustomer}
          >
            <div data-aid="add-new-customer-button">Add new customer</div>
          </PopoverMenuItem>
          <PopoverMenuItem
            className="profile-menuItem-refreshInbox"
            data-aid="menuItem-refreshInbox"
            onClick={onRefreshInbox}
          >
            <div data-aid="refresh-inbox-button">Refresh customer list</div>
          </PopoverMenuItem>
        </PopoverMenu>
      </OutsideClickHandler>
    </React.Fragment>
  );
}

const CollapsedIconContainer = styled.div`
  display: flex;
  margin: 8px 0px;
  text-align: center;
`;

const CollapsedInboxMenuIcon = styled(OverflowFillIcon)`
  background: ${p => (p.open ? p.theme.colors.green400 : p.theme.colors.gray600)};
  border-radius: 50%;
  fill: ${p => p.theme.colors.white};
  height: 40px;
  line-height: 0;
  margin: 4px 2px;
  padding: 8px 0px;
  stroke: ${p => (p.open ? p.theme.colors.green400 : p.theme.colors.gray600)};
  vertical-align: middle;
  width: 40px;
  &:hover {
    cursor: pointer;
    background: ${p => p.theme.colors.green400};
    stroke: ${p => p.theme.colors.green400};
  }
`;

const Padding = styled.div`
  margin: 0 4px;
  width: 10px;
`;

const StyledMenuIcon = styled(MenuIcon)`
  cursor: pointer;
  fill: ${p => (p.open ? p.theme.colors.green400 : p.theme.colors.gray800)};
  height: 20px;
  width: auto;
  padding: 0 8px;
  &:hover {
    fill: ${p => p.theme.colors.green400};
    stroke: ${p => p.theme.colors.green400};
  }
`;

const StyledMenuIconContainer = styled.div`
  position: absolute;
  right: 10px;
`;
