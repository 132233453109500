import { after } from 'scripts/infrastructure/backends/http_client';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';

export default class KbVariableGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
  }

  url(kbVariableId) {
    return `/api/v1/orgs/${this.orgId}/kb-variables/${kbVariableId || ''}`.replace(/\/$/, '');
  }

  get broadcastTopic() {
    return `v1/orgs/${this.orgId}/kb-variables/+`;
  }

  init({ orgId, agentId }) {
    this.orgId = orgId;
    this.agentId = agentId;
  }

  get _httpClient() {
    return this.backend.axios();
  }

  reconnectHandler() {
    if (this._isSubscribed) {
      this.request();
    }
  }

  request() {
    this._isSubscribed = true;
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.get.bind(this));
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleEntity', envelope.payload);
  }

  get() {
    return after(this._httpClient.get(this.url()), this.onFetchAll.bind(this));
  }

  update(id, body) {
    return after(this._httpClient.put(this.url(id), body), this.onUpdate.bind(this, id, body));
  }

  onUpdate(id, body, err, res) {
    if (err) {
      this.notifyObservers('handleUpdateErrors', id, err);
      return;
    }
    if (res.status === 204) {
      this.notifyObservers('handleUpdateSuccess', id, body);
    } else {
      this.notifyObservers('handleUpdateErrors', id, body);
    }
  }

  onFetchAll(err, res) {
    if (err) {
      this.notifyObservers('handleRequestError', err);
      return;
    }
    if (res.status === 200) {
      this.notifyObservers('handleRequestedEntities', res.data);
    } else {
      this.notifyObservers('handleRequestError', res.data);
    }
  }
}

mixin(KbVariableGateway.prototype, Observable);
