import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  CompositeDecorator,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  getVisibleSelectionRect,
  KeyBindingUtil,
  RichUtils,
} from 'draft-js';
const { isCtrlKeyCommand } = KeyBindingUtil;

import { Alignments, ALIGNMENT_DATA_KEY } from './draft_alignment';
import { isEntitySelected } from './draft_entity';
import { CUSTOM_STYLE_MAP, customStyleFn } from './draft_custom_styles';
import DraftAnswer, {
  findAnswerEntities,
  insertAnswerLink,
  updateAnswerLinks,
} from 'components/customer/composition/lib/draft/draft_answer';
import DraftMention, {
  ENTITY_TYPE_MENTION,
  findMentionEntities,
  getCurrentMention,
  isMentionCharacter,
  removeMention,
  startMention,
} from './draft_mention';
import DraftInlineLink, { findInlineLinks } from './draft_inline_link';
import { convertFromEmailHTML, convertToEmailHTML } from './draft_convert_email';
import DraftLink, { createNewLink, ENTITY_TYPE_LINK, findLinkEntities } from './draft_link';
import DraftPlaceholder, {
  findNextPlaceholderSelection,
  findPlaceholderEntities,
  togglePlaceholder,
} from './draft_placeholder';
import { splitBlock, insertSoftNewLine } from './draft_split';
import { insertText } from './draft_shared';
import { insertSnippet } from './draft_snippet';
import DraftInlineImage, { ENTITY_TYPE_INLINE_IMAGE } from './draft_inline_image';
import DraftInlineTable from './draft_inline_table';
import DraftVariable, { findVariableEntities, insertVariable, updateVariables } from './draft_variable';
import { formatEditorState } from 'components/customer/composition/lib/draft/format_editor_state';
import DroppableMixin from 'components/lib/droppable_mixin';
import Snippet, { SnippetContentType } from 'models/answers/snippet';

export { getCurrentMention };

const MAX_LIST_DEPTH = 4;

export const getBlockStyle = block => {
  const blockData = block.getData();
  const alignment = blockData && blockData.get(ALIGNMENT_DATA_KEY);
  switch (alignment) {
    case Alignments.RIGHT:
      return 'draftEditor-alignRight';
    case Alignments.CENTER:
      return 'draftEditor-alignCenter';
    case Alignments.JUSTIFY:
      return 'draftEditor-alignJustify';
    case Alignments.LEFT:
    default:
      return null;
  }
};

const BLOCK_RENDER_MAP = DefaultDraftBlockRenderMap.merge(
  Immutable.Map({
    'code-block': {
      element: 'div',
    },
  })
);

const DraftEditor = createReactClass({
  mixins: [DroppableMixin],

  displayName: 'DraftEditor',

  propTypes: {
    allowDroppingFiles: PropTypes.bool,
    autoFocus: PropTypes.bool,
    channel: PropTypes.string,
    children: PropTypes.array, // Each child is fed with editorState as a prop
    className: PropTypes.string,
    editorState: PropTypes.object, // If passed in, DraftEditor becomes a controlled component
    enterSends: PropTypes.bool,
    id: PropTypes.string,
    isEditingSnippets: PropTypes.bool,
    mentionCharacter: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func,
    onClickTable: PropTypes.func,
    onDropFiles: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    richTextEnabled: PropTypes.bool,
    singleLineInput: PropTypes.bool,
    snippetLinks: PropTypes.arrayOf(PropTypes.instanceOf(Snippet)),
    snippet: PropTypes.instanceOf(Snippet),
    variables: PropTypes.object, // Map from variable ID to variable
  },

  /* Lifecycle */

  getDefaultProps() {
    return {
      isEditingSnippets: false,
      mentionCharacter: '+',
      richTextEnabled: false,
      variables: {},
      snippetLinks: {},
    };
  },

  getInitialState() {
    let state = {
      editorState: this.props.editorState || DraftEditor.getNewEditorState(),
      isDragActive: false,
    };

    return state;
  },

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }

    this.updateEditorState(this.props);
  },

  UNSAFE_componentWillReceiveProps(props) {
    this.updateEditorState(props);
  },

  updateEditorState(props) {
    let originalEditorState = props.editorState || this.getEditorState();
    let editorState = originalEditorState;

    if (!_.isEqual(props.variables, this.props.variables)) {
      editorState = updateVariables(editorState, props.variables);
    }

    editorState = updateAnswerLinks(editorState, props.snippetLinks);

    if (editorState !== originalEditorState) {
      this.props.onChange(editorState);
    }
  },

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.closeTimeoutId);
  },

  /* Draft Actions */

  insertVariable(variable) {
    const editorState = insertVariable(this.getEditorState(), variable);
    this.onChange(editorState);
  },

  insertSnippet(snippet) {
    let content = snippet.findContentByLanguage();
    const editorState = insertSnippet(
      this.getEditorState(),
      content.getBodyByType(SnippetContentType.ANY_CHANNEL),
      this.props.variables,
      this.props.richTextEnabled
    );
    this.onChange(editorState);
  },

  insertAnswerLink(answer) {
    const editorState = insertAnswerLink(this.getEditorState(), answer);
    this.onChange(editorState);
  },

  removeMention() {
    const editorState = removeMention(this.getEditorState());
    this.onChange(editorState);
  },

  undo() {
    this.onChange(EditorState.undo(this.getEditorState()));
  },

  focus() {
    this.editor.focus();
  },

  focusAtEnd() {
    this.focus();
    this.onChange(EditorState.moveFocusToEnd(this.getEditorState()));
  },

  /* Getters */

  getEditorState() {
    return this.props.editorState || this.state.editorState;
  },

  getSelection() {
    return this.getEditorState().getSelection();
  },

  getPlaintext() {
    return this.getEditorState()
      .getCurrentContent()
      .getPlainText();
  },

  getHtml({ includeVariables }) {
    return convertToEmailHTML(this.getEditorState(), includeVariables);
  },

  getCursorPixels() {
    return getVisibleSelectionRect(window);
  },

  getCurrentMentionPixels() {
    return this.getEntityPixels(ENTITY_TYPE_MENTION);
  },

  getEntityNode(entityType, entityKey) {
    const editorState = this.getEditorState();
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selection.getEndKey());
    const key = block.getEntityAt(selection.getEndOffset() - 1);
    if (key && contentState.getEntity(key).type === entityType) {
      const keySelector = entityKey ? `-${entityKey}` : '';
      const selector = `.draftEditor-${entityType.toLowerCase()}${keySelector}`;
      const entityNode = ReactDOM.findDOMNode(this).querySelector(selector);
      return entityNode;
    }
    return null;
  },

  getEntityPixels(entityType, entityKey) {
    const entityNode = this.getEntityNode(entityType, entityKey);
    return entityNode && entityNode.getBoundingClientRect();
  },

  getCurrentLineText() {
    return this.getEditorState()
      .getCurrentContent()
      .getBlockForKey(this.getCurrentLineKey())
      .getText();
  },

  getCurrentLineKey() {
    return this.getEditorState()
      .getSelection()
      .getAnchorKey();
  },

  /* Handlers */

  onChange(editorState) {
    const oldEditorState = this.getEditorState();
    if (editorState === oldEditorState) {
      return;
    }

    let newEditorState = formatEditorState(
      editorState,
      oldEditorState,
      this.props.isEditingSnippets,
      this.props.mentionCharacter
    );

    if (!this.props.editorState) {
      this.setState({ editorState: newEditorState });
    }

    if (this.props.onChange) {
      this.props.onChange(newEditorState);
    }
  },

  handleBeforeInput(chars) {
    let editorState = this.getEditorState();

    // Inserting a space or '+' after a '+' should cancel the mention (and not start another one).
    if (
      (chars === ' ' || isMentionCharacter(chars, this.props.mentionCharacter)) &&
      getCurrentMention(editorState).text === ''
    ) {
      editorState = removeMention(editorState);
      editorState = insertText(editorState, chars);
      this.onChange(editorState);
      return true;
    } else if (isMentionCharacter(chars, this.props.mentionCharacter)) {
      editorState = startMention(editorState, chars);
      this.onChange(editorState);
      return true;
      // Pressing space after a link should be a normal space, *not* append the space to the Link entity.
    } else if (chars === ' ' && isEntitySelected(editorState, ENTITY_TYPE_LINK)) {
      editorState = insertText(editorState, ' ');
      this.onChange(editorState);
      return true;
    }

    return false;
  },

  keyBindingFn(e) {
    // Ctrl-p -> Insert placeholder
    if (this.props.isEditingSnippets && e.keyCode === 80 && isCtrlKeyCommand(e)) {
      return 'insert-placeholder';
    }

    if (e.keyCode === 191 && (isCtrlKeyCommand(e) || e.metaKey)) {
      return 'blur-editor';
    }

    if ((isCtrlKeyCommand(e) || e.metaKey) && e.key === 'k') {
      return 'insert-link';
    }

    if ((e.altKey && e.key === 'h') || e.key === '˙') {
      return 'toggle-hide-composer';
    }

    return getDefaultKeyBinding(e);
  },

  // Strings we return in keyBindingFn above get passed into this function for processing.
  handleKeyCommand(command) {
    // Disable Cmd-J, which inserts a code block
    if (command === 'code') {
      return false;
    }

    // Try to handle rich text shortcuts like Cmd-B or Cmd-I
    if (this.props.richTextEnabled) {
      const newState = RichUtils.handleKeyCommand(this.getEditorState(), command);
      if (newState) {
        this.onChange(newState);
        return true;
      }
    }

    if (command === 'insert-placeholder') {
      const editorState = togglePlaceholder(this.getEditorState());
      this.onChange(editorState);
      return true;
    }

    if (command === 'blur-editor') {
      this.editor.blur();
      return true;
    }

    if (command === 'insert-link') {
      const editorState = createNewLink(this.getEditorState());
      this.onChange(editorState);
    }

    if (command === 'toggle-hide-composer') {
      this.editor.blur();
      this.props.onToggleHide();
      return true;
    }

    return false;
  },

  handleReturn(e) {
    if (this.props.singleLineInput) {
      return true;
    }

    if (e.shiftKey) {
      this.onChange(insertSoftNewLine(this.getEditorState()));
      return true;
    }

    if (this.props.enterSends) {
      return true;
    }

    if (e.ctrlKey) {
      return true;
    }

    let newEditorState = splitBlock(this.getEditorState());
    this.onChange(newEditorState);
    return true;
  },

  handleEscape(e) {
    const editorState = this.getEditorState();
    if (getCurrentMention(editorState).text !== null) {
      e.preventDefault();
      e.stopPropagation();
      this.removeMention(editorState);
    }
  },

  handleTab(e) {
    const editorState = this.getEditorState();

    // Use RichUtils to try to handle list indentation first
    const tabbedEditorState = RichUtils.onTab(e, editorState, MAX_LIST_DEPTH);
    if (tabbedEditorState !== editorState) {
      this.onChange(tabbedEditorState);
    }

    if (this.props.isEditingSnippets) {
      return;
    }
    const nextPlaceholderSelection = findNextPlaceholderSelection(editorState);
    if (!nextPlaceholderSelection) {
      return;
    }

    e.preventDefault();
    this.onChange(EditorState.forceSelection(editorState, nextPlaceholderSelection));
  },

  handleDroppedFiles(selection, files) {
    if (this.props.allowDroppingFiles && this.props.onDropFiles) {
      this.props.onDropFiles(files, this.props.channel);
      return true;
    }
    return false;
  },

  /* Render */

  render() {
    const editorState = this.getEditorState();

    // If the user changes block type before entering any text, hide the placeholder.
    const contentState = editorState.getCurrentContent();
    const classNames = classnames('draftEditor-wrapper', {
      'draftEditor-wrapper-hidePlaceholder':
        !contentState.hasText() &&
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled',
      'draftEditor-wrapper-droppingFiles': this.state.isDragActive,
    });

    const dropProps = this.props.allowDroppingFiles
      ? {
          onDragEnter: this.onDragEnter,
          onDragOver: this.onDragOver,
          onDragLeave: this.onDragLeave,
          onDrop: this.onDrop,
        }
      : {};

    const dragDropCompatible = this.browserHasDragDrop();
    const blockRendererFn = getBlockRenderer(editorState, this.props.onClickTable);

    return (
      <div className={classNames} {...dropProps} ref={node => (this.editorContainer = node)}>
        {this.state.isDragActive && this.props.allowDroppingFiles ? (
          <div className={classnames('draftEditor-dropZone', { 'draftEditor-dropZone-disabled': !dragDropCompatible })}>
            <div
              className={classnames('draftEditor-dropLabel', {
                'draftEditor-dropLabel-disabled': !dragDropCompatible,
              })}
            >
              {dragDropCompatible ? 'Drop files here' : 'Drag and drop not supported on this browser'}
            </div>
          </div>
        ) : null}
        <Editor
          blockRenderMap={BLOCK_RENDER_MAP}
          blockRendererFn={blockRendererFn}
          blockStyleFn={getBlockStyle}
          customStyleFn={customStyleFn}
          customStyleMap={CUSTOM_STYLE_MAP}
          editorState={editorState}
          handleBeforeInput={this.handleBeforeInput}
          handleDroppedFiles={this.handleDroppedFiles}
          handleKeyCommand={this.handleKeyCommand}
          handleReturn={this.handleReturn}
          key="draftEditor"
          keyBindingFn={this.keyBindingFn}
          onChange={this.onChange}
          onEscape={this.handleEscape}
          onTab={this.handleTab}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          ref={node => (this.editor = node)}
          spellCheck
          stripPastedStyles
        />
        {this.renderChildren()}
      </div>
    );
  },

  browserHasDragDrop() {
    // draft doesn't support drag and drop on IE. See https://github.com/facebook/draft-js/issues/889
    return typeof document.caretRangeFromPoint === 'function';
  },

  renderChildren() {
    const editorState = this.getEditorState();
    return React.Children.map(this.props.children, child => React.cloneElement(child, { editorState }));
  },

  createDecorators() {
    return new CompositeDecorator(getStaticDecorators());
  },

  statics: {
    getNewEditorState() {
      return EditorState.createEmpty(createDefaultDecorator());
    },

    toHTML(editorState, options) {
      return editorState ? convertToEmailHTML(editorState, options && options.includeVariables) : '';
    },

    fromHTML({ answers, html, isEditingSnippets, variables }) {
      return EditorState.createWithContent(
        convertFromEmailHTML(html, { answers, variables, isEditingSnippets }),
        createDefaultDecorator()
      );
    },

    toPlaintext(editorState) {
      return editorState ? editorState.getCurrentContent().getPlainText() : '';
    },
  },
});

function createDefaultDecorator() {
  return new CompositeDecorator(getStaticDecorators());
}

function getStaticDecorators() {
  return [
    {
      strategy: findVariableEntities,
      component: DraftVariable,
    },
    {
      strategy: findMentionEntities,
      component: DraftMention,
    },
    {
      strategy: findPlaceholderEntities,
      component: DraftPlaceholder,
    },
    {
      strategy: findLinkEntities,
      component: DraftLink,
    },
    {
      strategy: findAnswerEntities,
      component: DraftAnswer,
    },
    // Keep inline links last so that they don't override actual entities.
    {
      strategy: findInlineLinks,
      component: DraftInlineLink,
    },
  ];
}

export function getBlockRenderer(editorState, onClickTable) {
  return contentBlock => {
    const type = contentBlock.getType();
    if (type === 'atomic') {
      const contentState = editorState.getCurrentContent();
      const entityKey = contentBlock.getEntityAt(0);
      const entity = entityKey && contentState.getEntity(entityKey);
      if (entity && entity.type === ENTITY_TYPE_INLINE_IMAGE) {
        return {
          component: DraftInlineImage,
          editable: false,
        };
      }
      if (contentBlock.getData().get('type') === 'table') {
        return {
          component: DraftInlineTable,
          editable: false,
          props: { onClickTable },
        };
      }
      return null;
    }
    return null;
  };
}

export { BLOCK_RENDER_MAP };

export default DraftEditor;
