import createModel from './lib/create_model';

const MessagingSession = createModel({
  modelName: 'MessagingSession',

  // we just need to successfully unmarshal the messaging session item
  // but since we don't ever need to actually access or interact with it, its not actually important what its fields are
  properties: {},
});

export default MessagingSession;
