export default function() {
  const hostname = window.location.hostname;
  if (hostname.endsWith('gladly.com')) {
    return 'PROD';
  }

  if (hostname.endsWith('gladly.qa')) {
    return 'STAGING';
  }

  return 'LOCAL';
}
