import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

/** @deprecated Please use components/lib/icons/icon.jsx instead */
export default function BaseIcon(props) {
  let { className, viewBox, ...otherProps } = props;
  return (
    <svg
      className={classnames('iconContainer', className)}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox || '0 0 19 21'}
      {...otherProps}
    />
  );
}

BaseIcon.propTypes = {
  className: PropTypes.string,
  viewBox: PropTypes.string,
};
