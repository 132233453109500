import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import { GRAYS } from 'components/common/colors';
import ConfirmLinkToCustomer from 'actions/conversation_item/pin_item/confirm_link_to_customer';
import LoadingSpinner from 'components/lib/loading_spinner';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import SelectRelationshipMenu from './select_relationship_menu';
import withShortcuts from 'components/keyboard_shortcuts/with_shortcuts';

export function ConfirmCustomerContent({
  address,
  email,
  name,
  phone,
  labelId,

  onUpdateLabelId,

  isLoading,
}) {
  if (isLoading) {
    return (
      <div className="confirmCustomerModal-loading">
        <LoadingSpinner color={GRAYS.xlight} position="relative" size="32px" />
      </div>
    );
  }

  return (
    <div className="confirmCustomerModal-body">
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Name</div>
        <div className="confirmCustomerModal-body-value fs-exclude confirmCustomerModal-body-name" title={name}>
          {renderFieldOrBlank(name)}
        </div>
      </div>
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Email</div>
        <div className="confirmCustomerModal-body-value fs-exclude confirmCustomerModal-body-email" title={email}>
          {renderFieldOrBlank(email)}
        </div>
      </div>
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Phone #</div>
        <div className="confirmCustomerModal-body-value fs-exclude confirmCustomerModal-body-phone" title={phone}>
          {renderFieldOrBlank(phone)}
        </div>
      </div>
      <div className="confirmCustomerModal-body-row">
        <div className="confirmCustomerModal-body-label">Address</div>
        <div className="confirmCustomerModal-body-value fs-exclude confirmCustomerModal-body-address" title={address}>
          {renderFieldOrBlank(address)}
        </div>
      </div>
      {renderRelationship()}
    </div>
  );

  function renderFieldOrBlank(field) {
    return field || <div className="confirmCustomerModal-body-blank">none</div>;
  }

  function renderRelationship() {
    return (
      <div className="confirmCustomerModal-body-row confirmCustomerModal-body-row-withBorder">
        <div className="confirmCustomerModal-body-label">Relationship</div>
        <SelectRelationshipMenu
          className="confirmCustomerModal-body-select fs-exclude"
          labelId={labelId}
          updateRelationship={onUpdateLabelId}
        />
      </div>
    );
  }
}

ConfirmCustomerContent.propTypes = {
  address: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  labelId: PropTypes.string,

  onUpdateLabelId: PropTypes.func,

  isLoading: PropTypes.bool,
};

const ConfirmCustomerContentContainer = withShortcuts(connect(mapStateToProps)(ConfirmCustomerContent), [
  { key: 'enter', handler: props => props.onSubmit() },
  { key: 'escape', handler: props => props.onCancel() },
]);

function mapStateToProps({ getProvider, isFeatureEnabled }, { id }) {
  const profileProvider = getProvider(`customers.${id}.profile`);
  const profile = profileProvider.get();
  const isLoading = profileProvider.isLoading();

  return {
    address: _.get(profile, 'address'),
    email: _.get(profile, 'emails[0].original'),
    id,
    isLoading,
    name: _.get(profile, 'name'),
    phone: _.get(profile, 'phones[0].original'),
  };
}

export class ConfirmCustomerModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpdateLabelId = this.handleUpdateLabelId.bind(this);
    this.state = { labelId: props.labelId || null };
    this.onConfirmCustomer = this.onConfirmCustomer.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  render() {
    const { onCancel, selectedCustomerId } = this.props;

    const contentProps = {
      id: selectedCustomerId,
      labelId: this.state.labelId,
      onKeyDown: this.handleKeyDown,
      onCancel: this.props.onCancel,
      onSubmit: this.onConfirmCustomer,
      onUpdateLabelId: this.handleUpdateLabelId,
    };

    return (
      <PortalModalWrapper>
        <ModalCard className="confirmCustomerModal" onClose={onCancel}>
          <div className="confirmCustomerModal-header">{this.props.title}</div>
          <ConfirmCustomerContentContainer {...contentProps} />
          <div className="confirmCustomerModal-footer">
            <Button
              blurOnClick
              buttonType={ButtonTypes.SECONDARY}
              className="confirmCustomerModal-cancelButton"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              blurOnClick
              buttonType={ButtonTypes.PRIMARY}
              className="confirmCustomerModal-confirmButton"
              onClick={this.onConfirmCustomer}
            >
              Confirm
            </Button>
          </div>
        </ModalCard>
      </PortalModalWrapper>
    );
  }

  onConfirmCustomer() {
    this.props.onConfirmCustomer({
      relationshipId: this.props.relationshipId,
      labelId: this.state.labelId,
      originalLabelId: this.props.labelId,
    });
  }

  handleKeyDown(evt) {
    if (evt.key === 'Enter') {
      this.onConfirmCustomer();
    } else if (evt.key === 'Escape') {
      this.props.onCancel();
    }
  }

  handleUpdateLabelId(evt) {
    this.setState({ labelId: evt.value || null });
  }
}

ConfirmCustomerModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirmCustomer: PropTypes.func.isRequired,
  originalCustomerId: PropTypes.string.isRequired,
  selectedCustomerId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labelId: PropTypes.string,
  relationshipId: PropTypes.string,
};

const ConfirmCustomerModalContainer = connect(mapStateToModalProps, mapExecuteToModalProps)(ConfirmCustomerModal);

function mapStateToModalProps(
  { getProvider, isFeatureEnabled },
  { originalCustomerId, itemId, onClose, selectedCustomerId }
) {
  const relationshipsProvider = getProvider(`customers.${originalCustomerId}.relationships`);

  let relationship = relationshipsProvider.findBy({ customerId: selectedCustomerId });
  return {
    labelId: _.get(relationship, 'labelId'),
    relationshipId: _.get(relationship, 'id'),
  };
}

function mapExecuteToModalProps(executeAction, { originalCustomerId, itemId, onClose, selectedCustomerId }) {
  return {
    onConfirmCustomer: ({ labelId, originalLabelId, relationshipId }) => {
      executeAction(ConfirmLinkToCustomer, {
        itemId,
        originalCustomerId,
        selectedCustomerId,

        labelId,
        originalLabelId,
        relationshipId,
      });
      onClose();
    },
  };
}

export default ConfirmCustomerModalContainer;
