export default class ResumeRecording {
  constructor(context) {
    this.context = context;
  }

  run() {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.resumeRecording(activeCall.customer.id, activeCall.conversationItem.id, {
        agentId: this.currentAgentId,
      });
    }
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }
}
