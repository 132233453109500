export default class ClearAgentStatuses {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.agentStatuses.set([]);
    this.context.gateways.agentStatuses.unsubscribeAll();
  }
}
