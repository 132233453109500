export default class PopstateEventHandler {
  constructor(context) {
    this.context = context;
  }

  createBrowserNavHandler() {
    return () => {
      const locationHistory = this.context.stores.locationHistory.get();

      locationHistory.resetHistory();
      locationHistory.setNavigatingBack(true);
      this.context.stores.locationHistory.set(locationHistory);
    };
  }
}
