/**
 * Quiet console. Drop-in replacement for window.console. Buffers console
 * output until `tail()` is called.
 */
export default class Qconsole {
  /**
   * A console logging function.
   *
   * @callback logFn
   * @param {*} message - the item to log to the console
   * @param {...*} optionalArgs - additional parameters to log to the console
   */

  /**
   * Adds an item to the local storage.
   *
   * @callback setItemFn
   * @param {string} key - the key under which to store the item
   * @param {sring} value - the value to store
   */

  /**
   * Gets an item from local storage.
   *
   * @callback getItemFn
   * @param {string} key - the key under which to find the item
   */

  /**
   * Removes an item from local storage.
   *
   * @callback rmItemFn
   * @param {string} key - the key under which to remove the item
   */

  /**
   * Quiet console. Drop-in replacement for window.console. Buffers console
   * output until `tail()` is called.
   *
   * @constructor
   * @param {Object} console - a `window.console`-like object
   * @param {logFn} console.log - operational logging function
   * @param {logFn} console.error - error logging function
   * @param {logFn} console.debug - debug logging function
   * @param {Object} [opts] - options
   * @param {number} [opts.maxBuffer] - the number of messages to buffer
   * before dropping history. Default is 1024.
   * @param {Object} [opts.storage] - storage mechanism for persisting tail
   * follow options across browser reloads. Default is `window.sessionStorage`
   * @param {setItemFn} opts.storage.setItem - adds an item to the storage
   * @param {getItemFn} opts.storage.getItem - gets an item from storage
   * @param {rmItemFn} opts.storage.removeItem - removes an item from storage
   */
  constructor(console, opts = {}) {
    this.console = console;
    this.buffer = [];
    this.maxBuffer = opts.maxBuffer || 1024;
    this.storage = opts.storage || window.sessionStorage;

    this._restoreFollowOpts();

    // pass through unimplemented console functions
    ['error', 'time', 'timeEnd', 'warn'].forEach(methodName => {
      if (!Qconsole.prototype.hasOwnProperty(methodName)) {
        if (typeof console[methodName] === 'function') {
          this[methodName] = console[methodName].bind(console);
        }
      }
    });
  }

  /**
   * Drop-in replacement for `console.log`, but writes to the qconsole
   * buffer instead of the developer tools console.
   *
   * @param {...*} args - parameters to pass to console.log.
   */
  log(...args) {
    this._buffer('log', args);
  }

  /**
   * Drop-in replacement for `console.debug`, but writes to the qconsole
   * buffer instead of the developer tools console.
   *
   * @param {...*} args - parameters to pass to console.log.
   */
  debug(...args) {
    this._buffer('debug', args);
  }

  /**
   * `tail()` outputs logging information from the buffer to the console
   * provided by the constructor.
   *
   * @param {Object} [opts] - options
   * @param {boolean} [opts.follow] - continuously stream further input. Call
   * `tail()` again to stop. When `follow: true`, provided options will
   * persist across browser reloads using session storage.
   * @param {RegExp} [opts.match] - print only lines with text matching the
   * provided regular expression.
   * @param {number} [opts.count] - print `count` lines of history (default
   * Infinity).
   * @param {number} [opts.since] - print lines of history that occurred
   * within the last `since` seconds.
   */
  tail(opts = {}) {
    this._storeFollowOpts(opts);

    if (this.follow && !opts.follow) {
      this.follow = false; // stop following without additional logging
      return;
    }

    this.follow = opts.follow;
    this.match = opts.match;

    let now = new Date().getTime();
    let count = opts.count == null ? Infinity : opts.count;
    let out = [];

    for (let i = this.buffer.length - 1; i >= 0 && count > 0; i--) {
      let buffered = this.buffer[i];
      if (opts.since != null && now - buffered.timestamp.getTime() > opts.since * 1000) {
        break;
      }
      if (!this._filtered(buffered)) {
        out.unshift(buffered);
        count--;
      }
    }
    out.forEach(b => this._forward(b));
  }

  /**
   * Adds an item to the buffer. Also forwarding it to the underlying
   * implementation if `this.follow` is true.
   *
   * @param {string} methodName - the console method called
   * @param {*[]} params - the params that the console method was called with
   */
  _buffer(methodName, params) {
    let item = {
      methodName,
      timestamp: new Date(),
      params,
    };

    this.buffer.push(item);

    while (this.buffer.length > this.maxBuffer) {
      this.buffer.shift();
    }

    if (this.follow && !this._filtered(item)) {
      this._forward(item);
    }
  }

  /**
   * An item in the Qconsole buffer.
   *
   * @typedef {Object} Qconsole~BufferedItem
   * @property {Date} timestamp - the time the item was added to the buffer
   * @property {string} methodName - the console method called
   * @property {*[]} params - the params that the console method was called with
   */

  /**
   * Forwards the buffered item to the underlying console implementation.
   *
   * @param {Qconsole~BufferedItem} item - the item to forward to the console
   * implementation.
   */
  _forward(item) {
    const args = [item.timestamp.toISOString()].concat(item.params);
    this.console[item.methodName](...args);
  }

  /**
   * Determines whether the supplied item should be forwarded to the underlying
   * console.
   *
   * @param {Qconsole~BufferedItem} buffered - a {@link Qconsole~BufferedItem} object
   * @returns {boolean} whether this item should be forwarded to the underlying
   * console.
   */
  _filtered(buffered) {
    return !this._match(buffered.params, 5);
  }

  /**
   * Determines whether the supplied item matches the regexp in `this.match`.
   * May be called recursively.
   *
   * @param {*} item - any item
   * @param {number} depth - the depth to explore the object tree
   * @returns {boolean} whether the item matches `this.match`.
   */
  _match(item, depth) {
    if (this.match == null) {
      return true;
    } // match by default
    if (depth < 0) {
      return false;
    }
    if (item == null) {
      return false;
    }

    if (Array.isArray(item)) {
      return item.some(function(val) {
        return this._match(val, depth - 1);
      }, this);
    }

    if (typeof item === 'object' && !(item instanceof RegExp)) {
      return Object.keys(item).some(function(key) {
        return this._match(key, depth - 1) || this._match(item[key], depth - 1);
      }, this);
    }

    if (typeof item.toString === 'function') {
      return this.match.test(item.toString());
    }

    return false;
  }

  /**
   * Saves the current following state in the session store, so that it can
   * persist across reloads.
   *
   * @param {Object} opts - options
   * @param {boolean} opts.follow - should the logs be printed on reload?
   * @param {RegExp} [opts.match] - the matching lines to print while following
   */
  _storeFollowOpts({ follow, match }) {
    if (!follow) {
      this.storage.removeItem('qconsole');
      return;
    }
    this.storage.setItem('qconsole', JSON.stringify({ follow, match: match ? match.toString() : null }));
  }

  /**
   * Restores the following state from the session store, so that it can
   * persist across reloads.
   */
  _restoreFollowOpts() {
    let opts = this.storage.getItem('qconsole');
    if (!opts) {
      return;
    }

    opts = JSON.parse(opts);
    this.follow = opts.follow;

    if (opts.match) {
      let parts = opts.match.match(/\/(.*?)\/([gimy]*)$/);
      this.match = new RegExp(parts[1], parts[2] || '');
    }
  }
}
