import { registerUrlConverter } from './location_url';
import Integrations from 'models/location/integrations';
import WebhooksAdmin from 'models/location/integrations/webhooks_admin';

import CloseWebhooks from 'actions/integrations/webhooks/close_webhooks';
import OpenIntegrations from 'actions/integrations/open_integrations';
import OpenWebhooks from 'actions/integrations/webhooks/open_webhooks';

import StaticUrlConverter from './url_converters/static_url_converter';

registerUrlConverter(Integrations, StaticUrlConverter.create('/integrations'));
registerUrlConverter(WebhooksAdmin, StaticUrlConverter.create('/integrations/webhooks'));

export default function(router, { runnerContext }) {
  return {
    '/': {
      after() {},

      '/?$': {
        on() {
          runnerContext.executeAction(OpenIntegrations);
        },
      },

      '/webhooks': {
        on() {
          runnerContext.executeAction(OpenWebhooks);
        },
        after() {
          runnerContext.executeAction(CloseWebhooks);
        },
      },
    },
  };
}
