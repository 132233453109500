import IdGenerator from 'scripts/domain/contracts/id_generator';
import mixin from 'scripts/lib/mixin';
import Observable from 'scripts/lib/observable_mixin';

const DEFAULT_TIMEOUT = 30000; // 30s

export function url(version, orgId, customerId, conversationId) {
  return `/api/${version}/orgs/${orgId}/customers/${customerId}/conversations/${conversationId}/topic-suggestions`;
}

export default class TopicSuggestionsGateway {
  constructor(http, requestorId) {
    this.http = http;
    this.requestorId = requestorId;
  }

  init({ orgId }) {
    this.orgId = orgId;
  }

  get version() {
    return 'v1';
  }

  url(customerId, conversationId) {
    return `/api/${this.version}/orgs/${this.orgId}/customers/${customerId}/conversations/${conversationId}/topic-suggestions`;
  }

  request(customerId, conversationId) {
    this.http.get(this.url(customerId, conversationId), this.handleResponse.bind(this, customerId), {
      timeout: DEFAULT_TIMEOUT,
      headers: { 'Gladly-Correlation-Id': IdGenerator.newId(), 'Gladly-Requestor-Id': this.requestorId },
    });
  }

  handleResponse(customerId, err, res) {
    if (err) {
      this.notifyObservers('handleRequestError', err, customerId);
      return;
    }
    switch (res.status) {
      case 200:
        this.notifyObservers('handleRequestedEntity', res.response, customerId);
        break;
      default:
        this.notifyObservers('handleTopicSuggestionError', res.response, customerId);
    }
  }
}

mixin(TopicSuggestionsGateway.prototype, Observable);
