import _ from 'lodash';
import HtmlToText from 'html-to-text';

import { ChannelFieldName } from './snippet';
import { createAttachmentsValidator, createTotalSizeValidator } from 'models/email_message';
import createBlankError from 'scripts/domain/factories/error/create_blank_error';
import createTooLongError from 'scripts/domain/factories/error/create_too_long_error';

export const MAX_LENGTH = {
  name: 500,
  subject: 500,
  text: 25000,
  description: 10000,
};

export function getValidationErrors(props) {
  let errors = [];

  let name = _.get(props, 'name');

  if (valueIsBlank(name)) {
    errors.push(createBlankError('name'));
  }

  if (valueIsTooLong(name, 'name')) {
    errors.push(createTooLongError('name', `${MAX_LENGTH['name']} characters`));
  }

  if (!props.contents.length) {
    errors.push(createBlankError('contents', 'answer'));
  } else {
    _.forEach(props.contents, channels => {
      errors = errors.concat(getContentErrors(channels));
    });
  }

  return errors;
}

function getContentErrors(channels) {
  if (!channels.anyChannel && !channels.info && !channels.message && !channels.selfService) {
    return createBlankError(getErrorAttr('text'), 'answer');
  }

  let bodyHtmlErrors = [];
  let anyChannelSubject = _.get(channels, 'anyChannel.subject');
  let anyChannelBodyHtml = _.get(channels, 'anyChannel.bodyHtml');
  let infoBodyHtml = _.get(channels, 'info.bodyHtml');
  let messageBodyHtml = _.get(channels, 'message.bodyHtml');
  let selfServiceBodyHtml = _.get(channels, 'selfService.bodyHtml');
  let selfServiceName = _.get(channels, 'selfService.name');

  if (channels.anyChannel && valueIsBlank(anyChannelBodyHtml)) {
    bodyHtmlErrors.push(createBlankError(getErrorAttr(ChannelFieldName.ANY_CHANNEL), 'email answer'));
  }

  if (channels.info && valueIsBlank(infoBodyHtml)) {
    bodyHtmlErrors.push(createBlankError(getErrorAttr(ChannelFieldName.INFO), 'reference answer'));
  }

  if (channels.message && valueIsBlank(messageBodyHtml)) {
    bodyHtmlErrors.push(createBlankError(getErrorAttr(ChannelFieldName.MESSAGE), 'message answer'));
  }

  if (channels.selfService) {
    if (valueIsBlank(selfServiceBodyHtml)) {
      bodyHtmlErrors.push(createBlankError(getErrorAttr(ChannelFieldName.SELF_SERVICE), 'self service answer'));
    } else if (valueIsBlank(selfServiceName)) {
      bodyHtmlErrors.push(createBlankError(getErrorAttr('selfServiceName'), 'Self service title'));
    }
  }

  if (valueIsTooLong(anyChannelBodyHtml, 'text')) {
    bodyHtmlErrors.push(
      createTooLongError(getErrorAttr(ChannelFieldName.ANY_CHANNEL), `${MAX_LENGTH['text']} characters`, 'email answer')
    );
  }

  if (valueIsTooLong(anyChannelSubject, 'subject')) {
    bodyHtmlErrors.push(
      createTooLongError(getErrorAttr('subject'), `${MAX_LENGTH['subject']} characters`, 'email subject')
    );
  }

  if (valueIsTooLong(infoBodyHtml, 'text')) {
    bodyHtmlErrors.push(
      createTooLongError(getErrorAttr(ChannelFieldName.INFO), `${MAX_LENGTH['text']} characters`, 'reference answer')
    );
  }

  if (valueIsTooLong(messageBodyHtml, 'text')) {
    bodyHtmlErrors.push(
      createTooLongError(getErrorAttr(ChannelFieldName.MESSAGE), `${MAX_LENGTH['text']} characters`, 'message answer')
    );
  }

  if (valueIsTooLong(selfServiceBodyHtml, 'text')) {
    bodyHtmlErrors.push(
      createTooLongError(
        getErrorAttr(ChannelFieldName.SELF_SERVICE),
        `${MAX_LENGTH['text']} characters`,
        'self service answer'
      )
    );
  }

  return bodyHtmlErrors;

  function getErrorAttr(field) {
    return getAttrForLanguage({ language: channels.language, field });
  }
}

function valueIsTooLong(value, key) {
  if (!value) {
    return false;
  }

  return HtmlToText.fromString(value, { wordwrap: false }).length > MAX_LENGTH[key];
}

function valueIsBlank(value) {
  return !value || !value.length || !HtmlToText.fromString(value).length;
}

export function getAttrForLanguage({ language, field }) {
  let languageAgnostic = ['name', 'description'].indexOf(field) !== -1;
  if (languageAgnostic) {
    return field;
  }
  return `${language}.${field}`;
}

export const validateAttachments = createAttachmentsValidator('save answer');
export const validateTotalSize = createTotalSizeValidator('Answer');
