import _ from 'lodash';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import IdGenerator from 'scripts/domain/contracts/id_generator';

function httpClientMiddleware(eventRecorder, requestorId) {
  return function(config) {
    if (!config.headers[HEADER_CORRELATION_ID]) {
      config.headers[HEADER_CORRELATION_ID] = IdGenerator.newId();
    }

    if (requestorId) {
      config.headers['Gladly-Requestor-Id'] = requestorId;
    }

    if (eventRecorder) {
      let correlationId = config.headers[HEADER_CORRELATION_ID];
      let name = `${config.method.toUpperCase()} ${config.url}`;
      eventRecorder.recordEvent(correlationId, name);
    }

    return config;
  };
}

export function createHttpClient(eventRecorder, requestorId, config) {
  config = Object.assign({ xsrfCookieName: '_csrf_token', xsrfHeaderName: 'X-CSRF-Token' }, config);
  let client = axios.create(config);
  client.interceptors.request.use(httpClientMiddleware(eventRecorder, requestorId));
  let retries = 3;
  axiosRetry(client, { retries });
  return client;
}

export function isResponseSuccessful(res) {
  const status = _.get(res, 'status');
  return status === 200 || status === 201 || status === 204;
}

export function getError(err, res) {
  return _.get(res, 'data') || err;
}

export function after(responsePromise, handler) {
  return responsePromise
    .then(response => {
      handler(null, response);
    })
    .catch(err => {
      if (err.response) {
        // Non 2XX response and has actual response body
        handler(undefined, err.response);
      } else if (err.request) {
        // Request was made but a response was not received
        handler(err, null);
      } else {
        // Something happened setting up request.
        handler(err, null);
      }
    });
}

export const HEADER_CORRELATION_ID = 'Gladly-Correlation-Id';
