import MqttGateway from './lib/mqtt_gateway';
import _ from 'lodash';

export default class UserPasswordGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'users/+/password';
  }

  update(userId, userUpdate) {
    return super._update([userId], userUpdate);
  }

  onUpdateSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateSuccess', _.last(parsedTopicParams), payload);
  }

  onUpdateErrors(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateErrors', _.last(parsedTopicParams), payload);
  }
}
