import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';

export default class ToggleSidebarCollapse {
  constructor(context) {
    this.context = context;
  }

  run({ windowWidth }) {
    const preferences = this.context.stores.agentPreferences.get();
    preferences.toggleShouldCollapseSidebar();
    setAgentPreferences(this.context, preferences);

    this.context.analytics.track('Customer List Collapse Toggled', {
      collapsed: preferences.shouldCollapseSidebar,
      isAnswerPanelOpen: this.context.stores.answerPanel.get().isOpen(),
      isTaskPanelOpen: this.context.stores.commentPanel.get().isOpen(),
      windowWidth,
    });
  }
}
