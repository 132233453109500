import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('Snippet').extend('Uuid');

Factory.define('SnippetWithDefaults')
  .extend('Snippet')
  .attr('content', () => Factory.attributes('SnippetChannelsWithDefaults'))
  .attr('contents', ['content'], content => [content])
  .attr('language', 'en-us')
  .attr('name', ['id'], id => `snippet-${id}`)
  .attr('plaintext', ['name'], name => `${name} text`);

Factory.define('SnippetChannels').extend('Uuid');

Factory.define('SnippetChannelsWithDefaults')
  .extend('SnippetChannels')
  .attr('anyChannel', () => Factory.attributes('SnippetContentWithDefaults'))
  .attr('info', () => Factory.attributes('SnippetContentWithDefaults'))
  .attr('message', () => Factory.attributes('SnippetContentWithDefaults'))
  .attr('selfService', () => Factory.attributes('SnippetContentWithDefaults'))
  .attr('public', () => Factory.attributes('SnippetContentWithDefaults')) // public is demo-only
  .attr('language', 'en-us');

Factory.define('SnippetWithAnyChannel')
  .extend('SnippetWithDefaults')
  .option('text', 'some body text')
  .option('attachments', [])
  .attr('contents', ['attachments', 'text'], (attachments, text) => [
    Factory.attributes('SnippetChannels', {
      anyChannel: { attachments, bodyHtml: text },
      language: 'en-us',
    }),
  ]);

Factory.define('SnippetContent');

Factory.define('SnippetContentWithDefaults')
  .extend('SnippetContent')
  .attr('attachments', [
    {
      id: 'plaintext-attachment',
      filename: 'some-file.txt',
      fileSize: 20,
      contentType: 'text/plain',
      source: {
        path: `kb/snippets/snippet-some-id/plaintext-attachment`,
        type: 'SNIPPET',
      },
      type: 'SNIPPET_ATTACHMENT',
    },
  ])
  .attr('bodyHtml', 'some body text')
  .attr('internal', false);

Factory.define('DeprecatedDemoSnippet')
  .extend('Uuid')
  .attr('text')
  .attr('attachments')
  .attr('content', ['text', 'attachments'], (text, attachments) => {
    return Factory.attributes('SnippetChannels', { anyChannel: { attachments, bodyHtml: text }, language: 'en-us' });
  })
  .attr('contents', ['content'], content => [content])
  .after(buildObject => {
    delete buildObject.attachments;
    delete buildObject.text;
  });
