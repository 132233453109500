import './lib/uuid';
import { Factory } from 'rosie';

Factory.define('AgentSearchHit').extend('Uuid');

Factory.define('AgentSearchHitWithDefaults')
  .extend('AgentSearchHit')
  .attr('agent', {
    id: 'agent-id',
    name: 'charlemagne',
    email: 'charlemagne@gmails.coms',
  })
  .attr('routingGroup', {
    id: 'routing-group-id',
    name: 'Charlies Angels',
  });
