import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { usePlateEditorState, isCollapsed, ELEMENT_LINK, usePlateEditorRef } from '@udecode/plate';

import { EVENT_EDIT_INLINE_LINK } from 'components/composer/inline_links/inline_link';
import { hasPluginOfType } from 'components/text_editor_new/lib/has_plugin_of_type';
import { Item } from 'components/text_editor_new/components/controls/controls_new';

function BaseLinkButton({ className }) {
  const disableLinkEditing = useRef(true);

  const editor = usePlateEditorState();
  disableLinkEditing.current = !editor.selection || (isCollapsed(editor.selection) && !editor.isLinkSelected());

  function openLinkEditor(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (editor?.events?.emit) {
      editor.events.emit(EVENT_EDIT_INLINE_LINK);
    }
  }

  /**
   * We intercept 'onMouseDown' in order to prevent 'click' event from happening, as it may interact with numerous
   * click-out handlers that we have, including the one used by LinkEditor component
   */
  return (
    <Item className={className} data-aid="link" isDisabled={disableLinkEditing.current} onMouseDown={openLinkEditor}>
      <i className="fa fa-link" />
    </Item>
  );
}

BaseLinkButton.propTypes = {
  className: PropTypes.string,
  hotKey: PropTypes.string,
};

export function LinkButton(props) {
  const editor = usePlateEditorRef();
  return hasPluginOfType(editor, ELEMENT_LINK) ? <BaseLinkButton {...props} /> : null;
}
