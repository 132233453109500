import React, { createElement } from 'react';

function defaultMapStateToProps(props, state) {
  return {
    ...props,
    ...state,
  };
}

/**
 * Example usage:

  function mapStateToProps(props, state) {
    return {
      // You could just ...props here to pass all props thru
      appendedCount: props.baseValue + state.count,
      className: props.className,
    };
  }

  function mapSetStateToProps(setState) {
    return {
      onIncrement: () => {
        // Notice I _only_ give you the `setState` function to work with. This forces you to use the newer
        // async safe callback syntax in order to access previous state
        setState((prevState, props) => {
          return { count: prevState.count + 1 };
        });
      },
    };
  };

  const Container = connectLocal({ mapStateToProps, mapSetStateToProps, initialState: { count: 2 } })(Component);

  initialState may also be a function rather than an object: (props) => { // return an object.. };
*/

export default function connectLocal({ mapStateToProps, mapSetStateToProps, initialState }) {
  if (!mapStateToProps) {
    mapStateToProps = defaultMapStateToProps;
  }
  if (!initialState) {
    initialState = {};
  }

  return function(WrappedComponent) {
    const name = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const Clazz = class LocalStateContainer extends React.Component {
      constructor(props) {
        super(props);
        this.state = typeof initialState === 'function' ? initialState(props) : initialState;
        this.setWrappedInstance = this.setWrappedInstance.bind(this);
      }

      getWrappedInstance() {
        return this.wrappedInstance;
      }
      setWrappedInstance(node) {
        this.wrappedInstance = node;
      }

      render() {
        const stateProps = mapStateToProps(this.props, this.state);
        const setStateProps = mapSetStateToProps(this.setState.bind(this));
        const wrappedInstance = (WrappedComponent.prototype.render && this.setWrappedInstance) || undefined;
        return createElement(WrappedComponent, {
          ...stateProps,
          ...setStateProps,
          ref: wrappedInstance,
        });
      }
    };
    Clazz.displayName = `${name}LocalContainer`;
    return Clazz;
  };
}
