import React from 'react';
import styled, { css } from 'styled-components';

import ConversationIcon from 'components/lib/conversation_icon';
import ConversationItemType from 'models/conversation_item_type';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import EmailStrokeIcon from 'components/common/icons/stroke/email-stroke';
import FacebookMessengerStrokeIcon from 'components/common/icons/stroke/facebook-messenger-stroke';
import { faTwitter, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MessageChannelType } from 'models/conversation_message';
import NoteStrokeIcon from 'components/common/icons/stroke/note-stroke';
import SmsStrokeIcon from 'components/common/icons/stroke/sms-stroke';
import TaskStrokeIcon from 'components/common/icons/stroke/task-stroke';
import VoiceStrokeIcon from 'components/common/icons/stroke/voice-stroke';
import VoicemailStrokeIcon from 'components/common/icons/stroke/voicemail-stroke';

export default function ChannelIcon({ conversationItem, isCurrent }) {
  if (!conversationItem) {
    return null;
  }
  let conversationType = conversationItem.contentType();

  switch (conversationType) {
    case ConversationItemType.CHAT_MESSAGE:
      return <ChatIcon $isCurrent={isCurrent} data-aid="sidebarInbox-chatIcon" />;
    case ConversationItemType.CONVERSATION_MESSAGE:
      return <MessageIcon conversationItem={conversationItem} isCurrent={isCurrent} />;
    case ConversationItemType.EMAIL:
      return <EmailIcon $isCurrent={isCurrent} data-aid="sidebarInbox-emailIcon" />;
    case ConversationItemType.FB_MESSAGE_INCOMING:
      return <FacebookIcon $isCurrent={isCurrent} data-aid="sidebarInbox-facebookIcon" />;
    case ConversationItemType.CONVERSATION_NOTE:
      return <NoteIcon $isCurrent={isCurrent} data-aid="sidebarInbox-noteIcon" />;
    case ConversationItemType.PHONE_CALL:
      return <PhoneIcon $isCurrent={isCurrent} data-aid="sidebarInbox-phonecallIcon" />;
    case ConversationItemType.SMS:
      return <SmsIcon $isCurrent={isCurrent} data-aid="sidebarInbox-smsIcon" />;
    case ConversationItemType.TASK:
      return <TaskIcon $isCurrent={isCurrent} data-aid="sidebarInbox-taskIcon" />;
    case ConversationItemType.VOICEMAIL:
      return <VoicemailIcon $isCurrent={isCurrent} data-aid="sidebarInbox-voicemailIcon" />;
    default:
      return (
        <ConversationIconContainer $isCurrent={isCurrent}>
          <ConversationIcon type={conversationType} />
        </ConversationIconContainer>
      );
  }
}

export function MessageIcon({ conversationItem, isCurrent }) {
  let channel = conversationItem.content.getChannelType();

  switch (channel) {
    case MessageChannelType.WHATSAPP:
      return <StyledMessageIcon $isCurrent={isCurrent} data-aid="sidebarInbox-whatsappIcon" icon={faWhatsapp} />;
    case MessageChannelType.TWITTER:
      return <StyledMessageIcon $isCurrent={isCurrent} data-aid="sidebarInbox-twitterIcon" icon={faTwitter} />;
    case MessageChannelType.INSTAGRAM_DIRECT:
      return <StyledMessageIcon $isCurrent={isCurrent} data-aid="sidebarInbox-instagramIcon" icon={faInstagram} />;
    default:
      return null;
  }
}

const iconStyles = css`
  align-self: center;
  fill: ${p => (p.$isCurrent ? p.theme.colors.white : p.theme.colors.gray600)};
  height: 19px;
  margin-left: ${p => p.theme.spacing.small};
  width: 23px;
`;

const ChatIcon = styled(ChatIconOutline)`
  ${iconStyles}
  stroke: ${p => (p.$isCurrent ? p.theme.colors.white : p.theme.colors.gray600)};
`;

const EmailIcon = styled(EmailStrokeIcon)`
  ${iconStyles}
`;

const FacebookIcon = styled(FacebookMessengerStrokeIcon)`
  ${iconStyles}
`;

const NoteIcon = styled(NoteStrokeIcon)`
  ${iconStyles}
`;

const SmsIcon = styled(SmsStrokeIcon)`
  ${iconStyles}
`;

const StyledMessageIcon = styled(FontAwesomeIcon)`
  align-self: center;
  color: ${p => (p.$isCurrent ? p.theme.colors.white : p.theme.colors.gray600)};
  font-size: 20px;
  margin-left: ${p => p.theme.spacing.small};
`;

const TaskIcon = styled(TaskStrokeIcon)`
  ${iconStyles}
`;

const PhoneIcon = styled(VoiceStrokeIcon)`
  ${iconStyles}
`;

const VoicemailIcon = styled(VoicemailStrokeIcon)`
  ${iconStyles}
`;

const ConversationIconContainer = styled.div`
  align-self: center;
  color: ${p => (p.$isCurrent ? p.theme.colors.white : p.theme.colors.gray600)};
  margin-left: ${p => p.theme.spacing.small};

  .profileAvatar-iconWrapper {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: inherit;
    line-height: 1;
    min-height: inherit;
    min-width: inherit;
    padding-left: 4px;
    position: relative;
  }

  .conversationIcon {
    background: transparent;
    color: inherit;
    height: inherit;
    line-height: 1;
    padding: 0 1px;
    width: inherit;
  }
`;
