import _ from 'lodash';

import connect from 'components/lib/connect';
import CloseCustomerUnlinkModal from 'actions/customer/close_customer_unlink_modal';
import { CustomerUnlinkModal } from './customer_unlink_modal';
import UnlinkExternalCustomer from 'actions/external_customer_lookup/unlink_external_customer';

function mapStateToProps({ getProvider }) {
  const isUnlinkPending = getProvider('customerUnlink').isPending();

  const profile = getProvider('profile').get();
  const profileDef = getProvider('customerProfileDef').get();

  let flattenedProfile = _.pick(profile.toJs(), ['name', 'address', 'emails', 'phones', 'twitter']);
  flattenedProfile = _.pickBy(flattenedProfile, a => !_.isArray(a) || !_.isEmpty(a));

  const profileCustomAttributes = profileDef
    ? _.filter(profileDef.attributes, a => !!_.get(profile.customAttributes, a.attr))
    : [];

  const partitionedFields = _.partition(profileCustomAttributes, a => {
    return a.presentation === 'EDITABLE';
  });

  // This map will have entries for Multi-source lookup/linking and will be empty otherwise
  const externalCustomerIds = _.get(profile, 'externalCustomerIds', {});
  const totalLinkCount = _.isEmpty(externalCustomerIds) ? 1 : _.keys(externalCustomerIds).length;

  return {
    customerId: _.get(profile, 'id'),
    totalLinkCount,
    fieldsToRemove: _.map(partitionedFields[1], f => f.label),
    fieldsToRemain: _.concat(
      _.map(partitionedFields[0], f => f.label),
      _.keys(flattenedProfile)
    ),
    isUnlinkPending,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClose: () => {
      executeAction(CloseCustomerUnlinkModal);
    },
    onUnlink: props => {
      executeAction(UnlinkExternalCustomer, props);
    },
  };
}

const CustomerUnlinkModalContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerUnlinkModal);

export default CustomerUnlinkModalContainer;
