import createModalModel, { prop } from './lib/create_modal_model';

export default createModalModel({
  modelName: 'CloseConversationWarning',
  properties: {
    shouldGetNext: prop(Boolean).default(false),
    conversationId: prop(String).isRequired,
    customerId: prop(String).isRequired,
  },
});
