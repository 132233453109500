import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * @visibleName Horizontal Control Container
 */

const HorizontalControlContainer = ({ label, description, control, error, 'data-aid': dataAid, ...otherProps }) => (
  <StyledControlContainer data-aid={`${dataAid}-horizontal-control`} {...otherProps}>
    {label && <ControlLabel data-aid={`${dataAid}-label`}>{label}</ControlLabel>}
    <ControlWrapper data-aid={`${dataAid}-input-wrapper`} error={error}>
      {control}
    </ControlWrapper>
    {error && <ErrorLabel data-aid={`${dataAid}-error`}>{error}</ErrorLabel>}
    {!error && description && (
      <ControlDescription data-aid={`${dataAid}-description`}>{description}</ControlDescription>
    )}
  </StyledControlContainer>
);

HorizontalControlContainer.propTypes = {
  label: PropTypes.any,
  description: PropTypes.any,
  control: PropTypes.element,
  error: PropTypes.string,
  'data-aid': PropTypes.string,
};

const StyledControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${p => p.theme.fontSize.base};
  margin-bottom: ${p => p.theme.spacing.stackSmall};
  min-height: 34px;
`;

const ControlWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 34px;
  margin-right: ${p => p.theme.spacing.inlineMedium};
  min-width: 320px;
  width: 320px;
`;

const ControlLabel = styled.label`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${p => p.theme.spacing.inlineMedium};
  min-height: 34px;
  min-width: 120px;
  width: 120px;
`;

const ControlDescription = styled.div`
  align-self: flex-start;
  color: ${p => p.theme.colors.gray600};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  line-height: 1.6;
  min-height: 34px;
`;

const ErrorLabel = styled.div`
  color: ${p => p.theme.colors.red400};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default HorizontalControlContainer;
