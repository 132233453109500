import DtoConverter from 'scripts/application/dto_converters/kb_variable_converter';
import mixin from 'scripts/lib/mixin';
import CollectionStoreSaveResultHandler from '../lib/collection_store_save_result_handler';
import qconsole from 'scripts/lib/qconsole';

export default class KbVariableGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.kbVariables;
  }

  handleRequestedEntities(kbVariableDtos) {
    this.store.set(kbVariableDtos.map(DtoConverter.fromDto));
  }

  handleRequestError(errorDto) {
    qconsole.log('Failed to fetch kb variable', errorDto);
  }

  handleEntity(kbVariableDto) {
    this.store.addOrReplace(DtoConverter.fromDto(kbVariableDto));
  }

  handleUpdateErrors(id, errorDto) {
    qconsole.log(`Failed to update ${id} ${JSON.stringify(errorDto)}`);
  }

  handleUpdateSuccess(id, kbVariablesDto) {
    qconsole.log(`Successfully updated ${id} ${JSON.stringify(kbVariablesDto)}`);
  }
}

mixin(KbVariableGatewayObserver.prototype, CollectionStoreSaveResultHandler.prototype);
