import _, { map } from 'lodash';
import { produce } from 'immer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import Chip, { ChipText, IconWrapper } from 'components/common/chip';
import EmailSearchMenu from './email_search_menu';
import TrackEmailInserted from 'actions/email_suggestions/track_email_inserted';
import useRerender from 'components/hooks/use_rerender';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const EMAIL_REGEX = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export function RecipientRow({ autoFocus, 'data-aid': dataAid, label, onChange, values }) {
  const [hasFocus, setHasFocus] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);

  const onDelete = useCallback(
    valueToDelete => {
      onChange(_.filter(values, value => value !== valueToDelete));
    },
    [onChange, values]
  );
  const pillsRef = useRef(null);

  return (
    <StyledRow data-aid={dataAid}>
      <RowLabel isActive={hasFocus}>{label}</RowLabel>
      <Pills onBlur={() => setHasFocus(false)} onFocus={() => setHasFocus(true)} ref={pillsRef} tabIndex="-1">
        {_.map(values, (value, index) =>
          editingIndex === index ? (
            <EditEmail
              autoFocus
              editingIndex={editingIndex}
              key={value}
              onChange={onChange}
              pillsRef={pillsRef}
              setEditingIndex={setEditingIndex}
              values={values}
            />
          ) : (
            <Pill
              data-aid={`email-pill-${value}`}
              key={value}
              label={value}
              onDelete={() => onDelete(value)}
              onDoubleClick={() => setEditingIndex(index)}
              title={value}
            />
          )
        )}
        {editingIndex === -1 ? (
          <EditEmail
            autoFocus={autoFocus}
            editingIndex={editingIndex}
            onChange={onChange}
            pillsRef={pillsRef}
            setEditingIndex={setEditingIndex}
            values={values}
          />
        ) : null}
      </Pills>
    </StyledRow>
  );
}

export default React.memo(RecipientRow);

const StyledRow = styled.div`
  display: flex;
`;
const RowLabel = styled.div`
  align-items: flex-start;
  color: ${p => (p.isActive ? p.theme.colors.green400 : p.theme.colors.gray600)};
  display: flex;
  margin-right: 4px;
  margin-top: 2px;
`;
const Pills = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  outline: none;
`;
const Pill = styled(Chip)`
  cursor: pointer;
  height: 24px;
  margin-bottom: 2px;
  margin-right: 4px;
  margin-top: 2px;
  max-width: 300px;
  padding: 3px 24px 2px 8px;
  &:hover {
    background-color: ${p => p.theme.colors.gray100};
  }

  ${ChipText} {
    cursor: pointer;
  }

  ${IconWrapper} {
    height: 18px;
    margin-right: 2px;
    margin-top: 2px;
    width: 18px;
  }
`;

function EditEmail({ autoFocus, editingIndex, onChange, pillsRef, setEditingIndex, values }) {
  const [isFocused, setIsFocused] = useState(false);
  const emailRef = useRef(values[editingIndex] || '');
  const rerender = useRerender();

  const changeHandler = useCallback(
    values => {
      const valuesWithoutZeroWidthCharacters = map(values, value => {
        return removeZeroWidth(value);
      });
      onChange && onChange(valuesWithoutZeroWidthCharacters);
    },
    [onChange]
  );

  const onBlur = useOnBlur({ editingIndex, emailRef, onChange: changeHandler, setEditingIndex, setIsFocused, values });
  const onKeyDown = useOnKeydown({ editingIndex, emailRef, onChange: changeHandler, setEditingIndex, values });

  // Focus at end of text area when mounted
  const textareaRef = useRef(null);
  useEffect(() => {
    if (autoFocus) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(emailRef.current.length, emailRef.current.length);
    }
  }, [autoFocus]);
  const calculateStyle = useCalculateStyle(pillsRef, emailRef);
  const [initialStyle] = useState(() => calculateStyle());
  const styleRef = useRef(initialStyle);
  const executeAction = useExecuteAction();

  const selectEmailSuggestion = useCallback(
    email => {
      if (editingIndex === -1) {
        changeHandler(_.concat(values, email));
        executeAction(TrackEmailInserted, { source: 'suggestion', type: 'add' });
      } else {
        changeHandler(
          produce(values, draft => {
            draft[editingIndex] = email;
          })
        );
        executeAction(TrackEmailInserted, { source: 'suggestion', type: 'edit' });
        setEditingIndex(-1);
      }
      emailRef.current = '';
      styleRef.current = calculateStyle();
      rerender();
    },
    [calculateStyle, editingIndex, emailRef, executeAction, setEditingIndex, styleRef, changeHandler, values]
  );

  return (
    <React.Fragment>
      <StyledEditTextarea
        autoFocus={autoFocus}
        data-aid={`email-recipients-edit`}
        onBlur={onBlur}
        onChange={evt => {
          const newValue = evt.target.value;
          if (!/\s/.test(_.last(newValue))) {
            emailRef.current = evt.target.value;
            styleRef.current = calculateStyle();
            rerender();
          }
        }}
        onFocus={() => setIsFocused(true)}
        onKeyDown={onKeyDown}
        ref={textareaRef}
        shouldGrow={editingIndex === -1 && emailRef.current === ''}
        style={styleRef.current}
        value={emailRef.current}
      />
      <EmailSearchMenu
        existingEmails={values}
        selectEmailSuggestion={selectEmailSuggestion}
        text={isFocused ? emailRef.current : ''}
        textareaRef={textareaRef}
      />
    </React.Fragment>
  );
}

const StyledEditTextarea = styled(ReactTextareaAutosize).withConfig({
  shouldForwardProp: prop => prop !== 'shouldGrow',
})`
  border: none;
  flex-grow: ${p => (p.shouldGrow ? '1' : 'initial')};
  height: 24px;
  margin: 1px 0;
  min-height: initial;
  min-width: 160px;
  outline: none;
  padding: 2px 4px;
`;

function useOnBlur({ editingIndex, emailRef, onChange, setEditingIndex, setIsFocused, values }) {
  const executeAction = useExecuteAction();
  return () => {
    setIsFocused(false);

    if (!EMAIL_REGEX.test(emailRef.current)) {
      return;
    }

    if (editingIndex === -1) {
      onChange(_.concat(values, emailRef.current));
      executeAction(TrackEmailInserted, { source: 'manual', type: 'add' });
      emailRef.current = '';
    } else {
      onChange(
        produce(values, draft => {
          draft[editingIndex] = emailRef.current;
        })
      );
      executeAction(TrackEmailInserted, { source: 'manual', type: 'edit' });
      setEditingIndex(-1);
    }
  };
}

function useOnKeydown({ editingIndex, emailRef, onChange, setEditingIndex, values }) {
  const executeAction = useExecuteAction();
  return evt => {
    const key = evt.key;
    // When we hit any whitespace character
    if (/\s/.test(key) || key === 'Enter' || key === 'Tab') {
      // Don't allow insertion of said character, unless it's a 'Tab' AND if the input is blank.
      // (This lets us tab to the next field if we're empty in our current one.)
      if (!(key === 'Tab' && !emailRef.current)) {
        evt.preventDefault();
      }
      // If it's not a valid email then don't insert it
      if (!EMAIL_REGEX.test(emailRef.current)) {
        return;
      }

      if (editingIndex === -1) {
        onChange(_.concat(values, emailRef.current));
        executeAction(TrackEmailInserted, { source: 'manual', type: 'add' });
        emailRef.current = '';
      } else {
        onChange(
          produce(values, draft => {
            draft[editingIndex] = emailRef.current;
          })
        );
        executeAction(TrackEmailInserted, { source: 'manual', type: 'edit' });
        setEditingIndex(-1);
      }
    }
  };
}

function useCalculateStyle(pillsRef, emailRef) {
  return useCallback(() => {
    const span = document.createElement('span');
    document.body.appendChild(span);

    span.style.height = '24px';
    span.style.margin = '1px 0';
    span.style.padding = '2px 4px';
    span.style.position = 'absolute';
    span.style.whiteSpace = 'no-wrap';
    span.style.width = 'auto';
    span.innerHTML = emailRef.current;

    const maxWidth = pillsRef.current ? Math.floor(pillsRef.current.getBoundingClientRect().width) : Infinity;
    let width = Math.ceil(span.clientWidth) + 10;
    if (maxWidth <= width) {
      width = maxWidth;
    }
    document.body.removeChild(span);

    return { width: `${width}px` };
  }, [pillsRef, emailRef]);
}

function removeZeroWidth(stringToTrim) {
  const ZERO_WIDTH_SPACES_REGEX = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g;
  return stringToTrim.replace(ZERO_WIDTH_SPACES_REGEX, '');
}
