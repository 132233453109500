import _ from 'lodash';

import GatewayErrorHandler from 'scripts/application/lib/gateway_error_handler';
import { getFriendlyMessage, InfrastructureError, RequestTimeout } from 'scripts/application/lib/error';
import mixin from 'scripts/lib/mixin';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import UnauthorizedHandler from 'actions/lib/unauthorized_handler';

/**
 * Handles typical errors occurring when communicating with a remote service
 * via a gateway by providing interactive feedback to the user via error messages.
 *
 * Sends unhandled errors to Sentry.
 *
 * This handler is useful when an error occurs in response to a user action, such
 * as clicking a button.
 *
 * @param context - the runner context
 * @param err - the error to handle
 */
export function handleCommonErrors(context, err) {
  let handler = new GatewayErrorInteractiveHandler(context);
  handler.handleCommonErrors(err);
}

/**
 * Common implementation of error handler for gateway observers
 *
 * Compose in a gateway observer as usual or mix in. If mixing in ensure the context property is defined.
 */
export default class GatewayErrorInteractiveHandler {
  constructor(context) {
    this.context = context;
  }

  /**
   * Handles errors originating outside of the business logic,
   * for instance, network, communication, service availability, server bugs, etc.
   *
   * @param ctx - request context to include in Sentry report for unexpected errors
   * @param err - the error to handle
   */
  handleInfrastructureErrors(ctx, err) {
    if (arguments.length === 1 || !err) {
      err = arguments[0];
      ctx = { correlationId: err.correlationId };
    }

    this.showErrorToast(err, getFriendlyMessage(err));

    if (!(err instanceof InfrastructureError)) {
      this.reportError(ctx, err); // We shouldn't see this, programming error.
    }
  }

  showErrorToast(err, message) {
    if (err instanceof RequestTimeout) {
      this.context.analytics.track('Request Timeout Toast Shown', { request: _.get(err, 'cause.config.url') });
    }
    this.context.executeAction(ShowToast, { type: ToastType.ERROR, message });
  }
}

mixin(GatewayErrorInteractiveHandler.prototype, GatewayErrorHandler.prototype);
mixin(GatewayErrorInteractiveHandler.prototype, UnauthorizedHandler.prototype);
