import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0)">
      <path
        clipRule="evenodd"
        d="M3.555 1.348a8 8 0 118.89 13.304 8 8 0 01-8.89-13.304zM5.037 12.48l1.082 2.193a6.898 6.898 0 003.762 0l1.082-2.193a.5.5 0 01.376-.273l2.428-.352a6.89 6.89 0 001.166-3.573l-1.766-1.712a.5.5 0 01-.145-.443l.41-2.416A6.964 6.964 0 0010.412 1.5L8.232 2.644a.5.5 0 01-.464 0L5.58 1.5A6.964 6.964 0 002.558 3.71l.42 2.415a.5.5 0 01-.146.445L1.067 8.28a6.89 6.89 0 001.173 3.574l2.42.352a.5.5 0 01.377.273zm6.115-5.5a.5.5 0 01.174.545l-1.098 3.552a.5.5 0 01-.478.353h-3.5a.5.5 0 01-.478-.353L4.674 7.524a.5.5 0 01.175-.544l2.847-2.177a.5.5 0 01.608 0l2.848 2.177z"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h16v16H0z" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
