import { Factory } from 'rosie';

import './customer_profile_factory';
import './conversation_factory';
import './conversation_items/email_preview_factory';

Factory.define('AgentInboxItem').extend('Uuid');

Factory.define('AgentInboxItemWithDefaults')
  .extend('AgentInboxItem')
  .attr('profile', () => Factory.attributes('CustomerProfileWithDefaults'))
  .attr('conversation', () => Factory.attributes('ConversationWithDefaults'))
  .attr('item', () => Factory.attributes('EmailPreviewItemWithDefaults'))
  .attr('type', 'CUSTOMER');
