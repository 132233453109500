import { createLocalRestBackend } from '../infrastructure/backends/local_rest_backend';
import * as databases from '../infrastructure/backends/fake_backend/database';
import DatasetGateway from '../infrastructure/backends/dataset_gateway';
import DemoRestClient from '../infrastructure/backends/demo_rest_client';
import FakeS3 from '../infrastructure/backends/fake_s3';
import LocalPubsubBackend from '../infrastructure/backends/local_pubsub_backend';
import CompositeRestClient from '../infrastructure/backends/composite_rest_client';
import XhrAdapter from '../infrastructure/backends/xhr_adapter';

export default function(eventRecorder, requestorId) {
  let xhrAdapter = new XhrAdapter(eventRecorder, requestorId);
  let localRest = createLocalRestBackend(
    DatasetGateway.instance,
    new FakeS3(),
    new LocalPubsubBackend(),
    databases.getDatabase
  );
  let demoClient = new DemoRestClient(xhrAdapter, localRest, databases.getDatabase);
  return new CompositeRestClient(xhrAdapter, demoClient);
}
