const ENTER = 13;
const ESCAPE = 27;
const ARROW_LEFT = 37;
const ARROW_UP = 38;
const ARROW_RIGHT = 39;
const ARROW_DOWN = 40;

const KeypressGlobalMixin = {
  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  },

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },

  handleKeyDown(event) {
    let metaShiftAlt = !!event.metaKey || !!event.shiftKey || !!event.altKey;

    if (event.keyCode === ESCAPE) {
      this.onCancel && this.onCancel(event);
    } else if (event.keyCode === ARROW_UP) {
      this.onArrowUp && this.onArrowUp(event);
    } else if (event.keyCode === ARROW_DOWN) {
      this.onArrowDown && this.onArrowDown(event);
    } else if (event.keyCode === ARROW_RIGHT) {
      this.onArrowRight && this.onArrowRight(event);
    } else if (event.keyCode === ARROW_LEFT) {
      this.onArrowLeft && this.onArrowLeft(event);
    } else if (event.keyCode === ENTER && !metaShiftAlt) {
      this.onSubmit && this.onSubmit(event);
    }

    this.onKeyDown && this.onKeyDown(event);
  },

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  },
};

export { ENTER, ESCAPE, ARROW_LEFT, ARROW_UP, ARROW_RIGHT, ARROW_DOWN };

export default KeypressGlobalMixin;
