import ModelFactory from 'scripts/domain/__specs__/factories/model_factory';

export default class AgentReadService {
  constructor(pubsub, getDatabase) {
    this.pubsub = pubsub;
    this.getDatabase = getDatabase;

    this.reads = {};

    this.fetch = this.fetch.bind(this);
    this.update = this.update.bind(this);
  }

  fetch(requestorId, orgId, conversationId, agentId, { correlationId }) {
    let agentRead = this.reads[conversationId];
    if (!agentRead) {
      agentRead = ModelFactory.attributes('AgentRead', { agentId, conversationId, readTo: new Date().toISOString() });
      this.reads[conversationId] = agentRead;
    }
    this.pubsub.publish(
      `v1/requestor/${requestorId}/orgs/${orgId}/conversation/${conversationId}/agent-reads/${agentId}`,
      { correlationId, status: 'success', payload: agentRead }
    );
  }

  update(requestorId, orgId, conversationId, agentId, { correlationId, payload }) {
    let agentRead = ModelFactory.attributes('AgentRead', { agentId, conversationId });
    agentRead = Object.assign({}, agentRead, payload);
    this.reads[conversationId] = agentRead;
    this.pubsub.publish(`v1/orgs/${orgId}/conversation/${conversationId}/agent-reads/${agentId}`, {
      correlationId,
      payload: agentRead,
    });
    this.pubsub.publish(
      `v1/requestor/${requestorId}/orgs/${orgId}/conversation/${conversationId}/agent-reads/${agentId}`,
      { correlationId, status: 'success' }
    );
  }
}
