import _ from 'lodash';
import Factory from 'factories/all';
import faker from 'faker';
import moment from 'moment';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import { RoutingChannel } from 'models/agent_routing_preferences';

export default class LiveboardService {
  constructor(publishResponse, database = getDatabase) {
    this.getDatabase = database;
  }

  getRoutes() {
    return bindCallbacks({
      '/api/v1/orgs/:orgId/liveboards/:liveboardType': {
        GET: (attrs, callback, path, { orgId, liveboardType }, query) => {
          callback(null, {
            status: 200,
            statusText: statusText(200),
            response: this.fetch(orgId, liveboardType, query),
          });
        },
      },
      '/api/v2/orgs/:orgId/liveboards/:liveboardType': {
        GET: (attrs, callback, path, { orgId, liveboardType }, query) => {
          callback(null, {
            status: 200,
            statusText: statusText(200),
            response: this.fetch(orgId, liveboardType, query),
          });
        },
      },
    });
  }

  fetch(orgId, liveboardType, query) {
    if (_.isEmpty(this.getDatabase(orgId).summaryLiveboard)) {
      return {};
    }
    switch (liveboardType) {
      case 'agents':
        return this.fetchAgentsLiveboard(orgId, query);
      case 'people-match':
        return this.fetchPeopleMatchLiveboard(orgId, query);
      case 'summary':
        return this.fetchSummaryLiveboard(orgId, query);
      case 'topics':
        return this.fetchTopicsLiveboard(orgId, query);
      default:
        throw new Error(`invalid liveboard requested [${liveboardType}]`);
    }
  }

  fetchAgentsLiveboard(orgId, query = {}) {
    let liveboard = this.getDatabase(orgId).summaryLiveboard;
    let agents = this._getAgents(orgId);
    let agentStatusReasons = this.getDatabase(orgId).agentStatusReasons;
    let activeReasons = _.filter(agentStatusReasons, { type: 'ACTIVE' });
    let awayReasons = _.filter(agentStatusReasons, { type: 'AWAY' });

    const customers = this.getDatabase(orgId).customers;
    const customersWithHistory = _.filter(
      customers,
      customer => customer.conversations && customer.conversations.length > 0
    );
    const agentActivities = ['CUSTOMER', 'INBOX', 'KNOWLEDGE_BASE', 'LIVEBOARDS', 'SEARCH', 'CONVERSATION_NOTE'];
    const sessionTypes = ['CHAT', 'FB_MESSENGER', 'INSTAGRAM_DIRECT', 'PHONE_CALL', 'SMS', 'WHATSAPP'];

    function getAgentUtilization(agentId, agentIndex) {
      let availableChannels = [];
      _(RoutingChannel)
        .values()
        .forEach(channel => {
          if (_.sample([true, false])) {
            availableChannels.push(channel);
          }
        });

      switch (agentIndex % 4) {
        case 0: {
          let activeSessions = [];

          if (customersWithHistory.length > 0) {
            const activeSessionCustomers = _.sampleSize(customersWithHistory, 2);
            activeSessions = _.sampleSize(
              [
                {
                  startedAt: moment()
                    .subtract(_.random(1, 3), 'minutes')
                    .subtract(_.random(1, 60), 'seconds')
                    .toISOString(),
                  customerId: activeSessionCustomers[0].id,
                  conversationId:
                    activeSessionCustomers[0].conversations[activeSessionCustomers[0].conversations.length - 1].id,
                  sessionTypes: [_.sample(sessionTypes)],
                },
                {
                  startedAt: moment()
                    .subtract(_.random(1, 5), 'minutes')
                    .subtract(_.random(1, 60), 'seconds')
                    .toISOString(),
                  customerId: activeSessionCustomers[1].id,
                  conversationId:
                    activeSessionCustomers[1].conversations[activeSessionCustomers[1].conversations.length - 1].id,
                  sessionTypes: [_.sample(sessionTypes)],
                },
              ],
              _.random(1, 2)
            );
          }

          return {
            agentId,
            status: 'READY',
            statusReasonId: _.get(_.sample(activeReasons), 'id'),
            availableChannels,
            activity: _.sample(agentActivities),
            activeSessions,
            statusUpdatedAt: moment()
              .subtract(agentIndex, 'seconds')
              .toISOString(),
            presenceUpdatedAt: moment()
              .subtract(agentIndex, 'seconds')
              .toISOString(),
            routingPreferences: {
              status: availableChannels.length > 0 ? _.sample(['ACTIVE', 'FOCUS']) : 'ACTIVE',
              updatedAt: moment()
                .subtract(agentIndex, 'seconds')
                .toISOString(),
            },
          };
        }
        case 1: {
          let sessionWork;
          if (customersWithHistory.length > 0) {
            sessionWork = {
              customerId: _.sample(customersWithHistory).id,
              sessionType: 'PHONE_CALL',
              workType: _.sample(['ACT', 'HANDLE']),
            };
          }
          return {
            agentId,
            status: 'BUSY',
            statusReasonId: _.get(_.sample(activeReasons), 'id'),
            availableChannels,
            activity: _.sample(agentActivities),
            statusUpdatedAt: moment()
              .subtract(11, 'minutes')
              .subtract(agentIndex, 'minutes')
              .toISOString(),
            presenceUpdatedAt: moment()
              .subtract(11, 'minutes')
              .subtract(agentIndex, 'minutes')
              .toISOString(),
            sessionWork,
            routingPreferences: {
              status: availableChannels.length > 0 ? _.sample(['ACTIVE', 'FOCUS']) : 'ACTIVE',
              updatedAt: moment()
                .subtract(11, 'minutes')
                .subtract(agentIndex, 'minutes')
                .toISOString(),
            },
          };
        }
        case 2:
          return {
            agentId,
            status: 'AWAY',
            availableChannels,
            statusReasonId: _.get(_.sample(awayReasons), 'id'),
            statusUpdatedAt: moment()
              .subtract(agentIndex, 'minutes')
              .toISOString(),
            presenceUpdatedAt: moment()
              .subtract(agentIndex, 'minutes')
              .toISOString(),
            routingPreferences: {
              status: 'AWAY',
              updatedAt: moment()
                .subtract(agentIndex, 'minutes')
                .toISOString(),
            },
          };

        case 3: {
          const currentCustomer = customersWithHistory[_.random(0, customersWithHistory.length - 1)];
          const conversationId = currentCustomer.conversations[currentCustomer.conversations.length - 1].id;
          return {
            agentId,
            status: 'BUSY',
            statusReasonId: _.get(_.sample(activeReasons), 'id'),
            availableChannels,
            activity: _.sample(['PHONE', 'EMAIL', 'CONVERSATION_NOTE']),
            statusUpdatedAt: moment()
              .subtract(11, 'minutes')
              .subtract(agentIndex, 'minutes')
              .toISOString(),
            presenceUpdatedAt: moment()
              .subtract(11, 'minutes')
              .subtract(agentIndex, 'minutes')
              .toISOString(),
            routingPreferences: {
              status: availableChannels.length > 0 ? _.sample(['ACTIVE', 'FOCUS']) : 'ACTIVE',
              updatedAt: moment()
                .subtract(11, 'minutes')
                .subtract(agentIndex, 'minutes')
                .toISOString(),
            },
            activeSessions: [
              {
                conversationId,
                customerId: currentCustomer.id,
                startedAt: moment()
                  .subtract(_.random(1, 15), 'minutes')
                  .subtract(_.random(1, 60), 'seconds')
                  .toISOString(),
                sessionTypes: [_.sample(sessionTypes)],
              },
            ],
          };
        }
      }
    }

    liveboard.utilization = agents.map(function(agent, index) {
      return getAgentUtilization(agent.id, index);
    });

    if (!query.routingGroupId) {
      return liveboard;
    }

    return { utilization: liveboard.utilization };
  }

  fetchPeopleMatchLiveboard(orgId, payload = {}) {
    let agents = this._getAgents(orgId);

    let customersWithHistory = _.filter(
      this.getDatabase(orgId).customers,
      customer => customer.conversations && customer.conversations.length > 0
    );

    let entries = [];
    customersWithHistory.forEach((customer, index) => {
      let smartMatchEntry = {
        agentId: agents[Math.floor(Math.random() * agents.length)].id,
        customerId: customer.id,
        conversationId: customer.conversations[customer.conversations.length - 1].id,
        customerName: customer.profile.name,
        createdAt: moment()
          .subtract(15 * entries.length, 'minutes')
          .toISOString(),
        ruleScores: ruleScoresForCustomer(_.random(0, 7)),
      };

      entries.push(smartMatchEntry);
    });

    return { entries };
  }

  fetchSummaryLiveboard(orgId, payload = {}) {
    let liveboard = this.getDatabase(orgId).summaryLiveboard;

    ['agent', 'conversation'].forEach(function(v) {
      _.forEach(liveboard[v], function(val, key) {
        liveboard[v][key] = randomValueWithMax(val);
      });
    });
    ['channel', 'sla'].forEach(function(v) {
      ['email', 'phone', 'sms', 'fbMessage', 'whatsapp'].forEach(function(c) {
        _.forEach(liveboard[v][c], function(val, key) {
          if (key !== 'oldestUnanswered') {
            liveboard[v][c][key] = randomValueWithMax(val);
          }
        });
      });
    });

    if (!payload.routingGroupId) {
      return liveboard;
    }

    let routingGroupBoard = {};
    _.forEach(liveboard, function(field, key) {
      if (key === 'trend') {
        routingGroupBoard.trend = _.clone(liveboard.trend);
        routingGroupBoard.trend.forEach(function(trend, trendKey) {
          trend.newConversations = randomValueWithMax(trend.newConversations);
          trend.closedConversations = randomValueWithMax(trend.closedConversations);
          routingGroupBoard.trend[trendKey] = trend;
        });
      } else {
        routingGroupBoard[key] = {};
        _.forEach(field, function(val, k) {
          routingGroupBoard[key][k] = typeof val !== 'number' ? val : Math.floor(val / 2);
        });
      }
    });
    return routingGroupBoard;
  }

  fetchTopicsLiveboard(orgId, payload = {}) {
    let topics = this.getDatabase(orgId).topics;

    let lbTopics = topics.map(topic => {
      return Factory.build('TopicsLiveboardTopicWithDefaults', {
        id: topic.id,
        conversations: {
          openAssigned: randomValueWithMax(30, 50, 0.2),
          openUnassigned: randomValueWithMax(30, 50, 0.2),
          waiting: randomValueWithMax(100, 150, 0.2),
          closedToday: randomValueWithMax(200, 300, 0.2),
        },
        currentWait: randomValueWithMax(100000, 1000000, 0.1),
        sla: {
          over: randomValueWithMax(10, 20, 0.1),
          total: randomValueWithMax(100, 120, 0.1),
        },
      });
    });
    lbTopics = [
      Factory.build('TopicsLiveboardTopicWithDefaults', {
        id: null,
        conversations: {
          openAssigned: randomValueWithMax(30, 50, 0.2),
          openUnassigned: randomValueWithMax(30, 50, 0.2),
          waiting: randomValueWithMax(100, 150, 0.2),
          closedToday: randomValueWithMax(200, 300, 0.2),
        },
        currentWait: randomValueWithMax(100000, 1000000, 0.1),
        sla: {
          over: randomValueWithMax(10, 20, 0.1),
          total: randomValueWithMax(100, 120, 0.1),
        },
      }),
    ].concat(lbTopics);

    let totals = Factory.build('TopicsLiveboardTotalsWithDefaults', {
      groupId: payload.routingGroupId ? payload.routingGroupId : null,
      agentsOnline: randomValueWithMax(30, 50, 0.1),
      conversations: {
        openAssigned: randomValueWithMax(600, 1000),
        openUnassigned: randomValueWithMax(600, 1000),
        waiting: randomValueWithMax(1000, 2000),
        closedToday: randomValueWithMax(2000, 3000),
      },
      sla: {
        over: randomValueWithMax(10, 20),
        total: randomValueWithMax(100, 120),
      },
    });
    let lbParams = { topics: lbTopics, totals };

    return Factory.build('TopicsLiveboardWithDefaults', lbParams);
  }

  _getAgents(orgId) {
    return this.getDatabase(orgId).agents.filter(agent => !agent.id.startsWith('thankful'));
  }
}

function randomValueWithMax(startingValue, maxVal, rangePct = 0.05) {
  let max = Math.floor(startingValue * (1 + rangePct));
  let min = Math.ceil(startingValue * (1 - rangePct));

  let newVal = Math.floor(Math.random() * (max - min + 1)) + min;
  return !maxVal || newVal < maxVal ? newVal : maxVal;
}

function ruleScoresForCustomer(numRuleScores) {
  let ruleScores = [];
  let routingPriorities = [1, 3, 5, 10];

  for (let i = 0; i < numRuleScores; i++) {
    ruleScores.push({
      id: `ruleScore${i}`,
      name: faker.company.catchPhrase(),
      revisionId: 1,
      value: _.sample(routingPriorities),
    });
  }

  return ruleScores;
}
