import _ from 'lodash';
import { Editor } from 'slate';
import { ELEMENT_PARAGRAPH } from '@udecode/plate';
import { ReactEditor } from 'slate-react';

export default function clearEditorAndHistory(editor) {
  const marks = Editor.marks(editor);
  _.forEach(marks, (hasMark, mark) => {
    Editor.removeMark(editor, mark);
  });

  const point = { path: [0, 0], offset: 0 };
  editor.selection = { anchor: point, focus: point };
  editor.history = { redos: [], undos: [] };
  editor.children = [
    {
      type: ELEMENT_PARAGRAPH,
      children: [{ text: '' }],
    },
  ];

  ReactEditor.focus(editor);
}
