import _ from 'lodash';
import HtmlToText from 'html-to-text';

import { SnippetContentType } from 'models/answers/snippet';

export function parseIdFromLinkedAnswers(html) {
  let regex = /< *answer[^>]*data-id *= *"(.*?)"/g;
  let ids = [];
  let match = regex.exec(html);
  while (match != null) {
    ids.push(match[1]);
    match = regex.exec(html);
  }
  return ids;
}

export function createPlaintext(attrs) {
  if (!attrs.contents) return '';
  const content = attrs.contents[0];

  const text =
    _.get(content, 'anyChannel.bodyHtml') ||
    _.get(content, 'info.bodyHtml') ||
    _.get(content, 'message.bodyHtml') ||
    _.get(content, 'selfService.bodyHtml');
  return HtmlToText.fromString(text || '', { wordwrap: false });
}

export function getVariableIdsFromString(str) {
  let variableIds = [];
  let match;
  const re = /<variable data-id="([^"]+)">/g;
  while ((match = re.exec(str))) {
    let id = match[1];
    if (!variableIds.includes(id)) {
      variableIds = variableIds.concat(id);
    }
  }
  return variableIds;
}

export function getAllAttachments(snippet) {
  let results = [];
  if (!snippet || !snippet.contents) {
    return results;
  }

  snippet.contents.forEach(c => results.push(c.getAttachments()));
  return _.flatten(results);
}

export function getAllLinkedAnswerIds(snippet) {
  let results = [];
  if (!snippet || !snippet.contents) {
    return results;
  }
  snippet.contents.forEach(c => results.push(c.getLinkedAnswerIds()));
  return _.flatten(results);
}

export function convertAllUploadsToAttachments(snippet) {
  if (!snippet) {
    return;
  }

  snippet.contents.forEach(channels => {
    _.forEach(channels.getChannels(), content => content && content.convertUploadsToAttachments(snippet.id));
  });
}

export function findAndRemoveAttachment({ snippet, id }) {
  // deeply find attachment in contents, remove, and return
  let attachment;
  if (!snippet || !snippet.contents) {
    return attachment;
  }
  snippet.contents.forEach(c =>
    _.forEach(c.getChannels(), (snippetContent, channel) => {
      if (!snippetContent) {
        return;
      }
      let removed = snippetContent.removeAttachment(id);
      if (removed) {
        attachment = removed;
      }
    })
  );
  return attachment;
}

export function buildSnippetRefs(snippets, language, audiences, maxSnippetIdCount) {
  const allowedContentTypes = [
    SnippetContentType.MESSAGE,
    SnippetContentType.SELF_SERVICE,
    SnippetContentType.ANY_CHANNEL,
  ];
  let snippetsWithAllowedContentTypeAndLanguage = _.filter(snippets, snippet => {
    for (let i = 0; i < snippet.contents.length; i++) {
      if (
        snippet.contents[i].language === language &&
        snippetChannelsHasContentForOneOfTypes(snippet.contents[i], allowedContentTypes)
      ) {
        return true;
      }
    }
    return false;
  });

  if (snippetsWithAllowedContentTypeAndLanguage.length > 0) {
    let snippetIds = _.map(snippetsWithAllowedContentTypeAndLanguage, snippet => snippet.id);
    if (maxSnippetIdCount) {
      snippetIds = snippetIds.slice(0, maxSnippetIdCount);
    }
    return {
      audienceIds: audiences,
      snippetIds,
      language,
    };
  }
  return null;
}

function snippetChannelsHasContentForOneOfTypes(snippetChannels, contentTypes) {
  return contentTypes.reduce(
    (accumulator, snippetContentType) => accumulator || _.get(snippetChannels, snippetContentType) !== undefined,
    false
  );
}
