import AgentInboxMeta from 'models/agent_inbox/agent_inbox_meta';
import RequestAgentInbox from 'actions/inbox/agent/request_agent_inbox';
import RequestAgentInboxDep from 'actions/inbox/agent/request_agent_inbox_deprecated';

export default class IncreaseAgentInboxLimit {
  constructor(context) {
    this.context = context;
  }

  run() {
    const meta = this.context.stores.agentInboxMeta.get();
    const appFeatures = this.context.stores.appFeatures.get();

    let items;
    if (appFeatures.isEnabled('customerListAssignmentBroadcast')) {
      items = this.context.stores.agentInboxItemsNew.findAll();
    } else {
      items = this.context.stores.agentInboxItems.findAll();
    }

    const { limit: currentLimit } = meta;

    // If we have fewer items than the limit currently, there's no need to increase it
    if (currentLimit > items.length) {
      return;
    }

    meta.setLimit(currentLimit + AgentInboxMeta.PAGE_SIZE);
    this.context.stores.agentInboxMeta.set(meta);

    // Re-request inbox
    if (appFeatures.isEnabled('customerListAssignmentBroadcast')) {
      this.context.executeAction(RequestAgentInbox);
    } else {
      this.context.executeAction(RequestAgentInboxDep);
    }
  }
}
