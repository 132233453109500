import { createContentModel, isEmpty } from './composition_content_shared';

const NoteCompositionContent = createContentModel({
  modelName: 'NoteCompositionContent',

  properties: {
    bodyHtml: String,
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },
});

NoteCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB = 500;
NoteCompositionContent.MAX_TOTAL_ATTACHMENT_SIZE_MB = 1000;

export default NoteCompositionContent;
