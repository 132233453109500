import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H3 } from 'components/common/headers';
import AgentPhonePreferences from 'models/agent_phone_preferences';
import AgentStatusButton from './agent_status_button';
import AgentStatusTooltip from './agent_status_tooltip';
import connect from 'components/lib/connect';
import CSSTransitionGroup from 'components/lib/css_transition_group';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import registerHotkey from 'components/hotkeys/register_hotkey';
import RoutableCounts from 'models/routable_counts';
import { RoutingChannel, RoutingPreferenceGroup } from 'models/agent_routing_preferences';
import Spinner from 'components/common/spinner';
import StationPreferenceCard from 'components/page_layout/agent_status/station_preference_card';
import StationPreferenceMenuContainer from 'components/page_layout/agent_status/station_preference_menu_container';
import UpdateAgentRoutingPreferences from 'actions/routing/update_agent_routing_preferences';
import Voice from './icons/voice';
import VoiceSelected from './icons/voice_selected';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

const ErrorTooltip = styled(H3)`
  color: ${p => p.theme.colors.red400};
`;

const StyledSpinnerWrapper = styled.div`
  display: inline-block;
  margin: 5px 0 -3px 10px;
`;

const ConnectingSpinner = () => (
  <StyledSpinnerWrapper style={{ width: '16px' }}>
    <Spinner stroke={1} />
  </StyledSpinnerWrapper>
);

export class VoiceStatusButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isErrorTooltipOpen: false, isStationMenuOpen: false };
    _.bindAll(this, [
      'handleClickAvailabilityIcon',
      'handleClickOutside',
      'handleHotKey',
      'handleOpenStationPreferenceMenu',
      'hasStation',
      'onSubmitStationPreference',
      'renderErrorTooltips',
      'renderStationPreferenceCard',
      'renderStationPreferenceMenu',
      'renderVoiceIcon',
      'toggleVoicePreference',
    ]);
  }

  handleHotKey() {
    if (this.props.isHotelingEnabled && !this.hasStation()) {
      this.handleClickAvailabilityIcon();
    } else {
      this.props.onHotKey(_.pick(this.props, 'isPreferred'));
    }
  }

  handleClickAvailabilityIcon(evt) {
    if (this.isVoiceButtonDisabled()) {
      this.setState({ isErrorTooltipOpen: !this.state.isErrorTooltipOpen });
      return;
    }

    if (this.props.isHotelingEnabled && !this.hasStation()) {
      this.setState({ isStationMenuOpen: !this.state.isStationMenuOpen });
    }
  }

  toggleVoicePreference() {
    if (this.isVoiceButtonDisabled()) {
      return;
    }

    if (!this.props.isHotelingEnabled) {
      this.props.onClick(_.pick(this.props, 'isPreferred'));
      return;
    }

    if (this.hasStation() && !this.state.isStationMenuOpen) {
      this.props.onClick(_.pick(this.props, 'isPreferred'));
    }
  }

  onSubmitStationPreference() {
    if (!this.props.isPreferred) {
      this.props.onClick(_.pick(this.props, 'isPreferred'));
    }
    this.setState({ isStationMenuOpen: false });
  }

  handleOpenStationPreferenceMenu() {
    this.setState({ isStationMenuOpen: true });
  }

  handleClickOutside() {
    this.setState({ isErrorTooltipOpen: false });
  }

  isVoiceButtonDisabled() {
    if (this.props.isPreferred) {
      return false;
    }
    return (
      this.props.isAgentCurrentlyOnCall ||
      this.props.isMicrophoneDisabled ||
      (this.props.isPhoneNumberMissingForDirectDial && !this.props.isHotelingEnabled) ||
      this.props.isUnsupportedVoicePreference ||
      this.props.isBrowserPhoneConnecting ||
      this.props.isBrowserPhoneUnavailable
    );
  }

  hasStation() {
    return this.props.isHotelingEnabled && this.props.hasPhone;
  }

  render() {
    if (this.props.isFocusOn) {
      return <StyledDiv>{this.props.isPreferred ? <VoiceSelected /> : <Voice />}</StyledDiv>;
    }

    return (
      <div className={classnames('voiceStatusButton', { disabled: this.props.isLoading })}>
        <AgentStatusTooltip
          excludeTooltip={
            this.props.isAgentCurrentlyOnCall ||
            this.props.isUnsupportedVoicePreference ||
            this.state.isErrorTooltipOpen ||
            this.state.isStationMenuOpen ||
            this.hasStation()
          }
          message="Voice"
          preferenceGroup={RoutingPreferenceGroup.VOICE}
        >
          {({ isHovered, handleHoverEnd }) => {
            return (
              <React.Fragment>
                <AgentStatusButton
                  disabled={this.props.isAgentCurrentlyOnCall || this.props.isUnsupportedVoicePreference}
                  onClick={this.toggleVoicePreference}
                  preferenceGroup={RoutingPreferenceGroup.VOICE}
                  routableCounts={this.props.routableCounts}
                  showTrueCount={isHovered}
                >
                  {this.renderVoiceIcon()}
                  {this.renderErrorTooltips()}
                  {this.renderStationPreferenceMenu(handleHoverEnd)}
                </AgentStatusButton>
                {isHovered && this.renderStationPreferenceCard()}
              </React.Fragment>
            );
          }}
        </AgentStatusTooltip>
      </div>
    );
  }

  renderStationPreferenceMenu(handleHoverEnd) {
    if (!this.props.isHotelingEnabled) {
      return null;
    }

    return this.state.isStationMenuOpen ? (
      <CSSTransitionGroup transitionEnterTimeout={75} transitionLeaveTimeout={75} transitionName="hoverTooltipFade">
        <OutsideClickHandler onClickOutside={this.handleClickOutside}>
          <StationPreferenceMenuContainer handleHoverEnd={handleHoverEnd} onSubmit={this.onSubmitStationPreference} />
        </OutsideClickHandler>
      </CSSTransitionGroup>
    ) : null;
  }

  renderStationPreferenceCard() {
    if (!this.hasStation() || this.state.isStationMenuOpen) {
      return null;
    }

    return (
      <CSSTransitionGroup transitionEnterTimeout={75} transitionLeaveTimeout={75} transitionName="hoverTooltipFade">
        <StationPreferenceCard openMenu={this.handleOpenStationPreferenceMenu} />
      </CSSTransitionGroup>
    );
  }

  renderVoiceIcon() {
    if (this.props.isPreferred) {
      return <VoiceSelected />;
    }
    return (
      <div className="voiceStatusButtonIcon" onClick={this.handleClickAvailabilityIcon}>
        <Voice />
      </div>
    );
  }

  renderErrorTooltips() {
    if (!this.state.isErrorTooltipOpen) {
      return null;
    }

    if (!this.isVoiceButtonDisabled()) {
      return null;
    }

    if (this.props.isMicrophoneDisabled) {
      return (
        <CSSTransitionGroup transitionEnterTimeout={75} transitionLeaveTimeout={75} transitionName="hoverTooltipFade">
          <OutsideClickHandler onClickOutside={this.handleClickOutside}>
            <MicrophoneDisabledTooltip />
          </OutsideClickHandler>
        </CSSTransitionGroup>
      );
    } else if (this.props.isPhoneNumberMissingForDirectDial) {
      return (
        <CSSTransitionGroup transitionEnterTimeout={75} transitionLeaveTimeout={75} transitionName="hoverTooltipFade">
          <OutsideClickHandler onClickOutside={this.handleClickOutside}>
            <PhoneNumberMissingTooltip />
          </OutsideClickHandler>
        </CSSTransitionGroup>
      );
    } else if (this.props.isBrowserPhoneConnecting || this.props.isBrowserPhoneUnavailable) {
      return (
        <CSSTransitionGroup transitionEnterTimeout={75} transitionLeaveTimeout={75} transitionName="hoverTooltipFade">
          <OutsideClickHandler onClickOutside={this.handleClickOutside}>
            <BrowserPhoneUnavailableTooltip isConnecting={this.props.isBrowserPhoneConnecting} />
          </OutsideClickHandler>
        </CSSTransitionGroup>
      );
    } else {
      return null;
    }
  }
}

export function MicrophoneDisabledTooltip() {
  return (
    <div className="agentStatus-ready-errorTooltip agentStatus-ready-microphoneDisabled-errorTooltip">
      <h1 className="agentStatus-ready-errorTooltip-heading">Unable to Accept Phone Calls</h1>
      <div className="agentStatus-ready-errorTooltip-overview">
        Microphone must not be blocked in order to receive phone calls.
        <br />
        Follow the steps below to unblock your microphone.
      </div>
      <ol className="agentStatus-ready-errorTooltip-list">
        <li className="agentStatus-ready-errorTooltip-list-item">
          From your Chrome browser address bar, click on the{' '}
          <div className="agentStatus-ready-errorTooltip-illustration-oneA" />
          symbol
          <div className="agentStatus-ready-errorTooltip-illustration-oneB" />
        </li>
        <li className="agentStatus-ready-errorTooltip-list-item">
          Select 'Always allow ...'
          <div className="agentStatus-ready-errorTooltip-illustration-two" />
        </li>
        <li className="agentStatus-ready-errorTooltip-list-item">
          Enable 'Voice'
          <div className="agentStatus-icon agentStatus-icon-inline agentStatus-voice-available" />
        </li>
      </ol>
    </div>
  );
}

export function PhoneNumberMissingTooltip() {
  return (
    <div className="agentStatus-ready-errorTooltip agentStatus-ready-noPhoneNumberSet-errorTooltip">
      <ErrorTooltip data-aid="agentStatus-ready-errorTooltip-heading">Unable to Set Ready</ErrorTooltip>
      <div className="agentStatus-ready-errorTooltip-overview agentStatus-ready-phoneError-errorTooltip-overview">
        You must set a phone number to receive phone calls
      </div>
    </div>
  );
}

export function BrowserPhoneUnavailableTooltip({ isConnecting }) {
  let message = isConnecting
    ? 'Your phone is connecting... Please wait'
    : 'Your phone is currently unavailable to take calls. Please try reloading the page.';

  let classNames = classnames('agentStatus-ready-errorTooltip', {
    'agentStatus-ready-browserPhoneConnecting-errorTooltip': isConnecting,
    'agentStatus-ready-browserPhoneUnavailable-errorTooltip': !isConnecting,
  });
  return (
    <div className={classNames}>
      <ErrorTooltip data-aid="agentStatus-ready-errorTooltip-heading">Voice Issues</ErrorTooltip>
      <div className="agentStatus-ready-errorTooltip-overview agentStatus-ready-phoneError-errorTooltip-overview">
        {message}
        {isConnecting ? <ConnectingSpinner /> : null}
      </div>
    </div>
  );
}

const VoiceStatusButtonWithShortcuts = withShortcuts(VoiceStatusButton, [
  registerHotkey({
    key: 'alt+2',
    callback: _.debounce(component => component.handleHotKey(), 300, { leading: true, trailing: false }),
    group: 'Availability',
    label: 'Toggle readiness for voice',
  }),
]);
export { VoiceStatusButtonWithShortcuts };

VoiceStatusButton.propTypes = VoiceStatusButtonWithShortcuts.propTypes = {
  hasPhone: PropTypes.bool,
  isAgentCurrentlyOnCall: PropTypes.bool,
  isBrowserPhoneConnecting: PropTypes.bool,
  isBrowserPhoneUnavailable: PropTypes.bool,
  isFocusOn: PropTypes.bool,
  isHotelingEnabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isMicrophoneDisabled: PropTypes.bool.isRequired,
  isPhoneNumberMissingForDirectDial: PropTypes.bool.isRequired,
  isPreferred: PropTypes.bool.isRequired,
  isUnsupportedVoicePreference: PropTypes.bool,
  onHotKey: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  routableCounts: PropTypes.instanceOf(RoutableCounts),
};

const VoiceStatusButtonContainer = connect(
  mapStateToProps,
  mapExecuteToProps,
  mergeProps
)(VoiceStatusButtonWithShortcuts);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  let activeCall = getProvider('activeCall').get();
  let preferencesProvider = getProvider('agentRoutingPreferences');
  let preferences = preferencesProvider.getPending() || preferencesProvider.get();
  let currentAgent = getProvider('currentAgent').getPending() || getProvider('currentAgent').get();
  let routableCounts = getProvider('routableCounts').get();

  let directDialEnabled = isFeatureEnabled('directDial');
  let webRtcEnabled = isFeatureEnabled('webRTC');
  let sipEnabled = isFeatureEnabled('sip');
  let microphoneEnabled = isFeatureEnabled('microphone');

  let isAgentCurrentlyOnCall = !!(activeCall && activeCall.isAgentOnLiveCall(currentAgent.id));
  let isPreferred = preferences.isPreferredOnChannel(RoutingChannel.VOICE);

  let hasPhone = currentAgent.hasPhoneNumber();
  let isMicrophoneDisabled = !(directDialEnabled && hasPhone) && webRtcEnabled && !microphoneEnabled;
  let isPhoneNumberMissingForDirectDial = directDialEnabled && !webRtcEnabled && !hasPhone;

  let voiceConfiguration = getProvider('voiceConfiguration').get();
  let isHotelingEnabled = voiceConfiguration && voiceConfiguration.hotelingEnabled;
  let connectionState = getProvider('connectionState').get();
  let agentVoicePreference = currentAgent.getVoiceConfigurationPreference();
  let isAgentConfiguredForBrowserCalls =
    agentVoicePreference === AgentPhonePreferences.NONE || agentVoicePreference === AgentPhonePreferences.BROWSER;
  let isBrowserPhoneConnecting =
    webRtcEnabled && isAgentConfiguredForBrowserCalls && connectionState.isBrowserPhoneConnecting();
  let isPhoneUnavailable = connectionState.isBrowserPhoneUnavailable();
  let isBrowserPhoneUnavailable = webRtcEnabled && isAgentConfiguredForBrowserCalls && isPhoneUnavailable;

  return {
    hasPhone,
    isAgentCurrentlyOnCall,
    isBrowserPhoneConnecting,
    isBrowserPhoneUnavailable,
    isFocusOn: preferences.isFocusOn,
    isHotelingEnabled,
    isLoading: preferences._version === 0,
    isMicrophoneDisabled,
    isPhoneNumberMissingForDirectDial,
    isPreferred,
    isUnsupportedVoicePreference: isUnsupportedVoicePreference(),
    routableCounts,
  };

  function isUnsupportedVoicePreference() {
    let currentAgentPhonePreference = currentAgent.getVoiceConfigurationPreference();

    switch (currentAgentPhonePreference) {
      case AgentPhonePreferences.BROWSER:
        return !webRtcEnabled;
      case AgentPhonePreferences.NONE:
        return !webRtcEnabled && !isHotelingEnabled;
      case AgentPhonePreferences.STATION:
        if (currentAgent.isConfiguredForSip()) {
          return !sipEnabled;
        }
        return true;
      case AgentPhonePreferences.DIRECT_DIAL:
        return !directDialEnabled;
      default:
        return true;
    }
  }
}

function mergeProps(stateProps, execProps) {
  let isHotKeyDisabled =
    stateProps.isAgentCurrentlyOnCall ||
    stateProps.isPhoneNumberMissingForDirectDial ||
    stateProps.isMicrophoneDisabled ||
    stateProps.isUnsupportedVoicePreference ||
    stateProps.isBrowserPhoneConnecting ||
    stateProps.isBrowserPhoneUnavailable;

  return {
    ...stateProps,
    ...execProps,
    onHotKey: ({ isPreferred }) => (isHotKeyDisabled ? null : execProps.onClick({ isPreferred })),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClick: ({ isPreferred }) =>
      executeAction(UpdateAgentRoutingPreferences, { channels: { [RoutingChannel.VOICE]: !isPreferred } }),
  };
}

export default VoiceStatusButtonContainer;

const StyledDiv = styled.div`
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
`;
