import createEnum from 'scripts/lib/create_enum';

import AgentMentionedInItem from './content/agent_mentioned_in_item';
import AgentMentionedInTaskComment from './content/agent_mentioned_in_task_comment';
import Basic from './content/basic';
import UnreadNotifications from './content/unread_notifications';

const ToastType = createEnum(
  'AGENT_MENTIONED_IN_ITEM',
  'AGENT_MENTIONED_IN_TASK_COMMENT',
  'BASIC',
  'UNREAD_NOTIFICATIONS'
);
export { ToastType };

const ToastContentClasses = [
  [ToastType.AGENT_MENTIONED_IN_ITEM, AgentMentionedInItem],
  [ToastType.AGENT_MENTIONED_IN_TASK_COMMENT, AgentMentionedInTaskComment],
  [ToastType.BASIC, Basic],
  [ToastType.UNREAD_NOTIFICATIONS, UnreadNotifications],
];
export default ToastContentClasses;
