import _ from 'lodash';

import createModel, { prop } from './lib/create_model';

export const UnreadConversation = createModel({
  modelName: 'UnreadConversation',

  properties: {
    conversationId: String.required,
    customerId: String.required,
    updatedAt: String.required,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const InboxCount = createModel({
  modelName: 'InboxCount',

  properties: {
    id: String,
    isTeam: Boolean,
    conversationIds: prop([String]).default([]),
    unreadConversationIds: prop([String]).default([]),
    oldestUnreadConversations: prop([UnreadConversation]).default([]), // limited to 5 oldest to reduce payload size

    newCount: prop(Number).default(0),
    openCount: prop(Number).default(0),
    openUnreadCount: Number,
    waitingCount: prop(Number).default(0),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },

  removeUnreadConversation(conversationId) {
    this.oldestUnreadConversations = _.filter(this.oldestUnreadConversations, c => c.conversationId !== conversationId);
  },
});

export const INBOX_COUNT_MAX = 999;

export default InboxCount;
