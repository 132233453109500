import React from 'react';
import styled from 'styled-components';
import Icon from 'components/lib/icons/icon';

export default props => (
  <StyledIcon viewBox="0 0 17 13" {...props}>
    <path
      d="M4.31385 1.08442L4.26136 1.03299L4.2564 1.03785C4.03657 0.872782 3.72066 0.888801 3.51948 1.08591L1.08987 3.46633C0.870042 3.6817 0.870042 4.03205 1.08987 4.24743C1.30884 4.46197 1.66376 4.46197 1.88273 4.24743L3.91669 2.25465L5.9506 4.24594C5.95061 4.24595 5.95062 4.24595 5.95062 4.24596C6.1696 4.46048 6.52449 4.46047 6.74346 4.24594C6.96329 4.03056 6.96329 3.68022 6.74346 3.46484L4.31385 1.08442Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.15"
    />
    <rect
      fill="currentColor"
      height="10.48"
      rx="0.491667"
      stroke="currentColor"
      strokeWidth="0.15"
      width="0.983334"
      x="3.425"
      y="1.75837"
    />
    <path d="M9 5L13.4545 5" stroke="currentColor" strokeLinecap="round" strokeWidth="1.2" />
    <path d="M9 2L16 2" stroke="currentColor" strokeLinecap="round" strokeWidth="1.2" />
  </StyledIcon>
);

const StyledIcon = styled(Icon)`
  min-width: 17px;
  min-height: 13px;
  stroke: ${p => p.theme.colors.gray800};
`;
