import moment from 'moment';
import { Factory } from 'rosie';

Factory.define('Composition').extend('Uuid');

Factory.define('CompositionWithDefaults')
  .extend('Composition')
  .attr('attachments', [])
  .attr('conversationId', 'i-am-a-conversation')
  .attr('content', () => Factory.attributes('EmailCompositionContent'))
  .attr('customerId', 'example-customer-id')
  .attr('snippetIds', [])
  .attr('updatedAt', () => moment().toISOString());

Factory.define('CompositionWithDefaultsAndAppliedSuggestedReply')
   .extend('CompositionWithDefaults')
   .attr('appliedSuggestedReply', () => Factory.attributes('SuggestedReplyWithDefaults'));
