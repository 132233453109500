import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Warning Stroke
 */

export default function WarningStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M8.9472 2.01246C8.76294 1.64394 8.23704 1.64394 8.05278 2.01246L2.17081 13.7764C2.00459 14.1088 2.24633 14.5 2.61802 14.5H14.382C14.7536 14.5 14.9954 14.1088 14.8292 13.7764L8.9472 2.01246ZM7.15835 1.56525C7.71114 0.459676 9.28885 0.459676 9.84163 1.56525L15.7236 13.3292C16.2223 14.3265 15.497 15.5 14.382 15.5H2.61802C1.50295 15.5 0.777707 14.3265 1.27638 13.3292L7.15835 1.56525ZM9 12.5C9 12.7761 8.77614 13 8.5 13C8.22386 13 8 12.7761 8 12.5C8 12.2239 8.22386 12 8.5 12C8.77614 12 9 12.2239 9 12.5ZM9 6C9 5.72386 8.77614 5.5 8.5 5.5C8.22386 5.5 8 5.72386 8 6V11C8 11.2761 8.22386 11.5 8.5 11.5C8.77614 11.5 9 11.2761 9 11V6Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
