import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'UserEditor',
  properties: {
    userId: String,
  },

  statics: {
    breadcrumb: 'User',
    create({ userId } = { userId: undefined }) {
      return new this({ userId });
    },
  },
});
