import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Notification from './notification';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import { DismissNotificationButton } from './dismiss_notification_button';
import PostponeOptionalUpgrade from 'actions/version/postpone_optional_upgrade';
import UpgradeSoftware from 'actions/version/upgrade_software';

export function OptionalUpgradeNotification({ notificationId, onPostpone, onReload }) {
  return (
    <Notification contentClasses="optionalUpgradeNotification" notificationId={notificationId}>
      <div className="conversationNotification-topic">
        <div className="optionalUpgradeNotification-icon" />
        <div className="optionalUpgradeNotification-content">
          A new version of Gladly is available. Reload to update.
        </div>
      </div>
      <div className="notification-buttonContainer">
        <ReloadButton buttonType={ButtonTypes.TEXT} data-aid="optionalUpgradeNotification-reload" onClick={onReload}>
          Reload Now
        </ReloadButton>
      </div>
      <DismissNotificationButton buttonText="Snooze" notificationId={notificationId} onClick={onPostpone} />
    </Notification>
  );
}

const ReloadButton = styled(Button)`
  color: ${p => p.theme.colors.white};
  &:hover {
    color: ${p => p.theme.colors.white};
    text-decoration: underline;
  }
`;
OptionalUpgradeNotification.propTypes = {
  notificationId: PropTypes.string.isRequired,
  onPostpone: PropTypes.func,
  onReload: PropTypes.func,
};

export default connect((context, props) => props, mapExecuteToProps)(OptionalUpgradeNotification);

function mapExecuteToProps(executeAction) {
  return {
    onPostpone: notificationId => executeAction(PostponeOptionalUpgrade, notificationId),
    onReload: () => executeAction(UpgradeSoftware),
  };
}
