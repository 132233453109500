import { after, HEADER_CORRELATION_ID } from 'scripts/infrastructure/backends/http_client';
import { DomainError, TooManyRequestsError } from 'scripts/application/lib/error';
import Err from 'models/err';
import HttpResponseHandler from 'scripts/adapters/gateways/lib/http_response_handler';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import mixin from 'scripts/lib/mixin';
import Observable from 'scripts/lib/observable_mixin';

export default class AuthenticationGateway {
  constructor(backend) {
    this.backend = backend;
  }

  get _httpClient() {
    return this.backend.axios();
  }

  login(credentials) {
    const correlationId = IdGenerator.newId();

    let handler = new LoginResponseHandler(this);

    after(
      this._httpClient.post('/api/v3/desktop_tokens', credentials, {
        headers: { [HEADER_CORRELATION_ID]: correlationId },
      }),
      handler.handleResponse.bind(handler, { requestName: 'login', correlationId, params: credentials })
    );

    return { correlationId };
  }

  logout(userId) {
    const correlationId = IdGenerator.newId();

    after(
      this._httpClient.delete(`/api/v3/desktop_tokens/${userId}`, {
        headers: { [HEADER_CORRELATION_ID]: correlationId },
      }),
      this.handleResponse.bind(this, { requestName: 'logout', correlationId, params: { userId } })
    );

    return { correlationId };
  }

  requestPasswordReset(username) {
    const correlationId = IdGenerator.newId();

    after(
      this._httpClient.post(
        '/api/v1/password_reset_tokens',
        { username },
        { headers: { [HEADER_CORRELATION_ID]: correlationId } }
      ),
      this.handleResponse.bind(this, { requestName: 'requestPasswordReset', correlationId, params: { username } })
    );

    return { correlationId };
  }

  requestTokenRefresh() {
    const correlationId = IdGenerator.newId();

    after(
      this._httpClient.post('/api/v3/refresh_tokens', {}, { headers: { [HEADER_CORRELATION_ID]: correlationId } }),
      this.handleResponse.bind(this, { requestName: 'requestTokenRefresh', correlationId })
    );

    return { correlationId };
  }
}

mixin(AuthenticationGateway.prototype, Observable);
mixin(AuthenticationGateway.prototype, HttpResponseHandler.prototype);

class LoginResponseHandler extends HttpResponseHandler {
  constructor(observable) {
    super();
    this.observable = observable;
  }

  notifyObservers(method, ...args) {
    this.observable.notifyObservers(method, ...args);
  }

  translateErrorResponse(res) {
    let err = super.translateErrorResponse(res);

    if (err instanceof TooManyRequestsError) {
      return new DomainError([{ code: Err.Code.INVALID, detail: 'Too many failed attempts. Please try again later.' }]);
    }
    return err;
  }
}
