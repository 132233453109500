import i18n from 'scripts/i18n/i18n';

// Load session ended item strings.
// While English is the only language it's easier to maintain the strings here, at
// the place where they are used.
// When languages other than English are added it would be best to relocate this
// bundle to the root with other i18n strings.
i18n.addResourceBundle('en', 'translation', {
  genericAgent: 'an agent',
  hour_one: 'hour',
  hour_other: 'hours',
  minute_one: 'minute',
  minute_other: 'minutes',
  sessionEndedItem: {
    AGENT_ENDED: {
      agent: '<agent>{{agent, upperFirst}}</agent> ended {{interaction}}',
      you: '<agent>You</agent> ended {{interaction}}',
    },
    CUSTOMER_NO_REPLY: 'Customer did not respond to {{interaction}} within {{count}} $t(minute)',
    STARTED_NEW:
      'The customer signed in from a new location. Please use your<br key={99} />best judgment before sharing out sensitive information.',
    TIME_EXPIRED: '{{interaction, upperFirst}} with customer ended because of inactivity for {{count}} $t(hour)',
  },
});
