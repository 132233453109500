import { faFontCase } from '@fortawesome/pro-solid-svg-icons/faFontCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { Emoji } from 'components/composer/shared/editor_action_buttons_new';
import { Item } from 'components/text_editor_new/components/controls/controls_new';
import FormatterContext from 'components/composer/shared/formatter_context';

export default function BelowEditorActions({ children, editorRef, positionRef }) {
  const { isFormattingVisible, toggleIsFormattingVisible } = useContext(FormatterContext);

  return (
    <StyledBelow data-aid="belowEditorActions">
      <Emoji
        editorRef={editorRef}
        key="editor-emoji-styles"
        menuMargin={16}
        menuPosition="left"
        positionRef={positionRef}
      />
      <FormattingToggle
        isFormattingVisible={isFormattingVisible}
        key="editor-rich-text-toggle"
        onClick={() => toggleIsFormattingVisible(!isFormattingVisible)}
      />
      {children}
    </StyledBelow>
  );
}

const StyledBelow = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 4px;
  margin-top: 4px;
`;

function FormattingToggle({ isFormattingVisible, onClick }) {
  const title = isFormattingVisible ? 'Hide formatting' : 'Show formatting';
  const preventBlurring = evt => evt.preventDefault();

  return (
    <FormattingToggleItem
      data-aid="formatting"
      isActive={isFormattingVisible}
      onClick={onClick}
      onMouseDown={preventBlurring}
      title={title}
    >
      <FontAwesomeIcon icon={faFontCase} />
    </FormattingToggleItem>
  );
}

const FormattingToggleItem = styled(Item)`
  font-size: 12px;
`;
