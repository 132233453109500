import isEmpty from 'lodash/isEmpty';

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CustomerProfileAvatar from 'components/customer/summary/customer_profile_avatar';
import connect from 'components/lib/connect';
import Tooltip from 'components/common/tooltip';
import LinkedIcon from 'components/lib/icons/customer_linked_icon';

export function CustomerInformationAvatar({ name, systemNames, showLinkedIcon }) {
  if (!showLinkedIcon) {
    return renderAvatar();
  }

  function buildTooltipMessage() {
    const externalSystems = systemNames && systemNames.length ? systemNames.join(', ') : 'one or more external systems';

    return (
      <StyledLinkedItemTooltip>
        {name} is linked to a customer in {externalSystems}.
      </StyledLinkedItemTooltip>
    );
  }

  return (
    <Tooltip bounds={{ left: 8 }} message={buildTooltipMessage()} position="bottom">
      <StyledAvatarContainer>
        {renderAvatar()}
        <StyledLinkedIconOffset />
      </StyledAvatarContainer>
    </Tooltip>
  );

  function renderAvatar() {
    return <CustomerProfileAvatar className="customerSummary-customerName-avatar" />;
  }
}

const StyledLinkedItemTooltip = styled.div`
  max-width: 280px;
`;

const StyledAvatarContainer = styled.div`
  position: relative;
  cursor: default;
`;

const StyledLinkedIcon = styled(LinkedIcon)`
  width: 16px;
  height: 16px;
  background: ${p => p.theme.colors.purple400};
  border-radius: 50%;
  padding: 3px;
  cursor: default;
`;

const StyledLinkedIconOffset = styled(StyledLinkedIcon)`
  position: absolute;
  top: 24px;
  left: 24px;
`;

CustomerInformationAvatar.propTypes = {
  name: PropTypes.string,
  systemNames: PropTypes.arrayOf(PropTypes.string),
  showLinkedIcon: PropTypes.bool,
  onCustomerUnlink: PropTypes.func,
};

export default connect(mapStateToProps)(CustomerInformationAvatar);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const profileProvider = getProvider('profile');
  if (profileProvider.isLoading()) {
    return { name: '', showLinkedIcon: false, systemNames: [] };
  }

  const profile = profileProvider.get();
  const integrations = getProvider('integrations').findAll() || [];
  const customerIsLinked = profile && !isEmpty(profile.externalCustomerIds);

  // Join profile.externalCustomerIds with the list of integrations to get names
  const integrationNames = [];
  if (customerIsLinked) {
    for (const integrationID in profile.externalCustomerIds) {
      const integration = integrations.find(item => item.id === integrationID);
      if (integration) integrationNames.push(integration.name);
    }
  }

  return {
    name: profile.name || '',
    showLinkedIcon: customerIsLinked,
    systemNames: integrationNames,
  };
}
