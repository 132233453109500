import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

/** @deprecated This spinner has been deprecated, use the [Spinner](http://go.glad.ly/style/#/Common%20Components/Spinner) instead */
const LoadingSpinner = ({ className, color, containerClassName, contextMessage, isModal, position, size, width }) => {
  const classNames = classnames('loadingSpinner', className, {
    'loadingSpinner-modal': isModal,
  });
  const containerClassNames = classnames(containerClassName, {
    'loadingSpinner-container': !isModal,
    'loadingSpinner-modal-container': isModal,
  });
  const containerStyle = position
    ? {
        position,
      }
    : undefined;
  const spinnerStyle = size
    ? {
        height: size,
        width: size,
      }
    : undefined;
  const layerStyle = color
    ? {
        borderColor: color,
      }
    : undefined;
  const circleStyle = width
    ? {
        borderWidth: width,
      }
    : undefined;

  return (
    <div className={containerClassNames} style={containerStyle}>
      <div className={classNames} style={spinnerStyle}>
        <div className="loadingSpinner-layer" style={layerStyle}>
          <div className="loadingSpinner-circle-clipper left">
            <div className="loadingSpinner-circle loadingSpinner-circle-left" style={circleStyle} />
          </div>
          <div className="loadingSpinner-gap-patch">
            <div className="loadingSpinner-gap-patch-circle" />
          </div>
          <div className="loadingSpinner-circle-clipper right">
            <div className="loadingSpinner-circle loadingSpinner-circle-right" style={circleStyle} />
          </div>
        </div>
      </div>
      {contextMessage && <div>{contextMessage}</div>}
    </div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  containerClassName: PropTypes.string,
  isModal: PropTypes.bool,
  position: PropTypes.string,
  size: PropTypes.string,
  contextMessage: PropTypes.string,
};

export default LoadingSpinner;
