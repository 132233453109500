import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  createLinkedReportNumberCell,
  createReportDateCellContent,
  getDatesForCell,
  ReportNumberCellContent,
  ReportDurationCellContent,
  ReportPercentCellContent,
} from './cell_content';
import Conversation from 'models/conversation';
import ReportChannel from 'models/reports/report_channel';
import ReportTable, { MIN_CELL_WIDTH } from './report_table';
import { SORT_PARAMETERS, getNextSortOrder } from './sort_control';
import searchContentTypeFromChannel from 'models/reports/search_content_type_from_channel';
import SummaryReport from 'models/reports/summary_report';

class SummaryTable extends Component {
  constructor(props) {
    super(props);

    this.report = props.report.init({ days: props.days });

    const initialSortMetric = 'date';
    const initialSort = SORT_PARAMETERS.ASC;
    this.state = {
      sortedRowOrder: sortByOrder(props.days, initialSortMetric, initialSort, this.report.getDataForDayAndMetric),
      currentSort: { [initialSortMetric]: initialSort },
    };

    this.changeTableSort = this.changeTableSort.bind(this);
  }

  /* render */
  render() {
    const { aggregationLevel, routingGroupIds, startDate, endDate } = this.props;
    const channel = searchContentTypeFromChannel(this.props.channel);
    const { timezone } = this.report;
    const reportStartDate = moment.tz(startDate, timezone);
    const reportEndDate = moment
      .tz(endDate, timezone)
      .add(1, 'd')
      .subtract(1, 'ms');
    const createdConversationsCell = createLinkedReportNumberCell({
      aggregationLevel,
      channel,
      routingGroupIds,
      hasCreatedFilter: true,
    });
    const closedConversationsCell = createLinkedReportNumberCell({
      aggregationLevel,
      channel,
      routingGroupIds,
      status: Conversation.InboxStatus.CLOSED,
      hasUpdatedFilter: true,
    });
    let cols = [
      {
        header: 'Date',
        ref: 'date',
        cellType: createReportDateCellContent(this.props.aggregationLevel),
      },
      {
        header: 'Created Conversations',
        ref: 'newConversationsByDay',
        cellType: createdConversationsCell,
      },
      {
        header: 'Closed Conversations',
        ref: 'closedConversationsByDay',
        cellType: closedConversationsCell,
      },
      {
        header: 'Avg. Close Time',
        ref: 'averageTimeToCloseByDay',
        cellType: ReportDurationCellContent,
      },
    ];

    if (this.props.channel === ReportChannel.PHONE_CALL) {
      cols = cols.concat([
        {
          header: 'Incoming Calls',
          ref: 'incomingCallsByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Avg. Incoming Talk Time',
          ref: 'averageIncomingTalkTimeByDay',
          cellType: ReportDurationCellContent,
        },
        {
          header: 'Outgoing Calls',
          ref: 'outgoingCallsByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Avg. Outgoing Talk Time',
          ref: 'averageOutgoingTalkTimeByDay',
          cellType: ReportDurationCellContent,
        },
        {
          header: 'Abandoned Calls',
          ref: 'abandonedCallsByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: '% Abandoned',
          ref: 'pctAbandonedCallsByDay',
          cellType: ReportPercentCellContent,
        },
      ]);

      cols = cols.concat([
        {
          header: 'Avg. Voice Active Handle Time',
          ref: 'voiceActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        },
        {
          header: 'Closed Voice Conversations',
          ref: 'closedVoiceConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
        {
          header: 'Avg. Voicemail Active Handle Time',
          ref: 'voicemailActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        },
        {
          header: 'Closed Voicemail Conversations',
          ref: 'closedVoicemailConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
      ]);
    } else if (this.props.channel === ReportChannel.EMAIL) {
      cols = cols.concat([
        {
          header: 'Incoming Emails',
          ref: 'incomingEmailsByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Outgoing Emails',
          ref: 'outgoingEmailsByDay',
          cellType: ReportNumberCellContent,
        },
      ]);
      cols = cols.concat([
        {
          header: 'Avg. Email Active Handle Time',
          ref: 'emailActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        },
        {
          header: 'Closed Email Conversations',
          ref: 'closedEmailConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
      ]);
    } else if (this.props.channel === ReportChannel.SMS) {
      cols = cols.concat([
        {
          header: 'Incoming SMS',
          ref: 'incomingSmsByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Outgoing SMS',
          ref: 'outgoingSmsByDay',
          cellType: ReportNumberCellContent,
        },
      ]);
      cols = cols.concat([
        {
          header: 'Avg. SMS Active Handle Time',
          ref: 'smsActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        },
        {
          header: 'Closed SMS Conversations',
          ref: 'closedSmsConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
      ]);
      if (_.isEmpty(this.props.routingGroupIds)) {
        cols = cols.concat([
          {
            header: 'Avg. Concurrent Messages',
            ref: 'avgConcurrentChatSessionsByTime',
            cellType: ReportNumberCellContent,
            width: MIN_CELL_WIDTH + 20,
          },
        ]);
      }
    } else if (this.props.channel === ReportChannel.FB_MESSENGER) {
      cols = cols.concat([
        {
          header: 'Incoming FB Messages',
          ref: 'incomingFBMessagesByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Outgoing FB Messages',
          ref: 'outgoingFBMessagesByDay',
          cellType: ReportNumberCellContent,
        },
      ]);
      cols = cols.concat([
        {
          header: 'Avg. FB Messenger Active Handle Time',
          ref: 'fbMessengerActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
        {
          header: 'Closed FB Messenger Conversations',
          ref: 'closedFbMessengerConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 110,
        },
      ]);
      if (_.isEmpty(this.props.routingGroupIds)) {
        cols = cols.concat([
          {
            header: 'Avg. Concurrent Messages',
            ref: 'avgConcurrentChatSessionsByTime',
            cellType: ReportNumberCellContent,
            width: MIN_CELL_WIDTH + 20,
          },
        ]);
      }
    } else if (this.props.channel === ReportChannel.CHAT_SESSION) {
      let chatCols = [
        {
          header: 'Chats',
          ref: 'chatSessionsByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Avg. Chat Time',
          ref: 'averageChatTimeByDay',
          cellType: ReportDurationCellContent,
        },
        {
          header: 'Avg. Concurrent Messages',
          ref: 'avgConcurrentChatSessionsByTime',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 20,
        },
        {
          header: 'Incoming Chats',
          ref: 'startedChatSessionsByTime',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Declined And Missed Chats',
          ref: 'declinedAndMissedChatsByTime',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Incoming Chat Messages',
          ref: 'incomingChatMessagesByTime',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Outgoing Chat Messages',
          ref: 'outgoingChatMessagesByTime',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Avg. Chat Message Reply Time',
          ref: 'avgChatReplyTimeByTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 40,
        },
      ];

      cols = cols.concat([
        {
          header: 'Avg. Chat Active Handle Time',
          ref: 'chatActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        },
        {
          header: 'Closed Chat Conversations',
          ref: 'closedChatConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
      ]);

      chatCols = chatCols.filter(
        col => !(!_.isEmpty(this.props.routingGroupIds) && col.ref === 'avgConcurrentChatSessionsByTime')
      );
      cols = cols.concat(chatCols);
    } else if (this.props.channel === ReportChannel.TWITTER) {
      cols = cols.concat([
        {
          header: 'Incoming Twitter',
          ref: 'incomingTwitterByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Outgoing Twitter',
          ref: 'outgoingTwitterByDay',
          cellType: ReportNumberCellContent,
        },
      ]);
      cols = cols.concat([
        {
          header: 'Avg. Twitter Active Handle Time',
          ref: 'twitterActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
        {
          header: 'Closed Twitter Conversations',
          ref: 'closedTwitterConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 110,
        },
      ]);
    } else if (this.props.channel === ReportChannel.WHATSAPP) {
      cols = cols.concat([
        {
          header: 'Incoming WhatsApp',
          ref: 'incomingWhatsappsByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Outgoing WhatsApp',
          ref: 'outgoingWhatsappsByDay',
          cellType: ReportNumberCellContent,
        },
      ]);
      cols = cols.concat([
        {
          header: 'Avg. WhatsApp Active Handle Time',
          ref: 'whatsappActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
        {
          header: 'Closed WhatsApp Conversations',
          ref: 'closedWhatsappConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 110,
        },
      ]);
    } else if (this.props.channel === ReportChannel.INSTAGRAM_DIRECT) {
      cols = cols.concat([
        {
          header: 'Incoming Instagram Messaging',
          ref: 'incomingInstagramDirectMessagesByDay',
          cellType: ReportNumberCellContent,
        },
        {
          header: 'Outgoing Instagram Messaging',
          ref: 'outgoingInstagramDirectMessagesByDay',
          cellType: ReportNumberCellContent,
        },
      ]);
      cols = cols.concat([
        {
          header: 'Avg. Instagram Messaging Active Handle Time',
          ref: 'instagramDirectActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 80,
        },
        {
          header: 'Closed Instagram Messaging Conversations',
          ref: 'closedInstagramDirectConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 110,
        },
      ]);
    }

    cols = cols.concat([
      {
        header: 'Avg. Wait Time',
        ref: 'averageResponseTimeByDay',
        cellType: ReportDurationCellContent,
      },
      {
        header: '% Within SLA',
        ref: 'pctWithinSLAByDay',
        cellType: ReportPercentCellContent,
      },
    ]);

    if (!this.props.channel) {
      if (_.isEmpty(this.props.routingGroupIds)) {
        cols.splice(-1, 0, {
          header: 'Avg. Handle Time for Closed Conversations',
          ref: 'averageHandleTimeByDay',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 110,
        });
        cols.push({
          header: '% Utilized',
          ref: 'pctUtilizedByDay',
          cellType: ReportPercentCellContent,
        });
      } else {
        cols.push({
          header: 'Avg. Handle Time for Closed Conversations',
          ref: 'averageHandleTimeByDay',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 110,
        });
      }

      if (this.props.isPhoneEnabled) {
        cols.push({
          header: 'Avg. Voice Active Handle Time',
          ref: 'voiceActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        });
        cols.push({
          header: 'Closed Voice Conversations',
          ref: 'closedVoiceConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
      }

      if (this.props.isChatEnabled) {
        cols.push({
          header: 'Avg. Chat Active Handle Time',
          ref: 'chatActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        });
        cols.push({
          header: 'Closed Chat Conversations',
          ref: 'closedChatConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
      }

      cols.push({
        header: 'Avg. Email Active Handle Time',
        ref: 'emailActiveHandleTime',
        cellType: ReportDurationCellContent,
        width: MIN_CELL_WIDTH + 60,
      });
      cols.push({
        header: 'Closed Email Conversations',
        ref: 'closedEmailConversations',
        cellType: ReportNumberCellContent,
        width: MIN_CELL_WIDTH + 80,
      });

      if (this.props.isFbMessengerEnabled) {
        cols.push({
          header: 'Avg. FB Messenger Active Handle Time',
          ref: 'fbMessengerActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
        cols.push({
          header: 'Closed FB Messenger Conversations',
          ref: 'closedFbMessengerConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 110,
        });
      }

      if (this.props.isSmsEnabled) {
        cols.push({
          header: 'Avg. SMS Active Handle Time',
          ref: 'smsActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        });
        cols.push({
          header: 'Closed SMS Conversations',
          ref: 'closedSmsConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
      }

      if (this.props.isPhoneEnabled) {
        cols.push({
          header: 'Avg. Voicemail Active Handle Time',
          ref: 'voicemailActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        });
        cols.push({
          header: 'Closed Voicemail Conversations',
          ref: 'closedVoicemailConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
      }

      if (this.props.isTwitterEnabled) {
        cols.push({
          header: 'Avg. Twitter Active Handle Time',
          ref: 'twitterActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        });
        cols.push({
          header: 'Closed Twitter Conversations',
          ref: 'closedTwitterConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
      }

      if (this.props.isWhatsappEnabled) {
        cols.push({
          header: 'Avg. WhatsApp Active Handle Time',
          ref: 'whatsappActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        });
        cols.push({
          header: 'Closed WhatsApp Conversations',
          ref: 'closedWhatsappConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
      }

      if (this.props.isInstagramDirectEnabled) {
        cols.push({
          header: 'Avg. Instagram Messaging Active Handle Time',
          ref: 'instagramDirectActiveHandleTime',
          cellType: ReportDurationCellContent,
          width: MIN_CELL_WIDTH + 60,
        });
        cols.push({
          header: 'Closed Instagram Messaging Conversations',
          ref: 'closedInstagramDirectConversations',
          cellType: ReportNumberCellContent,
          width: MIN_CELL_WIDTH + 80,
        });
      }
    }

    cols = cols.map(col => {
      col.renderCell = cellProps => {
        const date = this.state.sortedRowOrder[cellProps.rowIndex];
        const value = this.report.getDataForDayAndMetric(date, col.ref);
        const dates = getDatesForCell({
          minStartDate: reportStartDate,
          maxEndDate: reportEndDate,
          date: moment.tz(date, timezone),
          aggregationLevel,
        });
        return React.createElement(col.cellType, {
          value,
          date,
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      };
      return col;
    });

    return (
      <div className="reportTable-container">
        <ReportTable
          changeSort={this.changeTableSort}
          columns={cols}
          currentSort={this.state.currentSort}
          rowsCount={this.props.days.length}
          windowWidth={this.props.windowWidth}
        />
      </div>
    );
  }

  changeTableSort(metric) {
    let sort = getNextSortOrder(this.state.currentSort[metric]);
    let order = sortByOrder(this.state.sortedRowOrder, metric, sort, this.report.getDataForDayAndMetric);
    this.setState({
      sortedRowOrder: order,
      currentSort: { [metric]: sort },
    });
  }
}

SummaryTable.propTypes = {
  aggregationLevel: PropTypes.string,
  channel: PropTypes.string,
  routingGroupIds: PropTypes.arrayOf(PropTypes.string),

  days: PropTypes.arrayOf(PropTypes.string),
  endDate: PropTypes.string,
  startDate: PropTypes.string,

  isPhoneEnabled: PropTypes.bool,
  isSmsEnabled: PropTypes.bool,
  isFbMessengerEnabled: PropTypes.bool,
  isChatEnabled: PropTypes.bool,
  isTwitterEnabled: PropTypes.bool,
  isWhatsappEnabled: PropTypes.bool,
  isInstagramDirectEnabled: PropTypes.bool,

  report: PropTypes.instanceOf(SummaryReport).isRequired,

  windowWidth: PropTypes.number,
};

function sortByOrder(sortedRowOrder, metric, sort, getDataForDayAndMetric) {
  return _.orderBy(
    sortedRowOrder,
    date => {
      let val = metric === 'date' ? moment(date, ['YYYY-MM-DD']).valueOf() : getDataForDayAndMetric(date, metric);
      // Some values (durations) may be returned as null which should be treated as <0 for sorting
      return val !== null ? val : -1;
    },
    [sort.toLowerCase()]
  );
}

export default SummaryTable;
