import _ from 'lodash';
import createCollectionStoreClass from './lib/create_collection_store_class';
import createConverter from 'scripts/adapters/immutable_converters/lib/create_converter';
import ItemId from 'models/item_id';
import Observable from 'scripts/lib/observable_mixin';

const ItemIdsStore = createCollectionStoreClass({
  converter: createConverter(ItemId),
});

_.extend(ItemIdsStore.prototype, Observable);

export default ItemIdsStore;
