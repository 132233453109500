import map from 'lodash/map';

import qconsole from 'scripts/lib/qconsole';
import Ivr from 'models/ivr/ivr';

export default class IvrGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.ivrs;
  }

  onFetchAllSuccess(ivrsDto) {
    this.store.resetLoading();
    this.store.set(map(ivrsDto, ivrDto => Ivr.fromJs(ivrDto)));
  }

  onFetchAllError(error) {
    this.store.resetLoading();
    qconsole.log(`failed to fetch ivrs: ${error.message}`);
  }
}
