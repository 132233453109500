import { Factory } from 'rosie';

Factory.define('EmbeddedReport')
  .attr('expiringUrl')
  .attr('metricSet')
  .attr('supportedFilters')
  .attr('timezone');

Factory.define('EmbeddedReportWithDefaults')
  .extend('EmbeddedReport')
  .attr('expiringUrl', ['expiringUrl'], expiringUrl =>
    Object.assign(
      {
        url: 'report-url',
        expiry: '2030-10-01',
      },
      expiringUrl
    )
  )
  .attr('timezone', 'America/New_York');
