import createModel, { prop } from './lib/create_model';

const ConversationTopicChange = createModel({
  modelName: 'ConversationTopicChange',

  properties: {
    addedTopics: prop([String]).default([]),
    removedTopics: prop([String]).default([]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ConversationTopicChange;
