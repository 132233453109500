import { Factory } from 'rosie';

import '../conversation_item_factory';
import IdGenerator from '../lib/id_generator';

Factory.define('ConversationCustomAttributeChange').attr('type', 'CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE');

Factory.define('ConversationCustomAttributeChangeWithDefaults')
  .extend('ConversationCustomAttributeChange')
  .attr('added', () => [Factory.attributes('CustomAttributeValueWithDefaults', { id: 'addedId', value: 'addedValue' })])
  .attr('removed', () => [
    Factory.attributes('CustomAttributeValueWithDefaults', { id: 'removedId', value: 'removedValue' }),
  ]);

Factory.define('ConversationCustomAttributeChangeItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('ConversationCustomAttributeChange', content));

Factory.define('ConversationCustomAttributeChangeItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationCustomAttributeChangeWithDefaults', content);
  })
  .attr('initiator', {
    type: 'AGENT',
    id: IdGenerator.newId(),
  });

Factory.define('ConversationCustomAttributeChangeItemWithAPIInitiator')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ConversationCustomAttributeChangeWithDefaults', content);
  })
  .attr('initiator', {
    type: 'API',
  });
