import React from 'react';

export default function UnknownCustomerAvatar(props) {
  return (
    <svg
      height="35"
      id="unknown-customer-avatar"
      viewBox="0 0 55 55"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="27.5" cy="27.5" fill="#0F203C" r="27.5" />
      <circle cx="27.5" cy="22.25" fill="#fff" r="5.25" />
      <path
        d="M36.58,37.5a.49.49,0,0,1-.5.5H18.73a.48.48,0,0,1-.49-.5c.3-4.12,4.29-7.38,9.17-7.38S36.28,33.38,36.58,37.5Z"
        fill="#fff"
        transform="translate(0.09 0)"
      />
    </svg>
  );
}
