import CreateComposition from './lib/create_composition';
import NoteCompositionContent from 'models/composition/note_composition_content';

class CreateNoteComposition extends CreateComposition {
  constructor(context) {
    super(context, NoteCompositionContent);
  }
}

export default CreateNoteComposition;
