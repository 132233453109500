import PropTypes from 'prop-types';
import React from 'react';

import Conversation from 'models/conversation';
import ConversationTopicsContainer from 'components/customer/conversation_history/topics/conversation_topics_container';
import ConversationTopicsContainerV2 from 'components/customer/conversation_history/topics_v2/conversation_topics_container_v2';
import CustomerContext from 'components/customer/customer_context';

export function ConversationTopics({ isCustomAttributesEnabled }) {
  return (
    <CustomerContext.Consumer>
      {({ customerId, latestConversationId, latestConversationStatus }) => {
        if (!latestConversationId || latestConversationStatus === Conversation.Status.CLOSED) {
          return null;
        }

        if (isCustomAttributesEnabled) {
          return <ConversationTopicsContainerV2 conversationId={latestConversationId} customerId={customerId} />;
        }

        return <ConversationTopicsContainer conversationId={latestConversationId} customerId={customerId} />;
      }}
    </CustomerContext.Consumer>
  );
}

ConversationTopics.propTypes = {
  isCustomAttributesEnabled: PropTypes.bool.isRequired,
};

export default ConversationTopics;
