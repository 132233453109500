import classnames from 'classnames';
import React from 'react';

import IconContainer from './icon_container';

export default props => (
  <IconContainer className={classnames('circle-x-icon', props.className)} viewBox="0 0 51 51">
    <g className="circle-x-icon-path" fill="none" stroke="#000" strokeWidth="2">
      <circle cx="24" cy="24" r="23" />
      <path d="M16 16l16 16M16 32l16-16" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </IconContainer>
);
