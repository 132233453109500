import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import { EndpointTypes } from 'models/endpoint';
import FocusStatusButton from 'components/page_layout/agent_status/focus_status_button';
import { getAgentChannelProviders, getAgentChannels } from 'components/page_layout/lib/agent_channels';
import GetNextConversation from 'actions/conversation/get_next_conversation';
import MessagingStatusButton from './agent_status/messaging_status_button';
import NonInteractiveStatusButton from './agent_status/non_interactive_status_button';
import TaskStatusButton from './agent_status/task_status_button';
import VoiceStatusButton from './agent_status/voice_status_button';

export function AgentChannelStatusControls({
  channels,
  isMessagingEnabled,
  isNextButtonDisabled,
  isTaskUser,
  isVoiceEnabled,
  onNext,
}) {
  return <div className="agentStatus">{isTaskUser ? renderTaskControls() : renderChannelAvailabilityControls()}</div>;

  function renderTaskControls() {
    return (
      <React.Fragment>
        <TaskStatusButton />
        {renderNextButton()}
      </React.Fragment>
    );
  }

  function renderChannelAvailabilityControls() {
    let numPreferenceButtons = 1;
    if (isVoiceEnabled) numPreferenceButtons += 1;
    if (isMessagingEnabled) numPreferenceButtons += 1;

    return (
      <React.Fragment>
        <FocusStatusButton numPreferenceButtons={numPreferenceButtons} />
        <NonInteractiveStatusButton channels={channels} />
        {isVoiceEnabled ? <VoiceStatusButton /> : null}
        {isMessagingEnabled ? <MessagingStatusButton channels={channels} /> : null}
        {renderNextButton()}
      </React.Fragment>
    );
  }

  function renderNextButton() {
    return (
      <Button
        blurOnClick
        buttonType={ButtonTypes.PRIMARY}
        className="agentStatus-next-button"
        disabled={isNextButtonDisabled}
        onClick={onNext}
      >
        Next
      </Button>
    );
  }
}

AgentChannelStatusControls.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.oneOf(_.values(EndpointTypes))).isRequired,
  isMessagingEnabled: PropTypes.bool.isRequired,
  isNextButtonDisabled: PropTypes.bool.isRequired,
  isTaskUser: PropTypes.bool.isRequired,
  isVoiceEnabled: PropTypes.bool.isRequired,
  onNext: PropTypes.func,
};

export default connect(mapStateToProps, mapExecuteToProps)(AgentChannelStatusControls);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const agentChannelProviders = getAgentChannelProviders(getProvider);

  const channels = _.uniq(
    _.map(getAgentChannels(agentChannelProviders), channel =>
      channel.startsWith(`${EndpointTypes.CUSTOM_CHANNEL}:`) ? EndpointTypes.CUSTOM_CHANNEL : channel
    )
  );

  const isMessagingEnabled = !_(channels)
    .intersection([
      EndpointTypes.CHAT,
      EndpointTypes.CUSTOM_CHANNEL,
      EndpointTypes.FB_MESSENGER,
      EndpointTypes.INSTAGRAM_DIRECT,
      EndpointTypes.SMS,
      EndpointTypes.TWITTER,
      EndpointTypes.WHATSAPP,
    ])
    .isEmpty();
  const preferencesProvider = getProvider('agentRoutingPreferences');
  const preferences = preferencesProvider.getPending() || preferencesProvider.get();
  const isNextButtonDisabled = !_.some(preferences.channels) || preferences.isFocusOn;

  const channelConfiguration = getProvider('channelConfiguration').get();
  const isVoiceEnabled = channelConfiguration ? channelConfiguration.companyPhoneNumbers().length !== 0 : false;
  const isTaskUser = !isFeatureEnabled('externalAgentActions') && isFeatureEnabled('internalAgentActions');

  return {
    channels,
    isMessagingEnabled,
    isNextButtonDisabled,
    isTaskUser,
    isVoiceEnabled,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onNext: () => executeAction(GetNextConversation),
  };
}
