import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';

export const DEFAULT_AGENT_ASSISTANCE_CONFIG_CONTEXT = {
  disableHeroAI: false,
};

export function AgentAssistanceConfigContextProviderBase({ children, disableHeroAI }) {
  const value = useMemo(
    () => ({
      disableHeroAI,
    }),
    [disableHeroAI]
  );

  return <AgentAssistanceConfigContext.Provider value={value}>{children}</AgentAssistanceConfigContext.Provider>;
}

AgentAssistanceConfigContextProviderBase.propTypes = {
  children: PropTypes.any,
  disableHeroAI: PropTypes.bool,
};

function mapStateToProps({ getProvider }) {
  const agentAssistanceConfig = getProvider('agentAssistanceConfig').get();
  return {
    disableHeroAI: agentAssistanceConfig.isEnabled('disableHeroAI'),
  };
}

export const AgentAssistanceConfigContextProvider = connect(mapStateToProps)(AgentAssistanceConfigContextProviderBase);

const AgentAssistanceConfigContext = React.createContext(DEFAULT_AGENT_ASSISTANCE_CONFIG_CONTEXT);
export default AgentAssistanceConfigContext;
