import HtmlToText from 'html-to-text';
import { trim } from 'lodash';

export default function htmlToText(html) {
  let sourceHtml = trim(html);
  if (!sourceHtml) {
    return '';
  }

  const transformed = transformHtml(sourceHtml);
  return HtmlToText.fromString(transformed, { unorderedListItemPrefix: ' ', wordwrap: false });
}

/**
 * Takes an HTML string and transforms `<div><br/></div>` to `<div></div>` in order to avoid adding an
 * extra line break. Our current version of html-to-text adds a line break after `<div>` automatically
 * so adding extra `<br>` is no longer necessary. In the previous versions of html-to-text, we had to
 * add an extra break manually, hence the need for `<div><br/></div>`
 *
 * @param {string} html - source Html
 * @return {string}
 */
function transformHtml(html) {
  const parsedDocument = new DOMParser().parseFromString(html || '', 'text/html');

  for (const node of parsedDocument.body.childNodes) {
    if (node.nodeName === 'DIV' && node.childNodes.length === 1 && node.childNodes[0].nodeName === 'BR') {
      node.removeChild(node.childNodes[0]);
    }
  }

  return parsedDocument.body.innerHTML;
}
