import Assignee from './assignee';
import createModel from './lib/create_model';

var prop = createModel.prop;

var TaskRouting = createModel({
  modelName: 'TaskRouting',

  properties: {
    taskItemId: prop(String).isRequired,
    assignee: prop(Assignee).isRequired,
    assigneeWas: prop(Assignee),
    itemType: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default TaskRouting;
