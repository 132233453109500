import _ from 'lodash';

import { translateError, translateErrorResponse } from 'scripts/adapters/gateways/lib/http_response_handler';

export default class HttpAdapter {
  constructor(backend) {
    this.backend = backend;
  }

  get axios() {
    return this.backend.axios();
  }

  get(url, config, resource) {
    return this.axios
      .get(url, config)
      .then(onSuccess)
      .catch(error => onError(error, resource || url));
  }

  put(url, data, config, resource) {
    return this.axios
      .put(url, data, config)
      .then(onSuccess)
      .catch(error => onError(error, resource || url));
  }

  post(url, data, config, resource) {
    return this.axios
      .post(url, data, config)
      .then(onSuccess)
      .catch(error => onError(error, resource || url));
  }

  patch(url, data, config, resource) {
    return this.axios
      .patch(url, data, config)
      .then(onSuccess)
      .catch(error => onError(error, resource || url));
  }

  delete(url, config, resource) {
    return this.axios
      .delete(url, config)
      .then(onSuccess)
      .catch(error => onError(error, resource || url));
  }
}

// https://github.com/axios/axios#response-schema
function onSuccess({
  // `data` is the response that was provided by the server
  data,

  // `status` is the HTTP status code from the server response
  status,

  // `statusText` is the HTTP status message from the server response
  statusText,

  // `headers` the headers that the server responded with
  // All header names are lower cased
  headers,

  // `config` is the config that was provided to `axios` for the request
  config,

  // `request` is the XMLHttpRequest that generated this response
  request,
} = {}) {
  return data;
}

// https://github.com/axios/axios#handling-errors
export function onError(error, resource) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx (configurable via `validateStatus`)
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    let err = translateErrorResponse(error.response, resource);
    let correlationId = _.get(error.response, 'config.headers.Gladly-Correlation-Id');
    if (correlationId) {
      throw _.assign(err, { correlationId });
    }
    throw err;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    // console.log(error.request);
    throw translateError(error);
  }

  // Something happened in setting up the request that triggered an Error
  // console.log(error.message);
  // console.log(error.config);
  throw translateError(error);
}
