import _ from 'lodash';
import classnames from 'classnames';
import connect from 'components/lib/connect';
import Immutable from 'immutable';
import { MultiSelectMenu } from 'components/lib/conversation_multi_topic_deprecated';
import NavigateTo from 'actions/current_location/navigate_to';

export default connect(mapStateToProps, mapExecuteToProps)(MultiSelectMenu);

function mapStateToProps({ getProvider, isFeatureEnabled }, { className, dropDown, excludeDisabled, optionIds }) {
  return {
    className: classnames('multipleSelectMenu', className),
    dropDown,
    optionIds: getOptionIds(),
    onFocus: _.noop,
    options: getInboxOptions(),
    placeholder: 'All Inboxes',
    unitLabelSingular: 'inbox',
    unitLabelPlural: 'inboxes',
  };

  function getOptionIds() {
    return optionIds || [];
  }

  function getInboxOptions() {
    let inboxes = getProvider('routingGroups').findAll();
    if (excludeDisabled) {
      inboxes = _.filter(inboxes, inbox => !inbox.disabled);
    }
    let options = _.map(inboxes, inbox => ({
      id: inbox.id,
      name: inbox.disabled ? `${inbox.name} (Archived)` : inbox.name,
    }));
    return Immutable.fromJS(options);
  }
}

function mapExecuteToProps(executeAction, { currentLocation, optionIds, onApply }) {
  return {
    onOptionChange: handleOptionChange,
  };

  function handleOptionChange(updatedIds) {
    let currentIds = optionIds || [];
    if (updatedIds.length !== currentIds.length || _.difference(updatedIds, currentIds).length > 0) {
      if (onApply) {
        return onApply(updatedIds);
      } else {
        executeAction(NavigateTo, currentLocation.deriveNew({ routingGroupIds: updatedIds }));
      }
    }
  }
}
