import _ from 'lodash';

import AgentRead from 'models/agent_read';

import qconsole from 'scripts/lib/qconsole';

const throttlePerCustomerId = {};
const UPDATE_READ_INTERVAL = 10000; // Update agent read on backend only every 10 seconds

export default class UpdateAgentRead {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationId, agentReadAttrs }) {
    // Prefer to pass in customerId.. but there are occasionally circumstances where we don't have it available.
    if (!customerId) {
      customerId = this.context.stores.currentLocation.get().customerId;
      if (!customerId) {
        return;
      }
    }

    if (!this.context.stores.customers.has({ id: customerId })) {
      return;
    }

    const customerStores = this.context.stores.customers.storesFor(customerId);
    let conversation = customerStores.conversations.findBy({ id: conversationId });

    let personalInboxCounts = this.context.stores.personalInboxCounts.get();
    if (personalInboxCounts) {
      personalInboxCounts.removeUnreadConversation(conversationId);
      this.context.stores.personalInboxCounts.set(personalInboxCounts);
    }

    if (!conversation) {
      return;
    }

    const agentId = this.context.stores.currentAgent.get().id;

    if (!agentReadAttrs.readTo) {
      qconsole.warn(`Tried to update agent read without a timestamp`);
    }

    const existingRead = customerStores.agentRead.get();
    if (
      existingRead &&
      existingRead.conversationId === conversationId &&
      new Date(existingRead.readTo) >= new Date(agentReadAttrs.readTo)
    ) {
      return;
    }

    this.context.stores.agentRead.set(new AgentRead({ ...agentReadAttrs, conversationId, agentId }));
    let activeSession = this.context.stores.activeSessions.findBy({
      customer: { id: customerId },
    });
    if (activeSession) {
      activeSession.update({ readTo: agentReadAttrs.readTo });
      this.context.stores.activeSessions.replace(activeSession);
    }

    // We update agentRead a _lot_.. we don't need to be pounding the backend though with every update. Send updates only every 10 seconds.
    if (!throttlePerCustomerId[customerId]) {
      throttlePerCustomerId[customerId] = _.throttle(
        (conversationId, agentId, agentReadAttrs) => {
          this.context.gateways.agentRead.replace({ conversationId, agentId }, agentReadAttrs);
        },
        UPDATE_READ_INTERVAL,
        { leading: false }
      );
    }
    throttlePerCustomerId[customerId](conversationId, agentId, agentReadAttrs);
  }
}

export { throttlePerCustomerId };
