import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const PaymentStatusEventType = createEnum('COMPLETED', 'DECLINED', 'SUBMITTED', 'TIMED_OUT', 'CARD_VIEWED');
export const NotifiablePaymentStatusEventTypes = [PaymentStatusEventType.SUBMITTED, PaymentStatusEventType.DECLINED];

export default createModel({
  modelName: 'PaymentStatusEventContent',

  properties: {
    eventType: prop().oneOf(..._.keys(PaymentStatusEventType)).isRequired,
    secureDataItemId: prop(String).isRequired,
  },

  getMessageText() {
    return this.getText();
  },

  getText() {
    switch (this.eventType) {
      case 'DECLINED':
        return 'Card information request declined';
      case 'SUBMITTED':
        return 'Card information sent';
      case 'TIMED_OUT':
        return 'Card session timed out';
      case 'COMPLETED':
        return 'Payment completed';
    }
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
