import qconsole from 'scripts/lib/qconsole';
import * as Sentry from '@sentry/browser';

const ErrorReporter = {
  reportError(err, params = {}) {
    const { extra, tags, message } = params;

    // when an error occurs, log the last 30s of events sent to the console so
    // that fullstory and sentry get the logs
    qconsole.tail({ since: 30 });
    qconsole.error(err && err.stack ? err.stack : err);

    Sentry.withScope(function(scope) {
      tags && scope.setTags(tags);
      extra && scope.setExtras(extra);

      // @sentry/browser doesn' have a way to capture both message and error
      // so we append the message to the error name
      if (message) err.name = `${err.name}: ${message}`;
      Sentry.captureException(err);
    });
  },

  reportMessage(message, params = {}) {
    const { extra, tags } = params;

    qconsole.tail({ since: 30 });
    qconsole.error(message);

    Sentry.withScope(scope => {
      tags && scope.setTags(tags);
      extra && scope.setExtras(extra);
      Sentry.captureMessage(message);
    });
  },

  context(callback) {
    Sentry.wrap(callback);
  },
};

export default ErrorReporter;
