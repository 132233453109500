import ClearMergeProfiles from './clear_merge_profiles';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import NavigateToCustomerProfile from 'actions/customer/navigate_to_customer_profile';

export default class CompleteMergeCustomers {
  constructor(context) {
    this.context = context;
  }

  run(customerId) {
    this.context.executeAction(ClearMergeProfiles);

    let latestConversation = this.context.stores.customers.find(customerId).getLatestConversation();
    if (latestConversation) {
      this.context.executeAction(NavigateToConversation, { customerId, conversationId: latestConversation.id });
      return;
    }

    this.context.executeAction(NavigateToCustomerProfile, { customerId });
  }
}
