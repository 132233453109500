import _ from 'lodash';

export default class HandleRequestedAnswers {
  constructor(context) {
    this.context = context;
  }

  run({ answers }) {
    const answerPanel = this.context.stores.answerPanel.get();
    const loadedIds = [];

    _.forEach(answers, answer => {
      this.context.stores.snippets.addOrReplace(answer);
      loadedIds.push(answer.id);
    });

    const remainingIds = _.difference(answerPanel.loadingAnswerIds, loadedIds);
    answerPanel.setLoadingAnswerIds(remainingIds);
    this.context.stores.answerPanel.set(answerPanel);
  }
}
