import React from 'react';
import PropTypes from 'prop-types';

import Attachment from 'components/lib/attachment/attachment';
import { default as AttachmentModel } from 'models/attachment';
import connect from 'components/lib/connect';
import RemoveSnippetAttachment from 'actions/knowledge_base/remove_snippet_attachment';

const SnippetAttachmentContainer = connect(
  (context, props) => props,
  (executeAction, props) => {
    return {
      onRemove: () => {
        executeAction(RemoveSnippetAttachment, { attachmentId: props.attachment.id });
      },
    };
  }
)(SnippetAttachment);

SnippetAttachmentContainer.propTypes = {
  attachment: PropTypes.instanceOf(AttachmentModel).isRequired,
  onOpenAttachmentPreview: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
};

function SnippetAttachment({ attachment, isRemoveable, onOpenAttachmentPreview, onRemove, thumbnailUrl }) {
  return (
    <Attachment
      attachment={attachment}
      className="compositionAttachments-attachment"
      disableRemove
      onClick={onOpenAttachmentPreview}
      thumbnailUrl={thumbnailUrl}
    >
      {isRemoveable && (
        <span className="compositionAttachments-attachment-remove-container" onClick={onRemoveStopping}>
          <span className="compositionAttachments-attachment-remove">✕</span>
        </span>
      )}
    </Attachment>
  );

  function onRemoveStopping(evt) {
    evt.stopPropagation();
    onRemove();
  }
}

SnippetAttachment.propTypes = {
  ...SnippetAttachmentContainer.propTypes,
  onRemove: PropTypes.func.isRequired,
};

export { SnippetAttachment };
export default SnippetAttachmentContainer;
