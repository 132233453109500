import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import AttachmentsDropZone, { useDroppable } from './attachments_drop_zone';
import CloseCurrentComposition from 'actions/composition/close_current_composition';
import ComposerCancelModal from './composer_cancel_modal';
import ComposerContext from 'components/composer/contexts/composer_context';
import CompositionButtons, { CancelButton } from 'components/customer/composition/composition_buttons';
import CustomerContext from 'components/customer/customer_context';
import { ExecuteActionContext } from 'components/lib/connect';
import getExternalSerializer from 'components/text_editor/serializers/external_serializer';
import InsetContainer from 'components/common/containers/inset_container';
import normalize from 'components/text_editor/normalizers/rich_text';
import PlaintextSerializer from 'components/text_editor/serializers/plaintext_serializer';
import SendButton from 'components/customer/composition/send_button';
import SetComposerErrors from 'actions/composer/set_composer_errors';
import StackContainer from 'components/common/containers/stack_container';
import { StyledPlaceholder } from 'components/text_editor/plugins/placeholders';
import TrackGrammarlyUsage from 'actions/composer/track_grammarly_usage';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const StyledComposer = styled(InsetContainer)`
  flex-direction: column;
  max-height: 50vh;
  min-height: 25vh;
  overflow-x: hidden;
  position: relative;
  width: 100%;

  ${p => p.hasErrors && errorPlaceholder};
`;

const errorPlaceholder = css`
  ${StyledPlaceholder} {
    border-color: ${p => p.theme.colors.red400};
    color: ${p => p.theme.colors.red400};
  }
`;

export default function Composer({
  children,
  cancelText,
  disableSubmit,
  hideCancel,
  includeAttachments,
  isCancelling,
  onCancel,
  onSubmit,
  setIsCancelling,
  submitButton,
  submitText,
}) {
  const [dropProps, isDragActive] = useDroppable(includeAttachments);
  const { compositionErrors, isMinimized } = useContext(ComposerContext);

  return (
    <StyledComposer data-aid="composer" {...dropProps} hasErrors={!!compositionErrors.length}>
      {includeAttachments ? <AttachmentsDropZone isDragActive={isDragActive} /> : null}
      {children}
      <Errors errors={compositionErrors} />
      <Buttons
        cancelText={cancelText}
        disableSubmit={disableSubmit || isMinimized}
        hideCancel={hideCancel}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButton={submitButton}
        submitText={submitText}
      />

      {isCancelling ? (
        <ComposerCancelModal
          onCancel={() => {
            setIsCancelling(false);
          }}
        />
      ) : null}
    </StyledComposer>
  );
}

Composer.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.node,
  disableSubmit: PropTypes.bool,
  hideCancel: PropTypes.bool,
  includeAttachments: PropTypes.bool,
  isCancelling: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  setIsCancelling: PropTypes.func,
  submitButton: PropTypes.node,
  submitText: PropTypes.string,
};

const Error = styled.div`
  color: ${p => p.theme.colors.red400};
  flex-shrink: 0;
  text-align: right;
`;

export function Errors({ errors }) {
  if (!errors.length) {
    return null;
  }

  return <Error data-aid="composerError">{_.upperFirst(errors[0].detail)}</Error>;
}

Errors.propTypes = {
  errors: PropTypes.array,
};

const StyledHeader = styled(StackContainer)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: ${p => p.theme.spacing.medium};
`;

export function Header({ children, className }) {
  return (
    <StyledHeader className={className} inset="none">
      {children}
    </StyledHeader>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const HeaderText = styled.div.attrs({ 'data-aid': 'headerText' })`
  border-right: 2px solid ${p => p.theme.colors.gray200};
  color: ${p => (p.color ? p.theme.colors[p.color] : p.theme.colors.green400)};
  font-weight: ${p => p.theme.fontWeight.medium};
  margin-right: ${p => p.theme.spacing.medium};
  padding-right: ${p => p.theme.spacing.medium};
  text-transform: uppercase;
`;

export function Buttons({ cancelText, hideCancel, disableSubmit, onCancel, onSubmit, submitButton, submitText }) {
  const cancelButton = hideCancel ? null : <CancelButton label={cancelText} onClick={onCancel} />;
  return (
    <CompositionButtons>
      {cancelButton}
      {submitButton !== undefined ? (
        submitButton
      ) : (
        <SendButton disabled={disableSubmit} onClick={onSubmit}>
          {submitText}
        </SendButton>
      )}
    </CompositionButtons>
  );
}

Buttons.defaultProps = {
  submitText: 'Send',
};

Buttons.propTypes = {
  cancelText: PropTypes.string,
  hideCancel: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitButton: PropTypes.node,
  submitText: PropTypes.string,
};

const EXTERNAL_SERIALIZER = getExternalSerializer();

/**
 * Every editor in a composer should have an associated `useEditor` call that registers the editor with the composer.
 * Ensures that the editor's text content will autosave and submit correctly.
 *
 * @param {function} submitAction - Action that will submit this composer.
 * @param {object} options - Includes:
 *   `checkIsEmpty`: function, allows us to override the default check empty functionality.
 *   `createContent`: function, allows us to pass data besides just editor contents to the `submitAction`
 *   `trimEmptyParagraphs`: boolean, removes empty paragraphs before first line of text, and empty paragraphs after last line of text
 *   `trimParagraphWhitespace: boolean, removes empty whitespace at the beginning and end of each line of text
 *
 * @returns {[object, object]} the composer ref to register editors on and the props to be passed to the `Composer`
 */
export function useComposer(submitAction, options) {
  if (!options) {
    options = {};
  }
  const { checkIsEmpty, createContent, trimEmptyParagraphs, trimParagraphWhitespace } = options;

  const composerRef = useRef({
    attrs: {},
    editorRefs: {},
    editorValues: {},
    serializers: {},
    validators: {},
  });
  const [isCancelling, setIsCancelling] = useState(false);
  const { compositionId, customerId } = useContext(CustomerContext);
  const executeAction = useContext(ExecuteActionContext);
  const isFeatureEnabled = useIsFeatureEnabled();

  const onSubmit = useCallback(() => {
    // First validate each editor to ensure there are no errors such as unfilled placeholders.
    let errors = [];
    _.forEach(composerRef.current.editorValues, (editorValue, attr) => {
      const editorErrors = composerRef.current.validators[attr](editorValue);
      errors = errors.concat(editorErrors);
    });
    if (errors.length) {
      executeAction(SetComposerErrors, {
        compositionId,
        customerId,
        errors,
      });
      return;
    }

    // Normalizing editorValue will strip out things like phrase suggestions and transform inline links
    // into actual links.
    const normalizedEditorValues = _.mapValues(composerRef.current.editorValues, editorValue => {
      return normalize(editorValue, { trimEmptyParagraphs, trimParagraphWhitespace });
    });

    // Then format each editor (for example, turn inline links into actual links), and then serialize them.
    let content = {};
    _.forEach(normalizedEditorValues, (editorValue, attr) => {
      const serializer = composerRef.current.serializers[attr] || EXTERNAL_SERIALIZER;
      const finalAttr = composerRef.current.attrs[attr] || attr;
      content[finalAttr] = serializer.serialize(editorValue);
    });

    if (createContent) {
      _.forEach(composerRef.current.editorRefs, editorRef => {
        content = createContent(editorRef.current, content);
      });
    }

    executeAction(TrackGrammarlyUsage);
    executeAction(submitAction, {
      customerId,
      compositionId,
      content,
    });
  }, [composerRef, compositionId, createContent, executeAction, isFeatureEnabled, submitAction]);

  const onCancel = useCallback(() => {
    let isEmpty;
    if (checkIsEmpty) {
      isEmpty = checkIsEmpty(composerRef.current.editorValues);
    } else {
      const plaintexts = _.map(_.values(composerRef.current.editorValues), editorValue =>
        PlaintextSerializer.serialize(editorValue)
      );
      isEmpty = _(plaintexts)
        .map(plaintext => stripWhitespace(plaintext))
        .every(plaintext => plaintext.length === 0);
    }

    if (isEmpty) {
      executeAction(CloseCurrentComposition);
    } else {
      setIsCancelling(true);
    }
  }, [composerRef, executeAction, checkIsEmpty]);

  const props = { onCancel, onSubmit, isCancelling, setIsCancelling };

  return [props, composerRef];
}

function stripWhitespace(plaintext) {
  const lines = plaintext.split(/\r?\n/);
  return _.map(lines, line => line.trim()).join('');
}
