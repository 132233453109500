import AgentActivity from 'models/agent_activity';
import ApiTokens from 'models/location/api_tokens';
import changeCurrentLocation from 'actions/lib/change_current_location';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenApiTokens {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (!this.context.stores.appFeatures.get().isEnabled('devManagement')) {
      this.context.router.navigateHome();
      return;
    }

    changeCurrentLocation(this.context, ApiTokens.create());

    if (this.context.stores.apiTokens.count() === 0) {
      this.context.gateways.apiToken.request(this.currentAgentId);
      this.context.stores.apiTokens.setLoading();
    }

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.API_TOKENS });
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }
}
