import _ from 'lodash';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/lib/loading_spinner';

export default class SearchResults extends PureComponent {
  render() {
    const { isLoading, results } = this.props;
    if (!isLoading && (!results || !results.length)) {
      return null;
    }

    return (
      <div
        className={classnames(this.props.className, 'searchResults', {
          'searchResults-isLoading': isLoading,
        })}
      >
        {this.props.name && <div className="searchResults-header searchResults-header-font">{this.props.name}</div>}
        {this.renderHits()}
        {this.renderLoading()}
      </div>
    );
  }

  renderHits() {
    if (!this.props.results || !this.props.results.length) {
      return null;
    }
    return <div className="searchResults-hits">{this.props.results.map(this.props.renderRow)}</div>;
  }

  renderLoading() {
    if (!this.props.isLoading || _.get(this.props, 'results.length')) {
      return null;
    }

    return <LoadingSpinner className="searchResults-loadingSpinner" />;
  }
}

SearchResults.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  renderRow: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.object),
};
