import { Factory } from 'rosie';
import './lib/uuid';

import configureIdGenerator from './lib/configure_id_generator';

let IdGenerator = configureIdGenerator();

Factory.define('Topic').extend('Uuid');

Factory.define('TopicWithDefaults')
  .extend('Topic')
  .attr('name', 'Example Topic');

Factory.define('TopicWithAncestryDefaults')
  .extend('Topic')
  .attr('name', 'Example Topic')
  .attr('nameWithAncestry', 'Example Parent > Example Topic')
  .attr('parentId', () => IdGenerator.newId())
  .attr('children', () => [IdGenerator.newId(), IdGenerator.newId()]);
