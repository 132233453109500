import React, { useCallback } from 'react';

import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import CustomerProfile from 'models/customer_profile';
import createEnum from 'scripts/lib/create_enum';
import { isValidPhoneNumber, normalizePhoneNumber } from 'models/phone_number';
import Linkifier from 'components/lib/linkifier';
import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';
import RedactedItem from 'components/customer/conversation_history/conversation_items_v2/redacted_item';

const AnnotationType = createEnum('EMAIL', 'PHONE', 'REDACTED_CCN');

const InlineAnnotatedContent = createReactClass({
  propTypes: {
    canEmail: PropTypes.bool,
    hasExternalAgentActions: PropTypes.bool.isRequired,
    hasRedactedPaymentCard: PropTypes.bool,
    hasVoice: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isRedacted: PropTypes.bool,
    profile: PropTypes.instanceOf(CustomerProfile),
    profileHasErrors: PropTypes.bool,
    text: PropTypes.string,

    onCallPhone: PropTypes.func.isRequired,
    onSavePhone: PropTypes.func.isRequired,
    onSendEmail: PropTypes.func.isRequired,
    onSaveEmail: PropTypes.func.isRequired,
  },

  callPhone(value) {
    this.props.onCallPhone(value);
  },

  savePhone(value) {
    if (this.props.profileHasErrors) {
      return;
    }

    this.props.onSavePhone(value);
  },

  sendEmail(value) {
    if (this.props.canEmail) {
      this.props.onSendEmail(value);
    }
  },

  saveEmail(value) {
    if (!this.props.profile || this.props.profileHasErrors) {
      return;
    }

    this.props.onSaveEmail(value);
  },

  showCallPhone() {
    return this.props.hasVoice && this.props.hasExternalAgentActions;
  },

  showSavePhone(number) {
    return this.props.profile && !this.props.profile.hasPhoneNumber(number);
  },

  showSendEmail() {
    return this.props.canEmail && this.props.hasExternalAgentActions;
  },

  showSaveEmail(email) {
    return this.props.profile && !this.props.profile.hasEmail(email);
  },

  getAnnotatedText() {
    let text = this.props.text;
    if (!text || !text.length) {
      return '';
    }

    let cursor = 0;
    let textChunks = [];
    let ranges = getPhoneNumberRanges(text);
    ranges = collateRanges(ranges, getEmailRanges(text));

    if (this.props.hasRedactedPaymentCard) {
      ranges = collateRanges(ranges, getRedactedPaymentCardRanges(text));
    }

    _.forEach(ranges, (range, index) => {
      textChunks.push(
        <span key={`text-${this.props.id}-preceding-${index}`}>
          <Linkifier linkClassName="conversationItem-linkify">{text.slice(cursor, range.start)}</Linkifier>
        </span>
      );

      if (range.type === AnnotationType.PHONE) {
        textChunks.push(
          <PhoneMenu
            isSaveDisabled={this.props.profileHasErrors}
            key={`${range.value}-${index}`}
            onCallPhone={this.callPhone}
            onSavePhone={this.savePhone}
            phoneNumber={range.value}
            showCallPhone={this.showCallPhone()}
            showSavePhone={this.showSavePhone(range.value)}
            text={text.slice(range.start, range.end)}
          />
        );
      } else if (range.type === AnnotationType.REDACTED_CCN) {
        textChunks.push(
          <span className="redactedText" key={`text-${this.props.id}-redacted-${index}`}>
            {text.slice(range.start, range.end)}
          </span>
        );
      } else if (range.type === AnnotationType.EMAIL) {
        textChunks.push(
          <EmailMenu
            emailAddress={range.value}
            isSaveDisabled={this.props.profileHasErrors}
            key={`${range.value}-${index}`}
            onSaveEmail={this.saveEmail}
            onSendEmail={this.sendEmail}
            showSaveEmail={this.showSaveEmail(range.value)}
            showSendEmail={this.showSendEmail()}
            text={text.slice(range.start, range.end)}
          />
        );
      }
      cursor = range.end;
    });

    if (cursor < text.length) {
      textChunks.push(
        <span key={`text-${this.props.id}-trailing`}>
          <Linkifier linkClassName="conversationItem-linkify">{text.slice(cursor, text.length)}</Linkifier>
        </span>
      );
    }

    return textChunks;
  },

  render() {
    if (this.props.isRedacted) {
      return <RedactedItem />;
    }

    return <span data-aid="inlineAnnotatedContent">{this.getAnnotatedText()}</span>;
  },
});

export class AnnotationMenuItem extends React.PureComponent {
  render() {
    return (
      <div
        className={`inlineContactAnnotator-annotation-menu-item inlineContactAnnotator-annotation-menu-item-${this.props.type}`}
      >
        {this.props.children}
      </div>
    );
  }
}

AnnotationMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

export default InlineAnnotatedContent;

function getEmailRanges(text) {
  const EMAIL_REGEX = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  let ranges = [];
  let match = EMAIL_REGEX.exec(text);
  while (match !== null) {
    ranges.push({
      start: match.index,
      end: match.index + match[0].length,
      value: match[0],
      type: AnnotationType.EMAIL,
    });
    match = EMAIL_REGEX.exec(text);
  }
  return ranges;
}

// http://stackoverflow.com/questions/16631571/javascript-regular-expression-detect-all-the-phone-number-from-the-page-source
const PHONE_REGEX = /(?:\+?(\d{1,3}))?[-(]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\b/g;

function getPhoneNumberRanges(text) {
  let ranges = [];
  let match = PHONE_REGEX.exec(text);
  while (match !== null) {
    if (isValidPhoneNumber(match[0])) {
      const normalizedNumber = normalizePhoneNumber(match[0]);
      ranges.push({
        start: match.index,
        end: match.index + match[0].length,
        value: normalizedNumber,
        type: AnnotationType.PHONE,
      });
    }
    match = PHONE_REGEX.exec(text);
  }
  return ranges;
}

const REDACTION_REGEX = /\bXXXX XXXX XXXX \d{4}\b/g;

function getRedactedPaymentCardRanges(text) {
  let ranges = [];
  let match = REDACTION_REGEX.exec(text);
  while (match !== null) {
    ranges.push({ start: match.index, end: match.index + match[0].length, type: AnnotationType.REDACTED_CCN });
    match = REDACTION_REGEX.exec(text);
  }
  return ranges;
}

// Theoretically ranges could overlap, but in practice we assume they do not. Given the boundary conditions on the
// phone number and redacted payment card number regexes, they should not overlap. The result is sorted by the start
// index of the ranges.
function collateRanges(...sortedRanges) {
  return _.sortBy(_.union(...sortedRanges), 'start');
}

export function PhoneMenu({
  isSaveDisabled,
  phoneNumber,
  onCallPhone,
  onSavePhone,
  showCallPhone,
  showSavePhone,
  text,
}) {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();
  const onClickCallPhone = useCallback(() => {
    onCallPhone(phoneNumber);
    onClose();
  }, [onCallPhone, onClose, phoneNumber]);
  const onClickSavePhone = useCallback(() => {
    onSavePhone(phoneNumber);
    onClose();
  }, [onSavePhone, onClose, phoneNumber]);

  const highlightedText = (
    <span
      className="inlineContactAnnotator-annotation-content"
      data-aid="highlighted-phone-number"
      dir="auto"
      onClick={onToggle}
      ref={setTargetRef}
    >
      {text}
    </span>
  );

  if (!showCallPhone && !showSavePhone) {
    return highlightedText;
  }

  return (
    <React.Fragment>
      {highlightedText}
      <PopoverMenu
        data-aid="annotatedContentMenu"
        isOpen={isOpen}
        margin={5}
        onClose={onClose}
        position="top"
        targetRef={targetRef}
      >
        {showCallPhone && (
          <PopoverMenuItem data-aid="call-phone" key="call" onClick={onClickCallPhone}>
            <AnnotationMenuItem type="call" value={phoneNumber}>
              <i className="inlineContactAnnotator-annotation-menu-item-icon icon-phone-filled" />
              Call
            </AnnotationMenuItem>
          </PopoverMenuItem>
        )}
        {showSavePhone && (
          <PopoverMenuItem data-aid="save-phone" isDisabled={isSaveDisabled} key="save" onClick={onClickSavePhone}>
            <AnnotationMenuItem type="save" value={phoneNumber}>
              <i className="inlineContactAnnotator-annotation-menu-item-icon fa fa-save" />
              Save phone # to profile
            </AnnotationMenuItem>
          </PopoverMenuItem>
        )}
      </PopoverMenu>
    </React.Fragment>
  );
}

export function EmailMenu({
  emailAddress,
  isSaveDisabled,
  onSendEmail,
  onSaveEmail,
  showSendEmail,
  showSaveEmail,
  text,
}) {
  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();
  const onClickSendEmail = useCallback(() => {
    onSendEmail(emailAddress);
    onClose();
  }, [onSendEmail, onClose, emailAddress]);
  const onClickSaveEmail = useCallback(() => {
    onSaveEmail(emailAddress);
    onClose();
  }, [onSaveEmail, onClose, emailAddress]);

  const highlightedText = (
    <span
      className="inlineContactAnnotator-annotation-content"
      data-aid="highlighted-email-address"
      dir="auto"
      onClick={onToggle}
      ref={setTargetRef}
    >
      {text}
    </span>
  );

  if (!showSendEmail && !showSaveEmail) {
    return highlightedText;
  }

  return (
    <React.Fragment>
      {highlightedText}
      <PopoverMenu
        data-aid="annotatedContentMenu"
        isOpen={isOpen}
        margin={5}
        onClose={onClose}
        position="top"
        targetRef={targetRef}
      >
        {showSendEmail && (
          <PopoverMenuItem data-aid="send-email" key="email" onClick={onClickSendEmail}>
            <AnnotationMenuItem type="email" value={emailAddress}>
              <i className="inlineContactAnnotator-annotation-menu-item-icon icon-email" />
              Email
            </AnnotationMenuItem>
          </PopoverMenuItem>
        )}
        {showSaveEmail && (
          <PopoverMenuItem data-aid="save-email" isDisabled={isSaveDisabled} key="save" onClick={onClickSaveEmail}>
            <AnnotationMenuItem type="save" value={emailAddress}>
              <i className="inlineContactAnnotator-annotation-menu-item-icon fa fa-save" />
              Save email address to profile
            </AnnotationMenuItem>
          </PopoverMenuItem>
        )}
      </PopoverMenu>
    </React.Fragment>
  );
}
