import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import AgentPhonePreferences from 'models/agent_phone_preferences';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import { convertPhoneToDirectDialAddress } from 'models/station_configuration';

class StationPreferenceCard extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeStation = this.onChangeStation.bind(this);
  }

  onChangeStation() {
    this.props.openMenu();
  }

  render() {
    return (
      <div className="stationPreferenceCard">
        You are currently using
        <div className="stationPreferenceCard-stationName">{this.props.stationName}</div>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          className="stationPreferenceCard-button"
          onClick={this.onChangeStation}
        >
          Change Station
        </Button>
      </div>
    );
  }
}

StationPreferenceCard.propTypes = {
  openMenu: PropTypes.func,
  stationName: PropTypes.string,
};

const mapStateToProps = ({ getProvider }, props) => {
  const currentAgent = getProvider('currentAgent').get();
  const voiceConfiguration = currentAgent && currentAgent.voiceConfiguration;

  let stationAddress;
  if (voiceConfiguration && voiceConfiguration.preference === AgentPhonePreferences.DIRECT_DIAL) {
    let phone = voiceConfiguration.phone;
    stationAddress = convertPhoneToDirectDialAddress(phone);
  }

  let stationConfigurations = getProvider('stationConfigurations').findAll();
  let station = _.find(stationConfigurations, s => {
    return s.address === stationAddress;
  });
  let stationName = station && station.name;

  return { stationName, ...props };
};

export default connect(mapStateToProps)(StationPreferenceCard);
export { StationPreferenceCard };
