import { createPluginFactory } from '@udecode/plate';
import { Editor, Range, Transforms } from 'slate';

import { IdGenerator } from 'factories/lib/uuid';
import { StyledAITextCompletionHighlight } from './ai_text_completion_highlight_mark';

export const MARK_AI_TEXT_COMPLETION_HIGHLIGHT = 'ai-text-completion-highlight';

/**
 * This plugin can be used to highlight AI completion text that was recently inserted. It's
 * mad awkward if we implemented this using marks, as that ends up in the editor history.. e.g.
 * if you insert some AI completion text, then hit undo, you'll see the highlight again.
 *
 * To avoid this issue, we implement the highlighting using Slate's `decorate` functionality,
 * which doesn't affect the editor state or history.
 */
export default function AITextCompletionHighlight() {
  let selectionRef = null;

  return createPluginFactory({
    key: MARK_AI_TEXT_COMPLETION_HIGHLIGHT,
    isLeaf: true,
    decorate: () => ([node, path]) => {
      if (
        selectionRef &&
        selectionRef.current &&
        !Range.isCollapsed(selectionRef.current) &&
        Range.includes(selectionRef.current, path)
      ) {
        return [
          {
            [MARK_AI_TEXT_COMPLETION_HIGHLIGHT]: true,
            ...selectionRef.current,
          },
        ];
      }
    },
    withOverrides: withAITextCompletionHighlight,
  })();

  function withAITextCompletionHighlight(editor) {
    editor.highlightAITextCompletion = () => {
      selectionRef = Editor.rangeRef(editor, editor.selection, { affinity: 'outward' });
    };
    editor.removeAITextCompletionHighlight = () => {
      if (selectionRef) {
        const selection = selectionRef.unref();
        selectionRef = null;

        // Set a unique id on the first text node of the inserted selection in order to force
        // it to re-render with the update decorations to remove the highlight.
        const [, nodePath] = Editor.node(editor, selection);
        Transforms.setNodes(editor, { at: nodePath }, { rerenderId: IdGenerator.newId() });
      }
    };
    return editor;
  }
}

export { StyledAITextCompletionHighlight };
