import HitType from 'models/search/hit_types';
import Search from 'actions/search/search';
import SearchFilter from 'models/search/search_filter';
import SearchQuery from 'models/search/search_query';
import SearchResult from 'models/search/search_result';

export default class SearchCustomers {
  constructor(context) {
    this.context = context;
  }

  run({ conversationItemId, contentType, searchResultId, searchText }) {
    this.context.executeAction(
      Search,
      SearchResult.create({
        id: searchResultId,
        query: new SearchQuery({ text: searchText, filter: new SearchFilter({ type: HitType.CUSTOMER_PROFILE }) }),
      })
    );

    this.context.analytics.track('Customer Match - Searched', { conversationItemId, contentType });
  }
}
