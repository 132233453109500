import AttachmentPreview from 'models/modal/attachment_preview';

export default class OpenAttachmentPreview {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    this.context.stores.modal.set(AttachmentPreview.create(attrs));
  }
}
