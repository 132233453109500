'use strict';

import { Factory } from 'rosie';

import configureIdGenerator from './configure_id_generator';

export var IdGenerator = configureIdGenerator();

Factory.define('Uuid')
    .sequence('id', [], (incrementedId) => {
      return IdGenerator.newId();
    });
