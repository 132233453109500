import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import EditableFieldControlled from 'components/lib/editable_field_controlled';
import UpdateContact from 'actions/customer_profile/update_contact';

function CustomerContactField({ attr, isDisabled, isReadOnly, isResponsePending, label, onSubmit, value }) {
  return (
    <EditableFieldControlled
      className={`customerProfile-contactInput customerProfile-${attr}`}
      disabled={isDisabled}
      onSubmit={onSubmit}
      pending={isResponsePending}
      placeholder={getPlaceholder()}
      readOnly={isReadOnly}
      value={value || ''}
    />
  );

  function getPlaceholder() {
    return (
      label ||
      _.snakeCase(attr)
        .split('_')
        .map(_.capitalize)
        .join(' ')
    );
  }
}

CustomerContactField.propTypes = {
  attr: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  label: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export { CustomerContactField };

const CustomerContactFieldContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerContactField);

CustomerContactField.propTypes = {
  attr: PropTypes.string.isRequired,
  label: PropTypes.string,
};

function mapStateToProps({ getProvider }, { attr, label, isReadOnly }) {
  let profileProvider = getProvider('profile');

  return {
    attr,
    isDisabled: profileProvider.isLoading() || !_(profileProvider.getErrors()).isEmpty(),
    isReadOnly,
    isResponsePending: profileProvider.isPending(),
    label,
    value: profileProvider.get()[attr],
  };
}

function mapExecuteToProps(executeAction, { attr }) {
  return {
    onSubmit: value => executeAction(UpdateContact, { [attr]: value }),
  };
}

export default CustomerContactFieldContainer;
