import _ from 'lodash';

import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import ConversationMessage, { MessageStatus } from 'models/conversation_message';
import htmlToText from 'models//conversation_item/html_to_text';
import MessageContentTypeImage from 'models/message_content_type_image';
import MessageContentTypeFile from 'models/message_content_type_file';
import MessageContentTypeText from 'models/message_content_type_text';

export default function createConversationMessage(
  agentProfile,
  attachments,
  messageContent,
  conversationId,
  customerId,
  snippetIds,
  relatedSnippetIds
) {
  let content = getContent();
  let channel = messageContent.channel;
  let companyAddress = messageContent.companyAddress;
  let customerAddress = messageContent.customerAddress;

  return ConversationItem.create({
    conversationId,
    content: ConversationMessage.create({
      channel,
      companyAddress,
      content,
      customerAddress,
      status: { value: MessageStatus.NEW },
    }),
    customerId,
    initiator: {
      id: agentProfile.id,
      type: Communicator.AGENT,
    },
    snippetIds,
    relatedSnippetIds,
  });

  function getContent() {
    let body = messageContent.bodyHtml;
    if (_.size(attachments) < 1) {
      return MessageContentTypeText.create({
        body: htmlToText(body),
        bodyHtml: body,
      });
    }

    // Currently only support a single attachment per conversation message.
    let attachment = attachments[0];
    if (attachment.isImage()) {
      return MessageContentTypeImage.create({
        bodyHtml: body,
        caption: htmlToText(body),
        images: attachments,
      });
    }

    // Default to file type. No location support yet
    return MessageContentTypeFile.create({
      bodyHtml: body,
      files: attachments,
      text: htmlToText(body),
    });
  }
}
