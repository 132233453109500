import Upload from 'models/upload';
import UsersUpload from 'models/users_upload';

export default class UploadUsers {
  constructor(context) {
    this.context = context;
  }

  run({ file }) {
    let upload = Upload.create({ file });

    let errors = Upload.getValidationErrors({ file });
    let largeUploadErrors = UsersUpload.getValidationErrors(upload);
    errors = errors.concat(largeUploadErrors);

    if (errors.length) {
      this.context.stores.usersUpload.setErrors(errors);
      return;
    }

    this.context.stores.usersUpload.clearErrors();
    this.context.stores.usersUpload.set(UsersUpload.create({ file: upload.start() }));
    this.context.gateways.usersUpload.uploadFile(upload.fileDescriptor().filename, file);
  }
}
