import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import Toggle from 'components/common/toggle';
import ToggleExperimentalEditor from 'actions/agent_preferences/toggle_experimental_editor';
import useExecuteAction from 'components/hooks/use_execute_action';

export function AgentSlateBetaToggle({ isEnabled }) {
  const executeAction = useExecuteAction();

  const text = isEnabled ? 'Beta text editor is turned on' : 'Beta text editor is turned off';

  return (
    <Wrapper className="beta-feature-toggle">
      <Toggle
        data-aid="betaEditorToggle"
        isEnabled={isEnabled}
        onChange={() => executeAction(ToggleExperimentalEditor)}
      />
      <Text>{text}</Text>
    </Wrapper>
  );
}

AgentSlateBetaToggle.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
};

const AgentSlateBetaToggleContainer = connect(mapStateToProps)(AgentSlateBetaToggle);

function mapStateToProps({ getProvider }) {
  const preferences = getProvider('agentPreferences').get();

  return {
    isEnabled: preferences.experimentalEditorEnabled,
  };
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;

  .beta-feature-toggle + & {
    margin-top: 8px;
  }
`;

const Text = styled.span`
  margin-left: 8px;
`;

export default AgentSlateBetaToggleContainer;
