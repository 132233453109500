import _ from 'lodash';

import Attachment from 'models/attachment';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import Snippet from 'models/answers/snippet';

export default class AttachSnippetAttachments {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, item, contentChannelType, language }) {
    if (!(item instanceof Snippet)) {
      return;
    }

    const compositionsStore = getCompositionsStore(this.context);

    let composition = compositionsStore.find(compositionId);
    if (!composition.allowAttachments()) {
      return;
    }

    let content = item.findContentByLanguage(language);
    let attachments = content
      .getAttachmentsByType(contentChannelType)
      .filter(snippetAttachment => !composition.findAttachmentById(snippetAttachment.id))
      .map(snippetAttachment =>
        Attachment.create(
          _.assign({}, snippetAttachment, {
            source: {
              path: item.getUploadPath(snippetAttachment.id),
              type: Attachment.SourceType.SNIPPET,
            },
          })
        )
      );
    composition.addAttachments(attachments);
    compositionsStore.replace(composition);
  }
}
