import PropTypes from 'prop-types';
import React from 'react';

import CapitalizeFirstWord from 'components/text_editor/plugins/capitalize_first_word';
import CapitalizeI from 'components/text_editor/plugins/capitalize_i';
import Placeholders, { useFocusFirstPlaceholder } from 'components/text_editor/plugins/placeholders';
import TextEditor, { getEditorProps, useEditorRef, usePlugins } from 'components/text_editor';
import { useToggleAnswerPanel } from '../plugins/toggle_answer_panel';

export default function PlaintextEditor(props) {
  const { onChange } = props;
  const editorRef = useEditorRef(props.editorRef);
  useFocusFirstPlaceholder(editorRef.current, onChange);

  const toggleAnswerPanel = useToggleAnswerPanel();

  const plugins = usePlugins(() => [
    CapitalizeI(),
    CapitalizeFirstWord(),
    Placeholders(),
    toggleAnswerPanel,
    ...props.plugins,
  ]);

  const editorProps = getEditorProps(props);
  return <TextEditor {...editorProps} editorRef={editorRef} plugins={plugins} />;
}

PlaintextEditor.defaultProps = {
  plugins: [],
};

PlaintextEditor.propTypes = {
  editorRef: PropTypes.shape({ current: PropTypes.any }),
  onChange: PropTypes.func.isRequired,
  plugins: PropTypes.array,
};
