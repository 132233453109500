import { get, reduce } from 'lodash';

import analytics from 'scripts/lib/analytics';
import Err from 'models/err';
import { FormInputSectionTypes, FormSectionTypes } from 'models/external_action_form';

export default class PerformAction {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, action }) {
    const validationErrors = this.validateFormData(action);
    if (validationErrors.length > 0) {
      this.context.stores.externalActionForm.setErrors(validationErrors);
      return;
    }

    const performExternalActionAttrs = this.context.stores.performExternalAction.get().toJs();
    const integrationId = get(performExternalActionAttrs, 'action.integrationId');

    this.context.stores.performExternalAction.setLoading();
    this.context.gateways.externalCustomerLookupActions.performAction(customerId, integrationId, action);

    analytics.track('Action Performed', {
      actionName: get(performExternalActionAttrs, 'action.name'),
      customerId,
      conversationId: this.getCurrentConversationId(customerId),
      integrationId,
      integrationType: this.context.stores.integrations.findBy({ id: integrationId })?.type,
    });
  }

  validateFormData(action) {
    const sections = get(this.context.stores.externalActionForm.get(), 'sections', []);

    return reduce(
      sections,
      (errors, s) => {
        if (s.type === FormSectionTypes.input && s.input.type !== FormInputSectionTypes.checkbox && !s.input.optional) {
          if (!action[s.attr]) {
            errors.push(new Err({ attr: s.attr, code: Err.Code.BLANK, detail: 'Please enter a value.' }));
          }
        }
        return errors;
      },
      []
    );
  }

  getCurrentConversationId(customerId) {
    let currentLocation = this.context.stores.currentLocation.get();
    return currentLocation.customerId === customerId ? currentLocation.currentConversationId : null;
  }
}
