import ServerClock from 'scripts/application/lib/server_clock';

export default class ServerClockUpdater {
  constructor(context) {
    this.context = context;
  }

  handleUpdate(serverInfo) {
    if (serverInfo.clockSync) {
      ServerClock.updateOffset(serverInfo.clockSync, new Date().toISOString());
    }
  }
}
