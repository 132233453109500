import { getUploadAttachmentUrl } from 'scripts/domain/services/attachments';

export default class InsertInlineAttachmentCompositionImage {
  constructor(context) {
    this.context = context;
  }

  run({ upload, compositionId, insertImage, onChange }) {
    const src = getUploadAttachmentUrl(this.currentOrgId, this.currentAgentId, compositionId, upload);
    const fileName = upload.file?.name || '';
    onChange(insertImage({ src, attachmentId: upload.id, fileName }));
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }

  get currentOrgId() {
    return this.context.stores.auth.get().claims.orgId;
  }
}
