import { Factory } from 'rosie';

Factory.define('AnswerSuggestions')
  .extend('Id')
  .attr('version', '')
  .attr('suggestions', []);

Factory.define('AnswerSuggestionsWithDefaults')
  .extend('AnswerSuggestions')
  .attr('version', 'some-version');
