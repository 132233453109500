export default function debounceByAnimationFrame(callback, debounceMs) {
  let timestamp = null;
  let request = null;

  return function(...args) {
    cancelAnimationFrame(request);
    timestamp = performance.now();
    let onNextFrame = function(nextTimestamp) {
      nextTimestamp > timestamp + debounceMs
        ? callback.apply(null, args)
        : (request = requestAnimationFrame(onNextFrame));
    };
    request = requestAnimationFrame(onNextFrame);
    return request;
  };
}
