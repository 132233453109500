import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('TaskNotification');

Factory.define('TaskNotificationWithDefaults')
  .extend('TaskNotification')
  .attr('type', 'ASSIGNED_TASK')
  .attr('profile', () => Factory.attributes('CustomerProfileWithDefaults'))
  .attr('conversationId', 'conversation-id')
  .attr('conversationItem', () => Factory.attributes('TaskItemWithDefaults'))
  .attr('id', ['conversationItem'], conversationItem => conversationItem.id);

Factory.define('TaskNotificationNotDue')
  .extend('TaskNotificationWithDefaults')
  .attr('conversationItem', () => Factory.attributes('TaskItemNotDue'));
