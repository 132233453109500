import _ from 'lodash';
import React from 'react';

export function createErrorDisplayMixin(errorProp = 'errors') {
  return {
    getInitialState() {
      // Errors can either be passed in as props via errorProp, or errors can be set locally via
      // localErrors (for example, for local form validation vs. server side errors)
      return { showErrors: false, hasModified: {}, localErrors: [] };
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps[errorProp].length || this.state.localErrors.length) {
        this.setState({ showErrors: true });
      }
    },

    getErrorText(field, hasModifiedField) {
      if (!this.state.showErrors || this.state.hasModified[hasModifiedField || field]) {
        return null;
      }
      let error = _.find(this.props[errorProp].concat(this.state.localErrors), err => err.attr === field);
      if (!error) {
        return null;
      }
      return _.upperFirst(error.detail);
    },

    shouldDisplayErrors() {
      return this.state.showErrors && (!_.isEmpty(this.props[errorProp]) || !_.isEmpty(this.state.localErrors));
    },

    markAsModified(field) {
      this.setState({ hasModified: _.merge({}, this.state.hasModified, { [field]: true }) });
    },

    resetModified() {
      this.setState({ showErrors: false, hasModified: {} });
    },

    renderErrors(className) {
      if (!this.shouldDisplayErrors()) {
        return null;
      }
      let displayErrors = this.props[errorProp]
        .concat(this.state.localErrors)
        .filter(err => !!this.getErrorText(err.attr));

      if (!displayErrors.length) {
        return null;
      }

      return (
        <div className={className} key={`error-${displayErrors[0].attr}`}>
          {this.getErrorText(displayErrors[0].attr)}
        </div>
      );
    },
  };
}

export default createErrorDisplayMixin();
