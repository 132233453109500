import _ from 'lodash';
import { useCallback, useContext, useRef, useState } from 'react';

import CloseCurrentComposition from 'actions/composition/close_current_composition';
import CustomerContext from 'components/customer/customer_context';
import { ExecuteActionContext } from 'components/lib/connect';
import normalizeEditor from 'components/text_editor_new/lib/normalize_editor';
import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';
import SetComposerErrors from 'actions/composer/set_composer_errors';
import TrackGrammarlyUsage from 'actions/composer/track_grammarly_usage';

/**
 * Every editor in a composer should have an associated `useEditor` call that registers the editor with the composer.
 * Ensures that the editor's text content will autosave and submit correctly.
 *
 * @param {function} submitAction - Action that will submit this composer.
 *
 * @returns {[object, object]} the composer ref to register editors on and the props to be passed to the `Composer`
 */
export default function useComposer({ action, options = {} }) {
  const { createContent, trimEmptyParagraphs, trimParagraphWhitespace } = options;

  const composerRef = useRef({
    attrs: {},
    editorRefs: {},
    serializers: {},
    validators: {},
  });
  const [isCancelling, setIsCancelling] = useState(false);
  const { compositionId, customerId } = useContext(CustomerContext);
  const executeAction = useContext(ExecuteActionContext);

  const onSubmit = useCallback(
    evt => {
      evt.preventDefault();
      // First validate each editor to ensure there are no errors such as unfilled placeholders.
      let errors = [];
      _.forEach(composerRef.current.editorRefs, (editorRef, name) => {
        const editor = editorRef.current;
        const editorErrors = composerRef.current.validators[name](editor);
        errors = errors.concat(editorErrors);
      });
      if (errors.length) {
        executeAction(SetComposerErrors, {
          compositionId,
          customerId,
          errors,
        });
        return;
      }

      // Normalizing editorValue will strip out things like phrase suggestions and transform inline links
      // into actual links.
      _.forEach(composerRef.current.editorRefs, editorRef => {
        normalizeEditor(editorRef.current, { trimEmptyParagraphs, trimParagraphWhitespace });
      });

      // Finally, serialize each of the editors using the serializers configured via useEditor
      let content = {};
      _.forEach(composerRef.current.editorRefs, (editorRef, attr) => {
        const editor = editorRef.current;
        const serializer = composerRef.current.serializers[attr];
        const finalAttr = composerRef.current.attrs[attr] || attr;
        content[finalAttr] = serializer(editor);
      });

      if (createContent) {
        _.forEach(composerRef.current.editorRefs, (editorRef, attr) => {
          content = createContent(editorRef.current, attr, content);
        });
      }

      executeAction(TrackGrammarlyUsage);
      executeAction(action, {
        customerId,
        compositionId,
        content,
      });
    },
    [composerRef, compositionId, customerId, executeAction, action]
  );

  const onCancel = useCallback(() => {
    const plaintexts = _.map(composerRef.current.editorRefs, editorRef => serializePlaintext(editorRef.current));
    const isEmpty = _.every(plaintexts, plaintext => plaintext.length === 0);
    if (isEmpty) {
      executeAction(CloseCurrentComposition);
    } else {
      setIsCancelling(true);
    }
  }, [composerRef, executeAction]);

  return { composerRef, onCancel, onSubmit, isCancelling, setIsCancelling };
}
