import styled, { css } from 'styled-components';

const StyledNavLinkBase = css`
  color: ${p => p.theme.colors.black};
  padding: 6px 0;
  text-decoration: none;
  width: 100%;
`;

const StyledNavLink = styled.li`
  cursor: pointer;
  display: flex;
  line-height: ${p => p.theme.lineHeight.base};
  position: relative;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${p => p.theme.colors.green100};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    bottom: 0;
    margin: auto;
    transform: scaleX(0);
    background-color: ${p => p.theme.colors.green400};
    ${p => p.isActive && ` transform: scaleX(1); `};
  }

  ${p => p.isActive && ` transform: scaleX(1); background: ${p.theme.colors.gray300};`}

  a {
    text-decoration: none;
    color: ${p => p.theme.colors.black};
    font-weight: ${p => (p.isActive ? p.theme.fontWeight.heavy : p.theme.fontWeight.normal)};
  }
`;

export { StyledNavLinkBase, StyledNavLink };
