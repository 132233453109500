import _ from 'lodash';

export function descendants(topicsProvider, topicId, sort, sortFunc) {
  const topic = topicsProvider.findBy({ id: topicId });
  if (topic && topic.children && topic.children.length > 0) {
    const ret = _.concat(
      [topicId],
      ..._.map(sortBy(topic.children, sort, sortFunc), id => descendants(topicsProvider, id, sort, sortFunc))
    );
    return ret;
  }
  return [topicId];
}

export function sortBy(topicIds, sort, sortFunc) {
  if (!sortFunc) {
    return topicIds;
  }
  return _.orderBy(topicIds, id => sortFunc(id), [sort.toLowerCase()]);
}
