import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { usePlateEditorState } from '@udecode/plate';

import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';

export default function CharacterCount({ className, maxCharacters, maxCharactersNonAscii }) {
  const editor = usePlateEditorState();
  if (!editor || !editor.children.length) {
    return null;
  }

  const plaintext = serializePlaintext(editor);
  const characterCount = plaintext.length;
  const hasNonAscii = !/^[\u0000-\u007f]*$/.test(plaintext); // eslint-disable-line no-control-regex

  const max = hasNonAscii && maxCharactersNonAscii != null ? maxCharactersNonAscii : maxCharacters;

  let problemType = null;
  if (max) {
    let charactersRemaining = max - characterCount;
    if (charactersRemaining <= 10 && charactersRemaining >= 0) {
      problemType = 'warn';
    } else if (charactersRemaining < 0) {
      problemType = 'danger';
    }
  }

  return (
    <StyledCharacterCount $problemType={problemType} className={className}>
      <CharacterCountNum>{characterCount}</CharacterCountNum>
      <span>Chars</span>
    </StyledCharacterCount>
  );
}

CharacterCount.propTypes = {
  maxCharacters: PropTypes.number,
  maxCharactersNonAscii: PropTypes.number,
};

const StyledCharacterCount = styled.span.attrs({ 'data-aid': 'characterCount' })`
  bottom: 0;
  position: sticky;
  right: 4px;
  float: right;
  ${p => (p.$problemType === 'warn' ? `color: ${p.theme.colors.yellow500}` : '')}
  ${p => (p.$problemType === 'danger' ? `color: ${p.theme.colors.red400}` : '')}
  border-top-left-radius: 4px;
  background-color: ${p => p.theme.colors.white};
  padding: 4px 4px 0 4px;
  margin-left: 16px;
`;

const CharacterCountNum = styled.span`
  margin-right: 4px;
`;
