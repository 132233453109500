import _ from 'lodash';
import PropTypes from 'prop-types';
import scrollparent from 'scrollparent';
import React from 'react';

/**
 * This is component you can use with your React components if you want them to listen for parent scroll
 * events - they don't even have to know which parent that is! This is especially useful if you are using
 * position: fixed to position elements on the screen and need to reposition them after the parent scroll
 * position has changed.
 *
 * It's based off of the logic presented here:
 *  https://www.html5rocks.com/en/tutorials/speed/animations/#debouncing-scroll-events
 *
 * Props:
 *   One of these two is required:
 *    1. findScrollingParentOf()  Returns the DOM element to find the scrolling parent of
 *                                Doesn't necessarily have to exist after componentDidMount
 *    2. scrollingParent()        The scroll parent if we already know it
 *
 *    handleScroll()              Callback function to do your useful work related to scrolling
 */

export default class ScrollNotifier extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleScroll', 'afterAnimationFrame']);
  }

  componentDidMount() {
    this.initiateScrollParent();
    window.addEventListener('resize', this.handleScroll, false);
  }

  componentDidUpdate() {
    if (!this.scrollParent) {
      this.initiateScrollParent();
    }
  }

  componentWillUnmount() {
    this.request && cancelAnimationFrame(this.request);

    this._unlistenToScroll();
    window.removeEventListener('resize', this.handleScroll);
  }

  initiateScrollParent() {
    if (this.props.scrollingParent) {
      this.scrollParent = this.props.scrollingParent;
      this._listenToScroll();
    } else {
      const scrollChild = this.props.findScrollingParentOf;
      if (scrollChild) {
        this.scrollParent = scrollparent(scrollChild);
        this._listenToScroll();
      }
    }
  }

  _listenToScroll() {
    this.scrollParent && this.scrollParent.addEventListener('scroll', this.handleScroll, false);
  }

  _unlistenToScroll() {
    this.scrollParent && this.scrollParent.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.requestTick();
  }

  afterAnimationFrame() {
    this.props.onScroll();

    this.ticking = false;
  }

  requestTick() {
    if (!this.ticking) {
      this.request = requestAnimationFrame(this.afterAnimationFrame);
      this.ticking = true;
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollNotifier.propTypes = {
  children: PropTypes.node.isRequired,
  findScrollingParentOf: PropTypes.object,
  onScroll: PropTypes.func.isRequired,
  scrollingParent: PropTypes.object,
};
