import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from 'scripts/presentation/hoc_helpers/display_name';

export default function(ExistingField, NewFieldEditor) {
  class MultiValueField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        shouldFocusNewField: props.isAdding,
      };

      _.bindAll(this, ['handleNewValueBlur', 'handleNewValueCancel', 'handleNewValueSubmit', 'renderValue']);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.isAdding && !this.props.isAdding) {
        this.setState({ shouldFocusNewField: true });
      }

      if ((this.state.isAddPending && !this.isResponsePending(nextProps)) || this.hasAddErrors(nextProps)) {
        this.setState({ isAddPending: false });
      }
    }

    isResponsePending(props = this.props) {
      return props.isPending;
    }

    render() {
      return (
        <div className={this.props.className}>
          {this.renderExistingValues()}
          {this.shouldRenderNewValue() && this.renderNewValue()}
        </div>
      );
    }

    renderExistingValues() {
      return this.existingValues.map(this.renderValue);
    }

    get existingValues() {
      if (_.isEmpty(this.props.pendingValues)) {
        return this.props.values;
      }

      return this.shouldRenderNewValue() ? this.props.pendingValues.slice(0, -1) : this.props.pendingValues;
    }

    renderValue(value, index) {
      let errors = this.getValueErrors(index);
      let otherFieldHasError = !_.isEmpty(this.props.errors) && _.isEmpty(errors);

      return (
        <ExistingField
          errors={errors}
          forceDisplay={this.props.forceDisplay}
          isDisabled={this.props.isDisabled || otherFieldHasError}
          isResponsePending={this.isResponsePending()}
          key={`multiValueField-${index}`}
          onCancelEdit={this.handleCancelEdit.bind(this, index)}
          onSave={this.handleSave.bind(this, index)}
          value={value}
          {...this.getChildProps()}
        />
      );
    }

    shouldRenderNewValue() {
      return this.props.isAdding || _.isEmpty(this.props.values) || this.state.isAddPending || this.hasAddErrors();
    }

    renderNewValue() {
      return (
        <NewFieldEditor
          autoFocus={this.state.shouldFocusNewField}
          errors={this.getValueErrors(this.getNewValueIndex())}
          initialValue={this.props.newValue}
          isDisabled={this.state.isAddPending || this.props.isDisabled || this.isResponsePending()}
          onBlur={this.handleNewValueBlur}
          onCancel={this.handleNewValueCancel}
          onSubmit={this.handleNewValueSubmit}
          {...this.getChildProps()}
        />
      );
    }

    getChildProps() {
      return _.omit(this.props, _.keys(MultiValueField.propTypes));
    }

    handleCancelEdit(index) {
      this.cancel(index);
    }

    handleSave(index, value) {
      if (!this.props.isDisabled) {
        this.save(index, value);
      }
    }

    handleNewValueBlur(value) {
      if (this.state.isAddPending || this.props.isDisabled) {
        return;
      }

      if (!this.props.isEmptyValue(value)) {
        this.add(value);
        return;
      }

      if (this.hasAddErrors()) {
        this.cancelAdd();
        return;
      }

      this.setState({ shouldFocusNewField: false });

      if (this.props.onBlurNew) {
        this.props.onBlurNew();
      }
    }

    handleNewValueCancel() {
      if (this.state.isAddPending) {
        return;
      }

      this.cancelAdd();
    }

    handleNewValueSubmit(value) {
      if (this.state.isAddPending || this.props.isDisabled) {
        return;
      }

      this.add(value);
    }

    add(value) {
      this.setState({ isAddPending: true, shouldFocusNewField: false });
      this.save(this.getNewValueIndex(), value);
    }

    cancel(index) {
      if (this.props.onCancel) {
        this.props.onCancel(index);
      }
    }

    cancelAdd() {
      this.setState({ shouldFocusNewField: false });
      this.cancel(this.getNewValueIndex());
    }

    save(index, value) {
      if (this.props.onSave) {
        this.props.onSave(index, value);
      }
    }

    getValueErrors(index) {
      return this.props.errors && this.props.errors[index];
    }

    hasAddErrors(props = this.props) {
      return !_.isEmpty(props.errors && props.errors[this.getNewValueIndex(props)]);
    }

    getNewValueIndex(props = this.props) {
      return props.values.length;
    }
  }

  MultiValueField.propTypes = {
    className: PropTypes.string,
    errors: PropTypes.arrayOf(ExistingField.propTypes?.errors || PropTypes.any), // sparse array, one element per index in values prop
    forceDisplay: PropTypes.bool,
    isAdding: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isEmptyValue: PropTypes.func,
    isPending: PropTypes.bool,
    newValue: NewFieldEditor.propTypes?.initialValue || PropTypes.any,
    pendingValues: PropTypes.arrayOf(ExistingField.propTypes?.value || PropTypes.any).isRequired,
    values: PropTypes.arrayOf(ExistingField.propTypes?.value || PropTypes.any).isRequired,

    onBlurNew: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
  };

  MultiValueField.defaultProps = {
    isEmptyValue: _.isEmpty,
  };

  const existingName = getDisplayName(ExistingField);
  const newFieldName = getDisplayName(NewFieldEditor);
  MultiValueField.displayName = `MultiValueField(${existingName}, ${newFieldName})`;
  return MultiValueField;
}
