import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import MentionableAgent from './mentionable_agent';
import PopoverCard from 'components/common/popover_card';
import useWindowSize from 'components/hooks/use_window_size';

export default function AgentMentionList({
  autoPosition,
  dispatch,
  editorRef,
  height,
  itemsRef,
  mentionSearchRef,
  position,
  recentlyMentionedAgents,
  search,
  searchText,
  selectedIndex,
}) {
  const items = useMemo(() => {
    if (searchText) {
      return search.search(searchText).slice(0, 20);
    }
    return recentlyMentionedAgents;
  }, [search, searchText]);

  useResetSelectedIndexWhenItemsChange(items, itemsRef, dispatch);

  let content;
  if (items.length) {
    content = (
      <React.Fragment>
        {!searchText ? <RecentHeader>Recently mentioned agents</RecentHeader> : null}
        {_.map(items, (item, idx) => (
          <MentionableAgent
            {...item}
            dispatch={dispatch}
            editorRef={editorRef}
            index={idx}
            isSelected={selectedIndex === idx}
            key={item.id}
          />
        ))}
      </React.Fragment>
    );
  } else {
    content = <StartTyping>Type to search for an agent..</StartTyping>;
  }

  const bounds = useBoundByWindow();

  return (
    <StyledPopoverCard
      autoPosition={autoPosition}
      bounds={bounds}
      height={height}
      isVisible={!searchText || items.length > 0}
      position={position}
      targetPosition="start"
      targetRef={mentionSearchRef}
    >
      {content}
    </StyledPopoverCard>
  );
}

// When the search text changes such that the items shown change, we reset the selected index to 0,
// the assumption being that if the agent is still searching, they haven't found the agent they're looking
// for yet.
function useResetSelectedIndexWhenItemsChange(items, itemsRef, dispatch) {
  useEffect(() => {
    itemsRef.current = items;
    dispatch({ type: 'set', index: 0 });
  }, [items]);
}

function useBoundByWindow() {
  const { windowHeight, windowWidth } = useWindowSize();
  return {
    bottom: windowHeight - 8,
    left: 8,
    right: windowWidth - 8,
    top: 68,
  };
}

AgentMentionList.defaultProps = {
  position: 'top',
};

const StyledPopoverCard = styled(PopoverCard)`
  max-height: ${p => p.height || '320px'};
  overflow-y: auto;
  padding: 6px 0px;
  max-width: 500px;
`;

const StartTyping = styled.div`
  color: ${p => p.theme.colors.gray500};
  font-style: italic;
  padding: 8px 20px;
`;

const RecentHeader = styled.div`
  color: ${p => p.theme.colors.gray700};
  font-size: ${p => p.theme.fontSize.reduced};
  margin-bottom: 4px;
  padding: 0 20px;
`;
