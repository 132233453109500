import _ from 'lodash';

import { getAllReportConfigs } from 'scripts/application/actions/reporting/lib/reporting_helpers';
import { findReportConfigBySlug } from 'models/reports/report';
import { getChannelFilterValue } from 'models/reports/report_channel';
import ReportChannel from 'models/reports/report_channel';
import ScheduleRecurringReport from 'models/modal/schedule_recurring_report';

export default class ScheduleReport {
  constructor(context) {
    this.context = context;
  }

  run(params) {
    const oldReport = this.context.stores.currentLocation.get();
    const newReport = oldReport.deriveNew(params);

    const allReportConfigs = getAllReportConfigs(this.context);
    const scheduleReportParams = getScheduleReportParams(params, oldReport, allReportConfigs);

    this.context.stores.modal.set(ScheduleRecurringReport.create(scheduleReportParams));
    this.context.stores.currentLocation.set(newReport);
    this.context.analytics.track({ event: 'Schedule Report Modal Opened', props: scheduleReportParams });
  }
}

function getScheduleReportParams(params, report, reportConfigs) {
  const config = findReportConfigBySlug(reportConfigs, report.slug);
  const { filters, metricSetName, title } = config;

  return {
    name: title,
    metricSet: metricSetName,
    timezone: report.timezone,
    filters: {
      channel: _.includes(filters, 'channel') ? getChannel(params.channel) : undefined,
      inboxes: _.includes(filters, 'inboxes') ? params.routingGroupIds : undefined,
      teams: _.includes(filters, 'teams') ? params.teamIds : undefined,
    },
  };
}

function getChannel(channel) {
  return channel ? getChannelFilterValue(channel) : ReportChannel.ALL;
}
