import _ from 'lodash';

export const CONTEXT_SEARCH = 'iglu:com.gladly/context_search/jsonschema/1-0-0';

export const getSearchContext = searchResult => ({
  schema: CONTEXT_SEARCH,
  data: {
    id: searchResult.id,
    searchTerm: _.truncate(searchResult.query.text, { length: 255 }),
  },
});
