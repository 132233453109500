import _ from 'lodash';

import createLocationModel, { prop } from './lib/create_location_model';
import ServerClock from 'scripts/application/lib/server_clock';

export default createLocationModel({
  modelName: 'CustomerView',
  properties: {
    anchor: prop(String),
    anchorPosition: prop(String),
    isCompositionMinimized: prop(Boolean),
    compositionLastClosedAt: prop(String),
    customerId: prop(String).isRequired,
    currentCompositionId: prop(String),
    currentConversationId: prop(String),
    currentConversationItemId: prop(String),
    isCustomerExtensionPending: prop(Boolean),
    lastRoutedAt: prop(String),
  },

  changeCurrentComposition(compositionId) {
    this.currentCompositionId = compositionId;
  },

  changeCurrentConversation(conversationId) {
    this.currentConversationId = conversationId;
    this.updateRoutedAt();
  },

  changeCurrentConversationItem(conversationItemId) {
    this.currentConversationItemId = conversationItemId;
    this.updateRoutedAt();
  },

  clearCurrentComposition() {
    this.currentCompositionId = null;
  },

  clearCurrentConversation() {
    this.currentConversationId = null;
    this.currentConversationItemId = null;
  },

  clearCurrentConversationItem() {
    this.currentConversationItemId = null;
  },

  setCustomerExtensionPending() {
    this.isCustomerExtensionPending = true;
  },

  clearCustomerExtensionPending() {
    this.isCustomerExtensionPending = false;
  },

  toggleCompositionMinimized() {
    this.isCompositionMinimized = !this.isCompositionMinimized;
  },

  isInComposition(customerId, compositionId) {
    return this.customerId === customerId && this.currentCompositionId === compositionId;
  },

  setAnchor(anchor) {
    this.anchor = anchor;
  },

  setAnchorPosition(anchorPosition) {
    this.anchorPosition = anchorPosition;
  },

  clearAnchorPosition() {
    this.anchorPosition = undefined;
  },

  updateRoutedAt() {
    this.lastRoutedAt = ServerClock.toISOString();
  },

  setCompositionLastClosedAtToNow() {
    this.compositionLastClosedAt = ServerClock.toISOString();
  },

  statics: {
    breadcrumb: 'Conversation',
    create(attrs) {
      return new this(_.assign({ lastRoutedAt: ServerClock.toISOString() }, attrs));
    },
  },
});
