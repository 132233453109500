import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import KbAdmin from 'models/location/kb_admin';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenKbAdmin {
  constructor(context) {
    this.context = context;
  }

  run({ query, language, channel, audiences } = {}) {
    changeCurrentLocation(this.context, KbAdmin.create({ query, language, channel, audiences }));

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.KNOWLEDGE_BASE });
  }
}
