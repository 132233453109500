import { NEW, SENT, DELIVERED, UNDELIVERED } from 'models/conversation_item/status';

const SENDING = 'SENDING';
const DELIVERY_ERROR = 'DELIVERY_ERROR';

export default Object.freeze({
  NEW,
  SENDING,
  SENT,
  DELIVERED,
  UNDELIVERED,
  DELIVERY_ERROR,
});
