import { addCustomerToAccessHistory } from './customer_access_history';
import Customer from 'models/customer';
import requestCustomer from './request_customer';

export default function(context, customerId) {
  loadCustomerPlaceholder();
  addCustomerToAccessHistory(context, customerId);
  requestCustomer(context, customerId);

  function loadCustomerPlaceholder() {
    context.stores.customers.add(Customer.create({ id: customerId }));
  }
}
