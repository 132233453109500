import Immutable from 'immutable';
import ConversationPreview from 'models/conversation_preview';
import TaskPreview from 'models/task_preview';

export default {
  fromImmutable(immutable) {
    return immutable
      .toList()
      .map(i => {
        let js = i.toJS();
        return js.task ? TaskPreview.fromJs(js) : ConversationPreview.fromJs(js);
      })
      .toArray();
  },

  toImmutable(entities) {
    return Immutable.fromJS(entities.map(e => e.toJs()));
  },
};
