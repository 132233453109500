import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="m150 0 35.033 30.687 46.063-6.875 12.881 44.756 42.468 19.12-13.361 44.615 25.389 39.044-35.36 30.31.249 46.572-46.133 6.381-24.969 39.314L150 274.35l-42.26 19.574-24.969-39.314-46.133-6.381.25-46.572-35.361-30.31 25.389-39.044-13.36-44.615 42.467-19.12 12.88-44.756 46.064 6.875L150 0Z"
      fill="#2C96EA"
    />
    <path
      clipRule="evenodd"
      d="M209.291 115.68a2 2 0 0 1 .207 2.821l-68.511 79.386a2 2 0 0 1-2.739.274l-46.667-36.167a2 2 0 0 1-.356-2.806l13.477-17.389a2 2 0 0 1 2.806-.356l27.171 21.058a2 2 0 0 0 2.74-.274l52.396-60.713a2 2 0 0 1 2.821-.207l16.655 14.373Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </Icon>
);
