import TrackEvent from 'actions/analytics/track_event';

export default class TrackCustomerUpdate {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, field }) {
    this.context.executeAction(TrackEvent, {
      event: 'Customer Profile Changed',
      props: {
        customerId,
        field,
      },
    });
  }
}
