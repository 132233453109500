import { AgentAvailability } from 'models/agent_status';

export default class GetReadyAndBusyAgentStatuses {
  constructor(context) {
    this.context = context;
  }

  run() {
    const agentStatusesGateway = this.context.gateways.agentStatuses;
    agentStatusesGateway.requestAll({ availability: [AgentAvailability.READY, AgentAvailability.BUSY] });
  }
}
