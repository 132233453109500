import React from 'react';

import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 16 16" {...props}>
    <path
      d="M11 4L15.5 7.88909M15.5 7.88909L11 12M15.5 7.88909L0.999999 8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconContainer>
);
