import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ConversationTopicsMultiSelectV2 from 'components/customer/conversation_history/topics_v2/conversation_topics_multi_select_v2';
import registerHotkey from 'components/hotkeys/register_hotkey';
import { TopicsMenuOptionType } from 'components/customer/conversation_history/topics_v2/conversation_topics_constants_v2';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

export class ConversationTopicsBaseV2 extends PureComponent {
  render() {
    return (
      <ConversationTopicsMultiSelectV2
        {...this.props}
        label="Topics"
        onClose={this.props.onMenuClose}
        onOpen={this.props.onMenuOpen}
        onOptionChange={this.props.onTopicChange}
        onSearch={this.props.onTopicSearch}
        onSelect={this.props.onTopicSelect}
        optionIds={this.props.conversation.topicIds}
        options={this.props.topics.concat(...this.props.customAttributes)}
        placeholder="Add a Topic"
        ref={multiSelect => (this.multiSelect = multiSelect)}
        selectedCustomAttributes={this.props.conversation.customAttributes}
        suggestedIds={this.props.topicSuggestions && this.props.topicSuggestions.addTopics}
        suggestedLabel="Suggested Topics"
        unitLabelSingular="Topic"
        unselectedLabel="All Topics"
      />
    );
  }
}

ConversationTopicsBaseV2.propTypes = {
  className: PropTypes.string,
  dropDown: PropTypes.bool,
  conversation: PropTypes.object.isRequired,
  customAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf([TopicsMenuOptionType.CUSTOM_ATTRIBUTE]),
    })
  ),
  onFocus: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onTopicChange: PropTypes.func.isRequired,
  onTopicSearch: PropTypes.func,
  onTopicSelect: PropTypes.func,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      disabled: PropTypes.bool,
      type: PropTypes.oneOf([TopicsMenuOptionType.TOPIC]),
    })
  ),
  topicSuggestions: PropTypes.object,
};

export default withShortcuts(ConversationTopicsBaseV2, [
  registerHotkey({
    key: 'alt+t',
    callback: component => {
      component.multiSelect.handleMenuOpenerClick();
    },
    group: 'Conversation',
    label: 'Edit topics',
  }),
]);
