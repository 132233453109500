import { Editor, Transforms } from 'slate';

import { getAboveNode, isCollapsed } from '@udecode/plate';
import getPreviousSiblingNode from './get_previous_sibling_node';

export default function removeInlineOnBackspace(editor, evt, type) {
  const { selection } = editor;

  // If we're at the end of the inline and hit backspace, we want to delete the whole thing
  if (evt.key === 'Backspace' && isCollapsed(selection)) {
    const inlineNodes = [
      ...Editor.nodes(editor, {
        match: n => {
          return n.type === type;
        },
      }),
    ];
    let previousInlineNodePath;

    // Selections in between an inline and a text node may either be at the end of the inline or
    // at the beginning of the text node.

    // So if we're at the beginning of a node, check to see if we have a inline directly behind us
    if (selection.anchor.offset === 0) {
      const above = getAboveNode(editor);
      const previousNodePath = getPreviousSiblingNode(above, selection.anchor.path);
      previousInlineNodePath = (previousNodePath && previousNodePath[0].type === type && previousNodePath) || null;
    }
    // Otherwise we may be at the end of a inline node
    else {
      previousInlineNodePath = inlineNodes.length ? inlineNodes[0] : null;
    }

    if (previousInlineNodePath) {
      Transforms.removeNodes(editor, {
        at: previousInlineNodePath[1],
      });
      // Prevent default otherwise we end up backspacing twice
      evt.preventDefault();
      return;
    }
  }
}
