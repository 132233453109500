import AttachmentStatus from './attachment/attachment_status';
import createModel, { prop } from './lib/create_model';

const FbMessageIncomingAttachment = createModel({
  modelName: 'FbMessageIncomingAttachment',

  properties: {
    id: prop(String).isRequired,
    contentType: String,
    filename: String,
    fileSize: Number,
    isRedacted: Boolean,
    status: String,
    type: String,
  },

  isImage() {
    return this.contentType && this.contentType.startsWith('image/');
  },

  isAvailable() {
    return this.status === AttachmentStatus.AVAILABLE;
  },

  isSticker() {
    return this.type && this.type === FbMessageAttachmentType.STICKER;
  },

  fileDescriptor() {
    return {
      contentLength: this.fileSize,
      contentType: this.contentType,
      filename: this.filename,
    };
  },

  redact() {
    this.isRedacted = true;
  },
});

export const FbMessageAttachmentType = Object.freeze({
  AUDIO: 'audio',
  FILE: 'file',
  IMAGE: 'image',
  STICKER: 'sticker',
  VIDEO: 'video',
});

export default FbMessageIncomingAttachment;
