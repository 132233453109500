import _ from 'lodash';

import ExternalCustomerLookupGatewayObserver from 'actions/external_customer_lookup/external_customer_lookup_gateway_observer';
import { ExternalCustomerLookupResult } from 'models/external_customer_profile';
import Err from 'models/err';

export default class HandleCustomerSuggestions {
  constructor(context) {
    this.context = context;
  }

  get searchResultStore() {
    return this.context.stores.externalCustomerSearchResults;
  }

  run({ correlationId, suggestions, errors, resultsByIntegrationId, suggestionsByIntegrationId }) {
    const pendingResult = this.searchResultStore.getPending();
    const pendingSuggestionsResult = ExternalCustomerLookupGatewayObserver.getPendingSuggestionRequest(correlationId);

    if (pendingResult && pendingResult.id === correlationId) {
      if (errors) {
        this.searchResultStore.setErrors(_.map(errors.errors, Err.fromJs.bind(Err)));
      } else {
        this.searchResultStore.set(
          ExternalCustomerLookupResult.fromJs({
            ...suggestions,
            id: correlationId,
            resultsByIntegrationId: _.get(resultsByIntegrationId, 'results'),
            suggestionsByIntegrationId: _.get(suggestionsByIntegrationId, 'results'),
          })
        );
      }
      this.searchResultStore.resetPending();
    } else if (pendingSuggestionsResult) {
      ExternalCustomerLookupGatewayObserver.removePendingSuggestionRequest(correlationId);

      if (!errors && this.context.stores.customers.has({ id: pendingSuggestionsResult.customerId })) {
        this.context.stores.customers.storesFor(pendingSuggestionsResult.customerId).externalLookupSuggestions.set(
          ExternalCustomerLookupResult.fromJs({
            ...suggestions,
            id: correlationId,
            resultsByIntegrationId: _.get(resultsByIntegrationId, 'results'),
            suggestionsByIntegrationId: _.get(suggestionsByIntegrationId, 'results'),
          })
        );
      }
    }
  }
}
