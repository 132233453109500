import React from 'react';

import rgbToHex from 'components/common/lib/rgb_to_hex';

export const tableRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'table') {
        let firstChild = el.childNodes[0];
        let tagName = firstChild && firstChild.tagName && firstChild.tagName.toLowerCase();
        const childNodes = tagName === 'tbody' ? firstChild.childNodes : el.childNodes;
        return { object: 'block', type: 'table', nodes: next(childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'table') {
        return (
          <table>
            <tbody>{children}</tbody>
          </table>
        );
      }
    },
  },

  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'tr') {
        return { object: 'block', type: 'table_row', nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'table_row') {
        return <tr>{children}</tr>;
      }
    },
  },

  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'td' || el.tagName.toLowerCase() === 'th') {
        const textAlign = el.style.textAlign;
        const color = el.style.backgroundColor;
        let backgroundColor = color;
        // hex value is sometimes converted to an rgb when serializing
        if (color.startsWith('rgb')) {
          backgroundColor = rgbToHex(color).toUpperCase();
        }
        return {
          object: 'block',
          type: 'table_cell',
          nodes: next(el.childNodes),
          data: { textAlign, backgroundColor },
        };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'table_cell') {
        const textAlign = object.data.get('textAlign');
        const backgroundColor = object.data.get('backgroundColor');
        return <td style={{ textAlign, backgroundColor }}>{children}</td>;
      }
    },
  },
];
