import OpenFloorboard from 'actions/liveboards/open_floorboard';
import OpenLiveboard from './open_liveboard';

export default class CancelLiveboard {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.liveboard.remove();
    OpenLiveboard.stopPolling();
    OpenFloorboard.stopPolling();
    this.context.gateways.liveboard.cancelOutstandingRequest();
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }
}
