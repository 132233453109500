import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Link Out Stroke
 */

export default function LinkOutStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M9.14645 6.14645C8.95118 6.34171 8.95118 6.65829 9.14645 6.85356C9.34171 7.04882 9.65829 7.04882 9.85355 6.85356L13 3.70711L13 5.5C13 5.77614 13.2239 6 13.5 6C13.7761 6 14 5.77614 14 5.5L14 2.5C14 2.36739 13.9473 2.24022 13.8536 2.14645C13.7598 2.05268 13.6326 2 13.5 2H10.5C10.2239 2 10 2.22386 10 2.5C10 2.77614 10.2239 3 10.5 3L12.2929 3L9.14645 6.14645ZM4.5 3C3.11929 3 2 4.11929 2 5.5V11.5C2 12.8807 3.11929 14 4.5 14H10.5C11.8807 14 13 12.8807 13 11.5V8.5C13 8.22386 12.7761 8 12.5 8C12.2239 8 12 8.22386 12 8.5V11.5C12 12.3284 11.3284 13 10.5 13H4.5C3.67157 13 3 12.3284 3 11.5V5.5C3 4.67157 3.67157 4 4.5 4H7.5C7.77614 4 8 3.77614 8 3.5C8 3.22386 7.77614 3 7.5 3H4.5Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
