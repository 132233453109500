import React, { useContext } from 'react';

import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import { AITextCompletionContextProvider } from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import withRefreshEditorWhenFlagsChange from 'components/composer/shared/with_refresh_editor_when_flags_change';
import withStaticId from 'components/customer/lib/with_static_id';

export default function withAITextRewritingFeatures(Component) {
  function ComponentWithAIFeatures(props) {
    const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);

    const includeAIFeatures = !agentAssistanceConfig.disableHeroAI;

    let composer = <Component {...props} includeAIFeatures={includeAIFeatures} />;

    if (includeAIFeatures) {
      return (
        <AITextCompletionContextProvider requestorId={props.requestorId}>{composer}</AITextCompletionContextProvider>
      );
    }
    return composer;
  }
  ComponentWithAIFeatures.displayName = `withAITextRewritingFeatures(${Component.displayName || Component.name})`;

  return withStaticId(withRefreshEditorWhenFlagsChange(ComponentWithAIFeatures), 'requestorId');
}
