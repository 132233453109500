import React from 'react';
import PropTypes from 'prop-types';

import { FormSectionTypes } from 'models/external_action_form';
import TextSection from './text_section';
import InputSection from './input_section';

export default function ExternalFormSection({ error, section = {}, value, updateState }) {
  switch (section.type) {
    case FormSectionTypes.text:
      return <TextSection section={section} />;
    case FormSectionTypes.input:
      return <InputSection error={error} section={section} updateState={updateState} value={value} />;
    default:
      return null;
  }
}

ExternalFormSection.propTypes = {
  error: PropTypes.string,
  section: PropTypes.object.isRequired,
  value: PropTypes.any,
  updateState: PropTypes.func.isRequired,
};
