import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0)" clipRule="evenodd" fillRule="evenodd">
      <path d="M4.293 3c-1.25-.009-2.17.377-2.838.992C.27 5.086-.178 7.043.063 9.094c.242 2.05 1.17 4.159 2.534 5.479 1.356 1.311 3.076 1.885 5.197 1.005.159-.05.266-.075.405-.006 2.127.888 3.852.315 5.21-1 1.364-1.32 2.286-3.428 2.527-5.478.242-2.05-.199-4.008-1.385-5.102-1.18-1.088-3.159-1.458-6.332-.189a.599.599 0 01-.425 0c-1.38-.553-2.54-.796-3.5-.803zM3.93 6.215a.675.675 0 00-.299-.902.664.664 0 00-.894.3A6.421 6.421 0 002.085 8.9c.093 1.414.653 3.055 2.061 4.83.23.29.65.338.937.105a.676.676 0 00.104-.945C3.93 11.304 3.488 9.92 3.415 8.811a5.067 5.067 0 01.515-2.596z" />
      <path d="M4.111.073A.098.098 0 014.216.01c1.462.2 2.312.51 2.86.907.624.452.877 1.065.923 1.823v.066l.001.07c0 .064-.06.111-.122.098C6.4 2.65 5.35 2.28 4.733 1.844c-.65-.459-.897-1.01-.622-1.771z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h16v16H0z" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
