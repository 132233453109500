import StandardGateway from './lib/standard_gateway';

export default class AgentPresenceGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/agents/:agentId/presence');
  }

  set(presence) {
    return super.replace(presence);
  }
}
