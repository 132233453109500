import _ from 'lodash';
import createContext from 'create-react-context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const DEFAULT_CONTEXT = { onHeightChange: undefined };
export const HeightChangeContext = createContext(DEFAULT_CONTEXT);

/**
 * ListItem's purpose is to position an item correctly in the list via the style props passed in.
 * It also manages height changes for its children.
 */
export default class ObservedListItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { children, height, id, style } = this.props;
    if (nextProps.height !== height || nextProps.id !== id || nextProps.children.key !== children.key) {
      return true;
    }
    if (!_.isEqual(nextProps.style, style)) {
      return true;
    }
    if (!_.isEqual(nextProps.children.props, children.props)) {
      return true;
    }

    return !nextProps.isHeightValid;
  }

  render() {
    return <ListItem {...this.props} />;
  }
}

export function ListItem(props) {
  const ref = useRef(null);
  const isManualRef = useRef(false);
  const handleHeightChangeRef = useHandleHeightChangeRef(ref, isManualRef, props);
  useEffect(() => {
    handleHeightChangeRef.current();
  });
  useResizeObserver(ref, () => {
    handleHeightChangeRef.current && handleHeightChangeRef.current();
  });
  const heightChangeContext = useHeightChangeContext(isManualRef, props);

  const { children, id, style } = props;
  return (
    <HeightChangeContext.Provider value={heightChangeContext}>
      <div className="listItem" id={`listItem-${id}`} ref={ref} style={style}>
        {children}
      </div>
    </HeightChangeContext.Provider>
  );
}

function useHandleHeightChangeRef(ref, isManualRef, props) {
  const handleRef = useRef();
  const { height, id, isHeightValid, onHeightChange } = props;

  useEffect(() => {
    handleRef.current = () => {
      if (!ref.current || !onHeightChange) {
        return;
      }

      const bounds = ref.current.getBoundingClientRect();
      if (Math.abs(bounds.height - height) > 0.5 || height == null || !isHeightValid) {
        onHeightChange(id, bounds.height, isManualRef.current);
      }
      isManualRef.current = false;
    };
  }, [height, id, isHeightValid, isManualRef, onHeightChange, ref]);

  return handleRef;
}

function useHeightChangeContext(isManualRef, props) {
  return useMemo(() => {
    return {
      height: props.height,
      onHeightChange: ({ isManual }) => {
        isManualRef.current = isManual;
      },
    };
  }, [props.height, isManualRef]);
}

export function HandleHeightChange({ children, isManual }) {
  const { onHeightChange } = useContext(HeightChangeContext);

  return (
    <HandleHeightChangeBase isManual={isManual} onHeightChange={onHeightChange}>
      {children}
    </HandleHeightChangeBase>
  );
}

HandleHeightChange.propTypes = {
  children: PropTypes.func.isRequired,
  isManual: PropTypes.bool,
  onHeightChange: PropTypes.func,
};

class HandleHeightChangeBase extends React.Component {
  constructor(props) {
    super(props);
    this.handleHeightChange = this.handleHeightChange.bind(this);
  }
  render() {
    return this.props.children({ onHeightChange: this.handleHeightChange });
  }

  handleHeightChange() {
    this.props.onHeightChange && this.props.onHeightChange({ isManual: this.props.isManual });
  }
}

HandleHeightChangeBase.propTypes = {
  children: PropTypes.func.isRequired,
  isManual: PropTypes.bool,
  onHeightChange: PropTypes.func.isRequired,
};
