import AITextCompletion from 'models/ai_text_completion';
import qconsole from 'scripts/lib/qconsole';

export default class ClearComposerConversationSummary {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId, customerId }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      return qconsole.log(
        `ClearComposerConversationSummary: received request for unloaded customer [${customerId}]. Ignoring.`
      );
    }

    const store = this.context.stores.customers.storesFor(customerId).aiTextCompletions;
    const completion = store.findBy({ id: conversationId, type: AITextCompletion.Types.conversation_summary });
    if (completion) {
      store.remove(conversationId);
    }
  }
}
