/**
 * Removes all zero-width entities from the `source` and returns only the characters that can be
 * rendered in the DOM
 *
 * @param {string} source
 * @returns {string}
 */
export function stripZeroWidthCharacters(source) {
  if (!source) return '';
  // eslint-disable-next-line no-misleading-character-class
  return source.replace(/[\u200B\u200C\uFEFF\uFFFE]+/g, '');
}
