import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import DynamicOverflow from 'components/common/dynamic_overflow';
import { Item, Menu, Separator } from 'components/customer/composition/lib/slate/slate_styles_menu.styles';
import OverflowFillIcon from 'components/common/icons/fill/overflow-fill';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';

import { Bold, Italic, Link, OrderedList, Quote, Underline, UnorderedList } from './editor_action_buttons';
import FontColor from 'components/customer/composition/lib/slate/slate_font_color_menu';
import FontSize from 'components/customer/composition/lib/slate/slate_font_size_menu';
import {
  SlateTextDecreaseIndentationMenu,
  SlateTextIncreaseIndentationMenu,
} from 'components/customer/composition/lib/slate/slate_text_indentation_menu';
import TextAlignment from 'components/customer/composition/lib/slate/slate_alignment_menu';

export function RichTextEditorStyles(props) {
  return (
    <StyledMenu data-aid="richTextEditorStyles">
      <RichTextStyles {...props} />
    </StyledMenu>
  );
}

const StyledMenu = styled(Menu)`
  flex-grow: 1;
  display: flex;
  overflow: auto;
`;

export function RichTextStyles({ additionalMenuItems, children, editorRef, onChange, value }) {
  const stylesProps = { editor: editorRef.current, onChange, value };

  const menuItems = [
    ...additionalMenuItems,
    <Bold alwaysShow key="menu-bold-toggle" {...stylesProps} />,
    <Italic alwaysShow key="menu-italic-toggle" {...stylesProps} />,
    <Underline alwaysShow key="menu-underline-toggle" {...stylesProps} />,
    <FontSize key="menu-font-styles" {...stylesProps} />,
    <FontColor key="menu-font-colors" {...stylesProps} />,
    <OrderedList key="menu-ol-toggle" {...stylesProps} />,
    <UnorderedList key="menu-ul-toggle" {...stylesProps} />,
    <TextAlignment key="menu-align-styles" {...stylesProps} />,
    <SlateTextDecreaseIndentationMenu key="menu-unindent" {...stylesProps} />,
    <SlateTextIncreaseIndentationMenu key="menu-indent" {...stylesProps} />,
    <Link key="menu-link-styles" {...stylesProps} />,
    <Quote key="menu-quote-styles" {...stylesProps} />,
    ...children,
  ];

  function removeDuplicates(displayItems) {
    const deduped = _.reduce(
      displayItems,
      (acc, item) => {
        const itemType = item?.props?.children?.type;
        if (!itemType) return acc;

        if (itemType !== acc.lastItemType) {
          acc.result.push(item);
        }
        acc.lastItemType = itemType;
        return acc;
      },
      { result: [], lastItemType: null }
    );

    return deduped.result || [];
  }

  return (
    <DynamicOverflow
      defaultItemWidth={38}
      itemRenderer={(visibleItems, overflowItems) => {
        // remove two consecutive separators
        const itemsToDisplay = removeDuplicates(visibleItems);
        const visible = itemsToDisplay.map((item, idx) => ({ ...item, key: item.key || `visible-item-${idx}` }));
        const overflow = (overflowItems || []).map((item, idx) => ({
          ...item,
          key: item.key || `overflow-item-${idx}`,
        }));

        return (
          <React.Fragment>
            {visible}
            {overflow.length > 0 ? <RichTextStylesOverflowMenu>{overflow}</RichTextStylesOverflowMenu> : null}
          </React.Fragment>
        );
      }}
    >
      {menuItems}
    </DynamicOverflow>
  );
}

RichTextStyles.defaultProps = {
  additionalMenuItems: [],
  children: [],
};

import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';

const StyledPopoverMenu = styled(PopoverMenu)`
  overflow: visible;
`;

const RichTextStylesOverflowMenu = ({ children }) => {
  const { targetRef, isOpen, onClose, onToggle, setTargetRef } = usePopoverMenu();
  const preventBlurring = evt => evt.preventDefault();

  return (
    <React.Fragment>
      <Separator />
      <OutsideClickHandler onClickOutside={onClose}>
        <Item data-aid="overflowMenuButton" onClick={onToggle} onMouseDown={preventBlurring} ref={setTargetRef}>
          {isOpen ? <MoreMenuItemOpen /> : <MoreMenuItemClosed />}
        </Item>
        <StyledPopoverMenu
          boundByWindow
          isOpen={isOpen}
          margin={4}
          onClickOutside={null}
          onClose={onClose}
          position="top"
          targetPosition="center"
          targetRef={targetRef}
        >
          <StyledPopoverMenuItem onClose={_.noop}>{children}</StyledPopoverMenuItem>
        </StyledPopoverMenu>
      </OutsideClickHandler>
    </React.Fragment>
  );
};

const StyledPopoverMenuItem = styled(PopoverMenuItem)`
  flex-direction: row;
  gap: 4px;
  min-width: 0;
  padding: 8px;
  &:hover {
    background: none;
  }
`;

const MoreMenuItemOpen = styled(OverflowFillIcon)`
  stroke: ${p => p.theme.colors.green400};
  fill: ${p => p.theme.colors.green400};
  height: 18px;
  width: 13px;
  line-height: 0;
`;

const MoreMenuItemClosed = styled(OverflowFillIcon)`
  stroke: ${p => p.theme.colors.black};
  fill: ${p => p.theme.colors.gray900};
  height: 18px;
  width: 13px;
  line-height: 0;
`;
