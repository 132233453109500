import _ from 'lodash';
import moment from 'moment';

import ReportHelpers from './report_helpers';
import Report, { ReportType } from './report';

export default class TopicReport extends Report {
  _getDataByTopicId() {
    return ReportHelpers.getDataById.call(this, 'newConversationsByTopicIDAndDay');
  }

  _getFilteredData(filteredTopicIds) {
    if (!filteredTopicIds || filteredTopicIds.length === 0) {
      return this.data.newConversationsByTopicIDAndDay;
    }
    return _.filter(this.data.newConversationsByTopicIDAndDay, data => _.includes(filteredTopicIds, data.id));
  }

  init(filteredIds) {
    return ReportHelpers.init(this._getDataByTopicId.bind(this), filteredIds);
  }

  getDataByDayAndTopicId(nameData, filteredTopicIds, numTopTopics, days) {
    let zeroedDays = {};

    _.map(days, day => {
      zeroedDays[day] = 0;
    });

    let topTopics = this.getTopTopics(filteredTopicIds, numTopTopics);
    let totalsByDay = this.getTotalsByDay(filteredTopicIds);

    // filters out non-topTopics from raw data
    let topTopicsReport = _.filter(this._getFilteredData(filteredTopicIds), entry => {
      return topTopics.includes(entry.id);
    });

    // when explicitly filtering by topics, we expect to see 0s
    _.forEach(topTopics, id => {
      _.forEach(days, day => {
        if (
          !_.find(topTopicsReport, entry => {
            return entry.id === id && ReportHelpers.getDate(entry.day).format('YYYY-MM-DD') === day;
          })
        ) {
          topTopicsReport.push({ id, day, count: 0 });
        }
      });
    });

    // groups data by topicId so easier to augment with zeroes
    var dataByTopicId = _.mapValues(_.groupBy(topTopicsReport, 'id'), item => {
      let topicDates = {};
      item.map(entry => {
        topicDates[ReportHelpers.getDate(entry.day).format('YYYY-MM-DD')] = entry.count;
      });
      return topicDates;
    });

    // augments data with zeroes for dates where a topic has no created convos
    let dataWithZeroes = _.reduce(
      dataByTopicId,
      (result, value, key) => {
        result[key] = _.assign({}, zeroedDays, value);
        return result;
      },
      {}
    );

    // ungroups data by id
    let data = _.map(dataWithZeroes, (entry, key) => {
      return _.map(entry, (count, day) => {
        return {
          id: key,
          date: day,
          name:
            key === 'ALL_TOPICS'
              ? 'All Topics'
              : _.find(nameData, ['id', key])
              ? _.find(nameData, ['id', key]).name
              : 'No Topic',
          rank: topTopics.indexOf(key),
          value: count,
        };
      });
    }).flat();

    // groups by date and returns data in the shape TopicHierarchyChart and Tooltip expects

    let dataByDate = _.map(_.groupBy(data, 'date'), (value, key) => ({
      // we want to take the TZ into consideration here otherwise points are plotted against the wrong date
      date: moment(key).valueOf(),
      values: value,
      total: _.find(totalsByDay, ['date', key]) ? _.find(totalsByDay, ['date', key]).count : 0,
    }));

    // if there's actually no data, we want all 0s for a special `ALL_TOPICS` topic
    if (!dataByDate || dataByDate.length === 0) {
      dataByDate = _.map(days, day => {
        return {
          date: moment(day).valueOf(),
          total: 0,
          values: [
            {
              id: 'ALL_TOPICS',
              date: day,
              name: 'All Topics',
              rank: 0,
              value: 0,
            },
          ],
        };
      });
    }
    return dataByDate;
  }

  getTopTopics(filteredTopicIds, numTopTopics) {
    // all topics and their total for each day
    let totalsByTopic = _.map(_.groupBy(this._getFilteredData(filteredTopicIds), 'id'), (value, key) => {
      return {
        topic: key,
        count: _.sumBy(value, entry => entry.count),
      };
    });

    _.forEach(filteredTopicIds, id => {
      if (!_.find(totalsByTopic, ['topic', id])) {
        totalsByTopic.push({ topic: id, count: 0 });
      }
    });

    // when there are no topics to display, we show a special `ALL_TOPICS` topic
    if (totalsByTopic.length === 0) {
      return ['ALL_TOPICS'];
    }

    let descendingTotalsByTopic = _.reverse(_.sortBy(totalsByTopic, 'count'));

    // array of top N topics
    return _.map(descendingTotalsByTopic.slice(0, numTopTopics), 'topic');
  }

  getTotalsByDay(filteredTopicIds) {
    return _.map(_.groupBy(this._getFilteredData(filteredTopicIds), 'day'), (value, key) => {
      return {
        date: ReportHelpers.getDate(key).format('YYYY-MM-DD'),
        count: _.sumBy(value, entry => entry.count),
      };
    });
  }

  get reportType() {
    return ReportType.TOPICS;
  }

  static getLineData(data, topicId) {
    let lineData = data.map(dataByDay => {
      let singleEntry = dataByDay.values.find(entry => {
        return entry.id === topicId;
      });
      let total = singleEntry ? singleEntry.value : 0;
      return [dataByDay.date, total];
    });

    return lineData.sort((a, b) => (a[0] > b[0] ? 1 : -1));
  }

  static roundToNearestPowerOfTen(number) {
    let nearestPower = number.toString().length - 1 || 1;
    let nearestPowerOfTen = Math.pow(10, nearestPower);
    return Math.ceil(number / nearestPowerOfTen) * nearestPowerOfTen;
  }

  getLegendData(nameData, filteredTopicIds, numOfKeys) {
    let rankedTopics = [];
    if (nameData) {
      let topTopics = this.getTopTopics(filteredTopicIds, numOfKeys);
      rankedTopics = topTopics.map((id, rank) => {
        if (id === 'ALL_TOPICS') {
          return {
            ancestralName: 'All Topics',
            id,
            rank,
            name: 'All Topics',
          };
        }
        const topic = _.find(nameData, ['id', id]);
        const name = topic ? topic.name : 'No Topic';
        const ancestralName = topic ? topic.nameWithAncestry : 'No Topic';

        return {
          ancestralName,
          id,
          rank,
          name,
        };
      });
      rankedTopics = _.sortBy(rankedTopics, 'rank');
    }
    return rankedTopics;
  }
}
