import BeginLogOut from 'actions/user/begin_log_out';
import EventRateCounter from 'actions/lib/event_rate_counter';

const MAX_DISCONNECT_COUNT = 5;
const RECENT_DISCONNECT_MS = 30000;

export default class UnauthorizedEventHandler {
  constructor(context, maxDisconnectCount = MAX_DISCONNECT_COUNT, minDisconnectInterval = RECENT_DISCONNECT_MS) {
    this.context = context;
    this.eventCounter = new EventRateCounter(maxDisconnectCount, minDisconnectInterval);
  }

  handleDisconnect() {
    this.eventCounter.countEvent();
    if (this.eventCounter.isExcessive) {
      this.eventCounter.reset();
      this.context.executeAction(BeginLogOut);
    }
  }

  handleUnauthorized() {
    this.context.executeAction(BeginLogOut);
  }
}
