import React from 'react';
import PropTypes from 'prop-types';

import { AIActivityTypes } from 'models/ai_activity/ai_activity_item';
import ConversationItem from 'models/conversation_item';
import ConversationSummaryItem from 'components/customer/conversation_history/conversation_items_v2/ai_activity/conversation_summary_item';

export default function AIActivityItem({ item, className }) {
  const activityType = item?.content?.activityType || '';

  switch (activityType) {
    case AIActivityTypes.CONVERSATION_SUMMARY:
      return <ConversationSummaryItem className={className} item={item} />;

    default:
      return null;
  }
}

AIActivityItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
