import forEach from 'lodash/forEach';

import getNearestColor from '../lib/get_nearest_color';
import getNearestFontSize from '../lib/get_nearest_font_size';

export default function createWordRichTextRules(context) {
  return [
    {
      deserialize(el, next) {
        if (el.tagName.toLowerCase() === 'span' || el.tagName.toLowerCase() === 'div') {
          const marks = [];
          const styles = el.style;
          if ((styles.color && styles.color !== '#000000') || styles.fontSize !== '15pt') {
            let data = {};
            // Links are usually blue, but we'll ignore link color here.
            if (styles.color && styles.color !== '#000000') {
              const fontColor = getNearestColor(styles.color);
              data.fontColor = fontColor;
            }
            if (styles.fontSize !== '15pt') {
              const fontSizePt = parseInt(styles.fontSize.slice(0, -2));
              const fontSizePx = Math.round(fontSizePt * 1.33333);
              const nearestFontSize = getNearestFontSize(fontSizePx);
              data.fontSize = nearestFontSize;
            }
            marks.push({ type: 'font', data });
          }
          if (marks.length) {
            return createNestedMarks(el, next, marks);
          }
        }

        if (el.tagName.toLowerCase() === 'b') {
          return { object: 'mark', type: 'bold', nodes: next(el.childNodes) };
        }
        if (el.tagName.toLowerCase() === 'i') {
          return { object: 'mark', type: 'italic', nodes: next(el.childNodes) };
        }
        if (el.tagName.toLowerCase() === 'u') {
          return { object: 'mark', type: 'underline', nodes: next(el.childNodes) };
        }
        if (el.tagName.toLowerCase() === 'p') {
          return { object: 'block', type: 'paragraph', nodes: next(el.childNodes) };
        }
        return next();
      },
    },
    // Links
    {
      deserialize(el, next) {
        if (el.tagName.toLowerCase() === 'a') {
          const href = el.href;
          return { object: 'inline', type: 'link', nodes: next(el.childNodes), data: { href } };
        }
      },
    },
  ];
}

function createNestedMarks(el, next, marks) {
  const topObject = {};
  let object = topObject;
  forEach(marks, (mark, index) => {
    object.object = 'mark';
    object.type = mark.type;
    object.data = mark.data;

    if (index === marks.length - 1) {
      object.nodes = next(el.childNodes);
    } else {
      const newObject = {};
      object.nodes = [newObject];
      object = newObject;
    }
  });
  return topObject;
}
