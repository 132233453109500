import createItemLink from 'scripts/domain/factories/conversation_item/create_item_link';
import { LinkedCustomerProfile } from 'models/item_link_content';
import LinkedItem from 'models/linked_item';
import TrackEvent from 'actions/analytics/track_event';

export default class LinkItemToCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ itemId, linkedCustomerId, originalCustomerId }) {
    let currentAgent = this.context.stores.currentAgent.get();

    let conversationItem = createItemLink({
      content: {
        customerId: originalCustomerId,
        itemId,
      },
      currentAgentId: currentAgent.id,
      customerId: linkedCustomerId,
    });

    let linkedCustomerName;
    if (this.context.stores.customers.has({ id: linkedCustomerId })) {
      const customerStores = this.context.stores.customers.storesFor(linkedCustomerId);
      const profile = customerStores.profile.get();
      linkedCustomerName = profile.name;
    }
    const linkedCustomerProfile = new LinkedCustomerProfile({ name: linkedCustomerName });

    // Immediately add the linked item to the original item to give agent feedback that linking worked
    const originalCustomerStores = this.context.stores.customers.storesFor(originalCustomerId);
    const originalItem = originalCustomerStores.conversationHistory.findBy({ id: itemId });
    const newLinkedItem = new LinkedItem({
      customerId: linkedCustomerId,
      customerProfile: linkedCustomerProfile,
      itemId: conversationItem.id,
    });
    originalItem.addLinkedItem(newLinkedItem);
    originalCustomerStores.conversationHistory.setPending(originalItem);

    this.context.gateways.customerHistory.addItem(linkedCustomerId, conversationItem.toJs());

    this.context.executeAction(TrackEvent, {
      event: 'Relationships - Items - Link Item',
      props: { itemId, linkedCustomerId, originalCustomerId },
    });
  }
}
