import _ from 'lodash';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faStar as faStarOutline } from '@fortawesome/pro-light-svg-icons/faStar';
import { faStar as faStarFilled } from '@fortawesome/pro-solid-svg-icons/faStar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import connect from 'components/lib/connect';
import { getAllAttachments } from 'models/answers/snippet_helpers';
import getHighlightIndices from 'components/customer/answer_panel/search/get_highlight_indices';
import SelectAnswer from 'actions/answers/select_answer';
import ToggleFavoriteAnswer from 'actions/favorite_answers/toggle_favorite_answer';
import useExecuteAction from 'components/hooks/use_execute_action';

export const HEIGHT = 32;

export function AnswerListItemBase({ answer, isFavorited, isFocused, searchText }) {
  const ref = useRef();
  const onClick = useOnClick(answer);
  useScrollIntoView(ref, isFocused);

  return (
    <ListItem data-aid="answerListItem" isFavorited={isFavorited} isFocused={isFocused} onClick={onClick} ref={ref}>
      <Ellipsis title={answer.name}>
        <HighlightedName answer={answer} isFocused={isFocused} searchText={searchText} />
      </Ellipsis>
      <Paperclip hasAttachments={getAllAttachments(answer).length > 0} />
      <Favorite answer={answer} isFavorited={isFavorited} />
    </ListItem>
  );
}

const AnswerListItemContainer = connect(mapStateToItemProps)(AnswerListItemBase);

function mapStateToItemProps({ getProvider }, { answer }) {
  const agentPreferences = getProvider('agentPreferences').get();
  return {
    isFavorited: agentPreferences.isAnswerFavorited(answer.id),
  };
}

const AnswerListItem = React.memo(AnswerListItemContainer);
export default AnswerListItem;

const StyledFavorite = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.gray600};
  display: none;
  height: 32px;
  justify-content: center;
  margin-left: 4px;
  width: 16px;

  &:hover svg {
    opacity: 0.7;
  }
`;

export const ListItem = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0px 16px;
  position: relative;
  &:hover {
    background-color: ${p => p.theme.colors.green100};
    ${StyledFavorite} {
      display: flex;
      color: ${p => p.theme.colors.green400};
    }
  }

  ${p => p.isFocused && listItemFocused};
  ${p => p.isFavorited && listItemFavorited};
`;

const listItemFocused = css`
  &,
  &:hover {
    background-color: ${p => p.theme.colors.green400};
    color: ${p => p.theme.colors.white};
    ${StyledFavorite} {
      display: flex;
      color: ${p => p.theme.colors.white};
    }
  }
`;

const listItemFavorited = css`
  ${StyledFavorite} {
    display: flex;
  }
`;

const Ellipsis = styled.span`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function useOnClick(answer) {
  const executeAction = useExecuteAction();
  return useCallback(() => {
    executeAction(SelectAnswer, { answer, clearHistory: true });
  }, [answer]);
}

function useScrollIntoView(ref, isFocused) {
  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isFocused, ref]);
}

const HighlightedName = React.memo(HighlightedNameBase);
function HighlightedNameBase({ answer, isFocused, searchText }) {
  if (!answer.name) {
    return null;
  }

  const text = answer.name;
  const highlights = getHighlightIndices(searchText, answer, ['name']).name;

  let textChunks = [];
  let cursor = 0;

  _.forEach(highlights, (highlight, index) => {
    textChunks.push(<span key={`item-text-${index}`}>{text.slice(cursor, highlight.start)}</span>);
    textChunks.push(
      <Highlight isFocused={isFocused} key={`item-highlight-${index}`}>
        {text.slice(highlight.start, highlight.end)}
      </Highlight>
    );
    cursor = highlight.end;
  });

  if (cursor < text.length) {
    textChunks.push(<span key={'item-text-trailing'}>{text.slice(cursor, text.length)}</span>);
  }

  return <React.Fragment>{textChunks}</React.Fragment>;
}

const Highlight = styled.span`
  color: ${p => (p.isFocused ? p.theme.colors.white : p.theme.colors.green400)};
  font-weight: 600;
`;

function Paperclip({ hasAttachments }) {
  if (!hasAttachments) {
    return null;
  }

  return (
    <PaperclipStyled>
      <FontAwesomeIcon icon={faPaperclip} />
    </PaperclipStyled>
  );
}

const PaperclipStyled = styled.div`
  margin-left: ${p => p.theme.spacing.medium};
`;

function Favorite({ answer, isFavorited }) {
  const { answersList } = useContext(AnswerPanelContext);

  const executeAction = useExecuteAction();
  const toggleFavoriteAnswer = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    executeAction(ToggleFavoriteAnswer, { answer });
  };

  return (
    <StyledFavorite
      data-aid="answerFavorite"
      onClick={toggleFavoriteAnswer}
      showingFavorites={answersList.type === 'FAVORITES'}
    >
      <FontAwesomeIcon icon={isFavorited ? faStarFilled : faStarOutline} />
    </StyledFavorite>
  );
}
