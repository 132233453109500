import AgentProfileEditor from 'models/location/agent_profile_editor';
import changeCurrentLocation from 'actions/lib/change_current_location';

export default class OpenAgentProfile {
  constructor(context) {
    this.context = context;
  }

  run() {
    changeCurrentLocation(this.context, AgentProfileEditor.create());
  }
}
