import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import AddObserverToCall from 'models/modal/add_observer_to_call';
import AddObserverToCallModal from './add_observer_to_call_modal';
import AgentStatusReason from 'models/modal/agent_status_reason';
import AnswerPreview from 'models/modal/answer_preview';
import AnswerModalContainer from 'components/modals/answer_modal_container';
import AttachmentPreview from 'models/modal/attachment_preview';
import CloseConversationWarning from 'models/modal/close_conversation_warning';
import CloseConversationWarningModalContainer from 'components/modals/close_conversation_warning_modal_container';
import ConversationItemRedactModal from 'components/modals/conversation_item_redact_modal';
import CreditCardAmount from 'models/modal/credit_card_amount';
import CreditCardAmountModal from 'components/modals/credit_card_amount_modal';
import CustomerDeleteModal from 'components/modals/customer_delete_modal';
import CustomerUnlinkModalContainer from 'components/modals/customer_unlink_modal_container';
import CustomReportingError from 'models/modal/custom_reporting_error';
import CustomReportingErrorModal from 'components/custom_reporting/custom_reporting_error_modal';
import DeleteCustomer from 'models/modal/delete_customer';
import DeleteVoiceRecording from 'models/modal/delete_voice_recording';
import DeleteVoiceRecordingModal from './delete_voice_recording_modal';
import MergeProfile from 'models/modal/merge_profile';
import MergeProfileModal from './merge_profile/merge_profile_modal';
import PreviewAttachmentModalContainer from 'components/lib/attachment/preview_attachment_modal_container';
import RedactConversationItem from 'models/modal/redact_conversation_item';
import ReopenClosedConversationModalContainer from 'components/customer/lib/reopen_conversation_modal_container';
import ReopenConversation from 'models/modal/reopen_conversation';
import ShowCreditCard from 'models/modal/show_credit_card';
import ShowCreditCardModal from 'components/modals/show_credit_card_modal';
import ScheduleRecurringReport from 'models/modal/schedule_recurring_report';
import ScheduleRecurringReportModalContainer from 'components/reporting/schedule_recurring_report_modal';
import StatusReasonModal from 'components/modals/status_reasons_modal';
import TaskConfirmation from 'models/modal/task_confirmation';
import TaskConfirmationModal from './task_confirmation_modal';
import UnlinkProfile from 'models/modal/unlink_profile';
import UploadCsv from 'models/modal/upload_csv';
import UploadCsvModal from './upload_csv_modal';
import WebhookVerification from 'models/modal/webhook_verification';
import WebhookVerificationModal from 'components/modals/webhook_verification_modal';

const Modal = ({ modalShown, onClose, onError }) => {
  if (!modalShown) {
    return null;
  }

  // This is a full screen modal, and it wants to control its own transitions.
  if (modalShown instanceof ScheduleRecurringReport) {
    return <ScheduleRecurringReportModalContainer />;
  }

  const modalContent = renderModal();
  if (!modalContent) {
    // We were asked to display an unknown modal. Report the error and quit.
    const modalAttrs = modalShown?.toJs ? modalShown.toJs() : modalShown;
    reportError(`Modal [${modalAttrs.type}] was requested but did not render`, modalAttrs);
    return null;
  }

  return (
    <div>
      <ReactCSSTransitionGroup
        transitionAppear
        transitionAppearTimeout={1000}
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={1}
        transitionName="Modal-backdrop"
      >
        <div className="Modal-backdrop" />
      </ReactCSSTransitionGroup>
      {modalContent}
    </div>
  );

  function renderModal() {
    if (modalShown instanceof AgentStatusReason) {
      return <StatusReasonModal />;
    } else if (modalShown instanceof AnswerPreview) {
      return <AnswerModalContainer onClose={onClose} />;
    } else if (modalShown instanceof AttachmentPreview) {
      return <PreviewAttachmentModalContainer />;
    } else if (modalShown instanceof CloseConversationWarning) {
      return <CloseConversationWarningModalContainer onClose={onClose} />;
    } else if (modalShown instanceof RedactConversationItem) {
      return <ConversationItemRedactModal />;
    } else if (modalShown instanceof CreditCardAmount) {
      return <CreditCardAmountModal onClose={onClose} />;
    } else if (modalShown instanceof CustomReportingError) {
      return <CustomReportingErrorModal onClose={onClose} />;
    } else if (modalShown instanceof DeleteCustomer) {
      return <CustomerDeleteModal />;
    } else if (modalShown instanceof DeleteVoiceRecording) {
      return <DeleteVoiceRecordingModal onClose={onClose} />;
    } else if (modalShown instanceof AddObserverToCall) {
      return <AddObserverToCallModal onClose={onClose} />;
    } else if (modalShown instanceof MergeProfile) {
      return <MergeProfileModal />;
    } else if (modalShown instanceof ReopenConversation) {
      return <ReopenClosedConversationModalContainer onCancel={onClose} />;
    } else if (modalShown instanceof ShowCreditCard) {
      return <ShowCreditCardModal onClose={onClose} />;
    } else if (modalShown instanceof TaskConfirmation) {
      return <TaskConfirmationModal onConfirm={onClose} />;
    } else if (modalShown instanceof UnlinkProfile) {
      return <CustomerUnlinkModalContainer />;
    } else if (modalShown instanceof UploadCsv) {
      return <UploadCsvModal onClose={onClose} />;
    } else if (modalShown instanceof WebhookVerification) {
      return <WebhookVerificationModal />;
    }

    return null;
  }

  function reportError(message, modalAttrs) {
    if (onError) {
      setImmediate(() => {
        onError({ message, meta: modalAttrs });
      });
    }
  }
};

Modal.propTypes = {
  modalShown: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

export default Modal;
