import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import { AdminList } from '../lib/admin_layout';
import KbVariable from 'models/kb_variable';
import VariablesAdminListItem from './variables_admin_list_item';

const VariablesAdminList = createReactClass({
  propTypes: {
    isSavePending: PropTypes.func.isRequired,
    onChangeVariable: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    variables: PropTypes.arrayOf(PropTypes.instanceOf(KbVariable)).isRequired,
  },

  onChangeVariable(attrs) {
    this.props.onChangeVariable(attrs);
  },

  render() {
    return (
      <AdminList className="variablesAdmin-list">
        {this.props.variables.map(variable => (
          <VariablesAdminListItem
            key={`variablesAdmin-item-${variable.id}`}
            onChangeVariable={this.onChangeVariable}
            readOnly={this.props.readOnly}
            savePending={this.props.isSavePending(variable.id)}
            variable={variable}
          />
        ))}
      </AdminList>
    );
  },
});

export default VariablesAdminList;
