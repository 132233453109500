import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Button from 'components/common/button';
import CreateCreditCardRequestComposition from 'actions/composition/create_credit_card_request_composition';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function CreditCardRequestButton({
  agentIsExistingChatParticipant,
  isVGSEnabled,
  hasActiveSecureDataRequests,
  paymentIsEnabledForEndpoint,
}) {
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => executeAction(CreateCreditCardRequestComposition), []);
  const showCreditCardRequestButton = isVGSEnabled && agentIsExistingChatParticipant && paymentIsEnabledForEndpoint;

  if (showCreditCardRequestButton && !hasActiveSecureDataRequests) {
    return (
      <label className={'draftStylesMenu-item'} title={'Request credit card'}>
        <RequestPaymentButton className={classnames('fa fa-credit-card')} onClick={onClick} />
      </label>
    );
  }

  return null;
}

CreditCardRequestButton.propTypes = {
  agentIsExistingChatParticipant: PropTypes.bool.isRequired,
  isVGSEnabled: PropTypes.bool.isRequired,
  hasActiveSecureDataRequests: PropTypes.bool.isRequired,
  paymentIsEnabledForEndpoint: PropTypes.bool.isRequired,
};

const RequestPaymentButton = styled(Button)`
  background: transparent;
  border: none;
  color: inherit;
  height: 25px;
  padding: 0;
  width: 25px;
  &:focus {
    border: 1px solid grayLight;
    border-radius: 4px;
  }
  &:hover {
    background: transparent;
  }
`;

export { RequestPaymentButton };
