import ConversationItem from 'models/conversation_item';

export default class ActiveSecureDataRequestsObserver {
  constructor(context) {
    this.context = context;
  }

  onBroadcast(activeSecureDataRequestDto, { customerId }) {
    const customerStores = this.context.stores.customers.storesFor(customerId);
    const activeSecureDataRequestsStore = customerStores.activeSecureDataRequests;
    const activeSecureDataRequest = ConversationItem.fromJs(activeSecureDataRequestDto);

    activeSecureDataRequest.content.isActive()
      ? activeSecureDataRequestsStore.addOrReplace(activeSecureDataRequest)
      : activeSecureDataRequestsStore.remove(activeSecureDataRequest.id);
  }
}
