import React, { useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import ExternalLink, { ShipmentTracking } from 'components/lib/external_link';
import GetExternalActionForm from 'actions/external_customer_lookup/get_external_action_form';
import { H3 } from 'components/common/headers';
import { OrderTransactionDef, TransactionDefType } from 'models/configuration/transaction_def';
import OrderProductAttributes from 'components/customer/summary/transactions/order_product_attributes';
import ProfileCardType from 'models/configuration/profile_card_type';
import { setOverflowTitle } from 'components/lib/overflow_title';
import Tooltip from 'components/common/tooltip';
import useCopied from 'components/hooks/use_copied';
import VisibleCustomAttribute from '../custom_attributes/visible_custom_attribute';
import analytics from 'scripts/lib/analytics';

export const UNKNOWN_STATUS_LABEL = 'Status Unknown';

export function ExpandedOrderTransactionBase({
  attributes: orderAttributes,
  customerId,
  onOpenActionForm,
  shouldHideActions,
  shouldRenderImages,
  transactionDef,
}) {
  const [showCopied, onCopied] = useCopied();
  const orderNumber = _.trim(_.get(orderAttributes, 'orderNumber', ''));
  const orderStatus = getOrderStatus();

  return (
    <StyledExpandedCardContainer data-aid="expanded-card-container">
      <StyledTopTriangle>
        <StyledTopTriangleBorder />
      </StyledTopTriangle>
      <StyledOrderNumberSection data-aid="order-number-section">
        <StyledOrderNumberWrapper data-aid="order-number">{renderOrderNumber(orderNumber)}</StyledOrderNumberWrapper>
      </StyledOrderNumberSection>
      {renderOrderStatus()}
      <div>
        {renderSection('', renderProducts(), 'products')}
        {renderSection(<SectionHeader>Order Details</SectionHeader>, renderOrderAttributes(), 'order-attributes')}
        {renderSection(<SectionHeader>Shipments</SectionHeader>, renderFulfillments(), 'shipments')}
        {renderSection(<SectionHeader>Notes</SectionHeader>, renderNote(), 'notes')}
        {renderSection(<SectionHeader>Actions</SectionHeader>, renderActions(), 'actions')}
      </div>
    </StyledExpandedCardContainer>
  );

  /**
   * Different customer implementations use either `status` or `orderStatus` so we have to account
   * for both. We try to use `status` falling back on `orderStatus` if necessary
   *
   * @returns {string}
   */
  function getOrderStatus() {
    const status = _.get(orderAttributes, 'status', '');
    const fallbackStatus = _.get(orderAttributes, 'orderStatus', '');

    return _.trim(status || fallbackStatus || '');
  }

  function renderSection(label, content, tag) {
    if (!content) return null;

    return (
      <StyledOrderCardSection
        className="transaction-order-section"
        data-aid={`expanded-order-section-${tag}`}
        key={`order-section-${tag}`}
      >
        {label || null}
        {content}
      </StyledOrderCardSection>
    );
  }

  function renderOrderAttributes() {
    let hasAttributes = _.find(transactionDef?.attributes, def => !!orderAttributes[def.attr]);
    if (!hasAttributes) {
      return null;
    }

    return _.map(transactionDef.attributes, def => {
      return (
        <VisibleCustomAttribute
          className="transactionAttribute"
          data-aid={`order-attribute-${def.attr}`}
          def={def}
          key={`optionalAttributes-${def.attr}`}
          location={{ type: ProfileCardType.TRANSACTIONS, subType: TransactionDefType.ORDER }}
          value={orderAttributes[def.attr]}
        />
      );
    });
  }

  function renderOrderNumber() {
    if (!orderNumber) {
      return <>Order</>;
    }
    const orderNumberText = `${orderNumber}`;
    return (
      <div>
        <StyledOrderLabel>Order&nbsp;</StyledOrderLabel>
        <StyledOrderNumber>
          <Tooltip delay={100} message={showCopied ? 'Order Number Copied' : 'Copy to Clipboard'} position="top">
            <CopyToClipboard text={orderNumberText}>
              <div
                onClick={() => {
                  analytics.track('Order Number Copied', {});
                  onCopied();
                }}
              >
                {orderNumberText}
              </div>
            </CopyToClipboard>
          </Tooltip>
        </StyledOrderNumber>
      </div>
    );
  }

  function renderOrderStatus() {
    if (!orderStatus) return null;

    const status = orderStatus.toLowerCase();
    const displayStatus = _.capitalize(orderStatus);
    const dataAid = `order-status-${status}`;

    switch (status) {
      case 'fulfilled':
        return (
          <StyledFulfilledOrderStatus className="expanded-order-status fulfilled" data-aid={dataAid}>
            {displayStatus}
          </StyledFulfilledOrderStatus>
        );
      case 'cancelled':
        return (
          <StyledCancelledOrderStatus className="expanded-order-status cancelled" data-aid={dataAid}>
            {displayStatus}
          </StyledCancelledOrderStatus>
        );

      default:
        return (
          <StyledDefaultOrderStatus className="expanded-order-status" data-aid={dataAid}>
            {displayStatus}
          </StyledDefaultOrderStatus>
        );
    }
  }

  /**
   * Renders fulfillment list for the Order. All fields in the `fulfillment` object are considered optional.
   * If fulfillment has a list of product Ids (that are included in the fulfillment), then the matching product
   * names from the order will be also displayed
   *
   * @returns {JSX.Element|null}
   */
  function renderFulfillments() {
    const fulfillments = _.get(orderAttributes, 'fulfillments') || [];
    if (!fulfillments || _.isEmpty(fulfillments) || !_.isArray(fulfillments)) return null;

    // Force product IDs to string to make sure we can cross-reference it later
    const products = _.get(orderAttributes, 'products') || [];
    const productsWithId = _.map(products, product => ({
      ...product,
      id: getStringProperty(product, 'id', true),
    }));

    // Comb down the raw data that we received and make sure we have all the required fields
    // before we do any further processing. Also, if the "productIds" are available, try to
    // cross-reference the available product list and bring in those with matching IDs
    const transformed = _.map(fulfillments, fulfillment => {
      const productIds = _.get(fulfillment, 'productIds') || [];
      const trimmedDate = getStringProperty(fulfillment, 'estimatedDeliveryDate');
      const trimmedStatus = getStringProperty(fulfillment, 'status');
      const trimmedUrl = getStringProperty(fulfillment, 'trackingUrl');
      const trimmedNumber = getStringProperty(fulfillment, 'trackingNumber');
      const uniqueProductIds = _.uniq(_.map(productIds, id => (_.isString(id) || _.isNumber(id) ? _.trim(id) : '')));

      // Pull in the product information if available
      const mappedProducts = _.reduce(
        uniqueProductIds,
        (acc, productId) => {
          if (productId) {
            const matchingProduct = _.find(productsWithId, ['id', productId]);
            if (matchingProduct) acc.push(matchingProduct);
          }
          return acc;
        },
        []
      );

      return {
        ...fulfillment,
        estimatedDeliveryDate: trimmedDate,
        status: trimmedStatus,
        products: mappedProducts,
        trackingUrl: trimmedUrl,
        trackingNumber: trimmedNumber,
      };
    });

    // Do we have any information to display? If not, exit
    const filteredList = filterFulfillments(transformed);
    if (_.isEmpty(filteredList)) return null;

    // Sort the shipments by the estimated date, the ones without date will go to the end of the list
    const withDate = _.filter(filteredList, fulfillment => !!fulfillment.estimatedDeliveryDate);
    const withoutDate = _.filter(filteredList, fulfillment => !fulfillment.estimatedDeliveryDate);
    const sortedByDate = _.orderBy(withDate, ['estimatedDeliveryDate'], ['desc']);
    const combined = [...sortedByDate, ...withoutDate];

    const renderedList = _.map(combined, (fulfillment, index) => {
      return renderSingleFulfillment(`${index}`, fulfillment);
    });

    return <div data-aid={'fulfillments-section'}>{renderedList}</div>;
  }

  /**
   * Renders a single "fulfillment" block. The `fulfillmentInfo` object is expected to be "sanitized" and
   * validated by the caller prior to rendering
   *
   * @param {string} index - index of a fulfillment from a list of ordered fulfillments list
   * @param {*} fulfillmentInfo
   * @returns {JSX.Element}
   */
  function renderSingleFulfillment(index, fulfillmentInfo) {
    const renderedStatus = fulfillmentInfo.status ? (
      <StyledFulfillmentElement bold data-aid={`fulfillment-status`}>
        {_.capitalize(fulfillmentInfo.status)}
      </StyledFulfillmentElement>
    ) : null;

    let dateString = 'No estimated delivery date';
    if (fulfillmentInfo.estimatedDeliveryDate) {
      const dateMoment = moment(fulfillmentInfo.estimatedDeliveryDate);
      if (dateMoment.isValid()) dateString = `Expected to arrive ${dateMoment.format('MMM D, YYYY')}`;
    }
    const renderedDate = <StyledFulfillmentElement bold>{dateString}</StyledFulfillmentElement>;

    const renderedProducts = _.reduce(
      fulfillmentInfo.products || [],
      (acc, product, idx) => {
        if (product.name) {
          acc.push(
            <StyledFulfillmentElement key={`fulfillment-product-${idx}`} title={product.name}>
              {product.name}
            </StyledFulfillmentElement>
          );
        }
        return acc;
      },
      []
    );

    return (
      <StyledFulfillmentWrapper data-aid={`fulfillment-${index}-wrapper`} key={`fulfillment-${index}`}>
        {renderedStatus}
        {renderedDate}
        {renderedProducts}
        <ShipmentTrackingInfo
          trackingNumber={fulfillmentInfo.trackingNumber}
          trackingUrl={fulfillmentInfo.trackingUrl}
        />
      </StyledFulfillmentWrapper>
    );
  }

  function renderNote() {
    const note = _.get(orderAttributes, 'note') || '';
    if (!note) {
      return null;
    }
    return <StyledNoteBodyContainer data-aid="note-container">{note}</StyledNoteBodyContainer>;
  }

  function renderProducts() {
    const products = _.get(orderAttributes, 'products') || [];
    if (_.isEmpty(products)) {
      return null;
    }

    // Group products by status and then render each status group. Products without status (if any) are
    // rendered separately as their own group
    const withStatus = _.filter(products, product => !!product.status);
    const withoutStatus = _.filter(products, product => !product.status);
    const grouped = _.groupBy(withStatus, product => product.status);
    const statuses = _.sortBy(_.keys(grouped));

    // Figure out how many product status values we have and whether we need to render the status label with groups
    // We only render the status header if:
    // 1. there are more than one status OR
    // 2. the product status does not match the order status, OR
    // 3. we found products both with and without status (uncommon)
    const shouldRenderGroupHeader =
      statuses.length > 1 ||
      (statuses.length && statuses[0].toLowerCase() !== orderStatus.toLowerCase()) ||
      (statuses.length && withoutStatus.length);

    const renderedWithStatus = _.map(statuses, status =>
      renderProductStatusGroup(_.capitalize(status), grouped[status], shouldRenderGroupHeader, shouldRenderImages)
    );
    const renderedWithoutStatus = renderProductStatusGroup(
      UNKNOWN_STATUS_LABEL,
      withoutStatus,
      shouldRenderGroupHeader,
      shouldRenderImages
    );

    return (
      <div>
        {renderedWithStatus}
        {renderedWithoutStatus}
      </div>
    );
  }

  /**
   * Renders a section that groups products with the same status (e.g. "Delivered" or "Refunded" etc.)
   *
   * @param {string} status
   * @param {[Object]} products
   * @param {Boolean} renderGroupHeader - controls whether the product group will have a "header" with the status
   * and a separator line
   * @param {Boolean} renderImages - controls whether products should be rendered with images (or placeholders)
   * @returns {JSX.Element}
   */
  function renderProductStatusGroup(status, products, renderGroupHeader, renderImages) {
    if (!products || !products.length) return null;

    const header = renderGroupHeader ? (
      <>
        <StyledProductStatus data-aid="product-status">{status}</StyledProductStatus>
        <StatusSeparator />
      </>
    ) : null;

    return (
      <StyledProductGroup
        className="product-attributes"
        data-aid={`product-group-${_.kebabCase(status)}`}
        hasHeader={renderGroupHeader}
        key={`product-group-${status}`}
      >
        {header}
        {_.map(products, (product, idx) => (
          <OrderProductAttributes
            key={`product-${idx}`}
            product={product}
            shouldRenderImage={renderImages}
            transactionDef={transactionDef}
          />
        ))}
      </StyledProductGroup>
    );
  }

  function renderActions() {
    const actions = _.get(orderAttributes, 'actions') || [];
    if (shouldHideActions || _.isEmpty(actions)) {
      return null;
    }

    const actionsToDisplay = _.filter(actions, action => !!action.integrationId);
    return (
      <StyledActionsContainer data-aid="action-button-panel">
        {_.map(actionsToDisplay, (action, idx) => {
          return (
            <StyledActionButton
              buttonType={ButtonTypes.SECONDARY}
              key={`styled-action-button-${idx}`}
              onClick={() => onOpenActionForm(customerId, action)}
            >
              {action.name}
            </StyledActionButton>
          );
        })}
      </StyledActionsContainer>
    );
  }

  /**
   * Simple helper function that checks the given list of fulfillments and removes those that have no
   * "renderable" attributes (such as status, tracking information etc.) and would be considered "empty".
   * The list is expected to be "sanitized" by the caller.
   *
   * @param fulfillmentList - "sanitized" list of fulfillments with all required properties checked and
   * converted to string if necessary
   * @returns {object[]}
   */
  function filterFulfillments(fulfillmentList) {
    return _.filter(fulfillmentList, f => {
      const date = _.trim(f.estimatedDeliveryDate);
      const isValidDate = date && moment(date).isValid();

      return !!isValidDate || !_.isEmpty(f.products || []) || !!f.status || !!f.trackingNumber || !!f.trackingUrl;
    });
  }

  /**
   * Helper function that retrieves an optional property from the data object, checks the property value type,
   * then converts to a string. Returns empty string if the property does not exist or has a wrong type.
   *
   * @param {Object} data - source data object
   * @param {string} propertyName
   * @param {boolean} allowNumericValues
   * @returns {string}
   */
  function getStringProperty(data, propertyName, allowNumericValues = false) {
    const value = _.get(data, propertyName);
    const isEmpty = _.isNil(value) || _.isNaN(value);
    const isRightType = allowNumericValues ? _.isString(value) || _.isNumber(value) : _.isString(value);

    return !isEmpty && isRightType ? _.trim(value) : '';
  }
}

/**
 * Shipping information subcomponent. Splitting it out to make the code more manageable and testable
 *
 * @param {String} trackingNumber
 * @param {String} trackingUrl
 * @returns {JSX.Element}
 * @constructor
 */
function ShipmentTrackingInfo({ trackingNumber, trackingUrl }) {
  const trackingNumberRef = useRef();
  const trimmedNumber = _.isString(trackingNumber) ? _.trim(trackingNumber) : '';
  const trimmedUrl = _.isString(trackingUrl) ? _.trim(trackingUrl) : '';

  useEffect(() => {
    setOverflowTitle(trackingNumberRef, trimmedNumber);
  });

  if (!trimmedNumber && !trimmedUrl) {
    return (
      <StyledFulfillmentElement>
        <StyledTrackingInfoWrapper disabled>Tracking information is not available</StyledTrackingInfoWrapper>
      </StyledFulfillmentElement>
    );
  }

  const renderedTrackingUrl = trimmedUrl ? (
    <StyledTrackingInfoWrapper>
      <ExternalLink
        className="tracking_link"
        displayLinkOutIcon
        displayOverflowTooltip
        text="View tracking on the carrier site"
        type={ShipmentTracking}
        url={trimmedUrl}
      />
    </StyledTrackingInfoWrapper>
  ) : null;

  const renderedTrackingNumber = trimmedNumber ? (
    <StyledTrackingInfoWrapper>
      <StyledTrackingLabel>Tracking No.</StyledTrackingLabel>
      <div className="tracking_number" ref={trackingNumberRef}>
        {trimmedNumber}
      </div>
    </StyledTrackingInfoWrapper>
  ) : null;

  return (
    <div>
      {renderedTrackingNumber}
      {renderedTrackingUrl}
    </div>
  );
}

const SectionHeader = styled(H3)`
  margin-bottom: 14px;
`;

const StatusSeparator = styled.hr`
  border-top: 1px solid ${p => p.theme.colors.gray300};
  height: 0;
  margin: 0;
`;

const StyledActionButton = styled(Button)`
  border-radius: ${p => p.theme.borderRadius.small};
  margin-top: ${p => p.theme.spacing.stackSmall};
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    margin-top: 0;
  }
  &:hover {
    background-color: #fafafa;
    border-color: ${p => p.theme.colors.green400};
  }
`;

const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledExpandedCardContainer = styled.div`
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.large};
  box-shadow: ${p => p.theme.boxShadow.medium};
  font-size: ${p => p.theme.fontSize.base};
  margin: ${p => p.theme.spacing.medium} 0;
  position: relative;
  text-align: left;
`;

const StyledNoteBodyContainer = styled.div`
  background-color: ${p => p.theme.colors.gray100};
  border: 0.5px solid ${p => p.theme.colors.gray200};
  border-radius: ${p => p.theme.borderRadius.small};
  max-height: 130px;
  min-height: 60px;
  overflow: auto;
  padding: ${p => p.theme.spacing.insetSmall};
  white-space: pre-line;
`;

const StyledOrderCardSection = styled.div`
  padding: ${p => p.theme.spacing.insetMedium};

  &:first-child {
    padding-top: 0;
  }
  & + & {
    border-top: 1px solid ${p => p.theme.colors.gray200};
  }
`;

const StyledOrderLabel = styled.div`
  float: left;
`;

const StyledOrderNumber = styled.div`
  float: left;
  cursor: pointer;
`;

const StyledOrderNumberSection = styled.div`
  color: ${p => p.theme.colors.gray900};
  padding: 16px 16px 8px 16px;
`;

const StyledOrderNumberWrapper = styled(H3)`
  font-size: ${p => p.theme.fontSize.large};
  line-height: 21px;
  margin-bottom: ${p => p.theme.spacing.small};
  word-break: break-all;
`;

const StyledDefaultOrderStatus = styled.div`
  background-color: ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.xsmall};
  color: ${p => p.theme.colors.black};
  display: inline-block;
  font-size: ${p => p.theme.fontSize.base};
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1%;
  margin: 0 ${p => p.theme.spacing.insetMedium} 12px ${p => p.theme.spacing.insetMedium};
  padding: 1px 4px;
  text-transform: capitalize;
  word-break: break-all;
`;

const StyledFulfilledOrderStatus = styled(StyledDefaultOrderStatus)`
  background-color: ${p => p.theme.colors.purple100};
  color: ${p => p.theme.colors.purple700};
`;

const StyledCancelledOrderStatus = styled(StyledDefaultOrderStatus)`
  background-color: ${p => p.theme.colors.yellow100};
  color: ${p => p.theme.colors.yellow700};
`;

const StyledProductGroup = styled.div`
  margin-top: ${p => p.theme.spacing.insetMedium};

  &:first-child {
    margin-top: ${p => (p.hasHeader ? '0' : '-12px')};
  }
`;

const StyledProductStatus = styled.div`
  font-size: ${p => p.theme.fontSize.base};
  font-weight: ${p => p.theme.fontWeight.medium};
  text-transform: capitalize;
`;

const StyledTopTriangle = styled.div`
  border-bottom: 9px solid ${p => p.theme.colors.gray300};
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  height: 0;
  position: absolute;
  right: 10px;
  top: -10px;
  width: 0;
`;

const StyledTopTriangleBorder = styled.div`
  border-bottom: 9px solid white;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  height: 0;
  left: -9px;
  position: absolute;
  top: 1px;
  width: 0;
`;

const StyledFulfillmentElement = styled.div`
  font-weight: ${p => (p.bold ? p.theme.fontWeight.mediumHeavy : 'inherit')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const StyledTrackingInfoWrapper = styled.div`
  align-items: center;
  color: ${p => (p.disabled ? p.theme.colors.gray700 : 'inherit')};
  display: flex;
  font-weight: ${p => p.theme.fontWeight.medium};
  white-space: nowrap;
  width: 100%;

  & div.tracking_number {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & a.tracking_link {
    overflow: hidden;
  }
  & a.tracking_link .externalLink-icon {
    width: 14px;
    margin-left: 4px;
  }
  & a.tracking_link:hover .externalLink-icon {
    fill: ${p => p.theme.colors.brandDarkGreen};
  }
  & div.tracking_link,
  & .externalLink-text {
    font-weight: ${p => p.theme.fontWeight.medium};
    text-overflow: ellipsis;
  }
`;

const StyledTrackingLabel = styled.div`
  margin-right: 0.25rem;
`;

const StyledFulfillmentWrapper = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

/**
 *  Due to the wide variety of ways people send us the transaction data, we have to use `status` as the
 *  main "order status" attribute with fallback on `orderStatus`, which is also very common
 */
ExpandedOrderTransactionBase.propTypes = {
  attributes: PropTypes.shape({
    actions: PropTypes.array,
    orderNumber: PropTypes.string,
    orderStatus: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
      })
    ),
    fulfillments: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        estimatedDeliveryDate: PropTypes.string,
        productIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        status: PropTypes.string,
        trackingNumber: PropTypes.string,
        trackingUrl: PropTypes.string,
      })
    ),
    status: PropTypes.string,
  }).isRequired,
  customerId: PropTypes.string,
  transactionDef: PropTypes.instanceOf(OrderTransactionDef).isRequired,
  onOpenActionForm: PropTypes.func.isRequired,
  shouldHideActions: PropTypes.bool.isRequired,
  shouldRenderImages: PropTypes.bool.isRequired,
};

ShipmentTrackingInfo.propTypes = {
  trackingNumber: PropTypes.string,
  trackingUrl: PropTypes.string,
};

function mapExecuteToProps(executeAction) {
  return {
    onOpenActionForm: (customerId, action) => executeAction(GetExternalActionForm, { customerId, action }),
  };
}

function mapStateToProps({ getProvider, isFeatureEnabled }, { attributes }) {
  let shouldHideActions = false;
  if (_.has(attributes, 'integrationId')) {
    const integration = getProvider('integrations').findBy({ id: attributes.integrationId });
    if (!integration || !integration.enabled || !_.get(integration, 'actionSettings.enabled', false)) {
      shouldHideActions = true;
    }
  }

  return {
    customerId: getProvider('currentLocation').get().customerId,
    shouldHideActions,
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(ExpandedOrderTransactionBase);
