import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as linkify from 'linkifyjs';

class Linkifier extends React.Component {
  constructor(props) {
    super(props);

    this.parseCounter = 0;
  }

  getMatches(string) {
    return linkify.find(string);
  }

  parseString(string) {
    let elements = [];
    if (string === '') {
      return elements;
    }

    const matches = this.getMatches(string);
    if (!matches) {
      return string;
    }

    let lastIndex = 0;
    matches.forEach((match, idx) => {
      // Push the preceding text if there is any
      if (match.start > lastIndex) {
        elements.push(string.substring(lastIndex, match.start));
      }

      if (match.type !== 'url') {
        elements.push(match.value);
      } else {
        elements.push(
          <TruncatedLink
            className={this.props.linkClassName}
            key={`parse${this.parseCounter}match${idx}`}
            text={match.value}
            url={match.href}
          />
        );
      }
      lastIndex = match.end;
    });

    if (lastIndex < string.length) {
      elements.push(string.substring(lastIndex));
    }

    return elements.length === 1 ? elements[0] : elements;
  }

  parse(children) {
    let parsed = children;

    if (typeof children === 'string') {
      parsed = this.parseString(children);
    } else if (React.isValidElement(children) && children.type !== 'a' && children.type !== 'button') {
      parsed = React.cloneElement(
        children,
        { key: `parse${++this.parseCounter}` },
        this.parse(children.props.children)
      );
    } else if (children instanceof Array) {
      parsed = children.map(child => {
        return this.parse(child);
      });
    }

    return parsed;
  }

  render() {
    this.parseCounter = 0;
    const parsedChildren = this.parse(this.props.children);

    return (
      <span className={this.props.className} data-aid={this.props['data-aid']}>
        {parsedChildren}
      </span>
    );
  }
}

function TruncatedLink({ className, text, url }) {
  if (text.length > 200) {
    return (
      <span>
        <TruncateLink className={className} href={url} rel="noopener noreferrer" target="_blank">
          {text}
        </TruncateLink>
      </span>
    );
  }

  return (
    <OverflowLink className={className} href={url} rel="noopener noreferrer" target="_blank">
      {text}
    </OverflowLink>
  );
}

const TruncateLink = styled.a`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const OverflowLink = styled.a`
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
`;

Linkifier.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  component: PropTypes.any,
  linkClassName: PropTypes.string,
  properties: PropTypes.object,
  truncateLength: PropTypes.number,
};

Linkifier.defaultProps = {
  component: 'a',
  properties: {
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  truncateLength: 75,
};

export default Linkifier;
