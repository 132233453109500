import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import { H1 } from 'components/common/headers';
import CopyIcon from 'components/lib/icons/copy_icon';
import PageLayout from 'components/page_layout';
import TrashIcon from 'components/lib/icons/trash_icon';

export function AdminPage({ children, className, ...rest }) {
  return (
    <AdminPageLayout className={classnames('adminPage', className)} {...rest}>
      {children}
    </AdminPageLayout>
  );
}

const AdminPageLayout = styled(PageLayout)`
  overflow: auto;
  position: relative;
`;

export function AdminView({ children, className, ...rest }) {
  return (
    <div className={classnames('adminView', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminViewIntegrations({ children, className, ...rest }) {
  return (
    <div className={classnames('adminView-integrations', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminHeader({ children, className, ...rest }) {
  return (
    <div className={classnames('adminHeader', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminTitle({ children, className, ...rest }) {
  return (
    <H1 data-aid={classnames('adminTitle', className)} {...rest}>
      {children}
    </H1>
  );
}

export function AdminList({ children, className, ...rest }) {
  return (
    <div className={classnames('adminList', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminListItem({ children, className, ...rest }) {
  return (
    <div className={classnames('adminListItem', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminListItemHeader({ children, className, ...rest }) {
  return (
    <div className={classnames('adminListItemHeader', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminListItemBody({ children, className, ...rest }) {
  return (
    <div className={classnames('adminListItemBody', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminListItemMeta({ children, className, ...rest }) {
  return (
    <div className={classnames('adminListItemMeta', className)} {...rest}>
      {children}
    </div>
  );
}

export function AdminListItemMetaSwap({ children, className }) {
  let childrenArray = React.Children.toArray(children);
  return (
    <div className={classnames('adminListItemMetaSwap', className)}>
      <div className={classnames('adminListItemMetaSwap-default')}>{childrenArray[0]}</div>
      <div className={classnames('adminListItemMetaSwap-hovered')}>{childrenArray[1]}</div>
    </div>
  );
}
AdminListItemMetaSwap.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired, // length 2
  className: PropTypes.string,
};

export function AdminCopy({ className, onClick }) {
  let classNames = classnames('adminCopy', className);
  return (
    <div className={classNames} onClick={onClick}>
      <CopyIcon className="adminCopy-icon" />
      <span className="adminCopy-text">Copy</span>
    </div>
  );
}

AdminCopy.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export function AdminTrash({ className, onClick, ...rest }) {
  let classNames = classnames('adminTrash', className);
  return <TrashIcon className={classNames} onClick={onClick} {...rest} />;
}

AdminTrash.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
