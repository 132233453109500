import _ from 'lodash';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Attachments from '../attachments/attachments';
import BubbleContent from '../content/bubble_content';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import CustomerProfile from 'models/customer_profile';
import EmailBody from './email_body';
import ExpandableList from './expandable_list';
import FlippableChevron from '../content/flippable_chevron';
import getNormalizedEmailAddress from 'scripts/lib/get_normalized_email_address';
import InformationFillIcon from 'components/common/icons/fill/information-fill';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import QuotedContentIcon from 'components/common/icons/quoted_content_icon';
import RedactedItem from 'components/customer/conversation_history/conversation_items_v2/redacted_item';
import ViewOriginalHtmlModal from './view_original_html_modal';

export class EmailContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddresses: false,
      showQuotedText: this.shouldExpandOnRender(),
    };

    _.bindAll(this, ['toggleShowAddresses', 'toggleShowQuotedText']);
  }

  componentDidMount() {
    this.mountMeasure = fastdom.measure(() => {
      if (!this.content) {
        return;
      }
      this.height = this.content.getBoundingClientRect().height;
      delete this.mountMeasure;
    });
  }

  componentDidUpdate() {
    if (this.content) {
      this.updateMeasure = fastdom.measure(() => {
        if (!this.content) {
          return;
        }
        let { height } = this.content.getBoundingClientRect();
        if (height !== this.height) {
          this.props.onHeightChange && this.props.onHeightChange();
        }
        this.height = height;
        delete this.updateMeasure;
      });
    }
  }

  componentWillUnmount() {
    this.mountMeasure && fastdom.clear(this.mountMeasure);
    this.updateMeasure && fastdom.clear(this.updateMeasure);
    delete this.mountMeasure;
    delete this.updateMeasure;
  }

  render() {
    return (
      <>
        <BubbleContent
          className={classnames('emailItem-content', this.props.className)}
          data-aid={`emailItem-content-${this.props.item?.id}`}
          item={this.props.item}
          onRef={node => (this.content = node)}
        >
          {_.get(this.props.item, 'content.isRedacted') ? this.renderRedactedContent() : this.renderContent()}
        </BubbleContent>
        <ViewOriginalHtmlModal email={this.email} />
      </>
    );
  }

  renderContent() {
    return (
      <>
        {this.renderAutoreplyText()}
        <div className="emailItem-header">
          <bdi>
            <div className="emailItem-subject">
              {this.renderSubjectText()}
              {this.renderAddressToggle()}
            </div>
          </bdi>
          {this.renderAddresses()}
        </div>
        {this.renderEmailContent()}
        {this.renderInfoMessages()}
      </>
    );
  }

  renderRedactedContent() {
    return (
      <>
        {this.renderAutoreplyText()}
        <RedactedItem />
      </>
    );
  }

  renderAutoreplyText() {
    if (this.props.item.initiator.type !== Communicator.AUTOMATED) {
      return null;
    }

    return <div className="emailItem-autoreply">Autoreply</div>;
  }

  renderSubjectText() {
    return this.email.subject || 'No Subject';
  }

  renderAddressToggle() {
    return (
      <FlippableChevron
        className="emailItem-subject-chevron"
        isDownwards={!this.state.showAddresses}
        onClick={this.toggleShowAddresses}
      />
    );
  }

  renderAddresses() {
    if (!this.state.showAddresses) {
      return null;
    }

    const { from, to, cc, bcc } = this.email;
    return (
      <div className="emailItem-heading-metadata" data-aid="email-item-recipients">
        <div>From: {from}</div>
        <ExpandableList className="emailItem-heading-metadata-list" list={to} prefix="To:" />
        <ExpandableList className="emailItem-heading-metadata-list" list={cc} prefix="Cc:" />
        <ExpandableList className="emailItem-heading-metadata-list" list={bcc} prefix="Bcc:" />
      </div>
    );
  }

  renderEmailContent() {
    return (
      <bdi>
        <div className="emailItem-body">
          <EmailBody emailPlainTextFromHtml={this.props.emailPlainTextFromHtml} item={this.props.item} />
          {this.renderAttachments()}
          {this.renderQuotedText()}
        </div>
      </bdi>
    );
  }

  renderAttachments() {
    const attachments = _.filter(getEmailAttachments(this.props.item), a => !a.isInline);
    return <Attachments attachments={attachments} itemId={this.props.item.id} pathPrefix="email" />;
  }

  renderQuotedText() {
    if (!this.email.hasQuotedContent()) {
      return null;
    }

    let expandedQuotedText = null;
    if (this.state.showQuotedText) {
      const plainText = this.email.getQuotedPlainText();
      expandedQuotedText = (
        <InlineAnnotatedContentContainer
          id={this.props.item.id}
          isRedacted={this.props.item.content.isRedacted}
          text={plainText}
        />
      );
    }

    return (
      <div data-aid="emailItem-quotedText-container">
        <QuotedContentIcon onClick={this.toggleShowQuotedText} onMouseDown={this.preventDefault} />
        {expandedQuotedText}
      </div>
    );
  }

  renderInfoMessages() {
    return (
      <EmailItemFooter>
        {!this.includesCustomerAddress() ? (
          <FooterMessage data-aid="emailContent-customer-excluded">
            <StyledInfoIcon />
            The customer was not included in this message, which may happen if the customer’s email address was not
            listed on their Gladly profile at that time
          </FooterMessage>
        ) : null}

        {!this.email.bodyHtmlStripped && this.props.item.initiator.type === Communicator.AGENT ? (
          <FooterMessage data-aid="emailContent-formatting-message">
            <StyledInfoIcon />
            This email was sent with additional formatting
          </FooterMessage>
        ) : null}
      </EmailItemFooter>
    );
  }

  includesCustomerAddress() {
    const customerEmails =
      this.props.profile && this.props.profile.emails.map(email => getNormalizedEmailAddress(email.original, true));
    if (!customerEmails || customerEmails.length === 0) {
      return false;
    }

    const fromCustomer = this.props.item.initiator.type === Communicator.CUSTOMER;
    const sentToCustomer = this.email.isSentToAny(customerEmails);

    return fromCustomer || sentToCustomer;
  }

  shouldExpandOnRender() {
    // do not override email collapse state if email is outbound
    if (this.props.item.initiator.type !== Communicator.CUSTOMER) {
      return false;
    }

    const subject = this.email.subject;
    if (!subject) {
      return true;
    }
    // http://regexr.com/3egde - explanation of this regex
    const subjectRegEx = /^\s*([[(] *)?(RE|FWD?) *([-:\]][ :\])-]*|$)|\]+ *$/gim;
    return subject.search(subjectRegEx) === -1;
  }

  toggleShowQuotedText() {
    this.setState({ showQuotedText: !this.state.showQuotedText });
  }

  toggleShowAddresses() {
    this.setState({ showAddresses: !this.state.showAddresses });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  get email() {
    return this.props.item.content;
  }
}

function getEmailAttachments(item) {
  let email = item.content;
  return (email.attachments && (item.isAgentItem() ? email.regularAttachments() : email.attachments)) || [];
}

const StyledInfoIcon = styled(InformationFillIcon)`
  display: inline-flex;
  fill: ${p => p.theme.colors.gray600};
  height: 15px;
  margin-right: 5px;
  vertical-align: middle;
`;

const FooterMessage = styled.div`
  vertical-align: middle;
`;

const EmailItemFooter = styled.div`
  color: ${p => p.theme.colors.gray600};
  margin-top: 10px;
`;

EmailContent.propTypes = {
  className: PropTypes.string,
  emailPlainTextFromHtml: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onHeightChange: PropTypes.func,
  profile: PropTypes.instanceOf(CustomerProfile).isRequired,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  return {
    ...props,
    emailPlainTextFromHtml: isFeatureEnabled('emailPlainTextFromHtml'),
    profile: getProvider('profile').get(),
  };
}

const EmailContentContainer = connect(mapStateToProps)(EmailContent);

EmailContentContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onHeightChange: PropTypes.func,
};
export default EmailContentContainer;
