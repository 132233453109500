import { Factory } from 'rosie';

Factory.define('Relationship')
  .extend('Uuid')
  .attr('name', 'RELATIONSHIP');

Factory.define('RelationshipWithDefaults')
  .extend('Relationship')
  .attr('customerId', 'default-customerId')
  .attr('customerProfile', () => Factory.attributes('LinkedCustomerProfile', { name: 'Name' }))
  .attr('labelId', 'SPOUSE');
