import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from './confirmation_modal';
import ProvidersMixin from 'components/lib/providers_mixin';

const TaskConfirmationModal = createReactClass({
  mixins: [ProvidersMixin],

  propTypes: {
    onConfirm: PropTypes.func.isRequired,
  },

  render() {
    let modalText = this.getModalText();

    return <ConfirmationModal body={modalText} onConfirm={this.props.onConfirm} />;
  },

  getModalText() {
    let modal = this.getProvider('modal').get();
    let modalText = 'Your task must have an assignee, a description, and a due date.';
    if (!modal.hasAssignee) {
      modalText = 'Your task must have an assignee.';
    } else if (!modal.hasDescription) {
      modalText = 'Your task must have a description.';
    } else if (!modal.hasDueDate) {
      modalText = 'Your task must have a due date.';
    }

    return modalText;
  },
});

export default TaskConfirmationModal;
