import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';
import PropTypes from 'prop-types';

import ActionMixin from 'components/lib/action_mixin';
import AgentGroups from './agent_groups';
import AppFeaturesMixin from '../lib/app_features_mixin';

const TeamInboxesMenu = createReactClass({
  propTypes: {
    binding: PropTypes.any,
    className: PropTypes.string,
  },

  mixins: [ActionMixin, AppFeaturesMixin, Morearty.Mixin],

  render() {
    if (!this.shouldShowGroupInboxes()) {
      return null;
    }

    let classes = classnames('inboxMenu', this.props.className);
    return (
      <div className={classes}>
        <AgentGroups teamInboxCounts={this.props.teamInboxCounts} />
      </div>
    );
  },

  shouldShowGroupInboxes() {
    return this.isFeatureEnabled('groupInboxes');
  },
});

export default TeamInboxesMenu;
