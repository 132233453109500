import qs from 'qs';

import { after } from '../../infrastructure/backends/http_client';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';

export default class CurrentAgentProfileHttpGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
  }

  init({ orgId, agentId }) {
    this.orgId = orgId;
    this.agentId = agentId;
  }

  get version() {
    return '3';
  }

  get broadcastTopic() {
    return `v${this.version}/orgs/${this.orgId}/agent-profiles/${this.agentId}`;
  }

  get _http() {
    return this.backend.axios();
  }

  get url() {
    return `/api/v${this.version}/orgs/${this.orgId}/agent-profiles/${this.agentId}`;
  }

  /* mqtt methods and handlers */

  request() {
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.get.bind(this));
  }

  unsubscribe() {
    this.mqttClient.unsubscribe(this.broadcastTopic, this.handleBroadcast.bind(this));
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleAgentProfile', envelope.payload);
  }

  /* http methods and handlers */

  get() {
    return after(this._http.get(this.url), this.onFetch.bind(this));
  }

  update(agentAttrs) {
    return after(this._http.put(this.url, agentAttrs), this.onUpdate.bind(this));
  }

  forceUpdate(agentAttrs) {
    const url = `${this.url}?${qs.stringify({ claimEndpoint: true })}`;
    return after(this._http.put(url, agentAttrs), this.onUpdate.bind(this));
  }

  onFetch(err, res) {
    if (err) {
      this.notifyObservers('handleRequestError', err);
      return;
    }
    if (res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleAgentProfile', res.data);
    } else {
      this.notifyObservers('handleRequestError', res.data);
    }
  }

  onUpdate(err, res) {
    if (err) {
      this.notifyObservers('handleSetErrors', err);
      return;
    }
    if (res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleSetSuccess', res.data);
    } else {
      this.notifyObservers('handleSetErrors', res.data);
    }
  }
}

mixin(CurrentAgentProfileHttpGateway.prototype, Observable);
