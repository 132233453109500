import styled from 'styled-components';

import Button from 'components/common/button';

const Box = styled.div`
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.large};
  padding: 16px;
  width: 100%;
  z-index: 1;
`;
const Content = styled.div`
  margin-bottom: 12px;
`;
const Options = styled.div`
  display: flex;
  gap: 8px;
`;

const LoadingContent = styled.div`
  color: ${p => p.theme.colors.gray700};
  display: flex;
  justify-content: center;
`;

const BaseButton = styled(Button).attrs({ type: Button.Types.OUTLINE })`
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.small};
  color: ${p => p.theme.colors.black};
  font-size: ${p => p.theme.fontSize.small};
  padding: 2px 8px;

  &:active {
    background: ${p => p.theme.colors.gray300};
    color: ${p => p.theme.colors.black};
  }
`;

const PrimaryButton = styled(BaseButton)`
  color: ${p => p.theme.colors.green400};
  border: 1px solid ${p => p.theme.colors.green400};

  &:active {
    background-color: ${p => p.theme.colors.green400};
    color: ${p => p.theme.colors.white};
  }
`;

const SecondaryButton = styled(BaseButton)``;

export { Box, Content, Options, LoadingContent, PrimaryButton, SecondaryButton };
