import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <rect height="7" rx=".7" width="1.4" x="2.8" />
    <rect height="7" rx=".7" transform="rotate(90 7 2.8)" width="1.4" x="7" y="2.8" />
    <rect height="7" rx=".7" transform="rotate(90 16 2.8)" width="1.4" x="16" y="2.8" />
    <rect height="7" rx=".7" transform="rotate(90 16 10)" width="1.4" x="16" y="10" />
    <rect height="7" rx=".7" transform="rotate(90 16 13)" width="1.4" x="16" y="13" />
    <rect height="8.25" rx=".825" transform="rotate(45 5.833 9)" width="1.65" x="5.833" y="9" />
    <rect height="8.25" rx=".825" transform="rotate(135 7 14.833)" width="1.65" x="7" y="14.833" />
  </Icon>
);
