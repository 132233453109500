import _ from 'lodash';
import qconsole from 'scripts/lib/qconsole';
import TopicAncestry from 'models/topic_ancestry';
import TopicAncestryUpdater from 'actions/topics/lib/topic_ancestry_updater';

export default class TopicHierarchyGatewayObserver {
  constructor(context) {
    this.context = context;
    this.topicAncestryUpdater = new TopicAncestryUpdater(context);
  }

  get store() {
    return this.context.stores.topicHierarchy;
  }

  onFetchAllSuccess(topicAncestryDtos) {
    this.store.resetLoading();
    this.store.set(_.map(topicAncestryDtos, topicAncestryDto => TopicAncestry.fromJs(topicAncestryDto)));
    this.topicAncestryUpdater.update();
  }

  onFetchAllError(error) {
    this.store.resetLoading();
    qconsole.error('error fetching topic hierarchy: ', error);
  }

  onBroadcast(topicAncestryDto) {
    if (!this.store.findBy({ id: topicAncestryDto.id })) {
      this.store.add(TopicAncestry.fromJs(topicAncestryDto));
      this.topicAncestryUpdater.update();
    }
  }

  onBroadcastDelete(dto, params) {
    this.store.remove(params.topicHierarchyId);
    this.topicAncestryUpdater.update();
  }
}
