import _ from 'lodash';
import Assignee from 'models/assignee';
import UpdateComposition from 'actions/composer/update_composition';

export default class UpdateTaskCompositionAssignee {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, customerId, agentId, routingGroupId }) {
    if (routingGroupId) {
      const assignee = new Assignee({
        agentId,
        routingGroupId,
      });
      const { compositions } = this.context.stores.customers.storesFor(customerId);
      const composition = compositions.findBy({ id: compositionId });

      this.context.executeAction(UpdateComposition, {
        compositionId,
        customerId,
        attrs: {
          assignee,
        },
      });

      if (!_.isEqual(composition.assignee, assignee)) {
        const allErrors = compositions.getErrors(composition.id);
        const filteredErrors = _.filter(allErrors, err => err.attr !== 'assignee');
        compositions.setErrors(composition.id, filteredErrors);
      }
    }
  }
}
