import React from 'react';
import Icon from 'components/lib/icons/icon';

export default function MissingImageIcon(props) {
  return (
    <Icon viewBox="0 0 16 14" {...props}>
      <path
        clipRule="evenodd"
        d="M14 1H2C1.44772 1 1 1.44772 1 2V12C1 12.5523 1.44772 13 2 13H14C14.5523 13 15 12.5523 15 12V2C15 1.44772 14.5523 1 14 1ZM2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.895431 14 2 14H14C15.1046 14 16 13.1046 16 12V2C16 0.895431 15.1046 0 14 0H2ZM6 4.5C6 5.32843 5.32843 6 4.5 6C3.67157 6 3 5.32843 3 4.5C3 3.67157 3.67157 3 4.5 3C5.32843 3 6 3.67157 6 4.5ZM2.14645 9.85355L3.74517 8.25483C3.89735 8.10265 4.12984 8.06492 4.32233 8.16117L5.67767 8.83883C5.87016 8.93508 6.10265 8.89735 6.25483 8.74517L9.69337 5.30663C9.86931 5.13069 10.1479 5.11089 10.3469 5.26019L13.8 7.85C13.9259 7.94443 14 8.09262 14 8.25V11.5C14 11.7761 13.7761 12 13.5 12H2.5C2.22386 12 2 11.7761 2 11.5V10.2071C2 10.0745 2.05268 9.94732 2.14645 9.85355Z"
        fill="#B3B3B3"
        fillRule="evenodd"
      />
    </Icon>
  );
}
