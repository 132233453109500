import CustomerView from 'models/location/customer_view';

export default function requestCustomerExtension(context, customerId) {
  context.gateways.customerExtension.update(customerId);

  let currentLocation = context.stores.currentLocation.get();
  if (currentLocation instanceof CustomerView && currentLocation.customerId === customerId) {
    currentLocation.setCustomerExtensionPending();
    context.stores.currentLocation.set(currentLocation);
  }
}
