import _ from 'lodash';
import Transaction from 'models/transaction';

export default {
  fromDto: flatTransaction => {
    let transactionAttributes = _.omit(flatTransaction, ['id', 'type']);

    return new Transaction({ id: flatTransaction.id, type: flatTransaction.type, attributes: transactionAttributes });
  },
};
