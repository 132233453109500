import PropTypes from 'prop-types';
import React from 'react';

import CustomerProfileDef from 'models/customer_profile_def';
import { getAttributeDefsToShow, renderCustomAttributes } from '../lib/custom_attributes_helper';
import { ProfileCardSubgroupDef } from 'models/configuration/profile_card_def';

export default function CustomAttributesSubgroup({ subgroupDef, customerProfileDef, customAttributes, location }) {
  if (!customerProfileDef) {
    return null;
  }

  const subgroupAttrDefs = getAttributeDefsToShow(subgroupDef, customerProfileDef, customAttributes);
  if (!subgroupAttrDefs.length) {
    return null;
  }

  return (
    <div className="customAttributesSubgroup">
      <div className="customAttributesSubgroup-label">{subgroupDef.label}</div>
      {renderCustomAttributes(subgroupAttrDefs, customAttributes, location)}
    </div>
  );
}

CustomAttributesSubgroup.propTypes = {
  subgroupDef: PropTypes.instanceOf(ProfileCardSubgroupDef).isRequired,
  customerProfileDef: PropTypes.instanceOf(CustomerProfileDef),
  customAttributes: PropTypes.object,
  location: PropTypes.string,
};
