import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import AnswerPanelIcon from 'components/lib/icons/answer_panel_icon_with_circle';
import Attachments from './attachments';
import ComposerContext from 'components/composer/contexts/composer_context';
import CustomerContext from 'components/customer/customer_context';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import ToggleAnswerPanel from 'actions/answers/toggle_answer_panel';
import ToggleComposerVisibility from 'actions/composer/toggle_composer_visibility';
import Tooltip from 'components/common/tooltip';
import UpdateComposition from 'actions/composer/update_composition';
import { useAnswers } from 'components/text_editor/plugins/answers';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import { useThrottled } from 'components/hooks/debounce_hooks';
import validateEditor from 'components/text_editor/validators/external_validator';

const EditorScrollContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  position: relative;

  .slateTextEditor {
    min-height: 23vh;
    padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.medium};
  }
`;

const EditorContentContainer = styled.div`
  display: flex;
  margin: 0 -4px;
  min-height: 0;
`;

/**
 * EditorContainer wraps an editor component in a scrollable div and will optionally render attachments.
 */
export function EditorContainer({ children, includeAttachments }) {
  const scrollerRef = useRef(null);

  return (
    <EditorContentContainer data-aid="slate-editor-container" ref={scrollerRef}>
      <AnswerMenuButton />
      <EditorScrollContainer>
        {children}
        {includeAttachments ? <Attachments scrollerRef={scrollerRef} /> : null}
      </EditorScrollContainer>
    </EditorContentContainer>
  );
}

EditorContainer.propTypes = {
  children: PropTypes.node,
  includeAttachments: PropTypes.bool,
};

const AnswerPanelIconWrapper = styled.div`
  cursor: pointer;
  padding-top: 3px;
`;

const ToggleAnswerPanelMessage = styled.span`
  display: inline-block;
`;
const Hotkey = styled.span`
  color: #999;
  padding-left: 8px;
`;
const message = (
  <ToggleAnswerPanelMessage>
    Answer Panel
    <Hotkey>Ctrl-/</Hotkey>
  </ToggleAnswerPanelMessage>
);

const AnswerMenuButton = React.memo(function AnswerMenuButtonBase() {
  const { customerId, compositionId, latestConversationId } = useContext(CustomerContext);
  const executeAction = useExecuteAction();
  const toggleAnswerPanel = useCallback(() => {
    analytics.track('Composer Answer Panel Button Clicked', {
      customerId,
      compositionId,
      conversationId: latestConversationId,
    });
    executeAction(ToggleAnswerPanel, {
      customerId,
      compositionId,
      conversationId: latestConversationId,
    });
  }, [executeAction, customerId, compositionId, latestConversationId]);

  return (
    <Tooltip className="answerPanelToggleButton" delay={500} message={message} position="bottom">
      <AnswerPanelIconWrapper onClick={toggleAnswerPanel}>
        <AnswerPanelIcon className="answerPanelIcon-button" />
      </AnswerPanelIconWrapper>
    </Tooltip>
  );
});

/**
 * Every editor in a composer should have an associated `useEditor` call that registers the editor with the composer.
 * Ensures that the editor's text content will autosave and submit correctly.
 *
 * @param {string} attr - Corresponds to `composition.content.${attr}`, where the html will be stored.
 * @param {{current: { editorValues: Object }}} composerRef - the ref created by `useComposer`
 * @param {{ attr: string, onChange: Function, serializer: { serialize: Function }, validator: Function }} options
 *
 * @returns {Object} props to be passed to `Editor` component
 */
export function useEditor(
  attr,
  composer,
  { attr: finalAttr, autoFocus, initialEditorValue, onChange, serializer, shouldInsertAnswers, validator } = {}
) {
  autoFocus = autoFocus == null ? true : autoFocus;
  shouldInsertAnswers = shouldInsertAnswers == null ? true : shouldInsertAnswers;

  if (!validator) {
    validator = validateEditor;
  }

  const [composerProps, composerRef] = composer;

  const composerContext = useContext(ComposerContext);
  const compositionId = _.get(composerContext, 'composition.id');
  const customerId = _.get(composerContext, 'composition.customerId');

  // Register the attr / serializer / validator that the composer ref will use for this editor when submitted.
  useEffect(() => {
    composerRef.current.attrs[attr] = finalAttr;
    composerRef.current.validators[attr] = validator;
    composerRef.current.serializers[attr] = serializer;
  }, [composerRef, attr, serializer, validator, finalAttr]);

  // Initialize the editorValue once with the current composition content
  const [editorValue, setEditorValue] = useState(() => {
    const value =
      initialEditorValue || FullSerializer.deserialize(_.get(composerContext, `composition.content.${attr}`) || '');
    composerRef.current.editorValues[attr] = value;
    return value;
  });

  // updateComposition is the base updater. We don't want to call this every time the editor changes though
  // because serializing is expensive, and updating the composition in supernova is as well.
  const executeAction = useExecuteAction();
  const updateComposition = useCallback(
    newEditorValue => {
      executeAction(UpdateComposition, {
        compositionId,
        attrs: {
          [attr]: FullSerializer.serialize(newEditorValue),
        },
        customerId,
      });
    },
    [executeAction, compositionId, attr, customerId]
  );

  // Since updating is expensive, we throttle the composition updating.
  const throttledUpdateComposition = useThrottled(updateComposition, 5000);

  // But we want to immediately flush the changes when an agent blurs the composition.. this ensures that if
  // they click to go away, they don't lose any of their work.
  const onBlur = useCallback(
    (evt, editor, next) => {
      // Ensure that `onChange` fires before `onBlur` so that the editor re-renders with the latest value.
      next();
      setImmediate(() => throttledUpdateComposition.flush());
    },
    [throttledUpdateComposition]
  );

  // When the editor state changes, we do a few things:
  //   1. Set the editor state on the composerRef. We do this because when the agent submits, the update
  //      may still be throttled and therefore the composition in the store may not have the most recent
  //      editorValue. So we need to keep track of the latest editor value _somewhere_.. and that somewhere
  //      is on the composerRef. We then pass all of these latest editor values up to the submit action
  //      when the user click submit.
  //   2. setState with the latest editor state. This ensures the component re-renders with the latest
  //      editorState value.
  //   3. We trigger a throttled update to the composition.. this is what "autosaves".
  const fullOnChange = useCallback(
    ({ value }) => {
      composerRef.current.editorValues[attr] = value;
      setEditorValue(value);
      throttledUpdateComposition(value);
      onChange && onChange({ value });
    },
    [attr, composerRef, onChange, throttledUpdateComposition]
  );

  // We need to create the ref for the editor at the highest possible level so that we can, say, pass this
  // down to EditorStyles so it can perform commands on the editor (e.g. to bold or italicize something).
  const editorRef = useRef(null);

  // Register the editor into the composerRef so we have access directly to editors in, say, onSubmit
  useEffect(() => {
    composerRef.current.editorRefs[attr] = editorRef;
  }, [composerRef, attr]);

  const isMinimized = composerContext.isMinimized;
  useEffect(() => {
    if (!isMinimized && autoFocus) {
      editorRef.current && editorRef.current.focus();
    } else {
      editorRef.current && editorRef.current.blur();
    }
  }, [isMinimized]);

  const onToggleHide = useCallback(() => executeAction(ToggleComposerVisibility), [executeAction]);

  useAnswers(shouldInsertAnswers ? editorRef.current : null, fullOnChange);

  return {
    autoFocus,
    editorRef,
    onChange: fullOnChange,
    onBlur,
    onSubmit: composerProps.onSubmit,
    onToggleHide,
    value: editorValue,
  };
}
