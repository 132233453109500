import _ from 'lodash';

import AnalyticsTools from 'scripts/lib/analytics/domain/analytics_tools';
import ErrorReporter from 'scripts/infrastructure/error_reporter';

const USER_PROPERTIES = [
  'id',
  'orgId',
  'activeExperimentalEditor',
  'hasServiceWorker',
  'loginDomain',
  'name',
  'email',
  'numTabsOpen',
  'roles',
  'displayConversationSummaryControls',
  'usesPluginGrammarly',
  'loginDomain',
  'isSSOEnabled',
  'roles',
];

export class Analytics {
  constructor(defaultAnalyticsTools = [AnalyticsTools.AMPLITUDE, AnalyticsTools.FULLSTORY]) {
    this.defaultAnalyticsTools = defaultAnalyticsTools;
    this.defaultAnalyticsEnabled = false;
  }

  configure(adapters) {
    if (this.configured) {
      return;
    }
    this.adapters = adapters;
    this.defaultAdapters = _.pick(adapters, this.defaultAnalyticsTools);
    _.forOwn(this.adapters, adapter => adapter.init());
    this.configured = true;
  }

  isConfigured() {
    return this.configured;
  }

  setUser(user) {
    if (!user) {
      return;
    }
    if (!this.currentUser) {
      this.currentUser = _.pick(user, USER_PROPERTIES);
    } else {
      this.currentUser = _.assign(this.currentUser, _.pick(user, USER_PROPERTIES));
    }
    if (this.defaultAnalyticsEnabled) {
      _.forEach(this.defaultAdapters, adapter => adapter?.setUser(this.currentUser));
    }
  }

  updateUser(properties) {
    let userProperties = _.pick(properties, USER_PROPERTIES);
    this.currentUser = _.assign(this.currentUser || {}, userProperties);
    if (this.defaultAnalyticsEnabled) {
      _.forEach(this.defaultAdapters, adapter => adapter?.updateUser(properties));
    }
  }

  track(eventName, eventProperties) {
    if (this.defaultAnalyticsEnabled) {
      _.forOwn(this.defaultAdapters, adapter => adapter?.track(eventName, eventProperties));
    }
  }

  page(location) {
    this.track('Page View', { url: location.href.split('?')[0], path: location.pathname, host: location.host });
  }

  trackSnowplow(eventSchema, data = {}, contexts = []) {
    this.adapters[AnalyticsTools.SNOWPLOW]?.track(eventSchema, data, contexts);
  }

  setUserSnowplow({ id, orgId }) {
    this.adapters[AnalyticsTools.SNOWPLOW]?.setUser({ id, orgId });
  }

  enableDefault() {
    if (!this.configured) {
      ErrorReporter.reportError('Attempting to enable analytics which have not been properly configured');
      return;
    }
    if (!this.defaultAnalyticsEnabled) {
      _.forOwn(this.defaultAdapters, adapter => adapter?.enable());
      if (this.currentUser) {
        _.forOwn(this.defaultAdapters, adapter => {
          adapter?.setUser(this.currentUser);
        });
      }
      this.defaultAnalyticsEnabled = true;
    }
  }

  disableDefault() {
    this.defaultAnalyticsEnabled = false;
    this.currentUser = null;
    _.forOwn(this.defaultAdapters, adapter => adapter?.disable());
  }

  forceDisable(name) {
    if (name === AnalyticsTools.FULLSTORY) {
      this.fullstoryAdapter && this.fullstoryAdapter.forceDisable();
    }
  }
}

const analytics = new Analytics();

export default analytics;
