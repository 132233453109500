import qconsole from 'scripts/lib/qconsole';
import VoiceConfiguration from 'models/voice_configuration';

export default class VoiceConfigurationGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleFetchConfiguration(configurationDto) {
    const oldConfiguration = this.context.stores.voiceConfiguration.get();
    this.context.stores.voiceConfiguration.resetPending();
    const configuration = VoiceConfiguration.fromJs(configurationDto);
    this.context.stores.voiceConfiguration.set(configuration);

    const didConfigurationChange =
      !oldConfiguration || oldConfiguration.hotelingEnabled !== configuration.hotelingEnabled;
    if (didConfigurationChange && configuration.hotelingEnabled) {
      this.context.gateways.stationConfiguration.request();
      this.context.stores.stationConfigurations.setLoading();
    }
  }

  handleFetchConfigurationError(errorDto) {
    qconsole.log(`Failed to fetch '${VoiceConfiguration.name}': ${JSON.stringify(errorDto)}`);
  }

  handleConfigurationDelete() {
    this.context.stores.voiceConfiguration.remove();
  }

  handleDeleteErrors(errorDto) {
    qconsole.log(`Failed to delete '${VoiceConfiguration.name}': ${JSON.stringify(errorDto)}`);
  }

  handleConfigurationUpdate(configurationDto) {
    qconsole.log(`Updated configuration for '${VoiceConfiguration.name}'`);
  }

  handleUpdateErrors(errorDto) {
    qconsole.log(`Failed to update '${VoiceConfiguration.name}': ${JSON.stringify(errorDto)}`);
  }
}
