import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';

/**
 * Common implementation of Collection Store save result handlers
 *
 * Compose in a gateway as usual or mix in. If mixing in don't forget to define the `store` property.
 */
export default class CollectionStoreSaveResultHandler {
  constructor(store) {
    this.store = store;
  }

  handleAddErrors(errorsDto) {
    if (this.store.isPendingNew()) {
      this.store.setErrorsForNew(errorsDto.errors.map(ErrorDtoConverter.fromDto));
      this.store.resetPendingNew();
    }
  }

  handleAddSuccess() {
    this.store.commitPendingNew();
  }

  handleUpdateErrors(entityId, errorsDto) {
    if (this.store.isPending(entityId)) {
      this.store.setErrors(entityId, errorsDto.errors.map(ErrorDtoConverter.fromDto));
      this.store.resetPending(entityId);
    }
  }

  handleUpdateSuccess(entityId) {
    this.store.commitPending(entityId);
  }
}
