import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { CallParticipant } from 'components/phone_controls/participants/call_participant';
import connect from 'components/lib/connect';

export const CurrentAgentParticipant = ({ agentParticipant, agentName }) => (
  <CallParticipant
    displayName={`${agentName} (You)`}
    key={agentParticipant.callId}
    participantId={agentParticipant.participantId}
    participantStatus={agentParticipant.status}
    showMenuOnHover={false}
  />
);

CurrentAgentParticipant.propTypes = {
  agentName: PropTypes.string,
  agentParticipant: PropTypes.shape({
    callId: PropTypes.string,
    participantId: PropTypes.string,
    status: PropTypes.string,
  }),
};

function mapStateToProps({ getProvider }) {
  let activeCall = getProvider('activeCall').get();
  let currentAgent = getProvider('currentAgent').get();
  return {
    agentName: currentAgent.name,
    agentParticipant: _.find(activeCall.conversationItem.content.participants, { participantId: currentAgent.id }),
  };
}

export default connect(mapStateToProps, null)(CurrentAgentParticipant);
