import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Assignee from 'models/assignee';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ConversationItemMenuDisplayer from 'components/customer/conversation_history/conversation_items_v2/menu/conversation_item_menu_displayer';
import ConversationItemType from 'models/conversation_item_type';
import { createConversationItemFromItemLink } from 'models/item_link_content';
import { getIsCommentingEnabledForTask } from 'scripts/application/selectors/customer';
import Linkifier from 'components/lib/linkifier';
import LongDateTime from 'components/lib/date/long_date_time';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import SafeHtml from 'components/common/safe_html';
import TaskAnalyticsLocations from 'actions/conversation_item/task/task_analytics_locations';
import TaskComment from './task_comment';
import TaskCommentEditor from './task_comment_editor';
import TaskCommentEditorNew from './task_comment_editor_new';
import TaskHeader from 'components/customer/conversation_history/conversation_items_v2/task/task_header';
import TrackCommentPanelVisibility from 'actions/task/track_comment_panel_visibility';
import { typeReflect } from 'models/conversation_item/content_from_js';
import UpdateTaskAssignee from 'actions/conversation_item/task/update_task_assignee';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import { useOnUnmount } from 'components/hooks/lifecycle_hooks';

export function TaskCommentPanel({
  comments = [],
  currentAgentId,
  isCommentingEnabled,
  isSlateBetaEnabled,
  item,
  lastOpenedAt,
  selectedComment,
}) {
  const executeAction = useExecuteAction();
  const messagesRef = useScrollToBottom(comments.length, selectedComment);
  const itemId = item?.id;

  const onChangeAssignee = useCallback(
    ({ agentId, routingGroupId }) =>
      executeAction(UpdateTaskAssignee, {
        assignee: new Assignee({ agentId: agentId || '', routingGroupId }),
        conversationItemId: itemId,
        location: TaskAnalyticsLocations.SIDE_PANEL,
      }),
    [executeAction, itemId]
  );

  // we want to log the comment panel as OPEN every time it becomes visible
  // or displays a different task (this can happen while it is already open)
  // BUT we only want to log the panel as CLOSED when it closes completely and
  // NOT when it refreshes to show a new task
  let itemIdRef = useRef(itemId);
  useEffect(() => {
    executeAction(TrackCommentPanelVisibility, { itemId, isVisible: true });
    itemIdRef.current = itemId;
  }, [executeAction, itemId]);

  useOnUnmount(() => {
    executeAction(TrackCommentPanelVisibility, { itemId: itemIdRef.current, isVisible: false });
  }, []);

  if (!item) {
    return null;
  }
  const isLink = item.isLink();
  const task = item.content;

  return (
    <Panel data-aid="taskCommentPanel" tabIndex="0">
      {renderTaskDetail()}
      <StyledTaskMessages $currentAgentId={currentAgentId} ref={messagesRef}>
        <TaskContent item={item} />
        {renderComments()}
        {renderEditor()}
      </StyledTaskMessages>
    </Panel>
  );

  function renderTaskDetail() {
    return (
      <StyledDetail>
        <TaskHeader
          isLink={isLink}
          item={item}
          location={TaskAnalyticsLocations.SIDE_PANEL}
          onChangeAssignee={onChangeAssignee}
        />
        <StyledItemMenu hideComment item={item} location={TaskAnalyticsLocations.SIDE_PANEL} shouldShowOverflowMenu />
        <StyledDueDate>
          Due
          <LongDateTime className="taskItem-dueDate-timestamp" timestamp={moment(task.dueAt)} />
        </StyledDueDate>
      </StyledDetail>
    );
  }

  function renderComments() {
    return comments.map(comment => (
      <TaskComment
        {...comment}
        key={comment.id}
        selectedAt={selectedComment && comment.id === selectedComment.id ? selectedComment.at : undefined}
      />
    ));
  }

  function renderEditor() {
    if (!isCommentingEnabled) return null;

    return isSlateBetaEnabled ? (
      <TaskCommentEditorNew itemId={itemId} lastOpenedAt={lastOpenedAt} />
    ) : (
      <TaskCommentEditor itemId={itemId} lastOpenedAt={lastOpenedAt} />
    );
  }
}

const Panel = styled.div`
  box-shadow: ${p => p.theme.boxShadow.small};
  display: flex;
  flex: 0 0 25%;
  flex-direction: column;
  max-width: 360px;
  min-width: 200px;
  outline: none;
  width: 25%;
  z-index: 3;
`;

function useScrollToBottom(numComments, selectedComment) {
  const ref = useRef(null);

  // Only scroll to bottom when we mount or when someone else adds a comment, but don't do this if we
  // have a currently selected comment, as we'll want to scroll to that instead.
  useEffect(() => {
    if (numComments && !selectedComment) {
      const scrollHeight = ref.current.scrollHeight;
      const height = ref.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      ref.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [numComments]);
  return ref;
}

TaskCommentPanel.propTypes = {
  comments: PropTypes.array,
  item: PropTypes.instanceOf(ConversationItem),
};

function TaskContent({ item }) {
  const executeAction = useExecuteAction();
  const { content } = item;
  const { attachments, bodyHtml, note } = content;

  const contentRef = useRef(null);

  if (bodyHtml) {
    return (
      <ContentWrapper data-aid="taskCommentPanel-taskMessageWrapper">
        <bdi>
          <SafeHtml data-aid="taskCommentPanel-taskMessage" html={bodyHtml} ref={contentRef} setDirection />
        </bdi>
        <Footer>
          <TimelineLink
            data-aid="taskCommentPanel-viewInTimeline"
            onClick={() => executeAction(NavigateToItem, { itemId: item.id })}
          >
            View in timeline
          </TimelineLink>
          {attachments && attachments.length ? <NumAttachments>{attachments.length} attachments</NumAttachments> : null}
        </Footer>
      </ContentWrapper>
    );
  } else if (note) {
    return (
      <ContentWrapper data-aid="taskCommentPanel-taskMessageWrapper">
        <Linkifier data-aid="taskCommentPanel-taskMessage">{note}</Linkifier>
        <Footer>
          <TimelineLink
            data-aid="taskCommentPanel-viewInTimeline"
            onClick={() => executeAction(NavigateToItem, { itemId: item.id })}
          >
            View in timeline
          </TimelineLink>
          {attachments && attachments.length ? <NumAttachments>{attachments.length} attachments</NumAttachments> : null}
        </Footer>
      </ContentWrapper>
    );
  }

  return null;
}

const ContentWrapper = styled.div`
  box-shadow: ${p => p.theme.boxShadow.small};
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px;
`;

const NumAttachments = styled.span`
  color: ${p => p.theme.colors.gray600};
  font-size: 11px;
`;

const TimelineLink = styled.a`
  cursor: pointer;
  font-size: 11px;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const StyledItemMenu = styled(ConversationItemMenuDisplayer)`
  .conversationItemMenu-container {
    top: 25px;
    .conversationItemMenu-button {
      padding-top: 0;
    }
  }
`;

const StyledDetail = styled.div`
  background: ${p => p.theme.colors.gray100};
  padding: 20px 16px 16px;
`;

const StyledDueDate = styled.div`
  font-weight: 600;
`;

const StyledTaskMessages = styled.div`
  overflow: auto;
  padding: 16px 0 0 0;

  agentmention {
    background-color: ${p => p.theme.colors.gray100};
    border-radius: 4px;
    color: ${p => p.theme.colors.green400};
    font-weight: bold;
    padding: 4px;

    &:hover {
      background-color: ${p => p.theme.colors.gray200};
    }

    &[data-agentid="${p => p.$currentAgentId}"], &[data-agentid="${p => p.$currentAgentId}"]:hover {
      background-color: ${p => p.theme.colors.yellow200};
    }
`;

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const commentPanelProvider = getProvider('commentPanel');
  const historyProvider = getProvider('conversationHistory');
  const taskInfoProvider = getProvider('taskInfo');
  const currentAgentProvider = getProvider('currentAgent');

  const panel = commentPanelProvider.get();
  const selectedItemId = panel.selectedItemId;
  const lastOpenedAt = panel.lastOpenedAt;

  let item = historyProvider.findBy({ id: selectedItemId });
  item = item && historyProvider.isPending(selectedItemId) ? historyProvider.getPending(selectedItemId) : item;
  if (!item) return {};

  if (typeReflect.instanceToType(item.content) === ConversationItemType.ITEM_LINK) {
    item = createConversationItemFromItemLink(item);
  }

  let taskInfo = taskInfoProvider.findBy({ id: item.id });
  let comments = taskInfo ? taskInfo.getComments() : [];
  const preferences = getProvider('agentPreferences').get();

  return {
    comments,
    isCommentingEnabled: getIsCommentingEnabledForTask(
      { taskInfo: taskInfoProvider, conversationHistory: historyProvider },
      { taskId: item.id }
    ),
    currentAgentId: currentAgentProvider.get().id,
    isSlateBetaEnabled: preferences.experimentalEditorEnabled && isFeatureEnabled('slateBeta'),
    item,
    lastOpenedAt,
    selectedComment: panel.selectedComment,
  };
}

const TaskCommentPanelContainer = connect(mapStateToProps)(TaskCommentPanel);
export default TaskCommentPanelContainer;
