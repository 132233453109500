import _ from 'lodash';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';

import AgentHomeNavList from './agent_home_nav_list';
import AppFeaturesMixin from '../lib/app_features_mixin';
import ProvidersMixin from '../lib/providers_mixin';
import SharedBindings from '../lib/shared_bindings_mixin';
import { INBOX_COUNT_MAX } from 'models/inbox_count';

const AgentGroups = createReactClass({
  mixins: [AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindings],

  /* lifecycle */

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('currentAgent'));
    this.observeBinding(this.getSharedBinding('currentLocation'));
    this.observeBinding(this.getSharedBinding('routingGroups'));
  },

  /* rendering */

  render() {
    let routingGroups = this.getRoutingGroups();
    return (
      <div className="agentGroups">
        {this.renderMyGroups(routingGroups)}
        {this.renderOtherGroups(routingGroups)}
        {this.renderDisabledGroups(routingGroups)}
      </div>
    );
  },

  renderMyGroups(routingGroups) {
    let myRoutingGroups = this.filterMyRoutingGroups(routingGroups);
    if (myRoutingGroups.length === 0) {
      return null;
    }

    return (
      <div className="agentGroups-myGroups agentGroups-container">
        <div className="agentGroups-header">Team Inboxes</div>
        {this.renderNavList(myRoutingGroups)}
      </div>
    );
  },

  renderOtherGroups(routingGroups) {
    if (this.shouldRenderOtherGroups()) {
      let otherRoutingGroups = this.filterOtherGroups(routingGroups);
      if (otherRoutingGroups.length === 0) {
        return null;
      }
      return (
        <div className="agentGroups-otherGroups agentGroups-container">
          <div className="agentGroups-header">Other Inboxes</div>
          {this.renderNavList(otherRoutingGroups)}
        </div>
      );
    }
    return null;
  },

  renderDisabledGroups(routingGroups) {
    let disabledGroups = this.filterDisabledGroups(routingGroups);
    if (disabledGroups.length === 0) {
      return null;
    }
    return (
      <div className="agentGroups-archivedGroups agentGroups-container">
        <div className="agentGroups-header">Deactivated Inboxes</div>
        {this.renderNavList(disabledGroups)}
      </div>
    );
  },

  renderNavList(routingGroups) {
    return <AgentHomeNavList items={this.getNavListItems(routingGroups)} />;
  },

  /* helpers */

  getRoutingGroups() {
    return this.getProvider('routingGroups').findAll();
  },

  getCurrentAgentId() {
    return this.getSharedBinding('currentAgent').get('id');
  },

  filterOtherGroups(routingGroups) {
    let currentAgentId = this.getCurrentAgentId();

    return routingGroups.filter(group => {
      return group.agentIds.indexOf(currentAgentId) === -1 && !group.disabled;
    });
  },

  filterMyRoutingGroups(routingGroups) {
    let currentAgentId = this.getCurrentAgentId();

    return routingGroups.filter(group => {
      return group.agentIds.indexOf(currentAgentId) > -1 && !group.disabled;
    });
  },

  filterDisabledGroups(routingGroups) {
    return routingGroups.filter(group => {
      const count = this.getCount(group.id);
      return count > 0 && group.disabled;
    });
  },

  getNavListItems(routingGroups) {
    let activeInbox = this.getProvider('currentLocation').get();

    return _.compact(
      routingGroups.map(group => {
        let count = this.getCount(group.id);
        const name = group.name;
        return {
          name,
          isActive: group.id === activeInbox.routingGroupId,
          location: activeInbox.deriveNew({ conversationStatus: null, isTeam: true, routingGroupId: group.id }),
          count: count > INBOX_COUNT_MAX ? '999+' : count,
        };
      })
    );
  },

  getCount(groupId) {
    let count = 0;
    let teamInboxCounts = this.props.teamInboxCounts ? this.props.teamInboxCounts.toArray() : [];
    teamInboxCounts.map(inboxCount => {
      if (inboxCount.get('id') === groupId) {
        count = inboxCount.get('newCount') + inboxCount.get('openCount');
      }
    });
    return count;
  },

  shouldRenderOtherGroups() {
    return this.isFeatureEnabled('viewAllGroupInboxes');
  },
});

export default AgentGroups;
