import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import _ from 'lodash';
export default class TeamService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(teamAttrs, callback, path, { orgId }) {
    let teams = this._getTeams(orgId);

    callback(null, { status: 200, statusText: statusText(200), response: teams || [] });
  }

  _getTeams(orgId) {
    return this.getDatabase(orgId).teams;
  }

  addOrReplace(teamAttrs, callback, path, { orgId, teamId }) {
    let teams = this._getTeams(orgId);
    let existingTeam = _.find(teams, { id: teamId });

    if (existingTeam) {
      teams.push({ ...existingTeam, ...teamAttrs });
    } else {
      teams.push(teamAttrs);
    }
    callback(null, { status: 204, statusText: statusText(204) });
  }

  remove(teamId, callback, path, { orgId }) {
    _.remove(this._getTeams(orgId), { id: teamId });
    this._pubsub.publish(`v1/orgs/${orgId}/teams/${teamId}/event/delete`, {
      topicParams: {
        teamId,
      },
    });
    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/teams': {
          GET: this.findAll,
        },
        '/api/v1/orgs/:orgId/teams/:teamId': {
          DELETE: this.remove,
          PUT: this.addOrReplace,
        },
      },
      this
    );
  }
}
