import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class CustomChannelsService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(compAttrs, callback, path, { orgId }) {
    const database = this.getDatabase(orgId);
    callback(null, { status: 200, statusText: statusText(200), response: database && database.customChannels });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/custom-channels': {
          GET: this.find,
        },
      },
      this
    );
  }
}
