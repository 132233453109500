import _ from 'lodash';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons/faPaperPlaneTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useExecuteAction } from 'components/hooks/connect_hooks';
import AboveEditorActions from 'components/composer/shared/above_editor_actions';
import AddCommentToTask from 'actions/task/add_comment_to_task';
import BelowEditorActions from 'components/composer/shared/below_editor_actions';
import Button from 'components/common/button';
import connect from 'components/lib/connect';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import getExternalSerializer from 'components/text_editor/serializers/external_serializer';
import normalize from 'components/text_editor/normalizers/rich_text';
import PlainTextSerializer from 'components/text_editor/serializers/plaintext_serializer';
import RichTextEditor from 'components/composer/editors/rich_text';
import SubmitOnEnter from 'components/text_editor/plugins/submit_on_enter';
import EditorWrapperContainer from 'components/customer/task_comments/task_comment_editor_wrapper';

const EXTERNAL_SERIALIZER = getExternalSerializer();

export function TaskCommentSlateEditor({ className, isPending, itemId, lastOpenedAt, readOnly, wrapperRef }) {
  const executeAction = useExecuteAction();
  const [slateComment, setSlateComment] = useState(() => FullSerializer.deserialize('<div><br/></div>'));

  let itemIdRef = useRef(itemId);
  useEffect(() => {
    itemIdRef.current = itemId;
  }, [itemId]);

  const editorRef = useRef(null);
  const onSlateChange = useCallback(e => setSlateComment(e.value), []);
  const onSubmit = useCallback(() => {
    const normalizedValue = normalize(editorRef.current.value, {
      trimEmptyParagraphs: true,
      trimParagraphWhitespace: true,
    });

    const isEmpty = stripWhitespace(PlainTextSerializer.serialize(normalizedValue)) === '';
    if (isEmpty) {
      return;
    }

    const content = {
      itemId: itemIdRef.current,
      message: EXTERNAL_SERIALIZER.serialize(normalizedValue),
    };

    content.mentionedAgentIds = editorRef.current.getMentionedAgentIds();

    executeAction(AddCommentToTask, content);
    setSlateComment(FullSerializer.deserialize('<div><br/></div>'));
  }, [executeAction]);

  const plugins = useMemo(() => [SubmitOnEnter(onSubmit)], [onSubmit]);

  useEffect(() => {
    // When we reset the editor state to blank, for some reason we lose focus.
    // So we need to manually refocus.
    !isPending && editorRef.current && editorRef.current.focus();
  }, [isPending, lastOpenedAt]);

  const editorNodeRef = useRef(null);

  let placeholderText = 'Comment on this task';

  return (
    <EditorWrapperContainer className={className} data-aid="taskCommentEditor-editor" innerRef={wrapperRef}>
      <AboveEditorActions editorRef={editorRef} onChange={onSlateChange} value={slateComment} />
      <span ref={editorNodeRef}>
        <StyledEditorWrapperMemo
          editorRef={editorRef}
          onChange={onSlateChange}
          placeholder={placeholderText}
          plugins={plugins}
          readOnly={readOnly}
          value={slateComment}
        />
      </span>
      <Below>
        <BelowEditorActions editorRef={editorRef} positionRef={editorNodeRef} />
        <StyledSend
          data-aid="addTaskComment"
          disabled={!stripWhitespace(slateComment.document.text)}
          onClick={onSubmit}
          onMouseDown={evt => evt.preventDefault()}
        >
          <FontAwesomeIcon icon={faPaperPlaneTop} />
        </StyledSend>
      </Below>
    </EditorWrapperContainer>
  );
}

const StyledEditorWrapperMemo = React.memo(StyledEditorWrapper);
function StyledEditorWrapper({ editorRef, onChange, placeholder, plugins, readOnly, value }) {
  return (
    <StyledEditor
      agentMentionProps={{
        autoPosition: true,
        height: '200px',
      }}
      allowMentions
      editorRef={editorRef}
      onChange={onChange}
      placeholder={placeholder}
      plugins={plugins}
      readOnly={readOnly}
      value={value}
    />
  );
}

const StyledEditor = styled(RichTextEditor)`
  border: 1px solid ${p => p.theme.colors.gray400};
  border-radius: ${p => p.theme.borderRadius.default};
  margin-left: 0;
  max-height: 300px;
  outline: none;
  overflow: auto;
  padding: 8px 12px;
  resize: none;
  width: 100%;
  &:focus {
    border-color: ${p => p.theme.colors.green400};
  }
`;

const Below = styled.div`
  display: flex;
`;

const StyledSend = styled(Button)`
  margin-top: 4px;
  padding: 4px 12px;
`;

TaskCommentSlateEditor.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  lastOpenedAt: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

function mapStateToProps({ getProvider }, { itemId }) {
  const taskInfoProvider = getProvider('taskInfo');
  let isPending = true;
  if (taskInfoProvider.findBy({ id: itemId }) && !taskInfoProvider.isPending(itemId)) {
    isPending = false;
  }

  return {
    isPending,
  };
}

const TaskCommentEditorContainer = connect(mapStateToProps)(TaskCommentSlateEditor);

export default TaskCommentEditorContainer;

function stripWhitespace(plaintext) {
  const lines = plaintext.split(/\r?\n/);
  return _.map(lines, line => line.trim()).join('');
}
