import _ from 'lodash';
import { DomainError, PermanentError } from 'scripts/application/lib/error';
import qconsole from 'scripts/lib/qconsole';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import CloseModal from 'actions/modal/close_modal';

export default class ScheduledReportGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.scheduledReport;
  }

  onAddSuccess(dto) {
    this.context.executeAction(CloseModal);

    const reportName = _.get(dto, 'reportParameters.name', '');
    this.context.executeAction(ShowToast, {
      type: ToastType.SUCCESS,
      message: reportName === '' ? 'Report successfully scheduled.' : `"${reportName}" report scheduled.`,
      link: '/admin/scheduled-reports',
    });

    this.store.resetPending();
  }

  onAddError(err) {
    this.store.resetPending();

    if (err instanceof DomainError) {
      this.store.setErrors(err.errors);
    } else if (err instanceof PermanentError) {
      err.errors.forEach(e => {
        this.context.executeAction(ShowToast, {
          type: ToastType.ERROR,
          message: e.detail,
        });
      });
    } else {
      this.context.executeAction(CloseModal);

      this.context.executeAction(ShowToast, {
        type: ToastType.ERROR,
        message: 'We were unable to schedule your report. Please contact Gladly support if this problem persists.',
      });
      qconsole.error(`Unexpected error adding scheduled report ${JSON.stringify(err)}`);
    }
  }
}
