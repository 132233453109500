import React from 'react';
import PropTypes from 'prop-types';

import ConversationItem from 'models/conversation_item';
import WavePlayer from 'components/lib/audio/wave_player';
import { createWavePlayerContainer } from './lib/create_wave_player_container';

const PhoneCallWavePlayerContainer = createWavePlayerContainer({
  propTypes: {
    item: PropTypes.instanceOf(ConversationItem).isRequired,
  },

  renderVoiceRecordingContainer() {
    let phoneCallItemContent = this.props.item.content;
    let baseUrl = this.getBaseRecordingUrl();
    let url = `${baseUrl}/call-recordings/${phoneCallItemContent.recordingId}`;

    let props = {
      duration: phoneCallItemContent.calculateDuration(),
      isWebAudioSupported:
        typeof (
          window.AudioContext ||
          window.webkitAudioContext ||
          window.mozAudioContext ||
          window.oAudioContext ||
          window.msAudioContext
        ) === 'function',
      onReady: this.saveRecordingDuration,
      recordingId: phoneCallItemContent.recordingId,
      url,
      customerId: this.props.item.customerId,
      contentType: this.props.item.contentType(),
    };

    return <WavePlayer {...props} />;
  },
});

export default PhoneCallWavePlayerContainer;
