import CustomerView from 'models/location/customer_view';

export default class JumpToItem {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    const itemId = this.context.stores.itemIds.findBy({ id });
    currentLocation.changeCurrentConversation(itemId.conversationId);
    currentLocation.changeCurrentConversationItem(itemId.id);

    this.context.stores.currentLocation.set(currentLocation);
  }
}
