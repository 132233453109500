import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Selected Fill
 */

export default function SelectedFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" fill="white" r="8" />
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.8509 5.91921C12.0497 5.70893 12.0497 5.36799 11.8509 5.15771C11.6521 4.94743 11.3297 4.94743 11.1309 5.15771L6.83636 9.70004L4.86907 7.61925C4.67026 7.40897 4.34792 7.40897 4.14911 7.61925C3.9503 7.82953 3.9503 8.17047 4.14911 8.38075L6.47638 10.8423C6.67519 11.0526 6.99753 11.0526 7.19635 10.8423L11.8509 5.91921Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
