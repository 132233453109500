import createModel, { prop } from './lib/create_model';

const Organization = createModel({
  modelName: 'Organization',
  properties: {
    id: prop(String),
    loginDomain: prop(String),
    gladlyUrl: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Organization;
