import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import CustomerProfileDef from 'models/customer_profile_def';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import {
  countSubgroupCustomAttributes,
  getAttributeDefsToShow,
  renderCustomAttributes,
  renderCustomAttributesSubgroup,
} from './lib/custom_attributes_helper';
import ProfileCardDef from 'models/configuration/profile_card_def';
import ProfileCardType from 'models/configuration/profile_card_type';

export function CustomAttributes({ customAttributes, customerProfileDef, profileCardDef }) {
  const attrDefs = getAttributeDefsToShow(profileCardDef, customerProfileDef, customAttributes);
  const numSubgroupAttrDefs = countSubgroupCustomAttributes(profileCardDef, customerProfileDef, customAttributes);

  if (!attrDefs.length && !numSubgroupAttrDefs) {
    return null;
  }

  return (
    <ExpandableProfileCard data-aid="customAttributes" title={profileCardDef.label || 'Information'}>
      {renderCustomAttributes(attrDefs, customAttributes, ProfileCardType.CUSTOM_ATTRS)}
      {renderCustomAttributesSubgroup(
        profileCardDef,
        customerProfileDef,
        customAttributes,
        ProfileCardType.CUSTOM_ATTRS
      )}
    </ExpandableProfileCard>
  );
}

CustomAttributes.propTypes = {
  customAttributes: PropTypes.object,
  customerProfileDef: PropTypes.instanceOf(CustomerProfileDef),
  profileCardDef: PropTypes.instanceOf(ProfileCardDef).isRequired,
};

function mapStateToProps({ getProvider }) {
  const customerProfileDefProvider = getProvider('customerProfileDef');
  const profileProvider = getProvider('profile');
  const profile = profileProvider.get();

  return {
    customAttributes: profile?.customAttributes,
    customerProfileDef: customerProfileDefProvider.get(),
  };
}

const CustomAttributesContainer = connect(mapStateToProps)(CustomAttributes);
export default CustomAttributesContainer;
