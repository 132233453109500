import _ from 'lodash';
import { Factory } from 'rosie';
import { resequenceIds } from './lib/utils';

import './lib/uuid';
import './conversation_factory';
import './conversation_items/email_message_factory';
import './customer_note_factory';
import './customer_profile_factory';
import './external_customer_lookup_results_factory';
import './transaction_factory';

Factory.define('CustomerWithoutConversations')
  .extend('Uuid')
  .attr('actions', [])
  .attr('conversationHistory', [])
  .attr('note', ['note'], noteAttrs => noteAttrs && Factory.attributes('CustomerNote', noteAttrs))
  .attr('profile', ['profile', 'id'], withCustomerId)
  .attr('transactions', []);

/** The Customer factory supports 3 use cases
 *
 * 1. passed in attrs override Factory defaults and helpers
 *    - e.g.: can pass customer.conversationHistory
 * 2. the conversationHistory attr within each conversation is moved to a single customer.conversationHistory
 * 3. add self as initiator to any conversationItems with missing initiators
 */
Factory.define('Customer')
  .extend('CustomerWithoutConversations')
  .attr(
    'conversationHistory',
    ['conversationHistory', 'conversations', 'id'],
    (conversationHistory, conversations, id) => {
      let conversationItems = _.chain(conversations)
        .map(conversation => conversation.conversationItems)
        .flatten()
        .reject(_.isEmpty)
        .uniq('id')
        .value();

      conversations.forEach(conversation => {
        delete conversation.conversationItems;
      });

      return (conversationHistory || []).concat(conversationItems).map(ci => {
        if (_.isEmpty(ci.initiator) || ci.initiator.type === 'CUSTOMER') {
          ci.initiator = { type: 'CUSTOMER', id };
        }

        ci.customerId = id;

        return ci;
      });
    }
  )
  .attr('conversations', ['conversations'], conversations => {
    conversations = conversations || [];

    // if no explicit IDs were passed in, override default id generator so they start at 1
    conversations = resequenceIds(conversations);

    return conversations.map(conversation => Factory.attributes('ConversationWithDefaults', conversation));
  });

/** The CustomerWithDefaults factory supports 4 use cases regarding conversations and conversationHistory
 *
 * 1-3. everything from Customer factory
 * 4. no conversations
 *    - generate a single ConversationWithEmail
 */
Factory.define('CustomerWithDefaults')
  .extend('Customer')
  .attr('conversations', ['conversations'], conversations =>
    conversations
      ? conversations.map(conversation => Factory.attributes('ConversationWithDefaults', conversation))
      : [Factory.attributes('ConversationWithEmail')]
  )
  .attr('note', () => Factory.attributes('CustomerNoteWithDefaults'))
  .attr('profile', ['profile', 'id'], (profileAttrs, id) =>
    _.isUndefined(profileAttrs)
      ? Factory.attributes('CustomerProfileWithDefaults', { id })
      : withCustomerId(profileAttrs, id)
  )
  .attr('externalLookupSuggestions', () => Factory.attributes('ExternalCustomerLookupResultWithDefaults'));

function withCustomerId(profile, customerId) {
  if (profile && profile.id !== customerId) {
    profile.id = customerId;
  }
  return profile;
}
