import _ from 'lodash';
import moment from 'moment';

import Assignee from './assignee';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import { CustomAttributeValue, customAttributeValuesFromDto } from 'models/custom_attribute';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import Sla from './sla';

const Conversation = createModel({
  modelName: 'Conversation',

  properties: {
    id: prop(String).isRequired,
    closedAt: String,
    createdAt: String,
    customerId: String,
    updatedAt: String,
    dueAt: String, // deprecated, use sla.dueAt
    sla: Sla,
    assignee: Assignee,
    status: String,
    topicId: String,
    topicIds: prop([String]).default([]),
    customAttributes: prop([CustomAttributeValue]).default([]),
    version: prop(Number).default(0),
  },

  isOpen() {
    return this.status === Conversation.Status.OPEN;
  },

  isOverdue() {
    if (!this.isOpen) {
      return false;
    }
    return moment().isAfter(this.dueAt);
  },

  isNearlyDue() {
    if (!this.isOpen) {
      return false;
    }
    return (
      moment()
        .add(5, 'minutes')
        .isAfter(this.dueAt) && !this.isOverdue()
    );
  },

  replaceAssignee(assignee) {
    this.assignee = assignee;
  },

  setStatus(status) {
    if (status in Conversation.Status) {
      this.status = status;
    } else {
      throw new Error(`invalid status [${status}]`);
    }
  },

  setTopicId(topicId) {
    this.topicId = topicId;
  },

  setTopicIds(topicIds) {
    this.topicIds = topicIds;
  },

  // since placeholder items do not have timestamps, the conversation
  // timestamp cannot depend on conversation items' timestamps
  getTimestamp() {
    return this.createdAt;
  },

  hasSla() {
    return !!this.sla;
  },

  /* Outgoing Communication Updates */
  getUpdatedAttributesForOutgoingCommunication(agentId) {
    let updates = {};
    let updatedStatus = Conversation.Status.WAITING;

    if (!this.assignee.agentId) {
      // route to communicator if not assigned
      updates.assignee = this.assignee.routeToAgent(agentId);
    } else if (this.assignee.agentId !== agentId) {
      // let other agent notice activity
      updatedStatus = Conversation.Status.OPEN;
    }

    if (updatedStatus !== this.status) {
      updates.status = updatedStatus;
    }

    return updates;
  },

  getUpdatedAttributesForRealtimeCommunication(agentId, updates = {}) {
    updates.status = Conversation.Status.OPEN;

    const assignee = updates.assignee || this.assignee;
    if (!assignee.agentId) {
      // route to communicator if not assigned
      updates.assignee = assignee.routeToAgent(this.assignee.agentId || agentId);
    }

    return updates;
  },

  getUpdatedAttributesForOutgoingPhoneCall(agentId, updates = {}) {
    const assignee = updates.assignee || this.assignee;
    if (!assignee.agentId) {
      updates.assignee = assignee.routeToAgent(agentId);
    }

    if (this.status !== Conversation.Status.OPEN) {
      updates.status = Conversation.Status.OPEN;
    }

    return updates;
  },

  getInboxStatus() {
    switch (this.status) {
      case Conversation.Status.OPEN:
        return !this.assignee.agentId ? Conversation.InboxStatus.NEW : Conversation.InboxStatus.OPEN;
      case Conversation.Status.WAITING:
        return Conversation.InboxStatus.WAITING;
      case Conversation.Status.CLOSED:
        return Conversation.InboxStatus.CLOSED;
      default:
        throw new Error(`unknown status [${this.status}]`);
    }
  },

  overrideToJs(toJs) {
    return () => {
      return _(toJs())
        .omit('dueAt')
        .merge({
          sla: this.dueAt && { dueAt: this.dueAt },
        })
        .value();
    };
  },

  update(attrs) {
    let effectiveUpdates = {};

    if (_.has(attrs, 'status') && attrs.status !== this.status) {
      let status = attrs.status;
      if (!(status in Conversation.Status)) {
        throw new Error(`invalid status [${status}]`);
      }
      effectiveUpdates.status = status;
    }

    if (_.has(attrs, 'topicId') && this.topicId !== attrs.topicId) {
      effectiveUpdates.topicId = attrs.topicId;
    }

    if (attrs.topicIds && !_.isEqual(this.topicIds, attrs.topicIds)) {
      effectiveUpdates.topicIds = attrs.topicIds;
    }

    if (attrs.customAttributes && !_.isEqual(this.customAttributes, attrs.customAttributes)) {
      effectiveUpdates.customAttributes = customAttributeValuesFromDto(attrs.customAttributes);
    }

    if (_.has(attrs, 'assignee')) {
      let assignee = Assignee.create(attrs.assignee);
      if (assignee !== this.assignee) {
        effectiveUpdates.assignee = assignee;
      }
    }

    _.assign(this, effectiveUpdates);

    return effectiveUpdates;
  },

  statics: {
    create(attrs) {
      const customAttributes = customAttributeValuesFromDto((attrs && attrs.customAttributes) || []);
      return new this(_.merge({ id: IdGenerator.newId(), customAttributes }, attrs));
    },

    overrideFromJs(fromJs) {
      return attrs => {
        const dueAt = attrs.sla && attrs.sla.dueAt;
        const customAttributes = customAttributeValuesFromDto((attrs && attrs.customAttributes) || []);
        return fromJs({
          ...attrs,
          dueAt,
          customAttributes,
        });
      };
    },

    Status: createEnum('OPEN', 'WAITING', 'CLOSED'),
    InboxStatus: createEnum('NEW', 'OPEN', 'WAITING', 'CLOSED'),
    AssigneeType: createEnum('AGENT', 'ROUTING_GROUP'),
  },
});

export default Conversation;
