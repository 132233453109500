import createModalModel, { prop } from './lib/create_modal_model';

export default createModalModel({
  modelName: 'TaskConfirmation',
  properties: {
    hasAssignee: prop(Boolean),
    hasDescription: prop(Boolean),
    hasDueDate: prop(Boolean),
  },
});
