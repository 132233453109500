import _ from 'lodash';

import AgentPhonePreferences from 'models/agent_phone_preferences';
import AgentProfile from 'models/agent_profile';
import createBlankError from 'scripts/domain/factories/error/create_blank_error';

export default class UpdateCurrentAgentProfile {
  constructor(context) {
    this.context = context;
  }

  run({ agent, override }) {
    let parsedAttrs = _.omit(agent, ['phoneNumber', 'extension']);

    let appFeatures = this.context.stores.appFeatures.get();
    let isWebRTCEnabled = appFeatures.isEnabled('webRTC');
    let isDirectDialEnabled = appFeatures.isEnabled('directDial');
    let currentAgentPhonePreference = this.context.stores.currentAgent.get().getVoiceConfigurationPreference();
    let canAgentUpdatePhoneNumber =
      isDirectDialEnabled &&
      (currentAgentPhonePreference === AgentPhonePreferences.DIRECT_DIAL ||
        currentAgentPhonePreference === AgentPhonePreferences.BROWSER ||
        currentAgentPhonePreference === AgentPhonePreferences.NONE);

    if (canAgentUpdatePhoneNumber) {
      if (agent.phoneNumber !== '' || agent.extension !== '') {
        parsedAttrs.voiceConfiguration = {
          phone: { original: agent.phoneNumber, extension: agent.extension },
          preference: AgentPhonePreferences.DIRECT_DIAL,
        };
      } else {
        parsedAttrs.voiceConfiguration = {
          preference: isWebRTCEnabled ? AgentPhonePreferences.BROWSER : AgentPhonePreferences.NONE,
        };
      }
    }

    let validationErrors = AgentProfile.getValidationErrors(parsedAttrs);
    if (typeof parsedAttrs.name !== 'undefined' && !parsedAttrs.name) {
      validationErrors.push(createBlankError('name'));
    }
    if (validationErrors.length > 0) {
      this.context.stores.currentAgent.setErrors(validationErrors);
    } else {
      let agentProfile = this.context.stores.currentAgent.get();
      agentProfile.update(parsedAttrs);
      this.context.stores.currentAgent.setPending(agentProfile);
      override
        ? this.context.gateways.currentAgentProfile.forceUpdate(parsedAttrs)
        : this.context.gateways.currentAgentProfile.update(parsedAttrs);
    }
  }
}
