import { AgentAvailability } from 'models/agent_status';
import { setBusyPending } from 'actions/agent_status/lib/agent_status';
import { openStatusReasonModal } from 'actions/agent_status/lib/agent_status_reason';

export default class SelectIdleReason {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (this.store.get().getAvailability() !== AgentAvailability.AWAY) {
      return;
    }

    setBusyPending(this.context);
    openStatusReasonModal(this.context);
  }

  get store() {
    return this.context.stores.agentStatus;
  }
}
