import _ from 'lodash';
import { useRef, useEffect } from 'react';

import { useOnUnmount } from './lifecycle_hooks';

/**
 * `useDebounced` produces a debounced version of the `originalFn`. We assume the original function
 * is memoized, so if its value changes, we cancel the previous debounced function and create a new one.
 *
 * @param {function} originalFn Original function we want to debounce
 * @param {number} wait same as `wait` param for _.debounce
 * @param {Object} options same as `options` param for _.debounce
 */
export function useDebounced(originalFn, delay, opts) {
  return useDelayBase(_.debounce, originalFn, delay, opts);
}
/**
 * `useThrottled` produces a throttled version of the `originalFn`. We assume the original function
 * is memoized, so if its value changes, we cancel the previous throttled function and create a new one.
 *
 * @param {function} originalFn Original function we want to throttle
 * @param {number} wait same as `wait` param for _.throttle
 * @param {Object} options same as `options` param for _.throttle
 */
export function useThrottled(originalFn, delay, opts) {
  return useDelayBase(_.throttle, originalFn, delay, opts);
}

/**
 * `useDelayBase` produces a delayed function. We assume the original function is memoized, so if its value
 * changes, we cancel the previous debounced function and create a new one. Can either throttle or debounce.
 *
 * @param {function} delayFn Either _.throttle or _.debounce
 * @param {function} originalFn Original function we want to throttle or debounce
 * @param {number} wait same as `wait` param for _.debounce or _.throttle
 * @param {Object} options same as `options` param for _.debounce or _.throttle, with extra `dontCancelOnUnmount` opt
 * @param {Object} componentOptions can provide `dontCancelOnUnmount` to prevent the debounced function from being canceled on unmount
 */
function useDelayBase(delayFn, originalFn, wait = 0, options = {}) {
  let original = originalFn;
  const debounceOptions = _.pick(options, ['leading', 'maxWait', 'trailing']);
  const componentOptions = _.pick(options, ['dontCancelOnUnmount']);
  let debounced = original ? delayFn(original, wait, debounceOptions) : undefined;

  const debouncedRef = useRef({ original, debounced });

  useEffect(() => {
    if (original !== debouncedRef.current.original) {
      if (debouncedRef.current.debounced) {
        debouncedRef.current.debounced.cancel();
      }
      debouncedRef.current = { original, debounced };
    }
  }, [original]);

  // Cancel the debounced function either when the component unmounts or when the debounced function
  // changes (which means, of course, that the original function changed as well).
  useOnUnmount(() => {
    if (componentOptions?.dontCancelOnUnmount) {
      return;
    }

    debouncedRef.current.debounced && debouncedRef.current.debounced.cancel();
  }, [debouncedRef.current.debounced]);

  return debouncedRef.current.debounced;
}
