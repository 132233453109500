import _ from 'lodash';
import createModel, { prop } from 'models/lib/create_model';

export default function(modelDef) {
  const typeName = _.snakeCase(modelDef.modelName).toUpperCase();

  return createModel({
    overrideToJs(toJs) {
      return () => ({ ...toJs(), type: typeName });
    },
    ...modelDef,
    statics: {
      create(attrs) {
        return new this(attrs);
      },
      typeName,
      ...modelDef.statics,
    },
  });
}

export { prop };
