import { Path, Range, Transforms } from 'slate';

import getFirstNode from 'components/text_editor_new/lib/get_first_node';

export default function removeInlineFromStartOfSelection(editor, evt, type) {
  const { selection } = editor;
  const { inputType } = evt;

  if (!selection || Range.isCollapsed(selection)) {
    return false;
  }

  if (inputType === 'insertText') {
    const inlineEntry = getFirstNode(editor, {
      match: n => n.type === type,
    });

    if (inlineEntry) {
      const start = Range.start(selection);
      const isStartInsideFirstInline = Path.isCommon(inlineEntry[1], start.path);

      if (isStartInsideFirstInline) {
        evt.preventDefault();
        Transforms.removeNodes(editor, { at: inlineEntry[1] });
        Transforms.insertText(editor, evt.data);
        return true;
      }
    }
  }
  return false;
}
