import { get, isArray, map } from 'lodash';

import Err from 'models/err';

export class GladlyError extends Error {
  constructor(message, errorName, nestedErrors = []) {
    super(message);
    this.name = errorName;
    this.errors = map(nestedErrors, e => new Err(e));
  }

  get detailedMessage() {
    return map(this.errors, e => e.detail || e.code).join('; ');
  }
}

export class DomainError extends GladlyError {
  constructor(errors, resource) {
    super(`domain error occurred for ${resource}`, 'DomainError', errors);
  }
}

export class NotExistError extends GladlyError {
  constructor(errors, resource) {
    super(`entity not found for ${resource}`, 'NotExistError', errors);
  }

  get detailedMessage() {
    return get(this.errors, '[0].detail') || 'entity not found';
  }
}

export class PermanentError extends GladlyError {
  constructor(errors, resource) {
    super(`permanent error occurred for ${resource}`, 'PermanentError', errors);
  }
}

export class VersionOutdated extends DomainError {}

export class InfrastructureError extends GladlyError {
  constructor(message, errorType = 'InfrastructureError') {
    super(message, errorType);
  }
}

export class NetworkError extends InfrastructureError {
  constructor(cause) {
    super('network error occurred', 'NetworkError');
    this.cause = cause;
  }
}

export class RequestTimeout extends InfrastructureError {
  constructor(cause) {
    super('request timed out', 'RequestTimeout');
    this.cause = cause;
  }
}

export class ServerError extends InfrastructureError {
  constructor(response, resource) {
    super(`server error occurred for ${resource}`, 'ServerError');
    this.response = response;
  }
}

export class TooManyRequestsError extends GladlyError {
  constructor(resource) {
    super(`too many requests for ${resource}`, 'TooManyRequests');
  }
}

export class Unauthorized extends GladlyError {
  constructor(response, resource) {
    super(`authentication required for ${resource}`, 'Unauthorized');
    this.response = response;
  }
}

export function getFriendlyMessage(err) {
  if (err instanceof NetworkError) {
    return `It looks like you're offline. Please check your internet connection and try again.`;
  }

  if (err instanceof RequestTimeout) {
    return `Your last action could not be completed due to a slow or unstable internet connection. Please try again.`;
  }

  return `Hmm. Something seems to have gone wrong. Please refresh your page and try again.`;
}

/**
 * Converts error DTO typically received via a response to a typed error object based on the code of the first
 * error in the `errors` array:
 * - returns NotExistError if the first error has code `not_exist`
 * - returns ServerError if the first error has code `unexpected_error`
 * - returns DomainError otherwise
 * - returns generic Error with error JSON as a message if errorDto doesn't contain `errors` array.
 * @param errorDto - error DTO, expected to contain an object with `errors` array.
 * @returns {NotExistError|ServerError|DomainError|Error}
 */
export function errorFromDto(errorDto) {
  if (isArray(errorDto.errors)) {
    if (errorDto.errors[0].code === Err.Code.NOT_EXIST) {
      return new NotExistError(errorDto.errors);
    }

    if (errorDto.errors[0].code === Err.Code.UNEXPECTED_ERROR) {
      return new ServerError();
    }

    return new DomainError(errorDto.errors);
  }

  // Fall back on using error JSON as the error message. It shouldn't happen in a properly designed service.
  return Error(JSON.stringify(errorDto));
}
