import updateConnectionState from 'actions/health/lib/update_connection_state';

const DISCONNECT_TIME_DELAY = 15000; // ms

export default class MqttConnectionEventHandler {
  constructor(context) {
    this.context = context;
    this.updateConnectionState = updateConnectionState.bind(this, 'mqtt');
  }

  handleDisconnect() {
    this.delay(this.setDisconnected.bind(this));
  }

  handleConnect() {
    this.clearDelay();
    this.setConnected();
  }

  handleReset() {
    this.clearDelay();
  }

  setConnected() {
    this.updateConnectionState(state => state.setConnected());
  }

  setDisconnected() {
    this.updateConnectionState(state => state.setDisconnected());
  }

  delay(fn) {
    this.clearDelay();
    this.delayTimeout = setTimeout(fn, DISCONNECT_TIME_DELAY);
  }

  clearDelay() {
    clearTimeout(this.delayTimeout);
  }
}
