import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Attachment from 'components/lib/attachment/attachment';
import { default as AttachmentModel } from 'models/attachment';
import connect from 'components/lib/connect';
import RemoveAttachment from 'actions/composition/remove_attachment';

const CompositionSnippetAttachmentContainer = connect(mapStateToProps, mapExecuteToProps)(CompositionSnippetAttachment);

function mapStateToProps(context, props) {
  return _.omit(props, 'compositionId');
}

function mapExecuteToProps(executeAction, props) {
  return {
    onRemoveAttachment: () =>
      executeAction(RemoveAttachment, { attachmentId: props.attachment.id, compositionId: props.compositionId }),
  };
}

CompositionSnippetAttachmentContainer.propTypes = {
  attachment: PropTypes.instanceOf(AttachmentModel).isRequired,
  compositionId: PropTypes.string.isRequired,
  onOpenAttachmentPreview: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
};

function CompositionSnippetAttachment({ attachment, onOpenAttachmentPreview, onRemoveAttachment, thumbnailUrl }) {
  return (
    <Attachment
      attachment={attachment}
      className="compositionAttachments-attachment"
      disableRemove
      onClick={onOpenAttachmentPreview}
      thumbnailUrl={thumbnailUrl}
    >
      <span className="compositionAttachments-attachment-remove-container" onClick={onRemoveAttachmentStopping}>
        <span className="compositionAttachments-attachment-remove">✕</span>
      </span>
    </Attachment>
  );

  function onRemoveAttachmentStopping(evt) {
    evt.stopPropagation();
    onRemoveAttachment();
  }
}

CompositionSnippetAttachment.propTypes = {
  attachment: PropTypes.instanceOf(AttachmentModel),
  onOpenAttachmentPreview: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
};

export { CompositionSnippetAttachment };
export default CompositionSnippetAttachmentContainer;
