import _ from 'lodash';
import ReportHelpers from './report_helpers';
import Report, { ReportType } from './report';

export default class IvrSummaryReport extends Report {
  getDataForIvrSummaryByDay({ days }) {
    let dataByDay = _.fromPairs(
      _.map(days, date => {
        return [date, {}];
      })
    );

    _.mapKeys(this.data, (series, seriesName) => {
      _.map(series, item => {
        let day = item.day || item.time;
        var date = ReportHelpers.getDate(day).format('YYYY-MM-DD');
        if (date in dataByDay) {
          dataByDay[date][seriesName] = _.omit(item, ['day', 'time']);
        }
      });
    });

    return dataByDay;
  }

  init({ days }) {
    this.dataByDay = this.getDataForIvrSummaryByDay({ days });

    return {
      timezone: this.timezone,
      getDataForDayAndMetric: (date, metric) => {
        let dataForDate = this.dataByDay[date] || {};
        let rawData = dataForDate[metric] || {};

        const incomingCallCount = _.get(dataForDate, 'incomingIvrCallsByTime.count', 0);
        const endStateCount = _.get(dataForDate, 'callsReachingEndStateByTime.count', 0);

        const abandonedCallCount = incomingCallCount - endStateCount;

        switch (metric) {
          case 'incomingIvrCallsByTime':
            return incomingCallCount;
          case 'callsReachingEndStateByTime':
            return endStateCount;
          case 'abandonedIvrCallsByTime':
            return abandonedCallCount;
          case 'pctAbandonedByTime':
            return incomingCallCount > 0 ? abandonedCallCount / incomingCallCount : null;
          case 'speechAttemptsByTime':
          case 'unmappedSpeechAttemptsByTime':
          case 'touchtoneAttemptsByTime':
          case 'unmappedTouchtoneAttemptsByTime':
          case 'repeatCallerCountByTime':
          default:
            return rawData.count || 0;
        }
      },
    };
  }

  get reportType() {
    return ReportType['IVR-SUMMARY'];
  }
}
