import ServerClock from 'scripts/application/lib/server_clock';

export default class UpdateAgentTaskRead {
  constructor(context) {
    this.context = context;
  }

  run(taskId) {
    const agentId = this.context.stores.currentAgent.get().id;
    const readAt = ServerClock.toISOString();

    const agentTaskRead = {
      agentId,
      taskId,
      readAt,
    };

    this.context.gateways.agentTaskRead.update(agentTaskRead);
  }
}
