import addCustomerToInbox from 'actions/inbox/lib/add_customer_to_inbox';
import addTaskToInbox from 'actions/inbox/lib/add_task_to_inbox_deprecated';
import AgentVoiceCapabilitiesChecker from '../configuration/lib/agent_voice_capabilities_checker';
import {
  checkAndResetNavigatingToNext,
  handleNotifiableEvent,
  preloadCustomer,
  removeEmptyQueueNotifications,
} from './lib/conversation_workflow';
import { createAndShowNotification } from 'actions/notification/lib/show_notification';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import CustomerProfile from 'models/customer_profile';
import CustomerView from 'models/location/customer_view';
import NavigateToConversation from './navigate_to_conversation';
import RoutingEventType from 'models/routing_event/routing_event_type';
import tryUpdateInboxItem from 'actions/inbox/lib/try_update_inbox_item';
import tryUpdateTaskItem from 'actions/inbox/lib/try_update_task_item';
import UpdateAgentTaskRead from 'actions/task/update_agent_task_read';

export default class RoutingEventGatewayObserver {
  constructor(context) {
    this.context = context;
    this.agentVoiceCapabilitiesChecker = new AgentVoiceCapabilitiesChecker(this.context);
  }

  handleRoutingEvent(eventDto) {
    switch (eventDto.type) {
      case RoutingEventType.UNREAD_CONVERSATION:
        this.handleUnreadConversationEvent(eventDto);
        break;
      case RoutingEventType.UNREAD_TASK:
        this.handleUnreadTaskEvent(eventDto);
        break;
      case RoutingEventType.ASSIGNED_CONVERSATION:
        this.handleAssignedConversationEvent(eventDto);
        break;
      case RoutingEventType.ASSIGNED_TASK:
        this.handleAssignedTaskEvent(eventDto);
        break;
      default:
    }
  }

  handleAssignedTaskEvent(eventDto) {
    const resetNavigation = checkAndResetNavigatingToNext(this.context);
    if (this._isViewingCustomer(eventDto.profile.id)) {
      this.addTaskToInbox(eventDto);
      return;
    }
    preloadCustomer(this.context, eventDto.profile.id);
    removeEmptyQueueNotifications(this.context);

    if (resetNavigation) {
      this.context.executeAction(NavigateToConversation, {
        customerId: eventDto.profile.id,
        conversationId: eventDto.conversationId,
        conversationItemId: eventDto.conversationItem.id,
      });
    } else {
      createAndShowNotification(this.context, eventDto);
    }

    this.addTaskToInbox(eventDto);
  }

  addTaskToInbox(eventDto) {
    addTaskToInbox(this.context, ConversationItem.fromJs(eventDto.conversationItem));
    tryUpdateTaskItem(this.context, {
      id: eventDto.conversationItem.id,
      item: ConversationItem.fromJs(eventDto.conversationItem),
      profile: CustomerProfile.fromJs(eventDto.profile),
    });
  }

  handleAssignedConversationEvent(eventDto) {
    if (eventDto.conversationItem && NOTIFIABLE_CONTENT_TYPES.has(eventDto.conversationItem.content.type)) {
      handleNotifiableEvent(this.context, eventDto);
    }

    addCustomerToInbox(this.context, {
      customerId: eventDto.profile.id,
    });
    tryUpdateInboxItem(this.context, {
      item: eventDto.conversationItem ? ConversationItem.fromJs(eventDto.conversationItem) : undefined,
      profile: CustomerProfile.fromJs(eventDto.profile),
    });
  }

  handleUnreadConversationEvent(eventDto) {
    checkAndResetNavigatingToNext(this.context);

    if (this._isViewingCustomer(eventDto.profile.id)) {
      return;
    }

    preloadCustomer(this.context, eventDto.profile.id);
    removeEmptyQueueNotifications(this.context);

    this.context.executeAction(NavigateToConversation, {
      customerId: eventDto.profile.id,
      conversationId: eventDto.conversationId,
    });
  }

  handleUnreadTaskEvent(eventDto) {
    if (!this._isViewingCustomer(eventDto.profile.id)) {
      preloadCustomer(this.context, eventDto.profile.id);
      removeEmptyQueueNotifications(this.context);
    }

    if (checkAndResetNavigatingToNext(this.context)) {
      this.context.executeAction(UpdateAgentTaskRead, eventDto.conversationItem.id);

      this.context.executeAction(NavigateToConversation, {
        customerId: eventDto.profile.id,
        conversationId: eventDto.conversationId,
        conversationItemId: eventDto.conversationItem.id,
      });
    }
  }

  _isViewingCustomer(customerId) {
    return (
      this.context.stores.currentLocation.get() instanceof CustomerView &&
      this.context.stores.currentLocation.get().customerId === customerId
    );
  }
}

const NOTIFIABLE_CONTENT_TYPES = new Set([
  ConversationItemType.ABANDONED_CALL,
  ConversationItemType.CONVERSATION_NOTE,
  ConversationItemType.CUSTOM_CHANNEL_MESSAGE,
  ConversationItemType.EMAIL_SEND_FAILURE,
  ConversationItemType.EMAIL,
  ConversationItemType.FB_MESSAGE_INCOMING,
  ConversationItemType.PHONE_CALL,
  ConversationItemType.SMS,
  ConversationItemType.TASK,
  ConversationItemType.VOICEMAIL,
]);
