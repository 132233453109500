import _ from 'lodash';

import createModel from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

var prop = createModel.prop;

var TopicAncestry = createModel({
  modelName: 'TopicAncestry',

  properties: {
    id: prop(String).isRequired,
    topicId: prop(String).isRequired,
    ancestralTopicId: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },
  },
});

export default TopicAncestry;
