import createModel from 'models/lib/create_model';
import HtmlToText from 'html-to-text';

export function createContentModel(spec) {
  return createModel({
    isEmpty() {
      throw new Error('Composition content model must define an isEmpty() function');
    },
    ...spec,
  });
}

export function isEmpty(value) {
  return !value || !HtmlToText.fromString(value, { wordwrap: null }).trim().length;
}
