import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function getClassNames(props) {
  return classnames('row', getAlignmentClassNames(props), props.className);
}

function getAlignmentClassNames(props) {
  let alignClassNames = [];
  if (props.align) {
    alignClassNames.push(`${props.align}XS`);
  }
  if (props.vAlign) {
    alignClassNames.push(`${props.vAlign}XS`);
  }
  return alignClassNames;
}

export default function Row(props) {
  return (
    <div className={getClassNames(props)} ref={props.rowRef}>
      {props.children}
    </div>
  );
}

Row.propTypes = {
  align: PropTypes.oneOf(['beginning', 'center', 'end']),
  children: PropTypes.node,
  className: PropTypes.string,
  rowRef: PropTypes.func,
  vAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
};
