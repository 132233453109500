import get from 'lodash/get';

import getNearestColor from '../lib/get_nearest_color';
import getNearestFontSize from '../lib/get_nearest_font_size';

export const gdocsRichTextRules = [
  // Paragraphs and Breaks
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'p' && !hasParentList(el)) {
        const styles = el.style;
        let textAlign = undefined;
        if (styles.textAlign) {
          textAlign = styles.textAlign;
        }

        const nodes = next(el.childNodes);

        return {
          object: 'block',
          type: 'paragraph',
          nodes,
          data: {
            textAlign,
          },
        };
      } else if (el.tagName.toLowerCase() === 'br') {
        return {
          object: 'block',
          type: 'paragraph',
          nodes: next(el.childNodes),
        };
      }
      return next();
    },
  },
  // Rich Text
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'span') {
        const styles = el.style;
        const marks = [];
        if (styles.fontWeight === '700') {
          marks.push({
            type: 'bold',
          });
        }
        if (styles.fontStyle === 'italic') {
          marks.push({
            type: 'italic',
          });
        }
        if (styles.textDecoration === 'underline') {
          marks.push({
            type: 'underline',
          });
        }
        if ((styles.color && styles.color !== '#000000') || styles.fontSize !== '15pt') {
          let data = {};
          // Links are usually blue, but we'll ignore link color here.
          if (styles.color && styles.color !== '#000000' && !hasParentLink(el)) {
            const fontColor = getNearestColor(styles.color);
            data.fontColor = fontColor;
          }
          if (styles.fontSize !== '15pt') {
            const fontSizePt = parseInt(styles.fontSize.slice(0, -2));
            const fontSizePx = Math.round(fontSizePt * 1.33333);
            const nearestFontSize = getNearestFontSize(fontSizePx);
            data.fontSize = nearestFontSize;
          }
          marks.push({ type: 'font', data });
        }

        const isNewline =
          el.childNodes.length === 1 && el.childNodes[0].tagName && el.childNodes[0].tagName.toLowerCase() === 'br';
        return {
          object: 'text',
          text: el.innerText || (isNewline && '\n') || undefined,
          marks,
        };
      }

      return next();
    },
  },
  // Links
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'a') {
        const href = el.href;
        return { object: 'inline', type: 'link', nodes: next(el.childNodes), data: { href } };
      }
    },
  },
];

function hasParentLink(el) {
  return get(el, 'parentElement.tagName', '').toLowerCase() === 'a';
}

function hasParentList(el) {
  return get(el, 'parentElement.tagName', '').toLowerCase() === 'li';
}
