import StandardGateway from './lib/standard_gateway';

const DEFAULT_TIMEOUT = 40000; // 40s due to sc-154049

export default class ScheduledReportGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'reporting/v1/orgs/:orgId/scheduled-reports/:scheduledReportId');
  }

  add(scheduledReport) {
    let url = this.endpoint.addUrl;
    let resource = this.endpoint.patterns.addUrl;
    return this.http
      .post(url, scheduledReport, { timeout: DEFAULT_TIMEOUT }, resource)
      .then(dto => {
        this.observer.onAddSuccess(dto);
        return dto;
      })
      .catch(error => {
        this.observer.onAddError(error);
        throw error;
      });
  }
}
