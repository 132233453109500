import classnames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import XIcon from 'components/lib/icons/x_icon';

export class AnswerDescription extends PureComponent {
  constructor(props) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
  }

  onRemove() {
    this.props.onRemove(this.props.index);
  }

  renderRemove() {
    if (this.props.onRemove && this.props.index != null) {
      return <XIcon className="answerDescription-remove" onClick={this.onRemove} />;
    }
  }

  render() {
    return (
      <div className="answerDescription">
        {this.renderRemove()}
        <div className="answerDescription-name">{this.props.name}</div>
        {this.props.description}
      </div>
    );
  }
}

AnswerDescription.propTypes = {
  description: PropTypes.string.isRequired,
  index: PropTypes.number,
  name: PropTypes.string,
  onRemove: PropTypes.func,
};

export class AnswerDescriptionsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isHidden: true, showChevron: true };
    this.toggleDescriptionVisibility = this.toggleDescriptionVisibility.bind(this);
    this.renderAnswerPanelFormat = this.renderAnswerPanelFormat.bind(this);
    this.renderMentionMenuFormat = this.renderMentionMenuFormat.bind(this);
    this.updateShowChevron = this.updateShowChevron.bind(this);
  }

  componentDidMount() {
    this.updateShowChevron();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAnswerPanel) {
      // checking height of div to see if description is expanded
      if (this.descriptionDiv && this.descriptionDiv.offsetHeight > 20) {
        this.setState({ showChevron: true });
      } else {
        this.updateShowChevron();
      }
    }
  }

  updateShowChevron() {
    if (this.descriptionDiv) {
      let description = this.descriptionDiv;
      let showChevron = description.offsetWidth < description.scrollWidth;
      if (this.state.showChevron !== showChevron) {
        this.setState({ showChevron });
      }
    }
  }

  toggleDescriptionVisibility(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isHidden: !this.state.isHidden });
  }

  render() {
    return this.props.isAnswerPanel ? this.renderAnswerPanelFormat() : this.renderMentionMenuFormat();
  }

  renderMentionMenuFormat() {
    return (
      <div
        className={classnames('answerDescriptionsList', this.props.className, {
          'answerDescriptionsList-hidden': this.state.isHidden,
        })}
      >
        <div className="answerDescriptionsList-label">
          <div className="answerDescriptionsList-noticeIcon" />
          Internal only.
          <div className="answerDescriptionsList-toggle" onClick={this.toggleDescriptionVisibility}>
            {this.state.isHidden ? 'Show' : 'Hide'}
          </div>
        </div>
        {!this.state.isHidden && this.props.children}
      </div>
    );
  }

  renderAnswerPanelFormat() {
    return (
      <div
        className={classnames('answerPanel-answerDescription', {
          'answerPanel-answerDescription-togglable': this.state.showChevron,
        })}
        onClick={this.toggleDescriptionVisibility}
      >
        <div className="answerPanel-answerDescription-label">
          DESCRIPTION
          {this.renderChevron()}
        </div>
        <div
          className={classnames('answerPanel-answerDescription-description', {
            'answerPanel-answerDescription-description-hidden': this.state.isHidden,
          })}
          data-aid="answerPanel-content-description"
          ref={div => {
            this.descriptionDiv = div;
          }}
        >
          {this.props.description}
        </div>
      </div>
    );
  }

  renderChevron() {
    if (!this.state.showChevron) {
      return null;
    }

    const className = classnames({
      'answerPanel-answerDescription-chevron-down': this.state.isHidden,
      'answerPanel-answerDescription-chevron-up': !this.state.isHidden,
    });

    return <div className={className} />;
  }
}

AnswerDescriptionsList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  isAnswerPanel: PropTypes.bool,
};
