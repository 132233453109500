import qconsole from 'scripts/lib/qconsole';
import { Host, ErrorCode } from '@gladly/plugin-sdk';

export function createWidgetHost(iframe, publicToken, { params, context, onEvent, onError }) {
  const host = new Host(iframe);

  // Attach "config override" handler
  host.v1.getConfiguration = async clientID => {
    return clientID === publicToken ? params || [] : undefined;
  };
  host.v1.getContext = async clientID => {
    return clientID === publicToken ? context || {} : undefined;
  };

  // Create a virtual event handler. The client (widget) will call "handleEvent(token, 'xxx', ....)"
  host.v1.addMethod('handleEvent', async (clientID, event, ...args) => {
    if (clientID === publicToken && onEvent) return onEvent(event, ...args);
  });
  host.v1.clientErrorHandler = async (clientID, error, ...args) => {
    if (clientID === publicToken && onError) return onError(error, ...args);
  };

  // Start listening for incoming calls. Ignore the errors from destroyed connections because
  // they are not an indication of a real problem (e.g. error when the client iframe is destroyed during
  // the component unmounting)
  host.listen().catch(err => {
    if (err && err.code === ErrorCode.ConnectionDestroyed) return;
    qconsole.error('Error while waiting for the UI Widget to connect', err);
  });
  return host;
}
