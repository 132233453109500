import React from 'react';
import PropTypes from 'prop-types';

import ConversationNotification from 'models/notification/conversation_notification';
import NotificationContentContainer from './notification_content_container';

function SmsMessageNotification({ notification }) {
  const content = notification.conversationItem.content;
  const displayName = notification.profile.name || content.from;

  return (
    <NotificationContentContainer
      body={content.body}
      heading={displayName}
      iconClass="icon-sms"
      itemType="SMS"
      notification={notification}
    />
  );
}

SmsMessageNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default SmsMessageNotification;
