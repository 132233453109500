import _ from 'lodash';
import Action from 'models/configuration/action';
import createModel, { prop } from './lib/create_model';
import CustomerEmailAddress from 'models/customer_email_address';
import CustomerUsernameAddress from 'models/customer_username_address';
import ExternalCustomerAddress, { ExternalCustomerAddressType } from './customer_profile/external_customer_address';
import FbMessengerUserId from './customer_profile/fb_messenger_user_id';
import FbUserProfile from './customer_profile/fb_user_profile';
import PhoneNumber, { normalizePhoneNumber, SmsPreferenceType } from 'models/phone_number';
import Transaction from './transaction';

const UpdatableAttribute = Object.freeze(['address', 'externalCustomerId']);

export default createModel({
  modelName: 'CustomerProfile',
  properties: {
    id: String,
    address: String,
    customAttributes: prop(Object).default({}),
    transactions: prop([Transaction]).default([]),
    actions: prop([Action]).default([]),
    externalCustomerId: String,
    externalCustomerIds: prop(Object).default({}),
    externalCustomerAddresses: prop([ExternalCustomerAddress]).default([]),
    fbMessengerUserIds: prop([FbMessengerUserId]).default([]),
    fbUserProfile: FbUserProfile,
    image: String,
    name: String,
    merged: Boolean,
    unlinked: Boolean,
    phones: prop([PhoneNumber]).default([]),
    emails: prop([CustomerEmailAddress]).default([]),
    status: prop(String).default(''),
    whatsappIds: prop([CustomerUsernameAddress]).default([]),
    ...UpdatableAttribute.reduce((memo, name) => ({ ...memo, [name]: String }), {}),
    _version: prop(Number).default(0),
  },

  changeName(newName) {
    this.name = newName;
  },

  _getPrimaryPhoneNumber() {
    return _.find(this.phones, phone => phone.primary) || this.phones[0];
  },

  getDefaultEmail() {
    let defaultEmail = _.find(this.emails, { primary: true }) || this.emails[0];
    return defaultEmail && defaultEmail.original;
  },

  isVerified() {
    return this.status !== CustomerStatus.UNVERIFIED;
  },

  getImageUrl() {
    return this.image || (this.fbUserProfile && this.fbUserProfile.profilePic);
  },

  getPrimaryPhoneNumberNormalized() {
    const primaryPhone = this._getPrimaryPhoneNumber();
    return primaryPhone ? primaryPhone.normalized : null;
  },

  getPrimaryPhoneNumberForDisplay() {
    const primaryPhone = this._getPrimaryPhoneNumber();
    return primaryPhone ? primaryPhone.formatForDisplay() : null;
  },

  getSmsPhoneNumbers() {
    return this.phones.filter(phone => phone.smsPreference === SmsPreferenceType.ON);
  },

  getExternalCustomerAddressForType(type) {
    return this.getExternalCustomerAddresses().filter(address => address.type === type);
  },

  getDefaultTwitterAccount() {
    return _.head(this.getExternalCustomerAddressForType(ExternalCustomerAddressType.TWITTER));
  },

  getExternalCustomerAddresses() {
    return this.externalCustomerAddresses;
  },

  updateEmailAddress({ index, original, primary }) {
    // We can only have one primary email address, so if this address is toggled to be the primary,
    // we need to clear the other primary email.
    if (primary) {
      this._clearCurrentPrimaryEmail();
    }

    this.emails[index] = CustomerEmailAddress.create({ ...this.emails[index], original, primary });
  },

  deleteEmailAddress(index) {
    this.emails.splice(index, 1);
  },

  updatePhoneNumber({ index, phoneNumber, extension, primary, smsPreference, type }) {
    // We can only have one primary phone number, so if this number is toggled to be the primary, we need to disable the
    // other primary phone.
    if (primary) {
      this._clearCurrentPrimaryPhone();
    }

    const phoneAttrs = this.phones[index] || {};
    let attrs = { original: phoneNumber, extension, primary, smsPreference, type };

    const newNormalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
    if (newNormalizedPhoneNumber === phoneAttrs.normalized) {
      attrs = Object.assign({}, phoneAttrs, attrs);
    }
    this.phones[index] = PhoneNumber.create(attrs);
  },

  deletePhoneNumber(index) {
    this.phones.splice(index, 1);
  },

  addPhoneNumber({ phoneNumber, extension, smsPreference, primary, index, type }) {
    if (index === this.phones.length || index == null) {
      if (primary) {
        this._clearCurrentPrimaryPhone();
      }
      this.phones.push(PhoneNumber.create({ original: phoneNumber, extension, smsPreference, primary, type }));
    } else {
      this.updatePhoneNumber({ index, phoneNumber, extension, smsPreference, primary, type });
    }
  },

  hasPhoneNumber(phoneNumber) {
    return !!_.find(this.phones, phone => {
      return normalizePhoneNumber(phoneNumber) === phone.normalized;
    });
  },

  hasEmail(email) {
    return !!_.find(this.emails, existingEmail => existingEmail.original.toLowerCase() === email.toLowerCase());
  },

  _clearCurrentPrimaryEmail() {
    const primaryIndex = _.findIndex(this.emails, email => email.primary);
    if (primaryIndex !== -1) {
      const primaryEmail = this.emails[primaryIndex];
      this.emails[primaryIndex] = new CustomerEmailAddress({ ...primaryEmail, primary: false });
    }
  },

  _clearCurrentPrimaryPhone() {
    const primaryIndex = _.findIndex(this.phones, phone => phone.primary);
    if (primaryIndex !== -1) {
      const primaryPhone = this.phones[primaryIndex];
      this.phones[primaryIndex] = new PhoneNumber({ ...primaryPhone, primary: false });
    }
  },

  customChannelAddresses() {
    return this.getExternalCustomerAddressForType(ExternalCustomerAddressType.CUSTOM_CHANNEL);
  },

  instagramAddresses(companyInstagramAddresses) {
    const instagramAddresses = this.getExternalCustomerAddressForType(ExternalCustomerAddressType.INSTAGRAM_DIRECT);
    let transformedInstagramAddresses = [];
    let addInstagramFollowInfoToContent = function(address, content, companyInstagramHandle) {
      if (!companyInstagramHandle) {
        return content;
      }
      content.followedBusinesses = content.followedBusinesses ? content.followedBusinesses : [];
      content.businessFollowers = content.businessFollowers ? content.businessFollowers : [];
      if (address.content.isUserFollowBusiness) {
        content.followedBusinesses = _.concat(content.followedBusinesses, companyInstagramHandle);
      }
      if (address.content.isBusinessFollowUser) {
        content.businessFollowers = _.concat(content.businessFollowers, companyInstagramHandle);
      }
      return content;
    };

    _.map(instagramAddresses, function(address) {
      const companyInstagramEndpoint = _.find(companyInstagramAddresses, ['address', address.content.pageId]);
      const companyInstagramHandle = _.get(companyInstagramEndpoint, 'configuration.handle');
      const transformedAddress = _.find(transformedInstagramAddresses, i => i.id === address.displayName);
      let transformedInstagramAddress = {};
      if (!transformedAddress) {
        let content = { followerCount: address.content.followerCount, isVerifiedUser: address.content.isVerifiedUser };
        content = addInstagramFollowInfoToContent(address, content, companyInstagramHandle);
        transformedInstagramAddress = {
          id: address.displayName,
          type: ExternalCustomerAddressType.INSTAGRAM_DIRECT,
          displayName: address.displayName,
          content,
        };
        transformedInstagramAddresses = _.concat(transformedInstagramAddresses, transformedInstagramAddress);
      } else {
        let content = transformedAddress.content;
        content = addInstagramFollowInfoToContent(address, content, companyInstagramHandle);
        transformedInstagramAddress.content = content;
      }
    });
    return transformedInstagramAddresses;
  },

  updateContact(contact) {
    _.assign(this, _.pick(contact, UpdatableAttribute));
  },

  updateExternalCustomerId(newCustomerId) {
    this.externalCustomerId = newCustomerId;
  },

  updateExternalCustomerIds(newExternalIds) {
    this.externalCustomerIds = _.merge(this.externalCustomerIds, newExternalIds);
  },

  updateStatus(status) {
    this.status = status;
  },

  updateCustomAttributes(update) {
    _.assign(this.customAttributes, update);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const CustomerStatus = Object.freeze({
  VERIFIED: '',
  UNVERIFIED: 'UNVERIFIED',
});

export { CustomerStatus };
