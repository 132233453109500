import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import Snippet from 'models/answers/snippet';

export default createModel({
  modelName: 'SelectedKbItem',

  properties: {
    channelType: prop(String),
    id: prop(String).isRequired,
    item: prop(Snippet).isRequired,
    language: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(_.assign({ id: IdGenerator.newId() }, attrs));
    },
  },
});
