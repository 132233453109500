import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export default function WarningIcon({ className, isError }) {
  return (
    <Svg className={className} fill="none" isError={isError} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M1.618 15h11.764a1 1 0 0 0 .894-1.447L8.394 1.789a1 1 0 0 0-1.788 0L.724 13.553A1 1 0 0 0 1.618 15zM8 12.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM8 6a.5.5 0 1 0-1 0v4a.5.5 0 0 0 1 0V6z"
        fillRule="evenodd"
      />
    </Svg>
  );
}

const Svg = styled.svg`
  path {
    fill: ${p => (p.isError ? p.theme.colors.red500 : p.theme.colors.yellow400)};
  }
`;

Svg.propTypes = {
  isError: PropTypes.bool,
};

WarningIcon.propTypes = Svg.propTypes;
