import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Conversation from 'models/conversation';
import CustomerContext from 'components/customer/customer_context';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import RelativeDateTime from '../../lib/date/relative_date_time';
import { ShortList } from 'components/common/short_list';
import useExecuteAction from 'components/hooks/use_execute_action';

export default function ConversationHeader({
  conversation,
  assignedAgent,
  isActive,
  lastItemTimestamp,
  topics,
  topicsLong,
  onSlaClick,
}) {
  const { customerId } = useContext(CustomerContext);
  const executeAction = useExecuteAction();

  if (!conversation) {
    return <div className="conversationHeader-empty" />;
  }
  let content = null;

  let topicDisplay = (
    <ShortList className="conversationHeader-topic" placeholder="No Topic" tooltipValues={topicsLong} values={topics} />
  );

  const onClick = () => {
    executeAction(NavigateToConversation, {
      anchorPosition: 'top',
      customerId,
      conversationId: conversation.id,
    });
  };

  if (conversation.status === Conversation.Status.CLOSED) {
    const agentName = assignedAgent ? assignedAgent.name : 'An agent';
    const lastWorkDate = lastItemTimestamp || conversation.createdAt;
    const formattedDate = moment(lastWorkDate).format('MMM D, YYYY');

    content = (
      <StyledHeaderContent className="conversationHeader-content conversationHeader-closed" onClick={onClick}>
        {agentName}
        {` worked on\xa0`}
        {topicDisplay}
        {`\xa0on `}
        {formattedDate}
      </StyledHeaderContent>
    );
  } else {
    const formattedDate = moment(conversation.createdAt).format('MMM D');
    let shouldShowSeparator =
      (conversation.sla && conversation.sla.dueAt) || conversation.status === Conversation.Status.WAITING;

    content = (
      <StyledHeaderContent className="conversationHeader-content" onClick={onClick}>
        <div className="conversationHeader-date conversationHeader-emphasized">{formattedDate}</div>
        <div className="conversationHeader-separator">•</div>
        <div className="conversationHeader-emphasized conversationHeader-topicWrapper">{topicDisplay}</div>
        {shouldShowSeparator && <div className="conversationHeader-separator">•</div>}
      </StyledHeaderContent>
    );
  }
  const waitingStatus = (
    <div className="conversationHeader-emphasized conversationHeader-sla conversationHeader-waiting">Waiting</div>
  );
  let conversationId = conversation.id;
  let slaProps = { onSlaClick, conversationId };
  slaProps = _.merge(slaProps, _.pick(conversation.sla, ['dueAt', 'contentType', 'setByConversationItemId']));

  const headerClassNames = classnames('conversationHeader', {
    'conversationHeader-active': isActive,
  });
  return (
    <div className={headerClassNames}>
      <SpacerLeft className="conversationHeader-spacer" />
      {content}
      {conversation.isOpen() && <Sla {...slaProps} />}
      {conversation.status === Conversation.Status.WAITING && waitingStatus}
      <div className="conversationHeader-spacer" />
    </div>
  );
}

const StyledHeaderContent = styled.div`
  cursor: pointer;

  .conversationHeader-topic {
    cursor: pointer;
  }
`;

ConversationHeader.propTypes = {
  assignedAgent: PropTypes.object,
  conversation: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  lastItemTimestamp: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.string),
  topicsLong: PropTypes.arrayOf(PropTypes.string),
  onSlaClick: PropTypes.func,
};

const SpacerLeft = styled.div``;

export function Sla({ dueAt, contentType, setByConversationItemId, onSlaClick, conversationId }) {
  if (dueAt) {
    const danger = moment()
      .add(5, 'minutes')
      .isAfter(dueAt);
    const classNames = classnames('conversationHeader-sla', { 'conversationHeader-sla-danger': danger });
    const slaItemMarker = `#conversationItem-${setByConversationItemId}`;
    const onClick = e => {
      e && e.preventDefault();
      onSlaClick(conversationId, setByConversationItemId);
    };

    return (
      <a className={classNames} href={slaItemMarker} onClick={onClick}>
        <span>
          Due <RelativeDateTime timestamp={dueAt} />
        </span>
      </a>
    );
  }

  return null;
}

Sla.propTypes = {
  contentType: PropTypes.string,
  dueAt: PropTypes.string,
  setByConversationItemId: PropTypes.string,
  onSlaClick: PropTypes.func,
  conversationId: PropTypes.string,
};
