import _ from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AgentProfile from 'models/agent_profile';
import AgentProfileEditor from 'models/location/agent_profile_editor';
import BeginLogOut from 'actions/user/begin_log_out';
import Checkmark from 'components/common/icons/checkmark.jsx';
import connect from 'components/lib/connect';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import GoAway from 'actions/agent_status/go_away';
import InsetContainer from 'components/common/containers/inset_container';
import InteractionType from 'models/interaction_type';
import NavigateToUrl from 'actions/current_location/navigate_to_url';
import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';
import ProfileAvatar from '../lib/profile_avatar';
import { ReasonType } from 'models/agent_status_reason';
import { RoutingStatus } from 'models/routing_queue_item';
import StopImitation from 'actions/user/stop_imitation';
import UpdateActiveReason from 'actions/agent_status/update_active_reason';

const logoutPath = '/user/logout';

export function AgentInfoBar({
  canPerformAgentActions,
  canViewReportOrReportBuilder,
  currentAgent,
  currentActiveReason,
  doesAgentHaveAssignedChats,
  handleActiveClick,
  handleAwayClick,
  handleLogOut,
  handleProfileClick,
  handleStopImitation,
  isAgentOnCall,
  isImitatedAgent,
  reasons,
}) {
  if (!currentAgent) {
    currentAgent = AgentProfile.create();
  }

  const { targetRef, setTargetRef, isOpen, onClose, onToggle } = usePopoverMenu();

  return (
    <React.Fragment>
      <ProfileButton data-aid="agentInfoBar-menu-button" onClick={onToggle} ref={setTargetRef} tabIndex="0">
        <StyledProfileAvatar
          data-aid="agentInfoBar-profileAvatar"
          noWrapper
          profile={{ image: currentAgent.avatarUrl, name: currentAgent.name }}
          tabIndex="0"
        />
      </ProfileButton>
      <StyledPopoverMenu
        data-aid="agent-info-bar"
        isOpen={isOpen}
        onClose={onClose}
        position="bottom"
        targetPosition="start"
        targetRef={targetRef}
      >
        <PopoverMenuItem component={HeaderItem} data-aid="active-header" key="active-header">
          Active
        </PopoverMenuItem>
        {_(reasons)
          .filter(r => r.type === ReasonType.ACTIVE)
          .orderBy(r => r.name.toLowerCase(), ['asc'])
          .map(r => (
            <div key={r.id} title={r.name}>
              <ActiveOption
                data-aid={`active-reason-${r.id}`}
                onClick={() => {
                  handleActiveClick(r.id);
                }}
                selected={isSelected(r.id)}
              >
                {isSelected(r.id) ? <ActiveCheckmark /> : null}
                <ReasonText>{r.name}</ReasonText>
              </ActiveOption>
            </div>
          ))
          .value()}
        <StyledHR className="agentInfoBar-line" />
        <PopoverMenuItem component={HeaderItem} data-aid="away-header" key="away-header">
          Away
        </PopoverMenuItem>
        {_(reasons)
          .filter(r => r.type === ReasonType.AWAY)
          .orderBy(r => r.name.toLowerCase(), ['asc'])
          .map(r => (
            <div key={r.id} title={r.name}>
              <AwayOption
                data-aid={`away-reason-${r.id}`}
                isDisabled={isDisabled()}
                onClick={() => handleAwayClick(r.id)}
              >
                <ReasonText>{r.name}</ReasonText>
              </AwayOption>
            </div>
          ))
          .value()}
        <StyledHR className="agentInfoBar-line" />
        {canPerformAgentActions || canViewReportOrReportBuilder ? (
          <PopoverMenuItem
            data-aid="agentInfoBar-profile-menuItem"
            onClick={() => handleProfileClick(getLocationUrl(AgentProfileEditor.create()))}
          >
            Profile
          </PopoverMenuItem>
        ) : null}
        {isImitatedAgent ? (
          <PopoverMenuItem data-aid="agentInfoBar-stopImitation-menuItem" onClick={handleStopImitation} tabIndex="-1">
            Stop Impersonation
          </PopoverMenuItem>
        ) : (
          <PopoverMenuItem
            data-aid="agentInfoBar-logout-menuItem"
            href={logoutPath}
            onClick={handleLogOut}
            tabIndex="-1"
          >
            Log out
          </PopoverMenuItem>
        )}
      </StyledPopoverMenu>
    </React.Fragment>
  );

  function isDisabled() {
    return isAgentOnCall || doesAgentHaveAssignedChats || isImitatedAgent;
  }

  function isSelected(reasonId) {
    return reasonId === currentActiveReason;
  }
}

export default connect(mapStateToProps, mapExecuteToProps)(AgentInfoBar);

AgentInfoBar.propTypes = {
  canPerformAgentActions: PropTypes.bool,
  canViewReportOrReportBuilder: PropTypes.bool,
  currentActiveReason: PropTypes.string,
  currentAgent: PropTypes.instanceOf(AgentProfile),
  doesAgentHaveAssignedChats: PropTypes.bool.isRequired,
  handleActiveClick: PropTypes.func.isRequired,
  handleAwayClick: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  handleProfileClick: PropTypes.func,
  handleStopImitation: PropTypes.func.isRequired,
  isAgentOnCall: PropTypes.bool.isRequired,
  isImitatedAgent: PropTypes.bool.isRequired,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

const HeaderItem = styled(InsetContainer)`
  color: ${p => p.theme.colors.gray700};
  font-size: ${p => p.theme.fontSize.base};
  font-weight: bold;
  min-width: 100px;
  padding: ${p => p.theme.spacing.medium} ${p => p.theme.spacing.large} ${p => p.theme.spacing.small}
    ${p => p.theme.spacing.large};
`;

const ActiveCheckmark = styled(Checkmark)`
  height: ${p => p.theme.spacing.large};
  width: ${p => p.theme.spacing.large};
  fill: ${p => p.theme.colors.green400};
  margin-right: ${p => p.theme.spacing.medium};
  flex-shrink: 0;
`;
const ActiveOption = styled(PopoverMenuItem)`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${p => p.theme.spacing.medium};
  padding-left: ${p => (p.selected ? p.theme.spacing.large : '40px')};
  padding-right: ${p => p.theme.spacing.large};
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const AwayOption = styled(PopoverMenuItem)`
  height: 32px;
  padding: ${p => p.theme.spacing.small};
  padding-left: 40px;
  padding-right: ${p => p.theme.spacing.large};
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ProfileButton = styled.span`
  width: 34px;
  cursor: pointer;
  outline: none;
`;
const ReasonText = styled.div`
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledPopoverMenu = styled(PopoverMenu)`
  width: 240px;
  height: auto;
  max-height: 80vh;
  overflow: auto;
`;
const StyledProfileAvatar = styled(ProfileAvatar)`
  display: block;
`;
const StyledHR = styled.hr`
  margin: ${p => p.theme.spacing.small} 0 ${p => p.theme.spacing.small} 0;
`;

function mapExecuteToProps(executeAction) {
  return {
    handleActiveClick: reasonId => {
      executeAction(UpdateActiveReason, { reasonId });
    },

    handleAwayClick: reasonId => {
      executeAction(GoAway, { reasonId });
    },

    handleLogOut: () => {
      executeAction(BeginLogOut, { isManual: true });
    },

    handleProfileClick: url => {
      executeAction(NavigateToUrl, url);
    },

    handleStopImitation: () => {
      executeAction(StopImitation);
    },
  };
}

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  let reasons = getProvider('agentStatusReasons').findAll({
    filter: r => !r.disabled,
  });
  let agentStatus = getProvider('agentStatus').get();
  let activeCall = getProvider('activeCall').get();
  let currentActiveReason = agentStatus && agentStatus.getActiveReasonId();
  let currentAgent = getProvider('currentAgent').get();
  let isAgentOnCall = activeCall ? activeCall.isAgentOnLiveCall(currentAgent.id) : false;
  let activeChatSession = getProvider('activeSessions').findBy({
    filter: s =>
      s.queueItem.status === RoutingStatus.ACTIVE &&
      _.get(s.queueItem, 'assignee.agentId') === currentAgent.id &&
      _(s.queueItem.sessions).find({ type: InteractionType.CHAT }),
  });
  let doesAgentHaveAssignedChats = !!activeChatSession;

  const auth = getProvider('auth').get();
  return {
    canPerformAgentActions: isFeatureEnabled('internalAgentActions'),
    canViewReportOrReportBuilder: isFeatureEnabled('viewReports') || isFeatureEnabled('reportBuilder'),
    currentActiveReason,
    currentAgent,
    doesAgentHaveAssignedChats,
    isAgentOnCall,
    isImitatedAgent: !!get(auth, 'claims.saganUser'),
    reasons,
  };
}
