import React, { useContext } from 'react';
import styled from 'styled-components';

import AnswerList from 'components/customer/answer_panel/search/answer_list';
import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';

export default function AnswerFavorites() {
  const { answersList, focusedAnswer } = useContext(AnswerPanelContext);

  return (
    <Results data-aid="answerPanel-favorites">
      <AnswerList answers={answersList.answers} selectedAnswerId={focusedAnswer && focusedAnswer.id} />
    </Results>
  );
}

const Results = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;
