import _ from 'lodash';
import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import { getSuggestionText } from 'models/phrase_suggestion';
import { useGreetingSuggestions } from 'components/composer/contexts/greeting_suggestions_context';
import { usePhraseSuggestions } from 'components/composer/contexts/phrase_suggestions_context';
import { isEditorEmpty } from '../helpers';
import FullSerializer from '../serializers/full_serializer';
import { useVariables } from 'components/composer/contexts/variables_context';
import { updateVariables } from 'components/text_editor/plugins/variables';

const StyledPhraseSuggestion = styled.span`
  color: ${p => p.theme.colors.gray400};
  cursor: default;
`;

const PhraseSuggestionInner = styled.span`
  position: relative;
`;

const PhraseSuggestionTab = styled.span`
  background: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray400};
  border-radius: 4px;
  content: 'Tab';
  cursor: default;
  font-size: 10px;
  margin-left: 2px;
  padding: 0 4px;
  position: fixed;
`;

export class PhraseSuggestion extends React.Component {
  componentDidMount() {
    this.positionTab();
  }
  componentDidUpdate() {
    this.positionTab();
  }

  render() {
    const { attributes, children } = this.props;

    return (
      <StyledPhraseSuggestion {...attributes} contentEditable={false} ref={node => (this.preview = node)}>
        <PhraseSuggestionInner ref={text => (this.text = text)}>{children}</PhraseSuggestionInner>
        <PhraseSuggestionTab ref={tab => (this.tab = tab)}>Tab</PhraseSuggestionTab>
      </StyledPhraseSuggestion>
    );
  }

  positionTab() {
    if (!this.tab || !this.text) {
      return;
    }

    let textRects = this.text.getClientRects();
    let lastRect = _.last(textRects);
    const tabHeight = this.tab.getBoundingClientRect().height;
    const textHeight = lastRect.height;
    const diff = textHeight - tabHeight;

    this.tab.style.top = `${Math.ceil(lastRect.top + diff / 2)}px`;
    this.tab.style.left = `${Math.round(lastRect.right)}px`;
  }
}

export const PHRASE_SUGGESTION = 'phrase_suggestion';

/**
 * Returns a memoized PhraseSuggestions plugin
 */
export function usePhraseSuggestionsPlugin(showGreetingSuggestions = false) {
  const phraseSuggestionContext = usePhraseSuggestions();
  const greetingSuggestionContext = useGreetingSuggestions();
  const { composition } = useContext(ComposerContext);
  const variables = useVariables();
  const compositionId = composition.id;
  return useMemo(
    () =>
      PhraseSuggestions(
        phraseSuggestionContext,
        greetingSuggestionContext,
        showGreetingSuggestions,
        compositionId,
        variables
      ),
    [phraseSuggestionContext, greetingSuggestionContext, showGreetingSuggestions, compositionId, variables]
  );
}

/**
 * Phrase suggestions are shown by, after a change has occurred to the editor, deleting the old phrase suggestion inlines
 * and creating new ones if needed.
 */
export default function PhraseSuggestions(
  phraseSuggestionContext,
  greetingSuggestionContext,
  showGreetingSuggestions,
  compositionId,
  variables
) {
  const { onPhraseSuggestionShown, onPhraseSuggestionUsed } = phraseSuggestionContext;
  const { onUpdateGreetingSuggestionScore } = greetingSuggestionContext;
  let lastSuggestionId; // Purely for tracking purposes

  return {
    commands: { removePhraseSuggestion },

    onBlur(evt, editor, next) {
      editor.removePhraseSuggestion();
      return next();
    },

    onChange(editor, next) {
      const { value } = editor;
      const { document, selection } = value;

      if (!selection.isCollapsed) {
        lastSuggestionId = null;
        editor.removePhraseSuggestion();
        return next();
      }

      // If a user clicks the phrase suggestion, we want to move the cursor to the end of the text node before
      // the suggestion.
      const isSelectionInPhraseSuggestion = !!editor.value.inlines.find(
        i => i.type === PHRASE_SUGGESTION && i.get('data').get('type') !== 'GREETING'
      );
      if (isSelectionInPhraseSuggestion) {
        const suggestionNode = document.getNode(selection.start.key);
        const previousNode = document.getPreviousNode(suggestionNode.key);
        const lastText = previousNode.getLastText();
        if (lastText) {
          editor.moveTo(lastText.key, lastText.text.length);
          editor.focus();
        }
        return next();
      }

      // If the user clicks _after_ the phrase suggestion, we want to move the cursor to the end of the text
      // node before the suggestion.
      const lastText = editor.value.texts.last();
      const previousNode = lastText && document.getPreviousNode(lastText.key);
      if (previousNode && previousNode.type === PHRASE_SUGGESTION) {
        const nodeBeforePhraseSuggestion = document.getPreviousNode(previousNode.key);
        const lastText = nodeBeforePhraseSuggestion && nodeBeforePhraseSuggestion.getLastText();
        if (lastText) {
          editor.moveTo(lastText.key, lastText.text.length);
          editor.focus();
        }
        return next();
      }

      editor.removePhraseSuggestion();
      // If we move the cursor to not the end of a block (ignoring whitespace), we shouldn't see any suggestions.
      const currentBlock = editor.value.document.getClosestBlock(editor.value.selection.start.key);
      const lastBlockText = currentBlock && currentBlock.getTexts().last();
      const isAtEndOfLastBlockText = lastBlockText && editor.value.selection.start.isAtEndOfNode(lastBlockText);
      if (!isAtEndOfLastBlockText) {
        lastSuggestionId = null;
        return next();
      }

      const text = lastBlockText.text;
      const lastSentence = _.last(text.split(/[.!?]/)).trimLeft();
      let suggestion;
      let suggestedText;
      let type;

      // For displaying greeting suggestions when the editor is empty.
      if (showGreetingSuggestions && isEditorEmpty(editor.value)) {
        suggestion = greetingSuggestionContext.getGreetingSuggestion();
        if (suggestion && suggestion.score >= 5) {
          suggestedText = suggestion && suggestion.text;
          type = 'GREETING';
        }
      } else {
        suggestion = phraseSuggestionContext.getPhraseSuggestion(lastSentence);
        suggestedText = getSuggestionText(suggestion, lastSentence);
      }

      if (!suggestedText || !editor.value.selection.isFocused) {
        lastSuggestionId = null;
        return next();
      }

      // We only want to track when it is shown initially.
      if (lastSuggestionId !== suggestion.id) {
        onPhraseSuggestionShown(lastSentence, suggestion, compositionId);
      }
      lastSuggestionId = suggestion.id;

      const suggestionValue = FullSerializer.deserialize(
        `<phraseSuggestion type=${type}>${suggestedText}</phraseSuggestion>`
      );

      // We don't add the phrase suggestion modifications to the editor history to prevent a weird user experience
      // while undoing and redoing.
      editor.withoutSaving(() => {
        editor.insertFragment(suggestionValue.document);
        editor.moveTo(selection.start.key, selection.start.offset);
      });
      updateVariables(editor, variables);
      return next();
    },

    onKeyDown(evt, editor, next) {
      const { value } = editor;
      const { document } = value;
      const shouldComplete = evt.key === 'Tab' || evt.key === 'ArrowRight';
      const phraseSuggestionInlines = document.getInlinesByType(PHRASE_SUGGESTION);
      if (shouldComplete && phraseSuggestionInlines && phraseSuggestionInlines.count() > 0) {
        evt.preventDefault();

        let suggestion;

        const lastText = editor.value.texts.last();
        const text = lastText.text;
        const lastSentence = _.last(text.split(/[.!?]/)).trimLeft();
        if (
          showGreetingSuggestions &&
          phraseSuggestionInlines
            .first()
            .get('data')
            .get('type') === 'GREETING'
        ) {
          suggestion = greetingSuggestionContext.getGreetingSuggestion();
          suggestion && onUpdateGreetingSuggestionScore(suggestion.id);
        } else {
          suggestion = phraseSuggestionContext.getPhraseSuggestion(lastSentence);
        }

        editor.unwrapInlineByKey(phraseSuggestionInlines.first().key, PHRASE_SUGGESTION);
        updateVariables(editor, variables);
        editor.moveToEndOfBlock();
        onPhraseSuggestionUsed(lastSentence, evt.key, suggestion, compositionId);
        return true;
      }

      editor.removePhraseSuggestion();
      return next();
    },

    renderNode(props, editor, next) {
      if (props.node.type === PHRASE_SUGGESTION) {
        return <PhraseSuggestion editor={editor} {...props} />;
      }
      return next();
    },

    schema: {
      inlines: {
        [PHRASE_SUGGESTION]: {
          nodes: [
            {
              match: { object: 'text' },
              min: 1,
            },
            {
              types: ['variable'],
            },
          ],
          text: /.+/, // Can't have empty phrase suggestions
        },
      },
    },
  };
}

function removePhraseSuggestion(editor) {
  const { value } = editor;
  const { document } = value;
  const phraseSuggestionInlines = document.getInlinesByType(PHRASE_SUGGESTION);

  if (phraseSuggestionInlines.count()) {
    editor.withoutSaving(() => {
      phraseSuggestionInlines.forEach(phraseSuggestionInline => {
        editor.removeNodeByKey(phraseSuggestionInline.key);
      });
    });
  }
}
