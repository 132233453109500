import get from 'lodash/get';
import analytics from 'scripts/lib/analytics';
import Communicator from 'models/communicator';

// Tracks when outgoing email conversation items are truncated in supernova and agents are only shown the plaintext version in the feed
export default function(oldConversationItem, conversationItem) {
  // if the item in the store does not have bodyHtmlStripped then we've already tracked this event for that item
  if (
    get(oldConversationItem, 'content.bodyHtmlStripped') &&
    !conversationItem.content.bodyHtmlStripped &&
    conversationItem.initiator.type === Communicator.AGENT
  ) {
    analytics.track('Plaintext Email Displayed', {
      customerId: conversationItem.customerId,
      conversationId: conversationItem.conversationId,
      conversationItemId: conversationItem.id,
    });
  }
}
