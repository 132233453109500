import { Factory } from 'rosie';

const customerId = 'example-customer-id';
const conversationId = 'example-conversation-id';
const conversationItemId = 'example-conversation-item-id';

Factory.define('CustomerView').attr('name', 'CUSTOMER_VIEW');

Factory.define('CustomerViewWithDefaults')
  .extend('CustomerView')
  .attr('customerId', customerId)
  .attr('currentCompositionId', 'example-composition-id')
  .attr('currentConversationId', conversationId)
  .attr('currentConversationItemId', conversationItemId);
