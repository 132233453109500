import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DateRange } from 'react-date-range';

const DateRangeWrapper = ({ startDate, endDate, onChange }) => {
  return (
    <DateRange
      endDate={moment(endDate)}
      linkedCalendars
      onChange={onChange}
      startDate={moment(startDate)}
      theme={{
        Calendar: {
          color: null,
          width: 250,
        },
        MonthAndYear: {
          color: null,
          fontSize: null,
        },
        MonthButton: {
          background: null,
        },
        MonthArrow: {
          border: null,
          display: null,
        },
        MonthArrowPrev: {
          borderRightColor: null,
        },
        MonthArrowNext: {
          borderLeftColor: null,
        },
        Weekday: {
          color: null,
          fontWeight: null,
        },
        DayPassive: {
          color: null,
        },
        DaySelected: {
          background: null,
          color: null,
        },
        DayInRange: {
          background: null,
          color: null,
        },
      }}
    />
  );
};

DateRangeWrapper.propTypes = {
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired, // onChange({ startDate, endDate })
};

export default DateRangeWrapper;
