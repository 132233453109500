import _ from 'lodash';
import ChartAxis from '../../reporting/report/lib/chart_axis';
import ChartDateAxis from '../../reporting/report/lib/chart_date_axis';
import ChartLine from '../../reporting/report/lib/chart_line';
import d3 from 'd3';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import numeral from 'numeral';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';

import T from 'prop-types';

class TrendLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }

  render() {
    var trendStats = TrendLineChart.fillInData(this.props.data.toJS());

    var values = _.chain(trendStats)
      .map(trend => [trend.newConversations, trend.closedConversations])
      .flatten()
      .value();
    var times = _.map(trendStats, trend => moment(trend.timestamp).valueOf());

    var yScale = this.getYScale(values);
    var xScale = this.getTimeScale(times);

    var newConvoData = _.map(trendStats, trend => [moment(trend.timestamp).valueOf(), trend.newConversations]);
    var closedConvoData = _.map(trendStats, trend => [moment(trend.timestamp).valueOf(), trend.closedConversations]);

    var size = {
      width: this.getSvgWidth() - TrendLineChart.X_OFFSET,
      height: TrendLineChart.SVG_HEIGHT - TrendLineChart.Y_OFFSET,
      xOffset: TrendLineChart.X_OFFSET,
      yOffset: TrendLineChart.Y_OFFSET,
    };
    return (
      <svg className="trendLineChart" height={TrendLineChart.SVG_HEIGHT} width={this.getSvgWidth()}>
        <ChartAxis
          className="trendLineChart-yAxis"
          isYAxis
          scale={yScale}
          showMainLine
          size={size}
          tickFormat={num => numeral(num).format(numeral(num).value() >= 1000 ? '0.0a' : '0a')}
        />
        <ChartDateAxis
          aggregationLevel={TrendLineChart.AGGREGATION_LEVEL}
          className="trendLineChart-xAxis"
          endAt={_.max(times)}
          isUtc
          scale={xScale}
          showMainLine
          size={size}
          startAt={_.min(times)}
        />
        <ChartLine
          className="trendLineChart-new"
          data={newConvoData}
          showPoints={false}
          xScale={xScale}
          yScale={yScale}
        />
        <ChartLine
          className="trendLineChart-closed"
          data={closedConvoData}
          showPoints={false}
          xScale={xScale}
          yScale={yScale}
        />
      </svg>
    );
  }

  /* helpers*/
  getYScale(values) {
    var maxValue = _.max(values.concat([5]));

    return d3.scale
      .linear()
      .domain([0, maxValue])
      .rangeRound([TrendLineChart.SVG_HEIGHT - TrendLineChart.Y_OFFSET, TrendLineChart.Y_OFFSET])
      .nice();
  }

  getTimeScale(times) {
    var min = _.min(times);
    var max = _.max(times);

    return d3.time
      .scale()
      .domain([min, max])
      .rangeRound([TrendLineChart.X_OFFSET, this.getSvgWidth() - TrendLineChart.X_OFFSET / 2])
      .nice();
  }

  getSvgWidth() {
    var svgWidth = this.props.svgWidth;
    var width = svgWidth && svgWidth > TrendLineChart.SVG_MIN_WIDTH ? svgWidth : TrendLineChart.SVG_MIN_WIDTH;
    return width;
  }
}

TrendLineChart.propTypes = {
  data: ImmutablePropTypes.listOf(
    T.shape({
      timestamp: T.string,
      newConversations: T.number,
      closedConversations: T.number,
    })
  ).isRequired,
  svgWidth: T.number,
};

TrendLineChart.SVG_MIN_WIDTH = 350;
TrendLineChart.SVG_HEIGHT = 225;
TrendLineChart.X_OFFSET = 50;
TrendLineChart.Y_OFFSET = 30;

TrendLineChart.fillInData = function(trendStats) {
  if (trendStats.length === 0) {
    return trendStats;
  }
  var fullData = [];
  var lastTime = moment
    .utc(trendStats[0].timestamp.slice(0, 19), 'YYYY-MM-DD hh:mm')
    .startOf('day')
    .subtract(1, 'hour'); // 11PM the night before
  trendStats.forEach(function(val) {
    val.timestamp = moment.utc(val.timestamp.slice(0, 19), 'YYYY-MM-DD hh:mm');
    var diff = moment(val.timestamp).diff(lastTime, 'hour');
    if (diff > 1) {
      _.times(diff - 1, function() {
        lastTime.add(1, 'hour');
        fullData.push({
          newConversations: 0,
          closedConversations: 0,
          timestamp: moment(lastTime),
        });
      });
    }
    fullData.push(val);
    lastTime = moment(val.timestamp);
  });
  return fullData;
};

export default TrendLineChart;
