import DtoConverter from 'scripts/application/dto_converters/communication_queue_converter';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import qconsole from 'scripts/lib/qconsole';

export default class CommunicationQueueGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.communicationQueues;
  }

  onFetchAllSuccess(communicationQueuesDto) {
    this.store.set(communicationQueuesDto.map(DtoConverter.fromDto));
  }

  onFetchAllError(payload) {
    qconsole.log(`Failed to fetch communicationQueues list ${payload}`);
  }

  onBroadcast(communicationQueueDto) {
    this.store.addOrReplace(DtoConverter.fromDto(communicationQueueDto));
  }

  onUpdateError(err, { queueId }) {
    this.store.resetPending(queueId);
    handleCommonErrors(this.context, err);
  }

  onUpdateSuccess(queue, { queueId }) {
    this.store.commitPending(queueId);
    qconsole.log('successfully updated communication queue');
  }
}
