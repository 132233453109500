import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import createBlankError from '../factories/error/create_blank_error';
import createTooLongError from '../factories/error/create_too_long_error';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const Team = createModel({
  modelName: 'Team',
  properties: {
    id: prop(String),
    agentIds: prop([String]).default([]),
    createdBy: prop(String).default(''),
    description: prop(String).default(''),
    name: prop(String),
    _version: prop(Number).default(1),
  },

  isNew() {
    return !this.id;
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },
    MAX_LENGTH: {
      name: 50,
    },
    getValidationErrors,
  },
});

function getValidationErrors({ name }) {
  let errors = [];

  if (!name || _.isEmpty(name.trim())) {
    errors.push(createBlankError('name'));
  }

  if (name && name.length > Team.MAX_LENGTH.name) {
    errors.push(createTooLongError('name', `${Team.MAX_LENGTH.name} characters`, 'name'));
  }

  return errors;
}

export default Team;
