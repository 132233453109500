import _ from 'lodash';

import ActivateComposition from '../activate_composition';
import analytics from 'scripts/lib/analytics';
import changeComposition from 'actions/composition/lib/change_composition';
import { getLatestConversation } from 'models/customer';
import Composition from 'models/composition';
import CustomerView from 'models/location/customer_view';
import Endpoint from 'models/endpoint';
import Err from 'models/err';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import qconsole from 'scripts/lib/qconsole';
import { typeReflect as CompositionTypeReflect } from 'models/composition/composition_content_from_js';

export const MAX_OPEN_COMPOSITIONS = 5;

class CreateComposition {
  constructor(context, contentClass) {
    this.context = context;
    this.contentClass = contentClass;
  }

  run(attrs) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    let compositionStore = getCompositionsStore(this.context);
    if (!this._canCreateComposition(attrs)) {
      return;
    }

    let conversation = this.getLatestConversation();
    if (!conversation) {
      qconsole.log('Ignored an attempt to create a composition without any conversations');
      return;
    }

    if (!this._canAddComposition(conversation.id)) {
      let err = [
        new Err({
          code: Err.Code.TOO_LONG,
          detail: 'Maximum channels reached. Close a channel to open another',
        }),
      ];
      compositionStore.setErrorsForNew(err);
      return;
    }

    const compositions = compositionStore.findAll();
    const existingCompositionOfSameType = _.find(
      compositions,
      composition =>
        !compositionStore.isPendingDelete(composition.id) && this._isSameCompositionType(attrs, composition)
    );

    if (existingCompositionOfSameType && !this._allowDuplicate()) {
      changeComposition(this.context, existingCompositionOfSameType);
      if (!_.get(attrs, 'dontActivate')) {
        this.context.executeAction(ActivateComposition, {
          composition: existingCompositionOfSameType,
          toggleMode: false,
        });
      }
      return;
    }
    let content = this._createContent({ ...attrs, conversation });
    if (!content) {
      return;
    }
    let customerId = this.context.stores.currentLocation.get().customerId;

    let composition = this._createComposition({ ...attrs, conversationId: conversation.id, content });
    const customerStores = this.context.stores.customers.storesFor(customerId);
    customerStores.compositions.add(composition);
    this.context.stores.localCompositions.set(customerId, composition);

    const contentType = CompositionTypeReflect.instanceClassToType(this.contentClass);
    analytics.track('Conversation Response Created', {
      customerId: composition.customerId,
      conversationId: composition.conversationId,
      compositionId: composition.id,
      contentType,
    });
    if (!_.get(attrs, 'dontActivate')) {
      this.context.executeAction(ActivateComposition, { composition });
    }
  }

  _isSameCompositionType(attrs, composition) {
    return composition.content instanceof this.contentClass;
  }

  _getLatestManualItemOfType({ type, conversationId }) {
    const customerId = this.context.stores.currentLocation.get().customerId;
    const { conversationHistory } = this.context.stores.customers.storesFor(customerId);
    return getLatestManualItem({
      conversationHistory,
      conversationId,
      filter: item => item.content.type === type,
    });
  }

  _getFroms({ allFromNumbers, conversation, endpoints, endpointType, routingGroupId }) {
    let firstRoutingGroupNumber = _.chain(this.context.stores.communicationQueues.findAll())
      .filter(commQueue => commQueue.routingGroupId === routingGroupId)
      .map(commQueue => _.find(endpoints, endpoint => endpoint.id === commQueue.endpointId))
      .compact()
      .map(endpoint => endpoint.address)
      .head()
      .value();

    const channelConfiguration = this.context.stores.channelConfiguration.get();
    const currentInbox = this.context.stores.routingGroups.findBy({ id: conversation.assignee.routingGroupId });
    const defaultEndpointId = currentInbox && currentInbox.defaultEndpoints[endpointType];
    const defaultEndpoint =
      channelConfiguration && _.find(channelConfiguration.endpoints, e => e.id === defaultEndpointId);
    const defaultAddress = defaultEndpoint && defaultEndpoint.address;

    return {
      default: defaultAddress || firstRoutingGroupNumber || allFromNumbers[0],
      preferred: firstRoutingGroupNumber,
      allFromNumbers,
    };
  }

  _createComposition({ attachments, content, conversationId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    return Composition.create({ attachments, content, conversationId, customerId: currentLocation.customerId });
  }

  _canAddComposition(conversationId) {
    let numOpenCompositions = this._getNumOpenCompositions(conversationId);
    return numOpenCompositions < MAX_OPEN_COMPOSITIONS;
  }

  _canCreateComposition(attrs) {
    return true;
  }

  _getNumOpenCompositions(conversationId) {
    return getCompositionsStore(this.context).findAll({ conversationId }).length;
  }

  /* Can be overridden by subclasses to provide custom content creation logic.
   * Although the attrs are not used here, they are exposed to be used in
   * subclasses when needed.
   **/
  _createContent(attrs) {
    return new this.contentClass();
  }

  // Can be overridden to allow for more than composition of the same type
  _allowDuplicate() {
    return false;
  }

  getLatestConversation() {
    return getLatestConversation(this.customerStores.conversations.findAll());
  }

  get customerId() {
    return this.context.stores.currentLocation.get().customerId;
  }

  get customerStores() {
    return this.context.stores.customers.storesFor(this.customerId);
  }
}

export default CreateComposition;

export function getDescriptivePhoneNumbers({ getProvider, endpointType }) {
  let channelConfiguration = getProvider('channelConfiguration').get();
  let channelEndpoints = channelConfiguration ? channelConfiguration.getEndpointsOfType(endpointType) : [];
  let communicationQueues = getProvider('communicationQueues').findAll();
  let descriptiveNumbers = [];
  let voiceConfiguration = getProvider('voiceConfiguration').get();

  _.forEach(channelEndpoints, endpoint => {
    let commQueue = _.find(communicationQueues, { endpointId: endpoint.id });
    if (
      endpointType !== Endpoint.Type.VOICE ||
      !_.includes(voiceConfiguration.disableOutboundCallList, endpoint.address)
    ) {
      const label = _.get(endpoint, 'name', '') || _.get(commQueue, 'name', '');

      descriptiveNumbers.push({ number: endpoint.address, label });
    }
  });

  return _.sortBy(descriptiveNumbers, ['label', 'number']);
}
