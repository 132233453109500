import { EditorState, Modifier, RichUtils } from 'draft-js';

import getCurrentlySelectedBlock from './draft_selected_block';
import toggleAlignment, { ALIGNMENT_DATA_KEY } from './draft_alignment';

// Custom block splitter so that we preserve alignment when hitting return.
// Taken from: https://gist.github.com/joshdover/7c5e61ed68cc5552dc8a25463e357960
export function splitBlock(editorState) {
  // Original split logic
  const contentState = Modifier.splitBlock(editorState.getCurrentContent(), editorState.getSelection());
  const splitState = EditorState.push(editorState, contentState, 'split-block');

  // Assign alignment if previous block has alignment. Note that `currentBlock` is the block that was selected
  // before the split.
  const { currentBlock } = getCurrentlySelectedBlock(editorState);
  const alignment = currentBlock.getData().get(ALIGNMENT_DATA_KEY);
  if (alignment) {
    return toggleAlignment(splitState, alignment);
  }

  return splitState;
}

export function insertSoftNewLine(editorState) {
  let contentStateSelectionRemoved = Modifier.removeRange(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    'backward'
  );
  editorState = EditorState.push(editorState, contentStateSelectionRemoved, 'insert-characters');
  return RichUtils.insertSoftNewline(editorState);
}
