import _ from 'lodash';
import map from 'lodash/map';

import AgentNotification from 'models/agent_notification';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';
import { PAGE_SIZE } from './request_agent_notifications';
import setHasReachedEnd from './set_has_reached_end';

export default class FetchNextAgentNotificationsPage {
  constructor(context) {
    this.context = context;
  }

  run() {
    const meta = this.context.stores.agentNotificationMeta.get();
    const notifications = this.context.stores.agentNotifications.findAll();
    if (!notifications.length || meta.hasReachedEnd) {
      return;
    }

    const earliestNotification = _.last(_.orderBy(notifications, ['createdAt'], ['desc']));

    this.context.stores.agentNotifications.setLoading();
    this.context.gateways.agentNotification
      .fetchBefore(earliestNotification.createdAt)
      .then(notificationsDto => {
        const agentNotifications = map(notificationsDto, notification => AgentNotification.fromJs(notification));
        _.forEach(agentNotifications, notification => {
          this.context.stores.agentNotifications.addOrReplace(notification);
        });

        if (agentNotifications.length < PAGE_SIZE) {
          setHasReachedEnd(this.context);
        }
        this.context.analytics.track('Next Agent Notifications Page Fetched', {
          hasReachedEnd: agentNotifications.length < PAGE_SIZE,
          numberOfNotificationsLoaded: notifications.length + agentNotifications.length,
        });
      })
      .catch(errDto => {
        handleCommonErrors(this.context, errDto);
      })
      .finally(() => {
        this.context.stores.agentNotifications.resetLoading();
      });
  }
}
