import { postMessage } from './multiple_tabs_helpers';

export default class CloseMultipleTabsChannel {
  constructor(context) {
    this.context = context;
  }

  run() {
    postMessage({ type: 'end' });
  }
}
