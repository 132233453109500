import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import createEnum from 'scripts/lib/create_enum';

import SelectedFill from 'components/common/icons/fill/selected-fill.jsx';
import SettingsFill from 'components/common/icons/fill/settings-fill.jsx';
import DangerFill from 'components/common/icons/fill/danger-fill.jsx';
import SmsStrokeIcon from 'components/common/icons/stroke/sms-stroke';
import WarningStroke from 'components/common/icons/stroke/warning-stroke';

const Types = createEnum('success', 'warning', 'error', 'static', 'task');

/**
 * @visibleName Banner
 */

const Banner = ({ type, className, withIcon, titleList, buttons, customIcon }) => {
  const iconConfig = [
    {
      type: Types.success,
      icon: SuccessIcon,
      backgroundColor: 'green400',
    },
    {
      type: Types.warning,
      icon: WarningIcon,
      backgroundColor: 'yellow400',
    },
    {
      type: Types.error,
      icon: ErrorIcon,
      backgroundColor: 'red400',
    },
    {
      type: Types.static,
      icon: SettingsIcon,
      backgroundColor: 'gray700',
    },
    {
      type: Types.task,
      icon: InformationIcon,
      backgroundColor: 'black',
    },
  ];

  const IconConfig = iconConfig.find(config => config.type === type) || iconConfig[0];
  const IconItem = customIcon || IconConfig.icon;
  return (
    <BannerContainer backgroundColor={IconConfig.backgroundColor} className={className} type={type}>
      <StyledBody>
        {withIcon && (
          <StyledIcon>
            <IconItem />
          </StyledIcon>
        )}
        {titleList?.map((title, i) => (
          <StyledTitle key={i} type={type}>
            {title}
          </StyledTitle>
        ))}
      </StyledBody>
      {buttons && <StyledButtons>{buttons}</StyledButtons>}
    </BannerContainer>
  );
};

const BannerWarning = props => (
  <Banner {...props} type={Types.warning}>
    {props.children}
  </Banner>
);

const BannerSuccess = props => (
  <Banner {...props} type={Types.success}>
    {props.children}
  </Banner>
);

const BannerError = props => (
  <Banner {...props} type={Types.error}>
    {props.children}
  </Banner>
);

const BannerStatic = props => (
  <Banner {...props} type={Types.static}>
    {props.children}
  </Banner>
);

const BannerTask = props => (
  <Banner {...props} type={Types.task}>
    {props.children}
  </Banner>
);

const ErrorIcon = styled(DangerFill)`
  fill: ${p => p.theme.colors.white};
`;

const WarningIcon = styled(WarningStroke)`
  fill: ${p => p.theme.colors.black};
`;

const InformationIcon = styled(SelectedFill)`
  fill: ${p => p.theme.colors.white};
  stroke: ${p => p.theme.colors.black};
`;

const SettingsIcon = styled(SettingsFill)`
  fill: ${p => p.theme.colors.white};
`;

const SuccessIcon = styled(SmsStrokeIcon)`
  fill: ${p => p.theme.colors.white};
`;

const StyledIcon = styled.div`
  fill: ${p => p.theme.colors.white};
  height: ${p => p.theme.fontSize.medium};
  min-width: ${p => p.theme.fontSize.medium};
`;

const StyledTitle = styled.div`
  display: inline-block;
  padding: 0 10px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 24px;
    transform: translate(1px, -50%);
    background-color: ${({ theme, type }) => (type === Types.warning ? theme.colors.black : theme.colors.white)};
  }

  &:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:last-child:after {
    display: none;
  }
`;

const StyledButtons = styled.div`
  display: block;
`;

const StyledBody = styled.div`
  display: flex;
  align-items: center;

  max-width: calc(100% - 180px);
`;

const BannerContainer = styled.div`
  width: 100%;
  align-items: top;
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
  color: ${({ theme, type }) => (type === Types.warning ? theme.colors.black : theme.colors.white)};
  font-size: 13px;
  line-height: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: 5px 15px;
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
`;

Banner.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  titleList: PropTypes.arrayOf(PropTypes.node),
  withIcon: PropTypes.bool,
  customIcon: PropTypes.element,
};

Banner.defaultProps = {
  type: Types.success,
  titleList: [],
  withIcon: true,
  customIcon: null,
};

export default Banner;
export { BannerSuccess, BannerWarning, BannerError, BannerStatic, BannerTask, Types };
