import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('AbandonedCall').attr('type', 'ABANDONED_CALL');

Factory.define('AbandonedCallWithDefaults')
  .extend('AbandonedCall')
  .attr('customerNumber', '+15555555555');

Factory.define('AbandonedCallItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('AbandonedCallWithDefaults', content);
  });

Factory.define('AbandonedCallItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('AbandonedCallWithDefaults', content);
  })
  .attr('initiator', {
    type: 'CUSTOMER',
    id: 'default-initiator-id',
  });
