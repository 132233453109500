import ConversationItem from '../conversation_item';
import createModel, { prop } from '../lib/create_model';
import NotificationType from './notification_type';

export default createModel({
  modelName: 'UnofferedWarmTransferNotification',

  properties: {
    id: prop(String),
    conversationItem: prop(ConversationItem),
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.UNOFFERED_WARM_TRANSFER });
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
