export default class RedactConversationItem {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, itemId }) {
    this.context.gateways.customerHistoryRedaction.redactContent({ customerId, itemId });

    this.context.analytics.track('conversation item redaction - initiated', {
      customerId,
      itemId,
    });
  }
}
