import isArray from 'lodash/isArray';
import qconsole from 'scripts/lib/qconsole';

import CustomChannel from 'models/custom_channel';

export default class CustomChannelGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchAllSuccess(data) {
    this.handleUpdateAll(data);
  }

  onBroadcast(data) {
    this.handleUpdateItem(data);
  }

  onFetchAllError(error) {
    this.context.stores.customChannels.resetLoading();
    qconsole.error(`Unexpected error requesting custom channels list: ${JSON.stringify(error)}`);
  }

  handleUpdateAll(data) {
    this.context.stores.customChannels.resetLoading();
    if (!isArray(data)) {
      qconsole.error(
        `CustomChannelGatewayObserver.handleUpdateAll: Unexpected data - expected array, got [${JSON.stringify(data)}]`
      );
      return;
    }

    this.context.stores.customChannels.set(data.map(customChannel => CustomChannel.fromJs(customChannel)));
  }

  handleUpdateItem(data) {
    if (!data || !data.id) {
      qconsole.error(`CustomChannelGatewayObserver.handleUpdateItem: Unexpected data. Got [${JSON.stringify(data)}]`);
      return;
    }

    this.context.stores.customChannels.addOrReplace(CustomChannel.fromJs(data));
  }
}
