import ReportBuilderConfig from 'models/report_builder_config';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';

export default class ReportBuilderConfigGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  get store() {
    return this.context.stores.reportBuilderConfig;
  }

  onBroadcast(dto) {
    this.store.set(ReportBuilderConfig.fromJs(dto));
    this.context.stores.reportBuilderConfig.resetLoading();
  }

  onBroadcastDelete() {
    this.store.remove();
  }

  onFetchSuccess(dto) {
    this.store.set(ReportBuilderConfig.fromJs(dto));
    this.context.stores.reportBuilderConfig.resetLoading();
  }

  onFetchError(error) {
    this.errorHandler.handleCommonErrors(error);
    this.context.stores.reportBuilderConfig.resetLoading();
  }
}
