import CreateChatComposition from './create_chat_composition';
import CreateComposition from './lib/create_composition';
import CreateCustomChannelComposition from './create_custom_channel_composition';
import CreateEmailComposition, { canCreateEmailComposition } from './create_email_composition';
import CreateFbMessageComposition from './create_fb_message_composition';
import CreateInstagramComposition from './create_instagram_composition';
import CreateNoteComposition from './create_note_composition';
import CreateSmsComposition, { canCreateSmsComposition } from './create_sms_composition';
import CreateTweetComposition from './create_tweet_composition';
import CreateWhatsAppComposition from './create_whatsapp_composition';

import ConversationItemType from 'models/conversation_item_type';
import { getLatestManualCustomerConversationItemAttrs } from 'scripts/application/lib/conversation_history_helpers';
import { getSessionId } from 'actions/composition/activate_default_response';
import { MessageChannelType } from 'models/conversation_message';
import { SnippetChannel } from 'models/answers/snippet';

export default class CreateCompositionFromAnswerType extends CreateComposition {
  run({ answerType, conversationId }) {
    if (!this.context.stores.appFeatures.get().isEnabled('externalAgentActions')) {
      this.context.executeAction(CreateNoteComposition, { conversationId });
      return;
    }

    const customerId = this.context.stores.currentLocation.get().customerId;
    const { conversationHistory, conversations } = this.context.stores.customers.storesFor(customerId);
    const conversation = conversations.findBy({ id: conversationId });
    const latestCustomerAttrs = getLatestManualCustomerConversationItemAttrs({
      conversationHistory,
      conversationId: conversation.id,
      fields: ['content'],
    });
    const latestCustomerContent = latestCustomerAttrs && latestCustomerAttrs.content;
    let contentType = latestCustomerContent && latestCustomerContent.type;
    if (contentType === ConversationItemType.CONVERSATION_MESSAGE) {
      contentType = latestCustomerContent.channel;
    }

    if (answerType === SnippetChannel.ANY_CHANNEL) {
      if (canCreateEmailComposition(this.context)) {
        this.context.executeAction(CreateEmailComposition, { conversationId });
        return;
      }
    } else if (answerType === SnippetChannel.MESSAGE) {
      const sessionId = getSessionId(this.customerId, this.context, this.customerStores);
      if (sessionId) {
        this.context.executeAction(CreateChatComposition, {
          conversationId,
          sessionId,
        });
        return;
      }

      if (contentType === MessageChannelType.INSTAGRAM_DIRECT) {
        this.context.executeAction(CreateInstagramComposition, { conversationId });
        return;
      }

      if (contentType === ConversationItemType.CUSTOM_CHANNEL_MESSAGE) {
        this.context.executeAction(CreateCustomChannelComposition, {
          conversationId,
          customChannelId: latestCustomerContent?.customChannelId,
        });
        return;
      }

      if (contentType === MessageChannelType.TWITTER) {
        const customerTwitterHandle = latestCustomerContent && latestCustomerContent.meta['handle'];
        const customerTwitterAddress = latestCustomerContent && latestCustomerContent.customerAddress;
        this.context.executeAction(CreateTweetComposition, {
          conversationId,
          customerTwitterHandle,
          customerTwitterAddress,
        });
        return;
      }

      if (contentType === MessageChannelType.WHATSAPP) {
        this.context.executeAction(CreateWhatsAppComposition, { conversationId });
        return;
      }

      if (contentType === ConversationItemType.FB_MESSAGE_INCOMING) {
        this.context.executeAction(CreateFbMessageComposition, { conversationId });
        return;
      }

      if (canCreateSmsComposition(this.context)) {
        this.context.executeAction(CreateSmsComposition, { conversationId });
        return;
      }
    }

    this.context.executeAction(CreateNoteComposition, { conversationId });
  }

  _createContent() {
    return this.content.createResponse();
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }
}
