import { addPendingRequestIdForConversation } from 'actions/composition/lib/update_conversation_workflow';
import ChatMessage, { OutgoingChatOperation } from 'models/chat_message';
import ConversationMessageAttachment from 'models/conversation_message_attachment';
import createAgentChatMessage from 'scripts/domain/factories/conversation_item/create_agent_chat_message';
import CreateChatComposition from './create_chat_composition';
import Err from 'models/err';
import { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';
import SubmitCompositionMultipleItems from 'actions/composition/lib/submit_composition_multiple_items';
import unicodeByteLength from 'scripts/lib/unicode_byte_length';
import Upload from 'models/upload';
import { isEmpty } from 'models/composition/composition_content_shared';

export default class SendChatMessage extends SubmitCompositionMultipleItems {
  createOutgoingItems(composition, composedContent) {
    if (unicodeByteLength(composedContent.text) > ChatMessage.MAX_MESSAGE_SIZE) {
      return {
        compositionErrors: [
          new Err({
            attr: 'text',
            code: Err.Code.TOO_LONG,
            detail: `Your message can contain a maximum of ${ChatMessage.MAX_MESSAGE_SIZE} characters`,
          }),
        ],
      };
    }

    if (isEmpty(composedContent.text) && !composition.hasAttachments()) {
      return {
        compositionErrors: [
          new Err({
            attr: 'text',
            code: Err.Code.BLANK,
            detail: 'Message cannot be empty',
          }),
        ],
      };
    }

    let compositionErrors = composition.validateAttachmentStatus();
    compositionErrors = compositionErrors.concat(composition.validateTotalAttachmentSize());
    if (compositionErrors.length) {
      return { compositionErrors };
    }

    let attachments = this.createChatAttachments(composition);
    let chatMessageItems = [];
    if (composedContent.text !== '') {
      let chatMessageTextItem = createAgentChatMessage({
        agentProfile: this.currentAgent,
        content: composedContent,
        conversationId: composition.conversationId,
        customerId: composition.customerId,
        snippetIds: composition.snippetIds,
        relatedSnippetIds: composition.relatedSnippetIds,
      });
      chatMessageItems.push(chatMessageTextItem);
    }
    for (let i = 0; i < attachments.length; i++) {
      composedContent.text = '';
      let attachment = attachments[i];
      let chatMessageAttachmentItem = createAgentChatMessage({
        agentProfile: this.currentAgent,
        content: {
          attachments: [attachment],
          ...composedContent,
        },
        conversationId: composition.conversationId,
        customerId: composition.customerId,
        snippetIds: composition.snippetIds,
        relatedSnippetIds: composition.relatedSnippetIds,
      });
      chatMessageItems.push(chatMessageAttachmentItem);
    }

    return { compositionErrors: [], conversationItems: chatMessageItems };
  }

  processComposition(composition, content) {
    // save composed content
    if (this.isNewConversationPending() || this.willShowReopenConversationModal()) {
      this.saveComposedContent(composition, content);
      return null;
    }

    // create outgoing items
    let { compositionErrors, conversationItems } = this.createOutgoingItems(composition, content);
    if (compositionErrors.length) {
      this.compositions.setErrors(composition.id, compositionErrors);
      this.saveComposedContent(composition, content);
      return null;
    }

    this.clearComposition(composition);

    return conversationItems;
  }

  processConversationItems(composition, conversationItems, conversationId) {
    if (!conversationItems || conversationItems.length === 0) {
      return;
    }

    for (let i = 0; i < conversationItems.length; i++) {
      let conversationItem = conversationItems[i];
      if (!conversationItem) {
        return;
      }

      this.addItemToHistory(conversationItem);
      let conversationUpdates = this.addItemToConversation(conversationItem, this.activeConversation);

      this.sendMessage(conversationUpdates, conversationItem);
    }

    let lastConversationItemIndex = conversationItems.length - 1;
    let lastConversationItem = conversationItems[lastConversationItemIndex];
    this.markConversationRead(this.activeConversationId || conversationId, lastConversationItem.timestamp);
    this.scrollToItem(this.activeConversationId || conversationId);
    this.updateAgentInbox(lastConversationItem);
  }

  sendMessage(conversationUpdates, chatMessageItem) {
    let itemId = chatMessageItem.content.sessionItemId || chatMessageItem.content.sessionId;

    // remove conversationId and customerId from conversationItem payload because customer merge may cause a mismatch
    const payload = {
      outgoingCommunication: {
        id: chatMessageItem.id,
        customerId: this.currentCustomerId,
        conversation: conversationUpdates,
        conversationItem: getOutgoingItemDto(chatMessageItem),
      },
      operation: OutgoingChatOperation.SEND_CHAT_MESSAGE,
    };

    let correlationId;
    if (this.context.stores.appFeatures.get().isEnabled('enableOutgoingChatHttp')) {
      const obj = this.context.gateways.outgoingChatHttp.update(
        {
          agentId: this.currentAgent.id,
          itemId,
        },
        payload
      );
      correlationId = obj.correlationId;
    } else {
      let obj = this.context.gateways.outgoingChat.update(this.currentAgent.id, itemId, payload);
      correlationId = obj.correlationId;
    }
    addPendingRequestIdForConversation(this.context, chatMessageItem.conversationId, correlationId);
  }

  afterSend(content) {
    // create a new chat composition to continue chatting
    this.context.executeAction(CreateChatComposition, {
      conversationId: this.activeConversation.id,
      sessionId: content.sessionId,
    });
  }

  createChatAttachments(composition) {
    return composition.attachments.map(attachment => asChatAttachment(composition, attachment));
  }
}

function asChatAttachment(composition, attachment) {
  let fileDescriptor = attachment.fileDescriptor();
  let source = attachment instanceof Upload ? createUploadSource(composition, attachment.id) : attachment.source;

  return ConversationMessageAttachment.create({
    id: attachment.id,
    contentType: fileDescriptor.contentType,
    filename: fileDescriptor.filename,
    fileSize: fileDescriptor.contentLength,
    source,
  });
}

function createUploadSource(composition, attachmentId) {
  return {
    type: ConversationMessageAttachment.SourceType.UPLOAD,
    path: composition.getUploadPath(attachmentId),
  };
}
