import { useEffect } from 'react';

/**
 * `useOnUnmount` invokes the callback when either the component unmounts or the inputs change/
 * If `inputs` are not specified, it will only call the `callback` on unmount.
 *
 *  @param {function} callback
 *  @param {[]*} inputs
 */
export function useOnUnmount(callback, inputs = []) {
  useEffect(() => {
    return callback;
  }, inputs);
}
