import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { usePlateEditorRef } from '@udecode/plate';

import ComposerContext from 'components/composer/contexts/composer_context';
import InlineImageButton from 'components/lib/inline_image_button';
import InsertInlineAttachmentCompositionImage from 'actions/composition/insert_inline_attachment_composition_image';
import StartUpload from 'actions/composition/start_upload';

import { Item } from 'components/text_editor_new/components/controls/controls_new';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function InsertInlineImageButton({ editorId, onChange }) {
  const editor = usePlateEditorRef(editorId);
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();

  // Do we have a plugin that supports inline images API? If not, hide the button
  if (!isFunction(editor.insertInlineImage)) return null;

  /**
   * This handler is called when the user selects a file (or multiple) to insert as
   * an inline image(s), which triggers a "change" event on the input element
   *
   * @param {FileList|[]} filesToInsert - file(s) that the user is trying to insert
   */
  const onUploadImages = filesToInsert => {
    for (const file of filesToInsert) {
      if (!file.type.startsWith('image/')) continue;

      executeAction(StartUpload, {
        compositionId: composition.id,
        file,
        isInline: true,
        onInsertImage: uploadedFile => {
          executeAction(InsertInlineAttachmentCompositionImage, {
            compositionId: composition.id,
            insertImage: editor.insertInlineImage,
            onChange,
            upload: uploadedFile,
          });
        },
      });
    }
  };

  return (
    <InlineImageButton
      accept="image/*"
      buttonComponent={Item}
      className="slateStylesMenu-image"
      data-aid="inline-image-button"
      onInsertImage={onUploadImages}
    />
  );
}

InsertInlineImageButton.propTypes = {
  editorId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
