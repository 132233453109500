import _ from 'lodash';

import AutoAcceptedSessionNotification from 'models/notification/auto_accepted_session_notification';
import ChatMessage from 'models/chat_message';
import ConversationNotification from 'models/notification/conversation_notification';
import ConversationNote from 'models/conversation_note';
import DismissNotification from 'actions/notification/dismiss_notification';
import EmailMessage from 'models/email_message';
import EmailSendFailure from 'models/email_send_failure';
import FbMessageIncoming from 'models/fb_message_incoming';
import FollowIncomingNotification from 'actions/notification/follow_incoming_notification';
import { formatPhoneNumber } from 'models/phone_number';
import InboundCommunicationSessionNotification from 'models/notification/inbound_communication_session_notification';
import InboundMessageNotification from 'models/notification/inbound_message_notification';
import InteractionType from 'models/interaction_type';
import OfferedCallNotification from 'models/notification/offered_call_notification';
import OfferedWarmTransferNotification from 'models/notification/offered_warm_transfer_notification';
import SmsMessage from 'models/sms_message';
import SystemNotificationGenerator from 'scripts/domain/contracts/system_notification_generator';
import Task from 'models/task';
import TaskNotification from 'models/notification/task_notification';
import Voicemail from 'models/voicemail';

export default class ShowConversationSystemNotification {
  constructor(context) {
    this.context = context;
  }

  run(notification) {
    if (SystemNotificationGenerator.canGenerateNotifications()) {
      const systemNotification = SystemNotificationGenerator.newNotification(notification.id, {
        title: this._getTitle(notification),
        body: this._getBody(notification),
        icon: this._getIcon(notification),
        tag: notification.id,
      });
      // Did we create the actual Notification? If not, quit
      if (!systemNotification) return;

      const notificationTimeoutId = setTimeout(() => {
        SystemNotificationGenerator.closeNotification(notification.id);
      }, DismissNotification.timeout);
      systemNotification.onclick = this._onClick.bind(this, notification);
      systemNotification.timeoutId = notificationTimeoutId;
    }
  }

  _onClick(notification) {
    this.context.executeAction(FollowIncomingNotification, notification);
    window.focus();
  }

  _getTitle(notification) {
    switch (notification.constructor) {
      case OfferedCallNotification:
        if (notification.conversationItem.content.isBlindTransferring()) {
          return 'Cold transfer';
        }
        return 'Incoming call';
      case OfferedWarmTransferNotification:
        return 'Warm transfer';
      case ConversationNotification:
        switch (notification.conversationItem.content.constructor) {
          case ChatMessage:
            return 'Chat';
          case ConversationNote:
            return 'Note';
          case EmailMessage:
            return 'Incoming Email';
          case EmailSendFailure:
            return 'Failed Email';
          case FbMessageIncoming:
            return 'Incoming Facebook Message';
          case SmsMessage:
            return 'Incoming SMS';
          case Task:
            return 'Task is Due';
          case Voicemail:
            return 'Voicemail';
          default:
            return 'A conversation has been assigned to you';
        }
      case InboundMessageNotification:
        switch (notification.sessionType) {
          case InteractionType.CHAT:
            return 'Chat';
          case InteractionType.FB_MESSENGER:
            return 'Facebook Messenger';
          case InteractionType.SMS:
            return 'SMS';
          case InteractionType.WHATSAPP:
            return 'WhatsApp';
          default:
            return 'New message';
        }
      case AutoAcceptedSessionNotification:
      case InboundCommunicationSessionNotification:
        switch (notification.sessionType) {
          case InteractionType.ABANDONED_CALL:
            return 'Abandoned Call';
          case InteractionType.CHAT:
            return 'Chat';
          case InteractionType.Email:
            return 'Incoming Email';
          case InteractionType.FB_MESSENGER:
            return 'Facebook Messenger';
          case InteractionType.SMS:
            return 'SMS';
          case InteractionType.TWITTER:
            return 'Twitter';
          case InteractionType.VOICEMAIL:
            return 'Voicemail';
          case InteractionType.WHATSAPP:
            return 'WhatsApp';
          default:
            return '';
        }
      case TaskNotification:
        return 'Task';
    }
  }

  _getBody(notification) {
    switch (notification.constructor) {
      case OfferedCallNotification:
      case OfferedWarmTransferNotification:
        return notification.profile.name
          ? notification.profile.name
          : formatPhoneNumber(notification.conversationItem.content.customerNumber);
      case ConversationNotification:
        switch (notification.conversationItem.content.constructor) {
          case ChatMessage: {
            if (notification.profile.name) {
              return `${notification.profile.name} - ${notification.conversationItem.content.text}`;
            }
            return notification.conversationItem.content.text;
          }
          case ConversationNote:
            return notification.conversationItem.content.content;
          case EmailMessage:
            return notification.conversationItem.content.subject;
          case EmailSendFailure:
            return 'Email not delivered';
          case FbMessageIncoming:
            return _.get(notification.conversationItem.content, 'message.text');
          case SmsMessage:
            return notification.conversationItem.content.body;
          case Task:
            return notification.conversationItem.content.note;
          case Voicemail:
            return `From ${formatPhoneNumber(notification.conversationItem.content.customerNumber)}`;
          default:
            return '';
        }
      case InboundMessageNotification: {
        switch (notification.sessionType) {
          case InteractionType.CHAT:
          case InteractionType.FB_MESSENGER:
          case InteractionType.INSTAGRAM_DIRECT:
          case InteractionType.SMS:
          case InteractionType.TWITTER:
          case InteractionType.WHATSAPP: {
            let messageText = notification.messagePreview || 'New Message';
            if (notification.profile.name) {
              messageText = `${notification.profile.name} - ${messageText}`;
            }

            return messageText;
          }
          default:
            return '';
        }
      }
      case AutoAcceptedSessionNotification:
      case InboundCommunicationSessionNotification: {
        let messageSessionType = '';
        switch (notification.sessionType) {
          case InteractionType.CHAT:
            messageSessionType = 'a chat';
            break;
          case InteractionType.EMAIL:
            messageSessionType = 'an email';
            break;
          case InteractionType.FB_MESSENGER:
            messageSessionType = 'a message';
            break;
          case InteractionType.SMS:
            messageSessionType = 'an SMS';
            break;
          case InteractionType.WHATSAPP:
            messageSessionType = 'a message';
            break;
          default:
            messageSessionType = 'a message';
        }
        let customerName = notification.profile.name ? formatPhoneNumber(notification.profile.name) : 'Customer';
        if (notification.sessionType === InteractionType.ABANDONED_CALL) {
          return `${customerName} abandoned phone call`;
        }
        if (notification.sessionType === InteractionType.VOICEMAIL) {
          return `${customerName} left a voicemail`;
        }
        return `${customerName} sent ${messageSessionType}`;
      }
      case TaskNotification:
        return notification.conversationItem.content.note;
    }
  }

  _getIcon(notification) {
    switch (notification.constructor) {
      case OfferedCallNotification:
      case OfferedWarmTransferNotification:
        return '/images/notifications/call.png';
      case ConversationNotification:
        switch (notification.conversationItem.content.constructor) {
          case ChatMessage:
            return '/images/notifications/chat.png';
          case ConversationNote:
            return '/images/notifications/note.png';
          case FbMessageIncoming:
            return '/images/notifications/fb-messenger.png';
          case EmailMessage:
          case EmailSendFailure:
            return '/images/notifications/email.png';
          case SmsMessage:
            return '/images/notifications/sms.png';
          case Task:
            return '/images/notifications/task.png';
          case Voicemail:
            return '/images/notifications/voicemail.png';
          default:
            return '';
        }
      case InboundMessageNotification:
        switch (notification.sessionType) {
          case InteractionType.CHAT:
            return '/images/notifications/chat.png';
          case InteractionType.FB_MESSENGER:
            return '/images/notifications/fb-messenger.png';
          case InteractionType.SMS:
            return '/images/notifications/sms.png';
          case InteractionType.WHATSAPP:
            return '/images/notifications/whatsapp.png';
          default:
            return '';
        }
      case AutoAcceptedSessionNotification:
      case InboundCommunicationSessionNotification:
        switch (notification.sessionType) {
          case InteractionType.ABANDONED_CALL:
            return '/images/notifications/abandoned-call.png';
          case InteractionType.CHAT:
            return '/images/notifications/chat.png';
          case InteractionType.EMAIL:
            return '/images/notifications/email.png';
          case InteractionType.FB_MESSENGER:
            return '/images/notifications/fb-messenger.png';
          case InteractionType.SMS:
            return '/images/notifications/sms.png';
          case InteractionType.VOICEMAIL:
            return '/images/notifications/voicemail.png';
          case InteractionType.WHATSAPP:
            return '/images/notifications/whatsapp.png';
          default:
            return '';
        }
      case TaskNotification:
        return '/images/notifications/white-on-black-checkmark.png';
    }
  }
}
