import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'CustomerEmailAddress',

  properties: {
    original: prop(String).isRequired,
    primary: Boolean,
  },

  statics: {
    create(attrs = {}) {
      return new this(attrs);
    },
  },
});

export function normalizeEmailAddress(email) {
  return email.trim().toLowerCase();
}
