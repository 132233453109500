import React, { useCallback } from 'react';
import styled from 'styled-components';
import { animated, easings, useSpring } from '@react-spring/web';

export function StyledAITextCompletionHighlight(props) {
  const removeHighlight = useCallback(() => {
    props.editor && props.editor.removeAITextCompletionHighlight();
  }, []);

  const styles = useSpring({
    from: { backgroundColor: `rgba(157, 201, 251, 1)`, boxShadow: `0 0 0 3px rgba(157, 201, 251, 1)` },
    to: { backgroundColor: `rgba(157, 201, 251, 0)`, boxShadow: `0 0 0 3px rgba(157, 201, 251, 0)` },

    config: {
      duration: 1000,
      easings: easings.easeOut,
    },
    onRest: removeHighlight,
  });

  return <HighlightStyled data-aid="aiTextCompletion-highlight" {...props} style={styles} />;
}

const HighlightStyled = styled(animated.span)`
  border-radius: 1px;
`;
