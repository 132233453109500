import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/common/link';
import { H2 } from 'components/common/headers';
import ReportDuration from 'components/reporting/report/lib/report_duration';
import Tooltip from 'components/lib/deprecated_tooltip';

export function MetricCard({ className, children, labelColor, label, link, tooltip, tooltipPosition }) {
  if (labelColor) {
    label = (
      <div className="metricCard-label-withColor">
        <div className={classnames('metricCard-label-color', labelColor)} />
        {label}
      </div>
    );
  }

  let node = (
    <div className={classnames('metricCard', { [className]: !link })}>
      <div className="metricCard-value">{children}</div>
      <div className="metricCard-label">{label}</div>
    </div>
  );

  if (link) {
    node = (
      <Link className={classnames('metricCard-link', className)} href={link}>
        {node}
      </Link>
    );
  }

  if (tooltip) {
    node = (
      <Tooltip delayed message={tooltip} position={tooltipPosition || 'bottom'}>
        {node}
      </Tooltip>
    );
  }

  return node;
}

MetricCard.propTypes = {
  children: PropTypes.node,
  labelColor: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

export function CardRow({ className, children }) {
  return <div className={classnames('cardRow', className)}>{children}</div>;
}

CardRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export function NumberCard(props) {
  let { className, value, decimalPlaces, zeroValue } = props;
  return (
    <MetricCard className="metricCard-withBorder" {...props}>
      <H2
        className={classnames('numberCard', className)}
        data-aid={props['data-aid'] ? `${props['data-aid']}_value` : 'NumberCard_value'}
        stack="none"
      >
        {value
          ? value.toLocaleString(undefined, {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            })
          : zeroValue}
      </H2>
    </MetricCard>
  );
}

NumberCard.propTypes = _.merge({}, MetricCard.propTypes, {
  decimalPlaces: PropTypes.number,
  value: PropTypes.number,
  zeroValue: PropTypes.string,
});

NumberCard.defaultProps = {
  decimalPlaces: 0,
  zeroValue: '0',
};

export function DurationCard({ className, duration, label, link, tooltip }) {
  return (
    <MetricCard className="metricCard-withBorder" label={label} link={link} tooltip={tooltip}>
      <ReportDuration className={className} duration={duration} header />
    </MetricCard>
  );
}

DurationCard.propTypes = _.merge({}, MetricCard.propTypes, {
  className: PropTypes.string,
  duration: PropTypes.number,
});
