import classnames from 'classnames';
import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 16 16" {...props} className={classnames('warningIconContainer', props.className)}>
    <path d="M8.5 2C8.5 1.72386 8.27615 1.5 8 1.5C7.72386 1.5 7.5 1.72386 7.5 2V8C7.5 8.27614 7.72386 8.5 8 8.5C8.27615 8.5 8.5 8.27614 8.5 8V2Z" />
    <path d="M4 2.5C4.27615 2.5 4.5 2.72386 4.5 3V4.81818C4.5 4.95959 4.44013 5.09438 4.33521 5.18918C3.63812 5.81902 2.80714 6.95342 2.56779 8.28184C2.3423 9.53335 2.62903 11.0167 4.19571 12.5H11.9867C13.3789 11.0268 13.644 9.54102 13.4383 8.2733C13.2206 6.93264 12.4674 5.79636 11.8441 5.17123C11.7506 5.0775 11.6981 4.95054 11.6981 4.81818V3C11.6981 2.72386 11.922 2.5 12.1981 2.5C12.4743 2.5 12.6981 2.72386 12.6981 3V4.61722C13.4007 5.37706 14.1839 6.62586 14.4253 8.11307C14.6886 9.735 14.2989 11.6013 12.5522 13.353C12.5358 13.3695 12.5183 13.3847 12.5 13.3986V14.5C12.5 14.7761 12.2761 15 12 15C11.7239 15 11.5 14.7761 11.5 14.5V13.5H10.5V14.5C10.5 14.7761 10.2761 15 10 15C9.72386 15 9.5 14.7761 9.5 14.5V13.5H8.5V14.5C8.5 14.7761 8.27615 15 8 15C7.72386 15 7.5 14.7761 7.5 14.5V13.5H6.5V14.5C6.5 14.7761 6.27615 15 6 15C5.72386 15 5.5 14.7761 5.5 14.5V13.5H4.5V14.5C4.5 14.7761 4.27615 15 4 15C3.72386 15 3.5 14.7761 3.5 14.5V13.2184C1.70652 11.5156 1.29604 9.70077 1.58364 8.10452C1.85322 6.60827 2.72475 5.35867 3.5 4.60204V3C3.5 2.72386 3.72386 2.5 4 2.5Z" />
    <path d="M8.5 10.5C8.5 10.7761 8.27615 11 8 11C7.72386 11 7.5 10.7761 7.5 10.5C7.5 10.2239 7.72386 10 8 10C8.27615 10 8.5 10.2239 8.5 10.5Z" />
  </IconContainer>
);
