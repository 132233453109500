import { createPlugins, withPlate, createPluginFactory } from '@udecode/plate';
import { Editor, createEditor } from 'slate';
import _ from 'lodash';

import deserializeHtml from 'components/text_editor_new/lib/deserialize_html';

// Dependencies: VariablesPlugin created using useCreateVariablesPlugin hook
export function createAnswersPlugin() {
  return createPluginFactory({
    key: 'answers',
    withOverrides: withAnswersCommands,
  })();
}

function withAnswersCommands(editor) {
  editor.getSaturatedFragment = (html, onMalformedFragment) => {
    // First, try to verify and repair the markup, if possible. In some cases, we have to go with
    // the best-effort attempt at deserialization because the markup is messed up beyond repair
    let fragmentHtml = verifyAndWrapHtml(html, onMalformedFragment);

    // We do this by creating an entirely separate editor controller and updating the variables in it first,
    // then just returning the children as if nothing ever happened ;)
    const fragmentEditor = createFragmentEditor(editor);
    fragmentEditor.children = deserializeHtml(fragmentHtml, fragmentEditor);
    Editor.normalize(fragmentEditor, { force: true });

    fragmentEditor.updateVariables();
    return fragmentEditor.children;
  };

  return editor;
}

function createFragmentEditor(editor) {
  const plugins = createPlugins(editor.plugins);
  return withPlate(createEditor(), { plugins });
}

/**
 * Scan the provided html and verify that it has a correct nesting structure:
 * - the top level nodes must be either all blocks (in which case we don't do anything) OR
 * - the top level nodes are a mix of text and inlines (in which case we will try to wrap it into a block)
 *
 * We exclude list tags (OL/UL) from the "inlines" because Slate/Plate has no problem promoting them to the
 * top level as necessary, so we don't need to worry about wrapping them up. We also don't need to report an
 * error if the list happens to be at the top level
 *
 * If the html is correct (or if it is broken beyond repair, e.g. mix of blocks and inlines at the top level),
 * we will return the original markup. If the html is a mix of text and/or inlines, we will try to wrap it in
 * a <DIV> and return the result
 *
 * @param {string} html - source html
 * @param {function} [errorCallback] - optional callback we will call to report a potentially malformed html
 * @returns {string} resulting html
 */
function verifyAndWrapHtml(html, errorCallback) {
  const BLOCK_NODES = ['DIV', 'P'];
  const INLINE_NODES = ['A', 'BR', 'FONT', 'SPAN', 'VARIABLE', 'PLACEHOLDER'];

  const trimmed = _.trim(html);
  if (!trimmed) return '<div></div>';

  let hasBlocks = false;
  let hasText = false;
  let hasInline = false;

  const document = new DOMParser().parseFromString(trimmed, 'text/html');
  for (const node of document.body.childNodes) {
    if (node.nodeType === Node.TEXT_NODE) {
      hasText = true;
    } else if (INLINE_NODES.includes(node.nodeName || '')) {
      hasInline = true;
    } else if (BLOCK_NODES.includes(node.nodeName || '')) {
      hasBlocks = true;
    }
  }

  if (!(hasText || hasInline)) {
    // We are all good, no wrapping needed
    return trimmed;
  } else if (hasBlocks) {
    // We have mixed markup that cannot be fixed by wrapping. Report error and return the original html
    errorCallback && errorCallback();
    return trimmed;
  } else {
    // We have a shot at correcting the problem by wrapping markup in a DIV
    errorCallback && errorCallback();
    return `<div>${trimmed}</div>`;
  }
}
