import createModel from '../lib/create_model';

const FollowedTaskCommented = createModel({
  modelName: 'FollowedTaskCommented',

  properties: {
    commentId: String,
    customerId: String,
    taskId: String,
  },
});

export default FollowedTaskCommented;
