import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';

import AttributesWithImages from './attributes_with_images';
import connect from 'components/lib/connect';
import { H2, H3 } from 'components/common/headers';
import { SubscriptionTransactionDef, TransactionDefType } from 'models/configuration/transaction_def';
import ProfileCardType from 'models/configuration/profile_card_type';
import VisibleCustomAttribute from '../custom_attributes/visible_custom_attribute';
import GetExternalActionForm from 'actions/external_customer_lookup/get_external_action_form';

export function ExpandedSubscriptionTransactionBase({
  attributes,
  customerId,
  onOpenActionForm,
  shouldHideActions,
  transactionDef,
}) {
  const products = _.get(attributes, 'products') || [];
  const productSectionHeader = products.length > 1 ? 'Products' : 'Product';

  return (
    <div className="transaction-expandedInformation">
      <div className="transaction-expandedInformation-topTriangle">
        <div className="transaction-expandedInformation-topTriangle-border" />
      </div>
      {renderSection(
        <TransactionHeader>Subscription {attributes.subscriptionId}</TransactionHeader>,
        renderSubscriptionAttributes({ attributes, transactionDef })
      )}
      {renderSection(
        <SectionHeader>{productSectionHeader}</SectionHeader>,
        renderProducts({ attributes, transactionDef })
      )}
      {renderSection(
        <SectionHeader>Actions</SectionHeader>,
        renderActions({ attributes, customerId, onOpenActionForm, shouldHideActions })
      )}
    </div>
  );
}

export const TransactionHeader = styled(H2)`
  margin-bottom: 12px;
  word-break: break-all;
`;

const SectionHeader = styled(H3)`
  margin-bottom: 12px;
`;

function renderSection(label, content) {
  if (!content) {
    return null;
  }

  return (
    <div>
      <hr className="transaction-middleBorder" />
      <StyledSubscriptionCardSection>
        {label}
        {content}
      </StyledSubscriptionCardSection>
    </div>
  );
}

export const StyledSubscriptionCardSection = styled.div`
  padding: 16px;
`;

function renderSubscriptionAttributes({ attributes, transactionDef }) {
  let hasAttributes = _.find(transactionDef.attributes, def => !!attributes[def.attr]);
  if (!hasAttributes) {
    return null;
  }

  let billingFrequencyDef = _.find(transactionDef.attributes, def => def.attr === 'billingFrequency');
  return _.map(transactionDef.attributes, def => {
    if (def.attr === 'billingFrequency') {
      return renderBillingFrequency({ attributes, billingFrequencyDef });
    }
    return (
      <VisibleCustomAttribute
        className="transactionAttribute"
        def={def}
        key={`optionalAttributes-${def.attr}`}
        location={{ type: ProfileCardType.TRANSACTIONS, subType: TransactionDefType.SUBSCRIPTION }}
        value={attributes[def.attr]}
      />
    );
  });
}

function renderBillingFrequency({ attributes, billingFrequencyDef }) {
  if (!attributes || !attributes.billingIntervalUnit || !attributes.billingIntervalNumber) {
    return null;
  }
  const billingFrequency = `${attributes.billingIntervalNumber} ${attributes.billingIntervalUnit}${
    attributes.billingIntervalNumber === '1' ? '' : 's'
  }`;
  return (
    <VisibleCustomAttribute
      className="transactionAttribute"
      def={billingFrequencyDef}
      key={`optionalAttributes-${billingFrequencyDef.attr}`}
      location={{ type: ProfileCardType.TRANSACTIONS, subType: TransactionDefType.SUBSCRIPTION }}
      value={billingFrequency}
    />
  );
}

function renderProducts({ attributes, transactionDef }) {
  if (_.isEmpty(attributes.products)) {
    return null;
  }

  return (
    <div>
      {_.map(attributes.products, (product, idx) => {
        return (
          <SubscriptionProductAttributes key={`product-${idx}`} product={product} transactionDef={transactionDef} />
        );
      })}
    </div>
  );
}

function renderActions({ attributes, customerId, onOpenActionForm, shouldHideActions }) {
  if (shouldHideActions || _.isEmpty(attributes.actions)) {
    return null;
  }

  const actionsToDisplay = _.filter(attributes.actions, action => !!action.integrationId);

  return (
    <StyledActionsContainer>
      {_.map(actionsToDisplay, (action, idx) => {
        return (
          <StyledActionButton
            buttonType={ButtonTypes.SECONDARY}
            data-aid={`styled-action-button-${idx}`}
            key={`styled-action-button-${idx}`}
            onClick={() => onOpenActionForm(customerId, action)}
          >
            {action.name}
          </StyledActionButton>
        );
      })}
    </StyledActionsContainer>
  );
}

export const StyledActionButton = styled(Button)`
  border-radius: ${p => p.theme.borderRadius.small};
  margin-top: ${p => p.theme.spacing.stackSmall};
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    margin-top: 0;
  }
  &:hover {
    background-color: #fafafa;
    border-color: ${p => p.theme.colors.green400};
  }
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

ExpandedSubscriptionTransactionBase.propTypes = {
  attributes: PropTypes.object,
  customerId: PropTypes.string,
  headerRenderer: PropTypes.func,
  transactionDef: PropTypes.instanceOf(SubscriptionTransactionDef).isRequired,
  onOpenActionForm: PropTypes.func.isRequired,
  shouldHideActions: PropTypes.bool.isRequired,
};

function mapExecuteToProps(executeAction) {
  return {
    onOpenActionForm: (customerId, action) => executeAction(GetExternalActionForm, { customerId, action }),
  };
}

function mapStateToProps({ getProvider, isFeatureEnabled }, { attributes }) {
  let shouldHideActions = false;
  if (_.has(attributes, 'integrationId')) {
    const integration = getProvider('integrations').findBy({ id: attributes.integrationId });
    if (!integration || !integration.enabled || !_.get(integration, 'actionSettings.enabled', false)) {
      shouldHideActions = true;
    }
  }
  return {
    customerId: getProvider('currentLocation').get().customerId,
    shouldHideActions,
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(ExpandedSubscriptionTransactionBase);

export function SubscriptionProductAttributes({ product, transactionDef }) {
  return (
    <StyledProductAttributes>
      <AttributesWithImages imageUrl={_.get(product, 'imageUrl') || _.get(product, 'imageURL')}>
        <div className="productAttributes-name">{product.name}</div>
        {_.map(transactionDef.attributes, def => {
          const key = `productAttributes-${def.attr}`;
          return (
            <VisibleCustomAttribute
              className="transactionAttribute"
              def={def}
              key={key}
              location={{ type: ProfileCardType.TRANSACTIONS, subType: TransactionDefType.SUBSCRIPTION }}
              value={product[def.attr]}
            />
          );
        })}
      </AttributesWithImages>
    </StyledProductAttributes>
  );
}

// Negative margin is to allow for highlighting the entire width on hover, but with the content in the right place
const StyledProductAttributes = styled.div`
  margin: 0 -16px;
  padding: 0 16px 16px 16px;

  &:last-child {
    padding-bottom: 0px;
  }

  &:hover {
    background: ${p => p.theme.colors.gray200};
  }
`;

const SubscriptionProductPropTypes = PropTypes.shape({
  productId: PropTypes.string,
  name: PropTypes.string,
  sku: PropTypes.string,
  imageURL: PropTypes.string, // deprecated as not consistent with other products
  imageUrl: PropTypes.string,
});

SubscriptionProductAttributes.propTypes = {
  product: SubscriptionProductPropTypes,
  transactionDef: PropTypes.instanceOf(SubscriptionTransactionDef).isRequired,
};
