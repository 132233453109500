import PropTypes from 'prop-types';
import React from 'react';

export default function TextSection({ section = {} }) {
  return <div>{section.text}</div>;
}

TextSection.propTypes = {
  section: PropTypes.objectOf({
    text: PropTypes.string,
  }).isRequired,
};
