import React from 'react';

export const inlineImageRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'img') {
        const src = el.getAttribute('src');
        const attachmentId = el.getAttribute('data-attachment-id');
        return { object: 'block', type: 'image', nodes: next(el.childNodes), data: { src, attachmentId } };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'image') {
        const src = object.data.get('src');
        const attachmentId = object.data.get('attachmentId');
        return <img data-attachment-id={attachmentId} src={src} />;
      }
    },
  },
];
