import createReactClass from 'create-react-class';
import React from 'react';
import moment from 'moment';

const ShortTime = createReactClass({
  render() {
    let parsedTimestamp = moment(this.props.timestamp);
    let formattedTime;
    if (parsedTimestamp > moment().startOf('day')) {
      formattedTime = parsedTimestamp.format('h:mma');
    } else if (parsedTimestamp > moment().subtract(180, 'days')) {
      formattedTime = parsedTimestamp.format('MMM D');
    } else {
      formattedTime = parsedTimestamp.format('MMM YYYY');
    }
    let fullTime = parsedTimestamp.format('MMM D, YYYY h:mma');
    return (
      <time dateTime={parsedTimestamp.toDate()} title={fullTime}>
        {formattedTime}
      </time>
    );
  },
});

export default ShortTime;
