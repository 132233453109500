import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Checklist from 'components/lib/checklist';
import Err from 'models/err';
import UserCredential from 'models/user_credential';

const nameRequirementOptions = [
  {
    id: Err.Code.BLANK,
    label: 'Cannot be blank',
  },
  {
    id: Err.Code.TOO_LONG,
    label: `No longer than ${UserCredential.MAX_LENGTH.name} characters`,
  },
];

export default class NameHints extends React.Component {
  render() {
    let options = _.map(nameRequirementOptions, option => {
      return {
        label: option.label,
        value: option.id,
        isSelected: (this.props.name && this.props.errors.indexOf(option.id) === -1) || false,
      };
    });
    return (
      <div className="nameHints">
        <h1 className="nameHints-heading">Name Requirements</h1>
        <div className="nameHints-checkList">
          <Checklist options={options} readOnly />
        </div>
      </div>
    );
  }
}

NameHints.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
};
