import React from 'react';
import styled, { css } from 'styled-components';

import ConversationItemType from 'models/conversation_item_type';
import ProfileAvatar from 'components/lib/profile_avatar';
import TaskAvatar from 'components/common/icons/task_avatar';

export default function InboxItemAvatar({ collapsed, item }) {
  let itemType = item.item ? item.item.contentType() : null;
  if (itemType === ConversationItemType.TASK) {
    return (
      <StyledCustomerAvatar collapsed={collapsed} data-aid="inboxItem-taskAvatar">
        <TaskAvatar />
      </StyledCustomerAvatar>
    );
  }

  return (
    <CustomerAvatarWrapper collapsed={collapsed}>
      <ProfileAvatar isLarge={!collapsed} profile={item.profile} wrapperClassName="sidebar-customerAvatar" />
    </CustomerAvatarWrapper>
  );
}

const profileAvatarSize = css`
  height: 40px;
  width: 40px;
`;

const smallCustomerAvatar = css`
  .profileAvatar-initialIcon {
    ${profileAvatarSize}
  }

  .profileAvatar-iconWrapper .avatar {
    ${profileAvatarSize}
  }

  .profileAvatar-unknownCustomer {
    ${profileAvatarSize}
  }
`;

const CustomerAvatarWrapper = styled.div`
  align-items: center;
  display: flex;

  .sidebar-customerAvatar {
    background-color: transparent;
  }

  .profileAvatar {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    border: none;
  }

  ${p => (p.collapsed ? smallCustomerAvatar : '')}
`;

const smallIcon = css`
  height: 40px;
  width: 40px;
`;

const StyledCustomerAvatar = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  border: none;

  ${p => (p.collapsed ? smallIcon : '')}
`;
