import React from 'react';
import PropTypes from 'prop-types';

import Agent from 'models/agent';
import ConversationNotification from 'models/notification/conversation_notification';
import NotificationContentContainer from './notification_content_container';

function ConversationNoteNotification({ agent, notification }) {
  const content = notification.conversationItem.content.content;
  const heading = agent && `Added by ${agent.name}`;

  return (
    <NotificationContentContainer
      body={content}
      heading={heading}
      iconClass="icon-note-filled"
      itemType="Note"
      notification={notification}
    />
  );
}

ConversationNoteNotification.propTypes = {
  agent: PropTypes.instanceOf(Agent),
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default ConversationNoteNotification;
