import analytics from 'scripts/lib/analytics';
import qconsole from 'scripts/lib/qconsole';

export default class UseAITextCompletion {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, requestorId, composition }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      qconsole.log(`UseAITextCompletion: received request for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }

    const { aiTextCompletions } = this.context.stores.customers.storesFor(customerId);
    const completion = aiTextCompletions.findBy({ id: requestorId });
    if (!completion) {
      return;
    }

    aiTextCompletions.remove(requestorId);
    analytics.track('Rewrite Accepted', {
      customerId,
      conversationId: composition?.conversationId,
      compositionId: composition?.id,
      contentType: composition?.contentType(),
      rewriteId: completion?.rewriteId,
      rewriteType: completion?.type,
    });
  }
}
