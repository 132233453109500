import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel from './lib/create_model';

const prop = createModel.prop;

export const RecordingSummaryStatusState = createEnum('', 'READY', 'FAILED', 'PENDING', 'REDACTED');
export const RecordingSummaryStatusErrorCode = createEnum('GENERAL', 'EMPTY_TRANSCRIPT', 'LANGUAGE_NOT_SUPPORTED');

export const RecordingSummaryStatus = createModel({
  modelName: 'RecordingSummaryStatus',
  properties: {
    state: prop().oneOf(..._.keys(RecordingSummaryStatusState)),
    errorCode: prop(String),
    errorMessage: prop(String),
  },
});

const RecordingSummary = createModel({
  modelName: 'RecordingSummary',
  properties: {
    language: prop(String),
    status: prop(RecordingSummaryStatus),
    summary: prop(String),
    transcriptionConfidence: prop(Number),
  },
});

export default RecordingSummary;
