'use strict';

import { Factory } from 'rosie';

Factory.define('AgentVoiceConfiguration');

Factory.define('AgentVoiceConfigurationWithDefaults')
  .extend('AgentVoiceConfiguration')
  .attr('preference', 'NONE');

Factory.define('AgentVoiceConfigurationWebRtc')
  .extend('AgentVoiceConfiguration')
  .attr('preference', 'BROWSER');

Factory.define('AgentVoiceConfigurationDirectDial')
  .extend('AgentVoiceConfiguration')
  .attr('phone', () => Factory.attributes('PhoneNumberWithDefaults'))
  .attr('preference', 'DIRECT_DIAL');

Factory.define('AgentVoiceConfigurationStation')
  .extend('AgentVoiceConfiguration')
  .attr('stationId', 'exampleStationId')
  .attr('preference', 'STATION');

Factory.define('AgentVoiceConfigurationSipStation')
  .extend('AgentVoiceConfiguration')
  .attr('stationId', 'sip:exampleStationId@sipdomain.com')
  .attr('preference', 'STATION');
