import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import ErrorReporter from 'scripts/infrastructure/error_reporter';

/**
 * If a component has a tendency to throw errors during render, consider wrapping it with
 * an ErrorBoundary component in order to catch the error at a lower level in the component
 * tree.
 *
 * Be mindful of how a user can resolve an error - if the error is unrecoverable, the component
 * you render in renderError function should probably tell the user to reload the webpack / provide
 * a link to do so.
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.onResolveError = this.onResolveError.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const meta = { ...(info || {}), ...(this.props.errorMeta || {}) };

    const err = this.makeLoggableError(error);
    ErrorReporter.reportError(err, { extra: meta });
    isFunction(this.props.onError) && this.props.onError(err, meta);
  }

  onResolveError() {
    this.setState({ hasError: false });
  }

  makeLoggableError(error) {
    const boundaryError = new Error(`[React Error] ${error.name}: ${error.message}`);
    boundaryError.cause = error;
    boundaryError.stack = error.stack;
    return boundaryError;
  }

  render() {
    if (this.state.hasError) {
      return this.props.renderError({ onResolveError: this.onResolveError });
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  errorMeta: PropTypes.object,
  onError: PropTypes.func,
  renderError: PropTypes.func.isRequired,
};
