import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import Communicator from 'models/communicator';
import PhoneCall from 'models/phone_call';
import ReportDuration from 'components/reporting/report/lib/report_duration';
import { formatPhoneNumber } from 'models/phone_number';

const INACTIVE_STATUSES = [
  PhoneCall.Status.ABANDONED,
  PhoneCall.Status.COMPLETED,
  PhoneCall.Status.DECLINED,
  PhoneCall.Status.ERROR,
  PhoneCall.Status.NO_ANSWER,
  PhoneCall.Status.OUTGOING,
  PhoneCall.Status.RECEIVING,
];

const PhoneDuration = createReactClass({
  propTypes: {
    className: PropTypes.string,
    content: PropTypes.any,
    initiatorType: PropTypes.string,
  },

  componentDidUpdate() {
    clearTimeout(this._timeout);
    this.updateDuration();
  },

  componentDidMount() {
    this.updateDuration();
  },

  componentWillUnmount() {
    clearTimeout(this._timeout);
  },

  updateDuration() {
    if (!_.includes(INACTIVE_STATUSES, this.props.content.status)) {
      this._timeout = setTimeout(() => {
        this.forceUpdate();
      }, 1000);
    }
  },

  render() {
    switch (this.props.content.status) {
      case PhoneCall.Status.ABANDONED:
      case PhoneCall.Status.RECEIVING:
        return this.renderText('Call Abandoned');
      case PhoneCall.Status.NO_ANSWER:
        return this.renderText('No Answer');
      case PhoneCall.Status.ERROR:
        return this.renderText('Call Failed');
      case PhoneCall.Status.DECLINED:
        return this.renderText('Call Declined');
      case PhoneCall.Status.OUTGOING:
        return this.renderText(`Calling ${this.props.content.customerNumber}`);
      default:
        // handles incoming, in_progress, queued, completed, offering
        return this.renderDuration(this.props.content, this.props.initiatorType);
    }
  },

  renderDuration(content, initiatorType) {
    let duration = content.calculateDuration();
    let timestamp = duration && <ReportDuration className="preview-timestamp" duration={duration * 1000} />;
    let text = duration && this.actionText();
    let phoneNumber = initiatorType === Communicator.CUSTOMER ? content.customerNumber : content.companyNumber;
    let customerNumber = `Call from ${formatPhoneNumber(phoneNumber)}`;
    let seperator = duration && '\xa0-\xa0';
    return (
      <div className={classnames('phoneDuration', this.props.className)}>
        {customerNumber}
        {seperator}
        {timestamp}
        {text}
      </div>
    );
  },

  renderText(text) {
    return <div className={classnames('phoneDuration', this.props.className)}>{text}</div>;
  },

  actionText() {
    switch (this.props.content.status) {
      case PhoneCall.Status.INCOMING:
      case PhoneCall.Status.QUEUED:
        return 'Current Wait';
      case PhoneCall.Status.COMPLETED:
      case PhoneCall.Status.IN_PROGRESS:
        return 'Talk Time';
      case PhoneCall.Status.OFFERING:
        return 'Call Time';
      default:
        return 'Call Time';
    }
  },
});

export default PhoneDuration;
