import Attachment from 'models/attachment';

export function createContent(composition, attrs) {
  let attachments = composition.attachments.map(attachment => asAttachment(composition, attachment));
  return {
    attachments,
    ...attrs,
  };
}

export function asAttachment(composition, attachment) {
  if (attachment instanceof Attachment) {
    return attachment;
  }

  let fileDescriptor = attachment.fileDescriptor();

  return Attachment.create({
    id: attachment.id,
    contentType: fileDescriptor.contentType,
    filename: fileDescriptor.filename,
    fileSize: fileDescriptor.contentLength,
    source: {
      type: Attachment.SourceType.UPLOAD,
      path: composition.getUploadPath(attachment.id),
    },
  });
}
