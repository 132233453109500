import AgentMentionedInItem from 'models/toast/content/agent_mentioned_in_item';
import AgentMentionedInTaskComment from 'models/toast/content/agent_mentioned_in_task_comment';
import AgentNotification from 'models/agent_notification';
import CreateToast from 'actions/toast/create_toast';

export default function createToastFromNotification(context, notification) {
  if (notification.type === AgentNotification.Type.ITEM_MENTION) {
    context.executeAction(CreateToast, {
      content: new AgentMentionedInItem(notification.content),
    });
  } else if (notification.type === AgentNotification.Type.ITEM_MENTION_COMMENT) {
    context.executeAction(CreateToast, {
      content: new AgentMentionedInTaskComment(notification.content),
    });
  }
}
