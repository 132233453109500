import _ from 'lodash';
import classnames from 'classnames';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import FollowAnswerLink from 'actions/answers/follow_answer_link';
import { INVALID_ANSWER_LINK } from 'components/customer/composition/lib/draft/draft_answer';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export const StyledAnswerLink = styled.span`
  border-bottom: 1px dashed;
  ${p => (p.isEditingSnippets ? 'cursor: text' : 'cursor: pointer')};
  padding-bottom: 1px;
  & .slateAnswerLink-icon {
    font-size: 12px;
    margin-right: 5px;
  }
`;

export const StyledAnswerLinkPlaceholder = styled.span`
  border-bottom: 1px dashed ${p => p.theme.colors.gray600};
  color: ${p => p.theme.colors.gray600};
  padding-bottom: 1px;

  & .slateAnswerLink-icon {
    font-size: 12px;
    margin-right: 5px;
  }
`;

export const StyledAnswerLinkInvalid = styled.span`
  border-bottom: 1px dashed ${p => p.theme.colors.red400};
  color: ${p => p.theme.colors.red400};
  cursor: default;
`;

export function SlateAnswerLink(props) {
  const { answerId } = props;

  const executeAction = useExecuteAction();

  const onClick = useCallback(() => {
    executeAction(FollowAnswerLink, { answerId });
  }, [answerId]);

  if (props.isLoading) {
    return (
      <StyledAnswerLinkPlaceholder {...props.attributes} className="slateAnswerLink-placeholder">
        <FontAwesomeIcon className="slateAnswerLink-icon" icon={faBook} />
        {props.children}
      </StyledAnswerLinkPlaceholder>
    );
  }

  if (props.answerName === INVALID_ANSWER_LINK) {
    return (
      <StyledAnswerLinkInvalid className="slateAnswerLink-invalid" {...props.attributes}>
        {props.children}
      </StyledAnswerLinkInvalid>
    );
  }

  let classNames = classnames('slateAnswerLink', { 'slateAnswerLink-readOnly': props.isEditingSnippets });

  return (
    <StyledAnswerLink
      {...props.attributes}
      className={classNames}
      data-aid="answerLink"
      isEditingSnippets={props.isEditingSnippets}
      onClick={props.isEditingSnippets ? undefined : onClick}
    >
      <FontAwesomeIcon className="slateAnswerLink-icon" icon={faBook} />
      {props.children}
    </StyledAnswerLink>
  );
}

SlateAnswerLink.propTypes = {
  answerId: PropTypes.string,
  answerName: PropTypes.string,
  attributes: PropTypes.object,
  children: PropTypes.node,
  isEditingSnippets: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClickLink: PropTypes.func,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  const answerId = props.node.get('data').get('answerId');
  const answerName = props.node.text;

  const answerPanel = getProvider('answerPanel').get();

  const currentLocationProvider = getProvider('currentLocation');
  const isEditingSnippets = !!currentLocationProvider.get().snippetId;

  return {
    answerId,
    answerName,
    attributes: props.attributes,
    children: props.children,
    isEditingSnippets,
    isLoading: _.includes(answerPanel.loadingAnswerIds, answerId),
  };
}

const SlateAnswerLinkContainer = connect(mapStateToProps)(SlateAnswerLink);
export default SlateAnswerLinkContainer;
