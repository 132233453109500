import RemoveToast from 'actions/toast_deprecated/remove_toast';
import Toast, { ToastCategory } from 'models/toast_deprecated';

export default class ShowToast {
  constructor(context) {
    this.context = context;
  }

  /**
   * Creates a new "banner toast"
   *
   * @param {string} [id] - optional ID, a new unique ID will be generated if omitted
   * @param {string} [category] - optional "category" that controls the "slot" this toast should be displayed in.
   * Available categories are enumerated in `ToastCategory`
   * @param {string} [link] - optional link the toast will navigate to if clicked
   * @param {string} message - toast message
   * @param {boolean} [persistent] - optional flag that controls whether the toast will be automatically removed after a
   * certain period of time (false) or if it will stay visible until removed manually (true)
   * @param {string} type - toast type (visual style). See `ToastType` for values
   */
  run({ id, category, link, message, persistent, type }) {
    const toastCategory = category || ToastCategory.TOAST;
    const toast = Toast.create({ id, category: toastCategory, link, message, persistent: !!persistent, type });
    this.context.stores.toastsDeprecated.addOrReplace(toast);

    if (!persistent) {
      RemoveToast.schedule(this.context, { id: toast.id });
    }
  }
}
