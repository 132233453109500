import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttachmentModal from 'components/attachments/attachment_modal';
import AttachmentPreviewOpener from 'components/lib/attachment/attachment_preview_opener';
import GenericAttachment from 'components/lib/attachment/generic_attachment';
import { getMessagingAttachmentUrl } from 'scripts/domain/services/attachments';
import { HandleHeightChange } from 'components/customer/conversation_history/virtualized/list_item';
import InlineImageAttachment from 'components/lib/attachment/inline_image_attachment';
import ConversationMessageAttachment from 'models/conversation_message_attachment';

const ConversationMessageAttachmentsContainer = AttachmentPreviewOpener(ConversationMessageAttachments);

function ConversationMessageAttachments({ attachments, orgId }) {
  const [previewAttachment, setPreviewAttachment] = useState(null);
  if (_.isEmpty(attachments)) {
    return null;
  }

  let [inlineImageAttachments, genericAttachments] = _.partition(
    attachments,
    a => !a.isRedacted && a.isImage() && a.isAvailable()
  );
  let availableAttachments = _.filter(inlineImageAttachments.concat(genericAttachments), a => a.isAvailable());

  return (
    <React.Fragment>
      <div className="itemAttachments">
        <ul className="attachments-inlineImageAttachments">
          {_.map(inlineImageAttachments, renderInlineImageAttachment)}
        </ul>
        <div className="genericAttachments">{_.map(genericAttachments, renderGenericAttachment)}</div>
      </div>
      {previewAttachment && (
        <AttachmentModal
          attachment={previewAttachment}
          attachments={availableAttachments}
          setAttachment={setPreviewAttachment}
          url={getDownloadUrl(previewAttachment)}
        />
      )}
    </React.Fragment>
  );

  function renderGenericAttachment(attachment) {
    return (
      <GenericAttachment
        attachment={attachment}
        key={attachment.id}
        onClick={(attachment.isAvailable() && openAttachmentPreview) || undefined}
      />
    );

    function openAttachmentPreview() {
      setPreviewAttachment(attachment);
    }
  }

  function renderInlineImageAttachment(attachment) {
    return (
      <HandleHeightChange key={attachment.id}>
        {({ onHeightChange }) => (
          <InlineImageAttachment
            attachment={attachment}
            inlinePreviewImageUrl={getPreviewUrl()}
            onClick={openAttachmentPreview}
            onLoad={onHeightChange}
          />
        )}
      </HandleHeightChange>
    );

    function getPreviewUrl() {
      let imageUrl = getDownloadUrl(attachment);
      return `${imageUrl}?size=preview`;
    }

    function openAttachmentPreview() {
      setPreviewAttachment(attachment);
    }
  }

  function getDownloadUrl(a) {
    return getMessagingAttachmentUrl(orgId, a);
  }
}

ConversationMessageAttachmentsContainer.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.instanceOf(ConversationMessageAttachment)).isRequired,
  onHeightChange: PropTypes.func,
};

ConversationMessageAttachments.propTypes = {
  ...AttachmentPreviewOpener.childPropTypes,
  ...ConversationMessageAttachmentsContainer.propTypes,
};

export { ConversationMessageAttachments };
export default ConversationMessageAttachmentsContainer;
