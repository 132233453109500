import { Factory } from 'rosie';

Factory.define('EmbedTokensReport')
  .attr('apiToken')
  .attr('apiTokenTtl')
  .attr('navigationToken')
  .attr('navigationTokenTtl')
  .attr('sessionReferenceTokenTtl')
  .attr('tokenExpiry')
  .attr('metricSet')
  .attr('supportedFilters')
  .attr('timezone')
  .attr('url');

Factory.define('EmbedTokensReportWithDefaults')
  .extend('EmbedTokensReport')
  .attr('apiToken', 'api-token')
  .attr('apiTokenTtl', 600)
  .attr('navigationToken', 'navigation-token')
  .attr('navigationTokenTtl', 600)
  .attr('sessionReferenceTokenTtl', 2592000)
  .attr('tokenExpiry', '2019-12-19T01:11:13.297Z')
  .attr('metricSet', 'DailyTrendsReport')
  .attr('supportedFilters', null)
  .attr('timezone', 'America/New_York')
  .attr('url', 'http://some.url');
