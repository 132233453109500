import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import styled from 'styled-components';

import {
  COLLAPSE_SELECTED_OPTIONS_NUM,
  TopicsMenuOptionType,
} from 'components/customer/conversation_history/topics_v2/conversation_topics_constants_v2';
import ConversationTopicMenuHeaderV2 from 'components/customer/conversation_history/topics_v2/conversation_topics_menu_header_v2';
import ConversationCustomAttributeMenuOptionV2 from 'components/customer/conversation_history/topics_v2/conversation_custom_attribute_menu_option_v2';
import ConversationTopicMenuOptionV2 from 'components/customer/conversation_history/topics_v2/conversation_topic_menu_option_v2';
import ConversationTopicSelectionCollapserV2 from 'components/customer/conversation_history/topics_v2/conversation_topic_selection_collapser_v2';
import ConversationTopicSelectionExpanderV2 from 'components/customer/conversation_history/topics_v2/conversation_topic_selection_expander_v2';
import ensureOptionInView from 'components/lib/ensure_option_in_view';

export default class ConversationTopicMenuV2 extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleWheel', 'handleHeaderClick', 'handleMenuMouseDown', 'handleMouseDown', 'setMenuRef']);
  }

  componentDidUpdate() {
    this.ensureOptionInView(this.props.focusedOptionId);
  }

  handleWheel(ev) {
    ev.stopPropagation();
  }

  handleHeaderClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.scrollToTop();
    this.props.onHeaderClick();
  }

  // this will prevent closing the menu when clicking in an area of the menu that is not an option or button
  handleMouseDown(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  handleMenuMouseDown(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  render() {
    const classes = classnames('conversationTopic-menu-container', {
      'conversationTopic-menu-container-dropDown': this.props.dropDown,
    });
    this.inputRefs = {};

    return (
      <div className={classes} onMouseDown={this.handleMouseDown} onWheel={this.handleWheel}>
        {this.renderHeader()}
        {this.renderSelectedOptions()}
        <div className="conversationTopic-menu" onMouseDown={this.handleMenuMouseDown} ref={this.setMenuRef}>
          {this.renderOptions()}
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <ConversationTopicMenuHeaderV2
        numSelections={this.props.numSelectedOptions}
        onClick={this.handleHeaderClick}
        unitLabelPlural={this.props.unitLabelPlural}
        unitLabelSingular={this.props.unitLabelSingular}
      />
    );
  }

  renderSelectedOptions() {
    const selectedOptions = this.props.options.map(option => (option.selected ? option : null)).filter(Boolean);
    if (selectedOptions.length === 0) {
      return null;
    }

    const collapserOption = this.props.options.find(option => option.type === TopicsMenuOptionType.COLLAPSER);
    const collapser = collapserOption ? (
      <ConversationTopicSelectionCollapserV2
        isFocused={collapserOption.id === this.props.focusedOptionId}
        onClick={this.props.onCollapserClick}
        onMouseEnter={this.props.onOptionFocus}
        option={collapserOption}
      />
    ) : null;

    const expanderOption = this.props.options.find(option => option.type === TopicsMenuOptionType.EXPANDER);
    const expander = expanderOption ? (
      <ConversationTopicSelectionExpanderV2
        isFocused={expanderOption.id === this.props.focusedOptionId}
        numSelections={this.props.numSelectedOptions - COLLAPSE_SELECTED_OPTIONS_NUM}
        onClick={this.props.onExpanderClick}
        onMouseEnter={this.props.onOptionFocus}
        option={expanderOption}
      />
    ) : null;

    const displayOptions = selectedOptions.map((o, i) => {
      let id = o.id;
      let optionId = o.optionId;
      return (
        <div
          className="conversationTopic-menu-option-container"
          id={`conversation-topic-option-${optionId}-${i}`}
          key={`conversation-topic-option-${optionId}-${i}`}
          ref={id}
        >
          {this.renderOption(o, optionId === this.props.focusedOptionId, this.props.onOptionFocus)}
        </div>
      );
    });

    return (
      <ConversationTopicMenuSelectedTopicsContainer>
        {displayOptions}
        {collapser}
        {expander}
      </ConversationTopicMenuSelectedTopicsContainer>
    );
  }

  renderOptions() {
    return this.props.options.map((o, i) => {
      if (o?.selected) {
        return null;
      }

      let id = o.id;
      let optionId = o.optionId;
      return (
        <ConversationTopicMenuOptionContainer
          id={`conversation-topic-option-${optionId}-${i}`}
          key={`conversation-topic-option-${optionId}-${i}`}
          ref={id}
        >
          {this.renderOption(o, optionId === this.props.focusedOptionId, this.props.onOptionFocus)}
        </ConversationTopicMenuOptionContainer>
      );
    });
  }

  renderOption(option, isFocused) {
    switch (option.type) {
      case TopicsMenuOptionType.OPTION:
        if (option.optionType === TopicsMenuOptionType.CUSTOM_ATTRIBUTE) {
          if (!option.selected) {
            this.inputRefs[option.id] = createRef();
          }
          return (
            <ConversationCustomAttributeMenuOptionV2
              isFocused={isFocused}
              onClick={this.props.onOptionClick}
              onMouseEnter={this.props.onOptionFocus}
              onSubmit={this.props.onSubmit}
              option={option}
              ref={this.inputRefs[option.id]}
            />
          );
        }

        return (
          <ConversationTopicMenuOptionV2
            isFocused={isFocused}
            onClick={this.props.onOptionClick}
            onMouseEnter={this.props.onOptionFocus}
            option={option}
          />
        );
      case TopicsMenuOptionType.GROUP_TITLE:
        return <ConversationTopicGroupTitleV2 label={option.label} />;
      default:
        return null;
    }
  }

  /* in order to to get a sticky header on this menu, we need to disable `react-select`'s scrolling `<div>` and insert
   * our own. This, however, breaks `react-select`'s arrow key navigation. We have to compensate by scrolling an
   * option that is outside the menu into view when we navigate to it by arrow key.
   */
  ensureOptionInView(id) {
    if (!this.menu || !id) {
      return;
    }
    let optionNode = this.refs[id];
    if (!optionNode) {
      return;
    }
    ensureOptionInView(this.menu, optionNode);
  }

  getMenuOptionInputRef(id) {
    return this.inputRefs[id];
  }

  setMenuRef(ref) {
    this.menu = ref;
  }

  scrollToTop() {
    this.menu.scrollTop = 0;
  }

  scrollToBottom() {
    this.menu.scrollTop = this.menu.scrollHeight;
  }
}

ConversationTopicMenuV2.propTypes = {
  dropDown: PropTypes.bool,
  focusedOptionId: PropTypes.string,
  numSelectedOptions: PropTypes.number.isRequired,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  onCollapserClick: PropTypes.func.isRequired,
  onExpanderClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onOptionFocus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
};

const ConversationTopicMenuOptionContainer = styled.div`
  background: none;
  color: ${p => p.theme.colors.gray700};
  cursor: default;
  position: relative;
  word-break: break-all;
`;

const ConversationTopicMenuSelectedTopicsContainer = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.gray300};
  max-height: 300px;
  overflow-y: scroll;
`;
const ConversationTopicGroupTitleV2 = function({ label }) {
  return <div className="conversationTopic-groupTitle searchResults-header-font">{label}</div>;
};

ConversationTopicGroupTitleV2.propTypes = {
  label: PropTypes.string,
};
