import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class TopicService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(payload, callback, requestorId, { orgId }) {
    callback(null, { status: 200, statusText: statusText(200), response: this.getDatabase(orgId).topics });
  }

  add(payload, callback, path, { orgId }) {
    this.getDatabase(orgId).routingGroups.push(payload);
    this._pubsub.publish(`v1/orgs/${orgId}/topics/${payload.id}`, { payload });
    callback(null, { status: 204, statusText: statusText(204), response: payload });
  }

  update(payload, callback, path, { orgId, topicId }) {
    let topic = _.find(this.getDatabase(orgId).topics, { id: topicId });
    _.assign(topic, payload);

    this._pubsub.publish(`v1/orgs/${orgId}/topics/${topicId}`, { payload: topic });
    callback(null, { status: 204, statusText: statusText(204), response: payload });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/topics': {
          POST: this.add,
          GET: this.findAll,
        },
        '/api/v1/orgs/:orgId/topics/:topicId': {
          PUT: this.update,
        },
      },
      this
    );
  }
}
