import _ from 'lodash';

export default class RingIterator {
  constructor(collection) {
    this.ringIndexer = new RingIndexer(_.size(collection));
    this.collection = collection || [];
    this.keys = _.keys(collection);
  }

  getNextKey() {
    return this.keys[this.ringIndexer.getNext()];
  }

  getNext() {
    return this.collection[this.getNextKey()];
  }
}

class RingIndexer {
  constructor(size) {
    this.size = size;
    this.index = 0;
  }

  getNext() {
    if (this.index >= this.size) {
      this.index = 0;
    }
    return this.index++;
  }
}
