import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Arrow Right Stroke
 */

export default function ArrowRightStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M10.6217 3.67306C10.8023 3.46413 11.118 3.44113 11.3269 3.6217L15.8269 7.51079C15.935 7.60419 15.998 7.73937 15.9999 7.8822C16.0019 8.02503 15.9427 8.16189 15.8372 8.25824L11.3372 12.3691C11.1334 12.5554 10.8171 12.5411 10.6308 12.3372C10.4446 12.1334 10.4589 11.8171 10.6628 11.6308L14.2004 8.39904L1.00382 8.49998C0.72769 8.5021 0.502127 8.27996 0.500015 8.00382C0.497903 7.72769 0.720042 7.50212 0.996176 7.50001L14.1686 7.39925L10.6731 4.37829C10.4641 4.19773 10.4411 3.88198 10.6217 3.67306Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
