import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

import { AggregationLevel } from 'models/reports/report_configs';
import ApplyFilters from 'scripts/application/actions/reporting/apply_filters';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import DownloadCsv from 'actions/reporting/download_csv';
import DropdownMenu from 'components/common/dropdown_menu';
import { EndpointTypes } from 'models/endpoint';
import { formatPhoneNumber } from 'models/phone_number';
import MultipleInboxMenu from 'components/lib/multiple_inbox_menu';
import MultipleTeamMenu from 'components/lib/multiple_team_menu';
import MultipleTopicMenu from 'components/lib/multiple_topic_menu';
import NavigateTo from 'actions/current_location/navigate_to';
import ReportChannel from 'models/reports/report_channel';
import ReportDateRange from './report_date_range';
import ReportLegend from 'scripts/presentation/components/reporting/report/lib/report_legend';
import Reports, { AGGREGATION_LABELS, DATE_FORMAT } from 'models/location/reports';
import ScheduleReport from 'actions/reporting/schedule_report';
import { findReportConfigBySlug } from 'models/reports/report';
import { getAllReportConfigs } from 'actions/reporting/lib/reporting_helpers';

class ReportFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: stateFromLocation(this.props.currentReport, this.props.config.timeAggregation),
      isDownloadButtonDisabled: false,
    };

    _.bindAll(this, [
      'getNewFilters',
      'handleApplyFilters',
      'onAggregationLevelChange',
      'onChannelChange',
      'onDateRangeChange',
      'onEndpointChange',
      'onSelectedInboxChange',
      'onSelectedTeamChange',
      'onSelectedTopicChange',
    ]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentReport !== this.props.currentReport) {
      this.setState({ params: stateFromLocation(this.props.currentReport, this.props.config.timeAggregation) });
    }
  }

  /* Rendering */
  render() {
    const { currentReport, isApplyForceEnabled, isDisabled, report, topics, config } = this.props;

    const shouldDisplayChannelFilter = _.includes(config.filters, 'channel');
    const shouldDisplayDateRangeFilter = _.includes(config.filters, 'dateRange');
    const shouldDisplayEndpointFilter = _.includes(config.filters, 'phoneNumber');
    const shouldDisplayInboxesFilter = _.includes(config.filters, 'inboxes');
    const shouldDisplayTeamsFilter = _.includes(config.filters, 'teams');
    const shouldDisplayTimeAggregationFilter = _.includes(config.filters, 'timeAggregation');

    const shouldDisplayTopicsFilter = _.includes(config.filters, 'topics');
    const shouldDisableApplyButton = isApplyForceEnabled ? false : !this.hasUnappliedChanges();
    const shouldDisplayChartKey = (this.getReportSlug() === 'summary' || 'topics') && !isApplyForceEnabled;
    const shouldDisplayDownloadCsvButton = currentReport.isEmbeddedReport();
    const shouldDisplayScheduleReportButton = currentReport.isEmbeddedReport();

    const aggregationOptions = this.getAggregationOptions();
    const channelOptions = this.getChannelOptions();
    const endpointOptions = this.getEndpointOptions();
    const currentChannel = this.getChannel();

    return (
      <div className="reportFilters reportFilters-selectors">
        {isDisabled && <span className="reportFilters-overlay" />}
        {shouldDisplayDateRangeFilter && (
          <ReportDateRange
            className={classnames({
              'dateRange-changed': this.hasUnappliedDateChanges(),
            })}
            endDate={this.state.params.endAt}
            onApply={this.onDateRangeChange}
            startDate={this.state.params.startAt}
          />
        )}
        {shouldDisplayEndpointFilter && (
          <ReactSelect
            className={classnames('reportFilters-phoneNumberMenu', {
              'reportFilters-phoneNumberMenu-changed': this.hasUnappliedEndpointChanges(),
            })}
            clearable={false}
            name="reportingEndpointNumber"
            onChange={this.onEndpointChange}
            options={endpointOptions}
            placeholder={this.state.params.endpointNumber && formatPhoneNumber(this.state.params.endpointNumber)}
          />
        )}
        {shouldDisplayTimeAggregationFilter && (
          <ReactSelect
            className={classnames('reportFilters-aggregate', {
              'reportFilters-aggregate-changed': this.hasUnappliedAggregationLevelChanges(),
              'reportFilters-disabled': this.displayAgentPerformanceReport(),
            })}
            clearable={false}
            disabled={this.displayAgentPerformanceReport()}
            name="reportingAggregationLevel"
            onChange={this.onAggregationLevelChange}
            options={aggregationOptions}
            placeholder={this.getPlaceholder()}
          />
        )}
        {shouldDisplayChannelFilter && (
          <ReactSelect
            className={classnames('reportFilters-channelDropdown', {
              'reportFilters-channelDropdown-changed': this.hasUnappliedChannelChanges(),
            })}
            clearable={false}
            name="reportingChannelType"
            onChange={this.onChannelChange}
            options={channelOptions}
            value={currentChannel}
          />
        )}
        {shouldDisplayInboxesFilter && (
          <MultipleInboxMenu
            className={classnames('reportFilters-multiSelectMenu', {
              'reportFilters-multiSelectMenu-changed': this.hasUnappliedInboxChanges(),
            })}
            currentLocation={currentReport}
            dropDown
            onApply={this.onSelectedInboxChange}
            optionIds={this.state.params.routingGroupIds}
          />
        )}
        {shouldDisplayTeamsFilter && (
          <MultipleTeamMenu
            className={classnames('reportFilters-multiSelectMenu', {
              'reportFilters-multiSelectMenu-changed': this.hasUnappliedTeamChanges(),
            })}
            currentLocation={currentReport}
            dropDown
            onApply={this.onSelectedTeamChange}
            optionIds={this.state.params.teamIds}
          />
        )}
        {shouldDisplayTopicsFilter && (
          <MultipleTopicMenu
            className={classnames('reportFilters-multiSelectMenu', {
              'reportFilters-multiSelectMenu-changed': this.hasUnappliedTopicChanges(),
            })}
            currentLocation={currentReport}
            dropDown
            onApply={this.onSelectedTopicChange}
            optionIds={this.state.params.topicIds}
          />
        )}
        <Button
          buttonType={ButtonTypes.PRIMARY}
          className="reportFilters-applyButton"
          disabled={shouldDisableApplyButton}
          onClick={this.handleApplyFilters}
        >
          View Report
        </Button>
        {shouldDisplayDownloadCsvButton && this.renderDownloadButton()}
        {shouldDisplayScheduleReportButton && this.renderScheduleReportButton()}
        {shouldDisplayChartKey && this.displayLegend(report, topics)}
      </div>
    );
  }

  /* Helpers */

  renderDownloadButton() {
    let downloadButtonTitle = 'Download CSV';
    let allowedAggregations = this.props.config.timeAggregation || [];
    const aggregationOptions = _.chain(AGGREGATION_LABELS)
      .map((label, value) => {
        return { label, value };
      })
      .filter(({ value }) => _.includes(allowedAggregations, value))
      .value();

    let brieflyDisableDownloadButton = () => {
      this.setState({ isDownloadButtonDisabled: true });
      setTimeout(() => {
        this.setState({ isDownloadButtonDisabled: false });
      }, 4000);
    };

    let onClickDownloadCsv = menuSelection => {
      brieflyDisableDownloadButton();
      this.props.downloadCsv(this.getNewFilters(menuSelection));
    };

    onClickDownloadCsv.bind(this);

    let csvDropdownMenu = (
      <DropdownMenu
        data-aid="reportFilters-downloadMenu"
        disabled={this.state.isDownloadButtonDisabled}
        maxHeight={360}
        onSelect={onClickDownloadCsv}
        options={aggregationOptions}
        placeholder={downloadButtonTitle}
      />
    );

    let csvDownloadButton = (
      <Button
        buttonType={ButtonTypes.SECONDARY}
        data-aid="reportFilters-downloadButtonWithoutAggregation"
        disabled={this.state.isDownloadButtonDisabled}
        onClick={() => {
          onClickDownloadCsv();
        }}
      >
        {downloadButtonTitle}
      </Button>
    );

    return this.isFilterConfigured('timeAggregation') ? csvDropdownMenu : csvDownloadButton;
  }

  renderScheduleReportButton() {
    let onScheduleReport = () => {
      this.props.scheduleReport(this.getNewFilters(this.state.params.aggregationLevel));
    };

    onScheduleReport.bind(this);

    return (
      <Button
        buttonType={ButtonTypes.SECONDARY}
        className="reportFilters-scheduleButton"
        data-aid="reportFilters-scheduleButton"
        onClick={onScheduleReport}
      >
        Schedule
      </Button>
    );
  }

  isFilterConfigured(filterName) {
    const configuredFilters = this.props.config ? this.props.config.filters : [];
    return _.includes(configuredFilters, filterName);
  }

  displayLegend(report, topics) {
    if (this.getReportSlug() === 'summary') {
      return (
        <div className="reportFilters-summaryKey">
          <div className="reportFilters-summaryKey-color summaryChart-open-key" />
          Created
          <div className="reportFilters-summaryKey-color summaryChart-closed-key" />
          Closed
        </div>
      );
    } else if (this.getReportSlug() === 'topics' && report) {
      let data = report.getLegendData(topics, this.props.currentReport.getTopicIds(), 5);
      return data !== [] ? <ReportLegend data={data} /> : null;
    }
    return null;
  }

  getPlaceholder() {
    if (this.displayAgentPerformanceReport()) {
      return 'Interval';
    }
    return this.state.params.aggregationLevel
      ? AGGREGATION_LABELS[this.state.params.aggregationLevel]
      : AGGREGATION_LABELS[AggregationLevel.DAY];
  }

  getChannel() {
    return this.state.params.channel ? this.state.params.channel : ReportChannel.ALL;
  }

  getChannelOptions() {
    let channelOptions = [
      {
        label: 'All Channels',
        value: ReportChannel.ALL,
        disabled: !this.isChannelEnabled(ReportChannel.ALL),
      },
      {
        label: 'Chat',
        value: ReportChannel.CHAT_SESSION,
        disabled: !this.isChannelEnabled(ReportChannel.CHAT_SESSION),
      },
      {
        label: 'Email',
        value: ReportChannel.EMAIL,
        disabled: !this.isChannelEnabled(ReportChannel.EMAIL),
      },
      {
        label: 'FB Messenger',
        value: ReportChannel.FB_MESSENGER,
        disabled: !this.isChannelEnabled(ReportChannel.FB_MESSENGER),
      },
      {
        label: 'Instagram Messaging',
        value: ReportChannel.INSTAGRAM_DIRECT,
        disabled: !this.isChannelEnabled(ReportChannel.INSTAGRAM_DIRECT),
      },
      {
        label: 'SMS',
        value: ReportChannel.SMS,
        disabled: !this.isChannelEnabled(ReportChannel.SMS),
      },
      {
        label: 'Twitter',
        value: ReportChannel.TWITTER,
        disabled: !this.isChannelEnabled(ReportChannel.TWITTER),
      },
      {
        label: 'Voice',
        value: ReportChannel.PHONE_CALL,
        disabled: !this.isChannelEnabled(ReportChannel.PHONE_CALL),
      },
      {
        label: 'WhatsApp',
        value: ReportChannel.WHATSAPP,
        disabled: !this.isChannelEnabled(ReportChannel.WHATSAPP),
      },
    ];

    return channelOptions;
  }

  getEndpointOptions() {
    const options = [];
    this.props.voiceEndpoints.forEach(x => {
      options.push({ value: x.address, label: formatPhoneNumber(x.address) });
    });

    return options;
  }

  getAggregationOptions() {
    let aggregationOptions = Reports.getAggregationOptions({
      start: this.state.params.startAt,
      end: this.state.params.endAt,
      allowedAggregations: this.props.config.timeAggregation,
    });

    return _.map(AGGREGATION_LABELS, (label, value) => {
      const disabled = aggregationOptions.indexOf(value) === -1;
      return { label, value, disabled };
    });
  }

  hasUnappliedChanges() {
    return (
      this.hasUnappliedAggregationLevelChanges() ||
      this.hasUnappliedChannelChanges() ||
      this.hasUnappliedEndpointChanges() ||
      this.hasUnappliedDateChanges() ||
      this.hasUnappliedInboxChanges() ||
      this.hasUnappliedTeamChanges() ||
      this.hasUnappliedTopicChanges()
    );
  }

  hasUnappliedAggregationLevelChanges() {
    return (
      this.state.params.aggregationLevel &&
      this.state.params.aggregationLevel !==
        this.props.currentReport.getAggregationLevel(this.props.config.allowedAggregations)
    );
  }

  hasUnappliedEndpointChanges() {
    let selectedEndpoint = this.state.params.endpointNumber;
    let currentReportEndpoint = this.props.currentReport.getEndpointNumber();

    return selectedEndpoint !== currentReportEndpoint;
  }

  hasUnappliedChannelChanges() {
    let selectedChannel = this.state.params.channel;
    let currentReportChannel = this.props.currentReport.getChannel();

    if (selectedChannel === ReportChannel.ALL && currentReportChannel === undefined) {
      return false;
    }
    return selectedChannel !== currentReportChannel;
  }

  hasUnappliedInboxChanges() {
    let currentReportInboxes = this.props.currentReport.getRoutingGroupIds();
    let inboxes = this.state.params.routingGroupIds;

    return inboxes.length !== currentReportInboxes.length || _.difference(inboxes, currentReportInboxes).length > 0;
  }

  hasUnappliedTeamChanges() {
    let currentReportTeams = this.props.currentReport.getTeamIds();
    let teams = this.state.params.teamIds;

    return teams.length !== currentReportTeams.length || _.difference(teams, currentReportTeams).length > 0;
  }

  hasUnappliedTopicChanges() {
    const currentTopics = this.props.currentReport.getTopicIds();
    const newTopics = this.state.params.topicIds;

    return newTopics.length !== currentTopics.length || _.difference(newTopics, currentTopics).length > 0;
  }

  hasUnappliedDateChanges() {
    let selectedStartAt = this.state.params.startAt;
    let selectedEndAt = this.state.params.endAt;
    let currentReportStartAt = this.props.currentReport.getStartAt();
    let currentReportEndAt = this.props.currentReport.getEndAt();

    return !areDatesEqual(selectedStartAt, currentReportStartAt) || !areDatesEqual(selectedEndAt, currentReportEndAt);
  }

  displayAgentPerformanceReport() {
    return (
      (this.state.params.channel === ReportChannel.ALL || !this.state.params.channel) &&
      _.isEmpty(this.state.params.routingGroupIds) &&
      this.getReportSlug() === 'agents'
    );
  }

  isChannelEnabled(channel) {
    switch (channel) {
      case ReportChannel.EMAIL:
      case ReportChannel.PHONE_CALL:
      case ReportChannel.ALL:
        return true;
      case ReportChannel.SMS:
        return this.props.isSmsEnabled;
      case ReportChannel.FB_MESSENGER:
        return this.props.isFbEnabled;
      case ReportChannel.INSTAGRAM_DIRECT:
        return this.props.isInstagramDirectEnabled;
      case ReportChannel.CHAT_SESSION:
        return this.props.isChatEnabled;
      case ReportChannel.TWITTER:
        return this.props.isTwitterConfigured;
      case ReportChannel.WHATSAPP:
        return this.props.isWhatsAppConfigured;
      default:
        return false;
    }
  }

  getReportSlug() {
    return this.props.currentReport.getSlug();
  }

  onChannelChange(channel) {
    this.setState({
      params: { ...this.state.params, channel: channel.value },
    });
  }

  onEndpointChange(endpoint) {
    this.setState({
      params: { ...this.state.params, endpointNumber: endpoint.value },
    });
  }

  onSelectedInboxChange(routingGroupIds) {
    this.setState({
      params: { ...this.state.params, routingGroupIds },
    });
  }

  onSelectedTeamChange(teamIds) {
    this.setState({
      params: { ...this.state.params, teamIds },
    });
  }

  onSelectedTopicChange(topicIds) {
    this.setState({
      params: { ...this.state.params, topicIds },
    });
  }

  onAggregationLevelChange(aggregationOption) {
    this.setState({
      params: { ...this.state.params, aggregationLevel: aggregationOption.value },
    });
  }

  onDateRangeChange({ startDate, endDate }) {
    const { config } = this.props;
    const newDateRange = {
      startAt: startDate,
      endAt: endDate,
      aggregationLevel: Reports.getDefaultAggregationLevelForDateRange({
        startDate,
        endDate,
        allowedAggregations: config.timeAggregation,
      }),
    };

    this.setState({
      params: { ...this.state.params, ...newDateRange },
    });
  }

  handleApplyFilters() {
    this.props.applyFilters(this.getNewFilters(this.state.params.aggregationLevel));
  }

  getNewFilters(aggregationLevel) {
    let channel = this.state.params.channel === ReportChannel.ALL ? undefined : this.state.params.channel;

    return { ...this.state.params, aggregationLevel, channel };
  }
}

ReportFilters.propTypes = {
  applyFilters: PropTypes.func,
  config: PropTypes.object,
  currentReport: PropTypes.instanceOf(Reports).isRequired,
  downloadCsv: PropTypes.func,
  isApplyForceEnabled: PropTypes.bool,
  isChatEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFbEnabled: PropTypes.bool,
  isInstagramDirectEnabled: PropTypes.bool,
  isSmsEnabled: PropTypes.bool,
  isTwitterConfigured: PropTypes.bool,
  isWhatsAppConfigured: PropTypes.bool,
  navigateToNewReportFilters: PropTypes.func,
  report: PropTypes.object,
  scheduleReport: PropTypes.func.isRequired,
  topics: PropTypes.array,
};

// Container component since executeAction depends on the props
class ReportFiltersExecuteContainer extends React.Component {
  constructor(props) {
    super(props);

    this.navigateToNewReportFilters = this.navigateToNewReportFilters.bind(this);
  }

  navigateToNewReportFilters(newFilters) {
    this.props.navigateTo(this.props.currentReport.deriveNew(newFilters));
  }

  render() {
    return (
      <ReportFilters
        navigateToNewReportFilters={this.navigateToNewReportFilters}
        {..._.omit(this.props, 'navigateTo')}
      />
    );
  }
}

ReportFiltersExecuteContainer.propTypes = {
  applyFilters: PropTypes.func,
  currentReport: PropTypes.object,
  downloadCsv: PropTypes.func,
  isApplyForceEnabled: PropTypes.bool,
  isChatEnabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFbEnabled: PropTypes.bool,
  isInstagramDirectEnabled: PropTypes.bool,
  isSmsEnabled: PropTypes.bool,
  isTwitterConfigured: PropTypes.bool,
  isWhatsAppConfigured: PropTypes.bool,
  navigateTo: PropTypes.func,
  scheduleReport: PropTypes.func,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  let currentReport = getProvider('currentLocation').get();
  let report = currentReport.isEmbeddedReport() ? getProvider('embeddedReport').get() : getProvider('report').get();
  let topics = getProvider('topics').findAll();

  const isSmsEnabled = isFeatureEnabled('sms');
  const channelConfiguration = getProvider('channelConfiguration').get();
  const voiceEndpoints = channelConfiguration
    ? channelConfiguration.endpoints.filter(e => e.type === EndpointTypes.VOICE)
    : [];
  const isInstagramDirectEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.INSTAGRAM_DIRECT);
  const isChatEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.CHAT);
  const isFbEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.FB_MESSENGER);
  const isTwitterConfigured = isChannelEnabled(channelConfiguration, EndpointTypes.TWITTER);
  const isWhatsAppConfigured = isChannelEnabled(channelConfiguration, EndpointTypes.WHATSAPP);

  const reportSlug = currentReport.getSlug();
  const reportConfigs = getAllReportConfigs({ stores: { reportConfigs: getProvider('reportConfigs') } });
  const currentReportConfig = findReportConfigBySlug(reportConfigs, reportSlug);

  return {
    config: currentReportConfig,
    currentReport,
    isChatEnabled,
    isFbEnabled,
    isInstagramDirectEnabled,
    isSmsEnabled,
    isTwitterConfigured,
    isWhatsAppConfigured,
    report,
    topics,
    voiceEndpoints,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    applyFilters: params => executeAction(ApplyFilters, params),
    downloadCsv: params => executeAction(DownloadCsv, params),
    navigateTo: newFilters => executeAction(NavigateTo, newFilters),
    scheduleReport: params => executeAction(ScheduleReport, params),
  };
}

const ReportFiltersContainer = connect(mapStateToProps, mapExecuteToProps)(ReportFiltersExecuteContainer);

export default ReportFiltersContainer;

function isChannelEnabled(channelConfiguration, endpointType) {
  return !!channelConfiguration && channelConfiguration.isChannelEnabled(endpointType);
}

function areDatesEqual(date1, date2) {
  const dateFormat = 'YYYY-MM-DD';
  let formattedDate1 = moment(date1, dateFormat).format(dateFormat);
  let formattedDate2 = moment(date2, dateFormat).format(dateFormat);
  return formattedDate1 === formattedDate2;
}

function stateFromLocation(reports, allowedAggregations) {
  const defaultState = createDefaultState();
  return _.defaults(
    {},
    _.pick(reports.toJs(), _.keys(defaultState)),
    { aggregationLevel: reports.getValidAggregationLevel(allowedAggregations) },
    defaultState
  );
}

function createDefaultState() {
  return Object.freeze({
    aggregationLevel: undefined,
    channel: undefined,
    endAt: moment().format(DATE_FORMAT),
    endpointNumber: undefined,
    routingGroupIds: [],
    startAt: moment()
      .subtract(1, 'week')
      .format(DATE_FORMAT),
    teamIds: [],
    topicIds: [],
  });
}
