import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import FbPageAccountContent from 'models/fb_page_account_content';
import FbPageAccountSecretContent from 'models/fb_page_account_secret_content';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import TypeReflect from 'models/lib/type_reflect';
import VgsAccountContent, { VgsSecretContent } from 'models/vgs_account_content';

export const AccountType = createEnum('FB_MESSENGER', 'VGS', 'INSTAGRAM_DIRECT');
const ContentClasses = [
  [AccountType.FB_MESSENGER, FbPageAccountContent],
  [AccountType.VGS, VgsAccountContent],
  [AccountType.INSTAGRAM_DIRECT, FbPageAccountContent],
];
const SecretContentClasses = [
  [AccountType.FB_MESSENGER, FbPageAccountSecretContent],
  [AccountType.VGS, VgsSecretContent],
  [AccountType.INSTAGRAM_DIRECT, FbPageAccountSecretContent],
];
const accountContentTypeReflect = new TypeReflect(ContentClasses);
const accountSecretContentTypeReflect = new TypeReflect(SecretContentClasses);

export default createModel({
  modelName: 'Account',
  properties: {
    id: prop(String).isRequired,
    type: String,
    content: prop().oneOf(FbPageAccountContent, VgsAccountContent),
    secretContent: prop().oneOf(FbPageAccountSecretContent, VgsSecretContent),
    description: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },

    overrideFromJs(fromJs) {
      return attrs => {
        if (!attrs.content) {
          throw new Error(`missing account content for ${attrs.id}`);
        }

        let account = fromJs(_.omit(attrs, ['content', 'secretContent']));
        let Constructor = accountContentTypeReflect.typeToConstructor(attrs.type);
        let SecretsConstructor = accountSecretContentTypeReflect.typeToConstructor(attrs.type);
        return new this({
          ...account,
          content: Constructor.fromJs(attrs.content),
          secretContent: SecretsConstructor.fromJs(attrs.secretContent),
        });
      };
    },
  },
});
