import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import ConversationStatusChange from 'models/conversation_status_change';

export default function createConversationStatusChangeItem({ agentId, conversationId, customerId, status }) {
  return ConversationItem.create({
    conversationId,
    content: ConversationStatusChange.create({
      status,
    }),
    customerId,
    initiator: {
      id: agentId,
      type: Communicator.AGENT,
    },
  });
}
