import _ from 'lodash';

import Err from 'models/err';
import NavigateBack from '../current_location/navigate_back';
import UserEditor from 'models/location/user_editor';

export default class AgentUserGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddErrors(errorsDto) {
    this.setErrors(null, errorsDto);
  }

  handleAddSuccess() {
    this.handleSuccess(null);
  }

  handleUpdateErrors(userId, errorsDto) {
    this.setErrors(userId, errorsDto);
  }

  handleUpdateSuccess(userId) {
    this.handleSuccess(userId);
  }

  handleSuccess(userId) {
    if (userId && this.currentEditingUser && this.currentEditingUser.id !== userId) {
      return;
    }

    this.userEditorStore.remove();
    if (this.isEditingUser()) {
      this.context.executeAction(NavigateBack);
    }
  }

  setErrors(userId, errorsDto) {
    if (!this.isEditingUser()) {
      return;
    }

    if (userId && this.currentEditingUser.id !== userId) {
      return;
    }

    let errors = _.map(errorsDto.errors, e => Err.fromJs(e));
    this.userEditorStore.setErrors(errors);
    this.userEditorStore.resetPending();
  }

  isEditingUser() {
    let currentLocation = this.context.stores.currentLocation.get();
    return currentLocation instanceof UserEditor;
  }

  get currentEditingUser() {
    return this.userEditorStore.get();
  }

  get userEditorStore() {
    return this.context.stores.userEditor;
  }
}
