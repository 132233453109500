import ErrorReporter from './error_reporter';
import Morearty from 'morearty';
import qconsole from 'scripts/lib/qconsole';

const logger = Object.freeze({
  error(message, cause) {
    qconsole.error(message);
    ErrorReporter.reportError(cause, { message });
  },
});

const moreartyCtx = Morearty.createContext({
  initialState: {
    viewState: {},
  },
  options: { logger },
});

export default moreartyCtx;
