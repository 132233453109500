import React, { useCallback, useContext, useRef } from 'react';

import AttachmentButton from 'components/lib/attachment/attachment_button';
import ComposerContext from 'components/composer/contexts/composer_context';
import { default as EmojiCore } from 'components/text_editor_new/components/controls/emoji_new';
import { Item } from 'components/text_editor_new/components/controls/controls_new';
import StartUpload from 'actions/composition/start_upload';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function Attachment({ allowedFileTypes = '', multiple = true }) {
  const attachmentsRef = useRef(null);

  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();
  const uploadFile = useCallback(
    file =>
      executeAction(StartUpload, {
        compositionId: composition.id,
        file,
      }),
    [composition.id, executeAction]
  );

  const onChange = useCallback(() => {
    const files = attachmentsRef.current.input.files;
    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }
  }, [uploadFile]);

  return (
    <AttachmentButton
      allowedFileTypes={allowedFileTypes}
      buttonComponent={Item}
      labelClassName=""
      multiple={multiple}
      onAttach={onChange}
      ref={attachmentsRef}
    />
  );
}

export function Emoji(props) {
  const editor = props.editorRef.current;
  return <EmojiCore editor={editor} {...props} />;
}
