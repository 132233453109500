import { createContentModel, isEmpty } from './composition_content_shared';

const ConversationMessageCompositionContent = createContentModel({
  modelName: 'ConversationMessageCompositionContent',

  properties: {
    bodyHtml: String,
    channel: String,
    channelDisplayTitle: String,
    companyAddress: String,
    companyDisplayName: String,
    customerAddress: String,
    customerDisplayName: String,
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },
});

ConversationMessageCompositionContent.MAX_ATTACHMENT_COUNT = 10;
ConversationMessageCompositionContent.MAX_TOTAL_ATTACHMENT_SIZE_MB = 5;

export default ConversationMessageCompositionContent;
