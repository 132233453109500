import React, { useContext } from 'react';
import WhatsappFillIcon from 'components/lib/icons/whatsapp_fill_icon';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function WhatsappRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.whatsapp;
  const callSla = sla.whatsapp;
  return (
    <FloorboardRow
      activeItems={stats.currentOpenMessages}
      activeLabel="Open Messages"
      currentWait={stats.currentWait}
      icon={<WhatsappFillIcon />}
      newItems={stats.currentNewMessages}
      newLabel="New Messages"
      sla={callSla}
      waitLabel="Oldest Unanswered Message"
    />
  );
}
