import moment from 'moment';

import createModel, { prop } from './lib/create_model';

const CommentPanel = createModel({
  modelName: 'CommentPanel',
  properties: {
    lastOpenedAt: prop(String).default(''),
    selectedItemId: prop(String).default(null),

    selectedComment: prop({
      id: prop(String).isRequired,
      at: prop(String).isRequired,
    }),
  },

  isOpen() {
    return !!this.lastOpenedAt;
  },

  open() {
    this.lastOpenedAt = moment().toISOString();
  },

  close() {
    this.lastOpenedAt = '';
  },

  selectItemId(itemId) {
    this.lastOpenedAt = moment().toISOString();
    this.selectedItemId = itemId;
  },

  clearItemId() {
    this.selectedItemId = null;
  },

  setSelectedCommentId(commentId) {
    if (commentId) {
      this.selectedComment = {
        id: commentId,
        at: moment().toISOString(),
      };
    } else {
      this.selectedComment = null;
    }
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CommentPanel;
