import Organization from 'models/organization';
import qconsole from 'scripts/lib/qconsole';
import analytics from 'scripts/lib/analytics';

export default class RequestOrganization {
  constructor(context) {
    this.context = context;
  }

  run() {
    const orgId = this.context.stores.auth.get().getOrgId();
    this.context.gateways.organizations
      .fetch({ orgId })
      .then(organizationDto => {
        const organization = Organization.fromJs(organizationDto);
        this.context.stores.organization.set(organization);
        analytics.updateUser({ loginDomain: organization.loginDomain });
      })
      .catch(err => {
        qconsole.error(`error fetching organization: `, err);
      })
      .finally(() => {
        this.context.stores.organization.resetLoading();
      });
  }
}
