import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import createEnum from 'scripts/lib/create_enum';

export const SORT_PARAMETERS = createEnum('DESC', 'ASC');
export const getNextSortOrder = sortOrder =>
  sortOrder === SORT_PARAMETERS.DESC ? SORT_PARAMETERS.ASC : SORT_PARAMETERS.DESC;

const SortControl = ({ currentSort }) => {
  if (!currentSort) {
    return null;
  }
  const classes = classnames('reportTable-sortControl', 'icon', {
    'ion-chevron-up': currentSort === SORT_PARAMETERS.ASC,
    'ion-chevron-down': currentSort === SORT_PARAMETERS.DESC,
  });
  return <i className={classes} />;
};

SortControl.propTypes = {
  currentSort: PropTypes.oneOf([SORT_PARAMETERS.ASC, SORT_PARAMETERS.DESC]),
};

export default SortControl;
