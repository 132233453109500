import TrackCustomerUpdate from './track_customer_update';

export default class ChangeName {
  constructor(context) {
    this.context = context;
  }

  run(newName) {
    newName = newName.trim();

    let customerProfile = this.context.stores.profile.get();
    customerProfile.changeName(newName);

    this.context.stores.profile.setPending(customerProfile);
    this.context.gateways.customerProfile.update({ customerId: this.currentCustomerId }, { name: newName });

    this.updateAgentInbox(customerProfile);

    this.context.executeAction(TrackCustomerUpdate, {
      customerId: this.currentCustomerId,
      field: 'name',
    });
  }

  updateAgentInbox(customerProfile) {
    let inboxItem = this.context.stores.agentInboxItems.findBy({ id: this.currentCustomerId });
    if (!inboxItem) {
      return;
    }

    inboxItem.setProfile(customerProfile);
    this.context.stores.agentInboxItems.addOrReplace(inboxItem);
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }
}
