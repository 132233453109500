import CustomReporting from 'models/location/custom_reporting';
import LoadCustomReports from 'actions/custom_reporting/load_custom_reports';
import { registerUrlConverter } from './location_url';
import StaticUrlConverter from './url_converters/static_url_converter';

registerUrlConverter(CustomReporting, StaticUrlConverter.create('/customreporting'));

export default function(router, { runnerContext }) {
  return {
    '/?(.*)': path => {
      runnerContext.executeAction(LoadCustomReports, { path });
    },
  };
}
