import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import AttributeDef, { AttributePresentation } from 'models/configuration/attribute_def';
import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';
import { evaluateTemplate } from 'components/customer/flexible_profile_card/lib/evaluate_template';
import { renderCustomAttributes } from 'components/customer/summary/lib/custom_attributes_helper';

export default function FlexibleBlock({ config, customAttributes, location, className }) {
  if (_.isEmpty(config)) {
    return null;
  }

  const attributesToRender = getAttributesToDisplay(config.attributes, customAttributes);
  if (_.isEmpty(attributesToRender)) {
    return null;
  }

  const renderableAttrDefs = _.map(attributesToRender, attrDef => {
    return new AttributeDef({ ...attrDef, presentation: AttributePresentation.VISIBLE });
  });

  // Evaluate the title template (if any) and render the results
  const titleTemplate = _.trim(config.title || '');
  const title = titleTemplate ? evaluateTemplate(titleTemplate, customAttributes) : '';
  const renderedTitle = title ? <StyledTitle className="flexible-block-title">{title}</StyledTitle> : null;
  const componentClasses = classnames('flexible-block', className);

  return (
    <StyledBlockContainer className={componentClasses}>
      {renderedTitle}
      {renderCustomAttributes(renderableAttrDefs, customAttributes, location)}
    </StyledBlockContainer>
  );
}

const StyledBlockContainer = styled.div`
  & + & {
    margin-top: 8px;
  }
`;

const StyledTitle = styled.div`
  font-weight: ${p => p.theme.fontWeight.mediumHeavy};
`;

FlexibleBlock.propTypes = {
  config: PropTypes.object.isRequired,
  customAttributes: PropTypes.object,
  location: PropTypes.string,
  className: PropTypes.string,
};
