import MqttGateway from './lib/mqtt_gateway';

export default class ApiTokenGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'users/+/tokens/+';
  }

  add(userId, apiToken) {
    return this._add([userId], apiToken);
  }

  onAddSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleAddSuccess', parsedTopicParams[0], payload);
  }

  onAddErrors(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleAddErrors', parsedTopicParams[0], payload);
  }

  onDelete(topic, parsedTopicParams) {
    this.notifyObservers('handleDelete', parsedTopicParams[0], parsedTopicParams[1]);
  }

  onReceive(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleEntity', parsedTopicParams[0], payload);
  }

  remove(userId, apiTokenId) {
    return this._delete([userId, apiTokenId]);
  }

  request(userId) {
    return this._subscribeAndFetch([userId]);
  }

  onFetchResponse(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleFetchResponse', parsedTopicParams[0], payload);
  }

  onFetchError(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleFetchErrors', parsedTopicParams[0], payload);
  }

  update(userId, apiTokenId, updatedAttrs) {
    return this._update([userId, apiTokenId], updatedAttrs);
  }

  onUpdateSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateSuccess', parsedTopicParams[0], parsedTopicParams[1]);
  }

  onUpdateErrors(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateErrors', parsedTopicParams[0], parsedTopicParams[1], payload);
  }
}
