import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class ShowSnippetSuccessToast {
  constructor(context) {
    this.context = context;
  }

  run(snippet) {
    this.context.executeAction(ShowToast, {
      type: ToastType.SUCCESS,
      message: `"${snippet.name}" has been saved. Click here to view.`,
      link: `/kb-admin/answer/${snippet.id}`,
    });
  }
}
