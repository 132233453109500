import _ from 'lodash';

import qconsole from 'scripts/lib/qconsole';
import reloadSingleDataObject from 'actions/external_data/reload_single_data_object';

/**
 * This action walks through all customer stores and reloads the custom data objects that match the `dataObjectParams`
 * parameters. It is primarily meant for selective reloading of the external data in the event of broadcasts (in which
 * case the `dataObjectParams` would be based on the broadcast topic params)
 *
 * Required `dataObjectParams` attributes:
 *    dataObjectParams.parentEntityId - ID of the entity we are reloading data for (e.g. customer Id)
 *    dataObjectParams.parentEntityType - type of the entity (e.g. "CUSTOMER")
 *
 * Optional `dataObjectParams` attributes:
 *    dataObjectParams.namespace
 */
export default class RefreshAllExternalDataObjects {
  constructor(context) {
    this.context = context;
  }

  run({ dataObjectParams, requestDataRefresh }) {
    const { namespace, parentEntityType, parentEntityId } = dataObjectParams || {};
    if (!parentEntityId || !parentEntityType) {
      return qconsole.error(
        `RefreshAllExternalDataObjects: missing or invalid parameters. Received [${JSON.stringify(dataObjectParams)}]`
      );
    }

    const loadedCustomerIds = this.context.stores.customers.getAllCustomerIds() || [];
    const filter = { parentEntityType, parentEntityId };
    if (namespace) filter.namespace = namespace;

    for (const customerId of loadedCustomerIds) {
      const store = this.context.stores.customers.storesFor(customerId).externalDataObjects;
      const affectedObjects = store.findAll({ filter });

      _.forEach(affectedObjects, envelope =>
        reloadSingleDataObject(this.context, store, customerId, envelope, !!requestDataRefresh)
      );
    }
  }
}
