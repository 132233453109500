import React from 'react';

export default props => (
  <svg fill="none" height="60" width="60" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#F1F1F1" height="60" rx="2" transform="matrix(.99979 -.00187 -.0017 1.00021 .102 .108)" width="60" />
    <path
      d="m29.797 30.528.366.16L36 33.24v-6.738l-6.271-2.58L22 26.548v6.796l7.422-2.681.375-.136ZM22 34.408l-1 .361v-8.937l8.762-2.979L37 25.834v8.936l-1-.437-6.238-2.728L22 34.408Z"
      fill="#B3B3B3"
    />
    <path
      d="m28.038 29.655.183.08.187-.067 8.092-2.924v7.867l-8.245 2.803-6.755-2.78v-7.837l6.538 2.858Z"
      fill="#F1F1F1"
      stroke="#B3B3B3"
    />
    <path d="M28.238 29.185v8.564" stroke="#B3B3B3" />
    <path
      d="m25.116 27.845.054.115 6.035-2.295-6.089 2.18ZM25.055 28.326l-.139-.04-.05 3.894.056.02.133-3.875Z"
      fill="#000"
      stroke="#B3B3B3"
    />
  </svg>
);
