import _ from 'lodash';
import Observable from 'scripts/lib/observable_mixin';

export default class DeviceStatusGateway {
  constructor(navigator) {
    var getUserMedia =
      navigator &&
      (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
    if (getUserMedia) {
      // getUserMedia must be executed in the context of navigator
      this.getUserMedia = getUserMedia.bind(navigator);
    }
  }

  getStatus() {
    if (this.getUserMedia) {
      this.getUserMedia({ audio: true }, this.handleResponseSuccess.bind(this), this.handleResponseError.bind(this));
    } else {
      this.handleResponseError(new Error('unable to request microphone'));
    }
  }

  handleResponseSuccess(stream) {
    stream.getAudioTracks()[0].stop();
    this.notifyObservers('handleUpdate', { microphone: true });
  }

  handleResponseError(err) {
    this.notifyObservers('handleUpdate', { microphone: false });
  }
}

_.extend(DeviceStatusGateway.prototype, Observable);
