import { Factory } from 'rosie';
import './lib/uuid';

import './widget_config_settings_factory';

Factory.define('WidgetConfiguration').extend('Uuid');

Factory.define('WidgetConfigurationWithDefaults')
  .extend('WidgetConfiguration')
  .attr('id', 'widget-12345')
  .attr('type', 'SOME_WIDGET')
  .attr('name', 'default-name')
  .attr('enabled', true)
  .attr('publicToken', 'token-12345')
  .attr('settings', Factory.attributes('WidgetConfigSettingsWithDefaults'));

