import SystemNotificationGenerator from 'scripts/domain/contracts/system_notification_generator';

export default function() {
  const notifications = new Map();

  SystemNotificationGenerator.newNotification = (notificationId, { title, body, icon, tag }) => {
    // Even though Notification class may be available, Chrome might throw on certain platforms (mobile, Linux etc.)
    try {
      const notification = new Notification(title, { body, icon, tag });
      notifications.set(notificationId, notification);
      return notification;
    } catch (ex) {
      return undefined;
    }
  };

  SystemNotificationGenerator.canGenerateNotifications = () => {
    return 'Notification' in window && Notification.permission === 'granted';
  };

  SystemNotificationGenerator.closeNotification = id => {
    const notification = notifications.get(id);
    if (notification) {
      notification.close();
      clearTimeout(notification.timeoutId);
      notifications.delete(id);
    }
  };
}
