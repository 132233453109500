import EmbeddedReport from 'scripts/domain/models/reports/embedded_report';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import mixin from 'scripts/lib/mixin';

export default class EmbeddedReportGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.embeddedReport;
  }

  onAddError(error) {
    this.handleCommonErrors(error);
    this.store.resetLoading();
  }

  onAddSuccess(reportDto) {
    this.store.set(EmbeddedReport.fromJs(reportDto));
    this.store.resetLoading();
  }
}

mixin(EmbeddedReportGatewayObserver.prototype, GatewayErrorInteractiveHandler.prototype);
