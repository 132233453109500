import moment from 'moment';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class EmbeddedReportService {
  constructor(database = getDatabase) {
    this.getDatabase = database;
  }

  getRoutes() {
    return bindCallbacks({
      '/api/reporting/v1/embedded-reports': {
        POST: (attrs, callback, path, { orgId }) =>
          callback(null, {
            status: 200,
            statusText: statusText(200),
            response: this.create(orgId, attrs),
          }),
      },
    });
  }

  create(orgId, payload) {
    return {
      expiringUrl: {
        url:
          'https://gladlydev.cloud.looker.com/embed/dashboards/gladly_master::reporting_summary_all_channels?allow_login_screen=true&Date=Last 2 weeks\n',
        expiry: moment()
          .utc()
          .add(1, 'days')
          .toISOString(),
      },
      metricSet: 'DeclinedAndMissedCallsReport',
      supportedFilters: ['inboxes', 'teams'],
      timezone: 'America/New_York',
    };
  }
}
