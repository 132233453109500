import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import connect from 'components/lib/connect';
import CustomChannel from 'models/custom_channel';
import CustomerView from 'models/location/customer_view';
import DismissNotification from 'actions/notification/dismiss_notification';
import { getBrandIconByName } from 'components/common/custom_channels';
import { iconClassFromSessionType } from 'components/lib/icon_classes';
import InteractionType, { baseInteractionType, customId } from 'models/interaction_type';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import Notification from './notification';

function InboundMessageNotification({
  customChannel,
  customerName,
  isNotificationForCurrentConversation,
  messageText,
  notificationId,
  onCancel,
  onReply,
  sessionType,
  title,
}) {
  if (!notificationId || isNotificationForCurrentConversation) {
    return null;
  }
  return (
    <Notification contentClasses="incomingConcurrentMessageNotification" notificationId={notificationId}>
      <div className="conversationNotification-topic">
        {renderIcon()}
        <div className="conversationNotification-notificationType">Reply</div>
        <div className="conversationNotification-divider" />
        <div className="conversationNotification-topic-container">
          <div className="conversationNotification-topic-heading">{customerName}</div>
          <div className="conversationNotification-divider" />
          {title && (
            <>
              <div className="conversationNotification-topic-heading">{title}</div>
              <div className="conversationNotification-divider" />
            </>
          )}

          <div className="conversationNotification-topic-body">{messageText}</div>
        </div>
      </div>
      <div>
        <Button buttonType={ButtonTypes.TEXT} contrast data-aid="notification-button-dismiss" onClick={onCancel}>
          Dismiss
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          contrast
          data-aid="notification-button-primary"
          onClick={onClickPrimary}
        >
          Reply
        </Button>
      </div>
    </Notification>
  );

  function onClickPrimary(ev) {
    ev.stopPropagation();
    onReply();
  }

  function renderIcon() {
    switch (baseInteractionType(sessionType)) {
      case InteractionType.CHAT:
        return <ChatIconOutline className="conversationNotification-chat-icon" />;
      case InteractionType.CUSTOM_CHANNEL:
        return getBrandIconByName(customChannel?.icon, {
          className: 'conversationNotification-customChannel-icon',
          color: 'white',
          fill: 'white',
          letter: customChannel?.name,
          stroke: true,
        }).icon;
      default:
        return <i className={iconClassFromSessionType(sessionType)} />;
    }
  }
}

InboundMessageNotification.propTypes = {
  customChannel: PropTypes.instanceOf(CustomChannel),
  customerName: PropTypes.string,
  icon: PropTypes.node,
  isNotificationForCurrentConversation: PropTypes.bool,
  messageText: PropTypes.string,
  notificationId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  sessionType: PropTypes.string,
  title: PropTypes.string,
};

const InboundMessageNotificationContainer = connect(mapStateToProps, mapExecuteToProps)(InboundMessageNotification);

function mapStateToProps({ getProvider }, { notification }) {
  const currentLocation = getProvider('currentLocation').get();
  const customChannels = getProvider('customChannels');
  const customers = getProvider('customers');

  return {
    customChannel: customChanel(),
    customerName: _.get(notification, 'profile.name') || 'Customer',
    isNotificationForCurrentConversation: isNotificationForCurrentConversation(),
    messageText: notification.messagePreview || 'New message',
    notificationId: notification.id,
    sessionType: notification.sessionType,
    title: title(),
  };

  function customChanel() {
    if (!isNotificationForCustomChannel()) {
      return undefined;
    }

    return customChannels.findBy({ id: customId(notification.sessionType) });
  }

  function isNotificationForCurrentConversation() {
    return (
      currentLocation instanceof CustomerView && currentLocation.currentConversationId === notification.conversationId
    );
  }

  function isNotificationForCustomChannel() {
    return baseInteractionType(notification.sessionType) === InteractionType.CUSTOM_CHANNEL;
  }

  function title() {
    if (!isNotificationForCustomChannel()) {
      return undefined;
    }

    const customerId = notification.profile.id;
    if (!customers.has(customerId)) {
      return undefined;
    }

    const conversationHistory = customers.getProvider(customerId).getProvider('conversationHistory');

    return conversationHistory.findBy({ id: notification.conversationItemId })?.content.groupName;
  }
}

function mapExecuteToProps(executeAction, props) {
  return {
    onReply: () => {
      executeAction(DismissNotification, props.notification.id);
      executeAction(NavigateToConversation, {
        customerId: props.notification.profile.id,
        conversationId: props.notification.conversationId,
      });
    },
    onCancel: () => executeAction(DismissNotification, props.notification.id),
  };
}

export { InboundMessageNotification };
export default InboundMessageNotificationContainer;
