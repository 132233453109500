import StandardGateway from './lib/standard_gateway';

const DEFAULT_TIMEOUT = 10000;

// This does seem to duplicate the SnippetSearchResultHTTPGateway, and it kind of does, but
// the issue with the other gateway is that it has an observer set on it, meaning that any
// codepath that does _not_ want to hit that observer but instead use the request() method
// for its own purposes need to jump through confusing hoops to prevent that observer's
// logic from running spuriously and having unintended effects.

// So this gateway explicitly disallows setting an observer, meaning that actions that use
// the `request()` method must use the Promise returned to perform logic, ensuring that
// all calls of this gateway's `request()` are isolated logically.
export default class AnswerSearchGateway extends StandardGateway {
  constructor(backend) {
    super(backend, { fetchUrl: '/api/v1/orgs/:orgId/search/snippets' });
  }

  request(params) {
    let url = this.endpoint.set(params).fetchUrl;
    return this.http.post(url, params, { timeout: DEFAULT_TIMEOUT });
  }

  setObserver() {}
}
