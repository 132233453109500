import createModel from './lib/create_model';

export default createModel({
  modelName: 'SnippetRevision',

  properties: {
    id: String,
    agentId: String,
    updatedAt: String,
  },
});
