import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import LinkOutStrokeIcon from 'components/common/icons/stroke/link-out-stroke';
import connect from 'components/lib/connect';
import Notification from './notification';
import DismissNotificationButtonContainer from './dismiss_notification_button';
import { formatPhoneNumber } from 'models/phone_number';
import UnofferedCallNotificationModel from 'models/notification/unoffered_call_notification';

const HelpLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${p => p.theme.colors.white};
  text-decoration-thickness: 1px;
  text-underline-position: under;
  color: ${p => p.theme.colors.white};
  &:focus {
    color: ${p => p.theme.colors.white};
  }
`;

const StyledLinkOutStroke = styled(LinkOutStrokeIcon)`
  width: 16px;
  margin-left: 6px;
  fill: ${p => p.theme.colors.white};
  vertical-align: middle;
`;
const NotificationTextContainer = styled.div`
  display: block;
  color: ${p => p.theme.colors.white};
`;
const CustomerInfo = styled.span`
  font-weight: ${p => p.theme.fontWeight.heavy};
`;

export function MissedCallNotification({ notification, customerName }) {
  const customer = customerName
    ? customerName
    : formatPhoneNumber(notification.conversationItem?.content?.customerNumber);
  return (
    <Notification
      contentClasses="unofferedCallNotification"
      key={`notification-${notification.id}`}
      notificationId={notification.id}
    >
      <div className="missedCallNotification-message">
        <NotificationTextContainer>
          Missed call from <CustomerInfo>{customer}</CustomerInfo>. The call has been redirected to another agent. Learn
          why in our{' '}
          <HelpLink
            href="https://help.gladly.com/docs/missed-call-notification"
            rel="noopener noreferrer"
            target="_blank"
          >
            Help Docs.
          </HelpLink>
          <StyledLinkOutStroke />
        </NotificationTextContainer>
        <DismissNotificationButtonContainer buttonText="Close" notificationId={notification.id} />
      </div>
    </Notification>
  );
}

MissedCallNotification.propTypes = {
  notification: PropTypes.instanceOf(UnofferedCallNotificationModel).isRequired,
  customerName: PropTypes.string,
};

const MissedCallNotificationContainer = connect(mapStateToProps)(MissedCallNotification);

function mapStateToProps(context, props) {
  let customerName;
  try {
    const customerId = props.notification.conversationItem?.customerId;
    customerName = context
      .getProvider('customers')
      .getProvider(customerId)
      .profile.get().name;
  } catch {
    return;
  }
  return {
    customerName,
  };
}

export default MissedCallNotificationContainer;
