import AddRelationship from 'actions/relationship/add_relationship';
import CustomerEmailAddress from 'models/customer_email_address';
import CustomerProfile from 'models/customer_profile';
import CustomerProfileConverter from 'scripts/application/dto_converters/customer_profile_converter';
import Err from 'models/err';
import LinkItemToCustomer from './link_item_to_customer';
import PhoneNumber, { getValidationErrors } from 'models/phone_number';

export default class CreateProfileAndLinkItem {
  constructor(context) {
    this.context = context;
  }

  run({ itemId, originalCustomerId, profileAttrs }) {
    const { address, email, id, name, phone, labelId } = profileAttrs;

    const customerStores = this.context.stores.customers.storesFor(id);
    const profileStore = customerStores.profile;

    let errors = [];

    if (!email && !name && !phone) {
      errors = [
        new Err({
          code: Err.Code.BLANK,
          attr: 'name',
          detail: 'Must specify name, email, or phone',
        }),
        new Err({
          code: Err.Code.BLANK,
          attr: 'emails.0',
        }),
        new Err({
          code: Err.Code.BLANK,
          attr: 'phones.0',
        }),
      ];
      profileStore.setErrors(errors);
      profileStore.resetPending();
      return;
    }

    let phones = null;
    if (phone) {
      const phoneErrors = getValidationErrors({ phoneNumber: phone });
      if (phoneErrors.length) {
        errors = phoneErrors.map(
          err =>
            new Err({
              code: err.code,
              attr: `phones.0.${err.attr}`,
              detail: err.detail,
            })
        );
      } else {
        phones = [PhoneNumber.create({ original: phone })];
      }
    }

    if (errors.length) {
      profileStore.setErrors(errors);
      profileStore.resetPending();
      return;
    }

    let emails = email ? [CustomerEmailAddress.create({ original: email })] : null;

    const customerProfile = CustomerProfile.create({ address, id, name, emails, phones });
    profileStore.setPending(customerProfile);
    const customer = this.context.stores.customers.find(id);
    this.context.stores.customers.setPendingNew(customer);

    this.context.gateways.customerProfile.add(CustomerProfileConverter.toDto(customerProfile)).then(() => {
      if (itemId) {
        this.context.executeAction(LinkItemToCustomer, {
          itemId,
          linkedCustomerId: id,
          linkedCustomerName: customerProfile.name,
          originalCustomerId,
        });
      }

      this.context.executeAction(AddRelationship, {
        originalCustomerId,
        customerId: id,
        labelId,
      });
    });
  }
}
