import _ from 'lodash';
import moment from 'moment';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import ConversationItemsService from 'scripts/infrastructure/backends/fake_backend/conversation_items_service';
import Factory from 'factories/all';

export default class ExternalCustomerLookupActionsService {
  constructor(database, pubsub) {
    this.getDatabase = database;
    this.publisher = pubsub;
    this.conversationItemsService = ConversationItemsService.create(pubsub, database);
  }

  getCodeForOrgAndType(orgId, type) {
    return _.find(this.getDatabase(orgId).externalActionForms, { type });
  }

  getForm(attrs, callback, path, { orgId }) {
    let response;
    const formType = _.last(_.split(attrs.formUrl, '/'));
    if (formType) {
      if (formType === 'discount-codes') {
        response = this.getCodeForOrgAndType(orgId, 'discountCode');
      } else if (formType === 'issue-credits') {
        response = this.getCodeForOrgAndType(orgId, 'issueCredit');
      } else if (formType === 'schedule-grooming-appointments') {
        response = this.getCodeForOrgAndType(orgId, 'scheduleGroomingAppointment');
      } else if (formType === 'customer-give-backs') {
        response = this.getCodeForOrgAndType(orgId, 'customerGiveBacks');
      } else {
        response = this.getCodeForOrgAndType(orgId, formType);
      }
    }

    if (response) {
      callback(null, { status: 200, statusText: statusText(200), response });
    } else {
      callback(null, { status: 404, statusText: statusText(404) });
    }
  }

  performAction(attrs, callback, path, { orgId, customerId }) {
    const { title, body } = this.getActivityForAction(attrs);
    const customerActivity = Factory.build('CustomerActivityContentItem', {
      customerId,
      id: moment()
        .valueOf()
        .toString(),
      initiator: {
        type: 'AGENT',
        id: this.getDatabase(orgId).currentAgent.id,
      },
      timestamp: moment().format(),
      content: {
        type: 'CUSTOMER_ACTIVITY',
        activityType: 'ACTION_API',
        title,
        sourceName: 'Source name',
        body,
      },
    });
    this.conversationItemsService.add(orgId, customerId, { payload: customerActivity });
    callback(null, { status: 200, statusText: statusText(200) });
  }

  getActivityForAction(payload) {
    const actionType = _.last(_.split(payload.actionUrl, '/'));
    switch (actionType) {
      case 'unsubscribe':
        return {
          title: 'Customer unsubscribed from mailing lists',
          body: `Lists: ${payload.mailingList}`,
        };
      case 'fraud':
        return {
          title: 'Fraud report submitted',
          body: `Reason given: ${payload.reason}`,
        };
    }
    return {
      title: 'Action completed',
      body: 'You successfully completed the action',
    };
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customers/:customerId/external-customer-lookup/action-forms': {
          POST: this.getForm,
        },
        '/api/v2/orgs/:orgId/customers/:customerId/external-customer-lookup/integrations/:integrationId/action-forms': {
          POST: this.getForm,
        },
        '/api/v1/orgs/:orgId/customers/:customerId/external-customer-lookup/actions': {
          POST: this.performAction,
        },
        '/api/v2/orgs/:orgId/customers/:customerId/external-customer-lookup/integrations/:integrationId/actions': {
          POST: this.performAction,
        },
      },
      this
    );
  }
}
