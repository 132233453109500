import AnswersPreview from 'models/location/answers_preview';
import DtoConverter from 'scripts/application/dto_converters/search/snippet_search_result_converter';
import HandleAnswerSearchResults from 'actions/answers/handle_answer_search_results';
import qconsole from 'scripts/lib/qconsole';

export default class SnippetSearchResultGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.snippetSearchResult;
  }

  onFetchSuccess(dto, id) {
    const search = DtoConverter.fromDto({ ...dto, id });

    const currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation.customerId || currentLocation instanceof AnswersPreview) {
      this.context.executeAction(HandleAnswerSearchResults, { search });
      return;
    }

    const snippetSearchResult = this.store.get();
    if (snippetSearchResult && snippetSearchResult.id === search.id) {
      this.store.set(search);
      this.context.stores.snippets.set(search.hits);
    }
  }

  onFetchError(error) {
    qconsole.log(`Failed to fetch snippet search result ${JSON.stringify(error)}`);
  }
}
