import React from 'react';

export default function MessagingSelected() {
  return (
    <svg className="availabilityIcon availabilityIcon-messagingSelected" height="32" width="32">
      <defs>
        <rect className="availabilityIcon-messagingSelected-rect" height="30" id="a" rx="15" width="30" />
      </defs>
      <g fill="#009B00" fillRule="evenodd">
        <g transform="translate(1 1)">
          <rect
            className="availabilityIcon-messagingSelected-rect"
            height="29"
            rx="14.5"
            stroke="#009b00"
            width="29"
            x=".5"
            y=".5"
          />
        </g>
        <path
          className="availabilityIcon-messagingSelected-path"
          d="M17.082 21.5l-3.707 3.707V21.5h-.25C9.755 21.5 7 18.745 7 15.375S9.755 9.25 13.125 9.25h6c3.37 0 6.125 2.755 6.125 6.125S22.495 21.5 19.125 21.5h-2.043z"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
