import connect from 'components/lib/connect';
import React from 'react';
import PropTypes from 'prop-types';

import { ButtonTypes } from 'components/common/button';
import ConfirmOrCancelModal from 'components/common/confirm_or_cancel_modal';
import CloseModal from 'actions/modal/close_modal';
import RedactConversationItem from 'actions/compliance/conversation/redact_conversation_item';

function ConversationItemRedactModal(props) {
  const onSubmit = () => {
    props.onConfirm({ customerId: props.customerId, itemId: props.itemId });
  };

  return <ConfirmOrCancelModal {...props} onSubmit={onSubmit} />;
}

ConversationItemRedactModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
};

const ConversationItemRedactModalContainer = connect(mapStateToProps, mapExecuteToProps)(ConversationItemRedactModal);

export default ConversationItemRedactModalContainer;

function mapStateToProps({ getProvider }) {
  const modalAttrs = getProvider('modal').get();

  return {
    itemId: modalAttrs.itemId,
    customerId: modalAttrs.customerId,
    title: 'Delete message',
    submitText: 'Delete message',
    text: 'Are you sure you want to delete this message? This cannot be undone.',
    submitButtonType: ButtonTypes.DANGER,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onCancel: () => {
      executeAction(CloseModal);
    },
    onConfirm: props => {
      executeAction(RedactConversationItem, props);
    },
  };
}
