import clearExcessSnowplowStorage from 'scripts/adapters/stores/lib/clear_excess_snowplow_storage';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getStorageSizes from 'scripts/adapters/stores/lib/get_storage_sizes';

export const STORAGE_KEY = 'email-suggestions';

export default class LocalEmailSuggestionsStore {
  constructor(localStorage) {
    this.localStorage = localStorage;
  }

  set(emailSuggestions) {
    const json = JSON.stringify(emailSuggestions);
    try {
      this.localStorage.setItem(STORAGE_KEY, json);
    } catch (e) {
      const sizes = getStorageSizes(this.localStorage);
      clearExcessSnowplowStorage(this.localStorage);
      ErrorReporter.reportError(e, {
        extra: {
          sizes,
          sizesAfterClearing: getStorageSizes(this.localStorage),
        },
      });
    }
  }

  get() {
    const json = this.localStorage.getItem(STORAGE_KEY);
    return json ? JSON.parse(json) : [];
  }

  remove() {
    this.localStorage.removeItem(STORAGE_KEY);
  }
}
