import connect from 'components/lib/connect';
import Paginate from 'components/common/paginate';
import SearchAnswers from 'actions/knowledge_base/search_answers';

const SnippetAdminPaginateContainer = connect(mapStateToProps, mapExecuteToProps)(Paginate);

function mapStateToProps(context, props) {
  let from, size, total;
  let result = context.getProvider('snippetSearchResult').get();
  if (result && result.query) {
    from = result.query.from;
    size = result.query.size;
    total = result.aggregations ? result.aggregations.types.total : 0;
  }

  return {
    from,
    isLoading: result ? result.isLoading : true,
    size,
    total,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onChange: (from, size) => executeAction(SearchAnswers, { from, size, searchText: props.searchText }),
  };
}

export default SnippetAdminPaginateContainer;
