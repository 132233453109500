import _ from 'lodash';

import { default as AddNoteItem } from 'actions/composition/add_note';
import convertAgentIdsToMentions from './shared/convert_agent_ids_to_mentions';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class AddNote {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, content }) {
    const composition = getCompositionsStore(this.context).findBy({ id: compositionId });
    if (!composition) {
      return;
    }

    content = convertAgentIdsToMentions(this.context, content);

    const compositionContent = composition.toJs().content;
    _.forEach(content, (value, attr) => {
      compositionContent[attr] = value;
    });

    this.context.executeAction(AddNoteItem, {
      compositionId,
      content: compositionContent,
    });
  }
}
