import ConversationMessageAttachment from './conversation_message_attachment';
import createModel, { prop } from './lib/create_model';
import redactAttachment from './attachment_redact';

const MessageContentTypeFile = createModel({
  modelName: 'MessageContentTypeFile',
  properties: {
    text: prop(String),
    bodyHtml: prop(String),
    files: prop([ConversationMessageAttachment]).default([]),
    buttons: prop(Object).default([]),
    hasRedactedPaymentCardNumber: prop(Boolean).default(false),
  },

  getBody() {
    return this.text || '';
  },

  redactAttachment(id) {
    redactAttachment(this.files, id);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default MessageContentTypeFile;
