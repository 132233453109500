import ConversationItem from '../../models/conversation_item';
import Communicator from '../../models/communicator';
import Task from '../../models/task';

export default function createTaskItem({
  agentProfile,
  conversationId,
  content,
  customerId,
  snippetIds = [],
  relatedSnippetIds = [],
}) {
  return ConversationItem.create({
    conversationId,
    content: Task.create(content),
    customerId,
    initiator: {
      id: agentProfile.id,
      type: Communicator.AGENT,
    },
    snippetIds,
    relatedSnippetIds,
  });
}
