import { createPluginFactory } from '@udecode/plate';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import StartUpload from 'actions/composition/start_upload';
import useExecuteAction from 'components/hooks/use_execute_action';

export default function createPasteAttachmentsPluginFactory(uploadFileRef) {
  return createPluginFactory({
    key: 'paste-attachments',

    withOverrides: withPasteAttachments,
  });

  function withPasteAttachments(editor) {
    const defaultInsertData = editor.insertData;

    editor.insertData = data => {
      const { files } = data;

      if (files && files.length > 0) {
        for (const file of files) {
          uploadFileRef.current(file);
        }
      } else {
        defaultInsertData(data);
      }
    };

    return editor;
  }
}

export function useCreatePasteAttachmentsPlugin() {
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();

  const uploadFile = useCallback(
    file => {
      executeAction(StartUpload, { compositionId: composition.id, file });
    },
    [composition.id, executeAction]
  );

  const uploadFileRef = useRef(uploadFile);
  useEffect(() => {
    uploadFileRef.current = uploadFile;
  }, [uploadFile]);

  return useMemo(() => createPasteAttachmentsPluginFactory(uploadFileRef), []);
}
