import createModel, { prop } from './lib/create_model';
import { RoutingPreferenceGroup } from 'models/agent_routing_preferences';

const RoutableCounts = createModel({
  modelName: 'RoutableCounts',

  properties: {
    [RoutingPreferenceGroup.MESSAGING]: prop(Number).default(0),
    [RoutingPreferenceGroup.NON_INTERACTIVE]: prop(Number).default(0),
    [RoutingPreferenceGroup.TASK]: prop(Number).default(0),
    [RoutingPreferenceGroup.VOICE]: prop(Number).default(0),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default RoutableCounts;
