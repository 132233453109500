import _ from 'lodash';

import Communicator from 'models/communicator';
import SwitchCurrentConversation from 'actions/customer/switch_current_conversation';

export default class UpdateConversationTopicIds {
  constructor(context) {
    this.context = context;
  }

  run({ id, customerId, topicChanges, modelVersion }) {
    let agentId = this.currentAgent.id;

    this.context.gateways.customerHistory.updateConversationTopicIds(customerId, id, {
      ...topicChanges,
      initiator: {
        type: Communicator.AGENT,
        id: agentId,
      },
    });

    let conversations = this.context.stores.customers.storesFor(customerId).conversations;
    let conversation = conversations.findBy({ id });
    let topicIds = _.union(conversation.topicIds, topicChanges.add);
    topicIds = _.difference(topicIds, topicChanges.remove);
    let updates = { topicIds };

    if (topicIds.length === 1) {
      updates.topicId = topicIds[0];
    }
    conversation.update(updates);
    conversations.replace(conversation);

    if (this.context.stores.currentLocation.get().customerId === customerId) {
      // show applied topics in the feed, i.e. scroll to bottom
      this.context.executeAction(SwitchCurrentConversation, { conversationId: conversation.id });
    }

    this.context.analytics.track('Topics Updated', {
      customerId,
      conversationId: conversation.id,
      countTopicsAdded: topicChanges.add,
      countTopicsRemoved: topicChanges.remove,
      modelVersion,
    });
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
