import _ from 'lodash';
import createLocationModel, { prop } from './lib/create_location_model';
import LiveboardType from 'models/liveboards/liveboard_type';

const DEFAULT_LIVEBOARD_TYPE = LiveboardType.SUMMARY;

const Liveboards = createLocationModel({
  modelName: 'Liveboards',
  properties: {
    routingGroupIds: prop([String]),
    teamIds: prop([String]),
    timezone: prop(String).default(''),
    type: prop()
      .oneOf(..._.values(LiveboardType))
      .default(DEFAULT_LIVEBOARD_TYPE),
  },

  deriveNew(newQuery) {
    return Liveboards.create(_.assign({}, this, newQuery));
  },

  setRoutingGroupIds(routingGroupIds) {
    this.routingGroupIds = routingGroupIds;
  },

  getRoutingGroupIds() {
    return this.routingGroupIds;
  },

  setTeamIds(teamIds) {
    this.teamIds = teamIds;
  },

  getTimezone() {
    return this.timezone;
  },

  setTimezone(timezone) {
    this.timezone = timezone;
  },

  statics: {
    breadcrumb: 'Liveboards',

    create({ type, routingGroupId, routingGroupIds, teamIds, timezone } = {}) {
      if (type && !(type in LiveboardType)) {
        throw new Error(`[${type}] is not a valid Liveboard type`);
      }

      return new this(_.omitBy({ type, routingGroupId, routingGroupIds, teamIds, timezone }, v => !v));
    },

    defaultType: DEFAULT_LIVEBOARD_TYPE,
  },
});

export default Liveboards;
