'use strict';

import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('AgentActivity')
  .extend('Uuid');

Factory.define('AgentActivityWithDefaults')
  .extend('AgentActivity')
  .attr('agentId', 'exampleAgentId')
  .attr('timestamp', '2016-09-08T22:29:45.929Z')
  .attr('type', 'INBOX')
  .attr('customerId', 'exampleCustomerId')
  .attr('routingGroupId', 'exampleRoutingGroupId')
  .attr('conversationId', 'exampleConversationId');
