import DisconnectNotification from 'models/notification/disconnect_notification';

export default class HideDisconnectWarning {
  constructor(context) {
    this.context = context;
  }

  run() {
    let disconnectNotifications = this.context.stores.notifications
      .findAll()
      .filter(n => n instanceof DisconnectNotification);
    disconnectNotifications.forEach(n => this.context.stores.notifications.remove(n.id));
  }
}
