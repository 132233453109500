import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import BubbleContent from '../content/bubble_content';
import { ExecuteActionContext } from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import { HeightChangeContext } from 'components/customer/conversation_history/virtualized/list_item';
import ExternalLink, { ChatLinkType } from 'components/lib/external_link';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import { MetadataExpander, MetadataText, MetadataType } from './self_service_metadata';
import { TimelineEventType } from 'models/chat_self_service_metadata';
import SafeHtml from 'components/common/safe_html';
import ViewPublicAnswer from 'actions/customer/view_public_answer';
import CampaignTriggeredContent from './chat_self_service_campaign_timeline_event';

export const Bold = styled(SafeHtml)`
  cursor: ${p => (p.$isLink ? 'pointer' : 'auto')};
  text-decoration: ${p => (p.$isLink ? 'underline' : 'none')};
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  & .highlightedSearchTerm {
    background-color: inherit;
    color: black;
    flex-shrink: 0;
  }
`;

export const ActivityText = styled.div`
  display: flex;
`;

const StyledBubbleContent = styled(BubbleContent)`
  min-width: 400px;
`;

function AnswerLink({ name, id }) {
  const executeAction = useContext(ExecuteActionContext);
  const displayAnswer = useCallback(() => executeAction(ViewPublicAnswer, { id }), [executeAction, id]);
  return <Bold $isLink html={name} onClick={displayAnswer} />;
}

export function SelfServiceTimelineEvents({ isFirst, timelineEvents, startExpanded = false }) {
  const [isExpanded, setIsExpanded] = useState(startExpanded);
  const { onHeightChange } = useContext(HeightChangeContext);
  useEffect(() => onHeightChange && onHeightChange({ isManual: true }), [isExpanded, onHeightChange]);
  const expandTimeline = useCallback(() => setIsExpanded(true), []);

  if (!timelineEvents || !timelineEvents.length) {
    return null;
  }

  let timelineViews = [];
  if (!isExpanded && timelineEvents.length > 1) {
    const count = timelineEvents.length;
    timelineEvents = timelineEvents.slice(count - 1, count);
    timelineViews.push(
      <MetadataExpander
        count={count - 1}
        key={`${MetadataType.OTHER}-expander`}
        onClick={expandTimeline}
        type={MetadataType.OTHER}
      />
    );
  }

  let previousEvent = null;
  for (let i = 0; i < timelineEvents.length; i++) {
    const event = timelineEvents[i];
    const isLast = i === timelineEvents.length - 1;
    const index = isFirst ? i : i + 1;
    timelineViews = timelineViews.concat(renderTimlineEvent(event, index, isLast, previousEvent));
    previousEvent = event;
  }

  return timelineViews;
}

function renderTimlineEvent(event, index, isLast, previousEvent) {
  switch (event.type) {
    case TimelineEventType.QUICK_ANSWER_VIEWED:
      return renderTimlineQuickActionViewedContent(event, index, isLast);
    case TimelineEventType.SEARCH_RESULT_VIEWED:
      return renderTimlineSearchViewedContent(event, index, isLast, previousEvent);
    case TimelineEventType.UNANSWERED_SEARCH:
      return renderTimlineUnansweredSearchContent(event, index, isLast);
    case TimelineEventType.CAMPAIGN_TRIGGERED:
      return renderTimlineCampaignTriggeredContent(event, index, isLast);
    default:
      return [];
  }
}

function renderTimlineSearchViewedContent({ content }, index, isLast, previousEvent) {
  const { type: previousType, content: previousContent } = previousEvent ? previousEvent : {};
  let views = [];
  if (!(previousType === TimelineEventType.SEARCH_RESULT_VIEWED && content.searchTerm === previousContent.searchTerm)) {
    views.push(
      <MetadataText
        isFirst={index === 0}
        isLast={false}
        key={`${MetadataType.SEARCH}-${index}`}
        node={<Bold>{content.searchTerm}</Bold>}
        type={MetadataType.SEARCH}
      />
    );
  }
  views.push(
    <MetadataText
      isFirst={false}
      isLast={isLast}
      key={`${MetadataType.VIEW}-${index}`}
      node={<AnswerLink id={content.answerId} name={content.answerName} />}
      type={MetadataType.VIEW}
    />
  );
  return views;
}

function renderTimlineQuickActionViewedContent({ content }, index, isLast) {
  return [
    <MetadataText
      isFirst={index === 0}
      isLast={isLast}
      key={`${MetadataType.QUICK_ACTION}-${index}`}
      node={<AnswerLink id={content.answerId} name={content.answerName} />}
      type={MetadataType.QUICK_ACTION}
    />,
  ];
}

function renderTimlineUnansweredSearchContent({ content }, index, isLast) {
  return [
    <MetadataText
      isFirst={index === 0}
      isLast={isLast}
      key={`${MetadataType.SEARCH}-${index}`}
      node={<Bold>{content.searchTerm}</Bold>}
      type={MetadataType.SEARCH}
    />,
  ];
}

function renderTimlineCampaignTriggeredContent({ content }, index, isLast) {
  return [
    <MetadataText
      isFirst={index === 0}
      isLast={isLast}
      key={`${MetadataType.CAMPAIGN}-${index}`}
      node={<CampaignTriggeredContent content={content} />}
      type={MetadataType.CAMPAIGN}
    />,
  ];
}

export function ChatSelfServiceMetadataItem({ item }) {
  const { customerUrl, timelineEvents } = item.content;
  const isEmptyUrl = !(customerUrl && customerUrl.trim() !== '');
  const isEmptyTimeLine = !(timelineEvents && timelineEvents.length > 0);
  if (isEmptyUrl && isEmptyTimeLine) {
    return null;
  }

  function getItemMetadataText(content) {
    if (content.greeting) {
      return 'responded to a Proactive Chat';
    }
    return null;
  }

  return (
    <ItemContent item={item}>
      <ItemMetadata item={item} text={getItemMetadataText(item.content)} />
      <StyledBubbleContent item={item}>
        {!isEmptyUrl && (
          <MetadataText
            isFirst
            isLast={isEmptyTimeLine}
            node={<ExternalLink text={customerUrl} type={ChatLinkType} url={customerUrl} />}
            type={MetadataType.URL}
          />
        )}
        <SelfServiceTimelineEvents isFirst={isEmptyUrl} timelineEvents={timelineEvents} />
      </StyledBubbleContent>
    </ItemContent>
  );
}

ChatSelfServiceMetadataItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default ChatSelfServiceMetadataItem;
