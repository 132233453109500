export default class ClearSelectedAnswer {
  constructor(context) {
    this.context = context;
  }

  run() {
    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.clearAnswer();
    this.context.stores.answerPanel.set(answerPanel);
  }
}
