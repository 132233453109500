import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from '../lib/create_model';
import NotificationType from './notification_type';
import _ from 'lodash';

export const PhoneCallObserverNotificationRecipientType = createEnum('AGENT', 'OBSERVER');

export default createModel({
  modelName: 'PhoneCallObserverNotification',

  properties: {
    id: prop(String), // AD only field
    message: prop(String),
    recipient: prop().oneOf(..._.keys(PhoneCallObserverNotificationRecipientType)),
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.PHONE_CALL_OBSERVER });
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
