import Composition from 'models/composition';

export default class CompositionUploads {
  constructor({ agentId, compositionId, uploads }) {
    Object.assign(this, { agentId, compositionId, uploads });
  }

  getUploadPath(uploadId) {
    return Composition.getUploadPath(this.compositionId, uploadId);
  }

  static create({ agentId, compositionId, uploads }) {
    return new CompositionUploads({ agentId, compositionId, uploads });
  }
}
