import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getNormalizedUrl, isUrlValid } from 'components/lib/linkify_it';
import PopoverCard from 'components/common/popover_card';

const ESCAPE = 27;
const ENTER = 13;

class FloatingLinkInput extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['onKeyDown']);
  }

  componentDidMount() {
    // unfortunately needed for IE
    setImmediate(() => {
      this.input && this.input.focus();
    });
  }

  render() {
    return (
      <StyledInput
        className="draftStyles-floatingLinkMenu-input"
        data-aid="floatingLinkMenu-input"
        onBlur={this.props.onBlur}
        onChange={this.props.onChange}
        onKeyDown={this.onKeyDown}
        placeholder="http://www.example.com"
        ref={node => (this.input = node)}
        value={this.props.value}
      />
    );
  }
  onKeyDown(evt) {
    if (evt.keyCode === ENTER) {
      evt.preventDefault();
      this.props.onSubmit();
    } else if (evt.keyCode === ESCAPE) {
      evt.preventDefault();
      this.props.onEscape();
    }
  }
}

FloatingLinkInput.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEscape: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export { FloatingLinkInput };

class SlateFloatingLinkMenu extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['onBlur', 'onChange', 'onEscape', 'onSubmit', 'onRemove', 'onClickChange']);
    this.state = { isCurrentlyEditing: false, url: this.getCurrentUrl() };
  }

  componentDidMount() {
    this.setTargetElement();
  }

  componentDidUpdate() {
    this.setTargetElement();
  }

  setTargetElement() {
    if (!this.state.targetElement) {
      const targetElement = window.document.querySelector(`[data-key="${this.props.id}"]`);
      if (targetElement) {
        this.setState({ targetElement });
      }
    }
  }

  render() {
    return (
      <StyledPopoverCard
        className="slate-floatingLinkMenu"
        data-aid="slate-floatingLinkMenu"
        isVisible
        position="top"
        targetElement={this.state.targetElement}
      >
        {this.getCurrentUrl() ? this.renderEditing() : this.renderAdding()}
      </StyledPopoverCard>
    );
  }

  renderEditing() {
    if (this.state.isCurrentlyEditing) {
      return (
        <span className="draftStyles-floatingLinkMenu-wrapper">
          <FloatingLinkInput
            onBlur={this.onBlur}
            onChange={this.onChange}
            onEscape={this.onEscape}
            onSubmit={this.onSubmit}
            value={this.state.url}
          />
          <span className="draftStyles-floatingLinkMenu-separator" />
          <span
            className="draftStyles-floatingLinkMenu-save"
            data-aid="floatingLinkMenu-save"
            onClick={this.onSubmit}
            onMouseDown={evt => evt.preventDefault()}
          >
            Save
          </span>
          <span
            className="draftStyles-floatingLinkMenu-remove"
            onClick={this.onRemove}
            onMouseDown={evt => evt.preventDefault()}
          >
            Remove
          </span>
        </span>
      );
    }

    const url = this.getCurrentUrl();
    const linkClassNames = classnames('draftStyles-floatingLinkMenu-link', {
      'draftStyles-floatingLinkMenu-link-invalid': !isUrlValid(url),
    });

    return (
      <span className="draftStyles-floatingLinkMenu-wrapper" data-aid="floatingLinkMenu-wrapper">
        <a className={linkClassNames} href={url} rel="noreferrer" target="_blank">
          {url}
        </a>
        <span className="draftStyles-floatingLinkMenu-separator" />
        <span
          className="draftStyles-floatingLinkMenu-change"
          data-aid="floatingLinkMenu-change"
          onClick={this.onClickChange}
          onMouseDown={evt => evt.preventDefault()}
        >
          Change
        </span>
        <span
          className="draftStyles-floatingLinkMenu-remove"
          onClick={this.onRemove}
          onMouseDown={evt => evt.preventDefault()}
        >
          Remove
        </span>
      </span>
    );
  }

  renderAdding() {
    return (
      <span className="draftStyles-floatingLinkMenu-wrapper">
        <FloatingLinkInput
          onBlur={this.onBlur}
          onChange={this.onChange}
          onEscape={this.onEscape}
          onSubmit={this.onSubmit}
          value={this.state.url || ''}
        />
        <span className="draftStyles-floatingLinkMenu-separator" />
        <span
          className="draftStyles-floatingLinkMenu-add"
          data-aid="floatingLinkMenu-add"
          onClick={this.onSubmit}
          onMouseDown={evt => evt.preventDefault()}
        >
          Add
        </span>
      </span>
    );
  }

  // Helpers

  getCurrentUrl() {
    if (!this.props.editor) {
      return null;
    }

    const editor = this.props.editor;
    const value = editor.value;
    let url;
    value.inlines.forEach(inline => {
      if (inline.type === 'link') {
        url = inline.get('data').get('href');
      }
    });

    return url;
  }

  // Handlers

  onBlur(evt) {
    if (!this.getCurrentUrl()) {
      this.onRemove();
    }
  }

  onChange(event) {
    const url = event.target.value;
    this.setState({ url });
  }

  onEscape() {
    this.getCurrentUrl() ? this.setState({ isCurrentlyEditing: false }) : this.onRemove();
  }

  onRemove() {
    this.props.onChange(this.props.editor.unwrapInline('link'));
  }

  onSubmit() {
    const editor = this.props.editor;
    const value = editor.value;
    let link;
    value &&
      value.inlines.forEach(inline => {
        if (inline.type === 'link') {
          link = inline;
        }
      });
    if (!link) return;

    let currentData = link.data.toJSON();
    const href = getNormalizedUrl(this.state.url);
    let newData = { ...currentData, href };
    this.props.onChange(editor.setNodeByKey(link.key, { data: newData }).focus());
    this.setState({ url: href, isCurrentlyEditing: false });
  }

  onClickChange() {
    this.setState({ isCurrentlyEditing: true });
  }
}

SlateFloatingLinkMenu.propTypes = {
  editor: PropTypes.object,
  id: PropTypes.string,
  onChange: PropTypes.func,
};

const StyledPopoverCard = styled(PopoverCard)`
  padding: 0;
`;

const StyledInput = styled.input`
  margin: 0;
`;

export { StyledInput };

export default SlateFloatingLinkMenu;
