import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('CustomerActivityContent').attr('type', 'CUSTOMER_ACTIVITY');

Factory.define('CustomerActivityContentWithDefaults')
  .extend('CustomerActivityContent')
  .attr('activityType', 'EMAIL')
  .attr('link', { text: 'default-link', url: 'default-linkText' })
  .attr('createdAt', 'default-createdAt')
  .attr('title', 'default-title')
  .attr('body', 'default-body')
  .attr('sourceName', 'GladlyInc')
  .attr('occurredAt', '2018-01-02T15:04:05Z');

Factory.define('CustomerActivityContentItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('CustomerActivityContent', content);
  });

Factory.define('CustomerActivityContentItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('CustomerActivityContentWithDefaults', content);
  });
