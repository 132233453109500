const DEFAULT_ITEM_HEIGHT = 80;

/**
 * Layout takes a list of ids and manages the heights / tops of the corresponding elements in the browser in order to
 * enable efficient virtualization (aka only rendering the DOM nodes we _need_) of said list.
 *
 * When we learn the height of components when they mount (or when they change due to images loading / user interaction),
 * we register the height here with `setHeight()`.
 *
 * When we want to then render a portion of the list of items, we can call `getBounds(top, bottom)`, which takes a top
 * and a bottom view position on the screen. Because we know the heights of all the items, we can return the list of
 * items that are contained within those bounds. As the user scrolls the application, `top` and `bottom` will change,
 * and so do the items that we return.
 *
 * Layout's secondary responsbility is to account for the proper scroll position when changes occur. This includes
 * when items get added to / removed from the list (via `setIds()`), when the user resizes the screen
 * (via `setContainerHeight()`), when individual heights change (via `setHeight()`), and when the anchor changes
 * (via `setAnchor()`).
 *
 * When the `anchor` is set to an `id`, layout will ensure that the scroll position sticks to the bottom of the item
 * in the list corresponding to that `id`. This is especially useful when we are first rendering the list and want
 * it to, say, stick to the bottom of the last item in the list.
 */
export default class Layout {
  constructor(ids = []) {
    this.ids = ids;
    this.heightCache = {};
    this.heightValidity = {};
    this.tops = {};
    this.totalHeight = 0;
    this.containerHeight = 0;
  }

  // Getters

  getAnchor() {
    return this.anchor;
  }

  getBottom(id) {
    return this.tops[id] + this.getHeight(id);
  }

  getContainerHeight() {
    return this.containerHeight;
  }

  getHeight(id) {
    return id in this.heightCache ? this.heightCache[id] : DEFAULT_ITEM_HEIGHT;
  }

  getHeightValidity(id) {
    return !!this.heightValidity[id];
  }

  getTotalHeight() {
    return this.totalHeight;
  }

  getTop(id) {
    return this.tops[id];
  }

  getTops() {
    return this.tops;
  }

  getAnchorOffset(scrollTop) {
    if (this.anchorPosition === 'top') {
      return this.getTop(this.anchor);
    } else if (this.anchorPosition === 'center') {
      const itemHeight = this.getHeight(this.anchor);
      // If the item is taller than our container, scroll to the top of it to be less awkward
      if (itemHeight > this.containerHeight) {
        // If it's too tall and we're trying to scroll to the top, account for the fact that we have a conversation
        // header which has height 48px.
        return this.getTop(this.anchor) - 48;
      }
      return Math.floor(this.getTop(this.anchor) + this.getHeight(this.anchor) / 2 - this.containerHeight / 2);
    } else {
      return this.getTop(this.anchor) + this.getHeight(this.anchor) - this.containerHeight;
    }
  }

  // Returns the start and end indices of item ids we want to render on the screen
  // based on the lower bound and upper bound top pixels
  getBounds(lowerBoundTop, upperBoundTop) {
    let startIndex = 0;
    while (startIndex + 1 < this.ids.length) {
      const id = this.ids[startIndex + 1];
      const top = this.getTop(id);
      if (top > lowerBoundTop) break;
      startIndex += 1;
    }
    let endIndex = startIndex;
    while (endIndex < this.ids.length) {
      const id = this.ids[endIndex];
      const top = this.getTop(id);
      if (top > upperBoundTop) break;
      endIndex += 1;
    }
    return {
      start: startIndex,
      end: endIndex,
    };
  }

  getNewScrollTop(offset) {
    return Math.max(0, Math.min(this.totalHeight - this.containerHeight, Math.ceil(offset)));
  }

  getOffsetForId(id) {
    if (!id) {
      return this.totalHeight;
    }
    const top = this.getTop(id);
    return top ? top - this.anchorOffset : 0;
  }

  // Actions / Setters

  calculateTops() {
    const tops = {};
    let totalHeight = 0;
    for (let i = 0; i < this.ids.length; i++) {
      const id = this.ids[i];
      tops[id] = totalHeight;
      totalHeight += this.getHeight(id);
    }
    this.tops = tops;
    this.totalHeight = totalHeight;
  }

  setAnchor(id, position, scrollTop) {
    this.anchor = id;
    this.anchorPosition = position;
    return this.getAnchorScrollTop(scrollTop);
  }
  clearAnchor() {
    this.anchor = null;
  }
  getAnchorScrollTop(scrollTop) {
    return this.anchor && this.getTop(this.anchor) != null ? this.getNewScrollTop(this.getAnchorOffset()) : scrollTop;
  }

  setContainerHeight(height, scrollTop) {
    const diff = this.containerHeight - height;
    this.containerHeight = height;

    if (this.anchor) {
      return this.getAnchorScrollTop(scrollTop);
    } else if (this.shouldStickToBottom(scrollTop)) {
      return this.getNewScrollTop(Infinity);
    }

    // When we resize the window, we prefer that the items closest to the bottom of the screen stay visible
    // as that is what the agent is probably focusing on.
    return scrollTop + diff;
  }

  setHeight(id, height, scrollTop, isManual) {
    // If an agent manually expands an item, the expectation is that the item would expand down, not up.
    if (!isManual) {
      if (this.anchor) {
        this._setHeight(id, height);
        return this.getAnchorScrollTop(scrollTop);
      } else if (this.shouldStickToBottom(scrollTop)) {
        this._setHeight(id, height);
        return this.getNewScrollTop(Infinity);
      }

      const bottom = this.getBottom(id);
      if (bottom <= scrollTop + this.containerHeight) {
        const diff = height - this.getHeight(id);
        scrollTop += diff;
      }
    }

    // If we expand the last item when we're near the bottom of the feed, ensure expanding it keeps us
    // pinned to the bottom.
    let newScrollTop =
      this.ids[this.ids.length - 2] === id && this.shouldStickToBottom(scrollTop) ? Infinity : scrollTop;
    this._setHeight(id, height);
    return this.getNewScrollTop(newScrollTop);
  }

  invalidateHeight(id) {
    if (!id) {
      this.heightValidity = {};
    } else {
      delete this.heightValidity[id];
    }
  }

  _setHeight(id, height) {
    this.heightCache[id] = Math.round(height);
    this.heightValidity[id] = true;
    this.calculateTops();
  }

  setIds(ids, scrollTop) {
    if (this.anchor) {
      this._setIds(ids);
      return this.getAnchorScrollTop(scrollTop);
    } else if (this.shouldStickToBottom(scrollTop)) {
      this._setIds(ids);
      return this.getNewScrollTop(Infinity);
    }

    const anchorKey = this.findNearestIdBelowScrolltop(ids, scrollTop);
    if (!anchorKey) {
      this._setIds(ids);
      return this.getNewScrollTop(scrollTop);
    }

    const oldScrollTop = this.getTop(anchorKey) - scrollTop;
    this._setIds(ids);
    const newScrollTop = this.getTop(anchorKey) - scrollTop;
    const diff = oldScrollTop - newScrollTop;
    return this.getNewScrollTop(scrollTop - diff);
  }

  _setIds(ids) {
    this.ids = ids;
    this.calculateTops();
  }

  shouldStickToBottom(scrollTop) {
    return scrollTop >= this.totalHeight - this.containerHeight - DEFAULT_ITEM_HEIGHT / 2;
  }

  // Find the nearest id with a bottom below scrollTop in our current ids that is also in the
  // new set of ids.
  findNearestIdBelowScrolltop(newIds, scrollTop) {
    for (let i = 0; i < this.ids.length; i++) {
      const id = this.ids[i];
      if (this.getBottom(id) > scrollTop)
        for (let j = 0; j < newIds.length; j++) {
          if (newIds[j] === id) {
            return id;
          }
        }
    }
    return null;
  }
}
