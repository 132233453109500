import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('WidgetActivity').attr('type', 'WIDGET_ACTIVITY');

Factory.define('WidgetActivityWithDefaults')
  .extend('WidgetActivity')
  .attr('activityType', 'COBROWSE')
  .attr('body', 'view recording here')
  .attr('meta', { pin: '12345' })
  .attr('status', 'session completed')
  .attr('sourceName', 'ScreenMeet');

Factory.define('WidgetActivityItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('WidgetActivity', content));

Factory.define('WidgetActivityItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('WidgetActivityWithDefaults', content));
