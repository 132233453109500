import { AttributeType } from 'models/configuration/attribute_def';

export default {
  dataConfiguration: {
    defaultRowLimit: 10,
    namespace: 'loop_returns',
  },

  displayConfiguration: {
    title: '{{ data.length }} Return{{ (data.length === 1 ? "" : "s") }}',
    defaultExpandThreshold: 3,

    itemSummary: {
      attributes: [
        {
          attr: 'orderName',
          type: AttributeType.STRING,
          label: 'Order Number',
        },
        {
          attr: 'createdAt',
          type: AttributeType.DATE,
          label: 'Created on',
        },
      ],
    },
    itemDetails: {
      header: {
        title: 'Return ID {{ data.id }}',
        attributes: [
          {
            attr: 'customerEmail',
            type: AttributeType.STRING,
            label: 'Customer email',
          },
          {
            attr: 'updatedAt',
            type: AttributeType.DATE,
            label: 'Last updated',
          },
          {
            attr: 'stateCapitalized',
            type: AttributeType.STRING,
            label: 'Status',
          },
          {
            attr: 'outcome',
            type: AttributeType.STRING,
            label: 'Outcome',
          },
        ],
      },
      sections: [
        {
          blocks: [
            {
              attributes: [
                {
                  attr: 'url',
                  type: AttributeType.STRING,
                  label: '',
                  linkText: 'Open in Loop',
                },
              ],
            },
          ],
        },
        {
          title: 'Return credits',
          listSource: 'return.items',
          listItem: {
            title: '{{ data.title }}',
            attributes: [
              {
                attr: 'returnReasonCombined',
                type: AttributeType.STRING,
                label: 'Return reason',
              },
              {
                attr: 'priceWithCurrency',
                type: AttributeType.STRING,
                label: 'Price',
              },
            ],
          },
        },
        {
          title: 'Purchased',
          listSource: 'exchange.items',
          listItem: {
            title: '{{ data.title }}',
            attributes: [
              {
                attr: 'sku',
                type: AttributeType.STRING,
                label: 'SKU',
              },
              {
                attr: 'priceWithCurrency',
                type: AttributeType.STRING,
                label: 'Price',
              },
              {
                attr: 'isOutOfStock',
                type: AttributeType.STRING,
                label: 'Out of stock',
              },
            ],
          },
        },
        {
          title: 'Return summary',
          blocks: [
            {
              title: 'Return credits',
              attributes: [
                {
                  attr: 'return.productTotalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Item total',
                },
                {
                  attr: 'return.discountTotalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Discounts',
                },

                {
                  attr: 'return.taxTotalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Tax',
                },
                {
                  attr: 'return.totalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Total',
                },
              ],
            },
            {
              title: 'Purchased',
              attributes: [
                {
                  attr: 'exchange.productTotalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Item total',
                },
                {
                  attr: 'exchange.discountTotalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Discounts',
                },

                {
                  attr: 'exchange.taxTotalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Tax',
                },
                {
                  attr: 'exchange.totalWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Total',
                },
              ],
            },
            {
              title: 'Other',
              attributes: [
                {
                  attr: 'costBreakdown.handlingFeeWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Handling fee',
                },
              ],
            },
            {
              attributes: [
                {
                  attr: 'costBreakdown.refundWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Refund total',
                },
                {
                  attr: 'costBreakdown.upsellWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Upsell total',
                },
                {
                  attr: 'costBreakdown.giftCardAmountWithCurrency',
                  type: AttributeType.STRING,
                  label: 'Gift card total',
                },
              ],
            },
          ],
        },
        {
          title: 'Shipping',
          blocks: [
            {
              attributes: [
                {
                  attr: 'shipping.labelStatus',
                  type: AttributeType.STRING,
                  label: 'Shipment status',
                },
                {
                  attr: 'shipping.labelUpdatedAt',
                  type: AttributeType.DATE,
                  label: 'Last updated at',
                },
                {
                  attr: 'shipping.carrier',
                  type: AttributeType.STRING,
                  label: 'Carrier',
                },
                {
                  attr: 'shipping.trackingNumber',
                  type: AttributeType.STRING,
                  label: 'Tracking number',
                },
                {
                  attr: 'shipping.returnMethod',
                  type: AttributeType.STRING,
                  label: 'Return method',
                },
              ],
            },
            {
              attributes: [
                {
                  attr: 'shipping.statusPageUrl',
                  type: AttributeType.STRING,
                  label: '',
                  linkText: 'View the return status page',
                },
              ],
            },
          ],
        },
        {
          title: 'Notes',
          listSource: 'notes',
          listMaxLength: 5,
          listItem: {
            title: '{{ moment(data.createdAt).format("MMM D, YYYY h:mm A") }}',
            attributes: [
              {
                attr: 'content',
                type: AttributeType.MULTILINE_TEXT,
                label: '',
              },
            ],
          },
        },
      ],
    },
  },
};
