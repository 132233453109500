import React, { useEffect, useRef } from 'react';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import GeneralTransaction from './general_transaction';
import { SubscriptionAttributesPropTypes } from 'components/customer/summary/transactions/constants';
import { SubscriptionTransactionDef } from 'models/configuration/transaction_def';
import ProfileCardDef from 'models/configuration/profile_card_def';
import { setOverflowTitle } from 'components/lib/overflow_title';

export default function SubscriptionTransactions({
  isLoading,
  transactionDef,
  transactions,
  onExpand,
  profileCardDef,
}) {
  // First order by Active > other statuses, then by newest
  const subscriptions = _.orderBy(
    transactions,
    [
      subscription => subscription.attributes.status,
      subscription => moment(subscription.attributes.updatedAt).valueOf(),
    ],
    ['asc', 'desc']
  );
  const numSubscriptions = subscriptions.length;

  return (
    <ExpandableProfileCard
      data-aid="expandable-subscription-transaction"
      isEmpty={!numSubscriptions}
      isLoading={isLoading}
      limit={3}
      title={_.get(profileCardDef, 'label') || `${numSubscriptions} Subscription${numSubscriptions === 1 ? '' : 's'}`}
    >
      {_.map(subscriptions, (subscription, idx) => (
        <GeneralTransaction
          attributes={subscription.attributes}
          isExpanded={idx === 0}
          key={`general-transaction-${idx}`}
          onExpand={onExpand}
          shouldRenderImages={false}
          summaryRenderer={summaryRenderer}
          transactionDef={transactionDef}
        />
      ))}
    </ExpandableProfileCard>
  );

  function summaryRenderer(attributes) {
    return <SubscriptionSummary attributes={attributes} transactionDef={transactionDef} />;
  }
}

SubscriptionTransactions.propTypes = {
  isLoading: PropTypes.bool,
  onExpand: PropTypes.func,
  profileCardDef: PropTypes.instanceOf(ProfileCardDef),
  transactionDef: PropTypes.instanceOf(SubscriptionTransactionDef),
  transactions: PropTypes.arrayOf(PropTypes.shape({ attributes: SubscriptionAttributesPropTypes })),
};

export function SubscriptionSummary({ attributes }) {
  const { subscriptionId, status } = attributes || {};
  const subscriptionTitleRef = useRef();

  useEffect(() => {
    if (subscriptionId) {
      setOverflowTitle(subscriptionTitleRef.current?.container, _.trim(subscriptionId));
    }
  });

  return (
    <StyledSummary data-aid="subscriptionTransaction-summary">
      <StyledID>
        <Dotdotdot clamp={2} ref={subscriptionTitleRef}>
          <SubscriptionHeaderWrapper data-aid="subscriptionTransaction-summary-id">
            Subscription <SubscriptionIdWrapper>{subscriptionId}</SubscriptionIdWrapper>
          </SubscriptionHeaderWrapper>
        </Dotdotdot>
      </StyledID>
      <StyledStatus data-aid="subscriptionTransaction-summary-status">{status}</StyledStatus>
    </StyledSummary>
  );
}

SubscriptionSummary.propTypes = {
  attributes: SubscriptionAttributesPropTypes,
  transactionDef: PropTypes.instanceOf(SubscriptionTransactionDef),
};

const StyledSummary = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const StyledID = styled.div`
  display: flex;
  font-size: ${p => p.theme.fontSize.base};
  text-align: left;
  margin-right: 8px;
  flex-basis: 70%;
`;

const StyledStatus = styled.div`
  font-size: ${p => p.theme.fontSize.base};
  margin-right: ${p => p.theme.spacing.small};
  text-align: left;
  text-transform: capitalize;
  flex-basis: 35%;
`;

const SubscriptionHeaderWrapper = styled.div`
  word-break: break-all;
`;

const SubscriptionIdWrapper = styled.span`
  font-weight: ${p => p.theme.fontWeight.medium};
`;
