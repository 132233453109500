import createReactClass from 'create-react-class';
import d3 from 'd3';
import React from 'react';

import T from 'prop-types';

const ChartAxis = createReactClass({
  propTypes: {
    isYAxis: T.bool,
    numTicks: T.oneOfType([T.string, T.number]),
    scale: T.any.isRequired, // D3 scale
    size: T.shape({
      height: T.number,
      width: T.number,
      xOffset: T.number,
      yOffset: T.number,
    }).isRequired,
    showGridLine: T.bool,
    showMainLine: T.bool,
    tickFormat: T.func, // Formatting function (D3 or custom)
    tickLabel: T.string,
  },

  statics: {
    DEFAULT_NUMTICKS: 5,
    LABEL_PADDING: 20,
    CENTER_OFFSET: 5,
  },

  /* Init */
  getDefaultProps() {
    return {
      isYAxis: false,
      isRightSide: false,
      showGridLine: false,
      showMainLine: false,
      tickFormat: d3.format('d'), // number
    };
  },

  /* Render */
  render() {
    let mainTransform = this.props.isYAxis
      ? `translate(${this.props.size.xOffset},0)`
      : `translate(0,${this.props.size.height})`;

    return (
      <g className={this.props.className} transform={mainTransform}>
        {this.props.showMainLine && this.renderMainLine()}
        {this.getAxisTicks().map(this.renderTick)}
      </g>
    );
  },

  renderMainLine() {
    let props = this.props.isYAxis
      ? { y1: this.props.size.height, y2: this.props.size.yOffset }
      : { x1: this.props.size.xOffset, x2: this.props.size.width + this.props.size.xOffset };
    return <line {...props} className={`${this.props.className}-line-main`} />;
  },

  renderTick(tickValue, index) {
    let pos = this.props.scale(tickValue);
    let side = this.props.isRightSide ? 1 : -1;
    let labelTransform = this.props.isYAxis
      ? `translate(${side * ChartAxis.LABEL_PADDING}, ${pos + ChartAxis.CENTER_OFFSET})`
      : `translate(${pos}, ${ChartAxis.LABEL_PADDING})`;
    let textAnchor = this.props.isYAxis ? (this.props.isRightSide ? 'start' : 'end') : 'middle';

    return (
      <g key={`chartTick-${pos}`}>
        {this.props.showGridLine && this.renderGridLine(pos)}
        <text className={`${this.props.className}-text`} textAnchor={textAnchor} transform={labelTransform}>
          {`${this.props.tickLabel && index === 0 ? this.props.tickLabel : ''}${this.props.tickFormat(tickValue)}`}
        </text>
      </g>
    );
  },

  renderGridLine(pos) {
    let props = this.props.isYAxis
      ? { y1: pos, y2: pos, x2: this.props.size.width }
      : { x1: pos, x2: pos, y2: -1 * this.props.size.height };
    return <line {...props} className={`${this.props.className}-line-grid`} />;
  },

  getAxisTicks() {
    let numTicks = this.props.numTicks || ChartAxis.DEFAULT_NUMTICKS;
    return this.props.getAxisTicks ? this.props.getAxisTicks() : this.props.scale.ticks(numTicks);
  },
});

export default ChartAxis;
