import NavigateBack from 'actions/current_location/navigate_back';
import NavigateTo from 'actions/current_location/navigate_to';
import UsersAdmin from 'models/location/users_admin';

export default class NavigateBackToUsersAdmin {
  constructor(context) {
    this.context = context;
  }

  run() {
    let previousLocation = this.context.stores.locationHistory.get().getPreviousEntry();
    if (previousLocation instanceof UsersAdmin) {
      this.context.executeAction(NavigateBack);
    } else {
      this.context.executeAction(NavigateTo, UsersAdmin.create());
    }
  }
}
