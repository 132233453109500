import _ from 'lodash';
import moment from 'moment-timezone';
import DtoConverter from 'scripts/application/dto_converters/inbox_preview_converter';
import Inbox, { DEFAULT_FETCH_SIZE, InboxSortOrder } from 'models/location/inbox';
import qconsole from 'scripts/lib/qconsole';

const ASC_SORT = 'asc';
const DESC_SORT = 'desc';

export const getTime = preview => {
  return preview.task ? preview.task.content.updatedAt : preview.conversation.updatedAt;
};

export default class InboxGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleFetchInboxSuccess(dtos) {
    let queryCutoff = dtos.cutoff;
    let queryLimit = dtos.limit || DEFAULT_FETCH_SIZE;
    let conversationPreviews = dtos.previews.map(DtoConverter.fromDto);

    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof Inbox)) {
      return;
    }

    const sort = currentLocation.sortOrder === InboxSortOrder.OLDEST ? ASC_SORT : DESC_SORT;

    let inbox = this.context.stores.inbox.get() || [];
    let merged = this.mergePreviews(inbox, conversationPreviews, sort, queryCutoff, queryLimit);

    this.context.stores.inbox.set(merged);
    this.context.stores.inbox.resetLoading();

    this.setCutoffs(conversationPreviews);
  }

  handleFetchInboxError(errorDto) {
    qconsole.log(`Failed to fetch conversation list ${JSON.stringify(errorDto)}`);
    this.context.stores.inbox.resetLoading();
  }

  setCutoffs(conversationPreviews) {
    if (conversationPreviews.length > 0) {
      let currentLocation = this.context.stores.currentLocation.get();
      if (!(currentLocation instanceof Inbox)) {
        return;
      }

      let first = moment(getTime(conversationPreviews[0]));
      let last = moment(getTime(conversationPreviews[conversationPreviews.length - 1]));
      let { cutoffMax, cutoffMin } = first.isAfter(last)
        ? { cutoffMax: first, cutoffMin: last }
        : { cutoffMax: last, cutoffMin: first };

      let currentCutoffs = {
        max: currentLocation.cutoffMax && moment(currentLocation.cutoffMax),
        min: currentLocation.cutoffMin && moment(currentLocation.cutoffMin),
      };

      if (currentCutoffs.max && cutoffMax.isBefore(currentCutoffs.max)) {
        cutoffMax = currentCutoffs.max;
      }
      if (currentCutoffs.min && cutoffMin.isAfter(currentCutoffs.min)) {
        cutoffMin = currentCutoffs.min;
      }
      currentLocation.setTransientState({ cutoffMax: cutoffMax.toISOString(), cutoffMin: cutoffMin.toISOString() });

      this.context.stores.currentLocation.set(currentLocation);
    }
  }

  mergePreviews(existing, incoming, sort, queryCutoff, queryLimit) {
    let getId = preview => {
      return preview.task ? `task-${preview.task.id}` : `conversation-${preview.conversation.id}`;
    };

    if (existing.length === 0) {
      return incoming;
    }
    if (incoming.length === 0) {
      if (!queryCutoff) {
        // no cutoff and no returned items means no items for this query anymore
        return [];
      }
      let culled;
      if (sort === ASC_SORT) {
        culled = existing.filter(x => !(getTime(x) >= queryCutoff));
      } else {
        culled = existing.filter(x => !(getTime(x) <= queryCutoff));
      }
      return culled;
    }

    let t0 = queryCutoff || getTime(existing[0]);
    let t1 = incoming.length < queryLimit ? getTime(_.last(existing)) : getTime(_.last(incoming));
    let [min, max] = t0 < t1 ? [t0, t1] : [t1, t0];

    // Remove items whose `updatedAt` time intersects with the incoming
    // since it means it's either in `incoming` or it's been removed from this inbox view.
    // If no cutoff was sent in the query, remove items whose `updatedAt`
    // is before (by inbox sort) the returned results, for the same reason.
    let culled = existing.filter(x => !(getTime(x) >= min && getTime(x) <= max));

    // merge existing and incoming, deduplicating by conversation's id
    // and sorting by conversation's updatedAt
    return _.chain(culled)
      .keyBy(getId)
      .assign(_.keyBy(incoming, getId))
      .values()
      .orderBy(getTime, [sort])
      .value();
  }
}
