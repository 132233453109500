import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';

export const RoutingChannel = createEnum('MAIL', 'MESSAGING', 'TASK', 'VOICE');

export const RoutingChannelsAllUnselected = Object.freeze(
  _.reduce(RoutingChannel, (m, v) => _.assign(m, { [v]: false }), {})
);

const ChannelStates = createModel({
  modelName: 'ChannelStates',
  properties: {
    [RoutingChannel.MAIL]: prop(Boolean).default(false),
    [RoutingChannel.MESSAGING]: prop(Boolean).default(false),
    [RoutingChannel.TASK]: prop(Boolean).default(false),
    [RoutingChannel.VOICE]: prop(Boolean).default(false),
  },

  statics: {
    create(channels) {
      return new this(_.assign({}, RoutingChannelsAllUnselected, sanitizeChannels(channels)));
    },
  },
});

const AgentRoutingPreferences = createModel({
  modelName: 'AgentRoutingPreferences',
  properties: {
    channels: prop(ChannelStates).isRequired,
    isFocusOn: prop(Boolean).default(false),
    nextCallRoutingTime: prop(String).default(''),
    _version: prop(Number).isRequired,
  },

  isPreferredOnChannel(channel) {
    return this.channels[channel];
  },

  incrementVersion() {
    this._version = this._version + 1;
  },

  updatePreferences(preferences) {
    this.channels = ChannelStates.create(_.assign({}, this.channels, preferences.channels));
    if (!this.channels[RoutingChannel.VOICE]) {
      this.nextCallRoutingTime = '';
    }

    if (preferences.isFocusOn !== undefined) {
      this.isFocusOn = preferences.isFocusOn;
      this.nextCallRoutingTime = '';
    }
  },

  setNextCallRoutingTime(nextCallRoutingTime) {
    this.nextCallRoutingTime = nextCallRoutingTime;
  },

  statics: {
    create({ channels, isFocusOn, _version } = {}) {
      return new this({
        channels: ChannelStates.create(channels),
        isFocusOn: isFocusOn || false,
        _version: _version || 0,
      });
    },

    overrideFromJs(fromJs) {
      return attrs => {
        return fromJs(
          _.assign({}, attrs, {
            channels: sanitizeChannels(attrs.channels),
            isFocusOn: attrs.isFocusOn || attrs.status === PreferencesStatus.FOCUS,
          })
        );
      };
    },
  },
});

function sanitizeChannels(channels) {
  return _.pick(channels, _.values(RoutingChannel));
}

export default AgentRoutingPreferences;

/**
 * Represents grouping of routing preferences in UI by agent preference buttons
 */
export const RoutingPreferenceGroup = createEnum('MESSAGING', 'NON_INTERACTIVE', 'TASK', 'VOICE');

export const PreferencesStatus = createEnum('ACTIVE', 'AWAY', 'FOCUS', 'OFFLINE');
