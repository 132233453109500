import classnames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import DateRangeWrapper from 'components/lib/date_range_wrapper';
import { getDatetimeFromTimestamp, getTimestampFromDatetime } from 'components/lib/date/datetime_helpers';

export default class DateRangePicker extends Component {
  constructor(props) {
    super(props);

    let startDate = moment(props.startDate);
    let endDate = moment(props.endDate);

    if (!startDate.isValid() || !endDate.isValid()) {
      let now = moment();
      startDate = moment(now).startOf('day');
      endDate = moment(now).endOf('day');
    }

    this.state = {
      startDate,
      endDate,
    };

    this.handleApply = this.handleApply.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleApply() {
    this.props.onApply({
      startDate: this.state.startDate.format(),
      endDate: this.state.endDate.format(),
    });
  }

  handleChange({ startDate, endDate, startTime, endTime }) {
    // startDate and endDate are Momentjs objects
    let newStartDate = startDate && startDate.isValid() ? startDate : this.state.startDate;
    let newEndDate = endDate && endDate.isValid() ? endDate : this.state.endDate;
    let newStartTime = startTime || getDatetimeFromTimestamp(this.state.startDate).time;
    let newEndTime = endTime || getDatetimeFromTimestamp(this.state.endDate).time;

    if (startDate) {
      newEndDate = newEndDate.isBefore(newStartDate) ? newStartDate : newEndDate;
    }

    if (endDate) {
      newStartDate = newStartDate.isAfter(newEndDate) ? newEndDate : newStartDate;
    }

    this.setState({
      startDate: moment(getTimestampFromDatetime({ date: newStartDate, time: newStartTime })),
      endDate: moment(getTimestampFromDatetime({ date: newEndDate, time: newEndTime })),
    });
  }

  render() {
    let { startDate, endDate } = this.state;
    let Panel = this.props.panel;

    let classes = classnames('dateRange-picker-container', this.props.className);
    return (
      <div className={classes}>
        <div className="dateRange-picker">
          <div className="dateRange-picker-calendars">
            <DateRangeWrapper endDate={endDate} onChange={this.handleChange} startDate={startDate} />
          </div>
          <div className="dateRange-picker-shortcuts">
            {Panel && (
              <Panel
                endDate={endDate.format()}
                onChange={this.handleChange}
                quickPicks={this.props.quickPicks}
                startDate={startDate.format()}
              />
            )}
            <div className="dateRange-picker-row">
              <Button
                buttonType={ButtonTypes.PRIMARY}
                className="dateRange-picker-applyButton"
                onClick={this.handleApply}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DateRangePicker.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onApply: PropTypes.func.isRequired,
  quickPicks: PropTypes.objectOf(PropTypes.func),
};
