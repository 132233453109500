import CustomerView from 'models/location/customer_view';

export default class NavigateToItem {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, itemId }) {
    if (!customerId) {
      customerId = this.context.stores.currentLocation.get().customerId;
      if (!customerId) {
        return;
      }
    }

    this.context.router.navigateTo(
      CustomerView.create({
        customerId,
        currentConversationItemId: itemId,
      })
    );
  }
}
