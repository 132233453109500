import querystring from 'querystring';
import { datasetCache } from './fake_backend/database';
import XhrAdapter from './xhr_adapter';

export default class DatasetGateway {
  constructor(httpClient) {
    this.httpClient = httpClient;
    this.datasetCache = datasetCache;
  }

  findByOrgId(orgId, cb) {
    return this.findBy({ orgId }, cb);
  }

  findByCompanyDomain(companyDomain, cb) {
    return this.findBy({ companyDomain }, cb);
  }

  findBy(predicate, cb) {
    let dataset = this.datasetCache.findBy(predicate);
    if (dataset) {
      setImmediate(() => cb(null, dataset));
      return;
    }
    this.httpClient.get(`/api/v1/datasets?${querystring.stringify(predicate)}`, (err, request) => {
      if (err) {
        return cb({ status: 0, message: err.message });
      }

      if (request.status === 200) {
        dataset = request.response;
        this.datasetCache.set(dataset.orgId, dataset);
        return cb(null, dataset);
      }

      return cb({ status: request.status, message: request.response || request.statusText });
    });
  }

  static create(httpClient = new XhrAdapter()) {
    return new this(httpClient);
  }

  static get instance() {
    return this._instance;
  }
}

DatasetGateway._instance = DatasetGateway.create();
