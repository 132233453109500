import { Factory } from 'rosie';

Factory.define('KeywordGroup').attr('name', 'KEYWORD_GROUP');

Factory.define('KeywordGroupWithDefaults')
  .extend('KeywordGroup')
  .attr('id', 'default-id')
  .attr('label', 'Bags')
  .attr('languages', ['en-us'])
  .attr('phrases', ['bag', 'baggage', 'luggage'])
  .attr('updatedAt', () => new Date().toISOString())
  .attr('updatedBy', 'agent-id');
