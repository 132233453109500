import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import ConversationItemCard from 'components/lib/customer_conversation_card/conversation_item_card';
import CustomerCard from 'components/lib/customer_conversation_card/customer_card';
import Link from 'components/common/link';

export default function CustomerConversationItemCard(props) {
  const classNames = classnames('customerConversationItemCard', {
    'customerConversationItemCard-selected': props.isSelected,
  });
  const { profile, onClick, conversationItem } = props;
  const content = (
    <div className={classNames} onClick={onClick}>
      <CustomerCard {...profile} />
      <ConversationItemCard {...conversationItem} />
    </div>
  );

  if (props.isLink) {
    let linkUrl = `/customer/${profile.id}/conversation/${props.conversationId}`;

    return (
      <div>
        <Link className="unstyled" href={linkUrl} onClick={noop => {}}>
          {content}
        </Link>
      </div>
    );
  }

  return content;
}

CustomerConversationItemCard.propTypes = Object.assign({
  profile: PropTypes.shape(CustomerCard.propTypes),
  conversationItem: PropTypes.object,
  onClick: PropTypes.func,
});
