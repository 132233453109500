import MqttGateway from './lib/mqtt_gateway';
import analytics from 'scripts/lib/analytics';

export default class RoutingEventGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'agents/+/routing-event';
  }

  subscribe(agentId) {
    super._subscribe([agentId]);
  }

  onReceive(eventDto, topic, parsedTopicParams, correlationId) {
    analytics.track('Work Routed', {
      customerId: eventDto.profile && eventDto.profile.id,
      conversationId: eventDto.conversationId,
      conversationItemId: eventDto.conversationItem && eventDto.conversationItem.id,
      routingEventType: eventDto.type,
    });
    this.notifyObservers('handleRoutingEvent', eventDto, correlationId);
  }

  broadcast({ agentId, conversationItemId, conversationId, customerId, type }) {
    super._broadcast([agentId], { agentId, conversationItemId, conversationId, customerId, type });
  }

  broadcastTransfer({ agentId, conversationId, conversationItem, profile, initiator, transferee, type }) {
    super._broadcast([agentId], { agentId, conversationId, conversationItem, profile, initiator, transferee, type });
  }

  publish(agentId, event) {
    super._broadcast([agentId], event);
  }
}
