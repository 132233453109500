import createModel from '../lib/create_model';

const AssignedTaskCommented = createModel({
  modelName: 'AssignedTaskCommented',

  properties: {
    commentId: String,
    customerId: String,
    taskId: String,
  },
});

export default AssignedTaskCommented;
