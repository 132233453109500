import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import CloseTopicsMenu from 'actions/conversation/topics_menu/close_topics_menu';
import ConversationTopicsV2 from 'components/customer/conversation_history/topics_v2/conversation_topics_v2';
import OpenTopicsMenu from 'actions/conversation/topics_menu/open_topics_menu';
import SearchTopic from 'actions/conversation/topics_menu/search_topic';
import SelectTopic from 'actions/conversation/topics_menu/select_topic';
import { TopicsMenuOptionType } from 'components/customer/conversation_history/topics_v2/conversation_topics_constants_v2';
import UpdateConversationCustomAttributes from 'actions/conversation/update_conversation_custom_attributes';
import UpdateConversationTopicIds from 'actions/conversation/update_conversation_topic_ids';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function ConversationTopicsActionConnectorV2({
  className,
  conversation,
  customAttributesImmutable,
  customAttributesProvider,
  customerId,
  lastOpenedAt,
  getTopic,
  topicsImmutable,
  topicsProvider,
  topicSuggestions,
}) {
  // Memoize topics, only regenerate the topic options when the topics immutable changes
  const topics = useMemo(() => {
    return _.map(topicsProvider.findAll(), topic => ({
      id: topic.id,
      disabled: topic.disabled,
      name: topic.getNameWithAncestry(),
      type: TopicsMenuOptionType.TOPIC,
    }));
  }, [topicsImmutable, topicsProvider]);

  // Memoize custom attributes, only regenerate the topic options when the customAttributes immutable changes
  const customAttributes = useMemo(() => {
    return _.map(customAttributesProvider.findAll({ select: ['id', 'deactivated', 'label'] }), attr => ({
      id: attr.id,
      disabled: attr.deactivated,
      name: attr.label,
      type: TopicsMenuOptionType.CUSTOM_ATTRIBUTE,
    }));
  }, [customAttributesImmutable, customAttributesProvider]);

  const executeAction = useExecuteAction();

  const getTopicSuggestions = useCallback(
    () => (topicSuggestions && topicSuggestions.addTopics ? topicSuggestions.addTopics : []),
    [topicSuggestions]
  );

  const getModelVersion = useCallback(() => (topicSuggestions ? topicSuggestions.modelVersion : null), [
    topicSuggestions,
  ]);

  const handleMenuOpen = useCallback(() => {
    let numSuggestionsShown = _.difference(getTopicSuggestions(), conversation.topicIds).length;
    executeAction(OpenTopicsMenu, {
      conversationId: conversation.id,
      numSuggestionsShown,
      numSuggestions: getTopicSuggestions().length,
      modelVersion: getModelVersion(),
    });
  }, [conversation, executeAction, getTopicSuggestions, getModelVersion]);

  const handleMenuClose = useCallback(() => {
    executeAction(CloseTopicsMenu, { conversationId: conversation.id, modelVersion: getModelVersion() });
  }, [conversation, executeAction, getModelVersion]);

  const handleTopicsChange = useCallback(
    updatedTopics => {
      let updatedTopicIds = _.filter(updatedTopics.optionIds, id => getTopic(id));
      let prevTopicIds = conversation.topicIds;
      let add = _.difference(updatedTopicIds, prevTopicIds);
      let remove = _.difference(prevTopicIds, updatedTopicIds);
      if (add.length || remove.length) {
        let topicChanges = { add, remove };
        executeAction(UpdateConversationTopicIds, {
          id: conversation.id,
          customerId,
          topicChanges,
          modelVersion: getModelVersion(),
        });
      }

      const updatedAttributes = updatedTopics.attributeValues;
      const prevAttributes = conversation.customAttributes;

      const removedCustomAttributes = prevAttributes.filter(
        ({ id, value }) => !_.some(updatedAttributes, { id, value })
      );
      const addedCustomAttributes = updatedAttributes.filter(({ id, value }) => !_.some(prevAttributes, { id, value }));

      if (addedCustomAttributes.length || removedCustomAttributes.length) {
        executeAction(UpdateConversationCustomAttributes, {
          conversationId: conversation.id,
          customerId,
          customAttributeUpdates: { add: addedCustomAttributes, remove: removedCustomAttributes },
        });
      }
    },
    [conversation, customerId, executeAction, getModelVersion] // ignore get topic - the function changes each render but it's fine
  );

  const handleTopicSearch = useCallback(() => {
    executeAction(SearchTopic, { conversationId: conversation.id, modelVersion: getModelVersion() });
  }, [conversation, executeAction, getModelVersion]);

  const handleTopicSelect = useCallback(
    ({ topicId, topicName, action, selectionType, type }) => {
      const conversationId = conversation.id;
      let wasSuggested = _.includes(getTopicSuggestions(), topicId);
      executeAction(SelectTopic, {
        topicId,
        topicName,
        conversationId,
        action,
        selectionType,
        wasSuggested,
        modelVersion: getModelVersion(),
      });
    },
    [conversation, executeAction, getTopicSuggestions, getModelVersion]
  );

  return (
    <ConversationTopicsV2
      className={className}
      conversation={conversation}
      customAttributes={customAttributes}
      lastOpenedAt={lastOpenedAt}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      onTopicChange={handleTopicsChange}
      onTopicSearch={handleTopicSearch}
      onTopicSelect={handleTopicSelect}
      topicSuggestions={topicSuggestions}
      topics={topics}
    />
  );
}

ConversationTopicsActionConnectorV2.propTypes = {
  className: PropTypes.string,
  conversation: PropTypes.object.isRequired,
  customAttributesImmutable: PropTypes.any.isRequired,
  customAttributesProvider: PropTypes.shape({
    findAll: PropTypes.func.isRequired,
    findBy: PropTypes.func.isRequired,
  }).isRequired,
  customerId: PropTypes.string.isRequired,
  getTopic: PropTypes.func,
  lastOpenedAt: PropTypes.string,
  topicsImmutable: PropTypes.any.isRequired,
  topicsProvider: PropTypes.shape({
    findAll: PropTypes.func.isRequired,
  }).isRequired,
  topicSuggestions: PropTypes.object,
};
