import DtoConverter from 'scripts/application/dto_converters/topic_suggestions_converter';
import qconsole from 'scripts/lib/qconsole';

export default class TopicSuggestionsGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.topicSuggestions;
  }

  handleRequestedEntity(dto, customerId) {
    if (this._isCustomerLoaded(customerId)) {
      this.store.addOrReplace(DtoConverter.fromDto(dto));
      this.store.resetLoading();
    }
  }

  handleRequestError(errorDto, failedCustomerId) {
    if (this._isCustomerLoaded(failedCustomerId)) {
      qconsole.log('Failed to fetch topic suggestions', errorDto);
      this.store.resetLoading();
    }
  }

  handleTopicSuggestionError(error, failedCustomerId) {
    if (this._isCustomerLoaded(failedCustomerId)) {
      qconsole.log('Failed to fetch topic suggestions', error);
      this.store.resetLoading();
    }
  }

  _isCustomerLoaded(customerId) {
    return this.context.stores.customers.has({ id: customerId });
  }
}
