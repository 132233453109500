import Html from 'slate-html-serializer-old';

import { gdocsListRules } from './gdocs_list_rules';
import { gdocsRichTextRules } from './gdocs_rich_text_rules';

const STRIP_END_NEWLINE = `<br class="Apple-interchange-newline">`;

const GdocsDeserializer = new Html({
  rules: [...gdocsRichTextRules, ...gdocsListRules],
});

export default function deserializeGdocs(html) {
  if (html.endsWith(STRIP_END_NEWLINE)) {
    html = html.replace(new RegExp(`${STRIP_END_NEWLINE}$`), '');
  }
  const { document } = GdocsDeserializer.deserialize(html);
  return document;
}

export function isPastedFromGdocs(html) {
  const detectGDocsRegex = /id=("|')?docs-internal-guid-/;
  return detectGDocsRegex.test(html);
}
