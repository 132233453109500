import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import CallForwardIcon from 'components/lib/icons/call_forward_icon';
import ConversationItem from 'models/conversation_item';
import { formatPhoneNumber } from 'models/phone_number';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';

export function CallForwardItem({ item }) {
  return (
    <ItemContent isAuditItem item={item}>
      <ItemMetadata
        icon={renderIcon()}
        item={item}
        subject=""
        text={renderText()}
        tooltip="Sent out of Gladly by system"
      />
    </ItemContent>
  );

  function renderIcon() {
    return (
      <div className="itemMetadata-callForwardIcon">
        <CallForwardIcon />
      </div>
    );
  }

  function renderText() {
    let toNumber = _.get(item, 'content.forwardedToNumber');
    if (!toNumber) {
      return 'Forwarded';
    }
    return (
      <span>
        Forwarded to <strong>{formatPhoneNumber(toNumber)}</strong>
      </span>
    );
  }
}

CallForwardItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default CallForwardItem;
