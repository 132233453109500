import changeCurrentLocation from 'actions/lib/change_current_location';
import ExternalCustomerLookup from 'models/location/external_customer_lookup';
import { getSuggestionCount } from './lib/lookup_result';

export default class OpenExternalCustomerLookup {
  constructor(context) {
    this.context = context;
  }

  run(customerId) {
    let externalCustomerLookup = ExternalCustomerLookup.create({
      customerId,
    });
    changeCurrentLocation(this.context, externalCustomerLookup);

    this.context.analytics.track('External Customer Lookup Opened', {
      customerId,
      numSuggestions: getSuggestionCount(this.context),
    });
  }
}
