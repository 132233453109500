import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'AgentAssistanceConfig',

  properties: {
    disableHeroAI: prop(Boolean).default(false),
  },

  isEnabled(feature) {
    return this[feature];
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
