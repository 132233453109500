import { Factory } from 'rosie';
import '../conversation_item_factory';

import IdGenerator from '../lib/id_generator';

Factory.define('NoReplyNeeded').attr('type', 'NO_REPLY_NEEDED');

Factory.define('NoReplyNeededItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('NoReplyNeeded', content))
  .attr('initiator', {
    type: 'AGENT',
    id: IdGenerator.newId(),
  });
