import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AnswerDraftManager from '../snippet/answer_draft_manager';
import AnswerPreview from 'models/modal/answer_preview';
import ClearAnswerSearchResult from 'actions/customer/clear_answer_search_result';
import ClearCompositionStore from 'actions/knowledge_base/clear_composition_store';
import connect from 'components/lib/connect';
import CreateSnippet from 'actions/knowledge_base/create_snippet';
import KnowledgeBaseDecorator from 'components/customer/composition/lib/knowledge_base_decorator';
import FullPageSpinner from 'components/common/full_page_spinner';
import NavigateToKbAdmin from 'actions/knowledge_base/navigate_to_kb_admin';
import OpenModal from 'actions/modal/open_modal';
import RemoveSnippetAttachment from 'actions/knowledge_base/remove_snippet_attachment';
import RemoveUpload from 'actions/knowledge_base/remove_upload';
import SearchAnswers from 'actions/knowledge_base/search_answers';
import { SnippetContentType } from 'models/answers/snippet';
import StartUpload from 'actions/knowledge_base/start_upload';
import UpdateSnippet from 'actions/knowledge_base/update_snippet';
import createAnswerLinksWithStatus from 'components/customer/composition/lib/answer/create_answer_link_with_status';

const mapStateToProps = (context, props) => {
  const id = context.getProvider('currentLocation').get().snippetId;
  const snippetComposition = context.getProvider('snippetComposition');
  const snippet = snippetComposition.get();
  const isLoading = snippetComposition.isLoading();
  const isSnippetLinksStoreLoading = context.getProvider('snippetLinks').isLoading();
  let snippetLinks = context.getProvider('snippetLinks').findAll();
  snippetLinks = createAnswerLinksWithStatus(snippet, snippetLinks, isSnippetLinksStoreLoading);
  const answerSearchResult = context.getProvider('snippetSearchResult').get();
  const answerSearchResultHits = answerSearchResult ? answerSearchResult.hits : [];
  const answerMenuData = _.filter(answerSearchResultHits, hit => {
    let content = hit.findContentByLanguage();
    return content && content.getBodyByType(SnippetContentType.INFO);
  });
  return {
    answerMenuData,
    errors: getErrors(),
    id,
    isNewSnippet: !context.getProvider('snippets').findBy({ id }),
    readOnly: !context.isFeatureEnabled('kbManagement'),
    snippet,
    snippetLinks,
    isLoading,
    isSnippetLinksStoreLoading,
    snippetRevisions: context.getProvider('snippetRevisions').findAll(),
    ...props,
  };

  function getErrors() {
    return snippetComposition.getErrors() || [];
  }
};

const mapExecuteToProps = (executeAction, props) => {
  function submitSnippet(attrs, isNewSnippet) {
    if (isNewSnippet) {
      executeAction(CreateSnippet, attrs);
    } else {
      executeAction(UpdateSnippet, attrs);
    }
  }

  function onCancel() {
    executeAction(ClearCompositionStore);
    executeAction(NavigateToKbAdmin);
  }

  function onExpandAnswer(composition) {
    executeAction(OpenModal, AnswerPreview.create({ composition, isEditingSnippets: true }));
  }

  function onRemoveInlineImageAttachment(attachmentId) {
    executeAction(RemoveSnippetAttachment, { attachmentId });
  }

  function onRemoveInlineImageUpload(uploadId) {
    executeAction(RemoveUpload, { uploadId });
  }

  function onStartUpload({ file, contentChannelType, onInsertImage, isInline, language }) {
    executeAction(StartUpload, { file, contentChannelType, onInsertImage, isInline, language });
  }

  function onSearchAnswerMenu(input) {
    if (input === '') {
      executeAction(ClearAnswerSearchResult);
    } else {
      executeAction(SearchAnswers, { searchText: input });
    }
  }

  return {
    onCancel,
    onExpandAnswer,
    onSearchAnswerMenu,
    submitSnippet,
    onRemoveInlineImageAttachment,
    onRemoveInlineImageUpload,
    onStartUpload,
  };
};

function AnswerEditorContainer(props) {
  if (props.isLoading || !variablesHaveLoaded()) {
    return <FullPageSpinner />;
  }
  return <AnswerDraftManager {...props} onSubmit={onSubmit} />;

  function onSubmit(attrs, isNewSnippet) {
    props.submitSnippet(attrs, isNewSnippet);
  }

  function variablesHaveLoaded() {
    return _.keys(props.variables).length > 0;
  }
}

AnswerEditorContainer.propTypes = {
  mentionMenuData: PropTypes.array.isRequired,
  variables: PropTypes.object.isRequired,
  submitSnippet: PropTypes.func.isRequired,
};

export const AnswerEditorWrapper = connect(mapStateToProps, mapExecuteToProps)(AnswerEditorContainer);
export default KnowledgeBaseDecorator(AnswerEditorWrapper, {
  variableResolver: variable => variable.name,
  useVariables: true,
});
