import _ from 'lodash';

import isCustomerLoaded from 'actions/customer/lib/is_customer_loaded';
import reloadSingleDataObject from 'actions/external_data/reload_single_data_object';
import qconsole from 'scripts/lib/qconsole';

/**
 * This pseudo-action reloads all custom data objects for a particular customer. It is meant to be used
 * in reconnect handlers and in a situation when we need to re-request customer data
 *
 * @param {*} context
 * @param {String} customerId
 * @param {Boolean} [requestDataRefresh] - whether to ask the backend to reload the data and serve a fresh copy
 */
export default function reloadExternalData(context, customerId, requestDataRefresh) {
  if (!isCustomerLoaded(context, customerId)) {
    qconsole.log(`Received request to reload external data objects for unloaded customer [${customerId}]. Ignoring.`);
    return Promise.resolve();
  }

  const store = context.stores.customers.storesFor(customerId).externalDataObjects;
  const dataObjects = store.findAll();
  const requests = _.map(dataObjects, envelope =>
    reloadSingleDataObject(context, store, customerId, envelope, requestDataRefresh)
  );

  // We don't care about the results of each request as they will be handled by the Observer. We only want to know when
  // the loading process is complete.
  return Promise.allSettled(requests);
}
