import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import DeepTable from 'slate-deep-table';
import { Editor, getEventTransfer } from 'slate-react-old';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AnswerLinks from 'components/text_editor/plugins/answer_links';
import AnswerLinkEditing, {
  ANSWER_LINK_ACTIVATION_CHARACTER,
} from 'components/text_editor/plugins/answer_link_editing';
import CapitalizeFirstWord from 'components/text_editor/plugins/capitalize_first_word';
import { ChannelFieldName } from 'models/answers/snippet';
import Focus from 'components/text_editor/plugins/focus';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import { getMarginFromIndent } from 'components/customer/composition/lib/slate/slate_text_indentation_menu';
import InlineImages from 'components/text_editor/plugins/inline_images';
import Lists from 'components/text_editor/plugins/lists';
import MentionMenu from 'components/lib/draft_mention_menu.jsx';
import Mentions from 'components/text_editor/plugins/mentions';
import PhoneNumberHighlighting from 'components/text_editor/plugins/phone_number_highlighting';
import PlaceholderEditing from 'components/text_editor/plugins/placeholder_editing';
import RichText from 'components/text_editor/plugins/rich_text';
import Selection from 'components/text_editor/plugins/selection';
import Tables from 'components/text_editor/plugins/tables';
import TextEditorContext, { useTextEditorContextValue } from 'components/text_editor/text_editor_context';
import { onPaste } from 'components/text_editor/paste/use_on_paste';
import { VARIABLE } from 'models/kb_variable';
import Variables from 'components/text_editor/plugins/variables';
import VariableEditing, { VARIABLE_ACTIVATION_CHARACTER } from 'components/text_editor/plugins/variable_editing';

// currently only used for the reference and self service answer editors

function plugins(props) {
  const { channel } = props;
  const { INFO, ANY_CHANNEL, MESSAGE } = ChannelFieldName;

  let capabilities = [
    Lists(),
    DeepTable(),
    Selection(),
    Focus(),
    CapitalizeFirstWord(),

    Tables(),
    RichText(props),
    InlineImages(),
    PlaceholderEditing(),
    Mentions(),
    Variables(),
    AnswerLinks(),
  ];

  if (props.readOnly) {
    capabilities.push(PhoneNumberHighlighting(props));
  }

  if (channel === INFO) {
    capabilities = capabilities.concat([VariableEditing(), AnswerLinkEditing()]);
  } else if (channel === ANY_CHANNEL || channel === 'subject' || channel === MESSAGE) {
    capabilities = capabilities.concat([VariableEditing()]);
  }

  return capabilities;
}

const SlateEditor = createReactClass({
  propTypes: {
    allowDroppingFiles: PropTypes.bool,
    answerLinks: PropTypes.array,
    answerMenuData: PropTypes.array,
    channel: PropTypes.string,
    className: PropTypes.string,
    onDropFiles: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onSearchAnswerMenu: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    setFocus: PropTypes.func,
    unsetFocus: PropTypes.func,
    value: PropTypes.object.isRequired,
    variableMenuData: PropTypes.array,
  },

  getInitialState() {
    this.serializer = FullSerializer;
    this.plugins = plugins(this.props);
    return {};
  },

  UNSAFE_componentWillReceiveProps(props) {
    if (props.answerLinks !== this.props.answerLinks) {
      this.updateEditorState(props);
    }
  },

  componentDidMount() {
    this.updateEditorState(this.props);
  },

  render() {
    const { value } = this.props;
    const classNames = classnames('slateEditor', this.props.className);

    return (
      <React.Fragment>
        <TextEditorContextProvider>
          <Editor
            className={classNames}
            onBlur={this.onBlur}
            onChange={this.props.onChange}
            onDrop={this.onDrop}
            onFocus={this.onFocus}
            onKeyDown={this.onKeyDown}
            onPaste={this.onPaste}
            placeholder={this.props.placeholder}
            plugins={this.plugins}
            readOnly={this.props.readOnly}
            ref={this.setEditorComponent}
            renderNode={this.renderNode}
            value={value}
          />
        </TextEditorContextProvider>
        {this.renderMentionMenu()}
      </React.Fragment>
    );
  },

  renderMentionMenu() {
    if (!this.editor || this.props.readOnly) return null;
    if (!this.editor.getMentionText) return null;

    let isInsertingAnswerLink = false;
    let mentionText = null;
    const variableMentionText = this.editor.getMentionText(VARIABLE_ACTIVATION_CHARACTER);
    const answerMentionText = this.editor.getMentionText(ANSWER_LINK_ACTIVATION_CHARACTER);
    if (variableMentionText !== null) {
      mentionText = variableMentionText;
    } else if (answerMentionText !== null) {
      isInsertingAnswerLink = true;
      mentionText = answerMentionText;
    }
    const items = isInsertingAnswerLink ? this.props.answerMenuData : this.props.variableMenuData;

    return (
      <MentionMenu
        getPixelPosition={this.getPixelPosition}
        isInsertingAnswerLink={isInsertingAnswerLink}
        items={items}
        onCancel={this.onCancelMenu}
        onSearchAnswerMenu={this.props.onSearchAnswerMenu}
        onTextInsertion={this.onMention}
        ref={node => (this.mentionMenu = node)}
        text={mentionText}
      />
    );
  },

  // Handlers

  onCancelMenu() {
    this.editor.removeMention();
  },

  onMention(item) {
    if (item.type === VARIABLE) {
      this.editor.insertVariable(item);
    } else {
      this.editor.insertAnswerLink(item);
    }
  },

  onKeyDown(evt, editor, next) {
    this.props.onKeyDown && this.props.onKeyDown(evt);
    if (this.mentionMenu) {
      const hasMentionText =
        editor.getMentionText(ANSWER_LINK_ACTIVATION_CHARACTER) !== null ||
        editor.getMentionText(VARIABLE_ACTIVATION_CHARACTER) !== null;
      if (hasMentionText) {
        const key = evt.key;
        // Prevent the deep table plugin up/down functionality from overriding
        // menu navigation.
        if (key === 'Enter' || key === 'ArrowUp' || key === 'ArrowDown') {
          evt.preventDefault();
          this.mentionMenu.onKeyDown(evt);
          return;
        }
      }
    }

    return next();
  },

  onDrop(event, editor, next) {
    if (this.props.allowDroppingFiles && this.props.onDropFiles) {
      let files = Array.from(event.dataTransfer.files);
      this.props.onDropFiles(files, this.props.channel);
      return true;
    }

    return false;
  },

  onFocus(evt, editor, next) {
    if (!this.editor) return next();
    setTimeout(() => this.props.setFocus && this.props.setFocus(), 10);
    return next();
  },

  onBlur(evt, editor, next) {
    if (!this.editor) return next();
    setTimeout(() => this.props.unsetFocus && this.props.unsetFocus(), 10);
    this.props.onChange && this.props.onChange(this.editor.blur());
    return next();
  },

  onPaste(event, editor, next) {
    event.preventDefault();
    const transfer = getEventTransfer(event);

    // Only allow handling pasted HTML in email and info answer editor for now.. we need reduced paste
    // handlers for non-email answers to not accidentally allow agents to insert rich text into answers
    // which don't support it.
    if (
      (this.props.channel === ChannelFieldName.ANY_CHANNEL || this.props.channel === ChannelFieldName.INFO) &&
      (transfer.type === 'html' || transfer.type === 'fragment')
    ) {
      return onPaste(event, editor, next);
    }

    const text = event.clipboardData.getData('text');

    if (!text) {
      return next();
    }

    let lines = _.map(text.split('\n'), line => {
      return line ? `<p>${line}</p>` : '';
    });
    let html = lines.join('');
    const { document } = this.serializer.deserialize(html);
    editor.insertFragment(document);
  },

  // Helpers

  getPixelPosition() {
    let menu = document.getElementsByClassName('draftEditor-mention')[0];
    return menu ? menu.getBoundingClientRect() : { top: 0, left: 0 };
  },

  // necessary because of the overriding rules in DeepTable
  renderNode(props, editor, next) {
    const { attributes, children, node } = props;
    switch (node.type) {
      case 'table':
        return (
          <StyledTable className="slateEditor-table">
            <tbody {...attributes}>{children}</tbody>
          </StyledTable>
        );
      case 'table_row':
        return <tr {...attributes}>{children}</tr>;
      case 'table_cell': {
        const textAlign = node.get('data').get('textAlign');
        const backgroundColor = node.get('data').get('backgroundColor');
        return (
          <StyledTableCell style={{ textAlign, backgroundColor }} {...attributes}>
            {children}
          </StyledTableCell>
        );
      }
      case 'paragraph': {
        const textAlign = node.get('data').get('textAlign');
        const margin = '0';
        const indent = node.get('data').get('indent');
        const marginLeft = getMarginFromIndent(indent);
        return (
          <p {...attributes} style={{ margin, marginLeft, textAlign }}>
            {children}
          </p>
        );
      }
      default:
        return next();
    }
  },

  setEditorComponent(ref) {
    if (ref) {
      this.editor = ref;
    }
  },

  updateEditorState(props) {
    if (!this.editor) return null;

    if (this.props.onChange && this.editor.updateAnswerLinks) {
      this.props.onChange(this.editor.updateAnswerLinks(props.answerLinks));
    }
  },
});

function TextEditorContextProvider({ children }) {
  const textEditorContextValue = useTextEditorContextValue();
  return <TextEditorContext.Provider value={textEditorContextValue}>{children}</TextEditorContext.Provider>;
}

export default SlateEditor;

export const StyledTable = styled.table`
  border-collapse: collapse;
  max-width: 800px;
  min-width: 100%;
`;

export const StyledTableCell = styled.td`
  border: 1px solid ${p => p.theme.colors.gray300};
  min-height: 28px;
  min-width: 50px;
  padding: 3px 5px;
  vertical-align: top;
`;
