import Communicator from 'models/communicator';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ServerClock from 'scripts/application/lib/server_clock';
import { WidgetActivityTypes } from 'models/widget_activity';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';

export function addWidgetActivityItem(context, itemContent) {
  const agentId = context.stores.currentAgent.get().id;
  const currentLocation = context.stores.currentLocation.get();
  const customerId = currentLocation.customerId;
  if (!customerId) return;

  const { conversations } = context.stores.customers.storesFor(customerId);
  const conversationId = getActiveConversationId(conversations);

  const conversationItem = {
    id: IdGenerator.newId(),
    content: {
      activityType: WidgetActivityTypes.COBROWSE,
      ...itemContent,
    },
    conversationId,
    customerId,
    initiator: {
      id: agentId,
      type: Communicator.AGENT,
    },
    timestamp: ServerClock.toISOString(),
  };

  context.gateways.widgetActivity.add(conversationItem).catch(err => {
    context.errorReporter.reportError(err, {
      extra: { conversationItem },
      message: 'Unable to add Widget Activity conversation item',
    });
  });
}
