import CreateComposition from 'actions/composition/lib/create_composition';
import CoBrowseCompositionContent from 'models/composition/cobrowse_composition_content';

class CreateCoBrowseComposition extends CreateComposition {
  constructor(context) {
    super(context, CoBrowseCompositionContent);
  }

  _createContent({ conversation }) {
    return new CoBrowseCompositionContent({
      conversationId: conversation.id,
    });
  }
}

export default CreateCoBrowseComposition;
