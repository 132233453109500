import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import AgentMetadata from '../metadata/agent_metadata';
import ApiMetadata from '../metadata/api_metadata';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import ConversationItem from 'models/conversation_item';
import CustomerMetadata from '../metadata/customer_metadata';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import PrepositionalPhrase from '../metadata/prepositional_phrase';
import RuleLink from '../metadata/rule_link';

export function StatusChangeItem({ item }) {
  let content = renderContent();
  if (!content) {
    return null;
  }

  return (
    <ItemContent isAuditItem item={item} showIcon={false}>
      {content}
    </ItemContent>
  );

  function renderContent() {
    switch (item.initiator.type) {
      case Communicator.AGENT:
        return getAgentStatusChange();
      case Communicator.API:
        return getApiStatusChange();
      case Communicator.AUTOMATED:
        return item.initiator.id ? getRuleStatusChange() : getAgentStatusChange();
      case Communicator.CUSTOMER:
        if (item.content.status === Conversation.Status.CLOSED) {
          return null;
        }
        return getReopenedByCustomer();
      case Communicator.SYSTEM:
        if (item.content.status !== Conversation.Status.CLOSED) {
          return null;
        }
        return <Metadata isInbound={false} text="Conversation was closed automatically" timestamp={item.timestamp} />;
      default:
        return getAgentStatusChange();
    }
  }

  function getAgentStatusChange() {
    return (
      <AgentMetadata
        item={item}
        text={`${item.content.status === Conversation.Status.CLOSED ? 'closed' : 'opened'} this conversation`}
      />
    );
  }

  function getApiStatusChange() {
    return (
      <ApiMetadata
        item={item}
        text={`Conversation ${item.content.status === Conversation.Status.CLOSED ? 'closed' : 'opened'}`}
      />
    );
  }

  function getReopenedByCustomer() {
    let reopenWindow = moment.duration(
      item.content.reopenWindow ? `PT${item.content.reopenWindow.toUpperCase()}` : NaN
    );

    return (
      <CustomerMetadata
        item={item}
        text={
          reopenWindow.asSeconds() > 0
            ? `opened this conversation by responding within ${reopenWindow.humanize()}`
            : 'opened this conversation by responding within configured reopen window'
        }
      />
    );
  }

  function getRuleStatusChange() {
    return (
      <Metadata
        isInbound={false}
        prepositionalPhrases={getRuleStatusChangePrepositionalPhrase()}
        text={`Conversation ${item.content.status === Conversation.Status.CLOSED ? 'closed' : 'opened'}`}
        timestamp={item.timestamp}
      />
    );
  }

  function getRuleStatusChangePrepositionalPhrase() {
    let ruleComplement = <RuleLink ruleId={item.initiator.id}>rule</RuleLink>;

    return <PrepositionalPhrase complement={ruleComplement} key="by" preposition={'by'} />;
  }
}

StatusChangeItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function mapStateToProps({ isFeatureEnabled }, { item }) {
  return {
    item,
  };
}

const StatusChangeItemContainer = connect(mapStateToProps, null)(StatusChangeItem);

StatusChangeItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default StatusChangeItemContainer;
