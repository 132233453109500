import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AvailableLanguageService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(attrs, callback, path, { orgId }) {
    let languages = this._getLanguages(orgId);
    callback(null, { status: 200, statusText: statusText(200), response: languages });
  }

  _getLanguages(orgId) {
    return this.getDatabase(orgId).availableLanguages || [{ id: 'en-us' }, { id: 'en-gb' }, { id: 'es-419' }];
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/available-languages': {
          GET: this.findAll,
        },
      },
      this
    );
  }
}
