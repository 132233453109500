import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';

export default class MarkFeatureAsSeen {
  constructor(context) {
    this.context = context;
  }

  run({ feature }) {
    const agentPreferences = this.context.stores.agentPreferences.get();
    agentPreferences.markFeatureAsSeen(feature);
    setAgentPreferences(this.context, agentPreferences);
  }
}
