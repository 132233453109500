import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import { H2 } from 'components/common/headers';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import CloseCurrentComposition from 'actions/composition/close_current_composition';

export default function ComposerCancelModal({ onCancel }) {
  const executeAction = useExecuteAction();
  return (
    <PortalModalWrapper>
      <Modal>
        <ModalCard data-aid="composerCancelModal" onClose={onCancel}>
          <Header>Discard this draft?</Header>
          <Actions>
            <Button buttonType={ButtonTypes.TEXT} data-aid="cancel-discard-button" onClick={onCancel}>
              Cancel
            </Button>
            <SendButton
              buttonType={ButtonTypes.PRIMARY}
              data-aid="confirm-discard-button"
              onClick={() => executeAction(CloseCurrentComposition)}
            >
              Yes, discard
            </SendButton>
          </Actions>
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );
}

ComposerCancelModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

const SendButton = styled(Button)`
  margin-left: ${p => p.theme.spacing.large};
`;

const Header = styled(H2)`
  display: inline;
  padding: 0 16px;
`;

const Modal = styled.div`
  text-align: center;
  .modalCard-content {
    padding: ${p => p.theme.spacing.xlarge};
    position: relative;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => p.theme.spacing.large};
`;
