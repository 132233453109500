import _ from 'lodash';
import createModel, { prop } from 'models/lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import AgentSearchHit from './agent_search_hit';

export default createModel({
  modelName: 'AgentSearchResult',

  properties: {
    id: prop(String).isRequired,
    hits: prop([AgentSearchHit]).default([]),
  },

  statics: {
    create(attrs) {
      let defaults = {
        id: IdGenerator.newId(),
      };

      return new this(_.merge(defaults, attrs));
    },
  },
});
