import createModel from './lib/create_model';

const RelatedSnippetId = createModel({
  modelName: 'RelatedSnippetId',

  properties: {
    snippetLanguage: String,
    snippetId: String,
    snippetChannelType: String,
  },

  statics: {
    create(objectAttrs) {
      return new this(objectAttrs);
    },
  },
});

export default RelatedSnippetId;
