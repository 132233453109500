import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

export const ReasonType = createEnum('AWAY', 'ACTIVE');

const AgentStatusReason = createModel({
  modelName: 'AgentStatusReason',

  properties: {
    id: prop(String).isRequired,
    default: Boolean,
    disabled: Boolean,
    name: prop(String).isRequired,
    type: prop().oneOf(..._.keys(ReasonType)).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(Object.assign({ id: IdGenerator.newId() }, attrs));
    },
  },
});

export default AgentStatusReason;
