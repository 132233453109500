import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class AddNote {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, errors }) {
    const compositionsStore = getCompositionsStore(this.context);
    const composition = compositionsStore.findBy({ id: compositionId });
    if (!composition) {
      return;
    }

    compositionsStore.setErrors(compositionId, errors);
  }
}
