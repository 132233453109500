import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('Assignee');

Factory.define('AssigneeWithGroupDefaults')
  .extend('Assignee')
  .attr('routingGroupId', 'default-routing-group-id');

Factory.define('AssigneeWithAgentDefaults')
  .extend('Assignee')
  .attr('routingGroupId', 'default-routing-group-id')
  .attr('agentId', 'default-agent-id');
