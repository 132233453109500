import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';

import EmailSuggestion from 'models/email_suggestion';

export default class AddEmailSuggestions {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, emails }) {
    const customerStores = this.context.stores.customers.storesFor(customerId);
    const profile = customerStores.profile.get();

    forEach(emails, email => {
      if (profile.hasEmail(email)) {
        return;
      }

      const existingSuggestion = this.context.stores.emailSuggestions.findBy({ email: email.toLowerCase() });
      if (!existingSuggestion) {
        const newSuggestion = EmailSuggestion.create({
          email,
          count: 1,
        });
        this.context.stores.emailSuggestions.addOrReplace(newSuggestion);

        const localSuggestions = this.context.stores.localEmailSuggestions.get();
        localSuggestions.push(newSuggestion.toJs());
        this.context.stores.localEmailSuggestions.set(localSuggestions);
      } else {
        existingSuggestion.incrementCount();
        this.context.stores.emailSuggestions.addOrReplace(existingSuggestion);

        const localSuggestions = this.context.stores.localEmailSuggestions.get();
        const existingLocalIndex = findIndex(
          localSuggestions,
          localSuggestion => localSuggestion.id === existingSuggestion.id
        );
        localSuggestions[existingLocalIndex] = existingSuggestion.toJs();
        this.context.stores.localEmailSuggestions.set(localSuggestions);
      }
    });
  }
}
