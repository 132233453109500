import MqttGateway from './lib/mqtt_gateway';

export default class CompanyProfileGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'configuration/company-profile';
  }

  request() {
    return super._subscribeAndFetch();
  }

  onFetchResponse(fetchResultDto, topic) {
    this.notifyObservers('handleCompanyProfileFetch', fetchResultDto);
  }

  onFetchError(errorDto, topic) {
    this.notifyObservers('handleFetchRequestError', errorDto);
  }

  onReceive(entityDto, topic) {
    this.notifyObservers('handleCompanyProfile', entityDto);
  }
}
