import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function ExternalProfileRow({
  externalSystemName,
  isSelected,
  onClick,
  profileRow,
  rowSpan,
  shouldDisplaySource,
  shouldDisplaySeparator,
}) {
  const leftColumnText = shouldDisplaySource ? externalSystemName || 'External' : '';
  const selectorClassNames = classnames('externalCustomerLookupSearchResults-table-selector', {
    'externalCustomerLookupSearchResults-table-selector-selected': isSelected,
  });
  const trClassNames = classnames('externalCustomerLookupSearchResults-table-externalProfileRow', {
    'externalCustomerLookupSearchResults-table-lastRow': shouldDisplaySeparator,
  });
  const sourceCellClassNames = classnames('externalCustomerLookupSearchResults-table-sourceCell', {
    separated: shouldDisplaySeparator || rowSpan > 1,
  });
  const sourceNameRowspan = rowSpan || 1;

  const sourceNameCell = (
    <td className={sourceCellClassNames} key="externalCustomerLookup-sourceCell" rowSpan={sourceNameRowspan}>
      <div className="sourceName">{leftColumnText}</div>
    </td>
  );
  const shouldRenderNameCall = shouldDisplaySource || sourceNameRowspan === 1;

  return (
    <tr className={trClassNames}>
      {shouldRenderNameCall ? sourceNameCell : null}
      <td
        className="externalCustomerLookupSearchResults-table-externalProfileRow-dataCell externalCustomerLookupSearchResults-table-selectorSpacer"
        key="externalCustomerLookup-selectorSpacer"
        onClick={onClick}
      >
        <div className="externalCustomerLookupSearchResults-table-selectorWrapper">
          <div className={selectorClassNames} />
        </div>
      </td>
      {_.map(profileRow, (value, index) => (
        <td
          className="externalCustomerLookupSearchResults-table-externalProfileRow-dataCell"
          key={`externalCustomerLookup-external-${index}`}
          onClick={onClick}
        >
          {value}
        </td>
      ))}
    </tr>
  );
}

ExternalProfileRow.propTypes = {
  externalSystemName: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  profileRow: PropTypes.array,
  rowSpan: PropTypes.number,
  shouldDisplaySource: PropTypes.bool,
  shouldDisplaySeparator: PropTypes.bool,
};
