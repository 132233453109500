import { EditorState } from 'draft-js';

import { convertFromHTML } from './draft_convert';
import { convertFromEmailHTML } from './draft_convert_email';
import { getCurrentMention } from './draft_mention';
import { forceSelectionToFirstPlaceholder } from './draft_placeholder';
import { insertFragment } from './draft_shared';
import { updateVariables } from './draft_variable';

export function insertSnippet(
  editorState,
  snippetText,
  variables,
  richTextEnabled = false,
  shouldForceSelection = true
) {
  const { start, end, text } = getCurrentMention(editorState);

  let selection = editorState.getSelection();
  if (text !== null) {
    selection = selection.merge({
      anchorOffset: start,
      focusOffset: end,
    });
  }

  return insertSnippetAtSelection(
    editorState,
    snippetText,
    variables,
    selection,
    richTextEnabled,
    shouldForceSelection
  );
}

export function insertSnippetAtSelection(
  editorState,
  snippetText,
  variables,
  selection,
  richTextEnabled = false,
  shouldForceSelection = true
) {
  const originalEditorState = editorState;

  let converter = convertFromEmailHTML;
  if (!richTextEnabled) {
    converter = convertFromHTML;
  }

  let snippetEditorState = EditorState.createWithContent(converter(snippetText, { variables }));
  snippetEditorState = updateVariables(snippetEditorState, variables);

  editorState = insertFragment(editorState, selection, snippetEditorState.getCurrentContent().getBlockMap());

  if (!shouldForceSelection) {
    return editorState;
  }

  editorState = forceSelectionToFirstPlaceholder(selection, editorState, snippetEditorState);

  return collapseHistoryAndForceSelection(
    originalEditorState,
    editorState.getCurrentContent(),
    editorState.getSelection()
  );
}

// TRICK: use this when we do a bunch of editor state operations in a row here but DON'T want to be able to undo them individually.
// We do a bunch of intermediate operations to get the contentState and selection, then push that onto the original editor state.
function collapseHistoryAndForceSelection(originalEditorState, contentState, selection) {
  const editorState = EditorState.push(originalEditorState, contentState, 'insert-fragment');
  return EditorState.forceSelection(editorState, selection);
}
