import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { GRAYS } from 'components/common/colors';
import LoadingSpinner from 'components/lib/loading_spinner';
import StackContainer from 'components/common/containers/stack_container';
import { H3 } from 'components/common/headers';

export default function ProfileCard(props) {
  let { menu, contentInset, children, isEmpty, isLoading, onClick, footer, title, ...rest } = props;

  let content = (
    <React.Fragment>
      {title && (
        <StyledHeader
          data-aid={props['data-aid'] ? `${props['data-aid']}_header` : 'ProfileCard_header'}
          isEmpty={isEmpty}
        >
          {title}
          {menu && <StyledMenuContainer>{menu}</StyledMenuContainer>}
        </StyledHeader>
      )}
      {children}
    </React.Fragment>
  );
  if (isLoading) {
    content = <LoadingSpinner color={GRAYS.xlight} position="relative" size="22px" width="2px" />;
  }

  return (
    <StyledCard inset="none">
      <StyledCardContent inset={contentInset} onClick={!isLoading ? onClick : undefined} {...rest}>
        {content}
      </StyledCardContent>
      {footer}
    </StyledCard>
  );
}

export const StyledCard = styled(StackContainer)`
  background-color: ${p => p.theme.colors.white};
  border-radius: ${p => p.theme.borderRadius.default};
  box-shadow: ${p => p.theme.boxShadow.small};
`;

export const StyledCardContent = styled(StackContainer)`
  flex-direction: column;
  margin-bottom: 0px;
`;

/**
 * Use `compact` property to clamp line-height to 1 (as defined in H3) and reduce spacing around the header
 */
export const StyledHeader = styled(H3)`
  color: ${p => p.theme.colors.gray900};
  ${p => (p.compact ? '' : 'line-height: unset;')}
  ${p => (p.isEmpty ? `margin-bottom: 0;` : '')}
`;

const StyledMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

ProfileCard.propTypes = {
  /** (React/ HTML elements) Standard react children prop. This will be replaced with a loading spinner when `isLoading` is true */
  children: PropTypes.node.isRequired,

  /** Adjusts the padding around the card content. If omitted, default stack inset is used **/
  contentInset: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  'data-aid': PropTypes.string,
  isEmpty: PropTypes.bool,
  /** Boolean dictating whether the loading indicator is shown */
  isLoading: PropTypes.bool,
  /** (Menu component) A menu component including button and menu items */
  menu: PropTypes.object,
  /** OnClick handler for card */
  onClick: PropTypes.func,
  /** Optional node rendered under the list **/
  footer: PropTypes.node,
  /** Text to display at the top of the card */
  title: PropTypes.string,
};
