import i18n from 'scripts/i18n/i18n';

// Load interaction strings.
// While English is the only language it's easier to maintain the strings here, at
// the place where they are used.
// When languages other than English are added it would be best to relocate this
// bundle to the root with other i18n strings.
i18n.addResourceBundle('en', 'translation', {
  interactionChannel: {
    _DEFAULT: 'communication',
    CHAT: 'chat',
    CUSTOM_CHANNEL: '{{channelName}}',
    EMAIL: 'email',
    FB_MESSENGER: 'Facebook Messenger',
    INSTAGRAM_DIRECT: 'Instagram Direct',
    PHONE_CALL: 'call',
    SMS: 'SMS',
    TWITTER: 'Twitter',
    VOICEMAIL: 'voicemail',
    WHATSAPP: 'WhatsApp',
  },
  interactionMessage: {
    _DEFAULT: 'communication',
    EMAIL: 'email',
    CHAT: 'chat',
    CUSTOM_CHANNEL: '{{channelName}} message',
    FB_MESSENGER: 'Facebook message',
    INSTAGRAM_DIRECT: 'Instagram message',
    PHONE_CALL: 'call',
    SMS: 'SMS',
    TWITTER: 'Twitter DM',
    VOICEMAIL: 'voicemail',
    WHATSAPP: 'WhatsApp message',
  },
  interactionSession: {
    _DEFAULT: 'communication',
    EMAIL: 'email conversation',
    CHAT: 'chat',
    CUSTOM_CHANNEL: '{{channelName}} conversation',
    FB_MESSENGER: 'Facebook conversation',
    INSTAGRAM_DIRECT: 'Instagram conversation',
    PHONE_CALL: 'call',
    SMS: 'SMS conversation',
    TWITTER: 'Twitter DM',
    VOICEMAIL: 'voicemail',
    WHATSAPP: 'WhatsApp conversation',
  },
});
