import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';

import AgentNotificationSound from './agent_notification_sound';
import AgentSlateBetaToggle from './agent_slate_beta_toggle';
import AnswerSelect from 'components/common/answer_select';
import Button, { ButtonTypes } from 'components/common/button';
import ContactAgentStationIcon from 'components/lib/icons/contact_agent_station_icon';
import ContactEmailIcon from 'components/lib/icons/contact_email_icon';
import ContactIdIcon from 'components/lib/icons/contact_id_icon';
import ContactPhoneIcon from 'components/lib/icons/contact_phone_icon';
import EditableFieldDumb from '../lib/editable_field_dumb';
import InformationFillIcon from 'components/common/icons/fill/information-fill';
import LoadingSpinner from 'components/lib/loading_spinner';
import ProfileAvatar from '../lib/profile_avatar';
import Tooltip from 'components/common/tooltip';

export const PasswordSent = styled.div`
  align-items: center;
  display: flex;
  height: 34px;
  max-width: 150px;
  text-align: center;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AgentProfile = createReactClass({
  propTypes: {
    cancelCurrentAgent: PropTypes.func.isRequired,
    currentAgent: PropTypes.object.isRequired,
    emailGreetingSnippet: PropTypes.object,
    emailSignatureSnippet: PropTypes.object,
    errors: PropTypes.object,
    navigateToPreviousPage: PropTypes.func.isRequired,
    isDirectDialEnabled: PropTypes.bool.isRequired,
    isExternalNameEnabled: PropTypes.bool,
    isHotelingEnabled: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    isResponsePending: PropTypes.bool,
    isSsoEnabled: PropTypes.bool.isRequired,
    onProfileUpdate: PropTypes.func.isRequired,
    requestPasswordReset: PropTypes.func.isRequired,

    // Beta features and related properties
    isSlateBetaEnabled: PropTypes.bool,
  },

  getInitialState() {
    return {
      currentAgent: {
        name: this.props.currentAgent.name || '',
        phoneNumber: _(this.props.currentAgent.voiceConfiguration).get('phone.original') || '',
        extension: _(this.props.currentAgent.voiceConfiguration).get('phone.extension') || '',
        employeeId: this.props.currentAgent.employeeId || '',
        emailGreetingSnippetId: this.props.currentAgent.emailGreetingSnippetId || '',
        emailSignatureSnippetId: this.props.currentAgent.emailSignatureSnippetId || '',
        externalName: this.props.currentAgent.externalName || '',
      },
      resetPasswordClicked: false,
    };
  },

  // lifecycle methods

  componentWillUnmount() {
    this.props.cancelCurrentAgent();
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isResponsePending && _.every(nextProps.errors, _.isNull)) {
      this.setState({
        currentAgent: {
          name: nextProps.currentAgent.name || '',
          phoneNumber: _(nextProps.currentAgent.voiceConfiguration).get('phone.original') || '',
          extension: _(nextProps.currentAgent.voiceConfiguration).get('phone.extension') || '',
          employeeId: nextProps.currentAgent.employeeId || '',
          emailGreetingSnippetId: nextProps.currentAgent.emailGreetingSnippetId || '',
          emailSignatureSnippetId: nextProps.currentAgent.emailSignatureSnippetId || '',
          externalName: nextProps.currentAgent.externalName || '',
        },
      });
    }
  },

  // update methods

  updateName(name) {
    if (name !== this.state.currentAgent.name) {
      const newAgent = _.merge({}, this.state.currentAgent, { name: name.trim() });
      this.setState({ currentAgent: newAgent });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  },

  updatePhoneNumber(phoneNumber) {
    if (phoneNumber !== this.state.currentAgent.phoneNumber) {
      const newAgent = _.merge({}, this.state.currentAgent, { phoneNumber });
      this.setState({ currentAgent: newAgent });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  },

  updateExtension(extension) {
    if (extension !== this.state.currentAgent.extension) {
      const newAgent = _.merge({}, this.state.currentAgent, { extension });
      this.setState({ currentAgent: newAgent });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  },

  updateEmployeeId(employeeId) {
    if (employeeId !== this.state.currentAgent.employeeId) {
      const newAgent = _.merge({}, this.state.currentAgent, { employeeId });
      this.setState({ currentAgent: newAgent });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  },

  updateExternalName(externalName) {
    if (externalName !== this.state.currentAgent.externalName) {
      const newAgent = _.merge({}, this.state.currentAgent, { externalName });
      this.setState({ currentAgent: newAgent });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  },

  updateEmailGreetingSnippetId(emailGreetingSnippet) {
    const hasEmailGreetingBeenUpdated =
      !emailGreetingSnippet || emailGreetingSnippet.value !== this.state.currentAgent.emailGreetingSnippetId;
    const emailGreetingSnippetId = emailGreetingSnippet ? emailGreetingSnippet.value : '';

    if (hasEmailGreetingBeenUpdated) {
      const newAgent = _.merge({}, this.state.currentAgent, { emailGreetingSnippetId });
      this.setState({ currentAgent: newAgent });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  },

  updateEmailSignatureSnippetId(emailSignatureSnippet) {
    const hasEmailSignatureBeenUpdated =
      !emailSignatureSnippet || emailSignatureSnippet.value !== this.state.currentAgent.emailSignatureSnippetId;
    const emailSignatureSnippetId = emailSignatureSnippet ? emailSignatureSnippet.value : '';

    if (hasEmailSignatureBeenUpdated) {
      const newAgent = _.merge({}, this.state.currentAgent, { emailSignatureSnippetId });
      this.setState({ currentAgent: newAgent });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  },

  handleResetPasswordClick() {
    this.props.requestPasswordReset(this.props.currentAgent.email);
    this.setState({ resetPasswordClicked: true });
  },

  render() {
    if (this.props.isLoading) {
      return <LoadingSpinner />;
    }
    let fields, advanced;
    if (!this.props.hasExternalAgentActions) {
      fields = (
        <React.Fragment>
          {this.renderName()}
          {this.renderEmail()}
        </React.Fragment>
      );
      advanced = <React.Fragment />;
    } else {
      fields = (
        <React.Fragment>
          {this.renderName()}
          {this.renderEmail()}
          {this.renderStationId()}
          {this.renderPhoneNumber()}
          {this.renderEmployeeId()}
          {this.renderExternalName()}
        </React.Fragment>
      );
      advanced = this.renderEmailTemplateContainer();
    }

    let passwordReset;
    if (this.props.isSsoEnabled) {
      passwordReset = null;
    } else if (this.state.resetPasswordClicked) {
      passwordReset = <PasswordSent>Check your email to reset your password.</PasswordSent>;
    } else {
      passwordReset = (
        <Button
          buttonType={ButtonTypes.SECONDARY}
          className="agentProfile-request-password-reset"
          onClick={this.handleResetPasswordClick}
        >
          Reset Password
        </Button>
      );
    }

    return (
      <Translation>
        {t => {
          return (
            <div className="agentProfile">
              <Container>
                {this.renderAgentAvatar()}
                {passwordReset}
              </Container>
              <div className="agentProfile-details">
                <div className="agentProfile-profileContainer">
                  <div className="agentProfile-header agentProfile-profileHeader">{t('Your Profile')}</div>
                  {fields}
                </div>
                {advanced}
                {this.renderAgentNotificationSound()}
                {this.renderBetaFeatures()}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  },

  renderAgentAvatar() {
    return (
      <div className="agentProfile-avatarContainer">
        <div className="agentProfile-avatar">
          <ProfileAvatar profile={{ image: this.props.currentAgent.avatarUrl, name: this.props.currentAgent.name }} />
        </div>
      </div>
    );
  },

  renderName() {
    let agentNameClasses = classnames('agentProfile-inputField agentProfile-name editable-field', {
      'agentProfile-inputField-error': this.props.errors.name,
    });
    return (
      <div className="agentProfile-nameContainer">
        <span
          className={classnames('agentProfile-tooltipContainer', { 'simptip-position-bottom': this.props.errors.name })}
          data-tooltip={this.props.errors.name ? this.getFormattedErrorMessage(this.props.errors.name) : ''}
        >
          <EditableFieldDumb
            autoFocus
            className={agentNameClasses}
            onBlur={this.updateName}
            onSubmit={this.updateName}
            placeholder="Set your name"
            value={this.state.currentAgent.name}
          />
        </span>
      </div>
    );
  },

  renderEmail() {
    return (
      <div className="agentProfile-emailContainer">
        <ContactEmailIcon className="agentProfile-emailIcon" />
        <div className="agentProfile-email">{this.props.currentAgent.email}</div>
      </div>
    );
  },

  renderStationId() {
    let stationId = _(this.props.currentAgent.voiceConfiguration).get('stationId');
    if (!stationId) {
      return false;
    }
    return (
      <div className="agentProfile-stationIdContainer">
        <ContactAgentStationIcon className="agentProfile-stationIdIcon" />
        <div className="agentProfile-stationId">{stationId}</div>
      </div>
    );
  },

  renderPhoneNumber() {
    if (!this.props.isDirectDialEnabled) {
      return null;
    }

    let phoneFieldClasses = classnames('agentProfile-inputField agentProfile-phoneNumber editable-field', {
      'agentProfile-inputField-error': this.props.errors.phoneNumber != null,
    });
    let extensionFieldClasses = classnames('agentProfile-inputField agentProfile-extension editable-field', {
      'agentProfile-inputField-error': this.props.errors.extension != null,
    });
    let stationClasses = classnames('agentProfile-station', {
      'agentProfile-station-placeholder': !this.props.stationName,
    });

    return (
      <div className="agentProfile-phoneInputFieldContainer">
        <ContactPhoneIcon className="agentProfile-phoneIcon" />
        {this.props.isHotelingEnabled ? (
          <div className={stationClasses}>{this.props.stationName || 'Station'}</div>
        ) : (
          <React.Fragment>
            <span
              className={classnames('agentProfile-tooltipContainer', {
                'simptip-position-bottom': this.props.errors.phoneNumber != null,
              })}
              data-tooltip={
                this.props.errors.phoneNumber ? this.getFormattedErrorMessage(this.props.errors.phoneNumber) : ''
              }
            >
              <EditableFieldDumb
                className={phoneFieldClasses}
                onBlur={this.updatePhoneNumber}
                onSubmit={this.updatePhoneNumber}
                placeholder="(123) 456-7890"
                value={this.state.currentAgent.phoneNumber}
              />
            </span>
            <span
              className={classnames('agentProfile-tooltipContainer', {
                'simptip-position-bottom': this.props.errors.extension != null,
              })}
              data-tooltip={
                this.props.errors.extension ? this.getFormattedErrorMessage(this.props.errors.extension) : ''
              }
            >
              <EditableFieldDumb
                className={extensionFieldClasses}
                onBlur={this.updateExtension}
                onSubmit={this.updateExtension}
                placeholder="Ext"
                value={this.state.currentAgent.extension}
              />
            </span>
          </React.Fragment>
        )}
      </div>
    );
  },

  renderEmployeeId() {
    return (
      <div className="agentProfile-employeeIdContainer">
        <ContactIdIcon className="agentProfile-idIcon" />
        <EditableFieldDumb
          className="agentProfile-inputField agentProfile-employeeId editable-field"
          onBlur={this.updateEmployeeId}
          onSubmit={this.updateEmployeeId}
          placeholder="Employee ID"
          value={this.state.currentAgent.employeeId}
        />
      </div>
    );
  },

  renderExternalName() {
    if (!this.props.isExternalNameEnabled) {
      return null;
    }

    return (
      <div className="agentProfile-externalNameContainer">
        <ExternalNameIconWrapper>
          <FontAwesomeIcon icon={faFileSignature} />
        </ExternalNameIconWrapper>
        <EditableFieldDumb
          className="agentProfile-inputField agentProfile-externalName editable-field"
          onBlur={this.updateExternalName}
          onSubmit={this.updateExternalName}
          placeholder="External Name"
          value={this.state.currentAgent.externalName}
        />
        <Tooltip message="Used instead of your full name when writing external communications" position="top">
          <StyledInfoIcon />
        </Tooltip>
      </div>
    );
  },

  renderEmailTemplateContainer() {
    return (
      <Translation>
        {t => {
          return (
            <div className="agentProfile-template">
              <div className="agentProfile-header agentProfile-emailTemplateHeader">{t('Email Template')}</div>
              <div className="agentProfile-emailMenuContainer">
                {this.renderGreetingMenu()}
                {this.renderSignatureMenu()}
              </div>
            </div>
          );
        }}
      </Translation>
    );
  },

  renderGreetingMenu() {
    return (
      <div className="agentProfile-greetingMenuContainer">
        <AnswerSelect
          className="agentProfile-answerSelect"
          clearable
          data-aid="greetingInput"
          label="Greeting"
          onChange={this.updateEmailGreetingSnippetId}
          selectedOption={this.props.emailGreetingSnippet}
          value={this.state.currentAgent.emailGreetingSnippetId}
        />
      </div>
    );
  },

  renderSignatureMenu() {
    return (
      <div className="agentProfile-signatureMenuContainer">
        <AnswerSelect
          className="agentProfile-answerSelect"
          clearable
          data-aid="signatureInput"
          label="Signature"
          onChange={this.updateEmailSignatureSnippetId}
          selectedOption={this.props.emailSignatureSnippet}
          value={this.state.currentAgent.emailSignatureSnippetId}
        />
      </div>
    );
  },

  renderAgentNotificationSound() {
    return (
      <SectionContainer>
        <div className="agentProfile-header">Agent Notification Sound</div>
        <AgentNotificationSound />
      </SectionContainer>
    );
  },

  renderBetaFeatures() {
    if (!this.props.isSlateBetaEnabled) {
      return null;
    }

    const betaEditorToggle = this.props.isSlateBetaEnabled ? <AgentSlateBetaToggle /> : null;
    return (
      <SectionContainer>
        <div className="agentProfile-header">Beta Features</div>
        {betaEditorToggle}
      </SectionContainer>
    );
  },

  // helpers

  getFormattedErrorMessage(error) {
    return error.charAt(0).toUpperCase() + error.slice(1);
  },
});

const StyledInfoIcon = styled(InformationFillIcon)`
  cursor: pointer;
  display: inline-flex;
  fill: #999999;
  height: 12px;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle;
`;

const ExternalNameIconWrapper = styled.div`
  align-items: center;
  color: #999999;
  display: flex;
  height: 23px;
  justify-content: center;
  margin-right: 4px;
  padding-left: 6px;
  width: 23px;
`;

const SectionContainer = styled.div`
  margin-top: 16px;
`;

export default AgentProfile;
