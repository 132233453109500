import { useContext, useCallback } from 'react';
import IsFeatureEnabledContext from 'components/contexts/is_feature_enabled';

export default function useIsFeatureEnabled() {
  const appFeatures = useContext(IsFeatureEnabledContext);

  return useCallback(
    feature => {
      return (appFeatures && appFeatures.isEnabled(feature)) || false;
    },
    [appFeatures]
  );
}
