import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createNotificationModel, { prop } from 'models/notification/lib/create_notification_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import NotificationType from 'models/notification/notification_type';

export const ReloadReason = createEnum('PHONE_NOT_AVAILABLE');

export default createNotificationModel(NotificationType.OPTIONAL_RELOAD, {
  modelName: 'OptionalReloadNotification',
  properties: {
    id: prop(String).isRequired,
    reason: prop().oneOf(..._.keys(ReloadReason)),
  },

  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), ...attrs });
    },
  },
});
