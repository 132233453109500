import { Factory } from "rosie";

import '../conversation_item_factory';

Factory.define('VoiceCampaignOutreach').attr('type', 'VOICE_CAMPAIGN_OUTREACH');

Factory.define('VoiceCampaignOutreachWithDefaults')
  .extend('VoiceCampaignOutreach')
  .attr('name', 'campaign name')
  .attr('campaignId', 'campaign-id')
  .attr('description', 'campaign outreach description');

Factory.define('VoiceCampaignOutreachItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function (content) {
    return Factory.attributes('VoiceCampaignOutreach', content);
  });

Factory.define('VoiceCampaignOutreachItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('VoiceCampaignOutreachWithDefaults', content);
  });
