import React from 'react';
import { createPluginFactory } from '@udecode/plate';

import UseInsertSelectedSuggestedReply from './use_suggested_replies';

const SUGGESTED_REPLIES = 'create-suggested-reply';
export default function SuggestedReplies() {
  return createPluginFactory({
    key: SUGGESTED_REPLIES,
    isLeaf: true,
    renderAfterEditable: () => {
      return <UseInsertSelectedSuggestedReply />;
    },
  })();
}
