import React, { useContext } from 'react';
import styled from 'styled-components';

import AnswerList from 'components/customer/answer_panel/search/answer_list';
import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';

export default function AnswerSuggestions() {
  const { answersList, focusedAnswer } = useContext(AnswerPanelContext);

  return (
    <Results>
      <Suggestions>Suggested Answers</Suggestions>
      <AnswerList answers={answersList.answers} selectedAnswerId={focusedAnswer && focusedAnswer.id} />
    </Results>
  );
}

const Results = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

const Suggestions = styled.div`
  color: ${p => p.theme.colors.gray900};
  font-size: ${p => p.theme.fontSize.small};
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.large};
`;
