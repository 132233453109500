import AgentsLiveboard from 'models/liveboards/agents_liveboard';
import LiveboardType from './liveboard_type';
import SmartMatchLiveboard from 'models/liveboards/smart_match_liveboard';
import SummaryLiveboard from 'models/liveboards/summary_liveboard';
import TopicsLiveboard from 'models/liveboards/topics_liveboard';

export default [
  [LiveboardType.AGENTS, AgentsLiveboard],
  [LiveboardType.PEOPLE_MATCH, SmartMatchLiveboard],
  [LiveboardType.SUMMARY, SummaryLiveboard],
  [LiveboardType.TOPICS, TopicsLiveboard],
];
