import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const AISuggestedReply = createModel({
  modelName: 'AISuggestedReply',
  properties: {
    id: prop(String).isRequired,
    text: prop(String).isRequired,
    usedAt: prop(String),
  },

  setUsedAt() {
    this.usedAt = new Date().toISOString();
  },

  statics: {
    create(attrs) {
      return new this({
        id: IdGenerator.newId(),
        ...attrs,
      });
    },
  },
});

export default AISuggestedReply;
