import { Factory } from 'rosie';


Factory.define('PaymentStatusEventContent').attr('type', 'PAYMENT_STATUS_EVENT');

Factory.define('PaymentStatusEventContentWithDefaults')
  .extend('PaymentStatusEventContent')
  .attr('statusEvent', 'SUBMITTED')
  .attr('secureDataItemId', 'id-123');

Factory.define('PaymentStatusEventContentItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('PaymentStatusEventContent', content);
  });

Factory.define('PaymentStatusEventContentItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('PaymentStatusEventContentWithDefaults', content);
  });
