import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { getMultipleAttachmentsUrl } from '../../../../domain/services/attachments';

function AttachmentsDownloadAll({ attachments, customerName, itemId, orgId, pathPrefix }) {
  const unredactedAttachments = _.filter(attachments, a => !a.isRedacted);
  if (unredactedAttachments.length < 3) {
    return null;
  }
  const fileId = itemId ? `-${itemId.slice(0, 5)}` : '';
  const customerNameTransformed = customerNameForFile(customerName);
  const fileName = `${customerNameTransformed}-${pathPrefix}-attachments${fileId}`;
  return (
    <div className="attachments-attachment">
      <a
        className="attachments-attachment-description attachments-attachment-clickable attachments-downloadAll"
        href={getMultipleAttachmentsUrl(pathPrefix, orgId, fileName, unredactedAttachments)}
      >
        <i className="attachments-downloadAll-icon" />
        Download All
      </a>
    </div>
  );

  function customerNameForFile(customerName) {
    let nameSplit = customerName.split(' ');
    let transformedName = nameSplit[0][0] + nameSplit[1];
    return transformedName;
  }
}

AttachmentsDownloadAll.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  itemId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
};

export default AttachmentsDownloadAll;
