import map from 'lodash/map';
import bindAll from 'lodash/bindAll';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import connect from 'components/lib/connect';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PhoneControlsActionContainer from '../lib/phone_controls_action_container';
import { formatPhoneNumber, isValidPhoneNumber } from 'models/phone_number';
import classnames from 'classnames';
import { CustomerProfileAvatar } from '../../customer/summary/customer_profile_avatar';
import ProfileAvatar from 'components/lib/profile_avatar';

export class ParticipantList extends Component {
  constructor(props) {
    super(props);

    bindAll(this, ['handleClickOutside']);
  }

  render() {
    return (
      <OutsideClickHandler onClickOutside={this.handleClickOutside}>
        <PhoneControlsActionContainer className={classnames('participantListContainer', this.props.className)}>
          <div className={'participantsHeader'}>Call participants</div>
          {this.renderCustomer(this.props.customerName)}
          {this.props.agents.map(this.renderParticipant)}
          {this.props.joinedObservers.map(this.renderParticipant)}
          {this.renderExternalParticipants}
          {this.renderExternalParticipants(this.props.externalParticipantNumbers)}
        </PhoneControlsActionContainer>
      </OutsideClickHandler>
    );
  }

  renderCustomer(customer) {
    return (
      <div className={'participantContainer'}>
        <CustomerProfileAvatar className={'participantContainer-avatar-wrapper'} />
        <p className={'participantName'}>{this.props.customerName}</p>
      </div>
    );
  }

  renderParticipant(participant) {
    return (
      <div className={'participantContainer'}>
        <ProfileAvatar
          className={'participantContainer-avatar'}
          profile={{ name: participant.name, image: participant.avatarUrl }}
          wrapperClassName={'participantContainer-avatar-wrapper'}
        />
        <p className={'participantName'}>{participant.name}</p>
      </div>
    );
  }

  renderExternalParticipants(externalNumbers) {
    if (externalNumbers.length > 0) {
      return (
        <div className={'externalParticipantsContainer'}>
          <p className={'externalParticipantsContainer-header'}>External participants</p>
          {externalNumbers.map(this.renderExternalNumber)}
        </div>
      );
    }
    return;
  }

  renderExternalNumber(externalNumber) {
    return (
      <div className={'participantContainer'}>
        <div className="profileAvatar-placeholderIcon participantContainer-avatar-wrapper" />
        <p className={'participantName'}>{externalNumber}</p>
      </div>
    );
  }

  handleClickOutside(evt) {
    this.props.onClose(evt);
  }
}

ParticipantList.propTypes = {
  className: PropTypes.string,
  joinedObservers: PropTypes.arrayOf(PropTypes.object).isRequired,
  agents: PropTypes.object.isRequired,
  customerName: PropTypes.string.isRequired,
  externalParticipantNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  // callbacks
  onClose: PropTypes.func.isRequired,
};

const ParticipantListContainer = connect(mapStateToProps, mapExecuteToProps)(ParticipantList);
export default ParticipantListContainer;

function mapStateToProps({ getProvider }) {
  const activeCallProvider = getProvider('activeCall');
  const activeCall = activeCallProvider.get();
  const profile = getProvider('profile').get();
  const currentAgent = getProvider('currentAgent').get();
  const customerName = profile.name
    ? profile.name
    : formatPhoneNumber(activeCall.conversationItem.content.customerNumber);

  const phoneCall = activeCall.conversationItem.content;
  const joinedObserverIds = phoneCall.getJoinedObserverParticipantIds();
  const externalParticipantIds = phoneCall.getExternalActiveParticipantIds();
  const agentParticipantIds = phoneCall.getAgentParticipantIdsOnCall();

  const agents = map(agentParticipantIds, function(id) {
    let agent = getProvider('agents').findBy({ id });
    let agentName = agent.getDisplayName();
    if (id === currentAgent.id) {
      agentName += ' (You)';
    }
    return {
      id: agent.id,
      name: agentName,
      avatarUrl: agent.avatarUrl,
    };
  });

  const joinedObservers = map(joinedObserverIds, function(id) {
    let observer = getProvider('agents').findBy({ id });
    let observerName = observer.getDisplayName();
    if (id === currentAgent.id) {
      observerName += ' (You)';
    }
    return {
      id: observer.id,
      name: observerName,
      avatarUrl: observer.avatarUrl,
    };
  });

  let externalParticipantNumbers = map(externalParticipantIds, function(id) {
    return (isValidPhoneNumber(id) && formatPhoneNumber(id)) || id;
  });

  return {
    customerName,
    joinedObservers,
    agents,
    externalParticipantNumbers,
  };
}

function mapExecuteToProps(executeAction, { onClose }) {
  return {
    onClose,
  };
}
