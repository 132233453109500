import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/icons/icon';

export default props => (
  <StyledIcon viewBox="0 0 120 109" {...props}>
    <path
      className="bookCover-2"
      d="M16.105 81.56c0 2.355 1.897 4.308 4.255 4.423h73.598v3.044H20.475A7.456 7.456 0 0 1 13 81.56a7.456 7.456 0 0 1 7.475-7.466h73.54v3.044H20.59c-2.473-.058-4.485 1.952-4.485 4.422Z"
      fill="#9BD79B"
    />
    <path
      className="bookCover-1"
      d="M105.055 66.57a7.456 7.456 0 0 1-7.475 7.466H24.039v-3.044h40.077c2.357-.115 4.255-2.068 4.255-4.422v-.288a4.497 4.497 0 0 0-4.14-4.135H24.097v-3.044H97.637c4.025 0 7.418 3.331 7.418 7.467Z"
      fill="#919191"
    />
    <path
      className="bookPages-1"
      d="M68.314 66.57c0 2.354-1.898 4.307-4.255 4.422H26.972v-8.846h37.201a4.497 4.497 0 0 1 4.14 4.136v.287Z"
      fill="#E3E3E3"
    />
    <path
      className="bookPages-2"
      d="M91.428 77.138H20.417c-2.472.114-4.37 2.125-4.255 4.594.058 2.24 1.84 4.079 4.083 4.25H91.428v-8.844Z"
      fill="#D8F4D8"
    />
    <path
      className="sparkle-1"
      d="M88.07 21.951a1.179 1.179 0 0 1-1.07.651c-.462 0-.879-.253-1.07-.651l-2.922-6.037-6.325-2.79A1.118 1.118 0 0 1 76 12.102c0-.44.265-.838.683-1.021l6.325-2.79 2.922-6.037A1.179 1.179 0 0 1 87 1.603c.462 0 .879.253 1.07.651l2.922 6.038 6.325 2.789c.418.183.683.58.683 1.021 0 .441-.265.84-.683 1.022l-6.325 2.79-2.922 6.037Z"
      fill="#64B964"
    />
    <g clipPath="url(#a)">
      <path
        className="sparkle-2"
        d="M71.035 39.027a.59.59 0 0 1-.535.325.59.59 0 0 1-.535-.325l-1.461-3.019-3.163-1.395a.56.56 0 0 1-.341-.51c0-.22.133-.42.341-.511l3.163-1.395 1.46-3.019a.59.59 0 0 1 .536-.326c.23 0 .44.127.535.326l1.461 3.02 3.163 1.394a.56.56 0 0 1 .341.51.56.56 0 0 1-.341.512l-3.163 1.394-1.46 3.019Z"
        fill="#9BD79B"
      />
    </g>
    <path
      className="sparkle-3"
      d="M113.23 54.578a.801.801 0 0 1-.73.462.801.801 0 0 1-.73-.462l-1.992-4.276-4.313-1.976a.794.794 0 0 1 0-1.447l4.313-1.976 1.992-4.276a.801.801 0 0 1 .73-.462c.315 0 .599.18.73.462l1.992 4.276 4.313 1.976a.794.794 0 0 1 0 1.447l-4.313 1.976-1.992 4.276Z"
      fill="#E3E3E3"
    />
    <g clipPath="url(#b)">
      <path
        className="sparkle-4"
        d="M5.487 67.027a.533.533 0 0 1-.487.326c-.21 0-.4-.127-.487-.326l-1.328-3.019L.31 62.614a.563.563 0 0 1-.31-.511c0-.22.12-.42.31-.511l2.875-1.395 1.328-3.019A.533.533 0 0 1 5 56.853c.21 0 .4.126.487.325l1.328 3.02 2.875 1.394c.19.091.31.29.31.51 0 .22-.12.42-.31.512l-2.875 1.394-1.328 3.019Z"
        fill="#9BD79B"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M65 40.102h11v-12H65z" fill="#fff" />
      </clipPath>
      <clipPath id="b">
        <path d="M0 68.103h10v-12H0z" fill="#fff" />
      </clipPath>
    </defs>
  </StyledIcon>
);

const StyledIcon = styled(Icon)`
  width: 120px;
`;
