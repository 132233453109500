import _ from 'lodash';
import PhraseSuggestion from 'models/phrase_suggestion';
import qconsole from 'scripts/lib/qconsole';
export default class PhraseSuggestionsGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.phraseSuggestions;
  }

  handleFetchSuccess({ suggestions = [] } = {}) {
    const isLoading = this.store.isLoading();
    if (isLoading && suggestions) {
      this.store.set(_.map(suggestions, s => PhraseSuggestion.fromJs(s)));
      this.store.resetLoading();
    }
  }

  handleFetchError(errorDto) {
    const isLoading = this.store.isLoading();
    if (isLoading) {
      this.store.resetLoading();
    }
    qconsole.log(`Could not fetch phraseSuggestions ${JSON.stringify(errorDto)}`);
  }
}
