import _ from 'lodash';
import { convertFromHTML as fromHTML, convertToHTML as toHTML } from 'draft-convert';

import { ENTITY_TYPE_ANSWER } from './draft_answer';
import { ENTITY_TYPE_PLACEHOLDER } from './draft_placeholder';
import { IMMUTABLE, MUTABLE } from './draft_shared';
import { ENTITY_TYPE_VARIABLE } from './draft_variable';

export const fromOptions = ({ answers, variables, isEditingSnippets }) => {
  return {
    htmlToBlock: (nodeName, node) => {
      if (nodeName === 'pre') {
        return { type: 'unstyled' };
      } else if (nodeName === 'li') {
        return { type: 'unstyled' };
      }
    },
    htmlToStyle: (nodeName, node, currentStyle) => {
      // DraftConvert by default includes these styles
      if (nodeName === 'b') {
        return currentStyle.remove('BOLD');
      } else if (nodeName === 'i') {
        return currentStyle.remove('ITALIC');
      } else if (nodeName === 'u') {
        return currentStyle.remove('UNDERLINE');
      } else if (nodeName === 'code') {
        return currentStyle.remove('CODE');
      }
      return currentStyle;
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      switch (nodeName) {
        case 'placeholder': {
          return createEntity(ENTITY_TYPE_PLACEHOLDER, isEditingSnippets ? MUTABLE : IMMUTABLE);
        }
        case 'variable': {
          const variable = _.find(variables, candidate => candidate.id === node.getAttribute('data-id'));
          if (variable) {
            const { id, name, isDisabled } = variable;
            return createEntity(ENTITY_TYPE_VARIABLE, IMMUTABLE, { id, name, isDisabled });
          }
          break;
        }
        case 'answer': {
          const answer = _.find(answers, candidate => candidate.id === node.getAttribute('data-id'));
          if (answer) {
            const { id, name } = answer;
            return createEntity(ENTITY_TYPE_ANSWER, IMMUTABLE, { id, name, isEditingSnippets });
          }
          return createEntity(ENTITY_TYPE_ANSWER, IMMUTABLE, {});
        }
      }
    },
  };
};

export function convertFromHTML(html, { variables, isEditingSnippets } = { variables: [], isEditingSnippets: false }) {
  const options = fromOptions({ variables, isEditingSnippets });
  return fromHTML(options)(html || '', { flat: true });
}

/**
 * editorEntities are entities that are visible while editing, but when the HTML gets sent to the customer, they should
 * _not_ be visible.
 *
 * In other words, the variable HTML should not make it to the customer, but links always should.
 */
export const toOptions = includeEditorEntities => {
  return {
    blockToHTML: block => {
      const type = block.type;
      if (type === 'pre' || type === 'unstyled') {
        return {
          start: '<div>',
          end: '</div>',
          empty: '<div><br/></div>',
        };
      }
    },
    styleToHTML: {
      CODE: { start: '', end: '' },
      BOLD: undefined,
      ITALIC: undefined,
      UNDERLINE: undefined,
    },
    entityToHTML: (entity, text) => {
      switch (entity.type) {
        case ENTITY_TYPE_PLACEHOLDER:
          return includeEditorEntities
            ? {
                start: '<placeholder>',
                end: '</placeholder>',
              }
            : text;
        case ENTITY_TYPE_VARIABLE:
          return includeEditorEntities
            ? {
                start: `<variable data-id="${entity.data.id}">`,
                end: '</variable>',
              }
            : text;
        case ENTITY_TYPE_ANSWER:
          return includeEditorEntities
            ? {
                start: `<answer data-id="${entity.data.id}">`,
                end: `</answer>`,
              }
            : text;
        default:
          return text;
      }
    },
  };
};

export function convertToHTML(editorState, includeEditorEntities = true) {
  const options = toOptions(includeEditorEntities);
  return toHTML(options)(editorState.getCurrentContent());
}
