import _ from 'lodash';

import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import Conversation from 'models/conversation';
import Factory from 'factories/all';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import InteractionType from 'models/interaction_type';
import qconsole from 'scripts/lib/qconsole';

export default class IncomingFbMessageSimulator extends CommunicationSimulator {
  constructor({ orgId, publisher, services }, database) {
    super(
      { attribute: 'incomingFbMessages', createItem: createFbMessageIncomingItem, orgId, publisher, services },
      database
    );
  }

  _getCustomer(communication) {
    let customer = this._findCustomer(communication);
    if (!customer) {
      customer = this._createCustomer(communication);
      this.database.get().customers.push(customer);
    }
    return customer;
  }

  _findCustomer(communication) {
    let userId = communication.userId;
    let customer = _.find(this.database.get().customers, { profile: { fbMessengerUserIds: [{ userId }] } });
    if (!customer) {
      qconsole.log(`Could not find customer with Facebook Messenger user id [${userId}]`);
    }
    return customer;
  }

  _createCustomer(communication) {
    let userId = communication.userId;
    let customer = Factory.build('Customer', {
      id: IdGenerator.newId(),
      profile: Factory.attributes('CustomerProfile', {
        fbMessengerUserIds: [{ pageId: communication.pageId, userId }],
      }),
    });
    qconsole.log(`Created new customer [${customer.id}] with Facebook Messenger user id [${userId}]`);
    return customer;
  }

  _createAssignee(communication) {
    let routingGroupId = this._getRoutingGroupId(communication);
    if (!routingGroupId) {
      return undefined;
    }

    return Factory.build('Assignee', {
      routingGroupId,
      agentId: this.database.get().currentAgent.id,
    });
  }

  _getRoutingGroupId(communication) {
    let communicationQueue = _.find(this.database.get().communicationQueues, q =>
      _.find(_.get(this.database.get(), 'channelConfiguration.endpoints'), {
        id: q.endpointId,
        type: 'FB_MESSENGER',
      })
    );

    if (!communicationQueue) {
      qconsole.warn(
        'Could not simulate incoming Facebook message. No communication queue found for Facebook Messenger.'
      );
      return undefined;
    }

    return communicationQueue.routingGroupId;
  }

  _findConversation(communication, customer) {
    let lastConversation = _.last(customer.conversations);
    if (lastConversation && isActive(lastConversation)) {
      return lastConversation;
    }
  }

  _createRoutingItem(attrs) {
    return Factory.build(
      'RoutingItemForInbound',
      this._getRoutingItemAttrs({ channel: InteractionType.FB_MESSENGER, ...attrs })
    );
  }
}

function createFbMessageIncomingItem(communication, customerId) {
  return Factory.build('FbMessageIncomingItemWithDefaults', {
    initiator: {
      type: Communicator.CUSTOMER,
      id: customerId,
    },
    content: Factory.attributes('FbMessageIncomingWithDefaults', {
      message: Factory.attributes('FbMessageWithText', { text: communication.text }),
      recipient: Factory.attributes('FbPageWithDefaults', { id: communication.pageId }),
      sender: Factory.attributes('FbMessengerUserWithDefaults', { id: communication.userId }),
    }),
  });
}

function isActive(conversation) {
  return conversation.status !== Conversation.Status.CLOSED;
}
