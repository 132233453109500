import Account from 'models/account';
import qconsole from 'scripts/lib/qconsole';

export default class PublicAccountGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchAllSuccess(publicAccountDtos) {
    this.context.stores.accounts.resetLoading();
    if (publicAccountDtos) {
      publicAccountDtos.forEach(dto => {
        const account = Account.fromJs(dto);
        this.context.stores.accounts.addOrReplace(account);
      });
    }
  }

  onFetchAllError(error) {
    qconsole.error('error fetching public accounts', error);
  }
}
