import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import { after } from 'scripts/infrastructure/backends/http_client';

export default class ConversationWorkflowConfigGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
  }

  init({ orgId }) {
    this.orgId = orgId;
  }

  get broadcastTopic() {
    return `v${this.version}/orgs/${this.orgId}/configuration/conversation-workflow`;
  }

  reconnectHandler() {
    if (this._isSubscribed) {
      this.request();
    }
  }

  get version() {
    return '1';
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleEntity', envelope.payload);
  }

  /* HTTP */

  get _httpClient() {
    return this.backend.axios();
  }

  request() {
    this._isSubscribed = true;
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.get.bind(this));
  }

  get() {
    return after(
      this._httpClient.get(`/api/v${this.version}/orgs/${this.orgId}/configuration/conversation-workflow`),
      this.onFetch.bind(this)
    );
  }

  onFetch(err, res) {
    if (err) {
      this.notifyObservers('handleRequestError', err);
      return;
    }
    if (res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleEntity', res.data);
    } else {
      this.notifyObservers('handleRequestError', res.data);
    }
  }
}

mixin(ConversationWorkflowConfigGateway.prototype, Observable);
