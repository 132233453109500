import _ from 'lodash';
import Observable from 'scripts/lib/observable_mixin';
import { after } from 'scripts/infrastructure/backends/http_client';

export default class ConversationMessageGateway {
  constructor(backend) {
    this.backend = backend;
  }

  init({ orgId, agentId } = {}) {
    this.agentId = agentId;
    this.orgId = orgId;
  }

  get version() {
    return 1;
  }

  get _httpClient() {
    return this.backend.axios();
  }

  url() {
    return `/api/v${this.version}/orgs/${this.orgId}/agents/${this.agentId}/outgoing/conversation-message`;
  }

  add(outgoingMessage) {
    return after(this._httpClient.post(this.url(), outgoingMessage), this.handleAddResponse.bind(this));
  }

  handleAddResponse(err, res) {
    if (err) {
      this.notifyObservers('handleAddErrors', err);
      return;
    }
    if (res.status === 204) {
      this.notifyObservers('handleAddSuccess', res.data);
    } else {
      this.notifyObservers('handleAddErrors', res.data);
    }
  }
}

_.extend(ConversationMessageGateway.prototype, Observable);
