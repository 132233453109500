import _ from 'lodash';
import createModel, { prop } from 'models/lib/create_model';
import createEnum from 'scripts/lib/create_enum';

export const ActionType = createEnum('INITIATED', 'NAVIGATED');

export default createModel({
  modelName: 'ChatCustomerUrl',
  properties: {
    customerUrl: String,
    action: prop().oneOf(..._.keys(ActionType)),
  },

  getActionType() {
    if (this.action === '' || this.action === undefined) {
      return ActionType.INITIATED;
    }
    return this.action;
  },
});
