import { HistoryEditor } from 'slate-history';
import { Node, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { useEffect, useRef } from 'react';
import { usePlateEditorState } from '@udecode/plate';

// If we have both a greeting and a signature, we want the initial editor selection to be smack dab in the middle
// so that agents can just start typing.
// If we just have a signature, we want the initial editor selection to be at the beginning.
// If we just have a greeting, we want the initial editor selection to be at the end.
export default function SelectionInitializer({ paragraphIndex }) {
  const editor = usePlateEditorState();
  const hasInitializedSelection = useRef(false);

  useEffect(() => {
    if (!hasInitializedSelection.current && editor.children.length && paragraphIndex > -1) {
      const textEntries = Array.from(Node.texts(editor, { from: [paragraphIndex], to: [paragraphIndex + 1] }));
      const [, textPath] = textEntries[0];

      // Don't save this into the history so that an agent can't undo and put the cursor at the beginning or
      // something goofy like that.
      HistoryEditor.withoutSaving(editor, () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, {
          anchor: {
            path: textPath,
            offset: 0,
          },
          focus: {
            path: textPath,
            offset: 0,
          },
        });
      });
      hasInitializedSelection.current = true;
    }
  }, [editor.children]);

  return null;
}
