import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer className="backChevronIcon" viewBox="0 0 8 16" {...props}>
    <path
      d="M7.429 15.429L.57 8 7.43.571"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".9"
    />
  </IconContainer>
);
