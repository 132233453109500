import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import { BasicAuthCredentials } from './basic_credentials';

const Webhook = createModel({
  modelName: 'Webhook',
  properties: {
    id: prop(String).isRequired,
    name: prop(String),
    enabled: prop(Boolean),
    events: prop([String]).default([]),
    url: prop(String),
    hasCredentials: prop(Boolean),
    headersKeys: prop([String]).default([]),
    credentials: prop(BasicAuthCredentials),
    headers: prop(Object),
  },

  toggleStatus() {
    this.enabled = !this.enabled;
  },

  update(attrs) {
    _.assign(this, _.omit(attrs, ['id']));
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    overrideFromJs(fromJs) {
      return attrs => {
        let oldEvents = _.get(attrs, 'events', []);
        let events = _.map(oldEvents, event => {
          return event === 'CONVERSATION/CLOSE' ? 'CONVERSATION/CLOSED' : event;
        });
        return fromJs({ ...attrs, events });
      };
    },
  },
});

export default Webhook;
