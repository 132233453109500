import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';
import PropTypes from 'prop-types';

import ActionMixin from 'components/lib/action_mixin';
import AppFeaturesMixin from 'components/lib/app_features_mixin';
import KeyPressShortcutMixin from 'components/lib/keypress_shortcut_mixin';
import Search from 'actions/search/search';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';
import UniversalSearch from 'components/customer_combine/universal_search';

export default createReactClass({
  mixins: [ActionMixin, new KeyPressShortcutMixin(), AppFeaturesMixin, Morearty.Mixin, SharedBindingsMixin],

  propTypes: {
    filterHits: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSelectItem: PropTypes.func,
    placeholder: PropTypes.string,
  },

  handleSearch(searchResult) {
    this.executeAction(Search, searchResult);
  },

  render() {
    return (
      <UniversalSearch
        filterHits={this.props.filterHits}
        onSearch={this.handleSearch}
        onSearchChange={this.props.onSearchChange}
        onSelectItem={this.props.onSelectItem}
        placeholder={this.props.placeholder}
      />
    );
  },
});
