import classNames from 'classnames';
import React from 'react';
import T from 'prop-types';

import ChatIconOutline from 'components/lib/icons/chat_icon_outline';

const ConversationNotificationContent = props => {
  return (
    <div className="conversationNotification-topic assignedConversation-topic">
      {props.icon ? (
        props.icon
      ) : props.iconClass === 'icon-chat' ? (
        <ChatIconOutline className="conversationNotification-chat-icon" />
      ) : (
        <i className={classNames('conversationNotification-icon', props.iconClass)} />
      )}
      {props.itemType ? <div className="conversationNotification-itemType">{props.itemType}</div> : <div />}
      <div className="conversationNotification-divider" />

      {props.title && (
        <>
          <div className="conversationNotification-topic-heading">{props.title}</div>
          <div className="conversationNotification-divider" />
        </>
      )}

      <div className="conversationNotification-topic-heading">{props.heading}</div>
      {props.body ? <div className="conversationNotification-divider" /> : null}
      {props.body ? <div className="conversationNotification-topic-body">{props.body}</div> : null}
    </div>
  );
};

ConversationNotificationContent.propTypes = {
  body: T.string,
  title: T.string,
  heading: T.string.isRequired,
  icon: T.node,
  iconClass: T.string.isRequired,
  itemType: T.string,
};

export default ConversationNotificationContent;
