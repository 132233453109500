import _ from 'lodash';

const getErrorMessage = ({ attr, errors, suppressErrors }) => {
  if (suppressErrors || !errors || !errors.length) {
    return null;
  }

  const error = getErrorByAttribute(attr, errors);
  return error ? error.detail : null;
};

export function getErrorMessageList({ attr, errors, suppressErrors }) {
  if (suppressErrors || !errors || !errors.length) {
    return null;
  }

  const errorList = getErrorListByAttribute(attr, errors);
  return errorList ? _.map(errorList, 'detail') : null;
}

export function hasError(attr, errors = []) {
  return !!getErrorByAttribute(attr, errors);
}

function getErrorByAttribute(attr, errors = []) {
  const errs = getErrorListByAttribute(attr, errors);
  return errs ? _.head(errs) : null;
}

function getErrorListByAttribute(attr, errors = []) {
  const errs = errors.filter(error => {
    return error.attr === attr;
  });

  return errs.length ? errs : null;
}

export default getErrorMessage;
