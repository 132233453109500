import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';
import styled from 'styled-components';

import ActionMixin from 'components/lib/action_mixin';
import AddCustomerButton from 'components/customer/add_customer_button';
import AgentHomeIllustration from './agent_inbox/agent_home_illustration';
import AgentInbox from './agent_inbox';
import connect from 'components/lib/connect';
import PageLayout from './page_layout';
import Spinner from 'components/common/spinner_two';
import TeamInboxesMenu from './agent_inbox/team_inboxes_menu';

const AgentHome = createReactClass({
  mixins: [ActionMixin, Morearty.Mixin],

  /* rendering */

  render() {
    if (this.props.isLoading) {
      return (
        <LoadingPageLayout>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </LoadingPageLayout>
      );
    }

    if (this.props.isGroupInboxesEnabled) {
      return (
        <StyledPageLayout className="agentHome-pageLayout" id="agent-home">
          <div className="agent-home-body">
            {this.renderSideNav()}
            {this.renderInbox()}
          </div>
        </StyledPageLayout>
      );
    }

    return (
      <HomePageLayout className="agentHome-pageLayout" id="agent-home">
        <AgentHomeIllustration />
      </HomePageLayout>
    );
  },

  renderSideNav() {
    if (!this.props.isGroupInboxesEnabled) {
      return null;
    }

    let teamInboxCounts = this.getDefaultBinding().get('teamInboxCounts');

    return (
      <div className="agentHome-sidenav-col">
        <TeamInboxesMenu teamInboxCounts={teamInboxCounts} />
      </div>
    );
  },

  renderInbox() {
    if (this.props.isGroupInboxesEnabled) {
      return (
        <div className="agentHome-inbox main-column">
          <div className="agentHome-inbox-container">
            <AgentInbox />
            <AddCustomerButton />
          </div>
        </div>
      );
    }

    return null;
  },
});

const AgentHomeContainer = connect(mapStateToProps)(AgentHome);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  return {
    currentLocation: getProvider('currentLocation').get(),
    isGroupInboxesEnabled: isFeatureEnabled('groupInboxes'),
    isLoading: getProvider('routingGroups').isLoading(),
  };
}

export { AgentHome };

const SpinnerContainer = styled.div`
  height: 64px;
  width: 64px;
`;

const StyledPageLayout = styled(PageLayout)`
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  min-width: 1000px;
  width: auto;
`;

const LoadingPageLayout = styled(StyledPageLayout)`
  background-color: ${p => p.theme.colors.gray100};
  min-width: inherit;
  .page-childrenContainer {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    height: 100%;
  }
`;

const HomePageLayout = styled(StyledPageLayout)`
  background-color: ${p => p.theme.colors.gray100};
  min-width: inherit;
`;

export default AgentHomeContainer;
