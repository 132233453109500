export default class CheckVersion {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.gateways.serverInfo.fetch();
  }

  static get jobId() {
    return 'checkVersion';
  }
}
