import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import GeneralTransaction from 'components/customer/summary/transactions/general_transaction';
import { OrderAttributesPropTypes } from 'components/customer/summary/transactions/constants';
import { OrderTransactionDef } from 'models/configuration/transaction_def';
import OrderSummary from 'components/customer/summary/transactions/order_summary';
import ProfileCardDef from 'models/configuration/profile_card_def';

export default function OrderTransactions({ isLoading, transactionDef, transactions, onExpand, profileCardDef }) {
  const orders = _.orderBy(transactions, [order => moment(order.attributes.createdAt).valueOf()], ['desc']);
  const numOrders = orders.length;
  const haveImages = _.some(transactions, tx => {
    const products = tx.attributes?.products || [];
    return _.some(products, product => {
      const url = _.trim(product.imageUrl);
      return url && url.toLowerCase() !== 'none';
    });
  });

  return (
    <ExpandableProfileCard
      data-aid="expandable-order-transaction"
      isEmpty={!numOrders}
      isLoading={isLoading}
      limit={3}
      title={_.get(profileCardDef, 'label') || `${numOrders} Order${numOrders === 1 ? '' : 's'}`}
    >
      {renderTransactions(orders, haveImages)}
    </ExpandableProfileCard>
  );

  /**
   * Finds the first product that has a valid `imageUrl` and returns that Url. Otherwise, returns empty string
   */
  function findFeaturedImage({ products }) {
    const productWithImage = _.find(products || [], product => product.imageUrl && product.imageUrl !== 'none');
    return productWithImage?.imageUrl || '';
  }

  function renderTransactions(orderList, shouldRenderImages) {
    return _.map(orderList, (order, idx) => {
      return (
        <GeneralTransaction
          attributes={order.attributes}
          className="row-separator"
          isExpanded={idx === 0}
          key={`general-transaction-${idx}`}
          onExpand={onExpand}
          shouldRenderImages={shouldRenderImages}
          summaryRenderer={summaryRenderer}
          transactionDef={transactionDef}
        />
      );
    });
  }

  /**
   * Renders "order summary" row for a given transaction (order)
   *
   * @param {Object} attributes - transaction attributes
   * @param {Object} [params] - optional "parameters", such as `onClick` handler etc. This argument is only used by
   * the "V2" transaction renderers and will be omitted by the "legacy" Transactions components
   * @returns {JSX.Element}
   */
  function summaryRenderer(attributes, params) {
    const { onClick, shouldRenderImage } = params || {};
    const featuredImage = shouldRenderImage ? findFeaturedImage(attributes) : '';

    return (
      <OrderSummary
        attributes={attributes}
        featuredImage={featuredImage}
        onClick={onClick}
        shouldRenderImage={shouldRenderImage}
      />
    );
  }
}

OrderTransactions.propTypes = {
  isLoading: PropTypes.bool,
  onExpand: PropTypes.func,
  profileCardDef: PropTypes.instanceOf(ProfileCardDef),
  transactionDef: PropTypes.instanceOf(OrderTransactionDef),
  transactions: PropTypes.arrayOf(PropTypes.shape({ attributes: OrderAttributesPropTypes })),
};
