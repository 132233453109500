import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import analytics from 'scripts/lib/analytics';
import connect from 'components/lib/connect';
import ErrorTooltip from './error_tooltip';
import Err from 'models/err';
import MergeProfile from 'models/modal/merge_profile';
import { normalizeEmailAddress } from 'models/customer_email_address';
import { normalizePhoneNumber } from 'models/phone_number';
import OpenModal from 'actions/modal/open_modal';
import ShowMatches from 'actions/customer/show_matches';
import Tooltip from 'components/common/tooltip';
import TrackTooltipShown from 'actions/customer_match/track_tooltip_shown';

const MATCHING_TEXT = 'Matching customers found!';
const VIEW_MATCH = 'View matches';
const CLEAR_ENTRY = 'Clear entry';

/**
 * MergeableErrorTooltip will, if one of the errors is a TAKEN error, display a special tooltip
 * which will allow users to merge the customer that already possesses that piece of contact
 * information.
 */
export class MergeableErrorTooltip extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClickClear = this.handleClickClear.bind(this);
    this.handleClickViewMatch = this.handleClickViewMatch.bind(this);
  }

  componentDidMount() {
    if (this.props.showTooltip && this.props.onTooltipRender) {
      this.props.onTooltipRender(this.props.customerId);
    }
  }

  componentDidUpdate(prevProps) {
    const hasCustomerChanged = this.props.customerId !== prevProps.customerId;
    // if we weren't showing the tooltip before but are now
    if (this.props.showTooltip && (hasCustomerChanged || !prevProps.showTooltip)) {
      if (this.props.onTooltipRender) {
        this.props.onTooltipRender(this.props.customerId);
      }
    }
  }

  render() {
    const { children, className, 'data-aid': aid, errors, isModalOpen, showClearButton, showTooltip } = this.props;
    if (!showTooltip) {
      return (
        <ErrorTooltip className={className} data-aid={aid} errors={errors}>
          {children}
        </ErrorTooltip>
      );
    }

    const message = (
      <div className="contactField-mergeableErrorTooltip-matchingCustomer">
        <span>{MATCHING_TEXT}</span>
        <span className="contactField-mergeableErrorTooltip-matchingCustomer-actions">
          <span
            className="contactField-mergeableErrorTooltip-matchingCustomer-action contactField-mergeableErrorTooltip-matchingCustomer-action-viewMatch"
            onClick={this.handleClickViewMatch}
            onMouseDown={preventDefault}
          >
            {VIEW_MATCH}
          </span>
          {showClearButton && (
            <span
              className="contactField-mergeableErrorTooltip-matchingCustomer-action contactField-mergeableErrorTooltip-matchingCustomer-action-clearEntry"
              onClick={this.handleClickClear}
              onMouseDown={preventDefault}
            >
              {CLEAR_ENTRY}
            </span>
          )}
        </span>
      </div>
    );

    return (
      <Tooltip
        className={classnames(this.props.className, 'contactField-mergeableErrorTooltip')}
        forceVisible={!isModalOpen}
        margin={this.props.margin || 36}
        message={message}
        position="right"
      >
        {children}
      </Tooltip>
    );
  }

  handleClickClear(evt) {
    preventDefault(evt);
    this.props.onClear();
  }

  handleClickViewMatch(evt) {
    preventDefault(evt);
    this.props.onOpenModal();
  }
}

MergeableErrorTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  customerId: PropTypes.string,
  'data-aid': PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)),
  isModalOpen: PropTypes.bool,
  margin: PropTypes.number,
  onClear: PropTypes.func,
  onOpenModal: PropTypes.func.isRequired,
  onTooltipRender: PropTypes.func,
  showClearButton: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

const MergeableErrorTooltipContainer = connect(mapStateToProps, mapExecuteToProps)(MergeableErrorTooltip);
export default MergeableErrorTooltipContainer;

MergeableErrorTooltipContainer.propTypes = {
  attr: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)),
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function mapStateToProps({ getProvider }, props) {
  const isModalOpen = !!getProvider('modal').get();

  return {
    ...props,
    isModalOpen,
    showTooltip: !!_.find(props.errors, err => err.code === Err.Code.TAKEN && _.get(err, 'meta.customerId')),
    showClearButton: true,
  };
}

function mapExecuteToProps(executeAction, props) {
  const mergeableError = _.find(props.errors, err => err.code === Err.Code.TAKEN && _.get(err, 'meta.customerId'));
  const takenCustomerId = _.get(mergeableError, 'meta.customerId');
  let split, index;
  if (takenCustomerId) {
    split = mergeableError.attr.split('.');
    index = Number.parseInt(split[1]);
  }

  return {
    onClear: () => {
      analytics.track('Customer Match Tooltip Clear Selected', {
        customerId: props.customerId,
        location: 'customerContactCard',
        attr: props.attr,
        index,
      });
      props.onClear && props.onClear();
    },
    onOpenModal: () => {
      if (!takenCustomerId) {
        return;
      }

      let value = props.value;
      if (props.attr === 'phone') {
        try {
          value = normalizePhoneNumber(value);
        } catch (error) {
          // No big deal, just render it how it is
        }
      } else if (props.attr === 'email') {
        value = normalizeEmailAddress(value);
      }

      analytics.track('Customer Match Tooltip View Matches Selected', {
        customerId: props.customerId,
        location: 'customerContactCard',
        attr: props.attr,
        index,
      });

      executeAction(
        OpenModal,
        new MergeProfile({
          customerId: takenCustomerId,
          pendingDetail: {
            [props.attr]: value,
            index,
          },
        })
      );
    },
    onTooltipRender: () => {
      executeAction(TrackTooltipShown, {
        customerId: props.customerId,
        location: 'customerContactCard',
        attr: props.attr,
        index,
      });
      props.onTooltipRender && props.onTooltipRender();
    },
  };
}

export const MatchTooltipContainer = connect(matchMapStateToProps, matchMapExecuteToProps)(MergeableErrorTooltip);

MatchTooltipContainer.propTypes = {
  children: PropTypes.node,
};

function matchMapStateToProps({ getProvider }, { className, children, customerId }) {
  const customerMatchProvider = getProvider('customerMatch');
  const isModalOpen = !!getProvider('modal').get();

  return {
    children,
    className,
    customerId,
    isModalOpen,
    showTooltip: customerMatchProvider.get() && customerMatchProvider.get().profiles.length > 0,
    showClearButton: false,
  };
}

function matchMapExecuteToProps(executeAction, { customerId }) {
  return {
    onTooltipRender: nextCustomerId => {
      executeAction(TrackTooltipShown, { customerId: nextCustomerId, location: 'customerSummaryCard' });
    },
    onOpenModal: () => {
      analytics.track('Customer Match Tooltip View Matches Selected', {
        customerId,
        location: 'customerSummaryCard',
      });
      executeAction(OpenModal, new MergeProfile());
      executeAction(ShowMatches, customerId);
    },
  };
}

function preventDefault(evt) {
  evt.preventDefault();
  evt.stopPropagation();
}
