import createModel, { prop } from 'models/lib/create_model';

const UnreadNotifications = createModel({
  modelName: 'UnreadNotifications',

  properties: {
    numUnread: prop(Number).isRequired,
  },
});

export default UnreadNotifications;
