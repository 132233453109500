import { createQuotedEmailTextPlain, createQuotedEmailTextHtml } from './lib/create_quoted_email_text';
import CreateEmailComposition from './create_email_composition';
import EmailCompositionContent from 'models/composition/email_composition_content';

export default class ReplyToEmail extends CreateEmailComposition {
  constructor(context) {
    super(context, EmailCompositionContent);
  }

  _allowDuplicate() {
    return true;
  }

  _createContent({ conversation, item }) {
    let froms = this._getPotentialFroms(conversation);
    let from = this._getReplyFrom(item, froms);
    let recipients = this._getReplyRecipients(item, froms);

    return new EmailCompositionContent({
      from,
      to: recipients.to,
      cc: recipients.cc,
      bcc: recipients.bcc,
      subjectHtml: `<div>${this._getReplySubject(item)}</div>`,
      headers: { 'in-reply-to': item.content.getHeader('message-id') },
      inlineAttachments: item.content.inlineAttachments(),
      quotedPlain: createQuotedEmailTextPlain(item),
      quotedHtml: createQuotedEmailTextHtml(item),
    });
  }
}
