import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import NavigateToSearchResults from 'actions/search/navigate_to_search_results';
import Paginate from 'components/common/paginate';
import { MAX_RESULT_INDEX } from 'models/search/search_result';

const UniversalSearchPaginateContainer = connect(mapStateToProps, mapExecuteToProps)(Paginate);

UniversalSearchPaginateContainer.propTypes = {
  queryId: PropTypes.string,
};

function mapStateToProps(context, props) {
  let from, size;
  let total = 0;
  let result = null;
  let searchResultId = props.queryId;
  if (searchResultId) {
    result = context.getProvider('universalSearchResults').findBy({ id: searchResultId });
    let currentQuery = result && result.query;
    from = currentQuery.from;
    size = currentQuery.size;
    if (result.aggregations) {
      total = Math.min(result.aggregations.types.buckets[result.query.filter.type], MAX_RESULT_INDEX);
    }
  }
  return {
    from,
    isLoading: result ? result.isLoading : true,
    size,
    total,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onChange: (from, size) => executeAction(NavigateToSearchResults, { from, size }),
  };
}

export default UniversalSearchPaginateContainer;
