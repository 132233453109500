import { animated, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import theme from 'scripts/presentation/themes/default';
import ProfileAvatar from 'components/lib/profile_avatar';
import RelativeDateTime from 'components/lib/date/relative_date_time';
import SafeHtml from 'components/common/safe_html';
import SetSelectedCommentId from 'actions/task/set_selected_comment_id';
import TimestampFormatter from 'components/lib/date/lib/timestamp_formatter';
import Tooltip from 'components/common/tooltip';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useWindowSize from 'components/hooks/use_window_size';

export function TaskComment({
  agentAvatarUrl,
  agentName,
  className,
  createdAt,
  currentAgentId,
  id,
  message,
  selectedAt,
}) {
  const ref = useRef(null);
  const executeAction = useExecuteAction();

  // Will highlight the comment with a green background when selectedAt gets set
  const [styles, springApi] = useSpring(() => ({
    backgroundColor: theme.colors.white,
    config: {
      duration: 2000,
    },
  }));

  // If this item becomes selected, scroll to it, then unselect it (it's just a momentary thing so we can
  // scroll to it!)
  useLayoutEffect(() => {
    if (selectedAt) {
      ref.current.scrollIntoView({
        block: 'center',
      });
      executeAction(SetSelectedCommentId, { id: null });

      springApi.start({
        from: { backgroundColor: theme.colors.green100 },
        to: { backgroundColor: theme.colors.white },
      });
    }
  }, [executeAction, selectedAt, springApi]);

  function renderAvatar() {
    return (
      <StyledAvatar data-aid="taskComment-avatar" noWrapper profile={{ name: agentName, image: agentAvatarUrl }} />
    );
  }

  function renderDetail() {
    return (
      <StyledCommentDetail $currentAgentId={currentAgentId}>
        <StyledNameRow>
          <StyledName data-aid="taskComment-content-name">
            <bdi>{agentName || 'Agent'}</bdi>
          </StyledName>
          <Date timestamp={createdAt} />
        </StyledNameRow>
        <bdi>
          <StyledMessage data-aid="taskComment-content-message" html={message} setDirection />
        </bdi>
      </StyledCommentDetail>
    );
  }

  return (
    <StyledComment as={animated.div} className={className} data-aid="taskComment" data-id={id} ref={ref} style={styles}>
      {renderAvatar()}
      {renderDetail()}
    </StyledComment>
  );
}

TaskComment.propTypes = {
  agentAvatarUrl: PropTypes.string,
  agentName: PropTypes.string,
  className: PropTypes.string,
  createdAt: PropTypes.string,
  currentAgentId: PropTypes.string,
  id: PropTypes.string,
  message: PropTypes.string,
  selectedAt: PropTypes.string,
};

function mapStateToProps({ getProvider }, { initiatingAgentId }) {
  const agentsProvider = getProvider('agents');
  const agent = agentsProvider.findBy({ id: initiatingAgentId });

  return {
    agentName: agent ? agent.getDisplayName() : 'An agent',
    agentAvatarUrl: agent ? agent.avatarUrl : '',
    currentAgentId: getProvider('currentAgent').get().id,
  };
}

const TaskCommentContainer = connect(mapStateToProps)(TaskComment);

TaskCommentContainer.propTypes = {
  initiatingAgentId: PropTypes.string.isRequired,
};

export const StyledAvatar = styled(ProfileAvatar)`
  height: 35px;
  width: 35px;
`;

const StyledName = styled.div`
  font-weight: 600;
`;

const StyledCommentDetail = styled.div`
  flex-grow: 1;
  margin-left: ${p => p.theme.spacing.medium};

  agentmention {
    background-color: ${p => p.theme.colors.gray100};
    border-radius: 4px;
    color: ${p => p.theme.colors.green400};
    font-weight: bold;
    padding: 4px;

    &:hover {
      background-color: ${p => p.theme.colors.gray200};
    }

    &[data-agentid="${p => p.$currentAgentId}"], &[data-agentid="${p => p.$currentAgentId}"]:hover {
      background-color: ${p => p.theme.colors.yellow200};
    }
  }
`;

const StyledNameRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDate = styled(RelativeDateTime)`
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  display: none;
  margin-left: 8px;

  &:hover {
    color: ${p => p.theme.colors.gray700};
    text-decoration: underline;
  }
`;

export const StyledMessage = styled(SafeHtml)`
  word-break: break-word;

  ol,
  ul {
    margin: 4px 0;
    padding-inline-start: 16px;
  }
`;

const StyledComment = styled.div`
  display: flex;
  padding: ${p => p.theme.spacing.medium} 16px;

  &:hover {
    ${StyledDate} {
      display: block;
    }
  }
`;

const Date = React.memo(function({ timestamp }) {
  let tf = new TimestampFormatter(timestamp);
  let fullTime = tf.full();

  const { windowHeight, windowWidth } = useWindowSize();
  const bounds = {
    bottom: windowHeight - 68,
    left: 8,
    right: windowWidth - 8,
    top: 68,
  };

  return (
    <Tooltip
      bounds={bounds}
      delay={300}
      hoverableTooltip
      margin={5}
      message={<span>{fullTime}</span>}
      position="top"
      style={{ padding: '4px 8px' }}
    >
      <StyledDate data-aid="taskComment-content-timestamp" timestamp={timestamp} title="" />
    </Tooltip>
  );
});

export default TaskCommentContainer;
