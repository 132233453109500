import _ from 'lodash';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';

import ActionMixin from 'components/lib/action_mixin';
import AppFeaturesMixin from 'components/lib/app_features_mixin';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';
import UpdateConversationItem from 'actions/conversation_item/update_conversation_item';

export function createWavePlayerContainer(mixin) {
  return createReactClass(
    _.extend(
      {
        mixins: [ActionMixin, AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

        render() {
          return this.renderVoiceRecordingContainer();
        },

        renderVoiceRecordingContainer() {
          throw new Error('Concrete class must implement `renderVoiceRecordingContainer`');
        },

        saveRecordingDuration(recordingDuration) {
          let recordingDurationSeconds = _.floor(recordingDuration);
          if (!this.props.duration) {
            this.executeAction(UpdateConversationItem, {
              conversationItemId: this.props.item.id,
              customerId: this.getProvider('currentLocation').get().customerId,
              itemAttrs: { content: { recordingDuration: recordingDurationSeconds } },
            });
          }
        },

        getBaseRecordingUrl() {
          let orgId = this.getProvider('auth')
            .get()
            .getOrgId();
          return `${window.location.origin}/api/v1/orgs/${orgId}/voice`;
        },
      },
      mixin
    )
  );
}
