import _ from 'lodash';

import connect from 'components/lib/connect';
import ExpandTransaction from 'actions/customer_profile/expand_transaction';
import FlightTransactions from 'components/customer/summary/transactions/flight_transactions';

function mapStateToProps({ getProvider }, { profileCardDef }) {
  let profileDefProvider = getProvider('customerProfileDef');
  let profileProvider = getProvider('profile');

  const isLinked = !!_.get(profileProvider.get(), 'externalCustomerId');
  const isLoading = profileProvider.isLoading() || profileDefProvider.isLoading();
  const profileDef = profileDefProvider.get();

  return {
    isLinked,
    isLoading,
    transactionDef: profileDef && profileDef.transaction,
    transactions: _.get(profileProvider.get(), 'customAttributes.transactions') || {},
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onExpand: ({ isExpanded }) => executeAction(ExpandTransaction, { isExpanded }),
  };
}

const CustomerFlightTransactionsContainer = connect(mapStateToProps, mapExecuteToProps)(FlightTransactions);
export default CustomerFlightTransactionsContainer;
