import _ from 'lodash';

import createModel, { prop } from '../lib/create_model';

export const AggregationLevel = Object.freeze({
  QUARTER_HOUR: 'quarterHourly',
  HALF_HOUR: 'halfHourly',
  DAY: 'daily',
  WEEK: 'weekly',
  MONTH: 'monthly',
  QUARTER: 'quarterly',
});

export const ReportConfig = createModel({
  modelName: 'ReportConfig',
  properties: {
    metricSetName: prop(String),
    title: prop(String),
    categoryId: prop(String),
    urlSlug: prop(String),
    filters: prop([String]).default([]),
    timeAggregation: prop([String]),
    demoExcluded: prop(Boolean), // demoExcluded: used to indicate that this report should not be shown for any org with `demoMode` set true
    demoOf: prop(String), // demoOf: used to indicate that this report should only be included in orgs with `demoMode` set true.  any other report with a metricSetName that matches the value set here will be replaced with this one
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default createModel({
  modelName: 'ReportConfigs',
  properties: {
    configs: prop([ReportConfig]),
  },

  isEmpty() {
    return _.isEmpty(this.configs);
  },

  setConfigs(configs) {
    this.configs = configs;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const LegacyConfigs = [
  {
    metricSetName: 'TopicHierarchyReport',
    title: 'Topics',
    categoryId: 'Customer Experience',
    urlSlug: 'topics',
    filters: ['dateRange', 'timeAggregation', 'channel', 'inboxes', 'topics'],
    timeAggregation: ['daily', 'weekly', 'monthly', 'quarterly'],
  },
  {
    metricSetName: 'SummaryReport',
    title: 'Operational Summary',
    categoryId: 'Sunsetting',
    urlSlug: 'summary',
    filters: ['dateRange', 'timeAggregation', 'channel', 'inboxes'],
    timeAggregation: ['daily', 'weekly', 'monthly', 'quarterly'],
  },
  {
    metricSetName: 'IvrEndStatesReport',
    title: 'IVR',
    categoryId: 'Customer Experience',
    urlSlug: 'ivr-end-states',
    filters: ['dateRange', 'timeAggregation', 'phoneNumber'],
    timeAggregation: ['daily', 'weekly', 'monthly', 'quarterly'],
  },
  {
    metricSetName: 'IvrExecutiveSummaryReport',
    title: 'IVR',
    categoryId: 'Customer Experience',
    urlSlug: 'ivr-summary',
    filters: ['dateRange', 'timeAggregation', 'phoneNumber'],
    timeAggregation: ['daily', 'weekly', 'monthly', 'quarterly'],
  },
];

export const LegacyReportConfigs = LegacyConfigs.map(config => {
  return new ReportConfig(config);
});
