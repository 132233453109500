import _ from 'lodash';

import { EndpointTypes } from 'models/endpoint';

/**
 * Returns endpoint types from which the current agent can receive inbound communications regardless of routing groups
 *
 * @param { channelConfiguration, communicationQueues } - stores / providers
 * @returns {Array} of EndpointTypes
 */
export function getAgentChannels({ channelConfiguration, communicationQueues, customChannels }) {
  let agentCommQueues = communicationQueues.findAll();
  let endpoints = _.get(channelConfiguration.get(), 'endpoints', []);
  let currentAgentEndpoints = _.chain(agentCommQueues)
    .map(commQueue => _.find(endpoints, endpoint => endpoint.id === commQueue.endpointId))
    .value();

  return _.uniq(
    _.map(
      _.filter(currentAgentEndpoints, endpoint => !!endpoint?.type),
      endpoint => {
        if (endpoint?.type === EndpointTypes.CUSTOM_CHANNEL) {
          return `${endpoint.type}:${endpoint.configuration?.customChannelId}`;
        }
        return endpoint?.type;
      }
    )
  );
}

/**
 * Returns providers necessary to determine agent channels / endpoints
 *
 * @param getProvider - function to acquire a provider by name
 * @returns { channelConfiguration, communicationQueues }
 */
export function getAgentChannelProviders(getProvider) {
  return ['channelConfiguration', 'communicationQueues', 'customChannels'].reduce(
    (memo, name) => _.assign(memo, { [name]: getProvider(name) }),
    {}
  );
}
