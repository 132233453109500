import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('ConversationMessageAttachment').extend('Uuid');

Factory.define('ConversationMessageAttachmentWithDefaults')
  .extend('ConversationMessageAttachment')
  .attr('contentType', 'text/plain')
  .attr('filename', 'some-filename.txt')
  .attr('source', {})
  .attr('fileSize', 100);
