export default class NavigateBack {
  constructor(context) {
    this.context = context;
  }

  run() {
    navigateBack(this.context);
  }
}

export function navigateBack(context) {
  const locationHistory = context.stores.locationHistory.get();
  const previousPage = locationHistory.getPreviousEntry();

  locationHistory.setNavigatingBack(true);
  context.stores.locationHistory.set(locationHistory);

  context.router.navigateTo(previousPage);
}
