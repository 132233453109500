import AcceptCommunicationSession from 'actions/communication_session/accept_communication_session';
import closeNotification from './lib/close_notification';
import InboundCommunicationSessionNotification from 'models/notification/inbound_communication_session_notification';
import navigateToConversation from 'actions/conversation/lib/navigate_to_conversation';
import qconsole from 'scripts/lib/qconsole';
import TaskNotification from 'models/notification/task_notification';

export default class FollowIncomingNotification {
  constructor(context) {
    this.context = context;
  }

  run(incomingNotification) {
    let notification = getCurrentNotification(this.context, incomingNotification);
    if (!notification) {
      qconsole.log(`Could not find notification [${incomingNotification.id}] to accept`);
      return;
    }

    this.context.gateways.notification.delete(incomingNotification.id);
    closeNotification(this.context, incomingNotification.id);

    if (isInboundCommunicationSessionNotification(notification)) {
      this.context.executeAction(AcceptCommunicationSession);
      return;
    }

    if (isTaskNotification(notification)) {
      navigateToConversation(this.context, {
        customerId: notification.profile.id,
        conversationId: notification.conversationId,
        conversationItemId: notification.conversationItem.id,
      });
      return;
    }

    navigateToConversation(this.context, {
      customerId: notification.profile.id,
      conversationId: notification.conversationId,
    });
  }
}

function getCurrentNotification(context, incomingNotification) {
  if (isInboundCommunicationSessionNotification(incomingNotification)) {
    return incomingNotification;
  }

  return context.stores.notifications.findBy({ id: incomingNotification.id });
}

function isInboundCommunicationSessionNotification(notification) {
  return notification instanceof InboundCommunicationSessionNotification;
}

function isTaskNotification(notification) {
  return notification instanceof TaskNotification;
}
