import createModel, { prop } from '../lib/create_model';

const CommunicationDetail = createModel({
  modelName: 'CommunicationDetail',

  properties: {
    channel: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CommunicationDetail;
