import _ from 'lodash';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import EditableField from 'components/lib/editable_field_dumb';

const Meridiem = {
  AM: 'AM',
  PM: 'PM',
};

const TimeSelector = createReactClass({
  propTypes: {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    time: PropTypes.shape({
      hour: PropTypes.string.isRequired,
      minute: PropTypes.string.isRequired,
      meridiem: PropTypes.string.isRequired,
    }),
  },

  UNSAFE_componentWillMount() {
    if (!this.props.time) {
      this.onChange(); // Sets the working time to the current time
    }
  },

  getStringTime() {
    if (this.props.time) {
      return this.props.time;
    }

    const now = moment();
    return {
      hour: (now.hour() <= 12 ? now.hour() : now.hour() - 12).toString(),
      minute: now.minute().toString(),
      meridiem: now.hour() < 12 ? Meridiem.AM : Meridiem.PM,
    };
  },

  getHour() {
    const time = this.getStringTime();
    return parseInt(time.hour) || 1;
  },

  getMinute() {
    const time = this.getStringTime();
    return parseInt(time.minute) || 0;
  },

  /* Render */

  render() {
    return (
      <div className={classnames('timeSelector', this.props.className)}>
        {this.renderTime()}
        {this.renderMeridiem()}
      </div>
    );
  },

  renderTime() {
    return (
      <div className="timeSelector-time">
        {this.renderHour()}
        {this.renderMinute()}
      </div>
    );
  },

  renderHour() {
    const hour = this.getStringTime().hour;
    return (
      <div className="timeSelector-hour">
        <EditableField
          className="timeSelector-hour-value"
          maxLength={2}
          onSubmit={this.setHour}
          placeholder="1"
          value={hour}
        />
        <div className="timeSelector-hour-adjustors">
          <div className="timeSelector-hour-increment" onClick={this.incrementHour}>
            <i className="fa fa-chevron-up" />
          </div>
          <div className="timeSelector-hour-decrement" onClick={this.decrementHour}>
            <i className="fa fa-chevron-down" />
          </div>
        </div>
      </div>
    );
  },

  renderMinute() {
    let minute = this.getStringTime().minute;
    minute = minute.length === 1 ? `0${minute}` : minute;
    return (
      <div className="timeSelector-minute">
        <EditableField
          className="timeSelector-minute-value"
          maxLength={2}
          onSubmit={this.setMinute}
          placeholder="00"
          value={minute}
        />
        <div className="timeSelector-minute-adjustors">
          <div className="timeSelector-minute-increment" onClick={this.incrementMinute}>
            <i className="fa fa-chevron-up" />
          </div>
          <div className="timeSelector-minute-decrement" onClick={this.decrementMinute}>
            <i className="fa fa-chevron-down" />
          </div>
        </div>
      </div>
    );
  },

  renderMeridiem() {
    const meridiem = this.getStringTime().meridiem;
    return (
      <div className="timeSelector-meridiem">
        <div
          className={classnames('timeSelector-meridiem-am', {
            'timeSelector-meridiem-selected': meridiem === TimeSelector.Meridiem.AM,
          })}
          onClick={this.setAm}
        >
          am
        </div>
        <div
          className={classnames('timeSelector-meridiem-pm', {
            'timeSelector-meridiem-selected': meridiem === TimeSelector.Meridiem.PM,
          })}
          onClick={this.setPm}
        >
          pm
        </div>
      </div>
    );
  },

  /* Handlers */

  onChange(attrs) {
    const newTime = _.assign({}, this.getStringTime(), attrs);
    newTime.hour = newTime.hour.toString();
    newTime.minute = newTime.minute.toString();
    this.props.onChange(newTime);
  },

  /* Time Helpers */

  setAm() {
    this.onChange({ meridiem: TimeSelector.Meridiem.AM });
  },

  setPm() {
    this.onChange({ meridiem: TimeSelector.Meridiem.PM });
  },

  incrementHour() {
    const hour = (this.getHour() % 12) + 1;
    this.setHour(hour);
  },

  decrementHour() {
    let hour = this.getHour() - 1;
    hour = hour > 0 ? hour : 12 + hour;
    this.setHour(hour);
  },

  setHour(hour) {
    this.onChange({ hour: this.cleanTime(hour, 1, 12) });
  },

  incrementMinute() {
    const minute = (this.getMinute() + 1) % 60;
    this.setMinute(minute);
  },

  decrementMinute() {
    let minute = this.getMinute() - 1;
    minute = minute >= 0 ? minute : 60 + minute;
    this.setMinute(minute);
  },

  setMinute(minute) {
    this.onChange({ minute: this.cleanTime(minute, 0, 59) });
  },

  cleanTime(input, min, max) {
    let num = parseInt(input);
    num = num > max ? max : num;
    num = num < min ? min : num;
    return isNaN(num) ? min : num;
  },

  statics: {
    Meridiem,
  },
});

export default TimeSelector;
