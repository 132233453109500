import createModel from './lib/create_model';

const FbMessageExternalLink = createModel({
  modelName: 'FbMessageExternalLink',

  properties: {
    title: String,
    url: String,
  },
});

export default FbMessageExternalLink;
