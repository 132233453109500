import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AgentsLiveboard from './agents_liveboard';
import connect from 'components/lib/connect';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import InlineContainer from 'components/common/containers/inline_container';
import FloorboardLocation from 'models/location/floorboard';
import Link from 'components/common/link';
import LiveboardFilter from './liveboard_filter';
import LiveboardType from 'models/liveboards/liveboard_type';
import LiveboardsLocation from 'models/location/liveboards';
import LoadingSpinner from 'components/lib/loading_spinner';
import NavigateToUrl from 'actions/current_location/navigate_to_url';
import PageLayout from '../page_layout';
import PresentIcon from 'components/lib/icons/present_icon';
import ReportHeader from 'components/reporting/report_header';
import SmartMatchLiveboard from './smart_match_liveboard';
import SummaryLiveboard from './summary_liveboard';
import TopicsLiveboard from './topics_liveboard';
import TimezoneSelector from '../reporting/timezone_selector';

export function Liveboards({ isLoading, isRestricted, location, onLinkClick, tabOptions }) {
  return isRestricted ? (
    <PageLayout className="liveboards-pageWrapper" id="liveboards">
      <div data-aid="accessDenied" />
    </PageLayout>
  ) : (
    <PageLayout className="liveboards-pageWrapper" id="liveboards">
      <ReportHeader
        activeReport={location.type}
        getReportUrl={getLiveboardUrl}
        onReportClick={onLinkClick}
        renderSecondaryElements={renderSecondaryContent}
        reportSelectorOptions={tabOptions}
        title="Liveboards"
      />
      <div className="liveboards">
        {isLoading ? <LoadingSpinner className="liveboards-loading" /> : renderLiveboard()}
      </div>
    </PageLayout>
  );

  function getLiveboardUrl(type) {
    return getLocationUrl(
      LiveboardsLocation.create({
        type,
        routingGroupIds: location.routingGroupIds,
        teamIds: location.teamIds,
        timezone: location.timezone,
      })
    );
  }

  function renderLiveboard() {
    switch (location.type) {
      case LiveboardType.AGENTS:
        return <AgentsLiveboard />;
      case LiveboardType.PEOPLE_MATCH:
        return <SmartMatchLiveboard />;
      case LiveboardType.TOPICS:
        return (
          <TopicsLiveboard
            className={classnames('liveboards-board', 'liveboards-topics')}
            routingGroupIds={location.routingGroupIds}
          />
        );
      default:
        return <SummaryLiveboard className={classnames('liveboards-board', 'liveboards-summary')} />;
    }
  }
  function renderSecondaryContent() {
    let isSummary = location.type === LiveboardType.SUMMARY;
    return (
      <FilterWrapper inline="medium">
        <TimezoneSelector />
        <LiveboardFilter className="liveboards-header-filter" />
        {isSummary && (
          <Link href={getLocationUrl(FloorboardLocation.create(location))}>
            <FloorboardIcon />
          </Link>
        )}
      </FilterWrapper>
    );
  }
}

Liveboards.propTypes = {
  isLoading: PropTypes.bool,
  isRestricted: PropTypes.bool,
  location: PropTypes.instanceOf(LiveboardsLocation).isRequired,
  tabOptions: PropTypes.arrayOf(PropTypes.oneOf(_.values(LiveboardType))).isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapExecuteToProps)(Liveboards);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  return {
    isLoading: getProvider('liveboard').isLoading(),
    isRestricted: !isFeatureEnabled('viewLiveboards'),
    location: getProvider('currentLocation').get(),
    tabOptions: TABS,
  };
}

const TABS = Object.freeze([
  LiveboardType.SUMMARY,
  LiveboardType.AGENTS,
  LiveboardType.TOPICS,
  LiveboardType.PEOPLE_MATCH,
]);

function mapExecuteToProps(executeAction) {
  return {
    onLinkClick: url => executeAction(NavigateToUrl, url),
  };
}

const FilterWrapper = styled(InlineContainer)`
  display: flex;
  align-items: center;
`;

const FloorboardIcon = styled(PresentIcon)`
  width: 24px;
  cursor: pointer;
`;
