import _ from 'lodash';

import DtoConverter from 'scripts/application/dto_converters/customer_assignee_converter';
import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';
import qconsole from 'scripts/lib/qconsole';

export default class CustomerAssigneeGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.customers;
  }

  handleDelete(customerId) {
    if (this._isCustomerLoaded(customerId)) {
      let assigneeStore = this.store.storesFor(customerId).assignee;
      assigneeStore.remove(customerId);
    }
  }

  handleEntity(payload) {
    if (this._isCustomerLoaded(payload.id)) {
      this._replaceAssignee(payload);
    }
  }

  handleFetchSuccess(payload) {
    if (!_.isEmpty(payload) && this._isCustomerLoaded(payload.id)) {
      this._replaceAssignee(payload);
    }
  }

  handleFetchError(errorDto) {
    qconsole.log(`Failed to fetch customer assignee ${JSON.stringify(errorDto)}`);
  }

  handleUpdateSuccess(customerId) {
    if (this._isCustomerLoaded(customerId)) {
      let assigneeStore = this.store.storesFor(customerId).assignee;
      let pendingAssignee = assigneeStore.getPending();
      let existingAssignee = assigneeStore.get();

      if (!existingAssignee || pendingAssignee._version > existingAssignee._version) {
        assigneeStore.commitPending();
      } else {
        assigneeStore.resetPending();
      }
    }
  }

  handleUpdateErrors(customerId, errorsDto) {
    if (this._isCustomerLoaded(customerId)) {
      let assigneeStore = this.store.storesFor(customerId).assignee;
      if (assigneeStore.isPending()) {
        const errors = errorsDto.errors.map(ErrorDtoConverter.fromDto);
        assigneeStore.setErrors(errors);
        assigneeStore.resetPending();
      }
    }
  }

  _isCustomerLoaded(customerId) {
    return this.store.has({ id: customerId });
  }

  _replaceAssignee(dto) {
    let receivedAssignee = DtoConverter.fromDto(dto);

    let assigneeStore = this.store.storesFor(receivedAssignee.id).assignee;
    let existingAssignee = assigneeStore.get();

    if (existingAssignee) {
      if (receivedAssignee._version > existingAssignee._version) {
        assigneeStore.set(receivedAssignee);
      }
    } else {
      assigneeStore.set(receivedAssignee);
    }
    assigneeStore.resetLoading();
  }
}
