import createEnum from 'scripts/lib/create_enum';

export default createEnum(
  'ADD',
  'ADD_OBSERVER',
  'BLIND_TRANSFER',
  'CANCEL_WARM_TRANSFER',
  'COMPLETE_WARM_TRANSFER',
  'END_CALL',
  'END_CONFERENCE',
  'HOLD',
  'INITIATE_WARM_TRANSFER',
  'MUTE',
  'OBSERVER_ACCEPT_TRANSFER',
  'OBSERVER_COACH',
  'OBSERVER_DECLINE_TRANSFER',
  'OBSERVER_HIDE',
  'OBSERVER_JOIN',
  'OBSERVER_LISTEN',
  'OBSERVER_SHOW',
  'PAUSE_RECORDING',
  'RECORD_QUALITY_EVENT',
  'REMOVE',
  'RESUME_RECORDING'
);
