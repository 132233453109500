import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Close In Circle Fill
 */

export default function CloseInCircleFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <circle cx="8" cy="8" fill="white" r="8" />
      <path
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.8243 5.17574C11.0586 5.41005 11.0586 5.78995 10.8243 6.02426L8.84853 8L10.8243 9.97574C11.0586 10.21 11.0586 10.5899 10.8243 10.8243C10.5899 11.0586 10.21 11.0586 9.97573 10.8243L8 8.84853L6.02426 10.8243C5.78995 11.0586 5.41005 11.0586 5.17574 10.8243C4.94142 10.5899 4.94142 10.21 5.17574 9.97573L7.15147 8L5.17574 6.02427C4.94142 5.78995 4.94142 5.41005 5.17574 5.17574C5.41005 4.94142 5.78995 4.94142 6.02426 5.17574L8 7.15147L9.97574 5.17574C10.2101 4.94142 10.5899 4.94142 10.8243 5.17574Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
