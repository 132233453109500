import ConversationMessageAttachment from './conversation_message_attachment';
import createModel, { prop } from './lib/create_model';
import redactAttachment from './attachment_redact';

const MessageContentTypeImage = createModel({
  modelName: 'MessageContentTypeImage',
  properties: {
    caption: prop(String),
    bodyHtml: prop(String),
    buttons: prop(Object).default([]),
    hasRedactedPaymentCardNumber: prop(Boolean).default(false),
    images: prop([ConversationMessageAttachment]).default([]),
  },

  getBody() {
    return this.caption || '';
  },

  redactAttachment(id) {
    redactAttachment(this.images, id);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default MessageContentTypeImage;
