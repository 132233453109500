import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import WarningIcon from 'components/lib/icons/warning_icon';

export const ErrorMessage = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.red400};
  display: flex;
  flex-direction: row;
  max-width: 400px;
  padding-left: ${p => p.theme.spacing.inlineSmall};
`;

const IconContainer = styled.div`
  height: 16px;
  padding-right: ${p => p.theme.spacing.small};
  svg {
    height: 16px;
    width: 16px;
  }
  path {
    fill: ${p => p.theme.colors.red400};
  }
`;

export default function ScheduledReportError({ errors = [] }) {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <ErrorMessage data-aid="scheduled-report-error">
      <IconContainer>
        <WarningIcon />
      </IconContainer>
      {_.capitalize(errors[0])}
    </ErrorMessage>
  );
}

ScheduledReportError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};
