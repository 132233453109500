import createReactClass from 'create-react-class';
import React from 'react';

import T from 'prop-types';

const ChartTooltip = createReactClass({
  propTypes: {
    className: T.string,
    x: T.number.isRequired,
    y: T.number.isRequired,
  },

  /* Render */
  render() {
    let textTop;
    let textBottom = this.props.children;

    if (React.Children.count(textBottom) > 0 && this.props.children[0].type === ChartTooltipTitle.type) {
      textTop = textBottom[0];
      textBottom = textBottom.slice(1);
    }

    return (
      <div className="chartTooltip" style={{ left: this.props.x, top: this.props.y }}>
        <div className="chartTooltip-circle" />
        <div className="chartTooltip-triangle" />
        <div className={`chartTooltip-rectangle ${this.props.className ? `${this.props.className}-rectangle` : ''}`}>
          {textTop}
          {textBottom}
        </div>
      </div>
    );
  },
});

const ChartTooltipTitle = createReactClass({
  render() {
    return <div className="chartTooltip-text-top">{this.props.children}</div>;
  },
});

export default ChartTooltip;
export { ChartTooltipTitle };
