import { createPluginFactory } from '@udecode/plate-common';
import { MARK_COLOR, MARK_FONT_SIZE } from '@udecode/plate-font';

import { translateFontTagSize } from 'components/text_editor_new/lib/translate_font_size';

// Plate does not support <font> tag natively
export const ELEMENT_FONT = 'font';

/**
 * Simple plugin that adds support for the <font> tag deserialization when it is pasted into the editor.
 * We use <font> in Answers and in rich text composers when the font color and/or size is different
 * from the default. Since this plugin combines both text color and text size, it relies on two other
 * plugins (`fontSize` and `color`) in order to work properly
 */
export function createFontElementPlugin() {
  function getColor(element) {
    const colorAttr = element.attributes['color'];
    return (colorAttr && colorAttr.value) || '';
  }

  function getFontSize(element) {
    const sizeAttr = element.attributes['size'];
    const size = sizeAttr && sizeAttr.value;

    return (size && translateFontTagSize(size)) || '';
  }

  return createPluginFactory({
    key: ELEMENT_FONT,
    deserializeHtml: {
      isLeaf: true,

      getNode(element) {
        const color = getColor(element);
        const size = getFontSize(element);
        if (!color && !size) return;

        const node = {};
        if (color) node[MARK_COLOR] = color;
        if (size) node[MARK_FONT_SIZE] = size;

        return node;
      },

      // For some reason, Plate doesn't bother to make sure the node names are uppercase
      rules: [{ validNodeName: ELEMENT_FONT.toUpperCase() }],
    },
  })();
}
