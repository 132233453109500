import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CheckmarkIcon from './checkmark_icon';
import SummaryCard from 'components/customer/summary/lib/summary_card';

export default function ProfileCard({
  className,
  address,
  emails,
  isLoading,
  matchingFields,
  name,
  onClick,
  phones,
  customAttributes,
}) {
  let fields = [{ label: 'Address', hasMatch: matchingFields.address, value: address }];

  _.forEach(emails, (email, index) => {
    fields.push({
      hasMatch: matchingFields.emails[email],
      label: index === 0 ? 'Email' : '',
      value: email,
    });
  });

  _.forEach(phones, (phone, index) => {
    fields.push({
      hasMatch: matchingFields.phones[phone],
      label: index === 0 ? 'Phone' : '',
      value: phone,
    });
  });

  _.forEach(customAttributes, (customAttributeValue, customAttributeKey) => {
    fields.push({
      hasMatch: matchingFields.customAttributes[customAttributeKey],
      label: customAttributeValue.placeholder,
      value: customAttributeValue.value,
    });
  });

  return (
    <SummaryCard className={className} isLoading={isLoading} onClick={onClick}>
      <div
        className={classnames('mergeProfileModal-profileCard-row', 'mergeProfileModal-profileCard-nameRow', {
          'mergeProfileModal-profileCard-row-matching': matchingFields.name,
        })}
      >
        <ProfileCardValue placeholder="Name" value={name} />
        {matchingFields.name ? <CheckmarkIcon /> : null}
      </div>
      {_.map(fields, ({ hasMatch, label, value }, idx) => (
        <div
          className={classnames('mergeProfileModal-profileCard-row', {
            'mergeProfileModal-profileCard-row-matching': hasMatch,
          })}
          key={`profileCard-attr${idx}`}
        >
          <span className="mergeProfileModal-profileCard-label">{label}</span>
          <span className="mergeProfileModal-profileCard-content">
            <ProfileCardValue placeholder="None" value={value} />
            {hasMatch ? <CheckmarkIcon /> : null}
          </span>
        </div>
      ))}
    </SummaryCard>
  );
}

ProfileCard.propTypes = {
  address: PropTypes.string,
  className: PropTypes.string,
  customAttributes: PropTypes.object,
  emails: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  matchingFields: PropTypes.shape({
    address: PropTypes.bool,
    emails: PropTypes.object,
    name: PropTypes.bool,
    phones: PropTypes.object,
  }),
  name: PropTypes.string,
  onClick: PropTypes.func,
  phones: PropTypes.arrayOf(PropTypes.string),
};

export function ProfileCardValue({ placeholder, value }) {
  const classNames = classnames('mergeProfileModal-profileCard-value', {
    'mergeProfileModal-profileCard-value-empty': !value,
  });
  return (
    <span className={classNames} title={value}>
      {value || placeholder}
    </span>
  );
}
ProfileCardValue.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
