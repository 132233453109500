import _ from 'lodash';

import AgentRoutingPreferences from 'models/agent_routing_preferences';
import { CAPACITY_MANAGER_STARTUP_DELAY_SECONDS } from 'actions/routing/capacity_manager';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';
import qconsole from 'scripts/lib/qconsole';

/**
 * Applies partial update to agent routing preferences in the backend without specifying the version,
 * which results in server applying the patch on top of the latest version.
 *
 * It doesn't reflect any changes in the UI until a response is received, thus, making this function unsuitable for
 * any UI interaction.
 *
 * @param context - the agent-desktop context / env
 * @param channels - an object containing desired changes in the form of <channel>: (true|false) entries
 */
export function forceUpdateAgentRoutingPreferences(context, channels) {
  // Postpone routing on affected channels to prevent races between capacity manager and routing triggered by the change.
  postponeRouting();

  context.gateways.agentRoutingPreferences
    .update({ channels })
    .then(dto => handleUpdateSuccess(dto))
    .catch(err => handleUpdateError(err));

  function postponeRouting() {
    if (channels.MESSAGING != null) {
      context.capacityManager.postponeMessagingRouting(CAPACITY_MANAGER_STARTUP_DELAY_SECONDS);
    }

    if (channels.VOICE != null) {
      context.capacityManager.postponeCallRouting(CAPACITY_MANAGER_STARTUP_DELAY_SECONDS);
    }
  }

  function handleUpdateSuccess(dto) {
    updateStore(context, dto);
  }

  function handleUpdateError(err) {
    handleCommonErrors(context, err);
  }
}

/**
 * Updates agentRoutingPreferences store with DTO received from the server unless the store already has a newer version
 * @param context - the agent-desktop context / env
 * @param dto - agentRoutingPreferences DTO from the server
 */
export function updateStore(context, dto) {
  let received = AgentRoutingPreferences.fromJs(dto);
  let current = context.stores.agentRoutingPreferences.get();

  if (_.get(current, '_version', 0) > received._version) {
    qconsole.log(`Ignored older version of routing preferences: [${current._version} > ${received._version}]`);
    return;
  }

  if (current.nextCallRoutingTime) {
    received.setNextCallRoutingTime(current.nextCallRoutingTime);
  }

  context.stores.agentRoutingPreferences.set(received);
}
