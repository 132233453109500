import React from 'react';
import styled from 'styled-components';

import Modal from 'components/lib/modal';
import ProgressBar from 'components/common/progress_bar';

const WebhookVerficationModal = styled(Modal)`
  background: ${p => p.theme.colors.white};
  border-radius: ${p => p.theme.borderRadius.default};
  min-width: 300px;
`;

const WebhookVerificationHeader = styled.div`
  font-size: ${p => p.theme.fontSize.medium};
  font-weight: bold;
  padding: ${p => p.theme.spacing.insetLarge};
  text-align: center;
`;

const WebhookVerificationBody = styled.div`
  padding: ${p => p.theme.spacing.insetLarge};
  .react-progress-bar {
    background-color: ${p => p.theme.colors.gray400};
    border-radius: ${p => p.theme.borderRadius.default};
    height: 8px;
    position: relative;
  }
  .react-progress-bar-percent {
    border-radius: ${p => p.theme.borderRadius.default};
    height: 8px;
  }
`;

function WebhookVerification() {
  return (
    <WebhookVerficationModal>
      <WebhookVerificationHeader>Verifying URL...</WebhookVerificationHeader>
      <WebhookVerificationBody>
        <ProgressBar intervalTime={100} isVisible mode="inline" />
      </WebhookVerificationBody>
    </WebhookVerficationModal>
  );
}

export default WebhookVerification;
