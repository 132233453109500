import createReactClass from 'create-react-class';
import React from 'react';
import moment from 'moment';

const ShortDateRange = createReactClass({
  render() {
    let startMoment = moment(this.props.startTime);
    let endMoment = moment(this.props.endTime);

    let endFormat = startMoment.month() === endMoment.month() ? 'D' : 'MMM D';
    if (endMoment.year() !== moment().year()) {
      endFormat += ', YYYY';
    }

    return (
      <span className="short-date-range">
        {this.renderTime(startMoment, 'MMM D')}-<wbr />
        {this.renderTime(endMoment, endFormat)}
      </span>
    );
  },

  renderTime(momentTime, format) {
    let fullTime = momentTime.format('MMM D, YYYY h:mma');
    let isoTime = momentTime.toISOString();
    let formattedTime = momentTime.format(format);

    // http://stackoverflow.com/a/4932732
    return (
      <time className="nowrap" dateTime={isoTime} title={fullTime}>
        {formattedTime}
      </time>
    );
  },
});

export default ShortDateRange;
