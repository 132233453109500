import connect from 'components/lib/connect';
import StartUpload from 'actions/knowledge_base/start_upload';
import SlateStylesMenu from './slate_styles_menu';

const mapStateToProps = ({ getProvider, isFeatureEnabled }, props) => {
  const snippet = getProvider('snippetComposition').get();
  const snippetId = snippet && snippet.id;
  return { snippetId, ...props };
};

const mapExecuteToProps = (executeAction, props) => {
  function onStartUpload(file, contentChannelType, onInsertImage, isInline, currentImageCount) {
    executeAction(StartUpload, {
      file,
      contentChannelType,
      onInsertImage,
      isInline,
      language: props.language,
      currentImageCount,
    });
  }

  return { onStartUpload };
};

export default connect(mapStateToProps, mapExecuteToProps)(SlateStylesMenu);
