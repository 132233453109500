import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TopicsHeaderContainer from 'components/liveboards/topics/topics_header_container';
import TopicsTable from 'components/liveboards/topics/topics_table';

export default function TopicsLiveboard({ className, routingGroupIds }) {
  return (
    <div className={classnames('topicsLiveboard', className)}>
      <TopicsHeaderContainer routingGroupIds={routingGroupIds} />
      <TopicsTable routingGroupIds={routingGroupIds} />
    </div>
  );
}

TopicsLiveboard.propTypes = {
  className: PropTypes.string,
  routingGroupIds: PropTypes.arrayOf(PropTypes.string),
};
