import React from 'react';
import Icon from 'components/lib/icons/icon';
import styled from 'styled-components';

export default props => (
  <StyledIcon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M13.854 2.854a.5.5 0 0 0-.708-.708L8 7.293 2.854 2.146a.5.5 0 1 0-.708.708L7.293 8l-5.147 5.146a.5.5 0 0 0 .708.708L8 8.707l5.146 5.147a.5.5 0 0 0 .708-.708L8.707 8l5.147-5.146z"
      fillRule="evenodd"
    />
  </StyledIcon>
);

const StyledIcon = styled(Icon)`
  fill: ${p => p.theme.colors.black};
`;
