import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import MarkTaskClosedAndCreateCompletionItem from 'actions/conversation_item/task/mark_task_closed_and_create_completion_item';
import MarkTaskOpenedAndCreateReopenedItem from 'actions/conversation_item/task/mark_task_opened_and_create_reopend_item';
import SearchableTaskAssignmentMenu from 'components/lib/assignment_menu/searchable_task_assignment_menu';
import TaskStatusIndicator from './task_status_indicator';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function TaskHeader({ commentCount, isPending, location, onChangeAssignee, isLink, item }) {
  const executeAction = useExecuteAction();

  const task = item.content;
  const isClosed = task.isClosed();
  const isReadOnly = isClosed || isLink;
  const onToggleTask = useCallback(
    evt => {
      evt.stopPropagation();
      if (item.content.isClosed()) {
        executeAction(MarkTaskOpenedAndCreateReopenedItem, {
          conversationItem: item,
          location,
        });
      } else {
        executeAction(MarkTaskClosedAndCreateCompletionItem, {
          conversationItem: item,
          location,
        });
      }
    },
    [item, location]
  );

  function renderFinishButton() {
    if (isLink) {
      return null;
    }

    return (
      <StyledButton
        buttonType={ButtonTypes.OUTLINE}
        className={classnames({ 'taskHeader-toggle-toReopen': isClosed, 'taskHeader-toggle-toClose': !isClosed })}
        data-aid="taskHeader-toggle"
        disabled={isPending}
        isSmall
        onClick={onToggleTask}
      >
        {isClosed ? 'Reopen' : 'Close'}
      </StyledButton>
    );
  }

  function renderAssignee() {
    let assignee = task.assignee;
    return (
      <React.Fragment>
        <StyledWord>for</StyledWord>
        <StyledAssignmentMenu
          agentId={assignee && assignee.agentId}
          onSelect={onChangeAssignee}
          readOnly={isReadOnly}
          routingGroupId={assignee && assignee.routingGroupId}
        />
      </React.Fragment>
    );
  }
  return (
    <StyledTaskHeader data-aid="commentableTaskItem-header">
      <TaskStatusIndicator isComplete={isClosed} timestamp={task.dueAt} />{' '}
      <StyledStatus data-aid="taskHeader-status">{isClosed ? 'Closed Task' : 'Task '}</StyledStatus>
      {renderAssignee()} {renderFinishButton()}
    </StyledTaskHeader>
  );
}

TaskHeader.propTypes = {
  commentCount: PropTypes.number,
  isPending: PropTypes.bool,
  location: PropTypes.string.isRequired,
  onChangeAssignee: PropTypes.func.isRequired,
  isLink: PropTypes.bool,
  item: PropTypes.object.isRequired,
};

const StyledStatus = styled.strong`
  white-space: nowrap;
`;

const StyledTaskHeader = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  margin-right: ${p => p.theme.spacing.medium};
`;

export const StyledButton = styled(Button)`
  margin-left: ${p => p.theme.spacing.medium};
`;

const StyledWord = styled.span`
  padding: 0 3px;
`;

export const StyledAssignmentMenu = styled(SearchableTaskAssignmentMenu)`
  &.searchableTaskAssignment {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .searchableTaskAssignment-assignee-text,
    .searchableTaskAssignment-assignee-text-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
