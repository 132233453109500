import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShortDateRange from 'components/lib/date/short_date_range';

export default class DateRangePickerButton extends Component {
  constructor(props) {
    super(props);

    this.handleClearClick = this.handleClearClick.bind(this);
  }

  handleClearClick(event) {
    if (this.props.onClear) {
      event.stopPropagation();
      this.props.onClear();
    }
  }

  render() {
    let { onClick, renderPlaceholder } = this.props;
    if (!this.props.startDate) {
      let classes = classnames('dateRange-button-empty', this.props.className);
      return (
        <div className={classes} onClick={onClick} ref={this.props.setRef}>
          {renderPlaceholder && renderPlaceholder()}
        </div>
      );
    }

    let classes = classnames('dateRange-button-container', this.props.className);
    return (
      <div className={classes} ref={this.props.setRef}>
        <div className="dateRange-button button" onClick={onClick}>
          <div className="dateRange-button-content">
            <i className="dateRange-button-calendarIcon ion-android-calendar" />
            <ShortDateRange endTime={this.props.endDate} startTime={this.props.startDate} />
            {this.props.onClear ? (
              <span className="dateRange-button-clear" onClick={this.handleClearClick}>
                ✕
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

DateRangePickerButton.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.string,
  onClear: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  renderPlaceholder: PropTypes.func,
  setRef: PropTypes.func,
  startDate: PropTypes.string,
};
