import { IdGenerator } from 'factories/lib/uuid';
import SearchFilter from 'models/search/search_filter';
import SearchQuery from 'models/search/search_query';
import SearchQueryConverter from 'scripts/application/dto_converters/search/search_query_converter';
import DtoConverter from 'scripts/application/dto_converters/search/snippet_search_result_converter';

export default class RequestFavoriteAnswers {
  constructor(context) {
    this.context = context;
  }

  run() {
    const agentPreferences = this.context.stores.agentPreferences.get();
    const ids = agentPreferences.favoriteAnswerIds;
    if (!ids.length) {
      return;
    }

    const id = IdGenerator.newId();
    const filter = new SearchFilter({ answerIds: ids });
    const query = new SearchQuery({ filter, text: '', from: 0, size: 100 });
    const queryDto = { query: SearchQueryConverter.toDto(query) };

    this.context.gateways.answerSearch.request(queryDto).then(dto => {
      const search = DtoConverter.fromDto({ ...dto, id });
      this.context.stores.favoriteAnswers.set(search.hits);
    });
  }
}
