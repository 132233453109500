import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'VoiceRecordingDeleted',

  properties: {
    agentId: prop(String).isRequired,
    conversationItemId: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
