import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/common/input';
import KeypressEventHandler from 'components/lib/dom_controls/lib/keypress_event_handler';

const EditableFieldControlled = createReactClass({
  propTypes: {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    'data-aid': PropTypes.string,
    dir: PropTypes.string,
    disabled: PropTypes.bool,
    onCancel: PropTypes.func,
    onInputFocus: PropTypes.func,
    onInputBlur: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    pending: PropTypes.bool,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.string.isRequired,
  },

  getInitialState() {
    return { workingValue: this.props.value };
  },

  componentDidMount() {
    if (this.props.autoFocus) {
      this.input.focus();
    }
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.pending && nextProps.value !== this.state.workingValue) {
      this.setState({ workingValue: nextProps.value });
    }
  },

  componentDidUpdate(prevProps) {
    if (!prevProps.autoFocus && this.props.autoFocus) {
      this.input.focus();
    }
  },

  handleChange(evt) {
    this.setState({ workingValue: evt.target.value });
  },

  handleInputBlur() {
    if (this.props.onInputBlur) {
      this.props.onInputBlur(this.state.workingValue);
      return;
    }

    if (this.state.workingValue !== this.props.value) {
      this.submit();
    }
  },

  handleInputFocus() {
    if (this.props.onInputFocus) {
      this.props.onInputFocus();
    }
  },

  handleSubmit(evt) {
    evt.preventDefault();
    this.submit();
  },

  handleCancel(evt) {
    let input = evt.target; // cache the event target to work around synthetic event re-use by React
    this.setState({ workingValue: this.props.value }, () => {
      input.blur();
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    });
  },

  submit() {
    this.props.onSubmit(this.state.workingValue);
  },

  render() {
    const keypressEventHandler = new KeypressEventHandler(this.handleSubmit, this.handleCancel, { isInputField: true });
    return (
      <form onSubmit={this.handleSubmit}>
        <Input
          className={this.props.className}
          data-aid={this.props['data-aid']}
          dir={this.props.dir || 'auto'}
          disabled={this.props.pending || this.props.disabled}
          maxLength={this.props.maxLength}
          onBlur={this.handleInputBlur}
          onChange={this.handleChange}
          onFocus={this.handleInputFocus}
          placeholder={this.props.placeholder || ''}
          readOnly={this.props.readOnly}
          ref={node => (this.input = node)}
          title={this.props.title}
          value={this.state.workingValue}
          {...keypressEventHandler.reactAttributes()}
        />
      </form>
    );
  },
});

export default EditableFieldControlled;
