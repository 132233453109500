import closeNotification from './lib/close_notification';

export default class DismissNotification {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    this.context.gateways.notification.delete(id);
    closeNotification(this.context, id);
  }

  static schedule(context, { id, timeout }) {
    return context.scheduler.executeIn(timeout || this.timeout, id, this, id);
  }

  static get timeout() {
    return DISMISS_NOTIFICATION_DELAY_SECONDS * 1000;
  }
}

const DISMISS_NOTIFICATION_DELAY_SECONDS = 15;
