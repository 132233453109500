import _ from 'lodash';
import classNames from 'classnames';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RemoveAttachmentButton, { RemoveAttachmentModal, StyledRemoveIcon } from './remove_attachment_button';

export default class InlineImageAttachment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = { isLoaded: false };
  }

  render() {
    const { attachment, onClick, inlinePreviewImageUrl } = this.props;

    const handleClick = this.state.isLoaded ? onClick : _.noop;
    const imageStyle = this.state.isLoaded ? undefined : { display: 'none' };
    const imageClasses = classNames('inlineImageAttachment-image', {
      loaded: this.state.isLoaded,
    });
    return (
      <InlineAttachmentWrapper attachment={attachment} onClick={handleClick}>
        {this.state.isLoaded ? null : <PlaceholderInlineImage />}
        <img
          className={imageClasses}
          onLoad={this.handleLoad}
          ref={image => (this.image = image)}
          src={inlinePreviewImageUrl}
          style={imageStyle}
        />
      </InlineAttachmentWrapper>
    );
  }

  handleLoad() {
    this.setState({ isLoaded: true }, () => {
      this.props.onLoad && this.props.onLoad();
    });
  }
}

export function InlineAttachmentWrapper({ attachment, children, onClick }) {
  const [isRemoving, setIsRemoving] = useState(false);

  return (
    <React.Fragment>
      <StyledInlineAttachment className="inlineImageAttachment" onClick={onClick}>
        {children}
        <RemoveAttachmentButton attachment={attachment} isRemoving={isRemoving} setIsRemoving={setIsRemoving} />
      </StyledInlineAttachment>
      {isRemoving ? <RemoveAttachmentModal attachment={attachment} setIsRemoving={setIsRemoving} /> : null}
    </React.Fragment>
  );
}

const StyledInlineAttachment = styled.li`
  position: relative;
  &:hover ${StyledRemoveIcon} {
    opacity: 1;
    visibility: visible;
  }
`;

InlineImageAttachment.propTypes = {
  attachment: PropTypes.object,
  inlinePreviewImageUrl: PropTypes.string,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
};

export function PlaceholderInlineImage() {
  return (
    <div className="inlineImageAttachment-placeholder">
      <FontAwesomeIcon icon={faEllipsisH} />
    </div>
  );
}
