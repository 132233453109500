import { Factory } from 'rosie';

import '../conversation_item_factory';
import idGenerator from '../lib/id_generator';

Factory.define('SmsCampaignOutreach').attr('type', 'SMS_CAMPAIGN_OUTREACH');

Factory.define('SmsCampaignOutreachWithDefaults')
  .extend('SmsCampaignOutreach')
  .attr('campaignId', 'campaign-1')
  .attr('from', '+16139920250')
  .attr('to', '+12024561111')
  .attr('body', '!!!')
  .attr('initiator', {
    type: 'AGENT',
    id: idGenerator.newId(),
  });

Factory.define('SmsCampaignOutreachItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('SmsCampaignOutreach', content);
  });

Factory.define('SmsCampaignOutreachItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('SmsCampaignOutreachWithDefaults', content);
  });
