import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Err from 'models/err';
import ErrorTooltip from './contact_info/error_tooltip';
import Input from 'components/common/input';
import KeypressEventHandler from 'components/lib/dom_controls/lib/keypress_event_handler';
import MergeableErrorTooltip from './contact_info/mergeable_error_tooltip';

class CustomerEmailEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue,
    };

    _.bindAll(
      this,
      'handleBlur',
      'handleCancel',
      'handleClearEmail',
      'handleEmailChange',
      'handleFocus',
      'handlePrimaryClick',
      'handleSubmit'
    );
  }

  componentWillUnmount() {
    clearImmediate(this.blurTimeout);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.initialValue, this.props.initialValue)) {
      this.setState({ value: nextProps.initialValue });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoFocus && !prevProps.autoFocus) {
      this.emailInput.focus();
    }
  }

  render() {
    const rowClassNames = classnames('customerProfile-contactDetails-row', 'customerProfile-contactDetails-emailRow', {
      'customerProfile-contactDetails-row-focused': this.state.isFocused,
    });
    const primaryButtonClassNames = classnames(
      'customerProfile-contactDetails-email-primary-button',
      'customerProfile-contactDetails-attrib-button',
      {
        'customerProfile-contactDetails-attrib-button-active':
          !this.isPrimaryButtonDisabled() && this.state.value.isPrimary,
        'customerProfile-contactDetails-attrib-button-disabled': this.isPrimaryButtonDisabled(),
      }
    );
    const formKeypressHandler = new KeypressEventHandler(this.handleSubmit, this.handleCancel, { isInputField: true });
    const primaryButtonKeypressHandler = new KeypressEventHandler(_.noop, _.noop, {
      isInputField: true,
      toggleFn: this.handlePrimaryClick,
    });

    return (
      <form {...formKeypressHandler.reactAttributes()} onBlur={this.handleBlur} onFocus={this.handleFocus}>
        <div className={rowClassNames}>
          <MergeableErrorTooltip
            attr="email"
            className="customerProfile-contactInput-email-wrapper"
            customerId={this.props.customerId}
            data-aid="contactField-errorTooltip"
            errors={this.getFieldErrors('email')}
            margin={48}
            onClear={this.handleClearEmail}
            value={this.state.value.email || ''}
          >
            <Input
              autoFocus={!!this.props.autoFocus}
              className="editableField customerProfile-contactInput customerProfile-contactDetails-email"
              disabled={this.props.isDisabled}
              onChange={this.handleEmailChange}
              placeholder="Email"
              ref={node => (this.emailInput = node)}
              value={this.state.value.email || ''}
            />
          </MergeableErrorTooltip>
          <ErrorTooltip errors={this.getFieldErrors('isPrimary')}>
            <div
              className={primaryButtonClassNames}
              onClick={this.handlePrimaryClick}
              tabIndex="0"
              title="Main"
              {...primaryButtonKeypressHandler.reactAttributes()}
            >
              Main
            </div>
          </ErrorTooltip>
        </div>
      </form>
    );
  }

  getFieldErrors(fieldName) {
    return _.get(this.props.errors, fieldName) && [this.props.errors[fieldName]];
  }

  handleClearEmail() {
    if (this.props.isDisabled) {
      return;
    }
    this.setState({ isFocused: false, value: { email: '' } });
    this.props.onSubmit && this.props.onSubmit({ email: '' });
  }

  isPrimaryButtonDisabled() {
    return !this.state.value.email || this.props.isDisabled;
  }

  handleBlur() {
    this.setState({ isFocused: false });

    // schedule handleAllBlur for the next event cycle to make sure we left _all_ of the form inputs
    // since we _could_ be blurring one input to focus on another
    this.blurTimeout = setImmediate(() => this.handleAllBlur());
  }

  handleAllBlur() {
    this.props.onBlur && this.props.onBlur(this.state.value);
  }

  handleCancel() {
    if (this.props.isDisabled) {
      return;
    }

    this.setState({ value: this.props.initialValue }, () =>
      // use setState callback to ensure on blur is called with the reset state
      this.emailInput.blur()
    );

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleEmailChange(evt) {
    this.setState({ value: { ...this.state.value, email: evt.target.value } });
  }

  handleFocus() {
    this.setState({ isFocused: true });
    clearImmediate(this.blurTimeout);
  }

  handlePrimaryClick(evt) {
    evt.preventDefault(); // prevent scrolling when pressing space

    if (this.isPrimaryButtonDisabled()) {
      return;
    }

    this.setState({ value: { ...this.state.value, isPrimary: !this.state.value.isPrimary } });
  }

  handleSubmit(evt) {
    evt.preventDefault(); // prevent reload on Enter

    if (this.props.isDisabled) {
      return;
    }

    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.value);
    }
  }
}

CustomerEmailEditor.propTypes = {
  autoFocus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  customerId: PropTypes.string,
  errors: PropTypes.shape({ email: PropTypes.instanceOf(Err), isPrimary: PropTypes.instanceOf(Err) }),
  initialValue: PropTypes.shape({ email: PropTypes.string, isPrimary: PropTypes.bool }).isRequired,
  isDisabled: PropTypes.bool,

  onBlur: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CustomerEmailEditor;
