import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import CallParticipant from 'components/phone_controls/participants/call_participant';
import { formatPhoneNumber } from 'models/phone_number';
import connect from 'components/lib/connect';
import PhoneCall from 'models/phone_call';

export const CustomerParticipant = ({ customerName, customerParticipant, showMenuOnHover = false }) => (
  <CallParticipant
    className="callParticipant-customer"
    displayName={customerName}
    key={customerParticipant.callId}
    participantId={customerParticipant.participantId}
    participantStatus={customerParticipant.status}
    showMenuOnHover={showMenuOnHover}
  />
);

CustomerParticipant.propTypes = {
  customerName: PropTypes.string,
  customerParticipant: PropTypes.shape({
    callId: PropTypes.string,
    participantId: PropTypes.string,
    status: PropTypes.string,
  }),
  showMenuOnHover: PropTypes.bool,
};

function mapStateToProps({ getProvider }) {
  let activeCallProvider = getProvider('activeCall');
  let activeCall = activeCallProvider.get();
  let customerName = activeCall.customer.name
    ? activeCall.customer.name
    : formatPhoneNumber(activeCall.conversationItem.content.customerNumber);
  let customerParticipant = _.find(activeCall.conversationItem.content.participants, {
    type: PhoneCall.ParticipantType.CUSTOMER,
  });
  return { customerName, customerParticipant };
}

export default connect(mapStateToProps, null)(CustomerParticipant);
