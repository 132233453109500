import _ from 'lodash';
import Observable from 'scripts/lib/observable_mixin';

const DEFAULT_TIMEOUT = 5000; // 5s

export default class LanguageGateway {
  constructor(http) {
    this.http = http;
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  fetch() {
    this.http.get(`/api/v1/orgs/${this.orgId}/configuration/languages`, this.onFetchResponse.bind(this), {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  onFetchResponse(err, res) {
    if (err != null || res.status !== 200) {
      this.notifyObservers('handleFetchError', err);
    } else {
      this.notifyObservers('handleFetchSuccess', res.response);
    }
  }
}

_.extend(LanguageGateway.prototype, Observable);
