import _ from 'lodash';

export default function parseTopicParams(canonicalTopic, actualTopic) {
  const vars = parseVars(canonicalTopic);
  const matchTopicRegex = canonicalTopic.replace(/:[^/]+/g, '([^/]+)');
  let matches = actualTopic.match(matchTopicRegex);

  let parsedParams = {};
  _.forEach(vars, (v, i) => {
    const match = matches && matches[i + 1];
    if (match) {
      parsedParams[v] = match;
    }
  });
  return parsedParams;
}

function parseVars(topic) {
  const regex = /:([^/]+)/g;
  let vars = [];
  let match = regex.exec(topic);
  while (match !== null) {
    vars.push(match[1]);
    match = regex.exec(topic);
  }
  return vars;
}
