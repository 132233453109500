import _ from 'lodash';
import CommunicationSimulator from './communication_simulator';
import Conversation from 'models/conversation';
import qconsole from 'scripts/lib/qconsole';

// instead of adding a new conversation, append the communication to an exisiting conversation
// and assign it to the current agent
export default class CommunicationReplySimulator extends CommunicationSimulator {
  _findConversation(communication, customer) {
    let conversation = _.last(customer.conversations);
    if (!conversation) {
      qconsole.warn(`No conversation found for ${customer.id} in ${this.attribute}`);
      return false;
    }

    conversation.assignee.agentId = this.database.get().currentAgent.id;
    conversation.status = Conversation.Status.OPEN;
    return conversation;
  }

  _createConversation() {
    return undefined;
  }
}
