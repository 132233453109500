import _ from 'lodash';

import reloadExternalData from 'actions/external_data/reload_external_data';
import requestCustomer from 'actions/customer/lib/request_customer';

const ITEM_CHUNK_SIZE = 40;

export default function rerequestCustomer(context, customerId) {
  requestCustomer(context, customerId, { isRefreshing: true });

  context.stores.customers.unmarkCustomerAsStale(customerId);
  reloadExternalData(context, customerId, false);

  const customerStores = context.stores.customers.storesFor(customerId);
  customerStores.conversationHistory.clearErrorForLoading();
  const conversationHistory = customerStores.conversationHistory.getProvider().findAll();

  const ids = _.map(conversationHistory, 'id');
  if (!_.isEmpty(ids)) {
    for (let i = 0; i < ids.length; i += ITEM_CHUNK_SIZE) {
      context.gateways.customerHistory.fetchConversationItems(customerId, {
        filter: { ids: ids.slice(i, i + ITEM_CHUNK_SIZE) },
      });
    }
  }
}
