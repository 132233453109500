import _ from 'lodash';
import React from 'react';

import AnswerListItem from 'components/customer/answer_panel/search/answer_list_item';

export default function AnswerList({ answers, searchText, selectedAnswerId }) {
  if (!answers) {
    return null;
  }

  return (
    <React.Fragment>
      {_.map(answers, answer => {
        const props = {
          answer,
          isFocused: answer.id === selectedAnswerId,
          key: answer.id,
          searchText,
        };
        return <AnswerListItem {...props} />;
      })}
    </React.Fragment>
  );
}
