import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0)" fill="#919191">
      <path d="M12.06 2.09a.2.2 0 00-.167-.09h-3.52a.2.2 0 00-.166.31l3.719 5.58a.2.2 0 010 .22l-3.719 5.58a.2.2 0 00.167.31h3.519a.2.2 0 00.166-.09l3.867-5.8a.2.2 0 000-.22l-3.867-5.8zM4.06 2.09A.2.2 0 003.892 2H.373a.2.2 0 00-.166.31l3.719 5.58a.2.2 0 010 .22L.207 13.69a.2.2 0 00.167.31h3.519a.2.2 0 00.166-.09l3.867-5.8a.2.2 0 000-.22l-3.867-5.8z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h16v16H0V0z" fill="#fff" />
      </clipPath>
    </defs>
  </Icon>
);
