import _ from 'lodash';
import classnames from 'classnames';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import PopoverCard from 'components/common/popover_card';
import SearchInput from 'components/common/search_input';
import WindowSizeWatcher from 'components/common/utilities/window_size_watcher';

export default class SearchMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areResultsVisible: false,
      items: [],
      search: props.search,
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handleClickInput = this.handleClickInput.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.setBoundingRef = this.setBoundingRef.bind(this);
  }

  static getDerivedStateFromProps({ search }, state) {
    if (search !== state.search && state.searchText) {
      const items = search.search(state.searchText);
      return {
        items: items.slice(0, 20),
        search,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedItemIds.length > prevProps.selectedItemIds.length) {
      this.searchInput.clearValue();
      this.searchInput.focusAtEnd();
      this.setState({
        items: [],
        searchText: '',
      });
    }
  }

  render() {
    let items = null;
    if (this.state.areResultsVisible) {
      if (this.state.items.length) {
        items = this.state.items;
      } else if (this.state.searchText) {
        items = [];
      } else if (!this.props.selectedItemIds.length && this.props.emptySelectedDefaultItems) {
        items = this.props.emptySelectedDefaultItems;
      } else if (this.props.showAllByDefault) {
        items = this.props.search.list;
      }
    }

    return (
      <React.Fragment>
        <span className="searchFilterMenu-inputWrapper" ref={this.setBoundingRef}>
          <SearchInput
            autoFocus={false}
            delay={100}
            onBlur={this.handleBlur}
            onChange={this.handleChangeSearchText}
            onClick={this.handleClickInput}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            placeholder={this.props.placeholder}
            ref={node => (this.searchInput = node)}
          />
        </span>
        <SearchResults
          input={this.state.bounding}
          items={items}
          onSelectItem={this.handleSelectItem}
          ref={ref => (this.results = ref)}
          renderRow={this.props.renderRow}
        />
      </React.Fragment>
    );
  }

  renderRow(item) {
    return this.props.renderRow({ item, onClickItem: this.handleSelectItem });
  }

  setBoundingRef(node) {
    this.setState({ bounding: node });
  }

  handleBlur() {
    this.setState({ areResultsVisible: false });
  }

  handleChangeSearchText(evt) {
    const searchText = evt.target.value;
    if (searchText !== this.state.searchText && this.results) {
      this.results.resetScrollTop();
    }

    this.setState((state, props) => ({
      areResultsVisible: true,
      items: props.search.search(searchText).slice(0, 20),
      searchText,
    }));
  }

  handleFocus() {
    this.setState({ areResultsVisible: true });
  }

  handleClickInput() {
    this.setState({ areResultsVisible: true });
  }

  handleKeyDown(evt) {
    if (evt.key === 'ArrowDown') {
      evt.preventDefault();
      this.results.moveDown();
    } else if (evt.key === 'ArrowUp') {
      evt.preventDefault();
      this.results.moveUp();
    } else if (evt.key === 'Enter') {
      evt.preventDefault();
      this.results.selectFocusedItem();
    }
  }

  handleSelectItem(id) {
    this.setState({ areResultsVisible: false });
    this.props.onAddItem(id);
  }
}

SearchMenu.propTypes = {
  emptySelectedDefaultItems: PropTypes.arrayOf(PropTypes.object),
  onAddItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  renderRow: PropTypes.func.isRequired,
  search: PropTypes.shape({
    list: PropTypes.array,
    search: PropTypes.func.isRequired,
  }).isRequired,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string),
  showAllByDefault: PropTypes.bool,
};

/**
 * Keyboard navigable list of results
 */
export class SearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focusIndex: -1, items: props.items };

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleMouseMove = _.throttle(this.handleMouseMove.bind(this), 100);
    this.handleHover = this.handleHover.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.items || props.items !== state.items) {
      return { focusIndex: -1, items: props.items };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.focusIndex !== prevState.focusIndex && this.state.focusIndex > -1) {
      this.scrollItemIntoView();
    }
  }

  render() {
    if (!this.props.items) {
      return null;
    }

    let results;
    if (this.props.items.length === 0) {
      results = <div className="searchFilterMenu-emptyResults">No results. Try refining your search</div>;
    } else {
      results = _.map(this.props.items, (item, index) => {
        const result = this.props.renderRow({
          item,
          onClickItem: this.handleClickItem,
        });

        if (result.type !== SearchResult) {
          return result;
        }
        return React.cloneElement(result, {
          isFocused: index === this.state.focusIndex,
          onHover: this.handleHover,
        });
      });
    }

    return (
      <WindowSizeWatcher>
        {({ windowHeight }) => (
          <StyledPopoverCard
            autoPosition
            bounds={{
              bottom: windowHeight - 10,
              left: 30,
              top: 70,
            }}
            className="searchFilterMenuCard"
            isVisible={!!this.props.items}
            margin={8}
            position="bottom"
            targetElement={this.props.input}
          >
            <div
              className="searchFilterMenu-results"
              onMouseMove={this.handleMouseMove}
              ref={ref => (this.results = ref)}
            >
              {results}
            </div>
          </StyledPopoverCard>
        )}
      </WindowSizeWatcher>
    );
  }

  handleClickItem(id) {
    this.props.onSelectItem(id);
  }

  handleHover(id) {
    if (!this.ignoreHoverEvents) {
      const focusIndex = _.findIndex(this.props.items, item => item.id === id);
      if (focusIndex !== this.state.focusIndex) {
        this.setState({
          focusIndex,
        });
      }
    }
  }

  handleMouseMove() {
    this.ignoreHoverEvents = false;
  }

  moveDown() {
    this.setState(({ focusIndex, items }) => {
      if (items && focusIndex < items.length - 1) {
        return { focusIndex: focusIndex + 1 };
      }
    });
  }

  moveUp() {
    this.setState(({ focusIndex, items }) => {
      if (items && focusIndex > 0) {
        return { focusIndex: focusIndex - 1 };
      }
    });
  }

  resetScrollTop() {
    if (!this.results) return;
    this.results.scrollTop = 0;
  }

  scrollItemIntoView() {
    if (!this.results) return;

    const resultsRect = this.results.getBoundingClientRect();
    const itemRect = this.results.children[this.state.focusIndex].getBoundingClientRect();

    // When we're programmatically scrolling the results list, if our mouse is hovered over the results list, then
    // when the results list moves, it will trigger the hover event for the item underneath the mouse. This is not
    // desired, as it will change the focus from what our arrow keys are selecting to whatever our mouse is hovered
    // over. So we ignore hover events after scrolling _until_ the mouse moves.
    this.ignoreHoverEvents = true;
    if (itemRect.bottom > resultsRect.bottom) {
      this.results.scrollTop = this.results.scrollTop + (itemRect.bottom - resultsRect.bottom);
    } else if (itemRect.top < resultsRect.top) {
      this.results.scrollTop = this.results.scrollTop - (resultsRect.top - itemRect.top);
    } else {
      this.ignoreHoverEvents = false;
    }
  }

  selectFocusedItem() {
    if (this.props.items && this.state.focusIndex > -1) {
      const item = this.props.items[this.state.focusIndex];
      item && this.props.onSelectItem(item.id);
    }
  }
}

SearchResults.propTypes = {
  input: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  onSelectItem: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
};

const StyledPopoverCard = styled(PopoverCard)`
  padding: 0;
`;

export function SearchResultBase({ id, isFocused, onHover, onMouseDown, primary, secondary }) {
  const classNames = classnames('searchFilterResult', {
    'searchFilterResult-focused': isFocused,
  });

  return (
    <div className={classNames} onMouseDown={onMouseDownBase} onMouseOver={onMouseOver}>
      <span className="searchFilterResult-primary" title={primary}>
        {primary}
      </span>
      <span className="searchFilterResult-secondary" title={secondary}>
        {secondary}
      </span>
    </div>
  );

  function onMouseDownBase(evt) {
    evt.preventDefault();
    onMouseDown(id);
  }

  function onMouseOver(evt) {
    onHover(id);
  }
}

SearchResultBase.propTypes = {
  id: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
};

const SearchResult = React.memo(SearchResultBase);
export { SearchResult };

const SEARCH_OPTIONS = {
  distance: 100,
  location: 0,
  keys: ['name'],
  tokenize: true, // makes things work when there are non-alphanumeric characters in search strings
  shouldSort: false, // Maintains initial sort order
  threshold: 0.1,
};

export class SearchCache extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getFuzzySearch(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.immutable !== this.props.immutable || !_.isEqual(nextProps.excludedIds, this.props.excludedIds)) {
      this.setState(this.getFuzzySearch(nextProps));
    }
  }

  getFuzzySearch({ excludedIds, includeExtraItems, itemSelect, itemTransformer, provider, select, sortField }) {
    const excludedIdsSet = new Set(excludedIds);
    if (!select) {
      select = ['id', 'name'];
    }
    if (itemSelect) {
      select = [...select, ...itemSelect];
    }
    if (!sortField) {
      sortField = 'name';
    }
    let query = {
      filter: item => !excludedIdsSet.has(item.id),
      sortBy: i => (i[sortField] ? i[sortField].toLowerCase() : i.name.toLowerCase()),
    };
    if (select.length > 0) {
      query.select = select;
    }
    let items = provider.findAll(query);
    if (includeExtraItems) {
      items = _.filter(includeExtraItems(items), i => !excludedIdsSet.has(i.id));
    }
    if (itemTransformer) {
      items = _.map(items, item => itemTransformer(item));
    }

    const search = new Fuse(items, SEARCH_OPTIONS);
    return { search };
  }

  render() {
    return this.props.children({ search: this.state.search });
  }
}

SearchCache.propTypes = {
  excludedIds: PropTypes.arrayOf(PropTypes.string),
  immutable: PropTypes.object.isRequired,
  includeExtraItems: PropTypes.func,
  itemSelect: PropTypes.arrayOf(PropTypes.string),
  itemTransformer: PropTypes.func,
  provider: PropTypes.object.isRequired,
  select: PropTypes.arrayOf(PropTypes.string),
  sortField: PropTypes.string,
  children: PropTypes.func.isRequired,
};
