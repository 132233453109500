export const CUSTOM_STYLE_MAP = {
  CODE: {},
  SMALL: {
    fontSize: '9px',
  },
  NORMAL: {},
  LARGE: {
    fontSize: '18px',
  },
  HUGE: {
    fontSize: '40px',
  },
};

export const CUSTOM_STYLE_PREFIX_COLOR = 'color_';

// This is straight copy-pasted from this blog post:
// https://mdcox.net/posts/up-and-running-with-draftjs.html
export function customStyleFn(style) {
  const styleNames = style.toJS();
  return styleNames.reduce((styles, styleName) => {
    if (styleName.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
      styles.color = styleName.split(CUSTOM_STYLE_PREFIX_COLOR)[1];
    }
    return styles;
  }, {});
}
