/**
 * Detects various runtime browser capabilities. Intended to be used with analytics etc.
 *
 * @returns {*}
 */
export default function getRuntimeFeatures() {
  return {
    hasServiceWorker: hasServiceWorker(),
  };
}

function hasServiceWorker() {
  return 'serviceWorker' in navigator;
}
