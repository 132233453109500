import * as Broadcast from 'broadcast-channel'; // eslint-disable-line import/no-unresolved

// Hack to allow Jasmine to mock it out

const BroadcastChannel = Broadcast.BroadcastChannel;

const getBroadcastChannel = () => {
  return BroadcastChannel;
};

const getBc = {
  getBroadcastChannel,
};

export default getBc;
