import d3 from 'd3';
import React from 'react';
import PropTypes from 'prop-types';

import Checklist from 'components/lib/checklist';

let getCachedCounts = options => {
  return options.reduce((counts, option) => {
    counts[option.value] = option.count;
    return counts;
  }, {});
};

export const optionRenderer = option => {
  let { value, count, label } = option;
  return (
    <div className="checklist-option-label" htmlFor={value}>
      <span className="checklist-option-label-name">{label || value}</span>
      <span className="checklist-option-label-count">{count && d3.format(',')(count)}</span>
    </div>
  );
};

class ChecklistFilterWithCounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedCounts: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isLoading && nextProps.isLoading) {
      this.setState({ cachedCounts: getCachedCounts(this.props.options) });
    }
  }

  render() {
    let { options } = this.props;
    if (this.props.isLoading) {
      // Use old option counts while loading
      options = options.map(option => {
        return {
          ...option,
          count: this.state.cachedCounts[option.value],
        };
      });
    }
    return <Checklist onChange={this.props.onChange} optionRenderer={optionRenderer} options={options} />;
  }
}

ChecklistFilterWithCounts.propTypes = {
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      count: PropTypes.number,
      isSelected: PropTypes.bool,
    })
  ),
};

export default ChecklistFilterWithCounts;
