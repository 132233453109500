import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from 'components/common/spinner';
import StackContainer from 'components/common/containers/stack_container';

import Portal from 'components/common/utilities/portal';

const spinnerPortalId = 'SPINNER_PORTAL';

/**
 * @visibleName Full Page Spinner
 */

const FullPageSpinner = props => {
  const node = document && document.getElementById(spinnerPortalId);
  return (
    <Portal node={node}>
      <SpinnerBlocker />
      <SpinnerContainer>
        <StackContainer stack="small">
          <FullSizeSpinnerContainer>
            <Spinner {...props} />
          </FullSizeSpinnerContainer>
        </StackContainer>
        {props.children}
      </SpinnerContainer>
    </Portal>
  );
};

FullPageSpinner.propTypes = {
  /** Optional. Any children will be rendered below the Spinner */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export const SpinnerPortal = styled.div.attrs({ id: spinnerPortalId })`
  position: relative;
`;

const FullSizeSpinnerContainer = styled.div`
  width: 120px;
`;

const SpinnerBlocker = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${p => p.theme.colors.white};
  opacity: 0.6;
  position: fixed;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  margin-top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
`;

export default FullPageSpinner;
