import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { findHighlightMatches } from './lib/highlight_text';

const baseClass = 'customerConversationCard-customerInfo-customerField';

function CustomerFieldWithHighlights({ label, value }) {
  let matches = findHighlightMatches(value);
  if (!matches || matches.length === 0) {
    return null;
  }

  return (
    <div className={baseClass}>
      <span className={cn('label')}>{label}</span>
      <span className={cn('value')}>
        {_.map(matches, match => {
          return (
            <span className={cn('value-wrapper')} key={`customerInfo-value-${value.indexOf(match[1])}`}>
              ...
              <span className="customerConversationCard-customerInfo-highlight">{match[1]}</span>
            </span>
          );
        })}
      </span>
    </div>
  );
}

CustomerFieldWithHighlights.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default CustomerFieldWithHighlights;

function cn(...args) {
  let res = classnames(...args);
  return res
    .split(' ')
    .map(c => `${baseClass}-${c}`)
    .join(' ');
}
