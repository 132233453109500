import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'CustomChannel',
  properties: {
    id: prop(String).isRequired,

    archived: prop(Boolean),
    icon: prop(String).isRequired,
    name: prop(String).isRequired,

    agentInitiatedConversations: prop(Boolean),
    allowAttachments: prop(Boolean),
    displayAddressInProfile: prop(Boolean),
    messageGroupingEnabled: prop(Boolean),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
