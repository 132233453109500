import qconsole from 'scripts/lib/qconsole';

export default class ReactivateUpgradeNotification {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    let notification = this.context.stores.notifications.findBy({ id });
    if (!notification) {
      qconsole.log(`Could not find and re-activate optional upgrade notification [${id}]`);
      return;
    }

    notification.activate();
    this.context.stores.notifications.replace(notification);
    qconsole.log(`Re-activated deferred upgrade notification [${id}]`);
  }
}
