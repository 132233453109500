import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import Liveboards from 'models/location/liveboards';
import LiveboardType from 'models/liveboards/liveboard_type';
import pollLiveboard from 'actions/liveboards/poll_liveboard';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import TrackEvent from 'actions/analytics/track_event';

let stopPolling = () => {};

export default class OpenLiveboard {
  constructor(context) {
    this.context = context;
  }

  run({ type, routingGroupIds, teamIds, timezone }) {
    const nextLocation = Liveboards.create({ type, routingGroupIds, teamIds, timezone });

    changeCurrentLocation(this.context, nextLocation);

    stopPolling = pollLiveboard(this.context, {
      liveboardType: nextLocation.type,
      query: {
        routingGroupIds: nextLocation.routingGroupIds,
        teamIds: nextLocation.type === LiveboardType.AGENTS && nextLocation.teamIds,
        timezone: nextLocation.timezone,
      },
    });

    this.context.executeAction(TrackEvent, {
      event: 'Liveboard Opened',
      props: {
        inboxCount: nextLocation.routingGroupIds ? nextLocation.routingGroupIds.length : 0,
        roles: this.context.stores.auth.get().getRoleIds() || [],
        routingGroupIds: nextLocation.routingGroupIds,
        teamCount: nextLocation.type === LiveboardType.AGENTS && nextLocation.teamIds ? nextLocation.teamIds.length : 0,
        teamIds: nextLocation.type === LiveboardType.AGENTS ? nextLocation.teamIds : undefined,
        timezone: nextLocation.timezone,
        type: nextLocation.type,
      },
    });

    this.context.executeAction(SetAndLogActivity, {
      type: AgentActivity.Type.LIVEBOARDS,
    });
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }

  static stopPolling() {
    stopPolling();
  }
}
