import React from 'react';

import { PLACEHOLDER } from './placeholders';

export default function PlaceholderEditing() {
  return {
    renderNode(props, editor, next) {
      const { attributes, children, node } = props;
      switch (node.type) {
        case PLACEHOLDER:
          return (
            <span className="draftEditor-placeholder" {...attributes}>
              {children}
            </span>
          );
        default:
          return next();
      }
    },

    schema: {
      inlines: {
        [PLACEHOLDER]: {
          nodes: [
            {
              match: { object: 'text' },
              min: 1,
              max: 1,
            },
          ],
          text: /.+/, // Can't have empty placeholders
        },
      },
    },
  };
}
