import { Factory } from 'rosie';
import '../conversation_item_factory';
import './context_event_factory';

Factory.define('MobileContext')
  .attr('type', 'MOBILE_CONTEXT');

Factory.define('MobileContextWithDefaults')
  .extend('MobileContext')
  .attr('events', ['events'], events => (events || [{}]).map(
    (event) => Factory.attributes('ContextEventWithDefaults', event)))
  .attr('username', 'Sully');

Factory.define('MobileContextWithVideo')
  .extend('MobileContext')
  .attr('username', 'Sully')
  .attr('video', { type: 'mp4', url: 'video.mp4' });

Factory.define('MobileContextItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function (content) {
    return Factory.attributes('MobileContext', content);
  });

Factory.define('MobileContextItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function (content) {
    return Factory.attributes('MobileContextWithDefaults', content);
  });
