import { createContentModel } from './composition_content_shared';

const PhoneCallCompositionContent = createContentModel({
  modelName: 'PhoneCallCompositionContent',

  properties: {
    companyNumber: String,
    customerNumber: String,
  },

  isEmpty() {
    return false;
  },
});

export default PhoneCallCompositionContent;
