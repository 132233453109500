import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Checkbox from 'components/common/checkbox';
import ControlContainer from 'components/common/forms/horizontal_control_container';
import DropdownMenu from 'components/common/dropdown_menu';
import { FormInputSectionTypes } from 'models/external_action_form';
import Input from 'components/common/input';

export default function InputSection({ error, section, value, updateState }) {
  let ControlInput = getInputComponent(_.get(section, 'input.type', null));
  const onChange = useCallback(
    newValue => {
      updateState({
        [section.attr]: newValue,
      });
    },
    [section, updateState]
  );

  if (!ControlInput) {
    return null;
  }
  return (
    <ControlContainer
      control={<ControlInput attr={section.attr} input={section.input} onUpdate={onChange} value={value} />}
      data-aid={section.attr}
      description={section.hint}
      error={error}
      label={section.label}
    />
  );
}

InputSection.propTypes = {
  error: PropTypes.string,
  section: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  value: PropTypes.any,
  updateState: PropTypes.func.isRequired,
};

function getInputComponent(type) {
  switch (type) {
    case FormInputSectionTypes.text:
      return TextInputSection;
    case FormInputSectionTypes.select:
      return DropdownInput;
    case FormInputSectionTypes.checkbox:
      return CheckboxInput;
    default:
      return null;
  }
}

export function TextInputSection({ input = {}, onUpdate, value, attr }) {
  const onChange = e => onUpdate(e.target.value);
  return (
    <StyledInput
      className="externalForm-inputSection-text"
      data-aid={`${attr}-text-input`}
      onChange={onChange}
      placeholder={input.placeholder}
      value={value}
    />
  );
}

const StyledInput = styled(Input)`
  border-radius: 4px;
  border: 1px solid ${p => p.theme.colors.gray300};
  padding-left: ${p => p.theme.spacing.insetSmall};
`;

TextInputSection.propTypes = {
  input: PropTypes.shape({
    placeholder: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  attr: PropTypes.string,
};

export function DropdownInput({ input = {}, onUpdate, value, attr }) {
  let options = _.map(input.options, option => {
    return {
      label: option.text,
      value: option.value,
    };
  });

  return (
    <DropdownMenu
      className="externalForm-inputSection-dropdown"
      data-aid={`${attr}-dropdown`}
      onSelect={onUpdate}
      options={options}
      placeholder={input.placeholder}
      searchable
      value={value}
    />
  );
}

DropdownInput.propTypes = {
  input: PropTypes.shape({
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  attr: PropTypes.string,
};

export function CheckboxInput({ input = {}, onUpdate, value, attr }) {
  const onChange = e => onUpdate(!value);

  return (
    <Checkbox
      checked={value}
      data-aid={`${attr}-checkbox`}
      label={input.text}
      onChange={onChange}
      value="formCheckbox"
      wrapperClassName="externalForm-inputSection-checkbox"
    />
  );
}

CheckboxInput.propTypes = {
  input: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  attr: PropTypes.string,
};
