import React, { useMemo } from 'react';
import PropType from 'prop-types';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import { AITextCompletionContextProvider } from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import { AITextCompletionButtons } from 'components/text_editor_new/components/controls/ai_text_rewrite_controls';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import {
  AnswerPanelControl,
  BoldItalicUnderlineControls,
  ListControls,
  Separator,
} from 'components/composer/shared/editor_controls_new';
import { EditorContainer } from 'components/composer/shared/editor_new';
import { LinkButton } from 'components/composer/inline_links/link_button';
import { LinkEditor } from 'components/composer/inline_links/link_editor';
import AddNote from 'actions/composer/add_note';
import Composer, { Header, NewHeaderText } from 'components/composer/shared/composer_new';
import ComposerEditor from 'components/composer/shared/composer_editor';
import FontColorControls from 'components/composer/shared/font_color_controls';
import createAITextCompletion from 'components/text_editor_new/plugins/ai/ai_text_completion';
import createAITextCompletionHighlight from 'components/text_editor_new/plugins/ai/ai_text_completion/ai_text_completion_highlight';
import createSuggestedReplies from 'components/text_editor_new/plugins/suggested_replies/create_suggested_replies';
import EditorErrors from 'components/composer/shared/editor_errors';
import { isMacOS } from 'scripts/lib/browser_detector';
import serializeHtmlExternal from 'components/text_editor_new/lib/serialize_html_external';
import TextAlignmentMenu from 'components/composer/shared/editor_alignment_controls_new';
import useComposer from 'components/composer/shared/use_composer';
import useEditor from 'components/composer/shared/use_editor';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import useRichTextEditorPlugins from 'components/text_editor_new/hooks/use_rich_text_editor_plugins';
import withRefreshEditorWhenFlagsChange from 'components/composer/shared/with_refresh_editor_when_flags_change';
import withStaticId from 'components/customer/lib/with_static_id';
import { useSubmitOnHotkey } from 'components/composer/shared/use_submit_on_hotkey';

export function NoteComposer({ requestorId }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const composerProps = useComposer({
    action: AddNote,
    options: { trimEmptyParagraphs: true, trimParagraphWhitespace: true },
  });
  const includeAIFeatures = isFeatureEnabled('gclDemo2023');
  const isSuggestedRepliesEnabled = isFeatureEnabled('suggestedReplies');
  const editorProps = useEditor('bodyHtml', composerProps, { serializer: serializeHtmlExternal });

  const extraPlugins = useExtraPlugins({ includeAIFeatures, isSuggestedRepliesEnabled });
  const editorRef = useRichTextEditorPlugins(composerProps, editorProps, extraPlugins);

  // Register keyboard hotkeys that result in submitting the composition. For historic reason, we use both Meta+Enter
  // and Ctrl+Enter on Mac and just Ctrl+Enter on Windows
  const keyboardHotkeys = ['Mod+Enter'];
  if (isMacOS()) keyboardHotkeys.push('Control+Enter');
  useSubmitOnHotkey(editorRef.current, editorProps.onSubmit, keyboardHotkeys);

  const authoringControls = (
    <>
      {includeAIFeatures ? <AITextCompletionButtons /> : null}
      <AnswerPanelControl />
      <Separator />
    </>
  );

  const composer = (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      excludeErrors
      includeAttachments
      initialHtml={editorProps.initialHtml}
      submitText="Add Note"
      {...composerProps}
    >
      <Header>
        <NewHeaderText>Note</NewHeaderText>
      </Header>

      <EditorContainer excludeAnswerMenuButton includeAttachments>
        <ComposerEditor
          attr="bodyHtml"
          includeAIFeatures={includeAIFeatures}
          placeholder="Type to enter note"
          requestorId={requestorId}
          showFloatingToolbar
          {...editorProps}
        />
        <LinkEditor hotKey="mod+k" />
      </EditorContainer>

      <ActionBar>
        {authoringControls}
        <BoldItalicUnderlineControls {...editorProps} />
        <FontColorControls {...editorProps} />
        <TextAlignmentMenu {...editorProps} />
        <ListControls {...editorProps} />
        <>
          <Separator />
          <LinkButton />
        </>
        <Emoji {...editorProps} />
        <Attachment />
      </ActionBar>

      <EditorErrors />
    </Composer>
  );

  if (includeAIFeatures) {
    return <AITextCompletionContextProvider requestorId={requestorId}>{composer}</AITextCompletionContextProvider>;
  }
  return composer;
}

NoteComposer.propTypes = {
  requestorId: PropType.string,
};

function useExtraPlugins({ includeAIFeatures, isSuggestedRepliesEnabled }) {
  return useMemo(() => {
    let extraPlugins = [];
    if (includeAIFeatures) {
      extraPlugins = extraPlugins.concat([createAITextCompletion(), createAITextCompletionHighlight()]);
    }

    if (isSuggestedRepliesEnabled) {
      extraPlugins = extraPlugins.concat([createSuggestedReplies()]);
    }

    return extraPlugins;
  }, [includeAIFeatures, isSuggestedRepliesEnabled]);
}

export default React.memo(withStaticId(withRefreshEditorWhenFlagsChange(NoteComposer), 'requestorId'));
