import React, { useEffect, useState, useRef } from 'react';

export default function FakeTyping({ text }) {
  const timerRef = useRef();
  const textRef = useRef(text);
  const [index, setIndex] = useState(0);

  const displayText = textRef.current.slice(0, index);

  // This does the actual incrementing - we increase the typing index by 1 every 5-20ms.
  useEffect(() => {
    if (index >= textRef.current.length) {
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const nextIndex = getNextWordIndex(index, textRef.current);
      setIndex(nextIndex);
    }, Math.random() * 105 + 5);
    return () => {
      clearTimeout(timerRef);
    };
  }, [index]);

  // Reset the typing thing when the text changes
  useEffect(() => {
    textRef.current = text;

    return () => {
      setIndex(0);
      clearTimeout(timerRef.current);
    };
  }, [text]);

  return (
    <span>
      {'\u200b'}
      {displayText}
    </span>
  );
}

function getNextWordIndex(index, text) {
  const nextSpace = text.indexOf(' ', index);
  if (nextSpace === -1) {
    return text.length;
  }
  return nextSpace + 1;
}
