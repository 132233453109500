import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import AddCustomerRelationshipMenu from 'scripts/presentation/components/customer/conversation_history/conversation_items_v2/menu/pin_to_customer/add_customer_relationship_menu';
import connect from 'components/lib/connect';

export class PinToCustomerMenu extends PureComponent {
  render() {
    return <AddCustomerRelationshipMenu {...this.props} title="Pin Item to Another Customer" />;
  }
}

function mapStateToProps({ getProvider }, { conversationItemId }) {
  const convoHistoryProvider = getProvider('conversationHistory');
  const item = convoHistoryProvider.find(conversationItemId);
  const currLocation = getProvider('currentLocation').get();

  let omittedCustomerIds = item.linkedItems.map(i => i.customerId);

  return {
    currentCustomerId: currLocation.customerId,
    omittedCustomerIds,
  };
}

const PinToCustomerMenuContainer = connect(mapStateToProps)(PinToCustomerMenu);

PinToCustomerMenuContainer.propTypes = {
  className: PropTypes.string,
  conversationItemId: PropTypes.string,
  currentCustomerId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

PinToCustomerMenu.propTypes = {
  ...PinToCustomerMenuContainer.propTypes,
  omittedCustomerIds: PropTypes.arrayOf(PropTypes.string),
};

export default PinToCustomerMenuContainer;
