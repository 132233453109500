import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from '../lib/create_model';
import InteractionType from 'models/interaction_type';
import LiveboardType from 'models/liveboards/liveboard_type';
import { PreferencesStatus } from '../agent_routing_preferences';

export const ActiveSession = createModel({
  modelName: 'ActiveSession',
  properties: {
    conversationId: String,
    customerId: String,
    startedAt: String,
    sessionTypes: [String],
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const WorkType = createEnum('ACT', 'HANDLE', 'UNKNOWN');

export const SessionWork = createModel({
  modelName: 'SessionWork',
  properties: {
    customerId: String,
    sessionType: prop().oneOf(..._.values(InteractionType)),
    workType: prop().oneOf(..._.values(WorkType)),
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const RoutingPreferences = createModel({
  modelName: 'RoutingPreferences',
  properties: {
    status: prop().oneOf(..._.values(PreferencesStatus)),
    updatedAt: String,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const UtilizationStats = createModel({
  modelName: 'UtilizationStats',
  properties: {
    agentId: String,
    status: String,
    statusUpdatedAt: String,
    presenceUpdatedAt: String,
    routingPreferences: RoutingPreferences,
    availableChannels: [String],
    onCall: Boolean,
    activity: String,
    statusReasonId: String,
    activeSessions: [ActiveSession],
    sessionWork: SessionWork,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default createModel({
  modelName: 'AgentsLiveboard',
  properties: {
    utilization: [UtilizationStats],
    timezone: prop(String).default('America/New_York'),
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: LiveboardType.AGENTS });
  },
});
