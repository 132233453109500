import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import ProfileAvatar from 'components/lib/profile_avatar';

export function CustomerProfileAvatar({ className, name, image }) {
  return <ProfileAvatar profile={{ name, image }} wrapperClassName={className} />;
}

CustomerProfileAvatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
};

const CustomerProfileAvatarContainer = connect(mapStateToProps)(CustomerProfileAvatar);

function mapStateToProps({ getProvider }) {
  const profile = getProvider('profile').get();
  return {
    name: (profile && profile.name) || '',
    image: profile && profile.getImageUrl(),
  };
}

export default CustomerProfileAvatarContainer;
