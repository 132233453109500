import ConversationItemType from 'models/conversation_item_type';

// using reduce to work around the "Duplicate data property in object literal not allowed in strict mode" error
export const CHANNEL_ACTION_TEXT_WITH_SUBJECT = Object.freeze(
  [
    [ConversationItemType.CHAT, 'sent a chat'],
    [ConversationItemType.CHAT_MESSAGE, 'sent a chat'],
    [ConversationItemType.CHAT_SELF_SERVICE_METADATA, 'used Glad App'],
    [ConversationItemType.CONVERSATION_NOTE, 'noted'],
    [ConversationItemType.CUSTOM_CHANNEL_MESSAGE, 'sent a message via %channel_name%'],
    [ConversationItemType.EMAIL, 'emailed'],
    [ConversationItemType.EMAIL_PREVIEW, 'emailed'],
    [ConversationItemType.FB_MESSAGE_INCOMING, 'messaged via Facebook'],
    [ConversationItemType.FB_MESSAGE_OUTGOING, 'messaged via Facebook'],
    [ConversationItemType.HELP_APP_CONTEXT, 'opened the help app'],
    [ConversationItemType.HOTEL_RESERVATION, 'booked a room'],
    [ConversationItemType.MOBILE_CONTEXT, 'interacted'],
    [ConversationItemType.PHONE_CALL, 'called'],
    [ConversationItemType.PHONE_CALLBACK, 'scheduled a call'],
    [ConversationItemType.SMS, 'sent an SMS'],
    [ConversationItemType.TASK, 'added a task'],
    [ConversationItemType.TWITTER, 'tweeted'],
    [ConversationItemType.VOICEMAIL, 'left voicemail'],
    [ConversationItemType.WEB_CONTEXT, 'interacted'],
    [ConversationItemType.WEB_FORM, 'submitted form'],
  ].reduce((memo, type) => {
    memo[type[0]] = type[1];
    return memo;
  }, {})
);

export const CHANNEL_ACTION_TEXT_NO_SUBJECT = Object.freeze(
  [
    [ConversationItemType.CHAT, 'Sent a chat'],
    [ConversationItemType.CHAT_MESSAGE, 'Sent a chat'],
    [ConversationItemType.CHAT_SELF_SERVICE_METADATA, 'Used Glad App'],
    [ConversationItemType.EMAIL, 'Emailed'],
    [ConversationItemType.EMAIL_PREVIEW, 'Emailed'],
    [ConversationItemType.FB_MESSAGE_INCOMING, 'Messaged'],
    [ConversationItemType.FB_MESSAGE_OUTGOING, 'Messaged'],
    [ConversationItemType.HELP_APP_CONTEXT, 'Help App opened'],
    [ConversationItemType.MOBILE_CONTEXT, 'Interacted'],
    [ConversationItemType.PHONE_CALL, 'Called'],
    [ConversationItemType.SMS, 'SMS sent'],
    [ConversationItemType.TASK, 'Task added'],
    [ConversationItemType.TWITTER, 'Tweeted'],
    [ConversationItemType.VOICEMAIL, 'Called'],
    [ConversationItemType.WEB_CONTEXT, 'Interacted'],
    [ConversationItemType.WEB_FORM, 'Form submitted'],
  ].reduce((memo, type) => {
    memo[type[0]] = type[1];
    return memo;
  }, {})
);

export const CHANNEL_REPLY_TEXT = Object.freeze(
  [
    [ConversationItemType.CHAT, 'chat'],
    [ConversationItemType.CHAT_MESSAGE, 'chat'],
    [ConversationItemType.EMAIL, 'email'],
    [ConversationItemType.EMAIL_PREVIEW, 'email'],
    [ConversationItemType.FB_MESSAGE_OUTGOING, 'Facebook'],
    [ConversationItemType.PHONE_CALL, 'phone'],
    [ConversationItemType.TWITTER, 'tweet'],
    [ConversationItemType.SMS, 'SMS'],
  ].reduce((memo, type) => {
    memo[type[0]] = type[1];
    return memo;
  }, {})
);
