import React from 'react';

export default function Messaging() {
  return (
    <svg className="availabilityIcon availabilityIcon-messaging" height="32" width="32">
      <g fill="none" fillRule="evenodd" stroke="#111" transform="translate(1 1)">
        <rect height="29" rx="14.5" width="29" x=".5" y=".5" />
        <path
          d="M16.082 20.5l-3.707 3.707V20.5h-.25C8.755 20.5 6 17.745 6 14.375S8.755 8.25 12.125 8.25h6c3.37 0 6.125 2.755 6.125 6.125S21.495 20.5 18.125 20.5h-2.043z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
