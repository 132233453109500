import React from 'react';
import styled from 'styled-components';

import { EmptyContent, EmptyState, EmptyText } from 'components/customer/answer_panel/lib/empty_state';
import BooksIcon from 'components/lib/icons/books_icon';

export default function EmptyAnswers() {
  return (
    <EmptyAnswersContainer>
      <StyledBooksIcon />
      <StyledEmptyText>No Answers found</StyledEmptyText>
      <EmptyContent>
        Try a different keyword, reframe your question, adjust your filters, or check for typos.
      </EmptyContent>
    </EmptyAnswersContainer>
  );
}

const EmptyAnswersContainer = styled(EmptyState)`
  padding: 0px 20px;
`;

const StyledBooksIcon = styled(BooksIcon)`
  .sparkle-1 {
    fill: ${p => p.theme.colors.gray300};
  }
  .sparkle-2 {
    fill: ${p => p.theme.colors.gray100};
  }
  .sparkle-3 {
    fill: ${p => p.theme.colors.gray300};
  }
  .sparkle-4 {
    fill: ${p => p.theme.colors.gray100};
  }
  .bookPages-2 {
    fill: ${p => p.theme.colors.gray100};
  }
  .bookCover-2 {
    fill: ${p => p.theme.colors.gray500};
  }
`;

const StyledEmptyText = styled(EmptyText)`
  padding-top: 0;
`;
