import _ from 'lodash';
import moment from 'moment';

import './assignee_factory';
import './conversation_item_factory';
import './conversation_items/email_message_factory';
import './conversation_items/routing_item_factory';
import './lib/id';
import { Factory } from 'rosie';

Factory.define('Conversation')
  .extend('Id')
  .attr('closedAt', null)
  .attr('createdAt', () => moment().toISOString())
  .attr('sla', () => {
    return { dueAt: moment().toISOString() };
  })
  .attr('assignee', null)
  .attr('customAttributes', [])
  .attr('topicIds', [])
  .attr('updatedAt', '')
  .attr('conversationItems', ['conversationItems', 'id'], (conversationItems, id) => {
    return (conversationItems || []).map(ci => {
      ci.conversationId = id;
      return ci;
    });
  })
  .attr(
    'conversationItemIds',
    ['conversationItemIds', 'conversationItems'],
    (conversationItemIds, conversationItems) => {
      conversationItemIds = conversationItemIds || [];
      return _.uniq(conversationItemIds.concat(conversationItems.map(cI => cI.id)));
    }
  );

Factory.define('ConversationWithDefaults')
  .extend('Conversation')
  .attr('sla', () => {
    return {
      dueAt: moment()
        .add(36, 'hours')
        .toISOString(),
    };
  })
  .attr('status', 'OPEN')
  .attr('assignee', () => Factory.attributes('AssigneeWithAgentDefaults'))
  .attr('customAttributes', [])
  .attr('topicIds', [])
  .attr('closedAt', ['updatedAt', 'status'], (updatedAt, status) => (status === 'CLOSED' ? updatedAt : null))
  .attr('updatedAt', ['conversationItems'], conversationItems =>
    conversationItems.length
      ? _.chain(conversationItems)
          .sortBy('timestamp')
          .last()
          .value().timestamp
      : moment().toISOString()
  )
  .attr('createdAt', ['conversationItems'], conversationItems =>
    conversationItems.length
      ? _.chain(conversationItems)
          .sortBy('timestamp')
          .first()
          .value().timestamp
      : moment().toISOString()
  );

Factory.define('ConversationWithEmail')
  .extend('ConversationWithDefaults')
  .attr('conversationItems', () => [Factory.attributes('EmailMessageItemWithDefaults')]);
