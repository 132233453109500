import React, { useCallback, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { AgentAssistanceConfigContextProvider } from 'components/contexts/agent_assistance_config_context';
import analytics from 'scripts/lib/analytics';
import { CurrentAgentProvider } from 'components/contexts/current_agent';
import { ExecuteActionProvider } from 'components/lib/connect';
import ErrorBoundary from 'components/common/utilities/error_boundary';
import FakeWebRTCStarter from 'components/fake_webrtc_starter';
import { HotkeysProvider } from 'components/contexts/hotkeys';
import { IsFeatureEnabledProvider } from 'components/contexts/is_feature_enabled';
import NewRootContent from 'components/root/root_content';
import theme from 'scripts/presentation/themes/default';

export function Root({ binding }) {
  return (
    <ErrorBoundary errorMeta={{ errorBoundary: 'Root' }} renderError={() => <RootError />}>
      <FakeWebRTCStarter />
      <RootProviders>
        <NewRootContent binding={binding} />
      </RootProviders>
    </ErrorBoundary>
  );
}

function RootError() {
  const onClick = useCallback(() => location.reload(), []);
  useEffect(() => {
    analytics.track('Whoops Screen Shown', {
      componentName: 'root',
    });
  }, []);

  return (
    <div className="root-errorBoundary">
      <div>Whoops! Something went wrong.</div>
      <div className="root-errorBoundary-continue" onClick={onClick}>
        Click here to reload the page
      </div>
    </div>
  );
}

export function RootProviders({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <HotkeysProvider>
        <ExecuteActionProvider>
          <IsFeatureEnabledProvider>
            <AgentAssistanceConfigContextProvider>
              <CurrentAgentProvider>{children}</CurrentAgentProvider>
            </AgentAssistanceConfigContextProvider>
          </IsFeatureEnabledProvider>
        </ExecuteActionProvider>
      </HotkeysProvider>
    </ThemeProvider>
  );
}

export default Root;
