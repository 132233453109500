import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { evaluateTemplate } from 'components/customer/flexible_profile_card/lib/evaluate_template';
import FlexibleBlockList from 'components/customer/flexible_profile_card/components/flexible_block_list';
import FlexibleItemList from 'components/customer/flexible_profile_card/components/flexible_item_list';
import { H3 } from 'components/common/headers';

export default function FlexibleCardSection({ className, sectionConfig, sectionData }) {
  if (_.isEmpty(sectionConfig) || _.isEmpty(sectionData)) return null;

  // Render title if needed
  const sectionClassName = classnames('flexible-card-section', className);
  const titleTemplate = _.trim(_.get(sectionConfig, 'title'));
  const title = titleTemplate ? evaluateTemplate(titleTemplate, sectionData) : '';
  const renderedTitle = title ? <StyledSectionHeader className="section-title">{title}</StyledSectionHeader> : null;

  // Render "core" attributes
  const blocks = _.get(sectionConfig, 'blocks');
  const renderedBlocks = !_.isEmpty(blocks) ? (
    <FlexibleBlockList className="section-core" configList={blocks} externalData={sectionData} />
  ) : null;

  // Render the "item list"
  const renderedList = _.has(sectionConfig, 'listSource') ? (
    <FlexibleItemList className="section-list" sectionConfig={sectionConfig} sectionData={sectionData} />
  ) : null;

  return (
    <StyledSectionWrapper className={sectionClassName}>
      {renderedTitle}
      {renderedBlocks}
      {renderedList}
    </StyledSectionWrapper>
  );
}

const StyledSectionHeader = styled(H3)`
  font-size: ${p => p.theme.fontSize.medium};
  margin-bottom: 10px;
  margin-top: 4px;
`;

const StyledSectionWrapper = styled.div`
  & + & {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid ${p => p.theme.colors.gray300};
  }

  & .visibleCustomAttribute {
    line-height: 1.5;
    margin-bottom: 0;
  }
`;

FlexibleCardSection.propTypes = {
  className: PropTypes.string,
  sectionConfig: PropTypes.object.isRequired,
  sectionData: PropTypes.object.isRequired,
};
