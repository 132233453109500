export default {
  getPath(cv) {
    let path = `/customer/${cv.customerId}`;

    if (cv.currentConversationId) {
      path = `${path}/conversation/${cv.currentConversationId}`;
    }

    if (cv.currentConversationItemId) {
      path = `${path}/item/${cv.currentConversationItemId}`;
    }

    if (cv.anchorPosition) {
      path = `${path}?anchorPosition=${cv.anchorPosition}`;
    }

    return path;
  },
};
