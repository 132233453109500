import CloseReport from 'actions/reporting/close_report';
import OpenReport from 'actions/reporting/open_report';
import { registerUrlConverter } from './location_url';
import Reports from 'models/location/reports';
import ReportsUrlConverter from './url_converters/reports_url_converter';

registerUrlConverter(Reports, ReportsUrlConverter);

export default function(router, { runnerContext }) {
  return {
    // match zero or one forward slashes followed by an optional slug
    // that is just word characters or dashes
    '/?([\\w-]+)?': {
      on: slug => {
        runnerContext.executeAction(OpenReport, getReportParams(slug));
      },
      after: () => {
        runnerContext.executeAction(CloseReport);
      },
    },
  };

  function getReportParams(slug) {
    return ReportsUrlConverter.fromUrlParameters({ slug, ...router.parseQueryParameters().toJS() });
  }
}
