import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="2 2 19 19" {...props}>
    <g className="twitterIcon" fill="none" fillRule="evenodd">
      <path
        className="contactIcon-path"
        d="M18 8.065c-.403.18-.84.304-1.296.36.467-.282.823-.728.994-1.26-.437.26-.92.45-1.437.552-.408-.44-.996-.717-1.644-.717-1.245 0-2.256 1.018-2.256 2.272 0 .18.02.35.058.518-1.877-.096-3.538-.998-4.652-2.375-.195.335-.306.728-.306 1.144 0 .787.4 1.483 1.003 1.888-.37-.01-.715-.114-1.02-.284v.028c0 1.102.78 2.02 1.81 2.232-.19.05-.39.077-.595.077-.144 0-.285-.012-.426-.04.29.904 1.12 1.56 2.108 1.58-.772.608-1.746.97-2.803.97-.18 0-.36-.01-.537-.03C8 15.625 9.185 16 10.46 16c4.15 0 6.42-3.462 6.42-6.464l-.008-.294c.443-.32.826-.72 1.128-1.177z"
      />
    </g>
  </IconContainer>
);
