import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AgentMetadata from '../metadata/agent_metadata';
import ApiMetadata from '../metadata/api_metadata';
import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import PrepositionalPhrase from '../metadata/prepositional_phrase';
import RuleLink from '../metadata/rule_link';

export function TopicChangeItem({ addedTopics, item, removedTopics }) {
  const content =
    item.initiator.type === Communicator.AGENT
      ? getAgentTopicChangeContent()
      : item.initiator.type === Communicator.API
      ? getApiTopicChangeContent()
      : getRuleTopicChangeContent();

  return (
    <ItemContent isAuditItem item={item}>
      {content}
    </ItemContent>
  );

  function getAgentTopicChangeContent() {
    return (
      <>
        {addedTopics.length > 0 ? (
          <TopicChangeDisplayItem data-aid="conversationTopicAdded">
            <AgentMetadata
              item={item}
              object={addedTopics.join(', ')}
              text={`added topic${addedTopics.length > 1 ? 's' : ''} `}
            />
          </TopicChangeDisplayItem>
        ) : null}
        {removedTopics.length > 0 ? (
          <TopicChangeDisplayItem data-aid="conversationTopicRemoved">
            <AgentMetadata
              item={item}
              object={removedTopics.join(', ')}
              text={`removed topic${removedTopics.length > 1 ? 's' : ''} `}
            />
          </TopicChangeDisplayItem>
        ) : null}
      </>
    );
  }

  function getRuleTopicChangeContent() {
    return (
      <>
        {addedTopics.length > 0 ? (
          <TopicChangeDisplayItem data-aid="conversationTopicAdded">
            <Metadata
              isInbound={false}
              prepositionalPhrases={getNonAgentTopicPrepositionalPhrases(addedTopics, 'added')}
              timestamp={item.timestamp}
            />
          </TopicChangeDisplayItem>
        ) : null}
        {removedTopics.length > 0 ? (
          <TopicChangeDisplayItem data-aid="conversationTopicRemoved">
            <Metadata
              isInbound={false}
              prepositionalPhrases={getNonAgentTopicPrepositionalPhrases(removedTopics, 'removed')}
              timestamp={item.timestamp}
            />
          </TopicChangeDisplayItem>
        ) : null}
      </>
    );
  }

  function getApiTopicChangeContent() {
    return (
      <>
        {addedTopics.length > 0 ? (
          <TopicChangeDisplayItem data-aid="conversationTopicAdded">
            <ApiMetadata
              item={item}
              prepositionalPhrases={getNonAgentTopicPrepositionalPhrases(addedTopics, 'added')}
            />
          </TopicChangeDisplayItem>
        ) : null}
        {removedTopics.length > 0 ? (
          <TopicChangeDisplayItem data-aid="conversationTopicRemoved">
            <ApiMetadata
              item={item}
              prepositionalPhrases={getNonAgentTopicPrepositionalPhrases(removedTopics, 'removed')}
            />
          </TopicChangeDisplayItem>
        ) : null}
      </>
    );
  }

  function getNonAgentTopicPrepositionalPhrases(topics, action) {
    const prepositionalPhrases = [];

    prepositionalPhrases.push(
      <PrepositionalPhrase
        complement={topics.join(', ')}
        key="topic"
        preposition={`Topic${topics.length > 1 ? 's' : ''}`}
      />
    );

    let complement = '';
    let preposition = `${action}`;
    if (item.initiator.type !== Communicator.API) {
      complement = <RuleLink ruleId={item.initiator.id}>rule</RuleLink>;

      preposition = `${preposition} by`;
    }

    prepositionalPhrases.push(<PrepositionalPhrase complement={complement} key="by" preposition={preposition} />);
    return prepositionalPhrases;
  }
}

TopicChangeItem.propTypes = {
  addedTopics: PropTypes.arrayOf(String).isRequired,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  removedTopics: PropTypes.arrayOf(String).isRequired,
};

const TopicChangeDisplayItem = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

function mapStateToProps({ getProvider }, { item }) {
  let addedTopics = _.sortBy(getTopicNames(item.content.addedTopics), topic => topic.toLowerCase());
  let removedTopics = _.sortBy(getTopicNames(item.content.removedTopics), topic => topic.toLowerCase());

  return {
    addedTopics,
    item,
    removedTopics,
  };

  function getTopicNames(topicIds) {
    let topicNames = [];
    topicIds.forEach(topicId => {
      let topic = getProvider('topics').findBy({ id: topicId });
      if (topic) {
        topicNames.push(topic.getNameWithAncestry());
      }
    });
    return topicNames;
  }
}

const TopicChangeItemContainer = connect(mapStateToProps, null)(TopicChangeItem);
TopicChangeItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default TopicChangeItemContainer;
