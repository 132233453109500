import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class IvrsService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(attrs, callback, path, { orgId }) {
    let ivrs = this.getDatabase(orgId).ivrs || [];
    callback(null, { status: 200, statusText: statusText(200), response: ivrs });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/ivrs': {
          GET: this.findAll,
        },
      },
      this
    );
  }
}
