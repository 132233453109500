import classnames from 'classnames';
import { isFunction } from 'lodash/lang';
import React from 'react';
import PropTypes from 'prop-types';

export default function CustomerEmailDisplay({ className, isDisabled, onClick, value }) {
  const classNames = classnames(
    'customerProfile-contactDetails-row',
    'customerProfile-contactDetails-emailRow',
    {
      'customerProfile-contactInput-disabled': isDisabled,
    },
    className
  );

  function handleClick() {
    if (isFunction(onClick)) {
      onClick('email');
    }
  }

  return (
    <div className={classNames} onClick={handleClick}>
      <div className="customerProfile-contactInput customerEmailDisplay-email">{value.email}</div>
      {value.isPrimary ? (
        <div className="customerProfile-contactDetails-attrib-button customerProfile-contactDetails-attrib-button-active">
          Main
        </div>
      ) : null}
    </div>
  );
}

CustomerEmailDisplay.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  value: PropTypes.shape({ email: PropTypes.string, isPrimary: PropTypes.bool }).isRequired,

  onClick: PropTypes.func,
};
