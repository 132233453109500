import { createPluginFactory } from '@udecode/plate';
import React from 'react';

import { AUTOCOMPLETE } from './autocomplete_constants';
import AutocompleteInline from './autocomplete_inline';
import { ensureAutocompleteExists, removeAutocomplete } from './autocomplete_commands';
import getNodes from 'components/text_editor_new/lib/get_nodes';
import { onChange, onKeyDown } from './autocomplete_handlers';
import UseAutocomplete from './use_autocomplete';

// Autocomplete plugin implements what were known as "greeting suggestions" for now..
// if there is no text in the editor, and greeting suggestions exist, and the agent
// has not yet responded to a customer for a given conversation, we'll show a greeting
// suggestion in the editor. If they hit Tab or Right Arrow, it'll insert the suggestion.
// If they start typing but delete it all, making the editor empty again, we'll show it
// again.
//
// But once they use that suggestion, or they respond to the customer otherwise, we won't
// show the suggestion anymore.
//
// Plugin dependencies: Variables, Answers
export default function createAutocompletePlugin(context, autocompleteRef) {
  return createPluginFactory({
    key: AUTOCOMPLETE,
    isElement: true,
    isInline: true,
    isVoid: true,

    component: props => {
      return <AutocompleteInline {...props} />;
    },

    withOverrides: withAutocomplete,

    handlers: {
      onKeyDown: editor => evt => {
        onKeyDown(context, editor, evt);
      },

      onChange: editor => () => {
        onChange(autocompleteRef, editor);
      },
    },

    // This ensures that if a autocomplete suggestion loads in the store, then we'll ensure it shows in the editor
    // if it takes a moment to load. It helps guarantee we show an autocomplete.
    renderAfterEditable: () => <UseAutocomplete />,
  });

  function withAutocomplete(editor) {
    editor.getAutocompleteNodes = () => getAutocompleteNodes(editor);
    editor.ensureAutocompleteExists = () => ensureAutocompleteExists(context, editor);
    editor.removeAutocomplete = () => removeAutocomplete(autocompleteRef, editor);
    return editor;
  }
}

function getAutocompleteNodes(editor) {
  return getNodes(editor, { at: [], match: n => n.type === AUTOCOMPLETE });
}
