import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import ChangeName from 'actions/customer_profile/change_name';
import connect from 'components/lib/connect';
import EditableFieldControlled from 'components/lib/editable_field_controlled';

export function CustomerName({ isDisabled, isReadOnly, isResponsePending, onSubmit, value }) {
  return (
    <div className="customerInformation-nameWrapper">
      <EditableFieldControlled
        className="editableField customerInformation-name"
        data-aid="customerName-input"
        disabled={isDisabled}
        onSubmit={onSubmit}
        pending={isResponsePending}
        placeholder="Add Customer Name"
        readOnly={isReadOnly}
        value={value || ''}
      />
    </div>
  );
}

CustomerName.propTypes = {
  isDisabled: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  value: PropTypes.string,

  onSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapExecuteToProps)(CustomerName);

function mapStateToProps({ getProvider }, { isReadOnly }) {
  let profileProvider = getProvider('profile');

  return {
    isDisabled: profileProvider.isLoading() || profileProvider.getErrors().length > 0,
    isReadOnly,
    isResponsePending: profileProvider.isPending(),
    value: _(profileProvider.getPending() || profileProvider.get()).get('name'),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onSubmit: updatedName => executeAction(ChangeName, updatedName),
  };
}
