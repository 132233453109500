import _ from 'lodash';

import MqttGateway from './lib/mqtt_gateway';

export default function createOutgoingCommunicationGateway(name, backend, requestorId) {
  class OutgoingCommunicationGateway extends MqttGateway {
    get version() {
      return '1';
    }

    get topicPattern() {
      return `agents/+/outgoing/${name}/+`;
    }

    add(agentId, outgoingPayload) {
      return super._add([agentId], outgoingPayload);
    }

    onAddSuccess(payload, topic, parsedTopicParams, correlationId) {
      this.notifyObservers('handleAddSuccess', payload, correlationId);
    }

    onAddErrors(errorDtos, topic, parsedTopicParams, correlationId) {
      this.notifyObservers('handleAddErrors', errorDtos, correlationId);
    }

    update(agentId, itemId, payload) {
      return super._update([agentId, itemId], payload);
    }

    onUpdateSuccess(payload, topic, parsedTopicParams, correlationId) {
      const entityId = _.last(parsedTopicParams);
      this.notifyObservers('handleUpdateSuccess', entityId, payload, correlationId);
    }

    onUpdateErrors(errorDtos, topic, parsedTopicParams, correlationId) {
      const entityId = _.last(parsedTopicParams);
      this.notifyObservers('handleUpdateErrors', entityId, errorDtos, correlationId);
    }
  }

  return new OutgoingCommunicationGateway(backend, requestorId);
}
