import AgentExperienceConfig from 'models/agent_experience_config';

export default class AgentAssistanceConfigGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onBroadcast(dto) {
    this.context.stores.agentExperienceConfig.set(AgentExperienceConfig.fromJs(dto));
  }

  onBroadcastDelete() {
    this.context.stores.agentExperienceConfig.set(AgentExperienceConfig.create());
  }
}
