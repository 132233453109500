import React, { useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import DownChevronIcon from 'components/lib/icons/chevron_down_icon';
import { useSpringExpandCollapse } from 'components/hooks/animation_hooks';

export default function ExpandableSummary({ children, className, isExpanded, summary }) {
  const contentWrapperRef = useRef();
  const [isCollapsed, setIsCollapsed] = useState(!isExpanded || _.isEmpty(children));
  const isCollapsible = !_.isEmpty(children);

  const styles = useSpringExpandCollapse({ isCollapsed, contentWrapperRef, springTension: 220 });
  const caretAnimatedStyles = useSpring({
    to: {
      transform: isCollapsed ? 'rotate(0deg)' : 'rotate(-180deg)',
    },
    config: {
      clamp: true,
      friction: 20,
      tension: 220,
    },
  });

  // Do we have any content to render? If not, bail out
  if (!summary) return null;

  const componentClasses = classnames('expandable-flexible-summary', className, { collapsed: isCollapsed });
  const summaryTitleClasses = classnames('summary-clickable-tile', { collapsed: isCollapsed });
  const summaryContentClasses = classnames('summary-content', { collapsed: isCollapsed });
  const chevronClasses = classnames('expand-button', { collapsed: isCollapsed });
  const contentClasses = classnames('content-block', { collapsed: isCollapsed });
  const chevron = isCollapsible ? (
    <StyledCaretWrapper $collapsed={isCollapsed} className={chevronClasses} style={caretAnimatedStyles}>
      <DownChevronIcon />
    </StyledCaretWrapper>
  ) : null;

  const toggleExpandCollapse = () => {
    if (!isCollapsible) return;
    setIsCollapsed(currentState => !currentState);
  };

  // "$xyz" notation is for transient styled props that should not be passed onto the underlying DOM element
  // See https://styled-components.com/docs/faqs#transient-props-since-51
  return (
    <SummaryTile className={componentClasses}>
      <StyledSummaryWrapper $collapsed={isCollapsed} className={summaryTitleClasses} onClick={toggleExpandCollapse}>
        <StyledSummaryContentWrapper className={summaryContentClasses}>{summary}</StyledSummaryContentWrapper>
        {chevron}
      </StyledSummaryWrapper>
      <StyledContentWrapper className={contentClasses} style={styles}>
        <div ref={contentWrapperRef}>{children}</div>
      </StyledContentWrapper>
    </SummaryTile>
  );
}

const StyledCaretWrapper = styled(animated.div)`
  cursor: pointer;
  fill: ${p => (p.$collapsed ? p.theme.colors.gray600 : p.theme.colors.green400)};
  height: 16px;
  min-width: 16px;
  width: 16px;
`;

const StyledContentWrapper = styled(animated.div)`
  margin: 0 -${p => p.theme.spacing.insetMedium};
  overflow: hidden;
  padding: 0 calc(${p => p.theme.spacing.insetMedium} / 2);
`;

const StyledSummaryWrapper = styled.div`
  align-items: center;
  background-color: ${p => (p.$collapsed ? 'inherit' : p.theme.colors.gray100)};
  border-radius: ${p => p.theme.borderRadius.xsmall};
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  padding: 8px 10px;
  margin: 0 -8px 0 -10px;

  &:hover {
    background-color: ${p => p.theme.colors.gray100};
    svg {
      fill: ${p => p.theme.colors.green400};
    }
  }
  & .visibleCustomAttribute {
    margin-bottom: 0;
  }
`;

const StyledSummaryContentWrapper = styled.div`
  flex-basis: 100%;
`;

const SummaryTile = styled.div`
  & + & {
    border-top: 1px solid ${p => p.theme.colors.gray200};
  }
`;

ExpandableSummary.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isExpanded: PropTypes.bool,
  summary: PropTypes.node,
};
