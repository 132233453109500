import _ from 'lodash';

import AttachmentSourceType from './attachment/attachment_source_type';
import AttachmentStatus from 'models/attachment/attachment_status';
import createModel, { prop } from 'models/lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const ConversationMessageAttachment = createModel({
  modelName: 'ConversationMessageAttachment',

  properties: {
    id: prop(String).isRequired,
    contentType: String,
    filename: String,
    fileSize: Number,
    isRedacted: Boolean,
    status: prop().oneOf(..._.keys(AttachmentStatus)),
    source: prop({
      path: String,
      type: String,
    }).default({}).isRequired,
  },

  isImage() {
    return this.contentType && this.contentType.startsWith('image/');
  },

  isVideo() {
    return this.contentType && this.contentType.startsWith('video/');
  },

  isAvailable() {
    return this.status === AttachmentStatus.AVAILABLE;
  },

  fileDescriptor() {
    return {
      contentLength: this.fileSize,
      contentType: this.contentType,
      filename: this.filename,
    };
  },

  redact() {
    this.isRedacted = true;
  },

  statics: {
    create(attrs = {}) {
      return new this(_.assign({ id: IdGenerator.newId() }, attrs));
    },
    SourceType: AttachmentSourceType,
  },
});

export default ConversationMessageAttachment;
