import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const PhoneControlsActionContainerButton = ({ className, displayText, isDisabled, onClickCallback }) => {
  return (
    <div
      className={classnames(className, 'phoneControlsActionContainer-button', {
        'phoneControlsActionContainer-button-disabled': isDisabled,
      })}
      onClick={isDisabled ? _.noop : onClickCallback}
    >
      {displayText}
    </div>
  );
};

PhoneControlsActionContainerButton.propTypes = {
  className: PropTypes.string,
  displayText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onClickCallback: PropTypes.func.isRequired,
};

export default PhoneControlsActionContainerButton;
