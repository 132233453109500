import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import CloseModal from 'actions/modal/close_modal';
import connect from 'components/lib/connect';
import { H2 } from 'components/common/headers';
import ModalCard from 'components/common/modal_card';
import NavigateBack from 'actions/current_location/navigate_back';
import Reload from 'actions/current_location/reload';
import XIcon from 'components/lib/icons/x_icon';

const CustomReportingErrorModal = ({ onRetry, onAbort }) => (
  <Modal data-aid="customReportingErrorModal">
    <ModalCard>
      <CloseIcon onClick={onAbort} />
      <Header>Oops</Header>
      <Description>We ran into an unexpected problem loading these reports.</Description>

      <Actions>
        <Button buttonType={ButtonTypes.TEXT} onClick={onAbort}>
          Give Up
        </Button>
        <RetryButton buttonType={ButtonTypes.PRIMARY} onClick={onRetry}>
          Try Again
        </RetryButton>
      </Actions>
    </ModalCard>
  </Modal>
);

CustomReportingErrorModal.propTypes = {
  onRetry: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
};

export default connect(null, mapExecuteToProps)(CustomReportingErrorModal);

function mapExecuteToProps(executeAction) {
  let closeThen = action => {
    executeAction(CloseModal);
    executeAction(action);
  };

  return {
    onAbort: () => closeThen(NavigateBack),
    onRetry: () => closeThen(Reload),
  };
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${p => p.theme.spacing.large};
`;

const CloseIcon = styled(XIcon)`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 16px;
  width: 16px;
  fill: ${p => p.theme.colors.gray500};

  &:hover {
    fill: ${p => p.theme.colors.gray600};
  }
`;

const Description = styled.p``;

const Header = styled(H2)`
  display: inline;
  padding: 0 16px;
`;

const Modal = styled.div`
  text-align: center;
  .modalCard-content {
    padding: ${p => p.theme.spacing.large};
    position: relative;
  }
`;

const RetryButton = styled(Button)`
  margin-left: ${p => p.theme.spacing.large};
`;
