import _ from 'lodash';
import classnames from 'classnames';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { ChannelLabel } from './answer_content_editor';
import Err from 'models/err';
import AudienceSelector from './answer_editor_audience_selector';
import LanguageSelector from './answer_editor_language_selector';
import { SnippetChannel } from 'models/answers/snippet';
import SnippetRevision from 'models/snippet_revision';
import SnippetRevisionsContainer from 'components/admin/knowledge_base/snippet/snippet_revisions_container';
import Tooltip from 'components/common/tooltip';
import styled from 'styled-components';
import { H5 } from 'components/common/headers';
class AnswerEditorInfoPanel extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, ['setNameFocus']);
  }

  setNameFocus() {
    this.props.setFocus('name');
  }

  render() {
    return (
      <div className="answerEditor-info-panel">
        <div className="answerEditor-info-panel-item">
          <Header>Name</Header>
          {this.renderNameInput()}
          <LanguageSelector
            contents={this.props.contents}
            errors={this.props.errors}
            language={this.props.language}
            setLanguage={this.props.setLanguage}
          />
          <AudienceSelector audiences={this.props.audiences} setAudiences={this.props.setAudiences} />
          {this.renderNavigationMenu()}
        </div>
        {this.renderSnippetRevisions()}
      </div>
    );
  }

  renderNameInput() {
    let classNames = classnames('answerEditor-nameInput', {
      hasError: this.props.getError('name'),
      'answerEditor-nameInput-readOnly': this.props.readOnly,
    });
    return (
      <ReactTextareaAutosize
        className={classNames}
        onBlur={this.props.unsetFocus}
        onChange={this.props.onChange}
        onFocus={this.setNameFocus}
        placeholder="Add name"
        readOnly={this.props.readOnly}
        ref={input => (this.nameInput = input)}
        value={this.props.name}
      />
    );
  }

  renderNavigationMenu() {
    return (
      <ul className="answerEditor-navigation-list">
        {this.renderNavigationItem(SnippetChannel.INFO)}
        {this.renderNavigationItem(SnippetChannel.ANY_CHANNEL)}
        {this.renderNavigationItem(SnippetChannel.MESSAGE)}
        {this.renderNavigationItem(SnippetChannel.SELF_SERVICE)}
      </ul>
    );
  }

  renderNavigationItem(channel) {
    return (
      <NavigationItem
        addAnswerForChannel={this.props.addAnswerForChannel}
        alreadyCreated={this.props.hasChannel(channel)}
        channel={channel}
        focusCard={this.props.focusCard}
        isSelected={this.props.selectedChannelType === channel}
        readOnly={this.props.readOnly}
      />
    );
  }

  renderSnippetRevisions() {
    const hasRevisionHistory = this.props.snippetRevisions.length > 0;

    return hasRevisionHistory && !this.props.readOnly ? (
      <div className="answerEditor-info-panel-item answerEditor-revisions-container">
        <SnippetRevisionsContainer snippetRevisions={this.props.snippetRevisions} />
      </div>
    ) : null;
  }
}

AnswerEditorInfoPanel.propTypes = {
  addAnswerForChannel: PropTypes.func.isRequired,
  contents: PropTypes.array.isRequired,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
  focusCard: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  hasChannel: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  selectedChannelType: PropTypes.string,
  setFocus: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  snippetRevisions: PropTypes.arrayOf(PropTypes.instanceOf(SnippetRevision)),
  unsetFocus: PropTypes.func.isRequired,
};

class NavigationItem extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['onClick']);
  }

  onClick() {
    if (this.props.readOnly) {
      return;
    }
    if (this.props.alreadyCreated) {
      this.props.focusCard(this.props.channel);
    } else {
      this.props.addAnswerForChannel(this.props.channel);
    }
  }

  render() {
    const channel = this.props.channel;
    const canCreate = !this.props.readOnly && !this.props.alreadyCreated;
    const classNames = classnames(
      'answerEditor-navigation-item',
      `answerEditor-navigation-item-${_.camelCase(ChannelLabel[channel].toLowerCase())}`,
      {
        selected: this.props.isSelected,
        created: this.props.alreadyCreated,
      }
    );

    let icon;
    switch (channel) {
      case SnippetChannel.INFO:
        icon = <FontAwesomeIcon className="answerEditor-header-icon" icon={faBook} />;
        break;
      case SnippetChannel.ANY_CHANNEL:
        icon = <i className="answerEditor-header-icon icon-email" />;
        break;
      case SnippetChannel.MESSAGE:
        icon = <FontAwesomeIcon className="answerEditor-header-icon" icon={faComment} />;
        break;
      case SnippetChannel.SELF_SERVICE:
        icon = <FontAwesomeIcon className="answerEditor-header-icon" icon={faGlobe} />;
        break;
    }

    const label = ChannelLabel[channel];
    let tooltipMessage = canCreate
      ? `Click to add ${label === ChannelLabel.ANY_CHANNEL ? 'an' : 'a'} ${label} answer`
      : '';

    return (
      <Tooltip message={tooltipMessage} position="right">
        <li className={classNames} onClick={this.onClick}>
          <div className="answerEditor-navigation-label">
            {icon}
            {ChannelLabel[channel]}
          </div>
          {canCreate && (
            <i
              className={classnames(
                'answerEditor-create-icon',
                'icon',
                'icon-plus',
                `answerEditor-create-${_.camelCase(ChannelLabel[channel].toLowerCase())}`
              )}
            />
          )}
        </li>
      </Tooltip>
    );
  }
}

const Header = styled(H5)`
  margin-bottom: ${p => p.theme.spacing.medium};
  padding-top: ${p => p.theme.spacing.small};
`;

NavigationItem.propTypes = {
  alreadyCreated: PropTypes.bool,
  addAnswerForChannel: PropTypes.func.isRequired,
  channel: PropTypes.string.isRequired,
  focusCard: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default AnswerEditorInfoPanel;
