import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import OutsideClickHandler from 'components/common/utilities/outside_click_handler';

export default function ModalCard({ 'data-aid': dataAid, children, className, onClose }) {
  const classNames = classnames(className, 'modalCard-content');
  const contentRef = useRef(null);
  const content = (
    <div className={classNames} data-aid={dataAid} ref={contentRef}>
      {children}
    </div>
  );

  if (onClose) {
    return (
      <div className="modalCard-backdrop">
        <OutsideClickHandler innerRef={contentRef} onClickOutside={onClose}>
          {content}
        </OutsideClickHandler>
      </div>
    );
  }

  return <div className="modalCard-backdrop">{content}</div>;
}

ModalCard.propTypes = {
  'data-aid': PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
};
