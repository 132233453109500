import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import Err from 'models/err';
import FbPage from './fb_page';
import FbMessageOutgoingAttachment from 'models/fb_message_outgoing_attachment';
import FbMessengerUser from './fb_messenger_user';

export const FbMessageSent = createModel({
  modelName: 'FbMessageSent',
  properties: {
    attachment: prop(FbMessageOutgoingAttachment).default(undefined),
    text: String,
  },

  redactAttachment(attachmentId) {
    this.attachment && this.attachment.id === attachmentId && this.attachment.redact();
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const FbMessageRequest = createModel({
  modelName: 'FbMessageRequest',
  properties: {
    message: prop(FbMessageSent).isRequired,
    recipient: prop(FbMessengerUser).isRequired,
    sender: prop(FbPage).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const FbMessageResponse = createModel({
  modelName: 'FbMessageResponse',
  properties: {
    status: prop(String).isRequired,
    error: prop(Object),
  },

  getErrorMessage() {
    if (!this.error) {
      return '';
    }

    let errorCode = this.error.code;

    switch (this.error.attr) {
      default:
        if (errorCode === Err.Code.INVALID_STATE) {
          return 'Could not send Facebook message due to page configuration error.';
        } else if (errorCode === ErrorCode.NO_ACTIVE_CONVERSATION) {
          return 'Could not send message because conversation was closed.';
        }
        break;
      case ErrorAttr.TEXT:
        if (errorCode === Err.Code.TOO_LONG) {
          return 'Message exceeds the 640 character limit';
        }
        break;
      case ErrorAttr.ATTACHMENT:
        if (errorCode === Err.Code.TOO_LONG) {
          return 'Attachment size exceeds allowable limit.';
        }
        break;
      case ErrorAttr.RECIPIENT:
        if (errorCode === ErrorCode.NOT_RECEIVING) {
          return 'This customer cannot receive messages from you right now.';
        }
        if (errorCode === ErrorCode.NOT_IN_WINDOW) {
          return 'The 7 day response window has been exceeded.';
        }
        if (errorCode === ErrorCode.UNAVAILABLE) {
          return 'This customer is not available right now.';
        }
        break;
    }
    return '';
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const FbMessageOutgoing = createModel({
  modelName: 'FbMessageOutgoing',
  properties: {
    request: prop(FbMessageRequest).isRequired,
    response: FbMessageResponse,
  },

  hasErrorStatus() {
    return hasErrorStatus(this.getStatus());
  },

  getErrorMessage() {
    return this.response.getErrorMessage();
  },

  getStatus() {
    return (this.response && this.response.status) || Status.NEW;
  },

  redactAttachment(attachmentId) {
    this.request.message && this.request.message.redactAttachment(attachmentId);
  },

  getMessageText() {
    return this.request.message.text;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    MAX_MESSAGE_LENGTH: 640,
  },
});

export function hasErrorStatus(status) {
  return status === Status.FAILED;
}

export default FbMessageOutgoing;

export const Status = createEnum('FAILED', 'NEW', 'SENT');

export const ErrorCode = Object.freeze({
  NO_ACTIVE_CONVERSATION: 'NO_ACTIVE_CONVERSATION',
  NOT_IN_WINDOW: 'not_in_window',
  NOT_RECEIVING: 'not_receiving',
  UNAVAILABLE: 'unavailable',
});

export const ErrorAttr = Object.freeze({
  ATTACHMENT: 'attachment',
  RECIPIENT: 'recipient',
  TEXT: 'text',
});
