import _ from 'lodash';

import createModel from './lib/create_model';

var Tweet = createModel({
  modelName: 'Tweet',

  properties: {
    user: {
      screenName: String,
    },
    retweetCount: Number,
    favoriteCount: Number,
    entities: {
      userMentions: [
        {
          screenName: String,
          indices: [Number],
        },
      ],
      media: [
        {
          mediaUrlHttps: String,
          type: String,
          indices: [Number],
        },
      ],
    },
    text: String,
  },

  createResponse() {
    return Tweet.create({ text: `@${this.user.screenName} ` });
  },

  withText(text) {
    return Tweet.create(
      _.assign({}, this, {
        text,
      })
    );
  },

  statics: {
    create(attrs) {
      return new this(
        _.assign({}, attrs, {
          entities: this.parseEntities(attrs.text),
        })
      );
    },

    parseEntities(text) {
      return {
        userMentions: this.parseUserMentions(text),
      };
    },

    parseUserMentions(text) {
      var res = [];
      var re = /(^|[^\w])@(\w+)/gi;
      var match;
      while ((match = re.exec(text)) != null) {
        res.push({
          screenName: match[2].toLowerCase(),
          indices: [match.index + match[1].length, re.lastIndex - 1],
        });
      }
      return res;
    },
  },
});

export default Tweet;
