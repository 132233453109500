import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Simple Pause
 */

export default function PauseIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <g fillRule="evenodd">
        <rect height="8" rx="1" width="2" x="5" y="4" />
        <rect height="8" rx="1" width="2" x="9" y="4" />
      </g>
    </Icon>
  );
}
