import AgentActivity from 'models/agent_activity';
import AnswersPreview from 'models/location/answers_preview';
import changeCurrentLocation from 'actions/lib/change_current_location';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenAnswersPreview {
  constructor(context) {
    this.context = context;
  }

  run() {
    changeCurrentLocation(this.context, AnswersPreview.create());

    // logging activity the same as knowledge base
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.KNOWLEDGE_BASE });
  }
}
