import ReportConfigsConverter from 'scripts/application/dto_converters/report_configs_converter';

export default function requestSharedReportConfigs(context) {
  context.stores.sharedReportConfigs.setLoading();

  return context.gateways.sharedReportConfigs
    .fetch()
    .then(response => {
      context.stores.sharedReportConfigs.set(ReportConfigsConverter.fromDto(response));
    })
    .finally(() => {
      context.stores.sharedReportConfigs.resetLoading();
    });
}
