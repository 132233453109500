import _ from 'lodash';
import classnames from 'classnames';
import { Editor } from 'slate-react-old';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

import { IS_FIREFOX } from 'scripts/lib/browser_detector';
import FocusPlugin, { useFocusOnMount } from './plugins/focus';
import ReadOnlyPlugin from './plugins/read_only';
import SelectionPlugin from './plugins/selection';
import SubmitPlugin from './plugins/submit';
import TextEditorContext, { useTextEditorContextValue } from './text_editor_context';
import ToggleHidePlugin from './plugins/toggle_hide';
import { usePlaceholder } from './placeholder';
import useOnPaste from './paste/use_on_paste';
import Variables, { useVariables } from './plugins/variables';
import useCopyForNewSlate from './hooks/use_copy_for_new_slate';

export default function TextEditor(props) {
  let {
    autoFocus,
    children,
    className,
    onBlur,
    onChange,
    onSubmit,
    onToggleHide,
    placeholder,
    plugins,
    value,
    readOnly,
    renderNode,
  } = props;

  const placeholderElement = usePlaceholder(placeholder);

  const editorRef = useEditorRef(props.editorRef);
  useFocusOnMount(editorRef.current, onChange, autoFocus);
  useVariables(editorRef.current, onChange);

  const textEditorContextValue = useTextEditorContextValue(editorRef);

  const onPaste = useOnPaste();
  const optionalProps = {};
  if (onPaste) {
    optionalProps.onPaste = onPaste;
  }
  if (onBlur) {
    optionalProps.onBlur = onBlur;
  }

  // HACK: Override default on focus, some things break (like tabbing between editors)
  // but overall, it seems to work better than the default behavior (in which we)
  // continually focus and blur when trying to focus. Whoops. Two actual fixes would
  // be forking Slate on the version we're on, or just doing the upgrade.
  if (IS_FIREFOX) {
    optionalProps.onFocus = _.noop;
  }

  const onCopy = useCopyForNewSlate(editorRef);
  if (onCopy) {
    optionalProps.onCopy = onCopy;
  }

  const allPlugins = usePlugins(
    () => [
      ReadOnlyPlugin(readOnly),
      FocusPlugin(),
      SelectionPlugin(),
      SubmitPlugin(onSubmit),
      Variables(),
      ToggleHidePlugin(onToggleHide),
      ...plugins,
    ],
    [readOnly, ...plugins]
  );

  const classNames = classnames('slateTextEditor', className);
  return (
    <React.Fragment>
      <TextEditorContext.Provider value={textEditorContextValue}>
        <Editor
          autoFocus={autoFocus}
          className={classNames}
          onChange={onChange}
          placeholder={placeholderElement}
          plugins={allPlugins}
          ref={editorRef}
          renderNode={renderNode}
          spellCheck
          value={value}
          {...optionalProps}
        />
        {children}
      </TextEditorContext.Provider>
    </React.Fragment>
  );
}

TextEditor.defaultProps = {
  autoFocus: true,
  plugins: [],
  renderNode: (props, editor, next) => {
    return next();
  },
};

TextEditor.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onToggleHide: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  plugins: PropTypes.array,
  readOnly: PropTypes.bool,
  renderNode: PropTypes.func,
  value: PropTypes.object.isRequired,
};

/**
 * Returns a cached copy of the return value of `pluginFunc`. We cache this so that Slate does not
 * yell at us about changing the `plugins` prop too frequently (as that is inefficient).
 */
export function usePlugins(pluginFunc, inputs = []) {
  return useMemo(pluginFunc, inputs);
}

export function useEditorRef(editorRef) {
  const ref = useRef(null);
  return editorRef ? editorRef : ref;
}

export function getEditorProps(props) {
  return _.pick(props, [
    'autoFocus',
    'className',
    'onBlur',
    'onChange',
    'onToggleHide',
    'onSubmit',
    'placeholder',
    'value',
    'readOnly',
  ]);
}
