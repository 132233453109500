import React from 'react';
import PropTypes from 'prop-types';

export default function Icon(props) {
  let { viewBox, ...otherProps } = props;
  return <svg preserveAspectRatio="xMidYMid meet" viewBox={viewBox || '0 0 19 21'} {...otherProps} />;
}

Icon.propTypes = {
  viewBox: PropTypes.string,
};
