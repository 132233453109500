import React from 'react';
import PropTypes from 'prop-types';

import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import AutomationContentContainer from './message_automation_item_content_container';
import ChatMetadataIcon from '../chat/chat_metadata_icon';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import InteractionType from 'models/interaction_type';
import MessageAutomationAnnotationItem from './message_automation_annotation_item';
import { messageType } from 'models/message_automation_content';
import { StyledIconAPI } from '../metadata/api_metadata';
import ShieldOfTrust from '../chat/shield_of_trust';

export default function MessageAutomationItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  const metadataText = getMetadataText();
  const apiIcon = renderAppIcon();
  if (item.content.message.type === messageType.ANNOTATION) {
    return <MessageAutomationAnnotationItem item={item} />;
  }
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <span>
        <ItemMetadata
          icon={
            <span>
              {apiIcon}
              <ChatMetadataIcon />
            </span>
          }
          item={item}
          showMetadata={!isStacked}
          status={status}
          text={metadataText}
        />
        {showShieldOfTrust(item, isStacked) && <ShieldOfTrust conversationId={item.conversationId} />}
      </span>
      <AutomationContentContainer className={className} item={item} />
    </ItemContent>
  );

  function showShieldOfTrust(item, isStacked) {
    return !isStacked && item.isCustomerItem() && isAuthenticated(item.content);
  }

  function isAuthenticated(itemContent) {
    if (!itemContent || !itemContent.message || !itemContent.message.context) {
      return false;
    }
    return itemContent.message.context.IsAuthenticated === 'true';
  }

  function renderAppIcon() {
    if (item.content.message.from.type === Communicator.CUSTOMER) {
      return null;
    }
    return <StyledIconAPI className="api-icon">APP</StyledIconAPI>;
  }

  function getMetadataSource() {
    if (item.content.message.from.type === Communicator.API) {
      return 'Self-Service';
    }
    return '';
  }

  function getMetadataText() {
    switch (item.content.interactionType) {
      case InteractionType.CHAT:
        return `${getMetadataSource()} sent a chat`;
      default:
        return 'sent';
    }
  }
}

MessageAutomationItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
