import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import InboundIcon from './icon/inbound_icon';
import ItemsContext from './items_context';
import OutboundIcon from './icon/outbound_icon';
import { typeReflect } from 'models/conversation_item/content_from_js';

export function ItemContent({
  children,
  className,
  isAuditItem,
  isCollapsed,
  isFirstInStack,
  isHighlighted,
  isInbound,
  isItemGroupHighlighted,
  isLastInStack,
  isSomeGroupHighlighted,
  isStacked,
  item,
  showIcon,
}) {
  let itemType = typeReflect.instanceToType(item.content);
  if (itemType === ConversationItemType.CONVERSATION_MESSAGE) {
    itemType = item.content.getChannelType();
  }
  isInbound = isInbound != null ? isInbound : item.isInbound();
  const contentClassNames = classnames(className, 'conversationItem-content', {
    'conversationItem-content-inbound': isInbound,
    'conversationItem-content-outbound': !isInbound,
    'conversationItem-content-withLink': item.linkedItems && item.linkedItems.length,
  });

  const containerClassNames = classnames('conversationItem-container', {
    'conversationItem-container-audit': isAuditItem,
    'conversationItem-container-collapsed': isCollapsed,
    'conversationItem-container-firstInStack': isFirstInStack,
    'conversationItem-container-highlight': isHighlighted,
    'conversationItem-container-stacked': isStacked && !isLastInStack,
    'conversationItem-container-dimmed': !isItemGroupHighlighted && isSomeGroupHighlighted,
  });

  return (
    <div className={containerClassNames}>
      <InboundIcon
        initiator={item.initiator}
        itemType={itemType}
        showIcon={showIcon && isInbound && !isAuditItem && !isCollapsed}
      />
      <div className={contentClassNames}>{children}</div>
      <OutboundIcon
        initiator={item.initiator}
        itemSubType={item.content.activityType}
        itemType={itemType}
        showIcon={showIcon && !isInbound && !isAuditItem && !isCollapsed}
      />
    </div>
  );
}

ItemContent.defaultProps = {
  isAuditItem: false,
  showIcon: true,
};

ItemContent.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  isAuditItem: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isFirstInStack: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isInbound: PropTypes.bool, // Specify this if you can't rely on the item's initiator to be correct
  isItemGroupHighlighted: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isSomeGroupHighlighted: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onUnselectHighlightGroupedMessages: PropTypes.func,
  showIcon: PropTypes.bool,
};

const ItemContentMemo = React.memo(ItemContent);
export { ItemContentMemo };

const HIGHLIGHT_TIMEOUT_DELAY = 1600;

export class ItemContentAnchorHighlighter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorUpdatedAt: props.anchorUpdatedAt,
      isHighlighted: true,
    };
  }

  componentWillUnmount() {
    this.props.clearTimeout(this.highlightTimeout);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.anchorUpdatedAt !== state.anchorUpdatedAt) {
      return {
        anchorUpdatedAt: props.anchorUpdatedAt,
        isHighlighted: true,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isHighlighted && !this.highlightTimeout) {
      this.props.clearTimeout(this.highlightTimeout);
      this.highlightTimeout = this.props.setTimeout(() => {
        this.setState({ isHighlighted: false });
        this.highlightTimeout = null;
      }, HIGHLIGHT_TIMEOUT_DELAY);
    }
  }

  render() {
    return <ItemContentMemo {...this.props} isHighlighted={this.state.isHighlighted} />;
  }
}

ItemContentAnchorHighlighter.defaultProps = {
  clearTimeout: window.clearTimeout.bind(window),
  setTimeout: window.setTimeout.bind(window),
};

ItemContentAnchorHighlighter.propTypes = {
  ...ItemContent.propTypes,
  anchorId: PropTypes.string,
  anchorUpdatedAt: PropTypes.string,
};

function ItemContentContainerMain(props) {
  return (
    <ItemsContext.Consumer>
      {({ anchorId, anchorUpdatedAt }) =>
        anchorId === props.item.id ? (
          <ItemContentAnchorHighlighter {...props} anchorId={anchorId} anchorUpdatedAt={anchorUpdatedAt} />
        ) : (
          <ItemContentMemo {...props} />
        )
      }
    </ItemsContext.Consumer>
  );
}

const ItemContentContainer = connect(mapStateToProps)(ItemContentContainerMain);
export default ItemContentContainer;

function mapStateToProps({ getProvider }, props) {
  const highlightedMessages = getProvider('highlightedMessages').get();
  const customerId = getProvider('profile').get()?.id;

  const isSomeGroupHighlighted = !!highlightedMessages?.id && highlightedMessages.customerId === customerId;
  return { ...props, isSomeGroupHighlighted };
}

ItemContentContainer.propTypes = {
  ...ItemContent.propTypes,
};
