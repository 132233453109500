import AgentActivity from 'models/agent_activity';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class CloseCommentPanel {
  constructor(context) {
    this.context = context;
  }

  run() {
    const commentPanel = this.context.stores.commentPanel.get();
    const isOpen = commentPanel.isOpen();
    commentPanel.close();
    commentPanel.clearItemId();
    this.context.stores.commentPanel.set(commentPanel);

    // Only emit this activity event if the panel was previously open. This prevents sending excessive
    // TASK_COMMENT_PANEL_CLOSE events when the customer profile is unloaded
    if (isOpen) {
      this.context.executeAction(SetAndLogActivity, {
        type: AgentActivity.Type.TASK_COMMENT_PANEL_CLOSE,
      });
    }
  }
}
