import _ from 'lodash';
import { Editor, Node, Text, Transforms } from 'slate';
import { ELEMENT_PARAGRAPH } from '@udecode/plate';

import BLOCK_TYPES from 'components/text_editor_new/lib/block_types';
import getFirstNode from 'components/text_editor_new/lib/get_first_node';
import getLastNode from 'components/text_editor_new/lib/get_last_node';

export default function normalizeEditor(editor, options = {}) {
  // Trims whitespace from paragraphs or list nodes
  if (options.trimParagraphWhitespace) {
    trimParagraphWhitespace(editor);
  }
  if (options.trimEmptyParagraphs) {
    trimEmptyParagraphs(editor);
    trimEmptyParagraphs(editor, true);
  }

  // Normally, we should not end up with no children. This is a last ditch effort to fix the editor
  // in case we somehow deleted everything.
  if (!editor.children?.length) {
    editor.children = [{ type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }];
    const editorStart = Editor.start(editor, []);

    // Make sure to reset selection so that it does not point to some non-existing nodes
    Transforms.select(editor, { anchor: editorStart, focus: editorStart });
  }
}

function hasNonTextChildren(node) {
  const nodeChildren = node?.children;

  if (_.isEmpty(nodeChildren)) {
    return !Text.isText(node);
  } else {
    return _.some(nodeChildren, child => hasNonTextChildren(child));
  }
}

function trimEmptyParagraphs(editor, reverse = false) {
  while (editor.children?.length > 1) {
    const nodeIndex = reverse ? editor.children.length - 1 : 0;
    const node = editor.children[nodeIndex];

    // Break if we stumbled upon something that is not a plain text paragraph (i.e. list or inline image)
    if (node.type !== ELEMENT_PARAGRAPH || hasNonTextChildren(node)) break;

    // Break if we came across non-empty paragraph
    const text = _.trim(Node.string(node));
    if (text) break;

    Transforms.removeNodes(editor, { at: [nodeIndex] });
  }
}

function trimParagraphWhitespace(editor) {
  // Go through each block, trim the text from the front
  for (const [, blockPath] of Editor.nodes(editor, {
    at: [],
    match: n => {
      return _.includes(BLOCK_TYPES, n.type);
    },
  })) {
    let firstTextEntry = getFirstNode(editor, {
      at: blockPath,
      match: n => Text.isText(n),
    });

    if (!firstTextEntry) {
      break;
    }

    const [firstText, firstTextPath] = firstTextEntry;
    const startTrimmed = firstText.text.replace(/^[^\S\r\n]+/, '');
    const firstStartDiff = firstText.text.length - startTrimmed.length;

    if (firstStartDiff > 0) {
      Transforms.delete(editor, {
        at: {
          offset: 0,
          path: firstTextPath,
        },
        distance: firstStartDiff,
      });
    }
  }

  // Go through each block, trim the text from the end (only space-like characters, not newlines)
  for (const [, blockPath] of Editor.nodes(editor, {
    at: [],
    match: n => {
      return _.includes(BLOCK_TYPES, n.type);
    },
  })) {
    let lastTextEntry = getLastNode(editor, {
      at: blockPath,
      match: n => Text.isText(n),
    });

    if (!lastTextEntry) {
      break;
    }

    const [lastText, lastTextPath] = lastTextEntry;
    const lastTrimmed = lastText.text.replace(/[^\S\r\n]+$/, '');
    const lastDiff = lastText.text.length - lastTrimmed.length;

    if (lastDiff > 0) {
      Transforms.delete(editor, {
        at: {
          offset: lastText.text.length,
          path: lastTextPath,
        },
        distance: lastDiff,
        reverse: true,
      });
    }
  }
}
