import ModalButton, { ModalButtonDivider } from 'components/modals/modal_button';
import ModalFooter from 'components/modals/modal_footer';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Modal from '../lib/modal';
import React from 'react';

import T from 'prop-types';
let optionType = T.shape({
  className: T.string,
  label: T.string,
  onClick: T.func.isRequired,
});

export default createReactClass({
  propTypes: {
    className: T.string,
    headerIcon: T.string,
    headerText: T.string,
    onCancel: T.func.isRequired,
    option: optionType.isRequired,
    secondOption: optionType,
    renderBody: T.func,
  },

  render() {
    return (
      <Modal className={classnames('conversationModal', this.props.className)}>
        <div className="conversationModal-header">
          {this.props.headerIcon && <i className={this.props.headerIcon} />}
          {this.renderHeaderText()}
        </div>
        <div className="conversationModal-body">{this.props.renderBody && this.props.renderBody()}</div>
        <ModalFooter>
          <ModalButton className="conversationModal-cancelButton" onClick={this.props.onCancel}>
            Cancel
          </ModalButton>
          <ModalButtonDivider />
          {this.renderButton(this.props.option)}
          {this.props.secondOption && <ModalButtonDivider />}
          {this.props.secondOption && this.renderButton(this.props.secondOption)}
        </ModalFooter>
      </Modal>
    );
  },

  renderHeaderText() {
    if (this.props.headerText) {
      return <div className="conversationModal-header-text">{this.props.headerText}</div>;
    }
    return null;
  },

  renderButton(option) {
    let classNames = classnames('conversationModal-confirmButton', option.className);
    return (
      <ModalButton className={classNames} onClick={option.onClick}>
        {option.label}
      </ModalButton>
    );
  },
});
