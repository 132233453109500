import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import styled from 'styled-components';

import { Actions, Close, Header, Modal } from 'components/common/confirm_or_cancel_modal';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import CreditCard from 'models/credit_card';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import GetCreditCard from 'actions/secure_data/get_credit_card';
import Spinner from 'components/common/spinner_two';
import TextInput from 'components/common/text_input';
import theme from 'scripts/presentation/themes/default';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import { useDebounced } from 'components/hooks/debounce_hooks';
import MarkPaymentAsCompleted from 'actions/secure_data/mark_payment_as_completed';

export function ShowCreditCardModal({ creditCard, item, errorMessage, onClose }) {
  const executeAction = useExecuteAction();
  const [isCompleting, setIsCompleting] = useState(false);
  useEffect(() => executeAction(GetCreditCard, { itemId: item.id }), [executeAction, item.id]);
  const debouncedMarkPaymentAsCompleted = useDebounced(markPaymentAsCompleted, 500, {
    leading: true,
    trailing: false,
  });

  return (
    <PortalModalWrapper>
      <Modal data-aid="ShowCreditCardModal">
        <ModalCard onClose={onClose}>
          <ModalContent>
            <Close onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </Close>
            <Header>Credit Card</Header>
            {renderModalBody()}
            {renderActionButtons()}
          </ModalContent>
        </ModalCard>
      </Modal>
    </PortalModalWrapper>
  );

  function renderModalBody() {
    if (errorMessage) {
      return (
        <ModalBodyError>
          <Label>
            <LabelBody>{errorMessage}</LabelBody>
          </Label>
        </ModalBodyError>
      );
    } else if (!creditCard) {
      return (
        <ModalBodyLoading>
          <Spinner color={theme.colors.green400} size={68} />
        </ModalBodyLoading>
      );
    }
    return <ModalBody>{renderCreditCard()}</ModalBody>;
  }

  function markPaymentAsCompleted() {
    setIsCompleting(true);
    executeAction(MarkPaymentAsCompleted, { customerId: item.customerId, itemId: item.id });
  }

  function renderCreditCard() {
    const fsExclude = 'fs-exclude';
    return (
      <div>
        <Label>
          <LabelTitle>Card Number</LabelTitle>
          <Input className={fsExclude} readOnly value={creditCard.cardNumber} />
        </Label>
        <Label>
          <LabelTitle>Name on card</LabelTitle>
          <Input className={fsExclude} readOnly value={creditCard.cardName} />
        </Label>
        <InputsContainer>
          <Label>
            <LabelTitle>Expiration Date</LabelTitle>
            <Input className={fsExclude} readOnly value={creditCard.cardExpirationDate} />
          </Label>
          <Label>
            <LabelTitle>Security Code</LabelTitle>
            <Input className={fsExclude} readOnly value={creditCard.cardCvv} />
          </Label>
        </InputsContainer>
      </div>
    );
  }

  function renderActionButtons() {
    if (!creditCard) {
      return null;
    }
    return (
      <Actions>
        <Button buttonType={ButtonTypes.TEXT} data-aid="on-close" onClick={onClose}>
          Cancel
        </Button>
        {renderMarkCompleteButton()}
      </Actions>
    );
  }

  function renderMarkCompleteButton() {
    if (isCompleting) {
      return (
        <SpinnerButton buttonType={ButtonTypes.PRIMARY}>
          <Spinner color={theme.colors.white} size={15} />
        </SpinnerButton>
      );
    }

    return (
      <Button buttonType={ButtonTypes.PRIMARY} data-aid="mark-as-completed" onClick={debouncedMarkPaymentAsCompleted}>
        Mark as Completed
      </Button>
    );
  }
}

ShowCreditCardModal.propTypes = {
  creditCard: PropTypes.instanceOf(CreditCard),
  item: PropTypes.instanceOf(ConversationItem),
  errorMessage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const ModalContent = styled.div`
  padding: ${p => p.theme.spacing.large};
`;

const ModalBody = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 240px;
  justify-content: center;
  flex-direction: column;
  width: 419px;
`;

const ModalBodyLoading = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 133px;
  justify-content: center;
  flex-direction: column;
  width: 419px;
`;

const ModalBodyError = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 108px;
  justify-content: center;
  flex-direction: column;
  width: 419px;
`;

const Label = styled.label`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-right: ${p => p.theme.spacing.large};
  width: 100%;
`;

const LabelTitle = styled.span`
  font-weight: bold;
  margin-top: ${p => p.theme.spacing.large};
  margin-bottom: ${p => p.theme.spacing.medium};
`;

const LabelBody = styled.span`
  font-size: ${p => p.theme.fontSize.medium};
  font-weight: normal;
  margin-left: ${p => p.theme.spacing.large};
  margin-right: ${p => p.theme.spacing.large};
`;

const Input = styled(TextInput)`
  width: 100%;
`;

const InputsContainer = styled.div`
  display: flex;
`;

const SpinnerButton = styled(Button)`
  width: 155px;
`;

function mapStateToProps({ getProvider }, { onClose }) {
  const { creditCard, item, errorMessage } = getProvider('modal').get();
  return {
    creditCard,
    item,
    errorMessage,
    onClose,
  };
}

export default connect(mapStateToProps)(ShowCreditCardModal);
