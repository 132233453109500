import createModel, { prop } from './lib/create_model';

const DownloadableAttachment = createModel({
  modelName: 'DownloadableAttachment',

  properties: {
    id: prop(String).isRequired,
    downloadUrl: prop(String).isRequired,
    fileDescriptor: prop({
      contentType: String,
      fileSize: String,
      filename: String,
    }).isRequired,
  },

  isImage() {
    return this.fileDescriptor.contentType && this.fileDescriptor.contentType.startsWith('image/');
  },

  statics: {
    create({ id, fileDescriptor, downloadUrl }) {
      return new this({
        downloadUrl,
        fileDescriptor,
        id,
      });
    },
  },
});

export default DownloadableAttachment;
