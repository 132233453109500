import routes from 'scripts/adapters/routes/routes';
import Router from 'scripts/adapters/routes/router';

export default function configureRouter({ runnerContext, viewState }) {
  return new Router({
    routes,
    runnerContext,
    viewState,
  });
}
