export default class Scheduler {
  constructor(executor) {
    this.executor = executor;
    this.timers = new Map();
  }

  executeEvery(period, id, action, params) {
    this.cancel(id);
    this.timers.set(
      id,
      setInterval(() => this.executor.executeAction(action, params), period)
    );
  }

  executeIn(delay, id, action, params) {
    this.cancel(id);
    this.timers.set(id, setTimeout(execute.bind(this), delay));

    function execute() {
      this.executor.executeAction(action, params);
      this.timers.delete(id);
    }
  }

  cancel(id) {
    let timer = this.timers.get(id);
    if (timer) {
      clearTimeout(timer); // also clears interval
    }

    return this.timers.delete(id);
  }

  has(id) {
    return this.timers.has(id);
  }
}
