import _ from 'lodash';

import ActiveSession, { SessionCustomer } from 'models/active_session';
import { cleanUpActiveSession } from 'actions/communication_session/cleanup_active_session';
import closeNotification from 'actions/notification/lib/close_notification';
import CurrentCompositionUpdater from 'actions/lib/current_composition_updater';
import DeclinedReassignedSessionNotification from 'models/notification/declined_reassigned_session_notification';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import isCustomerLoaded from 'actions/customer/lib/is_customer_loaded';
import mixin from 'scripts/lib/mixin';
import { NotExistError } from 'scripts/application/lib/error';
import { NotificationSoundType } from 'actions/notification/lib/notification_constants';
import playNotificationSound from 'actions/notification/lib/play_notification_sound';
import qconsole from 'scripts/lib/qconsole';
import requestSessionDependencies from 'actions/communication_session/request_session_dependencies';
import RoutingQueueItem, { AgentParticipantStatus, RoutingStatus } from 'models/routing_queue_item';

export default class RoutingQueueItemGatewayObserver {
  constructor(context) {
    this.context = context;
    this.currentCompositionUpdater = new CurrentCompositionUpdater(context);
  }

  handleRoutingQueueItem(queueItemDto) {
    let queueItem = RoutingQueueItem.fromJs(queueItemDto);

    let session = this.context.stores.activeSessions.findBy({ id: queueItem.id });
    if (!session) {
      return;
    }

    if (session.queueItem._version >= queueItem._version) {
      qconsole.log(`Ignored outdated version [${queueItem._version}] of queue item [${queueItem.id}]`);
      return;
    }

    const currentAgentId = this.context.stores.currentAgent.get().id;
    if (!queueItem.getAgentParticipantStatus(currentAgentId)) {
      closeNotification(this.context, queueItem.id);
      cleanUpActiveSession(this.context, session);
      this.currentCompositionUpdater.update();
      return;
    }

    // check to see if we need to pop a failed transfer banner
    const wasYieldingAgent =
      session.queueItem.status === RoutingStatus.OFFERED_ACTIVE &&
      session.queueItem.getAgentParticipantStatus(currentAgentId) === AgentParticipantStatus.YIELDED;
    const isNowActiveAgent = queueItem.status === 'ACTIVE' && queueItem.assignee.agentId === currentAgentId;

    if (wasYieldingAgent && isNowActiveAgent) {
      const customerProfile = session.customer;

      playNotificationSound(NotificationSoundType.DECLINED_SESSION_REASSIGNMENT);
      this.context.stores.notifications.addOrReplace(
        DeclinedReassignedSessionNotification.create({
          conversationId: queueItem.conversationId,
          profile: customerProfile,
          transferee: session.queueItem.assignee,
          sessionType: _.get(queueItem.getLatestSession(), 'type'),
        })
      );
    }

    session.update({ queueItem });

    if (queueItem.customerId !== session.customer.id) {
      let customerName;
      if (this.context.stores.customers.has({ id: queueItem.customerId })) {
        customerName = this.context.stores.customers.storesFor(queueItem.customerId).profile.get().name;
      }

      session.update({ customer: SessionCustomer.create({ id: queueItem.customerId, name: customerName }) });
    }

    this.context.stores.activeSessions.replace(session);
    this.currentCompositionUpdater.update();
  }

  handleRoutingQueueItemDelete(queueItemId) {
    let session = this.context.stores.activeSessions.findBy({ id: queueItemId });
    if (!session) {
      return;
    }

    this.context.capacityManager.postponeMessagingRouting();
    cleanUpActiveSession(this.context, session);
    this.currentCompositionUpdater.update();
  }

  handleFindAllSuccess(ctx, dtos) {
    let activeSessions = _.map(dtos, dto => {
      let queueItem = RoutingQueueItem.fromJs(dto);
      return ActiveSession.fromJs({
        id: queueItem.id,
        queueItem,
        customer: {
          id: queueItem.customerId,
        },
      });
    });

    _.forEach(activeSessions, as => {
      requestSessionDependencies(this.context, as.customer.id, as.id, as.conversationId());
      if (isCustomerLoaded(this.context, as.customer.id)) {
        let customerStores = this.context.stores.customers.storesFor(as.customer.id);
        let customerName = customerStores.profile.get().name;
        if (customerName) {
          as.updateCustomerName(customerName);
        }
      } else {
        this.context.gateways.customerProfile.request({ customerId: as.customer.id });
      }
    });
    this.context.stores.activeSessions.set(activeSessions);
  }

  handleFindAllError(ctx, err) {
    this.handleCommonErrors(ctx, err);
  }

  handleFetchSuccess(ctx, dto) {
    this.handleRoutingQueueItem(dto);
  }

  handleFetchError(ctx, err) {
    if (err instanceof NotExistError) {
      qconsole.log(`queueItem with id: ${ctx.itemId} not found`);
      let session = this.context.stores.activeSessions.findBy({ id: ctx.itemId });
      if (session) {
        cleanUpActiveSession(this.context, session);
      }
      return;
    }
    this.handleCommonErrors(ctx, err);
  }
}

mixin(RoutingQueueItemGatewayObserver.prototype, GatewayErrorSilentHandler.prototype);
