import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function IntegrationPlaceholderRow({ externalSystemName, numColumns, numRows, message }) {
  const trClassNames = classnames(
    'externalCustomerLookupSearchResults-table-externalProfileRow',
    'externalCustomerLookupSearchResults-table-lastRow'
  );

  const messageCell = (
    <td colSpan={numColumns + 1} rowSpan={numRows || 1}>
      {message}
    </td>
  );
  return (
    <tr className={trClassNames}>
      <td className="externalCustomerLookupSearchResults-table-sourceCell" key="externalCustomerLookup-sourceCell">
        <div className="sourceName">{externalSystemName}</div>
      </td>
      {messageCell}
    </tr>
  );
}

IntegrationPlaceholderRow.propTypes = {
  externalSystemName: PropTypes.string,
  message: PropTypes.string,
  numColumns: PropTypes.number,
  numRows: PropTypes.number,
};
