import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import LiveboardType from 'models/liveboards/liveboard_type';
import LiveboardsLocation from 'models/location/liveboards';
import MultipleInboxMenu from 'components/lib/multiple_inbox_menu';
import MultipleTeamMenu from 'components/lib/multiple_team_menu';

export function LiveboardFilter({ className, currentLocation }) {
  const classes = classnames('liveboardFilter-selectors', className);
  return (
    <div className={classes}>
      {currentLocation.type === LiveboardType.AGENTS ? (
        <MultipleTeamMenu currentLocation={currentLocation} dropDown optionIds={currentLocation.teamIds} />
      ) : null}
      <MultipleInboxMenu
        currentLocation={currentLocation}
        dropDown
        excludeDisabled
        optionIds={currentLocation.routingGroupIds}
      />
    </div>
  );
}

LiveboardFilter.propTypes = {
  className: PropTypes.string,
  currentLocation: PropTypes.instanceOf(LiveboardsLocation).isRequired,
};

function mapStateToProps({ getProvider }) {
  const currentLocation = getProvider('currentLocation').get();

  return {
    currentLocation,
  };
}

const LiveboardFilterContainer = connect(mapStateToProps)(LiveboardFilter);

export default LiveboardFilterContainer;
