import './lib/uuid';
import { Factory } from 'rosie';

Factory.define('ChannelConfiguration');

Factory.define('ChannelConfigurationWithDefaults')
  .extend('ChannelConfiguration')
  .attr('endpoints', () => [
    Factory.attributes('ChatEndpoint'),
    Factory.attributes('EmailEndpoint', { address: 'billing@company.com' }),
    Factory.attributes('EmailEndpoint', { address: 'support@company.com' }),
    Factory.attributes('FbMessengerEndpoint', { address: 'abcdef' }),
    Factory.attributes('InstagramDirectEndpoint', { address: '2345724895' }),
    Factory.attributes('SmsEndpoint'),
    Factory.attributes('VoiceEndpoint'),
    Factory.attributes('WhatsAppEndpoint'),
    Factory.attributes('CustomChannelEndpoint', {
      address: 'CUSTOM_CHANNEL:1',
      configuration: { customChannelId: '1' },
    }),
  ]);
