import { Factory } from 'rosie';

import { IdGenerator } from './lib/uuid';

Factory.define('ExternalDataObjectWithDefaults')
  .extend('Uuid')
  .attr('namespace', 'some-namespace')
  .attr('parentEntityType', 'CUSTOMER')
  .attr('parentEntityId', [], () => IdGenerator.newId())
  .attr('data', {
    externalDataItems: [],
  });
