import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import ActivateDefaultResponse from 'actions/composition/activate_default_response';
import connect from 'components/lib/connect';
import ConversationItemType from 'models/conversation_item_type';
import CreateConversation from 'actions/conversation/create_conversation';
import { getLatestManualCustomerConversationItemAttrs } from 'scripts/application/lib/conversation_history_helpers';
import { getActiveChatSessionIdFromHistory } from 'actions/communication_session/session_finder';
import { MessageChannelType } from 'models/conversation_message';
import registerHotkey from 'components/hotkeys/register_hotkey';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

export class ResponsePrompt extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handlePromptClick']);
  }

  render() {
    return (
      <div
        className={classnames('responsePrompt', { 'responsePrompt-disabled': this.props.isDisabled })}
        data-aid="defaultResponsePrompt"
        onClick={this.handlePromptClick}
      >
        {this.getDisplayText()}
      </div>
    );
  }

  getDisplayText() {
    if (this.props.isNewConversationPending) {
      return 'Creating a new conversation...';
    }

    if (!this.props.hasConversations) {
      return 'Start a conversation';
    }

    const contentType = this.props.lastCustomerConversationItemContentType;
    if (
      this.props.customerHasActiveChat ||
      (this.props.lastCustomerConversationIsAutomation && contentType === ConversationItemType.CHAT)
    ) {
      return 'Reply with a chat';
    } else if (contentType === ConversationItemType.EMAIL) {
      return 'Reply with an email';
    } else if (contentType === ConversationItemType.FB_MESSAGE_INCOMING) {
      return 'Reply via Facebook';
    } else if (contentType === ConversationItemType.VOICEMAIL) {
      return 'Reply with a call';
    } else if (contentType === ConversationItemType.SMS) {
      return 'Reply with an SMS';
      // Message Channel types
    } else if (contentType === MessageChannelType.TWITTER) {
      return 'Reply with a tweet';
    } else if (contentType === MessageChannelType.WHATSAPP) {
      return 'Reply with a WhatsApp';
    } else if (contentType === MessageChannelType.INSTAGRAM_DIRECT) {
      return 'Reply via Instagram';
    }

    return 'Create a note';
  }

  handlePromptClick() {
    this.respond();
  }

  respond() {
    if (this.props.isDisabled) {
      return;
    }

    if (!this.props.hasConversations) {
      this.props.onCreate();
      return;
    }

    this.props.onReply();
  }
}

export const ResponsePromptWithShortcutHandler = withShortcuts(ResponsePrompt, [
  registerHotkey({
    key: 'alt+r',
    callback: instance => instance.respond(),
    group: 'Conversation',
    label: 'Reply to or reopen conversation',
  }),
]);

ResponsePrompt.propTypes = ResponsePromptWithShortcutHandler.propTypes = {
  hasConversations: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isNewConversationPending: PropTypes.bool,
  lastCustomerConversationIsAutomation: PropTypes.bool,
  lastCustomerConversationItemContentType: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
};

const ResponsePromptContainer = connect(mapStateToProps, mapExecuteToProps)(ResponsePromptWithShortcutHandler);
export default ResponsePromptContainer;

ResponsePromptContainer.propTypes = {
  conversationId: PropTypes.string,
};

function mapStateToProps({ getProvider }, { conversationId }) {
  const profile = getProvider('profile').get();
  let conversationHistory = getProvider('conversationHistory');
  let conversations = getProvider('conversations');
  let latestCustomerAttrs = getLatestManualCustomerConversationItemAttrs({
    conversationHistory,
    conversationId,
    fields: ['content'],
  });
  let lastItemContent = latestCustomerAttrs && latestCustomerAttrs.content;

  let isNewConversationPending = getProvider('conversations').isPendingNew();
  let currentItemsLoading = getProvider('conversationHistory').isLoading();
  const isNavigatingToNextConversation = getProvider('conversationWorkflow').get().isNavigatingNextConversation;
  let lastCustomerConversationItemContentType = lastItemContent && lastItemContent.type;
  // Generic message items will rely on the channel type
  if (lastCustomerConversationItemContentType === ConversationItemType.CONVERSATION_MESSAGE) {
    lastCustomerConversationItemContentType = lastItemContent.channel;
  }
  const lastCustomerConversationIsAutomation =
    lastItemContent && lastItemContent.type === ConversationItemType.MESSAGE_AUTOMATION_SUMMARY;
  if (lastCustomerConversationIsAutomation) {
    lastCustomerConversationItemContentType = lastItemContent.interactionType;
  }

  return {
    customerHasActiveChat: !!getActiveChatSessionIdFromHistory({ conversationHistory, conversations }),
    hasConversations: conversations.count() > 0,
    isDisabled:
      !(profile && profile.id) || isNewConversationPending || currentItemsLoading || isNavigatingToNextConversation,
    isNewConversationPending,
    lastCustomerConversationIsAutomation,
    lastCustomerConversationItemContentType,
  };
}

function mapExecuteToProps(executeAction, { conversationId }) {
  return {
    onCreate: () => executeAction(CreateConversation),
    onReply: () => executeAction(ActivateDefaultResponse, { conversationId }),
  };
}
