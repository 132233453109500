import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';

export default class ClickExternalLink {
  constructor(context) {
    this.context = context;
  }

  run({ type, subType, attrName }) {
    const currentLocation = this.context.stores.currentLocation.get();
    const customerId = currentLocation.customerId;
    const conversationsStore = this.context.stores.conversations;
    const conversationId = conversationsStore && getActiveConversationId(conversationsStore);

    this.context.analytics.track('External Link Clicked', { customerId, conversationId, type, subType, attrName });
  }
}
