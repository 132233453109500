import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function PrepositionalPhrase({ complement, preposition, suppressHighlight }) {
  const classes = classnames('conversationMetadata-prepPhrase-complement', {
    'conversationMetadata-prepPhrase-complement-highlight': !suppressHighlight,
  });

  return (
    <span className="conversationMetadata-prepPhrase">
      {preposition ? renderPreposition() : false}
      <span className={classes}> {complement}</span>
    </span>
  );

  function renderPreposition() {
    return <span className="conversationMetadata-prepPhrase-prep"> {preposition}</span>;
  }
}

PrepositionalPhrase.propTypes = {
  complement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  preposition: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suppressHighlight: PropTypes.bool,
};
