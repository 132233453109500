import AmplitudeAdapter from 'scripts/lib/analytics/adapters/amplitude';
import FullstoryAdapter from 'scripts/lib/analytics/adapters/fullstory';
import SnowplowAdapter from 'scripts/lib/analytics/adapters/snowplow';
import AnalyticsTools from 'scripts/lib/analytics/domain/analytics_tools';

export default function(config) {
  let adapters = {};
  if (config.amplitudeApiKey) {
    adapters[AnalyticsTools.AMPLITUDE] = new AmplitudeAdapter(config.amplitudeApiKey);
  }
  if (config.fullstoryOrgId) {
    adapters[AnalyticsTools.FULLSTORY] = new FullstoryAdapter(config.fullstoryOrgId);
  }

  if (config.snowplowEndpoint) {
    adapters[AnalyticsTools.SNOWPLOW] = new SnowplowAdapter(config.snowplowEndpoint, config.cluster);
  }

  return adapters;
}
