import styled from 'styled-components';
import { ModalButtonDivider } from 'components/modals/modal_button';

const ModalFooter = styled.div`
  background-color: ${p => p.theme.colors.green400};
  border-bottom-right-radius: ${p => p.theme.borderRadius.default};
  border-bottom-left-radius: ${p => p.theme.borderRadius.default};
  display: flex;
  &:hover {
    &:hover {
      ${ModalButtonDivider} {
        background: transparent;
      }
    }
  }
`;

export default ModalFooter;
