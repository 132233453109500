import { Factory } from 'rosie';

import './lib/uuid';

Factory.define('OptionalUpgradeNotification').extend('Uuid').attr('type', 'OPTIONAL_UPGRADE');

Factory.define('OptionalUpgradeNotificationWithDefaults').extend('OptionalUpgradeNotification');

Factory.define('PostponedOptionalUpgradeNotification')
  .extend('OptionalUpgradeNotificationWithDefaults')
  .attr('isPostponed', true);
