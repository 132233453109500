import AgentActivity from 'models/agent_activity';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import UpdateAgentTaskRead from 'actions/task/update_agent_task_read';

export default class OpenCommentPanel {
  constructor(context) {
    this.context = context;
  }

  run({ commentId, itemId, refreshOnly }) {
    const answerPanel = this.context.stores.answerPanel.get();
    answerPanel.close();
    this.context.stores.answerPanel.set(answerPanel);

    const commentPanel = this.context.stores.commentPanel.get();
    if (!refreshOnly) {
      commentPanel.open();
      commentPanel.selectItemId(itemId);
    }
    if (commentId) {
      commentPanel.setSelectedCommentId(commentId);
    }
    this.context.stores.commentPanel.set(commentPanel);

    // Are we just refocusing on the existing item? If not, record the activity and update read times
    if (!refreshOnly) {
      this.context.executeAction(SetAndLogActivity, {
        type: AgentActivity.Type.TASK_COMMENT_PANEL_OPEN,
      });
      this.context.executeAction(UpdateAgentTaskRead, itemId);
    }
  }
}
