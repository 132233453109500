import { Factory } from 'rosie';

Factory.define('ReportConfigSummary')
  .attr('metricSetName', 'SummaryReport')
  .attr('title', 'Operational Summary')
  .attr('categoryId', 'Operational Performance')
  .attr('urlSlug', 'summary')
  .attr('filters', ['dateRange', 'timeAggregation', 'channel', 'inboxes'])
  .attr('timeAggregation', ['daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('ReportConfigActiveConversationHandleTimeByAgent')
  .attr('metricSetName', 'ActiveConversationHandleTimeByAgentReport')
  .attr('title', 'Active Conversation Handle Time by Agent')
  .attr('categoryId', 'Agent Performance')
  .attr('urlSlug', 'active-conversation-handle-time-by-agent')
  .attr('filters', ['dateRange', 'timeAggregation', 'channel', 'inboxes', 'teams'])
  .attr('timeAggregation', ['halfHourly', 'daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('ReportConfigRestrictedTimeAggregation')
  .attr('metricSetName', 'ReportConfigRestrictedTimeAggregation')
  .attr('title', 'Report Config Test')
  .attr('categoryId', 'Agent Performance')
  .attr('urlSlug', 'report-config-test')
  .attr('filters', ['dateRange', 'timeAggregation', 'channel', 'inboxes', 'teams'])
  .attr('timeAggregation', ['daily', 'weekly', 'monthly']);

Factory.define('ReportConfigConversationSummary')
  .attr('metricSetName', 'ConversationSummaryReport')
  .attr('title', 'Conversation Summary')
  .attr('categoryId', 'Conversations')
  .attr('urlSlug', 'conversation-summary')
  .attr('filters', ['dateRange', 'timeAggregation', 'inboxes', 'teams'])
  .attr('timeAggregation', ['halfHourly', 'daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('ReportConfigChannelMix')
  .attr('metricSetName', 'ChannelMixReport')
  .attr('title', 'Channel Mix')
  .attr('categoryId', 'Channel Performance')
  .attr('urlSlug', 'channel-mix')
  .attr('filters', ['dateRange', 'timeAggregation', 'inboxes'])
  .attr('timeAggregation', ['halfHourly', 'daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('ReportConfigActiveConversationHandleTimeByCustomer')
  .attr('metricSetName', 'ActiveConversationHandleTimeByCustomerReport')
  .attr('title', 'Active Conversation Handle Time by Customer')
  .attr('categoryId', 'Customer Insights')
  .attr('urlSlug', 'active-conversation-handle-time-by-customer')
  .attr('filters', ['dateRange', 'timeAggregation', 'inboxes'])
  .attr('timeAggregation', ['halfHourly', 'daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('ReportConfigIvrSummary')
  .attr('metricSetName', 'IvrExecutiveSummaryReport')
  .attr('title', 'IVR')
  .attr('categoryId', 'Customer Experience')
  .attr('urlSlug', 'ivr-summary')
  .attr('filters', ['dateRange', 'timeAggregation', 'phoneNumber'])
  .attr('timeAggregation', ['daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('ReportConfigActiveConversationHandleTimeByTopic')
  .attr('metricSetName', 'ActiveConversationHandleTimeByTopicReport')
  .attr('title', 'Active Conversation Handle Time by Topic')
  .attr('categoryId', 'Operational Performance')
  .attr('urlSlug', 'active-conversation-handle-time-by-topic')
  .attr('filters', ['dateRange', 'channel', 'inboxes']);

Factory.define('ReportConfigTopics')
  .attr('metricSetName', 'TopicHierarchyReport')
  .attr('title', 'Topics')
  .attr('categoryId', 'Customer Experience')
  .attr('urlSlug', 'topics')
  .attr('filters', ['dateRange', 'timeAggregation', 'channel', 'inboxes', 'topics'])
  .attr('timeAggregation', ['daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('ReportConfigIvrEndStates')
  .attr('metricSetName', 'IvrEndStatesReport')
  .attr('title', 'IVR')
  .attr('categoryId', 'Customer Experience')
  .attr('urlSlug', 'ivr-end-states')
  .attr('filters', ['dateRange', 'timeAggregation', 'phoneNumber'])
  .attr('timeAggregation', ['daily', 'weekly', 'monthly', 'quarterly']);

Factory.define('SharedReportConfig')
  .attr('metricSetName', '15')
  .attr('title', 'My Custom Report')
  .attr('categoryId', 'Shared')
  .attr('urlSlug', '15');

Factory.define('ReportConfigDailyTrends')
  .attr('metricSetName', 'DailyTrendsReport')
  .attr('title', 'Daily Trends')
  .attr('categoryId', 'Conversations')
  .attr('filters', [])
  .attr('urlSlug', 'daily-trends');
