import _ from 'lodash';
import { SnippetChannel } from 'models/answers/snippet';

export const CONTEXT_ANSWER = 'iglu:com.gladly/context_answer/jsonschema/1-0-0';

export const getAnswerContextFromAnswer = (answer, selectedAnswerType, selectedAnswerLanguage) => ({
  schema: CONTEXT_ANSWER,
  data: {
    id: answer.id,
    name: _.truncate(answer.name, { length: 255 }),
    contentType: {
      [SnippetChannel.ANY_CHANNEL]: 'anyChannel',
      [SnippetChannel.MESSAGE]: 'message',
      [SnippetChannel.SELF_SERVICE]: 'selfService',
      [SnippetChannel.INFO]: 'info',
    }[selectedAnswerType],
    language: selectedAnswerLanguage,
  },
});
