import _ from 'lodash';

import Mention from 'models/mention';
import RecentlyMentionedAgentId from 'models/recently_mentioned_agent_id';

export default function convertAgentIdsToMentions(context, content) {
  let mentions = [];
  _.forEach(content.mentionedAgentIds, mentionedAgentId => {
    const agentProfile = context.stores.agents.findBy({ id: mentionedAgentId });
    if (!agentProfile || agentProfile.disabledAt) {
      return;
    }

    mentions.push(
      new Mention({
        id: agentProfile.id,
        name: agentProfile.name || agentProfile.email,
        type: 'AGENT',
      })
    );
  });

  keepTrackOfRecentMentions(context, mentions);

  const newContent = {
    ...content,
    mentions,
  };
  delete newContent.mentionedAgentIds;
  return newContent;
}

function keepTrackOfRecentMentions(context, mentions) {
  _.forEach(mentions, mention => {
    let recent = context.stores.recentlyMentionedAgentIds.findBy({ id: mention.id });
    if (!recent) {
      recent = RecentlyMentionedAgentId.create({
        id: mention.id,
      });
    } else {
      recent.incrementCount();
    }
    context.stores.recentlyMentionedAgentIds.addOrReplace(recent);
  });

  const allRecent = context.stores.recentlyMentionedAgentIds.findAll();
  const sortedRecent = _.sortBy(allRecent, r => r.updatedAt).reverse();
  const truncatedRecent = sortedRecent.slice(0, 20);

  context.stores.recentlyMentionedAgentIds.set(truncatedRecent);
  context.stores.localRecentlyMentionedAgentIds.set(truncatedRecent);
}
