import _ from 'lodash';

import Composition from 'models/composition';
import Upload from 'models/upload';
import { AttachmentType } from 'models/attachment/attachment_classes';

export default {
  toDto(composition) {
    let jso = composition.toJs();
    jso.attachments = _.filter(jso.attachments, a => {
      if (a.type === AttachmentType.UPLOAD) {
        return a.status === Upload.Status.COMPLETED;
      }
      return true;
    });

    return jso;
  },

  fromDto(dto) {
    dto.attachments = _.filter(dto.attachments, a => {
      if (a.type === AttachmentType.Upload && a.status !== Upload.Status.COMPLETED) {
        return false;
      }
      return true;
    });
    return Composition.fromJs(dto);
  },
};
