import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import createEnum from 'scripts/lib/create_enum';
import FlippableChevron from 'components/customer/conversation_history/conversation_items_v2/content/flippable_chevron';

export const MetadataType = createEnum('CAMPAIGN', 'OTHER', 'QUICK_ACTION', 'SEARCH', 'URL', 'VIEW');

const descriptionText = {
  [MetadataType.CAMPAIGN]: { action: '', noun: 'campaign' },
  [MetadataType.QUICK_ACTION]: { action: 'Clicked quick action', noun: 'quick action' },
  [MetadataType.SEARCH]: { action: 'Searched for', noun: 'term' },
  [MetadataType.VIEW]: { action: 'Viewed', noun: 'answer' },
  [MetadataType.URL]: { action: 'Opened on', noun: '' },
  [MetadataType.OTHER]: { noun: 'action' },
};

const MetadataWrapper = styled.div`
  display: flex;
`;

const ExpanderWrapper = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  display: flex;
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${p => p.theme.spacing.small};
  .conversationItem-flippableChevron {
    font-size: 5px;
    height: 5px;
    &:first-child {
      margin-bottom: 3px;
    }
  }
`;

let standardOffset = '13px';
let edgeOffset = '10px';
let singleItemOffset = '7px';

const Dot = css`
  &::before {
    background: ${p => p.theme.colors.purple400};
    border-radius: 50%;
    content: '';
    height: 7px;
    position: absolute;
    right: 1px;
    top: ${p => {
      if (p.isFirst && p.isLast) {
        return singleItemOffset;
      } else if (p.isFirst || p.isLast) {
        return edgeOffset;
      }
      return standardOffset;
    }};
    width: 7px;
  }
`;

const HalfLine = css`
  &::after {
    background: ${p => p.theme.colors.purple400};
    bottom: ${p => (p.isFirst ? 0 : 'auto')};
    content: '';
    height: ${standardOffset};
    position: absolute;
    top: ${p => (p.isLast ? 0 : 'auto')};
    width: 1px;
  }
`;

const DashedLine = css`
  border-left: 1px dashed ${p => p.theme.colors.purple400};
`;

const SolidLine = css`
  border-left: 1px solid ${p => p.theme.colors.purple400};
`;

export const Timeline = styled.div`
  flex: 0 0 auto;
  margin-right: ${p => p.theme.spacing.inlineMedium};
  padding-bottom: ${p => (!p.isLast ? p.theme.spacing.stackMedium : edgeOffset)};
  padding-top: ${p => (!p.isFirst ? p.theme.spacing.stackMedium : edgeOffset)};
  position: relative;
  width: 5px;
  ${p => (p.isCollapsed ? DashedLine : Dot)};
  ${p => !p.isCollapsed && !p.isFirst && !p.isLast && SolidLine};
  ${p => (p.isFirst || p.isLast) && !(p.isFirst && p.isLast) && HalfLine};
`;

export function MetadataExpander({ count, onClick, type }) {
  let text = descriptionText[type];
  return (
    <MetadataWrapper>
      <Timeline isCollapsed />
      <ExpanderWrapper data-aid="expanderWrapper" onClick={onClick}>
        <IconWrapper>
          <FlippableChevron />
          <FlippableChevron isDownwards />
        </IconWrapper>
        {text.action ? `${text.action} ` : ''}
        {`${count} other ${text.noun}${count > 1 ? 's' : ''}`}
      </ExpanderWrapper>
    </MetadataWrapper>
  );
}

MetadataExpander.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys(MetadataType)).isRequired,
};

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
`;

const ActionWrapper = styled.div`
  flex: 0 0 auto;
  padding-right: ${p => p.theme.spacing.small};
`;

export function MetadataText({ isFirst, isLast, node, type }) {
  let text = descriptionText[type];
  return (
    <MetadataWrapper>
      {!(isFirst && isLast) && <Timeline isFirst={isFirst} isLast={isLast} />}
      <TextWrapper>
        {text.action && <ActionWrapper>{text.action}</ActionWrapper>}
        {node}
      </TextWrapper>
    </MetadataWrapper>
  );
}

MetadataText.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  node: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.keys(MetadataType)).isRequired,
};
