import getLastInboxLocation from 'actions/inbox/lib/get_last_inbox_location';

export default class DeleteCustomer {
  constructor(context) {
    this.context = context;
  }

  run({ customerId }) {
    this.context.gateways.complianceCustomer.delete({ customerId });

    this.context.analytics.track('customer deletion - initiated', {
      customerId,
    });

    const inboxLocation = getLastInboxLocation(this.context);
    this.context.router.navigateTo(inboxLocation);
  }
}
