import classnames from 'classnames';
import createReactClass from 'create-react-class';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Calendar } from 'react-date-range';

import Button, { ButtonTypes } from 'components/common/button';
import KeypressGlobalMixin from 'components/lib/keypress_global_mixin';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverCard from 'components/common/popover_card';

export const DatePickerMenu = createReactClass({
  mixins: [KeypressGlobalMixin],

  propTypes: {
    className: PropTypes.string,
    date: PropTypes.string,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholderText: PropTypes.string,
  },

  getInitialState() {
    return { isPickerVisible: false };
  },

  togglePicker(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.props.isDisabled) {
      this.setState({ isPickerVisible: false });
    } else {
      this.setState({ isPickerVisible: !this.state.isPickerVisible });
    }
  },

  onCancel(e) {
    if (this.state.isPickerVisible) {
      this.handleClose();
      this.stopEvent(e);
    }
  },

  handleClose() {
    this.setState({ isPickerVisible: false });
  },

  handleSubmit(date) {
    this.props.onChange(date);
    this.handleClose();
  },

  render() {
    let classes = classnames('datePicker', this.props.className, {
      'datePicker-open': this.state.isPickerVisible,
      'datePicker-disabled': this.props.isDisabled,
    });

    const opener = this.opener;
    return (
      <div className={classes}>
        <div className="datePicker-opener" onClick={this.togglePicker} ref={node => (this.opener = node)}>
          {this.renderMenuOpener()}
        </div>
        <PopoverCard
          bounds={{ top: 10 }}
          className="datePicker-popoverCard"
          isVisible={this.state.isPickerVisible}
          position="right"
          targetElement={opener}
        >
          <OutsideClickHandler onClickOutside={this.handleClose}>
            <DatePicker
              button={opener}
              date={this.props.date}
              isDisabled={this.props.isDisabled}
              onClose={this.handleClose}
              onSubmit={this.handleSubmit}
            />
          </OutsideClickHandler>
        </PopoverCard>
      </div>
    );
  },

  renderMenuOpener() {
    return this.props.date ? this.renderSelectedDate() : this.renderDefaultText();
  },

  renderSelectedDate() {
    return (
      <div className="datePicker-selection">
        <div className="datePicker-selection-date">{moment(this.props.date).format('MMM D, YYYY')}</div>
      </div>
    );
  },

  renderDefaultText() {
    const classNames = classnames('datePicker-defaultText', {
      'datePicker-defaultText-open': this.state.isPickerVisible,
    });
    return (
      <span className={classNames}>
        <i className="fa fa-calendar-o datePicker-defaultText-icon" />
        {this.props.placeholderText ? this.props.placeholderText : 'Enter a date'}
      </span>
    );
  },

  preventDefault(evt) {
    evt.preventDefault();
  },
});

export const DatePicker = createReactClass({
  propTypes: {
    button: PropTypes.any,
    date: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return { date: this.props.date ? this.props.date : moment().toISOString() };
  },

  onChangeDate(date) {
    this.setState({ date });
  },

  render() {
    return (
      <div className="datePicker-picker" ref={node => (this.picker = node)}>
        <CalendarPicker date={this.state.date} onChange={this.onChangeDate} />
        <div className="datePicker-buttons">
          <Button
            buttonType={ButtonTypes.TEXT}
            className="datePicker-date-clear"
            disabled={this.props.isDisabled}
            onClick={this.handleClearDate}
          >
            Clear
          </Button>
          <Button
            buttonType={ButtonTypes.PRIMARY}
            className="datePicker-date-apply"
            disabled={this.props.isDisabled}
            onClick={this.handleApplyChanges}
          >
            Apply
          </Button>
        </div>

        <div className="datePicker-picker-leftTriangle" ref={node => (this.triangle = node)}>
          <div className="datePicker-picker-leftTriangle-border" />
        </div>
      </div>
    );
  },

  handleApplyChanges() {
    this.props.onSubmit(this.state.date);
  },

  handleClearDate() {
    this.props.onSubmit('');
  },
});

export const CalendarPicker = createReactClass({
  propTypes: {
    date: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  },

  render() {
    return (
      <Calendar
        date={moment(this.props.date)}
        onChange={this.onChange}
        theme={{
          Calendar: {
            background: null,
            borderRadius: null,
            color: null,
            padding: '0px',
          },
          MonthAndYear: {
            color: null,
            fontSize: null,
          },
          MonthButton: {
            background: null,
          },
          Weekday: {
            color: null,
            fontWeight: null,
          },
          DayPassive: {
            color: null,
          },
          DaySelected: {
            background: null,
            color: null,
          },
        }}
      />
    );
  },

  onChange(value) {
    this.props.onChange(value.toISOString());
  },
});

export default DatePickerMenu;
