import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import { AITextCompletionContextProvider } from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import AITextRewriteControls from 'components/text_editor_new/components/controls/ai_text_rewrite_controls';
import ComposerContext from 'components/composer/contexts/composer_context';
import connect from 'components/lib/connect';
import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';
import SendSms from 'actions/composer/send_sms';
import withStaticId from 'components/customer/lib/with_static_id';

import AISMSHeader from './sms/ai_sms_header_new';
import CharacterCount from 'components/text_editor_new/character_count';
import Composer from './shared/composer_new';
import ComposerEditor from './shared/composer_editor';
import { EditorContainer } from './shared/editor_new';
import EditorErrors from 'components/composer/shared/editor_errors';
import Header from './sms/sms_header_new';
import useAIComposerOptions from 'components/text_editor_new/plugins/ai/ai_text_completion/use_ai_composer_options';
import useComposer from './shared/use_composer';
import { useCreatePasteAttachmentsPlugin } from 'components/text_editor_new/plugins/create_paste_attachments_plugin';
import useEditor from './shared/use_editor';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import useMessagingEditorPlugins from 'components/text_editor_new/hooks/use_messaging_editor_plugins';
import withRefreshEditorWhenFlagsChange from 'components/composer/shared/with_refresh_editor_when_flags_change';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import { AnswerPanelControl, Separator } from 'components/composer/shared/editor_controls_new';

export function SmsComposer({ includeAIFeatures, phoneNumbers, requestorId }) {
  const composerProps = useComposer({ action: SendSms });
  const editorProps = useEditor('bodyHtml', composerProps, { attr: 'body', serializer: serializePlaintext });

  const { composition } = useContext(ComposerContext);
  const currentPhoneNumber = composition.content.to;
  const isInvalidNumber = !currentPhoneNumber || phoneNumbers.indexOf(currentPhoneNumber) === -1;
  const { disableSubmit, readOnly } = useAIComposerOptions(isInvalidNumber);

  editorProps.onSubmit = disableSubmit ? _.noop : editorProps.onSubmit;

  const extraPlugins = useExtraPlugins();
  const editorRef = useMessagingEditorPlugins(composerProps, editorProps, extraPlugins);

  const headerProps = {
    editorProps,
    isInvalidNumber,
    phoneNumbers,
  };

  const composer = (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      disableSubmit={disableSubmit}
      excludeErrors
      includeAttachments
      initialHtml={editorProps.initialHtml}
      submitText="Send"
      {...composerProps}
    >
      {includeAIFeatures ? <AISMSHeader {...headerProps} /> : <Header {...headerProps} />}

      <EditorContainer excludeAnswerMenuButton includeAttachments>
        <ComposerEditor
          includeAIFeatures={includeAIFeatures}
          key={composition.id}
          placeholder="Type to SMS"
          readOnly={readOnly}
          requestorId={requestorId}
          showFloatingToolbar={false}
          {...editorProps}
        />
      </EditorContainer>

      <MessagingActions>
        <ActionBar>
          <>
            <AnswerPanelControl />
            <Separator />
            <AITextRewriteControls />
          </>
          <Emoji {...editorProps} />
          <Attachment />
        </ActionBar>
        <StyledCharacterCount composerRef={composerProps.composerRef} />
      </MessagingActions>

      <EditorErrors />
    </Composer>
  );

  return composer;
}

const StyledCharacterCount = styled(CharacterCount)`
  background-color: inherit;
  position: relative;
`;

const MessagingActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

function SMSComposerWithAIFeatures(props) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);
  const includeAIFeatures = isFeatureEnabled('gclDemo2023') || !agentAssistanceConfig.disableHeroAI;

  let composer = <SmsComposer {...props} includeAIFeatures={includeAIFeatures} />;

  if (includeAIFeatures) {
    return (
      <AITextCompletionContextProvider requestorId={props.requestorId}>{composer}</AITextCompletionContextProvider>
    );
  }
  return composer;
}

const SmsComposerConnected = connect(mapStateToProps)(
  withStaticId(withRefreshEditorWhenFlagsChange(SMSComposerWithAIFeatures), 'requestorId')
);
export default SmsComposerConnected;

function mapStateToProps({ getProvider }) {
  const profile = getProvider('profile').get();

  return {
    phoneNumbers: profile ? profile.getSmsPhoneNumbers().map(number => number.normalized) : [],
  };
}

function useExtraPlugins() {
  const createPasteAttachmentsPlugin = useCreatePasteAttachmentsPlugin();

  return useMemo(() => {
    return [createPasteAttachmentsPlugin()];
  }, [createPasteAttachmentsPlugin]);
}
