import AgentActivity from 'models/agent_activity';
import ClearExternalCustomerSearchResults from 'actions/external_customer_lookup/clear_external_customer_search_results';
import CustomerCombine from 'models/location/customer_combine';
import CustomerView from 'models/location/customer_view';
import CustomerViewUrlConverter from './url_converters/customer_view_url_converter';
import ExternalCustomerLookup from 'models/location/external_customer_lookup';
import FlushCompositions from 'actions/composition/flush_compositions';
import { getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';
import OpenCustomerCombine from 'actions/customer/open_customer_combine';
import OpenExternalCustomerLookup from 'actions/external_customer_lookup/open_external_customer_lookup';
import { registerUrlConverter } from './location_url';
import ServerClock from 'scripts/application/lib/server_clock';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import SwitchCurrentConversation from 'actions/customer/switch_current_conversation';
import SwitchCurrentItem from 'actions/customer/switch_current_item';
import SwitchCustomer from 'actions/customer/switch_customer';
import UnloadCustomer from 'actions/customer/unload_customer';
import UpdateAgentRead from 'actions/conversation/update_agent_read';

registerUrlConverter(CustomerCombine, { getPath: cc => `/customer/${cc.customerId}/combine` });
registerUrlConverter(CustomerView, CustomerViewUrlConverter);
registerUrlConverter(ExternalCustomerLookup, { getPath: cc => `/customer/${cc.customerId}/external-lookup` });

export default function(router, { runnerContext, viewState }) {
  return {
    '/:customerId': {
      on: customerId => {
        viewState.set('search', router.getQueryParameter('search'));
        runnerContext.executeAction(SwitchCustomer, customerId);
        runnerContext.executeAction(SetAndLogActivity, { type: AgentActivity.Type.CUSTOMER });
        router.onBeforeUnload(() => {
          if (!router.getPath().startsWith(`/customer/${customerId}/`)) {
            // When we navigate away from a customer, flush any local compositions out to the backend.
            const compositions = runnerContext.stores.localCompositions.get(customerId);
            if (compositions.length) {
              const currentAgent = runnerContext.stores.currentAgent.get();
              if (currentAgent) {
                runnerContext.executeAction(FlushCompositions, { customerId });
              }
            }
          }
        });
      },

      '/conversation/:conversationId': {
        '/item/:conversationItemId': {
          on: (customerId, conversationId, conversationItemId) => {
            runnerContext.executeAction(SwitchCurrentConversation, {
              conversationId,
              conversationItemId,
            });
            runnerContext.executeAction(SetAndLogActivity, { type: AgentActivity.Type.CUSTOMER });
          },
        },
        '/?$': {
          on: (customerId, conversationId) => {
            const anchorPosition = router.getQueryParameter('anchorPosition');
            runnerContext.executeAction(SwitchCurrentConversation, { anchorPosition, conversationId });
            runnerContext.executeAction(SetAndLogActivity, { type: AgentActivity.Type.CUSTOMER });
          },
        },
      },
      '/item/:itemId': {
        on: (customerId, itemId) => {
          runnerContext.executeAction(SwitchCurrentItem, { itemId });
          runnerContext.executeAction(SetAndLogActivity, { type: AgentActivity.Type.CUSTOMER, itemId });
        },
      },
      '/combine': {
        on: customerId => {
          runnerContext.executeAction(OpenCustomerCombine, customerId);
        },
      },
      '/external-lookup': {
        on: customerId => {
          runnerContext.executeAction(OpenExternalCustomerLookup, customerId);
        },
        after: () => {
          runnerContext.executeAction(ClearExternalCustomerSearchResults);
        },
      },
      after: () => {
        // Check if the customer has changed.
        const customerRouteRe = /\/customer\/([^/^?]+)/;
        const currentCustomerMatch = customerRouteRe.exec(router.beforeUnloadUrl);
        if (!currentCustomerMatch) {
          return;
        }
        const nextCustomerMatch = customerRouteRe.exec(router.getPath());
        const currentCustomerId = currentCustomerMatch && currentCustomerMatch[1];
        const nextCustomerId = nextCustomerMatch && nextCustomerMatch[1];

        const hasPreviousCustomerInStore =
          currentCustomerId && runnerContext.stores.customers.has({ id: currentCustomerId });
        if (hasPreviousCustomerInStore && (!nextCustomerId || currentCustomerId !== nextCustomerId)) {
          const latestConversationId = getLatestConversationId(
            runnerContext.stores.customers.storesFor(currentCustomerId).conversations
          );
          if (latestConversationId) {
            // Important note: If you remove this or change this behavior at all, it might break the inbox read for some reason.
            // I forget exactly the reason but it is very silly.
            runnerContext.executeAction(UpdateAgentRead, {
              agentReadAttrs: { readTo: ServerClock.toISOString() },
              conversationId: latestConversationId,
              customerId: currentCustomerId,
            });
          }
        }

        if (currentCustomerId !== nextCustomerId) {
          // no need to unload customer if route changed but customer didn't
          // e.g. moving to a different conversation
          runnerContext.executeAction(UnloadCustomer, currentCustomerId);
        }
      },
    },
  };
}
