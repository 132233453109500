import AssignConversation from './assign_conversation';
import { getActiveConversationId } from 'actions/conversation/lib/conversation_helpers';

export default class ChangeAssignment {
  constructor(context) {
    this.context = context;
  }

  run({ assignee }) {
    const conversationId = getActiveConversationId(this.context.stores.conversations);
    if (!conversationId) {
      return;
    }
    this.context.executeAction(AssignConversation, { assignee, conversationId });
  }
}
