import createModel, { prop } from 'models/lib/create_model';
import HitTypes from 'models/search/hit_types';

export default createModel({
  modelName: 'ConversationItemSearchHit',

  properties: {
    id: prop(String).isRequired,
    customer: prop({
      id: String,
      email: String,
      name: String,
      phone: String,
    }),
    assignee: prop({
      agent: String,
      group: String,
    }),
    matchingText: String,
    topic: String,
    topics: [String],
    createdAt: String,
    updatedAt: String,
    dueAt: String,
    status: String,
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: HitTypes.CONVERSATION });
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
