import { Factory } from 'rosie';

Factory.define('FbUserProfile');

Factory.define('FbUserProfileWithDefaults')
  .extend('FbUserProfile')
  .attr('firstName', 'Martha')
  .attr('gender', 'female')
  .attr('lastName', 'Williams')
  .attr('profilePic', '/images/demo/martha.jpg')
  .attr('timezone', -8);
