import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';

const DEFAULT_TIMEOUT = 5000; // 5s

export default class CustomReportingGateway {
  constructor(http) {
    this.http = http;
  }

  load(path) {
    this.http.get(`/api/customreporting/${path}`, this.onLoad.bind(this, path), {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  onLoad(path, err, res) {
    if (err != null || res.status !== 200) {
      this.notifyObservers('handleLoadError', { path });
    } else {
      this.notifyObservers('handleLoad', { path, ...res.response });
    }
  }
}

mixin(CustomReportingGateway.prototype, Observable);
