import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import X from 'components/lib/icons/x_icon';

// Documentation: http://go.glad.ly/style/#/Common%20Components/Chip

export default function Chip({ disabled, onDelete, onClick, label, accent, ...props }) {
  let ChipRender = disabled ? ChipDisabled : accent ? ChipAccent : ChipMain;

  return (
    <ChipRender hasDelete={!!onDelete} onClick={!disabled ? onClick : undefined} {...props}>
      <ChipText>{label}</ChipText>
      {onDelete && (
        <IconWrapper
          data-aid="chip-delete-button"
          disabled={disabled}
          hasAccent={!!accent}
          hasClick={!!onClick}
          onClick={!disabled ? onDelete : undefined}
        >
          <CloseIcon disabled={disabled} />
        </IconWrapper>
      )}
    </ChipRender>
  );
}

export const ChipText = styled.div`
  align-items: center;
  align-self: flex-start;
  cursor: ${p => (p.onClick ? 'pointer' : 'default')};
  display: block;
  flex: 1 0 auto;
  font-size: ${p => p.theme.fontSize.base};
  font-weight: ${p => p.theme.fontWeight.medium};
  line-height: ${p => p.theme.lineHeight.controls};
  min-width: 0px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ${p => p.theme.transitions.fast};
  white-space: nowrap;
`;

const chipBase = css`
  align-items: center;
  border: 1px solid;
  box-shadow: ${p => p.theme.boxShadow.xsmall};
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  padding: ${p => (p.hasDelete ? '8px 38px 8px 16px' : '8px 16px')};
  position: relative;
  max-height: 34px;
  max-width: 100%;
`;

export const ChipDisabled = styled.div`
  ${chipBase};
  border-color: ${p => p.theme.colors.gray300};
  background: ${p => p.theme.colors.gray100};
  color: ${p => p.theme.colors.gray300};
  stroke: ${p => p.theme.colors.gray300};
`;

export const ChipMain = styled.div`
  ${chipBase};
  border-color: ${p => p.theme.colors.gray300};
  background: ${p => p.theme.colors.white};
  ${p => p.onClick && ChipMainInteraction};
`;

const ChipMainInteraction = css`
  &:hover {
    background: ${p => p.theme.colors.gray100};
  }
  &:active {
    background: ${p => p.theme.colors.gray600};
    color: ${p => p.theme.colors.white};
  }
`;

export const ChipAccent = styled.div`
  ${chipBase};
  background: ${p => p.theme.colors.purple400};
  border-color: ${p => p.theme.colors.purple400};
  color: ${p => p.theme.colors.white};
  ${p => p.onClick && ChipAccentInteraction};
`;

const ChipAccentInteraction = css`
  &:hover {
    background: ${p => p.theme.colors.purple500};
    border-color: ${p => p.theme.colors.purple500};
  }
  &:active {
    background: ${p => p.theme.colors.purple700};
    border-color: ${p => p.theme.colors.purple700};
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  background: transparent;
  mix-blend-mode: luminosity;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  height: 22px;
  justify-content: center;
  line-height: 0;
  margin: 6px 12px;
  position: absolute;
  right: 0;
  top: 0;
  stroke: ${p => (p.hasAccent ? p.theme.colors.white : p.theme.colors.black)};
  transition: all ${p => p.theme.transitions.fast};
  width: 20px;

  ${ChipMain} > &:hover {
    background: ${p => p.theme.colors.gray300};
  }
  ${ChipMain} > &:active {
    background: ${p => p.theme.colors.gray400};
  }
  /* When the chip is active, make the "X" white because it looks bad black  */
  ${ChipMain}:active > & {
    stroke: ${p => (p.hasClick ? p.theme.colors.white : p.theme.colors.black)};
  }
  ${ChipAccent} > &:hover {
    background: ${p => p.theme.colors.gray800};
  }
  ${ChipAccent} > &:active {
    background: ${p => p.theme.colors.gray900};
  }
  &[disabled] {
    stroke: ${p => p.theme.colors.gray300};
    background: transparent;
    cursor: default;
  }
  &[disabled]:hover {
    stroke: ${p => p.theme.colors.gray300};
    background: transparent;
    cursor: default;
  }
  &[disabled]:active {
    stroke: ${p => p.theme.colors.gray300};
    background: transparent;
    cursor: default;
  }
`;

const CloseIcon = styled(X)`
  width: 12px;
  height: 12px;
`;

Chip.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  /** Determines which side the inline spacing will be applied */
  direction: PropTypes.oneOf(['left', 'right']),
  /** Click handler for entire chip. This gives the chip interactive (hover, active) styles */
  onClick: PropTypes.func,
  /** Text to display within the chip */
  label: PropTypes.string.isRequired,
  /** Function to be called when the remove "X" icon is clicked. The presence of this function will create the "X" icon on the chip */
  onDelete: PropTypes.func,
};

Chip.defaultProps = {
  inline: 'small',
};
