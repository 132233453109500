import _ from 'lodash';

import ActivateUser from 'actions/user/activate_user';
import BeginLogOut from 'actions/user/begin_log_out';
import connect from 'components/lib/connect';
import Err from 'models/err';
import UserActivation from './user_activation';
import UserCredentialType from 'models/user_credential_type';
import ValidateUser from 'actions/user/validate_user';

function mapStateToProps({ getProvider }) {
  let nameErrors = getNameErrors();
  let passwordErrors = getPasswordErrors();
  let currentAgent = getProvider('currentAgent').get();

  return {
    currentAgentId: _.get(currentAgent, 'id', ''),
    isResponsePending:
      getProvider('userCredential').isPending() && passwordErrors.length === 0 && nameErrors.length === 0,
    isUserAuthorized: isUserAuthorized(),
    nameErrors,
    passwordErrors,
  };

  function isUserAuthorized() {
    let errors = getProvider('userCredential').getErrors();
    let isAuthorized = true;
    if (errors) {
      isAuthorized = !errors.some(err => !err.attr && err.code === Err.Code.INVALID.toLowerCase());
    }
    return isAuthorized;
  }

  function getNameErrors() {
    let errors = getProvider('userCredential').getErrors();
    return errors.filter(e => e.attr === 'name').map(e => e.code);
  }

  function getPasswordErrors() {
    let errors = getProvider('userCredential').getErrors();
    return errors.filter(e => e.attr === 'password');
  }
}

function mapExecuteToProps(executeAction) {
  return {
    onActivate: ({ id, name, password }) => executeAction(ActivateUser, { id, name, password }),
    onAttributesChange: ({ name, password }) =>
      executeAction(ValidateUser, { userCredentialType: UserCredentialType.ACTIVATION_TOKEN, name, password }),
    onContinue: () => executeAction(BeginLogOut),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(UserActivation);
