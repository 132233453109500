import _ from 'lodash';
import React, { useContext } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import connect from 'components/lib/connect';
import DropdownMenu, { OpenerButton } from 'components/common/dropdown_menu';
import Endpoint from 'models/endpoint';
import { getDescriptivePhoneNumbers } from 'actions/composition/lib/create_composition';
import { getFormattedPhoneNumbersWithTooltip } from 'components/customer/composition/lib/create_composition_form';
import Tooltip, { HoverWrapper, Hoverer } from 'components/common/tooltip';
import UpdateComposition from 'actions/composer/update_composition';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function FromField({ className, phoneNumbers }) {
  const { composition } = useContext(ComposerContext);
  const currentPhoneNumber = composition.content.from;

  const executeAction = useExecuteAction();
  const onChange = from => executeAction(UpdateComposition, { compositionId: composition.id, attrs: { from } });

  const { fromOptions, tooltipMessage } = getFormattedPhoneNumbersWithTooltip(phoneNumbers, currentPhoneNumber);

  return (
    <From className={className}>
      <Label>From</Label>
      <Tooltip delay={250} message={tooltipMessage}>
        <DropdownMenu
          data-aid="sms-fromField"
          onSelect={onChange}
          options={fromOptions}
          text={_.find(fromOptions, option => option.value === currentPhoneNumber).label}
          value={currentPhoneNumber}
        />
      </Tooltip>
    </From>
  );
}

const From = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 1;
  min-width: 0;

  ${OpenerButton} {
    flex-shrink: 1;
    min-width: 0;
  }
  ${HoverWrapper} {
    display: flex;
    flex-shrink: 1;
    min-width: 0;
  }
  ${Hoverer} {
    display: flex;
    flex-shrink: 1;
    min-width: 0;
  }
`;
const Label = styled.span`
  color: ${p => p.theme.colors.gray500};
  margin-right: 8px;
`;

const FromFieldConnected = connect(mapStateToProps)(FromField);
export default FromFieldConnected;

function mapStateToProps({ getProvider }) {
  return {
    phoneNumbers: getDescriptivePhoneNumbers({ getProvider, endpointType: Endpoint.Type.SMS }),
  };
}
