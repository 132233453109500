export default class CloseMergeProfileModal {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.customerMerge.clearErrors();
    this.context.stores.modal.remove();
  }
}
