import filter from 'lodash/filter';

import React from 'react';

export const fallbackRules = [
  {
    serialize(object, children) {
      if (!object.text) {
        return null;
      }

      const splitNewlineChildren = object.text.split('\n');
      let newChildrenArray = [];

      // Replace newlines with <br>s so they show up properly when rendering rich text.
      splitNewlineChildren.forEach((c, i) => {
        // If a line of text only contains whitespace, replace them all with non-breakable spaces so the line isn't lost
        // when rendered as HTML elsewhere (such as email clients)
        if (c.trim().length === 0) {
          c = c.replace(/\s/, '\u00A0');
        }
        newChildrenArray.push(c);
        if (i !== splitNewlineChildren.length - 1) {
          newChildrenArray.push(<br />);
        } else if (i > 0) {
          // Ensures that if we have a trailing newline (which becomes a <br>), it appears properly.
          // Trailing <br>s without characters proceeding them just.. disappear.
          newChildrenArray.push('\u200b');
        }
      });
      newChildrenArray = filter(newChildrenArray, i => !!i);
      return <React.Fragment>{newChildrenArray}</React.Fragment>;
    },
  },
];
