import IdGenerator from 'scripts/domain/contracts/id_generator';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import qconsole from 'scripts/lib/qconsole';

const DEFAULT_TIMEOUT = 10000; // 10s

export default class CompositionHttpGateway {
  constructor(http, requestorId) {
    this.http = http;
    this.requestorId = requestorId;
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  get version() {
    return '1';
  }

  url(agentId, customerId, compositionId = '') {
    let compositionIdParam = compositionId ? `/${compositionId}` : '';
    return `/api/v${this.version}/orgs/${this.orgId}/agents/${agentId}/customers/${customerId}/compositions${compositionIdParam}`;
  }

  /* Actions */

  add(agentId, customerId, payload) {
    const correlationId = IdGenerator.newId();

    this.http.post(this.url(agentId, customerId), payload, this.handleAddResponse.bind(this, correlationId, agentId), {
      headers: {
        'Gladly-Correlation-Id': correlationId,
        'Gladly-Requestor-Id': this.requestorId,
      },
      timeout: DEFAULT_TIMEOUT,
    });

    return { correlationId };
  }

  remove(agentId, customerId, compositionId) {
    const correlationId = IdGenerator.newId();

    this.http.delete(
      this.url(agentId, customerId, compositionId),
      this.handleDeleteResponse.bind(this, correlationId, agentId, compositionId),
      {
        headers: {
          'Gladly-Correlation-Id': correlationId,
          'Gladly-Requestor-Id': this.requestorId,
        },
        timeout: DEFAULT_TIMEOUT,
      }
    );

    return { correlationId };
  }

  request(agentId, customerId) {
    const correlationId = IdGenerator.newId();

    this.http.get(this.url(agentId, customerId), this.handleFetchResponse.bind(this, customerId), {
      headers: {
        'Gladly-Correlation-Id': correlationId,
        'Gladly-Requestor-Id': this.requestorId,
      },
      timeout: DEFAULT_TIMEOUT,
    });

    return { correlationId };
  }

  update(agentId, customerId, compositionId, compositionAttrs) {
    const correlationId = IdGenerator.newId();

    this.http.put(
      this.url(agentId, customerId, compositionId),
      compositionAttrs,
      this.handleUpdateResponse.bind(this, correlationId, agentId, compositionId),
      {
        headers: {
          'Gladly-Correlation-Id': correlationId,
          'Gladly-Requestor-Id': this.requestorId,
        },
        timeout: DEFAULT_TIMEOUT,
      }
    );

    return { correlationId };
  }

  /* Handlers */

  handleAddResponse(correlationId, agentId, err, res) {
    if (res && res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleAddSuccess', { agentId }, correlationId);
    } else {
      qconsole.error(`error adding customer composition: `, err, res);
      this.notifyObservers('handleAddError', { agentId }, correlationId);
    }
  }

  handleDeleteResponse(correlationId, agentId, compositionId, err, res) {
    if (res && res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleDeleteSuccess', { agentId, compositionId }, correlationId);
    } else {
      qconsole.error(`error deleting customer composition: `, err, res);
      this.notifyObservers('handleDeleteError', { agentId, compositionId }, correlationId);
    }
  }

  handleFetchResponse(customerId, err, res) {
    if (res && res.status === 200) {
      this.notifyObservers('handleFetchCompositions', res.response, customerId);
    } else {
      qconsole.error(`error fetching customer compositions: `, err, res);
      this.notifyObservers('handleFetchError', err || res.response);
    }
  }

  handleUpdateResponse(correlationId, agentId, compositionId, err, res) {
    if (res && res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleUpdateSuccess', { agentId, compositionId }, correlationId);
    } else {
      qconsole.error(`error updating customer composition: `, err);
      this.notifyObservers('handleUpdateErrors', { agentId, compositionId }, err || res.response, correlationId);
    }
  }
}

mixin(CompositionHttpGateway.prototype, Observable);
