import _ from 'lodash';

/**
 * Re-requests custom data based on the parameters of the "envelope". This method is used internally by the actions
 * and should not be invoked directly.
 *
 * @param context
 * @param store - custom data object store
 * @param {String} customerId - Customer ID
 * @param {Object} envelope - Custom data object "envelope" that keeps all the object meta along with the
 * attributes we need in order to re-run the request
 * @param {Boolean} [requestDataRefresh] - Optional boolean flag that indicates that we should request fresh data,
 * which may trigger backend data reloading
 */
export default function reloadSingleDataObject(context, store, customerId, envelope, requestDataRefresh) {
  const entityKey = _.pick(envelope, ['namespace', 'parentEntityId', 'parentEntityType', 'requestorId']);

  if (!store.isLoading(entityKey)) {
    store.clearErrorForLoading(entityKey);
    store.setLoading(entityKey);

    const queryParams = {
      ...envelope.extraParams,
      gladlyEntityId: envelope.parentEntityId,
      gladlyEntityType: envelope.parentEntityType,
      namespace: envelope.namespace,
      requestor: envelope.requestorId,
      context: customerId,
    };
    if (requestDataRefresh) queryParams.refresh = true;

    // Catch promise errors to prevent unhandled rejections. Errors are expected to be handled by the Observer.
    const request = context.gateways.customerExternalDataObjects.request(
      {
        gladlyEntityId: envelope.parentEntityId,
        gladlyEntityType: envelope.parentEntityType,
      },
      queryParams
    );

    return request && request.catch(_.noop);
  }

  return Promise.resolve();
}
