import qconsole from 'scripts/lib/qconsole';

export default class DatabaseProvider {
  constructor(orgId, getDb) {
    this.orgId = orgId;
    this.getDb = getDb;
  }

  get() {
    try {
      return this.getDb(this.orgId);
    } catch (err) {
      qconsole.warn(`No local database exist for organization ${this.orgId}. Simulation disabled.`);
      return {};
    }
  }
}
