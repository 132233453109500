import { useContext } from 'react';

import AITextCompletionContext from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';

export default function useAIComposerOptions(disableSubmit) {
  const { completion, isLoading } = useContext(AITextCompletionContext);

  let shouldAIDisableSubmit = !!completion || isLoading;
  return {
    disableSubmit: disableSubmit || shouldAIDisableSubmit,
    readOnly: !!completion,
  };
}
