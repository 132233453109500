import _ from 'lodash';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import moment from 'moment';

export function mergeClosedConversations(targetCustomer, sourceCustomer) {
  _.forEach(sourceCustomer.conversations, conversation => {
    if (conversation.status === 'CLOSED') {
      let mergedConversation = _.merge({}, conversation, { id: IdGenerator.newId() });
      targetCustomer.conversations.unshift(mergedConversation);
    }
  });

  return _.remove(sourceCustomer.conversations, conv => conv.status === 'CLOSED');
}

export function mergeOpenConversation(targetCustomer, sourceCustomer) {
  let targetOpenConversation = _.find(targetCustomer.conversations, conv => conv.status !== 'CLOSED');
  let sourceOpenConversation = _.find(sourceCustomer.conversations, conv => conv.status !== 'CLOSED');
  if (!sourceOpenConversation) {
    return null;
  } else if (!targetOpenConversation) {
    targetCustomer.conversations.unshift(sourceOpenConversation);
  } else {
    targetOpenConversation.assigneeId = sourceOpenConversation.assigneeId;
  }

  return _.remove(sourceCustomer.conversations, conv => conv.status !== 'CLOSED')[0];
}

export function mergeProfile(targetCustomer, sourceCustomer) {
  _.defaultsDeep(targetCustomer.profile, sourceCustomer.profile);
  delete sourceCustomer.profile;
}

export function mergeConversationHistory(targetCustomer, sourceCustomer) {
  _.forEach(sourceCustomer.conversationHistory, conversationItemDto => {
    var indexToInsertAt = _.sortedIndexBy(targetCustomer.conversationHistory, conversationItemDto, cItem =>
      moment(cItem.timestamp)
    );
    targetCustomer.conversationHistory.splice(indexToInsertAt, 0, conversationItemDto);
  });
}
