import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import PhoneControlsActions from 'models/phone_controls_actions';

export default class PhoneControlsService {
  constructor(database, pubsub, incomingCallService) {
    this._pubsub = pubsub;
    this.getDatabase = database;
    this.incomingCallService = incomingCallService;
  }

  post(payload, callback, path, { orgId, customerId, itemId, command }) {
    this.incomingCallService.updateCallParticipantStatus(orgId, customerId, itemId, {
      ...payload,
      action: this._getAction(command),
    });

    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customer/:customerId/conversation-items/:itemId/phone-controls/:command': {
          POST: this.post,
        },
      },
      this
    );
  }

  _getAction(route) {
    switch (route) {
      case 'add-participant':
        return PhoneControlsActions.ADD;
      case 'blind-transfer':
        return PhoneControlsActions.BLIND_TRANSFER;
      case 'cancel-warm-transfer':
        return PhoneControlsActions.CANCEL_WARM_TRANSFER;
      case 'complete-warm-transfer':
        return PhoneControlsActions.COMPLETE_WARM_TRANSFER;
      case 'end-call':
        return PhoneControlsActions.END_CALL;
      case 'end-conference':
        return PhoneControlsActions.END_CONFERENCE;
      case 'hold-participant':
        return PhoneControlsActions.HOLD;
      case 'initiate-warm-transfer':
        return PhoneControlsActions.INITIATE_WARM_TRANSFER;
      case 'mute-participant':
        return PhoneControlsActions.MUTE;
      case 'pause-recording':
        return PhoneControlsActions.PAUSE_RECORDING;
      case 'call-quality-event':
        return PhoneControlsActions.RECORD_QUALITY_EVENT;
      case 'remove-participant':
        return PhoneControlsActions.REMOVE;
      case 'resume-recording':
        return PhoneControlsActions.RESUME_RECORDING;
      default:
        return '';
    }
  }
}
