import Reports from 'models/location/reports';

export default class NavigateToReportWithFilters {
  constructor(context) {
    this.context = context;
  }

  run(newFilters) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof Reports)) {
      return;
    }
    const newLocation = currentLocation.deriveNew(newFilters);
    this.context.router.navigateTo(newLocation);
  }
}
