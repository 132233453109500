import classnames from 'classnames';
import nearestColor from 'nearest-color';
import React from 'react';
import styled from 'styled-components';

import createEnum from 'scripts/lib/create_enum';
import { getMarginFromIndent } from 'components/customer/composition/lib/slate/slate_text_indentation_menu';
import { getTopListParent } from 'components/text_editor/plugins/lists/utils';
import { isQuoted, QUOTEBLOCK } from 'components/text_editor/plugins/quote_blocks';
import ReadOnlyLink from 'components/text_editor/readonly_link';
import SlateFloatingLinkMenu from 'components/customer/composition/lib/slate/slate_floating_link_menu';

export default function RichTextPlugin({ onChange, readOnly } = { readOnly: false }) {
  return {
    commands: { setAlignment, toggleLink, increaseIndentation, decreaseIndentation },

    onKeyDown: (evt, editor, next) => {
      if (!evt.ctrlKey && !evt.metaKey) {
        return next();
      }

      switch (evt.key) {
        case 'b': {
          evt.preventDefault();
          editor.toggleMark('bold');
          break;
        }
        case 'i': {
          evt.preventDefault();
          editor.toggleMark('italic');
          break;
        }
        case 'u': {
          evt.preventDefault();
          editor.toggleMark('underline');
          break;
        }
        case 'k': {
          evt.preventDefault();
          editor.toggleLink();
          break;
        }
        default: {
          return next();
        }
      }
    },

    renderEditor(props, editor, next) {
      const children = next();

      const { value } = editor;

      let link = value && value.inlines.find(inline => inline.type === 'link');
      if (!link || readOnly) {
        return children;
      }

      return (
        <React.Fragment>
          {children}
          <SlateFloatingLinkMenu editor={editor} id={link.key} node={link} onChange={onChange} />
        </React.Fragment>
      );
    },

    renderMark: (props, editor, next) => {
      const { attributes, children, mark } = props;
      switch (mark.type) {
        case 'bold':
          return <strong {...attributes}>{children}</strong>;
        case 'italic':
          return <i {...attributes}>{children}</i>;
        case 'underline':
          return <u {...attributes}>{children}</u>;
        case 'font': {
          const fontSize = mark.get('data').get('fontSize');
          const fontColor = mark.get('data').get('fontColor');
          return (
            <font color={fontColor} size={fontSize} {...attributes}>
              {children}
            </font>
          );
        }
        default:
          return next();
      }
    },

    renderNode(props, editor, next) {
      const { attributes, children, node } = props;
      switch (node.type) {
        case 'link': {
          const href = node.get('data').get('href');
          const key = node.key;
          const className = classnames('slateEditor-link', `slateEditor-link-${key}`);

          if (readOnly) {
            return (
              <ReadOnlyLink attributes={attributes} className={className} href={href}>
                {children}
              </ReadOnlyLink>
            );
          }

          return (
            <StyledLink className={className} data-aid="slate-editable-link" {...attributes} href={href}>
              {children}
            </StyledLink>
          );
        }
        case 'paragraph': {
          const textAlign = node.get('data').get('textAlign');
          const indent = node.get('data').get('indent');
          const marginLeft = getMarginFromIndent(indent);
          return (
            <div {...attributes} style={{ textAlign, marginLeft }}>
              {children}
            </div>
          );
        }
        default:
          return next();
      }
    },

    schema: {
      inlines: {
        link: {
          nodes: [
            {
              match: { object: 'text' },
              min: 1,
              max: 1,
            },
          ],
          text: /.+/, // Can't have empty links
        },
      },
    },
  };
}

function toggleLink(editor) {
  return editor.wrapInline({ type: 'link' }).blur();
}

function setAlignment(editor, textAlign) {
  if (textAlign === 'left') {
    textAlign = undefined;
  }
  return editor.setBlocks({ data: { textAlign } });
}

function increaseIndentation(editor) {
  const value = editor.value;
  let indentAppliedToList = false;
  return value.blocks.forEach(block => {
    const parent = value.document.getParent(block.key);
    if (parent === value.document && block.type === 'paragraph') {
      addIndentToNode(editor, block);
    } else if (isQuoted(value)) {
      let quoteBlock = value.document.getClosest(block.key, parentNode => parentNode.type === QUOTEBLOCK);
      addIndentToNode(editor, quoteBlock);
    } else if (block.type === 'list_item' && !indentAppliedToList) {
      const topListParent = getTopListParent(editor, block);
      if (topListParent.nodes.first() === block) {
        // if it's the first bullet in a list, we want to move the whole list over
        addIndentToNode(editor, topListParent);
      } else {
        // if it's any bullet other than the first one, we want to increase depth of that bullet
        editor.increaseListDepth();
      }
      indentAppliedToList = true; // We only need to apply the indentation once for the entire list
    }
  });
}

function addIndentToNode(editor, node) {
  let currentData = node.get('data').toJSON();
  if (currentData.indent) {
    currentData.indent++;
  } else {
    currentData.indent = 1;
  }
  editor.setNodeByKey(node.key, { data: currentData });
}

function decreaseIndentation(editor) {
  const value = editor.value;
  let indentAppliedToList = false;
  return value.blocks.forEach(block => {
    const parent = value.document.getParent(block.key);
    if (parent === value.document && block.type === 'paragraph') {
      decreaseIndentOnNode(editor, block);
    } else if (isQuoted(value)) {
      let quoteBlock = value.document.getClosest(block.key, parentNode => parentNode.type === QUOTEBLOCK);
      decreaseIndentOnNode(editor, quoteBlock);
    } else if (block.type === 'list_item' && !indentAppliedToList) {
      const topListParent = getTopListParent(editor, block);
      if (topListParent.nodes.first() === block) {
        // if it's the first bullet in a list, we want to move the whole list over
        decreaseIndentOnNode(editor, topListParent);
      } else {
        // if it's any bullet other than the first one, we want to decrease depth of that bullet
        editor.decreaseListDepth();
      }
      indentAppliedToList = true; // We only need to apply the indentation once for the entire list
    }
  });
}

function decreaseIndentOnNode(editor, node) {
  let currentData = node.get('data').toJSON();
  if (currentData.indent === 1) {
    currentData.indent = undefined;
  }
  if (currentData.indent) {
    currentData.indent--;
  }
  return editor.setNodeByKey(node.key, { data: currentData });
}

export const FONT_COLORS = {
  blue: '#2650b2',
  green: '#009b00',
  orange: '#ff7d00',
  red: '#d2210b',
  gray: '#999999',
  black: '#000000',
};

export function getNearestColor(hexColor) {
  const nearest = nearestColor.from(FONT_COLORS);
  return nearest(hexColor).value;
}

export const FONT_SIZES = createEnum('SMALL', 'NORMAL', 'LARGE', 'HUGE');

export const StyledLink = styled.a`
  color: ${p => p.theme.colors.green400};
  text-decoration: underline;
  &:hover {
    color: ${p => p.theme.colors.green400};
    text-decoration: underline;
  }
`;
