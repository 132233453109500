import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('SessionEnded').attr('type', 'SESSION_ENDED');

Factory.define('SessionEndedWithDefaults')
  .extend('SessionEnded')
  .attr('sessionId', 'session-id')
  .attr('reason', 'CONVERSATION_CLOSED')
  .attr('sessionType', 'SMS');

Factory.define('SessionEndedWithChat')
  .extend('SessionEndedWithDefaults')
  .attr('sessionType', 'CHAT');

Factory.define('SessionEndedWithSMS')
  .extend('SessionEndedWithDefaults')
  .attr('sessionType', 'SMS');

Factory.define('SessionEndedItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('SessionEnded', content));

Factory.define('SessionEndedItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('SessionEndedWithDefaults', content));

Factory.define('SessionEndedItemWithChat')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('SessionEndedWithChat', content));

Factory.define('SessionEndedItemWithSMS')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('SessionEndedWithSMS', content));
