import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import { iconClassNameForItem } from '../metadata/metadata_icon_classes';

export function CustomerAssigneeChangeItem({ initiatorName, item, newCustomerAssignee, previousCustomerAssignee }) {
  let operation, assigneeNameWithType;

  if (_.isEmpty(previousCustomerAssignee)) {
    operation = 'made';
    assigneeNameWithType = getAssigneeAndType({ customerAssignee: newCustomerAssignee, prefix: '' });
  }

  if (_.isEmpty(newCustomerAssignee)) {
    operation = 'removed';
    assigneeNameWithType = getAssigneeAndType({ customerAssignee: previousCustomerAssignee, prefix: 'as ' });
  }

  if (!_.isEmpty(newCustomerAssignee) && !_.isEmpty(previousCustomerAssignee)) {
    operation = 'reassigned';
    assigneeNameWithType = getAssigneeAndType({ customerAssignee: newCustomerAssignee, prefix: 'as ' });
  }

  const metadataText = (
    <span>
      <b>{initiatorName}</b> {operation} <b>{assigneeNameWithType.name}</b> {assigneeNameWithType.type}
    </span>
  );

  return (
    <ItemContent isAuditItem item={item}>
      <Metadata iconClass={iconClassNameForItem(item)} text={metadataText} timestamp={item.timestamp} />
    </ItemContent>
  );

  function getAssigneeAndType({ customerAssignee, prefix }) {
    if (customerAssignee.agentName) {
      return {
        name: `${customerAssignee.agentName} in ${customerAssignee.routingGroupName}`,
        type: `${prefix}the Dedicated Hero`,
      };
    }

    return {
      name: `${customerAssignee.routingGroupName}`,
      type: `${prefix}the Hero Inbox`,
    };
  }
}

function mapStateToProps({ getProvider }, { item }) {
  let agentProvider = getProvider('agents');
  let currentAgentProvider = getProvider('currentAgent');
  let routingGroupProvider = getProvider('routingGroups');

  let currentAgent = currentAgentProvider.get();
  let customerAssigneeChangeContent = item.content;

  function getInitiatorName() {
    if (item.initiator.type === Communicator.AUTOMATED) {
      return 'System';
    }

    if (_.get(currentAgent, 'id') === item.initiator.id) {
      return 'You';
    }

    let agent = agentProvider.findBy({ id: item.initiator.id });
    return _.get(agent, 'name');
  }

  function getNamedCustomerAssignee(assigneeType) {
    let customerAssignee = _.get(customerAssigneeChangeContent, assigneeType, {});
    let namedCustomerAssignee = {};
    if (customerAssignee.agentId) {
      namedCustomerAssignee.agentName = _.get(agentProvider.findBy({ id: customerAssignee.agentId }), 'name');
      if (currentAgent.id === customerAssignee.agentId) {
        namedCustomerAssignee.agentName += ' (You)';
      }
    }
    if (customerAssignee.routingGroupId) {
      namedCustomerAssignee.routingGroupName = _.get(
        routingGroupProvider.findBy({ id: customerAssignee.routingGroupId }),
        'name'
      );
    }
    return namedCustomerAssignee;
  }

  return {
    initiatorName: getInitiatorName(),
    item,
    newCustomerAssignee: getNamedCustomerAssignee('newAssignee'),
    previousCustomerAssignee: getNamedCustomerAssignee('previousAssignee'),
  };
}

const CustomerAssigneeChangeItemContainer = connect(mapStateToProps)(CustomerAssigneeChangeItem);

CustomerAssigneeChangeItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

CustomerAssigneeChangeItem.propTypes = {
  initiatorName: PropTypes.string,
  newCustomerAssignee: PropTypes.shape({
    agentName: PropTypes.string,
    routingGroupName: PropTypes.string,
  }),
  previousCustomerAssignee: PropTypes.shape({
    agentName: PropTypes.string,
    routingGroupName: PropTypes.string,
  }),
  ...CustomerAssigneeChangeItemContainer.propTypes,
};

export default CustomerAssigneeChangeItemContainer;
