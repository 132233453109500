import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';
import { RoutingChannel } from 'models/agent_routing_preferences';
import ServerClock from 'scripts/application/lib/server_clock';

export const AgentAvailability = createEnum('AWAY', 'BUSY', 'OFFLINE', 'READY');

export const AvailabilityState = createModel({
  modelName: 'AvailabilityState',
  properties: {
    available: prop(Boolean).default(false),
    changedAt: prop(String).default(() => ServerClock.toISOString()),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const ChannelAvailability = createModel({
  modelName: 'ChannelAvailability',
  properties: {
    [RoutingChannel.MAIL]: prop(AvailabilityState).default(() => AvailabilityState.create()),
    [RoutingChannel.MESSAGING]: prop(AvailabilityState).default(() => AvailabilityState.create()),
    [RoutingChannel.TASK]: prop(AvailabilityState).default(() => AvailabilityState.create()),
    [RoutingChannel.VOICE]: prop(AvailabilityState).default(() => AvailabilityState.create()),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const AwayStatusValue = createModel({
  modelName: 'AwayStatusValue',

  properties: {
    availability: prop(String).default(AgentAvailability.AWAY),
    reasonId: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },

  setReason(reasonId) {
    this.reasonId = reasonId;
  },
});

export const BusyStatusValue = createModel({
  modelName: 'BusyStatusValue',

  properties: {
    availability: prop(String).default(AgentAvailability.BUSY),
    activeReasonId: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },

  setActiveReason(reasonId) {
    this.activeReasonId = reasonId;
  },
});

export const OfflineStatusValue = createModel({
  modelName: 'OfflineStatusValue',

  properties: {
    availability: prop(String).default(AgentAvailability.OFFLINE),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const ReadyStatusValue = createModel({
  modelName: 'ReadyStatusValue',

  properties: {
    availability: prop(String).default(AgentAvailability.READY),
    activeReasonId: prop(String),
    channels: prop(ChannelAvailability).default(() => new ChannelAvailability()),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },

  getReadyChannels() {
    return _.chain(this.channels)
      .map((availability, channel) => availability.available && channel)
      .filter()
      .value();
  },

  setActiveReason(reasonId) {
    this.activeReasonId = reasonId;
  },
});

export const AgentStatus = createModel({
  modelName: 'AgentStatus',

  properties: {
    id: prop(String).isRequired,
    availabilityUpdatedAt: prop(String).default(() => ServerClock.toISOString()),
    status: prop()
      .oneOf(AwayStatusValue, BusyStatusValue, OfflineStatusValue, ReadyStatusValue)
      .default(() => OfflineStatusValue.create())
      .fromJs(attrs => statusValueFromJs(attrs.availability, attrs)),
    updatedAt: prop(String).default(() => ServerClock.toISOString()),
    _version: prop(Number).default(0),
  },

  getActiveReasonId() {
    return this.status.activeReasonId;
  },

  getAvailability() {
    return this.status.availability;
  },

  getReadyChannels() {
    if (this.status.availability === AgentAvailability.READY) {
      return this.status.getReadyChannels();
    }
    return [];
  },

  incrementVersion() {
    this._version = this._version + 1;
  },

  setBusy() {
    this._updateAvailabilityIfNeeded(AgentAvailability.BUSY);
  },

  setAway(reasonId) {
    this._updateAvailabilityIfNeeded(AgentAvailability.AWAY);
    this.status.setReason(reasonId);
  },

  setOffline() {
    this._updateAvailabilityIfNeeded(AgentAvailability.OFFLINE);
  },

  updateActiveReason(reasonId) {
    this.status.setActiveReason(reasonId);
  },

  _updateAvailabilityIfNeeded(availability) {
    if (this.status.availability !== availability) {
      this.status = statusValueFromJs(availability);
      this.availabilityUpdatedAt = ServerClock.toISOString();
    }
    this.updatedAt = ServerClock.toISOString();
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

function statusValueFromJs(availability, attrs) {
  switch (availability) {
    case AgentAvailability.READY:
      return ReadyStatusValue.fromJs(attrs);
    case AgentAvailability.BUSY:
      return BusyStatusValue.fromJs(attrs);
    case AgentAvailability.AWAY:
      return AwayStatusValue.fromJs(attrs);
    case AgentAvailability.OFFLINE:
      return OfflineStatusValue.fromJs(attrs);
    default:
      throw new Error(`unknown agent status for availability [${availability}]`);
  }
}

export default AgentStatus;
