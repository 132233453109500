import React from 'react';
import styled from 'styled-components';

import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ActiveCallControlsContainer from 'components/phone_controls/active_call_controls_container';
import ActiveCallElapsedTime from 'components/phone_controls/active_call_elapsed_time';
import AgentInfoBar from 'components/page_layout/agent_info_bar';
import AgentChannelStatusControls from 'components/page_layout/agent_channel_status_controls';
import AgentNotificationsButton from 'components/agent_notifications/agent_notifications_menu';
import Link from 'components/common/link';
import connect from 'components/lib/connect';
import NavigateHome from 'actions/current_location/navigate_home';
import NavigateTo from 'actions/current_location/navigate_to';
import NextCallRoutingTimer from 'components/page_layout/agent_status/next_call_routing_timer';
import Search from 'models/location/search';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function TopBarContent({ canPerformAgentActions, isAgentLiveOnCall }) {
  if (!canPerformAgentActions) {
    return <LimitedContent />;
  } else if (isAgentLiveOnCall) {
    return <LiveCallContent />;
  }
  return <DefaultContent />;
}

export function LimitedContent() {
  return (
    <ContentContainer>
      <Logo />
      <Option>
        <AgentInfoBar />
      </Option>
    </ContentContainer>
  );
}

export function LiveCallContent() {
  return (
    <CallContentContainer>
      <ActiveCallControlsContainer />
      <Option>
        <SearchButton />
      </Option>
      <Option>
        <Divider />
      </Option>
      <ElapsedTime />
    </CallContentContainer>
  );
}

export function DefaultContent() {
  return (
    <ContentContainer>
      <Logo />
      <Option>
        <NextCallRoutingTimer />
      </Option>
      <Option>
        <AgentChannelStatusControls />
      </Option>
      <Option>
        <Divider />
      </Option>
      <Option>
        <SearchButton />
      </Option>
      <Option>
        <AgentNotificationsButton />
      </Option>
      <Option>
        <AgentInfoBar />
      </Option>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
  margin: 0;
  margin-right: 16px;
`;

const CallContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
  left: 240px;
  padding-left: 0;
  position: absolute;
  right: 0;
  margin: 0;
  margin-right: 16px;
`;

function ElapsedTime() {
  return (
    <Option>
      <ActiveCallElapsedTime />
    </Option>
  );
}

const Option = styled.div`
  align-items: center;
  display: flex;
  margin: 0 8px;
  padding: 0;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }
`;

function Logo() {
  const executeAction = useExecuteAction();
  const navigateHome = ev => {
    ev.preventDefault();
    executeAction(NavigateHome);
  };

  return (
    <LogoOption>
      <LogoLink href="/home" onClick={navigateHome}>
        <div className="page-logo" data-aid="pageLogo" />
      </LogoLink>
    </LogoOption>
  );
}

const LogoLink = styled(Link).attrs({ className: 'agentInfoBar-home-logo' })``;
const LogoOption = styled(Option)`
  position: fixed;
  right: 50%;
  transform: translateX(50%);
`;

const Divider = styled.div`
  border-right: 1px solid ${p => p.theme.colors.gray500};
  height: 60%;
`;

function SearchButton() {
  const executeAction = useExecuteAction();
  const navigateToSearch = ev => {
    ev.preventDefault();
    executeAction(NavigateTo, Search.create());
  };

  return (
    <SearchLink data-aid="topBar-searchButton" href={getLocationUrl(Search.create())} onClick={navigateToSearch}>
      <FontAwesomeIcon icon={faSearch} />
    </SearchLink>
  );
}

const SearchLink = styled(Link)`
  color: ${p => p.theme.colors.gray800};
  &:hover {
    color: ${p => p.theme.colors.green400};
  }
  font-size: 20px;
`;

const TopBarContentContainer = connect(mapStateToProps)(TopBarContent);

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const activeCall = getProvider('activeCall').get();
  const currentAgent = getProvider('currentAgent').get();

  return {
    canPerformAgentActions: isFeatureEnabled('internalAgentActions'),
    isAgentLiveOnCall: activeCall && activeCall.isAgentOnLiveCall(currentAgent.id) && activeCall.hasLiveConnection(),
  };
}

export default TopBarContentContainer;
