import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from '../lib/create_model';
import AttributeDef from './attribute_def';

const TransactionDefType = createEnum('FLIGHT', 'GENERIC', 'STAY', 'ORDER', 'SUBSCRIPTION');

const GenericTransactionDef = createModel({
  modelName: 'GenericTransactionDef',

  properties: {
    type: prop(String).default(TransactionDefType.GENERIC),
    attributes: [AttributeDef],
  },
});

const FlightTransactionDef = createModel({
  modelName: 'FlightTransactionDef',

  properties: {
    type: prop(String).default(TransactionDefType.FLIGHT),
    attributes: [AttributeDef], // Optional attributes to be displayed generically
    statusCodeMap: Object, // Map from label to FlightStatusColor
    urlAttribute: String,
  },
});

const OrderTransactionDef = createModel({
  modelName: 'OrderTransactionDef',

  properties: {
    type: prop(String).default(TransactionDefType.ORDER),
    attributes: [AttributeDef],
  },
});

const StayTransactionDef = createModel({
  modelName: 'StayTransactionDef',

  properties: {
    type: prop(String).default(TransactionDefType.STAY),
    attributes: [AttributeDef],
  },
});

const SubscriptionTransactionDef = createModel({
  modelName: 'SubscriptionTransactionDef',

  properties: {
    type: prop(String).default(TransactionDefType.SUBSCRIPTION),
    attributes: [AttributeDef],
  },
});

const TransactionDefClasses = Object.freeze([
  [TransactionDefType.FLIGHT, FlightTransactionDef],
  [TransactionDefType.GENERIC, GenericTransactionDef],
  [TransactionDefType.ORDER, OrderTransactionDef],
  [TransactionDefType.STAY, StayTransactionDef],
  [TransactionDefType.SUBSCRIPTION, SubscriptionTransactionDef],
]);

const FlightStatusColor = createEnum('GREEN', 'YELLOW', 'RED');

export {
  GenericTransactionDef,
  FlightTransactionDef,
  FlightStatusColor,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
  TransactionDefClasses,
  TransactionDefType,
};
