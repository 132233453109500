import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import styled from 'styled-components';

import CircleIcon from './icons/circle_icon';
import ParticipantsFillIcon from 'components/common/icons/fill/participants-fill';

export default class ParticipantsButton extends React.Component {
  constructor(props) {
    super(props);
    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  }
  render() {
    return (
      <ParticipantsContainer>
        <CircleIcon viewBox="0 0 25 25" {...this.props}>
          <StyledParticipantsButton />
        </CircleIcon>
        <ParticipantsNumber>{this.props.numOfParticipants}</ParticipantsNumber>
      </ParticipantsContainer>
    );
  }
}

const ParticipantsContainer = styled.div`
  height: 30px;
  width: 30px;
`;

const StyledParticipantsButton = styled(ParticipantsFillIcon)`
  path {
    transform: scale(0.6);
    transform-origin: center;
  }
`;

const ParticipantsNumber = styled.div`
  background: #4d4e4e;
  border-radius: 8px;
  border: 1px solid white;
  bottom: 19px;
  color: white;
  font-size: 8px;
  font-weight: 700;
  height: 16px;
  left: 17px;
  line-height: 14px;
  min-width: 16px;
  position: relative;
  text-align: center;
  width: 16px;
  z-index: 3;
`;
