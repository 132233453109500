import React from 'react';
import Icon from 'components/lib/icons/icon';

export default function ShippingBoxIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m7.038 7.451.182.08.188-.068L15.5 4.54v7.867L7.255 15.21.5 12.43V4.592l6.538 2.86ZM7.238 6.98v8.565"
          stroke="#B3B3B3"
        />
        <path
          d="m4.116 5.63.054.115 6.035-2.294-6.089 2.18ZM4.068 6.244l-.143-.063v3.698l.143.054v-3.69Z"
          fill="#000"
          stroke="#B3B3B3"
        />
        <path
          clipRule="evenodd"
          d="M1 11.143V4.348L8.728 1.72 15 4.3v7.829l1 .438V3.63L8.762.652 0 3.632v8.936l1-.361v-1.064Z"
          fill="#B3B3B3"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  );
}
