import UploadResult from 'models/upload_result';

export default class UserUploadGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleUploadAbort(path) {
    let usersUpload = this.context.stores.usersUpload.get();
    if (!usersUpload) return; // all uploads are already removed
  }

  handleUploadError(path, error) {
    this._applyToUpload(path, upload => upload.fail(error));
  }

  handleUploadProgress(path, progress) {
    this._applyToUpload(path, upload => upload.setProgress(progress));
  }

  handleUploadSuccess(path, response) {
    this._applyToUpload(path, upload => upload.complete());

    let responseJson = JSON.parse(response);
    let usersUpload = this.context.stores.usersUpload.get();
    usersUpload.setResult(UploadResult.fromJs(responseJson));
    this.context.stores.usersUpload.set(usersUpload);
  }

  _applyToUpload(path, getUpdatedUpload) {
    let usersUpload = this.context.stores.usersUpload.get();
    if (!usersUpload) {
      return null;
    }

    let upload = usersUpload.file;
    if (!upload) {
      return null;
    }

    let updatedUpload = getUpdatedUpload(upload);
    usersUpload.update(updatedUpload);
    this.context.stores.usersUpload.set(usersUpload);
    this.context.stores.usersUpload.clearErrors();
    return updatedUpload;
  }
}
