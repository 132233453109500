import createEnum from 'scripts/lib/create_enum';

const ReportChannel = createEnum(
  'ALL',
  'CHAT_SESSION',
  'EMAIL',
  'FB_MESSENGER',
  'INSTAGRAM_DIRECT',
  'PHONE_CALL',
  'SMS',
  'TWITTER',
  'WHATSAPP'
);
export default ReportChannel;

// Translate values/tokens used as channels in the UI to those
// recognized as channel filters by the reports API endpoint.
export function getChannelFilterValue(channel) {
  switch (channel) {
    case ReportChannel.ALL:
      return 'allChannels';
    case ReportChannel.CHAT_SESSION:
      return 'chat';
    case ReportChannel.EMAIL:
      return 'email';
    case ReportChannel.FB_MESSENGER:
      return 'fbmessenger';
    case ReportChannel.INSTAGRAM_DIRECT:
      return 'instagramDirect';
    case ReportChannel.PHONE_CALL:
      return 'voice';
    case ReportChannel.SMS:
      return 'sms';
    case ReportChannel.TWITTER:
      return 'twitter';
    case ReportChannel.WHATSAPP:
      return 'whatsapp';
    default:
      return undefined;
  }
}
