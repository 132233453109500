import _ from 'lodash';
import qconsole from 'scripts/lib/qconsole';

import {
  addPendingRequestIdForConversation,
  clearPendingRequest,
} from 'actions/composition/lib/update_conversation_workflow';
import Attachment from 'models/attachment';
import ConversationMessage from 'scripts/domain/models/conversation_message';
import ConversationMessageAttachment from 'models/conversation_message_attachment';
import createConversationMessage from 'models/create_conversation_message';
import CreateInstagramComposition from './create_instagram_composition';
import CreateTweetComposition from 'actions/composition/create_tweet_composition';
import CreateWhatsappComposition from 'actions/composition/create_whatsapp_composition';
import Err from 'models/err';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import { isEmpty } from 'models/composition/composition_content_shared';
import InteractionType from 'models/composition/interaction_type';
import { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';
import htmlToText from 'models/conversation_item/html_to_text';
import Upload from 'models/upload';
import SubmitCompositionMultipleItems from 'actions/composition/lib/submit_composition_multiple_items';

export default class SendConversationMessage extends SubmitCompositionMultipleItems {
  sendMessage(conversationUpdates, conversationMessageItem) {
    // mark the request as pending in the conversation workflow
    const requestId = IdGenerator.newId();
    addPendingRequestIdForConversation(this.context, conversationMessageItem.conversationId, requestId);

    this.context.gateways.conversationMessage
      .add({
        id: conversationMessageItem.id,
        customerId: this.currentCustomerId,
        conversation: conversationUpdates,
        conversationItem: getOutgoingItemDto(conversationMessageItem),
      })
      .finally(() => {
        clearPendingRequest(this.context, requestId);
      });
  }

  processComposition(composition, content) {
    if (this.isNewConversationPending() || this.willShowReopenConversationModal()) {
      this.saveComposedContent(composition, content);
      return null;
    }

    const { compositionErrors, conversationItems } = this.createOutgoingItems(composition, content);
    if (compositionErrors.length > 0) {
      this.compositions.setErrors(composition.id, compositionErrors);
      this.saveComposedContent(composition, content);
      return null;
    }
    this.clearComposition(composition);

    return conversationItems;
  }

  createOutgoingItems(composition, compositionContent) {
    const plainBody = htmlToText(compositionContent.bodyHtml);
    if (isEmpty(plainBody) && composition.attachments.length === 0) {
      return compErrors({
        attr: 'bodyHtml',
        code: Err.Code.BLANK,
        detail: `Message cannot be empty`,
      });
    }

    if (plainBody.length > this.getMaxCharCountByChannel(compositionContent.channel)) {
      return compErrors({
        attr: 'bodyHtml',
        code: Err.Code.TOO_LONG,
        detail: `Your message can contain a maximum of ${this.getMaxCharCountByChannel(
          compositionContent.channel
        )} characters`,
      });
    }

    const compositionErrors = [].concat(
      composition.validateAttachmentStatus(),
      composition.validateTotalAttachmentSize(),
      composition.validateAttachmentCount()
    );
    if (compositionErrors.length) {
      return { compositionErrors };
    }

    let conversationItems = [];
    if (_.trim(plainBody)) {
      conversationItems.push(
        createConversationMessage(
          this.currentAgent,
          [],
          compositionContent,
          composition.conversationId,
          composition.customerId,
          composition.snippetIds,
          composition.relatedSnippetIds
        )
      );
    }

    let attachments = this.createConversationMessageAttachments(composition);
    if (attachments.length > 0) {
      compositionContent.bodyHtml = '';
    }

    conversationItems = conversationItems.concat(
      attachments.map(attachment =>
        createConversationMessage(
          this.currentAgent,
          [attachment],
          compositionContent,
          composition.conversationId,
          composition.customerId
        )
      )
    );

    return { compositionErrors: [], conversationItems };
  }

  getMaxCharCountByChannel(channel) {
    switch (channel) {
      case InteractionType.INSTAGRAM_DIRECT:
        return ConversationMessage.MAX_INSTAGRAM_MESSAGE_SIZE;
      case InteractionType.WHATSAPP:
        return ConversationMessage.MAX_WHATSAPP_MESSAGE_SIZE;

      default:
        return ConversationMessage.MAX_CONVERSATION_MESSAGE_SIZE;
    }
  }

  processConversationItems(composition, conversationItems, conversationId) {
    if (_.isEmpty(conversationItems)) {
      return;
    }
    conversationItems.forEach(item => {
      this.addItemToHistory(item);
      const conversationUpdates = this.addItemToConversation(item);
      this.sendMessage(conversationUpdates, item);
    });

    const [lastItem] = conversationItems.slice(-1);
    this.markConversationRead(this.activeConversationId || conversationId, lastItem.timestamp);
    this.scrollToItem(this.activeConversationId || conversationId);
    this.updateAgentInbox(lastItem);
  }

  createConversationMessageAttachments(composition) {
    return composition.attachments.map(attachment => asConversationMessageAttachment(composition, attachment));
  }

  getConversationUpdates(conversation) {
    const updates = super.getConversationUpdates(conversation);
    return conversation.getUpdatedAttributesForRealtimeCommunication(this.currentAgent.id, updates);
  }

  afterSend(itemContent) {
    // create a new conversation message to continue talking with the customer
    switch (itemContent.channel) {
      case InteractionType.TWITTER:
        this.context.executeAction(CreateTweetComposition, { conversationId: this.activeConversation.id });
        break;
      case InteractionType.WHATSAPP:
        this.context.executeAction(CreateWhatsappComposition, { conversationId: this.activeConversation.id });
        break;
      case InteractionType.INSTAGRAM_DIRECT:
        this.context.executeAction(CreateInstagramComposition, { conversationId: this.activeConversation.id });
        break;
      default:
        qconsole.error('invalid channel interaction type', { interactionType: itemContent.channel });
    }
  }
}

function asConversationMessageAttachment(composition, attachment) {
  let fileDescriptor = attachment.fileDescriptor();
  let source = attachment instanceof Upload ? createUploadSource(composition, attachment.id) : attachment.source;

  return ConversationMessageAttachment.create({
    id: attachment.id,
    contentType: fileDescriptor.contentType,
    filename: fileDescriptor.filename,
    fileSize: fileDescriptor.contentLength,
    source,
  });
}

function createUploadSource(composition, attachmentId) {
  return {
    type: Attachment.SourceType.UPLOAD,
    path: composition.getUploadPath(attachmentId),
  };
}

function compErrors({ attr, code, detail }) {
  return {
    compositionErrors: [
      new Err({
        attr,
        code,
        detail,
      }),
    ],
  };
}
