import moment from 'moment';

import Assignee from 'models/assignee';
import CreateComposition from './lib/create_composition';
import TaskCompositionContent from 'models/composition/task_composition_content';

class CreateTaskComposition extends CreateComposition {
  constructor(context) {
    super(context, TaskCompositionContent);
  }

  _createContent(attrs) {
    // Initialize task dueAt to nearest 30 minute increment
    let defaultDate = moment();
    const remainder = 30 - (defaultDate.minute() % 30);
    defaultDate = defaultDate.add(remainder, 'minutes');
    const dueAt = defaultDate.toISOString();

    const lastConversation = this.getLatestConversation();

    let defaultAssignee = new Assignee({
      agentId: lastConversation.assignee.agentId,
      routingGroupId: lastConversation.assignee.routingGroupId,
    });

    return new TaskCompositionContent({
      assignee: defaultAssignee,
      dueAt,
    });
  }
}

export default CreateTaskComposition;
