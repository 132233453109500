import CustomerView from 'models/location/customer_view';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class UpdateCurrentComposition {
  constructor(context) {
    this.context = context;
  }

  run(conversationId) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error(
        `cannot update current composition when current location is [${currentLocation &&
          currentLocation.constructor.name}]`
      );
    }
    let currentCompositionId = currentLocation.currentCompositionId;

    if (currentCompositionId) {
      const compositionsStore = getCompositionsStore(this.context);
      let composition = compositionsStore.findBy({ id: currentCompositionId });
      composition.replaceConversationId(conversationId);
      compositionsStore.replace(composition);
    }
  }
}
