import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import ExternalCustomerLookup from './external_customer_lookup/external_customer_lookup';
import PageLayout from './page_layout';

export function ExternalCustomerLookupPage({ customerId }) {
  return (
    <CustomerLookupPageLayout id="external-customer-lookup-page">
      <ExternalCustomerLookup customerId={customerId} />
    </CustomerLookupPageLayout>
  );
}

ExternalCustomerLookupPage.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(ExternalCustomerLookupPage);

function mapStateToProps({ getProvider }) {
  return {
    customerId: getProvider('currentLocation').get().customerId,
  };
}

const CustomerLookupPageLayout = styled(PageLayout)`
  overflow: auto;
  width: 100%;
`;
