import addTaskToInbox from 'actions/inbox/lib/add_task_to_inbox_deprecated';
import removeItemFromInbox from 'actions/inbox/lib/remove_item_from_inbox';

export default class UpdateTaskAssignee {
  constructor(context) {
    this.context = context;
  }

  run({ assignee, conversationItemId, location }) {
    let store = this.context.stores.conversationHistory;
    let taskItem = store.find(conversationItemId);
    taskItem.content.assign(assignee);
    store.setPending(taskItem);

    let conversationItemUpdate = {
      content: {
        assignee: taskItem.content.assignee.toJs(),
      },
    };

    this.context.gateways.task.update(this.currentAgent.id, taskItem.id, {
      id: conversationItemId,
      customerId: this.currentCustomerId,
      conversationItem: conversationItemUpdate,
    });

    if (assignee.agentId !== this.currentAgent.id) {
      removeItemFromInbox(this.context, taskItem.id, taskItem.content.updatedAt);
    } else if (assignee.agentId === this.currentAgent.id) {
      addTaskToInbox(this.context, taskItem);
    }

    this.context.analytics.track('Task Assignee Changed', {
      agentId: assignee.agentId,
      routingGroupId: assignee.routingGroupId,
      itemId: conversationItemId,
      location,
    });
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }
}
