/**
 * Simple utility function for converting pretty much anything to a string
 *
 * @param {*} rawValue
 * @returns {string}
 */
export function tryStringify(rawValue) {
  try {
    return JSON.stringify(rawValue);
  } catch {
    return '';
  }
}
