import React from 'react';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import { ExpandableItem } from './slate_styles_menu.styles';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';

export default function SlateAlignmentMenu({ editor, isSelectionInTable, onChange }) {
  const { targetRef, isOpen, onClose, onToggle, setTargetRef } = usePopoverMenu();
  const chevronDir = isOpen ? 'up' : 'down';

  const onSetAlign = textAlign => {
    if (!editor) return;

    editor.focus();
    if (isSelectionInTable) {
      // do we have the table-specific alignment commands defined? If not -> ignore the call
      if (isFunction(editor.setCellAlignment)) {
        onChange(editor.setCellAlignment(textAlign));
      }
    } else {
      if (isFunction(editor.setAlignment)) {
        onChange(editor.setAlignment(textAlign));
      }
    }
    onClose();
  };

  return (
    <OutsideClickHandler onClickOutside={onClose}>
      <ExpandableItem
        data-aid="slateTextAlignment"
        isActive={isOpen || !isTextAligned('left')}
        onClick={onToggle}
        ref={setTargetRef}
        title="Text alignment"
      >
        <span>
          <i className="fa fa-align-left" />
          <i className={`draftStylesMenu-item-chevron fa fa-angle-${chevronDir}`} />
        </span>
      </ExpandableItem>
      {renderMenu()}
    </OutsideClickHandler>
  );

  function renderMenu() {
    return (
      <PopoverMenu
        boundByWindow
        data-aid="textAlignment-menu"
        isOpen={isOpen}
        onClickOutside={null}
        onClose={onClose}
        position="bottom"
        targetRef={targetRef}
      >
        <PopoverMenuItem data-aid="left" isActive={isTextAligned('left')} onClick={() => onSetAlign('left')}>
          Left
        </PopoverMenuItem>
        <PopoverMenuItem data-aid="right" isActive={isTextAligned('right')} onClick={() => onSetAlign('right')}>
          Right
        </PopoverMenuItem>
        <PopoverMenuItem data-aid="center" isActive={isTextAligned('center')} onClick={() => onSetAlign('center')}>
          Center
        </PopoverMenuItem>
        <PopoverMenuItem data-aid="justify" isActive={isTextAligned('justify')} onClick={() => onSetAlign('justify')}>
          Justify
        </PopoverMenuItem>
      </PopoverMenu>
    );
  }

  function isTextAligned(alignment) {
    if (!editor?.value) {
      return false;
    }

    const value = editor.value;
    const { startBlock } = value;
    let existingAlignment;

    if (isSelectionInTable) {
      const cell = startBlock && value.document.getClosest(startBlock.key, p => p.type === 'table_cell');
      existingAlignment = cell && cell.data.get('textAlign');
    } else {
      existingAlignment = startBlock && startBlock.data.get('textAlign');
    }
    return (alignment === 'left' && !existingAlignment) || existingAlignment === alignment;
  }
}

SlateAlignmentMenu.propTypes = {
  editor: PropTypes.shape({
    focus: PropTypes.func.isRequired,
    setAlignment: PropTypes.func,
    setCellAlignment: PropTypes.func,
    value: PropTypes.object,
  }),
  isSelectionInTable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
