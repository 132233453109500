import _ from 'lodash';

import ScheduledReport, { Recipient, Filters, Lookback } from 'models/reports/scheduled_report';

export default class SaveScheduledReport {
  constructor(context) {
    this.context = context;
  }

  run({ params }) {
    const recipients = _.map(params.recipients, address => Recipient.create({ type: 'EMAIL', address }));
    const scheduledReport = ScheduledReport.create({
      name: params.name,
      frequency: params.frequency,
      lookback: Lookback.create({
        quantity: _.toNumber(params.quantity),
        interval: params.interval,
      }),
      filters: Filters.create(params.filters),
      format: params.format,
      timezone: params.timezone,
      metricSet: params.metricSet,
      recipients,
    });

    this.context.stores.scheduledReport.setPending(scheduledReport);
    this.context.gateways.scheduledReport.add(scheduledReport);
  }
}
