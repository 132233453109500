import _ from 'lodash';
import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 41 41" {..._.omit(props, 'excludeCircle')}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g className="answerPanelIcon-path">
        <path d="m21.5,16c0,-1.934 5.148,-3.5 11,-3.5l0,16.5c-5.852,0 -11,1.566 -11,3.5c0,-1.934 -5.149,-3.5 -11,-3.5l0,-16.5c5.851,0 11,1.566 11,3.5l0,0z" />
        <path d="m21.5,16l0,16.375" />
        <path d="m19,18.2402c-1.61,-0.617 -3.887,-1.063 -6.5,-1.24" />
        <path d="m19,21.2402c-1.61,-0.617 -3.887,-1.063 -6.5,-1.24" />
        <path d="m19,24.2402c-1.61,-0.617 -3.887,-1.063 -6.5,-1.24" />
        <path d="m19,27.2402c-1.61,-0.617 -3.887,-1.063 -6.5,-1.24" />
        <path d="m24,18.2402c1.61,-0.617 3.887,-1.063 6.5,-1.24" />
        <path d="m24,21.2402c1.61,-0.617 3.887,-1.063 6.5,-1.24" />
        <path d="m24,24.2402c1.61,-0.617 3.887,-1.063 6.5,-1.24" />
        <path d="m24,27.2402c1.61,-0.617 3.887,-1.063 6.5,-1.24" />
      </g>
      <ellipse
        className="answerPanelIcon-circle"
        cx="21.5"
        cy="21"
        fillOpacity="0"
        id="svg_1"
        rx="18.5"
        ry="18.5"
        strokeWidth="1.25"
      />
    </g>
  </IconContainer>
);
