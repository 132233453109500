import _ from 'lodash';
import {
  ELEMENT_IMAGE,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_UL,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_PARAGRAPH,
} from '@udecode/plate';
import { jsx } from 'slate-hyperscript';
import { KEY_ALIGN } from '@udecode/plate-alignment';
import { MARK_COLOR, MARK_FONT_SIZE } from '@udecode/plate-font';
import { Text } from 'slate';

import { translateFontTagSize } from 'components/text_editor_new/lib/translate_font_size';
import { removeExtraBreaks } from 'components/text_editor_new/lib/remove_extra_breaks';

import { hasPluginOfType } from 'components/text_editor_new/lib/has_plugin_of_type';
import { hasPluginWithKey } from 'components/text_editor_new/lib/has_plugin_with_key';
import sanitize from 'components/text_editor_new/lib/sanitize_html';

/**
 * Beware - the `editor` parameter is optional and in many cases will be null/undefined. Also, the editor
 * instance may not match the "main" editor in the current composer
 */
export default function deserializeHtml(html, editor) {
  const sanitizedHtml = sanitize(html);
  const transformedHtml = removeExtraBreaks(sanitizedHtml);
  const parsedDocument = new DOMParser().parseFromString(transformedHtml, 'text/html');

  // Get the fragment, then go over the top nodes and make sure there are no unwrapped text nodes at the top.
  // Slate prohibits inline nodes at the top level and removes all unwrapped texts, so we have to wrap loose
  // text nodes into paragraphs.
  // See https://docs.slatejs.org/concepts/11-normalizing#built-in-constraints
  const fragment = deserializeHtmlElement(parsedDocument.body, {}, editor);
  const collatedNodes = collateNodes(fragment);

  return _.map(collatedNodes, nodeGroup => {
    if (isInlineNode(nodeGroup[0])) {
      return jsx('element', { type: ELEMENT_PARAGRAPH }, nodeGroup);
    }
    return nodeGroup[0];
  });
}

export function deserializeHtmlElement(el, markAttributes = {}, editor) {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = buildNodeStyleAttributes(el, markAttributes);
  const children = Array.from(el.childNodes)
    .map(node => deserializeHtmlElement(node, nodeAttributes, editor))
    .flat();

  if (!children.length) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  // If we have a list plugin available, we'll actually deserialize lists as expected. Without a list plugin,
  // we'll fall through to just treating LI items as paragraphs below.
  if (hasPluginWithKey(editor, 'list')) {
    switch (el.nodeName) {
      case 'OL': {
        return jsx('element', { type: ELEMENT_OL }, children);
      }
      case 'UL': {
        return jsx('element', { type: ELEMENT_UL }, children);
      }
      case 'LI': {
        const align = el.style.getPropertyValue('text-align');
        const node = jsx('element', { type: ELEMENT_LI }, wrapLIChildren(children));
        if (align && align !== 'left') {
          node[KEY_ALIGN] = align;
        }
        return node;
      }

      default:
        break;
    }
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);

    case 'DIV':
    case 'LI':
    case 'P': {
      const align = el.style.getPropertyValue('text-align');
      // If a paragraph-like element only contains a <br>, that means it's empty. Instead of
      // recursively deserializing the single <br> tag (which will resolve to a newline, creating
      // a unwanted extra line), we'll just short-circuit and return an empty text node.
      const isEmpty = el.childNodes.length === 1 && el.childNodes[0].nodeName === 'BR';
      const resolvedChildren = isEmpty ? [jsx('text', nodeAttributes, '')] : children;
      const node = jsx('element', { type: ELEMENT_PARAGRAPH }, resolvedChildren);
      if (align && align !== 'left' && align !== 'start') {
        node[KEY_ALIGN] = align;
      }

      return node;
    }

    case 'IMG':
      if (hasPluginOfType(editor, ELEMENT_IMAGE)) {
        const src = el.getAttribute('src');
        const attachmentId = el.getAttribute('data-attachment-id');
        const fileName = el.getAttribute('data-file');
        const decodedSrc = src && decodeURI(src);

        return jsx('element', { attachmentId, fileName, src: decodedSrc, type: ELEMENT_IMAGE }, children);
      }
      break;

    case 'PLACEHOLDER':
      return jsx('element', { type: 'placeholder' }, children);

    case 'VARIABLE': {
      const attributes = {
        'data-id': el.getAttribute('data-id'),
        'data-type': el.getAttribute('data-type'),
        'data-value': el.getAttribute('data-value'),
      };
      return jsx('element', { attributes, type: 'variable' }, children);
    }

    case 'A': {
      if (hasPluginOfType(editor, ELEMENT_LINK)) {
        const targetAttr = el.getAttribute('target') || '';
        const sanitizedTarget = ['_blank', '_self'].includes(targetAttr) ? targetAttr : '';
        const nodeTargetAttr = sanitizedTarget ? { target: sanitizedTarget } : {};

        return jsx('element', { url: el.getAttribute('href'), type: ELEMENT_LINK, ...nodeTargetAttr }, children);
      }
      return children;
    }

    case 'BR': {
      return jsx('text', markAttributes, '\n');
    }

    default:
      return children;
  }
}

/**
 * Builds a map of node attributes that control the element appearance (font style, color etc.)
 *
 * @param {HTMLElement} element
 * @param {object} initialAttributes
 * @return {object}
 */
function buildNodeStyleAttributes(element, initialAttributes = {}) {
  const nodeAttributes = { ...initialAttributes };
  if (!element) return nodeAttributes;

  switch (element.nodeName) {
    case 'STRONG':
    case 'B':
      nodeAttributes.bold = true;
      break;

    case 'EM':
    case 'I':
      nodeAttributes.italic = true;
      break;

    case 'U':
      nodeAttributes.underline = true;
      break;

    // Legacy font color and size. We still support it in order to handle existing Answers and items
    case 'FONT': {
      const colorAttribute = _.trim(element.getAttribute('color'));
      const sizeAttribute = _.trim(element.getAttribute('size'));
      const translatedSize = (sizeAttribute && translateFontTagSize(sizeAttribute)) || '';

      if (colorAttribute) {
        nodeAttributes[MARK_COLOR] = colorAttribute;
      }
      if (translatedSize) {
        nodeAttributes[MARK_FONT_SIZE] = translatedSize;
      }
      break;
    }

    // Current font color and size formatting
    case 'SPAN': {
      const color = element.style.color;
      const size = element.style.fontSize;

      if (color) nodeAttributes[MARK_COLOR] = color;
      if (size) nodeAttributes[MARK_FONT_SIZE] = size;
      break;
    }

    default:
      break;
  }

  return nodeAttributes;
}

/**
 * Walks through the list of nodes and groups consequent "inline" nodes together. Returns and array of
 * "node groups". Non-inline nodes will always be in a single-element group (by themselves)
 *
 * @param {object[]} nodes
 * @returns {object[][]}
 */
function collateNodes(nodes) {
  return _.reduce(
    nodes,
    (acc, node) => {
      if (!acc.length) return [[node]];
      if (!isInlineNode(node)) {
        acc.push([node]);
        return acc;
      }

      // If we are here, we are on an "inline" node. Now check the last group of nodes that we visited
      // and see if this node should join the group or start a new one
      const lastGroup = acc.pop();
      if (isInlineNode(lastGroup[0])) {
        lastGroup.push(node);
        acc.push(lastGroup);
      } else {
        acc.push(lastGroup);
        acc.push([node]);
      }

      return acc;
    },
    []
  );
}

/**
 * Helper function that checks whether the given node should be treated as "inline" for the purpose
 * of grouping. NOTE: this helper DOES NOT cover all inline nodes (i.e. it ignores all list-related
 * nodes) because it is NOT a general purpose utility. Its purpose in life is to help with collating
 * all nodes that should go into the same paragraph or <LIC> element
 *
 * @param node
 * @returns {boolean}
 */
function isInlineNode(node) {
  const inlineNodeTypes = ['A', 'PLACEHOLDER', 'VARIABLE'];
  const nodeType = (node?.type || '').toUpperCase();

  return node && (Text.isText(node) || inlineNodeTypes.includes(nodeType));
}

function wrapLIChildren(children) {
  // Before we can wrap LI children, we need to collate text nodes so that each
  // line does not become its own "element", which will break up list items with
  // colored text. We group consequent text nodes together so that they become
  // children of the same LIC element
  const collated = collateNodes(children);

  return _.map(collated, nodeGroup => {
    // Wrap raw text nodes in LIC element as expected by the Plate list plugin. Wrap only text nodes
    const firstChild = nodeGroup[0];

    // Non-inline children will always be in a "group" by themselves (a group of one node)
    return isInlineNode(firstChild) ? jsx('element', { type: ELEMENT_LIC }, nodeGroup) : firstChild;
  });
}
