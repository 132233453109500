import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
import { faPlane } from '@fortawesome/pro-solid-svg-icons/faPlane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';

import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import ExpandedFlightTransactionHeader from './expanded_flight_transaction';
import { FlightAttributesPropTypes } from 'components/customer/summary/transactions/constants';
import FlightTransactionDot from './flight_transaction_dot';
import { FlightTransactionDef, TransactionDefType } from 'models/configuration/transaction_def';
import ProfileCardDef from 'models/configuration/profile_card_def';
import { setOverflowTitle } from 'components/lib/overflow_title';
import TransactionGroup from './transaction_group';
import Tooltip from 'components/common/tooltip';

export function FlightTransactionSummary({ attributes, transactionDef }) {
  const flightNumberRef = useRef();

  const [flightNumber] = getDisplayAttribute('flightNumber');
  const [origin, displayOrigin] = getDisplayAttribute('origin', 4);
  const [destination, displayDestination] = getDisplayAttribute('destination', 4);

  useEffect(() => {
    setOverflowTitle(flightNumberRef, flightNumber);
  });

  return (
    <div className="flightTransaction-summary">
      {renderStatus()}
      <div className="flightTransaction-flightNumber" ref={flightNumberRef}>
        {flightNumber}
      </div>
      <div className="flightTransaction-originDestination">
        <div className="flightTransaction-origin" title={origin !== displayOrigin ? origin : null}>
          {displayOrigin}
        </div>
        <div className="flightTransaction-flightIcon">
          <FontAwesomeIcon icon={faPlane} />
        </div>
        <div
          className="flightTransaction-destination"
          title={destination !== displayDestination ? destination : displayDestination}
        >
          {displayDestination}
        </div>
      </div>
      <span className="flightTransaction-departure">{renderDepartureContent()}</span>
    </div>
  );

  /**
   * Extracts a string attribute from `attributes` and optionally truncates it to a certain length (if the
   * maxlength is provided)
   *
   * @param {string} attributeName - the name of the attribute. If the attribute does not exist, empty string
   * is returned
   * @param {number} [maxLength] - optional maximum length to which the value will be trimmed. If omitted,
   * the value is returned as-is
   * @returns {(*|string|string)[]} - returns a tuple [<original value>, <trimmed value>]. The original value
   * is often used for tooltips etc.
   */
  function getDisplayAttribute(attributeName, maxLength) {
    const attr = _.trim(_.get(attributes, attributeName, ''));
    return [attr, maxLength ? attr.substring(0, maxLength) : attr];
  }

  function renderStatus() {
    return (
      <div className="flightTransaction-status-wrapper">
        <div className="flightTransaction-status">
          <Tooltip bounds={{ left: 10 }} message={attributes.status || 'Unknown status'} position="top">
            <FlightTransactionDot status={attributes.status} statusCodeMap={transactionDef.statusCodeMap} />
          </Tooltip>
        </div>
      </div>
    );
  }

  function renderDepartureContent() {
    let departureContent;

    if (!attributes.departureTime) {
      departureContent = <div className="flightTransaction-departureUnavailable">N/A</div>;
    } else {
      const departureDate = moment(attributes.departureTime).format('MMM D');
      const departureTime = moment(attributes.departureTime).format('h:mm A');
      departureContent = [
        <div className="flightTransaction-departureDate" key="date">
          {departureDate}
        </div>,
        <div className="flightTransaction-departureTime" key="time">
          {departureTime}
        </div>,
      ];
    }
    return departureContent;
  }
}

FlightTransactionSummary.propTypes = {
  attributes: FlightAttributesPropTypes,
  transactionDef: PropTypes.instanceOf(FlightTransactionDef),
};

export default function FlightTransactions({ isLoading, transactionDef, transactions, onExpand, profileCardDef }) {
  const flightTransactionGroupMap = _.groupBy(transactions, 'attributes.pnrCode');
  const flightTransactionGroups = _(flightTransactionGroupMap)
    .map((groupTransactions, pnrCode) => {
      return [pnrCode, groupTransactions];
    })
    .sortBy(([pnrCode, groupTransactions]) => groupTransactions[0].attributes.departureTime)
    .value();

  const numGroups = _.keys(flightTransactionGroups).length;

  return (
    <ExpandableProfileCard
      data-aid="expandable-flight-transaction"
      isEmpty={!numGroups}
      isLoading={isLoading}
      limit={2}
      title={_.get(profileCardDef, 'label') || `${numGroups} Reservation${numGroups === 1 ? '' : 's'}`}
    >
      {_.map(flightTransactionGroups, ([pnrCode, groupTransactions]) => (
        <TransactionGroup
          groupClassName="flightTransaction-groupHeader"
          groupName={pnrCode}
          headerRenderer={attributes => (
            <ExpandedFlightTransactionHeader attributes={attributes} transactionDef={transactionDef} />
          )}
          key={pnrCode}
          onExpand={onExpand}
          shouldRenderImages={false}
          summaryRenderer={attributes => (
            <FlightTransactionSummary attributes={attributes} transactionDef={transactionDef} />
          )}
          transactionDef={transactionDef}
          transactions={groupTransactions}
          type={TransactionDefType.FLIGHT}
        />
      ))}
    </ExpandableProfileCard>
  );
}

FlightTransactions.propTypes = {
  isLoading: PropTypes.bool,
  onExpand: PropTypes.func,
  profileCardDef: PropTypes.instanceOf(ProfileCardDef),
  transactionDef: PropTypes.instanceOf(FlightTransactionDef),
  transactions: PropTypes.arrayOf(PropTypes.shape({ attributes: FlightAttributesPropTypes })),
};
