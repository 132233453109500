import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

class Checklist extends React.Component {
  defaultOptionRenderer({ value, label }) {
    return (
      <div className={'checklist-option-label'} htmlFor={value}>
        {label || value}
      </div>
    );
  }

  render() {
    let optionRenderer = this.props.optionRenderer || this.defaultOptionRenderer;
    let items = this.props.options.map(option => {
      const { value, isSelected } = option;
      let classNames = classnames('checklist-option', {
        'checklist-option-selected': !!isSelected,
        'checklist-option-modifiable': !this.props.readOnly,
      });
      return (
        <div
          className={classNames}
          key={value}
          onClick={!this.props.readOnly ? this.props.onChange.bind(null, value, !isSelected) : _.noop}
        >
          <div className={classnames('checklist-option-toggle', this.props.toggleClassName)} />
          {optionRenderer(option)}
        </div>
      );
    });

    return <div className={classnames('checklist', this.props.className)}>{items}</div>;
  }
}

Checklist.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ),
  optionRenderer: PropTypes.func,
  readOnly: PropTypes.bool,
  toggleClassName: PropTypes.string,
};

export default Checklist;
