import { Factory } from 'rosie';

import './lib/uuid';

Factory.define('RoutingSession').extend('Uuid');

Factory.define('RoutingSessionWithDefaults')
  .extend('RoutingSession')
  .attr('type', 'SMS')
  .attr('customerIdentifier', '+14151234567');

Factory.define('ChatRoutingSessionWithDefaults')
  .extend('RoutingSessionWithDefaults')
  .attr('type', 'CHAT')
  .attr('customerIdentifier', 'chat-customer-identifier');

Factory.define('RoutingQueueItem').extend('Uuid');

Factory.define('RoutingQueueItemWithDefaults')
  .extend('RoutingQueueItem')
  .attr('agents', [])
  .attr('assignee', () => Factory.attributes('AssigneeWithAgentDefaults'))
  .attr('conversationId', 'conversation-id')
  .attr('customerId', 'customer-id')
  .attr('sessions', () => [Factory.attributes('RoutingSessionWithDefaults')])
  .attr('status', 'QUEUED')
  .attr('_version', 1);

Factory.define('RoutingQueueItemWithChatSession')
  .extend('RoutingQueueItemWithDefaults')
  .attr('sessions', () => [
    Factory.attributes('RoutingSessionWithDefaults', { type: 'CHAT', customerIdentifier: 'chat-customer-identifier' }),
  ]);

Factory.define('RoutingQueueItemWithWhatsAppSession')
  .extend('RoutingQueueItemWithDefaults')
  .attr('sessions', () => [
    Factory.attributes('RoutingSessionWithDefaults', {
      type: 'WHATSAPP',
      customerIdentifier: 'whatsapp-customer-identifier',
    }),
  ]);

Factory.define('RoutingQueueItemWithFacebookMessengerSession')
  .extend('RoutingQueueItemWithDefaults')
  .attr('sessions', () => [
    Factory.attributes('RoutingSessionWithDefaults', {
      type: 'FB_MESSENGER',
      customerIdentifier: 'fb-messenger-customer-identifier',
    }),
  ]);
