import _ from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import connect from 'components/lib/connect';
import { H2 } from 'components/common/headers';
import RecentCustomer from './recent_customer';

export function RecentCustomers({ isLoading, recentCustomers }) {
  useTrackRecentCustomerViewed(recentCustomers, isLoading);

  return (
    <Customers>
      <Header>Recently Viewed Customers</Header>
      {_.map(recentCustomers, (recentCustomer, index) => (
        <RecentCustomer index={index} key={recentCustomer.id} recentCustomer={recentCustomer} />
      ))}
      {!isLoading && !recentCustomers.length && <Empty data-aid="noRecentCustomers">No customers viewed yet</Empty>}
    </Customers>
  );
}

function mapStateToProps({ getProvider }) {
  const recentCustomers = getProvider('recentCustomers').findAll();
  return {
    isLoading: getProvider('recentCustomers').isLoading(),
    recentCustomers,
  };
}

const RecentCustomersContainer = connect(mapStateToProps)(RecentCustomers);

const Header = styled(H2)`
  margin-left: 24px;
  margin-top: 24px;
`;
const Customers = styled.div`
  overflow: auto;
`;
const Empty = styled.span`
  align-items: center;
  color: ${p => p.theme.colors.gray600};
  display: flex;
  justify-content: center;
`;

function useTrackRecentCustomerViewed(recentCustomers, isLoading) {
  // Just track on mount for now..
  useEffect(() => {
    if (!isLoading) {
      analytics.track(`Recent Customers Viewed`, { count: recentCustomers.length });
    }
  }, [isLoading]);
}

export default RecentCustomersContainer;
