import createInvalidExtensionError, { ExtensionErrorReason } from '../factories/error/create_invalid_extension_error';
import createInvalidPhoneNumberError from '../factories/error/create_invalid_phone_number_error';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const SmsPreferenceType = createEnum('ON', 'OFF', 'FORBIDDEN');
export const PhoneNumberType = Object.freeze({
  HOME: 'HOME',
  MOBILE: 'MOBILE',
  OFFICE: 'OFFICE',
  OTHER: '',
});

export default createModel({
  modelName: 'PhoneNumber',

  properties: {
    original: prop(String).isRequired,
    normalized: prop(String).isRequired,
    extension: prop(String).default(''),
    regionCode: prop(String).default('US'),
    label: String,
    primary: Boolean,
    smsPreference: prop(String),
    type: prop(String).default(PhoneNumberType.OTHER),
  },

  formatForDisplay() {
    return formatPhoneNumber(this.normalized, this.regionCode);
  },

  statics: {
    create(attrs = {}) {
      attrs.regionCode = attrs.regionCode ? attrs.regionCode : 'US';
      attrs.normalized = normalizePhoneNumber(attrs.original, attrs.regionCode);
      return new this(attrs);
    },

    SmsPreference: SmsPreferenceType,
  },
});

export function getValidationErrors({ phoneNumber, regionCode, extension }) {
  let parsedPhoneNumber;
  const errors = [];

  if (extension) {
    if (extension.length > 7) {
      errors.push(createInvalidExtensionError('extension', ExtensionErrorReason.TOO_LONG));
    } else if (isNaN(extension)) {
      errors.push(createInvalidExtensionError('extension', ExtensionErrorReason.NAN));
    }
  }

  // Parse number with country code.
  try {
    parsedPhoneNumber = phoneUtil.parse(phoneNumber, regionCode || 'US');
    if (!phoneUtil.isValidNumber(parsedPhoneNumber, regionCode || 'US')) {
      errors.push(createInvalidPhoneNumberError('phone', phoneNumber));
    }
  } catch (e) {
    errors.push(createInvalidPhoneNumberError('phone', phoneNumber));
  }

  return errors;
}

export function normalizePhoneNumber(phoneNumber, regionCode = 'US') {
  const parsedNumber = phoneUtil.parse(phoneNumber, regionCode);
  if (!phoneUtil.isValidNumber(parsedNumber, regionCode)) {
    throw new Error(`[${phoneNumber}] is not a valid phone number`);
  }
  return phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
}

export function normalizePhoneNumberWithoutValidation(phoneNumber, regionCode = 'US') {
  const parsedNumber = phoneUtil.parse(phoneNumber, regionCode);
  return phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
}

export function isValidPhoneNumber(phoneNumber, regionCode = 'US') {
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber, regionCode);
    return phoneUtil.isValidNumber(parsedNumber, regionCode);
  } catch (e) {
    return false;
  }
}

export function formatPhoneNumber(phoneNumber, regionCode = 'US') {
  if (!phoneNumber) {
    return '';
  }
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber, regionCode);
    // Apply special format for US numbers: `+1 (xxx) xxx-xxx`
    if (parsedNumber.getCountryCode() === 1) {
      return `+1 ${phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL)}`;
    }
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
  } catch (e) {
    return phoneNumber;
  }
}

export function hasSipScheme(address) {
  return address.toLowerCase().startsWith('sip:') || address.toLowerCase().startsWith('sips:');
}
