import _ from 'lodash';

import AttachmentStatus from './attachment/attachment_status';
import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const CustomChannelMessageAttachment = createModel({
  modelName: 'CustomChannelMessageAttachment',

  properties: {
    id: prop(String).isRequired,
    contentType: String,
    filename: String,
    fileSize: Number,
    isRedacted: Boolean,
    source: prop({
      path: String,
      type: String,
    }).default({}).isRequired,
    status: String,
  },

  isAvailable() {
    return this.status === AttachmentStatus.AVAILABLE;
  },

  isImage() {
    return this.contentType && this.contentType.startsWith('image/');
  },

  fileDescriptor() {
    return {
      contentLength: this.fileSize,
      contentType: this.contentType,
      filename: this.filename,
    };
  },

  redact() {
    this.isRedacted = true;
  },

  statics: {
    create(attrs = {}) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },
  },
});

export default CustomChannelMessageAttachment;
