import ClearCurrentComposition from 'actions/composition/close_current_composition';
import CreateExternalFormComposition from 'actions/composition/create_external_form_composition';
import { DomainError, PermanentError } from 'scripts/application/lib/error';
import ExternalActionForm from 'models/external_action_form';
import qconsole from 'scripts/lib/qconsole';
import RefreshExternalCustomerData from 'actions/external_customer_lookup/refresh_external_customer_data';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class ExternalCustomerLookupActionsGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.externalActionForm;
  }

  handleFetchFormSuccess(dto) {
    this.store.resetLoading();
    this.store.set(ExternalActionForm.fromJs(dto));

    this.context.executeAction(CreateExternalFormComposition);
  }

  handleFetchFormErrors(err) {
    qconsole.log(`Unexpected error fetching form ${JSON.stringify(err)}`);

    this.store.resetLoading();
  }

  handlePerformActionSuccess() {
    this.context.stores.performExternalAction.resetLoading();

    this.endAction();
  }

  handlePerformActionErrors(err) {
    this.context.stores.performExternalAction.resetLoading();

    if (err instanceof DomainError) {
      this.store.setErrors(err.errors);
    } else if (err instanceof PermanentError) {
      err.errors.forEach(e => {
        this.context.executeAction(ShowToast, {
          type: ToastType.ERROR,
          message: e.detail,
        });
      });
      this.endAction();
    } else {
      qconsole.error(`Unexpected error performing action ${JSON.stringify(err)}`);
    }
  }

  endAction() {
    this.context.executeAction(RefreshExternalCustomerData, { shouldRequestCustomerExtension: true });
    this.context.executeAction(ClearCurrentComposition);
  }
}
