import _ from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import AnswerPanelDropdownMenu, { SortWrapper } from 'components/customer/answer_panel/lib/answer_panel_dropdown_menu';
import { Languages } from 'models/answers/snippet_channels';
import SetSearchLanguage from 'actions/answers/set_search_language';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function AnswerSearchLanguageSort() {
  const { languages, searchLanguage } = useContext(AnswerPanelContext);

  const executeAction = useExecuteAction();
  const options = useOptions(languages);
  const onSelect = useCallback(
    value => {
      if (searchLanguage !== value) {
        executeAction(SetSearchLanguage, { language: value });
      }
    },
    [searchLanguage]
  );

  // hide menu if org only has languages in one language
  // (check for <=2 because we prepend the "All Languages" option)
  if (options.length <= 2) {
    return null;
  }

  return (
    <StyledMenu>
      <AnswerPanelDropdownMenu
        data-aid="answerPanel-search-sortMenu"
        onSelect={onSelect}
        options={options}
        text={getText(searchLanguage)}
        value={searchLanguage}
      />
    </StyledMenu>
  );
}

const StyledMenu = styled.div`
  ${SortWrapper} {
    padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.large};
  }
`;

function useOptions(languages) {
  return useMemo(
    () =>
      [{ label: 'All languages', value: '' }].concat(
        _.orderBy(languages, l => Languages[l.code]).map(l => ({ label: Languages[l.code], value: l.code }))
      ),
    [languages]
  );
}

function getText(value) {
  if (!value) {
    return 'All languages';
  }
  return `${Languages[value]} only`;
}
