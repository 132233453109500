import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { REQUEST_DEFAULTS, IGNORE_ERROR_CODES } from 'components/customer/flexible_profile_card/lib/constants';
import connect from 'components/lib/connect';
import { evaluateTemplate } from 'components/customer/flexible_profile_card/lib/evaluate_template';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import ExternalDataObjectEnvelope from 'models/external_data_objects/external_data_object_envelope';
import { filterArray, sortArray } from 'components/customer/flexible_profile_card/lib/data_helpers';
import FlexibleCardBodyItem from 'components/customer/flexible_profile_card/components/flexible_card_body_item';
import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';
import { getCurrentCustomerState } from 'components/customer/summary/lib/store_helpers';
import { getRenderableSections } from 'components/customer/flexible_profile_card/lib/get_renderable_sections';
import { isRenderableItem } from 'components/customer/flexible_profile_card/lib/is_renderable_item';
import ProfileErrorCard from 'components/customer/profile/profile_error_card';
import Spinner from 'components/common/spinner_two';
import useExternalDataRequest from 'components/customer/hooks/use_external_data_request';
import withStaticId from 'components/customer/lib/with_static_id';

export function FlexibleProfileCardBase({
  cardConfiguration,
  customerId,
  data,
  dataFilter,
  dataRowLimit,
  dataSort,
  expandThreshold, // How many items to display in "collapsed" state before rendering the "expander"
  isLoading,
  isCustomerLoaded,
  loadingError,
  requestorId,
}) {
  const dataLoaded = !_.isEmpty(data);

  // Data ingesting/processing error(s). Not to be confused with the HTTP request error, which is loadingError
  const dataErrors = _.filter(_.get(data, 'errors', []), err => !!err);
  const hasDataErrors = !_.isEmpty(dataErrors);

  const requestedNamespace = _.get(cardConfiguration, 'dataConfiguration.namespace');

  useExternalDataRequest(
    customerId,
    isCustomerLoaded,
    dataLoaded,
    false,
    {
      namespace: requestedNamespace,
      parentEntityId: customerId,
      parentEntityType: ExternalDataObjectEnvelope.ParentEntityType.CUSTOMER,
      requestorId,
      /* Additional request parameters below this line */
      page: REQUEST_DEFAULTS.PAGE,
      pageSize: REQUEST_DEFAULTS.PAGE_SIZE,
    },
    _.get(cardConfiguration, 'dataConfiguration.requestTimeout')
  );

  if (!customerId || !isCustomerLoaded) return null;

  // If the card configuration is missing, render "Error card" instead
  if (_.isEmpty(cardConfiguration)) {
    return <ProfileErrorCard data-aid={`flexible_card_configuration_error`} />;
  }

  // If we tried and could not load the data, render "Error card" instead
  if (loadingError || hasDataErrors) {
    for (const errorCode of Object.values(IGNORE_ERROR_CODES)) {
      if (_.includes(dataErrors, errorCode)) {
        return null;
      }
    }

    return (
      <ProfileErrorCard
        data-aid={`flexible_card_loading_error_${cardConfiguration.namespace}`}
        reason="Unable to load the required data."
      />
    );
  }

  // Hide the card that has no data and is not actively loading
  if (!isLoading && _.isEmpty(data)) return null;

  // Apply defaults
  const defaultExpandThreshold = _.get(cardConfiguration, 'displayConfiguration.defaultExpandThreshold');
  const defaultRowLimit = _.get(cardConfiguration, 'dataConfiguration.defaultRowLimit', 0);
  const defaultFilter = _.get(cardConfiguration, 'dataConfiguration.defaultFilter');
  const defaultSort = _.get(cardConfiguration, 'dataConfiguration.defaultSortOrder');
  const expanderLimit = expandThreshold || defaultExpandThreshold;
  const rowLimit = dataRowLimit || defaultRowLimit || 0;
  const filter = _.isEmpty(dataFilter) ? defaultFilter : dataFilter;
  const sort = _.isEmpty(dataSort) ? defaultSort : dataSort;

  // Hide empty card
  const dataItems = dataLoaded ? transformDataForRendering(data, filter, sort, rowLimit) : [];
  const isEmptyCard = dataLoaded ? !hasRenderableData(cardConfiguration, dataItems) : true;
  if (isEmptyCard && dataLoaded) return null;

  const cardContent = isLoading ? <Spinner key="loading-spinner" /> : renderCardBodyItems(cardConfiguration, dataItems);
  return (
    <ExpandableProfileCard
      data-aid={`flexible-card-${_.kebabCase(requestedNamespace)}`}
      isEmpty={isEmptyCard}
      isLoading={isLoading}
      limit={expanderLimit || undefined}
      title={renderCardTitle(cardConfiguration, dataItems)}
    >
      {cardContent}
    </ExpandableProfileCard>
  );
}

function hasRenderableData(cardConfiguration, dataItems) {
  if (_.isEmpty(dataItems)) return false;

  const summaryBlockAttrs = _.get(cardConfiguration, 'displayConfiguration.itemSummary.attributes') || [];
  const itemDetailsAttrs = _.get(cardConfiguration, 'displayConfiguration.itemDetails.header.attributes') || [];
  const sections = _.get(cardConfiguration, 'displayConfiguration.itemDetails.sections') || [];

  // Find the first item that has at least one renderable attribute
  const renderableItem = _.find(dataItems, dataItem => {
    const renderableAttrs = getAttributesToDisplay([...summaryBlockAttrs, ...itemDetailsAttrs], dataItem);
    if (renderableAttrs.length) return true;

    const renderableSections = getRenderableSections(sections, dataItem);
    return !!renderableSections.length;
  });

  return !!renderableItem;
}

// Because of the way ExpandableProfileCard works, we need to return an array of components
function renderCardBodyItems(cardConfiguration, data) {
  if (_.isEmpty(data)) return [];

  const dataItems = _.isArray(data) ? data : [data];
  const summaryConfig = _.get(cardConfiguration, 'displayConfiguration.itemSummary');
  const detailsConfig = _.get(cardConfiguration, 'displayConfiguration.itemDetails');
  const hasSummary = !_.isEmpty(_.get(summaryConfig, 'attributes'));

  return _.reduce(
    dataItems,
    (acc, dataItem, key) => {
      const hasDetails = isRenderableItem(detailsConfig, dataItem);
      if (hasSummary || hasDetails) {
        acc.push(<FlexibleCardBodyItem cardConfig={cardConfiguration} itemData={dataItem} key={key} />);
      }
      return acc;
    },
    []
  );
}

function renderCardTitle(cardConfiguration, data) {
  if (_.isEmpty(data) || _.isEmpty(cardConfiguration)) return '';

  const titleTemplate = _.trim(_.get(cardConfiguration, 'displayConfiguration.title', ''));
  return evaluateTemplate(titleTemplate, data);
}

/**
 * Extracts the data items from the payload and prepares for rendering. Applies sorting, filtering and
 * row limit if necessary
 */
function transformDataForRendering(externalData, dataFilter, dataSort, dataRowLimit) {
  if (_.isEmpty(externalData)) return [];

  // First, extract the items from the payload
  const rawList = _.get(externalData, 'externalDataItems', []);
  const rawDataItems = _.map(rawList, element => _.get(element, 'dataItem'));
  const dataItems = _.filter(rawDataItems, item => !!item);

  // Second, filter and sort if necessary. The order of transformations:
  // Filtering is first, then sorting the results, then applying the row limit (0 means "no limit")
  const rowLimit = Math.max(dataRowLimit, 0);
  const filtered = _.isEmpty(dataFilter) ? dataItems : filterArray(dataFilter, dataItems);
  const sorted = _.isEmpty(dataSort) ? filtered : sortArray(dataSort, filtered);

  return rowLimit ? sorted.slice(0, rowLimit) : sorted;
}

FlexibleProfileCardBase.propTypes = {
  cardConfiguration: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired,
  data: PropTypes.object,
  dataFilter: PropTypes.object,
  dataRowLimit: PropTypes.number,
  dataSort: PropTypes.object,
  expandThreshold: PropTypes.number,
  isCustomerLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingError: PropTypes.object,
  requestorId: PropTypes.string.isRequired,
};

function mapStateToProps({ getProvider }, { cardConfiguration, requestorId }) {
  const { customerId, isCustomerLoaded } = getCurrentCustomerState(getProvider);
  const storeProvider = getProvider('externalDataObjects');

  // In case the customer stores are not fully loaded, bail out and wait for the next round of re-renderings
  if (!isCustomerLoaded) {
    return {
      customerId,
      isCustomerLoaded,
    };
  }

  const dataEntityKey = {
    namespace: _.get(cardConfiguration, 'dataConfiguration.namespace'),
    parentEntityId: customerId,
    parentEntityType: ExternalDataObjectEnvelope.ParentEntityType.CUSTOMER,
    requestorId,
  };
  const isLoading = storeProvider.isLoading(dataEntityKey);
  const loadingError = storeProvider.getErrorForLoading(dataEntityKey);
  const envelope = isLoading || loadingError ? null : storeProvider.findBy(dataEntityKey); // We use "findBy" to get the first match

  return {
    customerId,
    isCustomerLoaded,
    isLoading,
    loadingError,
    data: envelope?.data,
  };
}

// These two steps MUST happen in that exact order so that `mapStateToProps` has access to `requestorId`
const ConnectedFlexibleDataCard = connect(mapStateToProps)(FlexibleProfileCardBase);
export default withStaticId(ConnectedFlexibleDataCard, 'requestorId');
