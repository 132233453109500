import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import AgentSelect from 'components/search/filters/agent_select';
import ChannelFilterContainer from 'components/search/filters/channel_filter_container';
import DateCreatedFilterContainer from 'components/search/filters/date_created_filter_container';
import DateUpdatedFilterContainer from 'components/search/filters/date_updated_filter_container';
import InboxSelect from 'components/search/filters/inbox_select';
import HitTypes from 'models/search/hit_types';
import SLAFilterContainer from 'components/search/filters/sla_filter_container';
import StatusFilterContainer from 'components/search/filters/status_filter_container';
import TopicSelect from 'components/search/filters/topic_select';

const CONVERSATION = HitTypes.CONVERSATION;
const CUSTOMER = HitTypes.CUSTOMER_PROFILE;

class UniversalSearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedAggs: props.aggregations || null,
    };
  }
  // So we don't lose focus when using the filters
  preventDefault(ev) {
    ev.preventDefault();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.hasResult) {
      this.setState({ cachedAggs: props.aggregations, cachedType: props.documentType });
    } else if (!props.hasResult && !props.queryId) {
      this.setState({ cachedAggs: null, cachedType: null });
    }
  }

  render() {
    const aggregations = this.state.cachedAggs || this.props.aggregations;
    const type = this.state.cachedType || this.props.documentType;

    const convCount = aggregations ? _.get(aggregations, `types.buckets.${CONVERSATION}`) || 0 : null;
    const custCount = aggregations ? _.get(aggregations, `types.buckets.${CUSTOMER}`) || 0 : null;

    let conversationFilters = (
      <div className="universalSearch-conversationFilters">
        <div className="universalSearch-filter-facetSeparator" />
        <h5 className="universalSearch-filter-facetHeader">Filter by:</h5>
        <div>
          <div className="universalSearch-filterHeader">Topic</div>
          <div className="universalSearch-filter universalSearch-filter-topic">
            <TopicSelect onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} />
          </div>
          <div className="universalSearch-filterHeader">Status</div>
          <div className="universalSearch-filter universalSearch-filter-status">
            <StatusFilterContainer onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} />
          </div>
          <div className="universalSearch-filterHeader">SLA</div>
          <div className="universalSearch-filter universalSearch-filter-sla">
            <SLAFilterContainer onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} />
          </div>
          <div className="universalSearch-filterHeader">Agent</div>
          <div className="universalSearch-filter universalSearch-filter-agents">
            <AgentSelect onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} selectedAgents={[]} />
          </div>
          <div className="universalSearch-filterHeader">Inboxes</div>
          <div className="universalSearch-filter universalSearch-filter-inbox">
            <InboxSelect onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} />
          </div>
          <div className="universalSearch-filterHeader">Date</div>
          <div className="universalSearch-filter">
            <div className="universalSearch-filter-dateCreated">
              <DateCreatedFilterContainer onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} />
            </div>
            <div className="universalSearch-filter-dateUpdated">
              <DateUpdatedFilterContainer onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} />
            </div>
          </div>
          <div className="universalSearch-filterHeader">Channels</div>
          <div className="universalSearch-filter universalSearch-filter-channel">
            <ChannelFilterContainer onChange={this.props.onSearchFilterChange} queryId={this.props.queryId} />
          </div>
        </div>
      </div>
    );

    return (
      <div className="universalSearch-filters-wrapper">
        <div className="universalSearch-filters">
          <h5 className="universalSearch-filter-facetHeader">Search</h5>
          <ul className="universalSearch-filter-documentTypes">
            <li
              className={classnames(
                'universalSearch-filter-documentType',
                { 'universalSearch-filter-documentType-active': type === CONVERSATION },
                { 'universalSearch-filter-documentType-active-noResults': type === CONVERSATION && !convCount },
                { 'universalSearch-filter-documentType-noResults': type !== CONVERSATION && !convCount }
              )}
              data-aid="universalSearch-filter-conversation"
              onClick={() => this.props.onSearchFilterChange({ type: CONVERSATION })}
              onMouseDown={this.preventDefault.bind(this)}
            >
              <div className="universalSearch-filter-documentType-label">Conversations</div>
              <div className="universalSearch-filter-documentType-count">{convCount && convCount.toLocaleString()}</div>
            </li>
            <li
              className={classnames(
                'universalSearch-filter-documentType',
                { 'universalSearch-filter-documentType-active': type === CUSTOMER },
                { 'universalSearch-filter-documentType-active-noResults': type === CUSTOMER && !custCount },
                { 'universalSearch-filter-documentType-noResults': type !== CUSTOMER && !custCount }
              )}
              data-aid="universalSearch-filter-customer"
              onClick={() => this.props.onSearchFilterChange({ type: CUSTOMER })}
              onMouseDown={this.preventDefault.bind(this)}
            >
              <div className="universalSearch-filter-documentType-label">Customers</div>
              <div className="universalSearch-filter-documentType-count">{custCount && custCount.toLocaleString()}</div>
            </li>
          </ul>
          {type !== CUSTOMER && conversationFilters}
        </div>
      </div>
    );
  }
}

UniversalSearchFilter.propTypes = {
  aggregations: PropTypes.object,
  hasResult: PropTypes.bool.isRequired,
  onSearchFilterChange: PropTypes.func.isRequired,
  queryId: PropTypes.string,
  documentType: PropTypes.string,
};

export default UniversalSearchFilter;
