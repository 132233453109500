import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('AIActivityItem')
  .attr('type', 'AI_ACTIVITY');

Factory.define('AIConversationSummaryWithDefaults')
  .extend('AIActivityItem')
  .attr('activityType', 'CONVERSATION_SUMMARY')
  .attr('meta', {
    caption: 'This is a caption',
    details: 'Summary item #1\nSummary item #2\nSummary item #3'
  });

Factory.define('AIConversationSummaryItemWithDefaults')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('AIConversationSummaryWithDefaults', content))
  .attr('initiator', {
    type: 'AGENT',
    id: 'agent-id',
  });
