import React from 'react';
import styled from 'styled-components';

import PlaintextEditor from 'components/composer/editors/plaintext';

const SubjectContainer = styled.div.attrs({ 'data-aid': 'slate-subject-container' })`
  display: flex;
  justify-content: space-between;

  .slateTextEditor {
    flex-shrink: 1;
    font-weight: bold;
    flex-basis: 200px;
  }
`;

export function Subject(props) {
  return <PlaintextEditor data-aid="slate-email-subject" placeholder="Subject line" {...props} />;
}

const SubjectContainerNew = styled.div.attrs({ 'data-aid': 'slate-subject-container' })`
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.colors.gray400};
  display: flex;
  justify-content: space-between;
  width: 100%;

  [data-slate-editor='true'] {
    flex-shrink: 1;
    flex-basis: 200px;
    padding: 8px 4px;
    width: 100%;
  }
`;

export { SubjectContainer, SubjectContainerNew };
export default Subject;
