import _ from 'lodash';
import Report, { ReportType } from './report';
import ReportHelpers from './report_helpers';

export default class IvrEndStateReport extends Report {
  getDataForIvrEndStates() {
    let reportData = [];

    _.forEach(this.data.endStatesByTime, row => {
      row.time = ReportHelpers.getDate(row.time).format('YYYY-MM-DD');
      reportData.push(row);
    });

    return reportData;
  }

  init() {
    this.reportData = this.getDataForIvrEndStates();

    return {
      timezone: this.timezone,
      getDataForRowAndMetric: (rowIdx, metric) => {
        const dataForRow = this.reportData[rowIdx];

        switch (metric) {
          case 'time':
            return _.get(dataForRow, metric, null);
          case 'endStateDescription':
            return _.get(dataForRow, metric, '');
          case 'endStateId':
            return _.get(dataForRow, metric, '');
          case 'count':
            return _.get(dataForRow, metric, 0);
          case 'duration':
            return _.get(dataForRow, metric, 0) / _.get(dataForRow, 'count', 1);
          default:
            return null;
        }
      },
    };
  }

  get reportType() {
    return ReportType['IVR-END-STATES'];
  }
}
