import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

/**
 * @visibleName Text Input
 */

const TextInput = React.forwardRef((props, ref) => {
  return <StyledInput ref={ref} {...props} />;
});

TextInput.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
};

const errorCss = css`
  border-color: ${p => p.theme.colors.red400};
  &:focus {
    border-color: ${p => p.theme.colors.red600};
  }
`;

export const InputStyle = css`
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.gray300};
  padding: 8px;
  border-radius: 4px;
  &:focus {
    border-color: ${p => p.theme.colors.gray600};
  }
  transition: border-color 100ms ease-in;
  &[disabled] {
    background-color: ${p => p.theme.colors.gray100};
    color: ${p => p.theme.colors.gray500};
  }
  ${p => p.isError && errorCss};
`;

const StyledInput = styled.input`
  ${InputStyle};
`;

export default TextInput;
