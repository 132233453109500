import _ from 'lodash';
import moment from 'moment';

import ChecklistFilterWithCounts from 'components/search/filters/checklist_filter_with_counts';
import connect from 'components/lib/connect';
import { SlaStatuses } from 'models/search/search_aggregations';

function mapStateToProps(context, props) {
  const result = context.getProvider('universalSearchResults').findBy({ id: props.queryId });
  const counts = _.get(result, 'aggregations.dueDates.buckets');

  const options = [
    {
      count: counts ? counts[SlaStatuses.EXCEEDING] || 0 : null,
      value: 'lte',
      label: 'Exceeding SLA',
      isSelected: !!_.get(result, 'query.filter.dueAt.lte'),
    },
    {
      count: counts ? counts[SlaStatuses.WITHIN] || 0 : null,
      value: 'gte',
      label: 'Within SLA',
      isSelected: !!_.get(result, 'query.filter.dueAt.gte'),
    },
  ];

  function onChange(option, isSelected) {
    let newDueAt = {};
    if (isSelected) {
      _.set(newDueAt, option, moment().format());
    }
    props.onChange({ dueAt: newDueAt });
  }

  return {
    isLoading: result ? result.isLoading : false,
    onChange,
    options,
  };
}

export default connect(mapStateToProps)(ChecklistFilterWithCounts);
