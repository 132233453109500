import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import some from 'lodash/some';

import AgentStatusButton from './agent_status_button';
import connect from 'components/lib/connect';
import FocusButton from 'components/page_layout/agent_status/focus_button';
import Tooltip from 'components/common/tooltip';
import UpdateAgentRoutingPreferences from 'actions/routing/update_agent_routing_preferences';

export function FocusStatusButton({ numPreferenceButtons, isFocusOn, isPending, shouldDisplay, onClick }) {
  const isPendingRef = useRef(isPending);

  useEffect(() => {
    isPendingRef.current = isPending;
  }, [isPending]);

  const handleClick = useCallback(() => {
    // if another operation is pending, don't allow the user to update focus status
    if (isPendingRef.current) {
      return;
    }

    onClick({ isFocusOn });
  }, [isFocusOn]);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <div data-aid="focusStatusButton">
      <Tooltip
        data-aid="focusStatusButtonTooltip"
        delay={50}
        margin={-5}
        message={isFocusOn ? 'Turn off focus' : 'Turn on focus'}
        position="bottom"
        targetPosition={isFocusOn ? 'start' : 'center'}
      >
        <AgentStatusButton data-aid="agentStatusButton-focus" onClick={handleClick}>
          <FocusButton isFocusOn={isFocusOn} numPreferenceButtons={numPreferenceButtons} />
        </AgentStatusButton>
      </Tooltip>
    </div>
  );
}

FocusStatusButton.propTypes = {
  numPreferenceButtons: PropTypes.number.isRequired,
  isFocusOn: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  shouldDisplay: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapExecuteToProps)(FocusStatusButton);

function mapStateToProps({ getProvider }) {
  const messagingConfiguration = getProvider('messagingConfiguration').get();

  const preferencesProvider = getProvider('agentRoutingPreferences');
  const pending = preferencesProvider.getPending();
  const current = preferencesProvider.get();
  const preferences = pending || current;
  const isPending = preferencesProvider.isPending();
  const shouldDisplay =
    (messagingConfiguration &&
      messagingConfiguration.enableFocusMode &&
      preferences._version > 0 &&
      some(preferences.channels)) ||
    false;

  return {
    isFocusOn: preferences.isFocusOn,
    isPending,
    shouldDisplay,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClick: ({ isFocusOn }) => executeAction(UpdateAgentRoutingPreferences, { isFocusOn: !isFocusOn }),
  };
}
