import createAdminLocationModel from './lib/create_admin_location_model';
import { prop } from 'models/lib/create_model';

export default createAdminLocationModel({
  modelName: 'UsersAdmin',
  properties: {
    paginationStartIndex: prop(Number).default(0),
    textFilter: prop(String).default(''),
  },

  statics: {
    breadcrumb: 'Users',
  },

  setAttributes({ paginationStartIndex, textFilter }) {
    this.paginationStartIndex = paginationStartIndex;
    this.textFilter = textFilter;
  },
});
