import querystring from 'querystring';

import ErrorReporter from 'scripts/infrastructure/error_reporter';
import LocalCache from './local_cache';

const datasetCache = new LocalCache();
export { datasetCache };

export function getDatabase(orgId, errorReporter = ErrorReporter, request = new XMLHttpRequest()) {
  return datasetCache.findById(orgId) || getDatabaseBy('orgId', orgId, errorReporter, request);
}

export function getDatabaseByCompanyDomain(
  companyDomain,
  errorReporter = ErrorReporter,
  request = new XMLHttpRequest()
) {
  let database = datasetCache.findBy({ companyDomain });
  return database || getDatabaseBy('companyDomain', companyDomain, errorReporter, request);
}

function getDatabaseBy(field, value, errorReporter, request) {
  let query = querystring.stringify({ [field]: value });
  request.open('GET', `/api/v1/datasets?${query}`, false);
  request.send(null);
  if (request.status === 200) {
    let database = JSON.parse(request.responseText);
    datasetCache.set(database.orgId, database);
    return database;
  }
  errorReporter.reportError(`Error while fetching database with ${field}=${value}: ${request.response}`);
  return null;
}
