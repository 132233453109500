import InboxCountConverter from 'scripts/application/dto_converters/inbox_count_converter';
import qconsole from 'scripts/lib/qconsole';

export default class ConversationCountGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleFetchAgentCountsSuccess(countsDto) {
    this.context.stores.personalInboxCounts.set(InboxCountConverter.fromDto(countsDto));
  }

  handleFetchGroupCountsSuccess(countsDtos) {
    let teamCounts = countsDtos.map(countsDto => {
      return InboxCountConverter.fromDto(countsDto);
    });
    this.context.stores.teamInboxCounts.set(teamCounts);
  }

  handleFetchConversationCountError(errorDto) {
    qconsole.log(`Failed to fetch conversation counts ${JSON.stringify(errorDto)}`);
  }
}
