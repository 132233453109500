import _ from 'lodash';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

export default class AmplitudeAdapter {
  constructor(amplitudeApiKey) {
    this.isEnabled = false;
    this.amplitudeApiKey = amplitudeApiKey;
    this.sessionReplayTracking = sessionReplayPlugin();
  }

  _getCookieDomain() {
    const hostname = _.get(window, 'location.hostname', '');

    // If it's localhost, return as is
    if (hostname === 'localhost') {
      return hostname;
    }

    // Get the last two parts of the domain (e.g., "example.com" from "sub.example.com")
    const parts = hostname.split('.');
    if (parts.length > 2) {
      return `.${parts.slice(-2).join('.')}`;
    }

    return hostname;
  }

  _clearDuplicateKeyTracking() {
    const cookies = document.cookie.split(';');
    const ampCookies = cookies
      .map(cookie => cookie.trim().split('=')[0])
      .filter(name => /^AMP_[A-Za-z0-9]{10}$/.test(name));

    // Only clear cookies if we find duplicates
    if (ampCookies.length > 1) {
      for (let name of ampCookies) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${this._getCookieDomain()}`;
      }
    }
  }

  init() {
    // Amplitude claims that our session tracking is getting messed up cause
    // there is somehow 2 Amplitude Cookies. This function will remove the AMP_
    // cookies if there is more than 1 to ensure new cookies are created.
    this._clearDuplicateKeyTracking();

    // Documentation: https://www.docs.developers.amplitude.com/data/sdks/browser-2/#configuration
    this._init(this.amplitudeApiKey, {
      // This ensures we do not track events until explicitly told to do so,
      // e.g. after login
      optOut: true,
      autocapture: {
        elementInteractions: true,
      },
      // logLevel: 4, uncomment for debugging
    });
  }

  enable() {
    if (!this.isEnabled) {
      this.isEnabled = true;
      this._setOptOut(false);
      amplitude.add(this.sessionReplayTracking);
    }
  }

  disable() {
    if (this.isEnabled) {
      this._reset();
      this._setOptOut(true);
      amplitude.remove(this.sessionReplayTracking.name);
      amplitude.setSessionId(Date.now());
      this.isEnabled = false;
    }
  }

  track(eventName, eventProperties) {
    if (!this.isEnabled) {
      return;
    }
    this._track(eventName, eventProperties);
  }

  setUser(user) {
    if (!this.isEnabled || !user || !user.id) {
      return;
    }
    this._setUserId(user.id);
    let userProperties = _.omit(user, 'id');
    if (!_.isEmpty(userProperties)) {
      this.updateUser(userProperties);
    }
  }

  updateUser(properties) {
    if (!this.isEnabled) {
      return;
    }
    let userProperties = _(properties)
      .omit('id')
      .omitBy(_.isUndefined)
      .omitBy(_.isNull)
      .value();
    if (_.isEmpty(userProperties)) {
      return;
    }
    const identifyEvent = new amplitude.Identify();
    _.forEach(userProperties, (value, key) => {
      identifyEvent.set(key, value);
    });
    this._identify(identifyEvent);
  }

  _init(apiKey, options) {
    amplitude.init(apiKey, options);
  }

  _reset() {
    amplitude.reset();
  }

  _setOptOut(optOut) {
    amplitude.setOptOut(optOut);
  }

  addPlugin(plugin) {
    amplitude.add(plugin);
  }

  _track(eventName, eventProperties) {
    amplitude.track(eventName, eventProperties);
  }

  _setUserId(userId) {
    amplitude.setUserId(userId);
  }

  _identify(identifyEvent) {
    amplitude.identify(identifyEvent);
  }
}
