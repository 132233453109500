import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import { createLinkedReportNumberCell, createSearchLink, getDatesForCell } from './lib/cell_content';
import { descendants, sortBy } from 'actions/topics/lib/topic_helpers';
import { findReportConfigBySlug } from 'models/reports/report';
import { getDatesInRange } from 'models/location/reports';
import { LegacyReportConfigs } from 'scripts/domain/models/reports/report_configs';
import LineGraph from 'scripts/presentation/components/reporting/report/lib/line_graph';
import SharedTable, { SharedTableColumns } from './lib/shared_table';
import TopicNameCell from './lib/topic_name_cell';
import TopicReportModel from 'scripts/domain/models/reports/topic_report';
import searchContentTypeFromChannel from 'models/reports/search_content_type_from_channel';

const NUM_TOP_TOPICS = 5;

export function TopicReport({
  aggregationLevel,
  changeTableSort,
  days,
  endAt,
  endDate,
  getDescendants,
  getTopicName,
  getTopicTitle,
  initializedReport,
  lineData,
  lineIds,
  routingGroupIds,
  searchChannel,
  startAt,
  startDate,
  timezone,
  topicHierarchyData,
  totalsByTopic,
  windowWidth,
}) {
  return (
    <div>
      {renderChart(topicHierarchyData, totalsByTopic, windowWidth, lineData, lineIds)}
      <div className="reportTable-container">
        <SharedTable
          aggregationLevel={aggregationLevel}
          changeTableSort={changeTableSort}
          columnNames={['Topic Name', 'Total Created Conversations']}
          days={days}
          getNameForId={getTopicName}
          getTitleForId={getTopicTitle}
          name="Topics"
          renderCell={({ date, value, id }) => {
            date = date ? moment.tz(date, timezone) : null;
            const dates = getDatesForCell({
              minStartDate: startDate,
              maxEndDate: endDate,
              date,
              aggregationLevel,
            });
            return createLinkedReportNumberCell({
              aggregationLevel,
              searchChannel,
              routingGroupIds,
              topicIds: getDescendants(id),
              hasCreatedFilter: true,
            })({ startDate: dates.startDate, endDate: dates.endDate, value });
          }}
          report={initializedReport}
          windowWidth={windowWidth}
        />
      </div>
    </div>
  );

  function renderChart(topicHierarchyData, totalsByTopic, windowWidth, lineData, lineIds) {
    if (totalsByTopic.length > 0 || lineIds.length > 0) {
      return (
        <LineGraph
          aggregationLevel={aggregationLevel}
          data={topicHierarchyData}
          lineData={lineData}
          lineIds={lineIds}
          tooltipTotalLabel="topic"
          width={windowWidth}
        />
      );
    }
    return null;
  }
}

TopicReport.propTypes = {
  aggregationLevel: PropTypes.string,
  changeTableSort: PropTypes.func,
  days: PropTypes.arrayOf(PropTypes.string),
  endAt: PropTypes.string,
  endDate: PropTypes.object,
  getTopicName: PropTypes.func,
  getTopicTitle: PropTypes.func,
  initializedReport: PropTypes.object,
  lineData: PropTypes.object,
  lineIds: PropTypes.array,
  routingGroupIds: PropTypes.arrayOf(PropTypes.string),
  searchChannel: PropTypes.string,
  startAt: PropTypes.string,
  startDate: PropTypes.object,
  timezone: PropTypes.string,
  topicHierarchyData: PropTypes.array,
  totalsByTopic: PropTypes.arrayOf(PropTypes.object),
  windowWidth: PropTypes.number,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, { windowWidth }) {
  const report = getProvider('report').get();

  const topicsProvider = getProvider('topics');
  const nameData = topicsProvider.findAll();
  const currentLocation = getProvider('currentLocation').get();
  const aggregationLevel = currentLocation.getAggregationLevel(
    findReportConfigBySlug(LegacyReportConfigs, currentLocation.getSlug()).timeAggregation
  );

  const channel = currentLocation.getChannel();
  const endAt = currentLocation.getEndAt();
  const routingGroupIds = currentLocation.getRoutingGroupIds();
  const startAt = currentLocation.getStartAt();

  // props

  const days = getDatesInRange(startAt, endAt, aggregationLevel);

  const filteredTopicIds = currentLocation.getTopicIds();
  const topicHierarchyData = report.getDataByDayAndTopicId(nameData, filteredTopicIds, NUM_TOP_TOPICS, days);
  const lineIds = report.getTopTopics(filteredTopicIds, NUM_TOP_TOPICS);
  let lineData = {};
  _.forEach(lineIds, id => {
    lineData[id] = TopicReportModel.getLineData(topicHierarchyData, id);
  });

  const initializedReport = report.init(getFilteredTopicIdsWithDescendants(filteredTopicIds, topicsProvider));
  const timezone = report.timezone;
  const searchChannel = searchContentTypeFromChannel(channel);

  const startDate = moment.tz(startAt, timezone);
  const endDate = moment
    .tz(endAt, timezone)
    .add(1, 'd')
    .subtract(1, 'ms');

  const totalsByTopic = getTotalsByTopic(
    initializedReport,
    routingGroupIds,
    searchChannel,
    startDate,
    endDate,
    getTopicTitle
  );

  // prop functions

  function changeTableSort(sortCol, sort) {
    const sortFunc = getSortFunc(initializedReport, topicsProvider, sortCol);
    const ret = _.concat(
      ..._.map(rootsSortedBy(initializedReport, topicsProvider, sort, sortFunc), id =>
        descendants(topicsProvider, id, sort, sortFunc)
      )
    );
    return ret;
  }

  // obviously this isn't a prop function, but putting it here so we don't have to compute descendant every time we get
  // a topic name
  const topicsWithDepth = _.concat(
    ..._.map(roots(initializedReport, topicsProvider), id => descendantsWithDepth(topicsProvider, id, 0))
  );

  function getDescendants(id) {
    if (id === 'ALL_TOPICS' || id === null) {
      return [];
    }
    const topic = topicsProvider.findBy({ id });
    return topic ? topic.descendants : [id];
  }

  function getTopicName(topicId) {
    if (topicId === '') {
      return 'No Topic';
    }
    if (topicId === 'ALL_TOPICS') {
      return 'All Topics';
    }
    const topic = topicsProvider.findBy({ id: topicId });

    const depth = _.get(_.find(topicsWithDepth, { id: topicId }), 'depth');
    let name = topic ? topic.getNameWithAncestry() : '';
    if (topic && depth > 0) {
      name = `${topic.getName()}`;
    }
    return <TopicNameCell depth={depth} name={name} />;
  }

  function getTopicTitle(topicId) {
    if (topicId === '') {
      return 'No Topic';
    }
    if (topicId === 'ALL_TOPICS') {
      return 'All Topics';
    }
    const topic = topicsProvider.findBy({ id: topicId });

    return topic ? topic.getNameWithAncestry() : '';
  }

  return {
    aggregationLevel,
    days,
    changeTableSort,
    endAt,
    endDate,
    getDescendants,
    getTopicName,
    getTopicTitle,
    initializedReport,
    lineData,
    lineIds,
    routingGroupIds,
    searchChannel,
    startAt,
    startDate,
    timezone,
    topicHierarchyData,
    totalsByTopic,
    windowWidth,
  };
}

export default connect(mapStateToProps)(TopicReport);

// helper functions

function descendantsWithDepth(topicsProvider, topicId, depth) {
  const topic = topicsProvider.findBy({ id: topicId });
  if (topic && topic.children && topic.children.length > 0) {
    const ret = _.concat(
      [{ id: topicId, depth }],
      ..._.map(topic.children, id => descendantsWithDepth(topicsProvider, id, depth + 1))
    );
    return ret;
  }
  return [{ id: topicId, depth }];
}

function getFilteredTopicIdsWithDescendants(filteredTopicIds, topicsProvider) {
  if (filteredTopicIds && filteredTopicIds.length > 0) {
    return _.uniq(
      _.concat(
        ..._.map(filteredTopicIds, id => {
          const topic = topicsProvider.findBy({ id });
          return topic ? topic.descendants : [];
        })
      )
    );
  }
  return undefined;
}

function getSortFunc(report, topicsProvider, sortCol) {
  let sortFunc = id => report.getDataForDayAndId(sortCol, id);
  if (sortCol === SharedTableColumns.TOTAL) {
    sortFunc = report.getTotalForId;
  } else if (sortCol === SharedTableColumns.NAME) {
    sortFunc = id => {
      const topic = topicsProvider.findBy({ id });
      return topic ? topic.getNameWithAncestry().toLowerCase() : '';
    };
  }
  return sortFunc;
}

function getTotalsByTopic(report, routingGroupIds, searchChannel, startDate, endDate, getTopicTitle) {
  return _.map(report.getIds(), topicId => {
    const link = createSearchLink({
      routingGroupIds,
      topicIds: [topicId],
      searchChannel,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      hasCreatedFilter: true,
    });
    return {
      label: getTopicTitle(topicId),
      value: report.getTotalForId(topicId),
      link,
    };
  });
}

function roots(report, topicsProvider) {
  const topicIds = report.getIds();
  return _.filter(topicIds, id => {
    const topic = topicsProvider.findBy({ id });
    return !topic || (topic && (!topic.parentId || !_.includes(topicIds, topic.parentId)));
  });
}

function rootsSortedBy(report, topicsProvider, sort, sortFunc) {
  return sortBy(roots(report, topicsProvider), sort, sortFunc);
}
