import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AttributeDef, { AttributeType } from 'models/configuration/attribute_def';
import {
  checkEditableAttributeValue,
  connectEditableCustomAttribute,
  getPercentAttrAsNumber,
} from 'components/customer/summary/lib/custom_attributes_helper';
import CustomAttributeBase from './custom_attribute_base';
import EditableFieldControlled from 'components/lib/editable_field_controlled';
import PercentCustomAttributeGraphic from './percent_custom_attribute_graphic';
import { tryStringify } from 'scripts/lib/try_stringify';

function EditablePercentCustomAttribute({ def, value, renderProps, isDisabled, isResponsePending, onSubmit }) {
  if (def.type !== AttributeType.PERCENT) {
    return null;
  }

  let tooltip = undefined;
  const { isValid, asString } = checkEditableAttributeValue(value, true);
  if (!isValid) {
    const sanitizedValue = _.truncate(tryStringify(value), { length: 25 });
    tooltip = `Unable to display the data. [${sanitizedValue}] may not be a correct value for '${def.label}'`;
  }

  const renderedValue = (
    <EditableFieldControlled
      className={classnames('editableCustomAttribute-input', { error: !isValid })}
      disabled={isDisabled}
      onSubmit={onSubmit}
      pending={isResponsePending}
      readOnly={false}
      title={tooltip}
      value={asString}
    />
  );

  let renderGraphic = null;
  const valAsNumber = getPercentAttrAsNumber(asString);
  if (renderPropsHasHighWaterMark(renderProps) && valAsNumber && !Number.isNaN(valAsNumber)) {
    renderGraphic = <PercentCustomAttributeGraphic highWaterMark={renderProps.highWaterMark} value={valAsNumber} />;
  }

  return (
    <CustomAttributeBase className="editableCustomAttribute" label={def.label}>
      <EditablePercentCustomAttributeBase>
        <EditablePercentCustomAttributePercentage>{renderedValue}</EditablePercentCustomAttributePercentage>
        <EditablePercentCustomAttributePercentBar>{renderGraphic}</EditablePercentCustomAttributePercentBar>
      </EditablePercentCustomAttributeBase>
    </CustomAttributeBase>
  );
}

function renderPropsHasHighWaterMark(renderProps) {
  return renderProps?.highWaterMark !== undefined && !isNaN(renderProps?.highWaterMark);
}

const EditablePercentCustomAttributeBase = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const EditablePercentCustomAttributePercentage = styled.div`
  flex-basis: 25%;
`;

const EditablePercentCustomAttributePercentBar = styled.div`
  flex-basis: 75%;
  padding-left: 10px;
`;

EditablePercentCustomAttribute.propTypes = {
  def: PropTypes.instanceOf(AttributeDef).isRequired,
  value: PropTypes.string,
  renderProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  isResponsePending: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export { EditablePercentCustomAttribute };

const EditablePercentCustomAttributeContainer = connectEditableCustomAttribute(EditablePercentCustomAttribute);
export default EditablePercentCustomAttributeContainer;
