import _ from 'lodash';
import { CancelToken } from 'axios';
import qs from 'querystring';

import StandardGateway from './lib/standard_gateway';

export default class LiveboardGateway {
  constructor(backend) {
    this._gateways = {
      v1: new StandardGateway(backend, 'v1/orgs/:orgId/liveboards/:type'),
      v2: new StandardGateway(backend, 'v2/orgs/:orgId/liveboards/:type'),
    };
  }

  init(opts) {
    _.forIn(this._gateways, gateway => gateway.init(opts));
  }

  fetchAgentsLiveboard(query, config = {}) {
    return this.fetch(this._gateways.v2, 'agents', query, config);
  }

  fetchPeopleMatchLiveboard(query, config = {}) {
    return this.fetch(this._gateways.v1, 'people-match', query, config);
  }

  fetchSummaryLiveboard(query, config = {}) {
    return this.fetch(this._gateways.v1, 'summary', query, config);
  }

  fetchTopicsLiveboard(query, config = {}) {
    return this.fetch(this._gateways.v1, 'topics', query, config);
  }

  fetch(gateway, type, query, config) {
    gateway.source = CancelToken.source();
    config.cancelToken = gateway.source.token;
    return gateway.http.get(requestUrl(gateway.endpoint.set({ type }).fetchUrl, query), config);
  }

  cancelOutstandingRequest() {
    this._gateways.v1.source && this._gateways.v1.source.cancel();
    this._gateways.v2.source && this._gateways.v2.source.cancel();
  }
}

function requestUrl(url, params) {
  let query = qs.stringify(params);
  return `${url}?${query}`;
}
