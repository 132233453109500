import React, { useContext, useEffect, useRef } from 'react';

import analytics from 'scripts/lib/analytics';
import HotkeyContext from 'components/contexts/hotkeys';
import Mousetrap from 'components/lib/mousetrap';
import { setDisplayName } from 'scripts/presentation/hoc_helpers/display_name';

/*
  Add keyboard shortcuts to a component by passing in an array of keys and callbacks. When the key is pressed, the
  callback will be called, with the instance of the base component as the first argument.

  Usage:

  ```
  let DecoratedComponent = withShortcuts(BaseComponent, [
    { key: 'a', callback: (baseComponentInstance) => baseComponentInstance.someMethod() },
    { key: 'b', callback: (baseComponentInstance) => baseComponentInstance.someOtherMethod() },
  ])
  ```
*/

export default function withShortcuts(Component, shortcuts) {
  function ComponentWithShortcuts(props) {
    const { dispatchHotkey } = useContext(HotkeyContext);

    const ref = useRef(null);
    useEffect(() => {
      shortcuts.forEach(({ key, callback, label, group }) => {
        Mousetrap.bind(key, () => {
          callback(ref.current);
          analytics.track('Keyboard Shortcut Used', {
            key,
            label,
            group,
          });
          return false;
        });
        dispatchHotkey({ type: 'add', hotkey: { key, group, label } });
      });

      return () => {
        shortcuts.forEach(({ key }) => {
          Mousetrap.unbind(key);
          dispatchHotkey({ type: 'remove', key });
        });
      };
    }, []);

    return <Component ref={ref} {...props} />;
  }

  setDisplayName(ComponentWithShortcuts, Component, 'WithShortcuts');
  return ComponentWithShortcuts;
}
