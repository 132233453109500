import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import classnames from 'classnames';
import connect from 'components/lib/connect';
import ExpandTransaction from 'actions/customer_profile/expand_transaction';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import FlightTransactions from './transactions/flight_transactions';
import OrderTransactions from './transactions/order_transactions';
import ProfileCardDef from 'models/configuration/profile_card_def';
import ProfileCardType from 'models/configuration/profile_card_type';
import StayTransactions from './transactions/stay_transactions';
import SubscriptionTransactions from './transactions/subscription_transactions';
import Transaction from 'models/transaction';
import {
  FlightTransactionDef,
  GenericTransactionDef,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
  TransactionDefType,
} from 'models/configuration/transaction_def';
import { renderCustomAttributes } from './lib/custom_attributes_helper';

export function CustomerTransaction({ transaction, transactionDef }) {
  if (!transactionDef || !transaction || !transaction.attributes) {
    return null;
  }

  const attrDefs = _.filter(transactionDef.attributes, attrDef => !!transaction.attributes[attrDef.attr]);
  if (!attrDefs.length) {
    return null;
  }

  const transactionType = _.get(transactionDef, 'type');
  return (
    <div className={classnames('customerTransaction', _.toLower(transactionType))}>
      {renderCustomAttributes(attrDefs, transaction.attributes, ProfileCardType.TRANSACTIONS)}
    </div>
  );
}

CustomerTransaction.propTypes = {
  transactionDef: PropTypes.instanceOf(GenericTransactionDef),
  transaction: PropTypes.instanceOf(Transaction),
};

export function CustomerTransactions({
  isLoading,
  transactionDef,
  transactions,
  transactionFilter,
  onExpand,
  profileCardDef,
}) {
  if (!isLoading && (!transactionDef || !transactions)) {
    return null;
  }

  let filteredTransactions = transactions;
  if (transactionFilter && !_.isEmpty(transactionFilter)) {
    filteredTransactions = _.filter(transactions, tx => {
      const txJs = tx.toJs();
      return _.isMatch(txJs.attributes, transactionFilter);
    });
  }

  // Try to honor `transactionDef.type` for non-GENERIC transactions
  const transactionType = _.get(transactionDef, 'type');
  if (transactionType && transactionType !== TransactionDefType.GENERIC) {
    filteredTransactions = _.filter(
      filteredTransactions,
      transaction => transaction.type === undefined || transaction.type === transactionDef.type
    );
  }

  // We don't want to show transactions if there is no data
  if (!filteredTransactions.length) {
    return null;
  }

  switch (transactionType) {
    case TransactionDefType.ORDER:
      return (
        <OrderTransactions
          isLoading={isLoading}
          onExpand={onExpand}
          profileCardDef={profileCardDef}
          transactionDef={transactionDef}
          transactions={filteredTransactions}
        />
      );

    case TransactionDefType.FLIGHT:
      return (
        <FlightTransactions
          isLoading={isLoading}
          onExpand={onExpand}
          profileCardDef={profileCardDef}
          transactionDef={transactionDef}
          transactions={filteredTransactions}
        />
      );

    case TransactionDefType.STAY:
      return (
        <StayTransactions
          isLoading={isLoading}
          now={moment().format()}
          onExpand={onExpand}
          profileCardDef={profileCardDef}
          transactionDef={transactionDef}
          transactions={filteredTransactions}
        />
      );

    case TransactionDefType.SUBSCRIPTION:
      return (
        <SubscriptionTransactions
          isLoading={isLoading}
          onExpand={onExpand}
          profileCardDef={profileCardDef}
          transactionDef={transactionDef}
          transactions={filteredTransactions}
        />
      );

    default:
      break;
  }

  const customerTransactions = _.map(filteredTransactions, transaction => {
    if (!transactionDef) {
      return null;
    }
    const match = _.find(transactionDef.attributes, attrDef => !!transaction.attributes[attrDef.attr]);
    return match ? (
      <CustomerTransaction key={transaction.id} transaction={transaction} transactionDef={transactionDef} />
    ) : null;
  });

  // Remove transactions that we could not render for any reason
  const renderedTransactions = _.filter(customerTransactions, trx => !!trx);
  if (!renderedTransactions.length) {
    return null;
  }

  return (
    <ExpandableProfileCard isLoading={isLoading} title={(profileCardDef && profileCardDef.label) || 'Transactions'}>
      {renderedTransactions}
    </ExpandableProfileCard>
  );
}

CustomerTransactions.propTypes = {
  isLoading: PropTypes.bool,
  onExpand: PropTypes.func,
  profileCardDef: PropTypes.instanceOf(ProfileCardDef),
  transactionDef: PropTypes.oneOfType([
    PropTypes.instanceOf(GenericTransactionDef),
    PropTypes.instanceOf(FlightTransactionDef),
    PropTypes.instanceOf(OrderTransactionDef),
    PropTypes.instanceOf(StayTransactionDef),
    PropTypes.instanceOf(SubscriptionTransactionDef),
  ]),
  transactions: PropTypes.arrayOf(PropTypes.instanceOf(Transaction)),
  transactionFilter: PropTypes.object,
};

function mapStateToProps({ getProvider }, { profileCardDef }) {
  const profileDefProvider = getProvider('customerProfileDef');
  const profileProvider = getProvider('profile');
  const transactionsProvider = getProvider('transactions');

  const profileLoading = profileProvider.isLoading();
  const profileDefLoading = profileDefProvider.isLoading();
  const transactionsLoading = transactionsProvider.isLoading();
  const isLoading = profileLoading || profileDefLoading || transactionsLoading;

  const profileDef = !profileDefLoading ? profileDefProvider.get() : undefined;
  if (!profileDef) {
    return {
      isLoading,
      transactions: [],
    };
  }

  // Fall back on the "legacy" configuration schema for the cases when the customer-profile-def is incomplete
  const legacyTransactionDef = _.get(profileDef, 'transaction');
  const transactionDef = _.get(profileCardDef, 'properties.dataSources.transactions.definition', legacyTransactionDef);
  const transactionFilter = _.get(profileCardDef, 'properties.dataSources.transactions.filter', {});

  return {
    isLoading,
    transactionDef,
    transactions: !transactionsLoading ? transactionsProvider.findAll() : [],
    transactionFilter,
    profileCardDef,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onExpand: ({ isExpanded }) => executeAction(ExpandTransaction, { isExpanded }),
  };
}

const CustomerTransactionContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerTransactions);
export default CustomerTransactionContainer;
