import _ from 'lodash';
import moment from 'moment';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import { AgentInbox, GroupInbox } from './lib/inbox_backend';
import ConversationItemType from 'models/conversation_item_type';
import { InboxSortOrder } from 'models/location/inbox';

export default class InboxService {
  constructor(publishResponse, database = getDatabase) {
    this.publishResponse = publishResponse;
    this.getDatabase = database;
  }

  fetchAgentInbox(orgId, agentId, { conversationStatus, sort, cutoff, limit, unified }) {
    let customers = this.getDatabase(orgId).customers;
    let inbox = new AgentInbox(customers, agentId, conversationStatus, sort, unified).getInbox();
    let queryOffset = inbox.findIndex(this.hasCutoff(sort, new Date(cutoff)));
    let preview = _.flatten(inbox.map(inboxItem => this.convertConversationToPreview(inboxItem)));
    let sortOrder = unified ? 'desc' : sort === InboxSortOrder.LATEST ? 'desc' : 'asc';
    let sortedPreview = _.orderBy(
      preview,
      [
        item => {
          return item.task
            ? moment(item.task.content.updatedAt).valueOf()
            : moment(item.conversation.updatedAt).valueOf();
        },
      ],
      [sortOrder]
    );
    return { previews: this.subset(sortedPreview, queryOffset, limit, unified), cutoff, limit };
  }

  fetchGroupInbox(orgId, groupId, { conversationStatus, sort, cutoff, limit }) {
    let customers = this.getDatabase(orgId).customers;
    let inbox = new GroupInbox(customers, groupId, conversationStatus, sort).getInbox();
    let queryOffset = inbox.findIndex(this.hasCutoff(sort, new Date(cutoff)));
    let preview = _.flatten(inbox.map(inboxItem => this.convertConversationToPreview(inboxItem)));

    return { previews: this.subset(preview, queryOffset, limit), cutoff, limit };
  }

  convertConversationToPreview({ conversation, conversationItems, customer }) {
    let conversationPreview = {
      conversation,
      customer: customer.profile,
      taskCount: (conversationItems || []).filter(isOpenTask).length,
    };

    function isOpenTask(i) {
      return i.content.type === ConversationItemType.TASK && !i.content.closedAt;
    }

    let sortedConversationItems = _.orderBy(conversationItems, [item => moment(item.timestamp).valueOf()], ['asc']);
    let latestConversationItem = _.last(
      sortedConversationItems.filter(
        i =>
          [
            ConversationItemType.CALL_FORWARD,
            ConversationItemType.CHAT_CUSTOMER_URL,
            ConversationItemType.CHAT_ENDED,
            ConversationItemType.CHAT_SELF_SERVICE_METADATA,
            ConversationItemType.CONVERSATION_STATUS_CHANGE,
            ConversationItemType.CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE,
            ConversationItemType.CUSTOMER_ASSIGNEE_CHANGE,
            ConversationItemType.EMAIL_SEND_FAILURE,
            ConversationItemType.NO_REPLY_NEEDED,
            ConversationItemType.ROUTING_ITEM,
            ConversationItemType.TASK_COMPLETION,
            ConversationItemType.TASK_DUE,
            ConversationItemType.TASK_ROUTING,
            ConversationItemType.TOPIC_CHANGE,
          ].indexOf(i.content.type) === -1
      )
    );

    if (latestConversationItem) {
      conversationPreview.conversationItem = this.getItemPreview(latestConversationItem);
    }

    return conversationPreview;
  }

  getItemPreview(latestConversationItem) {
    let preview = _.cloneDeep(latestConversationItem);
    if (preview.content.type === ConversationItemType.EMAIL) {
      preview.content = {
        from: latestConversationItem.content.from,
        excerpt: latestConversationItem.content.bodyHtml || latestConversationItem.content.bodyPlain,
        subject: latestConversationItem.content.subject,
        type: ConversationItemType.EMAIL_PREVIEW,
      };
    }
    return preview;
  }

  getTypeFromContent(content) {
    return content.type;
  }

  subset(preview, offset, limit) {
    if (!limit && offset === -1) {
      return preview;
    }
    if (!limit) {
      return preview.slice(offset);
    }
    if (offset === -1) {
      return preview.slice(0, limit);
    }
    return preview.slice(offset, offset + limit);
  }

  hasCutoff(sort, cutoff) {
    return function(inboxItem) {
      if (cutoff == null) {
        return true;
      }
      if (sort === InboxSortOrder.LATEST) {
        return new Date(inboxItem.conversation.updatedAt) < cutoff;
      }
      return new Date(inboxItem.conversation.updatedAt) > cutoff;
    };
  }
}
