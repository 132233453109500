import AdminNotification from './admin_notification';
import AutoAcceptedSessionNotification from './auto_accepted_session_notification';
import ConversationNotification from './conversation_notification';
import DeclinedReassignedSessionNotification from './declined_reassigned_session_notification';
import DisconnectNotification from './disconnect_notification';
import EmbedTokenExpiringNotification from './embed_token_expiring_notification';
import ErrorNotification from './error_notification';
import FocusModeDisabledNotification from './focus_mode_disabled_notification';
import InboundCommunicationSessionNotification from 'models/notification/inbound_communication_session_notification';
import InboundMessageNotification from 'models/notification/inbound_message_notification';
import MultipleTabsNotification from './multiple_tabs_notification';
import NotificationType from './notification_type';
import PaymentStatusEventNotification from './payment_status_event_notification';
import PhoneCallObserverNotification from './phone_call_observer_notification';
import OfferedCallNotification from './offered_call_notification';
import OfferedWarmTransferNotification from './offered_warm_transfer_notification';
import OptionalReloadNotification from './optional_reload_notification';
import OptionalUpgradeNotification from './optional_upgrade_notification';
import SuccessNotification from 'models/notification/success_notification';
import TaskNotification from './task_notification';
import UnofferedCallNotification from './unoffered_call_notification';
import UnofferedWarmTransferNotification from './unoffered_warm_transfer_notification';

export default [
  [NotificationType.ADMIN, AdminNotification],
  [NotificationType.ASSIGNED_CONVERSATION, ConversationNotification],
  [NotificationType.ASSIGNED_TASK, TaskNotification],
  [NotificationType.AUTO_ACCEPTED_SESSION_NOTIFICATION, AutoAcceptedSessionNotification],
  [NotificationType.DISCONNECT, DisconnectNotification],
  [NotificationType.DECLINED_REASSIGNED_SESSION, DeclinedReassignedSessionNotification],
  [NotificationType.EMBED_TOKEN_EXPIRING, EmbedTokenExpiringNotification],
  [NotificationType.ERROR, ErrorNotification],
  [NotificationType.FOCUS_MODE_DISABLED, FocusModeDisabledNotification],
  [NotificationType.INBOUND_COMMUNICATION_SESSION, InboundCommunicationSessionNotification],
  [NotificationType.INBOUND_MESSAGE, InboundMessageNotification],
  [NotificationType.MULTIPLE_TABS, MultipleTabsNotification],
  [NotificationType.PAYMENT_STATUS_EVENT, PaymentStatusEventNotification],
  [NotificationType.PHONE_CALL_OBSERVER, PhoneCallObserverNotification],
  [NotificationType.OFFERED_CALL, OfferedCallNotification],
  [NotificationType.OFFERED_WARM_TRANSFER, OfferedWarmTransferNotification],
  [NotificationType.OPTIONAL_RELOAD, OptionalReloadNotification],
  [NotificationType.OPTIONAL_UPGRADE, OptionalUpgradeNotification],
  [NotificationType.SUCCESS, SuccessNotification],
  [NotificationType.UNOFFERED_CALL, UnofferedCallNotification],
  [NotificationType.UNOFFERED_WARM_TRANSFER, UnofferedWarmTransferNotification],
];
