import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import take from 'lodash/take';

import AddInCircleStrokeIcon from 'components/common/icons/stroke/add-in-circle-stroke';
import connect from 'components/lib/connect';
import PopoverMenu, { PopoverMenuItem } from 'components/common/menu';
import RemoveEmailSuggestion from 'actions/email_suggestions/remove_email_suggestion';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export function EmailSearchMenu({ emailSuggestions, existingEmails, selectEmailSuggestion, text, textareaRef }) {
  const executeAction = useExecuteAction();

  const filteredSuggestions = useMemo(() => {
    return filter(emailSuggestions, suggestion => !includes(existingEmails, suggestion.email)).map(s => ({
      email: s.email,
      count: s.count,
    }));
  }, [emailSuggestions, existingEmails]);
  const searchSuggestions = useMemo(() => {
    const fromText = filter(filteredSuggestions, suggestion =>
      suggestion.email.toLowerCase().startsWith(text.toLowerCase())
    );
    return sortBy(take(fromText, 5), s => 0 - s.count);
  }, [filteredSuggestions, text]);

  const isOpen = !!text && searchSuggestions.length;
  const [focusedIndex, setFocusedIndex, forceClose] = useKeyboardNavigation(
    textareaRef,
    text,
    isOpen,
    searchSuggestions,
    selectEmailSuggestion
  );

  if (!isOpen || forceClose) {
    return null;
  }

  return (
    <PopoverMenu isOpen position="top" targetPosition="start" targetRef={textareaRef.current}>
      {map(searchSuggestions, (suggestion, index) => (
        <StyledMenuItem
          isHovered={focusedIndex === index}
          key={suggestion.email}
          onMouseDown={evt => {
            stopEvent(evt);
            selectEmailSuggestion(suggestion.email);
          }}
          onMouseOver={() => {
            setFocusedIndex(index);
          }}
        >
          {suggestion.email}
          <RemoveIcon
            onMouseDown={evt => {
              stopEvent(evt);
              executeAction(RemoveEmailSuggestion, { email: suggestion.email });
            }}
          />
        </StyledMenuItem>
      ))}
    </PopoverMenu>
  );
}

const EmailSearchMenuContainer = connect(mapStateToProps)(EmailSearchMenu);

function mapStateToProps({ getProvider }, { existingEmails }) {
  const emailSuggestions = getProvider('emailSuggestions').findAll();
  return {
    emailSuggestions,
  };
}

export default EmailSearchMenuContainer;

function useKeyboardNavigation(textareaRef, text, isEnabled, searchSuggestions, selectEmailSuggestion) {
  const [focusIndex, setFocusIndex] = useState(0);
  const [isEscaped, setIsEscaped] = useState(false);

  // When the search results change, we want to refocus the top item
  useEffect(() => {
    setFocusIndex(0);
  }, [searchSuggestions]);

  // When the text changes, we want to re-open the menu if it was hidden
  useEffect(() => {
    setIsEscaped(false);
  }, [text]);

  useEffect(() => {
    if (isEnabled) {
      const listener = e => {
        if (e.key === 'ArrowDown') {
          if (focusIndex < searchSuggestions.length - 1) {
            stopEvent(e);
            setFocusIndex(focusIndex + 1);
          }
        } else if (e.key === 'ArrowUp') {
          if (focusIndex > 0) {
            stopEvent(e);
            setFocusIndex(focusIndex - 1);
          }
        } else if (e.key === 'Enter') {
          stopEvent(e);
          selectEmailSuggestion(searchSuggestions[focusIndex].email);
          setFocusIndex(0);
        } else if (e.key === 'Escape' && isEnabled && !isEscaped) {
          stopEvent(e);
          setIsEscaped(true);
        }
      };
      textareaRef.current.addEventListener('keydown', listener);
      const ref = textareaRef.current;
      return () => ref.removeEventListener('keydown', listener);
    }
  }, [focusIndex, isEnabled, isEscaped, searchSuggestions, selectEmailSuggestion, textareaRef]);

  const setFocusedIndex = useCallback(index => {
    setFocusIndex(index);
  }, []);

  return [focusIndex, setFocusedIndex, isEscaped];
}

function stopEvent(e) {
  e.preventDefault();
  e.stopPropagation();
}

const StyledMenuItem = styled(PopoverMenuItem)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RemoveIcon = styled(AddInCircleStrokeIcon)`
  height: 12px;
  margin-left: 16px;
  margin-right: -4px;
  transform: rotate(45deg);
  width: 12px;

  &:hover {
    fill: ${p => p.theme.colors.red400};
  }
`;
