import React from 'react';
import { Decoration, Mark } from 'slate-old';

import EmailHighlight from './email_highlight';
import EmailHighlightMenu from './email_highlight_menu';

export default function EmailHighlighting({ onChange }) {
  return {
    decorateNode(node, editor, next) {
      const others = next() || [];

      let decorations = [];
      const textNodes = node.getTexts();
      textNodes.forEach(textNode => {
        const emailRanges = findEmailRanges(textNode);
        emailRanges.forEach(range => {
          if (isConflictingRange(range, others)) {
            return;
          }
          decorations.push(
            Decoration.create({
              anchor: {
                key: textNode.key,
                offset: range.start,
              },
              focus: {
                key: textNode.key,
                offset: range.end,
              },
              mark: Mark.create({
                type: 'emailHighlight',
                isAtomic: true,
                data: {
                  text: range.word,
                },
              }),
            })
          );
        });
      });

      return [...others, ...decorations];
    },

    renderEditor(props, editor, next) {
      const children = next();

      return (
        <React.Fragment>
          {children}
          <EmailHighlightMenu />
        </React.Fragment>
      );
    },

    renderMark(props, editor, next) {
      const { attributes, children, mark, node, offset, text } = props;

      if (mark.type === 'emailHighlight') {
        return (
          <EmailHighlight
            editor={editor}
            end={offset + text.length}
            node={node}
            onChange={onChange}
            start={offset}
            text={text}
            {...attributes}
          >
            {children}
          </EmailHighlight>
        );
      }
      return next();
    },
  };
}

function findEmailRanges(textNode) {
  const EMAIL_REGEX = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  const text = textNode.text;

  let ranges = [];
  let match = EMAIL_REGEX.exec(text);
  while (match !== null) {
    ranges.push({
      start: match.index,
      end: match.index + match[0].length,
      word: match[0],
    });
    match = EMAIL_REGEX.exec(text);
  }

  return ranges;
}

// Do not include email ranges that match existing SIP address ranges. SIP addresses
// and email addresses can look similar and match both regex. When a match is found for both
// SIP and email addresses, we want to only have a single decoration for the SIP address.
// This is to prevent decorated SIP addresses from being split into 2 decorators where the
// 'copy to clipboard' functionality incorrectly includes only the `sip:` prefix.
function isConflictingRange(range, others) {
  let isConflicting = false;
  others.forEach(decoration => {
    if (isConflicting) {
      return;
    }
    if (!decoration.mark || !decoration.mark.data) {
      return false;
    }
    let existingMark = decoration.mark.data.toJS();
    if (
      decoration.mark.type === 'sipHighlight' &&
      existingMark.text &&
      existingMark.text.includes(range.word) &&
      decoration.focus.offset === range.end
    ) {
      isConflicting = true;
    }
  });
  return isConflicting;
}
