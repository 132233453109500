import _ from 'lodash';

import Communicator from 'models/communicator';
import Factory from 'factories/all';

export default function createIncomingTweetItem(incomingTweet, agent) {
  return Factory.build(
    'TwitterItem',
    _.assign({}, incomingTweet.content, {
      initiator: {
        type: Communicator.CUSTOMER,
        id: incomingTweet.customerId,
      },
    })
  );
}
