import createModel, { prop } from '../lib/create_model';

const EmbedTokensReport = createModel({
  modelName: 'EmbedTokensReport',
  properties: {
    apiToken: prop(String),
    apiTokenTtl: prop(Number),
    navigationToken: prop(String),
    navigationTokenTtl: prop(Number),
    sessionReferenceTokenTtl: prop(Number),
    tokenExpiry: prop(String),
    metricSet: prop(String),
    supportedFilters: prop([String]),
    timezone: prop(String).default('America/New_York'),
    url: prop(String),
  },

  setSessionReferenceTokenTtl(sessionReferenceTokenTtl) {
    this.sessionReferenceTokenTtl = sessionReferenceTokenTtl;
  },

  setUrl(url) {
    this.url = url;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default EmbedTokensReport;
