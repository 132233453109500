import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import createEnum from 'scripts/lib/create_enum';

export const SessionEndReason = createEnum(
  'AGENT_ENDED',
  'CONVERSATION_CLOSED',
  'CUSTOMER_NO_REPLY',
  'MERGED',
  'NO_REPLY_NEEDED',
  'STARTED_NEW',
  'TIME_EXPIRED'
);

const SessionEnded = createModel({
  modelName: 'SessionEnded',

  properties: {
    sessionId: prop(String).isRequired,
    sessionType: prop(String),
    reason: prop().oneOf(..._.keys(SessionEndReason)),
    // optional timeout, the units depend on the reason
    // - for CUSTOMER_NO_REPLY the timeout value is given in minutes
    // - for TIME_EXPIRED the timeout value is given in hours
    // - for other reasons the timeout isn't applicable and not provided
    timeout: prop(Number),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default SessionEnded;
