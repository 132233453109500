import _ from 'lodash';
import React, { useEffect, useMemo, useReducer, useRef } from 'react';

import AgentMentionList from './agent_mention_list';
import AgentSearch from 'components/agents/agent_search';
import { AGENT_MENTION_ACTIVATION_CHARACTER } from './agent_mention_plugin';
import connect from 'components/lib/connect';
import { useTextEditorContext } from 'components/text_editor/text_editor_context';

export default function AgentMentionMenu({
  autoPosition,
  height,
  mentionSearchRef,
  menuActionsRef,
  position,
  readOnly,
}) {
  const { editorRef } = useTextEditorContext();
  const editor = editorRef.current;

  const itemsRef = useRef(null);
  const [selectedIndex, dispatch] = useRegisterMenuActions(editorRef, menuActionsRef, itemsRef);

  if (readOnly || !editor) {
    return null;
  }

  const agentMentionText = editor.getAgentMentionText(AGENT_MENTION_ACTIVATION_CHARACTER);
  if (agentMentionText == null) {
    return null;
  }

  return (
    <AgentSearcherContainer
      autoPosition={autoPosition}
      dispatch={dispatch}
      editorRef={editorRef}
      height={height}
      itemsRef={itemsRef}
      mentionSearchRef={mentionSearchRef}
      position={position}
      searchText={agentMentionText}
      selectedIndex={selectedIndex}
    />
  );
}

// The AgentMentionPlugin implements an onKeyDown handler which, if we're inserting a mention, will intercept
// up/down arrows and enter keys. But the selected index state lives inside these components. Components don't
// normally communicate "down" the tree, they usually communicate "up" via callbacks.

// The conventional way of handling this communication is hoisting up the `selectedIndex` state so that both
// this component and the editor can see / modify this data.. but I'm avoiding this for two reasons:
//
// 1. Conpetually, it doesn't make sense to me to pollute the base TextEditor component with plugin specific logic,
//    as the whole point of the plugin architecture to is to isolate functionality from the core editor.
// 2. Updating this shared state frequently will cause unnecessary re-renders of the component, which may lead to
//    slugging performance.

// Long story short, I register these onKeyDown handlers on a _ref_ that is passed to the AgentMentionPlugin.. so when
// it intercepts these events, it can just call these handlers directly on the ref.
function useRegisterMenuActions(editorRef, menuActionsRef, itemsRef) {
  const [selectedIndex, dispatch] = useReducer(selectionReducer, 0);

  // Update selectedIndex inside a ref so that we don't have to re-create the menuActionsRef
  // onInsert callback each time the selectedIndex changes. The onInsert callback can instead just
  // grab the most recent index from the ref.
  const selectedIndexRef = useRef(selectedIndex);
  useEffect(() => {
    selectedIndexRef.current = selectedIndex;
  }, [selectedIndex]);

  // Register the callbacks here so the editor can communicate to us that the selected index should change
  useEffect(() => {
    menuActionsRef.current = {
      onArrowDown: () => {
        dispatch({ type: 'down', numItems: itemsRef.current.length });
      },
      onArrowUp: () => {
        dispatch({ type: 'up' });
      },
      onInsert: () => {
        const item = itemsRef.current[selectedIndexRef.current];
        if (!item) {
          return false;
        }
        editorRef.current.completeAgentMentionSearch(itemsRef.current[selectedIndexRef.current]);
        return true;
      },
    };
  }, [menuActionsRef]);

  return [selectedIndex, dispatch];
}

// Use a reducer so that we don't have to recreate the callback each time the selection changes
function selectionReducer(state, action) {
  switch (action.type) {
    case 'up':
      return state > 0 ? state - 1 : 0;
    case 'down':
      return state < action.numItems - 1 ? state + 1 : state;
    case 'set':
      return action.index;
    default:
      throw new Error();
  }
}

const AgentSearcherContainer = connect(mapStateToProps)(AgentSearcher);

function mapStateToProps({ getProvider }) {
  const agentsProvider = getProvider('agents');
  const recentlyMentionedAgentIds = getProvider('recentlyMentionedAgentIds').findAll();
  const sortedRecents = _.sortBy(recentlyMentionedAgentIds, r => r.updatedAt).reverse();
  const recentlyMentionedAgents = _.compact(
    _.map(sortedRecents, recent => {
      return agentsProvider.findBy({ id: recent.id });
    })
  );

  return {
    currentAgentId: getProvider('currentAgent').get().id,
    recentlyMentionedAgents,
  };
}

export function AgentSearcher({
  autoPosition,
  currentAgentId,
  dispatch,
  editorRef,
  height,
  itemsRef,
  mentionSearchRef,
  position,
  recentlyMentionedAgents,
  searchText,
  selectedIndex,
}) {
  const excludedAgentIds = useMemo(() => {
    return [currentAgentId];
  }, [currentAgentId]);

  return (
    <AgentSearch excludedAgentIds={excludedAgentIds}>
      {({ agentSearch }) => (
        <AgentMentionList
          autoPosition={autoPosition}
          dispatch={dispatch}
          editorRef={editorRef}
          height={height}
          itemsRef={itemsRef}
          mentionSearchRef={mentionSearchRef}
          position={position}
          recentlyMentionedAgents={recentlyMentionedAgents}
          search={agentSearch}
          searchText={searchText}
          selectedIndex={selectedIndex}
        />
      )}
    </AgentSearch>
  );
}
