import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="-2 -2 19 19" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#999" strokeLinejoin="round">
      <path
        d="M2.25 8.565c-.552 0-1-.467-1-1.043 0-.577.448-1.044 1-1.044M8.25 12.217h1c.967 0 1.75-.556 1.75-1.565v-.555"
        strokeLinecap="round"
        strokeWidth=".81"
      />
      <path
        d="M8.25 12.217c0 .432-.336.783-.75.783s-.75-.35-.75-.783c0-.432.336-.782.75-.782s.75.35.75.782zM4.25 10.13h-1c-.552 0-1-.467-1-1.043v-3.13c0-.577.448-1.044 1-1.044h1v5.217z"
        fill="#999"
        strokeLinecap="round"
        strokeWidth=".5"
      />
      <path d="M11.75 8.565c.553 0 1-.467 1-1.043 0-.577-.447-1.044-1-1.044" strokeLinecap="round" strokeWidth=".81" />
      <path
        d="M9.75 10.13h1c.553 0 1-.467 1-1.043v-3.13c0-.577-.447-1.044-1-1.044h-1v5.217z"
        fill="#999"
        strokeLinecap="round"
        strokeWidth=".5"
      />
      <path d="M3.5 4.913c0-2.017 1.567-3.652 3.5-3.652s3.5 1.635 3.5 3.652" strokeWidth=".81" />
    </g>
  </IconContainer>
);
