import _ from 'lodash';
import createModel, { prop } from '../lib/create_model';

import ChatAddressContent from 'models/customer_profile/chat_address_content';
import CustomChannelAddressContent from 'models/customer_profile/custom_channel_address_content';
import InstagramCustomerAddressContent from 'models/customer_profile/instagram_customer_address_content';

export const ExternalCustomerAddressType = Object.freeze({
  CHAT: 'CHAT',
  CUSTOM_CHANNEL: 'CUSTOM_CHANNEL',
  INSTAGRAM_DIRECT: 'INSTAGRAM_DIRECT',
  TWITTER: 'TWITTER',
  WHATSAPP: 'WHATSAPP',
});

export default createModel({
  modelName: 'ExternalCustomerAddress',
  properties: {
    id: prop(String).isRequired,
    type: prop().oneOf(..._.values(ExternalCustomerAddressType)).isRequired, // type of address i.e. Twitter, Whatsapp
    displayName: prop(String).isRequired, // display name to show
    content: prop().oneOf(CustomChannelAddressContent, InstagramCustomerAddressContent, ChatAddressContent),
  },

  statics: {
    overrideFromJs(fromJs) {
      return attrs => {
        let content;
        switch (attrs.type) {
          case ExternalCustomerAddressType.CUSTOM_CHANNEL:
            content = CustomChannelAddressContent.fromJs(attrs.content);
            break;
          case ExternalCustomerAddressType.INSTAGRAM_DIRECT:
            content = InstagramCustomerAddressContent.fromJs(attrs.content);
            break;
          case ExternalCustomerAddressType.CHAT:
            content = ChatAddressContent.fromJs(attrs.content);
            break;
          default:
            break;
        }

        return new this({ ...fromJs(_.omit(attrs, 'content')), content });
      };
    },
  },
});
