import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class RelationshipService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  add(relationshipAttrs, callback, path, { orgId, customerId }) {
    const attrs = _.merge(
      { customerProfile: this._getLinkedCustomerProfile(orgId, relationshipAttrs.customerId) },
      relationshipAttrs
    );
    let relationships = this._getRelationships(orgId, customerId);
    relationships.push(attrs);

    callback(null, { status: 200, statusText: statusText(200), response: attrs });
  }

  remove(relationshipAttrs, callback, path, { orgId, customerId, relationshipId }) {
    let relationships = this._getRelationships(orgId, customerId);
    _.remove(relationships, { id: relationshipId });

    callback(null, { status: 200, statusText: statusText(200) });
    this._pubsub.publish(`v1/orgs/${orgId}/customers/${customerId}/relationships/${relationshipId}/event/delete`, {
      topicParams: {
        customerId,
        relationshipId,
      },
    });
  }

  find(relationshipAttrs, callback, path, { orgId, customerId }) {
    let relationships = this._getRelationships(orgId, customerId);

    callback(null, { status: 200, statusText: statusText(200), response: relationships });
  }

  update(relationshipAttrs, callback, path, { orgId, customerId, relationshipId }) {
    let relationships = this._getRelationships(orgId, customerId);
    let relationship = _.find(relationships, { id: relationshipId });

    if (!relationship) {
      callback(null, { status: 400, statusText: statusText(400) });
      return;
    }

    _.assign(relationship, relationshipAttrs);
    callback(null, { status: 200, statusText: statusText(200) });
  }

  _getLinkedCustomerProfile(orgId, customerId) {
    let database = this.getDatabase(orgId);
    let customer = _.find(database.customers, { id: customerId });
    return { name: _.get(customer, 'profile.name') };
  }

  _getRelationships(orgId, customerId) {
    let database = this.getDatabase(orgId);

    let customer = _.find(database.customers, { id: customerId });
    let relationships = _.get(customer, 'relationships');

    if (!relationships) {
      customer.relationships = [];
    }
    return _.get(customer, 'relationships');
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customers/:customerId/relationships': {
          POST: this.add,
          GET: this.find,
        },
        '/api/v1/orgs/:orgId/customers/:customerId/relationships/:relationshipId': {
          DELETE: this.remove,
          PATCH: this.update,
        },
      },
      this
    );
  }
}
