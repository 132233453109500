import _ from 'lodash';

import { AccountType } from 'models/account';
import { AgentParticipantStatus } from 'models/routing_queue_item';
import { getActiveChatSessionByCustomer } from 'actions/communication_session/session_finder';
import connect from 'components/lib/connect';
import CreditCardRequestButton from 'components/composer/chat/credit_card_request_button';
import InteractionType from 'models/composition/interaction_type';

function mapStateToProps({ getProvider }) {
  const accounts = getProvider('accounts');
  const activeSecureDataRequests = getProvider('activeSecureDataRequests');
  const activeSessionsProvider = getProvider('activeSessions');
  const channelConfiguration = getProvider('channelConfiguration').get();
  const currentAgent = getProvider('currentAgent').get();
  const customer = getProvider('profile').get();

  const customerId = customer.id;
  const isVGSEnabled = !!accounts.findBy({ type: AccountType.VGS });

  let hasActiveSecureDataRequests = _hasActiveSecureDataRequests(activeSecureDataRequests);
  const activeSession = getActiveChatSessionByCustomer(activeSessionsProvider, customerId);
  let agentIsExistingChatParticipant = _isAgentExistingChatParticipant(activeSession, currentAgent.id);
  let paymentIsEnabledForEndpoint = false;

  if (activeSession) {
    const activeChatSession = _getActiveChatSession(activeSession);

    if (activeChatSession) {
      paymentIsEnabledForEndpoint = _isPaymentEnabledForEndpoint(
        channelConfiguration.endpoints,
        activeChatSession.endpointAddress
      );
    }
  }
  return {
    agentIsExistingChatParticipant,
    hasActiveSecureDataRequests,
    isVGSEnabled,
    paymentIsEnabledForEndpoint,
  };
}

function _hasActiveSecureDataRequests(activeSecureDataRequests) {
  return activeSecureDataRequests && activeSecureDataRequests.findAll().length !== 0;
}

function _isAgentExistingChatParticipant(activeSession, currentAgentId) {
  return !!(
    activeSession && activeSession.queueItem.getAgentParticipantStatus(currentAgentId) === AgentParticipantStatus.ACTIVE
  );
}

function _getActiveChatSession(activeSession) {
  return activeSession.queueItem.getSessionByType(InteractionType.CHAT) || {};
}

function _isPaymentEnabledForEndpoint(endpoints, endpointAddress) {
  const endpoint = _.find(endpoints, ep => ep.address === endpointAddress) || {};
  const paymentRequestEnabledFor = _.get(endpoint, 'configuration.paymentRequestEnabledFor') || [];
  return !_.isEmpty(paymentRequestEnabledFor);
}

export default connect(mapStateToProps)(CreditCardRequestButton);
