import React, { useContext } from 'react';

import { AnswersListType } from 'models/answer_panel';
import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import AnswerPanelDefault from 'components/customer/answer_panel/answer_panel_default';
import AnswerPreviewHeader from 'components/customer/answer_panel/preview/answer_preview_header';
import AnswerPreviewChannels from 'components/customer/answer_panel/preview/answer_preview_channels';
import AnswerPreviewContent from 'components/customer/answer_panel/preview/content';
import EmptyAnswers from 'components/customer/answer_panel/empty_answers';
import LoadingSuggestedReply from 'components/customer/answer_panel/suggested_reply/loading_suggested_reply';
import SuggestedReplyChannels from 'components/customer/answer_panel/suggested_reply/suggested_reply_channels';
import SuggestedReplyContent from 'components/customer/answer_panel/suggested_reply/suggested_reply_content';
import useTrackAnswerView from 'components/customer/answer_panel/use_track_answer_view';

export default function AnswerDisplay() {
  const { answersList, selectedAnswer, selectedAnswerType, selectedAnswerLanguage, suggestedReply } = useContext(
    AnswerPanelContext
  );
  useTrackAnswerView(selectedAnswer, selectedAnswerType, selectedAnswerLanguage);

  if (selectedAnswer) {
    return (
      <>
        <AnswerPreviewHeader />
        <AnswerPreviewContent />
        <AnswerPreviewChannels />
      </>
    );
  }

  if (suggestedReply?.isLoading) {
    return (
      <>
        <LoadingSuggestedReply />
        <SuggestedReplyChannels />
      </>
    );
  }

  if (suggestedReply?.content) {
    return (
      <>
        <SuggestedReplyContent />
        <SuggestedReplyChannels />
      </>
    );
  }

  if (answersList.type === AnswersListType.SEARCH && !answersList.answers.length) {
    return (
      <>
        <EmptyAnswers />
        <SuggestedReplyChannels />
      </>
    );
  }

  return (
    <>
      <AnswerPanelDefault />
      <SuggestedReplyChannels />
    </>
  );
}
