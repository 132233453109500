import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import Factory from 'factories/all';
import InteractionType from 'models/interaction_type';

export default class IncomingSmsSimulator extends CommunicationSimulator {
  constructor({ orgId, publisher, services }, database) {
    super({ attribute: 'incomingSms', createItem: createSmsItem, orgId, publisher, services }, database);
  }

  _createRoutingItem(attrs) {
    return Factory.build(
      'RoutingItemForInbound',
      this._getRoutingItemAttrs({ channel: InteractionType.SMS, ...attrs })
    );
  }
}

function createSmsItem(incomingSms) {
  return Factory.build('SmsMessageItem', {
    initiator: {
      type: Communicator.CUSTOMER,
      id: incomingSms.customerId,
    },
    content: incomingSms.content,
  });
}
