import _ from 'lodash';

import connect from 'components/lib/connect';
import UniversalSearchFilter from 'components/search/universal_search_filter';

const mapStateToProps = function(context, props) {
  const result = context.getProvider('universalSearchResults').findBy({ id: props.queryId });
  const aggregations = result ? result.aggregations : null;
  const documentType = result ? _.get(result, 'query.filter.type') : null;

  return {
    aggregations,
    documentType,
    hasResult: result ? !result.isLoading : false,
    onSearchFilterChange: props.onSearchFilterChange,
    queryId: props.queryId,
  };
};

export default connect(mapStateToProps)(UniversalSearchFilter);
