import React from 'react';
import Icon from 'components/lib/icons/icon_container';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0zm1 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-5.176-2.824a.6.6 0 0 1 0 .848L8.85 8l1.975 1.976a.6.6 0 0 1-.848.848L8 8.85l-1.976 1.975a.6.6 0 0 1-.848-.848L7.15 8 5.176 6.024a.6.6 0 1 1 .848-.848L8 7.15l1.976-1.975a.6.6 0 0 1 .848 0z"
      fillRule="evenodd"
    />
  </Icon>
);
