import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import connect from 'components/lib/connect';
import Link from 'components/common/link';
import SummaryCard, { SummaryHeader } from 'components/customer/summary/lib/summary_card';

function CustomerBulkEvents({ customAttributes, def, showBulkEvents, type }) {
  if (!def || !showBulkEvents) {
    return null;
  }

  let bulkEvents = _.get(customAttributes, 'bulkEvents');
  if (_.isEmpty(bulkEvents)) {
    return null;
  }

  return (
    <SummaryCard>
      {def.label && <SummaryHeader text={`${def.label}`} />}
      <div className={'customEvents-bulkEvents-header'}>
        <div className="customEvents-listWrapper">
          <div className={classnames('customEvents-list')}>{bulkEvents.map(renderEvent)}</div>
        </div>
      </div>
      <div className="customEvents-bulkEvents-expandedSection">
        <div className="customEvents-bulkEvents-expandedSection-spacer" />
        <div className="customEvents-bulkEvents-expandedSection-expand">⌵</div>
        <div className="customEvents-bulkEvents-expandedSection-spacer" />
      </div>
    </SummaryCard>
  );

  function renderEvent(event, index) {
    let bulkEventDef = _.get(def, 'row');
    return (
      <div className={'customEvents-bulkEvents'} key={index}>
        <div className="customEvents-bulkEvents-image-wrapper">
          <img className="customEvents-bulkEvents-image" src={_.get(event, 'image')} />
        </div>
        <div className="customEvents-bulkEvents-details-wrapper">
          <div className="customEvents-bulkEvents-details-header">
            <Link className="customEvents-bulkEvents-details-header-url" href={event[_.get(bulkEventDef, 'url.attr')]}>
              {event[_.get(bulkEventDef, 'title.attr')]}
            </Link>
          </div>
          <div className="customEvents-bulkEvents-details">
            <div className="customEvents-bulkEvents-details-row">
              <div className="customEvents-bulkEvents-detail">
                <div>{event[_.get(bulkEventDef, 'descriptionOne.attr')]}</div>
                <div className="customEvents-bulkEvents-detail-expand">&gt;</div>
              </div>
            </div>
            <div className="customEvents-bulkEvents-details-row">
              <div className="customEvents-bulkEvents-detail">
                {event[_.get(bulkEventDef, 'descriptionTwo.attr')]} –{' '}
                {event[_.get(bulkEventDef, 'descriptionThree.attr')]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomerBulkEvents.propTypes = {
  customAttributes: PropTypes.object.isRequired,
  def: PropTypes.object,
  showBulkEvents: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export { CustomerBulkEvents };

const CustomerBulkEventsContainer = connect(mapStateToProps)(CustomerBulkEvents);

function mapStateToProps({ getProvider, isFeatureEnabled }, { type }) {
  return {
    customAttributes: _.get(getProvider('profile').get(), 'customAttributes') || {},
    def: _.get(getProvider('customerProfileDef').get(), type),
    showBulkEvents: isFeatureEnabled('demoMode'),
    type,
  };
}

CustomerBulkEventsContainer.propTypes = {
  customAttributes: PropTypes.object,
  def: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default CustomerBulkEventsContainer;
