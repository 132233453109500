import PropTypes from 'prop-types';

import AttachmentsDownloadAll from 'components/lib/attachment/attachments_download_all';
import connect from 'components/lib/connect';

const AttachmentsDownloadAllContainer = connect(mapStateToProps)(AttachmentsDownloadAll);

AttachmentsDownloadAllContainer.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  itemId: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string.isRequired,
};

function mapStateToProps(context) {
  let orgId = context
    .getProvider('auth')
    .get()
    .getOrgId();

  const profile = context.getProvider('profile').get();
  const customerName = profile ? profile.name || profile.getDefaultEmail() || profile.id : '';
  return {
    customerName,
    orgId,
  };
}

export default AttachmentsDownloadAllContainer;
