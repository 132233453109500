import _ from 'lodash';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import UniversalSearchBar from 'components/search/universal_search_bar';

const UniversalSearchBarContainer = connect(mapStateToProps)(UniversalSearchBar);

UniversalSearchBarContainer.propTypes = {
  onSearchTextChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

function mapStateToProps(context, props) {
  let currentSearchResultsId = context.getProvider('currentLocation').get().currentResultId;
  const result = context.getProvider('universalSearchResults').findBy({ id: currentSearchResultsId });
  const queryText = result ? _.get(result, 'query.text') : '';

  return {
    initialText: queryText,
    onSubmit: props.onSearchTextChange,
    placeholder: props.placeholder,
  };
}

export default UniversalSearchBarContainer;
