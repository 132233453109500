import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Takes a timestamp and renders either:
 *   "Today" or "Yesterday"
 *   "Feb 09" if timestamp is during this year (or "February 09" if `displayFullMonth` is true)
 *   "Feb 09 2015" if not during this year
 */

export default function ShortLongDate({ className, timestamp, displayFullMonth }) {
  const datetime = moment(timestamp);
  const monthFormat = displayFullMonth ? 'MMMM' : 'MMM';

  let value;
  // If datetime is within this year, render "Feb 09"
  if (
    moment()
      .startOf('year')
      .isBefore(datetime)
  ) {
    // But use "today" or "yesterday"
    if (moment().isSame(datetime, 'day')) {
      value = 'Today';
    } else if (
      moment()
        .subtract(1, 'day')
        .isSame(datetime, 'day')
    ) {
      value = 'Yesterday';
    } else {
      value = datetime.format(`${monthFormat} DD`);
    }
    // Otherwise use fully qualified date "Feb 09 2015"
  } else {
    value = datetime.format(`${monthFormat} DD YYYY`);
  }

  return <span className={className}>{value}</span>;
}

ShortLongDate.propTypes = {
  className: PropTypes.string,
  displayFullMonth: PropTypes.bool,
  timestamp: PropTypes.string.isRequired,
};
