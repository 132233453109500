import _ from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import CloseCurrentComposition from 'actions/composition/close_current_composition';
import Composer, { Header, HeaderText } from './shared/composer';
import ComposerContext from 'components/composer/contexts/composer_context';
import ShowCreditCardAmountConfirmationModal from 'actions/composer/show_credit_card_amount_confirmation_modal';
import TextInput from 'components/common/text_input';
import UpdateComposition from 'actions/composer/update_composition';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const MAX_MEMO_LENGTH = 128;
const MAX_AMOUNT = 1000000000;

export default function CreditCardComposer() {
  const { composition } = useContext(ComposerContext);
  const [memo, setMemo] = useState(composition.content.memo);
  const [amount, setAmount] = useState(composition.content.amount);
  const [memoError, setMemoError] = useState('');
  const executeAction = useExecuteAction();

  const onCancel = useCallback(() => executeAction(CloseCurrentComposition), []);
  const onSubmit = useCallback(() => executeAction(ShowCreditCardAmountConfirmationModal, { amount, memo }), [
    amount,
    memo,
  ]);

  const handleAmountChange = useCallback(values => {
    setAmount(values.floatValue);
  }, []);

  const handleMemoChange = useCallback(event => {
    let updatedMemo = event.target.value;
    let memoLength = updatedMemo.length;
    if (memoLength > MAX_MEMO_LENGTH) {
      setMemoError('Max characters reached');
      return;
    }
    setMemoError('');
    setMemo(updatedMemo);
  }, []);

  const handleBlur = useCallback(() => {
    executeAction(UpdateComposition, {
      attrs: { amount, memo },
      compositionId: composition.id,
      customerId: composition.customerId,
    });
  }, [amount, memo]);

  const isAllowed = useCallback(values => {
    const newAmount = values.floatValue;
    return !newAmount || (newAmount >= 0 && newAmount < MAX_AMOUNT);
  }, []);

  return (
    <Composer disableSubmit={!_.isNumber(amount)} onCancel={onCancel} onSubmit={onSubmit} submitText="Request">
      <Header>
        <HeaderTitle>Request Credit Card</HeaderTitle>
      </Header>
      <ContentContainer>
        <InputContainer>
          <InputLabel>Amount to charge</InputLabel>
          <NumberFormat
            allowNegative={false}
            autoFocus
            customInput={Input}
            decimalScale={2}
            fixedDecimalScale
            isAllowed={isAllowed}
            onBlur={handleBlur}
            onValueChange={handleAmountChange}
            prefix={'$ '}
            thousandSeparator
            value={amount}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Memo</InputLabel>
          <Input onBlur={handleBlur} onChange={handleMemoChange} value={memo} />
        </InputContainer>
        <InputContainer>
          <InputLabel />
          <InputError>{memoError}</InputError>
        </InputContainer>
      </ContentContainer>
    </Composer>
  );
}

const HeaderTitle = styled(HeaderText)`
  color: ${p => p.theme.colors.black};
`;

const ContentContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: ${p => p.theme.spacing.xlarge};
  margin-top: ${p => p.theme.spacing.medium};
  min-height: 10vh;
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.medium};
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${p => p.theme.spacing.large};
  max-width: 1200px;
  width: 100%;
`;

const InputError = styled.div`
  color: ${p => p.theme.colors.red400};
  min-height: 20px;
  text-align: left;
  width: 100%;
`;

const InputLabel = styled.div`
  margin-right: ${p => p.theme.spacing.xlarge};
  min-width: 110px;
`;

const Input = styled(TextInput)`
  width: 80%;
  &:hover {
    border-color: ${p => p.theme.colors.green400};
  }
  &:focus {
    border-color: ${p => p.theme.colors.green400};
    outline: none;
  }
`;
