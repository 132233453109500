import { Factory } from 'rosie';

Factory.define('StationConfiguration')
  .attr('id', 'station-address')
  .attr('address', 'station-address')
  .attr('name', 'desk1')
  .attr('type', 'STATION')
  .attr('updatedAt', '2018-12-11T02:48:32.535325Z');

Factory.define('StationConfigurationWithDirectDial')
  .extend('StationConfiguration')
  .attr('id', '+15555555555')
  .attr('address', '+15555555555')
  .attr('name', 'phone1')
  .attr('type', 'DIRECT_DIAL');
