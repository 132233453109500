import qconsole from 'scripts/lib/qconsole';
import TaskFollower from 'models/task_follower';

export default class TaskFollowerGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.taskInfo;
  }

  onAddSuccess(taskFollowerDto) {
    let taskInfoPending = this.store.isPending(taskFollowerDto.itemId);
    if (taskInfoPending) {
      this.store.commitPending(taskFollowerDto.itemId);
    }
  }

  onDeleteSuccess(taskFollowerDto, { taskId, followerId }) {
    let taskInfoPending = this.store.isPending(taskId);
    if (taskInfoPending) {
      this.store.commitPending(taskId);
    }
  }

  onAddError(error) {
    qconsole.error('error adding task follower', error);
  }

  onBroadcast(taskFollowerDto) {
    let taskFollower;
    try {
      taskFollower = TaskFollower.fromJs(taskFollowerDto);
    } catch (e) {
      this.context.errorReporter.reportError(e, {
        extra: { taskFollower: taskFollowerDto },
        message: 'Ignored task follower',
      });
      return;
    }
    let taskInfo = this.store.findBy({ id: taskFollower.itemId });
    taskInfo.addFollower(taskFollower);
    this.store.addOrReplace(taskInfo);
  }
}
