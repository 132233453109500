import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transition from 'styled-transition-group';
import _ from 'lodash';

import Portal from 'components/common/utilities/portal';
import StackContainer from 'components/common/containers/stack_container';
import { ToastType } from 'models/toast_deprecated';

// Documentation: http://go.glad.ly/style/#/Common%20Components/Toast

const toastPortalId = 'TOAST_DEPRECATED_PORTAL';
const defaultAnimationTimeouts = {
  appear: 4000,
  enter: 4000,
  exit: 500,
};

const Toast = props => {
  const node = document && document.getElementById(toastPortalId);
  const { onClick, persistent, type, visible } = props;
  const visibleFlagRef = useRef(!!visible);

  const [isVisible, setIsVisible] = useState(!!visible);
  const setHidden = useCallback(() => setIsVisible(false), [setIsVisible]);

  // Trigger fade-in or fade-out when the visibility prop changes. Use layout effect
  // to make transitions smoother and eliminate occasional jitter
  useLayoutEffect(() => {
    if (!!visible !== visibleFlagRef.current) {
      visibleFlagRef.current = !!visible;
      setIsVisible(!!visible);
    }
  }, [visible]);

  const wrappedOnClick = onClick
    ? () => {
        onClick();
        setHidden();
      }
    : null;

  let RenderToast;
  switch (type) {
    case ToastType.ERROR:
      RenderToast = ErrorToast;
      break;
    case ToastType.GENERAL_ACTIVITY:
      RenderToast = ActivityToast;
      break;
    case ToastType.SUCCESS:
      RenderToast = SuccessToast;
      break;
    case ToastType.WARN:
      RenderToast = WarnToast;
      break;

    case ToastType.INFO:
    default:
      RenderToast = InfoToast;
      break;
  }

  const classname = classnames('toast', `toast-${(props.type || ToastType.INFO).toLowerCase()}`);
  const onEntered = persistent ? () => {} : setHidden;
  return (
    <Portal node={node}>
      <Fade appear in={isVisible} onEntered={onEntered} timeout={defaultAnimationTimeouts} unmountOnExit>
        <RenderToast {...props} className={classname} onClick={wrappedOnClick}>
          {props.children}
        </RenderToast>
      </Fade>
    </Portal>
  );
};

Toast.propTypes = {
  onClick: PropTypes.func,
  persistent: PropTypes.bool,
  type: PropTypes.oneOf([..._.values(ToastType)]).isRequired,
  visible: PropTypes.bool,
};

Toast.defaultProps = {
  type: ToastType.INFO,
};

export const ToastPortalDeprecated = styled.div.attrs({ id: toastPortalId })`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  left: 0;
  margin-top: 76px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 30;
`;

const ToastBase = styled(StackContainer)`
  background-color: ${p => p.theme.colors.white};
  border: 1px solid;
  border-radius: 8px;
  box-shadow: ${p => p.theme.boxShadow.xsmall};
  cursor: ${p => (p.onClick ? 'pointer' : 'default')};
  font-size: ${p => p.theme.fontSize.base};
  font-weight: bold;
  line-height: ${p => p.theme.lineHeight.base};
  min-width: 300px;
  padding: 16px 24px;
  pointer-events: auto;
  text-align: center;
  top: 0;
`;

export const ActivityToast = styled(ToastBase)`
  border-color: ${p => p.theme.colors.gray700};
  color: ${p => p.theme.colors.gray700};
  & .activity-indicator .dot {
    background-color: ${p => p.theme.colors.gray700};
  }
`;
export const ErrorToast = styled(ToastBase)`
  border-color: ${p => p.theme.colors.red400};
  color: ${p => p.theme.colors.red400};
  & .activity-indicator .dot {
    background-color: ${p => p.theme.colors.red400};
  }
`;
export const InfoToast = styled(ToastBase)`
  border-color: ${p => p.theme.colors.purple400};
  color: ${p => p.theme.colors.purple400};
  & .activity-indicator .dot {
    background-color: ${p => p.theme.colors.purple400};
  }
`;
export const SuccessToast = styled(ToastBase)`
  border-color: ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.green400};
  & .activity-indicator .dot {
    background-color: ${p => p.theme.colors.green400};
  }
`;
export const WarnToast = styled(ToastBase)`
  border-color: ${p => p.theme.colors.yellow400};
  color: ${p => p.theme.colors.yellow700};
  & .activity-indicator .dot {
    background-color: ${p => p.theme.colors.yellow700};
  }
`;

export const Fade = transition.div`
  &:appear {
    opacity: 0.01;
    transform: translateY(-20px);
  }
  &:appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 500ms ease;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: all 500ms ease;
  }
`;

export default Toast;
