import { clearPendingRequest } from 'actions/composition/lib/update_conversation_workflow';
import ErrorReporter from 'scripts/infrastructure/error_reporter';

export default class OutgoingChatGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddSuccess(payload) {}

  handleUpdateSuccess(entityId, payload, correlationId) {
    clearPendingRequest(this.context, correlationId);
  }

  handleAddErrors(payload) {
    ErrorReporter.reportError(new Error(`OutgoingChatGatewayObserver send error`), {
      extra: { payload },
    });
  }

  handleUpdateErrors(entityId, payload, correlationId) {
    clearPendingRequest(this.context, correlationId);

    ErrorReporter.reportError(new Error(`OutgoingChatGatewayObserver update chat error`), {
      extra: { chatId: entityId, payload },
    });
  }
}
