import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import KeypressEventHandler from 'components/lib/dom_controls/lib/keypress_event_handler';
import NumericInput from 'components/common/numeric_input';

const Meridiem = {
  AM: 'AM',
  PM: 'PM',
};
export { Meridiem };

/**
 * @visibleName Time Input
 */

export default class TimeInput extends React.Component {
  constructor(props) {
    super(props);

    _.bindAll(this, [
      'handleChangeHour',
      'handleChangeMinutes',
      'handleClickAm',
      'handleClickPm',
      'handleFocusHour',
      'handleHourKeyDown',
      'handleMinuteKeyDown',
    ]);
  }

  render() {
    const classNames = classnames('timeInput', this.props.className);
    const amClassNames = classnames('timeInput-am', {
      'timeInput-am-active': this.props.time.meridiem === Meridiem.AM,
    });
    const pmClassNames = classnames('timeInput-pm', {
      'timeInput-pm-active': this.props.time.meridiem === Meridiem.PM,
    });
    return (
      <div className={classNames}>
        <div className="timeInput-time">
          <NumericInput
            className="timeInput-hour"
            maxValue={12}
            minValue={0}
            onChange={this.handleChangeHour}
            onFocus={this.handleFocusHour}
            onKeyDown={this.handleHourKeyDown}
            ref={node => (this.hour = node)}
            value={Number.parseInt(this.props.time.hour)}
          />
          <div className="timeInput-colon">:</div>
          <MinutesInput
            onChange={this.handleChangeMinutes}
            onKeyDown={this.handleMinuteKeyDown}
            ref={node => (this.minutes = node)}
            value={this.props.time.minute}
          />
        </div>
        <div className="timeInput-amPm">
          <div className={amClassNames} onClick={this.handleClickAm}>
            AM
          </div>
          <div className={pmClassNames} onClick={this.handleClickPm}>
            PM
          </div>
        </div>
      </div>
    );
  }

  handleChange(attrs) {
    const newTime = {
      ...this.props.time,
      ...attrs,
    };
    this.props.onChange(newTime);
  }

  handleChangeHour(hour) {
    this.handleChange({ hour: hour ? hour.toString() : this.props.time.hour });
  }
  handleChangeMinutes(minute) {
    this.handleChange({ minute });
  }

  handleClickAm() {
    this.handleChange({ meridiem: Meridiem.AM });
  }
  handleClickPm() {
    this.handleChange({ meridiem: Meridiem.PM });
  }

  handleFocusHour() {
    setImmediate(() => {
      this.hour.selectAll();
    });
  }

  handleHourKeyDown(evt) {
    if (evt.key === 'ArrowUp') {
      this.handleArrowUp(evt);
    } else if (evt.key === 'ArrowDown') {
      this.handleArrowDown(evt);
    }
    if (evt.key === ':') {
      this.focusMinutes();
    }
  }
  handleMinuteKeyDown(evt) {
    if (evt.key === 'ArrowUp') {
      this.handleArrowUp(evt);
    } else if (evt.key === 'ArrowDown') {
      this.handleArrowDown(evt);
    }
  }

  // Rounds up to the nearest 15 minute interval, flipping meridiem if needed
  //    e.g. 12:48 AM -> 1:00 AM
  //         11:45 AM -> 12:00 PM
  //         7:25  PM -> 7:30 PM
  handleArrowUp(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    let minuteNumber = Number.parseInt(this.props.time.minute);
    const remainder = 15 - (minuteNumber % 15);
    let newMinute = (minuteNumber + remainder).toString();
    let newHour = this.props.time.hour;
    let newMeridiem = this.props.time.meridiem;
    if (Number.parseInt(newMinute) >= 60) {
      newMinute = '00';
      let oldHourNumber = Number.parseInt(this.props.time.hour);
      let newHourNumber = oldHourNumber + 1;
      if (oldHourNumber <= 11 && newHourNumber > 11) {
        newMeridiem = this.props.time.meridiem === Meridiem.AM ? Meridiem.PM : Meridiem.AM;
      }
      if (newHourNumber > 12) {
        newHourNumber = 1;
      }
      newHour = newHourNumber.toString();
    }
    this.handleChange({ hour: newHour, meridiem: newMeridiem, minute: leftPadMinutes(newMinute) });
  }
  // Rounds down to the nearest 15 minute interval, flipping meridiem if needed
  //    e.g. 4:05 AM -> 4:00 AM
  //         1:00 AM -> 12:45 AM
  //         12:00 PM -> 11:45 AM
  handleArrowDown(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    let minuteNumber = Number.parseInt(this.props.time.minute);
    let newMinuteNumber = minuteNumber - (minuteNumber % 15);
    if (newMinuteNumber === minuteNumber) {
      newMinuteNumber = newMinuteNumber - 15;
    }
    let newMinute = newMinuteNumber.toString();
    let newHour = this.props.time.hour;
    let newMeridiem = this.props.time.meridiem;
    if (newMinuteNumber < 0) {
      newMinute = '45';
      let oldHourNumber = Number.parseInt(this.props.time.hour);
      let newHourNumber = oldHourNumber - 1;
      if (oldHourNumber >= 12 && newHourNumber < 12) {
        newMeridiem = this.props.time.meridiem === Meridiem.AM ? Meridiem.PM : Meridiem.AM;
      }
      if (newHourNumber < 1) {
        newHourNumber = 12;
      }
      newHour = newHourNumber.toString();
    }
    this.handleChange({ hour: newHour, meridiem: newMeridiem, minute: leftPadMinutes(newMinute) });
  }

  focusMinutes() {
    this.minutes.focus();
    setImmediate(() => {
      this.minutes.selectAll();
    });
  }
}

TimeInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  time: PropTypes.shape({
    hour: PropTypes.string.isRequired,
    minute: PropTypes.string.isRequired,
    meridiem: PropTypes.string.isRequired,
  }).isRequired,
};

export class MinutesInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workingValue: leftPadMinutes(this.props.value),
    };

    _.bindAll(this, ['handleBlur', 'handleChange', 'handleFocus', 'handleSubmit']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.workingValue) {
      this.setValue(nextProps.value);
    }
  }

  render() {
    const keypressEventHandler = new KeypressEventHandler(this.handleSubmit, this.handleCancel, { isInputField: true });
    return (
      <input
        className="timeInput-minutes"
        maxLength={2}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
        placeholder="00"
        ref={node => (this.input = node)}
        value={this.state.workingValue}
        {...keypressEventHandler.reactAttributes()}
      />
    );
  }

  // Handlers

  handleBlur() {
    this.submitValue();
  }

  handleChange(evt) {
    const re = /^[0-9\b]+$/;
    if (evt.target.value === '' || re.test(evt.target.value)) {
      this.setState({ workingValue: evt.target.value });

      let valueToSubmit = leftPadMinutes(evt.target.value.toString());
      let valueNumber = Number.parseInt(valueToSubmit);
      if (valueNumber < 0) {
        valueToSubmit = '00';
      } else if (valueNumber > 59) {
        valueToSubmit = '59';
      }
      if (evt.target.value.length === 2) {
        this.submitValue(valueToSubmit);
      }
    } else {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }

  handleFocus() {
    setImmediate(() => {
      this.selectAll();
    });
  }

  handleSubmit(evt) {
    evt.target.blur();
    this.submitValue();
  }

  // Actions

  focus() {
    this.input.focus();
  }

  selectAll() {
    this.input.setSelectionRange(0, this.input.value.length);
  }

  setValue(value) {
    this.setState({ workingValue: leftPadMinutes(value) });
  }

  submitValue(value = this.state.workingValue) {
    if (!value) {
      value = '00';
    }
    this.props.onChange(leftPadMinutes(value));
  }
}

MinutesInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  value: PropTypes.string.isRequired,
};

function leftPadMinutes(value) {
  if (value.length === 1) {
    return `0${value}`;
  }
  return value;
}
