import CustomChannelsConfig from 'models/custom_channels_config';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';

export default class RequestCustomChannelsConfig {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.gateways.customChannelsConfig
      .request()
      .then(dto => {
        this.context.stores.customChannelsConfig.set(CustomChannelsConfig.fromJs(dto));
      })
      .catch(errDto => {
        handleCommonErrors(this.context, errDto);
      });
    this.context.stores.customChannelsConfig.setLoading();
  }
}
