/**
 * The Droppable mixin is used *entirely* to properly set `isDragActive` state properly on a component,
 * which can be used to display whether the area a user is dragging a file over can be dropped on.
 *
 * With this mixed in, you then pass in `onDragEnter`, `onDragLeave`, `onDragOver`, and `onDrop` as
 * props to the element that you want to be able to drop on. Then you can use `isDragActive` to set a
 * className on the element, for example.
 */

export default {
  componentDidMount() {
    this.enterCounter = 0;
  },

  onDragEnter(e) {
    if (e.dataTransfer.types[0] === 'Files') {
      e.preventDefault();

      ++this.enterCounter;

      this.setState({
        isDragActive: true,
      });
    }
  },

  onDragLeave(e) {
    if (e.dataTransfer.types[0] === 'Files') {
      e.preventDefault();

      // Only deactivate once the dropzone and all children was left.
      if (--this.enterCounter > 0) {
        return;
      }

      this.setState({
        isDragActive: false,
      });
    }
  },

  onDragOver(e) {
    if (e.dataTransfer.types[0] === 'Files') {
      e.preventDefault();
      e.stopPropagation();
      // This changes the mouse cursor to the "+" sign.
      e.dataTransfer.dropEffect = 'copy'; // eslint-disable-line no-param-reassign
      return false;
    }
  },

  onDrop(e) {
    this.enterCounter = 0;

    this.setState({
      isDragActive: false,
    });
  },
};
