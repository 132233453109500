import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from '../lib/create_model';

export const ReportFormat = createEnum('PDF', 'CSV', 'PNG');

export const Frequency = createEnum(
  'DAILY',
  'WEEKLY',
  'MONTHLY'
  // intentionally no quarterly
);

export const Recipient = createModel({
  modelName: 'Recipient',
  properties: {
    type: prop(String).oneOf('EMAIL'),
    address: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const Interval = createEnum('DAY', 'WEEK', 'MONTH');

export const Lookback = createModel({
  modelName: 'Lookback',
  properties: {
    quantity: prop(Number),
    interval: prop(String).oneOf(..._.keys(Interval)),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const Filters = createModel({
  modelName: 'Filters',
  properties: {
    channel: prop(String),
    inboxes: prop([String]),
    phoneNumber: prop(String),
    teams: prop([String]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default createModel({
  modelName: 'ScheduledReport',
  properties: {
    name: prop(String),
    metricSet: prop(),
    timezone: prop(String).default(''),
    frequency: prop().oneOf(..._.keys(Frequency)),
    format: prop()
      .oneOf(..._.keys(ReportFormat))
      .default(ReportFormat.PDF),
    recipients: prop([Recipient]).default([]),
    lookback: prop(Lookback),
    filters: prop(Filters),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
