export function areCompositionsReady(context) {
  const currentLocation = context.stores.currentLocation.get();
  const customerId = currentLocation.customerId;
  if (!context.stores.customers.has({ id: customerId })) {
    return false;
  }

  const { compositions, conversations, conversationHistory, profile } = context.stores.customers.storesFor(customerId);
  return (
    !compositions.isLoading() && !conversations.isLoading() && !profile.isLoading() && !conversationHistory.isLoading()
  );
}
