import Html from 'slate-html-serializer-old';

import { agentMentionRules } from './rules/agent_mention_rules';
import { answerLinkRules } from './rules/answer_link_rules';
import { basePasteRules } from './rules/base_paste_rules';
import { fallbackRules } from './rules/fallback_rules';
import { placeholderRules } from './rules/placeholder_rules';
import { richTextRules } from './rules/rich_text_rules';
import { variableRules } from './rules/variable_rules';

const PasteSerializer = new Html({
  rules: [
    ...agentMentionRules,
    ...basePasteRules,
    ...answerLinkRules,
    ...placeholderRules,
    ...richTextRules,
    ...variableRules,
    ...fallbackRules,
  ],
});

export default PasteSerializer;
