import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Instagram Stroke
 */

export default function InstagramStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M5.076.002c-1.23.023-1.8.084-2.355.252C1.344.671.42 1.717.14 3.175c-.075.387-.097.647-.131 1.536-.013.345-.013 4.233 0 4.578.036.938.063 1.231.152 1.638.362 1.664 1.543 2.734 3.284 2.977.313.043.525.057 1.294.086.357.013 4.166.013 4.52 0 .78-.03 1.004-.045 1.32-.09 1.762-.252 2.95-1.37 3.28-3.087.074-.387.097-.654.13-1.556.013-.36.013-4.153 0-4.513-.039-1.042-.071-1.357-.19-1.825C13.436 1.473 12.416.49 10.945.166 10.527.075 10.29.052 9.322.011 9.15.004 5.415-.004 5.076.002zm4.089 1.275c.833.029 1.075.047 1.393.107 1.098.206 1.807.885 2.036 1.952.076.354.102.654.13 1.536.02.658.015 3.91-.008 4.494-.028.717-.05.949-.114 1.26-.224 1.076-.9 1.752-1.975 1.976-.312.064-.544.085-1.261.114-.584.023-3.837.029-4.494.008-.883-.029-1.182-.054-1.536-.13-.999-.215-1.662-.852-1.908-1.835-.094-.373-.12-.653-.153-1.627-.013-.388-.013-3.876 0-4.264.025-.718.042-1.003.08-1.254.191-1.307.952-2.067 2.259-2.26.306-.044.986-.078 1.737-.087.483-.005 3.616.003 3.814.01zm1.448 1.15c-.657.094-.947.886-.51 1.393a.85.85 0 001.285-.019c.487-.602-.011-1.485-.775-1.375zm-3.778.976a3.6 3.6 0 10.33 7.193 3.6 3.6 0 00-.33-7.193zm.366 1.275c1.817.163 2.756 2.266 1.661 3.718-1.125 1.492-3.458 1.124-4.067-.643-.545-1.581.74-3.224 2.406-3.075z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
