import { StyledElement, withProps } from '@udecode/plate';
import { css } from 'styled-components';

// Plate provides out of the box styling for its built-in paragraph element.
// Since our paragraph plugin shares the same `ELEMENT_PARAGRAPH` key, we
// override it by creating a styled component here and providing it in
// `./components`
export const StyledP = withProps(StyledElement, {
  as: 'p',
  styles: {
    root: css`
      margin: 0;
    `,
  },
});
