import React from 'react';
import PropTypes from 'prop-types';

import ConversationNotification from 'models/notification/conversation_notification';
import NotificationContentContainer from './notification_content_container';

function FbMessageIncomingNotification({ notification }) {
  let content = notification.conversationItem.content;
  let customerName =
    notification.profile.name ||
    (notification.profile.fbUserProfile && notification.profile.fbUserProfile.getName()) ||
    'Private User';

  return (
    <NotificationContentContainer
      body={content.message.text}
      heading={customerName}
      iconClass="icon-fb-messenger-filled"
      itemType="Facebook Message"
      notification={notification}
    />
  );
}

FbMessageIncomingNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default FbMessageIncomingNotification;
