import classnames from 'classnames';
import createReactClass from 'create-react-class';
import moment from 'moment';
import React from 'react';

const LongDateTime = createReactClass({
  render() {
    let formattedTime = moment(this.props.timestamp).format('MMM D, YYYY h:mma');
    return <span className={classnames('longDateTime', this.props.className)}>{formattedTime}</span>;
  },
});

export default LongDateTime;
