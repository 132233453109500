import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import BubbleContent, { BubbleHeader, BubbleFooter } from '../content/bubble_content';
import ContextEvent from './context_event';
import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';

export default function HelpAppContextItem({ className, item }) {
  return (
    <ItemContent item={item}>
      <ItemMetadata item={item} />
      <HelpAppContextItemContent className={className} item={item} />
    </ItemContent>
  );
}

HelpAppContextItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export function HelpAppContextItemContent({ className, item }) {
  const events = item.content.events;
  const renderedEvents = _.map(events, event => {
    return <ContextEvent contextEvent={event} key={event.timestamp} />;
  });

  let firstTime = moment(_.head(events).timestamp);
  let lastTime = moment(_.last(events).timestamp);
  let elapsedTime = moment.duration(lastTime.diff(firstTime)).humanize();

  return (
    <BubbleContent className={className} item={item}>
      <BubbleHeader>
        <div className="contextContent-headerText">Help Application</div>
      </BubbleHeader>
      <ul className="contextContent-timeline">{renderedEvents}</ul>
      <BubbleFooter className="contextContent-footer">
        <div className="contextContent-headerTimestamp">Time spent: {elapsedTime}</div>
      </BubbleFooter>
    </BubbleContent>
  );
}

HelpAppContextItemContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
