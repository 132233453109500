import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('FbMessageIncomingAttachment').extend('Uuid');

Factory.define('FbMessageIncomingAttachmentWithDefaults')
  .extend('FbMessageIncomingAttachment')
  .attr('attachmentId', 'some-id')
  .attr('contentType', 'text/plain')
  .attr('filename', 'some-facebook-attachment.txt')
  .attr('type', 'file');
