import CommunicationSimulationService from './communication_simulation_service';
import qconsole from 'scripts/lib/qconsole';

export default class CommunicationSimulationProxy {}

Object.getOwnPropertyNames(CommunicationSimulationService.prototype).forEach(function(methodName) {
  if (methodName !== 'constructor') {
    CommunicationSimulationProxy.prototype[methodName] = function() {
      if (this.impl) {
        this.impl[methodName].apply(this.impl, arguments);
      } else if (methodName.startsWith('simulate')) {
        qconsole.warn('Simulation disabled');
      }
    };
  }
});
