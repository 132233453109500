import React from 'react';
import { find } from 'lodash';

import { ExternalCustomerAddressType } from 'models/customer_profile/external_customer_address';
import ContactTwitterIcon from 'components/lib/icons/contact_twitter_icon';
import InstagramDirectField from 'components/customer/profile/instagram_direct_field';
import { getBrandIconByName } from 'components/common/custom_channels';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/common/tooltip';

const rowWrapperClassNames =
  'customerProfile-twitterRow customerProfile-contactDetails-row customerProfile-contactDetails-readonly static';

const ExternalCustomerAddressField = props => {
  if (!props.address) {
    return null;
  }

  switch (props.address.type) {
    case ExternalCustomerAddressType.INSTAGRAM_DIRECT:
      if (!props.isInstagramEnabled) {
        return null;
      }
      return renderInstagramDirect(props.address);
    case ExternalCustomerAddressType.TWITTER:
      if (!props.isTwitterEnabled) {
        return null;
      }
      return renderTwitterField(props.address.displayName);
    case ExternalCustomerAddressType.WHATSAPP:
      if (!props.isWhatsAppEnabled) {
        return null;
      }
      return renderWhatsAppField(props.address.displayName);
    case ExternalCustomerAddressType.CUSTOM_CHANNEL:
      if (!props.isCustomChannelsEnabled) {
        return null;
      }

      return renderCustomChannelAddressField(props.address, props.customChannels);
    default:
      return null;
  }
};

function renderInstagramDirect(address) {
  return <InstagramDirectField address={address} />;
}

function renderWhatsAppField(displayName) {
  return (
    <div className={rowWrapperClassNames}>
      <FontAwesomeIcon className="customerProfile-whatsAppIcon" icon={faWhatsapp} />
      <div className="customerProfile-whatsApp-field">{displayName}</div>
    </div>
  );
}

function renderTwitterField(displayName) {
  return (
    <div className={rowWrapperClassNames}>
      <ContactTwitterIcon className="customerProfile-twitterIcon" />
      <div className="customerProfile-twitter-field">{displayName}</div>
    </div>
  );
}

function renderCustomChannelAddressField(channelInfo, customChannels) {
  const customChannel = find(customChannels, { id: channelInfo?.content?.customChannelId });

  if (!customChannel?.displayAddressInProfile || customChannel?.archived) {
    return null;
  }

  const icon = getBrandIconByName(customChannel?.icon, {
    className: 'iconContainer customerProfile-customChannelAddressIcon',
    letter: customChannel?.name,
  });

  return (
    <div className={rowWrapperClassNames}>
      <Tooltip className="customChannelTooltip" message={customChannel.name}>
        {icon.icon}
      </Tooltip>
      <div className="customerProfile-customChannelAddress">{channelInfo.displayName || channelInfo.id}</div>
    </div>
  );
}

export default ExternalCustomerAddressField;
