import _ from 'lodash';

export default class LocalCache {
  constructor() {
    this.values = {};
  }

  clear() {
    this.values = {};
  }

  findBy(predicate) {
    return _.find(this.values, predicate);
  }

  findById(id) {
    return this.values[id];
  }

  set(id, value) {
    this.values[id] = value;
  }
}
