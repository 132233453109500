import CancelLiveboard from 'actions/liveboards/cancel_liveboard';
import Floorboard from 'models/location/floorboard';
import FloorboardUrlConverter from './url_converters/floorboard_url_converter';
import Liveboards from 'models/location/liveboards';
import LiveboardsUrlConverter from './url_converters/liveboards_url_converter';
import OpenLiveboard from 'actions/liveboards/open_liveboard';
import OpenFloorboard from 'actions/liveboards/open_floorboard';
import { registerUrlConverter } from './location_url';

registerUrlConverter(Floorboard, FloorboardUrlConverter);
registerUrlConverter(Liveboards, LiveboardsUrlConverter);

export default function(router, { runnerContext }) {
  return {
    '/floorboard': {
      on: type => {
        runnerContext.executeAction(OpenFloorboard, getFloorboardParams());
      },
      after: () => {
        runnerContext.executeAction(CancelLiveboard);
      },
    },
    '/?(summary|agents|topics|people-match)?': {
      on: type => {
        runnerContext.executeAction(OpenLiveboard, getLiveboardsParams(type));
      },
      after: () => {
        runnerContext.executeAction(CancelLiveboard);
      },
    },
  };

  function getFloorboardParams() {
    return FloorboardUrlConverter.fromUrlParameters(router.parseQueryParameters().toJS());
  }

  function getLiveboardsParams(type) {
    return LiveboardsUrlConverter.fromUrlParameters({ type, ...router.parseQueryParameters().toJS() });
  }
}
