import _ from 'lodash';
import { CollectionStoreBase, CollectionProvider } from 'scripts/adapters/stores/lib/collection_store_base';
import qconsole from 'scripts/lib/qconsole';

class MultiDocumentProvider extends CollectionProvider {
  isLoading(entityKey) {
    if (_.isEmpty(entityKey)) throw new Error('isLoading: Missing or invalid argument');
    return !!this.immutableStore.isLoading(entityKey);
  }

  getErrorForLoading(entityKey) {
    if (_.isEmpty(entityKey)) throw new Error('getErrorForLoading: Missing or invalid argument');
    return this.immutableStore.getErrorForLoading(entityKey);
  }

  getLoadingStateKeys() {
    const serializedKeys = this.immutableStore.getLoadingStateKeys() || [];
    return serializedKeys.reduce((acc, key) => {
      try {
        const originalKey = JSON.parse(key);
        acc.push(originalKey);
      } catch (err) {
        qconsole.error(`MultiDocumentProvider.getLoadingStateKeys: unable to deserialize the key. ${err.message}`);
      }
      return acc;
    }, []);
  }

  hasActiveLoadingStateFor(partialEntityKey) {
    if (_.isEmpty(partialEntityKey)) throw new Error('hasActiveLoadingStateFor: Missing or invalid argument');

    const keys = this.getLoadingStateKeys();
    if (_.isEmpty(keys)) return false;
    return !!_.find(keys, key => _.isMatch(key, partialEntityKey));
  }

  hasActiveLoadingStates() {
    const keys = this.immutableStore.getLoadingStateKeys() || [];
    return keys.length > 0;
  }

  static create(immutableStore, options) {
    return new MultiDocumentProvider(immutableStore, options);
  }
}

/**
 * Factory for creating MultiDocumentStore class. MultiDocumentStore is a multi-element store where each
 * element can be loaded and tracked independently. It shares many common methods with the CollectionStore class.
 *
 * @param converter - converter used for converting stored entities (typically, models) to/from Immutable
 * @returns {MultiDocumentStore}
 */
export default function createMultiDocumentStoreClass({ converter }) {
  class MultiDocumentStore extends CollectionStoreBase {
    constructor(binding, opts) {
      super(binding, converter, { createProvider: MultiDocumentProvider.create, ...opts });
    }

    isLoading(entityKey) {
      this._checkRequiredArgument(entityKey, 'isLoading');
      return this.immutableStore.isLoading(entityKey);
    }

    setLoading(entityKey) {
      this._checkRequiredArgument(entityKey, 'setLoading');
      return this.immutableStore.setLoading(entityKey);
    }

    resetLoading(entityKey) {
      this._checkRequiredArgument(entityKey, 'resetLoading');
      this.immutableStore.resetLoading(entityKey);
    }

    clearErrorForLoading(entityKey) {
      this._checkRequiredArgument(entityKey, 'clearErrorForLoading');
      this.immutableStore.clearErrorForLoading(entityKey);
    }

    getErrorForLoading(entityKey) {
      this._checkRequiredArgument(entityKey, 'getErrorForLoading');
      return this.immutableStore.getErrorForLoading(entityKey);
    }

    setErrorForLoading(error, entityKey) {
      this._checkRequiredArgument(entityKey, 'setErrorForLoading');
      this.immutableStore.setErrorForLoading(error, entityKey);
    }

    _checkRequiredArgument(arg, methodName) {
      if (_.isEmpty(arg)) throw new Error(`${methodName}: Missing or invalid argument`);
    }
  }

  // Delegate the rest of the store APIs to ImmutableStore
  [
    'count',
    'remove',
    'clearAllErrorsForLoading',
    'clearErrors',
    'clearErrorsForNew',
    'getAllErrors',
    'getErrors',
    'getErrorsForNew',
    'setErrors',
    'setErrorsForNew',
    'commitPending',
    'commitPendingNew',
    'resetAllLoading',
    'resetPending',
    'resetPendingNew',
    'isPendingDelete',
    'setPendingDelete',
  ].forEach(m => {
    MultiDocumentStore.prototype[m] = function(...args) {
      return this.immutableStore[m](...args);
    };
  });

  return MultiDocumentStore;
}
