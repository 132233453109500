import _ from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { H3, H4 } from 'components/common/headers';
import HOTKEYS from './hotkeys_list';
import HotkeysContext, { HotkeysShownContext } from 'components/contexts/hotkeys';
import ModalCard from 'components/common/modal_card';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import registerHotkey from 'components/hotkeys/register_hotkey';
import StackContainer from 'components/common/containers/stack_container';
import useKeyboardShortcut from 'components/hooks/use_keyboard_shortcut';
import { useThrottled } from 'components/hooks/debounce_hooks';

const toggleModalHotkey = registerHotkey({
  key: 'alt+/',
  label: 'Show keyboard shortcuts',
  group: 'General',
});

function updateShowMoreShortcuts(ref, showMoreShortcuts, setShowMoreShortcuts) {
  if (ref) {
    const rect = ref.getBoundingClientRect();
    const maxScrollTop = Math.floor(ref.scrollHeight - rect.height);
    if (!showMoreShortcuts && ref.scrollTop < maxScrollTop) {
      setShowMoreShortcuts(true);
    } else if (showMoreShortcuts && ref.scrollTop === maxScrollTop) {
      setShowMoreShortcuts(false);
    }
  }
}

export function HotkeysModal() {
  const { hotkeys } = useContext(HotkeysContext);
  const { areHotkeysShown, dispatchHotkeysShown } = useContext(HotkeysShownContext);

  const toggleHotkeys = () => dispatchHotkeysShown('toggle');
  useKeyboardShortcut(toggleModalHotkey, toggleHotkeys);
  const onClose = () => dispatchHotkeysShown('off');

  const scrollerRef = useRef(null);
  const [showMoreShortcuts, setShowMoreShortcuts] = useState(false);
  const toggleMoreShortcutsBelow = useCallback(() => {
    updateShowMoreShortcuts(scrollerRef.current, showMoreShortcuts, setShowMoreShortcuts);
  }, [showMoreShortcuts]);
  const onScroll = useThrottled(toggleMoreShortcutsBelow, 100);
  useEffect(() => {
    updateShowMoreShortcuts(scrollerRef.current, showMoreShortcuts, setShowMoreShortcuts);
  }, [areHotkeysShown]);

  if (!areHotkeysShown) {
    return null;
  }

  const groups = _(HOTKEYS)
    .keys()
    .sort()
    .value();

  return (
    <PortalModalWrapper>
      <Wrapper>
        <ModalCard onClose={onClose}>
          <Close onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
          <Header>Keyboard Shortcuts</Header>
          <Hotkeys onScroll={onScroll} ref={scrollerRef}>
            {_.map(groups, groupName => {
              const hotkeyGroup = HOTKEYS[groupName];
              const keys = _(hotkeyGroup)
                .keys()
                .sort()
                .value();
              const activeKeys = _.filter(keys, k => _.find(hotkeys, h => h.key === k));

              return (
                <Group key={groupName}>
                  <GroupHeader isInactive={activeKeys.length === 0}>{groupName}</GroupHeader>
                  {keys.map((key, i) => {
                    return (
                      <Hotkey
                        isInactive={!_.find(hotkeys, h => h.key === key)}
                        key={`${key}-${i}`}
                        label={hotkeyGroup[key]}
                        shortcut={key}
                      />
                    );
                  })}
                </Group>
              );
            })}
          </Hotkeys>
          <MoreHotkeysBelow show={showMoreShortcuts}>More shortcuts below</MoreHotkeysBelow>
        </ModalCard>
      </Wrapper>
    </PortalModalWrapper>
  );
}

export default React.memo(HotkeysModal);

const Wrapper = styled.div`
  .modalCard-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    position: relative;
  }
`;
const Close = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    color: ${p => p.theme.colors.gray600};
  }
`;

const Header = styled(H3)`
  display: inherit;
  margin-bottom: ${p => p.theme.spacing.large};
  text-align: center;
`;
const Group = styled.div`
  width: 340px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const GroupHeader = styled(H4)`
  margin-bottom: ${p => p.theme.spacing.small};
  opacity: 1;

  ${p => p.isInactive && inactive};
`;
const Hotkeys = styled(StackContainer)`
  flex-shrink: 1;
  margin: 0 -32px;
  overflow: auto;
  padding: 0 32px;
`;
const MoreHotkeysBelow = styled.span`
  bottom: ${p => p.theme.spacing.medium};
  color: ${p => p.theme.colors.gray600};
  font-size: ${p => p.theme.fontSize.base};
  font-style: italic;
  left: 50%;
  opacity: 1;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.15s ease;

  ${p => !p.show && `opacity: 0`};
`;

function Hotkey({ isInactive, label, shortcut }) {
  let elements = [];
  const parts = shortcut.split('+');
  const keys = parts.map((p, i) => (
    <Key isLast={i === parts.length - 1} key={p}>
      {convertPart(p)}
    </Key>
  ));
  _.forEach(keys, (key, i) => {
    elements.push(key);
    if (i < keys.length - 1) {
      elements.push(<Plus key={`plus-${i}`}>+</Plus>);
    }
  });

  return (
    <HotkeyWrapper isInactive={isInactive}>
      <Label>{label}</Label>
      <Shortcut>{elements}</Shortcut>
    </HotkeyWrapper>
  );
}

Hotkey.propTypes = {
  isInactive: PropTypes.bool,
  label: PropTypes.string,
  shortcut: PropTypes.string.isRequired,
};

function convertPart(part) {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  if (!isMac) {
    return part;
  }

  switch (part.toLowerCase()) {
    case 'alt':
      return 'option';
    default:
      return part;
  }
}

const HotkeyWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${p => p.theme.spacing.small};
  min-width: 340px;
  opacity: 1;

  ${p => p.isInactive && inactive};
`;
const Label = styled.span`
  color: ${p => p.theme.colors.gray900};
`;
const Shortcut = styled.span`
  color: ${p => p.theme.colors.gray900};
  display: flex;
  padding-left: ${p => p.theme.spacing.medium};
`;
const lastKey = css`
  text-transform: inherit;
  width: 24px;
`;
const Key = styled.div`
  border: 1px solid ${p => p.theme.colors.gray600};
  border-bottom-width: 2px;
  border-radius: 8px;
  padding: 2px 6px;
  text-align: center;
  text-transform: capitalize;

  ${p => p.isLast && lastKey};
`;
Key.propTypes = {
  isLast: PropTypes.bool,
};
const Plus = styled.span`
  align-items: center;
  color: ${p => p.theme.colors.gray600};
  display: flex;
  margin: ${p => p.theme.spacing.small};
`;

const inactive = css`
  opacity: 0.5;
`;
