import _ from 'lodash';
import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import { reconcileInboxItem } from 'actions/inbox/agent/agent_inbox_helpers';

// Use the `force` option if we are locally updating a task item - by default, reconcileInboxItem
// does not update the conversation item in an inbox item if the item has the same id and has
// a lower or same version number.

// The problem is that we only update the version number for conversation items on the backend,
// so if we want put a newly edited task in the inbox item, we need to ignore the version
// item check and just set it directly.
export default function tryUpdateTaskItem(context, { id, item, profile, force }) {
  _.forEach([context.stores.agentInboxItems, context.stores.agentInboxItemsNew], store => {
    const existingItem = store.findBy({ id: id || item.id });
    if (!existingItem) {
      return;
    }

    if (force) {
      existingItem.setItem(item);
      store.replace(existingItem);
      return;
    }

    const newInboxItem = new AgentInboxItem({
      id: item.id,
      item,
      profile,
      type: AgentInboxItem.Type.TASK,
    });
    reconcileInboxItem(context, newInboxItem, store);
  });
}
