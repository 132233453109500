import TrackEvent from 'actions/analytics/track_event';

export default class TrackLinkedItemNavigation {
  constructor(context) {
    this.context = context;
  }

  run(link) {
    let currentLocation = this.context.stores.currentLocation.get();
    let currentCustomerId = currentLocation.customerId;

    let linkedCustomerId = link.isOriginal ? currentCustomerId : link.customerId;
    let originalCustomerId = link.isOriginal ? link.customerId : currentCustomerId;

    let event = link.isOriginal ? 'Navigate to Original Profile' : 'Navigate to Linked Profile';
    this.context.executeAction(TrackEvent, {
      event: `Relationships - Items - ${event}`,
      props: { itemId: link.itemId, linkedCustomerId, originalCustomerId },
    });
  }
}
