import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import createEnum from 'scripts/lib/create_enum';
import './custom_attribute_change_item_strings';
import ItemContent from '../item_content';
import Metadata from 'components/customer/conversation_history/conversation_items_v2/metadata/metadata';
import { StyledIconAPI } from 'components/customer/conversation_history/conversation_items_v2/metadata/api_metadata';
import { Trans, useTranslation } from 'react-i18next';

const Operation = createEnum('ADDED', 'REMOVED');

export function CustomAttributeChangeItem({ added, currentAgentId, initiatorName, item, removed }) {
  const icon =
    item.initiator.type === Communicator.API ? <StyledIconAPI className="api-icon">APP</StyledIconAPI> : null;

  return (
    <>
      {added.length > 0 ? (
        <div data-aid="conversationCustomAttributeAdded">
          <ItemContent isAuditItem item={item}>
            <Metadata
              icon={icon}
              text={
                <CustomAttributeUpdateMessage
                  currentAgentId={currentAgentId}
                  initiator={{ id: item.initiator?.id, type: item.initiator?.type, name: initiatorName }}
                  operation={Operation.ADDED}
                  values={added}
                />
              }
              timestamp={item.timestamp}
            />
          </ItemContent>
        </div>
      ) : null}
      {removed.length > 0 ? (
        <div data-aid="conversationCustomAttributeRemoved">
          <ItemContent isAuditItem item={item}>
            <Metadata
              icon={icon}
              text={
                <CustomAttributeUpdateMessage
                  currentAgentId={currentAgentId}
                  initiator={{ id: item.initiator?.id, type: item.initiator?.type, name: initiatorName }}
                  operation={Operation.REMOVED}
                  values={removed}
                />
              }
              timestamp={item.timestamp}
            />
          </ItemContent>
        </div>
      ) : null}
    </>
  );
}

CustomAttributeChangeItem.propTypes = {
  added: PropTypes.arrayOf(String).isRequired,
  currentAgentId: PropTypes.string,
  initiatorName: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  removed: PropTypes.arrayOf(String).isRequired,
};

function CustomAttributeUpdateMessage({ currentAgentId, initiator, operation, values }) {
  const { i18n, t } = useTranslation();
  const i18nKey = buildMessageKey();

  return (
    <Trans
      components={{ topic: <Highlight />, agent: <Highlight /> }}
      defaults="an agent added topic"
      i18nKey={i18nKey}
      values={{
        agent: initiator.name || t('genericAgent'),
        count: values.length,
        values: values.join(', '),
      }}
    />
  );

  function buildMessageKey() {
    let rootKey = `customAttributeUpdateItem.${initiator.type}_${operation}`;
    let initiatorKey = 'api';
    if (initiator.type === Communicator.AGENT) {
      initiatorKey = initiator.id === currentAgentId ? 'you' : 'agent';
    }

    const compoundKey = `${rootKey}.${initiatorKey}`;
    return i18n.exists(compoundKey) ? compoundKey : rootKey;
  }
}

CustomAttributeUpdateMessage.propTypes = {
  currentAgentId: PropTypes.string.isRequired,
  initiator: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  operation: PropTypes.oneOf(_.values(Operation)).isRequired,
  values: PropTypes.arrayOf(String),
};

const Highlight = styled.span.attrs(() => ({ 'data-aid': 'highlight' }))`
  font-weight: bold;
`;

function mapStateToProps({ getProvider }, { item }) {
  const getAttributeNames = attributes => {
    return attributes.map(attr => {
      const customAttribute = getProvider('customAttributes').find(attr.id);
      return customAttribute
        ? `${customAttribute.label} > ${attr.value}${customAttribute.deactivated ? ' (Archived)' : ''}`
        : null;
    });
  };

  function getInitiatorName(initiator) {
    return (initiator?.type === Communicator.AGENT && getProvider('agents').findBy({ id: initiator.id })?.name) || null;
  }

  return {
    added: _.sortBy(getAttributeNames(item.content.added), attr => attr.toLowerCase()),
    initiatorName: getInitiatorName(item.initiator),
    currentAgentId: getProvider('currentAgent').get().id,
    item,
    removed: _.sortBy(getAttributeNames(item.content.removed), attr => attr.toLowerCase()),
  };
}

const CustomAttributeChangeItemContainer = connect(mapStateToProps)(CustomAttributeChangeItem);
CustomAttributeChangeItemContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default CustomAttributeChangeItemContainer;
