import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import connect from 'components/lib/connect';
import CloseReport from 'actions/reporting/close_report';
import DismissNotification from 'actions/notification/dismiss_notification';
import { embedTokenExpiringNotificationTimerDelaySeconds } from 'models/notification/embed_token_expiring_notification';
import NavigateTo from 'actions/current_location/navigate_to';
import RefreshEmbedTokens from 'actions/reporting/refresh_embed_tokens';
import ShowEmbedTokenExpiringNotification from 'actions/notification/show_embed_token_expiring_notification';

const embedTokenExpiringNotificationId = 'embed-token-expiring';

export const ReportStatus = Object.freeze({
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  RUNNING: 'RUNNING',
  RAN: 'RAN',
  UNMOUNTED: 'UNMOUNTED',
});

function EmbeddedReport({
  isCookielessLookerEmbedEnabled,
  kind,
  reportUrl,
  sessionReferenceTokenTtl,
  dismissEmbedTokenExpiringNotification,
  onStatusChange,
  onTimezoneChange,
  onTokenRequest,
  openReport,
  showEmbedTokenExpiringNotification,
}) {
  const iframeRef = useRef(null);

  const onMessageEvent = e => handleMessageEvent(e, { iframeRef, onStatusChange, onTimezoneChange, onTokenRequest });

  useEffect(() => {
    onStatusChange(ReportStatus.LOADING);

    if (!reportUrl) {
      openReport();
    }

    window.addEventListener('message', onMessageEvent);
    return () => {
      window.removeEventListener('message', onMessageEvent);
      onStatusChange(ReportStatus.UNMOUNTED);
    };
  }, []);

  useEffect(() => {
    if (isCookielessLookerEmbedEnabled && sessionReferenceTokenTtl && kind === 'DATASET') {
      const delay = (sessionReferenceTokenTtl - embedTokenExpiringNotificationTimerDelaySeconds) * 1000;

      const messageTimeout = setTimeout(() => {
        showEmbedTokenExpiringNotification(embedTokenExpiringNotificationId);
      }, delay);

      return () => {
        dismissEmbedTokenExpiringNotification();
        clearTimeout(messageTimeout);
      };
    }
  }, [sessionReferenceTokenTtl]);

  return (
    <iframe
      frameBorder="0"
      height="100%"
      id="lkr-embedded-report"
      ref={iframeRef}
      scrolling="no"
      src={reportUrl}
      width="100%"
    />
  );
}

export function handleMessageEvent(event, { iframeRef, onStatusChange, onTimezoneChange, onTokenRequest }) {
  if (event.origin === 'https://gladly.looker.com' || event.origin === 'https://gladlydev.cloud.looker.com') {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      return;
    }

    if (!data) {
      return;
    }

    switch (data.type) {
      case 'page:changed':
        if (data.page && data.page.type === 'dashboard') {
          if (data.page.absoluteUrl) {
            let absoluteUrl = new URL(data.page.absoluteUrl);
            let absoluteUrlSearchParams = new URLSearchParams(absoluteUrl.search);
            if (absoluteUrlSearchParams.has('query_timezone')) {
              let timezone = absoluteUrlSearchParams.get('query_timezone');
              timezone = timezone === 'user_timezone' || timezone === 'query_saved' ? '' : timezone;
              onTimezoneChange(timezone);
            }
          }
        }
        break;
      case 'dashboard:loaded':
      case 'explore:state:changed':
        onStatusChange(ReportStatus.LOADED);
        break;
      case 'dashboard:run:start':
        onStatusChange(ReportStatus.RUNNING);
        break;
      case 'dashboard:run:complete':
        onStatusChange(ReportStatus.RAN);
        break;
      case 'session:tokens:request': {
        const tokens = onTokenRequest();
        if (_.isEmpty(tokens)) {
          break;
        }

        const message = JSON.stringify({
          type: 'session:tokens',
          ...tokens,
        });
        iframeRef.current.contentWindow.postMessage(message, event.origin);
        break;
      }
      default:
        return;
    }
  }
}

EmbeddedReport.propTypes = {
  isCookielessLookerEmbedEnabled: PropTypes.bool,
  kind: PropTypes.string,
  reportUrl: PropTypes.string,
  sessionReferenceTokenTtl: PropTypes.number,
  dismissEmbedTokenExpiringNotification: PropTypes.func,
  onStatusChange: PropTypes.func,
  openReport: PropTypes.func,
  onTimezoneChange: PropTypes.func,
  onTokenRequest: PropTypes.func,
  showEmbedTokenExpiringNotification: PropTypes.func,
};

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const isCookielessLookerEmbedEnabled = isFeatureEnabled('cookielessLookerEmbed');
  const embeddedReportUrl = _.get(getProvider('embeddedReport').get(), 'expiringUrl.url');
  const embedTokensReportProvider = getProvider('embedTokensReport');
  const cookielessUrl = _.get(embedTokensReportProvider.get(), 'url');
  const reportUrl = isCookielessLookerEmbedEnabled ? cookielessUrl : embeddedReportUrl;

  return {
    isCookielessLookerEmbedEnabled,
    reportUrl,
    sessionReferenceTokenTtl: _.get(embedTokensReportProvider.get(), 'sessionReferenceTokenTtl'),
    currentLocationProvider: getProvider('currentLocation'),
    embedTokensReportProvider,
  };
}

function mapExecuteToProps(executeAction, { onStatusChange }) {
  return {
    dismissEmbedTokenExpiringNotification: () => {
      executeAction(DismissNotification, embedTokenExpiringNotificationId);
    },
    onTimezoneChange: (currentLocationProvider, timezone) => {
      const currentLocation = currentLocationProvider.get();
      if (currentLocation.getTimezone() !== timezone) {
        executeAction(NavigateTo, currentLocation.deriveNew({ timezone }));
      }
    },
    onStatusChange: handleStatusChange,
    openReport: currentLocationProvider => executeAction(NavigateTo, currentLocationProvider.get()),
    onTokenRequest: embedTokensReportProvider => {
      const report = embedTokensReportProvider.get();
      const tokenExpirationTimeRemaining = moment(report.tokenExpiry).diff(moment.now(), 'seconds');

      if (tokenExpirationTimeRemaining < 100) {
        executeAction(RefreshEmbedTokens, { apiToken: report.apiToken, navigationToken: report.navigationToken });
        return;
      }

      return {
        api_token: report.apiToken,
        api_token_ttl: report.apiTokenTtl,
        navigation_token: report.navigationToken,
        navigation_token_ttl: report.navigationTokenTtl,
        session_reference_token_ttl: report.sessionReferenceTokenTtl,
      };
    },
    showEmbedTokenExpiringNotification: id => {
      executeAction(ShowEmbedTokenExpiringNotification, id);
    },
  };

  function handleStatusChange(newStatus) {
    if (newStatus === ReportStatus.UNMOUNTED) {
      executeAction(CloseReport);
    }

    onStatusChange(newStatus);
  }
}

function mergeProps(stateProps, executeProps, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...executeProps,
    onTimezoneChange: timezone => executeProps.onTimezoneChange(stateProps.currentLocationProvider, timezone),
    openReport: () => executeProps.openReport(stateProps.currentLocationProvider),
    onTokenRequest: () => executeProps.onTokenRequest(stateProps.embedTokensReportProvider),
  };
}

const EmbeddedReportContainer = connect(mapStateToProps, mapExecuteToProps, mergeProps)(EmbeddedReport);

EmbeddedReportContainer.defaultProps = {
  onStatusChange: _.noop,
};

EmbeddedReportContainer.propTypes = {
  onStatusChange: PropTypes.func,
};

export default EmbeddedReportContainer;
