import i18n from 'scripts/i18n/i18n';

// Load routing item strings.
// While English is the only language it's easier to maintain the strings here, at
// the place where they are used.
// When languages other than English are added it would be best to relocate this
// bundle to the root with other i18n strings.
i18n.addResourceBundle('en', 'translation', {
  routingItem: {
    _DEFAULT: 'Routed',
    ABANDONED_CALL: {
      // backward compatibility
      _DEFAULT: 'Routed because of abandoned call',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of abandoned call',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> because of abandoned call',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because of abandoned call',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> because of abandoned call',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of abandoned call',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> because of abandoned call',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> because of abandoned call',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> because of abandoned call',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of abandoned call',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> because of abandoned call',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because of abandoned call',
      },
    },
    AGENT_REQUEST: {
      _DEFAULT: 'Routed on request',
      toAgent: {
        fromAgent: 'Routed to <agent>{{agent}}</agent> because {{priorAgent}} was offline',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> on request',
        fromYou: 'Routed to <agent>{{agent}}</agent> because you were offline',
      },
      toYou: {
        fromAgent: 'Routed to <agent>you</agent> because {{priorAgent}} was offline',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> on request',
      },
    },
    AUTOMATED_ASSIGNMENT: {
      _DEFAULT: 'Routed by rule',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> by <rule>rule</rule>',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> by <rule>rule</rule>',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> by <rule>rule</rule>',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> by <rule>rule</rule>',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> by <rule>rule</rule>',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> by <rule>rule</rule>',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> by <rule>rule</rule>',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> by <rule>rule</rule>',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> by <rule>rule</rule>',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> by <rule>rule</rule>',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> by <rule>rule</rule>',
      },
    },
    BLIND_TRANSFER: {
      _DEFAULT: 'Routed because of the call transfer',
      toAgent: {
        _DEFAULT: {
          _DEFAULT: '<agent>{{priorAgent}}</agent> transferred the call to <agent>{{agent}}</agent>',
          byAgent: '<agent>{{initiator}}</agent> transferred the call to <agent>{{agent}}</agent>',
          byYou: '<agent>You</agent> transferred the call to <agent>{{agent}}</agent>',
        },
        fromYou: {
          _DEFAULT: '$t(routingItem.BLIND_TRANSFER.toAgent._DEFAULT.byYou)',
          byAgent: '$t(routingItem.BLIND_TRANSFER.toAgent._DEFAULT.byAgent)',
        },
      },
      toInbox: {
        _DEFAULT: {
          _DEFAULT: '<agent>{{priorAgent}}</agent> transferred the call to <inbox>{{inbox}}</inbox>',
          byAgent: '<agent>{{initiator}}</agent> transferred the call to <inbox>{{inbox}}</inbox>',
          byYou: '<agent>You</agent> transferred the call to <inbox>{{inbox}}</inbox>',
        },
        fromYou: {
          _DEFAULT: '$t(routingItem.BLIND_TRANSFER.toInbox._DEFAULT.byYou)',
          byAgent: '$t(routingItem.BLIND_TRANSFER.toInbox._DEFAULT.byAgent)',
        },
      },
      toYou: {
        _DEFAULT: {
          _DEFAULT: '<agent>{{priorAgent}}</agent> transferred the call to <agent>you</agent>',
          byAgent: '<agent>{{initiator}}</agent> transferred the call to <agent>you</agent>',
          byYou: '<agent>You</agent> transferred the call to <agent>you</agent>',
        },
      },
    },
    CALL_DEVICE_ERROR: {
      _DEFAULT: 'Routed because <help>Gladly Voice browser connection failed</help>',
      toAgent: 'Routed to <agent>{{agent}}</agent> because <help>Gladly Voice browser connection failed</help>',
      toInbox: 'Routed to <inbox>{{inbox}}</inbox> because <help>Gladly Voice browser connection failed</help>',
      toYou: 'Routed to <agent>you</agent> because <help>Gladly Voice browser connection failed</help>',
    },
    CONVERSATION_MERGE: {
      _DEFAULT: 'Routed because of conversation merge',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of conversation merge',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> because of conversation merge',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because of conversation merge',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> because of conversation merge',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of conversation merge',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> because of conversation merge',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> because of conversation merge',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> because of conversation merge',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of conversation merge',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> because of conversation merge',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because of conversation merge',
      },
    },
    CUSTOMER_ASSIGNEE_ASSIGNMENT: {
      _DEFAULT: 'Routed because of dedicated hero assignment',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of dedicated hero assignment',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> because of dedicated hero assignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because of dedicated hero assignment',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> because of dedicated hero assignment',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of dedicated hero assignment',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> because of dedicated hero assignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> because of dedicated hero assignment',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> because of dedicated hero assignment',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of dedicated hero assignment',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> because of dedicated hero assignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because of dedicated hero assignment',
      },
    },
    CUSTOMER_ASSIGNEE_CHANGE: {
      _DEFAULT: 'Routed due to updated dedicated hero assignment',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> due to updated dedicated hero assignment',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> due to updated dedicated hero assignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> due to updated dedicated hero assignment',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> due to updated dedicated hero assignment',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> due to updated dedicated hero assignment',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> due to updated dedicated hero assignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> due to updated dedicated hero assignment',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> due to updated dedicated hero assignment',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> due to updated dedicated hero assignment',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> due to updated dedicated hero assignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> due to updated dedicated hero assignment',
      },
    },
    DECLINED_OVER_CAPACITY_CHAT: {
      _DEFAULT: 'Routed because prior agent exceeded chat capacity',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> prior agent exceeded chat capacity',
        fromAgent: 'Routed to <agent>{{agent}}</agent> because <agent>{{priorAgent}}</agent> exceeded chat capacity',
        fromYou: 'Routed to <agent>{{agent}}</agent> because <agent>you</agent> exceeded chat capacity',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because prior agent exceeded chat capacity',
        fromAgent: 'Routed to <inbox>{{inbox}}</inbox> because <agent>{{priorAgent}}</agent> exceeded chat capacity',
        fromYou: 'Routed to <inbox>{{inbox}}</inbox> because <agent>you</agent> exceeded chat capacity',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because prior agent',
        fromAgent: 'Routed to <agent>you</agent> because <agent>{{priorAgent}}</agent> exceeded chat capacity',
      },
    },
    DECLINED_REASSIGNED_CHAT: {
      _DEFAULT: 'Agent declined the assignment',
      toAgent: '<agent>{{agent}}</agent> declined the assignment',
      toYou: '<agent>You</agent> declined the assignment',
    },
    INBOUND: {
      _DEFAULT: 'Routed because of inbound {{interactionMessage}}',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of inbound {{interactionMessage}}',
        fromAgent: '$t(routingItem.INBOUND.toAgent.fromInbox)',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because of inbound {{interactionMessage}}',
        fromYou: '$t(routingItem.INBOUND.toAgent.fromInbox)',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of inbound {{interactionMessage}}',
        fromAgent: '$t(routingItem.INBOUND.toInbox.fromInbox)',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> because of inbound {{interactionMessage}}',
        fromYou: '$t(routingItem.INBOUND.toInbox.fromInbox)',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of inbound {{interactionMessage}}',
        fromAgent: '$t(routingItem.INBOUND.toYou.fromInbox)',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because of inbound {{interactionMessage}}',
      },
    },
    INBOUND_MATCH: {
      _DEFAULT: 'Routed to next available agent for {{interactionChannel}}',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because they were next available for {{interactionChannel}}',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because they were next available for {{interactionChannel}}',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because you were next available for {{interactionChannel}}',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because you were next available for {{interactionChannel}}',
      },
    },
    MANUAL_ASSIGNMENT: {
      _DEFAULT: 'Routed via manual transfer',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> via manual transfer',
        fromAgent: {
          _DEFAULT: 'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent>',
          byAgent:
            'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> by <agent>you</agent>',
        },
        fromInbox: {
          _DEFAULT: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent>',
          byAgent:
            'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> by <agent>you</agent>',
        },
        fromYou: {
          _DEFAULT: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent>',
          byAgent: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> by <agent>you</agent>',
        },
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> via manual transfer',
        fromAgent: {
          _DEFAULT: 'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox>',
          byAgent:
            'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> by <agent>you</agent>',
        },
        fromInbox: {
          _DEFAULT: 'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox>',
          byAgent:
            'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> by <agent>you</agent>',
        },
        fromYou: {
          _DEFAULT: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox>',
          byAgent: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> by <agent>you</agent>',
        },
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> via manual transfer',
        fromAgent: {
          _DEFAULT: 'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent>',
          byAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> by <agent>you</agent>',
        },
        fromInbox: {
          _DEFAULT: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent>',
          byAgent: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> by <agent>you</agent>',
        },
        fromYou: {
          _DEFAULT: 'Routed from <agent>you</agent> to <agent>you</agent>',
          byAgent: 'Routed from <agent>you</agent> to <agent>you</agent> by <agent>{{initiator}}</agent>',
          byYou: 'Routed from <agent>you</agent> to <agent>you</agent> by <agent>you</agent>',
        },
      },
    },
    MESSAGE_AUTOMATION_HANDOFF: {
      _DEFAULT: 'Routed because of message automation handoff',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of message automation handoff',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> because of message automation handoff',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because of message automation handoff',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> because of message automation handoff',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of message automation handoff',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> because of message automation handoff',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> because of message automation handoff',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> because of message automation handoff',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of message automation handoff',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> because of message automation handoff',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because of message automation handoff',
      },
    },
    OFFER_CONFLICT: {
      _DEFAULT: 'Routed because of conflicting offer',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of conflicting offer',
        fromAgent: 'Routed to <agent>{{agent}}</agent> because <agent>{{priorAgent}}</agent> had a conflicting offer',
        fromYou: 'Routed to <agent>{{agent}}</agent> because <agent>you</agent> had a conflicting offer',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of conflicting offer',
        fromAgent: 'Routed to <inbox>{{inbox}}</inbox> because <agent>{{priorAgent}}</agent> had a conflicting offer',
        fromYou: 'Routed to <inbox>{{inbox}}</inbox> because <agent>you</agent> had a conflicting offer',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of conflicting offer',
        fromAgent: 'Routed to <agent>you</agent> because <agent>{{priorAgent}}</agent> had a conflicting offer',
      },
    },
    OFFER_DECLINE: {
      _DEFAULT: 'Routed because of declined {{interactionMessage}}',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of declined {{interactionMessage}}',
        fromAgent:
          'Routed to <agent>{{agent}}</agent> because <agent>{{priorAgent}}</agent> declined {{interactionMessage}}',
        fromYou: 'Routed to <agent>{{agent}}</agent> because <agent>you</agent> declined {{interactionMessage}}',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of declined {{interactionMessage}}',
        fromAgent:
          'Routed to <inbox>{{inbox}}</inbox> because <agent>{{priorAgent}}</agent> declined {{interactionMessage}}',
        fromYou: 'Routed to <inbox>{{inbox}}</inbox> because <agent>you</agent> declined {{interactionMessage}}',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of declined {{interactionMessage}}',
        fromAgent: 'Routed to <agent>you</agent> because <agent>{{priorAgent}}</agent> declined {{interactionMessage}}',
      },
    },
    OFFER_MISS: {
      // Override texts for Twitter because OFFER_MISS uses "Twitter message"
      // instead of "Twitter DM" for inbound and offer decline.
      _DEFAULT: 'Routed because of missed {{interactionMessage}}',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of missed {{interactionMessage}}',
        fromAgent:
          'Routed to <agent>{{agent}}</agent> because <agent>{{priorAgent}}</agent> missed {{interactionMessage}}',
        fromYou: 'Routed to <agent>{{agent}}</agent> because <agent>you</agent> missed {{interactionMessage}}',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of missed {{interactionMessage}}',
        fromAgent:
          'Routed to <inbox>{{inbox}}</inbox> because <agent>{{priorAgent}}</agent> missed {{interactionMessage}}',
        fromYou: 'Routed to <inbox>{{inbox}}</inbox> because <agent>you</agent> missed {{interactionMessage}}',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of missed {{interactionMessage}}',
        fromAgent: 'Routed to <agent>you</agent> because <agent>{{priorAgent}}</agent> missed {{interactionMessage}}',
      },
    },
    OUTBOUND: {
      _DEFAULT: 'Routed because of outbound {{interactionMessage}}',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of outbound {{interactionMessage}}',
        fromAgent: '$t(routingItem.OUTBOUND.toAgent.fromInbox)',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because of outbound {{interactionMessage}}',
        fromYou: '$t(routingItem.OUTBOUND.toAgent.fromInbox)',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because of outbound {{interactionMessage}}',
        fromAgent: '$t(routingItem.OUTBOUND.toInbox.fromInbox)',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> because of outbound {{interactionMessage}}',
        fromYou: '$t(routingItem.OUTBOUND.toInbox.fromInbox)',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of outbound {{interactionMessage}}',
        fromAgent: '$t(routingItem.OUTBOUND.toYou.fromInbox)',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because of outbound {{interactionMessage}}',
      },
    },
    QUEUED_TRANSFER: {
      _DEFAULT: 'Routed by system',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> by system',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> by system',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> by system',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> by system',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> by system',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> by system',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox> by system',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> by system',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> by system',
        fromAgent: 'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> by system',
        fromInbox: 'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> by system',
      },
    },
    REASSIGNED_CHAT: {
      _DEFAULT: 'Routed because of reassigned chat',
      toAgent: {
        _DEFAULT: 'Assigned to <agent>{{agent}}</agent>',
        fromAgent: 'Assigned from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent>',
        fromInbox: 'Assigned from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent>',
        fromYou: 'Assigned from <agent>you</agent> to <agent>{{agent}}</agent>',
      },
      toInbox: {
        _DEFAULT: 'Assigned to <inbox>{{inbox}}</inbox>',
        fromAgent: 'Assigned from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox>',
        fromInbox: 'Assigned from <inbox>{{priorInbox}}</inbox> to <inbox>{{inbox}}</inbox>',
        fromYou: 'Assigned from <agent>you</agent> to <inbox>{{inbox}}</inbox>',
      },
      toYou: {
        _DEFAULT: 'Assigned to <agent>you</agent>',
        fromAgent: 'Assigned from <agent>{{priorAgent}}</agent> to <agent>you</agent>',
        fromInbox: 'Assigned from <inbox>{{priorInbox}}</inbox> to <agent>you</agent>',
      },
    },
    REROUTED_OFFLINE: {
      _DEFAULT: 'Routed because prior agent went offline',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because prior agent went offline',
        fromAgent: 'Routed to <agent>{{agent}}</agent> because <agent>{{priorAgent}}</agent> went offline',
        fromYou: 'Routed to <agent>{{agent}}</agent> because <agent>you</agent> went offline',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because prior agent went offline',
        fromAgent: 'Routed to <agent>you</agent> because <agent>{{priorAgent}}</agent> went offline',
      },
    },
    REROUTED_UNASSIGNED: {
      _DEFAULT: 'Routed because of conversation reassignment',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because of conversation reassignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>{{agent}}</agent> because of conversation reassignment',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because of conversation reassignment',
        fromInbox:
          'Routed from <inbox>{{priorInbox}}</inbox> to <agent>you</agent> because of conversation reassignment',
      },
    },
    SMART_MATCH: {
      toAgent: 'Matched with {{agent}}',
      toYou: 'Matched with You',
    },
    UNASSIGNED_NON_MEMBER: {
      _DEFAULT: `Routed from agent to inbox because agent wasn't in inbox`,
      toInbox: {
        _DEFAULT: 'Routed from agent to <inbox>{{inbox}}</inbox> because agent was not in the inbox',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> because {{priorAgent}} was not in the inbox',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> because you were not in the inbox',
      },
    },
    UNASSIGNED_OFFLINE: {
      _DEFAULT: 'Routed because prior agent went offline',
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> prior agent went offline',
        fromAgent: 'Routed to <inbox>{{inbox}}</inbox> because <agent>{{priorAgent}}</agent> went offline',
        fromYou: 'Routed to <inbox>{{inbox}}</inbox> because <agent>you</agent> went offline',
      },
    },
    UNASSIGNED_UNAVAILABLE: {
      _DEFAULT: 'Routed from agent to inbox because agent was unavailable for {{interactionChannel}}',
      toInbox: {
        _DEFAULT:
          'Routed from agent to <inbox>{{inbox}}</inbox> because agent was unavailable for {{interactionChannel}}',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> because {{priorAgent}} was unavailable for {{interactionChannel}}',
        fromYou:
          'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> because you were unavailable for {{interactionChannel}}',
      },
    },
    USER_DISABLED: {
      _DEFAULT: 'Routed because prior user was deactivated',
      toAgent: {
        _DEFAULT: 'Routed to <agent>{{agent}}</agent> because prior user was deactivated',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>{{agent}}</agent> because {{priorAgent}} was deactivated',
        fromYou: 'Routed from <agent>you</agent> to <agent>{{agent}}</agent> because you were deactivated',
      },
      toInbox: {
        _DEFAULT: 'Routed to <inbox>{{inbox}}</inbox> because prior user was deactivated',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <inbox>{{inbox}}</inbox> because {{priorAgent}} was deactivated',
        fromYou: 'Routed from <agent>you</agent> to <inbox>{{inbox}}</inbox> because you were deactivated',
      },
      toYou: {
        _DEFAULT: 'Routed to <agent>you</agent> because prior user was deactivated',
        fromAgent:
          'Routed from <agent>{{priorAgent}}</agent> to <agent>you</agent> because {{priorAgent}} was deactivated',
      },
    },
  },
  routingItemInteraction: {
    _DEFAULT: 'communication',
    CHAT: 'chat',
    EMAIL: 'email',
    FB_MESSENGER: 'Facebook message',
    INSTAGRAM_DIRECT: 'Instagram message',
    PHONE_CALL: 'call',
    SMS: 'SMS',
    TWITTER: 'Twitter DM',
    VOICEMAIL: 'voicemail',
    WHATSAPP: 'WhatsApp message',
  },
  routingItemTooltip: {
    SMART_MATCH: 'Matched because of {{rules}}',
  },
});
