import React from 'react';
import styled from 'styled-components';
import FloorboardNumber from './floorboard_number';
import FloorboardPercent from './floorboard_percent';
import ReportDuration from 'components/reporting/report/lib/report_duration';

export default function FloorboardRow({
  icon,
  newItems,
  activeItems,
  currentWait,
  sla,
  activeLabel,
  newLabel,
  waitLabel,
}) {
  return (
    <React.Fragment>
      <IconWrapper>{icon}</IconWrapper>
      <FloorboardNumber label={newLabel} number={newItems} />
      <FloorboardNumber label={activeLabel} number={activeItems} />
      <FloorboardNumber animate={false} label={waitLabel} number={<ReportDuration duration={currentWait} />} />
      <FloorboardPercent label="Within SLA" sla={sla} />
    </React.Fragment>
  );
}

const IconWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.colors.gray600};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;
  .iconContainer {
    height: auto;
    width: 3vw;
    fill: ${p => p.theme.colors.white};
    margin-right: 40px;
  }
`;
