import MessagingConfiguration from 'models/messaging_configuration';
import qconsole from 'scripts/lib/qconsole';
import ShowFocusModeDisabledNotification from 'actions/notification/show_focus_mode_disabled_notification';

export default class MessagingConfigurationGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.messagingConfiguration;
  }

  updateStore(dto) {
    const current = this.store.get();
    const updated = MessagingConfiguration.fromJs(dto);
    if (
      current &&
      current.enableFocusMode &&
      !updated.enableFocusMode &&
      this.context.stores.appFeatures.get().isEnabled('externalAgentActions')
    ) {
      this.context.executeAction(ShowFocusModeDisabledNotification);
    }

    this.store.set(updated);
    this.store.resetLoading();
  }

  onBroadcast(dto) {
    if (!this.store.isPending()) {
      this.updateStore(dto);
    }
  }

  onFetchSuccess(dto) {
    this.updateStore(dto);
  }

  onFetchError(error) {
    qconsole.log(`Failed to fetch messaging configuration: ${JSON.stringify(error)}`);
    this.store.resetLoading();
  }

  onBroadcastDelete() {
    if (!this.store.isPending()) {
      this.store.remove();
    }
  }

  onReplaceSuccess() {
    this.store.commitPending();
  }

  onReplaceError(error) {
    qconsole.log(`Failed to replace messaging configuration: ${JSON.stringify(error)}`);
    this.store.resetPending();
  }
}
