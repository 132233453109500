import _ from 'lodash';

import CompositionConverter from 'scripts/application/dto_converters/composition_converter';

export const STORAGE_KEY = 'compositions';

export default class LocalCompositionsStore {
  constructor(sessionStorage) {
    this.sessionStorage = sessionStorage;
  }

  set(customerId, composition) {
    const json = JSON.stringify(CompositionConverter.toDto(composition));
    // A customer can only have one composition of each type open at a time.
    this.sessionStorage.setItem(`${STORAGE_KEY}-${customerId}-${composition.id}`, json);
  }

  has(customerId) {
    return this._keys(customerId).length > 0;
  }

  getAll() {
    return this._getAll();
  }

  get(customerId) {
    return this._getAll(customerId);
  }

  remove(customerId, compositionId) {
    this._removeAll(customerId, compositionId);
  }

  removeAllForCustomer(customerId) {
    this._removeAll(customerId);
  }

  removeAll() {
    this._removeAll();
  }

  // _keys returns all keys in session storage that match the (optional) provided
  // customerId and composition
  _keys(customerId, compositionId) {
    const additionalPrefix = _.filter([customerId, compositionId]).join('-');
    return _.keys(this.sessionStorage).filter(k => k.startsWith(`${STORAGE_KEY}-${additionalPrefix}`));
  }

  _getAll(customerId, composition) {
    return this._keys(customerId, composition).map(key =>
      CompositionConverter.fromDto(JSON.parse(this.sessionStorage.getItem(key)))
    );
  }

  _removeAll(customerId, compositionId) {
    this._keys(customerId, compositionId).forEach(key => {
      this.sessionStorage.removeItem(key);
    });
  }
}
