import Assignee from '../assignee';
import createModel, { prop } from '../lib/create_model';
import NotificationType from './notification_type';
import { SessionCustomer } from '../active_session';
import IdGenerator from 'scripts/domain/contracts/id_generator';

export default createModel({
  modelName: 'DeclinedReassignedSessionNotification',

  properties: {
    id: prop(String),
    profile: prop(SessionCustomer),
    conversationId: prop(String),
    transferee: prop(Assignee),
    sessionType: prop(String),
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: NotificationType.DECLINED_REASSIGNED_SESSION });
  },

  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), ...attrs });
    },
  },
});
