import _ from 'lodash';
import Liveboards from 'models/location/liveboards';

export default {
  fromUrlParameters({ type, groups, teams, timezone }) {
    return _.omitBy(
      {
        type: type && _.snakeCase(type).toUpperCase(),
        routingGroupIds: groups && _.filter(groups),
        teamIds: teams && _.filter(teams),
        timezone,
      },
      _.isNil
    );
  },

  getPath(liveboards) {
    return liveboards.type === Liveboards.defaultType
      ? '/liveboards'
      : `/liveboards/${_.kebabCase(liveboards.type).toLowerCase()}`;
  },

  getQueryParams(liveboards) {
    let queryParams = {};
    if (liveboards.routingGroupIds) {
      queryParams.groups = liveboards.routingGroupIds;
    }
    if (liveboards.teamIds) {
      queryParams.teams = liveboards.teamIds;
    }
    if (liveboards.timezone) {
      queryParams.timezone = liveboards.timezone;
    }
    return queryParams;
  },
};
