import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import SearchableTaskAssignmentMenu from 'components/lib/assignment_menu/searchable_task_assignment_menu';
import UpdateTaskCompositionAssignee from 'actions/composition/update_task_composition_assignee';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const StyledSearchableTaskAssignmentMenu = styled(SearchableTaskAssignmentMenu)`
  margin-left: 4px;
`;

export default function AssigneeSelector() {
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();
  const assignee = composition.content.assignee;
  const compositionId = composition.id;
  const onChangeAssignee = useCallback(
    attrs => {
      executeAction(UpdateTaskCompositionAssignee, {
        compositionId,
        customerId: composition.customerId,
        agentId: attrs.agentId,
        routingGroupId: attrs.routingGroupId,
      });
    },
    [compositionId, executeAction]
  );
  return (
    <StyledSearchableTaskAssignmentMenu
      agentId={assignee && assignee.agentId}
      label="Assign to"
      onSelect={onChangeAssignee}
      routingGroupId={assignee && assignee.routingGroupId}
    />
  );
}
