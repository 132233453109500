import _ from 'lodash';

export const _OutstandingSubscribes = {};

export function getSubscription(itemId) {
  return _OutstandingSubscribes[itemId] || {};
}

export function isSubscribedToItem(itemId) {
  return !!_OutstandingSubscribes[itemId];
}

export function removeSubscription(itemId) {
  delete _OutstandingSubscribes[itemId];
}

export function setSubscription(itemId, subscription) {
  _OutstandingSubscribes[itemId] = subscription;
}

export function clearSubscriptions() {
  _.keys(_OutstandingSubscribes).forEach(key => {
    delete _OutstandingSubscribes[key];
  });
}
