import { FlexibleCardConfigurationType } from 'models/flexible_card';

import { template as loopTemplate, dataConfiguration as loopDataConfig } from './loop_returns';

/**
 * A "catalog" of all Flexible Card configurations that AD knows and can render.
 *
 * When adding a new configuration, please make sure to
 *   1. Add it to the `FlexibleCardConfigurationType` enum
 *   2. Put the template into its own file in this folder
 *   2. Add the corresponding entry here
 */
const configurations = {
  [FlexibleCardConfigurationType.LOOP_RETURNS]: { template: loopTemplate, dataConfiguration: loopDataConfig },
};

/**
 * Helper function to look up the Flexible Card configuration block by the "key" (which normally comes
 * from the `customerProfileDef`)
 *
 * @param {string} configKey - should be one of `FlexibleCardConfigurationType` values
 * @returns {object|undefined}
 */
export function getFlexibleCardConfiguration(configKey) {
  return configurations[configKey];
}
