import _ from 'lodash';

import { AgentAvailability } from 'models/agent_status';
import analytics from 'scripts/lib/analytics';
import BeginLogOut from 'actions/user/begin_log_out';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import LogActivity from 'actions/agent_activity/log_activity';
import qconsole from 'scripts/lib/qconsole';
import { RoutingChannel } from 'models/agent_routing_preferences';
import { updateStore } from 'actions/agent_status/lib/agent_status';

export default class AgentStatusGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  onBroadcast(dto) {
    this.handleUpdate(dto);
  }

  onFetchError(err) {
    this.errorHandler.handleCommonErrors(err);
  }

  onFetchSuccess(dto) {
    this.handleUpdate(dto);
  }

  handleUpdate(dto) {
    if (this.isLoggingIn() && _(dto).get('status.availability') === AgentAvailability.OFFLINE) {
      qconsole.log('Ignored OFFLINE agent status during login');
      return;
    }

    let previous = updateStore(this.context, dto);

    let current = this.store.get();
    if (current._version === dto._version && current.getAvailability() === AgentAvailability.OFFLINE) {
      this.context.executeAction(BeginLogOut);
      return;
    }

    if (
      previous.getAvailability() !== AgentAvailability.AWAY &&
      previous.getAvailability() !== AgentAvailability.OFFLINE &&
      current.getAvailability() === AgentAvailability.AWAY
    ) {
      this.resetNextCallRoutingTimer();
      analytics.track('Went Away', { cause: 'automatic' });
    }

    // reset countdown timer if agent went from BUSY to READY for VOICE
    if (
      previous.getAvailability() === AgentAvailability.BUSY &&
      current.getAvailability() === AgentAvailability.READY &&
      current.getReadyChannels().indexOf(RoutingChannel.VOICE) !== -1
    ) {
      this.resetNextCallRoutingTimer();
    }

    this.logPendingActivity(previous);
  }

  resetNextCallRoutingTimer() {
    let routingPreferences = this.context.stores.agentRoutingPreferences.get();
    if (routingPreferences.nextCallRoutingTime) {
      routingPreferences.setNextCallRoutingTime('');
      this.context.stores.agentRoutingPreferences.set(routingPreferences);
    }
  }

  isLoggingIn() {
    return (
      this.store.get()._version === 0 &&
      _(this.store.getPending()).get('status.availability') === AgentAvailability.BUSY
    );
  }

  // The following code was transferred from the original implementation.
  // It appears to be logging the last activity when the status changes
  // from OFFLINE because activity logging is turned off in OFFLINE.
  // Why it's needed and why we only care about just a single arbitrary
  // previous activity isn't clear.
  logPendingActivity(previousStatus) {
    if (previousStatus.getAvailability() === AgentAvailability.OFFLINE) {
      this.context.executeAction(LogActivity);
    }
  }

  get store() {
    return this.context.stores.agentStatus;
  }
}
