import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import filter from 'lodash/filter';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import COLORS, { GRAYS } from 'components/common/colors';
import ProfileCard from './profile_card';

export default class ExpandableProfileCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
    this.onClickExpander = this.onClickExpander.bind(this);
  }

  render() {
    const { limit, children, header, ...rest } = this.props;
    const childrenArray = isArrayLike(children) ? children : [children];
    const compactedChildren = filter(childrenArray, child => !isNil(child));

    return (
      <ProfileCard {...rest} footer={this.renderExpander({ children: compactedChildren, limit })}>
        {header}
        {this.renderChildren({ children: compactedChildren, limit })}
      </ProfileCard>
    );
  }

  renderChildren({ children, limit }) {
    if (isEmpty(children)) return null;

    const fewerThanLimit = children.length <= limit;
    return (
      <React.Fragment>{fewerThanLimit || this.state.isExpanded ? children : children.slice(0, limit)}</React.Fragment>
    );
  }

  renderExpander({ children, limit }) {
    if (isEmpty(children)) return null;

    return children.length <= limit ? null : (
      <CardExpander isExpanded={this.state.isExpanded} onClick={this.onClickExpander} />
    );
  }

  onClickExpander() {
    this.setState(({ isExpanded }) => {
      return { isExpanded: !isExpanded };
    });
  }
}

export function CardExpander({ isExpanded, onClick }) {
  const expandedState = isExpanded ? 'expanded' : 'collapsed';
  return (
    <StyledCardExpander data-aid="card-expander" data-state={expandedState} onClick={onClick}>
      <StyledChevron className="ion-chevron-up" isDownwards={!isExpanded} onMouseDown={onMouseDown} />
    </StyledCardExpander>
  );
}

function onMouseDown(e) {
  e.preventDefault(); // Prevents selecting text if you click too much
}

export const StyledCardExpander = styled.div`
  border-bottom-left-radius: ${p => p.theme.borderRadius.default};
  border-bottom-right-radius: ${p => p.theme.borderRadius.default};
  border-top: 1px solid ${GRAYS.xxxlight};
  color: ${GRAYS.light};
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover {
    background: ${GRAYS.xxxxlight};
    color: ${COLORS.green};
  }
`;

export const StyledChevron = styled.div`
  cursor: pointer;
  display: inline-block;
  transition: transform 0.15s ease;
  ${p => (p.isDownwards ? 'transform: rotate(180deg)' : 'transform: none')};
`;

CardExpander.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ExpandableProfileCard.propTypes = {
  ...ProfileCard.propTypes,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  header: PropTypes.node,
  limit: PropTypes.number,
};

ExpandableProfileCard.defaultProps = {
  children: [],
  limit: 3,
};
