import _ from 'lodash';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';

const AGENT_OPTIONS = {
  distance: 100,
  location: 0,
  keys: ['name', 'email'],
  shouldSort: true,
  threshold: 0.2,
};

export class AgentSearchCache extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getAgentFuzzySearch(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.agentsImm !== this.props.agentsImm ||
      !_.isEqual(nextProps.excludedAgentIds, this.props.excludedAgentIds) ||
      nextProps.allowDisabled !== this.props.allowDisabled
    ) {
      this.setState(this.getAgentFuzzySearch(nextProps));
    }
  }

  getAgentFuzzySearch({ allowDisabled, agentsProvider, excludedAgentIds }) {
    const excludedIds = new Set(excludedAgentIds);
    const agents = agentsProvider
      .findAll({
        select: ['id', 'name', 'email'],
        filter: agent => (allowDisabled || !agent.disabledAt) && !excludedIds.has(agent.id),
      })
      .map(a => ({
        email: a.email,
        id: a.id,
        name: a.id === this.props.currentAgentId ? `${a.name} (You)` : a.name,
      }));

    const agentSearch = new Fuse(agents, AGENT_OPTIONS);
    return { agentSearch };
  }

  render() {
    return this.props.children({ agentSearch: this.state.agentSearch });
  }
}

AgentSearchCache.propTypes = {
  allowDisabled: PropTypes.bool,
  agentsImm: PropTypes.object.isRequired,
  agentsProvider: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
  currentAgentId: PropTypes.string,
  excludedAgentIds: PropTypes.arrayOf(PropTypes.string),
};

const AgentSearchContainer = connect(mapStateToProps)(AgentSearchCache);

function mapStateToProps({ getProvider }) {
  return {
    agentsImm: getProvider('agents').immutableStore.binding.get(),
    agentsProvider: getProvider('agents'),
    currentAgentId: getProvider('currentAgent').get().id,
  };
}

export default AgentSearchContainer;
