import _ from 'lodash';
import { ContentState, EditorState, Modifier } from 'draft-js';
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import connect from 'components/lib/connect';
import { ensureFullSelection, updateEntities, IMMUTABLE } from './draft_shared';
import { findEntities } from './draft_entity';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllLinkedAnswerIds } from 'models/answers/snippet_helpers';
import { getCurrentMention } from './draft_mention';
import NavigateAnswerPanelForward from 'actions/customer/navigate_answer_panel_forward';
import Tooltip from 'components/common/tooltip';
import Snippet from 'models/answers/snippet';

export const ENTITY_TYPE_ANSWER = 'ANSWER';
export const findAnswerEntities = findEntities(ENTITY_TYPE_ANSWER);
export const INVALID_ANSWER_LINK = 'Invalid Answer Link';
const LOADING_ANSWER_LINK = 'Loading answer link';

export const DraftAnswer = props => {
  if (props.isLoading) {
    return <span className="draftEditor-answer-placeholder">{props.children}</span>;
  }

  if (props.answerName === INVALID_ANSWER_LINK) {
    return <span className="draftEditor-answer-invalid">{props.children}</span>;
  }

  let tooltipMessage = props.isEditingSnippets ? 'This link will take agents to this answer' : '';
  let classNames = classnames('draftEditor-answer', {
    'draftEditor-answer-readOnly': props.isEditingSnippets,
  });

  return (
    <span
      className={classNames}
      onClick={props.isEditingSnippets ? undefined : props.onClickLink.bind(this, props.answerId)}
    >
      <Tooltip message={tooltipMessage} position="top" usePortal>
        <div className="draftEditor-answer-tooltip">
          <FontAwesomeIcon className="draftEditor-answer-icon" icon={faBook} />
          {props.children}
        </div>
      </Tooltip>
    </span>
  );
};

DraftAnswer.propTypes = {
  answerId: PropTypes.string,
  answerName: PropTypes.string,
  children: PropTypes.node,
  isEditingSnippets: PropTypes.bool,
  onClickLink: PropTypes.func,
};

export function ensureFullAnswerLinkSelection(editorState) {
  return ensureFullSelection(editorState, ENTITY_TYPE_ANSWER, findAnswerEntities);
}

/**
 * updateAnswerLinks() iterates through every ANSWER entity in editorState and updates it with the new
 * answer name if it is different.
 */
export function updateAnswerLinks(editorState, answerLinks) {
  return updateEntities(editorState, ENTITY_TYPE_ANSWER, answerLinks, findRangesNeedingUpdate);
}

function findRangesNeedingUpdate(contentState, block, answerLinks) {
  let updateRanges = [];

  let updateAnswerLink = (start, end) => {
    const entityKey = block.getEntityAt(start);
    const newAnswer = _.find(answerLinks, { id: contentState.getEntity(entityKey).getData().id });
    const currentAnswerName = block.getText().slice(start, end);
    if (newAnswer && newAnswer.name !== currentAnswerName) {
      updateRanges.push({ start, end, answer: newAnswer, entityKey, blockKey: block.getKey() });
    }
  };
  findAnswerEntities(block, updateAnswerLink, contentState);

  return updateRanges;
}

// Container

function mapStateToProps(context, props) {
  const entity = props.contentState.getEntity(props.entityKey);
  const data = entity && entity.getData();
  const { id, name, isEditingSnippets } = data;
  const isLoading = context.getProvider('snippetLinks').isLoading();

  return {
    ...props,
    answerId: id,
    answerName: name,
    isEditingSnippets,
    isLoading,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onClickLink: answerId => {
      executeAction(NavigateAnswerPanelForward, answerId);
    },
  };
}

const DraftAnswerContainer = connect(mapStateToProps, mapExecuteToProps)(DraftAnswer);
export default DraftAnswerContainer;

DraftAnswerContainer.propTypes = {
  contentState: PropTypes.instanceOf(ContentState),
  entityKey: PropTypes.string.isRequired,
};

export function insertAnswerLink(editorState, answer) {
  const { start, end } = getCurrentMention(editorState);

  const mentionSelection = editorState.getSelection().merge({
    anchorOffset: start,
    focusOffset: end,
  });
  return insertAnswerLinkAtSelection(editorState, answer, mentionSelection);
}

export function insertAnswerLinkAtSelection(editorState, answer, selection) {
  let contentState = editorState.getCurrentContent();
  let contentStateWithEntity = contentState.createEntity(ENTITY_TYPE_ANSWER, IMMUTABLE, {
    id: answer.id,
    name: answer.name,
    isEditingSnippets: true,
  });
  let text = answer.name;

  contentStateWithEntity = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    text,
    null,
    contentStateWithEntity.getLastCreatedEntityKey()
  );

  return EditorState.push(editorState, contentStateWithEntity, 'insert-fragment');
}

export function createAnswerLinksWithStatus(snippet, snippetLinks, isLoading) {
  if (!snippet) {
    return snippetLinks;
  }

  const answerLinkIds = getAllLinkedAnswerIds(snippet);
  let updatedLinks = snippetLinks;
  if (isLoading) {
    updatedLinks = _.map(answerLinkIds, id => {
      return Snippet.fromJs({ id, name: LOADING_ANSWER_LINK });
    });
    return updatedLinks;
  }

  _.forEach(answerLinkIds, id => {
    if (!_.find(snippetLinks, { id })) {
      updatedLinks.push(Snippet.fromJs({ id, name: INVALID_ANSWER_LINK }));
    }
  });
  return updatedLinks;
}
