import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="2 2 19 19" {...props}>
    <g className="addressIcon" fill="none" fillRule="evenodd">
      <path
        className="contactIcon-path"
        d="M15.8 9.434c0 2.172-3.9 8.13-3.9 8.13S8 11.605 8 9.433C8 7.262 9.747 5.5 11.9 5.5c2.153 0 3.9 1.762 3.9 3.934zm-2.34 0c0-.87-.698-1.574-1.56-1.574-.862 0-1.56.705-1.56 1.574 0 .87.698 1.573 1.56 1.573.862 0 1.56-.704 1.56-1.573z"
      />
    </g>
  </IconContainer>
);
