export default function createBrowserCapabilities(window = global) {
  if (hasWebRTCSupport(window)) {
    return {};
  }

  return { webRTC: false };
}

function hasWebRTCSupport(window) {
  let hasRTCsupport = window.webkitRTCPeerConnection || window.RTCPeerConnection || window.mozRTCPeerConnection;
  let hasGetUserMedia =
    window.navigator.getUserMedia ||
    window.navigator.webkitGetUserMedia ||
    window.navigator.mozGetUserMedia ||
    window.navigator.msGetUserMedia;
  return !!hasRTCsupport && !!hasGetUserMedia;
}
