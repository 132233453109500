import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import AddCustomerModal from './add_customer_modal';
import ConfirmCustomerModal from './confirm_customer_modal';
import connect from 'components/lib/connect';
import CreatePendingCustomer from 'actions/customer/create_pending_customer';
import Customer from 'models/customer';
import LoadCustomer from 'actions/customer/load_customer';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import SearchCustomers from 'actions/customer_match/search_customers';
import SearchableMenu from 'components/lib/assignment_menu/searchable_menu';
import SearchResult from 'models/search/search_result';
import SearchResults from 'components/lib/assignment_menu/search_results';
import { stripHighlighting } from 'components/lib/customer_conversation_card/lib/highlight_text';
import TrackRelationshipClick from 'scripts/application/actions/conversation_item/pin_item/track_relationship_click';

export class AddCustomerRelationshipMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.renderAddCustomer = this.renderAddCustomer.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  render() {
    return (
      <SearchableMenu
        className="addCustomerRelationshipMenu"
        footer={this.renderAddCustomer()}
        isLoading={this.props.isLoading}
        onClearSearch={this.props.onClearSearch}
        onSearch={this.props.onSearch}
        placeholder={this.getPlaceholderText()}
        searchPlaceholder="Search for name, phone, email, etc."
        searchResults={this.renderResults()}
        title={this.props.title}
      />
    );
  }

  renderResults() {
    const customerResults = this.props.customerResults;
    return (
      <div>
        {customerResults && customerResults.length ? (
          <SearchResults
            className="searchableMenu-results-customers"
            renderRow={this.renderRow}
            results={customerResults}
          />
        ) : null}
      </div>
    );
  }

  renderRow({ detail, id, name }) {
    if (id === this.props.currentCustomerId) {
      const identifier = name ? <strong>{name}</strong> : detail;
      return <CurrentCustomer key="current-customer">{identifier} (current customer)</CurrentCustomer>;
    }
    return (
      <AddCustomerRelationshipSearchResult
        detail={detail}
        id={id}
        key={id}
        name={name}
        onClick={this.props.onSelectCustomer}
      />
    );
  }

  renderAddCustomer() {
    return (
      <div className="addCustomerRelationshipMenu-addCustomer" onClick={this.props.onSelectNewCustomer}>
        + Add a New Customer
      </div>
    );
  }

  getPlaceholderText() {
    if (this.props.isLoading) {
      return null;
    }

    const customerResults = this.props.customerResults;
    if (!customerResults) {
      return 'Begin typing to find a customer.';
    } else if (!customerResults.length) {
      return 'No results. Try refining your search.';
    }
    return null;
  }
}

AddCustomerRelationshipMenu.propTypes = {
  className: PropTypes.string,
  currentCustomerId: PropTypes.string,
  customerResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      detail: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  onClearSearch: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  onSelectNewCustomer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export class AddCustomerRelationshipSearchResult extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  render() {
    return (
      <div className={classnames('addCustomerRelationshipSearchResult')} onClick={this.onClick}>
        <div className="addCustomerRelationshipSearchResult-name">{this.props.name}</div>
        <div className="addCustomerRelationshipSearchResult-detail">{this.props.detail}</div>
      </div>
    );
  }

  onClick() {
    this.props.onClick(this.props.id);
  }
}

AddCustomerRelationshipSearchResult.propTypes = {
  detail: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const AddCustomerRelationshipMenuContainer = connect(
  mapStateToMenuProps,
  mapExecuteToMenuProps
)(AddCustomerRelationshipMenu);

AddCustomerRelationshipMenuContainer.propTypes = {
  isCustomer: PropTypes.bool,
  itemId: PropTypes.string,
  queryId: PropTypes.string,
  omittedCustomerIds: PropTypes.arrayOf(PropTypes.string),
  onSetQueryId: PropTypes.func.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  onSelectNewCustomer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

AddCustomerRelationshipMenuContainer.defaultProps = {
  omittedCustomerIds: [],
};

function mapStateToMenuProps({ getProvider }, { omittedCustomerIds, queryId }) {
  const searchResultsProvider = getProvider('universalSearchResults');

  const results = searchResultsProvider.findBy({ id: queryId });
  const relationships = getProvider('relationships').findAll();
  const relationshipLabelsProvider = getProvider('relationshipLabels');

  let customerResults = null;
  if (queryId && results) {
    customerResults = results.hits
      .filter(hit => omittedCustomerIds.indexOf(hit.id) === -1)
      .map(hit => {
        return {
          id: hit.id,
          name: hit.name ? stripHighlighting(hit.name) : '',
          detail: (hit.email && stripHighlighting(hit.email)) || (hit.phone && stripHighlighting(hit.phone)) || '',
        };
      });
  } else {
    const possibleRelationships = _.filter(
      relationships,
      relationship => omittedCustomerIds.indexOf(relationship.customerId) === -1
    );
    if (possibleRelationships.length) {
      customerResults = _.map(possibleRelationships, relationship => ({
        id: relationship.customerId,
        name: relationship.customerProfile.name,
        detail:
          (relationship.labelId &&
            _.get(relationshipLabelsProvider.findBy({ id: relationship.labelId }), 'displayName')) ||
          null,
      }));
    }
  }

  const isLoading = results ? results.isLoading : false;

  return {
    customerResults,
    isLoading,
  };
}

function mapExecuteToMenuProps(executeAction, props) {
  return {
    onClearSearch: () => {
      props.onSetQueryId(null);
    },
    onSearch: event => {
      let searchText = event.target.value;

      if (!searchText) {
        props.onSetQueryId(null);
        return;
      }

      let searchResultId = SearchResult.newId();
      props.onSetQueryId(searchResultId);
      executeAction(SearchCustomers, { searchText, searchResultId });
    },
    onSelectCustomer: id => {
      props.onSelectCustomer(id);
      executeAction(LoadCustomer, id);
    },
    onSelectNewCustomer: () => {
      const id = Customer.newId();
      executeAction(CreatePendingCustomer, { id });
      props.onSelectNewCustomer(id);
      executeAction(TrackRelationshipClick, 'Add New Customer');
    },
  };
}

export class AddCustomerRelationshipMenuContainerWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { queryId: null, newCustomerId: null, selectedCustomerId: null };
    this.handleClearSelection = this.handleClearSelection.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSelectCustomer = this.handleSelectCustomer.bind(this);
    this.handleSelectNewCustomer = this.handleSelectNewCustomer.bind(this);
    this.handleSetQueryId = this.handleSetQueryId.bind(this);
  }

  render() {
    return (
      <OutsideClickHandler
        disableOnClickOutside={!!(this.state.selectedCustomerId || this.state.newCustomerId)}
        onClickOutside={this.handleClickOutside}
      >
        <AddCustomerRelationshipMenuContainer
          currentCustomerId={this.props.currentCustomerId}
          itemId={this.props.conversationItemId}
          omittedCustomerIds={this.props.omittedCustomerIds}
          onSelectCustomer={this.handleSelectCustomer}
          onSelectNewCustomer={this.handleSelectNewCustomer}
          onSetQueryId={this.handleSetQueryId}
          queryId={this.state.queryId}
          title={this.props.title}
        />
        {this.state.newCustomerId ? (
          <AddCustomerModal
            itemId={this.props.conversationItemId}
            newCustomerId={this.state.newCustomerId}
            onCancel={this.handleClearSelection}
            onClose={this.props.onClose}
            originalCustomerId={this.props.currentCustomerId}
          />
        ) : null}
        {this.state.selectedCustomerId ? (
          <ConfirmCustomerModal
            itemId={this.props.conversationItemId}
            onCancel={this.handleClearSelection}
            onClose={this.props.onClose}
            originalCustomerId={this.props.currentCustomerId}
            selectedCustomerId={this.state.selectedCustomerId}
            title="Is this the right customer?"
          />
        ) : null}
      </OutsideClickHandler>
    );
  }

  handleClickOutside() {
    this.props.onClose();
  }

  handleClearSelection() {
    this.setState({ selectedCustomerId: null, newCustomerId: null });
  }

  handleSelectCustomer(selectedCustomerId) {
    this.setState({ selectedCustomerId, newCustomerId: null });
  }

  handleSelectNewCustomer(newCustomerId) {
    this.setState({ selectedCustomerId: null, newCustomerId });
  }

  handleSetQueryId(queryId) {
    this.setState({ queryId });
  }
}

AddCustomerRelationshipMenuContainerWrapper.propTypes = {
  className: PropTypes.string,
  conversationItemId: PropTypes.string,
  currentCustomerId: PropTypes.string,
  omittedCustomerIds: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddCustomerRelationshipMenuContainerWrapper;

const CurrentCustomer = styled.div`
  color: ${p => p.theme.colors.gray600};
  cursor: not-allowed;
  padding: 4px 32px;
`;
