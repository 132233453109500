export default class ConversationUpdater {
  constructor(context) {
    this.context = context;
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }

  get currentCustomerId() {
    return this.customerId || this.context.stores.currentLocation.get().customerId;
  }

  getActiveConversation() {
    let customer = this.context.stores.customers.find(this.currentCustomerId);
    return customer.getActiveConversation();
  }

  getLatestConversation() {
    let customer = this.context.stores.customers.find(this.currentCustomerId);
    return customer.getLatestConversation();
  }

  addConversationItem(conversationItem) {
    this.context.gateways.customerHistory.addItem(conversationItem.customerId, conversationItem.toJs());
  }

  updateConversation(conversationId, conversationUpdates) {
    let conversation = this.context.stores.conversations.findBy({ id: conversationId });
    this._updateConversation(conversation, conversationUpdates);
  }

  updateActiveConversation(conversationUpdates) {
    this._updateConversation(this.getActiveConversation(), conversationUpdates);
  }

  _updateConversation(conversation, conversationUpdates = {}) {
    conversation.update(conversationUpdates);
    this.context.stores.conversations.replace(conversation);

    this.context.gateways.customerHistory.updateConversation(
      this.currentCustomerId,
      conversation.id,
      conversationUpdates
    );
  }
}
