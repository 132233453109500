import _ from 'lodash';

import CreateComposition from './lib/create_composition';
import { MessageChannelType } from 'models/conversation_message';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import ConversationMessageCompositionContent from 'models/composition/conversation_message_composition_content';

class CreateTweetComposition extends CreateComposition {
  constructor(context) {
    super(context, ConversationMessageCompositionContent);
  }

  _createContent(attrs) {
    const lastTwitterItem = this._findLastTwitterItem(attrs.conversationId);
    const conversation = this.customerStores.conversations.find(attrs.conversationId);

    const potentialFroms = this._getPotentialFroms(conversation);
    let companyAddress = _.get(potentialFroms, 'default.id');
    let companyHandle = _.get(potentialFroms, 'default.handle');
    if (lastTwitterItem) {
      const matchedAddress = _.find(potentialFroms.allFromAddresses, [
        'id',
        _.get(lastTwitterItem, 'content.companyAddress'),
      ]);
      if (matchedAddress) {
        companyAddress = matchedAddress.id;
        companyHandle = matchedAddress.handle;
      }
    }

    let companyDisplayName = this._normalizeTwitterHandle(companyHandle);
    let externalCustomerTwitterAddress = _.find(this.customerStores.profile.get().externalCustomerAddresses, {
      type: 'TWITTER',
    });

    let customerDisplayName = _.get(externalCustomerTwitterAddress, 'displayName');
    let formattedCustomerDisplayName = this._normalizeTwitterHandle(customerDisplayName);

    return new ConversationMessageCompositionContent({
      channel: MessageChannelType.TWITTER,
      companyAddress,
      companyDisplayName,
      customerAddress: _.get(externalCustomerTwitterAddress, 'id'),
      customerDisplayName: formattedCustomerDisplayName,
    });
  }

  _normalizeTwitterHandle(handle) {
    if (_.startsWith(handle, '@')) {
      return handle;
    }
    return `@${handle}`;
  }

  _canCreateComposition() {
    return this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
  }

  _getPotentialFroms(conversation) {
    let channelConfiguration = this.context.stores.channelConfiguration.get();
    let twitterEndpoints = channelConfiguration
      ? channelConfiguration.getEndpointsOfType(MessageChannelType.TWITTER)
      : [];
    let allFromAddresses = _.map(twitterEndpoints, endpoint => {
      return {
        handle: endpoint.configuration.handle,
        id: endpoint.address,
      };
    });
    let routingGroupId = conversation.assignee.routingGroupId;

    return this._getFroms({
      allFromAddresses,
      conversation,
      endpoints: twitterEndpoints,
      endpointType: MessageChannelType.TWITTER,
      routingGroupId,
    });
  }

  _getFroms({ allFromAddresses, conversation, endpoints, endpointType, routingGroupId }) {
    let firstRoutingGroupNumber = _.chain(this.context.stores.communicationQueues.findAll())
      .filter(commQueue => commQueue.routingGroupId === routingGroupId)
      .map(commQueue => _.find(endpoints, endpoint => endpoint.id === commQueue.endpointId))
      .compact()
      .map(endpoint => {
        return {
          handle: endpoint.configuration.handle,
          id: endpoint.address,
        };
      })
      .head()
      .value();

    const channelConfiguration = this.context.stores.channelConfiguration.get();
    const currentInbox = this.context.stores.routingGroups.findBy({ id: conversation.assignee.routingGroupId });
    const defaultEndpointId = currentInbox && currentInbox.defaultEndpoints[endpointType];
    const defaultEndpoint =
      channelConfiguration && _.find(channelConfiguration.endpoints, e => e.id === defaultEndpointId);
    const defaultAddress = defaultEndpoint
      ? {
          handle: defaultEndpoint.configuration.handle,
          id: defaultEndpoint.address,
        }
      : undefined;

    return {
      default: defaultAddress || firstRoutingGroupNumber || allFromAddresses[0],
      preferred: firstRoutingGroupNumber,
      allFromAddresses,
    };
  }

  _findLastTwitterItem(conversationId) {
    const customerId = this.context.stores.currentLocation.get().customerId;
    const { conversationHistory } = this.context.stores.customers.storesFor(customerId);
    return getLatestManualItem({
      conversationHistory,
      filter: item => item.content.channel === MessageChannelType.TWITTER,
      conversationId,
    });
  }
}

export default CreateTweetComposition;
