import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';

import { AdminPage } from '../lib/admin_layout';
import KnowledgeBaseAdmin from './knowledge_base_admin';

const KnowledgeBaseAdminPage = createReactClass({
  mixins: [Morearty.Mixin],

  render() {
    return (
      <AdminPage className="knowledgeBaseAdmin-page" id="knowledgeBaseAdmin-page">
        <KnowledgeBaseAdmin />
      </AdminPage>
    );
  },
});

export default KnowledgeBaseAdminPage;
