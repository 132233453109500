import { Editor, Text, Transforms } from 'slate';

export default function selectAll(editor) {
  let firstTextEntry;
  for (let nodeEntry of Editor.nodes(editor, {
    at: [],
    match: n => Text.isText(n),
  })) {
    firstTextEntry = nodeEntry;
    break;
  }
  if (!firstTextEntry) {
    return;
  }

  let lastTextEntry;
  for (let nodeEntry of Editor.nodes(editor, {
    at: [],
    match: n => Text.isText(n),
    reverse: true,
  })) {
    lastTextEntry = nodeEntry;
    break;
  }
  if (!lastTextEntry) {
    return;
  }

  const selection = {
    anchor: { path: firstTextEntry[1], offset: 0 },
    focus: {
      path: lastTextEntry[1],
      offset: lastTextEntry[0].text.length,
    },
  };
  Transforms.setSelection(editor, selection);
  return selection;
}
