import { Factory } from 'rosie';

Factory.define('Liveboards').attr('name', 'LIVEBOARDS');

Factory.define('LiveboardsWithDefaults')
  .extend('Liveboards')
  .attr('type', 'SUMMARY')
  .attr('timezone', '');

Factory.define('LiveboardsAgents')
  .extend('Liveboards')
  .attr('type', 'AGENTS')
  .attr('timezone', '');
