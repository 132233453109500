import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M2 3h12a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm12 10H8.5v1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h2.5V13H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2zM8.764 7.5L7 8.382V6.618l1.764.882zM6 9.191V5.809a.5.5 0 0 1 .724-.447l3.382 1.69a.5.5 0 0 1 0 .895L6.724 9.638A.5.5 0 0 1 6 9.191z"
      fill="#4D4E4E"
      fillRule="evenodd"
    />
  </Icon>
);
