import ActiveCall from 'models/active_call';
import analytics from 'scripts/lib/analytics';
import PhoneCall from 'models/phone_call';
import { subscribeToActiveCall } from 'actions/phone_call/lib/active_call';
import ServerClock from 'scripts/application/lib/server_clock';

export default class AddObserverToCall {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, conversationItemId, isAgentVisible }) {
    let phoneCallItem = this.context.stores.conversationHistory.find(conversationItemId);
    let currentAgent = this.context.stores.currentAgent.get();

    let agentParticipantIds = phoneCallItem.content.getAgentParticipantIdsOnCall();
    if (agentParticipantIds.length === 0) {
      // No agent currently on the call
      return;
    }

    // Set active call temporary to prevent race condition if twilio incoming call comes before the fetch
    // of active call returns
    let customerProfile = this.context.stores.customers.find(customerId).profile;
    let activeCall = new ActiveCall({
      id: phoneCallItem.id,
      conversationId: phoneCallItem.conversationId,
      conversationItem: phoneCallItem,
      customer: customerProfile,
    });
    activeCall.conversationItem.content.addParticipant({
      participantId: currentAgent.id,
      status: PhoneCall.ParticipantStatus.DIALING,
      statusUpdatedAt: ServerClock.toISOString(),
      type: PhoneCall.ParticipantType.OBSERVER,
      observerStatus: PhoneCall.PhoneCallObserverStatus.LISTEN,
      observerVisibility: isAgentVisible ? PhoneCall.ObserverVisibility.VISIBLE : PhoneCall.ObserverVisibility.HIDDEN,
    });

    this.context.stores.activeCall.set(activeCall);
    subscribeToActiveCall(this.context);

    this.context.gateways.phoneControlsHttp.addCallObserver(customerId, conversationItemId, {
      participantId: currentAgent.id,
      hidden: !isAgentVisible,
    });

    analytics.track('Hero Coaching - Observer Added', { customerId, conversationItemId });
  }
}
