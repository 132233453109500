import _ from 'lodash';
import {
  getAgentHasDismissedNotificationRecently,
  getChannelAndId,
  createNotification,
  hasNotification,
  postMessage,
} from './multiple_tabs_helpers';
import DismissMultipleTabsNotification from './dismiss_multiple_tabs_notification';

export default class CheckForMultipleTabs {
  constructor(context) {
    this.context = context;
  }

  run() {
    const [channel] = getChannelAndId();
    postMessage({ type: 'start' });
    this.context.analytics.updateUser({ numTabsOpen: 1 });

    let tabIds = [];
    channel.onmessage = msg => {
      let oldTabIds = tabIds;

      // Respond back to other started tab with an 'ack' so that they can also show the browser tab notification
      if (msg.type === 'start') {
        tabIds = _.uniq(_.concat(tabIds, msg.id));
        postMessage({ type: 'ack' });
        this.ensureAgentSeesNotification();
      }
      // If we get an ack, then we should try to show a notification if we haven't let the agent know recently
      // that it's a bad idea. This happens if we're the original tab, and another tab is opened and becomes
      // the active one.
      else if (msg.type === 'ack') {
        tabIds = _.uniq(_.concat(tabIds, msg.id));
        this.ensureAgentSeesNotification();
      }
      // If some other browser dismissed the notification, we can hide our own notification if it exists.
      else if (msg.type === 'dismiss') {
        this.context.executeAction(DismissMultipleTabsNotification);
      }
      // If some other tab closes, we want to update our tabIds to keep our analytics in synch
      else if (msg.type === 'end') {
        tabIds = _.uniq(_.filter(tabIds, tabId => tabId !== msg.id));
      }

      if (oldTabIds.length !== tabIds.length) {
        this.context.analytics.updateUser({ numTabsOpen: tabIds.length + 1 });
      }
    };
  }

  ensureAgentSeesNotification() {
    const hasDismissedNotification = getAgentHasDismissedNotificationRecently();
    if (!hasDismissedNotification) {
      if (hasNotification(this.context)) {
        return;
      }
      createNotification(this.context);
    }
  }
}
