import _ from 'lodash';

import HttpUploader from './lib/http_uploader';
import StandardGateway from './lib/standard_gateway';
import Observable from 'scripts/lib/observable_mixin';

export default class PublicAnswerUploadGateway {
  constructor(backend, requestorId) {
    this.publicUploadAuthGateway = new PublicUploadAuthGateway(backend, requestorId);
    this.publicUploadAuthGateway.setObserver(this);
    this.httpUploader = new HttpUploader(backend, this.notifyObservers.bind(this));
  }

  abort(path) {
    return this.httpUploader.abort(path);
  }

  remove(path) {
    return this.httpUploader.remove(path);
  }

  upload(path, file, auth) {
    this.httpUploader.uploadFile(path, file, auth);
  }

  fetchUploadAuth(orgId, assetId, fileDescriptor) {
    return this.publicUploadAuthGateway.fetch(orgId, assetId, fileDescriptor);
  }

  onFetchSuccess(auth) {
    this.notifyObservers('handleUploadAuthSuccess', { auth, path: auth.path });
  }

  onFetchError({ auth, errors }) {
    if (auth && auth.path) {
      this.notifyObservers('handleUploadAuthError', { path: auth.path, errors });
    } else {
      this.notifyObservers('handleUploadAuthError', { path: '', errors });
    }
  }
}

_.extend(PublicAnswerUploadGateway.prototype, Observable);

class PublicUploadAuthGateway extends StandardGateway {
  constructor(backend) {
    super(backend, {
      fetchUrl: '/api/v1/orgs/:orgId/assets/:contentType/public-media-upload-auth/:assetId',
    });
  }

  get version() {
    return '1';
  }

  fetch(orgId, assetId, fileDescriptor) {
    return super.fetch({
      orgId,
      assetId: `${assetId}/${fileDescriptor.filename}`,
      contentType: fileDescriptor.contentType.replace('/', '-'),
    });
  }
}
