import ErrorDtoConverter from 'scripts/application/dto_converters/err_converter';

export default class AgentTaskReadGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleUpdateSuccess() {}

  handleUpdateError(errorDto) {
    throw new Error(`Failed to update agent task read: ${JSON.stringify(ErrorDtoConverter.fromDto(errorDto))}`);
  }
}
