import StandardGateway from 'scripts/adapters/gateways/lib/standard_gateway';

export default class ChatTypingHttpGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/activity/typing/chat');
  }

  updateTypingStatus(data) {
    this.http.post(this.endpoint.set({}).addUrl, data).then();
  }
}
