import _ from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';

import InsetContainer from 'components/common/containers/inset_container';
import { PortalledPopover } from 'components/common/lib/_popover';
import WindowSizeWatcher from 'scripts/presentation/components/common/utilities/window_size_watcher';

// DEPRECATED - DO NOT USE - Please use ./menu.jsx
export default class PopoverMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: props.isOpen };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  render() {
    let props = this.props;

    const popoverProps = {
      autoPosition: true,
      isVisible: this.state.isOpen,
      onClickOutside: this.handleClickOutside,
      position: props.position,
      targetElement: this.props.button,
      ..._.omit(props, ['boundByWindow']),
    };

    if (props.boundByWindow) {
      return (
        <WindowSizeWatcher>
          {({ windowHeight, windowWidth }) => {
            popoverProps.bounds = {
              bottom: windowHeight - 68,
              left: 8,
              right: windowWidth - 8,
              top: 68,
            };
            return <StyledPopoverMenu {...popoverProps} />;
          }}
        </WindowSizeWatcher>
      );
    }

    return <StyledPopoverMenu {...popoverProps} />;
  }

  open() {
    this.setState({ isOpen: true });
    this.props.onToggle && this.props.onToggle(true);
  }

  close() {
    this.setState({ isOpen: false });
    this.props.onToggle && this.props.onToggle(false);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.onToggle && this.props.onToggle(!this.state.isOpen);
  }

  handleClickOutside(evt) {
    this.close();
    this.props.onClickOutside && this.props.onClickOutside(evt);
  }

  handleRef(node) {
    this.setState({ targetElement: node });
  }
}

PopoverMenu.defaultProps = {
  boundByWindow: true,
  isOpen: false,
  position: 'top',
};

const StyledPopoverMenu = styled(PortalledPopover)`
  background-color: ${p => p.theme.colors.white};
  border-radius: 6px;
  box-shadow: ${p => p.theme.boxShadow.medium};
  overflow: hidden;
`;

const StyledMenuItem = styled(InsetContainer)`
  cursor: pointer;
  min-width: 100px;
  &:hover {
    background: ${p => p.theme.colors.green200};
  }
  ${p => p.isDisabled && disabledMenuItem};
`;

const disabledMenuItem = css`
  color: ${p => p.theme.colors.gray600};
  cursor: default;
  &:hover {
    background: ${p => p.theme.colors.white};
  }
`;

StyledMenuItem.defaultProps = { inset: 'small' };

export function PopoverMenuItem({
  children,
  isDisabled,
  isFirstItem,
  isLastItem,
  onClick,
  onMouseDown,
  'data-aid': dataAid,
}) {
  return (
    <StyledMenuItem
      data-aid={dataAid}
      isDisabled={isDisabled}
      onClick={!isDisabled ? onClick : undefined}
      onMouseDown={!isDisabled ? onMouseDown : undefined}
    >
      {children}
    </StyledMenuItem>
  );
}

PopoverMenu.propTypes = PortalledPopover.propTypes;
