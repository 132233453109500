import { isEmpty } from 'lodash/lang';

import { CommunicationSessionStatus } from 'models/agent_activity';
import { snowplowTypes, getConversationContext, getContactContext } from 'scripts/lib/analytics/domain/snowplow';
import { getCommunicationSession } from 'actions/conversation/lib/conversation_helpers';

function buildContexts(sessionData, contactData) {
  const contexts = [getConversationContext(sessionData)];
  if (!isEmpty(contactData) && contactData.contactId) {
    contexts.push(getContactContext(contactData));
  }

  return contexts;
}

export function trackSessionCreated(context, { id, customerId, conversationId }) {
  context.analytics.track('Co-browsing Session Created', {
    sessionId: id,
    customerId,
    conversationId,
  });
}

export function trackSessionStarted(context, sessionData) {
  const contactInfo = {};

  const recentContact = getCommunicationSession(sessionData.customerId, context.stores);
  if (recentContact && recentContact.status !== CommunicationSessionStatus.ENDED) {
    contactInfo.contactId = recentContact.id;
    contactInfo.conversationId = sessionData.conversationId;
    contactInfo.contactChannel = recentContact.type;
  }

  context.analytics.trackSnowplow(
    snowplowTypes.EVENT_COBROWSING_SESSION_STARTED,
    { sessionId: sessionData.id },
    buildContexts(sessionData, contactInfo)
  );
  context.analytics.track('Co-browsing Session Started', {
    sessionId: sessionData.id,
    customerId: sessionData.customerId,
    conversationId: sessionData.conversationId,
  });
}

export function trackSessionEnded(context, sessionData) {
  context.analytics.trackSnowplow(
    snowplowTypes.EVENT_COBROWSING_SESSION_ENDED,
    { sessionId: sessionData.id },
    buildContexts(sessionData)
  );
  context.analytics.track('Co-browsing Session Ended', {
    sessionId: sessionData.id,
    customerId: sessionData.customerId,
    conversationId: sessionData.conversationId,
  });
}

export function trackWidgetLoaded(context, { loaderUrl, loadingTime }) {
  context.analytics.track('UI Widget Loaded', {
    loaderUrl,
    loadingTime,
  });
}
