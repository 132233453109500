import SearchQueryConverter from './search_query_converter';

export default {
  getPath() {
    return '/search';
  },

  getQueryParams(search) {
    return search.query && SearchQueryConverter.toUrlParameters(search.query);
  },
};
