import DtoConverter from 'scripts/application/dto_converters/snippet_converter';

export default class LoadProfileSnippets {
  constructor(context) {
    this.context = context;
  }

  run(agentProfile = {}) {
    const { emailGreetingSnippetId, emailSignatureSnippetId } = agentProfile;
    let snippetIds = [];

    if (emailGreetingSnippetId) {
      snippetIds.push(emailGreetingSnippetId);
    }

    if (emailSignatureSnippetId) {
      snippetIds.push(emailSignatureSnippetId);
    }

    if (snippetIds.length > 0) {
      this.context.gateways.snippet.fetchAll({ 'f.ids': snippetIds }).then(snippetDtos => {
        let currentAgent = this.context.stores.currentAgent.get();
        if (!currentAgent || snippetDtos.length > 2) {
          return;
        }

        const snippets = snippetDtos.map(dto => DtoConverter.fromDto(dto));
        snippets.forEach(snippet => {
          if (snippet.id === emailGreetingSnippetId) {
            this.context.stores.currentAgentSnippets.addOrReplace(snippet);
          }

          if (snippet.id === emailSignatureSnippetId) {
            this.context.stores.currentAgentSnippets.addOrReplace(snippet);
          }
        });
      });
    }
  }
}
