import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('ProactiveChatContent').attr('type', 'PROACTIVE_CHAT_CONTENT');

Factory.define('ProactiveChatContentWithDefaults')
  .extend('ProactiveChatContent')
  .attr('action', 'GREETING')
  .attr('campaignId', 'campaign-id')
  .attr('sentAt', new Date().toISOString())
  .attr('source', 'CAMPAIGN')
  .attr('text', 'This is an example greeting!');

Factory.define('ProactiveChatContentWithApiSource')
  .extend('ProactiveChatContent')
  .attr('action', 'GREETING')
  .attr('campaignId', '')
  .attr('sentAt', new Date().toISOString())
  .attr('source', 'API')
  .attr('text', 'This is an example greeting!');

Factory.define('ProactiveChatItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('ProactiveChatContent', content));

Factory.define('ProactiveChatItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('ProactiveChatContentWithDefaults', content));

Factory.define('ProactiveChatItemWithApiSource')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('ProactiveChatContentWithApiSource', content));
