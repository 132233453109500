import ApiTokens from 'models/location/api_tokens';
import ClearApiTokens from 'actions/api_tokens/clear_api_tokens';
import OpenApiTokens from 'actions/api_tokens/open_api_tokens';
import { registerUrlConverter } from './location_url';
import StaticUrlConverter from './url_converters/static_url_converter';

registerUrlConverter(ApiTokens, StaticUrlConverter.create('/api-tokens'));

export default function(router, { runnerContext }) {
  return {
    on() {
      runnerContext.executeAction(OpenApiTokens);
    },
    after() {
      runnerContext.executeAction(ClearApiTokens);
    },
  };
}
