import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import { CONVERSATION } from './feed_item_types';
import DebouncedAutosizer from 'components/customer/conversation_history/virtualized/debounced_autosizer';
import FeedItem from './feed_item';
import FeedLayout from './feed_layout';
import FetchMoreItems from 'actions/customer/feed/fetch_more_items';
import SizeProvider from './size_provider';
import List from 'components/customer/conversation_history/virtualized/list';

export class FeedItems extends React.Component {
  constructor(props) {
    super(props);
    this.decorateScrollTop = this.decorateScrollTop.bind(this);
    this.handleVisibleRangeChanged = this.handleVisibleRangeChanged.bind(this);
    this.renderItem = this.renderItem.bind(this);

    const { ids, stickyIds } = getIds(props.items);
    const lastStickyId = _.get(_.last(stickyIds), 'id');
    this.feedLayout = new FeedLayout({ lastStickyId });
    this.state = { ids, lastStickyId, stickyIds };
  }

  static getDerivedStateFromProps(props, state) {
    const { ids, stickyIds } = getIds(props.items);
    return { ids, stickyIds };
  }

  render() {
    const { anchorId, anchorPosition, lastAnchorChangeAt } = this.props;

    return (
      <DebouncedAutosizer>
        {({ height, width }) => {
          return (
            <SizeProvider height={height} width={width}>
              <List
                anchorId={anchorId}
                anchorPosition={anchorPosition}
                decorateScrollTop={this.decorateScrollTop}
                height={height}
                ids={this.state.ids}
                lastAnchorChangeAt={lastAnchorChangeAt}
                layout={this.feedLayout}
                onRenderedRangeChanged={this.props.onRenderedRangeChanged}
                onVisibleRangeChanged={this.handleVisibleRangeChanged}
                renderItem={this.renderItem}
                scrollerStyle={{}}
                stickyIds={this.state.stickyIds}
                width={width}
              />
            </SizeProvider>
          );
        }}
      </DebouncedAutosizer>
    );
  }

  renderItem(index, isSticky) {
    return <FeedItem isSticky={isSticky} item={this.props.items[index]} />;
  }

  decorateScrollTop(layout, scrollTop) {
    const lastStickyId = _.get(_.last(this.state.stickyIds), 'id');
    if (lastStickyId === layout.getLastStickyId()) {
      return scrollTop;
    }
    return layout.setLastStickyId(lastStickyId, scrollTop);
  }

  handleVisibleRangeChanged({ isScrolledToBottom, visibleRange }) {
    const { start, end } = visibleRange;
    this.props.onVisibleRangeChanged({ isScrolledToBottom, start, end });
  }
}

FeedItems.propTypes = {
  anchorId: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  lastAnchorChangeAt: PropTypes.string,

  onVisibleRangeChanged: PropTypes.func.isRequired,
  onRenderedRangeChanged: PropTypes.func.isRequired,
};

function mapExecuteToProps(executeAction, { items }) {
  return {
    onRenderedRangeChanged: ({ isManual, renderedRange, scrollDirection }) => {
      executeAction(FetchMoreItems, {
        feedItems: items,
        isManual,
        renderedRange,
        scrollDirection,
      });
    },
  };
}

const FeedItemsContainer = connect(undefined, mapExecuteToProps)(FeedItems);
export default FeedItemsContainer;

FeedItemsContainer.propTypes = {
  anchorId: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  lastAnchorChangeAt: PropTypes.string,

  onVisibleRangeChanged: PropTypes.func.isRequired,
};

function getIds(items) {
  let ids = [];
  let stickyIds = [];

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    ids.push(item.id);
    if (item.type === CONVERSATION) {
      stickyIds.push({
        id: item.id,
        index: i,
      });
    }
  }

  let previousStickyId = null;
  for (let i = stickyIds.length - 1; i >= 0; i--) {
    const stickyId = stickyIds[i];
    stickyId.nextId = previousStickyId;
    previousStickyId = stickyId.id;
  }

  return { ids, stickyIds };
}
