import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Email Stroke
 */

export default function EmailStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M2 4h12v.5a.498.498 0 00-.224.053L8 7.44 2.224 4.553A.498.498 0 002 4.5V4zm0 1.559V12h12V5.559L8.224 8.447a.5.5 0 01-.448 0L2 5.56zM1 4a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
