import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import DateInput from 'components/lib/date_input';

export default function QuickPickPanel({ startDate, endDate, onChange, quickPicks }) {
  return (
    <div className="dateRange-picker-row">
      <div className="dateRange-picker-quickPicks">
        {_.map(quickPicks, (dateGenerator, name) => {
          let [startDate, endDate] = dateGenerator(moment());
          return <QuickPick endDate={endDate} key={name} name={name} onClick={onChange} startDate={startDate} />;
        })}
      </div>
      <DateInput
        className="dateRange-picker-startAt"
        date={startDate}
        onValidDate={date => onChange({ startDate: moment(date) })}
      />
      {' - '}
      <DateInput
        className="dateRange-picker-endAt"
        date={endDate}
        onValidDate={date => onChange({ endDate: moment(date) })}
      />
    </div>
  );
}

QuickPickPanel.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  quickPicks: PropTypes.objectOf(PropTypes.func),
};

export const QuickPick = ({ name, startDate, endDate, onClick }) => {
  return (
    <div className="dateRange-picker-quickPick" key={name} onClick={() => onClick({ startDate, endDate })}>
      {name}
    </div>
  );
};

QuickPick.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};
