import createModel, { prop } from './lib/create_model';
const ConversationWorkflowConfig = createModel({
  modelName: 'ConversationWorkflowConfig',
  properties: {
    reopenConversationWindow: prop(String),
    requireTopics: prop(Boolean).default(false),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ConversationWorkflowConfig;
