import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import SearchMenu, { SearchCache, SearchResult } from './search_menu';
import SearchPills from './search_pills';

const InboxSearch = connect(mapStateToProps)(SearchCache);

function mapStateToProps({ getProvider }) {
  return {
    immutable: getProvider('routingGroups').immutableStore.binding.get(),
    provider: getProvider('routingGroups'),
    itemSelect: ['disabled'],
    itemTransformer: transformItem,
  };

  function transformItem(item) {
    if (item.disabled) {
      return { ...item, name: `${item.name} (Archived)` };
    }
    return item;
  }
}

const SelectedInboxesContainer = connect(mapStateToSelectedInboxesProps)(SearchPills);

function mapStateToSelectedInboxesProps({ getProvider }, { selectedInboxIds }) {
  const routingGroupsProvider = getProvider('routingGroups');

  return {
    selectedItems: !routingGroupsProvider.isLoading()
      ? selectedInboxIds
          .map(id => routingGroupsProvider.findBy({ id }))
          .map(inbox => {
            return {
              id: inbox.id,
              label: inbox.disabled ? `${inbox.name} (Archived)` : inbox.name,
            };
          })
      : [],
  };
}

export function InboxSearchMenu({ onChange, selectedInboxIds }) {
  return (
    <React.Fragment>
      <InboxSearch excludedIds={selectedInboxIds}>
        {({ search }) => (
          <SearchMenu
            onAddItem={onAddItem}
            placeholder="Search for an inbox"
            renderRow={renderRow}
            search={search}
            selectedItemIds={selectedInboxIds}
            showAllByDefault
          />
        )}
      </InboxSearch>
      <SelectedInboxesContainer onDeleteItem={onDeleteItem} selectedInboxIds={selectedInboxIds} />
    </React.Fragment>
  );

  function renderRow({ item, onClickItem }) {
    return <SearchResult id={item.id} key={item.id} onMouseDown={onClickItem} primary={item.name} />;
  }

  function onAddItem(id) {
    onChange({ inboxIds: selectedInboxIds.concat(id) });
  }

  function onDeleteItem(id) {
    onChange({
      inboxIds: _.filter(selectedInboxIds, selectedId => selectedId !== id),
    });
  }
}

InboxSearchMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedInboxIds: PropTypes.arrayOf(PropTypes.string),
};

const InboxSearchMenuContainer = connect(mapStateToSearchMenuProps)(InboxSearchMenu);
export default InboxSearchMenuContainer;

function mapStateToSearchMenuProps({ getProvider }, props) {
  const searchResult = getProvider('universalSearchResults').findBy({
    id: props.queryId,
  });
  const selectedInboxIds = searchResult ? _.get(searchResult, 'query.filter.inboxIds') || [] : [];

  return {
    selectedInboxIds,
  };
}
