import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import ClearAnswerSearch from 'actions/answers/clear_answer_search';
import CustomerContext from 'components/customer/customer_context';
import InsetContainer from 'components/common/containers/inset_container';
import SearchInput from 'components/common/search_input';
import SemanticSearchTips from 'components/customer/answer_panel/search/semantic_search_tips';
import SetSearchText from 'actions/answers/set_search_text';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export function AnswerSearchInput() {
  const { lastOpenedAt, hasSeenSemanticSearchTips, searchText } = useContext(AnswerPanelContext);
  const inputRef = useRef(null);
  useFocusInput(inputRef, lastOpenedAt);
  const isFeatureEnabled = useIsFeatureEnabled();
  const isSuggestedReplyEnabled = isFeatureEnabled('suggestedReplies');
  const placeholderText = isSuggestedReplyEnabled ? 'What would you like to know?' : 'Search Answers';
  const [shouldDisplaySearchTip, setShouldDisplaySearchTip] = useState(false);
  const handleSearchFocused = useCallback(() => {
    setShouldDisplaySearchTip(true);
  }, []);
  const handleSearchBlurred = useCallback(evt => {
    // if we click on the search tips container, we don't want to hide the search tips
    if (!evt.currentTarget.contains(evt.relatedTarget)) {
      setShouldDisplaySearchTip(false);
    }
  }, []);

  const [onChange, onClear] = useHandleSearchTextChange(setShouldDisplaySearchTip);
  const onKeyDown = useOnKeyDown(inputRef);

  function renderSearchTips() {
    if (!isSuggestedReplyEnabled || hasSeenSemanticSearchTips || !shouldDisplaySearchTip) {
      return null;
    }
    return (
      <SemanticSearchTipsContainer tabIndex={-1}>
        <SemanticSearchTips />
      </SemanticSearchTipsContainer>
    );
  }

  return (
    <InputWrapper onBlur={handleSearchBlurred} onFocus={handleSearchFocused}>
      <SearchInput
        autoFocus={false}
        delay={SEARCH_DELAY}
        initialValue={searchText}
        onChange={onChange}
        onClearText={onClear}
        onKeyDown={onKeyDown}
        placeholder={placeholderText}
        ref={inputRef}
      />
      {renderSearchTips()}
    </InputWrapper>
  );
}

const SEARCH_DELAY = 300; // ms
const InputWrapper = styled(InsetContainer).attrs({ inset: 'medium' })`
  position: relative;
  padding-bottom: 0;
`;

const SemanticSearchTipsContainer = styled.div`
  position: absolute;
  top: 56px;
  padding-right: ${p => p.theme.spacing.insetMedium};
  z-index: ${p => p.theme.zIndex.top};
`;

const AnswerSearchInputMemo = React.memo(AnswerSearchInput);
export default AnswerSearchInputMemo;

function useHandleSearchTextChange(setShouldDisplaySearchTip) {
  const executeAction = useExecuteAction();
  const onChange = useCallback(evt => {
    const text = evt.target.value;
    executeAction(SetSearchText, { text });
    setShouldDisplaySearchTip(false);
  }, []);
  const onClear = useCallback(() => {
    executeAction(ClearAnswerSearch);
  }, []);
  return [onChange, onClear];
}

// Focus the input when openedAt changes and it occurs _after_ the customer was last routed at. Why?
// Because when we first mount a customer _and_ the answer panel is already open, we don't want to focus it..
// by checking to ensure that openedAt is after lastRoutedAt, we won't focus the answer panel on that
// first mount.
function useFocusInput(ref, openedAt) {
  const { lastRoutedAt } = useContext(CustomerContext);
  useEffect(() => {
    if (openedAt && new Date(openedAt) > new Date(lastRoutedAt)) {
      ref.current && ref.current.focusAll();
    }
  }, [openedAt, lastRoutedAt]);
}

// Blur the editor when we arrow up or down to notify agents that they have selected an
// answer in the list.
function useOnKeyDown(inputRef) {
  return useCallback(evt => {
    if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {
      inputRef.current.blur();
    }
  }, []);
}
