import createModel from './lib/create_model';
import moment from 'moment';
import RecordingSummary from './recording_summary';

const prop = createModel.prop;

const Voicemail = createModel({
  modelName: 'Voicemail',

  properties: {
    companyNumber: String,
    customerNumber: String,
    notes: String,
    recordingDuration: Number, // in seconds
    recordingId: String,
    recordingStatus: String,
    startedAt: String,
    recordingSummary: prop(RecordingSummary),
  },

  getMessageText() {
    return 'Voicemail';
  },

  fileDescriptor() {
    let filename = `${this.customerNumber}-voicemail`;
    if (this.startedAt) {
      filename = filename.concat('-').concat(moment(this.startedAt).format('YYYY-MM-DD HH-mm'));
    }
    return {
      filename: `${filename}.mp3`,
    };
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Voicemail;
