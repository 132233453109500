import _ from 'lodash';
import analytics from 'scripts/lib/analytics';

import getPollingInterval from './get_polling_interval';

export default function trackIncomingInboxDifferences(context, newInboxItems, requestedAt) {
  const currentItems = context.stores.agentInboxItems.findAll();

  let numIgnored = 0;

  // Remove current inbox items which were either removed before the request, or added after.
  // These are the items, thus, we expect to see in the new set of polled items.
  const currentItemsFiltered = _.filter(currentItems, item => {
    const wasRemovedBefore = item.removedAt && new Date(item.removedAt) <= requestedAt;
    const wasAddedAfter = item.manuallyCreatedAt && new Date(item.manuallyCreatedAt) > requestedAt;

    if (wasRemovedBefore || wasAddedAfter) {
      numIgnored += 1;
    }

    return !wasRemovedBefore && !wasAddedAfter;
  });

  const currentItemIds = _.sortBy(_.map(currentItemsFiltered, i => i.id));
  const newItemIds = _.sortBy(_.map(newInboxItems, i => i.id));

  if (_.isEqual(currentItemIds, newItemIds)) {
    return;
  }

  const removedItems = _.difference(currentItemIds, newItemIds);
  const addedItems = _.difference(newItemIds, currentItemIds);

  // Num items in the polled inbox, but missing from current (e.g. were added without us knowing)
  const numMissingItems = addedItems.length;
  // Num items in the current inbox, but missing from polled (e.g. were deleted without us knowing)
  const numExtraItems = removedItems.length;

  const totalDiff = numMissingItems + numExtraItems;
  const numItems = newInboxItems.length;

  if (totalDiff !== 0) {
    const pollingInterval = getPollingInterval(context);
    analytics.track('Incoming Inbox Count Difference', {
      numExtraItems,
      numIgnored,
      numItems,
      numMissingItems,
      pollingInterval,
      totalDiff,
    });
  }
}
