import _ from 'lodash';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import getNormalizedEmailAddress from 'scripts/lib/get_normalized_email_address';
import { normalizePhoneNumber } from 'models/phone_number';
import ExternalCustomerLookupService from './external_customer_lookup_service';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class CustomerExtensionService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this._getDatabase = database;
  }

  update(orgId, customerId) {
    let customer = CustomerExtensionService.getCustomer(this._getDatabase(orgId), orgId, customerId);

    if (_.isEmpty(_.get(customer, 'profile.externalCustomerIds'))) {
      return {};
    }
    _.forEach(customer.profile.externalCustomerIds, externalId => {
      const payload = { id: customerId, externalCustomerId: externalId };

      CustomerExtensionService.updateCustomer(this._getDatabase(orgId), this._pubsub, orgId, payload);
    });
  }

  static updateCustomer(datasource, pubsub, orgId, payload) {
    if (!payload) {
      return;
    }

    const customerId = payload.id;
    let customer = CustomerExtensionService.getCustomer(datasource, orgId, customerId);

    const query = _.omit(payload, ['id', 'integrationId']);
    query.emails = getQueryContactList(query.emails, 'original');
    query.phones = getQueryContactList(query.phones, 'normalized');

    const lookupProfiles = ExternalCustomerLookupService.findProfiles(datasource, orgId, {
      lookupLevel: 'DETAILED',
      query,
    });

    let newProfile = { ...customer.profile };
    let externalIds = {};
    lookupProfiles.results.forEach(foundProfile => {
      newProfile = _.merge(
        newProfile,
        _.pick(foundProfile, ['address', 'externalCustomerId', 'image', 'name', 'twitter', 'actions'])
      );

      if (_.get(foundProfile, 'emails.length')) {
        let appendedEmails = _.filter(foundProfile.emails, email => {
          return !_.find(
            customer.profile.emails,
            profileEmail =>
              getNormalizedEmailAddress(profileEmail.original) === getNormalizedEmailAddress(email.original)
          );
        });
        if (appendedEmails.length) {
          newProfile.emails = newProfile.emails ? newProfile.emails.concat(appendedEmails) : appendedEmails;
        }
      }

      if (_.get(foundProfile, 'phones.length')) {
        let appendedPhones = _.filter(foundProfile.phones, phone => {
          return !_.find(
            customer.profile.phones,
            profilePhone => normalizePhoneNumber(profilePhone.original) === normalizePhoneNumber(phone.original)
          );
        });
        if (appendedPhones.length) {
          newProfile.phones = newProfile.phones ? newProfile.phones.concat(appendedPhones) : appendedPhones;
        }
      }

      if (foundProfile.customAttributes) {
        newProfile.customAttributes = {
          ...newProfile.customAttributes,
          ...foundProfile.customAttributes,
        };
      }

      if (foundProfile.integrationId) {
        externalIds[foundProfile.integrationId] = foundProfile.externalCustomerId;
      }
    });

    newProfile.externalCustomerIds = externalIds;

    customer.profile = newProfile;
    CustomerExtensionService.publishResponse(
      pubsub,
      `v1/orgs/${orgId}/customer-profiles/${customerId}`,
      customer.profile
    );
  }

  removeCustomerLinks(orgId, customerId) {
    const customer = CustomerExtensionService.getCustomer(this._getDatabase(orgId), orgId, customerId);
    delete customer.profile.transactions;
    delete customer.profile.customAttributes;
    customer.profile.externalCustomerId = '';
    customer.profile.externalCustomerIds = {};

    CustomerExtensionService.publishResponse(
      this._pubsub,
      `v1/orgs/${orgId}/customer-profiles/${customerId}`,
      customer.profile
    );

    CustomerExtensionService.publishResponse(this._pubsub, `v1/orgs/${orgId}/customers/${customerId}/transactions`, []);
  }

  static getCustomer(datasource, orgId, customerId) {
    return _.find(datasource.customers, { id: customerId });
  }

  static publishResponse(pubsub, topic, payload) {
    pubsub.publish(topic, (payload && { payload }) || {});
  }

  performAutoExtension(payload, callback, path, { orgId, customerId }) {
    const response = this.update(orgId, customerId);
    callback(null, { status: 200, statusText: statusText(200), response });
  }

  unlinkCustomer(payload, callback, path, { orgId, customerId }) {
    this.removeCustomerLinks(orgId, customerId);
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/customer-extensions/:customerId': {
          DELETE: this.unlinkCustomer,
        },
        '/api/v2/orgs/:orgId/customer-auto-extensions/:customerId': {
          POST: this.performAutoExtension,
        },
      },
      this
    );
  }
}

function getQueryContactList(attributes, takeAttr) {
  return _(attributes)
    .partition(attr => attr.primary)
    .flatten()
    .compact()
    .map(attr => attr[takeAttr])
    .value();
}
