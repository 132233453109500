import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import Upload from 'models/upload';

export default class StartUpload {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, file, onInsertImage, isInline }) {
    let compositions = getCompositionsStore(this.context);
    if (!compositions) return;

    let errors = Upload.getValidationErrors({ file });
    if (errors.length) {
      compositions.setErrors(compositionId, errors);
      return;
    }

    let composition = compositions.find(compositionId);
    let upload = Upload.create({ file, isInline });
    let largeUploadError = composition.validateSingleAttachmentSize(upload);
    if (largeUploadError.length) {
      compositions.setErrors(compositionId, largeUploadError);
      return;
    }

    composition.addAttachments([upload]);
    compositions.clearErrors(compositionId);
    compositions.replace(composition);

    this.context.gateways.compositionUpload.fetchUploadAuth(
      this.currentAgent.id,
      composition.getUploadPath(upload.id),
      upload.fileDescriptor().toJs()
    );

    if (upload.isImage() && onInsertImage) {
      onInsertImage(upload);
    }
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
