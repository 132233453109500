import map from 'lodash/map';
import qconsole from 'scripts/lib/qconsole';
import RelationshipLabel from 'scripts/domain/models/relationship_label';

export default class RelationshipLabelHttpGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.relationshipLabels;
  }

  onBroadcast(labelsDto) {
    if (!labelsDto) {
      qconsole.error(`RelationshipLabelHttpGatewayObserver: onBroadcast received invalid data`);
      return;
    }
    this.store.addOrReplace(RelationshipLabel.fromJs(labelsDto));
  }

  onFetchAllSuccess(labelsDto) {
    this.store.resetLoading();
    this.store.set(map(labelsDto, l => RelationshipLabel.fromJs(l)));
  }

  onFetchAllError(errorDto) {
    this.store.resetLoading();
    qconsole.error(`RelationshipLabelHttpGatewayObserver: error fetching labels ${errorDto}`);
  }
}
