import CustomerProfileDef from 'models/customer_profile_def';
import qconsole from 'scripts/lib/qconsole';

export default class CustomerProfileDefGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onBroadcast(customerProfileDefDto) {
    this.context.stores.customerProfileDef.resetLoading();
    this.context.stores.customerProfileDef.set(CustomerProfileDef.fromJs(customerProfileDefDto));
  }

  onFetchSuccess(customerProfileDefDto) {
    this.context.stores.customerProfileDef.resetLoading();
    this.context.stores.customerProfileDef.set(CustomerProfileDef.fromJs(customerProfileDefDto));
  }

  onFetchError(error) {
    this.context.stores.customerProfileDef.resetLoading();
    qconsole.log(`Unexpected error requesting customer profile ${JSON.stringify(error)}`);
  }
}
