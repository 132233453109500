import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';
import currency from 'models/currency/currency';

export const TransmissionStatuses = createEnum('FAILED', 'SENT');
export const SecureDataRequestType = createEnum('CREDIT_CARD');
export const SecureDataRequestStatuses = createEnum(
  'COMPLETED',
  'DECLINED',
  'ERROR',
  'REQUESTING',
  'SUBMITTED',
  'TIMED_OUT'
);

export const SecureDataRequestMessageStatus = createModel({
  modelName: 'SecureDataRequestMessageStatus',
  properties: {
    errorCode: String,
    value: prop().oneOf(..._.keys(TransmissionStatuses)),
  },
});

export const SecureDataRequestStatusEvent = createModel({
  modelName: 'SecureDataRequestStatusEvent',

  properties: {
    requestStatus: prop().oneOf(..._.keys(SecureDataRequestStatuses)),
    messageStatus: prop(SecureDataRequestMessageStatus),
    createdAt: String,
  },
});

export const SecureDataRequestMetadata = createModel({
  modelName: 'SecureDataRequestMetadata',

  properties: {
    amount: Number,
    memo: String,
    monetaryUnit: Number,
    currency: prop()
      .oneOf(..._.keys(currency))
      .default(currency.USD),
  },
});

export default createModel({
  modelName: 'SecureDataRequest',

  properties: {
    metadata: SecureDataRequestMetadata,
    requestStatusHistory: prop([SecureDataRequestStatusEvent]).default([]),
    secureDataType: prop().oneOf(..._.keys(SecureDataRequestType)).isRequired,
    currentRequestStatus: prop(SecureDataRequestStatusEvent),
  },

  isActive() {
    return !_.includes(
      [SecureDataRequestStatuses.DECLINED, SecureDataRequestStatuses.SUBMITTED, SecureDataRequestStatuses.COMPLETED],
      this.currentRequestStatus.requestStatus
    );
  },
});
