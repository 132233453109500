import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('UnofferedCallNotification');

Factory.define('UnofferedCallNotificationWithDefaults')
  .extend('UnofferedCallNotification')
  .attr('id', 'call-id')
  .attr('reason', 'AGENT_TIMEOUT')
  .attr('type', 'UNOFFERED_CALL')
  .attr('conversationItem', () => Factory.attributes('PhoneCallItemWithDefaults'));
