import CreateComposition from './lib/create_composition';
import ExternalFormCompositionContent from 'models/composition/external_form_composition_content';

class CreateExternalFormComposition extends CreateComposition {
  constructor(context) {
    super(context, ExternalFormCompositionContent);
  }
}

export default CreateExternalFormComposition;
