import { Factory } from 'rosie';
import moment from 'moment';

import '../conversation_item_factory';

Factory.define('Task')
  .attr('type', 'TASK')
  .attr('updatedAt', moment().format());

Factory.define('TaskWithDefaults')
  .extend('Task')
  .attr('assignee', () => Factory.attributes('AssigneeWithAgentDefaults'))
  .attr('bodyHtml', '<div>Help out the customer with the issue</div>')
  .attr('hasNotifiedDue', true)
  .attr('note', 'Help out the customer with the issue')
  .attr(
    'closedAt',
    moment()
      .subtract(1, 'days')
      .format()
  )
  .attr(
    'dueAt',
    moment()
      .subtract(2, 'days')
      .format()
  )
  .attr('updatedAt', moment().format());

Factory.define('TaskNotDue')
  .extend('Task')
  .attr('assignee', () => Factory.attributes('AssigneeWithAgentDefaults'))
  .attr('bodyHtml', '<div>Help out the customer with the issue</div>')
  .attr('hasNotifiedDue', false)
  .attr('note', 'Help out the customer with the issue')
  .attr(
    'closedAt',
    moment()
      .subtract(1, 'days')
      .format()
  )
  .attr(
    'dueAt',
    moment()
      .add(2, 'days')
      .format()
  )
  .attr('updatedAt', moment().format());

Factory.define('TaskItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('Task', content);
  })
  .attr('initiator', {
    type: 'AGENT',
  });

Factory.define('TaskItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskWithDefaults', content);
  })
  .attr('initiator', {
    type: 'AGENT',
  });

Factory.define('TaskItemNotDue')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskNotDue', content);
  })
  .attr('initiator', {
    type: 'AGENT',
  });

Factory.define('TaskItemWithoutConversationId')
  .extend('ConversationItemWithoutConversationId')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskWithDefaults', content);
  })
  .attr('initiator', {
    type: 'AGENT',
  });
