import createModel from './lib/create_model';

const ConversationStatusChange = createModel({
  modelName: 'ConversationStatusChange',

  properties: {
    reopenWindow: String,
    status: String,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ConversationStatusChange;
