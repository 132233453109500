import { AgentAvailability } from 'models/agent_status';

export default class StayAway {
  constructor(context) {
    this.context = context;
  }

  run() {
    if (this.store.get().getAvailability() !== AgentAvailability.AWAY) {
      return;
    }

    this.store.resetPending();
    this.context.stores.modal.remove();
  }

  get store() {
    return this.context.stores.agentStatus;
  }
}
