import React from 'react';
import ZeroStateIcon from './icons/zero_state_icon';
import styled from 'styled-components';

import { H1 } from 'components/common/headers';

const ReportZerostateHeader = styled(H1)`
  margin-top: 15px;
`;

const ReportZeroState = () => {
  return (
    <div className="reportZeroState-container">
      <ZeroStateIcon />
      <ReportZerostateHeader data-aid="reportZeroState-upperText">Looking for a report?</ReportZerostateHeader>
      <div className="reportZeroState-lowerText">Use the filters above to customize your report</div>
    </div>
  );
};

export default ReportZeroState;
