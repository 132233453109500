import { AccountType } from 'models/account';
import CustomerView from 'models/location/customer_view';

export default class GetCreditCard {
  constructor(context) {
    this.context = context;
  }

  run({ itemId }) {
    const vgsAccount = this.context.stores.accounts.findBy({ type: AccountType.VGS });
    if (!vgsAccount) {
      throw new Error('no vgs account found');
    }

    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error('must be viewing customer to request credit card');
    }

    const vaultEnvironment = vgsAccount.content.vaultEnvironment;
    const vaultId = vgsAccount.content.vaultId;
    const customerId = currentLocation.customerId;

    this.context.gateways.creditCard.get(vaultEnvironment, vaultId, customerId, itemId);
  }
}
