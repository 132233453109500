import { Factory } from 'rosie';

Factory.define('CustomerEmailAddress');

Factory.define('CustomerEmailAddressPrimary')
  .extend('CustomerEmailAddress')
  .attr('original', 'martha.williams@gmail.com')
  .attr('primary', true);

Factory.define('CustomerEmailAddressWithDefaults').extend('CustomerEmailAddressPrimary');
