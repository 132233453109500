import _ from 'lodash';

import createModel, { prop } from 'models/lib/create_model';
import SearchFilter from './search_filter';

const filterProperties = SearchFilter.properties;
const DEFAULT_PAGE_SIZE = 25;
export default createModel({
  modelName: 'SearchQuery',

  properties: {
    fields: prop(Array),
    filter: prop(SearchFilter),
    from: prop(Number).default(0),
    languages: prop(Array),
    size: prop(Number).default(DEFAULT_PAGE_SIZE),
    sort: prop(String),
    text: prop(String),
  },

  setFilterProperties(properties) {
    if (!this.filter) {
      this.resetFilter();
    }
    _.each(properties, (pv, pk) => {
      if (_.find(filterProperties, p => p.label === pk)) {
        let filterSetMethod = _.camelCase(`set ${pk}`);
        this.filter[filterSetMethod](pv);
      }
    });
  },

  resetFilter() {
    this.filter = new SearchFilter();
  },

  setText(text) {
    this.text = text.trim();
  },

  // previous search implementation
  setType(type) {
    this.setFilterProperties({ type });
  },

  setFrom(from) {
    this.from = from;
  },

  setSize(size) {
    this.size = size;
  },

  statics: {
    DEFAULT_PAGE_SIZE,
  },
});
