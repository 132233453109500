import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkifyIt = new LinkifyIt();
linkifyIt
  .tlds(tlds)
  .add('mailto:', null)
  .add('ftp:', null);

export function getNormalizedUrl(url) {
  if (!url) {
    return '';
  }
  const matches = linkifyIt.match(url);
  return (matches && matches[0].url) || url;
}

/**
 * Note: This can return false positives.
 */
export function isUrlValid(url) {
  return url && linkifyIt.test(url);
}

export default linkifyIt;
