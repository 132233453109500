import AddRelationship from 'actions/relationship/add_relationship';
import LinkItemToCustomer from 'actions/conversation_item/pin_item/link_item_to_customer';
import UpdateRelationship from 'actions/relationship/update_relationship';

export default class ConfirmLinkToCustomer {
  constructor(context) {
    this.context = context;
  }

  run({
    itemId,
    originalCustomerId,
    selectedCustomerId,

    labelId,
    originalLabelId,
    relationshipId,
  }) {
    if (itemId) {
      this.context.executeAction(LinkItemToCustomer, {
        itemId,
        linkedCustomerId: selectedCustomerId,
        originalCustomerId,
      });
    }

    const relationship = this.context.stores.relationships.findBy({ customerId: selectedCustomerId });
    if (relationship && labelId !== originalLabelId) {
      this.context.executeAction(UpdateRelationship, { originalCustomerId, id: relationshipId, labelId });
    } else if (!relationship) {
      this.context.executeAction(AddRelationship, {
        customerId: selectedCustomerId,
        originalCustomerId,
        labelId,
      });
    }
  }
}
