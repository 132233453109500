import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';

export function getLookupAnalyticsProps(context) {
  let lookupType = getLookupResultType(context);
  return {
    itemCount: lookupType === LookupResultType.SEARCH ? getSearchResultCount(context) : getSuggestionCount(context),
    selectionType: lookupType,
  };
}

export function getSuggestionCount(context) {
  return context.stores.externalLookupSuggestions.isPending()
    ? undefined
    : _.get(context.stores.externalLookupSuggestions.get(), 'results.length', 0);
}

export function getSearchResultCount(context) {
  return context.stores.externalCustomerSearchResults.isPending()
    ? undefined
    : _.get(context.stores.externalCustomerSearchResults.get(), 'results.length', 0);
}

export function getLookupResultType(context) {
  return context.stores.externalCustomerSearchResults.get() ? LookupResultType.SEARCH : LookupResultType.SUGGESTION;
}

export const LookupResultType = createEnum('SEARCH', 'SUGGESTION');
