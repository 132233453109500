import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import METADATA_ICON_CLASSES from '../metadata/metadata_icon_classes';
import ItemContent from '../item_content';
import Metadata from '../metadata/metadata';
import SwitchCurrentConversation from 'actions/customer/switch_current_conversation';

export function EmailSendFailureItem({ emailItemId, item, onClickError }) {
  const href = emailItemId ? `#conversationItem-${emailItemId}` : undefined;
  const recipientInfo = item.content.recipient ? ` Email not delivered for ${item.content.recipient}.` : '';
  const errorDetail = getErrorDetail();
  const helpDocDetail = ` For more information, please visit our `;
  const helpDocHref = `https://help.gladly.com/docs/why-do-i-keep-getting-errors-when-sending-an-email`;
  const errorMessage = ` – ${errorDetail}.${recipientInfo}`;

  const metadataText = (
    <span className="emailSendFailureItem">
      <a className="emailSendFailureItem-link" href={href} onClick={onClick}>
        Error
      </a>
      {errorMessage}
      {helpDocDetail}
      <a className="emailSendFailureItem-helpDoc-link" href={helpDocHref} rel="noreferrer" target="_blank">
        help docs
      </a>
    </span>
  );

  return (
    <ItemContent isAuditItem isInbound={false} item={item}>
      <Metadata
        iconClass={METADATA_ICON_CLASSES[ConversationItemType.EMAIL_SEND_FAILURE]}
        isInbound={false}
        text={metadataText}
        timestamp={item.timestamp}
      />
    </ItemContent>
  );

  function onClick(e) {
    e.preventDefault();
    onClickError();
  }

  function getErrorDetail() {
    const content = item.content;
    switch (content.code) {
      case 421:
        return 'Temporary delay due to high volume';
      case 450:
        return 'Mailbox full';
      case 511:
      case 550:
        return 'Mailbox does not exist';
      case 552:
        return 'Mailbox not found';
      case 413:
        // https://documentation.mailgun.com/en/latest/api-intro.html#errors
        return 'Attachment size is too large';
      case 523:
        return 'Email size is too large';
      case 605:
        return 'Non-deliverable email address';
      // Gladly custom error codes
      case 1000:
        return "Invalid 'to' address";
      case 1001:
        return "Invalid 'cc' address";
      case 1002:
        return "Invalid 'bcc' address";
      case 1004:
        return 'Invalid sending domain. Please ensure your domain has been configured. If additional assistance is needed, please contact Gladly Support';
      case 1005:
        return 'Sending failed due to attachments. Please re-upload the attachments and resend the email';
    }

    if (
      (content.detail != null && content.detail.toLowerCase().indexOf('spam') !== -1) ||
      (content.recipientDetail != null && content.recipientDetail.toLowerCase().indexOf('spam') !== -1) ||
      (content.senderDetail != null && content.senderDetail.toLowerCase().indexOf('spam') !== -1)
    ) {
      return 'Rejected as spam';
    }

    return 'Unknown error';
  }
}

EmailSendFailureItem.propTypes = {
  emailItemId: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onClickError: PropTypes.func.isRequired,
};

function mapStateToProps(context, props) {
  return props;
}

function mapExecuteToProps(executeAction, { conversationId, emailItemId, item }) {
  return {
    onClickError: () => {
      if (conversationId && emailItemId) {
        executeAction(SwitchCurrentConversation, {
          conversationId,
          conversationItemId: emailItemId,
        });
      }
    },
  };
}

const EmailSendFailureItemContainer = connect(mapStateToProps, mapExecuteToProps)(EmailSendFailureItem);
EmailSendFailureItemContainer.propTypes = {
  conversationId: PropTypes.string,
  emailItemId: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
export { EmailSendFailureItemContainer };

function mapStateToItemFetcherProps({ getProvider }, { item }) {
  let emailItemId, conversationId;
  if (item.relatedItemId && item.relatedItemId.conversationId && item.relatedItemId.conversationItemId) {
    emailItemId = item.relatedItemId.conversationItemId;
    conversationId = item.relatedItemId.conversationId;
  }
  return {
    conversationId,
    emailItemId,
    item,
  };
}

const FailedEmailItemFetcher = connect(mapStateToItemFetcherProps)(EmailSendFailureItemContainer);
FailedEmailItemFetcher.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
export default FailedEmailItemFetcher;
