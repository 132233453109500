import _ from 'lodash';
import { v4 } from 'uuid';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import { createFakeJwt, simulateEmailToConsole } from '../local_rest_backend';

const validHostnameRegex = new RegExp(
  '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$'
);

export default class UserService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(requestorId, orgId) {
    return this.getDatabase(orgId).users;
  }

  add(requestorId, orgId, { correlationId, payload }) {
    let user = payload;

    // add a one-time token to user
    user.activationToken = v4();

    let database = this.getDatabase(orgId);
    const userDomain = user.username.replace(/.*@/, '');

    if (!validHostnameRegex.test(userDomain)) {
      this.publishError(`v1/requestor/${requestorId}/orgs/${orgId}/users`, correlationId);
    } else {
      database.users.push(user);
      simulateEmailToConsole({
        actionMessage: 'Activate account',
        jwToken: createFakeJwt({
          activationToken: user.activationToken,
          orgId,
        }),
        path: 'activate',
      });

      this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/users`, { correlationId, status: 'success' });
      this._pubsub.publish(`v1/orgs/${orgId}/users/${user.id}`, { payload: user });
    }
  }

  update(requestorId, orgId, userId, { correlationId, payload }) {
    let database = this.getDatabase(orgId);

    let dbUser = _.find(database.users, { id: userId });
    let modifiedUser = _.merge({}, dbUser, payload, (lhs, rhs) => (_.isArray(lhs) ? rhs : undefined));

    if (!isActivated(modifiedUser) && isActivatable(modifiedUser)) {
      modifiedUser.activatedAt = new Date().toISOString();
    }
    const userDomain = modifiedUser.username.replace(/.*@/, '');

    if (!validHostnameRegex.test(userDomain)) {
      this.publishError(`v1/requestor/${requestorId}/orgs/${orgId}/users/${userId}`, correlationId);
    } else {
      _.assign(dbUser, modifiedUser);

      this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/users/${dbUser.id}`, {
        correlationId,
        status: 'success',
      });
      this._pubsub.publish(`v1/orgs/${orgId}/users/${dbUser.id}`, { payload: dbUser });
    }
  }

  updateActivationToken(requestorId, orgId, userId, { correlationId, payload }) {
    let user = _.find(this.getDatabase(orgId).users, { id: userId });
    if (!isActivated(user)) {
      user.activationSentAt = new Date().toISOString();
    }
    this._pubsub.publish(`v1/orgs/${orgId}/users/${user.id}`, { payload: user });
  }

  publishError(topic, correlationId) {
    this._pubsub.publish(topic, {
      correlationId,
      payload: {
        errors: [
          {
            code: 'invalid',
            detail: 'email has invalid hostname',
            attr: 'username',
          },
        ],
      },
      status: 'error',
    });
  }
}

function isActivatable(user) {
  return !!user.password;
}

function isActivated(user) {
  return !!user.activatedAt;
}
