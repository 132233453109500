export default class RuleService {
  constructor(pubsub, getDatabase) {
    this.pubsub = pubsub;
    this.getDatabase = getDatabase;

    this.fetch = this.fetch.bind(this);
    this.add = this.add.bind(this);
    this.update = this.update.bind(this);
  }

  fetch(requestorId, orgId, { correlationId }) {
    let rules = this.getRules(orgId) || [];
    this.publish(`v1/requestor/${requestorId}/orgs/${orgId}/rules`, {
      correlationId,
      status: 'success',
      payload: rules,
    });
  }

  add(requestorId, orgId, { correlationId, payload: rule }) {
    let rules = this.getRules(orgId);
    if (rule.order == null) {
      rule.order = rules.length;
    }
    rules.push(rule);

    this.publish(`v1/requestor/${requestorId}/orgs/${orgId}/rules`, { correlationId, status: 'success' });
    this.publish(`v1/orgs/${orgId}/rules/${rule.id}`, { correlationId, payload: rule });
  }

  update(requestorId, orgId, ruleId, { correlationId, payload: rule }) {
    let reordered = this.reorder(orgId, rule);
    this.replace(orgId, rule);

    this.publish(`v1/requestor/${requestorId}/orgs/${orgId}/rules/${rule.id}`, { correlationId, status: 'success' });

    reordered.concat(rule).forEach(r => {
      this.publish(`v1/orgs/${orgId}/rules/${r.id}`, { correlationId, payload: r });
    });
  }

  reorder(orgId, rule) {
    let rules = this.getRules(orgId);
    let oldRule = rules.find(r => r.id === rule.id);

    let oldOrder = oldRule.order;
    let newOrder = rule.order;

    let [minOrder, maxOrder, delta] = oldOrder < newOrder ? [oldOrder + 1, newOrder, -1] : [newOrder, oldOrder - 1, 1];

    oldRule.order = newOrder;

    let reordered = [];
    rules.forEach(r => {
      if (r.order >= minOrder && r.order <= maxOrder && r.id !== rule.id) {
        r.order += delta;
        reordered.push(r);
      }
    });

    return reordered;
  }

  replace(orgId, rule) {
    let rules = this.getRules(orgId);
    let i = rules.findIndex(r => r.id === rule.id);
    rules[i] = rule;
  }

  getRules(orgId) {
    return this.getDatabase(orgId).rules;
  }

  publish(topic, message) {
    this.pubsub.publish(topic, message);
  }
}
