import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useExecuteAction } from 'components/hooks/connect_hooks';
import ConversationItem from 'models/conversation_item';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import { PaymentStatusEventType } from 'models/payment_status_event_content';
import qconsole from 'scripts/lib/qconsole';
import ItemContent from '../item_content';
import AgentMetadata from '../metadata/agent_metadata';
import Metadata from '../metadata/metadata';
import CustomerMetadata from '../metadata/customer_metadata';
import { iconClassNameForItem } from '../metadata/metadata_icon_classes';

export default function PaymentStatusEventItem({ item }) {
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    executeAction(NavigateToItem, { customerId: item.customerId, itemId: item.content.secureDataItemId });
  }, [item.customerId, item.content.secureDataItemId]);

  switch (item.content.eventType) {
    case PaymentStatusEventType.COMPLETED:
      return renderAgentMetadata(textMetadata('marked ', 'request', ' as completed', 'paymentCompleted'));
    case PaymentStatusEventType.DECLINED:
      return renderCustomerMetadata(textMetadata('declined ', 'request', undefined, 'paymentDeclined'));
    case PaymentStatusEventType.SUBMITTED:
      return renderCustomerMetadata(textMetadata('submitted ', 'credit card', undefined, 'paymentSubmitted'));
    case PaymentStatusEventType.TIMED_OUT:
      return renderMetadata(textMetadata(undefined, 'session', ' timed out'));
    case PaymentStatusEventType.CARD_VIEWED:
      return renderAgentMetadata(textMetadata('viewed', ' credit card', undefined, 'creditCardViewed'));
    default:
      qconsole.error('invalid payment status event type');
      return null;
  }

  function renderAgentMetadata(metadataText) {
    return (
      <ItemContent isAuditItem item={item}>
        <AgentMetadata iconClass={iconClassNameForItem(item)} item={item} text={metadataText} />
      </ItemContent>
    );
  }

  function renderCustomerMetadata(metadataText) {
    return (
      <ItemContent isAuditItem item={item}>
        <CustomerMetadata iconClass={iconClassNameForItem(item)} item={item} text={metadataText} />
      </ItemContent>
    );
  }

  function renderMetadata(metadataText) {
    return (
      <ItemContent isAuditItem item={item}>
        <Metadata iconClass={iconClassNameForItem(item)} text={metadataText} timestamp={item.timestamp} />
      </ItemContent>
    );
  }

  function textMetadata(start, middle, end, className) {
    return (
      <span className={`${className} payment-status-item`}>
        {start}
        <a
          className={`payment-status-link itemMetadata-subject`}
          href={`#conversationItem-${item.content.secureDataItemId}`}
          onClick={onClick}
        >
          {middle}
        </a>
        {end}
      </span>
    );
  }
}

PaymentStatusEventItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
