import { Path } from 'slate';

// Usually ancestorEntry can be acquired with import { getAbove } from '@udecode/plate-common'
export default function getPreviousSiblingNode(ancestorEntry, path) {
  const [ancestor, ancestorPath] = ancestorEntry;
  const leafIndex = path[ancestorPath.length];

  if (leafIndex - 1 >= 0) {
    return [ancestor.children[leafIndex - 1], Path.previous(path)];
  }

  return null;
}
