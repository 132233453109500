import _ from 'lodash';

import UpdateProfileActionBase from './update_profile_action_base';

export default class DeletePhoneNumber extends UpdateProfileActionBase {
  _update(profile, { index }) {
    profile.deletePhoneNumber(index);
    return _.pick(profile, 'phones');
  }

  _getValidationErrors() {
    return [];
  }
}
