import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import connect from 'components/lib/connect';
import { StyledAvatar } from './task_comment';

export function FakeCommentEditor({ agentAvatarUrl, agentName, className, onClick }) {
  return (
    <StyledEditorWrapper className={className} onClick={onClick}>
      <StyledAvatar noWrapper profile={{ name: agentName, image: agentAvatarUrl }} />
      <StyledCommentEditor data-aid="fakeCommentEditor" placeholder="Comment on this task" readOnly />
    </StyledEditorWrapper>
  );
}

const StyledCommentEditor = styled.input`
  border: 1px solid ${p => p.theme.colors.gray400};
  border-radius: ${p => p.theme.borderRadius.default};
  cursor: pointer;
  margin-left: ${p => p.theme.spacing.medium};
  outline: none;
  padding: 8px 12px;
  width: 100%;

  &:hover {
    background-color: ${p => p.theme.colors.white};
    border-color: ${p => p.theme.colors.green400};
    ::placeholder {
      color: ${p => p.theme.colors.green400};
    }
  }
`;

const StyledEditorWrapper = styled.div`
  display: flex;
`;

FakeCommentEditor.propTypes = {
  agentName: PropTypes.string,
  agentAvatarUrl: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

function mapStateToProps({ getProvider }) {
  const agent = getProvider('currentAgent').get();

  return {
    agentName: agent && (agent.name || agent.email),
    agentAvatarUrl: agent && agent.avatarUrl,
  };
}

const FakeEditorContainer = connect(mapStateToProps)(FakeCommentEditor);
export default FakeEditorContainer;
