import _ from 'lodash';
import { Node } from 'slate';

export function serializePlaintext(editor) {
  return serializePlaintextFragment(editor?.children);
}

export function serializePlaintextFragment(nodes) {
  return _.map(nodes, n => {
    return Node.string(n);
  }).join('\n');
}
