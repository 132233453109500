import _ from 'lodash';

import AgentActivity, { TaskStatus } from 'models/agent_activity';
import { getActiveConversationId, getCommunicationSession } from 'actions/conversation/lib/conversation_helpers';
import ItemLinkContent from 'models/item_link_content';

export default class SetActivity {
  constructor(context) {
    this.context = context;
  }

  run({ agentId, availabilityChannels, channel, reasonId, routingGroupId, timestamp, type }) {
    agentId = agentId || (this.currentAgent && this.currentAgent.id);
    let customerId = this.currentCustomerId;

    // only currently active conversations should be used for agent activities
    // we want to attribute any agent activities on the customer profile to
    // currently active conversations even if agents navigate directly to an old, closed conversation
    let conversationId = this.getCurrentConversationId(customerId);
    let communicationSession = getCommunicationSession(customerId, this.context.stores);

    const agentActivity = {
      agentId,
      availabilityChannels,
      channel,
      conversationId,
      customerId,
      reasonId,
      routingGroupId,
      timestamp,
      type,
    };

    if (!_.isEmpty(communicationSession)) {
      agentActivity.communicationSessionId = communicationSession.id;
      agentActivity.communicationSessionChannel = communicationSession.type;
      agentActivity.communicationSessionStatus = communicationSession.status;
    }

    if (agentActivity.communicationSessionStatus && availabilityChannels == null) {
      let channels = this.context.stores.agentRoutingPreferences.get().channels;
      agentActivity.availabilityChannels = _.keys(channels).filter(id => channels[id]);
    }

    const task = this.getTaskFromPanel(customerId);
    if (task) {
      agentActivity.taskId = task.id;
      agentActivity.taskStatus = task.status;
    }

    const activity = AgentActivity.create(agentActivity);
    this.context.stores.agentActivity.set(activity);
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }

  getCurrentConversationId(customerId) {
    if (customerId) {
      const { conversations } = this.context.stores.customers.storesFor(customerId);
      return getActiveConversationId(conversations);
    }
  }

  getTaskFromPanel(customerId) {
    const commentPanel = this.context.stores.commentPanel.get();

    if (!commentPanel.lastOpenedAt) {
      return;
    }

    const itemId = commentPanel.selectedItemId;
    if (!itemId) {
      return;
    }

    if (customerId && this.context.stores.customers.has({ id: customerId })) {
      const { conversationHistory } = this.context.stores.customers.storesFor(customerId);
      let item = conversationHistory.findBy({ id: itemId });

      if (item && conversationHistory.isPending(itemId)) {
        item = conversationHistory.getPending(itemId);
      }

      if (!item) {
        return;
      }

      let taskId = itemId;
      let taskIsClosed = !!item.content.closedAt;
      if (item.content instanceof ItemLinkContent) {
        taskId = item.content.itemId;
        taskIsClosed = !!item.content.originalContent.closedAt;
      }

      return {
        id: taskId,
        status: taskIsClosed ? TaskStatus.CLOSED : TaskStatus.OPEN,
      };
    }
  }
}
