import { Editor, Point, Range, Transforms } from 'slate';
import { match, createPluginFactory } from '@udecode/plate';
import { ReactEditor } from 'slate-react';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import ensureFullSelection from 'components/text_editor_new/lib/ensure_full_selection';
import getFirstNode from 'components/text_editor_new/lib/get_first_node';
import removeInlineFromStartOfSelection from 'components/text_editor_new/lib/remove_inline_from_start_of_selection';
import removeInlineOnBackspace from 'components/text_editor_new/lib/remove_inline_on_backspace';

export const PLACEHOLDER = 'placeholder';

export default function createPlaceholders() {
  return createPluginFactory({
    key: PLACEHOLDER,
    isElement: true,
    isInline: true,

    component: StyledPlaceholder,

    handlers: {
      // After any change that happens in the editor, our selection _may_ have ended up inside of a placeholder,
      // so we do a quick check to select the entire thing if that has happened.
      onChange: editor => () => {
        ensureFullSelection(editor, n => n.type === PLACEHOLDER);
      },

      // If we have the placeholder selected (via ensureFullSelection above), remove the entire placeholder
      // inline before inserting text.. if we don't do this, we just end up editing the text within.

      // We use onDOMBeforeInput rather than onKeyDown because onKeyDown gets called for ALL input presses,
      // including hitting Cmd for example. onDOMBeforeInput lets us confirm that the user is in fact
      // inserting text and not doing something else to the document that involves a keypress (like hitting
      // Tab!).
      onDOMBeforeInput: editor => evt => {
        return removeInlineFromStartOfSelection(editor, evt, PLACEHOLDER);
      },

      // Do two things:
      //   1. If we hit backspace and we have a placeholder directly behind the cursor, delete it.
      //   2. If hit tab, try to focus the next placeholder if it exists.
      onKeyDown: editor => evt => {
        if (ReactEditor.isComposing(editor)) {
          return;
        }

        const { selection } = editor;

        removeInlineOnBackspace(editor, evt, PLACEHOLDER);
        if (evt.defaultPrevented) {
          return;
        }

        if (evt.key !== 'Tab') {
          return;
        }

        // TODO: this isn't working quite correctly (WAIT HOW? LOL)
        let currentPlaceholder;
        for (const [node, path] of Editor.nodes(editor, {
          match: n => {
            return n.type === PLACEHOLDER;
          },
        })) {
          currentPlaceholder = [node, path];
        }

        const startPoint = Range.start(selection);
        const navigateBackwards = evt.shiftKey;

        let hasSeenCurrentPlaceholder;
        let nextPlaceholder;
        for (const [node, path] of Editor.nodes(editor, {
          at: [],
          match: n => {
            return n.type === PLACEHOLDER;
          },
          reverse: navigateBackwards,
        })) {
          // On first tab, we'll "see" our current placeholder. So ignore it.
          if (currentPlaceholder && !hasSeenCurrentPlaceholder && node === currentPlaceholder[0]) {
            hasSeenCurrentPlaceholder = true;
          }
          // After that, we'll look at the "next" placeholder, ensuring that it lies somewhere after
          // (or before, if holding shift) our current selection.
          else if (
            !nextPlaceholder &&
            Point.compare(startPoint, { offset: 0, path }) === (navigateBackwards ? 1 : -1)
          ) {
            nextPlaceholder = [node, path];
            break;
          }
        }

        if (!nextPlaceholder) {
          return;
        }

        const newSelection = {
          anchor: {
            offset: 0,
            path: [...nextPlaceholder[1], 0],
          },
          focus: {
            offset: nextPlaceholder[0].children[0].text.length,
            path: [...nextPlaceholder[1], 0],
          },
        };

        evt.preventDefault();
        Transforms.select(editor, newSelection);
      },
    },

    deserializeHtml: {
      rules: [{ validNodeName: ['PLACEHOLDER'] }],
    },

    serializeHtml: props => {
      const { children } = props;
      return <placeholder>{children}</placeholder>;
    },

    withOverrides: withNormalizePlaceholders(),
  })();
}

function withNormalizePlaceholders() {
  return editor => {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
      // Remove empty placeholder
      if (match(node, [], { type: PLACEHOLDER })) {
        if (!node.children.length || !node.children[0].text) {
          return Transforms.removeNodes(editor, { at: path });
        }
      }
      normalizeNode([node, path]);
    };

    return editor;
  };
}

export const StyledPlaceholder = styled.span.attrs({ 'data-aid': 'slate-placeholder' })`
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  color: #a5a5a5;
  padding: 2px 4px;
  span::selection {
    background-color: #b2d7fe;
    color: #5a85b3;
  }
`;

export function findFirstPlaceholderEntry(editor, { at } = { at: [] }) {
  return getFirstNode(editor, {
    at,
    match: n => {
      return n.type === PLACEHOLDER;
    },
  });
}

export function focusFirstPlaceholder(editor, { at } = { at: [] }) {
  const firstPlaceholderEntry = findFirstPlaceholderEntry(editor, { at });
  if (!firstPlaceholderEntry) {
    return;
  }

  const newSelection = {
    anchor: {
      offset: 0,
      path: [...firstPlaceholderEntry[1], 0],
    },
    focus: {
      offset: firstPlaceholderEntry[0].children[0].text.length,
      path: [...firstPlaceholderEntry[1], 0],
    },
  };

  Transforms.select(editor, newSelection);
}

export function useFocusFirstPlaceholder(editor) {
  useEffect(() => {
    if (editor) {
      focusFirstPlaceholder(editor);
    }
  }, [editor]);
}
