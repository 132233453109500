import { Factory } from 'rosie';

Factory.define('CompanyProfile');

Factory.define('CompanyProfileWithDefaults')
  .extend('CompanyProfile')
  .attr('emailDomain', 'example.com')
  .attr('name', 'Company Profile')
  .attr('widgets', ['CONVERSATION', 'EMAIL'])
  .attr('fromAddresses', ['support@example.com']);
