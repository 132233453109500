import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('VoiceRecordingDeleted').attr('type', 'VOICE_RECORDING_DELETED');

Factory.define('VoiceRecordingDeletedWithDefaults')
  .extend('VoiceRecordingDeleted')
  .attr('agentId', 'foo-agent-id')
  .attr('conversationItemId', 'foo-conversation-item-id');

Factory.define('VoiceRecordingDeletedItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('VoiceRecordingDeletedWithDefaults', content);
  });

Factory.define('VoiceRecordingDeletedItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('VoiceRecordingDeletedWithDefaults', content);
  })
  .attr('initiator', {
    type: 'AGENT',
    id: 'foo-agent-id',
  });
