import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const PhoneControlsActionContainer = ({ children, className }) => {
  return <div className={classnames('phoneControlsActionContainer', className)}>{children}</div>;
};

PhoneControlsActionContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default PhoneControlsActionContainer;
