import qconsole from 'scripts/lib/qconsole';

let localConnection,
  sendChannel,
  remoteConnection,
  connectionStarted = false;

// Gladly hasn't been working as well lately because of a change to the way Chrome throttles timers now, esp. causing frequent
// and spurious MQTT disconnects:

// https://developer.chrome.com/blog/timer-throttling-in-chrome-88/

// Throttling
//   This happens to timers that are scheduled when minimal throttling doesn't apply, and any of the following is true:
//     * The chain count is less than 5.
//     * The page has been hidden for less than 5 minutes.
//     * WebRTC is in use. Specifically, there's an RTCPeerConnection with an 'open' RTCDataChannel or a 'live' MediaStreamTrack.
//  The browser will check timers in this group once per second. Because they're only checked once per second, timers with a similar
//  timeout will batch together, consolidating the time the tab needs to run code. This isn't new either; browsers have been doing this
//  to some extent for years.

// So this is a hack which establishes a fake WebRTC connection to trick Chrome into thinking it shouldn't throttle the tab (or
// at least throttle as much as it is currently doing.)

export default function configureFakeWebRTCConnection() {
  localConnection = new RTCPeerConnection();
  sendChannel = localConnection.createDataChannel('sendChannel');
  sendChannel.onopen = () => {
    qconsole.log('[fakewebrtc] Send channel onopen');
  };
  sendChannel.onclose = () => {
    qconsole.log('[fakewebrtc] Send channel onclose');
  };
  remoteConnection = new RTCPeerConnection();
  remoteConnection.ondatachannel = () => {
    qconsole.log('[fakewebrtc] Remote connection on data channel');
  };

  localConnection.onicecandidate = e =>
    !e.candidate ||
    remoteConnection.addIceCandidate(e.candidate).catch(() => {
      qconsole.error('[fakewebrtc] Error setting up fake connection from local to remote');
    });

  remoteConnection.onicecandidate = e =>
    !e.candidate ||
    localConnection.addIceCandidate(e.candidate).catch(() => {
      qconsole.error('[fakewebrtc] Error setting up fake connection from remote to local');
    });
}

export function startFakeWebRTCConnection() {
  if (!localConnection || !remoteConnection) {
    return qconsole.error('[fakewebrtc] Unable to start fake WebRTC connection. Please setup the connection first');
  }

  if (connectionStarted) {
    return qconsole.log('[fakewebrtc] The connection is already active. Ignoring...');
  }

  return localConnection
    .createOffer()
    .then(offer => localConnection.setLocalDescription(offer))
    .then(() => remoteConnection.setRemoteDescription(localConnection.localDescription))
    .then(() => remoteConnection.createAnswer())
    .then(answer => remoteConnection.setLocalDescription(answer))
    .then(() => localConnection.setRemoteDescription(remoteConnection.localDescription))
    .then(() => {
      connectionStarted = true;
    })
    .catch(() => {
      qconsole.error('[fakewebrtc] Error setting up fake WebRTC connection');
    });
}

export function teardownFakeWebRTCConnection() {
  sendChannel && sendChannel.close();

  localConnection && localConnection.close();
  remoteConnection && remoteConnection.close();
  connectionStarted = false;
}
