import get from 'lodash/get';
import SelectFirstAnswerShown from './select_first_answer_shown';
import { getSearchContext, getSearchResultContexts, snowplowTypes } from 'scripts/lib/analytics/domain/snowplow';
import ClearSuggestedReply from 'actions/suggested_replies/clear_suggested_reply';
import RequestSuggestedReply from 'actions/suggested_replies/request_suggested_reply';

export default class HandleAnswerSearchResults {
  constructor(context) {
    this.context = context;
  }

  run({ search }) {
    const answerPanel = this.context.stores.answerPanel.getPending();
    const pendingSearch = get(answerPanel, 'searchResult');
    if (!pendingSearch || !pendingSearch.isLoading || pendingSearch.id !== search.id) {
      return;
    }

    answerPanel.setSearchResult(search);
    this.context.stores.answerPanel.resetPending();
    this.context.stores.answerPanel.set(answerPanel);
    this.context.executeAction(SelectFirstAnswerShown);
    const snowplowContexts = [getSearchContext(search), ...getSearchResultContexts(search.hits)];
    this.context.analytics.trackSnowplow(
      snowplowTypes.EVENT_ANSWER_SEARCHED,
      { sortType: search.query.sort, language: search.query.languages },
      snowplowContexts
    );

    if (this.context.stores.appFeatures.get().isEnabled('suggestedReplies')) {
      const currentLocation = this.context.stores.currentLocation.get();
      this.context.executeAction(ClearSuggestedReply, { customerId: currentLocation.customerId });
      const id = search.id;
      const text = search.query.text;
      const snippets = search.hits;
      const searchLanguage = answerPanel.searchLanguage;
      const searchAudiences = answerPanel.searchAudiences;
      this.context.executeAction(RequestSuggestedReply, { id, text, snippets, searchLanguage, searchAudiences });
    }
  }
}
