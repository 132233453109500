import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('Webhook').extend('Uuid');

Factory.define('WebhookWithDefaults')
  .extend('Webhook')
  .attr('id', 'default-id')
  .attr('name', 'default-name')
  .attr('enabled', true)
  .attr('events', ['CONVERSATION/CLOSED'])
  .attr('url', 'default-url')
  .attr('hasCredentials', true)
  .attr('headersKeys', ['header-key']);
