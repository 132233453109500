import _ from 'lodash';

import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import { getAllReportConfigs } from 'scripts/application/actions/reporting/lib/reporting_helpers';
import { findReportConfigBySlug, getTypeFromSlug } from 'models/reports/report';
import fetchReportConfigs from 'actions/reporting/fetch_report_configs';
import requestSharedReportConfigs from 'actions/reporting/request_shared_report_configs';
import { getChannelFilterValue } from 'models/reports/report_channel';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import TrackEvent from 'actions/analytics/track_event';
import Reports, { DEFAULT_REPORT_SLUG } from 'models/location/reports';
import ServerClock from 'scripts/application/lib/server_clock';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class OpenReport {
  constructor(context) {
    this.context = context;
  }

  run(params) {
    if (!this.isFeatureEnabled('viewReports')) {
      this.context.router.navigateHome();
      return;
    }

    // will be undefined unless legacy report slug
    let legacyType = getTypeFromSlug(params.slug || DEFAULT_REPORT_SLUG);

    let newLocation;
    try {
      newLocation = Reports.create(params);
    } catch (e) {
      this.navigateToDefaultFilters(params.slug);
      return;
    }

    let currentLocation = this.context.stores.currentLocation.get();

    const sharedReportConfigs = this.context.stores.sharedReportConfigs.get();
    const isCookielessLookerEmbedEnabled = this.isFeatureEnabled('cookielessLookerEmbed');

    if (!(currentLocation instanceof Reports) || sharedReportConfigs.isEmpty()) {
      requestSharedReportConfigs(this.context)
        .then(() => this.getSharedReport({ newLocation, currentLocation, params, isCookielessLookerEmbedEnabled }))
        .catch(err => this.handleErrors(err));
    } else {
      this.getSharedReport({ newLocation, currentLocation, params, isCookielessLookerEmbedEnabled });
    }

    if (!(currentLocation instanceof Reports) || this.context.stores.reportConfigs.get().isEmpty()) {
      // if report should be fetched we render the report instead of empty state,
      //  setLoading so the spinner is visible while we fetch configs
      if (this.isReportFetchNeeded(currentLocation, newLocation)) {
        this.getStore(legacyType, isCookielessLookerEmbedEnabled).setLoading();
      }

      fetchReportConfigs(this.context)
        .then(() =>
          this.getReport({
            newLocation,
            currentLocation,
            params,
            legacyType,
            isCookielessLookerEmbedEnabled,
          })
        )
        .catch(err => this.handleErrors(err, legacyType));
    } else {
      this.getReport({
        newLocation,
        currentLocation,
        params,
        legacyType,
        isCookielessLookerEmbedEnabled,
      });
    }
  }

  handleErrors(err, legacyType) {
    this.resetLoading(legacyType);
    let interactiveHandler = new GatewayErrorInteractiveHandler(this.context);
    interactiveHandler.handleCommonErrors(this.context, err);
  }

  getStore(legacyType, isCookielessLookerEmbedEnabled) {
    return legacyType
      ? this.context.stores.report
      : isCookielessLookerEmbedEnabled
      ? this.context.stores.embedTokensReport
      : this.context.stores.embeddedReport;
  }

  resetLoading(legacyType) {
    this.getStore(legacyType).resetLoading();
  }

  getReport({ newLocation, currentLocation, params, legacyType, isCookielessLookerEmbedEnabled }) {
    let { slug, endpointNumber } = params;

    const allReportConfigs = getAllReportConfigs(this.context);

    const isIvrReport = this.isIvrReport(slug);

    const reportSlugs = _.map(allReportConfigs, 'urlSlug');
    const isInvalidReportSlug = slug && reportSlugs.indexOf(slug) < 0;

    if (isInvalidReportSlug) {
      return;
    }
    let slugForGetConfig = slug || DEFAULT_REPORT_SLUG;

    const config = findReportConfigBySlug(allReportConfigs, slugForGetConfig);

    const metricSet = config.metricSetName;

    let startAt = newLocation.getStartAt();
    let endAt = newLocation.getEndAt();
    if (endAt < startAt) {
      [startAt, endAt] = [endAt, startAt];
    }

    const configuredFilters = config.filters;
    const noConfiguredFilters = _.isEmpty(configuredFilters);
    const payload = {
      metricSet,
      startAt, // startAt and endAt are always sent, even for embedded reports that don't support the dateRange filter, in order to get the report descriptor
      endAt,
      timezone: newLocation.timezone,
      aggregationLevel: _.includes(configuredFilters, 'timeAggregation')
        ? newLocation.getValidAggregationLevel(config.timeAggregation)
        : undefined,
      filters: {
        channel: _.includes(configuredFilters, 'channel') ? this.getChannel(newLocation.channel) : undefined,
        teams: _.includes(configuredFilters, 'teams') ? newLocation.teamIds : undefined,
        inboxes: _.includes(configuredFilters, 'inboxes') ? newLocation.routingGroupIds : undefined,
        phoneNumber: _.includes(configuredFilters, 'phoneNumber') ? endpointNumber : undefined,
        topics: _.includes(configuredFilters, 'topics') ? newLocation.topicIds : undefined,
      },
    };

    const fetchOptions = {
      correlationId: IdGenerator.newId(),
      reportRequestedAt: ServerClock.toISOString(),
    };

    let shouldFetchReport = this.isReportFetchNeeded(currentLocation, newLocation, noConfiguredFilters);

    if (isIvrReport && !endpointNumber) {
      // this is an IVR report and does not have an endpointNumber
      shouldFetchReport = false;
    }

    changeCurrentLocation(this.context, newLocation);

    if (shouldFetchReport) {
      if (legacyType) {
        this.context.stores.report.setLoading();
        this.context.gateways.report.fetch(legacyType, payload, fetchOptions);
      } else {
        if (isCookielessLookerEmbedEnabled) {
          this.context.stores.embedTokensReport.setLoading();
          this.context.gateways.embedTokensReport.add({ type: 'oob-report' }, payload);
        } else {
          this.context.stores.embeddedReport.setLoading();
          this.context.gateways.embeddedReport.create(payload);
        }
      }

      this.context.executeAction(TrackEvent, {
        event: 'Report Requested',
        props: {
          slug: newLocation.slug,
          view: 'UI',
          ..._.omitBy(payload, _.isUndefined),
          ..._.omitBy(fetchOptions, _.isUndefined),
          inboxCount: payload.filters.inboxes ? payload.filters.inboxes.length : 0,
          teamCount: payload.filters.teams ? payload.filters.teams.length : 0,
          topicCount: payload.filters.topics ? payload.filters.topics.length : 0,
        },
      });
    }
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.REPORTS });
  }

  getSharedReport({ newLocation, params, isCookielessLookerEmbedEnabled }) {
    let { slug } = params;
    const sharedReportConfigs = this.context.stores.sharedReportConfigs.get().configs;
    if (!slug || !_.some(sharedReportConfigs, { urlSlug: slug })) {
      return;
    }

    changeCurrentLocation(this.context, newLocation);

    if (isCookielessLookerEmbedEnabled) {
      this.context.stores.embedTokensReport.setLoading();
      this.context.gateways.embedTokensReport.add(
        { type: 'shared-report' },
        {
          id: slug,
          timezone: newLocation.timezone,
        }
      );
    } else {
      this.context.stores.embeddedReport.setLoading();
      this.context.gateways.sharedReport.add({ id: slug, timezone: newLocation.timezone });
    }

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.REPORTS });
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }

  isIvrReport(slug) {
    return slug === 'ivr-summary' || slug === 'ivr-end-states';
  }

  showInvalidSlugError() {
    this.context.executeAction(ShowToast, {
      type: ToastType.ERROR,
      message: 'Report cannot be found',
    });
  }

  // parameter adapters
  getChannel(channel) {
    return getChannelFilterValue(channel);
  }

  navigateToDefaultFilters(slug) {
    this.context.router.navigateTo(Reports.create({ slug }));
  }

  isReportFetchNeeded(currentLocation, newLocation, noConfiguredFilters) {
    if (noConfiguredFilters) {
      return true;
    }

    if (currentLocation instanceof Reports) {
      // navigating from within /reports
      if (currentLocation.getSlug() === newLocation.getSlug() && newLocation.hasFilters()) {
        // new report slug is the same as current report slug and there are filters
        return true;
      }
    } else if (newLocation.hasFilters()) {
      // navigating from outside /reports & has filters set (direct link)
      return true;
    }
    return false;
  }
}
