import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AttachmentButton from 'components/lib/attachment/attachment_button';
import { ChannelFieldName } from 'models/answers/snippet';
import { lngDirection } from 'models/answers/answer_languages';
import SlateEditor from 'components/customer/composition/lib/slate/slate_editor';
import SlateStylesMenu from 'components/customer/composition/lib/slate/slate_styles_menu_container';

export default class AnswerChannelComposition extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['setFocus', 'onAttachInputChange']);
  }

  render() {
    let channel = this.props.channel;
    let classNames = classnames('answerEditor-composition-container', {
      hasError: this.props.hasError,
      focused:
        (this.props.focusField === ChannelFieldName.INFO && channel === ChannelFieldName.INFO) ||
        (this.props.focusField === ChannelFieldName.ANY_CHANNEL && channel === ChannelFieldName.ANY_CHANNEL) ||
        (this.props.focusField === ChannelFieldName.MESSAGE && channel === ChannelFieldName.MESSAGE) ||
        (this.props.focusField === ChannelFieldName.SELF_SERVICE && channel === ChannelFieldName.SELF_SERVICE),
    });

    return (
      <div className="answerEditor-composition-wrapper">
        <div className="answerEditor-label answerEditor-composition-header">
          <div>Content</div>
        </div>
        <div className={classNames}>
          {!this.props.readOnly && this.renderStylesMenu(channel)}
          {this.renderEditor(channel)}
        </div>
      </div>
    );
  }

  renderStylesMenu(channel) {
    const editor = this.slate && this.slate.editor;
    const onChange = this.props.onChange;

    return (
      <StyledStylesMenu
        channel={channel}
        className="answerEditor-draftStylesMenu"
        editor={editor}
        language={this.props.language}
        onChange={onChange}
        value={this.props.html}
      >
        {channel === ChannelFieldName.INFO || channel === ChannelFieldName.ANY_CHANNEL
          ? this.renderAttachmentButton(channel)
          : null}
      </StyledStylesMenu>
    );
  }

  renderAttachmentButton(channel) {
    return (
      <AttachmentButton
        labelClassName="draftStylesMenu-item"
        onAttach={this.onAttachInputChange}
        onAttachBlur={this.props.onAttachBlur}
        ref={node => (this.attachmentButton = node)}
      />
    );
  }

  renderEditor(channel) {
    const className = classnames('answerEditor-textComposition', 'answerEditor-slateComposition', {
      'answerEditor-textComposition-info': channel === ChannelFieldName.INFO,
      'answerEditor-textComposition-selfService': channel === ChannelFieldName.SELF_SERVICE,
      'answerEditor-textComposition-email': channel === ChannelFieldName.ANY_CHANNEL,
      'answerEditor-textComposition-message': channel === ChannelFieldName.MESSAGE,
      directionRtl: lngDirection(this.props.language) === 'rtl',
    });
    let placeholder = 'Enter content';
    let allowDroppingFiles = false;
    switch (channel) {
      case ChannelFieldName.INFO:
        placeholder = 'Enter reference content';
        allowDroppingFiles = true;
        break;
      case ChannelFieldName.MESSAGE:
        placeholder = 'Enter messaging content';
        break;
      case ChannelFieldName.ANY_CHANNEL:
        placeholder = 'Enter email content';
        allowDroppingFiles = true;
        break;
      case ChannelFieldName.SELF_SERVICE:
        placeholder = 'Enter public content';
        break;
    }

    return (
      <SlateEditor
        allowDroppingFiles={allowDroppingFiles}
        answerLinks={this.props.snippetLinks}
        answerMenuData={this.props.answerMenuData}
        channel={channel}
        className={className}
        onChange={this.props.onChange}
        onDropFiles={this.props.onDropFiles}
        onSearchAnswerMenu={this.props.onSearchAnswerMenu}
        placeholder={this.props.readOnly ? 'There is no content' : placeholder}
        readOnly={this.props.readOnly}
        ref={node => (this.slate = node)}
        setFocus={this.setFocus}
        unsetFocus={this.unsetFocus}
        value={this.props.html}
        variableMenuData={this.props.mentionMenuData}
      />
    );
  }

  onAttachInputChange() {
    this.props.onAttachInputChange(this.props.channel);
  }

  setFocus() {
    this.props.setFocus(this.props.channel);
  }
}

AnswerChannelComposition.propTypes = {
  answerMenuData: PropTypes.array,
  channel: PropTypes.string.isRequired,
  focusField: PropTypes.string,
  hasError: PropTypes.bool,
  html: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  mentionMenuData: PropTypes.array.isRequired,
  onAttachBlur: PropTypes.func.isRequired,
  onAttachInputChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDropFiles: PropTypes.func.isRequired,
  onSearchAnswerMenu: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  setFocus: PropTypes.func.isRequired,
  snippetLinks: PropTypes.array,
};

const StyledStylesMenu = styled(SlateStylesMenu)`
  padding-left: 8px;
  padding-right: 8px;
`;
