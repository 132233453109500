import { Factory } from 'rosie';
import moment from 'moment';

import '../conversation_item_factory';

Factory.define('ChatMessage').attr('type', 'CHAT_MESSAGE');
Factory.define('ChatMessageWithDefaults')
  .extend('ChatMessage')
  .attr('messageType', 'TEXT')
  .attr('participantId', 'default-agent-id')
  .attr('platform', 'WEB')
  .attr('sentAt', () => moment().toISOString())
  .attr('sessionId', 'default-chat-session-id')
  .attr('sessionItemId', 'default-chat-session-item-id')
  .attr('text', 'Some chat message text');

Factory.define('ChatMessageItem')
  .extend('ConversationItem')
  .attr('_demoOnly', true)
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ChatMessage', content);
  });

Factory.define('ChatMessageItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ChatMessageWithDefaults', content);
  });

Factory.define('ChatEnded').attr('type', 'CHAT_ENDED');
Factory.define('ChatEndedWithDefaults')
  .extend('ChatEnded')
  .attr('sessionId', '')
  .attr('reasonEnded', 'AGENT_ENDED');

Factory.define('ChatEndedItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('initiator', { type: 'AGENT', id: 'default-agent-id' })
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ChatEndedWithDefaults', content);
  });

Factory.define('ChatCustomerUrl').attr('type', 'CHAT_CUSTOMER_URL');
Factory.define('ChatCustomerUrlWithDefaults')
  .extend('ChatCustomerUrl')
  .attr('customerUrl', 'customer-url')
  .attr('action', 'INITIATED');

Factory.define('ChatCustomerUrlItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ChatCustomerUrlWithDefaults', content);
  });

Factory.define('ChatSelfServiceMetadata').attr('type', 'CHAT_SELF_SERVICE_METADATA');
Factory.define('ChatSelfServiceMetadataWithDefaults')
  .extend('ChatSelfServiceMetadata')
  .attr('action', 'INITIATED')
  .attr('browser', 'Chrome')
  .attr('customerUrl', 'https://www.google.com')
  .attr('device', 'PC')
  .attr('greeting', 'some-greeting');

Factory.define('ChatSelfServiceMetadataItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('ChatSelfServiceMetadataWithDefaults', content);
  });
