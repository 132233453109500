import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import ConversationItemsService from 'scripts/infrastructure/backends/fake_backend/conversation_items_service';

export default class AISuggestedReplyService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
    this.itemsService = ConversationItemsService.create(pubsub, database);
    this.customersSuggestionIndexItems = {};
  }

  get({ customerId, itemId, type }, callback, path, { orgId }) {
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: this._getSuggested(orgId, customerId, itemId, type),
    });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/ai-suggested-replies': {
          POST: this.get,
        },
      },
      this
    );
  }

  _getSuggested(orgId, customerId, itemId, type) {
    let customerIndexItemsByType = this.customersSuggestionIndexItems[customerId];
    if (!customerIndexItemsByType) {
      customerIndexItemsByType = {};
      this.customersSuggestionIndexItems[customerId] = customerIndexItemsByType;
    }

    let customerIndexItem = customerIndexItemsByType[type];
    if (!customerIndexItem) {
      customerIndexItem = {
        itemId,
        index: 0,
      };
      this.customersSuggestionIndexItems[customerId][type] = customerIndexItem;
    } else if (customerIndexItem.itemId !== itemId) {
      customerIndexItem.itemId = itemId;
      customerIndexItem.index += 1;
    }

    const customer = this._getCustomer(orgId, customerId);
    if (!customer.suggestedReplies) {
      return null;
    }
    const suggestedByType = customer.suggestedReplies[type];
    if (!suggestedByType) {
      return null;
    }

    return suggestedByType[customerIndexItem.index] || null;
  }

  _getCustomer(orgId, customerId) {
    return _.find(this.getDatabase(orgId).customers, { id: customerId });
  }
}
