import DismissNotification from 'actions/notification/dismiss_notification';

export default class StartNotificationTimer {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    DismissNotification.schedule(this.context, { id });
  }
}
