import React from 'react';
import PropTypes from 'prop-types';

import AgentMetadata from '../metadata/agent_metadata';
import ConversationItem from 'models/conversation_item';
import CreditCardRequestItem from './credit_card_request_item';
import ItemContent from 'components/customer/conversation_history/conversation_items_v2/item_content';
import { SecureDataRequestType } from 'models/secure_data_request';

export default function SecureDataRequestItem({ item }) {
  return (
    <ItemContent item={item}>
      <AgentMetadata iconClass="fa fa-credit-card" item={item} text={auditItemText(item)} />
      {renderContent(item)}
    </ItemContent>
  );
}

SecureDataRequestItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

function auditItemText(item) {
  switch (item.content.secureDataType) {
    case SecureDataRequestType.CREDIT_CARD:
      return 'requested to collect card information';
    default:
      throw new Error('unsupported secure data request type');
  }
}

function renderContent(item) {
  switch (item.content.secureDataType) {
    case SecureDataRequestType.CREDIT_CARD:
      return <CreditCardRequestItem item={item} />;
    default:
      throw new Error('unsupported secure data request type');
  }
}
