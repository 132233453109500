export default class RetryUpload {
  constructor(context) {
    this.context = context;
  }

  run({ uploadId }) {
    let composition = this.context.stores.snippetComposition.get();
    let upload = composition.findAttachmentById(uploadId);

    this.context.gateways.snippetUpload.fetchUploadAuth(
      composition.getUploadPath(upload.id),
      upload.fileDescriptor().toJs()
    );
  }
}
