import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ensureOptionInView from 'components/lib/ensure_option_in_view';
import {
  ConversationTopicMenuHeader,
  ConversationTopicSelectionCollapser,
  ConversationTopicSelectionDivider,
  ConversationTopicSelectionExpander,
  ConversationTopicMenuOption,
  ConversationTopicGroupTitle,
} from './conversation_topics_menu_options';
import { COLLAPSE_SELECTED_OPTIONS_NUM, TopicsMenuOptionType } from './conversation_topics_constants';

export default class ConversationTopicMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleWheel', 'handleHeaderClick', 'handleMenuMouseDown', 'handleMouseDown', 'setMenuRef']);
  }

  componentDidUpdate() {
    this.ensureOptionInView(this.props.focusedOptionId);
  }

  handleWheel(ev) {
    ev.stopPropagation();
  }

  handleHeaderClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.scrollToTop();
    this.props.onHeaderClick();
  }

  // this will prevent closing the menu when clicking in an area of the menu that is not an option or button
  handleMouseDown(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  handleMenuMouseDown(ev) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  render() {
    let classes = classnames('conversationTopic-menu-container', {
      'conversationTopic-menu-container-dropDown': this.props.dropDown,
    });
    return (
      <div className={classes} onMouseDown={this.handleMouseDown} onWheel={this.handleWheel}>
        {this.renderHeader()}
        <div className="conversationTopic-menu" onMouseDown={this.handleMenuMouseDown} ref={this.setMenuRef}>
          {this.renderOptions()}
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <ConversationTopicMenuHeader
        numSelections={this.props.numSelectedOptions}
        onClick={this.handleHeaderClick}
        onCloseClick={this.props.onCancel}
        unitLabelPlural={this.props.unitLabelPlural}
        unitLabelSingular={this.props.unitLabelSingular}
      />
    );
  }

  renderOptions() {
    return _.map(this.props.options, o => {
      let id = o.id;
      let optionId = o.optionId;
      return (
        <div
          className="conversationTopic-menu-option-container"
          id={`conversation-topic-option-${optionId}`}
          key={`conversation-topic-option-${optionId}`}
          ref={id}
        >
          {this.renderOption(o, optionId === this.props.focusedOptionId, this.props.onOptionFocus)}
        </div>
      );
    });
  }

  renderOption(option, isFocused) {
    switch (option.type) {
      case TopicsMenuOptionType.COLLAPSER:
        return (
          <ConversationTopicSelectionCollapser
            isFocused={isFocused}
            onClick={this.props.onCollapserClick}
            onMouseEnter={this.props.onOptionFocus}
            option={option}
          />
        );
      case TopicsMenuOptionType.DIVIDER:
        return <ConversationTopicSelectionDivider />;
      case TopicsMenuOptionType.EXPANDER:
        return (
          <ConversationTopicSelectionExpander
            isFocused={isFocused}
            numSelections={this.props.numSelectedOptions - COLLAPSE_SELECTED_OPTIONS_NUM}
            onClick={this.props.onExpanderClick}
            onMouseEnter={this.props.onOptionFocus}
            option={option}
          />
        );
      case TopicsMenuOptionType.OPTION:
        return (
          <ConversationTopicMenuOption
            isFocused={isFocused}
            onClick={this.props.onOptionClick}
            onMouseEnter={this.props.onOptionFocus}
            option={option}
          />
        );
      case TopicsMenuOptionType.GROUP_TITLE:
        return <ConversationTopicGroupTitle label={option.label} />;
      default:
        return null;
    }
  }

  /* in order to to get a sticky header on this menu, we need to disable `react-select`'s scrolling `<div>` and insert
   * our own. This, however, breaks `react-select`'s arrow key navigation. We have to compensate by scrolling an
   * option that is outside the menu into view when we navigate to it by arrow key.
   */
  ensureOptionInView(id) {
    if (!this.menu || !id) {
      return;
    }
    let optionNode = this.refs[id];
    if (!optionNode) {
      return;
    }
    ensureOptionInView(this.menu, optionNode);
  }

  setMenuRef(ref) {
    this.menu = ref;
  }

  scrollToTop() {
    this.menu.scrollTop = 0;
  }

  scrollToBottom() {
    this.menu.scrollTop = this.menu.scrollHeight;
  }
}

ConversationTopicMenu.propTypes = {
  dropDown: PropTypes.bool,
  focusedOptionId: PropTypes.string,
  numSelectedOptions: PropTypes.number.isRequired,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  onCollapserClick: PropTypes.func.isRequired,
  onExpanderClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onOptionFocus: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
};
