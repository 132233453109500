import _ from 'lodash';

import MqttGateway from './lib/mqtt_gateway';
import Observable from 'scripts/lib/observable_mixin';
import qconsole from 'scripts/lib/qconsole';
import qs from 'qs';
import NoopOnReconnectGateway from 'scripts/adapters/gateways/noop_on_reconnect_gateway';

export class AttachmentsGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer-history/+/conversation-items/+/attachments/+';
  }

  update(customerId, itemId, attachmentId, payload) {
    this._update([customerId, itemId, attachmentId], payload);
  }
}

class ConversationMergeGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer-history/+/merges';
  }

  subscribe(customerId) {
    return super._subscribe([customerId]);
  }

  unsubscribe(customerId) {
    super._unsubscribe([customerId]);
  }

  onReceive(mergeDto) {
    this.notifyObservers('handleCustomerMerge', mergeDto);
  }
}

export default class CustomerHistoryGateway {
  constructor(backend, requestorId) {
    this._gateways = {
      attachments: new AttachmentsGateway(backend, requestorId),
      conversationsHttp: new ConversationsHttpGateway(backend),
      conversationItemsHttp: new ConversationItemsHttpGateway(backend),
      conversationTopicIdsHttp: new NoopOnReconnectGateway(backend, {
        updateUrl: '/api/v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId/topicIds',
      }),
      currentConversationItemsHttp: new NoopOnReconnectGateway(
        backend,
        'v1/orgs/:orgId/customer-history/:customerId/current-items/:conversationId'
      ),
      merge: new ConversationMergeGateway(backend, requestorId),
    };

    this._gateways.conversationsHttp.setObserver({
      onFetchAllSuccess: this.handleReceiveConversations.bind(this),
      onFetchAllError: this.handleReceiveConversationsError.bind(this),
      onBroadcast: this.handleBroadcastConversation.bind(this),
      onBroadcastDelete: this.handleBroadcastDeleteConversation.bind(this),
    });
    this._gateways.conversationItemsHttp.setObserver({
      onBroadcast: this.handleBroadcastConversationItem.bind(this),
      onBroadcastDelete: this.handleBroadcastDeleteConversationItem.bind(this),
    });
    this._gateways.merge.addObserver(this);

    _.bindAll(this, ['resetHandler', 'reconnectHandler']);

    this.subscribedCustomerIds = new Set();
  }

  init(opts) {
    _.forIn(this._gateways, gateway => gateway.init(opts));
  }

  unsubscribe(customerId) {
    this.subscribedCustomerIds.delete(customerId);
    this._gateways.conversationsHttp.unsubscribeAll({ customerId });
    this._gateways.conversationItemsHttp.unsubscribeAll({ customerId });
    this._gateways.merge.unsubscribe(customerId);
  }

  reconnectHandler() {}

  resetHandler() {
    this.subscribedCustomerIds.clear();
    this._gateways.conversationsHttp.resetHandler();
    this._gateways.conversationItemsHttp.resetHandler();
  }

  /* Conversations */
  requestConversations(customerId) {
    this.subscribedCustomerIds.add(customerId);
    return this._gateways.conversationsHttp.requestAll({ customerId });
  }

  addConversation(customerId, conversationDto) {
    return this._gateways.conversationsHttp
      .add({ customerId }, conversationDto)
      .then(() => this.handleAddConversationSuccess({ customerId }))
      .catch(errorDtos => this.handleAddConversationErrors({ customerId, errorDtos }));
  }

  handleAddConversationSuccess({ customerId }) {
    this.notifyObservers('handleAddConversationSuccess', { customerId });
  }

  handleAddConversationErrors({ customerId, errorDtos }) {
    this.notifyObservers('handleAddConversationErrors', { customerId, errorDtos });
  }

  updateConversation(customerId, conversationId, conversationAttrs) {
    return this._gateways.conversationsHttp
      .update({ customerId, conversationId }, conversationAttrs)
      .then(() => this.handleUpdateConversationSuccess({ customerId, conversationId }))
      .catch(error => this.handleUpdateConversationError({ customerId, conversationId, correlationId: null, error }));
  }

  updateConversationTopicIds(customerId, conversationId, topicChangesByAgent) {
    return this._gateways.conversationTopicIdsHttp
      .update({ customerId, conversationId }, topicChangesByAgent)
      .then(() => {
        this.handleUpdateConversationTopicIdsSuccess({ customerId, conversationId });
      })
      .catch(error => {
        this.handleUpdateConversationTopicIdsError({ customerId, conversationId, error });
      });
  }

  handleReceiveConversations(conversationsDto, { customerId }) {
    conversationsDto = conversationsDto || [];
    this.notifyObservers('handleReceiveConversations', { customerId, conversationsDto });
  }

  handleReceiveConversationsError(error, { customerId }) {
    this.notifyObservers('handleFetchConversationsError', { customerId, error });
  }

  handleUpdateConversation({ customerId, conversationDto }) {
    this.notifyObservers('handleUpdateConversation', { customerId, conversationDto });
  }

  handleUpdateConversationSuccess({ customerId, conversationId }) {
    this.notifyObservers('handleUpdateConversationSuccess', { customerId, conversationId });
  }

  handleUpdateConversationError({ customerId, conversationId, correlationId, error }) {
    this.notifyObservers('handleUpdateConversationError', { customerId, conversationId, correlationId, error });
  }

  handleDeleteConversation({ customerId, conversationId }) {
    this.notifyObservers('handleDeleteConversation', { customerId, conversationId });
  }

  handleUpdateConversationTopicIdsSuccess({ customerId, conversationId }) {
    this.notifyObservers('handleUpdateConversationTopicIdsSuccess', { customerId, conversationId });
  }

  handleUpdateConversationTopicIdsError({ customerId, conversationId, error }) {
    this.notifyObservers('handleUpdateConversationTopicIdsError', { customerId, conversationId, error });
  }

  handleReconnect() {}

  handleBroadcastConversation(conversationDto, { customerId }) {
    this.handleUpdateConversation({ customerId, conversationDto });
  }

  handleBroadcastDeleteConversation(topic, { customerId, conversationId }) {
    this.handleDeleteConversation({ customerId, conversationId });
  }

  /* Conversation Items */

  fetchConversationItems(customerId, payload) {
    const gateway = this._gateways.conversationItemsHttp;
    let url = gateway.endpoint.set({ customerId }).fetchAllUrl;

    return gateway.http
      .get(requestUrl(url, { query: payload }))
      .then(conversationItemsDto => this.handleReceiveConversationItems({ customerId, conversationItemsDto }))
      .catch(error => this.notifyObservers('handleFetchConversationItemsError', { customerId, error }));
  }

  updateConversationItem(customerId, conversationItemId, updatedAttrs) {
    return this._gateways.conversationItemsHttp
      .update({ customerId, conversationItemId }, updatedAttrs)
      .then(conversationItemDto => this.handleUpdateConversationItem({ customerId, conversationItemDto }))
      .catch(errorDto =>
        qconsole.log(
          `Failed to update conversation item [${conversationItemId}] for customer ${customerId}: ${JSON.stringify(
            errorDto
          )}`
        )
      );
  }

  deleteConversationItem(customerId, conversationItemId) {
    return this._gateways.conversationItemsHttp
      .delete({ customerId, conversationItemId })
      .then(() => this.handleDeleteConversationItem({ customerId, conversationItemId }))
      .catch(errorDto =>
        qconsole.log(
          `Failed to delete conversation item [${conversationItemId}] for customer ${customerId}: ${JSON.stringify(
            errorDto
          )}`
        )
      );
  }

  handleDeleteConversationItem({ customerId, conversationItemId }) {
    this.notifyObservers('handleDeleteConversationItem', { customerId, conversationItemId });
  }

  handleUpdateConversationItem({ customerId, conversationItemDto }) {
    this.notifyObservers('handleUpdateConversationItem', {
      customerId,
      conversationItemDto,
    });
  }

  handleReceiveConversationItems({ customerId, conversationItemsDto }) {
    conversationItemsDto = conversationItemsDto || [];
    this.notifyObservers('handleReceiveConversationItems', { customerId, conversationItemsDto });
  }

  subscribeToConversationItems(customerId) {
    this._gateways.conversationItemsHttp.subscribeAll({ customerId });
  }

  handleBroadcastConversationItem(conversationItemDto) {
    let customerId = conversationItemDto.customerId;
    this.handleUpdateConversationItem({
      customerId,
      conversationItemDto,
    });
  }

  handleBroadcastDeleteConversationItem(conversationItemDto, { customerId, conversationItemId }) {
    this.handleDeleteConversationItem({ customerId, conversationItemId });
  }

  /* Customer Items */

  addItem(customerId, conversationItemDto) {
    return this._gateways.conversationItemsHttp
      .add({ customerId }, conversationItemDto)
      .then(() => this.handleAddItemSuccess({ customerId }))
      .catch(errorDtos => {
        this.handleAddItemErrors({ customerId, errorDtos });
      });
  }

  handleAddItemSuccess({ customerId }) {
    this.notifyObservers('handleAddItemSuccess', { customerId });
  }

  handleAddItemErrors({ customerId, errorDtos }) {
    this.notifyObservers('handleAddItemErrors', { customerId, errorDtos });
  }

  /* Current Conversation Items */

  fetchCurrentConversationItems(customerId, conversationId) {
    return this._gateways.currentConversationItemsHttp
      .fetch({ customerId, conversationId })
      .then(currentItemsDto =>
        this.handleReceiveCurrentConversationItems({ customerId, conversationId, currentItemsDto })
      )
      .catch(error =>
        this.notifyObservers('handleFetchCurrentConversationItemsError', {
          customerId,
          conversationId,
          error,
        })
      );
  }

  handleReceiveCurrentConversationItems({ customerId, conversationId, currentItemsDto }) {
    this.notifyObservers('handleReceiveCurrentConversationItems', {
      customerId,
      conversationId,
      currentItemsDto,
    });
  }

  /* Merge */

  subscribeToMerge(customerId) {
    this._gateways.merge.subscribe(customerId);
  }

  handleCustomerMerge(mergeDto) {
    this.notifyObservers('handleCustomerMerge', mergeDto);
  }

  /* Attachments */

  updateAttachment(customerId, itemId, attachmentId, payload) {
    this._gateways.attachments.update(customerId, itemId, attachmentId, payload);
  }
}

_.extend(CustomerHistoryGateway.prototype, Observable);

function requestUrl(url, params) {
  let query = qs.stringify(params.query);
  return `${url}?${query}`;
}

export class ConversationsHttpGateway extends NoopOnReconnectGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/customer-history/:customerId/conversations/:conversationId');
  }
}

export class ConversationItemsHttpGateway extends NoopOnReconnectGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/customer-history/:customerId/conversation-items/:conversationItemId');
  }
}
