import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import noop from 'lodash/noop';

import Tooltip from 'components/common/tooltip';
import HiddenButton from './buttons/hidden_button';
import VisibleButton from './buttons/visible_button';

export default function VisibilityControl({ isHidden, onHide, onShow }) {
  const [requestedHiddenValue, setRequestedHiddenValue] = useState(isHidden);
  const onToggle = useCallback(() => {
    setRequestedHiddenValue(!requestedHiddenValue);
    if (requestedHiddenValue) {
      onShow();
    } else {
      onHide();
    }
  }, [requestedHiddenValue]);

  return (
    <Tooltip
      className={'phoneControls-visibility-tooltip'}
      enabled
      key={'phoneControls-visibility-tooltip'}
      message={getVisibilityTooltip({ isHidden })}
      position="bottom"
    >
      <li className="phoneControls-button phoneControls-visibilityContainer">
        {renderVisibilityButton({ isHidden, requestedHiddenValue, onToggle })}
      </li>
    </Tooltip>
  );
}

function renderVisibilityButton({ isHidden, requestedHiddenValue, onToggle }) {
  const isPending = isHidden !== requestedHiddenValue;
  let classes = classnames('unstyled', 'action', 'phoneControls-visibility', {
    'circleIcon-deactivated': !isPending && isHidden,
    'circleIcon-activated': !isPending && !isHidden,
    'circleIcon-pendingAnimation': isPending,
  });

  if (isHidden) {
    return <HiddenButton className={classes} onClick={isHidden !== requestedHiddenValue ? noop : onToggle} />;
  }

  return <VisibleButton className={classes} onClick={isHidden !== requestedHiddenValue ? noop : onToggle} />;
}

function getVisibilityTooltip({ isHidden }) {
  if (isHidden) {
    return 'Make yourself visible to the agent';
  }
  return 'Make yourself hidden to the agent';
}
