import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import { getLatestConversationAttributes } from 'actions/conversation/lib/conversation_helpers';

/**
 * `CustomerContext` stores / caches values that are commonly used throughout the customer page.
 *
 * Many subcomponents rely on the current `customerId`, and thus wrap themselves in a container
 * that reads from the `currentLocation` store. That means any time the `currentLocation` changes
 * (say, to update the `anchorId`), _all_ of these subcomponents re-render even though the thing
 * they care about (the `customerId`) hasn't changed.
 *
 * By caching these values in a top level provider, we can ensure these components only rerender
 * when the values they care about actually change. We also reduce the need to wrap components
 * in containers as an added bonus.
 */

export const DEFAULT_CUSTOMER_CONTEXT = {
  compositionId: undefined,
  customerId: undefined,
  latestConversationId: undefined,
};
const CustomerContext = React.createContext(DEFAULT_CUSTOMER_CONTEXT);
export default CustomerContext;

export class CustomerContextProviderBase extends React.Component {
  constructor(props) {
    super(props);
    const {
      compositionId,
      customerId,
      lastRoutedAt,
      latestConversationId,
      latestConversationStatus,
      latestConversationUpdatedAt,
    } = props;
    this.state = {
      compositionId,
      customerId,
      lastRoutedAt,
      latestConversationId,
      latestConversationStatus,
      latestConversationUpdatedAt,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.compositionId !== state.compositionId ||
      props.customerId !== state.customerId ||
      props.lastRoutedAt !== state.lastRoutedAt ||
      props.latestConversationId !== state.latestConversationId ||
      props.latestConversationStatus !== state.latestConversationStatus ||
      props.latestConversationUpdatedAt !== state.latestConversationUpdatedAt
    ) {
      return {
        compositionId: props.compositionId,
        customerId: props.customerId,
        lastRoutedAt: props.lastRoutedAt,
        latestConversationId: props.latestConversationId,
        latestConversationStatus: props.latestConversationStatus,
        latestConversationUpdatedAt: props.latestConversationUpdatedAt,
      };
    }

    return null;
  }

  render() {
    return <CustomerContext.Provider value={this.state}>{this.props.children}</CustomerContext.Provider>;
  }
}

CustomerContextProviderBase.propTypes = {
  children: PropTypes.node,
  compositionId: PropTypes.string,
  customerId: PropTypes.string,
  latestConversationId: PropTypes.string,
  latestConversationStatus: PropTypes.oneOf(_.keys(Conversation.Status)),
  latestConversationUpdatedAt: PropTypes.string,
};

export const CustomerContextProvider = connect(mapStateToProps)(CustomerContextProviderBase);

function mapStateToProps({ getProvider }) {
  const conversationsProvider = getProvider('conversations');
  const currentLocation = getProvider('currentLocation').get();

  const conversation = getLatestConversationAttributes(conversationsProvider, ['id', 'status', 'updatedAt']);

  return {
    compositionId: currentLocation.currentCompositionId,
    customerId: currentLocation.customerId,
    lastRoutedAt: currentLocation.lastRoutedAt,
    latestConversationId: conversation && conversation.id,
    latestConversationStatus: conversation && conversation.status,
    latestConversationUpdatedAt: conversation && conversation.updatedAt,
  };
}
