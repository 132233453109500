import AddObserverToCall from 'models/modal/add_observer_to_call';

export default class ShowAddObserverToCallModal {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    this.context.stores.modal.set(AddObserverToCall.create(attrs));
  }
}
