import React from 'react';
import Icon from 'components/lib/icons/icon';
import styled from 'styled-components';

export default props => (
  <Icon viewBox="-1 0 42 40" {...props}>
    <Circle d="M0 20a20 20 0 1140 0 20 20 0 01-40 0z" fill="white" fillOpacity=".7" />
    <Arrow
      clipRule="evenodd"
      d="M15 10.52c0-.41.43-.66.75-.43l13.04 9.48c.28.2.28.66 0 .86l-13.04 9.48c-.32.23-.75-.02-.75-.43V10.52z"
      fill="#009B00"
      fillRule="evenodd"
    />
    <Border
      d="M20 39A19 19 0 011 20h-2a21 21 0 0021 21v-2zm19-19a19 19 0 01-19 19v2a21 21 0 0021-21h-2zM20 1a19 19 0 0119 19h2A21 21 0 0020-1v2zm0-2A21 21 0 00-1 20h2A19 19 0 0120 1v-2z"
      fill="#E3E3E3"
    />
  </Icon>
);

const Arrow = styled.path`
  fill: ${p => p.theme.colors.green400};
`;

const Border = styled.path`
  fill: ${p => p.theme.colors.white};
`;

const Circle = styled.path`
  fill: ${p => p.theme.colors.white};
`;
