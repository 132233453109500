import _ from 'lodash';

import getComposition from 'actions/customer/lib/get_composition';
import RemoveCompositionError from 'actions/composer/remove_composition_error';
import updateComposition from 'actions/composition/lib/update_composition';

export default class UpdateComposition {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, attrs, customerId }) {
    if (!compositionId) return;
    const composition = getComposition(this.context, customerId, compositionId);
    if (!composition) return;

    const oldContent = composition.content;
    const Constructor = oldContent.constructor;

    let isEqual = true;
    const attrsKeys = _.keys(attrs);
    for (let i = 0; i < attrsKeys.length; i++) {
      const attr = attrsKeys[i];
      if (oldContent[attr] !== attrs[attr]) {
        isEqual = false;
        break;
      }
    }

    if (!isEqual) {
      const newContent = new Constructor({
        ...oldContent,
        ...attrs,
      });

      _.forIn(newContent, (value, key) => {
        if (value !== oldContent[key]) {
          this.context.executeAction(RemoveCompositionError, { attr: key, compositionId, customerId });
        }
      });

      updateComposition(this.context, composition, newContent);
    }
  }
}
