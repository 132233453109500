import setAgentPreferences from 'actions/agent_preferences/set_agent_preferences';

export default class ToggleExperimentalEditor {
  constructor(context) {
    this.context = context;
  }

  run() {
    const preferences = this.context.stores.agentPreferences.get();
    preferences.toggleExperimentalEditor();

    const params = {
      activeExperimentalEditor: preferences.experimentalEditorEnabled,
    };
    this.context.analytics.track('Experimental Text Editor Toggled', params);
    this.context.analytics.updateUser(params);
    setAgentPreferences(this.context, preferences);
  }
}
