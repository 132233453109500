import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import ActionBar from 'components/text_editor_new/components/action_bar/action_bar';
import AITextRewriteControls from 'components/text_editor_new/components/controls/ai_text_rewrite_controls';
import { AnswerPanelControl, Separator } from 'components/composer/shared/editor_controls_new';
import { Attachment, Emoji } from 'components/composer/shared/editor_action_buttons_new';
import CharacterCount from 'components/text_editor_new/character_count';
import Composer, { Header, NewHeaderText } from './shared/composer_new';
import ComposerContext from 'components/composer/contexts/composer_context';
import ComposerEditor from './shared/composer_editor';
import { EditorContainer } from './shared/editor_new';
import EditorErrors from 'components/composer/shared/editor_errors';
import SendFbMessage from 'actions/composer/send_fb_message';
import { serializePlaintext } from 'components/text_editor_new/lib/serialize_plaintext';
import useAIComposerOptions from 'components/text_editor_new/plugins/ai/ai_text_completion/use_ai_composer_options';
import useComposer from './shared/use_composer';
import { useCreatePasteAttachmentsPlugin } from 'components/text_editor_new/plugins/create_paste_attachments_plugin';
import useEditor from './shared/use_editor';
import useMessagingEditorPlugins from 'components/text_editor_new/hooks/use_messaging_editor_plugins';
import withAITextRewrting from 'components/composer/with_ai_text_rewriting';

export function FbMessengerComposer({ requestorId }) {
  const { composition } = useContext(ComposerContext);
  const composerProps = useComposer({ action: SendFbMessage });
  const editorProps = useEditor('messageHtml', composerProps, { attr: 'body', serializer: serializePlaintext });

  const { disableSubmit, readOnly } = useAIComposerOptions();
  editorProps.onSubmit = disableSubmit ? _.noop : editorProps.onSubmit;
  const extraPlugins = useExtraPlugins();
  const editorRef = useMessagingEditorPlugins(composerProps, editorProps, extraPlugins);

  return (
    <Composer
      defaultEditor={editorRef.current}
      defaultEditorId={editorProps.editorId}
      defaultOnChange={editorProps.onChange}
      disableSubmit={disableSubmit}
      excludeErrors
      includeAttachments
      initialHtml={editorProps.initialHtml}
      submitText="Send"
      {...composerProps}
    >
      <Header>
        <Left>
          <NewHeaderText>Messenger</NewHeaderText>
        </Left>
      </Header>

      <EditorContainer excludeAnswerMenuButton includeAttachments>
        <ComposerEditor
          key={composition.id}
          placeholder="Type to Respond"
          readOnly={readOnly}
          requestorId={requestorId}
          {...editorProps}
          onSubmit={editorProps.onSubmit}
        />
      </EditorContainer>

      <MessagingActions>
        <ActionBar>
          <>
            <AnswerPanelControl />
            <Separator />
            <AITextRewriteControls />
          </>
          <Emoji {...editorProps} />
          <Attachment />
        </ActionBar>
        <StyledCharacterCount composerRef={composerProps.composerRef} />
      </MessagingActions>

      <EditorErrors />
    </Composer>
  );
}

const StyledCharacterCount = styled(CharacterCount)`
  background-color: inherit;
  position: relative;
`;

const MessagingActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export default withAITextRewrting(FbMessengerComposer);

function useExtraPlugins() {
  const createPasteAttachmentsPlugin = useCreatePasteAttachmentsPlugin();
  return useMemo(() => [createPasteAttachmentsPlugin()], [createPasteAttachmentsPlugin]);
}
