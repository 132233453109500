import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import VirtualizedSelect from 'react-virtualized-select';

import AgentPhonePreferences from 'models/agent_phone_preferences';
import Button, { ButtonTypes } from 'components/common/button';
import LoadingSpinner from 'components/lib/loading_spinner';

class StationPreferenceMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { station: null, isOpen: false };

    _.bindAll(this, [
      'convertDirectDialStationToPhone',
      'getAlphabetizedAndFilteredStationList',
      'onChange',
      'onClaim',
      'onClose',
      'onOpen',
      'onStationAssigned',
      'onSubmit',
      'renderClaim',
      'renderDropDown',
      'renderSpinner',
      'renderSubmit',
      'renderValue',
    ]);
  }

  componentDidUpdate(prevProps) {
    // Check if attempt to assign station was successfull
    if (prevProps.isPending && !this.props.isPending && !this.props.error && this.state.station) {
      this.onStationAssigned();
    }
  }

  // Handlers

  onStationAssigned() {
    this.props.onSubmit();
    this.props.handleHoverEnd();
  }

  onChange(station) {
    this.props.onClearProfileErrors();
    this.setState({ station });
  }

  onOpen() {
    this.setState({ isOpen: true });
  }

  onClose() {
    this.setState({ isOpen: false });
  }

  onSubmit() {
    if (this.props.isPending) {
      return;
    }
    let station = this.state.station;
    if (station && station.type === AgentPhonePreferences.DIRECT_DIAL) {
      const { phoneNumber, extension } = this.convertDirectDialStationToPhone(station);

      const newAgent = _.merge({}, this.props.currentAgent, { phoneNumber, extension });
      this.props.onProfileUpdate({ agent: newAgent });
    }
  }

  onClaim() {
    if (this.props.isPending) {
      return;
    }

    let station = this.state.station;
    if (station.type === AgentPhonePreferences.DIRECT_DIAL) {
      const { phoneNumber, extension } = this.convertDirectDialStationToPhone(station);

      const newAgent = _.merge({}, this.props.currentAgent, { phoneNumber, extension });
      this.props.onProfileUpdate({ agent: newAgent, override: true });
    }

    this.onStationAssigned();
  }

  convertDirectDialStationToPhone(station) {
    const stationValue = station.value.split(';ext=');
    const phoneNumber = stationValue[0];
    const extension = stationValue[1] || '';
    return { phoneNumber, extension };
  }

  // Renders

  render() {
    const form = (
      <React.Fragment>
        {this.renderDropDown()}
        {this.props.error ? this.renderClaim() : this.renderSubmit()}
      </React.Fragment>
    );

    return (
      <div className="stationPreferenceMenu">
        <div className="stationPreferenceMenu-title">Select Your Station</div>
        <div className="stationPreferenceMenu-body-text">
          A Station allows Gladly to route phone <br /> calls to you
        </div>
        <div className="stationPreferenceMenu-body-text">
          You will be prompted to select one
          <br />
          each time you log into Gladly
        </div>
        {this.props.isPending ? this.renderSpinner() : form}
      </div>
    );
  }

  renderValue(option) {
    const isOpen = this.state.isOpen;
    const classNames = classnames('stationPreferenceMenu-select-input', {
      'stationPreferenceMenu-select-input-placeholder': isOpen,
    });
    return <div className={classNames}>{isOpen ? 'Type to search' : option.label}</div>;
  }

  renderSpinner() {
    return <LoadingSpinner containerClassName="stationPreferenceMenu-spinner" size="35px" />;
  }

  renderDropDown() {
    return (
      <VirtualizedSelect
        className="stationPreferenceMenu-select"
        clearable={false}
        multi={false}
        noResultsText="No results"
        onChange={this.onChange}
        onClose={this.onClose}
        onOpen={this.onOpen}
        options={this.getAlphabetizedAndFilteredStationList()}
        placeholder="Choose a station"
        searchable
        value={this.state.station}
        valueRenderer={this.renderValue}
      />
    );
  }

  renderSubmit() {
    if (!this.state.station) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="stationPreferenceMenu-submit-button-container">
          <Button
            buttonType={ButtonTypes.PRIMARY}
            className="stationPreferenceMenu-submit-button"
            disabled={this.props.isPending}
            onClick={this.onSubmit}
          >
            Submit
          </Button>
        </div>
      </React.Fragment>
    );
  }

  renderClaim() {
    if (!this.state.station) {
      return null;
    }

    return (
      <div className="stationPreferenceMenu-claim-container">
        <div className="stationPreferenceMenu-claim-description">
          This station is in use by another agent. If you are at the correct station, click <strong>Claim</strong>,
          otherwise choose another.
        </div>
        <Button buttonType={ButtonTypes.PRIMARY} className="stationPreferenceMenu-claim-button" onClick={this.onClaim}>
          Claim
        </Button>
      </div>
    );
  }

  getAlphabetizedAndFilteredStationList() {
    let filteredStations = _.filter(
      this.props.stations,
      station => station.type === AgentPhonePreferences.DIRECT_DIAL && this.props.isDirectDialEnabled
    );
    return _.sortBy(filteredStations, station => station.label);
  }
}

StationPreferenceMenu.propTypes = {
  currentAgent: PropTypes.object,
  error: PropTypes.string,
  handleHoverEnd: PropTypes.func,
  isDirectDialEnabled: PropTypes.bool,
  isPending: PropTypes.bool,
  onClearProfileErrors: PropTypes.func,
  onProfileUpdate: PropTypes.func,
  onSubmit: PropTypes.func,
  stations: PropTypes.array,
};

export default StationPreferenceMenu;
