import _ from 'lodash';

import Communicator from 'models/communicator';
import Err from 'models/err';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import SwitchCurrentConversation from 'actions/customer/switch_current_conversation';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class UpdateConversationCustomAttributes {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId, customerId, customAttributeUpdates }) {
    let agentId = this.currentAgent.id;

    const updatedAttributeIds = new Set(
      _.union(customAttributeUpdates.add, customAttributeUpdates.remove).map(attr => attr.id)
    );
    const findQuery = { filter: attr => updatedAttributeIds.has(attr.id), select: ['id', 'key'] };
    const existingAttributes = this.context.stores.customAttributes.findAll(findQuery);
    let attributesKeysByID = {};
    for (const attr of existingAttributes) {
      attributesKeysByID[attr.id] = attr.key;
    }

    const attributesToAdd =
      customAttributeUpdates.add &&
      customAttributeUpdates.add.map(attr => {
        return {
          key: attributesKeysByID[attr.id],
          value: attr.value,
        };
      });

    const attributesToRemove =
      customAttributeUpdates.remove &&
      customAttributeUpdates.remove.map(attr => {
        return {
          key: attributesKeysByID[attr.id],
          value: attr.value,
        };
      });

    let attributeUpdates = {};
    if (attributesToAdd) {
      attributeUpdates.add = attributesToAdd;
    }
    if (attributesToRemove) {
      attributeUpdates.remove = attributesToRemove;
    }

    this.context.gateways.conversationCustomAttributes
      .add(
        { customerId, conversationId },
        {
          ...attributeUpdates,
          initiator: {
            type: Communicator.AGENT,
            id: agentId,
          },
        }
      )
      .then(() => {
        let conversations = this.context.stores.customers.storesFor(customerId).conversations;
        let conversation = conversations.findBy({ id: conversationId });

        const equalAttrs = (a, b) => a.id === b.id && a.value === b.value;

        let customAttributes = _.unionWith(conversation.customAttributes, customAttributeUpdates.add, equalAttrs);
        customAttributes = _.differenceWith(customAttributes, customAttributeUpdates.remove, equalAttrs);
        let updates = { customAttributes };
        conversation.update(updates);
        conversations.replace(conversation);

        if (this.context.stores.currentLocation.get().customerId === customerId) {
          // show applied custom attributes in the feed, i.e. scroll to bottom
          this.context.executeAction(SwitchCurrentConversation, { conversationId: conversation.id });
        }

        this.context.analytics.track('Conversation Custom Attributes Updated', {
          customerId,
          conversationId: conversation.id,
          customAttributesAdded: customAttributeUpdates.add,
          customAttributesRemoved: customAttributeUpdates.remove,
        });
      })
      .catch(err => {
        if (err.errors) {
          const error = err.errors[0];
          if (error.code === Err.Code.TOO_LONG) {
            this.showToast(
              ToastType.ERROR,
              'The topic value you entered exceeds the 255 character limit. Please remove it and try again.'
            );
          } else if (Err.Code.INVALID && error.detail === 'key is archived') {
            this.showToast(
              ToastType.ERROR,
              'The topic you selected is now archived. Please remove it and try again or activate the topic.'
            );
          }
        } else {
          new GatewayErrorInteractiveHandler(this.context).handleCommonErrors(err);
        }
      });
  }

  showToast(type, message) {
    this.context.executeAction(ShowToast, { type, message });
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
