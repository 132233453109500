export default function ToggleHide(onToggleHide) {
  return {
    onKeyDown(evt, editor, next) {
      if (!onToggleHide) {
        return next();
      }

      const altHeld = evt.altKey;
      if ((evt.key === '˙' || evt.key === 'h') && altHeld) {
        onToggleHide(evt);
        evt.preventDefault();
        evt.stopPropagation();
        return true;
      }

      return next();
    },
  };
}
