import React, { useEffect } from 'react';
import styled from 'styled-components';

export const PLACEHOLDER = 'placeholder';

export const StyledPlaceholder = styled.span`
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  color: #a5a5a5;
  padding: 2px 4px;
  span::selection {
    background-color: #b2d7fe;
    color: #5a85b3;
  }
`;

export default function PlaceholdersPlugin(options) {
  return {
    onChange(editor, next) {
      return editor.ensureFullSelection(next, PLACEHOLDER);
    },

    onKeyDown(evt, editor, next) {
      const { value } = editor;
      const { document, selection } = value;

      const currentPlaceholder = editor.value.inlines.find(i => i.type === PLACEHOLDER);
      if (evt.key !== 'Tab') {
        return next();
      }

      // If we have a placeholder selected, we need to ensure we find the _next_ one, so move the start selection
      // up past it.
      if (currentPlaceholder) {
        const nextNode = editor.value.document.getNextNode(currentPlaceholder.key);
        // This means the placeholder is actually the last node - in which case Tab should proceed as normal.
        if (!nextNode) {
          return next();
        }
        // I use Anchor instead of Start because I don't think Slate likes it when I move the start past the end..
        editor.moveAnchorToStartOfNode(nextNode);
      }

      // Select the rest of the document so that we can find the next placeholder within it.
      // For some reason editor.moveEndToEndOfDocument() doesn't work..
      editor.moveFocusToEndOfNode(document.getTexts().last());
      const nextPlaceholder = document.getLeafInlinesAtRange(editor.value.selection).find(i => i.type === PLACEHOLDER);
      if (!nextPlaceholder) {
        editor.select(selection); // Move the selection back into place
        return next();
      }

      evt.preventDefault();
      editor.moveEndToEndOfNode(nextPlaceholder);
      editor.moveStartToStartOfNode(nextPlaceholder);
      return true;
    },

    renderNode(props, editor, next) {
      if (props.node.type === PLACEHOLDER) {
        const { attributes, children } = props;
        return <StyledPlaceholder {...attributes}>{children}</StyledPlaceholder>;
      }
      return next();
    },

    schema: {
      inlines: {
        [PLACEHOLDER]: {
          nodes: [
            {
              match: { object: 'text' },
              min: 1,
              max: 1,
            },
          ],
          text: /.+/, // Can't have empty placeholders
        },
      },
    },
  };
}

export function findFirstPlaceholderNode(editor) {
  const placeholders = editor.value.document.getInlinesByType(PLACEHOLDER);
  if (!placeholders.count()) {
    return null;
  }

  return placeholders.get(0);
}

export function useFocusFirstPlaceholder(editor, onChange) {
  useEffect(() => {
    if (editor) {
      const firstPlaceholderNode = findFirstPlaceholderNode(editor);
      if (!firstPlaceholderNode) {
        return;
      }
      editor.moveStartToStartOfNode(firstPlaceholderNode);
      editor.moveEndToEndOfNode(firstPlaceholderNode);
      onChange(editor);
    }
  }, [editor, onChange]);
}
