import PropTypes from 'prop-types';
import React from 'react';

import { CHANNEL_ACTION_TEXT_WITH_SUBJECT } from 'components/lib/channel_action_text';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import { iconClassNameForItem } from './metadata_icon_classes';
import ItemPrepositionalPhrases from './item_prepositional_phrases';
import Metadata from './metadata';
import { typeReflect } from 'models/conversation_item/content_from_js';

export default function ThirdPartyMetadata({ hasError, icon, iconClass, item, subject, text, tooltip }) {
  const itemType = typeReflect.instanceToType(item.content);
  iconClass = iconClass || iconClassNameForItem(item);
  text = text || CHANNEL_ACTION_TEXT_WITH_SUBJECT[itemType];
  subject = subject || item.from();

  return (
    <Metadata
      hasError={hasError}
      icon={icon}
      iconClass={iconClass}
      isInbound={item.initiator.type !== Communicator.INTERNAL}
      prepositionalPhrases={<ItemPrepositionalPhrases item={item} />}
      subject={subject}
      text={text}
      timestamp={item.timestamp}
      tooltip={tooltip}
    />
  );
}

ThirdPartyMetadata.propTypes = {
  hasError: PropTypes.bool,
  icon: PropTypes.node,
  iconClass: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  subject: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
};
