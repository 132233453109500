import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('ItemId').extend('Uuid');

Factory.define('ItemIdWithDefaults')
  .extend('ItemId')
  .attr('id', 'item-1')
  .attr('conversationId', 'conversation-1')
  .attr('timestamp', () => new Date().toISOString());
