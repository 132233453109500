import _ from 'lodash';

/**
 * RunnerContext has the following responsibilities:
 *
 * Provides an injection point in Use Case Runners / Actions for service and gateways
 * Implements `executeAction` API
 * @property {object} analytics - Deprecated analytics service
 */
export default class RunnerContext {
  constructor(contextAttrs) {
    _.assign(this, contextAttrs);
  }

  executeAction(RunnerClass, params) {
    let runner = new RunnerClass(this);
    return runner.run(params);
  }

  reset() {
    this.backends.reset();
    this.analytics.disableDefault();
    this._moreartyCtx.resetState();
    this.stores.auth.remove();
  }
}
