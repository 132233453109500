import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 8 8" {...props} className="placeholderIcon">
    <path
      clipRule="evenodd"
      d="M7.001 8h-1V7h.75a.25.25 0 0 0 .25-.25v-.752H8V7a1 1 0 0 1-.999 1zm0-5H8v1.999h-.999V3zm0-1.75a.25.25 0 0 0-.25-.25h-.75V0h1A1 1 0 0 1 8 1v1h-.999v-.75zM2.999 1V0h2v1h-2zM1.25 1a.249.249 0 0 0-.25.25V2H0V1a1 1 0 0 1 1-1h1v1h-.75zM1 4.999H0V3h1v1.999zM1 6.75c0 .137.111.25.25.25H2v1H1c-.552 0-1-.449-1-1V5.998h1v.752zM4.999 7v1h-2V7h2z"
      fillRule="evenodd"
    />
  </IconContainer>
);
