import moment from 'moment';
import _ from 'lodash';

/**
 * Simple helper function that uses lodash template function to evaluate mustache-style templates
 * like "Hello {{ data.name }}!".
 *
 * The template can use plain JavaScript expressions and lodash functions (lodash is exposed as '_'). If
 * the `templateData` is an object or array, it will be passed to the template as a pseudo-variable with
 * the name specified in `templateDataVariableName` (default to 'data'). If the value is a primitive, it
 * will be passed in as a pseudo-variable 'value' for backward compatibility.
 *
 * Examples:
 *    "{{ data.length }} Order{{ (data.length !== 1 ? 's' : '') }}" + [{}, {}]  =>  "2 Orders"
 *    "The value is {{ value }}" + "25"                                         =>  "The value is 25"
 *
 * NOTE: lodash template expect the content of {{}} to be a value. Remember to enclose expressions (including
 * inline `if` etc.) in brackets: {{ ( <some expression> ) }}
 *
 * @param {string} templateString - the template
 * @param {object|array|*} templateData - the data to be merged into the template
 * @param {string} [templateDataVariableName] - the name of the pseudo-variable that template will use to access
 *                                              the data. Defaults to 'data'.
 */
export function evaluateTemplate(templateString, templateData, templateDataVariableName = 'data') {
  if (!templateString) return '';

  const MUSTACHE_REGEX = /{{([\s\S]+?)}}/g;
  const options = {
    interpolate: MUSTACHE_REGEX,
    escape: MUSTACHE_REGEX,
    imports: { _, moment },
  };

  // _.isObject() also covers arrays and classes
  if (_.isObject(templateData)) {
    options.variable = _.trim(templateDataVariableName) || 'data';
  }

  const compiled = _.template(templateString, options);
  const data = _.isObject(templateData) ? templateData : { value: templateData };
  return compiled(data);
}
