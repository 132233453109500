import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import DismissNotification from 'actions/notification/dismiss_notification';
import Notification from './notification';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function FocusModeDisabledNotification({ id }) {
  const executeAction = useExecuteAction();
  const handleClick = useCallback(() => {
    executeAction(DismissNotification, id);
  }, [executeAction, id]);

  return (
    <Notification contentClasses="warningNotification" notificationId={id} onClick={handleClick}>
      <StyledContent>
        <StyledMessage>
          <StyledWarning data-aid="warning-icon" icon={faTriangleExclamation} />
          <StyledMessageWrapper>
            Your system administrator has turned off the Focus button. If you had Focus on, you may be routed more work
            based on your availability
          </StyledMessageWrapper>
        </StyledMessage>
        <Button buttonType={ButtonTypes.SECONDARY} contrast data-aid="dismiss-button" onClick={handleClick}>
          Got it
        </Button>
      </StyledContent>
    </Notification>
  );
}

FocusModeDisabledNotification.propTypes = {
  id: PropTypes.string.isRequired,
};

export const StyledMessage = styled.div`
  align-items: center;
  color: black;
  display: flex;
  font-weight: 600;
`;

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledMessageWrapper = styled.div`
  margin-right: 10px;
`;

export const StyledWarning = styled(FontAwesomeIcon)`
  color: ${p => p.theme.colors.black};
  font-size: 24px;
  margin-right: 16px;
`;
