import _ from 'lodash';

export default function(context, customerId, sessionId, conversationId) {
  context.gateways.routingQueueItem.subscribe(sessionId);
  context.gateways.customerTypingState.subscribe(customerId);

  const currentAgentId = _.get(context.stores.currentAgent.get(), 'id');
  if (currentAgentId) {
    context.gateways.agentRead.request({ conversationId, agentId: currentAgentId });
  }
}
