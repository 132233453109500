import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import VirtualizedSelect from 'react-virtualized-select';

import ClearAnswerSearchResult from 'actions/customer/clear_answer_search_result';
import connect from 'components/lib/connect';
import Spinner from 'components/common/spinner';
import SearchAnswers from 'actions/knowledge_base/search_answers';
import { SnippetContentType } from 'models/answers/snippet';

const mapStateToProps = (context, props) => {
  const { clearable, label, value, selectedOption } = props;

  const searchResult = context.getProvider('snippetSearchResult').get();
  const searchResults = searchResult ? searchResult.hits : [];

  const isLoading = _.get(searchResult, 'isLoading');
  let options = clearable ? [{ label: `No ${label}`, value: '' }] : [];

  if (selectedOption && selectedOption.id === value) {
    options.push({
      label: selectedOption.name,
      value: selectedOption.id,
    });
  }

  searchResults.forEach(result => {
    if (result.id === value) {
      return;
    }

    let content = result.findContentByLanguage();

    if (content.getBodyByType(SnippetContentType.ANY_CHANNEL) || content.getBodyByType(SnippetContentType.MESSAGE)) {
      options.push({ label: _.get(result, 'name'), value: _.get(result, 'id') });
    }
  });

  if (searchResult && !isLoading && searchResults.length === 0) {
    options.push({ label: 'No results found', value: 'NO_RESULTS_FOUND', disabled: true });
  }

  return {
    ...props,
    options,
    isLoading,
  };
};

const mapExecuteToProps = (executeAction, props) => {
  return {
    onSearch: input => {
      if (input === '') {
        executeAction(ClearAnswerSearchResult);
      } else {
        executeAction(SearchAnswers, { searchText: input });
      }
    },
    onClose: () => executeAction(ClearAnswerSearchResult),
  };
};

export const AnswerSelect = createReactClass({
  propTypes: {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    'data-aid': PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    value: PropTypes.string,
  },

  getInitialState() {
    return {
      isOpen: false,
      savePendingOption: null,
    };
  },

  UNSAFE_componentWillMount() {
    this.searchAnswers = _.debounce(this.props.onSearch, 400);
  },

  renderAnswerValue(option) {
    const isOpen = this.state.isOpen;
    const classNames = classnames('answerSelect-input', {
      'answerSelect-input-placeholder': isOpen,
    });
    return <div className={classNames}>{isOpen ? 'Type to search' : option.label}</div>;
  },

  onChange(option) {
    this.setState({ savePendingOption: option });
    this.props.onChange(option);
  },

  onOpen() {
    this.setState({
      isOpen: true,
      savePendingOption: null,
    });
  },

  onClose() {
    this.setState({ isOpen: false });
    this.props.onClose();
  },

  filterOptions(options, filter, currentValues) {
    // Do not do default ReactSelect filtering
    return options;
  },

  render() {
    const isLoading = this.state.isOpen && this.props.isLoading;
    const options = this.state.savePendingOption
      ? _.concat(this.props.options, this.state.savePendingOption)
      : this.props.options;
    return (
      <div className="answerSelect-container" data-aid={this.props['data-aid']}>
        <VirtualizedSelect
          backspaceToRemoveMessage=""
          className={classnames('answerSelect', this.props.className)}
          clearable={false}
          filterOptions={this.filterOptions}
          multi={false}
          noResultsText="Type to search"
          onChange={this.onChange}
          onClose={this.onClose}
          onInputChange={this.searchAnswers}
          onOpen={this.onOpen}
          openOnFocus
          options={options}
          placeholder={this.props.label}
          searchable
          value={this.props.value}
          valueRenderer={this.renderAnswerValue}
        />
        {isLoading && (
          <div className="answerSelect-spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  },
});

export default connect(mapStateToProps, mapExecuteToProps)(AnswerSelect);
