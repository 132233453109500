import { Factory } from 'rosie';
import '../conversation_item_factory';
import './context_event_factory';

Factory.define('HelpAppContext')
  .attr('type', 'HELP_APP_CONTEXT')
  .attr('events', ['events'], events => {
    return (events || []).map(event => Factory.attributes('ContextEvent', event));
  });

Factory.define('HelpAppContextWithDefaults')
  .extend('HelpAppContext')
  .attr('username', 'Sully')
  .attr('events', ['events'], events => {
    return (events || [{}]).map(event => Factory.attributes('ContextEventWithDefaults', event));
  });

Factory.define('HelpAppContextItem')
  .attr('_demoOnly', true)
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('HelpAppContext', content));

Factory.define('HelpAppContextItemWithDefaults')
  .attr('_demoOnly', true)
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('HelpAppContextWithDefaults', content));
