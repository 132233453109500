import { useContext, useEffect, useRef } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import FullSerializer from '../serializers/full_serializer';
import { insertFragmentWithoutExtraLineToStart } from './fragment_helpers';
import { PLACEHOLDER } from './placeholders';
import { SnippetContentType } from 'models/answers/snippet';
import { updateVariables } from './variables';
import { useVariables } from 'components/composer/contexts/variables_context';

export function insertAnswer(editor, serializer, html, variables, shouldRefocus = true) {
  const answerValue = serializer.deserialize(html);
  const placeholderInline = answerValue.document.getInlines().find(i => i.type === PLACEHOLDER);
  const preSelection = editor.value.selection;

  insertFragmentWithoutExtraLineToStart(editor, answerValue.document);
  updateVariables(editor, variables);

  if (placeholderInline && shouldRefocus) {
    // Now the selection includes the entire fragment, so we can find the first placeholder
    // within the selection.
    editor.moveStartTo(preSelection.start.path, preSelection.start.offset);
    const firstPlaceholder = editor.value.document
      .getLeafInlinesAtRange(editor.value.selection)
      .find(i => i.type === PLACEHOLDER);
    if (firstPlaceholder) {
      editor.moveStartToStartOfNode(firstPlaceholder);
      editor.moveEndToEndOfNode(firstPlaceholder);
    }
  } else {
    editor.moveToEndOfBlock();
  }

  if (shouldRefocus) {
    editor.focus();
  }
}

// This actually doesn't belong in the base text editor, but instead the composer.
// Let's pretend this is ok for now though.
export function useAnswers(editor, onChange) {
  const { answerToInsert, clearInsertedAnswer } = useContext(ComposerContext);
  const variables = useVariables();

  const prevAnswerRef = useRef(null);
  const hasValidSelection = editor && !!editor.value.selection.start.key;

  useEffect(() => {
    if (editor && answerToInsert !== prevAnswerRef.current) {
      // HACK: Slate has a bug where insertFragment (which is used to insert the answer) will break
      // if the editor is not currently focused in a certain way. Programmatically blurring elsewhere
      // in the app, for example, will leave the editor with an empty selection. insertFragment expects
      // a non-empty selection. So first, we focus the editor to generate a valid selection, _then_ we
      // insert the answer.
      if (!hasValidSelection) {
        editor.focus();
        return;
      }

      prevAnswerRef.current = answerToInsert;
      if (answerToInsert) {
        const channelType = answerToInsert.channelType;
        const item = answerToInsert.item;
        let content = item.findContentByLanguage(answerToInsert.language);
        const itemHtml = channelType && content.getBodyByType(SnippetContentType[channelType]);
        if (itemHtml) {
          insertAnswer(editor, FullSerializer, itemHtml, variables);
          onChange(editor);
        }
        clearInsertedAnswer();
      }
    }
  }, [editor, hasValidSelection, onChange, clearInsertedAnswer, variables, answerToInsert]);
}
