import _ from 'lodash';
import classnames from 'classnames';
import Dotdotdot from 'react-dotdotdot';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import path from 'path';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import AttachmentStatus from 'models/attachment/attachment_status';
import AttachmentType, { AttachmentTypeIcon } from './attachment_type';
import RemoveAttachmentButton, { RemoveAttachmentModal, StyledRemoveIcon } from './remove_attachment_button';

const GenericAttachment = ({ attachment, onClick }) => {
  const filename = attachment.fileDescriptor().filename;

  if (attachment.isRedacted) {
    return (
      <Redacted className="attachments-attachment">
        <FontAwesomeIcon icon={faTrashAlt} />
        <Text>This item was deleted.</Text>
      </Redacted>
    );
  }
  return (
    <GenericAttachmentWrapper attachment={attachment} onClick={onClick}>
      <Dotdotdot clamp={3}>
        <h4 className="genericAttachment-label" title={filename}>
          {filenameOrLoadingStatus()}
        </h4>
      </Dotdotdot>
      {fileTypeOrFailedStatus()}
    </GenericAttachmentWrapper>
  );

  function fileTypeOrFailedStatus() {
    let type = attachment.fileDescriptor().contentType;

    if (attachment.status === AttachmentStatus.ERROR) {
      return (
        <span className="genericAttachment-errorMessage">
          <AttachmentTypeIcon title={filename} type={type} />
          <h4 className="genericAttachment-label genericAttachment-label-errorMessage">Failed</h4>
        </span>
      );
    }
    return <AttachmentType extension={filenameExtension()} type={type} />;
  }

  function filenameOrLoadingStatus() {
    return (!attachment.status && 'Loading...') || filenameWithoutExtension(filename);
  }

  function filenameWithoutExtension() {
    return path.basename(filename, path.extname(filename));
  }

  function filenameExtension() {
    let extname = path.extname(attachment.fileDescriptor().filename);
    return extname && extname.substr(1);
  }
};

const Redacted = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.gray300};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
`;
const Text = styled.div`
  font-size: 11px;
  margin-top: 8px;
  text-align: center;
`;

GenericAttachment.propTypes = {
  attachment: PropTypes.shape({ fileDescriptor: PropTypes.func, isRedacted: PropTypes.bool }).isRequired,
  onClick: PropTypes.func,
};

export function GenericAttachmentWrapper({ attachment, children, onClick }) {
  const [isRemoving, setIsRemoving] = useState(false);
  let classNames = classnames('genericAttachment', {
    'genericAttachment-clickable': onClick,
  });

  return (
    <React.Fragment>
      <StyledGenericAttachment className={classNames} onClick={onClick || _.noop}>
        {children}
        <RemoveAttachmentButton attachment={attachment} isRemoving={isRemoving} setIsRemoving={setIsRemoving} />
      </StyledGenericAttachment>
      {isRemoving ? <RemoveAttachmentModal attachment={attachment} setIsRemoving={setIsRemoving} /> : null}
    </React.Fragment>
  );
}

const StyledGenericAttachment = styled.li`
  position: relative;
  &:hover ${StyledRemoveIcon} {
    opacity: 1;
    visibility: visible;
  }
`;

export default GenericAttachment;
