export default class TrackEmailInserted {
  constructor(context) {
    this.context = context;
  }

  run({ source, type }) {
    const currentLocation = this.context.stores.currentLocation.get();
    const compositionId = currentLocation.currentCompositionId;

    this.context.analytics.track('Email Recipient Inserted', { source, type, compositionId });
  }
}
