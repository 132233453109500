import qconsole from 'scripts/lib/qconsole';
import AITextCompletion from 'models/ai_text_completion';

export default class UseComposerConversationSummary {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, conversationId, customerId, summaryDto }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      return qconsole.log(
        `UseComposerConversationSummary: received summary for unloaded customer [${customerId}]. Ignoring.`
      );
    }

    // First, check if the composition is still around. AI call takes a few seconds and the composition
    // may have been closed by now.
    const { aiTextCompletions, compositions } = this.context.stores.customers.storesFor(customerId);
    const composition = compositions.findBy({ id: compositionId });
    if (!composition || composition.conversationId !== conversationId) return;

    // The composition is still alive, proceed with adding the summary
    const newCompletion = AITextCompletion.create({
      id: conversationId,
      type: AITextCompletion.Types.conversation_summary,
      data: summaryDto,
    });
    aiTextCompletions.addOrReplace(newCompletion);
  }
}
