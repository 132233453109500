import PropTypes from 'prop-types';

import AbortUpload from 'actions/knowledge_base/abort_upload';
import CompositionUpload from 'components/customer/composition/lib/attachments/composition_upload';
import connect from 'components/lib/connect';
import RemoveUpload from 'actions/knowledge_base/remove_upload';
import RetryUpload from 'actions/knowledge_base/retry_upload';
import Upload from 'models/upload';

const SnippetUploadContainer = connect(mapStateToProps, mapExecuteToProps)(CompositionUpload);

function mapStateToProps(context, props) {
  return {
    thumbnailUrl: props.thumbnailUrl,
    upload: props.upload,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onAbort: () => executeAction(AbortUpload, { uploadId: props.upload.id }),
    onOpenAttachmentPreview: props.onOpenAttachmentPreview,
    onRemove: () => executeAction(RemoveUpload, { uploadId: props.upload.id }),
    onRetry: () => executeAction(RetryUpload, { uploadId: props.upload.id }),
  };
}

SnippetUploadContainer.propTypes = {
  onOpenAttachmentPreview: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  upload: PropTypes.instanceOf(Upload).isRequired,
};

export default SnippetUploadContainer;
