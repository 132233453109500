import _ from 'lodash';
import faker from 'faker';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import ConversationItemsService from 'scripts/infrastructure/backends/fake_backend/conversation_items_service';
import Factory from 'factories/all';
import ConversationsService from 'scripts/infrastructure/backends/fake_backend/conversations_service';

export default class AIConversationSummaryService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
    this.conversationItemService = ConversationItemsService.create(pubsub, database);
    this.conversationService = ConversationsService.create(pubsub, this.conversationItemService, database);
  }

  getConversationSummary(query, callback, path, { orgId, conversationId }) {
    if (!orgId || !conversationId) {
      return callback(Error(statusText(400)), { status: 400, statusText: statusText(400) });
    }

    const database = this.getDatabase(orgId);
    if (!database) {
      return callback(Error(statusText(404)), { status: 404, statusText: statusText(404) });
    }

    const conversation = _.reduce(
      database.customers,
      (acc, customer) => {
        if (!acc) {
          return _.find(customer.conversations, conv => conv.id === conversationId);
        }
        return acc;
      },
      null
    );

    // Make sure the conversation exists and is in valid state
    if (!conversation) {
      return callback(Error(statusText(404)), {
        status: 404,
        statusText: statusText(404),
        response: {
          errors: [{ code: 'not_exist', detail: 'conversation not found' }],
        },
      });
    }

    if (!['OPEN', 'WAITING'].includes(conversation.status)) {
      return callback(Error(statusText(400)), {
        status: 400,
        statusText: statusText(400),
        response: {
          errors: [{ attr: 'CONVERSATION', code: 'WRONG_STATUS' }],
        },
      });
    }

    // Mimic "too short" but don't go into a very involving detection logic
    const conversationItems = this.conversationItemService.findByConversationId(orgId, conversationId);
    if (conversationItems.length < 3) {
      return callback(Error(statusText(400)), {
        status: 400,
        statusText: statusText(400),
        response: {
          errors: [{ attr: 'CONVERSATION', code: 'TOO_SHORT' }],
        },
      });
    }

    // Return result within 1s to 2s
    const delay = Math.floor(Math.random() * 1000) + 1000;
    const summary = this._createSummary(database, conversationId, conversationItems);
    setTimeout(() => callback(null, { status: 200, statusText: statusText(200), response: summary }), delay);
  }

  createConversationSummaryItem(query, callback, path, { orgId, conversationId }) {
    // This callback will be called once the summary is created and we need to post the item to the timeline
    const summaryCallback = (err, res) => {
      if (err) return callback(err, res);

      // Look up the customer ID. If we are here, the conversationId is valid and points to an existing conversation
      const database = this.getDatabase(orgId);
      const customerWithConversation = _.find(
        database.customers,
        customer => !!_.find(customer.conversations, conversation => conversation.id === conversationId)
      );
      const customerId = customerWithConversation.id;

      const summary = res.response.summary;
      const itemContent = {
        type: 'AI_ACTIVITY',
        activityType: 'CONVERSATION_SUMMARY',
        body: '',
        isRedacted: false,
        meta: {
          summaryType: summary.summaryType,
          caption: summary.caption,
          details: (summary.details || []).join('\n'),
          mentionedIdentifiers: (summary.mentionedIdentifiers || []).join('\n'),
          fromConversation: summary.summaryStart.conversationId,
          fromConversationItem: summary.summaryStart.conversationItemId,
          fromTimestamp: summary.summaryStart.timestamp,
          toConversation: summary.summaryEnd.conversationId,
          toConversationItem: summary.summaryEnd.conversationItemId,
          toTimestamp: summary.summaryEnd.timestamp,
        },
      };

      const conversationItem = Factory.build('ConversationItemWithDefaults', {
        customerId,
        conversationId,
        content: itemContent,
        initiator: { type: 'AGENT', id: database.currentAgent?.id },
      });
      this.conversationService.update(orgId, customerId, conversationId, {
        payload: {
          newConversationItems: [conversationItem],
        },
      });
      return callback(null, { status: 200, statusText: statusText(200) });
    };

    this.getConversationSummary(query, summaryCallback, path, { orgId, conversationId });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/conversations/:conversationId/summary': {
          GET: this.getConversationSummary,
        },
        '/api/v1/orgs/:orgId/conversations/:conversationId/summary/items': {
          POST: this.createConversationSummaryItem,
        },
      },
      this
    );
  }

  // We try to look up a canned summary (based on the `conversationId`) and use the `caption`, `details` and
  // `mentionedIdentifiers` if found. Otherwise, use faker to generate random text
  _createSummary(database, conversationId, conversationItems) {
    const summary = this._findSummaryByConversationId(database, conversationId);

    const summaryCaption = summary?.caption || this._createFakeSentences(1)[0];
    const summaryDetails = summary?.details || this._createFakeSentences(5);
    const mentionedIds = summary?.mentionedIdentifiers || this._createFakeIdentifiers(3);

    const firstItem = conversationItems[0];
    const lastItem = conversationItems[conversationItems.length - 1];

    return {
      summary: {
        caption: summaryCaption,
        details: summaryDetails,
        mentionedIdentifiers: mentionedIds,
        summaryStart: {
          conversationId,
          conversationItemId: _.get(firstItem, 'id'),
          timestamp: _.get(firstItem, 'timestamp'),
        },
        summaryEnd: {
          conversationId,
          conversationItemId: _.get(lastItem, 'id'),
          timestamp: _.get(lastItem, 'timestamp'),
        },
        summaryType: 'COMPLETE',
      },
    };
  }

  _findSummaryByConversationId(database, conversationId) {
    const summaries = _.reduce(
      database.customers,
      (acc, customer) => {
        const customerSummaries = _.get(customer, 'aiConversationSummaries');
        if (!_.isEmpty(customerSummaries)) {
          const item = _.find(customerSummaries, summary => _.get(summary, 'conversationId') === conversationId);
          if (item) acc.push(item);
        }
        return acc;
      },
      []
    );

    return summaries.length ? summaries[0].summary : undefined;
  }

  _createFakeSentences(numberOfSentences) {
    const ret = [];
    for (let i = 0; i < numberOfSentences; i++) {
      ret.push(_.capitalize(faker.lorem.sentence()));
    }

    return ret;
  }

  _createFakeIdentifiers(numberOfIds) {
    const ret = [];
    for (let i = 0; i < numberOfIds; i++) {
      const label = faker.lorem.sentence(2, 0);
      const account = faker.finance.account();
      ret.push(`${_.capitalize(label)}: ${account.toUpperCase()}`);
    }

    return ret;
  }
}
