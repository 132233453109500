import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';

import SearchQuery from 'models/search/search_query';
import SearchResult from 'models/search/search_result';
import UniversalSearchBar from 'components/customer_combine/universal_search_bar';
import UniversalSearchResultsContainer from 'components/search/universal_search_results_container';

const searchTypingTimeout = 150;

class UniversalSearchBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: null,
      queryId: null,
    };

    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);

    this.typingDelayTimeout = null;
  }

  /* Handlers */

  fetchSearchResults({ text }) {
    let query = this.state.query;
    if (!query) {
      query = new SearchQuery();
      query.setType(SearchResult.HitTypes.CUSTOMER_PROFILE);
    }

    if (text) {
      query.setText(text);
    }

    const newSearch = SearchResult.create({ query });

    if (query.text) {
      this.props.onSearch(newSearch);
    }

    if (text !== '') {
      this.setState({ queryId: newSearch.id, query });
    } else {
      this.setState({ queryId: null, query: null });
    }
  }

  handleSearchTextChange(event) {
    this.props.clearTimeout(this.typingDelayTimeout);
    const text = event.target.value;
    this.typingDelayTimeout = this.props.setTimeout(() => {
      this.fetchSearchResults({ text });
    }, searchTypingTimeout);
    if (this.props.onSearchChange) {
      this.props.onSearchChange({ text });
    }
  }

  render() {
    return (
      <div className="universalSearch">
        <UniversalSearchBar onChange={this.handleSearchTextChange} placeholder={this.props.placeholder} />
        <UniversalSearchResultsContainer
          filterHits={this.props.filterHits}
          onSelectItem={this.props.onSelectItem}
          queryId={this.state.queryId}
          shouldShowPagination={false}
        />
      </div>
    );
  }
}

UniversalSearchBase.propTypes = {
  filterHits: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func,
  onSelectItem: PropTypes.func,
  placeholder: PropTypes.string,

  // For ReactTimeout
  clearTimeout: PropTypes.func.isRequired,
  setTimeout: PropTypes.func.isRequired,
};

export { UniversalSearchBase };
export default ReactTimeout(UniversalSearchBase);
