import createLocationModel, { prop } from './lib/create_location_model';
import createEnum from 'scripts/lib/create_enum';

export const LoadingStates = createEnum('LOADING', 'FAILED', 'LOADED');

export default createLocationModel({
  modelName: 'CustomReporting',
  properties: {
    path: prop(String).isRequired,
    files: prop([{ name: String }]).default([]),
    dirs: prop([String]).default([]),
    loadingState: prop(String).default(LoadingStates.LOADING),
  },

  startLoading() {
    this.loadingState = LoadingStates.LOADING;
    this.files = [];
    this.dirs = [];
  },

  failedToLoad() {
    this.loadingState = LoadingStates.FAILED;
  },

  filesLoaded({ files, dirs }) {
    this.loadingState = LoadingStates.LOADED;
    this.files = files;
    this.dirs = dirs;
  },

  statics: {
    breadcrumb: 'Custom Reports',

    create(attrs) {
      return new this(attrs);
    },
  },
});
