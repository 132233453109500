import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import Inbox from 'scripts/infrastructure/backends/fake_backend/inbox_service_v3';

export default class InboxService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
    this.inbox = new Inbox(pubsub, database);
  }

  findAgentInbox(_, callback, path, { orgId, agentId }, query) {
    let agentInbox = this.inbox.fetchAgentInbox(orgId, agentId, query);
    callback(null, { status: 200, statusText: statusText(200), response: agentInbox });
  }

  findGroupInbox(_, callback, path, { orgId, routingGroupId }, query) {
    let groupInbox = this.inbox.fetchGroupInbox(orgId, routingGroupId, query);
    callback(null, { status: 200, statusText: statusText(200), response: groupInbox });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v4/orgs/:orgId/routing-groups/:routingGroupId/inbox': {
          GET: this.findGroupInbox,
        },
        '/api/v5/orgs/:orgId/agents/:agentId/inbox': {
          GET: this.findAgentInbox,
        },
      },
      this
    );
  }
}
