import forEach from 'lodash/forEach';

import { convertFontSize } from 'components/customer/composition/lib/slate/slate_font_size_menu';
import { FONT_SIZES } from 'components/customer/composition/lib/slate/slate_marks';

const fontSizesInPx = {
  [FONT_SIZES.SMALL]: 10,
  [FONT_SIZES.REGULAR]: 13,
  [FONT_SIZES.LARGE]: 18,
  [FONT_SIZES.HUGE]: 32,
};

export default function getNearestFontSize(actualSize) {
  let { bestSize, minDist } = { bestSize: FONT_SIZES.REGULAR, minDist: Infinity };
  forEach(fontSizesInPx, (sizeValue, size) => {
    const diff = Math.abs(actualSize - sizeValue);
    if (diff < minDist) {
      minDist = diff;
      bestSize = size;
    }
  });

  return convertFontSize(bestSize);
}
