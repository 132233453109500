import createModel, { prop } from './lib/create_model';

const EmailSendFailure = createModel({
  modelName: 'EmailSendFailure',

  properties: {
    messageId: prop(String).isRequired,
    code: Number,
    detail: String,
    senderDetail: String,
    recipientDetail: String,
    recipient: String,
  },

  setRecipient(recipient) {
    this.recipient = recipient;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default EmailSendFailure;
