import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { useUID } from 'react-uid';

import { inlineDirectionSpacing } from 'components/space_helper';

/**
 * @visibleName Radio Button
 */

export default function RadioButton(props) {
  const { dataAid = '', disabled, isChecked, label, onChange, value, description, ...otherProps } = props;
  const uid = useUID();

  let renderValue = <RadioButtonLabel data-aid={`${dataAid}-label`}>{label}</RadioButtonLabel>;
  if (description) {
    renderValue = (
      <div>
        {renderValue}
        <RadioButtonDescription data-aid={`${dataAid}-description`}>{description}</RadioButtonDescription>
      </div>
    );
  }

  return (
    <Label
      data-aid={`${dataAid}-radio-label-wrapper`}
      disabled={disabled}
      htmlFor={uid}
      key={uid}
      showDescription={description}
      {...otherProps}
    >
      <HiddenRadio
        checked={isChecked}
        data-aid={`${dataAid}-hidden-radio`}
        disabled={disabled}
        id={uid}
        onChange={noop}
        onClick={onChange}
        value={value}
      />
      <Checkmark checked={isChecked} data-aid={`${dataAid}-radio-checkmark`} disabled={disabled} {...otherProps} />
      {renderValue}
    </Label>
  );
}

RadioButton.propTypes = {
  dataAid: PropTypes.string,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
};

export const Label = styled.label`
  cursor: pointer;
  display: inline-flex;
  margin-bottom: ${p => getSize(p) / 2 + 4}px;
  vertical-align: middle;
  flex-direction: row;
  flex-basis: auto;
  ${p => (!p.showDescription ? 'align-items: center' : '')};
  &[disabled] {
    color: ${p => p.theme.colors.gray400};
    cursor: default;
  }
`;

export const RadioButtonLabel = styled.span`
  line-height: 162%;
`;

export const RadioButtonDescription = styled.div`
  line-height: 162%;
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

export const Checkmark = styled.div.attrs({ inline: 'small', direction: 'right' })`
  background-color: ${p => p.theme.colors.white};
  border: ${p => (p.checked ? getSize(p) / 4 : 1)}px solid
    ${p => (p.checked ? p.theme.colors.green400 : p.theme.colors.gray300)};
  border-radius: 100%;
  display: flex;
  margin-right: ${p => getSize(p) / 4}px;
  padding: 0;
  height: ${p => getSize(p)}px;
  width: ${p => getSize(p)}px;
  min-width: ${p => getSize(p)}px;
  ${p => p.label && inlineDirectionSpacing(p)};

  &[disabled] {
    border-color: ${p => p.theme.colors.gray400};
    background-color: ${p => (p.checked ? p.theme.colors.gray500 : p.theme.colors.gray400)};
  }
`;

// TODO Move to components/space_helper and refactor other usages to fit output
function getSize(props) {
  let size;
  switch (props.size) {
    case 'medium':
      size = 24;
      break;
    case 'large':
      size = 32;
      break;
    case 'small':
    default:
      size = 16;
      break;
  }
  return size;
}
