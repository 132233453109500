import React from 'react';
import styled from 'styled-components';

import analytics from 'scripts/lib/analytics';
import connect from 'components/lib/connect';
import ContactEmailIcon from 'components/lib/icons/contact_email_icon';
import ContactPhoneIcon from 'components/lib/icons/contact_phone_icon';
import Link from 'components/common/link';
import ProfileAvatar from 'components/lib/profile_avatar';

export function RecentCustomer({ index, recentCustomer }) {
  if (!recentCustomer) {
    return null;
  }
  const { assignee, email, image, name, phone } = recentCustomer;

  const trackRecentCustomerClicked = () =>
    analytics.track('Recent Customer Clicked', { customerId: recentCustomer.id, rank: index });

  return (
    <Customer
      data-aid="recentCustomer-link"
      href={`/customer/${recentCustomer.id}`}
      onMouseDown={trackRecentCustomerClicked}
    >
      <Left>
        <Avatar profile={{ name, image }} />
        <Name data-aid="recentCustomer-name" hasName={!!name}>
          {name || 'Unknown customer'}
        </Name>
        {email && (
          <Email>
            <EmailIcon className="customerProfile-emailIcon" /> <span className="fs-mask">{email}</span>
          </Email>
        )}
        {phone && (
          <Phone>
            <PhoneIcon className="customerProfile-phoneIcon" /> <span className="fs-mask">{phone}</span>
          </Phone>
        )}
      </Left>

      {assignee && <AssigneeContainer assignee={assignee} />}
    </Customer>
  );
}

const Avatar = styled(ProfileAvatar)`
  width: 35px;
`;

const AgentAvatar = styled.span`
  margin-left: 8px;
`;

const Customer = styled(Link)`
  align-items: center;
  border-radius: ${p => p.theme.borderRadius.default};
  box-shadow: ${p => p.theme.boxShadow.small};
  color: #111111;
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px 16px;
  padding: 8px;
  text-decoration: none;

  &:active,
  &:hover,
  &:visited {
    background-color: ${p => p.theme.colors.gray100};
    color: #111111;
    text-decoration: none;
  }
`;

const Email = styled.span.attrs({ 'data-aid': 'recentCustomer-email' })`
  align-items: center;
  display: flex;
  margin-left: 16px;
`;

const EmailIcon = styled(ContactEmailIcon)`
  margin-right: 2px;
`;

const Left = styled.span`
  align-items: center;
  display: flex;
`;

const Name = styled.span.attrs({ className: 'fs-mask', 'data-aid': 'recentCustomer-name' })`
  ${p => (!p.hasName ? `color: ${p.theme.colors.gray500};` : '')}
  font-size: ${p => p.theme.fontSize.medium};
  ${p => (!p.hasName ? `font-style: italic;` : '')}
  font-weight: ${p => p.theme.fontWeight.heavy};
  margin-left: 8px;
`;

const Phone = styled.span.attrs({ 'data-aid': 'recentCustomer-phone' })`
  align-items: center;
  display: flex;
  margin-left: 16px;
`;

const PhoneIcon = styled(ContactPhoneIcon)`
  margin-right: 0;
`;

const Right = styled.span`
  align-items: center;
  display: flex;
`;

function Assignee({ assigneeAgent, assigneeRoutingGroup }) {
  if (assigneeAgent) {
    return (
      <Right>
        Assigned to {assigneeAgent.name}{' '}
        <AgentAvatar>
          <Avatar profile={assigneeAgent} />
        </AgentAvatar>
      </Right>
    );
  }
  if (assigneeRoutingGroup) {
    return <Right>Assigned to {assigneeRoutingGroup.name}</Right>;
  }
  return null;
}

const AssigneeContainer = connect(mapStateToAssigneeProps)(Assignee);

function mapStateToAssigneeProps({ getProvider }, { assignee }) {
  const routingGroupProvider = getProvider('routingGroups');
  const agentProvider = getProvider('agents');

  if (assignee.agentId) {
    const agent = agentProvider.findBy({ id: assignee.agentId });
    return { assigneeAgent: agent };
  }
  return { assigneeRoutingGroup: routingGroupProvider.findBy({ id: assignee.routingGroupId }) };
}

export default RecentCustomer;
