import _ from 'lodash';
import moment from 'moment';
import SearchFilter from 'models/search/search_filter';

const filterProperties = SearchFilter.properties;

const toLongDate = value => moment(value).format();

const filterPropertiesMap = [
  { dto: filterProperties.agentIds.label, url: 'ag' },
  { dto: filterProperties.channels.label, url: 'ch' },
  { dto: filterProperties.inboxIds.label, url: 'ib' },
  { dto: filterProperties.statuses.label, url: 'st' },
  { dto: filterProperties.topicIds.label, url: 'tp' },
  { dto: filterProperties.type.label, url: 't' },
  { dto: 'createdAt.gte', url: 'cg', transformForUrl: toLongDate },
  { dto: 'createdAt.lte', url: 'cl', transformForUrl: toLongDate },
  { dto: 'updatedAt.gte', url: 'ug', transformForUrl: toLongDate },
  { dto: 'updatedAt.lte', url: 'ul', transformForUrl: toLongDate },
  { dto: 'dueAt.gte', url: 'dg', transformForUrl: toLongDate },
  { dto: 'dueAt.lte', url: 'dl', transformForUrl: toLongDate },
];

export default {
  urlToDto(urlParam) {
    const dtoParam = this.find(i => urlParam === i.url);
    return dtoParam;
  },

  find(searchFn) {
    return filterPropertiesMap.find(searchFn);
  },

  convertToDto(urlParams) {
    return _.transform(urlParams, (result, value, key) => {
      const dtoProp = this.urlToDto(key);
      if (dtoProp) {
        _.set(result, dtoProp.dto, value);
      }
    });
  },

  convertToUrl(dtoParams) {
    let urlParams = {};
    filterPropertiesMap.forEach(({ dto, url, transformForUrl }) => {
      let value = _.get(dtoParams, dto);
      if (value) {
        urlParams[url] = transformForUrl ? transformForUrl(value) : value;
      }
    });
    return urlParams;
  },
};
