import { Factory } from 'rosie';

Factory.define('ActiveSession');
Factory.define('ActiveSessionWithDefaults')
  .extend('ActiveSession')
  .attr('id', 'session-id')
  .attr('customer', () => Factory.attributes('SessionCustomerWithDefaults'))
  .attr('typingState', 'STOPPED')
  .attr('queueItem', ['id'], id =>
    Factory.attributes('RoutingQueueItemWithDefaults', { id, offeredSessionType: 'SMS', status: 'OFFERED' })
  );

Factory.define('SessionCustomer');
Factory.define('SessionCustomerWithDefaults')
  .extend('SessionCustomer')
  .attr('id', 'customer-id')
  .attr('name', 'Darth Vader');
