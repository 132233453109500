import _ from 'lodash';

export default function getHighlightIndices(searchText, item, params) {
  const paramMatches = findMatches(searchText, item, params);

  return paramMatches.reduce((highlight, paramMatch) => {
    highlight[paramMatch.param] = paramMatch.matches.map(match => {
      return { start: match.index, end: match.index + match[0].length };
    });
    return highlight;
  }, {});
}

function findMatches(searchText, item, params) {
  if (!searchText) {
    return [];
  }

  searchText = searchText.toLowerCase();
  const searchRegex = getRegex(_.escapeRegExp(searchText));
  return _.compact(
    _.map(params, param => {
      const itemText = item[param];
      if (!itemText) {
        return null;
      }
      const lowerCaseText = itemText.toLowerCase();
      let match = searchRegex.exec(lowerCaseText);

      let matches = [];
      while (match !== null) {
        matches.push(match);
        match = searchRegex.exec(lowerCaseText);
      }
      return matches.length > 0 ? { param, matches } : null;
    })
  );
}

function getRegex(text) {
  const matchRe = text
    .trim()
    .toLowerCase()
    .split(' ')
    .join('|');
  return new RegExp(`(${matchRe})`, 'g');
}
