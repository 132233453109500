import { trackWidgetLoaded } from 'actions/cobrowsing/analytics';

export default class HandleWidgetLoaded {
  constructor(context) {
    this.context = context;
  }

  run({ loadingTime, loaderUrl }) {
    trackWidgetLoaded(this.context, { loadingTime, loaderUrl });
  }
}
