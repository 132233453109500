import React from 'react';
import PropTypes from 'prop-types';

import { createAttachmentContainer } from 'components/lib/attachment/create_attachment_container';
import FbMessageIncomingAttachment from 'models/fb_message_incoming_attachment';
import FbMessageOutgoingAttachment from 'models/fb_message_outgoing_attachment';
import GenericAttachment from 'components/lib/attachment/generic_attachment';
import { getFbAttachmentUrl } from 'scripts/domain/services/attachments';

const FbMessageAttachmentContainer = createAttachmentContainer({
  propTypes: {
    attachment: PropTypes.oneOfType([
      PropTypes.instanceOf(FbMessageIncomingAttachment),
      PropTypes.instanceOf(FbMessageOutgoingAttachment),
    ]).isRequired,
  },

  getAttachmentUrl(orgId, attachment) {
    return getFbAttachmentUrl(orgId, attachment);
  },

  renderAttachment() {
    return (
      <GenericAttachment
        attachment={this.props.attachment}
        onClick={(this.props.attachment.isAvailable() && this.openAttachmentPreview) || undefined}
      />
    );
  },
});

export default FbMessageAttachmentContainer;
