import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import { after } from 'scripts/infrastructure/backends/http_client';

export default class AgentTaskReadGateway {
  constructor(backend) {
    this.http = backend;
  }

  init({ orgId, agentId }) {
    this.orgId = orgId;
    this.agentId = agentId;
  }

  get version() {
    return 1;
  }

  /*
    HTTP methods
   */

  get _httpClient() {
    return this.http.axios();
  }

  url() {
    return `/api/v${this.version}/orgs/${this.orgId}/agent-task-reads/${this.agentId}`;
  }

  update(agentReadAttrs) {
    const endpoint = this.url();
    return after(this._httpClient.put(endpoint, agentReadAttrs), this.onUpdate.bind(this));
  }

  onUpdate(err, res) {
    if (res && res.status >= 200 && res.status < 300) {
      this.notifyObservers('handleUpdateSuccess', res);
      return;
    }

    const responseErr = err || res || new Error('unable to update agent task read');
    this.notifyObservers('handleUpdateError', responseErr);
  }
}

mixin(AgentTaskReadGateway.prototype, Observable);
