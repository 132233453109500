import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { CategoryChevron } from 'components/common/nav/category_chevron.jsx';
import connect from 'components/lib/connect';
import Datasets from 'models/location/datasets';
import DropdownMenu from 'components/common/dropdown_menu';
import { getAllReportConfigs } from 'actions/reporting/lib/reporting_helpers';
import { getDatasetNames } from 'models/reports/embedded_dataset';
import NavigateTo from 'actions/current_location/navigate_to';
import { NavMenu } from 'components/common/nav/nav_menu';
import { NavLink } from 'components/common/nav/nav_link';
import { MenuCategory } from 'components/common/nav/menu_category';
import { ReportConfig } from 'models/reports/report_configs';
import Reports from 'models/location/reports';
import SpinnerTwo from 'scripts/presentation/components/common/spinner_two';

export const SLUG_IVR = 'ivr';

/**
 * Determines if the slug represents a report or a set of reports and
 * returns the original slug in the former case or the slug of the default
 * report in the set in the latter one.
 *
 * Example: "ivr" slug refers ti a set of reports it's refined as
 * "ivr-summary".
 *
 * @param slug the reporg slug to refine.
 */
export function refineSlug(slug) {
  if (slug === SLUG_IVR) {
    // use ivr-summary for default IVR report
    return 'ivr-summary';
  }
  return slug;
}

function ReportNavigation({
  activeReport,
  datasetNames,
  isCollapsible = true,
  isReportBuilderConfigEnabled,
  isReportBuilderConfigLoading,
  isReportBuilderFeatureEnabled,
  isReportConfigsLoading,
  isSharedReportConfigsLoading,
  reportConfigs,
  sharedReportConfigs,
  onDatasetSelect,
  onReportSelect,
}) {
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState('Conversation');
  const [closedReportCategories, setClosedReportCategories] = useState(['Sunsetting']);
  const activeParentReport = getActiveParentReport(activeReport);

  const toggleSelectedReportCollapse = id => {
    setClosedReportCategories(calculateNewClosedReportCategories(id));
  };

  const calculateNewClosedReportCategories = id => {
    const newClosedReportCategories = [...closedReportCategories];
    const index = newClosedReportCategories.indexOf(id);
    if (newClosedReportCategories.indexOf(id) > -1) {
      newClosedReportCategories.splice(index, 1);
    } else {
      newClosedReportCategories.push(id);
    }
    return newClosedReportCategories;
  };

  const getNavReportData = function(rawNavData) {
    const processedReportData = rawNavData.reduce((navData, report) => {
      if (!navData[report.categoryId]) {
        navData[report.categoryId] = {
          categoryId: report.categoryId,
          childReports: [],
        };
      }
      const reportDataForNav = _.pick(report, ['title', 'urlSlug']);
      navData[report.categoryId].childReports = [reportDataForNav, ...navData[report.categoryId].childReports];
      return navData;
    }, {});

    const sortedCategoryReportData = _.orderBy(processedReportData, [category => category.categoryId.toLowerCase()]);
    const sortedReportAndCategoryReportData = _.map(sortedCategoryReportData, category => {
      category.childReports = _.orderBy(category.childReports, [report => report.title.toLowerCase()]);
      return category;
    });
    return sortedReportAndCategoryReportData;
  };

  // Filter out the IVR End States config because we do not want to show it in the nav
  // IVR has its own sub-tab nav system on the IVR Summary page
  const urlsToFilterOut = ['ivr-end-states'];

  let filteredReportConfigs = _.filter(reportConfigs, config => !_.includes(urlsToFilterOut, config.urlSlug));
  if (isReportBuilderConfigEnabled) {
    filteredReportConfigs = _.concat(filteredReportConfigs, sharedReportConfigs);
  }
  const navReportData = getNavReportData(filteredReportConfigs);

  useEffect(() => {
    setCurrentSelectedCategory(getActiveCategoryInData());
    function getActiveCategoryInData() {
      if (!navReportData) {
        return null;
      }
      const getActiveReportInData = navReportData.find(category =>
        category.childReports.find(report => report.urlSlug === activeParentReport)
      );
      return getActiveReportInData && getActiveReportInData.categoryId;
    }
  }, [activeParentReport, navReportData]);

  return (
    <NavMenu data-aid="reporting-navMenu" title="Reports">
      {isReportBuilderFeatureEnabled ? renderDatasetsDropdown(datasetNames) : null}
      {isReportConfigsLoading && isSharedReportConfigsLoading ? renderLoadingSpinner() : renderPopulatedSideNav()}
    </NavMenu>
  );

  function renderDatasetsDropdown(datasetNames) {
    if (isReportBuilderFeatureEnabled && isReportBuilderConfigLoading) {
      return renderDropdownLoadingSpinner();
    }

    if (!(isReportBuilderConfigEnabled && isReportBuilderFeatureEnabled)) {
      return false;
    }

    const currentDataset = activeParentReport;
    const options = _.map(datasetNames, dataset => ({ label: _.startCase(dataset), value: dataset }));

    return (
      <DropdownContainer>
        <DropdownMenu
          data-aid="embeddedDatasets-dropdown"
          maxHeight={275}
          onSelect={onDatasetSelect}
          options={options}
          placeholder={'Create a Report'}
          searchable
          value={currentDataset}
        />
      </DropdownContainer>
    );
  }

  function renderLoadingSpinner() {
    return (
      <SpinnerContainer data-aid="loadingSpinner">
        <SpinnerTwo />
      </SpinnerContainer>
    );
  }

  function renderDropdownLoadingSpinner() {
    return (
      <DropdownSpinnerContainer data-aid="dropdown-loadingSpinner">
        <SpinnerTwo />
      </DropdownSpinnerContainer>
    );
  }

  function renderPopulatedSideNav() {
    return navReportData.map(reportingCategory => {
      const isSelectedCategory = currentSelectedCategory === reportingCategory.categoryId;
      const isOpen = !closedReportCategories.includes(reportingCategory.categoryId);

      return (
        <MenuCategory
          data-aid={`menuCategory-${reportingCategory.categoryId}`}
          icon={
            <CategoryChevron
              categoryId={reportingCategory.categoryId}
              isOpen={isOpen}
              toggleSelectedReportCollapse={toggleSelectedReportCollapse}
            />
          }
          isOpen={isOpen}
          isSelected={isSelectedCategory && !isOpen}
          key={reportingCategory.categoryId}
          label={reportingCategory.categoryId}
          onClick={isCollapsible ? () => setCurrentSelectedCategory(reportingCategory.categoryId) : null}
        >
          {reportingCategory.childReports.map(report => (
            <NavLink
              data-aid={`pageLink-${report.urlSlug}`}
              isActive={activeParentReport === report.urlSlug}
              key={report.title}
              onClick={() => onReportSelect(report.urlSlug)}
              to={report.urlSlug}
            >
              {report.title}
            </NavLink>
          ))}
        </MenuCategory>
      );
    });
  }
}

function getActiveParentReport(activeReport) {
  switch (activeReport) {
    case 'ivr-end-states':
    case 'ivr-summary':
      return 'ivr-summary';
    default:
      return activeReport;
  }
}

ReportNavigation.propTypes = {
  /** Currently selected report. */
  activeReport: PropTypes.string,
  /** Names of Insight Builder datasets. */
  datasetNames: PropTypes.arrayOf(PropTypes.string),
  /** True if category collapsing is allowed. */
  isCollapsible: PropTypes.bool,
  /** True if Insight Builder is enabled for the org. */
  isReportBuilderConfigEnabled: PropTypes.bool,
  /** True if Insight Builder config is loading. */
  isReportBuilderConfigLoading: PropTypes.bool,
  /** True if the user has access to Insight Builder. */
  isReportBuilderFeatureEnabled: PropTypes.bool,
  /** True if report configs are loading. */
  isReportConfigsLoading: PropTypes.bool,
  /** True if shared report configs are loading. */
  isSharedReportConfigsLoading: PropTypes.bool,
  /** Array of report configs. */
  reportConfigs: PropTypes.arrayOf(PropTypes.instanceOf(ReportConfig)),
  /** Array of shared configs. */
  sharedReportConfigs: PropTypes.arrayOf(PropTypes.instanceOf(ReportConfig)),

  /** Callback that is invoked when a dataset is selected. */
  onDatasetSelect: PropTypes.func.isRequired,
  /** Callback that is invoked when a report is selected. */
  onReportSelect: PropTypes.func.isRequired,
};

const DropdownContainer = styled.div`
  padding-bottom: 24px;
`;

const DropdownSpinnerContainer = styled.span`
  align-items: center;
  display: flex;
  flex: 1 1 300px;
  justify-content: center;
  padding-top: 0px;
  width: 100%;
`;

const SpinnerContainer = styled.span`
  align-items: center;
  display: flex;
  flex: 1 1 300px;
  justify-content: center;
  padding-top: ${p => p.theme.spacing.xlarge};
  width: 100%;
`;

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const reportConfigProvider = getProvider('reportConfigs');
  const sharedReportConfigProvider = getProvider('sharedReportConfigs');

  const isReportConfigsLoading = reportConfigProvider.isLoading();
  const isSharedReportConfigsLoading = sharedReportConfigProvider.isLoading();

  const currentLocation = getProvider('currentLocation').get();
  const reportSlug = currentLocation.slug;

  const orgIsDemoMode = getProvider('appFeatures')
    .get()
    .isEnabled('demoMode');
  const datasetNames = getDatasetNames(orgIsDemoMode);

  const reportBuilderConfigProvider = getProvider('reportBuilderConfig');
  const isReportBuilderConfigLoading = reportBuilderConfigProvider.isLoading();
  const reportBuilderConfig = reportBuilderConfigProvider.get();

  const isReportBuilderFeatureEnabled = isFeatureEnabled('reportBuilder');

  const reportConfigs = getAllReportConfigs({ stores: { reportConfigs: reportConfigProvider } });
  const sharedReportConfigs = sharedReportConfigProvider.get().configs;

  const isReportBuilderConfigEnabled = reportBuilderConfig ? reportBuilderConfig.enabled : false;

  return {
    activeReport: reportSlug,
    currentLocation,
    datasetNames,
    isReportBuilderFeatureEnabled,
    isReportBuilderConfigEnabled,
    isReportBuilderConfigLoading,
    isReportConfigsLoading,
    isSharedReportConfigsLoading,
    reportConfigs,
    sharedReportConfigs,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    navigateToDataset: (currentLocation, newDatasetSlug) => {
      executeAction(NavigateTo, Datasets.create({ ...currentLocation, slug: newDatasetSlug }));
    },
    navigateToReport: (currentLocation, newReportSlug) => {
      executeAction(NavigateTo, Reports.create({ ...currentLocation, slug: refineSlug(newReportSlug) }));
    },
  };
}

function mergeProps(stateProps, executeProps, ownProps) {
  return {
    ...ownProps,
    ..._.omit(stateProps, 'currentLocation'),
    onDatasetSelect: datasetSlug => executeProps.navigateToDataset(stateProps.currentLocation, datasetSlug),
    onReportSelect: reportSlug => executeProps.navigateToReport(stateProps.currentLocation, reportSlug),
  };
}

const ReportNavigationContainer = connect(mapStateToProps, mapExecuteToProps, mergeProps)(ReportNavigation);

export default ReportNavigationContainer;
