import _ from 'lodash';

import ConversationItemType from 'models/conversation_item_type';
import isCustomerLoaded from 'actions/customer/lib/is_customer_loaded';
import qconsole from 'scripts/lib/qconsole';

import { throttlePerCustomerId } from 'actions/conversation/update_agent_read';

export default function(context, customerId) {
  if (customerId === context.stores.currentLocation.get().customerId) {
    return;
  }

  qconsole.log(`Unloading customer ${customerId} from stores`);

  let currentAgentId = _.get(context.stores.currentAgent.get(), 'id');
  let customer = context.stores.customers.findBy({ id: customerId });
  let conversations = customer && customer.conversations;

  // Unwind subscriptions that require stores, before the stores are cleared
  clearCommentsAndFollowers(context, customer);
  unsubscribeFromExternalDataObjects(context, customerId);

  // Clear the stores
  context.stores.customers.remove(customerId);
  context.stores.customers.clearCachedStoresFor(customerId);

  // unsubscribe from the customer
  context.gateways.customerActiveSecureDataRequests.unsubscribe({ customerId });
  context.gateways.customerAssignee.unsubscribe(customerId);
  context.gateways.customerProfile.unsubscribe({ customerId });
  context.gateways.customerProfileMerge.unsubscribe({ customerId });
  context.gateways.customerHistory.unsubscribe(customerId);
  context.gateways.customerTransaction.unsubscribe({ customerId });
  context.gateways.customerNote.unsubscribe({ customerId });
  context.gateways.relationship.unsubscribeAll({ customerId });

  if (currentAgentId && conversations && conversations.length) {
    conversations.forEach(conversation =>
      context.gateways.agentRead.unsubscribe({ conversationId: conversation.id, agentId: currentAgentId })
    );
  }

  if (throttlePerCustomerId[customerId]) {
    throttlePerCustomerId[customerId].flush();
    delete throttlePerCustomerId[customerId];
  }
}

export function clearCommentsAndFollowers(context, customer) {
  if (!customer) return;

  const taskInfoStore = context.stores.taskInfo;
  customer.conversationHistory.forEach(item => {
    if (
      item.contentType() === ConversationItemType.TASK ||
      (item.contentType() === ConversationItemType.ITEM_LINK &&
        _.get(item, 'content.originalContent.type') === ConversationItemType.TASK)
    ) {
      // unsubscribe to comments and followers for customer
      context.gateways.taskComments.unsubscribeAll({ taskId: item.id });
      context.gateways.taskFollowers.unsubscribeAll({ taskId: item.id });
      // remove task info for customer's tasks from taskInfo store
      taskInfoStore.remove(item.id);
    }
  });
}

export function unsubscribeFromExternalDataObjects(context, customerId) {
  if (!customerId || !isCustomerLoaded(context, customerId)) return;

  const stores = context.stores.customers.storesFor(customerId);
  const dataStore = stores?.externalDataObjects;
  const dataObjects = dataStore?.findAll() || [];

  // Item should have the attributes of the `ExternalDataObjectEnvelope`
  const promises = dataObjects.map(item => {
    return context.gateways.customerExternalDataObjects.unsubscribe({
      gladlyEntityType: item.parentEntityType,
      gladlyEntityId: item.parentEntityId,
    });
  });
  return Promise.allSettled(promises);
}
