import MessageContentClasses from './message_content_classes';
import TypeReflect from 'models/lib/type_reflect';

export const typeReflect = new TypeReflect(MessageContentClasses);

export default function(content) {
  try {
    let ContentClass = typeReflect.typeToConstructor(content.type);
    return ContentClass.fromJs(content);
  } catch (e) {
    throw new Error(`unknown message content type [${content.type}]`);
  }
}
