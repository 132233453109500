import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'InstagramConfiguration',
  properties: {
    enabled: prop(Boolean).isRequired,
    displayName: prop(String),
    handle: prop(String),
  },

  setHandle(handle) {
    this.handle = handle;
  },

  setDisplayName(displayName) {
    this.displayName = displayName;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
