import { Factory } from 'rosie';

Factory.define('Team').extend('Uuid');

Factory.define('TeamWithDefaults')
  .extend('Team')
  .attr('id', 'default-id')
  .attr('agentIds', ['default-AgentID-1', 'default-AgentID-2'])
  .attr('name', 'default-Name')
  .attr('_version', 1);
