import createModel, { prop } from 'models/lib/create_model';

export default createModel({
  modelName: 'SmsCampaignOutreachResult',

  properties: {
    campaignId: prop(String),
    recipientCollectionId: prop(String),
    recipientId: prop(String),
    result: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
