import _ from 'lodash';

import HitTypes from 'models/search/hit_types';
import Search from 'models/location/search';
import SearchFilter from 'models/search/search_filter';
import SearchQuery from 'models/search/search_query';

export default class NavigateToSearchResults {
  constructor(context) {
    this.context = context;
  }

  run({ filterProperties, text, size, from }) {
    let query;

    if (text === '') {
      query = undefined;
    } else {
      query = this.getCurrentQuery() || new SearchQuery({ filter: new SearchFilter() });

      if (filterProperties && filterProperties.newTopicId && filterProperties.topicIds) {
        const topicId = filterProperties.newTopicId;
        const topic = this.context.stores.topics.findBy({ id: topicId });
        const topicIds = _.uniq(filterProperties.topicIds.concat(topic ? topic.descendants : undefined));
        filterProperties = { ...filterProperties, topicIds };
      }

      if (text !== undefined) {
        query.setText(text);
      }

      if (filterProperties && filterProperties.type === HitTypes.CUSTOMER_PROFILE) {
        // if type filter is set to customer, ignore the other filters
        query.resetFilter();
        query.setFilterProperties({ type: HitTypes.CUSTOMER_PROFILE });
      } else if (filterProperties) {
        query.setFilterProperties(filterProperties);
      }

      if (!query.filter.type) {
        // require type filter to be set, defaulting to conversation
        query.setType(HitTypes.CONVERSATION);
      }

      query.setFrom(from || 0);
      if (size !== undefined) {
        query.setSize(size);
      }
    }

    let newSearch = Search.create({ query });
    this.context.router.navigateTo(newSearch);
    if (query) {
      this.context.analytics.track('Universal Search Initiated', {
        text: query.text,
        ...query.filter,
      });
    }
  }

  getCurrentQuery() {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof Search)) {
      return null;
    }

    return currentLocation.query;
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }
}
