import _ from 'lodash';

import ConversationItemType from 'models/conversation_item_type';
import { InboxItemType } from 'models/agent_inbox/agent_inbox_item';

export default function needsAttention(item, activeSessions, activeCallId) {
  if (item.item && item.item.contentType() === ConversationItemType.PHONE_CALL) {
    return (item.conversation && item.conversation.id === activeCallId) || item.hasSLA();
  }
  if (
    item.type === InboxItemType.CUSTOMER &&
    !!_.find(activeSessions, s => s.queueItem.conversationId === _.get(item.conversation, 'id'))
  ) {
    return item.hasSLA() || (item.item && !item.hasSLA() && item.item.initiator.type === 'CUSTOMER');
  }
  return item.hasSLA();
}
