import { Factory } from 'rosie';

import './lib/id';

Factory.define('CustomAttribute').extend('Id');

Factory.define('CustomAttributeWithDefaults')
  .extend('CustomAttribute')
  .attr('key', ['id'], function(id) {
    return `Key_${id}`;
  })
  .attr('label', ['id'], function(id) {
    return `Label ${id}`;
  })
  .attr('_version', 1)
  .attr('deactivated', false)
  .attr('createdAt', () => new Date().toISOString())
  .attr('createdBy', 'exampleAgentId')
  .attr('updatedAt', () => new Date().toISOString())
  .attr('updatedBy', 'exampleAgentId');

Factory.define('CustomAttributeValue');

Factory.define('CustomAttributeValueWithDefaults')
  .extend('CustomAttributeValue')
  .attr('id', 'exampleId')
  .attr('value', 'exampleValue');
