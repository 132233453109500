import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Checkmark from 'components/common/icons/checkmark.jsx';
import { MenuItem as RawMenuItem } from 'components/lib/menu_deprecated';

import ConversationItemType from 'models/conversation_item_type';
import ViewOriginalEmailHtmlMenuItem from './view_original_email_html';

export default class ConversationItemMenuItems extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.renderReplyToGroupMessage()}
        {this.renderAddCommentMenuItem()}
        {this.renderForwardMenuItem()}
        {this.renderReplyMenuItem()}
        {this.renderNoReplyNeededItem()}
        {this.renderViewOriginalEmailHtmlItem()}
        {this.renderVoiceRecordingMenuItems()}
        {this.renderCopyMenuItem()}
        {this.renderObserveCallMenuItem()}
        {this.renderMentionMenuItem()}
        {this.renderEditTaskMenuItem()}
        {this.renderFollowTaskMenuItem()}
        {this.renderUnfollowTaskMenuItem()}
        {this.renderReportPoorCallQuality()}
        {this.renderReactionMenuItem()}
        {this.renderUnreactionMenuItem()}
        {this.renderHighlightGroupedMessagesMenuItem()}
        {this.renderRedactMenuItem()}
      </React.Fragment>
    );
  }

  renderAddCommentMenuItem() {
    if (
      !this.props.hideComment &&
      this.props.contentType === ConversationItemType.TASK &&
      !_.get(this.props, 'item.content.closedAt')
    ) {
      return (
        <MenuItem
          className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-addComment')}
          onSelect={this.props.openSidePanel}
        >
          <span>Add a Comment</span>
        </MenuItem>
      );
    }
    return null;
  }

  renderForwardMenuItem() {
    if (!this.props.isForwardVisible) {
      return null;
    }
    return (
      <MenuItem
        className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-forward')}
        onSelect={this.props.onForward}
      >
        <span>Forward</span>
      </MenuItem>
    );
  }

  renderReplyMenuItem() {
    if (!this.props.isReplyVisible) {
      return null;
    }
    return (
      <MenuItem
        className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-reply')}
        onSelect={this.props.onReply}
      >
        <span>Reply All</span>
      </MenuItem>
    );
  }

  renderNoReplyNeededItem() {
    if (!this.props.isNoReplyNeededVisible) {
      return null;
    }
    return (
      <MenuItem
        className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-noReplyNeeded')}
        onSelect={this.props.onNoReplyNeeded}
      >
        <span>No Reply Needed</span>
      </MenuItem>
    );
  }

  renderViewOriginalEmailHtmlItem() {
    if (!this.props.isViewOriginalEmailHtmlVisible) {
      return null;
    }

    return <ViewOriginalEmailHtmlMenuItem />;
  }

  renderVoiceRecordingMenuItems() {
    if (!this.props.canManageVoiceRecordings) {
      return null;
    }
    return [
      <MenuItem
        className="conversationItemMenu-menuItem conversationItemMenu-menuItem-downloadRecording"
        key="conversationItemMenu-menuItem-downloadRecording"
      >
        <a className="conversationItemMenu-menuItem-downloadRecording-url" download href={this.props.recordingUrl}>
          <span>Download Recording</span>
        </a>
      </MenuItem>,
      <MenuItem
        className="conversationItemMenu-menuItem conversationItemMenu-menuItem-deleteRecording"
        key="conversationItemMenu-menuItem-deleteRecording"
        onSelect={this.props.onDeleteRecording}
      >
        <span>Delete Recording</span>
      </MenuItem>,
    ];
  }

  renderCopyMenuItem() {
    return (
      <MenuItem
        className="conversationItemMenu-menuItem conversationItemMenu-menuItem-copyLink"
        onSelect={this.props.onCopyLink}
      >
        <span>Copy Link</span>
      </MenuItem>
    );
  }

  renderObserveCallMenuItem() {
    if (this.props.contentType === ConversationItemType.PHONE_CALL && this.props.canObserveCall) {
      return (
        <MenuItem
          className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-observeCall')}
          onSelect={this.props.onObserveCall}
        >
          <span>Listen In On Call</span>
        </MenuItem>
      );
    }
    return null;
  }

  renderMentionMenuItem() {
    return (
      <MenuItem
        className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-mention')}
        onSelect={this.props.onOpenPinToCustomer}
      >
        <span>Pin To Another Customer</span>
      </MenuItem>
    );
  }

  renderEditTaskMenuItem() {
    if (this.props.contentType === ConversationItemType.TASK && !_.get(this.props, 'item.content.closedAt')) {
      return (
        <MenuItem
          className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-editTask')}
          onSelect={this.props.onEditTask}
        >
          <span>Edit Task</span>
        </MenuItem>
      );
    }
    return null;
  }

  renderFollowTaskMenuItem() {
    if (this.props.isFollowVisible) {
      return (
        <MenuItem
          className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-followTask')}
          onSelect={this.props.onFollowTask}
        >
          <span>Follow Task</span>
        </MenuItem>
      );
    }
    return null;
  }

  renderUnfollowTaskMenuItem() {
    if (this.props.isUnfollowVisible) {
      return (
        <MenuItem
          className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-unfollowTask')}
          onSelect={this.props.onUnfollowTask}
        >
          <span>Unfollow Task</span>
        </MenuItem>
      );
    }
    return null;
  }

  renderReportPoorCallQuality() {
    if (!this.props.canReportPoorCallQuality) {
      return null;
    }

    return (
      <MenuItem
        className="conversationItemMenu-menuItem conversationItemMenu-menuItem-reportPoorCallQuality"
        key="conversationItemMenu-menuItem-reportPoorCallQuality"
        onSelect={this.props.onReportPoorCallQuality}
      >
        <span>Report Poor Call Quality</span>
      </MenuItem>
    );
  }

  renderReactionMenuItem() {
    if (this.props.isReactionVisible) {
      return (
        <MenuItem
          className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-reaction')}
          onSelect={this.props.onReaction}
        >
          <span>Like Message</span>
        </MenuItem>
      );
    }
    return null;
  }

  renderUnreactionMenuItem() {
    if (this.props.isUnreactionVisible) {
      return (
        <MenuItem
          className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-unreaction')}
          onSelect={this.props.onUnreaction}
        >
          <span>Unlike Message</span>
        </MenuItem>
      );
    }
    return null;
  }

  renderRedactMenuItem() {
    if (!this.props.canRedactItem) {
      return null;
    }

    return (
      <RedactMenuItem
        className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-redact')}
        onSelect={this.props.onRedact}
      >
        <span>Delete Message</span>
      </RedactMenuItem>
    );
  }

  renderReplyToGroupMessage() {
    if (!this.props.isGroupMessagingItem) {
      return null;
    }

    return (
      <MenuItem
        className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-replyToGroupMessage')}
        onSelect={this.props.onReplyToGroupMessage}
      >
        <span>Reply to Message</span>
      </MenuItem>
    );
  }

  renderHighlightGroupedMessagesMenuItem() {
    if (!this.props.isGroupMessagingItem) {
      return null;
    }

    return (
      <HighlightGroupedMessagesMenuItem
        className={classnames(
          'conversationItemMenu-menuItem',
          'conversationItemMenu-menuItem-highlightGroupedMessages'
        )}
        onSelect={
          this.props.isHighlighted
            ? this.props.onUnselectHighlightGroupedMessages
            : this.props.onHighlightGroupedMessages
        }
      >
        {this.props.isHighlighted && <ActiveCheckmark />}
        <span>Highlight related messages</span>
      </HighlightGroupedMessagesMenuItem>
    );
  }
}

const ActiveCheckmark = styled(Checkmark)`
  fill: ${p => p.theme.colors.gray600};
  height: ${p => p.theme.spacing.large};
  left: 16px;
  position: absolute;
  top: 9px;
  width: ${p => p.theme.spacing.large};
`;

const MenuItem = styled(RawMenuItem)`
  padding: 8px 36px;
  position: relative;

  &:focus {
    padding: 8px 36px;

    svg {
      fill: ${p => p.theme.colors.green400};
    }
  }
`;

const HighlightGroupedMessagesMenuItem = styled(MenuItem)`
  border-top: 1px solid ${p => p.theme.colors.gray300} !important;
`;

const RedactMenuItem = styled(MenuItem)`
  border-top: 1px solid ${p => p.theme.colors.gray300};
  color: ${p => p.theme.colors.red400};
  &:hover {
    background: ${p => p.theme.colors.red400};
    color: ${p => p.theme.colors.white};
  }
`;

ConversationItemMenuItems.propTypes = {
  canManageVoiceRecordings: PropTypes.bool,
  canObserveCall: PropTypes.bool,
  canRedactItem: PropTypes.bool,
  canReportPoorCallQuality: PropTypes.bool,
  className: PropTypes.string,
  contentType: PropTypes.string.isRequired,
  conversationItemId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  hideComment: PropTypes.bool,
  isFollowVisible: PropTypes.bool,
  isForwardVisible: PropTypes.bool,
  isGroupMessagingItem: PropTypes.bool,
  isMenuVisible: PropTypes.bool,
  isNoReplyNeededVisible: PropTypes.bool,
  isReactionVisible: PropTypes.bool,
  isReplyVisible: PropTypes.bool,
  isUnfollowVisible: PropTypes.bool,
  isUnreactionVisible: PropTypes.bool,
  isViewOriginalEmailHtmlVisible: PropTypes.bool,
  onCopyLink: PropTypes.func,
  onDeleteRecording: PropTypes.func,
  onEditTask: PropTypes.func,
  onFollowTask: PropTypes.func,
  onForward: PropTypes.func,
  onHighlightGroupedMessages: PropTypes.func,
  onNoReplyNeeded: PropTypes.func,
  onObserveCall: PropTypes.func,
  onOpenPinToCustomer: PropTypes.func,
  onReaction: PropTypes.func,
  onRedact: PropTypes.func,
  onReply: PropTypes.func,
  onReplyToGroupMessage: PropTypes.func,
  onReportPoorCallQuality: PropTypes.func,
  onUnfollowTask: PropTypes.func,
  onUnreaction: PropTypes.func,
  onUnselectHighlightGroupedMessages: PropTypes.func,
  openSidePanel: PropTypes.func,
  recordingUrl: PropTypes.string,
};
