import CollectionStoreSaveResultHandler from '../lib/collection_store_save_result_handler';
import DtoConverter from 'scripts/application/dto_converters/answer_suggestion_converter';
import mixin from 'scripts/lib/mixin';
import qconsole from 'scripts/lib/qconsole';

export default class AnswerSuggestionGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.answerSuggestions;
  }

  handleRequestedEntities(dto) {
    this.store.addOrReplace(DtoConverter.fromDto(dto));
  }

  handleRequestError(errorDto) {
    qconsole.log('Failed to fetch answer suggestions', errorDto);
    this.store.resetLoading();
  }
}

mixin(AnswerSuggestionGatewayObserver.prototype, CollectionStoreSaveResultHandler.prototype);
