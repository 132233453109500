import CustomerView from 'models/location/customer_view';

export default class ToggleComposerVisibility {
  constructor(context) {
    this.context = context;
  }

  run() {
    const currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    currentLocation.toggleCompositionMinimized();
    this.context.stores.currentLocation.set(currentLocation);
  }
}
