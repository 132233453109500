import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AttributeType } from 'models/configuration/attribute_def';
import Button, { ButtonTypes } from 'components/common/button';
import Checklist from 'components/lib/checklist';
import ClearMatches from 'actions/customer/clear_matches';
import CloseMergeProfileModal from 'actions/customer/close_merge_profile_modal';
import CloseModal from 'actions/modal/close_modal';
import connect from 'components/lib/connect';
import createEnum from 'scripts/lib/create_enum';
import CustomerMerge from 'models/customer_merge';
import Err from 'models/err';
import LoadingSpinner from 'components/lib/loading_spinner';
import MergeCustomers from 'actions/customer/merge_customers';
import ModalCard from 'components/common/modal_card';
import { normalizeEmailAddress } from 'models/customer_email_address';
import ProfileCard from './profile_card';
import SelectMatch from 'actions/customer/select_match';
import TrackEvent from 'actions/analytics/track_event';

export const MergeProfileModalTypes = createEnum('MERGE', 'MATCH');

/**
 * MergeProfileModalBase renders a modal card which allows an agent to merge the current customer profile
 * with another profile (while still on the customer page).
 */

export class MergeProfileModalBase extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomerIdx: 0,
    };

    _.bindAll(this, ['mergeCustomers', 'updateSelection', 'onClose']);
  }

  reportAnalytics(eventName, prevProps) {
    // Check to see if the props we are interested in have changed. If not - skip
    const trackedProps = ['preventMerging', 'matches'];
    if (prevProps) {
      const prev = _.pick(prevProps, trackedProps);
      const current = _.pick(this.props, trackedProps);
      if (_.isEqual(prev, current)) return;
    }

    this.props.onTrackedEvent &&
      this.props.onTrackedEvent(eventName, {
        modalType: this.props.modalType || MergeProfileModalTypes.MERGE,
        mergingDisabled: !!this.props.preventMerging,
        potentialMatches: this.props.matches?.length || 0,
      });
  }

  componentDidMount() {
    this.reportAnalytics('Customer Merge Dialog Displayed');
  }
  componentDidUpdate(prevProps) {
    this.reportAnalytics('Customer Merge Dialog Updated', prevProps);
  }

  hideEmptyModal() {
    const handler = this.props.onEmptyModal;
    if (handler) {
      setImmediate(() => {
        this.reportAnalytics('Customer Merge Dialog Hidden');
        handler();
      });
    }
  }

  render() {
    const {
      currentDetails,
      isMergePending,
      matches,
      onClose,
      modalType = MergeProfileModalTypes.MERGE,
      preventMerging,
    } = this.props;

    if (!matches?.length) {
      this.hideEmptyModal();
      return null;
    }

    const modalOptions = {
      [MergeProfileModalTypes.MERGE]: {
        action: 'Merge',
        cancel: {
          buttonType: ButtonTypes.SECONDARY,
          buttonClass: 'mergeProfileModal-cancel',
          text: 'Cancel',
        },
      },
      [MergeProfileModalTypes.MATCH]: {
        action: 'Match',
        cancel: {
          buttonType: ButtonTypes.TEXT,
          buttonClass: 'mergeProfileModal-cancel-wide',
          text: 'No match',
        },
      },
    };

    return (
      <StyledModalCard className="mergeProfileModal" onClose={onClose}>
        <div className="mergeProfileModal-header">{modalOptions[modalType].action} the correct customer</div>
        <div className="mergeProfileModal-cards">
          <div className="mergeProfileModal-card mergeProfileModal-leftCard">
            <div className="mergeProfileModal-cardTitle">Current Customer</div>
            <div className="mergeProfileModal-cards-list">
              <ProfileCard
                {...currentDetails}
                className="mergeProfileModal-profileCard"
                matchingFields={matches[this.state.selectedCustomerIdx].matchingFields}
              />
            </div>
          </div>
          <div
            className={classnames('mergeProfileModal-card mergeProfileModal-rightCard', {
              'mergeProfileModal-card-wide': matches.length > 1,
            })}
          >
            <div className="mergeProfileModal-cardTitle">
              Potential Match
              {matches.length > 1 ? 'es' : ''}
            </div>
            {this.renderMatches()}
          </div>
        </div>
        {this.renderErrors()}
        <div className="mergeProfileModal-actions">
          <Button
            blurOnClick
            buttonType={modalOptions[modalType].cancel.buttonType}
            className={modalOptions[modalType].cancel.buttonClass}
            onClick={() => {
              this.reportAnalytics('Customer Merge Dialog Cancelled');
              this.onClose();
            }}
            onMouseDown={preventDefault}
          >
            {modalOptions[modalType].cancel.text}
          </Button>
          <Button
            blurOnClick
            buttonType={ButtonTypes.PRIMARY}
            className="mergeProfileModal-merge"
            disabled={!!preventMerging}
            onClick={isMergePending ? _.noop : this.mergeCustomers}
          >
            {isMergePending ? <LoadingSpinner /> : modalOptions[modalType].action}
          </Button>
        </div>
      </StyledModalCard>
    );
  }

  renderMatches() {
    const { matches, isMatchedProfileLoading } = this.props;

    if (matches.length === 1) {
      return (
        <ProfileCard {...matches[0]} className="mergeProfileModal-profileCard" isLoading={isMatchedProfileLoading} />
      );
    }

    return (
      <div className="mergeProfileModal-cards-list">
        {matches.map((match, idx) => {
          const isSelected = idx === this.state.selectedCustomerIdx;
          const className = isSelected
            ? 'mergeProfileModal-profileCard-selected'
            : 'mergeProfileModal-profileCard-multi';

          return (
            <div className="mergeProfileModal-checkbox-card" key={`mergeProfileModal-match${idx}`}>
              <Checklist
                className="mergeProfileModal-checkbox"
                onChange={this.updateSelection}
                optionRenderer={_.noop}
                options={[{ value: idx, isSelected }]}
              />
              <div className="mergeProfileModal-card-withCheckbox">
                <ProfileCard {...match} className={className} onClick={this.updateSelection.bind(this, idx)} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderErrors() {
    const errorMessage = _.get(this.props.mergeError, 'detail');
    if (!errorMessage) return null;

    // Handle multi-line error messages
    const lines = _.trim(errorMessage).split('\n');
    const renderedLines = lines.reduce((acc, line, idx) => {
      if (idx > 0) acc.push(<br key={`${idx}-break`} />);
      acc.push(<span key={`${idx}-span`}>{line}</span>);
      return acc;
    }, []);
    return <div className="mergeProfileModal-error">{renderedLines}</div>;
  }

  updateSelection(value) {
    if (value !== this.state.selectedCustomerIdx && value < this.props.matches.length) {
      this.setState({
        selectedCustomerIdx: value,
      });
    }
  }

  onClose() {
    this.props.onClose({
      modalType: this.props.modalType,
      numMatches: this.props.matches.length,
      shouldClearMatches: true,
    });
  }

  mergeCustomers() {
    this.props.onMerge({
      customerId: this.props.matches[this.state.selectedCustomerIdx].id,
      modalType: this.props.modalType,
      numMatches: this.props.matches.length,
      selectionRank: this.state.selectedCustomerIdx,
    });
  }
}

const DETAILS_SHAPE = PropTypes.shape({
  address: PropTypes.string,
  emails: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  name: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.string),
});

MergeProfileModalBase.propTypes = {
  currentDetails: DETAILS_SHAPE,
  isMatchedProfileLoading: PropTypes.bool,
  isMergePending: PropTypes.bool,
  matches: PropTypes.arrayOf(DETAILS_SHAPE),
  mergeError: PropTypes.instanceOf(Err),
  modalType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired,
  onEmptyModal: PropTypes.func.isRequired,
  onTrackedEvent: PropTypes.func,
  preventMerging: PropTypes.bool,
};

export const StyledModalCard = styled(ModalCard)`
  max-width: min-content;
`;

export const MergeProfileModalContainer = connect(mapStateToProps, mapExecuteToProps)(MergeProfileModalBase);

function mapStateToProps({ getProvider }, { matchedCustomerId, pendingDetail }) {
  const customersProvider = getProvider('customers');
  const customerMergeProvider = getProvider('customerMerge');
  const currentProfile = getProvider('profile').get() || {};
  const customerProfileDef = getProvider('customerProfileDef').get();

  let currentMatches;
  let errorMessage = '';
  let props = {};

  // With a specific customer matched on a unique field (mobile phone/email), modal shows only that customer
  // Otherwise it shows all matches
  if (matchedCustomerId) {
    const matchedProfileProvider = customersProvider.has(matchedCustomerId)
      ? getProvider(`customers.${matchedCustomerId}.profile`)
      : null;

    const matchedProfile = matchedProfileProvider ? matchedProfileProvider.get() || {} : {};
    props.isMatchedProfileLoading = matchedProfileProvider ? matchedProfileProvider.isLoading() : false;
    props.modalType = MergeProfileModalTypes.MERGE;
    currentMatches = [matchedProfile];

    // This may happen in case of "merge collision" when two agents try to merge the same customer at the same time
    if (_.isEmpty(matchedProfile)) {
      props.preventMerging = true;

      // If we are here, the matching profile was not loaded
      if (!props.isMatchedProfileLoading) {
        errorMessage =
          'Potential match is no longer available.\nThis may happen if the potential match was merged with another customer.';
      }
    }
  } else {
    const currentMatchProvider = getProvider('customerMatch');

    currentMatches = currentMatchProvider.get() ? currentMatchProvider.get().profiles : [];
    props.modalType = MergeProfileModalTypes.MATCH;
  }

  const currentEmails = _.map(currentProfile.emails, email => normalizeEmailAddress(email.original));
  const currentPhones = _.map(currentProfile.phones, phone => phone.normalized);

  if (pendingDetail.email) {
    currentEmails.push(pendingDetail.email);
  } else if (pendingDetail.phone) {
    if (_.indexOf(currentPhones, pendingDetail.phone) === -1) {
      currentPhones.push(pendingDetail.phone);
    }
  }

  let attributeKeysToShowWithDef = [];
  if (customerProfileDef) {
    // collect the full set of custom attributes populated on all the profiles (this and every match) combined
    const currentProfilePopulatedAttributes = getKeysWithDefinedValues(currentProfile.customAttributes);
    const populatedAttributesInMatches = currentMatches.map(possibleMatch => {
      return getKeysWithDefinedValues(possibleMatch.customAttributes);
    });
    const allPopulatedAttributes = _.union(currentProfilePopulatedAttributes, ...populatedAttributesInMatches);

    // verify that the attributes are still valid and specified within the profile def
    const attributeDefsByAttribute = _.keyBy(customerProfileDef.attributes, 'attr');
    const definedPopulatedAttributes = _.filter(allPopulatedAttributes, function(attributeKey) {
      return _.has(attributeDefsByAttribute, attributeKey);
    });

    // filter down the attributes for display depending on whether card attributes is defined or not
    let attributeKeysToShow;
    if (customerProfileDef.cardAttributes && _.size(customerProfileDef.cardAttributes) > 0) {
      attributeKeysToShow = _.intersection(customerProfileDef.cardAttributes, definedPopulatedAttributes);
    } else {
      attributeKeysToShow = _.take(definedPopulatedAttributes, 5);
    }

    // join the keys to show with their defs (will need that information for rendering purposes)
    attributeKeysToShowWithDef = _.map(attributeKeysToShow, function(attributeKey) {
      return { attributeKey, customAttributeDef: attributeDefsByAttribute[attributeKey] };
    });
  }

  const currentDetails = {
    address: currentProfile.address,
    emails: currentEmails.length > 0 ? currentEmails : [''],
    id: currentProfile.id,
    name: currentProfile.name,
    phones: currentPhones.length > 0 ? currentPhones : [''],
    customAttributes: getCustomAttributesFilteredByKeyList(currentProfile.customAttributes, attributeKeysToShowWithDef),
  };

  let matches = currentMatches.map(currentMatch => {
    const matchedEmails = _.map(currentMatch.emails, email => normalizeEmailAddress(email.original));
    const matchedPhones = _.map(currentMatch.phones, phone => phone.normalized);

    const match = {
      id: currentMatch.id,
      address: currentMatch.address,
      emails: matchedEmails.length ? matchedEmails : [''],
      name: currentMatch.name,
      phones: matchedPhones.length ? matchedPhones : [''],
      customAttributes: getCustomAttributesFilteredByKeyList(currentMatch.customAttributes, attributeKeysToShowWithDef),
    };

    const matchingFieldsInfo = getMatchingFieldsInfo(
      currentDetails,
      match,
      _.map(attributeKeysToShowWithDef, 'attributeKey')
    );
    match.matchingFields = matchingFieldsInfo.matchingFields;
    match.numMatchingFields = matchingFieldsInfo.numMatchingFields;
    return match;
  });

  // sort by length of matching fields, in descending order
  const sortedMatches = _.sortBy(matches, match => -match.numMatchingFields);

  return {
    ...props,
    currentDetails,
    isMergePending: customerMergeProvider.isPending(),
    matches: _.take(sortedMatches, 5),
    mergeError: errorMessage
      ? new Err({ code: Err.Code.INVALID, detail: errorMessage })
      : _.first(customerMergeProvider.getErrors()),
  };
}

function mapExecuteToProps(executeAction, { currentCustomerId }) {
  return {
    onClose: ({ numMatches, shouldClearMatches, modalType } = {}) => {
      executeAction(CloseMergeProfileModal);

      if (modalType === MergeProfileModalTypes.MATCH && shouldClearMatches) {
        executeAction(ClearMatches, { customerId: currentCustomerId, numMatches });
      }
    },
    onMerge: ({ customerId, selectionRank, numMatches, modalType }) => {
      executeAction(
        MergeCustomers,
        new CustomerMerge({
          sourceCustomerId: customerId,
          destCustomerId: currentCustomerId,
          shouldClearMatches: modalType === MergeProfileModalTypes.MATCH,
        })
      );

      if (modalType === MergeProfileModalTypes.MATCH) {
        executeAction(SelectMatch, { customerId: currentCustomerId, selectionRank, numMatches });
      }
    },
    onEmptyModal: () => {
      executeAction(CloseModal);
    },
    onTrackedEvent: (event, props) => {
      const trackedProps = { customerId: currentCustomerId, ...props };
      executeAction(TrackEvent, { event, props: trackedProps });
    },
  };
}

const MergeProfileModalContainerWithModalProps = connect(mapStateToContainerProps)(MergeProfileModalContainer);
export default MergeProfileModalContainerWithModalProps;

function mapStateToContainerProps({ getProvider }) {
  const profile = getProvider('profile').get();
  const modal = getProvider('modal').get();
  const { customerId, pendingDetail } = modal || {};
  return { currentCustomerId: profile && profile.id, matchedCustomerId: customerId, pendingDetail };
}

function getKeysWithDefinedValues(customAttributes) {
  return _.keys(_.pickBy(customAttributes, _.identity));
}

/**
 * From a map of custom attributes, turn that into a list of custom attributes to use for the merge modal
 * - This will filter the list of custom attributes to be only the ones that should display
 * - also, date types will get transformed into a human friendly format
 * - include a placeholder for the form based on the label of the custom attribute
 *
 * @param {object} customAttributes key/value pairs of custom attributes for a profile
 * @param {object[]} keysToIncludeWithDefs list of { attributeKey, attributeDef } that should be displayed
 * @returns {object[]} a list of display objects for the custom attribute. { value: <value to display>, placeholder: <text if there is no value>}
 */
function getCustomAttributesFilteredByKeyList(customAttributes, keysToIncludeWithDefs) {
  if (!customAttributes) {
    return {};
  }

  return _.reduce(
    keysToIncludeWithDefs,
    function(filteredCustomAttributes, attributeKeyWithDef) {
      const attributeKey = attributeKeyWithDef.attributeKey;
      const customAttributeDef = attributeKeyWithDef.customAttributeDef;

      let value = customAttributes[attributeKey];
      if (value && customAttributeDef.type === AttributeType.DATE) {
        value = moment(value).format('MMM D, YYYY');
      }

      filteredCustomAttributes[attributeKey] = {
        value,
        placeholder: customAttributeDef.label || attributeKey,
      };
      return filteredCustomAttributes;
    },
    {}
  );
}

/**
 * Determine which details contain the same values between `currentDetails` and `matchedDetails`.
 *
 * For nested arrays like phones or emails, an object will be returned with the email / phone being the key,
 * and the value will be `true` depending on whether that email / phone exists in both details.
 *
 * @param {object} currentDetails
 * @param {object} matchedDetails
 * @param {string[]} attributeKeysToShow
 */
function getMatchingFieldsInfo(currentDetails, matchedDetails, attributeKeysToShow) {
  let numMatchingFields = 0;

  const sameEmails = _.compact(_.intersection(currentDetails.emails, matchedDetails.emails));
  const matchingEmails = {};
  _.forEach(sameEmails, email => (matchingEmails[email] = true));
  numMatchingFields += _.size(matchingEmails);

  const samePhones = _.compact(_.intersection(currentDetails.phones, matchedDetails.phones));
  const matchingPhones = {};
  _.forEach(samePhones, phone => (matchingPhones[phone] = true));
  numMatchingFields += _.size(matchingPhones);

  let matchingCustomAttributes = {};
  const overlappingKeys = _.intersection(
    attributeKeysToShow,
    _.keys(currentDetails.customAttributes),
    _.keys(matchedDetails.customAttributes)
  );
  _.each(overlappingKeys, function(customAttributeKey) {
    let profileValue = _.get(currentDetails.customAttributes[customAttributeKey], 'value');

    if (
      !_.isEmpty(profileValue) &&
      profileValue === _.get(matchedDetails.customAttributes[customAttributeKey], 'value')
    ) {
      matchingCustomAttributes[customAttributeKey] = true;
      numMatchingFields = numMatchingFields + 1;
    }
  });

  const trimmedCurrentAddress = _.get(currentDetails, 'address', '').replace(/\s/g, '');
  const trimmedMatchingAddress = _.get(matchedDetails, 'address', '').replace(/\s/g, '');

  const matchedAddress = !!trimmedCurrentAddress && trimmedCurrentAddress === trimmedMatchingAddress;
  const matchedName = !!currentDetails.name && currentDetails.name === matchedDetails.name;
  const matchedExternalCustomerId =
    !!currentDetails.externalCustomerId && currentDetails.externalCustomerId === matchedDetails.externalCustomerId;

  const numMatchingOthers = _.size(_.filter([matchedAddress, matchedName, matchedExternalCustomerId], _.identity));
  numMatchingFields += numMatchingOthers;

  return {
    numMatchingFields,
    matchingFields: {
      address: matchedAddress,
      emails: matchingEmails,
      name: matchedName,
      phones: matchingPhones,
      externalCustomerId: matchedExternalCustomerId,
      customAttributes: matchingCustomAttributes,
    },
  };
}

function preventDefault(evt) {
  evt.preventDefault();
}
