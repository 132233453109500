import React, { useContext, useEffect, useRef } from 'react';

import analytics from 'scripts/lib/analytics';
import HotkeyContext from 'components/contexts/hotkeys';
import Mousetrap from 'components/lib/mousetrap';
import { setDisplayName } from 'scripts/presentation/hoc_helpers/display_name';

/*
  Add keyboard shortcuts to a component by passing in an array of keys and callbacks. When the key is pressed, the
  callback will be called, with the instance of the base component as the first argument.

  Usage:

  ```
  let DecoratedComponent = withShortcuts(BaseComponent, [
    { key: 'a', handler: props => props.someCallback(), label: 'Callback' },
    { key: 'b', handler: props => props.someOtherCallback(), label: 'Callback' },
  ])
  ```
*/

export default function withShortcuts(Component, shortcuts) {
  function ComponentWithShortcuts(props) {
    const { dispatchHotkey } = useContext(HotkeyContext);

    const propsRef = useRef(props);
    useEffect(() => {
      propsRef.current = props;
    });

    useEffect(() => {
      shortcuts.forEach(({ key, handler, label, group }) => {
        Mousetrap.bind(key, () => {
          // We're only running the this `useEffect` _once_ (so that it acts like componentDidMount / Unmount).
          // This means if we just used `props` in the argument here, we'd be capturing the `props` from the first
          // render and always passing that into `handler`, even if the props actually change in subsequent renders.
          // We get around this by using a ref that we update after every render to have the latest props.
          handler(propsRef.current);
          analytics.track('Keyboard Shortcut Used', {
            key,
            label,
            group,
          });
          return false;
        });
        dispatchHotkey({ type: 'add', hotkey: { key, group, label } });
      });

      return () => {
        shortcuts.forEach(({ key }) => {
          Mousetrap.unbind(key);
          dispatchHotkey({ type: 'remove', key });
        });
      };
    }, []);

    return React.createElement(Component, {
      ...props,
    });
  }

  setDisplayName(ComponentWithShortcuts, Component, 'WithShortcuts');
  return ComponentWithShortcuts;
}
