import _ from 'lodash';

export const CONTEXT_SEARCH_RESULT = 'iglu:com.gladly/context_search_result/jsonschema/1-0-0';

export const getSearchResultContexts = results => {
  return _.map(results, (result, idx) => ({
    schema: CONTEXT_SEARCH_RESULT,
    data: {
      entityId: result.id,
      entityType: 'answer',
      position: idx + 1,
    },
  }));
};
