import AgentNotification from 'models/agent_notification/agent_notification';
import createToastFromNotification from 'actions/toast/create_toast_from_notification';

export default class AgentNotificationGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onBroadcast(agentNotificationDto) {
    const notification = AgentNotification.fromJs(agentNotificationDto);

    const isNew = !this.context.stores.agentNotifications.has({ id: notification.id });
    this.context.stores.agentNotifications.addOrReplace(notification);

    if (isNew) {
      createToastFromNotification(this.context, notification);
    }
  }
}
