import createReactClass from 'create-react-class';
import React from 'react';

import T from 'prop-types';

const ChartBar = createReactClass({
  propTypes: {
    className: T.string,
    data: T.array.isRequired, // array of tuples [x,y]
    xScale: T.any.isRequired, // d3 scale for x-axis
    yScale: T.any.isRequired, // d3 scale for y-axis
    width: T.number, // width of entire chart
    yPos: T.number,
  },

  statics: {
    MIN_BAR_HEIGHT: 1,
    MIN_BAR_WIDTH: 1,
    MAX_BAR_WIDTH: 20,
  },

  /* Render */
  render() {
    let barWidth = this.getBarWidth();
    return <g>{this.props.data.map((point, index) => this.renderBar(point, index, barWidth))}</g>;
  },

  renderBar(point, index, barWidth) {
    let barHeight = this.props.yScale(point[1]);

    return (
      <rect
        className={this.props.className}
        height={this.props.yPos - barHeight || ChartBar.MIN_BAR_HEIGHT}
        key={`bar${point[0]}${point[1]}`}
        width={barWidth}
        x={this.props.xScale(point[0]) - barWidth / 2}
        y={barHeight}
      />
    );
  },

  getBarWidth() {
    let barWidth = Math.ceil(this.props.width / (this.props.data.length * 2));
    return barWidth >= ChartBar.MAX_BAR_WIDTH ? ChartBar.MAX_BAR_WIDTH : barWidth;
  },
});

export default ChartBar;
