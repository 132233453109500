import AutoAcceptIncomingCall from 'actions/phone_call/auto_accept_incoming_call';
import ActiveCallConverter from 'scripts/application/dto_converters/active_call_converter';
import addCustomerToInbox from 'actions/inbox/lib/add_customer_to_inbox';
import AgentVoiceCapabilitiesChecker from 'actions/configuration/lib/agent_voice_capabilities_checker';
import Assignee from 'models/assignee';
import {
  checkAndResetNavigatingToNext,
  preloadCustomer,
  removeEmptyQueueNotifications,
} from 'actions/conversation/lib/conversation_workflow';
import cleanupNonCurrentCustomer from 'actions/customer/lib/cleanup_non_current_customer';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import hasLiveConnection from 'actions/phone_call/lib/has_live_connection';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import navigateToConversation from 'actions/conversation/lib/navigate_to_conversation';
import { notificationFromRoutingEvent } from 'actions/notification/lib/notification_converter';
import removeItemFromInbox from 'actions/inbox/lib/remove_item_from_inbox';
import RoutingEventType from 'models/routing_event/routing_event_type';
import ShowConversationSystemNotification from 'actions/notification/show_conversation_system_notification';
import SystemNotificationGenerator from 'scripts/domain/contracts/system_notification_generator';
import tryUpdateInboxItem from 'actions/inbox/lib/try_update_inbox_item';
import UnofferedCallTypes from 'models/routing_event/unoffered_call_type';
import VerifyNewCustomer from 'actions/customer_match/verify_new_customer';
import DismissNotification from 'actions/notification/dismiss_notification';

export default class PhoneCallRoutingEventObserver {
  constructor(context) {
    this.context = context;
    this.agentVoiceCapabilitiesChecker = new AgentVoiceCapabilitiesChecker(this.context);
  }

  handleRoutingEvent(event) {
    switch (event.type) {
      case RoutingEventType.OFFERED_CALL:
      case RoutingEventType.OFFERED_WARM_TRANSFER:
        this.handleOfferedCallEvent(event);
        break;
      case RoutingEventType.UNOFFERED_CALL:
        this.handleUnofferedCallEvent(event);
        break;
      case RoutingEventType.UNOFFERED_WARM_TRANSFER:
        this.handleUnofferedWarmTransferEvent(event);
        break;
      default:
    }
  }

  handleOfferedCallEvent(eventDto) {
    let routingPreferences = this.context.stores.agentRoutingPreferences.get();
    if (routingPreferences.nextCallRoutingTime) {
      routingPreferences.setNextCallRoutingTime('');
      this.context.stores.agentRoutingPreferences.set(routingPreferences);
    }

    let activeCall = ActiveCallConverter.fromDto({
      id: IdGenerator.newId(),
      customer: eventDto.profile,
      conversationId: eventDto.conversationId,
      conversationItem: eventDto.conversationItem,
      routingGroupId: eventDto.routingGroupId,
    });
    let currentAgent = this.context.stores.currentAgent.get();
    let connectionStates = this.context.stores.connectionState.get();

    if (!this.agentVoiceCapabilitiesChecker.canHandlePhoneCalls()) {
      ErrorReporter.reportError(new Error('offered call error'), {
        message: 'Agent can not handle offered call',
        extra: {
          connectionStates,
          phonePreference: currentAgent.getVoiceConfigurationPreference(),
        },
      });
      return;
    }

    if (connectionStates.isTwilioDisconnected()) {
      // report error if agent is routed a call without the Twilio phone gateway setup
      ErrorReporter.reportError(new Error('offered call error'), {
        message: 'phone gateway is disconnected',
        extra: {
          connectionStates,
          phonePreference: currentAgent.getVoiceConfigurationPreference(),
        },
      });
    }

    // Do not enable incoming call notification without live phone connection
    let hasLivePhoneConnection = hasLiveConnection(this.context, { activeCall });

    activeCall.setHasLiveConnection(hasLivePhoneConnection);
    if (currentAgent.isConfiguredForInteractiveVoice() && hasLivePhoneConnection) {
      checkAndResetNavigatingToNext(this.context);
    }
    if (eventDto.initiator) {
      activeCall.setInitiator(new Assignee(eventDto.initiator));
    }
    this.context.stores.activeCall.set(activeCall);
    this.context.gateways.customerHistoryMerge.subscribe(activeCall.customer.id);
    this.context.gateways.conversationItem.request(activeCall.customer.id, activeCall.conversationItem.id);

    preloadCustomer(this.context, activeCall.customer.id);
    addCustomerToInbox(this.context, { customerId: activeCall.customer.id });
    tryUpdateInboxItem(this.context, {
      item: activeCall.conversationItem,
      profile: activeCall.customer,
    });

    removeEmptyQueueNotifications(this.context);
    if (!currentAgent.isConfiguredForInteractiveVoice() && currentAgent.usesExternalHandset()) {
      activeCall.setAgentHasAcceptedCall();
      this.context.stores.activeCall.set(activeCall);

      if (!activeCall.customer.isVerified()) {
        this.context.executeAction(VerifyNewCustomer, {
          customerId: activeCall.customer.id,
        });
      }
      navigateToConversation(this.context, {
        customerId: activeCall.customer.id,
        conversationId: activeCall.conversationId,
      });
    }

    // Only show the system notification for live calls
    if (hasLivePhoneConnection) {
      const notification = notificationFromRoutingEvent(eventDto);
      this.context.executeAction(ShowConversationSystemNotification, notification);
      this.context.executeAction(AutoAcceptIncomingCall, { activeCall, currentAgentId: currentAgent.id });
    }
  }

  handleUnofferedCallEvent(eventDto) {
    const activeCall = this.context.stores.activeCall.get();
    const currentAgent = this.context.stores.currentAgent.get();
    let shouldDisplayUnofferedCallNotification = true;
    if (activeCall) {
      shouldDisplayUnofferedCallNotification = activeCall.shouldDisplayIncomingCallNotification(currentAgent.id);
    }

    this.cleanUpActiveCall(eventDto.conversationItem.id);
    SystemNotificationGenerator.closeNotification(eventDto.conversationItem.id);

    this.context.capacityManager.postponeCallRouting();

    if (!this.agentVoiceCapabilitiesChecker.canHandlePhoneCalls()) {
      return;
    }

    if (
      [UnofferedCallTypes.AGENT_TIMEOUT, UnofferedCallTypes.CALLER_ABANDONED, UnofferedCallTypes.CALL_ERROR].includes(
        eventDto.reason
      ) &&
      shouldDisplayUnofferedCallNotification
    ) {
      const notification = notificationFromRoutingEvent(eventDto);
      this.context.stores.notifications.addOrReplace(notification);
      if ([UnofferedCallTypes.AGENT_TIMEOUT, UnofferedCallTypes.CALL_ERROR].includes(eventDto.reason)) {
        DismissNotification.schedule(this.context, { id: notification.id });
      }
    }
  }

  handleUnofferedWarmTransferEvent(eventDto) {
    let activeCall = this.context.stores.activeCall.get();
    const currentAgent = this.context.stores.currentAgent.get();
    let shouldDisplayUnofferedCallNotification = true;
    if (activeCall) {
      shouldDisplayUnofferedCallNotification = activeCall.shouldDisplayIncomingCallNotification(currentAgent.id);
    }
    this.cleanUpActiveCall(eventDto.conversationItem.id);
    SystemNotificationGenerator.closeNotification(eventDto.conversationItem.id);

    if (!this.agentVoiceCapabilitiesChecker.canHandlePhoneCalls()) {
      return;
    }
    if (shouldDisplayUnofferedCallNotification) {
      const notification = notificationFromRoutingEvent(eventDto);
      this.context.stores.notifications.addOrReplace(notification);
    }
  }

  cleanUpActiveCall(conversationItemId) {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall && activeCall.conversationItem.id === conversationItemId) {
      cleanupNonCurrentCustomer(this.context, activeCall.customer.id);
      this.removeActiveCall(activeCall);
      removeItemFromInbox(this.context, activeCall.customer.id);
    }
  }

  removeActiveCall(activeCall) {
    this.context.stores.activeCall.remove();
    this.context.gateways.activeCall.cancelFetches();
    this.context.gateways.customerHistoryMerge.unsubscribe(activeCall.customer.id);
    this.context.gateways.conversationItem.unsubscribe(activeCall.customer.id, activeCall.conversationItem.id);
  }
}
