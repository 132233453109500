import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import SnippetSearch from 'scripts/infrastructure/backends/fake_backend/snippet_search_service';

export default class SnippetSearchService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
    this.snippetSearch = new SnippetSearch(this.pubsub, this.getDatabase);
  }

  find(payload, callback, path, { orgId }) {
    let results = this.snippetSearch.request(orgId, { payload });
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: results,
    });
  }

  getRoutes() {
    return bindCallbacks({ '/api/v1/orgs/:orgId/search/snippets': { POST: this.find } }, this);
  }
}
