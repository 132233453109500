import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { usePlateEditorRef } from '@udecode/plate';
import { ReactEditor } from 'slate-react';
import { Editor, Text } from 'slate';

import AITextCompletionContext from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import {
  Box,
  Content,
  Options,
  LoadingContent,
  PrimaryButton,
  SecondaryButton,
} from './ai_text_completion_preview_styles';
import CancelAITextCompletion from 'actions/ai_text_completions/cancel_ai_text_completion';
import ComposerContext from 'components/composer/contexts/composer_context';
import CustomerContext from 'components/customer/customer_context';
import EditorScrollContext from 'components/composer/shared/editor_scroll_context';
import FakeTyping from 'components/text_editor_new/plugins/ai/components/fake_typing';
import LoadingDots from 'components/lib/loading_dots';
import { MARK_AI_TEXT_COMPLETION_SELECTION } from 'components/text_editor_new/plugins/ai/ai_text_completion';
import RetryAITextCompletion from 'actions/ai_text_completions/retry_ai_text_completion';
import UseAITextCompletion from 'actions/ai_text_completions/use_ai_text_completion';
import useExecuteAction from 'components/hooks/use_execute_action';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function AITextCompletionPreviewWrapper() {
  const { completion, isLoading, requestorId } = useContext(AITextCompletionContext);
  if ((!isLoading && !completion) || !requestorId) {
    return null;
  }

  if (isLoading) {
    return <AITextCompletionLoading />;
  }

  return <AITextCompletionPreview completion={completion} />;
}

export function AITextCompletionPreview({ completion }) {
  const editor = usePlateEditorRef();
  const { replace, tryAgain, cancel } = useCompletionActions();
  const boxRef = useRef(null);
  const isFeatureEnabled = useIsFeatureEnabled();
  const gclDemoEnabled = isFeatureEnabled('gclDemo2023');

  usePositionPreview(editor, boxRef);
  useScrollToPreview(boxRef, completion);

  const text = completion?.data?.text || '';

  return (
    <Box data-aid="textCompletionPreview" ref={boxRef}>
      <Content>{gclDemoEnabled ? <FakeTyping text={text} /> : text}</Content>
      <Options>
        <PrimaryButton onClick={replace}>Use 'tab'</PrimaryButton>
        <SecondaryButton onClick={tryAgain}>Retry 'r'</SecondaryButton>
        <SecondaryButton onClick={cancel}>Cancel 'esc'</SecondaryButton>
      </Options>
    </Box>
  );
}

function AITextCompletionLoading() {
  const editor = usePlateEditorRef();
  const boxRef = useRef(null);
  usePositionPreview(editor, boxRef);
  useScrollToPreview(boxRef, null);

  return (
    <Box ref={boxRef}>
      <LoadingContent>
        Got it! Writing <LoadingDots />
      </LoadingContent>
    </Box>
  );
}

function usePositionPreview(editor, boxRef) {
  useLayoutEffect(() => {
    if (!boxRef.current) {
      return;
    }

    const lastNode = getLastSelectionNode(editor);
    if (!lastNode) {
      return;
    }

    const domNode = ReactEditor.toDOMNode(editor, lastNode);
    if (domNode) {
      // Position the text assist box right below the last AI Text rewrite selection node
      const targetRect = domNode.getBoundingClientRect();
      const top = `${domNode.offsetTop + targetRect.height + 8}px`;
      boxRef.current.style.top = top;
      boxRef.current.style.position = 'absolute';
    }
  });
}

function getLastSelectionNode(editor) {
  for (const [node] of Editor.nodes(editor, {
    match: n => Text.isText(n) && n[MARK_AI_TEXT_COMPLETION_SELECTION],
    reverse: true,
  })) {
    return node;
  }
  return null;
}

// Each time the completion changes, we want to smoothly scroll the box into view so that the agent
// can see the completion.
function useScrollToPreview(boxRef, completion) {
  const parentScrollNode = useContext(EditorScrollContext);

  useLayoutEffect(() => {
    if (!boxRef.current || !parentScrollNode) {
      return;
    }

    const timeoutId = setTimeout(() => boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' }), 10);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [boxRef, completion, parentScrollNode]);
}

function useCompletionActions() {
  const { completion, requestorId, setTextToInsert } = useContext(AITextCompletionContext);
  const { customerId } = useContext(CustomerContext);
  const { composition } = useContext(ComposerContext);
  const executeAction = useExecuteAction();

  const compositionRef = useRef(composition);
  useEffect(() => {
    compositionRef.current = composition;
  }, [composition]);

  // Cancel the text completion if the component or customer being rendered changes
  useEffect(() => {
    return () => {
      executeAction(CancelAITextCompletion, { customerId, requestorId, composition, source: 'rerender' });
    };
    // Note we only want to cancel the AI text completion if customerId or compositionId changes.. we don't
    // want to listen strictly to composition changes.. because the composition model updates pretty frequently.
  }, [executeAction, customerId, requestorId, composition.id]);

  const replace = useCallback(() => {
    const text = completion?.data?.text;
    if (text) {
      setTextToInsert(text);
      executeAction(UseAITextCompletion, { customerId, requestorId, composition: compositionRef.current, text });
    } else {
      executeAction(CancelAITextCompletion, {
        customerId,
        requestorId,
        composition: compositionRef.current,
        source: 'blank_text',
      });
    }
  }, [executeAction, completion, setTextToInsert, customerId, requestorId, compositionRef]);

  const tryAgain = useCallback(() => {
    executeAction(RetryAITextCompletion, { customerId, requestorId, composition: compositionRef.current });
  }, [executeAction, customerId, requestorId, compositionRef]);

  const cancel = useCallback(() => {
    executeAction(CancelAITextCompletion, {
      customerId,
      requestorId,
      composition: compositionRef.current,
      source: 'user',
    });
  }, [executeAction, customerId, requestorId, compositionRef]);

  // Handle keyboard shortcuts for using text completion
  useEffect(() => {
    function onKeyDown(e) {
      if (e.ctrlKey || e.altKey || e.metaKey) {
        return;
      }

      if (e.key === 'Tab') {
        e.preventDefault();
        replace();
      } else if (e.key && e.key.toLowerCase() === 'r') {
        e.preventDefault();
        tryAgain();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        cancel();
      }
    }

    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [replace, tryAgain, cancel]);

  return { completion, replace, tryAgain, cancel };
}
