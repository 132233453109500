import _ from 'lodash';
import classnames from 'classnames';
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import { AttributePresentation } from 'models/configuration/attribute_def';
import connect from 'components/lib/connect';
import CustomerContext from 'components/customer/customer_context';
import CustomerProfileDef from 'models/customer_profile_def';
import CustomerName from './customer_name';
import CustomerInformationAvatar from './customer_information_avatar';
import CustomerSummaryMenu from 'components/customer/summary/customer_summary_menu';
import ExpandableSummaryCard from './lib/expandable_summary_card';
import ExternalCustomerLookup from 'models/location/external_customer_lookup';
import {
  getAttributeDefsToShow,
  renderCustomAttributes,
  renderCustomAttributesSubgroup,
} from './lib/custom_attributes_helper';
import { MatchTooltipContainer } from 'components/customer/profile/contact_info/mergeable_error_tooltip';
import NavigateTo from 'actions/current_location/navigate_to';
import ProfileCardDef from 'models/configuration/profile_card_def';
import ProfileCardType from 'models/configuration/profile_card_type';
import { SummaryHeader } from '../summary/lib/summary_card';

export function CustomerInformation({
  customAttributes,
  customerId,
  customerProfileDef,
  hasNoProfile,
  isLoading,
  lookupSuggestionsCount,
  onClickCustomerLookup,
  profileCardDef,
  shouldShowLookupLink,
}) {
  if (hasNoProfile) {
    return null;
  }

  const attrDefs = getAttributeDefsToShow(profileCardDef, customerProfileDef, customAttributes);
  return (
    <ExpandableSummaryCard
      className="customerInformationCard"
      footer={renderFooter()}
      header={renderHeader()}
      isLoading={isLoading}
    >
      {renderCustomAttributes(attrDefs, customAttributes, ProfileCardType.BASIC_PROFILE)}
      {renderCustomAttributesSubgroup(
        profileCardDef,
        customerProfileDef,
        customAttributes,
        ProfileCardType.BASIC_PROFILE
      )}
    </ExpandableSummaryCard>
  );

  function renderFooter() {
    if (!shouldShowLookupLink) {
      return null;
    }

    return (
      <div className="customerInformationCard-footer" onClick={onClickCustomerLookup}>
        <span>
          <span className="customerInformationCard-externalLookupIcon">
            <FontAwesomeIcon icon={faAddressBook} />
          </span>{' '}
          Link to external customer
        </span>
        {renderSuggestions()}
      </div>
    );
  }

  function renderSuggestions() {
    if (!lookupSuggestionsCount) {
      return null;
    }

    return (
      <span className="customerInformationCard-footer-suggestionsHighlight">
        {lookupSuggestionsCount} suggestion
        {lookupSuggestionsCount > 1 ? 's' : ''}
      </span>
    );
  }

  function renderHeader() {
    const classNames = classnames('customerInformationCard-header', {
      'customerInformationCard-header-empty': !attrDefs.length,
    });

    return (
      <SummaryHeader className={classNames}>
        <CustomerInformationAvatar />
        <MatchTooltipContainer className="customerInformationCard-matchTooltip" customerId={customerId}>
          <CustomerName isReadOnly={isNameReadOnly()} />
        </MatchTooltipContainer>
        <CustomerSummaryMenu customerId={customerId} />
      </SummaryHeader>
    );
  }

  function isNameReadOnly() {
    const contactAttributes = (customerProfileDef && customerProfileDef.contactAttributes) || [];
    const nameAttribute = _.find(contactAttributes, contactAttr => contactAttr.attr === 'name');
    return nameAttribute && nameAttribute.presentation === AttributePresentation.VISIBLE;
  }
}

CustomerInformation.propTypes = {
  customAttributes: PropTypes.object,
  customerId: PropTypes.string,
  customerProfileDef: PropTypes.instanceOf(CustomerProfileDef),
  hasNoProfile: PropTypes.bool,
  isCustomAttributesEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  lookupSuggestionsCount: PropTypes.number,
  onClickCustomerLookup: PropTypes.func,
  profileCardDef: PropTypes.instanceOf(ProfileCardDef).isRequired,
  shouldShowLookupLink: PropTypes.bool,
};

const CustomerInformationContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerInformation);
export { CustomerInformationContainer };

CustomerInformationContainer.propTypes = {
  profileCardDef: PropTypes.instanceOf(ProfileCardDef).isRequired,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, { customerId }) {
  const profile = getProvider('profile').get();
  const customerProfileDefProvider = getProvider('customerProfileDef');
  const isLoading = getProvider('profile').isLoading() || customerProfileDefProvider.isLoading();
  const lookupSuggestions = getProvider('externalLookupSuggestions').get();

  const integrations = getProvider('integrations').findAll();
  const unlinkedIntegrations = _.filter(integrations, integration => {
    const isNotLinked = _.isEmpty(_.get(profile, `externalCustomerIds.${integration.id}`));
    return integration.customerLookupSettings && integration.enabled && isNotLinked;
  });

  const currentLocation = getProvider('currentLocation').get();

  const shouldShowLookupLink = !currentLocation.isCustomerExtensionPending && unlinkedIntegrations.length > 0;

  const lookupSuggestionsCount = lookupSuggestions
    ? _.flatten(_.values(lookupSuggestions.suggestionsByIntegrationId)).length
    : 0;

  return {
    customAttributes: profile && profile.customAttributes,
    customerId,
    customerProfileDef: customerProfileDefProvider.get(),
    hasNoProfile: !isLoading && !profile,
    isLoading,
    lookupSuggestionsCount,
    shouldShowLookupLink,
  };
}

function mapExecuteToProps(executeAction, { customerId }) {
  return {
    onClickCustomerLookup: () => executeAction(NavigateTo, ExternalCustomerLookup.create({ customerId })),
  };
}

export function CustomerInformationContainerWithCustomer({ profileCardDef }) {
  return (
    <CustomerContext.Consumer>
      {({ customerId }) => <CustomerInformationContainer customerId={customerId} profileCardDef={profileCardDef} />}
    </CustomerContext.Consumer>
  );
}

CustomerInformationContainerWithCustomer.propTypes = {
  profileCardDef: PropTypes.instanceOf(ProfileCardDef).isRequired,
};

export default React.memo(CustomerInformationContainerWithCustomer);
