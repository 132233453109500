import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('UnofferedWarmTransferNotification');

Factory.define('UnofferedWarmTransferNotificationWithDefaults')
  .extend('UnofferedWarmTransferNotification')
  .attr('id', 'call-id')
  .attr('conversationItem', () => Factory.attributes('PhoneCallItemWithDefaults'))
  .attr('type', 'UNOFFERED_WARM_TRANSFER');
