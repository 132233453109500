import CreateComposition from './lib/create_composition';
import ChatCompositionContent from 'models/composition/chat_composition_content';
import { getActiveChatSessionIdFromHistory } from 'actions/communication_session/session_finder';
import InteractionType from 'models/interaction_type';

export default class CreateChatComposition extends CreateComposition {
  constructor(context) {
    super(context, ChatCompositionContent);
  }

  _createContent() {
    let chatSession = this._getActiveChatSession();
    if (!chatSession) {
      let sessionId = getActiveChatSessionIdFromHistory(this.customerStores);
      return (
        sessionId &&
        new ChatCompositionContent({
          sessionId,
        })
      );
    }
    return new ChatCompositionContent({
      sessionId: chatSession.id,
    });
  }

  _getActiveChatSession() {
    let activeSession = this.context.stores.activeSessions.findBy({ customer: { id: this.customerId } });
    return activeSession && activeSession.queueItem.getSessionByType(InteractionType.CHAT);
  }

  _canCreateComposition() {
    const hasExternalAgentActions = this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
    if (!hasExternalAgentActions) {
      return false;
    }
    return this._getActiveChatSession() || !!getActiveChatSessionIdFromHistory(this.customerStores);
  }
}
