import ChartAxis from './chart_axis';
import createReactClass from 'create-react-class';
import d3 from 'd3';
import moment from 'moment';
import React from 'react';
import { AggregationLevel } from 'models/reports/report_configs';

import T from 'prop-types';

const ChartDateAxis = createReactClass({
  propTypes: {
    aggregationLevel: T.string,
    endAt: T.number,
    startAt: T.number,
    isUtc: T.bool,
  },

  render() {
    let units = this.getTickUnit();
    return (
      <ChartAxis
        {...this.props}
        getAxisTicks={() => {
          let ticks = this.props.scale.ticks(units.time, units.interval);
          let isTooMany = ticks.length > 14;
          return !isTooMany ? ticks : ticks.filter((d, index) => index % 3 === 0);
        }}
        tickFormat={date =>
          this.props.isUtc
            ? moment(date)
                .utc()
                .format(units.format)
            : moment(date)
                .local()
                .format(units.format)
        }
        tickLabel={this.getTickLabel()}
      />
    );
  },

  getTickLabel() {
    return this.props.aggregationLevel === AggregationLevel.WEEK && this.canShowWeekly() ? 'Week of ' : '';
  },

  getTickUnit() {
    let numWeeks = moment(this.props.endAt).diff(this.props.startAt, 'weeks');

    let hourly1 = { interval: 1, format: 'ha' };
    let hourly3 = { interval: 3, format: 'ha' };
    let daily = { interval: 1, format: 'MMM D' };
    let weekly = { tinterval: 1, format: 'MMM D' };
    let monthly = { interval: 1, format: 'MMM YYYY' };
    let monthly3 = { interval: 3, format: 'MMM YYYY' };
    let yearly = { interval: 1, format: 'YYYY' };

    if (this.props.isUtc) {
      hourly1.time = d3.time.hour.utc;
      hourly3.time = d3.time.hour.utc;
      daily.time = d3.time.day.utc;
      weekly.time = d3.time.monday.utc;
      monthly.time = d3.time.month.utc;
      monthly3.time = d3.time.month.utc;
      yearly.time = d3.time.year.utc;
    } else {
      hourly1.time = d3.time.hour;
      hourly3.time = d3.time.hour;
      daily.time = d3.time.day;
      weekly.time = d3.time.monday;
      monthly.time = d3.time.month;
      monthly3.time = d3.time.month;
      yearly.time = d3.time.year;
    }

    switch (this.props.aggregationLevel) {
      case AggregationLevel.DAY:
        if (numWeeks <= 6) {
          return daily;
        }
      /* falls through */
      case AggregationLevel.WEEK:
        if (this.canShowWeekly()) {
          return weekly;
        }
      /* falls through */
      case AggregationLevel.MONTH:
        if (numWeeks <= 50) {
          return monthly;
        }
      /* falls through */
      case AggregationLevel.QUARTER:
        return numWeeks <= 144 ? monthly3 : yearly;
      default:
        if (numWeeks === 0) {
          let numHours = moment(this.props.endAt).diff(this.props.startAt, 'hours');
          if (numHours <= 8) {
            return hourly1;
          }
          return hourly3;
        }
        return daily;
    }
  },

  canShowWeekly() {
    return moment(this.props.endAt).diff(this.props.startAt, 'weeks') <= 14;
  },
});

export default ChartDateAxis;
