import changeCurrentLocation from 'actions/lib/change_current_location';
import WebhooksAdmin from 'models/location/integrations/webhooks_admin';

export default class OpenWebhooks {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.gateways.webhook.requestAll();

    this.context.stores.webhooks.setLoading();

    changeCurrentLocation(this.context, WebhooksAdmin.create());
  }
}
