import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledNavLinkBase, StyledNavLink } from 'components/common/nav/nav_link_styles';

import Link from 'components/common/link';

const StyledLink = styled(Link)`
  ${StyledNavLinkBase};
`;

/**
 * @visibleName Nav Link
 */
const NavLink = ({ children, className = '', 'data-aid': dataAid = 'pageLink', isActive, onClick, to }) => {
  return (
    <StyledNavLink className={className} data-aid={dataAid} isActive={isActive}>
      <StyledLink href={to} key={`reportHeaderTab-reportUrl`} onClick={onClick}>
        {children}
      </StyledLink>
    </StyledNavLink>
  );
};

NavLink.propTypes = {
  /** Child components of the NavLink, usually a link component */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** CSS class names to allow custom styling */
  className: PropTypes.string,
  /** Boolean for if a link should have isActive styling applied */
  isActive: PropTypes.bool,
  /** The label for the PageLink anchor */
  label: PropTypes.string,
  /** The onClick function for the Link */
  onClick: PropTypes.func,
  /** The url destination of the PageLink */
  to: PropTypes.string,
};

export { NavLink, StyledLink, StyledNavLinkBase };
