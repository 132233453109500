import ModalClasses from './modal_classes';
import TypeReflect from 'models/lib/type_reflect';

export const typeReflect = new TypeReflect(ModalClasses);

export default function(modal) {
  function getModalClass(modalName) {
    try {
      return typeReflect.typeToConstructor(modalName);
    } catch (e) {
      throw new Error(`unknown modal [${modalName}]`);
    }
  }

  return getModalClass(modal.type).fromJs(modal);
}
