import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import KeypressEventHandler from 'components/lib/dom_controls/lib/keypress_event_handler';

const NumericInput = createReactClass({
  propTypes: {
    className: PropTypes.string,
    onCancel: PropTypes.func,
    onChange: PropTypes.func, // Will fire every time the value of the input changes
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onSubmit: PropTypes.func, // Will fire after blurring / hitting enter
    placeholder: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    value: PropTypes.number,
    allowFloat: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      maxValue: 10000,
      minValue: 0,
    };
  },

  getInitialState() {
    return { workingValue: this.props.value };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.workingValue) {
      this.setState({ workingValue: nextProps.value });
    }
  },

  focus() {
    this.input.focus();
  },

  selectAll() {
    this.input.setSelectionRange(0, this.input.value.length);
  },

  handleChange(evt) {
    const { allowFloat, onChange } = this.props;
    const re = allowFloat ? /^\d+(\.)?(\d+)?$/ : /^[0-9\b]+$/;
    if (evt.target.value === '' || re.test(evt.target.value)) {
      let value = allowFloat ? evt.target.value : parseInt(evt.target.value);
      value = isNaN(value) ? null : value;
      this.setState({ workingValue: value });

      if (value && onChange) {
        this.submit(value);
      }
    } else {
      evt.preventDefault();
      evt.stopPropagation();
    }
  },

  handleBlur() {
    this.setState({ isFocused: false });
    if (this.ignoreBlur) {
      this.ignoreBlur = false;
      return;
    }

    if (this.state.workingValue !== this.props.value) {
      this.submit();
    }
  },

  handleFocus(evt) {
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
    this.setState({ isFocused: true });
  },

  handleSubmit(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.ignoreBlur = true;
    evt.target.blur();
    this.submit();
    this.setState({ isFocused: false });
  },

  handleCancel(evt) {
    this.setState({ isFocused: false, workingValue: this.props.value });
    this.ignoreBlur = true;
    evt.target.blur();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  },

  submit(submittedValue = this.state.workingValue) {
    let value = this.props.allowFloat ? parseFloat(submittedValue) : submittedValue;

    if (this.props.minValue && value < this.props.minValue && value !== null) {
      value = this.props.minValue;
    } else if (this.props.maxValue && value > this.props.maxValue) {
      value = this.props.maxValue;
    } else if (value === this.props.value) {
      return;
    }
    this.setState({ workingValue: value });

    if (this.props.onSubmit) {
      this.props.onSubmit(value);
    } else if (this.props.onChange) {
      this.props.onChange(value);
    }
  },

  render() {
    const keypressEventHandler = new KeypressEventHandler(this.handleSubmit, this.handleCancel, { isInputField: true });
    let value =
      this.state.workingValue !== undefined && this.state.workingValue !== null ? this.state.workingValue : '';
    return (
      <input
        className={this.props.className}
        maxLength={this.props.maxValue.toString().length}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onClick={this.props.onClick}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
        placeholder={this.props.placeholder}
        ref={node => (this.input = node)}
        value={value}
        {...keypressEventHandler.reactAttributes()}
      />
    );
  },
});

export default NumericInput;
