import _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

import ActivateComposition from 'actions/composition/activate_composition';
import Button from 'components/common/button';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import ChevronDownIcon from 'components/lib/icons/chevron_down_icon';
import CoBrowseOutlineIcon from 'components/lib/icons/cobrowse_outline_icon';
import Composition from 'models/composition';
import CompositionContentType from 'models/composition/composition_content_type';
import CompositionIconClasses from 'components/lib/composition_icon_classes';
import connect from 'components/lib/connect';
import ConversationItemType from 'models/conversation_item_type';
import CreditCardStrokeIcon from 'components/common/icons/stroke/credit-card-stroke';
import EmailStrokeIcon from 'components/common/icons/stroke/email-stroke';
import FacebookMessengerStrokeIcon from 'components/common/icons/stroke/facebook-messenger-stroke';
import { getBrandIconByName } from 'components/common/custom_channels';
import HotkeyLabel from 'components/hotkeys/hotkey_label';
import InstagramStrokeIcon from 'components/common/icons/stroke/instagram-stroke';
import InteractionType from 'models/composition/interaction_type';
import NoteStrokeIcon from 'components/common/icons/stroke/note-stroke';
import SmsStrokeIcon from 'components/common/icons/stroke/sms-stroke';
import TaskStrokeIcon from 'components/common/icons/stroke/task-stroke';
import TicketIcon from 'components/lib/icons/ticket_icon';
import Tooltip from 'components/common/tooltip';
import TwitterFillIcon from 'components/common/icons/fill/twitter-fill';
import VoiceStrokeIcon from 'components/common/icons/stroke/voice-stroke';
import WhatsappStrokeIcon from 'components/common/icons/stroke/whatsapp-stroke';

export function CompositionTabs({ compositionId, compositions, isMinimized, onClickTab }) {
  return (
    <StyledTabs>
      {compositions.map(c => (
        <CompositionTab
          composition={c}
          isActive={c.id === compositionId}
          isMinimized={isMinimized}
          key={c.id}
          onClickTab={onClickTab}
        />
      ))}
    </StyledTabs>
  );
}
CompositionTabs.propTypes = {
  compositionId: PropTypes.string.isRequired,
  compositions: PropTypes.arrayOf(PropTypes.instanceOf(Composition)),
  isMinimized: PropTypes.bool,
  onClickTab: PropTypes.func.isRequired,
};

export function CompositionTab({ composition, isActive, isMinimized, onClickTab }) {
  // because of the new conversation message model, if the content type is a conversation
  // message then use channel to get interaction type else, just use composition.contentType()
  const tabRef = useRef();
  const [, rerender] = useState({});
  const setRef = useCallback(el => {
    tabRef.current = el;
    rerender();
  }, []);

  const interactionType =
    composition.contentType() === ConversationItemType.CONVERSATION_MESSAGE
      ? composition.content.channel
      : composition.contentType();

  const tabIcon =
    interactionType === 'CUSTOM_CHANNEL' ? (
      <CustomChannelTabIconContainer customChannelId={composition.content?.customChannelId} />
    ) : (
      <CompositionTabIcon interactionType={interactionType} message />
    );

  let tab = (
    <StyledTab ref={setRef} role="presentation">
      <TabButton
        active={isActive}
        className={_.kebabCase(interactionType)}
        data-aid="composition-tab"
        onClick={onClick}
      >
        <ChevronIcon minimized={isMinimized ? 'minimized' : 'expanded'} />
        {tabIcon}
      </TabButton>
    </StyledTab>
  );

  if (isActive) {
    tab = (
      <Tooltip
        delay={800}
        message={<HotkeyLabel hotkey="alt+h" label="Show/hide" />}
        position="top"
        targetElement={tabRef.current}
      >
        {tab}
      </Tooltip>
    );
  }

  return (
    <React.Fragment>
      <TabSpacer />
      {tab}
    </React.Fragment>
  );

  function onClick(evt) {
    evt.preventDefault();
    onClickTab(composition);
  }
}
CompositionTab.propTypes = {
  composition: PropTypes.instanceOf(Composition).isRequired,
  isActive: PropTypes.bool,
  isMinimized: PropTypes.bool,
  onClickTab: PropTypes.func.isRequired,
};

const CustomChannelTabIcon = ({ customChannels, customChannelId }) => {
  const customChannel = _.find(customChannels, { id: customChannelId });

  return <>{getBrandIconByName(customChannel?.icon, { letter: customChannel?.name, stroke: true }).icon}</>;
};
const CustomChannelTabIconContainer = connect(mapStateToPropsCustomChannelIcon)(CustomChannelTabIcon);

function mapStateToPropsCustomChannelIcon({ getProvider }) {
  return { customChannels: getProvider('customChannels').findAll() };
}

export function CompositionTabIcon({ interactionType }) {
  const preClassNames = 'icon';
  const iconClasses = classnames(preClassNames, CompositionIconClasses[interactionType]);

  switch (interactionType) {
    case InteractionType.INSTAGRAM_DIRECT:
      return <InstagramIcon className="compositionTabs-instagramIcon" />;
    case InteractionType.CHAT:
      return <ChatIcon className="compositionTabs-chatIcon" />;
    case CompositionContentType.EXTERNAL_FORM:
      return <StyledTicketIcon className="compositionTabs-ticketIcon" />;
    case CompositionContentType.PHONE_CALL:
      return <VoiceIcon className="compositionTabs-voiceIcon" />;
    case CompositionContentType.EMAIL:
      return <EmailIcon className="compositionTabs-emailIcon" />;
    case CompositionContentType.FB_MESSAGE:
      return <FacebookMessengerIcon className="compositionTabs-facebookIcon" />;
    case CompositionContentType.SMS:
      return <SmsIcon className="compositionTabs-smsIcon" />;
    case CompositionContentType.WHATSAPP:
      return <WhatsappIcon className="compositionTabs-whatsappIcon" />;
    case CompositionContentType.TWITTER:
      return <TwitterIcon className="compositionTabs-twitterIcon" />;
    case CompositionContentType.CONVERSATION_NOTE:
      return <NoteIcon className="compositionTabs-noteIcon" />;
    case CompositionContentType.TASK:
    case CompositionContentType.TASK_EDIT:
      return <TaskIcon className="compositionTabs-taskIcon" />;
    case CompositionContentType.CREDIT_CARD_REQUEST:
      return <CreditCardIcon className="compositionTabs-creditCardIcon" />;
    case CompositionContentType.COBROWSE_WIDGET:
      return <CoBrowseIcon className="compositionTabs-cobrowseIcon" />;

    default:
      return <Icon className={iconClasses} />;
  }
}

CompositionTabIcon.propTypes = {
  interactionType: PropTypes.string.isRequired,
};

const CompositionTabsContainer = connect(mapStateToProps, mapExecuteToProps)(CompositionTabs);

CompositionTabsContainer.propTypes = {
  conversationId: PropTypes.string,
};

export default CompositionTabsContainer;

function mapStateToProps({ getProvider }, { conversationId }) {
  const compositionsProvider = getProvider('compositions');

  return {
    compositions: _.filter(compositionsProvider.findAll(), c => !compositionsProvider.isPendingDelete(c.id)),
    isMinimized: getProvider('currentLocation').get().isCompositionMinimized,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onClickTab: composition => executeAction(ActivateComposition, { composition }),
  };
}

const commonIconStyle = css`
  position: absolute;
  transition: opacity 150ms ease;
  width: 22px;
`;

const chevronCommon = css`
  stroke: ${p => p.theme.colors.green400};
  fill: ${p => p.theme.colors.green400};
  height: 16px;
  width: 16px;
  line-height: 0;
`;
const ChevronIcon = styled(ChevronDownIcon)`
  opacity: 0;
  position: absolute;
  transition: opacity 150ms ease, transform 150ms ease;
  ${chevronCommon};

  ${p => (p.minimized === 'minimized' ? 'transform: rotate(180deg)' : '')};
`;

const ChatIcon = styled(ChatIconOutline)`
  ${commonIconStyle}
`;

const CreditCardIcon = styled(CreditCardStrokeIcon)`
  ${commonIconStyle}
`;

const VoiceIcon = styled(VoiceStrokeIcon)`
  ${commonIconStyle}
`;

const EmailIcon = styled(EmailStrokeIcon)`
  ${commonIconStyle}
`;

const FacebookMessengerIcon = styled(FacebookMessengerStrokeIcon)`
  ${commonIconStyle}
`;

const SmsIcon = styled(SmsStrokeIcon)`
  ${commonIconStyle}
`;

const WhatsappIcon = styled(WhatsappStrokeIcon)`
  ${commonIconStyle}
`;

const TwitterIcon = styled(TwitterFillIcon)`
  ${commonIconStyle}
`;

const NoteIcon = styled(NoteStrokeIcon)`
  ${commonIconStyle}
`;

const TaskIcon = styled(TaskStrokeIcon)`
  ${commonIconStyle}
`;

const InstagramIcon = styled(InstagramStrokeIcon)`
  ${commonIconStyle}
`;

const CoBrowseIcon = styled(CoBrowseOutlineIcon)`
  ${commonIconStyle}
  fill: ${p => p.theme.colors.gray600};
`;

const StyledTicketIcon = styled(TicketIcon)`
  ${commonIconStyle}
  height: 22px;
  stroke-width: 0.1;
`;

const Icon = styled.i`
  ${commonIconStyle}
`;

const StyledTabs = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  align-items: center;
  height: 60px;
`;

const StyledTab = styled.li`
  margin: 0;
  display: flex;
`;

export const activeChatIcon = css`
  .compositionTabs-chatIcon {
    stroke: ${p => p.theme.colors.green400};
  }
`;

export const TabButton = styled(Button)`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0;
  border-top: 1px solid transparent;
  fill: ${p => p.theme.colors.gray600};
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 0 10px;
  padding: 8px;
  position: relative;
  width: 40px;

  & i {
    font-size: 21px;
    color: ${p => p.theme.colors.gray600};
  }
  & i:hover,
  & i:active,
  & i:focus {
    color: ${p => p.theme.colors.green400};
  }

  .compositionTabs-chatIcon {
    height: 21px;
    stroke: ${p => p.theme.colors.gray600};
    width: 21px;
  }
  &:hover,
  &:active,
  &:focus {
    background: ${p => p.theme.colors.white};
    border-top-color: ${p => p.theme.colors.green400};
    fill: ${p => p.theme.colors.green400};
  }
  &:hover .compositionTabs-chatIcon,
  &:active .compositionTabs-chatIcon,
  &:focus .compositionTabs-chatIcon {
    stroke: ${p => p.theme.colors.green400};
  }
  ${p => (p.active ? activeChatIcon : '')};
  &.twitter:hover,
  &.twitter:focus {
    border-top: 1px solid #5ea9dd;
    fill: #5ea9dd;
  }
  &.conversation-note:hover,
  &.conversation-note:focus {
    border-top: 1px solid ${p => p.theme.colors.yellow400};
    fill: ${p => p.theme.colors.yellow400};
  }
  & .compositionTabs-whatsApp {
    font-size: 22px;
    opacity: 0.6;
  }

  &:focus,
  &:active &:hover {
    .customChannel-brand-icon {
      color: ${p => p.theme.colors.green400};
    }
  }

  .customChannel-brand-icon {
    ${commonIconStyle}
    width: 16px;
    height: auto;
    color: ${p => p.theme.colors.gray600};

    &:hover,
    &:focus {
      color: ${p => p.theme.colors.green400};
    }
  }

  ${StyledTicketIcon} {
    stroke: ${p => p.theme.colors.gray600};
    fill: ${p => p.theme.colors.gray600};
  }
  &:hover ${StyledTicketIcon}, &:active ${StyledTicketIcon}, &:focus ${StyledTicketIcon} {
    stroke: ${p => p.theme.colors.green400};
    fill: ${p => p.theme.colors.green400};
  }
  &:hover ${CoBrowseIcon} {
    fill: ${p => p.theme.colors.green400};
  }
  ${p => p.active && activeTab};
`;

const activeTab = css`
  border-top: 1px solid ${p => p.theme.colors.green400};
  fill: ${p => p.theme.colors.green400};
  &:hover,
  &:focus {
    fill: ${p => p.theme.colors.green400};
  }
  &.twitter,
  &.twitter:hover,
  &.twitter:focus {
    border-top: 1px solid #5ea9dd;
    fill: #5ea9dd;
  }
  &.conversation-note,
  &.conversation-note:hover,
  &.conversation-note:focus {
    border-top: 1px solid ${p => p.theme.colors.yellow400};
    fill: ${p => p.theme.colors.yellow400};
  }
  &.compositionTabs-chatIcon {
    stroke: ${p => p.theme.colors.green400};
  }

  & i {
    color: ${p => p.theme.colors.green400};
  }
  & i:hover {
    opacity: 0;
  }

  .customChannel-brand-icon {
    color: ${p => p.theme.colors.green400};
  }

  &:hover
    ${VoiceIcon},
    &:hover
    ${ChatIcon},
    &:hover
    ${StyledTicketIcon},
    &:hover
    ${EmailIcon},
    &:hover
    ${SmsIcon},
    &:hover
    ${WhatsappIcon},
    &:hover
    ${TwitterIcon},
    &:hover
    ${NoteIcon},
    &:hover
    ${FacebookMessengerIcon},
    &:hover
    ${TaskIcon},
    &:hover
    ${CreditCardIcon},
    &:hover
    ${InstagramIcon},
    &:hover
    ${CoBrowseIcon},
    &:hover
    .customChannel-brand-icon {
    opacity: 0;
  }
  &:hover ${ChevronIcon} {
    opacity: 1;
  }
  &.conversation-note ${ChevronIcon} {
    stroke: ${p => p.theme.colors.yellow400};
    fill: ${p => p.theme.colors.yellow400};
  }
  ${StyledTicketIcon} {
    stroke: ${p => p.theme.colors.green400};
    fill: ${p => p.theme.colors.green400};
  }
  ${CoBrowseIcon} {
    fill: ${p => p.theme.colors.green400};
  }
`;

const TabSpacer = styled.li`
  background-color: ${p => p.theme.colors.gray300};
  height: 30px;
  width: 1px;
  &:first-child {
    display: none;
  }
`;
