import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';

import SnippetsAdminContainer from './snippets_admin';
import VariablesAdmin from './variables_admin';

const SNIPPETS = 'Answers';
const VARIABLES = 'Variables';

const KnowledgeBaseAdmin = createReactClass({
  getInitialState() {
    return { display: SNIPPETS };
  },

  switchDisplay(display) {
    this.setState({ display });
  },

  render() {
    if (this.state.display === VARIABLES) {
      return <VariablesAdmin renderSwitch={this.renderSwitch} />;
    }

    return <SnippetsAdminContainer renderSwitch={this.renderSwitch} />;
  },

  renderSwitch() {
    let snippetsClassNames = classnames('knowledgeBaseAdmin-switch-answers', {
      'knowledgeBaseAdmin-switch-active': this.state.display === SNIPPETS,
    });
    let variablesClassNames = classnames('knowledgeBaseAdmin-switch-variables', {
      'knowledgeBaseAdmin-switch-active': this.state.display === VARIABLES,
    });

    return (
      <div className="knowledgeBaseAdmin-switch">
        <div className={snippetsClassNames} onClick={this.switchDisplay.bind(this, SNIPPETS)}>
          {SNIPPETS}
        </div>
        <div className={variablesClassNames} onClick={this.switchDisplay.bind(this, VARIABLES)}>
          {VARIABLES}
        </div>
      </div>
    );
  },
});

export default KnowledgeBaseAdmin;
