import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';

import KeypressMixin from 'components/lib/keypress_mixin';
import DraftEditor, { getCurrentMention } from 'components/customer/composition/lib/draft/draft_editor';
import DraftEditorContainer from 'components/customer/composition/lib/draft/draft_editor_container';
import { removeMention } from './draft/draft_mention';
import Snippet from 'models/answers/snippet';

const ACTIVATION_CHARACTER = ['+', '/'];

const CompositionText = createReactClass({
  mixins: [KeypressMixin],

  propTypes: {
    allowDroppingFiles: PropTypes.bool,
    answerMenuData: PropTypes.array,
    autoFocus: PropTypes.bool,
    channel: PropTypes.string,
    children: PropTypes.array,
    className: PropTypes.string,
    editorChildren: PropTypes.array,
    editorState: PropTypes.instanceOf(EditorState).isRequired,
    enterSends: PropTypes.bool,
    hasAttachments: PropTypes.bool,
    isAnswerLinkEditingAllowed: PropTypes.bool,
    isEditingSnippets: PropTypes.bool,
    mentionMenuData: PropTypes.array.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onClickTable: PropTypes.func,
    onDropFiles: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onSearchAnswerMenu: PropTypes.func,
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    richTextEnabled: PropTypes.bool,
    singleLineInput: PropTypes.bool,
    snippet: PropTypes.instanceOf(Snippet),
    variables: PropTypes.object.isRequired,
  },

  /* Handlers */

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.editorState.getSelection().hasFocus &&
      this.props.editorState.getSelection().hasFocus &&
      this.props.onBlur
    ) {
      this.props.onBlur();
    }
  },

  UNSAFE_componentWillMount() {
    window.addEventListener('focus', this.handleWindowFocus);
  },

  componentWillUnmount() {
    window.removeEventListener('focus', this.handleWindowFocus);
  },

  handleWindowFocus() {
    if (this.props.autoFocus) {
      this.focus();
    }
  },

  onCancelMenu() {
    this.props.onChange(removeMention(this.props.editorState));
  },

  onChange(editorState) {
    this.props.onChange(editorState);
  },

  onKeyDown(e) {
    if (this.mentionMenu) {
      this.mentionMenu.onKeyDown(e);
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  },

  onSubmit(event) {
    const editorState = this.props.editorState;
    if (!this.props.onSubmit || !this.hasTextOrAttachments() || getCurrentMention(editorState).text != null) {
      return;
    }
    this.props.onSubmit(event);
  },

  /* Render */

  render() {
    let editorState = this.props.editorState;
    let hasFocus = editorState.getSelection().getHasFocus();

    let classNames = classnames(
      'compositionText',
      { 'compositionText-empty': editorState.getCurrentContent().hasText() },
      { 'compositionText-focused': hasFocus },
      this.props.className
    );
    let activationCharacter = [];
    if (this.props.isEditingSnippets) {
      activationCharacter = this.props.isAnswerLinkEditingAllowed ? ACTIVATION_CHARACTER : ['/'];
    }

    return (
      <div
        className={classNames}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        onKeyDown={this.handleKeyDown}
      >
        <DraftEditorContainer
          allowDroppingFiles={this.props.allowDroppingFiles}
          autoFocus={this.props.autoFocus}
          channel={this.props.channel}
          className={this.props.className}
          editorState={this.props.editorState}
          enterSends={this.props.enterSends}
          isEditingSnippets={this.props.isEditingSnippets}
          key="compositionText-draftEditor"
          mentionCharacter={activationCharacter}
          onChange={this.onChange}
          onClickTable={this.props.onClickTable}
          onDropFiles={this.props.onDropFiles}
          placeholder={this.props.placeholder}
          readOnly={this.props.readOnly}
          ref={node => (this.editorContainer = node)}
          richTextEnabled={this.props.richTextEnabled}
          singleLineInput={this.props.singleLineInput}
          snippet={this.props.snippet}
          variables={this.props.variables}
        >
          {this.props.editorChildren}
        </DraftEditorContainer>
        {this.props.children}
      </div>
    );
  },

  /* Editor Getters */

  getPixelPosition() {
    return this.editor().getCurrentMentionPixels();
  },

  hasTextOrAttachments() {
    const content = this.getPlaintext();
    const hasText = content && content.trim() !== '';
    return hasText || this.props.hasAttachments;
  },

  getPlaintext() {
    return DraftEditor.toPlaintext(this.props.editorState);
  },

  /* Editor Actions */

  editor() {
    return this.editorContainer.getWrappedInstance();
  },

  focus() {
    this.editor().focus();
  },

  focusAtEnd() {
    this.editor().focusAtEnd();
  },

  statics: {
    getNewEditorState() {
      return DraftEditor.getNewEditorState();
    },

    toPlaintext(editorState) {
      return DraftEditor.toPlaintext(editorState);
    },
  },
});

export default CompositionText;
