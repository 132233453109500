'use strict';

export function fbMessageId(len)  {
  let id = '';
  let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for(let i = 0; i < len; i++ )
    id += chars.charAt(Math.floor(Math.random() * chars.length));

  return id;
}