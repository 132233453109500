export default class RequestAgentRead {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId, customerId, agentId }) {
    const customerStores = this.context.stores.customers.storesFor(customerId);
    const currentAgentRead = customerStores.agentRead.get();
    if (currentAgentRead && currentAgentRead.conversationId === conversationId) {
      return;
    }

    customerStores.agentRead.setLoading();
    this.context.gateways.agentRead.request({ conversationId, agentId });
  }
}
