import ConversationUpdater from 'actions/conversation/lib/conversation_updater';
import createTaskCompletionItem from 'scripts/domain/factories/conversation_item/create_task_completion_item';
import MarkTaskClosed from './mark_task_closed';

export default class MarkTaskClosedAndCreateCompletionItem extends ConversationUpdater {
  run({ conversationItem, location }) {
    this.context.executeAction(MarkTaskClosed, { conversationItemId: conversationItem.id });
    let completionItem = createTaskCompletionItem({
      agentId: this.currentAgent.id,
      note: conversationItem.content.note,
      taskItemId: conversationItem.id,
      customerId: conversationItem.customerId,
    });
    this.addConversationItem(completionItem);

    this.context.analytics.track('Task Closed', {
      itemId: conversationItem.id,
      location,
    });
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
