import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import ComposerContext from 'components/composer/contexts/composer_context';
import DropdownMenu from 'components/common/dropdown_menu';
import { formatPhoneNumber } from 'models/phone_number';
import UpdateComposition from 'actions/composer/update_composition';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function ToField({ isInvalidNumber, phoneNumbers }) {
  const { composition } = useContext(ComposerContext);
  const currentPhoneNumber = composition.content.to;

  const executeAction = useExecuteAction();
  const onChange = to => executeAction(UpdateComposition, { compositionId: composition.id, attrs: { to } });

  if (phoneNumbers.length === 0 || (phoneNumbers.length === 1 && phoneNumbers.indexOf(currentPhoneNumber) > -1)) {
    return (
      <PhoneNumber data-aid="sms-singleToField" data-invalid={isInvalidNumber} isInvalid={isInvalidNumber}>
        {formatPhoneNumber(currentPhoneNumber)}
      </PhoneNumber>
    );
  }

  const options = _.map(phoneNumbers, number => ({ label: formatPhoneNumber(number), value: number }));

  return (
    <DropdownMenu
      data-aid="sms-toField"
      onSelect={onChange}
      options={options}
      text={formatPhoneNumber(currentPhoneNumber)}
      value={currentPhoneNumber}
    />
  );
}

ToField.propTypes = {
  isInvalidNumber: PropTypes.bool,
  phoneNumbers: PropTypes.arrayOf(PropTypes.string),
};

const PhoneNumber = styled.div`
  color: ${p => (p.isInvalid ? p.theme.colors.red400 : 'inherit')};
  margin-right: 12px;
  white-space: nowrap;
`;
