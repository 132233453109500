import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'VgsAccountContent',

  properties: {
    vaultEnvironment: prop(String),
    vaultId: prop(String).isRequired,
  },
});

export const VgsSecretContent = createModel({
  modelName: 'VgsAccountSecretContent',
  properties: {},
});
