// explicit path to client version of director needed to fix issue when
// running 'npm test'
import Director from 'director/build/director';
import Immutable from 'immutable';
import Qs from 'qs';

import { getLocationUrl } from './location_url';
import verifyLogin from './filters/verify_login';

class Router {
  constructor(opts) {
    this.beforeUnload = this.beforeUnload.bind(this);
    this.configureDirector(opts);
  }

  start() {
    this.director.init();
  }

  stop() {
    this.director.destroy();
  }

  // route helpers

  refresh() {
    this.director.dispatch('on', this.getPath());
  }

  navigateHome() {
    this.navigateToUrl('/home');
  }

  /**
   * Navigates to the in-app URL specified via a location model
   * that must have a `getPath` method and optionally `getQueryParams` method
   */
  navigateTo(location) {
    this.navigateToUrl(getLocationUrl(location));
  }

  navigateToUrl(route) {
    this.director.setRoute(route);
  }

  navigateToPath(path) {
    window.location.pathname = path;
  }

  // query parameters helpers

  getQueryParameter(parameterName) {
    let parameters = this.parseQueryParameters();
    return parameters.get(parameterName);
  }

  getPath() {
    const { search } = this.getWindowLocation();
    return this.director.getPath() + search;
  }

  // configuration helpers

  configureDirector({ routes, runnerContext, viewState }) {
    this.director = Director.Router(routes(this, { runnerContext, viewState }));
    this.director.configure({
      recurse: 'forward',
      html5history: true,
      strict: false,
      convert_hash_in_init: false,
      before: () => {
        if (this.cancelUnload) {
          delete this.cancelUnload;
          return false; // prevent execution of `on` and `before` callbacks during revert (3,5)
        }

        // ensure beforeUnload does not leak across routes
        // it will be reattached, if appropriate, in downstream route handlers
        this.offBeforeUnloadAll();

        if (verifyLogin(this, runnerContext)() === false) {
          return false; // stop routing
        }
        runnerContext.analytics.page(this.getWindowLocation());
      },
      after: () => {
        if (this.cancelUnload) {
          return false; // prevent execution of the next route `after` callback (2)
        }

        if (this.wasUnloadConfirmed() === false) {
          this.cancelUnload = true; // prevent execution of next route callback in (2) and current route callbacks in (3)
          this.navigateToUrl(this.beforeUnloadUrl); // recursively navigate from the next to the current route (1)
          this.cancelUnload = true; // prevent execution of the next route `before` and `on` callbacks (used by 5)
          return false; // prevent execution of the current route `after` callback (4)
        }

        // returning false here prevents all other `after` handlers from running
        return runnerContext.stores.auth.get().isLoggedIn();
      },
      notfound: () => {
        this.navigateHome();
      },
    });

    // HACK work around a vestigial hack in director that causes it not to
    // initialize correctly for 500ms - see:
    // https://github.com/flatiron/director/blob/master/build/director.js#L67-L74
    if (!window.onpopstate) {
      window.onpopstate = function(onChangeEvent) {
        for (let i = 0, l = Director.Router.listeners ? Director.Router.listeners.length : 0; i < l; i++) {
          Director.Router.listeners[i](onChangeEvent);
        }
      };
    }
  }

  getWindowLocation() {
    return window.location;
  }

  parseQueryParameters() {
    let windowLocationSearch = this.getWindowLocation().search || '';

    let parameters = {};

    if (windowLocationSearch.indexOf('?') !== -1) {
      let queryString = windowLocationSearch.split('?')[1];
      // Override default array limit - parse query params with up to 40
      // items into array instead of object
      // https://github.com/ljharb/qs#parsing-arrays
      parameters = Qs.parse(queryString, { arrayLimit: 39 });

      // Check if any array parameter is over the limit - it will be an Object instead of array
      const isPlainObject = input => input && !Array.isArray(input) && typeof input === 'object';
      for (var key in parameters) {
        if (parameters.hasOwnProperty(key)) {
          if (isPlainObject(parameters[key])) {
            throw new Error(`parameter "${key}" exceeds the limit for length of array query params (40)`);
          }
        }
      }
    }

    return Immutable.Map(parameters);
  }

  // beforeunload helpers
  beforeUnload() {
    let args = arguments;
    if (this.getPath() === this.beforeUnloadUrl) {
      return;
    } // this cancels the check for a hash change, being used by conversation item navigation
    if (!this.beforeUnloadCallbacks) {
      return;
    }
    this.beforeUnloadCallbacks.forEach(fn => {
      fn(...args);
    });
  }

  onBeforeUnload(callback) {
    if (!this.beforeUnloadCallbacks) {
      this.beforeUnloadCallbacks = [];
      window.addEventListener('beforeunload', this.beforeUnload);
    }
    this.beforeUnloadCallbacks.push(callback);

    const { pathname, search } = this.getWindowLocation();
    this.beforeUnloadUrl = pathname + search;
  }

  offBeforeUnload(callback) {
    if (!this.beforeUnloadCallbacks) {
      return;
    }

    let index = this.beforeUnloadCallbacks.indexOf(callback);
    if (index === -1) {
      return;
    }

    this.beforeUnloadCallbacks.splice(index, 1);

    if (this.beforeUnloadCallbacks.length === 0) {
      window.removeEventListener('beforeunload', this.beforeUnload);
      delete this.beforeUnloadCallbacks;
      delete this.beforeUnloadUrl;
    }
  }

  offBeforeUnloadAll() {
    if (!this.beforeUnloadCallbacks) {
      return;
    }
    window.removeEventListener('beforeunload', this.beforeUnload);
    delete this.beforeUnloadCallbacks;
    delete this.beforeUnloadUrl;
  }

  wasUnloadConfirmed() {
    if (this.beforeUnloadCallbacks) {
      let event = {
        preventDefault: () => {},
      };

      // run the beforeUnload callback to see if the confirmation
      // dialog should be displayed
      this.beforeUnload(event);

      // if there is a return value, display the confirmation dialog
      // api compatible with window.onBeforeUnload
      // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload#Examples
      if (event.returnValue != null) {
        return window.confirm(event.returnValue);
      }
    }
  }
}

export default Router;
