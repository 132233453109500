import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import ConversationItemType from 'models/conversation_item_type';
import Conversation from 'models/conversation';
import { InboxConversationStatus } from 'models/location/inbox';

let Status = Conversation.Status;
let SortOrder = createEnum('LATEST', 'OLDEST');

export class Inbox {
  constructor(customers, conversationStatus, sort) {
    this.customers = customers;
    this.conversationStatus = (conversationStatus && conversationStatus.toUpperCase()) || '';
    this.sort = sort;
  }

  getInboxConversationDtos() {
    return _.chain(this.customers)
      .map(this.customerConversations)
      .flatten()
      .compact()
      .filter(this.filter.bind(this))
      .orderBy([this.getUpdatedAt.bind(this)], [this.sort === SortOrder.OLDEST ? 'asc' : 'desc'])
      .map(this.toDto)
      .value();
  }

  getInboxConversations() {
    return _.chain(this.customers)
      .map(this.customerConversations)
      .flatten()
      .compact()
      .filter(this.filter.bind(this))
      .orderBy([this.getUpdatedAt.bind(this)], [this.sort === SortOrder.OLDEST ? 'asc' : 'desc'])
      .map(({ customer, conversation }) => {
        return { conversationId: conversation.id, customerId: customer.id, updatedAt: conversation.updatedAt };
      })
      .value();
  }

  getInbox() {
    return _.chain(this.customers)
      .map(this.customerConversations)
      .flatten()
      .compact()
      .filter(this.filter.bind(this))
      .orderBy([this.getUpdatedAt.bind(this)], [this.sort === SortOrder.OLDEST ? 'asc' : 'desc'])
      .map(this.toDtoWithItems.bind(this))
      .value();
  }

  toDtoWithItems({ customer, conversation }) {
    let conversationItems = this.getConversationItems({ customer, conversation });
    return { conversation, customer, conversationItems };
  }

  customerConversations(customer) {
    return (
      (customer.conversations &&
        customer.conversations.map(conversation => {
          return { customer, conversation };
        })) ||
      []
    );
  }

  filter({ customer, conversation }) {
    return this.isMatchingConversation({ customer, conversation }) || this.hasMatchingTasks({ customer, conversation });
  }

  getUpdatedAt({ customer, conversation }) {
    return _(this.getConversationItems({ customer, conversation }))
      .map('timestamp')
      .reduce((later, curr) => (!later || curr > later ? curr : later));
  }

  toDto({ customer, conversation }) {
    return { conversationId: conversation.id, customer };
  }

  isMatchingConversation({ customer, conversation }) {
    return this.hasCorrectConversationAssignment(conversation) && this.hasCorrectConversationStatus(conversation);
  }

  hasMatchingTasks({ customer, conversation }) {
    return this.getConversationItems({ customer, conversation })
      .filter(item => item && item.content.type === ConversationItemType.TASK)
      .some(item => this.hasCorrectTaskAssignment(item.content) && this.hasCorrectTaskStatus(item.content));
  }

  getConversationItems({ customer, conversation }) {
    return _.filter(customer.conversationHistory, item => item.conversationId === conversation.id);
  }

  hasCorrectConversationAssignment(conversation) {
    throw new Error('Not implemented');
  }

  hasCorrectConversationStatus(conversation) {
    return this.unified
      ? conversation.status === 'OPEN' || conversation.status === 'WAITING'
      : conversation.status === this.conversationStatus;
  }

  hasCorrectTaskAssignment(task) {
    throw new Error('Not implemented');
  }

  hasCorrectTaskStatus(task) {
    throw new Error('Not implemented');
  }
}

export class AgentInbox extends Inbox {
  constructor(customers, agentId, conversationStatus, sort, unified) {
    super(customers, conversationStatus, sort);
    this.unified = unified;
    this.agentId = agentId;
  }

  hasCorrectConversationAssignment(conversation) {
    return conversation.assignee && conversation.assignee.agentId === this.agentId;
  }

  hasCorrectTaskAssignment(task) {
    return task.assigneeId === this.agentId;
  }

  hasCorrectTaskStatus(task) {
    if (this.unified || this.conversationStatus === Status.OPEN) {
      return !task.closedAt;
    } else if (this.conversationStatus === Status.CLOSED) {
      return !!task.closedAt;
    } // WAITING
    return false;
  }
}

export class GroupInbox extends Inbox {
  constructor(customers, groupId, conversationStatus, sort) {
    super(customers, conversationStatus, sort);
    this.groupId = groupId;
  }

  hasCorrectConversationAssignment(conversation) {
    let isAssignedToGroup = conversation.assignee.routingGroupId === this.groupId;

    if (this.conversationStatus === InboxConversationStatus.NEW) {
      return isAssignedToGroup && !conversation.assignee.agentId;
    } else if (this.conversationStatus === InboxConversationStatus.OPEN) {
      return isAssignedToGroup && conversation.assignee.agentId;
    }

    return isAssignedToGroup;
  }

  hasCorrectConversationStatus(conversation) {
    let status =
      this.conversationStatus === InboxConversationStatus.NEW ? InboxConversationStatus.OPEN : this.conversationStatus;
    return conversation.status === status;
  }

  hasCorrectTaskAssignment(task) {
    // tasks cannot currently be assigned to groups
    return false;
  }

  hasCorrectTaskStatus(task) {
    return !task.closedAt;
  }
}
