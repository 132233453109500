import analytics from 'scripts/lib/analytics';
import partition from 'lodash/partition';

export default class TrackEmailRecipients {
  constructor(context) {
    this.context = context;
  }

  run({ tos, ccs, bccs }) {
    const organization = this.context.stores.organization.get();
    if (!organization) {
      return;
    }

    const [internalTos, externalTos] = partition(tos, address => isInternal(organization, address));
    const [internalCcs, externalCcs] = partition(ccs, address => isInternal(organization, address));
    const [internalBccs, externalBccs] = partition(bccs, address => isInternal(organization, address));

    analytics.track('Email Sent', {
      numTosInternal: internalTos.length,
      numCcsInternal: internalCcs.length,
      numBccsInternal: internalBccs.length,
      numTosExternal: externalTos.length,
      numCcsExternal: externalCcs.length,
      numBccsExternal: externalBccs.length,
    });
  }
}

function isInternal(organization, address) {
  return address.endsWith(`@${organization.loginDomain}`);
}
