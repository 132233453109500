import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import BrokenLinkIcon from 'components/common/icons/broken_link';
import ExternalLink, { HelpLinkType } from 'components/lib/external_link';
import ProfileCard from 'components/customer/profile/profile_card';

/**
 * Very simple "surrogate card" that gets rendered if the actual card throws an error (due to wrong data or
 * otherwise)
 *
 * @returns {JSX.Element}
 */
export default function ProfileErrorCard({ 'data-aid': dataAid, helpUrl, reason }) {
  const messageDataAid = [dataAid || '', 'error_message'].join('_');
  const helpDataAid = [dataAid || '', 'help_link'].join('_');
  const reasonDataAid = [dataAid || '', 'error_reason'].join('_');

  const url = _.trim(helpUrl);
  const helpLinkMessage = url ? (
    <StyledHelpMessage data-aid={helpDataAid}>
      <span className="text">To learn more, visit our</span>
      <ExternalLink subtype="PROFILE_CARD_ERROR" text="Help Docs" type={HelpLinkType} url={url} />
    </StyledHelpMessage>
  ) : null;

  const reasonMessage = reason ? <StyledReasonMessage data-aid={reasonDataAid}>{reason}</StyledReasonMessage> : null;

  return (
    <StyledCard>
      <StyledCardBody data-aid={dataAid}>
        <StyledIcon />
        <StyledErrorMessage data-aid={messageDataAid}>Something's wrong with this card.</StyledErrorMessage>
        {reasonMessage}
        {helpLinkMessage}
      </StyledCardBody>
    </StyledCard>
  );
}

const StyledCard = styled(ProfileCard)`
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: ${p => p.theme.borderRadius.default};
  padding-bottom: 18px;
`;

const StyledCardBody = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  min-height: 80px;
`;

const StyledErrorMessage = styled.div`
  font-weight: ${p => p.theme.fontWeight.mediumHeavy};
`;

const StyledHelpMessage = styled.div`
  display: flex;
  font-weight: ${p => p.theme.fontWeight.normal};
  & .text {
    margin-right: 0.3rem;
  }
`;

const StyledReasonMessage = styled.div`
  font-weight: ${p => p.theme.fontWeight.normal};
`;

const StyledIcon = styled(BrokenLinkIcon)`
  height: 24px;
  width: 24px;
  margin-bottom: 8px;
`;

ProfileErrorCard.propTypes = {
  'data-aid': PropTypes.string,
  helpUrl: PropTypes.string,
  reason: PropTypes.string,
};
