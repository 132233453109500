import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import TypingState from 'models/typing_state';

const CustomerTypingState = createModel({
  modelName: 'CustomerTypingState',
  properties: {
    state: prop()
      .oneOf(..._.keys(TypingState))
      .default(TypingState.STOPPED),
    timestamp: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CustomerTypingState;
