import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Lightweight "inline image" component that displays a "placeholder" and tries to load the image from the Url.
 * Once the image is loaded, it is displayed over the placeholder. If the image cannot be loaded (loading error),
 * then optional `errorImage` is displayed - or if no `errorImage` is provided, the placeholder will be used instead.
 *
 * If the `imageUrl` is omitted, the component will display the placeholder. Typically, the placeholder is an icon
 * or SVG but can be any valid node content - text, animation etc.
 *
 * The component makes no assumptions about the styling and expects the parent to provide height, width, border radius
 * etc. A typical parent container would have styles as follows:
 *
 * const ProductImageContainer = styled.div`
 *   height: 60px;
 *   width: 60px;
 *   min-height: 60px;
 *   min-width: 60px;
 *   margin: 8px;
 *   border-radius: 4px;
 *   background-color: ${p => p.theme.colors.gray200};
 *   filter: drop-shadow(0.75px 0.75px 1px rgba(36, 36, 36, 0.12));
 * `;
 *
 * <ProductImageContainer>
 *   <InlineImage imageUrl="..." placeholder={...} />
 * </ProductImageContainer>
 *
 * @param {String} [imageUrl] - image Url. If omitted, the placeholder is displayed
 * @param {*} placeholder - content to display while image is still loading (or in case of error if no `errorImage` is
 * provided)
 * @param {*} [errorImage] - optional content to display if loading error occurs. If omitted, `placeholder` will be used
 * @param {Function} [onLoad] - optional callback that will be invoked when the image is loaded. If the `imageUrl` is
 * omitted, this callback is ignored
 * @param {Function} [onError] - optional callback that will be invoked when loading error occurs.
 * @returns {JSX.Element}
 * @constructor
 */
const InlineImage = ({ imageUrl, placeholder, errorImage, onLoad, onError }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);

  const url = (imageUrl || '').trim();
  const isValidUrl = url && url !== 'none';
  const displayImage = isValidUrl && !isError;
  const imageLayer = displayImage ? (
    <FlexWrapper className="image-wrapper" style={{ opacity: isLoaded ? 1 : 0 }}>
      <StyledImage
        className="image"
        onError={() => {
          setError(true);
          onError && onError(imageUrl);
        }}
        onLoad={() => {
          setLoaded(true);
          onLoad && onLoad(imageUrl);
        }}
        referrerPolicy="no-referrer"
        src={url}
      />
    </FlexWrapper>
  ) : null;

  const displayLoadingError = !!(errorImage && isValidUrl && isError);
  const errorLayer = displayLoadingError ? <FlexWrapper className="loading-error">{errorImage}</FlexWrapper> : null;

  const displayPlaceholder = !(isLoaded && displayImage) && !(isError && displayLoadingError);
  const placeholderLayer = displayPlaceholder ? <FlexWrapper className="placeholder">{placeholder}</FlexWrapper> : null;

  return (
    <StyledContainer className="inline-image-container">
      {placeholderLayer}
      {imageLayer}
      {errorLayer}
    </StyledContainer>
  );
};

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
`;

const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const StyledImage = styled.img`
  object-fit: contain;
  flex-grow: 0;
  height: 100%;
  width: 100%;
`;

InlineImage.propTypes = {
  errorImage: PropTypes.node,
  imageUrl: PropTypes.string,
  placeholder: PropTypes.node.isRequired,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

export { FlexWrapper, StyledImage };
export default InlineImage;
