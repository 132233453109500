import _ from 'lodash';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class SetRecipients {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, recipients }) {
    const compositionsStore = getCompositionsStore(this.context);
    const composition = compositionsStore.findBy({ id: compositionId });
    composition.content.setRecipients(recipients);
    compositionsStore.replace(composition);

    const errors = compositionsStore.getErrors(compositionId);
    const filtered = _.filter(errors, err => err.attr !== 'to' && err.attr !== 'cc' && err.attr !== 'bcc');
    compositionsStore.setErrors(compositionId, filtered);
  }
}
