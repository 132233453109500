import _ from 'lodash';
import moment from 'moment';

import AgentNotificationContentClasses, { AgentNotificationType } from './agent_notification_content_classes';
import Communicator from 'models/communicator';
import contentFromJs from './content_from_js';
import createModel, { prop } from '../lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const AgentNotification = createModel({
  modelName: 'AgentNotification',

  properties: {
    content: prop()
      .oneOf(..._.map(AgentNotificationContentClasses, clazz => clazz[1]))
      .fromJs(contentFromJs).isRequired,
    createdAt: prop(String).isRequired,
    id: prop(String).isRequired,
    initiator: {
      id: String,
      type: prop().oneOf(..._.values(Communicator)),
    },
    readAt: String,
    type: prop().oneOf(..._.keys(AgentNotificationType)),
  },

  hasRead() {
    return !!this.readAt;
  },

  markAsRead() {
    this.readAt = moment().toISOString();
  },

  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), ...attrs });
    },
    overrideFromJs(fromJs) {
      return attrs => {
        attrs.content.type = attrs.type;
        return fromJs(attrs);
      };
    },
    Type: AgentNotificationType,
  },
});

export default AgentNotification;
