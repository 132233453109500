import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('MessageAutomationItemContent').attr('type', 'MESSAGE_AUTOMATION_MESSAGE');

Factory.define('MessageAutomationItemContentWithDefaults')
    .extend('MessageAutomationItemContent')
    .attr('interactionType', 'CHAT')
    .attr('message', {
        id: 'message-id',
        from: { type: 'CUSTOMER', id: 'customer-id' },
        createdAt: '2022-03-29T22:32:17.994Z',
        type: 'TEXT',
        content: { value: 'HELLO' },
    });
