// `createEnum` creates an object that can be used as an enumerable
//
// Usage:
//
// ```javascript
// var Status = createEnum('NEW', 'OPEN', 'DONE');
//
// // it sets up properties for each argument
// assert(Status.NEW === 'NEW');
// assert(Status.OPEN === 'OPEN');
// assert(Status.OPEN === 'DONE');
//
// // it allows for O(1) testing of whether the item is enumerable
// assert(Status.NEW in Status);
// assert(!'invalid' in Status);
// ```

export default function createEnum(...names) {
  return Object.freeze(
    names.reduce(function(ret, name) {
      ret[name] = name;
      return ret;
    }, {})
  );
}
