import CreateComposition from './lib/create_composition';
import { createPhoneCallContent, hasConfiguredPhoneNumbers } from './lib/phone_composition';
import PhoneCallCompositionContent from 'models/composition/phone_call_composition_content';

class CreatePhoneCallComposition extends CreateComposition {
  constructor(context) {
    super(context, PhoneCallCompositionContent);
  }

  _createContent({ conversationId, phoneNumber }) {
    let conversation = this.customerStores.conversations.find(conversationId);
    return createPhoneCallContent(this.context, conversation, phoneNumber);
  }

  _canCreateComposition() {
    const hasExternalAgentActions = this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
    return hasConfiguredPhoneNumbers(this.context) && hasExternalAgentActions;
  }
}

export default CreatePhoneCallComposition;
