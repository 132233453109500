import filter from 'lodash/filter';

import { cleanUpActiveSession } from './cleanup_active_session';
import closeNotification from 'actions/notification/lib/close_notification';
import { getOfferedSessionForAgent } from './session_finder';
import removeItemFromInbox from 'actions/inbox/lib/remove_item_from_inbox';
import RoutingEventType from 'models/routing_event/routing_event_type';

export default class DeclineCommunicationSession {
  constructor(context) {
    this.context = context;
  }

  run() {
    const currentAgentId = this.context.stores.currentAgent.get().id;
    let offeredSession = getOfferedSessionForAgent(this.context.stores.activeSessions, currentAgentId);
    if (!offeredSession) {
      return;
    }

    this.context.capacityManager.postponeMessagingRouting();
    let newConversationPreviews = filter(this.context.stores.inbox.get(), preview => {
      if (!preview.conversationItem) {
        return true;
      }
      return offeredSession.id !== preview.conversationItem.content.sessionId;
    });
    this.context.stores.inbox.set(newConversationPreviews);

    cleanUpActiveSession(this.context, offeredSession);
    closeNotification(this.context, offeredSession.id);

    this.context.gateways.routingEvent.publish(currentAgentId, {
      queueItemId: offeredSession.id,
      reason: REASON_AGENT_DECLINED,
      type: RoutingEventType.REJECTED_COMMUNICATION_SESSION,
    });

    removeItemFromInbox(this.context, offeredSession.customer.id);
  }
}

const REASON_AGENT_DECLINED = 'DECLINED';
