import { findAndRemoveAttachment } from 'models/answers/snippet_helpers';

export default class RemoveSnippetAttachment {
  constructor(context) {
    this.context = context;
  }

  run({ attachmentId }) {
    let composition = this.context.stores.snippetComposition.get();
    if (!findAndRemoveAttachment({ snippet: composition, id: attachmentId })) {
      return;
    }
    this.context.stores.snippetComposition.set(composition);
    this.context.stores.snippetComposition.clearErrors();
  }
}
