import AbandonedCallItem from 'components/customer/conversation_history/conversation_items_v2/phone/abandoned_call_item';
import AIActivityItem from 'components/customer/conversation_history/conversation_items_v2/ai_activity/ai_activity_item';
import CallForwardItem from './conversation_items_v2/phone/call_forward_item';
import ChatItem from './conversation_items_v2/chat/chat_item';
import ChatCustomerUrlItem from './conversation_items_v2/chat/chat_customer_url_item';
import ChatEndedItem from './conversation_items_v2/chat/chat_ended_item';
import ChatSelfServiceMetadataItem from './conversation_items_v2/chat/chat_self_service_metadata_item';
import ContextItem from './conversation_items_v2/demo/context_item';
import ConversationItemType from 'models/conversation_item_type';
import CustomAttributeChangeItem from './conversation_items_v2/audit/custom_attribute_change_item';
import CustomChannelMessageItem from './conversation_items_v2/customchannel/custom_channel_message';
import CustomerAssigneeChangeItem from './conversation_items_v2/audit/customer_assignee_change_item';
import CustomerMergeItem from './conversation_items_v2/merge/customer_merge_item';
import EmailItem from './conversation_items_v2/email/email_item';
import EmailSendFailureItem from './conversation_items_v2/email/email_send_failure_item';
import CustomerActivity from './conversation_items_v2/customer_activity/customer_activity';
import FacebookIncomingItem from './conversation_items_v2/facebook/facebook_incoming_item';
import FacebookOutgoingItem from './conversation_items_v2/facebook/facebook_outgoing_item';
import HelpAppContextItem from './conversation_items_v2/demo/help_app_context_item';
import LinkedItem from './conversation_items_v2/linked_item/linked_item';
import MarketingEmail from './conversation_items_v2/demo/marketing_email';
import MessageAutomationItem from './conversation_items_v2/automation/message_automation_item';
import MessageAutomationSummaryItem from './conversation_items_v2/automation/message_automation_summary_item';
import MessageItem from './conversation_items_v2/message/message_item_container';
import NoReplyNeededItem from './conversation_items_v2/audit/no_reply_needed_item';
import NoteItem from './conversation_items_v2/note/note_item';
import PhoneCallItem from './conversation_items_v2/phone/phone_call_item';
import ProactiveChatItem from './conversation_items_v2/chat/proactive_chat_item';
import RoutingItem from './conversation_items_v2/audit/routing_item';
import RuleDisabledLoop from './conversation_items_v2/audit/rule_disabled_loop';
import SecureDataRequestItem from './conversation_items_v2/secure_data_request/secure_data_request_item';
import SessionEndedItem from './conversation_items_v2/audit/session_ended_item';
import SmsItem from './conversation_items_v2/sms/sms_item';
import StatusChangeItem from './conversation_items_v2/audit/status_change_item';
import TaskCompletionItem from './conversation_items_v2/task/task_completion_item';
import TaskDueItem from './conversation_items_v2/task/task_due_item';
import TaskEditedItem from './conversation_items_v2/task/task_edited_item';
import TaskItem from './conversation_items_v2/task/task_item';
import TaskReopenedItem from './conversation_items_v2/task/task_reopened_item';
import TaskRoutingItem from './conversation_items_v2/task/task_routing_item';
import TopicChangeItem from './conversation_items_v2/audit/topic_change_item';
import TweetItem from './conversation_items_v2/demo/tweet_item';
import { typeReflect } from 'models/conversation_item/content_from_js';
import VoicemailItem from './conversation_items_v2/phone/voicemail_item';
import VoiceRecordingDeletedItem from './conversation_items_v2/audit/voice_recording_deleted_item';
import VoiceCampaignOutreachItem from './conversation_items_v2/campaign_outreach/voice_campaign_outreach_item';
import VoiceCampaignOutreachResultItem from './conversation_items_v2/campaign_outreach/voice_campaign_outreach_result_item';
import PaymentStatusEventItem from './conversation_items_v2/secure_data_request/payment_status_event_item';
import WidgetActivityItem from './conversation_items_v2/widget_activity/widget_activity_item';

export default function componentFromItemContent(content) {
  switch (typeReflect.instanceToType(content)) {
    /* Content Items */

    case ConversationItemType.ABANDONED_CALL:
      return AbandonedCallItem;
    case ConversationItemType.AI_ACTIVITY:
      return AIActivityItem;
    case ConversationItemType.MESSAGE_AUTOMATION_MESSAGE:
      return MessageAutomationItem;
    case ConversationItemType.MESSAGE_AUTOMATION_SUMMARY:
    case ConversationItemType.MESSAGE_AUTOMATION:
      return MessageAutomationSummaryItem;
    case ConversationItemType.CHAT_MESSAGE:
      return ChatItem;
    case ConversationItemType.CHAT_SELF_SERVICE_METADATA:
      return ChatSelfServiceMetadataItem;
    case ConversationItemType.CUSTOM_CHANNEL_MESSAGE:
      return CustomChannelMessageItem;
    case ConversationItemType.EMAIL_CAMPAIGN_OUTREACH:
      return EmailItem;
    case ConversationItemType.EMAIL:
      return EmailItem;
    case ConversationItemType.CUSTOMER_ACTIVITY:
      return CustomerActivity;
    case ConversationItemType.FB_MESSAGE_INCOMING:
      return FacebookIncomingItem;
    case ConversationItemType.FB_MESSAGE_OUTGOING:
      return FacebookOutgoingItem;
    case ConversationItemType.CONVERSATION_MESSAGE:
      return MessageItem;
    case ConversationItemType.CONVERSATION_NOTE:
      return NoteItem;
    case ConversationItemType.PHONE_CALL:
      return PhoneCallItem;
    case ConversationItemType.SMS:
    case ConversationItemType.SMS_CAMPAIGN_OUTREACH:
      return SmsItem;
    case ConversationItemType.SECURE_DATA_REQUEST:
      return SecureDataRequestItem;
    case ConversationItemType.TASK:
      return TaskItem;
    case ConversationItemType.VOICEMAIL:
      return VoicemailItem;
    case ConversationItemType.VOICE_CAMPAIGN_OUTREACH:
      return VoiceCampaignOutreachItem;
    case ConversationItemType.VOICE_CAMPAIGN_OUTREACH_RESULT:
      return VoiceCampaignOutreachResultItem;
    case ConversationItemType.PROACTIVE_CHAT_CONTENT:
      return ProactiveChatItem;
    case ConversationItemType.WIDGET_ACTIVITY:
      return WidgetActivityItem;
    /* Demo Items */

    case ConversationItemType.MOBILE_CONTEXT:
    case ConversationItemType.WEB_CONTEXT:
      return ContextItem;
    case ConversationItemType.TWITTER:
      return TweetItem;
    case ConversationItemType.MARKETING_EMAIL:
      return MarketingEmail;
    case ConversationItemType.HELP_APP_CONTEXT:
      return HelpAppContextItem;

    /* Audit Items */

    case ConversationItemType.CALL_FORWARD:
      return CallForwardItem;
    case ConversationItemType.CHAT_CUSTOMER_URL:
      return ChatCustomerUrlItem;
    case ConversationItemType.CHAT_ENDED:
      return ChatEndedItem;
    case ConversationItemType.CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE:
      return CustomAttributeChangeItem;
    case ConversationItemType.CONVERSATION_STATUS_CHANGE:
      return StatusChangeItem;
    case ConversationItemType.CUSTOMER_ASSIGNEE_CHANGE:
      return CustomerAssigneeChangeItem;
    case ConversationItemType.CUSTOMER_MERGE:
      return CustomerMergeItem;
    case ConversationItemType.EMAIL_SEND_FAILURE:
      return EmailSendFailureItem;
    case ConversationItemType.NO_REPLY_NEEDED:
      return NoReplyNeededItem;
    case ConversationItemType.PAYMENT_STATUS_EVENT:
      return PaymentStatusEventItem;
    case ConversationItemType.ROUTING_ITEM:
      return RoutingItem;
    case ConversationItemType.RULE_DISABLED_LOOP:
      return RuleDisabledLoop;
    case ConversationItemType.SESSION_ENDED:
      return SessionEndedItem;
    case ConversationItemType.TASK_COMPLETION:
      return TaskCompletionItem;
    case ConversationItemType.TASK_DUE:
      return TaskDueItem;
    case ConversationItemType.TASK_EDITED:
      return TaskEditedItem;
    case ConversationItemType.TASK_ROUTING:
      return TaskRoutingItem;
    case ConversationItemType.TASK_REOPENED:
      return TaskReopenedItem;
    case ConversationItemType.TOPIC_CHANGE:
      return TopicChangeItem;
    case ConversationItemType.VOICE_RECORDING_DELETED:
      return VoiceRecordingDeletedItem;

    /* Linked Item */
    case ConversationItemType.ITEM_LINK:
      return LinkedItem;
    default:
      return null;
  }
}
