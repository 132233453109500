import React from 'react';
import Icon from 'components/lib/icons/icon';
import styled from 'styled-components';

const LoveReactionIcon = props => (
  <Icon viewBox="0 0 14 13" {...props}>
    <path d="M13 1C12.2688 0.28355 11.0343 0 10 0C8.96593 0 8.12136 0.394535 7.39013 1.11126L6.99879 1.49468L6.60773 1.11153C5.87649 0.394806 5.03407 0.000270823 4 0.000270823C2.96565 0.000270823 1.86356 0.395077 1.13232 1.11153C-0.37744 2.59049 -0.37744 4.99727 1.13232 6.4765L7 12C6.8584 12 7.10786 12.106 7 12C6.89186 12.106 7.1416 12 7 12L13 7C13.7315 6.28328 14 5.01344 14 4C14 2.98656 13.7312 1.71672 13 1Z" />
  </Icon>
);

export default styled(LoveReactionIcon)``;
