import includes from 'lodash/includes';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import connect from 'components/lib/connect';
import ConversationItemType from 'models/conversation_item_type';
import { getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import { getTopGreetingSuggestion } from 'models/greeting_suggestion';
import UpdateGreetingSuggestionScore from 'actions/greeting_suggestions/update_greeting_suggestion_score';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export const DEFAULT_GREETING_SUGGESTIONS_CONTEXT = {
  getGreetingSuggestion: () => null,
  onUpdateGreetingSuggestionScore: () => null,
};

const GreetingSuggestionsContext = React.createContext(DEFAULT_GREETING_SUGGESTIONS_CONTEXT);

export function GreetingSuggestionsContextProviderBase({ children, agentHasSentMessage, greetingSuggestions }) {
  const executeAction = useExecuteAction();

  const onUpdateGreetingSuggestionScore = useCallback(greetingSuggestionId => {
    executeAction(UpdateGreetingSuggestionScore, {
      greetingSuggestionId,
    });
  }, []);

  // All of this ref nonsense is to ensure we don't ever have to recreate the getGreetingSuggestion callback..
  // we update the refs when their underlying values change, and the callback uses the refs so that they
  // always have the latest value.
  //
  // We do this because Slate doesn't really handle plugins changing very well, if the callback changed then
  // we'd need to recreate the plugin.
  const greetingSuggestionsRef = useRef(greetingSuggestions);
  useEffect(() => {
    greetingSuggestionsRef.current = greetingSuggestions;
  }, [greetingSuggestions]);

  const agentHasSentMessageRef = useRef(agentHasSentMessage);
  useEffect(() => {
    agentHasSentMessageRef.current = agentHasSentMessage;
  }, [agentHasSentMessage]);

  const getGreetingSuggestion = useCallback((options = { force: false }) => {
    if (agentHasSentMessageRef.current && !options.force) {
      return null;
    }

    const topSuggestion = getTopGreetingSuggestion(greetingSuggestionsRef.current);
    if (topSuggestion && topSuggestion.score >= 5) {
      return topSuggestion;
    }
  }, []);

  const value = useMemo(
    () => ({
      getGreetingSuggestion,
      onUpdateGreetingSuggestionScore,
    }),
    []
  );

  return <GreetingSuggestionsContext.Provider value={value}>{children}</GreetingSuggestionsContext.Provider>;
}

const GreetingSuggestionsContextProvider = connect(mapStateToProps)(GreetingSuggestionsContextProviderBase);

function mapStateToProps({ getProvider }) {
  const greetingSuggestionsProvider = getProvider('greetingSuggestions');
  const currentAgentId = getProvider('currentAgent').get().id;
  const latestConversationId = getLatestConversationId(getProvider('conversations'));
  const conversationHistory = getProvider('conversationHistory');

  return {
    greetingSuggestions: greetingSuggestionsProvider.findAll(),
    agentHasSentMessage: hasAgentSentMessage(conversationHistory, latestConversationId, currentAgentId),
  };
}

export default function GreetingSuggestionsProvider({ children }) {
  return <GreetingSuggestionsContextProvider>{children}</GreetingSuggestionsContextProvider>;
}

export function hasAgentSentMessage(conversationHistory, latestConversationId, currentAgentId) {
  const messagingChannels = [
    ConversationItemType.SMS,
    ConversationItemType.FB_MESSAGE_OUTGOING,
    ConversationItemType.CHAT_MESSAGE,
    ConversationItemType.CONVERSATION_MESSAGE,
  ];
  return !!getLatestManualItem({
    conversationHistory,
    conversationId: latestConversationId,
    filter: ci => ci.initiator.id === currentAgentId && includes(messagingChannels, ci.content.type),
  });
}

export function useGreetingSuggestions() {
  return useContext(GreetingSuggestionsContext);
}
