import i18n from 'scripts/i18n/i18n';

// Load custom attribute update item strings.
// While English is the only language it's easier to maintain the strings here, at
// the place where they are used.
// When languages other than English are added it would be best to relocate this
// bundle to the root with other i18n strings.
i18n.addResourceBundle('en', 'translation', {
  genericAgent: 'an agent',
  topic_one: 'topic',
  topic_other: 'topics',
  customAttributeUpdateItem: {
    AGENT_ADDED: {
      agent: '<agent>{{agent, upperFirst}}</agent> added $t(topic) <topic>{{values}}</topic>',
      you: '<agent>You</agent> added $t(topic) <topic>{{values}}</topic>',
    },
    AGENT_REMOVED: {
      agent: '<agent>{{agent, upperFirst}}</agent> removed $t(topic) <topic>{{values}}</topic>',
      you: '<agent>You</agent> removed $t(topic) <topic>{{values}}</topic>',
    },
    API_ADDED: {
      api: '$t(topic, upperFirst) <topic>{{values}}</topic> added',
    },
    API_REMOVED: {
      api: '$t(topic, upperFirst) <topic>{{values}}</topic> removed',
    },
  },
});
