import _ from 'lodash';

import AttachmentSourceType from './attachment/attachment_source_type';
import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const Attachment = createModel({
  modelName: 'Attachment',

  properties: {
    id: prop(String).isRequired,

    filename: String,
    fileSize: Number,
    contentId: String,
    contentType: String,
    isInline: Boolean,
    isRedacted: Boolean,
    source: prop({
      path: String,
      type: String,
    }).default({}).isRequired,
    uploadPath: String, // for uploads only, should never be set for attachments after they have been sent or received
  },

  fileDescriptor() {
    return {
      contentType: this.contentType,
      contentLength: this.fileSize,
      filename: this.filename,
    };
  },

  isImage() {
    return this.contentType && this.contentType.startsWith('image/');
  },

  isPdf() {
    return this.contentType && this.contentType === 'application/pdf';
  },

  isUpload() {
    return this.source && !!this.source.path;
  },

  redact() {
    this.isRedacted = true;
  },

  statics: {
    create(attrs = {}) {
      let source = attrs.source;
      if (!source && attrs.uploadPath) {
        source = {
          path: attrs.uploadPath,
          type: Attachment.SourceType.UPLOAD,
        };
      }
      return new this(_.assign({ id: IdGenerator.newId() }, { source }, attrs));
    },

    overrideFromJs(fromJs) {
      return attrs => {
        let from = { ...attrs };
        if (attrs.uploadPath) {
          from.source = { path: attrs.uploadPath, type: Attachment.SourceType.Upload };
        }
        return fromJs(from);
      };
    },

    SourceType: AttachmentSourceType,
  },
});

export default Attachment;
