import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import AttachmentPreviewOpener from 'components/lib/attachment/attachment_preview_opener';
import { getSnippetUploadUrl } from 'scripts/domain/services/attachments';
import SnippetAttachmentContainer from './snippet_attachment_container';
import SnippetAttachmentsSlider from 'components/admin/knowledge_base/snippet/snippet_attachments_slider';
import SnippetUploadContainer from './snippet_upload_container';
import Upload from 'models/upload';
import { SnippetContentType } from 'models/answers/snippet';

function SnippetAttachments({
  className,
  onOpenAttachmentPreview,
  orgId,
  snippet,
  contentChannelType,
  language,
  isRemoveable = true,
}) {
  if (!snippet) {
    return null;
  }

  let content = snippet.findContentByLanguage(language);

  let allAttachments = content.getAttachmentsByType(contentChannelType);

  let attachments = _.filter(allAttachments, a => !a.isInline);
  let slidesToShow = contentChannelType === SnippetContentType.INFO ? 4 : 3;

  if (_.isEmpty(attachments)) {
    return null;
  }
  if (attachments.length > slidesToShow) {
    return (
      <SnippetAttachmentsSlider
        className={classnames(className, 'answerEditor-attachments-slider')}
        slidesToShow={slidesToShow}
      >
        {_.map(attachments, renderAttachment)}
      </SnippetAttachmentsSlider>
    );
  }

  return <ul className={classnames(className, 'snippetAttachments')}>{_.map(attachments, renderAttachment)}</ul>;

  function getDownloadUrl(attachment) {
    return getSnippetUploadUrl(orgId, snippet.id, attachment);
  }

  function getThumbnailUrl(attachment) {
    return getDownloadUrl(attachment);
  }

  function renderAttachment(attachment, index) {
    let props = {
      key: attachment.id,
      onOpenAttachmentPreview: openAttachmentPreview,
      thumbnailUrl: getThumbnailUrl(attachment),
      isRemoveable,
    };
    if (attachment instanceof Upload) {
      return <SnippetUploadContainer {...props} upload={attachment} />;
    }
    return <SnippetAttachmentContainer {...props} attachment={attachment} />;

    function openAttachmentPreview() {
      onOpenAttachmentPreview(attachments, getDownloadUrl, index);
    }
  }
}

SnippetAttachments.propTypes = {
  ...AttachmentPreviewOpener.childPropTypes,
  snippet: PropTypes.shape({
    content: PropTypes.shape({
      anyChannel: PropTypes.shape({
        attachments: PropTypes.array,
      }),
    }),
    id: PropTypes.string,
  }).isRequired,
};

export const SnippetAttachmentsContainer = AttachmentPreviewOpener(SnippetAttachments);

SnippetAttachmentsContainer.propTypes = {
  snippet: PropTypes.shape({
    content: PropTypes.shape({
      anyChannel: PropTypes.shape({
        attachments: PropTypes.array,
      }),
    }),
    id: PropTypes.string,
  }).isRequired,
};

export { SnippetAttachments };

export default function SnippetAttachmentsShortCircuit(props) {
  const { contentChannelType, language, snippet } = props;

  if (!snippet) return null;
  let hasLanguage = snippet.hasLanguage(language);
  if (!hasLanguage) return null;

  let content = snippet.findContentByLanguage(language);
  let attachments = content.getAttachmentsByType(contentChannelType);
  if (_.isEmpty(attachments)) {
    return null;
  }
  return <SnippetAttachmentsContainer {...props} />;
}
