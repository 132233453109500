import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import BackIcon from 'components/lib/icons/chevron_left';
import { CloseButtonTypes } from 'components/common/button';
import InsetContainer from 'components/common/containers/inset_container';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';
import XIcon from 'components/lib/icons/x_icon';

const transitionName = 'full-page-modal';
const appearDuration = 200;
const exitDuration = 200;

FullPageModal.exitDuration = exitDuration;

/**
 * @visibleName Full Page Modal
 */

export default function FullPageModal({
  closeButtonType,
  closeButtonLabel = 'Back',
  'data-aid': dataAid,
  content,
  onClose,
  topPadding = 80,
  visible,
  preventCloseOnEscape,
  renderInPortal,
}) {
  // temporary hide app layout while modal is visible...
  useEffect(() => {
    if (renderInPortal) {
      const appLayoutContainer = document.getElementsByClassName('appLayoutContainer')[0];
      appLayoutContainer.style.display = 'none';
      return () => (appLayoutContainer.style.display = '');
    } else {
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = '');
    }
  }, [renderInPortal]);

  const onKeyDown = useCallback(
    evt => {
      if (!preventCloseOnEscape && evt.key === 'Escape') {
        onClose();
      }
    },
    [onClose, preventCloseOnEscape]
  );

  const renderModal = (
    <CSSTransition
      appear
      classNames={transitionName}
      enter
      exitDuration={exitDuration}
      in={visible}
      timeout={{ appear: appearDuration, exit: appearDuration, enter: appearDuration }}
      unmountOnExit
    >
      <ModalContainer onKeyDown={onKeyDown} renderInPortal={renderInPortal} tabIndex="0">
        <ModalLayout>
          <CloseButton
            closeButtonType={closeButtonType}
            data-aid={`${dataAid}-backButton`}
            label={closeButtonLabel}
            onClose={onClose}
            renderInPortal={renderInPortal}
          />
          <Content data-aid={dataAid} renderInPortal={renderInPortal} topPadding={topPadding}>
            {content}
          </Content>
        </ModalLayout>
      </ModalContainer>
    </CSSTransition>
  );

  return renderInPortal ? <PortalModalWrapper>{renderModal}</PortalModalWrapper> : renderModal;
}

FullPageModal.propTypes = {
  content: PropTypes.element,
  footerActions: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  visible: PropTypes.bool,
  'data-aid': PropTypes.string,
};

const CloseButton = ({ 'data-aid': dataAid, closeButtonType, onClose, label, renderInPortal }) => {
  if (closeButtonType === CloseButtonTypes.NONE) {
    return null;
  }
  if (closeButtonType === CloseButtonTypes.BACK_BUTTON) {
    return <BackButton data-aid={dataAid} label={label} onClick={onClose} />;
  } else {
    const CloseIcon = renderInPortal ? StyledCloseIconFixed : StyledCloseIconAbsolute;
    return <CloseIcon onClick={onClose} />;
  }
};

const BackButton = ({ 'data-aid': dataAid, onClick, label }) => (
  <Back data-aid={dataAid} onClick={onClick}>
    <StyledBackIcon />
    {label}
  </Back>
);

const styledCloseIcon = css`
  cursor: pointer;
  height: 20px;
  right: 42px;
  top: 42px;
  width: 20px;
`;

export const Footer = styled(InsetContainer).attrs({ inset: 'medium' })`
  align-items: flex-end;
  align-self: flex-end;
  background-color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const StyledCloseIconFixed = styled(XIcon)`
  ${styledCloseIcon}
  position: fixed;
`;

const StyledCloseIconAbsolute = styled(XIcon)`
  ${styledCloseIcon}
  position: absolute;
`;

const Back = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.gray600};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  left: 31px;
  position: absolute;
  top: 24px;
  z-index: 1;
`;

const StyledBackIcon = styled(BackIcon)`
  height: 16px;
  margin-right: 3px;
  stroke-width: 0.85;
  stroke: ${p => p.theme.colors.gray600};
  width: 16px;
`;

const ModalLayout = styled.div`
  background-color: ${p => p.theme.colors.gray100};
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: 100vh;
  width: 100vw;
`;

const ModalContainer = styled.div`
  background-color: transparent;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  position: ${p => (p.renderInPortal ? 'absolute' : 'fixed')};
  right: 0;
  top: 0;
  z-index: 10;

  &.${transitionName}-appear, &.${transitionName}-enter {
    ${ModalLayout} {
      opacity: 0;
      transform: scale(0.8);
      ${Footer} {
        box-shadow: none;
      }
    }
  }

  &.${transitionName}-appear-active, &.${transitionName}-enter-active {
    ${ModalLayout} {
      opacity: 1;
      transform: scale(1);
      transition: opacity ${appearDuration}ms ease-out, transform ${appearDuration}ms ease-out;
      ${Footer} {
        box-shadow: none;
      }
    }
  }
  &.${transitionName}-enter-done {
    ${ModalLayout} {
      ${Footer} {
        box-shadow: ${p => p.theme.boxShadow.medium};
      }
    }
  }

  &.${transitionName}-exit {
    ${ModalLayout} {
      opacity: 1;
      transform: scale(1);
      ${Footer} {
        box-shadow: none;
      }
    }
  }
  &.${transitionName}-exit-active {
    ${ModalLayout} {
      opacity: 0;
      transform: scale(0.8);
      transition: opacity ${exitDuration}ms ease-in, transform ${exitDuration}ms ease-in;
      ${Footer} {
        box-shadow: none;
      }
    }
  }
  &:focus {
    outline: none; // adjusts the 'focus' state caused by tabIndex="0"
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0 auto;
  margin-top: ${p => p.topPadding}px;
  width: 100%;
  ${p => (p.renderInPortal ? '' : 'overflow: auto;')}
`;
