import React from 'react';

export default function AwayIllustration() {
  return (
    <svg fill="none" height="173" width="300" xmlns="http://www.w3.org/2000/svg">
      <path d="M300 151H0" stroke="#CACFD4" />
      <g clipPath="url(#clip0)">
        <path d="M87.892 8.784h67.628v153.347l-67.628 1.723V8.784z" fill="#FDFDFD" />
        <path d="M174.904 166.439l-20.32-10.696a56.799 56.799 0 00-26.45-6.536H87.892v17.232h87.012z" fill="#EDF5FF" />
        <path d="M81 0v166.439h6.892V8.785h67.628v157.654h6.892V0H81z" fill="#CACFD5" />
        <path d="M155.521 8.784l63.751-2.153V172.47l-63.751-6.031V8.784z" fill="#E3E8EE" />
        <mask height="148" id="a" maskUnits="userSpaceOnUse" width="68" x="88" y="9">
          <path
            clipRule="evenodd"
            d="M155.628 9H88v140.423h40.242a56.787 56.787 0 0126.45 6.536l.936.493V9z"
            fill="#fff"
            fillRule="evenodd"
          />
        </mask>
        <g mask="url(#a)">
          <path d="M274 6.019H-31v160.538h305V6.019z" fill="#EFF0F2" />
          <path
            d="M84.972 65.352l-2.304-3.981 2.304-3.981h4.608l2.304 3.98-2.304 3.982h-4.608zM136.118 60.714l-2.305-3.982 2.305-3.981h4.608l2.304 3.981-2.304 3.982h-4.608z"
            fill="#93C3FF"
          />
          <path d="M137.073 92.188l2.304-3.981-2.304-3.981h-4.608l-2.304 3.98 2.304 3.982h4.608z" fill="#2888FF" />
          <path
            d="M125.78 78.273l2.304-3.98-2.304-3.983h-4.608l-2.304 3.982 2.304 3.981h4.608zM110.171 95.501l2.304-3.98-2.304-3.982h-4.608l-2.304 3.981 2.304 3.981h4.608z"
            fill="#93C3FF"
          />
          <path d="M122.556-10.059h-.461v28.68h.461v-28.68z" fill="#002652" />
          <path
            d="M137.105 32.017c0 8.233-6.62 14.905-14.778 14.905-8.162 0-14.777-6.672-14.777-14.905s6.615-14.905 14.777-14.905c1.742 0 3.414.302 4.966.865a14.612 14.612 0 014.588 2.671 14.912 14.912 0 015.224 11.37z"
            fill="#002652"
          />
          <path d="M114.894 30.013l-1.531-2.673 1.531-2.672h3.059l1.53 2.672-1.53 2.673h-3.059z" fill="#fff" />
          <path d="M112.283 40.004l-1.53-2.673 1.53-2.673h3.06l1.53 2.673-1.53 2.673h-3.06z" fill="#fff" opacity=".3" />
          <path
            d="M119.462 32.657l-1.53-2.672 1.53-2.674h3.06l1.53 2.674-1.53 2.672h-3.06zM131.881 20.647l-.954 1.673h-3.058l-1.53-2.676.954-1.668a14.623 14.623 0 014.588 2.671z"
            fill="#fff"
            opacity=".7"
          />
          <path d="M119.473 37.987l-1.53-2.673 1.53-2.672h3.059l1.53 2.672-1.53 2.673h-3.059z" fill="#fff" />
          <path
            d="M124.041 40.63l-1.529-2.673 1.529-2.673h3.06l1.53 2.673-1.53 2.673h-3.06z"
            fill="#fff"
            opacity=".7"
          />
          <path
            d="M119.46 27.319l-1.53-2.673 1.53-2.672h3.059l1.53 2.672-1.53 2.673h-3.059zM130.976 32.74l-1.531-2.673 1.531-2.672h3.059l1.53 2.672-1.53 2.673h-3.059z"
            fill="#fff"
            opacity=".5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h138.271v172.47H0z" fill="#fff" transform="translate(81)" />
        </clipPath>
      </defs>
    </svg>
  );
}
