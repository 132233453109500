export default class SharedUploadService {
  constructor(fakeS3) {
    this.fakeS3 = fakeS3;
  }

  createGetUrl(orgId, urlRequest) {
    let url = this.fakeS3.createGetUrl(`uploads/${urlRequest.path}`);

    return url
      ? {
          id: urlRequest.id,
          path: urlRequest.path,
          url,
        }
      : {
          errors: [{ code: 'not_exist', detail: `upload ${urlRequest.path} not found` }],
        };
  }

  createUploadAuth(orgId, path, fileDescriptor) {
    return {
      postParams: {
        acl: 'private',
        key: `uploads/${path}`,
        policy: 'policy',
        'x-amz-algorithm': 'x-amz-algorithm',
        'x-amz-credential': 'x-amz-credential',
        'x-amz-date': 'x-amz-date',
        'x-amz-security-token': 'x-amz-security-token',
        'x-amz-signature': 'x-amz-signature',
        'x-amz-meta-filename': fileDescriptor.name,
        'Content-Disposition': `attachment; filename="${fileDescriptor.name}"`,
      },
      url: 'https://bucket.s3.amazonaws.com',
    };
  }

  remove(orgId, path) {
    this.fakeS3.remove(`uploads/${path}`);
  }
}
