import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomerConversationCardList from 'components/lib/customer_conversation_card/customer_conversation_card_list';
import LoadingSpinner from 'components/lib/loading_spinner';
import RecentCustomers from './recent_customers';
import UniversalSearchPaginateContainer from 'components/search/universal_search_paginate_container';

class UniversalSearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedHits: null,
      selectedIndex: null,
    };

    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  resetScroll() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryId && !this.props.isLoading && nextProps.isLoading) {
      this.setState({ cachedHits: this.props.hits });
    } else if (this.props.isLoading && !nextProps.isLoading) {
      this.resetScroll();
      this.setState({ cachedHits: null, selectedIndex: null });
    }
  }

  handleSelectItem(customerId, conversationId, index) {
    this.props.onSelectCustomer(customerId, conversationId);
    this.setState({ selectedIndex: index });
  }

  render() {
    let hits = this.props.isLoading ? this.state.cachedHits : this.props.hits;
    if (!this.props.queryId || !hits) {
      if (this.props.isLoading) {
        return (
          <div className="universalSearch-results">
            <LoadingSpinner className="universalSearch-results-loading" />
          </div>
        );
      } else if (!hits) {
        return <RecentCustomers />;
      }
      return null;
    }

    let classNames = classnames('universalSearch-results', {
      'universalSearch-results-loading': this.props.isLoading && hits.length,
      'universalSearch-results-withSort': this.props.shouldShowSLASort,
    });

    let results =
      !this.props.isLoading && hits.length === 0 ? (
        <h4 className="universalSearch-results-noResults">No results to display</h4>
      ) : (
        <CustomerConversationCardList
          hits={hits}
          onCustomerClick={this.handleSelectItem}
          selectedIndex={this.state.selectedIndex}
          showLoading={false}
        />
      );

    return (
      <SearchResults className={classNames}>
        {results}
        {this.props.isLoading && (
          <span
            className={classnames({
              'loadingSpinner-page-overlay universalSearch-results-loadingOverlay': hits && hits.length,
              'universalSearch-results-loadingOverlay-combine': this.props.isCustomerCombine,
            })}
          >
            <LoadingSpinner className="universalSearch-results-loading" />
          </span>
        )}
        {hits.length > 0 && this.props.shouldShowPagination && (
          <UniversalSearchPaginateContainer queryId={this.props.queryId} />
        )}
      </SearchResults>
    );
  }
}

UniversalSearchResults.propTypes = {
  hits: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelectCustomer: PropTypes.func.isRequired,
  queryId: PropTypes.string,
  shouldShowPagination: PropTypes.bool,
  shouldShowSLASort: PropTypes.bool,
};

const SearchResults = styled.div`
  padding-top: 16px;
`;

export default UniversalSearchResults;
