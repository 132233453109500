import _ from 'lodash';
import { createPluginFactory } from '@udecode/plate';
import { ReactEditor } from 'slate-react';
import { useEffect, useRef } from 'react';

export function createSubmitOnEnterPlugin(onSubmitRef) {
  return createPluginFactory({
    key: 'submit-on-enter',
    handlers: {
      onKeyDown: editor => evt => {
        if (ReactEditor.isComposing(editor)) {
          return;
        }

        const metaIncluded = !!evt.metaKey || !!evt.shiftKey || !!evt.altKey;
        if (evt.key === 'Enter' && !metaIncluded && _.isFunction(onSubmitRef.current)) {
          onSubmitRef.current(evt);
        }
      },
    },
  });
}

export function useCreateSubmitOnEnterPlugin(onSubmit) {
  const onSubmitRef = useRef(onSubmit);
  useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);

  return createSubmitOnEnterPlugin(onSubmitRef);
}
