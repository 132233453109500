import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import BubbleContent from '../content/bubble_content';
import VideoPlayIcon from 'components/lib/icons/video_play_icon';
import MessageReaction from './message_reaction';
import { HandleHeightChange } from 'components/customer/conversation_history/virtualized/list_item';

export function MessageItemVideo({ item, url }) {
  let [isPlaying, setIsPlaying] = useState(false);
  let [isLoadErr, setLoadErr] = useState(false);

  const isInbound = item.isInbound();

  const onError = () => setLoadErr(true);

  if (isLoadErr) {
    return renderVideoIsNoLongerAvailable(item);
  }

  return (
    <HandleHeightChange>
      {({ onHeightChange }) => (
        <AssetBubble className="messageItem-content" data-aid={`messageItem-video-${item?.id}`} item={item}>
          <AssetWrapper $inbound={isInbound}>
            {!isPlaying && <PlayButton />}
            <TopShadow inbound={isInbound} />
            <VideoPlayer
              $inbound={isInbound}
              config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true } } }}
              controls={isPlaying}
              height="auto"
              loop
              muted
              onClick={() => setIsPlaying(!isPlaying)}
              onError={onError}
              onPause={() => setIsPlaying(false)}
              onPlay={() => setIsPlaying(true)}
              onReady={onHeightChange}
              url={url}
              volume={0.25}
              width="100%"
            />
          </AssetWrapper>
          {renderReaction(item)}
        </AssetBubble>
      )}
    </HandleHeightChange>
  );
}

function renderReaction(item) {
  if (item.content.reactions && item.content.reactions.length > 0) {
    return <Reaction timestamp={item.content.reactions[0].timestamp} />;
  }
  return null;
}

MessageItemVideo.propTypes = {
  item: PropTypes.any,
  url: PropTypes.string,
};

export function MessageItemImage({ item, url }) {
  let [isLoadErr, setLoadErr] = useState(false);

  const onError = () => setLoadErr(true);

  if (isLoadErr) {
    return renderVideoIsNoLongerAvailable(item);
  }

  const isInbound = item.isInbound();
  return (
    <AssetBubble className="messageItem-content" data-aid={`messageItem-image-${item?.id}`} item={item}>
      <AssetWrapper $inbound={isInbound}>
        <TopShadow $inbound={isInbound} />
        <ImageAsset $inbound={isInbound} onError={onError} src={url} />
      </AssetWrapper>
      {renderReaction(item)}
    </AssetBubble>
  );
}

function renderVideoIsNoLongerAvailable(item) {
  let msg = 'Video is no longer available.';
  if (item.content.getStoryMentionUrl()) {
    msg = 'Story is no longer available.';
  }
  return (
    <BubbleContent className="messageItem-content" item={item}>
      <NoLongerAvailable>{`${msg}`}</NoLongerAvailable>
    </BubbleContent>
  );
}

const NoLongerAvailable = styled.div`
  color: ${p => p.theme.colors.gray700};
`;

MessageItemImage.propTypes = {
  item: PropTypes.any,
  url: PropTypes.string,
};

const ImageAsset = styled.img`
  max-width: 240px;
  max-height: 428px;
  border-radius: 10px;
  border-top-left-radius: ${p => (p.$inbound ? 0 : '10px')};
  border-top-right-radius: ${p => (!p.$inbound ? 0 : '10px')};
`;

const PlayButton = styled(VideoPlayIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 40px;
  width: 40px;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  pointer-events: none;
`;

const TopShadow = styled.div`
  opacity: 0;
  border-radius: 10px 10px 0 0;
  border-top-left-radius: ${p => (p.$inbound ? 0 : '10px')};
  border-top-right-radius: ${p => (!p.$inbound ? 0 : '10px')};
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  height: 30%;
  box-shadow: -2px 95px 39px -48px rgba(0, 0, 0, 0.46) inset;
  z-index: 4;
  transition: opacity 0.4s;
`;

const AssetWrapper = styled.div`
  border-radius: 10px;
  border-top-left-radius: ${p => (p.$inbound ? 0 : '10px')};
  border-top-right-radius: ${p => (!p.$inbound ? 0 : '10px')};
  position: relative;
  cursor: pointer;
  img {
    box-shadow: ${p => p.theme.boxShadow.medium};
    display: block;
    border-radius: 10px;
    border-top-left-radius: ${p => (p.$inbound ? 0 : '10px')};
    border-top-right-radius: ${p => (!p.$inbound ? 0 : '10px')};
    transform: translateZ(0);
    outline: none;
  }
`;

const VideoPlayer = styled(ReactPlayer)`
  max-width: 240px;
  max-height: 428px;
  border-radius: 10px;
  border-top-left-radius: ${p => (p.$inbound ? 0 : '10px')};
  border-top-right-radius: ${p => (!p.$inbound ? 0 : '10px')};
  video {
    background-color: ${p => p.theme.colors.gray300};
    box-shadow: ${p => p.theme.boxShadow.medium};
    display: block;
    border-radius: 10px;
    border-top-left-radius: ${p => (p.$inbound ? 0 : '10px')};
    border-top-right-radius: ${p => (!p.$inbound ? 0 : '10px')};
    transform: translateZ(0);
    outline: none;
  }
`;

const AssetBubble = styled(BubbleContent)`
  background: transparent;
  padding: 0;
  box-shadow: ${p => p.theme.boxShadow.large};
  margin-bottom: 10px;
  transform: translateZ(0);

  .conversationItemMenu-container {
    z-index: 5;
    .menuIcon {
      fill: ${p => p.theme.colors.white};
      opacity: 0;
      transition: opacity 0.25s;
    }
    .conversationItemMenu-button-open .menuIcon {
      fill: ${p => p.theme.colors.green400};
    }
  }
  &:hover {
    ${TopShadow} {
      opacity: 1;
    }
    .menuIcon {
      opacity: 1;
    }
  }
`;

const Reaction = styled(MessageReaction)`
  bottom: -10px;
  left: 16px;
`;
