import { getConversationFlags } from 'actions/conversation/lib/conversation_action_helpers';
import CloseAndGetNextConversation from 'actions/conversation//close_and_get_next_conversation';
import CloseConversationWarning from 'models/modal/close_conversation_warning';
import Conversation from 'models/conversation';
import GatewayErrorInteractiveHandler from 'scripts/application/lib/gateway_error_interactive_handler';
import GetNextConversation from './get_next_conversation';
import OpenModal from 'actions/modal/open_modal';

export default class TryCloseAndGetNextConversation {
  constructor(context) {
    this.context = context;
    this.interactiveErrorHandler = new GatewayErrorInteractiveHandler(context);
  }

  run({ conversationId, customerId }) {
    let { hasActiveCall, hasSla, needsTopics, status } = getConversationFlags(this.context, {
      conversationId,
      customerId,
    });

    if (status === Conversation.Status.CLOSED) {
      this.context.executeAction(GetNextConversation, { conversationId });

      return;
    }

    if (hasActiveCall || needsTopics) {
      return;
    }

    if (hasSla) {
      this.context.executeAction(
        OpenModal,
        CloseConversationWarning.create({ conversationId, customerId, shouldGetNext: true })
      );
    } else {
      this.context.executeAction(CloseAndGetNextConversation, { conversationId, customerId });
    }
  }
}
