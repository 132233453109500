import _ from 'lodash';

import PhoneCall from 'models/phone_call';
export default class EndPhoneCall {
  constructor(context) {
    this.context = context;
  }

  run() {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      let activeCallParticipants = _.filter(activeCall.conversationItem.content.participants, participant =>
        PhoneCall.isActiveParticipant(participant)
      );
      activeCallParticipants.forEach(participant => {
        activeCall.conversationItem.content.setParticipantStatus(
          participant.participantId,
          PhoneCall.ParticipantStatus.DISCONNECTING
        );
      });
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.endCall(activeCall.customer.id, activeCall.conversationItem.id);
    }
  }
}
