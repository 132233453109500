import _ from 'lodash';

import MqttGateway from './lib/mqtt_gateway';

export default class UserGateway extends MqttGateway {
  constructor(backend, requestorId) {
    super(backend, requestorId);
    this.userActivationTokenGateway = new UserActivationTokenGateway(backend, requestorId);
  }

  init(opts) {
    super.init(opts);
    this.userActivationTokenGateway.init(opts);
  }

  get version() {
    return '1';
  }

  get topicPattern() {
    return 'users/+';
  }

  request() {
    return super._subscribeAndFetch();
  }

  unsubscribe() {
    this._unsubscribe();
  }

  update(userId, userAttrs) {
    return this._update([userId], userAttrs);
  }

  updateActivationToken(userId) {
    return this.userActivationTokenGateway.update(userId);
  }

  onReceive(userDto, topic, parsedTopicParams) {
    this.notifyObservers('handleUser', userDto);
  }

  onDelete(topic, parsedTopicParams) {
    this.notifyObservers('handleDeleteUser', parsedTopicParams[0]);
  }

  onFetchResponse(usersDto, topic, parsedTopicParams) {
    this.notifyObservers('handleRequestedUsers', usersDto);
  }

  onFetchError(errorDto, topic, parsedTopicParams) {
    this.notifyObservers('handleRequestFetchError', errorDto);
  }

  onUpdateSuccess(payload, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateSuccess', _.last(parsedTopicParams), payload);
  }

  onUpdateErrors(errorDtos, topic, parsedTopicParams) {
    this.notifyObservers('handleUpdateErrors', _.last(parsedTopicParams), errorDtos);
  }
}

class UserActivationTokenGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'users/+/activation-token';
  }

  update(userId) {
    return super._update([userId]);
  }
}
