import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ServerClock from 'scripts/application/lib/server_clock';
import Tooltip from 'components/common/tooltip';

export default function TaskStatusIndicator({ isComplete, timestamp }) {
  const isOverdue = !isComplete && timestamp < ServerClock.toISOString();
  const icon = isComplete ? <FontAwesomeIcon icon={faCheck} /> : '●';
  return (
    <Tooltip message={`Due ${moment(timestamp).format('MMM D, YYYY h:mma')}`}>
      <StyledStatus data-aid="taskStatusIndicator-icon" isOverdue={isOverdue}>
        {icon}
      </StyledStatus>
    </Tooltip>
  );
}

const StyledStatus = styled.div`
  align-items: center;
  color: ${p => (p.isOverdue ? p.theme.colors.red400 : p.theme.colors.green400)};
  display: flex;
  justify-content: center;
  margin-right: ${p => p.theme.spacing.small};
`;

TaskStatusIndicator.propTypes = {
  isComplete: PropTypes.bool,
  timestamp: PropTypes.string.isRequired,
};
