import _ from 'lodash';
import classnames from 'classnames';
import HtmlToText from 'html-to-text';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import {
  StyledActivityBubbleContent,
  StyledChevron,
  StyledActivityContent,
  StyledActivityExpander,
  StyledActivityTitle,
} from './customer_activity_content_styles';
import ConversationItem from 'models/conversation_item';
import ExternalLink from 'components/lib/external_link';
import Linkifier from 'components/lib/linkifier';
import SafeHtml from 'components/common/safe_html';

export default function CustomerActivityContentWithAdditional({ className, item, onClick, onHeightChange }) {
  const { activityType, additionalContent, expandByDefault, title, body, link } = item.content;
  const [isExpanded, setIsExpanded] = useState(expandByDefault || false);

  const isBodyPlaintext = useMemo(() => {
    return _.trim(HtmlToText.fromString(body)).length === _.trim(body).length;
  }, [body]);
  const isAdditionalPlaintext = useMemo(() => {
    return _.trim(HtmlToText.fromString(additionalContent)).length === _.trim(additionalContent).length;
  }, [additionalContent]);

  useEffect(() => {
    onHeightChange && onHeightChange();
  }, [isExpanded, onHeightChange]);

  let additional = null;
  if (isExpanded) {
    additional = isAdditionalPlaintext ? (
      <Linkifier>{additionalContent}</Linkifier>
    ) : (
      <SafeHtml html={additionalContent} setDirection />
    );
  }

  return (
    <StyledActivityBubbleContent
      className={classnames('CustomerActivityContent', className)}
      data-aid={`customerActivityContent-${item?.id || ''}`}
      item={item}
    >
      <StyledActivityContent>
        <bdi>
          <StyledActivityTitle className="CustomerActivityContent-title">{title}</StyledActivityTitle>
        </bdi>
        <bdi data-aid="customerActivity-body">
          <div>{isBodyPlaintext ? <Linkifier>{body}</Linkifier> : <SafeHtml html={body} setDirection />}</div>
          {additional ? <div>{additional}</div> : null}
        </bdi>
        <bdi>
          {_.get(link, 'url') && (
            <ExternalLink subType={activityType} text={_.get(link, 'text')} type="ACTIVITY" url={_.get(link, 'url')} />
          )}
        </bdi>
      </StyledActivityContent>
      {renderExpander()}
    </StyledActivityBubbleContent>
  );

  function renderExpander() {
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
      onClick({ isExpanded });
    };

    return (
      <StyledActivityExpander data-aid="expander-btn" onClick={toggleExpand}>
        <StyledChevron $isDownwards={!isExpanded} className="ion-chevron-up" />
      </StyledActivityExpander>
    );
  }
}

CustomerActivityContentWithAdditional.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onClick: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
  shouldStartExpanded: PropTypes.bool,
};
