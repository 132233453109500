import createModel from './lib/create_model';

const ConversationItemStatus = createModel({
  modelName: 'ConversationItemStatus',

  properties: {
    value: String,
    description: String,
  },

  statics: {
    create(attrs) {
      return new ConversationItemStatus(attrs);
    },
  },
});

export default ConversationItemStatus;
