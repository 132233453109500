import { Factory } from 'rosie';
import '../conversation_item_factory';
import './context_event_factory';

Factory.define('WebContext')
  .attr('type', 'WEB_CONTEXT')
  .attr('events', ['events'], (events) => {
    return (events || []).map((event) => Factory.attributes('ContextEvent', event));
  });

Factory.define('WebContextWithDefaults')
  .extend('WebContext')
  .attr('username', 'Sully')
  .attr('events', ['events'], (events) => {
    return (events || [{}]).map((event) => Factory.attributes('ContextEventWithDefaults', event));
  });

Factory.define('WebContextItem')
  .attr('_demoOnly', true)
  .extend('ConversationItem')
  .attr('content', ['content'], (content) => Factory.attributes('WebContext', content));

Factory.define('WebContextItemWithDefaults')
  .attr('_demoOnly', true)
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], (content) => Factory.attributes('WebContextWithDefaults', content));
