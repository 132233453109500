import classnames from 'classnames';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';

const LabelInput = createReactClass({
  propTypes: {
    containerClass: PropTypes.string,
    errorString: PropTypes.string,
    labelClass: PropTypes.string,

    children: PropTypes.element.isRequired,
    inputId: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  },

  render() {
    let child = this.props.children;

    let containerClasses = classnames('labelInput-container', this.props.containerClass);
    let inputClasses = classnames('labelInput-input', child.props.className, {
      'labelInput-input-error': !!this.props.errorString,
    });
    let labelClasses = classnames('labelInput-label', this.props.labelClass, {
      'labelInput-label-error': !!this.props.errorString,
    });

    let label = (
      <label className={labelClasses} htmlFor={this.props.inputId} key="labelInput">
        {this.props.errorString || this.props.placeholder}
      </label>
    );

    let cloneProps = {
      className: inputClasses,
      id: this.props.inputId,
      children: child.props.children,
    };

    if (this.props.placeholder) {
      cloneProps.placeholder = this.props.placeholder;
    }

    let input = React.cloneElement(child, cloneProps);

    return (
      <div className={containerClasses}>
        {input}
        {this.props.labelAsChild ? null : label}
      </div>
    );
  },
});

export default LabelInput;
