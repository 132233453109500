import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import Factory from 'factories/all';
import InteractionType from 'models/interaction_type';

export default class IncomingCustomChannelSimulator extends CommunicationSimulator {
  constructor({ orgId, publisher, services }, database) {
    super(
      { attribute: 'incomingCustomChannelMessages', createItem: createCustomChannelItem, orgId, publisher, services },
      database
    );
  }

  _createRoutingItem(attrs) {
    return Factory.build(
      'RoutingItemForInbound',
      this._getRoutingItemAttrs({ channel: `${InteractionType.CUSTOM_CHANNEL}:${'airbnb-messages-1'}`, ...attrs })
    );
  }
}

function createCustomChannelItem(incomingMessage) {
  return Factory.build('CustomChannelMessageItem', {
    initiator: {
      type: Communicator.CUSTOMER,
      id: incomingMessage.customerId,
    },
    content: incomingMessage.content,
  });
}
