import { useEffect, useRef } from 'react';
import { isNil } from 'lodash';

import ExternalDataObjectEnvelope from 'models/external_data_objects/external_data_object_envelope';
import RequestExternalData from 'actions/external_data/request_external_data';
import { simpleHash } from 'scripts/adapters/lib/simple_hash';
import { useExecuteAction } from 'components/hooks/connect_hooks';

const DEFAULT_TIMEOUT = 60000; // 60 sec.

/**
 * Custom hook that encapsulates Custom Data loading logic for a single "data type". The data is requested only once
 * so the hook is safe to use in components that are re-rendered frequently
 *
 * @param {String} customerId - customer ID
 * @param {Boolean} isCustomerLoaded - boolean flag that indicates whether the Customer Profile is fully loaded
 * @param {Boolean} isDataLoaded - boolean flag that indicates that the data has been loaded and the request can be skipped
 * unless `forceReRequest` is set. This is because we cannot run hooks conditionally and have to execute it on every render,
 * even if the data is already available
 * @param {Boolean} forceReRequest - whether we should run the request again even if the params are exactly the same as the
 * previous call (for example, we want to retry on error)
 *
 * The "request parameters" may have the following attributes:
 * @param {Object} requestParams
 * @param {String} requestParams.externalDataType - name of the data type we are trying to load
 * @param {String} requestParams.externalDataVersion - version of the external data (semver format, e.g. "1.2")
 * @param {String} [requestParams.parentEntityId] - optional "parent entity ID" for the data we are requesting, defaults to `customerId`
 * @param {String} [requestParams.parentEntityType] - optional "parent entity type" for the data we are requesting, defaults to "customer"
 * @param {String} requestParams.requestorId - unique ID of the component making the request
 * @param {*} [requestParams.*] - arbitrary additional parameters to be added to the query
 * @param {Number} [requestTimeout] - optional request timeout (in milliseconds)
 *
 * @returns {boolean} - returns whether the data is loaded
 */
export default function useExternalDataRequest(
  customerId,
  isCustomerLoaded,
  isDataLoaded,
  forceReRequest,
  requestParams,
  requestTimeout = DEFAULT_TIMEOUT
) {
  const executeAction = useExecuteAction();
  const lastRequestedParamHash = useRef('');
  let { namespace, parentEntityId, parentEntityType, requestorId, ...additionalParams } = requestParams;

  parentEntityId = requestParams.parentEntityId || customerId;
  parentEntityType = requestParams.parentEntityType || ExternalDataObjectEnvelope.ParentEntityType.CUSTOMER;

  const currentParamHash = simpleHash({ customerId, ...requestParams, parentEntityId, parentEntityType });
  useEffect(() => {
    // The card gets re-rendered multiple times, so we need to guard against repeated requests to the backend
    // We also have to guard against "phantom renders" when customer is not fully loaded yet
    if (!customerId || !isCustomerLoaded || !parentEntityId || !requestorId || !namespace) {
      return;
    }

    // Are we running an identical request? Is data loaded already? Unless we are asked to re-run, we are done here
    if ((isDataLoaded || lastRequestedParamHash.current === currentParamHash) && !forceReRequest) {
      return;
    }

    lastRequestedParamHash.current = currentParamHash;
    const timeout = isNil(requestTimeout) ? DEFAULT_TIMEOUT : requestTimeout;
    executeAction(
      RequestExternalData,
      {
        customerId,
        namespace,
        extraParams: { ...additionalParams },
        parentEntityId,
        parentEntityType,
        requestorId,
      },
      { timeout }
    );
  });
}
