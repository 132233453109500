import React from 'react';
import IconContainer from './icon_container';

export default function(props) {
  return (
    <IconContainer viewBox="0 0 51 51" {...props} className="uploadIcon">
      <g fill="none" fillRule="evenodd" stroke="#999" strokeWidth="1.5">
        <circle className="uploadIcon-circle" cx="25" cy="25" r="25" transform="translate(.67)" />
        <g className="uploadIcon-path" fill="#999" fillRule="evenodd" stroke="none">
          <path d="M36.28 16.19H14.72c-.49 0-.817-.327-.817-.817 0-.49.327-.816.817-.816h21.723c.49 0 .817.326.817.816-.163.49-.49.817-.98.817zM26.317 18.803v20.09c0 .49-.327.817-.817.817-.49 0-.817-.327-.817-.816v-20.09c0-.49.327-.817.817-.817.49 0 .817.327.817.817z" />
          <path d="M25.99 19.62l-6.86 6.86c-.327.326-.98.326-1.307 0-.327-.327-.327-.98 0-1.307l6.86-6.86c.327-.327.98-.327 1.307 0 .326.327.326.817 0 1.307z" />
          <path d="M25.01 19.62l6.86 6.86c.326.326.98.326 1.306 0 .327-.327.327-.98 0-1.307l-6.86-6.86c-.326-.327-.98-.327-1.306 0-.327.327-.327.817 0 1.307z" />
        </g>
      </g>
    </IconContainer>
  );
}
