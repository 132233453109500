import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class RoutingGroupService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(payload, callback, requestorId, { orgId }) {
    callback(null, { status: 200, statusText: statusText(200), response: this.getDatabase(orgId).routingGroups });
  }

  add(payload, callback, path, { orgId }) {
    this.getDatabase(orgId).routingGroups.push(payload);
    this._pubsub.publish(`v1/orgs/${orgId}/routing-groups/${payload.id}`, { payload });
    callback(null, { status: 204, statusText: statusText(200), response: payload });
  }

  update(payload, callback, path, { orgId, routingGroupId }) {
    let routingGroup = _.find(this.getDatabase(orgId).routingGroups, { id: routingGroupId });
    _.merge(routingGroup, payload, function(groupAttr, payloadAttr) {
      // Arrays in the payload (such as agentIds) should overwrite the previous array
      if (_.isArray(payloadAttr)) {
        return payloadAttr;
      }
    });

    this._pubsub.publish(`v1/orgs/${orgId}/routing-groups/${routingGroupId}`, { payload: routingGroup });
    callback(null, { status: 204, statusText: statusText(200), response: payload });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/routing-groups': {
          POST: this.add,
          GET: this.findAll,
        },
        '/api/v1/orgs/:orgId/routing-groups/:routingGroupId': {
          PUT: this.update,
        },
      },
      this
    );
  }
}
