import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from 'components/lib/menu_deprecated';
import connect from 'components/lib/connect';
import HoldPhoneCall from 'actions/phone_call/hold_phone_call';
import PhoneCall from 'models/phone_call';

export class HoldMenuItem extends Component {
  constructor(props) {
    super(props);
    this.toggleParticipantStatus = this.toggleParticipantStatus.bind(this);
  }

  render() {
    return (
      <MenuItem onSelect={this.toggleParticipantStatus}>
        {this.props.participantStatus === PhoneCall.ParticipantStatus.HOLD_REQUESTED ||
        this.props.participantStatus === PhoneCall.ParticipantStatus.ON_HOLD
          ? 'Take off Hold'
          : 'Put on Hold'}
      </MenuItem>
    );
  }

  toggleParticipantStatus() {
    this.props.onHoldParticipant(this.props.participantStatus !== PhoneCall.ParticipantStatus.ON_HOLD);
  }
}

HoldMenuItem.propTypes = {
  participantStatus: PropTypes.string.isRequired,
  onHoldParticipant: PropTypes.func,
};

function mapStateToProps({ getProvider }, { participantId }) {
  let activeCall = getProvider('activeCall').get();
  return {
    participantStatus: activeCall.conversationItem.content.findParticipantById(participantId).status,
  };
}

function mapExecuteToProps(executeAction, { participantId }) {
  return {
    onHoldParticipant: state => executeAction(HoldPhoneCall, { participantId, state }),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(HoldMenuItem);
