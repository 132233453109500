export default class CompositeRestClient {
  constructor(defaultClient, demoClient) {
    this._activeClient = this._defaultClient = defaultClient;
    this._demoClient = demoClient;
  }

  post(url, obj, cb, opts = {}) {
    return this._activeClient.post(url, obj, cb, opts);
  }

  put(url, obj, cb, opts = {}) {
    return this._activeClient.put(url, obj, cb, opts);
  }

  get(url, cb, opts = {}) {
    return this._activeClient.get(url, cb, opts);
  }

  axios() {
    return this._activeClient.axios();
  }

  delete(url, cb, opts = {}) {
    return this._activeClient.delete(url, cb, opts);
  }

  postUpload(url, obj, listeners, opts = {}) {
    return this._activeClient.postUpload(url, obj, listeners, opts);
  }

  enableDemo() {
    this._activeClient = this._demoClient;
  }

  disableDemo() {
    this._activeClient = this._defaultClient;
  }
}
