import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Twitter Fill
 */

export default function TwitterFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M15 3.41979C14.4847 3.66072 13.9318 3.82412 13.3509 3.89707C13.9441 3.52226 14.3981 2.92776 14.6133 2.22155C14.0569 2.56864 13.4428 2.82068 12.7884 2.95731C12.2644 2.36742 11.519 2 10.6923 2C9.1062 2 7.82018 3.35703 7.82018 5.02977C7.82018 5.26701 7.84555 5.49872 7.89455 5.72029C5.50801 5.5938 3.39178 4.38726 1.97543 2.55387C1.72784 3.00069 1.58699 3.52132 1.58699 4.07707C1.58699 5.12855 2.0944 6.05631 2.86427 6.5991C2.3936 6.58249 1.95093 6.44587 1.56339 6.21876V6.25661C1.56339 7.72442 2.5537 8.94943 3.86682 9.22823C3.62624 9.29655 3.37255 9.3344 3.11009 9.3344C2.92464 9.3344 2.74529 9.31501 2.56944 9.27808C2.93511 10.4828 3.99543 11.3588 5.25171 11.3829C4.26926 12.1952 3.03048 12.678 1.68499 12.678C1.45317 12.678 1.22482 12.6633 1 12.6365C2.27114 13.4978 3.78024 14 5.40218 14C10.6853 14 13.5732 9.38241 13.5732 5.37781L13.5635 4.98547C14.1278 4.5608 14.6159 4.02722 15 3.41979Z" />
    </Icon>
  );
}
