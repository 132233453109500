import parseAddress from 'addressparser';
import normalizeEmail from 'normalize-email';

// with strict === true, getNormalizedEmailAddress will strip '.' and strings following '+' in email addresses
export default function getNormalizedEmailAddress(address, strict = false) {
  let addr = getSingleAddress(address);
  if (strict) {
    return addr ? normalizeEmail(addr) : '';
  }
  return addr ? addr.toLowerCase() : '';
}

export function getSingleAddress(originalAddress) {
  return (originalAddress && parseAddress(originalAddress)[0].address) || originalAddress;
}
