import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CommentPanelToggleIcon from 'components/lib/icons/comment_panel_toggle_icon';
import { StyledToggle, StyledX } from 'components/customer/answer_panel/answer_panel_toggle_button';
import ToggleSidePanels from 'actions/task/toggle_side_panels';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function TaskCommentPanelToggleButton({ isActive, isVisible }) {
  const executeAction = useExecuteAction();

  const onClick = useCallback(() => executeAction(ToggleSidePanels), []);

  const [isHovered, setHovered] = useState(false);
  const onMouseEnter = useCallback(() => setHovered(true), []);
  const onMouseLeave = useCallback(() => setHovered(false), []);

  useEffect(() => {
    setHovered(false);
  }, [isVisible]);

  let content = isActive && isHovered ? <StyledX>✕</StyledX> : <CommentPanelToggleIcon />;

  return (
    isVisible && (
      <StyledButtonWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <StyledButton data-aid="taskCommentPanelToggleButton" isActive={isActive} onClick={onClick}>
          {content}
        </StyledButton>
      </StyledButtonWrapper>
    )
  );
}

TaskCommentPanelToggleButton.propTypes = {
  isVisible: PropTypes.bool,
};

export const StyledButton = styled(StyledToggle)`
  .iconPath {
    fill: ${p => (p.isActive ? p.theme.colors.white : p.theme.colors.green400)};
  }
  .iconContainer {
    padding: 0 0 2px 2px;
  }
`;

const StyledButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 6;
`;
