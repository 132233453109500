import PropTypes from 'prop-types';
import React from 'react';
import DeepTable from 'slate-deep-table';

import CapitalizeFirstWord from 'components/text_editor/plugins/capitalize_first_word';
import CapitalizeI from 'components/text_editor/plugins/capitalize_i';
import EmailHighlighting from 'components/text_editor/plugins/email_highlighting';
import InlineLinks from 'components/text_editor/plugins/inline_links';
import Lists from 'components/text_editor/plugins/lists';
import Placeholders, { useFocusFirstPlaceholder } from 'components/text_editor/plugins/placeholders';
import PhoneNumberHighlighting from 'components/text_editor/plugins/phone_number_highlighting';
import RichText from 'components/text_editor/plugins/rich_text';
import TextEditor, { getEditorProps, useEditorRef, usePlugins } from 'components/text_editor';
import SipHighlighting from 'components/text_editor/plugins/sip_highlighting';
import { useToggleAnswerPanel } from '../plugins/toggle_answer_panel';

import Tables, { renderTableNode } from 'components/text_editor/plugins/tables';
import InlineImages from 'components/text_editor/plugins/inline_images';
import PlaceholderEditing from 'components/text_editor/plugins/placeholder_editing';
import Mentions from 'components/text_editor/plugins/mentions';
import AnswerLinks from 'components/text_editor/plugins/answer_links';
import AnswerLinkEditing from 'components/text_editor/plugins/answer_link_editing';
import VariableEditing from 'components/text_editor/plugins/variable_editing';

export default function SuperEditor(props) {
  const { onChange, readOnly } = props;
  const editorRef = useEditorRef(props.editorRef);
  useFocusFirstPlaceholder(editorRef.current, onChange);

  const toggleAnswerPanel = useToggleAnswerPanel();

  const plugins = usePlugins(() => {
    let capabilities = [
      DeepTable(),
      Tables(),
      InlineImages(),
      AnswerLinks(),
      RichText({ onChange, readOnly }),
      Lists(),
      InlineLinks({ readOnly }),
      Placeholders(),
      toggleAnswerPanel,
      ...props.plugins,
    ];

    if (readOnly) {
      capabilities = capabilities.concat([
        PhoneNumberHighlighting({ onChange }),
        EmailHighlighting({ onChange }),
        SipHighlighting({ onChange }),
      ]);
    } else {
      capabilities = capabilities.concat([
        CapitalizeI(),
        CapitalizeFirstWord(),
        PlaceholderEditing(),
        Mentions(),
        VariableEditing(),
        AnswerLinkEditing(),
      ]);
    }

    return capabilities;
  });

  const editorProps = getEditorProps(props);
  return <TextEditor {...editorProps} editorRef={editorRef} plugins={plugins} renderNode={renderTableNode} />;
}

SuperEditor.defaultProps = {
  plugins: [],
};

SuperEditor.propTypes = {
  editorRef: PropTypes.shape({ current: PropTypes.any }),
  onChange: PropTypes.func.isRequired,
  plugins: PropTypes.array,
  readOnly: PropTypes.bool,
};
