import DtoConverter from 'scripts/application/dto_converters/agent_converter';
import qconsole from 'scripts/lib/qconsole';

export default class AgentGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchAllSuccess(agentDtos) {
    // replace all agents with those passed in
    this.context.stores.agents.set(agentDtos.map(DtoConverter.fromDto));
    this.context.stores.agents.resetLoading();
  }

  onFetchAllError(errorDto) {
    qconsole.log(`Failed to fetch agents ${JSON.stringify(errorDto)}`);
  }

  onBroadcast(agentDto) {
    this.context.stores.agents.addOrReplace(DtoConverter.fromDto(agentDto));
  }

  onBroadcastDelete(agentDto, { agentId }) {
    this.context.stores.agents.remove(agentId);
  }
}
