import changeCurrentLocation from 'actions/lib/change_current_location';
import Snippet, { SnippetContent, SnippetChannels } from 'models/answers/snippet';
import SnippetCompositionUploads from 'actions/composition/lib/snippet_composition_uploads';
import SnippetEditor from 'models/location/snippet_editor';

export default class SwitchSnippet {
  constructor(context) {
    this.context = context;
  }

  run(snippetId) {
    this.updateCurrentLocation(snippetId);
    this.fetchSnippet(snippetId);
  }

  updateCurrentLocation(snippetId) {
    changeCurrentLocation(this.context, SnippetEditor.create({ snippetId }));
  }

  fetchSnippet(snippetId) {
    if (!snippetId) {
      this.removeSnippetComposition();
      return;
    }
    let snippetComposition = this.context.stores.snippetComposition.get();
    if (!snippetComposition || snippetComposition.id !== snippetId) {
      let placeholderSnippet = Snippet.create({
        id: snippetId,
        content: SnippetChannels.create({
          anyChannel: SnippetContent.create(),
          info: SnippetContent.create(),
          message: SnippetContent.create(),
          selfService: SnippetContent.create(),
        }),
      });
      this.context.stores.snippetComposition.set(placeholderSnippet);
      this.context.stores.snippetComposition.setLoading();
      this.context.gateways.snippet.fetch({ snippetId });
      this.context.stores.snippetLinks.set([]);
    }
  }

  removeSnippetComposition() {
    let snippetComposition = this.context.stores.snippetComposition.get();
    if (!snippetComposition) {
      return;
    }

    SnippetCompositionUploads.fromComposition(snippetComposition).cleanUp(this.context);
    this.context.stores.snippetComposition.remove();
  }
}
