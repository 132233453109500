import _ from 'lodash';

/**
 * Helper function that takes in an object ("sort expression") and sorts the data array accordingly. Returns
 * sorted array.
 *
 * Sort expression format: `{<attribute path>:"asc"|"desc",...}`. Sort order ("asc" or "desc") is not case-
 * sensitive. Any sort order that is not "asc" or "desc" will be treated as "asc".
 *
 * Example:
 *  { "item.name": "desc", price: "asc" } => "Sort by the `item.name` in descending order, then within subgroups
 *  sort by the `price` ascending
 *
 * @param {object} sortExpression
 * @param {object[]} data
 * @return {object[]}
 */
export function sortArray(sortExpression, data) {
  if (_.isEmpty(data) || !_.isArray(data)) return [];
  if (_.isEmpty(sortExpression)) return data;

  const sortArgs = _.reduce(
    sortExpression,
    (acc, value, key) => {
      const sortKey = _.trim(key);
      const sortOrder = _.trim(value).toLowerCase() === 'desc' ? 'desc' : 'asc';

      if (sortKey) {
        acc.iteratees.push(sortKey);
        acc.sortOrders.push(sortOrder);
      }
      return acc;
    },
    { iteratees: [], sortOrders: [] }
  );

  return _.isEmpty(sortArgs.iteratees) ? data : _.orderBy(data, sortArgs.iteratees, sortArgs.sortOrders);
}

/**
 * Simple wrapper around lodash `filter` function that allows for filtering the arrays based on a set of
 * properties and values.
 *
 * See https://lodash.com/docs/4.17.15#filter
 *
 * Examples:
 *    filterExpression = { name: 'barney' } => "return only the elements where attribute `name` === 'barney' "
 *    filterExpression = { price: 25, length: 13 } => "return elements where `price` === 25 AND length === 13"
 *    filterExpression = { count: 10, product: { name: 'shirt' } } => "elements where `count` === 10 AND `product.name` === 'shirt'
 *
 * @param {object} filterExpression - an object that gets "matched" to every element of the array
 * @param {object[]} data - the data to be filtered
 */
export function filterArray(filterExpression, data) {
  if (_.isEmpty(filterExpression)) return data;

  return _.filter(data, element => _.isMatch(element, filterExpression));
}
