import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M1.033 2.986C.021 4.454-.303 5.994.31 6.427c.613.434 1.93-.405 2.942-1.872 1.012-1.467 1.336-3.008.723-3.44-.612-.434-1.93.404-2.942 1.871zm11.34 3.675c.023-.188.036-.38.036-.575 0-2.461-1.972-4.457-4.403-4.457-2.432 0-4.403 1.996-4.403 4.457 0 .195.013.387.037.575a4.465 4.465 0 00-2.238 3.882C1.402 13.005 3.373 15 5.805 15c.583 0 1.14-.115 1.65-.324v-2.74l-1.08-1.641a.557.557 0 01.458-.866h2.346c.44 0 .702.496.458.866l-1.08 1.64v2.741c.51.209 1.066.324 1.65.324 2.432 0 4.403-1.995 4.403-4.457a4.465 4.465 0 00-2.238-3.882zM5.804 8.315c.607 0 1.1-.5 1.1-1.115s-.493-1.114-1.1-1.114c-.608 0-1.101.499-1.101 1.114 0 .616.493 1.115 1.1 1.115zM11.308 7.2c0 .616-.493 1.115-1.1 1.115-.609 0-1.101-.5-1.101-1.115s.492-1.114 1.1-1.114c.608 0 1.101.499 1.101 1.114zm4.37-.755c.625-.443.313-1.991-.7-3.459-1.012-1.467-2.34-2.297-2.966-1.854-.627.443-.314 1.99.698 3.458 1.012 1.467 2.34 2.298 2.967 1.855z"
      fillRule="evenodd"
    />
  </Icon>
);
