import { Plate } from '@udecode/plate';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import useAutofocus from './hooks/use_autofocus';

export default function TextEditor(props) {
  const {
    autoFocus,
    children,
    className,
    editorRef,
    editorId,
    minHeight,
    onChange,
    padding,
    placeholder,
    readOnly,
    width,
  } = props;
  const editor = editorRef.current;

  const editableProps = {
    autoFocus,
    placeholder: placeholder || 'Type...',
    readOnly,
  };

  useAutofocus(editor, autoFocus);

  return (
    <React.Fragment>
      <Plate
        editableProps={editableProps}
        editor={editor}
        id={editorId}
        onChange={onChange}
        plugins={editor.plugins}
        renderEditable={content => (
          <StyleWrapper
            $minHeight={minHeight}
            $padding={padding}
            $width={width}
            className={className}
            data-aid={props['data-aid']}
          >
            {content}
          </StyleWrapper>
        )}
      >
        {children}
      </Plate>
    </React.Fragment>
  );
}

TextEditor.defaultProps = {
  autoFocus: true,
  minHeight: '23vh',
  padding: '4px 8px',
  plugins: [],
};

TextEditor.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  editorId: PropTypes.string,
  editorRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  minHeight: PropTypes.string,
  onChange: PropTypes.func,
  padding: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  width: PropTypes.string,
};

/**
 * We have to brute-force the empty editor placeholder width style because Slate implements it in a way that prevents
 * any normal styling, which causes editor overflow and ugly scrollbars when the editor is empty
 */
const StyleWrapper = styled.span`
  ${p => (p.$width ? `width: ${p.$width};` : '')}

  [data-slate-editor='true'] {
    min-height: ${p => p.$minHeight} !important;
    outline: none;
    padding: ${p => p.$padding || '4px 8px'};
  }

  & [data-slate-placeholder='true'] {
    color: ${p => p.theme.colors.defaultFontBlack};
    font-size: ${p => p.theme.fontSize.base};
    top: unset !important;
    width: auto !important;
  }
`;
