import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('TaskInfo');

Factory.define('TaskInfoWithDefaults')
  .extend('TaskInfo')
  .attr('comments', id => {
    id = id || 'comment-id';
    return { [id]: Factory.attributes('TaskCommentWithDefaults', { id }) };
  })
  .attr('followers', id => {
    id = id || 'follower-id';
    return { [id]: Factory.attributes('TaskFollowerWithDefaults', { id }) };
  });
