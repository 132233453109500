import Immutable from 'immutable';

export default function createConverter(EntityClass) {
  return {
    fromImmutable(immutableMap) {
      return EntityClass.fromJs(immutableMap.toJS());
    },

    toImmutable(entity) {
      return Immutable.fromJS(entity && entity.toJs());
    },
  };
}
