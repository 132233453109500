import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class ConversationWorkflowConfigService {
  constructor(database) {
    this.getDatabase = database;
  }

  get(attrs, callback, path, { orgId }) {
    let conversationWorkflowConfig = this._getConversationWorkflowConfig(orgId);
    callback(null, { status: 200, statusText: statusText(200), response: conversationWorkflowConfig });
  }

  _getConversationWorkflowConfig(orgId) {
    let database = this.getDatabase(orgId);
    return database.conversationWorkflowConfig;
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/conversation-workflow': {
          GET: this.get,
        },
      },
      this
    );
  }
}
