import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default function GladlyProfileRow({ profileRow }) {
  const sourceClassName = classnames('externalCustomerLookupSearchResults-table-sourceCell');

  return (
    <tr className="externalCustomerLookupSearchResults-table-gladlyProfileRow" key={`externalCustomer-gladlyCustomer`}>
      <td className={sourceClassName}>
        <i className="externalCustomerLookupSearchResults-table-sourceCell-gladlyLogo" />
      </td>
      <td className="externalCustomerLookupSearchResults-table-selectorSpacer" />
      {_.map(profileRow, (value, i) => (
        <td key={`gladlyCustomer-${i}`}>{value}</td>
      ))}
    </tr>
  );
}

GladlyProfileRow.propTypes = {
  profileRow: PropTypes.array,
};
