import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import Input from './input';

/**
 * @visibleName Submit Input
 */

const SubmitInput = createReactClass({
  propTypes: {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.string.isRequired,
    wrapperClassName: PropTypes.string,
  },

  onBlur() {
    if (!this.props.readOnly) {
      this.props.onSubmit();
    }
  },

  onChange(evt) {
    this.props.onChange(evt.target.value);
  },

  onKeyDown(evt) {
    if (!this.props.readOnly) {
      if (evt.key === 'Enter') {
        evt.target.blur();
        this.props.onSubmit();
      }
    }
  },

  render() {
    return (
      <Input
        {...this.props}
        className={this.props.className}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        placeholder={this.props.placeholder}
        value={this.props.value}
        wrapperClassName={this.props.wrapperClassName}
      />
    );
  },
});

export default SubmitInput;
