import {
  createPluginFactory,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  insertNodes,
  isCollapsed,
  getPointAfter,
  getPointBefore,
  select,
} from '@udecode/plate';
import { Editor, Node, Text } from 'slate';

import getFirstNode from 'components/text_editor_new/lib/get_first_node';
import qconsole from 'scripts/lib/qconsole';
import { tryFocusEditorAsync } from 'components/text_editor_new/lib/try_focus';

export function createInlineImagePlugin() {
  return createPluginFactory({
    key: ELEMENT_IMAGE,
    type: ELEMENT_IMAGE,

    isElement: true,
    isVoid: true,

    handlers: {
      onKeyPress: editor => evt => {
        if (evt.key === 'Enter') {
          selectNextNodeOnKeyboardEvent(editor, evt);
        }
      },
    },

    withOverrides: editor => {
      // Externally accessible APIs
      editor.insertInlineImage = imageAttributes => insertInlineImage(editor, imageAttributes);

      // Attach internal handlers that override editor defaults and are not supposed to be called directly
      return withSelectOnBackspace(editor);
    },
  })();
}

function insertInlineImage(editor, imageAttributes = {}) {
  const imageNode = {
    ...imageAttributes,
    type: ELEMENT_IMAGE,
    children: [{ text: '' }],
  };
  const emptyParagraph = {
    type: ELEMENT_PARAGRAPH,
    children: [{ text: '' }],
  };

  insertNodes(editor, [imageNode, emptyParagraph]);
  tryFocusEditorAsync(editor, editor.selection).catch(err => {
    qconsole.log('ERROR: Unable to focus editor after inserting an inline image.', err);
  });
}

/**
 * When the inline image is selected and the user hits `Enter`, move the selection to the next block
 * as if the image was a readonly block of text. If the next block is also an image, then insert an
 * empty paragraph between the blocks. If there are no nodes after the current one, also insert an
 * empty paragraph
 *
 * @param editor
 * @param keyboardEvent - the original keyboard event. If we are able to handle the event (i.e. select
 *                        the next node ect.), we will call `preventDefault()` on it to make sure Plate
 *                        doesn't try to continue processing the event
 */
function selectNextNodeOnKeyboardEvent(editor, keyboardEvent) {
  if (!editor.selection || !isCollapsed(editor.selection)) return false;

  const [, nodePath] = getFirstNode(editor, { match: node => node.type === ELEMENT_IMAGE }) || [];
  if (nodePath) {
    const pointAfter = getPointAfter(editor, nodePath, { unit: 'block' });
    if (pointAfter) {
      const nextNode = getFirstNode(editor, { match: node => node?.type === ELEMENT_IMAGE, at: pointAfter });
      if (nextNode) {
        insertNodes(editor, { type: ELEMENT_PARAGRAPH, children: [{ text: '' }] });
      } else {
        select(editor, pointAfter);
      }
    } else {
      insertNodes(editor, { type: ELEMENT_PARAGRAPH, children: [{ text: '' }] });
    }

    // We have handled the event and do not want Plate to do anything else with it
    keyboardEvent.preventDefault();
  }
}

/**
 * Override the default backspace behavior so that pressing backspace when the cursor is right after the image
 * will select it, then pressing backspace when the image is selected actually deletes it. It makes backspace
 * navigation a bit less jarring.
 *
 * @param editor
 * @returns {*}
 */
function withSelectOnBackspace(editor) {
  const defaultDeleteBackward = editor.deleteBackward;

  editor.deleteBackward = unit => {
    if (unit !== 'character' || !editor.selection || !isCollapsed(editor.selection)) {
      return defaultDeleteBackward(unit);
    }

    // Check whether we have any nodes before the selection. If not, handle delete as normal
    const pointBefore = getPointBefore(editor, editor.selection, { unit });
    if (!pointBefore) {
      return defaultDeleteBackward(unit);
    }

    // See if the previous node is an image. If not, allow to delete as normal
    const prevNode = getFirstNode(editor, {
      match: node => node?.type === ELEMENT_IMAGE,
      at: pointBefore,
    });
    if (!prevNode) {
      return defaultDeleteBackward(unit);
    }

    // If the current node (that is, the node immediately after the image) is an empty paragraph,
    // fake "deleting" it by unwrapping the current node, then selecting the image
    const [currentNode] = Editor.first(editor, editor.selection);
    if (Text.isText(currentNode) && Node.string(currentNode) === '') {
      editor.unwrapNodes();
    }

    // Select the image
    select(editor, pointBefore);
  };

  return editor;
}
