import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AudiencesService {
  constructor(getDatabase, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = getDatabase;
  }

  findAll(attrs, callback, path, { agentId, orgId }, query) {
    const audiences = this.getDatabase(orgId).audiences || [];
    callback(null, { status: 200, statusText: statusText(200), response: audiences });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/audiences': {
          GET: this.findAll,
        },
      },
      this
    );
  }
}
