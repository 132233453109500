import _ from 'lodash';
import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Note: The Product Language team is planning on deprecating this component,
// and we recommend you use TextInput instead
// (https://sagansystems.github.io/styleguide/#/Components/TextInput).
// If that doesn't work for whatever reason, please reach out to @Bill.

class Input extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = { value: props.value };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  componentDidMount() {
    // Sets the cursor to the end of the input
    if (this.props.autoFocus) {
      setImmediate(() => {
        if (this.input) {
          this.input.selectionStart = this.input.value.length;
          this.input.selectionEnd = this.input.value.length;
        }
      });
    }
  }

  render() {
    let propsToOmit = ['wrapperClassName', 'onChange'];
    let inputProps = _.omit(this.props, propsToOmit);
    return (
      <Wrapper className={classnames('inputWrapper', this.props.wrapperClassName)}>
        <input
          {...inputProps}
          className={classnames('input', this.props.className)}
          onChange={_.noop}
          onFocus={this.handleFocus}
          onInput={this.handleOnChange}
          ref={node => (this.input = node)}
        />
      </Wrapper>
    );
  }

  blur() {
    this.input.blur();
  }

  focus() {
    this.input.focus();
  }

  focusAtEnd() {
    this.input.focus();
    this.input.setSelectionRange(this.props.value.length, this.props.value.length);
  }

  focusAll() {
    this.input.focus();
    this.input.setSelectionRange(0, this.props.value.length);
  }

  handleFocus(evt) {
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  }

  handleOnChange(evt) {
    this.setState({ value: evt.target.value });
    this.props.onChange(evt);
  }

  getPlaceholder(props) {
    let placeholder;
    return placeholder;
  }
}

Input.propTypes = {
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  dir: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  'data-aid': PropTypes.string,
};

export default Input;

const Wrapper = styled.div`
  height: inherit;
  position: relative;
  width: inherit;
`;
