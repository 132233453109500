import MqttGateway from './lib/mqtt_gateway';

export default class AgentAssistanceConfigGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'configuration/agent-assistance';
  }

  request() {
    return super._subscribeAndFetch();
  }

  onFetchError(errorDto) {
    this.notifyObservers('handleRequestError', errorDto);
  }

  onFetchResponse(entityDto) {
    this.notifyObservers('handleEntity', entityDto);
  }

  onReceive(entityDto) {
    this.notifyObservers('handleEntity', entityDto);
  }
}
