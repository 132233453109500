import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Tooltip from 'components/common/tooltip';

function ReportLegend({ data }) {
  // returns a legend key for every legend id
  function renderLegendKeys() {
    const allLegendKeys = _.map(data, entry => {
      let legendKeyName = entry.name;

      return (
        <Tooltip
          data-aid={`tooltip-${entry.rank}`}
          key={`tooltip-${entry.rank}`}
          message={entry.ancestralName || entry.name}
          position="left"
        >
          <LegendItem key={`legendItem-${entry.rank}`}>
            <LegendKey data-aid={`legendKey-${entry.rank}`} rank={entry.rank} />
            <LegendKeyName>{legendKeyName}</LegendKeyName>
          </LegendItem>
        </Tooltip>
      );
    });
    return allLegendKeys;
  }

  /* Render */
  return (
    <LegendWrapper>
      <Legend data-aid="reportLegend">{renderLegendKeys()}</Legend>
    </LegendWrapper>
  );
}

ReportLegend.propTypes = {
  data: PropTypes.array,
};

export default ReportLegend;

const LegendWrapper = styled.div`
  display: inline-block;
  margin: auto 0 auto auto;
  position: relative;
  text-align: right;
`;

const Legend = styled.div`
  display: inline-block;
  margin: auto auto auto ${p => p.theme.spacing.insetLarge};
  position: relative;
  text-align: left;
  width: 296px;
`;

const LegendItem = styled.div`
  display: inline-block;
`;

const LegendKey = styled.div`
  background-color: ${p => p.theme.lineGraphColors[`lineGraphLine${p.rank}`]};
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 0 ${p => p.theme.spacing.insetSmall};
  margin-bottom: 5px;
  width: 10px;
`;

const LegendKeyName = styled.div`
  display: inline-block;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
