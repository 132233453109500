import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import RequestRecentCustomers from 'actions/recent_customers/request_recent_customers';
import Search from 'actions/search/search';
import SearchResult from 'models/search/search_result';
import SearchView from 'models/location/search';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenSearchResults {
  constructor(context) {
    this.context = context;
  }

  run(query) {
    let result = query && SearchResult.create({ query: ensureFilterTypeSet(query) });
    if (result) {
      this.context.executeAction(Search, result);
    } else {
      this.context.executeAction(RequestRecentCustomers);
    }

    let searchView = SearchView.create({ currentResultId: result && result.id, query });
    changeCurrentLocation(this.context, searchView);

    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.SEARCH });
  }
}

function ensureFilterTypeSet(query) {
  if (!query.filter.type) {
    // require type filter to be set, defaulting to conversation
    query.setType(SearchResult.HitTypes.CONVERSATION);
  }
  return query;
}
