import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import DOMPurify from 'dompurify';

/**
 * DOMPurify transform function that we use if we need to explicitly set direction on <div> elements.
 * Since DOMPurify is a global instance, we have to remove the hook after each use.
 *
 * @param {HTMLElement} node
 */
function setDivDirectionHook(node) {
  if (node?.nodeName && node.nodeName.toLowerCase() === 'div' && !node.hasAttribute('dir')) {
    node.setAttribute('dir', 'auto');
  }
}

function sanitize(html, { setDirection } = {}) {
  if (setDirection) DOMPurify.addHook('afterSanitizeAttributes', setDivDirectionHook);
  const purified = DOMPurify.sanitize(html, { ADD_ATTR: ['target', 'dir'], ADD_TAGS: ['agentmention'] });
  if (setDirection) DOMPurify.removeHook('afterSanitizeAttributes');

  return { __html: purified };
}

/**
 * SafeHtml renders a string of potentially dangerous html safely using
 * DOMPurfiy.
 *
 * @param {string} html - the html to render safely (use instead of dangerouslySetInnerHTML)
 * @param {...*} props - other props (passed through)
 */
const SafeHtml = forwardRef((props, ref) => {
  const { html, setDirection, ...otherProps } = props || {};
  const dangerouslySetInnerHTML = useMemo(() => sanitize(html, { setDirection: !!setDirection }), [html, setDirection]);

  const finalProps = otherProps || {};
  if (setDirection && !finalProps.dir) finalProps.dir = 'auto';
  if (html != null) {
    finalProps.dangerouslySetInnerHTML = dangerouslySetInnerHTML;
  }

  return <div {...finalProps} ref={ref} />;
});

SafeHtml.propTypes = {
  html: PropTypes.string.isRequired,
  setDirection: PropTypes.bool,
};

export default SafeHtml;
