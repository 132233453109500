import createModel, { prop } from './lib/create_model';
const MessageContentTypeLocation = createModel({
  modelName: 'MessageContentTypeLocation',
  properties: {
    text: prop(String),
    location: prop(Object).default({}),
    buttons: prop(Object).default([]),
  },

  getBody() {
    return this.text || '';
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default MessageContentTypeLocation;
