import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';

export const InboxParticipant = ({ inboxName, isStatusFailure }) => (
  <div className="callParticipant-entry">
    <div className="callParticipant-entry-name">{inboxName}</div>
    <div
      className={classnames('callParticipant-entry-status', {
        'callParticipant-entry-status-unavailable': isStatusFailure,
      })}
    >
      {isStatusFailure ? 'No Agents Available' : 'Calling...'}
    </div>
  </div>
);

InboxParticipant.propTypes = {
  inboxName: PropTypes.string.isRequired,
  isStatusFailure: PropTypes.bool.isRequired,
};

function mapStateToProps({ getProvider }, { inboxId }) {
  let activeCall = getProvider('activeCall').get();
  let inbox = getProvider('routingGroups').findBy({ id: inboxId });
  return {
    inboxName: inbox.name,
    isStatusFailure: activeCall.didWarmTransferFail(),
  };
}

export default connect(mapStateToProps, null)(InboxParticipant);
