import _ from 'lodash';
import createReactClass from 'create-react-class';
import Immutable from 'immutable';
import Morearty from 'morearty';
import React from 'react';
import PropTypes from 'prop-types';

import AppFeaturesMixin from 'components/lib/app_features_mixin';
import Conversation from 'models/conversation';
import ConversationCard from './conversation_card';
import ConversationItem from 'models/conversation_item';
import ProvidersMixin from 'components/lib/providers_mixin';
import SharedBindingsMixin from 'components/lib/shared_bindings_mixin';

const ConversationCardContainer = createReactClass({
  mixins: [AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  propTypes: {
    conversation: PropTypes.instanceOf(Conversation),
    conversationItem: PropTypes.instanceOf(ConversationItem),
    hasDraft: PropTypes.bool,
    taskCount: PropTypes.number,
  },

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('topics'));
    this.topicsProvider = this.getProvider('topics');

    this.observeBinding(this.getSharedBinding('routingGroups'));
    this.groupsProvider = this.getProvider('routingGroups');

    this.observeBinding(this.getSharedBinding('agents'));
    this.agentsProvider = this.getProvider('agents');

    this.observeBinding(this.getSharedBinding('currentAgent'));
    this.currentAgentProvider = this.getProvider('currentAgent');
  },

  render() {
    const { conversation, conversationItem, hasDraft, taskCount } = this.props;
    const { agentId, routingGroupId } = conversation ? conversation.assignee : {};
    const { topicId } = conversation || {};

    const assignedAgent = agentId && this.agentsProvider.findBy({ id: agentId });
    const assignedGroup = routingGroupId && this.groupsProvider.findBy({ id: routingGroupId });
    const topic = topicId && this.topicsProvider.findBy({ id: topicId });
    const topics = conversation
      ? Immutable.fromJS(
          _.map(conversation.topicIds, id => {
            let t = this.getProvider('topics').findBy({ id });
            return t ? t.name : null;
          })
        )
      : Immutable.Map();

    return (
      <ConversationCard
        assignedAgent={assignedAgent}
        assignedGroup={assignedGroup}
        conversation={conversation}
        conversationItem={conversationItem}
        hasDraft={hasDraft}
        taskCount={taskCount}
        topic={topic}
        topics={topics}
      />
    );
  },
});

export default ConversationCardContainer;
