import createEnum from 'scripts/lib/create_enum';

/**
 * Domain error codes returned by the conversation summarization endpoints
 */
export const AIConversationSummarizationErrorCode = createEnum(
  'TIMEOUT',
  'TOO_LONG',
  'TOO_SHORT',
  'NO_CUSTOMER_ITEMS',
  'UNKNOWN',
  'WRONG_STATUS'
);
