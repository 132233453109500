import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import CustomerContainer from 'components/customer';
import PageLayout from 'components/page_layout';
import { useTitle } from 'components/title';

const RERENDER_DEBOUNCE_MS = 100;

export class CustomerPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleResizeCustomerPage = _.debounce(this.handleResizeCustomerPage, RERENDER_DEBOUNCE_MS);
  }

  handleResizeCustomerPage() {
    let customerPage = document.getElementById('customer-page');
    customerPage.style.display = 'none';
    void customerPage.offsetHeight; // no need to store this value anywhere, the reference is enough
    customerPage.style.display = 'block';
  }

  render() {
    return (
      <PageLayout className="customerPage" id="customer-page" showLoading={this.props.isLoading}>
        <CustomerContainer customerId={this.props.customerId} />
        <CustomerTitleContainer />
      </PageLayout>
    );
  }
}

CustomerPage.propTypes = {
  customerId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps)(CustomerPage);

function mapStateToProps({ getProvider }) {
  return {
    customerId: getProvider('currentLocation').get().customerId,
    isLoading: getIsLoading(),
  };

  function getIsLoading() {
    const providers = ['conversationHistory', 'conversations', 'note', 'profile'].map(name => getProvider(name));
    const externalDataStoreProvider = getProvider('externalDataObjects');
    const isNewConversationPending = getProvider('conversations').isPendingNew();
    const currentLocation = getProvider('currentLocation').get();

    // check the keys for all external data objects that are currently in the `loading` state
    const isExternalDataLoading = externalDataStoreProvider?.hasActiveLoadingStates();

    return (
      currentLocation.isCustomerExtensionPending ||
      isNewConversationPending ||
      isExternalDataLoading ||
      providers.some(provider => provider.isLoading())
    );
  }
}

const CustomerTitleContainer = connect(mapStateToTitleProps)(CustomerTitle);

function mapStateToTitleProps({ getProvider }) {
  const profile = getProvider('profile').get();
  return _.pick(profile, ['name', 'emails', 'phones']);
}

export function CustomerTitle({ emails, name, phones }) {
  const bestTitle =
    name ||
    (emails && emails.length && _.first(emails).original) ||
    (phones && phones.length && _.first(phones).original) ||
    'Customer';
  useTitle(bestTitle);
  return null;
}
