const Communicator = {
  AGENT: 'AGENT',
  API: 'API',
  AUTOMATED: 'AUTOMATION',
  CUSTOMER: 'CUSTOMER',
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
  SYSTEM: '',
};

export default Communicator;

export const inboundCommunicators = [Communicator.CUSTOMER, Communicator.EXTERNAL, Communicator.INTERNAL];
