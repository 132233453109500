const REMOVE_TOAST_DELAY_MILLISECONDS = 5000;

export default class RemoveToast {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    const hasToast = this.context.stores.toastsDeprecated.has({ id });
    if (hasToast) {
      this.context.stores.toastsDeprecated.remove(id);
    }
  }

  static schedule(context, { id, timeout }) {
    return context.scheduler.executeIn(timeout || REMOVE_TOAST_DELAY_MILLISECONDS, id, this, id);
  }
}
