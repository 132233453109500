import Assignee from 'models/assignee';
import { createContentModel, isEmpty } from './composition_content_shared';

const TaskCompositionContent = createContentModel({
  modelName: 'TaskCompositionContent',

  properties: {
    assignee: Assignee,
    bodyHtml: String,
    dueAt: String,
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },
});

TaskCompositionContent.MAX_SINGLE_ATTACHMENT_SIZE_MB = 500;
TaskCompositionContent.MAX_TOTAL_ATTACHMENT_SIZE_MB = 1000;

export default TaskCompositionContent;
