import SetAnswerView from 'actions/knowledge_base/set_answer_view';

export default class NavigateAnswerPanelForward {
  constructor(context) {
    this.context = context;
  }

  run(answerId) {
    let answerPanelState = this.context.stores.answerPanelState.get();
    answerPanelState.navigateForward(answerId);
    this.context.stores.answerPanelState.set(answerPanelState);

    let snippet = this.context.stores.snippetLinks.findBy({ id: answerId });
    this.context.executeAction(SetAnswerView, { snippet });
  }
}
