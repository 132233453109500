import PropTypes from 'prop-types';
import React from 'react';

import CompositionManager from 'components/customer/composition_manager';
import ConversationAssignment from 'components/customer/conversation_history/conversation_assignment';
import ConversationTopics from 'components/customer/conversation_topics';
import WorkflowButtons from 'components/customer/workflow_buttons';

const CustomerFooter = ({ isCustomAttributesEnabled }) => {
  return (
    <div className="customerV2-footer">
      <ConversationAssignment />
      <CompositionManager />
      <ConversationTopics isCustomAttributesEnabled={isCustomAttributesEnabled} />
      <WorkflowButtons />
    </div>
  );
};

CustomerFooter.propTypes = {
  isCustomAttributesEnabled: PropTypes.bool.isRequired,
};

export default React.memo(CustomerFooter);
