import _ from 'lodash';

import CompositionConverter from 'scripts/application/dto_converters/composition_converter';
import removeComposition from 'actions/composition/lib/remove_composition';

export default class FlushCompositions {
  constructor(context) {
    this.context = context;
  }

  run({ customerId }) {
    const customerIsLoaded = this.context.stores.customers.has({ id: customerId });
    if (customerIsLoaded) {
      const compositions = this.context.stores.localCompositions.get(customerId);
      const agentId = this.context.stores.currentAgent.get().id;
      const { compositions: compositionsStore } = this.context.stores.customers.storesFor(customerId);

      _.forEach(compositions, composition => {
        if (composition.isEmpty()) {
          removeComposition(this.context, composition);
        } else {
          composition.markAsPersisted();
          if (compositionsStore.has({ id: composition.id })) {
            compositionsStore.replace(composition);
          }
          this.context.gateways.composition.update(
            agentId,
            customerId,
            composition.id,
            CompositionConverter.toDto(composition)
          );
        }
      });

      this.context.stores.localCompositions.removeAllForCustomer(customerId);
    }
  }
}
