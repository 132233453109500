import { createPluginFactory, ELEMENT_LINK } from '@udecode/plate';
import { Transforms, Node, Editor } from 'slate';
import { trim } from 'lodash';

import getFirstNode from 'components/text_editor_new/lib/get_first_node';
import { isUrlValid } from 'scripts/presentation/components/lib/linkify_it';

export function createLinkPlugin() {
  return createPluginFactory({
    key: ELEMENT_LINK,
    withOverrides: withLinks,

    isElement: true,
    isInline: true,

    deserializeHtml: {
      rules: [
        {
          validNodeName: ELEMENT_LINK.toUpperCase(),
        },
      ],
      getNode: el => {
        const url = trim(el.getAttribute('href'));
        const targetAttr = trim(el.getAttribute('target'));
        const linkTarget = ['_blank', '_self'].includes(targetAttr) ? targetAttr : '';

        if (url && isUrlValid(url)) {
          return {
            type: ELEMENT_LINK,
            target: linkTarget,
            url,
          };
        }
      },
    },
  })();

  function withLinks(editor) {
    const { normalizeNode: unoverriddenNormalizeNode } = editor;

    editor.addLink = url => {
      if (editor && editor.selection) {
        const linkNodes = Editor.nodes(editor, { match: node => node.type === ELEMENT_LINK });
        for (const [, nodePath] of linkNodes) {
          Transforms.unwrapNodes(editor, { at: nodePath });
        }

        Transforms.wrapNodes(
          editor,
          { type: ELEMENT_LINK, url, target: '_blank' },
          { at: editor.selection, split: true }
        );
      }
    };

    editor.getCurrentLinkNode = () => {
      return getFirstNode(editor, { match: n => n.type === ELEMENT_LINK });
    };

    editor.isLinkSelected = () => {
      return !!editor.getCurrentLinkNode();
    };

    editor.editLink = (nodeEntry, url) => {
      const [node, nodePath] = nodeEntry || '';
      if (!node) return;

      Transforms.setNodes(editor, { type: ELEMENT_LINK, url, target: node.target || '_blank' }, { at: nodePath });
    };

    editor.normalizeNode = ([node, path]) => {
      if (node?.type === ELEMENT_LINK) {
        const text = Node.string(node);

        if (!text) {
          Transforms.removeNodes(editor, { at: path });
        }
        // Updating the link's url to an empty string or clicking remove will remove the link node by setting the URL to ''
        else if (!trim(node.url)) {
          Transforms.unwrapNodes(editor, { at: path });
        }
      } else {
        unoverriddenNormalizeNode([node, path]);
      }
    };

    return editor;
  }
}
