import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/common/button';

class InlineImageButton extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['onAttach', 'getFiles']);
  }

  onAttach(event) {
    this.input.value = '';
    this.input.click();
    event.preventDefault();
  }

  getFiles() {
    return this.input?.files || [];
  }

  render() {
    const ButtonComponent = this.props.buttonComponent || StyledImageButton;
    const buttonClassName = classnames(`${this.props.className}-inlineImageButton`, 'fa', 'fa-image');

    return (
      <label className="draftStylesMenu-item" key="label" title="Inline Image">
        <input
          accept={this.props.accept}
          className="inlineImageInput"
          onChange={() => {
            const files = this.getFiles();
            this.props.onInsertImage(files);
          }}
          ref={input => (this.input = input)}
          style={{ display: 'none' }}
          type="file"
        />
        <ButtonComponent
          className={buttonClassName}
          data-aid={this.props['data-aid']}
          onBlur={this.props.onInsertImageBlur}
          onClick={this.onAttach}
          ref={button => (this.button = button)}
          tabIndex="-1"
        />
      </label>
    );
  }
}

InlineImageButton.propTypes = {
  accept: PropTypes.string,
  buttonComponent: PropTypes.any,
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  onInsertImage: PropTypes.func.isRequired,
  onInsertImageBlur: PropTypes.func,
};

export default InlineImageButton;

const StyledImageButton = styled(Button)`
  background: inherit;
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    border: 1px solid ${p => p.theme.colors.gray200};
    border-radius: 4px;
  }
`;
