import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Chevron Down Fill
 */

export default function ChevronDownFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M7.64645 10.6464L2.85355 5.85355C2.53857 5.53857 2.76165 5 3.20711 5H12.7929C13.2383 5 13.4614 5.53857 13.1464 5.85355L8.35355 10.6464C8.15829 10.8417 7.84171 10.8417 7.64645 10.6464Z" />
    </Icon>
  );
}
