import createModel, { prop } from '../lib/create_model';
import RuleScore from 'models/rule_score';

const SmartMatchDetail = createModel({
  modelName: 'SmartMatchDetail',

  properties: {
    ruleScores: prop([RuleScore]).default([]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default SmartMatchDetail;
