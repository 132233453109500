import closeNotification from './lib/close_notification';

export default class NotificationGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onBroadcastDelete({ notificationId }) {
    closeNotification(this.context, notificationId);
  }
}
