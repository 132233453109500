import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import Tooltip from 'components/lib/deprecated_tooltip';
import ProfileAvatar from 'components/lib/profile_avatar';

export default function ObserverPill({ avatarUrl, name, selected }) {
  const circleClasses = classnames('observer-selection', {
    selected,
  });

  return (
    <Tooltip
      className={'phoneControls-observer-tooltip'}
      enabled
      key={'phoneControls-observer-tooltip'}
      message={name}
      position="bottom"
    >
      <li className="phoneControls-button phoneControls-observerContainer">
        <Circle className={circleClasses} selected={selected}>
          <ProfileAvatar isSmall profile={{ name, image: avatarUrl }} />
        </Circle>
      </li>
    </Tooltip>
  );
}

const Circle = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${p => p.theme.colors.white};
  border-radius: 15px;
  padding: 2px;
  border-style: solid;
  border-width: 1px;
  ${p => (p.selected ? `border-color: ${p.theme.colors.green400}` : `border-color: ${p.theme.colors.white}`)};
`;
