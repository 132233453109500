import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useCallback } from 'react';

import PopoverMenu, { PopoverMenuItem } from 'components/common/popover_menu_deprecated';
import useCopied from 'components/hooks/use_copied';
import { useTextEditorContext } from 'components/text_editor/text_editor_context';

export default function EmailHighlightMenu() {
  const [showCopied, onCopy] = useCopied();
  const { emailHighlight, onClickOutside } = useEmailHighlight();
  if (!emailHighlight) {
    return null;
  }

  // Find the element in the DOM pertaining to the current highlight range
  const element = document.querySelector(
    `[data-aid="email-highlight"][data-key="${emailHighlight.key}"][data-start="${emailHighlight.start}"]`
  );
  if (!element) {
    return null;
  }

  const copiedText = showCopied ? 'Copied!' : 'Copy to clipboard';
  const copyClassNames = classnames('draftEditor-inlinePhoneNumber-copy', {
    'draftEditor-inlinePhoneNumber-copy-copied': showCopied,
  });

  const menuItems = [
    <PopoverMenuItem key="copy">
      <CopyToClipboard onCopy={onCopy} text={emailHighlight.text}>
        <div className={copyClassNames}>{copiedText}</div>
      </CopyToClipboard>
    </PopoverMenuItem>,
  ];

  return (
    <PopoverMenu
      autoPosition
      boundByWindow={false}
      button={element}
      isOpen
      onClickOutside={onClickOutside}
      position="top"
      style={{ zIndex: 21 }}
    >
      {menuItems}
    </PopoverMenu>
  );
}

function useEmailHighlight() {
  const { data, setDataValue } = useTextEditorContext();

  const onClickOutside = useCallback(() => {
    setDataValue('emailHighlight', null);
  }, [setDataValue]);

  return {
    emailHighlight: data.emailHighlight,
    onClickOutside,
  };
}
