import _ from 'lodash';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActionMixin from './lib/action_mixin';
import analytics from 'scripts/lib/analytics';
import AppFeaturesMixin from 'components/lib/app_features_mixin';
import ErrorBoundary from 'components/common/utilities/error_boundary';
import ProgressBar from 'components/common/progress_bar';
import ProvidersMixin from './lib/providers_mixin';
import SharedBindings from 'components/lib/shared_bindings_mixin';

const PageLayout = createReactClass({
  mixins: [ActionMixin, AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindings],

  statics: {
    TOP_BAR_HEIGHT: 60,
  },

  propTypes: {
    children: PropTypes.node,
    className: PropTypes.string,
    showLoading: PropTypes.bool,
    childrenContainerClassName: PropTypes.string,
  },

  render() {
    const divProps = _.pick(this.props, ['id']);
    return (
      <ChildrenContainer {...divProps} className={classnames('page', this.props.className)}>
        <ProgressBar isVisible={this.props.showLoading} />
        {this.renderChildren()}
      </ChildrenContainer>
    );
  },

  renderChildren() {
    return (
      <div className={classnames('page-childrenContainer', this.props.childrenContainerClassName)}>
        <ErrorBoundary
          errorMeta={{ errorBoundary: 'Page Layout' }}
          renderError={({ onResolveError }) => <PageLayoutError onClickContinue={onResolveError} />}
        >
          {this.props.children}
        </ErrorBoundary>
      </div>
    );
  },
});

function PageLayoutError({ onClickContinue }) {
  useEffect(() => {
    analytics.track('Whoops Screen Shown', {
      componentName: 'pageLayout',
    });
  }, []);

  return (
    <div className="pageLayout-errorBoundary">
      <div>Whoops! Something went wrong.</div>
      <div className="pageLayout-errorBoundary-continue" onClick={onClickContinue}>
        Click here to continue working
      </div>
    </div>
  );
}

const ChildrenContainer = styled.div`
  flex-grow: 1;
`;

export default PageLayout;
