import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export default function HotkeyLabel({ hotkey, label }) {
  return (
    <React.Fragment>
      {label} <Hotkey>({getHotkeyLabel(hotkey)})</Hotkey>
    </React.Fragment>
  );
}

HotkeyLabel.propTypes = {
  hotkey: PropTypes.string,
  label: PropTypes.string,
};

const Hotkey = styled.span`
  color: ${p => p.theme.colors.gray600};
`;

export function getHotkeyLabel(key) {
  const keys = key.split('+');
  const convertedKeys = convertKeys(keys);
  return convertedKeys.join('-');
}

function convertKeys(keys) {
  let hostKeys = keys;
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  if (isMac) {
    hostKeys = _.map(keys, key => {
      switch (key.toLowerCase()) {
        case 'alt':
          return 'option';
        case 'ctrl':
          return '⌘';
        default:
          return key;
      }
    });
  }

  return _.map(hostKeys, key => (key.length > 1 ? _.capitalize(key) : key));
}
