import CloseModal from '../modal/close_modal';
import { DomainError } from 'scripts/application/lib/error';
import OpenWebhooks from 'actions/integrations/webhooks/open_webhooks';
import qconsole from 'scripts/lib/qconsole';
import Webhook from 'models/webhook';

export default class WebhookGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.webhooks;
  }

  onFetchAllSuccess(dto) {
    this.store.set((dto && dto.map(webhook => Webhook.fromJs(webhook))) || []);
    this.store.resetLoading();
  }

  onFetchAllError(errorDto) {
    qconsole.log(`Could not fetch webhook ${JSON.stringify(errorDto)}`);
  }

  onDeleteSuccess(dto, { webhookId }) {
    if (this.store.isPendingDelete(webhookId)) {
      this.store.commitPending(webhookId);
    }
    this.context.executeAction(OpenWebhooks);
  }

  onDeleteError(error, { webhookId }) {
    if (error instanceof DomainError) {
      this.store.setErrors(webhookId, error.errors);
    } else {
      qconsole.error(`Could not delete webhook ${JSON.stringify(error)}`);
    }
    this.store.resetPending(webhookId);
  }

  onAddSuccess(dto) {
    this.store.resetPendingNew();
    this._addOrUpdateSuccess();
  }

  onAddError(error) {
    if (error instanceof DomainError) {
      this.store.setErrorsForNew(error.errors);
    } else {
      qconsole.error(`Could not add webhook ${JSON.stringify(error)}`);
    }
    this.store.resetPendingNew();
    this._addOrUpdateError();
  }

  onUpdateSuccess(dto, { webhookId }) {
    this.store.resetPending(webhookId);
    this._addOrUpdateSuccess();
  }

  onUpdateError(error, { webhookId }) {
    if (error instanceof DomainError) {
      this.store.setErrors(webhookId, error.errors);
    } else {
      qconsole.error(`Could not update webhook ${JSON.stringify(error)}`);
    }
    this.store.resetPending(webhookId);
    this._addOrUpdateError();
  }

  onBroadcast(dto) {
    this.store.addOrReplace(Webhook.fromJs(dto));
  }

  onBroadcastDelete(dto, params) {
    this.store.remove(params.webhookId);
  }

  _addOrUpdateSuccess() {
    this.context.executeAction(CloseModal);
    this.context.executeAction(OpenWebhooks);
  }

  _addOrUpdateError() {
    this.context.executeAction(CloseModal);
  }
}
