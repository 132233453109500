import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function SnippetRevisions(props) {
  return (
    <div className="snippetRevisions">
      <div className="snippetRevisions-title">Revision History</div>
      {renderRevisions()}
    </div>
  );

  function renderRevisions() {
    let revisions = _.orderBy(props.snippetRevisions, r => r.updatedAt, 'desc').map((revision, i) => {
      return <SnippetRevision agentName={revision.agentName} key={i} updatedAt={revision.updatedAt} />;
    });

    if (!revisions.length) {
      return null;
    }
    return <ul className="snippetRevisions-list">{revisions}</ul>;
  }
}

SnippetRevisions.propTypes = {
  snippetRevisions: PropTypes.arrayOf(Object).isRequired,
};

function SnippetRevision(props) {
  let options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  let dateString = new Date(Date.parse(props.updatedAt)).toLocaleDateString('en-US', options);

  return (
    <li className="snippetRevision-listItem">
      <div className="snippetRevision-updatedAt">{dateString}</div>
      <div className="snippetRevision-agent">{props.agentName}</div>
    </li>
  );
}

SnippetRevision.propTypes = {
  updatedAt: PropTypes.string.isRequired,
  agentName: PropTypes.string.isRequired,
};
