import React, { useContext } from 'react';
import styled from 'styled-components';

import FormatterContext from 'components/composer/shared/formatter_context';

export function AboveEditorActions({ children }) {
  const { isFormattingVisible } = useContext(FormatterContext);

  if (!isFormattingVisible) {
    return null;
  }

  return <ControlsWrapper data-aid="taskCommentEditor-formattingBar">{children}</ControlsWrapper>;
}

export const ControlsWrapper = styled.div`
  display: flex;
  gap: 4px;
  justify-content: flex-start;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TextEditorWrapper = styled.div`
  [data-slate-editor='true'] {
    min-height: 2rem !important;
    border: 1px solid ${p => p.theme.colors.gray400};
    border-radius: 6px;
  }

  [data-slate-editor='true']:focus {
    border-color: ${p => p.theme.colors.green400};
  }

  margin-top: 4px;
`;

export const Below = styled.div`
  display: flex;
`;
