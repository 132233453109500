import _ from 'lodash';
import { Editor } from 'slate-old';

import { hasInlineParent } from '../helpers';
import linkifyIt from 'components/lib/linkify_it';
import { PHRASE_SUGGESTION } from 'components/text_editor/plugins/phrase_suggestions';
import { VARIABLE } from 'components/text_editor/plugins/variables';

export default function normalize(editorValue, options = {}) {
  // Use a raw slate Editor controller to allow us to modify `editorValue` without a ref to a Slate Editor.
  const editor = new Editor({
    plugins: [],
    onChange: _.noop,
    value: editorValue,
  });

  // convert variables to real text nodes so they play nice with linkify down below
  const variableInlines = editor.value.document.getInlinesByType(VARIABLE);
  if (variableInlines.count()) {
    editor.withoutSaving(() => {
      variableInlines.forEach(varInline => {
        editor.moveToRangeOfNode(varInline);
        editor.removeNodeByKey(varInline.key);
        editor.insertText(varInline.text);
      });
    });
  }

  let textNodeIndex = 0;
  while (textNodeIndex < editor.value.document.getTexts().count()) {
    // Since we may be inserting additional text nodes as we go, we need to treat editor as if it is being mutated from
    // underneath us, so we need to grab each text by index fresh from the editor each iteration.
    let textNode = editor.value.document.getTexts().get(textNodeIndex);
    if (hasInlineParent(editor, textNode)) {
      textNodeIndex++;
      continue;
    }

    let text = textNode.text;
    let matches = linkifyIt.match(text);

    // Linkify returns multiple matches for each text, but because we are going to be inserting a new text node each time
    // we insert the link inline, the indexes for the other matches will become out of sync. Easier to just let the next
    // loop interation deal with it.
    if (matches) {
      let match = matches[0];
      editor
        .moveToRangeOfNode(textNode)
        .moveStartTo(textNode.key, match.index)
        .moveEndTo(textNode.key, match.lastIndex)
        .wrapInline({
          type: 'link',
          data: {
            href: match.url,
          },
        });
    }

    textNodeIndex++;
  }

  const firstTextNode = editor.value.document.getTexts().get(0);
  if (!firstTextNode.text) {
    // For some reason, Slate doesn't like it when I remove the first text node, and will "normalize" the editor
    // after I remove the node. Can't use `editor.withoutNormalizing()`, since that also calls normalizeDirtyPaths
    // for some reason.
    // It's not that big of a deal since we're ditching this editor immediately afterwards.
    editor.tmp.normalize = false;
    editor.removeNodeByKey(firstTextNode.key);
  }

  // If they submit with a phrase suggestion still present, remove it
  const phraseSuggestionInlines = editor.value.document.getInlinesByType(PHRASE_SUGGESTION);
  if (phraseSuggestionInlines.count()) {
    editor.withoutSaving(() => {
      phraseSuggestionInlines.forEach(phraseSuggestionInline => {
        editor.removeNodeByKey(phraseSuggestionInline.key);
      });
    });
  }

  editor.tmp.normalize = true;

  // Remove empty whitespace at beginning / end of paragraphs (only space-like characters, not newlines)
  if (options.trimParagraphWhitespace) {
    // Go through each block, trim the text from the front
    editor.value.document.getBlocks().forEach(block => {
      const firstText = block.getFirstText();
      if (!firstText) return;
      const startTrimmed = firstText.text.replace(/^[^\S\r\n]+/, '');
      const firstStartDiff = firstText.text.length - startTrimmed.length;
      editor.removeTextByKey(firstText.key, 0, firstStartDiff);
    });

    // Go through each block, trim the text from the end (only space-like characters, not newlines)
    editor.value.document.getBlocks().forEach(block => {
      const lastText = block.getLastText();
      if (!lastText) return;
      const endTrimmed = lastText.text.replace(/[^\S\r\n]+$/, '');
      const lastEndDiff = lastText.text.length - endTrimmed.length;
      editor.removeTextByKey(lastText.key, lastText.text.length - lastEndDiff, lastEndDiff);
    });
  }

  // Remove empty paragraphs at beginning / end of message
  if (options.trimEmptyParagraphs) {
    // Go forwards to trim the front empty paras
    const topLevelNodes = editor.value.document.nodes;
    let hasSeenNonEmptyBlock = false;
    let blockKeysToRemove = [];
    topLevelNodes.forEach(block => {
      if (hasSeenNonEmptyBlock) {
        return;
      }
      const text = block.text.trim();
      if (text.trim() !== '') {
        hasSeenNonEmptyBlock = true;
      } else {
        blockKeysToRemove.push(block.key);
      }
    });

    // Go backwards to trim the back empty paras
    hasSeenNonEmptyBlock = false;
    topLevelNodes.reverse().forEach(block => {
      if (hasSeenNonEmptyBlock) {
        return;
      }
      const text = block.text.trim();
      if (text.trim() !== '') {
        hasSeenNonEmptyBlock = true;
      } else {
        blockKeysToRemove.push(block.key);
      }
    });
    _.forEach(_.uniq(blockKeysToRemove), key => {
      editor.removeNodeByKey(key);
    });
  }

  return editor.value;
}
