import HitClasses from 'models/search/hit_classes';
import TypeReflect from 'models/lib/type_reflect';

export const typeReflect = new TypeReflect(HitClasses);

export default function(hit) {
  function getHitClass(hitType) {
    try {
      return typeReflect.typeToConstructor(hitType);
    } catch (e) {
      throw new Error(`unknown search hit type [${hitType}]`);
    }
  }

  return getHitClass(hit.type).fromJs(hit);
}
