import Immutable from 'immutable';
import notificationFromJs, { typeReflect } from 'models/notification/notification_from_js';

export default {
  fromImmutable(immutableMap) {
    return notificationFromJs(immutableMap.toJS());
  },

  toImmutable(notification) {
    return Immutable.fromJS(notification.toJs());
  },
};

export function typeFromNotification(notification) {
  return typeReflect.instanceToType(notification);
}
