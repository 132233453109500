import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/icons/icon';

export default props => (
  <StyledIcon viewBox="0 0 14 14" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M8.955 2.33a.316.316 0 0 0-.205.295c0 .131.082.249.205.295l1.545.58.58 1.545a.316.316 0 0 0 .59 0l.58-1.545 1.545-.58a.316.316 0 0 0 0-.59l-1.545-.58-.58-1.545a.316.316 0 0 0-.59 0L10.5 1.75l-1.545.58Zm-3.347-.326a.436.436 0 0 0-.793 0L3.371 5.121.254 6.562a.437.437 0 0 0 0 .796L3.374 8.8l1.438 3.117a.436.436 0 0 0 .793 0l1.441-3.12 3.12-1.44a.436.436 0 0 0 0-.793L7.05 5.123l-1.44-3.12ZM10.5 10.5l-1.545.58a.316.316 0 0 0 0 .59l1.545.58.58 1.545a.316.316 0 0 0 .59 0l.58-1.545 1.545-.58a.316.316 0 0 0 0-.59l-1.545-.58-.58-1.545a.316.316 0 0 0-.59 0L10.5 10.5Z"
        fill="#767676"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h14v14H0z" fill="#fff" />
      </clipPath>
    </defs>
  </StyledIcon>
);

const StyledIcon = styled(Icon)`
  height: 14px;
  width: 14px;
`;
