import _ from 'lodash';

import CompositionConverter from 'scripts/application/dto_converters/composition_converter';

export default class SaveAndRemoveUnpersistedCompositions {
  constructor(context) {
    this.context = context;
  }

  run({ agentId }) {
    const compositions = this.context.stores.localCompositions.getAll();
    _.forEach(compositions, composition => {
      if (!composition.content.isEmpty || !composition.content.isEmpty()) {
        composition.markAsPersisted();
        this.context.gateways.composition.update(
          agentId,
          composition.customerId,
          composition.id,
          CompositionConverter.toDto(composition)
        );
      }
    });
  }
}
