import createModel from '../lib/create_model';

const AssignedTaskClosed = createModel({
  modelName: 'AssignedTaskClosed',

  properties: {
    customerId: String,
    taskId: String,
  },
});

export default AssignedTaskClosed;
