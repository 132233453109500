import _ from 'lodash';
import { createPluginFactory } from '@udecode/plate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import { Editor, Node, Range, Text, Transforms } from 'slate';

import createUndoHistoryBreak from 'components/text_editor_new/lib/create_undo_history_break';
import getLastNode from 'components/text_editor_new/lib/get_last_node';

export default function createCapitalizeFirstWordPlugin() {
  return createPluginFactory({
    key: 'capitalize-first-word',

    handlers: {
      onKeyDown: editor => evt => {
        return capitalizeFirstWord(editor, evt);
      },
    },
  })();
}

export function capitalizeFirstWord(editor, evt) {
  // Composing probably means using an IME (e.g. Japanese keyboard) to type.
  if (ReactEditor.isComposing(editor)) {
    return;
  }

  let { selection } = editor;

  // We only capitalize if you're typing normally, with a collapsed selection
  if (!selection || !Range.isCollapsed(selection)) {
    return;
  }

  const lastNodeEntry = getLastNode(editor, {
    at: selection,
    match: Text.isText,
  });
  if (!lastNodeEntry) {
    return;
  }

  // We only want to capitalize the word if we're adding characters to the end of a text
  const [lastTextNode, lastTextPath] = lastNodeEntry;
  if (!lastTextNode || !lastTextNode.text || selection.anchor.offset !== lastTextNode.text.length) {
    return;
  }

  // Get the parent block that contains the current selection so we can find all of the relevant
  // nearby text nodes.
  const [, parentBlockPath] = Editor.above(editor, {
    match: n => Editor.isBlock(editor, n),
  });

  // Collate all of the consecutive children from the end of the block until we hit a non-text node,
  // such as an inline node like a link.
  let precedingTextNodeEntries = [];
  let collatedText = '';
  for (const childNodeEntry of Node.children(editor, parentBlockPath, { reverse: true })) {
    const [childNode] = childNodeEntry;
    if (Text.isText(childNode)) {
      precedingTextNodeEntries.unshift(childNodeEntry);
      collatedText = `${childNode.text}${collatedText}`;
    } else {
      break;
    }
  }

  // If parts of a text have different marks, they will actually be different text nodes. To account
  // for this, we combine all of the text node texts together into `collatedText`. Theoretically,
  // a single uncapitalized word could have multiple marks, meaning its composed of multiple text nodes.
  const offset = collatedText.length;
  const text = collatedText;

  // We only capitalize after we hit a whitespace character.
  const charRegexp = new RegExp(/^\s$/);
  if (!charRegexp.test(evt.key)) {
    return;
  }

  let wordRange = { start: offset, end: offset };

  // Grab the last word finished by the agent, including punctuation (except we only include up to the first
  // terminating character.)
  // Examples:
  //   "Start. Hi,"            => "Hi,"
  //   "Start. www.google.com" => "com"
  //   "Start. Great."         => "Great."
  const alphabetOrApostropheRegexp = new RegExp(/[a-zA-Z',:]/);
  const termRegexp = new RegExp(/[!?.]/);
  let hasMatchedTerminatingOnce = false;
  let hasMatchedWordOnce = false;
  let i = offset - 1;
  for (; i >= 0; i--) {
    const char = text.charAt(i);
    if (termRegexp.test(char)) {
      if (hasMatchedWordOnce || hasMatchedTerminatingOnce) {
        break;
      }
      wordRange.start = i;
      hasMatchedTerminatingOnce = true;
    } else if (alphabetOrApostropheRegexp.test(char)) {
      hasMatchedWordOnce = true;
      wordRange.start = i;
    } else {
      break;
    }
  }

  const word = text.slice(wordRange.start, wordRange.end);
  if (!word) {
    return;
  }

  // If it's already capitalized, nothing to do.
  const firstCharacter = word.charAt(0);
  if (firstCharacter === firstCharacter.toUpperCase()) {
    return;
  }

  // This matched word must either be at the start of the block or it must have a space in front of it.
  if (wordRange.start > 0 && !charRegexp.test(text.charAt(wordRange.start - 1))) {
    return;
  }

  // Search for a terminating character. If we don't find one before a non-whitespace character, then this
  // isn't the first word in a sentence, so there's nothing to do.
  let shouldCapitalize = true;
  let encounteredTerminus = false;
  const terminusRegexp = new RegExp(/[.?!]/);
  const nonWhitespaceRegexp = new RegExp(/\S/);
  for (; i >= 0; i--) {
    const char = text.charAt(i);
    if (terminusRegexp.test(char)) {
      encounteredTerminus = true;
      break;
    } else if (nonWhitespaceRegexp.test(char)) {
      shouldCapitalize = false;
      break;
    }
  }
  if (!shouldCapitalize) {
    return;
  }

  // If we haven't encountered a terminus, we don't want to accidentally think this is
  // the first word in a sentence if there's an inline before it. For example, if we type
  // the first word after a variable, we shouldn't try to autocapitalize that.
  const previousNodeEntry = Editor.previous(editor, { at: lastTextPath, match: n => editor.isInline(n) });
  if (!encounteredTerminus && previousNodeEntry) {
    return;
  }

  // Insert the text and _then_ change the capitalization. This way, an agent can hit undo if they
  // didn't want that word capitalized.
  evt.preventDefault();
  HistoryEditor.withoutMerging(editor, () => {
    Transforms.insertText(editor, evt.key);
  });

  // Force push new undo batch so that hitting undo only un-does the capitalization.
  createUndoHistoryBreak(editor);

  // Find the path to the text node that contains the word we want to capitalize. We collated
  // all of the text ranges together, meaning `wordRange.start` is relative to that large text
  // string, so we need to find the node that contains it.
  let index = 0;
  let editPath;
  for (const [node, path] of precedingTextNodeEntries) {
    editPath = path;
    if (index + node.text.length > wordRange.start) {
      break;
    } else {
      index += node.text.length;
    }
  }
  let finalOffset = wordRange.start - index;

  const capitalizeWordSelection = {
    anchor: {
      offset: finalOffset,
      path: editPath,
    },
    focus: {
      offset: finalOffset + 1,
      path: editPath,
    },
  };

  let newSelection = editor.selection;
  Transforms.insertText(editor, _.capitalize(word.charAt(0)), { at: capitalizeWordSelection });
  Transforms.select(editor, newSelection);

  return true;
}
