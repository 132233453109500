import createCollectionStoreClass from './lib/create_collection_store_class';
import ConversationConverter from '../immutable_converters/conversation_converter';
import qconsole from 'scripts/lib/qconsole';

const ConversationsStoreBase = createCollectionStoreClass({
  converter: ConversationConverter,
});

export default class ConversationsStore extends ConversationsStoreBase {
  _fromImmutable(immutableConversation) {
    return ConversationConverter.fromImmutable(immutableConversation);
  }

  addOrReplace(entity) {
    const foundEntity = this.findBy({ id: entity.id });

    if (foundEntity && foundEntity.version && entity.version < foundEntity.version) {
      qconsole.warn(`
          Attempting to add or replace conversation [${entity.id}] at version [${foundEntity.version}] with an older version [${entity.version}]
        `);
      return;
    }
    super.addOrReplace(entity);
  }
}
