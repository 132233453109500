import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BlindTransferPhoneCall from 'actions/phone_call/blind_transfer_phone_call';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import InitiateWarmTransfer from 'actions/phone_call/initiate_warm_transfer';

export class TransferButtonOptions extends PureComponent {
  render() {
    return (
      <div className="transferButtonOptions-buttons">
        <Button
          buttonType={ButtonTypes.SECONDARY}
          className="transferButtonOptions-button-warm"
          onClick={this.props.onWarmSelect}
        >
          Warm
        </Button>
        <Button
          buttonType={ButtonTypes.SECONDARY}
          className="transferButtonOptions-button-cold"
          onClick={this.props.onColdSelect}
        >
          Cold
        </Button>
      </div>
    );
  }
}

TransferButtonOptions.propTypes = {
  onColdSelect: PropTypes.func.isRequired,
  onWarmSelect: PropTypes.func.isRequired,
};

const TransferButtonOptionsContainer = connect(undefined, mapExecuteToProps)(TransferButtonOptions);

TransferButtonOptionsContainer.propTypes = {
  agentId: PropTypes.string,
  currentAgentId: PropTypes.string,
  onClose: PropTypes.func,
  routingGroupId: PropTypes.string,
};

function mapExecuteToProps(executeAction, { agentId, currentAgentId, onClose, routingGroupId }) {
  return {
    onColdSelect: () => {
      executeAction(BlindTransferPhoneCall, routingGroupId);
      onClose();
    },
    onWarmSelect: () =>
      executeAction(InitiateWarmTransfer, {
        fromAgentId: currentAgentId,
        toAgentId: agentId,
        toRoutingGroupId: routingGroupId,
      }),
  };
}

export default TransferButtonOptionsContainer;
