import _ from 'lodash';

import { AgentParticipantStatus } from 'models/routing_queue_item';
import { areCompositionsReady } from 'actions/composition/lib/composition_helpers';
import changeComposition from 'actions/composition/lib/change_composition';
import ChatCompositionContent from 'models/composition/chat_composition_content';
import ConversationMessageCompositionContent from 'models/composition/conversation_message_composition_content';
import CreateChatComposition from 'actions/composition/create_chat_composition';
import CreateInstagramComposition from 'actions/composition/create_instagram_composition';
import CreateFbMessageComposition from '../composition/create_fb_message_composition';
import CreateNoteComposition from '../composition/create_note_composition';
import CreateSmsComposition from '../composition/create_sms_composition';
import CreateTweetComposition from '../composition/create_tweet_composition';
import CreateWhatsAppComposition from '../composition/create_whatsapp_composition';
import CreateCustomChannelComposition from '../composition/create_custom_channel_composition';
import CreditCardCompositionContent from 'models/composition/credit_card_composition_content';
import CustomerView from 'models/location/customer_view';
import FbMessageCompositionContent from 'models/composition/fb_message_composition_content';
import { getActiveConversationId, getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import { getLatestManualCustomerConversationItemAttrs } from 'scripts/application/lib/conversation_history_helpers';
import { getActiveSessionOfTypeByCustomerForAgent } from 'actions/communication_session/session_finder';
import InteractionType, { baseInteractionType, customId, getInteractionType } from 'models/interaction_type';
import NoteCompositionContent from 'models/composition/note_composition_content';
import SmsCompositionContent from 'models/composition/sms_composition_content';
import CustomChannelComposition from 'models/composition/custom_channel_composition_content';
import qconsole from 'scripts/lib/qconsole';

/* When we load the customer page, we need to decide which composition that we have loaded should be the current
 * composition. The problem is that if we have, say, an active realtime session going on in the feed, we want to bring
 * up either an existing realtime messaging session (sms, facebook, chat, etc) draft composition or a new composition by default based off of the channel the customer last used,
 * ignoring other existing drafts.
 *
 * So now setting the current composition depends on conversations (so we can find the active composition) and active sessions, on top of compositions.
 */
export default class CurrentCompositionUpdater {
  constructor(context) {
    this.context = context;
  }

  update() {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      return;
    }

    const customerId = currentLocation.customerId;
    const customerStores = this.context.stores.customers.storesFor(customerId);
    const conversationId = getActiveConversationId(customerStores.conversations);
    const previousConversationId = getLatestConversationId(customerStores.conversations);
    if (!areCompositionsReady(this.context)) {
      return;
    }

    if (
      currentLocation.currentCompositionId &&
      !this.compositionsStore.has({ id: currentLocation.currentCompositionId })
    ) {
      qconsole.log('Removing current composition because it is no longer in the store');
      currentLocation.clearCurrentComposition();
      this.context.stores.currentLocation.set(currentLocation);
    }

    let compositions = _.sortBy(this.compositionsStore.findAll(), c => Date.parse(c.updatedAt));
    compositions = _.filter(compositions, composition => !this.compositionsStore.isPendingDelete(composition.id));

    if (!conversationId && !_.find(compositions, c => c.conversationId === previousConversationId)) {
      return;
    }

    const chatComposition = _.find(compositions, c => c.content instanceof ChatCompositionContent);

    const currentAgent = this.context.stores.currentAgent.get();
    const activeCall = this.context.stores.activeCall.get();
    const isAgentCurrentlyOnCall = activeCall && activeCall.isAgentOnLiveCall(currentAgent.id);
    const isActiveCallForCurrentCustomer = activeCall && activeCall.customer.id === currentLocation.customerId;
    const shouldAutoOpenCallNote = this.context.stores.voiceConfiguration.get().shouldAutoOpenCallNote;

    qconsole.log(`Updating current composition for ${customerId}`);
    if (
      chatComposition &&
      !getActiveSessionOfTypeByCustomerForAgent(
        this.context.stores.activeSessions,
        customerId,
        currentAgent.id,
        InteractionType.CHAT
      )
    ) {
      this.removeCompositionByContentType(CreditCardCompositionContent);
      this.removeCompositionByContentType(ChatCompositionContent);
      this.changeComposition();
    } else if (this.hasActiveSession(customerId, currentAgent.id)) {
      // for realtime messaging channels
      this.showCompositionForMessagingChannel(customerId, conversationId, compositions);
    } else if (isAgentCurrentlyOnCall && isActiveCallForCurrentCustomer && shouldAutoOpenCallNote) {
      this.showNoteComposition();
    } else if (!_.isEmpty(compositions) && !currentLocation.currentCompositionId) {
      changeComposition(this.context, _.last(compositions));
    } else if (_.isEmpty(compositions)) {
      changeComposition(this.context, null);
    }
  }

  hasActiveSession(customerId, agentId) {
    let activeSession = this.context.stores.activeSessions.findBy({ customer: { id: customerId } });
    return (
      activeSession && activeSession.queueItem.getAgentParticipantStatus(agentId) === AgentParticipantStatus.ACTIVE
    );
  }

  showCompositionForMessagingChannel(customerId, conversationId) {
    let currentLocation = this.context.stores.currentLocation.get();
    const compositions = this.compositionsStore.findAll({ customerId });
    const customerStores = this.context.stores.customers.storesFor(customerId);

    const activeSession = this.context.stores.activeSessions.findBy({ customer: { id: customerId } });

    const lastCustomerMessage = getLatestManualCustomerConversationItemAttrs({
      conversationHistory: customerStores.conversationHistory,
      conversationId,
      fields: ['content'],
    });

    // Don't open a composition if the agent has already closed the composition window manually for this customer
    if (currentLocation.compositionLastClosedAt) {
      return;
    }

    // if there's no message from the customer, defualt to displaying the composition for the latest session in the queue item
    let sessionType = _.get(activeSession.queueItem.getLatestSession(), 'type');
    if (
      lastCustomerMessage &&
      activeSession.queueItem.getSessionByType(
        getInteractionType(lastCustomerMessage.content.type, lastCustomerMessage.content)
      )
    ) {
      sessionType = getInteractionType(lastCustomerMessage.content.type, lastCustomerMessage.content);
    }

    const hasCurrentComposition = !!currentLocation.currentCompositionId;

    if (sessionType === InteractionType.SMS) {
      let smsComposition = _.find(compositions, c => c.content instanceof SmsCompositionContent);
      if (smsComposition) {
        if (!hasCurrentComposition) {
          changeComposition(this.context, smsComposition);
        }
        return;
      }
      this.context.executeAction(CreateSmsComposition, {
        conversationId,
        dontActivate: hasCurrentComposition,
      });
    } else if (sessionType === InteractionType.CHAT) {
      let chatComposition = _.find(compositions, c => c.content instanceof ChatCompositionContent);
      if (chatComposition) {
        if (!hasCurrentComposition) {
          changeComposition(this.context, chatComposition);
        }
        return;
      }
      this.context.executeAction(CreateChatComposition, {
        conversationId: activeSession.conversationId(),
        sessionId: activeSession.queueItem.getSessionByType(InteractionType.CHAT).id,
        dontActivate: hasCurrentComposition,
      });
    } else if (sessionType === InteractionType.FB_MESSENGER) {
      let fbMessageComposition = _.find(compositions, c => c.content instanceof FbMessageCompositionContent);
      if (fbMessageComposition) {
        if (!hasCurrentComposition) {
          changeComposition(this.context, fbMessageComposition);
        }
        return;
      }
      this.context.executeAction(CreateFbMessageComposition, {
        conversationId,
        dontActivate: hasCurrentComposition,
      });
    } else if (sessionType === InteractionType.INSTAGRAM_DIRECT) {
      let instagramComposition = _.find(
        compositions,
        c => c.content instanceof ConversationMessageCompositionContent && c.content.channel === sessionType
      );
      if (instagramComposition) {
        if (!hasCurrentComposition) {
          changeComposition(this.context, instagramComposition);
        }
        return;
      }
      this.context.executeAction(CreateInstagramComposition, {
        conversationId,
        dontActivate: hasCurrentComposition,
      });
    } else if (sessionType === InteractionType.TWITTER) {
      let tweetComposition = _.find(
        compositions,
        c => c.content instanceof ConversationMessageCompositionContent && c.content.channel === sessionType
      );
      if (tweetComposition) {
        if (!hasCurrentComposition) {
          changeComposition(this.context, tweetComposition);
        }
        return;
      }
      this.context.executeAction(CreateTweetComposition, {
        conversationId,
        dontActivate: hasCurrentComposition,
      });
    } else if (sessionType === InteractionType.WHATSAPP) {
      let whatsappComposition = _.find(
        compositions,
        c => c.content instanceof ConversationMessageCompositionContent && c.content.channel === sessionType
      );
      if (whatsappComposition) {
        if (!hasCurrentComposition) {
          changeComposition(this.context, whatsappComposition);
        }
        return;
      }
      this.context.executeAction(CreateWhatsAppComposition, {
        conversationId,
        dontActivate: hasCurrentComposition,
      });
    } else if (baseInteractionType(sessionType) === InteractionType.CUSTOM_CHANNEL) {
      let customChannelComposition = _.find(compositions, c => c.content instanceof CustomChannelComposition);
      if (customChannelComposition) {
        if (!hasCurrentComposition) {
          changeComposition(this.context, customChannelComposition);
        }
        return;
      }
      this.context.executeAction(CreateCustomChannelComposition, {
        conversationId,
        customChannelId: customId(sessionType),
        dontActivate: hasCurrentComposition,
      });
    }
    // Unhandled session type, default to just setting the current composition to the latest composition.
    // We'll hit this if our only active session is email, for example.
    else if (!hasCurrentComposition && compositions.length > 0) {
      changeComposition(this.context, _.last(compositions));
    }
  }

  removeCompositionByContentType(CompositionContentType) {
    const currentLocation = this.context.stores.currentLocation.get();
    const compositions = this.compositionsStore.findAll({ customerId: currentLocation.customerId });
    const composition = _.find(compositions, c => c.content instanceof CompositionContentType);

    if (!composition) {
      return;
    }

    this.compositionsStore.remove(composition.id);
    this.context.gateways.composition.remove(this.currentAgentId, this.currentCustomerId, composition.id);
  }

  changeComposition() {
    let currentLocation = this.context.stores.currentLocation.get();
    let compositions = this.compositionsStore.findAll({ customerId: currentLocation.customerId });
    compositions = _.filter(compositions, c => !this.compositionsStore.isPendingDelete(c.id));

    let nextComposition = _.isEmpty(compositions) ? null : _.last(compositions);
    changeComposition(this.context, nextComposition);
  }

  showNoteComposition() {
    const currentLocation = this.context.stores.currentLocation.get();
    let compositions = this.compositionsStore.findAll({ customerId: currentLocation.customerId });
    compositions = _.filter(compositions, c => !this.compositionsStore.isPendingDelete(c.id));

    let noteComposition = _.find(compositions, c => c.content instanceof NoteCompositionContent);

    if (noteComposition && currentLocation.currentCompositionId !== noteComposition.id) {
      changeComposition(this.context, noteComposition);
    } else if (!noteComposition) {
      this.context.executeAction(CreateNoteComposition, {
        conversationId: currentLocation.currentConversationId,
      });
    }
  }

  get currentAgentId() {
    return this.context.stores.currentAgent.get().id;
  }

  get currentCustomerId() {
    return this.context.stores.currentLocation.get().customerId;
  }

  get compositionsStore() {
    return getCompositionsStore(this.context);
  }
}
