import React from 'react';
import PropTypes from 'prop-types';

import AgentProfile from 'models/agent_profile';
import ConversationNotification from 'models/notification/conversation_notification';
import NotificationContentContainer from './notification_content_container';

function TaskItemNotification({ currentAgent, notification }) {
  const heading = currentAgent ? `${currentAgent.name} your task is due` : 'Your task is due';

  return (
    <NotificationContentContainer
      body={notification.conversationItem.content.note}
      containerClasses="taskItemNotification"
      heading={heading}
      iconClass="icon-task-filled"
      itemType="Task"
      notification={notification}
    />
  );
}

TaskItemNotification.propTypes = {
  currentAgent: PropTypes.instanceOf(AgentProfile),
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default TaskItemNotification;
