import { Factory } from 'rosie';

Factory.define('Language').attr('name', 'LANGUAGE');

Factory.define('LanguageWithDefaults')
  .extend('Language')
  .attr('id', 'default-id')
  .attr('code', 'en-us')
  .attr('language', 'en')
  .attr('region', 'us');
