export const gdocsListRules = [
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'ul') {
        return { object: 'block', type: 'unordered_list', nodes: next(el.childNodes) };
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'ol') {
        return { object: 'block', type: 'ordered_list', nodes: next(el.childNodes) };
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'li') {
        const textAlign = el.style.textAlign;
        return { object: 'block', type: 'list_item', nodes: next(el.childNodes), data: { textAlign } };
      }
    },
  },
];
