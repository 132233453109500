import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import SuggestedReply from 'models/suggested_reply';

export default createModel({
  modelName: 'SelectedSuggestedReply',

  properties: {
    item: prop(SuggestedReply).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(_.assign({ id: IdGenerator.newId() }, attrs));
    },
  },
});
