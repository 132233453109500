import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Task Stroke
 */

export default function TaskStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M6.1138 2C6.50097 2 6.85334 1.77652 7.01839 1.4263C7.08317 1.28883 7.39909 1 8 1C8.60091 1 8.91683 1.28883 8.98161 1.4263C9.14666 1.77652 9.49903 2 9.8862 2H11V3H5V2H6.1138ZM8 0C8.87081 0 9.61164 0.417404 9.8862 1H11.5C11.7761 1 12 1.22386 12 1.5V2H13C13.5523 2 14 2.44772 14 3V15C14 15.5523 13.5523 16 13 16H3C2.44771 16 2 15.5523 2 15V3C2 2.44772 2.44772 2 3 2H4V1.5C4 1.22386 4.22386 1 4.5 1H6.1138C6.38836 0.417404 7.12919 0 8 0ZM4 3H3V15H13V3H12V3.5C12 3.77614 11.7761 4 11.5 4H4.5C4.22386 4 4 3.77614 4 3.5V3ZM11.361 6.65405C11.5521 6.85342 11.5453 7.16993 11.346 7.36099L7.17204 11.361C6.97307 11.5517 6.65732 11.5454 6.46607 11.347L4.63999 9.45223C4.44836 9.2534 4.4542 8.93688 4.65303 8.74525C4.85186 8.55362 5.16839 8.55946 5.36002 8.75829L6.84011 10.294L10.654 6.63901C10.8534 6.44794 11.1699 6.45468 11.361 6.65405Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
