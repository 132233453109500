import AgentActivity from 'models/agent_activity';
import CustomerView from 'models/location/customer_view';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import CompositionContentType from 'models/composition/composition_content_type';
import RequestAISuggestedReply from 'actions/ai_suggested_replies/request_ai_suggested_reply';
import { typeReflect as CompositionTypeReflect } from 'models/composition/composition_content_from_js';

export default function changeComposition(context, composition) {
  let currentLocation = context.stores.currentLocation.get();
  if (!(currentLocation instanceof CustomerView)) {
    return;
  }

  context.executeAction(SetAndLogActivity, getActivity(composition));

  // Do not scroll if a task edit
  if (composition && composition.contentType() === CompositionContentType.TASK_EDIT) {
    currentLocation.changeCurrentConversationItem(composition.content.conversationItemId);
  }

  currentLocation.changeCurrentComposition((composition && composition.id) || null);
  context.stores.currentLocation.set(currentLocation);

  if (composition) {
    const type = CompositionTypeReflect.instanceToType(composition.content);
    context.executeAction(RequestAISuggestedReply, { customerId: composition.customerId, type });
  }
}

function getActivity(composition) {
  if (composition) {
    return {
      type: AgentActivity.Type.COMPOSITION_OPEN,
      channel: resolveChannel(composition),
    };
  }

  return {
    type: AgentActivity.Type.COMPOSITION_CLOSE,
  };
}

function resolveChannel(composition) {
  const contentType = composition.contentType();
  if (contentType === CompositionContentType.CONVERSATION_MESSAGE) {
    return composition.content.channel;
  }

  if (contentType === CompositionContentType.CUSTOM_CHANNEL) {
    return `${CompositionContentType.CUSTOM_CHANNEL}:${composition.content.customChannelId}`;
  }

  return contentType;
}
