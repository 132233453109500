import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import ActiveCall from 'models/active_call';
import AgentProfile from 'models/agent_profile';
import AutoAcceptedSessionNotification from './auto_accepted_session_notification';
import ConversationNotification from './conversation_notification';
import DeclinedSessionReassignmentNotification from './declined_session_reassignment_notification';
import DisconnectedNotification from './disconnected_notification';
import DismissNotificationButtonContainer from './dismiss_notification_button';
import EmbedTokenExpiringNotification from './embed_token_expiring_notification';
import ErrorNotification from './error_notification';
import FocusModeDisabledNotification from 'components/notification/focus_mode_disabled_notification';
import IncomingCallNotificationContainer from './incoming_call_notification';
import InboundCommunicationSessionNotification from './inbound_communication_session_notification';
import InboundMessageNotification from 'components/notification/inbound_message_notification';
import MultipleTabsNotification from './multiple_tabs_notification';
import MissedCallNotification from './missed_call_notification';
import Notification from './notification';
import NotificationClasses from 'models/notification/notification_classes';
import NotificationType from 'models/notification/notification_type';
import OptionalReloadNotificationContainer from './optional_reload_notification';
import OptionalUpgradeNotificationContainer from './optional_upgrade_notification';
import PaymentStatusNotification from './payment_status_notification';
import PhoneCallObserverNotification from './phone_call_observer_notification';
import TaskNotification from './task_notification';
import UnofferedWarmTransferNotification from './unoffered_warm_transfer_notification';
import { typeFromNotification } from 'scripts/adapters/immutable_converters/notification_converter';
import UnofferedCallTypes, { UnofferedCallMessages } from 'models/routing_event/unoffered_call_type';

function Notifications({ activeCall, currentAgent, onDismissNotification, notifications }) {
  let hasActiveCall = activeCall && activeCall.shouldDisplayIncomingCallNotification(currentAgent && currentAgent.id);
  return (
    <div className="notificationList">
      {hasActiveCall && <IncomingCallNotificationContainer />}
      <InboundCommunicationSessionNotification />
      {notifications.length > 0 && _.map(notifications, notification => renderNotification(notification))}
    </div>
  );

  function renderNotification(notification) {
    const notificationType = typeFromNotification(notification);
    switch (notificationType) {
      case NotificationType.ADMIN:
        return renderAdminNotification();
      case NotificationType.ASSIGNED_CONVERSATION:
        return renderConversationNotification();
      case NotificationType.ASSIGNED_TASK:
        return renderTaskNotification();
      case NotificationType.AUTO_ACCEPTED_SESSION_NOTIFICATION:
        return renderAutoAcceptedSessionNotification();
      case NotificationType.DECLINED_REASSIGNED_SESSION:
        return renderDeclinedSessionReassignmentNotification();
      case NotificationType.DISCONNECT:
        return renderUnstableConnectionNotification();
      case NotificationType.DUE_TASK:
        return renderTaskNotification();
      case NotificationType.EMBED_TOKEN_EXPIRING:
        return renderEmbedTokenExpiringNotification();
      case NotificationType.ERROR:
        return renderErrorNotification();
      case NotificationType.FOCUS_MODE_DISABLED:
        return renderFocusModeDisabledNotification();
      case NotificationType.INBOUND_MESSAGE:
        return renderInboundMessageNotification();
      case NotificationType.MULTIPLE_TABS:
        return renderMultipleTabsNotification();
      case NotificationType.OPTIONAL_RELOAD:
        return renderOptionalReloadNotificaiton();
      case NotificationType.OPTIONAL_UPGRADE:
        return renderOptionalUpgradeNotification();
      case NotificationType.PHONE_CALL_OBSERVER:
        return renderPhoneCallObserverNotification();
      case NotificationType.SUCCESS:
        return renderSuccessNotification();
      case NotificationType.UNOFFERED_CALL:
        return renderUnofferedCallNotification();
      case NotificationType.UNOFFERED_WARM_TRANSFER:
        return renderUnofferedWarmTransferNotification();
      case NotificationType.PAYMENT_STATUS_EVENT:
        return renderPaymentStatusUpdate();
      default:
        return null;
    }

    function renderUnstableConnectionNotification() {
      return (
        <DisconnectedNotification
          id={notification.id}
          key={`notification-${notification.id}`}
          onDismiss={onDismissNotification}
        />
      );
    }

    function renderAdminNotification() {
      return (
        <Notification
          contentClasses="adminNotification"
          key={`notification-${notification.id}`}
          notificationId={notification.id}
          onClick={onDismissNotification}
        >
          <div className="adminNotification-message">{notification.message}</div>
          <DismissNotificationButtonContainer notificationId={notification.id} />
        </Notification>
      );
    }

    function renderAutoAcceptedSessionNotification() {
      return <AutoAcceptedSessionNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderConversationNotification() {
      return (
        <ConversationNotification
          currentAgent={currentAgent}
          key={`notification-${notification.id}`}
          notification={notification}
        />
      );
    }

    function renderTaskNotification() {
      return <TaskNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderEmbedTokenExpiringNotification() {
      return <EmbedTokenExpiringNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderErrorNotification() {
      return <ErrorNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderFocusModeDisabledNotification() {
      return <FocusModeDisabledNotification id={notification.id} />;
    }

    function renderDeclinedSessionReassignmentNotification() {
      return (
        <DeclinedSessionReassignmentNotification key={`notification-${notification.id}`} notification={notification} />
      );
    }

    function renderInboundMessageNotification() {
      return <InboundMessageNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderMultipleTabsNotification() {
      return <MultipleTabsNotification id={notification.id} key={`${notification.id}`} />;
    }

    function renderOptionalReloadNotificaiton() {
      return activeCall ? null : (
        <OptionalReloadNotificationContainer key={`notification-${notification.id}`} notification={notification} />
      );
    }

    function renderPhoneCallObserverNotification() {
      return <PhoneCallObserverNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderOptionalUpgradeNotification() {
      return notification.isPostponed || activeCall ? null : (
        <OptionalUpgradeNotificationContainer
          key={`notification-${notification.id}`}
          notificationId={notification.id}
        />
      );
    }

    function renderUnofferedCallNotification() {
      if (notification.reason === UnofferedCallTypes.AGENT_TIMEOUT) {
        return <MissedCallNotification key={`notification-${notification.id}`} notification={notification} />;
      }

      return (
        <Notification
          contentClasses="unofferedCallNotification"
          key={`notification-${notification.id}`}
          notificationId={notification.id}
        >
          <div className="unofferedCallNotification-message">{UnofferedCallMessages[notification.reason]}</div>
          <DismissNotificationButtonContainer notificationId={notification.id} />
        </Notification>
      );
    }

    function renderUnofferedWarmTransferNotification() {
      return <UnofferedWarmTransferNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderPaymentStatusUpdate() {
      return <PaymentStatusNotification key={`notification-${notification.id}`} notification={notification} />;
    }

    function renderSuccessNotification() {
      return (
        <Notification
          contentClasses="successNotification"
          data-aid="success-notification"
          key={`notification-${notification.id}`}
          notificationId={notification.id}
          onClick={onDismissNotification}
        >
          <i className="successNotification-icon" />
          <div data-aid="successNotification-message">{notification.message}</div>
        </Notification>
      );
    }
  }
}

Notifications.propTypes = {
  activeCall: PropTypes.instanceOf(ActiveCall),
  currentAgent: PropTypes.instanceOf(AgentProfile),
  notifications: PropTypes.arrayOf(PropTypes.oneOfType(_.map(NotificationClasses, c => PropTypes.instanceOf(c[1]))))
    .isRequired,
  onDismissNotification: PropTypes.func.isRequired,
};

export default Notifications;
