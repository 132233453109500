import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';

export default function ProactiveChatItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  let iconClass = 'ion-ios-world-outline';
  let subject = 'Proactive Chat';
  let text = getItemMetadataText(item.content);
  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata
        iconClass={iconClass}
        item={item}
        showMetadata={!isStacked}
        subject={subject}
        text={text}
        tooltip={null}
      />
      <ProactiveChatContent className={className} item={item} />
    </ItemContent>
  );

  function getItemMetadataText(content) {
    if (content.isGreetingMessage()) {
      return 'greeting';
    }
    return '';
  }
}

ProactiveChatItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export function ProactiveChatContent({ className, item }) {
  return (
    <BubbleContent
      className={classnames('proactiveItem-content', className)}
      data-aid={`proactiveItem-content-${item.id}`}
      item={item}
    >
      <InlineAnnotatedContentContainer id={item.id} text={item.content.text} />
    </BubbleContent>
  );
}

ProactiveChatContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
