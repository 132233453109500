import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class RetryUpload {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, uploadId }) {
    let composition = getCompositionsStore(this.context).find(compositionId);
    let upload = composition.findAttachmentById(uploadId);

    this.context.gateways.compositionUpload.fetchUploadAuth(
      this.currentAgent.id,
      composition.getUploadPath(upload.id),
      upload.fileDescriptor().toJs()
    );
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }
}
