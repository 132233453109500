import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

export default function Spinner({ className, size, stroke, color }) {
  return (
    <SpinnerScaler className={className} size={size}>
      <ScalerInner>
        <SpinnerContainer>
          <SpinnerLayer color={color}>
            <CircleClipper>
              <SpinnerCircle dir="left" stroke={stroke} />
            </CircleClipper>
            <SpinnerGap>
              <SpinnerCircleGap />
            </SpinnerGap>
            <CircleClipper>
              <SpinnerCircle dir="right" stroke={stroke} />
            </CircleClipper>
          </SpinnerLayer>
        </SpinnerContainer>
      </ScalerInner>
    </SpinnerScaler>
  );
}

Spinner.propTypes = {
  className: PropTypes.string,
  /** The stroke width of the circle that makes up the spinner*/
  stroke: PropTypes.number,
  /** The color of the circle that makes up the spinner */
  color: PropTypes.string,
  /** Optional: specific height and width desired for the spinner */
  size: PropTypes.number,
};

Spinner.defaultProps = {
  stroke: 3,
};

// Animations
const fillUnfillRotate = keyframes`
  12.5% {
    transform: rotate(135deg);
  }
  25% {

    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
`;

const containerRotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const leftSpin = keyframes`
    from {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    to {
        transform: rotate(130deg);
    }
`;

const rightSpin = keyframes`
    from {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
    to {
        transform: rotate(-130deg);
    }
  `;

const SpinnerScaler = styled.div`
  position: relative;
  ${p => (p.size ? fixedSize : flexibleSize)};
`;

const fixedSize = css`
  height: ${p => p.size}px;
  width: ${p => p.size}px;
`;

const flexibleSize = css`
  width: 100%;
  padding-top: 100%;
`;

const ScalerInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const SpinnerContainer = styled.div`
  animation: ${containerRotate} 1568ms linear infinite;
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
`;

const SpinnerLayer = styled.div`
  animation: ${fillUnfillRotate} 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  border-color: ${p => (p.color ? p.color : p.theme.colors.green400)};
  height: 100%;
  opacity: 1;
  position: absolute;
  width: 100%;
`;

const CircleClipper = styled.div`
  border-color: inherit;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 50%;
`;

const SpinnerCircle = styled.div`
  animation: none;
  border-bottom-color: transparent !important;
  border-color: inherit;
  border-radius: 50%;
  border-style: solid;
  border-width: ${p => (p.stroke ? `${p.stroke}px` : '3px')};
  bottom: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 200%;
  ${p => (p.dir === 'left' ? circleLeft : circleRight)};
`;

const circleLeft = css`
  animation: ${leftSpin} 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  border-right-color: transparent !important;
  left: 0;
  transform: rotate(129deg);
`;

const circleRight = css`
  animation: ${rightSpin} 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  border-left-color: transparent !important;
  left: -100%;
  transform: rotate(-129deg);
`;

const SpinnerGap = styled.div`
  border-color: inherit;
  height: 100%;
  left: 45%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 10%;
`;

const SpinnerCircleGap = styled.div`
  left: -450%;
  width: 1000%;
`;
