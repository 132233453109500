import ConversationItem from './conversation_item';
import createModel from './lib/create_model';
import CustomerProfile from './customer_profile';
import moment from 'moment';

const TaskPreview = createModel({
  modelName: 'TaskPreview',

  properties: {
    customer: CustomerProfile,
    task: ConversationItem,
    readTo: String,
  },

  isUnread() {
    return !this.readTo || !this.task.content.updatedAt || moment(this.readTo).isBefore(this.task.content.updatedAt);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default TaskPreview;
