import ConversationUpdater from 'actions/conversation/lib/conversation_updater';
import createPaymentStatusEventItem from 'scripts/domain/factories/conversation_item/create_payment_status_event_item';

export default class AddPaymentStatusEventItem extends ConversationUpdater {
  run({ customerId, eventType, initiator, secureDataItemId }) {
    const paymentStatusEventItem = createPaymentStatusEventItem({
      customerId,
      eventType,
      initiator,
      secureDataItemId,
    });
    this.addConversationItem(paymentStatusEventItem);
  }
}
