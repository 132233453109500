import Attachment from 'models/attachment';
import createEnum from 'scripts/lib/create_enum';
import Upload from 'models/upload';

const AttachmentType = createEnum('SNIPPET_ATTACHMENT', 'UPLOAD');

const AttachmentClasses = [
  [AttachmentType.SNIPPET_ATTACHMENT, Attachment],
  [AttachmentType.UPLOAD, Upload],
];

export { AttachmentType };
export default AttachmentClasses;
