import loadCustomer from './lib/load_customer';
import rerequestCustomer from './rerequest_customer';

export default class LoadCustomer {
  constructor(context) {
    this.context = context;
  }

  run(id) {
    const isCustomerStale = this.context.stores.customers.isCustomerStale(id);
    if (this.context.stores.customers.has({ id }) && !isCustomerStale) {
      return;
    }

    if (isCustomerStale) {
      rerequestCustomer(this.context, id);
    } else {
      loadCustomer(this.context, id, isCustomerStale);
    }
  }
}
