import _ from 'lodash';

import IdGenerator from 'scripts/domain/contracts/id_generator';

export default class ApiTokenSerivce {
  constructor(publisher, getDb) {
    this.publisher = publisher;
    this.getDatabase = getDb;
  }

  add(orgId, userId, { correlationId, payload }) {
    let user = this._getUser(orgId, userId);
    if (!user.apiTokens) {
      user.apiTokens = [];
    }

    let newApiToken = { ...payload, token: IdGenerator.newId() };
    user.apiTokens.unshift(newApiToken);
    this.publisher.publishApiToken(correlationId, orgId, userId, newApiToken);

    return newApiToken;
  }

  findAll(orgId, userId) {
    let user = this._getUser(orgId, userId);
    return _.get(user, 'apiTokens', []);
  }

  remove(orgId, userId, apiTokenId, { correlationId }) {
    let user = this._getUser(orgId, userId);
    if (!user.apiTokens) {
      return;
    }

    let updatedTokens = user.apiTokens.filter(apiToken => apiToken.id !== apiTokenId);
    if (updatedTokens.length < user.apiTokens.length) {
      user.apiTokens = updatedTokens;
      this.publisher.publishApiTokenDelete(correlationId, orgId, userId, apiTokenId);
    }
  }

  update(orgId, userId, apiTokenId, { correlationId, payload }) {
    let user = this._getUser(orgId, userId);

    let updatedApiToken = _.merge(_.find(user.apiTokens, { id: apiTokenId }), payload);
    this.publisher.publishApiToken(correlationId, orgId, userId, updatedApiToken);
  }

  _getUser(orgId, id) {
    return _.find(this.getDatabase(orgId).users, { id });
  }

  static create(pubsub, getDb) {
    return new ApiTokenSerivce(new ApiTokenPublisher(pubsub), getDb);
  }
}

export class ApiTokenPublisher {
  constructor(pubsub) {
    this.pubsub = pubsub;
  }

  publishApiToken(correlationId, orgId, userId, apiToken) {
    this.pubsub.publish(`v1/orgs/${orgId}/users/${userId}/tokens/${apiToken.id}`, {
      correlationId,
      payload: apiToken,
    });
  }

  publishApiTokenDelete(correlationId, orgId, userId, apiTokenId) {
    this.pubsub.publish(`v1/orgs/${orgId}/users/${userId}/tokens/${apiTokenId}/event/delete`, { correlationId });
  }
}
