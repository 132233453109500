import createModel, { prop } from 'models/lib/create_model';

export const AttributeDef = createModel({
  modelName: 'AttributeDef',
  properties: {
    attr: prop(String).isRequired,
    label: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const CustomerLookupSettings = createModel({
  modelName: 'CustomerLookupSettings',
  properties: {
    isOriginal: prop(Boolean).default(false),
    searchQueryAttributes: prop([AttributeDef]).default([]),
    searchResultAttributes: prop([AttributeDef]).default([]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export const ActionSettings = createModel({
  modelName: 'ActionSettings',
  properties: {
    enabled: prop(Boolean).default(false),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

const Integration = createModel({
  modelName: 'Integration',
  properties: {
    id: prop(String).isRequired,
    type: prop(String),
    name: prop(String),
    enabled: prop(Boolean).default(false),
    customerLookupSettings: prop(CustomerLookupSettings),
    actionSettings: prop(ActionSettings),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Integration;
