import createModel, { prop } from 'models/lib/create_model';

import Assignee from 'models/assignee';

const RecentCustomer = createModel({
  modelName: 'RecentCustomer',

  properties: {
    id: prop(String).isRequired,
    assignee: prop(Assignee),
    email: prop(String),
    name: prop(String),
    phone: prop(String),
  },
});

export default RecentCustomer;
