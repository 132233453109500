import CustomerView from 'models/location/customer_view';

export default function(context, { anchorPosition, customerId, conversationId, conversationItemId }) {
  context.router.navigateTo(
    CustomerView.create({
      anchorPosition,
      customerId,
      currentConversationId: conversationId,
      currentConversationItemId: conversationItemId,
    })
  );
}
