import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('AgentStatusReason')
  .extend('Uuid');

Factory.define('ActiveAgentStatusReasonWithDefaults')
  .extend('AgentStatusReason')
  .attr('name', 'amazing active reason!')
  .attr('type', 'ACTIVE');

Factory.define('AwayAgentStatusReasonWithDefaults')
  .extend('AgentStatusReason')
  .attr('name', 'incredible away reason!')
  .attr('type', 'AWAY');