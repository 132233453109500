import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class RoutingQueueItemService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(payload, callback, path, { orgId, itemId }) {
    let allQueueItems = this.getDatabase(orgId).routingQueueItems;
    let queueItem = _.find(allQueueItems, item => item.id === itemId);

    if (!queueItem) {
      callback(
        Error(statusText(404), {
          status: 404,
          statusText: statusText(404),
          response: {},
        })
      );
      return;
    }

    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: queueItem,
    });
  }

  findAll(payload, callback, path, { orgId }, { agentId }) {
    let allQueueItems = this.getDatabase(orgId).routingQueueItems;
    let queueItemsForAgent = _.filter(allQueueItems, queueItem => this._hasAgentAsParticipant(queueItem, agentId));

    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: queueItemsForAgent || [],
    });
  }

  _hasAgentAsParticipant(queueItem, agentId) {
    return _.find(queueItem.agents, { id: agentId });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/routing-queue-items': {
          GET: this.findAll,
        },
        '/api/v1/orgs/:orgId/routing-queue-items/:itemId': {
          GET: this.find,
        },
      },
      this
    );
  }
}
