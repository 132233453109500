import { ANSWER_LINK } from './answer_links';

export const ANSWER_LINK_ACTIVATION_CHARACTER = '+';

export default function AnswerLinkEditingPlugin() {
  let previousAnswerLinkMentionText = null;

  return {
    commands: { insertAnswerLink },

    onChange(editor, next) {
      let answerLinkMentionText = editor.getMentionText(ANSWER_LINK_ACTIVATION_CHARACTER);
      if (answerLinkMentionText && answerLinkMentionText !== previousAnswerLinkMentionText) {
        previousAnswerLinkMentionText = answerLinkMentionText;
        editor.updateMention(answerLinkMentionText);
        return next();
      }
    },

    onKeyDown(evt, editor, next) {
      if (evt.key === ANSWER_LINK_ACTIVATION_CHARACTER) {
        evt.preventDefault();
        editor.startMention(ANSWER_LINK_ACTIVATION_CHARACTER);
        return next();
      }

      if (evt.key === 'Escape' && editor.getMentionText(ANSWER_LINK_ACTIVATION_CHARACTER) !== null) {
        editor.removeMention();
        return next();
      }

      return next();
    },
  };
}

export function insertAnswerLink(editor, answer) {
  const mentionText = editor.getMentionText(ANSWER_LINK_ACTIVATION_CHARACTER);

  editor.removeMention();
  editor.deleteBackward(mentionText.length + 1);
  const selectedRange = editor.value.selection;

  editor
    .insertText(' ')
    .insertInlineAtRange(selectedRange, {
      data: {
        answerId: answer.id,
      },
      nodes: [
        {
          object: 'text',
          leaves: [
            {
              text: answer.name,
            },
          ],
        },
      ],
      type: ANSWER_LINK,
    })
    .focus();
}
