import _ from 'lodash';
import { Factory } from 'rosie';

import '../conversation_item_factory';
import './rule_score_factory';

Factory.define('CommunicationDetail').attr('channel', '');

Factory.define('RoutingItem')
  .attr('assignee')
  .attr('itemType')
  .attr('type', 'ROUTING_ITEM');

Factory.define('RoutingItemContentWithChannel')
  .extend('RoutingItem')
  .attr('detail', ['detail'], detail => Factory.attributes('CommunicationDetail', detail));

Factory.define('RoutingItemContentFromInbox')
  .extend('RoutingItem')
  .attr('assigneeWas', ['assigneeWas', 'assignee'], (assigneeWas, assignee) =>
    _.assign({ routingGroupId: _.get(assignee, 'routingGroupId', 'routing-group-id') }, assigneeWas)
  );

Factory.define('RoutingItemContentFromAgent')
  .extend('RoutingItem')
  .attr('assigneeWas', ['assigneeWas', 'assignee'], (assigneeWas, assignee) =>
    _.assign(
      {
        agentId: 'prior-agent-id',
        routingGroupId: _.get(assignee, 'routingGroupId', 'routing-group-id'),
      },
      assigneeWas
    )
  );

Factory.define('RoutingItemContentInbound')
  .extend('RoutingItemContentWithChannel')
  .attr('itemType', 'INBOUND');

Factory.define('RoutingItemContentOfferDecline')
  .extend('RoutingItemContentWithChannel')
  .extend('RoutingItemContentFromAgent')
  .attr('itemType', 'OFFER_DECLINE');

Factory.define('RoutingItemContentOfferMiss')
  .extend('RoutingItemContentWithChannel')
  .extend('RoutingItemContentFromAgent')
  .attr('itemType', 'OFFER_MISS');

Factory.define('RoutingItemContentOutbound')
  .extend('RoutingItemContentWithChannel')
  .extend('RoutingItemContentFromInbox')
  .attr('itemType', 'OUTBOUND');

Factory.define('RoutingItemForAgentRequest')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('RoutingItem', _.merge({ itemType: 'AGENT_REQUEST' }, content));
  });

Factory.define('RoutingItemForAutomatedAssignment')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('RoutingItem', _.merge({ itemType: 'AUTOMATED_ASSIGNMENT' }, content));
  });

Factory.define('RoutingItemForBlindTransfer')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('RoutingItem', _.merge({ itemType: 'BLIND_TRANSFER' }, content));
  });

Factory.define('RoutingItemForInbound')
  .extend('ConversationItem')
  .attr('initiator', ['customerId'], id => ({ type: 'CUSTOMER', id: id || 'customer-id' }))
  .attr('content', ['content'], content => Factory.attributes('RoutingItemContentInbound', content));

Factory.define('RoutingItemForInboundMatch')
  .extend('ConversationItem')
  .attr('initiator', {}) // SystemInitiator is an empty object `{}`
  .attr('content', ['content'], content =>
    Factory.attributes('RoutingItemContentWithChannel', _.merge({ itemType: 'INBOUND_MATCH' }, content))
  );

Factory.define('RoutingItemForManualAssignment')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('RoutingItem', _.merge({ itemType: 'MANUAL_ASSIGNMENT' }, content));
  });

Factory.define('RoutingItemForOfferConflict')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('RoutingItem', _.merge({ itemType: 'OFFER_CONFLICT' }, content));
  });

Factory.define('RoutingItemForOfferDecline')
  .extend('ConversationItem')
  .attr('initiator', ['initiator', 'content'], (initiator, content) =>
    _.assign({ type: 'AGENT', id: _.get(content, 'assigneeWas.agentId', 'initiator-id') }, initiator)
  )
  .attr('content', ['content'], content => Factory.attributes('RoutingItemContentOfferDecline', content));

Factory.define('RoutingItemForOfferMiss')
  .extend('ConversationItem')
  .attr('initiator', { type: '', id: '' })
  .attr('content', ['content'], content => Factory.attributes('RoutingItemContentOfferMiss', content));

Factory.define('RoutingItemForOutbound')
  .extend('ConversationItem')
  .attr('initiator', ['initiator', 'content'], (initiator, content) =>
    _.assign({ type: 'AGENT', id: _.get(content, 'assignee.agentId', 'initiator-id') }, initiator)
  )
  .attr('content', ['content'], content => Factory.attributes('RoutingItemContentOutbound', content));

Factory.define('RoutingItemForSmartMatch')
  .extend('ConversationItem')
  .attr('initiator', ['content'], function(content) {
    return {
      type: 'AGENT',
      id: _.get(content, 'assignee.agentId', 'initiator-id'),
    };
  })
  .attr('content', ['content'], function(content) {
    return Factory.attributes(
      'RoutingItem',
      _.merge(
        {
          assigneeWas: { routingGroupId: _.get(content, 'assignee.routingGroupId', 'routing-group-id') },
          itemType: 'SMART_MATCH',
          detail: Factory.attributes('SmartMatchDetailWithDefaults'),
        },
        content
      )
    );
  });

Factory.define('SmartMatchDetailWithDefaults').attr('ruleScores', () => [Factory.attributes('RuleScoreWithDefaults')]);
