import { Factory } from 'rosie';
import './lib/uuid';

Factory.define('CommunicationQueue')
  .extend('Uuid');

Factory.define('CommunicationQueueWithDefaults')
  .extend('CommunicationQueue')
  .attr('endpointId', ['id'], id => `endpointId-${id}`)
  .attr('routingGroupId', ['id'], id => `routingGroupId-${id}`);
