import AgentPhonePreferences from 'models/agent_phone_preferences';
import PhoneConnectionStatus from 'models/phone_connection_status';

const alwaysLiveCallTypes = [AgentPhonePreferences.DIRECT_DIAL, AgentPhonePreferences.STATION];

export default function(context, { activeCall }) {
  let currentAgent = context.stores.currentAgent.get();
  if (!currentAgent) {
    return false;
  }

  let currentAgentId = currentAgent.id;
  let phoneCallContent = activeCall.conversationItem.content;
  let connectionStatus = context.gateways.phoneGateway.getConnectionStatus();
  return (
    alwaysLiveCallTypes.includes(phoneCallContent.getCallLegType(currentAgentId)) ||
    !!(connectionStatus && connectionStatus !== PhoneConnectionStatus.CLOSED)
  );
}
