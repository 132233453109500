import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const EVENT_EDIT_INLINE_LINK = 'edit-inline-link';

export const StyledLinkInline = ({ attributes, children, className, element, editor }) => {
  const { url } = element || {};

  const onClick = () => {
    // We delay sending the event in order to deal with the situation when the agent clicks
    // the link multiple times. We want the link editor to stay open
    if (editor?.events?.emit) {
      setTimeout(() => {
        editor.events.emit(EVENT_EDIT_INLINE_LINK);
      }, 25);
    }
  };

  return (
    <StyledLink className={className} href={url} title={url} {...attributes} onClick={onClick}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  cursor: pointer;
  color: ${p => p.theme.colors.green400};

  &:hover {
    color: ${p => p.theme.colors.green400};
    text-decoration: underline;
  }
`;

StyledLinkInline.propTypes = {
  attributes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  editor: PropTypes.object.isRequired,
  element: PropTypes.shape({ url: PropTypes.string }).isRequired,
};
