import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { createRef, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  ConversationMenuOptionLabel,
  ConversationTopicMenuOption,
} from 'components/customer/conversation_history/topics_v2/conversation_topic_menu_option_v2';
import RemoveOptionIcon from 'components/customer/conversation_history/topics_v2/remove_option_icon';

const ConversationCustomAttributeMenuOptionV2 = forwardRef(
  ({ isFocused, onClick, onMouseEnter, onSubmit, option }, externalRef) => {
    const [attributeValue, setAttributeValue] = useState('');
    const inputRef = externalRef || createRef();

    const isSelected = option.selected;
    const filler = !isFocused && !isSelected ? <CustomAttributeMenuOptionFiller /> : null;
    const className = classnames({
      'conversation-topic-menu-option-focused': isFocused,
      'conversation-topic-menu-option-selected': isSelected,
    });

    const icon = isSelected ? <RemoveOptionIcon isFocused={isFocused} /> : null;

    const labelClassName = classnames({
      'conversation-topic-menu-option-label-selected': isSelected,
    });

    return (
      <ConversationMenuCustomAttributeOption
        className={className}
        data-aid="conversation-topic-menu-option"
        onMouseDown={handleClick}
        onMouseEnter={handleMouseEnter}
      >
        <ConversationMenuCustomAttributeOptionLabel $shouldTruncate={!isSelected} className={labelClassName}>
          {option.label}
        </ConversationMenuCustomAttributeOptionLabel>
        {icon}
        {filler}
        {renderInputField()}
      </ConversationMenuCustomAttributeOption>
    );

    function handleChange(evt) {
      setAttributeValue(evt.target.value);
    }

    function handleClick(ev) {
      if (isFocused && !option.selected && inputRef.current) {
        inputRef.current.focus();
        return;
      }
      onClick(ev, option);
    }

    function handleKeyDown(evt) {
      if (evt.key === 'Enter') {
        if (attributeValue !== '') {
          onSubmit({ id: option.id, value: attributeValue });
        }
        setAttributeValue('');
      }
    }

    function handleMouseEnter() {
      onMouseEnter(option);
    }

    function renderInputField() {
      if (isSelected || !isFocused) {
        return null;
      }

      return (
        <CustomAttributeValueInput
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          type="text"
          value={attributeValue}
        />
      );
    }
  }
);

ConversationCustomAttributeMenuOptionV2.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  option: PropTypes.object,
};

const customAttributeValueInputWidth = css`
  width: 140px;
`;

const ConversationMenuCustomAttributeOption = styled(ConversationTopicMenuOption)`
  min-height: 32px;
  padding: 0 ${p => p.theme.spacing.large};
`;

const ConversationMenuCustomAttributeOptionLabel = styled(ConversationMenuOptionLabel)`
  padding-top: ${p => p.theme.spacing.medium};
  ${p => (p.$shouldTruncate ? truncatedLabelCSS : '')};
  &.conversation-topic-menu-option-label-selected {
    padding-top: 0;
  }
`;

const CustomAttributeMenuOptionFiller = styled.div`
  ${customAttributeValueInputWidth};
  flex: 0;
`;

const CustomAttributeValueInput = styled.input`
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.small};
  height: 24px;
  margin-top: ${p => p.theme.spacing.small};
  ${customAttributeValueInputWidth};
  &:focus {
    border: 1px solid ${p => p.theme.colors.green400};
    box-shadow: none;
    outline: 0;
  }
`;

const truncatedLabelCSS = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ConversationCustomAttributeMenuOptionV2;
