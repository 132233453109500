import classnames from 'classnames';
import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import numeral from 'numeral';
import styled from 'styled-components';
import React from 'react';
import _ from 'lodash';

import ActionMixin from './lib/action_mixin';
import AppFeaturesMixin from './lib/app_features_mixin';
import Conversation from 'models/conversation';
import CustomerConversationCardList from 'components/lib/customer_conversation_card/customer_conversation_card_list';
import EmptyInbox from './agent_inbox/empty_inbox';
import { H1 } from 'components/common/headers';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import { InboxConversationStatus } from 'models/location/inbox';
import { INBOX_COUNT_MAX } from 'models/inbox_count';
import InboxSortButton from './agent_inbox/inbox_sort_button';
import NavigateTo from 'actions/current_location/navigate_to';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import NavigateToItem from 'actions/conversation_item/navigate_to_item';
import NavigateToUrl from 'actions/current_location/navigate_to_url';
import ProvidersMixin from './lib/providers_mixin';
import RedirectToTeamInboxes from './agent_inbox/redirect_to_team_inboxes';
import registerHotkey from 'components/hotkeys/register_hotkey';
import RequestInbox from 'actions/inbox/request_inbox';
import SetInboxPolling from 'actions/inbox/set_inbox_polling';
import SetInboxScrollPosition from 'actions/inbox/set_inbox_scroll_position';
import SharedBindingsMixin from './lib/shared_bindings_mixin';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

const InboxHeader = styled(H1)`
  display: flex;
  flex-grow: 1;
  margin-right: 32px;
`;

const InboxFilters = styled.div`
  display: flex;
  position: relative;
`;

const TopBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const TeamInbox = createReactClass({
  mixins: [ActionMixin, AppFeaturesMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  statics: {
    SCROLL_THROTTLE_INTERVAL: 200,
    CLICK_THROTTLE_INTERVAL: 1000,
  },

  UNSAFE_componentWillMount() {
    this.onInfiniteLoad = _.debounce(this.onInfiniteLoad, 3000, { leading: true });
    this.observeBinding(this.getSharedBinding('activeChats'));
    this.observeBinding(this.getSharedBinding('currentAgent'));
    this.observeBinding(this.getSharedBinding('currentLocation'));
    this.observeBinding(this.getSharedBinding('routingGroups'));
    this.observeBinding(this.getSharedBinding('inbox'));
    this.observeBinding(this.getSharedBinding('teamInboxCounts'));
  },

  componentDidMount() {
    this.executeAction(SetInboxScrollPosition);

    this.handleScroll = _.throttle(this.handleScroll, TeamInbox.SCROLL_THROTTLE_INTERVAL);
    this.navigateToItem = _.throttle(this.navigateToItem, TeamInbox.CLICK_THROTTLE_INTERVAL, {
      leading: true,
      trailing: false,
    });
    this.navigateToConversation = _.throttle(this.navigateToConversation, TeamInbox.CLICK_THROTTLE_INTERVAL, {
      leading: true,
      trailing: false,
    });
  },

  navigateToInbox(conversationStatus) {
    let currentLocation = this.getProvider('currentLocation').get();
    this.executeAction(NavigateTo, currentLocation.deriveNew({ conversationStatus }));
  },

  render() {
    let currentAgentBinding = this.getSharedBinding('currentAgent');
    if (!currentAgentBinding.get()) {
      return false;
    }

    let currentLocation = this.getProvider('currentLocation').get();

    return (
      <div className="agentInboxWrapper">
        <TopBarWrapper>
          <div className="agentInbox-topBar">
            <InboxHeader data-aid="agentInbox-header-text" stack="none">
              {this.getInboxName(currentLocation.routingGroupId)}
            </InboxHeader>
            <InboxFilters>
              {this.renderFilters()}
              {this.renderInboxSort()}
            </InboxFilters>
          </div>
        </TopBarWrapper>
        <div className="agentInbox agentInbox-team">{this.renderInboxItems(currentLocation.isTeam)}</div>
        <RedirectToTeamInboxes />
      </div>
    );
  },

  getInboxName(routingGroupId) {
    let routingGroup = routingGroupId && this.getProvider('routingGroups').findBy({ id: routingGroupId });
    return routingGroup && routingGroup.name;
  },

  handleScroll() {
    let h = window.innerHeight;
    let isPolling = this.getProvider('currentLocation').get().isPolling;
    if ((isPolling && window.pageYOffset > h) || (!isPolling && window.pageYOffset < h)) {
      this.handlePollingClick(!isPolling);
    }
  },

  handlePollingClick(state) {
    this.executeAction(SetInboxPolling, state);
  },

  renderInboxSort() {
    return <InboxSortButton binding={this.getSharedBinding('currentLocation').sub('sortOrder')} />;
  },

  renderFilters() {
    let currentLocation = this.getProvider('currentLocation').get();
    let conversationStatuses = currentLocation.isTeam ? InboxConversationStatus : Conversation.Status;
    let inboxCounts = this.getInboxCountsForGroup(currentLocation.routingGroupId);

    let items = _.map(conversationStatuses, status => {
      let inboxLocation = currentLocation.deriveNew({ conversationStatus: status });

      return {
        name: status,
        count: this.getInboxCountForStatus(inboxCounts, status),
        href: getLocationUrl(inboxLocation),
        isActive: currentLocation.conversationStatus === status,
      };
    });

    return <ul className="inboxConversationFiltersList">{items.map(this.renderFilter)}</ul>;
  },

  getInboxCountsForGroup(groupId) {
    let zeroCounts = { newCount: 0, openCount: 0, waitingCount: 0 };
    return this.getProvider('teamInboxCounts').findBy({ id: groupId }) || zeroCounts;
  },

  getInboxCountForStatus(inboxCounts, status) {
    if (inboxCounts) {
      if (status === InboxConversationStatus.NEW) {
        return inboxCounts.newCount;
      } else if (status === InboxConversationStatus.OPEN) {
        return inboxCounts.openCount;
      } else if (status === InboxConversationStatus.WAITING) {
        return inboxCounts.waitingCount;
      }
    }
    return null;
  },

  renderFilter({ count, name, href, isActive }) {
    let key = href.replace(/\W/g, '-');
    let shouldShowCount = name !== Conversation.Status.CLOSED && name !== InboxConversationStatus.CLOSED;
    let countDisplay = count > INBOX_COUNT_MAX ? '999+' : numeral(count).format('0,0');

    let classNames = classnames(
      'inboxConversationFiltersList-item',
      `inboxConversationFiltersList-item-${name.toLowerCase()}`,
      {
        'inboxConversationFiltersList-item-active': isActive,
      }
    );
    return (
      <li className={classNames} key={key} onClick={() => this.onLinkClick(href)}>
        <a className="inboxConversationFiltersList-item-link">
          <span className="inboxConversationFiltersList-item-name">{name}</span>
          {shouldShowCount && <span className="inboxConversationFiltersList-item-count">{countDisplay}</span>}
        </a>
      </li>
    );
  },

  renderInboxItems(isTeam) {
    let binding = this.getSharedBinding('inbox');

    let isLoading = !!binding.meta().get('_loading');
    if (isLoading) {
      return null;
    }

    let previews = this.getProvider('inbox').get();
    if (!previews || !previews.length) {
      return this.renderNoItems();
    }

    const scrollPosition = this.getProvider('currentLocation').get().scrollPosition;
    const scrollY = scrollPosition ? scrollPosition[1] : 0;

    return (
      <CustomerConversationCardList
        hits={previews}
        infiniteScroll
        initialScrollY={scrollY}
        isLoading={isLoading}
        onCustomerClick={this.navigateToConversation}
        onInfiniteLoad={this.onInfiniteLoad}
        onItemClick={this.navigateToItem}
        onScroll={this.handleScrollNew}
        showLoading={false}
        showUnread={!isTeam}
        style={{ paddingRight: '16px' }}
      />
    );
  },

  handleScrollNew(scrollTop) {
    this.executeAction(SetInboxScrollPosition, [0, scrollTop]);
  },

  onInfiniteLoad() {
    let inboxState = this.getProvider('currentLocation').get();
    let cutoffs = {
      max: inboxState.cutoffMax,
      min: inboxState.cutoffMin,
    };
    this.executeAction(RequestInbox, { cutoffs });
  },

  onLinkClick(href) {
    this.executeAction(NavigateToUrl, href);
  },

  renderNoItems() {
    return <EmptyInbox status={this.getProvider('currentLocation').get().conversationStatus} />;
  },

  navigateToConversation(customerId, conversationId) {
    let previews = this.getProvider('inbox').get();
    let conversationPreview = _.find(previews, { customer: { id: customerId }, conversation: { id: conversationId } });
    if (conversationPreview && !conversationPreview.isUnverified()) {
      this.executeAction(NavigateToConversation, { customerId, conversationId });
    }
  },

  navigateToItem({ customerId, itemId }) {
    this.executeAction(NavigateToItem, { customerId, itemId });
  },
});

const TeamInboxWithShortcuts = withShortcuts(TeamInbox, [
  registerHotkey({
    key: 'alt+c',
    callback: component => component.navigateToInbox(InboxConversationStatus.CLOSED),
    group: 'Inboxes',
    label: 'See closed inbox',
  }),
  registerHotkey({
    key: 'alt+o',
    callback: component => component.navigateToInbox(InboxConversationStatus.OPEN),
    group: 'Inboxes',
    label: 'See open inbox',
  }),
  registerHotkey({
    key: 'alt+w',
    callback: component => component.navigateToInbox(InboxConversationStatus.WAITING),
    group: 'Inboxes',
    label: 'See waiting inbox',
  }),
]);

export default TeamInboxWithShortcuts;
