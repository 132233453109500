import createModel, { prop } from './lib/create_model';

export const AutoAccept = createModel({
  modelName: 'AutoAccept',

  properties: {
    enabled: prop(Boolean).default(false),
    delaySecs: prop(Number).default(10),
  },
});

export default createModel({
  modelName: 'VoiceConfiguration',
  properties: {
    autoAccept: prop(AutoAccept).default(new AutoAccept()),
    callWrapUpIntervalSecs: prop(Number).default(60),
    declineCallAutoReadyIntervalSecs: prop(Number).default(20),
    disableDeclineCall: prop(Boolean).default(false),
    disableOutboundCallList: prop(Array).default([]),
    hotelingEnabled: prop(Boolean).default(false),
    shouldAutoAcceptIncomingCalls: prop(Boolean).default(false),
    shouldAutoOpenCallNote: prop(Boolean).default(true),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
