import _ from 'lodash';

import createModel from '../lib/create_model';

export default createModel({
  modelName: 'FbUserProfile',
  properties: {
    gender: String,
    firstName: String,
    lastName: String,
    profilePic: String,
    timezone: Number,
  },

  getName() {
    return _.compact([this.firstName, this.lastName]).join(' ');
  },
});
