import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';

import Tooltip from 'components/common/tooltip';
import { ChannelsStyled, IconWrapper } from 'components/customer/answer_panel/preview/answer_preview_channels';

export default function SuggestedReplyChannels() {
  return (
    <ChannelsStyled>
      <Channel message="Reference">
        <FontAwesomeIcon icon={faBook} />
      </Channel>
      <Channel message="Email">
        <i className="icon-email" />
      </Channel>
      <Channel message="Message">
        <FontAwesomeIcon icon={faComment} />
      </Channel>
      <Channel message="Public">
        <FontAwesomeIcon icon={faGlobe} />
      </Channel>
    </ChannelsStyled>
  );
}

export function Channel({ children, message }) {
  return (
    <Tooltip delay={800} message={message} position="top">
      <IconWrapper isDisabled isFocused={false}>
        {children}
      </IconWrapper>
    </Tooltip>
  );
}
