import { useCallback, useContext, useEffect, useRef } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';
import { isContentEqual, isEditorEmpty } from 'components/text_editor/helpers';
import TypingState from 'models/typing_state';
import UpdateTypingStatus from 'actions/composition/update_typing_status';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import { useDebounced, useThrottled } from 'components/hooks/debounce_hooks';

const START_TYPING_INTERVAL = 5000;
const STOP_TYPING_TIMEOUT = 5500;

// useChatOnChange will fire off debounced typing started and typing stopped events when the
// composer content has changed.
export function useChatOnChange() {
  const onTypingRef = useOnTypingRef();

  const previousValue = useRef(null);
  return useCallback(
    ({ value }) => {
      if ((!previousValue.current || !isContentEqual(previousValue.current, value)) && !isEditorEmpty(value)) {
        onTypingRef.current.onStartTyping();
        onTypingRef.current.onStopTyping();
      }
      previousValue.current = value;
    },
    [onTypingRef]
  );
}

export function useOnTypingRef() {
  const { composition } = useContext(ComposerContext);
  const { customerId, conversationId, content } = composition;
  const { sessionItemId } = content;

  const executeAction = useExecuteAction();
  const onStopTyping = useCallback(
    () =>
      executeAction(UpdateTypingStatus, {
        channel: 'chat',
        typingState: TypingState.STOPPED,
        customerId,
        conversationId,
        itemId: sessionItemId,
      }),
    [executeAction, customerId, conversationId, sessionItemId]
  );

  // We don't want to cancel onStopTyping on unmount (which is the default behavior), as we
  // want to flush the STOP typing state event. This ensures that when, say, an agent closes
  // the composer or switches customer, that their typing indicator disappears for consumers.
  const debouncedOnStopTyping = useDebounced(onStopTyping, STOP_TYPING_TIMEOUT, {
    dontCancelOnUnmount: true,
  });

  const onStartTyping = useCallback(() => {
    executeAction(UpdateTypingStatus, {
      channel: 'chat',
      typingState: TypingState.STARTED,
      customerId,
      conversationId,
      itemId: sessionItemId,
    });
    debouncedOnStopTyping();
  }, [executeAction, customerId, conversationId, sessionItemId, debouncedOnStopTyping]);
  const throttledOnStartTyping = useThrottled(onStartTyping, START_TYPING_INTERVAL, {
    trailing: false,
    dontCancelOnUnmount: true,
  });

  const onTypingRef = useRef({ onStartTyping: throttledOnStartTyping, onStopTyping: debouncedOnStopTyping });
  useEffect(() => {
    onTypingRef.current = { onStartTyping: throttledOnStartTyping, onStopTyping: debouncedOnStopTyping };
  }, [throttledOnStartTyping, debouncedOnStopTyping]);

  useEffect(() => {
    return () => {
      debouncedOnStopTyping.flush();
      throttledOnStartTyping.cancel();
    };
  }, []);

  return onTypingRef;
}
