import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

class Col extends Component {
  getColSpanClassNames() {
    let colSpan = {};
    if (this.props.colSpan) {
      colSpan.XS = this.props.colSpan;
    }
    return _(colSpan)
      .merge(this.props.colSpanResp)
      .map((v, col) => `col${_.upperFirst(col)}-${v}`)
      .value();
  }

  getOffsetClassNames() {
    let offset = {};
    if (this.props.offset) {
      offset.XS = this.props.offset;
    }
    return _(offset)
      .merge(this.props.offsetResp)
      .map((v, col) => `col${_.upperFirst(col)}-offset-${v}`)
      .value();
  }

  getClassNames() {
    return classnames('colXS', this.getOffsetClassNames(), this.getColSpanClassNames(), this.props.className);
  }

  render() {
    let otherProps = _.omit(this.props, [
      'children',
      'className',
      'colSpan',
      'colXS',
      'colSpanResp',
      'offset',
      'offsetResp',
    ]);

    return (
      <div {...otherProps} className={this.getClassNames()}>
        {this.props.children}
      </div>
    );
  }
}

Col.propTypes = {
  children: PropTypes.node,
  /** Specify XS column span. If colSpan is not specified the column will auto size with the other
   * Col elements to fill 12 columns */
  className: PropTypes.string,
  colSpan: PropTypes.string,
  /** Specify responsive column spans.  */
  colSpanResp: PropTypes.shape({
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
  /** Specify the XS offset */
  offset: PropTypes.string,
  /** Specify responsive # of columns to offset. Note the prop offset always sets the XS offset */
  offsetResp: PropTypes.shape({
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
};

export default Col;
