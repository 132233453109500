import _ from 'lodash';

const MAX_SERVICE_LATENCY = 100; // do not make lower than 100 because of JIRA DEV-13227
const WILDCARD = '([^/]+)';

export default class LocalPubsubBackend {
  constructor() {
    this.asyncEnabled = true;
    this._subscriptions = {};
    this._retainedPublications = {};
    this._inProgress = 0;

    this.publish = this.publish.bind(this);
  }

  publish(topic, envelope) {
    this._simulateServiceLatency(() => {
      var matchedTopics = _.keys(this._subscriptions).filter(doesMatch.bind(null, topic));
      matchedTopics.forEach(matchedTopic => {
        var subscriberCallbacks = this._subscriptions[matchedTopic];
        if (subscriberCallbacks) {
          subscriberCallbacks.forEach(callback => callback.call(callback, envelope, topic));
        }
      });
    });
  }

  _simulateServiceLatency(responseFn) {
    if (this.asyncEnabled) {
      this._inProgress++;
      setTimeout(() => {
        this._inProgress--;
        responseFn();
      }, _.random(MAX_SERVICE_LATENCY));
    } else {
      responseFn();
    }
  }

  publishAndRetain(topic, envelope) {
    this.publish(topic, envelope);
    this._retainedPublications[topic] = envelope;
  }

  subscribe(topicPattern, callback, ack) {
    var topicSubscriptions = this._subscriptions[topicPattern] || (this._subscriptions[topicPattern] = []);

    if (topicSubscriptions.indexOf(callback) < 0) {
      topicSubscriptions.push(callback);
    }

    if (ack) {
      setImmediate(ack);
    }

    this._simulateServiceLatency(() => {
      var retainedPublications = this._findRetainedPublications(topicPattern);
      _.forEach(retainedPublications, (envelope, topic) => callback.call(callback, envelope, topic));
    });
  }

  _findRetainedPublications(topicPattern) {
    return _.pickBy(this._retainedPublications, (envelope, topic) => doesMatch(topic, topicPattern));
  }

  unsubscribe(topic, callback) {
    var topicSubscriptions = this._subscriptions[topic];
    if (topicSubscriptions) {
      var callbackIndex = topicSubscriptions.indexOf(callback);
      if (callbackIndex !== -1) {
        topicSubscriptions.splice(callbackIndex, 1);
      }
    }
  }

  close() {
    this._subscriptions = {};
    this._retainedPublications = {};
  }

  get inProgress() {
    return this._inProgress;
  }
}

function doesMatch(topic, topicPattern) {
  return (
    topicPattern === topic ||
    topic.match(`^${topicPattern.replace(/[.*?^${}()|[\]\\]/g, '\\$&').replace(/\+/g, WILDCARD)}$`)
  );
}
