import StandardGateway from './lib/standard_gateway';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import { HEADER_CORRELATION_ID } from 'scripts/infrastructure/backends/http_client';

export default class OutgoingChatGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/agents/:agentId/outgoing/chats/:itemId');
  }

  update(params, data) {
    const correlationId = IdGenerator.newId();
    const url = this.endpoint.set(params).updateUrl;
    this.http
      .patch(url, data, { headers: { [HEADER_CORRELATION_ID]: correlationId } })
      .then(_ => this.observer.onAddSuccess({ correlationId }))
      .catch(err => this.observer.onAddError(err, params, correlationId));
    return { correlationId };
  }
}
