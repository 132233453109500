import AddPaymentStatusEventItem from './add_payment_status_event_item';
import CloseModal from 'actions/modal/close_modal';
import CreditCard from 'models/credit_card';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import ShowCreditCard from 'models/modal/show_credit_card';
import { PaymentStatusEventType } from 'models/payment_status_event_content';

const creditCardModalNotFound = new Error('no credit card modal found');
const creditCardFetchError = new Error('failed to fetch credit card info');

export default class CreditCardGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleGetSuccess(creditCardDto) {
    const modal = this._getModal();
    modal.setCreditCard(new CreditCard(creditCardDto));
    this.context.stores.modal.set(modal);
    this.context.executeAction(AddPaymentStatusEventItem, {
      customerId: modal.item.customerId,
      eventType: PaymentStatusEventType.CARD_VIEWED,
      initiator: modal.item.initiator,
      secureDataItemId: modal.item.id,
    });
  }

  handleGetError({ error, response }) {
    this._setError('Unable to retrieve credit card information. Please try again.');
    let errMeta = {};
    if (error) {
      errMeta['errorCode'] = error.code;
      errMeta['errorMessage'] = error.message;
      errMeta['errorStatus'] = error.status;
      errMeta['errorUrl'] = error.config.url;
      if (error.response) {
        errMeta['errorResponseErrors'] = JSON.stringify(error.response.data.errors);
        errMeta['errorResponseStatus'] = error.response.status;
      }
    }
    if (response) {
      errMeta['responseErrors'] = JSON.stringify(response.data.errors);
      errMeta['responseStatus'] = response.status;
      errMeta['responseUrl'] = response.config.url;
    }
    ErrorReporter.reportError(creditCardFetchError, {
      extra: errMeta,
    });
  }

  handleCompleted() {
    const modal = this.context.stores.modal.get();
    this.context.executeAction(CloseModal);
    if (!(modal instanceof ShowCreditCard)) {
      ErrorReporter.reportError(creditCardModalNotFound, {
        extra: {
          modalType: typeof modal,
        },
      });
      return;
    }
  }

  handleCompletedError() {
    this._setError('Unable to mark payment as completed. Please try again.');
  }

  _getModal() {
    const modal = this.context.stores.modal.get();
    if (!(modal instanceof ShowCreditCard)) {
      throw creditCardModalNotFound;
    }

    return modal;
  }

  _setError(errMsg) {
    const modal = this._getModal();
    modal.setErrorMessage(errMsg);
    this.context.stores.modal.set(modal);
  }
}
