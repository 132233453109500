import React from 'react';
import { Decoration, Mark } from 'slate-old';

import { isValidPhoneNumber } from 'models/phone_number';
import PhoneNumberHighlight from './phone_number_highlight';
import PhoneNumberHighlightMenu from './phone_number_highlight_menu';

export default function PhoneNumberHighlighting({ onChange }) {
  return {
    decorateNode(node, editor, next) {
      const others = next() || [];

      let decorations = [];
      const textNodes = node.getTexts();
      textNodes.forEach(textNode => {
        const phoneNumberRanges = findPhoneNumberRanges(textNode);
        phoneNumberRanges.forEach(range => {
          decorations.push(
            Decoration.create({
              anchor: {
                key: textNode.key,
                offset: range.start,
              },
              focus: {
                key: textNode.key,
                offset: range.end,
              },
              mark: Mark.create({
                type: 'phoneNumberHighlight',
                isAtomic: true,
                data: {
                  text: range.word,
                },
              }),
            })
          );
        });
      });

      return [...others, ...decorations];
    },

    renderEditor(props, editor, next) {
      const children = next();
      return (
        <React.Fragment>
          {children}
          <PhoneNumberHighlightMenu />
        </React.Fragment>
      );
    },

    renderMark(props, editor, next) {
      const { attributes, children, mark, node, offset, text } = props;

      if (mark.type === 'phoneNumberHighlight') {
        return (
          <PhoneNumberHighlight
            editor={editor}
            end={offset + text.length}
            node={node}
            onChange={onChange}
            start={offset}
            text={text}
            {...attributes}
          >
            {children}
          </PhoneNumberHighlight>
        );
      }
      return next();
    },
  };
}

function findPhoneNumberRanges(textNode) {
  const PHONE_REGEX = /(?:[0-9] ?){6,14}[0-9]|((1?)[.\-)(]*([0-9]{3})[.\-)( ]*([0-9]{3})[.\-)( ]*([0-9]{4}))/g;
  const text = textNode.text;

  let ranges = [];
  let match = PHONE_REGEX.exec(text);
  while (match !== null) {
    if (isValidPhoneNumber(match[0]) || isValidPhoneNumber(`+${match[0]}`)) {
      ranges.push({
        start: match.index,
        end: match.index + match[0].length,
        word: match[0],
      });
    }
    match = PHONE_REGEX.exec(text);
  }

  return ranges;
}
