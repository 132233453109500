import _ from 'lodash';
import React from 'react';

import AgentPhonePreferences from 'models/agent_phone_preferences';
import AgentProfile from './agent_profile';
import CancelCurrentAgent from 'actions/current_agent/cancel_current_agent';
import connect from 'components/lib/connect';
import { convertPhoneToDirectDialAddress } from 'models/station_configuration';
import NavigateBack from 'actions/current_location/navigate_back';
import PageLayout from '../page_layout';
import RequestPasswordReset from 'actions/user/request_password_reset';
import UpdateCurrentAgentProfile from 'actions/current_agent/update_current_agent_profile';

export const AgentProfileWrapper = () => {
  return (
    <PageLayout>
      <AgentProfileContainer />
    </PageLayout>
  );
};

const mapStateToProps = ({ getProvider, isFeatureEnabled }) => {
  const isDirectDialEnabled = isFeatureEnabled('directDial');

  const auth = getProvider('auth').get();
  const isSsoEnabled = _.get(auth, 'claims.ssoEnabled', false);

  const currentAgentProvider = getProvider('currentAgent');
  const isResponsePending = currentAgentProvider.isPending();
  const currentAgent = currentAgentProvider.get();

  const agentProfileErrors = currentAgentProvider.getErrors();
  const nameError = getErrorByAttribute('name');
  const phoneNumberError = getErrorByAttribute('phone');
  const extensionError = getErrorByAttribute('extension');
  const errors = { name: nameError, phoneNumber: phoneNumberError, extension: extensionError };
  const { emailGreetingSnippetId, emailSignatureSnippetId } = currentAgent;

  let voiceConfiguration = getProvider('voiceConfiguration').get();
  let isHotelingEnabled = voiceConfiguration && voiceConfiguration.hotelingEnabled;

  let stationName;
  const agentVoiceConfiguration = currentAgent.voiceConfiguration;
  if (
    isHotelingEnabled &&
    agentVoiceConfiguration &&
    agentVoiceConfiguration.preference === AgentPhonePreferences.DIRECT_DIAL
  ) {
    let phone = agentVoiceConfiguration.phone;
    let stationAddress = convertPhoneToDirectDialAddress(phone);

    let stationConfigurations = getProvider('stationConfigurations').findAll();
    let station = _.find(stationConfigurations, s => {
      return s.address === stationAddress;
    });
    stationName = station && station.name;
  }

  const agentExperienceConfig = getProvider('agentExperienceConfig').get();

  return {
    currentAgent,
    emailGreetingSnippet: getProvider('currentAgentSnippets').findBy({ id: emailGreetingSnippetId }),
    emailSignatureSnippet: getProvider('currentAgentSnippets').findBy({ id: emailSignatureSnippetId }),
    errors,
    isDirectDialEnabled,
    isExternalNameEnabled: agentExperienceConfig.allowExternalName,
    isHotelingEnabled,
    isResponsePending,
    isSlateBetaEnabled: isFeatureEnabled('slateBeta'),
    isSsoEnabled,
    isLoading: currentAgentProvider.isLoading(),
    stationName,
    hasExternalAgentActions: isFeatureEnabled('externalAgentActions'),
  };

  function getErrorByAttribute(attr) {
    let attrErrors = _.filter(agentProfileErrors, e => e.attr === attr);
    return attrErrors.length ? attrErrors[0].detail : null;
  }
};

const mapExecuteToProps = executeAction => {
  return {
    cancelCurrentAgent: () => executeAction(CancelCurrentAgent),
    navigateToPreviousPage: () => executeAction(NavigateBack),
    onProfileUpdate: attrs => executeAction(UpdateCurrentAgentProfile, attrs),
    requestPasswordReset: email => executeAction(RequestPasswordReset, email),
  };
};

const AgentProfileContainer = connect(mapStateToProps, mapExecuteToProps)(AgentProfile);
export default AgentProfileContainer;
