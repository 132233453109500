import { Factory } from 'rosie';
import './customer_profile';
import './lib/id';
import './phone_number_factory';
import './customer_username_address_factory';
import './customer_email_address_factory';

Factory.define('CustomerProfile')
  .extend('Id')
  .attr('_version', 1)
  .attr('phones', [])
  .attr('status', '')
  .attr('transactions', [])
  .attr('actions', []);

Factory.define('CustomerProfileWithDefaults')
  .extend('CustomerProfile')
  .attr('customAttributes', {
    accountNo: '894-32-82',
    customerSince: '2013-01-13',
    lastVisit: '2014-11-14T10:35:00-08',
    level: 'Premium Customer',
  })
  .attr('address', '5491 S. Willow Dr.\nRacine, WI 53406')
  .attr('emails', [Factory.attributes('CustomerEmailAddressPrimary')])
  .attr('fbMessengerUserIds', [])
  .attr('image', '/images/demo/martha.jpg')
  .attr('name', 'Martha Williams')
  .attr('externalCustomerIds', {})
  .attr('externalCustomerAddresses', [])
  .attr('whatsappIds', [Factory.attributes('CustomerUsernameAddressWithDefaults')])
  .attr('phones', [Factory.attributes('PhoneNumberWithDefaults')]);
