import _ from 'lodash';
import moment from 'moment';

import DtoConverter from 'scripts/application/dto_converters/snippet_revision_converter';
import qconsole from 'scripts/lib/qconsole';

export default class SnippetRevisionGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchSuccess(revisionDtos) {
    let revisions = revisionDtos.map(dto => DtoConverter.fromDto(dto));
    let sortedRevisions = _.orderBy(revisions, r => new moment(r.updatedAt), 'desc').slice(0, 5);
    this.context.stores.snippetRevisions.set(sortedRevisions);
  }

  onFetchError(errorDto) {
    qconsole.log(`Failed to fetch snippet revisions ${JSON.stringify(errorDto)}`);
  }
}
