import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('TaskDueContent')
  .attr('type', 'TASK_DUE');

Factory.define('TaskDueContentWithDefaults')
  .extend('TaskDueContent')
  .attr('taskItemId', 'id-123');

Factory.define('TaskDueContentItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskDueContent', content);
  });

Factory.define('TaskDueContentItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskDueContentWithDefaults', content);
  });
