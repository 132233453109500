import UserCredential from 'models/user_credential';
import UserCredentialType from 'models/user_credential_type';

export default class ResetUserPassword {
  constructor(context) {
    this.context = context;
  }

  run({ id, password }) {
    let trimmedPassword = password.trim();
    this.context.gateways.userPassword.update(id, { password: trimmedPassword });
    this.context.stores.userCredential.clearErrors();
    this.context.stores.userCredential.setPending(
      UserCredential.create({ type: UserCredentialType.RESET_PASSWORD_TOKEN })
    );
  }
}
