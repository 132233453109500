import { useContext, useEffect } from 'react';
import { usePlateEditorRef } from '@udecode/plate';

import CustomerContext from 'components/customer/customer_context';
import { useGreetingSuggestions } from 'components/composer/contexts/greeting_suggestions_context';

export default function UseAutocomplete() {
  useAutocomplete();
  return null;
}

// This plugin exists purely to show a greeting suggestion if it takes the application a moment to
// load the greeting suggestion for whatever reason.
export function useAutocomplete() {
  const editor = usePlateEditorRef();
  const { getGreetingSuggestion } = useGreetingSuggestions();
  const { customerId } = useContext(CustomerContext);

  useEffect(() => {
    const suggestion = getGreetingSuggestion();

    if (suggestion) {
      editor.ensureAutocompleteExists();
    }
  }, [customerId, editor, getGreetingSuggestion]);
}
