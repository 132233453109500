import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Add User Fill
 */

export default function AddUserFillIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M2.5 2C2.77614 2 3 2.22386 3 2.5V4H4.5C4.77614 4 5 4.22386 5 4.5C5 4.77614 4.77614 5 4.5 5H3V6.5C3 6.77614 2.77614 7 2.5 7C2.22386 7 2 6.77614 2 6.5V5H0.5C0.223857 5 0 4.77614 0 4.5C0 4.22386 0.223857 4 0.5 4H2V2.5C2 2.22386 2.22386 2 2.5 2ZM12.75 5.25C12.75 7.04493 11.2949 8.5 9.5 8.5C7.70507 8.5 6.25 7.04493 6.25 5.25C6.25 3.45507 7.70507 2 9.5 2C11.2949 2 12.75 3.45507 12.75 5.25ZM15.9728 14.5007C16.0029 14.7752 15.7617 15 15.4856 15H9.5H3.50765C3.2315 15 2.99714 14.7752 3.02724 14.5007C3.32964 11.7432 6.11214 9.58333 9.5 9.58333C12.8879 9.58333 15.6704 11.7432 15.9728 14.5007Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
