import PropTypes from 'prop-types';
import styled from 'styled-components';
import { removeInlinePadding, inlineDirectionSpacing, removeInsetLastMargin } from 'components/space_helper';
import InsetContainer from 'components/common/containers/inset_container';
import childLayout from 'components/common/containers/child_layout';

const InlineContainer = styled(InsetContainer)`
  flex-direction: row;
  align-items: flex-start;
  ${p => removeInlinePadding(p)};
  & > .${p => p.childClassName} {
    ${p => inlineDirectionSpacing(p)};
  }
  ${p => removeInsetLastMargin(p)};
`;

InlineContainer.propTypes = {
  inset: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  inline: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  direction: PropTypes.oneOf(['left', 'right']),
};

/** @component */
export default childLayout(InlineContainer);
