import _ from 'lodash';

import connect from 'components/lib/connect';
import NavigateToSnippet from 'actions/knowledge_base/navigate_to_snippet';
import RemoveSnippet from 'actions/knowledge_base/remove_snippet';
import SnippetsAdminList from './snippets_admin_list';

const SnippetsAdminListContainer = connect(mapStateToProps, mapExecuteToProps)(SnippetsAdminList);

function mapStateToProps({ getProvider, isFeatureEnabled }, { readOnly, textFilter }) {
  const searchResult = getProvider('snippetSearchResult').get();
  const snippets = getProvider('snippets').findAll();
  const audiences = _.reduce(
    getProvider('audiences').findAll(),
    (acc, audience) => {
      acc[audience.id] = audience.name;
      return acc;
    },
    {}
  );
  // HACK: sometimes when resetting the demo, snippets are removed from the database but _not_ the search index.
  // In this case, when the results are returned, the only thing will exist is the snippet id, everything else
  // will be blank. We will fix this eventually, but for now let's just filter out those results.
  const filteredSnippets = _.filter(snippets, snippet => snippet.contents && snippet.contents.length > 0);

  return {
    isLoading: _.get(searchResult, 'isLoading'),
    readOnly,
    snippets: filteredSnippets,
    errors: getProvider('snippets').getAllErrors(),
    textFilter,
    audiences,
  };
}

function mapExecuteToProps(executeAction, props) {
  return {
    onNavigateToSnippet: snippetId => executeAction(NavigateToSnippet, snippetId),
    onRemoveSnippet: snippetId => executeAction(RemoveSnippet, snippetId),
  };
}

export default SnippetsAdminListContainer;
