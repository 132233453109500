import _ from 'lodash';

import OptionalReloadNotification from 'models/notification/optional_reload_notification';

export default class ShowOptionalReloadNotification {
  constructor(context) {
    this.context = context;
  }

  get notificationsStore() {
    return this.context.stores.notifications;
  }

  _isOptionalReloadNotification(notification) {
    return notification instanceof OptionalReloadNotification;
  }

  run(reason) {
    let notification = OptionalReloadNotification.create({ reason });
    let existingNotification = _.find(this.notificationsStore.findAll(), this._isOptionalReloadNotification);
    if (!existingNotification) {
      this.context.stores.notifications.add(notification);
    }
  }
}
