import PropTypes from 'prop-types';
import React from 'react';

import NotificationContentContainer from './notification_content_container';
import AutoAcceptedSessionNotificationModel from 'models/notification/auto_accepted_session_notification';
import { iconClassFromSessionType } from 'components/lib/icon_classes';
import InteractionType from 'models/interaction_type';

class AutoAcceptedSessionNotification extends React.Component {
  render() {
    const notification = this.props.notification;
    return (
      <NotificationContentContainer
        body={this.getNotificationText()}
        heading={notification.profile.name || 'Customer'}
        iconClass={iconClassFromSessionType(notification.sessionType)}
        itemType={this.getItemTypeLabel()}
        notification={notification}
      />
    );
  }

  getNotificationText() {
    let sessionType = this.props.notification.sessionType;
    if (sessionType === InteractionType.ABANDONED_CALL || sessionType === InteractionType.VOICEMAIL) {
      return '';
    }
    return this.props.notification.messagePreview || 'New Message';
  }

  getItemTypeLabel() {
    switch (this.props.notification.sessionType) {
      case InteractionType.ABANDONED_CALL:
        return 'Abandoned call';
      case InteractionType.CHAT:
        return 'Chat';
      case InteractionType.EMAIL:
        return 'Email';
      case InteractionType.FB_MESSENGER:
        return 'Facebook Message';
      case InteractionType.INSTAGRAM_DIRECT:
        return 'Instagram Message';
      case InteractionType.SMS:
        return 'SMS';
      case InteractionType.TWITTER:
        return 'Twitter Message';
      case InteractionType.VOICEMAIL:
        return 'Voicemail';
      case InteractionType.WHATSAPP:
        return 'WhatsApp Message';
    }
  }
}

AutoAcceptedSessionNotification.propTypes = {
  notification: PropTypes.instanceOf(AutoAcceptedSessionNotificationModel).isRequired,
};

export default AutoAcceptedSessionNotification;
