import _ from 'lodash';

import connect from 'components/lib/connect';
import ForgotPassword from './forgot_password';
import RequestPasswordReset from 'actions/user/request_password_reset';
import UserCredential from 'models/user_credential';

const mapStateToProps = function(context, props) {
  let currentLocation = context.getProvider('currentLocation').get();
  const hasExpiredPassword = !!_.find(context.getProvider('userCredential').getErrors(), err => {
    return err.code === UserCredential.LoginErrorCodes.PASSWORD_EXPIRED;
  });

  return {
    hasExpiredPassword,
    initialEmail: currentLocation.passwordResetEmail || '',
  };
};

const mapExecuteToProps = function(executeAction, props) {
  return {
    onRequestPasswordReset: email => executeAction(RequestPasswordReset, email),
  };
};

export default connect(mapStateToProps, mapExecuteToProps)(ForgotPassword);
