import _ from 'lodash';

import Err from 'models/err';

function isServerError(response) {
  return response.status >= 500;
}

function isDomainError(response) {
  return (response.status === 400 || response.status === 409) && response.data.errors;
}

function isVersionOutdated(response) {
  return response.status === 409 && _.get(response.data, 'errors.0.code') === Err.Code.VERSION_OUTDATED;
}

function isNotExistError(response) {
  return response.status === 404;
}
function isSuccessful(response) {
  return response.status >= 200 && response.status < 300;
}

function isTooManyRequestsError(response) {
  return response.status === 429;
}

function isUnauthorized(response) {
  return response.status === 401;
}

// 406 temporarily maps to a permanent error.
// https://app.clubhouse.io/gladly/story/55824/ad-disassociate-406-with-permanent-errors
function isPermanentError(response) {
  return response.status === 406 || response.status === 422;
}

export default {
  isServerError,
  isDomainError,
  isNotExistError,
  isPermanentError,
  isSuccessful,
  isTooManyRequestsError,
  isUnauthorized,
  isVersionOutdated,
};

export class UnexpectedHttpResponse extends Error {
  constructor(status, body, resource) {
    const message = `unexpected ${status} response received for ${resource}`;
    super(message);
    this.name = 'UnexpectedHttpResponse';
    this.message = message;
    this.resource = resource;
    this.status = status;
    this.body = body;
  }
}
