import React from 'react';
import IconContainer from './icon_container';

export default props => (
  <IconContainer viewBox="0 0 15 12" {...props} className="callForwardIcon">
    <g fillRule="evenodd">
      <path d="M7.649 9.97a.541.541 0 0 0 .315-.181l.886-1.024a.524.524 0 0 0-.04-.731L7.485 6.805a.558.558 0 0 0-.75-.005l-.708.638a.56.56 0 0 1-.583.101c-.32-.128-.948-.566-1.575-1.152l-.255-.278c-.587-.627-1.025-1.255-1.153-1.574a.562.562 0 0 1 .1-.584l.64-.707a.558.558 0 0 0-.006-.75L1.967 1.17a.524.524 0 0 0-.73-.041l-1.025.887a.543.543 0 0 0-.18.314c-.097.553-.11 2.193 2.08 4.756l.788.789c2.564 2.19 4.196 2.192 4.749 2.094z" />
      <path d="M9 1L4.91 5.09M6.34 1H9v2.659" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </IconContainer>
);
