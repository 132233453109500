import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AgentParticipant from 'components/phone_controls/participants/agent_participant';
import Assignee from 'models/assignee';
import CancelWarmTransfer from 'actions/phone_call/cancel_warm_transfer';
import CompleteWarmTransfer from 'actions/phone_call/complete_warm_transfer';
import connect from 'components/lib/connect';
import CurrentAgentParticipant from 'components/phone_controls/participants/current_agent_participant';
import CustomerParticipant from 'components/phone_controls/participants/customer_participant';
import InboxParticipant from 'components/phone_controls/participants/inbox_participant';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PhoneControlsActionButton from '../lib/phone_controls_action_container_button';
import PhoneControlsActionButtonContainer from '../lib/phone_controls_action_container_button_container';
import PhoneControlsActionButtonDivider from '../lib/phone_controls_action_container_button_divider';
import PhoneControlsActionContainer from '../lib/phone_controls_action_container';
import SearchableTransferMenu from 'components/lib/assignment_menu/searchable_transfer_menu';

export class Transfer extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleCancelTransfer', 'handleClickOutside', 'handleCompleteTransfer']);
  }

  render() {
    if (this.props.transferee || this.props.isLiveTransfer) {
      return (
        <OutsideClickHandler onClickOutside={this.handleClickOutside}>
          <PhoneControlsActionContainer className={this.props.className}>
            <div className={classnames('transferContainer', this.props.className)}>
              <div className="transferContainer-participants">
                <CurrentAgentParticipant />
                <CustomerParticipant />
                {this.props.transferee && this.renderTransfereeParticipant()}
              </div>
              {this.renderActiveTransferActionButtons()}
            </div>
          </PhoneControlsActionContainer>
        </OutsideClickHandler>
      );
    }
    return (
      <OutsideClickHandler onClickOutside={this.handleClickOutside}>
        <PhoneControlsActionContainer className={this.props.className}>
          {this.renderTransferMenu()}
        </PhoneControlsActionContainer>
      </OutsideClickHandler>
    );
  }

  renderTransferMenu() {
    return (
      <SearchableTransferMenu
        conversationId={this.props.conversationId}
        currentAgentId={this.props.currentAgentId}
        onClose={this.props.onClose}
      />
    );
  }

  renderTransfereeParticipant() {
    if (this.props.transferee.agentId) {
      // warm transfer to agent
      return (
        this.props.agentParticipantIds.indexOf(this.props.transferee.agentId) !== -1 && (
          <AgentParticipant agentId={this.props.transferee.agentId} />
        )
      );
    }

    // warm transfer to inbox
    let otherAgentIdsOnCall = this.props.onCallAgentParticipantIds.filter(p => p !== this.props.currentAgentId);
    return otherAgentIdsOnCall.length > 0 ? (
      <AgentParticipant agentId={otherAgentIdsOnCall[0]} />
    ) : (
      <InboxParticipant inboxId={this.props.transferee.routingGroupId} />
    );
  }

  renderActiveTransferActionButtons() {
    return (
      <PhoneControlsActionButtonContainer>
        {/* Cancel button is disabled after initiating a warm transfer until the transferee becomes an active participant */}
        <PhoneControlsActionButton
          className="transferContainer-cancelTransfer"
          displayText="Cancel"
          isDisabled={!this.props.transferee || (this.props.isPending && !this.props.canCompleteTransfer)}
          onClickCallback={this.handleCancelTransfer}
        />
        <PhoneControlsActionButtonDivider />
        {this.props.didWarmTransferFail ? (
          <PhoneControlsActionButton
            className="transferContainer-findAnotherAgent"
            displayText="Find Another Agent"
            isDisabled={this.props.isPending}
            onClickCallback={this.handleCancelTransfer}
          />
        ) : (
          <PhoneControlsActionButton
            className="transferContainer-completeTransfer"
            displayText="Complete"
            isDisabled={!this.props.transferee || !this.props.canCompleteTransfer}
            onClickCallback={this.handleCompleteTransfer}
          />
        )}
      </PhoneControlsActionButtonContainer>
    );
  }

  handleCancelTransfer() {
    this.props.onCancelWarmTransfer(this.props.transferee);
  }

  handleCompleteTransfer() {
    this.props.onCompleteWarmTransfer();
    this.props.onClose();
  }

  handleClickOutside(evt) {
    this.props.onClose(evt);
  }
}

Transfer.propTypes = {
  agentParticipantIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  conversationId: PropTypes.string,
  currentAgentId: PropTypes.string.isRequired,
  didWarmTransferFail: PropTypes.bool.isRequired,
  isLiveTransfer: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  canCompleteTransfer: PropTypes.bool.isRequired,
  onCallAgentParticipantIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  transferee: PropTypes.instanceOf(Assignee),
  // callbacks
  onCancelWarmTransfer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleteWarmTransfer: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapExecuteToProps)(Transfer);

function mapStateToProps({ getProvider, isFeatureEnabled }, { className }) {
  let activeCallProvider = getProvider('activeCall');
  let activeCall = activeCallProvider.get();
  let currentAgentId = getProvider('currentAgent').get().id;
  let onCallAgentParticipantIds = activeCall.conversationItem.content.getAgentParticipantIdsOnCall();

  return {
    agentParticipantIds: activeCall.conversationItem.content.getAgentParticipantIds(),
    className,
    conversationId: activeCall.conversationId,
    currentAgentId,
    didWarmTransferFail: activeCall.didWarmTransferFail(),
    isLiveTransfer: activeCall.isTransferLive(),
    isPending: activeCallProvider.isPending(),
    canCompleteTransfer: activeCall.canCompleteTransfer(),
    onCallAgentParticipantIds,
    transferee: activeCall.transferee,
  };
}

function mapExecuteToProps(executeAction, { onClose }) {
  return {
    onCancelWarmTransfer: transferee => executeAction(CancelWarmTransfer, transferee),
    onClose,
    onCompleteWarmTransfer: () => executeAction(CompleteWarmTransfer),
  };
}
