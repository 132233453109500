import _ from 'lodash';

import Assignee from './assignee';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import ServerClock from 'scripts/application/lib/server_clock';

const AgentParticipantStatus = createEnum('ACTIVE', 'OFFERED', 'YIELDED');
const RoutingStatus = createEnum(
  'ACTIVE',
  'OFFERED',
  'OFFERED_ACTIVE',
  'OFFERED_REQUEUED',
  'QUEUED',
  'REQUEUED',
  'RESERVED',
  'RESERVED_REQUEUED'
);

const AgentParticipant = createModel({
  modelName: 'AgentParticipant',

  properties: {
    id: prop(String).isRequired,
    status: prop().oneOf(..._.keys(AgentParticipantStatus)),
    updatedAt: prop(String),
  },

  updateStatus(newStatus) {
    this.status = newStatus;
    this.updatedAt = ServerClock.toISOString();
  },

  statics: {
    create(attrs) {
      return new this(_.assign({}, attrs));
    },
  },
});

const RoutingSession = createModel({
  modelName: 'RoutingSession',

  properties: {
    id: prop(String).isRequired,
    type: prop(String).isRequired,
    customerIdentifier: String,
    endpointAddress: String,
  },
});

const RoutingQueueItem = createModel({
  modelName: 'RoutingQueueItem',

  properties: {
    id: prop(String).isRequired,
    agents: [AgentParticipant],
    assignee: prop(Assignee),
    conversationId: String,
    customerId: String,
    offeredSessionType: String,
    status: prop().oneOf(..._.keys(RoutingStatus)),
    sessions: [RoutingSession],
    _version: Number,
  },

  getLatestSession() {
    return _.last(this.sessions);
  },

  updateStatus(newStatus) {
    this.status = newStatus;
  },

  updateParticipantStatus(agentId, newStatus) {
    _.find(this.agents, { id: agentId }).updateStatus(newStatus);
  },

  getOfferingAgentId() {
    let offeringAgent = _.find(this.agents, agent => agent.status === AgentParticipantStatus.OFFERED);
    return offeringAgent && offeringAgent.id;
  },

  getAgentParticipantStatus(agentId) {
    let agentParticipant = _.find(this.agents, { id: agentId });
    return agentParticipant && agentParticipant.status;
  },

  getAssignedAgentId() {
    return _.get(this.assignee, 'agentId');
  },

  getSessionByType(sessionType) {
    return _.find(this.sessions, ['type', sessionType]);
  },

  getYieldedAgentId() {
    let yieldedAgent = _.find(this.agents, agent => agent.status === AgentParticipantStatus.YIELDED);
    return yieldedAgent && yieldedAgent.id;
  },

  isOffered() {
    return RoutingQueueItem.isOffered(this);
  },

  statics: {
    create(attrs) {
      return new this(_.assign({}, attrs));
    },

    isOffered(qi) {
      return (
        qi.status === RoutingStatus.OFFERED ||
        qi.status === RoutingStatus.OFFERED_REQUEUED ||
        qi.status === RoutingStatus.OFFERED_ACTIVE
      );
    },
  },
});

export { AgentParticipant, AgentParticipantStatus, RoutingSession, RoutingStatus };

export default RoutingQueueItem;
