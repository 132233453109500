import { createContent } from 'scripts/domain/factories/conversation_item/create_item_content';
import { isEmpty } from 'models/composition/composition_content_shared';
import createConversationNoteItem from 'scripts/domain/factories/conversation_item/create_conversation_note_item';
import Err from 'models/err';
import SubmitComposition, { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';

export default class AddNote extends SubmitComposition {
  createOutgoingItem(composition, composedContent) {
    let compositionErrors = composition.validateAttachmentStatus();
    compositionErrors = compositionErrors.concat(composition.validateTotalAttachmentSize());
    if (isEmpty(composedContent.bodyHtml) && composition.attachments.length === 0) {
      compositionErrors = compositionErrors.concat([
        new Err({
          attr: 'bodyHtml',
          code: Err.Code.BLANK,
          detail: 'editor is empty',
        }),
      ]);
    }

    if (compositionErrors.length) {
      return { compositionErrors };
    }

    let noteContent = createContent(composition, composedContent);
    let note = createConversationNoteItem({
      agentProfile: this.currentAgent,
      content: noteContent,
      conversationId: this.activeConversation.id,
      customerId: composition.customerId,
      snippetIds: composition.snippetIds,
    });

    return { compositionErrors: [], conversationItem: note };
  }

  sendMessage(conversationUpdates, noteItem) {
    this.context.gateways.outgoingNote.add(this.currentAgent.id, {
      id: noteItem.id,
      customerId: this.currentCustomerId,
      conversation: conversationUpdates,
      conversationItem: getOutgoingItemDto(noteItem),
    });
  }
}
