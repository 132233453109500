import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { trim } from 'lodash';
import { useSelected } from 'slate-react';

import SlateInlineImage from 'components/customer/composition/lib/slate/slate_inline_image';

/**
 * Simple component to render an image as an editor "block". The out-of-the-box Plate implementation has a lot
 * of bells and whistles that we do not need (social network url parsing, resizing, captions etc. etc.), so
 * we are rolling our own simplified version based on the existing inline image component.
 *
 * @param slateNodeAttributes - set of node attributes provided by Slate. We must render those to make sure
 * Slate understands our node
 * @param children - these are also provided by Slate and must be rendered as-is to ensure correct positioning
 * @param className
 * @param editor - live editor instance provided by Plate. Every time the editor changes (which includes changes
 * to the focus/selection), Plate will re-render the component.
 * @param slateNode - the underlying Slate node with all the image attributes etc.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
export function InlineImage({ attributes: slateNodeAttributes, children, className, element: slateNode } = {}) {
  const selected = useSelected();
  const attachmentId = trim(slateNode?.attachmentId);
  const imageSrc = trim(slateNode?.src);
  const fileName = trim(slateNode?.fileName);
  if (!imageSrc) return null;

  const errorIcon = (
    <StyledImageLoadingError>
      <i className="fa fa-file-image-o" data-aid="image-loading-error" />
      <br />
      <StyledFilenameWrapper>{fileName}</StyledFilenameWrapper>
    </StyledImageLoadingError>
  );

  return (
    <div {...slateNodeAttributes} contentEditable={false}>
      <StyledInlineImageWrapper $selected={selected} data-aid="inline-image-container" data-file={fileName}>
        <StyledSlateInlineImage
          attachmentId={attachmentId}
          className={className}
          data-aid="inline-composer-image"
          loadingErrorIcon={errorIcon}
          src={imageSrc}
        />
      </StyledInlineImageWrapper>
      {children}
    </div>
  );
}

InlineImage.propTypes = {
  attributes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  element: PropTypes.shape({
    src: PropTypes.string,
    attachmentId: PropTypes.string,
  }).isRequired,
};

const StyledInlineImageWrapper = styled.div`
  border-radius: 4px;
  border: 2px solid ${p => (p.$selected ? p.theme.colors.gray400 : 'transparent')};
  display: inline-flex;
  margin: 0.125rem 0 0.125rem calc(-2px - 0.125rem);
  padding: 0.125rem;
`;

const StyledSlateInlineImage = styled(SlateInlineImage)`
  border-radius: 4px;
  border: none;
`;

const StyledFilenameWrapper = styled.div`
  bottom: 0;
  color: ${p => p.theme.colors.gray500};
  font-size: ${p => p.theme.fontSize.reduced};
  max-width: 100%;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledImageLoadingError = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.white};
  border: 1px dashed ${p => p.theme.colors.gray400};
  border-radius: 4px;
  color: ${p => p.theme.colors.gray300};
  display: flex;
  font-size: 40px;
  height: 110px;
  justify-content: center;
  position: relative;
  width: 110px;
`;
