import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import connect from 'components/lib/connect';
import Conversation from 'models/conversation';
import createEnum from 'scripts/lib/create_enum';
import CustomerView from 'models/location/customer_view';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import Link from 'components/common/link';
import ReportDuration from 'components/reporting/report/lib/report_duration';
import Search from 'models/location/search';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Tooltip from 'components/common/tooltip';
import { TopicsStats } from 'models/liveboards/topics_liveboard';
import WindowSizeWatcher from 'components/common/utilities/window_size_watcher';

const TABLE_SIZES = Object.freeze({
  CELL_HEIGHT: 40,
  HEADER_CELL_HEIGHT: 50,
  NAME_CELL_WIDTH: 225,
  CELL_WIDTH: 125,
  GUTTER: 120,
});

const ASC_SORT = 'asc';
const DESC_SORT = 'desc';

export const ColumnValueType = createEnum(..._.values(TopicsStats.CalculatedValueTypes), 'NAME');

class TopicsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortValueType: ColumnValueType.NAME,
      sortDirection: ASC_SORT,
    };
  }

  render() {
    let rows = this.getSortedData({
      valueType: this.state.sortValueType,
      order: this.state.sortDirection,
    });

    return (
      <div className="topicsLiveboard-table">
        <WindowSizeWatcher>
          {({ windowWidth }) => (
            // TODO: need to make the table width responsive with the customer list
            <Table
              headerHeight={TABLE_SIZES.HEADER_CELL_HEIGHT}
              maxHeight={TABLE_SIZES.CELL_HEIGHT * (rows.length + 1) + TABLE_SIZES.HEADER_CELL_HEIGHT}
              rowHeight={TABLE_SIZES.CELL_HEIGHT}
              rowsCount={rows.length}
              width={this.getTableWidth(windowWidth)}
            >
              <Column
                cell={({ rowIndex }) => {
                  return (
                    <TopicCell>
                      <Tooltip bounds={{ left: 60 }} message={rows[rowIndex].name || 'No topic'} position="bottom">
                        <div className="topicsLiveboard-cell-content">
                          {rows[rowIndex].name || <div className="topicsLiveboard-cell-italicized">No topic</div>}
                        </div>
                      </Tooltip>
                    </TopicCell>
                  );
                }}
                fixed
                flexGrow={2}
                header={this.renderHeaderCell('Topics', ColumnValueType.NAME)}
                width={TABLE_SIZES.NAME_CELL_WIDTH}
              />
              {this.renderStatLinkColumn('New', ColumnValueType.NEW_CONVERSATIONS, rows, calculateValue)}
              {this.renderStatLinkColumn('Open', ColumnValueType.OPEN_CONVERSATIONS, rows, calculateValue)}
              {this.renderStatLinkColumn('Waiting', ColumnValueType.WAITING_CONVERSATIONS, rows, calculateValue)}
              {this.renderStatLinkColumn('Closed Today', ColumnValueType.CLOSED_TODAY, rows, calculateValue)}
              {this.renderStatLinkColumn(
                'Current Wait',
                ColumnValueType.CURRENT_WAIT,
                rows,
                ({ rowIndex, valueType }) => (
                  <ReportDuration duration={calculateValue({ rowIndex, valueType })} />
                )
              )}
              {this.renderStatColumn('% Within SLA', ColumnValueType.PCT_WITHIN_SLA, ({ rowIndex, valueType }) => (
                <PercentContent value={calculateValue({ rowIndex, valueType })} />
              ))}
            </Table>
          )}
        </WindowSizeWatcher>
      </div>
    );

    function calculateValue({ rowIndex, valueType }) {
      return rows[rowIndex].calculateValue(valueType);
    }
  }

  // In unit tests, the window size is always 0. We are guarding against negative `width` values,
  // otherwise Table component gets confused
  getTableWidth(windowWidth) {
    return this.props.isCustomerListCollapsed || windowWidth <= 1280
      ? Math.max(windowWidth - 80 - TABLE_SIZES.GUTTER, 0)
      : Math.max(windowWidth - 340 - TABLE_SIZES.GUTTER, 0);
  }

  getSortedData({ valueType, order }) {
    return _.orderBy(this.props.rows, sortBy, order);

    function sortBy(t) {
      return valueType === ColumnValueType.NAME ? t.name && t.name.toLowerCase() : t.calculateValue(valueType);
    }
  }

  renderStatLinkColumn(name, valueType, rows, renderContent) {
    return this.renderStatColumn(name, valueType, ({ rowIndex, valueType }) => {
      const statValue = renderContent({ rowIndex, valueType });

      if (!this.props.includeLinks) {
        return statValue;
      }

      const statUrl = this.createStatUrl(rows[rowIndex], valueType);
      return statUrl ? <TopicValueLink href={statUrl}>{statValue}</TopicValueLink> : statValue;
    });
  }

  renderStatColumn(name, valueType, renderContent) {
    return (
      <Column
        cell={({ rowIndex }) => <TopicCell>{renderContent({ rowIndex, valueType })}</TopicCell>}
        flexGrow={1}
        header={this.renderHeaderCell(name, valueType)}
        width={TABLE_SIZES.CELL_WIDTH}
      />
    );
  }

  renderHeaderCell(name, valueType) {
    let isSorted = this.state.sortValueType === valueType;
    let isSortAsc = this.state.sortDirection === ASC_SORT;
    let sortClasses = classnames({
      'topicsLiveboard-headerCell-sorted': isSorted,
      'topicsLiveboard-headerCell-sorted-asc': isSorted && isSortAsc,
      'topicsLiveboard-headerCell-sorted-desc': isSorted && !isSortAsc,
    });
    return (
      <Cell className="topicsLiveboard-headerCell" onClick={this.changeSort.bind(this, valueType)}>
        {name}
        <i className={sortClasses} />
      </Cell>
    );
  }

  changeSort(valueType) {
    if (this.state.sortValueType === valueType) {
      this.setState({
        sortDirection: this.state.sortDirection === ASC_SORT ? DESC_SORT : ASC_SORT,
      });
    } else {
      this.setState({
        sortValueType: valueType,
        sortDirection: DESC_SORT,
      });
    }
  }

  createSearchUrl(topicId, valueType) {
    let params = {
      inboxIds: this.props.routingGroupIds,
      statuses: [conversationStatusFromValueType(valueType)],
      topicIds: [topicId || ''],
    };

    if (valueType === ColumnValueType.CLOSED_TODAY) {
      params.updatedAt = {
        gte: moment
          .tz(moment(), this.props.timezone)
          .startOf('day')
          .toISOString(),
        lte: moment
          .tz(moment(), this.props.timezone)
          .endOf('day')
          .toISOString(),
      };
    }

    return getLocationUrl(Search.createFiltered(params));
  }

  createStatUrl(stat, valueType) {
    switch (valueType) {
      case ColumnValueType.NEW_CONVERSATIONS:
      case ColumnValueType.OPEN_CONVERSATIONS:
      case ColumnValueType.WAITING_CONVERSATIONS:
      case ColumnValueType.CLOSED_TODAY:
        return this.createSearchUrl(stat.id, valueType);
      case ColumnValueType.CURRENT_WAIT:
        return stat.oldestUnanswered && createConversationUrl(stat.oldestUnanswered);
      default:
        return null;
    }
  }
}

TopicsTable.propTypes = {
  includeLinks: PropTypes.bool,
  isCustomerListCollapsed: PropTypes.bool,
  routingGroupIds: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      calculateValue: PropTypes.func.isRequired,
    })
  ).isRequired,
  timezone: PropTypes.string.isRequired,
};

export { TopicsTable };

function conversationStatusFromValueType(valueType) {
  switch (valueType) {
    case ColumnValueType.NEW_CONVERSATIONS:
      return Conversation.InboxStatus.NEW;
    case ColumnValueType.OPEN_CONVERSATIONS:
      return Conversation.InboxStatus.OPEN;
    case ColumnValueType.WAITING_CONVERSATIONS:
      return Conversation.InboxStatus.WAITING;
    case ColumnValueType.CLOSED_TODAY:
      return Conversation.InboxStatus.CLOSED;
    default:
      return '';
  }
}

function createConversationUrl({ customerId, conversationId }) {
  return getLocationUrl(CustomerView.create({ customerId, currentConversationId: conversationId }));
}

function PercentContent({ value }) {
  return (value === null && '–') || (value * 100.0).toFixed(1);
}

PercentContent.propTypes = {
  value: PropTypes.number,
};

function TopicCell({ children }) {
  return <Cell className="topicsLiveboard-cell">{children}</Cell>;
}

TopicCell.propTypes = {
  children: PropTypes.node.isRequired,
};

function TopicValueLink({ href, children }) {
  return (
    <div className="topicsLiveboard-link-container">
      <Link className="topicsLiveboard-link" href={href}>
        {children}
      </Link>
    </div>
  );
}

TopicValueLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

const TopicsTableContainer = connect(mapStateToProps)(TopicsTable);

TopicsTableContainer.propTypes = {
  routingGroupIds: PropTypes.arrayOf(PropTypes.string),
};

export default TopicsTableContainer;

function mapStateToProps({ getProvider, isFeatureEnabled }, { routingGroupIds }) {
  const liveboard = getProvider('liveboard').get();
  const topics = getProvider('topics');
  const includeLinks = isFeatureEnabled('internalAgentActions');

  return {
    includeLinks,
    isCustomerListCollapsed: getProvider('agentPreferences').get().shouldCollapseSidebar,
    routingGroupIds,
    rows: (liveboard.topics || []).map(t => _.extend(t, { name: getTopicName(t.id) })),
    timezone: liveboard.timezone,
  };

  function getTopicName(id) {
    let t = topics.findBy({ id });
    return t ? t.getNameWithAncestry() : id || '';
  }
}
