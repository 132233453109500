import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import HomeEntry from 'models/location/inbox';
import LocationTuples from 'models/location/location_classes';
import LocationFromJs from 'models/location/location_from_js';

const locationClasses = LocationTuples.map(tuple => tuple[1]);

export default createModel({
  modelName: 'LocationHistory',
  properties: {
    history: prop(locationClasses)
      .default(() => [new HomeEntry()])
      .fromJs(LocationFromJs),
    isNavigatingBack: prop(Boolean).default(false),
  },

  updateHistory(lastLocation) {
    // do not add an entry if user is using back navigation
    if (this.isNavigatingBack) {
      this.setNavigatingBack(false);
      return;
    }

    if (lastLocation.constructor === this.getPreviousEntry().constructor) {
      this.replacePreviousEntry(lastLocation);
    } else if (lastLocation.constructor.breadcrumb) {
      this.addEntry(lastLocation);
    }

    this.setNavigatingBack(false);
  },

  addEntry(historyEntry) {
    this.history.push(historyEntry);
  },

  getPreviousEntry() {
    return _.last(this.history);
  },

  popPreviousEntry() {
    return this.history.length > 1 ? this.history.pop() : this.history[0];
  },

  replacePreviousEntry(historyEntry) {
    this.history.pop();
    this.history.push(historyEntry);
  },

  resetHistory() {
    if (this.history.length > 1) {
      this.history = [new HomeEntry()];
    }
    this.setNavigatingBack(false);
  },

  getNavigatingBack() {
    return this.isNavigatingBack;
  },

  setNavigatingBack(value) {
    this.isNavigatingBack = value;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
