import React from 'react';
import PropTypes from 'prop-types';

const AttachmentUploadProgress = ({ progress }) => {
  const adjusted = progress > 100 ? 100 : progress < 0 ? 0 : progress;
  return (
    <div className="uploadProgress">
      <div className="uploadProgress-percent" style={{ width: `${adjusted}%` }} />
    </div>
  );
};

AttachmentUploadProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default AttachmentUploadProgress;
