import _ from 'lodash';
import { descendants } from 'actions/topics/lib/topic_helpers';

export const HIERARCHY_CHAR = '>';

export default class TopicAncestryUpdater {
  constructor(context) {
    this.context = context;
  }

  get topicsStore() {
    return this.context.stores.topics;
  }

  get topicHierarchyStore() {
    return this.context.stores.topicHierarchy;
  }

  update() {
    // we need both stores to update the models
    if (this.topicsStore.isLoading() || this.topicHierarchyStore.isLoading()) {
      return;
    }

    const topicHierarchy = this.topicHierarchyStore.findAll();
    const topics = this.topicsStore.findAll();
    _.forEach(topics, topic => {
      const parent = this._getParent(topicHierarchy, topics, topic);
      const children = this._getChildren(topicHierarchy, topic);
      topic.update({
        parentId: parent && parent.id,
        children: children && children.length ? _.map(children, child => child.topicId) : undefined,
        nameWithAncestry: this._getAncestralName(topicHierarchy, topics, topic),
      });
    });

    const tmpProvider = {
      findBy: filters => _.find(topics, filters),
    };

    _.forEach(topics, topic => {
      topic.update({
        descendants: descendants(tmpProvider, topic.id),
      });
    });
    this.topicsStore.set(topics);
  }

  _getAncestralName(topicHierarchy, topics, topic) {
    const parent = this._getParent(topicHierarchy, topics, topic);
    if (!parent) {
      return topic.name;
    }
    return `${this._getAncestralName(topicHierarchy, topics, parent)} ${HIERARCHY_CHAR} ${topic.name}`;
  }

  _getChildren(topicHierarchy, topic) {
    return _.chain(topicHierarchy)
      .filter({ ancestralTopicId: topic.id })
      .uniq()
      .compact()
      .value();
  }

  _getParent(topicHierarchy, topics, topic) {
    const ancestor = _.chain(topicHierarchy)
      .filter({ topicId: topic.id })
      .first()
      .value();
    return (
      ancestor &&
      _.chain(topics)
        .filter({ id: ancestor.ancestralTopicId })
        .first()
        .value()
    );
  }
}
