import _ from 'lodash';

import qconsole from 'scripts/lib/qconsole';
import CustomerTransactionConverter from 'scripts/application/dto_converters/customer_transaction_converter';

export default class CustomerTransactionGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onBroadcast(dto) {
    if (this._isCustomerLoaded(dto.id)) {
      let transactionsStore = this.context.stores.customers.storesFor(dto.id).transactions;
      transactionsStore.set(_.map(dto.transactions, t => CustomerTransactionConverter.fromDto(t)));
    }
  }

  onFetchSuccess(dto) {
    if (this._isCustomerLoaded(dto.id)) {
      let transactionsStore = this.context.stores.customers.storesFor(dto.id).transactions;
      transactionsStore.set(_.map(dto.transactions, t => CustomerTransactionConverter.fromDto(t)));
      transactionsStore.resetLoading();
    }
  }

  onFetchError(error) {
    qconsole.log(`Failed to fetch customer transaction ${JSON.stringify(error)}`);
  }

  _isCustomerLoaded(customerId) {
    return this.context.stores.customers.has({ id: customerId });
  }
}
