import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class CustomAttributeService {
  constructor(database) {
    this.getDatabase = database;
  }

  findAll(payload, callback, requestorId, { orgId }) {
    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: this.getDatabase(orgId).customAttributes || [],
    });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/custom-attributes': {
          GET: this.findAll,
        },
      },
      this
    );
  }
}
