import isFunction from 'lodash/isFunction';

import ActiveSession, { SessionCustomer } from 'models/active_session';
import addCustomerToInbox from 'actions/inbox/lib/add_customer_to_inbox';
import AutoAcceptedSessionNotification from 'models/notification/auto_accepted_session_notification';
import {
  checkAndResetNavigatingToNext,
  handleNotifiableEvent,
  removeEmptyQueueNotifications,
} from 'actions/conversation/lib/conversation_workflow';
import CustomerView from 'models/location/customer_view';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import { getOfferedSessionForAgent } from './session_finder';
import { getMessagePreview, isCustomerItem } from 'models/conversation_item';
import InboundCommunicationSessionNotification from 'models/notification/inbound_communication_session_notification';
import isCustomerLoaded from 'actions/customer/lib/is_customer_loaded';
import { isMessagePreviewEnabled } from 'actions/communication_session/lib/messaging_config';
import loadCustomer from 'actions/customer/lib/load_customer';
import navigateToConversation from 'actions/conversation/lib/navigate_to_conversation';
import { NotificationSoundType } from 'actions/notification/lib/notification_constants';
import playNotificationSound from 'actions/notification/lib/play_notification_sound';
import qconsole from 'scripts/lib/qconsole';
import requestSessionDependencies from 'actions/communication_session/request_session_dependencies';
import { RoutingChannel } from 'models/agent_routing_preferences';
import { routingChannelForInteractionType } from 'models/interaction_type';
import RoutingEventType from 'models/routing_event/routing_event_type';
import RoutingQueueItem, { AgentParticipant, RoutingStatus } from 'models/routing_queue_item';
import ServerClock from 'scripts/application/lib/server_clock';
import { sessionTypeForContent } from 'actions/communication_session/lib/interaction_type';
import ShowConversationSystemNotification from 'actions/notification/show_conversation_system_notification';

export default class CommunicationSessionRoutingEventObserver {
  constructor(context) {
    this.context = context;
  }

  handleRoutingEvent(eventDto, correlationId) {
    switch (eventDto.type) {
      case RoutingEventType.INBOUND_COMMUNICATION_SESSION:
        this.handleInboundCommunicationSessionEvent(eventDto, correlationId);
        break;
      case RoutingEventType.INBOUND_MESSAGE:
        this.handleInboundMessageEvent(eventDto);
        break;
    }
  }

  handleInboundCommunicationSessionEvent(eventDto, correlationId) {
    let queueItem = RoutingQueueItem.fromJs(eventDto.queueItem);
    let customer = SessionCustomer.create({
      id: queueItem.customerId,
    });

    let activeSession = this.context.stores.activeSessions.findBy({ id: queueItem.id });
    const isNew = !activeSession;

    let isNavigatingToNext = false;
    if (!activeSession) {
      activeSession = ActiveSession.create({
        id: queueItem.id,
        customer,
        queueItem,
      });
      isNavigatingToNext = checkAndResetNavigatingToNext(this.context);
      requestSessionDependencies(this.context, activeSession.customer.id, queueItem.id, activeSession.conversationId());
    } else {
      if (activeSession.queueItem._version >= queueItem._version) {
        qconsole.log(
          `Ignored outdated version [${queueItem._version}] of queue item [${queueItem.id}] in inbound session event`
        );
        queueItem = activeSession.queueItem;
      } else {
        activeSession.update({ customer, queueItem });
      }
    }

    let messagePreview = eventDto.messagePreview;

    if (isCustomerLoaded(this.context, activeSession.customer.id)) {
      let customerStores = this.context.stores.customers.storesFor(activeSession.customer.id);
      let customerName = customerStores.profile.get().name;
      if (customerName) {
        activeSession.updateCustomerName(customerName);
      }

      if (!messagePreview && isMessagePreviewEnabled(this.context)) {
        const sessionType = queueItem.offeredSessionType || queueItem.getLatestSession().type;
        let latestCustomerItem = getLatestManualItem({
          conversationHistory: customerStores.conversationHistory,
          conversationId: queueItem.conversationId,
          filter: ci => isCustomerItem(ci) && sessionTypeForContent(ci.content) === sessionType,
        });
        if (latestCustomerItem) {
          messagePreview = getMessagePreview(latestCustomerItem.content);
        }
      }
    } else {
      loadCustomer(this.context, activeSession.customer.id);
    }

    this.context.stores.activeSessions.addOrReplace(activeSession);

    addCustomerToInbox(this.context, {
      conversationId: activeSession.conversationId(),
      customerId: activeSession.customer.id,
    });

    removeEmptyQueueNotifications(this.context);

    const currentAgentId = this.context.stores.currentAgent.get().id;

    if (isAlreadyAcceptedBy(queueItem, currentAgentId) && !isNew) {
      // this addresses the race condition where the inbound message event comes in before this session event
      this.notifyAgent(AutoAcceptedSessionNotification.create(notificationAttrs()));

      return;
    }

    if (!queueItem.isOffered() || queueItem.getOfferingAgentId() !== currentAgentId) {
      return;
    }

    const alreadyOfferedSession = getOfferedSessionForAgent(this.context.stores.activeSessions, currentAgentId);
    if (alreadyOfferedSession && queueItem.id !== alreadyOfferedSession.queueItem.id) {
      ErrorReporter.reportError(Error('agent offered new communication session even though they already have one'), {
        extra: { correlationId, incoming: queueItem, existing: alreadyOfferedSession.queueItem },
      });
    }

    if (!this.subjectToAutoAccept(queueItem, currentAgentId)) {
      this.notifyAgent(InboundCommunicationSessionNotification.create(notificationAttrs()));

      return;
    }

    this.context.capacityManager.postponeMessagingRouting();

    if (isMailType(activeSession.queueItem.offeredSessionType)) {
      if (this._isViewingCustomer(activeSession.customer.id)) {
        return;
      }

      if (isNavigatingToNext) {
        navigateToConversation(this.context, {
          customerId: activeSession.customer.id,
          conversationId: activeSession.conversationId(),
        });

        return;
      }
    }

    this.notifyAgent(AutoAcceptedSessionNotification.create(notificationAttrs()));

    function notificationAttrs() {
      return {
        id: activeSession.id,
        profile: activeSession.customer,
        conversationId: activeSession.queueItem.conversationId,
        messagePreview,
        sessionType: activeSession.queueItem.offeredSessionType,
      };
    }
  }

  handleInboundMessageEvent(eventDto) {
    const sessionId = eventDto.queueItemId;
    let activeSession = this.context.stores.activeSessions.findBy({ id: sessionId });

    let currentAgentId = this.context.stores.currentAgent.get().id;
    if (!activeSession) {
      let conversationId = eventDto.conversationId;
      activeSession = ActiveSession.create({
        id: sessionId,
        queueItem: RoutingQueueItem.create({
          id: sessionId,
          conversationId,
          agents: [AgentParticipant.create({ id: currentAgentId, updatedAt: ServerClock.toISOString() })],
        }),
        customer: SessionCustomer.create({
          id: eventDto.customerId,
        }),
      });

      checkAndResetNavigatingToNext(this.context);
      requestSessionDependencies(this.context, activeSession.customer.id, sessionId, conversationId);
    }
    this.context.stores.activeSessions.addOrReplace(activeSession);

    removeEmptyQueueNotifications(this.context);

    if (isAlreadyAcceptedBy(activeSession.queueItem, currentAgentId)) {
      if (isMessagingType(eventDto.sessionType)) {
        playNotificationSound(NotificationSoundType.INBOUND_MESSAGE);
      }

      let notification = this.context.stores.notifications.findBy({ id: activeSession.id });
      if (notification?.sessionType === eventDto.sessionType && isFunction(notification?.updateMessagePreview)) {
        notification.updateMessagePreview(eventDto.messagePreview);
        this.context.stores.notifications.replace(notification);

        return;
      }

      handleNotifiableEvent(this.context, eventDto);
    }
  }

  notifyAgent(notification) {
    this.context.stores.notifications.addOrReplace(notification);

    if (isMessagingType(notification.sessionType)) {
      playNotificationSound(NotificationSoundType.INBOUND_MESSAGE);
    }

    this.context.executeAction(ShowConversationSystemNotification, notification);
  }

  subjectToAutoAccept(queueItem, currentAgentId) {
    let messagingConfiguration = this.context.stores.messagingConfiguration.get();
    let messagingAutoAcceptEnabled = messagingConfiguration?.autoAssignMessagingSessions;

    return (
      (messagingAutoAcceptEnabled || isMailType(queueItem.offeredSessionType)) &&
      queueItem.getOfferingAgentId() === currentAgentId
    );
  }

  isFeatureEnabled(feature) {
    return this.context.stores.appFeatures.get().isEnabled(feature);
  }

  _isViewingCustomer(customerId) {
    let currentLocation = this.context.stores.currentLocation.get();
    return currentLocation instanceof CustomerView && currentLocation.customerId === customerId;
  }
}

function isAlreadyAcceptedBy(queueItem, currentAgentId) {
  return queueItem.status === RoutingStatus.ACTIVE && queueItem.getAssignedAgentId() === currentAgentId;
}

function isMessagingType(sessionType) {
  return routingChannelForInteractionType(sessionType) === RoutingChannel.MESSAGING;
}

function isMailType(sessionType) {
  return routingChannelForInteractionType(sessionType) === RoutingChannel.MAIL;
}
