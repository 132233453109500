import _ from 'lodash';

import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

export const CustomerActivityType = createEnum('ACTION_API', 'ALERT', 'EMAIL', 'ISSUE', 'SMS', 'SURVEY', 'WARNING');

const CustomerActivity = createModel({
  modelName: 'CustomerActivity',
  properties: {
    activityType: prop().oneOf(..._.keys(CustomerActivityType)),
    additionalContent: prop(String),
    body: prop(String),
    createdAt: prop(String),
    expandByDefault: prop(Boolean),
    link: {
      url: prop(String),
      text: prop(String),
    },
    occurredAt: prop(String),
    sourceName: prop(String),
    title: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default CustomerActivity;
