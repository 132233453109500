import styled from 'styled-components';

const ErrLink = styled.a`
  color: ${p => p.theme.colors.red400};
  text-decoration: underline;
  &:hover {
    color: ${p => p.theme.colors.red600};
    text-decoration: underline;
  }
  &:visited {
    color: ${p => p.theme.colors.red400};
  }
`;

export default ErrLink;
