import Joi from 'joi';
import _ from 'lodash';

import { AttributeType } from 'models/configuration/attribute_def';

export const attributeSchema = Joi.object({
  attr: Joi.string()
    .trim()
    .min(1)
    .required(),
  type: Joi.string()
    .valid(...Object.values(AttributeType))
    .required(),
  label: Joi.string().allow(''),
  linkTemplate: Joi.string(),
  linkText: Joi.string(),
});

export const blockSchema = Joi.object({
  title: Joi.string(),
  attributes: Joi.array()
    .items(attributeSchema)
    .min(1),
});

export const itemSummarySchema = Joi.object({
  attributes: Joi.array()
    .items(attributeSchema)
    .min(1),
});

export const sectionSchema = Joi.object({
  title: Joi.string(),
  blocks: Joi.array().items(blockSchema),
  listSource: Joi.string().optional(),
  listMaxLength: Joi.any().when('listSource', {
    is: Joi.exist(),
    then: Joi.number().positive(),
    otherwise: Joi.any().forbidden(),
  }),
  listItem: Joi.any().when('listSource', {
    is: Joi.exist(),
    then: blockSchema.required(),
    otherwise: Joi.any().forbidden(),
  }),
});

export const itemDetailsSchema = Joi.object({
  header: Joi.object({
    title: Joi.string(),
    attributes: Joi.array()
      .items(attributeSchema)
      .min(1),
  }),
  sections: Joi.array().items(sectionSchema),
});

export const displayConfigurationSchema = Joi.object({
  title: Joi.string(),
  defaultExpandThreshold: Joi.number()
    .integer()
    .positive()
    .optional(),
  itemSummary: itemSummarySchema,
  itemDetails: itemDetailsSchema,
});

export const dataConfigurationSchema = Joi.object({
  namespace: Joi.string()
    .trim()
    .required(),
  requestTimeout: Joi.number()
    .integer()
    .positive()
    .optional(),
  defaultSortOrder: Joi.object().optional(),
  defaultFilter: Joi.object().optional(),
  defaultRowLimit: Joi.number()
    .integer()
    .positive()
    .optional(),
});

export const configurationSchema = Joi.object({
  dataConfiguration: dataConfigurationSchema.required(),
  displayConfiguration: displayConfigurationSchema.required(),
});

export const validateConfig = config => {
  if (_.isEmpty(config)) {
    return { error: Error('no configuration was provided'), valid: false };
  }

  const { error } = configurationSchema.validate(config);
  if (error) {
    return { error, valid: false };
  }

  return { valid: true };
};
