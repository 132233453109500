import { Factory } from 'rosie';
import '../conversation_item_factory';
import IdGenerator from '../lib/id_generator';

Factory.define('SmsMessage')
  .attr('type', 'SMS');

Factory.define('SmsMessageWithDefaults')
  .extend('SmsMessage')
  .attr('from', '+16139920250')
  .attr('to', '+12024561111')
  .attr('body', 'See you at the party, Obama!');

Factory.define('SmsMessageItem')
  .extend('ConversationItem')
  .attr('content', ['content'], (content) => Factory.attributes('SmsMessage', content));

Factory.define('SmsMessageItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], (content) => Factory.attributes('SmsMessageWithDefaults', content));

Factory.define('SmsMessageItemIncomingWithDefaults')
  .extend('SmsMessageItemWithDefaults')
  .attr('initiator', {
    type: 'CUSTOMER',
    id: IdGenerator.newId()
  });

Factory.define('SmsMessageItemOutgoingWithDefaults')
  .extend('SmsMessageItemWithDefaults')
  .attr('initiator', {
    type: 'AGENT',
    id: IdGenerator.newId()
  });
