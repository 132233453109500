import React, { useEffect, useRef } from 'react';
import Dotdotdot from 'react-dotdotdot';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import ExternalLink from 'components/lib/external_link';
import ProductImage from 'components/customer/summary/transactions/product_image';
import ProfileCardType from 'models/configuration/profile_card_type';
import { setOverflowTitle } from 'components/lib/overflow_title';
import { TransactionDefType } from 'models/configuration/transaction_def';

export default function OrderSummary({ attributes, featuredImage, shouldRenderImage }) {
  const orderLinkRef = useRef();
  const orderStatusRef = useRef();
  const orderTotalRef = useRef();

  const { createdAt, orderLink } = attributes;
  const createdAtMoment = moment(createdAt);
  const createdAtVal = createdAt && createdAtMoment.isValid() ? createdAtMoment.format('M/D/YYYY') : '';
  const createdAtText = createdAtVal ? `Ordered on ${createdAtVal}` : null;

  const displayOrderNumber = _.trim(_.get(attributes, 'orderNumber', ''));
  const displayStatus = getOrderStatus(attributes);
  const displayTotal = _.trim(_.get(attributes, 'orderTotal', ''));

  const productImage = shouldRenderImage ? (
    <StyledImageWrapper>
      <ProductImage imageUrl={featuredImage || ''} />
    </StyledImageWrapper>
  ) : null;

  // To avoid double-labelling, check whether the order number starts with "Order..."
  const needsLinkPrefix = !!displayOrderNumber && !displayOrderNumber.toLowerCase().startsWith('order');
  const linkPrefix = needsLinkPrefix ? <OrderLabelWrapper>Order</OrderLabelWrapper> : null;

  const orderNumberRow = displayOrderNumber ? (
    <SummaryRow>
      <Dotdotdot clamp={2} ref={orderLinkRef}>
        <OrderLinkWrapper>
          {linkPrefix}
          <ExternalLink
            attrName="orderLink"
            className="orderTransaction-orderLink"
            onClick={evt => {
              // stop event bubbling to make sure it doesn't interfere with the wrapper click handlers
              evt && evt.stopPropagation();
            }}
            subType={TransactionDefType.ORDER}
            text={displayOrderNumber}
            type={ProfileCardType.TRANSACTIONS}
            url={orderLink}
          />
        </OrderLinkWrapper>
      </Dotdotdot>
    </SummaryRow>
  ) : null;

  useEffect(() => {
    setOverflowTitle(orderStatusRef, displayStatus);
    setOverflowTitle(orderTotalRef, displayTotal);
    setOverflowTitle(orderLinkRef.current?.container, displayOrderNumber);
  });

  return (
    <StyledOrderTransactionSummary className="order-summary">
      {productImage}
      <SummaryWrapper withImage={shouldRenderImage}>
        {orderNumberRow}
        <SummaryRow className="order-totals">{renderStatusAndTotal()}</SummaryRow>
        <SummaryRow className="order-createdAt" title="Date Created">
          {createdAtText}
        </SummaryRow>
      </SummaryWrapper>
    </StyledOrderTransactionSummary>
  );

  /**
   * Different customer implementations use either `status` or `orderStatus` so we have to account
   * for both. We try to use `status` falling back on `orderStatus` if necessary
   *
   * @param {Object} orderAttributes
   * @returns {string}
   */
  function getOrderStatus(orderAttributes) {
    const status = _.get(orderAttributes, 'status', '');
    const fallbackStatus = _.get(orderAttributes, 'orderStatus', '');

    return _.trim(status || fallbackStatus || '');
  }

  function renderStatusAndTotal() {
    if (!displayStatus && !displayTotal) return null;

    const renderedStatus = displayStatus ? (
      <StyledOrderStatus className="order-status" ref={orderStatusRef}>
        {displayStatus}
      </StyledOrderStatus>
    ) : null;

    // Check if we need to start clipping the order total
    const clampOrderTotal = displayTotal && displayTotal.length > 10;
    const renderedTotal = displayTotal ? (
      <StyledOrderTotal clampWidth={!!clampOrderTotal} className="order-total" ref={orderTotalRef}>
        {displayTotal}
      </StyledOrderTotal>
    ) : null;

    return (
      <>
        {renderedStatus}
        {displayTotal && displayStatus ? <Bullet /> : null}
        {renderedTotal}
      </>
    );
  }
}

const Bullet = styled.div`
  border-radius: 50%;
  background-color: ${p => p.theme.colors.gray800};
  height: 6px;
  width: 6px;
  margin: 0 6px;
  min-width: 6px;
`;

const OrderLabelWrapper = styled.span`
  color: ${p => p.theme.colors.black};
  margin-right: 0.275em;
  position: relative;
  white-space: nowrap;
`;

const OrderLinkWrapper = styled.div`
  color: ${p => p.theme.colors.gray800};
  font-size: ${p => p.theme.fontSize.base};
  font-weight: ${p => p.theme.fontWeight.mediumHeavy};
  position: relative;

  & .externalLink,
  & .externalLink-text,
  & .orderTransaction-orderLink {
    display: inline;
    position: relative;
    white-space: normal;
    word-break: break-all;
  }
`;

const StyledImageWrapper = styled.div`
  align-self: flex-start;
  margin: 2px 0 2px 2px;
`;

const StyledOrderTransactionSummary = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-right: 12px;
  width: calc(100% - 16px);
`;

const StyledOrderStatus = styled.div`
  font-size: ${p => p.theme.fontSize.base};
  font-weight: ${p => p.theme.fontWeight.mediumHeavy};
  max-width: 100%;
  text-align: left;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledOrderTotal = styled.div`
  max-width: 60%;
  ${p => (p.clampWidth ? 'min-width: 48%; overflow: hidden;' : '')}
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SummaryRow = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: ${p => p.theme.fontWeight.normal};
  width: 100%;
`;

const SummaryWrapper = styled.div`
  font-size: ${p => p.theme.fontSize.base};
  font-weight: ${p => p.theme.fontWeight.normal};
  width: ${p => (p.withImage ? 'calc(100% - 72px)' : '100%')};
`;

/**
 * Because of wild variety of ways people send us their order data, we use `status` as the primary "order status"
 * attribute with fallback onto `orderStatus`. Normally, these two would not appear at the same time, but we have
 * to account for the fact
 */
OrderSummary.propTypes = {
  attributes: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    status: PropTypes.string,
    orderLink: PropTypes.string,
    orderNumber: PropTypes.string,
    orderStatus: PropTypes.string,
    orderTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    products: PropTypes.array,
  }),
  featuredImage: PropTypes.string,
  shouldRenderImage: PropTypes.bool,
};
