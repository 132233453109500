import connect from 'components/lib/connect';
import NavigateToSearchResults from 'actions/search/navigate_to_search_results';
import UniversalSearch from 'components/search/universal_search';

let mapStateToProps = function(context, props) {
  let currentSearchResultsId = context.getProvider('currentLocation').get().currentResultId || null;

  return {
    placeholder: props.placeholder,
    searchResultId: currentSearchResultsId,
  };
};

let mapExecuteToProps = function(executeAction) {
  return {
    onSearchQueryChange: query => executeAction(NavigateToSearchResults, query),
  };
};

export default connect(mapStateToProps, mapExecuteToProps)(UniversalSearch);
