import classnames from 'classnames';
import Immutable from 'immutable';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import connect from 'components/lib/connect';
import ConversationItemSearchHit from 'models/search/conversation_item_search_hit';
import ConversationPreview from 'models/conversation_preview';
import CustomerConversationCard from 'components/lib/customer_conversation_card/customer_conversation_card';
import CustomerConversationItemCard from 'components/lib/customer_conversation_card/customer_conversation_item_card';
import CustomerProfileDef from 'models/customer_profile_def';
import CustomerProfileSearchHit from 'models/search/customer_profile_search_hit';
import CustomerTaskCard from 'components/lib/customer_conversation_card/customer_task_card';
import TaskPreview from 'models/task_preview';
import { useThrottled } from 'components/hooks/debounce_hooks';
import useWindowSize from 'components/hooks/use_window_size';

const FALLBACK_INBOX_HEIGHT = 500;

export function CustomerConversationCardList({
  customerProfileDef,
  hits,
  infiniteScroll,
  initialScrollY,
  isLoading,
  onCustomerClick,
  onInfiniteLoad,
  onItemClick,
  onScroll,
  selectedIndex,
  showLoading,
  showUnread,
  style,
}) {
  if (showLoading) {
    if (isLoading) {
      return <div className="customerConversationCards">Loading results..</div>;
    }

    if (hits.length === 0) {
      return (
        <div className="customerConversationCards">
          <h3 className="customerConversationCards-header no-matching">No matching results</h3>
        </div>
      );
    }
  }

  let wrappedHits;
  let renderedHits = hits.map((hit, index) =>
    getCard({
      customerProfileDef,
      hit,
      onCustomerClick,
      onItemClick,
      index,
      isSelected: index === selectedIndex,
      showUnread,
    })
  );

  if (infiniteScroll && onInfiniteLoad) {
    wrappedHits = (
      <InfiniteList
        hits={renderedHits}
        initialScrollY={initialScrollY}
        onInfiniteLoad={onInfiniteLoad}
        onScroll={onScroll}
        style={style}
      />
    );
  } else {
    wrappedHits = <div>{renderedHits}</div>;
  }

  let header = `${hits.length} Possible Result${hits.length > 1 ? 's' : ''}`;

  return (
    <div className="customerConversationCards">
      {showLoading ? <h3 className="customerConversationCards-header">{header}</h3> : ''}
      {wrappedHits}
    </div>
  );
}

export function InfiniteList({ hits, initialScrollY, onScroll, onInfiniteLoad, style }) {
  const handleScroll = useCallback(evt => {
    onScroll(evt.target.scrollTop);
  }, []);
  const debouncedHandleScroll = useThrottled(handleScroll, 300);

  useWindowSize(); // Will re-render when window size changes

  // HACK: Read the height from the parent element. Not ideal.
  const [height, setHeight] = useState(() => {
    const container = document.getElementsByClassName('agentInbox')[0];
    return (container && container.getBoundingClientRect().height) || 0;
  });

  useLayoutEffect(() => {
    const container = document.getElementsByClassName('agentInbox')[0];
    const newHeight = (container && container.getBoundingClientRect().height) || 0;
    if (newHeight !== height) {
      setHeight(newHeight);
    }
  });

  return (
    <InfiniteScroll
      dataLength={hits.length}
      hasMore
      height={height || FALLBACK_INBOX_HEIGHT}
      initialScrollY={initialScrollY}
      loader={<div className="customerConversationCard-loading" />}
      next={onInfiniteLoad}
      onScroll={debouncedHandleScroll}
      style={style}
    >
      {hits}
    </InfiniteScroll>
  );
}

function getCard({ customerProfileDef, hit, onCustomerClick, onItemClick, index, isSelected, showUnread }) {
  if (hit instanceof CustomerProfileSearchHit) {
    const {
      customAttributes,
      email,
      externalCustomerAddresses,
      externalCustomerId,
      id: customerId,
      name,
      note,
      phone,
      conversation,
      conversationItem,
      taskCount,
      address,
      matchingAttribute,
    } = hit;

    return (
      <CustomerConversationCard
        conversation={conversation}
        conversationItem={conversationItem}
        customerProfileDef={customerProfileDef}
        isLink
        isSelected={isSelected}
        key={customerId}
        onClick={ev => onCustomerClick(customerId, conversation && conversation.id, index)}
        profile={{
          address,
          customAttributes,
          email,
          externalCustomerAddresses,
          externalCustomerId,
          id: customerId,
          name,
          note,
          phone,
          matchingAttribute,
        }}
        taskCount={taskCount}
      />
    );
  } else if (hit instanceof ConversationItemSearchHit) {
    const { id: customerId, email, externalCustomerAddresses, name, phone } = hit.customer;
    const { agent: assignedAgentName, group: assignedGroupName } = hit.assignee;
    const { id: conversationId, matchingText, topic, topics, createdAt, updatedAt, status, dueAt } = hit;
    return (
      <CustomerConversationItemCard
        conversationId={conversationId}
        conversationItem={{
          assignedAgentName,
          assignedGroupName,
          createdAt,
          dueAt,
          status,
          text: matchingText,
          topic,
          topics: Immutable.fromJS(topics),
          updatedAt,
        }}
        isLink
        isSelected={isSelected}
        key={conversationId}
        onClick={ev => onCustomerClick(customerId, conversationId, index)}
        profile={{ id: customerId, name, email, externalCustomerAddresses, phone }}
      />
    );
  } else if (hit instanceof TaskPreview) {
    const { id: customerId, name, address } = hit.customer;
    const email = hit.customer.getDefaultEmail();
    const profile = {
      address,
      customerId,
      email,
      image: hit.customer.getImageUrl(),
      name,
      phone: hit.customer.getPrimaryPhoneNumberForDisplay(),
    };

    return (
      <CustomerTaskCard
        className={classnames('inboxItem', {
          'customerConversationCard-agent-inbox': showUnread,
          'customerConversationCard-unread': showUnread && hit.isUnread(),
        })}
        index={index}
        isLink
        item={hit.task}
        key={`task-${hit.task.id}`}
        onClick={onItemClick}
        {...profile}
      />
    );
  } else if (hit instanceof ConversationPreview) {
    const { customAttributes, id: customerId, name, address } = hit.customer;
    const email = hit.customer.getDefaultEmail();

    const { id: conversationId } = hit.conversation;
    return (
      <CustomerConversationCard
        className={classnames('inboxItem', {
          'customerConversationCard-agent-inbox': showUnread,
          'customerConversationCard-unread': showUnread && hit.isUnread(),
        })}
        conversation={hit.conversation}
        conversationItem={hit.conversationItem}
        customerProfileDef={customerProfileDef}
        hasDraft={hit.hasDraft}
        isDisabled={hit.isUnverified()}
        isLink={!hit.isUnverified()}
        key={`conversation-${hit.conversation.id}`}
        onClick={ev => onCustomerClick(customerId, conversationId, index)}
        profile={{
          address,
          customAttributes,
          email,
          id: customerId,
          image: hit.customer.getImageUrl(),
          name,
          phone: hit.customer.getPrimaryPhoneNumberForDisplay(),
        }}
        taskCount={hit.taskCount}
      />
    );
  }
  return null;
}

CustomerConversationCardList.propTypes = {
  customerProfileDef: PropTypes.instanceOf(CustomerProfileDef),
  hits: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(CustomerProfileSearchHit),
      PropTypes.instanceOf(ConversationItemSearchHit),
      PropTypes.instanceOf(ConversationPreview),
      PropTypes.instanceOf(TaskPreview),
    ])
  ),
  isLoading: PropTypes.bool,
  onCustomerClick: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
  showLoading: PropTypes.bool,
  showUnread: PropTypes.bool,
};
CustomerConversationCardList.defaultProps = {
  showLoading: true,
};

const CustomerConversationCardListContainer = connect(mapStateToProps)(CustomerConversationCardList);

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  return {
    customerProfileDef: getProvider('customerProfileDef').get(),
    ...props,
  };
}

export default CustomerConversationCardListContainer;
