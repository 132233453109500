export default class TrackCommentPanelVisibility {
  constructor(context) {
    this.context = context;
  }

  run({ itemId, isVisible }) {
    if (isVisible) {
      this.context.analytics.track('Comment Panel Opened', {
        itemId,
      });
    } else {
      this.context.analytics.track('Comment Panel Closed', {
        itemId,
      });
    }
  }
}
