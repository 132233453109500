import CustomerView from 'models/location/customer_view';

export default class SwitchCurrentConversation {
  constructor(context) {
    this.context = context;
  }

  run({ anchorPosition, conversationId, conversationItemId }) {
    let currentLocation = this.context.stores.currentLocation.get();
    if (!(currentLocation instanceof CustomerView)) {
      throw new Error(
        `cannot switch conversation when current location is [${currentLocation && currentLocation.constructor.name}]`
      );
    }
    if (!conversationId) {
      return;
    }
    currentLocation.changeCurrentConversation(conversationId);
    if (conversationItemId) {
      currentLocation.changeCurrentConversationItem(conversationItemId);
    } else {
      currentLocation.clearCurrentConversationItem();
    }
    if (anchorPosition) {
      currentLocation.setAnchorPosition(anchorPosition);
    } else {
      currentLocation.clearAnchorPosition();
    }

    this.context.stores.currentLocation.set(currentLocation);
  }
}
