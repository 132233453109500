import _ from 'lodash';

import ActiveCall from 'models/active_call';
import AgentPhonePreferences from 'models/agent_phone_preferences';
import createPhoneCallItem from 'scripts/domain/factories/conversation_item/create_phone_call_item';
import createTakenActiveCallError from 'scripts/domain/factories/error/create_taken_active_call_error';
import PhoneCall from 'models/phone_call';
import SubmitComposition, { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';

export default class InitiatePhoneCall extends SubmitComposition {
  createOutgoingItem(composition, { companyNumber, customerNumber }) {
    if (this.context.stores.activeCall.get()) {
      let compositionErrors = [createTakenActiveCallError()];
      this.context.stores.activeCall.setErrors(compositionErrors);
      return { compositionErrors };
    }

    let phoneCallItem = createPhoneCallItem({
      agentId: this.currentAgent.id,
      customerId: this.currentCustomerId,
      conversationId: this.activeConversation.id,
      content: {
        participants: [
          {
            participantId: this.currentAgent.id,
            status: PhoneCall.ParticipantStatus.DIALING,
            type: PhoneCall.ParticipantType.AGENT,
            callLegType: _.get(this.currentAgent.voiceConfiguration, 'preference', AgentPhonePreferences.BROWSER),
          },
        ],
        customerId: composition.customerId,
      },
    });
    phoneCallItem.replaceContent(phoneCallItem.content.makeCall(companyNumber, customerNumber));

    return { compositionErrors: [], conversationItem: phoneCallItem };
  }

  getConversationUpdates(conversation) {
    const updates = super.getConversationUpdates(conversation);
    return this.activeConversation.getUpdatedAttributesForOutgoingPhoneCall(this.currentAgent.id, updates);
  }

  sendMessage(conversationUpdates, phoneCallItem) {
    this.context.stores.activeCall.setPending(
      new ActiveCall({
        id: phoneCallItem.id,
        conversationId: this.activeConversation.id,
        conversationItem: phoneCallItem,
        customer: this.currentCustomerProfile,
      })
    );

    this.context.gateways.outgoingCall.add(this.currentAgent.id, {
      id: phoneCallItem.id,
      customerId: this.currentCustomerId,
      conversation: conversationUpdates,
      conversationItem: getOutgoingItemDto(phoneCallItem),
    });
  }

  get currentCustomerProfile() {
    return this.context.stores.customers.find(this.currentCustomerId).profile;
  }
}
