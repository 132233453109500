import React from 'react';
import IconContainer from './icon_container';

export default function(props) {
  return (
    <IconContainer viewBox="0 0 51 51" {...props} className="circleCheckIcon">
      <g fill="none" strokeWidth="2">
        <circle cx="25" cy="25" r="25" stroke="#009B00" strokeWidth="2" />
        <path
          d="M14 26.226L21.83 33 37 18"
          stroke="#0E790E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </IconContainer>
  );
}
