import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('ConversationMessage').attr('type', 'CONVERSATION_MESSAGE');

Factory.define('ConversationMessageWithDefaults')
  .extend('ConversationMessage')
  .attr('externalId', 'example-externalId')
  .attr('companyAddress', '+15552224444')
  .attr('customerAddress', '+15557775555')
  .attr('direction', 'INCOMING')
  .attr('channel', 'WHATSAPP')
  .attr('content', ['content'], content => Factory.attributes('MessageContentTypeTextWithDefaults', content))
  .attr('meta', {})
  .attr('reactions', [])
  .attr('status', {
    value: 'DELIVERED',
    errorCode: '',
  })
  .attr('statusUpdates', []);

Factory.define('ConversationMessageItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('ConversationMessage', content));

Factory.define('ConversationMessageItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('ConversationMessageWithDefaults', content));
