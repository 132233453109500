import Dotdotdot from 'react-dotdotdot';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { InboxItemType } from 'models/agent_inbox/agent_inbox_item';
import ItemPreviewText from 'components/lib/item_preview_text';
import RelativeDateTime from 'components/lib/date/relative_date_time';
import ChannelIcon from 'components/sidebar/inbox_channel_icons';
import ConversationItemType from 'models/conversation_item_type';

export default function CustomerDetails({ item, currentAgentId, selectedItemId, collapsed }) {
  const dueAt = item.getDueAt();
  const nearOrOverDue =
    moment().isAfter(dueAt) ||
    moment()
      .add(5, 'minutes')
      .isAfter(dueAt);
  const customerName = (item.profile && item.profile.name) || 'Unknown customer';
  const nameLabel = item.type === InboxItemType.TASK ? `Task for ${customerName}` : customerName;

  function itemPreviewLabel(item) {
    if (
      get(item, 'item.contentType') === ConversationItemType.PAYMENT_STATUS_EVENT ||
      get(item, 'item.contentType') === ConversationItemType.SECURE_DATA_REQUEST
    ) {
      return null;
    }
    if (get(item, 'item.initiator.id') === currentAgentId) {
      return <Label>You:</Label>;
    }
    return null;
  }

  return (
    <DetailsContainer>
      <Header>
        <Name className="fs-mask" data-aid="customerList-item-name" dir="auto" title={nameLabel}>
          {nameLabel}
        </Name>
        {dueAt ? (
          <DueAt
            data-aid="customerList-item-dueAt"
            isCurrent={!collapsed && item.id === selectedItemId}
            isOverDue={nearOrOverDue}
          >
            due <RelativeDateTime shortened timestamp={dueAt} />
          </DueAt>
        ) : null}
        {item.type === InboxItemType.TASK ? null : (
          <StyledChannelIcon conversationItem={item.item} isCurrent={!collapsed && item.id === selectedItemId} />
        )}
      </Header>
      <Preview className="fs-mask" data-aid="customerList-item-preview" dir="auto">
        <Dotdotdot clamp={2}>
          {itemPreviewLabel(item)}
          <ItemPreviewText conversationItem={item.item} />
        </Dotdotdot>
      </Preview>
    </DetailsContainer>
  );
}

const StyledChannelIcon = styled(ChannelIcon)`
  flex-shrink: 0;
`;

const DueAt = styled.div`
  ${p => (p.isOverDue && !p.isCurrent ? `color: ${p.theme.colors.red400}` : '')};
  flex-shrink: 0;
  white-space: nowrap;
`;

const DetailsContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
  padding: 4px 8px;
`;

const Header = styled.span`
  font-size: 13px;
  display: flex;
  justify-content: space-between;
`;

const Name = styled.span`
  flex-grow: 1;
  font-weight: bold;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Preview = styled.div`
  display: flex;
`;

const Label = styled.span`
  margin-right: 4px;
`;
