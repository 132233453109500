import _ from 'lodash';

import CloseSidePanels from 'actions/customer/close_side_panels';
import CloseAnswerPanel from 'actions/answers/close_answer_panel';
import OpenCommentPanel from 'actions/task/open_comment_panel';
import ToggleAnswerPanel from 'actions/answers/toggle_answer_panel';

export default class ToggleSidePanels {
  constructor(context) {
    this.context = context;
  }

  run(attrs) {
    const answerPanel = this.context.stores.answerPanel.get();
    const commentPanel = this.context.stores.commentPanel.get();

    const isAnswerOpen = answerPanel.isOpen();
    const isCommentOpen = commentPanel.isOpen();

    const currentLocation = this.context.stores.currentLocation.get();
    const { compositions } = this.context.stores.customers.storesFor(currentLocation.customerId);
    if (!attrs) {
      const composition = compositions.findBy({ id: currentLocation.currentCompositionId });
      attrs = {
        compositionId: _.get(composition, 'id'),
        conversationId: _.get(composition, 'conversationId'),
        customerId: _.get(composition, 'customerId'),
      };
    }

    if (!isCommentOpen && !isAnswerOpen) {
      // comment panel is closed & answer panel is closed; open comment panel
      this.context.executeAction(OpenCommentPanel, attrs);
    } else if (!isCommentOpen && isAnswerOpen) {
      // comment panel is closed & answer panel is open; open comment panel & close answer panel
      this.context.executeAction(ToggleAnswerPanel);
      this.context.executeAction(OpenCommentPanel, attrs);
    } else if (isCommentOpen && !isAnswerOpen) {
      // comment panel is open & answer panel is closed; close all panels
      this.context.executeAction(CloseSidePanels, attrs);
    } else {
      // comment panel is open & answer panel is open; close answer panel
      this.context.executeAction(CloseAnswerPanel, attrs);
    }
  }
}
