import React from 'react';

import AgentMentionedInItem from './items/agent_mentioned_in_item';
import AgentMentionedInTaskComment from './items/agent_mentioned_in_task_comment';
import AgentNotification from 'models/agent_notification';
import TaskClosedItem from './items/task_closed_item';
import TaskCommentedItem from './items/task_commented_item';

export default function AgentNotificationItem({ notification }) {
  if (
    notification.type === AgentNotification.Type.ASSIGNED_TASK_CLOSED ||
    notification.type === AgentNotification.Type.FOLLOWED_TASK_CLOSED
  ) {
    return <TaskClosedItem notification={notification} />;
  } else if (
    notification.type === AgentNotification.Type.ASSIGNED_TASK_COMMENTED ||
    notification.type === AgentNotification.Type.FOLLOWED_TASK_COMMENTED
  ) {
    return <TaskCommentedItem notification={notification} />;
  } else if (notification.type === AgentNotification.Type.ITEM_MENTION) {
    return <AgentMentionedInItem notification={notification} />;
  } else if (notification.type === AgentNotification.Type.ITEM_MENTION_COMMENT) {
    return <AgentMentionedInTaskComment notification={notification} />;
  }

  return null;
}
