export default class ClearMatches {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, numMatches }) {
    if (this.context.stores.customers.findBy({ id: customerId })) {
      this.context.stores.customers.storesFor(customerId).customerMatch.set(null);
    }

    this.context.analytics.track('Customer Match - None selected', { customerId, numMatches });
  }
}
