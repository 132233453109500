import { Editor, Path } from 'slate';

export function hasSingleSelectedInlineNode(editor, selection) {
  if (Path.equals(selection.anchor.path, selection.focus.path)) {
    for (const [node] of Editor.nodes(editor, { at: selection })) {
      if (Editor.isInline(editor, node)) {
        return true;
      }
    }
  }
  return false;
}
