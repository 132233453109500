import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 14 12" {...props} className="conversationMessageFilledIcon">
    <path
      clipRule="evenodd"
      d="M14 1.42a5.504 5.504 0 01-1.65.477A3.02 3.02 0 0013.614.222a5.61 5.61 0 01-1.825.735A2.796 2.796 0 009.692 0C8.106 0 6.82 1.357 6.82 3.03c0 .237.026.469.075.69C4.508 3.594 2.392 2.387.975.554a3.136 3.136 0 00-.388 1.523c0 1.052.507 1.98 1.277 2.522a2.759 2.759 0 01-1.3-.38v.038c0 1.467.99 2.692 2.303 2.971-.24.069-.494.106-.757.106-.185 0-.365-.019-.54-.056.365 1.205 1.425 2.08 2.682 2.105A5.574 5.574 0 010 10.637 7.813 7.813 0 004.402 12c5.283 0 8.171-4.618 8.171-8.622l-.01-.393A5.919 5.919 0 0014 1.42z"
      fillRule="evenodd"
    />
  </Icon>
);
