import React from 'react';
import Spinner from 'react-md-spinner';
import PropTypes from 'prop-types';

/**
 * @visibleName Spinner Two
 */

export default function SpinnerTwo({ color, size, stroke }) {
  return <Spinner borderSize={stroke} singleColor={color} size={size} />;
}

SpinnerTwo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  stroke: PropTypes.number,
};

SpinnerTwo.defaultProps = {
  color: '#D4D4D4',
  size: 32,
  stroke: 3,
};
