import mixin from 'scripts/lib/mixin';

import DocumentStoreSaveResultHandler from '../lib/document_store_save_result_handler';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default class OutgoingCallGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.activeCall;
  }

  handleAddSuccess() {
    // this handles the committing and subscribing of the active call for both outgoing WebRTC and Direct Dial calls
    this.handleSetSuccess();
    let activeCall = this.context.stores.activeCall.get();
    if (!activeCall) {
      return;
    }

    this.context.gateways.activeCall.cancelFetches();
    this.context.gateways.customerHistoryMerge.subscribe(activeCall.customer.id);
    this.context.gateways.conversationItem.request(activeCall.customer.id, activeCall.conversationItem.id);

    if (!this.isCustomerLoaded(activeCall.customer.id)) {
      this.context.gateways.customerProfile.request({ customerId: activeCall.customer.id });
    }
  }

  handleAddErrors(errorDtos) {
    if (this.store.isPending()) {
      this.context.executeAction(ShowToast, {
        type: ToastType.ERROR,
        message: 'An error occurred while making the outgoing call.  Please try again.',
      });

      let activeCall = this.store.getPending();
      if (activeCall) {
        let customerStores = this.context.stores.customers.storesFor(activeCall.customer.id);
        customerStores.conversationHistory.remove(activeCall.conversationItem.id);
        customerStores.itemIds.remove(activeCall.conversationItem.id);
      }
    }

    this.handleSetErrors(errorDtos);
  }

  isCustomerLoaded(customerId) {
    return this.context.stores.customers.has({ id: customerId });
  }
}

mixin(OutgoingCallGatewayObserver.prototype, DocumentStoreSaveResultHandler.prototype);
