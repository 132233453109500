import createModel, { prop } from './lib/create_model';

import Err from './err';
import Upload from './upload';
import UploadResult from './upload_result';

const MB = 1 << (10 * 2);

const UsersUpload = createModel({
  modelName: 'UsersUpload',
  properties: {
    file: prop(Upload),
    uploadResult: prop(UploadResult),
  },

  update(file) {
    this.file = file;
  },

  setResult(uploadResult) {
    this.uploadResult = uploadResult;
  },

  statics: {
    MAX_FILE_UPLOAD_SIZE_MB: 5,
    create(attrs) {
      return new this(attrs);
    },

    getValidationErrors(upload) {
      if (upload.fileDescriptor().contentLength > UsersUpload.MAX_FILE_UPLOAD_SIZE_MB * MB) {
        return [
          new Err({
            attr: 'upload',
            code: Err.Code.TOO_LONG,
            detail: `Size of uploaded CSV ${upload.fileDescriptor().filename} exceeds ${
              UsersUpload.MAX_FILE_UPLOAD_SIZE_MB
            }MB`,
          }),
        ];
      }
      return [];
    },
  },
});

export default UsersUpload;
