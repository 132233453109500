import classnames from 'classnames';
import createReactClass from 'create-react-class';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-date-range';

import Button, { ButtonTypes } from 'components/common/button';
import KeypressGlobalMixin from 'components/lib/keypress_global_mixin';
import TimeInput from 'components/common/datetime/time_input';
import { getTimestampFromDatetime } from 'components/lib/date/datetime_helpers';
import PopoverCard from '../common/popover_card';

const DatetimePickerMenu = createReactClass({
  mixins: [KeypressGlobalMixin],

  propTypes: {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholderText: PropTypes.string.isRequired,
    value: PropTypes.shape({
      date: PropTypes.string.isRequired,
      time: PropTypes.shape({
        hour: PropTypes.string.isRequired,
        minute: PropTypes.string.isRequired,
        meridiem: PropTypes.string.isRequired,
      }).isRequired,
    }),
  },

  getInitialState() {
    return { isPickerVisible: false };
  },

  handleClickOutside() {
    this.closePicker();
  },

  closePicker() {
    this.setState({ isPickerVisible: false });
  },

  togglePicker() {
    this.setState({ isPickerVisible: !this.state.isPickerVisible });
  },

  onCancel(e) {
    if (this.state.isPickerVisible) {
      this.closePicker();
      this.stopEvent(e);
    }
  },

  onChange(value) {
    this.props.onChange(value);
    this.closePicker();
  },

  render() {
    let classes = classnames('dateTimePicker', this.props.className);

    return (
      <div className={classes}>
        {this.renderMenuOpener()}
        <PopoverCard
          bounds={{ top: 10 }}
          className="dateTimePicker-popoverCard"
          isVisible={this.state.isPickerVisible}
          onClickOutside={this.handleClickOutside}
          position="top"
          targetElement={this.selector}
          zIndex={22}
        >
          <DatetimePicker
            onChange={this.onChange}
            onClose={this.closePicker}
            selector={this.selector}
            value={this.props.value}
          />
        </PopoverCard>
      </div>
    );
  },

  renderMenuOpener() {
    return this.props.value ? this.renderSelectedTime() : this.renderDefaultText();
  },

  renderSelectedTime() {
    const dueTime = getTimestampFromDatetime(this.props.value);
    const classes = classnames('dateTimePicker-selection', {
      'dateTimePicker-selection-open': this.state.isPickerVisible,
    });

    return (
      <div
        className={classes}
        onClick={this.togglePicker}
        onMouseDown={this.preventDefault}
        ref={node => (this.selector = node)}
      >
        <div className="dateTimePicker-selection-date">{moment(this.props.value.date).format('ll')}</div>
        <div className="dateTimePicker-selection-time">{moment(dueTime).format('h:mm a')}</div>
      </div>
    );
  },

  renderDefaultText() {
    const classes = classnames('dateTimePicker-defaultText', {
      'dateTimePicker-defaultText-open': this.state.isPickerVisible,
    });
    return (
      <span className={classes} onClick={this.togglePicker} ref={node => (this.selector = node)}>
        <i className="fa fa-clock-o dateTimePicker-defaultText-icon" />
        {this.props.placeholderText}
      </span>
    );
  },

  preventDefault(evt) {
    evt.preventDefault();
  },

  statics: {
    NOW_DURATION: 100000,
  },
});

const DatetimePicker = createReactClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    selector: PropTypes.any,
    value: PropTypes.shape({
      date: PropTypes.string.isRequired,
      time: PropTypes.shape({
        hour: PropTypes.string.isRequired,
        minute: PropTypes.string.isRequired,
        meridiem: PropTypes.string.isRequired,
      }).isRequired,
    }),
  },

  getInitialState() {
    return {
      ...this.props.value,
    };
  },

  componentDidMount() {
    this.positionDatePicker();
  },

  componentDidUpdate() {
    this.positionDatePicker();
  },

  handleChangeDate(date) {
    this.setState({ date });
  },

  handleChangeTime(time) {
    this.setState({ time });
  },

  handleClickApply() {
    this.props.onChange(this.state);
  },

  render() {
    return (
      <div className="dateTimePicker-picker" ref={node => (this.picker = node)}>
        <DatePicker date={this.state.date} onChange={this.handleChangeDate} />
        <div className="dateTimePicker-time">
          <TimeInput className="dateTimePicker-timeInput" onChange={this.handleChangeTime} time={this.state.time} />
          <Button
            buttonType={ButtonTypes.PRIMARY}
            className="dateTimePicker-time-apply"
            onClick={this.handleClickApply}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  },

  positionDatePicker() {
    if (!this.props.selector || !this.picker) {
      return;
    }
  },
});

const DatePicker = createReactClass({
  propTypes: {
    date: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  },

  UNSAFE_componentWillMount() {
    if (!this.props.date) {
      this.onChange(moment()); // Sets the working date to today if no date passed in.
    }
  },

  render() {
    return (
      <Calendar
        date={moment(this.props.date)}
        onChange={this.onChange}
        theme={{
          Calendar: {
            color: null,
            width: 250,
          },
          MonthAndYear: {
            color: null,
            fontSize: null,
          },
          MonthButton: {
            background: null,
          },
          Weekday: {
            color: null,
            fontWeight: null,
          },
          DayPassive: {
            color: null,
          },
          DaySelected: {
            background: null,
            color: null,
          },
        }}
      />
    );
  },

  onChange(value) {
    this.props.onChange(value.toISOString());
  },
});

export { DatePicker, DatetimePicker };
export default DatetimePickerMenu;
