// We need to keep track of whether we are inside of a conditional comment or not.
// If we are, we don't want to process those nodes at all.
export default function createWordCommentRules(context) {
  return [
    {
      deserialize(el, next) {
        if (isComment(el)) {
          if (isConditionalStart(el)) {
            context.ignore = true;
          } else if (isConditionalEnd(el)) {
            context.ignore = false;
          }
          return null;
        }
        // If we're inside a conditional comment, don't process. (This comes after checking if
        // it _is_ a conditional comment so we can make sure its not an end conditional).
        if (context.ignore) {
          return null;
        }
        return next();
      },
    },
  ];
}

export function isComment(el) {
  return el.nodeType === 8;
}
export function isConditionalStart(el) {
  return el.textContent.startsWith('[if');
}
export function isConditionalEnd(el) {
  return el.textContent === '[endif]';
}
