import { useContext, useEffect } from 'react';
import { usePlateEditorRef } from '@udecode/plate';

import AITextCompletionContext from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';

// We're doing a few things to prevent agents from being able to modify the composer while a text edit is in progress.
// One of these is setting the editor to read only. We have to wait until the editor is _not_ read only in order to
// complete / cancel the text edit and restore the selection and focus to the editor.

// That's why we perform the removing / completing the text edit in a useEffect hook: this will run after the
// editor has been set to writable again.
export default function useAITextCompletion() {
  const editor = usePlateEditorRef();
  const { completion, isLoading, textToInsert, setTextToInsert } = useContext(AITextCompletionContext);

  useEffect(() => {
    if (completion || isLoading) return;

    if (textToInsert) {
      editor.insertAITextCompletion(textToInsert);
    } else {
      editor.removeAITextCompletion();
    }

    setTextToInsert(null);
  }, [completion, isLoading, editor, setTextToInsert, textToInsert]);
}

export function UseAITextCompletion() {
  useAITextCompletion();
  return null;
}
