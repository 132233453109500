import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import AddParticipantToPhoneCall from 'actions/phone_call/add_participant_to_phone_call';
import CallParticipantContainer from 'components/phone_controls/participants/call_participant';
import connect from 'components/lib/connect';
import CurrentAgentParticipant from 'components/phone_controls/participants/current_agent_participant';
import CustomerParticipant from 'components/phone_controls/participants/customer_participant';
import EndConference from 'actions/phone_call/end_conference';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PhoneControlsActionButton from '../lib/phone_controls_action_container_button';
import PhoneControlsActionButtonContainer from '../lib/phone_controls_action_container_button_container';
import PhoneControlsActionButtonDivider from '../lib/phone_controls_action_container_button_divider';
import PhoneControlsActionContainer from '../lib/phone_controls_action_container';
import PhoneCall from 'models/phone_call';
import { formatPhoneNumber, hasSipScheme, isValidPhoneNumber, normalizePhoneNumber } from 'models/phone_number';

export class Conference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAddParticipant: false,
      endConferenceClicked: false,
      externalNumber: null,
      selectedValue: false,
    };
    _.bindAll(this, [
      'addParticipantToConference',
      'clearSelectedOption',
      'displayAddParticipantOption',
      'endConference',
      'handleClickOutside',
      'handleInputChange',
      'setSelectedValue',
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.endConferenceClicked && !PhoneCall.isLiveConference(nextProps.participants)) {
      this.props.onClose();
    }
  }

  render() {
    if (PhoneCall.isLiveConference(this.props.participants) && !this.state.displayAddParticipant) {
      return (
        <OutsideClickHandler onClickOutside={this.handleClickOutside}>
          <PhoneControlsActionContainer className={this.props.className}>
            <div className={classnames('conferenceContainer', this.props.className)}>
              {this.renderConferenceParticipants()}
              {this.renderConferenceActionButtons()}
            </div>
          </PhoneControlsActionContainer>
        </OutsideClickHandler>
      );
    }

    let options = [];
    if (this.state.externalNumber) {
      const label = this.state.selectedValue ? `External: ${formatPhoneNumber(this.state.externalNumber)}` : 'External';
      options = [{ value: this.state.externalNumber, label, disabled: !this.isValidExternalAddress() }];
    }

    return (
      <OutsideClickHandler onClickOutside={this.handleClickOutside}>
        <PhoneControlsActionContainer className={this.props.className}>
          <div className={classnames('conferenceContainer', this.props.className)}>
            <div className="conferenceContainer-selectWrapper select-wrapper">
              <ReactSelect
                autoBlur
                autofocus
                backspaceRemoves
                className="conferenceContainer-select"
                clearable={false}
                onBlurResetsInput={false}
                onChange={this.setSelectedValue}
                onInputChange={this.handleInputChange}
                optionRenderer={this.renderOption}
                options={options}
                placeholder={'Enter phone number or SIP address'}
                value={this.state.selectedValue ? this.state.externalNumber : null}
              />
            </div>
            {this.state.selectedValue ? this.renderAddParticipantOptions() : null}
          </div>
        </PhoneControlsActionContainer>
      </OutsideClickHandler>
    );
  }

  renderConferenceParticipants() {
    return (
      <div className="conferenceContainer-participants">
        <CurrentAgentParticipant />
        <CustomerParticipant showMenuOnHover />
        {this.renderExternalParticipants()}
      </div>
    );
  }

  renderExternalParticipants() {
    let externalActiveParticipants = _.filter(
      this.props.participants,
      participant =>
        participant.type === PhoneCall.ParticipantType.EXTERNAL && PhoneCall.isActiveParticipant(participant)
    );

    if (externalActiveParticipants.length === 1) {
      let participant = externalActiveParticipants[0];
      return (
        <CallParticipantContainer
          displayName={this.getFormattedDisplayName(participant.participantId)}
          key={participant.participantId}
          participantId={participant.participantId}
          participantStatus={participant.status}
          showMenuOnHover
        />
      );
    }
    return this.renderSortedExternalParticipants(externalActiveParticipants);
  }

  /*
   For each active external participant, get the latest DIALING event from the `participantEvents`.
   Sort the participant events in ascending order of `eventTime`
   */
  renderSortedExternalParticipants(externalActiveParticipants) {
    let activeParticipantEvents = [];
    externalActiveParticipants.forEach(participant => {
      let latestParticipantEvent = _.findLast(
        this.props.participantEvents,
        event =>
          event.participantId === participant.participantId &&
          event.participantStatus === PhoneCall.ParticipantStatus.DIALING
      );
      if (latestParticipantEvent) {
        activeParticipantEvents.push(latestParticipantEvent);
      }
    });

    let sortedParticipantEvents = _.sortBy(activeParticipantEvents, participantEvent =>
      new Date(participantEvent.eventTime).getTime()
    );

    let activeConferenceParticipants = [];
    sortedParticipantEvents.forEach(participantEvent => {
      let participantId = participantEvent.participantId;
      activeConferenceParticipants.push(
        <CallParticipantContainer
          displayName={formatPhoneNumber(participantId)}
          key={participantId}
          participantId={participantId}
          participantStatus={_.find(externalActiveParticipants, { participantId }).status}
          showMenuOnHover
        />
      );
    });

    return activeConferenceParticipants;
  }

  setSelectedValue(selection) {
    if (selection) {
      this.setState({ externalNumber: selection.value, selectedValue: true });
    } else {
      this.setState({ selectedValue: true });
    }
  }

  handleInputChange(val) {
    this.setState({ externalNumber: val, selectedValue: false });
  }

  renderOption(option) {
    return (
      <div>
        <div className="Select-option-label">{option.label}</div>
        <div className="Select-option-value">{option.value}</div>
      </div>
    );
  }

  getFormattedDisplayName(phoneNumber) {
    return (isValidPhoneNumber(phoneNumber) && formatPhoneNumber(phoneNumber)) || phoneNumber;
  }

  renderAddParticipantOptions() {
    let normalizedExternalNumber = hasSipScheme(this.state.externalNumber)
      ? this.state.externalNumber
      : normalizePhoneNumber(this.state.externalNumber);
    let participant = _.find(this.props.participants, { participantId: normalizedExternalNumber });
    let addParticipantDisabled =
      participant &&
      (participant.type === PhoneCall.ParticipantType.CUSTOMER || PhoneCall.isActiveParticipant(participant));
    let addParticipantClasses = classnames('conferenceContainer-addParticipant', {
      'phoneControlsActionContainer-button-disabled': addParticipantDisabled,
    });
    return (
      <PhoneControlsActionButtonContainer>
        <PhoneControlsActionButton
          className="conferenceContainer-cancelAddParticipant"
          displayText="Cancel"
          onClickCallback={this.clearSelectedOption}
        />
        <PhoneControlsActionButtonDivider />
        <PhoneControlsActionButton
          className={addParticipantClasses}
          displayText="Conference"
          onClickCallback={addParticipantDisabled ? _.noop : this.addParticipantToConference}
        />
      </PhoneControlsActionButtonContainer>
    );
  }

  renderConferenceActionButtons() {
    return (
      <PhoneControlsActionButtonContainer>
        <PhoneControlsActionButton
          className="conferenceContainer-endConference phoneControlsActionContainer-button"
          displayText="End Conference"
          onClickCallback={this.endConference}
        />
        <PhoneControlsActionButtonDivider />
        <PhoneControlsActionButton
          className="conferenceContainer-addAnotherParticipant phoneControlsActionContainer-button"
          displayText="Add Participant"
          onClickCallback={this.displayAddParticipantOption}
        />
      </PhoneControlsActionButtonContainer>
    );
  }

  displayAddParticipantOption() {
    this.setState({ displayAddParticipant: true, endConferenceClicked: false });
  }

  clearSelectedOption() {
    this.setState({ selectedValue: false, externalNumber: null, displayAddParticipant: false });
  }

  addParticipantToConference() {
    let isSip = hasSipScheme(this.state.externalNumber);
    let participantId = isSip ? this.state.externalNumber : normalizePhoneNumber(this.state.externalNumber);

    this.props.onAddParticipant({
      participantId,
      participantType: PhoneCall.ParticipantType.EXTERNAL,
    });
    this.clearSelectedOption();
  }

  endConference() {
    this.setState({ endConferenceClicked: true });
    this.props.onEndConference();
  }

  handleClickOutside(evt) {
    this.props.onClose(evt);
  }

  isValidExternalAddress() {
    return hasSipScheme(this.state.externalNumber) || isValidPhoneNumber(this.state.externalNumber);
  }
}

Conference.propTypes = {
  className: PropTypes.string,
  currentAgentId: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired,
  participantEvents: PropTypes.array.isRequired,
  // callbacks
  onAddParticipant: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEndConference: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapExecuteToProps)(Conference);

function mapStateToProps({ getProvider }, { className }) {
  let activeCallProvider = getProvider('activeCall');
  let activeCall = activeCallProvider.get();
  let currentAgentId = getProvider('currentAgent').get().id;
  return {
    className,
    currentAgentId,
    participants: activeCall.conversationItem.content.participants,
    participantEvents: activeCall.conversationItem.content.participantEvents,
  };
}

function mapExecuteToProps(executeAction, { onClose }) {
  return {
    onAddParticipant: ({ participantId, participantType }) =>
      executeAction(AddParticipantToPhoneCall, { participantId, participantType }),
    onClose,
    onEndConference: () => executeAction(EndConference),
  };
}
