import './lib/uuid';
import { Factory } from 'rosie';

Factory.define('CustomerProfileSearchHit')
  .extend('Uuid')
  .attr('type', 'customer');

Factory.define('CustomerProfileSearchHitWithDefaults')
  .extend('CustomerProfileSearchHit')
  .attr('name', 'Martha Williams')
  .attr('address', '611 Mission St')
  .attr('email', 'martha.williams@gmail.com')
  .attr('conversation', () => Factory.attributes('ConversationWithDefaults'))
  .attr('conversationItem', () => Factory.attributes('PhoneCallItemWithDefaults'))
  .attr('phone', '415-216-9303')
  .attr('taskCount', 2);
