import React from 'react';
import Icon from 'components/lib/icons/icon_container';

export default props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      clipRule="evenodd"
      d="M30 15c0 7.18-6.268 13-14 13a14.94 14.94 0 0 1-4.816-.79L7 30v-5.042C3.943 22.574 2 18.998 2 15 2 7.82 8.268 2 16 2s14 5.82 14 13zM7.392 18.552l6.538-7.472a.1.1 0 0 1 .146-.004l3.87 3.87a.1.1 0 0 0 .12.016l6.495-3.711c.095-.055.196.062.129.148l-6.612 8.5a.1.1 0 0 1-.157.002l-3.868-4.834a.1.1 0 0 0-.127-.025l-6.41 3.663c-.097.056-.198-.068-.124-.153z"
      fillRule="evenodd"
    />
  </Icon>
);
