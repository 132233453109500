import OptionalUpgradeNotification from 'models/notification/optional_upgrade_notification';

export default function(context) {
  let optionalUpgradeNotifications = context.stores.notifications.findAll().filter(isOptionalUpgradeNotification);
  optionalUpgradeNotifications.forEach(n => removeOptionalUpgradeNotification(context, n.id));
}

function removeOptionalUpgradeNotification(context, id) {
  context.stores.notifications.remove(id);
  context.scheduler.cancel(id);
}

function isOptionalUpgradeNotification(notification) {
  return notification instanceof OptionalUpgradeNotification;
}
