import React from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import CustomerView from 'models/location/customer_view';
import DismissNotification from 'actions/notification/dismiss_notification';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import get from 'lodash/get';
import { getMessagePreview } from 'models/conversation_item';
import Notification from './notification';

function PaymentStatusNotification({
  customerName,
  isNotificationForCurrentConversation,
  messageText,
  notificationId,
  onView,
}) {
  if (!notificationId || isNotificationForCurrentConversation) {
    return null;
  }
  return (
    <Notification contentClasses="incomingPaymentStatusNotification" notificationId={notificationId}>
      <div className="conversationNotification-topic">
        <i className="fa fa-credit-card" />
        <div className="conversationNotification-notificationType">Card Payment</div>
        <div className="conversationNotification-divider" />
        <div className="conversationNotification-topic-container">
          <div className="conversationNotification-topic-heading">{customerName}</div>
          <div className="conversationNotification-divider" />
          <div className="conversationNotification-topic-body">{messageText}</div>
        </div>
      </div>
      <div>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          contrast
          data-aid="notification-button-primary"
          onClick={onClickPrimary}
        >
          View
        </Button>
      </div>
    </Notification>
  );

  function onClickPrimary(ev) {
    ev.stopPropagation();
    onView();
  }
}

PaymentStatusNotification.propTypes = {
  customerName: PropTypes.string,
  isNotificationForCurrentConversation: PropTypes.bool,
  messageText: PropTypes.string,
  onView: PropTypes.func.isRequired,
  notificationId: PropTypes.string,
};

const PaymentStatusNotificationContainer = connect(mapStateToProps, mapExecuteToProps)(PaymentStatusNotification);

function mapStateToProps(context, props) {
  let currentLocation = context.getProvider('currentLocation').get();
  let notification = props.notification;
  return {
    customerName: get(notification, 'profile.name') || 'Customer',
    isNotificationForCurrentConversation: isNotificationForCurrentConversation(),
    messageText: getMessagePreview(notification.conversationItem.content) || 'New card request update',
    notificationId: get(notification, 'id'),
  };

  function isNotificationForCurrentConversation() {
    return (
      currentLocation instanceof CustomerView &&
      currentLocation.currentConversationId === notification.conversationItem.conversationId
    );
  }
}

function mapExecuteToProps(executeAction, props) {
  return {
    onView: () => {
      executeAction(DismissNotification, props.notification.id);
      executeAction(NavigateToConversation, {
        customerId: props.notification.conversationItem.customerId,
        conversationId: props.notification.conversationItem.conversationId,
      });
    },
  };
}

export { PaymentStatusNotification };
export default PaymentStatusNotificationContainer;
