import PropTypes from 'prop-types';
import React from 'react';

export default class WindowSizeWatcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowHeight: window.innerHeight, windowWidth: window.innerWidth };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
  }

  render() {
    return this.props.children(this.state);
  }
}

WindowSizeWatcher.propTypes = {
  children: PropTypes.func.isRequired,
};
