import Inbox from 'models/location/inbox';
import InboxUrlConverter from './url_converters/inbox_url_converter';
import LeaveInbox from 'actions/inbox/leave_inbox';
import { registerUrlConverter } from './location_url';
import OpenHome from 'actions/inbox/open_home';
import OpenTeamInbox from 'actions/inbox/open_team_inbox';

registerUrlConverter(Inbox, InboxUrlConverter);

export default function(router, { runnerContext }) {
  return {
    // don't use director recursive route parsing since forward route
    // recursion makes it difficult to refresh the inbox only after the full
    // route has been parsed

    // personal inbox routes
    '/?$': {
      on() {
        runnerContext.executeAction(OpenHome, getInboxParams());
      },

      after: leaveInbox,
    },

    // team inbox routes
    '/team/:routingGroupId': {
      on(routingGroupId) {
        runnerContext.executeAction(OpenTeamInbox, { ...getInboxParams(), routingGroupId });
      },

      after: leaveInbox,
    },
  };

  function getInboxParams() {
    return InboxUrlConverter.fromUrlParameters(router.parseQueryParameters().toJS());
  }

  function leaveInbox() {
    runnerContext.executeAction(LeaveInbox);
  }
}
