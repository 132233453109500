import _ from 'lodash';
import React, { useMemo, useReducer } from 'react';

const HotkeysContext = React.createContext({
  hotkeys: [],
  dispatchHotkey: () => {},
});
export default HotkeysContext;

const HotkeysShownContext = React.createContext({
  areHotkeysShown: false,
  dispatchHotkeysShown: () => {},
});
export { HotkeysShownContext };

function hotkeysShownReducer(isShown, type) {
  switch (type) {
    case 'on':
      return true;
    case 'off':
      return false;
    case 'toggle':
      return !isShown;
    default:
      throw new Error();
  }
}

function hotkeysReducer(hotkeys, action) {
  switch (action.type) {
    case 'add': {
      return _.concat(hotkeys, action.hotkey);
    }
    case 'remove': {
      return _.filter(hotkeys, h => h.key !== action.key);
    }
    default:
      throw new Error();
  }
}

export function HotkeysProvider({ children }) {
  const [hotkeys, dispatchHotkey] = useReducer(hotkeysReducer, []);
  const hotkeysValue = useMemo(() => ({ hotkeys, dispatchHotkey }), [hotkeys, dispatchHotkey]);
  const [areHotkeysShown, dispatchHotkeysShown] = useReducer(hotkeysShownReducer, false);
  const hotkeysShownValue = useMemo(() => ({ areHotkeysShown, dispatchHotkeysShown }), [
    areHotkeysShown,
    dispatchHotkeysShown,
  ]);
  return (
    <HotkeysContext.Provider value={hotkeysValue}>
      <HotkeysShownContext.Provider value={hotkeysShownValue}>{children}</HotkeysShownContext.Provider>
    </HotkeysContext.Provider>
  );
}
