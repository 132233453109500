import { after, HEADER_CORRELATION_ID } from 'scripts/infrastructure/backends/http_client';
import HttpResponseHandler from 'scripts/adapters/gateways/lib/http_response_handler';
import Observable from 'scripts/lib/observable_mixin';
import mixin from '../../lib/mixin';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import MqttTopicPattern from 'scripts/adapters/gateways/lib/mqtt_topic_pattern';

const apiVer = 1;

export default class TeamGateway {
  constructor(backend) {
    this.backend = backend;
    this.mqttClient = backend;
  }

  init({ orgId } = {}) {
    this.orgId = orgId;
  }

  url(teamId) {
    return `/api/v${apiVer}/orgs/${this.orgId}/teams/${teamId || ''}`.replace(/\/$/, '');
  }

  get broadcastTopic() {
    return `v${apiVer}/orgs/${this.orgId}/teams/+`;
  }

  get broadcastDeleteTopicPattern() {
    return new MqttTopicPattern(`v${apiVer}/orgs/${this.orgId}/teams/+/event/delete`);
  }

  get _http() {
    return this.backend.axios();
  }

  reconnectHandler() {
    if (this._isSubscribed) {
      this.request();
    }
  }

  request() {
    this._isSubscribed = true;
    this.mqttClient.subscribe(this.broadcastTopic, this.handleBroadcast.bind(this), this.findAll.bind(this));
    this.mqttClient.subscribe(this.broadcastDeleteTopicPattern.interpolate(), this.handleDeleteBroadcast.bind(this));
  }

  handleBroadcast(envelope) {
    this.notifyObservers('handleTeam', envelope.payload);
  }

  handleDeleteBroadcast(envelope, topic) {
    this.notifyObservers('handleDelete', this.broadcastDeleteTopicPattern.extractParams(topic)[0]);
  }

  findAll() {
    const correlationId = IdGenerator.newId();
    const requestPromise = after(
      this._http.get(this.url(), { headers: { [HEADER_CORRELATION_ID]: correlationId } }),
      this.handleResponse.bind(this, { requestName: 'findAll', correlationId })
    );

    return { correlationId, requestPromise };
  }

  addOrReplace(team) {
    const correlationId = IdGenerator.newId();
    const requestPromise = after(
      this._http.put(this.url(team.id), team, { headers: { [HEADER_CORRELATION_ID]: correlationId } }),
      this.handleResponse.bind(this, { requestName: 'addOrReplace', correlationId, params: { team } })
    );

    return { correlationId, requestPromise };
  }

  remove(teamId) {
    const correlationId = IdGenerator.newId();
    const requestPromise = after(
      this._http.delete(this.url(teamId), { headers: { [HEADER_CORRELATION_ID]: correlationId } }),
      this.handleResponse.bind(this, { requestName: 'delete', correlationId, params: { teamId } })
    );

    return { correlationId, requestPromise };
  }
}

mixin(TeamGateway.prototype, Observable);
mixin(TeamGateway.prototype, HttpResponseHandler.prototype);
