import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { evaluateTemplate } from 'components/customer/flexible_profile_card/lib/evaluate_template';
import FlexibleBlock from 'components/customer/flexible_profile_card/components/flexible_block';
import FlexibleCardSection from 'components/customer/flexible_profile_card/components/flexible_card_section';
import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';
import { getRenderableSections } from 'components/customer/flexible_profile_card/lib/get_renderable_sections';
import { H3 } from 'components/common/headers';
import { isRenderableItem } from 'components/customer/flexible_profile_card/lib/is_renderable_item';

export default function FlexibleItemDetails({ className, itemDetailsConfig, itemData, renderFlags }) {
  if (!isRenderableItem(itemDetailsConfig, itemData)) return null;

  const { hasTopTriangle, hasShadow } = renderFlags || {};
  const itemClassnames = classnames('flexible-item-details', className);
  const topTriangle = hasTopTriangle ? (
    <StyledTopTriangle>
      <StyledTopTriangleBorder />
    </StyledTopTriangle>
  ) : null;

  return (
    <StyledItemContainer $hasShadow={hasShadow} className={itemClassnames}>
      {topTriangle}
      {renderItemHeader()}
      {renderItemBody()}
    </StyledItemContainer>
  );

  function renderItemHeader() {
    const headerConfig = _.get(itemDetailsConfig, 'header');
    if (_.isEmpty(headerConfig)) return null;

    const titleTemplate = _.trim(_.get(headerConfig, 'title'));
    const title = evaluateTemplate(titleTemplate, itemData);
    const renderedTitle = title ? <StyledTitle className="flexible-item-title">{title}</StyledTitle> : null;

    const headerAttributes = _.get(itemDetailsConfig, 'header.attributes') || [];
    const renderableAttrs = getAttributesToDisplay(headerAttributes, itemData);
    if (_.isEmpty(renderableAttrs) && !title) return null;

    const renderedAttributes = !_.isEmpty(renderableAttrs) ? (
      <FlexibleBlock config={{ attributes: headerAttributes }} customAttributes={itemData} />
    ) : null;
    return (
      <StyledItemHeader className="flexible-item-header">
        {renderedTitle}
        {renderedAttributes}
      </StyledItemHeader>
    );
  }

  function renderItemBody() {
    const sectionConfigs = _.get(itemDetailsConfig, 'sections') || [];
    const renderableSections = getRenderableSections(sectionConfigs, itemData);
    if (_.isEmpty(renderableSections)) return null;

    const renderedSections = _.map(renderableSections, (sectionConfig, key) => (
      <FlexibleCardSection key={key} sectionConfig={sectionConfig} sectionData={itemData} />
    ));

    return <div className="flexible-item-body">{renderedSections}</div>;
  }
}

const StyledItemContainer = styled.div`
  border: 1px solid ${p => p.theme.colors.gray300};
  border-radius: ${p => p.theme.borderRadius.large};
  box-shadow: ${p => (p.$hasShadow ? p.theme.boxShadow.medium : 'none')};
  font-size: ${p => p.theme.fontSize.base};
  margin: ${p => p.theme.spacing.medium} 0;
  padding: ${p => p.theme.spacing.insetMedium};
  position: relative;
  text-align: left;
`;

const StyledTopTriangle = styled.div`
  border-bottom: 9px solid ${p => p.theme.colors.gray300};
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  height: 0;
  position: absolute;
  right: 10px;
  top: -10px;
  width: 0;
`;

const StyledTopTriangleBorder = styled.div`
  border-bottom: 9px solid white;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  height: 0;
  left: -9px;
  position: absolute;
  top: 1px;
  width: 0;
`;

export const StyledItemHeader = styled.div`
  margin-bottom: ${p => p.theme.spacing.small};

  & + .flexible-item-body {
    margin-top: 8px;
  }
  & .visibleCustomAttribute {
    margin-bottom: 0;
    line-height: 1.5;
  }
`;

export const StyledTitle = styled(H3)`
  font-size: ${p => p.theme.fontSize.large};
  line-height: 21px;
  margin-bottom: ${p => p.theme.spacing.medium};
  word-break: break-all;
`;

FlexibleItemDetails.propTypes = {
  className: PropTypes.string,
  renderFlags: PropTypes.shape({ hasTopTriangle: PropTypes.bool, hasShadow: PropTypes.bool }),
  itemData: PropTypes.object.isRequired,
  itemDetailsConfig: PropTypes.object.isRequired,
};
