import classnames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import moment from 'moment';

import connect from 'components/lib/connect';
import NavigateTo from 'actions/current_location/navigate_to';

const tz = moment.tz.guess();
const timezoneOptions = [
  { label: 'Organization Time', value: '' },
  { label: 'Local Time', value: tz },
];

export function TimezoneSelector({ onChange, value, options }) {
  return (
    <ReactSelect
      className={classnames('reportFilters-timezoneDropdown')}
      clearable={false}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
}

TimezoneSelector.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string })),
  value: PropTypes.string,
};

const TimezoneSelectorContainer = connect(mapStateToProps, mapExecuteToProps, mergeProps)(TimezoneSelector);

function mapStateToProps({ getProvider }) {
  const currentReport = getProvider('currentLocation').get();
  return {
    currentReport,
    options: timezoneOptions,
    value: currentReport.getTimezone(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onTimezoneChange: (currentReport, timezone) => {
      if (timezone && currentReport.getTimezone() !== timezone.value) {
        executeAction(NavigateTo, currentReport.deriveNew({ timezone: timezone.value }));
      }
    },
  };
}

function mergeProps(stateProps, execProps) {
  return {
    ...stateProps,
    ...execProps,
    onChange: timezone => execProps.onTimezoneChange(stateProps.currentReport, timezone),
  };
}

export default TimezoneSelectorContainer;
