import React, { useCallback } from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import FeatureTipTypes from 'models/feature_tip_types';
import MarkFeatureTipAsSeen from 'actions/agent_preferences/mark_feature_tip_as_seen';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function SemanticSearchTips() {
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => {
    executeAction(MarkFeatureTipAsSeen, { feature: FeatureTipTypes.SEMANTIC_SEARCH });
  }, []);
  return (
    <SearchTipsContainer>
      <SearchTipsHeader>We’ve improved Search!</SearchTipsHeader>
      <SearchTipsContent>
        Describe what you’d like to know, and we’ll help you find the most relevant Answer. Gladly AI may even suggest a
        reply for you.
      </SearchTipsContent>
      <Button buttonType={ButtonTypes.PRIMARY} onClick={onClick}>
        Got it!
      </Button>
    </SearchTipsContainer>
  );
}

const SearchTipsContainer = styled.div`
  padding: ${p => p.theme.spacing.insetMedium};
  box-shadow: ${p => p.theme.boxShadow.small};
  background-color: ${p => p.theme.colors.white};
`;

export const SearchTipsHeader = styled.div`
  font-weight: ${p => p.theme.fontWeight.heavy};
  margin-bottom: 12px;
`;

export const SearchTipsContent = styled.div`
  margin-bottom: ${p => p.theme.spacing.stackMedium};
`;
