import _ from 'lodash';

import TypeReflect from 'models/lib/type_reflect';
import ConversationMessageAttachment from 'scripts/domain/models/conversation_message_attachment.js';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import redactAttachment from './attachment_redact';

export const ChatMessageStatusCode = createEnum('FAILED', 'NEW', 'SENT');
export const ChatMessageType = createEnum(
  'TEXT',
  'QUICK_REPLY_REQUEST',
  'QUICK_REPLY_RESPONSE',
  'HTML',
  'FLEXIBLE_CARD'
);
export const ChatPlatform = createEnum('ANDROID', 'IOS', 'WEB');
export const OutgoingChatOperation = createEnum('END_CHAT', 'SEND_CHAT_MESSAGE', 'REQUEST_CREDIT_CARD');

export const ChatMessageStatus = createModel({
  modelName: 'ChatMessageStatus',

  properties: {
    errorCode: String,
    value: prop().oneOf(..._.keys(ChatMessageStatusCode)),
  },
});

export const QuickReplyOption = createModel({
  modelName: 'QuickReplyOption',

  properties: {
    id: prop(String).isRequired,
    label: prop(String).isRequired,
  },
});

export const QuickReplyRequest = createModel({
  modelName: 'QuickReplyRequest',

  properties: {
    options: prop([QuickReplyOption]),
  },
});

export const QuickReplyResponse = createModel({
  modelName: 'QuickReplyResponse',

  properties: {
    quickReplyRequest: prop(QuickReplyRequest).isRequired,
    selectedOptionId: prop(String).isRequired,
    requestMessageId: prop(String),
  },
});

export const ChatTemplatedCard = createModel({
  modelName: 'ChatTemplatedCard',

  properties: {
    template: prop(String),
    data: prop().oneOf(String, Object, Array, Number, Boolean),
  },
});

const MessageContentTypes = [ChatTemplatedCard, QuickReplyRequest, QuickReplyResponse];

const MessageTypeClasses = [
  [ChatMessageType.FLEXIBLE_CARD, ChatTemplatedCard],
  [ChatMessageType.QUICK_REPLY_REQUEST, QuickReplyRequest],
  [ChatMessageType.QUICK_REPLY_RESPONSE, QuickReplyResponse],
];

const messageTypeReflect = new TypeReflect(MessageTypeClasses);

const ChatMessage = createModel({
  modelName: 'ChatMessage',

  properties: {
    attachments: [ConversationMessageAttachment],
    customerAddress: prop(String),
    deviceManufacturer: prop(String),
    deviceModel: prop(String),
    messageId: prop(String),
    messageData: prop().oneOf(...MessageContentTypes),
    messageType: prop()
      .oneOf(..._.keys(ChatMessageType))
      .default(ChatMessageType.TEXT),
    os: prop(String),
    osVersion: prop(String),
    participantId: prop(String),
    platform: prop()
      .oneOf(..._.keys(ChatPlatform))
      .default(ChatPlatform.WEB),
    sdkVersion: prop(String),
    sentAt: prop(String),
    sessionId: prop(String),
    sessionItemId: prop(String),
    status: prop(ChatMessageStatus),
    text: prop(String),
    isRedacted: prop(Boolean),
    isAuthenticated: prop(Boolean),
  },

  getStatus() {
    return (this.status && this.status.value) || ChatMessageStatusCode.NEW;
  },

  hasErrorStatus() {
    return hasErrorStatus(this.getStatus());
  },

  getErrorCode() {
    return (this.status && this.status.errorCode) || '';
  },

  getErrorMessage() {
    let errorCode = this.getErrorCode();
    if (errorCode === ErrorCodes.CANNOT_INITIATE_SESSION) {
      return 'Could not send message because chat was already ended.';
    } else if (errorCode === ErrorCodes.NO_ACTIVE_CONVERSATION) {
      return 'Could not send message because conversation was closed.';
    }

    return '';
  },

  getMessageText() {
    return this.text;
  },

  isMobileSdk() {
    return this.platform !== ChatPlatform.WEB;
  },

  redactAttachment(id) {
    redactAttachment(this.attachments, id);
  },

  canRedact() {
    return this.messageType !== ChatMessageType.QUICK_REPLY_REQUEST;
  },

  statics: {
    MAX_MESSAGE_SIZE: 2048,
    create(attrs) {
      return new this(attrs);
    },
    overrideFromJs(fromJs) {
      return attrs => {
        const exist = MessageTypeClasses.find(([type]) => type === attrs.messageType);
        if (!exist) {
          return fromJs(attrs);
        }
        const messageConstructor = messageTypeReflect.typeToConstructor(attrs.messageType);
        const messageData = messageConstructor.fromJs(attrs.messageData);
        return fromJs({ ...attrs, messageData });
      };
    },
  },
});

export const ErrorCodes = {
  CANNOT_INITIATE_SESSION: 'CANNOT_INITIATE_SESSION',
  NO_ACTIVE_CONVERSATION: 'NO_ACTIVE_CONVERSATION',
};

export function hasErrorStatus(status) {
  return status === ChatMessageStatusCode.FAILED;
}

export default ChatMessage;
