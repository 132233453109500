import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnswerDisplay from 'components/customer/answer_panel/answer_display';
import AnswerPanelContext, { AnswerPanelContextProvider } from 'components/customer/answer_panel/answer_panel_context';
import InsertSelectedAnswer from 'actions/answers/insert_selected_answer';
import Preview from 'components/customer/answer_panel/preview';
import Search from 'components/customer/answer_panel/search';
import ToggleAnswerPanel from 'actions/answers/toggle_answer_panel';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function AnswerPanel({ isReadOnly }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const isSuggestedReplyEnabled = isFeatureEnabled('suggestedReplies');
  return (
    <AnswerPanelContextProvider isReadOnly={isReadOnly}>
      <StyledPanel>
        <Search />
        {isSuggestedReplyEnabled ? <AnswerDisplay /> : <Preview />}
      </StyledPanel>
    </AnswerPanelContextProvider>
  );
}

const Panel = ({ className, children }) => {
  const { canInsertAnswer } = useContext(AnswerPanelContext);
  const onKeyDown = useOnKeyDown(canInsertAnswer);
  return (
    <div className={className} data-aid="answerPanel" onKeyDown={onKeyDown} tabIndex="0">
      {children}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const StyledPanel = styled(Panel)`
  box-shadow: ${p => p.theme.boxShadow.small};
  display: flex;
  flex: 0 0 25%;
  flex-direction: column;
  height: 100%;
  max-width: 360px;
  min-width: 200px;
  outline: none;
  width: 25%;
  z-index: 3;
`;

function useOnKeyDown(canInsertAnswer) {
  const executeAction = useExecuteAction();
  return useCallback(
    evt => {
      if (evt.key === 'Enter' && canInsertAnswer) {
        executeAction(InsertSelectedAnswer);
      } else if (evt.key === 'Escape') {
        executeAction(ToggleAnswerPanel);
      }
    },
    [canInsertAnswer, executeAction]
  );
}

AnswerPanel.propTypes = {
  isReadOnly: PropTypes.bool,
};
