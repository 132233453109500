import { createContentModel, isEmpty } from './composition_content_shared';

const CustomChannelCompositionContent = createContentModel({
  modelName: 'CustomChannelCompositionContent',

  properties: {
    bodyHtml: String,
    channel: String,
    channelDisplayTitle: String,
    companyAddress: String,
    companyDisplayName: String,
    customerAddress: String,
    customerDisplayName: String,
    customChannelId: String,
    groupId: String,
    groupName: String,
  },

  isEmpty() {
    return isEmpty(this.bodyHtml);
  },
});

export default CustomChannelCompositionContent;
