import { Factory } from 'rosie';

Factory.define('LocationHistory');

Factory.define('LocationHistoryWithDefaults')
  .extend('LocationHistory')
  .attr('history', [
    Factory.attributes('InboxWithDefaults'),
    Factory.attributes('LiveboardsWithDefaults'),
    Factory.attributes('SearchWithDefaults'),
  ]);
