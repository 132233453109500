import createModel from 'models/lib/create_model';

export default createModel({
  modelName: 'OldestUnanswered',
  properties: {
    conversationId: String,
    conversationItemId: String,
    customerId: String,
  },
  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
