import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class ScheduledReportService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  fetchAll(attrs, callback, path) {
    let reports = this._getScheduledReports('sagan---');
    callback(null, { status: 200, statusText: statusText(200), response: reports || [] });
  }

  add(attrs, callback, path) {
    if (attrs.recipients.length === 0) {
      let errorResponse = {
        status: 400,
        statusText: statusText(400),
        data: {
          errors: [{ attr: 'recipients', code: 'blank', detail: 'no recipients included' }],
        },
      };
      callback({ response: errorResponse });
      return;
    }

    if (this.getDatabase('sagan---').scheduledReports) {
      this.getDatabase('sagan---').scheduledReports.push(attrs);
    } else {
      this.getDatabase('sagan---').scheduledReports = [attrs];
    }
    callback(null, { status: 201, statusText: statusText(201) });
  }

  delete(attrs, callback, path, { scheduledReportId }) {
    _.remove(this._getScheduledReports('sagan---'), { id: scheduledReportId });
    callback(null, { status: 204, statusText: statusText(204) });
  }

  _getScheduledReports(orgId) {
    return this.getDatabase(orgId).scheduledReports;
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/reporting/v1/scheduled-reports': {
          GET: this.fetchAll,
          POST: this.add,
        },
        '/api/reporting/v1/scheduled-reports:scheduledReportId': {
          DELETE: this.delete,
        },
      },
      this
    );
  }
}
