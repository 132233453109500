import find from 'lodash/find';
import isFunction from 'lodash/isFunction';

import ConversationConverter from 'scripts/application/dto_converters/conversation_converter';
import ConversationItem, { getMessagePreview, isCustomerItem } from 'models/conversation_item';
import contentFromJs from 'models/conversation_item/content_from_js';
import DeclinedReassignedSessionNotification from 'models/notification/declined_reassigned_session_notification';
import { isMessagePreviewEnabled } from 'actions/communication_session/lib/messaging_config';
import { NotificationSoundType } from 'actions/notification/lib/notification_constants';
import playNotificationSound from 'actions/notification/lib/play_notification_sound';
import RoutingItem from 'models/routing_item';
import RoutingItemType from 'models/routing_item_type';
import { sessionTypeForContent } from 'actions/communication_session/lib/interaction_type';

export default class ActiveSessionCustomerHistoryObserver {
  constructor(context) {
    this.context = context;
  }

  handleReceiveCurrentConversationItems({ customerId, currentItemsDto }) {
    if (!isMessagePreviewEnabled(this.context)) {
      return;
    }

    let activeSession = this.context.stores.activeSessions.findBy({ customer: { id: customerId } });
    if (!activeSession) {
      return;
    }

    let notification = this.context.stores.notifications.findBy({ id: activeSession.id });
    if (!isFunction(notification?.updateMessagePreview)) {
      return;
    }

    let messageItem = find(
      currentItemsDto.latestNonAutomaticInitiatorItemsOfEachType,
      ci => isCustomerItem(ci) && sessionTypeForContent(ci.content) === notification.sessionType
    );
    if (!messageItem) {
      return;
    }

    const messagePreview = getMessagePreview(contentFromJs(messageItem.content));
    notification.updateMessagePreview(messagePreview);
    this.context.stores.notifications.replace(notification);
  }

  handleUpdateConversation({ conversationDto }) {
    this.updateActiveSession(conversationDto);
  }

  handleUpdateConversationItem({ customerId, conversationItemDto }) {
    let conversationItem = ConversationItem.fromJs(conversationItemDto);
    if (
      !(
        conversationItem.content instanceof RoutingItem &&
        conversationItem.content.itemType === RoutingItemType.OFFER_CONFLICT
      )
    ) {
      return;
    }

    let currentAgent = this.context.stores.currentAgent.get();
    if (conversationItem.content.assignee.agentId !== currentAgent.id) {
      return;
    }

    let activeSession = this.context.stores.activeSessions.findBy({ customer: { id: customerId } });
    if (!activeSession) {
      return;
    }

    this.context.stores.notifications.addOrReplace(
      DeclinedReassignedSessionNotification.create({
        conversationId: activeSession.queueItem.conversationId,
        profile: activeSession.customer,
        transferee: conversationItem.content.assigneeWas,
        sessionType: activeSession.queueItem.getLatestSession()?.type,
      })
    );

    playNotificationSound(NotificationSoundType.DECLINED_SESSION_REASSIGNMENT);
  }

  handleReceiveConversations({ conversationsDto }) {
    conversationsDto.forEach(conversationDto => this.updateActiveSession(conversationDto));
  }

  updateActiveSession(conversationDto) {
    const conversation = ConversationConverter.fromDto(conversationDto);
    const activeSession = this.context.stores.activeSessions.findBy({ queueItem: { conversationId: conversation.id } });
    if (activeSession) {
      activeSession.update({ conversationUpdatedAt: conversation.updatedAt });
      this.context.stores.activeSessions.replace(activeSession);
    }
  }
}
