import classnames from 'classnames';
import React from 'react';
import { EditorState, Modifier } from 'draft-js';

import { findEntities } from './draft_entity';
import { MUTABLE } from './draft_shared';

export { startMention, getCurrentMention, removeMention, getMentionClassname };

/**
 * A mention is a section of text that, when the cursor is inside of it, should display the mention menu
 * searching for a snippet / variable containing that text.
 */
const DraftMention = props => {
  const classNames = classnames('draftEditor-mention', getMentionClassname(props.entityKey));
  return <span className={classNames}>{props.children}</span>;
};

export default DraftMention;

export const ENTITY_TYPE_MENTION = 'MENTION';
export const findMentionEntities = findEntities(ENTITY_TYPE_MENTION);

function startMention(editorState, character) {
  const originalEditorState = editorState;
  let originalContentState = originalEditorState.getCurrentContent();

  originalContentState = Modifier.replaceText(originalContentState, editorState.getSelection(), character);

  editorState = EditorState.push(editorState, originalContentState, 'insert-characters');

  const preMentionSelection = editorState.getSelection();
  const mentionSelection = preMentionSelection.merge({
    anchorOffset: preMentionSelection.anchorOffset - 1,
  });

  let contentStateWithEntity = originalContentState.createEntity(ENTITY_TYPE_MENTION, MUTABLE);
  contentStateWithEntity = Modifier.applyEntity(
    contentStateWithEntity,
    mentionSelection,
    contentStateWithEntity.getLastCreatedEntityKey()
  );

  editorState = EditorState.push(editorState, contentStateWithEntity, 'apply-entity');

  const resolvedEditorState = EditorState.push(editorState, contentStateWithEntity, 'insert-fragment');

  return EditorState.forceSelection(resolvedEditorState, preMentionSelection);
}

function isOffsetInsideMention(contentState, offset, block) {
  const entityKey = block.getEntityAt(offset - 1);
  return entityKey !== null && contentState.getEntity(entityKey).getType() === ENTITY_TYPE_MENTION;
}

function getCurrentMention(editorState) {
  return getMentionAtSelection(editorState, editorState.getSelection());
}

function getMentionAtSelection(editorState, selection) {
  let entityStart = null;
  let entityEnd = null;
  let mentionText = null;
  let mentionCharacter = null;
  let contentState = editorState.getCurrentContent();

  if (editorState) {
    const block = contentState.getBlockForKey(selection.getAnchorKey());

    const cursorStart = selection.getStartOffset();
    const cursorEnd = selection.getEndOffset();

    if (
      isOffsetInsideMention(contentState, cursorStart, block) ||
      isOffsetInsideMention(contentState, cursorEnd, block)
    ) {
      let extractEntityRange = (start, end) => {
        if (cursorStart > start && cursorEnd <= end) {
          entityStart = start;
          entityEnd = end;
          mentionText = block.getText().slice(start + 1, end);
          mentionCharacter = block.getText().slice(start, start + 1);
        }
      };
      findMentionEntities(block, extractEntityRange, contentState);
    }
  }

  return { start: entityStart, end: entityEnd, text: mentionText, character: mentionCharacter };
}

function removeMention(editorState, mention) {
  const { start, end, text } = mention || getCurrentMention(editorState);
  if (text === null) {
    return editorState;
  }

  const selection = editorState.getSelection();

  let mentionSelection = selection.merge({
    anchorOffset: start,
    focusOffset: end,
  });

  const newContentState = Modifier.applyEntity(editorState.getCurrentContent(), mentionSelection, null);

  const removedEntity = EditorState.push(editorState, newContentState, 'apply-entity');

  return EditorState.forceSelection(removedEntity, selection);
}

function getMentionClassname(key) {
  return `draftEditor-mention-${key}`;
}

export function ensureCurrentMentionStillActive(editorState, mentionCharacter) {
  const selection = editorState.getSelection();
  const nextOffset = selection.getAnchorOffset() + 1;
  const nextSelection = selection.merge({ anchorOffset: nextOffset, focusOffset: nextOffset });

  const currentMention = getMentionAtSelection(editorState, nextSelection);
  if (currentMention.text == null) {
    return editorState;
  }

  const mentionBlock = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getAnchorKey());
  const char = mentionBlock.getText()[currentMention.start];
  if (isMentionCharacter(char)) {
    return editorState;
  }

  return removeMention(editorState, currentMention);
}

export function isMentionCharacter(char, mentionCharacter) {
  if (Object.prototype.toString.call(mentionCharacter) === '[object Array]') {
    return mentionCharacter.indexOf(char) >= 0;
  }
  return char === mentionCharacter;
}
