import ConversationItem from 'models/conversation_item';
import Communicator from 'models/communicator';
import TaskEditedContent from 'models/task_edited_content';

export default function createTaskEditedItem({ agentId, customerId, taskItemId }) {
  return ConversationItem.create({
    content: TaskEditedContent.create({
      taskItemId,
    }),
    customerId,
    initiator: {
      id: agentId,
      type: Communicator.AGENT,
    },
  });
}
