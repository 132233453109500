import classnames from 'classnames';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import qconsole from 'scripts/lib/qconsole';

import AlertIcon from 'components/lib/icons/alert_icon';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import CoBrowseOutlineIcon from 'components/lib/icons/cobrowse_outline_icon';
import ConversationItemType from 'models/conversation_item_type';
import { CustomerActivityType } from 'models/customer_activity';
import IconClasses from 'components/lib/icon_classes';
import SurveyIcon from 'components/lib/icons/survey_icon';
import TicketIcon from 'components/lib/icons/ticket_icon';
import TireWarningIcon from 'components/lib/icons/tire_warning_icon';
import { WidgetActivityTypes } from 'models/widget_activity';

const ConversationIcon = createReactClass({
  propTypes: {
    className: PropTypes.string,
    convoBackground: PropTypes.bool,
    filled: PropTypes.bool,
    iconClassName: PropTypes.string,
    isLarge: PropTypes.bool,
    subType: PropTypes.string,
    type: PropTypes.string.isRequired,
  },

  render() {
    const className = classnames({
      'profileAvatar-iconWrapper': !this.props.isLarge,
      'profileAvatar-iconWrapper-large': this.props.isLarge,
      'profileAvatar-iconWrapper-convoBackground': this.props.convoBackground,
    });
    return <div className={className}>{this.renderIcon()}</div>;
  },

  renderIcon() {
    if (
      this.props.type === ConversationItemType.CHAT_MESSAGE ||
      this.props.type === ConversationItemType.CHAT_SELF_SERVICE_METADATA ||
      this.props.type === ConversationItemType.MESSAGE_AUTOMATION_MESSAGE ||
      this.props.type === ConversationItemType.MESSAGE_AUTOMATION_SUMMARY
    ) {
      return (
        <div className="conversationIcon-chat-wrapper">
          <ChatIconOutline className="conversationIcon-chat" />
        </div>
      );
    } else if (
      this.props.type === ConversationItemType.CUSTOMER_ACTIVITY &&
      this.props.subType === CustomerActivityType.ISSUE
    ) {
      return (
        <div className="conversationIcon-ticket-wrapper">
          <TicketIcon className="conversationIcon-ticket" />
        </div>
      );
    } else if (
      this.props.type === ConversationItemType.CUSTOMER_ACTIVITY &&
      this.props.subType === CustomerActivityType.SURVEY
    ) {
      return (
        <div className="conversationIcon-survey-wrapper">
          <SurveyIcon className="conversationIcon-survey" />
        </div>
      );
    } else if (
      this.props.type === ConversationItemType.CUSTOMER_ACTIVITY &&
      this.props.subType === CustomerActivityType.WARNING
    ) {
      return (
        <div className="conversationIcon-warning-wrapper">
          <TireWarningIcon className="conversationIcon-warning" />
        </div>
      );
    } else if (
      this.props.type === ConversationItemType.CUSTOMER_ACTIVITY &&
      this.props.subType === CustomerActivityType.ALERT
    ) {
      return (
        <div className="conversationIcon-alert-wrapper">
          <AlertIcon className="conversationIcon-alert" />
        </div>
      );
    } else if (
      this.props.type === ConversationItemType.WIDGET_ACTIVITY &&
      this.props.subType === WidgetActivityTypes.COBROWSE
    ) {
      return (
        <div className="conversationIcon-widgetActivity-wrapper">
          <CoBrowseOutlineIcon className="conversationIcon-widgetActivity" />
        </div>
      );
    } else if (this.props.type === ConversationItemType.AI_ACTIVITY) {
      return null;
    }

    const iconClass = this.iconClass();
    if (!iconClass) {
      return null;
    }

    const classList = classnames('conversationIcon', iconClass, this.props.className);
    return <i className={classList} />;
  },

  iconClass() {
    const type = this.props.type === ConversationItemType.CUSTOMER_ACTIVITY ? this.props.subType : this.props.type;
    if (!(type in IconClasses)) {
      qconsole.error(`no icon css class defined for [type: ${this.props.type} subtype: ${this.props.subType}]`);
      return '';
    }
    return classnames(`${IconClasses[type]}${this.props.filled ? '-filled' : ''}`, this.props.iconClassName);
  },
});

export default ConversationIcon;
