import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class SnippetRevisionService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  findAll(payload, callback, path, { orgId, snippetId }) {
    let snippetRevisionsDb = this.getDatabase(orgId).snippetRevisions;

    callback(null, {
      status: 200,
      statusText: statusText(200),
      response: _.get(snippetRevisionsDb, snippetId) || [],
    });
  }

  getRoutes() {
    return bindCallbacks({ '/api/v1/orgs/:orgId/snippets/:snippetId/revisions': { GET: this.findAll } }, this);
  }
}
