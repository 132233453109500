import _ from 'lodash';

import HttpUploader from './lib/http_uploader';
import Observable from 'scripts/lib/observable_mixin';

const UPLOAD_CSV_TIMEOUT = 180 * 1000; // 3 mins

export default class UsersUploadGateway {
  constructor(backend, requestorId) {
    this.httpUploader = new HttpUploader(backend, this.notifyObservers.bind(this));
  }

  uploadFile(path, file) {
    let url = '/api/v3/agent_user_uploads';
    this.httpUploader.upload(path, file, url, { timeout: UPLOAD_CSV_TIMEOUT });
  }
}

_.extend(UsersUploadGateway.prototype, Observable);
