import createModel from 'models/lib/create_model';

export default createModel({
  modelName: 'CreditCard',

  properties: {
    cardNumber: String,
    cardCvv: String,
    cardExpirationDate: String,
    cardName: String,
  },
});
