import _ from 'lodash';
import classnames from 'classnames';
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';

import PageLayout from 'components/page_layout';
import SortControl, { SORT_PARAMETERS, getNextSortOrder } from './sort_control';

export const MIN_CELL_WIDTH = 220;
export const CELL_HEIGHT = 50;
export const CELL_PADDING = 8;
export const HEADER_HEIGHT = 38;

class ReportTable extends React.Component {
  constructor(props) {
    super(props);

    this.reportTableRef = createRef();
    this.state = { fixedHeader: false, headerPosition: 0, windowHeight: window.innerHeight };
    _.bindAll(this, ['renderColumn', 'renderRowElement', 'renderHeaderCell']);
  }

  componentDidMount() {
    this.setState({ headerPosition: this.reportTableRef.current.getBoundingClientRect().top, fixedHeader: false });
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  /* render */
  render() {
    let cols = this.props.columns.map(this.renderColumn);

    let classes = classnames('reportTable', { 'reportTable-fixedHeader': this.state.fixedHeader });
    return (
      <div className={classes} ref={this.reportTableRef}>
        <Table
          headerHeight={HEADER_HEIGHT}
          maxHeight={CELL_HEIGHT * (this.props.rowsCount + 2)}
          rowHeight={CELL_HEIGHT}
          rowsCount={this.props.rowsCount}
          width={_.max([this.props.windowWidth || document.documentElement.clientWidth, /* minWidth= */ 1024])}
        >
          {cols}
        </Table>
      </div>
    );
  }

  renderColumn(column, columnIndex) {
    return (
      <Column
        cell={props => this.renderRowElement(column, props, columnIndex)}
        fixed={columnIndex === 0}
        flexGrow={1}
        header={this.renderHeaderCell(column, columnIndex)}
        key={column.header}
        width={column.width || MIN_CELL_WIDTH}
      />
    );
  }

  renderRowElement(column, props, columnIndex) {
    let classes = classnames('reportTable-cell', {
      'reportTable-cell-left': columnIndex === 0,
      'reportTable-cell-noPadding': column.noPadding,
    });
    return <Cell className={classes}>{column.renderCell(props)}</Cell>;
  }

  renderHeaderCell(column, columnIndex) {
    const sortable = !column.unsortable;
    const currentSort = this.props.currentSort[column.ref];
    let classes = classnames('reportTable-headerCell', {
      'reportTable-headerCell-filtered': sortable && currentSort,
      'reportTable-cell-left': columnIndex === 0,
    });

    return (
      <Cell className={classes} onClick={() => sortable && this.props.changeSort(column.ref)}>
        {column.header}
        {sortable && <SortControl currentSort={currentSort} />}
      </Cell>
    );
  }

  onScroll() {
    if (window.pageYOffset + PageLayout.TOP_BAR_HEIGHT >= this.state.headerPosition) {
      if (!this.state.fixedHeader) {
        this.setState({ fixedHeader: true });
      }
    } else {
      if (this.state.fixedHeader) {
        this.setState({ fixedHeader: false });
      }
    }
  }
}

ReportTable.propTypes = {
  columns: PropTypes.array,
  rowsCount: PropTypes.number,
  windowWidth: PropTypes.number,
  changeSort: PropTypes.func,
  currentSort: PropTypes.object,
};

ReportTable.CELL_HEIGHT = CELL_HEIGHT;
ReportTable.CELL_PADDING = CELL_PADDING;
ReportTable.getNextSortOrder = getNextSortOrder;
ReportTable.MIN_CELL_WIDTH = MIN_CELL_WIDTH;
ReportTable.SORT_PARAMETERS = SORT_PARAMETERS;

export default ReportTable;
