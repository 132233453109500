import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import ModelFactory from 'scripts/domain/__specs__/factories/model_factory';

export default class AgentReadService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;

    this.reads = {};
  }

  get(agentAttrs, callback, path, { agentId, conversationId }) {
    let agentRead = this.reads[{ agentId, conversationId }];
    if (!agentRead) {
      agentRead = ModelFactory.attributes('AgentRead', { agentId, conversationId, readTo: new Date().toISOString() });
      this.reads[{ agentId, conversationId }] = agentRead;
    }

    callback(null, { status: 200, statusText: statusText(200), response: agentRead });
  }

  update(agentAttrs, callback, path, { orgId, agentId, conversationId }) {
    let agentRead = ModelFactory.attributes('AgentRead', { agentId, conversationId });
    agentRead = Object.assign({}, agentRead, agentAttrs);
    this.reads[{ agentId, conversationId }] = agentRead;

    this._pubsub.publish(`v1/orgs/${orgId}/conversation/${conversationId}/agent-reads/${agentId}`, {
      payload: agentRead,
    });

    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/conversation/:conversationId/agent-reads/:agentId': {
          GET: this.get,
          PUT: this.update,
        },
      },
      this
    );
  }
}
