import murmurhash from 'murmurhash-js';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import AwayIllustration from './lib/away_illustration';
import analytics from 'scripts/lib/analytics';
import Button from 'components/common/button';
import ButtonTypes from 'components/common/button';
import connect from 'components/lib/connect';
import InsetContainer from 'components/common/containers/inset_container';
import ServerClock from 'scripts/application/lib/server_clock';
import StackContainer from 'components/common/containers/stack_container';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

/*
Returns whether the agent should be shown the survey
Agents are put in 26 arbitrary groups, only one of which will see the survey
at any given time. Groups switch every 7 days.
*/
export const getIsInSurveyShown = function(agentId, orgId) {
  const totalGroups = 26;
  const groupDuration = 1000 * 60 * 60 * 24 * 7; // 7 days
  const startDate = new Date('March 01, 2020');
  let hash = murmurhash.murmur3(`${agentId}-saltASurvey`);
  function getCurrentGroup() {
    const today = ServerClock.now();
    return Math.floor((today - startDate) / groupDuration) % totalGroups;
  }
  const dateGroup = getCurrentGroup();
  const agentGroup = hash % totalGroups;
  return dateGroup === agentGroup;
};

// create link
const openSurveyUrl = function(agentId, orgId) {
  const url = `https://www.surveymonkey.com/r/RBSR79K?agentId=${agentId}&orgId=${orgId}`;
  window.open(url, '_blank');
};

const UserSurvey = ({ agentId, orgId }) => {
  const isFeatureEnabled = useIsFeatureEnabled();
  const isInSurvey = getIsInSurveyShown(agentId);
  const handleClick = useCallback(() => {
    analytics.track('User Survey Clicked');
    openSurveyUrl(agentId, orgId);
  }, [agentId]);
  if (isFeatureEnabled('userSurvey') && isInSurvey) {
    return (
      <StyledAgentSurveyContainer>
        <StyledAgentSurvey inset="large">
          <StyledEmojiWrapper stack="medium">👋</StyledEmojiWrapper>
          <div>
            Hey there! We&rsquo;d love to make Gladly better for you.
            <br />
            Take 2 minutes to let us know what you think.
          </div>
          <StyledAgentSurveyButton stack="medium">
            <Button buttonType={ButtonTypes.PRIMARY} onClick={handleClick}>
              {' '}
              Take the Survey{' '}
            </Button>
          </StyledAgentSurveyButton>
        </StyledAgentSurvey>
      </StyledAgentSurveyContainer>
    );
  }

  return <AwayIllustration />;
};

const StyledEmojiWrapper = styled(StackContainer)`
  font-size: 24px;
  align-items: center;
  display: flex;
  justifiy-content: center;
  margin-bottom: 0px;
`;

const StyledAgentSurvey = styled(InsetContainer)`
  height: auto;
  width: 388px;
  font-size: ${p => p.theme.fontSize.base};
  line-height: 162%;
  text-align: center;
  background-color: ${p => p.theme.colors.white};
  border-radius: ${p => p.theme.borderRadius.large};
  box-shadow: ${p => p.theme.boxShadow.small};
  z-index: 99999;
`;

const StyledAgentSurveyButton = styled(StackContainer)`
  margin: 0px auto;
  '& button': {
    width: fit-content;
  }
`;

const StyledAgentSurveyContainer = styled.div`
  clear: both;
  margin-bottom: 16px;
`;

export default connect(({ getProvider }) => {
  return {
    agentId: getProvider('currentAgent').get().id,
    orgId: getProvider('auth')
      .get()
      .getOrgId(),
  };
})(UserSurvey);
