import createModel, { prop } from 'models/lib/create_model';

const AgentMentionedInItem = createModel({
  modelName: 'AgentMentionedInItem',

  properties: {
    agentId: prop(String).isRequired,
    customerId: prop(String).isRequired,
    itemId: prop(String).isRequired,
    itemType: prop(String).isRequired,
  },
});

export default AgentMentionedInItem;
