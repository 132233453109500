import createModel, { prop } from './lib/create_model';

export default createModel({
  modelName: 'TwitterConfiguration',
  properties: {
    appId: prop(String).isRequired,
    enabled: prop(Boolean).isRequired,
    displayName: prop(String),
    handle: prop(String),
    provider: prop(String).default('SMOOCH'),
    providerId: prop(String),
  },

  setHandle(handle) {
    this.handle = handle;
  },

  setDisplayName(displayName) {
    this.displayName = displayName;
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
