import { every, forEach, indexOf, isArray, trim } from 'lodash';
import { getEventTransfer } from 'slate-react-old';
import { useCallback } from 'react';
import { Value } from 'slate-old';

import { isPastedFromExcel } from './excel/deserialize_excel';
import deserializeGdocs, { isPastedFromGdocs } from './gdocs/deserialize_gdocs';
import deserializeWord, { isPastedFromWord } from './word/deserialize_word';
import { insertFragmentWithoutExtraLineToStart } from '../plugins/fragment_helpers';
import PasteSerializer from 'components/text_editor/serializers/paste_serializer';

export function onPaste(event, editor, next) {
  event.preventDefault();

  let transfer;
  if (isNewSlateFragment(event)) {
    // This is a temporary plug to allow agents copy-paste between new email and old note/task composers.
    // Since the new Slate fragment is not backward compatible, we will try to use Html instead.
    // Some minor features (like text color) may be lost but the rest should work fine
    transfer = {
      type: 'html',
      html: getPastedHtml(event),
    };
  } else {
    transfer = getEventTransfer(event);
  }

  if (transfer.type === 'fragment') {
    const existingInlineImages = editor.value.document.getBlocksByType('image');
    next();
    removeInlineImages(editor, existingInlineImages);
  }

  if (transfer.type !== 'html' || isPastedFromExcel(transfer.html)) return next();
  let fragment;
  const html = transfer.html;
  if (isPastedFromGdocs(html)) {
    fragment = deserializeGdocs(html);
  } else if (isPastedFromWord(html)) {
    fragment = deserializeWord(html);
  } else {
    const value = PasteSerializer.deserialize(html);
    const valueJSON = value.toJSON();
    wrapNonblockChildrenRuns(valueJSON.document);
    consolidateParagraphChildren(valueJSON.document);
    fragment = Value.fromJSON(valueJSON).document;
  }

  insertFragmentWithoutExtraLineToStart(editor, fragment);
}

function removeInlineImages(editor, existingInlineImages) {
  const imageBlocks = editor.value.document.getBlocksByType('image');
  imageBlocks.forEach(block => {
    // remove any inline images inserted by the paste operation
    if (!existingInlineImages.find(image => image.key === block.key)) {
      editor.removeNodeByKey(block.key);
    }
  });
}

function isNewSlateFragment(pasteEvent) {
  const dataTransfer = pasteEvent.dataTransfer || pasteEvent.clipboardData;
  if (indexOf(dataTransfer?.types, 'application/x-slate-fragment') >= 0) {
    const encodedFragment = trim(dataTransfer.getData('application/x-slate-fragment'));
    if (!encodedFragment) return false;

    const decodedFragment = decodeURIComponent(atob(encodedFragment));
    const fragmentData = JSON.parse(decodedFragment);
    return isArray(fragmentData) && !fragmentData.object && !fragmentData.nodes;
  }
  return false;
}

function getPastedHtml(pasteEvent) {
  const dataTransfer = pasteEvent.dataTransfer || pasteEvent.clipboardData;
  if (indexOf(dataTransfer?.types, 'text/html') >= 0) {
    return trim(dataTransfer.getData('text/html'));
  }
  return '';
}

export default function useOnPaste() {
  return useCallback(onPaste, []);
}

/**
 * This is a bit of a hack, but basically, slate does not like when we have blocks that have both block AND text/inline children.
 * So to get around this, if a block has "mixed" children, we will wrap all consecutive runs of text/inline children with a
 * block. E.g.:
 *
 * block
 *   block
 *     text1
 *   text2
 *   text3
 *
 * becomes
 *
 * block
 *   block
 *     text1
 *   block
 *     text2
 *     text3
 */
function wrapNonblockChildrenRuns(parent) {
  forEach(parent.nodes, node => {
    wrapNonblockChildrenRuns(node);
  });

  let fixedNodes = [];
  let run = [];
  forEach(parent.nodes, node => {
    if (node.object === 'block') {
      if (run.length) {
        fixedNodes.push({
          object: 'block',
          type: 'paragraph',
          nodes: run,
        });
        run = [];
      }
      fixedNodes.push(node);
    } else {
      run.push(node);
    }
  });
  if (run.length && fixedNodes.length) {
    fixedNodes.push({
      object: 'block',
      type: 'paragraph',
      nodes: run,
    });
    run = [];
  } else if (!fixedNodes.length) {
    fixedNodes = run;
  }

  parent.nodes = fixedNodes;
}

/**
 * After ensuring that our pasted content can actually be rendered by Slate (which enforces blocks not having mixed children),
 * we can, for blocks that only have block children that only have text nodes, remove the intermediate block and raise its children
 * up a level. E.g.
 *
 * block
 *   block
 *     text1
 *   block
 *     text2
 *
 * becomes
 *
 * block
 *   text1
 * block
 *   text2
 *
 * This is done recursively to flatten the block paragraph structure to the one that our slate editor outputs during normal editing
 * operations.
 *
 * While our slate editor _can_ handle unnecessarily nested block structures such as those created by `wrapNonblockChildrenRuns`, most of the
 * plugin coding was done with assumption that we'd have a mostly "flat" block paragraph structure (except for lists of course).
 * From what I can tell, nothing broke horrifically from nested blocks, but the behavior was sometimes inconsistent and weird, so
 * flattening is preferable.
 */
function consolidateParagraphChildren(parent) {
  forEach(parent.nodes, node => {
    consolidateParagraphChildren(node);
  });

  let nodes = [];
  forEach(parent.nodes, node => {
    if (onlyHasParagraphChildren(node)) {
      nodes = [...nodes, ...node.nodes];
    } else {
      nodes.push(node);
    }
  });
  parent.nodes = nodes;
}

function onlyHasParagraphChildren(node) {
  return (
    node.object === 'block' &&
    node.type === 'paragraph' &&
    node.nodes.length &&
    every(
      node.nodes,
      childNode =>
        childNode.object === 'block' &&
        childNode.type === 'paragraph' &&
        every(childNode.nodes, grandChild => grandChild.object === 'text')
    )
  );
}
