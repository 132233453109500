import { DELAY_10, COUNT, getTrackAnswerId } from './start_track_answer_view';

export default class EndTrackAnswerView {
  constructor(context) {
    this.context = context;
  }

  run({ answerId, answerType }) {
    this.context.scheduler.cancel(getTrackAnswerId(answerId, answerType, DELAY_10));
    this.context.scheduler.cancel(getTrackAnswerId(answerId, answerType, COUNT));
  }
}
