import MqttGateway from './lib/mqtt_gateway';

export class CustomerHistoryMergeGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer-history/+/merges';
  }

  subscribe(customerId) {
    super._subscribe([customerId]);
  }

  onReceive(conversationHistoryMergeDto) {
    this.notifyObservers('handleConversationHistoryMerge', conversationHistoryMergeDto);
  }

  unsubscribe(customerId) {
    super._unsubscribe([customerId]);
  }
}

export class ConversationGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer-history/+/conversations/+';
  }

  request(customerId, conversationId) {
    return super._subscribeAndFetch([customerId, conversationId]);
  }

  subscribe(customerId, conversationId) {
    return super._subscribe([customerId, conversationId]);
  }

  unsubscribe(customerId, conversationId) {
    super._unsubscribe([customerId, conversationId]);
  }

  onFetchResponse(conversationDto, topic, parsedTopicParams) {
    const customerId = parsedTopicParams[0];
    this.notifyObservers('handleFetchConversationSuccess', { customerId, conversationDto });
  }

  onFetchError(errorDto) {
    this.notifyObservers('handleFetchConversationError', errorDto);
  }

  onReceive(conversationDto, topic, parsedTopicParams) {
    const customerId = parsedTopicParams[0];
    this.notifyObservers('handleConversationUpdate', { customerId, conversationDto });
  }
}

export class ConversationItemGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer-history/+/conversation-items/+';
  }

  request(customerId, conversationItemId) {
    return super._subscribeAndFetch([customerId, conversationItemId]);
  }

  subscribe(customerId, conversationItemId) {
    return super._subscribe([customerId, conversationItemId]);
  }

  unsubscribe(customerId, conversationItemId) {
    super._unsubscribe([customerId, conversationItemId]);
  }

  onFetchResponse(conversationItemDto, topic, parsedTopicParams) {
    const customerId = parsedTopicParams[0];
    this.notifyObservers('handleFetchItemSuccess', { customerId, conversationItemDto });
  }

  onFetchError(errorDto) {
    this.notifyObservers('handleFetchItemError', errorDto);
  }

  onReceive(conversationItemDto, topic, parsedTopicParams) {
    const customerId = parsedTopicParams[0];
    this.notifyObservers('handleItemUpdate', { customerId, conversationItemDto });
  }
}

export class ProfileGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer-profiles/+';
  }

  request(customerId) {
    return super._subscribeAndFetch([customerId]);
  }

  unsubscribe(customerId) {
    super._unsubscribe([customerId]);
  }

  onFetchResponse(fetchResultDto) {
    this.notifyObservers('handleFetchProfileSuccess', fetchResultDto);
  }

  onFetchError(errorDto) {
    this.notifyObservers('handleFetchProfileError', errorDto);
  }

  onReceive(entityDto) {
    this.notifyObservers('handleProfileUpdate', entityDto);
  }
}
