/**
 * KeypressEventHandler
 * returns handleKeyDown React property that channels ctlrEnter and escape events to
 * onSubmit, onCancel event handlers
 *
 * Requires two arguments:
 * @attribute onSubmit - the function to execute on enterKey (text input) and
 *                       ctrlKey+enterKey (textarea)
 * @attribute onCancel - the function to execute on escapeKey
 *
 * Example
 * ```
 * var keh = new KeypressEventHandler(onSubmit, onCancel);
 * MyReactComponent.render = function() {
 *   return (
 *     <React.DOM.input {...Keh.reactAttributes()} />
 *   )
 * }
 *```
 */

class KeypressEventHandler {
  constructor(submitFn, cancelFn, options = {}) {
    this.submitFn = submitFn;
    this.cancelFn = cancelFn;
    this.isInputField = !!options.isInputField;
    this.toggleFn = options.toggleFn;
  }

  submit(event) {
    if (this.submitFn) {
      this.submitFn(event);
    }
  }

  cancel(event) {
    if (this.cancelFn) {
      this.cancelFn(event);
    }
  }

  toggle(event) {
    if (this.toggleFn) {
      this.toggleFn(event);
    }
  }

  // input field accepts enterKey and ctrlKey for submit event
  // textarea accepts ctrlKey for submit event
  handleKeyDown(event) {
    // ensure meta, shift and alt are not also pressed
    const metaShiftAlt = !!event.metaKey || !!event.shiftKey || !!event.altKey;
    const spaceBar = ' ';

    if (event.key === 'Escape') {
      this.cancel(event);
    } else if (event.key === 'Enter' && !metaShiftAlt) {
      if (this.isInputField || event.ctrlKey === true) {
        this.submit(event);
      }
    } else if (event.key === spaceBar && !metaShiftAlt) {
      this.toggle(event);
    }
  }

  reactAttributes() {
    return {
      onKeyDownCapture: this.handleKeyDown.bind(this),
    };
  }
}

export default KeypressEventHandler;
