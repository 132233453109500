import React, { useContext } from 'react';
import SmsFillIcon from 'components/lib/icons/sms_fill_icon';
import FloorboardRow from './floorboard_row';
import LiveboardContext from 'components/liveboards/contexts/liveboard_context.jsx';

export default function SMSRow() {
  const { channel, sla } = useContext(LiveboardContext);

  const stats = channel.sms;
  const smsSla = sla.sms;
  return (
    <FloorboardRow
      activeItems={stats.currentOpenSms}
      activeLabel="Active SMS"
      currentWait={stats.currentWait}
      icon={<SmsFillIcon />}
      newItems={stats.currentNewSms}
      newLabel="New SMS"
      sla={smsSla}
      waitLabel="Oldest Unanswered SMS"
    />
  );
}
