import findIndex from 'lodash/findIndex';

export default class RemoveEmailSuggestion {
  constructor(context) {
    this.context = context;
  }

  run({ email }) {
    const existingSuggestion = this.context.stores.emailSuggestions.findBy({ email: email.toLowerCase() });
    if (existingSuggestion) {
      this.context.stores.emailSuggestions.remove(existingSuggestion.id);

      const localSuggestions = this.context.stores.localEmailSuggestions.get();
      const existingLocalIndex = findIndex(localSuggestions, localSuggestion => localSuggestion.email === email);
      if (existingLocalIndex >= 0) {
        localSuggestions.splice(existingLocalIndex, 1);
        this.context.stores.localEmailSuggestions.set(localSuggestions);
      }
    }
  }
}
