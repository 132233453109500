import { get, keys } from 'lodash';

import createModel, { prop } from '../lib/create_model';
import createEnum from 'scripts/lib/create_enum';
import Conversation from 'models/conversation';
import ConversationItem from 'models/conversation_item';
import CustomerProfile from 'models/customer_profile';
import ServerClock from 'scripts/application/lib/server_clock';

export const InboxItemType = createEnum('CUSTOMER', 'TASK');

const AgentInboxItem = createModel({
  modelName: 'AgentInboxItem',

  properties: {
    id: String, // customer id or task id

    conversation: Conversation,
    item: ConversationItem,
    profile: CustomerProfile,

    type: prop().oneOf(...keys(InboxItemType)), // 'CUSTOMER' or 'TASK'

    removedAt: prop(String),
    manuallyCreatedAt: prop(String),

    // Indicates whether we've successfully fetched this item from the backend,
    // as this may be a partially saturated model as a result of broadcasts.
    //
    // Relevant only when customerListAssignmentBroadcast flag is enabled
    hasFetchedAtLeastOnce: prop(Boolean),
  },

  setConversation(conversation) {
    this.conversation = conversation;
  },
  setItem(item) {
    this.item = item;
  },
  setProfile(profile) {
    this.profile = profile;
  },
  setHasFetchedAtLeastOnce(hasFetchedAtLeastOnce) {
    this.hasFetchedAtLeastOnce = hasFetchedAtLeastOnce;
  },

  resetRemovedAt() {
    this.removedAt = undefined;
  },
  setRemovedAt(removedAt) {
    this.removedAt = removedAt;
  },
  setRemovedAtToNow() {
    this.removedAt = ServerClock.toISOString();
  },

  setManuallyCreatedAtToNow() {
    this.manuallyCreatedAt = ServerClock.toISOString();
  },
  resetManuallyCreatedAt() {
    this.manuallyCreatedAt = undefined;
  },

  getUpdatedAt() {
    if (this.type === 'TASK') {
      return get(this.item, 'content.updatedAt');
    }
    return get(this.conversation, 'updatedAt');
  },
  getDueAt() {
    if (this.type === 'TASK') {
      return get(this.item, 'content.dueAt');
    }
    return (
      (this.conversation &&
        this.conversation.status === Conversation.Status.OPEN &&
        get(this.conversation, 'sla.dueAt')) ||
      undefined
    );
  },
  hasLoaded() {
    if (this.type === 'TASK') {
      return this.profile && this.item;
    }
    return this.profile && this.conversation;
  },
  hasSLA() {
    if (this.type === 'TASK') {
      return !!get(this.item, 'content.dueAt');
    }
    return !!(this.conversation && this.conversation.status === Conversation.Status.OPEN && this.conversation.sla);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
    Type: InboxItemType,
  },
});

export default AgentInboxItem;
