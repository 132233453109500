import Composition from 'models/composition';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';

export default class AbortUpload {
  constructor(context) {
    this.context = context;
  }

  run({ compositionId, uploadId }) {
    const compositionsStore = getCompositionsStore(this.context);
    let composition = compositionsStore.find(compositionId);
    if (!composition.removeAttachment(uploadId)) {
      return;
    }
    compositionsStore.replace(composition);

    let path = Composition.getUploadPath(compositionId, uploadId);
    this.context.gateways.compositionUpload.abort(path);
  }
}
