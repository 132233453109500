import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class LanguagesService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(attrs, callback, path, { orgId }) {
    let languages = this._getLanguages(orgId);
    callback(null, { status: 200, statusText: statusText(200), response: languages });
  }

  _getLanguages(orgId, customerId) {
    let database = this.getDatabase(orgId);

    return database.languages || ['en-us', 'en-gb', 'es-419'];
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/languages': {
          GET: this.find,
        },
      },
      this
    );
  }
}
