import React from 'react';

import AnswerEditorContainer from '../answer/answer_editor_container';
import PageLayout from 'components/page_layout';

const SnippetAdminPage = props => {
  return (
    <PageLayout className="snippetAdmin-page" id="snippetAdmin-page">
      <AnswerEditorContainer />
    </PageLayout>
  );
};

export default SnippetAdminPage;
