import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export default function ConversationTopicMenuHeaderV2({ numSelections, onClick, unitLabelSingular, unitLabelPlural }) {
  const [selectedTopics, setSelectedTopics] = useState(numSelections);
  const [selectionsUpdated, setSelectionsUpdated] = useState(false);
  const nodeRef = useRef(null);

  useEffect(() => {
    if (selectedTopics !== numSelections) {
      setSelectionsUpdated(true);
    }
    setSelectedTopics(numSelections);
  }, [numSelections, selectedTopics]);

  let unitLabel = unitLabelSingular;
  if (numSelections !== 1) {
    unitLabel = unitLabelPlural ? unitLabelPlural : `${unitLabelSingular}s`;
  }

  return (
    <CSSTransition
      classNames="conversation-topics-updated"
      in={selectionsUpdated}
      nodeRef={nodeRef}
      onEnter={() => setSelectionsUpdated(false)}
      timeout={1000}
    >
      <MenuHeader className="conversationTopic-header" onMouseDown={onClick} ref={nodeRef}>
        {`${numSelections} ${unitLabel} selected`}
      </MenuHeader>
    </CSSTransition>
  );
}

ConversationTopicMenuHeaderV2.propTypes = {
  numSelections: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  unitLabelSingular: PropTypes.string.isRequired,
  unitLabelPlural: PropTypes.string,
};

const MenuHeader = styled.div`
  &.conversation-topics-updated-enter {
    color: ${p => p.theme.colors.green400};
  }
  &.conversation-topics-updated-enter-active {
    color: ${p => p.theme.colors.gray400};
    transition: color 200ms;
  }
  &.conversation-topics-updated-exit {
    color: ${p => p.theme.colors.gray400};
  }
  &.conversation-topics-updated-exit-active {
    color: ${p => p.theme.colors.green400};
    transition: color 200ms;
  }
`;
