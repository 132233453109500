import { useCallback, useContext } from 'react';
import { usePlateEditorRef } from '@udecode/plate';

import AITextCompletionContext from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import CancelAITextCompletion from 'actions/ai_text_completions/cancel_ai_text_completion';
import CustomerContext from 'components/customer/customer_context';
import ComposerContext from 'components/composer/contexts/composer_context';
import getSelectedPlaintext from 'components/text_editor_new/lib/get_selected_plaintext';
import RequestAITextCompletion from 'actions/ai_text_completions/request_ai_text_completion';
import useExecuteAction from 'components/hooks/use_execute_action';

// useToggleAICompletion is a hook which returns a callback that, when triggered, either starts or cancels
// an AI text completion based on the `type`.

// If no text is selected, we'll automatically select _all_ the text in the editor, making the completion
// be effective over the entire content.
export default function useToggleAICompletion(type) {
  const executeAction = useExecuteAction();
  const { completion, requestorId } = useContext(AITextCompletionContext);
  const { customerId } = useContext(CustomerContext);
  const { composition } = useContext(ComposerContext);
  const editor = usePlateEditorRef();

  return useCallback(() => {
    if (completion) {
      executeAction(CancelAITextCompletion, { customerId, requestorId, source: 'existing_completion' });
      return;
    }

    editor.initiateAITextCompletion();
    const text = getSelectedPlaintext(editor);
    // This will only happen if there is _no_ text in the editor whatsoever.
    if (!text) {
      editor.removeAITextCompletion();
      return;
    }

    executeAction(RequestAITextCompletion, { customerId, requestorId, text, type, composition });
  }, [completion, customerId, editor, executeAction, requestorId, type, composition]);
}
