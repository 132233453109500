import PropTypes from 'prop-types';
import React from 'react';

import ChatMetadataIcon from './chat_metadata_icon';
import ConversationItem from 'models/conversation_item';
import CustomerMetadata from '../metadata/customer_metadata';
import ItemContent from '../item_content';
import { ActionType } from 'models/chat_customer_url';

export function ChatCustomerUrlItem({ item }) {
  if (
    !item.content.customerUrl ||
    typeof item.content.customerUrl === undefined ||
    item.content.getActionType() === ActionType.INITIATED
  ) {
    return null;
  }

  return (
    <ItemContent isAuditItem item={item}>
      <CustomerMetadata icon={<ChatMetadataIcon />} item={item} text={`navigated to ${item.content.customerUrl}`} />
    </ItemContent>
  );
}

ChatCustomerUrlItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  profile: PropTypes.shape({ name: PropTypes.string }),
};

export default ChatCustomerUrlItem;
