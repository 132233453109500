export function insertFragmentWithoutExtraLineToStart(editor, fragment) {
  // Slate has this "feature" where it won't combine a fragment with the existing block if the fragment
  // has either a start or end block with nested content (e.g. a list). So if we have an answer that
  // has a list node at the beginning or end, it'll look like we're inserting an extra newline at the
  // beginning of the answer.
  //
  // To get around this sort of, I see if the currently selected block is empty. If, after we insert the
  // answer, it is still empty, I delete it. Ez pz.
  let startBlockWasEmpty = false;
  let startBlockKey = null;
  if (editor.value.blocks.count() === 1) {
    const startBlock = editor.value.blocks.get(0);
    startBlockKey = startBlock.key;
    const texts = startBlock.getTexts();
    if (texts.count() === 1) {
      startBlockWasEmpty = !texts.get(0).text;
    }
  }

  editor.insertFragment(fragment);

  if (startBlockWasEmpty) {
    const startBlock = editor.value.document.getNode(startBlockKey);
    if (startBlock) {
      const texts = startBlock.getTexts();
      if (texts.count() === 1 && !texts.get(0).text) {
        editor.removeNodeByKey(startBlockKey);
      }
    }
  }
}
