import createModel, { prop } from 'models/lib/create_model';
import Conversation from '../conversation';
import ConversationItem from '../conversation_item';
import HitTypes from 'models/search/hit_types';

export default createModel({
  modelName: 'CustomerProfileSearchHit',

  properties: {
    id: prop(String).isRequired,
    conversation: Conversation,
    conversationItem: ConversationItem,
    address: String,
    email: String,
    externalCustomerAddresses: String,
    externalCustomerId: String,
    name: String,
    note: String,
    phone: String,
    taskCount: Number,
    customAttributes: prop(Object).default({}),
    matchingAttribute: prop(String).default(''),
  },

  overrideToJs(toJs) {
    return () => ({ ...toJs(), type: HitTypes.CUSTOMER_PROFILE });
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    overrideFromJs(fromJs) {
      return attrs => {
        if (attrs.conversationItem) {
          attrs.conversationItem.customerId = attrs.id;
        }
        return fromJs({
          ...attrs,
        });
      };
    },
  },
});
