import CustomerView from 'models/location/customer_view';

/**
 * Scrolls the conversation history feed to a specified conversation/conversation item. If `itemId` is
 * omitted, will try to scroll to the most recent item
 */
export default class ScrollToConversationItem {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId, itemId }) {
    const currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation instanceof CustomerView) {
      const currentConversationId = currentLocation.currentConversationId;
      const targetConversationId = conversationId || currentConversationId;

      currentLocation.changeCurrentConversation(targetConversationId);
      if (itemId) {
        // Scroll to a particular conversation item
        currentLocation.changeCurrentConversationItem(itemId);
      } else {
        // Try scrolling to the most recent item
        currentLocation.clearCurrentConversationItem();
      }
      this.context.stores.currentLocation.set(currentLocation);
    }
  }
}
