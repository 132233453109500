import React from 'react';
import Icon from 'components/lib/icons/icon';

export default props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      clipRule="evenodd"
      d="M13.33 3.624a.5.5 0 01.046.705l-7 8a.5.5 0 01-.758-.007l-3-3.555a.5.5 0 11.764-.645l2.625 3.11 6.617-7.561a.5.5 0 01.705-.047z"
      fill="000"
      fillRule="evenodd"
    />
  </Icon>
);
