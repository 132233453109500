import * as Sentry from '@sentry/browser';

import DocumentStoreSaveResultHandler from '../lib/document_store_save_result_handler';
import DtoConverter from 'scripts/application/dto_converters/agent_profile_converter';
import LoadProfileSnippets from 'actions/users/load_profile_snippets';
import qconsole from 'scripts/lib/qconsole';

export default class CurrentAgentProfileGatewayObserver extends DocumentStoreSaveResultHandler {
  constructor(context) {
    super(context.stores.currentAgent);
    this.context = context;
  }

  handleAgentProfile(agentProfileDto) {
    let agentProfileEntity = DtoConverter.fromDto(agentProfileDto);
    if (agentProfileDto.version > this.currentAgent.version) {
      // Configure user for analytics, sentry and chat
      this.context.analytics.setUser(agentProfileEntity);
      Sentry.setUser({ email: agentProfileEntity.email });

      this.store.set(agentProfileEntity);
      this.store.resetLoading();
      this.context.executeAction(LoadProfileSnippets, agentProfileEntity);
    }
  }

  get currentAgent() {
    return this.store.get();
  }

  handleRequestError(errorDto) {
    qconsole.log(`Failed to fetch current agent ${JSON.stringify(errorDto)}`);
  }
}
