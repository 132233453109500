import CompositionContentType from 'models/composition/composition_content_type';
import ConversationItemType from 'models/conversation_item_type';
import IconClasses from './icon_classes';
import InteractionType from 'models/composition/interaction_type';
import { MessageChannelType } from 'models/conversation_message';

export default {
  [InteractionType.CHAT]: IconClasses[ConversationItemType.CHAT],
  [InteractionType.CREDIT_CARD_REQUEST]: IconClasses[CompositionContentType.CREDIT_CARD_REQUEST],
  [InteractionType.EMAIL]: IconClasses[ConversationItemType.EMAIL],
  [InteractionType.FB_MESSAGE]: IconClasses[ConversationItemType.FB_MESSAGE_OUTGOING],
  [InteractionType.PHONE_CALL]: IconClasses[ConversationItemType.PHONE_CALL],
  [InteractionType.PHONE_CALLBACK]: IconClasses[ConversationItemType.PHONE_CALLBACK],
  [InteractionType.SMS]: IconClasses[ConversationItemType.SMS],
  [InteractionType.CONVERSATION_NOTE]: IconClasses[ConversationItemType.CONVERSATION_NOTE],
  [InteractionType.TASK]: IconClasses[ConversationItemType.TASK],
  [InteractionType.TASK_EDIT]: IconClasses[ConversationItemType.TASK],
  [InteractionType.TWITTER]: IconClasses[ConversationItemType.TWITTER],
  [InteractionType.WHATSAPP]: IconClasses[MessageChannelType.WHATSAPP],
};
