import createModel from './lib/create_model';

const EmailRecipientDeliveryStatus = createModel({
  modelName: 'EmailRecipientDeliveryStatus',

  properties: {
    status: String,
    code: Number,
    detail: String,
  },

  statics: {
    Status: Object.freeze({
      DELIVERED: 'DELIVERED',
      INITIAL: '',
      ERROR: 'ERROR',
      SENT: 'SENT',
      SENT_FINAL: 'SENT_FINAL',
    }),
  },
});

export default EmailRecipientDeliveryStatus;
