import { useCallback, useEffect, useRef, useState } from 'react';

export default function useCopied(resetDelay = 1500) {
  const [showCopied, setShowCopied] = useState(false);
  const timeoutRef = useRef(null);

  const onCopy = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setShowCopied(true);
  }, []);

  useEffect(() => {
    if (showCopied) {
      timeoutRef.current = setTimeout(() => {
        setShowCopied(false);
      }, resetDelay);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [showCopied]);

  return [showCopied, onCopy];
}
