import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import IconContainer from './icon_container';

export default function ChatIconOutline({ className }) {
  return (
    <IconContainer className={classnames('chatIconOutlineContainer', className)} viewBox="0 0 23 19">
      <g fill="none" fillRule="evenodd">
        <g className="chatIconOutline" strokeWidth="1.25" transform="translate(-160.000000, -22.000000)">
          <path d="M172.67555,36.9583333 L168.458333,41.1755501 L168.458333,36.9583333 L168.25,36.9583333 C164.467322,36.9583333 161.375,33.8660113 161.375,30.0833333 C161.375,26.3006554 164.467322,23.2083333 168.25,23.2083333 L174.916667,23.2083333 C178.699345,23.2083333 181.791667,26.3006554 181.791667,30.0833333 C181.791667,33.8660113 178.699345,36.9583333 174.916667,36.9583333 L172.67555,36.9583333 Z" />
        </g>
      </g>
    </IconContainer>
  );
}

ChatIconOutline.propTypes = {
  className: PropTypes.string,
};
