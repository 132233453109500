import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import ConversationItem from 'models/conversation_item';
import ItemContent from '../item_content';
import Link from 'components/common/link';
import Metadata from '../metadata/metadata';

export default function MarketingEmail({ item }) {
  return (
    <ItemContent isAuditItem item={item}>
      <Metadata
        isInbound={false}
        subject={<Link href={_.get(item, 'content.url')}>{_.get(item, 'content.subject')}</Link>}
        text="marketing email sent"
        timestamp={_.get(item, 'timestamp')}
      />
    </ItemContent>
  );
}

MarketingEmail.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
