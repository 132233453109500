import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import AgentProfile from 'models/agent_profile';
import connect from 'scripts/presentation/components/lib/connect';
import { resolveVariables } from 'scripts/domain/models/kb_variable';

export const DEFAULT_CONTEXT = {};

const VariablesContext = React.createContext(DEFAULT_CONTEXT);
export { VariablesContext };

export class VariablesContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...getResolvedVariables(props),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.agentExperienceConfigImm !== state.agentExperienceConfigImm ||
      props.agentImm !== state.agentImm ||
      props.customerProfileImm !== state.customerProfileImm ||
      props.variablesImm !== state.variablesImm
    ) {
      return {
        ...getResolvedVariables(props),
      };
    }

    return null;
  }

  render() {
    return <VariablesContext.Provider value={this.state.variables}>{this.props.children}</VariablesContext.Provider>;
  }
}

VariablesContextProvider.propTypes = {
  children: PropTypes.node,
};

function getResolvedVariables({
  agentExperienceConfigImm,
  agentExperienceConfigProvider,
  agentImm,
  agentProvider,
  customerProfileImm,
  customerProfileProvider,
  variablesImm,
  variablesProvider,
}) {
  const variableModels = variablesProvider.findAll();

  const agent = agentProvider.get();
  const customerProfile = customerProfileProvider.get();

  const agentExperienceConfig = agentExperienceConfigProvider.get();
  const isExternalNameEnabled = agentExperienceConfig.allowExternalName;
  const resolvedAgent = AgentProfile.create({
    ...agent,
    externalName: isExternalNameEnabled ? agent.externalName : undefined,
  });

  const variables = resolveVariables(variableModels, {
    agent: resolvedAgent,
    customerProfile,
  });

  return {
    agentExperienceConfigImm,
    agentImm,
    customerProfileImm,
    variablesImm,
    variables,
  };
}

const VariablesProvider = connect(mapStateToProps)(VariablesContextProvider);
export default VariablesProvider;

function mapStateToProps({ getProvider }) {
  return {
    agentExperienceConfigImm: getProvider('agentExperienceConfig').store.binding.get(),
    agentExperienceConfigProvider: getProvider('agentExperienceConfig'),
    agentImm: getProvider('currentAgent').store.binding.get(),
    agentProvider: getProvider('currentAgent'),
    customerProfileImm: getProvider('profile').store.binding.get(),
    customerProfileProvider: getProvider('profile'),
    variablesImm: getProvider('kbVariables').immutableStore.binding.get(),
    variablesProvider: getProvider('kbVariables'),
  };
}

export function useVariables() {
  return useContext(VariablesContext);
}
