import _ from 'lodash';

import AddGreetingSuggestion from 'actions/greeting_suggestions/add_greeting_suggestion';
import getCompositionsStore from 'actions/customer/lib/get_compositions_store';
import htmlToText from 'models/conversation_item/html_to_text';
import SendConversationMessage from 'actions/composition/send_conversation_message';

export default class SendComposerConversationMessage {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, compositionId, content }) {
    const composition = getCompositionsStore(this.context).findBy({ id: compositionId });
    if (!composition) {
      return;
    }
    // all the composition content from the store
    const compositionContent = composition.toJs().content;

    // add latest bodyHtml content to the composition content
    _.forEach(content, (html, attr) => {
      compositionContent[attr] = html;
    });

    let plaintext = htmlToText(content.bodyHtml);
    this.context.executeAction(AddGreetingSuggestion, {
      customerId,
      messageText: plaintext,
    });

    this.context.executeAction(SendConversationMessage, {
      compositionId,
      content: compositionContent,
    });
  }
}
