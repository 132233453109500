import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

const EmailSuggestion = createModel({
  modelName: 'EmailSuggestion',
  properties: {
    id: prop(String).default(() => IdGenerator.newId()).isRequired, // Email address
    email: prop(String).isRequired,
    count: prop(Number).default(1),
  },

  incrementCount() {
    this.count += 1;
  },
  statics: {
    create(attrs) {
      return new this({
        id: IdGenerator.newId(),
        ...attrs,
      });
    },
  },
});

export default EmailSuggestion;
