import AgentStatus from 'models/agent_status';
import { AgentAvailability } from 'models/agent_status';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import qconsole from 'scripts/lib/qconsole';

export function handleErrorInteractively(context, err) {
  context.stores.agentStatus.resetPending();

  handleCommonErrors(context, err);
}

export function setAgentBusy(context, errHandler = new GatewayErrorSilentHandler(context)) {
  setBusyPending(context);

  context.gateways.agentStatus
    .update({ availability: AgentAvailability.BUSY })
    .then(response => {
      context.stores.agentStatus.resetPending();
      updateStore(context, response);
    })
    .catch(err => {
      context.stores.agentStatus.resetPending();
      errHandler.handleCommonErrors(err);
    });
}

export function setBusyPending(context) {
  let status = context.stores.agentStatus.get();
  status.setBusy();
  status.incrementVersion();
  context.stores.agentStatus.setPending(status);
}

export function setBusyPendingWithReason(context, reasonId) {
  let status = context.stores.agentStatus.get();
  status.setBusy();
  status.updateActiveReason(reasonId);
  status.incrementVersion();
  context.stores.agentStatus.setPending(status);
}

export function updateStore(context, dto) {
  let current = context.stores.agentStatus.get();
  if (current._version > dto._version) {
    qconsole.log(`Ignored older version of agent status: [${current._version} > ${dto._version}]`);
    return current;
  }

  context.stores.agentStatus.set(AgentStatus.fromJs(dto));

  return current;
}
