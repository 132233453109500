import _ from 'lodash';

import clearExcessSnowplowStorage from 'scripts/adapters/stores/lib/clear_excess_snowplow_storage';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getStorageSizes from 'scripts/adapters/stores/lib/get_storage_sizes';
import RecentlyMentionedAgentid from 'models/recently_mentioned_agent_id';

export const STORAGE_KEY = 'recently-mentioned-agent-ids';

export default class LocalRecentlyMentionedAgentIds {
  constructor(localStorage, authStore) {
    this.localStorage = localStorage;
    this.authStore = authStore;
  }

  get() {
    const agentIdsByOrgId = this._get();
    const recentList = agentIdsByOrgId[this._getOrgId()] || [];
    return _.map(recentList, recent => RecentlyMentionedAgentid.create(recent));
  }

  set(recentlyMentionedAgentIds) {
    const recentlyMentionedAgentIdsJs = _.map(recentlyMentionedAgentIds, r => r.toJs());

    const current = this._get();
    const orgId = this._getOrgId();
    current[orgId] = recentlyMentionedAgentIdsJs;
    const json = JSON.stringify(current);

    try {
      this.localStorage.setItem(STORAGE_KEY, json);
    } catch (e) {
      const sizes = getStorageSizes(this.localStorage);
      clearExcessSnowplowStorage(this.localStorage);
      ErrorReporter.reportError(e, {
        extra: {
          sizes,
          sizesAfterClearing: getStorageSizes(this.localStorage),
        },
      });
    }
  }

  remove() {
    this.localStorage.removeItem(STORAGE_KEY);
  }

  _getOrgId() {
    return this.authStore.get().getOrgId();
  }

  _get() {
    const json = this.localStorage.getItem(STORAGE_KEY);
    return json ? JSON.parse(json) : {};
  }
}
