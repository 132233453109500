import styled from 'styled-components';

import { PortalledPopover, PopoverArrow } from 'components/common/lib/_popover';

/**
 * PopoverCard is a generic white popover card that has a little white arrow pointing at the given
 * `targetReference`.
 */

const PopoverCard = styled(PortalledPopover)`
  background-color: ${p => p.theme.colors.white};
  border-radius: 6px;
  box-shadow: ${p => p.theme.boxShadow.medium};
  padding: 16px;
  ${p => (p.zIndex ? `z-index: ${p.zIndex};` : '')}

  ${PopoverArrow} {
    display: none;
  }

  &[data-position='bottom'] {
    ${PopoverArrow} {
      border-bottom: 9px solid ${p => p.theme.colors.white};
    }
  }
  &[data-position='right'] {
    ${PopoverArrow} {
      border-right: 9px solid ${p => p.theme.colors.white};
    }
  }
  &[data-position='left'] {
    ${PopoverArrow} {
      border-left: 9px solid ${p => p.theme.colors.white};
    }
  }
  &[data-position='top'] {
    ${PopoverArrow} {
      border-top: 9px solid ${p => p.theme.colors.white};
    }
  }
`;

export default PopoverCard;
