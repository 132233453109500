import clearExcessSnowplowStorage from 'scripts/adapters/stores/lib/clear_excess_snowplow_storage';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getStorageSizes from 'scripts/adapters/stores/lib/get_storage_sizes';

export const STORAGE_KEY = 'notifications';

export default class NotificationGateway {
  constructor(localStorage) {
    this.localStorage = localStorage;

    this.onUpdate = this.onUpdate.bind(this);
    window.addEventListener('storage', this.onUpdate);
  }

  delete(notificationId) {
    try {
      this.localStorage.setItem(NotificationGateway.makeKey(notificationId), notificationId);
      this.localStorage.removeItem(NotificationGateway.makeKey(notificationId));
    } catch (e) {
      const sizes = getStorageSizes(this.localStorage);
      clearExcessSnowplowStorage(this.localStorage);
      ErrorReporter.reportError(e, {
        extra: {
          notificationId,
          sizes,
          sizesAfterClearing: getStorageSizes(this.localStorage),
        },
        message: 'Could not delete notification',
      });
    }
  }

  setObserver(observer) {
    this.observer = observer;
  }

  onUpdate(evt) {
    if (evt.key && evt.key.startsWith(STORAGE_KEY) && evt.newValue) {
      this.observer.onBroadcastDelete({ notificationId: evt.newValue });
    }
  }

  static makeKey(notificationId) {
    return `${STORAGE_KEY}-${notificationId}`;
  }
}
