import _ from 'lodash';
import PropTypes from 'prop-types';

import ChannelFilter from './channel_filter';
import connect from 'components/lib/connect';
import { EndpointTypes } from 'models/endpoint';

const ChannelFilterContainer = connect(mapStateToProps)(ChannelFilter);

ChannelFilterContainer.propTypes = {
  onChange: PropTypes.func,
  queryId: PropTypes.string,
};

const NONE = [];

function mapStateToProps(context, props) {
  let result = context.getProvider('universalSearchResults').findBy({ id: props.queryId });
  let selectedChannels = _.get(result, 'query.filter.channels') || NONE;
  let channelConfiguration = context.getProvider('channelConfiguration').get();
  let customChannels = context
    .getProvider('customChannels')
    .findAll()
    .filter(c => !c.archived);

  let isWhatsAppEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.WHATSAPP);
  let isChatEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.CHAT);
  let isTwitterEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.TWITTER);
  let isInstagramDirectEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.INSTAGRAM_DIRECT);
  let isFbMessengerEnabled = isChannelEnabled(channelConfiguration, EndpointTypes.FB_MESSENGER);

  return {
    customChannels,
    isChatEnabled,
    isFbMessengerEnabled,
    isInstagramDirectEnabled,
    isTwitterEnabled,
    isWhatsAppEnabled,
    onChange: props.onChange,
    selectedChannels,
  };
}

function isChannelEnabled(channelConfiguration, endpointType) {
  return !!channelConfiguration && channelConfiguration.isChannelEnabled(endpointType);
}

export default ChannelFilterContainer;
