import { Factory } from 'rosie';
import moment from 'moment';

import '../conversation_item_factory';

Factory.define('LinkedItem');
Factory.define('LinkedItemWithDefaults')
  .attr('customerId', 'customer-id')
  .attr('customerProfile', () => Factory.attributes('LinkedCustomerProfile', { name: 'Linked Name' }))
  .attr('itemId', 'item-id')
  .attr('timestamp', () =>
    moment()
      .subtract(5, 'days')
      .toISOString()
  );
Factory.define('LinkedCustomerProfile');

Factory.define('ItemLink').attr('type', 'ITEM_LINK');

Factory.define('ItemLinkWithDefaults')
  .extend('ItemLink')
  .attr('customerId', 'customer-id')
  .attr('itemId', 'item-id')
  .attr('customerProfile', () => Factory.attributes('LinkedCustomerProfile', { name: 'Original Name' }))
  .attr('originalContent', () => Factory.attributes('EmailMessageWithDefaults'))
  .attr('originalInitiator', ['customerId'], customerId => ({
    type: 'CUSTOMER',
    id: customerId,
  }))
  .attr('originalTimestamp', () =>
    moment()
      .subtract(5, 'days')
      .toISOString()
  );

Factory.define('ItemLinkItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('ItemLink', content));

Factory.define('ItemLinkItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('linkedItems', () => [
    {
      customerId: 'customer-id',
      itemId: 'item-id',
      customerProfile: Factory.attributes('LinkedCustomerProfile', { name: 'Original Name' }),
      isOriginal: true,
    },
  ])
  .attr('content', ['content'], content => Factory.attributes('ItemLinkWithDefaults', content));
