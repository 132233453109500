import { AtomicBlockUtils, ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';

import configureIdGenerator from 'scripts/configuration/configure_id_generator';
import { findEntities } from './draft_entity';
import SafeHtml from 'components/common/safe_html';

export const ENTITY_TYPE_INLINE_TABLE = 'INLINE_TABLE';
export const findInlineTableEntities = findEntities(ENTITY_TYPE_INLINE_TABLE);

export default function DraftInlineTable({ block, blockProps, contentState }) {
  const data = block.getData();
  const table = data.get('tableHtml');
  const id = data.get('id');

  const { onClickTable } = blockProps;
  return (
    <SafeHtml
      className="draftEditor-inlineTable"
      html={table}
      onClick={onClickTable ? onClickTable.bind(this, table, id) : null}
    />
  );
}

DraftInlineTable.propTypes = {
  block: PropTypes.object,
  blockProps: PropTypes.object,
  contentState: PropTypes.instanceOf(ContentState),
};

// Helpers

export function replaceTable({ editorState, table, id }) {
  const currentContent = editorState.getCurrentContent();
  const blocks = currentContent.getBlocksAsArray();

  let tableBlock;
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    if (block.getData().get('type') === 'table' && block.getData().get('id') === id) {
      tableBlock = block;
      break;
    }
  }

  if (!tableBlock) {
    return editorState;
  }
  const selection = new SelectionState({
    anchorKey: tableBlock.getKey(),
    anchorOffset: 0,
    focusKey: tableBlock.getKey(),
    focusOffset: tableBlock.getText().length,
    isBackward: false,
  });
  const newContentState = Modifier.mergeBlockData(currentContent, selection, { tableHtml: table });
  return EditorState.push(editorState, newContentState, 'change-block-data');
}

export function createTable({ editorState, table }) {
  // Create an atomic block with a non-breakable space.. ensures an answer isn't considered empty if it's just a table
  const editorStateWithAtomicBlock = AtomicBlockUtils.insertAtomicBlock(editorState, null, '\u0020');

  const blocks = editorStateWithAtomicBlock.getCurrentContent().getBlocksAsArray();
  let previousBlock;
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    // The selection gets set to the block immediately after the atomic block that was just inserted.
    if (block.getKey() === editorStateWithAtomicBlock.getSelection().getAnchorKey()) {
      break;
    }
    previousBlock = block;
  }

  const currentContent = editorStateWithAtomicBlock.getCurrentContent();
  const selection = new SelectionState({
    anchorKey: previousBlock.getKey(),
    anchorOffset: 0,
    focusKey: previousBlock.getKey(),
    focusOffset: previousBlock.getText().length,
    isBackward: false,
  });
  const newContentState = Modifier.setBlockData(currentContent, selection, {
    id: configureIdGenerator().newId(),
    tableHtml: table,
    type: 'table',
  });
  return EditorState.push(editorState, newContentState, 'change-block-data');
}
