import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'KeywordGroups',
  properties: {},

  statics: {
    breadcrumb: 'Keyword Groups',
  },
});
