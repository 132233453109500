import classnames from 'classnames';
import React from 'react';

import T from 'prop-types';
import { H3 } from 'components/common/headers';

export default function LabeledCard({ className, title, children }) {
  return (
    <div className={classnames('labeledCard', className)}>
      <H3 data-aid="labeledCard-title" stack="none">
        {title}
      </H3>
      <div className="labeledCard-card">{children}</div>
    </div>
  );
}

LabeledCard.propTypes = {
  className: T.string,
  title: T.string,
  children: T.node,
};
