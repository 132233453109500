import _ from 'lodash';

import createModel, { prop } from './lib/create_model';
import CustomerTypingState from 'models/customer_typing_state';
import RoutingQueueItem, { AgentParticipantStatus, RoutingStatus } from './routing_queue_item';

const SessionCustomer = createModel({
  modelName: 'SessionCustomer',

  properties: {
    id: prop(String).isRequired,
    name: prop(String),
  },

  updateName(name) {
    this.name = name;
  },

  statics: {
    create(attrs) {
      return new this(_.assign({}, attrs));
    },
  },
});

export default createModel({
  modelName: 'ActiveSession',

  properties: {
    id: prop(String).isRequired, // RoutingQueueItem id
    customer: prop(SessionCustomer).isRequired,
    queueItem: prop(RoutingQueueItem).isRequired,
    readTo: prop(String),
    conversationUpdatedAt: prop(String),
    typingState: prop(CustomerTypingState).default(() => new CustomerTypingState()),
  },

  conversationId() {
    return this.queueItem.conversationId;
  },

  accept(agentId) {
    this.queueItem.updateStatus(RoutingStatus.ACTIVE);
    this.queueItem.updateParticipantStatus(agentId, AgentParticipantStatus.ACTIVE);
  },

  getCustomerIdentifier(sessionType) {
    return this.queueItem.getSessionByType(sessionType)?.customerIdentifier;
  },

  isUnread() {
    if (!this.conversationUpdatedAt) {
      return false;
    }
    return new Date(this.conversationUpdatedAt) > new Date(this.readTo);
  },

  setTypingState(typingState) {
    this.typingState = typingState;
  },

  update(attrs) {
    _.assign(this, _.omit(attrs, 'id'));
  },

  updateCustomerName(name) {
    this.customer.updateName(name);
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export { SessionCustomer };
