import _ from 'lodash';
import { getRenderableSections } from 'components/customer/flexible_profile_card/lib/get_renderable_sections';
import { getAttributesToDisplay } from 'components/customer/flexible_profile_card/lib/get_attributes_to_display';

/**
 * Helper function that checks whether the given "item details" configuration has any renderable attributes
 * either in the header or in one of the "sections"
 */
export function isRenderableItem(itemDetailsConfig, itemData) {
  if (_.isEmpty(itemData) || _.isEmpty(itemDetailsConfig)) return false;

  const sectionConfigs = _.get(itemDetailsConfig, 'sections') || [];
  const headerAttributes = _.get(itemDetailsConfig, 'header.attributes') || [];
  const renderableSections = getRenderableSections(sectionConfigs, itemData);
  const renderableHeaderAttrs = getAttributesToDisplay(headerAttributes, itemData);

  return !_.isEmpty(renderableHeaderAttrs) || !_.isEmpty(renderableSections);
}
