import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/common/tooltip';

export default function ShortListDeprecated({ className, placeholder, tooltipValues, values }) {
  if (!values || values.size === 0) {
    return (
      <div className={classnames(className, `${className}-value-container`)}>
        <div className={`${className}-value`}>{placeholder}</div>
      </div>
    );
  }
  let numValues = values.size;
  return (
    <div className={className}>
      <Tooltip
        className={`${className}-tooltip`}
        message={tooltipValues && tooltipValues.size > 0 ? tooltipValues.join('\r\n') : undefined}
        position="bottom"
      >
        <div className={`${className}-value-container`}>
          <div className={`${className}-value`}>{numValues > 1 ? values.join(', ') : values.get(0)}</div>
          {numValues > 1 ? <div className={`${className}-trailer`}>{`\xa0(${numValues} total)`}</div> : null}
        </div>
      </Tooltip>
    </div>
  );
}

ShortList.propTypes = {
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  tooltipValues: PropTypes.arrayOf(PropTypes.node),
  values: PropTypes.arrayOf(PropTypes.node),
};

export function ShortList({ className, placeholder, tooltipValues, values }) {
  if (!values || values.length === 0) {
    return (
      <div className={classnames(className, `${className}-value-container`)}>
        <div className={`${className}-value`}>{placeholder}</div>
      </div>
    );
  }
  let numValues = values.length;
  return (
    <div className={className}>
      <Tooltip
        className={`${className}-tooltip`}
        message={tooltipValues && tooltipValues.length > 0 ? tooltipValues.join('\r\n') : undefined}
        position="bottom"
      >
        <div className={`${className}-value-container`}>
          <div className={`${className}-value`}>{numValues > 1 ? values.join(', ') : values[0]}</div>
          {numValues > 1 ? <div className={`${className}-trailer`}>{`\xa0(${numValues} total)`}</div> : null}
        </div>
      </Tooltip>
    </div>
  );
}
