import classnames from 'classnames';
import React, { useContext } from 'react';

import EmailItemContext from 'components/customer/conversation_history/conversation_items_v2/email/email_item_context';
import MenuItem from 'components/customer/conversation_history/conversation_items_v2/menu/conversation_item_menu_item';

export default function ViewOriginalEmailHtmlMenuItem() {
  const { setShowHtmlModal } = useContext(EmailItemContext);

  return (
    <MenuItem
      className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-viewOriginalEmailHtml')}
      isWide
      onSelect={() => setShowHtmlModal(true)}
    >
      <span>View original HTML</span>
    </MenuItem>
  );
}
