import _ from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import ComposerContext from 'components/composer/contexts/composer_context';

export default function useWasCustomerAddedToEmail(profile) {
  const { composition } = useContext(ComposerContext);
  const { to, cc, bcc } = composition.content;

  const [wasCustomerAddedToEmail, setWasCustomerAddedToEmail] = useState(false);
  const ignoreCustomerWasAddedToEmail = useCallback(() => setWasCustomerAddedToEmail(false), []);

  const customerEmails = _.map(profile.emails, email => email.original);
  const isCustomerOnEmail = isCustomerIncludedInRecepients(customerEmails, to, cc, bcc);
  const isCustomerOnEmailRef = useRef(isCustomerOnEmail);

  useEffect(() => {
    if (isCustomerOnEmail && !isCustomerOnEmailRef.current) {
      setWasCustomerAddedToEmail(true);
    }

    isCustomerOnEmailRef.current = isCustomerOnEmail;
  }, [isCustomerOnEmail, isCustomerOnEmailRef.current]);

  return [wasCustomerAddedToEmail, ignoreCustomerWasAddedToEmail];
}

function isCustomerIncludedInRecepients(customerEmails, to, cc, bcc) {
  const inToField = to && _.some(customerEmails, email => to.toLowerCase().includes(email.toLowerCase()));
  const inCcField = cc && _.some(customerEmails, email => cc.toLowerCase().includes(email.toLowerCase()));
  const inBccField = bcc && _.some(customerEmails, email => bcc.toLowerCase().includes(email.toLowerCase()));
  return inToField || inCcField || inBccField;
}
