import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BannerWarning } from 'components/common/banner';
import Button, { ButtonTypes } from 'components/common/button';
import connect from 'components/lib/connect';
import DismissNotification from 'actions/notification/dismiss_notification';
import { embedTokenExpiringNotificationTimerDelaySeconds } from 'models/notification/embed_token_expiring_notification';

const EmbedTokenExpiringNotification = ({ notification, onPrimaryAction }) => {
  const tokenExpiry = notification.tokenExpiry;

  const calculateTimeLeft = () => {
    return tokenExpiry ? moment(tokenExpiry).diff(moment.now(), 'seconds') : 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <BannerWarning
      buttons={
        <CountdownTimerButton
          buttonType={ButtonTypes.TEXT}
          contrast
          duration={embedTokenExpiringNotificationTimerDelaySeconds}
          onClick={onClick}
          startPercentage={
            ((embedTokenExpiringNotificationTimerDelaySeconds - timeLeft) /
              embedTokenExpiringNotificationTimerDelaySeconds) *
            100
          }
        >
          {formatTimeLeft(timeLeft)}
        </CountdownTimerButton>
      }
      className="embedTokenExpiringNotification"
      titleList={['Your Insights Builder session will expire soon. Please save your work now.']}
      withIcon
    />
  );

  function onClick(ev) {
    ev.stopPropagation();
    onPrimaryAction();
  }
};

export default connect(null, mapExecuteToProps)(EmbedTokenExpiringNotification);

function mapExecuteToProps(executeAction, props) {
  return {
    onPrimaryAction: () => {
      executeAction(DismissNotification, props.notification.id);
    },
  };
}

function formatTimeLeft(secondsUntilExpiry) {
  if (secondsUntilExpiry <= 0) {
    return 0;
  }

  let minutes = Math.floor((secondsUntilExpiry / 60) % 60);
  let seconds = Math.floor(secondsUntilExpiry % 60);

  return minutes
    ? `${minutes}:${seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`
    : seconds.toString();
}

const CountdownTimerButton = styled(Button)`
  background: linear-gradient(to right, rgba(255, 255, 255, 0.37) 50%, transparent 0);
  background-size: 200% 100%;
  background-position: ${p => 100 - p.startPercentage}% 0;
  border: 1px solid white;
  color: ${p => p.theme.colors.black};
  animation: fadeIn ${p => p.duration}s 1s forwards;
  width: 75px;

  &:hover {
    color: ${p => p.theme.colors.black};
  }

  @keyframes fadeIn {
    100% {
      background-position: 0% 0;
    }
  }
`;
