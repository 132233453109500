import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from 'models/lib/create_model';

export const AIActivityTypes = createEnum('CONVERSATION_SUMMARY');

const AIActivityItem = createModel({
  modelName: 'AIActivityItem',

  properties: {
    activityType: prop(String).oneOf(...Object.keys(AIActivityTypes)).isRequired,
    body: prop(String),
    isRedacted: prop(Boolean),
    meta: prop(Object),
    status: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default AIActivityItem;
