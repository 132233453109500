import createNotificationModel from './lib/create_notification_model';

import IdGenerator from 'scripts/domain/contracts/id_generator';
import NotificationType from './notification_type';

export default createNotificationModel(NotificationType.ADMIN.toString(), {
  modelName: 'AdminNotification',
  statics: {
    create(attrs) {
      return new this({ id: IdGenerator.newId(), ...attrs });
    },
  },
});
