import _ from 'lodash';

import ChatWidgetConfiguration from 'models/chat_widget_configuration';
import CustomChannelConfiguration from './custom_channel_configuration';
import InstagramConfiguration from 'models/instagram_configuration';
import TwitterConfiguration from 'models/twitter_configuration';
import VoiceEndpointConfiguration from 'models/voice_endpoint_configuration';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import TypeReflect from 'models/lib/type_reflect';

/* Endpoint - An endpoint is a email, phone number, etc. that the organization can use
 *            for incoming and/or outgoing communication.
 */
export const EndpointTypes = createEnum(
  'CHAT',
  'CUSTOM_CHANNEL',
  'EMAIL',
  'INSTAGRAM_DIRECT',
  'SMS',
  'FB_MESSENGER',
  'VOICE',
  'WHATSAPP',
  'TWITTER'
);

const ConfigurationClasses = [
  [EndpointTypes.CHAT, ChatWidgetConfiguration],
  [EndpointTypes.CUSTOM_CHANNEL, CustomChannelConfiguration],
  [EndpointTypes.INSTAGRAM_DIRECT, InstagramConfiguration],
  [EndpointTypes.TWITTER, TwitterConfiguration],
  [EndpointTypes.VOICE, VoiceEndpointConfiguration],
];

const configurationTypeReflect = new TypeReflect(ConfigurationClasses);

export default createModel({
  modelName: 'Endpoint',

  properties: {
    id: prop(String).isRequired,
    type: String,
    address: prop(String).isRequired,
    name: prop(String),
    displayName: String,
    configuration: prop().oneOf(
      ChatWidgetConfiguration,
      CustomChannelConfiguration,
      InstagramConfiguration,
      TwitterConfiguration,
      VoiceEndpointConfiguration
    ),
  },

  replaceConfiguration(configuration) {
    this.configuration = configuration;
  },

  statics: {
    Type: EndpointTypes,

    _isSupportedConfigurationType(endpointType) {
      for (var i = 0; i < ConfigurationClasses.length; i++) {
        if (ConfigurationClasses[i][0] === endpointType) {
          return true;
        }
      }
      return false;
    },

    overrideFromJs(fromJs) {
      return attrs => {
        const endpoint = fromJs(_.omit(attrs, 'configuration'));

        if (attrs.configuration && this._isSupportedConfigurationType(attrs.type)) {
          const Constructor = configurationTypeReflect.typeToConstructor(attrs.type);
          const configuration = Constructor.fromJs(attrs.configuration);

          return new this({ ...endpoint, configuration });
        }

        return new this({ ...endpoint });
      };
    },
  },
});
