import { animated, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

export default function Toggle({ debounceInterval, isDisabled, isEnabled, onChange, ...rest }) {
  const handleClick = _.debounce(onChange, debounceInterval);

  const styles = useSpring({
    x: isEnabled ? PILL_WIDTH - CIRCLE_RADIUS * 2 - CIRCLE_MARGIN : CIRCLE_MARGIN,
    config: { tension: 300, friction: 24 },
  });

  return (
    <Pill
      className={rest['className']}
      data-aid={rest['data-aid']}
      data-isenabled={isEnabled}
      disabled={isDisabled}
      isEnabled={isEnabled}
      onClick={isDisabled ? _.noop : handleClick}
    >
      <Circle as={animated.div} disabled={isDisabled} style={styles} />
    </Pill>
  );
}

Toggle.propTypes = {
  /** Time (in milliseconds) to delay multiple click interactions (prevents repeated clicking) */
  debounceInterval: PropTypes.number,
  isDisabled: PropTypes.bool,
  isEnabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  'data-aid': PropTypes.string,
};

Toggle.defaultProps = {
  debounceInterval: 100,
  isDisabled: false,
  isEnabled: false,
};

const CIRCLE_MARGIN = 2;
const CIRCLE_RADIUS = 10;
const PILL_WIDTH = 48;

const pillEnabled = css`
  background-color: ${p => p.theme.colors.green400};
`;

const Pill = styled.div`
  background-color: ${p => (!p.disabled ? p.theme.colors.gray400 : p.theme.colors.gray200)};
  border-radius: 12px;
  cursor: pointer;
  height: 24px;
  position: relative;
  transition: background-color 300ms ease;
  width: 48px;
  ${p => p.isEnabled && pillEnabled};
`;

const Circle = styled.div`
  background: ${p => (!p.disabled ? p => p.theme.colors.white : p.theme.colors.gray300)};
  border-radius: 10px;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 8px rgba(0, 0, 0, 0.15);
  height: ${CIRCLE_RADIUS * 2}px;
  position: absolute;
  top: ${CIRCLE_MARGIN}px;
  width: ${CIRCLE_RADIUS * 2}px;
`;
