import classnames from 'classnames';
import d3 from 'd3';
import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/common/link';

export default function DonutChart({ data, radius, width, label, displayValue, link }) {
  if (data.length === 0) {
    data.push({
      value: 1,
      className: 'donutChart-empty',
    });
  }

  let length = radius * 2;

  let arc = d3.svg
    .arc()
    .innerRadius(radius - width)
    .outerRadius(radius);
  let pie = d3.layout.pie().value(d => d.value);
  let arcs = pie(data).map(function(a) {
    return <path className={classnames('donutChart-arc', a.data.className)} d={arc(a)} key={`arc-${a.data.label}`} />;
  });

  let centerDisplay = (
    <text textAnchor={'middle'}>
      <tspan className="donutChart-count" x="0" y="0">
        {displayValue}
      </tspan>
      <tspan className="donutChart-label" x="0" y="20">
        {label}
      </tspan>
    </text>
  );

  if (link) {
    centerDisplay = (
      <Link className="donutChart-link" href={link}>
        {centerDisplay}
      </Link>
    );
  }

  return (
    <div>
      <svg height={length} width={length}>
        <g transform={`translate(${length / 2}, ${length / 2})`}>
          {arcs}
          {centerDisplay}
        </g>
      </svg>
    </div>
  );
}

DonutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  radius: PropTypes.number,
  width: PropTypes.number,
  displayValue: PropTypes.string,
  label: PropTypes.string,
};

DonutChart.defaultProps = {
  radius: 110,
  width: 20,
  data: [],
};
