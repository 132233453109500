import React from 'react';

import { ExpandableItem } from './slate_styles_menu.styles';
import { FONT_SIZES } from 'components/customer/composition/lib/slate/slate_marks';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { PopoverMenuItem, usePopoverMenu } from 'components/common/menu';

export default function SlateFontSizeMenu(props) {
  const { targetRef, isOpen, onClose, onToggle, setTargetRef } = usePopoverMenu();
  const chevronDir = isOpen ? 'up' : 'down';

  const onSetFontSize = size => {
    props.editor.focus();

    let value = props.editor.value;
    value.marks.forEach(mark => {
      const fontSize = mark.get('data').get('fontSize');
      if (fontSize) {
        props.onChange(props.editor.removeMark(mark));
      }
    });

    if (size !== FONT_SIZES.NORMAL) {
      const fontSize = convertFontSize(size);
      props.onChange(props.editor.addMark({ type: 'font', data: { fontSize } }));
    }
    onClose();
  };

  return (
    <OutsideClickHandler onClickOutside={onClose}>
      <ExpandableItem
        css={`
          font-size: 14px;
        `}
        data-aid="fontSize"
        isActive={isOpen || !isSizeActive(FONT_SIZES.NORMAL)}
        onClick={onToggle}
        ref={setTargetRef}
        title="Font size"
      >
        <span>
          <span className="draftStylesMenu-fontSize-small">T</span>T
        </span>
        <i className={`draftStylesMenu-item-chevron fa fa-angle-${chevronDir}`} />
      </ExpandableItem>
      {renderMenu()}
    </OutsideClickHandler>
  );

  function renderMenu() {
    return (
      <PopoverMenu
        boundByWindow
        data-aid="fontSize-menu"
        isOpen={isOpen}
        onClickOutside={null}
        onClose={onClose}
        position="bottom"
        targetRef={targetRef}
      >
        <PopoverMenuItem
          data-aid="small"
          isActive={isSizeActive(FONT_SIZES.SMALL)}
          onClick={() => onSetFontSize(FONT_SIZES.SMALL)}
        >
          Small
        </PopoverMenuItem>
        <PopoverMenuItem
          data-aid="normal"
          isActive={isSizeActive(FONT_SIZES.NORMAL)}
          onClick={() => onSetFontSize(FONT_SIZES.NORMAL)}
        >
          Normal
        </PopoverMenuItem>
        <PopoverMenuItem
          data-aid="large"
          isActive={isSizeActive(FONT_SIZES.LARGE)}
          onClick={() => onSetFontSize(FONT_SIZES.LARGE)}
        >
          Large
        </PopoverMenuItem>
        <PopoverMenuItem
          data-aid="huge"
          isActive={isSizeActive(FONT_SIZES.HUGE)}
          onClick={() => onSetFontSize(FONT_SIZES.HUGE)}
        >
          Huge
        </PopoverMenuItem>
      </PopoverMenu>
    );
  }

  function isSizeActive(size) {
    if (!props.editor) {
      return false;
    }

    const value = props.editor.value;
    const fontSize = convertFontSize(size);
    return size === FONT_SIZES.NORMAL
      ? value.activeMarks.every(mark => !mark.get('data').get('fontSize'))
      : value.activeMarks.some(mark => mark.get('data').get('fontSize') === fontSize);
  }
}

export function convertFontSize(fontSize) {
  switch (fontSize) {
    case FONT_SIZES.SMALL:
      return '1';
    case FONT_SIZES.LARGE:
      return '4';
    case FONT_SIZES.HUGE:
      return '6';
    default:
      return '';
  }
}
