export default class OpenTopics {
  constructor(context) {
    this.context = context;
  }

  run() {
    const workflow = this.context.stores.conversationWorkflow.get();
    workflow.updateTopicsLastOpenedAt();
    this.context.stores.conversationWorkflow.set(workflow);
  }
}
