import PhoneCall from 'models/phone_call';

export default class RemoveParticipantFromPhoneCall {
  constructor(context) {
    this.context = context;
  }

  run({ participantId }) {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      activeCall.conversationItem.content.setParticipantStatus(
        participantId,
        PhoneCall.ParticipantStatus.DISCONNECTING
      );
      this.context.stores.activeCall.setPending(activeCall);

      this.context.gateways.phoneControlsHttp.removeParticipant(
        activeCall.customer.id,
        activeCall.conversationItem.id,
        {
          participantId,
        }
      );
    }
  }
}
