import qconsole from 'scripts/lib/qconsole';
import TaskComment from 'models/task_comment';

export default class TaskCommentsGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.taskInfo;
  }

  onAddSuccess(taskCommentDto) {
    let taskInfoPending = this.store.isPending(taskCommentDto.itemId);
    if (taskInfoPending) {
      this.store.commitPending(taskCommentDto.itemId);
    }
  }

  onAddError(error) {
    qconsole.error('error adding task comment', error);
  }

  onBroadcast(taskCommentDto) {
    let taskComment;
    try {
      taskComment = TaskComment.fromJs(taskCommentDto);
    } catch (e) {
      this.context.errorReporter.reportError(e, {
        extra: { taskComment: taskCommentDto },
        message: 'Ignored task comment',
      });
      return;
    }
    let taskInfo = this.store.findBy({ id: taskComment.itemId });
    taskInfo.addComment(taskComment);
    this.store.addOrReplace(taskInfo);
  }
}
