import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function LoadingDots({ className }) {
  return (
    <LoadingDotsWrapper className={className}>
      <LoadingDot className="dot" />
      <LoadingDot className="dot" />
      <LoadingDot className="dot" />
    </LoadingDotsWrapper>
  );
}

const LoadingDot = styled.span`
  background-color: ${p => p.theme.colors.gray700};
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  width: 4px;

  animation-name: blink;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  @keyframes blink {
    0%,
    100% {
      opacity: 0.25;
    }
    20% {
      opacity: 1;
      transform: scale(1.25);
    }
  }
`;

const LoadingDotsWrapper = styled.div`
  & {
    align-items: center;
    display: flex;
    gap: 4px;
    justify-content: center;
    margin-left: 8px;
  }
  & .dot:nth-child(2) {
    animation-delay: 0.25s;
  }
  & .dot:nth-child(3) {
    animation-delay: 0.5s;
  }
`;

LoadingDots.propTypes = {
  className: PropTypes.string,
};
