import _ from 'lodash';
import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import Conversation from 'models/conversation';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import CustomerMergeConverter from 'scripts/application/dto_converters/customer_merge_converter';
import DtoConverter from 'scripts/application/dto_converters/customer_profile_converter';
import parseTopicParams from 'scripts/adapters/gateways/lib/standard_gateway/parse_topic_params';
import {
  getInboxItemIdFromConversationItem,
  reconcileInboxItem,
  shouldHandleItem,
} from 'actions/inbox/agent/agent_inbox_helpers';
import removeItemFromInbox from 'actions/inbox/lib/remove_item_from_inbox';

export default class AgentInboxObserver {
  constructor(context) {
    this.context = context;
  }

  getStore() {
    const isBroadcastEnabled = this.context.stores.appFeatures.get().isEnabled('customerListAssignmentBroadcast');
    return isBroadcastEnabled ? this.context.stores.agentInboxItemsNew : this.context.stores.agentInboxItems;
  }

  onBroadcastConversation(originalTopic, { payload: conversationDto }, topic) {
    const { customerId } = parseTopicParams(originalTopic, topic);
    const conversation = Conversation.fromJs(conversationDto);
    const currentAgentId = this.context.stores.currentAgent.get().id;
    let inboxItem = this.getStore().findBy({ id: customerId });

    // If the conversation is closed or assigned away, then we probably don't want to do anything with it
    // besides maybe removing the current inbox item from the customer list.
    if (conversation.status === 'CLOSED' || conversation.assignee.agentId !== currentAgentId) {
      if (inboxItem && inboxItem.conversation && inboxItem.conversation.id === conversation.id) {
        removeItemFromInbox(this.context, inboxItem.id, conversation.updatedAt);
      }
      return;
    }

    const updatedInboxItem = AgentInboxItem.create({
      id: customerId,
      type: AgentInboxItem.Type.CUSTOMER,
      conversation,
    });
    reconcileInboxItem(this.context, updatedInboxItem, this.getStore());
  }

  onBroadcastItem(originalTopic, { payload: itemDto }, topic) {
    const currentAgentId = this.context.stores.currentAgent.get().id;
    const convoItem = ConversationItem.fromJs(itemDto);
    if (!shouldHandleItem(convoItem)) {
      return;
    }
    const type =
      convoItem.contentType() === ConversationItemType.TASK ? AgentInboxItem.Type.TASK : AgentInboxItem.Type.CUSTOMER;
    const inboxItemId = getInboxItemIdFromConversationItem(convoItem);
    let inboxItem = this.getStore().findBy({ id: inboxItemId });

    // if the updated item is a task that has been closed or reassigned to a different agent, remove it from agentInboxItems
    if (
      convoItem.contentType() === ConversationItemType.TASK &&
      (convoItem.content.isClosed() || convoItem.content.assignee.agentId !== currentAgentId)
    ) {
      if (inboxItem) {
        removeItemFromInbox(this.context, inboxItemId, convoItem.content.closedAt || convoItem.content.updatedAt);
      }
      return;
    }

    const updatedInboxItem = AgentInboxItem.create({ id: inboxItemId, type, item: convoItem });
    reconcileInboxItem(this.context, updatedInboxItem, this.getStore());
  }

  onBroadcastProfile(originalTopic, { payload: profileDto }) {
    let profile = DtoConverter.fromDto(profileDto);
    const items = this.getStore().findAll({ profile: { id: profile.id } });

    _.forEach(items, item => {
      const updatedInboxItem = AgentInboxItem.create({ id: item.id, type: item.type, profile });
      reconcileInboxItem(this.context, updatedInboxItem, this.getStore());
    });
  }

  onBroadcastProfileMerge(originalTopic, { payload: mergeDto }, topic) {
    const mergeInfo = CustomerMergeConverter.fromDto(mergeDto);
    const oldCustomerId = mergeInfo.sourceCustomerId;
    removeItemFromInbox(this.context, oldCustomerId);
  }
}
