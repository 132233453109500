import _ from 'lodash';

import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import DismissNotification from 'actions/notification/dismiss_notification';
import { SecureDataRequestStatuses, TransmissionStatuses } from 'models/secure_data_request';
import qconsole from 'scripts/lib/qconsole';
import { NotifiablePaymentStatusEventTypes } from 'models/payment_status_event_content';
import PaymentStatusEventNotification from 'models/notification/payment_status_event_notification';
import playNotificationSound from 'actions/notification/lib/play_notification_sound';
import { NotificationSoundType } from 'actions/notification/lib/notification_constants';

export default class SecureDataRequestHistoryGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleUpdateConversationItem({ customerId, conversationItemDto }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      qconsole.log(
        `Received conversation item [${conversationItemDto.id}] for unloaded customer [${customerId}]. Ignoring.`
      );
      return;
    }
    const customerStores = this.context.stores.customers.storesFor(customerId);
    let conversationItem;
    try {
      conversationItem = ConversationItem.fromJs(conversationItemDto);
    } catch (e) {
      this.context.errorReporter.reportError(e, {
        tags: { customerId, conversationItemId: conversationItemDto.id },
        extra: { conversationItem: conversationItemDto },
        message: 'Ignored conversation item for customer',
      });
      return;
    }
    this._handleActiveSecureDataRequestUpdate({ customerStores, conversationItem });
  }

  _handleActiveSecureDataRequestUpdate({ customerStores, conversationItem }) {
    const activeSecureDataRequestsStore = customerStores.activeSecureDataRequests;
    if (conversationItem.contentType() === ConversationItemType.SECURE_DATA_REQUEST) {
      const requestStatus = _.get(conversationItem, 'content.currentRequestStatus.requestStatus');
      const messageStatus = _.get(conversationItem, 'content.currentRequestStatus.messageStatus');
      if (
        requestStatus !== SecureDataRequestStatuses.REQUESTING ||
        messageStatus.value === TransmissionStatuses.FAILED
      ) {
        activeSecureDataRequestsStore.remove(conversationItem.id);
      }
    }

    if (conversationItem.contentType() === ConversationItemType.PAYMENT_STATUS_EVENT) {
      const eventType = _.get(conversationItem, 'content.eventType');
      if (_.includes(NotifiablePaymentStatusEventTypes, eventType)) {
        const profile = customerStores.profile.get();
        playNotificationSound(NotificationSoundType.PAYMENT_STATUS_EVENT);
        const notification = PaymentStatusEventNotification.create({
          id: conversationItem.id,
          conversationItem,
          profile,
        });
        this.context.stores.notifications.addOrReplace(notification);
        DismissNotification.schedule(this.context, { id: notification.id });
      }
    }
  }
}
