import _ from 'lodash';

import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';

export default class CustomerAssigneeService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(orgId, customerId) {
    return this._getCustomerAssignee(orgId, customerId);
  }

  remove(requestorId, orgId, customerId, { correlationId }) {
    _.remove(this.getDatabase(orgId).customerAssignees, { id: customerId });
    this._pubsub.publish(`v1/orgs/${orgId}/customer-assignee/${customerId}/event/delete`);
  }

  update(requestorId, orgId, customerId, { correlationId, payload }) {
    let customerAssignee = this._getCustomerAssignee(orgId, customerId);

    let updatedCustomerAssignee;
    if (customerAssignee) {
      updatedCustomerAssignee = _.merge(customerAssignee, payload);
      updatedCustomerAssignee._version++;
      _.remove(this.getDatabase(orgId).customerAssignees, { id: customerId });
    } else {
      updatedCustomerAssignee = {
        id: payload.id,
        agentId: payload.agentId,
        routingGroupId: payload.routingGroupId,
        _version: 1,
      };
      this.getDatabase(orgId).customerAssignees.push(updatedCustomerAssignee);
    }

    this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/customer-assignee/${customerId}`, {
      correlationId,
      status: 'success',
    });
    this._pubsub.publish(`v1/orgs/${orgId}/customer-assignee/${customerId}`, { payload: updatedCustomerAssignee });
  }

  _getCustomerAssignee(orgId, id) {
    return _.find(this.getDatabase(orgId).customerAssignees, { id });
  }
}
