import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import Layout from './layout';
import ListItem from './list_item';
import Scroller from './scroller';

import debounce from 'components/lib/debounce';

const EXTRA_HEIGHT_FACTOR = 1.5;

export default class List extends React.PureComponent {
  constructor(props) {
    super(props);
    _.bindAll(this, ['handleListScrolled', 'handleHeightChange', 'handleScroll', 'setScrollRef']);

    const { anchorId, anchorPosition, height, layout, ids, stickyIds } = props;

    this.handleListScrolled = debounce(this.handleListScrolled, 150);

    this.stickyIds = stickyIdsById(stickyIds);

    this.isInitialLoad = true;
    this.layout = layout;
    this.scrollTop = this.layout.setContainerHeight(height, 0);
    this.scrollTop = this.layout.setIds(ids, this.getScrollTop());
    this.scrollTop = this.layout.setAnchor(anchorId, anchorPosition, this.getScrollTop());
    const { start, end } = this.layout.getBounds(this.getScrollTop(), this.getScrollTop() + height);

    this.state = {
      start,
      end,
      tops: this.layout.getTops(),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.height !== this.props.height) {
      this.scrollTop = this.layout.setContainerHeight(props.height, this.getScrollTop());
    }
    if (!_.isEqual(props.ids, this.props.ids)) {
      this.scrollTop = this.layout.setIds(props.ids, this.getScrollTop());
    }
    if (
      (!this.props.anchorId && props.anchorId) ||
      props.lastAnchorChangeAt !== this.props.lastAnchorChangeAt ||
      props.anchorPosition !== this.props.anchorPosition
    ) {
      this.scrollTop = this.layout.setAnchor(props.anchorId, props.anchorPosition, this.getScrollTop());
    }
    if (props.width !== this.props.width) {
      this.layout.invalidateHeight();
    }

    this.stickyIds = stickyIdsById(props.stickyIds);

    if (props.decorateScrollTop) {
      this.scrollTop = props.decorateScrollTop(this.layout, this.getScrollTop());
    }

    this.relayout({ isAuto: true });
  }

  componentDidUpdate() {
    if (this.pendingScrollTop != null) {
      const nextScrollTop = Math.round(this.pendingScrollTop);
      this.pendingSetScrollTop = nextScrollTop;
      this.scroller.scrollTop(nextScrollTop);
    }
  }

  componentWillUnmount() {
    this.handleListScrolled.cancel && this.handleListScrolled.cancel();
  }

  render() {
    this.pendingScrollTop = this.scrollTop;
    this.scrollTop = null;

    const contentHeight = this.getContentHeight();
    const scrollerProps = {
      height: this.props.height,
      onScroll: this.handleScroll,
      ref: this.setScrollRef,
      style: this.props.scrollerStyle,
      width: this.props.width,
    };

    return (
      <Scroller {...scrollerProps}>
        <div className="virtualizedList-content" style={{ position: 'relative', height: contentHeight }}>
          {this.renderItems()}
        </div>
      </Scroller>
    );
  }

  renderItems() {
    const ids = this.props.ids;
    const startIndex = this.state.start;
    const endIndex = this.state.end;

    const items = [];

    for (let index = startIndex; index < endIndex; index++) {
      const id = ids[index];

      let stickyId = this.stickyIds[id];
      if (stickyId) {
        items.push(this.renderStickyItem(id, stickyId.nextId, index));
        items.push(this.renderItem(id, index));
      } else {
        id && items.push(this.renderItem(id, index));
      }
    }

    this.prependStickyItem(items);
    return items;
  }

  prependStickyItem(items) {
    const firstStickyItemIndex =
      _.sortedIndexBy(this.props.stickyIds, { index: this.state.start }, stickyItem => stickyItem.index) - 1;
    if (firstStickyItemIndex >= 0) {
      const stickyId = this.props.stickyIds[firstStickyItemIndex];
      items.unshift(this.renderItem(stickyId.id, stickyId.index));
      items.unshift(this.renderStickyItem(stickyId.id, stickyId.nextId, stickyId.index));
    }
  }

  renderItem(id, index, isSticky) {
    const top = this.layout.getTop(id);
    const height = this.layout.getHeight(id);

    const props = {
      height,
      id,
      isHeightValid: this.layout.getHeightValidity(id),
      key: id,
      style: {
        top,
      },
      onHeightChange: this.handleHeightChange,
    };

    return <ListItem {...props}>{this.props.renderItem(index, isSticky)}</ListItem>;
  }

  renderStickyItem(id, nextId, index) {
    const bottom = nextId ? this.layout.getTotalHeight() - this.layout.getTop(nextId) : 0;

    const stickyContainerProps = {
      key: `sticky-${id}`,
      style: {
        top: this.layout.getTop(id),
        bottom,
      },
    };

    return (
      <div className="stickyListItem" {...stickyContainerProps}>
        {this.props.renderItem(index, true)}
      </div>
    );
  }

  handleHeightChange(id, height, isManual) {
    if (!id) return;
    if (Math.abs(height - this.layout.getHeight(id)) < 0.5 && this.layout.getHeightValidity(id)) return;

    this.scrollTop = this.layout.setHeight(id, height, this.getScrollTop(), isManual);
    this.relayout({ isAuto: true });
  }

  handleListScrolled() {
    const scrollTop = this.getScrollTop();
    const visibleRange = this.layout.getBounds(scrollTop, scrollTop + this.props.height - 1);
    this.props.onVisibleRangeChanged({
      isScrolledToBottom: this.isScrolledToBottom(),
      visibleRange,
    });
  }

  handleScroll(evt) {
    const isAutoScroll = evt.target.scrollTop === this.pendingSetScrollTop;
    if (this.pendingSetScrollTop) {
      this.pendingSetScrollTop = false;
    } else {
      this.layout.clearAnchor();
    }

    this.pendingScrollTop = null;

    this.relayout({ isAuto: isAutoScroll });
    this.handleListScrolled();
  }

  getBounds(lowerBounds, upperBounds) {
    return this.layout.getBounds(lowerBounds, upperBounds);
  }

  getContentHeight() {
    return this.layout.getTotalHeight();
  }

  getScrollTop() {
    if (this.scrollTop != null) return this.scrollTop;
    if (this.pendingScrollTop != null) return this.pendingScrollTop;
    if (!this.scroller) return 0;
    return this.scroller.scrollTop();
  }

  isScrolledToBottom() {
    return this.layout.shouldStickToBottom(this.getScrollTop());
  }

  /**
   * `relayout()` recalculates the bounds of items that need to be rendered based on our
   * current scroll position
   *   `isAuto` - whether the trigger was automatic (e.g. we programmatically set the scrolltop) or manual (e.g. agent scrolled)
   */
  relayout({ isAuto }) {
    this.setState(function(prevState, props) {
      const containerHeight = this.layout.getContainerHeight();
      const scrollTop = this.getScrollTop();

      // `extraHeight` is the amount of distance from each end of the visible viewport that we want
      // to want to keep items mounted in.
      //
      // If extraHeightFactor is, say, 2, then we'll have the visible viewport rendered plus 2
      // viewport heights of items on both top and bottom rendered.
      const extraHeight = containerHeight * this.props.extraHeightFactor;
      const lowerBounds = scrollTop - extraHeight;
      const upperBounds = scrollTop + containerHeight + extraHeight;

      const newBounds = this.getBounds(lowerBounds, upperBounds);

      const { start, end } = newBounds;

      const tops = this.layout.getTops();
      if (start === prevState.start && end === prevState.end && tops === prevState.tops) {
        return null;
      }

      this.props.onRenderedRangeChanged({
        isManual: !this.isInitialLoad && !isAuto,
        scrollDirection: this.getScrollDirection(),
        renderedRange: newBounds,
      });

      this.isInitialLoad = false;

      return {
        start,
        end,
        tops,
      };
    });
  }

  getScrollDirection() {
    const scrollTop = this.getScrollTop();
    let scrollDirection;
    if (Math.abs(scrollTop - this.prevScrollTop) < 0.5) {
      scrollDirection = this.prevScrollDirection;
    } else {
      scrollDirection = scrollTop > this.prevScrollTop ? 'DOWN' : 'UP';
    }

    this.prevScrollTop = scrollTop;
    this.prevScrollDirection = scrollDirection;

    return scrollDirection;
  }

  setScrollRef(node) {
    if (node) {
      this.scroller = node;
    }
  }
}

List.defaultProps = {
  extraHeightFactor: EXTRA_HEIGHT_FACTOR,
  stickyItems: [],
};

List.propTypes = {
  anchorId: PropTypes.string, // Id of the item that we should try to "stick" to scrolling wise
  lastAnchorChangeAt: PropTypes.string, // Timestamp of last time anchorId changed

  decorateScrollTop: PropTypes.func,
  extraHeightFactor: PropTypes.number,
  height: PropTypes.number.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  layout: PropTypes.instanceOf(Layout).isRequired,
  onRenderedRangeChanged: PropTypes.func.isRequired, // Rendered range is what everything put in the DOM - isn't necessarily visible
  onVisibleRangeChanged: PropTypes.func.isRequired, // Visible range is in the DOM _and_ within the list viewport
  renderItem: PropTypes.func.isRequired,
  scrollerStyle: PropTypes.object,
  stickyIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      nextId: PropTypes.string,
    })
  ), // Ids of items that should be position "sticky"
  width: PropTypes.number.isRequired,
};

function stickyIdsById(stickyIds) {
  const ids = {};
  for (let i = 0; i < stickyIds.length; i++) {
    const stickyId = stickyIds[i];
    ids[stickyId.id] = stickyId;
  }
  return ids;
}
