import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import Button from 'components/common/button';
import connect from 'components/lib/connect';
import DropdownMenu, { LabelContainer } from 'components/common/dropdown_menu';
import Menu from 'components/common/menu';
import { ReasonType } from 'models/agent_status_reason';
import ReturnFromAway from 'actions/agent_status/return_from_away';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function AwayStatusReasonSelector({ awayReasonId, isIdleReasonPending, isReturning, reasons }) {
  const executeAction = useExecuteAction();
  const [selectedReasonId, setSelectedReasonId] = useState(awayReasonId);

  const onClickContinue = () => {
    if (selectedReasonId) {
      executeAction(ReturnFromAway, { reasonId: selectedReasonId, isReturningFromIdle: true });
    }
  };

  return (
    <React.Fragment>
      <Description>What did you do while you were away?</Description>
      <Row>
        <StyledDropdownMenu
          disabled={isIdleReasonPending || isReturning}
          menuComponent={StyledMenu}
          onSelect={setSelectedReasonId}
          options={reasons}
          placeholder="Select an Away Status"
          position="top"
          searchable
          value={selectedReasonId}
        />
        <Button disabled={isReturning || !selectedReasonId} onClick={onClickContinue}>
          I'm back
        </Button>
      </Row>
    </React.Fragment>
  );
}

function optionsForAgentStatusReasons(reasons) {
  return _(reasons)
    .map(r => ({ value: r.id, label: r.name }))
    .sortBy(option => option.label.toLowerCase())
    .value();
}

AwayStatusReasonSelector.propTypes = {
  awayReasonId: PropTypes.string,
  isIdleReasonPending: PropTypes.bool,
  isReturning: PropTypes.bool,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};

function mapStateToProps({ getProvider }, { awayReasonId }) {
  const reasons = optionsForAgentStatusReasons(
    getProvider('agentStatusReasons').findAll({
      filter: r => !r.disabled && r.type === ReasonType.AWAY,
    })
  );

  const pendingStatus = getProvider('agentStatus').getPending();
  const pendingAwayReasonId = _.get(pendingStatus, 'status.reasonId');
  const displayedAwayReasonId = pendingAwayReasonId ? pendingAwayReasonId : awayReasonId;

  const isIdleReasonPending = !!_(pendingStatus).get('status.activeReasonId');

  return { awayReasonId: displayedAwayReasonId, isIdleReasonPending, reasons };
}

const AwayStatusReasonSelectorContainer = connect(mapStateToProps)(AwayStatusReasonSelector);
export default AwayStatusReasonSelectorContainer;

const StyledMenu = styled(Menu)`
  width: 200px;
  z-index: 21;

  ${LabelContainer} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-right: 16px;
  width: 200px;
`;

const Description = styled.p``;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
