import { Factory } from 'rosie';

import '../conversation_item_factory';

Factory.define('TaskEditedContent')
  .attr('type', 'TASK_EDITED');

Factory.define('TaskEditedContentWithDefaults')
  .extend('TaskEditedContent')
  .attr('taskItemId', 'id-123');

Factory.define('TaskEditedContentItem')
  .extend('ConversationItem')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskEditedContent', content);
  });

Factory.define('TaskEditedContentItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], function(content) {
    return Factory.attributes('TaskEditedContentWithDefaults', content);
  });