import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorTooltip extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['handleBlur', 'handleFocus']);
    this.state = { isFocused: false };
  }

  render() {
    let child = this.props.children;
    if (!child) {
      return child;
    }

    if (_.isEmpty(this.props.errors)) {
      return React.cloneElement(child, { className: child.props.className, ...this.focusProps });
    }

    let className = classnames(child.props.className, this.props.className, 'simptip-position-bottom', 'errorTooltip', {
      'tooltip-active': this.state.isFocused,
    });

    return React.cloneElement(child, {
      className,
      'data-tooltip': this.props.errors.join('; '),
      ...this.focusProps,
    });
  }

  get focusProps() {
    return { onBlur: this.handleBlur, onFocus: this.handleFocus };
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  handleFocus() {
    this.setState({ isFocused: true });
  }
}

ErrorTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string), // Array of error strings
};
