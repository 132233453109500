import CloseConversation from 'actions/conversation/close_conversation';
import CloseConversationWarning from 'models/modal/close_conversation_warning';
import Conversation from 'models/conversation';
import { getConversationFlags } from 'actions/conversation/lib/conversation_action_helpers';
import OpenModal from 'actions/modal/open_modal';

export default class TryClosingConversation {
  constructor(context) {
    this.context = context;
  }

  run({ conversationId, customerId }) {
    const { hasActiveCall, hasSla, needsTopics, status } = getConversationFlags(this.context, {
      conversationId,
      customerId,
    });

    if (status === Conversation.Status.CLOSED || hasActiveCall || needsTopics) {
      return;
    }

    hasSla
      ? this.context.executeAction(OpenModal, CloseConversationWarning.create({ conversationId, customerId }))
      : this.context.executeAction(CloseConversation, { conversationId, customerId });
  }
}
