import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import { COMPOSER_BUTTONS_CLASS } from 'components/customer/workflow_buttons';
import Portal from 'components/common/utilities/portal';

export default function CompositionButtons({ children }) {
  const node = document && document.getElementsByClassName(COMPOSER_BUTTONS_CLASS)[0];

  return (
    <Portal node={node}>
      <div className="compositionForm-formButtons">{children}</div>
    </Portal>
  );
}

export function CancelButton({ onClick, label, disabled }) {
  return (
    <StyledCancel
      buttonType={ButtonTypes.TEXT}
      data-aid="cancel-composition"
      direction="right"
      disabled={disabled}
      inline="small"
      onClick={onClick}
    >
      {label || 'Cancel'}
    </StyledCancel>
  );
}

const StyledCancel = styled(Button)`
  margin-right: 16px;
`;

CompositionButtons.propTypes = {
  children: PropTypes.node,
};
