import {
  CONTEXT_ANSWER,
  getAnswerContextFromAnswer,
} from 'scripts/lib/analytics/domain/snowplow/contexts/answer_context';
import {
  CONTEXT_COMPOSITION,
  getCompositionContextFromComposition,
} from 'scripts/lib/analytics/domain/snowplow/contexts/composition_context';
import { CONTEXT_SEARCH, getSearchContext } from 'scripts/lib/analytics/domain/snowplow/contexts/search_context';
import {
  CONTEXT_SEARCH_RESULT,
  getSearchResultContexts,
} from 'scripts/lib/analytics/domain/snowplow/contexts/search_result_context';
import { CONTEXT_CONTACT, getContactContext } from 'scripts/lib/analytics/domain/snowplow/contexts/contact_context';
import {
  CONTEXT_CONVERSATION,
  getConversationContext,
} from 'scripts/lib/analytics/domain/snowplow/contexts/conversation_context';
import {
  CONTEXT_SUGGESTED_REPLY,
  getSuggestedReplyContext,
} from 'scripts/lib/analytics/domain/snowplow/contexts/suggested_reply_context';

/**
 * Map of the Snowplow context & event definitions
 *
 * See https://github.com/sagansystems/snowplow-schemas for the actual schema definitions
 */
const snowplowTypes = {
  CONTEXT_ANSWER,
  CONTEXT_COMPOSITION,
  CONTEXT_SEARCH,
  CONTEXT_SEARCH_RESULT,

  CONTEXT_GLADLY: 'iglu:com.gladly/context_gladly/jsonschema/1-0-0',
  CONTEXT_CONTACT,
  CONTEXT_CONVERSATION,

  CONTEXT_SUGGESTED_REPLY,

  EVENT_ANSWER_USED: 'iglu:com.gladly.agent_desktop/event_answer_used/jsonschema/1-0-4',
  EVENT_ANSWER_SEARCHED: 'iglu:com.gladly.agent_desktop/event_answer_searched/jsonschema/1-0-0',
  EVENT_COMPONENT_CLICKED: 'iglu:com.gladly.agent_desktop/event_component_clicked/jsonschema/1-0-0',

  EVENT_COBROWSING_SESSION_STARTED: 'iglu:com.gladly.agent_desktop/event_cobrowse_session_started/jsonschema/1-0-1',
  EVENT_COBROWSING_SESSION_ENDED: 'iglu:com.gladly.agent_desktop/event_cobrowse_session_ended/jsonschema/1-0-1',

  EVENT_SUGGESTED_REPLY_REQUESTED: 'iglu:com.gladly.agent_desktop/event_suggested_reply_requested/jsonschema/1-0-0',
  EVENT_SUGGESTED_REPLY_RESPONSE_RECEIVED:
    'iglu:com.gladly.agent_desktop/event_suggested_reply_response_received/jsonschema/1-0-0',
  EVENT_SUGGESTED_REPLY_USED: 'iglu:com.gladly.agent_desktop/event_suggested_reply_used/jsonschema/1-0-0',
  EVENT_SUGGESTED_REPLY_SENT: 'iglu:com.gladly.agent_desktop/event_suggested_reply_sent/jsonschema/1-0-1',
};

export {
  getAnswerContextFromAnswer,
  getCompositionContextFromComposition,
  getContactContext,
  getConversationContext,
  getSearchContext,
  getSearchResultContexts,
  getSuggestedReplyContext,
  snowplowTypes,
};
