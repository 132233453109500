import React from 'react';

import SlateInlineImage from 'components/customer/composition/lib/slate/slate_inline_image';

export default function InlineImagesPlugin() {
  return {
    commands: { insertImage },
    queries: { getImageBlocks },

    renderNode(props, editor, next) {
      const { attributes, node, isFocused } = props;
      switch (node.type) {
        case 'image': {
          const src = node.get('data').get('src');
          const attachmentId = node.get('data').get('attachmentId');
          return <SlateInlineImage {...attributes} attachmentId={attachmentId} isFocused={isFocused} src={src} />;
        }
        default:
          return next();
      }
    },

    schema: {
      blocks: {
        image: {
          // https://docs.slatejs.org/slate-core/schema#isvoid
          isVoid: true,
        },
      },
    },
  };
}

export function insertImage(editor, attrs, target) {
  const { src, attachmentId } = attrs;
  if (target) {
    editor.select(target);
  }

  editor.insertBlock({ type: 'image', data: { src, attachmentId } });
  // extra line afterwards
  editor.insertBlock({ type: 'paragraph' });
  return editor;
}

export function getImageBlocks(editor) {
  return editor.value.document.getBlocksByType('image');
}
