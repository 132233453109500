import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import connect from 'components/lib/connect';
import LoadingDots from 'components/lib/loading_dots';
import NavigateToUrl from 'actions/current_location/navigate_to_url';
import Toast from 'components/common/toast_deprecated';
import { ToastCategory, ToastType } from 'models/toast_deprecated';
import { useExecuteAction } from 'components/hooks/connect_hooks';

function Toasts({ toasts }) {
  return (
    <React.Fragment>
      {_.map(toasts, toast => (
        <ExtendedToast key={toast.id} {...toast} />
      ))}
    </React.Fragment>
  );
}

export function ExtendedToast({ link, message, category, ...otherProps }) {
  const executeAction = useExecuteAction();
  const onClick = useCallback(() => link && executeAction(NavigateToUrl, link), [executeAction, link]);
  const activityIndicator =
    category === ToastCategory.ACTIVITY_BANNER ? <ToastActivityIndicator className="activity-indicator" /> : null;

  return (
    <Toast category={category} onClick={onClick} {...otherProps}>
      <ToastMessageWrapper>
        {message}
        {activityIndicator}
      </ToastMessageWrapper>
    </Toast>
  );
}

export const ToastActivityIndicator = styled(LoadingDots)`
  margin-left: 16px;
  position: relative;
  top: 1px;
`;

const ToastMessageWrapper = styled.div`
  display: flex;
`;

ExtendedToast.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string,
  message: PropTypes.string,
  persistent: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ToastType)).isRequired,
  link: PropTypes.string,
  visible: PropTypes.bool,
};

function mapStateToProps({ getProvider }, { category }) {
  const allToasts = getProvider('toastsDeprecated').findAll();
  const toastsToDisplay = category ? _.filter(allToasts, toast => toast.category === category) : allToasts;
  return {
    toasts: toastsToDisplay,
  };
}

export const ToastsContainer = connect(mapStateToProps)(Toasts);

export default Toasts;
