import PropTypes from 'prop-types';
import React from 'react';

import ConversationNotification from 'models/notification/conversation_notification';
import { formatPhoneNumber } from 'models/phone_number';
import NotificationContentContainer from './notification_content_container';

function AbandonedCallNotification({ notification }) {
  const heading = notification.profile.name || formatPhoneNumber(notification.conversationItem.content.customerNumber);

  return (
    <NotificationContentContainer
      heading={heading}
      iconClass="icon-phone-filled"
      itemType="Abandoned call"
      notification={notification}
    />
  );
}

AbandonedCallNotification.propTypes = {
  notification: PropTypes.instanceOf(ConversationNotification).isRequired,
};

export default AbandonedCallNotification;
