import _ from 'lodash';
import analytics from 'scripts/lib/analytics';
import assign from 'lodash/assign';
import AgentActivity from 'models/agent_activity';
import { AgentAvailability } from 'models/agent_status';
import CloseModal from 'actions/modal/close_modal';
import { handleErrorInteractively, setBusyPendingWithReason, updateStore } from 'actions/agent_status/lib/agent_status';
import { logStatusActivity } from 'actions/agent_status/lib/agent_activity';
import { ReasonType } from 'models/agent_status_reason';
import SelectIdleReason from 'actions/agent_status/select_idle_reason';
import startAgentPolling from 'actions/configuration/lib/start_agent_polling';

export default class ReturnFromAway {
  constructor(context) {
    this.context = context;
  }

  run({ reasonId } = {}) {
    if (this.store.get().getAvailability() !== AgentAvailability.AWAY) {
      return;
    }

    let newStatus = assign({ availability: AgentAvailability.BUSY }, reasonId && { awayReasonId: reasonId });

    const defaultReason = this.context.stores.agentStatusReasons.findBy({
      filter: r => r.default && r.type === ReasonType.ACTIVE,
    });
    newStatus = assign(newStatus, { activeReasonId: defaultReason.id });
    setBusyPendingWithReason(this.context, defaultReason.id);

    const isReturningFromIdle = !_(this.store.get()).get('status.reasonId');

    this.context.gateways.agentStatus
      .update(newStatus)
      .then(response => {
        this.handleSuccess(response);
        isReturningFromIdle && this.context.executeAction(CloseModal);
      })
      .catch(err => {
        handleErrorInteractively(this.context, err);
        isReturningFromIdle && this.context.executeAction(SelectIdleReason);
      });

    logStatusActivity(this.context, AgentActivity.Type.BUSY);

    if (this.isInternalAgentActionsEnabled()) {
      startAgentPolling(this.context);
    }
  }

  isInternalAgentActionsEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('internalAgentActions');
  }

  handleSuccess(response) {
    this.store.resetPending();
    updateStore(this.context, response);

    if (this.store.get().getAvailability() !== AgentAvailability.AWAY) {
      this.context.capacityManager.start();
    }
    analytics.track('Returned from Away');
  }

  get store() {
    return this.context.stores.agentStatus;
  }
}
