import React from 'react';
import PropTypes from 'prop-types';

import CallParticipant from 'components/phone_controls/participants/call_participant';
import connect from 'components/lib/connect';
import PhoneCall, { TransferStatus } from '../../../../domain/models/phone_call';

export const AgentParticipant = ({ agentParticipant, agentName }) => (
  <CallParticipant
    displayName={agentName}
    key={agentParticipant.participantId}
    participantId={agentParticipant.participantId}
    participantStatus={agentParticipant.status}
    showMenuOnHover
  />
);

AgentParticipant.propTypes = {
  agentName: PropTypes.string,
  agentParticipant: PropTypes.shape({
    callId: PropTypes.string,
    participantId: PropTypes.string,
    status: PropTypes.string,
  }),
};

function mapStateToProps({ getProvider }, { agentId }) {
  let activeCall = getProvider('activeCall').get();
  let agent = getProvider('agents').findBy({ id: agentId });
  let agentName = agent.name || agent.email;
  let phoneCall = activeCall.conversationItem.content;
  let agentParticipant = phoneCall.findParticipantById(agentId);
  // Simulare that we are dialing the observer an observer participant before the observer
  // accepted the transfer
  if (
    agentParticipant.type === PhoneCall.ParticipantType.OBSERVER &&
    phoneCall.getTransferStatus() === TransferStatus.INITIATED &&
    agentId === phoneCall.transfer.toParticipantId
  ) {
    agentParticipant.status = PhoneCall.ParticipantStatus.DIALING;
  }
  return {
    agentName,
    agentParticipant,
  };
}

export default connect(mapStateToProps, null)(AgentParticipant);
