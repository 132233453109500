import _ from 'lodash';
import moment from 'moment';

import Assignee from './assignee';
import Attachment from './attachment';
import createModel from './lib/create_model';
import htmlToText from './conversation_item/html_to_text';
import Mention from './mention';
import redactAttachment from './attachment_redact';

const Task = createModel({
  modelName: 'Task',

  properties: {
    assignee: Assignee,
    attachments: [Attachment],
    bodyHtml: String,
    closedAt: String,
    dueAt: String,
    hasNotifiedDue: Boolean,
    mentions: [Mention],
    note: String,
    updatedAt: String,
  },

  isClosed() {
    return !!this.closedAt;
  },

  markClosed() {
    let time = moment().toISOString();
    this.closedAt = time;
    this.updatedAt = time;
  },

  markAsOpen() {
    this.closedAt = null;
    this.updatedAt = moment().toISOString();
  },

  assign(assignee) {
    this.assignee = assignee;
    this.updatedAt = moment().toISOString();
  },

  redactAttachment(id) {
    redactAttachment(this.attachments, id);
  },

  getMessageText() {
    return this.note;
  },

  statics: {
    create(attrs) {
      if (!attrs.note) {
        attrs = {
          ...attrs,
          note: htmlToText(attrs.bodyHtml),
        };
      }

      return new this(
        _.assign(
          {
            updatedAt: moment().toISOString(),
            hasNotifiedDue: !moment().isBefore(attrs.dueAt),
          },
          attrs
        )
      );
    },
  },
});

export default Task;
