import createModel, { prop } from 'models/lib/create_model';

const ExternalDataObjectEnvelope = createModel({
  modelName: 'ExternalDataObjectEnvelope',

  properties: {
    id: prop(String).isRequired,
    data: prop(Object).isRequired,

    // Attributes used for calculating the `id` and re-running the requests
    parentEntityType: prop(String),
    parentEntityId: prop(String),
    requestorId: prop(String),
    namespace: prop(String).isRequired,

    // Additional search parameters (such as page, pageSize etc.) that are needed for re-running the query
    extraParams: prop(Object).default({}),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    ParentEntityType: Object.freeze({
      CUSTOMER: 'CUSTOMER',
    }),
  },
});

export default ExternalDataObjectEnvelope;
