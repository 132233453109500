import _ from 'lodash';

import Tweet from 'models/tweet';
import CommunicationSimulator from './communication_simulator';
import Conversation from 'models/conversation';
import Communicator from 'models/communicator';
import CustomerConverter from 'scripts/application/dto_converters/customer_converter';
import Factory from 'factories/all';

export default class CustomerTweetSimulator extends CommunicationSimulator {
  constructor({ orgId, publisher, services }, database) {
    super({ attribute: 'incomingTweets', createItem: createIncomingTweet, orgId, publisher, services }, database);
  }

  simulate(communication) {
    var customerId = communication.customerId;
    var customerDto = _.find(this.database.get().customers, { id: customerId });
    communication.user = { screenName: customerDto.profile.twitter.slice(1) };
    super.simulate(communication);
  }

  _findConversation(communication, customer) {
    let conversation = this._findConversationByCustomerId(customer);
    if (conversation) {
      conversation.status = Conversation.Status.OPEN;
    }
    return conversation;
  }

  _findConversationByCustomerId(customerDto) {
    let customer = CustomerConverter.fromDto(customerDto);
    let item = customer.conversationHistory.find(i => i.content instanceof Tweet);
    let conversation = _.find(customer.conversations, c => item.conversationId === c.id);
    return conversation ? _.find(customerDto.conversations, { id: conversation.id }) : null;
  }
}

export function createIncomingTweet(incomingTweet, agent) {
  return Factory.build('TwitterItem', {
    content: {
      user: incomingTweet.user,
      text: incomingTweet.message,
    },
    initiator: {
      type: Communicator.CUSTOMER,
      id: incomingTweet.customerId,
    },
  });
}
