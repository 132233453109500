import { Factory } from 'rosie';

import './customer_profile_factory';
import './conversation_factory';
import './conversation_items/email_preview_factory';

Factory.define('ConversationPreview');

Factory.define('ConversationPreviewWithDefaults')
  .attr('title', 'default title')
  .attr('customer', () => Factory.attributes('CustomerProfileWithDefaults'))
  .attr('conversation', () => Factory.attributes('ConversationWithDefaults'))
  .attr('conversationItem', () => Factory.attributes('EmailPreviewItemWithDefaults'))
  .attr('taskCount', 3)
  .attr('readTo', new Date().toISOString());
