import createReactClass from 'create-react-class';
import Morearty from 'morearty';
import React from 'react';

import ActionMixin from './lib/action_mixin';
import CustomerCombineContainer from './customer_combine/customer_combine_container';
import PageLayout from './page_layout';
import ProvidersMixin from './lib/providers_mixin';
import SharedBindingsMixin from './lib/shared_bindings_mixin';

const CustomerCombinePage = createReactClass({
  mixins: [ActionMixin, Morearty.Mixin, ProvidersMixin, SharedBindingsMixin],

  UNSAFE_componentWillMount() {
    this.observeBinding(this.getSharedBinding('currentLocation'));
    this.observeBinding(this.getSharedBinding('customers'));
  },

  render() {
    const customerId = this.getCustomerId();

    return (
      <PageLayout className="flex-col-container" id="customer-combine-page">
        <div className="customerCombine-container flex-col-flex">
          <CustomerCombineContainer customerId={customerId} />
        </div>
      </PageLayout>
    );
  },

  getCustomerId() {
    return this.getProvider('currentLocation').get().customerId;
  },

  getConversationId() {
    return this.getProvider('currentLocation').get().currentConversationId;
  },
});

export default CustomerCombinePage;
