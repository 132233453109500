import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { ContentState, EditorState, Modifier } from 'draft-js';

import { findEntities } from './draft_entity';
import { getCurrentMention } from './draft_mention';
import { ensureFullSelection, updateEntities, IMMUTABLE } from './draft_shared';

const DraftVariable = props => {
  const contentState = props.contentState;
  const data = contentState.getEntity(props.entityKey).getData();
  const classNames = classnames('draftEditor-variable', { 'draftEditor-emptyVariable': data.isDisabled });
  return <span className={classNames}>{props.children}</span>;
};

DraftVariable.propTypes = {
  contentState: PropTypes.instanceOf(ContentState),
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default DraftVariable;

export const ENTITY_TYPE_VARIABLE = 'VARIABLE';
export const findVariableEntities = findEntities(ENTITY_TYPE_VARIABLE);

function getVariableText(variable) {
  return variable.isDisabled ? `Add ${variable.name}` : variable.text;
}

export function insertVariable(editorState, variable) {
  const { start, end } = getCurrentMention(editorState);

  // TODO ANSWERSV2: currently search is not enabled, so the search text before
  // selection will always be null. Until then, commenting out the below

  // const { start, end, text } = getCurrentMention(editorState);
  // if (text === null) {
  //   return editorState;
  // }

  const mentionSelection = editorState.getSelection().merge({
    anchorOffset: start,
    focusOffset: end,
  });
  return insertVariableAtSelection(editorState, variable, mentionSelection);
}

export function insertVariableAtSelection(editorState, variable, selection) {
  let contentState = editorState.getCurrentContent();
  let contentStateWithEntity = contentState.createEntity(ENTITY_TYPE_VARIABLE, IMMUTABLE, {
    id: variable.id,
    name: variable.name,
    isDisabled: variable.isDisabled,
  });
  let varText = getVariableText(variable);

  contentStateWithEntity = Modifier.replaceText(
    contentStateWithEntity,
    selection,
    varText,
    null,
    contentStateWithEntity.getLastCreatedEntityKey()
  );

  return EditorState.push(editorState, contentStateWithEntity, 'insert-fragment');
}

/**
 * updateVariables() iterates through every VARIABLE entity in editorState and updates it with the new
 * variable value if it is different.
 */
export function updateVariables(editorState, variables) {
  return updateEntities(editorState, ENTITY_TYPE_VARIABLE, variables, findRangesNeedingUpdate);
}

export function ensureFullVariableSelection(editorState) {
  return ensureFullSelection(editorState, ENTITY_TYPE_VARIABLE, findVariableEntities);
}

function findRangesNeedingUpdate(contentState, block, variables) {
  let updateRanges = [];

  let updateVariable = (start, end) => {
    const entityKey = block.getEntityAt(start);
    const newVar = { ...variables[contentState.getEntity(entityKey).getData().id] };
    newVar.text = getVariableText(newVar);
    const currentVarText = block.getText().slice(start, end);

    if (newVar.text !== currentVarText) {
      updateRanges.push({ start, end, variable: newVar, entityKey, blockKey: block.getKey() });
    }
  };
  findVariableEntities(block, updateVariable, contentState);

  return updateRanges;
}
