import React from 'react';

import {
  getIndentLevelFromMargin,
  getMarginFromIndent,
} from 'components/customer/composition/lib/slate/slate_text_indentation_menu';
import { QUOTEBLOCK } from '../../plugins/quote_blocks';

export const richTextRules = [
  // Font rules

  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'strong' || el.tagName.toLowerCase() === 'b') {
        return { object: 'mark', type: 'bold', nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'bold') {
        return <strong>{children}</strong>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'i' || el.tagName.toLowerCase() === 'em') {
        return { object: 'mark', type: 'italic', nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'italic') {
        return <i>{children}</i>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'u') {
        return { object: 'mark', type: 'underline', nodes: next(el.childNodes) };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'underline') {
        return <u>{children}</u>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'font') {
        const fontSize = el.size;
        const fontColor = el.color;
        return { object: 'mark', type: 'font', nodes: next(el.childNodes), data: { fontSize, fontColor } };
      }
    },
    serialize(object, children) {
      if (object.object === 'mark' && object.type === 'font') {
        const fontSize = object.data.get('fontSize');
        const fontColor = object.data.get('fontColor');
        let props = {};
        if (fontSize) props.size = fontSize;
        if (fontColor) props.color = fontColor;
        return <font {...props}>{children}</font>;
      }
    },
  },

  // Link rules

  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'a') {
        const href = el.href;
        const target = el.target;

        return { object: 'inline', type: 'link', nodes: next(el.childNodes), data: { href, target } };
      }
    },
    serialize(object, children) {
      if (object.object === 'inline' && object.type === 'link') {
        const href = object.data.get('href');
        const target = object.data.get('target');
        const linkTarget = ['_blank', '_self'].includes(target || '') ? target : '_blank';

        return (
          <a href={href} rel="noopener noreferrer" target={linkTarget}>
            {children}
          </a>
        );
      }
    },
  },

  // List rules

  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'ul') {
        const indent = getIndentLevelFromMargin(el.style.marginLeft);
        return { object: 'block', type: 'unordered_list', nodes: next(el.childNodes), data: { indent } };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'unordered_list') {
        const marginLeft = getMarginFromIndent(object.data.get('indent'));
        return <ul style={{ marginLeft }}>{children}</ul>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'ol') {
        const indent = getIndentLevelFromMargin(el.style.marginLeft);
        return { object: 'block', type: 'ordered_list', nodes: next(el.childNodes), data: { indent } };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'ordered_list') {
        const marginLeft = getMarginFromIndent(object.data.get('indent'));
        return <ol style={{ marginLeft }}>{children}</ol>;
      }
    },
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'li') {
        const textAlign = el.style.textAlign;
        return { object: 'block', type: 'list_item', nodes: next(el.childNodes), data: { textAlign } };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === 'list_item') {
        const textAlign = object.data.get('textAlign');
        const style = textAlign ? { textAlign } : undefined;
        return <li style={style}>{children}</li>;
      }
    },
  },

  // Quote Block rules

  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'blockquote') {
        const indent = getIndentLevelFromMargin(el.style.marginLeft);
        return { object: 'block', type: QUOTEBLOCK, nodes: next(el.childNodes), data: { indent } };
      }
    },
    serialize(object, children) {
      if (object.object === 'block' && object.type === QUOTEBLOCK) {
        const blockQuoteStyles = { borderLeft: '4px solid #cecece', margin: '0px', paddingLeft: '8px' };
        const marginLeft = getMarginFromIndent(object.data.get('indent'));
        return <blockquote style={{ ...blockQuoteStyles, marginLeft }}>{children}</blockquote>;
      }
    },
  },
];
