import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class MessagingConfigurationService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  find(compAttrs, callback, path, { orgId }) {
    let config = this._getConfig(orgId);

    callback(null, { status: 200, statusText: statusText(200), response: config });
  }

  replace(payload, callback, path, { orgId }) {
    let config = this._getConfig(orgId);
    _.assign(config, payload);

    callback(null, { status: 204, statusText: statusText(204) });
  }

  _getConfig(orgId) {
    return this.getDatabase(orgId).messagingConfiguration;
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/messaging': {
          GET: this.find,
          PUT: this.replace,
        },
      },
      this
    );
  }
}
