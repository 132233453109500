import _ from 'lodash';
import moment from 'moment';

import { AgentAvailability } from 'models/agent_status';
import AgentPhonePreferences from 'models/agent_phone_preferences';
import AgentVoiceCapabilitiesChecker from 'actions/configuration/lib/agent_voice_capabilities_checker';
import Endpoint from 'models/endpoint';
import { forceUpdateAgentRoutingPreferences } from 'actions/routing/lib/agent_routing_preferences';
import { getNextAssignmentForChannel } from 'actions/conversation/lib/get_next_assignment_for_channel';
import { getOfferedSessionForAgent } from 'actions/communication_session/session_finder';
import HideOptionalReloadNotification from 'actions/notification/hide_optional_reload_notification';
import { ReloadReason } from 'models/notification/optional_reload_notification';
import { RoutingChannel } from 'models/agent_routing_preferences';
import { routingChannelForInteractionType } from 'models/interaction_type';
import ShowOptionalReloadNotification from 'actions/notification/show_optional_reload_notification';
import UpdateBrowserPhoneStatus from 'actions/health/lib/update_browser_phone_status';

export const CAPACITY_MANAGER_STARTUP_DELAY_SECONDS = 10;

export default class CapacityManager {
  constructor(context) {
    this.context = context;
    // Add some initial buffer to delay initial routing
    this._nextCallRoutingTime = moment().add(CAPACITY_MANAGER_STARTUP_DELAY_SECONDS, 'seconds');
    this._nextMessagingRoutingTime = moment().add(CAPACITY_MANAGER_STARTUP_DELAY_SECONDS, 'seconds');

    this.agentVoiceCapabilitiesChecker = new AgentVoiceCapabilitiesChecker(this.context);
  }

  start() {
    clearInterval(this.capacityInterval);
    this.capacityInterval = setInterval(() => {
      this.run();
    }, 5000);
  }

  stop() {
    clearInterval(this.capacityInterval);
  }

  run() {
    const agentStatus = this.context.stores.agentStatus.get();
    if (!agentStatus || agentStatus.getAvailability() === AgentAvailability.AWAY) {
      return;
    }

    if (this.isAgentOnCall()) {
      // Reset browser phone unavailabile check if the agent has an active call
      this.context.executeAction(HideOptionalReloadNotification);
      return;
    }

    if (this.isAgentConfiguredForBrowserCalls()) {
      this.updateBrowserPhoneStatus();
    }

    if (this.isPreferredAndUnableToHandleCalls()) {
      forceUpdateAgentRoutingPreferences(this.context, { [RoutingChannel.VOICE]: false });
      if (this.isAgentConfiguredForBrowserCalls()) {
        this._createReloadNotificaiton(ReloadReason.PHONE_NOT_AVAILABLE);
      }
      return;
    }

    if (this.agentRoutingPreferences.isFocusOn || this.pendingAgentRoutingPreferences?.isFocusOn) {
      return;
    }

    if (this._shouldRouteCall()) {
      getNextAssignmentForChannel(this.context, RoutingChannel.VOICE);
    } else if (this._shouldRouteMessaging()) {
      getNextAssignmentForChannel(this.context, RoutingChannel.MESSAGING);
    }
  }

  isTimeToRouteCall() {
    return this._nextCallRoutingTime.isBefore(moment());
  }

  isTimeToRouteMessaging() {
    return this._nextMessagingRoutingTime.isBefore(moment());
  }

  postponeCallRouting(delay = _.get(this.voiceConfiguration, 'declineCallAutoReadyIntervalSecs')) {
    this._nextCallRoutingTime = moment().add(delay, 'seconds');
  }

  postponeMessagingRouting(delay = _.get(this.messagingConfiguration, 'routeNextMessagingSessionIntervalSecs')) {
    this._nextMessagingRoutingTime = moment().add(delay, 'seconds');
  }

  startCallWrapup() {
    this._nextCallRoutingTime = moment().add(this.voiceConfiguration.callWrapUpIntervalSecs, 'seconds');

    // update agent routing preferences for showing the next call timer
    const routingPreferences = this.context.stores.agentRoutingPreferences.get();
    routingPreferences.setNextCallRoutingTime(this._nextCallRoutingTime.toISOString());
    this.context.stores.agentRoutingPreferences.set(routingPreferences);

    // postpone messaging routing because otherwise the second you hang up you are routed a messaging type
    this.postponeMessagingRouting();
  }

  _shouldRouteCall() {
    // Check if we have a VOICE endpoint configured
    if (!this._isChannelEnabled(Endpoint.Type.VOICE)) {
      return false;
    }

    // Checks if it's time to route another call
    if (!this.isTimeToRouteCall()) {
      return false;
    }

    // Check if the agent is preferred for phone calls
    if (!this.isPreferredOnChannel(RoutingChannel.VOICE)) {
      return false;
    }

    // Check if the agent is already available for phone calls
    if (this.isReadyForChannel(RoutingChannel.VOICE)) {
      return false;
    }

    // Check if the agent currently has an active call
    return !this.isAgentOnCall();
  }

  _shouldRouteMessaging() {
    // Checks if it's time to route another messaging type
    if (!this.isTimeToRouteMessaging()) {
      return false;
    }

    // Check if the agent is preferred for messaging
    if (!this.isPreferredOnChannel(RoutingChannel.MESSAGING)) {
      return false;
    }

    // Check if the agent is currently being offered a messaging type
    if (this._hasOffering(this.currentAgent.id)) {
      return false;
    }

    // Check if the agent is already available for messaging
    if (this.isReadyForChannel(RoutingChannel.MESSAGING)) {
      return false;
    }

    // Check if the agent is currently below the messaging capacity
    return this._hasCapacity(RoutingChannel.MESSAGING);
  }

  _hasCapacity(channel) {
    return this._getNumAssignedSessions(channel) < _.get(this.messagingConfiguration, 'agentCapacity');
  }

  _hasOffering(agentId) {
    return getOfferedSessionForAgent(this.context.stores.activeSessions, agentId);
  }

  isPreferredAndUnableToHandleCalls() {
    if (this.isAgentConfiguredForBrowserCalls()) {
      /* Use lenient unavailability checks for browser voice agents as it's expected that the browser phone device will intermittently be unavailable while fetching a new capability token
       */
      if (this.isBrowserUnavailableForCalls()) {
        return false;
      }

      this.context.executeAction(HideOptionalReloadNotification);
    }

    return false;
  }

  canAgentHandleCalls() {
    return this.agentVoiceCapabilitiesChecker.canHandlePhoneCalls();
  }

  isBrowserUnavailableForCalls() {
    let connectionState = this.context.stores.connectionState.get();
    return connectionState.isBrowserPhoneUnavailable();
  }

  /*
  The browser phone status is updated by the phone gateway. However, to handle cases when the phone gateway misses an update or if the device status ends up in an unexpected state, we can periodically check the status of the phone and update the status accordingly.
  */
  updateBrowserPhoneStatus() {
    this.context.executeAction(UpdateBrowserPhoneStatus);
  }

  isAgentConfiguredForBrowserCalls() {
    if (!this.currentAgent || !this.currentAgent.voiceConfiguration) {
      return false;
    }
    let currentAgentVoicePreference = this.currentAgent.voiceConfiguration.preference;
    return (
      currentAgentVoicePreference === AgentPhonePreferences.BROWSER ||
      currentAgentVoicePreference === AgentPhonePreferences.NONE
    );
  }

  isAgentOnCall() {
    return !!this.context.stores.activeCall.get();
  }

  isReadyForChannel(channel) {
    return this.agentStatus.getReadyChannels().includes(channel);
  }

  _isChannelEnabled(channel) {
    return this.channelConfiguration && this.channelConfiguration.isChannelEnabled(channel);
  }

  isPreferredOnChannel(channel) {
    return this.agentRoutingPreferences.isPreferredOnChannel(channel);
  }

  isAgentAtMaxMessagingCapacity() {
    return (
      this._getNumAssignedSessions(RoutingChannel.MESSAGING) >= _.get(this.messagingConfiguration, 'agentMaxCapacity')
    );
  }

  _createReloadNotificaiton(reason) {
    this.context.executeAction(ShowOptionalReloadNotification, reason);
  }

  _getAssignedSessions(channelType) {
    if (channelType !== RoutingChannel.MAIL && channelType !== RoutingChannel.MESSAGING) {
      // unsupported channel type, so there won't be any assigned sessions
      return [];
    }

    return _.filter(this.context.stores.activeSessions.findAll(), session => {
      let assignedToCurrentAgent = _.get(session, 'queueItem.assignee.agentId') === this.currentAgent.id;
      if (!assignedToCurrentAgent) {
        return false;
      }

      return _.some(
        _.get(session, 'queueItem.sessions'),
        s => routingChannelForInteractionType(s.type) === channelType
      );
    });
  }

  _getNumAssignedSessions(channel) {
    return this._getAssignedSessions(channel).length;
  }

  get agentRoutingPreferences() {
    return this.context.stores.agentRoutingPreferences.get();
  }

  get agentStatus() {
    return this.context.stores.agentStatus.get();
  }

  get channelConfiguration() {
    return this.context.stores.channelConfiguration.get();
  }

  get currentAgent() {
    return this.context.stores.currentAgent.get();
  }

  get messagingConfiguration() {
    return this.context.stores.messagingConfiguration.get();
  }

  get pendingAgentRoutingPreferences() {
    return this.context.stores.agentRoutingPreferences.getPending();
  }

  get voiceConfiguration() {
    return this.context.stores.voiceConfiguration.get();
  }
}
