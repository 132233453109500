import React from 'react';
import PropTypes from 'prop-types';

import AcceptCommunicationSession from 'actions/communication_session/accept_communication_session';
import { baseInteractionType, customId } from 'models/interaction_type';
import Button, { ButtonTypes } from 'components/common/button';
import ChatIconOutline from 'components/lib/icons/chat_icon_outline';
import connect from 'components/lib/connect';
import DeclineCommunicationSession from 'actions/communication_session/decline_communication_session';
import { formatPhoneNumber } from 'models/phone_number';
import { getBrandIconByName } from 'components/common/custom_channels';
import { getOfferedSessionForAgent } from 'actions/communication_session/session_finder';
import { iconClassFromSessionType } from 'components/lib/icon_classes';
import InteractionType from 'models/interaction_type';
import InboundCommunicationSessionNotificationModel from 'models/notification/inbound_communication_session_notification';
import Notification from './notification';
import { RoutingStatus } from 'models/routing_queue_item';
import { withTranslation } from 'react-i18next';

function InboundCommunicationSessionNotification({
  customChannel,
  customerName,
  type,
  messageText,
  notificationId,
  onCancel,
  onAccept,
  showNotification,
  titleText,
}) {
  if (!showNotification) {
    return null;
  }

  return (
    <Notification contentClasses="incomingConcurrentMessageNotification" notificationId={notificationId}>
      <div className="conversationNotification-topic">
        {renderIcon()}
        <div className="conversationNotification-notificationType">{titleText}</div>
        <div className="conversationNotification-divider" />
        <div className="conversationNotification-topic-container">
          <div className="conversationNotification-topic-heading">{customerName}</div>
          <div className="conversationNotification-divider" />
          <div className="conversationNotification-topic-body">{messageText}</div>
        </div>
      </div>
      <div>
        <Button buttonType={ButtonTypes.TEXT} contrast data-aid="notification-button-decline" onClick={onCancel}>
          Decline
        </Button>
        <Button
          buttonType={ButtonTypes.PRIMARY}
          contrast
          data-aid="notification-button-primary"
          onClick={onAcceptClick}
        >
          Accept
        </Button>
      </div>
    </Notification>
  );

  function onAcceptClick(ev) {
    ev.stopPropagation();
    onAccept();
  }

  function renderIcon() {
    switch (baseInteractionType(type)) {
      case InteractionType.CHAT:
        return <ChatIconOutline className="conversationNotification-chat-icon" />;
      case InteractionType.CUSTOM_CHANNEL:
        return getBrandIconByName(customChannel?.icon, {
          className: 'conversationNotification-customChannel-icon',
          color: 'white',
          fill: 'white',
          letter: customChannel?.name,
          stroke: true,
        }).icon;
      default:
        return <i className={iconClassFromSessionType(type)} />;
    }
  }
}

InboundCommunicationSessionNotification.propTypes = {
  customerName: PropTypes.string,
  messageText: PropTypes.string,
  notificationId: PropTypes.string,
  showNotification: PropTypes.bool,
  titleText: PropTypes.string,
  type: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const InboundCommunicationSessionNotificationContainer = connect(
  mapStateToProps,
  mapExecuteToProps
)(InboundCommunicationSessionNotification);

function mapStateToProps({ getProvider }, { t }) {
  const activeSessions = getProvider('activeSessions');
  const agents = getProvider('agents');
  const customChannels = getProvider('customChannels');
  const notifications = getProvider('notifications');

  let currentAgentId = getProvider('currentAgent').get()?.id;
  let currentLocation = getProvider('currentLocation').get();

  let offeredSession = getOfferedSessionForAgent(activeSessions, currentAgentId);
  if (!offeredSession) {
    return { showNotification: false };
  }

  let notification = notifications.findBy({ id: offeredSession.id });
  if (!(notification instanceof InboundCommunicationSessionNotificationModel)) {
    return { showNotification: false };
  }

  let messageText = notification.messagePreview || 'New message';
  let titleText = 'Incoming';

  let queueItem = offeredSession.queueItem;
  if (queueItem.status === RoutingStatus.OFFERED_ACTIVE) {
    // pull the yielding agent from the participants list. that will represent the last agent to have actually working on this item
    // if it does not exist, that means that agent has logged off, and that this item is essentially (or imminently will be) the same thing as OFFERED_REQUEUED
    let yieldedAgentId = queueItem.getYieldedAgentId();
    if (yieldedAgentId) {
      titleText = t('Assignment');
      messageText = t('has been assigned to you');

      let yieldedAgent = agents.findBy({ id: yieldedAgentId });
      let yieldedAgentName = yieldedAgent && yieldedAgent.getDisplayName();
      if (yieldedAgentName) {
        messageText = t('has been assigned to you from {{agentName}}', { agentName: yieldedAgentName });
      }
    }
  }

  const sessionType = notification.sessionType;

  return {
    currentLocation,
    customChannel: getCustomChannel(sessionType),
    customerName: notification.profile.name || getCustomerIdentifier(offeredSession, sessionType) || 'Customer',
    showNotification: true,
    messageText,
    notificationId: notification.id,
    titleText,
    type: sessionType,
  };

  function getCustomChannel(sessionType) {
    if (baseInteractionType(sessionType) !== InteractionType.CUSTOM_CHANNEL) {
      return null;
    }

    return customChannels.findBy({ id: customId(sessionType) });
  }
}

function getCustomerIdentifier(activeSession, sessionType) {
  switch (sessionType) {
    case InteractionType.SMS:
    case InteractionType.VOICEMAIL:
      return formatPhoneNumber(activeSession.getCustomerIdentifier(sessionType));
    default:
      return '';
  }
}

function mapExecuteToProps(executeAction) {
  return {
    onAccept: () => executeAction(AcceptCommunicationSession),
    onCancel: () => executeAction(DeclineCommunicationSession),
  };
}

export { InboundCommunicationSessionNotification, InboundCommunicationSessionNotificationContainer };
export default withTranslation()(InboundCommunicationSessionNotificationContainer);
