import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import ActionMixin from '../../lib/action_mixin';
import KeypadLayout from './keypad_layout';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PhoneControlsActionButton from '../lib/phone_controls_action_container_button';
import PhoneControlsActionButtonContainer from '../lib/phone_controls_action_container_button_container';
import SendKeypadDigits from 'actions/phone_call/send_keypad_digits';

const Keypad = createReactClass({
  mixins: [ActionMixin],

  propTypes: {
    backToConferenceCallback: PropTypes.func,
    className: PropTypes.string,
    isLiveConference: PropTypes.bool,
    onCloseCallback: PropTypes.func.isRequired,
  },

  getInitialState() {
    return { value: '' };
  },

  componentDidMount() {
    document.addEventListener('keypress', this.handleKeyPress);
  },

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress);
  },

  render() {
    return (
      <OutsideClickHandler onClickOutside={this.handleClickOutside}>
        <div className={classnames('keypad phoneControlsActionContainer', this.props.className)}>
          <div className="keypad-valueClose-container">
            <div className="keypad-value" dir="rtl">
              <bdi>{this.state.value}</bdi>
            </div>
            <div className="keypad-closeContainer" onClick={this.props.onCloseCallback}>
              <div className="keypad-closeLink" />
            </div>
          </div>
          <KeypadLayout onClick={this.handleButtonClick} />
          {this.renderBackToConference()}
        </div>
      </OutsideClickHandler>
    );
  },

  renderBackToConference() {
    if (this.props.isLiveConference) {
      return (
        <PhoneControlsActionButtonContainer className="keypad-backToConference-buttonContainer">
          <PhoneControlsActionButton
            className="keypad-backToConference-button"
            displayText="Back to Conference"
            onClickCallback={this.props.backToConferenceCallback}
          />
        </PhoneControlsActionButtonContainer>
      );
    }
    return false;
  },

  handleButtonClick(symbol) {
    this.updateState(symbol);
  },

  handleKeyPress(e) {
    switch (e.keyCode) {
      case 35: // #
      case 42: // *
      case 43: // +
      case 48: // 0
      case 49: // 1
      case 50: // 2
      case 51: // 3
      case 52: // 4
      case 53: // 5
      case 54: // 6
      case 55: // 7
      case 56: // 8
      case 57: // 9
        this.updateState(String.fromCharCode(e.keyCode));
        break;
      default:
        break;
    }
  },

  updateState(keypadEntry) {
    this.setState({
      value: `${this.state.value}${keypadEntry}`,
    });
    this.executeAction(SendKeypadDigits, keypadEntry);
  },

  handleClickOutside(evt) {
    this.props.onCloseCallback(evt);
  },
});

export default Keypad;
