import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export function CustomAttributeBase({ className, 'data-aid': dataAid, label, title, children }) {
  const hasLabel = !!_.trim(label);

  return (
    <CustomAttributeContainer className={className} data-aid={dataAid}>
      {hasLabel ? <CustomAttributeLabel className={`${className}-label`}>{label}</CustomAttributeLabel> : null}
      <CustomAttributeValue className={`${className}-value`} hasLabel={hasLabel} title={title}>
        {children}
      </CustomAttributeValue>
    </CustomAttributeContainer>
  );
}

export const CustomAttributeContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const CustomAttributeLabel = styled.div`
  flex-basis: 50%;
  font-weight: ${p => p.theme.fontWeight.normal};
  margin-right: ${p => p.theme.spacing.small};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CustomAttributeValue = styled.div`
  color: ${p => p.theme.colors.black};
  flex-basis: ${p => (p.hasLabel ? '50%' : '100%')};
  font-weight: ${p => p.theme.fontWeight.medium};
  min-width: 0;
`;

CustomAttributeBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  'data-aid': PropTypes.string,
  label: PropTypes.node,
  title: PropTypes.string,
};

export default CustomAttributeBase;
