import React from 'react';
import PropTypes from 'prop-types';

import Conversation from 'models/conversation';
import ConversationItem from 'models/conversation_item';
import CustomerProfile from 'models/customer_profile';
import CustomerConversationCard from 'components/lib/customer_conversation_card/customer_conversation_card';
import { H1 } from 'components/common/headers';

function CombineCurrentCustomer({ conversation, profile, latestConversationItem, taskCount }) {
  const { name, email, phone, address } = profile;

  return (
    <div className="customerCombine-currentCustomer-wrapper">
      <div className="customerCombine-currentCustomer">
        <H1 data-aid="customerCombine-currentCustomer-heading">Merge Customers</H1>
        <span className="customerCombine-currentCustomer-subheading">Current customer</span>
        <CustomerConversationCard
          conversation={conversation}
          conversationItem={latestConversationItem}
          profile={{ name, email, phone, address }}
          taskCount={taskCount}
        />
      </div>
    </div>
  );
}

CombineCurrentCustomer.propTypes = {
  conversation: PropTypes.instanceOf(Conversation),
  profile: PropTypes.instanceOf(CustomerProfile).isRequired,
  latestConversationItem: PropTypes.instanceOf(ConversationItem),
  taskCount: PropTypes.number.isRequired,
};

export default CombineCurrentCustomer;
