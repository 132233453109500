import AgentActivity from 'models/agent_activity';
import { AgentAvailability } from 'models/agent_status';
import AgentPresence from 'models/agent_presence';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_silent_handler';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class UpdatePresence {
  constructor(context) {
    this.context = context;
  }

  run() {
    let agentStatus = this.context.stores.agentStatus.get();
    if (
      agentStatus &&
      agentStatus.getAvailability() !== AgentAvailability.OFFLINE &&
      agentStatus.getAvailability() !== AgentAvailability.AWAY
    ) {
      this.context.gateways.agentPresence
        .set(AgentPresence.create())
        .catch(err => handleCommonErrors(this.context, err));

      this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.PRESENCE });
    }
  }
}
