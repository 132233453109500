import _ from 'lodash';
import Fuse from 'fuse.js';

import HitTypes from 'models/search/hit_types';

export default class SnippetSearchService {
  constructor(pubsub, getDatabase) {
    this.pubsub = pubsub;
    this.getDatabase = getDatabase;
  }

  createFuse(snippets) {
    let keys = [
      { name: 'description', weight: 0.1 },
      { name: 'name', weight: 0.5 },
      { name: 'subject', weight: 0.2 },
      { name: `content.anyChannel.bodyHtml`, weight: 0.1 },
      { name: `content.info.bodyHtml`, weight: 0.1 },
    ];

    let fuseOptions = {
      caseSensitive: false,
      distance: 0,
      findAllMatches: true,
      includeScore: false,
      keys,
      location: 0,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      shouldSort: true,
      threshold: 0.1,
      tokenize: true,
    };

    return new Fuse(snippets, fuseOptions);
  }

  request(orgId, envelope) {
    const query = envelope.payload.query;
    const { text, filter } = query;
    const ids = _.get(filter, 'answerIds');

    let searchSnippets;
    if (ids) {
      const idSet = new Set(ids);
      searchSnippets = _.filter(this.getDatabase(orgId).snippets, s => {
        return idSet.has(s.id);
      });
    } else {
      let snippets = this.getDatabase(orgId).snippets;
      let fuse = this.createFuse(snippets);
      searchSnippets = text ? fuse.search(text) : snippets;
    }

    const hits = _.map(searchSnippets, snippet => _.merge(snippet, { type: HitTypes.SNIPPET }));
    return createSnippetSearchDto(envelope.correlationId, text, hits);
  }

  publish(topic, message) {
    this.pubsub.publish(topic, message);
  }
}

function createSnippetSearchDto(id, text, hits) {
  return {
    id,
    hits,
    query: {
      text,
      from: 0,
      size: hits.length,
    },
  };
}
