import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useCallback } from 'react';

import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import PopoverMenu, { PopoverMenuItem } from 'components/common/popover_menu_deprecated';
import useCopied from 'components/hooks/use_copied';
import { useTextEditorContext } from 'components/text_editor/text_editor_context';

export default function SipHighlightMenu() {
  const [showCopied, onCopy] = useCopied();
  const { sipHighlight, onClickOutside } = useSipHighlight();
  if (!sipHighlight) {
    return null;
  }

  // Find the element in the DOM pertaining to the current highlight range
  const element = document.querySelector(
    `[data-aid="sip-highlight"][data-key="${sipHighlight.key}"][data-start="${sipHighlight.start}"]`
  );
  if (!element) {
    return null;
  }

  const copiedText = showCopied ? 'Copied!' : 'Copy to clipboard';
  const copyClassNames = classnames('draftEditor-inlineSip-copy', {
    'draftEditor-inlineSip-copy-copied': showCopied,
  });

  const menuItems = [
    <PopoverMenuItem key="copy">
      <CopyToClipboard onCopy={onCopy} text={sipHighlight.text}>
        <div className={copyClassNames}>{copiedText}</div>
      </CopyToClipboard>
    </PopoverMenuItem>,
  ];

  return (
    <OutsideClickHandler onClickOutside={onClickOutside}>
      <PopoverMenu autoPosition boundByWindow={false} button={element} isOpen position="top" style={{ zIndex: 21 }}>
        {menuItems}
      </PopoverMenu>
    </OutsideClickHandler>
  );
}

function useSipHighlight() {
  const { data, setDataValue } = useTextEditorContext();

  const onClickOutside = useCallback(() => {
    setDataValue('sipHighlight', null);
  }, [setDataValue]);

  return {
    sipHighlight: data.sipHighlight,
    onClickOutside,
  };
}
