export default class SetSelectedCommentId {
  constructor(context) {
    this.context = context;
  }

  run({ commentId }) {
    const commentPanel = this.context.stores.commentPanel.get();
    commentPanel.setSelectedCommentId(commentId);
    this.context.stores.commentPanel.set(commentPanel);
  }
}
